// @ts-nocheck
import constants from '#packages/constants';
import type { CompRef, CompStructure } from 'types/documentServices';
import * as util from '#packages/util';
import type { ListClassificationResponseGroup } from '#packages/listClassification';
import type { TextClassificationResponseData } from '#packages/textClassification';

export const SWITCH_LAYOUT_SERVICE_URL = `https://editor.wix.com/_api/swtich-layout-service/api/v1/suggest`;
export const SWITCH_LAYOUT_SERVICE_CMS_DIRECT_URL = `https://editor.wix.com/_api/swtich-layout-service/api/v1/suggest-cms-direct`;
export const DIVIDER_STRIP_HEIGHT = 100;
export const SWITCH_LAYOUT_PANEL_WIDTH = 288;
export const LAYOUT_PREVIEW_WIDTH = 240;
export const SCALE_FOR_PAAS_PRESET_PREVIEW = 0.201;
export const SWITCH_LAYOUT_ORIGIN = 'switchLayout';
export const SWITCH_LAYOUT_PANEL_NAME = 'SWITCH_LAYOUT_PANEL';
export const ENTER_SWITCH_LAYOUT = 'ENTER_SWITCH_LAYOUT';
export const SELECT_SECTION_BI_ORIGIN = 'section_click';
export const EMPTY_STATE_LINK_ORIGIN = 'empty_state_link';
export const SWITCH_LAYOUT_PANEL_TYPE = 'switch_layout';

const {
  VIDEO,
  VIDEO_PLAYER,
  MEDIA_PLAYER,
  STRIP_SLIDE_SHOW,
  SLIDE_SHOW_GALLERY,
  MASONRY,
  PAGINATED_GRID_GALLERY,
  MATRIX_GALLERY,
  SLIDER_GALLERY,
  HONEYCOMB_GALLERY,
  IMPRESS_GALLERY,
  FREESTYLE_GALLERY,
  TPA3D_GALLERY,
  TPA3D_CAROUSEL,
} = constants.COMP_TYPES;
// const { YOUTUBE, VIMEO } = constants.UI.VIDEO.URL;

export enum SupportedCompGeneralTypes {
  BUTTON = 'button',
  TEXT = 'text',
  MEDIA_VIDEOS = 'media_videos',
  MEDIA_IMAGES = 'media_images',
  MEDIA_GALLERIES = 'media_galleries',
  OTHER = 'other',
}

export interface ComponentIdAndType {
  id: string;
  compType: string;
}

export interface ComponentsGroupedByType {
  [compType: string]: ComponentIdAndType[];
}

export enum SupportedTextClassificationTypes {
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  PARAGRAPH = 'paragraph',
  SMALL_TEXT = 'small text',
}

export interface TypeMappingData {
  unsupported?: {};
  media_images?: TypeMappingComponentData[];
  media_videos?: TypeMappingComponentData[];
  media_galleries?: TypeMappingComponentData[];
  text?: TypeMappingComponentData[];
  button?: TypeMappingComponentData[];
}

export interface TypeMappingListData {
  resultListMap?: ListClassificationResponseGroup;
  originalExtractedList?: ListClassificationResponseGroup | {};
  updatedListAfterRestructure?: ListClassificationResponseGroup | {};
  allIdentifiedLists?: any;
  listClassificationOutput?: any;
  flattenListComponents?: any;
  isList?: boolean;
  listIds?: Set<string>;
  nonListComponents?: OriginalCompMappingData;
}

export interface TypeMappingTextClassificationData {
  textClassificationPredictions?: TextClassificationResponseData;
}

export interface OriginalCompMappingData {
  data?: TypeMappingData;
  listData?: TypeMappingListData;
  textClassificationData?: TypeMappingTextClassificationData;
  count?: number;
  bi?: any;
}

export interface TypeMappingComponentData {
  compRef: CompRef;
  serialized: CompStructure;
}

export const SUPPORTED_BUTTONS_FOR_SWITCH_LAYOUT: Set<string> = new Set([
  constants.COMP_TYPES.STYLABLE_BUTTON,
  constants.COMP_TYPES.SITE_BUTTON,
]);

export const SUPPORTED_COMPS: any = {
  [constants.COMP_TYPES.STYLABLE_BUTTON]: SupportedCompGeneralTypes.BUTTON,
  [constants.COMP_TYPES.SITE_BUTTON]: SupportedCompGeneralTypes.BUTTON,
  [constants.COMP_TYPES.PHOTO]: SupportedCompGeneralTypes.MEDIA_IMAGES,
  [constants.COMP_TYPES.VIDEO_PLAYER]: SupportedCompGeneralTypes.MEDIA_VIDEOS,
  [constants.COMP_TYPES.VIDEO]: SupportedCompGeneralTypes.MEDIA_VIDEOS,
  [constants.COMP_TYPES.MEDIA_PLAYER]: SupportedCompGeneralTypes.MEDIA_VIDEOS,
  [constants.COMP_TYPES.SLIDE_SHOW_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.STRIP_SLIDE_SHOW]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.MASONRY]: SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.PAGINATED_GRID_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.MATRIX_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.SLIDER_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.HONEYCOMB_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.IMPRESS_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.FREESTYLE_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.TPA3D_GALLERY]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.TPA3D_CAROUSEL]:
    SupportedCompGeneralTypes.MEDIA_GALLERIES,
  [constants.COMP_TYPES.TEXT]: SupportedCompGeneralTypes.TEXT,
  [constants.COMP_TYPES.FIVE_GRID_LINE]: SupportedCompGeneralTypes.OTHER,
  [constants.COMP_TYPES.VERTICAL_LINE]: SupportedCompGeneralTypes.OTHER,
  [constants.COMP_TYPES.SHAPE]: SupportedCompGeneralTypes.OTHER,
};

export const CONTAINERS_TO_IGNORE_FOR_PAAS: Set<string> = new Set([
  constants.COMP_TYPES.COLUMN,
  constants.COMP_TYPES.CONTAINER,
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
  constants.COMP_TYPES.SECTION,
]);

export const MAX_INSTACES_OF_SUPPORTED_TYPES_PAAS: any = {
  [SupportedCompGeneralTypes.TEXT]: 3,
  [SupportedCompGeneralTypes.BUTTON]: 1,
  [SupportedCompGeneralTypes.MEDIA_IMAGES]: 1,
  [SupportedCompGeneralTypes.MEDIA_VIDEOS]: 1,
  [SupportedCompGeneralTypes.MEDIA_GALLERIES]: 1,
};

export enum SupportedImageMediaTypes {
  IMAGE = 'image',
}

export enum SupportedVideoMediaTypes {
  EXT_VIDEO = 'extVideo',
  VIDEO = 'video',
}

export enum SupportedGalleryMediaTypes {
  GALLERY = 'gallery',
}

export enum SupportedVideoTypes {
  // VIMEO = 'vimeo',
  // YOUTUBE = 'youtube',
  FILE = 'file',
}

export const containersValidAsPaasComponent: Set<string> = new Set([
  constants.COMP_TYPES.COLUMN,
]);

export const containersValidBackgroundMediaTypes: Set<string> = new Set([
  'image',
]);

export const supportedVideoComponentTypes = new Set([
  VIDEO,
  VIDEO_PLAYER,
  MEDIA_PLAYER,
]);

export const supportedGalleryComponentTypes = new Set([
  STRIP_SLIDE_SHOW,
  SLIDE_SHOW_GALLERY,
  MASONRY,
  PAGINATED_GRID_GALLERY,
  MATRIX_GALLERY,
  SLIDER_GALLERY,
  HONEYCOMB_GALLERY,
  IMPRESS_GALLERY,
  FREESTYLE_GALLERY,
  TPA3D_GALLERY,
  TPA3D_CAROUSEL,
]);

export const unsupportedForSnapifyComponentTypes = new Set([
  VIDEO,
  // VIDEO_PLAYER,
  STRIP_SLIDE_SHOW,
  MASONRY,
  PAGINATED_GRID_GALLERY,
  MATRIX_GALLERY,
  HONEYCOMB_GALLERY,
  IMPRESS_GALLERY,
  FREESTYLE_GALLERY,
  TPA3D_GALLERY,
  TPA3D_CAROUSEL,
]);

export const SUPPORTED_VIDEOTYPES_LINKS: any = {
  // [SupportedVideoTypes.VIMEO]: VIMEO,
  // [SupportedVideoTypes.YOUTUBE]: YOUTUBE,
  [SupportedVideoTypes.FILE]: `${util.serviceTopology.staticMediaUrl}/`,
};

export const SUPPORTED_VIDEO_DATATYPES: any = {
  VIDEO_PLAYER: 'VideoPlayer',
  VIDEO: 'Video',
  WIX_VIDEO: 'WixVideo',
};

export interface ArgsMatchingPolicy {
  AboutBody: boolean;
  AboutMedia: boolean;
  AboutSubtitle: boolean;
  AboutTitle: boolean;
  button: boolean;
}

export interface MediaImageContentItem {
  Title: string;
  fileInput: { face: [] };
  fileUrl: string;
  geo: ['all'];
  height: number;
  locales: ['all'];
  mediaType: string;
  relativeUri: string;
  uri: string;
  width: number;
}

export interface MediaVideoContentItem {
  Title?: string;
  active?: boolean;
  controls?: number;
  fileBaseUrl?: string;
  fileInput?: any;
  fileOutput?: any;
  mediaType?: string;
  playmode?: {
    autoplay: boolean;
    loop: boolean;
  };
  relativeUri?: string;
  thumbnailUrl?: string;
  uri?: string;
  videoId?: string;
  videoUrl?: string;
}

export interface ListContentItem {
  header: ContentItem | undefined;
  footer: ContentItem | undefined;
  items: ContentItem[];
}

export interface MediaGalleryContentItem {
  galleryItems: MediaImageContentItem[];
}

export interface ContentItem {
  button: string | null;
  title: string | null;
  subTitle: string | null;
  longText: string | null;
  media: MediaImageContentItem[] | MediaVideoContentItem[];
}

export interface PaaSContent {
  contentItems: (ContentItem | ListContentItem)[];
  isList: boolean;
  ceType: string;
  sitePart: string;
}

export interface ExternalVideoContentItem {
  controls?: number;
  mediaType: string;
  playmode: {
    autoplay: boolean;
    loop: boolean;
  };
  videoId?: string;
  videoUrl: string;
}

export interface InternalVideoContentItem {
  Title: string;
  fileBaseUrl: string;
  fileInput: any;
  fileOutput: any;
  mediaType: string;
  playmode: {
    autoplay: boolean;
    loop: boolean;
  };
  relativeUri: string;
}

export const IS_COMP_UNSUPPORTED_BY_DATA: any = {
  [MEDIA_PLAYER]: (compStructure: CompStructure) => {
    return (
      compStructure.design?.background?.mediaRef?.mask ||
      compStructure.design?.background?.mediaRef?.mediaFeatures?.[0] === 'alpha'
    );
  },
};

export const SECTION_SCAN_UTIL_URL =
  'https://idanyael.wixsite.com/switch-layout-cms/_functions/scannedSectionPreset';

export const LIST_STRUCTURE_TYPES = {
  SIMPLE: 'simple',
  COMPLEX: 'complex',
};
export interface ListBiInfo {
  errorInfo: string | undefined;
  isList: boolean | undefined;
  listType: string | undefined;
  generalMessage: string | undefined;
  isSuccess: boolean | undefined;
}

export const LIST_PRECESS_INFO_OPTIONS = {
  NO_LISTS_FOUND: {
    errorInfo: 'No lists were classified by list classification module',
    isList: false,
    listType: undefined,
    generalMessage: 'List classification found no lists',
    isSuccess: false,
  },
  MORE_THAN_ONE_LIST_FOUND: {
    errorInfo: 'List classification classified more than one list',
    isList: true,
    listType: undefined,
    generalMessage: 'Precess failed due to limitation for one list per section',
    isSuccess: false,
  },
  RESTRUCTURE_FAILED: {
    errorInfo: 'Complex list attempt to restructure failed',
    isList: true,
    listType: undefined,
    generalMessage:
      'Process failed due to invalid list output from classificaiton',
    isSuccess: false,
  },
  RESTRUCTURE_SUCCEEDED: {
    errorInfo: undefined,
    isList: true,
    listType: LIST_STRUCTURE_TYPES.SIMPLE,
    generalMessage: 'List structure is valid after restructure',
    isSuccess: true,
  },
};

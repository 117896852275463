import type { EntryPoint } from '#packages/apilib';
import { HomePageApiKey, EditorAPIKey, PagesApiKey } from '#packages/apis';
import { createMainMenuApi } from './mainMenuApi';
import { MainMenuApiKey } from './publicApiKey';

export const MainMenuApiEntryPoint: EntryPoint = {
  name: 'MainMenuApi',
  declareAPIs: () => [MainMenuApiKey],
  getDependencyAPIs: () => [EditorAPIKey, PagesApiKey, HomePageApiKey],
  attach(shell) {
    shell.contributeAPI(MainMenuApiKey, () => createMainMenuApi(shell));
  },
};

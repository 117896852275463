import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';

function autoTranslate(
  editorAPI: EditorAPI,
  options: stateManagement.multilingual.AutoTranslationPayload,
) {
  const { languageCode, origin } = options;
  return editorAPI.store.dispatch(
    stateManagement.multilingual.actions.autoTranslate({
      languageCode,
      // backwards compatibility
      referrer: origin,
    }),
  );
}

export default { autoTranslate };

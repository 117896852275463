import React, { Component, type ComponentType } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';

import type {
  DropDownOwnProps,
  DropDownProps,
  PositionStyle,
} from '../dropDownTypes';

const ARROW_SIZE = 8;

function toCSSProps(position?: Position): PositionStyle {
  if (!position) return;
  return _.mapValues(position, (val) => `${val}px`);
}

// Clip the arrow to a triange shape to not cover content box
const arrowClipPathValues = {
  bottom: { clipPath: 'polygon(0 100%, 0 0, 100% 0)' },
  left: { clipPath: 'polygon(0 0, 100% 0, 100% 100%)' },
};

interface Position {
  top: number;
  left: number;
}

interface NodeRect {
  top: number;
  bottom: number;
  left: number;
  right: number;
  height: number;
  width: number;
  x: number;
  y: number;
}

interface ViewportRect {
  width: number;
  height: number;
}

const withCalcPanelPositionBehavior = (
  WrappedComponent: ComponentType<DropDownProps>,
) => {
  class WithCalcPanelPositionBehavior extends Component<DropDownOwnProps> {
    static defaultProps = {
      position: 'bottom',
      alignment: 'center',
      panelLeftShift: 70,
      panelTopMargin: 4,
      panelRightMargin: 12,
      panelLeftMargin: 12,
    };

    state = {
      arrowPosition: undefined as AnyFixMe,
      contentPosition: undefined as AnyFixMe,
      panelPosition: undefined as AnyFixMe,
    };

    componentDidMount() {
      if (this.props.isOpen) {
        this.recalcPositions();
      }
    }

    componentDidUpdate(prevProps: Readonly<DropDownOwnProps>) {
      if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
        this.recalcPositions();
      }
    }

    anchor: AnyFixMe = null;
    panel: AnyFixMe = null;

    panelRef = (node: AnyFixMe) => (this.panel = node);
    anchorRef = (node: AnyFixMe) => (this.anchor = node);

    recalcPositions = () => {
      if (!this.anchor || !this.panel) {
        return;
      }

      const panelPosition = this.calcPanelPosition();
      const arrowPosition = this.calcArrowPosition(panelPosition);
      const contentPosition = this.calcContentPosition();

      this.setState({
        arrowPosition,
        contentPosition,
        panelPosition,
      });
    };

    calcArrowPosition(panelPosition: Position): Position {
      const { panelTopMargin, position } = this.props;
      const { left: panelX } = panelPosition;
      const { width: panelWidth } = this.getPanelRect();
      const {
        width: anchorWidth,
        height: anchorHeight,
        x: anchorX,
      } = this.getAnchorRect();

      if (position === 'left') {
        return {
          top: anchorHeight / 2,
          left: panelWidth,
        };
      }

      return {
        top: panelTopMargin + ARROW_SIZE,
        left: anchorX - panelX + anchorWidth / 2,
      };
    }

    calcContentPosition(): Position {
      const { panelTopMargin } = this.props;

      if (this.props.position === 'left') {
        return { top: -panelTopMargin, left: 0 };
      }

      return {
        top: panelTopMargin + ARROW_SIZE,
        left: 0,
      };
    }

    calcPanelPosition(): Position {
      const { height: anchorHeight, y: anchorY } = this.getAnchorRect();
      const left = this.calcPanelPositionLeft();

      return {
        top:
          this.props.position === 'bottom' ? anchorY + anchorHeight : anchorY,
        left,
      };
    }

    calcPanelPositionLeft(): number {
      const { width: anchorWidth, x: anchorX } = this.getAnchorRect();
      const { width: panelWidth } = this.getPanelRect();
      const { width: viewportWidth } = this.getViewportRect();
      const { panelLeftShift, panelRightMargin, panelLeftMargin } = this.props;

      if (this.props.position === 'left') {
        return anchorX - this.getPanelRect().width - panelLeftShift;
      }

      let left = anchorX;

      if (this.props.alignment === 'left') {
        // align panel with left edge of the anchor
        left += 0;
      } else if (this.props.alignment === 'right') {
        // align panel with right edge of the anchor
        left += anchorWidth - panelWidth;
      } else {
        // shift panel relative to the center of the anchor
        left += anchorWidth / 2 - panelLeftShift;
      }

      if (left + panelWidth >= viewportWidth) {
        // panel doesn't fit by right viewport border
        left = viewportWidth - (panelWidth + panelRightMargin);
      } else if (left <= 0) {
        // panel doesn't fit by left viewport border
        left = panelLeftMargin;
      }

      return left;
    }

    getAnchorRect = (): NodeRect => {
      const anchorDOMNode = ReactDOM.findDOMNode(this.anchor) as Element;
      return anchorDOMNode.getBoundingClientRect();
    };

    getPanelRect = (): NodeRect => {
      const panelDOMNode = ReactDOM.findDOMNode(this.panel);
      // @ts-expect-error
      return panelDOMNode.getBoundingClientRect();
    };

    getViewportRect(): ViewportRect {
      return {
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      };
    }

    render() {
      const { props } = this;
      const { panelPosition, arrowPosition, contentPosition } = this.state;

      const arrowClipPath = arrowClipPathValues[props.position];

      return React.createElement(WrappedComponent, {
        ...props,
        anchorRef: this.anchorRef,
        panelRef: this.panelRef,
        panelPositionStyle: toCSSProps(panelPosition),
        arrowPositionStyle: toCSSProps(arrowPosition),
        arrowStyleOverrides: arrowClipPath,
        contentPositionStyle: toCSSProps(contentPosition),
        recalcPositions: () => this.recalcPositions(),
      });
    }
  }

  return WithCalcPanelPositionBehavior;
};

export default withCalcPanelPositionBehavior;

import * as blog from '#packages/blog';
import * as helpIds from '#packages/helpIds';
import * as stateManagement from '#packages/stateManagement';
import * as tpa from '#packages/tpa';
import constants from '#packages/constants';
import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  BlogWelcomeDispatchProps,
  BlogWelcomeOwnProps,
  BlogWelcomeStateProps,
} from './types';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

export const mapStateToProps: MapStateToProps<
  BlogWelcomeStateProps,
  BlogWelcomeOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  BlogWelcomeDispatchProps,
  BlogWelcomeOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  const sendBi = (event: AnyFixMe, parameters: AnyFixMe) =>
    dispatch(stateManagement.bi.actions.event(event, parameters));

  const openBlogAppManager = () => {
    const leavePanelsOpened = true;

    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME,
        { selectedAppId: blog.newBlogAppDefId },
        leavePanelsOpened,
      ),
    );
  };

  return {
    installBlogApp() {
      blog.addNewBlog(
        editorAPI,
        {
          biOrigin: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
          platformOrigin: {
            type: EditorType.Classic,
            initiator: InstallInitiator.Editor,
            info: {
              type: InstallationOriginType.AddPanel,
            },
          },
        },
        () => {
          if (
            blog.siteHasOldBlog(editorAPI) &&
            !blog.isNewBlogMagicMigrationEnabled()
          ) {
            blog.registerBlogButton(editorAPI);
          }

          openBlogAppManager();
        },
      );
    },
    migrateOldBlog() {
      return blog.startQuickMigration(editorAPI, sendBi, {
        action: 'switch',
        origin: 'add panel blog section',
      });
    },
    magicMigrateOldBlog() {
      if (blog.siteHasNewBlog(editorAPI)) {
        return blog.startMagicMigration(editorAPI, sendBi, {
          action: 'switch',
          origin: 'add panel blog section',
        });
      }

      return blog.showMigrationPopup(editorAPI, {
        skipInfo: true,
        origin: 'newBlogWelcomeSection',
      });
    },
    openHelpCenter(helpId) {
      return dispatch(stateManagement.panels.actions.openHelpCenter(helpId));
    },
    openEditorXHelpPanel() {
      editorAPI.panelManager.openHelpCenter(helpIds.ADD_PANEL.NEW_BLOG_WELCOME);
    },
    openAppMarket: () => blog.openNewBlogInAppMarket(editorAPI),
    siteHasOldBlog: blog.siteHasOldBlog(editorAPI),
    siteHasNewBlog: blog.siteHasNewBlog(editorAPI),
    isNewBlogQuickMigrationEnabled: blog.isNewBlogQuickMigrationEnabled(),
    isNewBlogMagicMigrationEnabled: blog.isNewBlogMagicMigrationEnabled(),
  };
};

import constants from '#packages/constants';
import { leftBar } from '#packages/stateManagement';
import { workspace, fedopsLogger } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { APP_MANAGER_ORIGIN } from './constants';
import { loadSegmentContent } from './services/dealer/dealer.segmentContent';
import { memoizePromise } from './utils';

import type { EditorAPI } from '#packages/editorAPI';

const { INIT_SEGMENTS } = fedopsLogger.INTERACTIONS.APP_MANAGER;

const initSegments = async (editorAPI: EditorAPI) => {
  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  ).instance;

  try {
    // TODO: add fedops

    const offer = await loadSegmentContent(metaSiteInstance);

    if (offer) {
      const patches: {
        label?: string;
        symbolName?: string;
        className?: string;
      } = {};

      const symbolName = workspace.isNewWorkspaceEnabled()
        ? offer.leftBarButtonIconName
        : offer.leftBarButtonIconNameOldWorkspace;

      if (offer.leftBarButtonName) {
        patches.label = offer.leftBarButtonName;
      }

      if (symbolName) {
        patches.symbolName = symbolName;
        patches.className = `am-${symbolName}`;
      }

      if (Object.keys(patches).length > 0) {
        editorAPI.store.dispatch(
          leftBar.actions.patchDesktopLeftBarItems({
            [constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME]: {
              ...patches,
            },
          }),
        );
      }
    }
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        [APP_MANAGER_ORIGIN]: true,
      },
    });
  }
};

export const init = memoizePromise(async (editorAPI: EditorAPI) => {
  fedopsLogger.interactionStarted(INIT_SEGMENTS);
  await initSegments(editorAPI);
  fedopsLogger.interactionEnded(INIT_SEGMENTS);
});

import React from 'react';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import constants from '../../../utils/constants';
import signupSettingsDesktopMapper from './signupSettingsDesktopMapper';
import signInSettingsDesktopMapper from './signInSettingsDesktopMapper';
import * as customSignupUtils from '../../../utils/customSignup';
import type { ConnectionType } from '../../../utils/socialLoginStatusApi';
import {
  Composites,
  Divider,
  DropDown,
  InfoIcon,
  type RadioButtonsProps,
  TextLabel,
  DropDownOption,
} from '@wix/wix-base-ui';
import type { IPolicy } from './signupSettingsDefaultDesktop';
import SignupSettingsDefaultDesktop, {
  TAB_VALUES,
} from './signupSettingsDefaultDesktop';
import SignupSettingsCustomDesktop from './signupSettingsCustomDesktop';
import SignupSettingsDeveloperDesktop from './signupSettingsDeveloperDesktop';
import PageSettingsDesktopCommon from './pageSettingsDesktopCommon';
import type { Link, SignUpPrivacyNoteType } from 'types/documentServices';
import { OnlyBackendLoginFrame } from './onlyBackendLoginFrame';

const { AUTH_VALUES, SIGNUP_OPTIONS_LABELS } = customSignupUtils;

type ISignupType = (typeof AUTH_VALUES)[keyof typeof AUTH_VALUES];
type ITabsType = (typeof TAB_VALUES)[keyof typeof TAB_VALUES];

interface ISignUpSettingsDesktop {
  getCurrentSignupState: () => ISignupType;
  isLoginDialogFirst: () => boolean;
  isAutoApproval: () => boolean;
  isDeveloperModeEnabled: () => boolean;
  setAutoApproval: (val: boolean) => void;
  setLoginDialogFirst: (val: boolean) => void;
  findManagedCustomSignup: () => string; // returns page id
  assignCustomSignup: (pageId: string) => void;
  navigateToCustomSignup: () => void;
  removeCustomSignup: () => void;
  sendBiEvent: () => any;
  hasCustomSignup: () => boolean;
  title: string;
  getCustomSignupPageId: () => string;
  getCustomSignupPageLabel: () => string;
  getCustomSignupLinkFromId: (pageId: string) => Link;
  createCustomSignup: () => void;
  openDashboard: () => void;
  focusOnLoginForm: () => void;
  getAutoApprovalOptions: () => { value: boolean; label: string }[];
  isTermsOfUseLinkVisible: () => boolean;
  isSiteSaved: () => boolean;
  isSocialAppInstalled: () => Promise<boolean>;
  canHavePublicMembers: () => Promise<boolean>;
  getJoinCommunityStatus: () => Promise<{
    joinCommunity: boolean;
    revision?: string;
  }>;
  loginDialogFirst: boolean;
  getLoginFirstOptions: () => RadioButtonsProps['options'];
  privacyNoteType: () => SignUpPrivacyNoteType;
  setPrivacyNoteType: (privacyNoteType: SignUpPrivacyNoteType) => void;
  getPrivacyNoteTypeOptions: () => {
    label: string;
    value: SignUpPrivacyNoteType;
  }[];
  toggleLoginDialogFirst: (toggle: boolean) => void;
  getSiteMembersUrl: () => string;
  closeSettingsPanel: () => void;
  translationKeys: { [key: string]: string };
  termsOfUseLink: () => Link;
  isPrivacyPolicyLinkVisible: () => boolean;
  privacyPolicyLink: () => Link;
  isCodeOfConductLinkVisible: () => boolean;
  codeOfConductLink: () => Link;
  getSocialLoginStatuses: () => Promise<Record<ConnectionType, boolean>>;
  setSocialLogin: (
    vendor: 'google' | 'facebook',
    val: boolean,
  ) => Promise<Boolean>;
  setJoinCommunityStatus: (
    toggle: boolean,
    revision?: string,
  ) => Promise<{ joinCommunity: boolean; revision?: string }>;
  openHelpPanel: (helpId: string) => void;
  toggleAutoApprove: (toggle: boolean) => void;
  autoApproval: boolean;
  getPolicyLinkData: (policy: string) => Link;
  getLabelForPolicyPageFromLink: (policyLink: Link) => string;
  getLabelForPolicyPageFromId: (policy: string) => string;
  setSignupPoliciesFieldStatus: (policy: IPolicy, value: boolean) => void;
  setSignupPoliciesLink: (policy: IPolicy, link: Link) => void;
  openLinkPanel: (params: any) => void;
  authMethod: 'signIn' | 'signUp';
  setBackendLoginOnlyStatus: (toggle: boolean) => Promise<void>;
  isBackendLoginOnlyOn: () => Promise<boolean>;
  hasBrandedApp: () => Promise<boolean>;
}
interface ISignUpSettingsDesktopState {
  dropDownValue: ISignupType;
  loginDialogFirst: boolean;
  autoApproval: boolean;
  selectedTab?: ITabsType;
  shouldOnlyBackendLoginFramePop?: ISignupType;
  isSSOMandatory: boolean;
}

class SignupSettingsDesktop extends React.Component<
  ISignUpSettingsDesktop,
  ISignUpSettingsDesktopState
> {
  displayName = 'signupSettingsDesktop';
  constructor(props: ISignUpSettingsDesktop) {
    super(props);
    this.state = {
      dropDownValue: this.props.getCurrentSignupState(),
      loginDialogFirst: this.props.isLoginDialogFirst(),
      autoApproval: this.props.isAutoApproval(),
      shouldOnlyBackendLoginFramePop: undefined,
      isSSOMandatory: false,
    };
  }

  getSignupOptions() {
    const dropdownValues = Object.values(AUTH_VALUES);
    return dropdownValues
      .filter((value) =>
        value === AUTH_VALUES.DEV ? this.props.isDeveloperModeEnabled() : value,
      )
      .map((value) => (
        <DropDownOption
          value={value}
          label={SIGNUP_OPTIONS_LABELS[value]}
          disabled={this.state.isSSOMandatory && value === AUTH_VALUES.CUSTOM}
          tooltipContent={
            this.state.isSSOMandatory && value === AUTH_VALUES.CUSTOM
              ? "These settings aren't available when SSO is enabled and set as required"
              : undefined
          }
        />
      ));
  }

  selectTab(selectedTab: ITabsType) {
    this.setState({ selectedTab });
  }

  toggleAutoApprove(toggle: boolean) {
    this.setState({ autoApproval: toggle }, () =>
      this.props.setAutoApproval(this.state.autoApproval),
    );
  }

  toggleLoginDialogFirst = (toggle: boolean) => {
    this.setState({ loginDialogFirst: toggle }, () =>
      this.props.setLoginDialogFirst(this.state.loginDialogFirst),
    );
  };

  async enableOnlyBackendLoginFrame(
    previousDropDownValue: ISignupType,
    dropDownValue: ISignupType,
    isBackendLoginOnlyOn: Promise<boolean>,
  ) {
    // If the backendLoginOnly is on and the user is switching out of Velo
    // we enforce him to turn backendLoginOnly off.
    if (
      previousDropDownValue === AUTH_VALUES.DEV &&
      dropDownValue !== AUTH_VALUES.DEV &&
      (await isBackendLoginOnlyOn)
    ) {
      this.setState({ shouldOnlyBackendLoginFramePop: dropDownValue });
      return false;
    }
    return true;
  }

  async handleDropdownValueChange(dropDownValue: ISignupType) {
    if (
      !(await this.enableOnlyBackendLoginFrame(
        this.state.dropDownValue,
        dropDownValue,
        this.props.isBackendLoginOnlyOn(),
      ))
    ) {
      return;
    }
    // We must assign the custom page in advance to switching the settings
    // view since we want the custom view to be up to date with the existing
    // custom page and present accordingly.
    if (dropDownValue === AUTH_VALUES.CUSTOM) {
      this.props.assignCustomSignup(this.props.findManagedCustomSignup());
    }
    this.setState({ dropDownValue }, () => {
      switch (dropDownValue) {
        case AUTH_VALUES.CUSTOM:
          this.props.navigateToCustomSignup();
          break;
        case AUTH_VALUES.DEFAULT:
          this.props.removeCustomSignup();
          break;
        case AUTH_VALUES.DEV:
          break;
        default:
          break;
      }
    });
    this.props.sendBiEvent().selectCustomSignupType(dropDownValue);
  }

  getHelpId() {
    return constants.AUTH_SETTINGS_PANEL_HELP_ID[this.props.authMethod];
  }

  getCloseSymbol() {
    return React.createElement(symbols.symbol, { name: 'arrowLeft' });
  }

  isCustomSignupExists() {
    return (
      this.state.dropDownValue === AUTH_VALUES.CUSTOM &&
      !!this.props.hasCustomSignup()
    );
  }

  getSignupValues() {
    return AUTH_VALUES;
  }

  render() {
    const SIGNUP_VALUES = this.getSignupValues();

    return (
      <>
        <PageSettingsDesktopCommon
          displayTitle={this.props.title}
          closeSettingsPanel={this.props.closeSettingsPanel}
          helpId={this.getHelpId()}
        >
          <div>
            <Composites.DropDownLabeled className="signup-settings-dropdown">
              <InfoIcon
                shouldTranslate={true}
                text={this.props.translationKeys.dropdownInfoText}
              />
              <TextLabel
                value={this.props.translationKeys.dropdownTitle}
                shouldTranslate={true}
                className="signup-settings-dropdown-label"
              />
              <DropDown
                value={this.state.dropDownValue}
                placeholder="NewPages_Panel_Signup_Choose_Form"
                openOnSelected={true}
                options={this.getSignupOptions()}
                onChange={(value: ISignupType) =>
                  this.handleDropdownValueChange(value)
                }
              />
            </Composites.DropDownLabeled>
            <Divider long={true} />
            {this.state.dropDownValue === SIGNUP_VALUES.DEFAULT ? (
              <SignupSettingsDefaultDesktop
                key="defaultSignupSettingsPanel"
                toggleLoginDialogFirst={(toggle: boolean) =>
                  this.toggleLoginDialogFirst(toggle)
                }
                loginDialogFirst={this.state.loginDialogFirst}
                isLoginDialogFirst={this.props.isLoginDialogFirst}
                isAutoApproval={this.props.isAutoApproval}
                isTermsOfUseLinkVisible={this.props.isTermsOfUseLinkVisible}
                getSocialLoginStatuses={this.props.getSocialLoginStatuses}
                isSiteSaved={this.props.isSiteSaved}
                isSocialAppInstalled={this.props.isSocialAppInstalled}
                canHavePublicMembers={this.props.canHavePublicMembers}
                getJoinCommunityStatus={this.props.getJoinCommunityStatus}
                getLoginFirstOptions={this.props.getLoginFirstOptions}
                privacyNoteType={this.props.privacyNoteType}
                setPrivacyNoteType={this.props.setPrivacyNoteType}
                getPrivacyNoteTypeOptions={this.props.getPrivacyNoteTypeOptions}
                getSiteMembersUrl={this.props.getSiteMembersUrl}
                getAutoApprovalOptions={this.props.getAutoApprovalOptions}
                termsOfUseLink={this.props.termsOfUseLink}
                isPrivacyPolicyLinkVisible={
                  this.props.isPrivacyPolicyLinkVisible
                }
                privacyPolicyLink={this.props.privacyPolicyLink}
                isCodeOfConductLinkVisible={
                  this.props.isCodeOfConductLinkVisible
                }
                codeOfConductLink={this.props.codeOfConductLink}
                setSocialLogin={this.props.setSocialLogin}
                setJoinCommunityStatus={this.props.setJoinCommunityStatus}
                openHelpPanel={this.props.openHelpPanel}
                toggleAutoApprove={(toggle: boolean) =>
                  this.toggleAutoApprove(toggle)
                }
                autoApproval={this.state.autoApproval}
                getPolicyLinkData={this.props.getPolicyLinkData}
                getLabelForPolicyPageFromLink={
                  this.props.getLabelForPolicyPageFromLink
                }
                getLabelForPolicyPageFromId={
                  this.props.getLabelForPolicyPageFromId
                }
                setSignupPoliciesFieldStatus={
                  this.props.setSignupPoliciesFieldStatus
                }
                setSignupPoliciesLink={this.props.setSignupPoliciesLink}
                openLinkPanel={this.props.openLinkPanel}
                translationKeys={this.props.translationKeys}
                authMethod={this.props.authMethod}
                openDashboard={this.props.openDashboard}
                hasBrandedApp={this.props.hasBrandedApp}
              />
            ) : null}
            {this.state.dropDownValue === SIGNUP_VALUES.CUSTOM ? (
              <SignupSettingsCustomDesktop
                key="customSignupSettingsPanel"
                toggleAutoApprove={(toggle: boolean) =>
                  this.toggleAutoApprove(toggle)
                }
                toggleLoginDialogFirst={(toggle: boolean) =>
                  this.toggleLoginDialogFirst(toggle)
                }
                loginDialogFirst={this.state.loginDialogFirst}
                autoApproval={this.state.autoApproval}
                isCustomSignupExists={() => this.isCustomSignupExists()}
                getAutoApprovalOptions={this.props.getAutoApprovalOptions}
                authMethod={this.props.authMethod}
                {...this.props}
              />
            ) : null}
            {this.state.dropDownValue === SIGNUP_VALUES.DEV ? (
              <SignupSettingsDeveloperDesktop
                key="developerSignupSettingsPanel"
                // @ts-expect-error the missing interface
                isCustomSignupExists={() => this.isCustomSignupExists()}
                {...this.props}
              />
            ) : null}
          </div>
        </PageSettingsDesktopCommon>
        {this.state.shouldOnlyBackendLoginFramePop && (
          <OnlyBackendLoginFrame
            panelName="BackendLoginOnly"
            // Disable Velo Form?
            title={translate(
              'Pages_Custom_Signup_API_Restrictions_Cancel_Confirmation_Modal_Title',
            )}
            // Disable
            confirmLabel={translate(
              'Pages_Custom_Signup_API_Restrictions_Cancel_Confirmation_Modal_Confirm_Label',
            )}
            // Cancel
            cancelLabel={translate(
              'Pages_Custom_Signup_API_Restrictions_Cancel_Confirmation_Modal_Cancel_Label',
            )}
            // Note: Changes to this setting apply immediately without saving or publishing your site.
            footnote={translate(
              'Pages_Custom_Signup_API_Restrictions_Cancel_Confirmation_Modal_Footer_Description',
            )}
            onCancel={() =>
              this.setState({ shouldOnlyBackendLoginFramePop: undefined })
            }
            onConfirm={async () => {
              this.props.setBackendLoginOnlyStatus(false);
              this.handleDropdownValueChange(
                this.state!.shouldOnlyBackendLoginFramePop,
              );
              this.setState({ shouldOnlyBackendLoginFramePop: undefined });
            }}
          >
            {/*
              Are you sure you want to turn off the Velo Signup & Login form?
              Block client settings are on and this will affect your live site.
            */}
            {translate(
              'Pages_Custom_Signup_API_Restrictions_Cancel_Confirmation_Modal_Description',
            )}
          </OnlyBackendLoginFrame>
        )}
      </>
    );
  }

  componentDidMount(): void {
    this.props.getSocialLoginStatuses().then((socialLoginStatuses) => {
      const isSSOMandatory =
        socialLoginStatuses.sso &&
        !socialLoginStatuses.google &&
        !socialLoginStatuses.facebook &&
        !socialLoginStatuses.password;

      this.setState({ isSSOMandatory });
    });
  }
}

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

export const signUpSettingsDesktop = connect(
  EDITOR_API,
  signupSettingsDesktopMapper,
)(renderWhenMutated(SignupSettingsDesktop));

export const signInSettingsDesktop = connect(
  EDITOR_API,
  signInSettingsDesktopMapper,
)(renderWhenMutated(SignupSettingsDesktop));

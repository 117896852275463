import constants from '#packages/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_webmaster',
      isApplied: true,
      helpId: '75a12d7d-06e3-4e9e-a894-a17c4c9bfed2',
    },
  ],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '75a12d7d-06e3-4e9e-a894-a17c4c9bfed2',
    },
  },
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  Preloader,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import { cx, propTypesFilterMixin } from '#packages/util';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import { translate } from '#packages/i18n';

import disableKeyboardShortcutsMixin from '../../mixins/disableKeyboardShortcutsMixin';
import PanelCloseOrigin from '../panelCloseOrigin';

interface DangerMessagePanelFrameProps {
  panelName: string;
  close: (origin: string) => void;
  onConfirm: PropTypes.func;
  title: string;
  contentLabel?: string;
  confirmLabel: string;
  cancelLabel?: string;
  iconName: string;
  onHelpHeader?: () => void;
  openHelpCenter: (helpId: string) => void;
  closeWithoutCalling?: boolean;
  callWithoutClosing?: boolean;
  validationError?: boolean;
  isLoading?: boolean;
  automationId?: string;

  helpMessage?: string;
  helpLinkMessage?: string;
  helpSuffix?: string;
  helpLink?: string;
  errorCodeText?: string;
  errorCode?: number;

  children: Elements[];
}
export interface FooterData {}

// eslint-disable-next-line react/prefer-es6-class
const generalMessage = createReactClass<DangerMessagePanelFrameProps>({
  displayName: constants.PANEL_TYPES.DANGER,
  mixins: [propTypesFilterMixin, disableKeyboardShortcutsMixin],

  propsTypes: {
    panelName: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    title: PropTypes.string.isRequired,
    contentLabel: PropTypes.string,
    confirmLabel: PropTypes.string.isRequired,
    cancelLabel: PropTypes.string,
    iconName: PropTypes.string.isRequired,
    onHelpHeader: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func,
    closeWithoutCalling: PropTypes.bool,
    callWithoutClosing: PropTypes.bool,
    validationError: PropTypes.bool,
    isLoading: PropTypes.bool,
    automationId: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.object,
    ]),
  },

  close(origin) {
    if (this.props.isLoading) {
      return;
    }
    this.closeAndCall(origin, this.props.closeWithoutCalling);
  },

  closeAndCall(origin, disableConfirm, disableClose) {
    if (!disableClose) {
      this.props.close(origin);
    }
    if (this.props.onConfirm && !disableConfirm) {
      this.props.onConfirm();
    }
  },

  openhelpLink() {
    this.props.openHelpCenter(this.props.helpLink);
  },

  getClasses() {
    return cx('popup-small-size-danger', 'danger', this.props.className, {
      validationErrorPanel: this.props.validationError,
    });
  },

  render() {
    return (
      <div
        id="danger-message-panel-frame"
        className="panel-frame-container screen-centered-frame general-panel-frame-z-index danger-message-panel-frame"
        data-aid={this.props.automationId}
      >
        <Composites.PopupSmallSize className={this.getClasses()}>
          <PanelHeader
            noHelpBtn={!this.props.onHelpHeader}
            onHelp={this.props.onHelpHeader}
            onClose={() => {
              this.close(PanelCloseOrigin.HEADER_CLOSE_BUTTON);
            }}
          >
            <TextLabel type="T11" value={this.props.title} />
          </PanelHeader>

          <Composites.PanelContent>
            <Composites.RichTextWithIllustration>
              <Illustration>
                <symbols.symbol name={this.props.iconName} />
              </Illustration>
              {this.props.contentLabel ? (
                <Composites.RichTextLabeled key="danger-message-content-labeled">
                  <TextLabel type="T11" value={this.props.contentLabel} />
                  <RichText type="T01" className="danger-message-content">
                    {this.props.children}
                  </RichText>
                </Composites.RichTextLabeled>
              ) : null}

              {!this.props.contentLabel ? (
                <Composites.RichText
                  key="danger-message-content"
                  automationId="dangerMessagePanelMessage"
                >
                  <RichText type="T01" className="danger-message-content">
                    {this.props.children}
                  </RichText>
                </Composites.RichText>
              ) : null}
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>

          <div className="danger-panel-footer">
            {this.props.helpMessage ? (
              <p className="danger-panel-footer-text">
                <span className="text-part">
                  {translate(this.props.helpMessage)}
                </span>
                <a className="link-part" onClick={this.openhelpLink}>
                  {translate(this.props.helpLinkMessage)}
                </a>
                <span className="help-suffix">
                  {translate(this.props.helpSuffix)}
                </span>
                <span className="error-code-part">
                  {translate(this.props.errorCodeText, {
                    status_code: this.props.errorCode,
                  })}
                </span>
              </p>
            ) : null}

            <Composites.ActionSetHorizontal className="action-set-danger-message">
              {this.props.cancelLabel && !this.props.isLoading ? (
                <Button
                  key="cancelLabel"
                  automationId="cancelButton"
                  onClick={() => this.props.close(PanelCloseOrigin.DONE_BUTTON)}
                  className="secondary btn-md btn-danger-primary"
                >
                  <TextLabel
                    automationId="danger-message-secondary-button"
                    value={this.props.cancelLabel}
                  />
                </Button>
              ) : null}
              <Button
                automationId="confirmButton"
                onClick={() =>
                  this.closeAndCall(
                    PanelCloseOrigin.CONFIRM_BUTTON,
                    false,
                    this.props.callWithoutClosing,
                  )
                }
                className={
                  /* eslint-disable-next-line you-dont-need-lodash-underscore/concat */
                  _.concat(
                    'primary btn-md btn-danger-primary',
                    this.props.isLoading ? 'hidden' : '',
                  ).join(' ')
                }
              >
                <TextLabel
                  automationId="danger-message-primary-button"
                  value={this.props.confirmLabel}
                  className="confirm-button-label"
                />
                {this.props.isLoading ? (
                  <Composites.Preloader key="confirmButtonLoading">
                    <Preloader className="small light button-on-load" />
                  </Composites.Preloader>
                ) : null}
              </Button>
            </Composites.ActionSetHorizontal>
          </div>
        </Composites.PopupSmallSize>
        <div className="dark-frame-overlay" />
      </div>
    );
  },
});

const WithPanelFrame = stateManagement.panels.hoc.panelFrame(
  constants.PANEL_TYPES.DANGER,
)(generalMessage);
WithPanelFrame.pure = generalMessage;
export default WithPanelFrame;

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import PropTypes from 'prop-types';
import * as stateManagement from '#packages/stateManagement';
import mobileWizardData from './mobileWizardData.json';
import React from 'react';
import MobileWizardSingleStep from './mobileWizardSingleStep';

const HEADER_POINTER = { id: 'SITE_HEADER', type: 'MOBILE' };
const selectors = stateManagement.mobile.selectors.mobileWizard;
const actions = stateManagement.mobile.actions.mobileWizard;

const { headerScrollTypes } = util;
const getHeaderTypeOrder = (headerType) =>
  mobileWizardData?.[HEADER_POINTER.id]?.[headerType.value.scrollType]?.order;

const buildThumbnailOption = (selectedHeaderType) => (headerType) => {
  const { scrollType } = headerType.value;
  const { label, imageSrc, automationId } =
    mobileWizardData?.[HEADER_POINTER.id]?.[scrollType];
  return {
    value: headerType,
    isSelected: selectedHeaderType === scrollType,
    label,
    imageSrc,
    automationId,
  };
};

//TYPE WAS GENERATED, remove this line when reviewed
interface MobileWizardSelectHeaderProps {
  availableHeaderTypes: Array<AnyFixMe>;
  selectedHeaderType?: string;
  setHeaderType: FunctionFixMe;
  init?: FunctionFixMe;
  destroy?: FunctionFixMe;
}

class MobileWizardSelectHeader extends React.Component<MobileWizardSelectHeaderProps> {
  static displayName = 'mobileWizardSelectHeader';

  static propTypes = {
    availableHeaderTypes: PropTypes.array.isRequired,
    selectedHeaderType: PropTypes.string,
    setHeaderType: PropTypes.func.isRequired,
    init: PropTypes.func,
    destroy: PropTypes.func,
  };

  componentDidMount() {
    this.props.init();
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  getStepOptions = () => {
    return _(this.props.availableHeaderTypes)
      .sortBy(getHeaderTypeOrder)
      .map(buildThumbnailOption(this.props.selectedHeaderType))
      .value();
  };

  render() {
    return (
      <MobileWizardSingleStep
        headerLabel="Mobile_Wizard_Welcome_Header_Title"
        actionToTakeLabel="Mobile_Wizard_Welcome_Header_Line"
        automationId="mobile-wizard-header-step"
        options={this.getStepOptions()}
        onOptionSelected={(option) => this.props.setHeaderType(option.value)}
      />
    );
  }
}

const stateToProps = ({ state }) => ({
  selectedHeaderType:
    selectors.header.getSelectedHeaderType(state) ||
    selectors.getLastSaved(state, 'header'),
  availableHeaderTypes: headerScrollTypes.headerScrollTypesModel,
});

const dispatchToProps = (dispatch) => ({
  init() {
    dispatch(actions.initHeaderStep());
  },
  destroy() {
    dispatch(actions.clearHeaderStep());
  },
  setHeaderType(headerType) {
    const scrollType = headerType?.value?.scrollType;
    dispatch(actions.setSelectedHeaderType(scrollType));
  },
});

const connected = _.flow(
  util.hoc.renderWhenMutated,
  util.hoc.connect(util.hoc.STORES.STATE_ONLY, stateToProps, dispatchToProps),
)(MobileWizardSelectHeader);
connected.pure = MobileWizardSelectHeader;
export default connected;

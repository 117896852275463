// @ts-nocheck
import * as platform from '#packages/platform';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import type { Dispatch } from 'types/redux';

import React from 'react';
import { DrillInListItem, InfoIcon, Tooltip } from '@wix/wix-base-ui';

import type { WidgetDesignProps } from './types';
import type { WidgetDesignTab } from '@wix/platform-editor-sdk';

import styles from './widgetDesignPanelElementsSection.scss';

const stopPropagationHandler = (e) => {
  e.stopPropagation();
};

class WidgetDesignPanelElementsSection extends React.Component<WidgetDesignProps> {
  handleHighlights(handler, index) {
    const { componentAdapter } = this.props;
    const { containerRef, isBoxSlideShowType, getComponentsInCurrentSlide } =
      this.props;

    const selectedComponents = componentAdapter.getSelectedComponents(index);
    const visibleComponents = platform.widgetDesignUtils.getVisibleComponents(
      containerRef,
      selectedComponents,
      isBoxSlideShowType,
      getComponentsInCurrentSlide,
    );

    return handler(visibleComponents);
  }

  highlightSelected = (index) => {
    return this.handleHighlights(this.props.handleHighlight, index);
  };

  clearHighlights = (index) => {
    return this.handleHighlights(this.props.clearHighlights, index);
  };

  openCustomizeDesignForTab = (index) => {
    this.clearHighlights(index);
    this.props.onOpenCustomizeDesignForTab(index);
  };

  getLinkContent = (tooltipData: WidgetDesignTab['tooltipData']) => {
    if (tooltipData?.helpId && tooltipData.text) {
      return (
        <span
          className={styles['info-icon-link']}
          data-hook="help-center-link"
          onClick={() => this.props.openHelpCenter(tooltipData.helpId)}
        >
          {tooltipData.text}
        </span>
      );
    }
    if (tooltipData?.url && tooltipData.text) {
      return (
        <span
          className={styles['info-icon-link']}
          onClick={() =>
            this.props.componentAdapter.openDashboardPanel(
              this.props.compData.appDefinitionId,
              {
                url: tooltipData.url,
              },
            )
          }
        >
          {tooltipData.text}
        </span>
      );
    }
    return null;
  };

  render() {
    return (
      <div className="tabs-wrapper">
        {this.props.componentAdapter
          .getTabsContent()
          .map(({ label, tooltip, tooltipData }, index) => (
            <div
              key={index}
              onMouseEnter={() => this.highlightSelected(index)}
              onMouseLeave={() => this.clearHighlights(index)}
            >
              <DrillInListItem
                label={label}
                onClick={() => this.openCustomizeDesignForTab(index)}
                key={index}
                className="tab-drill-in"
                id={index}
                contentAfter={
                  tooltip ? (
                    <div
                      className={styles.designPanelTabTooltipWrapper}
                      onClick={stopPropagationHandler}
                    >
                      <Tooltip
                        content={
                          <p className={styles.designPanelTabTooltipContent}>
                            {tooltip} {this.getLinkContent(tooltipData)}
                          </p>
                        }
                        interactive={true}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </div>
                  ) : null
                }
                shouldTranslate={this.props.shouldTranslate}
              />
            </div>
          ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openHelpCenter: (helpId: string) =>
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.STATE_AND_DS,
  null,
  mapDispatchToProps,
)(WidgetDesignPanelElementsSection);
ConnectedComponent.pure = WidgetDesignPanelElementsSection;

export default ConnectedComponent;

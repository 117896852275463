import React from 'react';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import ascendBi from '../common/bi/ascendBi';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';

interface AscendFirstTimeExperienceProps {
  superApp: any;
  nextSlide: any;
  onClose: any;
  sendBi: any;
  biBaseParams: any;
}

class FirstTimeExperience extends React.Component<AscendFirstTimeExperienceProps> {
  componentDidMount() {
    const { sendBi, biBaseParams } = this.props;

    sendBi(ascendBi.events.INTRO_VIEW, biBaseParams);
  }

  onNextClick = () => {
    const { nextSlide, sendBi, biBaseParams } = this.props;
    sendBi(ascendBi.events.INTRO_CTA_CLICK, biBaseParams);
    nextSlide();
  };

  render() {
    const { superApp, onClose } = this.props;
    return (
      <div className="ascend-first-time-experience">
        <superAppMenuInfra.firstTimeTourPanels.getStartedSlide
          superApp={superApp}
          title="AscendButton_WelcomePage_Title"
          subTitle="AscendButton_WelcomePage_Subtitle"
          pros={[
            'AscendButton_WelcomePage_Bullet1',
            'AscendButton_WelcomePage_Bullet2',
            'AscendButton_WelcomePage_Bullet3',
          ]}
          onNextButtonClick={this.onNextClick}
          nextSlide={this.onNextClick}
          onClose={onClose}
          nextButtonLabel="AscendButton_WelcomePage_Explore_CTA"
          mainImage="ascendFirstTimeTourMainImage"
        />
      </div>
    );
  }
}

const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const biBaseParams = ascendBi.biUtils.getBiBaseParams({ editorAPI, state });

  return {
    biBaseParams,
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  sendBi: (event: AnyFixMe, parameters: AnyFixMe) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(FirstTimeExperience);
ConnectedComponent.pure = FirstTimeExperience;

export default ConnectedComponent;

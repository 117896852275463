import React, { useCallback } from 'react';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import { workspace } from '#packages/util';
import { symbol as Symbol } from '#packages/baseUI';
import { translate } from '#packages/i18n';
import { frames } from '#packages/panels';

const { MessagePanelFrame, OldMessagePanelFrame } = frames;

interface SiteRevisionsPanelProps {
  panelName: string;
  onButtonClick?: (confirmed: boolean) => void;
}

const NewWorkspaceSiteRevisionsPanel: React.FC<SiteRevisionsPanelProps> = ({
  panelName,
  onButtonClick,
}) => {
  const handleConfirm = useCallback(() => {
    onButtonClick?.(true);
  }, [onButtonClick]);

  const handleCancel = useCallback(() => {
    onButtonClick?.(false);
  }, [onButtonClick]);

  return (
    <MessagePanelFrame
      dataHook="site-revisions-panel"
      panelName={panelName}
      title={translate('TOPBAR_SITE_HISTORY_MODAL_TITLE')}
      illustration={<Symbol name="siteHistoryClipart_NewWorkspace" />}
      confirmLabel={translate('TOPBAR_SITE_HISTORY_MODAL_BUTTON')}
      cancelLabel={translate('TOPBAR_SITE_HISTORY_MODAL_SECONDARY_BUTTON')}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    >
      {translate('TOPBAR_SITE_HISTORY_MODAL_TEXT')}
    </MessagePanelFrame>
  );
};

class SiteRevisionsPanel extends React.Component<SiteRevisionsPanelProps> {
  handleConfirm = () => {
    this.props.onButtonClick?.(true);
  };

  handleCancel = () => {
    this.props.onButtonClick?.(false);
  };

  render() {
    return (
      <OldMessagePanelFrame
        dataHook="site-revisions-panel"
        panelName={this.props.panelName}
        panelTitle={translate('TOPBAR_SITE_HISTORY_MSG_TITLE')}
        confirmLabel="TOPBAR_SITE_HISTORY_MSG_BUTTON"
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancel}
        className="site-revisions-panel"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <Symbol name="siteHistoryClipart" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">
              <p>{translate('TOPBAR_SITE_HISTORY_MSG_BODY1')}</p>
              <p>{translate('TOPBAR_SITE_HISTORY_MSG_BODY2')}</p>
            </RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  }
}

const SiteRevisionsPanelComponent = workspace.isNewWorkspaceEnabled()
  ? NewWorkspaceSiteRevisionsPanel
  : SiteRevisionsPanel;

export default SiteRevisionsPanelComponent;

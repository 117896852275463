import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import categories from '../utils/pageCategories';
import pagesAPI from '../api/api';
import type { IPagesPanelMenu } from '../api/menusPages';
import type { IRouter } from '../api/routerPages';
import type { IAppPage, PagesGrouped } from '../api/appsPages';
import type { EditorAPI } from '#packages/editorAPI';
import type { Dispatch, ThunkAction } from 'types/redux';
import { sendNavigateToPageBiEvent, shouldShowSignupTab } from '../utils';
import {
  EditorRestrictionsApiKey,
  FixedStageApiKey,
  PagesApiKey,
} from '#packages/apis';
import experiment from 'experiment';
import { TriggerSources } from '#packages/pages';

const { fedopsLogger } = util;
const { isInInteractionMode } = stateManagement.interactions.selectors;
const PANEL_NAME = 'quick_navigation';

const RECENTLY_VIEWED = 'NewPages_QuickNav_Pages_Recently_Viewed_Label';
const RECENTLY_VIEWED_THRESHOLD = 18;
const RECENTLY_VIEWED_COUNT = 3;
const RECENTLY_PREFIX = 'RECENTLY-';

const customSignUpAppDefId = '5f4fa4f1-5afd-4ac0-8a85-e5ac1d2b9b7d';
const customSignInAppDefId = 'bbe1406a-31f5-4f3f-9e0a-b39dfd25274f';

const openPagesPanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
);
const closePagesPanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
);

interface RecentlyViewedPages {
  time: number;
  id: string;
  title: string;
  biCategory: 'recently_viewed';
}

const getHeader = (category: { title: string }, length: number) =>
  length > 0 && { title: translate(category.title) };

const getDynamicPagesWithHeaders = (routers: IRouter[]) =>
  _.flatMap(routers, ({ title, pagesData, biCategory }) => [
    { title },
    ...pagesData.map(({ id, label }) => ({
      id,
      title: label,
      biCategory,
    })),
  ]);

const getMenuItemsWithHeaders = (menus: IPagesPanelMenu[]) =>
  _.flatMap(menus, ({ id, title, menuItems }) => {
    const filteredMenuItems = _(menuItems)
      .flatMap((menuItem) => getMenuItemTree(menuItem, id))
      .compact()
      .value();
    if (!_.isEmpty(filteredMenuItems)) {
      return [{ title }, ...filteredMenuItems];
    }
  });

const getPageFullyTitle = (pageData: IAppPage) =>
  pageData.inBracketsTitle
    ? `${pageData?.title} (${pageData.inBracketsTitle})`
    : pageData?.title;

const getApplicationPagesWithHeaders = (appPages: PagesGrouped[]) =>
  _.flatMap(appPages, ({ title, pagesData, biCategory }) => {
    if (pagesData) {
      return [
        { title },
        ...pagesData.map((pageData) => ({
          id: pageData.id,
          title: getPageFullyTitle(pageData),
          biCategory,
        })),
      ];
    }

    return [];
  });

const getMenuItemTree = (
  menuItem: AnyFixMe,
  menuId: AnyFixMe,
  isNested?: AnyFixMe,
): AnyFixMe => {
  const biCategory = categories.MENUS.getBiCategory(menuId);

  if (!_.isEmpty(menuItem.items)) {
    const nestedItems = _(menuItem.items)
      .flatMap((item) => getMenuItemTree(item, menuId, true))
      .compact()
      .value();
    if (!_.isEmpty(nestedItems)) {
      return [
        {
          id: menuItem.pageData?.id ?? menuItem.id,
          title: menuItem.pageData?.title ?? menuItem.label,
          isFolder: menuItem.type.isDropdown,
          isLink: menuItem.type.isLink,
          biCategory,
        },
        ...nestedItems,
      ];
    }
  }

  if (menuItem.type.isPage && menuItem.pageData) {
    return {
      id: menuItem.pageData.id,
      title: menuItem.pageData.title,
      isNested,
      biCategory,
    };
  }
  return null;
};

const trimMainMenuHeaderIfNecessary = (
  menuItemsWithHeaders: AnyFixMe,
  numberOfPagesAndHeaders: number,
  numberOfRecentlyViewed: number,
  numberOfMenus: number,
) => {
  if (
    numberOfPagesAndHeaders + numberOfRecentlyViewed === 0 &&
    numberOfMenus === 1
  ) {
    menuItemsWithHeaders[0] = null;
  }
};

const isRecentlyViewedId = (id: string) => id.startsWith(RECENTLY_PREFIX);
const toValidId = (id: string) => {
  if (isRecentlyViewedId(id)) {
    return _.trimStart(id, RECENTLY_PREFIX);
  }
  return id;
};
const toRecentlyViewedId = (id: string) =>
  `${isRecentlyViewedId(id) ? '' : RECENTLY_PREFIX}${id}`;

const getSelectedItem = (editorAPI: EditorAPI) => {
  const id = _.invoke(editorAPI, 'dsRead.pages.getFocusedPageId');
  if (!id) {
    return null;
  }
  return {
    id,
    title: editorAPI.pages.getPageTitle(id),
    isHomePage: editorAPI.pages.isHomePage(id),
  };
};

const getRecentlyViewedPages = (
  editorAPI: EditorAPI,
  pagesCount: number,
): RecentlyViewedPages[] => {
  if (pagesCount < RECENTLY_VIEWED_THRESHOLD) {
    return [];
  }
  return (
    stateManagement.userPreferences.selectors.getSiteUserPreferences<
      RecentlyViewedPages[]
    >('recentlyViewedPages')(editorAPI.store.getState()) || []
  );
};

const getBackToAdiTooltipVisibility = (editorAPI: EditorAPI) =>
  stateManagement.topBar.selectors.getBackToAdiTooltipVisibility(
    editorAPI.store.getState(),
  );

const mapStateToProps = ({ editorAPI }: { editorAPI: EditorAPI }) => {
  const pagesAPI = editorAPI.host.getAPI(PagesApiKey);
  const isLoadingAndTriggeredHere =
    pagesAPI.loading.getIsLoading() &&
    pagesAPI.loading.getTrigger()?.source === 'QUICK_NAVIGATION';
  const shouldShowLoader =
    isLoadingAndTriggeredHere &&
    experiment.isOpen('se_showLoadingStateForPages');

  return {
    selectedItem: getSelectedItem(editorAPI),
    closePopupPage: editorAPI.pages.popupPages.close,
    isPopupOpened: !!_.invoke(
      editorAPI,
      'dsRead.pages.popupPages.isPopupOpened',
    ),
    backToAdiTooltipVisibility: getBackToAdiTooltipVisibility(editorAPI),
    isSitePublished: editorAPI.dsRead?.generalInfo.isSitePublished(),
    isManagePagesButtonVisible: editorAPI.host
      .getAPI(EditorRestrictionsApiKey)
      .allowed('pages_manage-pages-button.visible'),
    shouldShrink: editorAPI.host.getAPI(FixedStageApiKey).isTopBarMinimized(),
    topBarStateBIParamValue:
      util.fixedStage.getTopBarStateBiParamValue(editorAPI),
    shouldShowLoader,
  };
};

const getGetItems: ThunkAction = (dispatch, getState, { editorAPI }) => {
  let signupPopups = [];
  let signInPopups = [];
  if (shouldShowSignupTab(editorAPI)) {
    const customSignup = editorAPI.dsRead.siteMembers.getCustomSignupPageId();
    const customSignIn = editorAPI.dsRead.siteMembers.getCustomSignInPageId();

    const allPopups =
      editorAPI.pages.popupPages.getDataListOrdered() as AnyFixMe;
    signupPopups = customSignup
      ? allPopups.filter(
          ({ managingAppDefId }: AnyFixMe) =>
            managingAppDefId === customSignUpAppDefId,
        )
      : [];
    signInPopups = customSignIn
      ? allPopups.filter(
          ({ managingAppDefId }: AnyFixMe) =>
            managingAppDefId === customSignInAppDefId,
        )
      : [];
  }
  const pagesStructure = pagesAPI.getFullPagesStructure(editorAPI, true);

  const applicationPagesWithHeaders = getApplicationPagesWithHeaders(
    pagesStructure.appPages,
  );
  const specialPagesWithHeaders = getDynamicPagesWithHeaders(
    pagesStructure.routers.filter(({ isSpecial }) => isSpecial),
  );
  const userDynamicPagesWithHeaders = getDynamicPagesWithHeaders(
    _.reject(pagesStructure.routers, 'isSpecial'),
  );
  const numberOfPagesWithHeaders = _.flatMap([
    pagesStructure.popups,
    signupPopups,
    signInPopups,
    specialPagesWithHeaders,
    userDynamicPagesWithHeaders,
    applicationPagesWithHeaders,
  ]).length;

  const menuItemsWithHeaders = getMenuItemsWithHeaders(pagesStructure.menus);
  const numberOfMenusWithoutHeaders =
    menuItemsWithHeaders.length - pagesStructure.menus.length;
  const itemsCount = numberOfPagesWithHeaders + numberOfMenusWithoutHeaders;
  const recentlyViewedPages = getRecentlyViewedPages(editorAPI, itemsCount);
  trimMainMenuHeaderIfNecessary(
    menuItemsWithHeaders,
    numberOfPagesWithHeaders,
    recentlyViewedPages.length,
    pagesStructure.menus.length,
  );

  return _.compact([
    getHeader({ title: RECENTLY_VIEWED }, recentlyViewedPages.length),
    ...recentlyViewedPages,
    ...menuItemsWithHeaders,
    ...specialPagesWithHeaders,
    ...applicationPagesWithHeaders,
    ...userDynamicPagesWithHeaders,
    getHeader(categories.POPUPS, pagesStructure.popups.length),
    ...pagesStructure.popups,
    getHeader(categories.SIGNUP, signupPopups.length + signInPopups.length),
    ...signupPopups,
    ...signInPopups,
  ]);
};

const getOnClickItem =
  (id: string, biCategory: string): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const validId = toValidId(id);
    if (isInInteractionMode(getState())) {
      dispatch(stateManagement.interactions.actions.exitInteraction());
    }
    editorAPI.pages.navigateTo(validId);

    sendNavigateToPageBiEvent(editorAPI, {
      pageId: validId,
      panelName: PANEL_NAME,
      biCategory,
    });

    editorAPI.pages.loading.setTrigger({
      source: TriggerSources.QUICK_NAVIGATION,
    });
  };

const getOnClickManagePages: ThunkAction = (
  dispatch,
  getState,
  { editorAPI },
) => {
  if (isInInteractionMode(getState())) {
    dispatch(stateManagement.interactions.actions.exitInteraction());
  }
  openPagesPanelInteraction.start();
  editorAPI.panelManager.openPanel('pages.pagesPanel', {
    openPanelInteraction: openPagesPanelInteraction,
    closePanelInteraction: closePagesPanelInteraction,
    origin: PANEL_NAME,
    frameType: constants.PANEL_TYPES.LEFT,
  });
  editorAPI.bi.event(coreBi.events.pages.click_on_pages_panel_open, {
    origin: PANEL_NAME,
  });
};

const closeAllPanels: ThunkAction = (dispatch, getState, { editorAPI }) => {
  editorAPI.panelManager.closeAllPanels();
};

const getUpdateRecentlyViewedPages =
  (selectedPage: AnyFixMe): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    if (!selectedPage) {
      return [];
    }

    const { id, title, isHomePage } = selectedPage;

    const recentlyViewed: RecentlyViewedPages[] =
      _.cloneDeep(
        stateManagement.userPreferences.selectors.getSiteUserPreferences<
          RecentlyViewedPages[]
        >('recentlyViewedPages')(editorAPI.store.getState()),
      ) || [];

    if (_.isEmpty(recentlyViewed) && isHomePage) {
      return [];
    }

    const pageExists = recentlyViewed.find(
      (recent) => recent.id === toRecentlyViewedId(id),
    );
    const now = Date.now();

    if (pageExists) {
      pageExists.time = now;
      pageExists.title = title;
    } else {
      recentlyViewed.push({
        time: now,
        id: toRecentlyViewedId(id),
        title,
        biCategory: 'recently_viewed',
      });
    }

    const newRecentlyViewed = _(recentlyViewed)
      .sortBy('time')
      .filter((recentPage) =>
        editorAPI.pages.isPageExist(toValidId(recentPage.id)),
      )
      .reverse()
      .slice(0, RECENTLY_VIEWED_COUNT)
      .value();

    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences<
        RecentlyViewedPages[]
      >('recentlyViewedPages', newRecentlyViewed),
    );
  };

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getItems: () => dispatch(getGetItems),
  onClickItem: ({ id, biCategory }: AnyFixMe) =>
    dispatch(getOnClickItem(id, biCategory)),
  onClickManagePages: () => dispatch(getOnClickManagePages),
  biEvent(eventType: AnyFixMe, params: AnyFixMe) {
    dispatch(stateManagement.bi.actions.event(eventType, params));
  },
  closeAllPanels: () => dispatch(closeAllPanels),
  updateRecentlyViewedPages: (selectedPage: AnyFixMe) =>
    dispatch(getUpdateRecentlyViewedPages(selectedPage)),
  closeBackToAdiTooltip: () =>
    dispatch(stateManagement.topBarTooltip.actions.closeBackToAdiTooltip()),
});

export { mapStateToProps, mapDispatchToProps, PANEL_NAME };

import React, { type ComponentType, useEffect, useRef, useState } from 'react';
import { Divider } from '@wix/wix-base-ui';
import styles from './styles/componentControlWrapper.scss';
import { QuickEditEngineScope } from '../quickEditEngineEntryPoint';
import { hasComponentPart } from '#packages/componentModel';
import GenericComponentControl from './genericComponentControl';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import { NULL_VALUE_RECORD } from '../consts';
import {
  areChildrenRestricted,
  isCompAndChildrenRestricted,
} from '../quickEditEngineUtils';
import { translate } from '#packages/i18n';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { UIResourceRestriction } from '#packages/editorRestrictions';
import type {
  QuickEditControlActions,
  QuickEditControlOwnProps,
  QuickEditControlWrapperOwnProps,
} from '@wix/editor-elements-types/quickEditControls';
import {
  mapDispatchToProps,
  mapStateToProps,
} from './componentControlWrapperMapper';
import type { GFPPActionType } from '@wix/editor-component-types';

export interface ComponentValueRecord {
  initial: any;
  last: any;
}

export interface QuickEditControlWrapperInnerOwnProps {
  getOverrideCompControls: (
    compRef: CompRef,
  ) => React.ComponentType<QuickEditControlOwnProps>;
  onControlClick: (
    compRef: CompRef,
    action: QuickEditControlActions | GFPPActionType,
  ) => void;
  onComponentChanged: (compRef: CompRef) => void;
  isComponentFiltered: (compRef: CompRef) => boolean;
  getPermissionsConfig: (
    compType: string,
    isAllowed: (uiResourceType: UIResourceRestriction) => boolean,
  ) => any;
  openComponentPanelDrillIn: (title?: string) => void;
  onDrillInAction: (compRef: CompRef) => void;
  onLinkAction: (compRef: CompRef) => void;
  onControlHover: (compRef: CompRef, rootControlCompRef: CompRef) => void;
  onControlLeave: (compRef: CompRef, rootControlCompRef: CompRef) => void;
  onMainActionClick: (compRef: CompRef, hasPrimaryConnection: boolean) => void;
  getMainActionLabel?: (compRef: CompRef) => string;
  getThumbnail?: (
    compDef: CompStructure,
    thumbnailDimensions: { width: number; height: number },
    onReady?: () => void,
  ) => Promise<React.ReactElement>;
  onGfppAction: (compRef: CompRef, actionType: GFPPActionType) => void;
  mapCompTypeToDrillInPanel?: { [compType: string]: React.FC };
  isMainComponent?: boolean;
}

type ComponentControlWrapperProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  QuickEditControlWrapperOwnProps & QuickEditControlWrapperInnerOwnProps
>;

const ComponentControlWrapperPure: React.FC<ComponentControlWrapperProps> = ({
  onHelp,
  compRef,
  compType,
  isSelected,
  rootControlCompRef,
  getChildren,
  onControlClick,
  getOverrideCompControls,
  displayName,
  setIsSelected,
  onActionClick,
  isAllowed,
  compChildrenLength,
  hasPrimaryConnection,
  isConnectedToDataBinding,
  onControlHover,
  onControlLeave,
  isComponentFiltered,
  loadComponentControl,
  mainActionType,
  mainActionLabel,
  onMainActionClick,
  getMainActionLabel,
  shouldRenderDivider,
  secondaryActionLabel,
  onSecondaryActionClick,
  onComponentChanged,
  getPermissionsConfig,
  openComponentPanelDrillIn,
  onDrillInAction,
  onLinkAction,
  reportComponentChangedIfNeeded,
  getThumbnail,
  hasListAncestor,
  onGfppAction,
  mapCompTypeToDrillInPanel,
  isMainComponent,
}) => {
  const [CompControl, setCompControl] = useState(null);
  const [isCompControlEvaluated, setIsCompControlEvaluated] =
    useState<boolean>(false);
  const valueRecordRef = useRef<ComponentValueRecord>({ ...NULL_VALUE_RECORD });

  const monitorValueChanges = (currentValue: any) => {
    if (!valueRecordRef.current.initial) {
      valueRecordRef.current.initial = currentValue;
    }
    valueRecordRef.current.last = currentValue;
  };

  const createElement = (
    ControlElement: ComponentType<QuickEditControlOwnProps>,
  ) =>
    React.createElement(
      ControlElement,
      getComponentControlProps(),
      renderChildren(),
    );

  const getComponentControlProps = (): QuickEditControlOwnProps => ({
    onHelp,
    compRef,
    translate,
    isSelected,
    displayName,
    setIsSelected,
    onActionClick,
    hasPrimaryConnection,
    isConnectedToDataBinding,
    mainActionLabel,
    onMainActionClick: () => {
      setIsSelected(mainActionType);
      onMainActionClick(compRef, hasPrimaryConnection);
    },
    secondaryActionLabel,
    monitorValueChanges,
    onSecondaryActionClick,
    onMouseOver: () => onControlHover?.(compRef, rootControlCompRef),
    onMouseLeave: () => onControlLeave?.(compRef, rootControlCompRef),
    configuration: getPermissionsConfig(compType, isAllowed),
    getThumbnail,
    hasListAncestor,
    mapCompTypeToDrillInPanel,
    isMainComponent,
  });

  const renderChildren = () => {
    if (areChildrenRestricted(compType)) {
      return null;
    }
    return getChildren(compRef).map((childRef: CompRef) => (
      <ComponentControlWrapper
        key={childRef.id}
        compRef={childRef}
        onControlHover={onControlHover}
        onControlLeave={onControlLeave}
        onMainActionClick={onMainActionClick}
        getMainActionLabel={getMainActionLabel}
        rootControlCompRef={rootControlCompRef}
        shouldRenderDivider={true}
        hasListAncestor={hasListAncestor}
        onControlClick={onControlClick}
        onComponentChanged={onComponentChanged}
        getOverrideCompControls={getOverrideCompControls}
        isComponentFiltered={isComponentFiltered}
        getPermissionsConfig={getPermissionsConfig}
        openComponentPanelDrillIn={openComponentPanelDrillIn}
        onDrillInAction={onDrillInAction}
        onLinkAction={onLinkAction}
        getThumbnail={getThumbnail}
        onGfppAction={onGfppAction}
        mapCompTypeToDrillInPanel={mapCompTypeToDrillInPanel}
      />
    ));
  };

  useEffect(() => {
    return () => {
      onControlLeave?.(compRef, rootControlCompRef);
      reportComponentChangedIfNeeded(valueRecordRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isComponentFiltered(compRef)) {
      setIsCompControlEvaluated(true);
      return;
    }
    let isMounted = true;

    const OverrideControls = getOverrideCompControls?.(compRef);

    if (OverrideControls) {
      setCompControl(createElement(OverrideControls));
      setIsCompControlEvaluated(true);
    } else if (!hasComponentPart(compType, 'quickEditControl')) {
      setCompControl(createElement(GenericComponentControl));
      setIsCompControlEvaluated(true);
    } else {
      loadComponentControl().then(({ getControl }) => {
        if (isMounted) {
          setCompControl(createElement(getControl()));
          setIsCompControlEvaluated(true);
        }
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, compChildrenLength]);

  const onMouseOverComponentControl = (e: React.MouseEvent) => {
    e.stopPropagation();
    onControlHover?.(compRef, rootControlCompRef);
  };

  const onMouseOut = (e: React.MouseEvent) => {
    e.stopPropagation();
    onControlLeave?.(compRef, rootControlCompRef);
  };

  if (isCompAndChildrenRestricted(compType) || !isCompControlEvaluated) {
    return null;
  }

  if (!CompControl) return <>{renderChildren()}</>;

  return (
    <div
      onMouseOver={onMouseOverComponentControl}
      onMouseOut={onMouseOut}
      className={styles.quickEditComponentControlWrapper}
    >
      {shouldRenderDivider && <Divider />}
      {CompControl}
    </div>
  );
};

const ComponentControlWrapper = connectWithScope(
  () => QuickEditEngineScope,
  ComponentControlWrapperPure,
  mapStateToProps,
  mapDispatchToProps,
);

export default ComponentControlWrapper;

import React from 'react';
import experiment from 'experiment';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import * as panels from '#packages/panels';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';

import type { Dispatch } from 'types/redux';

interface CantMakeChangeMobileDispatchProps {
  onDontShowAgainChange: (value: boolean) => void;
}

interface CantMakeChangeMobileProps extends CantMakeChangeMobileDispatchProps {
  panelName: string;
}

const dontShowAgainKey =
  constants.USER_PREFS.CANT_MAKE_CHANGES
    .MOBILE_CANT_MAKE_CHANGE_DONT_SHOW_AGAIN;

class CantMakeChangeMobile extends React.Component<CantMakeChangeMobileProps> {
  static displayName = 'cantMakeChangeMobile';

  render() {
    const text = (
      <RichText type="T01">
        {translate('Notification_Box_Changes_Mobile_Text')}
      </RichText>
    );

    return experiment.isOpen('se_removeOldMessagePanelFrame') ? (
      <panels.frames.MessagePanelFrame
        key="cantMakeChangesOnMobile"
        panelName={this.props.panelName}
        title={translate('Notification_Box_Changes_Mobile_Title')}
        confirmLabel={translate('Notification_Box_Got_It')}
        dontShowAgainKey={dontShowAgainKey}
        onToggleDontShowAgain={this.props.onDontShowAgainChange}
        userPrefType="site"
        className="mobile-cant-make-changes"
        illustration={<baseUI.symbol name="plaster" />}
      >
        {text}
      </panels.frames.MessagePanelFrame>
    ) : (
      <panels.frames.OldMessagePanelFrame
        ref="frame"
        key="cantMakeChangesOnMobile"
        panelName={this.props.panelName}
        panelTitle={translate('Notification_Box_Changes_Mobile_Title')}
        confirmLabel="Notification_Box_Got_It"
        dontShowAgainKey={dontShowAgainKey}
        onDontShowAgainChange={this.props.onDontShowAgainChange}
        userPrefType="site"
        className="mobile-cant-make-changes"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="plaster" />
          </Illustration>
          <Composites.RichText>{text}</Composites.RichText>
        </Composites.RichTextWithIllustration>
      </panels.frames.OldMessagePanelFrame>
    );
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch,
): CantMakeChangeMobileDispatchProps => ({
  onDontShowAgainChange: (value: boolean) => {
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        dontShowAgainKey,
        value,
      ),
    );
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(CantMakeChangeMobile);

import _ from 'lodash';
import type { TextManager } from 'types/data';
import type { Style, StyleRef } from 'types/documentServices';
import type { TextThemeStyleMap } from './textThemes';
import type { CompRef } from 'types/documentServices';
import type { ThemedActionCreationData } from './textAction';
import type { EditorAPI } from '#packages/editorAPI';

export interface ThemedAction<TActionState>
  extends ThemedActionCreationData<TActionState> {
  type: 'themed';
  getState: (
    textManager: TextManager,
    styleProperties: Style['properties'],
    theme: TextThemeStyleMap,
  ) => TActionState;
}

export const createThemedAction = <TActionState>(
  actionCreationData: Readonly<ThemedActionCreationData<TActionState>>,
): ThemedAction<TActionState> => ({
  ...actionCreationData,
  type: 'themed',
  getState: (
    textManager: TextManager,
    styleProperties: Style['properties'],
    theme: TextThemeStyleMap,
  ) => {
    return (
      actionCreationData.getWixRichTextCommandState(textManager) ||
      actionCreationData.getPropertyValue(styleProperties.style.properties) ||
      actionCreationData.getThemeValue(theme)
    );
  },
});

const applyStylePropertiesInternal = (
  styleRef: StyleRef,
  newStyleProperties: Style['properties'],
) => {
  const newProperties = _.omitBy(
    {
      ...styleRef.style.properties,
      ...newStyleProperties,
    },
    (value) => value === undefined,
  );
  return {
    ...styleRef,
    style: {
      ...styleRef.style,
      properties: newProperties,
    },
  };
};

export const applyStyleProperties = async (
  compRef: CompRef,
  styleProperties: StyleRef,
  neyProps: Style['properties'],
  updateStyleFunc: EditorAPI['components']['style']['update'],
) => {
  const updatedStyleRef = applyStylePropertiesInternal(
    styleProperties,
    neyProps,
  );

  updateStyleFunc(compRef, updatedStyleRef);

  return updatedStyleRef.style.properties;
};

const updateStyleOnData = <TActionState>(
  action: ThemedActionCreationData<TActionState>,
  actionInput: TActionState,
  styleProperties: StyleRef,
  theme: TextThemeStyleMap,
  textManager: TextManager,
) => {
  if (
    (action.getPropertyValue(styleProperties.style.properties) ||
      action.getThemeValue(theme)) !== actionInput
  ) {
    action.execCommand(textManager, actionInput);
  } else {
    action.execRevertCommand(textManager);
  }
};

const updateStyleOnStyleProperties = <TActionState>(
  compRef: CompRef,
  action: ThemedActionCreationData<TActionState>,
  actionInput: TActionState,
  styleProperties: StyleRef,
  updateStyleFunc: EditorAPI['components']['style']['update'],
) => {
  if (
    actionInput === action.getPropertyValue(styleProperties.style.properties)
  ) {
    return;
  }

  const stylePropertiesToUpdate =
    action.createUpdatedStyleProperties(actionInput);

  applyStyleProperties(
    compRef,
    styleProperties,
    stylePropertiesToUpdate,
    updateStyleFunc,
  );
};

const removeStyleFromData = <TActionState>(
  action: ThemedActionCreationData<TActionState>,
  textManager?: TextManager,
) => {
  if (textManager) {
    action.execRevertCommand(textManager);
  }
};

export const applyThemedAction = <TActionState>(
  compRef: CompRef,
  action: ThemedActionCreationData<TActionState>,
  actionInput: TActionState,
  styleProperties: StyleRef,
  theme: TextThemeStyleMap,
  updateStyleFunc: EditorAPI['components']['style']['update'],
  textManager?: TextManager,
) => {
  if (!textManager || textManager?.isAllSelected()) {
    removeStyleFromData(action, textManager);
    updateStyleOnStyleProperties(
      compRef,
      action,
      actionInput,
      styleProperties,
      updateStyleFunc,
    );
  } else {
    updateStyleOnData(action, actionInput, styleProperties, theme, textManager);
  }
};

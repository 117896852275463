import type { EntryPoint } from '#packages/apilib';
import { EditorEventRegistryApiKey, EditorAPIKey } from '#packages/apis';
import { createPlatformApi } from './platformWrapper';
import { PlatformApiKey } from './publicApiKey';
import { AddonsApiKey } from './addons/publicApiKey';

export const PlatformEntrypoint: EntryPoint = {
  name: 'PlatformApi',
  declareAPIs: () => [PlatformApiKey],
  getDependencyAPIs: () => [
    EditorAPIKey,
    EditorEventRegistryApiKey,
    AddonsApiKey,
  ],
  attach(shell) {
    shell.contributeAPI(PlatformApiKey, () => createPlatformApi(shell));
  },
};

import React from 'react';
import { ConnectedEditorComponent } from './editor';
import { createEntryPoint, ShellDontUseSymbol } from '#packages/apilib';
import { Scope } from './mainEditorScope';
import { WorkspaceModes } from '#packages/workspaceModes';
import type { UIRestrictionLeftBar } from '#packages/leftBar';
import type {
  UIRestrictionGFPP,
  UIRestrictionNameAddPanel,
  UIRestrictionStage,
} from './uiRestriction';
import type { UIRestrictionRCM } from './rightClickMenu/uiRestriction';
import type { UIRestrictionWixCode } from '#packages/wixCode';
import type { UIRestrictionTopBar } from '#packages/topBar';

// TODO move to restrictions
type UIRestriction =
  | UIRestrictionLeftBar
  | UIRestrictionStage
  | UIRestrictionRCM
  | UIRestrictionGFPP
  | UIRestrictionNameAddPanel
  | UIRestrictionWixCode
  | UIRestrictionTopBar;

const setRestrictions = ({
  editorPermissionsApi,
  editorRestrictionsApi,
  workspaceModesApi,
}: Scope) => {
  editorRestrictionsApi.set<UIRestriction>(
    [
      'left-bar.visible',
      'wix-code_ide-pane.visible',
      'wix-code_left-pane.visible',
      'rEditor_stage-container.interactive',
    ],
    () => editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
  );
  editorRestrictionsApi.set<UIRestriction>(
    [
      'left-bar_add.visible',
      'left-bar_add.interactive',
      'left-bar_mobile-add.visible',
      'left-bar_add-apps.visible',
      'left-bar_media-manager.visible',
      'left-bar_super-apps.visible',
      'left-bar_hidden-elements.visible',
      'left-bar_layers-button.visible',
      'rEditor_mouse-catcher_scalable.visible',
      'rEditor_gridlines-container.visible',
      'rEditor_rulers.visible',
      'rEditor_anchors-container.visible',
      'rEditor_edit-box.visible',
      'rEditor_focus-box.visible',
      'rEditor_hover-box.visible',
      'rEditor_parent-container-box.visible',
      'rcm_advancedA11y.visible',
      'rcm_arrange-items.visible',
      'rcm_overlapping-items.visible',
      'rcm_hide-item.visible',
      'rcm_paste-children.interactive',
      'gfpp_footer.visible',
      'gfpp_header.visible',
      'gfpp_classic-section.visible',
      'add-panel_deeplink.interactive',
    ],
    () => workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
  editorRestrictionsApi.set<UIRestriction>(
    ['rEditor_stage-block-layer.visible', 'left-bar_more-options.visible'],
    () => !workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
  editorRestrictionsApi.set<UIRestriction>(
    ['rEditor_mouse-events.interactive'],
    () =>
      editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN') &&
      workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
};

export const EditorMainViewEntryPoint = createEntryPoint({
  Scope,
  name: 'MainView',

  initialize(scope: Scope) {
    setRestrictions(scope);
    scope[ShellDontUseSymbol].contributeMainView(
      scope[ShellDontUseSymbol],
      () => <ConnectedEditorComponent />,
    );
  },
});

// @ts-nocheck
import * as util from '#packages/util';
import dataProvider from '../common/dataProvider';
import editorActionsService from '../common/editorActionsService';
import * as superApp from './superApp';

import React from 'react';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

class BookingsPanelView extends React.Component {
  static displayName = 'BookingsPanel';

  openBackOffice = () => {
    this.props.openDashboard();
  };

  render() {
    return (
      <superAppMenuInfra.superAppPanel
        superApp={this.props.getSuperApp()}
        selectedTabName={this.props.selectedTabName}
        panelClass={dataProvider.superAppPanelClassName}
      />
    );
  }
}

const BookingsPanelMapper = ({ editorAPI }) => {
  const actionsService = editorActionsService({ editorAPI });

  function getSuperApp() {
    return superApp.getWixBookingsSuperApp({
      editorAPI,
      isUserOwner: editorAPI.account.isUserOwner(),
      openAppPremiumPackagePicker: actionsService.handleUpgrade,
      isAppInstalled: () => dataProvider.isWixBookingsInstalled(editorAPI),
      isPremium: dataProvider.hasPremium,
    });
  }

  function openDashboard() {
    actionsService.openDashboard(dataProvider.BOOKINGS_BO_PAGES.offeringList);
  }

  return {
    getSuperApp,
    openDashboard,
  };
};

export default connect(
  EDITOR_API,
  BookingsPanelMapper,
)(renderWhenMutated(BookingsPanelView));

// @ts-nocheck
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const mediaContainerBackgroundPanelName =
  'compPanels.panels.MediaContainer.backgroundPanel';
const mediaContainerDesignPanelName = 'customizeDesignDataPanel.standalone';

const getBackgroundAction = (
  editorAPI: EditorAPI,
  selectedComponents: CompRef | CompRef[],
) => {
  const { label, icon, tooltip, backgroundMediaType } =
    gfppDataUtils.getDataGfppWithBackgroundIcon(
      editorAPI,
      Array.isArray(selectedComponents)
        ? selectedComponents[0]
        : selectedComponents,
      {
        label: 'gfpp_mainaction_background_listsandgrids',
        tooltip: '',
      },
    );

  return {
    label,
    isApplied: true,
    isSelected: gfppDataUtils.getPanelStateFn(
      mediaContainerBackgroundPanelName,
    ),
    onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
      gfppDataUtils.toggleComponentPanel(
        editorAPI,
        mediaContainerBackgroundPanelName,
        {
          selectedComponent: compRef,
          prependNoFillPreset: true,
          origin,
          biParams: { bg_media_type: backgroundMediaType },
        },
      );
    },
    icon,
    tooltip,
  };
};

export default {
  mainActions: (
    editorAPI: EditorAPI,
    selectedComponents: CompRef | CompRef[],
  ) => [getBackgroundAction(editorAPI, selectedComponents)],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    design: {
      isSelected: gfppDataUtils.getPanelStateFn(mediaContainerDesignPanelName),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          mediaContainerDesignPanelName,
          {
            selectedComponent: compRef,
            overrideTitle: 'lists&grids_design_item_title',
            origin,
          },
        );
      },
    },
    help: {
      helpId: helpIds.GFPP.MEDIA_CONTAINER,
      mobileHelpId: helpIds.GFPP.MEDIA_CONTAINER_MOBILE,
    },
  },
};

// @ts-nocheck
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import experiment from 'experiment';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

let enabledActions = [
  ACTIONS.MANAGE_LOCATIONS,
  ACTIONS.SETTINGS,
  ACTIONS.DESIGN,
  ACTIONS.ANIMATION,
  ACTIONS.HELP,
];
let mobileEnabledActions = [ACTIONS.SETTINGS, ACTIONS.HIDE, ACTIONS.HELP];
if (experiment.isOpen('setGoogleMapStyle')) {
  enabledActions = [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ];
  mobileEnabledActions = [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ];
}

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_googelmap_manage',
      isApplied: gfppDataUtils.getDataDefinedFn('address'),
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.MANAGE),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.MANAGE, {
        autoFocusAddress: true,
      }),
    },
  ],
  enabledActions,
  mobileEnabledActions,
  presetActions: {
    help: {
      helpId: '85be7222-3707-47b5-bb35-e61a760ab04e',
      mobileHelpId: helpIds.GFPP_MOBILE.GOOGLE_MAP,
    },
  },
};

import { createReduxStore } from '#packages/apilib';
import type { Page } from './types';

interface State {
  pagesData: Page[];
}

const getInitialState = (): State => ({ pagesData: [] });

export const SeoAutoRedirectStore = createReduxStore({
  getInitialState,
  selectors: {
    getPagesData: (state) => state.pagesData,
  },
  actions: {
    setPagesData: (state, pages: Page[]) => {
      return {
        ...state,
        pagesData: pages,
      };
    },
  },
});

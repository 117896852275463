import React from 'react';
import {
  Text,
  Button,
  TextButton,
  Popover,
  Tooltip,
  Preloader,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { withTranslation, type WithTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';
import * as coreBi from '#packages/coreBi';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { SendBIFunction } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import { Refresh } from '@wix/wix-ui-icons-common';
import { ChevronDownSmall } from '@wix/wix-ui-icons-common';
import experiment from 'experiment';
import { translate } from '#packages/i18n';

interface AutoTranslationModeStateProps {
  originalLanguageCode: string;
  originalLanguageCountryCode: string;
  currentLanguageCode: string;
  currentRegionCode?: string;
  currentLanguageCountryCode: string;
  isOriginalLanguageAutoTranslatable: boolean;
  isCurrentLanguageAutoTranslatable: boolean;
  isAutoTranslated: boolean;
  siteTranslatablesProperties: {
    totalWords: number;
    translatedWords: number;
    machineTranslateWordsLimit: number;
    machineTranslationWordsUsed: number;
  };
}

interface AutoTranslationModeDispatchProps {
  autoTranslate: () => void;
  sendBI: SendBIFunction;
  loadSiteTranslatablesProperties: (
    justInit: boolean,
    loadGetSiteTranslatablesPropertiesFailed?: (
      getSiteTranslatablesPropertiesFailed: boolean,
    ) => void,
  ) => void;
  openPackagesPage: (
    originalLanguageCode: string,
    currentLanguageCode: string,
    wordsToTranslate: number,
  ) => void;
}
interface AutoTranslationModeState {
  showPopover: boolean;
  getSiteTranslatablesPropertiesFailed: boolean;
}

export type AutoTranslatedModeProps = WithTranslation &
  AutoTranslationModeStateProps &
  AutoTranslationModeDispatchProps;

const {
  connect,
  STORES: { STATE_AND_DS },
} = util.hoc;

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

class AutoTranslationMode extends React.Component<
  AutoTranslatedModeProps,
  AutoTranslationModeState
> {
  constructor(props: AutoTranslatedModeProps) {
    super(props);

    this.state = {
      showPopover: false,
      getSiteTranslatablesPropertiesFailed: false,
    };
  }

  componentDidUpdate() {
    if (this.props.originalLanguageCode !== this.props.currentLanguageCode) {
      this.props.loadSiteTranslatablesProperties(true /* justInit */);
    }
  }
  getBIFor = (
    contentType:
      | 'auto translate'
      | 'translated'
      | 'google translate unavailable',
    eventType: 'opened' | 'closed' | 'opened_by_click',
  ) => {
    const {
      mode_bar_auto_translate_opened,
      mode_bar_auto_translate_closed,
      mode_bar_auto_translate_opened_by_click,
    } = coreBi.events.multilingual;
    let wordsToTranslate = -1;
    let wordsAvailable = -1;
    let wordsLeft = -1;
    if (this.props.siteTranslatablesProperties) {
      wordsToTranslate =
        this.props.siteTranslatablesProperties.totalWords -
        this.props.siteTranslatablesProperties.translatedWords;
      wordsAvailable =
        this.props.siteTranslatablesProperties.machineTranslateWordsLimit -
        this.props.siteTranslatablesProperties.machineTranslationWordsUsed;
      wordsLeft =
        this.props.siteTranslatablesProperties.machineTranslateWordsLimit -
        this.props.siteTranslatablesProperties.machineTranslationWordsUsed -
        this.props.siteTranslatablesProperties.totalWords +
        this.props.siteTranslatablesProperties.translatedWords;
    }
    const hasEnoughWords = wordsAvailable >= wordsToTranslate;
    let event: any;
    switch (eventType) {
      case 'opened':
        event = mode_bar_auto_translate_opened;
        break;
      case 'closed':
        event = mode_bar_auto_translate_closed;
        break;
      case 'opened_by_click':
        event = mode_bar_auto_translate_opened_by_click;
        break;
    }
    return () => {
      this.props.sendBI(event, {
        main_language: this.props.originalLanguageCode,
        lang: this.props.currentLanguageCode,
        content_type: contentType,
        words_in_credit: wordsAvailable,
        words_translated: wordsToTranslate,
        words_remain: hasEnoughWords ? wordsLeft : 0,
        is_credit_enough: hasEnoughWords,
        words_needed: hasEnoughWords ? 0 : -1 * wordsLeft,
      });
    };
  };

  onAutoTranslateClick = () => {
    this.props.autoTranslate();
  };

  getLocaleName = (languageCode: string, regionCode?: string) => {
    const isUseLocaleUIOpened = experiment.isOpen('specs.ml.UseLocaleUI');

    return regionCode && isUseLocaleUIOpened
      ? `${translate(
          `locale-dataset.languages.${languageCode}`,
        )} (${regionCode.toUpperCase()})`
      : translate(`locale-dataset.languages.${languageCode}`);
  };

  render() {
    const {
      originalLanguageCode,
      isOriginalLanguageAutoTranslatable,
      isCurrentLanguageAutoTranslatable,
      isAutoTranslated,
      currentLanguageCode,
      currentRegionCode,
      t,
      siteTranslatablesProperties,
      loadSiteTranslatablesProperties,
      openPackagesPage,
    } = this.props;

    const currentLocaleName = this.getLocaleName(
      currentLanguageCode,
      currentRegionCode,
    );

    const autoTranslateSupported =
      isOriginalLanguageAutoTranslatable && isCurrentLanguageAutoTranslatable;
    let hasEnoughWords = false;
    let wordsAvailable = -1;
    let wordsToTranslate = -1;
    let translatedWords = -1;
    if (siteTranslatablesProperties) {
      wordsToTranslate =
        siteTranslatablesProperties.totalWords -
        siteTranslatablesProperties.translatedWords;
      wordsAvailable =
        siteTranslatablesProperties.machineTranslateWordsLimit -
        siteTranslatablesProperties.machineTranslationWordsUsed;
      hasEnoughWords = wordsAvailable >= wordsToTranslate;
      translatedWords = siteTranslatablesProperties.translatedWords;
    }
    const isSiteTranslatablesPropertiesReady = wordsAvailable !== -1;
    const getContent = () => {
      if (
        this.state.getSiteTranslatablesPropertiesFailed ||
        isNaN(wordsToTranslate) ||
        isNaN(wordsAvailable)
      ) {
        return (
          <div className="language-mode-bar__tooltip__error__wrapper">
            <div className="language-mode-bar__tooltip__error__symbol">
              <symbols.symbol name="installer-builder-empty-state" />
            </div>
            <div className="language-mode-bar__tooltip__error__message">
              <Text
                enableEllipsis={false}
                size="small"
                weight="thin"
                shouldTranslate={false}
              >
                {t(
                  'Multilingual_Mode_OneClick_Tooltip_TranslationPackage_Error',
                )}
              </Text>
            </div>
            <div className="language-mode-bar__tooltip__error__refresh">
              <Refresh fill="#116DFF" />
              <TextButton
                onClick={() => {
                  loadSiteTranslatablesProperties(
                    false /* justInit */,
                    (status: boolean) =>
                      this.setState({
                        getSiteTranslatablesPropertiesFailed: status,
                      }),
                  );
                }}
                shouldTranslate={false}
                size="small"
              >
                {t(
                  'Multilingual_Mode_OneClick_Tooltip_TranslationPackage_Error_CTA',
                )}
              </TextButton>
            </div>
          </div>
        );
      }
      if (autoTranslateSupported && isSiteTranslatablesPropertiesReady) {
        return (
          <AutoTranslateToolTipContent
            t={t}
            hasEnoughWords={hasEnoughWords}
            autoTranslate={this.onAutoTranslateClick}
            originalLanguageCode={originalLanguageCode}
            currentLanguageCode={currentLanguageCode}
            currentLocaleName={currentLocaleName}
            wordsAvailable={wordsAvailable}
            wordsToTranslate={wordsToTranslate}
            translatedWords={translatedWords}
            openPackagesPage={openPackagesPage}
            closePopover={() => this.setState({ showPopover: false })}
            sendBI={this.props.sendBI}
          />
        );
      }
      if (!autoTranslateSupported) {
        return (
          <GoogleTranslateNotSupportedTooltipContent
            t={t}
            isOriginalLanguageAutoTranslatable={
              isOriginalLanguageAutoTranslatable
            }
            isCurrentLanguageAutoTranslatable={
              isCurrentLanguageAutoTranslatable
            }
            originalLanguageCode={originalLanguageCode}
            currentLanguageCode={currentLanguageCode}
          />
        );
      }
      return (
        <div className="language-mode-bar__tooltip__preloader__wrapper">
          <div className="language-mode-bar__tooltip__preloader">
            <Preloader />
          </div>
          <div className="language-mode-bar__tooltip__preloader__label">
            <Text enableEllipsis={false} shouldTranslate={false} size="small">
              {t(
                'Multilingual_Mode_OneClick_Tooltip_TranslationPackage_Loader',
              )}
            </Text>
          </div>
        </div>
      );
    };

    const AutoTranslateButton = () => (
      <Popover
        automationId="auto-translation-tooltip"
        shown={this.state.showPopover}
        placement="bottom"
        maxWidth={300}
        zIndex={10000}
        appendTo="viewport"
        showArrow
        onClickOutside={() => {
          this.getBIFor('auto translate', 'closed')();
          this.setState({ showPopover: false });
        }}
      >
        <Popover.Element>
          <div
            onMouseEnter={() => {
              if (!autoTranslateSupported) {
                this.getBIFor('google translate unavailable', 'opened')();
                this.setState({ showPopover: true });
              }
            }}
            onMouseLeave={() => {
              if (!autoTranslateSupported) {
                this.getBIFor('google translate unavailable', 'closed');
                this.setState({ showPopover: false });
              }
            }}
          >
            <Button
              suffixIcon={
                <div
                  style={{
                    marginRight: '-6px',
                    width: '18px',
                    height: '18px',
                  }}
                >
                  <ChevronDownSmall />
                </div>
              }
              disabled={!autoTranslateSupported}
              className={util.cx([
                'btn-inverted',
                {
                  'btn-sm': isNewWorkspace,
                },
              ])}
              automationId="auto-translation-button"
              onClick={() => {
                if (!this.state.showPopover) {
                  loadSiteTranslatablesProperties(
                    false /* justInit */,
                    (status: boolean) =>
                      this.setState({
                        getSiteTranslatablesPropertiesFailed: status,
                      }),
                  );
                }
                if (isSiteTranslatablesPropertiesReady)
                  this.getBIFor(
                    'auto translate',
                    this.state.showPopover ? 'closed' : 'opened_by_click',
                  )();
                this.setState({ showPopover: !this.state.showPopover });
              }}
            >
              {!isNewWorkspace && (
                <symbols.symbol name="googleTranslateMenuItem" />
              )}
              {isNewWorkspace && t('SiteLanguages_Panel_Button_Auto_Translate')}
            </Button>
          </div>
        </Popover.Element>
        <Popover.Content>
          <div
            style={{
              padding: '18px 24px',
            }}
          >
            {getContent()}
          </div>
        </Popover.Content>
      </Popover>
    );

    return (
      <div className="language-mode-bar__container-auto-translate">
        {isAutoTranslated ? (
          <Tooltip
            alignment="BOTTOM"
            marginBottom={6}
            maxWidth={210}
            onOpen={this.getBIFor('translated', 'opened')}
            onClose={this.getBIFor('translated', 'closed')}
            content={<AlreadyTranslatedTooltipContent t={t} />}
          >
            <span className="language-mode-bar__auto-translated">
              <Text
                className="language-mode-bar__auto-translated-label"
                size="tiny"
                shouldTranslate={false}
              >
                <symbols.symbol name="modeBarTranslated" />
                {t('Multilingual_Mode_OneClick_AlreadyTranslated_Label')}
              </Text>
            </span>
          </Tooltip>
        ) : (
          <AutoTranslateButton />
        )}
      </div>
    );
  }
}

const AlreadyTranslatedTooltipContent: React.FC<{ t: TFunction }> = ({ t }) => {
  return (
    <div>
      <Text shouldTranslate={false} enableEllipsis={false} size="medium">
        {t('Multilingual_Mode_OneClick_AlreadyTranslated_Tooltip_Title')}
      </Text>
      <Text
        skin="secondary"
        shouldTranslate={false}
        enableEllipsis={false}
        size="small"
      >
        {t('Multilingual_Mode_OneClick_AlreadyTranslated_Tooltip_Description')}
      </Text>
    </div>
  );
};

interface AutoTranslateToolTipContentProps {
  autoTranslate: () => void;
  originalLanguageCode: string;
  currentLanguageCode: string;
  currentLocaleName: string;
  hasEnoughWords: boolean;
  wordsAvailable: number;
  wordsToTranslate: number;
  translatedWords: number;
  openPackagesPage: (
    originalLanguageCode: string,
    currentLanguageCode: string,
    wordsToTranslate: number,
  ) => void;
  closePopover: () => void;
  t: TFunction;
  sendBI: (definition: BiEventDefinition, fields: BiEventFields) => void;
}

export class GoogleTranslateNotSupportedTooltipContent extends React.Component<{
  t: TFunction;
  isOriginalLanguageAutoTranslatable: boolean;
  isCurrentLanguageAutoTranslatable: boolean;
  originalLanguageCode: string;
  currentLanguageCode: string;
}> {
  getSubheaderText = () => {
    const {
      t,
      isOriginalLanguageAutoTranslatable,
      isCurrentLanguageAutoTranslatable,
      originalLanguageCode,
      currentLanguageCode,
    } = this.props;

    if (!isOriginalLanguageAutoTranslatable) {
      return t('Multilingual_Mode_OneClick_Unsupported_Main_Language_Tooltip', {
        MAIN_LANGUAGE_NAME: t(
          `locale-dataset.languages.${originalLanguageCode}`,
        ),
      });
    }
    if (!isCurrentLanguageAutoTranslatable) {
      return t('Multilingual_Mode_OneClick_Unsupported_Tooltip', {
        LANGUAGE_NAME: t(`locale-dataset.languages.${currentLanguageCode}`),
      });
    }
  };

  render() {
    return (
      <>
        <div className="language-mode-bar__tooltip__header">
          <Text
            automationId="auto-translation-tooltip-header"
            shouldTranslate={false}
            enableEllipsis={false}
            size="large"
          >
            {this.props.t(
              'Multilingual_Mode_OneClick_Unsupported_Tooltip_Title',
            )}
          </Text>
        </div>
        <Text
          automationId="auto-translation-not-supported"
          enableEllipsis={false}
          shouldTranslate={false}
          size="small"
        >
          {this.getSubheaderText()}
        </Text>
      </>
    );
  }
}

export class AutoTranslateToolTipContent extends React.Component<AutoTranslateToolTipContentProps> {
  render() {
    const {
      autoTranslate,
      originalLanguageCode,
      currentLanguageCode,
      currentLocaleName,
      t,
      hasEnoughWords,
      wordsAvailable,
      wordsToTranslate,
      openPackagesPage,
      closePopover,
    } = this.props;
    let notEnoughCreditsTextKey;

    if (!hasEnoughWords) {
      notEnoughCreditsTextKey =
        wordsToTranslate - wordsAvailable === 1
          ? 'Multilingual_Mode_OneClick_Tooltip_TranslationPackage_NotEnoughCredits'
          : 'Multilingual_Mode_OneClick_Tooltip_TranslationPackage_NotEnoughCredits_Plural';
    }

    return (
      <div className="language-mode-bar__tooltip__wrapper">
        <div className="language-mode-bar__tooltip__header">
          <Text
            automationId="auto-translation-tooltip-header"
            shouldTranslate={false}
            enableEllipsis={false}
            size="large"
          >
            {t('Multilingual_Mode_OneClick_Tooltip_Title')}
          </Text>
        </div>
        <div className="language-mode-bar__tooltip__line1">
          <Text
            enableEllipsis={false}
            size="small"
            shouldTranslate={false}
            skin="secondary"
          >
            {hasEnoughWords
              ? t(
                  'Multilingual_Mode_OneClick_Tooltip_TranslationPackage_EnoughCredits',
                  {
                    LANGUAGE_NAME: currentLocaleName,
                  },
                )
              : t(notEnoughCreditsTextKey, {
                  machine_translation_words_needed:
                    wordsToTranslate - wordsAvailable,
                })}
          </Text>
        </div>

        {!hasEnoughWords && (
          <Button
            className="btn-md"
            onClick={() => {
              closePopover();
              openPackagesPage(
                originalLanguageCode,
                currentLanguageCode,
                wordsToTranslate,
              );
            }}
            shouldTranslate={false}
          >
            {t(
              'Multilingual_Mode_OneClick_Tooltip_TranslationPackage_GetCredits',
            )}
          </Button>
        )}
        {hasEnoughWords && (
          <div className="language-mode-bar__tooltip__btn-auto-translation__wrapper">
            <Button
              className="btn-md"
              automationId="auto-translation-tooltip-button"
              shouldTranslate={false}
              onClick={() => {
                closePopover();
                this.props.sendBI(
                  coreBi.events.multilingual.mode_bar_auto_translate_clicked,
                  {
                    words_in_credit: wordsAvailable,
                    words_translated: wordsToTranslate,
                    words_remain: wordsAvailable - wordsToTranslate,
                    is_credit_enough: true,
                    main_language: originalLanguageCode,
                    lang: currentLanguageCode,
                  },
                );
                autoTranslate();
              }}
            >
              {t('Multilingual_Mode_OneClick_Tooltip_Button')}
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export const mapStateToProps: MapStateToProps<
  AutoTranslationModeStateProps
> = ({ dsRead: { language }, state }) => {
  const originalLanguage = language.original.get();
  const languages = []
    .concat(originalLanguage, language.get())
    .filter((lang) => lang !== undefined);
  const currentLanguageCode = language.current.get();
  const currentLanguage = languages.find(
    (lang) => lang.languageCode === currentLanguageCode,
  );
  const isOriginalLanguageAutoTranslatable = Boolean(
    (originalLanguage as any)?.machineTranslationLanguageCode,
  );
  const isCurrentLanguageAutoTranslatable = Boolean(
    currentLanguage?.machineTranslationLanguageCode,
  );

  return {
    originalLanguageCode: originalLanguage.languageCode,
    originalLanguageCountryCode: originalLanguage.countryCode,
    currentLanguageCode,
    currentLanguageCountryCode: currentLanguage.countryCode,
    currentRegionCode: currentLanguage.regionCode,
    isOriginalLanguageAutoTranslatable,
    isCurrentLanguageAutoTranslatable,
    isAutoTranslated:
      stateManagement.multilingual.selectors.isAutoTranslated(state),
    siteTranslatablesProperties:
      stateManagement.multilingual.selectors.siteTranslatablesProperties(state),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  AutoTranslationModeDispatchProps,
  {}
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    autoTranslate: () => {
      dispatch(
        stateManagement.multilingual.actions.autoTranslateWithUIEffects({
          referrer: 'mode-bar',
          origin: 'editor',
        }),
      );
    },
    sendBI: (definition: BiEventDefinition, fields: BiEventFields) => {
      dispatch(stateManagement.bi.actions.event(definition, fields));
    },
    loadSiteTranslatablesProperties(
      justInit: boolean,
      setLoadSiteTranslatablesPropertiesFailed?: (
        getSiteTranslatablesPropertiesFailed: boolean,
      ) => void,
    ) {
      const mlSelectors = stateManagement.multilingual.selectors;
      const state = editorAPI.store.getState();
      if (setLoadSiteTranslatablesPropertiesFailed) {
        setLoadSiteTranslatablesPropertiesFailed(false);
      }
      if (
        !justInit ||
        mlSelectors.siteTranslatablesProperties(state) === undefined
      ) {
        dispatch(
          stateManagement.multilingual.actions.getSiteTranslatablesPropertiesSuccess(
            null,
          ),
        );
        dispatch(
          stateManagement.multilingual.actions.getSiteTranslatablesPropertiesThunk(),
        );
        if (
          mlSelectors.siteTranslatablesProperties(state) === 'failed' &&
          setLoadSiteTranslatablesPropertiesFailed
        ) {
          setLoadSiteTranslatablesPropertiesFailed(true);
        }
      }
    },

    openPackagesPage: (
      originalLanguageCode: string,
      currentLanguageCode: string,
      wordsToTranslate: number,
    ) =>
      dispatch(
        stateManagement.multilingual.actions.sendOpenPackagePickerEventThunk(
          'floating_bar',
          originalLanguageCode,
          currentLanguageCode,
          wordsToTranslate,
        ),
      ),
  };
};

const Connected = connect(
  STATE_AND_DS,
  mapStateToProps,
  mapDispatchToProps,
)(AutoTranslationMode);

export const pure = AutoTranslationMode;
export default withTranslation()(Connected);

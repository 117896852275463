// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import categories from '../../utils/pageCategories';
import * as coreBi from '#packages/coreBi';
import {
  ContextMenu,
  ContextMenuAction,
  CustomScroll,
  Divider,
  Text,
  Tooltip,
  TooltipOnEllipsis,
} from '@wix/wix-base-ui';
import { cx } from '#packages/util';
import { DATA_BINDING } from '@wix/app-definition-ids';
import experiments from 'experiment';

function template() {
  return (
    <div className="tabs-pp">
      <CustomScroll>
        <ul>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.getTabs(), (tab) => (
            <li
              onClick={() => {
                this.handleClick(tab);
              }}
              key={`li-${tab.id}`}
              data-aid={`tab-${tab.title}`}
              className="tab"
            >
              <div
                className={cx('container-for-background', {
                  selected: this.props.selectedTabId === tab.id,
                })}
              >
                <TooltipOnEllipsis marginTop={9}>
                  <div className="title">{tab.title}</div>
                </TooltipOnEllipsis>
                <Tooltip
                  marginTop={6}
                  content="NewPages_Panel_Left_SiteMenu_Actions_Label"
                  closeOnMouseClick={true}
                  className="actions-tooltip"
                >
                  {!_.isEmpty(tab.actions) ? (
                    <ContextMenu
                      customButton={this.getActionsButton(tab.id)}
                      automationId={`context-menu-${tab.id}`}
                      key="tab-context-menu"
                      onToggle={(isOpen) => {
                        this.handleContextMenuToggle(tab, isOpen);
                      }}
                    >
                      {/* TODO: Fix this the next time the file is edited. */}
                      {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                      {_.map(tab.actions, (action) => (
                        <ContextMenuAction
                          onClick={action.onClick}
                          key={`action-${action.title}`}
                          className="panel-tab-action-pp"
                        >
                          {this.getActionSymbol(action)}
                          <Text
                            enableEllipsis={true}
                            shouldTranslate={false}
                            size="small"
                            weight="thin"
                            skin="secondary"
                          >
                            {action.title}
                          </Text>
                        </ContextMenuAction>
                      ))}
                    </ContextMenu>
                  ) : null}
                </Tooltip>
              </div>
              {tab.separatorAfter ? (
                <Divider key={`separator-after-${tab.id}`} />
              ) : null}
            </li>
          ))}
        </ul>
      </CustomScroll>
    </div>
  );
}

const createTabFor = ({ id, title, group, biCategory }) => ({
  id,
  title: translate(title),
  group,
  biCategory,
});

const addSeparatorsToTabs = (tabs) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(tabs, (tab, index) => {
    tab.separatorAfter = index === tabs.length - 1;
    return tab;
  });

const getBICategory = (tab) => {
  if (tab.group === categories.MENUS.group) {
    return categories.MENUS.getBiCategory(tab.id);
  }
  if (tab.group === categories.TPA_PAGES.group) {
    return categories.TPA_PAGES.getBiCategory(tab.id);
  }
  return tab.biCategory;
};

const getUserDynamicPagesTabs = (
  shouldShowDynamicPagesIntroTab,
  isDesktop,
  appPages,
  routers,
) => {
  const hasDataBindingAppPages = appPages.some(
    (appPage) => appPage.id === DATA_BINDING,
  );

  const shouldRenderDynamicPagesIntroTab =
    isDesktop && shouldShowDynamicPagesIntroTab && !hasDataBindingAppPages;

  const dynamicPagesTabs = [];

  if (shouldRenderDynamicPagesIntroTab) {
    dynamicPagesTabs.push(createTabFor(categories.DYNAMIC_PAGES_INTRO));
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(routers, ({ id, title, actions }) => {
    dynamicPagesTabs.push({
      id,
      title,
      actions,
      group: categories.DYNAMIC_PAGES.group,
      biCategory: categories.DYNAMIC_PAGES.biCategory,
    });
  });

  return dynamicPagesTabs;
};

class PanelTabs extends React.Component {
  constructor(props) {
    super(props);

    this.newPlatformizedAuthenticationOn = () => {
      return (
        experiments.isOpen('specs.editor.newPlatformizedAuthPages') &&
        this.props.hasCustomSignInLightbox
      );
    };

    this.shouldDisplaySignupTab = () => {
      const {
        isDesktop,
        hasCustomSignupLightbox,
        hasRestrictedPage,
        isMembersAreaV2Installed,
      } = this.props;
      const hasRestrictedPageOrNewMA =
        isDesktop && (hasRestrictedPage || isMembersAreaV2Installed);

      return (
        (hasRestrictedPageOrNewMA || hasCustomSignupLightbox) &&
        !this.newPlatformizedAuthenticationOn()
      );
    };

    this.getTabs = () => {
      const userDynamicPagesTabs = getUserDynamicPagesTabs(
        this.props.shouldShowDynamicPagesIntroTab,
        this.props.isDesktop,
        this.props.appPages,
        this.props.userRouters,
      );
      let tabs = [];

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(this.props.menus, ({ id, title, actions }) => {
        tabs.push({ id, title, actions, group: categories.MENUS.group });
      });

      if (!_.isEmpty(this.props.specialRouters)) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(
          this.props.specialRouters,
          ({ id, title, actions, biCategory }) => {
            tabs.push({
              id,
              title,
              actions,
              group: categories.SPECIAL_PAGES.group,
              biCategory,
            });
          },
        );
      }

      if (this.shouldDisplaySignupTab()) {
        tabs.push(createTabFor(categories.SIGNUP));
      }

      if (!_.isEmpty(this.props.appPages)) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(this.props.appPages, ({ id, title, actions, biCategory }) => {
          tabs.push({
            id,
            title,
            actions,
            group: categories.APPLICATION_PAGES.group,
            biCategory,
          });
        });
      }

      tabs = tabs.concat(userDynamicPagesTabs);

      if (!_.isEmpty(this.props.popups)) {
        tabs.push(createTabFor(categories.POPUPS));
      }
      tabs = addSeparatorsToTabs(tabs);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/last
      _.last(tabs).separatorAfter = false;

      return tabs;
    };
    this.handleContextMenuToggle = (tab, isOpen) => {
      if (isOpen) {
        this.props.biEvent(
          coreBi.events.pages.pagesPanel.tab_clicked_on_settings,
          {
            category: getBICategory(tab),
          },
        );
      }
    };
    this.getActionsButton = (id) =>
      React.createElement(
        'div',
        { key: `actions-button-${id}` },
        React.createElement(symbols.symbol, { name: 'settingsActionSmall' }),
      );
    this.handleClick = (tab) => {
      this.props.biEvent(coreBi.events.pages.pagesPanel.category_click, {
        category: getBICategory(tab),
      });
      this.props.onClickTab(tab.id);
    };

    this.getActionSymbol = (action) => {
      if (action?.symbol && action?.symbolName) {
        return (
          <action.symbol className="symbol" data-hook={action.symbolName} />
        );
      }
      if (action?.symbolName) {
        return (
          <symbols.symbol
            name={action.symbolName}
            data-hook={action.symbolName}
          />
        );
      }
      return null;
    };
  }

  componentDidMount(): void {
    this.sendActiveTabViewBi();
  }

  componentDidUpdate(prevProps): void {
    if (this.props.selectedTabId !== prevProps.selectedTabId) {
      this.sendActiveTabViewBi();
    }
  }

  sendActiveTabViewBi() {
    const tabs = this.getTabs();

    const activeTab = tabs.find((tab) => tab.id === this.props.selectedTabId);

    this.props.biEvent(coreBi.events.pages.pagesPanel.category_view, {
      category: getBICategory(activeTab),
    });
  }

  render() {
    return template.call(this);
  }
}

PanelTabs.displayName = 'PanelTabs';
PanelTabs.propTypes = {
  popups: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRouters: PropTypes.arrayOf(PropTypes.object).isRequired,
  specialRouters: PropTypes.arrayOf(PropTypes.object).isRequired,
  appPages: PropTypes.arrayOf(PropTypes.object).isRequired,
  menus: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickTab: PropTypes.func.isRequired,
  selectedTabId: PropTypes.string,
  biEvent: PropTypes.func.isRequired,
  shouldShowDynamicPagesIntroTab: PropTypes.bool.isRequired,
  hasCustomSignupLightbox: PropTypes.bool,
  hasCustomSignInLightbox: PropTypes.bool,
  hasRestrictedPage: PropTypes.bool,
  isDesktop: PropTypes.bool.isRequired,
};

export default PanelTabs;

import React from 'react';
import { PermissionSection } from '../CommonComponents';
import { PERMISSIONS_TAB_HOOKS } from '../../../../../utils/dataHooks';

import type { RolesPanelProps } from '../../permissionsTabMapper';
import type { Group } from '../../../../../utils/groupsApi';

export interface MemberRoleSectionProps {
  rolesForPage: Required<Group>[];
  availableRoles: Required<Group>[];
  permissions?: string[];
  disabled: boolean;
  openRolesPanel: (panelProps: RolesPanelProps) => void;
  openDashboardModal: (path: string) => void;
  updateGroupsPermissions: (groupsToUpdate: Group[]) => Promise<void>;
}

export const MemberRoleSection: React.FC<MemberRoleSectionProps> = ({
  openRolesPanel,
  openDashboardModal,
  updateGroupsPermissions,
  availableRoles,
  permissions,
  rolesForPage,
  disabled,
}) => {
  const hasCreatedRoleGroups = availableRoles.length > 0;
  const canCreateNewRole = permissions?.includes(
    'my-account.view-site-members',
  );

  const openMemberRolesDashboard = () =>
    openDashboardModal(
      '/member-permissions/roles/manage-permissions?referralInfo=editor&viewMode=editor',
    );

  const openMemberRolesPanel = () =>
    openRolesPanel({
      panelName: 'panels.toolPanels.rolesManagerPanel',
      groups: availableRoles,
      headerTitle: 'NewPages_Roles_Manager_Header_Title',
      selectedGroups: rolesForPage,
      applyButtonLabel: 'NewPages_Roles_Manager_Apply_Button',
      applyButtonCallback: updateGroupsPermissions,
      addNewButtonLabel: 'NewPages_Roles_Manager_Add_New_Role',
      addNewButtonCallback: openMemberRolesDashboard,
      disableAddNew: !canCreateNewRole,
    });

  const disconnectRoles = async () => {
    const groupsToRemove = rolesForPage.map((group) => ({
      ...group,
      checked: false,
    }));

    await updateGroupsPermissions(groupsToRemove);
  };

  return (
    <PermissionSection
      dataHook={PERMISSIONS_TAB_HOOKS.ROLES_PERMISSION_SECTION}
      title="NewPages_Panel_Permissions_Member_Roles_Section_Title"
      subtitle="NewPages_Panel_Permissions_Member_Roles_Section_Subtitle"
      disabled={disabled}
      disconnectGroups={disconnectRoles}
      hasAvailableGroups={hasCreatedRoleGroups}
      emptyText="NewPages_Panel_Permissions_Member_Roles_Section_Placeholder"
      createText="NewPages_Panel_Permissions_Member_Roles_Section_Create_Button"
      canCreateNew={canCreateNewRole}
      onCreate={openMemberRolesDashboard}
      connectedGroups={rolesForPage}
      connectText="NewPages_Panel_Permissions_Member_Roles_Section_Choose_Button"
      onConnect={openMemberRolesPanel}
    />
  );
};

import React, { type FC, useEffect, useState } from 'react';
import {
  Composites,
  Illustration,
  ProgressBar,
  TextLabel,
} from '@wix/wix-base-ui';
import * as util from '#packages/util';
import { frames } from '#packages/panels';

import styles from './progressBarPanel.scss';

const illustration = util.media.getMediaUrl(
  util.browserUtil.getDevicePixelRatio() === 2
    ? 'panels/focusPanels/new-app-installer-progress-panel_x2.gif'
    : 'panels/focusPanels/new-app-installer-progress-panel_x1.gif',
);

const stepDisplayNameArray = [
  'ai_template_injection_loader_step_one_text',
  'ai_template_injection_loader_step_two_text',
  'ai_template_injection_loader_step_three_text',
  'ai_template_injection_loader_step_four_text',
];

const STEP_TIMEOUT = 2000;

const ProgressBarPanel: FC<{}> = () => {
  const [step, setStep] = useState(0);
  const [stepDisplayName, setStepDisplayName] = useState(
    stepDisplayNameArray[0],
  );

  useEffect(() => {
    const timer = setInterval(() => {
      if (step < stepDisplayNameArray.length) {
        const nextStep = step + 1;
        setStep(nextStep);
        setStepDisplayName(stepDisplayNameArray[nextStep - 1]);
      } else {
        clearInterval(timer);
      }
    }, STEP_TIMEOUT);
    return () => clearInterval(timer);
  }, [step, stepDisplayName]);

  return (
    <frames.FocusPanelFrame
      panelName="ai-injection-into-template-loader-panel"
      dataHook="ai-injection-into-template-loader-panel"
      shouldHideHeader={true}
      shouldNotCloseOnBlur={true}
    >
      <div className={styles.wrapper}>
        <Composites.ProgressBarWithIllustration>
          <Illustration>
            <img
              src={illustration}
              className={styles.illustration}
              alt="illustration"
            />
          </Illustration>
          <Composites.ProgressBar>
            <TextLabel
              type="T03"
              value="ai_template_injection_loader_title"
              className="progress-bar-title"
              shouldTranslate
            />
            <ProgressBar
              progress={step}
              progressMax={stepDisplayNameArray.length}
              hideText
            />
            <TextLabel type="T02" value={stepDisplayName} shouldTranslate />
          </Composites.ProgressBar>
        </Composites.ProgressBarWithIllustration>
      </div>
    </frames.FocusPanelFrame>
  );
};

export default ProgressBarPanel;

import { biLogger } from '#packages/util';
import {
  editorModeClickToChange,
  editorModeChangedSuccessfully,
} from '@wix/bi-logger-editor/v2';

import type { WorkspaceModes } from './consts';

export const setBiDefaults = (workspace_mode: WorkspaceModes) => {
  biLogger.updateDefaults({
    workspace_mode,
  });
};

export const reportModeChange = (newMode: WorkspaceModes, origin: string) => {
  biLogger.report(
    editorModeClickToChange({
      mode: newMode,
      origin,
    }),
  );
};

export const reportModeChangeSuccess = (
  mode: WorkspaceModes,
  origin: string,
) => {
  biLogger.report(
    editorModeChangedSuccessfully({
      mode,
      origin,
    }),
  );
};

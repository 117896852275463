// @ts-nocheck
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  enabledActions: [ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.FORM_CONTAINER,
    },
  },
};

// @ts-nocheck
import * as baseUI from '#packages/baseUI';
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import genericCustomization from './customization';
import { Divider } from '@wix/wix-base-ui';
import { getSectionDividerLabelForCustomization } from './customizationUtils';

function renderGenericCustomization(customization) {
  return React.createElement(
    genericCustomization,
    _.merge({}, customization.input, {
      valueLink: linkCustomizationValue.call(this, customization),
    }),
  );
}

function renderCheckableTextFieldCustomization(customization) {
  return React.createElement(baseUI.checkableTextInput, {
    checkStateValueLink: linkBooleanCustomizationValue.call(this, {
      fieldId: customization.checkStateFieldId,
      forType: customization.forType,
      format: customization.checkStateFormat,
      key: customization.checkStateKey,
      view: customization.view,
    }),

    placeholderText: customization.input.placeholderText,
    shouldDisableTextEditing: customization.input.shouldDisableTextEditing,
    experimentNeeded: customization.input.experimentNeeded,

    textValueLink: linkCustomizationValue.call(this, {
      fieldId: customization.textFieldId,
      forType: customization.forType,
      format: customization.textFormat,
      key: customization.textKey,
      view: customization.view,
    }),
  });
}

function linkBooleanCustomizationValue(customizationSelector) {
  const customizationValueLink = linkCustomizationValue.call(
    this,
    customizationSelector,
  );

  const valueLink = {
    requestChange(value) {
      customizationValueLink.requestChange(String(value));
      syncValue();
    },
  };

  syncValue();

  return valueLink;

  function syncValue() {
    valueLink.value = parseStringifiedBoolean(
      String(customizationValueLink.value),
    );
  }
}

function parseStringifiedBoolean(stringifiedBoolean) {
  return stringifiedBoolean === 'true';
}

function linkCustomizationValue(customizationSelector) {
  return this.props.compPanel.linkedCustomization(customizationSelector);
}

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  compPanel?: { linkedCustomization: FunctionFixMe };
  isTopOfPage?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'customizationsContainer';

  static propTypes = {
    compPanel: PropTypes.shape({
      linkedCustomization: PropTypes.func.isRequired,
    }),
    isTopOfPage: PropTypes.bool,
  };

  static defaultProps = {
    isTopOfPage: false,
  };

  getComps = () => {
    const wixappsPanel = this.props.compPanel;
    const compCustoms = _(this.props.customizations)
      .sortBy('priority')
      .reverse()
      .filter(function (customization) {
        const hiddenWhen = customization?.input?.hiddenwhen;
        _.set(hiddenWhen, 'forType', customization.forType);
        const shouldBeHidden =
          wixappsPanel.evaluateHiddenWhenCondition(hiddenWhen);
        return !shouldBeHidden;
      })
      .value();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _(compCustoms)
      .forEach(function (customization) {
        customization.relatedComp = customization.relatedComp || '';
      })
      .forEach(this.addInfoIcons);

    const comps = _(compCustoms)
      .groupBy(getSectionDividerLabelForCustomization)
      .toPairs()
      .map(function transformPairIntoMap(labelAndCustomizations) {
        return {
          name: labelAndCustomizations[0],
          customizations: labelAndCustomizations[1],
        };
      })
      .sortBy(function sortByPriority(comp) {
        return comp.name ? comp.customizations[0].priority : Number.MAX_VALUE; // Non-grouped customizations always first.
      })
      .reverse()
      .value();

    return comps;
  };

  /**
   * @param {!{relatedComp: string=, fieldId: string=, key: string=}} customization
   * @returns {string}
   */

  addInfoIcons = (customization) => {
    if (customization.input && customization.toolTip) {
      customization.input.infoText = customization.toolTip;
    }

    return customization;
  };

  renderCustomization = (customization) => {
    switch (customization.input?.name) {
      default: {
        return renderGenericCustomization.call(this, customization);
      }

      case 'checkabletextfield': {
        return renderCheckableTextFieldCustomization.call(this, customization);
      }
    }
  };

  render() {
    return (
      <div>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.getComps(), (comp, compIndex) => (
          <div key={compIndex}>
            {comp.name && !(this.props.isTopOfPage && compIndex === 0) ? (
              <Divider long={true} key="TopOfPageDivider" />
            ) : null}
            {comp.name && !(this.props.isTopOfPage && compIndex === 0) ? (
              <baseUI.sectionDividerLabeled label={comp.name} key="divider" />
            ) : null}
            {comp.name ? <Divider key="longDivider" long={true} /> : null}
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(comp.customizations, (customization, customizationIndex) => (
              <div key={`customization_${customizationIndex}`}>
                {this.renderCustomization(customization)}
                {customizationIndex !== comp.customizations.length - 1 ? (
                  <Divider key="longDividerInCustomization" long={true} />
                ) : null}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

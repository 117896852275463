import * as core from '#packages/core';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import _ from 'lodash';
import constants from '#packages/constants';
import type { BaseDragPlugin } from './common';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompLayout } from 'types/documentServices';
import type { SnapDirections, ClosestLayoutDistance } from '#packages/core';

const { isMultiselect } = util.array;
const { SnapToHandler } = core.utils;
const { getStageRect } = stateManagement.domMeasurements.selectors;
const {
  DEFAULT_SNAP_TRESHOLD,
  FULL_WIDTH_THRESHOLD,
  BORDER_SNAP_TRESHOLD,
  SECTION_VERTICAL_THRESHOLD,
} = util.snapToUtils;

type Axis = 'x' | 'y';

interface Scope {
  editorAPI: EditorAPI;
  snapToHandler: AnyFixMe;
  snapDirections: SnapDirections;
}

function getEqualDistancesData(scope: Scope, layout: CompLayout, axis: Axis) {
  const { editorAPI, snapToHandler } = scope;
  const stageRect = getStageRect(editorAPI.store.getState());

  return util.componentsDistanceUtils.getEqualDistancesData(
    editorAPI,
    layout,
    snapToHandler.getSnapCandidates(),
    stageRect,
    axis,
  );
}

function boxInEqualDistancePosition(
  scope: Scope,
  alignmentExists: boolean,
  axis: Axis,
  layout: CompLayout,
) {
  const { snapToHandler } = scope;

  if (snapToHandler.equalDistanceLockedPosition && !alignmentExists) {
    if (
      Math.abs(layout[axis] - snapToHandler.equalDistanceLockedPosition[axis]) <
      10
    ) {
      layout[axis] = snapToHandler.equalDistanceLockedPosition[axis];
      layout.bounding[axis] = snapToHandler.equalDistanceLockedPosition[axis];
      if (axis === 'y') {
        snapToHandler.verticalEqualDistanceData = getEqualDistancesData(
          scope,
          layout,
          'y',
        );
      }
      snapToHandler.horizontalEqualDistanceData = getEqualDistancesData(
        scope,
        layout,
        'x',
      );
    } else {
      snapToHandler.equalDistanceLockedPosition = null;
    }
  }
  return layout;
}

function setEqualDistanceLockedPosition(
  scope: Scope,
  isEqualDistanceFound: boolean,
  compPositionInEqualDistanceFound: boolean,
) {
  const { snapToHandler } = scope;
  if (isEqualDistanceFound) {
    snapToHandler.equalDistanceLockedPosition =
      compPositionInEqualDistanceFound;
  }
}

function updateLayoutDueToAlignmentSnap(
  layout: CompLayout,
  closestLayoutDistance: Record<Axis, number>,
  axis: Axis,
) {
  const boundingLayout = _.defaults(
    {},
    { [axis]: layout.bounding[axis] + closestLayoutDistance[axis] },
    layout.bounding,
  );
  return _.defaults(
    {},
    {
      [axis]: layout[axis] + closestLayoutDistance[axis],
      bounding: boundingLayout,
    },
    layout,
  );
}

const getHorizontalThreshold = (
  { editorAPI }: Scope,
  closestLayoutDistance: ClosestLayoutDistance,
  layout: CompLayout,
) => {
  const isSectionLikeCandidate = editorAPI.sections.isSectionLike(
    closestLayoutDistance.compByY,
  );

  if (!isSectionLikeCandidate) {
    return DEFAULT_SNAP_TRESHOLD;
  }

  const isFullWidth = editorAPI.components.is.fullWidth(
    editorAPI.selection.getSelectedComponents(),
  );

  if (!util.snapToUtils.isNewStageGuidesEnabled()) {
    return isFullWidth ? FULL_WIDTH_THRESHOLD : DEFAULT_SNAP_TRESHOLD;
  }

  const sectionLayout = editorAPI.components.layout.getRelativeToScreen(
    closestLayoutDistance.compByY,
  );
  const isOutOfSection =
    layout.y < sectionLayout.y ||
    layout.y + layout.height > sectionLayout.y + sectionLayout.height;

  if (isFullWidth || isOutOfSection) {
    return FULL_WIDTH_THRESHOLD;
  }

  return BORDER_SNAP_TRESHOLD;
};

function getVerticalThreshold(
  { editorAPI }: Scope,
  closestLayoutDistance: ClosestLayoutDistance,
  layout: CompLayout,
) {
  const isSectionLikeCandidate = editorAPI.sections.isSectionLike(
    closestLayoutDistance.compByX,
  );

  if (!isSectionLikeCandidate || !util.snapToUtils.isNewStageGuidesEnabled()) {
    return DEFAULT_SNAP_TRESHOLD;
  }

  const siteWidth = editorAPI.site.getWidth();
  const siteX = editorAPI.site.getSiteX();
  const isOutOfStage =
    layout.x < siteX || layout.x + layout.width > siteWidth + siteX;

  return isOutOfStage ? SECTION_VERTICAL_THRESHOLD : BORDER_SNAP_TRESHOLD;
}

function snapToTheClosestLayout(scope: Scope, layout: CompLayout) {
  const { snapToHandler, editorAPI, snapDirections } = scope;

  const closestLayoutDistance = snapToHandler.getClosestLayoutDistance(
    editorAPI,
    layout.bounding,
    snapDirections,
  );

  const horizontalAlignmentExists =
    Math.abs(closestLayoutDistance.y) <
    getHorizontalThreshold(scope, closestLayoutDistance, layout);
  const verticalAlignmentExists =
    Math.abs(closestLayoutDistance.x) <
    getVerticalThreshold(scope, closestLayoutDistance, layout);

  if (horizontalAlignmentExists) {
    layout = updateLayoutDueToAlignmentSnap(layout, closestLayoutDistance, 'y');
    snapToHandler.horizontalEqualDistanceData = getEqualDistancesData(
      scope,
      layout,
      'x',
    );
    setEqualDistanceLockedPosition(
      scope,
      snapToHandler.horizontalEqualDistanceData.isHorizontalEqualDistanceFound,
      snapToHandler.horizontalEqualDistanceData.compLayoutInEqualDistanceFound,
    );
    layout = boxInEqualDistancePosition(
      scope,
      verticalAlignmentExists,
      'x',
      layout,
    );
  }
  if (verticalAlignmentExists) {
    layout = updateLayoutDueToAlignmentSnap(layout, closestLayoutDistance, 'x');
    snapToHandler.verticalEqualDistanceData = getEqualDistancesData(
      scope,
      layout,
      'y',
    );
    snapToHandler.horizontalEqualDistanceData = getEqualDistancesData(
      scope,
      layout,
      'x',
    );
    setEqualDistanceLockedPosition(
      scope,
      snapToHandler.horizontalEqualDistanceData.isHorizontalEqualDistanceFound,
      snapToHandler.horizontalEqualDistanceData.compLayoutInEqualDistanceFound,
    );
    setEqualDistanceLockedPosition(
      scope,
      snapToHandler.verticalEqualDistanceData.isVerticalEqualDistanceFound,
      snapToHandler.verticalEqualDistanceData.compLayoutInEqualDistanceFound,
    );
    layout = boxInEqualDistancePosition(
      scope,
      horizontalAlignmentExists,
      'y',
      layout,
    );
  }

  return layout;
}

function getEqualDistanceData(scope: Scope) {
  const { snapToHandler } = scope;

  return {
    horizontalEqualDistanceLines: snapToHandler.horizontalEqualDistanceData
      ? snapToHandler.horizontalEqualDistanceData.horizontalEqualDistanceLines
      : [],
    verticalEqualDistanceLines: snapToHandler.verticalEqualDistanceData
      ? snapToHandler.verticalEqualDistanceData.verticalEqualDistanceLines
      : [],
  };
}

function drawSnapLines(
  scope: Scope,
  layout: CompLayout,
  isAltKeyDown: boolean,
) {
  const { editorAPI, snapToHandler, snapDirections } = scope;
  const ignoreTouchingGridLines = editorAPI.dsRead.pages.isWidgetPage(
    editorAPI.pages.getFocusedPageId(),
  );
  const equalDistanceData = getEqualDistanceData(scope);
  const snapData = snapToHandler.getSnapData(
    layout.bounding,
    snapDirections,
    ignoreTouchingGridLines,
    equalDistanceData,
    constants.MOUSE_ACTION_TYPES.DRAG,
    isAltKeyDown,
  );
  editorAPI.snapData.set(snapData);
}

function endDrag(scope: Scope) {
  const { editorAPI, snapToHandler } = scope;

  if (util.snapToUtils.isNewStageGuidesEnabled()) {
    util.snapToUtils.snapCloseToBorder(
      editorAPI,
      snapToHandler.compRefs,
      constants.MOUSE_ACTION_TYPES.DRAG,
    );
  }

  editorAPI.snapData.clear();
}

function shouldDrawSnaplines({ isFastDrag }: { isFastDrag: boolean }) {
  return !isFastDrag;
}

function overrideLayout(
  scope: Scope,
  layout: CompLayout,
  { isFastDrag }: { isFastDrag: boolean },
) {
  if (shouldDrawSnaplines({ isFastDrag })) {
    layout = snapToTheClosestLayout(scope, layout);
  }
  return layout;
}

function beforeLayoutUpdate(
  scope: Scope,
  {
    layout,
    isFastDrag,
    isAltKeyDown,
  }: { layout: CompLayout; isFastDrag: boolean; isAltKeyDown: boolean },
) {
  const { editorAPI } = scope;
  if (shouldDrawSnaplines({ isFastDrag })) {
    drawSnapLines(scope, layout, isAltKeyDown);
  } else {
    editorAPI.snapData.clear();
  }
}

export const SnapPlugin: BaseDragPlugin = {
  init: ({ hooks, pluginFactoryScope }) => {
    const { editorAPI, selectedComp } = pluginFactoryScope;
    if (!editorAPI.getViewTools().snapToEnabled) {
      return;
    }

    const snapToHandler = new SnapToHandler(editorAPI, selectedComp);

    const isHorizontallyMovable =
      editorAPI.components.is.horizontallyMovable(selectedComp);

    let snapDirections: SnapDirections = {
      top: true,
      right: true,
      bottom: true,
      left: true,
      centerX: true,
      centerY: true,
    };

    if (!isMultiselect(selectedComp)) {
      snapDirections = {
        ...snapDirections,
        centerX: isHorizontallyMovable,
      };
    }

    const scope: Scope = {
      snapToHandler,
      editorAPI,
      snapDirections,
    };

    hooks.endDrag.tap(() => endDrag(scope));
    hooks.overrideLayout.tap((...args) => overrideLayout(scope, ...args));
    hooks.beforeLayoutUpdate.tap((args) => beforeLayoutUpdate(scope, args));
  },
};

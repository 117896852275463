import React from 'react';
import { Composites, Illustration, RichText, Text } from '@wix/wix-base-ui';

import { workspace } from '#packages/util';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import { frames } from '#packages/panels';

const { MessagePanelFrame, OldMessagePanelFrame } = frames;

const defaultTranslations = {
  panelTitle: 'MyDesigns_Delete_Design_Modal_Title',
  confirmLabel: 'MyDesigns_Delete_Design_Modal_Delete_Button',
  cancelLabel: 'MyDesigns_Delete_Design_Modal_Cancel_Button',
  paragraph1: 'MyDesigns_Delete_Design_Modal_Text',
  paragraph2: 'MyDesigns_Delete_Design_Modal_Text2',
};

type Translations = typeof defaultTranslations;
type TranslationKey = keyof Translations;

interface DeleteSavedComponentsPanelProps {
  panelName: string;
  name: string;
  onConfirm: () => void;

  translations: Translations;
}

const DeleteSavedComponentsPanel: React.FC<DeleteSavedComponentsPanelProps> = (
  props,
) => {
  return (
    <OldMessagePanelFrame
      panelName={props.panelName}
      onConfirm={props.onConfirm}
      confirmLabel="MyDesigns_DeleteDesign_Popup_Button_Delete"
      cancelLabel="MyDesigns_DeleteDesign_Popup_Button_Cancel"
      type="danger"
      panelTitle={translate('MyDesigns_DeleteDesign_Popup_Header')}
      className="delete-saved-components-panel"
    >
      <Composites.RichTextWithIllustration>
        <Illustration>
          <baseUI.symbol name="deleteGeneralIcon" />
        </Illustration>
        <Composites.RichText>
          <RichText type="T01">
            <p key="line1">
              {translate('MyDesigns_DeleteDesign_Popup_Text1', {
                0: props.name,
              })}
            </p>
            <p key="line2">{translate('MyDesigns_DeleteDesign_Popup_Text2')}</p>
          </RichText>
        </Composites.RichText>
      </Composites.RichTextWithIllustration>
    </OldMessagePanelFrame>
  );
};

const NewWorkspaceDeleteSavedComponentsPanel: React.FC<
  DeleteSavedComponentsPanelProps
> = (props) => {
  const getTranslationKey = (key: TranslationKey): string => {
    return props.translations?.[key] || defaultTranslations[key];
  };

  return (
    <MessagePanelFrame
      panelName={props.panelName}
      theme="destructive"
      title={translate(getTranslationKey('panelTitle'))}
      illustration={<baseUI.symbol name="deleteGeneralIcon_NewWorkspace" />}
      confirmLabel={translate(getTranslationKey('confirmLabel'))}
      cancelLabel={translate(getTranslationKey('cancelLabel'))}
      onConfirm={props.onConfirm}
      className="delete-saved-components-panel"
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        <p>
          {translate(getTranslationKey('paragraph1'), {
            0: props.name,
          })}
        </p>
        <p>{translate(getTranslationKey('paragraph2'))}</p>
      </Text>
    </MessagePanelFrame>
  );
};

export default workspace.isNewWorkspaceEnabled()
  ? NewWorkspaceDeleteSavedComponentsPanel
  : DeleteSavedComponentsPanel;

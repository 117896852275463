import type { OnDropCallback } from '#packages/addPanelInfra';
import type { EditorAPI } from '#packages/editorAPI';
import type { PresetSectionItem } from '@wix/add-panel-component';
import type { CompStructure } from 'types/documentServices';
import { addCompOnClick, addCompOnDrop } from './addComp';

interface AddHotelsOnClickParams {
  appDefinitionId: string;
  widgetId: string;
  compStructure: CompStructure;
  tags: string;
  itemId: string;
  sectionTitle: string;
  categoryId: string;
  biOrigin: string;
  position: { x: number; y: number } | void;
}

export const addHotelsOnClick = (
  editorAPI: EditorAPI,
  {
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    itemId,
    sectionTitle,
    categoryId,
    biOrigin,
    position,
  }: AddHotelsOnClickParams,
) => {
  return addCompOnClick(editorAPI, {
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    itemId,
    sectionTitle,
    categoryId,
    biOrigin,
    position,
  });
};

interface AddHotelsOnDropParams {
  item: PresetSectionItem;
  appDefinitionId: string;
  widgetId: string;
  biOrigin: string;
  compStructure: CompStructure;
}

export const addHotelsOnDrop = (
  editorAPI: EditorAPI,
  { appDefinitionId, widgetId, biOrigin }: AddHotelsOnDropParams,
): OnDropCallback => {
  return addCompOnDrop(editorAPI, {
    appDefinitionId,
    widgetId,
    biOrigin,
  });
};

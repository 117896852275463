// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import constants from '#packages/constants';
import pageSettingsTabMixin from './pageSettingsTabMixin';

import React from 'react';
import * as baseUI from '#packages/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import { CustomScroll } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabLayout',
  mixins: [pageSettingsTabMixin],
  propTypes: {
    menuItem: PropTypes.object.isRequired,
  },
  openLearnMore() {
    const biHelpParams = {
      panel_name: 'tab_layout',
      origin: constants.BI.HELP.ORIGIN.PAGES,
      learn_more: true,
    };
    const helpId = '97778018-fd88-4953-8be3-d994a1637882';
    this.getEditorAPI().panelManager.openHelpCenter(helpId, null, biHelpParams);
  },
  render() {
    return (
      <CustomScroll>
        <div className="layout tab-inner">
          <baseUI.tabs.holder
            valueLink={this.linkPageProperty('isLandingPage')}
            className="left radio"
          >
            <baseUI.tabs.header>
              <baseUI.tabs.label htmlFor={false}>
                <symbols.symbol name="layoutStandardSelected" />
                <symbols.symbol name="layoutStandardNotSelected" />
                <div>
                  <h3>{translate('Pages_Layouts_Standard_Title')}</h3>

                  <p>{translate('Pages_Layouts_Standard_Text')}</p>
                </div>
              </baseUI.tabs.label>
              <baseUI.tabs.label htmlFor={true}>
                <symbols.symbol name="layoutLandingSelected" />
                <symbols.symbol name="layoutLandingNotSelected" />
                <div>
                  <h3>{translate('Pages_Layouts_Landing_Title')}</h3>

                  <p>
                    {translate('Pages_Layouts_Landing_Text')}
                    <br />
                    <baseUI.button
                      label="Pages_Layouts_Landing_Learn"
                      onClick={this.openLearnMore}
                      className="btn-text"
                    />
                  </p>
                </div>
              </baseUI.tabs.label>
            </baseUI.tabs.header>
          </baseUI.tabs.holder>
        </div>
      </CustomScroll>
    );
  },
});

import type { Content } from './types';
import * as helpIds from '#packages/helpIds';
import { translate } from '#packages/i18n';
import type { SuggestionControls } from '#packages/baseUI';

const DISPLAY_NAME = 'ChangeBusinessTypePanel';

export { DISPLAY_NAME };

export const content: Content = {
  dataHook: 'change-business-type-panel-modal',
  panelTitle: 'content_injection_change_modal_title',
  inputLabel: 'content_injection_change_modal_search_label',
  inputPlaceholder: 'content_injection_change_modal_placeholder_label',
  modalSubtitleText: 'content_injection_change_modal_subtitle',
  modalSubSubtitleText: 'content_injection_change_modal_note',
  learnMoreCTA: 'content_injection_change_modal_subtitle_learn_more_cta',
  helpId: helpIds.businessTypePanel.LEARN_MORE,
  confirm: 'content_injection_change_modal_save_button',
  cancel: 'content_injection_change_modal_cancel_button',
};

export const TONE_OF_VOICE = [
  {
    type: 'button',
    value: translate('ai_text_creator_chat_refine_change_tone_casual'),
  },
  {
    type: 'button',
    value: translate('ai_text_creator_chat_refine_change_tone_professional'),
  },
  {
    type: 'button',
    value: translate('ai_text_creator_chat_refine_change_tone_enthusiastic'),
  },
  {
    type: 'button',
    value: translate('ai_text_creator_chat_refine_change_tone_confident'),
  },
  {
    type: 'button',
    value: translate('ai_text_creator_chat_refine_change_tone_funny'),
  },
  {
    type: 'button',
    value: translate('ai_text_creator_chat_header_default_tone_cta'),
  },
] as SuggestionControls;

export const FIELD_NAME_BUSINESS_TYPE = 'business type';
export const FIELD_NAME_BUSINESS_NAME = 'business name';
export const FIELD_NAME_TONE_OF_VOICE = 'tone of voice';
export const YES = 'yes';
export const NO = 'no';

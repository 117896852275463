import { selection } from '#packages/stateManagement';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const { getFocusedContainer } = selection.selectors;

export default {
  selectedBeforeDescendants(
    editorAPI: EditorAPI,
    columnsContainerPtr: CompRef,
    descendantCompPtr: CompRef,
  ) {
    return (
      editorAPI.columns.isMultiColumnsStrip(columnsContainerPtr) &&
      editorAPI.columns.isColumn(descendantCompPtr)
    );
  },

  containable: (
    editorAPI: EditorAPI,
    comp: CompRef,
    potentialParent: CompRef,
  ) => {
    const isDesktop = editorAPI.isDesktopEditor();

    if (isDesktop) return true;

    const masterPage = editorAPI.dsRead.siteSegments.getSiteStructure();

    const isSiteStructureOrChildren =
      editorAPI.utils.isSameRef(potentialParent, masterPage) ||
      editorAPI.components.isDescendantOfComp(potentialParent, masterPage);

    return !isSiteStructureOrChildren;
  },

  nestedBackgroundDesign: (
    editorAPI: EditorAPI,
    columnsContainerPtr: CompRef,
  ) => editorAPI.columns.isSingleColumnStrip(columnsContainerPtr),

  showMarginsIndicator: (
    editorAPI: EditorAPI,
    columnsContainerPtr: CompRef,
  ) => {
    const state = editorAPI.store.getState();
    const focusedContainer = getFocusedContainer(state);

    if (!focusedContainer) return;
    if (focusedContainer.id === columnsContainerPtr.id) {
      return (
        editorAPI.isDesktopEditor() &&
        editorAPI.components.is.fullWidth(focusedContainer) &&
        editorAPI.columns.isSingleColumnStrip(focusedContainer)
      );
    }
    return;
  },
  verticallyResizableByChildren: (editorAPI: EditorAPI, compRef: CompRef) => {
    const componentChildren = editorAPI.components.getChildren(compRef);

    return componentChildren.length === 1;
  },

  focusable: true,
  allowLassoOnContainer: true,
  pinnable: false,
  allowConnectToDB: true,
};

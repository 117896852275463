import { http } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';

const PREDICTOR_SERVICE_URL =
  'https://editor.wix.com/_api/predictor-service/v1/invoke/model/layout-sectiongrouping-2-744';

const getPredictionServiceUrl = () => {
  return PREDICTOR_SERVICE_URL;
};

export default async function fetchPrediction(
  metaSiteInstance: string,
  predictionInput: AnyFixMe,
) {
  const response = await http
    .fetchJson(getPredictionServiceUrl(), {
      method: 'POST',
      credentials: 'same-origin',
      headers: new Headers({
        Authorization: metaSiteInstance,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        model_id: 'layout-sectiongrouping-1-6',
        triggered_by: 'editor',
        predictionEntities: {
          uuid: {
            entity_id: '',
          },
        },
        features: predictionInput,
      }),
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ErrorReporter.captureException(error, {
        tags: {
          listClassificationFlow: true,
        },
      });
    });

  return response;
}

import type constants from '#packages/constants';
import type { Coordinates } from '@wix/stylable-panel-common';
import type { DocumentServicesObject } from 'types/documentServices';
import type { StylablePanelHost } from '@wix/stylable-panel';

export enum StylePanelPlane {
  Vertical = 'vertical',
  VerticalCollapse = 'verticalCollapse',
}

export type PopupPanelProps = Record<string, any>;
export interface PopupPanelPosition {
  top?: string;
  left?: string;
}
export type PopupPanelMap = Record<
  string,
  (panelProps: PopupPanelProps, coordinates?: Coordinates) => void
>;

export type ThemeColorPreset = ReturnType<
  DocumentServicesObject['theme']['colors']['getColorPresets']
>[0];

export type EditorPalette = Omit<
  ThemeColorPreset,
  ValueOf<typeof constants.STYLABLE.EDITOR.PALETTE_IGNORED_KEYS>
>;

export interface CompData {
  id: string;
}

export interface ForceStateSelectors {
  forceState: string;
  selectionSelector: string;
}

// TODO: Import all below from editor when more types are available
export interface MediaManagerImage {
  width: number;
  height: number;
  title: string;
  fileName: string;
  uri: string;
}

export interface MediaManagerInfo {
  path: string;
}

export interface MediaManagerOptions {
  callback(items?: MediaManagerImage[], info?: MediaManagerInfo): void;
  multiSelect?: boolean;
  path?: string;
  translation?: string;
}

export interface StyleMap {
  seoTag: string;
  label: string;
  tag: string;
  displayName: string;
  cssClass: string;
}

export interface ElementLayout {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface PanelsFlowsBIOptions {
  biPanelName?: string;
  biSubPanelName?: string;
  biOrigin?: string;
  biComponentId?: string;
  biComponentType?: string;
  biIsContainer?: boolean;
  biContainerLayoutType?: 'organizer' | 'grid' | 'stack';
}

export interface VerticalPanelOptions {
  biData: PanelsFlowsBIOptions;
  activePanelsProps?: {
    initialAppDefId?: string;
  };
}

export interface IStylablePanelHost
  extends Omit<StylablePanelHost, 'onSiteColorChange'> {
  onSiteColorChange: (option?: VerticalPanelOptions) => void;
}

import React from 'react';
import * as util from '#packages/util';
import constants from '#packages/constants';
import experiments from 'experiment';
import type { PanelData, PanelLink } from '../textAndLinkPanel';
import type {
  PublishPanelDispatchProps,
  PublishPanelStateProps,
} from './publishPanelMappers';
import { biManager } from '../../topBarComponent/mapTopBarPropsToButtons';
import {
  topBarPublishPopOverClick,
  publishViewYourSiteClick,
} from '@wix/bi-logger-editor/v2';
import { unpublishClick } from '@wix/bi-logger-editor-data/v2';

export interface PanelLinkConfig extends PanelLink {
  condition?: () => boolean;
}
export interface PublishPanelWithPanelDataProps {
  panelData: PanelData;
}

const CONTRIBUTION_ROLES_LINK_TEMPLATE =
  'https://{$lang}.wix.com/support/html5/article/roles-permissions-overview';

const PUBLISH_PANEL_VIEWS = {
  DISABLE_PUBLISH: 'DISABLE_PUBLISH',
  POST_PUBLISH: 'POST_PUBLISH',
  PRE_PUBLISH: 'PRE_PUBLISH',
};

const PUBLISH_PANEL_LINKS = {
  [PUBLISH_PANEL_VIEWS.DISABLE_PUBLISH]() {
    return [
      {
        titleKey: 'TOPBAR_SITE_PUBLISH_DISABLED_PROMOTION_LINK',
        onClick: () => {
          const url = util.languages.getLocalizedLink(
            CONTRIBUTION_ROLES_LINK_TEMPLATE,
            '{$lang}',
          );
          window.open(url);
        },
      },
    ];
  },
  [PUBLISH_PANEL_VIEWS.POST_PUBLISH](
    props: PublishPanelDispatchProps & PublishPanelStateProps,
  ) {
    return [
      {
        titleKey: 'SAVE_PUBLISH_BUTTON_VIEW',
        onClick: () => {
          window.open(props.sitePublicUrl);
          util.biLogger.report(
            topBarPublishPopOverClick({
              target: 'view_site',
            }),
          );
          util.biLogger.report(
            publishViewYourSiteClick({
              panel_name: 'publishTooltip',
            }),
          );
        },
        dataHook: 'view-site',
      },
      {
        titleKey: 'SAVE_PUBLISH_BUTTON_VIEW_MOBILE_SITE',
        onClick: () => {
          props.openPanel(constants.PANELS.PROMOTIONAL.QR_CODE_MAIN_PANEL);
          util.biLogger.report(
            topBarPublishPopOverClick({
              target: 'view_on_mobile',
            }),
          );
        },
        dataHook: 'view-on-mobile',
        condition: () => util.workspace.isNewWorkspaceEnabled(),
      },
      {
        titleKey: 'SAVE_PUBLISH_BUTTON_UNPUBLISH_SITE',
        onClick: () => {
          props.openAccountSettings('settings/website-settings');
          util.biLogger.report(
            topBarPublishPopOverClick({
              target: 'unpublish_site',
            }),
          );
          util.biLogger.report(
            unpublishClick({
              origin: 'publishTopBarTooltip',
              esi: util.editorModel.editorSessionId,
            }),
          );
        },
        dividerAbove: true,
        dataHook: 'unpublish-site',
        condition: () =>
          props.isSitePublished &&
          experiments.isOpen('se_publishPanelUnpublishButton'),
      },
    ];
  },
};

const PUBLISH_PANEL_DATA_CONFIGS = [
  {
    key: PUBLISH_PANEL_VIEWS.DISABLE_PUBLISH,
    title: 'TOPBAR_SITE_PUBLISH_DISABLED_PROMOTION_TITLE',
    body: 'TOPBAR_SITE_PUBLISH_DISABLED_PROMOTION_TEXT',
    condition: (props: PublishPanelDispatchProps & PublishPanelStateProps) =>
      !props.canUserPublish,
  },
  {
    key: PUBLISH_PANEL_VIEWS.POST_PUBLISH,
    title: 'TOPBAR_POSTPUBLISH_TOOLTIP_TITLE',
    body: 'TOPBAR_POSTPUBLISH_TOOLTIP_BODY',
    condition: (props: PublishPanelDispatchProps & PublishPanelStateProps) =>
      props.isSitePublished,
    onUnmounted: () => {
      console.log('biManager.cancelBi(Publish)');
    },
  },
  {
    key: PUBLISH_PANEL_VIEWS.PRE_PUBLISH,
    title: 'TOPBAR_PREPUBLISH_TOOLTIP_TITLE',
    body: 'TOPBAR_PREPUBLISH_TOOLTIP_BODY',
    onUnmounted: () => biManager.cancelBi('Publish'),
  },
];

export const getPublishPanelData = (
  props: PublishPanelDispatchProps & PublishPanelStateProps,
): PanelData => {
  const panelData = PUBLISH_PANEL_DATA_CONFIGS.find(
    (config) => config.condition?.(props) ?? true,
  );
  const links: PanelLinkConfig[] =
    PUBLISH_PANEL_LINKS[panelData.key]?.(props) || [];

  return {
    title: panelData.title,
    body: panelData.body,
    links: links.filter((link) => link.condition?.() ?? true),
  };
};

export function withPanelData<
  P extends PublishPanelDispatchProps & PublishPanelStateProps,
>(
  WrappedComponent: React.ComponentType<P & { panelData: PanelData }>,
): React.ComponentType<P> {
  const ComponentWithPanelData = (props: P) => (
    <WrappedComponent {...props} panelData={getPublishPanelData(props)} />
  );

  return ComponentWithPanelData;
}

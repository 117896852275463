import { addPanelUtils } from '#packages/addPanelInfra';
import constants from '#packages/constants';
import { mobile } from '#packages/stateManagement';
import { reportSuccessToBi } from '../reportToBi';
import { ErrorReporter } from '@wix/editor-error-reporter';

import type { TextUploadToStageScope } from '../textUploadToStageEntryPoint';

const { showCompPastedInMobileNotificationIfNeeded } = mobile.actions;

export const uploadTextElem = async (
  scope: TextUploadToStageScope,
  text: string,
) => {
  let textRef;
  /*
    this replace covers cases when HTML code is entered
    so HTML open and closing tag characters ('<' and '>')
    replaced with less-than and greater-than characters to prevent HTML injection
  */
  const sanitizedText = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

  const textCompStructure =
    scope.editorAPI.components.buildDefaultComponentStructure(
      constants.COMP_TYPES.TEXT,
    );

  textCompStructure.data.text = `<p class="font_8">${sanitizedText}</p>`;

  textCompStructure.layout = {
    ...textCompStructure.layout,
    width: 310,
    height: 10,
  };

  try {
    textRef = await addPanelUtils.attemptToAddComponent(
      scope.editorAPI,
      textCompStructure,
      '',
      '',
      '',
      '',
      'paste_text',
    );
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: {
        attemptToAddComponentFailed: true,
      },
    });
  }

  await scope.components.hooks.componentAddedToStage.fire({
    origin: 'paste_text',
    compRef: textRef,
    type: 'desktop',
  });

  if (scope.editorAPI.isMobileEditor()) {
    scope.editorAPI.store.dispatch(
      showCompPastedInMobileNotificationIfNeeded(),
    );
  }

  reportSuccessToBi(textRef.id, scope.components.getType(textRef));
};

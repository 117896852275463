// @ts-nocheck
import createReactClass from 'create-react-class';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import * as blogUtils from '../../utils/blogUtils';
import superApp from '../superApp';
import firstTimeTour from './firstTimeTour';
import newBlogMigrationSlide from './quickMigration/newBlogMigrationSlide';
import newBlogMagicMigrationSlide from './magicMigration/newBlogMagicMigrationSlide';
import quickMigrationSuccessSlide from './quickMigration/successSlide/quickMigrationSuccessSlide';
import quickMigrationFailureSlide from './quickMigration/failureSlide/quickMigrationFailureSlide';
import magicMigrationSuccessSlide from './magicMigration/successSlide/magicMigrationSuccessSlide';
import magicMigrationFailureSlide from './magicMigration/failureSlide/magicMigrationFailureSlide';

import React from 'react';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';

// eslint-disable-next-line react/prefer-es6-class
const BlogMenuPanel = createReactClass({
  displayName: 'BlogMenuPanel',
  mixins: [util.propTypesFilterMixin],
  getSuperApp() {
    return superApp({
      promotionalFlow: this.props.promotionalFlow,
      migrationFlow: this.props.migrationFlow,
    });
  },
  render() {
    return (
      <superAppMenuInfra.superAppPanel
        superApp={this.getSuperApp()}
        panelClass="new-blog-panel"
        {...this.filteredProps()}
      />
    );
  },
});

const mapStateToProps = ({ editorAPI, state }) => {
  const migrationStatus =
    stateManagement.blog.selectors.getBlogMigrationStatus(state);
  return {
    promotionalFlow: blogUtils.getNewBlogPromotionalFlow(editorAPI, {
      firstTimeTour,
      newBlogMigrationSlide,
      newBlogMagicMigrationSlide,
    }),
    migrationFlow: blogUtils.getMigrationFlow(
      editorAPI,
      migrationStatus,
      {
        newBlogMagicMigrationSlide,
        quickMigrationSuccessSlide,
        quickMigrationFailureSlide,
        magicMigrationSuccessSlide,
        magicMigrationFailureSlide,
      },
      true,
    ),
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(BlogMenuPanel);
ConnectedComponent.pure = BlogMenuPanel;

export default ConnectedComponent;

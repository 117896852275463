import { panels } from '#packages/stateManagement';

import type { MapStateToProps } from 'types/redux';

export interface StateProps {
  openedPanelName: string;
  openedPanelProps: AnyFixMe;
}

export interface OwnProps {}

export const mapStateToProps: MapStateToProps<StateProps, OwnProps> = ({
  state,
}) => {
  const openedPanel = panels.selectors.selectOpenRightPanels(state)?.[0];

  return {
    openedPanelName: openedPanel?.name,
    openedPanelProps: openedPanel?.props,
  };
};

import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 649,
        height: 444,
        x: 10,
        y: 4,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'bdrtv',
      },
      parent: 'comp-kguvyff4',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
        title: '',
        uri: '74f558_10aab65e4bad4b5b9a168ee1695f4e03~mv2.jpg',
        description: '',
        width: 4928,
        height: 3264,
        alt: 'aaron-huber-oMOx_wV6mLQ-unsplash.jpg',
        name: 'aaron-huber-oMOx_wV6mLQ-unsplash.jpg',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'bdrtv',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      style: 'wp2',
      mobileStructure: {
        layout: {
          width: 280,
          height: 192,
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kguvyffg',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      layout: {
        width: 649,
        height: 444,
        x: 9,
        y: 4,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'bdrtv',
      },
      parent: 'comp-kguvyff4',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
        title: '',
        uri: '74f558_55cd3117e9124d48ab78728c42a115af~mv2.jpg',
        description: '',
        width: 2800,
        height: 1869,
        alt: 'suhyeon-choi--OOiHUFNry8-unsplash_edited',
        crop: {
          x: 0,
          y: 368,
          width: 2195,
          height: 1501,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
          flip: 'y',
        },
        name: 'suhyeon-choi--OOiHUFNry8-unsplash_edited',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'bdrtv',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
      },
      scopedTransformations: {
        'variants-kguvyffe': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bdrtv',
          },
          rotate: 0,
        },
      },
      style: 'wp2',
      mobileStructure: {
        layout: {
          width: 280,
          height: 192,
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kguvyffh',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 440,
        height: 41,
        x: 30,
        y: 382,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'bdrtv',
      },
      parent: 'comp-kguvyff4',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
        text: `<h4 class="font_4" style="font-size:32px"><span style="font-size:32px"><span style="color:#FFFFFF"><span style="font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif">${translate(
          'interactions_preset_interior_design_main_title',
        )}</span></span></span></h4>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'bdrtv',
        },
        brightness: 1,
        packed: false,
        minHeight: 30,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
      },
      scopedTransformations: {
        'variants-kguvyffe': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bdrtv',
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -54,
            },
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 192,
          height: 22,
          x: 9,
          y: 152,
          scale: 0.59375,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'bdrtv',
          },
          brightness: 1,
          packed: true,
          overrideColor: '#FFFFFF',
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvyffl',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 440,
        height: 23,
        x: 30,
        y: 27,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'bdrtv',
      },
      parent: 'comp-kguvyff4',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
        text: `<h4 class="font_4" style="font-size:18px"><span style="color:rgb(255, 255, 255); font-family:helvetica-w01-bold,helvetica-w02-bold,helvetica-lt-w10-bold,sans-serif">${translate(
          'interactions_preset_interior_design_top_title',
        )}</span></h4>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'bdrtv',
        },
        brightness: 1,
        packed: false,
        minHeight: 17,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
      },
      scopedTransformations: {
        'variants-kguvyffe': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bdrtv',
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: 17,
            },
          },
        },
      },
      scopedStyles: {
        'variants-kguvyffe': {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bdrtv',
          },
          style: {
            properties: {
              f10: 'font_10',
              f0: 'font_0',
              f1: 'font_1',
              f2: 'font_2',
              f3: 'font_3',
              f4: 'font_4',
              f5: 'font_5',
              f6: 'font_6',
              f7: 'font_7',
              f8: 'font_8',
              f9: 'font_9',
              letterSpacing: '0em',
            },
            propertiesSource: {
              f10: 'theme',
              f0: 'theme',
              f1: 'theme',
              f2: 'theme',
              f3: 'theme',
              f4: 'theme',
              f5: 'theme',
              f6: 'theme',
              f7: 'theme',
              f8: 'theme',
              f9: 'theme',
              letterSpacing: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.WRichText',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 84,
          height: 12,
          x: 15,
          y: 15,
          scale: 0.5,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'bdrtv',
          },
          brightness: 1,
          packed: true,
          overrideColor: '#FFFFFF',
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvyffo',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 255,
        height: 40,
        x: 31,
        y: 384,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'bdrtv',
      },
      parent: 'comp-kguvyff4',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
        text: `<h2 class="font_2" style="font-size:16px"><span style="font-family:helvetica-w01-light,helvetica-w02-light,sans-serif"><span style="color:#FFDE5F"><span style="font-size:16px">${translate(
          'interactions_preset_interior_design_description',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'bdrtv',
        },
        brightness: 1,
        packed: false,
        minHeight: 15,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bdrtv',
        },
      },
      scopedTransformations: {
        'variants-kguvyffe': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bdrtv',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 34,
          height: 48,
          x: 9,
          y: 227,
          scale: 0.3072,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'bdrtv',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvyffr',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 665,
    height: 464,
    x: 156,
    y: 18,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'bdrtv',
  },
  parent: 'bdrtv',
  variants: {
    'variants-kguvyffe': {
      componentId: 'comp-kguvyff4',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'bdrtv',
      },
    },
  },
  scopedTransformations: {
    'variants-kguvyffe': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'bdrtv',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'bdrtv',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_15',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'theme',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 275,
      x: 20,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kguvyff4',
      author: 'studio',
    },
  },
  activeModes: {},
};

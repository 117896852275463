// @ts-nocheck
import * as util from '#packages/util';
import { createSource, Shutterstock } from '@wix/media-manager-sdk';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import * as mediaPanelConsts from '../../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import * as mediaPanelPurchaseUtils from '../../../mediaManagerPanelUtils/mediaManagerPanelPurchaseUtils';
import ItemsSection from '../itemsSection';
import { loadShutterstockPrices } from './shutterstockPricesService';

const {
  ITEMS_LOADING_OPTIONS,
  SHUTTERSTOCK_MEDIA_TYPE,
  MEDIA_MANAGER_OPEN_OPTIONS,
  MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH,
} = mediaPanelConsts;

class ShutterstockBaseMediaSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prices: null,
    };

    this.adapter = createSource(Shutterstock, {
      siteMediaToken: props.siteMediaToken,
      version: 'G6',
      country: util.editorModel.geo,
    });
  }

  componentDidMount() {
    loadShutterstockPrices(this.adapter.items.prices).then(
      ({ picture, video }) => {
        if (this.isUnmounted) {
          return;
        }

        this.setState({
          prices: {
            [SHUTTERSTOCK_MEDIA_TYPE.IMAGE]: picture,
            [SHUTTERSTOCK_MEDIA_TYPE.VIDEO]: video,
          },
        });
      },
    );
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  private isUnmounted: boolean = false;

  private getCategoryWithItemIds(fullItems: any) {
    return {
      [this.props.id]: fullItems.map(({ id }) => id),
    };
  }

  loadMedia() {
    const { mediaType, language, id } = this.props;

    if (this.props.query) {
      return this.adapter.items
        .search(id, this.props.query, {
          language,
          mediaType,
          country: util.editorModel.geo,
          filters: { pricing: 'all' },
          ...ITEMS_LOADING_OPTIONS,
        })
        .then((response) => {
          const items = response.data;
          const categoryWithItemIds = this.getCategoryWithItemIds(items);
          this.props.onMediaLoaded?.(items, categoryWithItemIds);
          return items;
        });
    }

    return this.adapter.items
      .list(`${mediaType}_any_any`, { paging: { size: 6 } })
      .then((response) => response.data);
  }

  handleMediaManagerOpen() {
    const { mediaType } = this.props;

    const { path } = this.props.query
      ? MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH[this.props.id](this.props.query)
      : MEDIA_MANAGER_OPEN_OPTIONS[this.props.id]();

    this.props.openMediaManager({
      path,
      biSource: `shutterstock-${mediaType}`,
    });
  }

  getItemPrice(item) {
    const { prices } = this.state;

    if (!prices) {
      return null;
    }

    return mediaPanelPurchaseUtils.extractPriceForShutterstockItem(
      item,
      prices,
    );
  }

  getItemsSectionProps() {
    return {
      action: () => this.handleMediaManagerOpen(),
      loadMedia: () => this.loadMedia(),
      getItemPrice: (item) => this.getItemPrice(item),
      ..._.pick(this.props, [
        'id',
        'title',
        'presetWidth',
        'shouldDisplayPresetName',
        'onItemClick',
        'automationId',
        'buyItem',
      ]),
    };
  }

  render() {
    return <ItemsSection {...this.getItemsSectionProps()} />;
  }
}

ShutterstockBaseMediaSection.propTypes = {
  title: PropTypes.string,
  mediaType: PropTypes.oneOf(Object.values(SHUTTERSTOCK_MEDIA_TYPE)),
};

export default ShutterstockBaseMediaSection;

import _ from 'lodash';
import type { TextManager } from 'types/data';
import type { Style, StyleRef } from 'types/documentServices';
import type { CompRef } from 'types/documentServices';
import type {
  NonThemedActionCreationData,
  NonThemedAction,
} from './textAction';
import type { EditorAPI } from '#packages/editorAPI';

export const createNonThemedAction = <TActionState>(
  actionCreationData: Readonly<NonThemedActionCreationData<TActionState>>,
): NonThemedAction<TActionState> => ({
  ...actionCreationData,
  type: 'nonThemed',
  getState: (
    textManager: TextManager,
    styleProperties: StyleRef,
    ignoreTextManager: boolean,
  ) => {
    const textManagerState =
      !ignoreTextManager &&
      actionCreationData.getWixRichTextCommandState(textManager);

    return (
      textManagerState ||
      actionCreationData.getPropertyValue(styleProperties.style.properties) ||
      actionCreationData.getDefaultValue()
    );
  },
});

const applyStylePropertiesInternal = (
  styleRef: StyleRef,
  newStyleProperties: Style['properties'],
) => {
  const newProperties = _.omitBy(
    {
      ...styleRef.style.properties,
      ...newStyleProperties,
    },
    (value) => value === undefined,
  );
  return {
    ...styleRef,
    style: {
      ...styleRef.style,
      properties: newProperties,
    },
  };
};

const applyStyleProperties = async (
  compRef: CompRef,
  styleProperties: StyleRef,
  neyProps: Style['properties'],
  updateStyleFunc: EditorAPI['components']['style']['update'],
) => {
  const updatedStyleRef = applyStylePropertiesInternal(
    styleProperties,
    neyProps,
  );

  updateStyleFunc(compRef, updatedStyleRef);

  return updatedStyleRef.style.properties;
};

const updateStyleOnData = <TActionState>(
  action: NonThemedActionCreationData<TActionState>,
  actionInput: TActionState,
  styleProperties: StyleRef,
  textManager: TextManager,
) => {
  if (
    (action.getPropertyValue(styleProperties.style.properties) ||
      action.getDefaultValue()) !== actionInput
  ) {
    action.execCommand(textManager, actionInput);
  } else {
    action.execRevertCommand(textManager);
  }
};

const updateStyleOnStyleProperties = <TActionState>(
  compRef: CompRef,
  action: NonThemedActionCreationData<TActionState>,
  actionInput: TActionState,
  styleProperties: StyleRef,
  updateStyleFunc: EditorAPI['components']['style']['update'],
) => {
  if (
    actionInput === action.getPropertyValue(styleProperties.style.properties)
  ) {
    return;
  }
  const stylePropertiesToUpdate =
    action.createUpdatedStyleProperties(actionInput);

  applyStyleProperties(
    compRef,
    styleProperties,
    stylePropertiesToUpdate,
    updateStyleFunc,
  );
};

const removeStyleFromData = <TActionState>(
  action: NonThemedActionCreationData<TActionState>,
  textManager: TextManager,
) => {
  if (textManager) {
    action.execRevertCommand(textManager);
    action.execRevertCommand(textManager);
  }
};

export const applyNonThemedAction = <TActionState>(
  compRef: CompRef,
  action: NonThemedActionCreationData<TActionState>,
  actionInput: TActionState,
  styleProperties: StyleRef,
  updateStyleFunc: EditorAPI['components']['style']['update'],
  textManager?: TextManager,
) => {
  if (!textManager || textManager?.isAllSelected()) {
    removeStyleFromData(action, textManager);

    updateStyleOnStyleProperties(
      compRef,
      action,
      actionInput,
      styleProperties,
      updateStyleFunc,
    );
  } else {
    updateStyleOnData(action, actionInput, styleProperties, textManager);
  }
};

import React from 'react';
import { cx } from '#packages/util';
import {
  Button,
  Composites,
  Illustration,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';

import style from './EmptyState.scss';

interface Props {
  onButtonClick: () => void;
  title: React.ReactNode;
  text: React.ReactNode;
  buttonLabel: React.ReactNode;
  illustration: React.ReactNode;
  className?: string;
}

export const EmptyState: React.FC<Props> = ({
  className,
  onButtonClick,
  title,
  text,
  buttonLabel,
  illustration,
}) => (
  <div className={cx(className, style.contentWrapper)}>
    <Composites.RichTextWithIllustrationVertical>
      <Illustration>{illustration}</Illustration>
      <Composites.RichTextLabeled>
        <TextLabel
          type="T01"
          enableEllipsis={false}
          shouldTranslate={false}
          // @ts-expect-error
          value={title}
        />
        <RichText className="light" type="T02">
          {text}
        </RichText>
      </Composites.RichTextLabeled>
    </Composites.RichTextWithIllustrationVertical>
    <Button
      className="btn-big"
      automationId="create-app-button"
      onClick={onButtonClick}
    >
      {buttonLabel}
    </Button>
  </div>
);

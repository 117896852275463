import {
  listConnections,
  enableConnection,
  disableConnection,
} from '@wix/ambassador-iam-connection-v1-connection/http';
import { brandedAppServiceGetBrandedApp } from '@wix/ambassador-mobile-v1-branded-app/http';
import type { EditorAPI } from '#packages/editorAPI';
import { HttpClient } from '@wix/http-client';
import constants from '#packages/constants';
import experiment from 'experiment';
import type { SocialLoginVendor } from '@wix/document-services-types';

export type ConnectionType = 'google' | 'facebook' | 'sso' | 'password';

const vendorAppDefIds: Record<ConnectionType, string> = {
  google: '0e6a50f5-b523-4e29-990d-f37fa2ffdd69',
  facebook: '3ecad13f-52c3-483d-911f-31dbcf2a6d23',
  sso: '4f199cbe-22ee-40c8-9129-11f2b4178a38',
  password: '31fff8fc-ea95-43ac-95c5-902497125b90',
};

export const createSocialLoginStatusApi = (editorAPI: EditorAPI) => {
  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  const httpClient = new HttpClient({
    getAppToken: () => metaSiteInstance,
  });

  const getSocialLoginStatus = async (): Promise<
    Record<ConnectionType, Boolean>
  > => {
    if (experiment.isOpen('se_useIAMForSMConnectionStorage')) {
      const connections = await httpClient.request(listConnections({}));

      return {
        google: connections.data.connections.some(
          (connection) =>
            connection.connection.appDefId === vendorAppDefIds.google &&
            connection.enabled,
        ),
        facebook: connections.data.connections.some(
          (connection) =>
            connection.connection.appDefId === vendorAppDefIds.facebook &&
            connection.enabled,
        ),
        sso: connections.data.connections.some(
          (connection) =>
            connection.connection.appDefId === vendorAppDefIds.sso &&
            connection.enabled,
        ),
        password: connections.data.connections.some(
          (connection) =>
            connection.connection.appDefId === vendorAppDefIds.password &&
            connection.enabled,
        ),
      };
    }

    return {
      google: editorAPI.siteMembers.isSocialLoginEnabled('google'),
      facebook: editorAPI.siteMembers.isSocialLoginEnabled('facebook'),
      sso: false,
      password: true,
    };
  };

  const setSocialLoginStatus = async (
    vendor: SocialLoginVendor,
    enabled: boolean,
  ): Promise<Boolean> => {
    editorAPI.siteMembers.setSocialLoginVendorStatus(vendor, enabled);

    if (experiment.isOpen('se_useIAMForSMConnectionStorage')) {
      const connectionId = await httpClient
        .request(listConnections({}))
        .then(
          (r) =>
            r.data.connections.find(
              (c) => c.connection.appDefId === vendorAppDefIds[vendor],
            )?.connection.id,
        );
      await httpClient.request(
        enabled
          ? enableConnection({
              connectionId,
            })
          : disableConnection({
              connectionId,
            }),
      );
    }

    return enabled;
  };

  const hasBrandedApp = async (): Promise<Boolean> => {
    return httpClient
      .request(brandedAppServiceGetBrandedApp({}))
      .then((r) => !!r.data.app)
      .catch(() => false);
  };

  return {
    getSocialLoginStatus,
    setSocialLoginStatus,
    hasBrandedApp,
  };
};

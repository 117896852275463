// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import * as compPanelInfra from '#packages/compPanelInfra';
import wixappsDesignSubComponentsPanel from './wixappsDesignSubComponentsPanel';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'WixappsDesignPanel',

  mixins: [core.mixins.editorAPIMixin, compPanelInfra.panelSwitcherMixin],

  getDefaultProps() {
    return {
      backButtonLabel: 'list_design_panel_back_button_label',
    };
  },

  getInitialPanelClass() {
    return wixappsDesignSubComponentsPanel;
  },
});

import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';

import type {
  StateMapperArgs,
  ThunkAction,
  Dispatch,
  MapStateToProps,
  MapDispatchToPropsFunction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  Action,
  FourthPublishSuccessPanelOwnProps,
  FourthPublishSuccessPanelStateProps,
  FourthPublishSuccessPanelDispatchProps,
} from './fourthPublishSuccessPanelTypes';
import { CONNECT_DOMAIN_4TH_PUBLISH } from './fourthPublishSuccessPanelConstants';

import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { DSRead } from 'types/documentServices';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  FourthPublishSuccessPanelStateProps,
  FourthPublishSuccessPanelOwnProps
> = ({ editorAPI }: StateMapperArgs) => {
  return {
    publicUrl: editorAPI.dsRead?.generalInfo.getPublicUrl(),
  };
};

export const mapDispatchToProps: MapDispatchToPropsFunction<
  FourthPublishSuccessPanelDispatchProps,
  FourthPublishSuccessPanelOwnProps
> = (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  const panelName = constants.PANELS.FOURTH_PUBLISH_SUCCESS_PANEL.PANEL_NAME;
  const isPremium: boolean = editorAPI.site.isPremium();
  const dsRead: DSRead = editorAPI.dsRead;
  const publicUrl = dsRead.generalInfo.getPublicUrl();
  const isDomainConnected = util.domain.isDomainConnected(dsRead, publicUrl);
  let userStatus: string;

  if (isPremium && !isDomainConnected) {
    userStatus = 'Not Connected Premium';
  } else if (!isPremium) {
    userStatus = 'free user';
  }

  const sendBI = (event: BiEventDefinition, params: BiEventFields = {}) => {
    dispatch(
      stateManagement.bi.actions.event(event, {
        panel_name: panelName,
        ...params,
      }),
    );
  };

  return {
    closePanel() {
      dispatch(stateManagement.panels.actions.closePanelByName(panelName));
    },
    openPremiumSettings: editorAPI.account.upgrade,

    panelOpenBI() {
      sendBI(coreBi.events.panels.PANEL_OPENED);
    },
    postPublishPanelOpenedWhatsNextContentBI() {
      sendBI(coreBi.events.publish.publish_success_panel_opened_with_content, {
        message: CONNECT_DOMAIN_4TH_PUBLISH,
        type: 'EDITOR',
      });
    },
    connectDomainPanelClickBI(action: Action) {
      sendBI(coreBi.events.topbar.urlBar.connect_domain_panel_action_click, {
        action,
        origin: '4th_publish',
        domain_type: 'general',
      });
    },
    secondPublishConnectDomainClickBI() {
      sendBI(coreBi.events.publish.Second_Publish_connect_domain_click, {
        message: CONNECT_DOMAIN_4TH_PUBLISH,
        user_status: userStatus,
      });
    },
    panelCloseBI() {
      sendBI(coreBi.events.panels.PANEL_CLOSED);
    },
    publishViewYourSiteClickBI() {
      sendBI(coreBi.events.publish.publish_view_your_site_click, {
        is_first_publish: false,
        user_status: userStatus,
      });
    },
  };
};

import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
} from 'types/redux';
import type {
  SiteReadinessPanelOwnProps,
  SiteReadinessPanelStateProps,
  SiteReadinessPanelDispatchProps,
} from './siteReadinessPanelTypes';
import { PANEL_NAMES } from '../../consts';
import { SiteReadinessApiKey } from '#packages/apis';

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;

const mapStateToProps: MapStateToProps<
  SiteReadinessPanelStateProps,
  SiteReadinessPanelOwnProps
> = ({ editorAPI }) => ({ editorAPI });

const mapDispatchToProps: MapDispatchToProps<
  SiteReadinessPanelDispatchProps,
  SiteReadinessPanelOwnProps
> = (dispatch, _ownProps: SiteReadinessPanelOwnProps) => {
  const editorAPI = dispatch(getEditorAPI);
  const siteReadinessApi = editorAPI.host.getAPI(SiteReadinessApiKey);

  return {
    openConnectDomain: () => {
      siteReadinessApi.reportClickInsidePanel('click_connect_custom_domain');
      const path = '/add-domain';
      editorAPI.account.openSettings({ path });
    },
    closePanel: (origin: string) => {
      editorAPI.panelManager.closePanelByName(
        PANEL_NAMES.SITE_READINESS,
        origin,
      );
    },
  };
};

export { mapStateToProps, mapDispatchToProps };

// @ts-nocheck
import React from 'react';
import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import Color from 'color';
import { Button, CustomScroll } from '@wix/wix-base-ui';
import experiment from 'experiment';
import * as core from '#packages/core';
import * as baseUI from '#packages/baseUI';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import * as panelUtils from '#packages/panelUtils';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import * as UA from '#packages/uiAnimations';
import BackgroundPresets from '#packages/backgroundPresets';
import { MediaImageStudioMode } from '@wix/media-image-studio-opener';
import { panelClick } from '@wix/bi-logger-editor/v2';
import { SelectedBackgroundPreview } from './SelectedBackgroundPreview';
import { connectBackground } from './Background.mapper';
import backgroundPresets from './backgroundPresets.json';
import { BackgroundTopActions } from './BackgroundTopActions';
import {
  PANEL_HEADER_HEIGHT,
  MAX_TILE_WIDTH,
  MAX_TILE_HEIGHT,
  AUTOMATION_IDS,
  FEDOPS,
} from './constants';

import styles from './Background.scss';

const isNewWorkspaceEnabled = util.workspace.isNewWorkspaceEnabled();

function template() {
  const { bgData: data, shouldSpinSettingsIcon } = this.props;
  const subPanel = this.getCurrentSubPanel();
  const mediaType = this.getMediaType(data.ref);
  const isAiImageExperimentOpen = experiment.isOpen('specs.mdst.AiTextToImage');
  const className = util.cx(styles['panel-container'], {
    designPanel: !this.props.isBackgroundPanel,
  });

  return (
    <div
      className={styles['bg-panel']}
      data-hook={AUTOMATION_IDS.BACKGROUND_VIEW}
    >
      <div className={className}>
        <div ref="panelContent" className={styles['panel-content']}>
          <div className={styles['bg-panel-top']}>
            <div
              onContextMenu={(e) => {
                e.preventDefault();
              }}
              className={styles['bg-panel-preview']}
            >
              <SelectedBackgroundPreview
                className={styles['bg-panel-preview-media']}
              />
              <div className={styles['bg-panel-preview-buttons']}>
                {mediaType !== this.mediaTypes.COLOR && (
                  <baseUI.tooltip
                    disabled={!!subPanel}
                    value={this.getSettingTranslation(mediaType)}
                    alignment="top"
                    key="settings-tooltip"
                    className="control-tooltip-small"
                  >
                    <div className={styles['bg-panel-preview-button']}>
                      <UA.rotatePartAnimation
                        selector=".gear-wheel"
                        play={shouldSpinSettingsIcon}
                      >
                        <baseUI.toggle
                          name="settings-icon"
                          label={this.getSettingsButtonTranslation(mediaType)}
                          valueLink={this.toggleSubPanel(mediaType)}
                          data-hook={AUTOMATION_IDS.BACKGROUND_VIEW_SETTINGS}
                          labelAfterSymbol={true}
                        />
                      </UA.rotatePartAnimation>
                    </div>
                  </baseUI.tooltip>
                )}

                {mediaType === this.mediaTypes.VIDEO && (
                  <div className={styles['bg-panel-preview-button']}>
                    <baseUI.tooltip
                      key="video-controls"
                      id="video-play-tooltip"
                      disabled={this.isPlayBtnTooltipDisabled(mediaType)}
                      alignment="top"
                      value={this.getPlayBtnTranslation()}
                      className="control-tooltip-small"
                    >
                      <baseUI.VideoPlayButton
                        key={`play_${this.isVideoEnded()}`}
                        onPlay={this.previewVideo}
                        onStop={this.pauseVideo}
                        isInProcess={this.isVideoInProcess()}
                        disabled={this.isVideoInProcess()}
                      />
                    </baseUI.tooltip>
                  </div>
                )}

                {mediaType === this.mediaTypes.IMAGE && (
                  <>
                    <div
                      key="image-adjust-button"
                      onClick={this.adjustPhoto}
                      className={util.cx(
                        styles['bg-panel-preview-button'],
                        styles['adjust-image-control'],
                      )}
                    >
                      <baseUI.symbol name="adjust" />
                    </div>
                    <div
                      onClick={(evt) => {
                        this.replaceColor(evt, data);
                      }}
                      style={this.getCurrentColor(data)}
                      key="bg-fold"
                      className={styles['bg-panel-preview-color']}
                    >
                      <baseUI.symbol name="fold-clipart" />
                    </div>
                  </>
                )}
              </div>
            </div>

            <BackgroundTopActions
              onChangeColorClick={(evt) => {
                const editorAPI = this.getEditorAPI();
                editorAPI.bi.event(coreBi.events.panels.PANEL_CLICK, {
                  action_name: 'color',
                  panel_name: this.constructor.displayName,
                });

                this.changeColor(evt, data);
              }}
              onChangeImageClick={() => {
                const editorAPI = this.getEditorAPI();
                editorAPI.bi.event(coreBi.events.panels.PANEL_CLICK, {
                  action_name: 'image',
                  panel_name: this.constructor.displayName,
                });

                this.changeMedia(this.mediaTypes.IMAGE);
              }}
              onChangeVideoClick={() => {
                const editorAPI = this.getEditorAPI();
                editorAPI.bi.event(coreBi.events.panels.PANEL_CLICK, {
                  action_name: 'video',
                  panel_name: this.constructor.displayName,
                });

                this.changeMedia(this.mediaTypes.VIDEO);
              }}
            />
          </div>

          {isAiImageExperimentOpen ? (
            <baseUI.AiImageCreatorEntryPoint
              onClick={this.handleAIImageCreatorClick}
            />
          ) : null}

          <CustomScroll>
            <div className={styles['bg-panel-preset-list']}>
              <div
                data-tooltip-bounds="true"
                className={`${styles['bg-panel-preset-list-sections']} sections`}
              >
                <BackgroundPresets {...this.getPresetsProps()} />
              </div>
            </div>
          </CustomScroll>

          <div className={styles['bg-panel-bottom']}>
            {isNewWorkspaceEnabled ? (
              <Button
                onClick={() => {
                  this.handleApplyToOtherClick();
                }}
                disabled={this.isOnePager()}
              >
                {translate('BGPP_PANEL_APPLY_TO_OTHER_BUTTON')}
              </Button>
            ) : (
              <baseUI.button2
                label="BGPP_PANEL_APPLY_TO_OTHER_BUTTON"
                onClick={() => {
                  this.handleApplyToOtherClick();
                }}
                disabled={this.isOnePager()}
                className="default"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const { getPreviewMode } = stateManagement.preview.selectors;

const {
  imageTransform,
  guidUtils,
  blockOuterScrollMixin,
  backgroundUtils,
  fedopsLogger,
} = util;
const { leftBar: leftBarStore, background } = stateManagement;
const { updatePageBackground } = background.actions;
const { getDeviceType } = leftBarStore.selectors;
const {
  biEvents,
  getColorValue,
  getVideoBackgroundObject,
  getImageBackgroundObject,
  getImageBackgroundObjectFromMediaStudioFile,
} = backgroundUtils;
const {
  MEDIA_TYPES: mediaTypes,
  PLAYBACK_STATES,
  VIDEO_READY_STATES,
  NORMALIZED_PLAYING_STATES,
} = constants;
const pageBackgroundMediaSource = {
  Image: 'background_image',
  WixVideo: 'background_video',
};

export const subPanels = {
  IMAGE_SETTINGS: 'rEditor.panels.bgImageSettingsPanel',
  VIDEO_SETTINGS: 'rEditor.panels.bgVideoSettingsPanel',
  APPLY: 'rEditor.panels.backgroundApplyPanel',
};

const undoOrigins = {
  APPLY_PANEL: 'applyPanel',
  IMAGE_PANEL: 'imagePanel',
  VIDEO_PANEL: 'videoPanel',
  PRESET: 'preset',
  MEDIA: 'media',
  REMOVE: 'remove',
  COLOR: 'color',
};

const biOrigins = {
  BG_PRESETS: 'bg_presets',
  BG_COLOR_PICKER: 'bg_color_picker',
  BG_MEDIA_MANAGER_WIXVIDEO: 'bg_media_manager_video',
  BG_MEDIA_MANAGER_IMAGE: 'bg_media_manager_image',
  BG_IMAGE_SETTINGS: 'bg_image_settings',
  BG_VIDEO_SETTINGS: 'bg_video_settings',
};

const SettingsButtonTranslation = {
  Image: 'BGPP_PANEL_IMAGE_SETTINGS_BUTTON',
  WixVideo: 'BGPP_PANEL_VIDEO_SETTINGS_BUTTON',
  //slideshow: 'BGPP_PANEL_SLIDESHOW_SETTINGS_BUTTON'
};

const BG_VIDEO_COMP_REF = { id: 'SITE_BACKGROUND' };

const MEDIA_STUDIO_INITIATOR = 'editor-image-settings';
const BI_ACTION_NAME_ADJUST = 'adjust';
const BI_ACTION_NAME_AICREATOR = 'create_image_with_AI';

const { setSessionUserPreferences } = stateManagement.userPreferences.actions;
const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;

function handleSubPanelsBiEvents(subPanel, editorAPI, biParams) {
  if (subPanel === subPanels.APPLY) {
    biEvents.applyToOtherClick(
      editorAPI,
      coreBi.events.backgroundPanel.panel_apply_to_other_pages_click,
    );
  } else {
    biParams.origin =
      biOrigins[`BG_${_.invert(subPanels)[subPanel].toUpperCase()}`];
  }
}

function setPathToUserPref(api, mediaType, info) {
  const pageId = api.dsRead.pages.getPrimaryPageId();
  api.store.dispatch(
    setSessionUserPreferences(`bg_panel_last_media_path_${pageId}`, {
      type: mediaType,
      path: info.path,
    }),
  );
}

function getPathFromUserPref(api) {
  const pageId = api.dsRead.pages.getPrimaryPageId();
  return (
    getSessionUserPreferences(`bg_panel_last_media_path_${pageId}`)(
      api.store.getState(),
    ) || {}
  );
}

function isHomePage(editorAPI, pageId) {
  return editorAPI.homePage.get() === pageId;
}

/**
 * Saving homepage data has quirks, it has a duplicated data item on both page and masterPage structures.
 * Basically - We need to trick the server to save the data on both page and masterPage structures
 * or we get data divergence between to dataItems with the same id but different contents.
 * see https://jira.wixpress.com/browse/SE-6816
 * @param editorAPI
 * @param data
 * @param pageId
 */
function getNewIdIfHomePage(editorAPI, data, pageId) {
  if (isHomePage(editorAPI, pageId) && !data.ref.mediaRef) {
    return `${guidUtils.getUniqueId(pageId, '_')}_bg`;
  }
  return data.ref.id;
}

function unmarkDataChange(editorAPI, mediaType) {
  const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
  const prop =
    mediaType === mediaTypes.VIDEO
      ? 'bg_video_settings_data_changed_'
      : 'bg_image_settings_data_changed_';

  editorAPI.store.dispatch(setSessionUserPreferences(prop + pageId, false));
  editorAPI.store.dispatch(
    setSessionUserPreferences(
      `bg_video_settings_pattern_state_${pageId}`,
      false,
    ),
  );
}

function setVideoTypeDefaults(bgData, api) {
  //force 'fixed' scroll type
  bgData.ref.scrollType = 'fixed';
  const pageId = api.dsRead.pages.getPrimaryPageId();
  const videoSettingsDataChange = getSessionUserPreferences(
    `bg_video_settings_data_changed_${pageId}`,
  )(api.store.getState());
  if (!videoSettingsDataChange) {
    backgroundUtils.clearOverlayData(bgData);
  }
}

function setImageTypeDefaults(mediaObj, bgData, api) {
  const pageId = api.dsRead.pages.getPrimaryPageId();
  const imageSettingsDataChange = getSessionUserPreferences(
    `bg_image_settings_data_changed_${pageId}`,
  )(api.store.getState());
  if (imageSettingsDataChange) {
    mediaObj.opacity = bgData.ref.mediaRef?.opacity || 1;
  }
  backgroundUtils.clearOverlayData(bgData);
}

function getVideoReadyStates() {
  return VIDEO_READY_STATES;
}

// eslint-disable-next-line react/prefer-es6-class
const BackgroundComponent = createReactClass({
  displayName: 'backgroundPanel',
  mixins: [
    core.mixins.editorAPIMixin,
    blockOuterScrollMixin,
    panelUtils.linkColorPickerMixin,
  ],

  getInitialState() {
    this.videoReadyStates = getVideoReadyStates();
    this.biEventsParams = {
      origin: null,
      presetIndex: null,
      isAiGenerator: false,
    };
    return {
      playbackState: 'waiting',
      isVideoPlaying: false,
      videoReadyState: this.videoReadyStates.IDLE,
    };
  },

  componentDidMount() {
    this.panelBoundingRect = ReactDOM.findDOMNode(
      this.refs.panelContent,
    ).getBoundingClientRect();
    this.registerPlayerState();
    this.markMobileDataDuplications();
    if (!this.props.isBackgroundPanel) {
      fedopsLogger.interactionEnded(FEDOPS.DESIGN_PANEL_BG_OPEN);
    }
  },

  componentDidUpdate() {
    if (this.getVideoReadyState() === this.videoReadyStates.IDLE) {
      baseUI.tooltipManager.hide('video-play-tooltip');
    }
  },

  componentWillUnmount() {
    this.closeSubPanel(this.getCurrentSubPanel());
    this.unregisterPlayerState();
    this.pauseVideo();
    const bgData = this.props.bgData.ref;
    this.biEventsParams.componentId = 'SITE_BACKGROUND';
    biEvents.changedBackgroundBiEvent(
      this.getEditorAPI(),
      coreBi.events.backgroundPanel.panel_background_changed,
      bgData,
      this.biEventsParams,
    );
  },

  mediaTypes,
  getMediaType: backgroundUtils.getMediaType,
  subPanels,
  videoReadyStates: null,
  panelBoundingRect: {},

  registerPlayerState() {
    const editorAPI = this.getEditorAPI();
    editorAPI.dsActions.media.mediaState.register(
      'bg_panel',
      BG_VIDEO_COMP_REF,
      this.handlePlayerStateChange,
    );
  },

  unregisterPlayerState() {
    const editorAPI = this.getEditorAPI();
    editorAPI.dsActions.media.mediaState.unregister(
      'bg_panel',
      BG_VIDEO_COMP_REF,
    );
  },

  getNormalizePlayState(playbackState) {
    const playing = NORMALIZED_PLAYING_STATES.includes(playbackState);
    return playing ? PLAYBACK_STATES.PLAYING : playbackState;
  },

  handlePlayerStateChange(state) {
    const playbackState = this.getNormalizePlayState(state.playbackState);
    if (
      state.playbackState !== this.state.playbackState ||
      state.mediaReadyState !== this.state.readyState
    ) {
      this.setState({
        playbackState,
        readyState: state.mediaReadyState,
      });
    }
  },

  isVideoInProcess() {
    return this.getVideoReadyState() === this.videoReadyStates.IN_PROCESS;
  },

  isVideoEnded() {
    return this.state.playbackState === PLAYBACK_STATES.ENDED;
  },

  previewVideo() {
    const editorAPI = this.getEditorAPI();
    editorAPI.components.behaviors.execute(BG_VIDEO_COMP_REF, 'play');
  },

  pauseVideo() {
    const editorAPI = this.getEditorAPI();
    const state = editorAPI.store.getState();
    //pause video only in editor state
    if (!getPreviewMode(state)) {
      editorAPI.components.behaviors.execute(BG_VIDEO_COMP_REF, 'stopPreview');
    }
  },

  isVideoPlaying() {
    return this.state.playbackState === 'playing';
  },

  getVideoReadyState() {
    return this.state.videoReadyState;
  },

  playingStatusChanged() {
    this.forceUpdate();
  },

  // TODO - Tom B. 22/01/2017: fix after GA
  videoReadyStateChanged() {
    this.forceUpdate();
  },

  linkedBackgroundColor(data, base, key) {
    const value = base[key].replace(/[\{\}]/g, '');
    return {
      value,
      requestChange: function (val) {
        base[key] = /color_/.test(val) ? `{${val}}` : val;
        this.biEventsParams.origin = biOrigins.BG_COLOR_PICKER;
        this.onRequestChange(data); //No undo management - color picker manages its own history.
      }.bind(this),
    };
  },

  getCurrentSubPanel() {
    const openPanels = this.getEditorAPI().getOpenPanels();
    const subPanel = openPanels?.find(({ name }) => !!subPanels[name]);
    return subPanel?.name;
  },

  closeSubPanel(subPanel) {
    const editorAPI = this.getEditorAPI();
    if (subPanel) {
      editorAPI.panelManager.closePanelByName(subPanel);
    }
  },

  getData(device) {
    if (device === 'mobile') {
      return this.props.mobileBgData;
    }

    return this.props.bgData;
  },

  /**
   *
   * @param {Object} backgroundData,  new Background data
   * @param {Object} origin , which control or child panel is the callee
   * @param {String} [pageId] for "apply to other pages"
   */
  onRequestChange(backgroundData, origin, pageId) {
    const editorAPI = this.getEditorAPI();
    const state = editorAPI.store.getState();
    const device = getDeviceType(state);
    pageId = pageId || editorAPI.pages.getPrimaryPageId();

    // TODO: Mutating backgroundData here...
    backgroundData.ref.id = getNewIdIfHomePage(
      editorAPI,
      backgroundData,
      pageId,
    );
    editorAPI.store.dispatch(
      updatePageBackground(device, backgroundData, pageId),
    );
    this.undoHandler(origin);

    // pause video and force play button
    if (this.state.isVideoPlaying) {
      this.pauseVideo();
      this.setState({ isVideoPlaying: false });
    }
  },

  undoHandler(origin) {
    const api = this.getEditorAPI();
    const origins = Object.values(undoOrigins);
    if (origins.includes(origin)) {
      api.history.add(`background panel - ${origin}`);
    }
  },

  toggleSubPanel(mediaType) {
    const currentSubPanel = this.getCurrentSubPanel();
    return {
      value: currentSubPanel && currentSubPanel !== this.subPanels.APPLY,
      requestChange: function () {
        const subPanel =
          mediaType === mediaTypes.VIDEO
            ? this.subPanels.VIDEO_SETTINGS
            : this.subPanels.IMAGE_SETTINGS;
        this.toggleSubPanelChange(subPanel);
      }.bind(this),
    };
  },

  toggleSubPanelChange(subPanel) {
    const currentSubPanel = this.getCurrentSubPanel();
    if (subPanel === currentSubPanel) {
      this.closeSubPanel(subPanel);
    } else {
      this.closeSubPanel(currentSubPanel);
      this.openSubPanel(subPanel);
    }
  },

  openSubPanel(subPanel) {
    const editorAPI = this.getEditorAPI();
    handleSubPanelsBiEvents(subPanel, editorAPI, this.biEventsParams);
    const panelProps = this.getSubPanelProps(subPanel);
    editorAPI.panelManager.openPanel(subPanel, panelProps, true);
  },

  getSubPanelProps(panelName) {
    const panelBoundingRect = ReactDOM.findDOMNode(
      this.refs.panelContent,
    ).getBoundingClientRect();
    return {
      requestChange: this.onRequestChange,
      undoHandler: this.undoHandler,
      undoOrigins,
      getData: this.getData,
      panelName,
      contentStyle: {
        top: `${panelBoundingRect.top - PANEL_HEADER_HEIGHT}px`,
        left: `${panelBoundingRect.left - window.pageXOffset + 362}px`,
        width: '290px',
      },
    };
  },

  getCurrentColor(data) {
    return {
      backgroundColor: getColorValue(this.getEditorAPI(), data.ref.color),
    };
  },

  replaceColor(event, bgData) {
    this.setColorPicker(
      event,
      bgData,
      this.colorPickerPanelHandleReplace,
      true,
    );
  },

  changeColor(event, bgData) {
    const previewOnHover = !bgData.ref.mediaRef;
    if (this.props.isBackgroundPanel) {
      const pageId = this.getEditorAPI().dsRead.pages.getFocusedPageId();
      this.props.sendBi(coreBi.events.backgroundPanel.customize_buttons_click, {
        page_id: pageId,
        button_name: 'color',
      });
    }
    this.setColorPicker(
      event,
      bgData,
      this.colorPickerPanelHandleChange,
      previewOnHover,
    );
  },

  setColorPicker(event, bgData, handler, previewOnHover) {
    const value = previewOnHover
      ? bgData.ref.color?.replace(/[\[\{\}\]]/g, '')
      : 'noColor';
    const position = { top: event.clientY, left: event.clientX };

    this.openColorPicker(
      handler,
      value,
      true,
      position,
      previewOnHover,
      true,
      undefined,
      this.props.isBackgroundPanel ? 'background_panel' : undefined,
    );
  },

  colorPickerPanelHandleReplace(val) {
    this.closeSubPanel(this.getCurrentSubPanel());
    const { bgData } = this.props;
    if (/color_/.test(val)) {
      bgData.ref.color = `{${val}}`;
    } else if (/rgba/.test(val)) {
      // Fix old sites with rgba(0,0,0,0) as bg color
      bgData.ref.color = new Color(val).hex();
    } else {
      bgData.ref.color = val;
    }
    this.biEventsParams.origin = biOrigins.BG_COLOR_PICKER;
    this.biEventsParams.isAiGenerator = false;
    this.onRequestChange(bgData);
  },

  colorPickerPanelHandleChange(val) {
    this.closeSubPanel(this.getCurrentSubPanel());
    const { bgData } = this.props;

    if (bgData.ref.mediaRef) {
      bgData.ref.mediaRef = null;
      unmarkDataChange(this.getEditorAPI(), mediaTypes.VIDEO);
      unmarkDataChange(this.getEditorAPI(), mediaTypes.IMAGE);
      backgroundUtils.clearOverlayData(bgData);
      this.undoHandler(undoOrigins.REMOVE);
    }
    if (/color_/.test(val)) {
      bgData.ref.color = `{${val}}`;
    } else if (/rgba/.test(val)) {
      // Fix old sites with rgba(0,0,0,0) as bg color
      bgData.ref.color = new Color(val).hex();
    } else {
      bgData.ref.color = val;
    }

    this.biEventsParams.origin = biOrigins.BG_COLOR_PICKER;
    this.biEventsParams.isAiGenerator = false;
    this.onRequestChange(bgData);
  },

  getFileId(mediaRef) {
    if (mediaRef.type === mediaTypes.IMAGE) {
      return mediaRef.uri;
    }

    if (mediaRef.type === mediaTypes.VIDEO) {
      return mediaRef.videoId;
    }

    return null;
  },

  logPresetSelected(preset) {
    const { focusedPageComponentType } = this.props;
    const { mediaRef } = preset.ref;

    this.props.sendBi(
      { evid: 93 },
      {
        fileId: this.getFileId(mediaRef),
        mediaType: mediaRef.type,
        component_type: focusedPageComponentType,
      },
    );
  },

  selectPreset(preset, presetKey, presetIndex) {
    const api = this.getEditorAPI();
    baseUI.tooltipManager.hideAll();
    this.closeSubPanel(this.getCurrentSubPanel());

    const { ref, ...bgDataWithoutRef } = this.props.bgData;
    Object.assign(preset, bgDataWithoutRef);

    if (preset.path) {
      setPathToUserPref(api, this.getMediaType(preset.ref), {
        path: preset.path,
      });
    }
    delete preset.path;
    delete preset.presetIndex;
    unmarkDataChange(api, mediaTypes.VIDEO);
    unmarkDataChange(api, mediaTypes.IMAGE);
    this.biEventsParams = {
      presetIndex,
      origin: biOrigins.BG_PRESETS,
      isAiGenerator: false,
    };
    this.onRequestChange(preset, undoOrigins.PRESET);
    this.logPresetSelected(preset);
  },

  onMediaManagerCallback(mediaType, payload, info) {
    if (!payload) {
      return;
    }
    let mediaObj;
    let imageData;
    const api = this.getEditorAPI();
    const { bgData } = this.props;
    setPathToUserPref(api, mediaType, info);

    if (mediaType === mediaTypes.VIDEO) {
      mediaObj = getVideoBackgroundObject(_.head(payload), info);
      setVideoTypeDefaults(bgData, api);
      unmarkDataChange(api, mediaTypes.IMAGE);
      imageData = mediaObj.posterImageRef;
      //force 'fixed' scroll type
      bgData.ref.scrollType = 'fixed';
    } else if (mediaType === mediaTypes.IMAGE) {
      mediaObj = getImageBackgroundObject(_.head(payload), info);
      setImageTypeDefaults(mediaObj, bgData, api);
      unmarkDataChange(api, mediaTypes.VIDEO);
      imageData = mediaObj;
    }

    //set default fitting and alignment
    const isSmaller = backgroundUtils.isSmallerFromContainer(
      imageData.width,
      imageData.height,
      MAX_TILE_WIDTH,
      MAX_TILE_HEIGHT,
    );
    let fittingType;
    if (mediaType === mediaTypes.IMAGE && isSmaller) {
      fittingType = imageTransform.fittingTypes.LEGACY_BG_FIT_AND_TILE;
    } else {
      fittingType = imageTransform.fittingTypes.SCALE_TO_FILL;
    }
    bgData.ref.fittingType = fittingType;
    bgData.ref.alignType = imageTransform.alignTypes.CENTER;

    //set new image
    bgData.ref.mediaRef = mediaObj;

    this.biEventsParams.origin =
      biOrigins[`BG_MEDIA_MANAGER_${mediaType.toUpperCase()}`];
    this.biEventsParams.isAiGenerator = false;

    this.onRequestChange(bgData, undoOrigins.MEDIA);
  },

  openMediaManager(mediaType, callback) {
    if (!mediaType) {
      return;
    }
    const api = this.getEditorAPI();
    const { mediaServices } = api;
    const { mediaManager } = mediaServices;
    const openSource = pageBackgroundMediaSource[mediaType];
    let category;

    const lastMediaPath = getPathFromUserPref(api);
    const path =
      lastMediaPath.type === mediaType ? lastMediaPath.path || '' : '';

    if (mediaType === mediaTypes.IMAGE) {
      category = mediaManager.categories.BG_IMAGE;
    } else if (mediaType === mediaTypes.VIDEO) {
      category = mediaManager.categories.BG_VIDEO;
    }

    this.closeSubPanel(this.getCurrentSubPanel());
    mediaManager.open(category, openSource, {
      callback: (items, info) => {
        callback(items, info);
        mediaServices.setFocusToEditor();
      },
      path,
      translation: {
        submitButton: translate('MMGR_submitbutton_background_uploads_change'),
      },
    });
  },

  changeMedia(mediaType) {
    if (this.props.isBackgroundPanel) {
      const pageId = this.getEditorAPI().dsRead.pages.getFocusedPageId();
      const buttonName =
        mediaType === this.mediaTypes.IMAGE ? 'image' : 'video';
      this.props.sendBi(coreBi.events.backgroundPanel.customize_buttons_click, {
        page_id: pageId,
        button_name: buttonName,
      });
    }
    this.openMediaManager(
      mediaType,
      this.onMediaManagerCallback.bind(this, mediaType),
    );
  },

  closePanel() {
    this.getEditorAPI().panelManager.closePanel(this.props.panelName);
  },

  getSettingsButtonTranslation(mediaType) {
    return SettingsButtonTranslation[mediaType];
  },

  handleApplyToOtherClick() {
    if (!this.isOnePager()) {
      this.toggleSubPanelChange(this.subPanels.APPLY);
    }
  },

  isOnePager() {
    return this.getEditorAPI().dsRead.pages.getPageIdList().length === 1;
  },

  markMobileDataDuplications() {
    const api = this.getEditorAPI();
    const currentMobileDataId = this.props.mobileBgData.ref.id;

    const mobileDataDuplication = getSessionUserPreferences(
      `bg_panel_mobile_data_duplications_${currentMobileDataId}`,
    )(api.store.getState());
    if (mobileDataDuplication) {
      return;
    }
    const currentPageId = api.dsRead.pages.getPrimaryPageId();
    let countDuplications = 0;
    const pages = api.dsRead.pages.getPagesData();
    for (let i = 0; i < pages.length; i++) {
      if (
        pages[i].id !== currentPageId &&
        pages[i]?.pageBackgrounds?.mobile?.ref?.id === currentMobileDataId
      ) {
        countDuplications++;
      }
    }

    api.store.dispatch(
      setSessionUserPreferences(
        `bg_panel_mobile_data_duplications_${currentMobileDataId}`,
        { count: countDuplications },
      ),
    );
    if (countDuplications) {
      biEvents.mobileDataDuplicationError(
        this.getEditorAPI(),
        coreBi.errors.BG_MOBILE_DATA_DUPLICATION_ERROR,
      );
    }
  },

  isPlayBtnTooltipDisabled() {
    return this.getVideoReadyState() === this.videoReadyStates.IDLE;
  },

  getPlayBtnTranslation() {
    let tr = '';
    const videoReadyState = this.getVideoReadyState();
    if (videoReadyState === this.videoReadyStates.PLAYING_PREVIEW) {
      tr = 'BGPP_PANEL_VIDEO_PLAYBACK_PREVIEW_READY_TOOLTIP';
    } else if (videoReadyState === this.videoReadyStates.IN_PROCESS) {
      tr = 'BGPP_PANEL_VIDEO_PLAYBACK_NOT_READY_TOOLTIP';
    }
    return tr;
  },

  getSettingTranslation(mediaType) {
    let tr = '';
    if (mediaType === mediaTypes.IMAGE) {
      tr = 'BGPP_PANEL_IMAGE_SETTINGS_BUTTON_TOOLTIP';
    } else if (mediaType === mediaTypes.VIDEO) {
      tr = 'BGPP_PANEL_VIDEO_SETTINGS_BUTTON_TOOLTIP';
    }
    return tr;
  },

  getPresetsProps() {
    return {
      desktopPresets: backgroundPresets,
      mobilePresets: backgroundPresets,
      title: translate('BGPP_PANEL_SELECTED_SECTION_TITLE'),
      infoTooltip: translate('BGPP_PANEL_SELECTED_SECTION_TOOLTIP'),
      onSelectPreset: this.selectPreset,
      columns: 4, // Special site background override
    };
  },

  onMediaStudioCallback(payload, isAiGenerator = false) {
    const { bgData } = this.props;
    const api = this.getEditorAPI();
    const imageData = getImageBackgroundObjectFromMediaStudioFile(payload);
    setImageTypeDefaults(imageData, bgData, api);
    unmarkDataChange(api, mediaTypes.VIDEO);

    //set default fitting and alignment
    const isSmaller = backgroundUtils.isSmallerFromContainer(
      imageData.width,
      imageData.height,
      MAX_TILE_WIDTH,
      MAX_TILE_HEIGHT,
    );
    bgData.ref.fittingType = isSmaller
      ? imageTransform.fittingTypes.LEGACY_BG_FIT_AND_TILE
      : imageTransform.fittingTypes.SCALE_TO_FILL;
    bgData.ref.alignType = imageTransform.alignTypes.CENTER;

    //set new image
    bgData.ref.mediaRef = imageData;

    this.biEventsParams.origin =
      biOrigins[`BG_MEDIA_MANAGER_${mediaTypes.IMAGE.toUpperCase()}`];
    this.biEventsParams.isAiGenerator = isAiGenerator;

    this.onRequestChange(bgData, undoOrigins.MEDIA);

    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MEDIA_SERVICES.MEDIA_STUDIO
        .ADJUST_BACKGROUND_IMAGE,
    );
  },

  openMediaStudio(callback) {
    const editorAPI = this.getEditorAPI();
    const { bgData } = this.props;

    if (
      !bgData.ref.mediaRef ||
      this.getMediaType(bgData.ref) !== mediaTypes.IMAGE
    ) {
      return;
    }

    const imageData = bgData.ref.mediaRef;

    editorAPI.mediaServices.openMediaStudio(
      {
        initiator: MEDIA_STUDIO_INITIATOR,
        imageFileId: imageData.uri,
      },
      callback,
    );
  },

  adjustPhoto() {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MEDIA_SERVICES.MEDIA_STUDIO
        .ADJUST_BACKGROUND_IMAGE,
    );

    const editorAPI = this.getEditorAPI();

    editorAPI.bi.event(coreBi.events.panels.PANEL_CLICK, {
      action_name: BI_ACTION_NAME_ADJUST,
      component_id: 'SITE_BACKGROUND',
      component_type: 'SiteBackground',
      panel_name: this.constructor.displayName,
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
      esi: util.editorModel.editorSessionId,
    });
    this.openMediaStudio(this.onMediaStudioCallback);
  },

  handleAIImageCreatorClick() {
    const editorAPI = this.getEditorAPI();

    util.biLogger.report(
      panelClick({
        action_name: BI_ACTION_NAME_AICREATOR,
        component_id: 'SITE_BACKGROUND',
        component_type: 'SiteBackground',
        panel_name: this.constructor.displayName,
        site_id: editorAPI.dsRead.generalInfo.getSiteId(),
        esi: util.editorModel.editorSessionId,
        origin: this.biEventsParams.origin,
      }),
    );

    editorAPI.mediaServices.openMediaStudio(
      {
        isUpload: false,
        mode: MediaImageStudioMode.Generate,
        initiator: MEDIA_STUDIO_INITIATOR,
      },
      (payload) => {
        this.onMediaStudioCallback(payload, true);
      },
    );
  },

  render() {
    return template.apply(this);
  },
});

export const Background = connectBackground(BackgroundComponent);
Background.pure = BackgroundComponent;

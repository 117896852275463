import React from 'react';
import { hoc } from '#packages/util';
import constants from '#packages/constants';
import * as interactions from '#packages/interactions';
import type { CompRef } from 'types/documentServices';

import { mapStateToProps } from './interactionContainerOutlineMapper';
import type { InteractionDef } from '#packages/stateManagement';
const { InteractionNavControls } = interactions.controls;

interface DimensionAndPosition {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface InteractionContainerOutlineProps {
  containerWithInteraction: CompRef;
  isLabelBottom: boolean;
  compInteraction: InteractionDef;
  componentUIColor: string;
  selectTrigger: () => void;
  outlineStyles: DimensionAndPosition;
}

class InteractionContainerOutline extends React.Component<InteractionContainerOutlineProps> {
  static displayName = 'hoverBox';

  render() {
    return (
      <div
        style={this.props.outlineStyles}
        className={`interactions-container-outline${
          this.props.componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE
            ? ' components-ui-color-orange'
            : ''
        }`}
      >
        <div className="container-outline-nav-wrapper">
          <InteractionNavControls
            componentUIColor={this.props.componentUIColor}
            compRef={this.props.containerWithInteraction}
            considerCompControlsPosition={false}
            handleDragMouseDown={() => {}}
            compInteraction={this.props.compInteraction}
          />
        </div>
      </div>
    );
  }
}

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(InteractionContainerOutline);

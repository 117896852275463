import React from 'react';
import _ from 'lodash';

import type { SendBiFunction } from 'types/bi';

import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import * as helpIds from '#packages/helpIds';
import * as util from '#packages/util';

import connect from './concurrentUserPanelConnect';
import {
  ConcurrentUserPanelFrame,
  NewWorkspaceConcurrentUserPanelFrame,
} from './concurrentUserPanelFrame';

const PANEL_STATIC_PROPS = {
  newWorkspace: {
    theme: 'destructive' as const,
    title: 'Continuous_Save_Concurrent_Editing_User2_Header_Title',
    symbolName: 'concurrentUsersPopupsRed_NewWorkspace',
    description: [
      'Continuous_Save_Concurrent_Editing_User2_Body_1',
      'Continuous_Save_Concurrent_Editing_User2_Body_2',
    ],
    cancelLabel: 'Continuous_Save_Concurrent_Editing_User2_Secondary_CTA',
    confirmLabel: 'Continuous_Save_Concurrent_Editing_User2_Primary_CTA',
    footnote: 'Continuous_Save_Concurrent_Editing_User2_Note',
    hideCloseButton: true,
    cancelOnEscPress: false,
    helpId: helpIds.SAVE_PUBLISH.MULTIPLE_USERS_EDITING,
  },
  default: {
    type: 'important',
    title: 'Continuous_Save_Concurrent_Editing_User2_Header_Title',
    symbolName: 'concurrentUsersPopupsRed',
    description: [
      'Continuous_Save_Concurrent_Editing_User2_Body_1',
      'Continuous_Save_Concurrent_Editing_User2_Body_2',
    ],
    cancelLabel: 'Continuous_Save_Concurrent_Editing_User2_Secondary_CTA',
    confirmLabel: 'Continuous_Save_Concurrent_Editing_User2_Primary_CTA',
    footnote: 'Continuous_Save_Concurrent_Editing_User2_Note',
    hideCloseButton: true,
    cancelOnEscPress: false,
    helpId: helpIds.SAVE_PUBLISH.MULTIPLE_USERS_EDITING,
  },
};

let isPanelShownFirstTime = 0;

export interface ConcurrentUserPanelProps {
  panelName: string;
  isFirstUser?: boolean;
  concurrentUsers: string[];
  concurrentUserToDisplay?: string;
  openHelpCenter: (helpId: string) => void;
  closePanelByName: (panelName: string) => void;
  saveSite: () => void;
  sendBi: SendBiFunction;
}

export class ConcurrentUserPanel extends React.Component<ConcurrentUserPanelProps> {
  componentDidMount() {
    if (isPanelShownFirstTime === 0) {
      isPanelShownFirstTime = 1;
    } else {
      isPanelShownFirstTime = 2;
    }
  }

  getConcurrentUserToDisplay() {
    let userNameToDisplay =
      this.props.concurrentUserToDisplay || _.head(this.props.concurrentUsers);
    if (
      userNameToDisplay === 'wixStaff' ||
      userNameToDisplay.toLowerCase().endsWith('@wixcs.com')
    ) {
      userNameToDisplay = translate(
        'CONCURRENT_EDITING_SOMEBODY_JOINED_DESCRIPTION_WIX_STAFF',
      );
    }
    return userNameToDisplay;
  }

  getStaticProps() {
    return util.workspace.isNewWorkspaceEnabled()
      ? PANEL_STATIC_PROPS.newWorkspace
      : PANEL_STATIC_PROPS.default;
  }

  renderFootnote(footnote?: string) {
    return (
      footnote &&
      translate(footnote, {
        user_email: this.getConcurrentUserToDisplay(),
      })
    );
  }

  renderContent(description: string[]) {
    if (util.workspace.isNewWorkspaceEnabled()) {
      return this.renderNewWorkspaceExperimentContent(description);
    }
    return this.renderExperimentContent(description);
  }

  renderNewWorkspaceContent(description: string[]) {
    const userNameToDisplay = this.getConcurrentUserToDisplay();
    const userNameNode = (
      <span className="concurrent-user">{userNameToDisplay}</span>
    );

    return (
      <>
        {description.map((message, key) => (
          <div key={key} className="textual-content">
            {key === 0 ? userNameNode : null}
            {translate(message)}
          </div>
        ))}
      </>
    );
  }

  renderNewWorkspaceExperimentContent(description: AnyFixMe) {
    const userNameToDisplay = this.getConcurrentUserToDisplay();

    return (
      <>
        {description.map((message: AnyFixMe, key: AnyFixMe) => (
          <div key={key} className="textual-content">
            {translate(message, {
              user_email: userNameToDisplay,
            })}
          </div>
        ))}
      </>
    );
  }

  renderExperimentContent(description: string[]) {
    const [description_top, description_bottom] = description;
    const userNameToDisplay = this.getConcurrentUserToDisplay();

    return (
      <div className="textual-content">
        <div className="sub-title">
          {translate(description_top, {
            user_email: userNameToDisplay,
          })}
        </div>
        <div>
          {translate(description_bottom, {
            user_email: userNameToDisplay,
          })}
        </div>
      </div>
    );
  }

  renderDefaultContent(description: string[]) {
    const [title, ...recommendations] = description;
    const userNameToDisplay = this.getConcurrentUserToDisplay();

    return (
      <div className="textual-content">
        <div className="sub-title">
          <span className="concurrent-user">{userNameToDisplay}</span>
          {translate(title)}
        </div>
        <ul className="recommendation-list">
          {recommendations.map((recommendation, recommendationIndex) => (
            <li key={recommendationIndex}>
              <span className="number">{recommendationIndex + 1}.</span>
              <span className="description">{translate(recommendation)}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  handleCancel = () => {
    util.windowInteractionUtils.reloadPageWithoutQuestion();
  };

  handleConfirm = () => {
    this.sendConfirmBi();
    window.location.replace(window.serviceTopology.businessManagerDomain);
  };

  sendConfirmBi() {
    const biEvent =
      coreBi.events.concurrentUsers.CONCURRENT_USERS_POPUP_OK_CLICK;
    const biFields = {
      user: this.props.isFirstUser ? 'first' : 'second',
      count: this.props.concurrentUsers.length,
      is_first: isPanelShownFirstTime === 1,
    };

    this.props.sendBi(biEvent, biFields);
  }

  close = () => {
    this.props.closePanelByName(this.props.panelName);
  };

  render() {
    const { helpId, description, footnote, ...staticProps } =
      this.getStaticProps();

    const { openHelpCenter } = this.props;

    const PanelFrameComponent = util.workspace.isNewWorkspaceEnabled()
      ? NewWorkspaceConcurrentUserPanelFrame
      : ConcurrentUserPanelFrame;

    return (
      <PanelFrameComponent
        panelName={this.props.panelName}
        {...staticProps}
        footnote={this.renderFootnote(footnote)}
        onConfirm={this.handleConfirm}
        onCancel={staticProps.cancelLabel && this.handleCancel}
        onHelp={helpId ? () => openHelpCenter(helpId) : null}
        onClose={this.close}
      >
        {this.renderContent(description)}
      </PanelFrameComponent>
    );
  }
}

export default connect(ConcurrentUserPanel);

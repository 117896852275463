import { panels } from '#packages/stateManagement';
import constants from '#packages/constants';
import _ from 'lodash';

import type { Dispatch } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';

const openPanelChangeColorTheme = (
  editorAPI: EditorAPI,
  dispatch: Dispatch,
  origin: string,
) => {
  const panel = constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME;

  const panelOptions = {
    selectedView: constants.DESIGN_PANEL.VIEWS.COLOR_THEME,
    origin,
  };

  if (panels.selectors.isPanelOpenedByName(editorAPI.store.getState(), panel)) {
    dispatch(
      panels.actions.updatePanel(panel, {
        ...panelOptions,
        key: panel + _.uniqueId(),
      }),
    );
  } else {
    dispatch(panels.actions.openLeftPanel(panel, panelOptions, true));
  }
};

export { openPanelChangeColorTheme };

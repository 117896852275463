// @ts-nocheck
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React from 'react';
import IconButton from './iconButton';
import * as util from '#packages/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  label?: string;
  iconName?: string;
  width?: number;
}

export default class extends React.Component<Props> {
  static displayName = 'textPopup';

  static propTypes = {
    label: PropTypes.string,
    iconName: PropTypes.string,
    width: PropTypes.number,
  };

  state = {
    popupOpen: false,
  };

  togglePopup = () => {
    const toggle = !this.state.popupOpen;
    this.setState({ popupOpen: toggle });
    if (toggle) {
      this.setPopupPosition();
    }
  };

  hide = () => {
    if (this.state.popupOpen) {
      this.setState({ popupOpen: false });
    }
  };

  setPopupPosition = () => {
    const buttonBounding = ReactDOM.findDOMNode(
      this.refs.popupButton,
    ).getBoundingClientRect();
    if (this.refs.popupContainer) {
      const popupContainer = ReactDOM.findDOMNode(this.refs.popupContainer);
      const right = 0 - popupContainer.getBoundingClientRect().width / 2;
      const bottom = buttonBounding.height + 10;

      popupContainer.style.setProperty('bottom', `${bottom}px`);
      popupContainer.style.setProperty('right', `${right}px`);
    }
  };

  render() {
    return (
      <util.outerClick onOuterClick={this.hide}>
        <div className="text-popup-control">
          <span>
            <IconButton
              ref="popupButton"
              isSelected={this.state.popupOpen}
              style={{
                width: this.props.width,
              }}
              onClick={this.togglePopup}
              popupMode={true}
              name={this.props.iconName}
            >
              {this.props.label ? (
                <span key="textPopupLabel">{this.props.label}</span>
              ) : null}
            </IconButton>
          </span>

          <div
            style={{
              visibility: this.state.popupOpen ? 'visible' : 'hidden',
            }}
            className="tri with-shadow"
          />
          <div
            ref="popupContainer"
            style={{
              visibility: this.state.popupOpen ? 'visible' : 'hidden',
            }}
            className="text-popup with-shadow"
          >
            <span>{this.props.children}</span>
          </div>
          <div
            style={{
              visibility: this.state.popupOpen ? 'visible' : 'hidden',
            }}
            className="tri cover-shadow"
          />
        </div>
      </util.outerClick>
    );
  }
}

// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import experiment from 'experiment';
import React from 'react';
import * as panels from '#packages/panels';
import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';
import PlatformPanelApplicationNative from './platformPanelApplicationNative';

const propTypes = {
  token: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  initialData: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

if (experiment.isOpen('se_platform_modal_native_panel')) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(propTypes, {
    url: PropTypes.string,
    nativePanelData: PropTypes.shape({
      metaSiteId: PropTypes.string.isRequired,
      pageId: PropTypes.string.isRequired,
    }),
  });
}

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {}

export default class extends React.Component<Props> {
  static displayName = 'platformModalPanel';
  static propTypes = propTypes;

  render() {
    const isNativePanel = this.props.nativePanelData;

    return (
      <panels.frames.FocusPanelFrame
        panelName="platformPanels.modalPanel"
        automationId="platformPanels.modalPanel"
        width={this.props.width}
        height={this.props.height}
        title={this.props.title}
        shouldHideHeader={this.props.shouldHideHeader}
        shouldNotCloseOnBlur={true}
        helpId={this.props.helpId}
        shouldHideHelp={!this.props.helpId}
        className={this.getCssClasses()}
      >
        {!isNativePanel ? (
          <PlatformPanelApplicationFrame
            key="applicationFrame"
            token={this.props.token}
            url={this.props.url}
            initialData={this.props.initialData}
            panelClass="platformToolPanel"
            height="100%"
            width="100%"
          />
        ) : null}

        {isNativePanel ? (
          <PlatformPanelApplicationNative
            key="applicationNative"
            initialData={this.props.initialData}
            metaSiteId={this.props.nativePanelData.metaSiteId}
            pageId={this.props.nativePanelData.pageId}
          />
        ) : null}
      </panels.frames.FocusPanelFrame>
    );
  }

  getCssClasses = () => {
    const shouldNotHaveVerticalOffset =
      !this.props.title && this.props.centered;
    return shouldNotHaveVerticalOffset ? 'platform-modal-panel-centered' : null;
  };
}

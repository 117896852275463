// @ts-nocheck
import * as util from '#packages/util';

const FALLBACK_REGEXP = new RegExp(`_${util.languages.getLanguageCode()}`, 'g');

function getFallbackImage(image) {
  return image ? image.replace(FALLBACK_REGEXP, '_en') : '';
}

export { getFallbackImage };

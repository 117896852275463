import React from 'react';
import HoveredComponentLabels from '../hoveredComponentLabels/hoveredComponentLabels';
import { cx, hoc } from '#packages/util';
import { withRestrictions } from '#packages/editorRestrictions';
import { mapStateToProps, shouldShowHoverboxSel } from './hoverBoxMapper';

import type { HoverBoxStateProps } from './hoverBoxMapper';

interface HoverBoxProps extends HoverBoxStateProps {}

const HoverBox: React.FC<HoverBoxProps> = ({
  hoverClasses,
  hoverBoxStyles,
  isLabelBottom,
  hoveredComp,
}) => (
  <>
    <div style={hoverBoxStyles} className={cx(hoverClasses)}>
      <HoveredComponentLabels
        isLabelBottom={isLabelBottom}
        compPointer={hoveredComp}
      />
    </div>
  </>
);

HoverBox.displayName = 'hoverBox';

export default withRestrictions('rEditor_hover-box')(
  hoc.withConditionalRender(
    hoc.STORES.EDITOR_API,
    shouldShowHoverboxSel,
  )(hoc.connect(hoc.STORES.MOUSE_OPS, mapStateToProps)(HoverBox)),
);

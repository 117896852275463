import createNodeProps from './createNodeProps';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
} from 'types/redux';
import {
  initLayersPanel,
  destroyLayersPanel,
  hideLayersPanel,
  openLayersPanelHelpCenter,
  type LayersPanelContext,
  type LayersPanelAction,
} from './layersPanelActions';
import type { LayersPanelOwnProps } from './layersPanel';
import type { NodeProps } from './node';

export interface LayersPanelStateProps {
  treeProps: {
    header?: NodeProps;
    footer?: NodeProps;
    page: NodeProps;
  };
}

export const mapStateToProps: MapStateToProps<
  LayersPanelStateProps,
  LayersPanelOwnProps
> = ({ editorAPI }, ownProps) => {
  const currentPage = editorAPI.pages.getCurrentPageId();
  const shouldHideHeaderFooter =
    editorAPI.pages.data.get(currentPage).isLandingPage ||
    editorAPI.dsRead.pages.popupPages.isPopupOpened();

  return {
    treeProps: {
      header: shouldHideHeaderFooter
        ? undefined
        : createNodeProps(
            editorAPI,
            editorAPI.dsRead.pages.getReference('SITE_HEADER'),
            { isSOAP: true },
            ownProps.treeNodesContext,
          ),
      footer: shouldHideHeaderFooter
        ? undefined
        : createNodeProps(
            editorAPI,
            editorAPI.dsRead.pages.getReference('SITE_FOOTER'),
            { isSOAP: true },
            ownProps.treeNodesContext,
          ),
      page: createNodeProps(
        editorAPI,
        editorAPI.dsRead.pages.getFocusedPage(),
        { isSOAP: false },
        ownProps.treeNodesContext,
      ),
    },
  };
};

export interface LayersPanelDispatchProps {
  init: (context: LayersPanelContext) => void;
  destroy: (context: LayersPanelContext) => void;
  openHelpCenter: () => void;
  close: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  LayersPanelDispatchProps,
  LayersPanelOwnProps
> = (dispatch: Dispatch<LayersPanelAction>) => ({
  init: (context) => dispatch(initLayersPanel(context)),
  destroy: (context) => dispatch(destroyLayersPanel(context)),
  openHelpCenter: () => dispatch(openLayersPanelHelpCenter()),
  close: () => dispatch(hideLayersPanel()),
});

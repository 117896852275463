import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import { translate } from '#packages/i18n';
import experiment from 'experiment';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import _ from 'lodash';
import { Divider } from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';
import type { InnerPanelProps } from '../../content/types';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<InnerPanelProps>({
  displayName: 'growBusinessPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    metaSiteId: PropTypes.string.isRequired,
    isFirstPublish: PropTypes.bool,
    userStatus: PropTypes.string,
  },

  getTitle() {
    return translate('PUBLISH_CONGRATULATIONS_NEXT_PROMOTE_TITLE');
  },

  getPanelContent() {
    const seoWizardAssets = {
      key: 'seo',
      symbol: 'publishSeo',
      description: translate('PUBLISH_CONGRATULATIONS_WHATS_NEXT_SEO_TITLE'),
      actionTitle: translate('PUBLISH_CONGRATULATIONS_WHATS_NEXT_SEO_LINK'),
      action: this.openSeoWizard,
    };
    const newsletterAssets = {
      key: 'newsletter',
      symbol: 'publishShoutout',
      description: translate('PUBLISH_CONGRATULATIONS_NEXT_PROMOTE_1_TITLE'),
      actionTitle: translate('PUBLISH_CONGRATULATIONS_NEXT_PROMOTE_1_LINK'),
      action: this.openShoutout,
    };
    const mailboxAssets = {
      key: 'mail',
      symbol: 'promoteMail',
      description: translate('PUBLISH_CONGRATULATIONS_NEXT_PROMOTE_2_TITLE'),
      actionTitle: translate('PUBLISH_CONGRATULATIONS_NEXT_PROMOTE_2_LINK'),
      action: this.getYourOwnMailbox,
    };
    const getTrafficAssets = {
      key: 'traffic',
      symbol: 'promoteContact',
      description: translate('PUBLISH_CONGRATULATIONS_NEXT_PROMOTE_3_TITLE'),
      actionTitle: translate('PUBLISH_CONGRATULATIONS_NEXT_PROMOTE_3_LINK'),
      action: this.getTrafficApps,
    };

    return experiment.isOpen('newPublishPanel')
      ? [newsletterAssets, mailboxAssets, seoWizardAssets]
      : [newsletterAssets, mailboxAssets, getTrafficAssets];
  },

  openSeoWizard() {
    if (this.props.userStatus) {
      this.getEditorAPI().bi.event(
        coreBi.events.publish.Second_Publish_Seo_Wizard_click,
        { user_status: this.props.userStatus },
      );
    }
    this.getEditorAPI().account.openWixSEOWiz('EDITOR_POSTPUBLISH_PREMIUM');
  },

  openShoutout() {
    this.sendPublishBI(
      coreBi.events.publish.First_Publish_Send_ShoutOut_link_click,
      coreBi.events.publish.Second_Publish_ShoutOut_Share_click,
    );
    core.utils.social.openShoutout(this.props.metaSiteId);
  },

  getYourOwnMailbox() {
    this.sendPublishBI(
      coreBi.events.publish.First_Publish_get_own_Mailbox_link_click,
      coreBi.events.publish.Second_Publish_get_own_Mailbox_click,
    );
    window.open(
      'https://premium.wix.com/wix/api/googleMailBoxConsole?referralAdditionalInfo=da_quick_actions',
    );
  },

  getTrafficApps() {
    this.sendPublishBI(
      coreBi.events.publish.First_Publish_Traffic_Apps_link_click,
      coreBi.events.publish.Second_Publish_Traffic_Apps_click,
    );
    const url = [
      '//www.wix.com/my-account/sites/',
      this.props.metaSiteId,
      '/apps/all?referralInfo=premium_publish',
    ].join('');
    window.open(url);
  },

  sendPublishBI(firstPublishEvent: AnyFixMe, secondPublishEvent: AnyFixMe) {
    if (this.props.isFirstPublish && this.props.userStatus) {
      this.getEditorAPI().bi.event(firstPublishEvent, {
        user_status: this.props.userStatus,
      });
    } else if (!this.props.isFirstPublish && this.props.userStatus) {
      this.getEditorAPI().bi.event(secondPublishEvent, {
        user_status: this.props.userStatus,
      });
    }
  },

  render() {
    return (
      <div className="panel-shout-out">
        <div className="promote-title-wrapper">
          <Divider long={true} />
          <div className="promote-title">{this.getTitle()}</div>
          <Divider long={true} />
        </div>

        <div className="promotion-content">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.getPanelContent(), (item) => (
            <div key={item.key} className="promote">
              {item.symbol ? (
                <div
                  key={`promotionSymbol-${item.key}`}
                  className={`promote-symbol promote-symbol-${item.key}`}
                >
                  <baseUI.symbol name={item.symbol} />
                </div>
              ) : null}
              {item.img && !item.symbol ? (
                <div
                  key={`promotionImg-${item.key}`}
                  style={{
                    backgroundImage: `url(${item.img})`,
                  }}
                  className="promote-image"
                />
              ) : null}
              <div className="description">{item.description}</div>
              <a onClick={item.action}>{item.actionTitle}</a>
            </div>
          ))}
        </div>
      </div>
    );
  },
});

import React, { type ComponentType } from 'react';
import _ from 'lodash';

import { isResponsiveEditor } from '@wix/santa-editor-utils';

import * as tpa from '#packages/tpa';
import * as util from '#packages/util';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './onlineProgramsWelcomeSectionMapper';
import type {
  OnlineProgramsWelcomeDispatchProps,
  OnlineProgramsWelcomeOwnProps,
  OnlineProgramsWelcomeStateProps,
} from './types';

const firstTimeTextContent = Object.freeze({
  promotionalList: [
    'Add_Online_Programs_First_Time_Bullet_1',
    'Add_Online_Programs_First_Time_Bullet_2',
    'Add_Online_Programs_First_Time_Bullet_3',
  ],
  title: 'Add_Online_Programs_First_Time_Title',
  subtitle: 'Add_Online_Programs_First_Time_Subtitle',
  mainActionLabel: 'Add_Online_Programs_First_Time_Button',
  secondaryActionLabel: 'Add_Online_Programs_First_Time_LinkCaption',
});

class OnlineProgramsWelcomeSection extends React.Component<
  OnlineProgramsWelcomeOwnProps &
    OnlineProgramsWelcomeStateProps &
    OnlineProgramsWelcomeDispatchProps
> {
  static displayName = 'OnlineProgramsWelcomeSection';
  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();

  getMainAction = () => {
    if (_.isFunction(this.props.installAppOverride)) {
      return _.partial(
        this.props.installAppOverride,
        tpa.superApps.superAppsConstants.ONLINE_PROGRAMS.APP_DEF_ID,
      );
    }
    return this.props.installOnlineProgramsApp;
  };

  getSecondaryAction = () => {
    if (isResponsiveEditor()) {
      return this.props.openEditorXHelpPanel;
    }
    return this.props.openAppMarket;
  };

  getBannerImgSrc() {
    let bannerSrc =
      'addPanelData/sections/onlineProgramsWelcomeSectionOldEditor_en/onlineProgramsWelcomeSectionOldEditor_en.png';

    if (this.isNewWorkspace) {
      bannerSrc = util.browserUtil.isRetina()
        ? 'addPanelData/sections/onlineProgramsWelcomeSectionOldEditor_en/newWorkspace_onlineProgramsWelcomeSectionOldEditor_en@x2.png'
        : 'addPanelData/sections/onlineProgramsWelcomeSectionOldEditor_en/newWorkspace_onlineProgramsWelcomeSectionOldEditor_en.png';
    }

    if (isResponsiveEditor()) {
      bannerSrc =
        'addPanelData/sections/onlineProgramsWelcomeSection_en/onlineProgramsWelcomeSection_en.png';
    }

    return util.media.getMediaUrl(bannerSrc);
  }

  render() {
    const WelcomeSection = this.props.welcomeSectionTemplate;
    const bannerImg = this.getBannerImgSrc();

    return (
      <div>
        <WelcomeSection
          bannerImgSrc={bannerImg}
          title={firstTimeTextContent.title}
          subtitle={firstTimeTextContent.subtitle}
          promotionalListItems={firstTimeTextContent.promotionalList}
          mainActionLabel={firstTimeTextContent.mainActionLabel}
          secondaryActionLabel={firstTimeTextContent.secondaryActionLabel}
          onMainActionClick={this.getMainAction()}
          onSecondaryActionClick={this.getSecondaryAction()}
        />
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(
  util.hoc.renderWhenMutated(OnlineProgramsWelcomeSection),
) as ComponentType<OnlineProgramsWelcomeOwnProps>;

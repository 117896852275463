/*eslint max-lines: [2, { "max": 3061, "skipComments": true, "skipBlankLines": true}]*/
/*this file is huge, please don't make it even bigger, try to decompose it*/
// @ts-nocheck
import { translate } from '#packages/i18n';
import * as paginationCustomizationsProvider from './paginationCustomizationsProvider';
import * as coreBi from '#packages/coreBi';
import coreUtilsLib from 'coreUtilsLib';
import { editorWixRecorder } from '#packages/util';

const { blogAppPartNames } = coreUtilsLib;

function getBlogFeedPageJSON(editorAPI, params) {
  return {
    type: 'Page',
    components: [
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 220,
          height: 24,
          x: 729,
          y: 741,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 29,
              topToTop: 53,
              originalValue: 793,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptr_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.TAG_CLOUD,
          viewName: 'TagCloud',
          appLogicCustomizations: [
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'TagCloud',
              mode: '*',
              key: 'comp.size',
              value: '8',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'TagCloud',
              mode: '*',
              key: 'value',
              value: 'Search by tags',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'wix:Object',
              view: 'TagCloud',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '6',
              fieldId: 'key',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'TagCloud',
              mode: 'view',
              key: 'comp.size',
              value: '8',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'wix:Object',
              view: 'TagCloud',
              format: '*',
              key: 'fixedSize',
              value: 'true',
              fieldId: 'vars',
            },
          ],
          appLogicParams: {},
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_e000b4bf-9ff1-4e66-a0d3-d4b365ba3af5_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 20,
          x: 729,
          y: 794,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 34,
              originalValue: 825,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptt_4',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_follow_us',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 670,
          height: 918,
          x: 30,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 0,
              topToTop: 30,
              originalValue: 1028,
              type: 'BOTTOM_PARENT',
              locked: false,
              targetComponent: 'csf1',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.FEED,
          viewName: params.viewName,
          appLogicCustomizations: [
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeftPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzagPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRightPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottomPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTopPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeftPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzagPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRightPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottomPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTopPage',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'ReadMoreBtn',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'text',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'text',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              //TODO: remove this customization once blogVideoThumbnail experiment is merged
              type: 'AppPartCustomization',
              forType: 'VideoPost',
              view: 'MediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'v1',
              fieldId: 'videoPlayable',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'VideoPost',
              view: 'MediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'wp2',
              fieldId: 'video',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'PhotoPost',
              view: 'MediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'wp2',
              fieldId: 'photo',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'text',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'text',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'ReadMoreBtn',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'text',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '14',
              fieldId: 'def_13',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: '*',
              key: 'padding',
              value: '22',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '22',
              fieldId: 'WholePost',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'date',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '12',
              fieldId: 'def_11',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '0',
              fieldId: 'def_11',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: '*',
              key: 'padding',
              value: '22',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: '*',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: '*',
              key: 'padding',
              value: '22',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: '*',
              key: 'layout.width',
              value: '254',
              fieldId: 'MediaSize',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: '*',
              key: 'padding',
              value: '22',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: '*',
              key: 'layout.width',
              value: '254',
              fieldId: 'MediaSize',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: '*',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: '*',
              key: 'padding',
              value: '22',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: '*',
              key: 'layout.width',
              value: '254',
              fieldId: 'MediaSize',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: '*',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: '*',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: '*',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: 'view',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '13',
              fieldId: 'title',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '10',
              fieldId: 'title',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '11',
              fieldId: 'title',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '13',
              fieldId: 'title',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '22',
              fieldId: 'WholePost',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '22',
              fieldId: 'WholePost',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '22',
              fieldId: 'def_10',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '22',
              fieldId: 'def_27',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '22',
              fieldId: 'def_10',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: '*',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'date',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'comp.size',
              value: '16',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '13',
              fieldId: 'title',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '15',
              fieldId: 'text',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '15',
              fieldId: 'text',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: '*',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'ReadMoreBtn',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRight',
              mode: '*',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'ReadMoreBtn',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeft',
              mode: '*',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'ReadMoreBtn',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '22',
              fieldId: 'WholePost',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '22',
              fieldId: 'def_11',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '22',
              fieldId: 'WholePost',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'ReadMoreBtn',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '22',
              fieldId: 'WholePost',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '20',
              fieldId: 'def_13',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: 'view',
              key: 'comp.size',
              value: '22',
              fieldId: 'def_17',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzag',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '22',
              fieldId: 'WholePost',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottom',
              mode: '*',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'ReadMoreBtn',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTop',
              mode: '*',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'ReadMoreBtn',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeftPage',
              mode: '*',
              key: 'padding',
              value: '20',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTopPage',
              mode: '*',
              key: 'comp.size',
              value: '30',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaTopPage',
              mode: '*',
              key: 'padding',
              value: '20',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottomPage',
              mode: '*',
              key: 'padding',
              value: '20',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaBottomPage',
              mode: '*',
              key: 'comp.size',
              value: '30',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRightPage',
              mode: '*',
              key: 'padding',
              value: '20',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaRightPage',
              mode: '*',
              key: 'comp.size',
              value: '30',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzagPage',
              mode: '*',
              key: 'comp.size',
              value: '30',
              fieldId: 'PostSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaZigzagPage',
              mode: '*',
              key: 'padding',
              value: '20',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'MediaLeftPage',
              mode: '*',
              key: 'comp.size',
              value: '30',
              fieldId: 'PostSpace',
            },
          ].concat(paginationCustomizationsProvider.getCustomizationsForFeed()),
          appLogicParams: {
            filter: {
              type: 'AppPartParam',
              value: '',
            },
            limit: {
              type: 'AppPartParam',
              value: '20',
            },
            sort: {
              type: 'AppPartParam',
              value: '{"date.iso":-1}',
            },
          },
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_4de5abc5-6da2-4f97-acc3-94bb74285072_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
        layout: {
          width: 88,
          height: 23,
          x: 729,
          y: 840,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 166,
              topToTop: 839,
              originalValue: 1028,
              type: 'BOTTOM_PARENT',
              locked: false,
              targetComponent: 'csf1',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.LinkBar',
        data: {
          type: 'ImageList',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          items: [
            {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Facebook Basic Square',
              uri: 'e316f544f9094143b9eac01f1f19e697.png',
              description: '',
              width: 205,
              height: 205,
              alt: '',
              link: {
                type: 'ExternalLink',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                url: 'https://www.facebook.com/wix',
                target: '_blank',
              },
            },
            {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Twitter Basic Square',
              uri: '9c4b521dd2404cd5a05ed6115f3a0dc8.png',
              description: '',
              width: 205,
              height: 205,
              alt: '',
              link: {
                type: 'ExternalLink',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                url: 'http://www.twitter.com/wix',
                target: '_blank',
              },
            },
            {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Google+ Basic Square',
              uri: '74d327d3628e4f14800301f1c61013b0.png',
              description: '',
              width: 205,
              height: 205,
              alt: '',
              link: {
                type: 'ExternalLink',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                url: 'https://plus.google.com/117167403531518744294/posts',
                target: '_blank',
              },
            },
          ],
        },
        props: {
          type: 'LinkBarProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          gallery: 'social_icons',
          iconSize: 23,
          spacing: 7,
          bgScale: 1,
          orientation: 'HORIZ',
        },
        style: 'lb1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 220,
          height: 221,
          x: 729,
          y: 378,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 10,
              topToTop: 216,
              originalValue: 598,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptu_2',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.POSTS_LIST,
          viewName: 'PostsListMediaLeft',
          appLogicCustomizations: [
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'comp.hidden',
              value: 'true',
              fieldId: 'postSeparator',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'hl1',
              fieldId: 'lineSeparator',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'comp.hidden',
              value: 'false',
              fieldId: 'dateShow',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsList',
              mode: 'view',
              key: 'comp.style',
              value: 'Body XS',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'Body XS',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsListMediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading S',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'VideoPost',
              view: 'PostsListMediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'wp2',
              fieldId: 'video',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'PhotoPost',
              view: 'PostsListMediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'wp2',
              fieldId: 'photo',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsList',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading S',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsList',
              mode: '*',
              key: 'comp.hidden',
              value: 'true',
              fieldId: 'postSeparator',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsList',
              mode: '*',
              key: 'postSpacing',
              value: '7',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsList',
              mode: '*',
              key: 'comp.size',
              value: '11',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'comp.size',
              value: '17',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'layout.width',
              value: '70',
              fieldId: 'MediaSize',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'postSpacing',
              value: '22',
              fieldId: 'vars',
            },
          ],
          appLogicParams: {
            filter: {
              type: 'AppPartParam',
              value: '{}',
            },
            limit: {
              type: 'AppPartParam',
              value: '10',
            },
            sort: {
              type: 'AppPartParam',
              value: '{"date.iso":-1}',
            },
          },
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_f72fe377-8abc-40f2-8656-89cfe00f3a22_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 20,
          x: 729,
          y: 332,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 34,
              originalValue: 364,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptt_1',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_recent_posts',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 364,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 9,
              topToTop: 14,
              originalValue: 378,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptt',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 32,
          x: 729,
          y: 700,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 34,
              originalValue: 733,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptt_3',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_search_by_tags',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 734,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 7,
              originalValue: 740,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptr',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 826,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 9,
              topToTop: 14,
              originalValue: 839,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6pts_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 20,
          x: 729,
          y: 34,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 34,
              originalValue: 66,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptu_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_featured_posts',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 66,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 9,
              topToTop: 14,
              originalValue: 80,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptu_1',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 220,
          height: 220,
          x: 729,
          y: 80,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 32,
              topToTop: 252,
              originalValue: 332,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptt_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.TICKER,
          viewName: 'TickerMediaTop',
          appLogicCustomizations: [],
          appLogicParams: {
            filter: {
              type: 'AppPartParam',
              value: '{"featured":true}',
            },
            limit: {
              type: 'AppPartParam',
              value: '10',
            },
            sort: {
              type: 'AppPartParam',
              value: '{"date.iso":-1}',
            },
          },
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_33a9f5e0-b083-4ccc-b55d-3ca5d241a6eb_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 33,
          x: 729,
          y: 609,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 1,
              topToTop: 34,
              originalValue: 632,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptu_3',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_archive',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 643,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 7,
              originalValue: 639,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptu_4',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 210,
          height: 31,
          x: 729,
          y: 650,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 19,
              topToTop: 81,
              originalValue: 699,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6ptt_2',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.ARCHIVE,
          viewName: 'ArchiveList',
          appLogicCustomizations: [],
          appLogicParams: {},
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_56ab6fa4-95ac-4391-9337-6702b8a77011_1',
      },
    ],
    skin: 'wysiwyg.viewer.skins.page.BasicPageSkin',
    layout: {
      width: 980,
      height: 968,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [],
      fixedPosition: false,
    },
    componentType: 'wixapps.integration.components.AppPage',
    data: {
      type: 'AppPage',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      },
      title: 'Blog',
      hideTitle: true,
      icon: '',
      descriptionSEO: '',
      metaKeywordsSEO: '',
      pageTitleSEO: '',
      pageUriSEO: 'blog',
      hidePage: false,
      underConstruction: false,
      tpaApplicationId: 0,
      pageSecurity: {
        requireLogin: false,
      },
      indexable: true,
      isLandingPage: false,
      pageBackgrounds: editorAPI.components.data.get(
        editorAPI.components.get.byId(editorAPI.pages.getPrimaryPageId()),
      ).pageBackgrounds,
      appPageId: '79f391eb-7dfc-4adf-be6e-64434c4838d9',
      appPageType: 'AppPage',
      appInnerID: params.appInnerID,
      repeaterPage: false,
    },
    style: 'p2',
  };
}

function getSinglePostPageJSON(editorAPI, params) {
  const SINGLE_POST_VIEW_NAME = 'SinglePostMediaTop';
  return {
    type: 'Page',
    components: [
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 220,
          height: 221,
          x: 729,
          y: 378,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 10,
              topToTop: 216,
              originalValue: 598,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4b',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.POSTS_LIST,
          viewName: 'PostsListMediaLeft',
          appLogicCustomizations: [
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'comp.hidden',
              value: 'true',
              fieldId: 'postSeparator',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'hl1',
              fieldId: 'lineSeparator',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'comp.hidden',
              value: 'false',
              fieldId: 'dateShow',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsList',
              mode: 'view',
              key: 'comp.style',
              value: 'Body XS',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'Body XS',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsListMediaLeft',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading S',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'VideoPost',
              view: 'PostsListMediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'wp2',
              fieldId: 'video',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'PhotoPost',
              view: 'PostsListMediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'wp2',
              fieldId: 'photo',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsList',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading S',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsList',
              mode: '*',
              key: 'comp.hidden',
              value: 'true',
              fieldId: 'postSeparator',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsList',
              mode: '*',
              key: 'postSpacing',
              value: '7',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsList',
              mode: '*',
              key: 'comp.size',
              value: '11',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'comp.size',
              value: '17',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'layout.width',
              value: '70',
              fieldId: 'MediaSize',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'PostsListMediaLeft',
              mode: '*',
              key: 'postSpacing',
              value: '22',
              fieldId: 'vars',
            },
          ],
          appLogicParams: {
            filter: {
              type: 'AppPartParam',
              value: '{}',
            },
            limit: {
              type: 'AppPartParam',
              value: '10',
            },
            sort: {
              type: 'AppPartParam',
              value: '{"date.iso":-1}',
            },
          },
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_f72fe377-8abc-40f2-8656-89cfe00f3a22_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 364,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 9,
              topToTop: 14,
              originalValue: 378,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q48_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 332,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 38,
              originalValue: 364,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q49',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_recent_posts',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 220,
          height: 220,
          x: 729,
          y: 80,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 32,
              topToTop: 252,
              originalValue: 332,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q49_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.TICKER,
          viewName: 'TickerMediaTop',
          appLogicCustomizations: [],
          appLogicParams: {
            filter: {
              type: 'AppPartParam',
              value: '{"featured":true}',
            },
            limit: {
              type: 'AppPartParam',
              value: '10',
            },
            sort: {
              type: 'AppPartParam',
              value: '{"date.iso":-1}',
            },
          },
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_33a9f5e0-b083-4ccc-b55d-3ca5d241a6eb_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 30,
          x: 729,
          y: 34,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 38,
              originalValue: 66,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q49_3',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_featured_posts',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 730,
          y: 66,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 9,
              topToTop: 14,
              originalValue: 80,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q49_1',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 670,
          height: 710,
          x: 30,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 19,
              topToTop: 753,
              originalValue: 783,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4a',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.SINGLE_POST,
          viewName: SINGLE_POST_VIEW_NAME,
          appLogicCustomizations: [
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: '*',
              key: 'comp.hidden',
              value: 'true',
              fieldId: 'TwitterShare',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: '*',
              key: 'comp.hidden',
              value: 'true',
              fieldId: 'FancyShare',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: '*',
              key: 'comp.hidden',
              value: 'true',
              fieldId: 'LinkedInShare',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'text',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'PhotoPost',
              view: 'SinglePostMediaInner',
              mode: 'view',
              key: 'comp.style',
              value: 'wp2',
              fieldId: 'photo',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'text',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'comp.style',
              value: 'Body S',
              fieldId: 'date',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'comp.style',
              value: 'Heading M',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'comp.style',
              value: 'Body M',
              fieldId: 'def_10',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'date',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '10',
              fieldId: 'def_10',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '16',
              fieldId: 'def_9',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'comp.size',
              value: '12',
              fieldId: 'socialIconsSpacer',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '0',
              fieldId: 'GoogleShare',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '12',
              fieldId: 'this',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: '*',
              key: 'comp.size',
              value: '12',
              fieldId: 'socialIconsSpacer',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '12',
              fieldId: 'this',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '10',
              fieldId: 'def_10',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '16',
              fieldId: 'def_9',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '14',
              fieldId: 'def_15',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: 'view',
              key: 'layout.spacerBefore',
              value: '14',
              fieldId: 'def_15',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaBottom',
              mode: '*',
              key: 'padding',
              value: '15',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: '*',
              key: 'padding',
              value: '20',
              fieldId: 'vars',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Post',
              view: 'SinglePostMediaTop',
              mode: 'view',
              key: 'comp.style',
              value: 'c1',
              fieldId: 'def_1',
            },
          ].concat(
            editorAPI.dsRead.wixapps.classics.getBlogNewSocialShareButtonsCustomizationForView(
              SINGLE_POST_VIEW_NAME,
            ),
          ),
          appLogicParams: {},
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_4656a63c-b316-4315-ab16-e2003f5935ca_1',
      },
      {
        type: 'Component',
        skin: 'skins.core.FacebookCommentSkin',
        layout: {
          width: 667,
          height: 116,
          x: 30,
          y: 759,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 0,
              topToTop: 802,
              originalValue: 925,
              type: 'BOTTOM_PARENT',
              locked: false,
              targetComponent: 'c5j0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WFacebookComment',
        props: {
          type: 'WFacebookCommentProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          numPosts: 2,
          width: 667,
          colorScheme: 'light',
          canBeShownOnAllPagesBug: false,
        },
        style: 'fbc1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 20,
          x: 729,
          y: 794,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 34,
              originalValue: 825,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4b_1',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_follow_us',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 220,
          height: 24,
          x: 729,
          y: 741,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 29,
              topToTop: 53,
              originalValue: 793,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4a_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.TAG_CLOUD,
          viewName: 'TagCloud',
          appLogicCustomizations: [
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'TagCloud',
              mode: '*',
              key: 'comp.size',
              value: '8',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'TagCloud',
              mode: '*',
              key: 'value',
              value: 'Search by tags',
              fieldId: 'title',
              format: '*',
            },
            {
              type: 'AppPartCustomization',
              forType: 'wix:Object',
              view: 'TagCloud',
              mode: 'view',
              key: 'layout.spacerAfter',
              value: '6',
              fieldId: 'key',
            },
            {
              type: 'AppPartCustomization',
              forType: 'Array',
              view: 'TagCloud',
              mode: 'view',
              key: 'comp.size',
              value: '8',
              fieldId: 'mainTitleSpace',
            },
            {
              type: 'AppPartCustomization',
              forType: 'wix:Object',
              view: 'TagCloud',
              mode: '*',
              key: 'fixedSize',
              value: 'true',
              fieldId: 'vars',
            },
          ],
          appLogicParams: {},
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_e000b4bf-9ff1-4e66-a0d3-d4b365ba3af5_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 32,
          x: 729,
          y: 700,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 34,
              originalValue: 733,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4b_2',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_search_by_tags',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.AppPartSkin',
        layout: {
          width: 210,
          height: 31,
          x: 729,
          y: 650,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 19,
              topToTop: 81,
              originalValue: 699,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4a_2',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wixapps.integration.components.AppPart',
        data: {
          type: 'AppPart',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          appInnerID: params.appInnerID,
          appPartName: blogAppPartNames.ARCHIVE,
          viewName: 'ArchiveList',
          appLogicCustomizations: [],
          appLogicParams: {},
        },
        props: {
          type: 'AppPartProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          direction: 'ltr',
        },
        style: 'blog_56ab6fa4-95ac-4391-9337-6702b8a77011_1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: {
          width: 220,
          height: 33,
          x: 729,
          y: 609,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 1,
              topToTop: 34,
              originalValue: 632,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4b_3',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.WRichText',
        data: {
          type: 'StyledText',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          text: `<h6 style="line-height: 1.4em;" class="font_6"><span style="line-height: 1.4em;">${translate(
            'blog_default_page_archive',
          )}</span></h6>\n`,
          stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
          linkList: [],
        },
        style: 'txtNew',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
        layout: {
          width: 88,
          height: 23,
          x: 729,
          y: 840,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 233,
              topToTop: 862,
              originalValue: 925,
              type: 'BOTTOM_PARENT',
              locked: false,
              targetComponent: 'c5j0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.LinkBar',
        data: {
          type: 'ImageList',
          metaData: {
            isPreset: true,
            schemaVersion: '1.0',
            isHidden: false,
          },
          items: [
            {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Facebook Basic Square',
              uri: 'e316f544f9094143b9eac01f1f19e697.png',
              description: '',
              width: 205,
              height: 205,
              alt: '',
              link: {
                type: 'ExternalLink',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                url: 'https://www.facebook.com/wix',
                target: '_blank',
              },
            },
            {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Twitter Basic Square',
              uri: '9c4b521dd2404cd5a05ed6115f3a0dc8.png',
              description: '',
              width: 205,
              height: 205,
              alt: '',
              link: {
                type: 'ExternalLink',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                url: 'http://www.twitter.com/wix',
                target: '_blank',
              },
            },
            {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Google+ Basic Square',
              uri: '74d327d3628e4f14800301f1c61013b0.png',
              description: '',
              width: 205,
              height: 205,
              alt: '',
              link: {
                type: 'ExternalLink',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                },
                url: 'https://plus.google.com/117167403531518744294/posts',
                target: '_blank',
              },
            },
          ],
        },
        props: {
          type: 'LinkBarProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          gallery: 'social_icons',
          iconSize: 23,
          spacing: 7,
          bgScale: 1,
          orientation: 'HORIZ',
        },
        style: 'lb1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 826,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 9,
              topToTop: 14,
              originalValue: 839,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4b_0',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 734,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 7,
              originalValue: 740,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4a_1',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
      {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.line.SolidLine',
        layout: {
          width: 220,
          height: 5,
          x: 729,
          y: 643,
          scale: 1,
          rotationInDegrees: 0,
          anchors: [
            {
              distance: 2,
              topToTop: 7,
              originalValue: 639,
              type: 'BOTTOM_TOP',
              locked: true,
              targetComponent: 'i6bq6q4a_3',
            },
          ],
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.FiveGridLine',
        props: {
          type: 'FiveGridLineProperties',
          metaData: {
            schemaVersion: '1.0',
          },
          fullScreenModeOn: false,
        },
        style: 'hl1',
      },
    ],
    skin: 'wysiwyg.viewer.skins.page.BasicPageSkin',
    layout: {
      width: 980,
      height: 895,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [],
      fixedPosition: false,
    },
    componentType: 'wixapps.integration.components.AppPage',
    data: {
      type: 'AppPage',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      },
      title: 'Single Post',
      hideTitle: true,
      descriptionSEO: '',
      metaKeywordsSEO: '',
      pageTitleSEO: '',
      pageUriSEO: 'single-post',
      hidePage: true,
      mobileHidePage: true,
      underConstruction: false,
      tpaApplicationId: 0,
      pageSecurity: {
        requireLogin: false,
        passwordDigest: '',
      },
      indexable: true,
      isLandingPage: false,
      pageBackgrounds: editorAPI.components.data.get(
        editorAPI.components.get.byId(editorAPI.pages.getPrimaryPageId()),
      ).pageBackgrounds,
      appPageId: '7326bfbb-4b10-4a8e-84c1-73f776051e10',
      appPageType: 'AppPage',
      appInnerID: params.appInnerID,
      repeaterPage: true,
    },
    style: 'p2',
  };
}

function addBlogPage(editorAPI, viewName, origin, doneCB) {
  editorAPI.dsActions.wixapps.classics.provision(
    'blog',
    '61f33d50-3002-4882-ae86-d319c1a249ab',
    function (appDefinition) {
      const params = {
        appInnerID: `${appDefinition.applicationId}`,
        viewName: viewName || 'MediaLeftPage',
      };
      const blogPage = editorAPI.pages.add(
        translate('siteapp_blog_page'),
        getBlogFeedPageJSON(editorAPI, params),
      );
      editorAPI.pages.add(
        translate('siteapp_blog_single_post_page'),
        getSinglePostPageJSON(editorAPI, params),
      );

      const component_type = 'wixapps.integration.components.AppPage';
      editorWixRecorder.addLabel(`${component_type} added to stage`);
      editorAPI.bi.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
        app_id: appDefinition.applicationId,
        origin,
        category: 'blog',
        section: 'add_section_label_bloglayouts',
        component_type,
        component_id: viewName,
        preset_id: '',
        adding_method: 'click',
        preset_data_skin: '',
        preset_data_tags: '',
      });

      editorAPI.pages.navigateTo(blogPage.id);

      editorAPI.dsActions.waitForChangesApplied(function () {
        doneCB(blogPage.id);
      });
    },
  );
}

export { addBlogPage };

import type { EntryPoint } from '#packages/apilib';
import { BaseDragApiKey, SelectionApiKey } from '#packages/apis';
import { fedopsLogger } from '#packages/util';

export const MeasureComponentDragAndDropEntryPoint: EntryPoint = {
  name: 'MeasureComponentDragAndDrop',
  declareAPIs: () => [],
  getDependencyAPIs: () => [BaseDragApiKey, SelectionApiKey],
  async extend(shell) {
    shell.getAPI(BaseDragApiKey).hooks.dragStart.tap(() => {
      fedopsLogger.interactionStarted('component_drag_and_drop', {
        paramsOverrides: {
          component_type: shell
            .getAPI(SelectionApiKey)
            .getSelectedComponentType(),
        },
      });
    });
    shell.getAPI(BaseDragApiKey).hooks.dragEnd.tap(() => {
      fedopsLogger.interactionEnded('component_drag_and_drop', {
        paramsOverrides: {
          component_type: shell
            .getAPI(SelectionApiKey)
            .getSelectedComponentType(),
        },
      });
    });
  },
};

// @ts-nocheck
import * as componentsPlugins from './componentsPlugins';
import * as historyPlugins from './APISectionsPlugins/historyPlugins';
import siteSeoUpdateOperationPlugin from './updateOperationsPlugins/siteSeoUpdateOperationPlugin';
import type { EditorAPI } from '#packages/editorAPI';

export default {
  /**
   * @deprecated
   * @param editorAPI
   */
  register(editorAPI: EditorAPI) {
    componentsPlugins.register(editorAPI);
    historyPlugins.register(editorAPI);
    siteSeoUpdateOperationPlugin.register(editorAPI);
  },
};

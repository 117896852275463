import React, { Component, type ComponentType } from 'react';
import * as util from '#packages/util';
import { mapDispatchToProps } from './withOpenDashboardMapper';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export interface WithOpenDashboardProps {
  openDashboard: () => void;
}

export interface ComponentWithOpenDashboardProps {
  openDashboard: () => void;
}

const withOpenDashboard = <P extends WithOpenDashboardProps>(
  WrappedComponent: ComponentType<P & ComponentWithOpenDashboardProps>,
) => {
  class WithNavigateToDashboard extends Component<WithOpenDashboardProps> {
    render() {
      const { props } = this;

      return React.createElement(WrappedComponent, props as P);
    }
  }

  return connect(EDITOR_API, null, mapDispatchToProps)(WithNavigateToDashboard);
};

export default withOpenDashboard;

import React from 'react';
import { translate } from '#packages/i18n';
import { Button, CustomScroll, Text } from '@wix/wix-base-ui';
import styles from './WixAppsCollectionsEmptyState.scss';

interface WixAppsCollectionsEmptyStateProps {
  openContentManagerAdvancedSettings: () => void;
}

export const WixAppsCollectionsEmptyState = ({
  openContentManagerAdvancedSettings,
}: WixAppsCollectionsEmptyStateProps) => (
  <CustomScroll flex="1">
    <div
      className={styles.container}
      data-hook="wix-apps-collections-empty-state"
    >
      <h1 className={styles.mainTitle}>
        <Text
          weight="bold"
          size="large"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {translate(
            'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_MAIN_TITLE',
          )}
        </Text>
      </h1>

      <div>
        <h2 className={styles.paragraphTitle}>
          <Text
            weight="bold"
            size="medium"
            shouldTranslate={false}
            enableEllipsis={false}
          >
            {translate(
              'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_PARAGRAPH1_TITLE',
            )}
          </Text>
        </h2>

        <Text
          weight="normal"
          size="small"
          shouldTranslate={false}
          enableEllipsis={false}
        >
          {translate(
            'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_PARAGRAPH1_BODY',
          )}
        </Text>
      </div>

      <div>
        <h2 className={styles.paragraphTitle}>
          <Text
            weight="bold"
            size="medium"
            shouldTranslate={false}
            enableEllipsis={false}
          >
            {translate(
              'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_PARAGRAPH2_TITLE',
            )}
          </Text>
        </h2>

        <Text
          weight="normal"
          size="small"
          shouldTranslate={false}
          enableEllipsis={false}
        >
          {translate(
            'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_PARAGRAPH2_BODY',
          )}
        </Text>
      </div>

      <div>
        <h2 className={styles.paragraphTitle}>
          <Text
            weight="bold"
            size="medium"
            shouldTranslate={false}
            enableEllipsis={false}
          >
            {translate(
              'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_PARAGRAPH3_TITLE',
            )}
          </Text>
        </h2>

        <Text
          weight="normal"
          size="small"
          shouldTranslate={false}
          enableEllipsis={false}
        >
          {translate(
            'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_PARAGRAPH3_BODY',
          )}
        </Text>
      </div>

      <div className={styles.buttonContainer}>
        <Button
          className="btn-confirm-secondary"
          onClick={openContentManagerAdvancedSettings}
          dataHook="open-advanced-settings-btn"
        >
          {translate(
            'CONTENT_MANAGER_6_BUTTON_WIX_APP_COLLECTIONS_EMPTY_STATE_BUTTON_LABEL',
          )}
        </Button>
      </div>
    </div>
  </CustomScroll>
);

import {
  EditorAPIKey,
  PanelManagerApiKey,
  BiApiKey,
  AccountApiKey,
  MobileEditorApiKey,
  ConcurrentUsersApiKey,
  SiteApiKey,
  SectionizerApiKey,
  EditorCoreApiKey,
} from '#packages/apis';

import { createSavePublishApi } from './savePublishApi';
import { createSaveManagerApi } from './managers/saveManagerApi';
import { createPublishManagerApi } from './managers/publishManagerApi';
import { createAutosaveManagerApi } from './managers/autosaveManagerApi';

import type { EntryPoint } from '#packages/apilib';
import {
  AutosaveManagerApiKey,
  PublishManagerApiKey,
  SaveManagerApiKey,
  SavePublishApiKey,
} from './publicApiKey';

export const SavePublishEntryPoint: EntryPoint = {
  name: 'SavePublishApi',
  declareAPIs: () => [
    SavePublishApiKey,
    SaveManagerApiKey,
    PublishManagerApiKey,
    AutosaveManagerApiKey,
  ],
  getDependencyAPIs: () => [
    EditorAPIKey,
    BiApiKey,
    SiteApiKey,
    AccountApiKey,
    ConcurrentUsersApiKey,
    MobileEditorApiKey,
    PanelManagerApiKey,
    SectionizerApiKey,
    EditorCoreApiKey,
  ],
  attach(shell) {
    shell.contributeAPI(SavePublishApiKey, () => createSavePublishApi(shell));
    shell.contributeAPI(SaveManagerApiKey, () =>
      createSaveManagerApi(shell, console),
    );
    shell.contributeAPI(PublishManagerApiKey, () =>
      createPublishManagerApi(shell),
    );
    shell.contributeAPI(AutosaveManagerApiKey, () =>
      createAutosaveManagerApi(shell),
    );
  },
};

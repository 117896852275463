import { ADVANCED_MENU_TYPE, PAGES_MENU_ID } from '../../constants';
import { isMembersMenuId } from '../../utils/utils';
import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '#packages/editorAPI';
import type { MenuAPI } from '../../API/menuAPI';
import type { DataForMigration } from '../getMenusUsageData';

const ITEM_NUMBER_AFTER_WHICH_TO_HIDE_ITEMS = 10;

export const migrateCustomProMenusToAdvancedMenus = (
  editorAPI: EditorAPI,
  menuAPI: MenuAPI,
  menuComps: CompRef[],
  dataForMigration: DataForMigration,
) => {
  const {
    pagesMenu: { items: pagesMenuItems },
  } = dataForMigration;

  if (pagesMenuItems.length > ITEM_NUMBER_AFTER_WHICH_TO_HIDE_ITEMS) {
    const newPagesMenuItems = pagesMenuItems.map((item, index) =>
      index < ITEM_NUMBER_AFTER_WHICH_TO_HIDE_ITEMS
        ? item
        : { ...item, isVisible: false, isVisibleMobile: false },
    );
    menuAPI.replaceMenuItems(PAGES_MENU_ID, newPagesMenuItems);
  }

  menuComps.forEach((comp) => {
    const compMenu = menuAPI.getMenuByCompRef(comp);

    if (
      isMembersMenuId(compMenu.id) ||
      compMenu.menuType === ADVANCED_MENU_TYPE
    ) {
      return;
    }

    editorAPI.temporaryAdvancedMenuAPI.createMenu(
      comp,
      menuAPI.getMenuByCompRef(comp).items,
    );
  });

  return editorAPI.waitForChangesAppliedAsync();
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { PanelHeader } from '@wix/wix-base-ui';

import constants from '#packages/constants';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import { cx } from '#packages/util';

const { panelDragLimits, panelFrame } = stateManagement.panels.hoc;

// eslint-disable-next-line react/prefer-es6-class
const BgSubPanelFrame = createReactClass({
  displayName: constants.PANEL_TYPES.BG_SUB_PANEL,
  mixins: [util.draggableMixin],
  propTypes: {
    panelName: PropTypes.string.isRequired,
    title: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    top: PropTypes.number,
    left: PropTypes.number,
    getPanelDragLimits: PropTypes.func.isRequired,
    contentClass: PropTypes.string,
  },
  getPanelProps() {
    const styleKeys = ['width', 'height', 'top', 'left'];
    const style = {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      styleKeys,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (styleKey) {
        if (this.props[styleKey]) {
          style[styleKey] = this.props[styleKey];
        }
      }, this),
    );

    const { className } = this.props;
    return { style, className };
  },
  render() {
    return (
      <div
        {...this.getPanelProps()}
        className={cx(
          'background-sub-panel-frame',
          this.getPanelProps()?.className,
        )}
      >
        {this.props.label ? (
          <PanelHeader
            ref="header"
            key="header"
            onMouseDown={(e) => {
              this.startDrag(e, this.props.getPanelDragLimits());
            }}
            onClose={this.props.close}
            onHelp={this.props.openHelpCenter}
            className="dragger dark"
          >
            {this.props.label ? (
              <span key="label">{translate(this.props.label)}</span>
            ) : null}
          </PanelHeader>
        ) : null}
        <div
          className={`content${
            this.props.contentClass ? ` ${this.props.contentClass}` : ''
          }`}
        >
          {this.props.children}
        </div>
      </div>
    );
  },
});

const WrappedFrame = _.flow(
  panelFrame(constants.PANEL_TYPES.BG_SUB_PANEL),
  panelDragLimits(),
)(BgSubPanelFrame);

WrappedFrame.pure = BgSubPanelFrame;
export default WrappedFrame;

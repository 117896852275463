import _ from 'lodash';
import { link } from '#packages/util';

import { MENU_ITEM_TYPE } from '../../constants';
import { isDropdown as checkIsDropdown } from '../../utils/utils';

import type { MenuItem } from 'types/documentServices';

const {
  linkTypeValidators: { isPageLink: checkIsPageLink, isDynamicPageLink },
} = link;

interface IPagesPanelMenuItem extends Omit<MenuItem, 'type'> {
  [i: string]: any;
}
type MenuItemForCreateMenu = Omit<MenuItem, 'id' | 'items'> & {
  items: MenuItemForCreateMenu[];
};

type IItemsGetter = (menuItems: MenuItem[]) => MenuItemForCreateMenu[];

const menuItemsMapper = (
  { label, link, metaData, items }: MenuItem,
  getter: IItemsGetter,
) => ({
  // pick only needed fields. If there could be any other field, viewer/ds could throw errors
  isVisible: true,
  isVisibleMobile: true,
  label,
  link,
  metaData,
  type: MENU_ITEM_TYPE,
  items: getter(items),
});

export const getDesktopMenuItems: IItemsGetter = (menuItems) =>
  menuItems
    .filter((menuItem) => menuItem.isVisible)
    .map((menuItem) => menuItemsMapper(menuItem, getDesktopMenuItems));

export const getMobileMenuItems: IItemsGetter = (menuItems) =>
  menuItems
    .filter((menuItem) => menuItem.isVisibleMobile)
    .map((menuItem) => menuItemsMapper(menuItem, getMobileMenuItems));

// pick only needed fields. If there could be any other field, viewer/ds could throw errors
const pageMenuItemPropsForMigration = [
  'id',
  'isVisible',
  'isVisibleMobile',
  'label',
  'link',
  'metaData',
  'items',
] as const;

export const transformPagesPanelStructure = (
  menuItems: IPagesPanelMenuItem[],
): MenuItem[] => {
  return menuItems.reduce((acc, pagesMenuItem) => {
    const menuItem = _.pick(pagesMenuItem, pageMenuItemPropsForMigration);
    const isPageLink = checkIsPageLink(menuItem.link);
    const isDynamicLink = isDynamicPageLink(menuItem.link);
    const isDropdown = checkIsDropdown(menuItem);

    const newChildren = transformPagesPanelStructure(menuItem.items);

    if (isPageLink || isDynamicLink) {
      acc.push({
        ...menuItem,
        items: newChildren,
        type: MENU_ITEM_TYPE,
      });
    } else if (newChildren.length > 0) {
      acc.push({
        ...menuItem,
        items: newChildren,
        type: MENU_ITEM_TYPE,
        link: isDropdown ? menuItem.link : undefined,
      });
    }

    return acc;
  }, []);
};

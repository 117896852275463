import * as stateManagement from '#packages/stateManagement';
import { getIsLabelBottom } from '../selectors/isLabelBottom';
import { getComponentUIColor } from '../selectionBox/editBox/common';
const { getComponentInteractionIfExists } =
  stateManagement.interactions.selectors;

const OUTLINE_BORDER_WIDTH = 3;

export const mapStateToProps = (
  { editorAPI }: AnyFixMe,
  ownProps: AnyFixMe,
) => {
  const { containerWithInteraction } = ownProps;
  const containerLayout = editorAPI.components.layout.getRelativeToScreen(
    containerWithInteraction,
  );
  const compInteraction = getComponentInteractionIfExists(editorAPI, [
    containerWithInteraction,
  ]);
  const outlineStyles = {
    top: containerLayout.y - OUTLINE_BORDER_WIDTH,
    left: containerLayout.x - OUTLINE_BORDER_WIDTH,
    width: containerLayout.width,
    height: containerLayout.height,
  };
  return {
    outlineStyles,
    compInteraction,
    isLabelBottom: getIsLabelBottom(editorAPI, containerWithInteraction),
    componentUIColor: getComponentUIColor(editorAPI, containerWithInteraction),
  };
};

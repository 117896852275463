import experiment from 'experiment';
import { WixCodeApiKey } from '#packages/apis';
import { serviceTopology } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { loadAutoCompleteData } from './autoComplete';

import type { Shell } from '#packages/apilib';
import type { WixCodeApi } from '../leftPublicApiKeys';

type WixCodeApiWithDefinitions = WixCodeApi & {
  definitions: {
    add: (definition: { fileName: string; content: string }) => void;
    remove: (definition: { fileName: string }) => void;
  };
};

interface DataWithAutoComplete {
  autoComplete: { definition: string };
}

const hasDefinitions = (
  wixCodeApi: any,
): wixCodeApi is WixCodeApiWithDefinitions =>
  wixCodeApi.definitions !== undefined;

const hasAutoCompleteDefinition = (data: any): data is DataWithAutoComplete =>
  data.autoComplete?.definition !== undefined;

export async function initAutoComplete(shell: Shell) {
  const wixCodeApi = shell.getAPI(WixCodeApiKey);

  await new Promise((resolve) =>
    wixCodeApi.registerToCodeEditorLoaded(resolve),
  );

  try {
    const autoCompleteDataMap = await loadAutoCompleteData({
      serviceTopology,
    });

    Object.entries(autoCompleteDataMap).forEach(([componentName, data]) => {
      const fileName = `${componentName}.dts`;
      if (hasDefinitions(wixCodeApi) && hasAutoCompleteDefinition(data)) {
        wixCodeApi.definitions.remove({ fileName });
        wixCodeApi.definitions.add({
          fileName,
          content: data.autoComplete.definition,
        });
      }
    });
  } catch (error) {
    console.error(
      '[wixCode-initAutoComplete]: wixCode autoComplete initialization failed',
      error,
    );
    ErrorReporter.captureException(error, {
      tags: { operation: 'wixCode-initAutoComplete' },
    });
  }
}

export function init(shell: Shell) {
  if (experiment.isOpen('se_AutoCompleteRegistry')) {
    void initAutoComplete(shell);
  }
}

import constants from '#packages/constants';
import { isMeshLayoutEnabled } from '#packages/layout';
import * as platformEvents from 'platformEvents';
import * as util from '#packages/util';
import type { CompRef } from 'types/documentServices';
import type { HistoryAddOptions } from '#packages/history';

const { asArray } = util.array;

type MoveForwardOptions = HistoryAddOptions & {};
type MoveBackwardOptions = HistoryAddOptions & {};
type MoveToFrontOptions = HistoryAddOptions & {};
type MoveToBackOptions = HistoryAddOptions & {};
type MoveToIndexOptions = HistoryAddOptions & {};

function create(editorAPI: AnyFixMe) {
  function historyAdd(label: string, options?: HistoryAddOptions) {
    if (options?.dontAddToUndoRedoStack) {
      return;
    }

    editorAPI.history.add(label);
  }

  function getCompIndex(compRef: CompRef): number {
    return editorAPI.dsRead.components.arrangement.getCompIndex(compRef);
  }

  function moveForward(compRef: CompRef, options?: MoveForwardOptions): void {
    const components = asArray(compRef);
    if (!editorAPI.components.arrangement.canMoveForward(components)) {
      return null;
    }

    editorAPI.dsActions.components.arrangement.moveForward(components[0]);
    historyAdd('component - move forward', options);

    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.componentArrangementChanged({
        compRef: components[0],
      }),
    );
  }

  function moveBackward(compRef: CompRef, options?: MoveBackwardOptions): void {
    const components = asArray(compRef);

    if (!editorAPI.components.arrangement.canMoveBackward(components)) {
      return null;
    }

    editorAPI.dsActions.components.arrangement.moveBackward(components[0]);
    historyAdd('component - move backward', options);

    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.componentArrangementChanged({
        compRef: components[0],
      }),
    );
  }

  function moveToFront(compRef: CompRef, options?: MoveToFrontOptions): void {
    const components = asArray(compRef);

    if (!editorAPI.components.arrangement.canMoveForward(components)) {
      return null;
    }

    editorAPI.dsActions.components.arrangement.moveToFront(components[0]);
    historyAdd('component - move to front', options);

    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.componentArrangementChanged({
        compRef: components[0],
      }),
    );
  }

  function moveToBack(compRef: CompRef, options?: MoveToBackOptions): void {
    const components = asArray(compRef);

    if (!editorAPI.components.arrangement.canMoveBackward(components)) {
      return null;
    }

    editorAPI.dsActions.components.arrangement.moveToBack(components[0]);
    historyAdd('component - move to back', options);

    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.componentArrangementChanged({
        compRef: components[0],
      }),
    );
  }

  /** @param dontAddToUndoRedoStack - deprecated. use options.dontAddToUndoRedoStack instead */
  function moveToIndex(compRef: CompRef, index: number, dontAddToUndoRedoStack?: boolean): void; // eslint-disable-line prettier/prettier
  /** @param options.dontAddToUndoRedoStack - if true, the action will not be added to the undo/redo stack */
  function moveToIndex(compRef: CompRef, index: number, options?: MoveToIndexOptions): void; // eslint-disable-line prettier/prettier

  function moveToIndex(
    compRefOrRefs: CompRef | CompRef[],
    index: number,
    options?: MoveToIndexOptions | boolean,
  ) {
    if (typeof options === 'boolean') {
      options = { dontAddToUndoRedoStack: options };
    }

    const compRefs = asArray(compRefOrRefs);
    if (compRefs.length === 0 || compRefs.length > 1 || !compRefs[0]) {
      return;
    }

    const [compRef] = compRefs;

    if (isMeshLayoutEnabled()) {
      // TODO: rewrite with interceptor instead of direct call
      return editorAPI.components.layout.__mesh.arrangement.moveToIndexTransaction(
        compRef,
        index,
        options,
      );
    }

    editorAPI.dsActions.components.arrangement.moveToIndex(compRef, index);

    historyAdd('component - move to index', options);
  }

  const forwardBackwardBlacklist = isMeshLayoutEnabled()
    ? new Set([constants.COMP_TYPES.ANCHOR, constants.COMP_TYPES.SECTION])
    : new Set([constants.COMP_TYPES.ANCHOR]);

  function canMoveBackward(compRefOrRefs: CompRef | CompRef[]): boolean {
    const compRefs = asArray(compRefOrRefs);
    if (compRefs.length === 0 || compRefs.length > 1 || !compRefs[0]) {
      return false;
    }

    const [compRef] = compRefs;
    if (
      !editorAPI.components.is.arrangeable(compRef) ||
      forwardBackwardBlacklist.has(editorAPI.components.getType(compRef))
    ) {
      return false;
    }

    return editorAPI.dsRead.components.arrangement.canMoveBackward(compRef);
  }

  function canMoveForward(compRefOrRefs: CompRef | CompRef[]): boolean {
    const compRefs = asArray(compRefOrRefs);
    if (compRefs.length === 0 || compRefs.length > 1 || !compRefs[0]) {
      return false;
    }

    const [compRef] = compRefs;
    if (
      !editorAPI.components.is.arrangeable(compRef) ||
      forwardBackwardBlacklist.has(editorAPI.components.getType(compRef))
    ) {
      return false;
    }

    return editorAPI.dsRead.components.arrangement.canMoveForward(compRef);
  }

  return {
    getCompIndex,
    moveForward,
    moveBackward,
    moveToFront,
    moveToBack,
    moveToIndex,
    canMoveBackward,
    canMoveForward,
  };
}

export { create };

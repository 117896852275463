import React from 'react';

import constants from '#packages/constants';
import { hoc } from '#packages/util';

import ComponentLabels from '../componentLabels/componentLabels';

import {
  mapStateToProps,
  type HoveredComponentLabelsStateProps,
  type HoveredComponentLabelsOwnProps,
} from './hoveredComponentLabelsMapper';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export interface HoveredComponentLabelsProps
  extends HoveredComponentLabelsStateProps,
    HoveredComponentLabelsOwnProps {}

class HoveredComponentLabels extends React.Component<HoveredComponentLabelsProps> {
  isLabelBottom() {
    const { isLabelBottom, calculateIsLabelBottom, compPointer } = this.props;

    if (typeof isLabelBottom === 'undefined') {
      if (calculateIsLabelBottom) {
        return calculateIsLabelBottom(compPointer);
      }
    }

    return isLabelBottom;
  }

  isSiteHeaderHovered() {
    return this.props.compPointer.id === 'SITE_HEADER';
  }

  isRootComponent() {
    const { compPointer, focusedPageId } = this.props;

    return (
      compPointer.id === 'SITE_FOOTER' ||
      this.isSiteHeaderHovered() ||
      compPointer.id === focusedPageId
    );
  }

  getComponentType() {
    return this.props.isHoveredSelectedComp ? '' : this.props.displayName;
  }

  shouldShowComponentType() {
    const { compPointer, enableDevMode, showConnectIndication } = this.props;
    return compPointer && !(enableDevMode && showConnectIndication);
  }

  isCompPinned() {
    const { isPinned, isHoveredSelectedComp } = this.props;
    return isPinned && !isHoveredSelectedComp;
  }

  getNicknameProps() {
    const {
      mainCompRef,
      enableDevMode,
      isHoveredSelectedComp,
      compNickname,
      compSecondaryNickname,
    } = this.props;

    if (
      !mainCompRef ||
      !enableDevMode ||
      isHoveredSelectedComp ||
      !compNickname
    ) {
      return {
        showComponentNickname: false,
      };
    }

    return {
      showComponentNickname: true,
      componentNickname: `#${compNickname}`,
      ...(compSecondaryNickname && {
        secondaryNickname: `#${compSecondaryNickname}`,
      }),
    };
  }

  render() {
    const {
      automationId,
      isMobileOnlyNonNativeComponent,
      componentUIColor,
      showConnectIndication,
    } = this.props;

    return (
      <ComponentLabels
        key="newComponentLabel"
        automationId={automationId}
        isMobileOnlyNonNativeComponent={isMobileOnlyNonNativeComponent}
        showComponentType={this.shouldShowComponentType()}
        infoText="PLATFORM_ONSTAGE_CONNECTED_ELEMENT_LABEL"
        isLabelLarge={this.isRootComponent()}
        isLabelBelowLine={this.isSiteHeaderHovered()}
        componentType={this.getComponentType()}
        labelInlineStyles={this.props.hoverBoxLabelStyles}
        componentUIColor={componentUIColor}
        componentUITheme={constants.COMPONENT_UI_THEMES.LIGHT}
        isPinnedToPage={this.isCompPinned()}
        isLabelBottom={this.isLabelBottom()}
        shouldShowConnectionIndication={showConnectIndication}
        {...this.getNicknameProps()}
      />
    );
  }
}

const Connected = connect(EDITOR_API, mapStateToProps)(HoveredComponentLabels);

Connected.pure = HoveredComponentLabels;

export default Connected;

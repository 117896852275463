import type { MediaManagerImage } from '#packages/mediaServices';

const DEFAULT_DESKTOP_IMAGE_WIDTH = 600;
export const DEFAULT_MOBILE_IMAGE_WIDTH = 280;
export const DEFAULT_IMAGE_COMP_TYPE = 'wysiwyg.viewer.components.WPhoto';

export const getImageCompDef = (
  componentType: string = DEFAULT_IMAGE_COMP_TYPE,
  imageItem: Partial<MediaManagerImage>,
  isMobileEditor: boolean,
) => {
  const width = Math.min(
    isMobileEditor ? DEFAULT_MOBILE_IMAGE_WIDTH : DEFAULT_DESKTOP_IMAGE_WIDTH,
    imageItem.width,
  );

  return {
    componentType,
    layout: {
      width,
      height: getProportionalImageHeight(
        width,
        imageItem.width,
        imageItem.height,
      ),
    },
    data: {
      width: imageItem.width,
      height: imageItem.height,
      alt: imageItem.title,
      name: imageItem.title,
      uri: imageItem.fileName,
      type: 'Image',
      description: '',
    },
  };
};

export const getProportionalImageHeight = (
  imageWidth: number,
  originalWidth: number,
  originalHeight: number,
): number => Math.round((imageWidth * originalHeight) / originalWidth);

// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import { isMeshLayoutEnabled } from '#packages/layout';
import { layoutTransitionsUtil } from '#packages/layoutUtils';
import * as pushingComponentSnapper from './pushingComponentSnapper';
import * as dragUtils from './dragUtils';
import type { EditorAPI } from '#packages/editorAPI';

const { translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;

let dragTransition: layoutTransitionsUtil.Drag;
let meshMoveByAndPushTransition: ReturnType<
  EditorAPI['components']['layout']['__mesh']['moveByAndPushTransition']
>;
let selectedComponent: CompRef[];
let editorAPI: EditorAPI;
let layoutByComp: Rect[];
let minYPossible = 0;
let onEndCallback;

function isLayoutAbove(layout, possibleLayoutAbove) {
  return possibleLayoutAbove.y + possibleLayoutAbove.height <= layout.y;
}

function getClosestSibling(editedCompLayout) {
  const siblings = editorAPI.components.getSiblings(selectedComponent);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const siblingsLayoutsAbove = _(siblings)
    .map((comp) => editorAPI.components.layout.getRelativeToScreen(comp))
    .filter((layout) => isLayoutAbove(editedCompLayout, layout))
    .value();

  if (siblingsLayoutsAbove.length === 0) {
    return 0;
  }

  const lowestSiblingAbove = _.maxBy(
    siblingsLayoutsAbove,
    (layout) => layout.y + layout.height,
  );
  return lowestSiblingAbove.y + lowestSiblingAbove.height;
}

function getMinYPossible(editedCompLayout) {
  const container = editorAPI.components.getContainer(selectedComponent);
  const containerLayout =
    editorAPI.components.layout.getRelativeToScreen(container);
  const closestSibling = getClosestSibling(editedCompLayout);
  return Math.max(closestSibling, containerLayout.y);
}

function startDrag(_editorAPI, params) {
  editorAPI = _editorAPI;
  editorAPI.components.layout.setLayoutReadFromDOM(true);
  selectedComponent = dragUtils.getCompToBeDragged(editorAPI, params.comps);
  pushingComponentSnapper.init(editorAPI, selectedComponent, {
    top: true,
    bottom: true,
    centerY: true,
  });

  onEndCallback = params.onDragAndPushEnd;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const selectedCompIds = _.map(selectedComponent, 'id');
  editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(selectedCompIds);

  const initLayoutRelativeToScreen = _.omit(
    editorAPI.components.layout.getRelativeToScreen(selectedComponent),
    'bounding',
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const boundCompLayoutsRelativeToScreen = _.map(selectedComponent, (comp) =>
    _.omit(editorAPI.components.layout.getRelativeToScreen(comp), 'bounding'),
  );

  minYPossible = getMinYPossible(initLayoutRelativeToScreen);
  const mouseCoordinates = translateToViewerCoordinates(editorAPI, params.evt);
  const initMouse = { x: mouseCoordinates.pageX, y: mouseCoordinates.pageY };
  dragTransition = new layoutTransitionsUtil.Drag(
    initLayoutRelativeToScreen,
    initMouse,
    boundCompLayoutsRelativeToScreen,
  );

  if (isMeshLayoutEnabled(editorAPI)) {
    if (selectedComponent.length > 1) {
      throw new Error(
        'Mesh layout is not supported for drag and push of multiple components',
      );
    }

    meshMoveByAndPushTransition =
      editorAPI.components.layout.__mesh.moveByAndPushTransition(
        selectedComponent[0],
      );
    return;
  }

  editorAPI.dsActions.components.layout.updateAndPushStart(
    selectedComponent[0],
  );
}

function onDrag(event) {
  const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
  const mouse = { x: mouseCoordinates.pageX, y: mouseCoordinates.pageY };
  const layout = dragTransition.getLayout(mouse);

  layout.y = Math.max(layout.y, minYPossible);
  pushingComponentSnapper.snapIfPossible(editorAPI, layout);

  layoutByComp = dragTransition.getBoundComponentLayouts(layout);

  if (isMeshLayoutEnabled(editorAPI)) {
    const { deltaY } = dragTransition.getLayoutDelta(layout);

    meshMoveByAndPushTransition.update({ deltaY });
  }

  for (let i = 0; i < selectedComponent.length; i++) {
    if (isMeshLayoutEnabled()) {
      // already handled above
    } else {
      editorAPI.components.layout.dragAndPush(
        selectedComponent[i],
        _.pick(layoutByComp[i], 'y'),
      );
    }
    editorAPI.notifyViewerUpdate(['components.layout.runtime.updateAndPush']);
  }

  const minimalEditorAPI = {
    waitForChangesApplied: editorAPI.dsActions.waitForChangesApplied,
    getRelativeToScreen: editorAPI.components.layout.getRelativeToScreen,
    snapToEnabled: editorAPI.getViewTools().snapToEnabled,
    snapDataSetter: editorAPI.snapData.set,
  };
  pushingComponentSnapper.waitForChangesAppliedAndDrawSnapLines(
    minimalEditorAPI,
    layout,
    selectedComponent,
  );
}

async function endDrag() {
  layoutByComp = null;
  pushingComponentSnapper.clear(editorAPI);

  if (isMeshLayoutEnabled()) {
    meshMoveByAndPushTransition.end();
  } else {
    editorAPI.dsActions.components.layout.updateAndPushEnd(
      selectedComponent[0],
    );
    editorAPI.history.debouncedAdd('component - update layout');
    editorAPI.components.layout.setLayoutReadFromDOM(false);
  }

  editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(null);
  if (_.isFunction(onEndCallback)) {
    onEndCallback();
  }
  return;
}

export default {
  start: startDrag,
  on: onDrag,
  end: endDrag,
  type: constants.MOUSE_ACTION_TYPES.DRAG_PUSH,
};

import { TextClassificationApi } from './textClassificationApi';
import { EditorAPIKey, TextClassificationApiKey } from '#packages/apis';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const TextClassificationApiEntryPoint = createEntryPoint({
  name: 'TextClassificationApi',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(TextClassificationApiKey, TextClassificationApi);
  },
  initialize() {},
});

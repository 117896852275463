import React from 'react';
import { PERMISSIONS_TAB_HOOKS } from '../../../../../utils/dataHooks';
import { PermissionSection } from '../CommonComponents';

import type { RolesPanelProps } from '../../permissionsTabMapper';
import type { Group } from '../../../../../utils/groupsApi';

interface BadgesSectionProps {
  badgesForPage: Required<Group>[];
  availableBadges: Required<Group>[];
  permissions?: string[];
  disabled: boolean;
  openRolesPanel: (panelProps: RolesPanelProps) => void;
  openDashboardModal: (path: string) => void;
  updateGroupsPermissions: (groupsToUpdate: Group[]) => Promise<void>;
}

export const BadgesSection: React.FC<BadgesSectionProps> = ({
  openRolesPanel,
  openDashboardModal,
  updateGroupsPermissions,
  disabled,
  availableBadges,
  permissions,
  badgesForPage,
}) => {
  const hasCreatedNewBadge = availableBadges.length > 0;
  const canCreateNewBadge = permissions?.includes('badges.manage');

  const openBadgesDashboard = () =>
    openDashboardModal(
      '/badge-definitions/new?referralInfo=editor&viewMode=editor',
    );

  const openBadgesPanel = () =>
    openRolesPanel({
      panelName: 'panels.toolPanels.rolesManagerPanel',
      groups: availableBadges,
      headerTitle: 'NewPages_Badges_Manager_Header_Title',
      selectedGroups: badgesForPage,
      applyButtonLabel: 'NewPages_Badges_Manager_Apply_Button',
      applyButtonCallback: updateGroupsPermissions,
      addNewButtonLabel: 'NewPages_Badges_Manager_Add_New_Badge',
      addNewButtonCallback: openBadgesDashboard,
      disableAddNew: !canCreateNewBadge,
    });

  const disconnectBadges = async () => {
    const groupsToRemove = badgesForPage.map((group) => ({
      ...group,
      checked: false,
    }));

    await updateGroupsPermissions(groupsToRemove);
  };

  return (
    <PermissionSection
      dataHook={PERMISSIONS_TAB_HOOKS.BADGES_PERMISSION_SECTION}
      title="NewPages_Panel_Permissions_Badges_Section_Title"
      subtitle="NewPages_Panel_Permissions_Badges_Section_Subtitle"
      disabled={disabled}
      disconnectGroups={disconnectBadges}
      hasAvailableGroups={hasCreatedNewBadge}
      emptyText="NewPages_Panel_Permissions_Badges_Section_Placeholder"
      createText="NewPages_Panel_Permissions_Badges_Section_Create_Button"
      canCreateNew={canCreateNewBadge}
      onCreate={openBadgesDashboard}
      connectedGroups={badgesForPage}
      connectText="NewPages_Panel_Permissions_Badges_Section_Choose_Button"
      onConnect={openBadgesPanel}
    />
  );
};

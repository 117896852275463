import React from 'react';
import { Button, PromotionalList, TextLabel } from '@wix/wix-base-ui';
import experiment from 'experiment';

import * as symbols from '@wix/santa-editor-symbols';

import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import * as util from '#packages/util';

import PromotionalPanel from './promotionalPanel/promotionalPanel';

const LIST_ITEMS_OFF = [
  translate('Topbar_Arena_DropMenu_Bullet1'),
  translate('Topbar_Arena_DropMenu_Bullet2'),
  translate('Topbar_Arena_DropMenu_Bullet3'),
  translate('Topbar_Arena_DropMenu_Bullet4'),
];

const ORIGIN = 'editor-top-menu';

const ALTERNATIVE_ARENA_ENDPOINT = experiment.isOpen(
  'specs.SupportMultipleServicesBrief',
)
  ? `https://www.wix.com/marketplace/multi/site/briefRoot?origin=${ORIGIN}`
  : `https://www.wix.com/marketplace/brief-auto-match?entry=editor&origin=${ORIGIN}`;

const CHECK_SYMBOL = React.createElement(symbols.symbol, { name: 'check' });

export const openArena = () => {
  window.open(ALTERNATIVE_ARENA_ENDPOINT, '_blank');
};

interface ArenaDropPanelProps {
  sendBi(event: any, params?: any): void;
}

export class ArenaDropPanel extends React.Component<ArenaDropPanelProps> {
  displayName = 'ArenaDropPanel';

  onButtonClick = () => {
    openArena();
    this.props.sendBi(coreBi.events.topbar.arena_button_clicked, {
      origin: 'top bar',
    });
  };

  renderFooter = () => {
    return (
      <Button
        automationId="arena-drop-down-btn"
        onClick={this.onButtonClick}
        className="btn-confirm-primary"
      >
        <span>{translate('Topbar_Arena_DropMenu_Start_Button')}</span>
      </Button>
    );
  };

  render() {
    if (util.workspace.isNewWorkspaceEnabled()) {
      return (
        <PromotionalPanel
          automationId="drop-panel-arena"
          title={translate('Topbar_Arena_DropMenu_Professional_Title')}
          subtitle={translate('Topbar_Arena_DropMenu_Subtitle')}
          listItems={LIST_ITEMS_OFF}
          symbol={<symbols.symbol name="hirePartner_NewWorkspace" />}
          renderFooter={this.renderFooter}
        />
      );
    }

    return (
      <div data-aid="drop-panel-arena" className="arena-drop-panel-content">
        <div className="header-container">
          <TextLabel
            value={'Topbar_Arena_DropMenu_Professional_Title'}
            enableEllipsis={false}
            type="T09"
          />
        </div>
        <div className="middle-container">
          <div className="text-container">
            <TextLabel
              value="Topbar_Arena_DropMenu_Subtitle"
              enableEllipsis={false}
              type="T07"
              className="subtitle"
            />

            <PromotionalList
              key="text-arena-description"
              items={LIST_ITEMS_OFF}
              symbol={CHECK_SYMBOL}
              shouldTranslate={false}
            />
          </div>
          <div className="symbol-container">
            <symbols.symbol
              key="arenaIllustrationSvg"
              name="arenaIllustration"
            />
          </div>
        </div>
        <div key="arena-button-wrapper" className="footer-container">
          <div key="regularFooter" className="regular-footer">
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }
}

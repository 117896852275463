import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import { sections } from '#packages/util';
import { isCompClipboardItem } from '#packages/clipboard';

import * as commonKeyboardActions from '../util/commonKeyboardActions';
import {
  pasteFromKeyboard,
  removeComponentFromKeyboard,
} from './baseEditorShortcuts';
import {
  copyComponentsFromKeyboard,
  duplicateComponentFromKeyboard,
  cutComponentFromKeyboard,
} from './editorShortcuts';

import type { EditorAPI } from '#packages/editorAPI';

const WORKSPACE_MODE_LITE_SHORTCUTS_CONFIG = {};

const { editor: editorKeyboardMap } = keyboardShortcutsActionMap;

function addWorkspaceModeLiteShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};

  keysToMerge[editorKeyboardMap.UNDO.key] = () =>
    commonKeyboardActions.undoFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.REDO.key] = () =>
    commonKeyboardActions.redoFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.SAVE.key] = () =>
    commonKeyboardActions.saveSiteFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.SAVE_AS_TEMPLATE.key] = () =>
    commonKeyboardActions.saveSiteAsTemplateFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.PUBLISH_SITE.key] = (event: KeyboardEvent) =>
    commonKeyboardActions.publishSiteFromKeyboard(editorAPI, event);

  if (sections.isSectionsEnabled()) {
    const canPaste = () => {
      const clipboardItem = editorAPI.clipboard.getItem();

      if (!isCompClipboardItem(clipboardItem)) return false;

      return clipboardItem.value.components.every((structure) =>
        editorAPI.sections.isSectionByCompType(structure.componentType),
      );
    };

    keysToMerge[editorKeyboardMap.COPY.key] = () =>
      copyComponentsFromKeyboard(editorAPI);

    keysToMerge[editorKeyboardMap.PASTE.key] = () => {
      if (!canPaste()) return;
      pasteFromKeyboard(editorAPI, false);
    };

    keysToMerge[editorKeyboardMap.PASTE_AND_APPLY_THEME.key] = () => {
      if (!canPaste()) return;
      pasteFromKeyboard(editorAPI, true);
    };

    keysToMerge[editorKeyboardMap.DUPLICATE.key] = () =>
      duplicateComponentFromKeyboard(editorAPI);

    keysToMerge[editorKeyboardMap.CUT.key] = () =>
      cutComponentFromKeyboard(editorAPI);

    keysToMerge[editorKeyboardMap.DELETE.key] = () =>
      removeComponentFromKeyboard(editorAPI);
  }

  Object.assign(WORKSPACE_MODE_LITE_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addWorkspaceModeLiteShortcuts(editorAPI);
  },
  shortcuts: WORKSPACE_MODE_LITE_SHORTCUTS_CONFIG,
};

import * as stateManagement from '#packages/stateManagement';
import { WorkspaceRightPanelApiKey, FixedStageApiKey } from '#packages/apis';

import type { EditorAPI } from '#packages/editorAPI';
import type { MapStateToProps, Dispatch } from 'types/redux';
import type { EditorState } from '#packages/stateManagement';

import { fixedStage } from '#packages/util';

const { selectors, actions } = stateManagement.editorSearch;
const TOP_BAR_SEARCH_ORIGIN = 'topbar_search';

const mapStateToProps: MapStateToProps = ({ state, editorAPI }) => ({
  toggled: selectors.isPanelOpen(state),
  isSitePublished: editorAPI.dsRead?.generalInfo.isSitePublished(),
  isLabelShown: !editorAPI.host.getAPI(FixedStageApiKey).isTopBarMinimized(),
  topBarStateBIParamValue: fixedStage.getTopBarStateBiParamValue(editorAPI),
});

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;
const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openSearchPanel() {
      const workspaceRightPanelAPI = editorAPI.host.getAPI(
        WorkspaceRightPanelApiKey,
      );
      if (workspaceRightPanelAPI.isOpen()) {
        workspaceRightPanelAPI.close(TOP_BAR_SEARCH_ORIGIN);
      }
      dispatch(actions.openEditorSearchPanel({ origin: 'top_bar' }));
    },
  };
};

export { mapDispatchToProps, mapStateToProps };

import { sections as sectionsUtils } from '#packages/util';
import { isAnchor, isSection } from '../../../utils';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SectionDescription, MobileComponent } from '../../types';

export function collectMobileComponents(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsDescription: SectionDescription[],
): {
  mobileRootComponents: MobileComponent[];
  mobileRootComponentsOrdering: string[];
  sectionsChildrenToKeepMobileParent: MobileComponent[][];
} {
  const mobileRootComponents: MobileComponent[] = [];
  const sectionsChildrenToKeepMobileParent: MobileComponent[][] = [];

  sectionsDescription.forEach((section: SectionDescription, index: number) => {
    sectionsChildrenToKeepMobileParent.push([]);

    section.children.forEach((desktopCompRef) => {
      const mobileCompRef =
        documentServices.components.getMobileRef(desktopCompRef);
      const mobileLayout =
        documentServices.components.layout.get(mobileCompRef);

      // hidden components on mobile has no layout
      if (!mobileLayout) return;

      const isFullWidth =
        documentServices.components.is.fullWidth(desktopCompRef);
      const mobileParentRef =
        documentServices.components.getContainer(mobileCompRef);
      const isAnchorComponent = isAnchor(documentServices, desktopCompRef);
      const isSectionComponent = isSection(documentServices, desktopCompRef);
      const isMobileRootChild =
        mobileParentRef.id === pageRef.id || isAnchorComponent; // Fix wrong container of anchor on mobile WEED-27205

      const keepParentRef = isMobileRootChild ? null : mobileParentRef; // Elements to reparent on desktop but don't touch on mobile
      const keepParentIndex =
        isAnchorComponent || isSectionComponent ? index : null; // Anchors and legacy sections cannot have diff parent on desktop/mobile WEED-27514

      const mobileChildrenData: MobileComponent = {
        desktopParentIndex: index,
        mobileParentIndex: undefined,
        keepParentRef,
        keepParentIndex,
        ref: mobileCompRef,
        layout: mobileLayout,
        isFullWidth,
      };

      if (isMobileRootChild) {
        mobileRootComponents.push(mobileChildrenData);
      } else {
        sectionsChildrenToKeepMobileParent[index].push(mobileChildrenData);
      }
    });
  });

  const pageRootComponents: CompRef[] = documentServices.components.getChildren(
    documentServices.components.getMobileRef(pageRef),
  );

  const mobileOnlyRootComponents: MobileComponent[] = pageRootComponents
    .filter(
      (compRef: CompRef) =>
        sectionsUtils.isSectionableComponent(documentServices, compRef) &&
        mobileRootComponents.every((item) => item.ref.id !== compRef.id),
    )
    .map(({ id }: CompRef): MobileComponent => {
      const mobileCompRef = <CompRef>{
        id,
        type: documentServices.viewMode.VIEW_MODES.MOBILE,
      };
      const mobileLayout =
        documentServices.components.layout.get(mobileCompRef);
      const isFullWidth =
        documentServices.components.is.fullWidth(mobileCompRef);

      return {
        desktopParentIndex: undefined,
        mobileParentIndex: undefined,
        ref: mobileCompRef,
        layout: mobileLayout,
        isFullWidth,
      };
    });

  mobileRootComponents.push(...mobileOnlyRootComponents);

  const mobileRootComponentsOrdering = pageRootComponents.map(
    ({ id }: CompRef) => id,
  );

  mobileRootComponents.sort((compA, compB) => compA.layout.y - compB.layout.y);

  return {
    mobileRootComponents,
    mobileRootComponentsOrdering,
    sectionsChildrenToKeepMobileParent,
  };
}

import React from 'react';
import * as BaseUI from '#packages/baseUI';
import { cx } from '#packages/util';
import styles from './uploadButton.scss';

interface UploadButtonProps {
  onButtonClick: () => void;
  label: string;
}

export const UploadButton: React.FC<UploadButtonProps> = ({
  onButtonClick,
  label,
}) => {
  return (
    <div className={styles.uploadButtonContainer}>
      <BaseUI.button2
        label={label}
        onClick={onButtonClick}
        data-hook="media-manager-panel-upload_button"
        className={cx('default', 'upload-button', styles.uploadButton)}
      />
    </div>
  );
};

import React, { Component, type ComponentType } from 'react';
import * as util from '#packages/util';
import type { SendBiFunction } from 'types/bi';

import { getSendBi } from '../topBarComponent/topBarMapper';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export interface WithSendBiProps {
  sendBi: SendBiFunction;
}

export interface ComponentWithSendBiProps {
  sendBi: SendBiFunction;
}

const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  sendBi: getSendBi(dispatch),
});

const withSendBi = <P extends WithSendBiProps>(
  WrappedComponent: ComponentType<P & ComponentWithSendBiProps>,
) => {
  class WithSendBi extends Component<WithSendBiProps> {
    render() {
      const { props } = this;

      return React.createElement(WrappedComponent, props as P);
    }
  }

  return connect(EDITOR_API, null, mapDispatchToProps)(WithSendBi);
};

export default withSendBi;

import React from 'react';

import * as symbols from '@wix/santa-editor-symbols';
import { Button, PromotionalList, RichText, TextLabel } from '@wix/wix-base-ui';

import constants from '#packages/constants';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import * as wixData from '#packages/wixData';

import type { EditorAPI } from '#packages/editorAPI';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

import PromotionalPanel from './promotionalPanel/promotionalPanel';
import LinksList from './promotionalPanel/linksList';
import experiment from 'experiment';

function template(this: AnyFixMe) {
  return (
    <div
      data-aid="drop-panel-code"
      className="drop-panel-content code-drop-panel-corvid"
    >
      <div className="header-container">
        <TextLabel
          value={this.getTitle()}
          shouldTranslate={false}
          enableEllipsis={false}
          type="T09"
        />
      </div>
      <div className="middle-container">
        <div className="text-container">
          <TextLabel
            value={this.getSubTitle()}
            type="T07"
            shouldTranslate={false}
            enableEllipsis={false}
            className="subtitle"
          />

          {!this.props.isDeveloperModeEnabled ? (
            <PromotionalList
              key="title-dev-mode-disabled"
              items={this.getOffListItems()}
              symbol={this.getCheckSymbol()}
              shouldTranslate={false}
            />
          ) : null}

          {this.props.isDeveloperModeEnabled ? (
            <div key="title-dev-mode-enabled" className="text-link-list">
              {this.getCodeOnListItems().map(
                (item: AnyFixMe, itemIndex: AnyFixMe) => (
                  <div key={`item${itemIndex}`} className="text-link-list-item">
                    <div
                      onClick={() => this.navigateToLink(item)}
                      className="link-symbol-container"
                    >
                      <symbols.symbol key="link_icon" name="link_icon" />
                    </div>
                    <div className="text-link">
                      <RichText type="T02">
                        <a onClick={() => this.navigateToLink(item)}>
                          {item.text}
                        </a>
                      </RichText>
                    </div>
                  </div>
                ),
              )}
            </div>
          ) : null}
        </div>
        {this.getExposureSymbolName() ? (
          <div className="symbol-container">
            <symbols.symbol
              key="dynamicPromotionalImage"
              name={this.getExposureSymbolName()}
            />
          </div>
        ) : null}
      </div>
      {this.props.devToolsButtonEnabled ? (
        <div key="dev-tools-button-enabled" className="footer-container">
          <div key="regularFooter" className="regular-footer">
            <Button
              automationId="code-btn-code-on"
              onClick={this.devToolsButtonClick}
              className={this.getButtonClass()}
            >
              <span>{this.getButtonText()}</span>
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

const LIST_ITEMS_OFF = [
  translate('Corvid_DropMenu_Bullet1'),
  translate('Corvid_DropMenu_Bullet2'),
  translate('Corvid_DropMenu_Bullet3'),
  translate('Corvid_DropMenu_Bullet4'),
];

const LIST_ITEMS_ON = [
  {
    item_name: 'API Reference',
    url: translate('Corvid_DropMenu_API_URL'),
    text: translate('Corvid_DropMenu_API_Label'),
  },
  {
    item_name: 'Articles',
    url: translate('Corvid_DropMenu_Articles_URL'),
    text: translate('Corvid_DropMenu_Articles_Label'),
  },
  {
    item_name: 'Video Tutorials',
    url: translate('Corvid_DropMenu_Videos_URL'),
    text: translate('Corvid_DropMenu_Videos_Label'),
  },
  {
    item_name: 'Forum',
    url: translate('Corvid_DropMenu_Forum_URL'),
    text: translate('Corvid_DropMenu_Forum_Label'),
  },
];

const CHECK_SYMBOL = React.createElement(symbols.symbol, { name: 'check' });
const LINK_SYMBOL = React.createElement(symbols.symbol, { name: 'link_icon' });

const DEV_TITLE = experiment.isOpen('se_devModeTitle')
  ? translate('TopBar_DevMode_DropMenu_Title')
  : translate('Corvid_DropMenu_Title');
const DEV_ON_SUBTITLE = translate('Corvid_Resources_Documentation_Line');
const DEV_OFF_SUBTITLE = translate('Corvid_DropMenu_Subtitle');

const DEV_OFF_BUTTON = experiment.isOpen('se_devModeTitle')
  ? translate('TopBar_DevMode_DropMenu_DisableDevMode')
  : translate('Corvid_DropMenu_Off_Button');

const DEV_ON_BUTTON = experiment.isOpen('se_devModeTitle')
  ? translate('TopBar_DevMode_DropMenu_EnableDevMode')
  : translate('Corvid_DropMenu_On_Button');

interface CodeDropPanelBannerProps {
  sendBi: (
    eventType: BiEventDefinition,
    params: BiEventFields,
    paramsBuilder?: (editorAPI: EditorAPI) => BiEventFields,
  ) => void;
  isDeveloperModeEnabled: boolean;
  isWixCodeProvisioned: boolean;
  toggleDeveloperMode: () => void;
  devToolsButtonEnabled: boolean;
  overrides: AnyFixMe;
}

class CodeDropPanelBanner extends React.Component<CodeDropPanelBannerProps> {
  static defaultProps = {
    overrides: {},
  };

  componentDidMount() {
    this.props.sendBi(coreBi.events.topbar.TOP_BAR_Dev_Mode_Panel_Opened, {
      menu_name: constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.CODE,
    });
  }

  getCodeOnListItems() {
    return (
      this.props.overrides[
        constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_ON_LIST_ITEMS
      ] || LIST_ITEMS_ON
    );
  }

  getOffListItems() {
    return LIST_ITEMS_OFF;
  }

  getCheckSymbol() {
    return CHECK_SYMBOL;
  }

  getTitle() {
    return (
      this.props.overrides[
        constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_HEADER
      ] || DEV_TITLE
    );
  }

  getSubTitle() {
    return (
      this.props.overrides[
        constants.ROOT_COMPS.TOPBAR.CODE_MENU_ITEMS.CODE_SUB_HEADER
      ] ||
      (this.props.isDeveloperModeEnabled ? DEV_ON_SUBTITLE : DEV_OFF_SUBTITLE)
    );
  }

  getButtonClass() {
    return this.props.isDeveloperModeEnabled
      ? 'btn-confirm-secondary'
      : 'btn-confirm-primary';
  }

  getExposureSymbolName() {
    if (util.workspace.isNewWorkspaceEnabled()) {
      return 'veloDevModeFacelift';
    }
    return 'veloDevMode';
  }

  getButtonText() {
    return this.props.isDeveloperModeEnabled ? DEV_OFF_BUTTON : DEV_ON_BUTTON;
  }

  navigateToLink = (item: AnyFixMe) => {
    this.props.sendBi(coreBi.events.topbar.top_bar_WIXCODE_resources_clicked, {
      esi: util.editorModel.editorSessionId,
      item_name: item.item_name,
    });

    window.open(item.url);
  };

  devToolsButtonClick = () => {
    this.props.sendBi(
      coreBi.events.topbar.top_bar_CODE_menu_click,
      null,
      (editorAPI) => ({
        category: 'dev_mode',
        origin: 'manual',
        status: !this.props.isDeveloperModeEnabled,
        is_data_on: wixData.isDataModeOn(editorAPI),
        is_first: !this.props.isWixCodeProvisioned,
      }),
    );

    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.CODE_TOP_BAR_TOGGLE,
    );

    this.props.toggleDeveloperMode();
  };

  renderLinksList = () => {
    return (
      <LinksList
        items={LIST_ITEMS_ON}
        symbol={LINK_SYMBOL}
        navigateToLink={this.navigateToLink}
      />
    );
  };

  renderFooter = () => {
    return (
      <>
        <Button
          automationId="code-btn-code-on"
          onClick={this.devToolsButtonClick}
          className={this.getButtonClass()}
        >
          <span>{this.getButtonText()}</span>
        </Button>
      </>
    );
  };

  render() {
    if (util.workspace.isNewWorkspaceEnabled()) {
      return (
        <PromotionalPanel
          automationId="drop-panel-code"
          className="corvid-promotional-panel"
          title={this.getTitle()}
          subtitle={this.getSubTitle()}
          listItems={this.getOffListItems()}
          symbol={<symbols.symbol name={this.getExposureSymbolName()} />}
          renderList={
            this.props.isDeveloperModeEnabled ? this.renderLinksList : null
          }
          renderFooter={
            this.props.devToolsButtonEnabled ? this.renderFooter : null
          }
        />
      );
    }

    return template.call(this);
  }
}

export default CodeDropPanelBanner;

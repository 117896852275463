import React from 'react';
import { Button, TextLabel, VerticalTabs, Divider } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { getGroupedCategories } from '../../../addPresetUtil';

import type { AddPageCategory } from '#packages/addPreset';

export interface AddPagesLeftBarProps {
  selectedCategory: number;
  categories: AddPageCategory[];
  isPremiumSite: boolean;
  onCategoryChanged: (selectedCategory: number) => void;
  onBlankButtonClicked: () => void;
}

export const AddPagesLeftBar = ({
  selectedCategory,
  categories,
  isPremiumSite,
  onCategoryChanged,
  onBlankButtonClicked,
}: AddPagesLeftBarProps) => {
  const groupedCategories = getGroupedCategories(categories);
  return (
    <div className="left-menu-wrapper">
      <div>
        <div className="blank-page-wrapper">
          <Button
            onClick={onBlankButtonClicked}
            className="primary blank-page-button"
          >
            <symbols.symbol name="addPage" />
            &nbsp;
            <TextLabel value="add_page_preset_blank_button" type="T07" />
          </Button>
        </div>
        <div
          className={`categories-menu${
            categories[selectedCategory].isPremium && !isPremiumSite
              ? ' premium-selected'
              : ''
          }`}
        >
          {groupedCategories.map((groupedCategory) =>
            groupedCategory[0].isDivider ? (
              <>
                <Divider key={groupedCategory[0].id} />
                {groupedCategory[0].label ? (
                  <TextLabel
                    type="T04"
                    className="tabs-divider-title"
                    value={groupedCategory[0].label}
                  />
                ) : null}
              </>
            ) : (
              // @ts-expect-error
              <VerticalTabs
                value={String(selectedCategory)}
                options={groupedCategory}
                key={groupedCategory[0].id}
                onChange={onCategoryChanged}
              />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

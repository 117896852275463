import React from 'react';
import { connectWithScope } from '#packages/apilib';
import * as stateManagement from '#packages/stateManagement';
import { translate } from '#packages/i18n';
import { FloatingModeBar } from '#packages/rEditor';
import type { FloatBarOptions } from '../types';
import { ComponentFocusModeScope } from '../componentFocusModeScope';

interface ComponentFocusModeBarDispatchProps {
  handleExit(): void;
  openHelpCenter(helpId: string): void;
}

interface ComponentFocusModeBarStateProps extends FloatBarOptions {
  isEnabled: boolean;
}

const ComponentFocusModeBarPure: React.FC<
  ComponentFocusModeBarDispatchProps & ComponentFocusModeBarStateProps
> = ({ isEnabled, label, exitLabel, helpId, handleExit, openHelpCenter }) =>
  isEnabled && (
    <FloatingModeBar
      draggableExtentionSlot={
        stateManagement.draggableSlots.DRAGGABLE_SLOTS.LIGHTBOX_MODE_BAR
      }
      title={translate(label)}
      exitModeLabelKey={exitLabel}
      onExitMode={handleExit}
      onOpenHelpCenter={() => openHelpCenter(helpId)}
    />
  );

const mapDispatchToProps = ({ editorAPI }: ComponentFocusModeScope) => ({
  handleExit: () =>
    editorAPI.componentFocusMode.exit({ shouldRestorePrevState: true }),
  openHelpCenter: editorAPI.panelManager.openHelpCenter,
});

const mapStateToProps = ({
  store,
}: ComponentFocusModeScope): ComponentFocusModeBarStateProps => ({
  ...store.getFloatBarOptions(),
  isEnabled: store.getIsEnabled(),
});

export const ComponentFocusModeBar = connectWithScope(
  () => ComponentFocusModeScope,
  ComponentFocusModeBarPure,
  mapStateToProps,
  mapDispatchToProps,
);

import _ from 'lodash';
import { EditorAPIKey, TpaApiKey } from '#packages/apis';
import experiment from 'experiment';
import * as stateManagement from '#packages/stateManagement';
import { init as initAppManager } from '#packages/appManager';

import * as tpaUtils from './utils/tpaUtils';
import tpaConstants from './constants/constants';
import superAppsConstants from './superApps/superAppsConstants';
import tpaPostMessageService from './services/tpaPostMessageService';
import * as tpaPreviewPostMessageService from './services/tpaPreviewPostMessageService';
import * as appMarketTabService from './appMarket/services/appMarketTabService';
import tpaSettings from './settings/compPanels/tpaSettings';
import appMarketPanel from './compPanels/appMarketPanel';
import * as packagePicker from './packagePicker/packagePicker';
import appMarketAppModal from './compModals/appMarketAppModal';
import appMarketPermissionsModal from './compModals/appMarketPermissionsModal';
import tpaSettingsModal from './compModals/tpaSettingsModal';
import appMarketModal from './compModals/appMarketModal';
import appImportPanel from './panels/appImportPanel/appImportPanel';
import appImportErrorPanel from './panels/appImportErrorPanel';
import updateErrorPanel from './panels/updateErrorPanel/updateErrorPanel';
import navigateAndOpenAppService from './services/navigateAndOpenAppService';
import * as musicSection from './addPanelData/music/musicSection';
import * as richContentSection from './addPanelData/richContent/richContentSection';
import * as soundCloudSection from './addPanelData/music/soundCloudSection';
import * as videoSection from './addPanelData/video/videoSection';
import * as proGalleriesSection from './addPanelData/gallery/proGalleriesSection';
import * as leanGalleriesSection from './addPanelData/gallery/leanGalleriesSection';
import * as newGridGalleriesSection from './addPanelData/gallery/newGridGalleriesSection';
import * as spotifyPlayerSection from './addPanelData/spotify/spotifyPlayerSection';
import * as eventsSection from './addPanelData/events/eventsSection';
import * as liveChatSection from './addPanelData/contact/liveChatSection';
import * as chatButtonSection from './addPanelData/contact/chatButtonSection';
import * as profileWidgetSection from './addPanelData/members/profileWidgetSection';
import * as membersWidgetSection from './addPanelData/members/membersWidgetSection';
import * as membersStaffWidgetSection from './addPanelData/members/staffWidgetSection';
import newBlogCategoryMenuSection from './addPanelData/newBlog/categoryMenuSection';
import newBlogTagCloudSection from './addPanelData/newBlog/tagCloudSection';
import newBlogArchiveSection from './addPanelData/newBlog/archiveSection';
import newBlogRssButtonSection from './addPanelData/newBlog/rssButtonSection';
import newBlogPostListLargeSection from './addPanelData/newBlog/postListLargeSection';
import newBlogPostListSidebarSection from './addPanelData/newBlog/postListSidebarSection';
import newBlogRecentPostsListSection from './addPanelData/newBlog/recentPostsListSection';
import triggerNewBlogBiEvent from './addPanelData/newBlog/triggerElementAddedBiEvent';
import * as bi from './bi/bi';
import * as superApps from './superApps/superApps';
import * as pendingAppsService from './services/pendingAppsService';
import * as dashboardAppService from './services/dashboardAppService';
import * as tpaAlertsService from './services/tpaAlertsService';
import * as gfpp from './gfpp/tpaGfpp';
import * as tpaDeleteService from './services/tpaDeleteService';
import * as tpaCompNameService from './services/tpaCompNameService';
import * as editorTPAHandlers from './handlers/editorTPAHandlers';
import * as editorRefreshTPADataAfterUpgrade from './handlers/editorRefreshTPADataAfterUpgrade';
import * as appMarketUtils from './appMarket/utils/appMarketUtils';
import * as appMarketHandlers from './appMarket/appMarketHandlers';
import * as settings from './settings/settings';
import * as experiments from './experiments/experiments';
import * as superAppsGfppDataCacheService from './superApps/gfpp/superAppsGfppDataCacheService';
import * as tpaCopyPasteService from './services/tpaCopyPasteService';
import * as editorSuperAppsHandlers from './handlers/editorSuperAppsHandlers';

import constants from '#packages/constants';

import * as tpaStyleService from './services/tpaStyleService';
import * as tpaAppIsAliveService from './services/tpaAppIsAliveService';
import * as searchSection from './addPanelData/search/searchSection';
export { mapDispatchToProps as tpaAppBoxMapDispatchToProps } from './addPanelData/tpaAddPanelAppBox';
import type { TpaAddPanelAppBoxDispatchProps } from './addPanelData/tpaAddPanelAppBox';
import { mapDispatchToProps as tpaAddPanelSectionMapDispatchToProps } from './addPanelData/tpaAddPanelSection';
import type { TpaAddPanelSectionDispatchProps } from './addPanelData/tpaAddPanelSection';
import * as addPanelAddCompService from './addPanelData/addPanelAddCompService';
import type { CompRef } from 'types/documentServices';
import { appController } from '#packages/platform';
import { isDataModeOn } from '#packages/wixData';

import type { EntryPoint } from '#packages/apilib';
import { createTpaApi } from './tpaWrapper';
import { isNotifyControllerGFPPDoubleClickDisabled } from './experiments/experiments';

export const TpaEntrypoint: EntryPoint = {
  name: 'TpaApi',
  declareAPIs: () => [TpaApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(TpaApiKey, () => createTpaApi(shell));
  },
};

// todo remove below
const { getPreviewMode } = stateManagement.preview.selectors;
let editorIsInPreviewMode = false;
let _editorAPI: AnyFixMe;

const { getDeviceType } = stateManagement.leftBar.selectors;
const initEditor = function (editorAPI: AnyFixMe) {
  _editorAPI = editorAPI;
  tpaPostMessageService(
    editorAPI,
    window.addEventListener,
  ).addListenerToTpaMessage();
  gfpp.init();
  tpaPreviewPostMessageService.init(editorAPI);
  superApps.init(editorAPI);
  superAppsGfppDataCacheService.init(editorAPI.panelManager);
  registerEditorEvents(editorAPI);
};

const initEditorResponsive = function (
  editorAPI: AnyFixMe,
  contributeTpaMessageHandlers: AnyFixMe,
) {
  _editorAPI = editorAPI;
  const tpaHandler = tpaPostMessageService(
    editorAPI,
    window.addEventListener,
  ).callHandler;
  gfpp.init();
  const tpaPreviewHandler = tpaPreviewPostMessageService.callHandler.bind(
    null,
    editorAPI,
  );
  contributeTpaMessageHandlers(tpaHandler, tpaPreviewHandler);
  superApps.init(editorAPI);
  superAppsGfppDataCacheService.init(editorAPI.panelManager);
  registerEditorEvents(editorAPI);
};

const registerEditorEvents = function (editorAPI: AnyFixMe) {
  const APP_MARKET_PANEL_NAME = 'tpa.compPanels.appMarketPanel';
  const { dispatch } = editorAPI.store;
  editorAPI.registerSitePublishedCallbacks(function () {
    const state = editorAPI.store.getState();
    const isAlreadyClicked =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        APP_MARKET_PANEL_NAME + constants.LEFT_BAR.WAS_BUTTON_CLICKED_KEY,
      )(state);

    if (
      !isAlreadyClicked &&
      _.isEmpty(stateManagement.leftBar.selectors.getCollapseOnClick(state))
    ) {
      dispatch(
        stateManagement.leftBar.actions.expandButton(APP_MARKET_PANEL_NAME),
      );
      dispatch(
        stateManagement.bi.actions.event(bi.events.APP_MARKET_ICON_EXPANDED, {
          site_id: editorAPI.dsRead.generalInfo.getSiteId(),
        }),
      );
    }
  });
};

const onPreviewReady = function (editorAPI: AnyFixMe) {
  const isInDevMode = tpaUtils.isInDevMode();
  editorAPI.dsActions.tpa.initEditor(isInDevMode, experiments);
  handleEditModeChange(editorAPI);

  const { pluginService } = editorAPI;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(editorAPI.dsRead.tpa.constants.COMP_TYPES, function (type) {
    editorAPI.registerCompNamePlugin(
      type,
      tpaCompNameService.getCompName.bind(null, editorAPI),
    );
    pluginService.registerPlugin(
      pluginService.pluginConstants.DOUBLE_CLICK,
      type,
      doubleClickHandler,
    );
  });

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES, function (type) {
    editorAPI.registerCompNamePlugin(
      type,
      tpaCompNameService.getCompName.bind(null, editorAPI),
    );
    pluginService.registerPlugin(
      pluginService.pluginConstants.DOUBLE_CLICK,
      type,
      doubleClickHandler,
    );
  });

  editorAPI.registerRemovePlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_WIDGET,
    tpaDeleteService.deleteWidget.bind(null, editorAPI),
  );
  editorAPI.registerRemovePlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_GLUED_WIDGET,
    tpaDeleteService.deleteWidget.bind(null, editorAPI),
  );
  editorAPI.registerRemovePlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_WIDGET,
    tpaDeleteService.deleteWidget.bind(null, editorAPI),
  );
  editorAPI.registerRemovePlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_GLUED_WIDGET,
    tpaDeleteService.deleteWidget.bind(null, editorAPI),
  );

  editorAPI.registerRemovePagePlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_SECTION,
    tpaDeleteService.deleteSection.bind(null, editorAPI),
  );
  editorAPI.registerRemovePagePlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_SECTION,
    tpaDeleteService.deleteSection.bind(null, editorAPI),
  );

  editorAPI.registerCopyComponentPlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_WIDGET,
    tpaCopyPasteService.copyComponentPlugin,
  );
  editorAPI.registerCopyComponentPlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_GLUED_WIDGET,
    tpaCopyPasteService.copyComponentPlugin,
  );
  editorAPI.registerCopyComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_WIDGET,
    tpaCopyPasteService.copyComponentPlugin,
  );
  editorAPI.registerCopyComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_GLUED_WIDGET,
    tpaCopyPasteService.copyComponentPlugin,
  );

  editorAPI.registerAddComponentPlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_WIDGET,
    tpaCopyPasteService.addComponentPlugin,
  );
  editorAPI.registerAddComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_GLUED_WIDGET,
    tpaCopyPasteService.addComponentPlugin,
  );
  editorAPI.registerAddComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_WIDGET,
    tpaCopyPasteService.addComponentPlugin,
  );
  editorAPI.registerAddComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_GLUED_WIDGET,
    tpaCopyPasteService.addComponentPlugin,
  );

  editorAPI.registerCutComponentPlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_WIDGET,
    tpaCopyPasteService.cutComponentPlugin.bind(null, editorAPI),
  );
  editorAPI.registerCutComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_GLUED_WIDGET,
    tpaCopyPasteService.cutComponentPlugin.bind(null, editorAPI),
  );
  editorAPI.registerCutComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_WIDGET,
    tpaCopyPasteService.cutComponentPlugin.bind(null, editorAPI),
  );
  editorAPI.registerCutComponentPlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_GLUED_WIDGET,
    tpaCopyPasteService.cutComponentPlugin.bind(null, editorAPI),
  );

  editorAPI.registerCannotRemovePlugin(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_SECTION,
    tpaDeleteService.displaySectionDeleteMessage.bind(null, editorAPI),
  );
  editorAPI.registerCannotRemovePlugin(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_SECTION,
    tpaDeleteService.displaySectionDeleteMessage.bind(null, editorAPI),
  );

  editorAPI.tooltipTextService.registerTooltipText(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_SECTION,
    'DELETE',
    'tpa_section_cant_delete_tooltip',
  );
  editorAPI.tooltipTextService.registerTooltipText(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_SECTION,
    'DELETE',
    'tpa_section_cant_delete_tooltip',
  );

  editorAPI.tooltipTextService.registerTooltipText(
    editorAPI.dsRead.tpa.constants.COMP_TYPES.TPA_GLUED_WIDGET,
    'SHOW_ON_ALL_PAGES',
    'tpa_glued_show_on_all_pages_tooltip',
  );
  editorAPI.tooltipTextService.registerTooltipText(
    editorAPI.dsRead.tpa.constants.TPA_COMP_TYPES.TPA_GLUED_WIDGET,
    'SHOW_ON_ALL_PAGES',
    'tpa_glued_show_on_all_pages_tooltip',
  );

  appMarketTabService.openAppMarketTabFromUrlParamIfNeeded(
    editorAPI.panelManager.openPanel,
  );

  navigateAndOpenAppService
    .handleIfComingFromETPA(editorAPI)
    .then(() => editorAPI.postDeepNavigation.forEach((cb: AnyFixMe) => cb()));

  editorAPI.registerInitUserPrefsCallback(async function () {
    await initAppManager(editorAPI);
  });

  editorAPI.dsActions.platform.registerToAppsCompleted(() => {
    pendingAppsService.updateNotification(editorAPI);
  });

  registerSuperAppsAddPanel(editorAPI);

  editorAPI.dsRead.tpa.billing.getPremiumApps(editorAPI.site.getMetaSiteId());

  editorAPI.registerSitePublishedCallbacks(sitePublished.bind(null, editorAPI));
  editorAPI.registerDeviceTypeChangeCallbacks(
    deviceTypeChanged.bind(null, editorAPI),
  );
  registerForAppMarketDataPerPage(editorAPI);
  bi.registerEvents(editorAPI);

  reportBiErrorIfEcomInstalledAndPagesAreMissing(editorAPI);
};

const reportBiErrorIfEcomInstalledAndPagesAreMissing = function (
  editorAPI: AnyFixMe,
) {
  if (
    editorAPI.dsRead.tpa.app.isInstalled(superAppsConstants.ECOM.APP_DEF_ID)
  ) {
    if (!isEcomPagesInstalled(editorAPI)) {
      editorAPI.dsActions.bi.error(bi.errors.ECOM_MISSING_PAGES);
      if (experiments.isEcomAddAppExperimentOn()) {
        editorAPI.panelManager.openPanel(
          'tpaPanels.confirmation.addEcom',
          {
            onConfirm() {
              editorAPI.dsActions.tpa.section.add(
                superAppsConstants.ECOM.APP_DEF_ID,
              );
            },
          },
          true,
        );
      }
    }
  }
};

const isEcomPagesInstalled = function (editorAPI: AnyFixMe) {
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(
    superAppsConstants.ECOM.APP_DEF_ID,
  );
  const { applicationId } = appData;
  const appSections = editorAPI.dsRead.tpa.app.getSections(applicationId);
  return appSections && !_.isEmpty(appSections);
};

const doubleClickHandler = function (editorAPI: AnyFixMe, params: AnyFixMe) {
  const compRef: CompRef = _.head(params.comp);
  const compData = editorAPI.components.data.get(compRef);
  const appData = editorAPI.dsRead.tpa.app.getData(compData.applicationId);
  if (editorAPI.isDesktopEditor()) {
    const appDefId = appData?.appDefinitionId ?? null;
    tpaUtils.bi.reportBIOnAppIntent(
      editorAPI,
      bi.events.APP_INTENT,
      appDefId,
      tpaConstants.BI.initiator.EDITOR,
      tpaConstants.BI.type.DOUBLECLICK_SETTINGS,
    );

    const gfppDataFromApp =
      editorAPI.dsRead.platform.controllers.getStageData(compRef)?.gfpp;
    const mainActionIdFromApp = gfppDataFromApp?.desktop?.mainAction1?.actionId;
    if (mainActionIdFromApp) {
      // TODO: remove when se_disableNotifyControllerGFPPDoubleClick removed
      if (!isNotifyControllerGFPPDoubleClickDisabled()) {
        appController.notifyControllerGfppClicked(
          mainActionIdFromApp,
          editorAPI,
          params.comp,
        );
      }
      return appController.notifyWidgetGfppClicked(
        { actionId: mainActionIdFromApp },
        editorAPI,
        params.comp,
      );
    }
    return settings.open(editorAPI, compRef);
  }
};

const registerSuperAppsAddPanel = function (editorAPI: AnyFixMe) {
  registerBlogSections(editorAPI);

  addCustomMusicSections(editorAPI);
  videoSection.registerSection(editorAPI);
  profileWidgetSection.registerSection(editorAPI);
  membersWidgetSection.registerSection(editorAPI);
  if (experiment.isOpen('se_membersStaffWidget')) {
    membersStaffWidgetSection.registerSection(editorAPI);
  }

  liveChatSection.registerSection(editorAPI);
  chatButtonSection.registerSection(editorAPI);
  leanGalleriesSection.registerSection(editorAPI);
  proGalleriesSection.registerSection(editorAPI);
  if (experiment.isOpen('se_replaceGalleriesAddPanel')) {
    newGridGalleriesSection.registerSection(editorAPI);
  }
  spotifyPlayerSection.registerSection(editorAPI);
  eventsSection.registerSection(editorAPI);
  searchSection.registerSection(editorAPI);
  if (isDataModeOn(editorAPI)) {
    richContentSection.registerSection(editorAPI);
  }
};

const addCustomMusicSections = function (editorAPI: AnyFixMe) {
  soundCloudSection.registerSection(editorAPI);
  musicSection.registerSection(editorAPI);
};

const registerBlogSections = function (editorAPI: AnyFixMe) {
  newBlogPostListLargeSection.registerSection(editorAPI);
  newBlogPostListSidebarSection.registerSection(editorAPI);
  newBlogRecentPostsListSection.registerSection(editorAPI);
  newBlogCategoryMenuSection.registerSection(editorAPI);
  newBlogTagCloudSection.registerSection(editorAPI);
  newBlogArchiveSection.registerSection(editorAPI);
  newBlogRssButtonSection.registerSection(editorAPI);
};

const registerForAppMarketDataPerPage = function (editorAPI: AnyFixMe) {
  editorAPI.dsRead.tpa.appMarket.getDataForPage(
    editorAPI.dsRead.pages.getFocusedPageId(),
  );
  editorAPI.registerPageNavigationCallback(function (pageId: AnyFixMe) {
    editorAPI.dsRead.tpa.appMarket.getDataForPage(pageId);
  });
};

const editModeChange = function (editorAPI: AnyFixMe, isPreviewMode: AnyFixMe) {
  editorAPI.dsActions.tpa.change.editMode(isPreviewMode ? 'preview' : 'editor');
  editorIsInPreviewMode = isPreviewMode;
  if (!isPreviewMode) {
    editorAPI.dsActions.tpa.closePopupsAndModal();
  }
};

const sitePublished = function (editorAPI: AnyFixMe) {
  editorAPI.dsActions.tpa.change.sitePublished();
};

const deviceTypeChanged = function (editorAPI: AnyFixMe) {
  const state = editorAPI.store.getState();
  editorAPI.dsActions.tpa.change.deviceType(getDeviceType(state));
};

const handleEditModeChange = function (editorAPI: AnyFixMe) {
  editorAPI.registerToViewerChange(function () {
    const state = editorAPI.store.getState();
    const isPreviewMode = getPreviewMode(state);
    if (isPreviewMode !== editorIsInPreviewMode) {
      editModeChange(editorAPI, isPreviewMode);
    }
  });
};

const getEditorAPI = function () {
  return _editorAPI;
};

const compPanels = {
  tpaSettings,
  appMarketPanel,
};

const compModals = {
  tpaSettingsModal,
  appMarketAppModal,
  appMarketPermissionsModal,
  appMarketModal,
};

const panels = {
  appImportPanel,
  appImportErrorPanel,
  updateErrorPanel,
};

const sections = {
  getProGalleriesSection: proGalleriesSection.getSection,
  getGridGalleriesSection: newGridGalleriesSection.getSection,
};

const responsiveEditorHooks = {
  tpaStyleService,
  tpaAppIsAliveService,
};

export type SuperApps = typeof superApps;

export * as services from './services';

export {
  addPanelAddCompService,
  navigateAndOpenAppService,
  initEditor,
  initEditorResponsive,
  onPreviewReady,
  editModeChange,
  packagePicker,
  compPanels,
  compModals,
  panels,
  tpaConstants as constants,
  chatButtonSection,
  liveChatSection,
  superApps,
  superAppsConstants,
  dashboardAppService as dashboardApps,
  getEditorAPI,
  editorTPAHandlers,
  editorSuperAppsHandlers,
  editorRefreshTPADataAfterUpgrade,
  appMarketUtils,
  settings,
  appMarketTabService,
  appMarketHandlers,
  bi,
  tpaUtils,
  tpaAddPanelSectionMapDispatchToProps,
  type TpaAddPanelSectionDispatchProps,
  type TpaAddPanelAppBoxDispatchProps,
  sections,
  responsiveEditorHooks,
  triggerNewBlogBiEvent,
  richContentSection,
  pendingAppsService,
  tpaAlertsService,
};

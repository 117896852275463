import React from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import * as editorUtils from '@wix/santa-editor-utils';
import constants from '#packages/constants';
import { ToolPanelFrame } from '../../frames';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;
const { withModules } = editorUtils.hoc;

const { SITE_PATH } = constants.STYLABLE.EDITOR;

interface StylableEditorPanelProps {
  panelName: string;
  onClose: () => void;
  title: string;
  stylablePanelLib: typeof import('stylable-panel');
  top: string;
  left: string;
}

export default (
  componentName:
    | 'WixStyleEditor'
    | 'FillPicker'
    | 'ColorPicker'
    | 'SingleShadowInput'
    | 'TextShadowLayerInput'
    | 'StateListDialog'
    | 'ImagePicker',
  className: string,
  pickerProps: AnyFixMe,
  styleProps: AnyFixMe,
  contentClass = 'stylable-editor-panel-content-wrapper',
) => {
  class StylableEditorPanel extends React.Component<StylableEditorPanelProps> {
    static dispalyName = 'StylableEditorPanel';
    static defaultProps = {
      top: '400px',
      left: '500px',
    };

    private getFrameStyle: () => AnyFixMe;
    private getTitle: () => string;
    private getContentClass: () => string;
    private getPickerProps: () => AnyFixMe;
    private renderPicker: () => JSX.Element;

    constructor(props: StylableEditorPanelProps) {
      super(props);

      this.getFrameStyle = () => ({
        ...styleProps,
        left: this.props.left || styleProps.left,
        top: this.props.top || styleProps.top,
      });
      this.getTitle = () => props.title;
      this.getContentClass = () => contentClass;

      this.getPickerProps = () => _.pick(this.props, Object.keys(pickerProps));
      this.renderPicker = () =>
        React.createElement(this.props.stylablePanelLib[componentName], {
          ...this.getPickerProps(),
          className,
          panelFrame: ToolPanelFrame,
        });
    }

    UNSAFE_componentWillUpdate(nextProps: StylableEditorPanelProps) {
      this.getTitle = () => nextProps.title;
      this.getPickerProps = () => _.pick(nextProps, Object.keys(pickerProps));
    }

    render() {
      const { panelName, onClose } = this.props;
      const { EDITORX_THEME_REF, DESIGN_PANEL_THEME_REF } =
        constants.STYLABLE.EDITOR;
      const THEME_NAME = `${
        isResponsiveEditor() ? EDITORX_THEME_REF : DESIGN_PANEL_THEME_REF
      }`;
      return (
        <ToolPanelFrame
          panelName={panelName}
          headerTitle={this.getTitle()}
          onClose={onClose}
          contentStyle={this.getFrameStyle()}
          contentWrapperClass={`stylable-editor-panel ${THEME_NAME}`}
          contentClass={this.getContentClass()}
          lightHeader
        >
          {this.renderPicker()}
        </ToolPanelFrame>
      );
    }
  }

  const getStylableEditor = (
    _dispatch: AnyFixMe,
    _getState: AnyFixMe,
    { stylableEditor: editor }: AnyFixMe, // TODO fix to StylableEditor;
  ) => editor;
  const mapDispatchToProps = (dispatch: AnyFixMe) => {
    const stylableEditor = dispatch(getStylableEditor);

    return {
      siteVarsDriver:
        stylableEditor.stylableDriver.getSiteVarsDriver(SITE_PATH),
    };
  };

  const panelModules = {
    stylablePanelLib: () => import('stylable-panel'),
  };

  return _.flow(
    withModules(panelModules),
    connect(EDITOR_API, null, mapDispatchToProps),
  )(StylableEditorPanel);
};

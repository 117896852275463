import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';

import { createEntryPoint } from '@/apilib';

import { AddonsApiKey } from './publicApiKey';
import { createAddonsPublicApi } from './publicApi';
import { AddonsScope } from './scope';

export const AddonsEntrypoint = createEntryPoint({
  name: 'Addons',
  Scope: AddonsScope,
  publicApi({ contributeApi }) {
    contributeApi(AddonsApiKey, createAddonsPublicApi);
  },

  async initialize({ editorAPI, editorCoreAPI, addonsPrivateApi }) {
    await editorCoreAPI.hooks.initReady.promise;

    const editorPlatformHostIntegrationAPI = editorAPI.host.getAPI(
      EditorPlatformHostIntegrationAPI,
    );

    editorPlatformHostIntegrationAPI.toolsEntry.register(
      ({ type, payload }) => {
        if (type === 'add') {
          addonsPrivateApi.registerAddon(payload);
        }

        if (type === 'remove') {
          addonsPrivateApi.removeRegisteredAddon(payload);
        }
      },
    );
  },
});

import { addAppointmentField } from '#packages/addPanelData';
import type { OnDropCallback } from '#packages/addPanelInfra';
import { fedopsLogger } from '#packages/util';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompStructure } from 'types/documentServices';

interface AddAppointmentFieldOnClickParams {
  structure: CompStructure;
  presetId: string;
}

export const addAppointmentFieldOnClick = (
  editorAPI: EditorAPI,
  { structure, presetId }: AddAppointmentFieldOnClickParams,
) => {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
  );

  return addAppointmentField(editorAPI, {
    structure,
    coords: null,
    preset: presetId,
    theme: null,
    dropContainer: null,
    formRef: null,
  });
};

interface AddAppointmentFieldOnDropParams {
  presetId: string;
}

export const addAppointmentFieldOnDrop =
  (
    editorAPI: EditorAPI,
    { presetId }: AddAppointmentFieldOnDropParams,
  ): OnDropCallback =>
  (coords: AnyFixMe, { structure }: AnyFixMe, dropContainer: AnyFixMe) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
    );

    return addAppointmentField(editorAPI, {
      structure,
      coords,
      preset: presetId,
      theme: null,
      dropContainer,
      formRef: null,
    });
  };

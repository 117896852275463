import experiment from 'experiment';
import { TourNames, StartOrigin } from '#packages/editorTours';
import { MigrationFlow } from '../types';
import { isSkippedFTE, HEAVY_SITES } from '../utils';
import { showNotification } from './showNotification';

import type { SectionsMigrationScope as Scope } from '../scope';
import type { CompRef } from '@wix/document-services-types';

const enableTourOrNotificationForPerPageFlow = (
  scope: Scope,
  flow: MigrationFlow,
  expectedFlow: MigrationFlow,
  pagesRefs: CompRef[],
) => {
  const [currentPage] = pagesRefs;
  return (
    flow === expectedFlow && scope.editorAPI.pages.isHomePage(currentPage.id)
  );
};

export function startTourOrShowNotification(
  scope: Scope,
  flow: MigrationFlow,
  pagesRefs: CompRef[],
) {
  if (
    flow === MigrationFlow.ADI ||
    enableTourOrNotificationForPerPageFlow(
      scope,
      flow,
      MigrationFlow.PerPageADI,
      pagesRefs,
    )
  ) {
    if (
      scope.tourApi.shouldShowTour(TourNames.ADI_TO_EDITOR) &&
      !isSkippedFTE()
    ) {
      scope.tourApi.startOnLoad(TourNames.ADI_TO_EDITOR);
    } else {
      showNotification(scope, {
        messageType: 'success',
        message: 'adi_to_ed2_migration_success_notifications_text',
        link: HEAVY_SITES.NOTIFICATION_SUCCESS_LINK_TEXT,
        onClick: () =>
          scope.tourApi.startTourByName(
            TourNames.ADI_TO_EDITOR,
            StartOrigin.Notification,
          ),
      });
    }
  }

  if (
    flow === MigrationFlow.Editor ||
    flow === MigrationFlow.HeavySite ||
    enableTourOrNotificationForPerPageFlow(
      scope,
      flow,
      MigrationFlow.PerPageEditor,
      pagesRefs,
    )
  ) {
    if (
      scope.tourApi.shouldShowTour(TourNames.EDITOR_TO_EDITOR) &&
      !isSkippedFTE()
    ) {
      scope.tourApi.startOnLoad(TourNames.EDITOR_TO_EDITOR);
    } else if (
      experiment.isOpen('se_heavySitesMigrationLoader') ||
      experiment.isOpen('se_perPageSectionsMigration')
    ) {
      showNotification(scope, {
        messageType: 'success',
        message: 'adi_to_ed2_migration_success_notifications_text',
        link: HEAVY_SITES.NOTIFICATION_SUCCESS_LINK_TEXT,
        onClick: () =>
          scope.tourApi.startTourByName(
            TourNames.EDITOR_TO_EDITOR,
            StartOrigin.Notification,
          ),
      });
    }
  }
}

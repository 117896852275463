import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
export interface BackToEditorFromZoomDispatchProps {
  exitMode: () => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  BackToEditorFromZoomDispatchProps,
  null
> = (dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  return {
    exitMode: () =>
      editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'topbar' } }),
  };
};

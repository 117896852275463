:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._workspace-right-panel_no6jl_7 {
  height: calc(100vh - var(--g-top-bar-height));
  position: fixed;
  right: 0;
  transition: transform ease 300ms;
  z-index: 1456;
  border-top: 1px solid #e1e3e6;
  box-shadow: 0px 5px 4px -1px rgba(0, 0, 0, 0.2), 0px 10px 7px -1px rgba(0, 0, 0, 0.14), 0px 10px 12px -3px rgba(0, 0, 0, 0.2); }
  ._workspace-right-panel_no6jl_7._hidden-controls_no6jl_15 {
    height: 100vh; }
  ._workspace-right-panel_no6jl_7 ._inner-panel_no6jl_17 {
    height: 100%; }
  ._workspace-right-panel_no6jl_7 ._inner-panel_no6jl_17 {
    height: 100%; }
  ._workspace-right-panel_no6jl_7 ._secondary_no6jl_21 {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    transition: transform ease 300ms, opacity ease 300ms;
    background-color: #fff; }
    ._workspace-right-panel_no6jl_7 ._secondary_no6jl_21 .panel-header .btn-back {
      background-color: transparent; }
      ._workspace-right-panel_no6jl_7 ._secondary_no6jl_21 .panel-header .btn-back:hover {
        background-color: #f7f8f8; }
  ._workspace-right-panel_no6jl_7 ._panel-body_no6jl_32 {
    position: relative;
    height: calc(100% - 49px); }
    ._workspace-right-panel_no6jl_7 ._panel-body-without-header_no6jl_35 {
      height: 100%; }
    ._workspace-right-panel_no6jl_7 ._panel-body_no6jl_32 .control-horizontal-tabs {
      background-color: #fff; }
      ._workspace-right-panel_no6jl_7 ._panel-body_no6jl_32 .control-horizontal-tabs .radio-button-wrapper:hover,
      ._workspace-right-panel_no6jl_7 ._panel-body_no6jl_32 .control-horizontal-tabs .selected {
        fill: #116dff; }
    ._workspace-right-panel_no6jl_7 ._panel-body_no6jl_32 ._content_no6jl_42 {
      height: 100%;
      background-color: #fff; }
      ._workspace-right-panel_no6jl_7 ._panel-body_no6jl_32 ._content_no6jl_42._has-tabs_no6jl_45 {
        height: calc(100% - 54px); }

import React from 'react';
import * as util from '#packages/util';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SelectedAppProvider } from '../selectedAppContext/selectedAppContext';
import { AppVersionsListProvider } from '../AppVersionsList/AppVersionsListContext';
import PrivateAppsPanel from '../../privateAppsPanel';
import { PrivateAppsProvider } from '../privateAppsProvider/privateAppsProvider';

export interface PrivateAppsContainerParams {
  initialAppDefId?: string;
  version?: string;
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const PrivateAppsPanelContainer: React.FC<PrivateAppsContainerParams> = ({
  initialAppDefId,
  version,
}) => (
  <QueryClientProvider client={queryClient}>
    <PrivateAppsProvider>
      <AppVersionsListProvider>
        <SelectedAppProvider
          initialAppDefId={initialAppDefId}
          selectedVersion={version}
        >
          <PrivateAppsPanel />
        </SelectedAppProvider>
      </AppVersionsListProvider>
    </PrivateAppsProvider>
  </QueryClientProvider>
);

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  null,
)(PrivateAppsPanelContainer);

import { newAddPanelAPI } from '#packages/newAddPanelAPI';
import { useEffect, useMemo, useState } from 'react';
import { enhanceAddPanelData } from './addPanelDataEnhancer';

export const useNewAddPanelData = ({
  categoryId,
  sectionName,
  myWidgets,
}: {
  categoryId?: string;
  sectionName?: string;
  myWidgets?: any;
}) => {
  const [isDataLoaded, setDataLoaded] = useState(newAddPanelAPI.isDataLoaded());

  const { categories, error } = useMemo(
    () => newAddPanelAPI.getDataSync(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDataLoaded],
  );

  const deeplink = newAddPanelAPI.getDeeplinkByCategoryIdAndSectionNameSync(
    categoryId,
    sectionName,
    categories,
  );

  useMemo(() => {
    if (myWidgets) {
      enhanceAddPanelData(categories, { myWidgets });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myWidgets?.length]);

  useEffect(() => {
    newAddPanelAPI.onceDataLoad(() => {
      setDataLoaded(true);
    });

    return () => {
      newAddPanelAPI.offDataLoad();
    };
  }, []);

  const reloadData = () => {
    setDataLoaded(false);

    newAddPanelAPI.onceDataLoad(() => {
      setDataLoaded(true);
    });

    newAddPanelAPI.loadEntities();
  };

  return {
    isDataLoaded,
    error,
    categories,
    deeplink,
    reloadData,
  };
};

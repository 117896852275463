import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 264,
        height: 402,
        x: 18,
        y: 12,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'qrj68',
      },
      parent: 'comp-kgujrsp1',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'qrj68',
        },
        title: '',
        uri: 'df88a311915f4d9aad62fb50c572e819.jpg',
        description: '',
        width: 5472,
        height: 3648,
        alt: 'Ice Cream Party ',
        crop: {
          x: 1662,
          y: 0,
          width: 2233,
          height: 3409,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'Ice Cream Party ',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'qrj68',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'qrj68',
        },
        author: 'studio',
        offsetX: 15,
        offsetY: 11,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgoshxi5',
        recommendedScale: 1,
        recommendedWidth: 250,
        recommendedHeight: 382,
        orderIndex: 0,
      },
      style: 'wp2',
    },
    {
      type: 'Container',
      components: [
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 106,
            height: 15,
            x: 52,
            y: 19,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'qrj68',
          },
          parent: 'comp-kgujrspb',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'qrj68',
            },
            text: `<h2 class="font_2" style="font-size:12px; line-height:1.2em; text-align:center"><span style="font-size:12px"><span style="color:#000000"><span style="letter-spacing:0.35em"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif">${translate(
              'interactions_preset_ice_cream_blog_text_1',
            )}</span></span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'qrj68',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'qrj68',
            },
            author: 'studio',
            offsetX: 61,
            offsetY: 20,
            offsetOrigin: 'leftTop',
            originalCompId: 'comp-kgotlj9e',
            recommendedScale: 0.84683645,
            recommendedWidth: 79,
            recommendedHeight: 14,
            orderIndex: 0,
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-in-out',
            duration: 0.4,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'qrj68',
            },
          },
          scopedTransitions: {
            'variants-kgujrsp9': {
              type: 'TransitionData',
              'timing-function': 'ease-in-out',
              duration: 0.4,
              delay: 0,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'qrj68',
              },
            },
          },
          scopedStyles: {
            'variants-kgujrsp9': {
              type: 'ComponentStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'qrj68',
              },
              style: {
                properties: {
                  f0: 'font_0',
                  f1: 'font_1',
                  f10: 'font_10',
                  f2: 'font_2',
                  f3: 'font_3',
                  f4: 'font_4',
                  f5: 'font_5',
                  f6: 'font_6',
                  f7: 'font_7',
                  f8: 'font_8',
                  f9: 'font_9',
                  color: '#000000',
                },
                propertiesSource: {
                  f0: 'theme',
                  f1: 'theme',
                  f10: 'theme',
                  f2: 'theme',
                  f3: 'theme',
                  f4: 'theme',
                  f5: 'theme',
                  f6: 'theme',
                  f7: 'theme',
                  f8: 'theme',
                  f9: 'theme',
                  color: 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.WRichText',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
            },
          },
          style: 'txtNew',
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 186,
            height: 56,
            x: 12,
            y: 40,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'qrj68',
          },
          parent: 'comp-kgujrspb',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'qrj68',
            },
            text: `<h2 class="font_2" style="font-size:21px; text-align:center"><span style="font-size:21px"><span style="color:#000000"><span style="font-family:playfairdisplay-bold,playfair display,serif">${translate(
              'interactions_preset_ice_cream_blog_text_2',
            )}</span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'qrj68',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'qrj68',
            },
            author: 'studio',
            offsetX: -48,
            offsetY: 6,
            offsetOrigin: 'leftBottom',
            originalCompId: 'comp-kgotjjdr',
            recommendedScale: 0.91740614,
            recommendedWidth: 175,
            recommendedHeight: 52,
            orderIndex: 1,
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-in-out',
            duration: 0.4,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'qrj68',
            },
          },
          scopedTransitions: {
            'variants-kgujrsp9': {
              type: 'TransitionData',
              'timing-function': 'ease-in-out',
              duration: 0.4,
              delay: 0,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'qrj68',
              },
            },
          },
          scopedStyles: {
            'variants-kgujrsp9': {
              type: 'ComponentStyle',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'qrj68',
              },
              style: {
                properties: {
                  f0: 'font_0',
                  f1: 'font_1',
                  f10: 'font_10',
                  f2: 'font_2',
                  f3: 'font_3',
                  f4: 'font_4',
                  f5: 'font_5',
                  f6: 'font_6',
                  f7: 'font_7',
                  f8: 'font_8',
                  f9: 'font_9',
                  color: '#000000',
                  textDecoration: 'underline',
                },
                propertiesSource: {
                  f0: 'theme',
                  f1: 'theme',
                  f10: 'theme',
                  f2: 'theme',
                  f3: 'theme',
                  f4: 'theme',
                  f5: 'theme',
                  f6: 'theme',
                  f7: 'theme',
                  f8: 'theme',
                  f9: 'theme',
                  color: 'value',
                  textDecoration: 'value',
                },
                groups: {},
              },
              componentClassName: 'wysiwyg.viewer.components.WRichText',
              pageId: '',
              compId: '',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
            },
          },
          style: 'txtNew',
        },
      ],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 210,
        height: 116,
        x: 42,
        y: 350,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'qrj68',
      },
      parent: 'comp-kgujrsp1',
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'qrj68',
        },
        author: 'studio',
        offsetX: 25,
        offsetY: 322,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgoti47q',
        recommendedScale: 1,
        recommendedWidth: 201,
        recommendedHeight: 110,
        orderIndex: 1,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'qrj68',
        },
      },
      scopedTransformations: {
        'variants-kgujrsp9': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'qrj68',
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -34,
            },
          },
        },
      },
      scopedStyles: {
        'variants-kgujrsp9': {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'qrj68',
          },
          style: {
            properties: {
              'alpha-bg': '1',
              'alpha-brd': '1',
              bg: '#FFFFFF',
              'boxShadowToggleOn-shd': 'false',
              brd: '#423737',
              brw: '1',
              rd: '0px',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              'alpha-brd': 'value',
              bg: 'value',
              'boxShadowToggleOn-shd': 'value',
              brd: 'value',
              brw: 'value',
              rd: 'value',
              shd: 'value',
            },
            groups: {},
          },
          componentClassName: 'mobile.core.components.Container',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'qrj68',
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-brd': '1',
            bg: '#FFFFFF',
            'boxShadowToggleOn-shd': 'false',
            brd: '#423737',
            brw: '1',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 297,
    height: 466,
    x: 341,
    y: 25,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'qrj68',
  },
  parent: 'qrj68',
  mobileHints: {
    type: 'MobileHints',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'qrj68',
    },
    author: 'studio',
    originalCompId: 'comp-kgotfxtn',
    recommendedScale: 1,
    recommendedWidth: 280,
    recommendedHeight: 443,
  },
  variants: {
    'variants-kgujrsp9': {
      componentId: 'comp-kgujrsp1',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'qrj68',
      },
    },
  },
  scopedTransformations: {
    'variants-kgujrsp9': {
      type: 'TransformData',
      zLayer: 'BELOW_PINNED',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'qrj68',
      },
      rotate: 0,
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'qrj68',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};

import React, { type FC } from 'react';
import { manageMenuPopupClick } from '@wix/bi-logger-editor/v2';
import { hoc, biLogger } from '#packages/util';
import { translate } from '#packages/i18n';
import { frames } from '#packages/panels';
import { PromotionalList } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  mapDispatchToProps,
  type AdvancedMenusMigrationPanelDispatchProps,
} from './advancedMenusMigrationPanelMapper';
import styles from './advancedMenusMigrationPanel.scss';
import {
  PANEL_CONTENT,
  PRIMARY_BUTTON_TEXT,
  SECONDARY_BUTTON_TEXT,
} from './constants';

const reportClick = (origin: string) => {
  biLogger.report(
    manageMenuPopupClick({
      action_type: `${origin}_btn`,
    }),
  );
};

export interface AdvancedMenusMigrationPanelOwnProps {
  panelName: string;
  menusTypeBeforeMigration: keyof typeof PANEL_CONTENT;
}

export interface AdvancedMenusMigrationPanelProps
  extends AdvancedMenusMigrationPanelDispatchProps,
    AdvancedMenusMigrationPanelOwnProps {}

const AdvancedMenusMigrationPanel: FC<AdvancedMenusMigrationPanelProps> = ({
  panelName,
  menusTypeBeforeMigration,
  openHelpCenter,
  closePanelByName,
}) => {
  const panelContent = PANEL_CONTENT[menusTypeBeforeMigration];

  const closePanel = () => closePanelByName(panelName);
  const openHelpArticle = () => openHelpCenter(panelContent.helpId);

  const onPrimaryButtonClick = () => {
    reportClick('manage_menu');
    closePanel();
  };
  const onCloseButtonClick = () => {
    reportClick('close');
    closePanel();
  };

  const onSecondaryButtonClick = () => {
    reportClick('learn_more');
    openHelpArticle();
  };
  const onHelpButtonClick = () => {
    reportClick('help');
    openHelpArticle();
  };

  return (
    <frames.PromotionalPanelFrame
      panelName={panelName}
      illustration={
        <Symbol
          name="advancedMenusMigrationPanel"
          className={styles.illustration}
        />
      }
      title={translate(panelContent.title)}
      primaryButtonText={translate(PRIMARY_BUTTON_TEXT)}
      secondaryButtonText={translate(SECONDARY_BUTTON_TEXT)}
      onPrimaryButtonClick={onPrimaryButtonClick}
      onCloseButtonClick={onCloseButtonClick}
      onSecondaryButtonClick={onSecondaryButtonClick}
      onHelpButtonClick={onHelpButtonClick}
    >
      <div className={styles.listContainer}>
        <PromotionalList
          items={panelContent.items}
          symbol={<Symbol className={styles.check} name="check" />}
        />
      </div>
    </frames.PromotionalPanelFrame>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(AdvancedMenusMigrationPanel);

// @ts-nocheck
import _ from 'lodash';
import * as hiddenItemsData from '../hiddenItemsPanel/hiddenItemsData';
import * as stateManagement from '#packages/stateManagement';

function getHiddenCompsForPage(editorAPI, pageId) {
  const comps = editorAPI.mobile.hiddenComponents.get(pageId);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(comps, function (compId) {
    const isMobileOnly =
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(compId);
    return hiddenItemsData.getItemData(editorAPI, compId, pageId, isMobileOnly);
  });
}

function getFocusedPageHiddenComps(editorAPI, focusedPageId) {
  focusedPageId = focusedPageId || editorAPI.dsRead.pages.getFocusedPageId();
  const materPageHiddenComps = getHiddenCompsForPage(editorAPI, 'masterPage');
  const focusPageHiddenComps = getHiddenCompsForPage(editorAPI, focusedPageId);
  return _.uniqBy(
    _.union(materPageHiddenComps, focusPageHiddenComps),
    'compId',
  );
}

function getHiddenInteractionsItems(editorAPI) {
  return stateManagement.mobile.selectors.hiddenItems.getInteractionsMobileHiddenItems(
    editorAPI,
  );
}

function getPreviousHiddenInteractionsItems(editorAPI) {
  return stateManagement.mobile.selectors.interactionsHiddenItems.getPreviousInteractionsHiddenItems(
    editorAPI.store.getState(),
  );
}

export {
  getHiddenCompsForPage,
  getFocusedPageHiddenComps,
  getHiddenInteractionsItems,
  getPreviousHiddenInteractionsItems,
};

// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import _ from 'lodash';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { Checkbox } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated
  displayName: 'pageSelectorPageItem',
  propTypes: {
    isSubMenu: PropTypes.bool,
    isSubMenuLast: PropTypes.bool,
    validatePage: PropTypes.func,
    selectedPageIds: PropTypes.array.isRequired,
    dataSource: PropTypes.object.isRequired,
    toggleSelection: PropTypes.func,
  },

  handleToggleChanged(value) {
    if (this.props.toggleSelection) {
      this.props.toggleSelection(this.getPageId(), value);
    }
  },

  getItemClassNames(menuItem) {
    const isValid = this.validatePage().valid;

    return {
      'pages-selector-item': true,
      'current-page': false,
      'item-hidden': !menuItem.isVisible,
      'item-disabled': !isValid,
      'item-first-level': !this.props.isSubMenu,
      'item-second-level': this.props.isSubMenu,
      'item-second-level-last': this.props.isSubMenuLast,
      'item-is-page': menuItem.type.isPage,
    };
  },

  isSelected() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(this.props.selectedPageIds, this.getPageId());
  },

  validatePage() {
    const pageId = this.getPageId();
    const validators = [
      this.props.validatePage || _.noop,
      _.constant({ valid: true }),
    ];

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      validators,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (res, fn) {
        return res || fn(pageId, this);
      }, this),
      undefined,
    );
  },

  getPageId() {
    return this.props.dataSource.pageData && this.props.dataSource.pageData.id;
  },

  render() {
    const menuItem = this.props.dataSource;
    const editorAPI = this.getEditorAPI();
    const validationResult = this.validatePage();

    return (
      <div className={cx(this.getItemClassNames(menuItem))}>
        <baseUI.tooltip
          disabled={validationResult.valid}
          shouldTranslate={false}
          value={validationResult.message}
        >
          <label className="item-label">
            {menuItem.pageData ? (
              <Checkbox
                key="toggle"
                disabled={!validationResult.valid}
                value={this.isSelected()}
                onChange={this.handleToggleChanged}
              />
            ) : null}
            <span className="page-name">{menuItem.label}</span>
            {menuItem.pageData &&
            editorAPI.pages.isHomePage(menuItem.pageData.id) ? (
              <symbols.symbol name="pageItemHomeIcon" key="isHome" />
            ) : null}
            {menuItem.pageData &&
            core.utils.tpaTypeUtils.isTPA(menuItem.pageData) &&
            !core.utils.tpaTypeUtils.isTPASpecialPage(
              editorAPI.dsRead,
              menuItem.pageData,
            ) ? (
              <symbols.symbol name="actionButtonSpecialPages" key="isTpaPage" />
            ) : null}
            {menuItem.pageData &&
            core.utils.blogTypeUtils.isBlogSectionPage(
              editorAPI,
              menuItem.pageData,
            ) ? (
              <symbols.symbol name="actionButtonBlogPages" key="isBlogPage" />
            ) : null}
            {menuItem.type.isLink ? (
              <symbols.symbol name="pageItemLinkIcon" key="isLink" />
            ) : null}
            {menuItem.type.isDropdown ? (
              <symbols.symbol name="pageItemDropdownIcon" key="isDropDown" />
            ) : null}
            {!menuItem.isVisible ? (
              <symbols.symbol name="pageItemHideIcon" key="isHidden" />
            ) : null}
          </label>
        </baseUI.tooltip>
      </div>
    );
  },
});

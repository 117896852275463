import PropTypes from 'prop-types';
import React from 'react';
import { cx, workspace } from '#packages/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  boxSize: {
    width?: number;
    height?: number;
  };
  coords: {
    left?: number;
    top?: number;
  };
  boxOffset: {
    left?: number;
    top?: number;
  };
}

export default class extends React.PureComponent<Props> {
  static displayName = 'dragBox';

  static propTypes = {
    boxSize: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }).isRequired,
    coords: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
    }).isRequired,
    boxOffset: PropTypes.shape({
      left: PropTypes.number,
      top: PropTypes.number,
    }).isRequired,
  };

  getTransformValue = () => {
    return `translate(${this.props.boxOffset.left * 100}%, ${
      this.props.boxOffset.top * 100
    }%)`;
  };

  getDragBoxStyle = () => {
    const transform = this.getTransformValue();

    return {
      left: this.props.coords.left,
      top: this.props.coords.top,
      WebkitTransform: transform,
      transform,
      ...this.props.boxSize,
    };
  };

  render() {
    return (
      <div
        className={cx({
          'add-panel-draggable-wrapper': true,
          'add-panel-draggable-wrapper_new-workspaces':
            workspace.isNewWorkspaceEnabled(),
        })}
      >
        <div
          style={this.getDragBoxStyle()}
          className="add-panel-draggable-item"
        />
      </div>
    );
  }
}

// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const enabledActions = [
  ACTIONS.SETTINGS,
  ACTIONS.DESIGN,
  ACTIONS.LAYOUT,
  ACTIONS.ANIMATION,
  ACTIONS.HELP,
];
const mobileEnabledActions = [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION];

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_video',
      isApplied: gfppDataUtils.getDataDefinedFn('videoId'), // should this check for default wix video value?,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions(editorAPI, compRef) {
    const videoType = editorAPI.components.data.get(compRef)?.videoType;
    if (videoType === 'FACEBOOK' || videoType === 'YOUTUBE') {
      return _.without(enabledActions, ACTIONS.LAYOUT);
    }
    return enabledActions;
  },
  mobileEnabledActions(editorAPI, compRef) {
    const videoType = editorAPI.components.data.get(compRef)?.videoType;
    if (videoType === 'VIMEO') {
      return [ACTIONS.SETTINGS, ACTIONS.LAYOUT].concat(mobileEnabledActions);
    }
    if (videoType === 'YOUTUBE') {
      return [ACTIONS.SETTINGS].concat(mobileEnabledActions);
    }
    return mobileEnabledActions;
  },
  presetActions: {
    help: {
      helpId(editorAPI, compRef) {
        const videoType = editorAPI.components.data.get(compRef)?.videoType;
        return _.get(helpIds.GFPP, videoType, '');
      },
    },
  },
};

import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 303,
        height: 438,
        x: 6,
        y: 7,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'n7fk5',
      },
      parent: 'comp-kguvvr18',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
        title: '',
        uri: '11062b_c605458f7b7246aeb50414b374e4220d~mv2_d_5760_3840_s_4_2.jpg',
        description: '',
        width: 5760,
        height: 3840,
        alt: 'Shoes on Display',
        crop: {
          x: 2201,
          y: 0,
          width: 2656,
          height: 3840,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'Shoes on Display',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'n7fk5',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
    },
    {
      type: 'Container',
      components: [],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 301,
        height: 438,
        x: 7,
        y: 7,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'n7fk5',
      },
      parent: 'comp-kguvvr18',
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      scopedTransformations: {
        'variants-kguvvr1g': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
          rotate: 0,
        },
        'variants-kgz74eod': {
          type: 'TransformData',
          hidden: true,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
        style: {
          properties: {
            'alpha-bg': '0.6',
            'alpha-brd': '1',
            bg: '#000000',
            'boxShadowToggleOn-shd': 'false',
            brd: '#999997',
            brw: '0px',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 258,
        height: 106,
        x: 25,
        y: 278,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'n7fk5',
      },
      parent: 'comp-kguvvr18',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
        text: `<h3 class="font_3" style="font-size: 40px; text-align: center;"><span style="font-size:40px;"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:rgb(66, 55, 55)"><span style="font-style:italic"><span style="font-family:roboto-bold,roboto,sans-serif"><span style="color:#FFFFFF">${translate(
          'interactions_preset_new_arrival_hover_title_1',
        )}</span></span></span></span></span></span></span></span></h3>\n\n<h3 class="font_3" style="font-size: 40px; text-align: center;"><span style="font-size:40px;"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:rgb(66, 55, 55)"><span style="font-style:italic"><span style="font-family:roboto-bold,roboto,sans-serif"><span style="color:#FFFFFF">${translate(
          'interactions_preset_new_arrival_hover_title_2',
        )}</span></span></span></span></span></span></span></span></h3>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'n7fk5',
        },
        brightness: 1,
        packed: false,
        minHeight: 103,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      scopedTransformations: {
        'variants-kguvvr1g': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
          rotate: 0,
        },
        'variants-kgz74eod': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 228,
        height: 26,
        x: 41,
        y: 390,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'n7fk5',
      },
      parent: 'comp-kguvvr18',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
        text: `<h6 class="font_6" style="font-size:18px; text-align:center"><span style="font-size:22px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span><span style="color:rgb(66, 55, 55)"><span style="font-size:18px"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span style="color:#FFFFFF">${translate(
          'interactions_preset_new_arrival_subtitle_2',
        )}</span></span></span></span></span></span></span></span></span></h6>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'n7fk5',
        },
        brightness: 1,
        packed: false,
        minHeight: 23,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      scopedTransformations: {
        'variants-kguvvr1g': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
          rotate: 0,
        },
        'variants-kgz74eod': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 258,
        height: 41,
        x: 30,
        y: 352,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'n7fk5',
      },
      parent: 'comp-kguvvr18',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
        text: `<h2 class="font_2" style="font-size:34px; text-align:center"><span style="font-size:34px"><span style="color:#000000"><span style="font-style:italic"><span style="font-family:roboto-bold,roboto,sans-serif"><span style="background-color:#FFFFFF">${translate(
          'interactions_preset_new_arrival_title',
        )}</span></span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'n7fk5',
        },
        brightness: 1,
        packed: false,
        minHeight: 41,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-out',
        duration: 0.2,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      scopedTransformations: {
        'variants-kguvvr1g': {
          type: 'TransformData',
          hidden: true,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 258,
        height: 25,
        x: 24,
        y: 404,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'n7fk5',
      },
      parent: 'comp-kguvvr18',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
        text: `<h2 class="font_2" style="font-size:20px; text-align:center"><span style="font-size:20px"><span style="color:#000000"><span style="font-family:roboto-bold,roboto,sans-serif"><span style="background-color:#FFFFFF">${translate(
          'interactions_preset_new_arrival_subtitle',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'n7fk5',
        },
        brightness: 1,
        packed: false,
        minHeight: 25,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-out',
        duration: 0.2,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'n7fk5',
        },
      },
      scopedTransformations: {
        'variants-kguvvr1g': {
          type: 'TransformData',
          hidden: true,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'n7fk5',
          },
        },
      },
      style: 'txtNew',
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 318,
    height: 457,
    x: 331,
    y: 21,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'n7fk5',
  },
  parent: 'n7fk5',
  variants: {
    'variants-kguvvr1g': {
      componentId: 'comp-kguvvr18',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'n7fk5',
      },
    },
  },
  scopedTransformations: {
    'variants-kguvvr1g': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'n7fk5',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'n7fk5',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};

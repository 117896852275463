import { mobile, selection } from '#packages/stateManagement';
import { canSelectSectionLike } from '../../../selectors';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import { FixedStageApiKey } from '#packages/apis';

export interface SiteSegmentNameOwnProps {
  compRef: CompRef;
}

export interface SiteSegmentNameStateProps {
  isSelected: boolean;
  isMobile: boolean;
  isZoomMode: boolean;
  isHeader: boolean;
  name: string;
  canSelect: boolean;
  labelOffsetRight: string;
}

export interface SiteSegmentNameDispatchProps {
  selectIfNeeded: () => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  SiteSegmentNameStateProps,
  SiteSegmentNameOwnProps
> = (stateMapperArgs, { compRef }) => {
  const { state, editorAPI } = stateMapperArgs;
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);

  const selectedComponent =
    selection.selectors.getSingleSelectedComponent(state);

  const isMobile = mobile.selectors.isMobileEditor(state);
  const isZoomMode = editorAPI.zoomMode.isStageZoomMode();
  const isSelected = editorAPI.utils.isSameRef(selectedComponent, compRef);
  const canSelect = canSelectSectionLike(stateMapperArgs);
  const isHeader = editorAPI.utils.isSameRef(
    compRef,
    editorAPI.siteSegments.getHeader(),
  );

  return {
    name: editorAPI.components.getDisplayName(compRef),
    canSelect,
    isMobile,
    isSelected,
    isZoomMode,
    isHeader,
    labelOffsetRight: fixedStageAPI.getOffsetRightForComponentsFloatingRight(),
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  SiteSegmentNameDispatchProps,
  SiteSegmentNameOwnProps
> = (dispatch, { compRef }) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    selectIfNeeded() {
      const isSelected =
        editorAPI.selection.getSelectedComponentId() === compRef.id;

      if (!isSelected) {
        editorAPI.selection.selectComponentByCompRef(compRef, {
          origin: 'label',
        });
      }
    },
  };
};

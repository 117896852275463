import _ from 'lodash';

import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';

import { getSendBi } from '../../../topBarComponent/topBarMapper';
import {
  mapStateToProps as mapStateToMenuAPIProps,
  mapDispatchToProps as mapDispatchToMenuAPIProps,
} from './toolsMenuAPIMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  ToolsListItemsOwnProps,
  ToolsListItemsStateProps,
  ToolsListItemsDispatchProps,
} from './toolsMenuStructure';

const mapStateToProps: MapStateToProps<
  ToolsListItemsStateProps,
  ToolsListItemsOwnProps
> = (...args) => {
  const [{ editorAPI, state }] = args;

  return {
    ...mapStateToMenuAPIProps(...args),

    isToolBarEnabled: Boolean(editorAPI.toolbar.isToolbarEnabled()),
    isLayersPanelDisplayed:
      stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state),
    inInteractionMode:
      stateManagement.interactions.selectors.isInInteractionMode(state),
    isRulersEnabled: Boolean(
      stateManagement.rulers.selectors.isRulersEnabled(state),
    ),
    developerModeFirstTime:
      stateManagement.userPreferences.selectors.getSiteUserPreferences<boolean>(
        constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME,
      )(state) || false,
    isDeveloperToolbarInCodePanel: Boolean(
      editorAPI.developerToolBar.isInCodePanel(),
    ),
    isDeveloperHiddenComponentsEnabled: Boolean(
      editorAPI.developerHiddenComponents.isEnabled(),
    ),
    isDeveloperToolBarEnabled: Boolean(
      editorAPI.developerToolBar.isEnabled() ||
        stateManagement.sectionedPanel.selectors
          .openDockedSections(state)
          .some(
            (sectionName: string) =>
              sectionName === constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
          ),
    ),
    isDeveloperModeEnabled: Boolean(editorAPI.developerMode.isEnabled()),
    isGuidelinesEnabled: Boolean(
      editorAPI.grid.guidelines.isGuidelinesEnabled(),
    ),
    isMobileEditor: editorAPI.isMobileEditor(),
    isSnapToEnabled: Boolean(editorAPI.grid.snapTo.isSnapToEnabled()),
    siteId: _.invoke(editorAPI, 'dsRead.generalInfo.getSiteId'),
    deviceType: stateManagement.leftBar.selectors.getDeviceType(state),
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  ToolsListItemsDispatchProps,
  ToolsListItemsOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToMenuAPIProps(dispatch, ownProps),

  sendBi: getSendBi(dispatch),
});

export { mapStateToProps, mapDispatchToProps };

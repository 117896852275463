import * as editorMain from './rootComps/editorMain';
import { EditorComponent } from './rootComps/editor';
import { TextEditor } from '#packages/textEditor';
import * as panels from './rootComps/panels/panels';
import * as rootSelectors from './rootComps/selectors/rootSelectors';
import * as mouseSelectors from './rootComps/selectors/mouseSelectors';
import * as selectedComponentsSelectors from './rootComps/selectors/selectedCompsSelectors';

import { createSelector, createStructuredSelector } from './selectors/selector';
import dynamicPagesComboBox from './rootComps/topBar/dynamicPagesComboBox';
import openedPanels from './rootComps/panels/openedPanels';
import { create } from './app/EditorAPI';
import * as addPanelAPI from './app/APISections/addPanelAPI';
import gfpp from './rootComps/gfpp/gfpp';
import * as gfppMapper from './rootComps/gfpp/gfppMapper';
import { gfppModel } from '#packages/gfppData';
import * as siteSettingsUtil from './utils/siteSettingsUtil';
import * as openPanelByURL from './utils/openPanelByURL';
import * as widgetViewState from './rootComps/selectionBox/focusBox/widgetViewState';

import { HelpHome as HelpHomePanel } from './rootComps/helpHome';
import NewReleasesFeedPanel from './rootComps/newReleasesFeed/newReleasesFeed';
import FloatingModeBar from './rootComps/floatingModeBar/floatingModeBar';

import { check, show } from './app/deeplink';

export type { __EditorAPI } from './app/EditorAPI';
export type { Point } from './pasteLogic/types';
export type { Address } from './rootComps/siteCreation/siteCreationUtils';
export type { MouseMoveAction } from './app/APISections/mouseActionsWrapper';
export type { RightClickMenuItems } from './rootComps/rightClickMenu/util/rcmDataParser';

export type { BaseDragApi } from './utils/mouseMoveActions/baseDrag/baseDragApi';

export {
  editorMain,
  EditorComponent as editorComp,
  panels,
  create as createEditorAPI,
  addPanelAPI,
  gfppModel,
  HelpHomePanel,
  NewReleasesFeedPanel,
  FloatingModeBar,
};
export const components = {
  textEditor: TextEditor,
  dynamicPagesComboBox,
  openedPanels,
  gfpp: {
    connectedGfpp: gfpp,
    gfppMapper,
  },
  focusBox: {
    widgetViewState,
  },
};
export const utils = {
  siteSettingsUtil,
  openPanelByURL,
};
export const deeplink = {
  check,
  show,
};
export const selectors = {
  rootSelectors,
  mouseSelectors,
  selectedComponentsSelectors,
  createSelector,
  createStructuredSelector,
};

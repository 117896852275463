import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import gfppDataUtils from './gfppDataUtils';
import { designData, arrayUtils, backgroundUtils } from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, LegacyBehaviorObject } from 'types/documentServices';

const getTooltips = (containerType: string, isSingleColumn?: boolean) => {
  if (containerType === 'ClassicSection') {
    return {
      transparent: 'gfpp_tooltip_section_bg_scroll_effects_unsupported',
      disabled: 'gfpp_tooltip_section_bg_scroll_effects_unsupported',
      applied: 'gfpp_tooltip_applied_stripContainer_bg_scroll_effects',
      default: 'gfpp_tooltip_stripContainer_bg_scroll_effects',
    };
  } else if (containerType === 'StripColumnsContainer' || isSingleColumn) {
    return {
      transparent: 'gfpp_tooltip_stripContainer_bg_scroll_effects_unsupported',
      disabled: 'gfpp_tooltip_stripContainer_bg_scroll_effects_covered',
      applied: 'gfpp_tooltip_applied_stripContainer_bg_scroll_effects',
      default: 'gfpp_tooltip_stripContainer_bg_scroll_effects',
    };
  }
  return {
    transparent: 'gfpp_tooltip_columns_bg_scroll_effects_unsupported',
    disabled: 'gfpp_tooltip_columns_bg_scroll_effects_unsupported',
    applied: 'gfpp_tooltip_applied_columns_bg_scroll_effects',
    default: 'gfpp_tooltip_stripContainer_bg_scroll_effects',
  };
};

const isBackgroundVisible = (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
) => {
  const activeComp = getColumnOrStripContainer(editorAPI, compRef) as CompRef[];
  const activeCompDesignData = editorAPI.components.design.get(activeComp[0]);
  if (backgroundUtils.isTransparentBG(activeCompDesignData)) {
    return false;
  }

  const isSingleColumn =
    isStripColumnsContainer(editorAPI, compRef) &&
    editorAPI.columns.isSingleColumnStrip(compRef);

  return (
    !isStripColumnsContainer(editorAPI, compRef) ||
    shouldShowChangeBackgroundAction(editorAPI, compRef, isSingleColumn)
  );
};

/**
 * check if background design data is not opaque
 */
const isBackgroundEffectDisabled = (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
): boolean => {
  if (!isBackgroundVisible(editorAPI, compRef)) {
    return true;
  }
  const activeComp = (
    getColumnOrStripContainer(editorAPI, compRef) as CompRef[]
  )[0];

  return gfppDataUtils.shouldDisableBackgroundEffectsByDesignData(
    editorAPI,
    activeComp,
  );
};

const hasOpacity = (designData: any) => {
  const background = designData?.background;
  const colorOpacity =
    background?.colorLayers?.[0]?.opacity ?? background?.colorOpacity ?? 1;
  const media = background?.mediaRef;
  return !background || (colorOpacity < 1 && (!media || media.opacity < 1));
};

const hasMargins = (
  editorAPI: EditorAPI,
  columnsContainer: CompRef | CompRef[],
) => {
  return (
    _(columnsContainer)
      .thru(editorAPI.components.properties.get)
      .pick(['columnsMargin', 'frameMargin', 'rowMargin'])
      .values()
      .compact()
      .value().length > 0
  );
};

const hasChildrenWithOpacity = (
  editorAPI: EditorAPI,
  columnsContainer: CompRef | CompRef[],
) =>
  _(columnsContainer)
    .thru(editorAPI.components.getChildren)
    .map(editorAPI.components.design.get)
    .filter(hasOpacity)
    .value().length > 0;

const shouldShowChangeBackgroundAction = (
  editorAPI: EditorAPI,
  columnsContainer: CompRef | CompRef[],
  isSingleColumn: boolean,
) =>
  isSingleColumn ||
  hasMargins(editorAPI, columnsContainer) ||
  hasChildrenWithOpacity(editorAPI, columnsContainer);

const isBackgroundEffectApplied = (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
) => {
  const isDisabled = isBackgroundEffectDisabled(editorAPI, compRef);
  const activeComp = (
    getColumnOrStripContainer(editorAPI, compRef) as CompRef[]
  )[0];
  const _viewMode = editorAPI.viewMode.get();
  const behaviors = editorAPI.components.behaviors.get(
    activeComp,
  ) as LegacyBehaviorObject[];
  return (
    !isDisabled &&
    behaviors.some(
      ({ action, viewMode = 'DESKTOP' }) =>
        action === 'bgScrub' && viewMode === _viewMode,
    )
  );
};

const getBackgroundEffectTooltip = (editorAPI: EditorAPI, compRef: CompRef) => {
  const containerType = stateManagement.components.selectors.getCompTypeSuffix(
    compRef,
    editorAPI.dsRead,
  );
  const isSingleColumn =
    containerType === 'StripColumnsContainer' &&
    editorAPI.columns.isSingleColumnStrip(compRef);
  const tooltips = getTooltips(containerType, isSingleColumn);

  const isDisabled = isBackgroundEffectDisabled(editorAPI, compRef);
  const isTransparent = !isBackgroundVisible(editorAPI, compRef);
  const isApplied = isBackgroundEffectApplied(editorAPI, compRef);
  const state =
    (isTransparent && 'transparent') ||
    (isDisabled && 'disabled') ||
    (isApplied && 'applied') ||
    'default';

  return tooltips[state];
};

const isStripColumnsContainer = (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
) =>
  stateManagement.components.selectors.getCompTypeSuffix(
    compRef,
    editorAPI.dsRead,
  ) === 'StripColumnsContainer';

const getColumnOrStripContainer = (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
) => {
  if (isStripColumnsContainer(editorAPI, compRef)) {
    return editorAPI.columns.isSingleColumnStrip(compRef) &&
      !designData.hasDividersDesign(editorAPI, compRef)
      ? editorAPI.components.getChildren(compRef)
      : compRef;
  }
  return compRef;
};

const shouldShowManageColumnsAction = (
  editorAPI: EditorAPI,
  columnsContainerPtr: CompRef,
) => {
  const isSingleColumn =
    editorAPI.columns.isSingleColumnStrip(columnsContainerPtr);

  return !isSingleColumn;
};

const MANAGE_COLUMNS_PANEL_NAME =
  'compPanels.panels.StripColumnsContainer.managePanel';

const getManageColumnsActionIfApplicable = (
  editorAPI: EditorAPI,
  columnsContainerPtr: CompRef,
  initialManagedColumn?: CompRef,
) => {
  if (!shouldShowManageColumnsAction(editorAPI, columnsContainerPtr)) {
    return null;
  }

  return {
    label: 'gfpp_secondaryaction_stripContainer',
    isSelected: gfppDataUtils.getPanelStateFn(MANAGE_COLUMNS_PANEL_NAME),
    onClick: _.partial(
      editorAPI.columns.manage.openPanel,
      // @ts-expect-error
      arrayUtils.asArray(columnsContainerPtr),
      initialManagedColumn,
    ),
  };
};

const getBackgroundEffectsRightClickLabel = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  return isStripColumnsContainer(editorAPI, compRef)
    ? 'gfpp_tooltip_stripContainer_bg_scroll_effects_right_click'
    : 'gfpp_tooltip_columns_bg_scroll_effects_right_click';
};

export default {
  getColumnOrStripContainer,
  getBackgroundEffectTooltip,
  shouldShowChangeBackgroundAction,
  isBackgroundEffectDisabled,
  isBackgroundEffectApplied,
  getBackgroundEffectsRightClickLabel,
  hasMargins,
  hasChildrenWithOpacity,
  isStripColumnsContainer,
  getManageColumnsActionIfApplicable,
};

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { AppData, ComponentRef } from '@wix/platform-editor-sdk';
import type { WidgetSlot } from '@wix/editor-platform-host-integration/ui';

interface SdkOrigin {
  origin: 'sdk';
}

interface AmOrigin {
  origin: 'appMarket';
}

interface KeyboardOrigin {
  origin: 'keyboard';
}

interface PluginsPanelOrigin {
  origin: 'pluginsPanel';
  action: 'installPluginInEditor' | 'installDefaultPluginInBlocks';
}

interface RcmOrigin {
  origin: 'contextMenu';
  action: 'movePlugin';
}

export type Origin =
  | SdkOrigin
  | AmOrigin
  | KeyboardOrigin
  | PluginsPanelOrigin
  | RcmOrigin;

function getWidgetSlots(
  editorAPI: EditorAPI,
  _appData: AppData,
  _token: string,
  options: { widgetRef: ComponentRef },
): WidgetSlot[] {
  return editorAPI.platform.widgetPlugins.getWidgetSlots(options.widgetRef);
}

async function addWidgetPlugin(
  editorAPI: EditorAPI,
  _appData: AppData,
  _token: string,
  options: {
    slotCompRef: ComponentRef;
    widgetPluginPointer: {
      appDefinitionId: string;
      widgetId: string;
    };
  },
  origin: Origin,
): Promise<CompRef> {
  const slotInfo = await editorAPI.platform.widgetPlugins.getWidgetSlot(
    options.slotCompRef,
  );
  if (slotInfo?.pluginInfo !== undefined) {
    await editorAPI.platform.widgetPlugins.uninstallWidgetPlugin(
      options.slotCompRef,
    );
  }
  return editorAPI.platform.widgetPlugins.installWidgetPlugin(
    options.slotCompRef,
    options.widgetPluginPointer,
    origin,
  );
}

function removeWidgetPlugin(
  editorAPI: EditorAPI,
  _appData: AppData,
  _token: string,
  options: { slotCompRef: ComponentRef; origin?: Origin['origin'] },
): Promise<void> {
  return editorAPI.platform.widgetPlugins.uninstallWidgetPlugin(
    options.slotCompRef,
    options.origin,
  );
}

export const widgetPlugins = {
  getWidgetSlots,
  addWidgetPlugin,
  removeWidgetPlugin,
};

import { SiteGlobalDataApiKey } from '#packages/apis';

import type { MapStateToProps } from 'types/redux';
import type {
  QuestionnairePanelOwnProps,
  QuestionnairePanelStateProps,
} from '../../types';

const mapStateToProps: MapStateToProps<
  QuestionnairePanelStateProps,
  QuestionnairePanelOwnProps
> = ({ host }) => {
  const siteGlobalDataAPI = host.getAPI(SiteGlobalDataApiKey);

  return {
    initialBusinessType: siteGlobalDataAPI.getBusinessType(),
    initialBusinessName: siteGlobalDataAPI.getBusinessName(),
  };
};

export { mapStateToProps };

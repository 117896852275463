import experiment from 'experiment';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type {
  PluginInfo,
  WidgetPointer,
} from '@wix/editor-platform-host-integration/ui';
import type { WidgetPluginComponentData } from '@wix/ambassador-app-service-webapp/types';
import type { EditorAPI } from '#packages/editorAPI';
import type { DsItem, CompStructure } from 'types/documentServices';
import type {
  AppDataComponent,
  AppComponent,
  AppData,
} from '@wix/document-services-types';
import { ClientSDK, Platform } from '@wix/installation-manager-client-sdk';
import type { Origin } from '../api/document/tpa/widgetPlugins';
import { HttpClient } from '@wix/http-client';

export const IFRAME_WIDGET = 'WIDGET';
export const IFRAME_PAGE = 'PAGE';
export const OOI_WIDGET = 'WIDGET_OUT_OF_IFRAME';
export const OOI_PAGE = 'PAGE_OUT_OF_IFRAME';
export const BLOCKS_WIDGET = 'STUDIO_WIDGET';
const SUPPORTED_WIDGETS = [
  IFRAME_WIDGET,
  IFRAME_PAGE,
  OOI_WIDGET,
  OOI_PAGE,
  BLOCKS_WIDGET,
] as const;

const compDefCreators = {
  [IFRAME_WIDGET]: createIFrameComponent,
  [IFRAME_PAGE]: createIFrameComponent,
  [OOI_WIDGET]: createTPAWidgetComponent,
  [OOI_PAGE]: createTPAWidgetComponent,
  [BLOCKS_WIDGET]: createBlocksWidgetComponent,
} as const;

export function getPluginMarketData(
  editorAPI: EditorAPI,
  widgetPointer: WidgetPointer,
): WidgetPluginComponentData | undefined {
  const pluginMarketDataComponent = editorAPI.tpa.app
    .getDataByAppDefId(widgetPointer.appDefinitionId)
    ?.components.find(
      (component) =>
        (component?.data as WidgetPluginComponentData)?.referenceComponentId ===
        widgetPointer.widgetId,
    );
  return pluginMarketDataComponent?.data as WidgetPluginComponentData;
}

export function getPluginInfo(
  editorAPI: EditorAPI,
  slotsComponentData: DsItem | undefined,
): PluginInfo | undefined {
  if (!slotsComponentData) {
    return undefined;
  }

  return {
    ...getPluginMarketData(editorAPI, {
      appDefinitionId: slotsComponentData.appDefinitionId,
      widgetId: slotsComponentData.widgetId,
    })?.marketData,
    widgetId: slotsComponentData.widgetId,
    appDefinitionId: slotsComponentData.appDefinitionId,
  } as PluginInfo;
}

function getWidgetAppComponent(
  editorAPI: EditorAPI,
  widgetPointer: WidgetPointer,
): AppDataComponent {
  const appData = editorAPI.tpa.app.getDataByAppDefId(
    widgetPointer.appDefinitionId,
  );

  const widgetAppComponent = appData.components.find(
    (appComponent) => appComponent.componentId === widgetPointer.widgetId,
  );

  if (!widgetAppComponent) {
    throw new Error(
      `Application "${appData.name}" is missing the widget component with ID: ${widgetPointer.widgetId}`,
    );
  }

  return widgetAppComponent;
}

function getWidgetPluginType(widgetAppComponent: AppDataComponent) {
  const widgetPluginType = widgetAppComponent?.type;

  // @ts-expect-error
  if (!SUPPORTED_WIDGETS.includes(widgetPluginType)) {
    throw new Error(`Widget-plugin type is unsupported -- ${widgetPluginType}`);
  }

  return widgetPluginType as (typeof SUPPORTED_WIDGETS)[number];
}

export async function createWidgetPluginComponentDefinition(
  editorAPI: EditorAPI,
  widgetPointer: WidgetPointer,
  applicationId: number,
) {
  const widgetAppComponent = getWidgetAppComponent(editorAPI, widgetPointer);

  const widgetPluginType = getWidgetPluginType(widgetAppComponent);

  return widgetPluginType === BLOCKS_WIDGET
    ? compDefCreators[widgetPluginType](editorAPI, widgetPointer, applicationId)
    : compDefCreators[widgetPluginType](
        editorAPI,
        widgetPointer,
        applicationId,
        widgetAppComponent as AppComponent,
      );
}

function createIFrameComponent(
  editorAPI: EditorAPI,
  { appDefinitionId, widgetId }: WidgetPointer,
  applicationId: number,
  widgetPluginAppComponent: AppComponent,
): CompStructure {
  const DEFAULT_INITIAL_HEIGHT = 100;

  const widgetComponent = editorAPI.components.buildDefaultComponentStructure(
    'wysiwyg.viewer.components.tpapps.TPAWidget',
  );

  widgetComponent.data = {
    ...widgetComponent.data,
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  };

  // iFrame needs an initial height to be rendered. Later it can set its own height via Js-SDK
  const initialHeight =
    // @ts-expect-error
    widgetPluginAppComponent?.data?.height || DEFAULT_INITIAL_HEIGHT;

  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts({ height: initialHeight }),
  } as any;

  return widgetComponent;
}

function createTPAWidgetComponent(
  editorAPI: EditorAPI,
  { appDefinitionId, widgetId }: WidgetPointer,
  applicationId: number,
): CompStructure {
  const widgetComponent = editorAPI.components.buildDefaultComponentStructure(
    'wysiwyg.viewer.components.tpapps.TPAWidget',
  );

  widgetComponent.data = {
    ...widgetComponent.data,
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  };

  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts(),
  } as any;

  return widgetComponent;
}

async function getDefaultPreset(
  editorAPI: EditorAPI,
  widgetPointer: WidgetPointer,
) {
  const appDescriptor =
    await editorAPI.dsRead.appStudioWidgets.getAppDescriptor(
      widgetPointer.appDefinitionId,
    );

  const widgetDescriptor = Object.values(appDescriptor.widgets).find(
    (widget) => widget.devCenterWidgetId === widgetPointer.widgetId,
  );

  const [defaultPreset] = widgetDescriptor?.presets ?? [];

  if (!defaultPreset) {
    return undefined;
  }

  const presetId = defaultPreset.presetId.replace('#', '');

  return {
    type: 'PresetData',
    layout: presetId,
    style: presetId,
  };
}

async function createBlocksWidgetComponent(
  editorAPI: EditorAPI,
  { appDefinitionId, widgetId }: WidgetPointer,
  applicationId: number,
): Promise<CompStructure> {
  const widgetComponent = editorAPI.components.buildDefaultComponentStructure(
    'wysiwyg.viewer.components.RefComponent',
  );

  widgetComponent.data = {
    ...widgetComponent.data,
    type: 'WidgetRef',
    appDefinitionId,
    applicationId: applicationId.toString(),
    widgetId,
  };
  widgetComponent.layouts = {
    ...widgetComponent.layouts,
    ...getResponsiveLayouts(),
  } as any;
  // @ts-expect-errors
  widgetComponent.presets = await getDefaultPreset(editorAPI, {
    appDefinitionId,
    widgetId,
  });

  return widgetComponent;
}

interface LayoutOptions {
  height?: number;
}

function getResponsiveLayouts(options: LayoutOptions = {}) {
  return {
    type: 'SingleLayoutData',
    componentLayout: {
      type: 'ComponentLayout',
      height: options.height
        ? { type: 'px', value: options.height }
        : { type: 'auto' },
      width: {
        type: 'auto',
      },
    },
    itemLayout: {
      type: 'GridItemLayout',
      gridArea: {
        rowStart: 1,
        columnStart: 1,
        rowEnd: 2,
        columnEnd: 2,
      },
      alignSelf: 'stretch',
      justifySelf: 'stretch',
    },
    containerLayout: {
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
    },
  };
}

export function isInViewport(
  editorAPI: EditorAPI,
  position: { y: number; height: number },
): boolean {
  const stage = editorAPI.ui.stage.getEditingAreaLayout();

  return position.y > 0 && position.y + position.height < stage.height;
}

function isAppInstalled(
  editorAPI: EditorAPI,
  widgetPointer: WidgetPointer,
): boolean {
  return (
    editorAPI.platform.isAppActive(widgetPointer.appDefinitionId) ||
    editorAPI.tpa.app.isInstalled(widgetPointer.appDefinitionId)
  );
}

function installAppWithPluginsIntent(
  editorAPI: EditorAPI,
  widgetPointer: WidgetPointer,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();

    const appInstaller = new ClientSDK({
      platform: Platform.EDITOR,
      metaSiteId,
      httpClient: new HttpClient(),
    });

    appInstaller.installAppWithIntent({
      appDefId: widgetPointer.appDefinitionId,
      onInstallationSuccess: resolve,
      onInstallationFail: reject,
      onInstallationCancel: reject,
      onInstallationWindowClosed: reject,
      intent: 'plugins',
    });
  });
}

export async function provisionPluginAppIfNeeded(
  editorAPI: EditorAPI,
  widgetPointer: WidgetPointer,
  origin: Origin,
): Promise<AppData> {
  const isPluginAppInstalled = isAppInstalled(editorAPI, widgetPointer);

  if (!isPluginAppInstalled) {
    if (
      ['pluginsPanel', 'contextMenu'].includes(origin?.origin) &&
      experiment.isOpen('se_wpInstallationManagerClientSDK')
    ) {
      await installAppWithPluginsIntent(editorAPI, widgetPointer);
    }
    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
    const dependencyDriver =
      await platformAPI.applications.createDependenciesDriver(
        widgetPointer.appDefinitionId,
      );
    const appDefIdsToInstall =
      dependencyDriver.getAppsToInstall().appsToInstall || [];

    for (const appDefId of appDefIdsToInstall) {
      if (experiment.isOpen('se_wp_addApps')) {
        await installPluginApp(appDefId, editorAPI);
      } else {
        await editorAPI.platform.provision(appDefId, {});
      }
    }
  }

  return editorAPI.tpa.app.getDataByAppDefId(widgetPointer.appDefinitionId);
}

function installPluginApp(appDefinitionId: string, editorAPI: EditorAPI) {
  return new Promise<void>((resolve) => {
    editorAPI.platform.addApps([appDefinitionId], {
      [appDefinitionId]: { headlessInstallation: true },
      singleAppCallback: () => {
        resolve();
      },
    });
  });
}

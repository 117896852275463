/*eslint max-lines: [2, { "max": 1250, "skipComments": true, "skipBlankLines": true}]*/
// @ts-nocheck
import React from 'react';
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import experiment from 'experiment';
import * as util from '#packages/util';
import * as core from '#packages/core';
import * as panelUtils from '#packages/panelUtils';
import * as stateManagement from '#packages/stateManagement';
import * as styles from '#packages/styles';
import * as staticAssets from '#packages/staticAssets';
import * as textControls from '#packages/textControls';
import * as higherOrderComponents from '#packages/higherOrderComponents';
import * as coreBi from '#packages/coreBi';
import * as symbols from '@wix/santa-editor-symbols';
import * as baseUI from '#packages/baseUI';
import { componentPartTabEmpty } from '@wix/bi-logger-editor/v2';
import { translate } from '#packages/i18n';
import { SiteSegmentMediaBackgroundApiKey } from '#packages/apis';
import {
  Composites,
  DropDown,
  HorizontalTabs,
  VerticalTabsCustom,
  CustomScroll,
  Divider,
  Text,
} from '@wix/wix-base-ui';
import UiControl from './advancedStyleDynamicControl';
import { ScrollResetButton } from './ScrollResetButton/scrollResetButton';
import {
  categoriesData,
  horizontalTabsStateTranslatedKeys,
  statesDropdownTranslatedKeys,
  statesOrder,
} from './advancedStylePanelConsts';
import { applyDesignPanelConfiguration } from './utils';
import constants from '#packages/constants';
import { clickInsideDesignPanel } from '@wix/bi-logger-editor-data/v2';

export const TestIds = {
  STATE_DROPDOWN: 'state-dropdown',
  STATE_DROPDOWN_DIVIDER: 'state-dropdown-divider',
};

export const experiments = {
  updateTabCategoriesInStylePanel:
    'responsive-editor.UpdateTabCategoriesInStylePanel',
  hideEmptyCompPartTabs: 'se_hideEmptyCompPartTabs',
  siteSegmentMediaBackground: 'se_siteSegmentMediaBackground',
};

const { advancedStyleDataUtil } = styles.advancedStyle;
const { advancedStyleConstants } = styles.advancedStyle;
const { getSkin } = stateManagement.components.selectors;
const { getViewPort } = stateManagement.domMeasurements.selectors;
const { isInInteractionMode, isShownOnlyOnVariant } =
  stateManagement.interactions.selectors;

const DEFAULT_STYLE_STATE = 'regular';
const DEFAULT_SELECTED_COMP_PART = 'host';
const DEFAULT_SHADOW = '0px 1px 4px 0px rgba(0,0,0,0.6)';
const SCROLLED_SUFFIX = '-scrl';

function isMultiComponentDesign(compType, isMultiComponentDesign) {
  return (
    compType === advancedStyleConstants.multiComponents.compType ||
    isMultiComponentDesign
  );
}

function getFirstCategory(panelData, styleState) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  return _.head(sortCategories(_.keys(panelData[styleState])));
}

function hasSkinParams(panelData) {
  return !_.isEmpty(panelData);
}

function getRequiredPropsForControl(controlType) {
  const colorPickerDefaultProps = {
    openColorPicker: _.partial(
      this.openColorPickerFromCustomizeDesign,
      controlType,
    ),
    ignoreOpacityInPreview: true,
    colorResolver: this.resolveColor,
    isSmallStepper: true,
  };

  switch (controlType) {
    case 'coverImage':
      return this.props;
    case 'fontFamily':
      return {
        fonts: textControls.fontUtils.getCurrentSelectableFontsWithParams(
          this.getEditorAPI(),
        ),
      };
    case 'colorPicker':
    case 'colorPickerWithOpacity':
      const appStudioSelectors = stateManagement.applicationStudio.selectors;
      const isStrictColorPicker = appStudioSelectors.isStrictColorPicker(
        this.getEditorAPI().store.getState(),
      );

      const isOpacityDisabled = this.getIsOpacityDisabled();
      const tooltip =
        isOpacityDisabled &&
        'StylablePanel_Design_Scroll_Opacity_Disabled_Tooltip';
      return {
        ...colorPickerDefaultProps,
        isStrictColorPicker,
        preserveRGBA: false,
        isOpacityDisabled,
        tooltip,
      };
    case 'shadowControl':
      return {
        ..._.pick(this.props, ['forceUpdateParentStyle', 'styleDataItem']),
        sendSiteSegmentBi: this.sendSiteSegmentBI,
        advancedSiteSegmentEnabled:
          this.props.advancedSiteSegmentBackgroundEnabled,
      };
    case 'backgroundFillsControl':
      return {
        colorPickerValues:
          util.colorPickerUtils.getBackgroundDesignValuesForColorpicker(
            this.getEditorAPI(),
            this.props.selectedComponent,
          ),
        colorPickerProps: colorPickerDefaultProps,
        updateCompDesign: (val) => {
          const { param, value } = util.colorPickerUtils.updateComponentDesign(
            this.getEditorAPI(),
            this.props.selectedComponent,
            val,
          );
          if (this.props.advancedSiteSegmentBackgroundEnabled) {
            const biValues = this.props.getBiValuesAdvancedStyle(param);
            if (biValues?.field_name === 'color') return;
            this.sendSiteSegmentBI({ field_value: value, ...biValues });
          }
        },
      };
  }
  return {};
}

function getSkinNameByStyleId(styleId) {
  return (
    this.props.selectedSkin ||
    this.getEditorAPI().dsRead.theme.styles.get(styleId)?.skin ||
    this.props.styleDataItem?.skin
  );
}

function sortCategories(categories) {
  const sortedCategories = _.sortBy(
    categories,
    (categoryName) => categoriesData[categoryName].displayOrder,
  );
  return sortedCategories;
}

function scrollContainerToTop() {
  this.resetScrollPosition();
}

function reportThumbnailSliderMove(editorAPI, direction) {
  editorAPI.bi.event(coreBi.events.ADVANCED_STYLE_PANEL.SCROLL_SKINS, {
    component_type: this.props.compType,
    navigation_arrow: direction,
  });
}

function getMaxNumOfStates(skins, currentSkin, getSkinDefinitionFunction) {
  const compSkins = advancedStyleDataUtil.filterSkins(skins, currentSkin);

  return _.max(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _.map(compSkins, function (skin) {
      const skinData = advancedStyleDataUtil.getSkinDefinition(
        getSkinDefinitionFunction(skin),
        skin,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      return _(skinData).map('state').uniq().size();
    }),
  );
}

function getStateLabel(key) {
  if (this.maxNumOfStates > 3) {
    return (
      statesDropdownTranslatedKeys[key] ||
      horizontalTabsStateTranslatedKeys[key]
    );
  }

  return horizontalTabsStateTranslatedKeys[key];
}

function getPreviewStateWithCustomStates() {
  return this.state.selectedCompPartTab
    ? advancedStyleDataUtil.getPreviewStateWithCustomStates(
        this.state.skinName,
        this.state.selectedCompPartTab,
        this.state.selectedStyleState,
      )
    : this.state.selectedStyleState;
}

function filterPageProblematicSkin(isPage, compType, listOfSkins) {
  if (isPage) {
    return listOfSkins.filter(
      (skin) => skin !== 'wysiwyg.viewer.skins.page.SloopyPageSkin',
    );
  }
  return listOfSkins;
}

// eslint-disable-next-line react/prefer-es6-class
const AdvancedStylePanel = createReactClass({
  propTypes: {
    configuration: PropTypes.object,
    useMouseEvent: PropTypes.bool,
    selectedSkin: PropTypes.string,
    skinDefinition: PropTypes.object,
    selectedComponent: PropTypes.arrayOf(PropTypes.object).isRequired,
    styleId: PropTypes.string.isRequired,
    compType: PropTypes.string.isRequired,
    onAdvancedStylePanelLoaded: PropTypes.func,
    linkStyleParam: PropTypes.func.isRequired,
    changeSkin: PropTypes.func.isRequired,
    forceUpdateParentStyle: PropTypes.func.isRequired,
    styleDataItem: PropTypes.object.isRequired,
    shouldTranslate: PropTypes.bool,
    skinName: PropTypes.string,
    tabsUiMode: PropTypes.oneOf(['tabs', 'labels']),
    showMigrationNotification: PropTypes.func,
    currentStyle: PropTypes.object,
    currentDesign: PropTypes.object,
  },
  displayName: 'advanced-style-panel',
  mixins: [
    ReactLinkedStateMixin,
    core.mixins.editorAPIMixin,
    panelUtils.linkColorPickerMixin,
  ],

  getDefaultProps() {
    return {
      tabsUiMode: 'tabs',
    };
  },

  getSkinDefinition(skinName) {
    return (
      this.props.skinDefinition ||
      this.getEditorAPI().dsRead.theme.skins.getSkinDefinition(skinName)
    );
  },

  getSkinsGetter() {
    const { dsRead } = this.getEditorAPI();
    const [selectedComponent] = _.castArray(this.props.selectedComponent);
    return dsRead.components.responsiveLayout.get(selectedComponent)
      ? dsRead.theme.skins.getComponentResponsiveSkins
      : dsRead.theme.skins.getComponentSkins;
  },

  getComponentSkins() {
    if (this.props.getSkin) {
      return [this.props.getSkin()];
    }
    const editorAPI = this.getEditorAPI();
    return filterPageProblematicSkin(
      editorAPI.components.is.page(this.props.selectedComponent),
      this.props.compType,
      this.props.compType ? this.getSkinsGetter()(this.props.compType) : [],
    );
  },

  isConnectedToBookingController(compRef, editorAPI) {
    return _(compRef)
      .thru(editorAPI.platform.controllers.connections.get)
      .map('controllerRef')
      .map((c) => editorAPI.components.data.get(c))
      .some({ applicationId: '13d21c63-b5ec-5912-8397-c3a5ddb27a97' });
  },
  getCurrentSkinOverride(skinName) {
    if (this.props.advancedSiteSegmentBackgroundEnabled) {
      return advancedStyleConstants.skins.DEFAULT_WITH_FILL_LAYERS_SKIN_NAME;
    }
    const isHeaderBgExperimentOpen = experiment.isOpen(
      experiments.siteSegmentMediaBackground,
    );
    const isSkinMigrated =
      skinName ===
      advancedStyleConstants.skins.DEFAULT_WITH_FILL_LAYERS_SKIN_NAME;

    if (!isHeaderBgExperimentOpen && isSkinMigrated) {
      return advancedStyleConstants.skins.DEFAULT_SITE_SEGMENT_TRANSPARENT_SKIN;
    }

    return skinName;
  },
  getPanelData(skinName) {
    skinName = this.getCurrentSkinOverride(skinName);
    const editorAPI = this.getEditorAPI();
    const rawSkinDefinition = this.getSkinDefinition(skinName);
    const selectedComponent = _.head(_.castArray(this.props.selectedComponent));
    const isDeveloperModeEnabled = editorAPI.developerMode.isEnabled();
    const isConnectedToBooking = this.isConnectedToBookingController(
      selectedComponent,
      editorAPI,
    );

    const { configuration, compType } = this.props;

    let skinParamsToIgnore: Array<string> = [];

    if (configuration) {
      const ignoredSkinParams = applyDesignPanelConfiguration(
        configuration,
        rawSkinDefinition,
      );

      skinParamsToIgnore = isMultiComponentDesign(compType)
        ? // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/filter
          _.filter(
            ignoredSkinParams,
            (param) => !_.get(rawSkinDefinition, `params.${param}.label`),
          )
        : ignoredSkinParams;
    } else {
      const ignoredSkinParams =
        editorAPI.components.is.skinParamsToIgnore(selectedComponent);

      skinParamsToIgnore = isMultiComponentDesign(compType)
        ? // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/filter
          _.filter(
            ignoredSkinParams,
            (param) => !_.get(rawSkinDefinition, `params.${param}.label`),
          )
        : ignoredSkinParams;
    }

    return advancedStyleDataUtil.getPanelData(
      skinName,
      rawSkinDefinition,
      skinParamsToIgnore,
      isDeveloperModeEnabled,
      isConnectedToBooking,
      this.props.compType,
      this.props.advancedSiteSegmentBackgroundEnabled,
    );
  },

  getCurrentSkin() {
    const editorAPI = this.getEditorAPI();
    return this.props.getSkin
      ? this.props.getSkin()
      : this.props.selectedSkin ||
          getSkin(this.props.selectedComponent, editorAPI.dsRead);
  },

  shouldStartClosed(panelData, defaultStyleState) {
    const currentEditorState = this.getEditorAPI().store.getState();
    return isInInteractionMode(currentEditorState) ||
      _.has(this.props, 'shouldStartClosed')
      ? this.props.shouldStartClosed
      : // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/size
        _.size(panelData[defaultStyleState]) > 1;
  },

  getInitialState() {
    const skinName = getSkinNameByStyleId.call(this, this.props.styleId);
    if (skinName === advancedStyleConstants.multiComponents.skinName) {
      const rawSkinDefinition = this.getSkinDefinition();
      advancedStyleDataUtil.initMultiComponent(rawSkinDefinition);
    }
    const selectedCompPartTab =
      advancedStyleDataUtil.getDefaultCompPart(skinName) ||
      DEFAULT_SELECTED_COMP_PART;
    const defaultStyleState = _.isFunction(this.props.getDefaultState)
      ? this.props.getDefaultState()
      : advancedStyleDataUtil.getSkinDefaultState(skinName) ||
        DEFAULT_STYLE_STATE;
    const panelData = _.get(
      this.getPanelData(skinName),
      selectedCompPartTab,
      {},
    );
    const panelClosed = this.shouldStartClosed(panelData, defaultStyleState);
    const currentSkin = this.getCurrentSkin();
    const skins = this.getComponentSkins();
    this.componentSkins = advancedStyleDataUtil.filterSkins(skins, currentSkin);

    const selectedLabeledCategories = [];

    if (!panelClosed) {
      selectedLabeledCategories.push(
        getFirstCategory(panelData, defaultStyleState),
      );
    }

    const categories = Object.keys(panelData?.[defaultStyleState] ?? {});

    return {
      categories,
      selectedLabeledCategories,
      panelData,
      skinName,
      scrollPosition: 0,
      advancedStylePanelControlsClosed: panelClosed,
      selectedStyleState: defaultStyleState,
      selectedCompPartTab,
      selectedTabCategory: panelClosed
        ? null
        : getFirstCategory(panelData, defaultStyleState),
    };
  },

  setSelectedLabeledCategories(selectedLabeledCategories) {
    this.setState({ selectedLabeledCategories });
  },

  resetScrollPosition() {
    this.setState({ scrollPosition: -1 }, () => {
      this.setState({ scrollPosition: 0 });
    });
  },

  UNSAFE_componentWillMount() {
    const editorAPI = this.getEditorAPI();
    const previewStatesMap = editorAPI.documentMode.getComponentPreviewState();
    const selectedComponent = _.head(_.castArray(this.props.selectedComponent));
    const originalPreviewState =
      (selectedComponent && previewStatesMap[selectedComponent.id]) || null;
    // temp refactor on the way to remove editorAPI
    const skins = this.getComponentSkins();
    const currentSkin = this.getCurrentSkin();

    this.maxNumOfStates = getMaxNumOfStates(
      skins,
      currentSkin,
      this.getSkinDefinition,
    );
    this.selectedComponent = selectedComponent;
    this.setState({
      originalPreviewState,
    });
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    if (
      _.isEqual(
        _.castArray(newProps.selectedComponent),
        _.castArray(this.props.selectedComponent),
      ) &&
      newProps.selectedSkin
    ) {
      this.onSkinChange(newProps.selectedSkin);
    }

    if (this.props.advancedSiteSegmentBackgroundEnabled) {
      const hasCompStyleChanged = !_.isEqualWith(
        newProps.currentStyle?.style.properties,
        this.props.currentStyle?.style.properties,
        (val1, val2, key) => {
          if (key) {
            // we need shallow equality for primitive values because they might come as '1' and 1
            return val1 == val2;
          }
        },
      );
      const hasCompDesignChanged = !_.isEqual(
        newProps.currentDesign,
        this.props.currentDesign,
      );
      const hasCompChanged = hasCompDesignChanged || hasCompStyleChanged;
      //we need this check to prevent migration if the user cancels the change of skin
      const isPrevSkinDefaultWithSkinLayers =
        this.props.currentStyle.skin ===
        advancedStyleConstants.skins.DEFAULT_WITH_FILL_LAYERS_SKIN_NAME;

      const shouldMigrateSiteSegmentToMediaBg =
        hasCompChanged && !isPrevSkinDefaultWithSkinLayers;

      if (shouldMigrateSiteSegmentToMediaBg) {
        this.migrateSiteSegmentToMediaBg();
      }
    }
  },
  componentDidMount() {
    if (this.props.onAdvancedStylePanelLoaded) {
      this.props.onAdvancedStylePanelLoaded();
    }

    const previewState = getPreviewStateWithCustomStates.call(this);
    this.applyComponentPreviewState(previewState);
  },
  componentDidUpdate(prevProps, prevState) {
    const isStateChange =
      this.state.selectedStyleState !== prevState.selectedStyleState;
    const isCompPartChange =
      this.state.selectedCompPartTab !== prevState.selectedCompPartTab;
    if (!this.shouldDisplayCompPartTab(this.state.selectedCompPartTab)) {
      const tabsToDisplay = this.getCompPartsTabs();
      const tabToSelect = tabsToDisplay?.[0];
      if (
        Boolean(tabToSelect) &&
        this.state.selectedCompPartTab !== tabToSelect
      ) {
        this.selectCompPartTab(tabToSelect);
      }
    }

    if (isStateChange || isCompPartChange) {
      this.getEditorAPI().bi.event(
        coreBi.events.ADVANCED_STYLE_PANEL.STATE_CHANGED,
        {
          component_type: this.props.compType,
          mouse_state: this.state.selectedStyleState,
        },
      );

      const nextPreviewState = getPreviewStateWithCustomStates.call(this);
      this.applyComponentPreviewState(nextPreviewState);
    }
  },
  applyComponentPreviewState(state) {
    const editorAPI = this.getEditorAPI();
    const applyPreviewOnComp = (selected) => {
      editorAPI.dsActions.documentMode.setComponentPreviewState(
        selected.id,
        state,
      );
      editorAPI.dsActions.waitForChangesApplied(function () {
        editorAPI.selection.setModalComponent(
          editorAPI.components.is.modal(selected) ? selected : null,
        );
      });
    };

    if (
      this.props.compType === advancedStyleConstants.multiComponents.compType
    ) {
      this.props.selectedComponent.forEach(applyPreviewOnComp);
    } else if (!_.isEmpty(this.props.multiSelectedComponents)) {
      if (this.props.multiSelectedComponents) {
        this.props.multiSelectedComponents.forEach(applyPreviewOnComp);
      }
    } else {
      const selected = this.selectedComponent;
      if (!this.selectedComponent) {
        return;
      }

      applyPreviewOnComp(selected);
      editorAPI.dsActions.waitForChangesApplied(() => {
        if (_.isFunction(this.props.onPreviewStateChange)) {
          this.props.onPreviewStateChange(state);
        }
      });
    }
  },

  componentWillUnmount() {
    this.getEditorAPI().bi.event(
      coreBi.events.ADVANCED_STYLE_PANEL.PANEL_CLOSED,
      {
        component_type: this.props.compType,
      },
    );
    if (!this.props.blockUnmountUpdate) {
      this.applyComponentPreviewState(this.state.originalPreviewState);
      if (
        this.props.shouldResetToggledOffShadow &&
        !isMultiComponentDesign(
          this.props.compType,
          this.props.isMultiComponentDesign,
        )
      ) {
        this.resetToggledOffShadow();
      }
    }
  },

  resetToggledOffShadow() {
    const styleData = _.cloneDeep(this.props.styleDataItem);

    const styleProps = styleData.style.properties;
    const keys = this.getAllShadowKeys();
    const siteSegmentBgEnabled =
      this.props.advancedSiteSegmentBackgroundEnabled;
    let updateRequired = false;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(keys, (key) => {
      if (siteSegmentBgEnabled && key.includes(SCROLLED_SUFFIX)) {
        return;
      }
      const isBoxShadowToggledOff = !util.stringUtils.isTrue(
        styleProps[`boxShadowToggleOn-${key}`],
      );
      const isDefaultShadow = styleProps[key] === DEFAULT_SHADOW;
      if (isBoxShadowToggledOff && !isDefaultShadow) {
        styleProps[key] = DEFAULT_SHADOW;
        updateRequired = true;
      }
    });
    if (updateRequired) {
      this.props.forceUpdateParentStyle(styleData);
    }
  },

  getAllShadowKeys() {
    const keys = [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(this.state.panelData, function (data) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(data.shadow, function (shadow) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(shadow.params, function (param) {
          keys.push(param.paramKey);
        });
      });
    });
    return keys;
  },

  selectRelevantCategory(newVal, cb) {
    const newState = {
      selectedStyleState: newVal,
    };

    if (!this.state.panelData?.[newVal]?.[this.state.selectedTabCategory]) {
      newState.selectedTabCategory = _.head(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/keys
        sortCategories(_.keys(this.state.panelData[newVal])),
      );
    }

    newState.advancedStylePanelControlsClosed = false;
    this.setState(newState, cb);
  },

  shouldDisplayCompPartTab(compPart) {
    const panelData = this.getPanelData(this.state.skinName);
    const panelDataForTab = _.get(panelData, compPart);
    const isTabEmpty = _.isEmpty(panelDataForTab);
    const shouldDisplayTab =
      !experiment.isOpen(experiments.hideEmptyCompPartTabs) || !isTabEmpty;
    if (isTabEmpty) {
      const eventProps = {
        skin_name: this.state.skinName,
        component_type: this.props.compType,
        component_id: _.castArray(this.props.selectedComponent)[0]?.id,
      };
      util.biLogger.report(componentPartTabEmpty(eventProps));
    }
    return shouldDisplayTab;
  },

  selectCompPartTab(newVal) {
    const panelData = this.getPanelData(this.state.skinName);
    const isUpdateTabCategoriesInStylePanelOpen = experiment.isOpen(
      experiments.updateTabCategoriesInStylePanel,
    );
    const newState = {
      selectedCompPartTab: newVal,
      panelData: _.get(panelData, newVal, {}),
      ...(isUpdateTabCategoriesInStylePanelOpen && {
        categories: sortCategories(
          Object.keys(panelData?.[newVal]?.[DEFAULT_STYLE_STATE]),
        ),
      }),
    };

    this.setState(
      newState,
      _.partial(this.selectRelevantCategory, DEFAULT_STYLE_STATE),
    );
  },
  getCompPartsTabs() {
    return _(this.state.skinName)
      .thru(advancedStyleDataUtil.getSkinCompParts)
      .filter((compPart) => this.shouldDisplayCompPartTab(compPart.value))
      .filter((compPart) =>
        compPart.experiment ? experiment.isOpen(compPart.experiment) : true,
      )
      .map((compPart) => _.pick(compPart, ['label', 'value']))
      .value();
  },
  shouldTranslate() {
    return this.props?.shouldTranslate ?? true;
  },
  linkSelectedCategory() {
    const self = this;
    return {
      value: this.state.selectedTabCategory,
      requestChange(newCategory) {
        if (self.state.selectedTabCategory !== newCategory) {
          self.setState({
            selectedTabCategory: newCategory,
            advancedStylePanelControlsClosed: false,
          });
          self
            .getEditorAPI()
            .bi.event(coreBi.events.ADVANCED_STYLE_PANEL.CATEGORY_CHANGE, {
              component_type: self.props.compType,
              tab_id: newCategory,
            });
        }
      },
    };
  },
  onCategoryChanged(newCategory) {
    if (this.state.selectedTabCategory !== newCategory) {
      this.setState({
        selectedTabCategory: newCategory,
        advancedStylePanelControlsClosed: false,
      });
      this.getEditorAPI().bi.event(
        coreBi.events.ADVANCED_STYLE_PANEL.CATEGORY_CHANGE,
        {
          component_type: this.props.compType,
          tab_id: newCategory,
        },
      );
    }

    scrollContainerToTop.call(this);
  },
  getStateRadioValues() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const states = _.map(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      _.keys(this.state.panelData),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (keyName) {
        return {
          label: getStateLabel.call(this, keyName),
          value: keyName,
        };
      }, this),
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/index-of
    return _.sortBy(states, (state) => _.indexOf(statesOrder, state.value));
  },
  getCategoriesRadioValues() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const categories = _.map(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      _.keys(this.state.panelData[this.state.selectedStyleState]),
      (categoryName) => {
        const categoryData = categoriesData[categoryName];

        return {
          value: categoryName,
          label: categoryData.translationKey,
          symbol: React.createElement(symbols.symbol, {
            name: categoryData.symbolName,
          }),
          displayOrder: categoryData.displayOrder,
        };
      },
    );

    return _.sortBy(categories, 'displayOrder');
  },
  getSelectedCategoryTranslatedText() {
    const { selectedTabCategory } = this.state;

    const categoryData = categoriesData[selectedTabCategory];
    return selectedTabCategory ? translate(categoryData.translationKey) : '';
  },
  adjustInteractionData(panelContentsData) {
    const editorAPI = this.getEditorAPI();
    if (isInInteractionMode(editorAPI.store.getState())) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      const selectedComp = _.isArray(this.props.selectedComponent)
        ? _.head(this.props.selectedComponent)
        : this.props.selectedComponent;
      if (!isShownOnlyOnVariant(editorAPI, selectedComp)) {
        panelContentsData.forEach((contentData) => {
          contentData.params = _.reject(contentData.params, { type: 'FONT' });
        });
      }
    }

    return panelContentsData;
  },
  updateSelectedLabeledCategories(
    selectedLabeledCategories: string[],
    category: string,
    isOpen: boolean,
  ) {
    const categories = new Set(selectedLabeledCategories);
    if (isOpen) {
      categories.add(category);
    } else {
      categories.delete(category);
    }
    this.setSelectedLabeledCategories([...categories]);

    this.getEditorAPI().bi.event(
      coreBi.events.ADVANCED_STYLE_PANEL.CATEGORY_CHANGE,
      {
        component_type: this.props.compType,
        component_id: _.castArray(this.props.selectedComponent)[0]?.id,
        tab_id: category,
        action: isOpen ? 'expand' : 'collapse',
      },
    );
  },

  getContentDataForLabelMode() {
    const selectedPanelData =
      this.state.panelData?.[this.state.selectedStyleState] ?? [];
    const panelContentsData = this.adjustInteractionData(
      _.flatMap(selectedPanelData, (panelData, key) => {
        const categoryData = categoriesData[key];
        return {
          sections: panelData,
          name: key,
          label: categoryData.translationKey,
          displayOrder: categoryData.displayOrder,
        };
      }),
    );

    return _.sortBy(panelContentsData, 'displayOrder');
  },

  getContentData() {
    const panelContentsData = this.adjustInteractionData(
      this.state.panelData?.[this.state.selectedStyleState]?.[
        this.state.selectedTabCategory
      ] ?? [],
    );

    return panelContentsData;
  },
  hasSkinParams() {
    return hasSkinParams(this.state.panelData);
  },
  onBackClick() {
    this.props.backButtonCallBack();
  },
  getHeight() {
    const editorAPI = this.getEditorAPI();
    const viewPortHeight = getViewPort(editorAPI.store.getState()).height;
    if (this.props.skinName === 'narrow') {
      return 'auto';
    }
    if (viewPortHeight < 500) {
      return '380px';
    }
    return '453px';
  },
  reportThumbnailSliderMoveLeft() {
    reportThumbnailSliderMove.call(this, this.getEditorAPI(), 'left');
  },
  reportThumbnailSliderMoveRight() {
    reportThumbnailSliderMove.call(this, this.getEditorAPI(), 'right');
  },
  onSkinChange(skinName, callback = _.noop) {
    if (skinName === this.state.skinName) {
      callback();
      return;
    }
    const newPanelData = _.get(
      this.getPanelData(skinName),
      this.state.selectedCompPartTab,
      {},
    );

    scrollContainerToTop.call(this);

    let styleState, category;
    let previewStateWithCustomStates;
    const previewSubMenuExperiment = experiment.isOpen('previewSubmenu');

    if (hasSkinParams(newPanelData)) {
      const currentStyleState = this.state.selectedStyleState;
      const skinDefaultStyleState =
        advancedStyleDataUtil.getSkinDefaultState(skinName);

      if (previewSubMenuExperiment) {
        previewStateWithCustomStates =
          advancedStyleDataUtil.getPreviewStateWithCustomStates(
            skinName,
            this.state.selectedCompPartTab,
            this.state.selectedStyleState,
          );
      }

      const candidateStyleState = _.has(newPanelData, currentStyleState)
        ? currentStyleState
        : '';

      styleState =
        skinDefaultStyleState || candidateStyleState || DEFAULT_STYLE_STATE;

      const currentCategory = this.state.selectedTabCategory;
      const categoryKeyPath = `${styleState}.${currentCategory}`;
      const candidateCategory = _.has(newPanelData, categoryKeyPath)
        ? currentCategory
        : '';

      category =
        candidateCategory || getFirstCategory(newPanelData, styleState);
    }

    const newState = {
      skinName,
      panelData: newPanelData,
      selectedStyleState: styleState,
      selectedTabCategory: category,
    };

    if (this.state.advancedStylePanelControlsClosed) {
      newState.advancedStylePanelControlsClosed = false;
    }

    this.getEditorAPI().bi.event(
      coreBi.events.ADVANCED_STYLE_PANEL.SKIN_CHANGE,
      {
        skin_id: skinName,
        component_id: _.castArray(this.props.selectedComponent)[0]?.id,
        component_type: this.props.compType,
      },
    );

    this.props.changeSkin(skinName);
    this.setState(newState, callback);
    this.applyComponentPreviewState(
      (previewSubMenuExperiment && previewStateWithCustomStates) || styleState,
    );
  },
  getItemsForThumbnailSlider() {
    if (experiment.isOpen('disableSkinsSelectionExp')) {
      return [];
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const items = _.map(this.componentSkins, function (skinName, index) {
      //var symbolName = advancedStyleDataUtil.getSvgNameForSkin(skinName);
      const assetName = `advancedStylePanel/iconsForSkins/${skinName}.png`;
      return {
        label: index,
        value: skinName,
        iconSrc: staticAssets?.staticAssetsMap?.[assetName],
      };
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const itemsWithSources = _.filter(items, 'iconSrc');
    return itemsWithSources.length && itemsWithSources;
  },
  getCategoryClassName() {
    return `category-${this.state.selectedTabCategory}`;
  },
  getAdditionalPropsForControl(paramData) {
    return _.merge(
      {},
      paramData.props,
      getRequiredPropsForControl.call(this, paramData.controlType),
      { paramKey: paramData.paramKey },
    );
  },
  isLastParam(params, paramIndex) {
    return params.length - 1 === paramIndex;
  },

  openColorPickerFromCustomizeDesign() {
    const skinName = getSkinNameByStyleId.call(this, this.props.styleId);
    const controlType = arguments[0];
    const openColorPickerArgs = _.toArray(arguments).slice(1);
    this.openColorPicker.apply(this, openColorPickerArgs);
    this.getEditorAPI().bi.event(
      coreBi.events.ADVANCED_STYLE_PANEL
        .COLOR_PICKER_OPENED_FROM_CUSTOMIZE_DESIGN,
      {
        skin_id: skinName,
        tab: this.state.selectedTabCategory,
        control_name: controlType,
      },
    );
  },

  migrateSiteSegmentToMediaBg() {
    const SiteSegmentMediaBackgroundApi = this.getEditorAPI().host.getAPI(
      SiteSegmentMediaBackgroundApiKey,
    );
    SiteSegmentMediaBackgroundApi.migrateSiteSegmentOnChangeIfApplicable(
      this.props.selectedComponent,
      this.props.showMigrationNotification,
    );
  },

  shouldDisplaySkinChooser() {
    if (this.props.advancedSiteSegmentBackgroundEnabled) {
      return false;
    }
    const editorAPI = this.getEditorAPI();
    const currentEditorState = editorAPI.store.getState();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    const selectedComp = _.isArray(this.props.selectedComponent)
      ? _.head(this.props.selectedComponent)
      : this.props.selectedComponent;
    if (
      isInInteractionMode(currentEditorState) &&
      !isShownOnlyOnVariant(editorAPI, selectedComp)
    ) {
      return false;
    }
    return this.getItemsForThumbnailSlider().length > 1;
  },

  getSelectedSkin() {
    if (this.props.multiSelectedComponents) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      const compStyles = _.map(
        this.props.multiSelectedComponents,
        (compRef) => {
          const style = this.getEditorAPI().components.style.get(compRef);
          return style?.style?.properties;
        },
      );

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      return _.every(compStyles, (style) => _.isEqual(style, compStyles[0]))
        ? this.state.skinName
        : undefined;
    }
    return this.state.skinName;
  },

  sendSiteSegmentBI(biValues) {
    const isHeaderOrFooter =
      this.props.compType === constants.COMP_TYPES.HEADER ||
      this.props.compType === constants.COMP_TYPES.FOOTER;

    if (!isHeaderOrFooter) return;

    util.biLogger.report(
      clickInsideDesignPanel({
        component_id:
          this.props.selectedComponent?.id ||
          this.props.selectedComponent?.[0]?.id,
        ...biValues,
        panel_name: constants.PANELS.ADVANCED_SITE_SEGMENT_DESIGN_PANEL,
        component_type: this.props.compType,
      }),
    );
  },

  getShoudShowResetScrollButton() {
    return (
      this.props.advancedSiteSegmentBackgroundEnabled &&
      this.state.selectedStyleState === 'scrolled'
    );
  },

  getResetButtonProps() {
    return {
      ..._.pick(this.props, ['updateStyle', 'currentStyle']),
      tab: this.state.selectedTabCategory,
      sendBi: this.sendSiteSegmentBI,
    };
  },

  getIsOpacityDisabled() {
    const isScrollFillTab =
      this.state.selectedTabCategory === 'fill' &&
      this.state.selectedStyleState === 'scrolled';
    const isExperimentOpen = this.props.advancedSiteSegmentBackgroundEnabled;

    if (!isScrollFillTab || !isExperimentOpen) return false;

    const isBgGradient =
      this.props?.currentDesign?.background?.colorLayers?.[0]?.fill?.type !==
      'SolidColor';
    const hasScrollBg = this.props.currentStyle.style?.properties?.['bg-scrl'];
    return isBgGradient && !hasScrollBg;
  },

  render() {
    const comPartsTabs = this.getCompPartsTabs();
    const showCompPartsOnTabs = comPartsTabs.length > 1;
    const stateOptions = this.getStateRadioValues();

    return (
      <div
        style={{
          height: this.getHeight(),
        }}
        className={`${
          this.props.skinName
            ? `advanced-style-panel-${this.props.skinName}`
            : ''
        } advanced-style-panel flex-column`}
      >
        <div className="advanced-style-panel-header">
          {this.shouldDisplaySkinChooser()
            ? (() => {
                const itemsForThumbnailSlider =
                  this.getItemsForThumbnailSlider();
                const skinName = this.getSelectedSkin();
                return (
                  <baseUI.thumbnailSlider
                    key="advancedStylePanelThumbnailSlider"
                    onChange={this.onSkinChange}
                    value={skinName}
                    items={itemsForThumbnailSlider}
                    width={288}
                    afterMoveRight={this.reportThumbnailSliderMoveRight}
                    afterMoveLeft={this.reportThumbnailSliderMoveLeft}
                  />
                );
              })()
            : null}
          <div>
            {showCompPartsOnTabs ? (
              <HorizontalTabs
                key="advancedStyleSubCompsTabs"
                value={this.state.selectedCompPartTab}
                onChange={this.selectCompPartTab}
                options={comPartsTabs}
                shouldTranslate={this.shouldTranslate()}
                arrowed={true}
              />
            ) : null}
            {comPartsTabs.length <= 1 &&
            stateOptions.length > 1 &&
            this.maxNumOfStates > 1 &&
            this.maxNumOfStates < 4 ? (
              <HorizontalTabs
                key="advancedStyleHorizontalTabs"
                value={this.state.selectedStyleState}
                onChange={this.selectRelevantCategory}
                options={stateOptions}
                shouldTranslate={true}
                arrowed={true}
              />
            ) : null}

            {(showCompPartsOnTabs || this.maxNumOfStates > 3) &&
            stateOptions.length > 0 ? (
              <Divider
                dataHook={TestIds.STATE_DROPDOWN_DIVIDER}
                long={true}
                key="divider1"
              />
            ) : null}

            {(showCompPartsOnTabs || this.maxNumOfStates > 3) &&
            stateOptions.length > 0 ? (
              <Composites.DropDown key="stateDropDown">
                <DropDown
                  dataHook={TestIds.STATE_DROPDOWN}
                  value={this.state.selectedStyleState}
                  options={stateOptions}
                  onChange={this.selectRelevantCategory}
                />
              </Composites.DropDown>
            ) : null}

            {!showCompPartsOnTabs && this.maxNumOfStates <= 3 ? (
              <Divider key="divider2" long={true} />
            ) : null}
          </div>
        </div>

        <div
          className={util.cx(
            'advanced-style-panel-body flex-column flex-child',
            {
              'as-labels': this.props.tabsUiMode === 'labels',
            },
          )}
        >
          {this.props.tabsUiMode === 'labels'
            ? this.getContentDataForLabelMode().map((category) => {
                return (
                  <baseUI.OptionalCollapseLabel
                    key={category.label}
                    categoryList={this.state.categories}
                    selectedCategories={this.state.selectedLabeledCategories}
                    label={category.label}
                    shouldTranslate={true}
                    name={category.name}
                    tabsUiSkin={this.props.tabsUiSkin}
                    updateSelectedCategories={
                      this.updateSelectedLabeledCategories
                    }
                  >
                    <div className="section">
                      {category.sections.map((section) => (
                        <React.Fragment
                          key={`${section?.label} ${section.params
                            .map((param) => param.paramKey)
                            .join(' ')}`}
                        >
                          {section.label ? (
                            <baseUI.sectionDividerLabeled
                              label={section.label}
                              key={`divider ${section.label}`}
                              shouldTranslate={this.shouldTranslate()}
                            />
                          ) : null}
                          {section.params.map((param) => (
                            <div key={param.paramKey}>
                              <UiControl
                                useMouseEvent={this.props.useMouseEvent}
                                type={param.controlType}
                                valueLink={this.props.linkStyleParam(
                                  param.paramKey,
                                  param.type,
                                  param.displayMode,
                                  category.name,
                                )}
                                forceUpdateParentStyle={
                                  this.props.forceUpdateParentStyle
                                }
                                styleDataItem={this.state.styleDataItem}
                                paramKey={param.paramKey}
                                styleId={this.props.styleId}
                                themeAPI={this.getEditorAPI().theme}
                                hideSaveThemeButton={
                                  this.props.hideSaveThemeButton
                                }
                                propsToPass={this.getAdditionalPropsForControl(
                                  param,
                                )}
                              />
                            </div>
                          ))}
                        </React.Fragment>
                      ))}
                    </div>
                  </baseUI.OptionalCollapseLabel>
                );
              })
            : null}
          {this.hasSkinParams() ? (
            <div key="paramEditBox" className="content-container flex-child">
              {showCompPartsOnTabs || this.maxNumOfStates > 3 ? (
                <Divider
                  key="shadowedDivider"
                  long={true}
                  className="shadow-divider"
                />
              ) : null}
              {this.props.tabsUiMode === 'tabs' ? (
                <VerticalTabsCustom
                  value={this.state.selectedTabCategory}
                  closed={this.state.advancedStylePanelControlsClosed}
                  options={this.getCategoriesRadioValues()}
                  onChange={this.onCategoryChanged}
                />
              ) : null}
              {!this.state.advancedStylePanelControlsClosed ? (
                <div
                  key="content"
                  className={util.cx('content', this.getCategoryClassName())}
                >
                  <CustomScroll
                    heightRelativeToParent="100%"
                    scrollTo={this.state.scrollPosition}
                  >
                    <div className="scrolled-content">
                      {this.props.tabsUiMode === 'tabs' ? (
                        <div className={'tab-text-composite'}>
                          <Text
                            skin="secondary"
                            size="medium"
                            weight="bold"
                            shouldTranslate={false}
                          >
                            {this.getSelectedCategoryTranslatedText()}
                          </Text>
                        </div>
                      ) : null}
                      {this.props.tabsUiMode === 'tabs'
                        ? this.getContentData().map((section) => (
                            <div key={section.label} className="section">
                              {section.label ? (
                                <baseUI.sectionDividerLabeled
                                  label={section.label}
                                  key="divider"
                                  shouldTranslate={this.shouldTranslate()}
                                />
                              ) : null}
                              {section.params.map((param, paramIndex) => {
                                return (
                                  <div key={param.paramKey}>
                                    <UiControl
                                      useMouseEvent={this.props.useMouseEvent}
                                      type={param.controlType}
                                      valueLink={this.props.linkStyleParam(
                                        param.paramKey,
                                        param.type,
                                        param.displayMode,
                                        this.state.selectedCategory,
                                      )}
                                      forceUpdateParentStyle={
                                        this.props.forceUpdateParentStyle
                                      }
                                      styleDataItem={this.state.styleDataItem}
                                      paramKey={param.paramKey}
                                      styleId={this.props.styleId}
                                      themeAPI={this.getEditorAPI().theme}
                                      propsToPass={this.getAdditionalPropsForControl(
                                        param,
                                      )}
                                    />
                                    {!this.isLastParam(
                                      section.params,
                                      paramIndex,
                                    ) ? (
                                      <Divider long={false} key="divider" />
                                    ) : null}
                                    {this.getShoudShowResetScrollButton() && (
                                      <ScrollResetButton
                                        {...this.getResetButtonProps()}
                                      />
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ))
                        : null}
                    </div>
                  </CustomScroll>
                </div>
              ) : null}
            </div>
          ) : null}
          {!this.hasSkinParams() ? (
            <div key="nothingToEdit" className="content-container">
              <div className="nothing-to-edit">
                <symbols.symbol name="nothingToEditASP" />
                <p className="no-options">
                  {translate('CustomDesign_TabLabel_NoDesingOptions')}
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  },
  onLoaded: _.noop,
});

const Wrapped = higherOrderComponents.linkStyle(AdvancedStylePanel);
Wrapped.pure = AdvancedStylePanel;

export default Wrapped;

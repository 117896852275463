import * as stateManagement from '#packages/stateManagement';
import { biLogger, http } from '#packages/util';
import { BasePublicApi } from '#packages/apilib';

import type { Scope } from './scope';
import { SITE_READINESS_URL } from './consts';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { siteReadinessPanelClicksInsideThePanel } from '@wix/bi-logger-editor/v2';

const domainSelectors = stateManagement.domain.selectors;
const CHECK_READINESS_COUNTER = 'checkReadinessCounter';

export const getSiteState = ({ editorAPI, siteApi }: Scope) => {
  const isDomainConnected = domainSelectors.isDomainConnected(editorAPI.dsRead);

  return {
    isDomainConnected,
    isSitePremium: siteApi.isPremium(),
  };
};

export const updatePopupShownCounter = (scope: Scope) => {
  const counter = getPopupShownCounter(scope);

  scope.editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      CHECK_READINESS_COUNTER,
      counter + 1,
    ),
  );
};

export const getPopupShownCounter = ({ editorAPI }: Scope) => {
  const counter =
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      CHECK_READINESS_COUNTER,
    )(editorAPI.store.getState());

  return counter || 0;
};

export const getIsSiteReady = async ({
  editorAPI,
  siteApi,
}: Scope): Promise<boolean | undefined> => {
  const metaSiteId = siteApi.getMetaSiteId();
  const url = `${SITE_READINESS_URL}?metaSiteId=${metaSiteId}`;

  try {
    const data = await http.fetchJson(url, {
      headers: new Headers({
        Authorization:
          editorAPI.dsRead.platform.getAppDataByApplicationId('-666')?.instance,
      }),
      method: 'GET',
    });

    if (data?.isSiteReady) {
      return data.isSiteReady;
    }
  } catch (err) {
    ErrorReporter.captureException(err);
  }
};

export const setInitialBiData = (scope: Scope, origin: string) => {
  scope.store.setCheckReadinessPanelBiData({
    panelOpenOrigin: origin,
  });
};

export const getOrigin = (scope: Scope) => {
  return scope.store.getCheckReadinessPanelBiData().panelOpenOrigin;
};

export const reportClickInsidePanel = (scope: Scope, action_name: string) => {
  biLogger.report(
    siteReadinessPanelClicksInsideThePanel({
      origin: getOrigin(scope),
      action_name,
    }),
  );
};

export class SiteReadinessApi extends BasePublicApi<Scope> {
  getSiteState = this.bindScope(getSiteState);
  updatePopupShownCounter = this.bindScope(updatePopupShownCounter);
  getPopupShownCounter = this.bindScope(getPopupShownCounter);
  getIsSiteReady = this.bindScope(getIsSiteReady);
  setInitialBiData = this.bindScope(setInitialBiData);
  reportClickInsidePanel = this.bindScope(reportClickInsidePanel);
  getOrigin = this.bindScope(getOrigin);
}

// @ts-nocheck
import _ from 'lodash';
import * as styles from '#packages/styles';

const advancedStyleConstants =
  styles.advancedStyle.advancedStyleConstants.multiComponents;
const NON_NUMBERED_CONTROLS = ['BORDER_RADIUS'];

export const PARAM_SEPERATOR = '__';

export default {
  groupHasAlpha(group) {
    return group.type.includes('_ALPHA');
  },
  groupHasShadow(group) {
    return group.type.includes('_SHADOW');
  },
  getPrefixAndGroup(key) {
    const splitKey = key.split('-');
    let prefix = '';
    if (splitKey.length > 1) {
      prefix = `${splitKey[0]}-`;
    }
    const groupKey = splitKey.pop();
    return { groupKey, prefix };
  },
  maxInFrequency(obj) {
    return Object.keys(obj).reduce((a, b) => (obj[a] > obj[b] ? a : b));
  },
  getPrefixType(prefix) {
    return /(.+)-/.exec(prefix)[1].toUpperCase();
  },
  getParamsValuesFrequency(
    group,
    prefix,
    styleByComp,
    skinDefByComp,
    designMapping,
  ) {
    const getParamsValues = (compId, mapping) => {
      const groupMapping = mapping[group];
      return groupMapping.reduce((acc, p) => {
        const param = `${prefix}${p}`;
        const paramType = prefix
          ? this.getPrefixType(prefix)
          : skinDefByComp[compId][param]?.type;

        const paramValueFromStyle = styleByComp[compId][param];
        const paramDefaultValue =
          skinDefByComp[compId][param]?.defaultValue ||
          _.get(advancedStyleConstants.PARAM_TYPE_DEFAULT_VALUE, paramType);
        acc = acc.concat(paramValueFromStyle || paramDefaultValue);
        return acc;
      }, []);
    };
    return Object.keys(designMapping).reduce((pacc, compId) => {
      const mapping = designMapping[compId];
      const compParamsValues = getParamsValues(compId, mapping);
      compParamsValues.forEach((val) => {
        pacc[val] = pacc[val] + 1 || 1;
      });
      return pacc;
    }, {});
  },
  transformToNumberIfNecessary(groupMapping, group, value) {
    const controlType = groupMapping.groups[group].type;
    return NON_NUMBERED_CONTROLS.includes(controlType) || isNaN(value)
      ? value
      : Number(value);
  },
  castParamToNumberIfNeeded(group, value) {
    return NON_NUMBERED_CONTROLS.includes(group?.type) || isNaN(value)
      ? value
      : Number(value);
  },
  adapterTransformation(mappings, compStyle) {
    const changeCompStyle = ({
      param,
      compStyle,
      mappingData: { val, overrideValue, source, prefix },
    }) => {
      const prefixedParam = `${prefix}${param}`;

      if (overrideValue && compStyle?.style?.properties?.[prefixedParam]) {
        _.set(
          compStyle,
          ['style', 'propertiesOverride', prefixedParam],
          overrideValue,
        );
      } else {
        _.set(compStyle, ['style', 'properties', prefixedParam], val);
      }

      if (source) {
        _.set(compStyle, ['style', 'propertiesSource', prefixedParam], source);
      }
    };

    let changedStyle = false;
    if (!mappings || !mappings.length) {
      return;
    }

    const newCompStyle = _.cloneDeep(compStyle);
    mappings.forEach(({ mapping, ...mappingData }) => {
      if (mapping) {
        mapping.forEach((param) =>
          changeCompStyle({
            param,
            compStyle: newCompStyle,
            mappingData,
          }),
        );
        changedStyle = true;
      }
    });

    if (changedStyle) {
      return newCompStyle;
    }
  },
  getFrequencyOfParamValue(
    paramKey,
    prefix,
    styleByComp,
    skinDefByComp,
    compIdsByRole,
    compsStylesOverrides,
  ) {
    const getParamValue = (compId, key) => {
      const keyOverride = _.head(
        _.get(compsStylesOverrides, `${compId}.${key}`, [key]),
      );
      const paramPath = `${compId}.${prefix}${keyOverride}`;
      const paramValueFromStyle = _.get(styleByComp, paramPath);
      // This makes sure that opacity values of 0 are returned correctly from style, and not from default value
      if (paramValueFromStyle !== undefined) {
        return paramValueFromStyle;
      }

      const paramType = prefix
        ? this.getPrefixType(prefix)
        : _.get(skinDefByComp, `${paramPath}.type`);
      return (
        _.get(skinDefByComp, `${paramPath}.defaultValue`) ||
        _.get(advancedStyleConstants.PARAM_TYPE_DEFAULT_VALUE, paramType)
      );
    };

    const [styleParam, role] = paramKey.split(PARAM_SEPERATOR);
    const compIds = role ? compIdsByRole[role] : Object.keys(styleByComp);

    return compIds.reduce((paramsFrequencies, compId) => {
      const compParamValue = getParamValue(compId, styleParam);
      if (compParamValue !== undefined) {
        paramsFrequencies[compParamValue] =
          paramsFrequencies[compParamValue] + 1 || 1;
      }
      return paramsFrequencies;
    }, {});
  },
};

import React from 'react';

import { TextButton } from '@wix/wix-base-ui';
import { ChevronRight } from '@wix/wix-ui-icons-common/classic-editor';

import { translate } from '#packages/i18n';
import { cx } from '#packages/util';

import DropDown from '../dropDown/dropDown';

import styles from './dropPanelListNested.scss';
import type { DropPanelItem } from './types';

interface Props {
  isOpen: boolean;
  items: DropPanelItem[];
  title: string;
  onHover: () => void;
  onItemClick?: () => void;
  automationId?: string;
}
export const NestedDropPanelList = (props: Props) => {
  return (
    <DropDown
      position="left"
      panelLeftShift={15}
      isOpen={props.isOpen}
      contentStyleOverrides={{ padding: '4px 0' }}
      withPortal={false}
      panelContent={
        <ul className={styles.nestedDropPanelList}>
          {props.items.map((item, itemIndex) => (
            <li
              key={`${itemIndex}-item-drop-panel-list`}
              data-aid={item.automationId}
              onClick={() => {
                props?.onItemClick();
                item.onClick?.();
              }}
              className={cx(styles.listItem, {
                [styles.listItemSeparator]: item.isSeparator,
              })}
            >
              {item.isSeparator ? (
                <hr key="item" className={styles.separator} />
              ) : (
                <span className={styles.listItemTitle}>
                  {item.translatedLeftTitle ?? translate(item.leftTitle)}
                </span>
              )}
            </li>
          ))}
        </ul>
      }
    >
      <li
        onMouseEnter={() => {
          props.onHover();
        }}
        className={styles.nestedListButton}
        data-aid={props.automationId}
      >
        <TextButton
          className={styles.textButton}
          skin="dark"
          size="small"
          underline="none"
          suffixIcon={<ChevronRight />}
        >
          {props.title}
        </TextButton>
      </li>
    </DropDown>
  );
};

// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import TabPageInfoMainMenu from './tabs/tabPageInfoMainMenu';
import TabLayout from './tabs/tabLayout';
import TabPermissions from './tabs/tabPermissions';
import TabSEO from './tabs/tabSEO';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  menuItem: AnyFixMe;
  selectedTab?: string;
  setActiveTab: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'pageSettingsPanelTabs';

  static propTypes = {
    menuItem: PropTypes.object.isRequired,
    selectedTab: PropTypes.string,
    setActiveTab: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div>
        <baseUI.tabs.holder
          value={this.props.selectedTab}
          onChange={(value) => {
            this.props.setActiveTab(value);
          }}
          className="top page-settings-tabs"
        >
          <baseUI.tabs.header>
            <baseUI.tabs.label htmlFor="page">
              {translate('Pages_Info_Tab_Title')}
            </baseUI.tabs.label>
            <baseUI.tabs.label htmlFor="layout">
              {translate('Pages_Layouts_Tab_Title')}
            </baseUI.tabs.label>
            <baseUI.tabs.label htmlFor="privacy">
              {translate('Pages_Permissions_Tab_Title')}
            </baseUI.tabs.label>
            <baseUI.tabs.label htmlFor="seo">
              {translate('Pages_SEO_Tab_Title')}
            </baseUI.tabs.label>
          </baseUI.tabs.header>

          <baseUI.tabs.content className="page-settings-panel-content">
            <baseUI.tabs.tab name="page">
              <TabPageInfoMainMenu menuItem={this.props.menuItem} />
            </baseUI.tabs.tab>

            <baseUI.tabs.tab name="layout">
              <TabLayout menuItem={this.props.menuItem} />
            </baseUI.tabs.tab>

            <baseUI.tabs.tab name="privacy">
              <TabPermissions pageData={this.props.menuItem.pageData} />
            </baseUI.tabs.tab>

            <baseUI.tabs.tab name="seo" className="seo">
              <TabSEO pageData={this.props.menuItem.pageData} />
            </baseUI.tabs.tab>
          </baseUI.tabs.content>
        </baseUI.tabs.holder>
      </div>
    );
  }
}

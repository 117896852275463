import _ from 'lodash';
import constants from '#packages/constants';
import createCopyPasteBILogger from '../copyPasteWrapper/createCopyPasteBILogger';

const enum LocalStorageStatus {
  Available = 'Available',
  QuotaExceeded = 'QuotaExceeded',
  NotAvailable = 'NotAvailable',
}

interface IClipboardStorage {
  get: () => unknown;
  set: (value: any) => void;
  remove: () => void;
}

/**
 *
 * Based on https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 * @param storage - localStorage or SessionStorage
 * @param error - error thrown by storage.set
 * @returns {boolean}
 */
function isStorageQuotaExceededError(
  storage: AnyFixMe,
  error: AnyFixMe,
): boolean {
  return (
    // everything except Firefox
    (error.code === 22 ||
      // Firefox
      error.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      error.name === 'QuotaExceededError' || // Firefox
      error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
    // acknowledge QuotaExceededError only if there's something already stored
    storage &&
    storage.length !== 0
  );
}

/**
 *
 * This parameter takes 10MB and breaks clipboard for many users.
 * This is a fallback mechanism that was activated due to a bug only manifested in DS-over-bolt.
 * While this mechanism is not needed anymore, we can proactively remove key from storage.
 * Any questions - to @mykytash or @oferb
 *
 * @param storage - localStorage
 */
function clearWixCodePendingMonitoring(storage: AnyFixMe) {
  storage.removeItem('wixCodePendingMonitoring');
}

/**
 * Based on https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
 */
function getLocalStorageStatus(): LocalStorageStatus {
  let storage;

  // Q: Why so complex testing?
  // A: https://gist.github.com/paulirish/5558557
  try {
    storage = window.localStorage;
    clearWixCodePendingMonitoring(storage);

    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return LocalStorageStatus.Available;
  } catch (e) {
    if (isStorageQuotaExceededError(storage, e)) {
      return LocalStorageStatus.QuotaExceeded;
    }

    return LocalStorageStatus.NotAvailable;
  }
}

function createInstance(
  editorAPI: AnyFixMe,
  biApi: AnyFixMe,
  options = { forceInMemory: false },
): IClipboardStorage {
  const copyPasteBILogger = createCopyPasteBILogger(biApi);
  const CLIPBOARD_SCOPE = constants.CLIPBOARD_SCOPE.GENERAL;

  function getLocalStorage(): IClipboardStorage {
    const { localStorage } = window;

    function getDataFromStorage() {
      const data = localStorage.getItem(constants.LOCAL_STORAGE_TYPE.CLIPBOARD);
      return data ? JSON.parse(data) : {};
    }

    function updateValue(value: AnyFixMe, scope = CLIPBOARD_SCOPE) {
      const store = getDataFromStorage() || {};

      localStorage.setItem(
        constants.LOCAL_STORAGE_TYPE.CLIPBOARD,
        JSON.stringify(Object.assign({}, store, { [scope]: value })),
      );
    }

    return {
      get: () => {
        const store = getDataFromStorage();
        return _.isObject(store)
          ? store[CLIPBOARD_SCOPE as keyof typeof store]
          : undefined;
      },
      set: (data) => {
        updateValue(data);
      },
      remove: () => {
        updateValue(undefined);
      },
    };
  }

  function getInMemoryStorage(): IClipboardStorage {
    // TODO: do we really need deep clone here?
    const getDataFromStorage = () =>
      _.cloneDeep(editorAPI.store.getState().clipboard);

    const updateValue = (value: AnyFixMe, scope = CLIPBOARD_SCOPE) => {
      editorAPI.updateState({
        clipboard: Object.assign({}, getDataFromStorage(), {
          [scope]: value,
        }),
      });
    };

    return {
      get: () => {
        const clipboard = getDataFromStorage();
        return _.isObject(clipboard)
          ? clipboard[CLIPBOARD_SCOPE as keyof typeof clipboard]
          : undefined;
      },
      set: (data) => {
        updateValue(data);
      },
      remove: () => {
        updateValue(undefined);
      },
    };
  }

  function getLocalStorageWithFallback(): IClipboardStorage {
    const ClipboardStorageType = {
      LocalStorage: 'LocalStorage',
      InMemoryStorage: 'InMemoryStorage',
    };

    let clipboardStorageType = ClipboardStorageType.LocalStorage;
    let clipboardStorage = getLocalStorage();

    function get() {
      try {
        return clipboardStorage.get();
      } catch (e) {
        copyPasteBILogger.clipboardError({
          action: 'get',
        });
      }
    }

    function set(data: AnyFixMe) {
      if (clipboardStorageType === ClipboardStorageType.InMemoryStorage) {
        clipboardStorage.set(data);
        return;
      }

      try {
        clipboardStorage.set(data);
      } catch (e) {
        copyPasteBILogger.clipboardError({
          action: 'set',
          isQuotaExceededError: isStorageQuotaExceededError(
            clipboardStorage,
            e,
          ),
        });

        // NOTE: if some error (for example `QUOTA_EXCEEDED_ERR`), we should switch to in-memory storage

        clipboardStorageType = ClipboardStorageType.InMemoryStorage;
        clipboardStorage = getInMemoryStorage();

        clipboardStorage.set(data);

        // TODO: add monitoring (bi, sentry?)
      }
    }

    function remove() {
      set(undefined);
    }

    return {
      get,
      set,
      remove,
    };
  }

  if (options.forceInMemory) {
    return getInMemoryStorage();
  }

  const localStorageStatus = getLocalStorageStatus();

  if (localStorageStatus !== LocalStorageStatus.Available) {
    copyPasteBILogger.clipboardError({
      action: 'init',
      isQuotaExceededError:
        localStorageStatus === LocalStorageStatus.QuotaExceeded,
    });

    return getInMemoryStorage();
  }

  return getLocalStorageWithFallback();
}

export { createInstance };

import React, { useCallback, useMemo, useState } from 'react';
import { Tooltip, Text, Badge } from '@wix/wix-base-ui';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import type { LanguageDefinition } from '@wix/document-services-types';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';

interface LocaleRowProps {
  locale: LanguageDefinition & { regionCode: string | undefined }; // remove the regionCode part after updating LanguageDefinition
  currentLocaleId: string;
  primaryLocaleId: string;
  onSelect(): void;
  index: number;
  flagUrl: string;
}

export const LocaleRow: React.FC<LocaleRowProps> = ({
  locale,
  currentLocaleId,
  primaryLocaleId,
  onSelect,
  index,
  flagUrl,
}) => {
  const { LANGUAGE_STATUSES } = constants.MULTILINGUAL;
  const [isRowHovered, setIsRowHovered] = useState(false);

  const handleMouseEnter = useCallback(() => setIsRowHovered(true), []);
  const handleMouseLeave = useCallback(() => setIsRowHovered(false), []);

  const localeName = useMemo(
    () =>
      locale.regionCode
        ? `${translate(
            `locale-dataset.languages.${locale.languageCode}`,
          )} (${locale.regionCode.toUpperCase()})`
        : translate(`locale-dataset.languages.${locale.languageCode}`),
    [locale.languageCode, locale.regionCode],
  );

  const regionName = useMemo(() => {
    if (!locale.regionCode) {
      return null;
    }

    return stateManagement.multilingual.services.utils.getRegionLocalizedNameByRegionCode(
      locale.regionCode,
    );
  }, [locale.regionCode]);

  const isVisible = useMemo(
    () => locale.status === LANGUAGE_STATUSES.ACTIVE,
    [LANGUAGE_STATUSES.ACTIVE, locale.status],
  );

  const isSelected = useMemo(
    () => locale.languageCode === currentLocaleId,
    [currentLocaleId, locale.languageCode],
  );

  const isPrimaryLocale = useMemo(
    () => locale.languageCode === primaryLocaleId,
    [locale.languageCode, primaryLocaleId],
  );

  const primaryLocaleBadge = (
    <Badge
      skin="standard"
      value={translate('SiteLanguages_Panel_Label_MainLanguage')}
    />
  );

  const visibilityText = (
    <Text size={'tiny'} light={isSelected && !isRowHovered}>
      {translate(
        isVisible
          ? 'SiteLanguages_Panel_Label_Visible'
          : 'SiteLanguages_Panel_Label_Hidden',
      )}
    </Text>
  );

  return (
    <div
      onClick={onSelect}
      className={util.cx('localeRowRoot', {
        localeRowSelected: isSelected,
      })}
      data-aid={`my-site-languages-panel-option-${index}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Tooltip
        content={translate('Multilingual_Mode_LangMenu_Activate_Tooltip', {
          Lang_Name: localeName,
        })}
        shouldTranslate={false}
        disabled={isVisible || isPrimaryLocale}
      >
        <div className="leftSideContainer">
          <div className="flagContainer">
            <img src={flagUrl} alt={locale.languageCode} />
          </div>
          <div className="leftSideTextsContainer">
            <Text
              size="small"
              weight="thin"
              data-aid={`my-site-languages-panel-option-language-${index}`}
              light={isSelected && !isRowHovered}
            >
              {localeName}
            </Text>
            <Text
              size="tiny"
              weight="thin"
              skin="secondary"
              light={isSelected && !isRowHovered}
            >
              {regionName}
            </Text>
          </div>
        </div>
      </Tooltip>

      <div className="rightSideContainer">
        {isPrimaryLocale ? primaryLocaleBadge : visibilityText}
      </div>
    </div>
  );
};

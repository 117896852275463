// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '#packages/core';
import * as helpIds from '#packages/helpIds';
import * as symbols from '@wix/santa-editor-symbols';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import {
  ActionWithSymbol,
  Composites,
  CustomScroll,
  Divider,
  InfoIcon,
  RadioButtons,
  RichText,
  SectionDivider,
  TextLabel,
} from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';
import FocusPanelFrame from '../frames/focusPanelFrame/focusPanelFrame';
// eslint-disable-next-line @wix/santa-editor/scoped-imports-relative
import OpenSignupSettingsDashboardButton from '../../../packages/pageSettingsPanel/panels/settings/desktop/OpenSignupSettingsDashboardButton';
// eslint-disable-next-line @wix/santa-editor/scoped-imports-relative
import { createJoinCommunityStatusApi } from '../../../packages/pageSettingsPanel/utils/joinCommunityStatusApi';
// eslint-disable-next-line @wix/santa-editor/scoped-imports-relative
import { LOGIN_FIRST_OPTIONS } from '../../../packages/pageSettingsPanel/utils/customSignup';
// eslint-disable-next-line @wix/santa-editor/scoped-imports-relative
import { createSocialLoginStatusApi } from '../../../packages/pageSettingsPanel/utils/socialLoginStatusApi';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

function template() {
  const TAB_VALUES = this.getTabValues();

  return (
    <FocusPanelFrame
      panelName={this.props.panelName}
      title={translate('Pages_Member_Signup_Settings_Title')}
      ref="frame"
      helpId={this.getTabHelpId()}
      className="site-members-settings-new-panel"
    >
      {this.isCustomSignupEnabled() ? (
        <div key="isCustomSignupEnabled">
          <Composites.Banner>
            <InfoIcon
              shouldTranslate={true}
              text="Pages_Member_Signup_Settings_Custom_Signup_Banner_Info_Tooltip_Content"
            />
            <SectionDivider>
              <TextLabel
                value="Pages_Member_Signup_Settings_Custom_Signup_Banner_Label"
                shouldTranslate={true}
              />
            </SectionDivider>
          </Composites.Banner>
        </div>
      ) : null}

      <div className="member-settings-panel-content">
        <baseUI.horizontalTabs
          shouldTranslate={true}
          options={this.getTabs()}
          value={this.state.selectedTab}
          onChange={this.selectTab}
          className="tabbed"
        />

        <div className={this.getTabContentClass()}>
          <Composites.RichText>
            <RichText className="light">
              {translate('Pages_Member_Signup_Settings_Description')}
            </RichText>
          </Composites.RichText>

          {this.state.selectedTab === TAB_VALUES.GENERAL ? (
            <div key="smSettingsTab" className="general-settings settings">
              <CustomScroll heightRelativeToParent="100%">
                <Divider />
                <Composites.RadioButtonsLabeled>
                  <TextLabel value="Pages_Member_Signup_Display_Settings_Dropdown_Label" />
                  <RadioButtons
                    value={this.state.loginDialogFirst.toString()}
                    options={this.getLoginFirstOptions()}
                    onChange={_.partial(
                      this.onRadioButtonsClick,
                      'loginDialogFirst',
                    )}
                  />
                </Composites.RadioButtonsLabeled>
                <>
                  <Divider />
                  <OpenSignupSettingsDashboardButton
                    onClick={this.openDashboard}
                  />
                </>
                {this.isDeveloperModeEnabled() ? (
                  <div key="customSignupSection">
                    <Divider />

                    <Composites.RichTextLabeled>
                      <TextLabel
                        disabled={true}
                        value="Pages_Member_Signup_Settings_Custom_Signup_Label"
                        shouldTranslate={true}
                      />
                      <RichText>
                        <a onClick={this.openCustomSignupPanel}>
                          {this.getCustomSignupLinkText()}
                        </a>
                      </RichText>
                    </Composites.RichTextLabeled>
                  </div>
                ) : null}
              </CustomScroll>
            </div>
          ) : null}

          {this.state.selectedTab === TAB_VALUES.SOCIAL ? (
            <div
              key="smSocialLoginSettingsSection"
              className="social-login-settings settings"
            >
              <CustomScroll heightRelativeToParent="100%">
                <Divider long={true} />
                <SectionDivider>
                  {translate('Pages_Member_Signup_Settings_Social_Label')}
                </SectionDivider>
                <Divider long={true} />

                <div className="options">
                  <section className="social-option">
                    <baseUI.symbol
                      name="mba_social_panel_google_plus_new"
                      className="icon google"
                    />
                    <baseUI.toggle
                      name="switch"
                      valueLink={this.linkState('socialLoginGoogleEnabled')}
                      label="Member_Login_Settings_Social_Google"
                    />
                  </section>

                  <Divider long={true} />

                  <section className="social-option">
                    <baseUI.symbol
                      name="mba_social_panel_facebook"
                      className="icon facebook"
                    />
                    <baseUI.toggle
                      name="switch"
                      valueLink={this.linkState('socialLoginFacebookEnabled')}
                      label="Member_Login_Settings_Social_Facebook"
                    />
                  </section>
                </div>

                {this.isSocialAppInstalled() ? (
                  <div key="shouldDisplayCommunityToggle">
                    <Divider long={true} />

                    <Composites.SectionDividerWithInfoIcon>
                      <InfoIcon
                        shouldTranslate={true}
                        text="Member_Login_Settings_Community_Settings_Info_Tooltip"
                      />
                      <SectionDivider>
                        {translate(
                          'Member_Login_Settings_Settings_Community_Label',
                        )}
                      </SectionDivider>
                    </Composites.SectionDividerWithInfoIcon>

                    <Divider long={true} />

                    <div className="options">
                      <section className="social-option">
                        <baseUI.symbol
                          name="communityIcon"
                          className="icon community"
                        />
                        <baseUI.toggle
                          name="switch"
                          valueLink={this.linkState('joinCommunityEnabled')}
                          label="Member_Login_Settings_Community_Join"
                        />
                      </section>
                    </div>
                  </div>
                ) : null}
              </CustomScroll>
            </div>
          ) : null}

          {this.state.selectedTab === TAB_VALUES.TERMS_AND_CONDITIONS
            ? (() => {
                const ARTICLES = this.getArticles();

                return (
                  <div
                    key="smSignupPoliciesSection"
                    className="signup-policies-settings settings"
                  >
                    <CustomScroll heightRelativeToParent="100%">
                      <div className="policies-segment-header">
                        <Divider long={true} />
                        <Composites.SectionDividerWithInfoIcon>
                          <InfoIcon
                            shouldTranslate={true}
                            linkText="Pages_Member_Signup_Settings_Terms_Of_Use_Link_Label_"
                            onLinkClick={() =>
                              this.openExamplePolicy(ARTICLES.TERMS_OF_USE)
                            }
                            text="Pages_Member_Signup_Settings_Terms_Of_Use_Info_Tooltip"
                          />
                          <SectionDivider>
                            {translate(
                              'Pages_Member_Signup_Settings_Terms_Of_Use_Divider_Label',
                            )}
                          </SectionDivider>
                        </Composites.SectionDividerWithInfoIcon>
                        <Divider long={true} />
                      </div>

                      <div className="options">
                        <section className="option">
                          <baseUI.toggle
                            name="switch"
                            value={this.state.termsOfUseLinkVisible}
                            onChange={_.partial(
                              this.toggleEditPoliciesVisibility,
                              'termsOfUseLinkVisible',
                            )}
                            label="Pages_Member_Signup_Settings_Terms_Of_Use_Toggle_Label"
                          />
                        </section>
                      </div>
                      {this.state.termsOfUseLinkVisible ? (
                        <div
                          key="ifTermsOfUseVisible"
                          className="policy-link-area"
                        >
                          <div className="policy-toggle-divider">
                            <Divider />
                          </div>
                          <Composites.ActionWithSymbolLabeled>
                            <TextLabel
                              value="Pages_Member_Signup_Settings_Policies_Link_Header"
                              shouldTranslate={true}
                            />
                            <ActionWithSymbol
                              action={_.partial(
                                this.openLinkPanelFor,
                                'termsOfUseLink',
                              )}
                            >
                              <TextLabel
                                value={this.getLabelForPolicyPage(
                                  'termsOfUseLink',
                                )}
                                shouldTranslate={false}
                              />
                            </ActionWithSymbol>
                          </Composites.ActionWithSymbolLabeled>
                        </div>
                      ) : null}

                      <div className="policies-segment-header">
                        <Divider long={true} />
                        <Composites.SectionDividerWithInfoIcon>
                          <InfoIcon
                            shouldTranslate={true}
                            linkText="Pages_Member_Signup_Settings_Terms_Of_Use_Link_Label_"
                            onLinkClick={() =>
                              this.openExamplePolicy(ARTICLES.PRIVACY_POLICY)
                            }
                            text="Pages_Member_Signup_Settings_Privacy_Policy_Tooltip"
                          />
                          <SectionDivider>
                            {translate(
                              'Pages_Member_Signup_Settings_Privacy_Policy_Divider_Label',
                            )}
                          </SectionDivider>
                        </Composites.SectionDividerWithInfoIcon>
                        <Divider long={true} />
                      </div>

                      <div className="options">
                        <section className="option">
                          <baseUI.toggle
                            name="switch"
                            value={this.state.privacyPolicyLinkVisible}
                            onChange={_.partial(
                              this.toggleEditPoliciesVisibility,
                              'privacyPolicyLinkVisible',
                            )}
                            label="Pages_Member_Signup_Settings_Privacy_Policy_Toggle_Label"
                          />
                        </section>
                      </div>

                      {this.state.privacyPolicyLinkVisible ? (
                        <div
                          key="ifPrivacyPolicyVisible"
                          className="policy-link-area"
                        >
                          <div className="policy-toggle-divider">
                            <Divider />
                          </div>
                          <Composites.ActionWithSymbolLabeled>
                            <TextLabel
                              value="Pages_Member_Signup_Settings_Policies_Link_Header"
                              shouldTranslate={true}
                            />
                            <ActionWithSymbol
                              action={_.partial(
                                this.openLinkPanelFor,
                                'privacyPolicyLink',
                              )}
                            >
                              <TextLabel
                                value={this.getLabelForPolicyPage(
                                  'privacyPolicyLink',
                                )}
                                shouldTranslate={false}
                              />
                            </ActionWithSymbol>
                          </Composites.ActionWithSymbolLabeled>
                        </div>
                      ) : null}

                      {this.isSocialAppInstalled() ? (
                        <div key="shouldShowCodeOfConductSection">
                          <div className="policies-segment-header">
                            <Divider long={true} />
                            <Composites.SectionDividerWithInfoIcon>
                              <SectionDivider>
                                {translate(
                                  'Pages_Member_Signup_Settings_Code_Of_Conduct_Divider_Label',
                                )}
                              </SectionDivider>
                            </Composites.SectionDividerWithInfoIcon>
                            <Divider long={true} />
                          </div>

                          <div className="options">
                            <section className="option">
                              <baseUI.toggle
                                name="switch"
                                value={this.state.codeOfConductLinkVisible}
                                onChange={_.partial(
                                  this.toggleEditPoliciesVisibility,
                                  'codeOfConductLinkVisible',
                                )}
                                label="Pages_Member_Signup_Settings_Code_Of_Conduct_Toggle_Label"
                              />
                            </section>
                          </div>

                          {this.state.codeOfConductLinkVisible ? (
                            <div
                              key="ifCodeOfConductVisible"
                              className="policy-link-area"
                            >
                              <div className="policy-toggle-divider">
                                <Divider />
                              </div>
                              <Composites.ActionWithSymbolLabeled>
                                <TextLabel
                                  value="Pages_Member_Signup_Settings_Policies_Link_Header"
                                  shouldTranslate={true}
                                />
                                <ActionWithSymbol
                                  action={_.partial(
                                    this.openLinkPanelFor,
                                    'codeOfConductLink',
                                  )}
                                >
                                  <TextLabel
                                    value={this.getLabelForPolicyPage(
                                      'codeOfConductLink',
                                    )}
                                    shouldTranslate={false}
                                  />
                                </ActionWithSymbol>
                              </Composites.ActionWithSymbolLabeled>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </CustomScroll>
                  </div>
                );
              })()
            : null}
        </div>

        <Divider long={true} />

        <footer className="actions">
          <baseUI.button
            label="Pages_Member_Signup_Settings_Done"
            onClick={this.applySettings}
            className="btn-confirm-primary btn-md done-button"
          />
        </footer>
      </div>
    </FocusPanelFrame>
  );
}

const MEMBERS_APPDEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

const SIGNUP_POLICIES = {
  TERMS_OF_USE: 'termsOfUse',
  PRIVACY: 'privacyPolicy',
  CODE_OF_CONDUCT: 'codeOfConduct',
};

const TAB_VALUES = {
  GENERAL: 'general',
  SOCIAL: 'social',
  TERMS_AND_CONDITIONS: 'terms',
};

const ARTICLES = {
  TERMS_OF_USE: helpIds.SITE_MEMBERS.TERMS_OF_USE_EXAMPLE,
  PRIVACY_POLICY: helpIds.SITE_MEMBERS.PRIVACY_POLICY_EXAMPLE,
};

function getAppAPI(editorAPI, appData, callback) {
  editorAPI.dsRead.platform
    .getAppPrivateApi(appData.appDefinitionId)
    .then(callback)
    .catch(function () {
      callback({ Error: 'Application API not found.' });
    });
}

function shouldUpdatePolicyToggle(siteMembersAPI, state, policy) {
  return (
    siteMembersAPI.isSignupPoliciesFieldEnabled(policy) !==
      state[`${policy}LinkVisible`] &&
    (state[`${policy}LinkItem`] || state[`${policy}Link`])
  );
}

function getPolicyLink(editorAPI, state, policy) {
  if (state[`${policy}Item`]) {
    return state[`${policy}Item`];
  }
  if (state[policy]) {
    return editorAPI.data.getById(state[policy].replace('#', ''));
  }
  return null;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'siteMembersSettingsPanel',

  mixins: [core.mixins.editorAPIMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated

  getInitialState() {
    const editorAPI = this.getEditorAPI();
    return {
      selectedTab: TAB_VALUES.GENERAL,
      autoApproval: editorAPI.siteMembers.isAutoApproval(),
      loginDialogFirst: editorAPI.siteMembers.isLoginDialogFirst(),
      socialLoginGoogleEnabled: true,
      socialLoginFacebookEnabled: true,
      termsOfUseLinkVisible: editorAPI.siteMembers.isSignupPoliciesFieldEnabled(
        SIGNUP_POLICIES.TERMS_OF_USE,
      ),
      privacyPolicyLinkVisible:
        editorAPI.siteMembers.isSignupPoliciesFieldEnabled(
          SIGNUP_POLICIES.PRIVACY,
        ),
      codeOfConductLinkVisible:
        editorAPI.siteMembers.isSignupPoliciesFieldEnabled(
          SIGNUP_POLICIES.CODE_OF_CONDUCT,
        ),
      termsOfUseLink: editorAPI.siteMembers.getSignupPoliciesLink(
        SIGNUP_POLICIES.TERMS_OF_USE,
      ),
      privacyPolicyLink: editorAPI.siteMembers.getSignupPoliciesLink(
        SIGNUP_POLICIES.PRIVACY,
      ),
      codeOfConductLink: editorAPI.siteMembers.getSignupPoliciesLink(
        SIGNUP_POLICIES.CODE_OF_CONDUCT,
      ),
      joinCommunityEnabled: true,
      joinCommunityRevision: undefined,
      hasSocialPages: false,
    };
  },

  UNSAFE_componentWillMount() {
    const editorAPI = this.getEditorAPI();
    const appData =
      editorAPI.dsRead.tpa.app.getDataByAppDefId(MEMBERS_APPDEF_ID);
    const joinCommunityStatusApi = createJoinCommunityStatusApi(editorAPI);
    const socialLoginStatusApi = createSocialLoginStatusApi(editorAPI);

    if (appData) {
      getAppAPI(editorAPI, appData, (api) =>
        api.hasSocialPages().then((hasSocialPages) => {
          joinCommunityStatusApi
            .getJoinCommunityStatus()
            .then((joinCommunityStatus) => {
              this.setState({
                hasSocialPages,
                joinCommunityEnabled: _.isBoolean(
                  joinCommunityStatus.joinCommunity,
                )
                  ? joinCommunityStatus.joinCommunity
                  : true,
                joinCommunityRevision: joinCommunityStatus.revision,
              });
            });
        }),
      );
    }

    socialLoginStatusApi.getSocialLoginStatus().then((socialLoginStatus) => {
      this.setState({
        socialLoginGoogleEnabled: socialLoginStatus.google,
        socialLoginFacebookEnabled: socialLoginStatus.facebook,
      });
    });
  },

  closePanel() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },

  getTabs() {
    return [
      {
        label: 'Member_Login_Settings_General_Tab_Title',
        value: TAB_VALUES.GENERAL,
      },
      {
        label: 'Member_Login_Settings_Social_Tab_Title',
        value: TAB_VALUES.SOCIAL,
      },
      {
        label: 'Member_Login_Settings_Policies_Tab_Title',
        value: TAB_VALUES.TERMS_AND_CONDITIONS,
      },
    ];
  },

  getLabelForPolicyPage(policy) {
    if (this.state[`${policy}Item`]) {
      return util.link.getLinkValueAsString(
        this.getEditorAPI(),
        this.state[`${policy}Item`],
      );
    }
    if (this.state[policy]) {
      return util.link.getLinkValueAsString(
        this.getEditorAPI(),
        this.getEditorAPI().data.getById(this.state[policy].replace('#', '')),
      );
    }

    return translate('Pages_Member_Signup_Settings_Policies_Link_Label');
  },

  openDashboard() {
    const path = '/member-permissions/signup-login-settings';
    if (isResponsiveEditor()) {
      const dashboardUrl = `${this.getEditorAPI().account.getSiteDashboardURL()}${path}`;
      window.open(dashboardUrl, '_blank').focus();
    } else {
      this.getEditorAPI().account.openSettings({
        path,
      });
    }
  },

  getTabValues() {
    return TAB_VALUES;
  },

  getArticles() {
    return ARTICLES;
  },

  selectTab(tabValue) {
    this.setState({ selectedTab: tabValue });
  },

  isSocialAppInstalled() {
    return this.state.hasSocialPages;
  },

  toggleEditPoliciesVisibility(policyType) {
    this.setState({ [policyType]: !this.state[policyType] });
  },

  linkPanelCallback(policyType, link) {
    if (_.isObject(link)) {
      this.setState({ [`${policyType}Item`]: link });
    }
    if (_.isString(link)) {
      this.setState({ [`${policyType}`]: link });
    }

    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_signup_link_to_policy,
      {
        name: policyType,
        action: 'set-link',
        type: _.isObject(link)
          ? link.type
          : this.getEditorAPI().data.getById(link.replace('#', '')),
      },
    );
  },

  async applySettings() {
    const editorAPI = this.getEditorAPI();
    const smAPI = this.getEditorAPI().siteMembers;
    const {
      autoApproval,
      loginDialogFirst,
      socialLoginGoogleEnabled,
      socialLoginFacebookEnabled,
    } = this.state;

    if (smAPI.isAutoApproval() !== autoApproval) {
      smAPI.setAutoApproval(autoApproval);
    }

    if (smAPI.isLoginDialogFirst() !== loginDialogFirst) {
      smAPI.setLoginDialogFirst(loginDialogFirst);
      this.getEditorAPI().bi.event(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_signup_settings,
        {
          name: 'shown first',
          value: this.state.loginDialogFirst ? 'login' : 'signup',
        },
      );
    }

    const socialLoginStatusApi = createSocialLoginStatusApi(editorAPI);
    const statuses = await socialLoginStatusApi.getSocialLoginStatus();
    if (statuses.google !== socialLoginGoogleEnabled) {
      await socialLoginStatusApi.setSocialLoginStatus(
        'google',
        socialLoginGoogleEnabled,
      );
      this.getEditorAPI().bi.event(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_google_login_changed,
        {
          googlePlus: socialLoginGoogleEnabled,
        },
      );
    }

    if (statuses.facebook !== socialLoginFacebookEnabled) {
      await socialLoginStatusApi.setSocialLoginStatus(
        'facebook',
        socialLoginFacebookEnabled,
      );
      this.getEditorAPI().bi.event(
        coreBi.events.topbar.pages
          .top_bar_PAGES_settings_facebook_login_changed,
        {
          facebook: socialLoginFacebookEnabled,
        },
      );
    }

    const joinCommunityStatusApi = createJoinCommunityStatusApi(editorAPI);
    const shouldToggleCommunityStatus =
      this.isSocialAppInstalled() &&
      this.state.hasSocialPages &&
      (await joinCommunityStatusApi.getJoinCommunityStatus()).joinCommunity !==
        this.state.joinCommunityEnabled;
    if (shouldToggleCommunityStatus) {
      joinCommunityStatusApi.setJoinCommunityStatus(
        this.state.joinCommunityEnabled,
        this.state.joinCommunityRevision,
      );

      this.getEditorAPI().bi.event(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_signup_settings,
        {
          name: 'join the community',
          value: this.state.joinCommunityEnabled ? 'checked' : 'unchecked',
        },
      );
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values, you-dont-need-lodash-underscore/for-each
    _.forEach(_.values(SIGNUP_POLICIES), (policy) => {
      if (shouldUpdatePolicyToggle(smAPI, this.state, policy)) {
        smAPI.setSignupPoliciesFieldStatus(
          policy,
          this.state[`${policy}LinkVisible`],
        );
        this.getEditorAPI().bi.event(
          coreBi.events.topbar.pages.top_bar_PAGES_settings_signup_settings,
          {
            name: policy,
            value: this.state[`${policy}LinkVisible`] ? 'on' : 'off',
          },
        );
      }

      if (this.state[`${policy}LinkItem`]) {
        smAPI.setSignupPoliciesLink(policy, this.state[`${policy}LinkItem`]);
      }
    });

    this.closePanel();
  },

  openHowToHelpCenter() {
    this.getEditorAPI().panelManager.openHelpCenter(
      helpIds.SITE_MEMBERS.HOW_TO,
      {},
      {
        origin: this.props.panelName,
        panel_name: this.props.panelName,
      },
    );
  },

  openExamplePolicy(policyHelpId) {
    this.getEditorAPI().panelManager.openHelpCenter(policyHelpId, {}, {});
  },

  getAutoApprovalOptions() {
    return [
      {
        label: 'Pages_Member_Signup_Permission_Settings_Dropdown_Automatic',
        value: true,
      },
      {
        label: 'Pages_Member_Signup_Permission_Settings_Dropdown_Manual',
        value: false,
      },
    ];
  },

  getLoginFirstOptions() {
    return LOGIN_FIRST_OPTIONS;
  },

  onRadioButtonsClick(key, value) {
    this.setState({ [key]: value });
  },

  getGoogleSymbol() {
    return React.createElement(symbols.symbol, {
      name: 'mba_social_panel_google_plus_new',
    });
  },

  getFacebookSymbol() {
    return React.createElement(symbols.symbol, {
      name: 'mba_social_panel_facebook',
    });
  },

  openLinkPanelFor(policy) {
    const editorAPI = this.getEditorAPI();

    editorAPI.openLinkPanel({
      visibleSections: {
        PageLink: true,
        AnchorLink: true,
        NoLink: false,
        PhoneLink: false,
        WhatsAppLink: false,
        ExternalLink: false,
        EmailLink: false,
        DocumentLink: false,
        LoginToWixLink: false,
        EdgeAnchorLinks: false,
        PopupLink: false,
      },
      link: getPolicyLink(editorAPI, this.state, policy),
      showAllPopups: true,
      pageTabTitle: 'LINK_PANEL_TAB_ANCHOR',
      pageTabPageDropDownTitle: 'PLATFORM_LINK_MENU_PAGE_PAGE_OPTION',
      anchorTabPageDropDownTitle: 'LINK_PANEL_PAGE_DROP_DOWN_PAGELINK_LABEL',
      title: 'Pages_Member_Signup_Settings_Policies_Link_Header',
      callback: _.partial(this.linkPanelCallback, policy),
    });

    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_signup_link_to_policy,
      {
        name: policy,
        action: 'click',
        type: 'none',
      },
    );
  },

  isDeveloperModeEnabled() {
    return this.getEditorAPI().developerMode.isEnabled();
  },

  isCustomSignupEnabled() {
    return !!this.getEditorAPI().siteMembers.getCustomSignupPageId();
  },

  getCustomSignupLinkText() {
    return this.isCustomSignupEnabled()
      ? translate('Pages_Member_Signup_Settings_Custom_Signup_Edit_Link')
      : translate('Pages_Member_Signup_Settings_Custom_Signup_Enable_Link');
  },

  getTabContentClass() {
    return `tab-content ${this.isCustomSignupEnabled() ? 'with-banner' : ''}`;
  },

  openCustomSignupPanel() {
    this.getEditorAPI().panelManager.openPanel(
      'panels.toolPanels.customSignupSettingsPanel',
      { panelName: 'panels.toolPanels.customSignupSettingsPanel' },
      true,
    );

    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.custom_signup_enable,
    );
  },

  getTabHelpId() {
    switch (this.state.selectedTab) {
      case TAB_VALUES.GENERAL:
        return helpIds.SITE_MEMBERS.GENERAL;
      case TAB_VALUES.SOCIAL:
        return helpIds.SITE_MEMBERS.SOCIAL_AND_COMMUNITY;
      case TAB_VALUES.TERMS_AND_CONDITIONS:
        return helpIds.SITE_MEMBERS.POLICIES;
      default:
        return helpIds.SITE_MEMBERS.SETTINGS;
    }
  },

  render() {
    return template.apply(this);
  },
});

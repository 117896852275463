import * as stateManagement from '#packages/stateManagement';
import type {
  InstallerAppsPanelOwnProps,
  PanelResolveType,
} from './installerAppsPanel';
import type { MapDispatchToProps } from 'types/redux';

export interface InstallerAppsPanelDispatchProps {
  closePanel(resolveArgs: PanelResolveType): void;
  openHelpCenter(): void;
}

const mapDispatchToProps: MapDispatchToProps<
  InstallerAppsPanelDispatchProps,
  InstallerAppsPanelOwnProps
> = (dispatch, ownProps) => {
  const helpId = '96bf9496-5b4a-455c-9b66-c645a4041aa9';
  const closePanel = (resolveArgs: PanelResolveType): void => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    ownProps.onPanelClose(resolveArgs);
  };

  const openHelpCenter = (): void =>
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId));

  return {
    closePanel,
    openHelpCenter,
  };
};

export { mapDispatchToProps };

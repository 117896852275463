// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as coreBi from '#packages/coreBi';
import PropTypes from 'prop-types';
import React from 'react';
import * as util from '#packages/util';
import ListSection from '../listSection/listSection';

export interface AddPagesSectionProps {
  sendBi: FunctionFixMe;
  onItemClick: FunctionFixMe;
  shouldBeDisabled: FunctionFixMe;
  staticPagesCount: number;
  openDynamicPagesStartingPanel: FunctionFixMe;
}

class AddPagesSection extends React.Component<AddPagesSectionProps> {
  static displayName = 'AddPagesSection';

  static propTypes = {
    sendBi: PropTypes.func.isRequired,
    onItemClick: PropTypes.func.isRequired,
    shouldBeDisabled: PropTypes.func.isRequired,
    staticPagesCount: PropTypes.number.isRequired,
    openDynamicPagesStartingPanel: PropTypes.func.isRequired,
  };

  getListSectionProps = () =>
    Object.assign(
      {
        onLargeTooltipHovered: () => {
          this.props.sendBi(coreBi.events.pages.cant_add_page_tooltip_shown, {
            origin: 'add_panel',
          });
        },
        largeTooltipContent:
          this.props.shouldBeDisabled() && this.getAddTooltipContent(),
      },
      this.props,
    );

  getAddTooltipContent = () => ({
    title: 'Pages_Menu_Add_Button_Disabled_Heavy_Sites_Tooltip_Title',
    description: 'Pages_Menu_Add_Button_Disabled_Heavy_Sites_Tooltip_Text',
    learnMoreText: 'Pages_Menu_Add_Button_Disabled_Heavy_Sites_Tooltip_Link',
    onLearnMore: () => {
      this.props.openDynamicPagesStartingPanel();
      this.props.sendBi(
        coreBi.events.pages.pagesPanel.too_many_pages_info_click,
        {
          origin: 'add_panel',
          pages_count: this.props.staticPagesCount,
        },
      );
    },
  });

  render() {
    return (
      <ListSection
        callback={this.props.onItemClick}
        {...this.getListSectionProps()}
      />
    );
  }
}

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

const biCategory = 'CUSTOM_MAIN_MENU';
const addPanelOrigin = 'addPanel';

/**
 * I'm implementing the whole mapper only in mapDispatchToProps because of 2 reasons:
 * 1. It can be implemented here because product wise, "canAddStaticPage" will not be changed while add panel is opened.
 * 2. Because editorAPI.pages.getStaticPagesCount() is very heavy operation and if can be calculated only once, better performance :)
 */
export const mapDispatchToProps = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  const staticPagesCount = editorAPI.pages.getStaticPagesCount();
  const canAddStaticPage = editorAPI.pages.canAddStaticPage(staticPagesCount);
  return {
    staticPagesCount,
    openDynamicPagesStartingPanel: () => {
      editorAPI.panelManager.openPanel('wixData.dynamicPagesStartingPanel', {
        notifyDataBindingAppTooManyPages:
          editorAPI.pages.notifyDataBindingAppTooManyPages,
        staticPagesCount,
        origin: `add_panel_#${staticPagesCount}`,
      });
    },
    onItemClick: (item) => {
      if (item.action === addPanelDataConsts.CLICK_ACTION.ADD_BLANK_PAGE) {
        editorAPI.bi.event(
          coreBi.events.pages.pagesPanel.add_page_selection_click,
          {
            origin: addPanelOrigin,
            category: 'page',
            source: biCategory,
          },
        );
        util.fedopsLogger.interactionStarted(
          util.fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_OPEN,
        );
        editorAPI.panelManager.openPanel(
          'addPreset.panels.addPagePanel',
          {
            origin: addPanelOrigin,
            biCategory,
          },
          true,
        );
      }
    },
    sendBi: editorAPI.bi.event,
    shouldBeDisabled: () => !canAddStaticPage,
  };
};

const Connected = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(AddPagesSection);

export default Connected;

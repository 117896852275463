import experiment from 'experiment';

import { appStudioUtils, sections } from '#packages/util';
import constants from '#packages/constants';

import { SectionTypes } from './constants';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { Section } from './types';

const SECTION_OVERLAP_THRESHOLD = -3;

const IGNORED_COMP_TYPES = new Set<string>([
  constants.COMP_TYPES.ANCHOR,
  constants.COMP_TYPES.TEXT,
]);

export const isSectionsOnStageEnabled = () =>
  experiment.isOpen('se_addSectionHoverUI') &&
  !sections.isSectionsEnabled() &&
  !appStudioUtils.isAppStudio();

export const shiftComponents = (
  editorAPI: EditorAPI,
  components: CompRef[],
  offset: number,
) => {
  components.forEach((component) => {
    const compLayout = editorAPI.components.layout.get_position(component);
    editorAPI.components.layout.update(
      component,
      {
        y: compLayout.y + offset,
      },
      true,
    );
  });
};

export const getComponentsBelowPosition = (
  editorAPI: EditorAPI,
  position: number,
) => {
  const currentPage = editorAPI.pages.getCurrentPage();
  const components = editorAPI.components.getChildren(currentPage);
  const notPinnedComponents = components.filter(
    (ref) => !editorAPI.components.layout.isPinned(ref),
  );

  const componentsToShift = notPinnedComponents.filter(
    (ref) => editorAPI.components.layout.get_position(ref).y >= position,
  );

  return componentsToShift;
};

export const shouldTreatAsSection = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  if (!compRef) return false;
  const compType = editorAPI.components.getType(compRef);
  if (!compType) return false;
  if (editorAPI.components.is.horizontallyMovable(compRef)) return false;
  if (editorAPI.components.layout.isPinned(compRef)) return false;
  if (IGNORED_COMP_TYPES.has(compType)) return false;

  const containerRef = editorAPI.components.getContainer(compRef);
  if (!containerRef) return false;

  if (editorAPI.components.is.page(containerRef)) return true;

  return (
    editorAPI.components.is.container(compRef) &&
    editorAPI.components.is.page(
      editorAPI.components.getContainer(containerRef),
    )
  );
};

export const shouldApplySectionResizeIndication = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => shouldTreatAsSection(editorAPI, compRef);

const getSortedSectionsOnStage = (editorAPI: EditorAPI): Section[] => {
  const currentPage = editorAPI.pages.getCurrentPage();
  const components = editorAPI.components.getChildren(currentPage);

  const sectionLayouts = components
    .filter((comp) => shouldTreatAsSection(editorAPI, comp))
    .map(editorAPI.components.layout.getRelativeToScreen);

  sectionLayouts.sort((a, b) => a.y - b.y);

  const sections = sectionLayouts.map(({ y, width, height }) => ({
    type: SectionTypes.Section,
    top: y,
    bottom: y + height,
    width,
    height,
  }));

  const [header, footer] = getLayoutSections(editorAPI);

  const allSections = [header, ...sections, footer];

  return allSections.reduce((acc, section) => {
    const prevSection = acc.pop();

    if (!prevSection) {
      return [section];
    }

    if (section.top > prevSection.bottom) {
      const height = section.top - prevSection.bottom;
      const virtualSection = {
        type: SectionTypes.VirtualSection,
        top: prevSection.bottom,
        bottom: prevSection.bottom + height,
        height,
        width: prevSection.width,
      };
      return [...acc, prevSection, virtualSection, section];
    }

    return [...acc, prevSection, section];
  }, []);
};

const mergeOverlappingSections = (sections: Section[]) =>
  sections.reduce((acc, section) => {
    const prevSection = acc.pop();

    if (!prevSection) {
      return [section];
    }

    const distanceBetweenSections = section.top - prevSection.bottom;
    if (distanceBetweenSections < SECTION_OVERLAP_THRESHOLD) {
      const mergedTop = Math.min(prevSection.top, section.top);
      const mergedBottom = Math.max(prevSection.bottom, section.bottom);
      const combinedSection = {
        ...prevSection,
        top: mergedTop,
        bottom: mergedBottom,
        height: mergedBottom - mergedTop,
      };

      return [...acc, combinedSection];
    }

    return [...acc, prevSection, section];
  }, []);

const getLayoutSections = (editorAPI: EditorAPI) => {
  const currentPage = editorAPI.pages.getCurrentPage();
  const isLandingPage = editorAPI.dsRead.pages.isLandingPage(currentPage.id);

  const pageLayout =
    editorAPI.components.layout.getRelativeToScreen(currentPage);

  if (isLandingPage) {
    return [
      {
        top: 0,
        bottom: 0,
        height: 0,
        width: pageLayout.width,
        type: SectionTypes.VirtualSection,
      },
      {
        top: pageLayout.height,
        bottom: pageLayout.height,
        height: 0,
        width: pageLayout.width,
        type: SectionTypes.VirtualSection,
      },
    ];
  }

  const siteHeader = editorAPI.dsRead.siteSegments.getHeader();
  const siteFooter = editorAPI.dsRead.siteSegments.getFooter();

  const headerLayout =
    editorAPI.components.layout.getRelativeToScreen(siteHeader);
  const footerLayout =
    editorAPI.components.layout.getRelativeToScreen(siteFooter);

  return [
    {
      top: headerLayout.y,
      bottom: headerLayout.y + headerLayout.height,
      height: headerLayout.height,
      width: pageLayout.width,
      type: SectionTypes.Header,
    },
    {
      top: footerLayout.y,
      bottom: footerLayout.y + footerLayout.height,
      height: footerLayout.height,
      width: pageLayout.width,
      type: SectionTypes.Footer,
    },
  ];
};

export const getSectionsOnStage = (editorAPI: EditorAPI) => {
  const sortedSectionsOnStage = getSortedSectionsOnStage(editorAPI);
  return mergeOverlappingSections(sortedSectionsOnStage);
};

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';

function themeChangeHandler(editorAPI, eventObj) {
  const colors = eventObj.values;
  if (eventObj.type === 'COLOR') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    _(
      editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getAllComponents(),
    )
      .reduce(function (designDataItems, compPointer) {
        const designDataItem = editorAPI.components.design.get(compPointer);
        if (designDataItem) {
          designDataItems.push({
            pointer: compPointer,
            data: designDataItem,
          });
        }
        return designDataItems;
      }, [])
      .forEach(function (item) {
        const { data } = item;
        util.designData.updateThemeColors(data, colors);
        editorAPI.components.design.update(item.pointer, data, true);
      });
  }
}

export default themeChangeHandler;

// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const title = 'add_section_label_feedbackforms';
const presetTitle = 'add_section_label_feedbackforms_preset';
const tooltipTitle = 'add_section_label_feedbackforms_tooltip_title';
const helpText = 'add_section_label_feedbackforms_tooltip_description';

const getFeedbackSection = (editorAPI) => ({
  feedbackSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title,
    topTitle: title,
    tooltipTitle,
    sectionName: 'feedbackSection',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    automationId: 'add-panel-section-feedbackSection',
    showSectionHeader: true,
    subNavigationHide: true,
    presetTitle,
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsFeedbackSection_en/formsFeedbackSection_en.v7.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Feedback_Forms_1',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.FEEDBACK03,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.FEEDBACK03,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 448,
              height: 695,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: { rect: { width: 162, height: 225, x: 0, y: 0 }, tags: null },
        },
        {
          id: 'Feedback_Forms_2',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.FEEDBACK04,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.FEEDBACK04,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 482,
              height: 1069,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 225, x: 162, y: 0 },
            tags: null,
          },
        },
        {
          id: 'Feedback_Forms_3',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.TESTIMONIAL03,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.TESTIMONIAL03,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 508,
              height: 545,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.MULTI_STEP_FORM,
          }),
          preset: {
            rect: { width: 162, height: 187, x: 0, y: 225 },
            tags: null,
          },
        },
        {
          id: 'Feedback_Forms_4',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.FEEDBACK05,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.FEEDBACK05,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 507,
              height: 638,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 187, x: 162, y: 225 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: helpText,
    },
  },
});

export default (editorAPI) => getFeedbackSection(editorAPI);

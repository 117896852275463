import type { Scope } from './wixStoreEntryPoint';
import { BasePublicApi } from '#packages/apilib';
import { getStoresCategoryStatusForSite } from './WixStoreApiUtil';
import { ErrorReporter } from '@wix/editor-error-reporter';

function getIsNewStoreCategoryUser(scope: Scope) {
  return scope.store.getIsNewStoreCategoryUser();
}

export async function initIsNewStoreCategoryUser(scope: Scope) {
  try {
    const { editorAPI, store } = scope;
    const { result } = await getStoresCategoryStatusForSite(editorAPI);
    const isNewStoreCategoryUser = result.value;
    store.setIsNewStoreCategoryUser(isNewStoreCategoryUser);
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: { failedToGetNewStoreCategoryUser: true },
    });
  }
}

export class WixStoreApi extends BasePublicApi<Scope> {
  getIsNewStoreCategoryUser = this.bindScope(getIsNewStoreCategoryUser);
}

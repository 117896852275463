import * as stateManagement from '#packages/stateManagement';
import { getBusinessData } from '../utils';

import type { SectionCategoryDefinition } from '../../../../types';
import type { AddPresetScope } from '../../../../addPresetApiEntry';
import type { PromptCreatorOwnProps } from './promptCreator';
import type { SectionCreatorPromptParams } from '../aiSectionCreatorTypes';

export interface PromptCreatorStateProps {
  categories: SectionCategoryDefinition[];
}

export type PromptCreatorOutput = Pick<
  SectionCreatorPromptParams,
  'categoryId' | 'description'
>;

export interface PromptCreatorDispatchProps {
  handleSubmitEvent: (formData: PromptCreatorOutput) => void;
}

export const mapStateToProps = (
  scope: AddPresetScope,
): PromptCreatorStateProps => {
  const categories = scope.store
    .getSectionsAiCategories()
    .filter((category) => !category.isDivider);
  return {
    categories,
  };
};

export const mapDispatchToProps = (
  scope: AddPresetScope,
  { onSubmit }: PromptCreatorOwnProps,
): PromptCreatorDispatchProps => {
  const { businessType, businessName } = getBusinessData(scope);

  return {
    handleSubmitEvent: (formData: PromptCreatorOutput): void => {
      scope.editorAPI.store.dispatch(
        stateManagement.ai.actions.setIsAiTermsAndConditionsShown(true),
      );

      onSubmit?.({
        ...formData,
        businessType,
        businessName,
      });
    },
  };
};

import React from 'react';
import { cx } from '#packages/util';
import { Text } from '@wix/wix-base-ui';

import styles from './menuButtonLabel.scss';

export interface MenuButtonLabelProps {
  text: string;
  className?: string;
  shouldTranslate: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  automationId?: string;
}

const MenuButtonLabel: React.FC<MenuButtonLabelProps> = (props) => {
  const {
    text,
    className,
    shouldTranslate,
    onClick,
    onMouseEnter,
    onMouseLeave,
    automationId,
  } = props;
  return (
    <div
      data-aid={automationId}
      className={cx(styles.labelBox, className)}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Text
        shouldTranslate={shouldTranslate}
        enableEllipsis={false}
        weight="bold"
        size="small"
      >
        {text}
      </Text>
    </div>
  );
};

export default MenuButtonLabel;

import { EditorAPIKey } from '#packages/apis';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import type { Shell } from '#packages/apilib';
import { hasPinDockingSizeRestrictionsIssues } from '#packages/pinModeUtils';

const pinModeActions = stateManagement.pinMode.actions;
const { getPreviewMode } = stateManagement.preview.selectors;
const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;

export function createPinModeApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const { dispatch } = editorAPI.store;

  function close() {
    const pinToScreenCount =
      getSessionUserPreferences(constants.USER_PREFS.PIN_TO_SCREEN.PIN_COUNT)(
        editorAPI.store.getState(),
      ) || 0;
    if (pinToScreenCount === 0) {
      const onNotificationLinkClick = () => {
        editorAPI.panelManager.openHelpCenter(
          helpIds.NOTIFICATIONS.PIN_TO_SCREEN,
        );
      };
      editorAPI.store.dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: 'Notifications_Pinned_Mobile_Text',
          title: 'Notifications_Pinned_Mobile_Text',
          type: 'info',
          link: {
            caption: 'Notifications_Pinned_Mobile_Link',
            onNotificationLinkClick,
          },
        }),
      );
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          constants.USER_PREFS.PIN_TO_SCREEN.PIN_COUNT,
          -4,
        ),
      );
    }
    dispatch(pinModeActions.close());
  }

  function closeAndCheckSizeRestrictions() {
    close();
  }

  function checkSizeRestrictionsAndShowNotification(origin?: string) {
    const state = editorAPI.store.getState();
    if (!getPreviewMode(state)) {
      editorAPI.dsActions.waitForChangesApplied(function () {
        const [compRef] = editorAPI.selection.getSelectedComponents();
        // eslint-disable-next-line no-lone-blocks -- lone block is here only to keep commit change history more readable
        {
          if (hasPinDockingSizeRestrictionsIssues(editorAPI, compRef)) {
            const onNotificationLinkClick = () => {
              editorAPI.panelManager.openHelpCenter(
                helpIds.NOTIFICATIONS.PINNED_TOO_BIG,
              );
            };
            editorAPI.store.dispatch(
              stateManagement.notifications.actions.showUserActionNotification({
                message: 'Notifications_Pinned_Large_Element_Text',
                title: 'Notifications_Pinned_Large_Element_Text',
                type: 'warning',
                origin: origin || '',
                link: {
                  caption: 'Notifications_Learn_More_Link',
                  onNotificationLinkClick,
                },
              }),
            );
          }
        }
      });
    }
  }

  return {
    closeAndCheckSizeRestrictions,
    checkSizeRestrictionsAndShowNotification,
  };
}

:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_qsz9u_7 {
  padding: 9px 12px;
  border-radius: 4px;
  border: 1px solid var(--elements-color);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: var(--stage-action-button-height);
  box-shadow: 0px 2px 5px -3px rgba(0, 0, 0, 0.15), 0px 4px 10px 1px rgba(0, 0, 0, 0.11), 0px 3px 14px 2px rgba(0, 0, 0, 0.09);
  background-color: var(--bg-color); }
  ._container_qsz9u_7 .control-text {
    color: var(--elements-color) !important; }
  ._container_qsz9u_7._hasIcon_qsz9u_20 {
    padding-right: 18px; }
  ._container_qsz9u_7._noLabel_qsz9u_22 {
    padding: 6px; }
    ._container_qsz9u_7._noLabel_qsz9u_22 ._icon_qsz9u_24 {
      margin: 0; }

._icon_qsz9u_24 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;
  width: 20px; }
  ._icon_qsz9u_24 svg {
    fill: var(--elements-color); }

._primary_qsz9u_36 {
  --bg-color: #6f56f9;
  --elements-color: #ffffff; }
  ._primary_qsz9u_36:hover {
    --bg-color: #4b31dc; }

._secondary_qsz9u_42 {
  --bg-color: #ffffff;
  --elements-color: #6f56f9; }
  ._secondary_qsz9u_42:hover {
    --bg-color: #d9d2ff; }

._lightLarge_qsz9u_48,
._lightLargeSoap_qsz9u_49 {
  --bg-color: #fff;
  --elements-color: #2d3034;
  height: 32px;
  border: none; }
  ._lightLarge_qsz9u_48:hover,
  ._lightLargeSoap_qsz9u_49:hover {
    --elements-color: #116dff; }

._primarySoap_qsz9u_58 {
  --bg-color: #fe620f;
  --elements-color: #ffffff; }
  ._primarySoap_qsz9u_58:hover {
    --bg-color: #dd560e; }

._secondarySoap_qsz9u_64 {
  --bg-color: #ffffff;
  --elements-color: #fe620f; }
  ._secondarySoap_qsz9u_64:hover {
    --bg-color: #fee8dc; }

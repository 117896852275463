import { promiseUtils } from '#packages/util';
import { loadScripts } from './loadScripts';
import { getSessionBiHistoryUrl } from './getSessionBiHistoryUrl';
import { getSessionRecordUrl } from './getSessionRecordUrl';
import { getPageUrlWithoutSessionRecording } from './sessionRecordingUtils';

/**
 * Read about Jira issue collector here:
 * https://support.atlassian.com/jira-cloud-administration/docs/customize-the-jira-issue-collector/
 *
 * Created issues you can find here:
 * https://jira.wixpress.com/issues/?jql=labels+%3D+collector-69cb46ac
 */

const JIRA_ISSUE_COLLECTOR_ID = '69cb46ac';

const JIRA_ISSUE_COLLECTOR_SCRIPTS = [
  `https://jira.wixpress.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/otxopc/813009/g345zt/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=${JIRA_ISSUE_COLLECTOR_ID}`,
  `https://jira.wixpress.com/s/4a66933a2d50d0553d6b84aac755799c-T/otxopc/813009/g345zt/4.0.4/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=${JIRA_ISSUE_COLLECTOR_ID}`,
];

const JIRA_ISSUE_COLLECTOR_TIMEOUT = 5 /* sec */ * 1000;

function ensureJiraIssueCollectorAvailable(): Promise<void> {
  // NOTE: if wix VPN is not enabled, requests to the scripts under `https://jira.wixpress.com` could be in `pending` status for a long time
  return promiseUtils.withPromiseTimeout(
    Promise.all(
      JIRA_ISSUE_COLLECTOR_SCRIPTS.map((scriptURL) =>
        fetch(scriptURL, {
          mode: 'no-cors',
          // ensure scripts are not taken from the cache
          cache: 'no-store',
        }),
      ),
    ).then(() => undefined),
    JIRA_ISSUE_COLLECTOR_TIMEOUT,
  );
}

interface JiraIssueCollector {
  open: (props: {
    fieldValues: {
      summary: string;
      fullname: string;
      email: string;
      description: string;
    };
  }) => void;
}

export interface JiraIssueCollectorParameters {
  metaSiteId: string;
  editorSessionId: string;
  userInfo: {
    firstName: string;
    lastName: string;
    userId: string;
    email: string;
  };
}

function withEllipsesInTheMiddle(text: string, startAndEndLength: number) {
  return `${text.substring(0, startAndEndLength)}...${text.substring(
    text.length - startAndEndLength,
  )}`;
}

let initJiraIssueCollectorPromise: Promise<JiraIssueCollector> | null;
async function initJiraIssueCollector() {
  if (initJiraIssueCollectorPromise) {
    return initJiraIssueCollectorPromise;
  }

  let resolve: (value: JiraIssueCollector) => void;
  initJiraIssueCollectorPromise = new Promise<JiraIssueCollector>(
    (_resolve) => {
      resolve = _resolve;
    },
  );

  const _fieldValues = {};
  Object.assign(window, {
    ATL_JQ_CONFIGS: {
      [JIRA_ISSUE_COLLECTOR_ID]: {
        enabled: true,
        recordWebInfo: true,
        baseUrl: 'https://jira.wixpress.com',
        triggerPosition: 'CUSTOM',
      },
    },
    ATL_JQ_PAGE_PROPS: {
      triggerFunction: (open: () => void) =>
        resolve({
          open: ({ fieldValues }) => {
            Object.assign(_fieldValues, fieldValues);

            open();
          },
        }),
      fieldValues: _fieldValues,
    },
  });

  await loadScripts(JIRA_ISSUE_COLLECTOR_SCRIPTS).catch((error) => {
    initJiraIssueCollectorPromise = null;
    return Promise.reject(error);
  });

  return initJiraIssueCollectorPromise;
}

export class JiraIssueCollectorIsNotAvailableError extends Error {}
export class EmptySessionRecordingUrlError extends Error {}

export async function openJiraIssueCollector({
  metaSiteId,
  editorSessionId,
  userInfo,
}: JiraIssueCollectorParameters) {
  try {
    await ensureJiraIssueCollectorAvailable();
  } catch (_error) {
    throw new JiraIssueCollectorIsNotAvailableError();
  }

  const jiraIssueCollector = await initJiraIssueCollector();

  const startTime = Date.now() - 1 /*minutes*/ * 60000;
  const endTime = Date.now();

  const sessionRecordUrl = await getSessionRecordUrl({ startTime });
  const sessionBiHistoryUrl = getSessionBiHistoryUrl({
    uuid: userInfo.userId,
    metaSiteId,
    startTime,
    endTime,
  });
  const editorUrl = getPageUrlWithoutSessionRecording();

  if (!sessionRecordUrl) {
    throw new EmptySessionRecordingUrlError();
  }

  jiraIssueCollector.open({
    fieldValues: {
      summary: '',
      fullname: `${userInfo.firstName} ${userInfo.lastName}`,
      email: userInfo.email,
      description:
        '*Description*:\n' +
        '\n----\n' +
        '*Steps to reproduce*:\n' +
        '\n\n----\n' +
        `*Editor URL*: [${withEllipsesInTheMiddle(
          editorUrl,
          30,
        )}|${editorUrl}]\n` +
        `*MetaSite ID*: ${metaSiteId}\n` +
        `*Session ID*: ${editorSessionId}\n` +
        `*Session recorder URL*: [${withEllipsesInTheMiddle(
          sessionRecordUrl,
          30,
        )}|${sessionRecordUrl}] ^1^\n` +
        `*Session BI history URL*: [${withEllipsesInTheMiddle(
          sessionBiHistoryUrl,
          30,
        )}|${sessionBiHistoryUrl}] ^1^\n` +
        `^1^ ??Issue reported at ${new Date().toTimeString()}. The bug is probably located a [few minutes before this time|https://help.fullstory.com/hc/en-us/articles/360020623614-Understanding-session-time-and-how-to-switch-to-absolute-time].??` +
        `\n----\n` +
        `This issue generated by [Jira issue collector|https://support.atlassian.com/jira-cloud-administration/docs/customize-the-jira-issue-collector/].\n` +
        `Created issues you can find here:\n` +
        `https://jira.wixpress.com/issues/?jql=labels+%3D+collector-${JIRA_ISSUE_COLLECTOR_ID}`,
      // TODO: find a way to make `recordWebInfo` checked
      // recordWebInfoConsent: 'true',
      // recordWebInfo: 'true',
      // TODO: find a way to make `labels` work
      // https://community.atlassian.com/t5/Jira-questions/How-to-set-label-in-Issue-Collector-javascript-widget/qaq-p/784609
      // https://community.atlassian.com/t5/Jira-questions/Issue-Collector-Can-we-add-a-field-quot-Label-quot-and-custom/qaq-p/1043453
      // labels: ['WixEditorBugsBucket'],
    },
  });
}

export function handleJiraIssueCollectorError(error: Error | unknown) {
  if (error instanceof JiraIssueCollectorIsNotAvailableError) {
    // eslint-disable-next-line no-alert
    alert(
      'Failed to load jira issue collector.\nYou may have network issues or WIX VPN is not enabled.',
    );
  } else if (error instanceof EmptySessionRecordingUrlError) {
    // eslint-disable-next-line no-alert
    alert(
      'Session Recording URL is empty.\nTry reloading the Editor and record the bug again.',
    );
  } else if (error instanceof Error) {
    // eslint-disable-next-line no-alert
    alert(
      `Something went wrong: ${error.name} - ${error.message}.\nTry reloading the Editor and record the bug again.`,
    );
  } else {
    // eslint-disable-next-line no-alert
    alert(
      `Something went wrong.\nTry reloading the Editor and record the bug again.`,
    );
  }

  throw error;
}

// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import * as util from '#packages/util';
import focusPanelMixin from './focusPanelMixin';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';

import React from 'react';
import WelcomeVideoPanel from './welcomeVideoPanel';
import * as baseUI from '#packages/baseUI';

const bi = coreBi;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'ADIVideoPanel',
  mixins: [core.mixins.editorAPIMixin, focusPanelMixin],
  openTooltip() {
    const editorAPI = this.getEditorAPI();
    editorAPI.store.dispatch(
      stateManagement.topBarTooltip.actions.showBackToAdiTooltip(),
    );
    this.sendTooltipOpenedBI();
  },
  getBIEvent() {
    return bi.events.newUserIntroVideoPanel.INTRO_VIDEO_CLOSE;
  },
  sendTooltipOpenedBI() {
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(
      coreBi.events.backToADIFromEditor.goingBackToWixADITooltipOpened,
      {
        esi: util.editorModel.editorSessionId,
        site_id: editorAPI.dsRead.generalInfo.getSiteId(),
      },
    );
  },
  render() {
    return (
      <WelcomeVideoPanel
        ref="frame"
        title={this.props.title}
        subtitle={this.props.subtitle}
        linkedVideoData={this.props.linkedVideoData}
        panelName={this.props.panelName}
        onCloseByX={this.sendBIEventWhenClosedByX}
        onCloseByESC={this.sendBIEventWhenClosedByESC}
        shouldNotCloseOnBlur={true}
      >
        <div className="buttons">
          <baseUI.button
            label={this.props.buttonLabel}
            onClick={() => {
              this.startNow();
            }}
          />
        </div>
      </WelcomeVideoPanel>
    );
  },
});

// @ts-nocheck
import React from 'react';
import * as util from '#packages/util';

export default class extends React.Component {
  static displayName = 'richTextFrame';

  render() {
    return (
      <util.outerClick onOuterClick={this.props.onOuterClick}>
        <div>{this.props.children}</div>
      </util.outerClick>
    );
  }
}

import { tpaUtils } from '#packages/util';
import {
  PRICING_PLANS,
  WIX_BLOG,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_RESTAURANTS_ORDERS,
  WIX_PORTFOLIO,
  WIX_LOYALTY_PROGRAM,
  WIX_REFERRAL_PROGRAM,
} from '@wix/app-definition-ids';

export const TRANSLATIONS = {
  PANEL_TITLE: 'App_Manager_Panel_Title',

  TABS: {
    INSTALLED_APPS: 'App_Manager_Panel_Tab_Header',
    MORE_APPS: 'App_Manager_Panel_More_Apps_Link',
  },

  INTRO: {
    TITLE: 'App_Manager_Panel_Empty_State_Header',
    TEXT: 'App_Manager_Panel_Empty_State_Text',
  },

  // Yeah, these keys are the real ones
  APPS_LIST: {
    INSTALLED_APPS_LABEL: 'App_Manager_Panel_Tab_Header',
    POPULAR_APPS_LABEL: 'App_Manager_Panel_Tab_Header2',
    MORE_APPS_LABEL: 'App_Manager_Panel_More_Apps_Link',
  },

  APP_CARD: {
    CTA_BUTTON_LABEL: {
      NOT_INSTALLED: 'App_Manager_Panel_Add_Button',
      INSTALLED: 'App_Manager_Panel_Manage_Button',
      PENDING: 'App_Manager_Panel_Continue_Button',
    },
    STATUS_TEXT: {
      INSTALLED: 'App_Manager_Installed',
      PENDING: 'App_Manager_Panel_Pending_Text',
    },
  },

  APP_MARKET_INFO_BANNER: {
    TEXT: 'App_Manager_Panel_App_Market_Banner_Text',
    LINK: 'App_Manager_Panel_App_Market_Banner_Link',
  },

  TRANSITION_STATE: {
    TITLE: 'App_Manager_Finalize_Installation_Header',
    TEXT: 'App_Manager_Finalize_Installation_Text',
    LINK: 'App_Manager_Finalize_Installation_Link',
  },

  APP_PAGE: {
    INSTALLED: 'App_Manager_Installed',
    REMOVE: 'App_Manager_Vertical_Panel_Delete_App_Link',
    BACK_BUTTON: 'App_Manager_Panel_Back_Button',

    QUICK_ACTIONS_SECTION: {
      TITLE: 'App_Manager_Panel_Section_Header_Quick_Actions',
      TOOLTIP: {
        TITLE: 'App_Manager_Panel_Section_Header_Quick_Actions_Tooltip_Header',
        TEXT: 'App_Manager_Panel_Section_Header_Quick_Actions_Tooltip_Text',
      },
    },

    MORE_ACTIONS_SECTION: {
      TITLE: 'App_Manager_Vertical_Panel_Secton_Header2',
      ACTIONS_LABELS: {
        LEARN_MORE: 'App_Manager_Vertical_Panel_Learn_More_Link',
        UPDATE: 'App_Manager_Panel_Manage_update_cta',
        DELETE_APP: 'App_Manager_Vertical_Panel_Delete_App_Link',
      },
    },
  },

  ERROR_STATE: {
    GENERAL: {
      TITLE: 'App_Manager_General_Error_Message_1_Title',
      TEXT: 'App_Manager_General_Error_Message_1_Text',
    },

    CONNECTION_ISSUES: {
      TITLE: 'App_Manager_General_Error_Message_3_Title',
      TEXT: 'App_Manager_General_Error_Message_3_Text',
    },

    LINK: 'App_Manager_General_Error_Message_1_Link',
  },

  MY_BUSINESS: {
    SIDEBAR: {
      RECOMMENDED_APPS_TAB:
        'App_Manager_Ecomm_Segment_Navigation_Section2_Link',
      MORE_APPS_TAB: 'App_Manager_Tab_No_Apps_Title',
    },
    SETUP_HEADER: {
      [tpaUtils.getStoresAppDefId()]: {
        TITLE: 'App_Manager_Wix_Stores_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Stores_Tab_Subeheader',
      },
      [WIX_BLOG]: {
        TITLE: 'App_Manager_Wix_Blog_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Blog_Tab_Subheader',
      },
      [WIX_BOOKINGS]: {
        TITLE: 'App_Manager_Wix_Bookings_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Bookings_Tab_Subheader',
      },
      [WIX_EVENTS]: {
        TITLE: 'App_Manager_Wix_Events_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Events_Tab_Subheader',
      },
      [WIX_RESTAURANTS_ORDERS]: {
        TITLE: 'App_Manager_Wix_Restaurants_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Restaurants_Tab_Subeheader',
      },
      [PRICING_PLANS]: {
        TITLE: 'App_Manager_Wix_Pricing_Plans_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Pricing_Plans_Tab_Subheader',
      },
      [WIX_PORTFOLIO]: {
        TITLE: 'App_Manager_Wix_Portfolio_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Portfolio_Tab_Subheader',
      },
      [WIX_LOYALTY_PROGRAM]: {
        TITLE: 'App_Manager_Wix_Loyalty_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Loyalty_Tab_Subheader',
      },
      [WIX_REFERRAL_PROGRAM]: {
        TITLE: 'App_Manager_Wix_Referral_Tab_Header',
        SUBTITLE: 'App_Manager_Wix_Referral_Tab_Subheader',
      },
    },
    MORE_APPS: {
      SECTION_TITLE: 'App_Manager_Panel_No_Apps_Title',
      APP_MARKET_LINK: 'App_Manager_Panel_App_Market_Banner_Link',
    },
  },
};

import * as addPanel from '#packages/oldAddPanel';
import getPopupContainerContactSection from './popupContactSection/section';
import getPopupContainerSubscribeSection from './popupSubscribeSection/section';
import getPopupContainerSaleSection from './popupSaleSection/section';

export default {
  getCategorySections(editorAPI: AnyFixMe) {
    return Object.assign({}, addPanel.sections.getSections().popup, {
      popupContactSection: getPopupContainerContactSection(editorAPI),
      popupSubscribeSection: getPopupContainerSubscribeSection(editorAPI),
      popupSaleSection: getPopupContainerSaleSection(editorAPI),
    });
  },
};

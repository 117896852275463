// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import disableKeyboardShortcutsMixin from '../../mixins/disableKeyboardShortcutsMixin';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import { Button, Divider, PanelHeader, RichText } from '@wix/wix-base-ui';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
const ConfirmationPanelFrame = createReactClass({
  displayName: constants.PANEL_TYPES.CONFIRMATION,
  mixins: [util.propTypesFilterMixin, disableKeyboardShortcutsMixin],
  propTypes: {
    panelName: PropTypes.string.isRequired,
    panelTitle: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    onConfirm: PropTypes.func,
    shouldCloseByDefaultOnConfirm: PropTypes.bool,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onHelp: PropTypes.func,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    shouldHideHeader: PropTypes.bool,
    shouldHideFooter: PropTypes.bool,
    noHelpBtn: PropTypes.bool,
    shouldDisableConfirmButton: PropTypes.bool,
    shouldHideCancelButton: PropTypes.bool,
    shouldHideCloseButton: PropTypes.bool,
    type: PropTypes.oneOf(['important', 'normal']),
    footerMessage: PropTypes.string,
  },
  getDefaultProps() {
    return {
      type: 'normal',
      // TODO: Replace with translation key
      confirmLabel: 'confirmationPanelFrame_Confirm',
      // TODO: Replace with translation key
      cancelLabel: 'confirmationPanelFrame_Cancel',
      shouldDisableConfirmButton: false,
      shouldCloseByDefaultOnConfirm: true,
    };
  },
  getPanelProps() {
    const { width } = this.props;
    const { height } = this.props;
    const { className } = this.props;
    return {
      style: {
        width,
        height,
      },
      className,
    };
  },
  onConfirm() {
    if (this.props.shouldCloseByDefaultOnConfirm) {
      this.props.close();
    }
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
  },
  onCancel() {
    this.props.close();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  },
  onClose() {
    if (this.props.onClose) {
      this.props.close();
      this.props.onClose();
    } else {
      this.onCancel();
    }
  },
  onHelp() {
    if (this.props.onHelp && this.props.noHelpBtn) {
      this.props.onHelp();
    }
  },
  getConfirmLabel() {
    return this.props.confirmLabel;
  },
  getCancelLabel() {
    return this.props.cancelLabel;
  },
  getConfirmButtonClass() {
    const className =
      this.props.type === 'important'
        ? 'btn-danger-primary'
        : 'btn-confirm-primary';
    return `${className} btn-md`;
  },
  getSecondaryButtonClass() {
    const className =
      this.props.type === 'important'
        ? 'btn-danger-secondary'
        : 'btn-confirm-secondary';
    return `${className} btn-md`;
  },
  render() {
    return (
      <div className="panel-frame-container screen-centered-frame confirmation-panel-frame-z-index">
        <div
          {...this.getPanelProps()}
          className={cx(
            `confirmation-panel-frame ${this.props.type}-confirmation`,
            this.getPanelProps()?.className,
          )}
        >
          {!this.props.shouldHideHeader ? (
            <PanelHeader
              automationId="confirmation-panel-header"
              key="header"
              onClose={this.onClose}
              onHelp={this.props.onHelp}
              noHelpBtn={this.props.noHelpBtn}
              noCloseBtn={this.props.shouldHideCloseButton}
              className="dark"
            >
              {this.props.panelTitle ? (
                <span key="headerTitle">{this.props.panelTitle}</span>
              ) : null}
            </PanelHeader>
          ) : null}

          <div className="content">{this.props.children}</div>

          {!this.props.shouldHideFooter && (
            <footer key="footer">
              {!this.props.shouldHideCancelButton && (
                <Button
                  key="cancelButton"
                  automationId="cancelButton"
                  onClick={this.onCancel}
                  className={this.getSecondaryButtonClass()}
                >
                  <span>{translate(this.props.cancelLabel)}</span>
                </Button>
              )}
              <Button
                key="confirmationButton"
                automationId="confirmationButton"
                onClick={this.onConfirm}
                disabled={this.props.shouldDisableConfirmButton}
                className={this.getConfirmButtonClass()}
              >
                <span>{translate(this.props.confirmLabel)}</span>
              </Button>
              {this.props.footerMessage && (
                <>
                  <div className="divider">
                    <Divider short />
                  </div>
                  <div className="footer-massage">
                    <RichText>{this.props.footerMessage}</RichText>
                  </div>
                </>
              )}
            </footer>
          )}
        </div>
        <div className="dark-frame-overlay" />
      </div>
    );
  },
});

const WithPanelFrame = stateManagement.panels.hoc.panelFrame(
  constants.PANEL_TYPES.CONFIRMATION,
)(ConfirmationPanelFrame);
WithPanelFrame.pure = ConfirmationPanelFrame;
export default WithPanelFrame;

// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import { CustomScroll, PanelHeader } from '@wix/wix-base-ui';

import * as core from '#packages/core';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import { cx, draggableMixin } from '#packages/util';

import PanelCloseOrigin from '../panelCloseOrigin';

const { panelDragLimits, panelFrame } = stateManagement.panels.hoc;

const stylePropKeys = ['width', 'top', 'left', 'right'];

interface TpaSettingsPanelFrameProps {
  panelName: string;
  title: string;
  width?: number;
  height?: number;
  top?: string;
  left?: string;
  right?: string;
  onHelpClicked?: () => void;
  shouldAddScroll: boolean;
  onClose?: () => void;
  close?: () => void;
  className?: string;
  cssPerBreakpoint?: boolean;
  style?: Record<string, string>;
  closeWithUserIntent?: boolean;
  isHidden?: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
const TpaSettingsPanelFrameSimple = createReactClass({
  displayName: constants.PANEL_TYPES.TPA_SETTINGS,
  mixins: [draggableMixin, core.mixins.editorAPIMixin],
  propTypes: {
    panelName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    top: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    onHelpClicked: PropTypes.func,
    getPanelDragLimits: PropTypes.func.isRequired,
    shouldAddScroll: PropTypes.bool.isRequired,
    style: PropTypes.object,
  },
  getInitialState() {
    return {
      showHelp: false,
    };
  },
  getPanelProps() {
    const styleProps = stylePropKeys.filter((propKey) => this.props[propKey]);
    const style = _.pick(this.props, styleProps);

    const fullStyle = {
      ...this.props.style,
      ...style,
    };

    if (this.props.isHidden) {
      fullStyle.display = 'none';
    }

    return {
      style: fullStyle,
      className: this.props.className,
    };
  },
  toggleHelp() {
    const newShowHelp = !this.state.showHelp;
    this.setState({
      showHelp: newShowHelp,
    });
  },
  getContentStyle() {
    return {
      height: _.isNumber(this.props.height) ? `${this.props.height}px` : '100%',
    };
  },

  handleClose() {
    _.invoke(this.props, 'close', PanelCloseOrigin.HEADER_CLOSE_BUTTON);
    _.invoke(this.props, 'onClose', PanelCloseOrigin.HEADER_CLOSE_BUTTON);
  },

  render() {
    const shouldHideHelpBtn =
      !this.props.helpId && !this.props.helpLink && !this.props.onHelpClicked;

    return (
      <div
        data-aid="tpa-settings-panel"
        {...this.getPanelProps()}
        className={cx(
          'tpa-settings-panel-frame',
          this.getPanelProps()?.className,
        )}
      >
        <PanelHeader
          onMouseDown={(e) => {
            this.startDrag(e, this.props.getPanelDragLimits());
          }}
          onHelp={this.props.openHelpCenter}
          noHelpBtn={shouldHideHelpBtn}
          onClose={this.handleClose}
          className="dragger dark"
        >
          <span data-hook="panel-header-label">{this.props.title}</span>
        </PanelHeader>
        {this.props.shouldAddScroll ? (
          <CustomScroll key="scroll">
            <div style={this.getContentStyle()} className="content">
              {this.props.children}
            </div>
          </CustomScroll>
        ) : null}
        {!this.props.shouldAddScroll ? (
          <div
            key="noScroll"
            style={this.getContentStyle()}
            className="content"
          >
            {this.props.children}
          </div>
        ) : null}
      </div>
    );
  },
});

export default _.flow(
  panelFrame(constants.PANEL_TYPES.TPA_SETTINGS),
  panelDragLimits(),
)(
  TpaSettingsPanelFrameSimple,
) as React.ComponentType<TpaSettingsPanelFrameProps>;

// @ts-nocheck
import _ from 'lodash';
import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';

const IMAGE_CROP_SHORTCUT_CONFIG = {};
const BI_ORIGIN = 'enter';
const keyboardMap = keyboardShortcutsActionMap.imageCrop;

function addImageCropShortcuts(editorAPI) {
  const keysToMerge = {};
  keysToMerge[keyboardMap.APPLY.key] = applyCrop.bind(null, editorAPI);
  keysToMerge[keyboardMap.CANCEL.key] = cancelCrop.bind(null, editorAPI);

  _.merge(IMAGE_CROP_SHORTCUT_CONFIG, keysToMerge);
}

function applyCrop(editorAPI) {
  const compRef = _.head(editorAPI.selection.getSelectedComponents());
  editorAPI.imageCrop.updateCrop(compRef, BI_ORIGIN);
}

function cancelCrop(editorAPI) {
  const compRef = _.head(editorAPI.selection.getSelectedComponents());
  editorAPI.imageCrop.cancelCrop(compRef);
}

export default {
  init(editorAPI) {
    addImageCropShortcuts(editorAPI);
  },
  shortcuts: IMAGE_CROP_SHORTCUT_CONFIG,
};

import constants from '#packages/constants';
import { createAdiToEditorTour } from './adiToEditorTour.conf';
import { createEditorToEditorTour } from './editorToEditorTour.conf';

export enum TourNames {
  ADI_TO_EDITOR = 'adiToEditorTour',
  EDITOR_TO_EDITOR = 'editorToEditorTour',
}

export const TOURS_CONFIG = {
  [TourNames.ADI_TO_EDITOR]: {
    create: createAdiToEditorTour,
    key: constants.USER_PREFS.SECTIONS_MIGRATION.SHOW_ADI_TO_EDITOR_TOUR,
  },
  [TourNames.EDITOR_TO_EDITOR]: {
    create: createEditorToEditorTour,
    key: constants.USER_PREFS.SECTIONS_MIGRATION.SHOW_EDITOR_TO_EDITOR_TOUR,
  },
};

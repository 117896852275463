import { addWixForm, addWixFormToContainer } from '#packages/addPanelData';
import { fedopsLogger } from '#packages/util';
import type { OnDropCallback } from '#packages/addPanelInfra';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompStructure } from 'types/documentServices';

interface AddWixFormOnClickParams {
  structure: CompStructure;
  presetId: string;
  categoryId: string;
}

export const addWixFormOnClick = (
  editorAPI: EditorAPI,
  { structure, presetId, categoryId }: AddWixFormOnClickParams,
) => {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
  );

  return addWixForm(editorAPI, {
    structure,
    categoryId,
    coords: null,
    preset: presetId,
    theme: null,
    dropContainer: null,
    formRef: null,
  });
};

interface AddWixFormOnDropParams {
  presetId: string;
  categoryId: string;
}

export const addWixFormOnDrop =
  (
    editorAPI: EditorAPI,
    { presetId, categoryId }: AddWixFormOnDropParams,
  ): OnDropCallback =>
  (coords: AnyFixMe, { structure }: AnyFixMe, dropContainer: AnyFixMe) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
    );

    return addWixForm(editorAPI, {
      structure,
      coords,
      categoryId,
      preset: presetId,
      theme: null,
      dropContainer,
      formRef: null,
    });
  };

interface AddWixFormToContainerOnClickParams {
  structure: CompStructure;
  categoryId: string;
  sectionTitle: string;
  presetId: string;
  position: { x: number; y: number } | void;
}

export const addWixFormToContainerOnClick = (
  editorAPI: AnyFixMe,
  {
    structure,
    categoryId,
    sectionTitle,
    presetId,
    position,
  }: AddWixFormToContainerOnClickParams,
) => {
  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
  );

  return addWixFormToContainer(
    categoryId,
    sectionTitle,
    editorAPI,
    presetId,
    position,
    structure,
  );
};

interface AddWixFormToContainerOnDropParams {
  categoryId: string;
  sectionTitle: string;
  presetId: string;
}

export const addWixFormToContainerOnDrop =
  (
    editorAPI: EditorAPI,
    { categoryId, sectionTitle, presetId }: AddWixFormToContainerOnDropParams,
  ): OnDropCallback =>
  (coords, { structure }) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
    );

    return addWixFormToContainer(
      categoryId,
      sectionTitle,
      editorAPI,
      presetId,
      coords,
      structure,
    );
  };

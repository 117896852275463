import { BaseEntryScope } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorPaasApiKey,
  EditorCoreApiKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
  WorkspaceModesApiKey,
} from '#packages/apis';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  editorPaasApi = this.useDependency(EditorPaasApiKey);
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  workspaceModesApi = this.useDependency(WorkspaceModesApiKey);
}

import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import PanelSection from './section';

const { media } = util;

const ItemId = {
  CreateImage: 'create_image',
  CreateVideo: 'create_video',
};

const itemsIds = [ItemId.CreateImage, ItemId.CreateVideo];

interface Props {
  onCreateImageClick: FunctionFixMe;
  onCreateVideoClick: FunctionFixMe;

  helpTitle?: string;
  helpDescription?: string;
}
export default class extends React.Component<Props> {
  static displayName = 'CreateMediaSection';
  title = translate('Media_Panel_Edit_ImagesVideos_Section_Label');

  getItemsIds() {
    return itemsIds;
  }

  getItemProps(itemId: AnyFixMe) {
    switch (itemId) {
      case ItemId.CreateImage:
        return this.getCreateImageProps();
      case ItemId.CreateVideo:
        return this.getCreateVideoProps();
      default:
        return null;
    }
  }

  getCreateImageProps() {
    return {
      title: 'Media_Panel_PhotoStudio_Entry_Title',
      desc: 'Media_Panel_PhotoStudio_Entry_Description',
      url: media.getMediaUrl(
        'rEditor/mediaManagerPanel/create-image-action.png',
      ),
      callback: () => _.invoke(this.props, 'onCreateImageClick'),
      shouldTranslate: true,
    };
  }

  getCreateVideoProps() {
    return {
      title: 'Media_Panel_VideoMaker_Entry_Title',
      desc: 'Media_Panel_VideoMaker_Entry_Description',
      url: media.getMediaUrl(
        'rEditor/mediaManagerPanel/create-video-action.png',
      ),
      callback: () => _.invoke(this.props, 'onCreateVideoClick'),
      shouldTranslate: true,
    };
  }

  render() {
    return (
      <PanelSection
        title={this.title}
        disabledContentPaddings={true}
        helpTitle={this.props.helpTitle}
        helpDescription={this.props.helpDescription}
      >
        {this.getItemsIds().map((itemId) => (
          <baseUI.listItemWithImage
            key={itemId}
            largeTooltipContent={null}
            {...this.getItemProps(itemId)}
          />
        ))}
      </PanelSection>
    );
  }
}

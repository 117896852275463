/*eslint max-lines: [2, { "max": 1112, "skipComments": true, "skipBlankLines": true}]*/
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { cx, editorWixRecorder, isDebugMode } from '#packages/util';
import _ from 'lodash';
import { Tooltip } from '@wix/wix-base-ui';
import constants from '#packages/constants';
import { languageSelectorButton } from '#packages/newAddPanelAPI';
import mapTopBarPropsToCustomDropPanels from './mapTopBarPropsToCustomDropPanels';
import mapPropsToButtons from './mapTopBarPropsToButtons';
import mapTopBarPropsToMenuBarItems from '../menuBar/menuBarListItems/menuStructure';
import * as topBarData from './topBarData';
import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';

import WixLogo from '../wixLogo/wixLogo';
import TopBarButton from '../topBarButton/topBarButton';
import LanguageSelect from '../languageSelect/languageSelect';
import HideToolsButton from '../hideToolsButton/hideToolsButton';
import Notification from '../notification/notification';
import DropPanelAnchor from '../dropPanel/dropPanelAnchor';
import MobileDropPanel from '../dropPanel/mobileDropPanel';
import TextAndLinkDropPanel from '../dropPanel/textAndLinkDropPanel';
import DynamicDropPanel from '../dropPanel/dynamicDropPanel';
import MenuBar from '../menuBar/menuBar';
import TopBarBanner from '../topBarBanner/topBarBanner';
import { integrateMenus } from '../editorSearchIntegrations/menusIntegration';
import TooltipContent from '../tooltipContent';

import * as UA from '#packages/uiAnimations';
import * as pages from '#packages/pages';

import type { BiEventFields, SendBiFunction } from 'types/bi';
import type { SwitchEditorModeInteractionStartedSource } from 'types/fedops/mobileEditorInteraction';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

const { LANGUAGE_DROP_PANEL } = constants.ROOT_COMPS.TOPBAR.DROP_PANELS;

function template() {
  const MOBILE_PANEL = constants.ROOT_COMPS.TOPBAR.BUTTONS.MOBILE;
  return (
    <div
      className={cx({
        'top-bar-wrapper': true,
        'with-top-bar-banner':
          this.props.showTopBarBanner && !this.props.isHidden,
        'is-hidden': this.props.isHidden,
        'is-hidden-completely':
          this.props.areToolsHiddenAndStagePositionMaintained,
      })}
      data-hook="top-bar-wrapper"
    >
      <div
        onContextMenu={this.handleContextMenu}
        className={cx({
          'main-top-bar': true,
          [`top-bar-lang-${this.getResponsivenessClassSuffix()}`]: true,
          'top-bar-multilingual': this.props.showLanguageSelect,
        })}
      >
        <div className="top-bar-left-section">
          <WixLogo
            sendBi={this.props.sendBi}
            isPreviewMode={this.props.isPreviewMode}
            isZoomMode={this.props.isZoomMode}
            isSitePublished={this.props.isSitePublished}
          />
          {this.props.showLanguageSelect ? this.renderLanguageSelect() : null}
          {!this.props?.overriddenQuickNavigation?.isHidden &&
          !this.props.isPreviewMode ? (
            <pages.quickNavigation
              key="quickNavigation"
              forceOpenEventCounter={
                this.props.forceOpenPagesQuickNavigationEventCounter
              }
              currentPageId={this.props.currentPageId}
            />
          ) : null}
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.getTopBarLeftButtons(), (button) => {
            return (
              <DropPanelAnchor key={`button${button.key}`}>
                <Tooltip
                  disabled={this.isButtonTooltipDisabled(button)}
                  marginBottom={6}
                  maxWidth={220}
                  content={TooltipContent(button)}
                >
                  <TopBarButton
                    automationId={button.automationId}
                    toggled={button.toggled}
                    label={button.label}
                    onClick={button.onClick}
                    setEditorMode={this.props.setEditorMode}
                    fakeLayoutItems={button.fakeLayoutItems}
                    onMouseEnter={() => {
                      this.onTopBarButtonMouseEnter(button);
                    }}
                    onMouseLeave={button.onMouseLeave}
                    disabled={button.disabled}
                    ref={button.ref}
                    customSymbol={button.customSymbol}
                    symbolName={button.symbolName}
                    symbolClassName={button.symbolClassName}
                    className={button.className}
                  />
                </Tooltip>
                <UA.topBarMenuPanelAnimation
                  outAnimationDuration={this.state.delayedFadeOut ? 0.2 : 0}
                >
                  {this.props.openedDropPanel === button.key &&
                  button.key === MOBILE_PANEL ? (
                    <MobileDropPanel
                      key={`${button.key}DropPanel`}
                      isMobileOptimized={this.props.isMobileOptimized}
                      setMobileOptimizedSite={this.props.setMobileOptimizedSite}
                      isPreviewMode={this.props.isPreviewMode}
                      setEditorMode={this.props.setEditorMode}
                      isMobileEditor={this.props.isMobileEditor}
                      sendBi={this.props.sendBi}
                      onMouseEnter={() => {
                        this.openDropPanel(button.key);
                      }}
                      onMouseLeave={() => {
                        this.closeDropPanel();
                      }}
                      closePanel={function () {
                        this.closeDropPanel(true);
                      }.bind(this)}
                    />
                  ) : null}
                  {this.props.openedDropPanel === button.key &&
                  // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
                  _.includes(this.textAndLinkDropPanelKeys, button.key) ? (
                    <TextAndLinkDropPanel
                      key={`${button.key}DropPanel`}
                      dropPanelData={button.dropPanelData}
                      bubbleStyleOverrides={button.bubbleStyleOverrides}
                      onMouseEnter={() => {
                        this.openDropPanel(button.key);
                      }}
                      onMouseLeave={() => {
                        this.closeDropPanel();
                      }}
                    />
                  ) : null}
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                  {_.map(this.getCustomDropPanelItems(), (customDropPanel) =>
                    this.props.openedDropPanel === customDropPanel.key &&
                    button.key === customDropPanel.targetButtonKey ? (
                      <TextAndLinkDropPanel
                        key={customDropPanel.key}
                        bubbleStyleOverrides={
                          customDropPanel.bubbleStyleOverrides
                        }
                        dropPanelData={customDropPanel.dropPanelData}
                        onMouseLeave={() => {
                          this.closeDropPanel();
                        }}
                      />
                    ) : null,
                  )}
                </UA.topBarMenuPanelAnimation>
              </DropPanelAnchor>
            );
          })}
          {!this.props.isPreviewMode ? (
            <MenuBar
              key="menuBarWrapper"
              backToAdiTooltipVisibility={
                this.props.getBackToAdiTooltipVisibility
              }
              shouldShowADITooltip={this.props.shouldShowADITooltip}
              menuBarItems={this.getMenuBarItems()}
              openDropPanel={this.openDropPanel}
              closeDropPanel={this.closeDropPanel}
              closeSiteCustomDropPanel={this.closeSiteCustomDropPanel}
              delayedFadeOut={this.state.delayedFadeOut}
              openedDropPanel={this.props.openedDropPanel}
              isFirstSave={this.props.isFirstSave}
              isDraftMode={this.props.isDraftMode}
              sendBi={this.props.sendBi}
              save={this.props.save}
              accountUpgrade={this.props.accountUpgrade}
              toggleDeveloperMode={this.props.toggleDeveloperMode}
              isDeveloperModeEnabled={this.props.isDeveloperModeEnabled}
              isWixCodeProvisioned={this.props.isWixCodeProvisioned}
              dealerViewer={this.props?.wixReactDealerViewer?.DealerViewer}
              fallbackToDefaultUpgradePanel={this.fallbackToDefaultUpgradePanel}
              metaSiteId={this.props.metaSiteId}
              metaSiteInstance={this.props.metaSiteInstance}
              isUsingExternalUpgradeData={this.isUsingExternalUpgradeData()}
              getPersonalSaleTranslatedLabel={
                this.getPersonalSaleTranslatedLabel
              }
              getPersonalSaleCampaignSymbolName={
                this.getPersonalSaleCampaignSymbolName
              }
              customDropPanelItems={this.getCustomDropPanelItems()}
              isSiteFromOnBoarding={this.props.isSiteFromOnBoarding}
              textAndLinkDropPanelKeys={this.textAndLinkDropPanelKeys}
              isActivePersonalSaleCampaign={
                this.props.isActivePersonalSaleCampaign
              }
              isMobileOptimized={this.props.isMobileOptimized}
              setMobileOptimizedSite={this.props.setMobileOptimizedSite}
            />
          ) : null}
        </div>
        <div className="top-bar-center-section">
          {this.props.isPreviewMode ? (
            <Notification key="notification">
              {this.getNotification()}
            </Notification>
          ) : null}
        </div>
        <div className="top-bar-right-section">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.getTopBarRightButtons(), (button) => (
            <DropPanelAnchor key={`button${button.key}`}>
              <Tooltip
                disabled={this.isButtonTooltipDisabled(button)}
                marginBottom={6}
                maxWidth={this.getRightButtonTooltipMaxWidth(button)}
                interactive={this.shouldTooltipBeInteractive(button)}
                content={TooltipContent(button)}
              >
                <TopBarButton
                  automationId={button.automationId}
                  label={button.label}
                  toggled={button.toggled}
                  onClick={button.onClick}
                  setEditorMode={this.props.setEditorMode}
                  fakeLayoutItems={button.fakeLayoutItems}
                  onMouseEnter={() => {
                    this.onTopBarButtonMouseEnter(button);
                  }}
                  onMouseLeave={button.onMouseLeave}
                  disabled={button.disabled}
                  ref={button.ref}
                  customSymbol={button.customSymbol}
                  symbolName={button.symbolName}
                  symbolClassName={button.symbolClassName}
                  className={
                    button.extraClassName
                      ? `${button.className} ${button.extraClassName}`
                      : button.className
                  }
                />
              </Tooltip>
              <UA.topBarMenuPanelAnimation
                outAnimationDuration={this.state.delayedFadeOut ? 0.2 : 0}
              >
                {this.props.openedDropPanel === button.key &&
                button.key === MOBILE_PANEL ? (
                  <MobileDropPanel
                    key={`${button.key}DropPanel`}
                    isMobileOptimized={this.props.isMobileOptimized}
                    setMobileOptimizedSite={this.props.setMobileOptimizedSite}
                    isPreviewMode={this.props.isPreviewMode}
                    setEditorMode={this.props.setEditorMode}
                    isMobileEditor={this.props.isMobileEditor}
                    sendBi={this.props.sendBi}
                    onMouseEnter={() => {
                      this.openDropPanel(button.key);
                    }}
                    onMouseLeave={() => {
                      this.closeDropPanel();
                    }}
                    closePanel={function () {
                      this.closeDropPanel(true);
                    }.bind(this)}
                  />
                ) : null}
                {this.props.openedDropPanel === button.key &&
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line you-dont-need-lodash-underscore/includes
                _.includes(this.textAndLinkDropPanelKeys, button.key) ? (
                  <TextAndLinkDropPanel
                    key={`${button.key}DropPanel`}
                    dropPanelData={button.dropPanelData}
                    bubbleStyleOverrides={button.bubbleStyleOverrides}
                    onMouseEnter={() => {
                      this.openDropPanel(button.key);
                    }}
                    onMouseLeave={() => {
                      this.closeSiteCustomDropPanel();
                    }}
                  />
                ) : null}
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.getCustomDropPanelItems(), (customDropPanel) => (
                  <React.Fragment key={customDropPanel.key}>
                    {this.props.openedDropPanel === customDropPanel.key &&
                    button.key === customDropPanel.targetButtonKey &&
                    customDropPanel.dropPanelData ? (
                      <TextAndLinkDropPanel
                        key={customDropPanel.key}
                        bubbleStyleOverrides={
                          customDropPanel.bubbleStyleOverrides
                        }
                        dropPanelData={customDropPanel.dropPanelData}
                        onMouseLeave={() => {
                          this.closeSiteCustomDropPanel();
                        }}
                      />
                    ) : null}
                    {this.props.openedDropPanel === customDropPanel.key &&
                    button.key === customDropPanel.targetButtonKey &&
                    customDropPanel.dynamicPanelPath ? (
                      <DynamicDropPanel
                        panelPath={customDropPanel.dynamicPanelPath}
                        key={`${customDropPanel.key}DynamicDropPanel`}
                        bubbleStyleOverrides={
                          customDropPanel.bubbleStyleOverrides
                        }
                        onMouseEnter={() => {
                          this.openDropPanel(customDropPanel.key);
                        }}
                        onMouseLeave={() => {
                          this.closeSiteCustomDropPanel();
                        }}
                      />
                    ) : null}
                  </React.Fragment>
                ))}
              </UA.topBarMenuPanelAnimation>
            </DropPanelAnchor>
          ))}
        </div>
        {this.props.showTopBarBanner ? (
          <TopBarBanner
            key="top-bar-banner"
            title={this.props.topBarBannerTitle}
          />
        ) : null}
      </div>

      {!this.props.isPreviewMode &&
      !this.props.isMobileEditor &&
      !this.props.isImageCropOn ? (
        <HideToolsButton
          isHidden={this.props.isHidden}
          key="hideAllTools"
          toggleHideTools={this.props.toggleHideTools}
          shouldHideToolsBtnBlink={this.props.shouldHideToolsBtnBlink}
        />
      ) : null}
    </div>
  );
}

const { MODES, MENU_BAR_ITEMS, BUTTONS, DROP_PANELS } =
  constants.ROOT_COMPS.TOPBAR;
const { TEXT_AND_LINK_DROP_PANEL_KEYS, RESPONSIVENESS_CLASS_SUFFIXES } =
  topBarData;
const RIGHT_BUTTON_TOOLTIP_MAX_WIDTH = 220;

const {
  mapTopBarPropsToLeftButtons,
  mapTopBarPropsToRightButtons,
  mapTopBarPropsToModeSwitchButtons,
} = mapPropsToButtons;

const topBarClickEvent = coreBi.events.topbar.top_bar_click;

const NBSP = '\xA0';
const MobileNonOptimizedNotification = (props) =>
  React.createElement('span', {}, [
    translate('mobile_preview_notification_text1'),
    NBSP,
    React.createElement(
      'a',
      { onClick: props.onClick },
      translate('mobile_preview_notification_link'),
    ),
    NBSP,
    translate('mobile_preview_notification_text2'),
  ]);
MobileNonOptimizedNotification.displayName = 'MobileNonOptimizedNotification';
MobileNonOptimizedNotification.propTypes = {
  onClick: PropTypes.func.isRequired,
};

interface SaveOptions {
  preventSiteSavedPanel: boolean;
  callback: () => void;
}

export interface TopBarDispatchProps {
  setMobileOptimizedSite: (isOptimized: boolean) => void;
  upgrade: (referralAdditionalInfo: string) => void;
  sendBi: SendBiFunction;
  toggleDeveloperMode: (options?: { notifyApps: boolean }) => void;
  saveSite: (options: SaveOptions) => void;
  togglePreview: (
    callback?: Function,
    params?: {
      biParams?: { origin?: string };
    },
  ) => void;
  togglePreviewSimple: () => void;
  toggleHideTools: () => void;
  toggleRightSection: (sectionName: string) => void;
  collapseMobileMenu: () => void;
  enterZoomMode: () => void;
  exitZoomMode: () => void;
  undo: () => void;
  redo: () => void;
  accountUpgrade: (referralAdditionalInfo: string) => void;
  myAccountAction: (action: string) => void;
  openPremiumSettings: (tab: string) => void;
  closeAllPanels: () => void;
  onMultilingualUpdated: (languageSelectorPreset?) => void;
  onSyncFlagButton: () => void;
  loadSupportedLanguages: () => void;
  closeBackToAdiTooltip: () => void;
  showBackToAdiTooltip: () => void;
  openSettings: (tabName: string, referrer?: string) => void;
  openPanel: (
    panelName: string,
    panelProps?: Record<string, any>,
    leavePanelsOpen?: boolean,
  ) => void;
  openMoveSiteToTrashConfirmation: (
    panelName: string,
    panelProps: Record<string, any>,
    leavePanelsOpen: boolean,
  ) => void;
  openConnectDomain: (flowId: string, isFirstPublish: boolean) => void;
  openMobileElementsPanel: () => void;
  setPanelType: (
    panelName: string,
    panelType: string,
    singleInstance: boolean,
    closeWithUserIntent: boolean,
    isHidden: boolean,
  ) => void;
  setEditorMode: (
    isMobile: boolean,
    sourceOfStart: SwitchEditorModeInteractionStartedSource,
    preventUndoRedoStack?: boolean,
  ) => void;
  getEditorVersionsInfo: () => undefined | EditorVersionsInfo;
  waitForChangesApplied: (
    callback: () => void,
    onlyChangesAlreadyRegistered?: boolean,
  ) => void;
  startWelcomeTour: () => Promise<any>;
  openDropPanel: (panelName: string) => void;
  closeDropPanel: (dropPanelName?: string) => void;
  siteSaveAndPublish: (options: SaveAndPublishOptions) => void;
  sitePublish: (options: PublishOptions) => void;
  openSearchPanel: (origin: string) => void;
  save: (origin?: string, sourceOfStart?: SaveInteractionStartedSource) => void;
  getInvalidLoggedUser: () => Promise<string | null>;
  handleInvalidLoggedUser: (invalidStatus: string) => void;
  getFeedbackNewCommentsCounter: () => '99+' | number;
  saveAndPublish: (origin?: string) => void;
  publishRC: (origin?: string) => void;
  publish: (origin?: string) => void;
  openReleaseManager: () => void;
  openRevisions: () => void;
  setTopBarSiteHistoryUsed: () => void;
  exitEditor: () => void;
  openWixSEOWiz: () => void;
  exitInteractionModeIfNeeded: () => void;
  manage: () => void;
  openAccessibilityWizard: () => void;
  toggleToolBar: () => void;
  toggleLayersPanel: () => void;
  toggleRulers: () => void;
  toggleGuidelines: (onSuccess?: () => void, onFail?: () => void) => void;
  toggleSnapTo: (onSuccess?: () => void, onFail?: () => void) => void;
  toggleDeveloperToolBar: () => void;
  toggleDeveloperHiddenComponents: () => void;
  openHelpCenter: (
    helpId: string,
    props: Record<string, unknown>,
    biHelpParams: Record<string, unknown>,
  ) => void;
  openHelpCenterHome: (
    helpId: string,
    props: Record<string, unknown>,
    biHelpParams: Record<string, unknown>,
  ) => void;
  showOrHideChat: (visibility: boolean) => void;
  openNewReleasesFeed: () => void;
  openHelpHome: () => void;
  sendBiForLoginRelatedError: () => void;
  openSiteBranchesManager: () => void;
}

export interface TopBarOwnProps {
  isImageCropOn: boolean;
  onContextMenu: () => void;
  fallbackToDefaultUpgradePanel: (err: string) => boolean;
  isUsingExternalUpgradeData: () => boolean;
}

export interface TopBarStateProps {
  sitePublicUrl: string;
  canUserPublish: boolean;
  isSitePublished: boolean;
  isPopupOpen?: boolean;
  isHidden: boolean;
  isFirstSave: boolean;
  isPreviewMode: boolean;
  isZoomMode: boolean;
  isMinimized: boolean;
  isMobileOptimized: boolean;
  isMobileEditor: boolean;
  isDeveloperModeEnabled: boolean;
  isWixCodeProvisioned: boolean;
  isSiteFromOnBoarding: boolean;
  isPublishInProgress: boolean;
  isSaveInProgress: boolean;
  isBuildInProgress: boolean;
  isEditorSearchPanelOpen: boolean;
  inInteractionMode: boolean;
  isDraftMode: boolean;
  isUserOwner: boolean;
  isMac: boolean;
  isRulersEnabled: boolean;
  isToolBarEnabled: boolean;
  isSavedInCurrentSession: boolean;
  isLayersPanelDisplayed: boolean;
  isGuidelinesEnabled: boolean;
  isSnapToEnabled: boolean;
  isDeveloperToolBarEnabled: boolean;
  isDeveloperToolbarInCodePanel: boolean;
  isDeveloperHiddenComponentsEnabled: boolean;
  isTemplate: boolean;
  developerModeFirstTime: boolean;
  hasSeenBackToAdiTooltip: boolean;
  didPassWelcomeToEditorPanel: boolean;
  autosaveDisabledOnDS: boolean;
  autosaveEnabled: boolean;
  showTopBarBanner: boolean;
  topBarBannerTitle: string;
  showLanguageSelect?: boolean;
  shouldShowADITooltip: boolean;
  shouldSaveBeforePublish: boolean;
  areToolsHiddenAndStagePositionMaintained: boolean;
  getBackToAdiTooltipVisibility: boolean;
  currentPageId: string;
  metaSiteId: string;
  metaSiteInstance: string;
  siteId: string;
  deviceType: 'mobile' | 'desktop';
  openedDropPanel: string;
  openPanels: unknown[];
  idePaneState: unknown;
  currentCountryCode?: string;
  currentLanguageCode?: string;
  shouldCurrentLanguageInvisible?: boolean;
  savingStatus: ProgressStatus;
  previewingStatus: ProgressStatus;
  publishingStatus: ProgressStatus;
  testSiteStatus: ProgressStatus;
  forceOpenPagesQuickNavigationEventCounter: number;
  shouldHideToolsBtnBlink: boolean;
  imageCrop: EditorState['imageCrop'];
  tpaMlState?: any; // see componentDidUpdate
  isMultilingualSite?: boolean;
  originalLanguage?: LanguageDefinition;
  translationLanguages?: LanguageDefinition[];
  languageCode: string;
  personalSaleCampaignAssetPath: string;
  supportedLanguages?: unknown[];
  overriddenMenus: unknown;
  overriddenSiteMenu: unknown;
  overriddenSettingsMenu: unknown;
  overriddenToolsMenu: unknown[];
  overriddenHelpMenu: unknown;
  overriddenCodeMenu: unknown;
  overriddenLeftButtons: unknown;
  overriddenRightButtons: unknown;
  overriddenCustomDropPanels: unknown;
  overriddenExternalLinks: unknown;
  overriddenQuickNavigation: AnyFixMe;
  wixReactDealerViewer: {
    DealerViewer: React.ComponentType<DealerViewerProps>;
  };
  wixDealerClientApi: {
    DealerAssetsLoader: unknown;
  };
  isDealerCssLoaded: boolean;
  userPermissions: string[];
  branchId: string;
  hasNewReleasesNotification: boolean;
  hasSiteBeenADI: boolean;
  isActivePersonalSaleCampaign: boolean;
  personalSaleCampaignDiscountValue: number;
  canSiteBeRestoredToADI: boolean;
  isChatLoaded: boolean;
  isChatVisible: boolean;
  hasUserSeenMobileEditor: boolean;
  undoLastSnapshotParams?: BiEventFields;
  redoLastSnapshotParams?: BiEventFields;
  numberOfManualPublish: number;
  devMode: 'on' | 'off';
  clickId: string;
}

export interface TopBarProps
  extends TopBarOwnProps,
    TopBarStateProps,
    TopBarDispatchProps {}

export default class TopBar extends React.Component<TopBarProps> {
  constructor(props) {
    super(props);

    this.state = {
      delayedFadeOut: true,
      hasDealerLoaded: false,
    };
    this.wasDealerSingleAttemptExecuted = false;
    this.hasUserSeenDefaultUpgradePanel = false;

    this.textAndLinkDropPanelKeys = TEXT_AND_LINK_DROP_PANEL_KEYS;

    this.openDropPanel = (openedDropPanel) => {
      if (this.props.getBackToAdiTooltipVisibility) {
        props.closeBackToAdiTooltip();
      }

      if (openedDropPanel === MENU_BAR_ITEMS.UPGRADE) {
        if (!this.isUsingExternalUpgradeData()) {
          this.hasUserSeenDefaultUpgradePanel = true;

          if (!this.props.isDealerCssLoaded) {
            const biParams = {
              isUsingDealer: false,
              stage: 'prefetch',
              description:
                'dealer not ready when user tried to open upgrade panel',
            };
            this.sendContentPresentedByDealerBI(biParams);
          }
        }
      }

      window.clearTimeout(this.dropPanelTimer);
      if (this.props.openedDropPanel !== openedDropPanel) {
        this.setState({
          delayedFadeOut: false,
        });
        this.props.openDropPanel(openedDropPanel);
      }
    };

    this.closeDropPanel = (immediately) => {
      const DROP_PANEL_DELAY = 500;

      window.clearTimeout(this.dropPanelTimer);
      if (immediately) {
        this.setState({
          delayedFadeOut: false,
        });
        this.props.closeDropPanel();
      } else {
        this.setState({ delayedFadeOut: true });
        this.dropPanelTimer = window.setTimeout(
          this.props.closeDropPanel,
          DROP_PANEL_DELAY,
        );
      }
    };

    this.getTopBarLeftButtons = () => {
      const currentMode = this.props.isPreviewMode
        ? MODES.PREVIEW
        : MODES.EDITOR;
      const buttonProps = _.defaults(
        {
          openDropPanel: (openedDropPanel) =>
            this.openDropPanel(openedDropPanel),
          closeDropPanel: this.closeDropPanel,
        },
        this.props,
      );

      return (
        _(buttonProps)
          .thru(mapTopBarPropsToLeftButtons)
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          .filter((item) => _.includes(item.MODES, currentMode))
          .value()
      );
    };

    this.getSwitchPreviewModeButtons = () => {
      const currentMode = this.props.isPreviewMode
        ? MODES.PREVIEW
        : MODES.EDITOR;
      const buttonProps = _.defaults(
        {
          openDropPanel: (openedDropPanel) =>
            this.openDropPanel(openedDropPanel),
          closeDropPanel: this.closeDropPanel,
        },
        this.props,
      );

      return (
        _(buttonProps)
          .thru(mapTopBarPropsToModeSwitchButtons)
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          .filter((item) => _.includes(item.MODES, currentMode))
          .value()
      );
    };

    this.stopDropPanelTimer = () => {
      window.clearTimeout(this.dropPanelTimer);
      this.setState({ delayedFadeOut: false });
    };

    this.getTopBarRightButtons = () => {
      const currentMode = this.props.isPreviewMode
        ? MODES.PREVIEW
        : MODES.EDITOR;
      const buttonProps = _.defaults(
        {
          openDropPanel: (openedDropPanel) =>
            this.openDropPanel(openedDropPanel),
          closeDropPanel: this.closeDropPanel,
        },
        this.props,
      );

      return (
        _(buttonProps, true)
          .thru(mapTopBarPropsToRightButtons)
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          .filter((item) => _.includes(item.MODES, currentMode))
          .value()
      );
    };

    this.getMenuBarItems = () =>
      mapTopBarPropsToMenuBarItems(
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(
          {
            closeDropPanel: this.closeDropPanel,
            getPersonalSaleTranslatedLabel: this.getPersonalSaleTranslatedLabel,
            getPersonalSaleCampaignSymbolName:
              this.getPersonalSaleCampaignSymbolName,
            getPersonalSaleCampaignImageUrl:
              this.getPersonalSaleCampaignImageUrl,
          },
          this.props,
        ),
      );

    this.getCustomDropPanelItems = () =>
      mapTopBarPropsToCustomDropPanels(this.props);

    this.isUsingExternalUpgradeData = () =>
      this.state.hasDealerLoaded &&
      this.props.isDealerCssLoaded &&
      !this.hasUserSeenDefaultUpgradePanel;

    this.getNotification = () => {
      const isMobileNonOptimized =
        this.props.isMobileEditor && !this.props.isMobileOptimized;
      return isMobileNonOptimized
        ? React.createElement(MobileNonOptimizedNotification, {
            onClick: () => {
              this.props.togglePreview();
              this.props.waitForChangesApplied(() => {
                this.props.collapseMobileMenu();
                this.props.openMobileElementsPanel();
              });
            },
          })
        : translate('Topbar_PREVIEW_TITLE');
    };

    this.getRightButtonTooltipMaxWidth = (button) => {
      if (button?.customTooltip?.overrideMaxWidth) {
        return button.customTooltip.overrideMaxWidth;
      }
      return RIGHT_BUTTON_TOOLTIP_MAX_WIDTH;
    };

    this.isCustomDropPanelActiveOnTargetButton = (targetButton) => {
      const customDropPanelData = this.getCustomDropPanelItems(this.props);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const openedCustomDropPanel = _.find(customDropPanelData, [
        'key',
        this.props.openedDropPanel,
      ]);

      return (
        openedCustomDropPanel &&
        openedCustomDropPanel.targetButtonKey === targetButton.key
      );
    };

    this.getPersonalSaleTranslatedLabel = (label) =>
      translate(label, {
        discount: this.props.personalSaleCampaignDiscountValue,
      });

    this.getPersonalSaleCampaignSymbolName = (symbolPathPrefix) => {
      return `${symbolPathPrefix}_${this.props.personalSaleCampaignDiscountValue}`;
    };

    this.getPersonalSaleCampaignImageUrl = (imagePathPrefix) =>
      util.media.getMediaUrl(
        `rEditor/topBar/${this.getPersonalSaleCampaignSymbolName(
          imagePathPrefix,
        )}.png`,
      );

    this.onTopBarButtonMouseEnter = (button) => {
      const isCustomDropPanelActiveOnTargetButton =
        this.isCustomDropPanelActiveOnTargetButton(button);
      const isMobilePanelOpen = this.props.openedDropPanel === BUTTONS.MOBILE;
      const isPublishRCOpen =
        this.props.openedDropPanel === DROP_PANELS.CUSTOM.PUBLISH_RC;

      if (!isCustomDropPanelActiveOnTargetButton && !isPublishRCOpen) {
        if (!isMobilePanelOpen) {
          this.closeDropPanel(true);
        }

        if (_.isFunction(button.onMouseEnter)) {
          button.onMouseEnter();
        }
      }

      this.lastOpenedDropPanel = null;
    };

    this.isButtonTooltipDisabled = (button) => {
      const isCustomDropPanelActiveOnTargetButton =
        this.isCustomDropPanelActiveOnTargetButton(button);
      const MOBILE_PANEL = BUTTONS.MOBILE;
      const ARENA_PANEL = BUTTONS.ARENA;

      return (
        !button.tooltipData ||
        this.props.openedDropPanel === MOBILE_PANEL ||
        this.props.openedDropPanel === ARENA_PANEL ||
        this.lastOpenedDropPanel === MOBILE_PANEL ||
        isCustomDropPanelActiveOnTargetButton
      );
    };

    this.shouldTooltipBeInteractive = (button) => {
      if (_.has(button, ['customTooltip', 'interactive'])) {
        return button.customTooltip.interactive;
      }
      return false;
    };

    this.getResponsivenessClassSuffix = () =>
      RESPONSIVENESS_CLASS_SUFFIXES[util.languages.getLanguageCode()] ||
      'large';

    this.sendContentPresentedByDealerBI = ({
      isUsingDealer,
      description,
      stage,
    }) => {
      this.props.sendBi(coreBi.events.dealer.content_presented_by_dealer, {
        description: isUsingDealer ? '' : description,
        content_presented_by_dealer: isUsingDealer,
        stage,
        category: 'upgrade',
        panel_name: 'top_bar_upgrade',
      });
    };

    this.fallbackToDefaultUpgradePanel = (errorType = 'unknown') => {
      if (
        this.state.hasDealerLoaded &&
        this.props.openedDropPanel === MENU_BAR_ITEMS.UPGRADE
      ) {
        this.setState({ hasDealerLoaded: false });

        const biParams = {
          isUsingDealer: false,
          stage: 'render',
          description: `dealer after pre fetch ${errorType} error`,
        };
        this.sendContentPresentedByDealerBI(biParams);
      }
    };

    this.loadDealerAsset = (bannerPosition, metaSiteId, metaSiteInstance) => {
      util.dealerUtils
        .loadDealerAsset(
          this.wixDealerClientApi,
          bannerPosition,
          null,
          metaSiteId,
          metaSiteInstance,
        )
        .then((asset) => {
          let biParams;
          const isUpgradePanelOpen =
            this.props.openedDropPanel === MENU_BAR_ITEMS.UPGRADE;
          if (isUpgradePanelOpen) {
            biParams = {
              isUsingDealer: false,
              stage: 'prefetch',
              description:
                'load asset response received when user have upgrade panel open',
            };
          } else {
            const hasDealerLoaded = Boolean(_.invoke(asset, 'assetExist'));
            this.setState({ hasDealerLoaded });

            biParams = {
              isUsingDealer: hasDealerLoaded,
              stage: 'prefetch',
              description: hasDealerLoaded
                ? ''
                : 'banner data pre fetch asset does not exist error',
            };
          }

          this.sendContentPresentedByDealerBI(biParams);
        })
        .catch((e) => {
          const biParams = {
            isUsingDealer: false,
            stage: 'prefetch',
            description: `banner data pre fetch ${
              e?.message ?? 'unknown'
            } error`,
          };
          this.sendContentPresentedByDealerBI(biParams);
        });
    };

    this.openLanguagesDropdown = () => {
      this.props.closeAllPanels();
      this.openDropPanel(DROP_PANELS.LANGUAGE_DROP_PANEL);
      editorWixRecorder.addLabel('multilingual top bar clicked');
      this.props.sendBi(topBarClickEvent, {
        origin: 'editor',
        category: 'multilingual',
        is_published: this.props.isSitePublished,
      });
    };

    this.showBackToAdiTooltip = () => {
      if (!this.props.hasSeenBackToAdiTooltip) {
        this.props.showBackToAdiTooltip();
        this.props.sendBi(
          coreBi.events.backToADIFromEditor.goingBackToWixADITooltipOpened,
          {
            esi: util.editorModel.editorSessionId,
            site_id: this.props.siteId,
          },
        );
      }
    };

    this.closeSiteCustomDropPanel = () => {
      this.props.closeBackToAdiTooltip();
      this.props.sendBi(
        coreBi.events.backToADIFromEditor.goingBackToWixADITooltipOpened,
        {
          esi: util.editorModel.editorSessionId,
          site_id: this.props.siteId,
        },
      );
      this.closeDropPanel();
    };
  }

  componentDidMount() {
    this.menusSearchIntegration = integrateMenus(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const shouldLoadDealerAsset =
      !this.wasDealerSingleAttemptExecuted &&
      !this.hasUserSeenDefaultUpgradePanel &&
      nextProps.wixDealerClientApi?.DealerAssetsLoader &&
      nextProps.metaSiteId &&
      nextProps.metaSiteInstance;
    if (shouldLoadDealerAsset) {
      this.wasDealerSingleAttemptExecuted = true;
      const bannerPosition =
        nextProps.isFirstSave || nextProps.isDraftMode
          ? util.dealerUtils.bannerPositions.SITE_NOT_SAVED
          : util.dealerUtils.bannerPositions.SITE_SAVED;
      this.wixDealerClientApi = nextProps.wixDealerClientApi;

      this.loadDealerAsset(
        bannerPosition,
        nextProps.metaSiteId,
        nextProps.metaSiteInstance,
      );
    }
    if (this.props.didPassWelcomeToEditorPanel) {
      this.showBackToAdiTooltip();
    }

    this.menusSearchIntegration.update(nextProps);
  }

  componentDidUpdate(prevProps) {
    this.lastOpenedDropPanel = prevProps.openedDropPanel;
    if (prevProps.tpaMlState !== this.props.tpaMlState) {
      this.props.onMultilingualUpdated(languageSelectorButton);
    }
    if (prevProps.currentLanguageCode !== this.props.currentLanguageCode) {
      this.props.onSyncFlagButton();
    }
  }

  handleContextMenu = (e) => {
    const contextMenuEnabled = e.ctrlKey && isDebugMode();

    if (!contextMenuEnabled) {
      e.preventDefault();
    }
  };

  renderLanguageSelect() {
    return (
      <LanguageSelect
        isOpened={this.props.openedDropPanel === LANGUAGE_DROP_PANEL}
        countryCode={this.props.currentCountryCode}
        languageCode={this.props.currentLanguageCode}
        delayedFadeOut={this.state.delayedFadeOut}
        onButtonClick={this.openLanguagesDropdown}
        onPanelMouseLeave={this.closeDropPanel.bind(this, false)}
        onPanelMouseEnter={this.stopDropPanelTimer}
        closePanelHandle={this.closeDropPanel.bind(this, true)}
        shouldCurrentLanguageInvisible={
          this.props.shouldCurrentLanguageInvisible
        }
      />
    );
  }

  render() {
    return template.call(this);
  }
}

TopBar.displayName = 'TopBar';

TopBar.propTypes = {
  openPanel: PropTypes.func.isRequired,
  openPanels: PropTypes.arrayOf(PropTypes.object).isRequired,
  openHelpCenter: PropTypes.func.isRequired,
  currentPageId: PropTypes.string.isRequired,
  isMobileEditor: PropTypes.bool.isRequired,
  setEditorMode: PropTypes.func.isRequired,
  isPreviewMode: PropTypes.bool.isRequired,
  inInteractionMode: PropTypes.bool.isRequired,
  exitInteractionModeIfNeeded: PropTypes.func.isRequired,
  undo: PropTypes.func.isRequired,
  redo: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  upgrade: PropTypes.func.isRequired,
  saveAndPublish: PropTypes.func.isRequired,
  sendBi: PropTypes.func.isRequired,
  togglePreview: PropTypes.func.isRequired,
  togglePreviewSimple: PropTypes.func.isRequired,
  toggleHideTools: PropTypes.func.isRequired,
  enterZoomMode: PropTypes.func.isRequired,
  isHidden: PropTypes.bool.isRequired,
  shouldHideToolsBtnBlink: PropTypes.bool.isRequired,
  areToolsHiddenAndStagePositionMaintained: PropTypes.bool.isRequired,
  idePaneState: PropTypes.string.isRequired,
  isPublishInProgress: PropTypes.bool.isRequired,
  isBuildInProgress: PropTypes.bool.isRequired,
  isSaveInProgress: PropTypes.bool.isRequired,
  isFirstSave: PropTypes.bool.isRequired,
  isDraftMode: PropTypes.bool.isRequired,
  isSiteFromOnBoarding: PropTypes.bool.isRequired,
  isSitePublished: PropTypes.bool.isRequired,
  siteId: PropTypes.string,
  metaSiteId: PropTypes.string,
  metaSiteInstance: PropTypes.string,
  sitePublicUrl: PropTypes.string.isRequired,
  canUserPublish: PropTypes.bool.isRequired,
  savingStatus: PropTypes.string,
  previewingStatus: PropTypes.oneOf([
    constants.PROGRESS_STATUS.IDLE,
    constants.PROGRESS_STATUS.IN_PROGRESS,
  ]).isRequired,
  publishingStatus: PropTypes.string,
  testSiteStatus: PropTypes.string,
  isMobileOptimized: PropTypes.bool,
  isSavedInCurrentSession: PropTypes.bool,
  getEditorVersionsInfo: PropTypes.func.isRequired,
  deviceType: PropTypes.string,
  isToolBarEnabled: PropTypes.bool.isRequired,
  isRulersEnabled: PropTypes.bool.isRequired,
  isGuidelinesEnabled: PropTypes.bool.isRequired,
  isDeveloperModeEnabled: PropTypes.bool.isRequired,
  isWixCodeProvisioned: PropTypes.bool.isRequired,
  isSnapToEnabled: PropTypes.bool.isRequired,
  isDeveloperToolBarEnabled: PropTypes.bool.isRequired,
  isDeveloperHiddenComponentsEnabled: PropTypes.bool.isRequired,
  toggleToolBar: PropTypes.func.isRequired,
  toggleRulers: PropTypes.func.isRequired,
  toggleGuidelines: PropTypes.func.isRequired,
  toggleSnapTo: PropTypes.func.isRequired,
  toggleDeveloperMode: PropTypes.func.isRequired,
  toggleDeveloperToolBar: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool,
  isUserOwner: PropTypes.bool,
  openWixSEOWiz: PropTypes.func.isRequired,
  manage: PropTypes.func.isRequired,
  openSettings: PropTypes.func.isRequired,
  openRevisions: PropTypes.func.isRequired,
  openReleaseManager: PropTypes.func.isRequired,
  myAccountAction: PropTypes.func.isRequired,
  openPremiumSettings: PropTypes.func.isRequired,
  exitEditor: PropTypes.func.isRequired,
  accountUpgrade: PropTypes.func.isRequired,
  isActivePersonalSaleCampaign: PropTypes.bool.isRequired,
  personalSaleCampaignAssetPath: PropTypes.string.isRequired,
  personalSaleCampaignDiscountValue: PropTypes.number,
  setMobileOptimizedSite: PropTypes.func.isRequired,
  developerModeFirstTime: PropTypes.bool.isRequired,
  closeAllPanels: PropTypes.func.isRequired,
  openDropPanel: PropTypes.func.isRequired,
  closeDropPanel: PropTypes.func.isRequired,
  openedDropPanel: PropTypes.string,
  openMobileElementsPanel: PropTypes.func.isRequired,
  waitForChangesApplied: PropTypes.func,
  collapseMobileMenu: PropTypes.func.isRequired,
  autosaveDisabledOnDS: PropTypes.bool,
  autosaveEnabled: PropTypes.bool,
  forceOpenPagesQuickNavigationEventCounter: PropTypes.number.isRequired,
  getFeedbackNewCommentsCounter: PropTypes.func.isRequired,
  overriddenMenus: PropTypes.arrayOf(PropTypes.object),
  overriddenSiteMenu: PropTypes.arrayOf(PropTypes.object),
  overriddenSettingsMenu: PropTypes.arrayOf(PropTypes.object),
  overriddenToolsMenu: PropTypes.arrayOf(PropTypes.object),
  overriddenHelpMenu: PropTypes.arrayOf(PropTypes.object),
  overriddenButtons: PropTypes.arrayOf(PropTypes.object),
  overriddenCustomDropPanels: PropTypes.arrayOf(PropTypes.object),
  closeSiteCustomDropPanel: PropTypes.func,
  toggleRightSection: PropTypes.func,
  isWidgetPublicAPISectionOpen: PropTypes.bool,
  openMoveSiteToTrashConfirmation: PropTypes.func.isRequired,
  wixReactDealerViewer: PropTypes.object,
  wixDealerClientApi: PropTypes.object,
  isDealerCssLoaded: PropTypes.bool,
  isImageCropOn: PropTypes.bool,
  showTopBarBanner: PropTypes.bool,
  topBarBannerTitle: PropTypes.string,
  closeBackToAdiTooltip: PropTypes.func,
  showBackToAdiTooltip: PropTypes.func,
  getBackToAdiTooltipVisibility: PropTypes.bool,
  hasSeenBackToAdiTooltip: PropTypes.bool,
  didPassWelcomeToEditorPanel: PropTypes.bool,
  shouldShowADITooltip: PropTypes.bool,
  isMultilingualSite: PropTypes.bool.isRequired,
  currentLanguageCode: PropTypes.string,
  currentCountryCode: PropTypes.string,
  onSyncFlagButton: PropTypes.func.isRequired,
  originalLanguage: PropTypes.object,
  translationLanguages: PropTypes.arrayOf(PropTypes.object),
  supportedLanguages: PropTypes.arrayOf(PropTypes.object),
  loadSupportedLanguages: PropTypes.func,
  showLanguageSelect: PropTypes.bool,
};

TopBar.propTypes.tpaMlState = PropTypes.object;
TopBar.propTypes.onMultilingualUpdated = PropTypes.func;
TopBar.propTypes.closeBackToAdiTooltip = PropTypes.func;
TopBar.propTypes.showBackToAdiTooltip = PropTypes.func;
TopBar.propTypes.getBackToAdiTooltipVisibility = PropTypes.bool;
TopBar.propTypes.hasSeenBackToAdiTooltip = PropTypes.bool;
TopBar.propTypes.didPassWelcomeToEditorPanel = PropTypes.bool;
TopBar.propTypes.publish = PropTypes.func.isRequired;

import _ from 'lodash';
import type { EditorAPI } from '@/editorAPI';
import type { PagesData } from 'types/documentServices';
import pageSettingsPanelConstants from './constants';

function getProtectionType(editorAPI: EditorAPI, pageData: PagesData) {
  const pageSecurityData = pageData?.pageSecurity;
  if (pageSecurityData.requireLogin) {
    return pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS;
  }

  if (isPageProtectedWithPassword(editorAPI, pageData)) {
    return pageSettingsPanelConstants.PERMISSION_STATES.PASSWORD;
  }

  return pageSettingsPanelConstants.PERMISSION_STATES.NONE;
}

function doesPageHavePasswordOnServer(editorAPI: EditorAPI, pageId: string) {
  return editorAPI.pages.permissions.hasPassword(pageId);
}

function isPageProtectedWithPassword(
  editorAPI: EditorAPI,
  pageData: PagesData,
) {
  return (
    !_.isEmpty(pageData?.pageSecurity?.passwordDigest) ||
    doesPageHavePasswordOnServer(editorAPI, pageData.id)
  );
}

export { getProtectionType, isPageProtectedWithPassword };

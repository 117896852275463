import React, { useState, useEffect, type FC } from 'react';
import styles from './globalAnimations.scss';
import { TextButton, Divider } from '@wix/wix-base-ui';
import { connectWithScope } from '#packages/apilib';
import {
  mapStateToProps,
  mapDispatchToProps,
  type MapDispatchToPropsType,
  type MapStateToPropsType,
} from './globalAnimations.mapper';
import { type AnimationKitDefinition } from '../api/types';
import { AnimationsScope } from '../scope';

type GlobalAnimationsCompProps = MapStateToPropsType & MapDispatchToPropsType;

const GlobalAnimationsComponent: FC<GlobalAnimationsCompProps> = ({
  animationKits,
  siteAnimationKit,
  applyAnimations,
  startPreview,
  stopPreview,
}) => {
  const [selectedAnimationKit, setSelectedAnimationKit] =
    useState<AnimationKitDefinition>();

  useEffect(() => {
    if (siteAnimationKit?.isConnected) {
      setSelectedAnimationKit(
        animationKits.find((kit) => kit.title === siteAnimationKit.title),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteAnimationKit?.title]);

  const selectKit = async (animationKit: AnimationKitDefinition) => {
    setSelectedAnimationKit(animationKit);
    await applyAnimations(animationKit);
  };

  const globalAnimationsKits = animationKits.filter(
    (kit) => kit.isGlobalAnimationsKit && !kit.appliedTo,
  );

  const sgAnimationsKits = animationKits.filter(
    (kit) => !kit.isGlobalAnimationsKit,
  );

  return (
    <section className={styles.animationKitsList}>
      <>
        {globalAnimationsKits.map((animationKit, index) => (
          <div
            className={styles.animationKitButton}
            key={`animation-kit-${index}`}
          >
            <TextButton
              onMouseEnter={() => startPreview(animationKit)}
              onMouseLeave={() => stopPreview()}
              className="btn-confirm-secondary"
              onClick={() => selectKit(animationKit)}
              underline={
                selectedAnimationKit === animationKit
                  ? 'always'
                  : 'onMouseHover'
              }
              shouldTranslate={false}
            >
              {animationKit.title}
            </TextButton>
          </div>
        ))}
        <Divider direction="horizontal" long />
        {sgAnimationsKits.map((animationKit, index) => (
          <div
            className={styles.animationKitButton}
            key={`animation-kit-${index}`}
          >
            <TextButton
              onMouseEnter={() => startPreview(animationKit)}
              onMouseLeave={() => stopPreview()}
              className="btn-confirm-secondary"
              onClick={() => selectKit(animationKit)}
              underline={
                selectedAnimationKit === animationKit
                  ? 'always'
                  : 'onMouseHover'
              }
              shouldTranslate={false}
            >
              {animationKit.title}
            </TextButton>
          </div>
        ))}
      </>
    </section>
  );
};

export const GlobalAnimationsPanel = connectWithScope(
  () => AnimationsScope,
  GlobalAnimationsComponent,
  mapStateToProps,
  mapDispatchToProps,
);

// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';

const { setSessionUserPreferences } = stateManagement.userPreferences.actions;
const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;
const { getData } = stateManagement.components.selectors;

const mapStateToProps = ({ editorAPI }, props) => {
  const state = editorAPI.store.getState();
  const compRef = props.selectedComponents[0];
  const compId = compRef.id;
  return {
    imageData: getData(props.selectedComponents, editorAPI.dsRead),
    lastCustomMaskId: getSessionUserPreferences(
      `mask-crop-custom-svg-${compId}`,
    )(state),
    svgId: editorAPI.imageCrop.getCropArea().svgId,
  };
};

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;
const getEditorState = (dispatch, getState) => getState();

const mapDispatchToProps = (dispatch, props) => {
  const editorAPI = dispatch(getEditorAPI);
  const state = dispatch(getEditorState);
  const compRef = props.selectedComponents[0];
  const compId = compRef.id;

  return {
    mediaManager: editorAPI.mediaServices.mediaManager,
    setFocusToEditor: editorAPI.mediaServices.setFocusToEditor,
    setCropArea: props.setCropData || editorAPI.imageCrop.setCropArea,
    getCropArea:
      props.getCropData ||
      editorAPI.imageCrop.getCropArea.bind(editorAPI.imageCrop, true),
    getMMgrPath: (pathKey) => getSessionUserPreferences(pathKey)(state),
    setMMgrPath: (pathKey, path) =>
      dispatch(setSessionUserPreferences(pathKey, path)),
    setLastCustomMaskId: (svgId) =>
      dispatch(
        setSessionUserPreferences(`mask-crop-custom-svg-${compId}`, svgId),
      ),
    onClose: props.onClose,
    getSVG: editorAPI.documentServices.media.vectorImage.getSvgFromCache,
  };
};

export { mapStateToProps, mapDispatchToProps };

import type { PublicApiKey, SlotKey } from '#packages/apilib';
import type { createAppsPanelSectionsApi } from './appsPanelSectionsApi';
import type { AppsPanelSection } from './types';

export const AppsPanelSectionsApiKey: PublicApiKey<
  typeof createAppsPanelSectionsApi
> = {
  name: 'AppsPanelSectionsApi',
  public: true,
};

export const AppsPanelSectionsSlot: SlotKey<AppsPanelSection> = {
  name: 'appsPanelSection',
};

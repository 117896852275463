import React from 'react';
import ReactDOM from 'react-dom';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import type { WithDoubleClickPrevention } from '#packages/util';
import { SafeInjectHtml } from '#packages/util';
import type { DraggableItem } from '#packages/addPanelInfra';

import {
  TextLabel,
  ContextMenu,
  ContextMenuAction,
  Symbol,
} from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';

const PREVIEW_WIDTH = 234;
const PREVIEW_HEIGHT_LANDSCAPE = 90;
const PREVIEW_HEIGHT_PORTRAIT = 144;

interface IPosition {
  x: number;
  y: number;
}

interface ISnapshotItemProps {
  id: string;
  name: string;
  width: number;
  height: number;
  previewHTML: string | null;
  pasteItem: (
    id: string,
    shouldApplyTheme: boolean,
    pastePosition: IPosition,
    addingMethod: 'drag' | 'click',
  ) => void;
  isOptimistic: boolean;
  fetchItemPreview: (id: string) => void;
  renameItem: (id: string, name: string) => void;
  deleteItem: (id: string, name: string) => void;
  onItemDrag: (e: React.MouseEvent, item: DraggableItem) => void;
}

interface ISnapshotItemState {
  isContextMenuOpen: boolean;
  isLandscape: boolean;
  scale: number;
}

const isIntersectionObserverSupported = (): boolean =>
  typeof window !== 'undefined' && Boolean(window.IntersectionObserver);

class SnapshotItem extends React.PureComponent<
  ISnapshotItemProps & WithDoubleClickPrevention,
  ISnapshotItemState
> {
  static displayName = 'SnapshotItem';

  constructor(props: AnyFixMe) {
    super(props);

    const isLandscape = props.width / props.height > 1.5;
    const previewHeight = isLandscape
      ? PREVIEW_HEIGHT_LANDSCAPE
      : PREVIEW_HEIGHT_PORTRAIT;
    const scale = Math.min(
      1,
      PREVIEW_WIDTH / props.width,
      previewHeight / props.height,
    );

    this.state = {
      isContextMenuOpen: false,
      isLandscape,
      scale,
    };
  }

  previewRef: React.RefObject<HTMLDivElement> = React.createRef();
  observer: IntersectionObserver;

  componentDidMount() {
    if (!this.props.previewHTML) {
      if (isIntersectionObserverSupported()) {
        this.observeOnViewed();
      } else {
        this.onViewed();
      }
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  observeOnViewed() {
    this.observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          this.observer.disconnect();
          this.onViewed();
        }
      },
      {
        threshold: 0,
      },
    );

    if (this.previewRef.current) {
      this.observer.observe(this.previewRef.current);
    }
  }

  onViewed() {
    this.props.fetchItemPreview(this.props.id);
  }

  onMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();

    const { id, width, height, onItemDrag, isAnotherMouseActionAllowed } =
      this.props;

    if (!onItemDrag || !isAnotherMouseActionAllowed(e)) {
      return;
    }

    const item = {
      rect: this.getPreviewDimensions(),
      structure: {
        layout: {
          width,
          height,
        },
      },
      section: 'snapshot',
      itemId: id,
      onDrop: this.onDrop,
    };

    onItemDrag(e, item);
  };

  onDrop = (pastePosition: IPosition) => {
    this.props.pasteItem(this.props.id, false, pastePosition, 'drag');
  };

  onAdd = (e: React.MouseEvent) => {
    if (this.props.isAnotherMouseActionAllowed(e)) {
      this.props.pasteItem(this.props.id, false, null, 'click');
    }

    this.props.setClickTimeStamp(e);
  };

  onAddAndApplyTheme = (e: React.MouseEvent) => {
    if (this.props.isAnotherMouseActionAllowed(e)) {
      this.props.pasteItem(this.props.id, true, null, 'click');
    }

    this.props.setClickTimeStamp(e);
  };

  onRename = () => {
    this.props.renameItem(this.props.id, this.props.name);
  };

  onDelete = () => {
    this.props.deleteItem(this.props.id, this.props.name);
  };

  onContextMenuToggle = (isContextMenuOpen: boolean) => {
    this.setState({ isContextMenuOpen });
  };

  getContextMenu() {
    return [
      {
        id: 'add',
        icon: 'addAction',
        name: 'add_panel_MyDesigns_design_more_add_tooltip',
        onClick: this.onAdd,
      },
      {
        id: 'addAndApplyTheme',
        icon: 'applyTo',
        name: 'add_panel_MyDesigns_design_more_add_site_theme_tooltip',
        onClick: this.onAddAndApplyTheme,
      },
      {
        id: 'rename',
        icon: 'renameAction',
        name: 'add_panel_MyDesigns_design_more_rename_tooltip',
        onClick: this.onRename,
      },
      {
        id: 'delete',
        icon: 'deleteAction',
        name: 'add_panel_MyDesigns_design_more_delete_tooltip',
        onClick: this.onDelete,
      },
    ];
  }

  getPreviewDimensions() {
    const previewElement = ReactDOM.findDOMNode(this.previewRef.current) as any;
    const { left, top, width, height } = previewElement.getBoundingClientRect();

    return { left, top, width, height };
  }

  render() {
    const { name, previewHTML, isOptimistic } = this.props;

    const { isContextMenuOpen, isLandscape, scale } = this.state;

    return (
      <div className="snapshot-section-item" data-aid={`wrapper-${name}`}>
        <TextLabel
          className="section-section-item-name"
          automationId="snapshot_section_item-name"
          enableEllipsis={true}
          shouldTranslate={false}
          type="T02"
          value={name}
        />

        <div
          className={util.cx({
            'snapshot-section-preview-wrapper': true,
            'snapshot-section-preview-wrapper_landscape': isLandscape,
            'snapshot-section-preview-wrapper_active': isContextMenuOpen,
          })}
        >
          <div
            className="snapshot-section-preview"
            ref={this.previewRef}
            onClick={this.onAdd}
            data-aid={name}
            onMouseDown={this.onMouseDown}
          >
            <div className="snapshot-section-preview__inner">
              <SafeInjectHtml
                tag="div"
                className="snapshot-section-preview__snapshot"
                style={{
                  transform: `translate(-50%, -50%) scale(${scale},${scale})`,
                }}
                html={previewHTML}
              />
            </div>
            <div className="snapshot-section-preview__overlay" />
          </div>
          <ContextMenu
            className="snapshot-section-context-menu flat"
            disabled={isOptimistic}
            onToggle={this.onContextMenuToggle}
          >
            {this.getContextMenu().map((menuItem) => (
              <ContextMenuAction
                automationId={menuItem.id}
                key={menuItem.id}
                onClick={menuItem.onClick}
              >
                <Symbol>
                  <Symbols.symbol name={menuItem.icon} />
                </Symbol>
                <span>{translate(menuItem.name)}</span>
              </ContextMenuAction>
            ))}
          </ContextMenu>
        </div>
      </div>
    );
  }
}

export default util.hoc.withDoubleClickPrevention<ISnapshotItemProps>(
  SnapshotItem,
);

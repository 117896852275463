import type { EditorAPI } from '#packages/editorAPI';
import * as util from '#packages/util';
import type { CompRef } from '@wix/document-services-types';
import * as platformEvents from 'platformEvents';

const { isAppWidget } = util.appStudioUtils;

function getGfppNotifyUtils(
  editorAPI: EditorAPI,
  componentRef: CompRef,
  applicationId: number,
) {
  const getWidgetGfppClickedEvent = (
    {
      id,
      targetRef,
    }: {
      id: string;
      targetRef: CompRef;
    },
    origin: string,
  ) => {
    return platformEvents.factory.widgetGfppClicked({
      id,
      componentRef: targetRef || componentRef,
      gfppOrigin: origin,
    });
  };

  const getComponentGfppClickedEvent = ({
    id,
    targetRef,
  }: {
    id: string;
    targetRef: CompRef;
  }) => {
    const connection =
      editorAPI.platform.controllers.connections.getPrimaryConnection(
        targetRef,
      );

    return platformEvents.factory.componentGfppClicked({
      id,
      componentRef: targetRef,
      controllerRef: connection.controllerRef,
      role: connection.role,
    });
  };

  function notifyGfppButtonClicked(
    {
      actionId: id,
      targetRef,
    }: {
      actionId: string;
      targetRef: CompRef;
    },
    origin: string,
  ) {
    const clickEvent =
      targetRef && !isAppWidget(editorAPI, targetRef)
        ? getComponentGfppClickedEvent({ id, targetRef })
        : getWidgetGfppClickedEvent({ id, targetRef }, origin);
    editorAPI.platform.notifyApplication(applicationId, clickEvent);
  }

  return { notifyGfppButtonClicked };
}

export default getGfppNotifyUtils;

import React from 'react';
import InteractionNav from './interactionNav';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import { getOverriddenInteractionsState } from '../overrideDefinitions/interactionsOverrideDefinitions';

import type { InteractionDef } from '#packages/stateManagement';
import type { CompRef, InteractionType } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const { getSelectedCompsRefs } = stateManagement.selection.selectors;
const { getVariantId } = stateManagement.interactions.selectors;

interface InteractionNavControlsProps
  extends InteractionNavControlsDispatchProps,
    InteractionNavControlsStateProps {
  compInteraction: InteractionDef;
  compRef: CompRef;
  componentUIColor?: string;
  considerCompControlsPosition?: boolean;
  handleDragMouseDown?: (event: React.MouseEvent) => void;
}

interface InteractionNavControlsDispatchProps {
  enterInteraction: (
    interactionName: InteractionType,
    compRef: CompRef,
  ) => void;
  selectComp: (compRef: CompRef) => void;
}

interface InteractionNavControlsStateProps {
  isTriggerSelected: boolean;
}

const InteractionsNavControls = ({
  compInteraction,
  compRef,
  enterInteraction,
  handleDragMouseDown,
  selectComp,
  isTriggerSelected,
  componentUIColor,
  considerCompControlsPosition,
}: InteractionNavControlsProps) => {
  return (
    <>
      <InteractionNav
        considerCompControlsPosition={considerCompControlsPosition}
        className="interaction"
        handleDragMouseDown={(e) => {
          e.stopPropagation();
        }}
        componentUIColor={componentUIColor}
        handleClick={() => {
          if (!isTriggerSelected) {
            selectComp(compRef);
          }
          enterInteraction(compInteraction.name, compRef);
        }}
        label={compInteraction.displayNameKey}
        symbolName="interaction_light_small"
      />
      <InteractionNav
        considerCompControlsPosition={considerCompControlsPosition}
        className="regular"
        componentUIColor={componentUIColor}
        handleClick={() => {
          if (!isTriggerSelected) {
            selectComp(compRef);
          }
        }}
        handleDragMouseDown={handleDragMouseDown}
        label="interactions_element_regular_trigger_label"
      />
    </>
  );
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): InteractionNavControlsDispatchProps => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    enterInteraction: (interactionName: InteractionType, compRef: CompRef) => {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.ENTER_INTERACTIONS,
      );
      const triggerRef = editorAPI.columns.isStrip(compRef)
        ? editorAPI.columns.getColumnIfStripIsSingleColumn(compRef)
        : compRef;
      const interactionState = getOverriddenInteractionsState(
        editorAPI,
        triggerRef,
      );
      dispatch(
        stateManagement.interactions.actions.enterInteraction(
          interactionName,
          triggerRef,
          interactionState,
        ),
      );
      const compType = editorAPI.components.getType(compRef);
      editorAPI.bi.event(coreBi.events.interactions.hoverbox_change_tab, {
        interaction_id: getVariantId(editorAPI.store.getState()),
        component_id: compRef.id,
        component_type: compType,
        tab_name: interactionName,
      });
    },
    selectComp: (compRef: CompRef) => {
      editorAPI.selection.selectComponentByCompRef(compRef);
    },
  };
};

const mapStateToProps = (
  { editorAPI, state }: AnyFixMe,
  ownProps: AnyFixMe,
): InteractionNavControlsStateProps => {
  const { compRef } = ownProps;
  const selectedComponents = getSelectedCompsRefs(state);
  const isTriggerSelected =
    selectedComponents.length === 1 &&
    editorAPI.utils.isSameRef(selectedComponents, compRef);
  return {
    isTriggerSelected,
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(InteractionsNavControls);

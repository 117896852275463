// @ts-nocheck
import React from 'react';
import * as UI from '#packages/baseUI';
import { cx } from '#packages/util';

export default class extends React.Component {
  static displayName = 'textLinkControl';

  render() {
    return (
      <span>
        {!this.shouldRenderDropDown() ? (
          <UI.toggleButton key="linkToggleButton" {...this.props} />
        ) : null}

        {this.shouldRenderDropDown() ? (
          <UI.tooltip
            value={this.props.tooltipValue}
            styleType={this.props.tooltipStyleType}
            key="textLinkDropdown"
          >
            <span className={cx({ small: this.props.smallSize })}>
              <UI.dropDown.select
                ref="dropdown"
                shouldTranslate={false}
                onChange={this.handleChange}
                optionsWidth={40}
                toggleIcon={false}
                value="link"
                setSelectedAnyway={true}
                onExpand={this.props.onExpand}
                doNotFocus={true}
                className="text-link-dd"
              >
                <UI.dropDown.option value="unlink" className="link-dd-option">
                  <div
                    className={cx({
                      'link-icon-container': true,
                      secondary: this.props.secondaryColors,
                      small: this.props.smallSize,
                    })}
                  >
                    <UI.symbol name="textUnlink" key="textUnlink" />
                  </div>
                </UI.dropDown.option>
                <UI.dropDown.option value="link" className="link-dd-option">
                  <div
                    className={cx({
                      'link-icon-container': true,
                      secondary: this.props.secondaryColors,
                      small: this.props.smallSize,
                    })}
                  >
                    <UI.symbol name="textLink" key="textLink" />
                  </div>
                </UI.dropDown.option>
              </UI.dropDown.select>
            </span>
          </UI.tooltip>
        ) : null}
      </span>
    );
  }

  shouldRenderDropDown = () => {
    return this.props.value === true && !this.props.disabled;
  };

  handleChange = (value) => {
    if (value === 'unlink') {
      this.props.onUnlink();
    } else if (value === 'link') {
      this.props.onChange();
    }
  };

  isExpanded = () => {
    return this.refs.dropdown.isExpanded();
  };
}

import pagesSelectorPanel from './pagesSelector/pagesSelectorPanel';
import exampleToolPanel from './exampleToolPanel';
import LinkPanel from './linkPanel/LinkPanel';
import * as colorPicker from './colorPicker/colorPicker';
import changeQabIcon from './iconPicker/changeQabIcon';
import languageSupport from './languageSupport/languageSupport';
import mobileActionBarSubPanel from './mobileActionBarSubPanel/mobileActionBarSubPanel';
import fontsUploadPanel from './fontsManager/fontsUploadPanel';
import deleteFontMsg from './fontsManager/deleteFontMsg';
import cancelFontUploadMsg from './fontsManager/cancelFontUploadMsg';
import gridAddColumnPanel from './grid/gridAddColumnPanel';
import customSignupSettingsPanel from './customSignupSettings/customSignupSettingsPanel';
import rolesManagerPanel from './rolesManager/rolesManager';
import layersPanel from './layers/layersPanel';
import { MultiselectPanel } from './multiselect/multiselect';
import type {
  IMultiselectPanelGroup,
  IMultiselectPanelSection,
  IMultiselectPanelItem,
  IMultiselectPanelOwnProps,
} from './multiselect/multiselect.types';
import imageCropSVGPanel from './imageMaskPanel/imageCropSVGPanel';
import apiDefinitionEvent from './apiDefinitionEvent/apiDefinitionEvent';
import stylableEditor from './stylableEditor/stylableEditor';
import addA11yAttribute from './a11yAttributePanel/addA11yAttribute';
import editorElements from './editorElements';

import type { IReplaceRemovePanelProps } from './colorPicker/replaceColorPanel/replaceColorPanel';

export default {
  pagesSelectorPanel,
  exampleTool: exampleToolPanel,
  linkPanel: LinkPanel,
  languageSupport,
  colorPicker,
  changeQabIcon,
  mobileActionBarSubPanel,
  fontsUploadPanel,
  deleteFontMsg,
  cancelFontUploadMsg,
  gridAddColumnPanel,
  customSignupSettingsPanel,
  rolesManagerPanel,
  layersPanel,
  imageCropSVGPanel,
  MultiselectPanel,
  apiDefinitionEvent,
  stylableEditor,
  addA11yAttribute,
  editorElements,
};

export type {
  IMultiselectPanelGroup,
  IMultiselectPanelSection,
  IMultiselectPanelItem,
  IMultiselectPanelOwnProps,
  IReplaceRemovePanelProps,
};

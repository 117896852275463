import React from 'react';
import { LowerDeck, UpperDeck } from './decs';

function editModeLayout(this: AnyFixMe) {
  const TopBanner = this.props.contributedTopBannerComponent;
  return (
    <>
      {this.props.contributedTopBannerComponent && <TopBanner />}

      <UpperDeck onClick={this.handleUpperDeckClick}>
        <div className="top-bar-left-section">
          {this.renderLogo()}
          {this.shouldDisplayWorkspaceModesToggle()
            ? this.renderWorkspaceModesToggle()
            : null}
          {this.renderMenuBar('left')}
          {this.shouldDisplayLogoutButton() ? this.renderLogout() : null}
        </div>
        <div className="top-bar-center-section" />
        <div className="top-bar-right-section top-bar-front-section">
          {this.renderMenuBar('right')}
          {this.renderBranchIndicator()}
          {this.renderEditorModeActionsButtons()}
          {this.renderRightSideContributions()}
        </div>
      </UpperDeck>
      <LowerDeck>
        <div className="top-bar-left-section with-separators top-bar-front-section">
          {this.renderLanguageSelect()}
          {this.renderQuickNavigation()}
          {this.renderDeviceSwitch()}
        </div>
        <div className="top-bar-center-section">{this.renderUrlBar()}</div>
        <div className="top-bar-right-section with-separators">
          {this.renderUndoRedo()}
          {this.renderZoomOut()}
          {this.renderTools()}
          {this.renderSearch()}
        </div>
      </LowerDeck>
    </>
  );
}

export default editModeLayout;

// @ts-nocheck
import createReactClass from 'create-react-class';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import * as helpIds from '#packages/helpIds';
import { translate } from '#packages/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'iTunesLinkMaker',
  render() {
    return (
      <FocusPanelFrame
        title={translate('iTunes_Link_Maker_Title')}
        panelName={this.props.panelName}
        helpId={helpIds.EXTERNAL.SEARCH_ITUNES}
        className="itunes-link-maker"
      >
        <iframe
          src="https://linkmaker.itunes.apple.com"
          className="content-iframe"
        />
      </FocusPanelFrame>
    );
  },
});

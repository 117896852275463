import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Tooltip } from '@wix/wix-base-ui';
import { Rename } from '@wix/wix-ui-icons-common/classic-editor';

import { hoc, cx } from '#packages/util';

// TODO: check why exportation didn't work in https://github.com/wix-private/wix-design-systems/pull/9497
import type { TextProps } from '@wix/wix-base-ui';

import { AUTOMATION_IDS } from './constants';
import { TRANSLATIONS } from '../../translations';
import styles from './SectionName.scss';

import {
  type SectionNameOwnProps,
  type SectionNameStateProps,
  type SectionNameDispatchProps,
  mapStateToProps,
  mapDispatchToProps,
} from './SectionNameMapper';

export interface SectionNameProps
  extends SectionNameOwnProps,
    SectionNameStateProps,
    SectionNameDispatchProps {}

const SectionNameComponent: React.FC<SectionNameProps> = ({
  name,
  isSelected,
  isMobile,
  isZoomMode,
  canSelectSection,
  openRenamePanel,
  selectSectionIfNeeded,
  className,
}) => {
  const [translate] = useTranslation();

  const isSelectedStyle = isSelected || !canSelectSection;

  const textProps: Omit<TextProps, 'children'> = {
    size: isZoomMode ? 'tiny' : 'small',
    skin: isSelectedStyle ? 'standard' : 'premium',
    light: isSelectedStyle,
    shouldTranslate: false,
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    openRenamePanel(buttonRef.current.getBoundingClientRect(), 'label');
  };

  const handleContainerClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (!isZoomMode) {
        selectSectionIfNeeded();
      }
    },
    [isZoomMode, selectSectionIfNeeded],
  );

  const handleContainerDoubleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    openRenamePanel(
      buttonRef.current.getBoundingClientRect(),
      'labelDoubleClick',
    );
  };

  const stopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const nameRef = useRef<HTMLDivElement>(null);

  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const { width: nameWidth } = nameRef.current.getBoundingClientRect();
    setMaxWidth(nameWidth);
  }, [name]);

  return (
    <div
      data-hook={AUTOMATION_IDS.NAME_LABEL}
      className={cx(styles.container, className, {
        [styles.selected]: isSelectedStyle,
        [styles.mobile]: isMobile,
        [styles.zoomMode]: isZoomMode,
      })}
      style={
        {
          '--maxNameWidth': `${maxWidth}px`,
        } as React.CSSProperties
      }
      onMouseDown={stopPropagation}
      onMouseMove={stopPropagation}
      onClick={handleContainerClick}
      onDoubleClick={handleContainerDoubleClick}
    >
      <div className={styles.hidden} ref={nameRef}>
        <Text {...textProps} enableEllipsis={false}>
          {translate(TRANSLATIONS.SECTION_NAME, { section_name: '' })}
        </Text>
        <div className={styles.name}>
          <Text {...textProps} enableEllipsis={false}>
            {name}
          </Text>
        </div>
      </div>

      <div className={styles.nameContainer}>
        <div className={styles.fullName}>
          <Text
            {...textProps}
            customEllipsisProps={{
              marginTop: 10,
              alignment: 'TOP',
            }}
          >
            {translate(TRANSLATIONS.SECTION_NAME, { section_name: name })}
          </Text>
        </div>
      </div>

      {!isMobile && (
        <Tooltip
          openDelay={300}
          alignment="TOP"
          maxWidth={220}
          content={translate(TRANSLATIONS.RENAME_TOOLTIP)}
          marginTop={6}
          shouldTranslate={false}
        >
          <button
            data-hook={AUTOMATION_IDS.RENAME_BTN}
            ref={buttonRef}
            className={styles.button}
            onClick={handleButtonClick}
          >
            <Rename className={styles.icon} />
          </button>
        </Tooltip>
      )}
    </div>
  );
};

export const SectionName = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SectionNameComponent);

import React from 'react';
import { cx } from '#packages/util';

import { TextLabel } from '@wix/wix-base-ui';

import * as BaseUI from '#packages/baseUI';
import { workspace } from '#packages/util';

interface SectionHeaderProps {
  title: string;
  headerActions?: React.ReactNode[];
  tooltipTitle?: string;
  tooltipText?: string;
  disabledHeaderMargins?: boolean;
  disabledContentPaddings?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
  headerActions,
  tooltipTitle,
  tooltipText,
  disabledHeaderMargins,
}) => {
  if (workspace.isNewWorkspaceEnabled()) {
    return (
      <BaseUI.SectionHeader
        className={cx('new-workspace-media-manager-panel-section-header', {
          'section-header-without-margins': disabledHeaderMargins,
        })}
        title={title}
        tooltipTitle={tooltipTitle}
        tooltipText={tooltipText}
      />
    );
  }

  return (
    <div className="media-manager-panel-section__header-wrapper">
      <div className="media-manager-panel-section__header">
        <TextLabel
          value={title}
          shouldTranslate={false}
          ellipsisProps={{ disabled: true }}
          type="T02"
          className="media-manager-panel-section__header-text"
        />
        {headerActions?.length ? (
          <span className="media-manager-panel-section__header-actions">
            {headerActions}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default SectionHeader;

import React from 'react';
import * as panels from '#packages/panels';
import * as leftBar from '#packages/leftBar';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import {
  Composites,
  CustomScroll,
  Illustration,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import HiddenInteractionItem from './hiddenInteractionItem';
import { symbol } from '@wix/santa-editor-symbols';
import {
  mapStateToProps,
  mapDispatchToProps,
  type InteractionsHiddenComponentsStateProps,
  type InteractionsHiddenComponentsDispatchProps,
} from './interactionsHiddenComponentsMapper';

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

class InteractionsHiddenComponentsPure extends React.Component<
  InteractionsHiddenComponentsStateProps &
    InteractionsHiddenComponentsDispatchProps
> {
  static displayName = 'InteractionsHiddenComponents';
  render() {
    const MySymbol = symbol;
    const hiddenComponentsLength = this.props.hiddenComponents.length;
    const LeftPanelFrame = isNewWorkspace
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    return (
      <LeftPanelFrame
        helpId="8a2a0c28-656c-4d8e-82df-7f50c1e9f0af"
        panelClass="interactions-hidden-components interactions-hidden-components-panel"
        panelIndex={this.props.panelIndex}
        panelName={this.props.panelName}
        label={`${translate(
          'interactions_hidden_header',
        )} (${hiddenComponentsLength})`}
        onContextMenu={(e: Event) => e.preventDefault()}
      >
        <div className="panel-content-wrapper">
          {hiddenComponentsLength > 0 ? (
            <CustomScroll flex="1">
              <div className="hidden-items-container">
                <div className="container-comps">
                  {this.props.hiddenComponents.map((hiddenComponent) => (
                    <HiddenInteractionItem
                      {...hiddenComponent}
                      key={hiddenComponent.compRef.id}
                      showComponent={this.props.showComponent}
                      numOfHiddenItems={this.props.hiddenComponents.length}
                    />
                  ))}
                </div>
              </div>
            </CustomScroll>
          ) : (
            <Composites.RichTextWithIllustrationVertical className="content-width-md">
              <Illustration>
                <MySymbol name="interactionsHiddenEmptyState" />
              </Illustration>
              <Composites.RichTextLabeled>
                <TextLabel
                  type="T09"
                  value="interactions_hidden_empty_state_title"
                />
                <RichText className="light" type="T07">
                  {translate('interactions_hidden_empty_state_text')}
                </RichText>
              </Composites.RichTextLabeled>
            </Composites.RichTextWithIllustrationVertical>
          )}
          <footer className="hidden-panel-message">
            <RichText>
              <p>
                {translate('interactions_hidden_element_accessibility_tooltip')}
                <span>
                  <a onClick={this.props.onLearnMore}>
                    {` ${translate(
                      'interactions_hidden_element_accessibility_tooltip_link',
                    )}`}
                  </a>
                </span>
              </p>
            </RichText>
          </footer>
        </div>
      </LeftPanelFrame>
    );
  }
}

const InteractionsHiddenComponents = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(InteractionsHiddenComponentsPure);

export default InteractionsHiddenComponents;

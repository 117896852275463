// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';

const pinModeSelectors = stateManagement.pinMode.selectors;
const pinModeActions = stateManagement.pinMode.actions;
let didAttemptDrag, initMousePosition, selectedComp, editorAPI;
const DRAG_LIMIT = 5;

const selectionSelectors = stateManagement.selection.selectors;

function wasDragAttempted(diffX, diffY) {
  return diffX >= DRAG_LIMIT || diffY >= DRAG_LIMIT;
}

function startDragAttempt(_editorAPI, params) {
  editorAPI = _editorAPI;
  didAttemptDrag = false;
  initMousePosition = params.initMousePosition;
  selectedComp = params.selectedComp;
}

function onDragAttempt(event) {
  const diffX = Math.abs(event.pageX - initMousePosition.x);
  const diffY = Math.abs(event.pageY - initMousePosition.y);
  didAttemptDrag = didAttemptDrag || wasDragAttempted(diffX, diffY);
  if (didAttemptDrag) {
    editorAPI.selection.setIsMouseUpSelectionEnabled(false);
  }
}

function endDragAttempt() {
  const { store } = editorAPI;
  const editorState = store.getState();
  const isPinMode = pinModeSelectors.isOpen(editorState);
  if (
    didAttemptDrag &&
    editorAPI.components.layout.isPinned(selectedComp) &&
    !isPinMode
  ) {
    store.dispatch(pinModeActions.open('on_drag'));
    store.dispatch(
      stateManagement.bi.actions.event(coreBi.events.pinToScreen.DRAG_ATTEMPT, {
        component_type: selectionSelectors.getSelectedComponentType(
          editorState,
          editorAPI.dsRead,
        ),
        component_id: selectedComp[0]?.id,
      }),
    );
  }
}

export default {
  start: startDragAttempt,
  on: onDragAttempt,
  end: endDragAttempt,
  type: constants.MOUSE_ACTION_TYPES.DRAG_ATTEMPT,
};

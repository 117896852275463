import { translateIfExists } from '../../utils';
import {
  dealerService,
  ESTATES,
  parseResponseOffers,
  generateActionFromDealerData,
} from './dealer.common';

import type { EditorAPI } from '#packages/editorAPI';
import type { DealerAppPageData, DealerRawAction } from './dealer.types';

interface DealerOfferAssetShape {
  appDefinitionId: string;
  headerAppImage: string;
  headerAppName: string;
  headerAppDescription: string;

  mainAction: DealerRawAction;
  mainActionIcon: string;

  secondaryAction: DealerRawAction;
  secondaryActionIcon: string;
}

const convertDealerResponse = (
  editorAPI: EditorAPI,
  offer: DealerOfferAssetShape,
): DealerAppPageData => {
  const { mainAction: primaryCTA, secondaryAction: secondaryCTA } = offer;
  const primaryCTACallback = primaryCTA
    ? generateActionFromDealerData(editorAPI, primaryCTA)
    : undefined;
  const secondaryCTACallback = secondaryCTA
    ? generateActionFromDealerData(editorAPI, secondaryCTA)
    : undefined;

  const primaryAction1 = {
    iconUrl: offer.mainActionIcon,
    onClick: primaryCTACallback,
  };

  const primaryAction2 = {
    iconUrl: offer.secondaryActionIcon,
    onClick: secondaryCTACallback,
  };

  return {
    appDefId: offer.appDefinitionId,
    imageUrl: offer.headerAppImage,
    title: translateIfExists(offer.headerAppName),
    description: translateIfExists(offer.headerAppDescription),

    primaryAction1,
    primaryAction2,
  };
};

export const loadDealerAppsData = async (metaSiteInstance: string) => {
  const response = await dealerService({
    Authorization: metaSiteInstance,
  }).listOffers({ realEstateId: ESTATES.MANAGE_APPS });

  return parseResponseOffers<DealerOfferAssetShape>(response.offers);
};

export const transformDealerAppsData = (
  editorAPI: EditorAPI,
  assets: DealerOfferAssetShape[],
) => {
  return assets
    .map((asset) => convertDealerResponse(editorAPI, asset))
    .reduce<Record<string, DealerAppPageData>>((acc, cur) => {
      acc[cur.appDefId] = cur;
      return acc;
    }, {});
};

import * as util from '#packages/util';
import { EditorAPIKey } from '#packages/apis';
import type { Shell } from '#packages/apilib';

const { applyForFirstAndThrowIfMulti } = util.array;

export function createMediaApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  function getSvgOriginalAspectRatio(compRefs: AnyFixMe) {
    return applyForFirstAndThrowIfMulti(
      'getSvgOriginalAspectRatio',
      editorAPI.dsRead.media.vectorImage.getSvgOriginalAspectRatio,
      compRefs,
    );
  }

  return {
    vectorImage: {
      getSvgOriginalAspectRatio,
    },
  };
}

import _ from 'lodash';
import * as helpIds from '#packages/helpIds';
import * as stateManagement from '#packages/stateManagement';
import * as tpa from '#packages/tpa';
import constants from '#packages/constants';
import type {
  MapDispatchToPropsFunction,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  OnlineProgramsWelcomeDispatchProps,
  OnlineProgramsWelcomeOwnProps,
  OnlineProgramsWelcomeStateProps,
} from './types';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

export const mapStateToProps: MapStateToProps<
  OnlineProgramsWelcomeStateProps,
  OnlineProgramsWelcomeOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  OnlineProgramsWelcomeDispatchProps,
  OnlineProgramsWelcomeOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  const openOnlineProgramsAppManager = () => {
    const leavePanelsOpened = true;

    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME,
        {
          selectedAppId:
            tpa.superApps.superAppsConstants.ONLINE_PROGRAMS.APP_DEF_ID,
        },
        leavePanelsOpened,
      ),
    );
  };

  const callback = (_: { error?: boolean }) => {
    openOnlineProgramsAppManager();
  };

  return {
    installOnlineProgramsApp() {
      tpa.superApps.addApp(
        tpa.superApps.superAppsConstants.ONLINE_PROGRAMS.APP_DEF_ID,
        {
          biOrigin: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
          showProgressBar: true,
          platformOrigin: {
            type: EditorType.Classic,
            initiator: InstallInitiator.Editor,
            info: {
              type: InstallationOriginType.AddPanel,
            },
          },
          callback: (data: AnyFixMe) => {
            if (_.isError(data)) {
              callback({ error: true });
            } else {
              const pageId = data?.page?.id;
              if (pageId) {
                editorAPI.pages.navigateTo(pageId, () => {
                  const sectionPointer =
                    tpa.superApps.getSectionRefByPageId(pageId);
                  editorAPI.selection.selectComponentByCompRef(sectionPointer);
                });
              }
              _.attempt(callback);
            }
          },
        },
      );
    },
    openHelpCenter(helpId) {
      return dispatch(stateManagement.panels.actions.openHelpCenter(helpId));
    },
    openEditorXHelpPanel() {
      editorAPI.panelManager.openHelpCenter(
        helpIds.ADD_PANEL.ONLINE_PROGRAMS_WELCOME,
      );
    },
    openAppMarket: () =>
      tpa.appMarketTabService.openAppMarketTab(
        {
          urlParams: {
            slug: tpa.superApps.superAppsConstants.ONLINE_PROGRAMS.APP_DEF_ID,
          },
        },
        editorAPI.panelManager.openPanel,
      ),
  };
};

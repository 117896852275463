import React, { useEffect, useRef, memo, type FC, useState } from 'react';
import experiment from 'experiment';
import {
  TextLabel,
  Button,
  Divider,
  CustomModal,
  TextButton,
  WixBaseUiEnvironmentProvider,
} from '@wix/wix-base-ui';
import { ChevronLeft, ChevronRight } from '@wix/wix-ui-icons-common';
import { CSSTransition } from 'react-transition-group';
import { cx } from '#packages/util';
import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import type { TourTooltipProps } from '@wix/tour-maker';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { TOUR_NAME as ADI_TO_EDITOR } from '../tours/adiToEditorTour.conf';
import { TOUR_NAME as EDITOR_TO_EDITOR } from '../tours/editorToEditorTour.conf';

export enum ButtonSize {
  SMALL = 'small',
  TINY = 'tiny',
}

export enum ButtonType {
  BACK = 'backButton',
  FORWARD = 'forwardButton',
  SKIP = 'skipButton',
}

const toursWithIntroStep = [ADI_TO_EDITOR, EDITOR_TO_EDITOR];

const ToursTooltip: FC<TourTooltipProps> = ({
  activeActionIndex,
  title,
  content,
  customProps: {
    totalSteps: total,
    sendBi,
    stepName: panelName,
    mediaSrc,
    className,
    tourName,
    link,
    customAnimation,
    successAnimation,
    startOrigin,
    stepCounterLabel,
  },
  stopTourFn,
  buttons,
}) => {
  const origin = useRef<string>(startOrigin);
  const findButton = (buttonName: string) =>
    buttons.find((button) => button.key === buttonName);
  const forwardButton = findButton(ButtonType.FORWARD);
  const backButton = findButton(ButtonType.BACK);
  const skipButton = findButton(ButtonType.SKIP);
  const totalSteps = typeof total === 'number' ? total : 0;

  const [animationDone, setAnimationDone] = useState(false);

  useEffect(() => {
    const startTime = Math.round(performance.now());

    sendBi?.(coreBi.events.panels.PANEL_OPENED, {
      panel_name: panelName,
      origin: origin.current,
    });

    return () => {
      const timeSpent = Math.round(performance.now()) - startTime;

      sendBi?.(coreBi.events.panels.PANEL_CLOSED, {
        panel_name: panelName,
        origin: origin.current,
        timeSpent,
      });
    };
  }, [sendBi, panelName]);

  const onCloseButtonClick = () => {
    origin.current = 'close';

    if (skipButton) {
      skipButton.onClick();
    } else {
      stopTourFn();
    }
  };
  const renderVideo = () => (
    <div className="tour-tooltip-panel__media">
      <video width="240px" src={mediaSrc} autoPlay muted loop />
    </div>
  );

  const renderImage = () => (
    <div className="tour-tooltip-panel__media">
      <img
        className="tour-tooltip-panel__media_img"
        src={mediaSrc}
        alt={title || ''}
      />
    </div>
  );
  const renderMedia = (mediaSrc?: string) => {
    if (typeof mediaSrc !== 'string') {
      return null;
    }

    const imageRegexp = /(png|jpeg|jpg|gif)$/;
    const isImage = imageRegexp.test(mediaSrc);

    if (isImage) {
      return renderImage();
    }
    return renderVideo();
  };

  const onNavButtonClick =
    (originOnClick: Function, buttonType: ButtonType) => () => {
      if (hasIntro && !activeActionIndex) {
        origin.current =
          buttonType === ButtonType.FORWARD ? 'take_a_tour' : 'remind_me_later';
      } else {
        origin.current = buttonType;
      }

      originOnClick();
    };

  const hasIntro = toursWithIntroStep.includes(tourName);
  const buttonSize =
    hasIntro && !activeActionIndex ? ButtonSize.SMALL : ButtonSize.TINY;
  const step = hasIntro ? activeActionIndex : activeActionIndex + 1;
  const steps = hasIntro ? totalSteps - 3 : totalSteps;
  const stepName = panelName ?? `step-${activeActionIndex}`;
  const showProgress = hasIntro
    ? step > 0 && step < totalSteps - 2
    : totalSteps > 0;
  const hasRightChevron = Boolean(step) && step < steps;

  return (
    <WixBaseUiEnvironmentProvider
      madefor
      theme="facelift"
      inputBorders={experiment.isOpen(
        'se_components_editorClassic_inputBorders',
      )}
    >
      <div
        data-hook={`tour-tooltip-panel`}
        data-step={`tour-tooltip-${stepName}`}
      >
        <CSSTransition
          unmountOnExit
          in={true}
          appear={true}
          classNames={customAnimation ?? 'tour-tooltip-animation'}
          timeout={800}
          onEntered={() => setAnimationDone(true)}
        >
          <CustomModal
            className={cx('tour-tooltip-panel', {
              [className]: Boolean(className),
            })}
            onCloseButtonClick={onCloseButtonClick}
            footerContent={
              <div
                className={cx('tour-tooltip-panel__nav', {
                  'tour-tooltip-panel__nav-reverse': !step,
                })}
              >
                {successAnimation && animationDone && (
                  <div className="success-animation">
                    <Symbol name="success" />
                  </div>
                )}
                <div className="tour-tooltip-panel__nav-button">
                  {backButton && (
                    <TextButton
                      automationId={`tour-tooltip-button-${ButtonType.BACK}`}
                      className="btn--secondary"
                      onClick={onNavButtonClick(
                        backButton.onClick,
                        ButtonType.BACK,
                      )}
                      prefixIcon={Boolean(activeActionIndex) && <ChevronLeft />}
                      size={ButtonSize.TINY}
                    >
                      {backButton.label}
                    </TextButton>
                  )}
                </div>
                {showProgress && (
                  <div className="tour-tooltip-panel__nav-progress">
                    {translate(
                      stepCounterLabel ??
                        'FTE_tour_adi_to_ed2_panel_step_counter',
                      {
                        step_number: step,
                        total_steps: steps,
                      },
                    )}
                  </div>
                )}
                <div className="tour-tooltip-panel__nav-button">
                  {forwardButton && (
                    <Button
                      automationId={`tour-tooltip-button-${ButtonType.FORWARD}`}
                      className={cx({
                        'btn-sm': buttonSize === ButtonSize.TINY,
                        'btn-md': buttonSize === ButtonSize.SMALL,
                      })}
                      onClick={onNavButtonClick(
                        forwardButton.onClick,
                        ButtonType.FORWARD,
                      )}
                      suffixIcon={hasRightChevron && <ChevronRight />}
                      // @ts-expect-error
                      label="Forward"
                    >
                      {forwardButton.label}
                    </Button>
                  )}
                </div>
              </div>
            }
          >
            {renderMedia(mediaSrc)}
            <TextLabel
              type={'T09'}
              className="tour-tooltip-panel__title"
              enableEllipsis={false}
              value={title}
            />
            <TextLabel
              type={'T02'}
              className="tour-tooltip-panel__subtitle"
              enableEllipsis={false}
              value={content}
            />
            {link && (
              <a
                href={link.href}
                className="tour-tooltip-panel__link"
                target="_blank"
                data-hook="tour-tooltip-link"
              >
                {link.shouldNotTranslate ? link.text : translate(link.text)}
              </a>
            )}
            {totalSteps ? <Divider long /> : null}
          </CustomModal>
        </CSSTransition>
      </div>
    </WixBaseUiEnvironmentProvider>
  );
};

export default memo(ToursTooltip);

import React, { Component, type ComponentType, type ReactElement } from 'react';
import _ from 'lodash';
import constants from '#packages/constants';

import PublishPanel from '../../dropPanel/publishPanel/publishPanel';

const { BUTTONS } = constants.ROOT_COMPS.TOPBAR;

interface WithPublishDropPanelProps {}

interface ComponentWithPublishDropPanelProps {
  dropPanelContent?: ReactElement | null;
  dropPanelKey?: string;
}

const withPublishDropPanel = <P extends WithPublishDropPanelProps>(
  WrappedComponent: ComponentType<P & ComponentWithPublishDropPanelProps>,
) => {
  class WithPublishDropPanel extends Component<WithPublishDropPanelProps> {
    render() {
      const { props } = this;

      return React.createElement(
        WrappedComponent,
        _.defaults({}, props as P, {
          dropPanelKey: BUTTONS.PUBLISH,
          dropPanelContent: <PublishPanel />,
        }),
      );
    }
  }

  return WithPublishDropPanel;
};

export default withPublishDropPanel;

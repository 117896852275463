import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import * as util from '#packages/util';
import dataProvider from './dataProvider';
import {
  upgradeAscendPanel,
  marketingPanel,
  customerManagementPanel,
  analyticsPanel,
  financePanel,
  virtualPhoneNumberPanel,
} from './panels';
import * as utils from './panels/common/utils';
import experiment from 'experiment';

const ascendHeader = {
  displayName: 'ascend-custom-header',
  headerComponent: (
    <div className="ascend-custom-header">
      <symbols.symbol name="ascendLogo" />
    </div>
  ),
};

const renderExtraTabs = () => {
  const extraTabs: {}[] = [
    {
      title: 'AscendButton_BusinessTools_MarketingSEO_Tab',
      template: marketingPanel,
      index: 0,
    },
    {
      title: 'AscendButton_BusinessTools_CustomerManagement_Tab',
      template: customerManagementPanel,
      index: 1,
    },
    {
      title: 'AscendButton_BusinessTools_Finance_Tab',
      template: financePanel,
      index: 2,
    },
    {
      title: 'AscendButton_BusinessTools_Analytics_Tab',
      template: analyticsPanel,
      index: 3,
    },
  ];

  if (experiment.isOpen('se_Ascend_virtualPhoneNumberButton')) {
    extraTabs.push({
      title: 'AscendButton_BusinessTools_BusinessPhoneNumber_Tab',
      template: virtualPhoneNumberPanel,
      index: 4,
    });
  }
  return extraTabs;
};

const renderAscendAppLabel = (removeAscendFeatures: boolean) => {
  return removeAscendFeatures ? (
    translate('AscendButton_marketing_panel_title')
  ) : (
    <symbols.symbol name="ascendLogo_v2" />
  );
};

const getAscendSuperApp = (
  showUpgradeTab: AnyFixMe,
  removeAscendFeatures: boolean,
) => {
  return {
    id: 'ascend',
    appDefId: 'ascend',
    appName: 'ascend',
    firstTimeTour: {
      templateFlow: [] as AnyFixMe[],
      manuallyInstalledFlow: [] as AnyFixMe[],
      promotionalFlow: [] as AnyFixMe[],
    },
    panel: {
      name: constants.SUPER_APPS.ASCEND_PANEL_NAME,
      label: util.workspace.isNewWorkspaceEnabled()
        ? renderAscendAppLabel(removeAscendFeatures)
        : undefined,
      pages: {
        isHidden: true,
      },
      manage: {
        isHidden: true,
      },
      learnMore: {
        title: 'AscendButton_LearnMore_Section_Header',
        section2HeaderViewA: 'AscendButton_LearnMore_HelpCenter_Text',
        section3HeaderViewA: 'AscendButton_LearnMore_MarketingTips_Text',
        section5HeaderViewA: 'AscendButton_LearnMore_VideoSeries_Text',
        data: dataProvider.helpData,
        isBottomTab: true,
        isHidden: removeAscendFeatures,
      },
      addElements: {
        isHidden: true,
      },
    },
    extraTabs: renderExtraTabs(),
    bottomTabs:
      !removeAscendFeatures || showUpgradeTab
        ? [
            {
              title: 'AscendButton_BusinessTools_Upgrade_Tab',
              template: upgradeAscendPanel,
              index: 1,
              isBottomTab: true,
            },
          ]
        : [],
    customHeader: () =>
      util.workspace.isNewWorkspaceEnabled()
        ? null
        : ascendHeader.headerComponent,
    upgradeProps: {
      promotionalItems: dataProvider.ascendUpgradePromotilalListItems,
      onClickUpgradeButton: (editorAPI: AnyFixMe, msid: AnyFixMe) =>
        utils.openAscendPackagePicker(editorAPI, msid),
      buttonText: translate('AscendButton_BusinessTools_Upgrade_Tab'),
    },
    isAppInstalled: () => true,
  };
};

export default { getAscendSuperApp };

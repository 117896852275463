import {
  isComponentScoped,
  getComponentScopeOwner,
  isRepeater,
} from '#packages/documentServices';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

function resolveComponentToBeDrugged(
  editorAPI: EditorAPI,
  compRef: CompRef,
): CompRef {
  const dsRead = editorAPI.dsRead;
  if (
    isComponentScoped(dsRead, compRef) &&
    !isRepeater(dsRead, getComponentScopeOwner(dsRead, compRef))
  ) {
    return resolveComponentToBeDrugged(
      editorAPI,
      getComponentScopeOwner(dsRead, compRef),
    );
  }

  return editorAPI.components.findAncestor(
    compRef,
    (ancestorRef) => !editorAPI.components.is.controlledByParent(ancestorRef),
    { includeSelf: true },
  );
}

function getCompToBeDragged(
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
): CompRef[] {
  const compRefs = selectedComponents.map((compRef) =>
    resolveComponentToBeDrugged(editorAPI, compRef),
  );
  return editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
    compRefs,
  );
}

export { getCompToBeDragged };

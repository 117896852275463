// injection is blocked for this templates
export const INJECTION_BLACK_LIST = new Set([
  'cbf36d3a-49d0-41c2-9482-1bb58d5fdda3', // wix.new
  '6e51b1b9-0fa7-42df-9637-771a4543f4f4', // site creation
  '9da26ed4-eda0-4483-abbc-75f20793b27f', // 1713
  'acbc48ed-1cb4-46ac-bf2a-b663db39909d', // 1713 de
  'e948b9c7-af67-4626-9d16-645d9d932050', // 1713 es
  '6ab0cbaf-2929-4f37-a18e-e879976bb0c4', // 1713 fr
  'e248e78f-ca9c-427d-9a08-61b49f1c6caa', // 1713 ja
  'c9e162e7-c15d-47dc-8166-cc69c663266e', // 1713 pt
  '73247e2e-34f5-411a-91e6-5405664f0100', // 1713 ru
  'cbf36d3a-49d0-41c2-9482-1bb58d5fdda3', // 1786
  '5042be2d-eb50-4f0a-abea-9afe70926fc0', // 1786 de
  '09828ae2-1001-41c9-b813-1a6675eacc3b', // 1786 ja
  'bb092910-0b7d-4c9f-a9f9-33dee816f6b0', // 1786 pt
  'e9e422ae-b809-41c9-b408-c19fd6424e97', // 1786 ru
  '6bcf8568-6fc1-4e87-87f9-13f7c9a05f07', // 1948
  '9673316f-0a12-4ef3-9524-5178f8cedd94', // 1948 pt
  'e8e50339-715b-45cc-8e13-b06df67f651b', // 2010 ja
  'c41d20fe-577c-4c3d-98ff-c8c149faee17', // 2166
  'c736b679-e33b-4183-a4a7-3f82eed98db3', // 2385
  'e29f403d-bab6-4b16-84a4-f29b63d7eb23', // 2402
  '334bd782-cae1-450a-8adf-d8b49408190f', // 2417
  '24440732-2dea-4ab6-8fb5-908167bba6aa', // 2454
  'ce7b40ea-18a8-4225-8bc1-2345396d04c4', // 2457
  'ae4746a3-89cd-479b-89a7-42a10fb9a7ec', // 2457 fr
  '5db8a2fc-6b58-4ce8-9a7a-fbc8d255824a', // 2461
  'f3ffef32-e394-4578-bec7-815c50c3c56d', // 2524
  'e7c10daa-71ad-4fbe-8581-5842b056beb1', // 2524 de
  'dafa1f6e-72c9-4a57-b3d9-2d17d35570ab', // 2524 es
  'd380b0ba-c879-4734-8073-a33176d97df7', // 2524 fr
  '730de52b-b486-48e9-896e-d8af42c834ce', // 2525
  'b3ca8cb7-b914-4466-bfcf-2c2ea9e23b2d', // 2525 de
  '1ef32a66-445b-4089-ad95-958b9647c4d3', // 2525 es
  '71d265cf-fa8d-4768-884a-fee6f3a64b1a', // 2525 fr
  '37306570-907d-470c-b6b9-8c51a4b8b478', // 2527
  'a704c317-be3f-4262-a404-9ba268b4376b', // 2527 de
  'c9821e99-7027-4ba1-acb6-55a9ee97cfcd', // 2527 fr
  '128451e6-cec4-4ed8-bd1f-5e30d46fa2cd', // 2527 pt
  '37e7002d-07af-49b2-a0c2-4e71c58d543a', // 2529
  'e6c976b4-1fda-4007-a4c6-3b74df5eaf03', // 2529 de
  'a140bfc2-00fa-4cd2-a22b-d02127f74722', // 2531
  '15eb5702-b02a-434e-83e8-985482979f01', // 2586
  '78e1c67c-91f6-42ab-ba50-8f9a1afa8237', // 2741
  '9a6c5c0a-0715-46fe-8d42-97976542b103', // 2741 de
  '5a082309-254c-4cf8-9055-08efa979da42', // 2741 es
  'aab63bbb-9750-4a49-9325-a6a9dbaac54c', // 2741 fr
  '1912882d-06a3-427c-8f17-a8b139907534', // 2741 ja
  '63b13ea0-f1d8-4a11-b8be-fc9f9258013b', // 2741 pt
  'c90a1726-f8d6-40ce-928b-73503afd6aa1', // 2741 ru
  'da1cc3eb-7e64-4af2-9b59-b5108471ad2e', // 2747
  '11e1bbed-94e2-40d5-beb7-dba677b16a3d', // 2747 de
  'eb9e33f0-c385-4f19-9674-440648724efe', // 2747 es
  'ef7fdab3-36d5-484c-b9e6-6c949847edeb', // 2747 fr
  '3154a41a-d4a2-40dc-92ad-246d243f4e66', // 2747 ja
  '7e4e866d-84bd-4999-8583-a5c97c8e1083', // 2747 pt
  'b0376290-30b7-4b7e-9941-12a421af796e', // 2793
  'adb79e7f-bfdf-4d31-b61b-14824b4beab1', // 3053
  'c89330af-e704-4833-97db-29ebc8b88d80', // 3053 de
  'c5e8600a-77e2-4ed7-b5ac-e4af39a13d4f', // 3053 fr
  '58d10a27-904c-470d-991c-16be294df163', // 3053 pt
  'b4ad6226-246d-4e3c-894c-5b1f188688ea', // 3121
  '860e5839-23f6-4ec9-99fe-fd7afb15b463', // 3121 de
  '60bad024-8bcd-4a90-9a44-a378a1d5832d', // 3121 es
  'a9315acb-1b2d-4142-ae61-1a508105a6f4', // 3121 fr
  '4d02f26d-4481-4abc-9ccf-f32326a1c3de', // 3121 ja
  'af627af5-58f0-4bde-993e-c4d785fe2e13', // 3121 pt
  '84f59361-304e-442f-bae6-800973837f29', // 3353
  '829056d2-ac49-4539-83ef-42441f6fd844', // 3354
  '1b3885d5-25a3-4937-837b-9e8c6070fc7a', // 3355
  'fe36c089-43fa-4098-a56c-068bcb01c32f', // 3356
]);

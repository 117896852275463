import * as platformEvents from 'platformEvents';

import { translate } from '#packages/i18n';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

import { TRANSLATIONS } from '../../translations';
import { APP_MANAGER_ORIGIN } from '../../constants';
import { AUTOMATION_IDS } from '../../automationIds';

import type { EditorAPI } from '#packages/editorAPI';
import type { AppDescriptor } from 'types/documentServices';
import type {
  AppActions,
  AppAction,
  AppPremiumBannerInfo,
} from './platform.types';
import type {
  CustomActionConfig,
  ExternalLinkActionConfig,
} from '@wix/platform-editor-sdk';
import _ from 'lodash';
import {
  shouldShowUpdateAction,
  updateApp,
  type AppData,
} from '#packages/privateAppsPanel';
import { symbols } from '#packages/pages';

type ActionConfig = CustomActionConfig | ExternalLinkActionConfig;

const { getPremiumUrl, getPremiumUrlForSiteUpgrade } = util.premiumUtils;

export const GENERAL_ACTION_IDS = {
  LEARN_MORE: 'LEARN_MORE',
  DELETE: 'DELETE',
  UPDATE: 'UPDATE',
} as const;

const getAppDescriptor = (editorAPI: EditorAPI, appDefId: string) => {
  return editorAPI.dsRead.platform.getAppDescriptor(appDefId);
};

export const isDescriptorExists = (editorAPI: EditorAPI, appDefId: string) => {
  const pageDescriptor = editorAPI.dsRead.platform.getAppDescriptor(appDefId);
  return pageDescriptor
    ? Object.values(pageDescriptor).find((value) => !_.isEmpty(value))
    : false;
};

interface GeneralAction extends AppAction {
  condition: () => boolean;
}

interface GetAppGeneralActions {
  (editorAPI: EditorAPI, appDefId: string): AppAction[];
}

const getAppGeneralActions: GetAppGeneralActions = (editorAPI, appDefId) => {
  const appDescriptor = getAppDescriptor(editorAPI, appDefId);
  const defaultActions = appDescriptor?.defaultActions || {};
  const config: GeneralAction[] = [
    {
      id: GENERAL_ACTION_IDS.LEARN_MORE,
      condition: () => !!defaultActions.learnMoreKB,
      onClick: () =>
        editorAPI.panelManager.openHelpCenter(defaultActions.learnMoreKB),
      title: translate(
        TRANSLATIONS.APP_PAGE.MORE_ACTIONS_SECTION.ACTIONS_LABELS.LEARN_MORE,
      ),
      ...symbols.getActionSymbolByName('learn_more_action'),
      dataHook: AUTOMATION_IDS.APP_PAGE.LEARN_MORE_ACTION,
    },
    {
      id: GENERAL_ACTION_IDS.DELETE,
      condition: () => defaultActions.uninstall !== 'HIDE',
      onClick: () => {
        const appData =
          editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);
        const shouldShowConfirmationModal = true;

        editorAPI.store.dispatch(
          stateManagement.platform.actions.uninstallApp(
            appData,
            shouldShowConfirmationModal,
            { origin: APP_MANAGER_ORIGIN },
          ),
        );
      },
      title: translate(
        TRANSLATIONS.APP_PAGE.MORE_ACTIONS_SECTION.ACTIONS_LABELS.DELETE_APP,
      ),
      ...symbols.getActionSymbolByName('delete_app_action'),
      dataHook: AUTOMATION_IDS.APP_PAGE.REMOVE_APP_ACTION,
    },
  ];

  return config.filter((action) => action.condition());
};

export const defaultAppActions: AppActions = {
  primary: [],
  general: [],
  secondary: [],
};

export const getAppActions = (
  editorAPI: EditorAPI,
  appDefId: string,
): AppActions => {
  const appDescriptor = getAppDescriptor(editorAPI, appDefId);

  if (!appDescriptor) {
    return defaultAppActions;
  }
  const applicationId =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId)?.applicationId;

  const notifyPlatformApplication = (actionId: string) =>
    editorAPI.platform.notifyApplication(
      applicationId,
      platformEvents.factory.appActionClicked({
        actionId,
      }),
    );

  const handleActionClick = (
    action: CustomActionConfig | ExternalLinkActionConfig,
  ) => {
    if ('url' in action) {
      window.open(action.url, '_blank');
    } else {
      notifyPlatformApplication(action.actionId);
    }
  };

  const mapPlatformActionToAppAction = (
    action: CustomActionConfig | ExternalLinkActionConfig,
    dataHook: AnyFixMe,
  ): AppAction => {
    const id = 'actionId' in action ? action.actionId : action.url;

    return {
      id,
      title: action.title,
      iconName: action.icon,
      onClick: () => handleActionClick(action),
      dataHook,
      permissions: editorAPI.platform.permissions.addGrantedToPermissions(
        action.permissions,
      ),
    };
  };

  const app = {
    ...editorAPI.platform.getAppDataByAppDefId(appDefId),
    latestVersion: stateManagement.platform.selectors.getLatestVersions(
      editorAPI.store.getState(),
    )[appDefId],
  } as AppData;
  app.blocksVersion = util.appStudioUtils.getBlocksVersion(app);
  const updateAction: AppAction[] = shouldShowUpdateAction(app)
    ? [
        {
          id: GENERAL_ACTION_IDS.UPDATE,
          title: translate(
            TRANSLATIONS.APP_PAGE.MORE_ACTIONS_SECTION.ACTIONS_LABELS.UPDATE,
            { App_name: app.appDefinitionName },
          ),
          iconName: 'appManager_settingsAction',
          onClick: () => editorAPI.store.dispatch(updateApp({ app })),
          dataHook: AUTOMATION_IDS.APP_PAGE.UPDATE_ACTION,
        },
      ]
    : [];
  const primaryActions = (appDescriptor.mainActions as ActionConfig[]).map(
    (action) =>
      mapPlatformActionToAppAction(
        action,
        AUTOMATION_IDS.APP_PAGE.PRIMARY_ACTION,
      ),
  );
  const secondaryActions = appDescriptor.customActions.map((action) =>
    mapPlatformActionToAppAction(
      action,
      AUTOMATION_IDS.APP_PAGE.SECONDARY_ACTION,
    ),
  );
  const generalActions = getAppGeneralActions(editorAPI, appDefId);

  return {
    primary: updateAction.concat(primaryActions),
    secondary: secondaryActions,
    general: generalActions,
  };
};

export const getAppPremiumBannerInfo = (
  editorAPI: EditorAPI,
  appDefId: string,
): AppPremiumBannerInfo | null => {
  const appDescriptor = getAppDescriptor(editorAPI, appDefId);

  const upgradeConfig = (appDescriptor as AppDescriptor)?.defaultActions
    ?.upgrade;

  if (!upgradeConfig) {
    return null;
  }

  if (
    upgradeConfig.upgradeType === 'SITE_UPGRADE' &&
    editorAPI.site.isPremium()
  ) {
    return null;
  }

  const premiumOrigin = `${APP_MANAGER_ORIGIN}_${appDefId}`;

  const premiumUrl =
    upgradeConfig.upgradeType === 'SITE_UPGRADE'
      ? getPremiumUrlForSiteUpgrade(editorAPI, {
          appDefinitionId: appDefId,
          origin: premiumOrigin,
          referralAdditionalInfo: premiumOrigin,
        })
      : getPremiumUrl(editorAPI, {
          appDefinitionId: appDefId,
          origin: premiumOrigin,
          referralAdditionalInfo: premiumOrigin,
        });

  return {
    upgrade: () => window.open(premiumUrl, '_blank'),
    text: upgradeConfig.upgradeText,
    linkText: upgradeConfig.upgradeLinkText,
  };
};

export const getAppHelpId = (
  editorAPI: EditorAPI,
  appDefId: string,
): string | undefined => {
  const appDescriptor = getAppDescriptor(editorAPI, appDefId);

  return appDescriptor?.defaultActions?.learnMoreKB;
};

import _ from 'lodash';

import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import experiment from 'experiment';
import { createSelectionChangePlugin } from '../selectionChangePlugin';
import { createSelectionStore } from './createSelectionStore';
import { createSelectionScopeApi } from './createSelectionScopeApi';
import { getSelectComponentByRefCoreInterceptors } from './getSelectComponentByRefCoreInterceptors';
import { gfppModel, gfppActionList } from '#packages/gfppData';

import type { CompRef, CompData } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { SelectionHooks } from '../createSelectionHooks';
import type { SelectionKeyboardAndMouseApi } from '../createSelectionKeyboardAndMouseApi';
import type { SelectionBI } from '../createSelectionBI';
import type { InteractionsApi } from '../interactions/createInteractionsApi';
import type { SelectionFocusApi } from '../selectionFocus/createSelectionFocusApi';
import type { HighlightsApi } from '../createHighlightsApi';

const MULTI_SELECT_LAYOUT_PANEL = 'compPanels.panels.MultiSelect.layoutPanel';

export function createSelectionBaseApi({
  editorAPI,
  selectionHooks,
  selectionFocusApi,
  highlightsApi,
  selectionKeyboardAndMouseApi,
  selectionBI,
  interactionsApi,
}: {
  editorAPI: EditorAPI;
  selectionHooks: SelectionHooks;
  selectionFocusApi: SelectionFocusApi;
  highlightsApi: HighlightsApi;
  selectionKeyboardAndMouseApi: SelectionKeyboardAndMouseApi;
  selectionBI: SelectionBI;
  interactionsApi: InteractionsApi;
}) {
  const selectionStore = createSelectionStore({
    editorAPI,
    store: editorAPI.store,
  });
  const selectionChangePlugin = createSelectionChangePlugin();
  const selectionScopeApi = createSelectionScopeApi({
    selectionStore,
    editorAPI,
  });
  const componentsSelectors = stateManagement.components.selectors;
  const componentsActions = stateManagement.components.actions;
  const { isMultiselect, asArray } = util.array;
  const singleSelectionComponentTypes = new Set<string>(
    constants.MULTISELECT.SINGLE_SELECTION_COMPONENT_TYPES,
  );

  function isSingleSelectionComponent(compRef: CompRef): boolean {
    const compType = editorAPI.components.getType(compRef);
    return (
      singleSelectionComponentTypes.has(compType) ||
      editorAPI.components.layout.isShowOnFixedPosition(compRef) ||
      !editorAPI.components.is.multiselectable(compRef)
    );
  }

  function isAncestorOfSelectedComponents(ancestorCompRef: CompRef): boolean {
    const selectedComponents = selectionStore.getSelectedComponents();
    return (
      selectedComponents.length > 0 &&
      selectedComponents.some((selectedCompRef) =>
        editorAPI.components.isAncestorOfCompOrCompScope(
          ancestorCompRef,
          selectedCompRef,
        ),
      )
    );
  }

  function isValidMultiSelectionInsideRepeater(components: CompRef[]): boolean {
    const firstCompItemContext = editorAPI.components.getAncestorRepeaterItem(
      components[0],
    );

    const compFromDifferentContextExist = components
      .slice(1)
      .some(function (compRef) {
        const currentItemContext =
          editorAPI.components.getAncestorRepeaterItem(compRef);

        if (!currentItemContext && !firstCompItemContext) {
          return false;
        }

        return !editorAPI.utils.isSameRef(
          currentItemContext,
          firstCompItemContext,
        );
      });

    return !compFromDifferentContextExist;
  }

  function allCompsHaveSameAppWidgetAncestor(components: CompRef[]): boolean {
    const firstComponent = components[0];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/drop
    const restComponents = _.drop(components);
    const firstCompAppWidget = componentsSelectors.getFirstAppWidget(
      firstComponent,
      editorAPI.dsRead,
    );
    return restComponents.every(
      (compRef) =>
        _.isEqual(compRef, firstCompAppWidget) ||
        _.isEqual(
          firstCompAppWidget,
          componentsSelectors.getFirstAppWidget(compRef, editorAPI.dsRead),
        ),
    );
  }

  function canBeSelectedTogether(compRefs: CompRef[]): boolean {
    return (
      compRefs.length <= 1 ||
      (!compRefs.some(isSingleSelectionComponent) &&
        isValidMultiSelectionInsideRepeater(compRefs) &&
        allCompsHaveSameAppWidgetAncestor(compRefs))
    );
  }

  function shouldCloseCompPanels(compsToBeSelected: CompRef[]): boolean {
    const currentFocusedContainer = selectionFocusApi.getFocusedContainer();
    const nextCompRef =
      compsToBeSelected &&
      compsToBeSelected.length === 1 &&
      compsToBeSelected[0];
    const selectedComps = selectionStore.getSelectedComponents();
    const currentCompRef =
      selectedComps && selectedComps.length === 1 && selectedComps[0];

    const wasSelectedChanged = !editorAPI.utils.isSameRef(
      currentCompRef,
      nextCompRef,
    );
    const isSelectingCurrentFocusedContainer = editorAPI.utils.isSameRef(
      nextCompRef,
      currentFocusedContainer,
    );

    const isSelectionChangedToFocusedContainer =
      currentFocusedContainer &&
      wasSelectedChanged &&
      isSelectingCurrentFocusedContainer;

    const isSelectingDifferentCompType =
      editorAPI.components.getType(nextCompRef) !==
      editorAPI.components.getType(currentCompRef);

    return isSelectionChangedToFocusedContainer || isSelectingDifferentCompType;
  }

  function getGfppDataForBlocksComponents(
    containerToFocus: CompRef,
    biParams?: { origin: string },
  ): { origin?: string; allGfppActions?: string } {
    if (
      containerToFocus &&
      util.appStudioUtils.isResponsiveBlocksWidget(
        { dsRead: editorAPI.dsRead },
        containerToFocus,
      )
    ) {
      const gfppData = gfppModel.getFullComponentGfppData(editorAPI, [
        containerToFocus,
      ]);
      const allGfppActions = gfppActionList
        .getGfppActionsList(gfppData)
        .toString();
      return { ...biParams, allGfppActions };
    }

    return null;
  }

  function getGfppDataForRichTextComponents(
    compsToBeSelected: CompRef[],
    biParams?: { origin: string },
  ): { origin?: string; allGfppActions?: string } {
    const compRef = Array.isArray(compsToBeSelected)
      ? compsToBeSelected[0]
      : compsToBeSelected;
    const componentType = editorAPI.components.getType(compRef);
    const isRichTextComponent = componentType === constants.COMP_TYPES.TEXT;

    if (isRichTextComponent) {
      const gfppData = gfppModel.getFullComponentGfppData(editorAPI, [compRef]);
      const allGfppActions = gfppActionList.getGfppActionsList(gfppData);
      return { ...biParams, allGfppActions };
    }

    return null;
  }

  function getAdditionalGfppData(
    containerToFocus: CompRef,
    compsToBeSelected: CompRef[],
    biParams?: { origin: string },
  ): { origin?: string; allGfppActions?: string } {
    const isTextSettingsInDesignGfppExperimentOpen = experiment.isOpen(
      'se_textSettingsInDesignGfpp',
    );
    const gfppDataForBlocksComponents = getGfppDataForBlocksComponents(
      containerToFocus,
      biParams,
    );

    const gfppDataForRichTextComponents =
      isTextSettingsInDesignGfppExperimentOpen &&
      getGfppDataForRichTextComponents(compsToBeSelected, biParams);

    return (
      gfppDataForBlocksComponents || gfppDataForRichTextComponents || biParams
    );
  }

  function updateSelectedComponents(
    compsToBeSelected: CompRef[],
    biParams?: { origin: string },
  ): void {
    selectionStore.setAncestorOfSelectedWithInteraction(
      compsToBeSelected.length === 1
        ? interactionsApi.getFirstAncestorWithInteraction(compsToBeSelected[0])
        : null,
    );

    highlightsApi.clearExternalHighlights();
    highlightsApi.removeFromHighlightsByHighlightType([
      highlightsApi.HighlightsType.THICK_LIGHT,
    ]);

    const lastFocusedContainer: CompRef =
      selectionFocusApi.getFocusedContainer();

    let containerToFocus = compsToBeSelected.length
      ? selectionFocusApi.getParentToFocus(compsToBeSelected)
      : null;

    if (
      selectionFocusApi.keepSpotlightFocused(
        compsToBeSelected,
        lastFocusedContainer,
      )
    ) {
      if (containerToFocus) {
        highlightsApi.addExternalHighlight(containerToFocus);
      }
      containerToFocus = lastFocusedContainer;
    }

    //maybe we should close panels at any change of selected component..
    if (shouldCloseCompPanels(compsToBeSelected)) {
      editorAPI.panelManager.closeAllPanelsOfType('compPanelFrame');
    }
    const previousSelectedComps = selectionStore.getSelectedComponents();
    const isSelectedComponentChanged =
      !selectionStore.areSameAsSelectedComponents(compsToBeSelected);

    if (isSelectedComponentChanged) {
      selectionStore.selectComponents(
        compsToBeSelected,
        editorAPI.getCompRestrictions(compsToBeSelected),
      );
      selectionHooks.selectionChanged.fire({ compRefs: compsToBeSelected });
    }

    const compToBeSelected = compsToBeSelected[0];
    const appContainerToFocus = isMultiselect(compsToBeSelected)
      ? null
      : componentsSelectors.getAppContainerIfExists(
          compToBeSelected,
          containerToFocus,
          editorAPI.dsRead,
        );

    selectionStore.setPrevComponentsAction(previousSelectedComps);

    if (appContainerToFocus) {
      if (!editorAPI.utils.isSameRef(containerToFocus, compToBeSelected)) {
        containerToFocus = null;
      }

      const ancestorsToHighlight =
        selectionFocusApi.getFocusableAndSelectableAncestors(
          compToBeSelected,
          appContainerToFocus,
        );
      highlightsApi.addCompsToHighlights(
        ancestorsToHighlight,
        highlightsApi.HighlightsType.THICK_LIGHT,
      );
    }

    if (editorAPI.componentFocusMode.isEnabled()) {
      containerToFocus = editorAPI.componentFocusMode.getCompRef();
    }

    const fullBiParams = getAdditionalGfppData(
      containerToFocus,
      compsToBeSelected,
      biParams,
    );
    selectionFocusApi.setFocusedContainer(
      containerToFocus,
      lastFocusedContainer,
      fullBiParams,
    );

    selectionStore.setAppContainer(appContainerToFocus);
    // these are post selection actions
    if (isSelectedComponentChanged) {
      editorAPI.store.dispatch(
        componentsActions.ignoreComponentsHiddenProperty(compsToBeSelected),
      );
      editorAPI.store.dispatch(
        componentsActions.ignoreComponentsCollapsedProperty(compsToBeSelected),
      );
      selectionBI.sendBiOnSelectPopupOverlay(compsToBeSelected);
      selectionChangePlugin.execute(
        editorAPI,
        previousSelectedComps,
        compsToBeSelected,
      );
    }
  }

  getSelectComponentByRefCoreInterceptors({
    editorAPI,
  }).forEach((interceptor) => {
    selectionHooks.selectComponentByCompRefInterceptor.tap(interceptor);
  });

  function selectComponentByCompRef(
    compRefOrRefs: CompRef | CompRef[],
    biParams?: { origin: string },
  ): void {
    const interceptionResult =
      selectionHooks.selectComponentByCompRefInterceptor.intercept({
        compsToBeSelected: _.uniqBy(asArray(compRefOrRefs), 'id'),
      });

    const compsToBeSelected = interceptionResult.data.compsToBeSelected.filter(
      (compRef) => editorAPI.components.is.visible(compRef),
    );

    //TODO due to performance issues (editor crashed with multi-select with lots of componentsToBeSelected, SE-6273), we limit for now the maximal multiselected componentsToBeSelected
    if (
      interceptionResult.isCanceled ||
      compsToBeSelected.length > 30 ||
      (compsToBeSelected.length > 0 &&
        (!editorAPI.components.is.selectable(compsToBeSelected) ||
          !canBeSelectedTogether(compsToBeSelected)))
    ) {
      return;
    }

    if (
      compsToBeSelected.length < 2 &&
      editorAPI.panelManager
        .getOpenPanels()
        .some((panel) => panel.name === MULTI_SELECT_LAYOUT_PANEL)
    ) {
      editorAPI.panelManager.closePanelByName(MULTI_SELECT_LAYOUT_PANEL);
    }

    if (compsToBeSelected.length === 1) {
      selectionBI.selectContainerComponent(compsToBeSelected, biParams);
    }

    selectionKeyboardAndMouseApi.onSelectComponentsByRef(compsToBeSelected);

    updateSelectedComponents(compsToBeSelected, biParams);
  }

  function shouldSelectLastFocusedContainer(
    lastFocusedContainer: CompRef,
    selectedComps: CompRef[],
    compsToBeSelected: CompRef[],
  ): boolean {
    const isLastFocusedContainerSelected = editorAPI.utils.isSameRef(
      selectedComps,
      lastFocusedContainer,
    );
    const shouldSelectFocusedContainerWhenDeselectingChildren =
      editorAPI.components.is.selectWhenDeselectingChildren(
        lastFocusedContainer,
      );
    const currentSelectedNotFocusedContainer =
      selectedComps.length > 0 && !isLastFocusedContainerSelected;
    const deselectingAll = compsToBeSelected.length === 0;
    return (
      deselectingAll &&
      currentSelectedNotFocusedContainer &&
      shouldSelectFocusedContainerWhenDeselectingChildren
    );
  }

  function addComponentToSelectionByRef(compRef: CompRef): CompRef[] {
    const components: CompRef[] = asArray(compRef);
    const selectedComponents = selectionStore.getSelectedComponents() || [];
    const nextSelectedComponents = selectedComponents.concat(components);

    if (nextSelectedComponents.length > 1) {
      selectionBI.multiselect(nextSelectedComponents, 'keyboard');
    }

    selectComponentByCompRef(nextSelectedComponents);
    return selectedComponents;
  }

  function getCompSiblingsThatAreNotSiteSegment(
    selectedComps: CompRef[],
  ): CompRef[] {
    return editorAPI.components
      .getSiblings(selectedComps)
      .filter((comp) => !editorAPI.components.is.siteSegment(comp));
  }

  function isPage(compRef: CompRef | CompRef[]): boolean {
    const componentType = componentsSelectors.getCompTypeSuffix(
      compRef,
      editorAPI.dsRead,
    );
    return componentType === 'Page' || componentType === 'AppPage';
  }

  function selectAll(): void {
    const selectedComps = selectionStore.getSelectedComponents();
    let compsToBeSelected, selectedCompsSiblings;

    if (
      !editorAPI.components.layout.containsSiblingsOnly(selectedComps) ||
      !selectionStore.isComponentSelected()
    ) {
      compsToBeSelected = [
        ...(selectedComps || []),
        ...editorAPI.components.getChildren(editorAPI.pages.getFocusedPage()),
      ];
    } else if (
      editorAPI.components.is.siteSegment(selectedComps) ||
      isPage(selectedComps)
    ) {
      compsToBeSelected = editorAPI.components.getChildren(selectedComps);
    } else {
      selectedCompsSiblings =
        getCompSiblingsThatAreNotSiteSegment(selectedComps);
      compsToBeSelected = selectedComps.concat(selectedCompsSiblings);
    }

    if (compsToBeSelected.length > 0) {
      editorAPI.closeCompPanelIfExists();
      if (compsToBeSelected.length > 1) {
        selectionBI.multiselect(compsToBeSelected, 'keyboard');
      }

      selectComponentByCompRef(compsToBeSelected);
    }
  }

  function deselectComponents(compRef?: CompRef | CompRef[]): void {
    selectionKeyboardAndMouseApi.onDeselectComponents(compRef);

    const shouldDeselectAllComponents = !compRef;
    const compsToDeselect = asArray(compRef);

    selectionChangePlugin.execute(editorAPI, compsToDeselect, null);

    const selectedComps = selectionStore.getSelectedComponents();
    const remainingSelectedComps = _.reject(
      selectedComps,
      function shouldComponentBeDeselected(currSelectedComp) {
        return (
          shouldDeselectAllComponents ||
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/some
          _.some(compsToDeselect, currSelectedComp)
        );
      },
    );

    const lastFocusedContainer = selectionFocusApi.getFocusedContainer();
    if (
      lastFocusedContainer &&
      shouldSelectLastFocusedContainer(
        lastFocusedContainer,
        selectedComps,
        remainingSelectedComps,
      )
    ) {
      selectComponentByCompRef(lastFocusedContainer);
    } else {
      selectComponentByCompRef(remainingSelectedComps);
    }
  }

  function getSelectedComponentId(): CompRef['id'] {
    const selectedComponent = selectionStore.getSelectedComponents()?.[0];
    return selectedComponent?.id;
  }

  function getSelectedComponentType(): string {
    const selectedComps = selectionStore.getSelectedComponents();
    if (selectedComps.length > 0) {
      return componentsSelectors.getCompTypeSuffix(
        selectedComps[0],
        editorAPI.dsRead,
      );
    }
    return null;
  }

  function getSelectedComponentData(): CompData {
    const selectedComponents = selectionStore.getSelectedComponents();
    if (selectedComponents) {
      return editorAPI.components.data.get(selectedComponents);
    }

    return null;
  }

  return {
    selectionChangePlugin,
    selectComponentByCompRef,
    addComponentToSelectionByRef,
    selectAll,
    deselectComponents,
    setPrevComponentsAction: selectionStore.setPrevComponentsAction,
    isSingleSelectionComponent,
    isAncestorOfSelectedComponents,
    isComponentSelected: selectionStore.isComponentSelected,
    getSelectedComponents: selectionStore.getSelectedComponents,
    getSelectedComponentsScopes: selectionScopeApi.getSelectedComponentsScopes,
    isComponentScopeSelected: selectionScopeApi.isComponentScopeSelected,
    getSelectedComponentId,
    getSelectedComponentType,
    getSelectedComponentData,
  };
}

export type SelectionBaseApi = ReturnType<typeof createSelectionBaseApi>;

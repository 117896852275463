import React from 'react';
import * as util from '@/util';
import * as stateManagement from '@/stateManagement';
import * as panels from '#packages/panels';
import servicePanels from '@wix/editor-elements-panel/service-panels';
import { translate } from '#packages/i18n';

interface EditorPanelProps {
  panelName: string;
  onClose: () => void;
  title: string;
  top: string;
  left: string;
}
const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const {
  frames: { ToolPanelFrame },
} = panels;

const panelFactory = (
  Component: React.ComponentType<any>,
  panelTitle: string,
) => {
  class EditorPanel extends React.Component<EditorPanelProps, any> {
    private readonly getFrameStyle: () => { left: string; top: string };
    private readonly WrappedComponent: React.FC<any>;

    constructor(props: EditorPanelProps) {
      super(props);
      this.getFrameStyle = () => ({
        left: this.props.left,
        top: this.props.top,
        width: 288,
      });
      this.WrappedComponent =
        stateManagement.components.hoc.compPanel(Component);
    }

    render() {
      const { WrappedComponent } = this;
      const { panelName, onClose } = this.props;
      return (
        <ToolPanelFrame
          panelName={panelName}
          headerTitle={translate(panelTitle)}
          onClose={onClose}
          contentStyle={this.getFrameStyle()}
          lightHeader
        >
          <WrappedComponent {...this.props} />
        </ToolPanelFrame>
      );
    }
  }

  return connect(EDITOR_API, undefined, undefined)(EditorPanel);
};

const wrappedPanels: Record<string, React.FC> = {};
for (const componentName of Object.keys(servicePanels)) {
  const { component, panelTitle } = servicePanels[componentName];
  wrappedPanels[componentName] = panelFactory(component, panelTitle);
}

export default wrappedPanels;

// @ts-nocheck
import _ from 'lodash';
import { translate } from '#packages/i18n';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_playbutton',
      isApplied: gfppDataUtils.getDataDefinedFn('uri'),
      onClick(editorAPI, compRef) {
        const compId = compRef[0]?.id;

        editorAPI.bi.event(coreBi.events.singleAudioPlayer.changeTrackClicked, {
          component_type: editorAPI.components.getType(compRef),
          comp_id: compId,
        });

        const { mediaServices } = editorAPI;
        mediaServices.mediaManager.open(
          mediaServices.mediaManager.categories.MUSIC,
          'music_player',
          {
            translation: {
              submitButton: translate(
                'MMGR_submitbutton_onstage_player_mytracks_choose',
              ),
            },
            multiSelect: false,
            callback(items) {
              if (!_.isEmpty(items)) {
                const data = editorAPI.components.data.get(compRef);
                data.uri = items[0].fileName;
                editorAPI.components.data.update(compRef, data);

                editorAPI.bi.event(coreBi.events.singleAudioPlayer.trackAdded, {
                  layout: 'null',
                  source: 'upload',
                  trackID: items[0]?.id,
                  component_type: editorAPI.components.getType(compRef),
                  comp_id: compId,
                });
              }
              mediaServices.setFocusToEditor();
            },
          },
        );
      },
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '3cca5625-4f77-4125-88b8-a58fabbb3030',
    },
  },
};

// @ts-nocheck
import * as util from '#packages/util';
import { createSource, Public } from '@wix/media-manager-sdk';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { translate } from '#packages/i18n';
import React from 'react';
import * as mediaPanelConsts from '../../mediaManagerPanelConstants/mediaManagerPanelConstants';
import * as mediaPanelUtils from '../../mediaManagerPanelUtils/mediaManagerPanelUtils';
import * as mediaPanelWixMediaUtils from '../../mediaManagerPanelUtils/mediaPanelWixMediaUtils';
import ItemsSection from './itemsSection';
import { biLogger } from '#packages/util';
import { addMenuImageSectionButtonClick } from '@wix/bi-logger-editor/v2';

const {
  PUBLIC_MEDIA_ROOT,
  ITEMS_LOADING_OPTIONS,
  MEDIA_MANAGER_OPEN_OPTIONS,
  MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH,
  MEDIA_MANAGER_PANEL_SECTION_ID,
} = mediaPanelConsts;
const { IMAGES, VECTOR_ART, ILLUSTRATIONS, SOCIAL_ICONS, VIDEOS } =
  PUBLIC_MEDIA_ROOT;
const WIX_MEDIA_ROOTS = `${IMAGES},${VECTOR_ART},${ILLUSTRATIONS},${SOCIAL_ICONS},${VIDEOS}`;
const DEFAULT_PRESET_WIDTH = 88;

let wixMediaFoldersTreePromise = null;

class WixMediaSectionBase extends React.Component {
  constructor(props) {
    super(props);

    this.adapter = createSource(Public, {
      siteMediaToken: props.siteMediaToken,
      version: 'G6',
      thumbnailSizes: {
        thumbnail: {
          width: props.presetWidth || DEFAULT_PRESET_WIDTH,
          height: 90,
        },
      },
    });

    _.bindAll(this, ['loadMedia', 'handleMediaManagerOpen']);
  }

  getWixMediaTree() {
    if (wixMediaFoldersTreePromise) {
      return wixMediaFoldersTreePromise;
    }

    wixMediaFoldersTreePromise = this.adapter.folders
      .tree(WIX_MEDIA_ROOTS, {
        levels: -1,
      })
      .then((response) => response.data);

    return wixMediaFoldersTreePromise;
  }

  getSubcategoryDescription() {
    return this.getWixMediaTree().then((tree) =>
      mediaPanelWixMediaUtils.getWixMediaSubcategory(
        tree,
        this.props.mediaRoot,
      ),
    );
  }

  loadItems(subcategory) {
    const { mediaRoot } = this.props;

    if (this.props.query) {
      return this.adapter.items
        .search(subcategory.id, this.props.query, {
          language: this.props.language,
          mediaType:
            mediaPanelConsts.PUBLIC_MEDIA_ROOT_MEDIA_TYPE_MAP[mediaRoot],
          country: util.editorModel.geo,
          // TODO do we use this feature?
          // ...(experiment.isOpen('se_mediaPanelShowPaidMediaFromWix')
          //   ? { filters: { pricing: 'all' } }
          //   : { filters: { pricing: 'free' } }),
          ...{ filters: { pricing: 'free' } },
          ...ITEMS_LOADING_OPTIONS,
        })
        .then((response) => response.data);
    }

    return this.adapter.items
      .list(subcategory.id, {
        language: this.props.language,
        mediaType: mediaPanelConsts.PUBLIC_MEDIA_ROOT_MEDIA_TYPE_MAP[mediaRoot],
        filters: { pricing: 'free' },
        country: util.editorModel.geo,
        ...ITEMS_LOADING_OPTIONS,
      })
      .then((response) => response.data);
  }

  fullItemsLoaderMiddleware(items) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const itemsIds = _(items)
      .filter((item) =>
        _.overEvery([
          mediaPanelUtils.isWixMediaItem,
          mediaPanelUtils.isVideoItem,
        ])(item),
      )
      .map((item) => item?.id)
      .value();

    if (_.isEmpty(itemsIds)) {
      return Promise.resolve(items);
    }

    return this.adapter.item
      .get(itemsIds, { language: this.props.language })
      .then((response) => _.keyBy(response.data, 'id'))
      .then((fullItemsMap) =>
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        _.map(items, (item) => _.merge(item, fullItemsMap[item.id])),
      );
  }

  async loadMedia() {
    const subCategory = await this.getSubcategoryDescription();
    const items = await this.loadItems(subCategory);
    const fullItems = await this.fullItemsLoaderMiddleware(items);
    const categoryWithItemIds = this.getCategoryWithItemIds(fullItems);
    this.props.onMediaLoaded?.(fullItems, categoryWithItemIds);
    return fullItems;
  }

  private getCategoryWithItemIds(fullItems: any) {
    return {
      [this.props.id]: fullItems.map(({ id }) => id),
    };
  }

  handleMediaManagerOpen() {
    biLogger.report(
      addMenuImageSectionButtonClick({
        section: 'exploreImages',
        sub_section: `${this.props.id}${this.props.query ? '_search' : ''}`,
      }),
    );

    this.getSubcategoryDescription().then((subcategory) => {
      const publicMediaItemsDeepLink = `${subcategory.categoryId}/${subcategory.id}`;
      const { path } = this.props.query
        ? MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH[this.props.id](
            this.props.query,
            publicMediaItemsDeepLink,
          )
        : MEDIA_MANAGER_OPEN_OPTIONS[this.props.id](publicMediaItemsDeepLink);

      this.props.openMediaManager({
        path,
        biSource: `wix-media-${this.props.mediaRoot}${
          this.props.query ? '_search' : ''
        }`,
      });
    });
  }

  getItemsSectionProps() {
    return Object.assign(
      {
        action: this.handleMediaManagerOpen,
        loadMedia: this.loadMedia,
        helpTitle: this.props.helpTitle
          ? translate(this.props.helpTitle)
          : null,
        helpDescription: this.props.helpDescription
          ? translate(this.props.helpDescription)
          : null,
      },
      _.pick(this.props, [
        'id',
        'title',
        'presetWidth',
        'showSectionHeader',
        'shouldDisplayPresetName',
        'onItemClick',
        'getItemPrice',
        'buyItem',
        'startItemDrag',
        'registerItem',
        'automationId',
        'query',
        'onMediaLoaded',
      ]),
    );
  }

  render() {
    return <ItemsSection {...this.getItemsSectionProps()} />;
  }
}

WixMediaSectionBase.propTypes = {
  mediaRoot: PropTypes.oneOf(Object.values(PUBLIC_MEDIA_ROOT)).isRequired,
  language: PropTypes.string.isRequired,
  query: PropTypes.string,
  id: PropTypes.oneOf(Object.values(MEDIA_MANAGER_PANEL_SECTION_ID)).isRequired,
};

export default WixMediaSectionBase;

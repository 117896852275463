import React from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as keyboardContextStack from '../util/keyboardContextStack';
import { Checkbox } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import type { DropPanelItem } from './types';
import { NestedDropPanelList } from './dropPanelListNested';

interface DropPanelListProps {
  automationId?: string;
  className?: string;
  isChecklist?: boolean;
  isTitleOnly?: boolean;
  selectedIndex?: number;
  items?: DropPanelItem[];
  closePanel: () => void;
  onItemHover?: (index: number) => void;
  hasDesignPermissions: boolean;
}

function template(this: DropPanelList) {
  const { isChecklist } = this.props;

  const list = (
    <ul
      className={util.cx(this.props.className, 'drop-panel-list', {
        'margin-none': true,
        'drop-panel-check-list':
          this.props.isChecklist && !this.props.isTitleOnly,
      })}
    >
      {this.props.items.map((item, itemIndex) => {
        const counter = this.getItemCounter(item);
        const title = item.translatedLeftTitle || translate(item.leftTitle);

        if (item.children) {
          return (
            <NestedDropPanelList
              isOpen={this.props.selectedIndex === itemIndex}
              items={item.children}
              onHover={() => this.onItemHover(itemIndex)}
              title={item.leftTitle}
              onItemClick={() => this.props.closePanel()}
              automationId={item.automationId}
            />
          );
        }

        return (
          <li
            key={`${itemIndex}-item-drop-panel-list`}
            data-aid={item.automationId}
            onClick={() => {
              this.onClick(item);
            }}
            onMouseEnter={() => {
              this.onItemHover(itemIndex);
            }}
            className={util.cx(
              {
                'drop-panel-list-item': !item.isSeparator && !item.isTitleOnly,
                'drop-panel-list-item-title': item.isTitleOnly,
                'drop-panel-list-separator': item.isSeparator,
                disabled: item.isDisabled,
              },
              item.titleClassName,
            )}
          >
            {!item.isSeparator && !item.isTitleOnly ? (
              <span
                key="item"
                className={this.getTitleContainerCssClassList(item, itemIndex)}
              >
                {isChecklist || item.isCheckbox ? (
                  <Checkbox
                    key={`${item.leftTitle}-checkbox-drop-panel-list`}
                    disabled={item.isDisabled}
                    type="T02"
                    label={item.leftTitle}
                    labelAfterSymbol={true}
                    value={item.checkboxValue}
                  />
                ) : null}
                {!isChecklist && !item.isCheckbox && (
                  <>
                    <label key="label" className="list-item-label">
                      {counter ? (
                        <span
                          key="counter"
                          className="drop-panel-list-item-counter"
                        >
                          {counter}
                        </span>
                      ) : null}
                      {item.iconName ? (
                        <symbols.symbol
                          key={`symbol-${item.iconName}`}
                          name={item.iconName}
                        />
                      ) : null}
                      <span className="drop-panel-list-item-text">{title}</span>
                      {item.hasNotification ? (
                        <span className="drop-panel-list-item-notification" />
                      ) : null}
                    </label>
                  </>
                )}
              </span>
            ) : null}
            {item.isSeparator && (
              <hr key="item" className="drop-panel-menu-bar-separator" />
            )}
            {item.isTitleOnly && (
              <span key="item" className="list-item-label">
                {title}
              </span>
            )}
            {item.suffix && item.suffix()}
          </li>
        );
      })}
    </ul>
  );

  return list;
}

class DropPanelList extends React.Component<DropPanelListProps> {
  customKeyboardContext: string;

  onClick = (item: DropPanelItem) => {
    if (!item.isDisabled && _.isFunction(item.onClick)) {
      if (!item.isCheckbox && !item.skipClickClosePanel) {
        keyboardContextStack.removeItemsAndRestoreLastContext([
          this.customKeyboardContext,
          util.keyboardShortcuts.CONTEXTS.MENU_BAR,
        ]);

        this.props.closePanel();
      }

      item.onClick();
    }
  };

  onItemHover = (itemIndex: number) => {
    if (
      !this.props.items[itemIndex].isSeparator &&
      !this.props.items[itemIndex].isTitleOnly
    ) {
      this.props.onItemHover(itemIndex);
    }
  };

  getTitleContainerCssClassList = (item: DropPanelItem, itemIndex: number) => {
    const isSelected = itemIndex === this.props.selectedIndex;
    return util.cx(
      'title-item-container-drop-panel-list',
      item.listItemClassName,
      {
        disabled: item.isDisabled,
        nested: item.isNested,
        'with-icon': !!item.iconName,
        selected:
          isSelected &&
          (this.props.hasDesignPermissions
            ? !item.selectedClassName
            : !item.isDisabled),
      },
      item.titleClassName,
      { [item.selectedClassName]: isSelected },
    );
  };

  private getNewItemIndex = (dir: number) => {
    const { items } = this.props;
    let { selectedIndex: newIndex } = this.props;

    if (newIndex === null) {
      newIndex = dir === 1 ? items.length - 1 : 0;
    }

    do {
      newIndex = (items.length + newIndex + dir) % items.length;
    } while (items[newIndex].isSeparator);

    return newIndex;
  };

  getItemCounter = (item: DropPanelItem) =>
    item.getCounter && item.getCounter();

  getExtendKeyboardContext = () =>
    util.keyboardShortcuts.extendContext(
      util.keyboardShortcuts.CONTEXTS.MENU_BAR,
      {
        up: (e: Event) => {
          this.onItemHover(this.getNewItemIndex(-1));
          e.preventDefault();
        },
        down: (e: Event) => {
          this.onItemHover(this.getNewItemIndex(1));
          e.preventDefault();
        },
        enter: (e: Event) => {
          const { selectedIndex, items } = this.props;
          const item = items[selectedIndex];

          if (item) {
            this.onClick(item);
          }
          e.preventDefault();
        },
      },
    );

  componentDidMount() {
    this.customKeyboardContext = keyboardContextStack.pushAndSetCustomContext(
      this.getExtendKeyboardContext(),
    );
  }

  componentWillUnmount() {
    keyboardContextStack.popAndRestoreLastContext(
      this.customKeyboardContext,
      true,
    );
  }

  render() {
    return template.call(this);
  }
}

// @ts-expect-error
DropPanelList.displayName = 'DropPanelList';

export default DropPanelList;

import React from 'react';
import { RichText, Text, Tooltip } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { hoc, cx } from '#packages/util';
import { translate } from '#packages/i18n';

import styles from './LandingPageIndication.scss';

import {
  type LandingPageIndicationDispatchProps,
  type LandingPageIndicationStateProps,
  mapStateToProps,
  mapDispatchToProps,
  shouldShowLandingPageIndication,
} from './LandingPageIndicationMapper';

export interface LandingPageIndicationOwnProps {
  type: 'topPage' | 'bottomPage';
}

interface LandingPageIndicationProps
  extends LandingPageIndicationOwnProps,
    LandingPageIndicationStateProps,
    LandingPageIndicationDispatchProps {}

const text = translate('Mobile_Pages_NoHeader_Indication');
const tooltipText = translate('Mobile_Pages_NoHeader_Indication_Tooltip_Text');
const tooltipLink = translate('Mobile_Pages_NoHeader_Indication_Tooltip_Link');

const LandingPageIndicationComponent: React.FC<LandingPageIndicationProps> = ({
  type,
  shouldHide,
  tooltipDisabled,
  openPagesLandingTab,
  logLandingPageLinkClicked,
}) => {
  const handleTooltipLinkClick = React.useCallback(() => {
    openPagesLandingTab();
    logLandingPageLinkClicked();
  }, [openPagesLandingTab, logLandingPageLinkClicked]);

  if (shouldHide) {
    return null;
  }
  const tooltipAlignment = type === 'topPage' ? 'BOTTOM' : 'TOP';

  const tooltipContent = (
    <RichText>
      {`${tooltipText}`}
      <br />
      <a onClick={handleTooltipLinkClick}> {tooltipLink} </a>
    </RichText>
  );

  return (
    <div
      className={cx(styles.container, {
        [styles.bottomPage]: type === 'bottomPage',
      })}
    >
      <Tooltip
        shouldTranslate={false}
        disabled={tooltipDisabled}
        content={tooltipContent}
        alignment={tooltipAlignment}
        className={styles.tooltip}
        interactive
      >
        <div className={styles.tooltipContent}>
          <Symbol name="infoIcon" className={styles.icon} />
          <Text
            weight="thin"
            skin="secondary"
            size="small"
            shouldTranslate={false}
          >
            {text}
          </Text>
        </div>
      </Tooltip>
    </div>
  );
};

const LandingPageIndicationConnected = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LandingPageIndicationComponent);

const LandingPageIndication = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  shouldShowLandingPageIndication,
)(LandingPageIndicationConnected);

export const LandingPageIndicationTop = () => (
  <LandingPageIndication type="topPage" />
);
export const LandingPageIndicationBottom = () => (
  <LandingPageIndication type="bottomPage" />
);

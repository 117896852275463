// @ts-nocheck
import PropTypes from 'prop-types';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import React from 'react';
import * as panels from '#packages/panels';
import * as baseUI from '#packages/baseUI';
import * as symbols from '@wix/santa-editor-symbols';

const { connect } = util.hoc;

//TYPE WAS GENERATED, remove this line when reviewed
interface ConnectDomainProgressProps {
  connectedDomainURL: string;
  currentPublicURL: string;
  panelName?: string;
  closePanel: FunctionFixMe;
}

class ConnectDomainProgress extends React.Component<ConnectDomainProgressProps> {
  static displayName = 'connectDomainInProgress';

  static propTypes = {
    connectedDomainURL: PropTypes.string.isRequired,
    currentPublicURL: PropTypes.string.isRequired,
    panelName: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
  };

  getShortcuts = () => {
    return {
      esc: this.onClose,
      enter: this.onClose,
    };
  };

  openWorkingURL = () => {
    window.open(this.props.currentPublicURL);
  };

  onClose = () => {
    this.props.closePanel();
  };

  render() {
    return (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        shouldHideHeader={true}
        frameClassName="save-publish-panel-frame"
        dataHook="connect-domain-in-progress-panel"
        keyboardShortcuts={this.getShortcuts()}
        className="connect-domain-in-progress-panel save-publish-panel"
      >
        <div className="save-publish-panel-header">
          <div className="title">{translate('PUBLISH_HANGON_TITLE')}</div>
          <div className="subtitle">{translate('PUBLISH_HANGON_SUBTITLE')}</div>
          <button onClick={this.onClose} className="close-button">
            <symbols.symbol name="headerCloseButton" />
          </button>
        </div>
        <div className="panel-content">
          <div className="content-top-part">
            <div className="content-graphics">
              <baseUI.symbol name="hangon" />
            </div>
            <div className="content-top-message">
              {translate('PUBLISH_HANGON_MAIN_TEXT')}
              <span className="public-url">
                {this.props.connectedDomainURL}
              </span>
            </div>
          </div>
          <div className="content-bottom-message">
            {translate('PUBLISH_HANGON_MEANTIME_TEXT')}
            <a
              onClick={this.openWorkingURL}
              className="content-bottom-message-link"
            >
              {translate('PUBLISH_HANGON_CLICKING_HERE_LINK')}
            </a>
          </div>
        </div>
        <div className="connect-domain-panel-footer">
          <baseUI.button
            type="button"
            label="PUBLISH_HANGON_DONE_BUTTON"
            onClick={this.onClose}
            className="maybe-later-button"
          />
        </div>
      </panels.frames.FocusPanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  closePanel() {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
  },
});

export default connect(
  [],
  undefined,
  mapDispatchToProps,
)(ConnectDomainProgress);

import React from 'react';

import * as _ from 'lodash';
import { TextButton } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import * as coreBi from '#packages/coreBi';
import type { SendBiFunction } from '../../../types/bi';
import withOpenDashboard, {
  type WithOpenDashboardProps,
} from '../hocs/withOpenDashboard/withOpenDashboard';
import withSendBi from '../hocs/withSendBi';
import { hoc, fixedStage } from '#packages/util';
import type { MapStateToProps } from 'types/redux';

interface DashboardShortcutTooltipStateProps {
  topBarStateBIParamValue: string;
}

export interface DashboardShortcutTooltipOwnProps {
  sendBi: SendBiFunction;
}
export interface DashboardShortcutTooltipProps
  extends DashboardShortcutTooltipOwnProps,
    DashboardShortcutTooltipStateProps,
    WithOpenDashboardProps {}

const DashboardShortcutTooltip: React.FC<DashboardShortcutTooltipProps> = (
  props,
) => {
  const handleClick = () => {
    props.sendBi(coreBi.events.topbar.top_bar_click, {
      category: 'wix_logo_tooltip',
      state: props.topBarStateBIParamValue,
    });

    props.openDashboard();
  };

  return (
    <TextButton
      size="small"
      shouldTranslate={false}
      onClick={handleClick}
      suffixIcon={<symbols.symbol name="topBarGoToURL" />}
    >
      {translate('Topbar_Dashboard_Label')}
    </TextButton>
  );
};

const mapStateToProps: MapStateToProps<
  DashboardShortcutTooltipStateProps,
  DashboardShortcutTooltipOwnProps
> = ({ editorAPI }) => ({
  topBarStateBIParamValue: fixedStage.getTopBarStateBiParamValue(editorAPI),
});

export default _.flow(
  hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps),
  withSendBi,
  withOpenDashboard,
)(DashboardShortcutTooltip);

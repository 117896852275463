import React from 'react';
import { Text } from '@wix/wix-base-ui';
import classNames from 'classnames';
import styles from './InputLabel.scss';

interface InputLabelProps {
  title: string;
  subtitle: string;
  gap: 'small' | 'big';
}

export const InputLabel: React.FC<InputLabelProps> = ({
  title,
  subtitle,
  gap,
}) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.smallGap]: gap === 'small',
        [styles.bigGap]: gap === 'big',
      })}
    >
      <div
        // Hack because of a bug in base-ui
        className={styles.inputTitle}
      >
        <Text
          skin="secondary"
          size="small"
          weight="bold"
          enableEllipsis={false}
          shouldTranslate
        >
          {title}
        </Text>
      </div>
      <Text
        skin="secondary"
        size="small"
        weight="thin"
        enableEllipsis={false}
        shouldTranslate
      >
        {subtitle}
      </Text>
    </div>
  );
};

import React, { useState, useEffect, type FC } from 'react';
import { Text } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';

import styles from './loader.scss';

enum LoaderProgressState {
  ENTRY_STATE = 'ENTRY_STATE',
  STAGE_ONE = 'STAGE_ONE',
  STAGE_TWO = 'STAGE_TWO',
  STAGE_THREE = 'STAGE_THREE',
}

const loaderStagesTitles: Partial<{ [ID in LoaderProgressState]: string }> = {
  [LoaderProgressState.STAGE_ONE]: 'add_section_ai_loader_step_one_text',
  [LoaderProgressState.STAGE_TWO]: 'add_section_ai_loader_step_two_text',
  [LoaderProgressState.STAGE_THREE]: 'add_section_ai_loader_step_three_text',
};

const STAGE_DURATION = 2000;

const Loader: FC = () => {
  const [loaderState, setLoaderState] = useState<LoaderProgressState>(
    LoaderProgressState.ENTRY_STATE,
  );

  useEffect(() => {
    let timerRef: AnyFixMe;
    setLoaderState(LoaderProgressState.STAGE_ONE);

    new Promise<void>((resolve) => {
      timerRef = setTimeout(() => {
        setLoaderState(LoaderProgressState.STAGE_TWO);
        resolve();
      }, STAGE_DURATION);
    }).then(() => {
      return new Promise<void>((resolve) => {
        timerRef = setTimeout(() => {
          setLoaderState(LoaderProgressState.STAGE_THREE);
          resolve();
        }, STAGE_DURATION);
      });
    });

    return () => clearTimeout(timerRef);
  }, []);

  return (
    <>
      <div className={styles.progressbarWrapper}>
        <div className={styles.lpWrapper}>
          <div className={styles.lpTitle}>
            <Text size="small" weight="thin" shouldTranslate={false}>
              {translate(loaderStagesTitles[loaderState])}
            </Text>
          </div>
          <div className={styles.lpBarWrapper}>
            <div className={styles.lpBarGradient} />
          </div>
        </div>
      </div>
      <div className={styles.wrapper} data-hook="ai-section-creator_loader">
        <div className={styles.placeholder} style={{ height: '158px' }} />
        <div className={styles.placeholder} style={{ height: '109px' }} />
        <div className={styles.placeholder} style={{ height: '136px' }} />
      </div>
    </>
  );
};

export default Loader;

:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._controlRichText_lphul_7 {
  display: flex;
  align-items: center;
  margin-bottom: 5px; }

._symbol_lphul_12 {
  margin-right: 5px;
  width: 17px;
  height: 17px; }
  ._symbol_lphul_12 path {
    fill: #116dff; }

import React, { type FC } from 'react';

import { translate } from '#packages/i18n';
import { Text, Button, IconButton } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { frames } from '#packages/panels';
import { hoc } from '#packages/util';

import styles from './styles.scss';
import type { CheckReadinessPanelProps } from './checkReadinessPanelTypes';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './checkReadinessPanelMapper';
import { TRANSLATION_KEYS } from '../../consts';

const { EmptyPanelFrame } = frames;

const CheckReadinessPanel: FC<CheckReadinessPanelProps> = ({
  closePanel,
  openSiteReadinessPanel,
}) => {
  return (
    <EmptyPanelFrame
      panelName="checkReadinessPanel"
      data-hook="check-readiness-panel"
      className={styles.panel}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.imageWrapper}>
          <Symbol name="aiIllustration" className={styles.aiIllustration} />
        </div>
        <div className={styles.mainContentWrapper}>
          <div className={styles.textContentWrapper}>
            <Text
              size="medium"
              weight="bold"
              skin="secondary"
              shouldTranslate={false}
            >
              {translate(TRANSLATION_KEYS.ENTRY_TITLE)}
            </Text>
            <Text
              size="small"
              weight="thin"
              shouldTranslate={false}
              customEllipsisProps={{ maxLines: 2 }}
            >
              {translate(TRANSLATION_KEYS.ENTRY_BODY)}
            </Text>
          </div>
          <Button
            className="btn-sm"
            onClick={openSiteReadinessPanel}
            dataHook="see-score-button"
          >
            <span>{translate(TRANSLATION_KEYS.ENTRY_BUTTON)}</span>
          </Button>
        </div>
      </div>
      <IconButton
        skin="light-dark"
        size="small"
        className={styles.closeButton}
        onClick={closePanel}
      >
        <Symbol name="rightPanelClose" />
      </IconButton>
    </EmptyPanelFrame>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(CheckReadinessPanel);

import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorAPIKey, SelectionApiKey, PagesApiKey } from '#packages/apis';
import { BlocksApi } from './blocksApi';
import { BlocksApiKey } from './publicApiKey';

export class BlocksScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  selection = this.useDependency(SelectionApiKey);
  pages = this.useDependency(PagesApiKey);
}

export const BlocksEntryPoint = createEntryPoint({
  name: 'Blocks',
  Scope: BlocksScope,
  publicApi({ contributeApi }) {
    contributeApi(BlocksApiKey, BlocksApi);
  },
  async initialize() {},
});

import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { ComponentValueRecord } from './components/componentControlWrapper';

const { getStateTitle } = stateManagement.stateBox.selectors;

export const quickEditPanelOrigin = 'quickEditPanel';

export const SectionHeaderType = 'responsive.components.HeaderSection';
export const SectionFooterType = 'responsive.components.FooterSection';

export const QUICK_EDIT_PANEL_HELP_ID = '6e2524c2-74e4-41ef-8390-8f3eb1d657b4';

export const NUMBER_OF_FIRST_LEVEL_DRILL_IN_PANELS = 2;

export const GENERIC_ITEM_THUMBNAIL_WIDTH = 100;
export const QUICK_EDIT_PANEL_WIDTH = 288;
export const NULL_VALUE_RECORD: ComponentValueRecord = {
  initial: null,
  last: null,
};

const {
  HEADER,
  FOOTER,
  COLUMN,
  SECTION,
  MEDIA_CONTAINER,
  STATE_BOX_STATE,
  BOX_SLIDE_SHOW_SLIDE,
  STRIP_CONTAINER_SLIDE_SHOW_SLIDE,
  REF_COMPONENT,
  STRIP_COLUMNS_CONTAINER,
  STRIP_SLIDE_SHOW,
  TPA_MULTI_SECTION,
  TPA_SECTION,
  TPA_WIDGET,
  DROPDOWN_MENU,
  STYLABLE_HORIZONTAL_MENU,
  VERTICAL_MENU,
  EXPANDABLE_MENU,
  TEXT,
  SLIDE_SHOW,
  GRID,
  ACCORDION_CONTAINER,
} = constants.COMP_TYPES;

export const QUICK_EDIT_COMPONENTS_WITH_RESTRICTED_CHILDREN = new Set<string>([
  REF_COMPONENT,
]);

export const QUICK_EDIT_COMPS_WITH_MAIN_ACTIONS = new Set<string>([
  REF_COMPONENT,
  TPA_MULTI_SECTION,
  TPA_SECTION,
  TPA_WIDGET,
  DROPDOWN_MENU,
  STYLABLE_HORIZONTAL_MENU,
  VERTICAL_MENU,
  EXPANDABLE_MENU,
  TEXT,
  GRID,
]);

export const QUICK_EDIT_RESTRICTED_COMPONENTS_AND_ITS_CHILDREN =
  new Set<string>([STRIP_SLIDE_SHOW]);

export const getMainActionLabelOverrideMap: {
  [compType: string]:
    | string
    | ((editorAPI: EditorAPI, compRef: CompRef) => string);
} = {
  [TPA_WIDGET]: (editorAPI: EditorAPI, compRef: CompRef) => {
    const appDefId = editorAPI.components.data.get(compRef).appDefinitionId;
    return getTpaWidgetMainActionLabelOverrideMap[appDefId];
  },
};

const getTpaWidgetMainActionLabelOverrideMap: {
  [appDefId: string]: string;
} = {
  //TODO: use constant from editor-platform when available
  '225dd912-7dea-4738-8688-4b8c6955ffc2': 'gfpp_mainaction_forms_edit_form',
};

export const getCompDisplayNameOverrideMap: {
  [compType: string]:
    | string
    | ((editorAPI: EditorAPI, compRef: CompRef) => string);
} = {
  [SECTION]: 'edit_section_section_background_name',
  [COLUMN]: 'edit_section_column_background_name',
  [STRIP_COLUMNS_CONTAINER]: 'edit_section_strip_background_name',
  [MEDIA_CONTAINER]: 'edit_section_item_background_name',
  [STRIP_CONTAINER_SLIDE_SHOW_SLIDE]: 'edit_section_slide_background_name',
  [BOX_SLIDE_SHOW_SLIDE]: 'edit_section_slide_background_name',
  [STATE_BOX_STATE]: (editorAPI: EditorAPI, compRef: CompRef) => {
    return translate('edit_section_state_background_name', {
      state_name: getStateTitle(
        editorAPI.dsRead,
        editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef),
        compRef,
      ),
    });
  },
  [EXPANDABLE_MENU]: 'component_label_verticalMenu',
  [TPA_WIDGET]: (editorAPI: EditorAPI, compRef: CompRef) => {
    const appDefId = editorAPI.components.data.get(compRef).appDefinitionId;
    return translate(getTpaWidgetDisplayNameOverrideMap[appDefId]);
  },
};

const getTpaWidgetDisplayNameOverrideMap: {
  [appDefId: string]: string;
} = {
  //TODO: use constant from editor-platform when available
  '225dd912-7dea-4738-8688-4b8c6955ffc2':
    'wixstudio.edit-content_component_label_FormContainer',
};

export const getStageDisplayNameOverrideMap: { [compType: string]: string } = {
  [STRIP_COLUMNS_CONTAINER]: 'edit_section_strip_name',
  Text_Title: 'edit_section_title_name',
  Text_Paragraph: 'edit_section_paragraph_name',
};

export const COMPONENT_TYPES_WITH_BACKGROUND_TO_CHECK = new Set<string>([
  COLUMN,
  FOOTER,
  HEADER,
  MEDIA_CONTAINER,
  SECTION,
  STRIP_COLUMNS_CONTAINER,
]);

export const QUICK_EDIT_COMPS_TO_SELECT_AND_EDIT_PARENT = new Set<string>([
  BOX_SLIDE_SHOW_SLIDE,
  STRIP_CONTAINER_SLIDE_SHOW_SLIDE,
  STATE_BOX_STATE,
]);

export const QUICK_EDIT_COMPONENTS_TO_DRILL_IN_WITH_OWN_DISPLAY_NAME =
  new Set<string>([SLIDE_SHOW, GRID, ACCORDION_CONTAINER]);

export const QUICK_EDIT_COMPONENTS_SECONDARY_ACTION_NOT_SETTINGS =
  new Set<string>([GRID]);

import { translate } from '#packages/i18n';
import type { EditorState, InnerRoutesCounts } from '#packages/stateManagement';
import { dynamicPages } from '#packages/stateManagement';
import { hoc } from '#packages/util';
import { DATA_BINDING } from '@wix/app-definition-ids';
import React, { type FC, type JSXElementConstructor, useEffect } from 'react';
import type { IRouter } from '../../../../api/routerPages';
import type { DynamicPage } from '../../../types';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const dynamicPagesState = dynamicPages;

export interface DynamicPagesCountProps {
  router: IRouter;
  pages: DynamicPage[];
  appDefinitionId: string;
}

interface MapStateToProps {
  routerInnerRoutesCounts: InnerRoutesCounts;
}

interface MapDispatchToProps {
  fetchInnerRoutesCounts: (pageIds: string[]) => void;
}

const mapStateToProps = (
  { state }: { state: EditorState },
  ownProps: DynamicPagesCountProps,
): MapStateToProps => {
  const routerInnerRoutesCounts = ownProps.pages.reduce(
    (countByPageId: Record<string, AnyFixMe>, page) => {
      countByPageId[page.id] = dynamicPagesState.selectors.getInnerRoutesCount(
        state,
        page.id,
      );
      return countByPageId;
    },
    {},
  );

  return {
    routerInnerRoutesCounts,
  };
};

const mapDispatchToProps: MapDispatchToProps = {
  fetchInnerRoutesCounts: (pageIds) =>
    dynamicPagesState.actions.fetchInnerRoutesCounts(pageIds),
};

interface ConnectedProps
  extends MapStateToProps,
    MapDispatchToProps,
    DynamicPagesCountProps {}

export const withDynamicPageCount = (Component: JSXElementConstructor<any>) => {
  const WithDynamicPageCountHOC: FC<ConnectedProps> = (props) => {
    const {
      pages,
      routerInnerRoutesCounts,
      fetchInnerRoutesCounts,
      appDefinitionId,
      router,
    } = props;

    const pagesWithoutCount = pages
      .filter((page) => !routerInnerRoutesCounts[page.id])
      .map((page) => page.id);

    const showDynamicPageCounts = appDefinitionId === DATA_BINDING;

    useEffect(
      () => {
        if (showDynamicPageCounts) {
          fetchInnerRoutesCounts(pagesWithoutCount);
        }
      },
      [router?.id ?? null, pages.length, pagesWithoutCount.length], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const pagesWithMaybeCount = pages.map((page) => {
      const count = routerInnerRoutesCounts[page.id];
      return count && count > 1
        ? {
            ...page,
            subtitle: translate('Pages_Dynamic_Pages_Items_Count', {
              //fix types
              count: (count > 999 ? '999+' : count) as number,
            }),
          }
        : page;
    });

    return <Component {...props} pages={pagesWithMaybeCount} />;
  };

  return connect(
    EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(WithDynamicPageCountHOC);
};

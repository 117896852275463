import React from 'react';
import { frames } from '#packages/panels';
import { translate as t } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import { hoc } from '#packages/util';
import { panels } from '#packages/stateManagement';

import type { InferComponentProps } from '#packages/apilib';
import type { Dispatch } from 'types/redux';

const { MessagePanelFrame } = frames;

interface ProhibitedCrossEditorPasteMessageOwnProps {
  panelName: string;
  origin: string;
}

type ProhibitedCrossEditorPasteMessageProps = InferComponentProps<
  null,
  typeof mapDispatchToProps,
  ProhibitedCrossEditorPasteMessageOwnProps
>;

const ProhibitedCrossEditorPasteMessage: React.FC<
  ProhibitedCrossEditorPasteMessageProps
> = ({ panelName, openLearnMore }) => {
  return (
    <MessagePanelFrame
      panelName={panelName}
      title={t('section_paste_modal_title')}
      confirmLabel={t('section_paste_modal_cta')}
      cancelLabel={t('section_paste_modal_secondary_cta')}
      onSecondaryAction={openLearnMore}
      illustration={<baseUI.symbol name="componentAlert" />}
      dataHook={'prohibited-cross-editor-paste-message'}
    >
      {t('section_paste_modal_body')}
    </MessagePanelFrame>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openLearnMore: () => {
    dispatch(
      panels.actions.openHelpCenter('2cb6671d-c40c-44b4-a036-9d9c78dac643'),
    );
  },
});

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(ProhibitedCrossEditorPasteMessage);

// @ts-nocheck
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const LABELS = {
  externalUrl: 'gfpp_mainaction_html1',
  externalUrlApplied: 'gfpp_mainaction_applied_html1',
  htmlCode: 'gfpp_mainaction_html',
  htmlCodeApplied: 'gfpp_mainaction_applied_html',
};

export default {
  mainActions(editorAPI, compRef) {
    const compData = editorAPI.components.data.get(compRef);
    const isApplied = !!compData.url;
    let label;

    if (compData.sourceType === 'external') {
      label = isApplied ? LABELS.externalUrlApplied : LABELS.externalUrl;
    } else {
      label = isApplied ? LABELS.htmlCodeApplied : LABELS.htmlCode;
    }

    return [
      {
        label,
        isApplied,
        isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
        onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
      },
    ];
  },
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId(editorAPI, compRef) {
        const data = editorAPI.components.data.get(compRef);
        if (data.sourceType === 'external') {
          return 'a7c2a674-c8df-4c9d-8868-87745b07f4f9';
        }
        return 'aa09ac2a-4f0e-4ffb-b59d-8f1fea2edfc5';
      },
    },
  },
};

import React from 'react';
import _ from 'lodash';

import * as symbols from '@wix/santa-editor-symbols';

import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import { fedopsLogger, editorWixRecorder } from '#packages/util';
import { translate } from '#packages/i18n';
import constants, { type ProgressStatus } from '#packages/constants';
import keyboardShortcuts from '#packages/keyboardShortcuts';

import ActionSuccessSymbol from '../actionSuccess/actionSuccessSymbol';
import { ArenaDropPanel } from '../dropPanel/arenaDropPanel';
import * as topBarData from './topBarData';

import type { TopBarProps } from './topBar';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';
import { getPublishPanelData } from '../dropPanel/publishPanel/publishPanelStructure';
import type { PanelData } from '../dropPanel/textAndLinkPanel';

export type TopBarButtonsProps = TopBarProps & {
  closeDropPanel: (immediate: boolean) => void;
};

const {
  progressButtonClassNames,
  PREVIEW_DATA,
  EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
} = topBarData;
const { MODES } = constants.ROOT_COMPS.TOPBAR;
const MOBILE_DROP_PANEL = constants.ROOT_COMPS.TOPBAR.DROP_PANELS.MOBILE;
const AUTOSAVE_ON_OFF_SHORTCUT_LABEL =
  keyboardShortcuts.topBar.AUTOSAVE_ON_OFF.label;

const isDoneSuccess = (status: ProgressStatus) =>
  status === constants.PROGRESS_STATUS.DONE_SUCCESS;
const isInProgress = (status: ProgressStatus) =>
  status === constants.PROGRESS_STATUS.IN_PROGRESS;
const getSwitchModeTooltipData = (props: AnyFixMe) => {
  return {
    title: props.isMobileEditor
      ? 'TOPBAR_DESKTOP_TOOLTIP_TITLE'
      : 'TOPBAR_MOBILE_TOOLTIP_TITLE_NEW',
    text: props.isMobileEditor
      ? 'TOPBAR_DESKTOP_TOOLTIP_BODY'
      : 'TOPBAR_MOBILE_TOOLTIP_BODY_NEW',
  };
};

class BiManager {
  private sendBiQueue = new Map();
  cancelBi(key: AnyFixMe) {
    if (this.sendBiQueue.has(key)) {
      clearTimeout(this.sendBiQueue.get(key));
      this.sendBiQueue.delete(key);
    }
  }

  onMouseEnter(key: AnyFixMe, sendBi: AnyFixMe) {
    // TODO: after se_publishPanelUnpublishButton is removed, return the timeout of 1500
    const biTimeout = 0;

    if (!this.sendBiQueue.has(key)) {
      const sendBiTask = setTimeout(() => {
        sendBi(coreBi.events.topbar.top_bar_tooltip_open, {
          item: key,
        });
        this.sendBiQueue.delete(key);
      }, biTimeout);
      this.sendBiQueue.set(key, sendBiTask);
    }
  }
}

export const biManager = new BiManager();

const getSwitchModeButtonLabel = (props: AnyFixMe) => {
  const label = props.isMobileEditor
    ? 'TOP_BAR_DESKTOP_EDITOR_LABEL'
    : 'TOP_BAR_MOBILE_EDITOR_LABEL';

  return translate(label);
};

export const deriveSavePanelDataFromProps = (props: AnyFixMe): PanelData => {
  if (props.isFirstSave || props.autosaveDisabledOnDS || props.isDraftMode) {
    return {
      title: 'TOPBAR_SAVE_TOOLTIP_TITLE',
      body: 'TOPBAR_SAVE_TOOLTIP_BODY',
    };
  }

  return {
    title: props.autosaveEnabled
      ? 'Topbar_Site_save_autosave_on_tooltip_title'
      : 'Topbar_Site_save_autosave_off_tooltip_title',
    body: props.autosaveEnabled
      ? 'Topbar_Site_save_autosave_on_tooltip_text'
      : 'Topbar_Site_save_autosave_off_tooltip_text',
    keyText: AUTOSAVE_ON_OFF_SHORTCUT_LABEL,
    links: [
      {
        titleKey: 'TOPBAR_SAVE_TOOLTIP_LINK',
        onClick: props.openRevisions,
      },
    ],
  };
};

const getSaveTooltipData = (props: AnyFixMe) => {
  if (props.isSiteFromOnBoarding) {
    return {
      title: 'ADI_EDITOR_MIGRATION_SAVE_TOOLTIP_TITLE',
      text: 'ADI_EDITOR_MIGRATION_SAVE_TOOLTIP_TEXT',
    };
  }
  return null;
};

const previewDataIdle = PREVIEW_DATA[constants.PROGRESS_STATUS.IDLE];
const getPreviewTooltipData = (previewingStatus: ProgressStatus) =>
  (PREVIEW_DATA[previewingStatus] || previewDataIdle).tooltipData.regular;

const getBackToEditorFakeLayoutItems = () => {
  return [
    { type: 'symbol', width: 60, className: 'fake-preview-item-symbol' },
    {
      type: 'text',
      content: translate('TOPBAR_PREVIEW_TOOLTIP_TITLE'),
      className: 'fake-preview-item-text',
    },
    { type: 'text', content: translate('TOPBAR_Site_Publish') },
  ];
};

const toggleDesktopMobileViewIfNeeded = (
  props: AnyFixMe,
  isMobile: AnyFixMe,
) => {
  const category = `editor_view_mode_${isMobile ? 'mobile' : 'desktop'}`;
  props.setEditorMode(isMobile);
  editorWixRecorder.addLabel(`${category} top bar clicked`);
  const stageMode = props.isZoomMode ? MODES.ZOOM_OUT : MODES.EDITOR;
  props.sendBi(coreBi.events.topbar.top_bar_click, {
    category,
    origin: props.isPreviewMode ? MODES.PREVIEW : stageMode,
    is_published: props.isSitePublished,
  });
};

const getModeSwitchButtons =
  (modesToShow: AnyFixMe) => (props: TopBarButtonsProps) => [
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.DESKTOP_MODE,
      automationId: 'top-bar-button-switch-mode-desktop',
      symbolName: 'topBarNewDesktop',
      className: util.cx({
        'top-bar-btn-device': true,
        desktop: true,
        toggled: !props.isMobileEditor,
      }),
      MODES: modesToShow,
      onClick() {
        if (props.isMobileEditor) {
          toggleDesktopMobileViewIfNeeded(props, false);
        }
      },
      tooltipData: {
        title: props.isMobileEditor
          ? 'TOPBAR_DESKTOP_TOOLTIP_TITLE'
          : 'TOPBAR_DESKTOP_TITLE_NEW',
        text: 'TOPBAR_DESKTOP_TOOLTIP_BODY',
      },
      condition() {
        return !util.url.isQA();
      },
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.MOBILE_MODE,
      automationId: 'top-bar-button-switch-mode-mobile',
      symbolName: 'topBarNewMobile',
      className: util.cx({
        'top-bar-btn-device': true,
        mobile: true,
        toggled: props.isMobileEditor,
      }),
      MODES: modesToShow,
      onClick() {
        if (!props.isMobileEditor) {
          toggleDesktopMobileViewIfNeeded(props, true);
        }
      },
      tooltipData: {
        title: props.isMobileEditor
          ? 'TOPBAR_MOBILE_TITLE_NEW'
          : 'TOPBAR_MOBILE_TOOLTIP_TITLE_NEW',
        text: 'TOPBAR_MOBILE_TOOLTIP_BODY_NEW',
      },
      condition() {
        return !util.url.isQA();
      },
    },
  ];
const getEditorModeSwitchButtons = getModeSwitchButtons([
  MODES.EDITOR,
  MODES.PREVIEW,
]);
const getPreviewModeSwitchButtons = getModeSwitchButtons([MODES.PREVIEW]);

const getTopBarButtons = (props: TopBarButtonsProps) =>
  _([
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.EDITOR_SEARCH,
      automationId: 'top-bar-button-editor-search',
      className: 'top-bar-button-editor-search',
      ref: 'topBarEditorSearchButton',
      symbolName: 'editorSearchButton',
      MODES: [MODES.EDITOR],
      noTranslate: true,
      onClick() {
        props.openSearchPanel('top_bar');
        editorWixRecorder.addLabel('search top bar clicked');
        props.sendBi(coreBi.events.topbar.top_bar_click, {
          category: 'search',
          is_published: props.isSitePublished,
        });
      },
      onMouseEnter() {
        props.sendBi(coreBi.events.editorSearch.topBarTooltipShown, {
          clickType: 'hover',
        });
      },
      toggled: props.isEditorSearchPanelOpen,
      tooltipData: {
        title: 'Editor_Search_Icon_Tooltip_Title',
        text: 'Editor_Search_Icon_Tooltip_Description',
      },
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.ARENA,
      automationId: 'top-bar-button-arena',
      ref: 'topBarArenaButton',
      symbolName: 'arenaTopBarButton',
      MODES: [MODES.EDITOR],
      className: util.cx({
        'top-bar-button-arena': true,
        hidden: props.isPopupOpen,
      }),
      onClick() {
        props.sendBi(coreBi.events.topbar.arena_tooltip_opened, {
          clickType: 'click',
        });
      },
      onMouseEnter() {
        props.sendBi(coreBi.events.topbar.arena_tooltip_opened, {
          clickType: 'hover',
        });
      },
      onMouseLeave() {
        props.closeDropPanel();
      },
      tooltipData: {},
      customTooltip: {
        content: React.createElement(ArenaDropPanel, {
          key: `${constants.ROOT_COMPS.TOPBAR.BUTTONS.ARENA}DropPanel`,
          sendBi: props.sendBi,
        }),
        overrideMaxWidth: 510,
        interactive: true,
      },
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.ZOOM,
      automationId: 'top-bar-button-zoom-out',
      ref: 'topBarZoomOutButton',
      symbolName: 'zoomOut_v3',
      MODES: [MODES.EDITOR],
      className: util.cx({
        'top-bar-button-zoom': true,
        hidden: props.isPopupOpen,
      }),
      onClick() {
        props.enterZoomMode();
      },
      tooltipData: {
        title: 'TOPBAR_ZoomOut_Tooltip_Title',
        text: 'TOPBAR_ZoomOut_Tooltip_Body',
      },
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.UNDO,
      automationId: 'top-bar-button-undo',
      ref: 'undoButton',
      symbolName: 'undo2_v2',
      MODES: [MODES.EDITOR],
      onClick() {
        props.undo();
        const biParams = props.undoLastSnapshotParams;
        editorWixRecorder.addLabel('undo top bar clicked');
        props.sendBi(coreBi.events.topbar.top_bar_click, {
          category: 'undo',
          is_published: props.isSitePublished,
        });
        props.sendBi(coreBi.events.editor.undo_redo_component_change, {
          type: 'undo',
          origin: 'topbar',
          pageId: biParams?.currentPage,
          date_of_action: biParams?.date_of_action,
          actionName: biParams?.label,
          component_id: biParams?.component_id,
          component_type: biParams?.component_type,
        });
      },
      tooltipData: {
        text: 'TOPBAR_UNDO_TOOLTIP_TITLE',
      },
      disabled:
        props.idePaneState ===
        constants.DEVELOPER_MODE.CONTAINER_STATES.MAXIMIZED,
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.REDO,
      automationId: 'top-bar-button-redo',
      className: 'top-bar-button-redo',
      ref: 'redoButton',
      symbolName: 'redo2_v2',
      MODES: [MODES.EDITOR],
      onClick() {
        props.redo();
        const biParams = props.redoLastSnapshotParams;
        editorWixRecorder.addLabel('redo top bar clicked');
        props.sendBi(coreBi.events.topbar.top_bar_click, {
          category: 'redo',
          is_published: props.isSitePublished,
        });
        props.sendBi(coreBi.events.editor.undo_redo_component_change, {
          type: 'redo',
          origin: 'topbar',
          pageId: biParams?.currentPage,
          date_of_action: biParams?.date_of_action,
          actionName: biParams?.label,
          component_id: biParams?.component_id,
          component_type: biParams?.component_type,
        });
      },
      tooltipData: {
        text: 'TOPBAR_REDO_TOOLTIP_TITLE',
      },
      disabled:
        props.idePaneState ===
        constants.DEVELOPER_MODE.CONTAINER_STATES.MAXIMIZED,
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.MOBILE,
      automationId: 'top-bar-button-switch-mode',
      symbolName: props.isMobileEditor ? 'topBarDesktop' : 'topBarMobile',
      className: util.cx({
        'top-bar-btn-device': true,
        active: props.openedDropPanel === 'MOBILE',
      }),
      MODES: [MODES.EDITOR, MODES.PREVIEW],
      label: getSwitchModeButtonLabel(props),
      onClick() {
        if (props.openedDropPanel === MOBILE_DROP_PANEL) {
          props.closeDropPanel(true);
        } else {
          props.openDropPanel(MOBILE_DROP_PANEL);
        }
        editorWixRecorder.addLabel('editor_view_mode top bar clicked');
        props.sendBi(coreBi.events.topbar.top_bar_click, {
          category: 'editor_view_mode',
          origin: props.isPreviewMode ? MODES.PREVIEW : MODES.EDITOR,
          is_published: props.isSitePublished,
        });
      },
      onMouseEnter() {
        if (props.openedDropPanel === MOBILE_DROP_PANEL) {
          props.openDropPanel(MOBILE_DROP_PANEL);
        }
      },
      onMouseLeave() {
        props.closeDropPanel();
      },
      tooltipData: getSwitchModeTooltipData(props),
      condition() {
        return util.url.isQA();
      },
    },
    _.defaults(
      isDoneSuccess(props.savingStatus)
        ? {
            customSymbol: React.createElement(ActionSuccessSymbol),
          }
        : {
            symbolName: 'topBarSave',
            symbolClassName: 'top-bar-save-symbol',
          },
      {
        key: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
        automationId: 'top-bar-button-save',
        ref: 'saveButton',
        className: util.cx({
          'top-bar-btn-save': true,
          [progressButtonClassNames[props.savingStatus] || '']: true,
        }),
        label: translate('TOPBAR_Site_Save'),
        MODES: util.localModeUtils.isLocalModeEnabled()
          ? [MODES.EDITOR]
          : [MODES.EDITOR, MODES.PREVIEW], // TODO: Change this to use overrides. _.defaultsDeep merges arrays. https://github.com/wix-private/santa-editor/pull/17909
        onClick() {
          props.save('topbar');
          props.closeDropPanel(true);
          editorWixRecorder.addLabel('save top bar clicked');
          props.sendBi(coreBi.events.topbar.top_bar_click, {
            category: 'save',
            origin: props.isPreviewMode ? MODES.PREVIEW : MODES.EDITOR,
            is_published: props.isSitePublished,
          });
        },
        onMouseEnter() {
          if (!getSaveTooltipData(props)) {
            props.openDropPanel(constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SAVE);
          }
        },
        onMouseLeave() {
          props.closeDropPanel();
        },
        disabled:
          props.isPublishInProgress ||
          props.isSaveInProgress ||
          props.isBuildInProgress,
        bubbleStyleOverrides: null,
        dropPanelData: deriveSavePanelDataFromProps(props),
        tooltipData: getSaveTooltipData(props),
      },
    ),
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PREVIEW,
      automationId: 'top-bar-button-preview',
      symbolName: 'topBarPreview',
      className: util.cx({
        'top-bar-button-preview': true,
        [progressButtonClassNames[props.previewingStatus] || '']: true,
      }),
      label: translate('TOPBAR_PREVIEW_TOOLTIP_TITLE'),
      MODES: [MODES.EDITOR],
      onClick() {
        props.togglePreview();
        editorWixRecorder.addLabel('preview top bar clicked');
        props.sendBi(coreBi.events.topbar.top_bar_click, {
          category: 'preview',
          is_published: props.isSitePublished,
        });
        props.sendBi(coreBi.events.preview.PREVIEW_CLICK, {
          origin: constants.BI.PREVIEW.ORIGIN.TOP_BAR,
        });
      },
      disabled: isInProgress(props.previewingStatus),
      tooltipData: getPreviewTooltipData(props.previewingStatus),
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.BACK_TO_EDITOR,
      automationId: 'top-bar-button-back-to-editor',
      className: 'top-bar-btn-back-to-editor',
      label: translate('TOPBAR_BACK_TO_EDITOR'),
      MODES: [MODES.PREVIEW],
      fakeLayoutItems: getBackToEditorFakeLayoutItems(),
      onClick() {
        props.sendBi(
          coreBi.events.topbar.top_bar_Preview_Page_Back_to_Editor_click,
        );
        props.togglePreview();
        if (props.isMobileEditor) {
          props.sendBi(coreBi.events.mobileEditor.MOBILE_EDITOR_READY, {
            isFirst: props.hasUserSeenMobileEditor,
          });
        }
        props.sendBi(
          coreBi.events.topbar.top_bar_Preview_Page_Back_to_Editor_click,
          {
            origin: 'thumb',
          },
        );
      },
      tooltipData: null,
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PUBLISH,
      automationId: 'top-bar-button-publish',
      ref: 'publishButton',
      customSymbol: isDoneSuccess(props.publishingStatus)
        ? React.createElement(ActionSuccessSymbol)
        : null,
      className: util.cx({
        'top-bar-btn-publish': true,
        'top-bar-edit-mode-last-btn': true,
        [progressButtonClassNames[props.publishingStatus] || '']: true,
      }),
      label: translate('TOPBAR_Site_Publish'),
      MODES: [MODES.EDITOR],
      onClick() {
        // NOTE: ⚠️ legacy publish button logic.
        // Will be removed soon after `se_topBarRedesign` experiment

        const sourceOfStart: PublishInteractionStartedSource =
          'topBar_publishButton';

        if (!props.shouldSaveBeforePublish) {
          // NOTE: https://github.com/wix-private/santa-editor/issues/37029
          fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
            paramsOverrides: {
              sourceOfStart,
            },
          });

          props.sitePublish({
            origin: 'top_bar_publish',
            sourceOfStart,
          });
        } else {
          props.siteSaveAndPublish({
            origin: 'top_bar_publish',
            sourceOfStart,
          });
        }
        props.closeDropPanel(true);
        editorWixRecorder.addLabel('publish top bar clicked');
        props.sendBi(coreBi.events.topbar.top_bar_click, {
          category: 'publish',
          is_published: props.isSitePublished,
        });
        const currentViewMode = util.publishUtil.getPublishViewMode(
          props.isMobileEditor,
          props.isPreviewMode,
        );

        props.sendBi(coreBi.events.publish.PUBLISH_CLICK, {
          origin: 'topbar',
          viewModeSource: currentViewMode,
          numberOfManualPublish: props.numberOfManualPublish
            ? props.numberOfManualPublish + 1
            : 1,
          devMode: props.devMode,
          clickId: props.clickId,
        });
      },
      onMouseEnter() {
        biManager.onMouseEnter('Publish', props.sendBi);
        props.openDropPanel(constants.ROOT_COMPS.TOPBAR.DROP_PANELS.PUBLISH);
      },
      onMouseLeave() {
        props.closeDropPanel();
      },
      disabled:
        props.isPublishInProgress ||
        props.isSaveInProgress ||
        props.isBuildInProgress ||
        !props.canUserPublish,
      dropPanelData: getPublishPanelData(props as AnyFixMe),
      bubbleStyleOverrides: EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
    },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.PUBLISH_RC,
      automationId: 'top-bar-button-publish-rc',
      ref: 'publishRCButton',
      customSymbol: React.createElement(symbols.symbol, { name: 'arrowDown' }),
      className: util.cx({
        'top-bar-publish-rc': true,
        active:
          props.openedDropPanel ===
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.PUBLISH_RC,
      }),
      MODES: [MODES.EDITOR],
      onClick() {
        if (
          props.openedDropPanel ===
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.PUBLISH_RC
        ) {
          props.closeDropPanel(true);
        } else {
          props.openDropPanel(
            constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.PUBLISH_RC,
          );
        }
        props.sendBi(coreBi.events.publish.PUBLISH_DROPDOWN_CLICKED, {
          origin: 'topbar',
        });
      },
      onMouseEnter() {
        props.openDropPanel(constants.ROOT_COMPS.TOPBAR.DROP_PANELS.PUBLISH_RC);
      },
      onMouseLeave() {
        props.closeDropPanel();
      },
      dropPanelData: {
        title: 'TOPBAR_PUBLISH_TEST_SITE_TOOLTIP_TITLE',
        body: 'TOPBAR_PUBLISH_TEST_SITE_TOOLTIP_BODY',
      },
      closeOnMouseLeave: false,
      disabled:
        props.isPublishInProgress ||
        props.isSaveInProgress ||
        props.isBuildInProgress ||
        !props.canUserPublish,
      bubbleStyleOverrides: EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
      condition: () =>
        props.isDeveloperModeEnabled &&
        props.canUserPublish &&
        props.isSitePublished,
    },
  ])
    .flatten()
    .compact()
    .value();

const symbolNameToReactComponent = {
  vi: React.createElement(ActionSuccessSymbol),
};

const resolveCustomButton = (item: AnyFixMe) => {
  if (typeof item.customSymbol === 'string') {
    const symbolComponent =
      symbolNameToReactComponent[
        item.customSymbol as keyof typeof symbolNameToReactComponent
      ];
    return _.defaults({}, { customSymbol: symbolComponent }, item);
  }
  return item;
};

const createMapTopBarPropsToButtons =
  (mapper: AnyFixMe) => (props: TopBarButtonsProps, overridden: AnyFixMe) =>
    // eslint-disable-next-line lodash/chaining,lodash/unwrap
    (_(props).thru(mapper) as AnyFixMe) // TS2339: Property 'reject' does not exist on type 'Primitive '.
      .reject((item: AnyFixMe) => item.condition && !item.condition())
      .thru((item: AnyFixMe) =>
        util.overrideUtils.applyOverrides(item, overridden),
      )
      .map(resolveCustomButton)
      .value();

export default {
  mapTopBarPropsToLeftButtons: (props: TopBarButtonsProps) =>
    createMapTopBarPropsToButtons(getEditorModeSwitchButtons)(
      props,
      props.overriddenLeftButtons,
    ),
  mapTopBarPropsToRightButtons: (props: TopBarButtonsProps) =>
    createMapTopBarPropsToButtons(getTopBarButtons)(
      props,
      props.overriddenRightButtons,
    ),
  mapTopBarPropsToModeSwitchButtons: (props: TopBarButtonsProps) =>
    createMapTopBarPropsToButtons(getPreviewModeSwitchButtons)(
      props,
      props.overriddenLeftButtons,
    ),
};

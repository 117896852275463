import React, { useMemo } from 'react';
import constants from '#packages/constants';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import { hoc, biLogger } from '#packages/util';
import { userPreferences } from '#packages/stateManagement';
import { pagesSettingsLayoutsChangeLayout } from '@wix/bi-logger-editor/v2';
import { AUTOMATION_IDS, Layouts } from './constants';
import styles from './PageSettingsLayoutTab.scss';

import type { PageMenuItem } from './types';
import type { EditorAPI } from '#packages/editorAPI';
import {
  CustomScroll,
  Composites,
  RadioButtonsWithIllustration,
  Text,
  TextButton,
} from '@wix/wix-base-ui';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';

const { USER_SET_MOBILE_LANDING_PAGE } = constants.USER_PREFS.MOBILE_EDITOR;

const getSiteUserPreferencesKey = (pageId: string) =>
  `${USER_SET_MOBILE_LANDING_PAGE}_${pageId}`;

const optionsContentMap = {
  desktop: {
    [Layouts.StandartPage]: {
      image: 'layoutStandardSelected_B',
      title: 'Pages_Layouts_Standard_Title',
      text: 'Pages_Layouts_Standard_Text',
    },
    [Layouts.NoHeaderFooter]: {
      image: 'layoutLandingSelected_B',
      title: 'Pages_Layouts_Landing_Title',
      text: 'Pages_Layouts_Landing_NoHeader_Text',
      learnMore: 'Pages_Layouts_Landing_Learn',
    },
  },
  mobile: {
    [Layouts.StandartPage]: {
      image: 'layoutStandardMobileSelected_B',
      title: 'Mobile_Pages_Layouts_Standard_Title',
      text: 'Mobile_Pages_Layouts_Standard_Text',
    },
    [Layouts.NoHeaderFooter]: {
      image: 'layoutLandingMobileSelected_B',
      title: 'Mobile_Pages_Layouts_Landing_Title',
      text: 'Mobile_Pages_Layouts_Landing_NoHeader_Text',
      learnMore: 'Mobile_Pages_Layouts_Landing_Learn',
    },
  },
};

export interface PageSettingsLayoutTabOwnProps {
  menuItem: PageMenuItem;
}

interface PageSettingsLayoutTabStateProps {
  pageLayout: Layouts;
  isMobile: boolean;
}

interface PageSettingsLayoutTabDispatchProps {
  onChange: (newValue: Layouts) => void;
  openLearnMore: () => void;
}

interface PageSettingsLayoutTabProps
  extends PageSettingsLayoutTabOwnProps,
    PageSettingsLayoutTabStateProps,
    PageSettingsLayoutTabDispatchProps {}

const PageSettingsLayoutTab: React.FC<PageSettingsLayoutTabProps> = ({
  pageLayout,
  onChange,
  openLearnMore,
  isMobile,
}) => {
  const shouldShowNote = !isMobile && pageLayout === Layouts.NoHeaderFooter;
  const options = useMemo(() => {
    const viewType = isMobile ? 'mobile' : 'desktop';
    const standartPageContent =
      optionsContentMap[viewType][Layouts.StandartPage];
    const noHeaderFooterContent =
      optionsContentMap[viewType][Layouts.NoHeaderFooter];
    return [
      {
        value: Layouts.StandartPage,
        image: <symbols.symbol name={standartPageContent.image} />,
        title: (
          <Text
            skin="standard"
            size="large"
            enableEllipsis={false}
            shouldTranslate={false}
          >
            {translate(standartPageContent.title)}
          </Text>
        ),
        text: (
          <div className={styles.optionText}>
            {translate(standartPageContent.text)}
          </div>
        ),
      },
      {
        value: Layouts.NoHeaderFooter,
        image: <symbols.symbol name={noHeaderFooterContent.image} />,
        title: (
          <Text
            skin="standard"
            size="large"
            enableEllipsis={false}
            shouldTranslate={false}
          >
            {translate(noHeaderFooterContent.title)}
          </Text>
        ),
        text: (
          <div className={styles.optionText}>
            <Text
              size="small"
              weight="thin"
              skin="secondary"
              enableEllipsis={false}
              shouldTranslate={false}
            >
              {translate(noHeaderFooterContent.text)}
            </Text>
            <br />
            <TextButton
              dataHook={AUTOMATION_IDS.LEARN_MORE_BTN}
              size="small"
              shouldTranslate={false}
              onClick={openLearnMore}
            >
              {translate(noHeaderFooterContent.learnMore)}
            </TextButton>
          </div>
        ),
      },
    ];
  }, [isMobile, openLearnMore]);

  return (
    <CustomScroll>
      <Composites.RadioButtonsWithIllustration
        className={styles.radioButtonsContainer}
      >
        <RadioButtonsWithIllustration
          dataHook={AUTOMATION_IDS.RADIO_BUTTONS}
          value={pageLayout}
          onChange={onChange}
          options={options}
        />
      </Composites.RadioButtonsWithIllustration>

      {shouldShowNote && (
        <div
          data-hook={AUTOMATION_IDS.NOTE_CONTAINER}
          className={styles.noteContainer}
        >
          <Text
            size="small"
            weight="thin"
            shouldTranslate={false}
            enableEllipsis={false}
          >
            {translate('Pages_Layouts_NoHeader_Info_Text')}
          </Text>
        </div>
      )}

      {isMobile && (
        <div className={styles.mobileFooterNote}>
          <Text
            size="tiny"
            weight="thin"
            skin="secondary"
            shouldTranslate={false}
            enableEllipsis={false}
          >
            {translate('Mobile_Pages_Settings_Info_Text')}
          </Text>
        </div>
      )}
    </CustomScroll>
  );
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  _d,
  _s,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const mapStateToProps: MapStateToProps<
  PageSettingsLayoutTabStateProps,
  PageSettingsLayoutTabOwnProps
> = ({ editorAPI }, { menuItem }) => {
  const isMobile = editorAPI.isMobileEditor();
  const isLandingPage = isMobile
    ? menuItem.pageData.isMobileLandingPage
    : menuItem.pageData.isLandingPage;
  const pageLayout = isLandingPage
    ? Layouts.NoHeaderFooter
    : Layouts.StandartPage;

  return {
    pageLayout,
    isMobile,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  PageSettingsLayoutTabDispatchProps,
  PageSettingsLayoutTabOwnProps
> = (dispatch: Dispatch, ownProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const isMobile = editorAPI.isMobileEditor();
  const pageId = ownProps.menuItem.pageData.id;

  const onChangeDesktop = (newValue: Layouts) => {
    const state = editorAPI.store.getState();
    const isLandingPage = newValue === Layouts.NoHeaderFooter;
    const hasChangedMobilePage =
      userPreferences.selectors.getSiteUserPreferences(
        getSiteUserPreferencesKey(pageId),
      )(state);

    editorAPI.pages.data.update(pageId, {
      isLandingPage,
      ...(!hasChangedMobilePage
        ? { isMobileLandingPage: isLandingPage }
        : null),
    });

    editorAPI.history.add('page property change');

    biLogger.report(
      pagesSettingsLayoutsChangeLayout({
        category: isLandingPage ? 'no_header_and_footer' : 'standard',
      }),
    );
  };

  const onChangeMobile = (newValue: Layouts) => {
    const isMobileLandingPage = newValue === Layouts.NoHeaderFooter;

    editorAPI.pages.data.update(pageId, {
      isMobileLandingPage,
    });

    editorAPI.history.add('page property change');

    biLogger.report(
      pagesSettingsLayoutsChangeLayout({
        category: isMobileLandingPage ? 'no_header_and_footer' : 'standard',
      }),
    );
    dispatch(
      userPreferences.actions.setSiteUserPreferences(
        getSiteUserPreferencesKey(pageId),
        true,
      ),
    );
  };

  const openLearnMore = () =>
    editorAPI.panelManager.openHelpCenter(
      isMobile
        ? 'daaeb4e0-d9e8-4e5a-9894-b5ade0f8bd1a'
        : '3ff92482-4a9e-48f1-af56-c7ed01571ad2',
      null,
      {
        panel_name: isMobile ? 'tab_layout' : 'tab_mobile_layout',
        origin: constants.BI.HELP.ORIGIN.PAGES,
        learn_more: true,
      },
    );

  return {
    onChange: isMobile ? onChangeMobile : onChangeDesktop,
    openLearnMore,
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PageSettingsLayoutTab);

import constants from '#packages/constants';
import type { EditorAPI } from '@wix/editor-elements-types/editor';
import { HttpClient } from '@wix/http-client';

// TODO move the usage of bulk-uninstall to single place for all types of apps
// now it is also prenent here
// @see https://github.com/wix-private/app-market-client/blob/master/packages/manage-installed-apps/src/api/api.ts#L106
export const bulkUninstall = (appDefId: string, editorAPI: EditorAPI) => {
  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;
  const httpClient = new HttpClient({
    getAppToken: () => metaSiteInstance,
  });

  return httpClient
    .post('/_api/site-apps/v1/site-apps/bulk-uninstall', {
      apps: [appDefId],
    })
    .catch((e) => {
      console.log(`err in bulkUninstall ${e.message}`);
      throw new Error(e);
    });
};

// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import { utils as themeUtils } from '#packages/theme';
import {
  arrayUtils,
  animations as animationsUtils,
  compIcon,
  backgroundUtils,
  sections,
} from '#packages/util';
import experiment from 'experiment';
import { isMeshLayoutEnabled } from '#packages/layoutOneDockMigration';
import type { EditorAPI } from '#packages/editorAPI';
import {
  AnimationsApiKey,
  SiteSegmentMediaBackgroundApiKey,
} from '#packages/apis';
import type { CompRef, ColorPalette, PagesData } from 'types/documentServices';

const { getBehaviors, getSkin, isReferredId } =
  stateManagement.components.selectors;
const {
  closePanelByName,
  updateOrOpenPanel,
  openComponentPanel,
  closeOpenedPanels,
} = stateManagement.panels.actions;
const { selectOpenPanels, selectHelpProps } = stateManagement.panels.selectors;
const {
  isInInteractionMode,
  isShownOnlyOnVariant,
  getCompVariantPointer,
  isInteractionsSupported,
  componentHasInteraction,
} = stateManagement.interactions.selectors;

const IMAGE_DATA_KEYS = [
  'uri',
  'width',
  'height',
  'title',
  'originalImageDataRef',
  'type',
];
const SCALE_STEP = 1.05;
const ANIMATION_ACTIONS = ['screenIn', 'modeIn', 'modeOut', 'modeChange'];
const MOBILE_ANIMATION_ACTIONS = ['screenIn', 'inlinePopupIn', 'backToTopIn'];
const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function isDataDefined(
  editorAPI: EditorAPI,
  compRef: CompRef[],
  key: string,
  dataType: string,
) {
  let data;
  if (dataType === 'property') {
    data = editorAPI.components.properties.get(compRef);
  } else {
    data = editorAPI.components.data.get(compRef);
  }
  return (
    data && ((!_.isObject(data[key]) && !!data[key]) || !_.isEmpty(data[key]))
  );
}

function isSkinDefined(editorAPI, compRef) {
  return !!getSkin(compRef, editorAPI.dsRead);
}

function isPanelOpen(editorAPI, panelName) {
  return !!findPanel(editorAPI, panelName);
}

function openPanel(editorAPI, panelName, optionalProps) {
  if (constants.componentPanels[panelName]) {
    return editorAPI.openComponentPanel(
      constants.componentPanels[panelName],
      optionalProps,
    );
  }

  return editorAPI.store.dispatch(updateOrOpenPanel(panelName, optionalProps));
}

function findPanel(editorAPI, panelName, forType) {
  //forType is an optional parameter used for cases where the panel is dynamically built and changes according to the component type (like the design panel for instance)
  const openedPanels = selectOpenPanels(editorAPI.store.getState());

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(openedPanels, function (panel) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/last
    const name = _.last(panel.name.split('.'));
    return (
      (panel.name === panelName ||
        constants.componentPanels[panelName] === name) && // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      (_.isUndefined(forType) || forType === panel.props.compType)
    );
  });
}

function closePanel(editorAPI, panelName) {
  const panelToClose = findPanel(editorAPI, panelName);
  if (panelToClose) {
    editorAPI.store.dispatch(closePanelByName(panelToClose.name));
  }
}

function togglePanel(editorAPI, panelName, optionalProps) {
  if (isPanelOpen(editorAPI, panelName)) {
    return closePanel(editorAPI, panelName);
  }

  editorAPI.store.dispatch(closeOpenedPanels());
  return openPanel(editorAPI, panelName, optionalProps);
}

function toggleComponentPanel(
  editorAPI: EditorAPI,
  fullPanelName: string,
  panelProps: Record<string, unknown>,
  forType?,
) {
  const { dispatch } = editorAPI.store;
  //forType is an optional parameter used for cases where the panel is dynamically built and changes according to the component type (like the design panel for instance)
  if (findPanel(editorAPI, fullPanelName, forType)) {
    dispatch(closePanelByName(fullPanelName));
  } else {
    dispatch(closeOpenedPanels());
    dispatch(openComponentPanel(fullPanelName, panelProps));
  }
}

function getSelectedComponentScale(editorAPI: EditorAPI, compRef: CompRef) {
  return editorAPI.components.layout.get_scale(compRef);
}

function changeScale(changeScaleInPercentage, editorAPI, compRef) {
  const curScale = getSelectedComponentScale(editorAPI, compRef);
  const newScale = curScale * changeScaleInPercentage;
  editorAPI.components.layout.updateAndAdjustLayout(compRef, {
    scale: newScale,
  });
}

function scaleUp(editorAPI, compRef) {
  changeScale(SCALE_STEP, editorAPI, compRef);
}

function scaleDown(editorAPI, compRef) {
  changeScale(1 / SCALE_STEP, editorAPI, compRef);
}

function getDataDefinedFn(key: string, dataType?: unknown) {
  return (editorAPI: EditorAPI, compRefs: CompRef[]) => {
    return isDataDefined(editorAPI, compRefs, key, dataType);
  };
}

const getTogglePanelFn =
  (panelName: string, optionalProps?: Record<string, unknown>) =>
  (
    editorAPI: EditorAPI,
    compRef?: CompRef[],
    origin?: unknown,
    optionalPropsOverrides?: Record<string, unknown>,
  ): void => {
    const props = {
      ...(optionalPropsOverrides || {}),
      selectedComponent: compRef,
      origin,
      compType: editorAPI.components.getType(compRef),
      ...(optionalProps || {}),
    };
    return togglePanel(editorAPI, panelName, props);
  };

function getToggleCompPanelFn(panelName, optionalProps, forType?) {
  return function (editorAPI) {
    toggleComponentPanel(editorAPI, panelName, optionalProps, forType);
  };
}

function getPanelStateFn(panelName) {
  return function (editorAPI) {
    return isPanelOpen(editorAPI, panelName);
  };
}

function isInteractionTransitionDisabled(editorAPI) {
  const { transitions } = editorAPI.documentServices.components;
  const compRef = editorAPI.selection.getSelectedComponents()[0];

  if (!transitions) {
    return true;
  }

  return !transitions.has(compRef);
}

function interactionNotOnlyOnVariantComp(editorAPI, compRefs) {
  return (
    isInInteractionMode(editorAPI.store.getState()) &&
    !isShownOnlyOnVariant(editorAPI, compRefs[0])
  );
}

function openAviaryPanel(editorAPI, compRef) {
  const compData = editorAPI.components.data.get(compRef);
  const panelData = {
    value: _.pick(compData, IMAGE_DATA_KEYS),
    requestChange(editedImageData) {
      if (editedImageData) {
        editorAPI.components.data.update(compRef, editedImageData);
      }
    },
  };

  editorAPI.panelHelpers.openAviaryPanel(panelData);
}

function openImageCrop(
  editorAPI: EditorAPI,
  _compRefUnusedPlsDeleteMe: CompRef[],
): void {
  editorAPI.imageCrop.toggleCropMode(true);
}

function openLinkPanel(editorAPI, compRef) {
  editorAPI.closeCompPanelIfExists();
  editorAPI.openLinkPanel({
    origin: 'gfpp',
    link: editorAPI.components.data.get(compRef).link,
    callback(newLinkData) {
      editorAPI.components.data.update(compRef, { link: newLinkData });
    },
  });
}

function isHelpPanelOpen(editorAPI, helpId) {
  const helpProps = selectHelpProps(editorAPI.store.getState());
  const openedHelpId = helpProps?.helpUrl?.split('?')[0];
  if (!openedHelpId) return false;

  return openedHelpId === helpId;
}

function isAnimationGfppApplied(
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
) {
  if (animationsUtils.isNewAnimationsEnabled(editorAPI)) {
    const animationsApi = editorAPI.host.getAPI(AnimationsApiKey);
    return animationsApi.isGfppHighlighted(arrayUtils.asArray(compRef));
  }

  const behaviors = getBehaviors(compRef, editorAPI.dsRead);
  const appliedActions = editorAPI.isMobileEditor()
    ? MOBILE_ANIMATION_ACTIONS
    : ANIMATION_ACTIONS;
  const _viewMode = editorAPI.viewMode.get();
  return behaviors.some(
    ({ action, viewMode }) =>
      appliedActions.includes(action) && (viewMode === _viewMode || !viewMode),
  );
}

function hasAnimations(editorAPI: EditorAPI, compRef: CompRef | CompRef[]) {
  if (animationsUtils.isNewAnimationsEnabled(editorAPI)) {
    const animationsApi = editorAPI.host.getAPI(AnimationsApiKey);
    return animationsApi.hasAnimations(compRef);
  }

  return isAnimationGfppApplied(editorAPI, compRef);
}

function isMobileAnimationApplied(editorAPI, compRef) {
  const behaviors = getBehaviors(compRef, editorAPI.dsRead);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(
    behaviors,
    (behavior) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(MOBILE_ANIMATION_ACTIONS, behavior.action) &&
      behavior.viewMode !== 'DESKTOP',
  );
}

function isCropApplied(editorAPI, compRef) {
  if (editorAPI.components.hasRuntimeDataOrConnected(compRef)) {
    return false;
  }
  const override = editorAPI.components.properties.get(compRef)?.overrideCrop;
  const data = editorAPI.components.data.get(compRef);

  if (_.isEmpty(override)) {
    return data && !_.isEmpty(data.crop);
  }

  return (
    override.x !== 0 ||
    override.y !== 0 ||
    override.width !== data.width ||
    override.height !== data.height
  );
}

function isMaskApplied(editorAPI, compRef) {
  const maskFromPropertiesOverride =
    editorAPI.components.properties.get(compRef)?.overrideMask?.svgId;
  const maskFromDesignData =
    editorAPI.components.design.get(compRef)?.background?.mediaRef?.mask?.svgId;
  const maskFromData = editorAPI.components.data.get(compRef)?.mask?.svgId;

  return !!(maskFromPropertiesOverride || maskFromDesignData || maskFromData);
}

function isInteractionsApplied(editorAPI, componentRefs: CompRef[]) {
  if (
    !isInteractionsSupported(editorAPI, componentRefs) ||
    editorAPI.isMobileEditor()
  ) {
    return false;
  }
  const compRef = editorAPI.columns.isStrip(componentRefs[0])
    ? editorAPI.columns.getColumnIfStripIsSingleColumn(componentRefs[0])
    : componentRefs[0];

  return componentHasInteraction(editorAPI, compRef);
}

function getInteractionsGfppTooltip(editorAPI, compRefs: CompRef[]): string {
  if (editorAPI.isMobileEditor()) {
    return {
      classPath: 'baseUI.popoverTemplates.titleBodyAndLinkTooltip',
      props: {
        className: 'small',
        title: 'gfpp_tooltip_mobile_interactions_disabled_title',
        text: 'gfpp_tooltip_mobile_interactions_disabled_text',
        link: 'gfpp_tooltip_mobile_interactions_disabled_link',
        linkAction: () => {
          editorAPI.panelManager.openPanel(
            constants.ROOT_COMPS.LEFTBAR.HIDDEN_ELEMENTS_PANEL_NAME,
          );
        },
      },
    };
  }
  return isInteractionsApplied(editorAPI, compRefs)
    ? 'gfpp_tooltip_interactions_active_preset'
    : 'gfpp_tooltip_interactions_main';
}

function isInteractionHideApplied(editorAPI) {
  const state = editorAPI.store.getState();
  if (!isInInteractionMode(state)) {
    return false;
  }
  const compVariantPointer = getCompVariantPointer(state);
  const compTransformations =
    editorAPI.documentServices.components.transformations.get(
      compVariantPointer,
    );
  return compTransformations?.hidden;
}

const shouldDisableBackgroundEffectsByDesignData = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const designData = editorAPI.components.design.get(compRef);
  return backgroundUtils.isTransparentBG(designData);
};

function isStretchApplied(
  editorAPI: EditorAPI,
  compRefOrRefs: CompRef | CompRef[],
) {
  if (isMeshLayoutEnabled()) {
    return editorAPI.components.layout.isFullWidth(compRefOrRefs);
  }

  if (editorAPI.columns.isStrip(compRefOrRefs)) {
    return editorAPI.components.properties.get(compRefOrRefs)?.fullWidth;
  }

  const compRefs = arrayUtils.asArray(compRefOrRefs);
  // NOTE: use ⚠️`compRefs.some` instead of ⚠️`compRefs.every` to preserve old logic,
  // https://github.com/wix-private/santa-editor/blob/7605d9f7f5ec18cabe8f8a918551de40d26e8127/santa-editor/packages/layout/createLayoutApi_structure.ts#L836
  return compRefs.some((compRef) =>
    editorAPI.components.layout.isHorizontallyStretchedToScreen(compRef),
  );
}

function getGfppDataResolver(
  editorAPI: EditorAPI,
  componentRefs: CompRef[],
  origin: string = null,
) {
  return (actions) =>
    _.isFunction(actions) ? actions(editorAPI, componentRefs, origin) : actions;
}

function resolveOnClickAction(actionDescriptor) {
  const { actionType } = actionDescriptor;
  switch (actionType) {
    case ACTIONS.SETTINGS:
    case ACTIONS.LAYOUT:
    case ACTIONS.DESIGN:
    case ACTIONS.MANAGE:
    case ACTIONS.BACKGROUND:
      return getTogglePanelFn(actionType, actionDescriptor.panelProps || {});
    case ACTIONS.PRESETS:
      return getToggleCompPanelFn(
        'compPanels.panels.Widget.widgetPresetsPanel',
      );
    case ACTIONS.SCALE_DOWN:
      return scaleDown;
    case ACTIONS.SCALE_UP:
      return scaleUp;
    case ACTIONS.CHANGE_MEDIA:
      return (editorAPI, compRef) => {
        switch (actionDescriptor.category) {
          case editorAPI.mediaServices.mediaManager.categories.CLIPART:
            editorAPI.mediaServices.changeClipArt(
              compRef,
              actionDescriptor.category,
            );
        }
      };
  }
}

const allowedSkins = [
  'wysiwyg.viewer.skins.page.TransparentPageSkin',
  'wysiwyg.viewer.skins.page.BasicPageSkin',
];

const shouldHidePageDesignButton = (editorAPI: EditorAPI) => {
  const pagesData = editorAPI.pages.getPagesData();
  const theme = editorAPI.theme.colors.getAll() || ({} as ColorPalette);

  return pagesData.every(({ id }: PagesData) => {
    const page = { id, type: 'DESKTOP' };

    const properties = editorAPI.components.style.get(page)?.style?.properties;

    const skin = stateManagement.components.selectors.getSkin(
      page,
      editorAPI.dsRead,
    );

    if (!allowedSkins.includes(skin)) return false;

    const designColor = themeUtils.fromThemeColorToHex(properties?.bg, theme);

    return !designColor || !Number(properties?.['alpha-bg']);
  });
};

const isChangeBackgroundEnabled = () =>
  ['B', 'C'].includes(experiment.getValue('se_changeBackgroundGfppIcon'));

const isChangeBackgroundWithIconOnlyEnabled = () =>
  experiment.getValue('se_changeBackgroundGfppIcon') === 'C';

const getDataGfppWithBackgroundIcon = (
  editorAPI: EditorAPI,
  selectedComponent: CompRef,
  defaultData: { tooltip: string; label: string; isDisabled?: boolean },
) => {
  const isBGIconEnabled = isChangeBackgroundEnabled();
  const isShowingIconOnly = isChangeBackgroundWithIconOnlyEnabled();
  const backgroundMediaType = compIcon.getBackgroundType(
    editorAPI,
    selectedComponent,
  );
  const iconInfo = isBGIconEnabled
    ? compIcon.getCompBackgroundMedia(
        editorAPI,
        selectedComponent,
        !!defaultData.isDisabled,
      )
    : null;
  const buttonLabel = isBGIconEnabled
    ? 'gfpp_mainaction_change_background'
    : defaultData.label;
  const buttonTooltip =
    isShowingIconOnly && !defaultData.isDisabled
      ? 'gfpp_tooltip_change_background'
      : defaultData.tooltip;

  return {
    icon: iconInfo,
    tooltip: buttonTooltip,
    label: !isShowingIconOnly && buttonLabel,
    backgroundMediaType,
  };
};

const siteSegmentBackgroundSupported = (
  editorAPI: EditorAPI,
  selectedComponent: CompRef,
) => {
  return (
    experiment.isOpen('se_siteSegmentMediaBackground') &&
    sections.isSectionsEnabled() &&
    editorAPI.host
      .getAPI(SiteSegmentMediaBackgroundApiKey)
      .compDesignExistsAndHasFills(selectedComponent) &&
    !editorAPI.zoomMode.isStageZoomMode()
  );
};

const isWithinBlocksWidget = (editorAPI: EditorAPI, compRef: CompRef) => {
  return (
    isReferredId(compRef.id) &&
    editorAPI.components.isDescendantOfBlocksWidget(compRef)
  );
};

const isMobileTextReplaceSupported = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  version: 'B' | 'C',
) => {
  const isMobileTextExperiment =
    experiment.getValue('se_editTextContentOnMobile') === version;

  const isCurrentLanguageSecondary =
    editorAPI.language.isCurrentLanguageSecondary();

  const mobileOnlyComponent =
    editorAPI.mobile.mobileOnlyComponents.isMobileOnlyComponent(compRef.id);

  return (
    isMobileTextExperiment &&
    !isCurrentLanguageSecondary &&
    !isReferredId(compRef.id) &&
    !isWithinBlocksWidget(editorAPI, compRef) &&
    !mobileOnlyComponent
  );
};
const getIsHeaderDiscoverabilityExperiment = () => {
  return (
    experiment.getValue('se_headerDiscoverability') === 'B' ||
    experiment.getValue('se_headerDiscoverability') === 'C'
  );
};
const getIsHeaderScrollPanelExperiment = () => {
  return experiment.isOpen('se_headerScrollPanelDiscoverability');
};
const getShouldChangeHeaderScrollGFPP = () => {
  const isHeaderDiscoverabilityExperiment =
    getIsHeaderDiscoverabilityExperiment();

  const isHeaderScrollPanelExperiment = getIsHeaderScrollPanelExperiment();
  return isHeaderDiscoverabilityExperiment || isHeaderScrollPanelExperiment;
};

export default {
  shouldHidePageDesignButton,
  isDataDefined,
  isSkinDefined,
  togglePanel,
  openPanel,
  findPanel,
  toggleComponentPanel,
  isPanelOpen,
  getDataDefinedFn,
  getTogglePanelFn,
  getToggleCompPanelFn,
  getPanelStateFn,
  openAviaryPanel,
  openImageCrop,
  openLinkPanel,
  isHelpPanelOpen,
  isAnimationGfppApplied,
  hasAnimations,
  isInteractionsApplied,
  getInteractionsGfppTooltip,
  isInteractionHideApplied,
  isInteractionTransitionDisabled,
  isMobileAnimationApplied,
  isCropApplied,
  isMaskApplied,
  isStretchApplied,
  isMobileTextReplaceSupported,
  shouldDisableBackgroundEffectsByDesignData,
  resolveOnClickAction,
  interactionNotOnlyOnVariantComp,
  getGfppDataResolver,
  isChangeBackgroundEnabled,
  isChangeBackgroundWithIconOnlyEnabled,
  getDataGfppWithBackgroundIcon,
  siteSegmentBackgroundSupported,
  getIsHeaderDiscoverabilityExperiment,
  getIsHeaderScrollPanelExperiment,
  getShouldChangeHeaderScrollGFPP,
};

import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as core from '#packages/core';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import * as stateManagement from '#packages/stateManagement';
import helpPanelFrameTranslations from './helpPanelFrameTranslations';

import React from 'react';
import * as baseUI from '#packages/baseUI';
import { PanelHeader } from '@wix/wix-base-ui';
import { cx, consentPolicyManager } from '#packages/util';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

const { getViewPort } = stateManagement.domMeasurements.selectors;

function getNewAnswersUrl(helpUrl: string, anchorId?: string): string {
  const { NEW_HELP_WIDGET_ID } = helpIds.HELP_CENTER;
  const languageCode = util.languages.getLanguageCode();
  const articleId = helpUrl?.startsWith('?') ? helpUrl : `article/${helpUrl}`;

  const url = new URL(
    `https://wix.wixanswers.com/apps/widget/v1/wix/${NEW_HELP_WIDGET_ID}/view/${languageCode}/${articleId}`,
  );

  const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();
  if (isNewWorkspace) {
    url.searchParams.set('isNewWorkspace', 'true');
  }

  const contentPolicyHeader = consentPolicyManager._getConsentPolicyHeader();

  Object.keys(contentPolicyHeader).forEach((key: string) => {
    url.searchParams.set(
      key,
      contentPolicyHeader[key as keyof typeof contentPolicyHeader],
    );
  });

  return anchorId ? `${url.toString()}#${anchorId}` : url.toString();
}

interface HelpPanelFrameProps {
  helpUrl: string;
  anchorId?: string;
  closePanelBiEvent?: BiEventDefinition;
  closePanelBiParams?: BiEventFields;
  openPanelBiEvent?: BiEventDefinition;
  openPanelBiParams?: BiEventFields;
  isMobileEditor?: boolean;
  onClose?: () => void;
}

// eslint-disable-next-line react/prefer-es6-class
const HelpPanelFrameSimple = createReactClass<HelpPanelFrameProps>({
  displayName: constants.PANEL_TYPES.HELP,
  mixins: [util.draggableMixin, core.mixins.editorAPIMixin],
  PropTypes: {
    helpUrl: PropTypes.string.isRequired,
    anchorId: PropTypes.string,
    closePanelBiEvent: PropTypes.object,
    closePanelBiParams: PropTypes.object,
    openPanelBiEvent: PropTypes.object,
    openPanelBiParams: PropTypes.object,
    isMobileEditor: PropTypes.bool,
    onClose: PropTypes.func,
  },
  getInitialState() {
    return {
      showLoader: true,
    };
  },

  componentDidMount() {
    if (this.props.openPanelBiEvent && this.props.openPanelBiParams) {
      this.getEditorAPI().bi.event(
        this.props.openPanelBiEvent,
        this.props.openPanelBiParams,
      );
    }
    ReactDOM.findDOMNode(this.refs.helpIframe).addEventListener(
      'load',
      this.hideLoader,
    );
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isMobileEditor !== this.props.isMobileEditor) {
      this.closeHelpCenter();
    }
  },

  getDragLimits() {
    const editorAPI = this.getEditorAPI();
    const viewPort = getViewPort(editorAPI.store.getState());
    return {
      y: [viewPort?.minY || editorAPI.editorConfig.topBarHeight],
    };
  },

  componentWillUnmount() {
    if (this.props.closePanelBiEvent && this.props.closePanelBiParams) {
      this.getEditorAPI().bi.event(
        this.props.closePanelBiEvent,
        this.props.closePanelBiParams,
      );
    }
  },

  closeHelpCenter() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.closeHelpCenter();
    if (this.props.onClose) {
      this.props.onClose();
    }
  },

  onCancel() {
    this.closeHelpCenter();
  },
  hideLoader() {
    this.setState({
      showLoader: false,
    });
  },

  getIframeSrc(): string {
    return getNewAnswersUrl(this.props.helpUrl, this.props.anchorId);
  },

  onWidgetLoaded() {
    this.refs.helpIframe.contentWindow.postMessage(
      {
        type: 'HELP_PANEL_TRANSLATIONS',
        translations: JSON.stringify(helpPanelFrameTranslations),
      },
      '*',
    );
  },

  // data-panel is used in editor assistant to block closing on click outside if click happened inside help center
  render() {
    return (
      <div
        className="help-panel-frame"
        data-hook="help-panel-frame"
        data-panel="help-center"
      >
        <PanelHeader
          // @ts-expect-error
          ref="header"
          onMouseDown={(e: AnyFixMe) => {
            this.startDrag(e, this.getDragLimits());
          }}
          onClose={this.closeHelpCenter}
          noHelpBtn
          className="dragger dark help-panel-frame-drag-handle"
          dataHook="help-panel-frame-header"
        />
        <iframe
          onLoad={this.onWidgetLoaded}
          id={this.props.id}
          src={this.getIframeSrc()}
          ref="helpIframe"
          className={this.props.className || ''}
        />
        <baseUI.preloader className={cx({ hidden: !this.state.showLoader })} />
      </div>
    );
  },
});

const HelpPanelFrame = stateManagement.panels.hoc.panelFrame(
  constants.PANEL_TYPES.HELP,
)(HelpPanelFrameSimple);
(HelpPanelFrame as AnyFixMe).pure = HelpPanelFrameSimple;

export default HelpPanelFrame;

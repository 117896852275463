// @ts-nocheck
import * as coreBi from '#packages/coreBi';

export default {
  getInitialState() {
    return {
      toggleValue: this.getToggleState(),
    };
  },
  UNSAFE_componentWillReceiveProps() {
    this.setState({ toggleValue: this.getToggleState() });
  },
  onToggleChange(toggleValue, originOverride) {
    this.setState({ toggleValue });
    this.runToggleAction(toggleValue);

    switch (this.props.id) {
      case 'mobileView':
        this.sendToggleBIOptimized(
          toggleValue,
          originOverride || 'mobile_view_panel_toggle',
        );
        break;

      case 'backToTop':
        this.sendToggleBIBackToTop(toggleValue);
        break;

      case 'actionBar':
        this.sendToggleBIActionBar(toggleValue);
        break;

      default:
        throw new Error(
          `Error: mobile settings cannot toggle ${this.props.id}`,
        );
    }
  },
  getImage() {
    return this.state.toggleValue ? this.props.image.on : this.props.image.off;
  },
  getToggleState() {
    if (this.props.toggle.state) {
      return this.props.toggle.state();
    }
    return false;
  },
  runToggleAction(toggleValue) {
    if (this.props.toggle.action) {
      this.props.toggle.action(toggleValue);
      this.getEditorAPI().history.add('toggledAction');
    }
  },
  getValueLink() {
    return {
      value: this.getToggleState(),
      requestChange: this.onToggleChange,
    };
  },
  sendToggleBIActionBar(value) {
    this.getEditorAPI().bi.event(
      coreBi.events.mobileEditor.mobileTools.MOBILE_ACTION_BAR_TOGGLE,
      {
        toggle_status: value ? 'on' : 'off',
        site_id: this.getEditorAPI().dsRead.generalInfo.getSiteId(),
      },
    );
  },
  sendToggleBIBackToTop(value) {
    const biEvent =
      coreBi.events.mobileEditor.mobileTools.BACK_TO_TOP_SHOW_TOGGLE;
    const biFields = { toggle_status: value ? 'on' : 'off' };
    this.getEditorAPI().bi.event(biEvent, biFields);
  },
  sendToggleBIOptimized(value, origin) {
    const biEvent =
      this.props.id === 'mobileView'
        ? coreBi.events.topbar.mobile_view_mode_toggle
        : null;
    if (biEvent) {
      const biFields = {
        origin: origin || '',
        status: value ? 1 : 0,
      };
      this.getEditorAPI().bi.event(biEvent, biFields);
    }
  },
};

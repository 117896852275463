// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import * as panels from '#packages/panels';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';

const PANEL_ACTIONS = {
  NAVIGATE_TO_OB: 'Go Back to Wix ADI',
  SAVE: 'Save Changes',
};

function reportPanelActionBI(editorAPI, actionType) {
  editorAPI.bi.event(coreBi.events.ON_BOARDING_MIGRATION.PANEL_ACTION_CLICKED, {
    action: actionType,
  });
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'onBoardingMigrationConfirmationPanel',
  propTypes: {
    panelName: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  },
  mixins: [core.mixins.editorAPIMixin],
  saveDocument() {
    reportPanelActionBI(this.getEditorAPI(), PANEL_ACTIONS.SAVE);
    this.props.onConfirm();
    this.close();
  },
  navigateToOnBoarding() {
    //todo: cancel navigation warning popup
    //todo: navigate to OB
    this.props.onCancel();
    reportPanelActionBI(this.getEditorAPI(), PANEL_ACTIONS.NAVIGATE_TO_OB);
    window.location = util.editorModel.onboardingEditorUrl;
  },
  close() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.closePanelByName(this.props.panelName);
  },
  render() {
    return (
      <panels.frames.FocusPanelFrame
        ref="frame"
        key="onBoardingMigrationConfirmationPanel"
        shouldHideHelp={true}
        title={translate('ADI_EDITOR_MIGRATION_SAVEPOPUP_TITLE')}
        panelName={this.props.panelName}
        className="save-on-boarding-site-confirm-panel"
      >
        <div className="save-on-boarding-site-confirm-content">
          <div className="image-container">
            <baseUI.symbol name="obMigrationBeforeSave" />
          </div>
          <div className="description-container">
            <span className="description-text">
              {translate('ADI_EDITOR_MIGRATION_SAVEPOPUP_TEXT')}
            </span>
          </div>
          <div className="footer-buttons">
            <baseUI.button
              onClick={this.navigateToOnBoarding}
              label="ADI_EDITOR_MIGRATION_SAVEPOPUP_BUTTON_ADI"
              className="btn-confirm-secondary btn-md footer-button"
            />

            <baseUI.button
              onClick={this.saveDocument}
              label="ADI_EDITOR_MIGRATION_SAVEPOPUP_BTN_SAVE"
              className="btn-confirm-primary btn-md footer-button"
            />
          </div>
        </div>
      </panels.frames.FocusPanelFrame>
    );
  },
});

import type { ThunkAction } from 'types/redux';
import { hoc } from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

import type { ConcurrentUserPanelProps } from './concurrentUserPanel';

const saveSite =
  () =>
  (
    _dispatch: AnyFixMe,
    _getState: AnyFixMe,
    { editorAPI }: AnyFixMe,
  ): ThunkAction =>
    editorAPI.saveManager.save();

const mapDispatchToProps: Partial<ConcurrentUserPanelProps> = {
  sendBi: stateManagement.bi.actions.event,
  closePanelByName: stateManagement.panels.actions.closePanelByName,
  openHelpCenter: stateManagement.panels.actions.openHelpCenter,
  saveSite,
};

export default hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps);

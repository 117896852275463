import { services } from '#packages/tpa';

import { searchModule } from '../searchModule';
import { Integrations } from '../constants';
import { openAppMarket } from '../utils/utils';

import type { EditorAPI } from '#packages/editorAPI';

export const integrateAppMarketToSearch = (editorAPI: EditorAPI) => {
  searchModule.addIntegration({
    type: Integrations.APP_MARKET,
    checkVisibility: () => {
      return !editorAPI.isMobileEditor();
    },
    transform: (result) => {
      const {
        id,
        extras: { slug },
      } = result;

      return {
        ...result,
        isSuccessIconVisible: services.tpaAddAppService.isAppInstalled(
          editorAPI,
          id,
          false,
        ),
        onSubmit: () => openAppMarket(editorAPI, slug),
      };
    },
  });
};

import _ from 'lodash';

import type { EditorAPI } from '#packages/editorAPI';

import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import experiment from 'experiment';
import {
  siteHasOldBlog,
  siteHasOldBlogDeprecationComponents,
  showMigrationPopup,
  showDeprecationPopup,
} from '#packages/blog';
import {
  showComponentDeprecationPopup,
  siteHasDeprecatedComponents,
  isAnyDeprecationSiteExperimentOpen,
} from '#packages/componentDeprecation';
import { startNewWorkspaceLeftBarHighlightTour } from '#packages/editorTours';

function translatedVideoIsMissing(videoLink: string) {
  return videoLink === 'na';
}

function isDraftMode(editorAPI: EditorAPI) {
  return editorAPI.dsRead.generalInfo.isDraft();
}

function currentSiteNeverSavedInNewEditor(editorAPI: EditorAPI) {
  const editorVersionsInfo = editorAPI.account.getEditorVersionsInfo();
  const userAlreadySavedInNewEditor =
    window.editorModel.firstSave === false &&
    !isDraftMode(editorAPI) &&
    editorVersionsInfo.isEligibleForSwitch === false;

  return (
    editorVersionsInfo &&
    !editorVersionsInfo.isNewUser &&
    !userAlreadySavedInNewEditor
  );
}

function shouldShowNewWorkspaceLeftBarHighlightTour(editorAPI: EditorAPI) {
  const zoomMode = editorAPI.zoomMode.isInZoomMode();
  const newSite =
    editorAPI.dsRead.generalInfo.isFirstSave() || isDraftMode(editorAPI);

  return util.workspace.isNewWorkspaceFTETourEnabled() && newSite && !zoomMode;
}

async function showNewWorkspaceLeftBarHighlightTour(editorAPI: EditorAPI) {
  startNewWorkspaceLeftBarHighlightTour(editorAPI);
}

function showNewWorkspaceWelcomeTour(editorAPI: EditorAPI) {
  editorAPI.panelManager.openPanel('panels.focusPanels.welcomeTourPanel', {
    title: 'Existing-Tour-Welcome-Title',
    description: 'Existing-Tour-Welcome-Text',
    primaryButtonLabel: 'First-Time-Tour-Welcome-Start-Button',
    secondaryButtonLabel: 'First-Time-Tour-Welcome-Later-Button',
    tourToStart: 'newWorkspaceTour',
  });
}

function showNewUserWelcomeTour(editorAPI: EditorAPI) {
  editorAPI.panelManager.openPanel('panels.focusPanels.welcomeTourPanel', {
    title: 'First-Time-Tour-Welcome-Title',
    description: 'First-Time-Tour-Welcome-Text',
    primaryButtonLabel: 'First-Time-Tour-Welcome-Start-Button',
    secondaryButtonLabel: 'First-Time-Tour-Welcome-Later-Button',
    tourToStart: 'welcomeTour',
  });
}

function shouldShowExistingUserNewWorkspaceTour(editorAPI: EditorAPI) {
  if (experiment.isOpen('se_NewWorkspaceWelcomeTourForce')) {
    return true;
  }

  if (util.tours.isNewWorkspaceTourEnabled()) {
    const didPassNewWorkspaceTourCheck =
      stateManagement.userPreferences.selectors.getGlobalUserPreferences(
        'didPassNewWorkspaceTourCheck',
      )(editorAPI.store.getState());
    const didPassWelcomeTourCheck =
      stateManagement.userPreferences.selectors.getGlobalUserPreferences(
        'didPassWelcomeTourCheck',
      )(editorAPI.store.getState());

    if (didPassNewWorkspaceTourCheck || didPassWelcomeTourCheck) {
      return false;
    }

    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        'didPassNewWorkspaceTourCheck',
        true,
      ),
    );

    return editorAPI.dsRead.account.hasSites();
  }

  return false;
}

function shouldShowNewUserWelcomeTour(editorAPI: EditorAPI) {
  if (experiment.isOpen('se_editorWelcomeTourForce')) {
    return true;
  }

  if (util.tours.isWelcomeTourEnabled()) {
    const didPassWelcomeTourCheck =
      stateManagement.userPreferences.selectors.getGlobalUserPreferences(
        'didPassWelcomeTourCheck',
      )(editorAPI.store.getState());

    if (didPassWelcomeTourCheck) {
      return false;
    }

    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        'didPassWelcomeTourCheck',
        true,
      ),
    );

    return !editorAPI.dsRead.account.hasSites();
  }

  return false;
}

function userAlreadySavedAnySiteInNewEditor(editorAPI: EditorAPI) {
  const editorVersionsInfo = editorAPI.account.getEditorVersionsInfo();
  const isUserWithNoSites = !editorAPI.dsRead.account.hasSites();
  const userUsingNewEditor =
    editorVersionsInfo && editorVersionsInfo.userOverride !== 'old';

  return userUsingNewEditor && !isUserWithNoSites;
}

function shouldShowNewFeaturesVideo(editorAPI: EditorAPI) {
  const didPassNewFeaturesVideoCheck =
    stateManagement.userPreferences.selectors.getGlobalUserPreferences(
      'didPassNewFeaturesVideoCheck',
    )(editorAPI.store.getState());

  if (
    !experiment.isOpen('newFeaturesIntroVideo') ||
    didPassNewFeaturesVideoCheck
  ) {
    return false;
  }

  return (
    !currentSiteNeverSavedInNewEditor(editorAPI) &&
    userAlreadySavedAnySiteInNewEditor(editorAPI)
  );
}

const didPassADIKey = 'didPassCameFromADIVideo';
let cameFromAdiVideoLink: AnyFixMe;

function shouldShowCameFromADIVideo(editorAPI: EditorAPI) {
  cameFromAdiVideoLink = translate('Intro_video_new_user_new_video_link');
  return (
    experiment.isOpen('se_enableADIWelcomeVideo') && // close for all because video with old workspace
    editorAPI.dsRead.generalInfo.isSiteFromOnBoarding() &&
    !stateManagement.userPreferences.selectors.getGlobalUserPreferences(
      didPassADIKey,
    )(editorAPI.store.getState()) &&
    !translatedVideoIsMissing(cameFromAdiVideoLink)
  );
}

function showNewFeaturesVideoPanel(editorAPI: EditorAPI) {
  editorAPI.store
    .dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        'didPassNewFeaturesVideoCheck',
        true,
      ),
    )
    .catch(_.noop);

  editorAPI.panelManager.openPanel('panels.focusPanels.newFeaturesVideoPanel', {
    linkedVideoData: translate('Notification_Box__video_url_EN'),
    title: translate('Notification_Box_New_Editor_Features_Title'),
    subtitle: translate('Notification_Box_New_Editor_Features_Subtitle'),
    buttonLabel: 'Intro_video_new_user_button',
  });
}

function showCameFromAdiVideoPanel(editorAPI: EditorAPI) {
  editorAPI.store
    .dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        didPassADIKey,
        true,
      ),
    )
    .catch(_.noop);

  editorAPI.panelManager.openPanel('panels.focusPanels.cameFromADIVideoPanel', {
    linkedVideoData: cameFromAdiVideoLink,
    title: translate('Notification_Box_ADI_Migration_Title'),
    subtitle: translate('Notification_Box_ADI_Migration_Subtitle'),
    buttonLabel: 'Notification_Box_ADI_Migration_Button',
  });
}

function shouldDisableWelcomeScreen(editorAPI: EditorAPI): boolean {
  const disableForAutomation =
    util.url.getParameterByName('disableWelcomeScreen') === 'true';
  const hasIntroFunnelAppIntent =
    stateManagement.introFunnel.selectors.hasAppIntent(
      editorAPI.store.getState(),
    );
  return disableForAutomation || hasIntroFunnelAppIntent;
}

const shouldShowBlogDeprecationPanel = (editorAPI: EditorAPI) => {
  return (
    experiment.isOpen('se_oldBlogDeprecation') &&
    !shouldShowComponentDeprecationPanel(editorAPI) &&
    siteHasOldBlogDeprecationComponents(editorAPI)
  );
};

const shouldShowComponentDeprecationPanel = (editorAPI: EditorAPI) => {
  return (
    isAnyDeprecationSiteExperimentOpen() &&
    siteHasDeprecatedComponents(editorAPI)
  );
};

const shouldShowBlogMigrationPanel = (editorAPI: EditorAPI) => {
  return (
    experiment.isOpen('newBlogMagicMigration') && siteHasOldBlog(editorAPI)
  );
};

function initWelcomeScreen(editorAPI: AnyFixMe) {
  const overrideWelcomeScreenPanels =
    stateManagement.welcomeScreen.selectors.getWelcomeScreenOverride(
      editorAPI.store.getState(),
    ).panels;

  const defaultWelcomeScreenPanels = [
    { shouldShow: shouldShowCameFromADIVideo, show: showCameFromAdiVideoPanel },
    {
      shouldShow: shouldShowNewWorkspaceLeftBarHighlightTour,
      show: showNewWorkspaceLeftBarHighlightTour,
    },
    { shouldShow: shouldShowNewUserWelcomeTour, show: showNewUserWelcomeTour },
    {
      shouldShow: shouldShowExistingUserNewWorkspaceTour,
      show: showNewWorkspaceWelcomeTour,
    },
    { shouldShow: shouldShowNewFeaturesVideo, show: showNewFeaturesVideoPanel },
    {
      shouldShow: shouldShowBlogDeprecationPanel,
      show: () => {
        showDeprecationPopup(editorAPI, { origin: 'welcomeScreen' });
      },
    },
    {
      shouldShow: shouldShowComponentDeprecationPanel,
      show: () => {
        showComponentDeprecationPopup(editorAPI, { origin: 'welcomeScreen' });
      },
    },
    {
      shouldShow: shouldShowBlogMigrationPanel,
      show: () => {
        showMigrationPopup(editorAPI, { origin: 'welcomeScreen' });
      },
    },
  ];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/concat
  const panelsOrder = _.concat(
    _.isEmpty(overrideWelcomeScreenPanels)
      ? defaultWelcomeScreenPanels
      : overrideWelcomeScreenPanels,
    {
      shouldShow() {
        return true;
      },
      show: _.noop,
    },
  );

  if (shouldDisableWelcomeScreen(editorAPI)) {
    return;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(panelsOrder, function (o) {
    return o.shouldShow(editorAPI);
  }).show(editorAPI);
}

export { initWelcomeScreen };

import * as stateManagement from '#packages/stateManagement';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { SelectComponentsByRefInterceptor } from '../types';

export function getSelectComponentByRefCoreInterceptors({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const isGroup = (compRef: CompRef) => editorAPI.components.is.group(compRef);
  const isAppWidget = (compRef: CompRef) =>
    stateManagement.components.selectors.isAppWidget(compRef, editorAPI.dsRead);

  const removeAncestorsDescendants: SelectComponentsByRefInterceptor = (
    { compsToBeSelected },
    { update },
  ) => {
    const ancestorsToBeSelected = compsToBeSelected.filter(
      (compRef) => isGroup(compRef) || isAppWidget(compRef),
    );

    const descendantsToBeSelected = ancestorsToBeSelected.flatMap(
      (ancestorRef) =>
        compsToBeSelected.filter((compRef) =>
          editorAPI.components.isAncestorOfCompOrCompScope(
            ancestorRef,
            compRef,
          ),
        ),
    );

    if (descendantsToBeSelected.length > 0) {
      update({
        compsToBeSelected: compsToBeSelected.filter(
          (compRef) => !descendantsToBeSelected.includes(compRef),
        ),
      });
    }
  };

  return [removeAncestorsDescendants];
}

// @ts-nocheck
import * as core from '#packages/core';
import constants from '#packages/constants';
import * as baseUI from '#packages/baseUI';
import * as stateManagement from '#packages/stateManagement';

const TOOLTIP_DELAY = 600;

export default {
  mixins: [core.mixins.editorAPIMixin],
  componentWillUnmount() {
    this.hideBubble();
  },
  showBubbleOnMouseMove(e) {
    if (this.props.isDisabled || this.props.shouldDisableDragTooltip === true) {
      return;
    }

    this.setBubbleTimeout({ left: e.clientX, top: e.clientY });
  },
  hideBubbleOnMouseLeave() {
    this.hideBubble();
  },
  showBubble(position) {
    const showedAlready =
      stateManagement.userPreferences.selectors.getSessionUserPreferences(
        'addPanel.dragToStage',
      )(this.getEditorAPI().store.getState());
    if (showedAlready || baseUI.tooltipManager.isDisplayingAnything()) {
      return;
    }
    this.getEditorAPI().showFloatingBubble(
      'add_section_tooltip_drag',
      position,
      { alignment: constants.UI.TOOLTIP.ALIGNMENT.RIGHT },
    );
  },
  hideBubble() {
    if (this.props.isDisabled) {
      return;
    }

    this.getEditorAPI().hideFloatingBubble();
    this.clearBubbleTimeout();
  },
  setBubbleTimeout(position) {
    this.getEditorAPI().hideFloatingBubble();
    this.clearBubbleTimeout();
    this.bubbleTimeout = window.setTimeout(
      this.showBubble.bind(this, position),
      TOOLTIP_DELAY,
    );
  },
  clearBubbleTimeout() {
    if (this.bubbleTimeout) {
      window.clearTimeout(this.bubbleTimeout);
      delete this.bubbleTimeout;
    }
  },
};

import * as addPanel from '@wix/editor-add-panel';
import * as util from '#packages/util';

export const dataUtil: any = util.addPanel.deprecateOldAddPanel(
  addPanel.dataUtil,
  'dataUtil',
);
export const sections: any = util.addPanel.deprecateOldAddPanel(
  addPanel.sections,
  'sections',
);
export default { dataUtil, sections };

import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import { BI_ORIGIN } from '../../constants';
import { FORM_APP } from '@wix/app-definition-ids';
import * as tpa from '#packages/tpa';

import type { MapDispatchToPropsFunction } from 'types/redux';
import type {
  CompRef,
  Point,
  SerializedCompStructure,
} from '@wix/document-services-types';

export interface FormsMapperProps {
  createNewForm: () => void;
  addExistingForm: () => void;
}

const FORMS_WIDGET_ID = '371ee199-389c-4a93-849e-e35b8a15b7ca';

const platformOrigin = {
  type: EditorType.Classic,
  initiator: InstallInitiator.Editor,
  info: {
    type: InstallationOriginType.AddPanel,
  },
};

export const addFormsWidgetActionCreatorOnDrop =
  (presetId: string, compStructure: SerializedCompStructure) =>
  (coords: Point, compDef: AnyFixMe, dropContainer: CompRef) =>
    addFormsWidgetActionCreator(presetId, compStructure, coords, dropContainer);

export const addFormsWidgetActionCreator = (
  presetId: string,
  compStructure?: SerializedCompStructure,
  dropPosition?: Point,
  dropContainer?: CompRef,
) => {
  const styleIdOrRef = compStructure?.style;
  tpa.superApps.addWidget(
    FORM_APP,
    FORMS_WIDGET_ID,
    {
      width: compStructure?.layout?.width,
      x: compStructure?.layout?.x || 300,
      y: compStructure?.layout?.y || 100,
      ...(dropPosition ? dropPosition : {}),
    },
    {
      containerRef: dropContainer,
      presetId,
      dontStretch: true,
      platformOrigin,
      biOrigin: BI_ORIGIN,
      styleId: styleIdOrRef,
    },
  );
};

export const formsMapper: MapDispatchToPropsFunction<
  FormsMapperProps,
  {}
> = () => {
  return {
    createNewForm: () => {
      addFormsWidgetActionCreator('blankFormApp');
    },
    addExistingForm: () => {
      addFormsWidgetActionCreator('existingFormApp');
    },
  };
};

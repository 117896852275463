// @ts-nocheck
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_skypecall',
      isApplied: gfppDataUtils.getDataDefinedFn('skypeName'),
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: 'ed24200d-2956-4fe8-9c54-4eec989f6c53',
    },
  },
};

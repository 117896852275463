import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';
const { isDesktopEditor } = stateManagement.mobile.selectors;
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import constants from '#packages/constants';

const getSiteUserPreferences = (key: AnyFixMe, state: AnyFixMe) =>
  stateManagement.userPreferences.selectors.getSiteUserPreferences(key)(state);

const USER_WAS_IN_MOBILE_EDITOR_KEY =
  constants.USER_PREFS.MOBILE_EDITOR.USER_WAS_IN_MOBILE_EDITOR;

export interface QrCodePublishedSitePanelStateProps {
  qrCodeUrl?: string;
  hasUserSeenMobileEditor: boolean;
  isDesktopEditor?: boolean;
}
export interface QrCodePublishedSitePanelDispatchProps {
  goToMobileEditor: () => void;
  closePanelByName: (panelName: string) => void;
  openHelpCenter: (helpId: string) => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

export const mapStateToProps: MapStateToProps<
  QrCodePublishedSitePanelStateProps,
  {}
> = ({ state }) => {
  return {
    qrCodeUrl: stateManagement.savePublish.selectors.getQrCodeUrl(state),
    isDesktopEditor: isDesktopEditor(state),
    hasUserSeenMobileEditor: Boolean(
      getSiteUserPreferences(USER_WAS_IN_MOBILE_EDITOR_KEY, state),
    ),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  QrCodePublishedSitePanelDispatchProps,
  {}
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    goToMobileEditor: editorAPI.setMobileEditor,
    closePanelByName: editorAPI.panelManager.closePanelByName,
    openHelpCenter: editorAPI.help.openHelpCenter,
    sendBi: editorAPI.bi.event,
  };
};

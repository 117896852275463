import React, { type FC } from 'react';
import {
  Composites,
  ControlHeader,
  DropDown,
  DropDownOption,
  SectionDivider,
} from '@wix/wix-base-ui';
import * as stateManagement from '#packages/stateManagement';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import { QuickEditScope } from '../quickEditEntryPoint';

import type { QuickEditControlOwnProps } from '@wix/editor-elements-types/quickEditControls';
import { isComponentInInteractionMode } from '#packages/quickEditEngine';
import { HOVER_BOX_DATA_HOOKS } from '../consts';
import type { InteractionType } from 'types/documentServices';

const enum InteractionModeState {
  Hover = 'Hover',
  Regular = 'Regular',
}

type HoverBoxItemProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  QuickEditControlOwnProps
>;

const HoverBoxItem: FC<HoverBoxItemProps> = ({
  children,
  changeHoverState,
  interactionModeState,
  translate,
}: HoverBoxItemProps) => {
  return (
    <>
      <SectionDivider>
        {translate('edit_section_hoverbox_title')}
      </SectionDivider>
      <Composites.DropDownLabeled>
        <ControlHeader
          labelProps={{
            text: translate('edit_section_hoverbox_mode_dropdown_label'),
          }}
          infoIconProps={{
            text: 'edit_section_hoverbox_mode_dropdown_tooltip',
          }}
        />
        <DropDown
          value={interactionModeState}
          onChange={changeHoverState}
          hasArrowIcon
          dataHook={HOVER_BOX_DATA_HOOKS.TOGGLE_DROPDOWN}
        >
          <DropDownOption
            value={InteractionModeState.Regular}
            label={'edit_section_hoverbox_mode_dropdown_regular_label'}
            dataHook={HOVER_BOX_DATA_HOOKS.REGULAR_MODE}
          />
          <DropDownOption
            value={InteractionModeState.Hover}
            label={'edit_section_hoverbox_mode_dropdown_hover_label'}
            dataHook={HOVER_BOX_DATA_HOOKS.HOVER_MODE}
          />
        </DropDown>
      </Composites.DropDownLabeled>
      <div key={interactionModeState}>{children}</div>
    </>
  );
};

const mapStateToProps = (
  { editorAPI }: QuickEditScope,
  { compRef }: QuickEditControlOwnProps,
) => {
  return {
    interactionModeState: isComponentInInteractionMode(editorAPI, compRef)
      ? InteractionModeState.Hover
      : InteractionModeState.Regular,
  };
};

const mapDispatchToProps = (
  { editorAPI }: QuickEditScope,
  { compRef }: QuickEditControlOwnProps,
) => {
  return {
    changeHoverState: async (newState: InteractionModeState) => {
      stateManagement.interactions.actions.exitInteractionModeIfNeeded(
        editorAPI,
      );
      if (newState === InteractionModeState.Hover) {
        stateManagement.interactions.actions.exitInteractionModeIfNeeded(
          editorAPI,
        );
        await editorAPI.waitForChangesAppliedAsync();
        editorAPI.store.dispatch(
          stateManagement.interactions.actions.enterInteraction(
            editorAPI.variants.getHoverType() as InteractionType,
            compRef,
            { leftBar: { shouldHighlight: false } },
            false,
          ),
        );
      }
    },
  };
};

export default connectWithScope(
  () => QuickEditScope,
  HoverBoxItem,
  mapStateToProps,
  mapDispatchToProps,
);

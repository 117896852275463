import React from 'react';
import * as panels from '#packages/panels';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Composites,
  PanelHeader,
  RichText,
  Button,
  TextLabel,
  Illustration,
} from '@wix/wix-base-ui';

interface PageAlreadyAddedModal {
  panelName: string;
  openExistingPage: () => void;
  closeModal: () => void;
  openHelpCenter: (helpId: string) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PageAlreadyAddedModal = ({
  panelName,
  openHelpCenter,
  openExistingPage,
  closeModal,
}: PageAlreadyAddedModal) => {
  return (
    <panels.frames.FocusPanelFrame
      panelName={panelName}
      shouldHideHeader
      className="panel-page-already-added"
    >
      <Composites.PopupSmallSize className="popup-modal">
        <PanelHeader
          onClose={closeModal}
          onHelp={() => openHelpCenter('d041f0d3-dffc-4675-b12d-e50527124d69')}
        >
          {translate('add_page_preset_404_replace_popup_title')}
        </PanelHeader>

        <Composites.PanelContent>
          <Composites.RichTextWithIllustration>
            <Illustration className="modal-illustration">
              <symbols.symbol name="plaster" />
            </Illustration>

            <Composites.RichText>
              <RichText type="T06">
                {translate('add_page_preset_404_replace_popup_text_intro')}
              </RichText>
              <RichText type="T01">
                {translate('add_page_preset_404_replace_popup_bullet1')}
              </RichText>
              <RichText type="T01">
                {translate('add_page_preset_404_replace_popup_bullet2')}
              </RichText>
              <RichText type="T01">
                {translate('add_page_preset_404_replace_popup_bullet3')}
              </RichText>
              <RichText type="T01">
                {translate('add_page_preset_404_replace_popup_bullet4')}
              </RichText>
            </Composites.RichText>
          </Composites.RichTextWithIllustration>
        </Composites.PanelContent>

        <Composites.ActionSetHorizontal>
          <Button onClick={closeModal} className="secondary">
            <TextLabel value="add_page_preset_404_replace_popup_cancel_button" />
          </Button>
          <Button onClick={openExistingPage} className="primary">
            <TextLabel value="add_page_preset_404_replace_popup_go_to_pages_button" />
          </Button>
        </Composites.ActionSetHorizontal>
      </Composites.PopupSmallSize>
    </panels.frames.FocusPanelFrame>
  );
};

import { sections } from '#packages/util';
import type { ComponentEditorMetaDataDefinition } from '../types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const metaData: ComponentEditorMetaDataDefinition = {
  pinnable: false,
  skipInQuickEditPanel: true,
  containable(editorAPI: EditorAPI, compRef: CompRef, containerRef?: CompRef) {
    if (!containerRef) {
      return;
    }

    const isPageContainer =
      editorAPI.pages.isPageExist(containerRef.id) &&
      !editorAPI.pages.popupPages.isPopup(containerRef.id);

    if (sections.isSectionsEnabled()) {
      return editorAPI.sections.isSection(containerRef) || isPageContainer;
    }

    return isPageContainer;
  },
};

export default metaData;

import React from 'react';
import { translate } from '#packages/i18n';
import { NotificationBanner } from '@wix/wix-base-ui';

export const WorkspaceIndicator: React.FC<{
  accountName: string;
  onClick: () => void | undefined;
}> = ({ accountName, onClick }) => {
  return (
    <NotificationBanner
      dataHook="workspace-indicator"
      text={translate('AppStudio_OpenApp_Workspace_Toast', {
        Workspace_Name: accountName,
      })}
      link={{
        onClick,
        label: translate('AppStudio_OpenApp_Workspace_Toast_CTA'),
        href: 'https://users.wix.com/login/select-workspace?redirect_to=https://www.wix.com/blocks/new',
        target: '_self',
      }}
      shouldTranslate={false}
    />
  );
};

import { createReduxStore } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';
import type {
  AddPanelOverriding,
  EnterPayload,
  FloatBarOptions,
  MenuType,
} from './types';

interface State {
  compRef: CompRef | null;
  compRefToShowOnTop: CompRef | null;
  compRefToAttachChildren: CompRef | null;
  floatBarOptions: FloatBarOptions | null;
  addPanelOverriding: AddPanelOverriding | null;
  allowedChildrenTypes: Array<string> | null;
  menuType: MenuType;
}

const initialState: State = {
  compRef: null,
  compRefToShowOnTop: null,
  compRefToAttachChildren: null,
  floatBarOptions: null,
  addPanelOverriding: null,
  allowedChildrenTypes: null,
  menuType: null,
};

export const ComponentFocusModeStore = createReduxStore({
  getInitialState: (): State => initialState,
  selectors: {
    getIsEnabled: ({ compRef }: State): boolean => !!compRef,
    getCompRef: ({ compRef }: State): CompRef | null => compRef,
    getCompRefToShowOnTop: ({ compRefToShowOnTop }: State): CompRef | null =>
      compRefToShowOnTop,
    getCompRefToAttachChildren: ({
      compRefToAttachChildren,
    }: State): CompRef | null => compRefToAttachChildren,
    getFloatBarOptions: ({ floatBarOptions }): FloatBarOptions | null =>
      floatBarOptions,
    getAddPanelOverriding: ({ addPanelOverriding }): AddPanelOverriding =>
      addPanelOverriding,
    getAllowedChildrenTypes: ({ allowedChildrenTypes }) => allowedChildrenTypes,
    getMenuType: ({ menuType }) => menuType,
  },
  actions: {
    enter: (
      state: State,
      {
        compRef,
        compRefToShowOnTop = compRef,
        compRefToAttachChildren = compRef,
        floatBarOptions,
        menuType,
      }: EnterPayload,
      {
        allowedChildrenTypes,
        addPanelOverriding,
      }: {
        allowedChildrenTypes: string[];
        addPanelOverriding: AddPanelOverriding;
      },
    ): State => ({
      ...state,
      compRef,
      compRefToShowOnTop,
      compRefToAttachChildren,
      floatBarOptions,
      addPanelOverriding,
      allowedChildrenTypes,
      menuType,
    }),
    exit: (): State => initialState,
  },
});

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Text, TextButton } from '@wix/wix-base-ui';
import { AUTOMATION_IDS } from './constants';
import { cx, hoc } from '#packages/util';
import styles from './SiteSegmentBox.scss';
import type {
  SiteSegmentEmptyStateLiteModeStateProps,
  SiteSegmentEmptyStateLiteModeDispatchProps,
} from './SiteSegmentEmptyStateLiteModeMapper';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './SiteSegmentEmptyStateLiteModeMapper';

export interface SiteSegmentEmptyStateLiteModeOwnProps {
  type: 'header' | 'footer';
  onAddElementsClick: (event: React.MouseEvent) => void;
}

interface SiteSegmentEmptyStateLiteModeProps
  extends SiteSegmentEmptyStateLiteModeOwnProps,
    SiteSegmentEmptyStateLiteModeStateProps,
    SiteSegmentEmptyStateLiteModeDispatchProps {}

const SiteSegmentEmptyStateLiteModeComponent: React.FC<
  SiteSegmentEmptyStateLiteModeProps
> = ({ onAddElementsClick, switchToFullEditor, isLightTextColor, type }) => {
  const { t } = useTranslation();

  const textKey =
    type === 'header'
      ? 'lite_editor_blank_header_empty_state_text'
      : 'lite_editor_blank_footer_empty_state_text';

  return (
    <div
      data-hook={AUTOMATION_IDS.EMPTY_STATE.CONTAINER}
      className={cx(styles.emptyStateContainer, styles.liteMode, {
        [styles.light]: isLightTextColor,
      })}
    >
      <span className={styles.textContainer}>
        <Text
          size="small"
          skin="secondary"
          weight="normal"
          shouldTranslate={false}
        >
          {t(textKey)}
        </Text>
      </span>

      <span
        className={styles.linksContainer}
        onMouseDown={(event) => event.stopPropagation()}
      >
        <TextButton
          size="small"
          skin="standard"
          weight="normal"
          shouldTranslate={false}
          dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_ELEMENT}
          onClick={(e: React.MouseEvent) => {
            switchToFullEditor();
            onAddElementsClick(e);
          }}
        >
          {t('lite_editor_blank_switch_full_empty_state_cta')}
        </TextButton>
      </span>
    </div>
  );
};

export const SiteSegmentEmptyStateLiteMode = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SiteSegmentEmptyStateLiteModeComponent);

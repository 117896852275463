import React, { type FC, useEffect, useCallback, useRef } from 'react';
import { ChatWidget, SidebarEnvironment } from '@wix/chat-widget-ui-lib';

import * as chatServiceSDK from '@wix/chat-service-sdk';
import { HttpClient } from '@wix/http-client';

import type { ChatMessageData } from '@wix/chat-widget-ui-lib';
import type { AiAssistantProps } from './aiAssistantTypes';

const httpClient = new HttpClient();

const AiAssistantSidebar: FC<AiAssistantProps> = ({
  config,
  closeAiAssistant,
}) => {
  const { createConversation, sendUserMessage, messages, statusMessage } =
    chatServiceSDK.useChatServiceProvider({
      httpClient,
      domainIds: config.domainIds,
      onFunctionCall: (name) => {
        return { success: true, response: { name } };
      },
    });

  const widgets = config.getWidgets(
    sendUserMessage,
    config.onWidgetAction,
    config.theme,
  );

  const startConversation = useCallback(async () => {
    const createdConversationId = await createConversation({
      origin: config.origin,
      firstAssistantMessageContent: config.firstUserMessage,

      tools: config.tools,
    });

    return createdConversationId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createConversation]);

  const onClearChat = () => {
    startConversation();
  };

  const onSendMessage = (message: ChatMessageData) => {
    sendUserMessage(message.content);
    config.onSendMessage();
  };

  const isFirstMessageSent = useRef(false);

  useEffect(() => {
    if (!isFirstMessageSent.current) {
      isFirstMessageSent.current = true;
      startConversation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startConversation]);

  const onClose = () => {
    config.onCloseChat?.();
    closeAiAssistant();
  };

  return (
    <SidebarEnvironment
      title={config.assistantName}
      onClean={onClearChat}
      onClose={onClose}
      isFooterVisible={false}
    >
      <ChatWidget
        chatMessages={messages}
        widgets={widgets}
        onSendMessage={onSendMessage}
        statusMessage={statusMessage}
        assistantName={config.assistantName}
        onUserStartTyping={config.onUserStartTyping}
        onWidgetAction={config.onWidgetAction}
      />
    </SidebarEnvironment>
  );
};

export default AiAssistantSidebar;

// @ts-nocheck
import * as util from '#packages/util';
import * as tpa from '#packages/tpa';
import { tpaUtils } from '#packages/util';

function handleDeepLink(state) {
  if (isAddCollectionType(state)) {
    openAddCollectionModal(state);
  }
}

function isAddCollectionType(reason) {
  return !!reason && reason.type === 'wixstores.addCollection';
}

function openAddCollectionModal(etpaState) {
  const url = util.url.buildFullUrl({
    protocol: 'https:',
    hostname: 'ecom.wix.com',
    path: '/storefront/collectionModal/',
    query: {
      categoryId: etpaState.categoryId,
      categoryName: etpaState.categoryName,
    },
    hash: '',
  });

  tpa.superApps.openSuperAppsModal({
    autoProvision: true,
    url,
    width: 498,
    height: 522,
    isBareMode: true,
    appDefinitionId: tpaUtils.getEcomAppDefId(),
  });
}

export { handleDeepLink };

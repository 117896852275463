import {
  createCategory,
  createServices,
  getDefaultStaff,
  insist,
} from '../api/bookings-api';
import {
  getDynamicContentForServices,
  getSection,
  getHomeSectionByCeType,
  getServiceContent,
} from './bookings-content-manager';
import dataProvider from '../dataProvider';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { translate } from '#packages/i18n';
import type {
  ContentManager,
  ListContentItem,
  OfferingTypeCMS,
} from '#packages/presetApi';
import { CeType, adapter } from '#packages/presetApi';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  BookingsResource,
  BookingsServiceCategory,
  BookingsServiceSkeleton,
  BookingsServiceType,
} from '../api/bookings-types';

async function getServiceSkeletons(
  editorAPI: EditorAPI,
  servicesContent: ListContentItem,
  servicesType: BookingsServiceType,
  category: BookingsServiceCategory,
  staffMember: BookingsResource,
): Promise<BookingsServiceSkeleton[]> {
  return servicesContent.items.map((item) => {
    const images = item.media.filter(
      (mediaItem) => mediaItem.mediaType === 'image',
    );
    const itemImage = images[0] as any;
    return {
      type: servicesType,
      categoryId: category.id,
      info: {
        name: item.title,
        description: item.longText,
        images: [
          {
            height: itemImage?.height,
            url: itemImage?.uri,
            width: itemImage?.width,
          },
        ],
      },
      staffMember,
    };
  });
}
function getCategoryTitle(servicesContent: ListContentItem): string {
  const categoryTitleFallback = translate('tpa.bookings.category-title');
  return servicesContent.header?.title || categoryTitleFallback;
}

export async function generateDynamicContentServices(
  editorAPI: EditorAPI,
  contentManager: ContentManager = null,
) {
  if (dataProvider.isWixBookingsInstalled(editorAPI)) {
    try {
      const { servicesContent, servicesType } =
        await getDynamicContentForServices(editorAPI, contentManager);
      const categoryTitle = getCategoryTitle(servicesContent);
      const [category, staffMember] = await Promise.all([
        createCategory(editorAPI, categoryTitle),
        insist(() => getDefaultStaff(editorAPI)),
      ]);
      const serviceSkeletons = await getServiceSkeletons(
        editorAPI,
        servicesContent,
        servicesType,
        category,
        staffMember,
      );
      await createServices(editorAPI, serviceSkeletons);
    } catch (e) {
      ErrorReporter.captureException(e, {
        tags: { bookingsDynamicServicesGenerationFailed: true },
      });
    }
  }
}

const generateServicesData = (servicesContent: ListContentItem) =>
  servicesContent.items.map((item) => {
    const images = item.media.filter(
      (mediaItem) => mediaItem.mediaType === 'image',
    );
    return {
      name: item.title,
      description: item.longText,
      images: [images?.[0]],
    };
  });

export async function updateContentServices(
  editorAPI: EditorAPI,
  contentManager: ContentManager,
) {
  if (dataProvider.isWixBookingsInstalled(editorAPI)) {
    try {
      const section =
        getSection(contentManager) ??
        getHomeSectionByCeType(contentManager, CeType.Service);
      const servicesContent = getServiceContent(contentManager, section);
      const servicesData = generateServicesData(servicesContent);
      const categoryTitle = getCategoryTitle(servicesContent);
      const category = await createCategory(editorAPI, categoryTitle);
      const { tpaService } = await adapter();
      await tpaService.addBookingServicesNew(
        category.id,
        servicesData,
        section.syncWithBooking as OfferingTypeCMS,
      );
    } catch (e) {
      ErrorReporter.captureException(e, {
        tags: { bookingsUpdateContentServicesFailed: true },
      });
    }
  }
}

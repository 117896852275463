import React from 'react';
import { Text, TextButton } from '@wix/wix-base-ui';

import constants from '#packages/constants';
import { frames } from '#packages/panels';
import { translate } from '#packages/i18n';

import PopupTemplate from '../popupTemplate';
import * as symbols from '@wix/santa-editor-symbols';

const { MessagePanelFrame } = frames;

interface OutOfGridMobilePanelFrameProps {
  panelName: string;
  onLearnMoreClick: () => void;
}

export const OutOfGridMobilePanelFrame: React.FC<
  OutOfGridMobilePanelFrameProps
> = (props) => {
  return (
    <PopupTemplate
      panelName={props.panelName}
      h1text="Notification_Box_Out_Grid_Mobile_Title"
      h2text="Notification_Box_Out_Grid_Mobile_Subtitle"
      symbolName="outOfGridMobile"
      bodyText="Notification_Box_Out_Grid_Mobile_Text"
      helpId="769b8807-30b7-4ee4-8a7b-93674099732f"
      dontShowAgainKey={
        constants.USER_PREFS.OUT_OF_GRID.MOBILE.OUT_OF_GRID_DONT_SHOW_AGAIN
      }
      userPrefType="site"
    />
  );
};

export const NewWorkspaceOutOfGridMobilePanelFrame: React.FC<
  OutOfGridMobilePanelFrameProps
> = (props) => {
  return (
    <MessagePanelFrame
      panelName={props.panelName}
      title={translate('Notification_Box_Out_Grid_Mobile_Title')}
      illustration={<symbols.symbol name="outOfGridMobile_NewWorkspace" />}
      confirmLabel={translate('Notification_Box_Got_It')}
      userPrefType="site"
      dontShowAgainKey={
        constants.USER_PREFS.OUT_OF_GRID.MOBILE.OUT_OF_GRID_DONT_SHOW_AGAIN
      }
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {translate('Notification_Box_Out_Grid_Mobile_Subtitle')}
      </Text>{' '}
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {translate('Notification_Box_Out_Grid_Mobile_Text')}
      </Text>{' '}
      <TextButton onClick={props.onLearnMoreClick} shouldTranslate={false}>
        {translate('Notification_Box_Learn_More')}
      </TextButton>
    </MessagePanelFrame>
  );
};

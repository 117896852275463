import { EditorAPIKey } from '#packages/apis';

import { createSelectionHooks } from './createSelectionHooks';
import { createSelectionBI } from './createSelectionBI';
import { createSelectionPopupApi } from './createSelectionPopupApi';
import { createSelectionKeyboardAndMouseApi } from './createSelectionKeyboardAndMouseApi';
import { createSelectionFocusApi } from './selectionFocus/createSelectionFocusApi';
import { createHighlightsApi } from './createHighlightsApi';
import { createInteractionsApi } from './interactions/createInteractionsApi';
import { createSelectionBaseApi } from './selectionBaseApi/createSelectionBaseApi';
import { createSelectionByClickApi } from './selectionByClickApi/createSelectionByClickApi';

import type { Shell } from '#packages/apilib';

export function createSelectionApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const selectionHooks = createSelectionHooks();
  const selectionBI = createSelectionBI({ editorAPI });
  const selectionPopupApi = createSelectionPopupApi({ editorAPI });
  const selectionKeyboardAndMouseApi = createSelectionKeyboardAndMouseApi({
    editorAPI,
  });

  const selectionFocusApi = createSelectionFocusApi({
    editorAPI,
    selectionHooks,
    selectionPopupApi,
  });
  const highlightsApi = createHighlightsApi({
    editorAPI,
  });
  const interactionsApi = createInteractionsApi({
    editorAPI,
    selectionHooks,
  });
  const selectionBaseApi = createSelectionBaseApi({
    editorAPI,
    selectionHooks,
    selectionFocusApi,
    selectionKeyboardAndMouseApi,
    highlightsApi,
    selectionBI,
    interactionsApi,
  });
  const selectionByClickApi = createSelectionByClickApi({
    editorAPI,
    selectionHooks,
    selectionBaseApi,
    selectionFocusApi,
    selectionKeyboardAndMouseApi,
    interactionsApi,
  });

  let isHiddenComponentDraggedNext: boolean = false;
  function setIsHiddenComponentDraggedNext(isHiddenDraggedNext: boolean): void {
    isHiddenComponentDraggedNext = !!isHiddenDraggedNext;
  }

  function isHiddenComponentDraggedNextPublic(): boolean {
    return isHiddenComponentDraggedNext;
  }

  return {
    hooks: selectionHooks,
    isMultiSelectKeyPressed:
      selectionKeyboardAndMouseApi.isMultiSelectKeyPressed,
    isSingleSelectionComponent: selectionBaseApi.isSingleSelectionComponent,
    setIsMouseUpSelectionEnabled:
      selectionKeyboardAndMouseApi.setIsMouseUpSelectionEnabled,
    getIsMouseUpSelectionEnabled:
      selectionKeyboardAndMouseApi.getIsMouseUpSelectionEnabled,
    setIsHiddenComponentDraggedNext,
    isHiddenComponentDraggedNext: isHiddenComponentDraggedNextPublic,
    isComponentExplicitlySelected:
      selectionByClickApi.isComponentExplicitlySelected,
    getComponentsByXY: selectionByClickApi.getComponentsByXY,
    getComponentsByXYConsideringUnselectedScopes:
      selectionByClickApi.getComponentsByXYConsideringUnselectedScopes,
    getComponentsUnderClick: selectionByClickApi.getComponentsUnderClick,
    getNonGroupCompUnderCursor: selectionByClickApi.getNonGroupCompUnderCursor,
    getComponentUnderClickToBeSelected:
      selectionByClickApi.getComponentUnderClickToBeSelected,
    getComponentToBeMarkedByHoverBox:
      selectionByClickApi.getComponentToBeMarkedByHoverBox,
    getSelectedPopup: selectionPopupApi.getSelectedPopup,
    isPopupPageSelected: selectionPopupApi.isPopupPageSelected,
    selectComponentByClick: selectionByClickApi.selectComponentByClick,
    selectNonGroupCompUnderCursor:
      selectionByClickApi.selectNonGroupCompUnderCursor,
    selectComponentByCompRef: selectionBaseApi.selectComponentByCompRef,
    addComponentToSelectionByRef: selectionBaseApi.addComponentToSelectionByRef,
    getSelectedComponentId: selectionBaseApi.getSelectedComponentId,
    getSelectedComponents: selectionBaseApi.getSelectedComponents,
    getParentToFocus: selectionFocusApi.getParentToFocus,
    getSelectedComponentType: selectionBaseApi.getSelectedComponentType,
    selectAll: selectionBaseApi.selectAll,
    getSelectedComponentData: selectionBaseApi.getSelectedComponentData,
    deselectComponents: selectionBaseApi.deselectComponents,
    isComponentSelected: selectionBaseApi.isComponentSelected,
    isSelectedCompUnderCursorAndBeneathSiteSegment:
      selectionByClickApi.isSelectedCompUnderCursorAndBeneathSiteSegment,
    setModalComponent: selectionByClickApi.setModalComponent,
    clearHighlights: highlightsApi.clearHighlights,
    selectionChangePlugin: selectionBaseApi.selectionChangePlugin,
  };
}

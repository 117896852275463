import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as helpIds from '#packages/helpIds';
import { link } from '#packages/util';

import { createMenuApi } from '../../../API/menuAPI';
import { TRANSLATIONS_MAP } from '../utils/translations';
import { menuItemTree } from '#packages/baseUI';

import type { MenuItem } from 'types/documentServices';
import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import { menuBiLogger } from '../../../bi/menuBiLogger';

const {
  linkTypeValidators: { isPageLink },
} = link;
const { findItem } = menuItemTree;

type MenuId = string;

const EDIT_MENU_SET_NOTIFICATION_SHOWN = 'menu.editMenuSetNotificationShown';
const RENAME_PAGE_FROM_MENU_NOTIFICATION_SHOWN =
  'menu.renamePageFromMenuNotificationShown';

export const showEditMenuNotification =
  (menuId: MenuId): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const hasNotificationBeenShown =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        EDIT_MENU_SET_NOTIFICATION_SHOWN,
      )(getState());

    if (!hasNotificationBeenShown) {
      const menuAPI = createMenuApi(editorAPI);

      const connectedMenuComponents =
        menuAPI.getDesktopMenuComponentsByMenuId(menuId);
      const hasMoreThanOneConnectedComponent =
        connectedMenuComponents.length > 1;

      if (hasMoreThanOneConnectedComponent) {
        dispatch(
          stateManagement.userPreferences.actions.setSiteUserPreferences(
            EDIT_MENU_SET_NOTIFICATION_SHOWN,
            true,
          ),
        );

        const onLearnMoreClick = () =>
          dispatch(
            stateManagement.panels.actions.openHelpCenter(
              helpIds.MENU_PANEL.EDIT_MENU_SET_NOTIFICATION,
              undefined,
              {
                panel_name: 'edit-menu-set-warning-panel',
              },
            ),
          );

        const message = TRANSLATIONS_MAP.NOTIFICATIONS.EDIT_MENU.description;

        menuBiLogger.logInfoPopupShown(message);

        dispatch(
          stateManagement.notifications.actions.showUserActionNotification({
            message,
            title: TRANSLATIONS_MAP.NOTIFICATIONS.EDIT_MENU.description,
            type: constants.NOTIFICATIONS.TYPES.INFO,
            link: {
              caption: TRANSLATIONS_MAP.NOTIFICATIONS.EDIT_MENU.linkTitle,
              onNotificationLinkClick: onLearnMoreClick,
            },
          }),
        );
      }
    }
  };

const shouldShowRenamePageNotification = (
  editorAPI: EditorAPI,
  menuItem: MenuItem,
) => {
  const { link } = menuItem;
  const hasRenamePageNotificationBeenShown =
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      RENAME_PAGE_FROM_MENU_NOTIFICATION_SHOWN,
    )(editorAPI.store.getState());

  if (hasRenamePageNotificationBeenShown || !isPageLink(link)) {
    return false;
  }

  const menuAPI = createMenuApi(editorAPI);

  const menusSetsWithConnectedComponents = menuAPI
    .getAll()
    .filter((menu) => menuAPI.hasConnectedComponents(menu.id));
  const connectedMenusWithRenamedPage = menusSetsWithConnectedComponents.filter(
    (menu) =>
      // @ts-expect-error
      findItem(menu.items, (treeItem) => {
        const { item } = treeItem;
        // @ts-expect-error
        return isPageLink(item.link) && item.link.pageId === link.pageId;
      }),
  );

  // renamed page exists in more than 1 menusets connected to stage components
  return connectedMenusWithRenamedPage.length > 1;
};

export const showRenameMenuItemNotification =
  (menuId: string, menuItem: MenuItem): ThunkAction =>
  (dispatch, _, { editorAPI }) => {
    if (shouldShowRenamePageNotification(editorAPI, menuItem)) {
      dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          RENAME_PAGE_FROM_MENU_NOTIFICATION_SHOWN,
          true,
        ),
      );

      const onLearnMoreClick = () =>
        dispatch(
          stateManagement.panels.actions.openHelpCenter(
            helpIds.MENU_PANEL.RENAME_PAGE_IN_MENU_NOTIFICATION,
            undefined,
            {
              panel_name: 'rename-menu-item-warning-panel',
            },
          ),
        );

      const message = TRANSLATIONS_MAP.NOTIFICATIONS.RENAME_PAGE.description;

      menuBiLogger.logInfoPopupShown(message);

      dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message,
          title: TRANSLATIONS_MAP.NOTIFICATIONS.RENAME_PAGE.description,
          type: constants.NOTIFICATIONS.TYPES.INFO,
          link: {
            caption: TRANSLATIONS_MAP.NOTIFICATIONS.RENAME_PAGE.linkTitle,
            onNotificationLinkClick: onLearnMoreClick,
          },
        }),
      );
    } else {
      dispatch(showEditMenuNotification(menuId));
    }
  };

// @ts-nocheck
import React from 'react';
import * as UI from '#packages/baseUI';

export default class extends React.Component {
  static displayName = 'RadioimagesCustomization';

  getOptions = () => {
    return this.props.options.map(function (option) {
      return {
        value: option.value,
        symbolName: option.icon,
      };
    });
  };

  render() {
    return (
      <UI.thumbnails
        maxThumbsPerRow="5"
        title={this.props.label}
        infoText={this.props.infoText}
        options={this.getOptions()}
        valueLink={this.props.valueLink}
      />
    );
  }
}

// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
  hide: false,
  title: 'add_section_label_my_gallery',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [
      'wysiwyg.viewer.components.MatrixGallery',
      'wysiwyg.viewer.components.SlideShowGallery',
      'wysiwyg.viewer.components.SliderGallery',
    ],
  },
  props: {
    compTypes: [
      'wysiwyg.viewer.components.MatrixGallery',
      'wysiwyg.viewer.components.SlideShowGallery',
      'wysiwyg.viewer.components.SliderGallery',
      'wysiwyg.viewer.components.PaginatedGridGallery',
    ],
    verticalMargin: 30,
    horizontalMargin: 20,
    maxFontSize: 12,
    scaleProportion: {
      x: 0.25,
      y: 0.25,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_theme_general',
  },
};

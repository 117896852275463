// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import * as compPanelInfra from '#packages/compPanelInfra';
import React from 'react';
import CustomizationsContainer from './customizations/customizationsContainer';
import BlogPostFilter from './blogPostFilter/blogPostFilter';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<any>({
  displayName: 'WixappsSettingsPanel',
  mixins: [compPanelInfra.compPanelMixin, compPanelInfra.wixappsPanelMixin],
  getCustomizations() {
    const customizations = this.getAvailableCustomizations('settings');

    // Old social icons and their customizations in mobile single post are in a separate view.
    // Add the view's customizations to mobile single post's customizations.
    if (this.isMobileViewMode() && this.isSinglePost()) {
      const socialCustomizations = this.getAvailableCustomizations('settings', {
        format: '',
        viewName: 'SocialShareSinglePostMobile',
      });
      return customizations.concat(socialCustomizations);
    }

    return customizations;
  },
  hasBlogPostFilter() {
    const role = this.getEditorAPI().dsRead.wixapps.classics.getAppPartRole(
      this.getSelectedComponentPackageName(),
      this.data.appPartName,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(
      ['CUSTOM_FEED', 'FEATURED_POSTS', 'POST_LIST', 'POST_GALLERY', 'TICKER'],
      role,
    );
  },
  render() {
    return (
      <compPanelInfra.compPanelFrame
        title={`${this.getSelectedAppPartDisplayName()}_settings`}
        contentClass="settings-panel"
        helpId={this.getComponentHelpIdByType('settings')}
        {...this.getFrameProps()}
      >
        {this.hasBlogPostFilter() ? (
          <BlogPostFilter
            appLogicParamsLink={this.linkedData('appLogicParams')}
            appPartName={this.data.appPartName}
            packageName={this.getSelectedComponentPackageName()}
            key="postFilter"
          />
        ) : null}

        <CustomizationsContainer
          customizations={this.getCustomizations()}
          compPanel={this}
          isTopOfPage={!this.hasBlogPostFilter()}
        />
      </compPanelInfra.compPanelFrame>
    );
  },
});

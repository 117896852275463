import constants from '#packages/constants';

import { getSectionCompNamePlugin } from './compName';
import { createAttachLabelPlugin } from './attachLabelPlugin';
import { createIsAllowedToPastePlugin } from './isAllowedToPaste';

import type { Scope } from '#packages/sections';

const bootstrapCorePlugins = (scope: Scope) => {
  const { editorAPI } = scope;

  const compNamePlugin = getSectionCompNamePlugin(scope);
  const attachLabelPlugin = createAttachLabelPlugin(scope);
  const isAllowedToPastePlugin = createIsAllowedToPastePlugin(scope);

  editorAPI.registerCompNamePlugin(
    constants.COMP_TYPES.SECTION,
    compNamePlugin,
  );

  editorAPI.pluginService.registerPlugin(
    editorAPI.pluginService.pluginConstants.ATTACH_LABEL,
    constants.COMP_TYPES.SECTION,
    attachLabelPlugin,
  );

  editorAPI.pluginService.registerPlugin(
    editorAPI.pluginService.pluginConstants.ALLOWED_TO_PASTE,
    constants.COMP_TYPES.SECTION,
    isAllowedToPastePlugin,
  );
};

export { bootstrapCorePlugins };

import type { EntryPoint } from '#packages/apilib';
import { ComponentsApiKey, EditorAPIKey } from '#packages/apis';
import { createFeedbackApi } from './feedbackApi';
import * as feedbackUtil from './feedbackUtil';
import { FeedbackApiKey } from './publicApiKey';

export const FeedbackEntrypoint: EntryPoint = {
  name: 'FeedbackApi',
  declareAPIs: () => [FeedbackApiKey],
  getDependencyAPIs: () => [EditorAPIKey, ComponentsApiKey, EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(FeedbackApiKey, () => createFeedbackApi(shell));
  },
  extend(shell) {
    shell
      .getAPI(ComponentsApiKey)
      .hooks.componentDeleted.tap(({ compType }) => {
        const editorAPI = shell.getAPI(EditorAPIKey);
        if (editorAPI.mediaServices.userFeedback) {
          const { showFeedback, FEEDBACK_EVENT_TYPES } = feedbackUtil;
          showFeedback(
            FEEDBACK_EVENT_TYPES.COMPONENT_REMOVED,
            compType,
            editorAPI.mediaServices.userFeedback.showQuestion.bind(
              editorAPI.mediaServices.userFeedback,
            ),
          );
        }
      });
  },
};

import {
  AssistantAction,
  type IAssistantAction,
  type IAssistantTask,
} from '@wix/tour-maker';
import { EditorAPIKey } from '#packages/apis';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';

import { getStepName } from '../utils/getStepName';
import {
  deleteTextComponentIfNeed,
  getFirstTextComponent,
  getTooltipOffsetYForStageAction,
  setBackdropClipPath,
  removeBackdropClipPath,
  getBackdropClipPath,
} from '../utils/stageUtils';
import { skipAction, createActions } from './util';

import type { CompRef } from 'types/documentServices';
import type { Shell } from '#packages/apilib';

import { StartOrigin } from './tours.types';
import { HOOKS, MEDIA } from './constants';

export const TOUR_NAME = 'welcomeTour';

export const createWelcomeTour = (
  shell: Shell,
  startOrigin?: StartOrigin,
): IAssistantTask => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const addPanelAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('addPanel')
      .thatAttachesTo()
      .hook(HOOKS.addPanel)
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-Add-Title')
      .withContent('First-Time-Tour-Add-Text')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'addPanel', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.addPanel)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .thatOpensRight({ offsetY: 173 })
      .withButton('skipButton')
      .onClickGoTo(lastStepIndex)
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .build();

  const stageAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('stage')
      .thatAttachesTo()
      .hook(HOOKS.stage)
      .and.withBehaviors()
      .thatDoesntScrollToElement()
      .and.withCallbacks()
      .onEnter(async () => {
        const textRef: CompRef = await getFirstTextComponent(editorAPI);
        editorAPI.selection.selectComponentByCompRef(textRef);
        setBackdropClipPath(
          HOOKS.tourBackdrop,
          getBackdropClipPath(editorAPI, textRef),
        );
      })
      .onExit(() => {
        deleteTextComponentIfNeed(editorAPI);
        editorAPI.selection.deselectComponents();
        removeBackdropClipPath(HOOKS.tourBackdrop);
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-Stage-Title')
      .withContent('First-Time-Tour-Stage-Text')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'stage', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.stage)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .thatOpensRight(getTooltipOffsetYForStageAction(editorAPI))
      .withButton('skipButton')
      .onClickGoTo(lastStepIndex)
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .withButton('backButton')
      .label('First-Time-Back-Link')
      .onClickGoTo(index - 1)
      .build();

  const pagesPanelAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('pagesPanel')
      .thatAttachesTo()
      .hook(HOOKS.pagesPanel)
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-Pages-Title')
      .withContent('First-Time-Tour-Pages-Text')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'pagesPanel', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.pagesPanel)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .thatOpensRight({ offsetY: 173 })
      .withButton('skipButton')
      .onClickGoTo(lastStepIndex)
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .withButton('backButton')
      .label('First-Time-Back-Link')
      .onClickGoTo(index - 1)
      .build();

  const mobileAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('mobile')
      .thatAttachesTo()
      .hook(HOOKS.deviceSwitch)
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-Mobile-Title')
      .withContent('First-Time-Tour-Mobile-Text')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'mobile', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.deviceSwitch)
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .thatOpensDown()
      .withButton('skipButton')
      .onClickGoTo(lastStepIndex)
      .withButton('forwardButton')
      .label('First-Time-Next-Link')
      .goToNextStep()
      .withButton('backButton')
      .label('First-Time-Back-Link')
      .onClickGoTo(index - 1)
      .build();

  const publishStepForwardButtonLabel =
    startOrigin !== StartOrigin.HelpMenu
      ? 'First-Time-Next-Link'
      : 'First-Time-Tour-SavePublish-Done-Button';

  const publishAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction =>
    new AssistantAction('publish')
      .thatAttachesTo()
      .hook(HOOKS.publish)
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-SavePublish-Title')
      .withContent('First-Time-Tour-SavePublish-Text')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'publish', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.publish)
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .thatOpensDown()
      .withButton('skipButton')
      .onClickGoTo(lastStepIndex)
      .withButton('forwardButton')
      .label(publishStepForwardButtonLabel)
      .goToNextStep()
      .withButton('backButton')
      .label('First-Time-Back-Link')
      .onClickGoTo(index - 1)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.preview)
      .build();

  const helpAction = (index: number, _: number): IAssistantAction =>
    new AssistantAction('help')
      .thatAttachesTo()
      .hook(HOOKS.helpDropDown)
      .and.withCallbacks()
      .onEnter(async () => {
        editorAPI.topBarMenuBar.openDropDown(
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
          constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_WELCOME_TOUR,
        );
      })
      .onExit(() => {
        editorAPI.topBarMenuBar.closeDropDown(
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
        );
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('First-Time-Tour-End-Title')
      .withContent('First-Time-Tour-End-Text1')
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'help', index))
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .thatOpensRight({ offsetX: 24, offsetY: 7 })
      .withButton('forwardButton')
      .label('First-Time-Tour-End-GotIt-Button')
      .completeOnClick()
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.helpMenuBarItem)
      .build();

  editorAPI.store.dispatch(stateManagement.panels.actions.closeOpenedPanels());

  const actionsList = [
    addPanelAction,
    stageAction,
    pagesPanelAction,
    mobileAction,
    publishAction,
    helpAction,
  ];

  let actions = createActions(actionsList, actionsList.length - 1);

  if (startOrigin === StartOrigin.HelpMenu) {
    actions = skipAction(actions, (a) => a.id === 'help');
  }

  return {
    id: TOUR_NAME,
    onEnter: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: false });
        editorAPI.panelManager.closeAllPanels();
      },
    },
    onExit: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: true }, true);
        deleteTextComponentIfNeed(editorAPI);
      },
    },
    actions,
  } as IAssistantTask;
};

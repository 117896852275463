import type { EditorAPI } from '#packages/editorAPI';
import type { Dispatch, ThunkAction } from 'types/redux';

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    createDefaultData(id: string) {
      return editorAPI.dsRead.data.createItem(id);
    },
  };
};

import type {
  MapStateToProps,
  MapDispatchToProps,
  Dispatch,
  ThunkAction,
} from 'types/redux';
import { textMaskChanges } from '@wix/bi-logger-editor/v2';
import { biLogger } from '#packages/util';
import type {
  TextMaskDesignPanelDispatcher,
  TextMaskDesignPanelMapper,
  TextMaskDesignPanelOwnProps,
} from './TextMask.types';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  ITextMaskDesignPanelPropsClassic,
  TextMaskData,
  TextMaskDesign,
} from '@wix/text-mask-panels';
import type { CompLayout } from '@wix/document-services-types';

export const mapStateToProps: MapStateToProps<
  TextMaskDesignPanelMapper,
  TextMaskDesignPanelOwnProps
> = ({ editorAPI }, props) => {
  const fonts = editorAPI.fonts.getSiteFontsOptions();
  const [compRef] = editorAPI.selection.getSelectedComponents();
  const compDesign = editorAPI.components.design.get(compRef);
  const compData = editorAPI.components.data.get(compRef);
  const compLayout = editorAPI.components.layout.get(compRef);

  return {
    compDesign,
    compData,
    allowAutoScaleControl: false,
    compLayout,
    fonts,
    selectedComponent: props.selectedComponent,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  TextMaskDesignPanelDispatcher,
  TextMaskDesignPanelOwnProps
> = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const compsPointer = editorAPI.selection.getSelectedComponents();
  const componentId = compsPointer[0].id;
  const sendBI = (biData: { fieldNames: string; fieldValues: string }) => {
    const biParams = {
      componentType: 'wixui.TextMask',
      panelName: 'TextMask design',
      componentId,
      fieldType: biData.fieldNames,
      fieldValue: biData.fieldValues,
    };
    biLogger.report(textMaskChanges(biParams));
  };
  return {
    updateData: (data: Partial<TextMaskData>) => {
      editorAPI.components.data.update(compsPointer[0], data);
    },
    updateDesign: (design: Partial<TextMaskDesign>) => {
      editorAPI.components.design.update(compsPointer, design);
    },
    updateLayout: (layout: Partial<CompLayout>) => {
      editorAPI.components.layout.update(compsPointer, layout);
    },
    sendBI,
    openColorPicker:
      editorAPI.openColorPicker as ITextMaskDesignPanelPropsClassic['openColorPicker'],
    colorResolver: editorAPI.theme.colors.get,
    openLanguagesPanel: () =>
      editorAPI.panelManager.openPanel(
        'panels.toolPanels.languageSupport',
        {},
        true,
      ),
    openUploadFonts: () =>
      editorAPI.panelManager.openPanel(
        'panels.toolPanels.fontsUploadPanel',
        {},
        true,
      ),
    mediaServices: editorAPI.mediaServices,
  };
};

import React from 'react';
import {
  Button,
  ContextMenu,
  ContextMenuAction,
  ContextMenuContent,
  RichText,
} from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import { WithRestrictionsRender } from '#packages/editorRestrictions';

import { TRANSLATIONS_MAP } from '../utils/translations';
import { AUTOMATION_IDS } from '../utils/automationIds';

import type { MenuAddItemAction } from '../menuActions';

interface MobileDisclaimerFooterProps {
  openMobileDisclaimerArticle: () => void;
}

export const MobileDisclaimerFooter: React.FC<MobileDisclaimerFooterProps> = ({
  openMobileDisclaimerArticle,
}) => {
  return (
    <footer
      className="menu-manage-panel__footer menu-manage-panel__footer_disclaimer"
      data-aid={AUTOMATION_IDS.FOOTER.CONTAINER}
    >
      <RichText type="T02" className="light">
        <p>
          {`${translate(
            TRANSLATIONS_MAP.MAIN_MENU_FLOW.FOOTER.MOBILE_DISCLAIMER,
          )} `}
          <a onClick={openMobileDisclaimerArticle}>
            {translate(
              TRANSLATIONS_MAP.MAIN_MENU_FLOW.FOOTER.MOBILE_DISCLAIMER_LINK,
            )}
          </a>
        </p>
      </RichText>
    </footer>
  );
};

interface FooterProps {
  addActions: MenuAddItemAction[];
  onMainButtonClick: () => void;
}

const FooterPure: React.FC<FooterProps> = ({
  addActions,
  onMainButtonClick,
}) => {
  return (
    <footer
      className="menu-manage-panel__footer"
      data-aid={AUTOMATION_IDS.FOOTER.CONTAINER}
    >
      <WithRestrictionsRender name={'menu-panel_add-item'}>
        {({ disabled }) => (
          <ContextMenu
            disabled={disabled}
            alignment="RIGHT"
            dataHook={AUTOMATION_IDS.FOOTER.ADD_PAGE_CONTEXT_MENU}
            customButton={
              // for ContextMenuTestkit
              <div data-hook="context-menu-button">
                <Button
                  disabled={disabled}
                  onClick={onMainButtonClick}
                  className="add-page-button"
                  automationId={AUTOMATION_IDS.FOOTER.ADD_PAGE_BUTTON}
                >
                  <div className="add-page-button-content">
                    <span className="add-page-button-title">
                      {translate(TRANSLATIONS_MAP.FOOTER.ADD_MENU_ITEM)}
                    </span>
                  </div>
                </Button>
              </div>
            }
          >
            <ContextMenuContent>
              {addActions.map((action) => (
                <ContextMenuAction
                  key={action.symbolName}
                  onClick={action.onClick}
                  disabled={action.disabled}
                  dataHook={action.automationId}
                  automationId={action.automationId}
                  className="sortable-list-item__context-menu-action"
                >
                  <baseUI.SymbolWrapper
                    className="symbol sortable-list-item__context-menu-action-icon"
                    name={action.symbolName}
                  />
                  <span className="sortable-list-item__context-menu-action-text">
                    {action.title}
                  </span>
                </ContextMenuAction>
              ))}
            </ContextMenuContent>
          </ContextMenu>
        )}
      </WithRestrictionsRender>
    </footer>
  );
};

export const Footer = React.memo(FooterPure, () => true);

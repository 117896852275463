import _ from 'lodash';
import constants from '#packages/constants';
import * as highlightsLayerUtils from './highlightsLayerUtils';
import * as stateManagement from '#packages/stateManagement';
import { componentSelectors } from '#packages/stateManagement';
import { sections, snapToUtils } from '#packages/util';
import { getIsLabelBottom } from '../selectors/isLabelBottom';

import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompLayout, CompRef } from 'types/documentServices';

export interface Highlight {
  type: string;
  compRef: CompRef;
  style: React.CSSProperties;
  isShownOnAllPages?: boolean;
  shouldRenderLabels?: boolean;
  layout?: CompLayout;
  compType?: ReturnType<typeof getCompTypeClassName>;
  displayNameOverride?: string;
}
export interface HighlightsLayerStateProps {
  highlights: Highlight[];
}
export interface HighlightsLayerDispatchProps {
  calculateIsLabelBottom: (compRef: CompRef) => boolean;
}

export interface HighlightsLayerOwnProps {}

const shouldRenderLabels = (editorAPI: EditorAPI, highlight: Highlight) => {
  return (
    highlight.compRef &&
    !editorAPI.utils.isSameRef(
      editorAPI.selection.getSelectedComponents(),
      highlight.compRef,
    ) &&
    (highlight.type === constants.UI.HIGHLIGHTS.TYPES.OVERLAY ||
      highlight.type === constants.UI.HIGHLIGHTS.TYPES.THIN)
  );
};

const getCompTypeClassName = (editorAPI: EditorAPI, highlight: Highlight) => {
  if (
    sections.isSectionsEnabled() &&
    editorAPI.sections.isSection(highlight.compRef)
  )
    return 'classicSection' as const;
};

export const mapStateToProps: MapStateToProps<
  HighlightsLayerStateProps,
  HighlightsLayerOwnProps
> = ({ editorAPI, state: editorState }) => {
  const isPerformingMouseMoveAction =
    stateManagement.mouseActions.selectors.isPerformingMouseMoveAction(
      editorState,
    );
  const focusedContainer =
    stateManagement.selection.selectors.getFocusedContainer(editorState);
  const isSpotlightStageOpen =
    editorAPI.components.is.spotlightStageContainer(focusedContainer);
  const containerHasResponsiveLayout = componentSelectors.hasResponsiveLayout(
    focusedContainer,
    editorAPI.dsRead,
  );

  if (isPerformingMouseMoveAction && !snapToUtils.isNewStageGuidesEnabled()) {
    return {
      highlights: [],
    };
  }

  const highlights: Highlight[] =
    stateManagement.highlights.selectors.getHighlights(editorState);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const currentHighlights = _.map(highlights, function (highlight) {
    const isExist = editorAPI.components.is.exist(highlight.compRef);

    if (!isExist) {
      return Object.assign({}, highlight);
    }
    const parentRef = editorAPI.components.getContainer(highlight.compRef);
    const parentHasResponsiveLayout = componentSelectors.hasResponsiveLayout(
      parentRef,
      editorAPI.dsRead,
    );

    const isShownOnAllPages =
      editorAPI.components.isShowOnAllPages(highlight.compRef) ||
      editorAPI.components.isShowOnSomePages(highlight.compRef);

    const style = highlightsLayerUtils.getStyle(
      editorAPI,
      isSpotlightStageOpen ||
        containerHasResponsiveLayout ||
        parentHasResponsiveLayout
        ? editorAPI.components.layout.getRelativeToScreen(highlight.compRef)
        : highlight.layout,
      highlight.compRef,
    );

    return Object.assign({}, highlight, {
      style,
      isShownOnAllPages,
    });
  });

  return {
    highlights: currentHighlights.map((highlight) => ({
      ...highlight,
      shouldRenderLabels: shouldRenderLabels(editorAPI, highlight),
      compType: getCompTypeClassName(editorAPI, highlight),
    })),
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  HighlightsLayerDispatchProps,
  HighlightsLayerOwnProps
> = (dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    calculateIsLabelBottom: (compRef: CompRef) =>
      getIsLabelBottom(editorAPI, compRef),
  };
};

import React, { type FC, useEffect, useState } from 'react';
import { Text } from '@wix/wix-base-ui';
import { frames } from '#packages/panels';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import { sendBIError } from '../../welcomePanelBI';

import type { ErrorBIData } from '../../types';
import type { PanelTypes } from '../../constants';

interface Props {
  onClose: (origin?: string) => void;
  errorBIData: ErrorBIData | null;
  panelType: PanelTypes;
}

const ErrorMessagePanel: FC<Props> = ({ onClose, errorBIData, panelType }) => {
  const errorMessage = translate(
    'ai_template_injection_high_traffic_error_text',
  );

  const [hasErrorSent, setErrorSent] = useState(false);

  useEffect(() => {
    if (!hasErrorSent && errorMessage && sendBIError) {
      sendBIError({
        query: errorBIData.params,
        errorMessage,
        gptParams: errorBIData?.currentGptParamsVersion,
        prompt: errorBIData.currentPromptsVersion,
        tokens: errorBIData.tokenUsage,
        panelType,
      });
      setErrorSent(true);
    }
  }, [errorMessage, hasErrorSent, setErrorSent, errorBIData, panelType]);

  return (
    <frames.MessagePanelFrame
      panelName="ai-injection-into-template-error-panel"
      dataHook="ai-injection-into-template-error-panel"
      title={translate('ai_template_injection_high_traffic_error_title')}
      illustration={<baseUI.symbol name="unsupportedComponentIllustration" />}
      confirmOnEnterPress
      cancelOnEscPress
      cancelOnClickOutside
      confirmLabel={translate('ai_template_injection_high_traffic_error_cta')}
      onConfirm={onClose}
      onCancel={onClose}
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {errorMessage}
      </Text>
    </frames.MessagePanelFrame>
  );
};

export default ErrorMessagePanel;

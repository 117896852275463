import * as stateManagement from '#packages/stateManagement';
import type { CompRef } from 'types/documentServices';

interface EditorAPISelection {
  selection: {
    getSelectedComponents(): CompRef[];
    deselectComponents(compRef?: CompRef | CompRef[]): void;
    selectComponentByCompRef(compRef: CompRef | CompRef[]): void;
  };
}

function getSelectedComponents(editorAPI: EditorAPISelection) {
  return editorAPI.selection.getSelectedComponents();
}

function deselectComponents(
  editorAPI: EditorAPISelection,
  appData?: unknown,
  token?: unknown,
  options?: { compsToDeselect?: CompRef[] },
) {
  editorAPI.selection.deselectComponents(options?.compsToDeselect ?? []);
}

function selectComponentByCompRef(
  editorAPI: EditorAPISelection,
  appData?: unknown,
  token?: unknown,
  options?: { compsToSelect?: CompRef[] },
) {
  editorAPI.selection.selectComponentByCompRef(options?.compsToSelect ?? []);
}

interface EditorAPIDispatch {
  store: {
    dispatch(action: unknown): void;
  };
}

function locateAndHighlightComponentByCompRef(
  editorAPI: EditorAPIDispatch,
  appData: unknown,
  token: unknown,
  options: { compRef: CompRef },
) {
  editorAPI.store.dispatch(
    stateManagement.componentLocator.actions.locateComponentAndHighlight(
      options.compRef,
      false,
    ),
  );
}

function clearHighlights(editorAPI: EditorAPIDispatch) {
  editorAPI.store.dispatch(
    stateManagement.highlights.actions.clearOverlayHighlights(),
  );
}

export {
  getSelectedComponents,
  deselectComponents,
  selectComponentByCompRef,
  locateAndHighlightComponentByCompRef,
  clearHighlights,
};

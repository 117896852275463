import type { Shell } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { biLogger } from '#packages/util';
import { type PluginPlacement } from '@wix/ambassador-app-service-webapp/types';
import {
  addPluginTry,
  pluginAdded,
  pluginRemoved,
} from '@wix/bi-logger-editor-platform/v2';
import type {
  PluginInfo,
  WidgetPointer,
  WidgetSlot,
} from '@wix/editor-platform-host-integration/ui';
import { getEditorHost } from '@wix/santa-editor-utils';
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import type { CompRef, DsItem } from 'types/documentServices';
import { type Origin } from '../api/document/tpa/widgetPlugins';
import blocksHostApiFactory from './blocksHostAPI';
import tpaHostApiFactory, { isVirtualSlotsPlaceholder } from './tpaHostAPI';
import { getPluginInfo, getPluginMarketData } from './widgetPluginsUtils';

const serializePlacement = (placement: PluginPlacement) =>
  placement &&
  `${placement.appDefinitionId}_${placement.widgetId}_${placement.slotId}`;

export default (shell: Shell) => {
  const editorType = getEditorHost()?.toLowerCase();
  const editorAPI = shell.getAPI(EditorAPIKey);

  const blocksHostAPI = blocksHostApiFactory(editorAPI);
  const tpaHostAPI = tpaHostApiFactory(editorAPI);

  function isTpaWidget(widgetCompRef: CompRef): boolean {
    return editorAPI.dsRead.tpa.isTpaByCompType(
      editorAPI.components.getType(widgetCompRef),
    );
  }

  function resolveHostAPI(widgetCompRef: CompRef) {
    return isTpaWidget(widgetCompRef) ? tpaHostAPI : blocksHostAPI;
  }

  function resolveHostAPIBySlotRef(slotPlaceholderCompRef: CompRef) {
    return isVirtualSlotsPlaceholder(slotPlaceholderCompRef)
      ? tpaHostAPI
      : blocksHostAPI;
  }

  function resolveHostAPIByWidgetPluginRef(widgetPluginRef: CompRef) {
    return blocksHostAPI.isWidgetPluginComponent(widgetPluginRef)
      ? blocksHostAPI
      : tpaHostAPI;
  }

  function hasWidgetSlot(widgetCompRef: CompRef): boolean {
    return resolveHostAPI(widgetCompRef).hasWidgetSlot(widgetCompRef);
  }

  function getWidgetSlot(slotCompRef: CompRef) {
    return resolveHostAPIBySlotRef(slotCompRef).getWidgetSlot(slotCompRef);
  }

  function getWidgetSlots(widgetCompRef: CompRef): WidgetSlot[] {
    return resolveHostAPI(widgetCompRef).getWidgetSlots(widgetCompRef);
  }

  function getWidgetSlotPlacement(slotComponentRef: CompRef) {
    return resolveHostAPIBySlotRef(slotComponentRef).getWidgetSlot(
      slotComponentRef,
    )?.placement;
  }

  async function installWidgetPlugin(
    slotComponentRef: CompRef,
    widgetPointer: WidgetPointer,
    origin: Origin,
  ): Promise<CompRef> {
    const installationInBlocks =
      origin.origin === 'pluginsPanel' &&
      origin.action === 'installDefaultPluginInBlocks';
    const installationViaSdk = origin.origin === 'sdk';

    const placement: WidgetSlot['placement'] = installationInBlocks
      ? getSlotPlacementInBlocks(slotComponentRef)
      : getWidgetSlotPlacement(slotComponentRef);

    if (installationInBlocks) {
      biLogger.report(
        addPluginTry({
          origin: origin.origin,
          target_type: 'widget_plugins',
          slot_id: slotComponentRef.id,
          plugin_id: widgetPointer.widgetId,
          role: 'dev',
          host_app_id: placement.appDefinitionId,
          editorType,
        }),
      );

      const compRef = await resolveHostAPIBySlotRef(
        slotComponentRef,
      ).installWidgetPlugin(slotComponentRef, widgetPointer, origin);

      biLogger.report(
        pluginAdded({
          origin: origin.origin,
          target_type: 'widget_plugins',
          slot_id: slotComponentRef.id,
          placement_id: serializePlacement(placement),
          host_app_id: placement.appDefinitionId,
          app_def_id_plugin: widgetPointer.appDefinitionId,
          plugin_id: widgetPointer.widgetId,
          role: 'dev',
          editorType,
        }),
      );

      return compRef;
    }

    biLogger.report(
      addPluginTry({
        origin: origin.origin,
        target_type: 'widget_plugins',
        slot_id: slotComponentRef.id,
        plugin_id: widgetPointer.widgetId,
        role: 'user',
        host_app_id: placement.appDefinitionId,
        editorType,
      }),
    );

    const compRef = await resolveHostAPIBySlotRef(
      slotComponentRef,
    ).installWidgetPlugin(slotComponentRef, widgetPointer, origin);

    biLogger.report(
      pluginAdded({
        origin: origin.origin,
        target_type: 'widget_plugins',
        slot_id: slotComponentRef.id,
        placement_id: serializePlacement(placement),
        host_app_id: placement.appDefinitionId,
        app_def_id_plugin: widgetPointer.appDefinitionId,
        plugin_id: widgetPointer.widgetId,
        role: 'user',
        editorType,
      }),
    );

    if (installationViaSdk) {
      return compRef;
    }

    await editorAPI.waitForChangesAppliedAsync();

    const { applicationId } = editorAPI.dsRead.platform.getAppDataByAppDefId(
      placement.appDefinitionId,
    );
    const hostComponentRef =
      resolveHostAPIBySlotRef(slotComponentRef).getWidgetHostRef(
        slotComponentRef,
      );

    editorAPI.dsActions.platform.notifyApplication(
      applicationId,
      platformEvents.factory.widgetPluginAdded({
        slotComponentRef,
        hostComponentRef,
        placement,
        plugin: widgetPointer,
      }),
    );

    return compRef;
  }

  async function uninstallWidgetPlugin(
    slotComponentRef: CompRef,
    origin = '',
  ): Promise<void> {
    const uninstallationInBlocks = origin === 'blocksAppEditor';

    resolveHostAPIBySlotRef(slotComponentRef).uninstallWidgetPlugin(
      slotComponentRef,
    );

    const widgetPointer = getWidgetPointer(slotComponentRef);
    const placement = getWidgetSlotPlacement(slotComponentRef);
    biLogger.report(
      pluginRemoved({
        origin,
        target_type: 'widget_plugins',
        slot_id: slotComponentRef.id,
        placement_id: serializePlacement(placement),
        host_app_id: placement.appDefinitionId,
        plugin_id: widgetPointer.widgetId,
        role: uninstallationInBlocks ? 'dev' : 'user',
        editorType,
      }),
    );
    if (platformEvents.factory.widgetPluginRemoved) {
      const { applicationId } = editorAPI.dsRead.platform.getAppDataByAppDefId(
        placement.appDefinitionId,
      );
      const hostComponentRef =
        resolveHostAPIBySlotRef(slotComponentRef).getWidgetHostRef(
          slotComponentRef,
        );
      editorAPI.dsActions.platform.notifyApplication(
        applicationId,
        platformEvents.factory.widgetPluginRemoved({
          slotComponentRef,
          hostComponentRef,
          placement,
          plugin: widgetPointer,
        }),
      );
    }
  }

  function isWidgetSlotPopulated(slotComponentRef: CompRef) {
    return resolveHostAPIBySlotRef(slotComponentRef).isWidgetSlotPopulated(
      slotComponentRef,
    );
  }

  function getWidgetPointer(
    slotPlaceholderCompRef: CompRef,
  ): WidgetPointer | undefined {
    return resolveHostAPIBySlotRef(slotPlaceholderCompRef).getWidgetPointer(
      slotPlaceholderCompRef,
    );
  }

  async function moveWidgetToAnotherSlot(
    fromSlotComponentRef: CompRef,
    toSlotComponentRef: CompRef,
  ) {
    const currentWidgetPluginPointer = getWidgetPointer(fromSlotComponentRef);

    if (!currentWidgetPluginPointer) {
      return;
    }

    await installWidgetPlugin(toSlotComponentRef, currentWidgetPluginPointer, {
      origin: 'contextMenu',
      action: 'movePlugin',
    });
    await uninstallWidgetPlugin(fromSlotComponentRef);
    await editorAPI.waitForChangesAppliedAsync();
  }

  function getWidgetHostRef(slotPlaceholderCompRef: CompRef): CompRef {
    return resolveHostAPIBySlotRef(slotPlaceholderCompRef).getWidgetHostRef(
      slotPlaceholderCompRef,
    );
  }

  function getSlotPlaceholderDisplayName(
    slotPlaceholderCompRef: CompRef,
  ): string {
    return resolveHostAPIBySlotRef(
      slotPlaceholderCompRef,
    ).getSlotPlaceholderDisplayName(slotPlaceholderCompRef);
  }

  function getAvailableWidgetSlotsToMoveContent(
    slotComponentRef: CompRef,
  ): Array<{ slotRef: CompRef; name: string }> {
    const hostCompRef = getWidgetHostRef(slotComponentRef);

    if (!hostCompRef) {
      return [];
    }

    const widgetPluginPointer = getWidgetPointer(slotComponentRef);
    const widgetSlots = getWidgetSlots(hostCompRef);
    const marketData = getPluginMarketData(editorAPI, widgetPluginPointer);

    const currentPluginPlacements = marketData?.placements ?? [];

    const availableSlots = widgetSlots.filter((widgetSlot) => {
      if (isWidgetSlotPopulated(widgetSlot.compRef)) {
        return false;
      }

      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      return _.find(currentPluginPlacements, widgetSlot.placement);
    });

    return availableSlots.map((slot) => ({
      slotRef: slot.compRef,
      name: getSlotPlaceholderDisplayName(slot.compRef),
    }));
  }

  function areSlotsToMoveAvailable(slotComponentRef: CompRef): boolean {
    return getAvailableWidgetSlotsToMoveContent(slotComponentRef).length > 0;
  }

  function getWidgetSlotsToMoveContent(slotComponentRef: CompRef): Array<{
    slotRef: CompRef;
    pluginInfo: PluginInfo;
    name: string;
    isPopulated: boolean;
    isSelected: boolean;
  }> {
    const hostCompRef = getWidgetHostRef(slotComponentRef);

    if (!hostCompRef) {
      return [];
    }

    const widgetPluginPointer = getWidgetPointer(slotComponentRef);
    const widgetSlots = getWidgetSlots(hostCompRef);
    const marketData = getPluginMarketData(editorAPI, widgetPluginPointer);

    const currentPluginPlacements = marketData?.placements ?? [];
    const currentSlotPlacement = getWidgetSlotPlacement(slotComponentRef);

    const slotsToMove = widgetSlots.filter((widgetSlot) => {
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      return _.find(currentPluginPlacements, widgetSlot.placement);
    });

    return slotsToMove.map((slot) => ({
      slotRef: slot.compRef,
      pluginInfo: slot.pluginInfo,
      name: getSlotPlaceholderDisplayName(slot.compRef),
      isPopulated: isWidgetSlotPopulated(slot.compRef),
      isSelected: _.isEqual(currentSlotPlacement, slot.placement),
    }));
  }

  function areSlotsToMoveExist(slotComponentRef: CompRef): boolean {
    return getWidgetSlotsToMoveContent(slotComponentRef).length > 1;
  }

  function unhighlightSlot(compRef: CompRef) {
    return resolveHostAPIBySlotRef(compRef).unhighlightSlot(compRef);
  }

  async function highlightSlot(compRef: CompRef) {
    return resolveHostAPIBySlotRef(compRef).highlightSlot(compRef);
  }

  function selectSlot(compRef: CompRef) {
    return resolveHostAPIBySlotRef(compRef).selectSlot(compRef);
  }

  function scrollToSlot(compRef: CompRef) {
    return resolveHostAPIBySlotRef(compRef).scrollToSlot(compRef);
  }

  function isSlotContentMovable(slotsPlaceholderCompRef: CompRef): boolean {
    return (
      isWidgetSlotPopulated(slotsPlaceholderCompRef) &&
      areSlotsToMoveAvailable(slotsPlaceholderCompRef)
    );
  }

  function isSlotContentPotentialMovable(
    slotsPlaceholderCompRef: CompRef,
  ): boolean {
    return (
      isWidgetSlotPopulated(slotsPlaceholderCompRef) &&
      areSlotsToMoveExist(slotsPlaceholderCompRef)
    );
  }

  function isWidgetPluginComponent(widgetPluginCompRef: CompRef): boolean {
    return (
      tpaHostAPI.isWidgetPluginComponent(widgetPluginCompRef) ||
      blocksHostAPI.isWidgetPluginComponent(widgetPluginCompRef)
    );
  }

  function isRefComponentVerticalApp(refComponentPointer: CompRef): boolean {
    const { appDefinitionId } =
      editorAPI.components.data.get(refComponentPointer);
    const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
    if (!appData) return false;
    return appData.components?.some(({ type }) => type === 'PLATFORM');
  }

  function getSlotPlaceholderRefByContentRef(
    compRef: CompRef,
  ): CompRef | undefined {
    return isWidgetPluginComponent(compRef)
      ? resolveHostAPIByWidgetPluginRef(
          compRef,
        ).getSlotPlaceholderRefByContentRef(compRef)
      : undefined;
  }

  function getWidgetHostRefByWidgetRef(compRef: CompRef): CompRef {
    return resolveHostAPIByWidgetPluginRef(compRef).getWidgetHostRef(compRef);
  }

  function getWidgetHostRefBySlotRef(compRef: CompRef): CompRef {
    return resolveHostAPIBySlotRef(compRef).getWidgetHostRef(compRef);
  }

  function getPlacementInBlocks(slotId: string) {
    const widgetPointer = editorAPI.dsRead.appStudio.widgets.getByRootCompId(
      editorAPI.dsRead.pages.getCurrentPageId(),
    );
    const devCenterWidgetId = editorAPI.dsRead.data.getById(widgetPointer?.id)
      ?.devCenterWidgetId;
    return {
      appDefinitionId: window.appStudioModel.devSiteAppDefId,
      widgetId: devCenterWidgetId,
      slotId,
    };
  }

  function getSlotInBlocks(slotCompRef: CompRef): WidgetSlot {
    const slotComponentData: DsItem | undefined = editorAPI.components.data.get(
      editorAPI.documentServices.components.slots.getSlotsData(slotCompRef)
        .slot,
    );

    const slotId = getSlotIdInBlocksAppEditor(slotCompRef);

    const interfaces = editorAPI.components.data.get(slotCompRef)?.interfaces;

    return {
      compRef: slotCompRef,
      interfaces,
      role: slotId,
      pluginInfo: getPluginInfo(editorAPI, slotComponentData),
      placement: getPlacementInBlocks(slotId),
    };
  }

  function getSlotIdInBlocksAppEditor(slotCompRef: CompRef): string {
    return editorAPI.platform.controllers.connections.getPrimaryConnection(
      slotCompRef,
    )?.role;
  }

  function getSlotPlacementInBlocks(
    slotCompRef: CompRef,
  ): WidgetSlot['placement'] {
    const slotId = getSlotIdInBlocksAppEditor(slotCompRef);
    return getPlacementInBlocks(slotId);
  }

  return {
    hasWidgetSlot,
    getWidgetSlot,
    getSlotInBlocks,
    getWidgetSlots,
    installWidgetPlugin,
    uninstallWidgetPlugin,
    moveWidgetToAnotherSlot,
    scrollToSlot,
    getAvailableWidgetSlotsToMoveContent,
    getWidgetSlotsToMoveContent,
    highlightSlot,
    unhighlightSlot,
    selectSlot,
    isSlotContentMovable,
    isSlotContentPotentialMovable,
    isWidgetPluginComponent,
    isRefComponentVerticalApp,
    getSlotPlaceholderRefByContentRef,
    getWidgetHostRefByWidgetRef,
    getWidgetHostRefBySlotRef,
  };
};

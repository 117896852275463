import { siteHasNewBlog } from '../utils/blogUtils';

import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';

export const mapStateToProps = ({ editorAPI }: AnyFixMe) => ({
  hasNewBlog: siteHasNewBlog(editorAPI),
});

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    onHelp: () => {
      editorAPI.panelManager.openHelpCenter(
        '59dba550-a185-4852-a470-ff3b435630e1',
      );
    },
    onContinue: () => {
      editorAPI.account.openSiteHistory();
    },
    onClose: () => {
      editorAPI.panelManager.closeAllPanels();
    },
  };
};

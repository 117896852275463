import React from 'react';
import { EmptyResults, Link, Icons } from '@wix/editor-search-ui';
import { useTranslation } from 'react-i18next';

import { hoc } from '#packages/util';

import {
  editorSearchBiLogger,
  BILinkButtonNames,
} from '../../services/biLogger/biLogger';
import { TRANSLATIONS } from '../../translations';
import { AUTOMATION_IDS } from '../../automationIds';

import { mapDispatchToProps } from './EmptyState.mapper';

import styles from './EmptyState.scss';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export type EmptyStateProps = {
  query: string;
} & ReturnType<typeof mapDispatchToProps>;

export const EmptyStateComponent: React.FC<EmptyStateProps> = ({
  query,
  openAppMarket,
  openHelpCenterHome,
}) => {
  const [translate] = useTranslation();

  const handleAppMarketClick = () => {
    openAppMarket();
    editorSearchBiLogger.logLinkClicked(BILinkButtonNames.AppMarketHome);
  };

  const handleHelpCenterClick = () => {
    openHelpCenterHome();
    editorSearchBiLogger.logLinkClicked(BILinkButtonNames.HelpCenterHome);
  };

  return (
    <EmptyResults
      title={translate(TRANSLATIONS.EMPTY_STATE.TITLE)}
      subtitle={translate(TRANSLATIONS.EMPTY_STATE.TEXT, {
        search_term: query,
      })}
      footer={
        <div className={styles.footer}>
          <Link
            key="explore-app-market"
            className={styles.footerLink}
            dataHook={AUTOMATION_IDS.FOOTER.APP_MARKET_LINK}
            icon={<Icons.AppMarket />}
            onClick={handleAppMarketClick}
          >
            {translate(TRANSLATIONS.FOOTER.APP_MARKET_LINK)}
          </Link>
          <Link
            key="go-to-help-center"
            className={styles.footerLink}
            dataHook={AUTOMATION_IDS.FOOTER.HELP_CENTER_LINK}
            icon={<Icons.Article />}
            onClick={handleHelpCenterClick}
          >
            {translate(TRANSLATIONS.FOOTER.HELP_CENTER_LINK)}
          </Link>
        </div>
      }
    />
  );
};

export const EmptyState = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(EmptyStateComponent);

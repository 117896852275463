import React from 'react';

import { translate } from '#packages/i18n';
import { Text, Button } from '@wix/wix-base-ui';

import styles from './../styles.scss';
import { categories } from '../consts';
import { ProgressPlaceholder } from './progressImages';
import { TRANSLATION_KEYS } from '../../../consts';

interface CategoriesProps {
  openConnectDomain: () => void;
}

export const Categories: React.FC<CategoriesProps> = ({
  openConnectDomain,
}) => (
  <div className={styles.categoriesWrapper}>
    {categories.map(({ title, subtitle, result, image }) => (
      <div className={styles.category} key={title}>
        <div className={styles.text}>
          <Text size="tiny" weight="bold" shouldTranslate={false}>
            {translate(title)}
          </Text>
          <Text
            size="tiny"
            weight="thin"
            shouldTranslate={false}
            customEllipsisProps={{ maxLines: 2 }}
          >
            {translate(subtitle)}
          </Text>
        </div>

        <div className={styles.progressImageWrapper}>
          <ProgressPlaceholder />
          {image}
          <Text
            size="tiny"
            weight="bold"
            shouldTranslate={false}
            className={styles.result}
          >
            {result}
          </Text>
        </div>
      </div>
    ))}

    <div className={styles.connectDomain}>
      <Text size="tiny" weight="bold" shouldTranslate={false}>
        {translate(TRANSLATION_KEYS.RESULTS_NEXT_STEP_LABEL)}
      </Text>
      <Button
        className="btn-sm"
        onClick={openConnectDomain}
        dataHook="connect-domain-button"
      >
        {translate(TRANSLATION_KEYS.RESULTS_NEXT_STEP_BUTTON)}
      </Button>
    </div>
  </div>
);

import experiment from 'experiment';
import type { WithStartItemDrag } from '#packages/util';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import { BasePublicApi } from '#packages/apilib';
import { type PanelProps, userPreferences } from '#packages/stateManagement';
import {
  convertBusinessTypeToBiString,
  extractAppIds,
  extractStructure,
  filterCorruptedPagePresets,
  fixSerializedApplicationIds,
  fixStructureMobileHints,
  getAppDefinitionToApplicationIdMap,
  getFilteredPages,
  getFilteredResults,
  getSectionOrWidgetRef,
  installAppsByOrder,
  isAppOrTpaInstalled,
  preInstallAddedSectionAppsIfNeeded,
  removeTransparentBoxesIfNeeded,
  setupAiSectionCreatorFeedbackLoop,
  shouldShowVerticalAppInstallNotification,
  showVerticalSectionInstallNotification,
  translateSectionNameIfNeeded,
} from './addPresetUtil';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { fetchLayoutFamilies } from '@wix/editor-site-generator';
import {
  getAddSectionPlatformOrigin,
  origin as addSectionOrigin,
} from './addSection/addSectionPanelUtil';
import type { AddPresetScope } from './addPresetApiEntry';
import type { CompRef, CompStructure } from 'types/documentServices';
import type {
  AddPageCategory,
  PageLabel,
  PagePresetDefinition,
  SectionCategoryDefinition,
  SectionPresetDefinition,
} from './types';
import {
  getComponentsBelowPosition,
  shiftComponents,
} from '#packages/sectionsOnStage';
import * as platformEvents from 'platformEvents';
import {
  WIX_BOOKINGS,
  WIX_ECOM,
  WIX_EVENTS,
  WIX_FORMS,
  WIX_NEW_STORES,
  WIX_PRO_GALLERY,
} from '@wix/app-definition-ids';
import { addPanelUtils } from '#packages/addPanelInfra';
import {
  getNextSectionDefaultNameUtil,
  processSavedSections,
} from './addSection/savedSectionUtils';
import type { KitDefinition } from '@wix/editor-kits';
import { fetchKitDefinitions } from '@wix/editor-kits';
import {
  CREATE_WITH_AI_CATEGORY_ID,
  SavedSectionCategory,
} from './addSection/consts';
import type { AddSectionPanelOwnProps } from './addSection/addSectionPanelMapper';
import type { EditorAPI } from '#packages/editorAPI';
import type { SiteGlobalDataApi } from '#packages/siteGlobalData';
import type { ContentInjectionApi } from '#packages/contentInjection';
import {
  ADD_PRESET_SERVERLESS_BASE_URL,
  AI_SECTION_FEEDBACK_ALREADY_SHOWN,
  AI_SECTIONS_COLLECTION_ID,
  DEFAULT_LANGUAGE,
  SECTIONS_SCOPE_PREFIX,
  TPA_CLONE_DATA_APPS,
} from './addPresetConsts';
import { isMeshLayoutEnabled } from '#packages/layout';
import type { AnimationKitDefinition } from '#packages/animations';

export function getPresetsBundleBaseUrl(): string {
  return window.serviceTopology.scriptsLocationMap['editor-presets-bundle'];
}

export const loadSectionsFromServerless = (
  { editorAPI }: AddPresetScope,
  categoryId: string,
  collectionId: keyof typeof SECTIONS_SCOPE_PREFIX,
  language: string,
): Promise<SectionPresetDefinition[]> => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append(
    'Authorization',
    editorAPI.dsRead.platform.getAppDataByApplicationId('-666')?.instance,
  );
  return util.http.fetchJson(
    `${ADD_PRESET_SERVERLESS_BASE_URL}/sections?categoryId=${categoryId}&language=${language}&collectionId=${collectionId}`,
    {
      method: 'GET',
      headers,
    },
  );
};

export const loadSections = async (
  scope: AddPresetScope,
  categoryId: string,
  language: string,
  collectionId: keyof typeof SECTIONS_SCOPE_PREFIX = 'SectionCategories',
): Promise<SectionPresetDefinition[]> => {
  if (experiment.isOpen('se_addDesignerSection')) {
    return loadSectionsFromServerless(
      scope,
      categoryId,
      collectionId,
      language,
    );
  }
  return fetch(
    `${getPresetsBundleBaseUrl()}/${
      SECTIONS_SCOPE_PREFIX[collectionId]
    }_${categoryId}_${language}.json`,
  )
    .then((res) => res.json())
    .catch((e: Error) => {
      ErrorReporter.captureException(e, {
        tags: { failedLoadSectionPresets: true },
      });
      return [];
    });
};

export const loadPagesFromServerless = (
  scope: AddPresetScope,
  categoryId: string,
  language: string,
): Promise<PagePresetDefinition[]> => {
  return util.http.fetchJson(
    `${ADD_PRESET_SERVERLESS_BASE_URL}pages/?categoryId=${categoryId}&language=${language}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization:
          scope.editorAPI.dsRead.platform.getAppDataByApplicationId('-666')
            ?.instance,
      }),
    },
  );
};

export const loadPages = async (
  scope: AddPresetScope,
  categoryId: string,
  language: string,
  canRetry = true,
): Promise<PagePresetDefinition[]> => {
  if (experiment.isOpen('se_addDesignerPage')) {
    return loadPagesFromServerless(scope, categoryId, language);
  }
  return fetch(
    `${getPresetsBundleBaseUrl()}/pages_${categoryId}_${language}.json`,
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Failed to fetch loadPages`);
      }
      return res;
    })
    .then((res) => res.json())
    .then(getFilteredPages)
    .catch((e: Error) => {
      if (language !== DEFAULT_LANGUAGE && canRetry) {
        return loadPages(scope, categoryId, DEFAULT_LANGUAGE, false);
      }
      ErrorReporter.captureException(e, {
        tags: { failedLoadPagesPresets: true },
        extra: {
          url: `${getPresetsBundleBaseUrl()}/pages_${categoryId}_${language}.json`,
        },
      });
      return [];
    })
    .then((pagePresets) =>
      experiment.isOpen('se_filterCorruptedPagePresets')
        ? filterCorruptedPagePresets(pagePresets)
        : pagePresets,
    );
};

export const loadSectionCategoriesFromServerless = (
  scope: AddPresetScope,
): Promise<SectionCategoryDefinition[]> => {
  return util.http.fetchJson(
    `${ADD_PRESET_SERVERLESS_BASE_URL}/sections/categories`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization:
          scope.editorAPI.dsRead.platform.getAppDataByApplicationId('-666')
            ?.instance,
      }),
    },
  );
};

export const loadSectionCategoriesFromProduction = (): Promise<
  SectionCategoryDefinition[]
> => {
  return util.http.fetchJson(
    `${getPresetsBundleBaseUrl()}/sections_categories.json`,
  );
};

export const loadAiSectionCategoriesFromProduction = (): Promise<
  SectionCategoryDefinition[]
> => {
  return util.http
    .fetchJson(`${getPresetsBundleBaseUrl()}/ai_sections_categories.json`)
    .catch((err: Error): SectionCategoryDefinition[] => {
      ErrorReporter.captureException(err, {
        tags: { failedLoadAiSectionCategories: true },
      });
      return [];
    });
};

export const loadAiSectionCategoriesFromServerless = (
  scope: AddPresetScope,
): Promise<SectionCategoryDefinition[]> => {
  return util.http.fetchJson(
    `${ADD_PRESET_SERVERLESS_BASE_URL}/sections/categories?collectionId=${AI_SECTIONS_COLLECTION_ID}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization:
          scope.editorAPI.dsRead.platform.getAppDataByApplicationId('-666')
            ?.instance,
      }),
    },
  );
};

const loadAiSectionCategories = (scope: AddPresetScope) => {
  // eslint-disable-next-line @wix/santa/no-falsy-experiment
  if (!experiment.isOpen('se_createSectionWithAI')) return;

  return experiment.isOpen('se_addDesignerSection')
    ? loadAiSectionCategoriesFromServerless(scope)
    : loadAiSectionCategoriesFromProduction();
};

export const loadSectionCategories = async (
  scope: AddPresetScope,
): Promise<void> => {
  const [sectionCategories, aiSectionCategories] = (
    await Promise.all([
      experiment.isOpen('se_addDesignerSection')
        ? loadSectionCategoriesFromServerless(scope)
        : loadSectionCategoriesFromProduction(),
      loadAiSectionCategories(scope),
    ])
  ).map((categoriesList) =>
    categoriesList
      ? (getFilteredResults(
          scope.editorAPI,
          categoriesList,
        ) as SectionCategoryDefinition[])
      : [],
  );

  scope.store.setSectionsCategories(sectionCategories, aiSectionCategories);
};

export const loadPagesCategoriesFromServerless = (
  scope: AddPresetScope,
): Promise<AddPageCategory[]> => {
  return util.http.fetchJson(
    `${ADD_PRESET_SERVERLESS_BASE_URL}/pages/categories/`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization:
          scope.editorAPI.dsRead.platform.getAppDataByApplicationId('-666')
            ?.instance,
      }),
    },
  );
};

export const loadPagesCategoriesFromProduction = (): Promise<
  AddPageCategory[]
> => {
  return util.http.fetchJson(
    `${getPresetsBundleBaseUrl()}/pages_categories.json`,
  );
};

export const loadPagesCategories = async (
  scope: AddPresetScope,
): Promise<void> => {
  const pagesCategories = experiment.isOpen('se_addDesignerPage')
    ? await loadPagesCategoriesFromServerless(scope)
    : await loadPagesCategoriesFromProduction();
  if (pagesCategories.length) {
    scope.editorCoreApi.hooks.initReady.promise.then(() => {
      scope.store.setPagesCategories(
        getFilteredResults(
          scope.editorAPI,
          pagesCategories,
        ) as AddPageCategory[],
      );
    });
  }
};

export const loadPagesLabelsFromServerless = (
  scope: AddPresetScope,
): Promise<PageLabel[]> => {
  return util.http.fetchJson(
    `${ADD_PRESET_SERVERLESS_BASE_URL}/pages/labels/`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization:
          scope.editorAPI.dsRead.platform.getAppDataByApplicationId('-666')
            ?.instance,
      }),
    },
  );
};

export const loadPagesLabelsFromProduction = (): Promise<PageLabel[]> => {
  return util.http.fetchJson(`${getPresetsBundleBaseUrl()}/pages_labels.json`);
};

export const loadPagesLabels = async (scope: AddPresetScope): Promise<void> => {
  const labels = experiment.isOpen('se_addDesignerPage')
    ? await loadPagesLabelsFromServerless(scope)
    : await loadPagesLabelsFromProduction();
  scope.store.setPagesLabels(labels);
};

export const loadLayoutFamilies = async (
  { store, hooks }: AddPresetScope,
  isStagingMode: boolean,
): Promise<void> => {
  const layoutFamilies = await fetchLayoutFamilies(
    isStagingMode,
    getPresetsBundleBaseUrl(),
  );
  store.setLayoutFamilies(layoutFamilies);
  hooks.layoutFamiliesReady.resolve();
};

export const loadKitDefinitions = async (
  { store, hooks }: AddPresetScope,
  isStagingMode: boolean,
): Promise<void> => {
  const kitDefinitions = await fetchKitDefinitions(
    isStagingMode,
    getPresetsBundleBaseUrl(),
  );
  store.setKitDefinitions(kitDefinitions);
  hooks.kitDefinitionsReady.resolve();
};

const loadAnimationKitsFromServerless = (
  scope: AddPresetScope,
): Promise<AnimationKitDefinition[]> => {
  return util.http.fetchJson(`${ADD_PRESET_SERVERLESS_BASE_URL}animationKits`, {
    method: 'GET',
    headers: new Headers({
      Authorization:
        scope.editorAPI.dsRead.platform.getAppDataByApplicationId('-666')
          ?.instance,
    }),
  });
};

const loadAnimationKitsFromProduction = (): Promise<
  AnimationKitDefinition[]
> => {
  return util.http.fetchJson(`${getPresetsBundleBaseUrl()}/animationKits.json`);
};

export const loadAnimationKits = async (
  scope: AddPresetScope,
  isStagingMode: boolean,
): Promise<void> => {
  const animationKits = isStagingMode
    ? await loadAnimationKitsFromServerless(scope)
    : await loadAnimationKitsFromProduction();
  scope.store.setAnimationKits(animationKits);
};

function setCategoryByUniquePagesUri(
  scope: AddPresetScope,
  uniquePageUri: string,
): void {
  scope.store.setCategoryByUniquePagesUri(uniquePageUri);
}

type AddSectionAddMethod =
  | 'drag'
  | 'click'
  | 'keyboard'
  | 'context_click'
  | 'silent_install';
const SCROLL_DURATION_TO_SECTION_SEC = 1.5;

const mapApplicationToFedopsEvent = {
  [WIX_PRO_GALLERY]:
    util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_INSTALL_PRO_GALLERY,
  [WIX_FORMS]: util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_INSTALL_FORMS,
  [WIX_ECOM]: util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_INSTALL_STORES,
  [WIX_NEW_STORES]:
    util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_INSTALL_STORES,
  [WIX_BOOKINGS]:
    util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_INSTALL_BOOKINGS,
  [WIX_EVENTS]: util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_INSTALL_EVENTS,
};

export async function addSectionPreset(
  scope: AddPresetScope,
  attachCandidate: CompRef,
  structure: Promise<Response> | CompStructure,
  stageEntryIndex = 0, // until dropzone is opened simultaneously with Add Section Panel
  categoryDefinition: SectionCategoryDefinition,
  sectionDefinition: SectionPresetDefinition,
  ownProps: Omit<AddSectionPanelOwnProps, keyof WithStartItemDrag>,
  addMethod: AddSectionAddMethod,
  addOrigin: string,
  shouldApplyOriginTheme: boolean = false,
  itemIndex?: number,
): Promise<CompRef> {
  const { editorAPI, historyAPI, siteGlobalDataAPI, contentInjectionAPI } =
    scope;
  const { notificationText } = categoryDefinition;

  let sectionStructure: CompStructure = null;

  if (!(structure instanceof Promise)) {
    sectionStructure = structure;
  }

  const processSavedSectionStructure = processSavedSections(editorAPI, {
    shouldApplyOriginTheme,
  });

  sectionStructure =
    sectionStructure ||
    (await extractStructure(
      structure as Promise<Response>,
      categoryDefinition,
      processSavedSectionStructure,
    ));

  fixStructureMobileHints(sectionStructure);

  const appIds = extractAppIds(
    sectionStructure,
    sectionDefinition,
    categoryDefinition,
  );

  const isAppInstallationRequired = appIds.length
    ? appIds.some((appId) => isAppOrTpaInstalled(editorAPI, appId) === false)
    : false;

  addSectionStartReport(
    editorAPI,
    siteGlobalDataAPI,
    contentInjectionAPI,
    categoryDefinition,
    sectionDefinition,
    addMethod,
    ownProps,
    isAppInstallationRequired,
    itemIndex,
  );

  if (appIds.length) {
    try {
      await preInstallAddedSectionAppsIfNeeded(
        scope,
        appIds,
        getAddSectionPlatformOrigin(),
        mapApplicationToFedopsEvent,
      );
      if (isAppInstallationRequired) {
        addSectionStartAfterAppInstallReport(
          editorAPI,
          categoryDefinition,
          sectionDefinition,
          addMethod,
          ownProps,
        );
      }
    } catch (e) {
      ErrorReporter.captureException(e, {
        tags: { verticalSectionsAppInstallation: true },
      });
    }

    const { canAddApps, appDefId, widgetId } =
      await editorAPI.platform.canAddAppsInStructure(appIds, sectionStructure);
    if (!canAddApps) {
      const applicationId =
        editorAPI.platform.getAppDataByAppDefId(appDefId).applicationId;
      editorAPI.platform.notifyApplication(
        applicationId,
        platformEvents.factory.solveAddWidgetLimitation({
          origin: addSectionOrigin,
          widgetId,
        }),
      );
      return;
    }
    await installAppsByOrder(
      editorAPI,
      appIds,
      getAddSectionPlatformOrigin(),
      mapApplicationToFedopsEvent,
    );
    const appDefinitionToApplicationIdMap = getAppDefinitionToApplicationIdMap(
      editorAPI,
      appIds,
    );
    fixSerializedApplicationIds(
      sectionStructure,
      appDefinitionToApplicationIdMap,
    );

    if (
      isAppInstallationRequired &&
      shouldShowVerticalAppInstallNotification(appIds)
    ) {
      showVerticalSectionInstallNotification(editorAPI, notificationText);
    }
  }

  const emptyStateSectionRef = ownProps.emptyStateSectionReplacement
    ? editorAPI.sections.getEmptyStateSection()
    : null;
  let componentsToShift: CompRef[] = [];

  const pageRef = editorAPI.pages.getFocusedPage();

  sectionStructure.layout.y = util.sections.getTopPositionByStageEntryIndex(
    editorAPI,
    stageEntryIndex,
    pageRef,
  );

  const pageSections =
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef);
  const nextSectionRef = pageSections[stageEntryIndex];

  if (nextSectionRef && !emptyStateSectionRef) {
    const nextSectionLayout =
      editorAPI.components.layout.get_position(nextSectionRef);

    componentsToShift = getComponentsBelowPosition(
      editorAPI,
      nextSectionLayout.y,
    );
  }

  translateSectionNameIfNeeded(sectionStructure);

  let addFunction: Function;
  if (appIds.some((appDefId: string) => TPA_CLONE_DATA_APPS.has(appDefId))) {
    addFunction = editorAPI.dsActions.tpa.widget.addAndCloneCompData;
  }

  const sectionRef = (await new Promise((resolve) => {
    const contentSource =
      contentInjectionAPI.isContentInjectionAvailableForSections
        ? 'caas'
        : 'template';
    editorAPI.components.add(
      attachCandidate,
      sectionStructure,
      null,
      resolve,
      addSectionOrigin,
      isMeshLayoutEnabled() ? { optionalIndex: stageEntryIndex } : null,
      addFunction,
      contentSource,
    );
  })) as CompRef;

  await editorAPI.waitForChangesAppliedAsync();

  if (emptyStateSectionRef) {
    await editorAPI.waitForChangesAppliedAsync();
    await editorAPI.components.remove(
      emptyStateSectionRef,
      undefined,
      'replace_empty_section',
    );
  }

  const { height: sectionHeight } =
    editorAPI.components.layout.get_size(sectionRef);

  shiftComponents(editorAPI, componentsToShift, sectionHeight);

  await editorAPI.waitForChangesAppliedAsync();

  const exitZoomPromise = editorAPI.zoomMode.exitZoomMode({
    biParams: {
      origin: ownProps.panelName,
      zoom_mode_type: util.zoomMode.isNewZoomLabelEnabled() ? '75%' : '50%',
    },
  });

  editorAPI.panelManager.closePanelByName(ownProps.panelName);

  await exitZoomPromise; // The trick here is to wait after closing the panel, which also calls exitZoomMode.

  const sectionOrWidgetRef = getSectionOrWidgetRef(sectionRef, editorAPI);
  editorAPI.selection.selectComponentByCompRef(sectionOrWidgetRef);

  const aiSectionFeedbackEnabled = experiment.isOpen('se_aiSectionFeedback');
  const userAlreadySawFeedbackModal = () =>
    !experiment.isOpen('se_forceAiSectionFeedback') &&
    userPreferences.selectors.getGlobalUserPreferences(
      AI_SECTION_FEEDBACK_ALREADY_SHOWN,
    )(editorAPI.store.getState());
  if (
    categoryDefinition.id === CREATE_WITH_AI_CATEGORY_ID &&
    aiSectionFeedbackEnabled &&
    !userAlreadySawFeedbackModal()
  ) {
    setupAiSectionCreatorFeedbackLoop(editorAPI, sectionRef);
  }

  ownProps.onSectionAddedToStage?.(sectionRef);
  removeTransparentBoxesIfNeeded(editorAPI, sectionRef);
  await editorAPI.waitForChangesAppliedAsync();
  historyAPI.add('Add section from sections panel');

  addPanelUtils.scrollToAddedComponentIfNeeded(
    editorAPI,
    sectionRef,
    SCROLL_DURATION_TO_SECTION_SEC,
  );
  await processSavedSectionStructure.afterAdd();

  addSectionEndReport(
    editorAPI,
    categoryDefinition,
    sectionDefinition,
    sectionRef,
    sectionStructure,
    addMethod,
    addOrigin,
    itemIndex,
  );

  return sectionRef;
}

function addSectionStartReport(
  editorAPI: EditorAPI,
  siteGlobalDataAPI: SiteGlobalDataApi,
  contentInjectionAPI: ContentInjectionApi,
  categoryDefinition: SectionCategoryDefinition,
  sectionDefinition: SectionPresetDefinition,
  addMethod: AddSectionAddMethod,
  ownProps: Omit<AddSectionPanelOwnProps, keyof WithStartItemDrag>,
  isAppInstallationRequired: boolean,
  itemIndex?: number,
): void {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION,
  );
  const { industryId, structureId } = siteGlobalDataAPI.getBusinessType();
  const isSectionsContentInjected =
    contentInjectionAPI.getIsSectionsContentInjected();
  editorAPI.bi.event(
    coreBi.events.sections.add_section_panel.add_section_start,
    {
      adding_method: addMethod,
      origin: ownProps.origin || addSectionOrigin,
      presetId: sectionDefinition.title,
      category: categoryDefinition.title,
      app_list: sectionDefinition.appIds.join(','),
      component_type: constants.COMP_TYPES.SECTION,
      editor_working_mode: editorAPI.isDesktopEditor() ? 'desktop' : 'mobile',
      is_app_installation_required: isAppInstallationRequired,
      business_type: convertBusinessTypeToBiString(industryId, structureId),
      is_injected_content: isSectionsContentInjected,
      itemIndex,
    },
  );
}

function addSectionEndReport(
  editorAPI: EditorAPI,
  categoryDefinition: SectionCategoryDefinition,
  sectionDefinition: SectionPresetDefinition,
  sectionRef: CompRef,
  sectionStructure: CompStructure,
  addMethod: AddSectionAddMethod,
  addOrigin: string,
  itemIndex?: number,
): void {
  const pageId = editorAPI.pages.getFocusedPageId();
  const parentRef = editorAPI.components.getContainer(sectionRef);
  const parentType = editorAPI.components.getType(parentRef);
  util.fedopsLogger.interactionEnded(
    util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION,
  );
  const sectionChildren =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
      sectionRef,
      true,
    );
  const sectionChildrenStringMap = sectionChildren.reduce(
    (acc, childRef) =>
      `${acc.length ? `${acc}, ` : ''}${
        childRef.id
      }:${editorAPI.components.getType(childRef)}`,
    '',
  );

  const [commonComponentBiParams] = editorAPI.bi.getComponentsBIParams([
    sectionRef,
  ]);

  editorAPI.bi.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
    origin: addOrigin,
    page_id: pageId,
    id: sectionRef.id,
    category: categoryDefinition.title,
    preset_id: sectionDefinition.title,
    adding_method: addMethod,
    component_id: sectionRef.id,
    target_component: parentType,
    target_component_id: parentRef?.id,
    app_id: sectionDefinition.appIds.join(','),
    component_type: sectionStructure.componentType,
    json_components_presets: `{ ${sectionChildrenStringMap} }`,
    app_list: sectionDefinition.appIds.join(','),
    non_page_top_parent_component_id:
      commonComponentBiParams.non_page_top_parent_component_id,
    non_page_top_parent_component_type:
      commonComponentBiParams.non_page_top_parent_component_type,
    itemIndex,
  });
}

function addSectionStartAfterAppInstallReport(
  editorAPI: EditorAPI,
  categoryDefinition: SectionCategoryDefinition,
  sectionDefinition: SectionPresetDefinition,
  addMethod: AddSectionAddMethod,
  ownProps: Omit<AddSectionPanelOwnProps, keyof WithStartItemDrag>,
): void {
  editorAPI.bi.event(
    coreBi.events.sections.add_section_panel
      .add_section_start_after_app_install,
    {
      category: categoryDefinition.title,
      presetId: sectionDefinition.title,
      component_type: constants.COMP_TYPES.SECTION,
      adding_method: addMethod,
      origin: ownProps.origin || addSectionOrigin,
      app_list: sectionDefinition.appIds.join(','),
    },
  );
}

function fixPresetStructureApplicationIds(
  { editorAPI }: AddPresetScope,
  presetStructure: CompStructure,
  appDefIds: string[],
): void {
  const appDefinitionToApplicationIdMap = getAppDefinitionToApplicationIdMap(
    editorAPI,
    appDefIds,
  );
  fixSerializedApplicationIds(presetStructure, appDefinitionToApplicationIdMap);
}

export async function loadSavedSections({
  editorAPI,
  savedComponentsAPI,
  store,
}: AddPresetScope) {
  try {
    const metaSiteInstance =
      editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      ).instance;
    const savedSections = await savedComponentsAPI.httpService.fetchCollection(
      metaSiteInstance,
      constants.COMP_TYPES.SECTION,
    );
    const savedSectionsPresets = savedSections.map(
      ({ id, name, sourceUrl, previewDimension, previewUrl }) => {
        return {
          _id: id,
          title: name,
          presetJsonUrl: sourceUrl,
          previewHeight: previewDimension.height,
          previewHtmlUrl: previewUrl,
          hasBorder: false,
          sectionTitleKey: name,
          isVideo: false,
          url: '',
          originalPresetWidth: previewDimension.width,
          previewResponsePath: 'body',
          appIds: [],
        };
      },
    ) as SectionPresetDefinition[]; // TODO remove when data structure will be agreed

    store.setSavedSections(savedSectionsPresets);

    return savedSectionsPresets;
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: { savedSectionLoad: true },
    });
  }
}

export async function renameSavedSection(
  scope: AddPresetScope,
  id: string,
  name: string,
) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.SAVED_SECTIONS_RENAME,
  );

  const metaSiteInstance =
    scope.editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance;

  return scope.editorAPI.savedComponents.httpService
    .renameItem(metaSiteInstance, id, name)
    .then(() => {
      scope.store.renameSavedSection(id, name);

      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.SAVED_SECTIONS_RENAME,
      );
    });
}

export const addBlankSection = async (
  scope: AddPresetScope,
  origin: string,
  addMethod: AddSectionAddMethod,
  sectionName?: string,
  shouldReplaceEmptySection: boolean = false,
): Promise<CompRef> => {
  const { hooks, editorAPI, sectionsAPI } = scope;
  const pageRef = editorAPI.pages.getFocusedPage();
  const sectionsWithLayout =
    sectionsAPI.getPageSectionsWithLayoutSortedByStageOrder(pageRef);
  const blankSectionPresetDefinition = getBlankSectionPresetDefinition();
  const blankSectionCategoryDefinition = getBlankSectionCategoryDefinition();
  let stageEntryIndex = util.sections.getStageEntryIndex(editorAPI, pageRef);
  let yPositionToInsert =
    stageEntryIndex > 0
      ? sectionsWithLayout[stageEntryIndex - 1].layout.y +
        sectionsWithLayout[stageEntryIndex - 1].layout.height
      : 0;
  if (!yPositionToInsert) {
    stageEntryIndex = 0;
    yPositionToInsert = 0;
  }
  const structure = sectionsAPI.getBlankSectionStructure({
    height: 500,
    y: yPositionToInsert,
    name: sectionName,
  });
  let compRef: CompRef = null;
  try {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_BLANK,
    );

    hooks.sections.addPresetStart.fire(false);

    compRef = await addSectionPreset(
      scope,
      pageRef,
      structure,
      stageEntryIndex,
      blankSectionCategoryDefinition,
      blankSectionPresetDefinition,
      {
        origin,
        emptyStateSectionReplacement: shouldReplaceEmptySection,
      },
      addMethod,
      origin,
    );
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.ADD_SECTION_PANEL_ADD_SECTION_BLANK,
    );
  } catch (e) {
    const flow = 'addBlankSection';
    const presetId = blankSectionPresetDefinition.title;
    const category = blankSectionCategoryDefinition.title;
    const app_list = blankSectionPresetDefinition.appIds.join(',');
    ErrorReporter.captureException(e, {
      tags: { addSectionFlow: true, addBlankSection: true },
      extra: {
        flow,
        structure,
        presetId,
        category,
        appsToInstall: app_list,
      },
    });
  } finally {
    hooks.sections.addPresetEnd.fire();
  }
  return compRef;
};
export function getBlankSectionCategoryDefinition(): SectionCategoryDefinition {
  return {
    id: '',
    isDivider: false,
    longTitle: '',
    name: '',
    order: 0,
    tooltipDescription: '',
    tooltipTitle: '',
    title: 'blank',
  };
}
export function getBlankSectionPresetDefinition(): SectionPresetDefinition {
  return {
    appIds: [],
    hasBorder: false,
    language: '',
    presetCompId: '',
    msid: '',
    pageId: '',
    presetJsonUrl: '',
    previewHeight: 0,
    previewHtmlUrl: '',
    sectionTitleKey: '',
    themeMap: undefined,
    title: 'blank',
    url: '',
    isVideo: false,
    shouldUsePreviewer: false,
  };
}

export async function removeSavedSection(scope: AddPresetScope, id: string) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.SAVED_SECTIONS_DELETE,
  );

  const metaSiteInstance =
    scope.editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance;

  return scope.editorAPI.savedComponents.httpService
    .deleteItem(metaSiteInstance, id)
    .then(() => {
      scope.store.removeSavedSection(id);

      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.SAVED_SECTIONS_DELETE,
      );
    });
}

function openSavedSectionsCategory(
  { editorAPI }: AddPresetScope,
  props: PanelProps,
) {
  editorAPI.panelManager.openPanel(
    constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
    { ...props, category: SavedSectionCategory.title },
  );
}

function getSavedSections({ store }: AddPresetScope) {
  return store.getSavedSections();
}
function getSavedSectionDefaultName(
  scope: AddPresetScope,
  originalSectionName: string,
) {
  const savedSections = getSavedSections(scope);
  return getNextSectionDefaultNameUtil(savedSections, originalSectionName);
}

function getLayoutFamilies(scope: AddPresetScope) {
  return scope.store.getLayoutFamilies();
}

function getKitDefinitions(scope: AddPresetScope): KitDefinition[] {
  return scope.store.getKitDefinitions();
}

function getAnimationKits(scope: AddPresetScope): AnimationKitDefinition[] {
  return scope.store.getAnimationKits();
}

export class AddPresetApi extends BasePublicApi<AddPresetScope> {
  loadLayoutFamilies = this.bindScope(loadLayoutFamilies);
  getLayoutFamilies = this.bindScope(getLayoutFamilies);
  getKitDefinitions = this.bindScope(getKitDefinitions);
  getAnimationKits = this.bindScope(getAnimationKits);

  pages = {
    loadPages: this.bindScope(loadPages),
    setCategoryByUniquePagesUri: this.bindScope(setCategoryByUniquePagesUri),
  };

  hooks = {
    layoutFamiliesReady: this.scope.hooks.layoutFamiliesReady,
    kitDefinitionsReady: this.scope.hooks.kitDefinitionsReady,
  };

  sections = {
    hooks: this.scope.hooks.sections,
    addBlankSection: this.bindScope(addBlankSection),
    addSectionPreset: this.bindScope(addSectionPreset),
    getBlankSectionCategoryDefinition,
    getBlankSectionPresetDefinition,
    loadSavedSections: this.bindScope(loadSavedSections),
    removeSavedSection: this.bindScope(removeSavedSection),
    openSavedSectionsCategory: this.bindScope(openSavedSectionsCategory),
    getSavedSections: this.bindScope(getSavedSections),
    getSavedSectionDefaultName: this.bindScope(getSavedSectionDefaultName),
    loadSections: this.bindScope(loadSections),
  };

  fixPresetStructureApplicationIds = this.bindScope(
    fixPresetStructureApplicationIds,
  );
}

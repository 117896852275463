import constants from '#packages/constants';
import * as util from '#packages/util';
import type { PromoteScope } from '../scope';
import { EM_FETCH_STATUS } from './constants';

const fetchUserPackage = (instance: string) => {
  const endpoint = `${util.serviceTopology.editorRootUrl}_serverless/wix-emails-serverless/user-package`;
  return util.http
    .fetch(endpoint, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: instance,
      },
    })
    .then((res: Response) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });
};

export const fetchPromoteData = async (scope: PromoteScope) => {
  try {
    const instance = scope.editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance;
    if (!instance) {
      throw new Error('failed to get instance');
    }
    scope.store.setFetchStatus(EM_FETCH_STATUS.LOADING);
    const userPackage = await fetchUserPackage(instance);
    scope.store.setEmailMarketingData(userPackage);
    scope.store.setFetchStatus(EM_FETCH_STATUS.SUCCESS);
  } catch (e) {
    scope.store.setFetchStatus(EM_FETCH_STATUS.ERROR);
  }
};

import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import type { EditorAPI } from '#packages/editorAPI';
import experiment from 'experiment';
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import * as platformAddPanel from './addPanel/platformAddPanel';
import { createMyWidgetsSectionsAddPanelV2 } from './addPanel/sectionsGenerator';
import platformEditorAPI from './api/api';
import * as appController from './appController/appController';
import constants from './common/constants';
import * as utils from './common/utils';
import * as panelHelpers from './api/editor/panelHelpers/panelHelpers';
import * as pageReplaceService from './services/pageReplaceService';
import * as connection from './connection/connection';
import appBuilderUpdateTypePanel from './panels/appBuilderUpdateTypePanel';
import appControllerDeleteConfirmationPanel from './panels/appControllerDeleteConfirmationPanel';
import warnForDisconnectedComponentsPanel from './panels/warnForDisconnectedComponentsPanel';
import platformNotification from './platformNotification/platformNotification';
import * as componentsRemoveHandlerPlugins from './plugins/componentsRemoveHandlerPlugins';
import * as notifyApplicationCopyPaste from './plugins/notifyApplicationCopyPaste';
import platformCompNamePlugin from './plugins/platformCompNamePlugin';
import * as platformConnectedGfpp from './services/platformConnectedGfpp';
import * as platformPostMessageService from './services/platformPostMessageService';
import * as platformWorkerService from './services/platformWorkerService';
import * as sectionsServices from './services/sectionsService';
import * as tokenService from './services/tokenService';
import * as upgradeUtils from './utils/upgradeUtils';
import * as widgetDesignUtils from './utils/widgetDesignUtils';
import * as widgetPresetsUtils from './utils/widgetPresetsUtils';
import * as gfppTargetUtils from './utils/gfppTargetUtils';
import * as roleUtils from './utils/roleUtils';
import * as renderUtils from './utils/renderUtils';
import { platformSilentInstallApps as silentInstallApps } from './services/silentInstallService/platformSilentInstallService';
import type { SilentInstallAppsOptions } from './services/silentInstallService/silentInstallConstants';
import type { AppData } from '@wix/platform-editor-sdk';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import getGfppNotifyUtils from './utils/gfppNotifyUtils';
import { createAllyHostAPI } from './api/v2';
import * as platformBI from './bi/bi';
import platformBiEvents from './bi/events';
import type { WidgetSlot } from '@wix/editor-platform-host-integration/ui';
import { EditorType } from '@wix/platform-editor-sdk';
import type { CompRef } from '@wix/document-services-types';
import { getAppDataAndCompByCompRef } from './appController/appViewState';
const PLATFORM_INIT_START = 'platform init start';
const PLATFORM_INIT_END = 'platform init end';
const PLATFORM_INIT_MEASUREMENT = 'platform init measurement';
const APP_MANIFESTS_LOADED = 'platform app manifests loaded';
const {
  notifyApplicationAfterComponentPasted,
  notifyApplicationAfterComponentDuplicated,
} = notifyApplicationCopyPaste;

function init(
  editorAPI: EditorAPI,
  registerGfppActions: AnyFixMe,
  registerGfppData: AnyFixMe,
) {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  let boundApiMethods = bindApiMethods(platformEditorAPI, editorAPI);

  appController.init(editorAPI, registerGfppData);
  connection.init(registerGfppActions);
  const extraNamespaces = utils.getExtraNamespaces();
  const options = {
    origin: {
      type: EditorType.Classic,
    },
  };
  if (experiment.isOpen('se_overridePlatfromAPIv1')) {
    boundApiMethods = platformAPI.sdk.overrideImplementation(
      'classic',
      // in case of types missmatch run "yarn dedupe @wix/document-services-types"
      editorAPI.documentServices,
      {
        accessibility: createAllyHostAPI(editorAPI.documentServices, editorAPI),
      },
      boundApiMethods,
    );
  }

  // init v1 platform
  platformAPI.init(boundApiMethods, options, extraNamespaces);

  platformAPI.initV2({
    hostType: 'classic',
    hostAPI: {
      accessibility: createAllyHostAPI(editorAPI.documentServices, editorAPI),
    },
    // in case of types missmatch run "yarn dedupe @wix/document-services-types"
    documentServices: editorAPI.documentServices,
  });

  return registerCallbacksAndPlugins(editorAPI);
}

function onAddPanelLoaded() {
  platformAddPanel.register();
}

function onAddPanelUnloaded() {
  platformAddPanel.unregister();
}

const displaySectionDeleteMessage = async (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const compData = editorAPI.components.data.get(compRef);
  const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(
    compData.applicationId,
  );

  return utils.displaySectionDeleteMessage(editorAPI, compRef, appData);
};

function registerCallbacksAndPlugins(editorAPI: AnyFixMe) {
  const doesHaveAppsToMeasureLoadTime = () =>
    stateManagement.platform.selectors.doesHaveAppsToMeasureLoadTime(
      editorAPI.store.getState(),
    );
  const haveAllAppManifestsBeenLoaded = () =>
    stateManagement.platform.selectors.haveAllAppManifestsBeenLoaded(
      editorAPI.store.getState(),
    );
  const hasAllAppManifestsLoadedReported = () =>
    stateManagement.platform.selectors.hasAllAppManifestsLoadedReported(
      editorAPI.store.getState(),
    );
  const hasEditorScriptsLoadedReported = () =>
    stateManagement.platform.selectors.hasEditorScriptsLoadedReported(
      editorAPI.store.getState(),
    );
  const hasStartLoadingPlatformBeenReported = () =>
    stateManagement.platform.selectors.hasStartLoadingPlatformBeenReported(
      editorAPI.store.getState(),
    );
  const hasStartLoadingEditorScriptsBeenReported = () =>
    stateManagement.platform.selectors.hasStartLoadingEditorScriptsBeenReported(
      editorAPI.store.getState(),
    );

  const getInstalledEditorAppsAsObjectStringified = () =>
    JSON.stringify(
      editorAPI.dsRead.platform.getInstalledEditorApps().reduce(
        (acc: AnyFixMe, current: AnyFixMe) => ({
          ...acc,
          [current.appDefinitionId]: true,
        }),
        {} as AnyFixMe,
      ),
    );
  const reportStartLoadingEditorScripts = () => {
    if (!hasStartLoadingEditorScriptsBeenReported()) {
      editorAPI.bi.event(coreBi.events.platform.INIT_START, {
        app_name: getInstalledEditorAppsAsObjectStringified(),
        uuid: editorAPI.dsRead.generalInfo.getUserInfo()?.userId,
        phaseName: 'platform',
        subPhase: 'editor-scripts',
        isTemplate: editorAPI.dsRead.generalInfo.isFirstSave(),
      });
      editorAPI.store.dispatch(
        stateManagement.platform.actions.setStartLoadingEditorScriptsReported(),
      );
    }
  };
  const reportStartLoadingPlatform = () => {
    const apps = editorAPI.dsRead.platform.getInstalledEditorApps();
    editorAPI.store.dispatch(
      stateManagement.platform.actions.setAppsToMeasureLoadingTime(apps),
    );

    if (
      doesHaveAppsToMeasureLoadTime() &&
      !hasStartLoadingPlatformBeenReported()
    ) {
      util.performance.mark(PLATFORM_INIT_START);
      editorAPI.bi.event(coreBi.events.platform.INIT_START, {
        app_name: getInstalledEditorAppsAsObjectStringified(),
        uuid: editorAPI.dsRead.generalInfo.getUserInfo()?.userId,
        phaseName: 'platform',
        subPhase: 'all',
        isTemplate: editorAPI.dsRead.generalInfo.isFirstSave(),
      });
      editorAPI.store.dispatch(
        stateManagement.platform.actions.setStartLoadingPlatformReported(),
      );

      reportStartLoadingEditorScripts();
    }
  };
  const reportEditorScriptsLoaded = () => {
    if (doesHaveAppsToMeasureLoadTime() && !hasEditorScriptsLoadedReported()) {
      util.performance.mark(PLATFORM_INIT_END);
      util.performance.measure(
        PLATFORM_INIT_MEASUREMENT,
        PLATFORM_INIT_START,
        PLATFORM_INIT_END,
      );
      editorAPI.bi.event(coreBi.events.platform.INIT_END, {
        app_name: getInstalledEditorAppsAsObjectStringified(),
        uuid: editorAPI.dsRead.generalInfo.getUserInfo()?.userId,
        phaseName: 'platform',
        subPhase: 'editor-scripts',
        is_template: editorAPI.dsRead.generalInfo.isFirstSave(),
        intervalLoadTime: Math.round(
          util.performance.getMeasure(PLATFORM_INIT_MEASUREMENT).duration,
        ),
      });

      editorAPI.store.dispatch(
        stateManagement.platform.actions.setEditorScriptsLoadedReported(),
      );
    }
  };
  const rerenderEditorOnManifestLoaded = () =>
    editorAPI.store.dispatch(
      stateManagement.services.actions.onViewerChanged(),
    );
  const rerenderSelectedComponentsOnManifestLoaded = () =>
    editorAPI.store.dispatch(
      stateManagement.components.actions.onAppManifestLoaded(),
    );

  const measureAndReportTimeToManifest = (appDefId: AnyFixMe) => {
    const setManifestMarkName = `manifest set for app ${appDefId}`;
    util.performance.mark(setManifestMarkName);

    const timeToManifestMeasureName = `time for manifest app ${appDefId}`;
    util.performance.measure(
      timeToManifestMeasureName,
      'editor ready',
      setManifestMarkName,
    );

    editorAPI.bi.event(coreBi.events.editor.APP_MANIFEST_LOADED, {
      app_id: appDefId,
    });
  };

  const reportAllAppManifestsLoaded = (app: AnyFixMe) => {
    if (!doesHaveAppsToMeasureLoadTime()) {
      return;
    }

    editorAPI.store.dispatch(
      stateManagement.platform.actions.setAppManifestHasBeenLoaded(app),
    );

    if (
      !hasAllAppManifestsLoadedReported() &&
      haveAllAppManifestsBeenLoaded()
    ) {
      editorAPI.store.dispatch(
        stateManagement.platform.actions.setAllAppManifestsLoadedReported(),
      );

      util.performance.mark(APP_MANIFESTS_LOADED);
      util.performance.measure(
        APP_MANIFESTS_LOADED,
        PLATFORM_INIT_START,
        APP_MANIFESTS_LOADED,
      );

      editorAPI.bi.event(coreBi.events.platform.INIT_END, {
        app_name: getInstalledEditorAppsAsObjectStringified(),
        uuid: editorAPI.dsRead.generalInfo.getUserInfo()?.userId,
        phaseName: 'platform',
        subPhase: 'all',
        is_template: editorAPI.dsRead.generalInfo.isFirstSave(),
        intervalLoadTime: Math.round(
          util.performance.getMeasure(APP_MANIFESTS_LOADED).duration,
        ),
      });
    }
  };

  reportStartLoadingPlatform();

  editorAPI.dsActions.platform.registerToManifestAdded(
    rerenderEditorOnManifestLoaded,
  );
  editorAPI.dsActions.platform.registerToManifestAdded(
    rerenderSelectedComponentsOnManifestLoaded,
  );
  editorAPI.dsActions.platform.registerToManifestAdded(
    (manifest: AnyFixMe, appData: AnyFixMe) =>
      measureAndReportTimeToManifest(appData.appDefinitionId),
  );
  editorAPI.registerSitePublishedCallbacks(_.partial(onPublished, editorAPI));

  editorAPI.dsActions.platform.registerToManifestAdded(
    (manifest: AnyFixMe, appData: AnyFixMe) => {
      reportAllAppManifestsLoaded(appData);
    },
  );
  editorAPI.dsActions.platform.registerToAppsCompleted(() => {
    reportEditorScriptsLoaded();
  });

  editorAPI.registerAfterPasteComponentCallback(
    notifyApplicationAfterComponentPasted,
  );
  editorAPI.registerAfterDuplicateComponentCallback(
    notifyApplicationAfterComponentDuplicated,
  );

  editorAPI.registerComponentsRemoveHandlerPlugin(
    componentsRemoveHandlerPlugins.handleWidgetPluginRemoving,
  );

  editorAPI.registerComponentsRemoveHandlerPlugin(
    componentsRemoveHandlerPlugins.confirmOnOrphanConnections,
  );
  editorAPI.registerComponentsRemoveHandlerPlugin(
    componentsRemoveHandlerPlugins.resetClipboardIfCopiedComponentDescendsFromDeletedWidget,
  );
  editorAPI.registerComponentsRemoveHandlerPlugin(
    componentsRemoveHandlerPlugins.openAddElementsPanelIfNeeded,
  );
  editorAPI.registerComponentsRemoveHandlerPlugin(
    componentsRemoveHandlerPlugins.displayDeleteNotificationIfNeeded,
  );

  editorAPI.registerCannotRemovePlugin(
    constants.APP_WIDGET,
    displaySectionDeleteMessage.bind(null, editorAPI),
  );

  editorAPI.registerCompNamePlugin(
    constants.APP_WIDGET,
    platformCompNamePlugin.create(editorAPI),
  );
  editorAPI.registerCompNamePlugin(
    'platform.components.AppController',
    platformCompNamePlugin.create(editorAPI, {
      considerControllerTypeDisplayName: true,
    }),
  );
  document.addEventListener(
    'consentPolicyChanged',
    _.partial(onConsentPolicyChanged, editorAPI),
  );

  return new Promise<void>((resolve) => {
    if (experiment.isOpen('se_platform_modal_native_panel')) {
      platformWorkerService
        .createWorkerMessagesAPI()
        .then((workerMessagesAPI) => {
          editorAPI.store.dispatch(
            stateManagement.rendererPanelsWorkerService.actions.setWorkerMessagesAPI(
              workerMessagesAPI,
            ),
          );
          resolve();
        });
    } else {
      resolve();
    }
  });
}

function onPublished(editorAPI: AnyFixMe) {
  const installedEditorApps = editorAPI.platform.getInstalledEditorApps();
  installedEditorApps.forEach(function (installedApp: AnyFixMe) {
    editorAPI.dsActions.platform.notifyApplication(
      installedApp.applicationId,
      platformEvents.factory.siteWasPublished(),
    );
  });
}

function onConsentPolicyChanged(editorAPI: AnyFixMe, eventPayload: AnyFixMe) {
  const installedEditorApps = editorAPI.platform.getInstalledEditorApps();
  installedEditorApps.forEach(function (installedApp: AnyFixMe) {
    editorAPI.dsActions.platform.notifyApplication(
      installedApp.applicationId,
      platformEvents.factory.consentPolicyChanged({
        currentPolicy: eventPayload.detail,
      }),
    );
  });
}

function bindApiMethods(
  dataStructure: AnyFixMe,
  boundArgs: AnyFixMe,
): AnyFixMe {
  if (_.isFunction(dataStructure)) {
    return _.partial(dataStructure, boundArgs);
  }
  if (_.isObject(dataStructure)) {
    return _.mapValues(dataStructure, function (childData) {
      return bindApiMethods(childData, boundArgs);
    });
  }

  return dataStructure;
}

const panels = {
  appControllerDeleteConfirmationPanel,
  warnForDisconnectedComponentsPanel,
  appBuilderUpdateTypePanel,
};

const sectionsService = {
  isAddingUnifiedWidget: sectionsServices.getIsAddingUnifiedWidget,
};

export type { PlatformOrigin } from './bi/bi';

export type SDKFunction<TOptions, TReturn> = (
  editorAPI: EditorAPI,
  appData: AppData,
  token: string,
  options: TOptions,
) => TReturn;

export {
  platformEditorAPI as api,
  init,
  platformBI,
  platformPostMessageService,
  platformConnectedGfpp,
  tokenService,
  constants,
  utils,
  upgradeUtils,
  appController,
  connection,
  panels,
  panelHelpers,
  platformNotification,
  onAddPanelLoaded,
  onAddPanelUnloaded,
  widgetDesignUtils,
  widgetPresetsUtils,
  gfppTargetUtils,
  roleUtils,
  renderUtils,
  createMyWidgetsSectionsAddPanelV2,
  silentInstallApps,
  type SilentInstallAppsOptions,
  getGfppNotifyUtils,
  type WidgetSlot,
  pageReplaceService,
  platformBiEvents,
  getAppDataAndCompByCompRef,
  registerCallbacksAndPlugins,
  sectionsService,
};

export { PlatformEntrypoint } from './platformEntry';
export { AddonsEntrypoint } from './addons';

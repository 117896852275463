import React from 'react';
import { Tooltip, RichText } from '@wix/wix-base-ui';

import { translate } from '#packages/i18n';
import { cx } from '#packages/util';

import { TRANSLATIONS } from '../../../../translations';

import styles from './Action.scss';

import type { Action as ActionType } from '../Actions.types';
import type { ActionIds } from '../Actions.constants';
import type { UISkin } from '../../../skins/skins.types';

export interface ActionProps extends ActionType {
  collapsible?: boolean;
  isMobile: boolean;
  className?: string;
  onLearnMoreClick: (helpId: string) => void;
  skin: UISkin;

  onClick: (actionId: ActionIds, e: React.MouseEvent) => void;
}

export const Action: React.FC<ActionProps> = ({
  id,
  icon: Icon,
  collapsible,
  tooltipText,
  tooltipHelpId,
  tooltipCustomLink,
  isMobile,
  className,
  disabled,
  dataHook,
  onClick,
  onLearnMoreClick,
  skin,
}) => {
  return (
    <div
      className={cx(styles.container, className, {
        [styles.disabled]: disabled,
        [styles.legacy]: skin === 'legacy',
        [styles.primary]: skin === 'primary',
        [styles.secondary]: skin === 'secondary',
        [styles.legacySoap]: skin === 'legacy-soap',
        [styles.primarySoap]: skin === 'primary-soap',
        [styles.secondarySoap]: skin === 'secondary-soap',
      })}
      data-collapsible={collapsible}
      data-hook={dataHook}
      onClick={(e) => !disabled && onClick(id, e)}
    >
      <Tooltip
        interactive
        openDelay={150}
        alignment={isMobile ? 'RIGHT' : 'LEFT'}
        content={
          <RichText>
            {tooltipText}
            {tooltipHelpId && (
              <>
                {' '}
                <a onClick={() => onLearnMoreClick(tooltipHelpId)}>
                  {translate(TRANSLATIONS.ACTIONS_BAR.TOOLTIP_LEARN_MORE)}
                </a>
              </>
            )}
            {tooltipCustomLink && (
              <div className={styles.tooltipCustomLink}>
                <a onClick={() => tooltipCustomLink.action()}>
                  {translate(tooltipCustomLink.text)}
                </a>
              </div>
            )}
          </RichText>
        }
        marginLeft={8}
        marginRight={8}
        shouldTranslate={false}
        closeOnMouseClick={!disabled}
      >
        <Icon className={styles.icon} />
      </Tooltip>
    </div>
  );
};

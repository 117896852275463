// @ts-nocheck
import createReactClass from 'create-react-class';
import experiment from 'experiment';
import _ from 'lodash';
import React from 'react';
import { Composites, Divider, ToggleSwitch } from '@wix/wix-base-ui';

import * as compPanelInfra from '#packages/compPanelInfra';
import * as helpIds from '#packages/helpIds';
import * as coreBi from '#packages/coreBi';
import * as CompPanelInfra from '#packages/compPanelInfra';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'overlaySettingsPanel',
  mixins: [compPanelInfra.compPanelMixin],
  getInitialState() {
    const popupProps = this.getPopupProps() || {};

    return {
      closeOnOverlayClick: this.linkPopupPropsValue(
        popupProps,
        'closeOnOverlayClick',
      ).value,
    };
  },
  render() {
    const popupProps = this.getPopupProps() || {};

    return (
      <CompPanelInfra.compPanelFrame
        title="Popups_OverlaySettings_Panel_Header"
        contentClass="settings-panel"
        helpId={this.getHelpId()}
        {...this.getFrameProps()}
        className={cx(
          this.getFrameProps()?.className,
          'popup-overlay-settings-panel',
        )}
      >
        <Divider long={false} />

        <Composites.ToggleSwitch key="clickClosesOverlay2">
          <ToggleSwitch
            value={this.state.closeOnOverlayClick}
            label={
              experiment.isOpen('spec.classic-editor.Lightbox-to-Popup')
                ? 'POPUPS_NEW_OVERLAYSETTINGS_CLICKCLOSES_TOGGLE'
                : 'Popups_OverlaySettings_ClickCloses_Toggle'
            }
            onChange={
              this.linkPopupPropsValue(popupProps, 'closeOnOverlayClick')
                .requestChange
            }
          />
        </Composites.ToggleSwitch>
      </CompPanelInfra.compPanelFrame>
    );
  },

  componentDidMount() {
    this.getEditorAPI().bi.event(
      coreBi.events.popups.setup_overlay_clicked,
      {},
    );
  },

  getHelpId() {
    return helpIds.UTILS.getSettingsHelpId(
      helpIds.COMP_TYPES.POPUP_OVERLAY,
      this.getEditorAPI().isMobileEditor(),
    );
  },

  getPage() {
    return this.getEditorAPI().pages.popupPages.getCurrentPopup();
  },

  getPopupProps() {
    return (
      this.getEditorAPI().pages.properties.get(this.getPage())?.popup ?? {}
    );
  },

  openHelpPanelFrame() {
    this.openHelpCenter(this.getHelpId(), true);
  },

  linkPopupPropsValue(popupData, valueName) {
    return {
      value: popupData?.[valueName],
      requestChange: function (newValue) {
        const editorAPI = this.getEditorAPI();

        const pageProps = editorAPI.pages.properties.get(this.getPage());
        _.set(pageProps, ['popup', valueName], newValue);

        editorAPI.pages.properties.update(this.getPage(), pageProps);
        editorAPI.history.add('toggle close popup by click on overlay');

        this.setState({ [valueName]: newValue });
      }.bind(this),
    };
  },
});

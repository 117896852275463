import experiment from 'experiment';
import { initSiteSegmentMigration } from './initDesignItem';
import { editorModel } from '#packages/util';

import type { Scope } from './scope';

export async function init(scope: Scope) {
  const { isInsideEditorX, isInsideAppStudio } = scope.editorParamsAPI;
  const isEnvSupportedForMigration = !(
    isInsideEditorX ||
    isInsideAppStudio ||
    editorModel.isImpersonated
  );
  const shouldMigrateSiteSegments =
    experiment.isOpen('se_siteSegmentMediaBackground') &&
    isEnvSupportedForMigration;

  if (!shouldMigrateSiteSegments) {
    scope.siteSegmentMediaBgMigrationHook.resolve();
    return;
  }

  scope.editorCoreApi.addToEditorInit(
    scope.siteSegmentMediaBgMigrationHook.promise,
  );
  await scope.editorCoreApi.hooks._dsIsReady.promise;
  await scope.editorCoreApi.hooks.sectionsMigrationReady.promise;

  await initSiteSegmentMigration(scope);
}

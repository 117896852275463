// @ts-nocheck
import React from 'react';
import * as baseUI from '#packages/baseUI';

export default class extends React.Component {
  static displayName = 'TextfieldCustomization';

  render() {
    return (
      <baseUI.textInput
        valueLink={this.props.valueLink}
        label={this.props.label}
        placeholder={this.props.placeholderText}
        infoText={this.props.infoText}
      />
    );
  }
}

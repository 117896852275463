import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { createDeveloperModeApi } from './developerModeWrapper';
import { DeveloperModeApiKey } from './publicApiKey';

export const DeveloperModeEntrypoint: EntryPoint = {
  name: 'DeveloperModeApi',
  declareAPIs: () => [DeveloperModeApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(DeveloperModeApiKey, () =>
      createDeveloperModeApi(shell),
    );
  },
};

import React from 'react';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  PromotionalList,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

import { translate } from '#packages/i18n';
import { frames } from '#packages/panels';

import { AUTOMATION_IDS } from './corvidDevModePanel.constants';

const { FocusPanelFrame, PromotionalPanelFrame } = frames;

const TRANSLATIONS = {
  TITLE: 'Editor_Search_Dev_Mode_Popup_Title',
  SUBTITLE: 'Editor_Search_Dev_Mode_Popup_Subtitle',
  LIST_ITEMS: [
    'Editor_Search_Dev_Mode_Popup_Bullet_1',
    'Editor_Search_Dev_Mode_Popup_Bullet_2',
    'Editor_Search_Dev_Mode_Popup_Bullet_3',
    'Editor_Search_Dev_Mode_Popup_Bullet_4',
  ],
  CTA: 'Editor_Search_Dev_Mode_Popup_Primary_CTA',
  LEARN_MORE: 'Editor_Search_Dev_Mode_Popup_Link',
};

interface CorvidDevModePanelFrameProps {
  panelName: string;
  onClose: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  keyboardShortcuts: any;
}

export const CorvidDevModePanelFrame: React.FC<CorvidDevModePanelFrameProps> = (
  props,
) => {
  return (
    <FocusPanelFrame
      dataHook="corvid-dev-mode-panel"
      shouldHideHeader={true}
      keyboardShortcuts={props.keyboardShortcuts}
      panelName={props.panelName}
      className="popup-medium corvid-dev-mode-panel"
    >
      <Composites.BigPanelHeaderWithIllustration>
        <PanelHeaderCenteredText
          title={TRANSLATIONS.TITLE}
          subtitle={TRANSLATIONS.SUBTITLE}
          onClose={props.onClose}
        />
        <PanelHeaderIllustration blueStripHeight={92}>
          <symbols.symbol
            name="welcomeVeloDevMode"
            style={{ marginTop: -10 }}
          />
        </PanelHeaderIllustration>
      </Composites.BigPanelHeaderWithIllustration>

      <Composites.PanelContent>
        <PromotionalList
          items={TRANSLATIONS.LIST_ITEMS}
          symbol={<symbols.symbol name="check" />}
        />
      </Composites.PanelContent>

      <Composites.ActionSetVertical>
        <Button
          onClick={props.onPrimaryButtonClick}
          automationId={AUTOMATION_IDS.CTA}
        >
          {translate(TRANSLATIONS.CTA)}
        </Button>
        <Button
          onClick={props.onSecondaryButtonClick}
          className="btn-text"
          automationId={AUTOMATION_IDS.LEARN_MORE}
        >
          {translate(TRANSLATIONS.LEARN_MORE)}
        </Button>
      </Composites.ActionSetVertical>
    </FocusPanelFrame>
  );
};

export const NewWorkspaceCorvidDevModePanelFrame: React.FC<
  CorvidDevModePanelFrameProps
> = (props) => {
  return (
    <PromotionalPanelFrame
      dataHook="corvid-dev-mode-panel"
      className="new-workspace-corvid-dev-mode-panel"
      panelName={props.panelName}
      title={translate(TRANSLATIONS.TITLE)}
      subtitle={translate(TRANSLATIONS.SUBTITLE)}
      illustration={<symbols.symbol name="welcomeVeloDevMode_NewWorkspace" />}
      primaryButtonText={translate(TRANSLATIONS.CTA)}
      secondaryButtonText={translate(TRANSLATIONS.LEARN_MORE)}
      onPrimaryButtonClick={props.onPrimaryButtonClick}
      onSecondaryButtonClick={props.onSecondaryButtonClick}
      onOuterClick={props.onClose}
      onCloseButtonClick={props.onClose}
      keyboardShortcuts={props.keyboardShortcuts}
    >
      <div className="corvid-dev-mode-panel-content">
        <PromotionalList items={TRANSLATIONS.LIST_ITEMS} />
      </div>
    </PromotionalPanelFrame>
  );
};

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as baseUI from '#packages/baseUI';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as bi from '#packages/coreBi';
import * as BaseUI from '#packages/baseUI';
import keyboardMap from '#packages/keyboardShortcuts';
import ToolSelector from './toolSelector';
import constants from '#packages/constants';
import { cx } from '#packages/util';
import type { SendBiFunction, BiEventFields } from 'types/bi';

//TYPE WAS GENERATED, remove this line when reviewed
interface ToolbarTopProps {
  restrictions?: AnyFixMe;
  components?: Array<AnyFixMe>;
  pasteEnabled?: boolean;
  compDeleteTooltipText?: string;
  compBIParams?: BiEventFields;
  isCompControlledByParent?: boolean;
  reportBI?: SendBiFunction;
  copyPasteAPI?: {
    copy?: FunctionFixMe;
    paste?: FunctionFixMe;
    pastePage?: FunctionFixMe;
    duplicate?: FunctionFixMe;
    hasComponentToPaste?: FunctionFixMe;
    hasPageToPaste?: FunctionFixMe;
  };
  selectedMenuItem?: AnyFixMe;
  canDistributeAllDirections?: boolean;
  distribute?: FunctionFixMe;
  canMatchAllSizes?: boolean;
  matchSize?: FunctionFixMe;
  addHistory?: FunctionFixMe;
  isMobileEditor?: boolean;
  canAlignAllDirections?: boolean;
  alignComp?: FunctionFixMe;
  removeComp?: FunctionFixMe;
  arrangementAPI?: {
    moveToFront?: FunctionFixMe;
    moveForward?: FunctionFixMe;
    moveToBack?: FunctionFixMe;
    moveBackward?: FunctionFixMe;
  };
  isCrossSitePaste?: FunctionFixMe;
}

class ToolbarTop extends React.Component<ToolbarTopProps> {
  static displayName = 'toolbarTop';

  static propTypes = {
    restrictions: PropTypes.object,
    components: PropTypes.arrayOf(PropTypes.object),
    pasteEnabled: PropTypes.bool,
    compDeleteTooltipText: PropTypes.func,
    compBIParams: PropTypes.array,
    isCompControlledByParent: PropTypes.bool,
    reportBI: PropTypes.func,
    copyPasteAPI: PropTypes.shape({
      copy: PropTypes.func,
      paste: PropTypes.func,
      pastePage: PropTypes.func,
      duplicate: PropTypes.func,
      hasComponentToPaste: PropTypes.func,
      hasPageToPaste: PropTypes.func,
    }),
    selectedMenuItem: PropTypes.object,
    canDistributeAllDirections: PropTypes.bool,
    distribute: PropTypes.func,
    canMatchAllSizes: PropTypes.bool,
    matchSize: PropTypes.func,
    addHistory: PropTypes.func,
    isMobileEditor: PropTypes.bool,
    canAlignAllDirections: PropTypes.bool,
    alignComp: PropTypes.func,
    removeComp: PropTypes.func,
    arrangementAPI: PropTypes.shape({
      moveToFront: PropTypes.func,
      moveForward: PropTypes.func,
      moveToBack: PropTypes.func,
      moveBackward: PropTypes.func,
    }),
    isCrossSitePaste: PropTypes.func,
  };

  getShortcutTooltip = (label: string, shortcut?: string) => {
    return React.createElement(
      baseUI.popoverTemplates.keyboardShortcutTooltip,
      {
        label,
        shortcut,
      },
    );
  };

  getDeleteTooltipText = () => {
    const tooltipLabel = this.props.isMobileEditor
      ? 'toolbar_tooltip_hide'
      : 'toolbar_tooltip_delete';
    if (this.props.restrictions.removable) {
      return this.getShortcutTooltip(
        tooltipLabel,
        translate('toolbar_tooltip_delete_shortcut'),
      );
    }

    return this.getDisabledDeleteTooltipText(
      tooltipLabel,
      translate('toolbar_tooltip_delete_shortcut'),
    );
  };

  getDisabledDeleteTooltipText = (label: string, shortcut: string) => {
    const tooltipServiceText = this.props.compDeleteTooltipText;

    return tooltipServiceText
      ? tooltipServiceText
      : this.getShortcutTooltip(label, shortcut);
  };

  getInfoTooltip = (title: string, text: string) => {
    return React.createElement(
      baseUI.popoverTemplates.titleBodyAndLinkTooltip,
      {
        title,
        text,
      },
    );
  };

  handleCopy = () => {
    if (this.props.restrictions.duplicatable) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        this.props.compBIParams,
        this.assignToolbarSpecificBI.bind(null, 'origin'),
      );

      this.props.reportBI(
        bi.events.rightClickMenu.COPY,
        this.props.compBIParams,
      );

      this.props.copyPasteAPI.copy(this.props.components);
    }
  };

  pasteComponent = () => {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.PAST_COMPONENT_OR_TPA,
    );
    const compsBIParams = this.props.compBIParams;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(compsBIParams, this.assignToolbarSpecificBI.bind(null, 'origin'));

    this.props.copyPasteAPI.paste(undefined, undefined, 'toolbar');
    this.props.addHistory('component - paste');
    this.props.reportBI(bi.events.rightClickMenu.PASTE, compsBIParams);
  };

  pastePage = () => {
    const FEDOPS_INTERACTION = this.props.isCrossSitePaste()
      ? util.fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
      : util.fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;

    util.fedopsLogger.interactionStarted(FEDOPS_INTERACTION);
    this.props.copyPasteAPI
      .pastePage({
        menuItemToPasteAfter: this.props.selectedMenuItem,
        origin: 'toolbar',
      })
      .then(() => {
        util.fedopsLogger.interactionEnded(FEDOPS_INTERACTION);
      });
  };

  handlePaste = () => {
    if (!this.props.pasteEnabled) {
      return;
    }

    if (this.props.copyPasteAPI.hasComponentToPaste()) {
      this.pasteComponent();
    } else if (this.props.copyPasteAPI.hasPageToPaste()) {
      this.pastePage();
    } else {
      // do nothing
    }
  };

  handleDuplicate = async () => {
    if (this.props.restrictions.duplicatable) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.DUPLICATE_COMPONENT_OR_TPA,
      );
      const compsBIParams = this.props.compBIParams;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        compsBIParams,
        this.assignToolbarSpecificBI.bind(null, 'origin'),
      );

      await this.props.copyPasteAPI.duplicate(this.props.components);
      this.props.addHistory('component - duplicate');
      this.props.reportBI(bi.events.rightClickMenu.DUPLICATE, compsBIParams);
    }
  };

  handleDelete = () => {
    if (this.props.restrictions.removable) {
      const compsBIParams = this.props.compBIParams;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        compsBIParams,
        this.assignToolbarSpecificBI.bind(null, 'removal_method'),
      );
      this.props
        .removeComp(this.props.components, undefined, 'toolbar')
        .then((removed: AnyFixMe) => {
          if (removed) {
            this.props.addHistory('component - remove');
            this.props.reportBI(
              bi.events.editor.component_removed,
              compsBIParams,
            );
          }
        });
    }
  };

  moveToFront = () => {
    this.props.arrangementAPI.moveToFront(this.props.components);
    this.props.reportBI(bi.events.rightClickMenu.BRING_TO_FRONT, {
      origin: 'toolbar',
    });
  };

  moveForward = () => {
    this.props.arrangementAPI.moveForward(this.props.components);
    this.props.reportBI(bi.events.rightClickMenu.MOVE_FORWARD, {
      origin: 'toolbar',
    });
  };

  moveToBack = () => {
    this.props.arrangementAPI.moveToBack(this.props.components);
    this.props.reportBI(bi.events.rightClickMenu.SEND_TO_BACK, {
      origin: 'toolbar',
    });
  };

  moveBackward = () => {
    this.props.arrangementAPI.moveBackward(this.props.components);
    this.props.reportBI(bi.events.rightClickMenu.MOVE_BACKWARDS, {
      origin: 'toolbar',
    });
  };

  isAlignEnabled = () => {
    if (this.props.isCompControlledByParent) {
      return false;
    }

    return this.props.canAlignAllDirections;
  };

  align = (alignment: AnyFixMe) => {
    this.props.alignComp(this.props.components, alignment);
    this.props.reportBI(bi.events.toolbar.TOOL_BAR_ALIGNMENT_MENU_CLICK, {
      category: alignment,
    });
  };

  isDistributeEnabled = () => {
    if (this.props.isCompControlledByParent) {
      return false;
    }

    return this.props.canDistributeAllDirections;
  };

  distribute = (distribution: AnyFixMe) => {
    this.props.distribute(this.props.components, distribution);
    this.props.reportBI(bi.events.toolbar.TOOL_BAR_DISTRIBUTION_MENU_CLICK, {
      category: distribution,
    });
  };

  isMatchSizeEnabled = () => {
    if (this.props.isCompControlledByParent) {
      return false;
    }

    return this.props.canMatchAllSizes;
  };

  matchSize = (matchSizeValue: AnyFixMe) => {
    this.props.matchSize(this.props.components, matchSizeValue);
    this.props.reportBI(bi.events.toolbar.TOOL_BAR_MATCH_SIZE_MENU_CLICK, {
      category: matchSizeValue,
    });
  };

  assignToolbarSpecificBI = (
    specificKeyName: AnyFixMe,
    biParamsToUpdate: AnyFixMe,
  ) => {
    const specificBIParam: Record<string, any> = {};
    specificBIParam[specificKeyName] = 'toolbar';
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(biParamsToUpdate, specificBIParam);
  };

  render() {
    const { restrictions } = this.props;
    const shortcuts = keyboardMap.editor;

    return (
      <div>
        <div className="inner button-grid">
          <BaseUI.tooltip
            value={this.getShortcutTooltip(
              'toolbar_tooltip_copy',
              shortcuts.COPY.label,
            )}
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
            delay="600"
          >
            <span
              onClick={this.handleCopy}
              className={cx({
                button: true,
                disabled: !restrictions.duplicatable,
              })}
            >
              <BaseUI.symbol name="toolbarCopy" />
            </span>
          </BaseUI.tooltip>
          <BaseUI.tooltip
            value={this.getShortcutTooltip(
              'toolbar_tooltip_paste',
              shortcuts.PASTE.label,
            )}
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
            delay="600"
          >
            <span
              onClick={this.handlePaste}
              className={cx({
                button: true,
                disabled: !this.props.pasteEnabled,
              })}
            >
              <BaseUI.symbol name="toolbarPaste" />
            </span>
          </BaseUI.tooltip>
          <BaseUI.tooltip
            value={this.getShortcutTooltip(
              'toolbar_tooltip_duplicate',
              shortcuts.DUPLICATE.label,
            )}
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
            delay="600"
          >
            <span
              onClick={this.handleDuplicate}
              className={cx({
                button: true,
                disabled: !restrictions.duplicatable,
              })}
            >
              <BaseUI.symbol name="toolbarDuplicate" />
            </span>
          </BaseUI.tooltip>
          <BaseUI.tooltip
            value={this.getDeleteTooltipText()}
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
            delay="600"
          >
            <span
              onClick={this.handleDelete}
              className={cx({
                button: true,
                disabled: !restrictions.removable,
              })}
            >
              {!this.props.isMobileEditor ? (
                <BaseUI.symbol key="deleteSymbol" name="toolbarDelete" />
              ) : null}
              {this.props.isMobileEditor ? (
                <BaseUI.symbol key="hideSymbol" name="gfpp_hide" />
              ) : null}
            </span>
          </BaseUI.tooltip>
        </div>
        <div className="seperator" />
        <div className="inner button-grid">
          <ToolSelector
            disabled={
              !(restrictions.canMoveForward || restrictions.canMoveBackward) ||
              this.props.isCompControlledByParent
            }
            symbol="toolbarMoveForward"
            columns={2}
            tooltip={this.getInfoTooltip(
              'toolbar_tooltip_arrange',
              'toolbar_tooltip_arrange_info',
            )}
            tooltipClass="control-tooltip-small"
          >
            <BaseUI.tooltip
              value={this.getShortcutTooltip(
                'toolbar_tooltip_arrange_move_forward',
                shortcuts.SEND_FORWARD.label,
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.moveForward();
                }}
                className={cx({
                  button: true,
                  disabled: !restrictions.canMoveForward,
                })}
              >
                <BaseUI.symbol name="toolbarMoveForward" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getShortcutTooltip(
                'toolbar_tooltip_arrange_move_backward',
                shortcuts.SEND_BACKWARD.label,
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.moveBackward();
                }}
                className={cx({
                  button: true,
                  disabled: !restrictions.canMoveBackward,
                })}
              >
                <BaseUI.symbol name="toolbarMoveBackward" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              alignment="bottom"
              value={this.getShortcutTooltip(
                'toolbar_tooltip_arrange_bring_to_front',
                shortcuts.SEND_TO_FRONT.label,
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.moveToFront();
                }}
                className={cx({
                  button: true,
                  disabled: !restrictions.canMoveForward,
                })}
              >
                <BaseUI.symbol name="toolbarMoveToFront" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              alignment="bottom"
              value={this.getShortcutTooltip(
                'toolbar_tooltip_arrange_send_to_back',
                shortcuts.SEND_TO_BACK.label,
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.moveToBack();
                }}
                className={cx({
                  button: true,
                  disabled: !restrictions.canMoveBackward,
                })}
              >
                <BaseUI.symbol name="toolbarMoveToBack" />
              </span>
            </BaseUI.tooltip>
          </ToolSelector>

          <ToolSelector
            disabled={!this.isAlignEnabled()}
            symbol="toolbarAlignRight"
            columns={3}
            onShow={_.partial(
              this.props.reportBI,
              bi.events.toolbar.TOOL_BAR_ALIGNMENT_CLICK,
              {},
            )}
            tooltip={this.getInfoTooltip(
              'toolbar_tooltip_align',
              'toolbar_tooltip_align_info',
            )}
            tooltipClass="control-tooltip-small"
          >
            <BaseUI.tooltip
              value={this.getShortcutTooltip('toolbar_tooltip_align_left')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.align('left');
                }}
                className="button"
              >
                <BaseUI.symbol name="toolbarAlignLeft" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getShortcutTooltip('toolbar_tooltip_align_center')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.align('center');
                }}
                className="button"
              >
                <BaseUI.symbol name="toolbarAlignCenter" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getShortcutTooltip('toolbar_tooltip_align_right')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.align('right');
                }}
                className="button"
              >
                <BaseUI.symbol name="toolbarAlignRight" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              alignment="bottom"
              value={this.getShortcutTooltip('toolbar_tooltip_align_top')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.align('top');
                }}
                className="button"
              >
                <BaseUI.symbol name="toolbarAlignTop" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              alignment="bottom"
              value={this.getShortcutTooltip('toolbar_tooltip_align_middle')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.align('middle');
                }}
                className="button"
              >
                <BaseUI.symbol name="toolbarAlignMiddle" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              alignment="bottom"
              value={this.getShortcutTooltip('toolbar_tooltip_align_bottom')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.align('bottom');
                }}
                className="button"
              >
                <BaseUI.symbol name="toolbarAlignBottom" />
              </span>
            </BaseUI.tooltip>
          </ToolSelector>

          <ToolSelector
            key="distributeButtons"
            disabled={!this.isDistributeEnabled()}
            symbol="distribute-vertical-btn-bld"
            columns={3}
            onShow={_.partial(
              this.props.reportBI,
              bi.events.toolbar.TOOL_BAR_DISTRIBUTION_CLICK,
              {},
            )}
            tooltip={this.getInfoTooltip(
              'toolbar_tooltip_distribute',
              'toolbar_tooltip_distribute_info',
            )}
            tooltipClass="control-tooltip-small"
          >
            <BaseUI.tooltip
              value={this.getShortcutTooltip(
                'toolbar_tooltip_distribute_horizontally',
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.distribute('horizontal');
                }}
                className="button"
              >
                <BaseUI.symbol name="distribute-horizontal-btn-bld" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getShortcutTooltip('toolbar_tooltip_distribute_both')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.distribute('verticalAndHorizontal');
                }}
                className="button"
              >
                <BaseUI.symbol name="distribute-hw-btn-bld" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getShortcutTooltip(
                'toolbar_tooltip_distribute_vertically',
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.distribute('vertical');
                }}
                className="button"
              >
                <BaseUI.symbol name="distribute-vertical-btn-bld" />
              </span>
            </BaseUI.tooltip>
          </ToolSelector>

          <ToolSelector
            key="matchSizeButtons"
            disabled={!this.isMatchSizeEnabled()}
            symbol="mech-to-height-btn-bld"
            columns={3}
            onShow={_.partial(
              this.props.reportBI,
              bi.events.toolbar.TOOL_BAR_MATCH_SIZE_CLICK,
              {},
            )}
            tooltip={this.getInfoTooltip(
              'toolbar_tooltip_match_size',
              'toolbar_tooltip_match_size_info',
            )}
            tooltipClass="control-tooltip-small"
          >
            <BaseUI.tooltip
              value={this.getShortcutTooltip(
                'toolbar_tooltip_match_size_width',
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.matchSize('width');
                }}
                className="button"
              >
                <BaseUI.symbol name="mech-to-width-btn-bld" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getShortcutTooltip('toolbar_tooltip_match_size_both')}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.matchSize('heightAndWidth');
                }}
                className="button"
              >
                <BaseUI.symbol name="mech-hw-btn-bld" />
              </span>
            </BaseUI.tooltip>
            <BaseUI.tooltip
              value={this.getShortcutTooltip(
                'toolbar_tooltip_match_size_height',
              )}
              styleType={constants.UI.TOOLTIP.STYLE_TYPE.SMALL}
              delay="600"
            >
              <span
                onClick={() => {
                  this.matchSize('height');
                }}
                className="button"
              >
                <BaseUI.symbol name="mech-to-height-btn-bld" />
              </span>
            </BaseUI.tooltip>
          </ToolSelector>
        </div>
      </div>
    );
  }
}

export default util.hoc.renderWhenMutated(
  ToolbarTop as AnyFixMe,
) as React.ComponentType<ToolbarTopProps>;

import React, { type ComponentType } from 'react';
import ReactDOM from 'reactDOM';
import * as util from '#packages/util';
import * as baseUI from '#packages/baseUI';
import LazyComponent from '#packages/lazyComponent';
import * as stateManagement from '#packages/stateManagement';
import * as Notifications from '#packages/notifications';
import * as TextControls from '#packages/textControls';
import constants from '#packages/constants';
import * as TopBar from '#packages/topBar';
import { EditorSearchPanel } from '#packages/editorSearch';
import { OpenedRightPanels } from '#packages/panels';
import ZoomModeBar from '../topBar/zoomModeBar';
import LightboxMode from '../lightboxModeBar/lightboxModeBar';
import UnifiedChatWrapper from '../unifiedChatWrapper/unifiedChatWrapper';
import DynamicPagesComboBox from '../topBar/dynamicPagesComboBox';
import { RightClickMenu } from '../rightClickMenu/rightClickMenu';
import Prefetch from '../prefetch/prefetch';
import { ToolbarWithQuickTip } from '../toolBar/toolBar';

import { SplitterWrapper } from './splitterWrapper';
import { mapStateToProps, mapDispatchToProps } from './editorViewMapper';
import BodyStylesSetter from './bodyStylesSetter';

import type { ContributedComponent } from '#packages/apilib';
import type { EditorState } from '#packages/stateManagement';

const { DragLayoutProvider } = stateManagement.interactions.context;

interface EditorViewProps {
  viewTools: EditorState['viewTools'];
  wixCodeLoaded: EditorState['wixCodeLoaded'];
  hideTools: EditorState['hideTools'];
  devModeContext: EditorState['devModeContext'];
  editorIsInit: EditorState['editorIsInit'];
  floatingBubble: EditorState['floatingBubble'];
  isContextMenu: boolean;
  editorMousePosition: any;
  focusEventCounter: number;
  shouldLoadWixCode: boolean;
  isPinMode: boolean;
  isZoomMode: boolean;
  isLightboxMode: boolean;
  previewMode: boolean;
  shouldShowLiveChat: boolean;
  shouldShowDevModePanels: boolean;
  languages: any;
  themeFonts: any;
  documentType: string;
  onMouseMove: (e: React.MouseEvent) => void;
  onMouseUp: (e: React.MouseEvent) => void;
  resetScrollListeners: () => void;
  WorkspaceComponents: Array<ContributedComponent<{}>>;
  wixCodeLoaderComponent: ComponentType<any>;
  loadWixCodeWithRepluggableInClassic: boolean;
}

const getWixCodeLoaderComponent = (
  loadWixCodeWithRepluggableInClassic: boolean,
  WixCodeLoaderComponent: ComponentType<any>,
  wixCodeLoaded: boolean,
) => {
  if (!loadWixCodeWithRepluggableInClassic) {
    return (
      <LazyComponent
        moduleName="wixCode.components.wixCodeLoader"
        key="wix-code-loader"
        id="wix-code-loader"
        wixCodeLoaded={wixCodeLoaded}
      />
    );
  }
  return <WixCodeLoaderComponent wixCodeLoaded={wixCodeLoaded} />;
};
export class EditorViewComponent extends React.Component<EditorViewProps> {
  static displayName = 'EditorView';

  UNSAFE_componentWillReceiveProps(nextProps: AnyFixMe) {
    this.receiveFocusEvent(nextProps.focusEventCounter);
  }

  UNSAFE_componentWillMount() {
    this.props.resetScrollListeners();
  }

  componentWillUnmount() {
    this.props.resetScrollListeners();
  }

  receiveFocusEvent(focusEventCounter: number) {
    if (focusEventCounter > this.props.focusEventCounter) {
      ReactDOM.findDOMNode(this).focus();
    }
  }

  onMouseDownCapture = (e: React.MouseEvent) => {
    if (e.button === 2) {
      // Stop mouseDown on right click
      e.stopPropagation();
    }
  };

  onContextMenuPreventDefault = (e: React.MouseEvent) => {
    const contextMenuEnabled = e.ctrlKey && util.isDebugMode();

    if (!contextMenuEnabled) {
      e.preventDefault();
    }
  };

  render() {
    const shouldShowDeveloperToolbar =
      util.localModeUtils.isLocalModeEnabled() &&
      this.props.shouldShowDevModePanels &&
      this.props.viewTools.developerToolBarEnabled &&
      !this.props.previewMode;
    const isLightboxMode =
      util.topBar.isNewTopBarEnabled() && this.props.isLightboxMode;

    return (
      <DragLayoutProvider>
        <div
          id={constants.ROOT_COMPS.SELECTOR_ID.EDITOR}
          tabIndex={0}
          className="editor-animation-manager-wrapper"
        >
          <div
            onMouseMove={this.props.onMouseMove}
            onMouseUp={this.props.onMouseUp}
            onMouseDownCapture={this.onMouseDownCapture}
            className={util.cx({
              'editor-layout-wrapper': true,
              'prefetched-iframe': true,
              'zoom-mode': this.props.isZoomMode,
              'top-bar-redesign': util.topBar.isNewTopBarEnabled(),
            })}
          >
            <BodyStylesSetter />

            <div className="editor-layout-header">
              <TopBar.topBar />
            </div>

            <div className="editor-layout-body">
              <SplitterWrapper />

              <EditorSearchPanel />

              <OpenedRightPanels />

              <Notifications.notificationCenter />
            </div>

            <div className="editor-layout-fixed-controls">
              {util.topBar.isNewTopBarEnabled() ? null : <ZoomModeBar />}

              {isLightboxMode && !this.props.previewMode ? (
                <LightboxMode />
              ) : null}

              <DynamicPagesComboBox
                previewMode={this.props.previewMode}
                devModeContext={this.props.devModeContext}
                editorIsInit={this.props.editorIsInit}
              />

              {this.props.viewTools.toolbarEnabled &&
              !this.props.previewMode ? (
                <ToolbarWithQuickTip />
              ) : null}

              {this.props.shouldLoadWixCode
                ? getWixCodeLoaderComponent(
                    this.props.loadWixCodeWithRepluggableInClassic,
                    this.props.wixCodeLoaderComponent,
                    this.props.wixCodeLoaded,
                  )
                : null}

              {shouldShowDeveloperToolbar ? (
                <LazyComponent
                  moduleName="wixCode.components.developerToolBar"
                  key="developerToolbar"
                  toolsHidden={this.props.hideTools}
                  isEnabled={this.props.editorIsInit}
                  onContextMenu={this.onContextMenuPreventDefault}
                />
              ) : null}

              <baseUI.tooltipsRenderer />

              <baseUI.floatingBubble
                value={this.props.floatingBubble.value}
                targetLayout={this.props.floatingBubble.targetLayout}
                bubbleProps={this.props.floatingBubble.bubbleProps}
                shouldNotHideOnMouseLeaveTarget={
                  this.props.floatingBubble.shouldNotHideOnMouseLeaveTarget
                }
                pinMode={this.props.isPinMode}
              />

              <baseUI.dropDown.options />

              {!this.props.editorIsInit ? (
                <div key="preloader-overlap" className="preloader-overlap" />
              ) : null}

              {this.props.isContextMenu && (
                <RightClickMenu
                  key={`${this.props.editorMousePosition.x}${this.props.editorMousePosition.y}`}
                />
              )}

              {this.props.languages ? (
                <TextControls.themeFontsLoader
                  themeFonts={this.props.themeFonts}
                  documentType={this.props.documentType}
                  languages={this.props.languages}
                />
              ) : null}

              <Prefetch
                href="editor-organize-images/index.html"
                base={util.editorModel.editorBase}
              />

              {this.props.shouldShowLiveChat ? <UnifiedChatWrapper /> : null}
              {this.props.WorkspaceComponents.map((slot) => (
                <slot.contribution key={slot.uniqueId} />
              ))}
            </div>
          </div>
        </div>
      </DragLayoutProvider>
    );
  }
}

export const EditorView = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(EditorViewComponent);

EditorView.pure = EditorViewComponent;

import React from 'react';
import { translate } from '#packages/i18n';
import { TRANSLATIONS } from '../../../translations';
import { getSectionName } from '../../../api';

import type { PluginServicePlugins } from 'types/editorPlugins';
import type { Scope } from '#packages/sections';

import styles from './attachLabelPlugin.scss';

export const createAttachLabelPlugin =
  (scope: Scope): PluginServicePlugins.AttachLabelPlugin =>
  // eslint-disable-next-line react/display-name
  ({ compRef }) => {
    const SECTION_NAME_PLACEHOLDER = '%SN%';
    const sectionName = getSectionName(scope, compRef);

    const label = translate(TRANSLATIONS.SECTION_ATTACH_LABEL, {
      section_name: SECTION_NAME_PLACEHOLDER,
    });

    const [textBefore, textAfter] = label.split(SECTION_NAME_PLACEHOLDER);

    return (
      <span className={styles.container}>
        {textBefore}
        <span className={styles.name}>{sectionName}</span>
        {textAfter}
      </span>
    );
  };

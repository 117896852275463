import { cx } from '#packages/util';
import experiment from 'experiment';
import { panels } from '#packages/stateManagement';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import React, { useEffect, useState } from 'react';
import { quickEditPanelOrigin } from '../consts';
import styles from './styles/quickEditCompPanelWrapper.scss';
import { QuickEditEngineScope } from '../quickEditEngineEntryPoint';
import { getCompPanelName, loadPanelByAction } from '../quickEditEngineUtils';
import { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';
import { quickEditPanelsSectionsDefinitions } from '../quickEditPanelsSectionsDefinitions';

import type { CompRef } from 'types/documentServices';
import type { PanelSectionsDefinition } from '#packages/compPanels';

export interface CompPanelProps {
  origin: string;
  compType: string;
  panelName: string;
  style: React.CSSProperties;
  selectedComponent: CompRef[];
  selectedComponents: CompRef[];
  experimentIsOpen: typeof experiment.isOpen;
  panelSectionsDefinition: PanelSectionsDefinition;
}

interface QuickEditCompPanelWrapperOwnProps {
  getDrillInAction: (compId: string) => React.ReactElement;
  isCompInInteractionMode: (compRef: CompRef) => boolean;
  getPanelStyle: (panelType: QuickEditControlActions) => React.CSSProperties;
  onCompPanelLoaded: (
    compId: string,
    panelName: string,
    compType: string,
  ) => void;
}

type QuickEditCompPanelWrapperProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  QuickEditCompPanelWrapperOwnProps
>;

const QuickEditCompPanelWrapper: React.FC<QuickEditCompPanelWrapperProps> =
  React.memo(
    ({
      compId,
      loadPanel,
      panelType,
      getCompPanelProps,
      onCompPanelLoaded,
      getDrillInAction,
    }: QuickEditCompPanelWrapperProps) => {
      const [panel, setPanel] = useState(null);
      useEffect(() => {
        if (compId) {
          loadPanel(compId, panelType).then((_panel) => {
            const compPanelProps = getCompPanelProps(compId, panelType);
            setPanel(React.createElement(_panel, compPanelProps));
            onCompPanelLoaded(
              compId,
              compPanelProps.panelName,
              compPanelProps.compType,
            );
          });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [compId]);
      const drillInAction = getDrillInAction(compId);
      return (
        <div
          className={cx(styles.quickEditCompPanelWrapper, {
            [styles.fullHeight]: !drillInAction,
          })}
          data-hook="quick-edit-comp-panel-wrapper"
        >
          {panel}
          {drillInAction}
        </div>
      );
    },
    (prevProps, nextProps) => {
      return prevProps.compId === nextProps.compId;
    },
  );

QuickEditCompPanelWrapper.displayName = 'QuickEditCompPanelWrapper';

const mapStateToProps = (
  { editorAPI, store }: QuickEditEngineScope,
  { isCompInInteractionMode }: QuickEditCompPanelWrapperOwnProps,
) => {
  const compId = store.getCurrentEditedCompId();
  return {
    compId,
    panelType: store.getComponentPanelType(),
    isComponentInInteractionMode: isCompInInteractionMode(
      editorAPI.components.get.byId(compId),
    ),
  };
};

const mapDispatchToProps = (
  { editorAPI, store }: QuickEditEngineScope,
  { getPanelStyle }: QuickEditCompPanelWrapperOwnProps,
) => {
  return {
    loadPanel: async (
      compId: string,
      action: QuickEditControlActions | string,
    ) => {
      const compRef = editorAPI.components.get.byId(compId);
      const compType = editorAPI.components.getType(compRef);

      switch (action) {
        case 'custom':
          return store.getComponentPanelContent();
        case QuickEditControlActions.SETTINGS:
        case QuickEditControlActions.BACKGROUND:
        case QuickEditControlActions.MANAGE:
          return loadPanelByAction(compType, action);
        default:
          break;
      }
    },
    getCompPanelProps: (compId: string, action: QuickEditControlActions) => {
      const compRef = editorAPI.components.get.byId(compId);
      const componentType = editorAPI.components.getType(compRef);
      const panelName = getCompPanelName(componentType, action);
      const style = getPanelStyle(action);
      return {
        style,
        panelName,
        compType: componentType,
        selectedComponent: [compRef],
        selectedComponents: [compRef],
        origin: quickEditPanelOrigin,
        experimentIsOpen: experiment.isOpen,
        panelSectionsDefinition:
          quickEditPanelsSectionsDefinitions[panelName] ||
          panels.panelsSectionsDefinitionsDefaults[panelName],
      };
    },
  };
};

export default connectWithScope(
  () => QuickEditEngineScope,
  QuickEditCompPanelWrapper,
  mapStateToProps,
  mapDispatchToProps,
);

import { addPanelUtils } from '#packages/addPanelInfra';
import * as utils from '#packages/util';
import { reportErrorToBi, reportSuccessToBi } from '../reportToBi';
import constants from '#packages/constants';

import type { MediaPlatformFile } from '@wix/santa-editor-utils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompStructure } from 'types/documentServices';
import { HELP_IDS, TRANSLATION_KEYS } from '../consts';

export interface Dimensions {
  width: number;
  height: number;
}

export type ImageOrigin = 'paste_file' | 'desktop_file';

const LOADER_IMAGE_URLS = {
  large: utils.media.getMediaUrl('imageUploadToStage/loader_600x600.gif'),
  medium: utils.media.getMediaUrl('imageUploadToStage/loader_300x300.gif'),
  small: utils.media.getMediaUrl('imageUploadToStage/loader_150x150.gif'),
};

const loaderSpinnerImage = {
  componentType: constants.COMP_TYPES.PHOTO,
  type: 'Component',
  data: {
    alt: 'loader,gif',
    name: 'loader.gif',
    uri: LOADER_IMAGE_URLS.small,
    type: 'Image',
    title: LOADER_IMAGE_URLS.small,
  },
  activeModes: {},
  referredVariants: {},
};

const MAX_IMAGE_DIMENSIONS = 600;

const getRestrictImageDimensions = ({ width, height }: Dimensions) => {
  if (width <= MAX_IMAGE_DIMENSIONS && height <= MAX_IMAGE_DIMENSIONS) {
    return { width, height };
  }

  if (width > MAX_IMAGE_DIMENSIONS && width > height) {
    const ratio = MAX_IMAGE_DIMENSIONS / width;
    return { width: MAX_IMAGE_DIMENSIONS, height: height * ratio };
  }

  const ratio = MAX_IMAGE_DIMENSIONS / height;
  return { width: width * ratio, height: MAX_IMAGE_DIMENSIONS };
};

export async function getImageDimensions(file: File): Promise<Dimensions> {
  return new Promise(function (resolve) {
    const image = new Image();
    image.onload = function () {
      const dimensions = getRestrictImageDimensions({
        width: image.width,
        height: image.height,
      });

      resolve(dimensions);
    };
    image.src = URL.createObjectURL(file);
  });
}

const reportAndNotifySuccess = (
  editorAPI: EditorAPI,
  blob: File,
  origin: ImageOrigin,
  filesInBatch: number,
) => {
  reportSuccessToBi(origin, blob, filesInBatch);
  editorAPI.showUserActionNotification({
    message: TRANSLATION_KEYS.UPLOAD_SUCCESS,
    type: 'success',
  });
};

const reportAndNotifyError = (
  editorAPI: EditorAPI,
  blob: File,
  origin: ImageOrigin,
  filesInBatch: number,
) => {
  reportErrorToBi(origin, blob, filesInBatch);
  editorAPI.showUserActionNotification({
    message: TRANSLATION_KEYS.ERROR_UPLOADING_FILE,
    type: 'error',
    link: {
      caption: TRANSLATION_KEYS.ERROR_UPLOADING_FILE_READ_MORE,
      onNotificationLinkClick: () =>
        editorAPI.panelManager.openHelpCenter(HELP_IDS.ERROR_UPLOADING_FILE),
    },
  });
};

export const handleAddImage = async (
  editorAPI: EditorAPI,
  blob: File,
  uploadFilePromise: Promise<MediaPlatformFile>,
  origin: ImageOrigin,
  filesInBatch: number,
): Promise<void> => {
  let imageRefPromise: Promise<CompRef>;

  try {
    const dimensions = await getImageDimensions(blob);
    imageRefPromise = addImageToSite(
      editorAPI,
      dimensions.width,
      dimensions.height,
      origin,
    );

    const [imageRef, fileData] = await Promise.all([
      imageRefPromise,
      uploadFilePromise,
    ]);

    updateImageData(
      editorAPI,
      fileData.file_name,
      blob.name,
      imageRef,
      dimensions.width,
      dimensions.height,
    );
    reportAndNotifySuccess(editorAPI, blob, origin, filesInBatch);
  } catch (e) {
    reportAndNotifyError(editorAPI, blob, origin, filesInBatch);
    const imageRef = await imageRefPromise;
    if (imageRef) {
      editorAPI.components.remove(imageRef);
    }
  }
};

const getLoaderImageUri = (imageHeight: number, imageWidth: number) => {
  if (imageHeight >= 600 || imageWidth >= 600) {
    return LOADER_IMAGE_URLS.large;
  }
  if (imageHeight >= 300 || imageWidth >= 300) {
    return LOADER_IMAGE_URLS.medium;
  }
  return LOADER_IMAGE_URLS.small;
};

async function addImageToSite(
  editorAPI: EditorAPI,
  imageWidth: number,
  imageHeight: number,
  origin: ImageOrigin,
) {
  const layout = {
    width: imageWidth,
    height: imageHeight,
  };
  const data = {
    ...loaderSpinnerImage.data,
    uri: getLoaderImageUri(imageHeight, imageWidth),
  };
  const imageRef = await addPanelUtils.attemptToAddComponent(
    editorAPI,
    {
      ...loaderSpinnerImage,
      layout,
      data,
    } as CompStructure,
    '',
    '',
    '',
    '',
    origin,
  );
  await editorAPI.components.hooks.componentAddedToStage.fire({
    origin,
    compRef: imageRef,
    type: 'desktop',
  });
  return imageRef;
}

function updateImageData(
  editorAPI: EditorAPI,
  imageFileName: string,
  imageName: string,
  imageRef: CompRef,
  imageWidth: number,
  imageHeight: number,
) {
  editorAPI.components.data.update(imageRef, {
    alt: imageName,
    name: imageFileName,
    uri: imageFileName,
    type: 'Image',
    title: imageFileName,
    width: imageWidth,
    height: imageHeight,
  });
}

// @ts-nocheck
import * as util from '#packages/util';
import { Button } from '@wix/wix-base-ui';
import { ShowSmall } from '@wix/wix-ui-icons-common/classic-editor';
import PropTypes from 'prop-types';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import _ from 'lodash';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { InfoIcon, TextLabel, Tooltip } from '@wix/wix-base-ui';
import { cx } from '#packages/util';
import { translate } from '#packages/i18n';
import * as experiment from 'experiment';

const actions = stateManagement.mobile.actions.hiddenItems;
const isRedesignWithTooltipEnabled =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') === 'B';
const isRedesignWithoutTooltipEnabled =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') === 'C';
const withLeftPanelHiddenElementsRedesignExperiment =
  isRedesignWithTooltipEnabled || isRedesignWithoutTooltipEnabled;

//TYPE WAS GENERATED, remove this line when reviewed
interface HiddenItemProps {
  label: string;
  compId: string;
  compType: string;
  pageId: string;
  sectionType: string;
  isEssential: boolean;
  essentialText: string;
  svgName?: string;
  iconUrl?: string;
  css?: AnyFixMe;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

class HiddenItem extends React.Component<HiddenItemProps> {
  static displayName = 'hiddenItem';

  static propTypes = {
    label: PropTypes.string.isRequired,
    compId: PropTypes.string.isRequired,
    compType: PropTypes.string.isRequired,
    pageId: PropTypes.string.isRequired,
    sectionType: PropTypes.string.isRequired,
    svgName: PropTypes.string,
    isEssential: PropTypes.bool,
    essentialText: PropTypes.string,
    iconUrl: PropTypes.string,
    css: PropTypes.object,
    sendBi: PropTypes.func,
  };

  state = {
    timeoutDelayFinished: false,
    addedToStage: false,
  };

  render() {
    return (
      <div
        onClick={() => {
          {
            this.reAddComponent();
          }
        }}
        onMouseEnter={() => {
          {
            this.previewReAddComponent();
          }
        }}
        onMouseLeave={() => {
          {
            this.stopPreviewReAddComponent();
          }
        }}
        className={cx({
          'hidden-item': true,
          'new-hidden-item': true,
          'added-to-stage': this.state.addedToStage,
          'left-panel-hidden-elements-redesign-experiment':
            withLeftPanelHiddenElementsRedesignExperiment,
        })}
      >
        <div
          {...this.getStyle()}
          className={cx('icon', this.getStyle()?.className)}
        >
          {this.props.svgName ? (
            <baseUI.SymbolWrapper
              key="hiddenItemIcon"
              name={this.props.svgName}
            />
          ) : null}
        </div>
        <TextLabel
          className="label"
          shouldTranslate={false}
          value={this.props.label}
          automationId="text-label"
        />
        {this.props.isEssential ? (
          <InfoIcon
            tooltipClassName="essential"
            error={true}
            small={true}
            text={this.props.essentialText}
            automationId="essential-info"
            shouldTranslate={false}
          />
        ) : null}
        <Tooltip content="mobile_hidden_items_show_tooltip">
          {withLeftPanelHiddenElementsRedesignExperiment && (
            <Button
              className="btn-sm btn-confirm-secondary show-button-extend"
              prefixIcon={<ShowSmall />}
            >
              {translate('mobile_hidden_items_show_button')}
            </Button>
          )}
          {!withLeftPanelHiddenElementsRedesignExperiment && (
            <div className="show-button">
              <baseUI.symbol name="showComp" key="show-icon" className="show" />{' '}
              <span className="text">
                {translate('mobile_hidden_items_show_button')}
              </span>
            </div>
          )}
        </Tooltip>
      </div>
    );
  }

  timeoutId = undefined;

  cancelTimeout = () => {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
  };

  stopPreviewReAddComponent = () => {
    this.cancelTimeout();
    if (this.state.timeoutDelayFinished) {
      this.setState({ timeoutDelayFinished: false });
      this.props.stopPreviewReAddComponent();
    }
  };

  previewReAddComponent = () => {
    const alreadyDuringPreview = !!(
      this.timeoutId || this.state.timeoutDelayFinished
    );
    if (alreadyDuringPreview) {
      return;
    }

    this.timeoutId = window.setTimeout(() => {
      this.setState({ timeoutDelayFinished: true });
      const { compId, pageId } = this.props;
      this.props.previewReAddComponent(compId, pageId);
    }, 500);
    this.props.sendBi(coreBi.events.layers.hover_hidden_element, {
      section: this.props.sectionType,
      component_id: this.props.compId,
      component_type: this.props.compType,
      current_page_name: this.props.pageId,
    });
  };

  reAddComponent = () => {
    this.cancelTimeout();
    const { compId, pageId, compType, sectionType } = this.props;
    this.setState({ addedToStage: true });
    this.props.reAddComponent(compId, pageId, compType, sectionType);
  };

  getStyle = () => {
    return {
      style: this.props.css
        ? // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({}, this.props.css, {
            backgroundImage: `url(${this.props.iconUrl})`,
          })
        : {},
    };
  };
}

const stateToProps = ({ state }) => ({
  selectedComponents:
    stateManagement.selection.selectors.getSelectedCompsRefs(state),
});

const dispatchToProps = {
  previewReAddComponent: actions.previewReAddComponent,
  reAddComponent: actions.reAddComponent,
  stopPreviewReAddComponent: actions.stopPreviewReAddComponent,
};

const Connected = util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  stateToProps,
  dispatchToProps,
)(HiddenItem);
Connected.pure = HiddenItem;

export default Connected;

import { createReduxStore } from '#packages/apilib';
import type { SiteGlobalDataState } from './siteGlobalDataTypes';

const getInitialState = (): SiteGlobalDataState => ({});

export const SiteGlobalDataStore = createReduxStore({
  getInitialState,
  selectors: {
    getBusinessType: (state: SiteGlobalDataState) => state.businessType,
    getBusinessName: (state: SiteGlobalDataState) => state.businessName,
  },
  actions: {
    setBusinessType: (
      state: SiteGlobalDataState,
      businessType: SiteGlobalDataState['businessType'],
    ) => {
      return {
        ...state,
        businessType,
      };
    },
    setBusinessName: (
      state: SiteGlobalDataState,
      businessName: SiteGlobalDataState['businessName'],
    ) => {
      return {
        ...state,
        businessName,
      };
    },
  },
});

import {
  fedopsLogger,
  sections as sectionsUtils,
  editorModel,
} from '#packages/util';
import { runSectionMigration } from './api';
import { MAX_MIGRATIONS_ATTEMPTS_PER_PAGE, MigrationOrigin } from './constants';
import {
  PageMigrationStatus,
  MigrationFlow,
  type PageMigrationResult,
} from './types';
import { pageFailedMigrationCounter } from './utils/pageMigrationCounter';
import { hasPagesWithoutSectionsInRoot } from './utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from './scope';
import { type PanelOptions } from '#packages/stateManagement';

const isMigrationRestricted = (scope: Scope) => {
  const { isInsideEditorX, isInsideAppStudio } = scope.editorParamsAPI;
  const isMigrationRestricted =
    isInsideEditorX || isInsideAppStudio || editorModel.isImpersonated;

  return isMigrationRestricted;
};

const isSiteMigratedByRegularFlow = (scope: Scope): boolean => {
  const homePageId = scope.editorAPI.pages.getCurrentPageId();
  const homePageRef = scope.editorAPI.components.get.byId(homePageId);
  const isHomePageDataEmpty = !hasPageSectionsData(scope.editorAPI, homePageId);
  const isHomePageHasSections = !hasPagesWithoutSectionsInRoot(
    scope.editorAPI.documentServices,
    [homePageRef],
  );
  const isSiteMigrated = isHomePageDataEmpty && isHomePageHasSections;

  return isSiteMigrated;
};

const hasPageSectionsData = (editorAPI: EditorAPI, pageId: string) =>
  sectionsUtils.getPageSectionsEditorData(editorAPI, pageId)?.isSectionsEnabled;

const isPageMigrated = (scope: Scope, pageId: string) => {
  return (
    hasPageSectionsData(scope.editorAPI, pageId) ||
    !hasPagesWithoutSectionsInRoot(scope.editorAPI.documentServices, [
      scope.editorAPI.components.get.byId(pageId),
    ])
  );
};

const resolveMigrationFlow = (scope: Scope): MigrationFlow => {
  const { editorParamsAPI, editorAPI } = scope;
  const { sectionsMigrationFlow } = editorParamsAPI;

  const isSectionsEnabled =
    sectionsUtils.isSectionsEnabled() ||
    sectionsUtils.getSiteSectionsEditorData(editorAPI)?.isSectionsEnabled;

  if (!isSectionsEnabled && sectionsMigrationFlow) {
    return sectionsMigrationFlow;
  }

  const isMigratedFromADI =
    sectionsUtils.getSiteSectionsEditorData(editorAPI)?.isMigratedFromAdi;

  if (isSectionsEnabled && isMigratedFromADI) {
    return MigrationFlow.PerPageADI;
  }

  return MigrationFlow.PerPageEditor;
};

const showErrorModal = (scope: Scope) => {
  const { panelManager } = scope;

  const panelOptions: PanelOptions = {
    panelLoader: () =>
      import('./components/openPageErrorModal/openPageErrorModal').then(
        (m) => m.OpenPageErrorModal,
      ),
  };

  panelManager.openPanel('openPageErrorModal', {}, panelOptions);
};

const runPerPageMigration = async (
  scope: Scope,
  pageId: string,
  options?: { origin: MigrationOrigin },
) => {
  const { editorAPI } = scope;
  const { origin } = options ?? { origin: MigrationOrigin.Navigation };

  const migrationFlow = resolveMigrationFlow(scope);
  const isMobileEditor = editorAPI.isMobileEditor();
  const currentPage = isMobileEditor
    ? [{ id: pageId, type: 'DESKTOP' } as CompRef]
    : [editorAPI.components.get.byId(pageId)];

  await runSectionMigration(scope, {
    origin,
    flow: migrationFlow,
    pagesRefs: currentPage,
  });

  return {
    status: PageMigrationStatus.SUCCESS,
  };
};

const runMigrationOnHomePage = async (scope: Scope) => {
  const { editorAPI, editorCoreAPI } = scope;
  const homePageId = editorAPI.pages.getCurrentPageId();

  if (isPageMigrated(scope, homePageId)) {
    editorCoreAPI.hooks.sectionsMigrationReady.resolve({
      isMigrated: false,
    });
    return {
      status: PageMigrationStatus.ALREADY_MIGRATED,
    };
  }

  let migrationResult;

  try {
    fedopsLogger.appLoadingPhaseStart('sections-migration-init');

    migrationResult = await runPerPageMigration(scope, homePageId);

    fedopsLogger.appLoadingPhaseFinish('sections-migration-init');

    editorCoreAPI.hooks.sectionsMigrationReady.resolve({
      isMigrated: migrationResult.status === PageMigrationStatus.SUCCESS,
    });
  } catch (error: MaybeError) {
    editorCoreAPI.hooks.UNSTABLE_sectionsMigrationFailedOnHomePage.resolve();
  }

  return migrationResult;
};

const runMigrationOnPageNavigate = (scope: Scope) => {
  scope.editorAPI.pages.hooks.beforePageNavigate.tap(async ({ pageId }) => {
    if (isPageMigrated(scope, pageId)) {
      scope.readonlyModeApi.disableReadonlyMode();
      return;
    }

    const failedMigrationCount =
      pageFailedMigrationCounter.getMigrationCount(pageId);

    if (failedMigrationCount === MAX_MIGRATIONS_ATTEMPTS_PER_PAGE) {
      scope.readonlyModeApi.enableReadonlyMode();
      return;
    }

    try {
      await runPerPageMigration(scope, pageId);
      scope.readonlyModeApi.disableReadonlyMode();
    } catch (error: MaybeError) {
      if (!failedMigrationCount) {
        showErrorModal(scope);
      } else {
        scope.readonlyModeApi.enableReadonlyMode();
      }

      pageFailedMigrationCounter.incrementMigrationCount(pageId);
    }
  });
};

const runMigrationOnExitPreview = (scope: Scope) => {
  const { editorAPI } = scope;

  editorAPI.preview.registerToBeforeExitPreview(async () => {
    const currentPageId = editorAPI.pages.getCurrentPageId();

    if (isPageMigrated(scope, currentPageId)) {
      scope.readonlyModeApi.disableReadonlyMode();
      return;
    }

    try {
      await runPerPageMigration(scope, currentPageId, {
        origin: MigrationOrigin.ExitPreview,
      });
    } catch (error: MaybeError) {
      const homePageId = editorAPI.homePage.get();

      editorAPI.pages.navigateTo(homePageId);
    }
  });
};

export const initPerPageMigration = async (scope: Scope) => {
  if (isSiteMigratedByRegularFlow(scope) || isMigrationRestricted(scope)) {
    scope.editorCoreAPI.hooks.sectionsMigrationReady.resolve({
      isMigrated: false,
    });
    return;
  }

  const migrationResult: PageMigrationResult | undefined =
    await runMigrationOnHomePage(scope);

  if (migrationResult) {
    runMigrationOnPageNavigate(scope);

    runMigrationOnExitPreview(scope);
  }
};

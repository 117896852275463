import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import { MigrationStepError } from '../../utils';
import constants from '#packages/constants';

const isSection = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) =>
  documentServices.components.getType(compRef) === constants.COMP_TYPES.SECTION;

export const ensureEveryPageHasSections = async (
  documentServices: DocumentServicesObject,
  pagesRefs: CompRef[],
) => {
  const everyPageHasSectionChild = pagesRefs.every((pageRef) =>
    documentServices.components
      .getChildren(pageRef)
      .some((childRef) => isSection(documentServices, childRef)),
  );

  if (!everyPageHasSectionChild) {
    throw new MigrationStepError(
      'Migration failed: not every page has a section child',
    );
  }
};

export const ensureOnlySectionsInRoot = async (
  documentServices: DocumentServicesObject,
  pagesRef: CompRef[],
) => {
  const everyRootCompIsSection = pagesRef.every((pageRef) =>
    documentServices.components
      .getChildren(pageRef)
      .every((childRef) => isSection(documentServices, childRef)),
  );

  if (!everyRootCompIsSection) {
    throw new MigrationStepError(
      'Migration failed: page has non-section child in the root',
    );
  }
};

import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type { PageRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import { PanelResolveType } from '@wix/platform-editor-sdk';
import { translate } from '#packages/i18n';
import { bi } from '#packages/tpa';
import events from '../bi/events';
import * as stateManagement from '#packages/stateManagement';

export const updateCollapseMenuIfNeeded = (
  editorAPI: EditorAPI,
  pageId: string,
  isCollapsed: boolean,
) => {
  let collapsedMap =
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      'collapsedMenuItems',
    )(editorAPI.store.getState()) as Record<string, boolean>;

  if (!collapsedMap) {
    // NOTE: https://github.com/wix-private/santa-editor/pull/20942#discussion_r331974822
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        'collapsedMenuItems',
        {},
      ),
    );
    collapsedMap = {};
  }
  collapsedMap = Object.assign({}, collapsedMap, {
    [`${pageId}_replacersCollapser`]: Boolean(isCollapsed),
  });

  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      'collapsedMenuItems',
      collapsedMap,
    ),
  );
};

function canBeReplaced(
  editorAPI: EditorAPI,
  appDefinitionId: string,
  pageRef: PageRef,
) {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  return platformAPI.pageReplace.isReplaceable(appDefinitionId, pageRef);
}

async function replaceAppPageWithReplacerPage(
  editorAPI: EditorAPI,
  pageRef: PageRef,
  replacerPageRef: PageRef,
) {
  if (!pageRef.id) {
    throw new Error(
      'in order to replace page with black, you must pass pageRef.',
    );
  }
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);

  if (!platformAPI.pageReplace.isRouterExistForPage(pageRef)) {
    const routerRef = await platformAPI.pageReplace.createRouter(pageRef);
    await platformAPI.pageReplace.connectPageToRouter(routerRef, pageRef);
  }

  const pageDataRequestDetails = platformAPI.pageReplace.getPageInfo(pageRef);
  if (pageDataRequestDetails) {
    if (
      !canBeReplaced(
        editorAPI,
        pageDataRequestDetails?.managingAppDefId,
        pageRef,
      )
    ) {
      throw new Error('app does not support replacement of this page');
    }
    const pageData = editorAPI.pages.data.get(pageRef.id);
    const replacerData = editorAPI.pages.data.get(replacerPageRef.id);

    const userAction = await editorAPI.platform.panels.openConfirmationPanel({
      helpId: '10ed2529-3649-4748-8edb-ab16b707e597',
      headerText: translate(
        'PLATFORM_flow_customization_replace_page_modal_title',
      ),
      shouldShowIllustration: true,
      symbol: 'replaceConflictPage',
      descriptionText: [
        {
          tag: 'p',
          content: translate(
            'PLATFORM_flow_customization_replace_page_modal_text',
            {
              page_name: pageData.title,
            },
          ),
        },
      ],
      mainActionText: translate(
        'PLATFORM_flow_customization_replace_page_modal_replace_button',
      ),
      secondaryActionText: translate(
        'PLATFORM_flow_customization_replace_page_modal_secondary_button',
      ),
    });
    const params = {
      page_id: replacerPageRef.id,
      default_page_id: pageRef.id,
      app_id: replacerData?.managingAppDefId,
      page_name: pageData.title,
    };
    if (userAction === PanelResolveType.MAIN_ACTION) {
      editorAPI.bi.event(events.conflicting_pages_actions, {
        ...params,
        target: 'replace',
      });
      platformAPI.pageReplace.setReplacerPage(pageRef, replacerPageRef, true);
      updateCollapseMenuIfNeeded(editorAPI, pageRef.id, false);
      await editorAPI.pages.navigateToAsync(replacerPageRef.id);
      editorAPI.bi.event(events.replacement_page_succeeded, {
        original_page: pageRef.id,
        replacer_page: replacerPageRef.id,
        app_id: replacerData?.managingAppDefId,
        host_app_id: pageDataRequestDetails.hostAppDefinitionId,
      });
    } else {
      editorAPI.bi.event(events.conflicting_pages_actions, {
        ...params,
        target: 'cancel',
      });
      platformAPI.pageReplace.setReplacerPage(pageRef, replacerPageRef, false);
      updateCollapseMenuIfNeeded(editorAPI, pageRef.id, false);
    }
  }
}

async function deleteReplacerAppAndResetModal(
  editorAPI: EditorAPI,
  appDefinitionId: string,
): Promise<{ status: PanelResolveType }> {
  if (!appDefinitionId) {
    throw new Error(
      'in order to delete an app, you must pass appDefinitionId.',
    );
  }
  const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
  if (appData) {
    editorAPI.bi.event(bi.events.TPA_MESSAGE_LOADED, {
      app_id: appDefinitionId,
      description: 'page_replace_app_delete',
      type: 'error_message',
    });
    const userAction = await editorAPI.platform.panels.openErrorPanel({
      helpId: '4b2cc9ee-1217-4293-9632-56e91eefd19a',
      headerText: translate(
        'PLATFORM_flow_customization_delete_app_modal_title',
      ),
      shouldShowIllustration: true,
      topDescriptionText: [
        {
          tag: 'p',
          content: translate(
            'PLATFORM_flow_customization_delete_app_modal_text',
            { app_name: appData.appDefinitionName },
          ),
        },
      ],
      secondaryActionText: translate(
        'PLATFORM_flow_customization_delete_app_modal_cancel_button',
      ),
      mainActionText: translate(
        'PLATFORM_flow_customization_delete_app_modal_delete_button',
      ),
    });
    return { status: userAction };
  }
}

export { replaceAppPageWithReplacerPage, deleteReplacerAppAndResetModal };

// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const getHealthDeclarationSection = (editorAPI) => ({
  healthDeclarationSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_heading_healthforms',
    topTitle: 'add_section_heading_healthforms',
    tooltipTitle: 'add_section_heading_healthforms_tooltip_title',
    automationId: 'add_section_heading_healthforms',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    showSectionHeader: true,
    subNavigationTitle: 'add_section_heading_healthforms',
    subNavigationHide: false,
    presetTitle: 'add_section_label_healthforms',
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsHealthDeclarationSection_en/formsHealthDeclarationSection_en.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Health_Declaration_Forms_1',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.HEALTH_DECLARATION,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.HEALTH_DECLARATION,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 660,
              height: 861,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: { rect: { width: 162, height: 176, x: 0, y: 0 }, tags: null },
        },
        {
          id: 'Health_Declaration_Forms_2',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.MEDICAL_QUESTIONNAIRE,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.MEDICAL_QUESTIONNAIRE,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 660,
              height: 1004,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 176, x: 162, y: 0 },
            tags: null,
          },
        },
        {
          id: 'Health_Declaration_Forms_3',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.GYM_HEALTH_WAIVER,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.GYM_HEALTH_WAIVER,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 660,
              height: 880,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 176, x: 0, y: 176 },
            tags: null,
          },
        },
        {
          id: 'Health_Declaration_Forms_4',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.COVID19_HEALTH_DECLARATION,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.COVID19_HEALTH_DECLARATION,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 644,
              height: 747,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 176, x: 162, y: 176 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: 'add_section_label_healthDeclarationForms_tooltip_description',
    },
  },
});

export default (editorAPI) => getHealthDeclarationSection(editorAPI);

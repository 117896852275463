import _ from 'lodash';
import * as util from '#packages/util';
import { utils as themeUtils } from '#packages/theme';
import * as helpIds from '#packages/helpIds';
import { translate } from '#packages/i18n';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import createCopyPasteUtils from '@/rEditor/app/APISections/copyPasteWrapper/createCopyPasteUtils';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import collectMediaIds from '@/rEditor/utils/copyPaste/collectMediaIds';
import { ErrorReporter } from '@wix/editor-error-reporter';

import type { SavedComponentPreset } from '#packages/savedComponents';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompStructure } from 'types/documentServices';
import type { SectionPresetDefinition } from '../types';

function openMediaManager(editorAPI: EditorAPI) {
  editorAPI.mediaServices.mediaManager.open(
    editorAPI.mediaServices.mediaManager.categories.ALL_MEDIA,
    { path: 'home' },
  );
}

function showUploadSuccessNotification(editorAPI: EditorAPI) {
  editorAPI.showUserActionNotification({
    title: 'Notifications_UploadingPastedFiles_Text',
    message: 'Notifications_UploadingPastedFiles_Text',
    type: 'info',
    link: {
      caption: 'Notifications_Open_SiteFiles_Link',
      onNotificationLinkClick: () => openMediaManager(editorAPI),
    },
  });
}

function showCantPasteNotification(editorAPI: EditorAPI) {
  editorAPI.showUserActionNotification({
    title: 'Notifications_CantPasteElements_Text',
    message: 'Notifications_CantPasteElements_Text',
    type: 'warning',
    link: {
      caption: 'Notifications_Learn_More_Link',
      onNotificationLinkClick: () =>
        editorAPI.panelManager.openHelpCenter(
          helpIds.HELP_CENTER.CANNOT_PASTE_COMPONENTS,
        ),
    },
  });
}

function transferMediaFiles(
  editorAPI: EditorAPI,
  { mediaIds, uploadToken }: { mediaIds: string[]; uploadToken: string },
) {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.PASTE_COPY_MEDIA_FILES,
  );
  return editorAPI.mediaServices.transferMediaItems(mediaIds, uploadToken).then(
    () => {
      showUploadSuccessNotification(editorAPI);
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.PASTE_COPY_MEDIA_FILES,
      );
    },
    (error) =>
      ErrorReporter.captureException(error, {
        tags: { transferMediaFiles: true },
      }),
  );
}

export const processSavedSections = (
  editorAPI: EditorAPI,
  { shouldApplyOriginTheme }: { shouldApplyOriginTheme: boolean },
) => {
  const currentSiteMetasiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
  const copyPasteUtils = createCopyPasteUtils(editorAPI);

  let hasCrossSiteUnsupportedComponents = false;
  let isCrossSite = false;
  let siteUploadToken: string;
  let mediaIds: string[] = [];

  const beforeCrossSiteAdd = (
    compPreset: SavedComponentPreset,
  ): CompStructure => {
    const { serializedData, metaData } = compPreset;
    siteUploadToken = metaData.siteUploadToken;
    isCrossSite = currentSiteMetasiteId !== metaData.metaSiteId;
    let components = serializedData.components;

    // TODO: process with root
    if (!isCrossSite) {
      return components[0];
    }

    hasCrossSiteUnsupportedComponents =
      copyPasteUtils.hasCrossSiteUnsupportedComponents(components);

    components = copyPasteUtils.getCrossSiteDuplicatableComponents(components);
    mediaIds = collectMediaIds(components);

    return components[0];
  };

  const afterCrossSiteAdd = async () => {
    if (isCrossSite) {
      const hasMediaContent = mediaIds.length > 0;

      if (hasMediaContent) {
        await transferMediaFiles(editorAPI, {
          mediaIds,
          uploadToken: siteUploadToken,
        });
      }

      if (hasCrossSiteUnsupportedComponents) {
        showCantPasteNotification(editorAPI);
      }
    }
  };

  const beforeAdd = (preset: SavedComponentPreset) => {
    const structure = beforeCrossSiteAdd(preset);

    if (shouldApplyOriginTheme) {
      return themeUtils.applyThemeToSerializedComponents(
        structure,
        preset.serializedData.theme,
      );
    }

    return structure;
  };

  const afterAdd = () => {
    afterCrossSiteAdd();
  };

  return {
    beforeAdd,
    afterAdd,
  };
};

export type ProcessSavedSections = ReturnType<typeof processSavedSections>;

export const getNextSectionDefaultNameUtil = (
  items: SectionPresetDefinition[],
  originalSectionName: string,
) => {
  const COUNT_PLACEHOLDER = '__REGEXP_COUNT_PLACEHOLDER__';

  const defaultNameTemplateWithoutNumber = translate(
    'add_section_saved_sections_save_modal_default_name',
    {
      section_name: originalSectionName,
      number: COUNT_PLACEHOLDER,
    },
  );

  const regexStr = _.escapeRegExp(defaultNameTemplateWithoutNumber).replace(
    COUNT_PLACEHOLDER,
    '(\\d+)',
  );

  const pattern = new RegExp(regexStr);
  const indexes = items
    .filter((item) => pattern.test(item.sectionTitleKey))
    .map((item) => parseInt(item.sectionTitleKey.match(pattern)[1], 10));

  return translate('add_section_saved_sections_save_modal_default_name', {
    section_name: originalSectionName,
    number: indexes.length === 0 ? 1 : Math.max(...indexes) + 1,
  });
};

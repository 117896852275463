import React from 'react';
import { Button, Divider, RichText, TextButton, Text } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import { Action } from '../../actionsButtons/testSite/testSite';

interface TestSiteRCFrame {
  publishRC: () => void;
  preview: () => void;
  onLearnMoreClick: (action: Action) => void;
}

export const TestSiteRCFrame: React.FC<TestSiteRCFrame> = (props) => (
  <div className="test-site-rc-drop-panel">
    <div className="container">
      <div className="content">
        <div className="label-wrapper">
          <div className="label">
            <Text
              key="testSiteLabel"
              size="small"
              weight="bold"
              skin="secondary"
            >
              TopBar_DevMode_Preview_Options_Dropdown_TestSite_Title
            </Text>
          </div>
          <Text size="tiny" skin="secondary">
            TopBar_DevMode_Preview_Options_Dropdown_TestSite_Shortcut
          </Text>
        </div>
        <div className="description">
          <RichText>
            <p>
              {translate(
                'TopBar_DevMode_Preview_Options_Dropdown_TestSite_Description',
              )}
            </p>
            <TextButton
              size="small"
              onClick={() => props.onLearnMoreClick(Action.TEST_SITE)}
            >
              TopBar_DevMode_Preview_Options_Dropdown_TestSite_LearnMore_Text
            </TextButton>
          </RichText>
        </div>
        <Button
          automationId="rcButton"
          onClick={() => props.publishRC()}
          className="btn-md btn-confirm-secondary"
        >
          {translate(
            'TopBar_DevMode_Preview_Options_Dropdown_TestSite_CTA_Label',
          )}
        </Button>
      </div>
    </div>
    <Divider className="divider" long />
    <div className="container">
      <div className="content">
        <div className="label-wrapper">
          <div className="label">
            <Text
              key="previewLabel"
              size="small"
              weight="bold"
              skin="secondary"
            >
              TopBar_DevMode_Preview_Options_Dropdown_Preview_Title
            </Text>
          </div>
          <Text size="tiny" skin="secondary">
            TopBar_DevMode_Preview_Options_Dropdown_Preview_Shortcut
          </Text>
        </div>
        <div className="description">
          <RichText>
            <p>
              {translate(
                'TopBar_DevMode_Preview_Options_Dropdown_Preview_Description',
              )}
            </p>
            <TextButton
              size="small"
              onClick={() => props.onLearnMoreClick(Action.PREVIEW)}
            >
              TopBar_DevMode_Preview_Options_Dropdown_Preview_LearnMore_Text
            </TextButton>
          </RichText>
        </div>
        <Button
          automationId="previewButton"
          onClick={() => props.preview()}
          className="btn-md btn-confirm-secondary"
        >
          {translate(
            'TopBar_DevMode_Preview_Options_Dropdown_Preview_CTA_Label',
          )}
        </Button>
      </div>
    </div>
  </div>
);

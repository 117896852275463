import * as coreBi from '#packages/coreBi';
import type {
  Dispatch,
  MapDispatchToProps,
  StateMapperArgs,
} from 'types/redux';
import type { AddPageCategory } from '#packages/addPreset';
import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import { appInstallationMap } from '../../addPagePanelUtil';

export interface AddPagePreInstallAppDispatchProps {
  addApp: () => void;
  sendBICarouselArrow: (direction: string) => void;
}

export interface AddPagePreInstallAppOwnProps {
  category: AddPageCategory;
  pageAddedCallback: (tpaType: string) => void;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  AddPagePreInstallAppDispatchProps,
  AddPagePreInstallAppOwnProps
> = (dispatch, ownProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    addApp: async () => {
      const { appDefinitionId } = ownProps.category.application;
      const { title } = ownProps.category;
      editorAPI.bi.event(coreBi.events.pages.addPage.addAppClick, {
        app_id: appDefinitionId,
        app_name: title,
      });
      editorAPI.panelManager.closeAllPanels();

      const tpaType = await appInstallationMap[appDefinitionId](editorAPI);

      ownProps.pageAddedCallback?.(tpaType);
    },
    sendBICarouselArrow: (direction: string) => {
      const { title } = ownProps.category;
      editorAPI.bi.event(coreBi.events.pages.addPage.appCarouselArrowClick, {
        direction,
        target: title,
      });
    },
  };
};

import React from 'react';

import { workspace } from '#packages/util';
import * as baseUI from '#packages/baseUI';

interface BackgroundPresetSectionHeaderProps {
  title: string;
  tooltipText?: string;
}

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

const BackgroundPresetSectionHeader: React.FC<
  BackgroundPresetSectionHeaderProps
> = (props) => {
  if (isNewWorkspace) {
    return (
      <baseUI.SectionHeader
        title={props.title}
        tooltipText={props.tooltipText}
      />
    );
  }

  return (
    <div className="section-header">
      <div className="title-line">
        <div className="title">{props.title}</div>
        <baseUI.infoIcon
          key="infoIcon"
          text={props.tooltipText}
          className="info-icon"
          shouldTranslate={false}
        />
      </div>
    </div>
  );
};

export default BackgroundPresetSectionHeader;

// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';

const { isPerformingMouseMoveAction } = stateManagement.mouseActions.selectors;

const mapStateToProps = ({ state }) => ({
  isPerformingMouseMoveAction: isPerformingMouseMoveAction(state),
});

const noUpdateOnMouseMove = (Component) => {
  // eslint-disable-next-line react/prefer-es6-class
  const NoUpdateOnMouseMove = createReactClass({
    displayName: recompose.wrapDisplayName(Component, 'NoUpdateOnMouseMove'),
    propTypes: {
      isPerformingMouseMoveAction: PropTypes.bool.isRequired,
    },
    shouldComponentUpdate() {
      return !this.props.isPerformingMouseMoveAction;
    },

    render() {
      return React.createElement(Component, this.props);
    },
  });

  return util.hoc.connect(
    util.hoc.STORES.STATE_ONLY,
    mapStateToProps,
  )(NoUpdateOnMouseMove);
};

export default noUpdateOnMouseMove;

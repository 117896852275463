import React, { type FC } from 'react';
import { NotificationBanner } from '@wix/wix-base-ui';
import { useTranslation } from 'react-i18next';
import * as util from '#packages/util';
import {
  mapDispatchToProps,
  type MapDispatchToPropsType,
} from './sectionsMigrationBannerMapper';

const SectionsMigrationBannerComponent: FC<MapDispatchToPropsType> = ({
  openSectionsMigrationPanel,
  reportOnHover,
}) => {
  const [translate] = useTranslation();

  return (
    <div onMouseEnter={() => reportOnHover()}>
      <NotificationBanner
        dataHook="top-bar-sections-migration-banner"
        shouldTranslate={false}
        skin="warning"
        type="floating"
        text={translate('FTE_Tour_partners_update_banner_text')}
        link={{
          label: translate('FTE_Tour_partners_update_banner_cta'),
          onClick: () => openSectionsMigrationPanel(),
        }}
      />
    </div>
  );
};

export const SectionsMigrationBanner = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(SectionsMigrationBannerComponent);

import { $layoutY, type LayoutY } from '#packages/layout';
import type { CompRef, DocumentServicesObject } from 'types/documentServices';

export function calcAnchorArrangementIndexInSection(
  documentServices: DocumentServicesObject,
  anchorRef: CompRef,
): number | null {
  // Anchor does not have height, clean it up
  const anchorLayout: LayoutY = {
    y: documentServices.components.layout.getRelativeToScreen(anchorRef).y,
    height: 0,
  };
  const anchorSectionRef = documentServices.components.getContainer(anchorRef);

  const sectionComponents = documentServices.components
    .getChildren(anchorSectionRef)
    .filter((compRef) => !documentServices.utils.isSameRef(compRef, anchorRef))
    .map((compRef) => ({
      compRef,
      layout: documentServices.components.layout.getRelativeToScreen(compRef),
    }));

  const { above, overlap } = $layoutY(anchorLayout).getAboveBelowOverlap(
    sectionComponents.map(({ layout }) => layout),
  );

  const isAnchorBelowMiddleOfOverlappedComponents =
    overlap.length > 0 &&
    $layoutY(anchorLayout).isBelow($layoutY(overlap).middleY);

  return isAnchorBelowMiddleOfOverlappedComponents
    ? // NOTE: return index after "above + overlap"
      above.concat(overlap).length
    : // NOTE: return index after "above"
      above.length;
}

// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';
import section1 from './live/section1';
import section2 from './live/section2';
import stylableButtonTheme from './live/stylableButtonTheme';

export default {
  getCategorySections() {
    return addPanel.dataUtil.mergeWithCommonSections(
      addPanel.sections.getSections().button,
      {
        section_1: section1,
        section_2: section2,
        stylableButtonTheme,
      },
    );
  },
};

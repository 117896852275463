import * as stateManagement from '#packages/stateManagement';
import CONSTANTS from './constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { CmdState, TextManager } from 'types/data';
import type { LinkPanelComponentOwnProps } from '#packages/panels';

const { TEXT_LIST_TYPES } = CONSTANTS;

const forceSaveComponentData = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  getLinksHelper: any,
  textManager?: TextManager,
) => {
  const text = textManager
    ? textManager.getData()
    : editorAPI.text.getCurrentTextManager().getData();
  const linkList = getLinksHelper().getAllLinks();

  editorAPI.components.data.update(compRef, {
    text,
    linkList,
  });
};

const openLinkPanel = (
  editorAPI: EditorAPI,
  linkValue: AnyFixMe,
  callback: AnyFixMe,
  props: Partial<LinkPanelComponentOwnProps> = {},
) => {
  editorAPI.openLinkPanel({
    ...props,
    link: linkValue,
    callback,
  });
};

const openLinkDialog = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  cmdState: CmdState,
  execCommand: (
    commandName: string,
    params?: AnyFixMe,
    cmdOptions?: AnyFixMe,
    isMouseOut?: boolean,
  ) => void,
  getLinksHelper: () => any,
  textManager?: TextManager,
  props?: Partial<LinkPanelComponentOwnProps>,
) => {
  const linkId = cmdState.wixLink as string;
  const currentLink = getLinksHelper().getLink(linkId);

  function fixEmailSubject(linkData: AnyFixMe) {
    if (linkData.type === 'EmailLink' && !linkData.subject) {
      linkData.subject = '';
    }
  }

  openLinkPanel(
    editorAPI,
    currentLink,
    (linkData: AnyFixMe) => {
      let newLinkId;

      if (linkData) {
        fixEmailSubject(linkData);

        newLinkId = getLinksHelper().saveLink(linkData);

        execCommand('wixLink', newLinkId);
      } else if (currentLink) {
        execCommand('wixUnlink');
      }

      // in case of unfocus text component during callback
      forceSaveComponentData(editorAPI, compRef, getLinksHelper, textManager);

      return newLinkId;
    },
    props,
  );
};

const getListType = (cmdState: CmdState) => {
  if (cmdState && cmdState[TEXT_LIST_TYPES.textListNumbers]) {
    return TEXT_LIST_TYPES.textListNumbers;
  } else if (cmdState && cmdState[TEXT_LIST_TYPES.textListBullets]) {
    return TEXT_LIST_TYPES.textListBullets;
  }
  return TEXT_LIST_TYPES.textListNone;
};

const execCommand = (
  editorAPI: EditorAPI,
  commandName: string,
  params?: AnyFixMe,
  cmdOptions?: AnyFixMe,
  isMouseOut?: boolean,
  isEditingRepeatedTextComp?: boolean,
) => {
  const textManager = editorAPI.text.getCurrentTextManager();
  isEditingRepeatedTextComp =
    isEditingRepeatedTextComp || editorAPI.text.isEditingRepeatedTextComp();
  const { store } = editorAPI;
  const state = store.getState();
  const widgetDesignSelectedComps =
    stateManagement.text.selectors.getWidgetDesignSelectedComponents(state);
  const selectedComps =
    (widgetDesignSelectedComps?.length > 0 && widgetDesignSelectedComps) ||
    stateManagement.selection.selectors.getSelectedCompsRefs(state);

  if (selectedComps.length > 1) {
    return editorAPI.store.dispatch(
      stateManagement.text.actions.multiselect.execCmd(
        commandName,
        params,
        cmdOptions,
        isMouseOut,
      ),
    );
  }

  textManager.execCommand(commandName, params, cmdOptions);

  const shouldApplyChangesToRepeatedText =
    textManager.isAllSelected() &&
    isEditingRepeatedTextComp &&
    !['wixLink', 'wixUnlink'].includes(commandName);

  if (shouldApplyChangesToRepeatedText) {
    editorAPI.store.dispatch(
      stateManagement.text.actions.invisibleTextEditor.execCmd({
        isRepeated: true,
        data: {
          commandName,
          commandData: params,
          cmdOptions,
        },
      }),
    );
  }
};

const focusCkEditor = (editorAPI: EditorAPI) => {
  editorAPI.text.getCurrentTextManager().focus();
};

const execListCommand = (
  editorAPI: EditorAPI,
  newListType: AnyFixMe,
  execCommand: any,
  cmdState: CmdState,
) => {
  const currentListType = getListType(cmdState);

  if (newListType !== currentListType) {
    if (newListType === TEXT_LIST_TYPES.textListNone) {
      //will toggle off the current list type
      execCommand(currentListType);
    } else {
      //will toggle on the newListType, it is not 'none' and not on currently, otherwise it would not enter here because listType === this.getListType()
      execCommand(newListType);
    }
  }
  //always set focus back to ck editor, even if there was no change (user canceled change...)
  focusCkEditor(editorAPI);
};

export { openLinkDialog, getListType, execCommand, execListCommand };

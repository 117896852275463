// @ts-nocheck
import { array } from '#packages/util';
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 373,
      height: 296,
      x: 19,
      y: 371,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 440,
          originalValue: 778,
          type: 'BOTTOM_PARENT',
          locked: false,
          targetComponent: 'comp-im0fk2hw',
        },
      ],
      fixedPosition: false,
    },
    id: 'comp-im0fk2in',
    mobileLayout: {
      width: 305,
      height: 304,
      x: 7,
      y: 272,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 31,
          topToTop: 344,
          originalValue: 626,
          type: 'BOTTOM_TOP',
          locked: true,
          targetComponent: 'comp-im0fy5d2',
        },
      ],
      fixedPosition: false,
    },
  });

  // add form to
  // components: [
  //     component_0: {
  //         components: [
  //             component_00
  //             component_01,
  //             form,
  //             ...
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: array.immutableSplice(structure.components, 0, 1, {
      ...structure.components[0],
      components: array.immutableSplice(
        structure.components[0].components,
        2,
        0,
        form,
      ),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.LIGHTBOX_CONTACT02);
  onClick = onClick(wixFormsConstants.FormPreset.LIGHTBOX_CONTACT02);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};

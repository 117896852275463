// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Color from 'color';

import * as core from '#packages/core';
import * as util from '#packages/util';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';

import mediaPreview from './mediaPreview';
import SectionHeader from './backgroundPresetSectionHeader';
import {
  generate as generateGradient,
  GradientLinear,
  GradientCircle,
  GradientEllipse,
  GradientConic,
  GradientMesh,
} from '@wix/css-gradient-generator';

const { url, serviceTopology, imageTransform, backgroundUtils, cx } = util;
const { getColorValue } = backgroundUtils;
const { fittingTypes, alignTypes } = imageTransform;
const DEFAULT_COLOR = '#ffffff';
const mediaTypes = constants.MEDIA_TYPES;

const thumbSizes = [
  { thumbWidth: 238, thumbHeight: 58 },
  { thumbWidth: 110, thumbHeight: 58 },
  { thumbWidth: 68, thumbHeight: 50 },
  // Special Case for Site Background
  { thumbWidth: 88, thumbHeight: 59 },
];

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'backgroundPresetSection',
  propTypes: {
    title: PropTypes.string,
    infoTooltip: PropTypes.string,
    onSelectPreset: PropTypes.func,
    columns: PropTypes.number,
    desktopPresets: PropTypes.array.isRequired,
    mobilePresets: PropTypes.array,
  },
  mixins: [core.mixins.editorAPIMixin],
  getInitialState() {
    const columns = this.props.columns - 1 || 0;
    return {
      thumbWidth: thumbSizes[columns].thumbWidth,
      thumbHeight: thumbSizes[columns].thumbHeight,
    };
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    const columns = nextProps.columns - 1 || 0;
    this.setState({
      thumbWidth: thumbSizes[columns].thumbWidth,
      thumbHeight: thumbSizes[columns].thumbHeight,
    });
  },
  isMediaVideo(preset) {
    return this.getMediaType(preset) === mediaTypes.VIDEO;
  },

  isGradient(preset) {
    return (
      this.getMediaType(preset) === mediaTypes.COLOR &&
      preset.ref.colorLayers &&
      preset.ref.colorLayers[0].fill.type !== 'SolidColor'
    );
  },

  getMediaType(preset) {
    return backgroundUtils.getMediaType(preset.ref);
  },

  getPresetData() {
    return this.getEditorAPI().isMobileEditor()
      ? this.props.mobilePresets || {}
      : this.props.desktopPresets;
  },

  getMediaPreviewComp(preset) {
    return this.getMediaType(preset) !== mediaTypes.COLOR ||
      this.isGradient(preset)
      ? React.createElement(mediaPreview, { data: preset.ref })
      : null;
  },

  /**
   * This is how a thumbnail + empty data looks like:
   *
   *  "thumbnail": {
   *    "uri": "0da768_880a3209e3744cd0a96b66835a01b8c5.png",
   *    "width": 256,
   *    "height": 256,
   *    "title": "No Image Demo"
   *  },
   *  "ref": {
   *    "type": "BackgroundMedia",
   *    "mediaRef": null,
   *    "alignType": "center",
   *    "fittingType": "fill",
   *    "colorOverlay": "",
   *    "colorOverlayOpacity": 1,
   *    "imageOverlay": ""
   *  }
   *
   * @param preset
   * @returns {{}}
   */

  getThumbStyle(preset) {
    let style = {};
    let imageData;
    let fittingType = fittingTypes.SCALE_TO_FILL;
    let alignType = alignTypes.CENTER;
    const type = this.getMediaType(preset);

    // If we have a thumbnail
    if (
      preset.thumbnail?.uri &&
      preset.thumbnail.width &&
      preset.thumbnail.height
    ) {
      imageData = _.omit(preset.thumbnail, 'title');
    } else if (type !== mediaTypes.COLOR) {
      // If we are not color and don't have a thumbnail
      imageData = preset.ref.mediaRef.posterImageRef || preset.ref.mediaRef;

      if (
        preset.ref.fittingType === fittingTypes.LEGACY_BG_FIT_AND_TILE ||
        preset.ref.fittingType === fittingTypes.TILE
      ) {
        const isSmaller = backgroundUtils.isSmallerFromContainer(
          imageData.width,
          imageData.height,
          this.state.thumbWidth,
          this.state.thumbHeight,
        );
        fittingType = isSmaller ? fittingTypes.TILE : fittingTypes.FIT_AND_TILE;
      } else {
        fittingType = fittingTypes.SCALE_TO_FILL;
      }
      alignType = preset.ref.alignType;
    }

    // If we had thumbnail data or media data, we can create a thumbnail image url
    if (imageData) {
      const thumbDisplayData = backgroundUtils.getImageDisplayData(
        fittingType,
        alignType,
        imageData.uri,
        imageData.width,
        imageData.height,
        this.state.thumbWidth,
        this.state.thumbHeight,
        90,
      );
      style = thumbDisplayData.css.container;
      style.opacity = imageData.opacity || 1;
      style.backgroundImage = `url(${url.joinURL(
        serviceTopology.staticMediaUrl,
        thumbDisplayData.uri,
      )})`;
    }
    style.width = `${this.state.thumbWidth}px`;
    style.height = `${this.state.thumbHeight}px`;
    return style;
  },

  getColorValue(
    data: BackgroundData,
  ):
    | string
    | GradientLinear
    | GradientCircle
    | GradientEllipse
    | GradientConic
    | GradientMesh {
    const editorAPI = this.getEditorAPI();
    const fill =
      data.colorLayers?.[0].fill ||
      getColorValue(editorAPI, data.color || DEFAULT_COLOR);
    return fill?.type === 'SolidColor'
      ? getColorValue(editorAPI, fill.color)
      : fill;
  },

  getThumbBackdropStyle(preset) {
    const editorAPI = this.getEditorAPI();
    let style = {};
    const data = preset.ref;
    const fittingType = fittingTypes.TILE;
    const alignType = alignTypes.CENTER;
    const color = this.getColorValue(data);
    const backgroundImageParts = [];

    if (
      preset.thumbnailBackground?.uri &&
      preset.thumbnailBackground.width &&
      preset.thumbnailBackground.height
    ) {
      const thumbDisplayData = backgroundUtils.getImageDisplayData(
        fittingType,
        alignType,
        preset.thumbnailBackground.uri,
        preset.thumbnailBackground.width,
        preset.thumbnailBackground.height,
        this.state.thumbWidth,
        this.state.thumbHeight,
        90,
      );
      style = thumbDisplayData.css.container;
      const backgroundUrl = url.isExternalUrl(thumbDisplayData.uri)
        ? thumbDisplayData.uri
        : url.joinURL(serviceTopology.staticMediaUrl, thumbDisplayData.uri);
      backgroundImageParts.push(`url(${backgroundUrl})`);
    }

    if (typeof color === 'string') {
      style.backgroundColor = new Color(color)
        .alpha(preset.ref.colorOpacity || 1)
        .toString();
    } else {
      backgroundImageParts.push(
        generateGradient(color, { resolveColor: editorAPI.theme.colors.get }),
      );
    }
    if (backgroundImageParts.length) {
      style.backgroundImage = backgroundImageParts.join();
    }
    style.width = `${this.state.thumbWidth}px`;
    style.height = `${this.state.thumbHeight}px`;
    return style;
  },

  getOverlayStyle(preset) {
    const data = preset.ref;
    const style = {};
    //style.backgroundImage = (data.imageOverlay && data.imageOverlay.uri) ? 'url(' + serviceTopology.staticMediaUrl + data.imageOverlay.uri + ')' : null;
    const color = getColorValue(this.getEditorAPI(), data.colorOverlay);
    if (color) {
      style.backgroundColor = new Color(color)
        .alpha(data.colorOverlayOpacity || 0)
        .toString();
    }
    return style;
  },

  selectPreset(preset, presetBackgrounds, presetIndex) {
    this.props.onSelectPreset(
      _.cloneDeep(preset),
      presetBackgrounds,
      presetIndex,
    );
  },

  render() {
    const { columns = 1, title, infoTooltip } = this.props;
    const sectionClassName = cx(
      'preset-list-section-list',
      `columns-${columns}`,
    );

    return (
      <div className="bg-preset-list-section section-wrapper">
        <SectionHeader title={title} tooltipText={infoTooltip} />
        <ul className={sectionClassName}>
          {this.getPresetData().map((preset, presetIndex) => (
            <li
              key={`preset${presetIndex}`}
              onClick={() => {
                this.selectPreset(preset, 'presetBackgrounds', presetIndex);
              }}
              className="preset-list-item"
            >
              <baseUI.tooltip
                value={this.getMediaPreviewComp(preset)}
                alignment="right"
                styleType={constants.UI.TOOLTIP.STYLE_TYPE.CONTENT_ONLY}
                delay="300"
                width="294px"
                noArrow={true}
              >
                <div
                  style={this.getThumbBackdropStyle(preset)}
                  className="preset-list-preset"
                >
                  <div
                    style={this.getThumbStyle(preset)}
                    className="preset-list-preset-content"
                  />
                  {this.isMediaVideo(preset) && (
                    <>
                      <div
                        style={this.getOverlayStyle(preset)}
                        key="preset-list-preset-overlay"
                        className="preset-list-preset-overlay"
                      />
                      <div
                        key="bgVideoIndicator"
                        className="preset-list-preset-video-controls"
                      >
                        <baseUI.symbol name="bgVideoIndicator" />
                        {/* <span class="video-duration">{this.getSecInMin(preset.ref.mediaRef.duration)}</span> */}
                      </div>
                    </>
                  )}
                  {preset.thumbnail?.title && (
                    <div key="preset-title" className="preset-title">
                      {translate(preset.thumbnail.title)}
                    </div>
                  )}
                </div>
              </baseUI.tooltip>
            </li>
          ))}
        </ul>
      </div>
    );
  },
});

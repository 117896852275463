import { DATA_BINDING } from '@wix/app-definition-ids';
import type { EditorAPI } from '#packages/editorAPI';
import type { ComponentRef } from '@wix/platform-editor-sdk';

interface NotifyApplicationParams {
  eventType: string;
  eventPayload: {
    origin?: string;
    collectionsGroup?: string;
    pageRef?: ComponentRef;
  };
}

export const notifyDataBindingApplication = (
  editorAPI: EditorAPI,
  params: NotifyApplicationParams,
) => {
  const { applicationId } =
    editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);

  editorAPI.dsActions.platform.notifyApplication(applicationId, params);
};

import * as stateManagement from '#packages/stateManagement';
import { WixCodeWorkspaceApiKey, WorkspaceApiKey } from '#packages/apis';
import type { StateMapperArgs } from 'types/redux';
import * as util from '#packages/util';

const { getPreviewMode } = stateManagement.preview.selectors;
const { isOpen: isPinModeOpen } = stateManagement.pinMode.selectors;
const { isChatLoaded } = stateManagement.unifiedChat.selectors;

export const mapStateToProps = ({
  state: editorState,
  editorAPI,
  host,
}: StateMapperArgs) => {
  const {
    viewTools,
    wixCodeLoaded,
    hideTools,
    devModeContext,
    editorIsInit,
    floatingBubble,
    contextMenu,
    eventCounters,
  } = editorState;
  const isDeveloperModeEnabled = editorAPI.developerMode.isEnabled();
  const isWixCodeProvisioned = editorAPI.dsRead?.wixCode.isProvisioned();
  const shouldLoadWixCode = isDeveloperModeEnabled || isWixCodeProvisioned;
  const isPinMode = isPinModeOpen(editorState);
  const previewMode = getPreviewMode(editorState);
  const isZoomMode = editorAPI.zoomMode.isInZoomMode();
  const shouldShowLiveChat = isChatLoaded(editorState);
  const shouldShowDevModePanels =
    wixCodeLoaded && isDeveloperModeEnabled && !hideTools;

  const languages = editorAPI.dsRead?.theme.fonts.getCharacterSet();
  const themeFonts = editorAPI.dsRead?.theme.fonts.getAll();
  // @ts-expect-error
  const documentType = editorAPI.dsRead?.generalInfo.getDocumentType();
  const isContextMenu = Boolean(contextMenu);
  const editorMousePosition = contextMenu?.editorMousePosition;
  const isLightboxMode = editorAPI.dsRead?.pages.popupPages.isPopupOpened();
  const WorkspaceComponents = host
    .getAPI(WorkspaceApiKey)
    .getWorkspaceComponents();
  const wixCodeLoaderComponent = host
    .getAPI(WixCodeWorkspaceApiKey)
    .getWixCodeLoaderComponent();
  const loadWixCodeWithRepluggableInClassic =
    !util.appStudioUtils.isAppStudio();
  const isPreviewReady = editorAPI.isPreviewReady();
  return {
    viewTools,
    isZoomMode,
    wixCodeLoaded,
    hideTools,
    devModeContext,
    editorIsInit,
    floatingBubble,
    isContextMenu,
    editorMousePosition,
    focusEventCounter: eventCounters.focus,
    shouldLoadWixCode,
    isPinMode,
    isLightboxMode,
    previewMode,
    shouldShowLiveChat,
    shouldShowDevModePanels,
    languages,
    themeFonts,
    documentType,
    WorkspaceComponents,
    loadWixCodeWithRepluggableInClassic,
    wixCodeLoaderComponent,
    isPreviewReady,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => ({
  editorAPI,
  state: getState(),
});

export const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const { editorAPI } = dispatch(getEditorAPI);

  return {
    onMouseMove: (e: AnyFixMe) => {
      editorAPI.mouseActions.onEditorViewMouseMove(e);
    },
    onMouseUp: (e: AnyFixMe) => editorAPI.mouseActions.onEditorViewMouseUp(e),
    resetScrollListeners: () => editorAPI.scroll.resetScrollListeners(),
  };
};

import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';

import { getRecursiveItem } from './rightClickDebug.utils';

import type {
  RightClickDebugDispatchProps,
  RightClickDebugOwnProps,
  RightClickDebugStateProps,
} from './rightClickDebug';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export const mapStateToProps: MapStateToProps<RightClickDebugStateProps> = ({
  state,
  editorAPI,
}) => {
  const selectedComponents =
    stateManagement.selection.selectors.getSelectedCompsRefs(state);
  const isAppController =
    editorAPI.components.getType(selectedComponents[0]) ===
    'platform.components.AppController';

  const isSingleController = selectedComponents.length === 1 && isAppController;
  const isSingleNonController =
    selectedComponents.length === 1 && !isAppController;

  const isRefComponentSelected =
    stateManagement.components.selectors.isRefComponent(
      selectedComponents[0],
      editorAPI.dsRead,
    );

  const isReferredComponentSelected =
    stateManagement.components.selectors.isReferredComponent(
      selectedComponents[0],
    );

  const isAppWidgetSelected =
    editorAPI.components.getType(selectedComponents) ===
    'platform.components.AppWidget';

  return {
    selectedComponents,
    isSingleController,
    isSingleNonController,
    isRefComponentSelected,
    isReferredComponentSelected,
    isAppWidgetSelected,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const getSelectedComponentThunk =
  (): ThunkAction<CompRef> => (dispatch, getState) => {
    const state = getState();
    return stateManagement.selection.selectors.getSelectedCompsRefs(state)?.[0];
  };

export const mapDispatchToProps: MapDispatchToProps<
  RightClickDebugDispatchProps,
  RightClickDebugOwnProps
> = (dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const getSelectedComponent = (): CompRef =>
    dispatch(getSelectedComponentThunk);

  const restartPlatform = () => {
    editorAPI.closeRightClickMenu();
    // @ts-expect-error
    editorAPI.documentServices.platform.init();
  };

  const openComponentInspectorFinder = () => {
    editorAPI.panelManager.openComponentPanel(
      'debugTools.componentInspector.finderPanel',
      {
        comp: getSelectedComponent(),
      },
    );
    editorAPI.closeRightClickMenu();
  };

  const openAddController = () => {
    editorAPI.panelManager.openPanel(
      'debugTools.platform.addControllerPanel',
      {},
    );
    editorAPI.closeRightClickMenu();
  };

  const exportDebugInfo = (comp: CompRef) => {
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const compStructure = editorAPI.components.serialize(
      comp,
      null,
      false,
      true,
    );
    // @ts-expect-error
    window.selectedComp = _.cloneDeep(compStructure);
    console.info(`Exported [${comp.id}] to window.selectedComp`);
    editorAPI.closeRightClickMenu();
  };

  const openControllerInspector = () => {
    console.info('Opening controller panel');
    editorAPI.panelManager.openComponentPanel(
      'debugTools.platform.controllerInspectorPanel',
      {
        comp: getSelectedComponent(),
      },
    );
    editorAPI.closeRightClickMenu();
  };

  const openConnectionsInspector = () => {
    console.info('Opening connections panel');
    editorAPI.panelManager.openComponentPanel(
      'debugTools.platform.connectionsInspectorPanel',
      {
        comp: getSelectedComponent(),
      },
    );
    editorAPI.closeRightClickMenu();
  };

  const exportWidget = () => {
    editorAPI.closeRightClickMenu();
    editorAPI.store.dispatch(
      stateManagement.applicationStudio.actions.exportWidget(),
    );
  };

  const importWidget = () => {
    editorAPI.closeRightClickMenu();
    editorAPI.store.dispatch(
      stateManagement.applicationStudio.actions.importWidget(),
    );
  };

  const selectControllerConnectedComponents = () => {
    const controller = getSelectedComponent();
    import('@/debugTools').then((debugTools) => {
      debugTools.platform.utils.selectControllerConnectedComponents(
        editorAPI,
        controller,
      );
    });
    editorAPI.closeRightClickMenu();
  };

  const getComponentDebugItems = (compRef: CompRef) => {
    // used only for debug
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const compStructure = editorAPI.components.serialize(compRef);

    return getRecursiveItem(compRef.id, compStructure, ['structure']);
  };

  const getCompDisplayLabel = (compRef: CompRef) =>
    `${editorAPI.components.getDisplayName(compRef)} - ${compRef.id}`;

  return {
    editorAPI,
    restartPlatform,
    openComponentInspectorFinder,
    openAddController,
    exportDebugInfo,
    openControllerInspector,
    openConnectionsInspector,
    exportWidget,
    importWidget,
    selectControllerConnectedComponents,
    getComponentDebugItems,
    getCompDisplayLabel,
  };
};

import _ from 'lodash';
import {
  EditorAPIKey,
  BiApiKey,
  ConcurrentUsersApiKey,
  SiteApiKey,
} from '#packages/apis';
import experiment from 'experiment';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import {
  fedopsLogger,
  editorWixRecorder,
  workspace as workspaceUtils,
} from '#packages/util';

import type { Shell } from '#packages/apilib';
import type { SitePremiumState } from '#packages/stateManagement';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { initDsSaveTimeoutErrorReporting } from './utils/initDsSaveTimeoutErrorReporting';
import type { PublishOptions } from './managers/publishManagerApi';

function apply(f: AnyFixMe) {
  return f();
}
const domainSelectors = stateManagement.domain.selectors;
const savePublishActions = stateManagement.savePublish.actions;
const publishingStatusActions = stateManagement.publishingStatus.actions;
const testSiteStatusActions = stateManagement.testSiteStatus.actions;
const savePublishSelectors = stateManagement.savePublish.selectors;
const appStudioSelectors = stateManagement.applicationStudio.selectors;
const { getIsSessionInitializedWithWizard } =
  stateManagement.siteCreation.selectors;

export function createSavePublishApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const siteApi = shell.getAPI(SiteApiKey);
  const concurrentUsersApi = shell.getAPI(ConcurrentUsersApiKey);
  const biApi = shell.getAPI(BiApiKey);
  const { store } = editorAPI;

  function setSaveProgress(
    isSaving: boolean,
    isManualSave: boolean = false,
  ): void {
    store.dispatch(
      savePublishActions.setIsSaveInProgress(isSaving, isManualSave),
    );
  }

  function setPublishProgress(isPublishing: boolean): void {
    store.dispatch(savePublishActions.setIsPublishInProgress(isPublishing));
  }
  function setTestSiteProgress(isTestSiteInProgress: boolean): void {
    store.dispatch(
      savePublishActions.setIsTestSiteInProgress(isTestSiteInProgress),
    );
  }

  function setPublishInCurrentSession(
    isPublishedInCurrentSession: boolean,
  ): void {
    store.dispatch(
      savePublishActions.setPublishInCurrentSession(
        isPublishedInCurrentSession,
      ),
    );
  }

  function setWaitForSaveDoneCallback(
    cb: (saveSucces: boolean, e?: any) => void,
  ): void {
    store.dispatch(savePublishActions.setWaitForSaveDoneCallback(cb));
  }

  function clearSaveCallbacksOnSaveComplete(): void {
    store.dispatch(savePublishActions.clearSaveCallbacksOnSaveComplete());
  }

  function isWaitForSaveDoneCallbackEmpty(): boolean {
    return savePublishSelectors.getIsWaitingForSaveDoneEmpty(store.getState());
  }

  function setDiffSaveProgress(isSaving: boolean, _?: AnyFixMe): void {
    store.dispatch(savePublishActions.setIsDiffSaveInProgress(isSaving));
  }

  function setManualSave(isManualSave: boolean): void {
    store.dispatch(savePublishActions.setIsManualSave(isManualSave));
  }

  function setIsLocked(isLocked: boolean): void {
    store.dispatch(savePublishActions.setIsLocked(isLocked));
  }

  function isBuildInProgress(): boolean {
    return savePublishSelectors.getIsBuildInProgress(store.getState());
  }

  function isPublishInProgress(): boolean {
    return savePublishSelectors.getIsPublishInProgress(store.getState());
  }

  function isTestSiteInProgress(): boolean {
    return savePublishSelectors.getIsTestSiteInProgress(store.getState());
  }

  function isSaveInProgress(): boolean {
    return savePublishSelectors.getIsSaveInProgress(store.getState());
  }

  function isDiffSaveInProgress(): boolean {
    return savePublishSelectors.getIsDiffSaveInProgress(store.getState());
  }

  function canSaveOrPublish(_?: AnyFixMe): boolean {
    return (
      !isPublishInProgress() &&
      !isSaveInProgress() &&
      !isBuildInProgress() &&
      !isSavePublishLocked()
    );
  }

  function subscribeToSavePublishUnlocked(cb: () => void): void {
    let prevCanSaveOrPublish = canSaveOrPublish();

    editorAPI.store.subscribe(() => {
      const currentCanSaveOrPublish = canSaveOrPublish(editorAPI);

      if (currentCanSaveOrPublish && !prevCanSaveOrPublish) {
        cb();
      }

      prevCanSaveOrPublish = currentCanSaveOrPublish;
    });
  }

  subscribeToSavePublishUnlocked(() => {
    editorAPI.editorEventRegistry.dispatch(
      editorAPI.editorEventRegistry.constants.events.SAVE_PUBLISH_UNLOCKED,
    );
  });

  function canPackage(): boolean {
    return (
      canSaveOrPublish() &&
      !appStudioSelectors.isPackageInProgress(editorAPI.store.getState())
    );
  }

  function isSaveReminderPanelEnabled(): boolean {
    const maybeSaveReminderPanel =
      savePublishSelectors.getOverridingSaveReminderPanel(store.getState());
    return !((maybeSaveReminderPanel as AnyFixMe)?.disabled ?? false);
  }

  function canDiffSave(): boolean {
    return (
      !isDiffSaveInProgress() &&
      !isSaveInProgress() &&
      !isPublishInProgress() &&
      !isSavePublishLocked()
    );
  }

  function lockSavePublish(): void {
    setIsLocked(true);
  }

  function unlockSavePublish(): void {
    setIsLocked(false);
  }

  function isSavePublishLocked(): boolean {
    return savePublishSelectors.getIsLocked(store.getState());
  }

  function getSiteState(): SitePremiumState & {
    isFirstSave: boolean;
    isSitePublished: boolean;
  } {
    const state = store.getState();
    const isDomainConnected = domainSelectors.isDomainConnected(
      editorAPI.dsRead,
    );

    return {
      isFirstSave: editorAPI.dsRead.generalInfo.isFirstSave(),
      isSitePublished: editorAPI.dsRead.generalInfo.isSitePublished(),
      isDomainConnected,
      domain: isDomainConnected
        ? editorAPI.dsRead.generalInfo.getPublicUrl()
        : getFreeDomainURL(),
      isSitePremium: siteApi.isPremium(),
      ...state.sitePremiumState,
    };
  }

  function setSitePremiumState(sitePremiumState: SitePremiumState) {
    editorAPI.updateState({
      sitePremiumState,
    });
  }

  function getFreeDomainURL(): string {
    return (
      domainSelectors.getFreeDomainPrefix(store.getState(), editorAPI.dsRead) +
      editorAPI.siteName.get()
    );
  }

  function unhighlightMenuIfNeeded() {
    const state = editorAPI.store.getState();
    if (
      !getIsSessionInitializedWithWizard(state) &&
      !editorAPI.platform.applications.isSilentInstallRunning()
    ) {
      editorAPI.store.dispatch(
        stateManagement.leftBar.actions.unhighlightMenu(),
      );
    }
  }

  const firstSaveCallbacks = [onFirstSave];
  const siteDraftCallbacks = [onDraftSave];

  function onFirstSave(): void {
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.loadSitePreferences(
        editorAPI.dsRead.generalInfo.getSiteId(),
        true,
      ),
    );

    if (workspaceUtils.isNewWorkspaceEnabled()) {
      unhighlightMenuIfNeeded();
    } else {
      editorAPI.store.dispatch(stateManagement.leftBar.actions.collapseMenu());
    }
  }

  function onDraftSave(): void {
    if (workspaceUtils.isNewWorkspaceEnabled()) {
      unhighlightMenuIfNeeded();
    } else {
      editorAPI.store.dispatch(stateManagement.leftBar.actions.collapseMenu());
    }
  }

  function reportSaveErrorBI(
    errorInfo: {
      [index: string]: { errorType: string; errorCode: number };
    },
    {
      isFirstSave,
      isDraft,
      origin,
      sourceOfStart,
    }: {
      isFirstSave: boolean;
      isDraft: boolean;
      origin: string;
      sourceOfStart: string;
    },
  ): void {
    let errorInfoString: string;

    try {
      errorInfoString = JSON.stringify(errorInfo);
    } catch (error: MaybeError) {
      errorInfoString = `JSON.stringify failed: ${error.message}`;
    }

    const biParams = {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      failedServices: _.keys(errorInfo).join(','),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      errorTypes: _.compact(_.map(errorInfo, 'errorType')).join(','),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      errorCodes: _.compact(_.map(errorInfo, 'errorCode')).join(','),
      p1: errorInfoString,
      p2: JSON.stringify({
        isFirstSave,
        isDraft,
        origin,
        sourceOfStart,
      }),
      p3: `origin: ${origin}`,
      p4: `sourceOfStart: ${sourceOfStart}`,
    };

    if (isFirstSave) {
      editorAPI.dsActions.bi.error(coreBi.errors.FIRST_SAVE_ERROR, biParams);
    } else {
      editorAPI.dsActions.bi.error(coreBi.errors.SAVE_ERROR, biParams);
    }

    ErrorReporter.captureException(new Error('dsActions.save error'), {
      extra: {
        errorInfo,
        isFirstSave,
        isDraft,
        origin,
        sourceOfStart,
      },
    });
  }

  type SaveType = 'first' | 'full' | 'partial';

  function getSaveType(isFirstSave: boolean): SaveType {
    if (isFirstSave) {
      return 'first';
    }

    return 'partial';
  }

  const { PROGRESS_STATUS } = constants;

  function getReorderDomStatus() {
    return (editorAPI.pages.data.get('masterPage') as AnyFixMe).autoDomReorder;
  }

  async function save(
    _isFullSave: boolean,
    origin: string,
    options: { origin?: string; sourceOfStart?: string },
  ) {
    await editorAPI.zoomMode.exitZoomModeAndClearTransformationsIfNeeded();

    return new Promise<void>(function (resolve, reject) {
      const startTime = _.now();
      editorAPI.updateState({ savingStatus: PROGRESS_STATUS.IN_PROGRESS });
      const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
      const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();

      const saveType = getSaveType(isFirstSave);

      const isReorderActivated = getReorderDomStatus();
      _.set(
        options,
        'shouldReorderDOM',
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
        _.isUndefined(isReorderActivated) ? true : isReorderActivated,
      );
      editorWixRecorder.addLabel('save click');
      biApi.event(coreBi.events.save.saveProcess.SAVE_CLICK, { origin });

      const save_data = {
        save_type: saveType,
        origin,
        duration: undefined as number,
        revisionId: editorAPI.dsRead.generalInfo.getRevision(),
      };

      options.origin = options.origin || origin || options.sourceOfStart;

      function onSaveSuccess() {
        save_data.duration = _.now() - startTime;
        biApi.event(
          coreBi.events.save.saveProcess.SUCCESS_SAVE_PROCESS,
          save_data,
        );

        fedopsLogger.interactionEnded('save_execute_ds', {
          paramsOverrides: {
            sourceOfStart: options.sourceOfStart,
          },
          customParams: {
            origin: options.origin,
            isFirstSave,
            isDraft: isDraftMode,
          },
        });

        if (isFirstSave) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
          _.forEach(firstSaveCallbacks, apply);
        }

        if (isDraftMode) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
          _.forEach(siteDraftCallbacks, apply);
        }

        editorAPI.isSavedInCurrentSession = true;
        resolve();

        concurrentUsersApi.checkForConcurrentUsers();
        editorAPI.updateState({ savingStatus: PROGRESS_STATUS.DONE_SUCCESS });
        store.dispatch(stateManagement.leavePopup.actions.setLeavePopup(false));
        window.setTimeout(function () {
          editorAPI.updateState({ savingStatus: PROGRESS_STATUS.IDLE });
        }, constants.SAVE_PUBLISH.DURATION_TO_SHOW_RESULT);

        editorAPI.store.dispatch(
          stateManagement.schoolMode.actions.notifySiteWasSave(),
        );
      }

      function onSaveFail(error: AnyFixMe) {
        reportSaveErrorBI(error, {
          isFirstSave,
          isDraft: isDraftMode,
          origin: options.origin,
          sourceOfStart: options.sourceOfStart,
        });

        reject(error);

        editorAPI.updateState({ savingStatus: PROGRESS_STATUS.DONE_FAILED });
        store.dispatch(stateManagement.leavePopup.actions.setLeavePopup(true));
        window.setTimeout(function () {
          editorAPI.updateState({ savingStatus: PROGRESS_STATUS.IDLE });
        }, constants.SAVE_PUBLISH.DURATION_TO_SHOW_RESULT);
      }

      biApi.event(coreBi.events.save.saveProcess.START_SAVE_PROCESS, save_data);

      fedopsLogger.interactionStarted('save_execute_ds', {
        paramsOverrides: {
          sourceOfStart: options.sourceOfStart,
        },
        customParams: {
          origin: options.origin,
          isFirstSave,
          isDraft: isDraftMode,
        },
      });

      const dsSavePromise = new Promise<void>((resolve, reject) => {
        // TODO: do we still need `_.defer` here?
        // - https://github.com/wix-private/santa-editor/commit/9feff356a5b5383b61701e9402e11feb139cba4a
        // - https://github.com/wix-private/santa-editor/commit/5a35a0e390aa049c20718c020063225aecc0bdb1
        // - https://jira.wixpress.com/browse/SE-14945
        _.defer(() => {
          editorAPI.dsActions.save(resolve, reject, false, options);
        });
      });

      dsSavePromise.then(onSaveSuccess, onSaveFail);

      initDsSaveTimeoutErrorReporting(
        dsSavePromise,
        editorAPI.dsActions.bi,
        options,
      );
    });
  }

  async function saveAsTemplate(
    onSuccess?: () => void,
    onError?: (error: unknown) => void,
  ) {
    await editorAPI.zoomMode.exitZoomModeAndClearTransformationsIfNeeded();

    function onFail(error: unknown) {
      if (onError) {
        onError(error);
      }
      console.info('Failed saving as template ', error);
    }

    function onSaveSuccess() {
      if (onSuccess) {
        onSuccess();
      }
    }

    editorAPI.dsActions.saveAsTemplate(onSaveSuccess, onFail);
  }

  async function publish(publishOptions: PublishOptions = {}): Promise<{}> {
    const { publish_type, dsPublishOptions, isAutoPublish } = publishOptions;
    await editorAPI.zoomMode.exitZoomModeAndClearTransformationsIfNeeded();

    return new Promise(function (resolve, reject) {
      updatePublishStartStatus();

      const publish_data = {
        publish_type:
          publish_type ||
          (editorAPI.dsRead.generalInfo.isSitePublished()
            ? 'publish'
            : 'first_publish'),
      };
      biApi.event(coreBi.events.publish.START_PUBLISH, publish_data);
      const options = dsPublishOptions || {};
      const isReorderActivated = getReorderDomStatus();
      _.set(
        options,
        'shouldReorderDOM',
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
        _.isUndefined(isReorderActivated) ? true : isReorderActivated,
      );

      function _saveNumberOfManualPublish() {
        if (isAutoPublish) return;

        const NUMBER_OF_MANUAL_PUBLISH = 'numberOfManualPublish';
        const numberOfPublish =
          stateManagement.userPreferences.selectors.getSiteUserPreferences(
            NUMBER_OF_MANUAL_PUBLISH,
          )(editorAPI.store.getState());

        editorAPI.store.dispatch(
          stateManagement.userPreferences.actions.setSitePreferences('site')(
            NUMBER_OF_MANUAL_PUBLISH,
            _.isNumber(numberOfPublish) ? numberOfPublish + 1 : 1,
          ),
        );
      }

      function onPublishSuccess() {
        biApi.event(coreBi.events.publish.SUCCESS_PUBLISH, publish_data);
        editorAPI.sitePublishedCallbacks.forEach(function (callback) {
          callback();
        });

        resolve({});
        updatePublishEndStatus('DONE_SUCCESS');
        _saveNumberOfManualPublish();
      }

      function onPublishFail(error: AnyFixMe) {
        reject(error);
        updatePublishEndStatus('DONE_FAILED');
        biApi.reportBI({ evid: 10 }, error);
      }
      editorAPI.dsActions.publish(onPublishSuccess, onPublishFail, options);
    });
  }

  async function publishRC(overrideRevisionInfo?: {
    revision: string;
    branchId?: string;
  }): Promise<void> {
    const isTestSiteExperimentOpen =
      experiment.isOpen('specs.wixCode.TestSiteEntryPoint') &&
      editorAPI.developerMode.isEnabled();
    await editorAPI.zoomMode.exitZoomModeAndClearTransformationsIfNeeded();

    return new Promise(function (resolve, reject) {
      if (isTestSiteExperimentOpen) {
        updateTestSiteStartStatus();
      } else {
        updatePublishStartStatus();
      }
      function onPublishSuccess() {
        resolve();
        if (isTestSiteExperimentOpen) {
          updateTestSiteEndStatus('DONE_SUCCESS');
        } else {
          updatePublishEndStatus('DONE_SUCCESS');
        }
      }

      function onPublishFail(error: unknown) {
        reject(error);
        if (isTestSiteExperimentOpen) {
          updateTestSiteEndStatus('DONE_FAILED');
        } else {
          updatePublishEndStatus('DONE_FAILED');
        }
      }

      const publishTestSiteOptions = overrideRevisionInfo
        ? { publishTestSite: true, overrideRevisionInfo }
        : { publishTestSite: true };

      editorAPI.store.dispatch(
        stateManagement.savePublish.actions.setWasLatestRCPublished(true),
      );

      editorAPI.dsActions.publish(
        onPublishSuccess,
        onPublishFail,
        publishTestSiteOptions,
      );
    });
  }

  function build(
    versionType: string,
    params: {
      isAsyncBuild: boolean;
      releaseNotes?: string;
      onBuildSuccessCallback: () => void;
      onBuildErrorCallback?: (error: unknown) => void;
      publishRc?: boolean;
      packageImportName?: string;
    } = {
      isAsyncBuild: false,
      onBuildSuccessCallback: () => {},
    },
  ): Promise<void> {
    const { dispatch, getState } = editorAPI.store;
    const state = getState();
    return new Promise<void>((resolve, reject) => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.APP_STUDIO_BUILD,
      );
      dispatch(savePublishActions.setIsBuildInProgress(true));
      updatePublishStartStatus();
      const appName = appStudioSelectors.getAppName(state);
      const blocksVersion = appStudioSelectors.getBlocksVersion(state);
      const appDefId = appStudioSelectors.getAppDefId(state);

      editorAPI.dsActions.appStudio.buildWithOptions({
        onSuccess: resolve,
        onError: reject,
        versionType,
        appName,
        blocksVersion,
        appDefId,
        isAsyncBuild: params.isAsyncBuild,
        releaseNotes: params.releaseNotes,
        publishRc: params.publishRc,
        packageImportName: params.packageImportName,
      });
    })
      .then(() => {
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.APP_STUDIO_BUILD,
        );
        dispatch(savePublishActions.setIsBuildInProgress(false));
        updatePublishEndStatus('DONE_SUCCESS');
        params.onBuildSuccessCallback();
      })
      .catch((e) => {
        updatePublishEndStatus('DONE_FAILED');
        dispatch(savePublishActions.setIsBuildInProgress(false));
        throw e;
      });
  }

  function updatePublishStartStatus() {
    store.dispatch(
      publishingStatusActions.setPublishingStatus(PROGRESS_STATUS.IN_PROGRESS),
    );
  }

  function updatePublishEndStatus(doneStatus: keyof typeof PROGRESS_STATUS) {
    store.dispatch(
      publishingStatusActions.setPublishingStatus(PROGRESS_STATUS[doneStatus]),
    );

    window.setTimeout(function () {
      store.dispatch(
        publishingStatusActions.setPublishingStatus(PROGRESS_STATUS.IDLE),
      );
    }, constants.SAVE_PUBLISH.DURATION_TO_SHOW_RESULT);
  }

  function updateTestSiteStartStatus() {
    store.dispatch(
      testSiteStatusActions.setTestSiteStatus(PROGRESS_STATUS.IN_PROGRESS),
    );
  }

  function updateTestSiteEndStatus(doneStatus: keyof typeof PROGRESS_STATUS) {
    store.dispatch(
      testSiteStatusActions.setTestSiteStatus(PROGRESS_STATUS[doneStatus]),
    );

    window.setTimeout(function () {
      store.dispatch(
        publishingStatusActions.setPublishingStatus(PROGRESS_STATUS.IDLE),
      );
    }, constants.SAVE_PUBLISH.DURATION_TO_SHOW_RESULT);
  }

  function registerFirstSaveCallback(callback: () => void) {
    firstSaveCallbacks.push(callback);
  }

  function canUserPublish(): boolean {
    const userCanPublish =
      editorAPI.dsRead && editorAPI.dsRead.canUserPublish();
    return _.isBoolean(userCanPublish) ? userCanPublish : true;
  }

  return {
    build,
    save,
    saveAsTemplate,
    publish,
    publishRC,
    registerFirstSaveCallback,

    getSiteState,
    setSitePremiumState,

    lockSavePublish,
    unlockSavePublish,
    isSavePublishLocked,

    canDiffSave,
    canUserPublish,
    canSaveOrPublish,
    canPackage,
    setSaveProgress,
    setPublishProgress,
    setTestSiteProgress,
    setPublishInCurrentSession,
    setDiffSaveProgress,
    setManualSave,
    isSaveInProgress,
    isPublishInProgress,
    isTestSiteInProgress,
    isSaveReminderPanelEnabled,
    setWaitForSaveDoneCallback,
    clearSaveCallbacksOnSaveComplete,
    isWaitForSaveDoneCallbackEmpty,
    updatePublishStartStatus,
    updatePublishEndStatus,
  };
}

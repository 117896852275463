import React from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import {
  mapStateToProps,
  mapDispatchToProps,
  type TestSitePanelDispatchProps,
  type TestSitePanelStateProps,
} from './testSitePanelMappers';
import TextAndLinkPanel from '../textAndLinkPanel';
import { deriveTestSitePanelDataFromProps } from './testSitePanelStructure';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export interface TestSitePanelProps
  extends TestSitePanelDispatchProps,
    TestSitePanelStateProps {
  sitePublicUrl: string;
}

const TestSitePanel = (props: TestSitePanelProps) => (
  <TextAndLinkPanel panelData={deriveTestSitePanelDataFromProps(props)} />
);

const ConnectedTestSitePanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
)(TestSitePanel);

export default ConnectedTestSitePanel;

import React from 'react';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  PromotionalList,
  Text,
} from '@wix/wix-base-ui';

import * as util from '#packages/util';
import * as baseUI from '#packages/baseUI';
import { frames } from '#packages/panels';
import { translate } from '#packages/i18n';

import panelConstants from './constants';

const { FocusPanelFrame, PromotionalPanelFrame } = frames;
const { automationIds, dataHooks } = panelConstants;

const premiumPromotionalListItems = [
  translate('custom404_create_popup_bullet1'),
  translate('custom404_create_popup_bullet2'),
  translate('custom404_create_popup_bullet3'),
];

const freemiumPromotionalListItems = [
  translate('custom404_upgrade_popup_bullet1'),
  translate('custom404_upgrade_popup_bullet2'),
  translate('custom404_upgrade_popup_bullet3'),
  translate('custom404_upgrade_popup_bullet4'),
];

interface CustomErrorPagePanelContent {
  introBold?: boolean;
  introCentered?: boolean;
  introText: string;
  listItems?: string[];
}

const CustomErrorPagePanelContent: React.FC<CustomErrorPagePanelContent> = (
  props,
) => (
  <div className="custom-error-page-panel__content">
    <div
      className={util.cx('custom-error-page-panel__title', {
        'custom-error-page-panel__title_centered': props.introCentered,
      })}
    >
      <Text
        shouldTranslate={false}
        enableEllipsis={false}
        skin="secondary"
        weight={props.introBold ? 'bold' : 'thin'}
      >
        {props.introText}
      </Text>
    </div>
    {props.listItems ? (
      <Composites.PromotionalList className="custom-error-page-panel__promotional-list">
        <PromotionalList
          shouldTranslate={false}
          items={props.listItems}
          className="custom-error-page-panel__promotional-list"
        />
      </Composites.PromotionalList>
    ) : null}
  </div>
);

interface CustomErrorPagePanelFrameProps {
  panelName: string;
  onClose: () => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
}

export const NewWorkspacePremiumCustomErrorPagePanelFrame: React.FC<
  CustomErrorPagePanelFrameProps
> = (props) => (
  <PromotionalPanelFrame
    panelName={props.panelName}
    title={translate('custom404_create_popup_title')}
    illustration={
      <baseUI.symbol
        name="custom404_Modal"
        className="custom-error-page-panel__symbol"
      />
    }
    onOuterClick={props.onClose}
    onEscKeyPress={props.onClose}
    onCloseButtonClick={props.onClose}
    primaryButtonText={translate('custom404_create_popup_create_button')}
    secondaryButtonText={translate('custom404_create_popup_help_link')}
    onPrimaryButtonClick={props.onPrimaryButtonClick}
    onSecondaryButtonClick={props.onSecondaryButtonClick}
  >
    <CustomErrorPagePanelContent
      introBold
      introText={translate('custom404_create_popup_subtitle')}
      listItems={premiumPromotionalListItems}
    />
  </PromotionalPanelFrame>
);

export const PremiumCustomErrorPagePanelFrame: React.FC<
  CustomErrorPagePanelFrameProps
> = (props) => (
  <div>
    <FocusPanelFrame
      panelName={props.panelName}
      shouldHideHeader={true}
      className="custom-error-page-panel custom-error-page-panel custom-error-page-panel"
    >
      <Composites.BigPanelHeaderWithIllustration className="custom-error-page-panel__header">
        <PanelHeaderCenteredText
          title="custom404_create_popup_title"
          onClose={props.onClose}
          // @ts-expect-error
          shouldTranslate={true}
          className="custom-error-page-panel__header-text"
        />
        <PanelHeaderIllustration
          // @ts-expect-error
          className="custom-error-page-panel__header-illustration"
        >
          <baseUI.symbol
            name="custom404_Modal"
            className="custom-error-page-panel__symbol"
          />
        </PanelHeaderIllustration>
      </Composites.BigPanelHeaderWithIllustration>
      <CustomErrorPagePanelContent
        introBold
        introText={translate('custom404_create_popup_subtitle')}
        listItems={premiumPromotionalListItems}
      />
      <div className="custom-error-page-panel__footer">
        <Button
          onClick={props.onPrimaryButtonClick}
          dataHook={dataHooks.PRIMARY_BUTTON}
          automationId={automationIds.SET_PAGE_BUTTON}
          className="btn-big custom-error-page-panel__btn"
        >
          {translate('custom404_create_popup_create_button')}
        </Button>
        <Button
          dataHook={dataHooks.SECONDARY_BUTTON}
          automationId={automationIds.HELP_LINK}
          onClick={props.onSecondaryButtonClick}
          className="btn-text link custom-error-page-panel__link"
        >
          {translate('custom404_create_popup_help_link')}
        </Button>
      </div>
    </FocusPanelFrame>
  </div>
);

export const NewWorkspaceFreemiumCustomErrorPagePanelFrame: React.FC<
  CustomErrorPagePanelFrameProps
> = (props) => (
  <PromotionalPanelFrame
    panelName={props.panelName}
    className="new-workspace-custom-error-page-panel_freemium"
    illustration={
      <baseUI.symbol
        name="custom404_Upgrade_NewWorkspace"
        className="custom-error-page-panel__symbol"
      />
    }
    theme="premium"
    title={translate('custom404_upgrade_popup_customize_title')}
    onOuterClick={props.onClose}
    onEscKeyPress={props.onClose}
    onCloseButtonClick={props.onClose}
    primaryButtonText={translate('custom404_upgrade_popup_upgrade_button')}
    secondaryButtonText={translate('custom404_upgrade_popup_preview_link')}
    onPrimaryButtonClick={props.onPrimaryButtonClick}
    onSecondaryButtonClick={props.onSecondaryButtonClick}
  >
    <CustomErrorPagePanelContent
      introCentered
      introText={translate('custom404_upgrade_popup_customize_text')}
    />
  </PromotionalPanelFrame>
);

export const FreemiumCustomErrorPagePanelFrame: React.FC<
  CustomErrorPagePanelFrameProps
> = (props) => (
  <div>
    <FocusPanelFrame
      panelName={props.panelName}
      shouldHideHeader={true}
      className="custom-error-page-panel custom-error-page-panel custom-error-page-panel custom-error-page-panel_freemium"
    >
      <Composites.BigPanelHeaderWithIllustration className="custom-error-page-panel__header">
        <PanelHeaderCenteredText
          title="custom404_upgrade_popup_title"
          onClose={props.onClose}
          // @ts-expect-error
          shouldTranslate={true}
          className="custom-error-page-panel__header-text"
        />
        <PanelHeaderIllustration
          // @ts-expect-error
          className="custom-error-page-panel__header-illustration"
        >
          <baseUI.symbol
            name="custom404_Upgrade"
            className="custom-error-page-panel__symbol"
          />
        </PanelHeaderIllustration>
      </Composites.BigPanelHeaderWithIllustration>
      <CustomErrorPagePanelContent
        introText={translate('custom404_upgrade_popup_text_intro')}
        listItems={freemiumPromotionalListItems}
      />
      <div className="custom-error-page-panel__footer">
        <Button
          onClick={props.onPrimaryButtonClick}
          dataHook={dataHooks.PRIMARY_BUTTON}
          automationId={automationIds.UPGRADE_PLAN_BUTTON}
          className="btn-big custom-error-page-panel__btn"
        >
          {translate('custom404_upgrade_popup_upgrade_button')}
        </Button>
        <Button
          dataHook={dataHooks.SECONDARY_BUTTON}
          automationId={automationIds.PREVIEW_LINK}
          onClick={props.onSecondaryButtonClick}
          className="btn-text custom-error-page-panel__link"
        >
          {translate('custom404_upgrade_popup_preview_link')}
        </Button>
      </div>
    </FocusPanelFrame>
  </div>
);

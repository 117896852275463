import type { StateMapperArgs } from 'types/redux';

import _ from 'lodash';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import * as newBlogBi from '../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../utils/blogUtils';
import React from 'react';
import BlogPromoSlide from '../blogPromoSlide/blogPromoSlide';
import * as symbols from '@wix/santa-editor-symbols';
import type { SendBiFunction } from 'types/bi';
import type { MapDispatchToProps } from 'types/redux';

interface NewBlogMagicMigrationSlideOwnProps {}

interface NewBlogMagicMigrationSlideDispatchProps {
  sendBi: SendBiFunction;
}

interface NewBlogMagicMigrationSlideProps
  extends NewBlogMagicMigrationSlideDispatchProps {
  displayName: string;
  helpArticleId: string;
  panelName: string;
  title: string;
  subTitle: string;
  learnMoreLabel: string;
  siteHasNewBlog: boolean;
  startMagicMigrationProp: FunctionFixMe;
  openParentPanel: FunctionFixMe;
  pros: string[];
  buttons: { key: string; label: string }[];
}

class NewBlogMagicMigrationSlide extends React.Component<NewBlogMagicMigrationSlideProps> {
  static displayName = 'NewBlogMagicMigrationSlide';

  componentDidMount() {
    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_SHOWN, {
      modal_name: 'Migration entry panel',
      new_blog_valid: this.props.siteHasNewBlog,
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  }

  onButtonClick = (action: string) => {
    this.props.startMagicMigrationProp(this.props.sendBi, {
      action,
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  onCloseClick = () => {
    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_ACTION, {
      modal_name: 'Migration entry panel',
      action: 'close',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  onLearnMoreClick = () => {
    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_ACTION, {
      modal_name: 'Migration entry panel',
      action: 'Learn more',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  render() {
    return (
      <div className="magic-migration-slide">
        <BlogPromoSlide
          onCloseClick={this.onCloseClick}
          onButtonClick={this.onButtonClick}
          onLearnMoreClick={this.onLearnMoreClick}
          closeButtonClass="dark"
          illustration={
            <div className="blog-promo-illustration-container">
              <symbols.symbol name="illustrationOldBlog" />
            </div>
          }
          {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  const startMagicMigrationProp = blogUtils.siteHasNewBlog(editorAPI)
    ? _.partial(blogUtils.showMigrationPopup, editorAPI, {
        skipInfo: true,
        origin: 'newBlogMagicMigrationSlide',
      })
    : _.partial(blogUtils.startMagicMigration, editorAPI);
  const promoSlideTextContent = blogUtils.siteHasNewBlog(editorAPI)
    ? {
        title: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Title',
        subTitle: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Subtitle',
        learnMoreLabel: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Link',
        pros: [
          'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Bullet_1',
          'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Bullet_2',
          'SiteApp_NewBlog_Migration_TwoBlogsInstalled_Bullet_3',
        ],
        buttons: [
          {
            key: 'switch',
            label: 'SiteApp_NewBlog_Migration_TwoBlogsInstalled_CTA',
          },
        ],
      }
    : {
        title: 'SiteApp_NewBlog_Migration_No_Support_Title',
        subTitle: 'SiteApp_NewBlog_Migration_No_Support_Subtitle',
        learnMoreLabel: 'SiteApp_NewBlog_Migration_No_Support_Link',
        pros: [
          'SiteApp_NewBlog_Migration_No_Support_Bullet_1',
          'SiteApp_NewBlog_Migration_No_Support_Bullet_2',
          'SiteApp_NewBlog_Migration_No_Support_Bullet_3',
        ],
        buttons: [
          {
            key: 'switch',
            label: 'SiteApp_NewBlog_Migration_No_Support_CTA',
          },
        ],
      };

  return {
    ...promoSlideTextContent,
    siteHasNewBlog: blogUtils.siteHasNewBlog(editorAPI),
    helpArticleId: blogUtils.helpArticles.switchToNewBlog,
    panelName: blogUtils.newSuperAppPanelName,
    startMagicMigrationProp,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  NewBlogMagicMigrationSlideDispatchProps,
  NewBlogMagicMigrationSlideOwnProps
> = (dispatch) => ({
  sendBi: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(NewBlogMagicMigrationSlide);
ConnectedComponent.pure = NewBlogMagicMigrationSlide;

export default ConnectedComponent;

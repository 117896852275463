import experiment from 'experiment';
import { AddPresetStore } from './addPresetStore';
import {
  AddPresetApi,
  loadPagesLabels,
  loadPagesCategories,
  loadSectionCategories,
  loadSavedSections,
  loadLayoutFamilies,
  loadKitDefinitions,
  loadAnimationKits,
} from './addPresetApi';
import {
  EditorAPIKey,
  AddPresetApiKey,
  HistoryApiKey,
  SavedComponentsApiKey,
  PreviewerApiKey,
  EditorCoreApiKey,
  ContentInjectionApiKey,
  SiteGlobalDataApiKey,
  EditorRestrictionsApiKey,
  WorkspaceModesApiKey,
  WixStoreApiKey,
} from '#packages/apis';
import { BaseEntryScope, createEntryPoint, Hooks } from '#packages/apilib';
import { SectionsApiKey } from '#packages/sections';
import { sections as sectionsUtils } from '#packages/util';
import { WorkspaceModes } from '#packages/workspaceModes';

export class AddPresetScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  sectionsAPI = this.useDependency(SectionsApiKey);
  historyAPI = this.useDependency(HistoryApiKey);
  contentInjectionAPI = this.useDependency(ContentInjectionApiKey);
  savedComponentsAPI = this.useDependency(SavedComponentsApiKey);
  previewerApi = this.useDependency(PreviewerApiKey);
  store = this.declareStore(AddPresetStore);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  siteGlobalDataAPI = this.useDependency(SiteGlobalDataApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  workspaceModesApi = this.useDependency(WorkspaceModesApiKey);
  wixStoreAPI = this.useDependency(WixStoreApiKey);

  hooks = this.declareApi(() => ({
    sections: {
      addPresetStart: Hooks.createHook<boolean>(),
      addPresetEnd: Hooks.createHook(),
    },
    layoutFamiliesReady: Hooks.createPromiseHook(),
    kitDefinitionsReady: Hooks.createPromiseHook(),
  }));
}

const setPermissions = (scope: AddPresetScope) => {
  const { editorRestrictionsApi, workspaceModesApi } = scope;
  editorRestrictionsApi.set('add-section_add-blank-section.visible', () =>
    workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
};

export const AddPresetApiEntryPoint = createEntryPoint({
  name: 'AddPresetAPI',
  Scope: AddPresetScope,
  publicApi({ contributeApi }) {
    contributeApi(AddPresetApiKey, AddPresetApi);
  },
  async initialize(scope: AddPresetScope) {
    setPermissions(scope);
    await scope.editorCoreApi.hooks.initReady.promise;
    loadPagesCategories(scope);
    loadSectionCategories(scope);
    loadPagesLabels(scope);
    if (experiment.isOpen('se_addDesignerSection')) {
      loadLayoutFamilies(scope, true);
      loadKitDefinitions(scope, true);
      loadAnimationKits(scope, true);
    }
    scope.editorCoreApi.hooks.sectionsMigrationReady.promise.then(() => {
      if (sectionsUtils.isSectionsEnabled()) {
        loadSavedSections(scope);
      }
    });
  },
});

import React, { type ReactElement, Component, type ComponentType } from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import type { SendBiFunction } from 'types/bi';
import * as coreBi from '#packages/coreBi';

import {
  mapStateToProps,
  type WithPublishDropPanelBiBehaviorStateProps,
} from './withDropPanelBiBehaviorMapper';

interface WithPublishDropPanelBiBehaviorProps
  extends WithPublishDropPanelBiBehaviorStateProps {
  dropPanelContent?: ReactElement | null;
  sendBi: SendBiFunction;
}

interface ComponentWithPublishDropPanelBiBehaviorProps {
  dropPanelContent?: ReactElement | null;
}

const withPublishDropPanelBiBehavior = <
  P extends WithPublishDropPanelBiBehaviorProps,
>(
  WrappedComponent: ComponentType<
    P & ComponentWithPublishDropPanelBiBehaviorProps
  >,
) => {
  class WithPublishDropPanelBiBehavior extends Component<WithPublishDropPanelBiBehaviorProps> {
    state = {
      hovered: false,
    };

    handlePanelPresentEnoughLong = () => {
      this.props.sendBi(coreBi.events.topbar.top_bar_tooltip_open, {
        item: 'Publish',
        is_published: this.props.isPublished,
      });
      this.props.sendBi(coreBi.events.topbar.TOP_BAR_PANEL_OPENED, {
        menu_name: 'PUBLISH',
        is_published: this.props.isPublished,
      });
    };

    handleMouseEnter = () => this.setState({ hovered: true });

    handleMouseLeave = () => this.setState({ hovered: false });

    getDropPanelContent() {
      const { dropPanelContent } = this.props;
      const { hovered } = this.state;
      // TODO: after se_publishPanelUnpublishButton is removed, return the timeout of 2000
      const timeout = 0;

      return hovered && dropPanelContent ? (
        <MountHandle wait={timeout} onMount={this.handlePanelPresentEnoughLong}>
          {dropPanelContent}
        </MountHandle>
      ) : (
        dropPanelContent
      );
    }

    render() {
      const { props } = this;
      const dropPanelContent = this.getDropPanelContent();
      const wrappedComponent = React.createElement(
        WrappedComponent,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, props as P, {
          dropPanelContent,
        }),
      );

      return (
        <span
          id="top-bar-publish-hover-bi-handler"
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
        >
          {wrappedComponent}
        </span>
      );
    }
  }

  return util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
  )(WithPublishDropPanelBiBehavior);
};

export default withPublishDropPanelBiBehavior;

interface MountHandleProps {
  wait: number;
  onMount: () => void;
}

class MountHandle extends Component<MountHandleProps> {
  componentDidMount() {
    const { onMount, wait } = this.props;

    this.timeout = setTimeout(() => onMount(), wait);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  private timeout: NodeJS.Timeout;

  render() {
    return this.props.children;
  }
}

import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { createPreviewApi } from './previewWrapper';
import { PreviewApiKey } from './publicApiKey';

export {
  PREVIEW_HORIZONTAL_MARGIN,
  TABLET_MIN_WIDTH,
  TABLET_WIDTH,
  TABLET_LAPTOP_WIDTH,
  DESKTOP_WIDTH,
  WIDE_DESKTOP_WIDTH,
  BREAKPOINT_DELTA,
} from './constants';

export const PreviewEntrypoint: EntryPoint = {
  name: 'PreviewApi',
  declareAPIs: () => [PreviewApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(PreviewApiKey, () => createPreviewApi(shell));
  },
};

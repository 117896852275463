import React, { type FC } from 'react';
import styles from './saleIndicatorB.scss';
import { translate } from '#packages/i18n';
import { cx } from '#packages/util';

interface SaleIndicatorBProps {
  isActive: boolean;
}

const SaleIndicatorB: FC<SaleIndicatorBProps> = ({ isActive }) => {
  return (
    <div
      className={cx(styles.saleIndicatorB, {
        [styles.saleIndicatorB_active]: isActive,
      })}
    >
      {translate('Topbar_upgrade_sale_banner_text')}
    </div>
  );
};

export default SaleIndicatorB;

import { BasePublicApi } from '#packages/apilib';
import _ from 'lodash';
import { uploadTextElem } from './addCompsHandlers/addTextHandler';
import type { TextUploadToStageScope } from './textUploadToStageEntryPoint';

const getTextItems = (event: ClipboardEvent): DataTransferItem[] => {
  const { items } = (event.clipboardData ||
    // @ts-expect-error
    event.originalEvent.clipboardData) as DataTransfer;

  return Array.from(items).filter((item) => {
    return item.kind === 'string' && item.type === 'text/plain';
  });
};

const validate = (event: ClipboardEvent): boolean => {
  const filesToPaste = getTextItems(event);

  return !_.isEmpty(filesToPaste);
};

const handleTextUpload = (
  scope: TextUploadToStageScope,
  event: ClipboardEvent,
) => {
  const itemsToPaste = getTextItems(event);

  if (_.isEmpty(itemsToPaste)) {
    return;
  }

  itemsToPaste.forEach((itemToPaste: DataTransferItem) => {
    itemToPaste.getAsString((text) => {
      if (text !== null && text.trim().length !== 0) {
        uploadTextElem(scope, text);
      }
    });
  });
};

export class TextUploadToStageApi extends BasePublicApi<TextUploadToStageScope> {
  handleUpload = this.bindScope(handleTextUpload);
  validate = validate;
}

import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { EditorAPIKey } from '#packages/apis';
import * as baseUI from '#packages/baseUI';
import constants from '#packages/constants';
import * as platformEvents from 'platformEvents';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import type { Shell } from '#packages/apilib';
import type { EditorAPI } from '#packages/editorAPI';

const { getPreviewMode, getPageIdBeforeEnteringPreview } =
  stateManagement.preview.selectors;
const { isInInteractionMode, getVariantPointers, getInteractionTriggerRef } =
  stateManagement.interactions.selectors;
const { isTranslating } = stateManagement.multilingual.selectors;
const { setIsUserToggledPreviewInCurrentSession } =
  stateManagement.preview.actions;
const {
  exitInteraction,
  clearInteractionBlockingLayer,
  setInteractionBlockingLayer,
} = stateManagement.interactions.actions;
const { setPreviewMode } = stateManagement.preview.actions;

export function createPreviewApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey) as EditorAPI;
  const goToPreviewCallbacks: Function[] = [];
  const exitPreviewCallbacks: Function[] = [];
  const beforeGoToPreviewCallbacks: Function[] = [];
  const beforeExitPreviewCallbacks: Function[] = [];

  function clearTextSelectionInPreview() {
    const { eventCounters } = editorAPI.store.getState();
    const newEventCounters = _.defaults(
      { clearTextSelection: eventCounters.clearTextSelection + 1 },
      eventCounters,
    );
    editorAPI.store.dispatch(
      stateManagement.services.actions.setState({
        eventCounters: newEventCounters,
      }),
    );
  }

  function goToPreview(callback: Function) {
    const callbacks = _.isFunction(callback) ? [callback] : [];
    clearTextSelectionInPreview();
    baseUI.tooltipManager.hide(
      constants.ROOT_COMPS.TOOLTIPS.FIRST_TIME_EXIT_POPUP_MODE,
    );
    util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.PREVIEW);
    enterPreview(callbacks);
  }

  function enterPreview(onPreviewEnteredCallbacks: AnyFixMe) {
    const callbacks = goToPreviewCallbacks.concat(onPreviewEnteredCallbacks);
    editorAPI.store.dispatch(setPreviewMode(true));
    editorAPI.updateState(
      {
        pageIdBeforeEnteringPreview: editorAPI.pages.getFocusedPageId(),
        previewingStatus: constants.PROGRESS_STATUS.IDLE,
        contextMenu: null,
      },
      function () {
        _.invokeMap(callbacks, Function.call);
        editorAPI.dsActions.waitForChangesApplied(function () {
          editorAPI.actions.executeAnimationsInPage();
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.PREVIEW_SITE,
          );
          util.fedopsLogger.interactionEnded(
            editorAPI.isMobileEditor()
              ? util.fedopsLogger.INTERACTIONS.GO_TO_MOBILE_PREVIEW
              : util.fedopsLogger.INTERACTIONS.GO_TO_DESKTOP_PREVIEW,
          );
        });
      },
    );
  }

  function togglePreview(
    callback?: Function,
    { biParams }: { biParams?: { origin?: string } } = {},
  ) {
    const startTime = _.now();
    const isGoingToPreviewMode = isNextModePreview();

    if (isGoingToPreviewMode) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.PREVIEW_SITE,
      );

      const cb = callback;
      callback = () => {
        if (cb) {
          cb();
        }
        editorAPI.bi.event(coreBi.events.preview.PREVIEW_SUCCESS, {
          duration: _.now() - startTime,
          ...biParams,
          is_published: editorAPI.dsRead.generalInfo.isSitePublished(),
        });
        editorAPI.store.dispatch(setIsUserToggledPreviewInCurrentSession());
      };
      editorAPI.updateState({
        previewingStatus: constants.PROGRESS_STATUS.IN_PROGRESS,
      });
    } else {
      editorAPI.documentServices.transitions.enable(false);
    }

    editorAPI.store.dispatch(
      stateManagement.layersPanel.actions.setPreviewMode(isGoingToPreviewMode),
    );

    ErrorReporter.breadcrumb('Clicked on go to Editor/Preview', {
      isGoingToPreviewMode,
    });
    if (editorAPI.isMobileEditor()) {
      util.fedopsLogger.interactionStarted(
        isGoingToPreviewMode
          ? util.fedopsLogger.INTERACTIONS.GO_TO_MOBILE_PREVIEW
          : util.fedopsLogger.INTERACTIONS.GO_TO_MOBILE_EDITOR,
      );
    } else {
      util.fedopsLogger.interactionStarted(
        isGoingToPreviewMode
          ? util.fedopsLogger.INTERACTIONS.GO_TO_DESKTOP_PREVIEW
          : util.fedopsLogger.INTERACTIONS.GO_TO_DESKTOP_EDITOR,
      );
    }

    if (isGoingToPreviewMode) {
      editorAPI.panelManager.closeAllPanels();
      editorAPI.selection.deselectComponents();
    }

    // hide panels with 'closeWithUserIntent'
    editorAPI.panelManager.getOpenPanels().forEach((p) => {
      editorAPI.panelManager.updatePanelProps(p.name, {
        isHidden: isGoingToPreviewMode,
      });
    });

    const isFocusMode = editorAPI.componentFocusMode.isEnabled();

    const state = editorAPI.store.getState();
    if (isInInteractionMode(state)) {
      const triggerRef = getInteractionTriggerRef(state);
      if (editorAPI.components.is.repeaterItem(triggerRef) || isFocusMode) {
        editorAPI.store.dispatch(exitInteraction());
      } else {
        const currentFocusedPageId = editorAPI.pages.getFocusedPageId();
        const pageIdBeforeEnteringPreview =
          getPageIdBeforeEnteringPreview(state);
        const returnToEditorInDifferentPage =
          !isGoingToPreviewMode &&
          pageIdBeforeEnteringPreview !== currentFocusedPageId;

        if (returnToEditorInDifferentPage) {
          editorAPI.store.dispatch(exitInteraction());
        } else {
          const variantPointers = getVariantPointers(state);
          if (isGoingToPreviewMode) {
            editorAPI.documentServices.variants.disable(variantPointers);
            clearInteractionBlockingLayer(editorAPI);
          } else {
            util.fedopsLogger.interactionStarted(
              util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE
                .ENTER_INTERACTIONS,
            );
            editorAPI.documentServices.variants.enable(variantPointers);
            const cb = callback;
            callback = () => {
              if (cb) {
                cb();
              }
              setInteractionBlockingLayer(editorAPI, triggerRef);
            };
          }
        }
      }
    }

    if (isFocusMode) {
      editorAPI.componentFocusMode.exit();
    }

    editorAPI.prepareDocumentForPreviewMode(
      isGoingToPreviewMode,
      isTranslating(state),
    );
    editorAPI.setRenderPlugins(isGoingToPreviewMode);
    editorAPI.store.dispatch(
      stateManagement.inlinePopup.actions.closeAll(true),
    );

    editorAPI.dsActions.waitForChangesApplied(function () {
      if (isGoingToPreviewMode) {
        goToPreview(callback);
        editorAPI.documentServices.transitions.enable(true);
      } else {
        exitPreview(callback);
      }
      ErrorReporter.setTags({
        isPreview: isGoingToPreviewMode,
      });
    });
  }

  async function exitPreview(callback: AnyFixMe) {
    editorAPI.updateState({
      exitPreviewStatus: constants.PROGRESS_STATUS.IN_PROGRESS,
    });
    await Promise.all(beforeExitPreviewCallbacks.map((cb) => cb()));
    editorAPI.updateState({
      exitPreviewStatus: constants.PROGRESS_STATUS.IDLE,
    });

    editorAPI.dsActions.components.modes.resetAllActiveModes();
    const siteScroll = editorAPI.site.getScroll();
    editorAPI.scroll.scrollTo({
      scrollLeft: siteScroll.x,
      scrollTop: siteScroll.y,
    });

    editorAPI.store.dispatch(setPreviewMode(false));
    editorAPI.updateState({ pageIdBeforeEnteringPreview: null }, () => {
      editorAPI.hideFloatingBubble();
      util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.EDITOR);
      if (editorAPI.isMobileEditor()) {
        editorAPI.mobile.afterMovingToMobileEditorCallback(false);
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.GO_TO_MOBILE_EDITOR,
        );
      } else {
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.GO_TO_DESKTOP_EDITOR,
        );
      }
      if (_.isFunction(callback)) {
        callback();
      }
      exitPreviewCallbacks.forEach((cb) => cb());

      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.switchedFromPreview({}),
      );
    });

    if (editorAPI.mobile.mobileWizard.isEnabled()) {
      editorAPI.store.dispatch(
        stateManagement.mobile.actions.mobileWizard.disableMobileWizard(),
      );
    }
  }

  function registerToGoToPreview(callback: AnyFixMe) {
    goToPreviewCallbacks.push(callback);
  }

  function registerExitPreview(callback: Function) {
    exitPreviewCallbacks.push(callback);
  }

  function registerToBeforeGoToPreview(callback: Function) {
    beforeGoToPreviewCallbacks.push(callback);
  }

  function registerToBeforeExitPreview(callback: Function) {
    beforeExitPreviewCallbacks.push(callback);
  }

  async function togglePreviewPublic(
    callback?: Function,
    params?: { biParams?: { origin?: string } },
  ) {
    if (isNextModePreview()) {
      await Promise.all(beforeGoToPreviewCallbacks.map((cb) => cb()));
      await new Promise(editorAPI.wixCode.prepareBeforePreview);
    }

    togglePreview(callback, params);
  }

  function isInPreviewMode() {
    const state = editorAPI.store.getState();
    return !!getPreviewMode(state);
  }

  function isNextModePreview() {
    return !isInPreviewMode();
  }

  return {
    registerExitPreview,
    registerToGoToPreview,
    registerToBeforeGoToPreview,
    registerToBeforeExitPreview,
    togglePreview: togglePreviewPublic,
    isInPreviewMode,
  };
}

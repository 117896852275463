import { EditorAPIKey } from '#packages/apis';
import type { Shell } from '#packages/apilib';
import * as stateManagement from '#packages/stateManagement';
import type {
  PanelTag,
  ConfirmationPanelFootnote,
} from '#packages/platformPanels';
import type { PanelResolveType } from '@wix/platform-editor-sdk';

const { openPlatformPanel } = stateManagement.panels.actions;

export interface OpenConfirmationPanelOptions {
  headerText: string;
  shouldShowIllustration: boolean;
  descriptionText: string | PanelTag[];
  mainActionText: string;
  illustration?: string;
  secondaryActionText?: string;
  helpId?: string;
  symbol?: string;
  footnote?: ConfirmationPanelFootnote;
}

export interface OpenPromotionalPanelOptions {
  titleText: string;
  contentArray: string[];
  shouldShowIllustration: boolean;
  mainActionText: string;
  secondaryActionText?: string;
  subtitleText?: string;
  illustration?: string;
  KBLinkText?: string;
  helpId?: string;
  primaryAction?: () => void;
  secondaryAction?: () => void;
}

export interface OpenErrorPanelOptions {
  headerText: string;
  shouldShowIllustration: boolean;
  topDescriptionText: string | PanelTag[];
  mainActionText: string;
  illustration?: string;
  bottomDescriptionText?: string | PanelTag[];
  secondaryActionText?: string;
  helpId?: string;
  symbol?: string;
}

export function createPlatformPanelHelpers(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  function openConfirmationPanel(options: OpenConfirmationPanelOptions) {
    return new Promise(function (resolve) {
      const panelProps = {
        ...options,
        onPanelClose: resolve,
      };
      editorAPI.store.dispatch(
        openPlatformPanel({
          panelName: 'platformPanels.platformConfirmationPanel',
          panelProps,
        }),
      );
    });
  }

  function openPromotionalPanel(
    options: OpenPromotionalPanelOptions,
  ): Promise<PanelResolveType> {
    return new Promise(function (resolve) {
      const panelProps = {
        ...options,
        onPanelClose: resolve,
      };
      editorAPI.store.dispatch(
        openPlatformPanel({
          panelName: 'platformPanels.platformPromotionalPanel',
          panelProps,
        }),
      );
    });
  }

  function openErrorPanel(
    options: OpenErrorPanelOptions,
  ): Promise<PanelResolveType> {
    return new Promise(function (resolve) {
      const panelProps = {
        ...options,
        onPanelClose: resolve,
      };
      editorAPI.store.dispatch(
        openPlatformPanel({
          panelName: 'platformPanels.platformErrorPanel',
          panelProps,
        }),
      );
    });
  }

  return {
    openErrorPanel,
    openConfirmationPanel,
    openPromotionalPanel,
  };
}

import constants from '#packages/constants';

import { getSectionCutPlugin } from './cut';
import { getSectionRemovePlugin } from './remove';
import { sectionAfterPastePlugin } from './afterPaste';
import { sectionDuplicatePlugin } from './afterDuplicate';
import { createCannotCopyOrDuplicatePlugin } from './cannotCopyOrDuplicate';
import { createCannotRemovePlugin } from './cannotRemove';
import { doubleClickPlugin } from './doubleClick';

import type { Scope } from '#packages/sections';

const bootstrapSectionCompPlugins = (scope: Scope) => {
  const { editorAPI, copyPaste } = scope;

  const removePlugin = getSectionRemovePlugin(scope);
  const cutPlugin = getSectionCutPlugin(scope);
  const cannotCopyOrDuplicatePlugin = createCannotCopyOrDuplicatePlugin(scope);
  const cannotRemovePlugin = createCannotRemovePlugin(scope);

  editorAPI.registerCutComponentPlugin(constants.COMP_TYPES.SECTION, cutPlugin);
  editorAPI.registerRemovePlugin(constants.COMP_TYPES.SECTION, removePlugin);
  editorAPI.registerAfterDuplicateComponentCallback(
    sectionDuplicatePlugin(scope),
  );
  editorAPI.registerAfterPasteComponentCallback(sectionAfterPastePlugin(scope));
  copyPaste.hooks.cannotDuplicate.tap(cannotCopyOrDuplicatePlugin);
  editorAPI.registerCannotRemovePlugin(
    constants.COMP_TYPES.SECTION,
    cannotRemovePlugin,
  );
  editorAPI.pluginService.registerPlugin(
    editorAPI.pluginService.pluginConstants.CANNOT_COPY,
    constants.COMP_TYPES.SECTION,
    (editorAPI, { compRefs }) => cannotCopyOrDuplicatePlugin(compRefs),
  );
  editorAPI.pluginService.registerPlugin(
    editorAPI.pluginService.pluginConstants.DOUBLE_CLICK,
    constants.COMP_TYPES.SECTION,
    doubleClickPlugin,
  );
};

export { bootstrapSectionCompPlugins };

import { editorSearch } from '#packages/stateManagement';
import constants from '#packages/constants';

import type { StateMapperArgs } from 'types/redux';

export const mapStateToProps = ({ editorAPI }: StateMapperArgs) => ({
  isOpen: editorSearch.selectors.isPanelOpen(editorAPI.store.getState()),
  openOrigin: editorSearch.selectors.getPanelOpenOrigin(
    editorAPI.store.getState(),
  ),
  authorizationToken: editorAPI.dsRead
    ? editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      ).instance
    : null,
});

export const mapDispatchToProps = (dispatch: FunctionFixMe) => ({
  closeSearch() {
    dispatch(editorSearch.actions.closeEditorSearchPanel());
  },
});

import React from 'react';
import { Button, IconButton } from '@wix/wix-base-ui';
import { cx } from '#packages/util';
import {
  openJiraIssueCollector,
  handleJiraIssueCollectorError,
  type JiraIssueCollectorParameters,
} from '../jiraIssueCollector';
import {
  isPageUrlWithSessionRecording,
  reloadPageWithSessionRecording,
  reloadPageWithoutSessionRecording,
} from '../sessionRecordingUtils';

import style from './JiraIssueCollectorButton.scss';

interface JiraIssueCollectorButtonProps {
  collectorParameters: JiraIssueCollectorParameters;
}

export const JiraIssueCollectorButton: React.FC<
  JiraIssueCollectorButtonProps
> = ({ collectorParameters }) => {
  const [isInProgress, setIsInProgress] = React.useState(false);
  const [isPanelHidden, setIsPanelHidden] = React.useState(false);

  const onRecordBugClick = () => {
    setIsInProgress(true);
    reloadPageWithSessionRecording();
  };

  const onReportToJiraClick = async () => {
    setIsInProgress(true);

    try {
      await openJiraIssueCollector(collectorParameters);
    } catch (error) {
      handleJiraIssueCollectorError(error);
    } finally {
      setIsInProgress(false);
    }
  };

  const onStopRecordingClick = () => {
    setIsInProgress(true);
    reloadPageWithoutSessionRecording();
  };

  const onHidePanelClick = () => {
    setIsPanelHidden(true);
  };

  if (isPanelHidden) {
    return null;
  }

  return (
    <div className={style.root}>
      {!isPageUrlWithSessionRecording() ? (
        <>
          <Button
            className={cx('btn-confirm-secondary', style.button)}
            disabled={isInProgress}
            onClick={onRecordBugClick}
          >
            Record a bug
          </Button>
          <span className={style.text}>
            Editor will reload to start recording.
          </span>
          <IconButton
            className={style.closeButton}
            skin="light"
            size="small"
            onClick={onHidePanelClick}
          >
            &times;
          </IconButton>
        </>
      ) : (
        <>
          <span className={style.text}>
            Recording session. Reproduce the bug, then report it.
          </span>
          <Button
            className={cx('btn-confirm-primary', style.button)}
            disabled={isInProgress}
            onClick={onReportToJiraClick}
          >
            Report to Jira
          </Button>
          <Button
            className={cx('btn-confirm-secondary', style.button)}
            disabled={isInProgress}
            onClick={onStopRecordingClick}
          >
            Stop recording
          </Button>
        </>
      )}
    </div>
  );
};

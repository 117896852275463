import { bi } from '#packages/stateManagement';
import {
  getAppActions,
  getAppPremiumBannerInfo,
} from '../../services/platform/platform';

import type { MapStateToProps } from 'types/redux';
import type { BusinessAppOwnProps } from './businessApp';
import type {
  AppActions,
  AppPremiumBannerInfo,
} from '../../services/platform/platform.types';
import type { BiEventFields } from 'types/bi';

export interface BusinessAppStateProps {
  appActions: AppActions;
  premiumBannerInfo: AppPremiumBannerInfo;
}

export interface BusinessAppDispatchProps {
  sendBi: (evid: number, biParams: BiEventFields) => void;
}

export const mapStateToProps: MapStateToProps<
  BusinessAppStateProps,
  BusinessAppOwnProps
> = ({ editorAPI }, ownProps) => {
  return {
    appActions: getAppActions(editorAPI, ownProps.app.id),
    premiumBannerInfo: getAppPremiumBannerInfo(editorAPI, ownProps.app.id),
  };
};

export const mapDispatchToProps = (
  dispatch: FunctionFixMe,
): BusinessAppDispatchProps => {
  return {
    sendBi: (evid, biParams) => dispatch(bi.actions.event({ evid }, biParams)),
  };
};

import coreUtilsLib from 'coreUtilsLib';
import * as platformEvents from 'platformEvents';
import * as core from '#packages/core';
import { arrayUtils } from '#packages/util';
import { componentSelectors } from '#packages/stateManagement';
import * as componentsUtils from './componentsUtils';

import type { CompRef, ComponentProperties } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

export function createComponentsDataApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  function get(
    compRefs: CompRef | CompRef[],
    useOriginalLanguage: boolean = false,
  ) {
    return componentSelectors.getData(
      compRefs,
      editorAPI.dsRead,
      useOriginalLanguage,
    );
  }

  function updateSingleCompData(
    compRef: CompRef,
    dataItem: AnyFixMe,
    dontAddToUndoRedoStack: boolean,
  ) {
    const currentData = editorAPI.components.data.get(compRef);
    dataItem.type = dataItem.type || currentData?.type;
    dataItem = core.utils.updateDataUtil.fixData(dataItem);

    const isChanged =
      !currentData || !componentsUtils.isContainedObject(currentData, dataItem);
    coreUtilsLib.wSpy.log('editorUpdateData', [
      'updateSingleCompData',
      isChanged,
      currentData,
      dataItem,
      ...arguments,
    ]);

    if (isChanged) {
      editorAPI.dsActions.components.data.update(compRef, dataItem);
      if (!dontAddToUndoRedoStack) {
        editorAPI.history.debouncedAdd('comp data changed');
      }
      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.componentDataChanged({
          compRef,
          previousData: currentData,
        }),
      );
      componentsUtils.sendBiIfRefComponent(
        editorAPI,
        compRef,
        componentsUtils.REF_COMPONENT_CHANGE_TYPE.DATA,
      );
    }
  }

  function update(
    compRefOrRefs: CompRef | CompRef[],
    propertiesItem: Partial<ComponentProperties> & { type?: string },
    dontAddToUndoRedoStack?: boolean,
  ) {
    coreUtilsLib.wSpy.log('editorUpdateData', ['updateData', ...arguments]);

    if (Array.isArray(compRefOrRefs) && compRefOrRefs.length > 1) {
      throw new Error('data.update does not support multiple components');
    }

    const [compRef] = arrayUtils.asArray(compRefOrRefs);
    return updateSingleCompData(
      compRef,
      propertiesItem,
      dontAddToUndoRedoStack,
    );
  }

  function remove(
    compRefOrRefs: CompRef | CompRef[],
    dontAddToUndoRedoStack: boolean,
  ) {
    if (Array.isArray(compRefOrRefs) && compRefOrRefs.length > 1) {
      throw new Error('data.remove does not support multiple components');
    }

    const [compRef] = arrayUtils.asArray(compRefOrRefs);

    editorAPI.dsActions.components.data.remove(compRef);

    if (!dontAddToUndoRedoStack) {
      editorAPI.history.add('comp data removed');
    }
  }

  return {
    update,
    get,
    remove,
  };
}

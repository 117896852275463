// @ts-nocheck
import React, { ComponentType } from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import * as util from '#packages/util';
import {
  loadComponentPanelDefinition,
  hasComponentPanel,
} from '#packages/componentModel';
import * as stateManagement from '#packages/stateManagement';

const DESIGN_PANEL_CONVENTION = 'designPanel';

export function hasCustomDesignPanel(compType: string) {
  return hasComponentPanel(compType, DESIGN_PANEL_CONVENTION);
}

async function getCustomPanel(compType: string) {
  const customPanel = await loadComponentPanelDefinition(
    compType,
    DESIGN_PANEL_CONVENTION,
  );
  return customPanel as ComponentType | undefined;
}

// eslint-disable-next-line react/prefer-es6-class
export const customDesignPanelFactory = createReactClass({
  displayName: 'designPanelFactory',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    compType: PropTypes.string,
    shouldMaintainOriginalLayout: PropTypes.bool,
    contentOnly: PropTypes.bool,
  },

  getInitialState() {
    //@ts-expect-error - not typed
    return { customPanel: undefined, customPanelElement: undefined };
  },

  async loadCustomPanel(props = this.props) {
    const selectedComponents = util.array.asArray(props.selectedComponent);
    const selectedComponentId = selectedComponents[0].id;
    const compType = this.getComponentType();

    if (!hasCustomDesignPanel(compType)) {
      return;
    }

    const customPanel = await getCustomPanel(compType);

    if (!customPanel) {
      this.setState({ customPanelElement: null });
      return;
    }
    const CustomPanelComponent = customPanel.component ?? customPanel;
    const WrappedPanelComponent =
      stateManagement.components.hoc.compPanel(CustomPanelComponent);

    const customPanelElement = React.createElement(WrappedPanelComponent, {
      key: selectedComponentId,
    });
    this.setState({ customPanelElement });
  },

  componentDidMount() {
    this.loadCustomPanel();
  },

  componentWillReceiveProps(nextProps) {
    const selectedComponents = util.array.asArray(this.props.selectedComponent);
    const selectedComponentId = selectedComponents[0].id;
    const nextSelectedComponents = util.array.asArray(
      nextProps.selectedComponent,
    );
    const nextSelectedComponentId = nextSelectedComponents[0].id;
    if (selectedComponentId !== nextSelectedComponentId) {
      this.loadCustomPanel(nextProps);
    }
  },

  componentWillUnmount() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  },

  getComponentType() {
    const editorAPI = this.getEditorAPI();
    let { compType, selectedComponent } = this.props;
    if (!selectedComponent || !compType) {
      selectedComponent = editorAPI.selection.getSelectedComponents();
      compType = editorAPI.components.getType(selectedComponent);
    }
    return compType;
  },

  render() {
    const { customPanelElement } = this.state;

    return customPanelElement ?? null;
  },
});

export default {
  hasCustomDesignPanel,
  panel: customDesignPanelFactory,
};

import React from 'react';
import { CustomScroll } from '@wix/wix-base-ui';

import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import * as util from '#packages/util';
import * as pagesMenu from '#packages/pagesMenu';
import * as tpa from '#packages/tpa';
import * as coreBi from '#packages/coreBi';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './wixStorePagesPanelMapper';

import * as wixStoresBi from '../../../common/bi/wixStoresBi';
import * as wixStoreDataProvider from '../../../common/wixStoreDataProvider';
import * as wixStoreEditorActionsService from '../../../common/wixStoreEditorActionsService';

import type {
  WixStorePagesPanelDispatchProps,
  WixStorePagesPanelStateProps,
} from './wixStorePagesPanelMapper';

import type { PagesData } from 'types/documentServices';
import type {
  PageActionHandlerName,
  PagesMenuItem,
} from './wixStorePagesPanel.types';

const pagesPanelAddGallerySectionHeight = 90;

export interface WixStorePagesPanelOwnProps {}

interface WixStorePagesPanelProps
  extends WixStorePagesPanelOwnProps,
    WixStorePagesPanelStateProps,
    WixStorePagesPanelDispatchProps {}

interface WixStorePagesPanelState {
  selectedPage: PagesData | null;
  isPageSettingsPanelOpened: boolean;
  editedPageId: string | null;
}

class WixStorePagesPanel extends React.Component<
  WixStorePagesPanelProps,
  WixStorePagesPanelState
> {
  state: WixStorePagesPanelState = {
    selectedPage: null,
    isPageSettingsPanelOpened: false,
    editedPageId: null,
  };

  pagesList: PagesMenuItem[];

  getPages() {
    const { getPagesData } = this.props;
    const pagesData = getPagesData();

    this.pagesList = pagesData.pagesList;

    return pagesData;
  }

  setSelected = (page: AnyFixMe) => {
    const { sendBi, navigateToPage } = this.props;

    if (page?.pageData) {
      navigateToPage(page.pageData.id);
    }

    this.setState({ selectedPage: page });

    const biParams = {
      app_id: util.tpaUtils.getStoresAppDefId(),
      tab_name: 'Pages',
      action_id: 'selectPage',
      action_type: superAppMenuInfra.BIAppActionType.General,
    };

    sendBi(superAppMenuInfra.superAppEvents.CLICK_ON_APP_ACTION, biParams);
  };

  isSelected = (item: AnyFixMe) => {
    const { focusedPageId } = this.props;

    const pagesList = this.pagesList || this.getPages().pagesList;

    const currentPageMenuItem = pagesList?.find(
      (menuItem) => menuItem.pageData?.id === focusedPageId,
    );

    return currentPageMenuItem ? item.id === currentPageMenuItem.id : null;
  };

  onActionSelected = (
    actionName: PageActionHandlerName,
    pageMenuItem?: PagesMenuItem,
    additionalData?: AnyFixMe,
  ) => {
    const { getPageActionHandlers } = this.props;

    const pageActionHandlers = getPageActionHandlers({
      openSettings: this.openSettings,
    });

    pageActionHandlers[actionName](pageMenuItem, additionalData);
  };

  closeSettings = () => {
    this.setState({
      isPageSettingsPanelOpened: false,
    });
  };
  openSettings = () => {
    this.setState({
      isPageSettingsPanelOpened: true,
    });
  };

  addWixStoreGallerySection = async () => {
    const { sendBi, getManagingAppDefId } = this.props;

    const params = {
      app_id: util.tpaUtils.getStoresAppDefId(),
      app_name: wixStoreDataProvider.getAppName(),
    };

    sendBi(wixStoresBi.events.PAGE_PANEL_ADD_STORE_PAGE_CLICK, params);

    const currentPageId =
      await wixStoreEditorActionsService.addSuperAppSectionPage(
        getManagingAppDefId(),
      );

    const currentPage = this.getAddedGalleryPage(currentPageId);
    this.setSelected(currentPage);
  };

  getAddedGalleryPage(currentPageId: string) {
    const { pagesList } = this.getPages();

    return pagesList.find((page) => page.pageData.id === currentPageId);
  }

  getSectionsRelativeHeight = () => {
    return `calc(100% -  ${pagesPanelAddGallerySectionHeight}px)`;
  };

  openStoreManager = () => {
    const { sendBi } = this.props;

    tpa.superApps.openDashboardUrl(
      tpa.superApps.superAppsConstants.ECOM.APP_DEF_ID,
    );

    sendBi(
      coreBi.events.topbar.pages.top_bar_PAGES_store_pages_store_manager_click,
    );
  };

  getEditedPageIdLink = () => {
    return {
      value: this.state.editedPageId,
      requestChange: (newVal: AnyFixMe) => {
        this.setState({ editedPageId: newVal });
      },
    };
  };

  getSettingPanelComponent() {
    const { isDesktop, isTPASpecialPage } = this.props;

    const pageItem = this.state.selectedPage;

    if (!pageItem) {
      return null;
    }
    const section = isDesktop
      ? pagesMenu.settingsPanels.desktop
      : pagesMenu.settingsPanels.mobile;

    return isTPASpecialPage((pageItem as AnyFixMe).pageData)
      ? section.subpage
      : section.mainMenu;
  }
  onNotificationClick = (item: AnyFixMe) => {
    this.setSelected(item);
    this.onActionSelected('settings');
  };
  render() {
    const { isWixStoresInstalled, isDesktop } = this.props;
    const { selectedPage } = this.state;

    const pagesWithSpecialPagesStartingIndex = this.getPages();
    const SettingsPanel = this.getSettingPanelComponent();

    return (
      <div className="wix-store-pages-panel">
        {this.state.isPageSettingsPanelOpened && (
          <div className="pages-panel-shadow" />
        )}

        <div className="pageSettings">
          {(selectedPage as AnyFixMe)?.pageData &&
            this.state.isPageSettingsPanelOpened && (
              <SettingsPanel
                // @ts-expect-error
                menuItem={selectedPage}
                closeSettingsPanel={this.closeSettings}
                actions={{ openStoreManager: this.openStoreManager }}
              />
            )}
        </div>

        <CustomScroll heightRelativeToParent={this.getSectionsRelativeHeight()}>
          <div className="page-list-container">
            <ul className="page-list">
              {pagesWithSpecialPagesStartingIndex.pagesList.map(
                (item, itemIndex) => {
                  const editedPageIdLink = this.getEditedPageIdLink();

                  return (
                    <li
                      key={item.id}
                      className={util.cx({
                        selected: selectedPage?.id === item.id,
                      })}
                    >
                      <pagesMenu.mixedList.nodeContentUniversalRenderer
                        // @ts-expect-error
                        dataSource={item}
                        isBelongToSubmenu={
                          itemIndex >=
                          pagesWithSpecialPagesStartingIndex.specialPagesStartingIndex
                        }
                        editedPageIdLink={editedPageIdLink}
                        onActionSelected={this.onActionSelected}
                        onNotificationClick={() =>
                          this.onNotificationClick(item)
                        }
                        notificationCount={1}
                        isSelected={this.isSelected(item)}
                        setSelected={() => this.setSelected(item)}
                      />
                    </li>
                  );
                },
              )}
            </ul>
          </div>
        </CustomScroll>
        {isDesktop && isWixStoresInstalled && (
          <div
            key="addGallerySection"
            className="pages-panel-add-gallery-section"
          >
            <p className="pages-panel-add-label">
              {translate(
                'SiteApp_WixStores_PagesPanel_AddGalleryPageButton_Label',
              )}
            </p>
            <baseUI.button
              label="SiteApp_WixStores_PagesPanel_AddGalleryPageButton_Caption"
              icon="addPage"
              onClick={this.addWixStoreGallerySection}
              className="add-page-btn"
              automationId="wix-store-panel-add-gallery-page"
            />
          </div>
        )}
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(WixStorePagesPanel);

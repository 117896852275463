// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import * as tpa from '#packages/tpa';
import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  token: string;
  url: string;
  width?: number | string;
  height?: number | string;
  isBareMode?: boolean;
  onClose?: FunctionFixMe;
  shouldCloseModal?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'platformDashboardPanel';

  static propTypes = {
    token: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isBareMode: PropTypes.bool,
    onClose: PropTypes.func,
    shouldCloseModal: PropTypes.bool,
  };

  render() {
    return (
      <tpa.compModals.tpaSettingsModal
        panelName="platformPanels.dashboardPanel"
        isBareMode={this.props.isBareMode}
        width={this.props.width}
        height={this.props.height}
        onClose={this.props.onClose}
        shouldCloseModal={this.props.shouldCloseModal}
      >
        <PlatformPanelApplicationFrame
          key="applicationFrame"
          token={this.props.token}
          url={this.props.url}
          panelClass="platformToolPanel"
          height="100%"
          width="100%"
        />
      </tpa.compModals.tpaSettingsModal>
    );
  }
}

import experiment from 'experiment';
import constants from '#packages/constants';
import * as util from '#packages/util';

import type {
  ComponentEditorMetaDataDefinition,
  ComponentEditorMetaDataValueResolver,
} from '../types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const { isWidgetController } = util.appStudioUtils;
const { GFPP_BUTTON_CLICK, FOCUS_BOX, ACCESSIBILITY } =
  constants.COMP_META_DATA.CONTROLS;

const containable: ComponentEditorMetaDataValueResolver<boolean> = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  potentialParent?: CompRef,
) => {
  if (!potentialParent) {
    return;
  }

  const isRepeaterContainer =
    editorAPI.components.is.repeatedComponent(potentialParent);

  if (isRepeaterContainer) {
    // TODO: use isRepeatable meta data here when it is production ready
    return experiment.isOpen('se_widgetInRepeater');
  }

  return true;
};

const canContainByStructure: ComponentEditorMetaDataValueResolver<boolean> = (
  editorAPI: EditorAPI,
  potentialParent: CompRef,
) => containable(editorAPI, null, potentialParent);

const metaData: ComponentEditorMetaDataDefinition = {
  skipInQuickEditPanel: true,
  isDirectChildrenSelectable: false,
  selectedBeforeDescendants: true,
  focusable: true,
  delegateControlsToFirstChild: {
    override: [GFPP_BUTTON_CLICK, FOCUS_BOX, ACCESSIBILITY],
  },
  applyMetaDataToFirstChild: {
    skipInLayersPanel: true,
    focusable: false,
    selectedBeforeDescendants: false,
    controlledByParent: true,
  },
  allowConnectToDB: (editorAPI: EditorAPI, compRef: CompRef) => {
    if (!isWidgetController(editorAPI, compRef)) {
      return false;
    }
    const appDefId = editorAPI.components.data.get(compRef).applicationId;
    return editorAPI.dsRead.platform.hasAppManifest(appDefId);
  },
  containable,
  canContainByStructure,
};

export default metaData;

import _ from 'lodash';
import { SectionType, type Category } from '@wix/add-panel-common';
import type { MapDispatchToProps, MapStateToProps } from 'types/redux';

const checkDispatchMapper = <T>(value: T): T => {
  if (isLazyAccessor(value)) {
    return value;
  }
  if (typeof value === 'function') {
    return value;
  }
  if (typeof value === 'object' && value !== null) {
    return value;
  }
  if (process.env.NODE_ENV === 'test') {
    return value;
  }
  throw new Error(
    'mapDispatchToProps should be function or object, probably circular deps',
  );
};

const checkStateMapper = <T>(value: T): T => {
  if (typeof value === 'function') {
    return value;
  }
  if (process.env.NODE_ENV === 'test') {
    return value;
  }
  throw new Error(
    'mapStateToProps should be function or object, probably circular deps',
  );
};

export const wrapWithPropsTransformer = <T, U>(
  mapDispatchToProps: MapDispatchToProps<T, any>,
  transformer: (props: T) => U,
): MapDispatchToProps<U, any> => {
  checkDispatchMapper(mapDispatchToProps);
  return (...args) => {
    const props = _.isFunction(mapDispatchToProps)
      ? mapDispatchToProps(...args)
      : mapDispatchToProps;
    return transformer(props);
  };
};

export const mergeMapStateToPropsFunctions = (
  ...mapStateToPropsFunctions: MapStateToProps<any, any>[]
): MapStateToProps<any, any> => {
  mapStateToPropsFunctions.forEach(checkStateMapper);
  return (...args) =>
    Object.assign({}, ...mapStateToPropsFunctions.map((f) => f(...args)));
};

const lazySymb = Symbol('lazy');

export const createLazyAccessor = <T>(fn: () => T) => {
  return {
    [lazySymb]: fn,
  };
};

interface LazyAccessor<T> {
  [lazySymb]: () => T;
}

const isLazyAccessor = (obj: any): obj is LazyAccessor<any> => {
  return obj && typeof obj === 'object' && lazySymb in obj;
};

const unwrapLazyAccessor = <T>(obj: T) => {
  if (isLazyAccessor(obj)) {
    return obj[lazySymb]();
  }
  return obj;
};

export const mergeMapDispatchToPropsFunctions = (
  ...mapDispatchToPropsFunctions: MapDispatchToProps<any, any>[]
): MapDispatchToProps<any, any> => {
  mapDispatchToPropsFunctions.forEach(checkDispatchMapper);
  return (...args: AnyFixMe[]) =>
    Object.assign(
      {},
      ...mapDispatchToPropsFunctions
        .map(unwrapLazyAccessor)
        .map((mapDispatchToProps) =>
          _.isFunction(mapDispatchToProps)
            ? mapDispatchToProps(...args)
            : mapDispatchToProps,
        ),
    );
};

export const gatherAddPanelTagIds = (categories: Category[]): string[] => {
  const tagIds: string[] = [];
  categories.forEach(({ groups }) => {
    groups?.forEach(({ sections }) => {
      sections?.forEach(({ type, props }) => {
        if (type === SectionType.RelatedApps && props?.tagId) {
          tagIds.push(props.tagId);
        }
      });
    });
  });
  return [...new Set(tagIds)];
};

// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import { Button, Composites, TextLabel } from '@wix/wix-base-ui';

const actions = stateManagement.mobile.actions.mobileWizard;
const VIDEO_URL = 'https://www.youtube.com/embed/CCFWEJ6r3NA';
const VIDEO_URL_PARAMS =
  '?modestbranding=1;rel=0&frameborder=0;allowfullscreen=0;autoplay=1;controls=1;autohide=1;showinfo=0';

// eslint-disable-next-line react/prefer-es6-class
const MobileWizardLastStep = createReactClass({
  displayName: 'MobileWizardLastStep',
  propTypes: {
    finishWizard: PropTypes.func.isRequired,
    sendVideoCloseBiEvent: PropTypes.func.isRequired,
  },
  getInitialState() {
    return {
      wizardHasFinished: false,
    };
  },
  componentDidMount() {
    this.displayTime = Date.now();
  },
  componentWillUnmount() {
    // this event should be sent when user clicks "Go to mobile editor" and also when user clicks back in the progress bar.
    const duration = Date.now() - this.displayTime;
    const origin = this.state.wizardHasFinished
      ? 'go_to_mobile_editor'
      : 'progress_bar_back_button';

    this.props.sendVideoCloseBiEvent({
      origin,
      duration,
      video_url: VIDEO_URL,
    });
  },
  finishWizard() {
    this.setState({ wizardHasFinished: true });
    this.props.finishWizard();
  },
  render() {
    return (
      <div
        data-aid="mobile-wizard-last-step"
        className="mobile-wizard-last-step"
      >
        <div className="step-header-wrapper">
          <TextLabel
            value="Mobile_Wizard_Welcome_Success_Title"
            enableEllipsis={false}
            type="T13"
            className="step-header"
          />

          <TextLabel
            value="Mobile_Wizard_Welcome_Success_Text"
            enableEllipsis={false}
            type="T03"
            className="step-sub-header"
          />
        </div>

        <iframe
          src={`${VIDEO_URL}${VIDEO_URL_PARAMS}`}
          frameBorder="0"
          allowFullScreen
          className="mobile-wizard-last-step-video"
        />

        <Composites.ButtonLarge>
          <Button
            automationId="mobile-wizard-back-to-mobile-editor"
            onClick={() => {
              this.finishWizard();
            }}
          >
            {translate('Mobile_Wizard_Welcome_Editor_Go_Button')}
          </Button>
        </Composites.ButtonLarge>
      </div>
    );
  },
});

const dispatchToProps = (dispatch) => ({
  finishWizard: () => dispatch(actions.finishWizard()),
  sendVideoCloseBiEvent: (eventParams) =>
    dispatch(actions.sendVideoCloseBiEvent(eventParams)),
});

const connected = util.hoc.connect(
  [],
  _.noop,
  dispatchToProps,
)(MobileWizardLastStep);

connected.pure = MobileWizardLastStep;
export default connected;

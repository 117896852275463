import React from 'react';
import _ from 'lodash';

import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import * as util from '#packages/util';

import ItemWithSubItems from '../menuItems/rcmItemWithSubItems';
import ItemWithSubItemsRecursive, {
  type RcmItemWithSubItemsRecursiveProps,
} from '../menuItems/rcmItemWithSubItemsRecursive';
import ItemBasic from '../menuItems/rcmItemBasic';

import { mapStateToProps, mapDispatchToProps } from './rightClickDebugMapper';
import {
  createRefComponentStructure,
  generateNicknameForInnerElement,
} from './rightClickDebug.utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const { openPlatformPanel } = stateManagement.panels.actions;

export interface RightClickDebugOwnProps {
  calculateSubMenuTop: (e: React.MouseEvent) => void;
  subMenuPositionY: number;
}

export interface RightClickDebugStateProps {
  selectedComponents: CompRef[];
  isSingleController: boolean;
  isSingleNonController: boolean;
  isRefComponentSelected: boolean;
  isReferredComponentSelected: boolean;
  isAppWidgetSelected: boolean;
}

export interface RightClickDebugDispatchProps {
  editorAPI: EditorAPI;
  restartPlatform(): void;
  openComponentInspectorFinder(): void;
  openAddController(): void;
  exportDebugInfo(comp: CompRef): void;
  openControllerInspector(): void;
  openConnectionsInspector(): void;
  exportWidget(): void;
  importWidget(): void;
  selectControllerConnectedComponents(): void;
  getComponentDebugItems(compRef: CompRef): unknown[];
  getCompDisplayLabel(compRef: CompRef): string;
}

interface RightClickDebugProps
  extends RightClickDebugOwnProps,
    RightClickDebugStateProps,
    RightClickDebugDispatchProps {}

class RightClickDebugComponent extends React.Component<RightClickDebugProps> {
  getReferableApps = () => {
    const { editorAPI } = this.props;

    const addWidget = (
      appDefinitionId: string,
      widgetId: string,
      variationId: string,
    ) => {
      const structure = createRefComponentStructure({
        appDefinitionId,
        widgetId,
        variationId,
      });

      editorAPI.components.add(editorAPI.pages.getFocusedPage(), structure);
    };

    const filterAndMapWidgets = (appId: string, widgets: AnyFixMe[]) =>
      _(widgets)
        .flatMap((w) => {
          const variations = w?.componentFields?.appStudioFields?.variations;
          const widgetType = w?.componentFields?.appStudioFields?.id;
          if (variations) {
            return variations.map((variation: AnyFixMe) => ({
              id: variation.id,
              name: getDisplayName(appId, widgetType, variation.id),
              add: addWidget.bind(null, appId, w.widgetId, variation.id),
            }));
          }
          return {
            id: w.widgetId,
            name: getDisplayName(appId, widgetType),
            add: addWidget.bind(null, appId, w.widgetId, undefined),
          };
        })
        .filter('name')
        .value();

    const getDisplayName = (
      appDefinitionId: string,
      widgetType: string,
      variationId?: string,
    ) => {
      const widgetsData =
        stateManagement.platform.selectors.getWidgetsDataFromManifest(
          editorAPI.dsRead,
          appDefinitionId,
        );
      if (widgetsData?.[widgetType]) {
        const widgetName = widgetsData[widgetType].name;
        const displayName = `${widgetName} - ${widgetType}`;
        return variationId ? `${displayName} - ${variationId}` : displayName;
      }

      return widgetType;
    };

    const installedApps = editorAPI.platform.getInstalledEditorApps() || [];

    return installedApps
      .map(({ appDefinitionId, appDefinitionName, widgets }: AnyFixMe) => ({
        id: appDefinitionId,
        name: appDefinitionName,
        widgets: filterAndMapWidgets(appDefinitionId, widgets),
      }))
      .filter(({ widgets }) => !_.isEmpty(widgets));
  };

  isAppBuilder = () => {
    return util.appStudioUtils.isAppStudio();
  };

  getRefComponentSubItems = (referableApps: unknown[]) => {
    const { editorAPI } = this.props;
    const {
      exportDebugInfo,
      isRefComponentSelected,
      isReferredComponentSelected,
    } = this.props;

    const refCompDebugItems = isRefComponentSelected
      ? [
          <ItemBasic
            shouldTranslate={false}
            item={{
              label: 'Export referred comp to console',
              enabled: true,
            }}
            key="export-referred"
            onClick={() => {
              const [comp] = editorAPI.components.getChildrenOrScopedChildren(
                this.props.selectedComponents[0],
              );
              exportDebugInfo(comp);
            }}
          />,
        ]
      : [];

    const referredCompDebugItems = isReferredComponentSelected
      ? [
          <ItemBasic
            shouldTranslate={false}
            item={{
              label: 'Generate nickname',
              enabled: true,
            }}
            key="generate-nickname"
            onClick={() => {
              const [comp] = isRefComponentSelected
                ? editorAPI.components.getChildrenOrScopedChildren(
                    this.props.selectedComponents[0],
                  )
                : this.props.selectedComponents;
              generateNicknameForInnerElement(editorAPI, comp);
            }}
          />,
        ]
      : [];

    return [
      ...(referableApps || []).map((app: any) => (
        <ItemWithSubItems
          item={{ enabled: true }}
          label={app.name}
          shouldTranslate={false}
          key={app.id}
        >
          {app.widgets.map((w: any) => (
            <ItemBasic
              shouldTranslate={false}
              item={{
                label: w.name,
                enabled: true,
              }}
              key={w.id}
              onClick={w.add}
            />
          ))}
        </ItemWithSubItems>
      )),
      ...refCompDebugItems,
      ...referredCompDebugItems,
    ];
  };

  getVariationsOfWidget = () => {
    const { editorAPI } = this.props;
    const { isRefComponentSelected, isAppWidgetSelected, selectedComponents } =
      this.props;

    const selectedComponent = _.head(selectedComponents);

    if (
      !selectedComponent ||
      !(isRefComponentSelected || isAppWidgetSelected)
    ) {
      return;
    }

    const appData: AnyFixMe = this.getSelectedCompoentAppData();
    if (!appData) {
      return;
    }

    const widgetId =
      editorAPI.selection.getSelectedComponentData().widgetId ||
      editorAPI.dsRead.platform.controllers.settings.getIn(
        selectedComponent,
        'devCenterWidgetId',
      );

    const widgetData = _.get(appData.widgets, widgetId);

    return widgetData?.componentFields?.appStudioFields?.variations;
  };

  getSelectedCompoentAppData = () => {
    const { editorAPI } = this.props;

    let selectedComponent = _.head(this.props.selectedComponents);
    if (!selectedComponent) {
      return;
    }
    selectedComponent = util.controlsUtils.getFirstControllableComponent(
      editorAPI,
      selectedComponent,
    );
    if (!selectedComponent) {
      return;
    }
    const appDefinitionId =
      editorAPI.components.data.get(selectedComponent)?.applicationId;
    if (!appDefinitionId) {
      return;
    }

    const installedEditorApps = editorAPI.platform.getInstalledEditorApps();

    return installedEditorApps?.find(
      (app) => app.appDefinitionId === appDefinitionId,
    );
  };

  changeVariation = () => {
    const { editorAPI } = this.props;

    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: 'platformPanels.changeVariationsPanel',
        panelProps: {
          presets: (this.getVariationsOfWidget() || []).map(
            (variation: AnyFixMe) => ({
              displayName: variation.name,
              variationId: variation.id,
            }),
          ),
        },
        panelMetaData: {
          frameType: constants.PANEL_TYPES.COMP,
          closeAllOtherPanels: true,
        },
      }),
    );
  };

  getSourceAppBuilderParams = () => {
    const appData: AnyFixMe = this.getSelectedCompoentAppData();
    if (!appData) {
      return;
    }

    const siteAssets = appData?.appFields?.platform?.baseUrls?.siteAssets;
    if (siteAssets) {
      const [, queryParamsStr] = siteAssets.split('?');
      const queryParams = util.url.parseUrl(`fake.com?${queryParamsStr}`).query;
      const { siteId, metaSiteId } = queryParams;

      return { siteId, metaSiteId };
    }
  };

  editInAppBuilder = () => {
    const { siteId, metaSiteId } = this.getSourceAppBuilderParams() || {};
    if (siteId && metaSiteId) {
      const appStudioBaseUrl = `${util.serviceTopology.appStudioServerRoot}/renderer`;
      const url = `${appStudioBaseUrl}/edit/${siteId}?metaSiteId=${metaSiteId}`;
      window.open(url, '_blank');
    }
  };

  render() {
    const referableApps = this.getReferableApps();
    const {
      calculateSubMenuTop,
      subMenuPositionY,
      selectedComponents,
      isSingleController,
      isSingleNonController,
      restartPlatform,
      openAddController,
      exportDebugInfo,
      openControllerInspector,
      openConnectionsInspector,
      openComponentInspectorFinder,
      exportWidget,
      importWidget,
      selectControllerConnectedComponents,
      getComponentDebugItems,
      getCompDisplayLabel,
    } = this.props;

    return (
      <ul key="debug">
        <ItemWithSubItems
          item={{ enabled: true }}
          label="Debug"
          shouldTranslate={false}
          key="debug"
          onMouseOver={calculateSubMenuTop}
          subMenuPositionY={subMenuPositionY}
        >
          {selectedComponents?.map((comp) => (
            <ItemWithSubItemsRecursive
              shouldTranslate={false}
              key={comp.id}
              label={getCompDisplayLabel(comp)}
              item={{ enabled: true }}
              items={
                getComponentDebugItems(
                  comp,
                ) as RcmItemWithSubItemsRecursiveProps[]
              }
            />
          ))}
          {selectedComponents?.map((comp) => (
            <ItemBasic
              shouldTranslate={false}
              item={{
                label: `Export [${comp.id}] to console`,
                enabled: true,
              }}
              key={`${comp.id}.debugInfo`}
              onClick={() => exportDebugInfo(comp)}
            />
          ))}
          {isSingleController ? (
            <ItemBasic
              shouldTranslate={false}
              item={{
                label: 'Inspect controller',
                enabled: true,
              }}
              onClick={openControllerInspector}
            />
          ) : null}
          {isSingleController ? (
            <ItemBasic
              shouldTranslate={false}
              item={{
                label: 'Select connected components',
                enabled: true,
              }}
              key="selectConnectedComponents"
              onClick={selectControllerConnectedComponents}
            />
          ) : null}
          {isSingleNonController ? (
            <ItemBasic
              shouldTranslate={false}
              item={{
                label: 'Inspect connections to controllers',
                enabled: true,
              }}
              key="connectionInspector"
              onClick={openConnectionsInspector}
            />
          ) : null}
          <ItemBasic
            shouldTranslate={false}
            item={{
              label: 'Add controller',
              enabled: true,
            }}
            key="add-controller"
            onClick={openAddController}
          />
          <ItemBasic
            shouldTranslate={false}
            item={{
              label: 'Component Finder',
              enabled: true,
            }}
            key="component-finder"
            onClick={openComponentInspectorFinder}
          />
          <ItemBasic
            shouldTranslate={false}
            item={{
              label: 'Restart Platform',
              enabled: true,
            }}
            key="restart-platform"
            onClick={restartPlatform}
          />
          {this.isAppBuilder() ? (
            <ItemWithSubItems
              item={{ enabled: true }}
              label="App Builder"
              shouldTranslate={false}
              key="app-builder"
              onMouseOver={calculateSubMenuTop}
              subMenuPositionY={subMenuPositionY}
            >
              <ItemBasic
                shouldTranslate={false}
                item={{
                  label: 'Export this widget',
                  enabled: true,
                }}
                key="export-widget"
                onClick={exportWidget}
              />
              <ItemBasic
                shouldTranslate={false}
                item={{
                  label: 'Import pending widget',
                  enabled: Boolean(
                    // eslint-disable-next-line no-undef
                    localStorage.getItem('exportedWidget'),
                  ),
                }}
                key="import-widget"
                onClick={importWidget}
              />
            </ItemWithSubItems>
          ) : null}
          {!_.isEmpty(referableApps) ? (
            <ItemWithSubItems
              item={{ enabled: true }}
              label="RefComponents"
              shouldTranslate={false}
              key="ref-component"
              onMouseOver={calculateSubMenuTop}
              subMenuPositionY={subMenuPositionY}
            >
              {this.getRefComponentSubItems(referableApps)}
            </ItemWithSubItems>
          ) : null}
          {!this.isAppBuilder() && !_.isEmpty(this.getVariationsOfWidget()) ? (
            <ItemBasic
              shouldTranslate={false}
              item={{
                label: 'open change variation panel',
                enabled: true,
              }}
              key="open-change-variation-panel"
              onClick={this.changeVariation}
            />
          ) : null}
          {!_.isEmpty(this.getSourceAppBuilderParams()) ? (
            <ItemBasic
              shouldTranslate={false}
              item={{
                label: 'Edit in App Builder',
                enabled: true,
              }}
              key="edit-in-app-builder"
              onClick={this.editInAppBuilder}
            />
          ) : null}
        </ItemWithSubItems>
      </ul>
    );
  }
}

export const RightClickDebug = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(RightClickDebugComponent);

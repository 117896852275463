import * as stateManagement from '#packages/stateManagement';

import type { AiButtonOwnProps } from './aiButton';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';

export interface AiButtonStateProps {
  isSelected: boolean;
  isEnabled: boolean;
  aiToolsWasOpened: boolean;
}

const PANEL_NAME = 'ai.panels.aiPageToolsPanel';

export const mapStateToProps: MapStateToProps<
  AiButtonStateProps,
  AiButtonOwnProps
> = ({ state, editorAPI }) => {
  return {
    isSelected: stateManagement.panels.selectors.isPanelOpenedByName(
      state,
      PANEL_NAME,
    ),
    isEnabled: editorAPI.editorIsInit(),
    aiToolsWasOpened:
      !!stateManagement.userPreferences.selectors.getSiteUserPreferences(
        'aiToolsWasOpened',
      )(editorAPI.store.getState()),
  };
};

export interface AiButtonDispatchProps {
  sendBIButtonClick: (category: string) => void;
  sendBIButtonHover: (element_name: string) => void;
  sendBIButtonSeen: (element_name: string) => void;
  toggleLeftBarAiPanel: () => void;
  saveInteractionStateToSitePreferences: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  AiButtonDispatchProps,
  AiButtonOwnProps
> = {
  sendBIButtonClick: stateManagement.leftBar.actions.sendBIButtonClick,
  sendBIButtonHover: stateManagement.leftBar.actions.sendBIButtonHover,
  sendBIButtonSeen: stateManagement.leftBar.actions.sendBIButtonSeen,
  toggleLeftBarAiPanel:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI }) => {
      const state = editorAPI.store.getState();
      const isPanelOpened =
        stateManagement.panels.selectors.isPanelOpenedByName(state, PANEL_NAME);

      if (isPanelOpened) {
        return dispatch(
          stateManagement.panels.actions.closePanelByName(PANEL_NAME),
        );
      }

      return dispatch(
        stateManagement.panels.actions.openLeftPanel(PANEL_NAME, {
          origin: 'left_bar',
        }),
      );
    },
  saveInteractionStateToSitePreferences: (): ThunkAction => (dispatch) => {
    dispatch(
      stateManagement.userPreferences.actions.setSitePreferences('site')(
        'aiToolsWasOpened',
        true,
      ),
    );
  },
};

// @ts-nocheck
import _ from 'lodash';
import CONSTANTS from './constants';
import * as util from '#packages/util';
import { PALETTE_COLORS } from '#packages/theme';

function getColorValueFromTheme(color, themeColors) {
  color = util.colors.getColorInHex(color);

  const result = _.findKey(themeColors, function (colorOption) {
    return colorOption === color;
  });
  if (!isColorInPaletteSequences(result, PALETTE_COLORS)) {
    return color;
  }
  return result;
}

function isColorInPaletteSequences(color, paletteSequences) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(paletteSequences, color);
}

function getThemeColorData(themeFonts) {
  const colorArr = [];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(themeFonts, function (color, colorName) {
    const index = colorName.split('_')[1];
    colorArr[index] = color;
  });

  return {
    color: colorArr,
  };
}

function getHexColorsMap(classNamePrefix, themeColors) {
  const colorsMap = {};
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(themeColors, function (color, colorClassName) {
    const colorKey = classNamePrefix + colorClassName;
    colorsMap[colorKey] = util.colors.getColorInHex(color);
  });

  return colorsMap;
}

function getColorPickerValue(color) {
  return color === 'noColor' || !color ? CONSTANTS.CK_OFF : color;
}

function trimThemeColorValue(color) {
  return color.replace('{', '').replace('}', '');
}

export {
  trimThemeColorValue,
  getColorValueFromTheme,
  isColorInPaletteSequences,
  getThemeColorData,
  getHexColorsMap,
  getColorPickerValue,
};

import React, {
  type FC,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { hoc } from '#packages/util';

import QuestionnairePanel from './components/questionnairePanel/questionnairePanel';
import ProgressBarPanel from './components/progressBarPanel/progressBarPanel';
import ErrorMessagePanel from './components/errorMessagePanel/errorMessagePanel';

import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps,
} from './welcomePanelMapper';
import {
  sendBIRequestOutput,
  sendBIUseTextBI,
  sendBITextChanged,
  sendBIStartSiteInjectionProcess,
} from './welcomePanelBI';
import {
  flattenObject,
  isBusinessDescriptionFromFunnelEnabled,
  isInjectionOnCloseEnabled,
  isInjectionWithoutFormEnabled,
} from './utils';

import type {
  QuestionnaireParams,
  ErrorBIData,
  OnInjectionSuccessFnParams,
} from './types';
import { InjectionActionOrigin, PanelTypes } from './constants';

type Props = ReturnType<typeof mergeProps>;

enum Routes {
  QUESTIONNAIRE = 'form',
  LOADER = 'loader',
  ERROR = 'error',
  EMPTY = 'empty',
}

const getSuccessNotificationMessage = (
  actionOrigin: string,
): string | undefined => {
  if (isInjectionOnCloseEnabled()) {
    if (actionOrigin === InjectionActionOrigin.Close) {
      return 'ai_template_injection_legal_skip_notification_text';
    } else if (actionOrigin === InjectionActionOrigin.Submit) {
      return 'ai_template_injection_legal_notification_text';
    }
  } else if (isInjectionWithoutFormEnabled()) {
    return 'ai_template_injection_legal_skip_notification_text';
  }
};

const WelcomePanel: FC<Props> = ({
  onClose,
  onSubmit,
  isFunnelDataApplicable,
  autoSubmitForm,
  highlightLeftBarMenu,
  unHighlightLeftBarMenu,
  getParentId,
  getAdditionalBIData,
  showNotification,
}) => {
  const [route, setRoute] = useState(Routes.EMPTY);
  const [panelType, setPanelType] = useState<PanelTypes | null>(
    PanelTypes.InjectionToSite,
  );
  const [errorBIData, setErrorBIData] = useState<ErrorBIData | null>(null);

  const hasBeenLoaded = useRef(false);

  useEffect(() => {
    unHighlightLeftBarMenu();

    return () => {
      highlightLeftBarMenu();
    };
  }, [highlightLeftBarMenu, unHighlightLeftBarMenu]);

  const onSuccess = useCallback(
    ({
      requestParams,
      responseParams,
      requestStartTime,
      isAutosubmitFlow,
      actionOrigin,
    }: OnInjectionSuccessFnParams) => {
      const duration = Date.now() - requestStartTime;

      const pageComponents = responseParams.map(
        ({ pageId, pageSuggestion }) => ({
          pageId,
          componentIds: Object.values(pageSuggestion?.idMap ?? {}),
        }),
      );
      const pageTokens = responseParams.map(({ pageId, pageSuggestion }) => ({
        pageId,
        tokens: pageSuggestion?.completionMetadata?.tokenUsage,
      }));
      const componentIds = pageComponents.reduce((acc, item) => {
        acc.push(...item.componentIds);
        return acc;
      }, []);

      const panelType = isAutosubmitFlow
        ? PanelTypes.AutoInjectionToSite
        : PanelTypes.InjectionToSite;

      sendBIRequestOutput({
        duration,
        query: requestParams,
        queryOutput: responseParams[0].pageSuggestion?.outline,
        pageComponents,
        pageTokens,
        prompt:
          responseParams[0].pageSuggestion?.completionMetadata?.promptsVersion,
        gptParams:
          responseParams[0].pageSuggestion?.completionMetadata
            ?.gptParamsVersion,
        panelType,
        actionOrigin,
      });

      sendBIUseTextBI({
        duration,
        componentIds,
        panelType,
        actionOrigin,
      });

      const notificationMessage = getSuccessNotificationMessage(actionOrigin);
      if (notificationMessage) showNotification(notificationMessage);

      responseParams.forEach(({ pageSuggestion }) => {
        if (pageSuggestion === undefined) {
          return;
        }

        const flattOutline = flattenObject(pageSuggestion.outline);
        const flattOriginalOutline = flattenObject(
          pageSuggestion.originalOutline,
        );

        Object.keys(flattOutline).forEach((componentKey) => {
          const componentId = pageSuggestion.idMap[componentKey];
          if (!componentId) return;

          const { componentType, pageId, parentComponentType, sectionType } =
            getAdditionalBIData(componentId);

          sendBITextChanged({
            componentId,
            componentType,
            pageId,
            parentComponentType,
            sectionType,
            parentComponentId: getParentId(componentId),
            text: flattOutline[componentKey as keyof typeof flattOutline],
            textOrigin:
              flattOriginalOutline[
                componentKey as keyof typeof flattOriginalOutline
              ],
            actionOrigin,
          });
        });
      });

      onClose('closed after success result');
    },
    [showNotification, onClose, getAdditionalBIData, getParentId],
  );

  const onSubmitError = useCallback((errorData) => {
    setErrorBIData({
      params: {
        businessType: undefined,
        businessName: '',
        businessDescription: '',
      },
      currentGptParamsVersion:
        errorData?.contentServerError?.currentGptParamsVersion ??
        errorData?.originalError?.extras?.currentGptParamsVersion,
      currentPromptsVersion:
        errorData?.contentServerError?.currentPromptsVersion ??
        errorData?.originalError?.extras?.currentPromptsVersion,
      tokenUsage: errorData.tokenUsage,
    });
    setRoute(Routes.ERROR);
  }, []);

  const onCloseInterceptor = useCallback(
    (origin: string) => {
      if (isInjectionOnCloseEnabled()) {
        setRoute(Routes.LOADER);
        autoSubmitForm(
          false,
          InjectionActionOrigin.Close,
          onSuccess,
          onSubmitError,
        );
      } else {
        onClose(origin);
      }
    },
    [autoSubmitForm, onClose, onSubmitError, onSuccess],
  );

  const handleSubmit = useCallback(
    (params: QuestionnaireParams) => {
      const actionOrigin = InjectionActionOrigin.Submit;
      setRoute(Routes.LOADER);
      setPanelType(PanelTypes.InjectionToSite);
      sendBIStartSiteInjectionProcess({
        panelType: PanelTypes.InjectionToSite,
        actionOrigin,
      });
      onSubmit(params, false, actionOrigin, onSuccess, onSubmitError);
    },
    [onSubmit, onSuccess, onSubmitError],
  );

  useEffect(() => {
    if (hasBeenLoaded.current) return;
    hasBeenLoaded.current = true;

    const injectionWithoutFormEnabled = isInjectionWithoutFormEnabled();
    if (
      !isBusinessDescriptionFromFunnelEnabled() &&
      !injectionWithoutFormEnabled
    ) {
      setRoute(Routes.QUESTIONNAIRE);
      return;
    }

    isFunnelDataApplicable((isApplicable) => {
      if (!isApplicable && !injectionWithoutFormEnabled) {
        setRoute(Routes.QUESTIONNAIRE);
        return;
      }

      const actionOrigin = isApplicable
        ? InjectionActionOrigin.WithFunnelData
        : InjectionActionOrigin.NoPanel;

      setRoute(Routes.LOADER);
      setPanelType(PanelTypes.AutoInjectionToSite);
      sendBIStartSiteInjectionProcess({
        panelType: PanelTypes.AutoInjectionToSite,
        actionOrigin,
      });

      autoSubmitForm(isApplicable, actionOrigin, onSuccess, onSubmitError);
    });
  }, [autoSubmitForm, isFunnelDataApplicable, onSubmitError, onSuccess]);

  const views = {
    [Routes.QUESTIONNAIRE]: (
      <QuestionnairePanel
        onClose={onCloseInterceptor}
        onSubmit={handleSubmit}
      />
    ),
    [Routes.LOADER]: <ProgressBarPanel />,
    [Routes.ERROR]: (
      <ErrorMessagePanel
        onClose={onCloseInterceptor}
        errorBIData={errorBIData}
        panelType={panelType}
      />
    ),
    [Routes.EMPTY]: <></>,
  };

  return <>{views[route]}</>;
};

const WelcomePanelConnect = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(WelcomePanel);

export default WelcomePanelConnect;

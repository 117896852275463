import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 694,
        height: 453,
        x: 20,
        y: 21,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'ryszn',
      },
      parent: 'comp-kgt5idao',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'ryszn',
        },
        title: '',
        uri: '74f558_2ab64cdc31824f378f001adc70670fff~mv2.png',
        description: '',
        width: 2054,
        height: 1372,
        alt: 'image.png',
        name: 'image.png',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'ryszn',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ryszn',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ryszn',
        },
      },
      scopedTransformations: {
        'variants-kgt5idb8': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ryszn',
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ryszn',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 280,
          height: 183,
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5idba1',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 450,
        height: 79,
        x: 20,
        y: 474,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ryszn',
      },
      parent: 'comp-kgt5idao',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ryszn',
        },
        text: `<h3 class="font_3" style="font-size:55px"><span style="font-size:60px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span><span style="color:#000000"><span style="font-size:55px"><span style="font-style:normal"><span style="font-weight:400"><span style="font-family:jockey one,sans-serif"><span style="letter-spacing:0.01em">${translate(
          'interactions_preset_sale_title',
        )}</span></span></span></span></span></span></span></span></span></span></span></h3>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ryszn',
        },
        brightness: 1,
        packed: false,
        minHeight: 78,
        verticalText: false,
      },
      transitions: {
        duration: 0.4,
        delay: 0,
        'timing-function': 'ease-in-out',
        type: 'TransitionData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ryszn',
        },
      },
      scopedTransformations: {
        'variants-kgt5idb8': {
          rotate: 0,
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ryszn',
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 280,
          height: 39,
          x: 0,
          y: 183,
          scale: 0.7777777777777778,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ryszn',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5idbc1',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 250,
        height: 31,
        x: 464,
        y: 510,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'ryszn',
      },
      parent: 'comp-kgt5idao',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ryszn',
        },
        text: `<p class="font_7" style=""><span style="font-size: 17px;"><span style="font-family: proxima-n-w01-reg;"><span style="font-weight: normal;"><span style="font-style: normal;"><span style="font-variant: normal;"><span style="color: rgb(66, 55, 55);"><span style="font-style:normal"><span style="font-weight:400"><span style="font-size:22px"><span style="font-family:brandon-grot-w01-light,sans-serif"><span style="color:#292929">${translate(
          'interactions_preset_sale_tagline',
        )}</span></span></span></span></span></span></span></span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'ryszn',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'ryszn',
        },
      },
      scopedTransformations: {
        'variants-kgt5idb8': {
          rotate: 0,
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'ryszn',
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 280,
          height: 26,
          x: 0,
          y: 232,
          scale: 0.8260869565217391,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'ryszn',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5idbe',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 744,
    height: 566,
    x: 118,
    y: 41,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'ryszn',
  },
  parent: 'ryszn',
  variants: {
    'variants-kgt5idb8': {
      componentId: 'comp-kgt5idao',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'ryszn',
      },
    },
  },
  transitions: {
    type: 'TransitionData',
    'timing-function': 'ease-in-out',
    duration: 0.4,
    delay: 0,
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'ryszn',
    },
  },
  transformations: {
    type: 'TransformData',
    origin: {
      x: {
        value: 50,
        type: 'percentage',
      },
      y: {
        value: 50,
        type: 'percentage',
      },
    },
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'ryszn',
    },
  },
  scopedTransformations: {
    'variants-kgt5idb8': {
      type: 'TransformData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'ryszn',
      },
      scale: {
        x: 1.15,
        y: 1.15,
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'ryszn',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#FFF5F5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 258,
      x: 20,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5idao',
      author: 'studio',
    },
  },
  activeModes: {},
};

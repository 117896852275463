// @ts-nocheck
import { editorDataFunction } from '@wix/editor-component-data-resolver';
import * as helpIds from '#packages/helpIds';
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

import type { GFPPValueOrResolver } from '@wix/editor-component-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const BACK_TO_TOP_BUTTON_TYPE = 'wysiwyg.viewer.components.BackToTopButton';
const changeBackToTopButtonIconPanel =
  'compPanels.panels.BackToTopButton.changePanel';

const translationKeys = {
  HEADER_SHAPE_TITLE: 'BacktoTop_Button_Design_Header_Label',
  HEADER_COLOR_TITLE: 'BacktoTop_Button_Design_Header_Label',
  FILL_COLOR_LABEL: 'CustomDesign_Backtotopbutton_IconColor_Label',
  FILL_COLORS_LABEL: 'CustomDesign_Backtotopbutton_IconColor_Label',
  FILL_OPACITY_LABEL: 'CustomDesign_Backtotopbutton_IconColor_Opacity',
  REVERT_COLOR_LABEL: 'CustomDesign_Backtotopbutton_IconColor_Reset_Link',
  ENABLE_BORDER_LABEL: '',
  BORDER_COLOR_LABEL: '',
  BORDER_WIDTH_LABEL: '',
};

export default {
  getGfppData: editorDataFunction(() => ({
    mainActions: [],
    enabledActions: [],
    mobileMainActions: [
      {
        label: 'CustomDesign_Backtotopbutton_ChangeIcon_Label',
        isSelected: gfppDataUtils.getPanelStateFn(
          changeBackToTopButtonIconPanel,
        ) as GFPPValueOrResolver,
        onClick(editorAPI) {
          gfppDataUtils.toggleComponentPanel(
            editorAPI,
            changeBackToTopButtonIconPanel,
          );
        },
      },
    ],
    mobileEnabledActions: [
      ACTIONS.DESIGN,
      ACTIONS.LAYOUT,
      ACTIONS.ANIMATION,
      ACTIONS.HELP,
    ],
    actionOverrides: {
      [ACTIONS.ANIMATION]: {
        onClick: gfppDataUtils.getTogglePanelFn('animation', {
          panelAction: 'backToTopIn',
          disabledAnimationParamsList: ['delay'],
          allowOnlyShowOnceUI: false,
        }),
      },
      [ACTIONS.DESIGN]: {
        onClick: gfppDataUtils.getTogglePanelFn('design', {
          translationKeys,
          helpId: helpIds.DESIGN[BACK_TO_TOP_BUTTON_TYPE],
        }),
      },
    },
    mobileHelpId: '6cd39b4d-bb52-4b25-b5cd-b2e09e7d5c7f',
  })),
};

import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';

import type {
  MapDispatchToProps,
  StateMapperArgs,
  ThunkAction,
} from 'types/redux';
import type { MapStateToProps } from 'types/redux';
import type { RightClickMenuStateProps } from './rightClickMenu';
import type {
  RightClickMenuDispatchProps,
  RightClickMenuOwnProps,
} from './rightClickMenu';
import rcmDataParser, {
  DEFAULT_BI_ORIGIN,
  shouldForceSOAPForNonContainable,
} from './util/rcmDataParser';
import { SwitchLayoutApiKey } from '#packages/switchLayout';

import constants, { RCM_ORIGIN } from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';

const { getSelectedCompsRefs } = stateManagement.selection.selectors;
const { getPreviewPosition } = stateManagement.domMeasurements.selectors;
const { getHoveredComp } = stateManagement.hoverBox.selectors;

const ORIGINS_FOR_COMPONENT_ACTIONS = [
  RCM_ORIGIN.STAGE,
  RCM_ORIGIN.ACTIONS_MORE_ACTION,
  RCM_ORIGIN.STAGE_SIDE_AREA,
];

export const mapStateToProps: MapStateToProps<
  RightClickMenuStateProps,
  RightClickMenuOwnProps
> = ({ state: editorState, editorAPI }: StateMapperArgs) => {
  const {
    contextMenu: {
      editorMousePosition,
      viewerPosition: viewerMousePosition,
      origin,
      biOrigin: biOriginFromState,
      context,
      removeSelectedGuide,
      removeAllGuidesInRuler,
      openRCMInteraction,
    },
    clipboard,
  } = editorState;

  const selectedComponents = getSelectedCompsRefs(editorState);
  const previewPosition = getPreviewPosition(editorState);

  const isComponentShowOnAllPages =
    selectedComponents &&
    editorAPI.components.isShowOnAllPages(selectedComponents);

  const shouldShowComponentActions =
    ORIGINS_FOR_COMPONENT_ACTIONS.includes(origin);

  const isViewerMousePositionDefined =
    viewerMousePosition.x !== undefined && viewerMousePosition.y !== undefined;

  const overlappingComponents = isViewerMousePositionDefined
    ? editorAPI.selection
        .getComponentsByXYConsideringUnselectedScopes(
          viewerMousePosition.x,
          viewerMousePosition.y,
        )
        .filter(editorAPI.components.is.selectable)
    : [];

  const biOrigin = biOriginFromState || DEFAULT_BI_ORIGIN;
  const menuItems = rcmDataParser.buildMenuItems(
    editorAPI,
    overlappingComponents,
    selectedComponents,
    biOrigin,
    origin,
  );

  const isInSwitchLayoutMode = editorAPI.host
    .getAPI(SwitchLayoutApiKey)
    .isInSwitchLayoutMode();

  const forceSOAPForNonContainable = shouldForceSOAPForNonContainable(
    editorAPI,
    selectedComponents[0],
  );

  return {
    editorMousePosition,
    viewerMousePosition,
    origin,
    biOrigin,
    context,
    removeSelectedGuide,
    removeAllGuidesInRuler,
    clipboard,
    selectedComponents,
    previewPosition,
    isComponentShowOnAllPages,
    forceSOAPForNonContainable,
    menuItems,
    shouldShowComponentActions,
    isInSwitchLayoutMode,
    openRCMInteraction,
  };
};

const getEditorAPI: ThunkAction<{
  editorAPI: EditorAPI;
  state: stateManagement.EditorState;
}> = (dispatch, getState, { editorAPI }) => ({
  editorAPI,
  state: getState(),
});

const hoverBoxActions = stateManagement.hoverBox.actions;

export const mapDispatchToProps: MapDispatchToProps<
  RightClickMenuDispatchProps,
  RightClickMenuOwnProps
> = (dispatch) => {
  const {
    editorAPI,
    state,
  }: { editorAPI: EditorAPI; state: stateManagement.EditorState } =
    dispatch(getEditorAPI);

  return {
    setHoverBox: (compRef) => {
      const newCompRef = editorAPI.utils.getParentIfCompCanBeSelected(compRef);
      const nextHoveredCompRef = newCompRef || compRef;
      const hoveredCompRef = getHoveredComp(state);

      if (!_.isEqual(hoveredCompRef, nextHoveredCompRef)) {
        dispatch(hoverBoxActions.setHoverBox(nextHoveredCompRef, false));
      }
    },
    clearHoverBox: () => {
      const hoveredCompRef = getHoveredComp(state);

      if (hoveredCompRef) {
        dispatch(hoverBoxActions.clearHoverBox());
      }
    },
    toggleBackgroundTargetHighlight: (
      backgroundTargetCompRef,
      shouldHighlight,
    ) => {
      if (shouldHighlight) {
        dispatch(
          stateManagement.highlights.actions.addCompsToHighlights(
            [backgroundTargetCompRef],
            constants.UI.HIGHLIGHTS.TYPES.NORMAL_WITH_OVERLAY,
          ),
        );
      } else {
        dispatch(stateManagement.highlights.actions.clearHighlights());
      }
    },
  };
};

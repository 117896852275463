import { url as urlUtils, sections as sectionsUtils } from '#packages/util';

import type { SectionsMigrationScope as Scope } from '../scope';
import type { ForceMigrationOptions } from '../types';

export const refreshWithForceMigration = async (
  scope: Scope,
  migrationOptions: ForceMigrationOptions,
) => {
  if (!urlUtils.isQA()) {
    await new Promise((resolve, reject) =>
      scope.editorAPI.saveManager.saveInBackground(
        resolve,
        reject,
        'optionalSectionsMigration',
        {
          forceOBMigration: true,
          sourceOfStart: 'optionalSectionsMigration_bgSave',
        },
      ),
    );
  }

  const trimmedUrl = new URL(window.location.href.split('#')[0]);

  trimmedUrl.searchParams.set('forceSectionsMigration', 'true');
  if (migrationOptions.origin) {
    trimmedUrl.searchParams.set(
      sectionsUtils.constants.FORCE_SECTION_MIGRATION_ORIGIN_PARAM,
      migrationOptions.origin,
    );
  }

  window.onbeforeunload = null;
  window.location.href = trimmedUrl.toString();
};

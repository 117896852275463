import React, { PureComponent } from 'react';
import _ from 'lodash';

import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import type { SendBiFunction, BiEventFields } from 'types/bi';

import withSendBi from '../hocs/withSendBi';
import UndoButton from './undoButton';
import RedoButton from './redoButton';
import { mapStateToProps, mapDispatchToProps } from './undoRedoMapper';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const sendBiEvents = (
  sendBi: SendBiFunction,
  category: string,
  isZoomMode: boolean,
  isSitePublished: boolean,
  topBarStateBIParamValue: string,
  biParams?: BiEventFields,
): void => {
  util.editorWixRecorder.addLabel(`${category} top bar clicked`);
  sendBi(coreBi.events.topbar.top_bar_click, {
    category,
    origin: isZoomMode ? 'zoomout' : 'editor',
    is_published: isSitePublished,
    state: topBarStateBIParamValue,
  });
  sendBi(coreBi.events.editor.undo_redo_component_change, {
    type: category,
    origin: 'topbar',
    pageId: biParams?.currentPage,
    date_of_action: biParams?.date_of_action,
    actionName: biParams?.label,
    component_id: biParams?.component_id,
    component_type: biParams?.component_type,
  });
};

interface UndoRedoProps {
  disabled?: boolean;
  sendBi: SendBiFunction;
  undo: () => void;
  redo: () => void;
  isZoomMode: boolean;
  isSitePublished: boolean;
  undoLastSnapshotParams: BiEventFields;
  redoLastSnapshotParams: BiEventFields;
  topBarStateBIParamValue: string;
}

class UndoRedo extends PureComponent<UndoRedoProps> {
  handleUndoClick = () => {
    const biParams = this.props.undoLastSnapshotParams;
    this.props.undo();
    sendBiEvents(
      this.props.sendBi,
      'undo',
      this.props.isZoomMode,
      this.props.isSitePublished,
      this.props.topBarStateBIParamValue,
      biParams,
    );
  };

  handleRedoClick = () => {
    const biParams = this.props.redoLastSnapshotParams;
    this.props.redo();
    sendBiEvents(
      this.props.sendBi,
      'redo',
      this.props.isZoomMode,
      this.props.isSitePublished,
      this.props.topBarStateBIParamValue,
      biParams,
    );
  };

  render() {
    return (
      <div className="top-bar-undo-redo">
        <UndoButton
          disabled={this.props.disabled}
          onClick={this.handleUndoClick}
        />
        <RedoButton
          disabled={this.props.disabled}
          onClick={this.handleRedoClick}
        />
      </div>
    );
  }
}

const ConnectedUndoRedo = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withSendBi,
)(UndoRedo);

ConnectedUndoRedo.pure = UndoRedo;

export default ConnectedUndoRedo;

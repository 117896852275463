import { createReduxStore } from '#packages/apilib';

export interface SiteReadinessStore {}

export interface CheckReadinessPanelBiData {
  panelOpenOrigin: string;
}

export interface SiteReadinessState {
  checkReadinessPanelBiData: CheckReadinessPanelBiData;
}

const getInitialState = (): SiteReadinessState => ({
  checkReadinessPanelBiData: null,
});

export const SiteReadinessStore = createReduxStore({
  getInitialState,
  selectors: {
    getCheckReadinessPanelBiData: (state: SiteReadinessState) =>
      state.checkReadinessPanelBiData,
  },
  actions: {
    setCheckReadinessPanelBiData: (
      state: SiteReadinessState,
      checkReadinessPanelBiData: CheckReadinessPanelBiData,
    ) => {
      return {
        ...state,
        checkReadinessPanelBiData,
      };
    },
  },
});

import * as coreBi from '#packages/coreBi';
import * as tpa from '#packages/tpa';
import { addPanel as addPanelUtil, tpaUtils } from '#packages/util';
import type { MapDispatchToProps, MapStateToProps } from '../../../types/redux';
import * as wixStoreDataProvider from '../common/wixStoreDataProvider';
import * as wixStoreEditorActionsService from '../common/wixStoreEditorActionsService';
import type {
  WixStoreAddPanelDispatchProps,
  WixStoreAddPanelOwnProps,
  WixStoreAddPanelStateProps,
} from './types';

export const mapStateToProps: MapStateToProps<
  WixStoreAddPanelStateProps,
  WixStoreAddPanelOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  WixStoreAddPanelDispatchProps,
  WixStoreAddPanelOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  const isNewAddPanel = addPanelUtil.isNewPanelEnabled();

  return {
    installApp() {
      wixStoreEditorActionsService.addAppToSite(
        isNewAddPanel
          ? tpa.constants.BI.type.ADD_APP_NEW_ADD_PANEL
          : tpa.constants.BI.type.ADD_APP_ADD_PANEL,
      );

      const biData = {
        app_id: tpaUtils.getStoresAppDefId(),
        app_name: wixStoreDataProvider.getAppName(),
        origin: isNewAddPanel ? 'newAddPanel' : 'addPanel',
      };
      editorAPI.bi.event(
        coreBi.events.addPanel.SITE_APP_INSTALL_AGREE_CLICK,
        biData,
      );
    },
    openAppMarket() {
      const appMarketPanelProps = {
        urlParams: {
          slug: tpaUtils.getStoresAppDefId(),
        },
      };

      tpa.appMarketTabService.openAppMarketTab(
        appMarketPanelProps,
        editorAPI.panelManager.openPanel,
      );

      const biData = {
        app_id: tpaUtils.getStoresAppDefId(),
        app_name: wixStoreDataProvider.getAppName(),
      };
      editorAPI.bi.event(
        coreBi.events.addPanel.INSTALL_FOUND_OUT_MORE_CLICK,
        biData,
      );
    },
  };
};

import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const WHITELIST_OF_TYPES_FOR_SELECT_COMPONENT_BI: Set<string> = new Set([
  constants.COMP_TYPES.CONTAINER,
  constants.COMP_TYPES.MEDIA_CONTAINER,
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
  constants.COMP_TYPES.SECTION,
  constants.COMP_TYPES.HEADER,
  constants.COMP_TYPES.FOOTER,
]);

export function createSelectionBI({ editorAPI }: { editorAPI: EditorAPI }) {
  const selectionActions = stateManagement.selection.actions;

  function selectContainerComponent(
    compsToBeSelected: CompRef[],
    biParams?: { origin: string },
  ) {
    const compRef = compsToBeSelected[0];
    const compType = editorAPI.dsRead.components.getType(compRef);

    if (WHITELIST_OF_TYPES_FOR_SELECT_COMPONENT_BI.has(compType)) {
      const appIds = editorAPI.tpa
        .getUniqueChildrenWidgets(compRef)
        .map(
          (ref: CompRef) => editorAPI.components.data.get(ref)?.appDefinitionId,
        )
        .filter(Boolean);

      editorAPI.bi.event(coreBi.events.selection.select_container_component, {
        ...editorAPI.bi.getComponentsBIParams(compsToBeSelected)[0],
        origin: biParams?.origin || 'stage',
        compName: editorAPI.components.getDisplayName(compRef),
        openedPanels: editorAPI.panelManager
          .getOpenPanels()
          .map(({ name }) => name),
        app_id: appIds.join(','),
      });
    }
  }

  function multiselect(
    nextSelectedComponents: CompRef[],
    selectionMethod: 'keyboard',
  ) {
    editorAPI.bi.event(coreBi.events.selection.MULTISELECT, {
      num_of_elements_selected: nextSelectedComponents.length,
      selection_method: selectionMethod,
    });
  }

  function sendBiOnSelectPopupOverlay(compsToBeSelected: CompRef[]) {
    editorAPI.store.dispatch(
      selectionActions.sendBiOnSelectPopupOverlay(compsToBeSelected),
    );
  }

  return {
    selectContainerComponent,
    sendBiOnSelectPopupOverlay,
    multiselect,
  };
}

export type SelectionBI = ReturnType<typeof createSelectionBI>;

import React, { type MouseEventHandler } from 'react';
import ReactDOM from 'react-dom'; // Was reactDOM before the refactoring *fingers crossed*
import _ from 'lodash';
import experiment from 'experiment';
import constants, { RCM_ORIGIN } from '#packages/constants';
import {
  cx,
  isMainMenuFlowEnabled,
  isNewPagesPanelEnabled,
  editorWixRecorder,
  workspace,
  hoc,
  keyboardShortcuts,
  isAdvancedMenuOpen,
  compIcon,
} from '#packages/util';

// Urgent fix https://jira.wixpress.com/browse/WEED-22603 ->
// TODO: delete this
import PropTypes from 'prop-types';

import * as mainMenuTreeState from './utils/mainMenuTreeState';
import { NewWorkspaceContentHeader, AddPageButton } from '../../utils';
import * as menuViewMapper from './editorAPIConnectors/menuViewMapper';
import AddTooltip from './addTooltip';
import * as coreBi from '#packages/coreBi';
import * as helpIds from '#packages/helpIds';

import * as baseUI from '#packages/baseUI';
import { Button, CustomScroll, PanelHeader, Tooltip } from '@wix/wix-base-ui';
import menuItem from './menuItem/menuItem';
import * as symbols from '@wix/santa-editor-symbols';
import TooManyPagesBanner from './tooManyPagesBanner';
import { CustomMenusFirstTime } from '../../CustomMenusFirstTime/CustomMenusFirstTime';
import type { MenuItem } from '@wix/document-services-types';
import type { MenuItemAction } from '../../api/menusPages';
import type {
  GetActionsOptions,
  IMenuConfig,
  IPageMenuItem,
  MenuAddItemAction,
} from '#packages/menu';
import FooterContextMenu from './FooterContextMenu/FooterContextMenu';
import { Add } from '@wix/wix-ui-icons-common/classic-editor';
import type { IAppPage } from '../../api/appsPages';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const { WARNING_NUMBER_OF_STATIC_PAGES, MAX_STATIC_PAGES_ALLOWED } =
  constants.STATIC_PAGES;

const MAX_VISIBLE_PAGES = 17;
const SHORT_DELAY = 700;
const TOO_MANY_PAGES_FREQUENCY = 4;

const countVisible = (pages: AnyFixMe[] = []): number =>
  pages.reduce(
    (count, page) =>
      count + 1 + (!page.isCollapsed ? countVisible(page.items) : 0),
    0,
  );

// Created from the propTypes
interface MenuViewProps {
  setInPrefIscollapsedMenuItems: (
    menuItemId: string,
    isCollapsed: boolean,
  ) => void;
  navigateCallback: FunctionFixMe;
  isDraggable: boolean;
  isMobile: boolean;
  onMoveMenuItem: FunctionFixMe;
  menuItems: Object[];
  mainMenuPanelContext: { [key: string]: any };
  findMenuItemById: FunctionFixMe;
  openPanel: FunctionFixMe;
  staticPagesCount: number;
  title: string;
  onClose: MouseEventHandler;
  onHelp: FunctionFixMe;
  onHelpHeader: MouseEventHandler;
  openContextMenu: FunctionFixMe;
  isEditorLoadedWithMoreThanMaxStaticPages: boolean;
  notifyDataBindingAppTooManyPages: FunctionFixMe;
  getMenuItemActions: (menuItem: IPageMenuItem) => MenuItemAction[];
  renamedItemId: string;
  initialActions: Array<any> | Object; // ¯\_(ツ)_/¯
  renameEnabled: boolean;
  showAddOptions: boolean;
  onClickAddPage: (clickOrigin: AddPageButton) => void;
  onAddLinkClick: () => void;
  onAddFolderClick: () => void;
  onAddPagesSelectionClick: (category: string) => void;
  onContextMenuItemsHover: (category: string, target: string) => void;
  getAddItemsActions: (
    option: Partial<GetActionsOptions>,
    config?: IMenuConfig,
  ) => MenuAddItemAction[];
  showDynamicPages: boolean;
  titleAdd: string;
  titleAddMenuItem: string;
  titleNewPage: string;
  titleDropdownPlaceholder: string;
  onSelectNonPageItem: FunctionFixMe;
  onSelectPageItem: FunctionFixMe;
  biEvent: FunctionFixMe;
  biCategory: string;
  selectedMenuItemId?: string;
  showUserActionNotification?: FunctionFixMe;
  timeoutHandler?: { setTimeout: AnyFixMe; clearTimeout: AnyFixMe };
  setTooManyPagesFrequencyCount?: FunctionFixMe;
  getTooManyPagesFrequencyCount: number;
  onComponentMount?: FunctionFixMe;
  shouldShowAddFolderButton?: boolean;
  shouldShowAddLinkButton?: boolean;
  openSiteGeneratorPlayground?: () => void;
  openUpdateSectionsPanel?: () => void;
  appPages: IAppPage[];
  loadingPageId?: string;
  loadingTrigger?: object;
  setLoadingTrigger?: (nonePageId?: string) => void;
}

interface MenuViewState {
  selectedMenuItemId: string;
  isAddLinkHovered: boolean;
  isAddFolderHovered: boolean;
  isAddPageHovered: boolean;
  isSiteGeneratorHovered: boolean;
  isUpdateSectionPresetsHovered: boolean;
}

class MenuView extends React.Component<MenuViewProps, MenuViewState> {
  static defaultProps = {
    timeoutHandler: window,
  };

  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      selectedMenuItemId: this.props.selectedMenuItemId,
      isAddLinkHovered: false,
      isAddFolderHovered: false,
      isAddPageHovered: false,
      isSiteGeneratorHovered: false,
      isUpdateSectionPresetsHovered: false,
    };
    this.scrollToSelectedNode = false;
  }

  componentDidMount = () => {
    this.scrollToSelectedNode = true;
    this.doScrollToSelectedNode();

    if (typeof this.props.onComponentMount === 'function') {
      this.props.onComponentMount();
    }

    const currentKeyboardContext = keyboardShortcuts.isEnabled()
      ? keyboardShortcuts.getContext()
      : keyboardShortcuts.CONTEXTS.EDITOR; // Fallback in case of disabling current context
    this.lastKeyboardContext = currentKeyboardContext;

    keyboardShortcuts.setContext(keyboardShortcuts.CONTEXTS.PAGES_MENU_PANEL);

    this.props.mainMenuPanelContext.setOpened({
      selectedMenuItem: this.getMenuItemById(this.state.selectedMenuItemId),
    });
    this.props.biEvent(coreBi.events.pages.pagesPanel.site_menu_opened, {
      pages_count: this.props.staticPagesCount,
    });
  };

  UNSAFE_componentWillReceiveProps = (nextProps: AnyFixMe) => {
    const selectedMenuItemId =
      nextProps.renamedItemId || nextProps.selectedMenuItemId;
    if (
      selectedMenuItemId &&
      this.state.selectedMenuItemId !== selectedMenuItemId
    ) {
      this.setState({ selectedMenuItemId });

      this.props.mainMenuPanelContext.setSelectedMenuItem(
        this.getMenuItemById(selectedMenuItemId),
      );
    }
  };

  componentDidUpdate = () => {
    this.doScrollToSelectedNode();
  };

  componentWillUnmount = () => {
    const currentKeyboardContext = keyboardShortcuts.getContext();
    const shouldSetPreviousKeyboardContext =
      this.lastKeyboardContext &&
      currentKeyboardContext === keyboardShortcuts.CONTEXTS.PAGES_MENU_PANEL;
    if (shouldSetPreviousKeyboardContext) {
      keyboardShortcuts.setContext(this.lastKeyboardContext);
    }
    this.props.mainMenuPanelContext.setClosed();
  };

  lastKeyboardContext: AnyFixMe;
  scrollToSelectedNode;
  tooltipTimeout: AnyFixMe;
  readonly isNewWorkspace = workspace.isNewWorkspaceEnabled();

  isShrink = () => {
    if (isNewPagesPanelEnabled()) {
      return false;
    }

    return countVisible(this.props.menuItems) > MAX_VISIBLE_PAGES;
  };

  //@ts-expect-error Property 'getScrollTop' does not exist on type 'Element'
  getScrollTop = () => this.refs.customScroll.getScrollTop();

  handleMovePage = (e: AnyFixMe) => {
    const hierarchy = { items: this.props.menuItems };
    const fromItem = e.from.findIn(hierarchy, 'items');
    const fromId = fromItem.id;
    if (!fromId) {
      return;
    }

    const toId = e.to.parent().findIn(hierarchy, 'items').id || null;
    const toItem = e.to.findIn(hierarchy, 'items');
    const position = toItem ? toItem.position : e.to.atRight();

    if (toId) {
      mainMenuTreeState.setMenuItemCollapsed(toId, false);
    }

    this.props.onMoveMenuItem(fromId, toId, position, () => {
      if (toId) {
        this.scrollToSelectedNode = true;
      }

      this.handleSelectedItem(fromItem);
    });

    //page was a main-page and turned into a sub-page
    if (toId && e.from.length() === 1) {
      this.props.biEvent(
        coreBi.events.topbar.pages.pages_menu_sub_page_created,
        {
          origin: 'drag',
          source: this.props.biCategory,
        },
      );
      //page was a sub-page and was turned into a main-page
    } else if (!toId && e.from.length() > 1) {
      this.props.biEvent(
        coreBi.events.topbar.pages
          .top_bar_PAGES_menu_user_reverted_to_main_page,
        {
          origin: 'drag',
          source: this.props.biCategory,
        },
      );
    }

    const getDragBiType = (fromItem: AnyFixMe, toId: AnyFixMe) => {
      if (fromItem.parent) {
        if (toId) {
          if (fromItem.parent.id === toId) {
            // move in same parent
            return 'subitem_drag';
          }

          return 'subitem_moved';
        }

        return 'move_from_folder';
      }

      if (toId) {
        return 'move_to_folder';
      }

      return 'item_drag';
    };

    const getBiParentItemName = (toItem: AnyFixMe, toId: AnyFixMe) => {
      if (!toId) {
        return;
      }

      return toItem?.parent?.type.isDropdown ? 'folder' : 'page';
    };

    const category = getDragBiType(fromItem, toId);

    editorWixRecorder.addLabel(`pages_panel_dnd_${category}`);

    this.props.biEvent(coreBi.events.topbar.pages.top_bar_PAGES_menu_drag, {
      source: this.props.biCategory,
      parent_item_name: getBiParentItemName(toItem, toId),
      category,
    });
  };

  onMenuItemToggled = (node: AnyFixMe, isCollapsed: boolean) => {
    const menuItemId = node.props.dataSource.id;
    this.props.setInPrefIscollapsedMenuItems(menuItemId, isCollapsed);
  };

  onMenuItemContextMenu = (e: AnyFixMe, menuItem: AnyFixMe) => {
    if (this.props.isMobile) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.handleSelectedItem(menuItem);

    this.props.openContextMenu({
      editorPositionX: e.clientX,
      editorPositionY: e.clientY,
      origin: RCM_ORIGIN.PAGES_MENU_ITEM,
    });
  };

  onContextMenu = (e: AnyFixMe) => {
    if (this.props.isMobile) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    this.props.openContextMenu({
      editorPositionX: e.clientX,
      editorPositionY: e.clientY,
      origin: RCM_ORIGIN.PAGES_MENU,
    });
  };

  handleSelectedItem = (menuItem: AnyFixMe) => {
    if (menuItem.type.isDropdown || menuItem.type.isLink) {
      this.props.onSelectNonPageItem();
    } else if (menuItem.type.isPage) {
      this.props.onSelectPageItem(menuItem.pageData.id);
    }

    this.setState({
      selectedMenuItemId: menuItem.id,
    });

    this.props.mainMenuPanelContext.setSelectedMenuItem(menuItem);

    this.props.navigateCallback(menuItem);
  };

  equalityComparer = (menuItem: AnyFixMe, selectedMenuItemId: AnyFixMe) =>
    menuItem.id === selectedMenuItemId;

  doScrollToSelectedNode = () => {
    let menuTreeNodeRect;
    let selectedRect;
    let newScrollTop = NaN;

    if (!this.scrollToSelectedNode) {
      return;
    }

    this.scrollToSelectedNode = false;

    //is that right? - no - need to apply ref to the selected
    const rootNode = ReactDOM.findDOMNode(this) as Element;
    const menuTreeNode = rootNode.querySelector('.pages-tree-container');
    const selectedNode = rootNode.querySelector('.menu-item-pp.selected');

    if (selectedNode) {
      menuTreeNodeRect = menuTreeNode.getBoundingClientRect();
      selectedRect = selectedNode.getBoundingClientRect();

      if (selectedRect.top < menuTreeNodeRect.top) {
        newScrollTop =
          this.getScrollTop() + (selectedRect.top - menuTreeNodeRect.top);
      } else if (selectedRect.bottom > menuTreeNodeRect.bottom) {
        newScrollTop =
          this.getScrollTop() + (selectedRect.bottom - menuTreeNodeRect.bottom);
      }

      if (!isNaN(newScrollTop)) {
        //@ts-expect-error Property 'updateScrollPosition' does not exist on type 'ReactInstance'
        this.refs.customScroll.updateScrollPosition(Math.max(0, newScrollTop));
      }
    }
  };

  clearTimeout = () =>
    this.props.timeoutHandler.clearTimeout(this.tooltipTimeout);

  getAddFolderTooltipContent = () => {
    let title = 'NewPages_Panel_Add_Folder_Dropdown_Title';
    let description = 'NewPages_Panel_Add_Folder_Text';
    let learnMoreText: string | undefined;
    let helpId = 'c29d5e56-186e-47c6-903a-7ee7908fc078';

    if (isAdvancedMenuOpen()) {
      title = experiment.isOpen('specs.santa-editor.dropdownOverSubmenu')
        ? 'custom_menu_manage_menu_add_item_dropdown'
        : 'custom_menu_manage_menu_add_item_submenu';
    }

    if (isMainMenuFlowEnabled()) {
      title = 'NewPages_Panel_Create_Folder_Organize_Title';
      description = 'NewPages_Panel_Create_Folder_Organize_Text';
      learnMoreText = 'NewPages_Panel_Create_Folder_Organize_Link';
      helpId = '224ec29b-570c-476c-b001-47ba0b248e41';
    }

    return (
      <AddTooltip
        title={title}
        description={description}
        learnMoreText={learnMoreText}
        onLearnMore={() => {
          this.props.onHelp(helpId);
          this.clearTimeout();
          this.setState({ isAddFolderHovered: false });
        }}
        onMouseEnter={this.handleMouseEnterAddFolder}
        onMouseLeave={() => {
          this.clearTimeout();
          this.setState({ isAddFolderHovered: false });
        }}
      />
    );
  };

  getAddPageTooltipContent = () => {
    const showNewContent =
      experiment.isOpen('se_tooManyPagesLimitation') &&
      !this.props.isEditorLoadedWithMoreThanMaxStaticPages;
    const newContent = {
      title: 'Pages_Menu_Add_Button_Disabled_Heavy_Sites_Tooltip_Title',
      description: 'Pages_Menu_Add_Button_Disabled_Heavy_Sites_Tooltip_Text',
      learnMoreText: 'Pages_Menu_Add_Button_Disabled_Heavy_Sites_Tooltip_Link',
      onLearnMore: () => {
        this.props.openPanel(
          'wixData.dynamicPagesStartingPanel',
          {
            notifyDataBindingAppTooManyPages:
              this.props.notifyDataBindingAppTooManyPages,
            staticPagesCount: this.props.staticPagesCount,
            origin: `pages_panel_add_tooltip_#${this.props.staticPagesCount}`,
          },
          true,
        );
      },
    };
    return React.createElement(
      AddTooltip,
      Object.assign(
        {
          title: 'Add_Page_TooManyPages_Tooltip_Title',
          description: 'Add_Page_TooManyPages_Tooltip_Text',
          onLearnMore: () => {
            this.props.onHelp('244dfae4-bd5c-4672-85ba-28938f4cb07f');
          },
        },
        showNewContent ? newContent : {},
      ),
    );
  };

  handleMouseEnterAddLink = () => {
    this.clearTimeout();
    this.setState({
      isAddLinkHovered: true,
      isAddFolderHovered: false,
      isAddPageHovered: false,
      isSiteGeneratorHovered: false,
      isUpdateSectionPresetsHovered: false,
    });
  };

  handleMouseLeaveAddLinkButton = () => {
    this.clearTimeout();
    this.tooltipTimeout = this.props.timeoutHandler.setTimeout(() => {
      this.setState({ isAddLinkHovered: false });
    }, SHORT_DELAY);
  };

  handleMouseEnterAddFolder = () => {
    this.clearTimeout();
    this.setState({
      isAddFolderHovered: true,
      isAddLinkHovered: false,
      isAddPageHovered: false,
      isSiteGeneratorHovered: false,
      isUpdateSectionPresetsHovered: false,
    });
  };

  handleMouseLeaveAddFolderButton = () => {
    this.clearTimeout();
    this.tooltipTimeout = this.props.timeoutHandler.setTimeout(() => {
      this.setState({ isAddFolderHovered: false });
    }, SHORT_DELAY);
  };

  handleMouseEnterAddPage = () => {
    this.clearTimeout();
    this.setState({
      isAddPageHovered: true,
      isAddLinkHovered: false,
      isAddFolderHovered: false,
      isSiteGeneratorHovered: false,
      isUpdateSectionPresetsHovered: false,
    });
    if (this.isAddButtonDisabled()) {
      this.props.biEvent(coreBi.events.pages.cant_add_page_tooltip_shown, {
        origin: 'pages_panel',
      });
    }
  };

  handleMouseLeaveAddPageButton = () => {
    this.clearTimeout();
    this.tooltipTimeout = this.props.timeoutHandler.setTimeout(() => {
      this.setState({ isAddPageHovered: false });
    }, SHORT_DELAY);
  };

  handleMouseEnterSiteGenerator = () => {
    this.clearTimeout();
    this.setState({
      isAddFolderHovered: false,
      isAddLinkHovered: false,
      isAddPageHovered: false,
      isSiteGeneratorHovered: true,
      isUpdateSectionPresetsHovered: false,
    });
  };

  handleMouseLeaveSiteGeneratorButton = () => {
    this.clearTimeout();
    this.tooltipTimeout = this.props.timeoutHandler.setTimeout(() => {
      this.setState({ isSiteGeneratorHovered: false });
    }, SHORT_DELAY);
  };

  handleMouseEnterUpdateSectionPresets = () => {
    this.clearTimeout();
    this.setState({
      isAddFolderHovered: false,
      isAddLinkHovered: false,
      isAddPageHovered: false,
      isSiteGeneratorHovered: false,
      isUpdateSectionPresetsHovered: true,
    });
  };

  handleMouseLeaveUpdateSectionPresets = () => {
    this.clearTimeout();
    this.tooltipTimeout = this.props.timeoutHandler.setTimeout(() => {
      this.setState({ isUpdateSectionPresetsHovered: false });
    }, SHORT_DELAY);
  };

  shouldShowTooManyPagesBanner = () =>
    experiment.isOpen('se_tooManyPagesLimitation') &&
    !this.props.isEditorLoadedWithMoreThanMaxStaticPages &&
    this.props.staticPagesCount >= WARNING_NUMBER_OF_STATIC_PAGES;

  isAddButtonDisabled = () =>
    experiment.isOpen('se_tooManyPagesLimitation') &&
    !this.props.isEditorLoadedWithMoreThanMaxStaticPages &&
    this.props.staticPagesCount >= MAX_STATIC_PAGES_ALLOWED;

  siteHasTooManyPages = () => this.props.menuItems.length >= 50;

  isTooManyPagesTooltipOpen = () => {
    if (experiment.isOpen('se_tooManyPagesLimitation')) {
      return (
        this.props.staticPagesCount >= MAX_STATIC_PAGES_ALLOWED &&
        !this.props.isEditorLoadedWithMoreThanMaxStaticPages
      );
    }

    return this.siteHasTooManyPages();
  };

  addPage = (clickOrigin: AddPageButton) => () => {
    this.props.onClickAddPage(clickOrigin);
    if (
      this.siteHasTooManyPages() &&
      !experiment.isOpen('se_tooManyPagesLimitation')
    ) {
      if (
        this.props.getTooManyPagesFrequencyCount % TOO_MANY_PAGES_FREQUENCY ===
        0
      ) {
        const onNotificationLinkClick = () => {
          this.props.onHelp(helpIds.NOTIFICATIONS.TOO_MANY_PAGES);
        };
        this.props.showUserActionNotification({
          message: 'Notifications_Too_Many_Pages_Text',
          title: 'Notifications_Too_Many_Pages_Text',
          type: 'warning',
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick,
          },
        });
      }
      this.props.setTooManyPagesFrequencyCount(
        this.props.getTooManyPagesFrequencyCount + 1,
      );
    }
  };

  getMenuItemById = (menuItemId: AnyFixMe) => {
    return this.props.findMenuItemById(this.props.menuItems, menuItemId);
  };

  renderOldPanelHeader = () => {
    return (
      <PanelHeader
        onClose={this.props.onClose}
        onHelp={this.props.onHelpHeader}
        className="header light"
      >
        <span className="title-menu-view-pp">{this.props.title}</span>
      </PanelHeader>
    );
  };

  renderNewPanelHeader = () => {
    return (
      <NewWorkspaceContentHeader
        onAddPage={this.addPage(AddPageButton.Header)}
        title={this.props.title}
        tooltipContent={this.props.titleAdd}
        showAddOptions={this.props.showAddOptions}
      />
    );
  };

  getMenuItems = () => {
    const menuItems = this.props.menuItems as MenuItem[];

    if (isNewPagesPanelEnabled()) {
      const [items, folders] = _.partition(menuItems, (item) =>
        Boolean(item.link),
      );
      return [...items, ...folders];
    }

    return menuItems;
  };

  getAddPageActionItem = () => {
    return {
      title: this.props.titleNewPage,
      type: 'action',
      symbolName: compIcon.toWBU('PageAdd'),
      onClick: this.addPage(AddPageButton.Main),
      automationId: 'add-page-panel-action',
      disabled: this.isAddButtonDisabled(),
      isShowTooltip: this.isTooManyPagesTooltipOpen(),
      tooltipContent: this.getAddPageTooltipContent(),
    };
  };

  getAddActions = () => {
    const { getAddItemsActions, showDynamicPages, shouldShowAddLinkButton } =
      this.props;
    const addPageAction = this.getAddPageActionItem();
    const dividerElement = { type: 'divider' } as MenuAddItemAction;
    const otherActions = getAddItemsActions({
      showDynamicPages,
      showSitePages: false,
      showSubmenu: false,
      showLinks: shouldShowAddLinkButton,
      onAddPageClick: this.addPage(AddPageButton.Main),
    });

    return [addPageAction, dividerElement, ...otherActions];
  };

  render() {
    return (
      <div
        className={cx('menu-view-pp', {
          'menu-view-pp_custom-menus': experiment.isOpen(
            'se_customMenus_newPagesPanel',
          ),
        })}
      >
        {this.shouldShowTooManyPagesBanner() && (
          <TooManyPagesBanner
            key="too-many-pages-comp"
            openPanel={this.props.openPanel}
            sendBi={this.props.biEvent}
            notifyDataBindingAppTooManyPages={
              this.props.notifyDataBindingAppTooManyPages
            }
            staticPagesCount={this.props.staticPagesCount}
          />
        )}

        {this.isNewWorkspace
          ? this.renderNewPanelHeader()
          : this.renderOldPanelHeader()}

        <div
          onContextMenu={this.onContextMenu}
          className={cx({
            'pages-tree-container': true,
            'disable-drag': !this.props.isDraggable,
          })}
        >
          <baseUI.treeDocker
            onNodeMoved={this.handleMovePage}
            treeClass="pages-tree"
            treeNodeClass="pages-tree-node"
            treeNodeLabelClass="menu-item-pp"
            draggedClass="rotated"
            previewClass="shadow"
            isDragAvailable={this.props.isDraggable}
            scrollTopGetter={this.getScrollTop}
            collapsedClass="tree-collapsed"
            emptyDropdownClass="empty-dropdown"
            className={cx({
              'pages-tree-docker': true,
              'pages-tree-shrinked': this.isShrink(),
            })}
          >
            <CustomScroll ref="customScroll">
              {/* TODO: remove structure type */}
              {/* TreeView is using onChange, equalityComparer and value props to deal with selected items */}
              <CustomMenusFirstTime />
              <baseUI.treeView
                nodeContent={menuItem}
                dropdownPlaceholderText={this.props.titleDropdownPlaceholder}
                structureType="tree"
                isDragAvailable={this.props.isDraggable}
                isMobile={this.props.isMobile}
                appPages={this.props.appPages}
                dataSource={this.getMenuItems()}
                collapsedSelector="isCollapsed"
                onNodeToggled={this.onMenuItemToggled}
                onNodeContextMenu={this.onMenuItemContextMenu}
                isRoot={true}
                onChange={this.handleSelectedItem}
                initialActions={this.props.initialActions}
                equalityComparer={this.equalityComparer}
                value={this.state.selectedMenuItemId}
                getMenuItemActions={this.props.getMenuItemActions}
                renameEnabled={this.props.renameEnabled}
                biEvent={this.props.biEvent}
                biCategory={this.props.biCategory}
                renamedMenuItemId={this.props.renamedItemId}
                loadingPageId={this.props.loadingPageId}
                loadingTrigger={this.props.loadingTrigger}
                setLoadingTrigger={this.props.setLoadingTrigger}
                className={cx('pages-tree', {
                  'pages-panel-ui-fixes': experiment.isOpen(
                    'se_pagesPanelDropdownPlaceholder',
                  ),
                })}
              />
            </CustomScroll>
          </baseUI.treeDocker>
        </div>
        {this.props.showAddOptions && (
          <footer key="add-container">
            <div className="add-options-container">
              <FooterContextMenu
                addActions={this.getAddActions()}
                onContextMenuItemsHover={this.props.onContextMenuItemsHover}
                category={'add_menu_item'}
                customButton={
                  <div
                    className="add-menu-page-button-container"
                    onClick={() =>
                      this.props.onAddPagesSelectionClick('add_menu_item')
                    }
                  >
                    <Button
                      automationId="add-menu-page-button"
                      className="add-menu-page-button add-page-panel-entry"
                      prefixIcon={<Add />}
                    >
                      <span className="add-title" data-aid="add-menu-item">
                        {this.props.titleAddMenuItem}
                      </span>
                    </Button>
                  </div>
                }
              />
              {this.props.shouldShowAddFolderButton && (
                <div
                  onMouseEnter={this.handleMouseEnterAddFolder}
                  onMouseLeave={this.handleMouseLeaveAddFolderButton}
                  className="add-menu-folder-button-container"
                >
                  <Tooltip
                    // @ts-expect-error
                    arrowAlignment="top"
                    isOpen={this.state.isAddFolderHovered}
                    content={this.getAddFolderTooltipContent()}
                    key="tooltip-add-folder"
                  >
                    <Button
                      onClick={this.props.onAddFolderClick}
                      automationId="add-menu-folder-button"
                      className="btn-confirm-secondary"
                    >
                      <symbols.symbol
                        name={
                          isAdvancedMenuOpen() ? 'addMenuFolder' : 'addFolder'
                        }
                      />
                    </Button>
                  </Tooltip>
                </div>
              )}

              {experiment.isOpen('se_addDesignerSection') && (
                <>
                  <div
                    onMouseEnter={this.handleMouseEnterSiteGenerator}
                    onMouseLeave={this.handleMouseLeaveSiteGeneratorButton}
                    className="add-menu-folder-button-container"
                  >
                    <Tooltip
                      // @ts-expect-error
                      arrowAlignment="top"
                      isOpen={this.state.isSiteGeneratorHovered}
                      content={'Site Generator Playground'}
                      key="tooltip-add-folder"
                      shouldTranslate={false}
                    >
                      <Button
                        onClick={this.props.openSiteGeneratorPlayground}
                        automationId="site-generator-button"
                        className="btn-inverted"
                      >
                        {/*<symbols.symbol name={'addPagePagesPanel'} />*/}
                        <symbols.symbol name={'sparkling-bulb-reg'} />
                      </Button>
                    </Tooltip>
                  </div>
                  <div
                    onMouseEnter={this.handleMouseEnterUpdateSectionPresets}
                    onMouseLeave={this.handleMouseLeaveUpdateSectionPresets}
                    className="add-menu-folder-button-container"
                  >
                    <Tooltip
                      // @ts-expect-error
                      arrowAlignment="top"
                      isOpen={this.state.isUpdateSectionPresetsHovered}
                      content={'Update section presets'}
                      key="tooltip-add-folder"
                      shouldTranslate={false}
                    >
                      <Button
                        onClick={this.props.openUpdateSectionsPanel}
                        className="btn-inverted"
                      >
                        <symbols.symbol name={'animation'} />
                      </Button>
                    </Tooltip>
                  </div>
                </>
              )}
            </div>
          </footer>
        )}
      </div>
    );
  }
}

// Urgent fix https://jira.wixpress.com/browse/WEED-22603 ->
// TODO: delete this and renderWhenMutated
//@ts-expect-error
MenuView.propTypes = {
  selectedMenuItemId: PropTypes.string,
  setInPrefIscollapsedMenuItems: PropTypes.func.isRequired,
  navigateCallback: PropTypes.func.isRequired,
  isDraggable: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onMoveMenuItem: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  mainMenuPanelContext: PropTypes.object.isRequired,
  findMenuItemById: PropTypes.func.isRequired,
  openPanel: PropTypes.func.isRequired,
  staticPagesCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onHelp: PropTypes.func.isRequired,
  onHelpHeader: PropTypes.func.isRequired,
  openContextMenu: PropTypes.func.isRequired,
  isEditorLoadedWithMoreThanMaxStaticPages: PropTypes.bool.isRequired,
  notifyDataBindingAppTooManyPages: PropTypes.func.isRequired,
  getMenuItemActions: PropTypes.func.isRequired,
  renamedItemId: PropTypes.string,
  initialActions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  renameEnabled: PropTypes.bool.isRequired,
  showAddOptions: PropTypes.bool.isRequired,
  onClickAddPage: PropTypes.func.isRequired,
  onAddLinkClick: PropTypes.func.isRequired,
  onAddFolderClick: PropTypes.func.isRequired,
  onAddPagesSelectionClick: PropTypes.func.isRequired,
  onContextMenuItemsHover: PropTypes.func.isRequired,
  getAddItemsActions: PropTypes.func.isRequired,
  showDynamicPages: PropTypes.bool,
  titleAdd: PropTypes.string.isRequired,
  titleAddMenuItem: PropTypes.string.isRequired,
  titleNewPage: PropTypes.string.isRequired,
  titleDropdownPlaceholder: PropTypes.string.isRequired,
  onSelectNonPageItem: PropTypes.func.isRequired,
  onSelectPageItem: PropTypes.func.isRequired,
  biEvent: PropTypes.func.isRequired,
  biCategory: PropTypes.string.isRequired,
  showUserActionNotification: PropTypes.func,
  timeoutHandler: PropTypes.object,
  setTooManyPagesFrequencyCount: PropTypes.func,
  getTooManyPagesFrequencyCount: PropTypes.number,
  onComponentMount: PropTypes.func,
  shouldShowAddFolderButton: PropTypes.bool,
  shouldShowAddLinkButton: PropTypes.bool,
  loadingPageId: PropTypes.string,
  loadingTrigger: PropTypes.object,
  setLoadingTrigger: PropTypes.func,
};

const Connected = connect(
  EDITOR_API,
  menuViewMapper.mapStateToProps,
  menuViewMapper.mapDispatchToProps,
)(hoc.renderWhenMutated(MenuView));

Connected.pure = MenuView;

export default Connected;

// @ts-nocheck
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';

import * as utils from '#packages/util';

import { HelpPanelFrame } from '../frames';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'exampleHelpPanel',
  PropTypes: {
    helpUrl(props, propName) {
      if (!utils.validate.url(props[propName])) {
        return new Error(
          'helpUrl prop must be a string representing a valid url',
        );
        //todo Shimi_Liderman 2/18/15 12:18 maybe replace with a more specific help-center url
      }
    },
    anchorId: PropTypes.string,
  },
  render() {
    return (
      <HelpPanelFrame
        panelName={this.props.panelName}
        helpUrl={this.props.helpUrl}
        anchorId={this.props.anchorId}
      />
    );
  },
});

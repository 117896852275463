// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as addPanel from '#packages/oldAddPanel';
import cannotAddList from './cannotAddList';
import dataRegistrationManager from '../../utils/dataRegistrationManager';

const repoNotLoadedSection = [getRepoNotLoadedSection()];

function getRepoNotLoadedSection() {
  const section = {
    showSectionHeader: false,
    props: {
      template: cannotAddList,
    },
    teaser: {
      hide: true,
    },
  };

  return _.merge(
    {},
    addPanelDataConsts.SECTIONS_STRUCTURE.GENERAL_SECTION_STRUCTURE,
    section,
  );
}

function getSections(editorApi) {
  if (
    editorApi.dsRead.wixapps.appbuilder.metadata.wasRepoLoadedSuccessfully()
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    return _.values(addPanel.sections.getSections().list);
  }
  return repoNotLoadedSection;
}

function register() {
  dataRegistrationManager.override.registerOverrideGroupsForCategory(
    addPanelDataConsts.CATEGORIES_ID.LIST,
    getSections,
  );
}

register();

export {};

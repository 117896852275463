import React from 'react';
import { RichText } from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import { frames } from '#packages/panels';
import * as util from '#packages/util';
import { THEME_MANAGER } from '#packages/helpIds';

import {
  mapStateToProps,
  mapDispatchToProps,
  type StateProps,
  type DispatchProps,
} from './newWorkspaceSaveTextStyleConfirmationMapper';

const { MessagePanelFrame } = frames;

interface SaveTextStyleConfirmationProps extends StateProps, DispatchProps {
  panelName: string;
  textStyleName: string;
  onConfirm: () => void;
}

class SaveTextStyleConfirmation extends React.Component<SaveTextStyleConfirmationProps> {
  handleOnSave = () => {
    this.props.onConfirm();
    this.close();
  };

  close = () => {
    this.props.closePanelByName(this.props.panelName);
  };

  openLearnMore = () => {
    const helpId = THEME_MANAGER.SAVE_THEME_MODAL_TEXT_THEME;
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      component: this.props.selectedComponentType,
      learn_more: true,
    };
    this.props.openHelpCenter(helpId, null, biHelpParams);
  };

  openThemePanel = () => {
    const panel = constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME;

    const panelOptions = {
      selectedView: constants.DESIGN_PANEL.VIEWS.TEXT,
      origin: 'TextStyleConfirmation',
    };

    this.props.openPanel(panel, panelOptions);
  };

  render() {
    return (
      <MessagePanelFrame
        panelName={this.props.panelName}
        theme="standard"
        title={translate('Text_Editor_Update_Save_Theme_Title')}
        illustration={
          <baseUI.symbol name="saveYourThemeClipart_NewWorkspace" />
        }
        confirmLabel={translate('Text_Editor_Update_Save_Theme_Update_Button')}
        onConfirm={this.handleOnSave}
        onCancel={this.close}
      >
        <RichText type="T06">
          <p>
            {translate('Text_Editor_Update_Save_Theme_Text1', {
              Theme_Name: this.props.textStyleName,
            })}{' '}
            <a onClick={this.openLearnMore}>
              {translate('Text_Editor_Update_Save_Theme_Text1_Link')}
            </a>
          </p>

          <p>
            {translate('Text_Editor_Update_Save_Theme_Text2')}{' '}
            <a onClick={this.openThemePanel}>
              {translate('Text_Editor_Update_Save_Theme_Text2_Link')}
            </a>
          </p>
        </RichText>
      </MessagePanelFrame>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SaveTextStyleConfirmation);

import * as tpa from '#packages/tpa';
import {
  InstallationOriginType,
  InstallInitiator,
  EditorType,
} from '@wix/platform-editor-sdk';
import { WIX_FILE_SHARE } from '@wix/app-definition-ids';

import type { MapDispatchToPropsFunction } from 'types/redux';

export interface FileShareWelcomeProps {
  fileShareMainAction: () => void;
}

export const fileShareWelcomeMapper: MapDispatchToPropsFunction<
  FileShareWelcomeProps,
  {}
> = () => ({
  fileShareMainAction: () => {
    tpa.superApps.addApp(WIX_FILE_SHARE, {
      biOrigin: tpa.constants.BI.type.ADD_APP_APP_BUTTON,
      platformOrigin: {
        type: EditorType.Classic,
        initiator: InstallInitiator.Editor,
        info: {
          type: InstallationOriginType.AddPanel,
        },
      },
      callback: (data: AnyFixMe) => {
        const pageId = data?.page?.id;
        console.log(pageId, 'pageId');
      },
    });
  },
});

import _ from 'lodash';
import React, { type MouseEvent } from 'react';

import * as UI from '#packages/baseUI';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import { cx, hoc, sections } from '#packages/util';

import pageResize from '../../../utils/mouseMoveActions/pageResize';
import baseResize from '../../../utils/mouseMoveActions/baseResize';
import baseRotate from '../../../utils/mouseMoveActions/baseRotate';
import closeGaps from '../../../utils/mouseMoveActions/closeGaps';
import baseDragAndPush from '../../../utils/mouseMoveActions/baseDragAndPush';

import { mapStateToProps, mapDispatchToProps } from './knobsBoxMapper';
import type { SendBiFunction } from 'types/bi';
import type { CompRef } from 'types/documentServices';
import type { RegisterMouseMoveAction } from '../../../app/APISections/mouseActionsWrapper';

const SHOW_TOOLTIP_DELAY = 800;

const CORNERS = [
  'TOP_LEFT',
  'TOP',
  'TOP_RIGHT',
  'RIGHT',
  'BOTTOM_RIGHT',
  'BOTTOM',
  'BOTTOM_LEFT',
  'LEFT',
];
const RESIZE_HANDLE_CURSOR_CLASSES = [
  'top-left-resize-cursor',
  'top-resize-cursor',
  'top-right-resize-cursor',
  'right-resize-cursor',
  'bottom-right-resize-cursor',
  'bottom-resize-cursor',
  'bottom-left-resize-cursor',
  'left-resize-cursor',
];
export interface KnobsBoxOwnProps {
  isDuringMouseAction: boolean;
  isResizing: boolean;
  isDragging: boolean;
}

export interface KnobsBoxStateProps {
  componentUIColor: string;
  knobsMap: any;
  rotationInDegrees: number;
  parentRotationInDegrees: number;
  dragAndHandlersClass: string;
  gaps: any;
  isPage: boolean;
  comps: any | Array<any>;
  compType?: string | Array<string>;
  isBoxRotated: boolean;
  shouldChangeVerticalResizeDesign: boolean;
  componentType: string;
  compToDragAndPush: any[];
}

export interface KnobsBoxDispatchProps {
  registerMouseMoveAction: RegisterMouseMoveAction;
  reportComponentResized: (
    componentType: string,
    directionWithRotation: string,
  ) => void;
  biEvent: SendBiFunction;
  updateGaps: Function;
  addToHistory: Function;
  setRotateKnobRect: Function;
  setIsMouseUpSelectionEnabled: Function;
  updateLayout: Function;
  getSelectedComponentBiFields: () => Record<string, string>;
  startResizeAndPush: (event: MouseEvent, components: CompRef[]) => void;
}

interface KnobsBoxProps
  extends KnobsBoxOwnProps,
    KnobsBoxStateProps,
    KnobsBoxDispatchProps {}

class KnobsBox extends React.PureComponent<KnobsBoxProps> {
  rotateRef = React.createRef<HTMLSpanElement>();
  cancelRotateRef = React.createRef<HTMLSpanElement>();
  showTooltipTimeout?: number;

  startDragAndPush = (event: AnyFixMe, origin: string) => {
    this.props.registerMouseMoveAction(baseDragAndPush, {
      evt: event,
      comps: this.props.compToDragAndPush,
    });
    this.props.biEvent(coreBi.events.editBox.DRAG_BY_HANDLE, {
      ...this.props.getSelectedComponentBiFields(),
      component_type: this.props.compType,
      origin,
    });
    this.hideKnobTooltip('dragWithAnchorsVerticalTooltip');
    event.stopPropagation();
  };

  startCloseGap = (event: AnyFixMe) => {
    this.props.registerMouseMoveAction(closeGaps, {
      evt: event,
      comps: this.props.comps,
    });

    this.hideKnobTooltip('closeGapsKnobTooltip');
    event.stopPropagation();
  };

  closeGap = (isPage: AnyFixMe, event: AnyFixMe) => {
    const gapKey = isPage ? 'headerToPagesGap' : 'pagesToFooterGap';
    const updatedGap = _.set({}, gapKey, 0);

    this.props.updateGaps(updatedGap);
    this.props.biEvent(coreBi.events.editBox.DRAG_GAP, {
      gap_type: gapKey,
      gap_size_start: _.get(this.props.gaps, gapKey),
      gap_size_end: 0,
    });
    this.props.addToHistory('gap removed');

    event.stopPropagation();
  };

  startPageResize = (event: AnyFixMe) => {
    this.props.registerMouseMoveAction(pageResize, {
      evt: event,
      comps: this.props.comps,
    });
    this.hidePageResizeKnobTooltip();
    event.stopPropagation();
  };

  isComponentRotated = () => {
    return this.props.rotationInDegrees !== 0;
  };

  resetPageSize = (event: AnyFixMe) => {
    pageResize.reset();
    this.props.biEvent(
      coreBi.events.pageResize.PAGE_MIN_HEIGHT_RESET_MIN_HEIGHT,
    );
    event.stopPropagation();
  };

  setRotateKnobRect = () => {
    if (this.props.isDuringMouseAction) {
      return;
    }

    const rotateKnobRect = this.props.rotationInDegrees
      ? this.cancelRotateRef.current &&
        this.cancelRotateRef.current.getBoundingClientRect()
      : this.rotateRef.current &&
        this.rotateRef.current.getBoundingClientRect();

    const rotateKnobJsonRect = _.pick(rotateKnobRect, [
      'x',
      'y',
      'width',
      'height',
      'top',
      'right',
      'bottom',
      'left',
    ]);

    if (rotateKnobRect) {
      this.props.setRotateKnobRect(rotateKnobJsonRect);
    }
  };

  componentDidMount() {
    this.setRotateKnobRect();
  }

  componentDidUpdate() {
    this.setRotateKnobRect();
  }

  /**
   * if component has an opposite rotation value from parent rotation, resize direction should be adjusted
   * @param direction
   * @returns {string}
   */
  getDirectionWithRotation = (direction: AnyFixMe) => {
    const compRotation = this.props.rotationInDegrees;
    const parentRotation = this.props.parentRotationInDegrees;
    const isBothRotated = compRotation && parentRotation;
    //todo: add generic calc
    const isOppositeRotation =
      isBothRotated && Math.round(compRotation + compRotation) === 360;
    const rotateValue =
      (isOppositeRotation && this.props.parentRotationInDegrees) || 0;
    const shiftValue = Math.round(rotateValue / 90) * 2;
    const currentDirectionIndex =
      constants.RESIZE_DIRECTIONS.indexOf(direction);
    const newDirectionIndex =
      (currentDirectionIndex + shiftValue) % constants.RESIZE_DIRECTIONS.length;
    return constants.RESIZE_DIRECTIONS[newDirectionIndex];
  };

  getResizeHandleCursorClass = (corner: AnyFixMe) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/index-of
    const cornerIndex = _.indexOf(CORNERS, corner);

    const rotateValue = this.props.isBoxRotated
      ? this.props.rotationInDegrees
      : 0;
    let rotationInDegrees = rotateValue + 22.5;

    if (rotationInDegrees >= 360) {
      rotationInDegrees -= 360;
    }

    const rotateCursorCounter = Math.floor(rotationInDegrees / 45);

    let resizeHandleCursorClassIndex = cornerIndex + rotateCursorCounter;

    if (resizeHandleCursorClassIndex >= RESIZE_HANDLE_CURSOR_CLASSES.length) {
      resizeHandleCursorClassIndex -= RESIZE_HANDLE_CURSOR_CLASSES.length;
    }

    const resizeHandleCursorClass =
      RESIZE_HANDLE_CURSOR_CLASSES[resizeHandleCursorClassIndex];

    return resizeHandleCursorClass;
  };

  cancelRotation = (event: AnyFixMe) => {
    this.props.setIsMouseUpSelectionEnabled(false);
    this.props.updateLayout(this.props.comps, { rotationInDegrees: 0 });
    this.props.biEvent(coreBi.events.editBox.CANCEL_ROTATE_COMPONENT);
    this.props.addToHistory('Rotation canceled');
    event.stopPropagation();
  };

  startResize = (event: AnyFixMe) => {
    const directionName = event.target.getAttribute('data-direction-name');
    const directionWithRotation = this.getDirectionWithRotation(directionName);
    this.props.registerMouseMoveAction(baseResize, {
      evt: event,
      directionName: directionWithRotation,
      comps: this.props.comps,
    });
    this.props.reportComponentResized(
      this.props.componentType,
      directionWithRotation,
    );
    event.stopPropagation();
  };

  startRotate = (event: AnyFixMe) => {
    this.props.registerMouseMoveAction(baseRotate, {
      evt: event,
      comps: this.props.comps,
    });
    event.stopPropagation();
  };

  startResizeAndPush = (event: MouseEvent) => {
    this.props.startResizeAndPush(event, this.props.comps);

    this.props.biEvent(coreBi.events.editBox.RESIZE_BY_HANDLE, {
      ...this.props.getSelectedComponentBiFields(),
      component_type: this.props.compType,
      origin: 'indicator',
    });

    this.hideKnobTooltip('resizeWithAnchorsBottomTooltip');
  };

  showKnobTooltip = (tooltipId: AnyFixMe) => {
    if (!this.props.isResizing && !this.props.isDragging) {
      this.showTooltipTimeout = window.setTimeout(function () {
        UI.tooltipManager.show(tooltipId);
      }, SHOW_TOOLTIP_DELAY);
    }
  };

  hideKnobTooltip = (tooltipId: AnyFixMe) => {
    if (this.showTooltipTimeout) {
      window.clearTimeout(this.showTooltipTimeout);
      this.showTooltipTimeout = null;
    }

    UI.tooltipManager.hide(tooltipId);
  };

  getDragHandlersClass = (
    handlerType: AnyFixMe,
    handlerDirection: AnyFixMe,
  ) => {
    return cx(
      `handle handle-${handlerDirection} handle-${handlerType}-with-anchors`,
      this.props.dragAndHandlersClass,
      sections.isSectionsEnabled() && 'section',
    );
  };

  showDragWithAnchorsKnobTooltip = () => {
    this.showKnobTooltip('dragWithAnchorsVerticalTooltip');
  };

  showResizeWithAnchorsKnobTooltip = () => {
    this.showKnobTooltip('resizeWithAnchorsBottomTooltip');
  };

  showPageResizeKnobTooltip = () => {
    this.showKnobTooltip('pageResizeKnobTooltip');
  };

  showCloseGapsKnobTooltip = () => {
    this.showKnobTooltip('closeGapsKnobTooltip');
  };

  hidePageResizeKnobTooltip = () => {
    this.hideKnobTooltip('pageResizeKnobTooltip');
  };

  hideDragWithAnchorsKnobTooltip = () => {
    this.hideKnobTooltip('dragWithAnchorsVerticalTooltip');
  };

  hideCloseGapsKnobTooltip = () => {
    this.hideKnobTooltip('closeGapsKnobTooltip');
  };

  hideResizeWithAnchorsKnobTooltip = () => {
    this.hideKnobTooltip('resizeWithAnchorsBottomTooltip');
  };

  getResizeIndicationClass = () => {
    const { componentUIColor, shouldChangeVerticalResizeDesign } = this.props;

    if (!shouldChangeVerticalResizeDesign) return;

    if (componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE)
      return 'new-design-orange';

    if (componentUIColor === constants.COMPONENT_UI_COLORS.BLUE)
      return 'new-design-blue';
  };

  render() {
    const { knobsMap, shouldChangeVerticalResizeDesign } = this.props;

    return (
      <>
        {knobsMap.topLeft && (
          <span
            data-direction-name="topLeft"
            ref="resizeTopLeft"
            onMouseDown={this.startResize}
            key="resizeTopLeft"
            className={`${this.getResizeHandleCursorClass(
              'TOP_LEFT',
            )} handle handle-resize-corner top left`}
          />
        )}

        {knobsMap.top ||
        (knobsMap.dragAndPush && shouldChangeVerticalResizeDesign) ? (
          <span
            data-direction-name="top"
            onMouseDown={
              shouldChangeVerticalResizeDesign
                ? (e) => this.startDragAndPush(e, 'stretch button')
                : this.startResize
            }
            key="resizeTopCenter"
            className={cx(
              this.getResizeHandleCursorClass('TOP'),
              'handle',
              'handle-resize-side',
              'top',
              this.getResizeIndicationClass(),
            )}
          />
        ) : (
          knobsMap.bottomPage &&
          shouldChangeVerticalResizeDesign && (
            <span
              data-direction-name="top"
              onMouseDown={this.startPageResize}
              onDoubleClick={this.resetPageSize}
              onMouseEnter={this.showPageResizeKnobTooltip}
              onMouseLeave={this.hidePageResizeKnobTooltip}
              key="resizeTopCenter"
              className={cx(
                this.getResizeHandleCursorClass('TOP'),
                'handle',
                'handle-resize-side',
                'top',
                this.getResizeIndicationClass(),
              )}
            />
          )
        )}

        {knobsMap.topRight && (
          <span
            data-direction-name="topRight"
            onMouseDown={this.startResize}
            key="resizeTopRight"
            className={`${this.getResizeHandleCursorClass(
              'TOP_RIGHT',
            )} handle handle-resize-corner top right`}
          />
        )}

        {knobsMap.right && (
          <span
            data-direction-name="right"
            onMouseDown={this.startResize}
            key="resizeMiddleRight"
            className={`${this.getResizeHandleCursorClass(
              'RIGHT',
            )} handle handle-resize-side right`}
          />
        )}

        {knobsMap.bottomRight && (
          <span
            data-direction-name="bottomRight"
            onMouseDown={this.startResize}
            key="resizeBottomRight"
            className={`${this.getResizeHandleCursorClass(
              'BOTTOM_RIGHT',
            )} handle handle-resize-corner bottom right`}
          />
        )}

        {knobsMap.bottom && (
          <span
            data-hook="knob-box-resize-bottom"
            data-direction-name="bottom"
            onMouseDown={
              shouldChangeVerticalResizeDesign
                ? this.startResizeAndPush
                : this.startResize
            }
            key="resizeBottomCenter"
            className={cx(
              this.getResizeHandleCursorClass('BOTTOM'),
              'handle',
              'handle-resize-side',
              'bottom',
              this.getResizeIndicationClass(),
            )}
          />
        )}

        {knobsMap.bottomPage && (
          <UI.tooltip
            id="pageResizeKnobTooltip"
            key="pageResizeKnobTooltip"
            value="OnStage_PageMinHeightHandle_Tooltip"
            alignment="top"
            delay="500"
            openTriggers={[]}
            closeTriggers={[]}
            onOpen={() => {
              this.props.biEvent(
                coreBi.events.pageResize.PAGE_MIN_HEIGHT_TOOLTIP_APPEARED,
              );
            }}
          >
            <span
              id="resize-page"
              onMouseDown={this.startPageResize}
              onDoubleClick={this.resetPageSize}
              onMouseEnter={this.showPageResizeKnobTooltip}
              onMouseLeave={this.hidePageResizeKnobTooltip}
              key="resizeBottomPage"
              className={cx(
                {
                  'footer-handle': this.props.compType === 'FooterContainer',
                },
                this.getDragHandlersClass('resize', 'push'),
              )}
            >
              <UI.symbol name="adjustPageHeightButtonIcon" />
              {translate('OnStage_PageMinHeightHandle_Label')}
            </span>
          </UI.tooltip>
        )}

        {knobsMap.bottomLeft && (
          <span
            data-direction-name="bottomLeft"
            onMouseDown={this.startResize}
            key="resizeBottomLeft"
            className={`${this.getResizeHandleCursorClass(
              'BOTTOM_LEFT',
            )} handle handle-resize-corner bottom left`}
          />
        )}

        {knobsMap.left && (
          <span
            data-direction-name="left"
            onMouseDown={this.startResize}
            key="resizeMiddleLeft"
            className={`${this.getResizeHandleCursorClass(
              'LEFT',
            )} handle handle-resize-side left`}
          />
        )}

        {knobsMap.rotate && (
          <span
            onMouseDown={this.startRotate}
            key="rotate"
            ref={this.rotateRef}
            className="handle handle-rotate"
          >
            <UI.symbol name="rotate" />
          </span>
        )}

        {knobsMap.rotate && this.isComponentRotated() && (
          <span
            onMouseDown={this.cancelRotation}
            style={{
              transform: `rotate(${
                360 - this.props.rotationInDegrees
              }deg) translate3d(0,0,0)`,
            }}
            key="cancelRotate"
            ref={this.cancelRotateRef}
            className="handle handle-rotate-cancel"
          >
            <UI.symbol name="rotateCancel" />
          </span>
        )}

        {knobsMap.dragAndPush && (
          <UI.tooltip
            id="dragWithAnchorsVerticalTooltip"
            key="dragWithAnchorsVerticalTooltip"
            value="ONSTAGE_DragHandle_Tooltip"
            alignment="top"
            openTriggers={[]}
            closeTriggers={[]}
          >
            <span
              onMouseEnter={this.showDragWithAnchorsKnobTooltip}
              onMouseLeave={this.hideDragWithAnchorsKnobTooltip}
              onMouseDown={(e) => this.startDragAndPush(e, 'drag button')}
              key="dragWithAnchorsVertical"
              className={this.getDragHandlersClass('drag', 'push')}
            >
              <UI.symbol name="pushAndEnforceAnchors" />
              {translate('ONSTAGE_DragHandle_Hover_Text')}
            </span>
          </UI.tooltip>
        )}

        {knobsMap.closeGaps && (
          <UI.tooltip
            id="closeGapsKnobTooltip"
            key="closeGapsTooltip"
            value={
              this.props.isPage
                ? 'Onstage_DragPage_CloseGap_Tooltip'
                : 'OnStage_DragFooter_CloseGap_Tooltip'
            }
            alignment="top"
            openTriggers={[]}
            closeTriggers={[]}
          >
            <span
              onMouseEnter={this.showCloseGapsKnobTooltip}
              onMouseLeave={this.hideCloseGapsKnobTooltip}
              onDoubleClick={this.closeGap.bind(this, this.props.isPage)}
              onMouseDown={this.startCloseGap}
              className={this.getDragHandlersClass('drag', 'pull')}
            >
              <UI.symbol name="pullAndEnforceAnchors" />
              {translate(
                this.props.isPage
                  ? 'Onstage_DragPage_CloseGap_Label'
                  : 'OnStage_DragFooter_CloseGap_Label',
              )}
            </span>
          </UI.tooltip>
        )}

        {knobsMap.resizeAndPush &&
          (sections.isSectionsEnabled()
            ? !shouldChangeVerticalResizeDesign
            : true) && (
            <UI.tooltip
              id="resizeWithAnchorsBottomTooltip"
              key="resizeWithAnchorsBottomTooltip"
              value="OnStage_StretchHandle_Tooltip"
              alignment="bottom"
              openTriggers={[]}
              closeTriggers={[]}
            >
              <span
                data-aid="drag-handle-indication"
                onMouseEnter={this.showResizeWithAnchorsKnobTooltip}
                onMouseLeave={this.hideResizeWithAnchorsKnobTooltip}
                onMouseDown={this.startResizeAndPush}
                key="resizeWithAnchorsBottom"
                className={this.getDragHandlersClass('resize', 'push')}
              >
                <UI.symbol name="resizeAndEnforceAnchors" />
                {translate('OnStage_StretchHandle_Hover_Text')}
              </span>
            </UI.tooltip>
          )}
      </>
    );
  }
}

const ConnectedComponent = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(KnobsBox);

ConnectedComponent.pure = KnobsBox;

export default ConnectedComponent;

import React, { type FunctionComponent } from 'react';

import TopBarFlagButton from '../topBarFlagButton/topBarFlagButton';
import TooltipContent from '../tooltipContent';
import DropDown from '../dropDown/dropDown';
import LanguagePanel from '../dropPanel/languagePanel';
import experiment from 'experiment';
import { LanguagePanelButton } from '../dropPanel/languagePanelButton';

const TOOLTIP_DATA = {
  title: 'SiteLanguages_Tooltip_Title',
  text: 'SiteLanguages_Tooltip_Text',
};

interface LanguageSelectProps {
  isOpened: boolean;
  delayedFadeOut?: boolean;
  countryCode: string;
  languageCode: string;
  regionCode: string;
  shouldCurrentLanguageInvisible: boolean;
  shouldHideLanguageIcon: boolean;
  onButtonClick: () => void;
  onPanelMouseLeave?: () => void;
  onPanelMouseEnter?: () => void;
  closePanelHandle?: () => void;
}

const LanguageSelect: FunctionComponent<LanguageSelectProps> = (props) => {
  const isUseLocaleUIOpened = experiment.isOpen('specs.ml.UseLocaleUI');

  const tooltipContent = props.isOpened ? null : (
    <TooltipContent tooltipData={TOOLTIP_DATA} />
  );
  const dropDownContent = (
    <LanguagePanel
      biOrigin="top_bar"
      key="languageDropPanel"
      closeHandle={props.closePanelHandle}
      onMouseLeave={props.onPanelMouseLeave}
    />
  );

  return (
    <DropDown
      isOpen={props.isOpened}
      panelContent={dropDownContent}
      onPanelMouseEnter={props.onPanelMouseEnter}
      onPanelMouseLeave={props.onPanelMouseLeave}
      panelClassName={'drop-panel-language-bubble'}
      delayedFadeOut={props.delayedFadeOut}
    >
      {isUseLocaleUIOpened ? (
        <LanguagePanelButton
          languageCode={props.languageCode}
          onClick={props.onButtonClick}
          tooltip={tooltipContent}
          regionCode={props.regionCode}
          countryCode={props.countryCode}
        />
      ) : (
        <TopBarFlagButton
          className="top-bar-button-language"
          automationId="top-bar-button-language"
          onClick={props.onButtonClick}
          countryCode={props.countryCode}
          languageCode={props.languageCode}
          toolTip={tooltipContent}
          shouldCurrentLanguageInvisible={props.shouldCurrentLanguageInvisible}
          shouldHideLanguageIcon={props.shouldHideLanguageIcon}
        />
      )}
    </DropDown>
  );
};

export default LanguageSelect;

import styles from './aiWriterPanel.scss';
import React, { useState, useEffect, useRef } from 'react';
import {
  biLogger,
  cx,
  guidUtils,
  smartForm,
  fedopsLogger,
} from '#packages/util';
import { connectWithScope } from '#packages/apilib';
import { hooks } from '#packages/history';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '#packages/constants';
import type { BusinessTypeTypes } from '#packages/panels';
import {
  AiInfoBlock,
  AiTermsAndConditions,
  BusinessInfo,
} from '#packages/baseUI';
import { translate } from '#packages/i18n';
import {
  Button,
  Composites,
  InfoIcon,
  TextInputMultiline,
  TextLabel,
  TextButton,
  TextInput,
  Text,
} from '@wix/wix-base-ui';
import { SelectionTags } from '@wix/wix-base-ui/next';
import { SparklesBold } from '@wix/wix-ui-icons-common/classic-editor';
import {
  aiTextGeneratorAdditionalActions,
  aiTextGeneratorSettingsChanged,
  smartWriterCreateTextClick,
  aiTextGeneratorRequestOutput,
  smartWriterUseTextClick,
  textTextChanged,
  aiTextGeneratorPanelOpen,
  panelOpened,
  quickNavigationClicked,
  aiTextGeneratorNotificationShown,
} from '@wix/bi-logger-editor/v2';

import { mapDispatchToProps, mapStateToProps } from './aiWriterPanelMapper';
import type { AiWriterPanelProps } from './aiWriterPanelTypes';
import { AiWriterPanelScope } from './aiWriterPanelScope';
import {
  BUSINESS_TYPE_PANEL_ORIGIN,
  MAX_DESCRIPTION_LENGTH,
  MAX_TONE_LENGTH,
  PANEL_TYPE,
  TONE_OF_VOICE,
  AI_WRITER_SESSION_KEY,
} from './constants';
import {
  createQueryOutputBiData,
  getTokensFromQueryOutput,
} from './utils/biData';

const AiWriterPanel: React.FC<AiWriterPanelProps> = ({
  onClick,
  onUndo,
  showFailStateModal,
  defaultFormState,
  onLoading,
  origin,
  onWrongPageClick,
  onAddSectionClick,
  isIrrelevantPage,
  isEmptyState,
  isPreInjection,
  businessData,
  sendUndoRedoBI,
}) => {
  const [description, setDescription] = useState<string>('');
  const [toneInput, setToneInput] = useState<string>('');
  const [toneOfVoice, setToneOfVoice] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toneSelect, setToneSelect] = useState<string | null>(null);
  const [isInputValueDirty, setIsInputValueDirty] = useState<boolean>(false);
  const [isStatusError, setIsStatusError] = useState<boolean>(false);

  const sessionId = useRef(guidUtils.getGUID());

  const descirptionFieldControls = smartForm.useSmartField<string>(
    description,
    [smartForm.maxLengthValidator(MAX_DESCRIPTION_LENGTH)],
  );

  const isFormInvalid =
    !descirptionFieldControls.isValid && descirptionFieldControls.isDirty;

  const isCreatingBlocked =
    isIrrelevantPage ||
    isLoading ||
    isFormInvalid ||
    isStatusError ||
    !description;

  useEffect(() => {
    const aiWriterSessionValues = JSON.parse(
      sessionStorage.getItem(AI_WRITER_SESSION_KEY),
    );

    if (aiWriterSessionValues) {
      setDescription(aiWriterSessionValues.description);
      setToneInput(aiWriterSessionValues.toneInput);
      setToneSelect(aiWriterSessionValues.toneSelect);
    }
    const queryValues = {
      description: aiWriterSessionValues?.description || '',
      toneInput: aiWriterSessionValues?.toneInput || '',
      toneSelect: aiWriterSessionValues?.toneSelect || '',
    };

    const query = { ...defaultFormState, queryValues };

    const undoSubscribe = hooks.undoWasPerformed.tap(
      ({ originParams, targetParams }) => {
        sendUndoRedoBI(originParams, targetParams);
      },
    );
    const redoSubscribe = hooks.redoWasPerformed.tap(
      ({ originParams, targetParams }) => {
        sendUndoRedoBI(originParams, targetParams);
      },
    );
    biLogger.report(
      panelOpened({
        panel_name: WORKSPACE_RIGHT_PANEL_NAMES.AI_PAGE_WRITER,
        origin,
      }),
    );

    biLogger.report(
      aiTextGeneratorPanelOpen({
        query: JSON.stringify(query),
        panelType: PANEL_TYPE,
        sessionId: sessionId.current,
        origin,
      }),
    );

    return () => {
      undoSubscribe();
      redoSubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const currentValues = JSON.stringify({
      description,
      toneInput,
      toneSelect,
    });

    sessionStorage.setItem(AI_WRITER_SESSION_KEY, currentValues);
  }, [description, toneInput, toneSelect]);

  useEffect(() => {
    if (!isIrrelevantPage) return;

    biLogger.report(
      aiTextGeneratorNotificationShown({
        fieldName: 'null',
        type: 'error',
        message: isEmptyState
          ? `${translate('ai_text_to_page_blank_banner')} ${translate(
              'ai_text_to_page_blank_banner_cta',
            )}`
          : `${translate('ai_text_to_page_banner')} ${translate(
              'ai_text_to_page_banner_cta',
            )}`,
        sessionId: sessionId.current,
        panelType: PANEL_TYPE,
      }),
    );
  }, [isIrrelevantPage, isEmptyState]);

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading, onLoading]);

  useEffect(() => {
    if (!isFormInvalid) return;

    biLogger.report(
      aiTextGeneratorNotificationShown({
        fieldName: 'freeText',
        type: 'error',
        message: `${translate(
          'ai_text_to_page_free_text_tooltip_error_max_length',
        )}`,
        sessionId: sessionId.current,
        panelType: PANEL_TYPE,
      }),
    );
  }, [isFormInvalid]);

  const handleToneChange = (value: string) => {
    setIsInputValueDirty(true);

    biLogger.report(
      aiTextGeneratorSettingsChanged({
        fieldName: 'tone of voice',
        mandatoryField: 'no',
        newValue: value,
        panelType: PANEL_TYPE,
        sessionId: sessionId.current,
      }),
    );
  };

  const handleDescriptionBlur = () => {
    if (!isInputValueDirty) return;

    biLogger.report(
      aiTextGeneratorSettingsChanged({
        fieldName: 'freeText',
        mandatoryField: 'yes',
        newValue: description,
        panelType: PANEL_TYPE,
        sessionId: sessionId.current,
      }),
    );

    descirptionFieldControls.setDirty();
    setIsInputValueDirty(false);
  };

  const handleToneInputBlur = () => {
    if (!isInputValueDirty) return;

    biLogger.report(
      aiTextGeneratorSettingsChanged({
        fieldName: 'customTone',
        mandatoryField: 'no',
        newValue: toneInput,
        panelType: PANEL_TYPE,
        sessionId: sessionId.current,
      }),
    );

    setIsInputValueDirty(false);
  };

  const handleBusinessDataChange = (
    data: BusinessTypeTypes.BusinessInfoData,
  ) => {
    biLogger.report(
      aiTextGeneratorSettingsChanged({
        fieldName: 'site info',
        mandatoryField: 'yes',
        newValue: JSON.stringify(data),
        panelType: PANEL_TYPE,
        sessionId: sessionId.current,
      }),
    );
  };

  const handleOnWrongPageClick = () => {
    onWrongPageClick();
    sendAdditionalActionEvent(
      `${translate('ai_text_to_page_banner')} -> click on "${translate(
        'ai_text_to_page_banner_cta',
      )}" CTA`,
    );

    biLogger.report(
      quickNavigationClicked({
        origin: PANEL_TYPE,
      }),
    );
  };

  const handleOnAddSectionClick = () => {
    onAddSectionClick();
    sendAdditionalActionEvent(
      `${translate('ai_text_to_page_blank_banner')} -> click on "${translate(
        'ai_text_to_page_blank_banner_cta',
      )}" CTA`,
    );
  };

  useEffect(() => {
    const isToneInput = toneInput.length > 0;

    if (toneInput.length > MAX_TONE_LENGTH) {
      setIsStatusError(true);
    }

    if (isStatusError && toneInput.length <= MAX_TONE_LENGTH) {
      setIsStatusError(false);
    }

    if (toneSelect !== null && isToneInput) {
      setToneInput('');
    }

    if (!isToneInput && toneSelect === null && toneOfVoice !== null) {
      return setToneOfVoice(() => null);
    }
    setToneOfVoice(() => (isToneInput ? toneInput : translate(toneSelect)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toneInput, toneSelect]);

  const handleDescriptionChange = (value: string) => {
    setIsInputValueDirty(true);
    setDescription(value);
    descirptionFieldControls.setValue(value);
  };

  const handleToneInputChange = (value: string) => {
    setIsInputValueDirty(true);
    setToneInput(value.trim());
  };
  const handleToneSelectChange = (value: string) => {
    setToneSelect(value);
    handleToneChange(value);
  };

  const sendAdditionalActionEvent = (actionName: string) => {
    biLogger.report(
      aiTextGeneratorAdditionalActions({
        actionName,
        panelType: PANEL_TYPE,
        sessionId: sessionId.current,
      }),
    );
  };

  const handleInfoIconHover = (fieldName: string) =>
    sendAdditionalActionEvent(`${fieldName} -> info button -> hover`);

  const handleOnClick = (origin: string) => {
    const startTimestampt = performance.now();
    setIsLoading(() => true);
    const isFormValid = descirptionFieldControls.isValid;
    fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.AI_WRITER);

    const query = {
      ...businessData,
      freeText: description,
      tone: toneInput.length > 0 ? `custom/${toneInput}` : toneSelect,
    };

    biLogger.report(
      smartWriterCreateTextClick({
        actionOrigin: origin,
        isAiTextCreator: true,
        sessionId: sessionId.current,
        panelType: PANEL_TYPE,
        success: isFormValid,
        query: JSON.stringify(query),
      }),
    );

    onClick(description, toneOfVoice)
      .then(({ queryOutput, guid }: AnyFixMe) => {
        const duration = Math.floor(performance.now() - startTimestampt);
        const additionalBiData = createQueryOutputBiData(queryOutput);
        const componentIds = Object.values(queryOutput.idMap).join(', ');
        const pageComponents = queryOutput.idMap;
        const queries = [queryOutput.originalOutline, queryOutput.outline];
        const sectionComponents = Object.keys(queries[0]).map((key) =>
          queries.map((parentSection) => parentSection[key]),
        );
        sectionComponents.flatMap((section) =>
          Object.entries(section[0]).map(([component, prevText], index) => {
            const new_text = Object.values(section[1])[index];
            const component_id = pageComponents[component];

            return biLogger.report(
              textTextChanged({
                actionOrigin: origin,
                text: prevText as string,
                textOrigin: new_text as string,
                component_id,
                origin: 'autofill (AI Writer Panel)',
              }),
            );
          }),
        );

        biLogger.report(
          aiTextGeneratorRequestOutput({
            output_id: guid,
            actionOrigin: origin,
            duration,
            sessionId: sessionId.current,
            panelType: PANEL_TYPE,
            query: JSON.stringify(query),
            ...additionalBiData,
          }),
        );

        biLogger.report(
          smartWriterUseTextClick({
            actionOrigin: origin,
            isAiTextCreator: true,
            clickOrigin: 'autofill (AI Writer Panel)',
            duration,
            sessionId: sessionId.current,
            panelType: PANEL_TYPE,
            component_id: componentIds,
          }),
        );
        fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.AI_WRITER);
      })
      .catch(({ error, queryOutput }: AnyFixMe) => {
        const completionText =
          error.originalError?.extras?.completionText ?? '';
        const session = sessionId.current;

        if (!queryOutput) {
          const tokens = JSON.stringify(getTokensFromQueryOutput(error));

          showFailStateModal(
            {
              description,
              toneOfVoice,
              tokens,
              completionText,
              sessionId: session,
            },
            error?.originalError?.originalMessage,
          );
        } else {
          const tokens = JSON.stringify(
            getTokensFromQueryOutput(queryOutput[0]),
          );

          const errorMessage = queryOutput[0]?.originalError
            ? queryOutput[0].originalError?.message
            : error;

          showFailStateModal(
            {
              description,
              toneOfVoice,
              tokens,
              completionText,
              sessionId: session,
            },
            errorMessage,
          );
        }
      })
      .finally(() => {
        setIsLoading(() => false);
      });
  };

  const handleOnUndo = () => {
    onUndo();
    sendAdditionalActionEvent('undo');
  };

  return (
    <>
      <div
        key={'aiWriterPanel'}
        className={cx(styles.aiWriterPanel)}
        data-hook="ai-writer-panel"
      >
        <div className={styles.infoBlock}>
          <AiInfoBlock
            panelName="AiWriterPanel"
            text={translate('ai_text_to_page_panel_text')}
          />
        </div>

        {isIrrelevantPage && (
          <div className={styles.wrongPageWrapper}>
            <div className={styles.wrongPage}>
              <Text size="small" shouldTranslate={false} enableEllipsis={false}>
                {translate(
                  isEmptyState
                    ? 'ai_text_to_page_blank_banner'
                    : 'ai_text_to_page_banner',
                )}{' '}
              </Text>

              <TextButton
                size="small"
                enableEllipsis={false}
                onClick={
                  isEmptyState
                    ? handleOnAddSectionClick
                    : handleOnWrongPageClick
                }
                shouldTranslate={false}
              >
                {translate(
                  isEmptyState
                    ? 'ai_text_to_page_blank_banner_cta'
                    : 'ai_text_to_page_banner_cta',
                )}
              </TextButton>
            </div>
          </div>
        )}
        <div className={styles.siteInfo}>
          <BusinessInfo
            onTooltipOpen={() => handleInfoIconHover('Site info')}
            origin={BUSINESS_TYPE_PANEL_ORIGIN}
            onChangeInfoClick={() =>
              sendAdditionalActionEvent("click to open 'site info' panel")
            }
            onBusinessDataUpdated={handleBusinessDataChange}
            infoIconText="ai_text_to_page_site_info_tooltip_text"
          />
        </div>

        <Composites.TextInputLabeled>
          <TextLabel
            type="T02"
            shouldTranslate={false}
            value={translate('ai_text_to_page_panel_free_text_label')}
          />
          <InfoIcon
            onTooltipOpen={() => handleInfoIconHover('Important to mention')}
            shouldTranslate={false}
            text={translate('ai_text_to_page_free_text_tooltip_text')}
          />
          <TextInputMultiline
            value={description}
            disabled={isIrrelevantPage}
            shouldTranslate={false}
            onBlur={handleDescriptionBlur}
            className={styles.multilineInput}
            onChange={handleDescriptionChange}
            dataHook="ai-writer-free-text-input"
            placeholder={translate(
              'ai_text_to_page_panel_free_text_placeholder',
            )}
            isValid={
              !descirptionFieldControls.isDirty ||
              descirptionFieldControls.isValid
            }
            invalidMessage={translate(
              'ai_text_to_page_free_text_tooltip_error_max_length',
            )}
          />
        </Composites.TextInputLabeled>

        <Composites.TextInputLabeled>
          <TextLabel
            type="T02"
            shouldTranslate={false}
            value={translate('ai_text_to_page_panel_tone_label')}
          />
          <InfoIcon
            onTooltipOpen={() => handleInfoIconHover('Tone')}
            shouldTranslate={false}
            text={translate('ai_text_to_page_tone_tooltip_text')}
          />
          <div className={styles.tone}>
            <SelectionTags
              dataHook="ai-writer-tone-of-voice-buttons"
              value={[toneSelect]}
              selectable={!isIrrelevantPage ? 'multi' : 'read-only'}
              tags={TONE_OF_VOICE}
              className={styles.toneOfVoice}
              onSelect={(e: AnyFixMe, value: string[]) => {
                const tone = value.pop();
                handleToneSelectChange(tone);
              }}
            />
            <TextInput
              dataHook="ai-writer-tone-free-text-input"
              className={styles.input}
              disabled={isIrrelevantPage}
              value={toneInput}
              shouldTranslate={false}
              onChange={handleToneInputChange}
              onFocus={() => setToneSelect(() => null)}
              onBlur={() => handleToneInputBlur()}
              isValid={!isStatusError}
              invalidMessage={translate(
                'ai_text_to_page_tone_tooltip_error_max_length',
              )}
              placeholder={translate(
                'ai_text_to_page_panel_tone_type_other_placeholder',
              )}
            />
          </div>
        </Composites.TextInputLabeled>

        <div className={styles.button}>
          <Button
            dataHook="ai-writer-submit-button"
            className="btn-ai-primary"
            disabled={isCreatingBlocked}
            suffixIcon={<SparklesBold />}
            onClick={() =>
              handleOnClick(isPreInjection ? 'create_text' : 'regenerate')
            }
          >
            {translate(
              isPreInjection
                ? 'ai_text_to_page_panel_create_text_cta'
                : 'ai_text_to_page_panel_create_again_cta',
            )}
          </Button>
          {!isPreInjection && (
            <TextButton
              dataHook="ai-writer-undo-button"
              onClick={handleOnUndo}
              size="small"
              disabled={isIrrelevantPage || isLoading}
              shouldTranslate={false}
            >
              {translate('ai_text_to_page_panel_undo_cta')}
            </TextButton>
          )}
        </div>

        <div className={styles.footer}>
          <div className={styles.termsWrapper}>
            <AiTermsAndConditions
              translationKey={'add_section_ai_panel_legal'}
              panelType={PANEL_TYPE}
              sessionId={sessionId.current}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default connectWithScope(
  () => AiWriterPanelScope,
  AiWriterPanel,
  mapStateToProps,
  mapDispatchToProps,
);

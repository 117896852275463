import { editorSearch, panels } from '#packages/stateManagement';
import constants from '#packages/constants';

export const mapDispatchToProps = (dispatch: FunctionFixMe) => ({
  openAppMarket() {
    dispatch(editorSearch.actions.closeEditorSearchPanel());
    dispatch(
      panels.actions.openLeftPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_APPS_PANEL_NAME,
        {
          urlParams: {
            openMarketOrigin: constants.BI.HELP.ORIGIN.EDITOR_SEARCH,
          },
        },
      ),
    );
  },
  openHelpCenterHome() {
    dispatch(editorSearch.actions.closeEditorSearchPanel());
    dispatch(
      panels.actions.openHelpCenterHome(
        '58406e7a-1ad0-4105-8e37-c053b529f9d8',
        null,
        {
          origin: constants.BI.HELP.ORIGIN.EDITOR_SEARCH,
          panel_name: 'editorSearchPanel',
          learn_more: false,
        },
      ),
    );
  },
});

// @ts-nocheck
import * as core from '#packages/core';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as designPanelUtils from '#packages/designPanelUtils';
import * as helpIds from '#packages/helpIds';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import advancedStylePanelComponent from './advancedStylePanelComponent';
import boxSlideShowSlideAdvancedStylePanel from './boxSlideShowSlideAdvancedStylePanel';
import svgShapeAdvancedStylePanel from './svgShapeAdvancedStylePanel';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import tpaGalleryAdvancedStylePanel from '../tpaGalleries/tpaGalleryAdvancedStylePanel';

import React from 'react';
import * as compPanelInfra from '#packages/compPanelInfra';

const { panelPosition } = stateManagement.domMeasurements.hoc;
const { getSkin, getStyleId } = stateManagement.components.selectors;

const COMP_TYPES_WITH_CUSTOM_ADVANCE_STYLE = {
  'wysiwyg.viewer.components.svgshape.SvgShape': svgShapeAdvancedStylePanel,
  'wysiwyg.viewer.components.BoxSlideShowSlide':
    boxSlideShowSlideAdvancedStylePanel,
};

// eslint-disable-next-line react/prefer-es6-class
const AdvancedStylePanelStandalone = createReactClass({
  displayName: 'advancedStylePanelStandalone',
  render() {
    return (
      <compPanelInfra.compPanelFrame
        id="customizeDesignPanel"
        title="title"
        helpId={``}
        {...this.getPanelFrameProps()}
      >
        {this.styleId
          ? (() => {
              const AdvanceStylePanelClass = this.getAdvancedStylePanelClass();

              return (
                <div key="advanceStylePanelClass">
                  <AdvanceStylePanelClass {...this.getAdvancedStylingProps()} />
                </div>
              );
            })()
          : null}
      </compPanelInfra.compPanelFrame>
    );
  },

  mixins: [core.mixins.editorAPIMixin],

  UNSAFE_componentWillMount() {
    const editorAPI = this.getEditorAPI();
    this.styleId =
      this.props.styleId ||
      getStyleId(this.props.selectedComponent, editorAPI.dsRead);
  },

  UNSAFE_componentWillReceiveProps() {
    const editorAPI = this.getEditorAPI();
    this.styleId = getStyleId(this.props.selectedComponent, editorAPI.dsRead);
  },

  getAdvancedStylePanelClass() {
    const compType = this.getEditorAPI().components.getType(
      this.props.selectedComponent,
    );
    if (COMP_TYPES_WITH_CUSTOM_ADVANCE_STYLE[compType]) {
      return COMP_TYPES_WITH_CUSTOM_ADVANCE_STYLE[compType];
    } else if (
      designPanelUtils.customizeDesignComponents.isTPAGalleryAdvancedStyle(
        compType,
      )
    ) {
      return tpaGalleryAdvancedStylePanel;
    }
    return advancedStylePanelComponent;
  },

  openDesignPanel(editorAPI, compType) {
    editorAPI.replaceComponentPanel(constants.componentPanels.design, {
      onCustomizeDesignCallBack: this.props.onCustomizeDesignCallBack,
      withAdditionalBehaviors: this.props.withAdditionalBehaviors,
      onPreviewStateChange: this.props.onPreviewStateChange,
      getDefaultState: this.props.getDefaultState,
      title: this.props.title,
    });
    editorAPI.bi.event(coreBi.events.ADVANCED_STYLE_PANEL.BACK_BUTTON_CLICK, {
      component_type: compType,
    });
    if (_.isFunction(this.props.onCustomizeDesignCallBack)) {
      this.props.onCustomizeDesignCallBack();
    }
  },

  getPanelFrameProps() {
    const editorAPI = this.getEditorAPI();
    const backClickCallback = _.partial(
      this.openDesignPanel,
      editorAPI,
      editorAPI.components.getType(this.props.selectedComponent),
    );
    return _.merge(util.panelUtils.getFrameProps(this.props), {
      onBackClick: !this.props.onlyAdvancedStyling && backClickCallback,
      title: 'design_title_custom_design',
      backButtonLabel: 'design_panel_back_button_label',
      helpId: helpIds.UTILS.getCustomizeDesignHelpId(this.props.compType),
    });
  },

  getAdvancedStylingProps() {
    const editorAPI = this.getEditorAPI();
    const selectedSkin = getSkin(
      this.props.selectedComponent,
      editorAPI.dsRead,
    );

    const props = _.merge({}, this.props, {
      shouldStartClosed: false,
      styleId: this.styleId,
      compType: editorAPI.components.getType(this.props.selectedComponent),
      selectedSkin,
    });

    return props;
  },
});

export default panelPosition()(AdvancedStylePanelStandalone);

import constants from '#packages/constants';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import * as util from '#packages/util';
import { subscribeToDataModeChange } from './dataMode';
import { getOpenDataPanelOnStart } from './shouldShowDataPanel';
import type { EditorAPI } from '#packages/editorAPI';

const superAppId = 'wixData';
const key = constants.ROOT_COMPS.LEFTBAR.WIX_DATA_PANEL_ID;
const superAppPanelName = constants.SUPER_APPS.WIX_DATA_PANEL_NAME;
const getSuperAppButtonLabel = () => 'SiteApp_WixData_Button_Hover_Caption_CMS';
const superAppButtonPromotionalLabel = '';
const superAppPanelClassName = 'wix-data-panel-icon-b';

const getSuperAppSymbolName = () =>
  util.workspace.isNewWorkspaceEnabled()
    ? 'leftBarButtonData'
    : 'wixDataPanel_v2';

const showButton = (editorAPI: EditorAPI) => {
  superAppMenuInfra.button.registerButton(
    editorAPI,
    superAppId,
    true,
    superAppPanelName,
    getSuperAppButtonLabel(),
    superAppButtonPromotionalLabel,
    superAppPanelClassName,
    getSuperAppSymbolName(),
    false,
    { [superAppId]: true },
    key,
  );
};

const openPanel = (editorAPI: EditorAPI) =>
  superAppMenuInfra.button.onAppInstalled(
    editorAPI,
    superAppId,
    superAppPanelName,
    getSuperAppButtonLabel(),
    superAppPanelClassName,
    getSuperAppSymbolName(),
    { [superAppId]: true },
    false,
    key,
  );

export default {
  register(editorAPI: EditorAPI) {
    showButton(editorAPI);
    subscribeToDataModeChange(editorAPI, (newDataMode, prevDataMode) => {
      const dataModeWasTriggered =
        newDataMode === true && prevDataMode === false;
      const isDevMode = editorAPI.developerMode.isEnabled();
      const shouldOpen =
        dataModeWasTriggered &&
        !isDevMode &&
        getOpenDataPanelOnStart(editorAPI);

      if (shouldOpen) {
        openPanel(editorAPI);
      }
    });
  },
};

// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    const panelSections = addPanel.sections.getSections().hoverBox;
    return {
      titles: ['hover_box_theme_preset_section', 'hover_box_preset_section'],
      sections: [panelSections.hoverBoxTheme, panelSections.hoverBoxSection],
    };
  },
};

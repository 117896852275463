// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import * as styles from '#packages/styles';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

import React from 'react';
import * as compPanelInfra from '#packages/compPanelInfra';

import * as widgetDesignPanelMapper from './widgetDesignPanelMapper';
import { cx } from '#packages/util';
import type { WidgetDesignProps } from './types';
import WidgetDesignPanelAdvancedStyleSection from './widgetDesignPanelAdvancedStyleSection';
import WidgetDesignPanelElementsSection from './widgetDesignPanelElementsSection';
import WidgetDesignPanelPresetSection from './WidgetDesignPanelPresetSection';
import WidgetDesignPanelStylableSection from './WidgetDesignPanelStylableSection';

const advancedStyleConstants =
  styles.advancedStyle.advancedStyleConstants.multiComponents;
const { panelPosition } = stateManagement.domMeasurements.hoc;

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

class WidgetDesignPanel extends React.Component<WidgetDesignProps> {
  static propTypes = {
    presets: PropTypes.object,
    title: PropTypes.string,
    presetsTitle: PropTypes.string,
    onPresetChanged: PropTypes.func,
    customHelpId: PropTypes.string,
    presetHelpId: PropTypes.string,
    shouldTranslate: PropTypes.bool,
    tabs: PropTypes.array.isRequired,
    connectedComponents: PropTypes.array.isRequired,
    clearHighlights: PropTypes.func.isRequired,
    handleHighlight: PropTypes.func.isRequired,
    containerRef: PropTypes.object,
    selectedInnerComponent: PropTypes.object,
    loadTextEditor: PropTypes.func.isRequired,
    onBackFromTextPanel: PropTypes.func.isRequired,
    onCloseTextPanel: PropTypes.func.isRequired,
    isTextComponent: PropTypes.func.isRequired,
    isStylableComponent: PropTypes.func.isRequired,
    getCompType: PropTypes.func.isRequired,
    selectedComponent: PropTypes.arrayOf(PropTypes.object),
    getCurrentPresetId: PropTypes.func,
  };

  componentDidUpdate = () => {
    this.fetchCurrentPresetId();
  };

  static displayName = advancedStyleConstants.displayName;

  getComponentAdapter = () => {
    const { getWidgetDesignComponentAdapter, tabs, connectedComponents } =
      this.props;
    return getWidgetDesignComponentAdapter(tabs, connectedComponents);
  };

  shouldOpenStylableEditor = (selectedComponents) => {
    return selectedComponents.every((selectedComponent) =>
      this.props.isStylableComponent(selectedComponent),
    );
  };

  openElementsPanel = () => {
    this.setState({
      isElementPanelOpen: true,
      isPresetsPanelOpen: false,
    });
  };

  getState = () => {
    const componentAdapter = this.getComponentAdapter();
    const { presets, selectedInnerComponent } = this.props;
    const isPresetsPanelOpen = !selectedInnerComponent && presets;
    const isStylablePanelOpen =
      selectedInnerComponent &&
      this.shouldOpenStylableEditor([selectedInnerComponent]);

    const state = {
      componentAdapter,
      isPresetsPanelOpen,
      isStylablePanelOpen,
      isElementPanelOpen: !isPresetsPanelOpen && !selectedInnerComponent,
      isCustomDesignOpen: selectedInnerComponent && !isStylablePanelOpen,
      title: this.props.title,
      currentPresetId: undefined,
      ...(this.props.stateOverrides || {}),
    };

    if (selectedInnerComponent) {
      const { getTabIndexForComponent, getTabLabels, getStyle } =
        componentAdapter;
      const index = getTabIndexForComponent(selectedInnerComponent);
      Object.assign(state, {
        activeTab: index,
        style: getStyle(index),
        title: getTabLabels()[index],
      });
    }

    return state;
  };

  state = this.getState();

  getBackButtonProps = () => {
    const { presets } = this.props;
    const { isElementPanelOpen, isCustomDesignOpen, isStylablePanelOpen } =
      this.state;

    if (isElementPanelOpen) {
      return presets
        ? {
            onBackClick: () =>
              this.setState({
                isElementPanelOpen: false,
                isPresetsPanelOpen: true,
              }),
            backButtonLabel:
              advancedStyleConstants.backToPresetsButtonTranslation,
          }
        : {};
    }

    if (isCustomDesignOpen || isStylablePanelOpen) {
      return {
        onBackClick: () => {
          this.setState({
            isCustomDesignOpen: false,
            isStylablePanelOpen: false,
            isElementPanelOpen: true,
            title: this.props.title,
          });
        },
        backButtonLabel: advancedStyleConstants.backButtonLabel,
      };
    }

    return {};
  };

  getHelpId = () => {
    // TODO add third help id for tabs?
    const { customHelpId, presetHelpId } = this.props;
    const { isPresetsPanelOpen } = this.state;
    return isPresetsPanelOpen
      ? presetHelpId || advancedStyleConstants.defaultHelpId
      : customHelpId;
  };

  getPanelFrameProps = () => {
    const { backButtonLabel, onBackClick } = this.getBackButtonProps();
    return _.merge({}, util.panelUtils.getFrameProps(this.props), {
      title: this.state.title,
      backButtonLabel,
      onBackClick,
      helpId: this.getHelpId(),
      style: this.state.style,
      shouldTranslate: false,
    });
  };

  fetchCurrentPresetId = () => {
    const selectedComponent = this.props.selectedComponent?.[0];
    if (selectedComponent && this.state.isPresetsPanelOpen) {
      this.props
        .getCurrentPresetId(selectedComponent)
        .then((currentPresetId) => {
          this.setState({ currentPresetId });
        });
    }
  };
  onStyleParamChanged = (key, val, source) => {
    const { componentAdapter, activeTab } = this.state;
    componentAdapter.onStyleParamChanged(key, val, source, activeTab).then(() =>
      this.setState((state) => ({
        style: _.merge({}, state.style, {
          style: { properties: { [key]: val } },
        }),
      })),
    );
  };

  updateStyle = (newStyle) => {
    const { activeTab, style, componentAdapter } = this.state;
    componentAdapter.updateStyle(style, newStyle, activeTab).then(() =>
      this.setState((state) => ({
        style: _.merge({}, state.style, newStyle),
      })),
    );
  };

  shouldComponentUpdate(
    nextProps: Readonly<WidgetDesignProps>,
    nextState: Readonly<{}>,
  ): boolean {
    return (
      !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    );
  }

  shouldOpenTextEditor = (selectedComponents) => {
    return (
      !isResponsiveEditor() &&
      selectedComponents.every((selectedComponent) =>
        this.props.isTextComponent(selectedComponent),
      )
    );
  };

  getTextEditorPanelProps(panelTitle) {
    const {
      style,
      onPresetChanged,
      customHelpId,
      presetHelpId,
      connectedComponents,
      containerRef,
      tabs,
      presets,
      title,
      presetsTitle,
    } = this.props;

    const panelProps: WidgetDesignPanelProps = {
      onPresetChanged,
      customHelpId,
      presetHelpId,
      shouldTranslate: false,
      connectedComponents,
      containerRef,
      tabs,
      presets,
      title,
      presetsTitle,
    };

    return {
      title: panelTitle,
      style,
      backButtonLabel: advancedStyleConstants.backButtonLabel,
      onBackClick: () =>
        this.props.onBackFromTextPanel({
          ...panelProps,
          stateOverrides: this.state,
        }),
      helpId: this.getHelpId(),
      shouldTranslate: false,
      onClose: () => this.props.onCloseTextPanel(),
    };
  }

  onOpenCustomizeDesignForTab = (index) => {
    const { componentAdapter } = this.state;
    const selectedComponents = componentAdapter.getSelectedComponents(index);
    const style = componentAdapter.getStyle(index);
    const title = componentAdapter.getTabLabels()[index];

    const shouldOpenTextEditor = this.shouldOpenTextEditor(selectedComponents);
    if (shouldOpenTextEditor) {
      const panelProps = this.getTextEditorPanelProps(title);
      this.props.loadTextEditor(selectedComponents, panelProps);
      return;
    }

    const shouldOpenStylablePanel =
      this.shouldOpenStylableEditor(selectedComponents);

    this.setState({
      isCustomDesignOpen: !shouldOpenTextEditor && !shouldOpenStylablePanel,
      isStylablePanelOpen: shouldOpenStylablePanel,
      isElementPanelOpen: false,
      activeTab: index,
      style,
      title,
    });
  };

  render() {
    return (
      <compPanelInfra.compPanelFrame
        id="multiComponentAdvancedStylePanel"
        {...this.getPanelFrameProps()}
        className={cx(
          'multi-component-advanced-style-panel',
          this.getPanelFrameProps()?.className,
        )}
      >
        {this.state.isPresetsPanelOpen ? (
          <WidgetDesignPanelPresetSection
            {...this.props}
            isPresetsPanelOpen
            onCustomizeButtonClick={() => this.openElementsPanel()}
            fetchCurrentPresetId={this.fetchCurrentPresetId}
            currentPresetId={this.state.currentPresetId}
          />
        ) : null}

        {this.state.isElementPanelOpen ? (
          <WidgetDesignPanelElementsSection
            {...this.props}
            componentAdapter={this.state.componentAdapter}
            onOpenCustomizeDesignForTab={this.onOpenCustomizeDesignForTab}
          />
        ) : null}

        {this.state.isCustomDesignOpen ? (
          <WidgetDesignPanelAdvancedStyleSection
            {...this.props}
            activeTab={this.state.activeTab}
            componentAdapter={this.state.componentAdapter}
            style={this.state.style}
            onStyleParamChanged={this.onStyleParamChanged}
            updateStyle={this.updateStyle}
          />
        ) : null}
        {this.state.isStylablePanelOpen ? (
          <WidgetDesignPanelStylableSection
            {...this.props}
            activeTab={this.state.activeTab}
            componentAdapter={this.state.componentAdapter}
          />
        ) : null}
      </compPanelInfra.compPanelFrame>
    );
  }
}

const WrappedWidgetDesignPanel = stateManagement.components.hoc.compPanel(
  connect(
    EDITOR_API,
    undefined,
    widgetDesignPanelMapper.mapDispatchToProps,
  )(panelPosition()(WidgetDesignPanel)),
);

WrappedWidgetDesignPanel.pure = WidgetDesignPanel;
export default WrappedWidgetDesignPanel;

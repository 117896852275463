import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  AccountApiKey,
  EditorAPIKey,
  EditorCoreApiKey,
  PanelManagerApiKey,
  SiteApiKey,
  DocumentServicesApiKey,
} from '#packages/apis';
import { SiteReadinessApi } from './siteReadinessApi';
import { SiteReadinessStore } from './store';
import { SiteReadinessApiKey } from './publicApiKey';
import { init } from './init';
import experiment from 'experiment';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  panelManagerAPI = this.useDependency(PanelManagerApiKey);
  siteApi = this.useDependency(SiteApiKey);
  accountApi = this.useDependency(AccountApiKey);
  dsReadApi = this.useDependency(DocumentServicesApiKey);
  store = this.declareStore(SiteReadinessStore);
}

export const SiteReadinessEntryPoint = createEntryPoint({
  name: 'SiteReadinessEntryPoint',
  publicApi({ contributeApi }) {
    contributeApi(SiteReadinessApiKey, SiteReadinessApi);
  },
  Scope,
  async initialize(scope) {
    if (experiment.isOpen('se_siteReadiness')) {
      init(scope);
    }
  },
});

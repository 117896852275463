export default {
  multiComponents: {
    PARAM_TYPE_DEFAULT_VALUE: {
      ALPHA: 1,
      BG_COLOR_ALPHA: '#ffffff',
      BORDER_COLOR_ALPHA: '#e3e3e3',
      BORDER_SIZES: '1px',
      BORDER_RADIUS: 0,
      TEXT_COLOR: '#000000',
      FONT: 'font_8',
      SHADOW: 'false',
      BOX_SHADOW: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      BOXSHADOWTOGGLEON: 'false',
      COLOR_ALPHA: '#000000',
    },
    styleId: 'MultiCompsStyleId',
    compType: 'MultiComps',
    displayName: 'MultiCompAdvancedStylePanel',
    ALPHA_PREFIX: 'alpha-',
    SHADOW_PREFIX: 'boxShadowToggleOn-',
    skinName: 'multiComponentSkin',
    backButtonTranslation: 'design_panel_back_button_label',
    backToPresetsButtonTranslation: 'design_panel_back_button_label',
    backButtonLabel: 'BIG_PLAY_CONTROL_DESIGN_BACK',
    defaultHelpId: '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
    sectionWrapperProps: {
      parentType: 'DESIGN_PANEL',
      classNames:
        'section-wrapper preset-section without-footer without-labels',
      showSectionHeader: true,
      shouldTranslate: false,
    },
    skinTypeOverrides: {
      COLOR_ALPHA: {
        border: 'BORDER_COLOR_ALPHA',
        fill: 'BG_COLOR_ALPHA',
        text: 'COLOR_ALPHA',
      },
    },
  },
  skins: {
    DEFAULT_WITH_FILL_LAYERS_SKIN_NAME:
      'wysiwyg.viewer.skins.screenwidthcontainer.DefaultWithFillLayers',
    DEFAULT_SITE_SEGMENT_TRANSPARENT_SKIN:
      'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen',
  },
};

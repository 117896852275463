import { biLogger, sections, guidUtils, multilingual } from '#packages/util';
import {
  dropDownSelection,
  editSectionClickOnComponent,
  editSectionTextComponentStartChanging,
  panelHeaderClose,
  panelOpened,
  textPanelAlignmentClick,
  textPanelBoldClick,
  textPanelBulletsClick,
  textPanelItalicClick,
  textPanelTextOrientationClick,
  textPanelUnderlineClick,
  textTextChanged,
  switchLayoutUnsupportedLayoutOpen,
  editSectionComponentChanged,
} from '@wix/bi-logger-editor/v2';
import type { CompRef } from 'types/documentServices';
import {
  ALIGNMENT_TYPES,
  BOLD,
  COMMANDS_WITH_BI_TARGET,
  ITALIC,
  LTR,
  quickEditPanelOrigin,
  RTL,
  TEXT_LIST_TYPES,
  UNDERLINE,
} from './consts';
import { getTextFromHtmlString } from './quickEditUtils';
import { getCompPanelName, getCompSuffix } from '#packages/quickEditEngine';

import type { QuickEditScope } from './quickEditEntryPoint';
import type { EditorAPI } from '#packages/editorAPI';
import type { GFPPActionType } from '@wix/editor-component-types';
import type { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';

interface ExecCommandBiParams {
  origin: string;
  component_id: string;
  target?: string;
  panel_name?: string;
  category?: string;
}

const getBiFuncMap = (): { [key: string]: Function } => ({
  [BOLD]: textPanelBoldClick,
  [ITALIC]: textPanelItalicClick,
  [UNDERLINE]: textPanelUnderlineClick,
  [LTR]: textPanelTextOrientationClick,
  [RTL]: textPanelTextOrientationClick,
  [ALIGNMENT_TYPES.textAlignLeft]: textPanelAlignmentClick,
  [ALIGNMENT_TYPES.textAlignCenter]: textPanelAlignmentClick,
  [ALIGNMENT_TYPES.textAlignRight]: textPanelAlignmentClick,
  [ALIGNMENT_TYPES.textAlignBlock]: textPanelAlignmentClick,
  [TEXT_LIST_TYPES.textListNone]: textPanelBulletsClick,
  [TEXT_LIST_TYPES.textListNumbers]: textPanelBulletsClick,
  [TEXT_LIST_TYPES.textListBullets]: textPanelBulletsClick,
});

export const reportPanelClose = (
  panel_name: string,
  component_id: string,
  origin: string,
  store: QuickEditScope['store'],
) => {
  const { panelOpenTime, section_correlation_id, panel_correlation_id } =
    store.getQuickEditBiData();

  biLogger.report(
    panelHeaderClose({
      panel_name,
      component_id,
      timeSpent: Math.round(performance.now() - panelOpenTime),
      origin,
      section_correlation_id,
      parent_correlation_id: panel_correlation_id,
    }),
  );
};

export const reportPanelOpen = (
  panel_name: string,
  origin: string,
  store: QuickEditScope['store'],
) => {
  const {
    section_correlation_id,
    panel_correlation_id,
    component_id,
    component_type,
  } = store.getQuickEditBiData();
  biLogger.report(
    panelOpened({
      panel_name,
      origin,
      section_correlation_id,
      panel_correlation_id,
      component_id,
      component_type,
    }),
  );
};

export const reportSecondaryPanelClose = (store: QuickEditScope['store']) => {
  const { openTime, component_id, component_type, panel_name } =
    store.getInnerPanelBiData();
  biLogger.report(
    panelHeaderClose({
      component_id,
      component_type,
      panel_name,
      timeSpent: Math.round(performance.now() - openTime),
    }),
  );
};

export const reportSecondaryPanelOpen = (
  store: QuickEditScope['store'],
  component_id: string,
  panelName: string,
  compType: string,
) => {
  const { panel_correlation_id, section_correlation_id } =
    store.getQuickEditBiData();
  biLogger.report(
    panelOpened({
      origin: quickEditPanelOrigin,
      component_id,
      panel_name: panelName,
      component_type: compType,
      panel_correlation_id,
      section_correlation_id,
    }),
  );
};

export const setInitialBiData = (
  store: QuickEditScope['store'],
  origin: string,
  rootControlCompRef: CompRef,
  editorAPI: EditorAPI,
) => {
  store.setQuickEditBiData({
    panel_correlation_id: guidUtils.getGUID(),
    section_correlation_id: guidUtils.getGUID(),
    parent_component_id: rootControlCompRef?.id,
    panelOpenTime: Math.round(performance.now()),
    panelOpenOrigin: origin,
    component_id: rootControlCompRef?.id ?? '',
    component_type: editorAPI.components.getType(rootControlCompRef) ?? '',
  });
};

export const setSectionCorrelationId = (store: QuickEditScope['store']) => {
  store.setQuickEditBiData({
    section_correlation_id: guidUtils.getGUID(),
  });
};

export const setInnerPanelBiData = (
  store: QuickEditScope['store'],
  component_id: string,
  panelName: string,
  compType: string,
) => {
  store.setInnerPanelBiData({
    openTime: performance.now(),
    component_id,
    panel_name: panelName,
    component_type: compType,
  });
};

export const reportComponentClickEvent = (
  store: QuickEditScope['store'],
  editorAPI: EditorAPI,
  compRef: CompRef | null,
  compType: string | null,
  action: QuickEditControlActions | GFPPActionType,
  isConnectedToDataBinding: boolean,
  target: string = '',
  actionOrigin: string = '',
) => {
  const {
    panelOpenOrigin,
    panel_correlation_id,
    section_correlation_id,
    parent_component_id,
  } = store.getQuickEditBiData();

  const action_origin =
    action && compType && !actionOrigin
      ? getCompPanelName(compType, action)
      : `${quickEditPanelOrigin}${actionOrigin ? `_${actionOrigin}` : ''}`;

  target =
    compType && !target ? `${getCompSuffix(compType)}_${action}` : target;

  const parentRef =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
  const parentType = editorAPI.components.getType(parentRef);

  biLogger.report(
    editSectionClickOnComponent({
      origin: panelOpenOrigin,
      parent_component_id,
      panel_correlation_id,
      section_correlation_id,
      component_id: compRef?.id ?? '',
      component_parent_type: parentType,
      component_parent_id: parentRef?.id,
      target: isConnectedToDataBinding ? 'connect_to_data' : target,
      component_type: compType ?? '',
      action_origin,
    }),
  );
};

export const reportClickNoComp = (
  scope: QuickEditScope,
  target?: string,
  origin?: string,
) => {
  reportComponentClickEvent(
    scope.store,
    scope.editorAPI,
    null,
    null,
    null,
    false,
    target,
    origin,
  );
};

export const reportTextComponentStartEditing = (
  store: QuickEditScope['store'],
  compRef: CompRef,
) => {
  const { panel_correlation_id, section_correlation_id, parent_component_id } =
    store.getQuickEditBiData();
  biLogger.report(
    editSectionTextComponentStartChanging({
      origin: quickEditPanelOrigin,
      parent_component_id,
      panel_correlation_id,
      section_correlation_id,
      component_id: compRef.id,
    }),
  );
};

export const reportTextChanged = (
  editorAPI: EditorAPI,
  store: QuickEditScope['store'],
  oldText: string,
  newText: string,
  compRef: CompRef,
) => {
  const textOrigin = getTextFromHtmlString(oldText);
  const text = getTextFromHtmlString(newText);
  if (textOrigin === text) return;

  const parentRef =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
  const parentType = editorAPI.components.getType(parentRef);
  const focusedSectionLike = editorAPI.sections.getFocusedSectionLike();

  biLogger.report(
    textTextChanged({
      origin: quickEditPanelOrigin,
      textOrigin,
      text,
      multilingual:
        multilingual.multilingualBi.getMultilingualBiParam(editorAPI),
      pageId: editorAPI.dsRead.pages.getFocusedPageId(),
      component_id: compRef.id,
      component_type: editorAPI.components.getType(compRef),
      parentComponentId: parentRef?.id,
      parentComponentType: parentType,
      sectionType:
        focusedSectionLike &&
        sections.getSectionLikeName(editorAPI, focusedSectionLike),
    }),
  );
};

export const reportTextExecCommand = (
  commandName: keyof typeof getBiFuncMap | string,
  compRef: CompRef,
) => {
  const biFunc = getBiFuncMap()[commandName];
  if (!biFunc) return;

  const biParams: ExecCommandBiParams = {
    origin: quickEditPanelOrigin,
    component_id: compRef.id,
  };
  if (COMMANDS_WITH_BI_TARGET.includes(commandName)) {
    biParams.target = commandName;
  }
  if ([LTR, RTL].includes(commandName)) {
    biParams.panel_name = quickEditPanelOrigin;
    biParams.category = commandName;
  }
  biLogger.report(biFunc(biParams));
};

export const reportDropDownSelection = (value: string) => {
  biLogger.report(
    dropDownSelection({
      origin: quickEditPanelOrigin,
      panel_name: quickEditPanelOrigin,
      selection: value,
    }),
  );
};

export const reportUnsupportedSectionOpened = (
  store: QuickEditScope['store'],
  editorAPI: EditorAPI,
) => {
  const {
    panelOpenOrigin,
    panel_correlation_id,
    section_correlation_id,
    parent_component_id,
  } = store.getQuickEditBiData();

  let section_name_technical = '';
  const [compRef] = editorAPI.selection.getSelectedComponents();
  if (editorAPI.components.is.exist(compRef)) {
    section_name_technical = editorAPI.sections.isHeaderOrFooter(compRef)
      ? compRef.id
      : editorAPI.sections.getName(compRef);
  }

  biLogger.report(
    switchLayoutUnsupportedLayoutOpen({
      component_id: parent_component_id,
      panel_type: quickEditPanelOrigin,
      panel_correlation_id,
      section_correlation_id,
      origin: panelOpenOrigin,
      section_name_technical,
    }),
  );
};

export const reportComponentChanged = (
  { store, editorAPI }: QuickEditScope,
  compRef: CompRef,
  compType: string,
) => {
  const { panelOpenOrigin, panel_correlation_id, section_correlation_id } =
    store.getQuickEditBiData();

  const parentRef =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
  const parentType = editorAPI.components.getType(parentRef);

  biLogger.report(
    editSectionComponentChanged({
      origin: panelOpenOrigin,
      section_correlation_id,
      panel_correlation_id,
      component_id: compRef.id,
      component_type: compType,
      parent_component_id: parentRef?.id,
      component_parent_type: parentType,
    }),
  );
};

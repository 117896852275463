// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import disableKeyboardShortcutsMixin from '../mixins/disableKeyboardShortcutsMixin';

import React from 'react';
import * as baseUI from '#packages/baseUI';
import { EmptyPanelFrame } from '../frames';
import { cx } from '#packages/util';

const CODE_X_BUTTON = 'X_BUTTON';
const CODE_VIDEO_ENDED = 'VIDEO_ENDED';
const CLOSE_OVERLAY = 'CLOSE_OVERLAY';

const stillBackFocusFromIframeToCatchEscEventWhenLoading = function () {
  window.focus();
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'howToVideoPlayer',
  mixins: [disableKeyboardShortcutsMixin, core.mixins.editorAPIMixin],
  propTypes: {
    videoId: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
    withShadow: PropTypes.bool,
    withOverlay: PropTypes.bool,
    videoName: PropTypes.string,
  },
  getDefaultProps() {
    return {
      withShadow: true,
      withOverlay: true,
    };
  },
  componentDidMount() {
    const biParams = {
      videoID: this.props.videoId,
      origin: this.props.origin,
      video_name: this.props.videoName,
    };
    this.getEditorAPI().bi.event(coreBi.events.videoPlayer.OPEN, biParams);
    this.startTime = Date.now();
    stillBackFocusFromIframeToCatchEscEventWhenLoading();

    const addYoutubeListener = function () {
      const handleStateChange = function (e) {
        if (e.data === window.YT.PlayerState.ENDED) {
          this.close({ code: CODE_VIDEO_ENDED });
        }
      };
      //eslint-disable-next-line no-new
      new window.YT.Player('how-to-video-player-iframe', {
        events: { onStateChange: handleStateChange.bind(this) },
      });
    };
    if (!window.YT) {
      window.onYouTubeIframeAPIReady = function () {
        addYoutubeListener.call(this);
        delete window.onYouTubeIframeAPIReady;
      }.bind(this);
      const script = window.document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//www.youtube.com/iframe_api';
      window.document.head.appendChild(script);
    } else if (window.YT.Player && window.YT.PlayerState) {
      addYoutubeListener.call(this);
    }
  },
  getIframeSrcUrl() {
    const base = 'https://www.youtube.com/embed/';
    const params =
      '?rel=0' +
      '&autoplay=1' +
      '&color=red' +
      '&controls=1' +
      '&showinfo=0' +
      '&fs=0' +
      '&enablejsapi=1' +
      '&origin=http://editor.wix.com' +
      '&modestbranding=1';
    return base + this.props.videoId + params;
  },
  close(e) {
    const editor = this.getEditorAPI();
    const biParams = {
      videoID: this.props.videoId,
      duration: Date.now() - this.startTime,
      origin: e.code,
    };
    editor.bi.event(coreBi.events.videoPlayer.CLOSE, biParams);
    editor.panelManager.closePanelByName(this.props.panelName);
  },
  handleClickClose() {
    this.close({ code: CODE_X_BUTTON });
  },
  handleClickOverlay() {
    this.close({ code: CLOSE_OVERLAY });
  },
  getKeyboardShortcuts() {
    return {
      esc: this.close,
    };
  },
  render() {
    return (
      <EmptyPanelFrame
        panelName="howToVideoPlayer"
        className="how-to-video-player panel-frame-container"
      >
        <div
          key="darkOverlay"
          onClick={this.handleClickClose}
          className={cx({
            'dark-overlay': this.props.withOverlay,
            'close-overlay': !this.props.withOverlay,
          })}
        />
        <div
          className={cx({
            'container-iframe': true,
            'with-shadow': this.props.withShadow,
          })}
        >
          <div onClick={this.handleClickClose} className="close">
            <baseUI.symbol name="close_video_icon_reg" />
          </div>
          <iframe
            id="how-to-video-player-iframe"
            src={this.getIframeSrcUrl()}
          />
        </div>
      </EmptyPanelFrame>
    );
  },
});

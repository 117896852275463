import React from 'react';
import { PermissionSection } from '../CommonComponents';
import { PERMISSIONS_TAB_HOOKS } from '../../../../../utils/dataHooks';

import type { RolesPanelProps } from '../../permissionsTabMapper';
import type { Group } from '../../../../../utils/groupsApi';

export interface PicingPlansSectionProps {
  plansForPage: Required<Group>[];
  availablePlans: Required<Group>[];
  permissions?: string[];
  disabled: boolean;
  openRolesPanel: (panelProps: RolesPanelProps) => void;
  openDashboardModal: (path: string) => void;
  updateGroupsPermissions: (groupsToUpdate: Group[]) => Promise<void>;
}

export const PricingPlansSection: React.FC<PicingPlansSectionProps> = ({
  permissions,
  plansForPage,
  disabled,
  availablePlans,
  openRolesPanel,
  openDashboardModal,
  updateGroupsPermissions,
}) => {
  const hasCreatePlanGroup = availablePlans.length > 0;
  const canCreatePlans = permissions?.includes('paid-plans.manage');

  const openPlansDashboard = () =>
    openDashboardModal(
      '/pricing-plans/new?referralInfo=editor&viewMode=editor',
    );

  const openPaidPlansPanel = () =>
    openRolesPanel({
      panelName: 'panels.toolPanels.rolesManagerPanel',
      groups: availablePlans,
      selectedGroups: plansForPage,
      headerTitle: 'NewPages_Plans_Manager_Header_Title',
      applyButtonLabel: 'NewPages_Plans_Manager_Apply_Button',
      applyButtonCallback: updateGroupsPermissions,
      addNewButtonLabel: 'NewPages_Plans_Manager_Add_New_Plan',
      addNewButtonCallback: openPlansDashboard,
      disableAddNew: !canCreatePlans,
    });

  const disconnectPlan = async () => {
    const rolesToRemove = plansForPage.map((role) => ({
      ...role,
      checked: false,
    }));

    await updateGroupsPermissions(rolesToRemove);
  };

  return (
    <PermissionSection
      dataHook={PERMISSIONS_TAB_HOOKS.PLANS_PERMISSION_SECTION}
      title="NewPages_Panel_Permissions_Plans_Section_Title"
      subtitle="NewPages_Panel_Permissions_Plans_Section_Subtitle"
      disabled={disabled}
      disconnectGroups={disconnectPlan}
      hasAvailableGroups={hasCreatePlanGroup}
      emptyText="NewPages_Panel_Permissions_Plans_Section_Placeholder"
      createText="NewPages_Panel_Permissions_Plans_Section_Create_Button"
      canCreateNew={canCreatePlans}
      onCreate={openPlansDashboard}
      connectedGroups={plansForPage}
      connectText="NewPages_Panel_Permissions_Plans_Section_Choose_Button"
      onConnect={openPaidPlansPanel}
    />
  );
};

import React, { type FC } from 'react';
import {
  ColorDropBold,
  ImageBold,
  VideoBold,
} from '@wix/wix-ui-icons-common/classic-editor';
import { TextLabel } from '@wix/wix-base-ui';

import constants from '#packages/constants';
import { cx, colors } from '#packages/util';

import { withConnect } from './SelectedBackgroundPreview.mapper';
import styles from './SelectedBackgroundPreview.scss';

const { MEDIA_TYPES } = constants;
interface PreviewProps {
  className?: string;
  colorStyle: React.CSSProperties;
  medialStyle: React.CSSProperties;
  mediaType: string;
  showMediaType?: boolean;
  colorValue: string;
  overlayStyle: Record<string, any>;
}

const PERCEIVED_LIGHTNESS_THRESHOLD = 0.5;
const isDarkColor = (color: string) =>
  colors.getLumaPerceivedLightness(color) < PERCEIVED_LIGHTNESS_THRESHOLD;

const Preview: FC<PreviewProps> = ({
  colorStyle,
  medialStyle,
  className,
  mediaType,
  showMediaType = false,
  colorValue,
  overlayStyle,
}) => {
  const isColorType = mediaType === MEDIA_TYPES.COLOR;
  const previewStyle = isColorType ? colorStyle : medialStyle;
  const previewClassName = cx(styles.container, className);
  const MediaIcon = mediaType === MEDIA_TYPES.IMAGE ? ImageBold : VideoBold;
  const Icon = isColorType ? ColorDropBold : MediaIcon;

  const iconGroupClassName = showMediaType
    ? cx({
        [styles.dark]: isDarkColor(colorValue) || !isColorType,
        [styles.media]: !isColorType,
      })
    : '';

  return (
    <div className={previewClassName}>
      <div style={previewStyle} className={styles.preview} />
      <div style={overlayStyle} className={styles.overlay} />
      <div className={styles.iconContainer}>
        {showMediaType && (
          <>
            <Icon className={iconGroupClassName} />
            {isColorType && (
              <TextLabel
                value={colorValue}
                type="T02"
                shouldTranslate={false}
                className={iconGroupClassName}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export const SelectedBackgroundPreview = withConnect(Preview);

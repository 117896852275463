import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey, EditorCoreApiKey } from '#packages/apis';
import { createAccountApi } from './accountApi';
import { AccountApiKey } from './publicApiKey';
import { accountActions, accountReducer } from './state';
import type { AccountActions, AccountStateFull } from './state';
import { fetchIsStudioAccount } from './accountApiUtil';

export const AccountEntrypoint: EntryPoint = {
  name: 'AccountApi',
  declareAPIs: () => [AccountApiKey],
  getDependencyAPIs: () => [EditorAPIKey, EditorCoreApiKey],
  attach(shell) {
    shell.contributeState<AccountStateFull, AccountActions>(() => ({
      account: accountReducer,
    }));

    shell.contributeAPI(AccountApiKey, () => createAccountApi(shell));
  },
  async extend(shell) {
    const editorAPI = shell.getAPI(EditorAPIKey);
    const editorCoreApi = shell.getAPI(EditorCoreApiKey);
    const shellStore = shell.getStore<AccountStateFull>();
    await editorCoreApi.hooks._dsIsReady.promise;
    const isStudioAccount = await fetchIsStudioAccount(editorAPI);
    shellStore.dispatch(accountActions.setIsStudioAccount(isStudioAccount));
  },
};

// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import * as pagesMenu from '#packages/pagesMenu';
import * as blogManagerFrame from '../../blogManager/blogManagerFrame';
import React from 'react';
import { CustomScroll } from '@wix/wix-base-ui';
import experiment from 'experiment';
import { cx } from '#packages/util';

const pageActionHandlers = {
  settings() {
    this.setState({
      isPageSettingsPanelOpened: true,
    });
  },
  rename(editorAPI, pageMenuItem, additionalData) {
    pagesMenu.pageMenuItemHelpers.renamePage(
      editorAPI,
      pageMenuItem.pageData,
      additionalData,
    );
  },
  unhide: _.partialRight(setPageHiddenDependingOnViewMode, false),
  hide: _.partialRight(setPageHiddenDependingOnViewMode, true),
  moveup: pagesMenu.pageMenuItemHelpers.moveUp,
  remove: pagesMenu.pageMenuItemHelpers.removeMenuItem,
};

function setPageHiddenDependingOnViewMode(
  editorApi,
  data,
  additionalData,
  value,
) {
  const viewMode = editorApi.dsRead.viewMode.get();

  if (viewMode === editorApi.dsRead.viewMode.VIEW_MODES.DESKTOP) {
    pagesMenu.pageMenuItemHelpers.setHidden(editorApi, data, value);
  }

  if (viewMode === editorApi.dsRead.viewMode.VIEW_MODES.MOBILE) {
    pagesMenu.pageMenuItemHelpers.setHidden(editorApi, data, undefined, value);
  }
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'BlogPagesPanel',
  mixins: [ReactLinkedStateMixin, core.mixins.editorAPIMixin], //eslint-disable-line react/no-deprecated
  getInitialState() {
    return {
      selectedPageId: null,
      isPageSettingsPanelOpened: false,
      isWixAppsPanelOpened: false,
      editedPageId: null,
    };
  },
  getPages() {
    const editorAPI = this.getEditorAPI();
    const pagesDataList = editorAPI.dsRead.pages.getPagesData();
    const blogMenuLogic = pagesMenu.specialPages.blog;
    const sectionPagesDataList = blogMenuLogic.getSectionPagesDataList(
      editorAPI,
      pagesDataList,
    );
    const specialPagesDataList = blogMenuLogic.getSpecialPagesDataList(
      editorAPI,
      pagesDataList,
    );

    const resultPagesDataList = _.union(
      sectionPagesDataList,
      specialPagesDataList,
    );

    return {
      theList:
        editorAPI.mainMenu.convertPagesDataListToMenuItemsList(
          resultPagesDataList,
        ),
      specialPagesStartingIndex: sectionPagesDataList.length,
    };
  },

  getSelectedPage(pagesList) {
    if (this.state.selectedPageId) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      return _.find(pagesList, { id: this.state.selectedPageId });
    }
  },

  setSelected(page) {
    this.getEditorAPI().pages.navigateTo(page.pageData.id);
    this.setState({ selectedPageId: page.id });
  },

  isSelected(item) {
    const currentPageId = this.getEditorAPI().pages.getCurrentUrlPageId();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const currentPageMenuItem = _.find(this.getPages().theList, {
      pageData: { id: currentPageId },
    });

    return currentPageMenuItem ? item.id === currentPageMenuItem.id : null;
  },

  onActionSelected(actionName, pageMenuItem, additionalData) {
    pageActionHandlers[actionName].call(
      this,
      this.getEditorAPI(),
      pageMenuItem,
      additionalData,
    );
  },

  closeSettings() {
    this.setState({
      isPageSettingsPanelOpened: false,
    });
  },
  openBlogManager() {
    blogManagerFrame.open(this.getEditorAPI(), 'allPosts');
  },
  getSettingPanelComponent(pageItem) {
    if (!pageItem) {
      return null;
    }
    const section = this.getEditorAPI().isDesktopEditor()
      ? pagesMenu.settingsPanels.desktop
      : pagesMenu.settingsPanels.mobile;

    return core.utils.blogTypeUtils.isBlogSpecialPage(pageItem.pageData)
      ? section.subpage
      : section.mainMenu;
  },
  getEditedPageIdLink() {
    return {
      value: this.state.editedPageId,
      requestChange: function (newVal) {
        this.setState({ editedPageId: newVal });
      }.bind(this),
    };
  },
  onNotificationClick(item) {
    this.setSelected(item);
    this.onActionSelected('settings');
  },
  render() {
    const pages = this.getPages();
    const page = this.getSelectedPage(pages.theList);
    const SettingsPanel = this.getSettingPanelComponent(
      this.getSelectedPage(pages.theList),
    );
    return (
      <div className="blog-pages-panel">
        {this.state.isPageSettingsPanelOpened ? (
          <div key="psgesPanelShadow" className="pages-panel-shadow" />
        ) : null}
        <div className="pageSettings">
          {page && this.state.isPageSettingsPanelOpened ? (
            <SettingsPanel
              key="pageSettings"
              menuItem={page}
              closeSettingsPanel={this.closeSettings}
              actions={{ openBlogManager: this.openBlogManager }}
            />
          ) : null}
        </div>

        <CustomScroll heightRelativeToParent="100%">
          <div className="page-list-container">
            <ul className="page-list">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(pages.theList, (item, itemIndex) => {
                const editedPageIdLink = this.getEditedPageIdLink();

                return (
                  <li
                    className={cx({
                      selected:
                        this.state.selectedPageId &&
                        this.state.selectedPageId === item.id,
                    })}
                  >
                    <pagesMenu.mixedList.nodeContentUniversalRenderer
                      dataSource={item}
                      isBelongToSubmenu={
                        itemIndex >= pages.specialPagesStartingIndex
                      }
                      editedPageIdLink={
                        experiment.isOpen('se_blogChangeSinglePostPageName')
                          ? editedPageIdLink
                          : undefined
                      }
                      onActionSelected={this.onActionSelected}
                      onNotificationClick={this.onNotificationClick.bind(
                        this,
                        item,
                      )}
                      notificationCount={1}
                      isSelected={this.isSelected(item)}
                      setSelected={this.setSelected.bind(this, item)}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </CustomScroll>
      </div>
    );
  },
});

import { biLogger } from '#packages/util';
import {
  crossSitePasteComponentOrTpa,
  pasteComponentOrTpa,
} from '@wix/bi-logger-editor/v2';

export const origin = 'editor_shortcut_paste_on_stage';

export const reportSuccessToBi = (
  component_id: string,
  component_type: string,
) => {
  biLogger.report(
    crossSitePasteComponentOrTpa({
      component_id,
      component_type,
      paste_option: origin,
      success: true,
    }),
  );

  biLogger.report(
    pasteComponentOrTpa({
      component_id,
      component_type,
      origin,
    }),
  );
};

// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_pagination_bar'],
      sections: [addPanel.sections.getSections().developer.paginationSection],
    };
  },
};

import { createReduxStore } from '#packages/apilib';

interface ReadonlyModeState {
  isReadonlyModeEnabled: boolean;
}

const getInitialState = (): ReadonlyModeState => {
  return {
    isReadonlyModeEnabled: false,
  };
};

export const ReadonlyModeStore = createReduxStore({
  getInitialState,
  selectors: {
    getIsReadonlyModeEnabled: (state: ReadonlyModeState) =>
      state.isReadonlyModeEnabled,
  },
  actions: {
    enableReadonlyMode: () => {
      return {
        isReadonlyModeEnabled: true,
      };
    },
    disableReadonlyMode: () => {
      return {
        isReadonlyModeEnabled: false,
      };
    },
  },
});

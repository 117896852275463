// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import { DangerMessagePanelFrame } from '../../frames';
import { translate } from '#packages/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'widgetWarningMessage',

  propsTypes: {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    messageTemplateReplacements: PropTypes.object,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    iconName: PropTypes.string,
  },

  getDefaultProps() {
    return {
      messageTemplateReplacements: {},
      confirmLabel: 'AppStudio_Continue_Button',
      cancelLabel: 'AppStudio_Cancel_Button',
      iconName: 'platformWarning',
    };
  },

  render() {
    return (
      <DangerMessagePanelFrame
        automationId="widget-warning-message"
        panelName={this.props.panelName}
        onConfirm={this.props.onConfirm}
        confirmLabel={this.props.confirmLabel}
        cancelLabel={this.props.cancelLabel}
        title={this.props.title}
        iconName={this.props.iconName}
        closeWithoutCalling="true"
        onHelpHeader={this.props.onHelpHeader}
      >
        {translate(this.props.message, this.props.messageTemplateReplacements)}
      </DangerMessagePanelFrame>
    );
  },
});

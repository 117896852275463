import React from 'react';
import { i18n } from '#packages/i18n';
import { Text, Divider, TextButton } from '@wix/wix-base-ui';
import { Trans } from 'react-i18next';

export const FormsMigrationHelper: React.FC<{
  selectTabByName: (name: string) => void;
}> = ({ selectTabByName }) => {
  return (
    <>
      <Divider direction="horizontal" long />
      <div className="forms-migration-helper">
        <Text enableEllipsis={false} size="small" shouldTranslate={false}>
          <Trans
            i18n={i18n}
            i18nKey="CONTENT_MANAGER_6_BUTTON_FormCollectionsMigration_Helper"
          >
            <TextButton
              onClick={() => {
                selectTabByName('WixFormCollections');
              }}
              skin="dark"
              underline="always"
              size="small"
              shouldTranslate={false}
            />
          </Trans>
        </Text>
      </div>
    </>
  );
};

import React from 'react';
import { translate } from '#packages/i18n';
import { RichText, Text, Divider, TextButton } from '@wix/wix-base-ui';

export interface PanelLink {
  titleKey: string;
  onClick: () => void;
  dividerAbove?: boolean;
  dataHook?: string;
}

export interface PanelData {
  body: string;
  title?: string;
  keyText?: string;
  onUnmounted?: () => void;
  links?: PanelLink[];
}

export interface TextAndLinkPanelProps {
  panelData: PanelData;
}

const TextAndLinkPanel: React.FC<TextAndLinkPanelProps> = ({
  panelData: { title, body, keyText, links = [] },
}) => (
  <div className="drop-panel-content text-and-link-drop-panel">
    <Text skin="secondary" enableEllipsis={false} weight="bold">
      {title}
    </Text>
    <RichText className="drop-panel-body">
      <span>{translate(body)}</span>
      {keyText && (
        <span
          key="autosaveTooltipShortcut"
          className="drop-panel-body-autosave-keyText"
        >
          {keyText}
        </span>
      )}
      {links.map(({ titleKey, onClick, dividerAbove, dataHook }) => (
        <React.Fragment key={titleKey}>
          {dividerAbove && <Divider long className="drop-panel-devider" />}
          <TextButton
            onClick={onClick}
            className="drop-panel-link"
            dataHook={dataHook}
            size="small"
          >
            {titleKey}
          </TextButton>
        </React.Fragment>
      ))}
    </RichText>
  </div>
);

export default TextAndLinkPanel;

// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as util from '#packages/util';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_facebook_comments',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: `addPanelData/sections/facebookCommentsSection_${util.languages.getLanguageCode()}/facebookCommentsSection_${util.languages.getLanguageCode()}.png`,
    imageHover: null,
    items: [
      {
        id: 'Blog_Facebook_Comments_1',
        structure: {
          type: 'Component',
          skin: 'skins.core.FacebookCommentSkin',
          layout: {
            width: 400,
            height: 176,
            x: -86,
            y: 11,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WFacebookComment',
          props: {
            type: 'WFacebookCommentProperties',
            metaData: { schemaVersion: '1.0' },
            numPosts: 2,
            width: 400,
            colorScheme: 'light',
            canBeShownOnAllPagesBug: true,
          },
          style: {
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            type: 'TopLevelStyle',
            id: 'fbc1',
            skin: 'skins.core.FacebookCommentSkin',
          },
          id: 'comp-ifzhy7g2',
        },
        preset: { rect: { width: 324, height: 194, x: 0, y: 0 }, tags: null },
      },
    ],
    compTypes: ['wysiwyg.viewer.components.WFacebookComment'],
  },
  help: { hide: false, text: 'add_section_info_text_facebook_comments' },
};

import { waitForAddedCompRef } from '#packages/componentsAddUtils';
import constants from '#packages/constants';
import type { CompStructure, CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import * as util from '#packages/util';

export const textFontRegex = /font_([0-9]|10)(?![a-z0-9])/gi;
export const textColorRegex = /color_[0-9]?[0-9]/gi;
export const textFontFamilyRegex = /font-family: ?(([a-z0-9]*-?)*,?)*/gi;
export const textFontWeightRegex = /font-weight: ?[a-z]*;?/gi;

/**
 * If the component is the root component - strip - this method will return
 * it's layout's y value.
 * If not, for any other component it will return the suggested y value.
 *
 * @param suggestedComp: CompStructure
 * @param currentComp: CompStructure
 */
// Currently not in use, Need to fixed this, because it is not corret
// for a strip inside another strip
export const getLayoutY = (
  suggestedComp: CompStructure,
  currentComp: CompStructure,
): number => {
  if (
    suggestedComp.componentType === constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER
  ) {
    return currentComp.layout.y;
  }
  return suggestedComp.layout.y;
};

export const getTextComponentMeasurement = async (
  editorAPI: EditorAPI,
  serializedText: CompStructure,
): Promise<DOMRect> => {
  const textToAdd = {
    ...serializedText,
    transformations: {
      type: 'TransformData',
      hidden: true,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'ya3gj',
      },
    },
  };
  const textRef = await waitForAddedCompRef(
    editorAPI.components.add(editorAPI.pages.getFocusedPage(), textToAdd),
  );

  return new Promise((resolve) => {
    editorAPI.waitForChangesApplied(() => {
      setTimeout(() => {
        const previewFrame = document.getElementById(
          'preview',
        ) as HTMLIFrameElement;
        const previewDocument = previewFrame.contentWindow.document;
        const textElement = previewDocument.getElementById(textRef.id);
        const boundingRect = textElement.getBoundingClientRect();
        editorAPI.components.remove(textRef);
        resolve(boundingRect);
      }, 500);
    });
  });
};

/**
 * This code is only for POC purposes, and will not be used in production.
 * In order to add all serialized suggestions to the page this function cleans
 * the ID's and parent (parent ID) so there will be no matching ID's to two
 * different strips.
 * @param currentComponent
 */
export const removeIDsFromSuggestion = (currentComponent: AnyFixMe) => {
  if (currentComponent.id) delete currentComponent.id;
  if (currentComponent.parent) delete currentComponent.parent;
  if (currentComponent.components) {
    currentComponent.components.forEach((component: AnyFixMe) => {
      removeIDsFromSuggestion(component);
    });
  }
};

/**
 * This code is only for POC purposes, and will not be used in production.
 * For each suggestion that we want to add to the page we will need to update
 * the strip's Y position so it will be added below the previous strip.
 * @param previousSuggestionPointer
 * @param originalStripBottomYPosition
 */
export const getSuggestionLayoutY = (
  editorAPI: EditorAPI,
  previousSuggestionPointer: CompRef,
  originalStripBottomYPosition: number,
) => {
  if (!previousSuggestionPointer) {
    return originalStripBottomYPosition;
  }
  const { y, height } = editorAPI.components.layout.get_rect(
    previousSuggestionPointer,
  );
  return y + height;
};

export const decodeHtml = (html: any) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

export const getText = (editorAPI: EditorAPI, textRef: CompRef): string => {
  const compData = editorAPI.components.data.get(textRef);
  return compData
    ? decodeHtml(util.stringUtils.removeHtmlTagsFromString(compData.text))
    : '';
};

export const getSectionToSwitchLayout = (
  editorAPI: EditorAPI,
): CompRef | null => {
  const pageRef = editorAPI.pages.getFocusedPage();
  const sectionsStageEntryIndex =
    util.sections.getStageEntryIndex(editorAPI, pageRef) - 1;
  return (
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef)[
      sectionsStageEntryIndex
    ] || null
  );
};

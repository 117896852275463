// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { CustomScroll, Checkbox } from '@wix/wix-base-ui';

import * as core from '#packages/core';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import * as Panels from '#packages/panels';
import * as helpIds from '#packages/helpIds';
import { cx, hoc } from '#packages/util';

import SlideItem from './slideShowBgApplyPanelItem';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;
const { getAllSlides, findCurrentSlide } =
  stateManagement.boxSlideShow.selectors;

function applySlideBgData(
  currentSlideBgData,
  appliedSlides,
  editorAPI,
  origin,
) {
  let bgData;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(appliedSlides, function (appliedSlide) {
    if (appliedSlide.checked) {
      bgData = createSlideBgData(_.cloneDeep(currentSlideBgData), appliedSlide);
    } else {
      bgData = createSlideBgData(
        _.cloneDeep(appliedSlide.originalSlideBgData),
        appliedSlide,
      );
    }

    switch (origin) {
      case 'strip':
        setStripSlideBgData(bgData, appliedSlide.slide, editorAPI);
        break;
      case 'box':
        setBoxSlideBgData(bgData, appliedSlide.slide, editorAPI);
        break;
    }
  });
}

function setStripSlideBgData(newBgData, slide, editorAPI) {
  editorAPI.components.design.update(slide, { background: newBgData });
}

function setBoxSlideBgData(newBgData, slide, editorAPI) {
  editorAPI.components.design.update(slide, { background: newBgData });
}

function createSlideBgData(currentBgData, slide) {
  const oldBgData = slide.originalSlideBgData;

  //same media type
  if (
    currentBgData.mediaRef &&
    oldBgData.mediaRef &&
    currentBgData.mediaRef.type === oldBgData.mediaRef.type
  ) {
    currentBgData.mediaRef.id = oldBgData.mediaRef.id;
    if (currentBgData.mediaRef.type === 'WixVideo') {
      currentBgData.mediaRef.posterImageRef.id =
        oldBgData.mediaRef.posterImageRef.id;
      if (currentBgData.imageOverlay) {
        currentBgData.imageOverlay.id = oldBgData.imageOverlay?.id;
      }
    }
    // not the same media type
  } else if (currentBgData.mediaRef) {
    delete currentBgData.mediaRef.id;
    if (currentBgData.mediaRef.type === 'WixVideo') {
      delete currentBgData.mediaRef.posterImageRef.id;
      if (currentBgData.imageOverlay) {
        delete currentBgData.imageOverlay.id;
      }
    }
  } else {
    currentBgData.mediaRef = null;
    currentBgData.colorOverlay = '';
    currentBgData.imageOverlay = null;
  }

  //copy top level 'BackgroundMedia' id
  currentBgData.id = oldBgData.id;

  return currentBgData;
}

// eslint-disable-next-line react/prefer-es6-class
const SlideShowBgApplyPanel = createReactClass({
  displayName: 'slideShowBgApplyPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    boxSlideShow: PropTypes.object,
  },
  getAllSlides() {
    const editorAPI = this.getEditorAPI();
    return getAllSlides(editorAPI.dsRead, this.props.boxSlideShow);
  },

  getInitialState() {
    const editorAPI = this.getEditorAPI();
    this.selectedItems = [];
    this.appliedSlides = [];
    this.origin = this.props.origin;
    this.currentSlide = findCurrentSlide(
      editorAPI.dsRead,
      this.props.boxSlideShow,
    );
    this.currentSlideBgData = this.getSlideBgData(this.currentSlide);
    this.allSlides = this.getAllSlides();
    this.itemsProps = this.getItemsProps();

    return {
      markAllSlidesDisplay: false,
      indeterminate: false,
    };
  },

  getSlideBgData(slide) {
    const editorAPI = this.getEditorAPI();
    switch (this.origin) {
      case 'strip':
        return editorAPI.components.design.get(slide).background;
      case 'box':
        return editorAPI.components.design.get(slide).background;
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.markAllSlidesDisplay !== nextState.markAllSlidesDisplay ||
      this.state.indeterminate !== nextState.indeterminate ||
      this.appliedSlides.length > 0
    );
  },

  handleSelectAllChange(checked) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.itemsProps,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (item) {
        const { slide } = item;
        const slideId = slide.id;
        if (slideId === this.currentSlide.id) {
          return;
        }
        if (checked) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (!_.includes(this.selectedItems, slideId)) {
            this.selectedItems.push(slideId);
            this.appliedSlides.push({
              checked,
              originalSlideBgData: item.itemBgData,
              slide,
            });
          }
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        } else if (_.includes(this.selectedItems, slideId)) {
          _.pull(this.selectedItems, slideId);
          this.appliedSlides.push({
            checked,
            originalSlideBgData: item.itemBgData,
            slide,
          });
        }
      }, this),
    );
    this.setState(
      { markAllSlidesDisplay: checked, indeterminate: false },
      this.updateSlides,
    );
  },

  getItemsProps() {
    const itemsProps = [];
    const editorAPI = this.getEditorAPI();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.allSlides,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (slide) {
        itemsProps.push({
          slide,
          slideTitle: editorAPI.components.data.get(slide).title,
          currentSlideBgData: this.currentSlideBgData,
          itemBgData: this.getSlideBgData(slide),
          isCurrentSlide: this.currentSlide.id === slide.id,
        });
      }, this),
    );
    return itemsProps;
  },

  getSlideProps(slide) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const slideProps = _.find(this.itemsProps, { slide: { id: slide.id } });
    slideProps.origin = this.origin;
    return slideProps;
  },

  updateSlides() {
    const editorAPI = this.getEditorAPI();
    applySlideBgData(
      this.currentSlideBgData,
      this.appliedSlides,
      editorAPI,
      this.origin,
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const biParams = _.assign(
      {
        total_slides: this.getAllSlides().length,
        num_selected_slides: this.appliedSlides.length,
        all_slides_selected: this.state.markAllSlidesDisplay,
      },
      editorAPI.components.getDefaultBiParams(this.props.boxSlideShow),
    );
    editorAPI.bi.event(
      coreBi.events.boxSlideShow.APPLY_ON_OTHER_SLIDES_SELECTED_SLIDES,
      biParams,
    );
    this.appliedSlides = [];
  },

  slidesStateChange() {
    let value = null;
    if (this.allSlides.length === this.selectedItems.length + 1) {
      value = true;
    }

    if (this.selectedItems.length === 0) {
      value = false;
    }

    return {
      value,
      requestChange: function (checked, slide, originalSlideBgData) {
        const slideId = slide.id;

        if (checked && !(slideId === this.currentSlide.id)) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (!_.includes(this.selectedItems, slideId)) {
            this.selectedItems.push(slideId);
          }
        } else {
          _.pull(this.selectedItems, slideId);
        }
        this.appliedSlides.push({ checked, originalSlideBgData, slide });
        const markAllSlidesDisplay =
          this.allSlides.length === this.selectedItems.length + 1;
        const indeterminate =
          !markAllSlidesDisplay && this.selectedItems.length > 0;
        if (
          markAllSlidesDisplay !== this.state.markAllSlidesDisplay ||
          indeterminate !== this.state.indeterminate
        ) {
          const newState = {
            markAllSlidesDisplay,
            indeterminate,
          };
          this.setState(newState, this.updateSlides);
        } else {
          this.updateSlides();
        }
      }.bind(this),
    };
  },

  render() {
    return (
      <Panels.frames.BgSubPanelFrame
        helpId={helpIds.EXTERNAL.BOX_SLIDE_SHOW_BG_APPLY}
        label="Regular_Slideshow_Apply_Panel_Header_Title"
        {..._.omit(this.props, 'children')}
        className={cx(
          _.omit(this.props, 'children')?.className,
          'slide-show-apply-panel',
        )}
      >
        <div className="slide-show-apply-panel-wrapper">
          <div className="slide-show-apply-panel-description">
            {translate('Regular_Slideshow_Apply_Panel_Header_Description')}
          </div>

          <div className="slide-show-apply-panel-all">
            <Checkbox
              label="Regular_Slideshow_Apply_To_All"
              value={this.state.markAllSlidesDisplay}
              onChange={this.handleSelectAllChange}
              indeterminate={this.state.indeterminate}
              labelAfterSymbol={true}
            />
          </div>

          <CustomScroll>
            <div className="slide-show-apply-panel-scrollbox slide-show-settings-panel-scrollbox">
              <ul className="slide-show-apply-panel-scrollbox-list">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.allSlides, (slide, slideIndex) => (
                  <li key={`slide${slideIndex}`}>
                    <SlideItem
                      valueLink={this.slidesStateChange()}
                      ref={`slide${slideIndex}`}
                      {...this.getSlideProps(slide)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </CustomScroll>
        </div>
      </Panels.frames.BgSubPanelFrame>
    );
  },
});

const mapEditorAPItoProps = ({ editorAPI }, ownProps) =>
  _.defaults(
    {
      boxSlideShow: _.head(
        ownProps.slideShowCompRef
          ? [ownProps.slideShowCompRef]
          : editorAPI.selection.getSelectedComponents(),
      ),
    },
    ownProps,
  );

export default connect(EDITOR_API, mapEditorAPItoProps)(SlideShowBgApplyPanel);

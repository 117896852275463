import _ from 'lodash';

import { translate } from '#packages/i18n';
import { fedopsLogger, isAdvancedMenuOpen, link } from '#packages/util';
import { createPagesApi } from '@/pages-wip';

import { createMenuApi } from '../../API/menuAPI';
import {
  cleanId,
  filterVisibleMenuItems,
  isDropdown,
  isMembersMenuId,
} from '../../utils/utils';
import { MENU_ITEM_TYPE, PAGES_MENU_ID } from '../../constants';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  MenuItem,
  PagesData,
  PagesDataFromAppManifest,
  PageLink,
  DynamicPageLink,
} from 'types/documentServices';
import type {
  IMultiselectPanelGroup,
  IMultiselectPanelSection,
  IMultiselectPanelItem,
} from '#packages/panels';

const { linkTypeValidators } = link;
const { isPageLink, isDynamicPageLink } = linkTypeValidators;

const MEMBERS_GROUP_NAME = 'members';

interface DynamicPageData {
  pageId: string;
  innerRoute: string;
  routerId: string;
  title: string;
}

const getMembersPagesData = (editorAPI: EditorAPI): DynamicPageData[] => {
  const pagesAPI = createPagesApi(editorAPI);

  return pagesAPI.getRouterGroupPagesData(MEMBERS_GROUP_NAME);
};

const getMembersPagesGroupSection = (
  editorAPI: EditorAPI,
): IMultiselectPanelSection | null => {
  const dynamicPagesList = editorAPI.dsRead.routers.pages.getDynamicPagesList();
  const membersAppId =
    editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;

  const membersManifest =
    editorAPI.dsRead.platform.getAppManifest(membersAppId);

  if (!membersManifest) {
    return null;
  }

  const membersGroupLabel =
    membersManifest?.routers?.pagesGroups?.[MEMBERS_GROUP_NAME]?.label;

  const membersPagesSettings = editorAPI.pages.appPages.getApplicationSettings(
    membersAppId,
  ) as PagesDataFromAppManifest;

  const membersPages = _.flatMap(
    dynamicPagesList.filter((page) => page.appDefinitionId === membersAppId),
    // eslint-disable-next-line lodash/prop-shorthand
    (group) => group.pages,
  );

  if (membersPages.length === 0) {
    return null;
  }

  return {
    id: 'members',
    label: membersGroupLabel
      ? `${membersGroupLabel} ${translate('DYNAMIC_PAGES_ROUTER_PAGES')}`
      : membersPagesSettings.displayName,
    automationId: 'multiselect-members-pages',
    groups: [
      {
        id: 'members',
        items: membersPages.map((page) => ({
          id: page.id,
          label: page.title,
          checked: false,
        })),
      },
    ],
  };
};

const getApplicationsPagesSections = (
  editorAPI: EditorAPI,
  pagesIdsInPagesMenu: string[],
): IMultiselectPanelSection[] => {
  const pagesData = editorAPI.pages.getPagesData();
  const installedAppsIds = editorAPI.dsRead.platform
    .getInstalledEditorApps()
    .map((app) => app.appDefinitionId);
  const installedAppsIdsSet = new Set(installedAppsIds);

  const pageIdsInPagesMenuSet = new Set(pagesIdsInPagesMenu);

  const pagesGroupsByAppDefId = pagesData.reduce<Record<string, PagesData[]>>(
    (acc, pageData) => {
      const pageAppDefId = pageData.managingAppDefId;

      if (
        pageAppDefId &&
        pageIdsInPagesMenuSet.has(pageData.id) &&
        installedAppsIdsSet.has(pageAppDefId)
      ) {
        acc[pageAppDefId] = acc[pageAppDefId] || [];
        acc[pageAppDefId].push(pageData);
      }

      return acc;
    },
    {},
  );

  return installedAppsIds
    .map((appDefinitionId) => {
      const appPages: PagesData[] =
        pagesGroupsByAppDefId[appDefinitionId] || [];

      if (appPages.length > 0) {
        const applicationPagesSettings =
          editorAPI.pages.appPages.getApplicationSettings(
            appDefinitionId,
          ) as PagesDataFromAppManifest;

        const appDataClientSpecMap =
          editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId);

        return {
          id: appDefinitionId,
          automationId: `multiselect-${appDefinitionId}-pages`,
          label:
            applicationPagesSettings?.displayName ||
            appDataClientSpecMap.appDefinitionName,
          groups: [
            {
              id: appDefinitionId,
              items: appPages.map((page) => ({
                id: page.id,
                label: page.title,
                checked: false,
              })),
            },
          ],
        };
      }

      return null;
    })
    .filter(Boolean);
};

const getFlattenedMenuItems = <T extends MenuItem>(items: T[]): T[] =>
  items.reduce(
    (acc, menuItem) => [
      ...acc,
      menuItem,
      ...getFlattenedMenuItems(menuItem.items),
    ],
    [],
  );

export const getMainPagesSection = (
  menuItems: MenuItem[],
): IMultiselectPanelSection => {
  const mapMenuItemToGroupItem = (
    menuItem: MenuItem,
  ): IMultiselectPanelItem => ({
    id: cleanId((menuItem.link as PageLink).pageId),
    label: menuItem.label,
    checked: false,
  });

  const pagesMenuGroups = menuItems.reduce<IMultiselectPanelGroup[]>(
    (acc, menuItem) => {
      const childPageItems = menuItem.items.filter((childMenuItem) =>
        isPageLink(childMenuItem.link),
      );

      let currentGroup: IMultiselectPanelGroup;

      if (isPageLink(menuItem.link)) {
        // flatten pages and it's children to 1 group
        currentGroup = {
          id: cleanId(menuItem.link.pageId),
          items: [menuItem, ...childPageItems].map(mapMenuItemToGroupItem),
        };
      } else if (
        (isDropdown(menuItem) || isAdvancedMenuOpen()) &&
        childPageItems.length
      ) {
        // create group with dropdown if it has necessary children
        currentGroup = {
          id: menuItem.id,
          label: menuItem.label,
          items: childPageItems.map(mapMenuItemToGroupItem),
        };
      }

      if (currentGroup) {
        const closestPrevGroup = acc[acc.length - 1];

        if (
          closestPrevGroup &&
          !closestPrevGroup?.label &&
          !currentGroup.label
        ) {
          closestPrevGroup.items.push(...currentGroup.items);
        } else {
          acc.push(currentGroup);
        }
      }

      return acc;
    },
    [],
  );

  return {
    id: 'main',
    label: translate('custom_menu_manage_menu_pages_main_section_title'),
    automationId: 'multiselect-main-pages',
    groups: pagesMenuGroups,
  };
};

const getPageIdsFromMenuItems = (menuItems: MenuItem[]): string[] => {
  const selectPagesIds = (items: MenuItem[]) =>
    items
      .map((item) =>
        linkTypeValidators.isPageLink(item.link)
          ? cleanId(item.link.pageId)
          : null,
      )
      .filter(Boolean);

  return _.flow(getFlattenedMenuItems, selectPagesIds)(menuItems);
};

export const markSectionItemsWithCheckedState = (
  section: IMultiselectPanelSection,
  pagesIdsInMenu: string[],
): IMultiselectPanelSection => {
  const pagesIdsInMenuSet = new Set(pagesIdsInMenu);

  return {
    ...section,
    groups: section.groups.map((group) => ({
      ...group,
      items: group.items.map((groupItem) => ({
        ...groupItem,
        checked: pagesIdsInMenuSet.has(groupItem.id),
      })),
    })),
  };
};

const collectDynamicPagesLinksFromMenuItems = (
  menuItems: MenuItem[],
): DynamicPageLink[] =>
  getFlattenedMenuItems(menuItems)
    .filter((menuItem) => isDynamicPageLink(menuItem.link))
    .map((menuItem) => menuItem.link as DynamicPageLink);

const getPageIdFromDynamicPageLink = (
  link: DynamicPageLink,
  dynamicPagesData: DynamicPageData[],
): string | undefined => {
  const dynamicPageData = dynamicPagesData.find(
    (dynPageData) =>
      link.routerId === dynPageData.routerId &&
      link.innerRoute === dynPageData.innerRoute,
  );

  return dynamicPageData?.pageId;
};

const getMembersPagesIdsFromMenuItems = (
  editorAPI: EditorAPI,
  menuItems: MenuItem[],
): string[] => {
  const membersPagesData = getMembersPagesData(editorAPI);
  const dynamicPagesLinks = collectDynamicPagesLinksFromMenuItems(menuItems);

  return dynamicPagesLinks
    .map((link) => getPageIdFromDynamicPageLink(link, membersPagesData))
    .filter(Boolean);
};

export const getPagesMultiSelectPanelData = (
  editorAPI: EditorAPI,
  menuId: string,
): IMultiselectPanelSection[] => {
  const menuAPI = createMenuApi(editorAPI);
  const currentMenu = menuAPI.getMenu(menuId);
  const pagesMenu = menuAPI.getMenu(PAGES_MENU_ID);

  const currentMenuItems = filterVisibleMenuItems(
    currentMenu.items,
    editorAPI.isMobileEditor(),
  );

  const pagesMenuItems: MenuItem[] =
    editorAPI.menus.getExtendedMenuItemsTree(pagesMenu).items;

  const pageIdsInPageMenu = getPageIdsFromMenuItems(pagesMenuItems);
  const pageIdsInCurrentMenu = getPageIdsFromMenuItems(currentMenuItems);
  const membersPageIdsInMenu = getMembersPagesIdsFromMenuItems(
    editorAPI,
    currentMenuItems,
  );
  const allPageIdsInMenu = pageIdsInCurrentMenu.concat(membersPageIdsInMenu);

  const membersSection = getMembersPagesGroupSection(editorAPI);

  const sections = isMembersMenuId(menuId)
    ? [membersSection]
    : [
        getMainPagesSection(pagesMenuItems),
        membersSection,
        ...getApplicationsPagesSections(editorAPI, pageIdsInPageMenu),
      ];

  return sections
    .filter(Boolean)
    .map((section) =>
      markSectionItemsWithCheckedState(section, allPageIdsInMenu),
    );
};

export const removePagesFromMenuStructure = (
  pagesIdsToRemove: string[],
  menuItems: MenuItem[],
  pageDataFromRouters: DynamicPageData[],
) => {
  const processItems = (menuItems: MenuItem[]): MenuItem[] => {
    return menuItems.reduce<MenuItem[]>((acc, currentMenuItem) => {
      const { link, items: children } = currentMenuItem;

      const processedChildren = processItems(children);

      if (isPageLink(link)) {
        const shouldRemove = pagesIdsToRemove.includes(cleanId(link.pageId));

        if (shouldRemove) {
          return [...acc, ...processedChildren];
        }
      } else if (isDynamicPageLink(link)) {
        const pageId = getPageIdFromDynamicPageLink(link, pageDataFromRouters);
        const shouldRemove = pageId && pagesIdsToRemove.includes(pageId);

        if (shouldRemove) {
          return [...acc, ...processedChildren];
        }
      }

      return [...acc, { ...currentMenuItem, items: processedChildren }];
    }, []);
  };

  return processItems(menuItems);
};

const commonLinkFields = {
  isVisible: true,
  isVisibleMobile: true,
  type: MENU_ITEM_TYPE,
  items: [] as AnyFixMe,
};

export const generateAddPagesLinks = (
  pagesIds: string[],
  pagesMap: Record<string, PagesData>,
  pageDataFromRouter: DynamicPageData[],
): Omit<MenuItem, 'id'>[] => {
  const dynamicPagesByIdData = _.keyBy(pageDataFromRouter, 'pageId');

  return pagesIds.map((pageId) => {
    const dynamicPageData = dynamicPagesByIdData[pageId];
    const page = pagesMap[pageId];

    if (dynamicPageData) {
      const link: DynamicPageLink = {
        routerId: dynamicPageData.routerId,
        innerRoute: dynamicPageData.innerRoute,
        type: 'DynamicPageLink',
      };

      return {
        ...commonLinkFields,
        label: page?.title || dynamicPageData.title,
        link,
      };
    }

    const link: PageLink = {
      type: 'PageLink',
      target: '_self',
      pageId: `#${pageId}`,
    };

    return {
      ...commonLinkFields,
      label: page.title,
      link,
    };
  });
};

export const handleMultiSelectSubmit = (
  editorAPI: EditorAPI,
  menuId: string,
  selectedIds: string[],
) => {
  const menuAPI = createMenuApi(editorAPI);
  const currentMenu = menuAPI.getMenu(menuId);
  const menuItems = filterVisibleMenuItems(
    currentMenu.items,
    editorAPI.isMobileEditor(),
  );

  const pagesMap = _.keyBy(editorAPI.dsRead.pages.getPagesData(), 'id');

  const pageIdsInMenu = getPageIdsFromMenuItems(menuItems);

  const membersPagesData = getMembersPagesData(editorAPI);

  const membersPageIdsInMenu = getMembersPagesIdsFromMenuItems(
    editorAPI,
    menuItems,
  );
  const allPageIdsInMenu = pageIdsInMenu.concat(membersPageIdsInMenu);

  const itemsToAdd = _.difference(selectedIds, allPageIdsInMenu);
  const itemsToRemove = _.difference(allPageIdsInMenu, selectedIds);

  const menuItemsWithoutUnselectedPages = removePagesFromMenuStructure(
    itemsToRemove,
    currentMenu.items,
    membersPagesData,
  );

  const pagesToAddLinks = generateAddPagesLinks(
    itemsToAdd,
    pagesMap,
    membersPagesData,
  );

  menuAPI.replaceMenuItems(menuId, [
    ...menuItemsWithoutUnselectedPages,
    ...(pagesToAddLinks as MenuItem[]),
  ]);

  editorAPI.waitForChangesApplied(() => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.CHANGE_MENU_PAGES,
    );
  });
};

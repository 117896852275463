import type { Scope } from './listClassificationEntryPoint';
import { BasePublicApi } from '#packages/apilib';
import type { CompRef } from '@wix/document-services-types';
import type { ListClassificationResponseGroup } from './prediction/types';
import { getPrediction } from './prediction/prediction';

export interface Config {
  getListClassification: (scope: Scope, sectionRef: CompRef) => void;
}

export async function getListClassification(
  scope: Scope,
  pageId: string,
  compRef: CompRef,
): Promise<ListClassificationResponseGroup | {}> {
  return getPrediction(scope.editorAPI.documentServices, pageId, compRef);
}

export class ListClassificationApi extends BasePublicApi<Scope> {
  getListClassification = this.bindScope(getListClassification);
}

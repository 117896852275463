// @ts-nocheck
import { bi } from '#packages/stateManagement';

import * as blogUtils from '../../utils/blogUtils';

const mapStateToProps = ({ editorAPI }) => ({
  currentPageId: editorAPI.pages.getCurrentPageId(),
  pages: blogUtils.getNewBlogPages(editorAPI),
});

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

const mapDispatchToProps = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  const sendBi = (evid: number, biParams: Record<string, string | string[]>) =>
    dispatch(bi.actions.event({ evid }, biParams));
  const selectPage = (pageId) => editorAPI.pages.navigateTo(pageId);

  return { sendBi, selectPage };
};

export { mapStateToProps, mapDispatchToProps };

import React, { type FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorReporter } from '@wix/editor-error-reporter';
import constants from '#packages/constants';
import {
  Button,
  CustomScroll,
  Divider,
  Preloader,
  TextLabel,
} from '@wix/wix-base-ui';
import type { EditorAPI } from '#packages/editorAPI';
import { utils as rEditorUtils } from '#packages/rEditor';
import * as symbols from '@wix/santa-editor-symbols';
import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import * as util from '#packages/util';

import * as wixStoresBi from '../../../common/bi/wixStoresBi';
import * as wixStoreDataProvider from '../../../common/wixStoreDataProvider';
import dealer, { EventType } from '../../../dealer';

export enum ActionType {
  BusinessManagerLink = 'businessManagerLink',
  ExternalLink = 'externalLink',
  Publish = 'publish',
}

interface IAction {
  type: ActionType;
  value: string;
}

interface IActionItem {
  offerGuid: string;
  action: IAction;
}

interface IMainInfo extends IActionItem {
  title: string;
  subtitle: string;
  CTAtext: string;
  biButtonName: string;
}

interface IQuickAction extends IActionItem {
  title: string;
  icon: string;
  biButtonName: string;
}

export interface IOwnProps {}

interface IDispatchProps {
  onMainInfoCTAClick: (mainInfo: IMainInfo) => void;
  onQuickActionClick: (quickAction: IQuickAction) => void;
  loadData: () => Promise<{
    mainInfo: IMainInfo;
    quickActions: IQuickAction[];
  }>;
  openDashboard: () => void;
}

type IProps = IOwnProps & IDispatchProps;

export const MAIN_INFO_REAL_ESTATE_ID = '251044bc-f47c-4e14-86e8-72a7153464e2';
export const QUICK_ACTIONS_REAL_ESTATE_ID =
  'ef06d0ba-b5e9-4a9b-9fbe-6544e3ef93ca';

const MAX_QUICK_ACTIONS_COUNT = 3;

const MENU_MANAGER_ORIGIN = 'wixstores_menu_manager';

const WixStoresManagePanelWithDealer: FC<IProps> = (props: IProps) => {
  const isCancelled = useRef(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mainInfo, setMainInfo] = useState<IMainInfo>({
    title: '',
    subtitle: '',
    CTAtext: '',
    biButtonName: '',
    offerGuid: '',
    action: { type: ActionType.BusinessManagerLink, value: '' },
  });

  const [quickActions, setQuickActions] = useState<IQuickAction[]>([]);

  const [translate] = useTranslation();

  useEffect(
    () => {
      const doLoad = async () => {
        setIsLoading(true);

        try {
          const { mainInfo, quickActions } = await props.loadData();

          if (isCancelled.current) {
            return;
          }

          setMainInfo(mainInfo);
          setQuickActions(quickActions);
          setIsLoading(false);
        } catch (error) {
          // For now, leave the user in infinite loading loop.
          setIsLoading(true);
          sendSentryError(error);
        }
      };

      doLoad();

      return () => {
        isCancelled.current = true;
      };
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div className="wix-stores-manage-panel-with-dealer">
      {isLoading ? (
        <div className="preloader-center" data-aid="preloader">
          <Preloader className="large" />
        </div>
      ) : (
        <CustomScroll heightRelativeToParent="100%">
          <div className="main-info">
            <TextLabel
              type="T09"
              className="text-center title"
              value={translate(mainInfo.title)}
              enableEllipsis={false}
              shouldTranslate={false}
            />
            <TextLabel
              type="T07"
              className="text-center subtitle"
              value={translate(mainInfo.subtitle)}
              enableEllipsis={false}
              shouldTranslate={false}
            />
            <div className="main-info-cta-container">
              <Button
                className="main-info-cta-button"
                onClick={() => props.onMainInfoCTAClick(mainInfo)}
                automationId="main-info-cta-button"
              >
                {translate(mainInfo.CTAtext)}
              </Button>
              <Button
                className="main-info-secondary-cta-button btn-confirm-secondary"
                automationId="main-info-secondary-cta-button"
                onClick={props.openDashboard}
              >
                {translate(
                  'SiteApp_WixStores_ManagePanel_ManageStore_Button_Label',
                )}
              </Button>
            </div>
          </div>
          <div className="quick-actions-header">
            <TextLabel
              type="T07"
              value={translate('SiteApp_WixStores_FTUE_ItemPart_QuickActions')}
              className="quick-actions-header-text"
              shouldTranslate={false}
            />
            <Divider long />
          </div>

          <ul className="quick-actions">
            {quickActions.map((action, index) => (
              <li
                className="quick-action"
                data-aid="quick-action"
                key={`${action.title}-${index}`}
                onClick={() => props.onQuickActionClick(action)}
              >
                <div className="quick-action-content">
                  <img src={action.icon} alt={action.title} />
                  <TextLabel
                    type="T18"
                    value={translate(action.title)}
                    className="quick-action-text"
                    shouldTranslate={false}
                  />
                  <symbols.symbol name="arrowWithStates" />
                </div>
                {index < quickActions.length - 1 ? <Divider long /> : null}
              </li>
            ))}
          </ul>
        </CustomScroll>
      )}
    </div>
  );
};

const getInstance = (editorAPI: EditorAPI) => {
  const appData = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  );
  return appData?.instance;
};

const getTargetUrl = (editorAPI: EditorAPI, actionItem: IActionItem) => {
  const actionType = actionItem.action.type;

  if (actionType === ActionType.BusinessManagerLink) {
    const baseDomain = util.serviceTopology.businessManagerUrl;
    const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    return rEditorUtils.siteSettingsUtil.getSettingsIframeUrl({
      category: 'default',
      tab: 'default',
      baseDomain,
      metaSiteId,
      path: actionItem.action.value,
    });
  }
  if (actionType === ActionType.ExternalLink) {
    return actionItem.action.value;
  }
  if (actionType === ActionType.Publish) {
    return null;
  }

  return null;
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (
  dispatch: any,
) => {
  const editorAPI = dispatch(getEditorAPI);
  const instance = getInstance(editorAPI);

  const onMainInfoCTAClick = (mainInfo: IMainInfo) => {
    handleClick(mainInfo);

    reportMainInfoCTAClick(mainInfo);
  };

  const handleClick = (actionItem: IActionItem) => {
    const actionType = actionItem.action.type;

    if (actionType === ActionType.BusinessManagerLink) {
      editorAPI.account.openSettings({ path: actionItem.action.value });
      return;
    }
    if (actionType === ActionType.ExternalLink) {
      window.open(actionItem.action.value, '_blank');
      return;
    }
    if (actionType === ActionType.Publish) {
      editorAPI.publishManager.publish({ origin: MENU_MANAGER_ORIGIN });
      return;
    }

    sendSentryError(new Error(`unrecognized action type ${actionType}`));
  };

  const reportMainInfoCTAClick = (mainInfo: IMainInfo) => {
    editorAPI.bi.event(wixStoresBi.events.MANAGE_PANEL_BUTTON_CLICK, {
      app_id: util.tpaUtils.getStoresAppDefId(),
      app_name: wixStoreDataProvider.getAppName(),
      button_name: mainInfo.biButtonName,
      origin: MENU_MANAGER_ORIGIN,
    });

    dealer.reportEvent({
      realEstateId: MAIN_INFO_REAL_ESTATE_ID,
      instance,
      offerId: mainInfo.offerGuid,
      type: EventType.MAIN_CTA_CLICK,
      mainCtaClick: {
        targetUrl: getTargetUrl(editorAPI, mainInfo),
      },
    });
  };

  const onQuickActionClick = (quickAction: IQuickAction) => {
    handleClick(quickAction);

    reportQuickActionClick(quickAction);
  };

  const reportQuickActionClick = (quickAction: IQuickAction) => {
    editorAPI.bi.event(
      wixStoresBi.events.CLICK_ON_QUICK_ACTION_STORES_PANEL_IN_EDITOR,
      {
        app_id: util.tpaUtils.getStoresAppDefId(),
        // For some reason next 2 are uppercase
        appName: wixStoreDataProvider.getAppName(),
        buttonName: quickAction.biButtonName,
        origin: MENU_MANAGER_ORIGIN,
      },
    );

    editorAPI.bi.event(wixStoresBi.events.MANAGE_PANEL_BUTTON_CLICK, {
      app_id: util.tpaUtils.getStoresAppDefId(),
      app_name: wixStoreDataProvider.getAppName(),
      button_name: quickAction.biButtonName,
      origin: MENU_MANAGER_ORIGIN,
    });

    dealer.reportEvent({
      realEstateId: QUICK_ACTIONS_REAL_ESTATE_ID,
      instance,
      offerId: quickAction.offerGuid,
      type: EventType.SECONDARY_CTA_CLICK,
      secondaryCtaClick: {
        targetUrl: getTargetUrl(editorAPI, quickAction),
      },
    });
  };

  const loadDealerOffers = async (realEstateId: string) => {
    const offers = await dealer.getOffers({ instance, realEstateId });
    return offers.map((offer) => {
      const parsedOffer = JSON.parse(offer?.asset?.payloadJson);
      if (typeof parsedOffer.action.value === 'string') {
        parsedOffer.action.value = parsedOffer.action.value.trim();
      }
      return {
        ...parsedOffer,
        offerGuid: offer.offerGuid,
      };
    });
  };

  const loadData = async () => {
    const [mainInfoOffers, quickActionsOffers] = await Promise.all([
      loadDealerOffers(MAIN_INFO_REAL_ESTATE_ID),
      loadDealerOffers(QUICK_ACTIONS_REAL_ESTATE_ID),
    ]);

    if (mainInfoOffers.length < 1 || quickActionsOffers.length < 1) {
      throw new Error('invalid offers');
    }

    const mainInfo = mainInfoOffers[0];
    const quickActions = quickActionsOffers.slice(0, MAX_QUICK_ACTIONS_COUNT);

    await onDataLoaded(mainInfo, quickActions);

    return { mainInfo, quickActions };
  };

  const onDataLoaded = async (
    mainInfo: IMainInfo,
    quickActions: IQuickAction[] = [],
  ) => {
    const actions = quickActions.map((action) => action.biButtonName);
    const state = wixStoreDataProvider.getDropshippingIntentExist()
      ? 'store_manager_dropshipping'
      : 'store_manager';

    editorAPI.bi.event(wixStoresBi.events.VIEW_STORES_PANEL_IN_EDITOR, {
      app_id: util.tpaUtils.getStoresAppDefId(),
      // For some reason this is camelCase
      appName: wixStoreDataProvider.getAppName(),
      state,
      type: 'click',
      actions: JSON.stringify(actions),
    });

    dealer.reportEvent({
      realEstateId: MAIN_INFO_REAL_ESTATE_ID,
      instance,
      offerId: mainInfo.offerGuid,
      type: EventType.VIEW,
    });

    quickActions.map((quickAction) => {
      return dealer.reportEvent({
        realEstateId: QUICK_ACTIONS_REAL_ESTATE_ID,
        instance,
        offerId: quickAction.offerGuid,
        type: EventType.VIEW,
      });
    });
  };

  const openDashboard = () => {
    editorAPI.account.openSettings({ path: '/home' });
    editorAPI.bi.event(wixStoresBi.events.MANAGE_PANEL_BUTTON_CLICK, {
      app_id: util.tpaUtils.getStoresAppDefId(),
      app_name: wixStoreDataProvider.getAppName(),
      button_name: 'DashboardLink',
      origin: MENU_MANAGER_ORIGIN,
    });
  };

  return {
    onMainInfoCTAClick,
    onQuickActionClick,
    loadData,
    openDashboard,
  };
};

const sendSentryError = (error: Error | unknown) => {
  ErrorReporter.captureException(error, {
    tags: { component: 'wix-stores-manage-panel-with-dealer' },
  });
};

export default util.hoc.connect<{}, IDispatchProps, IOwnProps>(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(WixStoresManagePanelWithDealer);

import React from 'react';
import _ from 'lodash';
import { Badge } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import { compIcon, sections } from '#packages/util';
import experiment from 'experiment';

import { AUTOMATION_IDS } from './utils/automationIds';
import { TRANSLATIONS_MAP } from './utils/translations';
import type { IMenuConfig } from './utils/config.types';

export interface GetActionsOptions {
  onAddPageClick: () => void;
  onAddLinkClick: () => void;
  onAddAnchorClick: () => void;
  onAddSectionClick: () => void;
  onAddDropdownClick: () => void;
  onAddDynamicClick: () => void;
  onAddContainerClick: () => void;
  showDynamicPages: boolean;
  showSitePages?: boolean;
  showSubmenu?: boolean;
  showDropdownTitle?: boolean;
  showLinks?: boolean;
  isMembersMenu: boolean;
  isMembersLoginMenu: boolean;
  isInMultiLingualFlow: boolean;
  isMembersAreaV2Context: boolean;
}

export interface MenuAddItemAction {
  type?: string;
  title: React.ReactNode;
  symbolName: string;
  disabled?: boolean;
  onClick: () => void;
  automationId?: string;
  isShowTooltip?: boolean;
  tooltipContent?: React.ReactNode;
}

type ActionDecoratorFactory = (
  options: GetActionsOptions,
) => (actions: MenuAddItemAction[]) => MenuAddItemAction[];

export const getAddMenuItemsActions = (
  options: GetActionsOptions,
  config?: IMenuConfig,
) => {
  const initialActions: MenuAddItemAction[] = [];
  const {
    isMembersMenu,
    isMembersLoginMenu,
    isInMultiLingualFlow,
    isMembersAreaV2Context,
  } = options;

  let decorators = [
    withSitePages,
    withDynamicPages,
    withLinks,
    withSection,
    withAnchor,
    withSubmenu,
    withDropdownTitle,
  ];

  if (config?.features.containers.enabled) {
    decorators.push(withContainer);
  }

  if (isMembersLoginMenu) {
    decorators = [isMembersAreaV2Context ? withLinks : withMemberPages];
  } else if (isMembersMenu) {
    decorators = isInMultiLingualFlow
      ? [withMemberPages]
      : [withMemberPages, withSubmenu];
  }

  const decoratorsWithOptions = decorators.map((decorator) =>
    decorator(options),
  );

  return _.flowRight(...decoratorsWithOptions)(initialActions);
};

const withSitePages: ActionDecoratorFactory =
  ({ onAddPageClick, showSitePages = true }) =>
  (actions) => {
    if (!showSitePages) {
      return actions;
    }

    const title = translate(TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.SITE_PAGES);
    const action = {
      title,
      type: 'action',
      symbolName: compIcon.toWBU('Page'),
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.SITE_PAGES,
      onClick: onAddPageClick,
    };

    return [action, ...actions];
  };

const withDynamicPages: ActionDecoratorFactory =
  ({ onAddDynamicClick, showDynamicPages }) =>
  (actions) => {
    if (!showDynamicPages) {
      return actions;
    }

    const title = translate(TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.DYNAMIC_PAGES);
    const action = {
      title,
      type: 'action',
      symbolName: compIcon.toWBU('PageDynamic'),
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.DYNAMIC_PAGES,
      onClick: onAddDynamicClick,
    };

    return [action, ...actions];
  };

const withLinks: ActionDecoratorFactory =
  ({ onAddLinkClick, showLinks = true }) =>
  (actions) => {
    if (!showLinks) {
      return actions;
    }

    const title = translate(TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.LINK);
    const action = {
      title,
      type: 'action',
      symbolName: compIcon.toWBU('UrlLink'),
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.LINK,
      onClick: onAddLinkClick,
    };

    return [action, ...actions];
  };

const withAnchor: ActionDecoratorFactory =
  ({ onAddAnchorClick }) =>
  (actions) => {
    const title = translate(TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.ANCHOR);
    const action = {
      title,
      type: 'action',
      symbolName: compIcon.toWBU('Anchor'),
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.ANCHOR,
      onClick: onAddAnchorClick,
    };

    return [action, ...actions];
  };

const withSection: ActionDecoratorFactory =
  ({ onAddSectionClick }) =>
  (actions) => {
    if (sections.isSectionsEnabled()) {
      const title = translate(TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.SECTION);
      const action = {
        title,
        type: 'action',
        symbolName: compIcon.toWBU('Section'),
        automationId: AUTOMATION_IDS.FOOTER.ACTIONS.SECTION,
        onClick: onAddSectionClick,
      };

      return [action, ...actions];
    }

    return actions;
  };

const withSubmenu: ActionDecoratorFactory =
  ({ onAddDropdownClick, showSubmenu = true }) =>
  (actions) => {
    if (!showSubmenu) {
      return actions;
    }

    const title = translate(
      experiment.isOpen('specs.santa-editor.dropdownOverSubmenu')
        ? TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.DROPDOWN
        : TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.SUBMENU,
    );
    const action = {
      title,
      type: 'action',
      symbolName: compIcon.toWBU('MenuFolder'),
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.SUBMENU,
      onClick: onAddDropdownClick,
    };

    return [action, ...actions];
  };

const withDropdownTitle: ActionDecoratorFactory =
  ({ onAddDropdownClick, showDropdownTitle }) =>
  (actions) => {
    if (!showDropdownTitle) {
      return actions;
    }

    const title = translate(
      TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.DROPDOWN_TITLE,
    );
    const action = {
      title,
      type: 'action',
      symbolName: compIcon.toWBU('MenuFolder'),
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.SUBMENU,
      onClick: onAddDropdownClick,
    };

    return [action, ...actions];
  };

const withContainer: ActionDecoratorFactory =
  ({ onAddContainerClick }) =>
  (actions) => {
    const title = (
      <>
        {translate(TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.CONTAINER)}
        <Badge
          skin="standard"
          className="menu-manager-panel__add-container-badge"
          value={TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.CONTAINER_BADGE}
        />
      </>
    );

    const action: MenuAddItemAction = {
      title,
      type: 'action',
      symbolName: 'containerPageType',
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.CONTAINER,
      onClick: onAddContainerClick,
    };

    return [action, ...actions];
  };

const withMemberPages: ActionDecoratorFactory =
  ({ onAddPageClick }) =>
  (actions) => {
    const title = translate(TRANSLATIONS_MAP.FOOTER.MENU_ACTIONS.MEMBER_PAGES);
    const action = {
      title,
      type: 'action',
      symbolName: 'membersAction',
      automationId: AUTOMATION_IDS.FOOTER.ACTIONS.MEMBER_PAGES,
      onClick: onAddPageClick,
    };

    return [action, ...actions];
  };

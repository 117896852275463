import { link } from '#packages/util';
import { utils as coreUtils } from '#packages/core';
import { events } from '#packages/coreBi';
import type { IPageMenuItem } from '#packages/menu';
import type { EditorAPI } from '#packages/editorAPI';

export const getMenuItemBiType = (pageMenuItem: IPageMenuItem) => {
  if (pageMenuItem.type.isDropdown) {
    return 'group';
  }

  if (pageMenuItem.type.isPage) {
    return 'page';
  }

  if (pageMenuItem?.link) {
    if (link.linkTypeValidators.isSectionLink(pageMenuItem.link)) {
      return 'SectionLink';
    }

    return pageMenuItem.link.type;
  }

  return 'page';
};

export const sendNavigateToPageBiEvent = (
  editorAPI: EditorAPI,
  {
    pageId,
    biCategory,
    panelName,
  }: {
    pageId?: string;
    biCategory: string;
    panelName: string;
  },
) => {
  const isDynamicPage = pageId
    ? coreUtils.dynamicTypeUtils.getExtendedDynamicPageInfo(editorAPI, pageId)
    : { isDynamicPage: true };

  editorAPI.bi.event(events.pages.navigate_to_page, {
    page_id: pageId,
    panel_name: panelName,
    category: biCategory,
    isDynamicPage: JSON.stringify(isDynamicPage),
  });
};

// @ts-nocheck
import * as util from '#packages/util';
import * as wixStoreCollectionService from '../deepLinkHandlers/wixStoreCollectionService';
import * as wixStoreDashboardService from '../deepLinkHandlers/wixStoreDashboardService';
import { tpaUtils } from '#packages/util';

function hasDeepLink() {
  return (
    util.url.getParameterByName('etpa') === tpaUtils.getEcomAppDefId() &&
    util.url.getParameterByName('etpaMode') === 'blank'
  );
}

function getDeepLinkState() {
  return JSON.parse(util.url.getParameterByName('etpaState') || '{}');
}

function handle() {
  const watchers = [wixStoreCollectionService, wixStoreDashboardService];

  if (hasDeepLink()) {
    const deepLinkState = getDeepLinkState();

    watchers.forEach(function (watcher) {
      watcher.handleDeepLink(deepLinkState);
    });
  }
}

export { hasDeepLink, getDeepLinkState, handle };

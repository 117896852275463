import React from 'react';
import type { MapDispatchToProps, Dispatch } from 'types/redux';
import { ContextMenu, ContextMenuAction } from '@wix/wix-base-ui';
import { privateAppsAvailableAppsContextMenuClick } from '@wix/bi-logger-blocks-consumption/v2';

import * as util from '#packages/util';
import { translate } from '#packages/i18n';

import { isAlphaApp } from '../../utils/utils';
import { editInBlocks } from '../../utils/appDetailsUtils';
import type { AppData } from '../privateAppsProvider/privateAppsProvider';
import { useAppVersionsListContext } from '../AppVersionsList/AppVersionsListContext';
import {
  installApp,
  getAppInstallVersion,
  type InstallAppDispatchProps,
} from '../../utils/installApp';

const TRANSLATION_KEYS = {
  INSTALL_APP:
    'blocks-private-apps.AppDiscovery_Not_Installed_Apps_Context_Menu_Item3',
  SEE_ALL_VERSIONS:
    'blocks-private-apps.AppDiscovery_Not_Installed_Apps_Context_Menu_Item1',
  EDIT_ON_BLOCKS:
    'blocks-private-apps.AppDiscovery_Not_Installed_Apps_Context_Menu_Item2',
};

interface Action {
  label: string;
  biAction: string;
  isAvailable: boolean;
  onClick: () => void;
}

interface AvailableAppActionsProps extends InstallAppDispatchProps {
  app: AppData;
  origin: 'app item' | 'app details page';
  contextMenuProps?: Partial<React.ComponentProps<typeof ContextMenu>>;
}

const AvailableAppActionsComponent: React.FC<AvailableAppActionsProps> = ({
  app,
  origin,
  contextMenuProps = {},
  installApp,
}) => {
  const installAppAction = {
    label: translate(TRANSLATION_KEYS.INSTALL_APP),
    biAction: 'install app',
    isAvailable: origin === 'app item',
    onClick: () => installApp(app),
  };

  const { setSelectedVersionListApp } = useAppVersionsListContext();

  const seeAllVersionsAction = {
    label: translate(TRANSLATION_KEYS.SEE_ALL_VERSIONS),
    biAction: 'see all versions',
    isAvailable: !isAlphaApp(app.blocksVersion),
    onClick: () => {
      setSelectedVersionListApp(app);
    },
  };

  const editOnBlocksAction = {
    label: translate(TRANSLATION_KEYS.EDIT_ON_BLOCKS),
    biAction: 'edit in blocks',
    isAvailable: true,
    onClick: () => {
      editInBlocks(
        app.appDefinitionId,
        util.appStudioUtils.httpReferrerOptions.PrivateAppPanel,
      );
    },
  };

  const actions: Action[] = [
    installAppAction,
    seeAllVersionsAction,
    editOnBlocksAction,
  ];

  return (
    <ContextMenu {...contextMenuProps}>
      {actions
        .filter((action) => action.isAvailable)
        .map((action) => (
          <ContextMenuAction
            key={action.label + action.biAction}
            onClick={() => {
              action.onClick();
              util.biLogger.report(
                privateAppsAvailableAppsContextMenuClick({
                  appId: app.appDefinitionId,
                  origin,
                  action: action.biAction,
                  builderType: 'classic',
                }),
              );
            }}
          >
            <span>{action.label}</span>
          </ContextMenuAction>
        ))}
    </ContextMenu>
  );
};

type IMapDispatchToProps = MapDispatchToProps<InstallAppDispatchProps, {}>;

const mapDispatchToProps: IMapDispatchToProps = (dispatch: Dispatch) => ({
  installApp: (app: AppData): Promise<unknown> => {
    const version = getAppInstallVersion(app);
    return dispatch(installApp({ app, version }));
  },
});

export const AvailableAppActions = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(AvailableAppActionsComponent);

// @ts-nocheck
import _ from 'lodash';
import { translate } from '#packages/i18n';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import * as coreBi from '#packages/coreBi';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_audioplayer',
      isApplied: gfppDataUtils.getDataDefinedFn('uri'),
      onClick(editorAPI, compRef) {
        const compId = compRef[0]?.id;

        editorAPI.bi.event(coreBi.events.singleAudioPlayer.changeTrackClicked, {
          component_type: editorAPI.components.getType(compRef),
          comp_id: compId,
        });

        const { mediaServices } = editorAPI;
        mediaServices.mediaManager.open(
          mediaServices.mediaManager.categories.MUSIC,
          'music_player',
          {
            translation: {
              submitButton: translate(
                'MMGR_submitbutton_onstage_player_mytracks_choose',
              ),
            },
            multiSelect: false,
            callback(items) {
              if (!_.isEmpty(items)) {
                const selectedAudioDetails = items[0];
                const data = editorAPI.components.data.get(compRef);

                const newData = {
                  uri: selectedAudioDetails.fileName,
                  originalFileName: selectedAudioDetails.title,
                  artist: 'Unknown Artist',
                  track: 'Unknown Track',
                };

                if (newData.uri !== data.uri) {
                  editorAPI.components.data.update(compRef, newData);
                }

                editorAPI.bi.event(coreBi.events.singleAudioPlayer.trackAdded, {
                  layout: 'null',
                  source: 'upload',
                  trackID: selectedAudioDetails.id,
                  component_type: editorAPI.components.getType(compRef),
                  comp_id: compId,
                });
              }
              editorAPI.mediaServices.setFocusToEditor();
            },
          },
        );
      },
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.SINGLE_AUDIO_PLAYER,
    },
  },
};

// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';

//TYPE WAS GENERATED, remove this line when reviewed
interface EmptyPanelFrameProps {
  panelName: string;
}

class EmptyPanelFrame extends React.Component<EmptyPanelFrameProps> {
  static displayName = constants.PANEL_TYPES.EMPTY;

  static propTypes = {
    panelName: PropTypes.string.isRequired,
  };

  getHtmlProps = () => {
    return _.pick(this.props, ['style', 'className', 'data-hook']);
  };

  render() {
    return (
      <div {...this.getHtmlProps()}>
        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}

const WrappedCompPanelFrame = stateManagement.panels.hoc.panelFrame(
  constants.PANEL_TYPES.EMPTY,
)(EmptyPanelFrame);
WrappedCompPanelFrame.pure = EmptyPanelFrame;

export default WrappedCompPanelFrame;

import React from 'react';

import { fedopsLogger } from '#packages/util';
import connect, {
  type MultilingualGoogleTranslateWarningDispatchProps,
} from './multilingualGoogleTranslateWarningMapper';
import { GoogleTranslateWarningPanelFrame } from './multilingualGoogleTranslateWarningPanelFrame';

export interface MultilingualGoogleTranslateWarningPanelOwnProps {
  panelName?: string;
  translate: () => Promise<void>;
  wordsRemain: number;
  contentLength: number;
  translatedWords: number;
  source: 'site-translation' | 'element-translation';
}

export interface MultilingualGoogleTranslateWarningPanelProps
  extends MultilingualGoogleTranslateWarningPanelOwnProps,
    MultilingualGoogleTranslateWarningDispatchProps {}

class GoogleTranslateWarningPanel extends React.Component<MultilingualGoogleTranslateWarningPanelProps> {
  state = {
    dontShowAgain: false,
  };

  componentWillUnmount() {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MULTILINGUAL
        .GOOGLE_TRANSLATE_WARNING_PANEL_CLOSE,
    );
  }

  closePanel = (button_name: 'x' | 'cancel' | 'autotranslate') => {
    const { dontShowAgain } = this.state;
    this.props.closePanel(dontShowAgain);
    if (button_name !== 'autotranslate') this.props.sendCancelBi(button_name);

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MULTILINGUAL
        .GOOGLE_TRANSLATE_WARNING_PANEL_CLOSE,
    );
  };

  onCancel = () => {
    this.closePanel('cancel');
  };

  onClose = () => {
    this.closePanel('x');
  };

  onConfirm = async () => {
    this.props.sendConfirmBi();
    await this.props.translate();
    this.closePanel('autotranslate');
  };

  onDontShowAgain = (dontShowAgain: boolean) => {
    this.props.sendDontShowAgainBi();
    this.setState({ dontShowAgain });
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  render() {
    return (
      <GoogleTranslateWarningPanelFrame
        panelName={this.props.panelName}
        onClose={this.onClose}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
        howMuchLeftAfterTranslation={
          this.props.wordsRemain - this.props.contentLength
        }
        source={this.props.source}
        dontShowAgain={this.state.dontShowAgain}
        onDontShowAgainChange={(value) => this.onDontShowAgain(value)}
      />
    );
  }
}

export default connect(GoogleTranslateWarningPanel);

import React from 'react';
import { translate } from '#packages/i18n';
import { frames } from '#packages/panels';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  RichText,
} from '@wix/wix-base-ui';
import {
  mapDispatchToProps,
  mapStateToProps,
} from './blogDeprecationPanelMapper';
import * as util from '#packages/util';

const BlogDeprecationPanel = ({
  hasNewBlog,
  onHelp,
  onContinue,
  onClose,
}: AnyFixMe) => {
  return (
    <frames.FocusPanelFrame
      shouldHideHeader={true}
      panelName={'blog.blogDeprecationPanel'}
      className="popup-large"
    >
      <Composites.PopupLargeSize>
        <Composites.BigPanelHeaderWithIllustration>
          <PanelHeaderCenteredText
            // @ts-expect-error
            shouldTranslate={false}
            title={translate(
              hasNewBlog
                ? 'SiteApp_NewBlog_SiteHistory_Popup_Title'
                : 'SiteApp_NewBlog_SiteHistory_Popup2_Title',
            )}
            onHelp={onHelp}
            onClose={onClose}
          />
          <PanelHeaderIllustration>
            <symbols.symbol name="blogUpgradeIllustration" />
          </PanelHeaderIllustration>
        </Composites.BigPanelHeaderWithIllustration>
        <Composites.PanelContent>
          {hasNewBlog ? (
            <>
              <Composites.RichText>
                <RichText type="T06">
                  {translate('SiteApp_NewBlog_SiteHistory_Popup_Subtitle')}
                </RichText>
              </Composites.RichText>
              <Composites.ActionSetVertical>
                <Button onClick={onContinue}>
                  {translate('SiteApp_NewBlog_SiteHistory_Popup_Continue')}
                </Button>
                <Button className="btn-text" onClick={onHelp}>
                  {translate('SiteApp_NewBlog_SiteHistory_Popup_Link')}
                </Button>
              </Composites.ActionSetVertical>
            </>
          ) : (
            <>
              <Composites.RichText>
                <RichText type="T06">
                  {translate('SiteApp_NewBlog_SiteHistory_Popup2_Subtitle')}
                </RichText>
              </Composites.RichText>
              <Composites.RichText>
                <RichText type="T06">
                  {translate('SiteApp_NewBlog_SiteHistory_Popup2_Subtitle2')}
                </RichText>
              </Composites.RichText>
              <Composites.ActionSetVertical>
                <Button onClick={onContinue}>
                  {translate('SiteApp_NewBlog_SiteHistory_Popup2_CTA')}
                </Button>
                <Button className="btn-text" onClick={onHelp}>
                  {translate('SiteApp_NewBlog_SiteHistory_Popup2_Link')}
                </Button>
              </Composites.ActionSetVertical>
            </>
          )}
        </Composites.PanelContent>
      </Composites.PopupLargeSize>
    </frames.FocusPanelFrame>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BlogDeprecationPanel);

import { createReduxStore } from '#packages/apilib';
import type promoteConstants from './constants';

interface UserPackage {
  packageName: string;
  isTopTier: boolean;
  packageId: string;
  packageGroup: string;
}

export interface PromoteStore {}

export interface PromoteState {
  userPackage: UserPackage | null;
  fetchStatus: ValueOf<typeof promoteConstants.EM_FETCH_STATUS>;
}

const getInitialState = (): PromoteState => ({
  fetchStatus: null,
  userPackage: null,
});

export const PromoteStore = createReduxStore({
  getInitialState,
  selectors: {
    getPackageName: (state: PromoteState) => state.userPackage?.packageName,
    getIsTopTier: (state: PromoteState) => state.userPackage?.isTopTier,
    getPackageId: (state: PromoteState) => state.userPackage?.packageId,
    getPackageGroup: (state: PromoteState) => state.userPackage?.packageGroup,
    getFetchStatus: (state: PromoteState) => state.fetchStatus,
  },
  actions: {
    setFetchStatus(
      state: PromoteState,
      fetchStatus: ValueOf<typeof promoteConstants.EM_FETCH_STATUS>,
    ) {
      return {
        ...state,
        fetchStatus,
      };
    },
    setEmailMarketingData(state: PromoteState, userPackage: UserPackage) {
      return {
        ...state,
        userPackage,
      };
    },
  },
});

import * as core from '#packages/core';
import { DATA_BINDING } from '@wix/app-definition-ids';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import createReactClass from 'create-react-class';
import { createCollectionInEmptyStateClicked } from '../bi';
import { origin, EVENTS } from '../consts';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { EmptyState } from '@wix/wix-base-ui/next';
import type { VideoTooltipProps } from './videoTooltip/videoTooltip';
import VideoTooltip from './videoTooltip/videoTooltip';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import styles from './emptyState.scss';

interface CollectionItemProps {
  aid: string;
  title: string;
  description: string;
  icon: string;
  onCreateNewCollectionClick?: () => void;
  buttonTitle: string;
  videoTooltipProps?: VideoTooltipProps;
  sendBi: (event: BiEventDefinition, params: BiEventFields) => void;
  onSettingsIconClick?: (actionName: string, isEmptyState: boolean) => void;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<CollectionItemProps>({
  displayName: 'CollectionItem',

  propTypes: {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onCreateNewCollectionClick: PropTypes.func.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    videoTooltipProps: PropTypes.object,
    sendBi: PropTypes.func.isRequired,
    onSettingsIconClick: PropTypes.func,
  },

  mixins: [core.mixins.editorAPIMixin, util.translationMixin as AnyFixMe],

  onCreateNewCollectionClick() {
    this.props.sendBi(createCollectionInEmptyStateClicked, null);
    const editorAPI = this.getEditorAPI();
    const { applicationId } =
      editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);
    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType: EVENTS.createCollectionButtonClicked,
      eventPayload: {
        origin,
      },
    });
  },

  render() {
    return (
      <div data-hook="empty-state">
        <div className={styles.actionsContainer}>
          {this.props.videoTooltipProps ? (
            <VideoTooltip {...this.props.videoTooltipProps} />
          ) : null}
        </div>
        <EmptyState
          image={
            <symbols.symbol aid="empty-state-icon" name={this.props.icon} />
          }
          title={this.translateIfNeeded(this.props.title)}
          content={this.translateIfNeeded(this.props.description)}
          primaryButton={{
            text: this.translateIfNeeded(this.props.buttonTitle),
            onClick: this.onCreateNewCollectionClick,
          }}
        />
      </div>
    );
  },
});

import React from 'react';
import { MessageModal } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';

interface ErrorSectionProps {
  closePanel: () => void;
  content: {
    header: string;
    message: string;
  };
  onHelp?: () => void;
  primaryButton: {
    label: string;
    onClick: () => void;
  };
}

const ErrorSection = (props: ErrorSectionProps) => (
  <MessageModal
    theme="destructive"
    onCloseButtonClick={props.closePanel}
    dataHook="error-section"
    title={props.content.header}
    content={props.content.message}
    primaryButtonText={translate(props.primaryButton.label)}
    primaryButtonOnClick={props.primaryButton.onClick}
  />
);

export default ErrorSection;

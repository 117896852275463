import type { ISuggestResult } from '#packages/presetApi';
import type { BusinessType } from '../../types';

export const suggestItemsToOptions = (
  suggestResult = {
    results: [],
    relatedResults: [],
  } as ISuggestResult,
) =>
  suggestResult.results
    .concat(suggestResult.relatedResults.slice(0, 5))
    .map((res) => res.displayName);

export const getBusinessTypeByString = (
  str: string,
  { relatedResults, results }: ISuggestResult,
): BusinessType => {
  const suggestItem = [...results, ...relatedResults].find(
    (item) => item.displayName === str,
  );

  return {
    displayName: suggestItem?.displayName || str,
    industryId: suggestItem?.industryId,
    structureId: suggestItem?.structureId,
  };
};

export const HEAVY_SITES = {
  NOTIFICATION_SKIPPED_THIRD:
    'FTE_tour_ed_to_ed2_heavy_sites_no_offline_skip_notifications_text',
  NOTIFICATION_TECH_ERROR:
    'FTE_tour_ed_to_ed2_heavy_sites_tech_error_notifications_text',
  NOTIFICATION_TIME_OUT:
    'FTE_tour_ed_to_ed2_heavy_sites_time_out_notifications_text',
  NOTIFICATION_SUCCESS_MIGRATION:
    'FTE_tour_ed_to_ed2_heavy_sites_success_notifications_text',
  NOTIFICATION_SUCCESS_LINK_TEXT:
    'FTE_tour_ed_to_ed2_heavy_sites_success_notifications_link_text',
  NOTIFICATION_LEARN_MORE_LINK_TEXT: 'Notifications_Learn_More_Link',
};

export const PER_PAGE_MIGRATION = {
  ERROR_MODAL_TITLE: 'FTE_per_page_inner_page_migration_error_title',
  ERROR_MODAL_BODY: 'FTE_per_page_inner_page_migration_error_body',
  ERROR_MODAL_BUTTON: 'FTE_per_page_inner_page_migration_error_button',
  NOTIFICATION_ERROR_FROM_PREVIEW_TEXT:
    'FTE_per_page_inner_page_migration_from_preview_error_notification_text',
  NOTIFICATION_ERROR_FROM_PREVIEW_CTA:
    'FTE_per_page_inner_page_migration_from_preview_error_notification_cta',
};

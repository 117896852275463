import React, { useRef, useEffect, useState } from 'react';
import { PresetPreviewComponent } from '#packages/presetApi';
import styles from './switchLayoutPanel.scss';
import { LAYOUT_PREVIEW_WIDTH, SCALE_FOR_PAAS_PRESET_PREVIEW } from '../consts';
import { ImSureInputIsSafeInjectHtml } from '#packages/util';

export interface ResultPreviewProps {
  resultItem: any;
  isOriginal?: boolean;
  onScaled?: (scaledHeight: number) => void;
  onPresetPreviewBeforeRender: (preset: any) => void;
}

export const ResultPreview: React.FC<ResultPreviewProps> = React.memo(
  ({ resultItem, isOriginal, onScaled, onPresetPreviewBeforeRender }) => {
    const snapshotRef = useRef(null);
    const [scale, setScale] = useState(0);
    useEffect(() => {
      if (snapshotRef.current) {
        const scale = LAYOUT_PREVIEW_WIDTH / snapshotRef.current.offsetWidth;
        setScale(scale);
        if (onScaled) {
          onScaled(snapshotRef.current.offsetHeight * scale);
        }
      }
    }, [onScaled]);

    if (isOriginal) {
      return (
        <ImSureInputIsSafeInjectHtml
          tag="div"
          className={styles['snap-shot-preview']}
          data-hook="switch-layout-result-preview-original"
          style={{
            transform: `scale(${scale}`,
          }}
          ref={snapshotRef}
          html={resultItem.snapShot}
        />
      );
    } else if (resultItem.isPaasPreset) {
      return (
        <div className={styles.presetPreviewWrapper}>
          <PresetPreviewComponent
            scale={SCALE_FOR_PAAS_PRESET_PREVIEW}
            presetPreview={resultItem.preset}
            onBeforeRender={() =>
              onPresetPreviewBeforeRender(resultItem.preset)
            }
          />
        </div>
      );
    } else if (resultItem.preset) {
      return (
        <div className={styles['previewer-preview']}>
          {resultItem.reactPreview}
        </div>
      );
    }
    return null;
  },
  () => true, //NOTE: we don’t want to re-render this comp
);

ResultPreview.displayName = 'ResultPreview';

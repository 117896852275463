import constants from '#packages/constants';
import type { Shell } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { Duplexer } from '@wix/duplexer-js';

type VirtualSocket = ReturnType<Duplexer['connect']>;

const duplexerSocketsServerUrl = 'duplexer.wix.com';
const WIX_BLOCKS_SERVICE_APP_DEF_ID = '2c48b394-4078-4c1e-9fe6-05feee951ef9';

export const createDuplexerFacade = (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const instanceUpdater = {
    getInstance() {
      return editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      )?.instance;
    },
  };

  const duplexer = new Duplexer(duplexerSocketsServerUrl, { instanceUpdater });
  const userChannelEvents: [string, Function][] = [];

  let appConnection: VirtualSocket;
  let userChannel: ReturnType<VirtualSocket['subscribeToUserChannel']>;

  return {
    isConnected: () => !!appConnection,
    connect: () => {
      appConnection = duplexer.connect({
        appDefId: WIX_BLOCKS_SERVICE_APP_DEF_ID,
      });
      appConnection.on('@duplexer:connected', () => {
        userChannel = appConnection.subscribeToUserChannel();
        userChannelEvents.forEach(([eventName, eventHandler]) =>
          userChannel.on(eventName, eventHandler),
        );
      });
    },
    disconnect: () => {
      appConnection.disconnect();
      appConnection = undefined;
      userChannel = undefined;
    },
    subscribeToUserChannelEvent: (eventName: string, handler: Function) => {
      userChannelEvents.push([eventName, handler]);
      if (userChannel) {
        return (eventName: string, handler: Function) => {
          userChannel.on(eventName, handler);
        };
      }
    },
  };
};

import _ from 'lodash';
import * as gfppData from '#packages/gfppData';
import constants from '#packages/constants';
import experiment from 'experiment';

import { sections, compIcon, biLogger, guidUtils } from '#packages/util';
import { layoutUtils } from '#packages/layoutUtils';
import { BasePublicApi } from '#packages/apilib';
import { background, notifications } from '#packages/stateManagement';
import {
  setImageAsBackgroundActions,
  setImageAsBackgroundSuccess,
  undoRedoComponentChange,
  componentAddedToStage,
} from '@wix/bi-logger-editor/v2';
import {
  DEFAULT_BACKGROUND_STRUCTURE,
  mapImageToBackgroundData,
  getImageDimensions,
} from './util';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  CompRef,
  PageBackgroundMediaRef,
  Size,
} from 'types/documentServices';
import type { Scope } from './scope';
import type { IconInfo } from 'types/core';
import type {
  setImageAsBackgroundSuccessParams,
  setImageAsBackgroundActionsParams,
} from '@wix/bi-logger-editor/v2/types';

type EditorAPIFieldType<Path> = GetFieldType<EditorAPI, Path>;

type ComponentDesignType = ReturnType<
  EditorAPIFieldType<'components.design.get'>
>;

const {
  SECTION,
  STRIP_COLUMNS_CONTAINER,
  COLUMN,
  POPUP_CONTAINER,
  PHOTO,
  PAGE,
} = constants.COMP_TYPES;

export const backgroundCandidatesCompTypes = [
  COLUMN,
  STRIP_COLUMNS_CONTAINER,
  SECTION,
  POPUP_CONTAINER,
];

const panelsThatDontMatchSelectedComponentByCompType = {
  [STRIP_COLUMNS_CONTAINER]:
    'compPanels.panels.StripColumnsContainer.backgroundPanel',
  [PAGE]: constants.COMP_PANELS.POPUP.OVERLAY.DESIGN_PANEL.NAME,
  [POPUP_CONTAINER]: constants.COMP_PANELS.POPUP.CONTAINER.DESIGN_PANEL.NAME,
};

export const isImageToBackgroundEnabled = (
  scope: Scope,
  selectedComponents: CompRef[],
) => {
  const { components, editorAPI, platform } = scope;

  if (
    !sections.isSectionsEnabled() ||
    !experiment.isOpen('se_imageToBackground') ||
    editorAPI.isMobileEditor() ||
    components.getType(selectedComponents) !== PHOTO ||
    components.layout.isPinned(selectedComponents)
  ) {
    return false;
  }

  const hasConnections = platform.controllers.getConnections(
    selectedComponents[0],
  ).length;

  return !hasConnections;
};

const updatePopupPageBackground = (
  scope: Scope,
  pageId: string,
  newBackground: ComponentDesignType['background'],
) => {
  const { pages, editorAPI } = scope;
  const { pageBackgrounds } = pages.popupPages.getCurrentPopupData();
  const deviceType = 'desktop';
  const currentRef = pageBackgrounds[deviceType].ref;
  if (currentRef && typeof currentRef !== 'string') {
    Object.assign(pageBackgrounds[deviceType].ref, newBackground);
  }
  editorAPI.store.dispatch(
    background.actions.updatePageBackground(
      deviceType,
      pageBackgrounds[deviceType],
      pageId,
    ),
  );

  return pageBackgrounds.desktop.ref;
};

export const isDetachImageFromBackgroundEnabled = (
  scope: Scope,
  selectedComponentsOriginal: CompRef[],
) => {
  const { components, editorAPI, columns, platform, pages } = scope;
  if (
    !sections.isSectionsEnabled() ||
    !experiment.isOpen('se_imageToBackground') ||
    editorAPI.isMobileEditor()
  ) {
    return false;
  }

  const selectedComponentType = components.getType(selectedComponentsOriginal);

  if (
    !backgroundCandidatesCompTypes.includes(selectedComponentType) &&
    !editorAPI.isPopUpMode()
  ) {
    return false;
  }
  let selectedComponent = selectedComponentsOriginal[0];

  if (selectedComponentType === STRIP_COLUMNS_CONTAINER) {
    selectedComponent =
      columns.getColumnIfStripIsSingleColumn(selectedComponent);
  }

  const backgroundType = (
    components.design.get(selectedComponent)?.background ||
    pages.popupPages.getCurrentPopupData()?.pageBackgrounds?.desktop?.ref
  )?.mediaRef?.type;

  if (backgroundType !== 'Image') {
    return false;
  }

  const hasConnections =
    platform.controllers.getConnections(selectedComponent).length;

  return !hasConnections;
};

const postImageDetach = async (
  scope: Scope,
  backgroundDetachTarget: CompRef,
  addedImage: CompRef,
  {
    correlationId,
    detachImageEntryPointTriggerTime,
  }: { correlationId: string; detachImageEntryPointTriggerTime: number },
) => {
  const { selection, components, editorAPI } = scope;

  const blankBackgroundStructure: { mediaRef: null } = { mediaRef: null };
  if (components.getType(backgroundDetachTarget) === PAGE) {
    updatePopupPageBackground(
      scope,
      backgroundDetachTarget.id,
      blankBackgroundStructure,
    );
    editorAPI.history.debouncedAdd('page background changed');
  } else {
    updateComponentBackground(
      scope,
      backgroundDetachTarget,
      blankBackgroundStructure,
      false,
    );
  }

  await scope.editorAPI.waitForChangesAppliedAsync();

  biLogger.report(
    setImageAsBackgroundSuccess({
      origin: 'rcm',
      action_name: 'detach',
      component_id: backgroundDetachTarget.id,
      component_type: components.getType(backgroundDetachTarget),
      image_component_id: addedImage.id,
      duration: Math.round(
        performance.now() - detachImageEntryPointTriggerTime,
      ),
      correlationId,
    }),
  );

  selection.selectComponentByCompRef(addedImage);

  biLogger.report(
    componentAddedToStage({
      origin: 'detachFromBackground',
      component_id: addedImage.id,
      component_type: PHOTO,
    }),
  );
};

const detachImageFromBackground = (
  scope: Scope,
  backgroundDetachTargetOriginal: CompRef,
  detachImageEntryPointTriggerTime: number,
) => {
  const { components, editorAPI, columns, pages } = scope;

  let backgroundDetachTarget = backgroundDetachTargetOriginal;
  let imageAttachContainer = backgroundDetachTargetOriginal;

  const backgroundCompType = components.getType(backgroundDetachTarget);

  if (backgroundCompType === STRIP_COLUMNS_CONTAINER) {
    backgroundDetachTarget = columns.getColumnIfStripIsSingleColumn(
      backgroundDetachTarget,
    );
    imageAttachContainer = components.getChildren(
      backgroundDetachTargetOriginal,
    )[0];
  }
  const biCorrelationId = guidUtils.getGUID();

  if (backgroundCompType === PAGE) {
    imageAttachContainer = pages.popupPages.getPopupContainer();
  }

  sendImageToBackgroundEntryPointActionBI(
    scope,
    backgroundDetachTargetOriginal,
    {
      origin: 'rcm',
      action_name: 'detach',
      action_type: 'click',
      correlationId: biCorrelationId,
    },
    imageAttachContainer,
  );

  const imageMediaData = _.pick(
    (
      components.design.get(backgroundDetachTarget)?.background ||
      pages.popupPages.getCurrentPopupData()?.pageBackgrounds?.desktop?.ref
    )?.mediaRef,
    ['uri', 'title', 'alt', 'height', 'width'],
  );

  const { width, height } =
    components.layout.getRelativeToScreen(imageAttachContainer);
  const { left, right } = components.layout.getCompMargin(imageAttachContainer);
  const marginsSum = left + right;
  const heightToWidthProportions = imageMediaData.height / imageMediaData.width;

  const imageLayout = getImageDimensions(
    width,
    height,
    marginsSum,
    heightToWidthProportions,
  );
  const imagePosition =
    editorAPI.pasteLogic.addPanelPasteLogic.getPastePosition(
      editorAPI,
      imageLayout,
      imageAttachContainer.id,
    );

  Object.assign(imageLayout, imagePosition);

  const componentStructure =
    editorAPI.components.buildDefaultComponentStructure(PHOTO);
  _.merge(componentStructure, {
    layout: imageLayout,
    data: imageMediaData,
    props: { autoFill: true },
  });
  components.add(
    imageAttachContainer,
    componentStructure,
    null,
    (addedImage: CompRef) =>
      postImageDetach(scope, backgroundDetachTarget, addedImage, {
        correlationId: biCorrelationId,
        detachImageEntryPointTriggerTime,
      }),
  );
};

const updateComponentBackground = (
  scope: Scope,
  backgroundTargetComp: CompRef,
  newBackground: ComponentDesignType['background'],
  shouldNotAddToUndoRedoStack = true,
): ComponentDesignType['background'] => {
  const { components } = scope;

  const currentBackgroundData =
    components.design.get(backgroundTargetComp)?.background ||
    DEFAULT_BACKGROUND_STRUCTURE;

  components.design.update(
    backgroundTargetComp,
    {
      type: 'MediaContainerDesignData',
      background: { ...currentBackgroundData, ...newBackground },
    },
    true,
    shouldNotAddToUndoRedoStack,
  );

  return currentBackgroundData;
};

const showAttachNotificationIfApplicable = (
  scope: Scope,
  originalBackground:
    | PageBackgroundMediaRef
    | ComponentDesignType['background'],
  removedImage: CompRef,
) => {
  const isBackgroundColorVisible =
    originalBackground.colorLayers?.length &&
    originalBackground.colorLayers?.[0]?.opacity !== 0;
  const isBackgroundMediaVisible =
    originalBackground.mediaRef && originalBackground.mediaRef?.opacity !== 0;

  if (isBackgroundColorVisible || isBackgroundMediaVisible) {
    scope.editorAPI.store.dispatch(
      notifications.actions.showUserActionNotification({
        message: 'fluid_set_background_replace_notification_body',
        title: 'Image to Background',
        type: 'info',
        link: {
          caption: 'fluid_set_background_replace_notification_cta',
          onNotificationLinkClick: () => {
            scope.editorAPI.history.undo();
            biLogger.report(
              undoRedoComponentChange({
                actionName: 'set_image_as_background',
                component_id: removedImage.id,
                component_type: PHOTO,
                origin: 'notification',
              }),
            );
          },
        },
      }),
    );
  }
};

const postBackgroundUpdate = async (
  scope: Scope,
  backgroundTargetComp: CompRef,
  // for single-column strip, the selected component on stage and in panel should be different
  panelTargetComp: CompRef,
  previousBackground:
    | PageBackgroundMediaRef
    | ComponentDesignType['background'],
  biData: {
    biParams: setImageAsBackgroundSuccessParams;
    imageToBgEntryPointTriggerTime: number;
  },
) => {
  const { components, selection, editorAPI } = scope;
  const imageComponent = selection.getSelectedComponents()[0];
  const backgroundTargetCompType = components.getType(backgroundTargetComp);

  editorAPI.history.debouncedAdd('image set as container background');

  biLogger.report(
    setImageAsBackgroundSuccess({
      ...biData.biParams,
      component_id: backgroundTargetComp.id,
      component_type: backgroundTargetCompType,
      image_component_id: imageComponent.id,
      duration: Math.round(
        performance.now() - biData.imageToBgEntryPointTriggerTime,
      ),
    }),
  );

  await components.remove(imageComponent, null, 'imageToBackground');

  selection.selectComponentByCompRef(backgroundTargetComp);

  if (
    _.isEqual(backgroundTargetComp, panelTargetComp) &&
    !panelsThatDontMatchSelectedComponentByCompType[backgroundTargetCompType]
  ) {
    const togglePanelFn = gfppData.utils.getTogglePanelFn(
      constants.ROOT_COMPS.GFPP.ACTIONS.BACKGROUND,
      {
        selectedComponent: [panelTargetComp],
        origin: 'imageToBackground',
      },
    );
    togglePanelFn(editorAPI);
  } else {
    gfppData.utils.toggleComponentPanel(
      editorAPI,
      panelsThatDontMatchSelectedComponentByCompType[backgroundTargetCompType],
      {
        selectedComponent: [panelTargetComp],
        origin: 'imageToBackground',
      },
    );
  }

  if (previousBackground) {
    showAttachNotificationIfApplicable(
      scope,
      previousBackground,
      imageComponent,
    );
  }
};

export const sendImageToBackgroundEntryPointActionBI = (
  scope: Scope,
  backgroundCandidate: CompRef,
  actionBiParams: setImageAsBackgroundActionsParams,
  imageAttachCandidate?: CompRef,
) => {
  const [selectedComponent] = scope.selection.getSelectedComponents();
  const biParams = actionBiParams;

  Object.assign(biParams, {
    bg_component_id: backgroundCandidate.id,
    bg_component_type: scope.components.getType(backgroundCandidate),
  });

  if (actionBiParams.action_name === 'detach') {
    Object.assign(biParams, {
      parent_component_id: imageAttachCandidate?.id,
      parent_component_type: scope.components.getType(imageAttachCandidate),
    });

    biLogger.report(setImageAsBackgroundActions(biParams));
    return;
  }

  const [componentBIParams] = scope.bi.getComponentsBIParams([
    selectedComponent,
  ]);

  Object.assign(biParams, componentBIParams);

  biLogger.report(setImageAsBackgroundActions(biParams));
  return;
};

export const setImageAsComponentBackground = async (
  scope: Scope,
  backgroundTargetCompOriginal: CompRef,
  imageData: ComponentDesignType,
  imageToBgEntryPointTriggerTime?: number,
  biParams?: setImageAsBackgroundActionsParams,
) => {
  const { components, columns, editorAPI } = scope;
  let backgroundTargetComp = backgroundTargetCompOriginal;

  sendImageToBackgroundEntryPointActionBI(
    scope,
    backgroundTargetCompOriginal,
    biParams,
  );

  const backgroundTargetCompType = components.getType(backgroundTargetComp);

  if (backgroundTargetCompType === STRIP_COLUMNS_CONTAINER) {
    backgroundTargetComp =
      columns.getColumnIfStripIsSingleColumn(backgroundTargetComp);
  }

  const newBackground = mapImageToBackgroundData(imageData);

  let previousBackground = null;

  if (backgroundTargetCompType === PAGE) {
    previousBackground = updatePopupPageBackground(
      scope,
      backgroundTargetComp.id,
      newBackground,
    );
  } else {
    previousBackground = updateComponentBackground(
      scope,
      backgroundTargetComp,
      newBackground,
    );
  }

  await editorAPI.waitForChangesAppliedAsync();

  postBackgroundUpdate(
    scope,
    backgroundTargetCompOriginal,
    backgroundTargetComp,
    previousBackground,
    { biParams, imageToBgEntryPointTriggerTime },
  );
};

const getOverlappedComponentsEligibleAsBGTarget = (
  scope: Scope,
  selectedCompRef: CompRef,
  shouldNotSortByOrder = false,
) => {
  const hasConnections = (compRef: CompRef) =>
    scope.platform.controllers
      .getConnections(compRef)
      .find((connection) => connection.type === 'ConnectionItem');

  if (scope.editorAPI.isPopUpMode()) {
    const currentPopupOverlay = scope.pages.popupPages.getCurrentPopup();
    const currentPopupContainer = scope.pages.popupPages.getPopupContainer();

    return [currentPopupContainer, currentPopupOverlay].filter(
      (container) => !hasConnections(container),
    );
  }
  const selectedComponentLayout =
    scope.components.layout.getRelativeToScreen(selectedCompRef);

  const allContainersOfEligibleTypeInHeader =
    backgroundCandidatesCompTypes.flatMap((compType) =>
      scope.components.get.byType(
        compType,
        scope.editorAPI.siteSegments.getHeader(),
      ),
    );
  const allContainersOfEligibleTypeInFooter =
    backgroundCandidatesCompTypes.flatMap((compType) =>
      scope.components.get.byType(
        compType,
        scope.editorAPI.siteSegments.getFooter(),
      ),
    );

  const allContainersOfEligibleTypeInCurrentPage =
    backgroundCandidatesCompTypes.flatMap((compType) =>
      scope.components.get.byType(compType, scope.pages.getCurrentPage()),
    );

  const allContainersOfEligibleType = _.flatten([
    allContainersOfEligibleTypeInHeader,
    allContainersOfEligibleTypeInCurrentPage,
    allContainersOfEligibleTypeInFooter,
  ]);

  const allContainersFilteredByProperties = allContainersOfEligibleType.filter(
    (container) => {
      const containerType = scope.components.getType(container);
      if (containerType === POPUP_CONTAINER || hasConnections(container)) {
        return false;
      }

      // should not return a strip item if it has multiple columns and all columns have a non-transparent background, no margins and no spacing
      if (containerType === STRIP_COLUMNS_CONTAINER) {
        const isBackgroundVisible =
          gfppData.columnsUtils.shouldShowChangeBackgroundAction(
            scope.editorAPI,
            container,
            scope.columns.isSingleColumnStrip(container),
          );

        return isBackgroundVisible;
      }

      // should not return a column item for single-column strip
      if (
        containerType === COLUMN &&
        scope.columns.isSingleColumnStrip(
          scope.components.getContainerOrScopeOwner(container),
        )
      ) {
        return false;
      }
      return true;
    },
  );

  const allElegibleContainers = allContainersFilteredByProperties.filter(
    (container) => {
      const containerLayout =
        scope.components.layout.getRelativeToScreen(container);

      const selectedComponentOverlapsBGCandidate = layoutUtils.doBoxesOverlap(
        containerLayout,
        selectedComponentLayout,
      );

      return selectedComponentOverlapsBGCandidate;
    },
  );

  if (shouldNotSortByOrder) {
    return allElegibleContainers;
  }

  return scope.components.normalizeOrder(allElegibleContainers).reverse();
};

const getAllBackgroundCandidates = (
  scope: Scope,
  selectedComponent: CompRef,
) => {
  return getOverlappedComponentsEligibleAsBGTarget(scope, selectedComponent);
};

const getCompWithLargestOverlappedArea = (
  scope: Scope,
  selectedComponent: CompRef,
  overlappedComponents: CompRef[],
) => {
  const selectedComponentRect =
    scope.components.layout.getRelativeToScreen(selectedComponent);

  const allOverlapAreas: number[] = [];

  const getActualOverlapArea = (
    mainComponent: CompRef,
    originalOverlapArea: number,
  ): number | undefined => {
    const indicesOfBGCandidatesOverlappingContainer: number[] = [];

    const mainComponentIndex = overlappedComponents.findIndex(
      (component) => component.id === mainComponent.id,
    );

    for (let i = 0; i < mainComponentIndex; i++) {
      if (
        layoutUtils.doBoxesOverlap(
          scope.components.layout.getRelativeToScreen(mainComponent),
          scope.components.layout.getRelativeToScreen(overlappedComponents[i]),
        )
      ) {
        indicesOfBGCandidatesOverlappingContainer.push(i);
      }
    }

    const areasOfBGCandidatesOverlappingContainer =
      indicesOfBGCandidatesOverlappingContainer.map(
        (index) => allOverlapAreas[index],
      );

    if (areasOfBGCandidatesOverlappingContainer.length > 0) {
      const sumOfContainerOverlappingCompsAreas =
        areasOfBGCandidatesOverlappingContainer.reduce((acc, area) => {
          return acc + area;
        });

      const componentType = scope.components.getType(mainComponent);

      const actualOverlapArea =
        originalOverlapArea - sumOfContainerOverlappingCompsAreas;

      if (
        componentType === STRIP_COLUMNS_CONTAINER &&
        !scope.columns.isSingleColumnStrip(mainComponent)
      ) {
        const stripColumns = scope.components.getChildren(mainComponent);
        const columnsIndicesInOverlappedComponents = stripColumns
          .map((column) =>
            overlappedComponents.findIndex((comp) => column.id === comp.id),
          )
          .filter((index) => index >= 0);

        const columnsAreasSum = allOverlapAreas.reduce(
          (acc, area, index) =>
            columnsIndicesInOverlappedComponents.includes(index)
              ? acc + area
              : acc,
          0,
        );

        if (
          columnsAreasSum === originalOverlapArea &&
          columnsIndicesInOverlappedComponents.length > 1
        ) {
          columnsIndicesInOverlappedComponents.forEach((index) => {
            allOverlapAreas[index] = 0;
          });
          return originalOverlapArea;
        }
      }

      return Math.max(0, actualOverlapArea);
    }

    return originalOverlapArea;
  };

  overlappedComponents.forEach((component) => {
    const overlappedComponentRect =
      scope.components.layout.getRelativeToScreen(component);

    const squareOverlapArea = layoutUtils.getBoxesOverlapArea(
      selectedComponentRect,
      overlappedComponentRect,
    );

    const actualOverlapArea = getActualOverlapArea(
      component,
      squareOverlapArea,
    );

    allOverlapAreas.push(actualOverlapArea);
  });

  const largestOverlappedArea = Math.max(...allOverlapAreas);
  const largestOverlappedAreaIndex = allOverlapAreas.findIndex(
    (area) => area === largestOverlappedArea,
  );

  return overlappedComponents[largestOverlappedAreaIndex];
};

export const getMainBackgroundCandidate = (
  scope: Scope,
  selectedComponent: CompRef,
) => {
  const allOverlappedComponents = getOverlappedComponentsEligibleAsBGTarget(
    scope,
    selectedComponent,
    true,
  );

  return getCompWithLargestOverlappedArea(
    scope,
    selectedComponent,
    allOverlappedComponents,
  );
};

const getIconForRCMSubItem = (
  scope: Scope,
  item: CompRef,
  imageSize?: number,
  containerSize?: Size,
) => {
  const { components, pages, columns } = scope;

  const componentType = components.getType(item);
  if (
    componentType === STRIP_COLUMNS_CONTAINER &&
    columns.isSingleColumnStrip(item)
  ) {
    item = columns.getColumnIfStripIsSingleColumn(item);
  }

  const background =
    components.design.get(item)?.background ||
    pages.popupPages.getCurrentPopupData()?.pageBackgrounds?.desktop?.ref;
  const iconData: Partial<IconInfo> = {
    css:
      containerSize || imageSize
        ? { containerSize: containerSize?.width || imageSize }
        : {},
  };

  if (background?.mediaRef?.type === 'Image') {
    const { url: src, css } = compIcon.getSrcByImageData(background.mediaRef);
    Object.assign(css, {
      width: imageSize,
      height: imageSize,
    });
    _.merge(iconData, { src, css, svgName: '' });
  } else {
    _.merge(iconData, components.getIconInfo(item, containerSize, 'rcm'));
  }

  return iconData as IconInfo;
};

export class ImageToBackgroundPublicApi extends BasePublicApi<Scope> {
  setImageAsComponentBackground = this.bindScope(setImageAsComponentBackground);
  isImageToBackgroundEnabled = this.bindScope(isImageToBackgroundEnabled);
  getIconForRCMSubItem = this.bindScope(getIconForRCMSubItem);
  getMainBackgroundCandidate = this.bindScope(getMainBackgroundCandidate);
  getAllBackgroundCandidates = this.bindScope(getAllBackgroundCandidates);
  isDetachImageFromBackgroundEnabled = this.bindScope(
    isDetachImageFromBackgroundEnabled,
  );
  detachImageFromBackground = this.bindScope(detachImageFromBackground);
  sendImageToBackgroundEntryPointActionBI = this.bindScope(
    sendImageToBackgroundEntryPointActionBI,
  );
}

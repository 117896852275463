import React from 'react';
import { cx } from '#packages/util';
import {
  Skin,
  Action,
  SectionView,
  type DispatchActionFn,
  type DispatchableAction,
  SectionViewParentType,
} from '@wix/add-panel-component';
import type { CompStructure } from '@wix/document-services-types';
import { type PresetSectionItem, SectionType } from '@wix/add-panel-common';
import { languages, hoc, workspace } from '#packages/util';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import { useAddPanelUtils } from './useAddPanelUtils';
import {
  mapStateToProps,
  mapDispatchToProps,
  type IAddPanelSectionViewProps,
} from './sectionViewMapper';
import styles from './sectionView.scss';

const locale = languages.getLanguageCode();
const isNewWorkspace = workspace.isNewWorkspaceEnabled();
const SELECTED_ITEM_INDICATOR_SYMBOL_NAME = 'selectedItemDesignPanel';

const headerStyle = {
  width: addPanelDataConsts.DESIGN_PANEL.LIVE_COMPONENT_CATEGORY_CONTENT,
  minHeight: addPanelDataConsts.DESIGN_PANEL.SECTION_HEADER_HEIGHT,
};

const NewSectionView: React.FC<IAddPanelSectionViewProps> = ({
  index,
  theme,
  viewType,
  section,
  isSelectedItem,
  onClickHandler,
  getSuperAppData,
  hasSectionStyle,
  selectedItemKey,
  onMouseEnterHandler,
  onMouseLeaveHandler,
  getLiveChatSectionColors,
}) => {
  const addPanelUtils = useAddPanelUtils({
    getLiveChatSectionColors,
    getSuperAppData,
    hasSectionStyle,
    theme,
  });

  const handleAction = React.useCallback(
    (action: DispatchableAction) => {
      switch (action.type) {
        case Action.ComponentClick: {
          const {
            item,
            section,
            itemId: payloadItemId,
            compDef: payloadCompDef,
          } = action.payload;

          const compDef = item?.structure ?? payloadCompDef;
          const itemId = payloadItemId ?? item?.id;

          onClickHandler(
            compDef as CompStructure,
            section.sectionName,
            (item as PresetSectionItem)?.preset?.tags,
            itemId,
          );
          return;
        }
        case Action.ComponentMouseEnter: {
          const { compDef, itemId } = action.payload;

          onMouseEnterHandler(compDef as CompStructure, itemId);
          return;
        }
        case Action.ComponentMouseLeave: {
          const { itemId } = action.payload;
          onMouseLeaveHandler(itemId);
          return;
        }
      }
    },
    [onClickHandler, onMouseEnterHandler, onMouseLeaveHandler],
  );

  const classes = cx(styles.sectionView, {
    'live-comps':
      section.type === SectionType.Theme ||
      section.type === SectionType.Personalized ||
      section.type === SectionType.PersonalizedDesign,
    'items-wrapper': section.type === SectionType.Preset,
  });

  return (
    <SectionView
      index={index}
      locale={locale}
      section={section}
      className={classes}
      utils={addPanelUtils}
      viewType={viewType}
      headerStyle={headerStyle}
      shouldDisableTooltipOnHover
      selectedItemKey={selectedItemKey}
      checkItemSelected={isSelectedItem}
      onAction={handleAction as DispatchActionFn}
      skin={isNewWorkspace ? Skin.ClassicFacelift : Skin.Classic}
      themedSectionParentType={SectionViewParentType.DesignPanel}
      selectedItemSymbolName={SELECTED_ITEM_INDICATOR_SYMBOL_NAME}
    />
  );
};

export const NewAddPanelSectionView = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(NewSectionView);

import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import * as panels from '#packages/panels';
import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';

interface Props {
  token: string;
  url: string;
  title?: string;
  initialData?: any;
  width?: number | string;
  height?: number | string;
}

export default class extends React.Component<Props> {
  static displayName = 'platformToolPanel';

  static propTypes = {
    token: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    initialData: PropTypes.any,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  render() {
    return (
      <panels.frames.ToolPanelFrame
        panelName="platformPanels.toolPanel"
        shouldTranslate={false}
        headerTitle={this.props.title}
        shouldNotCloseOnBlur={true}
        shouldHideHelp={true}
        lightHeader
        contentStyle={_.pick(this.props, ['top', 'left', 'width'])}
        contentClass="platform-tool-panel-content"
      >
        <PlatformPanelApplicationFrame
          token={this.props.token}
          url={this.props.url}
          initialData={this.props.initialData}
          panelClass="platformToolPanel"
          height={this.props.height}
          width="100%"
        />
      </panels.frames.ToolPanelFrame>
    );
  }
}

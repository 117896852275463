export const PANEL_NAME = 'ai.panels.welcomePanel';

export enum PanelTypes {
  InjectionToSite = 'injection to site',
  AutoInjectionToSite = 'auto-injection to site',
}

export enum ImageTypes {
  Image = 'image',
  PageBackground = 'pageBackground',
  StripBackground = 'stripBackground',
  SectionBackground = 'sectionBackground',
}

export enum SuggestPhotosRequestSource {
  UNKNOWN = 'UNKNOWN',
  PUBLIC_MEDIA = 'PUBLIC_MEDIA',
  UNSPLASH = 'UNSPLASH',
  CAAS = 'CAAS',
}

export enum InjectionActionOrigin {
  NoPanel = 'no panel',
  WithFunnelData = 'with funnel data',
  Submit = 'submit',
  Close = 'close',
}

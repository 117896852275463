import { fedopsLogger } from '#packages/util';
import constants from '#packages/constants';
import { quickEditPanelOrigin } from '../consts';

import type {
  StateMapperArgs,
  Dispatch,
  MapStateToProps,
  MapDispatchToPropsFunction,
  ThunkAction,
} from 'types/redux';

import type {
  AiTextCreatorEntryPointDispatchProps,
  AiTextCreatorEntryPointOwnProps,
  AiTextCreatorEntryPointStateProps,
} from './aiTextCreatorEntryPointTypes';
import type { EditorAPI } from '#packages/editorAPI';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  AiTextCreatorEntryPointStateProps,
  AiTextCreatorEntryPointOwnProps
> = ({}: StateMapperArgs) => {
  return {};
};

export const mapDispatchToProps: MapDispatchToPropsFunction<
  AiTextCreatorEntryPointDispatchProps,
  AiTextCreatorEntryPointOwnProps
> = (dispatch: Dispatch, { compRef }) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openAiTextGeneratorPanel: (onDataChanged: (compText: string) => void) => {
      const openPanelInteraction = fedopsLogger.mapInteraction(
        fedopsLogger.INTERACTIONS.AI_TEXTGENERATOR_OPEN,
      );
      openPanelInteraction.start();
      editorAPI.openComponentPanel(constants.componentPanels.aiTextGenerator, {
        openPanelInteraction,
        onDataChanged: async () => {
          await editorAPI.waitForChangesAppliedAsync();
          onDataChanged(editorAPI.components.data.get(compRef).text);
        },
        origin: quickEditPanelOrigin,
      });
    },
  };
};

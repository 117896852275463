// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';

const BI_NOT_IMPLEMENTED_MESSAGE =
  'There is no BI event for the control [%s], in [%s] panel.\n\tDear developer, Please add the control mapping to core/bi/controlsToEvents and the event to core/bi/events.json .';

function sendBI(controlName, instanceId, changeEvent) {
  const eventDefinition = util.uiControlsBIUtil.getEventDefinition(controlName);
  if (!eventDefinition) {
    console.warn(
      BI_NOT_IMPLEMENTED_MESSAGE,
      controlName,
      this.constructor.displayName,
    );
    return;
  }
  const controlParams = util.uiControlsBIUtil.getParams(
    controlName,
    changeEvent,
  );
  const params = _.merge(
    { panel_name: this.constructor.displayName, control_name: instanceId },
    controlParams,
  );
  this.getEditorAPI().selectedComponent.sendSelectedComponentBI(
    eventDefinition,
    params,
  );
}

function onUIChange(controlName, instanceId, event) {
  sendBI.call(this, controlName, instanceId, event);
  //undoRedo -> take snapshot? - perhaps only if the 'event' sends {value: someVal} ?
}

export default {
  childContextTypes: {
    reportUIChange: PropTypes.func,
  },
  componentDidMount() {
    if (!_.isFunction(this.getEditorAPI)) {
      throw new Error(
        'You must use editorAPIMixin to use the reportBiEventOnUiChangeMixin',
      );
    }
  },
  getChildContext() {
    return {
      reportUIChange: onUIChange.bind(this),
    };
  },
};

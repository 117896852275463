// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as utils from '@wix/santa-editor-utils';
import * as Symbols from '@wix/santa-editor-symbols';
import constants from '#packages/constants';
import * as util from '#packages/util';
import * as BaseUI from '#packages/baseUI';
import * as coreBi from '#packages/coreBi';

import * as rulersComputations from './rulersComputations';
import type { BiEventDefinition } from 'types/documentServices';

const trashSymbolName = 'guideToolTipTrash';
const approvalSymbolName = 'smallVApproval';
const APPROVE_POSITION_TIMEOUT = 700;
const DISAPPROVE_POSITION_TIMEOUT = 900;
const INPUT_IN_STEPPER_CHARACTER_SIZE = 10;
const INPUT_IN_STEPPER_MINIMUM_SIZE = 20;
const BUBBLE_DISTANCE_FROM_TOP_HOVER = 3;
const BUBBLE_DISTANCE_FROM_TOP_SELECTED = 6;
const MARGIN_FROM_GUIDE_CATCHER_VERTICAL = -5;
const MARGIN_FROM_GUIDE_CATCHER_HORIZONTAL = 5;
const HOVER_BUBBLE_DISAPPEARANCE_TIMEOUT = 600;
const HOVER_BUBBLE_ACTIVATION_TIMEOUT = 500;

//TYPE WAS GENERATED, remove this line when reviewed
interface GuideProps {
  rulerDirection: string;
  guidePosition: number;
  guideId: string;
  isSelected?: boolean;
  isGuideOutOfLimitsFunc: FunctionFixMe;
  startDrag: FunctionFixMe;
  isBeingDragged: boolean;
  endDrag: FunctionFixMe;
  registerDragCallbacks: FunctionFixMe;
  isMouseOverRulers?: boolean;
  toolsHidden?: boolean;
  disableInteraction?: boolean;
  pagesContainerAbsLayout: AnyFixMe;
  stageLayout: any;
  isMobileEditor: boolean;
  ruler: any;
  sendBIEvent: FunctionFixMe;
  selectGuide: FunctionFixMe;
  deselectGuide: FunctionFixMe;
  removeGuideById: FunctionFixMe;
  updateStateOnDragEnd: FunctionFixMe;
  startGuideDrag: FunctionFixMe;
  changeGuidePosition: FunctionFixMe;
  previewSize: AnyFixMe;
  scrollLeft: number;
  editingAreaWidth: number;
  isFixedStageTopGapEnabled: boolean;
  sendBi: (biEvent: BiEventDefinition, fields: any) => void;
}

class Guide extends React.Component<GuideProps> {
  static displayName = 'guide';

  static propTypes = {
    rulerDirection: PropTypes.string.isRequired,
    guidePosition: PropTypes.number.isRequired,
    guideId: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    isGuideOutOfLimitsFunc: PropTypes.func.isRequired,
    startDrag: PropTypes.func.isRequired,
    isBeingDragged: PropTypes.bool.isRequired,
    endDrag: PropTypes.func.isRequired,
    registerDragCallbacks: PropTypes.func.isRequired,
    isMouseOverRulers: PropTypes.bool,
    toolsHidden: PropTypes.bool,
    disableInteraction: PropTypes.bool,
    pagesContainerAbsLayout: PropTypes.object.isRequired,
    isMobileEditor: PropTypes.bool.isRequired,
    sendBIEvent: PropTypes.func.isRequired,
    selectGuide: PropTypes.func.isRequired,
    deselectGuide: PropTypes.func.isRequired,
    removeGuideById: PropTypes.func.isRequired,
    updateStateOnDragEnd: PropTypes.func.isRequired,
    startGuideDrag: PropTypes.func.isRequired,
    changeGuidePosition: PropTypes.func.isRequired,
    previewSize: PropTypes.object.isRequired,
    scrollLeft: PropTypes.number.isRequired,
    editingAreaWidth: PropTypes.number.isRequired,
    isFixedStageTopGapEnabled: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.preventClickAfterDragEnd = false;
    props.registerDragCallbacks({
      onDragStart: this.onDragStart,
      whileDrag: this.whileDrag,
      onDragEnd: this.onDragEnd,
    });

    this.state = {
      isHoverBubble: false,
      mouseOverGuide: false,
      guidePosition: props.guidePosition,
      stepperSymbol: trashSymbolName,
      stepperValueInvalid: false,
      selectedDueToHoverBubbleClick: false,
      isFirstRender: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isBeingDragged) {
      this.setState({
        stepperValueInvalid: false,
        guidePosition: nextProps.guidePosition,
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (
      !this.props.toolsHidden &&
      nextProps.toolsHidden &&
      nextProps.isSelected
    ) {
      this.props.deselectGuide();
    }
    if (!this.state.isHoverBubble && nextState.isHoverBubble) {
      this.props.sendBIEvent('GUIDE_TOOLTIP_APPEAR', {});
    }
  }

  setGuideSelected = (origin) => {
    if (this.state.isFirstRender) {
      this.setState({ isFirstRender: false });
    }
    this.props.selectGuide(this.props.guideId, origin);

    this.setState({
      stepperValueInvalid: false,
      stepperSymbol: trashSymbolName,
    });
  };

  shouldShowSelectedGuide = () => {
    return (
      this.props.isBeingDragged ||
      this.state.mouseOverGuide ||
      this.props.isSelected
    );
  };

  getGuideContainerStyle = () => {
    return this.props.rulerDirection === 'horizontal'
      ? { top: this.state.guidePosition }
      : {
          left: rulersComputations.getVerticalGuidePosition(
            this.props.isMobileEditor,
            this.props.pagesContainerAbsLayout,
            this.state.guidePosition,
            this.props.scrollLeft,
          ),
        };
  };

  getGuideStyle = () => {
    const { previewSize, editingAreaWidth, isFixedStageTopGapEnabled } =
      this.props;
    if (this.props.rulerDirection === 'horizontal') {
      const guideWidth = util.sections.isSectionsEnabled()
        ? editingAreaWidth + constants.UI.EDITING_AREA_RIGHT_MARGIN
        : previewSize.width;

      return {
        left: -guideWidth,
        width: guideWidth,
      };
    }

    const mobilePreviewTopInNewWorkspace = isFixedStageTopGapEnabled
      ? constants.UI.FIXED_STAGE_MARGIN_TOP
      : constants.UI.MOBILE_PREVIEW_TOP_NEW_WORKSPACE;

    const MOBILE_PREVIEW_TOP = util.workspace.isNewWorkspaceEnabled()
      ? mobilePreviewTopInNewWorkspace
      : constants.UI.MOBILE_PREVIEW_TOP;
    const DESKTOP_PREVIEW_TOP = isFixedStageTopGapEnabled
      ? constants.UI.FIXED_STAGE_MARGIN_TOP
      : 0;
    const height = this.props.isMobileEditor
      ? previewSize.height + MOBILE_PREVIEW_TOP
      : previewSize.height + DESKTOP_PREVIEW_TOP;

    return { height };
  };

  removeGuide = () => {
    this.props.removeGuideById(this.props.guideId);
  };

  onDragStart = () => {
    this.props.startGuideDrag();
    this.setState({
      stepperValueInvalid: false,
      stepperSymbol: trashSymbolName,
    });
  };

  getDragPosition = (newLocation) => {
    const xOffset = this.props.stageLayout.left;

    return this.props.rulerDirection === 'horizontal'
      ? rulersComputations.calcHorizontalDraggingPosition(
          newLocation.top,
          this.containerRef,
        )
      : newLocation.left - this.props.pagesContainerAbsLayout.x - xOffset;
  };

  whileDrag = (newLocation) => {
    const realNewPosition = this.getDragPosition(newLocation);
    if (this.props.isGuideOutOfLimitsFunc(realNewPosition)) {
      this.props.endDrag();
    } else {
      this.setState({ guidePosition: realNewPosition });
    }
  };

  onDragEnd = (newLocation) => {
    this.props.updateStateOnDragEnd(this.props.isMouseOverRulers);

    const newGuidePosition = this.getDragPosition(newLocation);
    if (this.props.isGuideOutOfLimitsFunc(newGuidePosition)) {
      this.props.sendBIEvent('GUIDE_DELETED', { origin: 'drag_outside' });
      this.removeGuide();
    } else {
      this.sendDragBIEvent(this.props.guidePosition, newGuidePosition);
      this.preventClickAfterDragEnd = true;
      this.props.changeGuidePosition(
        this.props.rulerDirection,
        this.props.guideId,
        newGuidePosition,
      );
    }
  };

  getDragLimits = () => {
    return this.props.rulerDirection === 'horizontal'
      ? { lockedX: true }
      : { lockedY: true };
  };

  sendDragBIEvent = (oldPosition, newPosition) => {
    const BIParams = {
      xCoordinate_new: 0,
      xCoordinate_old: 0,
      yCoordinate_new: 0,
      yCoordinate_old: 0,
    };
    if (this.props.rulerDirection === 'vertical') {
      BIParams.xCoordinate_new = newPosition;
      BIParams.xCoordinate_old = oldPosition;
    } else {
      BIParams.yCoordinate_new = newPosition;
      BIParams.yCoordinate_old = oldPosition;
    }

    this.props.sendBIEvent('GUIDE_DRAGGED', BIParams);
  };

  closeHoverBubble = () => {
    this.setState({ isHoverBubble: false });
  };

  deselectGuide = () => {
    if (!this.props.isBeingDragged) {
      this.props.deselectGuide();
    }
  };

  onStepperValueChangeApproval = (value) => {
    if (!this.props.isGuideOutOfLimitsFunc(_.parseInt(value))) {
      this.props.changeGuidePosition(
        this.props.rulerDirection,
        this.props.guideId,
        _.parseInt(value),
      );
      this.setState({ stepperSymbol: approvalSymbolName });
      window.setTimeout(
        this.deselectGuide.bind(this),
        APPROVE_POSITION_TIMEOUT,
      );
    }
  };

  onStepperSteppingValueChange = (value) => {
    const intValue = _.parseInt(value);
    const isStepperValid = !this.props.isGuideOutOfLimitsFunc(intValue);
    const isValueChanged = this.props.guidePosition === value;
    this.sendStepperValueChangeBIEvent(value, isStepperValid, isValueChanged);
    this.setState({ stepperValueInvalid: !isStepperValid });
    if (!isStepperValid) {
      window.setTimeout(
        function () {
          this.setState({ stepperValueInvalid: false });
        }.bind(this),
        DISAPPROVE_POSITION_TIMEOUT,
      );
    } else {
      this.props.changeGuidePosition(
        this.props.rulerDirection,
        this.props.guideId,
        intValue,
      );
    }
  };

  sendStepperValueChangeBIEvent = (value, isValid, isValueChanged) => {
    let valid_value;
    if (isValueChanged) {
      valid_value = 'no_change';
    } else {
      valid_value = isValid ? 'accepted' : 'denied';
    }
    const BIData = {
      type: this.props.rulerDirection,
      value,
      valid_value,
    };
    this.props.sendBIEvent('VALUE_CHANGE_IN_GUIDE_TOOLTIP', BIData);
  };

  stepperSymbolClickFunc = () => {
    if (this.state.stepperSymbol === trashSymbolName) {
      this.props.sendBIEvent('GUIDE_DELETED', { origin: 'trash_icon' });
      this.removeGuide();
    }
  };

  stepperClick = () => {
    if (this.state.isHoverBubble) {
      this.setState({
        isHoverBubble: false,
        stepperValueInvalid: false,
        stepperSymbol: trashSymbolName,
        selectedDueToHoverBubbleClick: true,
      });
      this.setGuideSelected('click_on_tooltip');
    } else {
      this.setState({
        stepperValueInvalid: false,
        stepperSymbol: trashSymbolName,
      });
    }
  };

  getStepperWithSVGProps = () => {
    return {
      showSymbol: this.props.isSelected && !this.state.isFirstRender,
      stepperClickFunc: this.stepperClick,
      symbolClickFunc: this.stepperSymbolClickFunc,
      symbolName: this.state.stepperSymbol,
      stepperProps: {
        min: -99999,
        max: 99999,
        step: 1,
        selectedUnits: 'px',
        units: 'px',
        afterBlur: this.onStepperValueChangeApproval,
        isInvalidValue: this.state.stepperValueInvalid,
        valueLink: {
          value: this.state.guidePosition,
          requestChange: this.onStepperSteppingValueChange,
        },
        dynamicInputSize: {
          characterWidth: INPUT_IN_STEPPER_CHARACTER_SIZE,
          minWidth: INPUT_IN_STEPPER_MINIMUM_SIZE,
        },
        inputInFocus: this.state.selectedDueToHoverBubbleClick,
      },
    };
  };

  getOverridePositionAdjustments = (bubbleState) => {
    if (this.props.rulerDirection === 'horizontal') {
      return undefined;
    }
    return {
      type: constants.UI.TOOLTIP.ALIGNMENT.TOP,
      value:
        bubbleState === 'hover'
          ? BUBBLE_DISTANCE_FROM_TOP_HOVER
          : BUBBLE_DISTANCE_FROM_TOP_SELECTED,
    };
  };

  getBubbleMode = () => {
    if (this.props.isSelected && !this.state.isFirstRender) {
      return 'selected';
    }
    return this.state.isHoverBubble && !this.props.toolsHidden ? 'hover' : null;
  };

  getHoverBubbleProps = () => {
    return {
      closeTrigger: [],
      hideMethod: this.closeHoverBubble,
      timeoutForMouseOverBubble: HOVER_BUBBLE_DISAPPEARANCE_TIMEOUT,
      mouseLeftTargetNode:
        !this.props.isBeingDragged && !this.state.mouseOverGuide,
      className: 'hover-bubble',
    };
  };

  getSelectedBubbleProps = () => {
    return {
      closeTrigger: [constants.UI.TOOLTIP.TRIGGERS.OUTER_CLICK],
      hideMethod: this.deselectGuide,
      timeoutForMouseOverBubble: 0,
      mouseLeftTargetNode: null,
      className: 'selected-bubble',
    };
  };

  getBubbleProps = () => {
    const bubbleState = this.getBubbleMode();
    const bubbleProps =
      bubbleState === 'hover'
        ? this.getHoverBubbleProps()
        : this.getSelectedBubbleProps();
    const overridePositionAdjustments =
      this.getOverridePositionAdjustments(bubbleState);
    const marginsFromElement =
      this.props.rulerDirection === 'horizontal'
        ? MARGIN_FROM_GUIDE_CATCHER_HORIZONTAL
        : MARGIN_FROM_GUIDE_CATCHER_VERTICAL;
    const alignment =
      this.props.rulerDirection === 'horizontal'
        ? constants.UI.TOOLTIP.ALIGNMENT.LEFT
        : constants.UI.TOOLTIP.ALIGNMENT.RIGHT;

    return {
      value: {
        classPath: 'baseUI.stepperWithSVG',
        props: this.getStepperWithSVGProps(),
      },
      styleType: constants.UI.TOOLTIP.STYLE_TYPE.NORMAL,
      alignment,
      noArrow: true,
      targetNode: this.guideRef,
      closeTriggers: bubbleProps.closeTrigger,
      hideMethod: bubbleProps.hideMethod,
      marginsFromWindow: 0,
      mouseLeftTargetNode: bubbleProps.mouseLeftTargetNode,
      marginsFromElement,
      timeoutForMouseOverBubble: bubbleProps.timeoutForMouseOverBubble,
      overridePositionAdjustments,
      customClasses: [bubbleProps.className],
    };
  };

  onContainerMouseDown = (e) => {
    e.stopPropagation();
    if (!this.props.toolsHidden) {
      this.props.startDrag(e, this.getDragLimits());
    }
  };

  onMouseLeave = () => {
    this.setState({
      mouseOverGuide: false,
    });
  };

  activateHoverBubble = () => {
    if (this.state.mouseOverGuide) {
      this.setState({
        isHoverBubble: true,
        selectedDueToHoverBubbleClick: false,
      });
    }
  };

  onMouseEnter = () => {
    const shouldHoverBubbleAppear =
      !this.props.disableInteraction &&
      ((!this.props.isSelected && !this.props.toolsHidden) ||
        (this.props.isSelected && this.state.isFirstRender));

    if (shouldHoverBubbleAppear) {
      this.setState({ mouseOverGuide: true });
      window.setTimeout(
        this.activateHoverBubble,
        HOVER_BUBBLE_ACTIVATION_TIMEOUT,
      );
    }
  };

  handleGuideClick = (e) => {
    e.stopPropagation();
    if (this.props.disableInteraction || this.props.toolsHidden) {
      return;
    }
    if (this.state.isHoverBubble) {
      this.setState({
        isHoverBubble: false,
      });
    }
    if (this.preventClickAfterDragEnd) {
      this.preventClickAfterDragEnd = false;
      return;
    }
    this.setGuideSelected('click_on_guide');
  };

  handleRightClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.sendBi(coreBi.events.rightClickMenu.RIGHT_CLICK_MENU_OPEN, {
      component: 'ruler_scale',
    });
    this.setGuideSelected('right_click_on_guide');
    this.props.openRightClickMenu(
      e,
      this.props.ruler,
      'guide',
      this.props.guideId,
    );
  };

  setGuideRef = (ref) => {
    this.guideRef = ref;
  };

  setContainerRef = (ref) => {
    this.containerRef = ref;
  };

  render() {
    return (
      <div
        ref={this.setContainerRef}
        style={this.getGuideContainerStyle()}
        className={`${this.props.rulerDirection}-guide-container`}
      >
        <div
          style={this.getGuideStyle()}
          className={`${this.props.rulerDirection}-guide`}
        />

        <div
          ref={this.setGuideRef}
          onClick={this.handleGuideClick}
          onMouseDown={(e) => {
            this.onContainerMouseDown(e);
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onContextMenu={this.handleRightClick}
          className={`${this.props.rulerDirection}-guide-catcher`}
        >
          <div className={`${this.props.rulerDirection}-guide-handler`}>
            {this.shouldShowSelectedGuide() ? (
              <Symbols.symbol key="selected" name="guidesHandler_selected" />
            ) : null}
            {!this.shouldShowSelectedGuide() ? (
              <Symbols.symbol key="regular" name="guidesHandler_regular" />
            ) : null}
          </div>
        </div>

        {this.getBubbleMode() ? (
          <BaseUI.bubble
            ref="bubble"
            key="hoverBubble"
            {...this.getBubbleProps()}
          />
        ) : null}
      </div>
    );
  }
}

export default utils.hoc.draggable(Guide);

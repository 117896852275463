import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import _ from 'lodash';

const { isRepeaterItem } = stateManagement.components.selectors;

const { isInInteractionMode, isShownOnlyOnVariant } =
  stateManagement.interactions.selectors;

const GFPP_ACTIONS = constants.ROOT_COMPS.GFPP.ACTIONS;

const availableGfppItems = [
  GFPP_ACTIONS.INTERACTION_HIDE,
  GFPP_ACTIONS.DESIGN,
  GFPP_ACTIONS.TRANSITION,
  GFPP_ACTIONS.HELP,
];

const FORBIDDEN_INTERACTIONS_DESIGN_TYPES = {
  [constants.COMP_TYPES.SHAPE]: true,
  [constants.COMP_TYPES.STYLABLE_BUTTON]: false,
  [constants.COMP_TYPES.ICON_BUTTON]: true,
};

export default function (
  editorAPI: AnyFixMe,
  compRefs: AnyFixMe,
  compGfppData: AnyFixMe,
) {
  const currentEditorState = editorAPI.store.getState();
  const inInteractionMode = isInInteractionMode(currentEditorState);

  if (inInteractionMode) {
    if (!isShownOnlyOnVariant(editorAPI, compRefs[0])) {
      compGfppData.enabledActions = availableGfppItems;
      compGfppData.mainActions = [
        {
          label: () => 'gfpp_mainaction_interactions',
          automationId: 'gfpp-button-effects',
          onClick: () => {
            editorAPI.panelManager.openComponentPanel(
              'interactions.panels.effectsPanel',
              {},
            );
          },
        },
      ];
    }

    const compType = editorAPI.components.getType(compRefs);
    if (
      (FORBIDDEN_INTERACTIONS_DESIGN_TYPES[
        compType as keyof typeof FORBIDDEN_INTERACTIONS_DESIGN_TYPES
      ] &&
        !isShownOnlyOnVariant(editorAPI, compRefs[0])) ||
      isRepeaterItem(compRefs[0], editorAPI.dsRead)
    ) {
      compGfppData.enabledActions = _.without(
        compGfppData.enabledActions,
        'design',
      );
    }
  }
}

import _ from 'lodash';
import createReactClass from 'create-react-class';
import $ from 'zepto';
import { connectWithScope } from '#packages/apilib';
import * as util from '#packages/util';
import shortcutsConfig from './keyboard/config/shortcutsConfig';
import * as uiAnimations from '#packages/uiAnimations';
import PropTypes from 'prop-types';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import type { EditorAPI } from '#packages/editorAPI';
import { EditorView } from './editorView/editorView';
import * as i18n from '#packages/i18n';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { Scope } from './mainEditorScope';
import { htmlLoadingStepEditorLoaded } from '@wix/bi-logger-editor/v2';
import SiteCreationPagesView from '../rootComps/siteCreation/siteCreationPagesView';

const { getPreviewMode } = stateManagement.preview.selectors;
const { getShouldShowWizard } = stateManagement.siteCreation.selectors;
const { isInInteractionMode } = stateManagement.interactions.selectors;

const timerHandle = false;

util.biLogger.report(htmlLoadingStepEditorLoaded({}));

util.performance.mark('editor loaded');
util.performance.measure('main-r started', 'domLoading', 'main-r loaded');
util.performance.measure(
  'addPanelData loaded',
  'main-r loaded',
  'addPanelData loaded',
);
util.performance.measure(
  'editor loaded',
  'addPanelData loaded',
  'editor loaded',
);

interface EditorComponentOwnProps {}

interface EditorComponentStateProps {
  previewUrl: string;
  previewMode: boolean;
  editorIsInit: boolean;
  shouldShowSiteCreationWizard: boolean;
}

interface EditorComponentDispatchProps {
  editorAPI: EditorAPI;
  emitStageIsReadyAndVisible: () => void;
}

type EditorComponentProps = EditorComponentOwnProps &
  EditorComponentStateProps &
  EditorComponentDispatchProps;

// eslint-disable-next-line react/prefer-es6-class
export const EditorComponent = createReactClass<EditorComponentProps>({
  displayName: 'editor',
  // util.componentUpdatesMixin is used -> santa-editor/packages/debugTools/domSelectors
  mixins: [uiAnimations.animationManager, util.componentUpdatesMixin],
  propTypes: {
    previewMode: PropTypes.bool.isRequired,
    editorIsInit: PropTypes.bool.isRequired,
    previewUrl: PropTypes.string.isRequired,
    editorAPI: PropTypes.object.isRequired,
  },
  childContextTypes: {
    dsRead: PropTypes.object,
    editorAPI: PropTypes.object,
    mediaServices: PropTypes.object,
    bi: PropTypes.object,
    translateFromContext: PropTypes.func,
  },
  getChildContext() {
    return {
      editorAPI: this.props.editorAPI, // used in editorAPIMixin
      mediaServices: this.props.editorAPI.mediaServices, // used in mediaServicesMixin
      translateFromContext: i18n.translate, // used in wix-base-ui/hoc/withTranslation
    };
  },
  componentWillUnmount() {
    if (timerHandle) {
      util.animationFrameUtils.cancel(timerHandle);
    }
  },
  componentDidMount() {
    if (this.props.shouldShowSiteCreationWizard) {
      this.removePreLoader();
    } else {
      this.setHistoryAndPopstateIfNeeded();
    }
    if (window.testApi?.domSelectors) {
      window.testApi.domSelectors.setSearchRoot(this);
    }

    shortcutsConfig.init(this.props.editorAPI);

    this.updateKeyboardShortcutContext();
  },
  shouldComponentUpdate(nextProps) {
    const editorState = nextProps.editorAPI.store.getState();
    return !editorState.mouseActions.dragInProgress;
  },
  removePreLoader() {
    window.initialLoader?.unmount();
  },
  setHistoryAndPopstateIfNeeded() {
    if (window.history) {
      window.history.replaceState({ preview: false }, 'edit mode');
      window.onpopstate = this.onHistoryPopState;
    }
  },
  componentDidUpdate(prevProps) {
    if (!prevProps.editorIsInit && this.props.editorIsInit) {
      this.removePreLoader();

      this.props.emitStageIsReadyAndVisible();
    }
    if (prevProps.previewMode !== this.props.previewMode) {
      if (
        window.history.state &&
        this.props.previewMode &&
        !window.history.state.preview
      ) {
        this.onEnterPreview();
      } else if (
        window.history.state &&
        !this.props.previewMode &&
        window.history.state.preview
      ) {
        this.onExitPreview();
      }
      this.updateKeyboardShortcutContext();
      $(window.document.body).toggleClass(
        'previewMode',
        this.props.previewMode,
      );
    }
    if (
      prevProps.shouldShowSiteCreationWizard &&
      !this.props.shouldShowSiteCreationWizard
    ) {
      this.setHistoryAndPopstateIfNeeded();
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    _(this.renderCallbacks)
      .filter(_.isFunction)
      .forEach(function (listener) {
        listener();
      });
  },
  componentDidCatch(error, { componentStack = '' }) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/split
    const throwingComponent = _(componentStack)
      .split('in ')
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/split
      .remove((v) => _.split(v.trim(), '').length > 2 && v !== 'div ')
      .value()[0]; //https://github.com/reactjs/reactjs.org/issues/703
    ErrorReporter.captureException(error, {
      tags: { whiteScreenOfDeath: true, throwingComponent },
      extra: { componentStack },
    });
  },
  onExitPreview() {
    window.history.replaceState({ preview: false }, 'edit mode');
  },
  renderSiteCreationPagesView() {
    return this.props.shouldShowSiteCreationWizard ? (
      <SiteCreationPagesView />
    ) : null;
  },
  onEnterPreview() {
    window.history.pushState({ preview: true }, 'site preview');
  },
  onHistoryPopState(state: AnyFixMe) {
    if (!state.state) {
      return;
    }
    const enterPreviewFromBrowserHistory =
      state.state.preview && !this.props.previewMode;
    const exitPreviewFromBrowserHistory =
      !state.state.preview && this.props.previewMode;
    if (enterPreviewFromBrowserHistory || exitPreviewFromBrowserHistory) {
      this.props.editorAPI.preview.togglePreview();
    }
  },
  updateKeyboardShortcutContext() {
    const contexts = util.keyboardShortcuts.CONTEXTS;

    if (isInInteractionMode(this.props.editorAPI.store.getState())) {
      util.keyboardShortcuts.setContext(contexts.INTERACTIONS);
      return;
    }
    util.keyboardShortcuts.setContext(
      this.props.previewMode ? contexts.PREVIEW : contexts.EDITOR,
    );
  },
  render() {
    return (
      <>
        {this.renderSiteCreationPagesView()}
        <EditorView />
      </>
    );
  },
});

export const ConnectedEditorComponent = connectWithScope(
  () => Scope,
  EditorComponent,
  (scope) => {
    const { editorAPI } = scope;
    const editorState = editorAPI.store.getState();
    const previewUrl = window.__previewFrameData.frameUrl;
    const shouldShowSiteCreationWizard = getShouldShowWizard(editorState);

    return {
      previewUrl,
      shouldShowSiteCreationWizard,
      editorIsInit: editorState.editorIsInit,
      previewMode: getPreviewMode(editorState),
    };
  },
  (scope) => {
    const { editorAPI, editorCoreApi } = scope;
    return {
      editorAPI,
      emitStageIsReadyAndVisible: () =>
        editorCoreApi.hooks.stageIsReadyAndVisible.resolve(),
    };
  },
);

import React, { PureComponent } from 'react';

import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';

import type { SendBiFunction } from 'types/bi';

export interface DealerViewerProps {
  bannerPosition: unknown;
  metasiteGuid?: string;
  signedInstance?: string;
  translationsUrl?: string;
  language?: string;
  onDisplay: () => void;
  onError: (eventParams: unknown) => void;
}

interface DealerUpgradePanelProps {
  isFirstSave: boolean;
  isDraftMode: boolean;
  dealerViewer: React.ComponentType<DealerViewerProps>;
  fallbackToDefaultUpgradePanel: (errorType: string) => void;
  sendBi: SendBiFunction;
  metaSiteId: string;
  metaSiteInstance: string;
  recalcPositions?: () => void;
}

class DealerUpgradePanel extends PureComponent<DealerUpgradePanelProps> {
  componentDidMount() {
    this.props.sendBi(coreBi.events.topbar.upgrade_topbar_open, {
      type: 'dealer',
    });

    if (!this.dealerLoadSuccess) {
      this.renderTimeout = util.dealerUtils.createDealerRenderSuccessTimeout(
        this.props.fallbackToDefaultUpgradePanel,
      );
    }
  }

  componentWillUnmount() {
    util.dealerUtils.clearDealerRenderSuccessTimeout(this.renderTimeout);
  }

  private dealerLoadSuccess: boolean = false;
  private renderTimeout: number;

  onDealerLoadSuccess = () => {
    this.props.recalcPositions?.();

    util.dealerUtils.clearDealerRenderSuccessTimeout(this.renderTimeout);

    this.props.sendBi(coreBi.events.dealer.dealer_upgrade_topbar_on_view);

    this.dealerLoadSuccess = true;
  };

  getDealerViewerProps = (): Partial<DealerViewerProps> =>
    Object.assign({}, util.dealerUtils.getDealerViewerBaseProps(), {
      metasiteGuid: this.props.metaSiteId,
      signedInstance: this.props.metaSiteInstance,
    });

  onDealerError = (eventParams: AnyFixMe) =>
    this.props.fallbackToDefaultUpgradePanel(eventParams?.errorType ?? '');

  render() {
    const { dealerViewer: DealerViewer } = this.props;
    const bannerPosition =
      this.props.isFirstSave || this.props.isDraftMode
        ? util.dealerUtils.bannerPositions.SITE_NOT_SAVED
        : util.dealerUtils.bannerPositions.SITE_SAVED;

    return (
      <DealerViewer
        key="dealerViewer"
        bannerPosition={bannerPosition}
        onDisplay={this.onDealerLoadSuccess}
        onError={this.onDealerError}
        {...this.getDealerViewerProps()}
      />
    );
  }
}

export default DealerUpgradePanel;

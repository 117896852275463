import { selectWixAppsCollections } from '../../collections';
import type {
  Dispatch,
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import { bi } from '#packages/stateManagement';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { Schema } from '@wix/wix-data-schema-types';

import { contentManagerButtonOrigin, EVENTS } from '../../consts';
import { notifyDataBindingApplication } from '../../notifyApplication';
import type { CollectionsListOwnProps } from '../collectionsList/collectionsList.mapper';
import { cmsAdvancedSettingsClicked } from '../../bi';

export interface WixAppsCollectionsListOwnProps
  extends CollectionsListOwnProps {}

export interface WixAppsCollectionsListStateProps {
  collections: Schema[];
}

export const mapStateToProps: MapStateToProps<
  WixAppsCollectionsListStateProps
> = ({ state }) => ({
  collections: selectWixAppsCollections(state),
});

export interface WixAppsCollectionsListDispatchProps {
  openContentManagerAdvancedSettings: () => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  WixAppsCollectionsListDispatchProps,
  WixAppsCollectionsListOwnProps
> = (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  const sendBi = (event: BiEventDefinition, params: BiEventFields) =>
    dispatch(bi.actions.event(event, params));

  return {
    openContentManagerAdvancedSettings: () => {
      sendBi(cmsAdvancedSettingsClicked, {
        origin: 'wix_app_empty_state',
        environment: 'EDITOR',
      });
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openContentManagerAdvancedSettings,
        eventPayload: { origin: contentManagerButtonOrigin },
      });
    },
  };
};

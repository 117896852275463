// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';

const { selectors, actions } = stateManagement.userPreferences;

const getUserPreferences = (state, type, key) => {
  switch (type) {
    case 'site':
      return selectors.getSiteUserPreferences(key)(state);
    case 'session':
      return selectors.getSessionUserPreferences(key)(state);
    case 'global':
      return selectors.getGlobalUserPreferences(key)(state);
  }
};

const setUserPreferences = (dispatch, type, key) => (value, appId) => {
  switch (type) {
    case 'site':
      return dispatch(actions.setSiteUserPreferences(key, value, appId));
    case 'session':
      return dispatch(actions.setSessionUserPreferences(key, value));
    case 'global':
      return dispatch(actions.setGlobalUserPreferences(key, value));
  }
};

const withUserPreferences = (userPreferencesDefs) => {
  const mapStateToProps = ({ state }) =>
    _.fromPairs(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(userPreferencesDefs, ({ type, key, propName }) => [
        propName,
        getUserPreferences(state, type, key),
      ]),
    );

  const mapDispatchToProps = (dispatch) =>
    _.fromPairs(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(userPreferencesDefs, ({ type, key, updatePropName }) => [
        updatePropName,
        setUserPreferences(dispatch, type, key),
      ]),
    );

  return util.hoc.connect(
    util.hoc.STORES.STATE_ONLY,
    mapStateToProps,
    mapDispatchToProps,
  );
};

export default withUserPreferences;

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { DsItem } from '@wix/document-services-types';
import type {
  WidgetPointer,
  WidgetSlot,
} from '@wix/editor-platform-host-integration/ui';

import {
  provisionPluginAppIfNeeded,
  createWidgetPluginComponentDefinition,
  getPluginInfo,
  isInViewport,
} from './widgetPluginsUtils';
import type { Origin } from '../api/document/tpa/widgetPlugins';

export const virtualSlotPlaceholderSeparator = '_vs_';

interface OOIWidgetSlot {
  displayName: string;
  name: string;
  interfaces: string[];
}

export function isVirtualSlotsPlaceholder(
  slotsPlaceholderCompRef: CompRef,
): boolean {
  return slotsPlaceholderCompRef.id.includes(virtualSlotPlaceholderSeparator);
}

export default (editorAPI: EditorAPI) => {
  function getSlotsFromAppManifest(widgetCompRef: CompRef): OOIWidgetSlot[] {
    const { appDefinitionId, widgetId } =
      editorAPI.components.data.get(widgetCompRef);

    if (!appDefinitionId) {
      return [];
    }

    const appManifest =
      editorAPI.dsRead.platform.getAppManifest(appDefinitionId);

    if (!appManifest) {
      return [];
    }

    const slotsMap: Record<
      string,
      Pick<OOIWidgetSlot, 'displayName' | 'interfaces'>
    > =
      // @ts-expect-error
      appManifest.controllersStageData?.[widgetId]?.default?.slots ?? {};

    return Object.entries(slotsMap).map(([slotName, slotConfig]) => ({
      name: slotName,
      ...slotConfig,
    }));
  }

  function hasWidgetSlot(widgetCompRef: CompRef): boolean {
    return getSlotsFromAppManifest(widgetCompRef).length > 0;
  }

  function getVirtualSlotsPlaceholderCompRef(
    widgetCompRef: CompRef,
    slotName: string,
  ): CompRef {
    return {
      id: `${widgetCompRef.id}${virtualSlotPlaceholderSeparator}${slotName}`,
      type: widgetCompRef.type,
    };
  }

  function getWidgetSlotData(
    hostRef: CompRef,
    slot: OOIWidgetSlot,
  ): WidgetSlot {
    const slotId = slot.name;
    const { widgetId, appDefinitionId } =
      editorAPI.components.data.get(hostRef);
    const slotComponentData: DsItem | undefined = editorAPI.components.data.get(
      editorAPI.documentServices.components.slots.getSlotsData(hostRef)[slotId],
    );
    return {
      compRef: getVirtualSlotsPlaceholderCompRef(hostRef, slotId),
      role: slotId,
      interfaces: slot.interfaces,
      pluginInfo: getPluginInfo(editorAPI, slotComponentData),
      placement: {
        appDefinitionId,
        widgetId,
        slotId,
      },
    };
  }

  function getWidgetSlots(widgetCompRef: CompRef) {
    const slots = getSlotsFromAppManifest(widgetCompRef);
    return slots.map((slot) => getWidgetSlotData(widgetCompRef, slot));
  }

  function getWidgetSlot(virtualSlotsPlaceholderRef: CompRef) {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotsPlaceholderRef,
    );
    const slots = getSlotsFromAppManifest(widgetHostRef);
    return getWidgetSlotData(
      widgetHostRef,
      slots.find((s) => s.name === slotName),
    );
  }

  function getVirtualSlotPlaceholderData(compRef: CompRef): {
    widgetHostRef: CompRef;
    slotName: string;
  } {
    const [hostId, slotName] = compRef.id.split(
      virtualSlotPlaceholderSeparator,
    );

    return {
      widgetHostRef: {
        id: hostId,
        type: compRef.type,
      },
      slotName,
    };
  }

  function getEditorPreviwFrame(): HTMLIFrameElement {
    return (document.getElementById('preview') || // santa-editor
      document.getElementsByName('preview-frame')[0]) as HTMLIFrameElement; // responsive and studio
  }

  function getVirtualSlotsPlaceholderPosition(
    widgetHostRef: CompRef,
    slotName: string,
  ) {
    const virtualSlotPlaceholderID = `${widgetHostRef.id}.${slotName}`;
    const previewFrame = getEditorPreviwFrame();
    const virtualSlotPlaceholderElement =
      previewFrame.contentWindow.document.getElementById(
        virtualSlotPlaceholderID,
      );

    if (!virtualSlotPlaceholderElement) {
      return null;
    }

    return virtualSlotPlaceholderElement.getBoundingClientRect();
  }

  function scrollToSlot(virtualSlotPlaceholderRef: CompRef) {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    const slotPlaceholderPosition = getVirtualSlotsPlaceholderPosition(
      widgetHostRef,
      slotName,
    );

    if (!slotPlaceholderPosition) {
      return;
    }

    if (isInViewport(editorAPI, slotPlaceholderPosition)) {
      return;
    }

    const positionOffset = 30;

    editorAPI.ui.scroll.animateScroll(
      {
        y: slotPlaceholderPosition.top - positionOffset,
      },
      0.8,
    );
  }

  async function highlightSlot(virtualSlotPlaceholderRef: CompRef) {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    editorAPI.documentMode.setComponentPreviewState(
      widgetHostRef.id,
      `${slotName}-highlighted`,
    );
    await editorAPI.waitForChangesAppliedAsync();

    scrollToSlot(virtualSlotPlaceholderRef);
  }

  function unhighlightSlot(virtualSlotPlaceholderRef: CompRef) {
    const { widgetHostRef } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    editorAPI.documentMode.setComponentPreviewState(widgetHostRef.id, null);
  }

  function selectSlot(virtualSlotPlaceholderRef: CompRef): void {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    const contentRef =
      editorAPI.dsRead.components.slots.getSlotsData(widgetHostRef)[slotName];

    if (!contentRef) {
      return undefined;
    }

    editorAPI.selection.selectComponentByCompRef(contentRef);
  }

  async function installWidgetPlugin(
    virtualSlotPlaceholderRef: CompRef,
    widgetPointer: WidgetPointer,
    origin: Origin,
  ): Promise<CompRef> {
    const { applicationId } = await provisionPluginAppIfNeeded(
      editorAPI,
      widgetPointer,
      origin,
    );

    const widgetComponent = await createWidgetPluginComponentDefinition(
      editorAPI,
      widgetPointer,
      applicationId,
    );

    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    const compRef: CompRef = editorAPI.dsActions.components.slots.populate(
      widgetHostRef,
      slotName,
      widgetComponent,
    );

    return compRef;
  }

  function uninstallWidgetPlugin(virtualSlotPlaceholderRef: CompRef) {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    editorAPI.dsActions.components.slots.remove(widgetHostRef, slotName);
  }

  function getWidgetPointer(
    virtualSlotPlaceholderRef: CompRef,
  ): WidgetPointer | undefined {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    const contentRef =
      editorAPI.dsRead.components.slots.getSlotsData(widgetHostRef)[slotName];

    if (!contentRef) {
      return undefined;
    }

    const { appDefinitionId, widgetId } =
      editorAPI.dsRead.components.data.get(contentRef);

    return { appDefinitionId, widgetId };
  }

  function getWidgetHostRef(virtualSlotPlaceholderRef: CompRef): CompRef {
    const { widgetHostRef } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    return widgetHostRef;
  }

  function isWidgetSlotPopulated(virtualSlotPlaceholderRef: CompRef) {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    return !!editorAPI.dsRead.components.slots.getSlotsData(widgetHostRef)?.[
      slotName
    ];
  }

  function isWidgetPluginComponent(compRef: CompRef): boolean {
    const parentRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);

    // for OOI widgets widget plugins are hosted directly by TPA Widget
    const compType = editorAPI.components.getType(parentRef);
    return (
      compType === 'wysiwyg.viewer.components.tpapps.TPAWidget' ||
      compType === 'wysiwyg.viewer.components.tpapps.TPAMultiSection'
    );
  }

  function getSlotPlaceholderRefByContentRef(
    widgetPluginCompRef: CompRef,
  ): CompRef | undefined {
    const hostWidgetRef =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(
        widgetPluginCompRef,
      );

    const hostSlots = editorAPI.components.slots.getSlotsData(hostWidgetRef);

    const [pluginSlotName] =
      Object.entries(hostSlots).find(
        ([, slotContentRef]) => slotContentRef.id === widgetPluginCompRef.id,
      ) ?? [];

    if (!pluginSlotName) {
      return undefined;
    }

    return getVirtualSlotsPlaceholderCompRef(hostWidgetRef, pluginSlotName);
  }

  function getSlotPlaceholderDisplayName(
    virtualSlotPlaceholderRef: CompRef,
  ): string {
    const { widgetHostRef, slotName } = getVirtualSlotPlaceholderData(
      virtualSlotPlaceholderRef,
    );

    const slotsPlaceholders = getSlotsFromAppManifest(widgetHostRef);

    const slotPlaceholder = slotsPlaceholders.find((s) => s.name === slotName);

    return slotPlaceholder?.displayName;
  }

  return {
    hasWidgetSlot,
    getWidgetSlots,
    getWidgetSlot,
    highlightSlot,
    unhighlightSlot,
    selectSlot,
    installWidgetPlugin,
    uninstallWidgetPlugin,
    getWidgetPointer,
    getWidgetHostRef,
    isWidgetSlotPopulated,
    isWidgetPluginComponent,
    getSlotPlaceholderRefByContentRef,
    getSlotPlaceholderDisplayName,
    scrollToSlot,
  };
};

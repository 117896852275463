import * as util from '#packages/util';

export default {
  helpData: {
    viewA: {
      helpCenterPopUp: { isHidden: true },
      wixVideos: {
        link: 'https://www.wix.com/learn/courses/web-design/get-started-with-the-content-manager',
        image: util.media.getMediaUrl('superAppMenuInfra/ContentManager.png'),
      },
      supportForumsPopUp: {
        isHidden: true,
      },
      wixTipsBlog: {
        isHidden: true,
      },
      helpCenterTab: {
        link: 'https://support.wix.com/en/content-manager/content-collections ',
        image: util.media.getMediaUrl('superAppMenuInfra/HelpCenter.png'),
      },
    },
  },
};

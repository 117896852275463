import constants from '#packages/constants';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import { panels, bi } from '#packages/stateManagement';
import { addPanel } from '#packages/util';
import * as coreBi from '#packages/coreBi';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { ThunkAction, StateMapperArgs, Dispatch } from 'types/redux';
import type { PopupLinkComponentOwnProps } from './PopupLink';

const LIGHTBOX_COMPONENT_TYPE = 'wysiwyg.viewer.components.PopupContainer';

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

interface OpenAddPanelPopupSectionProps {
  onComponentAddedToStage(compRef: CompRef): void;
}

const openAddPanelPopupSection = (props: OpenAddPanelPopupSectionProps) =>
  panels.actions.updateOrOpenPanel(
    constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
    {
      category: addPanelDataConsts.CATEGORIES_ID.BOX_SLIDE_SHOW,
      origin: 'linkPanelPopupLink',
      [addPanel.isNewPanelEnabled() ? 'sectionId' : 'section']:
        'popupWelcomeSection',
      ...props,
    },
  );

export const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  const popups = editorAPI.pages.popupPages.getDataListOrdered();
  return {
    popups,
    focusedPageId: editorAPI.dsRead.pages.getFocusedPageId(),
    isMobileEditor: editorAPI.isMobileEditor(),
  };
};

export const mapDispatchToProps = (
  dispatch: Dispatch,
  _ownProps: PopupLinkComponentOwnProps,
) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const checkIsPagePopup = (pageId: string) =>
    editorAPI.dsRead.pages.popupPages.isPopup(pageId);

  const createDefaultData = (type: string = 'PageLink') =>
    editorAPI.dsRead.data.createItem(type);

  const getPopupPageIdByRef = (compRef: CompRef) => {
    return editorAPI.components.getType(compRef) === LIGHTBOX_COMPONENT_TYPE
      ? editorAPI.components.getPage(compRef).id
      : null;
  };

  const sendLightboxAddedBiEvent = (compRef: CompRef) =>
    bi.actions.event(
      coreBi.events.linkPanel.LINK_PANEL_LIGHTBOX_ADDED_TO_STAGE,
      {
        origin: 'link_panel',
        component_type: editorAPI.components.getType(compRef),
        component_id: compRef.id,
        page_id: editorAPI.pages.getFocusedPage()?.id,
        target_component: editorAPI.components.getType(
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef),
        ),
      },
    );

  const onComponentAdded = (compRef: CompRef) => {
    const popupPageId = getPopupPageIdByRef(compRef);

    if (!popupPageId) {
      return;
    }

    dispatch(sendLightboxAddedBiEvent(compRef));
  };

  return {
    openAddPanelPopupSection() {
      dispatch(
        openAddPanelPopupSection({ onComponentAddedToStage: onComponentAdded }),
      );
    },
    openHelpCenter: (helpId: string, props?: object, biHelpParams?: any) => {
      dispatch(panels.actions.openHelpCenter(helpId, props, biHelpParams));
    },

    checkIsPagePopup,

    createDefaultData,
  };
};

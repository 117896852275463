import type { EditorAPI } from '#packages/editorAPI';
import type { layoutTransitionsUtil } from '#packages/layoutUtils';
import type { CompLayout, CompRef } from 'types/documentServices';
import type { OptimisticDragHandler } from './optimisticDragHandlerThunderbolt';
import type { MousePosition } from '../../types';

export interface DragMoveToScope {
  editorAPI: EditorAPI;
  selectedComp: CompRef[];
  isHorizontallyMovable: boolean;
  isVerticallyMovable: boolean;
  considerParentLayoutChange: boolean[];
  initMousePosition: MousePosition;
  dragTransition: layoutTransitionsUtil.Drag;
  initialCompLayout: CompLayout;
  optimisticDragHandler: OptimisticDragHandler;
  initCompLayoutsRelativeToScreen: CompLayout[];
  initCompLayouts: CompLayout[];
  meshMoveByTransition: ReturnType<
    EditorAPI['components']['layout']['__mesh']['moveByTransition']
  >;
}

export interface DragMoveToInterceptionScope {
  editorAPI: EditorAPI;
  compRefs: CompRef[];
  mousePositionInitial: MousePosition;
}

export interface DragMoveToInterceptionResult {
  isHandled: boolean;
}

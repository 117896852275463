import _ from 'lodash';
import { LEGACY_WIX_APPS_NAMES } from '#packages/constants';

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/values
export const LEGACY_WIX_APPS_IDS = _.values(LEGACY_WIX_APPS_NAMES);

export enum APP_DEF_IDS {
  WIX_RESTAURANTS_MENUS = '13c1402c-27f2-d4ab-7463-ee7c89e07578',
  WIX_FAQ = '14c92d28-031e-7910-c9a8-a670011e062d',
}

export enum AUTOMATION_IDS {
  CTA = 'wix-apps-deprecation-panel-cta',
  SAVE_DATA_CTA = 'wix-apps-deprecation-panel-save-data-cta',
  LEARN_MORE = 'wix-apps-deprecation-panel-learn-more',
  PANEL_FRAME = 'wix-apps-deprecation-panel-frame',
}

const NEWS_TRANSLATIONS = {
  PANEL_TITLE: 'news_settings_panel_migration_warning_header',
  TITLE: 'news_settings_panel_migration_warning_title',
  TEXT: 'news_settings_panel_migration_warning_text_1',
  TEXT_2: 'news_settings_panel_migration_warning_text_2',
  LEARN_MORE: 'news_settings_panel_migration_warning_link',
  CTA: 'news_settings_panel_migration_warning_CTA',
};

const FAQ_TRANSLATIONS = {
  PANEL_TITLE: 'FAQ_settings_panel_migration_warning_header',
  TITLE: 'FAQ_settings_panel_migration_warning_title',
  TEXT: 'FAQ_settings_panel_migration_warning_text_1',
  TEXT_2: 'FAQ_settings_panel_migration_warning_text_2',
  LEARN_MORE: 'FAQ_settings_panel_migration_warning_link',
  CTA: 'FAQ_settings_panel_migration_warning_CTA',
};

const RESTAURANTS_MENUS_TRANSLATIONS = {
  PANEL_TITLE: 'menus_settings_panel_migration_warning_header',
  TITLE: 'menus_settings_panel_migration_warning_title',
  TEXT: 'menus_settings_panel_migration_warning_text_1',
  TEXT_2: 'menus_settings_panel_migration_warning_text_2',
  LEARN_MORE: 'menus_settings_panel_migration_warning_link',
  CTA: 'menus_settings_panel_migration_warning_CTA_export',
};

export const TRANSLATIONS_PER_APP_NAME = {
  [LEGACY_WIX_APPS_NAMES.FAQ]: FAQ_TRANSLATIONS,
  [LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS]: RESTAURANTS_MENUS_TRANSLATIONS,
  [LEGACY_WIX_APPS_NAMES.NEWS_A]: NEWS_TRANSLATIONS,
  [LEGACY_WIX_APPS_NAMES.NEWS_B]: NEWS_TRANSLATIONS,
};

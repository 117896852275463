// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import type { EditorAPI } from '#packages/editorAPI';
import * as superApps from '../../superApps/superApps';
import superAppsConstants from '../../superApps/superAppsConstants';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';
import * as addPanelAddCompService from '../addPanelAddCompService';
import navigateAndOpenAppService from '../../services/navigateAndOpenAppService';
import experiment from 'experiment';
import { getChatButtonSection } from './chatButtonSection';

import { openAppMarketTab } from '../../appMarket/services/appMarketTabService';

const appDefinitionId = superAppsConstants.WIX_CHAT.APP_DEF_ID;
const widgetId = superAppsConstants.WIX_CHAT.WIDGET;
const widthAndHeight = {
  width: 350,
  height: 700,
};

const getCategory = () => 'other';

const getSectionNumber = () =>
  addPanelDataConsts.SECTION_NUMBERS.CHAT[getCategory()];

const CONTACT_SECTION_INDEX_IN_GROUP = 0;

export const registerSection = function (editorAPI) {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.CONTACT,
    2,
    CONTACT_SECTION_INDEX_IN_GROUP,
    _.partial(getContactSection, editorAPI),
  );
};

const install = async function (editorAPI, installCb) {
  if (!superApps.isInstalled(appDefinitionId)) {
    return installCb();
  }
  navigateAndOpenAppService.openSettingsPanel(editorAPI, appDefinitionId);
};

export const addApp = function (
  compStructure,
  sectionTitle,
  tags,
  id,
  editorAPI,
  biOptions,
  addOptions,
) {
  return install(editorAPI, () =>
    addPanelAddCompService.addCompOnClick(
      editorAPI,
      appDefinitionId,
      widgetId,
      compStructure,
      tags,
      id,
      biOptions,
      addOptions,
    ),
  );
};

export const onDrop = function (layoutParams, compPreset) {
  const layout = _.merge({}, widthAndHeight, {
    x: layoutParams.x,
    y: layoutParams.y,
  });
  install(this.editorAPI, () =>
    addPanelAddCompService.addCompOnDrop(
      this.editorAPI,
      appDefinitionId,
      widgetId,
      layout,
      compPreset,
    ),
  );
};

const LANG = 'en';

const getContactSection = (editorAPI) => {
  if (superApps.isInstalled(appDefinitionId)) {
    return getChatButtonSection(editorAPI);
  }

  return getChatSection(editorAPI);
};

export const getLiveChatSectionColors = (editorAPI: EditorAPI) => {
  const palette = editorAPI.dsRead.theme.colors.getAll();
  const barColor = palette.color_18;
  const bubbleColor = /^#FFFFFF$/i.test(palette.color_15)
    ? palette.color_11
    : palette.color_15;
  return { barColor, bubbleColor };
};

const getChatSection = function (editorAPI) {
  const newDesign = experiment.isOpen('specs.chat.WidgetRedesignPhase1');
  const barWidgetId = 'bar';
  const bubbleWidgetId = 'bubble';

  const { barColor, bubbleColor } = getLiveChatSectionColors(editorAPI);

  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_livechat',
    subNavigationTitle: 'add_section_label_livechat',
    presetTitle: 'add_section_label_livechat',
    tooltipTitle: 'add_section_label_livechat',
    sectionName: 'liveChatSection',
    subNavigationHide: false,
    sectionNumber: getSectionNumber(),
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      iconEnabledForComps: {},
    },
    showSectionHeader: true,
    props: {
      onClick: addApp,
      onDrop: onDrop.bind({ editorAPI }),
      image: `addPanelData/sections/liveChatSection_${LANG}/liveChatSection_${LANG}.${
        newDesign ? 'v7' : 'v2'
      }.png`,
      imageStyle: {
        background: `linear-gradient(to left, ${barColor} 0%, ${barColor} 75%, ${bubbleColor} 50%, ${bubbleColor} 100%)`,
        height: '100%',
        width: '100%',
      },
      items: [
        {
          id: bubbleWidgetId,
          structure: {
            layout: {
              x: 315,
              y: 650,
              width: 60,
              height: 60,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            appDefinitionId,
            slug: 'wix-live-chat',
            data: {
              widgetId,
              applicationId: 'applicationId',
              type: 'TPAWidget',
              metaData: {
                isHidden: false,
                isPreset: true,
                schemaVersion: '1.0',
              },
            },
            style: {
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                appDefinitionId,
                bubbleWidgetId,
              ),
              type: 'TopLevelStyle',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
          },
          preset: { rect: { width: 81, height: 110, x: 0, y: 0 }, label: '' },
        },
        {
          id: barWidgetId,
          structure: {
            layout: {
              x: 315,
              y: 650,
              width: 336,
              height: 54,
              scale: 1,
              rotationInDegrees: 0,
            },
            type: 'Component',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
            appDefinitionId,
            slug: 'wix-live-chat',
            data: {
              widgetId,
              applicationId: 'applicationId',
              type: 'TPAWidget',
              metaData: {
                isHidden: false,
                isPreset: true,
                schemaVersion: '1.0',
              },
            },
            style: {
              componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
              pageId: '',
              compId: '',
              styleType: 'custom',
              style: superAppsCustomData.getSuperAppsAddPanelStyles(
                appDefinitionId,
                barWidgetId,
              ),
              type: 'TopLevelStyle',
              skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
            },
          },
          preset: { rect: { width: 243, height: 110, x: 81, y: 0 }, label: '' },
        },
      ],
    },
    help: {
      hide: false,
      text: 'add_section_info_text_livechat',
    },
    teaser: {
      id: 'livechat_section_teaser', // TODO - what is teaser id
      hide: false,
      title: 'add_section_teaser_title_livechat',
      text: 'add_section_teaser_text_livechat',
      linkText: 'add_category_teaser_link',
      learnMore() {
        const appMarketPanelProps = {
          urlParams: {
            slug: appDefinitionId,
          },
        };

        openAppMarketTab(appMarketPanelProps, editorAPI.panelManager.openPanel);
      },
    },
  };
};

import React from 'react';
import { Feed } from '@wix/discovery-client';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import { frames } from '#packages/panels';
import { translate } from '#packages/i18n';

import type { MapStateToProps, MapDispatchToProps } from 'types/redux';

import styles from './newReleasesFeed.scss';

const { RightPanelFrame } = frames;

interface StateProps {
  metaSiteId: string;
  editorSessionId: string;
  metaSiteInstance: string;
  language: string;
}

interface DispatchProps {
  closeFeed: () => void;
}

interface Props extends StateProps, DispatchProps {}

const NewReleasesFeed: React.FunctionComponent<Props> = (props: Props) => (
  <RightPanelFrame panelName="rEditor.NewReleasesFeedPanel">
    <div className={styles.container}>
      <Feed
        origin="editor"
        metaSiteId={props.metaSiteId}
        metaSiteInstance={props.metaSiteInstance}
        editorSessionId={props.editorSessionId}
        language={props.language}
        subjectNames={constants.NEW_RELEASES_SUBJECT_NAMES}
        onClose={props.closeFeed}
        title={translate('Editor_New_Releases_Feed_Title')}
        footerTitle={translate('Editor_New_Releases_Feed_Footer_Text')}
        footerLink={translate(
          'Editor_New_Releases_Feed_See_All_Updates_Button',
        )}
      />
    </div>
  </RightPanelFrame>
);

const mapStateToProps: MapStateToProps<StateProps> = ({ editorAPI }) => ({
  metaSiteId:
    editorAPI.dsRead?.generalInfo.getMetaSiteId() ||
    util?.editorModel?.metaSiteId,
  metaSiteInstance: editorAPI.dsRead?.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  ).instance,
  language: editorAPI.dsRead?.generalInfo.getLanguage(),
  editorSessionId: util.editorSessionUtil.editorSessionId,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, {}> = (
  dispatch,
) => ({
  closeFeed: () =>
    dispatch(
      stateManagement.panels.actions.closePanelByName(
        'rEditor.NewReleasesFeedPanel',
      ),
    ),
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(NewReleasesFeed);

// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const title = 'add_section_label_orderforms';
const presetTitle = 'add_section_label_orderforms_preset';
const tooltipTitle = 'add_section_label_orderforms_tooltip_title';
const subNavigationTitle = 'add_section_label_orderforms_sidebar';

const getPaymentSection = (editorAPI) => ({
  paymentSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title,
    topTitle: title,
    tooltipTitle,
    automationId: 'add-panel-section-paymentSection',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    showSectionHeader: true,
    subNavigationTitle,
    subNavigationHide: false,
    presetTitle,
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsPaymentSection_en/formsPaymentSection_en.v2.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Payment_Forms_1',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.PAYMENT05,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.PAYMENT05,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 862,
              height: 566,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 324, height: 210, x: 0, y: 0 },
            tags: null,
          },
        },
        {
          id: 'Payment_Forms_2',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.PAYMENT07,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.PAYMENT07,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 382,
              height: 812,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 304, x: 0, y: 210 },
            tags: null,
          },
        },
        {
          id: 'Payment_Forms_3',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.PAYMENT06,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.PAYMENT06,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 360,
              height: 732,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 304, x: 162, y: 210 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: 'add_section_label_paymentforms_tooltip_description',
    },
  },
});

export default (editorAPI) => getPaymentSection(editorAPI);

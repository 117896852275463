import React, { type CSSProperties } from 'react';
import { hoc } from '#packages/util';
import { createStructuredSelector } from '../selectors';
import {
  layoutRotationButtonStyleSel,
  layoutRotationDegreesSel,
} from '../selectors';

interface LayoutRotationIndicatorOwnProps {}

interface LayoutRotationIndicatorStateProps {
  layoutRotationButtonStyle: CSSProperties;
  rotationInDegrees: number;
}

interface LayoutRotationIndicatorProps
  extends LayoutRotationIndicatorOwnProps,
    LayoutRotationIndicatorStateProps {}

const LayoutRotationIndicator: React.FC<LayoutRotationIndicatorProps> = (
  props,
) => {
  return (
    <span
      style={props.layoutRotationButtonStyle}
      key="rotationIndicator"
      className="edit-box-indicator rotating"
    >
      {Math.floor(props.rotationInDegrees)}&#176;
    </span>
  );
};

const layoutRotationIndicatorPropsSel =
  createStructuredSelector<LayoutRotationIndicatorStateProps>({
    layoutRotationButtonStyle: layoutRotationButtonStyleSel,
    rotationInDegrees: layoutRotationDegreesSel,
  });

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  layoutRotationIndicatorPropsSel,
)(LayoutRotationIndicator);

// @ts-nocheck
import ReactDOM from 'reactDOM';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '#packages/i18n';
import * as mediaManagerPanelUtils from '../../mediaManagerPanelUtils/mediaManagerPanelUtils';
import React from 'react';
import { Button } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  value?: string;
  onSearchStart: FunctionFixMe;
  placeholder?: string;
  onClearSearchButtonClick?: FunctionFixMe;
  onBlur?: FunctionFixMe;
  searchOnEnterPressed?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'MediaManagerPanelSearch';

  static propTypes = {
    value: PropTypes.string,
    onSearchStart: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    onClearSearchButtonClick: PropTypes.func,
    onBlur: PropTypes.func,
    searchOnEnterPressed: PropTypes.bool,
  };

  static defaultProps = {
    value: '',
    searchOnEnterPressed: true,
  };

  state = {
    query: this.props.value,
    isFocused: false,
  };

  getQuery = () => {
    return this.state.query;
  };

  isFocused = () => {
    return this.state.isFocused;
  };

  getInputInstance = () => {
    return ReactDOM.findDOMNode(this.refs.inputInstance);
  };

  getStartSearchButtonInstance = () => {
    return ReactDOM.findDOMNode(this.refs.buttonInstance);
  };

  getPlaceholderText = () => {
    return translate(
      this.props.placeholder || 'Media_Panel_Search_Media_Placeholder',
    );
  };

  isQueryEmpty = () => {
    return _.isEmpty(this.getQuery());
  };

  isButtonVisible = () => {
    return !this.isQueryEmpty();
  };

  startSearch = (query) => {
    return _.invoke(this.props, 'onSearchStart', query);
  };

  handleBlur = (query) => {
    return _.invoke(this.props, 'onBlur', query);
  };

  handleInputValueChange = (event) => {
    this.setState({ query: event.target.value });
  };

  handleWrapperClick = () => {
    this.getInputInstance().focus();
  };

  handleWrapperMouseDown = (event) => {
    const buttonInstance = this.getStartSearchButtonInstance();

    if (
      buttonInstance &&
      mediaManagerPanelUtils.findFirstParentButtonNode(event.target) ===
        buttonInstance
    ) {
      setTimeout(() => {
        this.startSearch(this.state.query);
      }, 0);
    }
  };

  handleClearSearchButtonClick = () => {
    const { onClearSearchButtonClick } = this.props;

    if (onClearSearchButtonClick) {
      onClearSearchButtonClick();
    }

    this.setState({ query: '' });
  };

  handleInputFocus = () => {
    this.setState({ isFocused: true });
  };

  handleInputBlur = () => {
    this.setState({ isFocused: false });
    this.handleBlur(this.state.query);
  };

  handleKeyDown = (event) => {
    if (event && event.key === 'Enter' && this.props.searchOnEnterPressed) {
      this.getInputInstance().blur();
      this.startSearch(this.state.query);
    }
  };

  render() {
    return (
      <div className="media-manager-panel-search">
        <div
          onClick={this.handleWrapperClick}
          onMouseDown={this.handleWrapperMouseDown}
          className={cx('media-manager-panel-search__input-wrapper', {
            focused: this.isFocused(),
            'with-query': !this.isQueryEmpty(),
          })}
        >
          <span className="media-manager-panel-search__search-icon">
            <symbols.symbol name="media-manager-panel-search" />
          </span>
          <input
            value={this.state.query}
            placeholder={this.getPlaceholderText()}
            onChange={this.handleInputValueChange}
            onKeyDown={this.handleKeyDown}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            ref="inputInstance"
            className="media-manager-panel-search__input"
          />
          <div
            data-hook="media-manager-panel-search_button_wrapper"
            className={cx('media-manager-panel-search__button-wrapper', {
              'is-visible': this.isButtonVisible(),
            })}
          >
            {!this.isFocused() ? (
              <Button
                onClick={this.handleClearSearchButtonClick}
                key="clearSearchButton"
                className="media-manager-panel-search__button media-manager-panel-search__clear-search-button btn btn-light"
              >
                <symbols.symbol name="headerCloseButton" />
              </Button>
            ) : null}

            {this.isFocused() ? (
              <Button
                key="startSearchButton"
                ref="buttonInstance"
                className="media-manager-panel-search__button media-manager-panel-search__start-search-button btn"
              >
                <symbols.symbol name="media-manager-panel-arrow-right" />
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import { Composites, PanelHeaderCenteredText } from '@wix/wix-base-ui';

const VIEWPORT_BREAK_POINT = 1290;
const PLAYER_ORIGIN = 'TOP_BAR_HELP';

const { getPreviewPosition, getStageLayout } =
  stateManagement.domMeasurements.selectors;

function getBaseUrl() {
  return `https://apps.${util.serviceTopology.baseDomain}`;
}

function getDealerUrl(stageWidth) {
  let url = '/wix-dealer-webapp/api/lightboxViewer/EDITOR_VIDEO_GALLERY';
  url += stageWidth < VIEWPORT_BREAK_POINT ? '_SMALL/' : '/';

  return url;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'howToVideoGalleryPanel',
  mixins: [core.mixins.editorAPIMixin],
  componentDidMount() {
    window.addEventListener('message', this.iframeMessageHandler);
  },
  componentWillUnmount() {
    window.removeEventListener('message', this.iframeMessageHandler);
  },
  getStageWidth() {
    const state = this.getEditorAPI().store.getState();
    return getStageLayout(state).width + getPreviewPosition(state).left;
  },
  iframeMessageHandler(event) {
    const origin = event.origin || event.originalEvent.origin;
    if (origin !== getBaseUrl()) {
      return;
    }

    if (event?.data?.event === 'dealerButtonClick') {
      this.getEditorAPI().panelManager.openPanel(
        'panels.focusPanels.howToVideoPlayer',
        {
          videoId: event.data.details.clickType,
          origin: PLAYER_ORIGIN,
          withOverlay: false,
          withShadow: false,
        },
        true,
      );
    }
  },
  getIframeSrc() {
    const metaSiteId = this.getEditorAPI().dsRead.generalInfo.getMetaSiteId();
    const stageWidth = this.getStageWidth();

    return getBaseUrl() + getDealerUrl(stageWidth) + metaSiteId;
  },
  getKeyboardShortcuts() {
    return {
      esc: this.onClose,
    };
  },
  onClose() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  render() {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        frameClassName="how-to-video-gallery-frame"
        shouldHideHeader={true}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
        className="how-to-video-gallery-panel"
      >
        <div className="panel-content">
          <Composites.BigPanelHeader>
            <PanelHeaderCenteredText
              title="HowTo_Videos_Panel_Title"
              subtitle="HowTo_Videos_Panel_Subtitle"
              onClose={this.onClose}
            />
          </Composites.BigPanelHeader>
          <iframe src={this.getIframeSrc()} className="gallery-iframe" />
        </div>
      </FocusPanelFrame>
    );
  },
});

// @ts-nocheck
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import * as newBlogBi from '../../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../../utils/blogUtils';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

class QuickMigrationSuccessSlide extends React.Component {
  static displayName = 'QuickMigrationSuccessSlide';

  onClose = () => {
    this.props.markQuickMigrationAsSeen();
    this.props.onClose();
    this.props.openParentPanel();

    this.props.sendBi(newBlogBi.events.BLOG_QUICK_MIGRATION_FAILED, {
      is_first: true,
      role: 'owner',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  handleHelpClick = (e, articleType) => {
    const articles = {
      delete: blogUtils.helpArticles.deleteOldBlog,
      customize: blogUtils.helpArticles.customizeFeed,
    };
    e.preventDefault();
    this.props.openHelpCenter(articles[articleType]);
  };

  render() {
    return (
      <div className="blog-migration-success-slide">
        <CustomScroll heightRelativeToParent="100%">
          <div className="migration-banner">
            <symbols.symbol name="illustrationMigration" />
          </div>

          <div className="content">
            <h1 className="title">
              {translate('SiteApp_NewBlog_Migration_Panel_Title')}{' '}
            </h1>

            <p className="articles-title">
              {translate('SiteApp_NewBlog_Migration_Panel_Subtitle')}{' '}
              {translate('SiteApp_NewBlog_Migration_Panel_Description')}
            </p>
            <ul className="articles">
              <li key="customize" className="article">
                <p className="article-text">
                  <span className="article-icon">
                    <symbols.symbol name="customize" />
                  </span>
                  {translate('SiteApp_NewBlog_Migration_Panel_Article_2')}
                  <a
                    href="#"
                    onClick={(e) => {
                      this.handleHelpClick(e, 'customize');
                    }}
                    className="article-link"
                  >
                    {translate(
                      'SiteApp_NewBlog_Migration_Panel_ArticleLink_LearnHow',
                    )}
                  </a>
                </p>
              </li>

              <li key="delete" className="article">
                <p className="article-text">
                  <span className="article-icon">
                    <symbols.symbol name="deleteBlack" />
                  </span>
                  {translate('SiteApp_NewBlog_Migration_Panel_Article_1')}
                  <a
                    href="#"
                    onClick={(e) => {
                      this.handleHelpClick(e, 'delete');
                    }}
                    className="article-link"
                  >
                    {translate(
                      'SiteApp_NewBlog_Migration_Panel_ArticleLink_LearnHow',
                    )}
                  </a>
                </p>
              </li>

              <li key="publish" className="article">
                <p className="article-text">
                  <span className="article-icon">
                    <symbols.symbol name="publish" />
                  </span>
                  {translate('SiteApp_NewBlog_Migration_Panel_Article_3')}
                </p>
              </li>
            </ul>

            <baseUI.button
              label="SiteApp_NewBlog_Migration_Panel_CTA_GotIt"
              onClick={this.onClose}
              className="btn-confirm-primary btn-switch"
            />
          </div>
        </CustomScroll>
      </div>
    );
  }
}

const mapStateToProps = ({ editorAPI }, { superApp }) => {
  const isNewBlogPanel = superApp.id === blogUtils.newSuperAppId;
  const parentPanel = isNewBlogPanel
    ? blogUtils.newSuperAppPanelName
    : blogUtils.superAppPanelName;

  return {
    openParentPanel: () => editorAPI.panelManager.openPanel(parentPanel),
    markQuickMigrationAsSeen: () =>
      blogUtils.markQuickMigrationAsSeen(editorAPI),
  };
};

const mapDispatchToProps = (dispatch) => ({
  sendBi: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
  openHelpCenter: (article) =>
    dispatch(stateManagement.panels.actions.openHelpCenter(article)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(QuickMigrationSuccessSlide);
ConnectedComponent.pure = QuickMigrationSuccessSlide;

export default ConnectedComponent;

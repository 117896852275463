import { GFPP } from '#packages/helpIds';
import constants from '#packages/constants';
import { sections } from '#packages/util';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'ANCHOR_MENU_GFPP_MANAGER_LABEL',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.MANAGE),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.MANAGE),
    },
  ],
  enabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  presetActions: {
    help: {
      helpId: () =>
        sections.isSectionsEnabled()
          ? GFPP.VERTICAL_ANCHORS_MENU_SECTIONS
          : GFPP.VERTICAL_ANCHORS_MENU,
    },
  },
};

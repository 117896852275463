import { services, superAppsConstants } from '#packages/tpa';
import addPanelDataConsts from '#packages/addPanelDataConsts';

import { openAddPanel, openAppMarket } from '../../utils/utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { ConditionStructure, ConditionTranslations } from '../types';

const getRequitedAppMap: () => Record<
  string,
  { check?(editorAPI: EditorAPI): boolean; action?(editorAPI: EditorAPI): void }
> = () => ({
  [superAppsConstants.MEMBERS.APP_DEF_ID]: {
    check: (editorAPI) => {
      return !editorAPI.tpa.app.isInstalled(
        superAppsConstants.MEMBERS.APP_DEF_ID,
      );
    },
    action: (editorAPI) => {
      openAddPanel(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.COMMUNITY,
        'membersWelcomeSection',
      );
    },
  },
  [superAppsConstants.WIX_EVENTS.APP_DEF_ID]: {
    action: (editorAPI) => {
      openAddPanel(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.EVENTS_WELCOME,
        'eventsWelcomeSection',
      );
    },
  },
  [superAppsConstants.NEW_BLOG_APP_DEF_ID]: {
    action: (editorAPI) => {
      openAddPanel(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.NEW_BLOG_WELCOME,
        'blogWelcomeSection',
      );
    },
  },
  [superAppsConstants.WIX_BOOKINGS_APP_DEF_ID]: {
    action: (editorAPI) => {
      openAddPanel(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.BOOKINGS_WELCOME,
        'bookingsWelcomeSection',
      );
    },
  },
  [superAppsConstants.ECOM.APP_DEF_ID]: {
    action: (editorAPI) => {
      openAddPanel(editorAPI, addPanelDataConsts.CATEGORIES_ID.STORE, '');
    },
  },
});

const METADATA_KEY = 'requiredApp';
const TRANSLATION_KEY = 'requiredAppCondition';

export interface RequiredAppCondition {
  extras: {
    [METADATA_KEY]?: {
      appDefinitionID: string;
      slug: string;
    };
  };
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export interface RequiredAppTranslation {
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export const getRequiredAppConditionStructure = (
  editorAPI: EditorAPI,
): ConditionStructure<
  typeof METADATA_KEY,
  typeof TRANSLATION_KEY,
  RequiredAppCondition
> => ({
  metadataKey: METADATA_KEY,
  translationsKey: TRANSLATION_KEY,
  check: (conditionData) => {
    const REQUIRED_APP_MAP = getRequitedAppMap();
    const {
      extras: {
        requiredApp: { appDefinitionID },
      },
    } = conditionData;

    if (REQUIRED_APP_MAP[appDefinitionID]?.check) {
      return REQUIRED_APP_MAP[appDefinitionID].check(editorAPI);
    }

    return !services.tpaAddAppService.isAppInstalled(
      editorAPI,
      appDefinitionID,
      false,
    );
  },
  getAction: (conditionData) => {
    const REQUIRED_APP_MAP = getRequitedAppMap();
    const {
      extras: {
        requiredApp: { slug, appDefinitionID },
      },
    } = conditionData;
    if (REQUIRED_APP_MAP[appDefinitionID]?.action) {
      return () => REQUIRED_APP_MAP[appDefinitionID].action(editorAPI);
    }
    return () => {
      openAppMarket(editorAPI, slug);
    };
  },
});

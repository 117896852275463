import _ from 'lodash';

import { editorModel } from '#packages/util';
import { translate } from '#packages/i18n';
import {
  createSchemasAPI,
  createSchema,
  updateSchema,
  addField,
  setPermissions,
  getPermissionsByPreset,
  PRESETS,
  setDisplayName,
} from '#packages/contentManagerUtils';

import { createWixCodeCollectionsAPI } from '../wixCodeCollectionsAPI';
import {
  provisionWixCodeIfNeeded,
  enableDataMode,
  refreshSchemasCacheForDataBindingApp,
  openContentManagerPanelIfNeeded,
} from '../utils';

import type { EditorAPI } from '#packages/editorAPI';

export const exportMenusToContentManager = async (
  editorAPI: EditorAPI,
  { menus, sections, dishes }: any,
) => {
  editorAPI.panelHelpers.updateProgressBar(
    0,
    translate('menus_exporting_to_collection_progress_bar_text1'),
  );

  await provisionWixCodeIfNeeded(editorAPI);

  enableDataMode(editorAPI);

  editorAPI.panelHelpers.updateProgressBar(
    1,
    translate('menus_exporting_to_collection_progress_bar_text2'),
  );

  const schemasAPI = createSchemasAPI(editorAPI.wixCode.fileSystem);

  const timestamp = Date.now();
  const menusSchemaId = `menus-${timestamp}`;
  const menuSectionsSchemaId = `menu-sections-${timestamp}`;
  const dishesSchemaId = `dishes-${timestamp}`;

  const createMenusSchemaOperations = createSchema(
    menusSchemaId,
    setDisplayName(translate('menus_content_collection_menus_name')),
    addField('title', {
      type: 'text',
      displayName: translate('menus_content_collection_menus_field_title'),
    }),
    addField('description', {
      type: 'text',
      displayName: translate(
        'menus_content_collection_menus_field_description',
      ),
    }),
    setPermissions(getPermissionsByPreset(PRESETS.CONTENT_FOR_YOUR_SITE)),
  );

  const createReferencesForMenusSchema = updateSchema(
    menusSchemaId,
    addField('menus', {
      type: 'multi-reference',
      displayName: translate('menus_content_collection_sections_name'),
      referencingFieldKey: 'menus',
      referencingDisplayName: translate('menus_content_collection_menus_name'),
      referencedCollection: menuSectionsSchemaId,
    }),
  );

  const createSectionsSchemaOperations = createSchema(
    menuSectionsSchemaId,
    setDisplayName(translate('menus_content_collection_sections_name')),
    addField('title', {
      type: 'text',
      displayName: translate('menus_content_collection_sections_field_title'),
    }),
    addField('description', {
      type: 'text',
      displayName: translate(
        'menus_content_collection_sections_field_description',
      ),
    }),
    addField('image', {
      type: 'image',
      displayName: translate('menus_content_collection_sections_field_image'),
    }),
    setPermissions(getPermissionsByPreset(PRESETS.CONTENT_FOR_YOUR_SITE)),
  );

  const createReferencesForSectionsSchema = updateSchema(
    menuSectionsSchemaId,
    addField('sections', {
      type: 'multi-reference',
      displayName: translate('menus_content_collection_dishes_name'),
      referencingFieldKey: 'sections',
      referencingDisplayName: translate(
        'menus_content_collection_sections_name',
      ),
      referencedCollection: dishesSchemaId,
    }),
    addField('menus', {
      type: 'multi-reference',
      displayName: translate('menus_content_collection_menus_name'),
      referencingFieldKey: 'menus',
      referencingDisplayName: translate(
        'menus_content_collection_sections_name',
      ),
      referencedCollection: menusSchemaId,
    }),
  );

  const createDishesSchemaOperations = createSchema(
    dishesSchemaId,
    setDisplayName(translate('menus_content_collection_dishes_name')),
    addField('title', {
      type: 'text',
      displayName: translate('menus_content_collection_dishes_field_title'),
    }),
    addField('price', {
      type: 'text',
      displayName: translate('menus_content_collection_dishes_field_price'),
    }),
    addField('description', {
      type: 'text',
      displayName: translate(
        'menus_content_collection_dishes_field_description',
      ),
    }),
    addField('image', {
      type: 'image',
      displayName: translate('menus_content_collection_dishes_field_image'),
    }),
    setPermissions(getPermissionsByPreset(PRESETS.CONTENT_FOR_YOUR_SITE)),
  );

  const createReferencesForDishesSchema = updateSchema(
    dishesSchemaId,
    addField('sections', {
      type: 'multi-reference',
      displayName: translate('menus_content_collection_sections_name'),
      referencingFieldKey: 'sections',
      referencingDisplayName: translate('menus_content_collection_dishes_name'),
      referencedCollection: menuSectionsSchemaId,
    }),
  );

  await schemasAPI.execute(createMenusSchemaOperations);
  await schemasAPI.execute(createSectionsSchemaOperations);
  await schemasAPI.execute(createDishesSchemaOperations);

  await schemasAPI.execute(createReferencesForMenusSchema);
  await schemasAPI.execute(createReferencesForSectionsSchema);
  await schemasAPI.execute(createReferencesForDishesSchema);

  await refreshSchemasCacheForDataBindingApp(editorAPI);

  const collectionsAPI = createWixCodeCollectionsAPI(editorAPI);

  await collectionsAPI.bulkInsert(
    menusSchemaId,
    menus.map((menu: any) => {
      return {
        _id: menu.id,
        _owner: editorModel.permissionsInfo.ownerId,
        title: menu.title,
        description: menu.description,
      };
    }),
  );

  await collectionsAPI.bulkInsert(
    menuSectionsSchemaId,
    sections.map((section: any) => {
      return {
        _id: section.id,
        _owner: editorModel.permissionsInfo.ownerId,
        title: section.title,
        description: section.description,
        image: section.image,
      };
    }),
  );

  const PARALLEL_REQUESTS_COUNT = 5;

  for await (const chunk of _.chunk(sections, PARALLEL_REQUESTS_COUNT)) {
    await Promise.all(
      chunk.map((section: any) => {
        return collectionsAPI.insertReference(
          menuSectionsSchemaId,
          'menus',
          section.id,
          section.parentMenuId,
        );
      }),
    );
  }

  await collectionsAPI.bulkInsert(
    dishesSchemaId,
    dishes.map((dish: any) => {
      return {
        _id: dish.id,
        _owner: editorModel.permissionsInfo.ownerId,
        title: dish.title,
        description: dish.description,
        image: dish.image,
        price: dish.price,
      };
    }),
  );

  const menuSectionsReferences = _.flatten(
    dishes.map((dish: AnyFixMe) => {
      return dish.menuSections.map((sectionId: AnyFixMe) => {
        return {
          dishId: dish.id,
          sectionId,
        };
      });
    }),
  );

  for await (const chunk of _.chunk(
    menuSectionsReferences,
    PARALLEL_REQUESTS_COUNT,
  )) {
    await Promise.all(
      chunk.map((ref: any) => {
        return collectionsAPI.insertReference(
          dishesSchemaId,
          'sections',
          ref.dishId,
          ref.sectionId,
        );
      }),
    );
  }

  editorAPI.panelHelpers.updateProgressBar(
    2,
    translate('menus_exporting_to_collection_progress_bar_text3'),
  );

  // Display last message for a while
  await new Promise((resolve) => setTimeout(resolve, 1000));

  openContentManagerPanelIfNeeded(editorAPI);
};

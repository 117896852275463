import { ToursApiKey } from './api';
import { createAdiToEditorTour } from './tours/adiToEditorTour.conf';

import type { EditorAPI } from '#packages/editorAPI';
import type { StartOrigin } from './tours/tours.types';

export const startAdiToEditorTour = async (
  editorAPI: EditorAPI,
  startOrigin?: StartOrigin,
) => {
  const tourApi = editorAPI.host.getAPI(ToursApiKey);
  const adiToEditorTour = tourApi.createTour((shell) =>
    createAdiToEditorTour(shell, startOrigin),
  );

  return await tourApi.startTour(adiToEditorTour);
};

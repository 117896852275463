import * as wixStoresBi from '../common/bi/wixStoresBi';
import * as wixStoreDataProvider from '../common/wixStoreDataProvider';
import * as wixStoreEditorActionsService from '../common/wixStoreEditorActionsService';
import type { EditorAPI } from '#packages/editorAPI';
import { tpaUtils } from '#packages/util';

export default {
  openStoreManager(
    editorAPI: EditorAPI,
    buttonName: string,
    state: { state?: string; origin?: string; leavePanelsOpen?: boolean } = {},
  ) {
    const params: {
      button_name: string;
      app_id: string;
      app_name: string;
      origin?: string;
    } = {
      button_name: buttonName,
      app_id: tpaUtils.getStoresAppDefId(),
      app_name: wixStoreDataProvider.getAppName(),
    };

    if (state.origin) {
      params.origin = state.origin;
    }

    editorAPI.bi.event(wixStoresBi.events.MANAGE_PANEL_BUTTON_CLICK, params);
    state.leavePanelsOpen = true;
    wixStoreEditorActionsService.openAppDashboardInEditor(state);
  },
  shouldShowDropshippingBanner() {
    return wixStoreDataProvider.getDropshippingIntentExist();
  },
};

import _ from 'lodash';
import {
  environment,
  editorDataFunction,
} from '@wix/editor-component-data-resolver';

import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import { sections } from '#packages/util';
import { isSectionsOnStageEnabled } from '#packages/sectionsOnStage';

import type { ComponentEditorMetaDataDefinition } from '../types';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const { getFocusedContainer, getSelectedCompsRefs } =
  stateManagement.selection.selectors;
const { isInInteractionMode, getInteractionTriggerRef } =
  stateManagement.interactions.selectors;

function hasSiblings(editorAPI: EditorAPI, compPointer: CompRef): boolean {
  return editorAPI.components.getSiblings(compPointer).length > 0;
}

function isColumnSelectable(editorAPI: EditorAPI, compRef: CompRef): boolean {
  const state = editorAPI.store.getState();
  if (
    isInInteractionMode(state) &&
    editorAPI.utils.isSameRef(compRef, getInteractionTriggerRef(state))
  ) {
    return true;
  }
  return hasSiblings(editorAPI, compRef);
}

const metaData: ComponentEditorMetaDataDefinition = {
  showMarginsIndicator: (editorAPI: EditorAPI) => {
    const editorState = editorAPI.store.getState();
    const focusedContainer = getFocusedContainer(editorState);
    if (focusedContainer) {
      const selectedComponent = _.head(getSelectedCompsRefs(editorState));
      const parent =
        editorAPI.components.getContainerOrScopeOwner(focusedContainer);
      const isFocusedContainerDescendantOfColumn =
        editorAPI.components.someAncestor(
          focusedContainer,
          (candidateRef) => editorAPI.columns.isColumn(candidateRef),
          { includeScopeOwner: true },
        );

      if (isFocusedContainerDescendantOfColumn) {
        return true;
      }
      if (_.isEqual(selectedComponent, focusedContainer)) {
        return (
          editorAPI.components.is.fullWidth(parent) &&
          !!hasSiblings(editorAPI, selectedComponent)
        );
      }
      return (
        editorAPI.isDesktopEditor() && editorAPI.components.is.fullWidth(parent)
      );
    }
  },
  focusable: true,
  selectable: isColumnSelectable,
  horizontallyMovable: false,
  verticallyResizableByContainer: editorDataFunction(
    environment('isMobileEditor'),
    (isMobileEditor: boolean) => !isMobileEditor,
  ),
  movable: false,
  verticallyMovable: () => isSectionsOnStageEnabled(),
  allowLassoOnContainer: true,
  disabledKnobs: editorDataFunction(
    environment('isMobileEditor'),
    (isMobileEditor: boolean) => {
      if (isSectionsOnStageEnabled() || sections.isSectionsEnabled()) {
        return [constants.RESIZE_SIDES.LEFT, constants.RESIZE_SIDES.RIGHT];
      }

      if (isMobileEditor) {
        return Object.values(constants.RESIZE_SIDES);
      }

      return _(constants.RESIZE_SIDES).omit(['BOTTOM']).values().value();
    },
  ),
  controlledByParent: true,
  allowConnectToDB: true,
  skipInLayersPanel: (editorAPI: EditorAPI, compRef: CompRef) => {
    return !hasSiblings(editorAPI, compRef);
  },
  isAllowedAddToSavedComponent: false,
  applyMetaDataToFirstChild: {
    isAllowedAddToSavedComponent: true,
  },
};

export default metaData;

import _ from 'lodash';
import constants from '#packages/constants';
import * as topBarData from './topBarData';
import * as util from '#packages/util';
import keyboardShortcuts from '#packages/keyboardShortcuts';
import type { PanelData } from '../dropPanel/textAndLinkPanel';

const {
  EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
  BACK_TO_ADI_BUBBLE_STYLE_OVERRIDE,
} = topBarData;
const AUTOSAVE_ON_OFF_SHORTCUT_LABEL =
  keyboardShortcuts.topBar.AUTOSAVE_ON_OFF.label;

interface DropPanelWithPath {
  key: string;
  targetButtonKey: string;
  dropPanelData: PanelData;
  closeOnMouseLeave?: boolean;
  bubbleStyleOverrides?: AnyFixMe;
}

interface DropPanelWithContent {
  key: string;
  targetButtonKey: string;
  closeOnMouseLeave?: boolean;
  bubbleStyleOverrides?: AnyFixMe;
  dynamicPanelPath: string;
}

const getCustomDropPanels = (
  props: AnyFixMe,
): (DropPanelWithPath | DropPanelWithContent)[] => [
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM
      .MOBILE_OPTIMIZE_LAYOUT_UNDO,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.UNDO,
    dropPanelData: {
      title: 'TOPBAR_UNDO_TOOLTIP_TITLE',
      body: 'TOPBAR_MOBILE_TOOLTIP_UNDO_BODY',
    },
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.AUTO_SAVE,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'TOPBAR_SITE_AUTOSAVE_TOOLTIP_TITLE',
      body: 'TOPBAR_SITE_AUTOSAVE_TOOLTIP_BODY_TEXT',
      keyText: AUTOSAVE_ON_OFF_SHORTCUT_LABEL,
      links: [
        {
          titleKey: 'TOPBAR_SITE_AUTOSAVE_TOOLTIP_LINK_TEXT',
          onClick: props.openRevisions,
        },
      ],
    },
    bubbleStyleOverrides: null as AnyFixMe,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.BACK_TO_ADI,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SITE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'TOPBAR_SITE_BACK_ADI_TOOLTIP_TITLE',
      body: 'TOPBAR_SITE_BACK_ADI_TOOLTIP_BODY',
    },
    bubbleStyleOverrides: BACK_TO_ADI_BUBBLE_STYLE_OVERRIDE,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.BACK_TO_CHAT,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'Topbar_LiveChat_Tooltip_Title',
      body: 'Topbar_LiveChat_Tooltip_Text',
    },
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.PUBLISH_RC,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.PUBLISH_RC,
    closeOnMouseLeave: false,
    dynamicPanelPath: 'topBar.dropPanels.publishRC',
    bubbleStyleOverrides: EDIT_MODE_LAST_BUTTON_BUBBLE_STYLE_OVERRIDES,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.DISABLE_AUTO_SAVE,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'Topbar_Site_autosave_off_tooltip_title',
      body: 'Topbar_Site_autosave_off_tooltip_text',
      keyText: AUTOSAVE_ON_OFF_SHORTCUT_LABEL,
    },
    bubbleStyleOverrides: null as AnyFixMe,
  },
  {
    key: constants.ROOT_COMPS.TOPBAR.DROP_PANELS.CUSTOM.ENABLE_AUTO_SAVE,
    targetButtonKey: constants.ROOT_COMPS.TOPBAR.BUTTONS.SAVE,
    closeOnMouseLeave: true,
    dropPanelData: {
      title: 'Topbar_Site_autosave_on_tooltip_title',
      body: 'Topbar_Site_autosave_on_tooltip_text',
      keyText: AUTOSAVE_ON_OFF_SHORTCUT_LABEL,
    },
    bubbleStyleOverrides: null as AnyFixMe,
  },
];

const mapTopBarPropsToCustomDropPanels = (props: AnyFixMe) =>
  _(props)
    .thru(getCustomDropPanels)
    .thru((item) =>
      util.overrideUtils.applyOverrides(item, props.overriddenCustomDropPanels),
    )
    .value();

export default mapTopBarPropsToCustomDropPanels;

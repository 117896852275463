import * as util from '#packages/util';
import type { StateMapperArgs } from 'types/redux';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
function mapStateToProps({ editorAPI }: StateMapperArgs) {
  const { publishRC } = editorAPI.publishManager;
  const { togglePreview } = editorAPI.preview;
  const isDevModeOn = editorAPI.getViewTools().developerModeEnabled;
  const { setSiteUserPreferences } = stateManagement.userPreferences.actions;
  const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
  const { dispatch } = editorAPI.store;
  const actionChoice = getSiteUserPreferences(
    constants.USER_PREFS.TEST_SITE.TEST_SITE_BUTTON_TITLE,
  )(editorAPI.store.getState());

  return {
    publishRC,
    togglePreview,
    sendBI: (eventId: AnyFixMe, params: AnyFixMe) =>
      editorAPI.bi.event(eventId, params),
    isDevModeOn,
    setSiteUserPreferences,
    dispatch,
    actionChoice: actionChoice as string,
  };
}

export default util.hoc.connect(util.hoc.STORES.EDITOR_API, mapStateToProps);

import { media, url } from '#packages/util';
import { tpaUtils } from '#packages/tpa';
import type { V2App as AppServiceApp } from '@wix/ambassador-app-service-webapp/types';
import {
  type InstalledAppData,
  SiteappsApiStatus,
  type V2App as SiteAppsApp,
  VersionStatus,
} from '@wix/ambassador-site-apps-server/types';
import { APP_ICON_SIZE, APP_MANAGER_APPS_LIST } from '../../constants';
import type { RegularAppData } from './appMarket.types';

export const checkIsAppReadyForAppManager = (descriptor: InstalledAppData) => {
  const isAcceptableInstallationStatus =
    descriptor.installedData.status === SiteappsApiStatus.INSTALLED ||
    descriptor.installedData.status === SiteappsApiStatus.PENDING;
  const isAcceptableVersionStatus =
    descriptor.app.status === VersionStatus.PUBLISHED ||
    descriptor.app.status === VersionStatus.APPROVED;

  return isAcceptableInstallationStatus && isAcceptableVersionStatus;
};

export const compareAppsByAppManagerPredefinedList = <
  T extends AppServiceApp | SiteAppsApp,
>(
  app1: T,
  app2: T,
) => {
  const app1Index = APP_MANAGER_APPS_LIST.indexOf(app1.id);
  const app2Index = APP_MANAGER_APPS_LIST.indexOf(app2.id);

  if (app1Index === -1) {
    return 1;
  }
  if (app2Index === -1) {
    return -1;
  }

  return app1Index - app2Index;
};

export const getAppData = (app: SiteAppsApp | AppServiceApp) => {
  return {
    id: app.id,
    name: app.name,
    description: app.market.marketListing.basicInfo.teaser,
    icon: media.getResizedImage(
      app.market.marketListing.assetsMap.appIcon.assets[0].url,
      APP_ICON_SIZE * 2,
    ),
    slug: app.market.slug,
    version: app.version,
    isPublished:
      APP_MANAGER_APPS_LIST.includes(app.id) || app.status === 'PUBLISHED',
  };
};

export const getInstalledAppData = (
  installedAppData: InstalledAppData,
): RegularAppData => ({
  ...getAppData(installedAppData.app),
  isPending:
    installedAppData.installedData.status === SiteappsApiStatus.PENDING,
  isInstalled:
    installedAppData.installedData.status === SiteappsApiStatus.INSTALLED,
});

const addCaretPrefixIfNeeded = (str: string) =>
  str.startsWith('^') ? str : `^${str}`;

export const getAppVersionToInstall = (app: RegularAppData) => {
  // There is a case when the editor is opened with the QS of what app version should be installed.
  // In order to support this capability, we need to parse app market params and install a specific version of an app if it was provided
  const appMarketQS = url.getParameterByName('appMarketParams');
  const parsedParams = tpaUtils.parseAppMarketQueryParam(appMarketQS);

  if (parsedParams?.appDefId === app.id && parsedParams?.version) {
    return parsedParams.version;
  }

  return addCaretPrefixIfNeeded(app.version);
};

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import * as newBlogBi from '../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../utils/blogUtils';
import React from 'react';
import BlogPromoSlide from '../blogPromoSlide/blogPromoSlide';

class NewBlogMigrationSlide extends React.Component {
  static displayName = 'NewBlogMigrationSlide';

  static defaultProps = {
    panelName: blogUtils.newSuperAppPanelName,
    title: 'SiteApp_NewBlog_Migration_Promo_Title',
    subTitle: 'SiteApp_NewBlog_Migration_Promo_Subtitle',
    learnMoreLabel: 'SiteApp_NewBlog_Migration_Promo_LearnMore',
    pros: [
      'SiteApp_NewBlog_Migration_Promo_Bullet_1',
      'SiteApp_NewBlog_Migration_Promo_Bullet_2',
      'SiteApp_NewBlog_Migration_Promo_Bullet_3',
    ],
    buttons: [
      {
        key: 'switch',
        label: 'SiteApp_NewBlog_Migration_Promo_Button_Switch',
      },
    ],
    helpArticleId: blogUtils.helpArticles?.oldBlogMigration,
  };

  onButtonClick = (action) => {
    this.props.startQuickMigrationProp(this.props.sendBi, {
      action,
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  onCloseClick = () => {
    this.props.sendBi(newBlogBi.events.BLOG_QUICK_MIGRATION_ACTION, {
      action: 'close',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  onLearnMoreClick = () => {
    this.props.sendBi(newBlogBi.events.BLOG_QUICK_MIGRATION_LEARN_MORE, {
      role: 'owner',
      action: 'switch',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  render() {
    return (
      <BlogPromoSlide
        onCloseClick={this.onCloseClick}
        onButtonClick={this.onButtonClick}
        onLearnMoreClick={this.onLearnMoreClick}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ editorAPI }) => ({
  startQuickMigrationProp: _.partial(blogUtils.startQuickMigration, editorAPI),
});

const mapDispatchToProps = (dispatch) => ({
  sendBi: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(NewBlogMigrationSlide);
ConnectedComponent.pure = NewBlogMigrationSlide;

export default ConnectedComponent;

import _ from 'lodash';
import { translate } from '#packages/i18n';
import type { FetchListResult, ItemRef } from '../types';
import { getWixImageDescriptor } from '../utils';
import { WixAppsAppNames } from '../constants';

export const convertMenuItems = (fetchResults: FetchListResult<any, any>[]) => {
  const getItemByRef = (referencedItems: any, itemRef: ItemRef) => {
    return referencedItems[`${itemRef.collectionId}/${itemRef.itemId}`];
  };

  const withBasicFields = (item: any) => {
    return {
      description: item.description,
      title: item.title,
      id: item._iid,
    };
  };

  const buildMenu = (item: any) => {
    return withBasicFields(item);
  };

  const buildSection = (item: any) => {
    return {
      ...withBasicFields(item),
      ...(item.image && {
        image: getWixImageDescriptor(item.image, WixAppsAppNames.Menus),
      }),
      parentMenuId: item.parentRef.itemId,
    };
  };

  const buildDish = (item: any) => {
    return {
      ...withBasicFields(item),
      ...(item.image && {
        image: getWixImageDescriptor(item.image, WixAppsAppNames.Menus),
      }),
      price: item.price,
      menuSections: _.uniq(item.parentRefs.map((ref: AnyFixMe) => ref.itemId)),
    };
  };

  const payloads = fetchResults.map((res) => res.payload);
  const menus = payloads.map((payload) => buildMenu(payload.item));

  const sectionsAndDishes = payloads.reduce(
    (acc, payload) => {
      const { item, referencedItems } = payload;

      const possiblySections = item.items.map((item: AnyFixMe) =>
        getItemByRef(referencedItems, item),
      );
      const sections = possiblySections.filter(
        (pSection: AnyFixMe) => typeof pSection.items !== 'undefined',
      );
      const orphanedDishes = possiblySections.filter(
        (pSection: AnyFixMe) => typeof pSection.items === 'undefined',
      );

      const dishes = sections.reduce((acc: AnyFixMe, section: AnyFixMe) => {
        return [
          ...acc,
          ...section.items.map((item: AnyFixMe) =>
            getItemByRef(referencedItems, item),
          ),
        ];
      }, []);

      return {
        dishes: [...acc.dishes, ...dishes],
        sections: [...acc.sections, ...sections],
        orphanedDishes: [...acc.orphanedDishes, ...orphanedDishes],
      };
    },
    {
      dishes: [],
      sections: [],
      orphanedDishes: [],
    },
  );

  const NEW_SECTION_ID = 'new-section';

  sectionsAndDishes.orphanedDishes = sectionsAndDishes.orphanedDishes.map(
    (orphan) => {
      return {
        ...orphan,
        parentRefs: [{ itemId: NEW_SECTION_ID }],
      };
    },
  );

  const dishes = [
    ...sectionsAndDishes.dishes,
    ...sectionsAndDishes.orphanedDishes,
  ].map(buildDish);

  const sections = sectionsAndDishes.sections.map(buildSection);

  if (sectionsAndDishes.orphanedDishes.length) {
    sections.push(
      buildSection({
        _iid: NEW_SECTION_ID,
        title: translate(
          'menus_content_collection_sections_field_generic_section',
        ),
        description: '',
        parentRef: {
          itemId: menus[0].id,
        },
      }),
    );
  }

  return {
    menus,
    sections,
    dishes,
  };
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import { FocusPanelFrame } from '../../frames';
import * as UI from '#packages/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import FontItem from './fontItem';
import { translate } from '#packages/i18n';

const { getList, getQueue, getUploadedItems } =
  stateManagement.uploadedFonts.selectors;
const {
  setUploadedItems,
  assignFlagToQueueItem,
  cancelUpload,
  prepareUploadedFontsData,
  removeFonts,
  clearUploadQueue,
  uploadItems,
} = stateManagement.uploadedFonts.actions;
const { updateOrOpenPanel, closePanelByName, openHelpCenter } =
  stateManagement.panels.actions;

const FONT_TOO_BIG_HELP_ID = '98c9964e-1df5-48c9-a05e-1475043f71e9';

// eslint-disable-next-line react/prefer-es6-class
const FontsUploadPanel = createReactClass({
  displayName: 'fontsUploadPanel',
  propTypes: {
    panelName: PropTypes.string,
    geoLocation: PropTypes.string.isRequired,
    uploadedFontsList: PropTypes.object,
    uploadedFontsQueue: PropTypes.object,
    uploadedFontsItems: PropTypes.object,
    setUploadedItems: PropTypes.func.isRequired,
    assignFlagToQueueItem: PropTypes.func.isRequired,
    cancelUpload: PropTypes.func.isRequired,
    clearUploadQueue: PropTypes.func.isRequired,
    prepareUploadedFontsData: PropTypes.func.isRequired,
    removeFonts: PropTypes.func.isRequired,
    uploadItems: PropTypes.func.isRequired,
    closePanelByName: PropTypes.func.isRequired,
    updateOrOpenPanel: PropTypes.func.isRequired,
    reportBi: PropTypes.func.isRequired,
    onClose: PropTypes.func,
  },
  render() {
    return (
      <FocusPanelFrame
        ref="frame"
        panelName={this.props.panelName}
        shouldNotCloseOnBlur={true}
        onClose={this.handlePanelCloseClick}
        title={translate('UPLOAD_FONTS_Panel_Header')}
        helpId="96323a82-c61a-4ac1-90ac-10aecb37cbf8"
        width="600"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        className="fonts-upload-panel"
      >
        <div className="fonts-upload-header">
          <span className="general-explanation-text">
            {this.shouldFilterOtfFiles()
              ? translate('Upload_Fonts_Panel_Header_Text_CJK')
              : translate('Upload_Fonts_Panel_Header_Text')}
          </span>
          <UI.fileInput
            label="Upload_Fonts_Upload_Button"
            onChange={(fileList) => {
              this.handleUpload(fileList, false);
            }}
            fileTypes={this.getFileTypes()}
            className="font-upload-button"
          />
        </div>
        {!this.shouldShowFontsList() ? (
          <UI.filesDrop
            onChange={(fileList) => {
              this.handleUpload(fileList, true);
            }}
            key="empty-fonts-list"
            className="empty-msg"
          >
            <div className="empty-message-container">
              <div className="empty-symbol-container">
                <symbols.symbol name="fonts-manager-clipart" />
              </div>
              <span className="empty-text-title">
                {translate('Upload_Fonts_Empty_State_Title')}
              </span>
              <br />
              <span className="empty-text">
                {this.shouldFilterOtfFiles()
                  ? translate('Upload_Fonts_Empty_State_Text_CJK')
                  : translate('Upload_Fonts_Empty_State_Text')}
              </span>
              <UI.fileInput
                label="Upload_Fonts_Empty_State_Text_Link"
                onChange={(fileList) => {
                  this.handleUpload(fileList, false);
                }}
                onlyText={true}
                fileTypes={this.getFileTypes()}
                className="font-upload-link"
              />
            </div>
          </UI.filesDrop>
        ) : null}
        {this.shouldShowBigFontsWarning() && (
          <div className="big-fonts-upload-warning">
            <span>{this.state.bigFontWarning}&nbsp;</span>
            <a
              className="big-fonts-learn-more-link"
              onClick={this.openHelpFontTooBig}
            >
              {translate('Upload_Fonts_TooLarge_Link')}
            </a>
          </div>
        )}
        {this.shouldShowFontsList() ? (
          <UI.filesDrop
            key="fonts-list"
            hasBorder={false}
            onChange={(fileList) => {
              this.handleUpload(fileList, true);
            }}
            className="list"
          >
            <CustomScroll ref="customScroll">
              <div className="font-list-container">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.state.invalidFiles, (font) => (
                  <FontItem
                    key={font.name}
                    {...this.getInvalidFilesProps(font)}
                  />
                ))}
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.getFontsList(), (font, fontIndex) => (
                  <FontItem
                    key={font.fontFamily || font.fileName + fontIndex}
                    {...font}
                  />
                ))}
              </div>
            </CustomScroll>
          </UI.filesDrop>
        ) : null}
        <div className="fonts-upload-footer">
          {this.shouldShowFooterMsg() ? (
            <span key="upload-text" className="footer-msg">
              {translate('Upload_Fonts_Footer_Text')}
            </span>
          ) : null}
          {this.shouldShowErrorMsg() ? (
            <span key="error-text" className="footer-error-msg">
              {translate('Upload_Fonts_Footer_Error_Text')}
            </span>
          ) : null}
          <UI.button
            label={this.getButtonLabel()}
            disabled={this.isUploadInProcess()}
            onClick={this.handleDoneClick}
            className="default btn-md close-panel"
          />
        </div>
      </FocusPanelFrame>
    );
  },
  getInitialState() {
    return {
      invalidFiles: [],
      isUploadStarted: false,
      isUploadError: false,
      bigFontWarning: '',
    };
  },
  handleUpload(fileList, isDragNDrop) {
    this.setState({ bigFontWarning: '' });
    const shouldFilterOTF = this.shouldFilterOtfFiles();
    if (fileList.length > 20) {
      this.setState({ isUploadError: true });
      return;
    }

    this.sendUploadBIEvents(fileList, isDragNDrop);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    const invalidFiles = _.reduce(
      fileList,
      function (res, file) {
        if (util.fontsManagerUtils.isFolder(file)) {
          file.errorCode = '999998';
          res.push(file);
        } else if (!util.fontsManagerUtils.isFontExt(file, shouldFilterOTF)) {
          file.errorCode = '-7751';
          res.push(file);
        }

        return res;
      },
      [],
    );

    const validFiles = _.difference(fileList, invalidFiles);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(invalidFiles, (file) => {
      this.sendUploadFailedBi(file.errorCode, file);
    });

    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const allBigFontFiles = _.filter(fileList, (file) => {
      return (
        file.size > util.fontsManagerUtils.constants.WARNING_FONT_UPLOAD_SIZE
      );
    });
    const shouldShowBigFontsWarning = allBigFontFiles.length > 0;
    const warningContent =
      allBigFontFiles.length === 1
        ? translate('Upload_Fonts_TooLarge_Singular_Toast', {
            FontName: allBigFontFiles[0].name,
          })
        : translate('Upload_Fonts_TooLarge_Plural_Toast', {
            Count: allBigFontFiles.length,
          });
    this.setState({
      isUploadError: false,
      invalidFiles,
      isUploadStarted: true,
      bigFontWarning: shouldShowBigFontsWarning ? warningContent : '',
    });

    this.props.uploadItems(validFiles);
  },

  openHelpFontTooBig() {
    this.props.openHelpCenter(FONT_TOO_BIG_HELP_ID);
  },
  sendUploadBIEvents(fileList, isDragNDrop) {
    const biEvent = isDragNDrop
      ? coreBi.events.fontsUploadPanel.Fonts_Dragged
      : coreBi.events.fontsUploadPanel.Upload_Fonts_Click;
    this.props.reportBi(biEvent, { count: fileList.length });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(fileList, (file) => {
      this.props.reportBi(
        coreBi.events.fontsUploadPanel.Upload_Fonts_Try_Upload,
        { name: file.name },
      );
    });
  },
  sendUploadFailedBi(errorCode, file) {
    const message = util.fontsManagerUtils.getErrorMessage(errorCode).bi_msg;
    this.props.reportBi(coreBi.events.fontsUploadPanel.Upload_Failed, {
      message,
      name: file.name,
    });
  },
  getFontProps(item) {
    const isProcessingItem = !!item.status && !item.fontFamily;
    let itemStatus = '';
    if (!isProcessingItem) {
      itemStatus = util.fontsManagerUtils.constants.FONT_STATUS.FINISHED;
    } else if (isProcessingItem) {
      itemStatus = item.status;
    }

    const props = {
      fileName: item.name,
      name: item.displayName,
      fontFamily: item.fontFamily,
      status: itemStatus,
      errorCode: item.error_code,
      downloadable: !isProcessingItem,
      downloadUrl: item.downloadUrl,
      removeable: !isProcessingItem,
      finished:
        item.status ===
          util.fontsManagerUtils.constants.FONT_STATUS.SUCCEEDED ||
        this.isUploadedItem(item),
      onRemoveClick: _.partial(this.handleRemoveClick, item),
      onDownloadClick: _.partial(this.handleDownloadClick, item),
      cancelable:
        item.status === util.fontsManagerUtils.constants.FONT_STATUS.WAITING,
      isStillUploading: item.stillUploading,
      onCancelClick: isProcessingItem
        ? _.partial(this.handleCancleClick, item)
        : null,
    };
    return props;
  },
  isUploadedItem(item) {
    return item.id && !!_.get(this.props.uploadedFontsItems, item.id);
  },
  getFontsList() {
    const items = this.getQueueItems().concat(this.getExistingItems());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(items, this.getFontProps);
  },
  getInvalidFilesProps(font) {
    return {
      fileName: font.name,
      status: util.fontsManagerUtils.constants.FONT_STATUS.FAILED,
      errorCode: font.errorCode,
    };
  },
  getExistingItems() {
    const queue = this.props.uploadedFontsQueue;
    return _.reject(this.props.uploadedFontsList, function (item) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      return _.find(queue, { id: item.id });
    });
  },
  getQueueItems() {
    const queue = this.props.uploadedFontsQueue;
    return _.reject(queue, function (queueItem) {
      return queueItem.removed || queueItem.isAborted() || queueItem.canceled;
    });
  },
  componentWillUnmount() {
    this.cancelAllUpload();
    this.props.prepareUploadedFontsData();
    this.props.onClose?.(this.props.uploadedFontsList);
  },
  shouldShowFontsList() {
    return (
      !_.isEmpty(this.getExistingItems()) ||
      !_.isEmpty(this.getQueueItems()) ||
      !_.isEmpty(this.state.invalidFiles)
    );
  },
  shouldShowBigFontsWarning() {
    return !!this.state.bigFontWarning.length;
  },
  handleRemoveClick(item) {
    this.props.reportBi(coreBi.events.fontsUploadPanel.Delete_File, {});
    this.openDeleteConfirmationPanel(item);
  },
  handleDownloadClick() {
    this.props.reportBi(coreBi.events.fontsUploadPanel.Download_File_Click, {});
  },
  openDeleteConfirmationPanel(item) {
    const onConfirmCallBack = _.partial(this.removeItem, item);

    const onCancel = () => {
      this.props.reportBi(coreBi.events.fontsUploadPanel.Approve_Delete_File, {
        text: 'cancel',
      });
    };

    this.props.updateOrOpenPanel(
      'panels.toolPanels.deleteFontMsg',
      {
        panelName: 'deleteFontConfirmation',
        onConfirm: onConfirmCallBack,
        onCancel,
      },
      true,
    );
  },
  removeItem(item) {
    this.props.reportBi(coreBi.events.fontsUploadPanel.Approve_Delete_File, {
      text: 'delete',
    });

    const queue = this.props.uploadedFontsQueue;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const queueItemIndex = _.findIndex(queue, { id: item.id });
    let uploadedItems = this.props.uploadedFontsItems;
    if (queueItemIndex !== -1) {
      this.props.assignFlagToQueueItem(queueItemIndex, { removed: true });
      if (uploadedItems[item.id]) {
        uploadedItems = _.clone(uploadedItems);
        delete uploadedItems[item.id];
      }
      this.props.setUploadedItems(uploadedItems);
    }

    this.props.removeFonts([item]);
  },
  handleCancleClick(item) {
    if (item.status === util.fontsManagerUtils.constants.FONT_STATUS.WAITING) {
      this.props.reportBi(coreBi.events.fontsUploadPanel.Cancel_Upload, {
        name: item.name,
      });

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/index-of
      this.cancelUpload(_.indexOf(this.props.uploadedFontsQueue, item));
    }
  },

  cancelUpload(itemIndex) {
    this.props.cancelUpload(itemIndex);
  },
  cancelAllUpload() {
    const self = this;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const fontsToCancel = _.filter(this.props.uploadedFontsQueue, {
      status: util.fontsManagerUtils.constants.FONT_STATUS.WAITING,
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const fontsToCancelIndexes = _.map(fontsToCancel, (item) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/index-of
      _.indexOf(this.props.uploadedFontsQueue, item),
    );

    if (this.isUploadInProcess()) {
      if (fontsToCancel) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(fontsToCancelIndexes, function (itemIndex) {
          self.cancelUpload(itemIndex);
        });
      }
    }
    this.setState({ bigFontWarning: '' });
    this.props.clearUploadQueue();
  },
  handlePanelCloseClick() {
    if (this.isUploadInProcess()) {
      this.openCancelConfirmationPanel();
      return false;
    }
    return true;
  },
  openCancelConfirmationPanel() {
    const self = this;
    this.props.updateOrOpenPanel(
      'panels.toolPanels.cancelFontUploadMsg',
      {
        panelName: 'cancelUploadConfirmation',
        onConfirm: function onConfirm() {
          self.props.reportBi(
            coreBi.events.fontsUploadPanel.Approve_Stop_Upload,
            {},
          );
          self.closePanel();
        },
        onCancel: function onCancel() {
          return false;
        },
      },
      true,
    );
  },
  isAnyInQueueProcessing() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return _.some(this.props.uploadedFontsQueue, {
      status: util.fontsManagerUtils.constants.FONT_STATUS.PROCESSING,
    });
  },
  isAnyInQueueWaiting() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return _.some(this.props.uploadedFontsQueue, {
      status: util.fontsManagerUtils.constants.FONT_STATUS.WAITING,
    });
  },
  isAnyInQueueUploading() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return _.some(this.props.uploadedFontsQueue, {
      status: util.fontsManagerUtils.constants.FONT_STATUS.UPLOADING,
    });
  },
  closePanel() {
    this.props.closePanelByName(this.props.panelName);
  },
  handleDoneClick() {
    this.props.reportBi(coreBi.events.fontsUploadPanel.Closed_Button_Click, {});
    this.closePanel();
  },
  getButtonLabel() {
    return this.state.isUploadStarted
      ? 'Upload_Fonts_Close_Button_2'
      : 'Upload_Fonts_Close_Button_1';
  },
  isUploadInProcess() {
    return (
      this.isAnyInQueueProcessing() ||
      this.isAnyInQueueWaiting() ||
      this.isAnyInQueueUploading()
    );
  },
  shouldShowFooterMsg() {
    return (
      !this.state.isUploadError &&
      !_.isEmpty(this.props.uploadedFontsItems) &&
      !this.isUploadInProcess()
    );
  },
  shouldShowErrorMsg() {
    return this.state.isUploadError;
  },
  getFileTypes() {
    const shouldFilterOtfFiles = this.shouldFilterOtfFiles();
    const extensions = shouldFilterOtfFiles
      ? util.fontsManagerUtils.constants.CONVERTED_EXTENTIONS
      : util.fontsManagerUtils.constants.ACCEPTABLE_EXTENTIONS;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(extensions, function (ext) {
      return `.${ext}`;
    }).join(',');
  },
  shouldFilterOtfFiles() {
    const language = util.languages.getLanguageCode();

    return util.fontsManagerUtils.shouldFilterOtfFiles(
      language,
      this.props.geoLocation,
    );
  },
});

const mapStateToProps = ({ state, dsRead }) => ({
  uploadedFontsList: getList(state),
  uploadedFontsQueue: getQueue(state),
  uploadedFontsItems: getUploadedItems(state),
  geoLocation: dsRead.generalInfo.getGeo(),
});

const mapDispatchToProps = {
  setUploadedItems,
  assignFlagToQueueItem,
  cancelUpload,
  prepareUploadedFontsData,
  removeFonts,
  uploadItems,
  clearUploadQueue,
  updateOrOpenPanel,
  openHelpCenter,
  closePanelByName,
  reportBi: (...args) => stateManagement.bi.actions.event(...args),
};

const Connected = _.flow(
  util.hoc.renderWhenMutated,
  util.hoc.connect(
    util.hoc.STORES.STATE_AND_DS,
    mapStateToProps,
    mapDispatchToProps,
  ),
)(FontsUploadPanel);

Connected.pure = FontsUploadPanel;

export default Connected;

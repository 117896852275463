import * as React from 'react';
import * as baseUI from '#packages/baseUI';
import _ from 'lodash';

interface ColorPickerValues {
  color: string;
  alpha: number;
}

interface BackgroundFillControlProps {
  colorPickerValues: ColorPickerValues;
  colorPickerProps: any;
  updateCompDesign: (value: ColorPickerValues) => void;
}

const BackgroundFillsControl: React.FC<BackgroundFillControlProps> = ({
  colorPickerValues,
  colorPickerProps,
  updateCompDesign,
}) => {
  const updateDesignIfApplicable = (val: ColorPickerValues) => {
    if (_.isEqual(val, colorPickerValues)) return;

    updateCompDesign(val);
  };
  return (
    <baseUI.colorPickerInputWithOpacity
      label={'CustomDesign_FillColor_BGColor&Opacity'}
      value={colorPickerValues}
      onChange={updateDesignIfApplicable}
      panelMode="gradient"
      automationId="bgFill"
      {...colorPickerProps}
    />
  );
};

BackgroundFillsControl.displayName = 'backgroundFillsControl';

export default BackgroundFillsControl;

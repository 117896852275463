import React from 'react';
import { RadioButtons, Divider } from '@wix/wix-base-ui';
import CONSTANTS from '../../../../utils/constants';
import { InputLabel } from './CommonComponents';
import { PERMISSIONS_TAB_HOOKS } from '../../../../utils/dataHooks';
import styles from './membersAccessTypes.scss';

import type { MemberAccessPermission } from '../../../../utils/constants';

const { MEMBER_ACCESS_PERMISSIONS } = CONSTANTS;

interface MembersAccessTypesProps {
  accessType: MemberAccessPermission;
  onAccessChange: (accessType: MemberAccessPermission) => void;
}

export const MembersAccessTypes: React.FC<MembersAccessTypesProps> = ({
  accessType,
  onAccessChange,
}) => {
  return (
    <div className={styles.memberAccessTypes}>
      <RadioButtons
        value={accessType}
        onChange={onAccessChange}
        dataHook={PERMISSIONS_TAB_HOOKS.MEMBERS_ACCESS_TYPE_RADIO_BUTTONS}
      >
        <button
          value={MEMBER_ACCESS_PERMISSIONS.ALL}
          onClick={() => onAccessChange(MEMBER_ACCESS_PERMISSIONS.ALL)}
        >
          <div className={styles.accessLabel}>
            <InputLabel
              gap="big"
              title="NewPages_Panel_Permissions_Member_Roles_All_Members_Title"
              subtitle="NewPages_Panel_Permissions_Member_Roles_All_Members_Subtitle"
            />
          </div>
        </button>
        <Divider long />
        <button
          value={MEMBER_ACCESS_PERMISSIONS.SPECIFIC}
          onClick={() => onAccessChange(MEMBER_ACCESS_PERMISSIONS.SPECIFIC)}
        >
          <div className={styles.accessLabel}>
            <InputLabel
              gap="big"
              title="NewPages_Panel_Permissions_Member_Roles_Specific_Members_Title"
              subtitle="NewPages_Panel_Permissions_Member_Roles_Specific_Members_Subtitle"
            />
          </div>
        </button>
      </RadioButtons>
    </div>
  );
};

import React, { memo, type FC } from 'react';
import { Composites, TextLabel, InfoIcon } from '@wix/wix-base-ui';
import * as BaseUI from '#packages/baseUI';

import type { BusinessType } from '#packages/siteGlobalData';

export interface BusinessTypeInputProps {
  label: string;
  placeholder?: string;
  value: BusinessType;
  invalidMessage?: string;
  onChange: (value: BusinessType) => void;
  onBlur: () => void;
  onTooltipOpen?: () => void;
}

import styles from './businessTypeInput.scss';

const BusinessTypeInput: FC<BusinessTypeInputProps> = memo(
  function BusinessTypeInput({
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    onTooltipOpen,
  }) {
    return (
      <Composites.TextInputLabeled>
        <TextLabel type="T02" value={label} />
        <InfoIcon
          small
          text="ai_template_injection_panel_site_type_tooltip_text"
          onTooltipOpen={onTooltipOpen}
        />
        <BaseUI.BusinessTypeInput
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          className={styles.input}
          clearButton={false}
        />
      </Composites.TextInputLabeled>
    );
  },
);

export { BusinessTypeInput };

import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 296,
        height: 202,
        x: 24,
        y: 376,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'bs16c',
      },
      parent: 'comp-kgt5wzeq',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        text: `<h2 class="font_2" style="font-size:97px; line-height:0.9em"><span style="color:#F58144;"><span style="font-size:97px"><span style="font-family:anton,sans-serif">${translate(
          'interactions_preset_ice_cream_title_1',
        )}</span></span></span></h2>\n\n<h2 class="font_2" style="font-size:97px; line-height:0.9em"><span style="color:#F58144;"><span style="font-size:97px"><span style="font-family:anton,sans-serif">${translate(
          'interactions_preset_ice_cream_title_2',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'bs16c',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wzf4': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -30,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bs16c',
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 257,
          height: 178,
          x: 10,
          y: 319,
          scale: 1.7398102903133084,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'bs16c',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wzf51',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 312,
        height: 32,
        x: 28,
        y: 539,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'bs16c',
      },
      parent: 'comp-kgt5wzeq',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        text: `<h4 class="font_4" style="font-size:18px; line-height:1.2em"><span style="font-size:40px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span><span style="color:rgb(66, 55, 55)"><span style="font-family:barlow-extralight,barlow,sans-serif"><span style="font-size:18px">${translate(
          'interactions_preset_ice_cream_subtitle',
        )}</span></span></span></span></span></span></span></span></h4>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'bs16c',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wzf4': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bs16c',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 166,
          height: 25,
          x: 13,
          y: 468,
          scale: 0.8788732394366198,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'bs16c',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wzf7',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 53,
        height: 53,
        x: 216,
        y: 38,
        scale: 1,
        rotationInDegrees: 90,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'bs16c',
      },
      parent: 'comp-kgt5wzeq',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        alt: '',
        svgId: '74f558_61d38254fe2b48648f2e6d304df17765.svg',
        title: 'Corner.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'bs16c',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        overrideColors: {
          color1: '#242323',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.6,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wzf4': {
          type: 'TransformData',
          translate: {
            x: {
              type: 'px',
              value: -14,
            },
            y: {
              type: 'px',
              value: -14,
            },
          },
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bs16c',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 50,
          height: 50,
          x: 165,
          y: 8,
          scale: 1,
          rotationInDegrees: 90,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wzf91',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      layout: {
        width: 204,
        height: 456,
        x: 104,
        y: 52,
        scale: 1,
        rotationInDegrees: 10.139786485762613,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'bs16c',
      },
      parent: 'comp-kgt5wzeq',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        title: '',
        uri: '74f558_2dd7e3814e2249559ca61ec896488ed6~mv2.png',
        description: '',
        width: 1080,
        height: 1920,
        alt: 'Chockolate-Mix-Nuts.png',
        name: 'Chockolate-Mix-Nuts.png',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'bs16c',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.5,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wzf4': {
          type: 'TransformData',
          rotate: -31,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bs16c',
          },
          translate: {
            x: {
              type: 'px',
              value: 37.230088495575224,
            },
            y: {
              type: 'px',
              value: -34.725663716814154,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 172,
          height: 386,
          x: 61,
          y: 30,
          scale: 1,
          rotationInDegrees: 10.139786485762613,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wzfd3',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 53,
        height: 53,
        x: 308,
        y: 38,
        scale: 1,
        rotationInDegrees: 180,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'bs16c',
      },
      parent: 'comp-kgt5wzeq',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        alt: '',
        svgId: '74f558_61d38254fe2b48648f2e6d304df17765.svg',
        title: 'Corner.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'bs16c',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        overrideColors: {
          color1: '#242323',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.6,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wzf4': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bs16c',
          },
          translate: {
            x: {
              type: 'px',
              value: 14,
            },
            y: {
              type: 'px',
              value: -14,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 50,
          height: 50,
          x: 222,
          y: 8,
          scale: 1,
          rotationInDegrees: 180,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wzff4',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 53,
        height: 53,
        x: 308,
        y: 124,
        scale: 1,
        rotationInDegrees: 270,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'bs16c',
      },
      parent: 'comp-kgt5wzeq',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        alt: '',
        svgId: '74f558_61d38254fe2b48648f2e6d304df17765.svg',
        title: 'Corner.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'bs16c',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        overrideColors: {
          color1: '#242323',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.6,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wzf4': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'bs16c',
          },
          translate: {
            x: {
              type: 'px',
              value: 14,
            },
            y: {
              type: 'px',
              value: 14,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'bs16c',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 50,
          height: 50,
          x: 222,
          y: 66,
          scale: 1,
          rotationInDegrees: 270,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wzfh4',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 404,
    height: 600,
    x: 288,
    y: 40,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'bs16c',
  },
  parent: 'bs16c',
  variants: {
    'variants-kgt5wzf4': {
      componentId: 'comp-kgt5wzeq',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'bs16c',
      },
    },
  },
  scopedTransformations: {
    'variants-kgt5wzf4': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'bs16c',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'bs16c',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: '#F6DECC',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 526,
      x: 20,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5wzeq',
      author: 'studio',
    },
  },
  activeModes: {},
};

// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as helpIds from '#packages/helpIds';
import * as attachToSOSPPanelMapper from './attachToSOSPPanelMapper';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import {
  Button,
  Checkbox,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  RichText,
} from '@wix/wix-base-ui';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

const RETINA_ILLUSTRATION_PATH =
  'panels/focusPanels/addRemoveFromBarRetina.gif';
const ILLUSTRATION_PATH = 'panels/focusPanels/addRemoveFromBar.gif';

//TYPE WAS GENERATED, remove this line when reviewed
interface AttachDetachSOSPPanelProps {
  panelName: string;
  closePanel: FunctionFixMe;
  openHelpCenter: FunctionFixMe;
  doNotShowAgain: boolean;
  setDoNotShowAgain: FunctionFixMe;
}

class AttachDetachSOSPPanel extends React.Component<AttachDetachSOSPPanelProps> {
  static displayName = 'AttachDetachSOSPPanel';

  static propTypes = {
    panelName: PropTypes.string.isRequired,
    closePanel: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
    doNotShowAgain: PropTypes.bool.isRequired,
    setDoNotShowAgain: PropTypes.func.isRequired,
  };

  state = {
    doNotShowAgain: this.props.doNotShowAgain,
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  getIllustrationSrc = () => {
    const backgroundImageRelativePath =
      util.browserUtil.getDevicePixelRatio() === 2
        ? RETINA_ILLUSTRATION_PATH
        : ILLUSTRATION_PATH;

    return util.media.getMediaUrl(backgroundImageRelativePath);
  };

  onClose = () => {
    if (this.state.doNotShowAgain) {
      this.props.setDoNotShowAgain();
    }

    this.props.closePanel(this.props.panelName);
  };

  onLearnMoreClick = () => {
    this.props.openHelpCenter(helpIds.LEARN_MORE.SOSP_ATTACH_PANEL);
  };

  onDoNotShowAgainClick = () => {
    this.setState({
      doNotShowAgain: !this.state.doNotShowAgain,
    });
  };

  render() {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        shouldHideHeader={true}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
        className="popup-medium attach-to-sosp-panel"
      >
        <Composites.BigPanelHeader>
          <PanelHeaderCenteredText
            title="Notification_Box_MembersBar_Added_Title"
            subtitle="Notification_Box_MembersBar_Added_Subtitle"
            titleType="T16"
            onClose={this.onClose}
          />
          <PanelHeaderIllustration blueStripHeight={92}>
            <img src={this.getIllustrationSrc()} className="illustration" />
          </PanelHeaderIllustration>
        </Composites.BigPanelHeader>

        <Composites.PanelContent>
          <Composites.RichText>
            <RichText type="T06">
              <p>
                {translate('Notification_Box_MembersBar_Added_Text')}
                <a onClick={this.onLearnMoreClick}>
                  {' '}
                  {translate('Notification_Box_Learn_More')}
                </a>
              </p>
            </RichText>
          </Composites.RichText>
        </Composites.PanelContent>

        <Composites.ActionSetHorizontal>
          <Checkbox
            value={this.state.doNotShowAgain}
            label="Notification_Box_Dont_Show_Again"
            labelAfterSymbol={true}
            onChange={this.onDoNotShowAgainClick}
          />
          <Button onClick={this.onClose} className="btn-md">
            <span>{translate('Notification_Box_Got_It')}</span>
          </Button>
        </Composites.ActionSetHorizontal>
      </FocusPanelFrame>
    );
  }
}

export default connect(
  EDITOR_API,
  attachToSOSPPanelMapper.mapStateToProps,
  attachToSOSPPanelMapper.mapDispatchToProps,
)(renderWhenMutated(AttachDetachSOSPPanel));

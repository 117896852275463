import { utils as coreUtils } from '#packages/core';
import { events } from '#packages/coreBi';
import { getSavedComponents } from '#packages/newAddPanelAPI';
import { createMyWidgetsSectionsAddPanelV2 as createMyWidgetsSections } from '#packages/platform';
import {
  bi,
  editorSearch,
  newAddPanel as newAddPanelStateManagement,
  relatedApps,
} from '#packages/stateManagement';
import {
  tpaAddPanelSectionMapDispatchToProps,
  tpaAppBoxMapDispatchToProps,
  liveChatSection,
} from '#packages/tpa';
import { hoc, workspace } from '#packages/util';
import type { NewAddPanelUtils } from '#packages/newAddPanelAPI';
import { liveComponentSectionUtils } from '#packages/addPanelInfra';
import type { PresetSectionComponentMetaData } from '@wix/add-panel-component';
import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import { BI_ORIGIN } from './constants';
import { blogWelcomeMapper } from './mappers/dispatch/blogWelcomeMapper';
import { bookingsWelcomeMapper } from './mappers/dispatch/bookingsWelcomeMapper';
import { contentManagerMapper } from './mappers/dispatch/contentManager';
import { contentManagerWelcomeMapper } from './mappers/dispatch/contentManagerWelcomeMapper';
import { dragMapper } from './mappers/dispatch/dragMapper';
import { eventsWelcomeMapper } from './mappers/dispatch/eventsWelcomeMapper';
import { fileShareWelcomeMapper } from './mappers/dispatch/fileShareWelcomeMapper';
import { formsMapper } from './mappers/dispatch/formsMapper';
import { imageMapper } from './mappers/dispatch/imageMapper';
import { forumWelcomeMapper } from './mappers/dispatch/forumWelcomeMapper';
import { groupsWelcomeMapper } from './mappers/dispatch/groupsWelcomeMapper';
import { onlineProgramsWelcomeMapper } from './mappers/dispatch/onlineProgramsWelcomeMapper';
import { pricingPlansWelcomeMapper } from './mappers/dispatch/pricingPlansWelcomeMapper';
import { installMapper } from './mappers/dispatch/installMapper';
import { membersWelcomeMapper } from './mappers/dispatch/membersWelcomeMapper';
import { openMapper } from './mappers/dispatch/openMapper';
import { pagesComponentsMapper } from './mappers/dispatch/pageMapper';
import { savedComponentsMapper } from './mappers/dispatch/savedComponentsMapper';
import { storesWelcomeMapper } from './mappers/dispatch/storesWelcomeMapper';
import { toggleDevModeMapper } from './mappers/dispatch/toggleDevModeMapper';
import {
  mergeMapDispatchToPropsFunctions,
  mergeMapStateToPropsFunctions,
  createLazyAccessor,
  gatherAddPanelTagIds,
} from './mappers/utils';
import type {
  AddPanelDispatchProps,
  AddPanelOwnProps,
  AddPanelStateProps,
} from './types';
import { installAppWelcomeMapper } from './mappers/dispatch/installAppMapper';

const mapStateToProps: MapStateToProps<
  AddPanelStateProps,
  AddPanelOwnProps
> = ({ state, editorAPI }) => {
  const myWidgetsSections = createMyWidgetsSections(editorAPI);

  const savedComponents = getSavedComponents(state);

  return {
    theme: editorAPI.theme as unknown as NewAddPanelUtils['theme'],
    isMobileEditor: editorAPI.isMobileEditor(),
    pathsState: newAddPanelStateManagement.selectors.getPathsState(state),
    panelManager: editorAPI.panelManager,
    savedComponents,
    myWidgets: myWidgetsSections,
    relatedApps: relatedApps.selectors.getRelatedApps(state),
    isEditorSearchButtonVisibleInLeftPanel:
      editorSearch.selectors.isEnabled(state),
    isEditorSearchButtonVisible:
      !workspace.isNewWorkspaceEnabled() &&
      editorSearch.selectors.isEnabled(state),
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  AddPanelDispatchProps,
  any
> = (dispatch) => {
  const editorAPI = dispatch(
    (_dispatch: AnyFixMe, _getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI,
  );

  return {
    getLiveChatSectionColors: () =>
      liveChatSection.getLiveChatSectionColors(editorAPI),
    getSuperAppData: (appDefinitionId) =>
      appDefinitionId
        ? editorAPI.dsRead.tpa.appMarket.getDataAsync(appDefinitionId)
        : null,
    hasSectionStyle: (section) =>
      liveComponentSectionUtils.hasStylesForSection(editorAPI, section),
    setPathsState: (pathsState) =>
      dispatch(newAddPanelStateManagement.actions.setPathsState(pathsState)),
    storePath: (structure, mediaManagerPreset) =>
      coreUtils.mediaManagerPresetUtil.storePathWithDispatch(
        dispatch,
        structure,
        mediaManagerPreset,
      ),
    handleSearchButtonClick: () => {
      dispatch(
        editorSearch.actions.openEditorSearchPanel({
          origin: BI_ORIGIN,
          shouldLeavePanelsOpen: true,
        }),
      );
      dispatch(bi.actions.event(events.editorSearch.addPanelButtonClicked));
    },
    fetchCategoriesRelatedApps: (categories) =>
      dispatch(
        relatedApps.actions.fetchRelatedAppsByTagIds(
          gatherAddPanelTagIds(categories),
        ),
      ),
    trackCategoryOpen: (category, origin, biParams) => {
      dispatch(
        bi.actions.event(events.addPanel.ADD_MENU_CATEGORY_SELECT, {
          category,
          origin,
          ...biParams,
        }),
      );
    },
    trackSectionOpen: (category, sectionName) => {
      dispatch(
        bi.actions.event(events.addPanel.ADD_MENU_SUB_CATEGORY_SELECT, {
          category,
          sub_category: sectionName,
        }),
      );
    },
    trackCategoryClick: (categoryId: string, biParams) => {
      dispatch(
        bi.actions.event(events.addPanel.ADD_MENU_CATEGORY_CLICK, {
          category: categoryId,
          ...biParams,
        }),
      );
    },
    trackSectionClick: (categoryId, sectionName) => {
      dispatch(
        bi.actions.event(events.addPanel.ADD_MENU_SUB_CATEGORY_CLICK, {
          category: categoryId,
          sub_category: sectionName,
        }),
      );
    },
    trackMaxScroll: (maxHoveredItem: PresetSectionComponentMetaData) => {
      dispatch(
        bi.actions.event(events.addPanel.ADD_MENU_MAX_CATEGORY_SCROLL, {
          category: maxHoveredItem.categoryId,
          section: `${maxHoveredItem.sectionIndex}_${maxHoveredItem.sectionName}`,
          max_hover_item: maxHoveredItem.itemIndex,
        }),
      );
    },
    trackHelpFromCategoryViewHeaderClick: (categoryId: string) => {
      dispatch(
        bi.actions.event(events.addPanel.ADD_MENU_HELP_CATEGORY_CLICK, {
          category: categoryId,
        }),
      );
    },
    fireComponentAdded: editorAPI.components.hooks.componentAddedToStage.fire,
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mergeMapStateToPropsFunctions(mapStateToProps),
  mergeMapDispatchToPropsFunctions(
    mapDispatchToProps,
    dragMapper,
    createLazyAccessor(() => tpaAppBoxMapDispatchToProps),
    createLazyAccessor(() => tpaAddPanelSectionMapDispatchToProps),
    installMapper,
    openMapper,
    imageMapper,
    contentManagerMapper,
    savedComponentsMapper,
    blogWelcomeMapper,
    bookingsWelcomeMapper,
    eventsWelcomeMapper,
    fileShareWelcomeMapper,
    formsMapper,
    membersWelcomeMapper,
    forumWelcomeMapper,
    groupsWelcomeMapper,
    onlineProgramsWelcomeMapper,
    pricingPlansWelcomeMapper,
    installAppWelcomeMapper,
    storesWelcomeMapper,
    contentManagerWelcomeMapper,
    toggleDevModeMapper,
    pagesComponentsMapper,
  ),
);

// @ts-nocheck
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_textbutton2',
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS),
    },
  ],
  enabledActions: [
    ACTIONS.LINK,
    ACTIONS.DESIGN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileMainActions: [],
  mobileEnabledActions: [
    ACTIONS.SCALE_DOWN,
    ACTIONS.SCALE_UP,
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    animation: {
      onClick: gfppDataUtils.getTogglePanelFn('animation', {
        helpId: helpIds.ANIMATION.CLOSE_BUTTON,
        customizeHelpId: helpIds.ANIMATION.CUSTOMIZE_CLOSE_ICON_BUTTON,
      }),
    },
    help: helpIds.UTILS.getGfppHelpIds(helpIds.COMP_TYPES.CLOSE_BUTTON),
  },
};

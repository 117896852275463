import { editorWixRecorder } from '#packages/util';

export async function getSessionRecordUrl({
  startTime,
}: {
  startTime: number;
}) {
  const recordUrl = await editorWixRecorder.getRecordUrl();

  return recordUrl ? recordUrl + encodeURIComponent(`:${startTime}`) : null;
}

import platformComponentPanel from './panels/platformComponentPanel';
import platformModalPanel from './panels/platformModalPanel';
import platformDashboardPanel from './panels/platformDashboardPanel';
import platformToolPanel from './panels/platformToolPanel';
import floatingToolPanel from './panels/floatingToolPanel';
import platformSidePanel from './panels/platformSidePanel';
import platformFullStagePanel from './panels/platformFullStagePanel';
import changeVariationsPanel from './panels/changeVariationsPanel';
import PlatformPanelApplicationFrame from './panels/PlatformPanelApplicationFrame';
import PlatformErrorPanel from './panels/errorPanel/errorPanel';
import PlatformConfirmationPanel from './panels/confirmationPanel/confirmationPanel';
import PlatformPromotionalPanel from './panels/promotionalPanel/promotionalPanel';
import platformManageContentPanel from './panels/manageContentPanel/manageContentPanel';
import PlatformUninstallConfirmationPanel from './panels/uninstallConfirmationPanel/uninstallConfirmationPanel';
import InstallerAppsPanelComponent from './panels/installerAppsPanel/installerAppsPanel';
import listParser from './panels/ListParser/listParser';
import PageVariantsPanel from './panels/pageReflowPanels/PageVariantsPanel';
import PlatformDeleteAppModal from './panels/deleteAppModal/deleteAppModal';

import type { PanelResolveType } from './panels/installerAppsPanel/installerAppsPanel';
import type { ErrorPanelResolveType } from './panels/errorPanel/errorPanel';
import type { PanelTag } from './panels/ListParser/listParser';
import type { ConfirmationPanelFootnote } from './panels/confirmationPanel/confirmationPanel';

export {
  platformComponentPanel as componentPanel,
  platformModalPanel as modalPanel,
  platformToolPanel as toolPanel,
  floatingToolPanel,
  platformFullStagePanel as fullStagePanel,
  platformSidePanel as sidePanel,
  PlatformPanelApplicationFrame,
  changeVariationsPanel,
  platformDashboardPanel as dashboardPanel,
  PlatformErrorPanel as platformErrorPanel,
  PlatformConfirmationPanel as platformConfirmationPanel,
  PlatformPromotionalPanel as platformPromotionalPanel,
  platformManageContentPanel,
  PlatformUninstallConfirmationPanel,
  PlatformDeleteAppModal,
  InstallerAppsPanelComponent,
  listParser,
  PageVariantsPanel,
  type PanelResolveType,
  type ErrorPanelResolveType,
  type PanelTag,
  type ConfirmationPanelFootnote,
};

import React from 'react';
import { connectWithScope } from '#packages/apilib';
import { Scope } from './scope';
import {
  mapStateToProps,
  mapDispatchToProps,
  type HighlightBoxesProps,
} from './resizeHighlightMapper';
import type { HighlightBox } from './types';
import { DATA_HOOKS } from './constants';
import styles from './resizeHighlight.scss';

const ResizeHighlight: React.FC<HighlightBoxesProps> = ({
  highlightedBoxes,
}) => {
  return (
    <>
      {highlightedBoxes.map((highlightedBox: HighlightBox) => (
        <div
          key={highlightedBox.id}
          data-comp-id={highlightedBox.id}
          data-hook={DATA_HOOKS.HIGHLIGHTED_BOX}
          style={highlightedBox.style}
          className={styles.highlightedBox}
        />
      ))}
    </>
  );
};

export const Component = connectWithScope(
  () => Scope,
  ResizeHighlight,
  mapStateToProps,
  mapDispatchToProps,
  { rerenderOnMouseOps: true },
);

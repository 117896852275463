// @ts-nocheck
import _ from 'lodash';
import { isDataModeOn } from '#packages/wixData';

const mapStateToProps = ({ editorAPI }, props) => {
  const pagesData = editorAPI.pages.getPagesData();
  const publicUrl = editorAPI.dsRead.generalInfo.getPublicUrl();
  const pageRef = editorAPI.components.get.byId(props.menuItem.pageData.id);
  const dataModeIsOn = isDataModeOn(editorAPI);
  let routerData = {};
  let routerRef;
  if (pageRef) {
    routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
    routerData = routerRef ? editorAPI.dsRead.routers.get.byRef(routerRef) : {};
  }
  return {
    pageRef,
    routerRef,
    dataModeIsOn,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    pageData: _.find(pagesData, { id: props.menuItem.pageData.id }),
    publicUrl,
    routerData,
  };
};

const getAppDataByAppDefId =
  (appDefId) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);

const mapDispatchToProps = (dispatch) => ({
  getAppDataByAppDefId(appDefId) {
    return dispatch(getAppDataByAppDefId(appDefId));
  },
});

export { mapStateToProps, mapDispatchToProps };

import React, { useState } from 'react';
import _ from 'lodash';
import { Composites, Divider, TextInput, TextLabel } from '@wix/wix-base-ui';
import CONSTANTS from '../../../../utils/constants';
import { PERMISSIONS_TAB_HOOKS } from '../../../../utils/dataHooks';

import type { InputType } from '../../../../utils/constants';

interface PasswordPermissionProps {
  onPasswordUpdate: (password: string) => void;
  plainPassword: string;
  isPageProtectedWithPassword: boolean;
}

const { INPUT_TYPES } = CONSTANTS;

export const PasswordPermission: React.FC<PasswordPermissionProps> = ({
  onPasswordUpdate,
  plainPassword,
  isPageProtectedWithPassword,
}) => {
  const [inputType, setInputType] = useState<InputType>('password');

  const isPasswordHidden = inputType === INPUT_TYPES.PASSWORD;
  const isPasswordEmpty = _.isEmpty(plainPassword);
  const shouldShowPasswordMockElement =
    isPasswordHidden && isPasswordEmpty && isPageProtectedWithPassword;

  const onPasswordFocus = () => {
    setInputType(INPUT_TYPES.TEXT);
  };

  const onPasswordBlur = (password: string) => {
    onPasswordUpdate(password);
    setInputType(INPUT_TYPES.PASSWORD);
  };

  const isPasswordValid = (password: string) => {
    return !_.isEmpty(password);
  };

  return (
    <>
      <Divider />
      <Composites.TextInputLabeled key="page-permissions-password-input">
        <TextLabel
          value="Pages_Permissions_Password_Choose"
          dataHook={PERMISSIONS_TAB_HOOKS.PASSWORD_INPUT_LABEL}
        />
        {shouldShowPasswordMockElement ? (
          <TextInput
            dataHook={PERMISSIONS_TAB_HOOKS.PASSWORD_INPUT_MOCK}
            key="passwordMockElement"
            onClick={onPasswordFocus}
            value={CONSTANTS.PASSWORD_TEMPLATE}
            type="password"
          />
        ) : (
          <TextInput
            dataHook={PERMISSIONS_TAB_HOOKS.PASSWORD_INPUT}
            key="visible-password-input"
            focus={!isPasswordHidden}
            onFocus={onPasswordFocus}
            onBlur={(event) => onPasswordBlur(event.target.value)}
            value={plainPassword}
            type={inputType}
            placeholder="NewPages_Panel_Permissions_Set_Password_InputField_Text"
            maxLength={CONSTANTS.PASSWORD_MAX_LENGTH}
            validator={(password) => isPasswordValid(password)}
            invalidMessage="Pages_Permissions_Password_Error_Tooltip"
          />
        )}
      </Composites.TextInputLabeled>
    </>
  );
};

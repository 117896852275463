import { BaseEntryScope } from '@/apilib';
import { EditorAPIKey, EditorCoreApiKey, PanelManagerApiKey } from '@/apis';
import { createAddonsPrivateApi } from './privateApi';

export class AddonsScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  panelManager = this.useDependency(PanelManagerApiKey);

  addonsPrivateApi = this.declareApi(createAddonsPrivateApi);
}

import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import { TextLabel } from '@wix/wix-base-ui';

interface TriggerLayoutProps {
  scaleY: number;
  dragging: boolean;
  navOffsetLeft?: number | string;
  negativeScaleAndSkewString: string;
  handleMouseDown: (event: React.MouseEvent) => void;
  exitMode: () => void;
  selectTrigger: () => void;
}

const TRIGGER_LABEL_HEIGHT = 26;
const TRIGGER_BORDER_WIDTH = 3;

export const TriggerLabel = ({
  dragging,
  navOffsetLeft,
  handleMouseDown,
  scaleY,
  exitMode,
  selectTrigger,
  negativeScaleAndSkewString = '',
}: TriggerLayoutProps) => {
  return (
    <div
      onMouseDown={handleMouseDown}
      className="trigger-label"
      style={{
        top: `-${
          Math.floor(TRIGGER_LABEL_HEIGHT / scaleY) + TRIGGER_BORDER_WIDTH
        }px`,
        marginLeft: navOffsetLeft || 0,
        opacity: dragging ? 0 : 1,
        transform: `${negativeScaleAndSkewString}`,
      }}
    >
      <div className="regular" onClick={exitMode}>
        <TextLabel
          type="T07"
          value="interactions_element_regular_trigger_label"
        />
      </div>
      <div className="interaction" onClick={selectTrigger}>
        <symbols.symbol name="interaction_light_small" />
        {translate('interactions_element_hover_trigger_label')}
      </div>
    </div>
  );
};

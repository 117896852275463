import React, { type FC } from 'react';
import { connectWithScope } from '#packages/apilib';
import AIAssistant from '../assistant/';
import { AIAssistantPanelScope } from './aiAssistantPanelScope';

import type { AIAssistantPanelProps } from './aiAssistantPanelTypes';
import { mapStateToProps, mapDispatchToProps } from './aiAssistantPanelMapper';

const AIAssistantPanel: FC<AIAssistantPanelProps> = ({
  assistantConfig,
  closeAiAssistant,
}) => {
  return (
    <AIAssistant config={assistantConfig} closeAiAssistant={closeAiAssistant} />
  );
};

export default connectWithScope(
  () => AIAssistantPanelScope,
  AIAssistantPanel,
  mapStateToProps,
  mapDispatchToProps,
);

import React from 'react';
import * as util from '#packages/util';
import * as baseUI from '#packages/baseUI';
import constants from '#packages/constants';
import DropshippingTooltip from './dropshippingTooltip';
import utils from '../utils';
import { translate } from '#packages/i18n';

interface IDropshippingTab {
  title: string;
  text: string;
  label: string;
  image: string;
  onClick: (e: unknown) => void;
}

class DropshippingTab extends React.Component<IDropshippingTab> {
  getTabDefinitions() {
    return {
      title: this.props.title,
      text: this.props.text,
      label: this.props.label,
      image: this.props.image,
    };
  }

  render() {
    return (
      <div key="dropshipping">
        <div className="premium">
          <baseUI.tooltip
            value={<DropshippingTooltip {...this.props} />}
            alignment={constants.UI.TOOLTIP.ALIGNMENT.BOTTOM}
            styleType={constants.UI.TOOLTIP.STYLE_TYPE.NORMAL}
            interactive={true}
            width="432px"
            id="dropshippingPopoverId"
          >
            <p onClick={this.props.onClick}>
              {translate('SiteApp_WixStores_FindProductsTab')}
            </p>
          </baseUI.tooltip>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ editorAPI }: AnyFixMe) => ({
  onClick: () => {
    utils.openStoreManager(editorAPI, 'FindProducts', {
      state: '/explore-products',
      origin: 'wixstores_menu_storemanager',
    });
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(DropshippingTab);

import platformConsts from '../common/constants';
import type { AddonsScope } from './scope';

export const createAddonsPublicApi = (scope: AddonsScope) => {
  const openAddonsMarketPanel = () => {
    scope.panelManager.openPanel(
      platformConsts.panelTypes.ADDONS_MARKET,
      {
        panelName: platformConsts.panelTypes.ADDONS_MARKET,
      },
      {
        panelLoader: () =>
          import('./AddonsMarketPanel').then((m) => m.AddonsPanel),
      },
    );
  };

  const getRegisteredAddons = () => {
    return scope.addonsPrivateApi.getRegisteredAddons();
  };

  return { openAddonsMarketPanel, getRegisteredAddons };
};

import _ from 'lodash';
import gfppDataUtils from './gfppDataUtils';
import { isEditorDataFunction } from '@wix/editor-component-data-resolver';
import * as stateManagement from '#packages/stateManagement';
import type { CompRef } from 'types/documentServices';
import type {
  ComponentGFPPDefinition,
  GFPPActionsDictionary,
  GFPPAction,
  GFPPActionType,
  GFPPData,
  GFPPDataOrResolver,
} from '@wix/editor-component-types';
import type { EditorAPI } from '#packages/editorAPI';
import type { EditorDataFunction } from '@wix/editor-component-data-resolver';

const { getEditorData } = stateManagement.components.selectors;

type GFPPActionKey = keyof GFPPAction;

const allowedMainActionKeys: GFPPActionKey[] = [
  'label',
  'isApplied',
  'isSelected',
  'helpId',
  'onClick',
  'automationId',
  'shouldTranslate',
];
const allowedPresetActionKeys: GFPPActionKey[] = [
  'icon',
  'tooltip',
  'onClick',
  'isSelected',
  'isDisabled',
  'tooltip',
];

const pickSupportedPresetActionKeys = (
  actionOverrides: GFPPActionsDictionary,
): GFPPActionsDictionary =>
  _.mapValues(actionOverrides, (presetActionDescriptor) =>
    _.pick(presetActionDescriptor, allowedPresetActionKeys),
  );

const mergeWithHelp =
  (helpId: string, mobileHelpId: string) =>
  (actionOverrides: GFPPActionsDictionary): GFPPActionsDictionary => {
    const sanitizedActions = pickSupportedPresetActionKeys(actionOverrides);
    if (helpId || mobileHelpId) {
      return _.defaults({ help: { helpId, mobileHelpId } }, sanitizedActions);
    }

    return sanitizedActions;
  };

const filterOverridesForEnabledActions =
  (enabledActions: GFPPActionType[], mobileEnabledActions: GFPPActionType[]) =>
  (actionOverrides: GFPPActionsDictionary): GFPPActionsDictionary => {
    const allEnabled = _.union(enabledActions, mobileEnabledActions);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.pickBy(actionOverrides, (val, key) => _.includes(allEnabled, key));
  };

const createPresetActions = (
  actionOverrides: GFPPActionsDictionary,
  helpId: string,
  mobileHelpId: string,
  enabledActions: GFPPActionType[],
  mobileEnabledActions: GFPPActionType[],
): GFPPActionsDictionary =>
  _.flow(
    filterOverridesForEnabledActions(enabledActions, mobileEnabledActions),
    pickSupportedPresetActionKeys,
    mergeWithHelp(helpId, mobileHelpId),
  )(actionOverrides);

const getEnabledActions = (actionTypes: GFPPActionType[]): GFPPActionType[] =>
  Array.isArray(actionTypes)
    ? actionTypes.filter((actionType) => typeof actionType === 'string')
    : [];

const createMainActions = (mainActions: GFPPAction[]): GFPPAction[] =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(mainActions, (actionDescriptor: GFPPAction) => {
    const supportedMainActionProperties: GFPPAction = _.pick(
      actionDescriptor,
      allowedMainActionKeys,
    );
    if (actionDescriptor.actionType) {
      return _.defaults(
        {
          onClick: gfppDataUtils.resolveOnClickAction(actionDescriptor),
        },
        supportedMainActionProperties,
      );
    }
    return supportedMainActionProperties;
  });

const wrapExternalGfppDef = (
  gfppDef: ComponentGFPPDefinition,
): GFPPDataOrResolver => {
  if (isEditorDataFunction(gfppDef.getGfppData)) {
    return (editorAPI: EditorAPI, compRefs: CompRef) =>
      wrapGfppData(
        getEditorData(
          gfppDef.getGfppData as EditorDataFunction,
          editorAPI,
          compRefs,
        ),
      );
  }
  return wrapGfppData(gfppDef.getGfppData() as GFPPData);
};

const wrapGfppData = (gfppData: GFPPData): GFPPData => {
  const enabledActions = getEnabledActions(gfppData.enabledActions);
  const mobileEnabledActions = getEnabledActions(gfppData.mobileEnabledActions);
  return {
    mainActions: createMainActions(gfppData.mainActions),
    mobileMainActions: createMainActions(gfppData.mobileMainActions),
    enabledActions,
    mobileEnabledActions,
    presetActions: createPresetActions(
      gfppData.actionOverrides,
      gfppData.helpId,
      gfppData.mobileHelpId,
      enabledActions,
      mobileEnabledActions,
    ),
    untranslatable: gfppData.untranslatable,
    previewState: gfppData.previewState,
  };
};

export default {
  wrapExternalGfppDef,
  createMainActions,
};

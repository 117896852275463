import React from 'react';
import { Button, Preloader, Text } from '@wix/wix-base-ui';
import { Text as NextText } from '@wix/wix-base-ui/next';
import { Add } from '@wix/wix-base-ui/icons';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import { PromoteScope } from '../../../scope';
import * as utils from '../common/utils';
import { Bullets } from '../common/bullets';
import promoteBi from '../common/bi/promoteBi';
import { fetchPromoteData } from '../../../store/initActions';

export interface OwnProps {}

type EmailMarketingPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

const TAB_NAME = 'EmailMarketingPanel';
const PRODUCT_NAME = 'EmailMarketing';

const EmailMarketingPanel = React.memo<EmailMarketingPanelProps>(
  (props: EmailMarketingPanelProps) => {
    if (props.dataFetchStatus === null) {
      props.fetchPromoteData();
    }
    const planName = props.planName;
    const shouldShowUpgradeButton = props.shouldShowUpgradeButton;

    React.useEffect(() => {
      if (props.dataFetchStatus === 'SUCCESS') {
        props.sendBi(promoteBi.events.TAB_VIEW, {
          ...props.biBaseParams,
          tab_name: TAB_NAME,
          product_name: PRODUCT_NAME,
        });
      }
    }, [props.dataFetchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    const onPressEM = () => {
      props.sendBi(promoteBi.events.SHOUTOUT_OPEN, {
        ...props.biEmailMarketingBaseParams,
        referral_info: 'editor_6th_button',
      });
      props.sendBi(promoteBi.events.PRODUCT_CLICK, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
      props.navigateToEmailMarketingSettings();
    };

    const onPressCompare = () => {
      props.sendBi(promoteBi.events.COMPARE_PLANS, {
        ...props.biEmailMarketingBaseParams,
        button_location: '6th_button',
        button_name: 'compare_plans',
        origin: 'editor',
      });
      props.navigateToCompare(props.msid);
    };

    const onPressCreateEmail = () => {
      props.sendBi(promoteBi.events.SHOUTOUT_COMPOSE, {
        ...props.biEmailMarketingBaseParams,
        referral_info: 'editor_6th_button',
      });
      props.sendBi(promoteBi.events.PRODUCT_CLICK, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
      props.navigateToCreateEmail();
    };

    const shouldShowPlanName = props.dataFetchStatus === 'SUCCESS';

    return (
      <div className="super-app-extra-panel">
        <div className="em-panel">
          <div className="em-body">
            <symbols.symbol name="promoteEmPanelLogo" className="em-logo" />
            <div className="em-title">
              <Text
                skin="standard"
                size="large"
                weight="bold"
                enableEllipsis={false}
              >
                {'Promote_Email_Marketing_Title'}
              </Text>
            </div>
            <Bullets
              bullets={[
                'Promote_Email_Marketing_Bullet1',
                'Promote_Email_Marketing_Bullet2',
                'Promote_Email_Marketing_Bullet3',
              ]}
            />
            <div className="em-buttons-container">
              <div>
                <Button
                  prefixIcon={<Add />}
                  onClick={onPressCreateEmail}
                  className="em-button btn-md"
                >
                  {translate('Promote_Email_Marketing_Main_Button')}
                </Button>
              </div>
              <div>
                <Button
                  onClick={onPressEM}
                  className="btn-text btn-sm em-text-button"
                >
                  {translate('Promote_Email_Marketing_Secondary_Button')}
                </Button>
              </div>
            </div>
          </div>
          <div className="em-footer">
            <div className="em-footer-left">
              <Text
                skin="secondary"
                size="tiny"
                enableEllipsis={false}
                customEllipsisProps={{ maxLines: 2 }}
              >
                {'Promote_Email_Marketing_Footer_Title'}
              </Text>
              {shouldShowPlanName ? (
                <NextText skin="secondary" size="small" weight="bold">
                  {planName}
                </NextText>
              ) : (
                <Preloader className="tiny" />
              )}
            </div>
            <div className="em-footer-right">
              {shouldShowUpgradeButton && (
                <Button
                  onClick={onPressCompare}
                  className="btn-confirm-secondary btn-md"
                  ellipsis={false}
                >
                  {translate('Promote_Email_Marketing_Footer_Button')}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

const mapStateToProps = (scope: PromoteScope) => {
  const biEmailMarketingBaseParams =
    promoteBi.biUtils.getBiEmailMarketingBaseParams(scope);

  const biBaseParams = promoteBi.biUtils.getBiBaseParams(scope);

  const planName = scope.store.getPackageName();
  const dataFetchStatus = scope.store.getFetchStatus();
  const shouldShowUpgradeButton = !scope.store.getIsTopTier();

  return {
    biBaseParams,
    biEmailMarketingBaseParams,
    planName,
    dataFetchStatus,
    msid: scope.editorAPI.dsRead.generalInfo.getMetaSiteId(),
    shouldShowUpgradeButton,
  };
};

const mapDispatchToProps = (scope: PromoteScope) => {
  return {
    fetchPromoteData: () => fetchPromoteData(scope),
    navigateToEmailMarketingSettings: () =>
      scope.editorAPI.account.openSettings({ tab: 'EmailMarketing' }),
    navigateToCreateEmail: () =>
      scope.editorAPI.account.openSettings({ path: '/shoutout?dl=message' }),
    navigateToCompare: (msid: string) =>
      utils.openEmailMarketingPackagePicker(scope.editorAPI, msid),
    sendBi: (event: ValueOf<typeof promoteBi.events>, parameters: object) => {
      promoteBi.report(event, parameters);
    },
  };
};

EmailMarketingPanel.displayName = 'EmailMarketingPanel';

export default connectWithScope(
  () => PromoteScope,
  EmailMarketingPanel,
  mapStateToProps,
  mapDispatchToProps,
);

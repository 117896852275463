import { mouseActions } from '#packages/stateManagement';

import { removeSectionsGaps } from './removeSectionsGaps';
import { createEnlargeLastSectionIfNeeded } from './enlargeLastSection';

import type { Scope } from '#packages/sections';
import type { ViewerChangesData } from 'types/core';
import type { CompRef } from 'types/documentServices';

const viewerLayoutChanges = [
  'components.layout',
  'renderPlugins.setCompsToShowOnTop',
];

export const enforceSectionsLayoutOnPage = (scope: Scope, pageRef: CompRef) => {
  const isPerformingMouseMoveAction =
    mouseActions.selectors.isPerformingMouseMoveAction(
      scope.editorAPI.store.getState(),
    );

  if (isPerformingMouseMoveAction) return;

  removeSectionsGaps(scope, pageRef);
};

const wasLayoutPotentiallyChanged = (changes: ViewerChangesData) =>
  changes?.some((change) =>
    viewerLayoutChanges.some((layoutChange) => change?.includes(layoutChange)),
  );

export const createSectionsEnforcementOnViewerChanges = (scope: Scope) => {
  const { editorAPI } = scope;
  const enlargeLastSectionIfNeeded = createEnlargeLastSectionIfNeeded(scope);

  // TODO: Talk with DM on a proper subscription API from their side
  editorAPI.tpa.dangerouslySubscribeToAppInstall('*', async () => {
    scope.enforceSectionOnPageService.run(editorAPI.pages.getCurrentPage());
  });

  return (changes: ViewerChangesData) => {
    if (!scope.enforcement.isEnforcementEnabled()) return;

    if (changes?.includes('pages.navigateTo')) {
      scope.enforceSectionOnPageService.run(editorAPI.pages.getCurrentPage());
    }

    if (wasLayoutPotentiallyChanged(changes)) {
      enforceSectionsLayoutOnPage(scope, editorAPI.pages.getCurrentPage());
    }

    enlargeLastSectionIfNeeded(changes);
  };
};

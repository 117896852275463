// @ts-nocheck
import _ from 'lodash';
import * as styles from '#packages/styles';

import React from 'react';
import AdvancedStylePanel from '../advancedStylePanelComponent';
import type { WidgetDesignProps } from './types';

const advancedStyleConstants =
  styles.advancedStyle.advancedStyleConstants.multiComponents;

class WidgetDesignPanelAdvancedStyleSection extends React.Component<WidgetDesignProps> {
  getStyle = () => this.props.style;

  getAdvancedStylingProps = () => {
    const { activeTab, componentAdapter } = this.props;
    const compType = componentAdapter.getType(activeTab);

    return _.merge({}, this.props, {
      selectedComponent: componentAdapter.getSelectedComponents(activeTab),
      shouldStartClosed: true,
      compType,
      selectedSkin: componentAdapter.getSkin(activeTab),
      skinDefinition: componentAdapter.getSkinDefinitionForTab(activeTab),
      getSkin: () => componentAdapter.getSkin(activeTab),
      configuration: componentAdapter.getConfiguration(activeTab),
      onStyleParamChanged: this.props.onStyleParamChanged,
      getStyle: this.getStyle,
      updateStyle: this.props.updateStyle,
      styleId: advancedStyleConstants.styleId,
      shouldTranslate:
        compType !== advancedStyleConstants.compType ||
        this.props.shouldTranslate,
      sliderProps: {
        max: 100,
        min: 0,
        units: 'px',
        step: 1,
      },
    });
  };

  render() {
    return (
      <div key="advancedStylePanelClass">
        <AdvancedStylePanel {...this.getAdvancedStylingProps()} />
      </div>
    );
  }
}

export default WidgetDesignPanelAdvancedStyleSection;

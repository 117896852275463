import { BasePublicApi } from '#packages/apilib';
import { browserUtil } from '#packages/util';
import { isPasteTargetAllowed } from '#packages/util';

import type { PasteClipboardDataToStageScope } from './pasteClipboardDataToStageEntryPoint';

let pasteFunctionToCall: () => void = () => {};

const setPasteFunctionToCall = (fn: () => void) => {
  pasteFunctionToCall = fn;
};

export const bootstrap = ({
  pasteHandlers,
}: PasteClipboardDataToStageScope) => {
  document.addEventListener('paste', async function (event: ClipboardEvent) {
    let dataPasted = false;

    if (browserUtil.isFirefox()) {
      pasteFunctionToCall();
      return;
    }

    if (!isPasteTargetAllowed(event)) {
      return;
    }

    pasteHandlers.forEach((handler) => {
      if (handler.validate && handler.handleUpload && handler.validate(event)) {
        handler.handleUpload(event);
        dataPasted = true;
      }
    });

    if (!dataPasted) {
      pasteFunctionToCall();
    }
  });
};

export class PasteClipboardDataToStageApi extends BasePublicApi<PasteClipboardDataToStageScope> {
  setPasteFunctionToCall = setPasteFunctionToCall;
}

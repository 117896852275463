import type React from 'react';
import { Edit } from '@wix/wix-ui-icons-common';

import { sectionClickOnAction } from '@wix/bi-logger-editor/v2';
import { translationUtils, biLogger } from '#packages/util';
import {
  EditorRestrictionsApiKey,
  QuickEditApiKey,
  SiteSegmentMediaBackgroundApiKey,
} from '#packages/apis';
import { gfppModel } from '#packages/gfppData';
import type { StageActionsOwnProps } from './StageActions';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { GFPPAction } from '@wix/editor-component-types';
import type { EditorAPI } from '#packages/editorAPI';
import experiment from 'experiment';

export interface StageAction {
  id: string;
  Icon?: React.JSXElementConstructor<any>;
  label?: string;
  onClick: () => void;
  dataHook: string;
  biName: string;
}

const QUICK_EDIT_ACTION_HOOK = 'section-stage-actions-quick-edit';
const WIDGET_ACTION_HOOK_PREFIX = 'section-stage-actions-widget-action';
const HEADER_DESIGN_ACTION_HOOK = 'section-stage-actions-header-design';
const HEIGHT_THRESHOLD = 48;
export const CONTAINER_MARGIN = 18;
export const STAGE_ACTION_BUTTON_HEIGHT = 30;

const isExperimentVariantForHeaderDesign =
  experiment.getValue('se_headerDiscoverability') === 'B' ||
  experiment.getValue('se_headerDiscoverability') === 'C';

export interface StageActionsStateProps {
  actions: StageAction[];
  shouldShow: boolean;
  horizontallyCentered: boolean;
  containerOffsetLeft: number;
}

export interface StageActionsDispatchProps {
  reportActionClicked: (action: StageAction) => void;
}

const STAGE_ACTIONS_ORIGIN = 'sections_stage_actions';

const getMainAction = (editorAPI: EditorAPI, compRef: CompRef): GFPPAction => {
  const compGfppData = gfppModel.getFullComponentGfppData(
    editorAPI,
    [compRef],
    STAGE_ACTIONS_ORIGIN,
  );

  return compGfppData.mainActions[0];
};

const getWidgetsActions = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): StageAction[] => {
  const widgetRefs = editorAPI.tpa.getUniqueChildrenWidgets(compRef);

  const widgetsActions = widgetRefs.reduce<StageAction[]>(
    (actions, widgetRef) => {
      const mainAction = getMainAction(editorAPI, widgetRef);
      if (!mainAction) return actions;

      actions.push({
        id: widgetRef.id,
        label: translationUtils.translateIfNeeded(mainAction.label),
        onClick: () => {
          editorAPI.selection.selectComponentByCompRef(widgetRef);
          mainAction.onClick(editorAPI, [widgetRef], STAGE_ACTIONS_ORIGIN);
        },
        biName: mainAction.label,
        dataHook: WIDGET_ACTION_HOOK_PREFIX,
      });

      return actions;
    },
    [],
  );

  return widgetsActions;
};

const getHeaderDesignActions = (editorAPI: EditorAPI, compRef: CompRef) => {
  const isHeader = editorAPI.sections.isHeader(compRef);

  const canShowDesignActions =
    isExperimentVariantForHeaderDesign &&
    isHeader &&
    editorAPI.isDesktopEditor() &&
    !editorAPI.zoomMode.isInZoomMode();

  if (!canShowDesignActions) return [];

  const headerMediaBackgroundEnabled = editorAPI.host
    .getAPI(SiteSegmentMediaBackgroundApiKey)
    .advancedSiteSegmentBackgroundEnabled(compRef);

  const headerDesignAction = {
    id: 'headerDesign',
    label: translationUtils.translateIfNeeded(
      'section_action_header_design_button',
    ),
    onClick: () => {
      const headerLayout =
        editorAPI.components.layout.getRelativeToStructure_rect(compRef);
      editorAPI.selection.selectComponentByCompRef(compRef);
      const stagePosition = editorAPI.ui.stage.getEditingAreaLayout();
      const panelTopPosition =
        stagePosition.top +
        headerLayout.y +
        2 * CONTAINER_MARGIN +
        STAGE_ACTION_BUTTON_HEIGHT;
      const panelLeftPosition =
        stagePosition.left + headerLayout.x + CONTAINER_MARGIN;
      editorAPI.panelManager.openComponentPanel('designPanel.designPanel', {
        origin: STAGE_ACTIONS_ORIGIN,
        onlyAdvancedStyling: headerMediaBackgroundEnabled,
        startWithAdvanced: headerMediaBackgroundEnabled,
        style: { top: panelTopPosition, left: panelLeftPosition },
      });
    },
    dataHook: HEADER_DESIGN_ACTION_HOOK,
    biName: 'headerDesign',
  };
  return [headerDesignAction];
};

export const mapStateToProps: MapStateToProps<
  StageActionsStateProps,
  StageActionsOwnProps
> = ({ editorAPI }, { compRef }) => {
  const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);

  const quickEditAction = {
    id: 'quickEdit',
    label: translationUtils.translateIfNeeded(
      'section_action_edit_section_button',
    ),
    Icon: Edit,
    onClick: () => {
      editorAPI.selection.selectComponentByCompRef(compRef);
      quickEditAPI.openPanel({
        rootControlCompRef: compRef,
        origin: STAGE_ACTIONS_ORIGIN,
      });
    },
    dataHook: QUICK_EDIT_ACTION_HOOK,
    biName: 'quickEdit',
  };

  const canShowQuickEdit =
    quickEditAPI.isQuickEditAvailable() &&
    editorRestrictionsApi.allowed('sections_quick-edit-stage-action.visible');
  const canShowWidgetActions =
    editorAPI.isDesktopEditor() &&
    !editorAPI.zoomMode.isInZoomMode() &&
    editorAPI.sections.isSection(compRef) &&
    editorRestrictionsApi.allowed('sections_widget-stage-action.visible');

  const defaultActions: StageAction[] = [canShowQuickEdit && quickEditAction];

  const widgetsActions = canShowWidgetActions
    ? getWidgetsActions(editorAPI, compRef)
    : [];

  const headerDesignActions = getHeaderDesignActions(editorAPI, compRef);

  const actions = defaultActions
    .concat(widgetsActions)
    .concat(headerDesignActions)
    .filter(Boolean);

  const compHeight =
    editorAPI.components.layout.get_size(compRef).height *
    editorAPI.getSiteScale();

  return {
    actions,
    shouldShow: actions.length > 0,
    horizontallyCentered: compHeight <= HEIGHT_THRESHOLD,
    containerOffsetLeft:
      (editorAPI.scroll.get().scrollLeft || 0) + CONTAINER_MARGIN,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  StageActionsDispatchProps,
  StageActionsOwnProps
> = (dispatch, { compRef: sectionLikeRef }) => {
  const editorAPI = dispatch(getEditorAPI);
  const [defaultComponentFields] = editorAPI.bi.getComponentsBIParams([
    sectionLikeRef,
  ]);

  return {
    reportActionClicked: (action) => {
      const appIds = editorAPI.tpa
        .getUniqueChildrenWidgets(sectionLikeRef)
        .map(
          (ref: CompRef) => editorAPI.components.data.get(ref)?.appDefinitionId,
        )
        .filter(Boolean);

      biLogger.report(
        sectionClickOnAction({
          ...defaultComponentFields,
          action_name: action.biName,
          origin: STAGE_ACTIONS_ORIGIN,
          app_id: appIds.join(','),
          panel_name: action.id,
        }),
      );
    },
  };
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import React from 'react';
import * as UA from '#packages/uiAnimations';
import TopBarButton from './buttons/topBarButton/topBarButton';
import { cx } from '#packages/util';

const { getDeviceType } = stateManagement.leftBar.selectors;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'zoomModeBar',
  mixins: [core.mixins.editorAPIMixin],

  exitZoomMode() {
    const editorAPI = this.getEditorAPI();
    editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'legacyTopBar' } });

    const state = editorAPI.store.getState();
    editorAPI.store.dispatch(
      stateManagement.bi.actions.event(coreBi.events.editor.ZOOM_MODE_CLOSE, {
        editor_view_mode: getDeviceType(state),
      }),
    );

    window.setTimeout(function () {
      editorAPI.toolsControl.toggleHideTools(false, true);
    }, 300);
  },

  inZoomMode() {
    return this.getEditorAPI().zoomMode.isInZoomMode();
  },

  callUndo() {
    this.getEditorAPI().history.undo();
  },

  callRedo() {
    this.getEditorAPI().history.redo();
  },

  getSiteScale() {
    return this.getEditorAPI().getSiteScale();
  },

  openHelp() {
    const helpId =
      this.getEditorAPI().viewMode.get() === 'DESKTOP'
        ? '00d369ed-aedf-4fbd-b11c-3fcc51459cef'
        : '65ff699a-dc0c-4c42-b2af-8df5e022d350';
    this.getEditorAPI().panelManager.openHelpCenter(helpId, null, {
      origin: constants.BI.HELP.ORIGIN.TOPBAR,
      component: 'zoomModeBar',
      panel_name: constants.BI.HELP.ORIGIN.TOPBAR,
    });
  },

  getTooltipData(id, text) {
    return {
      presenter: baseUI.popoverTemplates.titleBodyAndLinkTooltip,
      id,
      props: {
        text,
      },
    };
  },

  getVerticalScrollWidth() {
    return util.uiUtils.getScrollbarWidth() === 0 ? '0px' : '6px';
  },

  render() {
    return (
      <UA.contentSlideCSS distance="60px">
        {this.inZoomMode() ? (
          <div
            key="zoomModeBarWrapper"
            ref="zoomModeBarWrapper"
            style={{
              width: `calc(${`${
                this.getSiteScale() * 100
              }% - ${this.getVerticalScrollWidth()}`})`,
              left: `calc(${`50% - ${this.getVerticalScrollWidth()}`})`,
            }}
            className="zoom-bar-wrapper"
          >
            <div key="zoom-mode-bar" className={cx({ 'zoom-mode-bar': true })}>
              <div
                onClick={() => {
                  this.exitZoomMode();
                }}
                className="left-zoom-bar"
              >
                <span className="zoom-bar-text">
                  {translate('TOPBAR_Exit_ZoomOut')}
                </span>
                <TopBarButton
                  iconSymbol="zoomClose"
                  classes="zoom-bar-symbol"
                  actions={[this.exitZoomMode]}
                />
              </div>
              <div className="right-zoom-bar">
                <TopBarButton
                  iconSymbol="undo2"
                  classes="zoom-bar-btn-seperator top-bar-item-margin"
                  actions={[this.callUndo]}
                  tooltipData={this.getTooltipData(
                    'topBarUndoTooltipID',
                    'TOPBAR_UNDO_TOOLTIP_TITLE',
                  )}
                />
                <TopBarButton
                  iconSymbol="redo2"
                  classes="zoom-bar-btn-seperator top-bar-item-margin"
                  actions={[this.callRedo]}
                  tooltipData={this.getTooltipData(
                    'topBarRedoTooltipID',
                    'TOPBAR_REDO_TOOLTIP_TITLE',
                  )}
                />
                <TopBarButton
                  iconSymbol="zoomBarHelp"
                  classes="zoom-bar-btn"
                  actions={[this.openHelp]}
                  tooltipData={this.getTooltipData(
                    'zoomBarHelpTooltipID',
                    'gfpp_tooltip_help',
                  )}
                />
              </div>
            </div>
          </div>
        ) : null}
      </UA.contentSlideCSS>
    );
  },
});

// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.THEME,
  hide: false,
  title: 'add_section_label_theme_menu',
  subNavigationTitle: 'add_section_label_theme_menu',
  presetTitle: 'add_section_label_theme_menu',
  tooltipTitle: 'add_section_label_theme_menu',
  automationId: 'add-panel-section-menuThemeSection',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  },
  props: {
    compTypes: ['wysiwyg.viewer.components.menus.DropDownMenu'],
    verticalMargin: 30,
    horizontalMargin: 20,
    maxFontSize: 15,
    scaleProportion: {
      x: 1,
      y: 1,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_theme_general',
  },
};

import { isRefComponent, isRepeater } from '#packages/documentServices';
import { components } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import { renderUtils } from '#packages/platform';

const canComponentBeShownInLayersPanel = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) =>
  renderUtils.isComponentVisibleInLayersPanel(editorAPI, compRef) &&
  components.selectors.getIsComponentVisibleInCurrentMode(
    editorAPI.dsRead,
    compRef,
    editorAPI.store.getState(),
  );

const isRenderedAndVisible = (editorAPI: EditorAPI, compRef: CompRef) =>
  canComponentBeShownInLayersPanel(editorAPI, compRef) &&
  editorAPI.components.isRenderedOnSite(compRef);

function* replaceWithChildrenIfComponentIsSkipped_GENERATOR(
  editorAPI: EditorAPI,
  compRef: CompRef,
): Generator<CompRef> {
  if (editorAPI.components.is.skipInLayersPanel(compRef)) {
    if (editorAPI.components.is.shouldShowChildrenInLayersPanel(compRef)) {
      for (const childCompRef of editorAPI.components.getChildrenOrScopedChildren(
        compRef,
      )) {
        if (!isRenderedAndVisible(editorAPI, childCompRef)) {
          continue;
        }
        yield* replaceWithChildrenIfComponentIsSkipped_GENERATOR(
          editorAPI,
          childCompRef,
        );
      }
    }
    return;
  }
  yield compRef;
}

/**
 * This generator is used to get all the children of a component that should be shown in the layers panel.
 * ⚠️ WARN: It could be quite heavy in some cases so use only when you realy need component childs.
 *
 * It will skip components:
 * - that are marked as skipped in the layers panel (⚠️and replace with children if possible).
 * - are marked as ghost or hidden in the current mode.
 * - children of components marked as skipped, which are not rendered on the site at the moment of check.
 */
export function* getComponentChildrenToShowInLayersPanel_GENERATOR(
  editorAPI: EditorAPI,
  compRef: CompRef,
): Generator<CompRef, void, CompRef> {
  if (!editorAPI.components.is.shouldShowChildrenInLayersPanel(compRef)) {
    return;
  }

  for (const childCompRef of editorAPI.components.getChildrenOrScopedChildren(
    compRef,
  )) {
    if (!canComponentBeShownInLayersPanel(editorAPI, childCompRef)) {
      continue;
    }

    yield* replaceWithChildrenIfComponentIsSkipped_GENERATOR(
      editorAPI,
      childCompRef,
    );
  }
}

export const hasComponentChildrenToShowInLayersPanel = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  if (
    isRefComponent(editorAPI.dsRead, compRef) ||
    isRepeater(editorAPI.dsRead, compRef)
  ) {
    return true;
  }

  const childrenGenerator = getComponentChildrenToShowInLayersPanel_GENERATOR(
    editorAPI,
    compRef,
  );

  const hasChildrenToShow = !childrenGenerator.next().done;
  return hasChildrenToShow;
};

import * as stateManagement from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export function createInteractionsStore({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const store = editorAPI.store;
  const interactionsActions = stateManagement.interactions.actions;
  const interactionsSelectors = stateManagement.interactions.selectors;

  function stopPlayInteraction(compRef: CompRef) {
    store.dispatch(interactionsActions.stopPlayInteraction(compRef));
  }

  function exitInteraction() {
    store.dispatch(interactionsActions.exitInteraction());
  }

  function onInteractionSelectedComponentsChanged(compRef: CompRef) {
    store.dispatch(
      interactionsActions.onInteractionSelectedComponentsChanged(compRef),
    );
  }

  function isInteractionPlaying() {
    return interactionsSelectors.isInteractionPlaying(store.getState());
  }

  function isInInteractionMode() {
    return interactionsSelectors.isInInteractionMode(store.getState());
  }

  function showInteractionModeControls() {
    return interactionsSelectors.showInteractionModeControls(store.getState());
  }

  function getVariantId() {
    return interactionsSelectors.getVariantId(store.getState());
  }

  function getInteractionTriggerRef() {
    return interactionsSelectors.getInteractionTriggerRef(store.getState());
  }

  function getFirstAncestorWithInteraction(compRef: CompRef) {
    return interactionsSelectors.getFirstAncestorWithInteraction(
      editorAPI,
      compRef,
    );
  }

  return {
    stopPlayInteraction,
    exitInteraction,
    onInteractionSelectedComponentsChanged,
    isInteractionPlaying,
    isInInteractionMode,
    showInteractionModeControls,
    getVariantId,
    getInteractionTriggerRef,
    getFirstAncestorWithInteraction,
  };
}

export type InteractionsStore = ReturnType<typeof createInteractionsStore>;

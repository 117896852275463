import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import type { EditorAPI } from '#packages/editorAPI';

const keyboardMap = keyboardShortcutsActionMap.interactions;
const shortcuts: { [key: string]: () => void } = {};

export default {
  init(editorAPI: EditorAPI) {
    shortcuts[keyboardMap.EXIT_INTERACTION.key] = () => {
      editorAPI.componentFocusMode.exit({ shouldRestorePrevState: true });
    };
  },
  shortcuts,
};

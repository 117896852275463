// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';

function getAppName({ store }) {
  return stateManagement.applicationStudio.selectors.getAppName(
    store.getState(),
  );
}

function getAppNamespace({ store }) {
  return stateManagement.applicationStudio.selectors.getNamespace(
    store.getState(),
  );
}

function getAllSerializedWidgets({ dsRead }) {
  return dsRead.appStudio.widgets.getAllSerialized();
}

function getAllSerializedCustomDefinitions({ dsRead }) {
  return dsRead.appStudio.definitions.getAllSerialized();
}

const appBuilder = {
  appInfo: {
    getName: getAppName,
    getNamespace: getAppNamespace,
  },
  widgets: {
    getAllSerialized: getAllSerializedWidgets,
  },
  definitions: {
    getAllSerialized: getAllSerializedCustomDefinitions,
  },
};

export { appBuilder };

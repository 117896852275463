import { layoutTransitionsUtil } from '#packages/layoutUtils';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import { BaseResizeAndPushApiKey, EditorAPIKey } from '#packages/apis';

import type { CompRef, CompLayout } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { Shell } from '#packages/apilib';

const { translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;

export const createClassicSectionResizeAndPushApi = (shell: Shell) => {
  let compToResize: CompRef;
  let lastFWE: CompRef;
  let lastFWEInitialLayout: CompLayout;
  let sectionLike: CompRef;
  let treshholdPassed = false;
  let sectionLikeResizeTransition: InstanceType<
    typeof layoutTransitionsUtil.Resize
  >;
  let sectionLikeInitialAbsLayout: CompLayout;
  let sectionWidth: number;
  let lastFWEIntialBottom: number;
  let waitForCompSwitch = false;
  let sectionLikeHeightSnapToFWEBottom: number;
  let siteScale: number;

  const editorAPI = shell.getAPI(EditorAPIKey);
  const baseResizeAndPushApi = shell.getAPI(BaseResizeAndPushApiKey);

  const getSortedFWEsWithLayout = (container: CompRef) =>
    editorAPI.components
      .getChildren(container)
      .filter(editorAPI.components.is.fullWidth)
      .map((comp) => ({
        ref: comp,
        layout: editorAPI.components.layout.getRelativeToScreen(comp),
      }))
      .sort((a, b) => a.layout.y - b.layout.y);

  const getLastFWEOverride = (component: {
    ref: CompRef;
    layout: CompLayout;
  }) => {
    if (
      editorAPI.components.is.verticallyResizableByChildren([component.ref])
    ) {
      return getSortedFWEsWithLayout(component.ref).pop();
    }

    return component;
  };

  const getLastFWE = (sectionLike: CompRef) => {
    const topLevelSortedFWEs = getSortedFWEsWithLayout(sectionLike);

    return topLevelSortedFWEs.length
      ? getLastFWEOverride(topLevelSortedFWEs.pop())
      : null;
  };

  const getMousePosition = (event: React.MouseEvent) => {
    const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
    return { x: mouseCoordinates.pageX, y: mouseCoordinates.pageY };
  };

  const isMousePassedTreshhold = (
    currentSectionLikeBottom: number,
    nextSectionLikeBottom: number,
    lastFWEIntialBottom: number,
    treshholdSize: number = 40,
  ) => {
    const isSectionSnappedToFWE =
      lastFWEIntialBottom >= currentSectionLikeBottom;

    return (
      isSectionSnappedToFWE &&
      nextSectionLikeBottom <= lastFWEIntialBottom - treshholdSize
    );
  };

  function startResize(
    _editorAPI: EditorAPI,
    params: { evt: React.MouseEvent; comps: CompRef[] },
  ) {
    const { evt, comps } = params;

    const mouse = getMousePosition(evt);

    sectionLike = comps[0];

    const lastFWECompWithLayout = getLastFWE(sectionLike);

    if (lastFWECompWithLayout) {
      compToResize = sectionLike;

      lastFWE = lastFWECompWithLayout.ref;

      lastFWEInitialLayout = lastFWECompWithLayout.layout;

      lastFWEIntialBottom =
        lastFWEInitialLayout.height + lastFWEInitialLayout.y;

      sectionWidth = editorAPI.components.layout.get_size(sectionLike).width;

      sectionLikeInitialAbsLayout =
        editorAPI.components.layout.getRelativeToScreen(sectionLike);

      const isLastFWEStripColumn =
        editorAPI.components.getType(lastFWECompWithLayout?.ref) ===
        constants.COMP_TYPES.COLUMN;

      const fweRelativeToSectionLayout = editorAPI.components.layout.get_rect(
        isLastFWEStripColumn
          ? editorAPI.components.getContainer(lastFWE)
          : lastFWE,
      );

      sectionLikeHeightSnapToFWEBottom =
        fweRelativeToSectionLayout.y + fweRelativeToSectionLayout.height;

      siteScale = stateManagement.domMeasurements.selectors.getSiteScale(
        editorAPI.store.getState(),
      );

      sectionLikeResizeTransition = new layoutTransitionsUtil.Resize(
        sectionLikeInitialAbsLayout,
        mouse,
        { x: 0, y: 1 },
        false,
        siteScale,
      );
    }

    baseResizeAndPushApi.start(_editorAPI, params);
  }

  async function onResize(event: React.MouseEvent) {
    event?.persist?.();

    if (!lastFWE) return baseResizeAndPushApi.on(event);

    if (waitForCompSwitch) return;

    const mouse = getMousePosition(event);

    const currentSectionLikeLayout =
      editorAPI.components.layout.getRelativeToScreen(sectionLike);

    const currentSectionLikeBottom =
      currentSectionLikeLayout.y + currentSectionLikeLayout.height;

    const nextSectionLikeLayout = sectionLikeResizeTransition.getLayout(mouse);

    const nextSectionLikeBottom =
      nextSectionLikeLayout.y + nextSectionLikeLayout.height;

    const prevCompToResize = compToResize;

    const isPrevCompSection =
      editorAPI.sections.isSectionLike(prevCompToResize);

    treshholdPassed =
      treshholdPassed ||
      isMousePassedTreshhold(
        currentSectionLikeBottom,
        nextSectionLikeBottom,
        lastFWEIntialBottom,
      );

    if (treshholdPassed) {
      if (
        isPrevCompSection &&
        currentSectionLikeBottom === lastFWEIntialBottom &&
        nextSectionLikeBottom < lastFWEIntialBottom
      ) {
        compToResize = lastFWE;
      }

      if (!isPrevCompSection && nextSectionLikeBottom > lastFWEIntialBottom) {
        compToResize = sectionLike;
      }
    }

    const compToResizeChanged = prevCompToResize.id !== compToResize.id;

    if (!compToResizeChanged) return baseResizeAndPushApi.on(event);

    const isCurrentCompSectionLike = !isPrevCompSection;

    waitForCompSwitch = true;

    const layoutOverride = {
      height: isCurrentCompSectionLike
        ? lastFWEInitialLayout.height
        : sectionLikeHeightSnapToFWEBottom,
      width: isCurrentCompSectionLike
        ? lastFWEInitialLayout.width
        : sectionWidth,
    };

    baseResizeAndPushApi.on(event, layoutOverride);

    await editorAPI.waitForChangesAppliedAsync();

    await baseResizeAndPushApi.end(event, {
      dontAddToUndoRedoStack: true,
    });

    await editorAPI.waitForChangesAppliedAsync();

    if (isCurrentCompSectionLike) {
      sectionLikeResizeTransition = new layoutTransitionsUtil.Resize(
        {
          ...sectionLikeInitialAbsLayout,
          height: sectionLikeHeightSnapToFWEBottom,
        },
        {
          x: mouse.x * siteScale,
          y: lastFWEIntialBottom * siteScale,
        },
        { x: 0, y: 1 },
        false,
        siteScale,
      );
    }

    baseResizeAndPushApi.start(editorAPI, {
      evt: event,
      comps: [compToResize],
      initalMouseOverride: {
        x: mouse.x * siteScale,
        y: lastFWEIntialBottom * siteScale,
      },
    });

    waitForCompSwitch = false;
  }

  async function endResize(event: React.MouseEvent) {
    event?.persist?.();

    if (!lastFWE) return baseResizeAndPushApi.end(event);

    await editorAPI.waitForChangesAppliedAsync();
    await baseResizeAndPushApi.end(event);

    lastFWE = null;
    sectionLike = null;
    treshholdPassed = false;
    waitForCompSwitch = false;
    lastFWEInitialLayout = null;
    lastFWEIntialBottom = null;
    sectionLikeResizeTransition = null;
    sectionLikeHeightSnapToFWEBottom = null;
    sectionWidth = null;
    compToResize = null;
  }

  return {
    start: startResize,
    on: onResize,
    end: endResize,
    type: constants.MOUSE_ACTION_TYPES.CLASSIC_SECTION_RESIZE_PUSH,
  };
};

// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import inlinePopupToggleGfppData from './inlinePopupToggleGfppData';
import * as util from '#packages/util';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const inlinePopupToggleGfppDataResolved = inlinePopupToggleGfppData;
const inlinePopupToggleMobileEnabledActions =
  inlinePopupToggleGfppDataResolved?.mobileEnabledActions ?? [];

export default _.merge({}, inlinePopupToggleGfppDataResolved, {
  mobileEnabledActions(editorAPI, compRef) {
    const actions = [];
    if (editorAPI.components.is.showLegacyFixedPosition(compRef)) {
      actions.push(ACTIONS.SETTINGS);
    }
    return _.union(actions, inlinePopupToggleMobileEnabledActions);
  },
  presetActions: {
    [ACTIONS.SETTINGS]: {
      isSupported: (editorAPI, compRefs) =>
        !util.inlinePopupUtils.isTargetOpen(editorAPI, _.head(compRefs)),
    },
  },
});

import React, { useEffect } from 'react';
import _ from 'lodash';
import { Preloader } from '@wix/wix-base-ui';
import {
  APPS_EXCLUDED_FROM_INSTALLATION_IN_MY_BUSINESS_PANEL,
  LITE_MODE_FILTERED_APPS,
} from '../constants';
import { fedopsLogger, hoc, hooks } from '#packages/util';

import { AUTOMATION_IDS } from '../automationIds';
import { BI_EVENTS_IDS } from '../biEvents';

import { getWixApps } from '../services/appMarket/appMarket';
import { prepareSegmentsAppsData } from '../services/dealer/dealer.segmentApps';

import { MyBusinessView } from '../views/MyBusiness/myBusiness';
import { withPanelFrame } from '../PanelFrame/PanelFrame';

import { mapStateToProps, mapDispatchToProps } from './AppManagerPanel.mapper';

import type { DealerSegmentContent } from '../services/dealer/dealer.segmentContent';
import type { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import type { RegularAppData } from '../services/appMarket/appMarket.types';
import type { BiEventFields } from 'types/bi';

import styles from './AppManagerPanel.scss';

const { LOAD, FETCH_DEALER_INFO, FETCH_WIX_APPS, FETCH_DEALER_SEGMENT_APPS } =
  fedopsLogger.INTERACTIONS.APP_MANAGER;

export interface AppManagerPanelOwnProps {
  segmentContent: DealerSegmentContent;
  isLoadingSegmentContent: boolean;
  errorFromSegmentContent?: AmbassadorHTTPError;
  selectedAppId?: string;
  selectedAppFromUrl?: string;
  setHelpParams({ helpId, origin }: { helpId: string; origin: string }): void;
  openHelpCenter: (helpId: string, biHelpParams?: BiEventFields) => void;
  closePanel(): void;
}

export type AppManagerPanelProps = AppManagerPanelOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const AppManagerPanelComponent: React.FC<AppManagerPanelProps> = ({
  segmentContent,
  isLoadingSegmentContent,
  errorFromSegmentContent,
  metaSiteInstance,
  selectedAppId,
  selectedAppFromUrl,
  hasInstalledApps,
  sendBi,
  checkIsDescriptorExists,
  isPendingApp,
  supportedApps,
  availableAppsToUpdate,
  isAppInstalled,
  loadAndTransformSegmentApps,
  loadAndTransformAppPageOverrides,
  openHelpCenter,
  isLiteMode,
}) => {
  const [
    dealerData,
    { isLoading: isLoadingDealer, error: errorFromLoadDealerData },
  ] = hooks.useRequest(
    () => loadAndTransformAppPageOverrides(metaSiteInstance),
    {
      fedopsInteractionName: FETCH_DEALER_INFO,
      initialData: {},
    },
  );

  const [allApps, { isLoading: isLoadingWixApps, error: errorFromGetWixApps }] =
    hooks.useRequest(
      () =>
        getWixApps(supportedApps).then((app) =>
          app
            .map((app) => {
              const shouldFilterApp =
                (isLiteMode && LITE_MODE_FILTERED_APPS.includes(app.id)) ||
                !app.isPublished;
              if (shouldFilterApp) {
                return null;
              }
              return {
                ...app,
                isPending: isPendingApp(app.id),
                isInstalled: isAppInstalled(app.id),
                isUpdateAvailable: availableAppsToUpdate.includes(app.id),
              };
            })
            .filter(Boolean),
        ),
      {
        fedopsInteractionName: FETCH_WIX_APPS,
        initialData: [],
      },
    );

  const [
    segmentApps,
    { isLoading: isLoadingSegmentApps, error: errorFromLoadSegmentApps },
  ] = hooks.useRequest(() => loadAndTransformSegmentApps(metaSiteInstance), {
    fedopsInteractionName: FETCH_DEALER_SEGMENT_APPS,
    initialData: [],
  });

  const hasSegmentApps = segmentApps.length > 0;

  const getApps = (): RegularAppData[] => {
    const availableApps = allApps.filter((app) =>
      app.isInstalled ? checkIsDescriptorExists(app.id) : true,
    );

    if (hasSegmentApps) {
      return prepareSegmentsAppsData(availableApps, segmentApps);
    }

    return availableApps;
  };

  const availableApps = getApps();

  const [installedOrPendingApps, uninstalledApps] = _.partition(
    availableApps,
    (app) => app.isInstalled || app.isPending,
  );

  const moreApps = uninstalledApps.filter(
    ({ id }) =>
      !APPS_EXCLUDED_FROM_INSTALLATION_IN_MY_BUSINESS_PANEL.includes(id),
  );

  const error =
    errorFromLoadDealerData ||
    errorFromGetWixApps ||
    errorFromLoadSegmentApps ||
    errorFromSegmentContent;

  if (error) {
    throw error;
  }

  // `hasInstalledApps` is only `true` if the site has one of managed apps
  // installed, so if it is `true` and `installedOrPendingApps` is empty, it
  // means that the apps' manifests haven't been loaded yet, so we keep the
  // Preloader spinning until at least one manifest is loaded
  const isLoadingAppManifests =
    hasInstalledApps && installedOrPendingApps.length === 0;

  const isLoadingData =
    isLoadingWixApps ||
    isLoadingDealer ||
    isLoadingSegmentApps ||
    isLoadingSegmentContent ||
    isLoadingAppManifests;

  useEffect(
    () => {
      if (isLoadingData) {
        fedopsLogger.interactionStarted(LOAD);
        return;
      }

      fedopsLogger.interactionEnded(LOAD);
      const initialApp = availableApps.find(
        (app) => app.id === selectedAppId || app.slug === selectedAppFromUrl,
      );

      const biParams = {
        isSegment: true,
        tab_name: initialApp?.id,
        segment_info: installedOrPendingApps.map((app) => app.id),
      };

      sendBi(BI_EVENTS_IDS.PANEL_LOADED, biParams);
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoadingData],
  );

  if (isLoadingData) {
    return (
      <div className={styles.loaderWrapper}>
        <Preloader className="large" />
      </div>
    );
  }

  return (
    <div className={styles.content} data-aid={AUTOMATION_IDS.PANEL_CONTENT}>
      <MyBusinessView
        apps={installedOrPendingApps}
        moreApps={moreApps}
        initialSelectedAppId={selectedAppId}
        segmentContent={segmentContent}
        openHelpCenter={openHelpCenter}
        dealerData={dealerData}
      />
    </div>
  );
};

const connect = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
);

export const AppManagerPanel = connect(
  withPanelFrame(AppManagerPanelComponent),
);

export const TRANSLATIONS = {
  DEV: {
    DESCRIPTION:
      'wixCode.publishPipeline.codeErrors.modal.devModeOn.description',
    TITLE: 'wixCode.publishPipeline.codeErrors.modal.devModeOn.title',
    PRIMARY_CTA:
      'wixCode.publishPipeline.codeErrors.modal.devModeOn.PrimaryCTA',
    SECONDARY_CTA:
      'wixCode.publishPipeline.codeErrors.modal.devModeOn.SecondaryCTA',
    SKIP_STEP_CHECKBOX:
      'wixCode.publishPipeline.codeErrors.modal.devModeOn.skipStepCheckbox',
  },
  NON_DEV: {
    DESCRIPTION_1:
      'wixCode.publishPipeline.codeErrors.modal.devModeOff.description1',
    DESCRIPTION_2:
      'wixCode.publishPipeline.codeErrors.modal.devModeOff.description2',
    TITLE: 'wixCode.publishPipeline.codeErrors.modal.devModeOff.title',
    PRIMARY_CTA:
      'wixCode.publishPipeline.codeErrors.modal.devModeOff.PrimaryCTA',
    SECONDARY_CTA:
      'wixCode.publishPipeline.codeErrors.modal.devModeOff.SecondaryCTA',
    SKIP_STEP_CHECKBOX:
      'wixCode.publishPipeline.codeErrors.modal.devModeOff.skipStepCheckbox',
  },
};

export const PANEL_NAME = 'savePublish.panels.publish.publishErrorPanel';
export const HELP_ID = '3f9cb24d-f15d-4ae6-9bca-3c3ac25a5917';
export const PUBLISH_ERROR_PANEL_DEV = 'PublishErrorPanelDev';
export const PUBLISH_ERROR_PANEL_NON_DEV = 'PublishErrorPanelNonDev';
export const dataHooks = {
  publishErrorDevPanel: 'publish-error-dev-panel',
  publishErrorNonDevPanel: 'publish-error-non-dev-panel',
  publishAnyway: 'publish-error-publish-anyway',
  openBuildLog: 'publish-error-open-build-log',
};

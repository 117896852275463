import { ProgressBar, Text } from '@wix/wix-base-ui';
import styles from './styles.scss';
import React, { useState, type FC, useEffect, useRef } from 'react';
import { translate } from '#packages/i18n';

const LoaderOnStage: FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const progressRef = useRef<number>(progress);
  const requestRef = useRef<NodeJS.Timeout>();

  progressRef.current = progress;

  const animate = () => {
    if (progressRef.current === 98) return;
    setProgress((curProgress) => curProgress + 1);
    requestRef.current = setTimeout(animate, 100);
  };

  useEffect(() => {
    requestRef.current = setTimeout(animate, 100);
    return () => clearTimeout(requestRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.panel} data-hook="ai-writer-loader">
      <Text weight="thin" shouldTranslate={false}>
        {translate('ai_text_to_page_loader_step_one_text')}
      </Text>
      <ProgressBar progress={progress} theme="ai" hideText />
    </div>
  );
};

export default LoaderOnStage;

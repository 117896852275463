import type { MigrationOrigin } from './constants';

export enum MigrationFlow {
  Template = 'template',
  ADI = 'adi',
  Editor = 'editor',
  Editor2Fix = 'editor2Fix',
  Editor2Anchors = 'editor2Anchors',
  HeavySite = 'heavySite',
  PerPageADI = 'perPage_adi',
  PerPageEditor = 'perPage_editor',
}

export interface ForceMigrationOptions {
  origin: MigrationOrigin.SiteMenu | MigrationOrigin.TopBarBanner;
}

export enum PageMigrationStatus {
  SUCCESS = 'success',
  ALREADY_MIGRATED = 'already_migrated',
}

export interface PageMigrationResult {
  status: PageMigrationStatus;
}

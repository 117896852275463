import { translate } from '#packages/i18n';
import {
  HELP_CTX,
  QUICK_EDIT_PANEL_HELP_ID,
  RENAME_CTX,
  SETTINGS_CTX,
} from './consts';
import { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';
import { reportClickNoComp } from './quickEditBi';
import {
  getCurrentEditedSection,
  openComponentPanelDrillIn,
} from './quickEditUtils';

import type { QuickEditScope } from './quickEditEntryPoint';
import type { ContextMenuConfig } from '#packages/workspaceRightPanel';

const getLabel = (id: string) => `edit_section_panel_menu_${id}_label`;

const getHelpAction = (scope: QuickEditScope) => ({
  id: HELP_CTX,
  icon: HELP_CTX,
  label: getLabel(HELP_CTX),
  onClick: () => {
    reportClickNoComp(scope, HELP_CTX, 'drop_down');
    scope.editorAPI.panelManager.openHelpCenter(QUICK_EDIT_PANEL_HELP_ID);
  },
});

const getRenameSectionAction = (scope: QuickEditScope) => ({
  id: RENAME_CTX,
  icon: 'renameAction',
  label: getLabel(RENAME_CTX),
  onClick: () => {
    const sectionRef = getCurrentEditedSection(scope.editorAPI);
    reportClickNoComp(scope, RENAME_CTX, 'drop_down');
    scope.store.setCurrentEditedCompId(sectionRef.id);
    scope.store.setComponentPanelType(QuickEditControlActions.SETTINGS);
    openComponentPanelDrillIn(
      scope,
      translate('edit_section_panel_menu_rename_label'),
    );
  },
});

const getSettingsAction = (scope: QuickEditScope) => ({
  id: SETTINGS_CTX,
  icon: 'settings',
  label: 'edit_section_settings_tooltip',
  onClick: () => {
    const compRef = getCurrentEditedSection(scope.editorAPI);
    reportClickNoComp(scope, SETTINGS_CTX, 'drop_down');
    scope.store.setCurrentEditedCompId(compRef.id);
    scope.store.setComponentPanelType(QuickEditControlActions.SETTINGS);
    openComponentPanelDrillIn(
      scope,
      translate('edit_section_settings_tooltip'),
    );
  },
});

export const getContextMenuConfig = (
  scope: QuickEditScope,
): ContextMenuConfig => {
  return {
    onContextMenuOpen: () => reportClickNoComp(scope),
    items: [getRenameSectionAction(scope), getHelpAction(scope)],
  };
};

export const getHeaderFooterContextMenuConfig = (
  scope: QuickEditScope,
): ContextMenuConfig => {
  return {
    onContextMenuOpen: () => reportClickNoComp(scope),
    items: [getSettingsAction(scope), getHelpAction(scope)],
  };
};

import { fedopsLogger } from '#packages/util';

import { createFallbackPagesMenu } from './utils';
import { createMenuApi } from '../../API/menuAPI';
import { PAGES_MENU_ID } from '../../constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { MenuItem } from 'types/documentServices';
import { getFallbackMenu } from './utils';

const {
  INTERACTIONS: { CUSTOM_MENUS_PAGES_PANEL_REVERT },
} = fedopsLogger;

export const revertTransformation = (menuItems: MenuItem[]): MenuItem[] =>
  menuItems.reduce((acc: MenuItem[], menuItem) => {
    const children = menuItem.items;
    const childWithSameName = children.find(
      (childItem) => childItem.label === menuItem.label,
    );

    if (!menuItem.link && children.length > 1 && childWithSameName) {
      return [
        ...acc,
        {
          ...childWithSameName,
          items: menuItem.items.filter(
            (subItem) => subItem.id !== childWithSameName.id,
          ),
        },
      ];
    }

    return [...acc, menuItem];
  }, []);

export const revertFixPagesMenuSubItems = (editorAPI: EditorAPI) => {
  const menuAPI = createMenuApi(editorAPI);

  if (
    editorAPI.language.multilingual.isEnabled() ||
    getFallbackMenu(editorAPI)
  ) {
    return;
  }

  // update for main language
  fedopsLogger.interactionStarted(
    CUSTOM_MENUS_PAGES_PANEL_REVERT.MIGRATION_FOR_MAIN_MENU,
  );

  const pagesMenu = menuAPI.getMenu(PAGES_MENU_ID);
  createFallbackPagesMenu(editorAPI);

  const newItems = revertTransformation(pagesMenu.items);
  menuAPI.replaceMenuItems(PAGES_MENU_ID, newItems);

  fedopsLogger.interactionEnded(
    CUSTOM_MENUS_PAGES_PANEL_REVERT.MIGRATION_FOR_MAIN_MENU,
  );
};

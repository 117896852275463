import React from 'react';
import * as _util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

const util: any = _util;

interface ISavedComponentsWelcomeProps {
  welcomeSectionTemplate: React.ElementType;
  bannerImgSrc: string;
  openHelpCenter: () => void;
  fetchCollection: () => void;
}

class SavedComponentsWelcome extends React.Component<ISavedComponentsWelcomeProps> {
  componentDidMount() {
    this.props.fetchCollection();
  }

  render() {
    const { welcomeSectionTemplate, bannerImgSrc, openHelpCenter } = this.props;
    const WelcomeSectionTemplate = welcomeSectionTemplate;

    return (
      <WelcomeSectionTemplate
        bannerImgSrc={bannerImgSrc}
        title="add_section_MyDesigns_empty_state_title"
        subtitle="add_section_MyDesigns_empty_state_subtitle"
        automationId="add-panel-section-savedComponentsWelcomeSection"
        numericListItems={[
          'add_section_MyDesigns_empty_state_list1',
          'add_section_MyDesigns_empty_state_list2',
          'add_section_MyDesigns_empty_state_list3',
          'add_section_MyDesigns_empty_state_list4',
        ]}
        secondaryActionLabel="add_section_MyDesigns_empty_state_link"
        onSecondaryActionClick={openHelpCenter}
      />
    );
  }
}

function getBannerSrc() {
  const locale = util.editorModel.languageCode;
  let bannerSrc = `addPanelData/sections/savedComponentsWelcomeSection_${locale}/savedComponentsWelcomeSection_${locale}.jpg`;

  // TODO: check translations
  if (util.workspace.isNewWorkspaceEnabled()) {
    bannerSrc = util.browserUtil.isRetina()
      ? 'addPanelData/sections/savedComponentsWelcomeSection_en/newWorkspace_savedComponentsWelcomeSection_en@x2.png'
      : 'addPanelData/sections/savedComponentsWelcomeSection_en/newWorkspace_savedComponentsWelcomeSection_en.png';
  }

  return util.media.getMediaUrl(bannerSrc);
}

const mapStateToProps = ({ editorAPI }: AnyFixMe) => ({
  bannerImgSrc: getBannerSrc(),
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  fetchCollection() {
    dispatch(stateManagement.savedComponents.actions.fetchCollection());
  },

  openHelpCenter() {
    const helpId = 'a7fcc82b-b766-4a66-ac2d-b1648bbbdb46';
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId));
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SavedComponentsWelcome);

import type { EditorAPI } from '#packages/editorAPI';
import _ from 'lodash';
import constants from '#packages/constants';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import experiment from 'experiment';

const register = (editorAPI: EditorAPI) => {
  const superAppId = 'promote';
  const isAppInstalled = true;
  const superAppPanelName = constants.SUPER_APPS.PROMOTE_PANEL_NAME;
  const superAppButtonLabel = 'Promote_BusinessTools_6thButton_Label';
  const superAppButtonPromotionalLabel = 'SiteApp_Promote_Button_Hover_Caption';
  const superAppPanelClassName = 'promote-panel';
  const superAppSymbolName = 'leftBarIconMarketing';
  const siteIsPublished = _.invoke(
    editorAPI,
    'dsRead.generalInfo.isSitePublished',
  );

  const showPromoteButtonOnNotPublishedExpEnabled = experiment.isOpen(
    'se_wixPromote_showAscendButtonOnNotPublishedSite',
  );
  let buttonRegistered = false;

  const shouldRegisterPromoteButton =
    showPromoteButtonOnNotPublishedExpEnabled || siteIsPublished;

  if (shouldRegisterPromoteButton) {
    registerButton(false);
  } else {
    editorAPI.registerSitePublishedCallbacks(onPublished);
  }

  function onPublished() {
    return !buttonRegistered && registerButton(true);
  }

  function registerButton(isButtonOpen: boolean) {
    superAppMenuInfra.button.registerButton(
      editorAPI,
      superAppId,
      isAppInstalled,
      superAppPanelName,
      superAppButtonLabel,
      superAppButtonPromotionalLabel,
      superAppPanelClassName,
      superAppSymbolName,
      isButtonOpen,
    );
    buttonRegistered = true;
  }
};

export { register };

// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import coreUtilsLib from 'coreUtilsLib';

const { blogAppPartNames } = coreUtilsLib;

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_recentposts',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: 'blog/sections/recentPostsSection.png',
    imageHover: null,
    items: [
      {
        id: 'Blog_Recent_Posts_1',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.AppPartSkin',
          layout: {
            width: 210,
            height: 605,
            x: -84,
            y: 5,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixapps.integration.components.AppPart',
          data: {
            type: 'AppPart',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            appInnerID: '12',
            appPartName: blogAppPartNames.POSTS_LIST,
            viewName: 'PostsListMediaBottom',
            appLogicCustomizations: [
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaBottom',
                key: 'comp.style',
                value: 'Body L',
                fieldId: 'title',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaBottom',
                key: 'comp.hidden',
                value: 'false',
                fieldId: 'dateShow',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaBottom',
                key: 'comp.max-chars',
                value: '50',
                fieldId: 'title',
                format: '',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaBottom',
                key: 'postSpacing',
                value: '20',
                fieldId: 'vars',
                format: '',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaBottom',
                key: 'comp.size',
                value: '5',
                fieldId: 'MediaSpace',
                format: '',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaBottom',
                key: 'layout.spacerBefore',
                value: '0',
                fieldId: 'date',
                format: '',
              },
            ],
            appLogicParams: {},
          },
          props: {
            type: 'AppPartProperties',
            metaData: { schemaVersion: '1.0' },
            direction: 'ltr',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'blog_f72fe377-8abc-40f2-8656-89cfe00f3a22_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              groups: {},
              properties: { rd: '7px' },
              propertiesSource: { rd: 'value' },
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.AppPartSkin',
          },
          id: 'i74vepwl',
        },
        preset: {
          rect: { width: 162, height: 357, x: 0, y: 0 },
          label: '',
          tags: null,
        },
      },
      {
        id: 'Blog_Recent_Posts_2',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.AppPartSkin',
          layout: {
            width: 210,
            height: 188,
            x: 196,
            y: 15,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixapps.integration.components.AppPart',
          data: {
            type: 'AppPart',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            appInnerID: '12',
            appPartName: blogAppPartNames.POSTS_LIST,
            viewName: 'PostsListMediaLeft',
            appLogicCustomizations: [
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaLeft',
                key: 'comp.style',
                value: 'Body L',
                fieldId: 'title',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaLeft',
                key: 'comp.style',
                value: 'Body S',
                fieldId: 'date',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaLeft',
                key: 'comp.max-chars',
                value: '50',
                fieldId: 'title',
                format: '',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaLeft',
                key: 'postSpacing',
                value: '15',
                fieldId: 'vars',
                format: '',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Post',
                view: 'PostsListMediaLeft',
                key: 'layout.width',
                value: '70',
                fieldId: 'MediaSize',
                format: '',
              },
            ],
            appLogicParams: {},
          },
          props: {
            type: 'AppPartProperties',
            metaData: { schemaVersion: '1.0' },
            direction: 'ltr',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'blog_f72fe377-8abc-40f2-8656-89cfe00f3a22_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              groups: {},
              properties: { rd: '7px' },
              propertiesSource: { rd: 'value' },
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.AppPartSkin',
          },
          id: 'i73881qh',
        },
        preset: {
          rect: { width: 162, height: 177, x: 162, y: 0 },
          label: '',
          tags: null,
        },
      },
    ],
    compTypes: ['wixapps.integration.components.AppPart'],
  },
  help: { hide: false, text: 'add_section_info_text_recentposts' },
};

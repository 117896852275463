import * as textControls from '#packages/textControls';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import {
  text as stateManagementText,
  selection,
} from '#packages/stateManagement';

interface LinkData {
  type: string;
  subject?: string;
  id: string;
  pageId: string;
  target: string;
}

const getLinksHelper = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  getHelper: Function,
) => {
  const helper = getHelper();

  if (helper) return helper;

  const componentData = editorAPI.components.data.get(compRef);
  const currentLinks = componentData.linkList;

  return new (textControls.LinksHelper as AnyFixMe)(currentLinks, []);
};

function fixEmailSubject(linkData: LinkData) {
  if (linkData.type === 'EmailLink' && !linkData.subject) {
    linkData.subject = '';
  }
}

const execCommand = (
  editorAPI: EditorAPI,
  commandName: string,
  params?: boolean,
) => {
  // @ts-expect-error
  const { store, dispatch, text } = editorAPI;
  const isEditingRepeatedTextComp = text.isEditingRepeatedTextComp();
  const textManager = text.getCurrentTextManager();
  const state = store.getState();
  const widgetDesignSelectedComps =
    stateManagementText.selectors.getWidgetDesignSelectedComponents(state);
  const selectedComps =
    widgetDesignSelectedComps?.length > 0 ||
    selection.selectors.getSelectedCompsRefs(state);

  // @ts-expect-error
  if (selectedComps.length > 1) {
    return dispatch(
      // @ts-expect-error
      stateManagementText.actions.multiselect.execCmd(commandName, params),
    );
  }

  textManager.execCommand(commandName, params);

  const shouldApplyChangesToRepeatedText =
    textManager?.isAllSelected() &&
    isEditingRepeatedTextComp &&
    !['wixLink', 'wixUnlink'].includes(commandName);

  if (shouldApplyChangesToRepeatedText) {
    dispatch(
      stateManagementText.actions.invisibleTextEditor.execCmd({
        isRepeated: true,
        data: {
          commandName,
          commandData: params,
        },
      }),
    );
  }
};

interface Params {
  shouldCloseParentPanels?: boolean;
  removeOverlay?: boolean;
  linkUpdate?: boolean;
}

export function openTextLinkPanel(
  editorAPI: EditorAPI,
  params: Params = {} as Params,
) {
  const {
    shouldCloseParentPanels = false,
    removeOverlay = false,
    linkUpdate = false,
  } = params;

  const [compRef] = editorAPI.selection.getSelectedComponents();
  const {
    actions: { closeTextEditor },
    selectors: { isLinkPanelOpen, isTextEditorOpen },
  } = stateManagementText;
  const {
    store: { dispatch, getState },
    text: { getCurrentTextManager, getCurrentLinksHelper },
    components: { data },
  } = editorAPI;
  const { wixLink } = getCurrentTextManager().getCommandsStates();

  const linksHelper = getLinksHelper(editorAPI, compRef, getCurrentLinksHelper);
  const currentLink = linksHelper.getLink(wixLink);

  editorAPI.openLinkPanel(
    {
      link: currentLink,
      callback: (linkData: LinkData, prevLink: LinkData) => {
        let newLinkId;

        if (linkData) {
          fixEmailSubject(linkData);
          newLinkId = linksHelper.saveLink(linkData);

          execCommand(editorAPI, 'wixLink', newLinkId);
        } else if (prevLink) {
          execCommand(editorAPI, 'wixUnlink');
        }

        data.update(compRef, {
          text: getCurrentTextManager().getData(),
          linkList: linksHelper.getAllLinks(),
        });

        return newLinkId;
      },
      removeOverlay,
      getCurrentTextManager,
      getCurrentLinksHelper,
      linkUpdate,
      onUnmountCallback: () => {
        const state = getState();

        if (
          shouldCloseParentPanels &&
          isLinkPanelOpen(state) &&
          isTextEditorOpen(state)
        ) {
          dispatch(closeTextEditor());
        }
      },
    },
    shouldCloseParentPanels,
  );
}

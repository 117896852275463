// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox, Composites, PanelHeader } from '@wix/wix-base-ui';

import constants from '#packages/constants';
import * as higherOrderComponents from '#packages/higherOrderComponents';
import * as stateManagement from '#packages/stateManagement';
import * as baseUI from '#packages/baseUI';
import { cx, propTypesFilterMixin } from '#packages/util';

import disableKeyboardShortcutsMixin from '../../mixins/disableKeyboardShortcutsMixin';
import PanelCloseOrigin from '../panelCloseOrigin';

const PANEL_TYPES = ['danger'];

// OldMessagePanelFrame is a legacy component, use ./MessagePanelFrame instead
// eslint-disable-next-line react/prefer-es6-class
const OldMessagePanelFrame = createReactClass({
  displayName: constants.PANEL_TYPES.MESSAGE,
  mixins: [propTypesFilterMixin, disableKeyboardShortcutsMixin],
  propTypes: {
    dataHook: PropTypes.string,
    panelName: PropTypes.string.isRequired,
    panelTitle: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    onConfirm: PropTypes.func,
    confirmLabel: PropTypes.string,
    keyboardShortcuts: PropTypes.object,
    shouldExtendDefaultShortcuts: PropTypes.bool,
    dontShowAgain: PropTypes.bool,
    toggleDontShowAgain: PropTypes.func.isRequired,
    showCancelBtn: PropTypes.bool,
    cancelLabel: PropTypes.string,
    onCancel: PropTypes.func,
    close: PropTypes.func,
    dontShowAgainKey: PropTypes.string,
    onDontShowAgainChange: PropTypes.func,
    type: PropTypes.oneOf(PANEL_TYPES),
    footerText: PropTypes.object,
    shouldCloseOnConfirm: PropTypes.bool,
    shouldCloseOnCancel: PropTypes.bool,
    confirmDisabled: PropTypes.bool,
    cancelDisabled: PropTypes.bool,
    onHelp: PropTypes.func,
  },
  getInitialState() {
    return {
      buttonWasClicked: false,
    };
  },
  getDefaultProps() {
    return {
      // TODO: Replace with translation key
      confirmLabel: 'messagePanelFrame_Confirm',
      shouldCloseOnConfirm: true,
      shouldCloseOnCancel: true,
    };
  },
  getPanelProps() {
    return {
      style: {
        width: this.props.width,
        height: this.props.height,
      },
      className: this.props.className,
    };
  },
  _closeAndCall(func, origin) {
    if (!this.state.buttonWasClicked) {
      this.setState({ buttonWasClicked: true });
      this.props.close(origin);
      if (func) {
        func(origin);
      }
    }
  },
  onConfirm() {
    if (this.props.confirmDisabled) {
      return;
    }
    if (!this.props.shouldCloseOnConfirm) {
      return this.props.onConfirm();
    }
    this._closeAndCall(this.props.onConfirm, PanelCloseOrigin.CONFIRM_BUTTON);
  },
  onCancel(origin) {
    if (this.props.cancelDisabled) {
      return;
    }
    if (!this.props.shouldCloseOnCancel) {
      return this.props.onCancel();
    }
    this._closeAndCall(this.props.onCancel, origin);
  },
  onDontShowAgainChange(dontShowAgain) {
    this.props.onDontShowAgainChange(dontShowAgain);
    this.props.toggleDontShowAgain(dontShowAgain);
  },

  render() {
    return (
      <div className="panel-frame-container screen-centered-frame message-panel-frame-z-index">
        <div className="dark-frame-overlay" />
        <div
          {...this.getPanelProps()}
          data-hook={this.props.dataHook}
          className={cx(
            `composite-popup composite-popup-small-size close-font-message message-panel-frame ${
              this.props.type ? this.props.type : ''
            }`,
            this.getPanelProps()?.className,
          )}
        >
          <PanelHeader
            onClose={() => this.onCancel(PanelCloseOrigin.HEADER_CLOSE_BUTTON)}
            onHelp={this.props.onHelp}
            noHelpBtn={!this.props.onHelp}
            className="dark"
          >
            {this.props.panelTitle ? (
              <span key="headerTitle">{this.props.panelTitle}</span>
            ) : null}
          </PanelHeader>

          <Composites.PanelContent>
            {this.props.children}
          </Composites.PanelContent>

          <div className="composite-action-set-horizontal">
            {this.props.footerText ? (
              <div key="footerText" className="footer-text">
                {this.props.footerText}
              </div>
            ) : null}
            {this.props.dontShowAgainKey && this.props.userPrefType ? (
              <Checkbox
                key="dont-show-again-toggle"
                labelAfterSymbol={true}
                label="Notification_Box_Dont_Show_Again"
                value={this.props.dontShowAgain}
                onChange={this.onDontShowAgainChange}
                className="dont-show-again"
              />
            ) : null}
            {this.props.cancelLabel ? (
              <baseUI.button
                automationId="message-panel-frame-cancel-btn"
                key="btn-confirm-secondary"
                disabled={this.props.cancelDisabled}
                onClick={() => this.onCancel(PanelCloseOrigin.DONE_BUTTON)}
                label={this.props.cancelLabel}
                className="btn-confirm-secondary secondary"
              />
            ) : null}
            <baseUI.button
              disabled={this.props.confirmDisabled}
              automationId="message-panel-frame-confirm-btn"
              onClick={this.onConfirm}
              label={this.props.confirmLabel}
              className="btn-confirm-primary primary"
            />
          </div>
        </div>
      </div>
    );
  },
});

const ConnectedMessagePanelFrame = higherOrderComponents.linkUserPreferences()(
  stateManagement.panels.hoc.panelFrame(constants.PANEL_TYPES.MESSAGE)(
    OldMessagePanelFrame,
  ),
);

ConnectedMessagePanelFrame.pure = OldMessagePanelFrame;

export default ConnectedMessagePanelFrame;

import _ from 'lodash';

import { editorModel } from '#packages/util';
import { translate } from '#packages/i18n';
import {
  createSchemasAPI,
  createSchema,
  updateSchema,
  addField,
  setPermissions,
  getPermissionsByPreset,
  PRESETS,
  setDisplayName,
} from '#packages/contentManagerUtils';

import { createWixCodeCollectionsAPI } from '../wixCodeCollectionsAPI';
import { TRANSLATIONS } from '../constants';
import {
  provisionWixCodeIfNeeded,
  enableDataMode,
  openContentManagerPanelIfNeeded,
  refreshSchemasCacheForDataBindingApp,
} from '../utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { convertNewsItems } from './convertNewsItems';

export const exportNewsToContentManager = async (
  editorAPI: EditorAPI,
  { sections, news }: ReturnType<typeof convertNewsItems>,
) => {
  editorAPI.panelHelpers.updateProgressBar(
    0,
    translate(TRANSLATIONS.PROGRESS_BAR.STEP_1),
  );

  await provisionWixCodeIfNeeded(editorAPI);

  enableDataMode(editorAPI);

  editorAPI.panelHelpers.updateProgressBar(
    1,
    translate(TRANSLATIONS.PROGRESS_BAR.STEP_2),
  );

  const schemasAPI = createSchemasAPI(editorAPI.wixCode.fileSystem);

  const timestamp = Date.now();
  const newsSchemaId = `news-${timestamp}`;
  const sectionsSchemaId = `news-sections-${timestamp}`;

  const createNewsSchemaOperations = createSchema(
    newsSchemaId,
    setDisplayName(translate('news_content_collection_items_name')),
    addField('title', {
      type: 'text',
      displayName: translate('news_content_collection_items_field_title'),
    }),
    addField('description', {
      type: 'richtext',
      displayName: translate('news_content_collection_items_field_description'),
    }),
    addField('createdDate', {
      type: 'datetime',
      displayName: translate(
        'news_content_collection_items_field_created_date',
      ),
    }),
    addField('createdBy', {
      type: 'text',
      displayName: translate(
        'news_content_collection_items_field_created_by_text',
      ),
    }),
    addField('createdByLink', {
      type: 'text',
      displayName: translate(
        'news_content_collection_items_field_created_by_link',
      ),
    }),
    addField('image', {
      type: 'image',
      displayName: translate('news_content_collection_items_field_image'),
    }),
    setPermissions(getPermissionsByPreset(PRESETS.CONTENT_FOR_YOUR_SITE)),
  );

  const createReferencesForNewsSchema = updateSchema(
    newsSchemaId,
    addField('sections', {
      type: 'multi-reference',
      displayName: translate('news_content_collection_sections_name'),
      referencingFieldKey: 'sections',
      referencingDisplayName: translate(
        'news_content_collection_sections_name',
      ),
      referencedCollection: sectionsSchemaId,
    }),
  );

  const createSectionsSchemaOperations = createSchema(
    sectionsSchemaId,
    setDisplayName(translate('news_content_collection_sections_name')),
    addField('title', {
      type: 'text',
      displayName: translate('news_content_collection_sections_field_name'),
    }),
    setPermissions(getPermissionsByPreset(PRESETS.CONTENT_FOR_YOUR_SITE)),
  );

  const createReferencesForSectionsSchema = updateSchema(
    sectionsSchemaId,
    addField('sections', {
      type: 'multi-reference',
      displayName: translate('news_content_collection_items_name'),
      referencingFieldKey: 'sections',
      referencingDisplayName: translate('news_content_collection_items_name'),
      referencedCollection: newsSchemaId,
    }),
  );

  await schemasAPI.execute(createNewsSchemaOperations);
  await schemasAPI.execute(createSectionsSchemaOperations);
  await schemasAPI.execute(createReferencesForNewsSchema);
  await schemasAPI.execute(createReferencesForSectionsSchema);

  await refreshSchemasCacheForDataBindingApp(editorAPI);

  const collectionsAPI = createWixCodeCollectionsAPI(editorAPI);

  const newsItems = news.map((newsItem) => {
    return {
      _owner: editorModel.permissionsInfo.ownerId,
      title: newsItem.title,
      image: newsItem.image,
      createdDate: {
        $date: newsItem.createdDate,
      },
      createdBy: newsItem.createdBy,
      createdByLink: newsItem.createdByLink,
      description: newsItem.description,
      sections: newsItem.sections,
    };
  });

  await collectionsAPI.bulkInsert(
    sectionsSchemaId,
    sections.map((section) => {
      return {
        _owner: editorModel.permissionsInfo.ownerId,
        _id: section.id,
        title: section.title,
      };
    }),
  );

  const result = await collectionsAPI.bulkInsert(newsSchemaId, newsItems);

  await Promise.all(
    _.flatten(
      result.insertedItemIds.map((id: AnyFixMe, index: AnyFixMe) => {
        const newsItem = newsItems[index];

        return newsItem.sections.map((sectionId) => {
          return collectionsAPI.insertReference(
            newsSchemaId,
            'sections',
            id,
            sectionId,
          );
        });
      }),
    ),
  );

  editorAPI.panelHelpers.updateProgressBar(
    2,
    translate(TRANSLATIONS.PROGRESS_BAR.STEP_3),
  );

  // Display last message for a while
  await new Promise((resolve) => setTimeout(resolve, 1000));

  openContentManagerPanelIfNeeded(editorAPI);
};

import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as BaseUI from '#packages/baseUI';
import * as stateManagement from '#packages/stateManagement';

import type { CompRef, CompLayout } from 'types/documentServices';
import type { MapStateToProps, StateMapperArgs } from 'types/redux';

const { isOpen: isPinModeOpen } = stateManagement.pinMode.selectors;
const { getSelectedCompsRefs, getFocusedContainer } =
  stateManagement.selection.selectors;

const {
  connect,
  STORES: { EDITOR_API },
  withConditionalRender,
} = util.hoc;

const { RECOMMENDED_MOBILE_HEADER_HEIGHT } = constants.ROOT_COMPS.MOBILE_EDITOR;
const { MOBILE_FRAME_WIDTH } = constants.ROOT_COMPS.MOBILE_EDITOR;
const HEADER_INFO_MARGIN_LEFT = 36;
const HEADER_INFO_MARGIN_BOTTOM = 12;
const HEADER_INFO_HEIGHT = 28;

interface RecommendedMobileHeaderHeightOwnProps {
  isResizing: boolean;
  stageLayout: CompLayout;
}

interface RecommendedMobileHeaderHeightStateProps {
  selectedComponents: CompRef[];
}

interface RecommendedMobileHeaderHeightProps
  extends RecommendedMobileHeaderHeightOwnProps,
    RecommendedMobileHeaderHeightStateProps {}

class RecommendedMobileHeaderHeight extends React.PureComponent<RecommendedMobileHeaderHeightProps> {
  static displayName = 'recommendedMobileHeaderHeight';

  getHeaderInfoStyle = () => {
    const { stageLayout } = this.props;
    const scrollWidth = util.uiUtils.getScrollbarWidth();
    const marginFromScaledLayout =
      (stageLayout.width - scrollWidth - MOBILE_FRAME_WIDTH) / 2;

    return {
      left:
        marginFromScaledLayout + MOBILE_FRAME_WIDTH + HEADER_INFO_MARGIN_LEFT,
      top:
        RECOMMENDED_MOBILE_HEADER_HEIGHT -
        HEADER_INFO_HEIGHT / 2 -
        HEADER_INFO_MARGIN_BOTTOM +
        1,
    };
  };

  getRecommendedHeaderLines = () => {
    const { stageLayout } = this.props;
    const scrollWidth = util.uiUtils.getScrollbarWidth();

    return util.recommendedMobileHeaderHeightUtils.buildRecommendedHeaderLines(
      stageLayout,
      scrollWidth,
    );
  };

  render() {
    return (
      <div
        className={util.cx({
          'recommended-mobile-header-height': true,
          selected: !this.props.isResizing,
        })}
      >
        <div
          key="recommended-mobile-header"
          style={this.getHeaderInfoStyle()}
          className="header-footer-info-tooltip"
        >
          <Tooltip
            key="recommended-mobile-header-tooltip"
            content="mobile_headerscroll_panel_size_tooltip"
            alignment="BOTTOM"
          >
            <div className="header-tooltip-content">
              <BaseUI.symbol name="infoIcon" className="info-icon" />
              <span className="recommended-mobile-header-height-title">
                {translate('mobile_headerscroll_panel_size_title')}
              </span>
            </div>
          </Tooltip>
        </div>

        <svg className="header-recommended-height-separator">
          {this.getRecommendedHeaderLines().map((line, lineIndex) => (
            <line
              key={`recommended-height-separator-${lineIndex}`}
              x1={line.x1}
              x2={line.x2}
              y1={line.y}
              y2={line.y}
              className="header-recommended-height-separator"
            />
          ))}
        </svg>
      </div>
    );
  }
}

const recommendedMobileHeaderHeightMapper: MapStateToProps<
  RecommendedMobileHeaderHeightStateProps,
  RecommendedMobileHeaderHeightOwnProps
> = ({ editorAPI }) => ({
  selectedComponents: editorAPI.selection.getSelectedComponents(),
});

export const ConnectedRecommendedMobileHeaderHeight = connect(
  EDITOR_API,
  recommendedMobileHeaderHeightMapper,
)(RecommendedMobileHeaderHeight);

ConnectedRecommendedMobileHeaderHeight.pure = RecommendedMobileHeaderHeight;

const shouldShowRecommendedMobileHeaderHeight = ({
  editorAPI,
  state,
}: StateMapperArgs) => {
  const [selectedComponent] = getSelectedCompsRefs(state);
  return (
    state.editorIsInit &&
    editorAPI.isMobileEditor() &&
    selectedComponent?.id === 'SITE_HEADER' &&
    !util.sections.isSectionsEnabled() &&
    !editorAPI.zoomMode.isInZoomMode() &&
    !isPinModeOpen(state) &&
    !editorAPI.isPopUpMode() &&
    !editorAPI.components.is.spotlightStageContainer(getFocusedContainer(state))
  );
};

export default withConditionalRender(
  EDITOR_API,
  shouldShowRecommendedMobileHeaderHeight,
)(ConnectedRecommendedMobileHeaderHeight);

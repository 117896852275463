import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import { bi } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

const MULTILINGUAL_MODE_SHORTCUTS_CONFIG = {};
const keyboardMap = keyboardShortcutsActionMap.editor;

function addMultilingualModeShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};

  keysToMerge[keyboardMap.CLOSE_PANELS.key] = () => {
    editorAPI.store.dispatch(
      stateManagement.multilingual.actions.switchToOriginalLanguageThunk(),
    );
    editorAPI.store.dispatch(
      bi.actions.event(coreBi.events.editor.ESCAPE_BUTTON_CLICKED, {
        mode: 'multilingual_mode',
      }),
    );
  };

  Object.assign(MULTILINGUAL_MODE_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addMultilingualModeShortcuts(editorAPI);
  },
  shortcuts: MULTILINGUAL_MODE_SHORTCUTS_CONFIG,
};

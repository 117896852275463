// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import React from 'react';
import { Composites, ToggleSwitch, Divider } from '@wix/wix-base-ui';
import * as panelUtils from '#packages/panelUtils';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  paramKey: string;
  styleDataItem: AnyFixMe;
  forceUpdateParentStyle: FunctionFixMe;
  advancedSiteSegmentEnabled: boolean;
  sendSiteSegmentBi: (biValues: {
    field_value: 'on' | 'off';
    mouse_state: 'scroll' | 'regular';
    tab_name: string;
    field_name: string;
  }) => void;
}

export default class extends React.Component<Props> {
  static displayName = 'advancedStyleShadowControl';

  static propTypes = {
    paramKey: PropTypes.string.isRequired,
    styleDataItem: PropTypes.object.isRequired,
    forceUpdateParentStyle: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div>
        <Composites.ToggleSwitch>
          <ToggleSwitch
            value={this.getEnabledShadowValue()}
            onChange={this.onShadowEnabledChange}
            shouldTranslate={true}
            label="CustomDesign_Shadow_EnableToggle"
          />
        </Composites.ToggleSwitch>
        <Divider long={false} />
        <panelUtils.shadowControl
          valueLink={this.props.valueLink}
          disabled={!this.getEnabledShadowValue()}
          key="shadowControl"
        />
      </div>
    );
  }

  getEnabledParamKey = () => {
    return `boxShadowToggleOn-${this.props.paramKey}`;
  };

  getEnabledShadowValue = () => {
    const paramKey = this.getEnabledParamKey();
    const paramValue = this.props.styleDataItem.style.properties[paramKey];
    const boolParamValue = util.stringUtils.isTrue(paramValue);

    if (!this.props.advancedSiteSegmentEnabled || paramValue !== undefined)
      return boolParamValue;

    const paramValueWithoutScroll = util.stringUtils.isTrue(
      this.props.styleDataItem.style.properties[paramKey.replace('-scrl', '')],
    );

    return paramValueWithoutScroll;
  };

  onShadowEnabledChange = (newVal: boolean) => {
    const { styleDataItem, sendSiteSegmentBi } = this.props;
    const param = this.getEnabledParamKey();
    const value = newVal.toString();
    if (
      param === 'boxShadowToggleOn-shd-scrl' &&
      this.props.advancedSiteSegmentEnabled
    ) {
      if (newVal) {
        styleDataItem.style.properties['shd-scrl'] =
          styleDataItem.style.properties.shd;
      } else {
        styleDataItem.style.properties['shd-scrl'] = '';
      }
    }
    styleDataItem.style.properties[param] = value;
    this.props.forceUpdateParentStyle(styleDataItem, param, value);

    sendSiteSegmentBi?.({
      field_value: newVal ? 'on' : 'off',
      mouse_state: param?.includes('-scrl') ? 'scroll' : 'regular',
      tab_name: 'shadow',
      field_name: 'apply_shadow',
    });
  };
}

import React from 'react';
import { Composites, TextButton } from '@wix/wix-base-ui';
import { Settings } from '@wix/wix-ui-icons-common/classic-editor';
import { translate } from '#packages/i18n';
import { cx } from '#packages/util';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

interface OpenSignupSettingsDashboardButtonProps {
  onClick: () => void;
}

export const OpenSignupSettingsDashboardButton: React.FC<
  OpenSignupSettingsDashboardButtonProps
> = (props: OpenSignupSettingsDashboardButtonProps) => {
  return (
    <Composites.ButtonLeft
      className={cx({
        'open-settings-button': !isResponsiveEditor(),
        'open-settings-button-editorx': isResponsiveEditor(),
      })}
    >
      <TextButton
        onClick={() => props.onClick()}
        prefixIcon={<Settings />}
        size="small"
        weight="thin"
        shouldTranslate={false}
      >
        {translate('NewPages_Member_SignIn_Custom_Member_Settings_Link')}
      </TextButton>
    </Composites.ButtonLeft>
  );
};

export default OpenSignupSettingsDashboardButton;

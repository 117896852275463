import type { AddPresetScope } from '../../../../addPresetApiEntry';
import { CREATE_SECTON_WITH_AI_CACHE } from '../aiSectionCreatorConsts';
import { SiteGlobalDataApiKey } from '#packages/apis';

export const clearSectionsCacheEntry = (): void => {
  const cachedData = JSON.parse(
    sessionStorage.getItem(CREATE_SECTON_WITH_AI_CACHE),
  );

  const updatedData = {
    ...cachedData,
    sections: null,
  };

  sessionStorage.setItem(
    CREATE_SECTON_WITH_AI_CACHE,
    JSON.stringify(updatedData),
  );
};

export const getBusinessData = (
  scope: AddPresetScope,
): { businessName: string; businessType: string } => {
  const { editorAPI } = scope;
  const siteGlobalDataAPI = editorAPI.host.getAPI(SiteGlobalDataApiKey);
  const businessType = siteGlobalDataAPI.getBusinessType()?.displayName ?? '';
  const businessName = siteGlobalDataAPI.getBusinessName();

  return {
    businessType,
    businessName,
  };
};

import type { Scope } from '#packages/sections';
import type { ViewerChangesData } from 'types/core';
import { removeSectionsGaps } from './removeSectionsGaps';

const clearSectionsTransformationsIfNeeded = (scope: Scope) => {
  const { editorAPI } = scope;
  const pageRef = editorAPI.pages.getCurrentPage();

  if (editorAPI.sections.hasTransformationsOnCurrentPage()) {
    editorAPI.sections.clearSectionTransformationsOnCurrentPage();
    removeSectionsGaps(scope, pageRef);
  }
};

const CHANGES_TO_REACT = [
  'pages.navigateTo',
  'documentMode.setComponentViewMode',
];

export const enforceClearSectionsTransformations = (scope: Scope) => {
  clearSectionsTransformationsIfNeeded(scope);

  return (changes: ViewerChangesData) => {
    if (changes?.some((change) => CHANGES_TO_REACT.includes(change))) {
      clearSectionsTransformationsIfNeeded(scope);
    }
  };
};

import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import constants from '#packages/constants';

import connectWithEditor from '../../util/connectWithEditor';

import type { MapStateToProps } from 'types/redux';
import type { SendBiFunction } from 'types/bi';

const { MENU_BAR_ITEMS } = constants.ROOT_COMPS.TOPBAR;
const isUpgradePanelOpen = (panelKey: AnyFixMe) =>
  panelKey === MENU_BAR_ITEMS.UPGRADE;

interface WithDealerUpgradeBehaviorOwnProps {
  openedDropPanel: string;
  openDropPanel: (key: string) => void;
  closeDropPanel: () => void;
  sendBi: SendBiFunction;
}

interface WithDealerUpgradeBehaviorStateProps {
  isDealerCssLoaded: boolean;
  isFirstSave: boolean;
  isDraftMode: boolean;
  wixDealerClientApi: {
    DealerAssetsLoader: unknown;
  };
  metaSiteId: string;
  metaSiteInstance: string;
}

interface WithDealerUpgradeBehaviorProps
  extends WithDealerUpgradeBehaviorOwnProps,
    WithDealerUpgradeBehaviorStateProps {}

export interface ComponentWithDealerUpgradeBehaviorProps {
  isUsingExternalUpgradeData: () => boolean;
  fallbackToDefaultUpgradePanel: (errorType: string) => void;
  openDropPanel: (panelName: string) => void;
}

const mapStateToProps: MapStateToProps<
  WithDealerUpgradeBehaviorStateProps,
  WithDealerUpgradeBehaviorOwnProps
> = ({ editorAPI }) => ({
  isDealerCssLoaded: editorAPI.isDealerCssLoaded,
  isFirstSave: editorAPI.dsRead?.generalInfo.isFirstSave(),
  isDraftMode: editorAPI.dsRead?.generalInfo.isDraft(),
  wixDealerClientApi: editorAPI.wixDealerClientApi,
  metaSiteId:
    editorAPI.dsRead?.generalInfo.getMetaSiteId() ||
    util.editorModel?.metaSiteId,
  metaSiteInstance: editorAPI.dsRead?.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance,
});

const withDealerUpgradeBehavior = <P extends WithDealerUpgradeBehaviorProps>(
  WrappedComponent: ComponentType<P & ComponentWithDealerUpgradeBehaviorProps>,
) => {
  class WithDealerUpgradeBehavior extends Component<WithDealerUpgradeBehaviorProps> {
    state = {
      hasDealerLoaded: false,
    };

    componentDidMount() {
      this.loadDealerAssetIfNeeded(this.props);
    }

    UNSAFE_componentWillReceiveProps(
      nextProps: WithDealerUpgradeBehaviorProps,
    ) {
      this.loadDealerAssetIfNeeded(nextProps);
    }

    private wasDealerSingleAttemptExecuted: boolean = false;
    private hasUserSeenDefaultUpgradePanel: boolean = false;

    isUsingExternalUpgradeData = () =>
      this.state.hasDealerLoaded &&
      this.props.isDealerCssLoaded &&
      !this.hasUserSeenDefaultUpgradePanel;

    sendContentPresentedByDealerBI = (biProps: AnyFixMe) => {
      const { isUsingDealer, description, stage } = biProps;

      this.props.sendBi(coreBi.events.dealer.content_presented_by_dealer, {
        description: isUsingDealer ? '' : description,
        content_presented_by_dealer: isUsingDealer,
        stage,
        category: 'upgrade',
        panel_name: 'top_bar_upgrade',
      });
    };

    fallbackToDefaultUpgradePanel = (errorType = 'unknown') => {
      const panelOpen = isUpgradePanelOpen(this.props.openedDropPanel);
      if (this.state.hasDealerLoaded && panelOpen) {
        this.setState({ hasDealerLoaded: false });

        this.sendContentPresentedByDealerBI({
          isUsingDealer: false,
          stage: 'render',
          description: `dealer after pre fetch ${errorType} error`,
        });
      }
    };

    loadDealerAssetIfNeeded = (props: WithDealerUpgradeBehaviorProps) => {
      const shouldLoadDealerAsset = Boolean(
        !this.wasDealerSingleAttemptExecuted &&
          !this.hasUserSeenDefaultUpgradePanel &&
          props.wixDealerClientApi?.DealerAssetsLoader &&
          props.metaSiteId &&
          props.metaSiteInstance,
      );

      if (shouldLoadDealerAsset) {
        this.wasDealerSingleAttemptExecuted = true;
        const bannerPosition =
          props.isFirstSave || props.isDraftMode
            ? util.dealerUtils.bannerPositions.SITE_NOT_SAVED
            : util.dealerUtils.bannerPositions.SITE_SAVED;

        this.loadDealerAsset(
          props.wixDealerClientApi,
          bannerPosition,
          props.metaSiteId,
          props.metaSiteInstance,
        );
      }
    };

    loadDealerAsset = (
      dealerClientApi: AnyFixMe,
      bannerPosition: AnyFixMe,
      metaSiteId: AnyFixMe,
      metaSiteInstance: AnyFixMe,
    ) => {
      util.dealerUtils
        .loadDealerAsset(
          dealerClientApi,
          bannerPosition,
          null,
          metaSiteId,
          metaSiteInstance,
        )
        .then(
          this.handleLoadDealerAssetSuccess,
          this.handleLoadDealerAssetFail,
        );
    };

    handleLoadDealerAssetSuccess = (asset: AnyFixMe) => {
      if (isUpgradePanelOpen(this.props.openedDropPanel)) {
        this.sendContentPresentedByDealerBI({
          isUsingDealer: false,
          stage: 'prefetch',
          description:
            'load asset response received when user have upgrade panel open',
        });

        return;
      }

      const hasDealerLoaded = Boolean(_.invoke(asset, 'assetExist'));
      const description = hasDealerLoaded
        ? ''
        : 'banner data pre fetch asset does not exist error';

      this.setState({ hasDealerLoaded });

      this.sendContentPresentedByDealerBI({
        isUsingDealer: hasDealerLoaded,
        stage: 'prefetch',
        description,
      });
    };

    handleLoadDealerAssetFail = (e: AnyFixMe) => {
      const errorMessage = e?.message ?? 'unknown';
      this.sendContentPresentedByDealerBI({
        isUsingDealer: false,
        stage: 'prefetch',
        description: `banner data pre fetch ${errorMessage} error`,
      });
    };

    openDropPanel = (openedDropPanel: AnyFixMe) => {
      if (
        isUpgradePanelOpen(openedDropPanel) &&
        !this.isUsingExternalUpgradeData()
      ) {
        this.hasUserSeenDefaultUpgradePanel = true;

        if (!this.props.isDealerCssLoaded) {
          this.sendContentPresentedByDealerBI({
            isUsingDealer: false,
            stage: 'prefetch',
            description:
              'dealer not ready when user tried to open upgrade panel',
          });
        }
      }

      this.props.openDropPanel(openedDropPanel);
    };

    render() {
      const { props } = this;

      return React.createElement(
        WrappedComponent,
        Object.assign({}, props as P, {
          isUsingExternalUpgradeData: this.isUsingExternalUpgradeData,
          fallbackToDefaultUpgradePanel: this.fallbackToDefaultUpgradePanel,
          openDropPanel: this.openDropPanel,
        }),
      );
    }
  }

  return connectWithEditor(mapStateToProps, null)(WithDealerUpgradeBehavior);
};

export default withDealerUpgradeBehavior;

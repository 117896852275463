// @ts-nocheck
import React from 'react';
import { DATA_BINDING } from '@wix/app-definition-ids';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as symbols from '@wix/santa-editor-symbols';
import * as core from '#packages/core';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import * as platform from '#packages/platform';
import * as coreBi from '#packages/coreBi';
import * as pageMenuItemHelpers from '../../../utils/pageMenuItemHelpers';
import {
  Composites,
  CustomScroll,
  RichText,
  TextLabel,
  ToggleSwitch,
} from '@wix/wix-base-ui';
import PageSettingsMobileCommon from './pageSettingsMobileCommon';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'pageSettingsMobile',
  propTypes: {
    menuItem: PropTypes.object.isRequired,
    actions: PropTypes.object,
    isDeveloperModeEnabled: PropTypes.bool,
    closeSettingsPanel: PropTypes.func.isRequired,
    token: PropTypes.string,
  },
  render() {
    return (
      <div>
        {!this.isDynamicPage() ? (
          <PageSettingsMobileCommon
            key="regular-page-mobile-settings"
            title={this.props.menuItem.label}
            closeSettingsPanel={this.props.closeSettingsPanel}
          >
            <CustomScroll>
              <div className="page-info tab-inner page-settings-mobile-common-page">
                <section>
                  <ToggleSwitch
                    value={!this.props.menuItem.isVisibleMobile}
                    label={this.getMobileVisibilityContent()}
                    onChange={this.changeMobileVisibility}
                    shouldTranslate={false}
                  />
                </section>
                <section className="hidden-from-desktop">
                  {this.props.menuItem.isVisible ? (
                    <span key="page-settings-mobile-visible">
                      <symbols.symbol name="desktopVisible" />
                      <span className="label">
                        {translate('Pages_Mobile_Settings_IsVisible')}
                      </span>
                    </span>
                  ) : null}
                  {!this.props.menuItem.isVisible ? (
                    <span key="page-settings-mobile-hidden">
                      <symbols.symbol name="desktopHidden" />
                      <span className="label">
                        {translate('Pages_Mobile_Settings_IsHidden')}
                      </span>
                    </span>
                  ) : null}
                </section>
              </div>
            </CustomScroll>
          </PageSettingsMobileCommon>
        ) : null}

        {this.isDynamicPage() && this.shouldShowDynamicPageSettings() ? (
          <PageSettingsMobileCommon
            key="dynamic-page-mobile-settings"
            title={this.props.menuItem.label}
            closeSettingsPanel={this.props.closeSettingsPanel}
          >
            <CustomScroll>
              <div className="page-info tab-inner page-settings-mobile-common-page">
                {this.props.isDeveloperModeEnabled ? (
                  <Composites.RichTextLabeled key="title-dev-mode-enabled">
                    <TextLabel
                      type="T06"
                      value={translate(
                        'Platform_Page_Settings_Mobile_View_DevTools_Enabled_Title',
                      )}
                      shouldTranslate={false}
                    />
                    <RichText>
                      {translate(
                        'Platform_Page_Settings_Mobile_View_DevTools_Enabled_Text',
                      )}
                    </RichText>
                  </Composites.RichTextLabeled>
                ) : null}

                {!this.props.isDeveloperModeEnabled ? (
                  <Composites.RichTextLabeled key="title-dev-mode-not-enabled">
                    <TextLabel
                      type="T06"
                      value={translate(
                        'Platform_Page_Settings_Mobile_View_DevTools_Disabled_Title',
                      )}
                      shouldTranslate={false}
                    />
                    <RichText>
                      <div>
                        {translate(
                          'Platform_Page_Settings_Mobile_View_DevTools_Disabled_Text',
                        )}
                      </div>
                      <br />
                      <div>
                        {translate(
                          'Platform_Page_Settings_Mobile_View_DevTools_Disabled_Text2',
                        )}
                      </div>
                    </RichText>
                  </Composites.RichTextLabeled>
                ) : null}
              </div>
            </CustomScroll>
          </PageSettingsMobileCommon>
        ) : null}

        {this.isDynamicPage() && !this.shouldShowDynamicPageSettings() ? (
          <PageSettingsMobileCommon
            key="dynamic-page-mobile-settings"
            title={this.props.menuItem.label}
            closeSettingsPanel={this.props.closeSettingsPanel}
          >
            <baseUI.tabs.tab children={this.getAppTabContent()} />
          </PageSettingsMobileCommon>
        ) : null}
      </div>
    );
  },

  getMobileIconProps() {
    return this.props.menuItem.isVisibleMobile
      ? { name: 'mobileVisible', className: 'mobileVisible', key: 'visible' }
      : { name: 'mobileHidden', className: 'mobileHidden', key: 'hidden' };
  },
  getMobileVisibilityContent() {
    return React.createElement(
      'div',
      { className: 'toggle-with-icon' }, //eslint-disable-line react/no-deprecated
      React.createElement(
        'span',
        null,
        React.createElement(symbols.symbol, this.getMobileIconProps()),
      ), //eslint-disable-line react/no-deprecated
      React.createElement(
        'span',
        { className: 'label' },
        translate('Pages_Mobile_Settings_Hide'),
      ),
    ); //eslint-disable-line react/no-deprecated
  },
  changeMobileVisibility(nextHideFromMobile) {
    pageMenuItemHelpers.setHidden(
      this.getEditorAPI(),
      this.props.menuItem,
      undefined,
      nextHideFromMobile,
    );
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages
        .top_bar_PAGES_settings_hide_from_mobile_done_click,
      { category: nextHideFromMobile ? 'hide' : 'show' },
    );
  },
  isDynamicPage() {
    const editorAPI = this.getEditorAPI();
    if (!this.props.menuItem.pageData) {
      return false;
    }
    return !!editorAPI.dsRead.routers.getRouterDataForPageIfExist(
      this.props.menuItem.pageData.id,
    );
  },
  shouldShowDynamicPageSettings() {
    const editorAPI = this.getEditorAPI();
    const APPS_THAT_HAVE_TO_HAVE_DEV_TOOLS_TO_SHOW_PANEL = [
      editorAPI.dsRead.platform.editorApps.DYNAMIC_PAGES.appDefId,
      DATA_BINDING,
    ];
    const appData = this.getAppData();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(
      APPS_THAT_HAVE_TO_HAVE_DEV_TOOLS_TO_SHOW_PANEL,
      appData.appDefId,
    );
  },

  getAppMobileTabs(pageId) {
    const editorAPI = this.getEditorAPI();
    return editorAPI.pages.dynamicPages.getDynamicPageActions(
      pageId,
      'pageSettings',
    );
  },

  getAppData() {
    const editorAPI = this.getEditorAPI();
    const pageRef = editorAPI.components.get.byId(
      this.props.menuItem.pageData.id,
    );
    const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
    if (routerRef) {
      const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);
      return {
        appDefId: routerData.appDefinitionId,
        applicationId: editorAPI.dsRead.platform.getAppDataByAppDefId(
          routerData.appDefinitionId,
        )?.applicationId,
      };
    }
  },
  getIframeFullSrc(url, appDefId, applicationId) {
    const editorAPI = this.getEditorAPI();
    const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);
    return platform.utils.getFullApplicationUrl(
      appData,
      url,
      appDefId,
      applicationId,
    );
  },

  iframeLoadedHandler() {
    //todo: do we need this?
  },

  getPostMessageHandler() {
    const editorAPI = this.getEditorAPI();
    const pageRef = editorAPI.components.get.byId(
      this.props.menuItem.pageData.id,
    );
    const initialData = {
      routerRef: editorAPI.dsRead.routers.getRouterRef.byPage(pageRef),
      publicUrl: editorAPI.dsRead.generalInfo.getPublicUrl(),
      pageRef,
      origin: 'dynamic_page_settings_panel_tab',
    };
    return platform.platformPostMessageService.createPostMessageListener(
      {
        token: this.props.token,
        initialData,
      },
      this.props.token,
    );
  },

  getAppTabContent() {
    if (this.isDynamicPage() && !this.shouldShowDynamicPageSettings()) {
      const appData = this.getAppData();
      const appMobileTabs = this.getAppMobileTabs(
        this.props.menuItem.pageData.id,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const tab = _.find(appMobileTabs, {
        type: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
      });

      return React.createElement(
        baseUI.iframe,
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(
          {},
          { menuItem: this.props.menuItem },
          {
            key: `dynamic-page-iframe${this.props.menuItem.pageData.id}${appData.appDefId}`,
            ref: `dynamic-page-iframe${this.props.menuItem.pageData.id}${appData.appDefId}`,
            className: 'content-iframe',
            appData,
            name: this.props.token,
            src: this.getIframeFullSrc(
              tab.url,
              appData.appDefId,
              appData.applicationId,
            ),
            onPostMessage: this.getPostMessageHandler(this.props.token),
            onLoad: this.iframeLoadedHandler,
          },
        ),
      );
    }
  },
});

import React, { useMemo, useEffect } from 'react';
import { HtmlPreviewThumbnail } from '../htmlPreviewThumbnail/htmlPreviewThumbnail';
import HtmlPreviewThumbnailManager from '../htmlPreviewThumbnail/htmlPreviewThumbnailManager';
import { fedopsLogger } from '#packages/util';
import type { EditorInteractionName } from 'types/fedops';

import type { ThemeMap } from '../types';

interface AddPresetHtmlPreviewProps {
  previewHtmlUrl: string;
  fontsUrls: string[];
  containerWidth: number;
  currentSiteThemeMap: ThemeMap;
  themeMap: ThemeMap;
  onReady: () => void;
  interactionKey: EditorInteractionName;
  height?: React.CSSProperties['height'];
  shouldScaleOverIframe?: boolean;
  shouldOptimizeImages?: boolean;
  enableScrolling?: boolean;
  shouldWrapWithHeight?: boolean;
}

const AddPresetHtmlPreview = ({
  fontsUrls,
  containerWidth,
  currentSiteThemeMap,
  previewHtmlUrl,
  themeMap,
  onReady,
  interactionKey,
  height,
  shouldScaleOverIframe = false,
  shouldOptimizeImages = true,
  enableScrolling = false,
  shouldWrapWithHeight = true,
}: AddPresetHtmlPreviewProps) => {
  useEffect(() => {
    fedopsLogger.interactionStarted(interactionKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const htmlPreviewThumbnailManager = useMemo<HtmlPreviewThumbnailManager>(
    () => new HtmlPreviewThumbnailManager('', previewHtmlUrl),
    [previewHtmlUrl],
  );

  const handleHTMLPreviewReady = () => {
    onReady();
    fedopsLogger.interactionEnded(interactionKey);
  };

  const getHtmlPreviewThumbnail = () => {
    return (
      <HtmlPreviewThumbnail
        containerWidth={containerWidth}
        containerHeight={height}
        previewHtmlManager={htmlPreviewThumbnailManager}
        fontsUrls={fontsUrls}
        currentSiteThemeMap={currentSiteThemeMap}
        themeMap={themeMap}
        shouldOptimizeImages={shouldOptimizeImages}
        onIframeReady={() => {
          handleHTMLPreviewReady();
        }}
        enableScrolling={enableScrolling}
        shouldScaleOverIframe={shouldScaleOverIframe}
      />
    );
  };

  if (shouldWrapWithHeight) {
    return <div style={{ height }}>{getHtmlPreviewThumbnail()}</div>;
  }
  return getHtmlPreviewThumbnail();
};

export default AddPresetHtmlPreview;

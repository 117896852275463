import constants from '#packages/constants';
import * as util from '#packages/util';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import { interactions } from '#packages/stateManagement';

const { isInteractionModeAvailable } = interactions.selectors;
export const DEPRECATION_PANEL_PREF_KEY = 'DEPRECATION_PANEL';
const DEPRECATION_PANEL_HELP_ID = '168a2a6b-db47-4bdd-ba7c-b4c7360a2b92';

export interface DeprecationPanelDefinitions {
  title: string;
  text: string;
  bgColor: string;
  headerBg: string;
  linkText?: string;
  isDisabled(editorAPI: AnyFixMe): boolean;
  getOnLearnMore(editorAPI: AnyFixMe): () => void;
  getOnClickHandler(editorAPI: AnyFixMe): () => void;
}

export const definitions: {
  [ComponentType: string]: DeprecationPanelDefinitions;
} = {
  [constants.COMP_TYPES.HOVER_BOX]: {
    title: 'ONSTAGE_POPUP_HOVERBOX_DEPRECATING_TITLE',
    text: 'ONSTAGE_POPUP_HOVERBOX_DEPRECATING_TEXT',
    bgColor: '#ffffff',
    headerBg: util.media.getMediaUrl(
      'compPanels/deprecationPanel/deprecationIllustration.svg',
    ),
    linkText: 'ONSTAGE_POPUP_HOVERBOX_DEPRECATING_LINK',
    isDisabled: (editorAPI) => {
      return !isInteractionModeAvailable(editorAPI.dsRead);
    },
    getOnLearnMore: (editorAPI) => () => {
      editorAPI.panelManager.openHelpCenter(DEPRECATION_PANEL_HELP_ID);
    },
    getOnClickHandler: (editorAPI) => () => {
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
        {
          origin: 'deprecatePanelHoverBox',
          category: addPanelDataConsts.CATEGORIES_ID.BOX,
          section: 'interactionsHoverSection',
        },
      );
    },
  },
};

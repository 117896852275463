import constants from '#packages/constants';
import * as tpaGfppData from '../tpaGfppDataActions';
import type { GFPPDataRaw } from '@wix/editor-component-types';

const gfppData: GFPPDataRaw = {
  mainActions: tpaGfppData.mainActions,
  enabledActions(editorAPI) {
    return tpaGfppData.enabledActions(
      editorAPI,
      constants.ROOT_COMPS.GFPP.ACTIONS,
    );
  },
  presetActions(editorAPI) {
    return tpaGfppData.presetActions(editorAPI);
  },
  mobileMainActions: tpaGfppData.mobileMainActions,
  mobileEnabledActions: [
    constants.ROOT_COMPS.GFPP.ACTIONS.HIDE,
    constants.ROOT_COMPS.GFPP.ACTIONS.HELP,
  ],
};

export default gfppData;

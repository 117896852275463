import React from 'react';
import { WorkspaceRightPanelApiKey } from '#packages/apis';
import * as gfppData from '#packages/gfppData';
import { hoc } from '#packages/util';
import styles from './styles/buttonDesignEntryPoint.scss';
import { scrollToComponent } from '../quickEditUtils';
import { type DesignEntryPointOwnProps, quickEditPanelOrigin } from '../consts';
import * as platform from '#packages/platform';
import { Text } from '@wix/wix-base-ui';
import type { Dispatch, ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';

interface ButtonDesignEntryPointDispatchProps {
  openButtonDesignPanel: () => void;
}

interface ButtonDesignEntryPointProps
  extends ButtonDesignEntryPointDispatchProps {}

const ButtonDesignEntryPoint: React.FC<ButtonDesignEntryPointProps> = ({
  openButtonDesignPanel,
}: ButtonDesignEntryPointProps) => {
  return (
    <div className={styles.buttonDesignEntryPoint}>
      <Text size="small">
        {'edit_section_button_settings_more_options_text'}
      </Text>
      <div
        className={styles.link}
        onClick={() => {
          openButtonDesignPanel();
        }}
      >
        <Text size="small" skin="link">
          {'edit_section_button_settings_more_options_cta'}
        </Text>
      </div>
    </div>
  );
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const mapDispatchToProps = (
  dispatch: Dispatch,
  { compId }: DesignEntryPointOwnProps,
) => {
  const editorAPI = dispatch(getEditorAPI);
  const workspaceRightPanelApi = editorAPI.host.getAPI(
    WorkspaceRightPanelApiKey,
  );
  return {
    openButtonDesignPanel: async () => {
      await workspaceRightPanelApi.close(quickEditPanelOrigin);
      let compRef = editorAPI.components.get.byId(compId);

      await scrollToComponent(editorAPI, compRef);
      compRef = platform.gfppTargetUtils.getSelectedComponents(
        compRef,
        editorAPI,
        'design',
      );
      return gfppData.utils.getTogglePanelFn('design')(editorAPI, [compRef]);
    },
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(ButtonDesignEntryPoint);

import * as stateManagement from '#packages/stateManagement';
import { SectionsMigrationApiKey } from '#packages/apis';
import constants from '#packages/constants';
import { sections as sectionsUtils } from '#packages/util';

import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { CompRef } from 'types/documentServices';
import type { IconInfo } from 'types/core';
import type { GroupingItem } from '#packages/sectionsMigration';
import type { SectionNamePanelProps } from './sectionNamePanel';
import type {
  MessageObject,
  SectionizerPanelStateProps,
  SectionizerPanelDispatchProps,
} from './sectionizerPanel.types';

const SECTION_NAME_PANEL_NAME =
  'sectionizer.panels.sectionizerSectionNamePanel';
const ERROR_PANEL_NAME = 'sectionizer.panels.sectionizerErrorPanel';

const mapStateToProps: MapStateToProps<SectionizerPanelStateProps> = ({
  state,
  editorAPI,
}) => {
  const sectionsMigrationApi = editorAPI.host.getAPI(SectionsMigrationApiKey);
  const selectedCompId =
    stateManagement.selection.selectors.getSelectedCompsRefs(state)?.[0]?.id;

  return {
    unlabeledPageTitles: sectionsMigrationApi
      .getUnlabeledPages()
      .map(({ id }) => editorAPI.pages.getPageTitle(id)),
    components: editorAPI.components
      .getChildren(editorAPI.pages.getCurrentPage())
      .filter((compRef: CompRef) =>
        sectionsUtils.isSectionableComponent(
          editorAPI.documentServices,
          compRef,
        ),
      ),
    selectedCompId,
  };
};

const mapDispatchToProps: MapDispatchToProps<
  SectionizerPanelDispatchProps,
  null
> = {
  // @ts-expect-error
  getComponentIconInfo:
    (compId: string): ThunkAction =>
    (dispatch, getState, { editorAPI }): IconInfo => {
      const compRef = editorAPI.components.get.byId(compId);

      return editorAPI.components.getIconInfo(compRef);
    },
  // @ts-expect-error
  getComponentDisplayName:
    (compId: string): ThunkAction =>
    (dispatch, getState, { editorAPI }): string => {
      const compRef = editorAPI.components.get.byId(compId);

      return compRef ? editorAPI.components.getDisplayName(compRef) : '';
    },
  save:
    (sections: GroupingItem[]): ThunkAction =>
    (dispatch, getState, { editorAPI }) =>
      editorAPI.components.features.update(
        editorAPI.pages.getCurrentPage(),
        sectionsUtils.constants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
        {
          sections,
        },
      ),
  close: stateManagement.sections.actions.hideSectionizedPanel,
  openSectionNamePanel:
    (props: SectionNamePanelProps): ThunkAction =>
    (dispatch) =>
      dispatch(
        stateManagement.panels.actions.updateOrOpenPanel(
          SECTION_NAME_PANEL_NAME,
          props,
          true,
        ),
      ),
  closeSectionNamePanel: (): ThunkAction => (dispatch) =>
    dispatch(
      stateManagement.panels.actions.closePanelByName(SECTION_NAME_PANEL_NAME),
    ),
  showErrorMessage:
    ({ title, message }: MessageObject): ThunkAction =>
    (dispatch) =>
      dispatch(
        stateManagement.panels.actions.updateOrOpenPanel(
          ERROR_PANEL_NAME,
          {
            title,
            message,
          },
          true,
        ),
      ),
  showSuccessMessage:
    ({ title, message }: MessageObject): ThunkAction =>
    (dispatch) =>
      dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message,
          title,
          type: constants.NOTIFICATIONS.TYPES.SUCCESS,
        }),
      ),
};

export { mapStateToProps, mapDispatchToProps };

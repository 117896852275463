import { EditorAPIKey } from '#packages/apis';

import { SchoolModeApiKey } from './publicApiKey';
import { createSchoolModeApi } from './schoolModeApi';

import type { EntryPoint } from '#packages/apilib';

export const SchoolModeApiEntryPoint: EntryPoint = {
  name: 'SchoolModeApi',
  declareAPIs: () => [SchoolModeApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(SchoolModeApiKey, () => createSchoolModeApi());
  },
};

// @ts-nocheck
import constants from '#packages/constants';
import * as util from '#packages/util';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const { getRootAppWidget, isAppWidget } = util.appStudioUtils;

function isWidgetPage(editorAPI) {
  return editorAPI.dsRead.pages.isWidgetPage(
    editorAPI.pages.getFocusedPageId(),
  );
}

const isConnectedToRoot = (editorAPI, compRef) => {
  const controllerRef =
    editorAPI.platform.controllers.connections.getPrimaryConnection(compRef)
      ?.controllerRef;

  return editorAPI.utils.isSameRef(controllerRef, getRootAppWidget(editorAPI));
};

export default function (editorAPI, compRef, compGfppData) {
  if (isWidgetPage(editorAPI)) {
    if (
      isAppWidget(editorAPI, compRef) &&
      !isConnectedToRoot(editorAPI, compRef[0])
    ) {
      delete compGfppData.presetActions[ACTIONS.DESIGN];
    }

    delete compGfppData.presetActions[ACTIONS.INTERACTIONS];
  }
}

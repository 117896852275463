// @ts-nocheck
import _ from 'lodash';
import * as core from '#packages/core';

/**
 * Please ctrl/cmd + click on biEvents to see the schema :)
 * @type {Object.<String, biEvent>}
 */
const events = {
  appButton: {
    MAIN_BUTTON_HOVER: {
      evid: 'x',
      src: 'x',
      endpoint: 'editor',
      fields: {
        app_name: 'string',
        app_id: 'string',
        button_state: 'string',
      },
    },
    MAIN_BUTTON_CLICK: {
      evid: 'x',
      src: 'x',
      endpoint: 'editor',
      fields: {
        app_name: 'string',
        app_id: 'string',
        button_state: 'string',
        is_first: 'boolean',
      },
    },
    MENU_OPENED: {
      evid: 'x',
      src: 'x',
      endpoint: 'editor',
      fields: {
        app_name: 'string',
        app_id: 'string',
        button_state: 'string',
        app_state: 'string',
        origin: 'string',
        is_first: 'boolean',
      },
    },
  },

  firstTimeTour: {
    template: {
      STEP_1_CONTINUE_CLICK: {
        evid: 198,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_1_CLOSE_CLICK: {
        evid: 199,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_2_WELCOME_CONTINUE_CLICK: {
        evid: 200,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_2_WELCOME_CLOSE_CLICK: {
        evid: 201,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_1_WELCOME_CONTINUE_CLICK: {
        evid: 202,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_1_WELCOME_CLOSE_CLICK: {
        evid: 204,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
    },
    manuallyInstalled: {
      STEP_1_WELCOME_CONTINUE_CLICK: {
        evid: 202,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_1_WELCOME_CLOSE_CLICK: {
        evid: 204,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
    },
    promotional: {
      INSTALL_AGREE_CLICK: {
        evid: 193,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      INSTALL_CLOSE_CLICK: {
        evid: 194,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      INSTALL_FOUND_OUT_MORE_CLICK: {
        evid: 195,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_2_WELCOME_CONTINUE_CLICK: {
        evid: 196,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
      STEP_2_WELCOME_CLOSE_CLICK: {
        evid: 197,
        src: 38,
        endpoint: 'editor',
        fields: {
          app_id: 'string',
          app_name: 'string',
        },
      },
    },
  },

  PANEL_SELECT: {
    evid: 204,
    src: 38,
    endpoint: 'editor',
    fields: {
      panel: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
  MANAGE_PANEL_BUTTON_CLICK: {
    evid: 205,
    src: 38,
    endpoint: 'editor',
    fields: {
      button_name: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
  UPGRADE_TOOLTIP_OPENED: {
    evid: 206,
    src: 38,
    endpoint: 'editor',
    fields: {
      origin: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
  UPGRADE_CLICK: {
    evid: 207,
    src: 38,
    endpoint: 'editor',
    fields: {
      origin: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
  LEARN_MORE_PANEL_CATEGORY_CLICK: {
    evid: 209,
    src: 38,
    endpoint: 'editor',
    fields: {
      category: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
  HELP_ICON_CLICK: {
    evid: 210,
    src: 38,
    endpoint: 'editor',
    fields: {
      panel: 'string',
      app_id: 'string',
      app_name: 'string',
    },
  },
  CLICK_ON_APP_ACTION: {
    evid: 205,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      tab_name: 'string',
      action_type: 'string', // check AppActionType
      action_id: 'string',
    },
  },
} as const;
export enum BIAppActionType {
  Main = 'main',
  Quick = 'quick',
  General = 'general',
}

const mixins = [core.mixins.editorAPIMixin];
const registerEvents = _.noop;

export { mixins, events as superAppEvents, registerEvents };

import React from 'react';
import * as panels from '#packages/panels';
import * as symbols from '@wix/santa-editor-symbols';
import { Button, Text, TextLabel, Tooltip } from '@wix/wix-base-ui';
import { AddPageNotificationBanner } from '../addPagePanel/components/addPageNotificationBanner';
import { cx } from '#packages/util';

import type { PagePresetDefinition } from '../../';
import type { AddPageCategory } from '#packages/addPreset';

interface SinglePreviewModalProps {
  pagePresetDefinition: PagePresetDefinition;
  panelName: string;
  addPage: () => void;
  closeModal: () => void;
  isPremiumSite: boolean;
  category: AddPageCategory;
  upgrade: (category: AddPageCategory) => void;
  isReplacePage: boolean;
  openHelpCenter: () => void;
  useExtraPreviewHeight?: boolean;
  shouldShowUniquePageMessage: (
    selectedCategory: AddPageCategory,
  ) => string | undefined;
  width: number;
  height: number;
  previewComponent: React.FC | null | React.ReactElement;
}

const SingleThumbnailPreviewModal = ({
  panelName,
  addPage,
  closeModal,
  pagePresetDefinition,
  isPremiumSite,
  isReplacePage,
  category,
  upgrade,
  openHelpCenter,
  shouldShowUniquePageMessage,
  width,
  height,
  previewComponent,
  useExtraPreviewHeight,
}: SinglePreviewModalProps) => {
  const { pageTitleKey } = pagePresetDefinition;
  const shouldShowNotification = shouldShowUniquePageMessage(category);
  const addPageDisabled = !isPremiumSite && category.isPremium;

  const tooltipContent = (
    <>
      <Text weight="thin" size="small">
        {'add_page_preset_404_preview_upgrade_add_tooltip_text_1'}
      </Text>
      &nbsp;
      <span
        onClick={() => upgrade(category)}
        className={'tooltip-upgrade-link'}
      >
        <Text weight="thin" size="small" skin="premium">
          {'add_page_preset_404_preview_upgrade_add_tooltip_link'}
        </Text>
      </span>
      <Text weight="thin" size="small">
        {'add_page_preset_404_preview_upgrade_add_tooltip_text_2'}
      </Text>
    </>
  );

  return (
    <panels.frames.FocusPanelFrame
      panelName={panelName}
      shouldHideHeader={true}
      className="add-page-panel-preview-thumbnail"
      width={width}
      height={height}
      disableBlockingScroll={true}
    >
      {shouldShowNotification && (
        <AddPageNotificationBanner
          isPremiumSite={isPremiumSite}
          category={category}
          isReplacePage={isReplacePage}
          upgrade={upgrade}
          openHelpCenter={openHelpCenter}
          isPreview={true}
        />
      )}
      <div className="x-button" onClick={closeModal}>
        <symbols.symbol name="headerCloseButton" />
      </div>
      <div className="thumbnail-preview-wrapper">{previewComponent}</div>
      <footer
        className={cx('thumbnail-preview-footer', {
          'extra-footer-height': useExtraPreviewHeight, //TODO: Should be removed when previewer fully merged
        })}
      >
        <TextLabel
          type="T06"
          className="thumbnail-preview-title"
          value={pageTitleKey}
        />
        <Tooltip
          content={tooltipContent}
          interactive={true}
          disabled={!addPageDisabled}
          marginTop={10}
        >
          <Button
            onClick={addPage}
            disabled={addPageDisabled}
            className="primary add-page-footer-button"
          >
            <TextLabel
              value={
                isReplacePage
                  ? 'add_page_preset_404_replace_popup_replace_button'
                  : 'add_page_preset_add_button'
              }
            />
          </Button>
        </Tooltip>
      </footer>
    </panels.frames.FocusPanelFrame>
  );
};

export default SingleThumbnailPreviewModal;

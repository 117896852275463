import React from 'react';
import styles from './horizontalLoader.scss';
import * as util from '#packages/util';

interface HorizontalLoaderProps {
  shouldShow: boolean;
}

const HorizontalLoader: React.FC<HorizontalLoaderProps> = React.memo(
  ({ shouldShow }) => {
    return (
      <div
        className={styles.siteCreationHorizontalLoader}
        style={
          shouldShow
            ? {
                backgroundImage: `url(${util.media.getMediaUrl(
                  `siteCreation/footerLoader3.gif?i=${Date.now()}`, // Need to requery the gif in order to lad
                )})`,
              }
            : null
        }
      />
    );
  },
);

HorizontalLoader.displayName = 'HorizontalLoader';

export default HorizontalLoader;

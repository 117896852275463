import React from 'react';
import { utils as themeUtils } from '#packages/theme';

import ColorDrop from './colorDrop';
import IconButton from './iconButton';
import type { ColorPalette } from 'types/documentServices';

interface Props {
  tooltipValue?: AnyFixMe;
  value?: string | number;
  isBackColor?: boolean;
  onClick: (event: React.MouseEvent) => void;
  automationId?: string;
  className?: string;
  themeColors?: ColorPalette;
  tooltipStyleType?: AnyFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'textColor';

  static defaultProps = {
    isBackColor: false,
  };

  getBiName = () => {
    return this.props.isBackColor ? 'textHighlight' : 'textColor';
  };

  getReportedFields = () => {
    return this.props.isBackColor
      ? {
          target: 'click_to_open',
        }
      : {};
  };

  getColor = () => {
    return themeUtils.fromThemeColorToHex(
      this.props.value as string,
      this.props.themeColors,
    );
  };

  render() {
    return (
      <span className={this.props.className}>
        <IconButton
          onClick={this.props.onClick}
          tooltipValue={this.props.tooltipValue}
          biName={this.getBiName()}
          reportedFields={this.getReportedFields()}
          automationId={this.props.automationId}
        >
          <ColorDrop
            isBackColor={this.props.isBackColor}
            fill={this.getColor()}
          />
        </IconButton>
      </span>
    );
  }
}

import * as stateManagement from '#packages/stateManagement';

import { createSelector } from '../../selectors/selector';
import { isResizingSel, isDraggingSel } from '../selectors/mouseSelectors';
import { editorStateSel, editorAPISel } from '../selectors/rootSelectors';
import { zoomModeSel, isPinModeSel } from '../selectors/other';

export const canShowAddSectionSelector = createSelector(
  [
    zoomModeSel,
    isPinModeSel,
    isResizingSel,
    isDraggingSel,
    editorStateSel,
    editorAPISel,
  ],
  (isZoomMode, isPinMode, isResizing, isDragging, editorState, editorAPI) =>
    !isZoomMode &&
    !isPinMode &&
    !isResizing &&
    !isDragging &&
    !editorAPI.dsRead?.pages.popupPages.isPopupOpened() &&
    !stateManagement.interactions.selectors.isInInteractionMode(editorState) &&
    !stateManagement.multilingual.selectors.isTranslating(editorState) &&
    !editorAPI.componentFocusMode.isEnabled(),
);

export const shouldShowSelector = createSelector(
  [canShowAddSectionSelector, editorStateSel, editorAPISel],
  (canShowAddSection, editorState, editorAPI) =>
    canShowAddSection &&
    !editorAPI.isMobileEditor() &&
    Boolean(
      stateManagement.sectionsOnStage.selectors.getHoveredSection(editorState),
    ),
);

import React from 'react';

import { frames } from '@/panels';
import { constants as platformConsts } from '@/platform';

import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';

interface Props {
  url: string;
  token: string;
  title: string;
  appDefinitionId?: string;
  initialData: unknown;
  initialPosition?: {
    x?: string;
    y?: string;
  };
  width?: number;
  height?: number;
  closeWithUserIntent?: boolean;
  isHidden?: boolean;
}

const FloatingToolPanel = (props: Props) => {
  return (
    <frames.TpaSettingsPanelFrame
      closeWithUserIntent={props.closeWithUserIntent}
      panelName={platformConsts.panelTypes.TOOL_FLOATING}
      title={props.title}
      width={props.width}
      height={props.height}
      top={props.initialPosition?.y}
      left={props.initialPosition?.x}
      shouldAddScroll={false}
      isHidden={props.isHidden}
      style={{ overflowY: 'hidden' }}
    >
      <PlatformPanelApplicationFrame
        token={props.token}
        url={props.url}
        initialData={props.initialData}
        panelClass="platformFloatingToolPanel"
        appDefinitionId={props.appDefinitionId}
        scrolling="yes"
      />
    </frames.TpaSettingsPanelFrame>
  );
};

export default FloatingToolPanel;

import _ from 'lodash';
import * as util from '#packages/util';
import * as popups from '#packages/popups';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { Checkbox, CustomScroll } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import pageItem from './pageItem';
import { ToolPanelFrame } from '../../frames';
import type { CompRef } from 'types/documentServices';

function treeNodeTmpl(this: any) {
  const TreeView = this.getTreeViewClass();
  const TreeNodeContent = this.props.nodeContent;
  const numOfChildren = this.getChildren().length;

  return (
    <li
      data-numofchildren={numOfChildren}
      className={util.cx({
        'pages-tree-node': true,
        'has-children': this.hasChildren(),
      })}
    >
      <TreeNodeContent {...this.passNodeContentProps(this.props.dataSource)} />
      <TreeView isCollapsed={this.state.collapsed} {...this.passNodeProps()} />
    </li>
  );
}

interface PageSelectorPanelProps {
  width: string;
  top: string;
  left: string;
  onSelectedPagesChange?: (oldPageIds: string[], newPageIds: string[]) => void;
}

class PagesSelectorPanel extends React.Component<
  PagesSelectorPanelStateProps &
    PagesSelectorPanelDispatchProps &
    PageSelectorPanelProps
> {
  static displayName = 'pagesSelectorPanel';

  getFrameStyle = () => {
    return _.pick(this.props, ['top', 'left', 'width']);
  };

  toggleOpenOnPage = (pageId: AnyFixMe, shouldDisplay: AnyFixMe) => {
    if (shouldDisplay) {
      this.props.assignOpenBehaviorToPage(pageId);
    } else {
      this.props.removeOpenBehaviorFromPage(pageId);
    }
    const oldSelectedPageIds = this.props.selectedPageIds;
    const newSelectedPageIds = shouldDisplay
      ? [...oldSelectedPageIds, pageId]
      : oldSelectedPageIds.filter((id) => id !== pageId);
    this.props.onSelectedPagesChange(oldSelectedPageIds, newSelectedPageIds);
  };

  toggleOpenOnAllPages = (shouldDisplay: AnyFixMe) => {
    if (shouldDisplay) {
      this.props.assignOpenBehaviorToAllPages();
    } else {
      this.props.removeOpenBehaviorFromAllPages();
    }
    const oldSelectedPageIds = this.props.selectedPageIds;
    const newSelectedPageIds = shouldDisplay ? this.props.availablePageIds : [];
    this.props.onSelectedPagesChange(oldSelectedPageIds, newSelectedPageIds);
  };

  validatePage = (store: AnyFixMe, pageId: AnyFixMe) => {
    if (_.has(store.occupiedPages, pageId)) {
      return {
        valid: false,
        message: translate('Popups_Settings_Triggers_Page_Has_Popup_Tooltip', {
          POPUP_NAME: `"${store.occupiedPages[pageId]}"`,
        }),
      };
    }
  };

  getKeyboardShortcuts = () => {
    const shortcuts: AnyFixMe = {};
    const SPECIAL_KEY_VALUE = util.browserUtil.getKeyboardSpecialKey().value;

    shortcuts[`${SPECIAL_KEY_VALUE} + z`] = this.props.undo;
    shortcuts[
      [`${SPECIAL_KEY_VALUE} + y`, `${SPECIAL_KEY_VALUE} + shift + z`].join(',')
    ] = this.props.redo;

    return shortcuts;
  };

  getTreeDataSource = () => {
    const dynamicPages = _.flatMap(this.props.dynamicPagesTree, 'items');
    return this.props.pagesItems.concat(dynamicPages);
  };

  render() {
    if (!this.props.currentPopup) {
      return null;
    }

    return (
      <ToolPanelFrame
        contentStyle={this.getFrameStyle()}
        key="pages-selector-panel"
        keyboardShortcuts={this.getKeyboardShortcuts()}
        contentWrapperClass="pages-selector-panel"
        headerTitle={translate('Popups_Settings_Pages_Panel_Header')}
        {..._.omit(this.props, 'children')}
      >
        {this.props.selectAll.visible ? (
          <div key="selectAll_description" className="description-block">
            {translate('Popups_Settings_Pages_Panel_Text')}
          </div>
        ) : null}

        {this.props.selectAll.visible ? (
          <div key="selectAll_control" className="select-all-block">
            <Checkbox
              disabled={this.props.selectAll.disabled}
              labelAfterSymbol={true}
              value={this.props.selectAll.checked}
              indeterminate={this.props.selectAll.indeterminate}
              onChange={this.toggleOpenOnAllPages}
              label="Popups_Settings_Pages_All_Toggle"
            />
          </div>
        ) : null}

        <CustomScroll>
          <div className="pages-selector-scrollbox">
            <baseUI.treeView
              dataSource={this.getTreeDataSource()}
              nodeContent={pageItem}
              nodeTemplate={treeNodeTmpl}
              validatePage={_.partial(this.validatePage, this.props)}
              selectedPageIds={this.props.selectedPageIds}
              toggleSelection={this.toggleOpenOnPage}
              isRoot={true}
              className={'pages-tree '}
            />
          </div>
        </CustomScroll>
      </ToolPanelFrame>
    );
  }
}

interface PagesSelectorPanelStateProps {
  currentPopup: CompRef;
  dynamicPagesTree: any;
  pagesItems: any;
  selectAll: any;
  selectedPageIds: string[];
  availablePageIds: string[];
}

interface PagesSelectorPanelDispatchProps {
  assignOpenBehaviorToPage: (pageId: string) => void;
  removeOpenBehaviorFromPage: (pageId: string) => void;
  assignOpenBehaviorToAllPages: () => void;
  removeOpenBehaviorFromAllPages: () => void;
  undo(): void;
  redo(): void;
}

const mapStateToProps = ({ editorAPI }: AnyFixMe) =>
  popups.mappers.pagesSelectorPanel(editorAPI);

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    assignOpenBehaviorToPage: (pageId: string) => {
      popups.actions.openBehaviors.assignOpenBehaviorToPage(editorAPI, pageId);
    },
    removeOpenBehaviorFromPage: (pageId: string) => {
      popups.actions.openBehaviors.removeOpenBehaviorFromPage(
        editorAPI,
        pageId,
      );
    },
    assignOpenBehaviorToAllPages: () => {
      popups.actions.openBehaviors.assignOpenBehaviorToAllPages(editorAPI);
    },
    removeOpenBehaviorFromAllPages: () => {
      popups.actions.openBehaviors.removeOpenBehaviorFromAllPages(editorAPI);
    },
    get selectedPageIds() {
      return popups.mappers.pagesSelectorPanel(editorAPI).selectedPageIds;
    },
    get availablePageIds() {
      return popups.mappers.pagesSelectorPanel(editorAPI).availablePageIds;
    },
    undo: editorAPI.history.undo,
    redo: editorAPI.history.redo,
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PagesSelectorPanel);

import type { EditorAPI } from '#packages/editorAPI';

import type { ConditionStructure, ConditionTranslations } from '../types';

const METADATA_KEY = 'isDesktopEditorRequired';
const TRANSLATION_KEY = 'isDesktopEditorCondition';

export interface DesktopEditorCondition {
  extras: {
    [METADATA_KEY]?: boolean;
  };
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export const getDesktopEditorConditionStructure = (
  editorAPI: EditorAPI,
): ConditionStructure<
  typeof METADATA_KEY,
  typeof TRANSLATION_KEY,
  DesktopEditorCondition
> => ({
  metadataKey: METADATA_KEY,
  translationsKey: TRANSLATION_KEY,
  check: () => editorAPI.isMobileEditor(),
  getAction: () => () =>
    editorAPI.setEditorMode(false, 'editorSource_conditionManager'),
});

// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import React from 'react';
import * as panels from '#packages/panels';
import * as baseUI from '#packages/baseUI';
import { Checkbox, Composites, TextInput, TextLabel } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'publishConfigurationPanel',
  mixins: [ReactLinkedStateMixin, core.mixins.editorAPIMixin], //eslint-disable-line react/no-deprecated
  propTypes: {
    onConfirm: PropTypes.func.isRequired,
    panelName: PropTypes.string,
  },
  getInitialState() {
    return {
      isSiteContributor: false,
      isFirstPublish: true,
      isDomainConnected: false,
      isPremiumUser: true,
      shouldPublishSucceed: true,
      sitePremiumDomain: 'http://www.published-site-domain.com',
    };
  },
  onConfirm() {
    this.props.onConfirm({
      isSiteContributor: this.state.isSiteContributor,
      isFirstPublish: this.state.isFirstPublish,
      isDomainConnected: this.state.isDomainConnected,
      sitePremiumDomain: this.state.sitePremiumDomain,
      isPremiumUser: this.state.isPremiumUser,
      shouldPublishSucceed: this.state.shouldPublishSucceed,
    });
  },
  close() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  render() {
    return (
      <panels.frames.EmptyPanelFrame
        panelName={this.props.panelName}
        className="save-configuration-panel"
      >
        <a onClick={this.close} className="close-button">
          x
        </a>
        <h2
          style={{
            textAlign: 'center',
          }}
        >
          Publish Testing Configuration
        </h2>

        <Checkbox
          label="Is Contributor to site"
          valueLink={this.linkState('isSiteContributor')}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Checkbox
          label="Did Publish Succeed"
          valueLink={this.linkState('shouldPublishSucceed')}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Checkbox
          label="Site is Premium"
          valueLink={this.linkState('isPremiumUser')}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Checkbox
          label="First Publish"
          valueLink={this.linkState('isFirstPublish')}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Checkbox
          label="Is Domain Connected"
          valueLink={this.linkState('isDomainConnected')}
          shouldTranslate={false}
          labelAfterSymbol={true}
          className="checkbox-field"
        />
        <Composites.TextInputLabeled>
          <TextLabel value={'Site address (Domain Name)'} />
          <TextInput
            value={this.getState('sitePremiumDomain')}
            onChange={this.linkState('sitePremiumDomain').requestChange}
          />
        </Composites.TextInputLabeled>
        <baseUI.button
          disabled={
            !this.state.isPremiumUser &&
            this.state.isDomainConnected &&
            this.state.shouldPublishSucceed
          }
          shouldTranslate={false}
          label="Start Publish"
          onClick={this.onConfirm}
          className="confirm-button"
        />
      </panels.frames.EmptyPanelFrame>
    );
  },
});

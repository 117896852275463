// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as compPanelInfra from '#packages/compPanelInfra';
import * as util from '#packages/util';
import * as panelUtils from '#packages/panelUtils';
import * as textControls from '#packages/textControls';
import React from 'react';
import { Composites, Divider, TextLabel, InfoIcon } from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';
import * as helpIds from '#packages/helpIds';
import experiment from 'experiment';
import constants from '#packages/constants';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'wixappsFontCustomizationPanel',

  mixins: [
    compPanelInfra.compPanelMixin,
    util.propTypesFilterMixin,
    compPanelInfra.wixappsPanelMixin,
    panelUtils.linkColorPickerMixin,
  ],

  propTypes: {
    fieldIds: PropTypes.arrayOf(PropTypes.string).isRequired,
    forType: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    view: PropTypes.string.isRequired,
    customizeTheme: PropTypes.bool,
  },

  _getAppPartCustomizationRulesForCompParam(compParam) {
    return this.props.fieldIds.map(function (fieldId) {
      return {
        fieldId,
        forType: this.props.forType,
        format: this.props.format,
        key: `comp.${compParam}`,
        type: 'AppPartCustomization',
        view: this.props.view,
      };
    }, this);
  },

  _getValueFromCustomizationsOrDefaultValues(compParam) {
    const rules = this._getAppPartCustomizationRulesForCompParam(compParam);
    while (rules.length) {
      const rule = rules.shift();
      const valueLink = this.linkedCustomization(rule);
      if (valueLink.value) {
        return valueLink.value;
      }
    }
  },

  _getValueFromCurrentStyle(compParam) {
    const editorAPI = this.getEditorAPI();
    const fontClass = this.getTheme();
    const currentStyle = textControls.fontUtils.parseStyleFont(
      fontClass,
      // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
      editorAPI.dsRead.theme.fonts.getAll(),
      editorAPI.theme.colors.getAll(),
    );

    return currentStyle[
      {
        fontFamily: 'family',
        fontSize: 'size',
        bold: 'bold',
        italic: 'italic',
        color: 'cssColor',
        backgroundColor: 'color',
      }[compParam]
    ];
  },

  _getValue(compParam) {
    let value = this._getValueFromCustomizationsOrDefaultValues(compParam);
    if (value === undefined) {
      value = this._getValueFromCurrentStyle(compParam);
    }
    return value;
  },

  _setValue(compParam, value) {
    const rules = this._getAppPartCustomizationRulesForCompParam(compParam);
    rules.forEach(function (rule) {
      this.linkedCustomization(rule).requestChange(value);
    }, this);
  },

  getFontFamily() {
    return this._getValue('fontFamily');
  },

  setFontFamily(fontFamily) {
    this._setValue('fontFamily', fontFamily);
  },

  getFontSize() {
    return this._getValue('fontSize');
  },

  setFontSize(fontSize) {
    this._setValue('fontSize', fontSize);
  },

  _getBooleanCustomizationValue(compParam) {
    return util.stringUtils.isTrue(this._getValue(compParam));
  },

  _setBooleanCustomizationValue(compParam, value) {
    this._setValue(compParam, value ? 'true' : 'false');
  },

  isBold() {
    return this._getBooleanCustomizationValue('bold');
  },

  setBold(isBold) {
    this._setBooleanCustomizationValue('bold', isBold);
  },

  isItalic() {
    return this._getBooleanCustomizationValue('italic');
  },

  setItalic(isItalic) {
    this._setBooleanCustomizationValue('italic', isItalic);
  },

  isUnderlined() {
    return this._getBooleanCustomizationValue('underline');
  },

  setUnderlined(isUnderlined) {
    this._setBooleanCustomizationValue('underline', isUnderlined);
  },

  openTextColorPicker(onChange, colorValue) {
    const ALLOW_PALETTE_EDITING = true;
    const position = { top: this.props.style.top, left: this.props.style.left };
    this.openColorPicker(onChange, colorValue, ALLOW_PALETTE_EDITING, position);
  },

  getColorValue(colorCompParam) {
    return this._getValue(colorCompParam) || 'noColor';
  },

  setAppPartCustomizationValueForColorCompParam(colorCompParam, color) {
    this._setValue(colorCompParam, color && color !== 'noColor' ? color : null);
  },

  getColor() {
    return this.getColorValue('color');
  },

  setColor(color) {
    this.setAppPartCustomizationValueForColorCompParam('color', color);
  },

  getBackgroundColor() {
    return this.getColorValue('backgroundColor');
  },

  setBackgroundColor(backgroundColor) {
    const color = /^color_/.test(backgroundColor)
      ? `back${backgroundColor}`
      : backgroundColor;
    // TODO: I think the panel shouldn't know about color pattern. If you know how to do it better, please, do.

    this.setAppPartCustomizationValueForColorCompParam(
      'backgroundColor',
      color,
    );
  },

  handleNoColor() {
    this.setAppPartCustomizationValueForColorCompParam(
      'backgroundColor',
      'transparent',
    );
  },

  getStyle() {
    return this._getValueFromCustomizationsOrDefaultValues('style');
  },

  setStyle(style) {
    this.setFontFamily(null);
    this.setFontSize(null);
    this._setValue('style', style);
  },

  getTheme() {
    const style = this.getStyle();
    return (
      this.getEditorAPI().dsRead.wixapps.classics.styleMapping.styleToFontClass(
        style,
      ) || 'font_8'
    );
  },

  setTheme(fontTheme) {
    const style =
      this.getEditorAPI().dsRead.wixapps.classics.styleMapping.fontClassToStyle(
        fontTheme,
      );
    this.setStyle(style);
  },

  getLineHeightValue() {
    const lineHeight = this._getValue('line-height') || 'auto';
    return lineHeight === 'auto' ? '' : lineHeight;
  },

  setLineHeight(lineHeight) {
    this._setValue('line-height', lineHeight);
  },

  render() {
    const blogLineSpacingExp = experiment.isOpen('blogLineSpacing');
    const editorAPI = this.getEditorAPI();
    const smallDesign = constants.UI.TOOLTIP.STYLE_TYPE.SMALL;
    const isBold = this.isBold();
    const isItalic = this.isItalic();
    const isUnderlined = this.isUnderlined();
    const colorValue = this.resolveColor(this.getColor());
    const backgroundColorValue = this.resolveColor(this.getBackgroundColor());

    return (
      <compPanelInfra.compPanelFrame
        panelName="wixappsFontCustomizationPanel"
        title={this.props.title}
        helpId={helpIds.SETTINGS.WRICHTEXT}
        {...this.filteredProps()}
        className={cx('settings-panel', this.filteredProps()?.className)}
      >
        {this.props.customizeTheme ? (
          <textControls.textStyle
            key="textStyleCustomization"
            label="text_editor_theme_label"
            infoText="text_editor_tooltip_theme"
            value={this.getTheme()}
            onChange={this.setTheme}
            themeColors={this.getEditorAPI().theme.colors.getAll()}
            themeFonts={this.getEditorAPI().dsRead.theme.fonts.getAll()}
            isDeveloperMode={this.getEditorAPI().developerMode.isEnabled()}
          />
        ) : null}

        {this.props.customizeTheme ? (
          <Divider long={true} key="textStyleCustomizationSeparator" />
        ) : null}

        <textControls.fontFamily
          fonts={textControls.fontUtils.getCurrentSelectableFontsWithParams(
            editorAPI,
          )}
          infoText="text_editor_font_tooltip"
          label="text_editor_font_label"
          onChange={(fontFamily) => {
            this.setFontFamily(fontFamily);
          }}
          value={this.getFontFamily()}
        />

        <Divider long={true} />

        {this.props.customizeTheme && (
          <Composites.SliderLabeled>
            <InfoIcon text="ext_editor_font_size_tooltip" />

            <TextLabel value="text_editor_mobile_font_size" />

            <baseUI.WixBaseUISliderWithBI
              value={this.getFontSize()}
              onChange={(fontSize) => {
                this.setFontSize(fontSize);
              }}
              min={textControls.constants.FONT_SIZE.MIN}
              max={textControls.constants.FONT_SIZE.MAX}
              inputMin={textControls.constants.FONT_SIZE.MIN}
              inputMax={textControls.constants.FONT_SIZE.MAX}
              key="fontSizeCustomization"
              label="text_editor_mobile_font_size"
            />
          </Composites.SliderLabeled>
        )}

        {this.props.customizeTheme ? (
          <Divider long={true} key="fontSizeCustomizationSeparator" />
        ) : null}

        <div className="button-row">
          <textControls.iconButton
            isSelected={isBold}
            name="textBold"
            tooltipValue="text_editor_buttons_tooltip_bold"
            tooltipStyleType={smallDesign}
            onClick={() => {
              this.setBold(!isBold);
            }}
          />

          <textControls.iconButton
            isSelected={isItalic}
            name="textItalic"
            tooltipValue="text_editor_buttons_tooltip_italic"
            tooltipStyleType={smallDesign}
            onClick={() => {
              this.setItalic(!isItalic);
            }}
          />

          <textControls.iconButton
            isSelected={isUnderlined}
            name="textUnderline"
            tooltipValue="text_editor_buttons_tooltip_underline"
            tooltipStyleType={smallDesign}
            onClick={() => {
              this.setUnderlined(!isUnderlined);
            }}
          />

          <textControls.textColor
            onClick={() => {
              this.openTextColorPicker(this.setColor, colorValue);
            }}
            tooltipValue="text_editor_buttons_tooltip_text_color"
            tooltipStyleType={smallDesign}
            value={colorValue}
          />

          <textControls.textBackgroundColor
            onClick={() => {
              this.openTextColorPicker(this.setBackgroundColor, 'noColor');
            }}
            tooltipValue="text_editor_buttons_tooltip_text_highlight"
            tooltipStyleType={smallDesign}
            handleNoColor={this.handleNoColor}
            color={
              backgroundColorValue !== 'transparent'
                ? backgroundColorValue
                : null
            }
          />
        </div>

        {blogLineSpacingExp ? (
          <Divider long={true} key="blogLineSpacingExpDivider" />
        ) : null}
        {blogLineSpacingExp ? (
          <baseUI.accordion
            key="blogLineSpacingExpComp"
            label="text_editor_spacing_label"
          >
            <textControls.lineHeight
              infoText="text_editor_line_spacing_tooltip"
              value={this.getLineHeightValue()}
              onChange={(value) => {
                this.setLineHeight(value);
              }}
              onSlideEnd={this.focus}
            />
          </baseUI.accordion>
        ) : null}
      </compPanelInfra.compPanelFrame>
    );
  },
});

import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import * as coreBi from '#packages/coreBi';
import { bi } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import { SWITCH_LAYOUT_PANEL_TYPE } from '#packages/switchLayout';

const SWITCH_LAYOUT_PANEL_SHORTCUTS_CONFIG = {};
const keyboardMap = keyboardShortcutsActionMap.editor;

function addSwitchLayoutPanelModeShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};
  const noop = () => {};

  keysToMerge[keyboardMap.DELETE.key] = noop;
  keysToMerge[keyboardMap.DUPLICATE.key] = noop;
  keysToMerge[keyboardMap.CLOSE_PANELS.key] = () => {
    if (editorAPI.zoomMode.isZoomedByUser()) {
      editorAPI.zoomMode.exitZoomMode({
        zoomScale: 0.5,
        biParams: { origin: 'keyboard' },
        clickedByUser: editorAPI.zoomMode.isZoomedByUser(),
      });
    } else {
      editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'keyboard' } });
    }
    editorAPI.store.dispatch(
      bi.actions.event(coreBi.events.editor.ESCAPE_BUTTON_CLICKED, {
        mode: SWITCH_LAYOUT_PANEL_TYPE,
      }),
    );
  };

  Object.assign(SWITCH_LAYOUT_PANEL_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addSwitchLayoutPanelModeShortcuts(editorAPI);
  },
  shortcuts: SWITCH_LAYOUT_PANEL_SHORTCUTS_CONFIG,
};

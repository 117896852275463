// @ts-nocheck
import ReactDOM from 'reactDOM';
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';

export default {
  /**
   *
   * @param e
   * @param {dragItemData} dragItemData
   */
  startItemDrag(e, dragItemData) {
    const editorAPI = this.getEditorAPI();
    if (e.button === 2) {
      // right click
      return;
    }

    dragItemData.rect = _.transform(
      dragItemData.rect,
      function (result, val, key) {
        result[key] = parseInt(val, 10);
      },
    );

    const dragParams = {
      item: dragItemData,
      dragBoxClass: editorAPI.addPanelInfra.dragBox,
      panelElm: ReactDOM.findDOMNode(this),
      evt: e,
      onItemDragEnd: this.onStopItemDrag,
      onItemDragStart: this.onStartItemDrag,
    };

    editorAPI.mouseActions.registerMouseMoveAction(
      editorAPI.addPanelInfra.addPanelDragToStage,
      dragParams,
    );
    editorAPI.reportAddMenuDrag({
      preset_id: dragItemData.itemId || '',
      category: dragItemData.categoryId,
      section: dragItemData.sectionTitle,
    });

    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        'addPanel.dragToStage',
        true,
      ),
    );
  },
  onStopItemDrag() {
    this.setState({ isDragging: false });
  },
  onStartItemDrag() {
    if (!this.state.isDragging) {
      this.setState({ isDragging: true });
    }
  },
};

import { tpaUtils } from '#packages/util';

const ECOM_APP_DEF_ID = tpaUtils.getStoresAppDefId();
const BLOG_APP_DEF_ID = '61f33d50-3002-4882-ae86-d319c1a249ab';
const NEW_BLOG_APP_DEF_ID = '14bcded7-0066-7c35-14d7-466cb3f09103';
const WIXMUSIC_APP_DEF_ID = '13bb5d67-1add-e770-a71f-001277e17c57';
const HOTELS_APP_DEF_ID = '135aad86-9125-6074-7346-29dc6a3c9bcf';
const GET_SUBSCRIBERS_APP_DEF_ID = '1375baa8-8eca-5659-ce9d-455b2009250d';
const WIX_BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
const SHOUTOUT_APP_DEF_ID = '135c3d92-0fea-1f9d-2ba5-2a1dfb04297e';
const PRO_GALLERY_APP_DEF_ID = '14271d6f-ba62-d045-549b-ab972ae1f70e';
const MEMBER_PROFILE_CARD_APP_DEF_ID = '14ce28f7-7eb0-3745-22f8-074b0e2401fb';
const MEMBERS_WIDGET_APP_DEF_ID = '1518f596-5744-ae33-0524-4fe42c3ee64e';
const MEMBERS_STAFF_WIDGET_APP_DEF_ID = 'cffc6740-8042-48cc-a35b-d3fd03a69f0c';
const WIX_CHAT_APP_DEF_ID = '14517e1a-3ff0-af98-408e-2bd6953c36a2';
const WIX_VIDEO_APP_DEF_ID = '14409595-f076-4753-8303-9a86f9f71469';
const WIX_SOUNDCLOUD_APP_DEF_ID = '14a85b13-d7da-ee8e-7290-3b93340f63fa';
const MULTI_LINGUAL_APP_DEF_ID = '14d84998-ae09-1abf-c6fc-3f3cace5bf19';
const WIX_EVENTS_APP_DEF_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351';
const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
const AUTOMATIONS_APP_DEF_ID = '139ef4fa-c108-8f9a-c7be-d5f492a2c939';
const FORMS_APP_DEF_ID = '14ce1214-b278-a7e4-1373-00cebd1bef7c';
const VIRTUAL_NUMBERS_APP_DEF_ID = '94bc563b-675f-41ad-a2a6-5494f211c47b';
const SPOTIFY_PLAYER_APP_DEF_ID = '099ac204-be88-4ed1-89af-cf14358c83f1';
const GROUPS_APP_DEF_ID = '148c2287-c669-d849-d153-463c7486a694';
const FORUM_APP_DEF_ID = '14724f35-6794-cd1a-0244-25fd138f9242';
const WIX_PROMOS_APP_DEF_ID = '7fdf5576-9d52-4c0a-9840-6e226f2ec415';

export default {
  ECOM: {
    APP_DEF_ID: ECOM_APP_DEF_ID,
    PRODUCT_PAGE: '13a94f09-2766-3c40-4a32-8edb5acdd8bc',
    GRID_GALLERY: '13afb094-84f9-739f-44fd-78d036adb028',
    SLIDER_GALLERY: '139a41fd-0b1d-975f-6f67-e8cbdf8ccc82',
    //main section
    PRODUCT_GALLERY_PAGE: ECOM_APP_DEF_ID,
    SHOPPING_CART: '1380bbab-4da3-36b0-efb4-2e0599971d14',
    TANK_YOU_PAGE: '1380bbb4-8df0-fd38-a235-88821cf3f8a4',
    SHOPPING_CART_WIDGET: '1380bbc4-1485-9d44-4616-92e36b1ead6b',
    PRODUCT_WIDGET: '13ec3e79-e668-cc0c-2d48-e99d53a213dd',
  },
  WIXMUSIC: {
    APP_DEF_ID: WIXMUSIC_APP_DEF_ID,
    WIDGET: '13bb5dc2-a098-5a74-502b-6bac84e80b23',
  },
  WIX_VIDEO: {
    APP_DEF_ID: WIX_VIDEO_APP_DEF_ID,
    WIDGET: '144097ea-fea0-498e-ade7-e6de40127106',
  },
  BLOG: {
    APP_DEF_ID: BLOG_APP_DEF_ID,
  },
  MULTI_LINGUAL_APP_DEF_ID,
  NEW_BLOG_APP_DEF_ID,
  HOTELS: {
    APP_DEF_ID: HOTELS_APP_DEF_ID,
    SEARCH_BAR: '135aae78-42c9-63b5-d09a-77233cebc1c4',
  },
  MEMBER_PROFILE_CARD: {
    APP_DEF_ID: MEMBER_PROFILE_CARD_APP_DEF_ID,
    WIDGET: '14cefc05-d163-dbb7-e4ec-cd4f2c4d6ddd',
  },
  MEMBERS_STAFF_WIDGET: {
    APP_DEF_ID: MEMBERS_STAFF_WIDGET_APP_DEF_ID,
    WIDGET: '57074dda-cdaf-4df5-8919-a41f25911331',
  },
  MEMBERS_WIDGET: {
    APP_DEF_ID: MEMBERS_WIDGET_APP_DEF_ID,
    WIDGET: '1518f81b-32a7-90d4-9927-4285b3291d1f',
  },
  MEMBERS: {
    APP_DEF_ID: MEMBERS_APP_DEF_ID,
  },
  GET_SUBSCRIBERS_APP_DEF_ID,
  WIX_BOOKINGS_APP_DEF_ID,
  WIX_EVENTS: {
    APP_DEF_ID: WIX_EVENTS_APP_DEF_ID,
    WIDGET: '1440e92d-47d8-69be-ade7-e6de40127106',
  },
  WIX_PROMOS: {
    APP_DEF_ID: WIX_PROMOS_APP_DEF_ID,
  },
  SHOUTOUT: {
    APP_DEF_ID: SHOUTOUT_APP_DEF_ID,
  },
  PRO_GALLERY: {
    APP_DEF_ID: PRO_GALLERY_APP_DEF_ID,
  },
  SPOTIFY: {
    APP_DEF_ID: SPOTIFY_PLAYER_APP_DEF_ID,
    WIDGET: '53d0a5fa-3da5-418b-84d6-e58bd55fecad',
  },
  WIX_CHAT: {
    APP_DEF_ID: WIX_CHAT_APP_DEF_ID,
    WIDGET: '14517f3f-ffc5-eced-f592-980aaa0bbb5c',
  },
  WIX_SOUNDCLOUD: {
    APP_DEF_ID: WIX_SOUNDCLOUD_APP_DEF_ID,
    WIDGET: '14a85b28-2374-72cf-db64-e0c11046aa60',
  },
  ONLINE_PROGRAMS: {
    APP_DEF_ID: '2936472a-a1ed-4ae5-9f71-614313a9f4e7',
  },
  SITE_SEARCH: {
    APP_DEF_ID: '1484cb44-49cd-5b39-9681-75188ab429de',
  },
  AUTOMATIONS: {
    APP_DEF_ID: AUTOMATIONS_APP_DEF_ID,
  },
  FORMS: {
    APP_DEF_ID: FORMS_APP_DEF_ID,
  },
  VIRTUAL_NUMBERS: {
    APP_DEF_ID: VIRTUAL_NUMBERS_APP_DEF_ID,
  },
  GROUPS: {
    APP_DEF_ID: GROUPS_APP_DEF_ID,
  },
  FORUM: {
    APP_DEF_ID: FORUM_APP_DEF_ID,
  },
};

import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import * as UA from '#packages/uiAnimations';

import withCalcPanelPositionBehavior from './behaviors/withCalcPanelPositionBehavior';

import DropDownAnchor from './dropDownAnchor';
import DropDownPanel from './dropDownPanel';
import DropDownPortal from './dropDownPortal';

import type { DropDownOwnProps, DropDownProps } from './dropDownTypes';

const { topBarMenuPanelAnimation: DropDownAnimation } = UA;
const OuterClick = util.outerClick;

class DropDown extends Component<DropDownProps> {
  static defaultProps = {
    withPortal: true,
    position: 'bottom',
  };
  renderPanel() {
    const panelContent = React.cloneElement(this.props.panelContent, {
      recalcPositions: this.props.recalcPositions,
    });
    const panel = (
      <DropDownPanel
        className={this.props.panelClassName}
        nodeRef={this.props.panelRef}
        onMouseEnter={this.props.onPanelMouseEnter}
        onMouseLeave={this.props.onPanelMouseLeave}
        arrowPositionStyle={this.props.arrowPositionStyle}
        arrowStyleOverrides={this.props.arrowStyleOverrides}
        contentPositionStyle={this.props.contentPositionStyle}
        contentStyleOverrides={this.props.contentStyleOverrides}
        panelPositionStyle={this.props.panelPositionStyle}
        arrowAlignment={this.props.position === 'left' ? 'right' : 'top'}
      >
        {panelContent}
      </DropDownPanel>
    );

    return this.props.onPanelOuterClick ? (
      <OuterClick
        outerClickShouldStopPropagation={
          this.props.outerClickShouldStopPropagation
        }
        onOuterClick={this.props.onPanelOuterClick}
      >
        {panel}
      </OuterClick>
    ) : (
      panel
    );
  }

  renderPanelOverlay() {
    return this.props.withOverlay ? (
      <div className="top-bar-drop-down-overlay" />
    ) : null;
  }

  render() {
    const outAnimationDuration = this.props.delayedFadeOut ? 0.2 : 0;
    const PortalWrapper = this.props.withPortal
      ? DropDownPortal
      : React.Fragment;

    return (
      <DropDownAnchor nodeRef={this.props.anchorRef}>
        {this.props.children}
        <PortalWrapper>
          <DropDownAnimation outAnimationDuration={outAnimationDuration}>
            {this.props.isOpen ? (
              <>
                {this.renderPanel()}
                {this.renderPanelOverlay()}
              </>
            ) : null}
          </DropDownAnimation>
        </PortalWrapper>
      </DropDownAnchor>
    );
  }
}

const DropDownWithBehaviors = _.flow(withCalcPanelPositionBehavior)(
  DropDown,
) as ComponentType<DropDownOwnProps> & { pure?: typeof DropDown };

DropDownWithBehaviors.pure = DropDown;

export default DropDownWithBehaviors;

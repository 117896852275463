import React, { useState, useEffect, useContext } from 'react';
import {
  type VersionsResponse,
  VersionStatus,
} from '@wix/ambassador-site-apps-server/types';
import {
  type AppData,
  ListView,
  usePrivateApps,
} from '../privateAppsProvider/privateAppsProvider';

import { devCenterFacade } from '#packages/serverFacade';

import * as util from '#packages/util';

interface AppVersionsData {
  versions?: VersionsResponse[];
  app?: AppData;
  error?: boolean;
}

const AppVersionsListContext = React.createContext<{
  selectedVersionListApp?: AppVersionsData;
  setSelectedVersionListApp?: (appVersionData: AppData) => void;
}>({});

export const AppVersionsListProvider: React.FC = ({ children }) => {
  const [versions, setVersions] = useState<VersionsResponse[]>(null);
  const [app, setSelectedVersionListApp] = useState<AppData>(null);
  const { setSelectedListView } = usePrivateApps();

  const getVersionDataToDisplay = (versionsData: VersionsResponse[]) => {
    const builtAppsVersionsData = versionsData.filter(
      (ver) =>
        ver.status !== VersionStatus.HIDDEN &&
        ver.status !== VersionStatus.DRAFT &&
        ver.status !== VersionStatus.IN_REVIEW &&
        ver.status !== VersionStatus.SUBMITTED,
    );

    const versionDataToDisplay: VersionsResponse[] = [];
    const addedMajorVersions = new Set();

    builtAppsVersionsData.forEach((curVersionData) => {
      const curVersion = util.appStudioUtils.getVersion(curVersionData.version);
      if (!addedMajorVersions.has(curVersion.major)) {
        addedMajorVersions.add(curVersion.major);
        versionDataToDisplay.push(curVersionData);
      }
    });

    return versionDataToDisplay;
  };
  useEffect(() => {
    const fetchAllVersions = async () => {
      if (!app?.appDefinitionId) {
        return;
      }
      try {
        const versionsData = await devCenterFacade.getVersionsByAppId(
          app?.appDefinitionId,
        );
        setVersions(getVersionDataToDisplay(versionsData));
      } catch (e) {
        setVersions(
          app ? [{ appId: app.appDefinitionId, version: app.version }] : [],
        );
      }
    };
    fetchAllVersions();
  }, [app, app?.appDefinitionId, app?.version]);

  return (
    <AppVersionsListContext.Provider
      value={{
        selectedVersionListApp: {
          app,
          versions,
        },
        setSelectedVersionListApp: (selectedApp) => {
          if (selectedApp?.appDefinitionId !== app?.appDefinitionId) {
            setVersions(null);
            setSelectedVersionListApp(selectedApp);
          }

          setSelectedListView(
            selectedApp ? ListView.AppVersions : ListView.Apps,
          );
        },
      }}
    >
      {children}
    </AppVersionsListContext.Provider>
  );
};

export const useAppVersionsListContext = () =>
  useContext(AppVersionsListContext);

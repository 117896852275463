// @ts-nocheck
// Deprecated component
// Please use DropDown instead
import React from 'react';
import PropTypes from 'prop-types';
import { Bubble } from '@wix/wix-base-ui';
import IllustratedListPanel from './illustratedListPanel';

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  left: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  left: 'calc(50% - 70px)',
  paddingLeft: 0,
  top: '60px',
};

class DropPanel extends React.Component {
  constructor(props) {
    super(props);

    this.bubbleContentStyle = BUBBLE_CONTENT_STYLE;
    this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
  }

  render() {
    return (
      <Bubble
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        arrowAlignment="top"
        contentStyle={this.bubbleContentStyle}
        arrowStyle={this.bubbleArrowStyle}
        maxWidth={750}
        className="drop-panel-top-bar"
      >
        <IllustratedListPanel
          automationId={this.props.automationId}
          menuKey={this.props.menuKey}
          sendBi={this.props.sendBi}
          items={this.props.items}
          isChecklist={this.props.isChecklist}
          closePanel={this.props.closePanel}
          hasDesignPermissions={true}
        />
      </Bubble>
    );
  }
}

DropPanel.displayName = 'DropPanel';

DropPanel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        leftTitle: PropTypes.string,
        rightTitle: PropTypes.string,
        description: PropTypes.string,
        onClick: PropTypes.func,
        symbolName: PropTypes.string,
        image: PropTypes.string,
        automationId: PropTypes.string,
        illustrationClassName: PropTypes.string,
        listItemClassName: PropTypes.string,
        isCheckbox: PropTypes.oneOf([true]),
        skipClickClosePanel: PropTypes.bool,
        experiment: PropTypes.string,
        getCounter: PropTypes.func,
        condition: PropTypes.func,
        isDisabled: PropTypes.bool,
        isNested: PropTypes.bool,
        checkBoxValue: PropTypes.bool,
      }),
      PropTypes.shape({
        isSeparator: PropTypes.oneOf([true]).isRequired,
      }),
    ]),
  ).isRequired,
  automationId: PropTypes.string,
  isChecklist: PropTypes.bool,
  isTitleOnly: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  closePanel: PropTypes.func.isRequired,
};

DropPanel.defaultProps = {
  isChecklist: false,
};

export default DropPanel;

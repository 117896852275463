import * as React from 'react';
import { Composites, Button } from '@wix/wix-base-ui';
import { ResetSmall } from '@wix/wix-ui-icons-common/classic-editor';
import styles from './scrollResetButton.scss';
import { translate } from '#packages/i18n';

import type { StyleRef } from '@wix/document-services-types';

type Tab = 'fill' | 'corners' | 'border' | 'shadow';
type StyleUpdateFn = (styleDataItem: StyleRef) => void;

const tabToPropertyMap = {
  fill: ['bg-scrl', 'alpha-bg-scrl'],
  corners: ['rd-scrl'],
  border: ['brd-scrl', 'brwb-scrl', 'alpha-brd-scrl', 'brwt-scrl'],
  shadow: ['shd-scrl', 'boxShadowToggleOn-shd-scrl'],
};

interface Props {
  currentStyle: StyleRef;
  tab: Tab;
  updateStyle: StyleUpdateFn;
  sendBi?: (biValues: {
    field_value: 'reset';
    mouse_state: 'scroll';
    tab_name: string;
  }) => void;
}
const getIsResetDisabled = (tab: Tab, style: StyleRef) => {
  const hasScrlProperty = tabToPropertyMap[tab].some((property) => {
    const propertyValue = style?.style?.properties?.[property];
    return !!propertyValue;
  });

  return !hasScrlProperty;
};

const resetScrollForTab = (
  tab: Tab,
  style: StyleRef,
  forceUpdateParentStyle: StyleUpdateFn,
) => {
  if (tab === 'fill' && style?.style?.properties) {
    // bg scroll depends on regular alpha-bg in style
    style.style.properties['alpha-bg'] = '1';
  }
  tabToPropertyMap[tab].forEach((property) => {
    delete style?.style?.properties?.[property];
  });
  forceUpdateParentStyle(style);
};

export const ScrollResetButton = ({
  updateStyle,
  tab,
  currentStyle,
  sendBi,
}: Props) => {
  const isDisabled = getIsResetDisabled(tab, currentStyle);
  const onReset = () => {
    resetScrollForTab(tab, currentStyle, updateStyle);
    sendBi?.({
      field_value: 'reset',
      mouse_state: 'scroll',
      tab_name: tab,
    });
  };

  return (
    <Composites.ButtonLarge className={styles.scrollBtnContainer}>
      <Button
        onClick={onReset}
        disabled={isDisabled}
        prefixIcon={<ResetSmall />}
        className="btn-md"
        dataHook="scroll-reset-button"
      >
        {translate('StylablePanel_Design_Scroll_Reset_Regular')}
      </Button>
    </Composites.ButtonLarge>
  );
};

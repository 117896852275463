import { Hooks } from '#packages/apilib';

export const hooks = {
  undoWasPerformed: Hooks.createHook<{
    originParams: any;
    targetParams: any;
  }>(),
  redoWasPerformed: Hooks.createHook<{
    originParams: any;
    targetParams: any;
  }>(),
};

import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  WorkspaceRightPanelApiKey,
} from '#packages/apis';
import { AIAssistantPanelStore } from './aiAssistantPanelStore';
import { AIAssistantApi, openAIAssistantPanel } from './aiAssistantAPI';
import { AIAssistantApiKey } from './publicApiKey';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '#packages/constants';
import React from 'react';
import aiAssistantPanel from './aiAssistantPanel';

export class AIAssistantPanelScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  store = this.declareStore(AIAssistantPanelStore);
  workspaceRightPanelApi = this.useDependency(WorkspaceRightPanelApiKey);
}

const contributeAIAssistantPanel = (scope: AIAssistantPanelScope) => {
  const { workspaceRightPanelApi } = scope;

  workspaceRightPanelApi.contributePanelContent(
    () => React.createElement(aiAssistantPanel),
    {
      panelName: WORKSPACE_RIGHT_PANEL_NAMES.AI_ASSISTANT,
      onOpen: () => {
        openAIAssistantPanel(scope);
      },
    },
  );
};

export const AIAssistantPanelEntryPoint = createEntryPoint({
  name: 'AIAssistantPanel',
  Scope: AIAssistantPanelScope,
  publicApi({ contributeApi }) {
    contributeApi(AIAssistantApiKey, AIAssistantApi);
  },
  async initialize(scope: AIAssistantPanelScope) {
    await scope.editorCoreAPI.hooks._dsIsReady.promise;
    contributeAIAssistantPanel(scope);
  },
});

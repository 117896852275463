import _ from 'lodash';
import * as util from '#packages/util';
import { fontUtils, colors } from '#packages/util';
import { utils as themeUtils } from '#packages/theme';
import * as stateManagement from '#packages/stateManagement';
import * as colorUtils from './colorUtils';
import constants from './constants';

import type { CompRef, Font, TextTheme } from 'types/documentServices';

import type { EditorAPI } from '#packages/editorAPI';
import type { ThemeManagerFontStyles } from '#packages/newDesignPanel';

export interface FontTheme extends Font {
  letterSpacing?: string;
  foreColor?: string;
  fontSize: number;
  fontFamily: string;
}

const { getUploadedId, parseStyleFont, isUploadedFontFamily } = fontUtils;

const THEME_FONT_REGEX = /font_([0-9]|10)(?=["'])/g;

const isFloat = (number: number): boolean =>
  typeof number === 'number' && number % 1 !== 0;

//TODO: chene: blog is using this
function getFontsUrlWithParams(
  fontFamilies: AnyFixMe,
  documentType: string,
  languages: AnyFixMe,
) {
  const result = fontUtils.getFontsUrlWithParams(
    fontFamilies,
    documentType,
    languages,
  );
  if (result) {
    return result;
  }

  return '';
}

//TODO: chene: blog is using this
function getWixStoredFontsCss(languages: AnyFixMe, documentType: AnyFixMe) {
  //Wix CDN stored fonts
  const baseUrl = window.santaBase;
  //@ts-expect-error
  let urls = fontUtils.getWixStoredFontsCssUrlsWithParams(baseUrl, languages);
  if (documentType === 'WixSite') {
    urls = urls.concat(
      `${util.serviceTopology.publicStaticsUrl}/css/Helvetica/fontFace.css`,
    );
  }
  return urls;
}

function getStyleFont(
  themeFontClass: string,
  themeColors: Record<string, string>,
  themeFonts: AnyFixMe,
): FontTheme | null {
  if (_.isString(themeFontClass)) {
    return fontUtils.parseStyleFont(themeFontClass, themeFonts, themeColors);
  }

  return null;
}

interface UploadedFont {
  fontFace: string;
}

function getUploadedFonts(editorAPI: EditorAPI): UploadedFont[] {
  const uploadedFontsSelectors = stateManagement.uploadedFonts.selectors;
  const uploadedFonts: UploadedFont[] = uploadedFontsSelectors.getList(
    editorAPI.store.getState(),
  );
  if (!_.isEmpty(uploadedFonts)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const fontItems = _.map(uploadedFonts);
    return _.sortBy(fontItems, 'displayName');
  }
  return [];
}

function getCurrentSelectableFontsWithParams(
  editorAPI: EditorAPI,
  shouldFilterUploadedFonts = false,
  isAllFonts = false,
) {
  const fonts = editorAPI?.dsRead?.fonts;
  if (fonts) {
    let fontOptions = fonts.getSiteFontsOptions();

    if (isAllFonts) {
      fontOptions = fonts.getAllFontsOptions();
    }

    if (!shouldFilterUploadedFonts) {
      fontOptions.unshift({
        lang: 'my-uploads',
        //@ts-expect-error
        fonts: getUploadedFonts(editorAPI),
      });
    }

    return fontOptions;
  }
  return [];
}

/**
 * Gets font items for the font family selector drop down
 * @param {Object} fontsByLang - getCurrentSelectablefontsWithParams output
 * @returns {Object} Font items in font family selector drop down format
 */
function getFontsDropDownItems(fontsByLang: AnyFixMe) {
  return fontUtils.getFontsDropDownItems(fontsByLang);
}

function getUsedFontsInTextComp(textData: AnyFixMe) {
  return fontUtils.collectFontsFromTextDataArray([textData]);
}

function createFontsCssString(
  themeColors: Record<string, string>,
  stylesMap: Record<string, AnyFixMe>,
  themeFonts: Record<string, string>,
) {
  const themeColorsData = colorUtils.getThemeColorData(themeColors);
  const fontNameToStyle = _.groupBy(stylesMap, 'cssClass');
  let cssString = '';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(themeFonts, function (fontValue, fontName) {
    const fontCss = `font: ${fontUtils.fontToCSSWithColor(
      fontValue,
      themeColorsData.color,
    )}`;
    const selector = createCssSelector(fontName, fontNameToStyle);
    cssString += `${selector}{${fontCss}}`;
  });
  return cssString;
}

function createCssSelector(
  fontName: string,
  fontNameToStyle: Record<string, AnyFixMe>,
) {
  let selector = '';
  if (fontNameToStyle[fontName]) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(fontNameToStyle[fontName], function (style) {
      selector += `${style.tag}, `;
    });
  }

  selector += `.${fontName}`;

  return selector;
}

function createFontStyleString(
  fontValue: string,
  themeColors: Record<string, string>,
) {
  const themeColorsData = colorUtils.getThemeColorData(themeColors);

  return `font:${fontUtils.fontToCSSWithColor(
    fontValue,
    themeColorsData.color,
  )}`;
}

function getFont(
  fontName: string,
): { fontFamily: string; displayName: string } | null {
  fontName = fontName.toLowerCase();
  return _.head(fontUtils.getMetadata([fontName]));
}

/**
 * Used by the blog old ck editor
 * @returns {*}
 */
function getFontsMetadata(documentType?: string) {
  let fontMetadataWithoutStudioFonts =
    documentType !== 'WixSite'
      ? _.omitBy(fontUtils.getFontsMetaData(), { permissions: 'studio' })
      : {};

  fontMetadataWithoutStudioFonts = _.mapValues(
    fontMetadataWithoutStudioFonts,
    function (font, fontName) {
      return {
        ...font,
        cssFontFamily: fontUtils.getFontFamilyWithFallbacks(fontName),
      };
    },
  );

  return fontMetadataWithoutStudioFonts;
}

/**
 * Used by the blog old ck editor
 * @returns {*}
 */
function getAllCustomFontsUrls(documentType: string, languages: AnyFixMe) {
  const allCustomFonts: Record<string, string> = {};

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(fontUtils.getFontsMetaData(), function (font, fontName) {
    if (font.cdnName) {
      allCustomFonts[fontName] = fontName;
    }
  });

  const allCustomFontsUrl = getFontsUrlWithParams(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    _.keys(allCustomFonts),
    documentType,
    languages,
  );

  return {
    missingFontsNames: allCustomFonts,
    missingFontsUrl: allCustomFontsUrl,
  };
}

function getAllFontsCss(languages: AnyFixMe, documentType?: string) {
  const fontsFamilies = Object.keys(fontUtils.getFontsMetaData());

  const fontsUrl = getFontsUrlWithParams(
    fontsFamilies,
    documentType,
    languages,
  );
  const wixStoredFontsUrl = getWixStoredFontsCss(languages, documentType);

  return [...wixStoredFontsUrl, fontsUrl];
}

function stringifyFontWithoutColor(fontObject: Font) {
  return stringifyFont(fontObject, false);
}

function stringifyBold(bold: boolean | string) {
  if (bold === true) {
    return '700';
  } else if (bold === false) {
    return 'normal';
  }

  return bold;
}

function stringifyItalic(italic: boolean | string) {
  if (italic === true) {
    return 'italic';
  } else if (italic === false) {
    return 'normal';
  }

  return italic;
}

function stringifyFont(fontObject: Partial<Font>, withColor: boolean) {
  let result = `${stringifyItalic(fontObject.italic)} ${
    fontObject.variant
  } ${stringifyBold(fontObject.bold)} ${fontObject.size}/${
    fontObject.lineHeight
  } ${replaceSpaceFontFamily(fontObject.family)}`;

  if (withColor && fontObject.color) {
    const color = toThemeColor(fontObject.color);
    result += ` ${color}`;
  }

  return result;
}

function getFontsSpriteUrl() {
  return `url(${util.media.getMediaUrl('textEditor/fonts.v21.png')})`;
}

function getUploadedFontFace(uploadedGUIDsArray: string[]) {
  return fontUtils.getUploadedFontFaceStyles(
    uploadedGUIDsArray,
    util.serviceTopology.mediaRootUrl,
  );
}

function toThemeColor(color: string) {
  if (color.includes('{color_')) {
    return color;
  }

  if (color.includes('color_')) {
    return `{${color}}`;
  }

  return colors.getColorInHex(color);
}

function getThemeFontFamilies(themeFonts: Record<string, string>) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values
  return _(themeFonts)
    .values()
    .map((themeFontString) => fontUtils.getFontFamily(themeFontString))
    .value();
}

function getThemeMap(fontsMap: Record<string, Font>) {
  return _.transform(
    fontsMap,
    function (acc: Record<string, FontTheme>, theme, key) {
      const themeColor = themeUtils.getThemeColorKey(theme.color);
      acc[key] = {
        ...theme,
        fontFamily: theme.family,
        fontSize: parseInt(theme.size, 10),
        foreColor: themeColor,
      };
      return acc;
    },
  );
}

function getUploadedUsedFonts(
  text: string,
  themeFonts: Record<string, string>,
) {
  const usedFonts = _.union(
    getUsedFontsInTextComp(text),
    getThemeFontFamilies(themeFonts),
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _.filter(usedFonts, (name) => fontUtils.isUploadedFontFamily(name));
}

function getSkinBasedCompFontSize(editorAPI: EditorAPI, compRef: CompRef[]) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  const fontKey = _(getSkinBasedCompFontDefs(editorAPI, compRef)).keys().head();
  const style = editorAPI.components.style.get(compRef);
  const fontValue = _.get(style, `style.properties.${fontKey}`);
  if (!fontValue) {
    return '';
  }
  const realFontValue = fontUtils.parseStyleFont(
    fontValue,
    // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
    editorAPI.theme.fonts.getAll(),
    editorAPI.theme.colors.getAll(),
  );
  return realFontValue.size;
}

function getSkinBasedCompFontDefs(
  editorAPI: EditorAPI,
  compRef: CompRef[],
): AnyFixMe {
  //@ts-expect-error
  const skinId: string = editorAPI.components.skin.get(_.head(compRef));
  const skinDef =
    editorAPI.documentServices.theme.skins.getSkinDefinition(skinId);
  //@ts-expect-error
  return _.pickBy(skinDef, { type: 'FONT' });
}

const fromFontStyleToTextTheme = (
  fontStyle: ThemeManagerFontStyles,
): TextTheme => ({
  fontStyle: fontStyle.isItalic ? 'italic' : 'normal',
  fontVariant: 'normal',
  fontWeight: fontStyle.isBold ? 'bold' : 'normal',
  fontSize: stringifyFontSize(fontStyle.size),
  fontFamily: replaceSpaceFontFamily(fontStyle.family),
  color: toThemeColor(fontStyle.color),
  lineHeight: stringifyLineHeight(fontStyle.lineHeight),
  letterSpacing: stringifyLetterSpacing(fontStyle.letterSpacing),
});

const addUnitsToNumber = (val: number | string, units: string): string => {
  if (typeof val === 'string') return val;
  const isValid = isFloat(val) || Number.isInteger(val);
  return isValid ? `${+val.toFixed(2)}${units}` : String(val);
};

const stringifyLineHeight = (lineHeight: number | string): string => {
  if (lineHeight === '') return constants.DEFAULT_LINE_HEIGHT;
  return addUnitsToNumber(lineHeight, 'em');
};

const stringifyLetterSpacing = (letterSpacing: number | string): string => {
  if (letterSpacing === '') return constants.DEFAULT_LETTER_SPACING;
  return addUnitsToNumber(letterSpacing, 'em');
};

const stringifyFontSize = (size: number) => {
  return Number.isInteger(size) ? `${size}px` : String(size);
};

const replaceSpaceFontFamily = (fontFamily: string) =>
  fontFamily?.replace(/\s/g, '+');

const getOnlyFontFamily = (fontFamily: string) => {
  const UPLOADED_FONT_PREFIX = 'wfont';

  if (fontFamily?.includes(UPLOADED_FONT_PREFIX)) return fontFamily;

  return fontFamily?.split(',')[0].replace(/[+]/g, ' ').replace(/['"]/g, '');
};

export {
  getStyleFont,
  parseStyleFont, //TODO: chene: remove this when finish fonts refactor
  stringifyFontWithoutColor,
  stringifyFont,
  createFontsCssString,
  createFontStyleString,
  getCurrentSelectableFontsWithParams,
  getFontsDropDownItems,
  getFont,
  getSkinBasedCompFontSize,
  getSkinBasedCompFontDefs,
  getWixStoredFontsCss, //used by the blog
  getAllFontsCss,
  getFontsMetadata,
  getAllCustomFontsUrls, //used by the blog
  getFontsSpriteUrl, //used by the blog
  getUploadedFontFace,
  getUploadedFonts,
  isUploadedFontFamily as isUploadedFont,
  getUploadedId,
  getThemeMap,
  getThemeFontFamilies,
  getUploadedUsedFonts,
  toThemeColor,
  fromFontStyleToTextTheme,
  stringifyLineHeight,
  stringifyLetterSpacing,
  stringifyFontSize,
  replaceSpaceFontFamily,
  getOnlyFontFamily,
  THEME_FONT_REGEX,
};

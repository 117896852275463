// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tpaProductPageInfo',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    const api = this.getEditorAPI();

    return (
      <div className="page-info tab-inner">
        <section>
          <p className="title">
            {translate('Pages_Store_Product_Info_WhatisTitle')}
          </p>

          <p>{translate('Pages_Store_Product_Info_WhatisText')}</p>
        </section>

        <section>
          <p className="title">
            {translate('Pages_Store_Product_Info_HowTitle')}
          </p>

          <p>{translate('Pages_Store_Product_Info_HowText')}</p>
        </section>

        <section>
          <p className="title">
            {translate('Pages_Store_Product_Info_ChangeTitle')}
          </p>

          <p>
            {api.isDesktopEditor() ? (
              <span key="desktop">
                {translate('Pages_Store_Product_Info_ChangeText')}{' '}
              </span>
            ) : null}
            {api.isMobileEditor() ? (
              <span key="mobile">
                {translate('Pages_Store_Product_Info_ChangeText_Mobile')}{' '}
              </span>
            ) : null}

            <baseUI.button
              label="Pages_Store_Product_Info_ChangeLink"
              onClick={(e) => {
                e.stopPropagation();
                this.props.actions.openStoreManager();
              }}
              className="btn-text"
            />
            <span>.</span>
          </p>
        </section>
      </div>
    );
  },
});

import { link } from '#packages/util';
import * as core from '#packages/core';
import * as stateManagement from '#packages/stateManagement';
import { createMenuApi } from '../API/menuAPI';
import {
  ADVANCED_MENU_TYPE,
  NON_CUSTOM_MENUS_IDS,
  NON_ATTACHABLE_MENUS_IDS,
  MEMBER_MENUS_IDS,
  MEMBER_LOGIN_MENU_IDS,
  MAIN_MENU_TYPE,
  FALLBACK_MENU_TYPE,
  FALLBACK_PAGES_MENU_NAME,
  MENU_SUPPORTS_SUBSUBITEMS,
  PAGES_MENU_ID,
} from '../constants';

import type {
  MenuData,
  MenuItem as IMenuItem,
  PagesData,
} from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import { createPagesApi, type IPagesAPI } from '@/pages-wip';
import { getAddMenuItemsActions } from '../panels/MenuManagePanel/menuActions';

const {
  linkTypeValidators: { isDynamicPageLink },
  getPagesForLinkPanel,
} = link;

export { getAddMenuItemsActions };

const { menuLogic } = core.utils;

export const cleanId = (id: string) => id.replace('#', '');

export const isMembersMenuId = (menuId: string) =>
  MEMBER_MENUS_IDS.includes(menuId);

export const isMembersLoginMenuId = (menuId: string) =>
  MEMBER_LOGIN_MENU_IDS.includes(menuId);

export const isMainMenu = (menu: MenuData) => menu.menuType === MAIN_MENU_TYPE;

export const isDropdown = (item: Partial<IMenuItem>) => !item.link;

export const isMegaMenuContainer = (item: Partial<IMenuItem | MenuData>) =>
  item.type !== 'CustomMenu' && !!item.slot;

// finding next name for menu.
// if `nameTemplate` doesnt exist in menus, returning nameTemplate
// if `nameTemplate` already exists in menu names, returning `nameTemplate largest number at end of template+1`
// getItemName(['Main menu'], 'Footer') -> 'Footer'
// getItemName(['Main menu', 'Footer'], 'Footer') -> 'Footer 2'
// getItemName(['Main menu', 'Footer', 'Footer 2'], 'Footer') -> 'Footer 3'
// you can check tests if you want to get better understanding
export const getItemName = (names: string[], nameTemplate: string) => {
  const nameTemplateRegex = new RegExp(`^${nameTemplate} ?(\\d+)?$`);

  const newMenuIndices = names.map((n) => {
    const match = nameTemplateRegex.exec(n);

    if (match) {
      const matchGroup = match[1];

      return Number(matchGroup) || 1;
    }

    return 0;
  });

  const maxIndex = Math.max(...newMenuIndices, 0);

  if (maxIndex === 0) {
    return nameTemplate;
  }

  return `${nameTemplate} ${maxIndex + 1}`;
};

const isFallbackMenu = ({ menuType, name }: MenuData) =>
  menuType === FALLBACK_MENU_TYPE || name === FALLBACK_PAGES_MENU_NAME;

const isAdvancedMenu = ({ menuType }: MenuData) =>
  menuType === ADVANCED_MENU_TYPE;

export const getAttachableMenus = (menus: MenuData[]) =>
  menus.filter(
    (menu) =>
      !NON_ATTACHABLE_MENUS_IDS.includes(menu.id) &&
      !isFallbackMenu(menu) &&
      !isAdvancedMenu(menu),
  );

export const getUpdatableMenus = (menus: MenuData[]) =>
  menus.filter((menu) => !isFallbackMenu(menu));

export const getCustomMenus = (menus: MenuData[]) =>
  menus.filter(
    (menu) =>
      !NON_CUSTOM_MENUS_IDS.includes(menu.id) &&
      !isFallbackMenu(menu) &&
      !isAdvancedMenu(menu),
  );

export const getAdvancedMenus = (menus: MenuData[]) =>
  menus.filter(
    (menu) =>
      !NON_CUSTOM_MENUS_IDS.includes(menu.id) &&
      !isFallbackMenu(menu) &&
      isAdvancedMenu(menu),
  );

const MEMBERS_GROUP_TITLE = 'members';

const getSubPages = (editorAPI: EditorAPI, pageId: string) => {
  return editorAPI.tpa.page?.getSubPages(pageId);
};

const getTpaInnerRoutes = (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  pageId: string,
  subPage: string,
) => {
  return (
    stateManagement.tpaDynamicPages.selectors.getSubPageInnerRoute(
      editorAPI.store.getState(),
      appDefinitionId,
      pageId,
      subPage,
    ) ?? []
  );
};

const getDynamicPagesMap = (pagesAPI: IPagesAPI) => {
  return pagesAPI
    .getDynamicPagesList()
    .reduce<Record<string, boolean>>((acc, router) => {
      router.pages.forEach((page) => {
        acc[page.id] = true;
      });

      return acc;
    }, {});
};

const getIsVerticalsDynamicPage = (editorAPI: EditorAPI, page: PagesData) => {
  const subPages = getSubPages(editorAPI, page.id);
  const subPageKey = subPages?.[0]?.key;
  const tpaInnerRoutes = getTpaInnerRoutes(
    editorAPI,
    page.appDefinitionId,
    page.id,
    subPageKey,
  );

  return tpaInnerRoutes.length > 0;
};

const getPageDetails = (
  editorAPI: EditorAPI,
  pagesAPI: IPagesAPI,
  dynamicPagesMap: Record<string, boolean>,
  page: PagesData,
) => {
  const isMembersPage = pagesAPI.isMembersPage(page.id);
  const isDynamicPage = pagesAPI.isDynamicPage(page.id);
  const isSpecialPage = menuLogic.isSpecialPage(
    editorAPI,
    page,
    dynamicPagesMap,
  );
  const isVerticalsDynamicPage = getIsVerticalsDynamicPage(editorAPI, page);

  return {
    isMembersPage,
    isDynamicPage,
    isSpecialPage,
    isVerticalsDynamicPage,
  };
};

export const getLinkPanelPages = (
  editorAPI: EditorAPI,
  showAllPages: boolean,
) => {
  const defaultLinkPanelPages: PagesData[] = getPagesForLinkPanel(editorAPI);
  const pagesAPI = createPagesApi(editorAPI);
  const dynamicPagesMap = getDynamicPagesMap(pagesAPI);

  return defaultLinkPanelPages.filter((page) => {
    const { isMembersPage, isDynamicPage, isSpecialPage } = getPageDetails(
      editorAPI,
      pagesAPI,
      dynamicPagesMap,
      page,
    );

    if (!showAllPages) {
      return isDynamicPage && !isMembersPage;
    }

    return !isSpecialPage || isDynamicPage;
  });
};

export const getPagesWithVerticalsDynamic = (editorAPI: EditorAPI) => {
  const defaultLinkPanelPages: PagesData[] = getPagesForLinkPanel(editorAPI);
  const pagesAPI = createPagesApi(editorAPI);
  const dynamicPagesMap = getDynamicPagesMap(pagesAPI);

  return defaultLinkPanelPages.filter((page) => {
    const { isVerticalsDynamicPage, isDynamicPage, isSpecialPage } =
      getPageDetails(editorAPI, pagesAPI, dynamicPagesMap, page);

    return !isSpecialPage || isDynamicPage || isVerticalsDynamicPage;
  });
};

export const isMenuItemRenamable = (
  editorAPI: EditorAPI,
  menuId: string,
  itemId: string,
): boolean => {
  const isInMultiLingualFlow =
    editorAPI.language.multilingual.isEnabled() &&
    !stateManagement.multilingual.services.utils.currentIsOriginal(editorAPI);

  // temporary enabling rename when user is in multilingual flow as a workaround for https://jira.wixpress.com/browse/EK-1362
  if (isInMultiLingualFlow) {
    return true;
  }

  const menuAPI = createMenuApi(editorAPI);
  const item = menuAPI.getItem(menuId, itemId);

  if (!item) {
    return false;
  }

  // @ts-expect-error
  if (isDynamicPageLink(item.link)) {
    // @ts-expect-error
    const { routerId } = item.link;

    const router = editorAPI.dsRead.routers.get.byId(routerId);

    // members pages cannot be renamed
    if (router && router.group === MEMBERS_GROUP_TITLE) {
      return false;
    }
  }

  return true;
};

export const filterVisibleMenuItems = <T extends IMenuItem>(
  menuItems: T[],
  isMobileEditor: boolean,
): T[] =>
  menuItems
    .filter((menuItem) => {
      if (isMobileEditor) {
        return menuItem.isVisibleMobile;
      }
      return menuItem.isVisible;
    })
    .map((menuItem) => ({
      ...menuItem,
      items: filterVisibleMenuItems(menuItem.items, isMobileEditor),
    }));

export const isSupportsSubsubItems = (editorAPI: EditorAPI): boolean => {
  const selectedComponent = editorAPI.selection.getSelectedComponents()[0];
  const componentType = editorAPI.components.getType(selectedComponent);

  return MENU_SUPPORTS_SUBSUBITEMS.includes(componentType);
};

export const updateMainMenuTranslations = (
  editorAPI: EditorAPI,
  newMenuId: string,
) => {
  const languageCodes = editorAPI.dsRead.language
    .get()
    .map((language) => language.code);

  const menuTranslations = languageCodes
    .map((languageCode) => ({
      menuData: editorAPI.multilingual.menu.get(languageCode, PAGES_MENU_ID),
      languageCode,
    }))
    .filter((el) => Boolean(el.menuData));

  menuTranslations.forEach((el) => {
    editorAPI.multilingual.menu.update(el.languageCode, newMenuId, el.menuData);
  });
};

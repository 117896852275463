import _ from 'lodash';
import constants from '#packages/constants';
import { arrayUtils } from '#packages/util';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

export function createComponentsAlignmentApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  function align(compRefs: CompRef[], alignment: AnyFixMe) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      // @ts-expect-error
      editorAPI.dsActions.components.alignment.alignComponents(
        compsArr,
        alignment,
      );
    } else {
      editorAPI.dsActions.components.alignment.alignInContainer(
        _.head(compsArr),
        alignment,
      );
    }

    editorAPI.history.add(`component - align - ${alignment}`);
  }

  function canAlign(compRefs: CompRef[], alignment: AnyFixMe) {
    return (
      editorAPI.components.is.alignable(compRefs) &&
      // @ts-expect-error
      editorAPI.dsRead.components.alignment.canAlign(compRefs, alignment)
    );
  }

  function canAlignAllDirections(compRefs: CompRef[]) {
    return (
      compRefs &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      _.every(constants.COMP_ALIGNMENT_OPTIONS, _.partial(canAlign, compRefs))
    );
  }

  return {
    align,
    canAlign,
    canAlignAllDirections,
  };
}

// @ts-nocheck
import constants from '#packages/constants';
import gfppDataUtils from './gfppDataUtils';
import * as stateManagement from '#packages/stateManagement';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const getCompMediaType = (editorAPI, selectedComponents) => {
  const compDesign = editorAPI.components.design.get(selectedComponents);
  return compDesign?.background?.mediaRef?.type;
};

const isBackgroundSettingsGfppButtonEnabled = (compType, mediaType) => {
  const isVideoType = mediaType === constants.MEDIA_TYPES.VIDEO;
  const isImageType = mediaType === constants.MEDIA_TYPES.IMAGE;

  if (
    [constants.COMP_TYPES.COLUMN, constants.COMP_TYPES.SECTION].includes(
      compType,
    )
  ) {
    return isImageType || isVideoType;
  }

  return isVideoType;
};

const getTooltip = (isDisabled, mediaType) => {
  if (isDisabled) {
    if (mediaType === constants.MEDIA_TYPES.IMAGE) {
      return 'gfpp_tooltip_background_image_disabled';
    }
    return 'gfpp_tooltip_background_color_disabled';
  }
  return 'gfpp_tooltip_background';
};

const getMobileBackgroundSettingsAction = (editorAPI, compRef) => {
  const selectedComponents =
    stateManagement.components.selectors.resolveSelectedComponentForBackgroundSettings(
      compRef,
      editorAPI,
    );
  const mediaType = getCompMediaType(editorAPI, selectedComponents);
  const compType = editorAPI.components.getType(selectedComponents);
  const isDisabled = !isBackgroundSettingsGfppButtonEnabled(
    compType,
    mediaType,
  );
  return {
    isDisabled,
    isSelected: gfppDataUtils.getPanelStateFn(
      ACTIONS.MOBILE_BACKGROUND_SETTINGS,
    ),
    tooltip: getTooltip(isDisabled, mediaType),
    icon: 'mobileBackgroundSettingsGfpp',
    onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.MOBILE_BACKGROUND_SETTINGS),
    automationId: 'gfpp-button-background-settings',
  };
};

export default {
  getMobileBackgroundSettingsAction,
};

import type { EditorAPI } from '#packages/editorAPI';

import type { ConditionStructure, ConditionTranslations } from '../types';

const METADATA_KEY = 'isMobileEditorRequired';
const TRANSLATION_KEY = 'isMobileEditorCondition';

export interface MobileEditorCondition {
  extras: {
    [METADATA_KEY]?: boolean;
  };
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export const getMobileEditorConditionStructure = (
  editorAPI: EditorAPI,
): ConditionStructure<
  typeof METADATA_KEY,
  typeof TRANSLATION_KEY,
  MobileEditorCondition
> => ({
  metadataKey: METADATA_KEY,
  translationsKey: TRANSLATION_KEY,
  check: () => !editorAPI.isMobileEditor(),
  getAction: () => () =>
    editorAPI.setEditorMode(true, 'editorSource_conditionManager'),
});

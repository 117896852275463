import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['Design_Ellipsis_Text_Discovermore_label'],
      sections: [
        addPanel.sections.getSections().text.collapsibleTextParagraphsSection,
      ],
    };
  },
};

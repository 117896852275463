import constants from '#packages/constants';
import { link, scroll, fedopsLogger } from '#packages/util';
import {
  notifications,
  selection,
  panels,
  bi,
} from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import {
  convertPageIdToLinkPageId,
  convertLinkPageIdToPageId,
} from '../../utils';
import { addNewAnchor } from '#packages/anchors';

import type { Anchor } from '#packages/util';
import type { CompRef, Link } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  ThunkAction,
  MapStateToProps,
  MapDispatchToProps,
} from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { AnchorLinkComponentOwnProps } from './AnchorLink';
import type { TAnchorLink } from './AnchorLink';

const { linkTypeValidators } = link;

const ORIGIN = 'linkPanel';
const navigateToComponentInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.LINK_PANEL.NAVIGATE_TO_LINKED_COMPONENT,
);

interface GetAddComponentCallback {
  (
    editorAPI: EditorAPI,
    link: TAnchorLink,
    dummyLinkId: string,
    selectedComponentRef: CompRef,
    currentPageId: string,
    targetPageId: string,
    origin: string,
    saveLink: (link: TAnchorLink) => string,
    sendBIAddCompFlow: (
      link: TAnchorLink,
      targetCompId: string,
      targetCompType: string,
    ) => void,
    biOrigin: string,
  ): (data?: string) => Promise<void>;
}

export enum AnchorDataType {
  Section = 'Section',
  Anchor = 'Anchor',
  Footer = 'Footer',
  Header = 'Header',
}
export interface AnchorData extends Anchor {
  type: AnchorDataType;
  displayName?: string;
}

export interface AnchorLinkComponentStateProps {
  isMobileEditor: boolean;
  currentPageId: string;
}
export interface AnchorLinkComponentDispatchProps {
  getPageFromInnerRoute: EditorAPI['routers']['getPageFromInnerRoute'];
  openHelpCenter: EditorAPI['panelManager']['openHelpCenter'];
  addAndLinkComp: (link: TAnchorLink) => void;
  sendBI: (eventType: BiEventDefinition, biParams: BiEventFields) => void;
}

export const mapStateToProps: MapStateToProps<
  AnchorLinkComponentStateProps,
  AnchorLinkComponentOwnProps
> = ({ editorAPI }) => ({
  isMobileEditor: editorAPI.isMobileEditor(),
  currentPageId: editorAPI.pages.getPrimaryPageId(),
});

const getEditorAPI: ThunkAction<EditorAPI> = (_d, _s, { editorAPI }) =>
  editorAPI;

const sendComponentAddedToStageBI = (
  componentId: string,
  componenType: string,
  currentPage: string,
  targetPage: string,
) =>
  bi.actions.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
    origin: 'link_panel',
    adding_method: 'click',
    component_type: componenType,
    component_id: componentId,
    page_id: currentPage,
    target_component: targetPage,
  });

const showAnchorNofication = (
  editorAPI: EditorAPI,
  text: { caption: string; message: string },
  isSamePage: boolean,
  prevPageId?: string,
  linkedComponentRef?: CompRef,
) => {
  const { message, caption } = text;
  const { dispatch } = editorAPI.store;
  return notifications.actions.showUserActionNotification({
    message,
    title: null,
    type: 'success',
    ...(!isSamePage && {
      link: {
        caption,
        onNotificationLinkClick: async () => {
          navigateToComponentInteraction.start();
          dispatch(panels.actions.closeOpenedPanels());
          const linkedCompLayout =
            editorAPI.components.layout.getRelativeToScreen(linkedComponentRef);
          await editorAPI.pages.navigateToAsync(prevPageId);
          await new Promise<void>((resolve) =>
            editorAPI.scroll.animateScrollTo(
              {
                x: 0,
                y: linkedCompLayout.y - linkedCompLayout.height / 2,
              },
              1.5,
              resolve,
            ),
          );
          await scroll.waitForAnimationScrollEnd(() =>
            editorAPI.site.getScroll(),
          );
          dispatch(selection.actions.selectComponents([linkedComponentRef]));
          navigateToComponentInteraction.end();
        },
      },
    }),
  });
};

/**
 * For add section/anchor flow for rich text component we need to "remember" text selection.
 * That's why we need to create a dummy link. When we have a valid link
 * updateDummyLinkForRichText func will update richText linkList with valid anchorDataId and anchorName
 */
const updateDummyLinkForRichText = ({
  editorAPI,
  richTextRef,
  dummyLinkId,
  linkObj,
}: {
  editorAPI: EditorAPI;
  richTextRef: CompRef;
  dummyLinkId: string;
  linkObj: TAnchorLink;
}) => {
  const data = editorAPI.components.data.get(richTextRef);
  data.linkList = (data.linkList as Link[]).map((link) => {
    if (link.id === dummyLinkId) {
      return {
        ...link,
        ...linkObj,
      };
    }
    return link;
  });
  editorAPI.components.data.update(richTextRef, data);
};

const isRichTextComp = (editorAPI: EditorAPI, compRef: CompRef) =>
  editorAPI.components.getType(compRef) === constants.COMP_TYPES.TEXT;

const getAddAnchorCb: GetAddComponentCallback = (
  editorAPI,
  link,
  dummyLinkId,
  selectedComponentRef,
  currentPageId,
  targetPageId,
  origin,
  saveLink,
  sendBIAddCompFlow,
) => {
  const callback = async () => {
    const { dispatch } = editorAPI.store;
    const isSamePage = targetPageId === currentPageId;
    const isRichText = isRichTextComp(editorAPI, selectedComponentRef);
    const anchorRef = await addNewAnchor(editorAPI, {
      pageRef: editorAPI.pages.getReference(targetPageId),
      origin: ORIGIN,
    });

    await editorAPI.waitForChangesAppliedAsync();

    const { name, id } = editorAPI.components.data.get(anchorRef);
    const linkObj = {
      ...link,
      anchorDataId: convertPageIdToLinkPageId(id),
      anchorName: name,
    };

    if (isRichText) {
      updateDummyLinkForRichText({
        editorAPI,
        richTextRef: selectedComponentRef,
        dummyLinkId,
        linkObj,
      });
    } else {
      saveLink(linkObj);
    }

    sendBIAddCompFlow(linkObj, anchorRef.id, constants.COMP_TYPES.ANCHOR);

    dispatch(panels.actions.closeOpenedPanels());

    if (!isSamePage) {
      const anchorLayout = editorAPI.components.layout.get_rect(anchorRef);
      await editorAPI.pages.navigateToAsync(
        linkTypeValidators.isDynamicPageLink(link) ? link : targetPageId,
      );

      await new Promise<void>((resolve) =>
        editorAPI.scroll.animateScrollTo(
          {
            x: 0,
            y: anchorLayout.y - anchorLayout.height / 2,
          },
          1.5,
          resolve,
        ),
      );
      await scroll.waitForAnimationScrollEnd(() => editorAPI.site.getScroll());
    }

    await editorAPI.waitForChangesAppliedAsync();
    dispatch(selection.actions.selectComponents([anchorRef]));
    dispatch(
      panels.actions.openComponentPanel(
        constants.PANELS.ANCHOR.ANCHOR_SETINGS_PANEL,
      ),
    );
    dispatch(
      sendComponentAddedToStageBI(
        anchorRef.id,
        constants.COMP_TYPES.ANCHOR,
        currentPageId,
        targetPageId,
      ),
    );
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.LINK_PANEL.ADD_ANCHOR,
    );
    dispatch(
      showAnchorNofication(
        editorAPI,
        {
          message: 'LINK_PANEL_ANCHOR_ADDED_TO_PAGE_TEXT',
          caption: 'LINK_PANEL_ANCHOR_ADDED_TO_PAGE_LINK',
        },
        isSamePage,
        currentPageId,
        selectedComponentRef,
      ),
    );

    editorAPI.text.getCurrentTextManager().unRegisterListener(callback);
  };

  return callback;
};

const getAddSectionCb: GetAddComponentCallback = (
  editorAPI,
  link,
  dummyLinkId,
  selectedComponentRef,
  currentPageId,
  targetPageId,
  origin = ORIGIN,
  saveLink,
  sendBIAddCompFlow,
  biOrigin,
) => {
  const callback = async (data?: string) => {
    const { dispatch } = editorAPI.store;
    const isSamePage = targetPageId === currentPageId;
    const isRichText = isRichTextComp(editorAPI, selectedComponentRef);

    if (isRichText) {
      // we need this dummy update to wait for ck editor update component link
      editorAPI.components.data.update(selectedComponentRef, { text: data });
      await editorAPI.waitForChangesAppliedAsync();
    }

    if (!isSamePage) {
      await editorAPI.pages.navigateToAsync(
        linkTypeValidators.isDynamicPageLink(link) ? link : targetPageId,
      );
    }

    editorAPI.panelManager.openPanel(
      constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
      {
        origin: biOrigin || origin,
        onClose: () =>
          fedopsLogger.interactionEnded(
            fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.ADD_AND_LINK,
          ),
        onSectionAddedToStage: (sectionRef: CompRef) => {
          const { name, id } = editorAPI.components.anchor.get(sectionRef);
          const linkObj = {
            ...link,
            anchorDataId: convertPageIdToLinkPageId(id),
            anchorName: name,
          };

          if (isRichText) {
            updateDummyLinkForRichText({
              editorAPI,
              richTextRef: selectedComponentRef,
              dummyLinkId,
              linkObj,
            });
          } else {
            saveLink(linkObj);
          }

          sendBIAddCompFlow(
            linkObj,
            sectionRef.id,
            constants.COMP_TYPES.SECTION,
          );
          dispatch(
            showAnchorNofication(
              editorAPI,
              {
                message: 'LINK_PANEL_SECTION_ADDED_TO_PAGE_TEXT',
                caption: 'LINK_PANEL_ANCHOR_ADDED_TO_PAGE_LINK',
              },
              isSamePage,
              currentPageId,
              selectedComponentRef,
            ),
          );
        },
      },
    );

    editorAPI.text.getCurrentTextManager().unRegisterListener(callback);
  };

  return callback;
};

export const mapDispatchToProps: MapDispatchToProps<
  AnchorLinkComponentDispatchProps,
  AnchorLinkComponentOwnProps
> = (dispatch, ownProps) => ({
  getPageFromInnerRoute(routerId, innerRoute, callback) {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    if (routerId) {
      return editorAPI.dsRead.routers.getPageFromInnerRoute(
        routerId,
        innerRoute,
        callback,
      );
    }
    return callback(undefined);
  },
  openHelpCenter(args) {
    const editorAPI = dispatch(getEditorAPI);
    editorAPI.panelManager.openHelpCenter(...args);
  },
  sendBI(eventType: BiEventDefinition, biParams: BiEventFields = {}) {
    dispatch(bi.actions.event(eventType, biParams));
  },
  async addAndLinkComp(link: TAnchorLink) {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const targetPageId = convertLinkPageIdToPageId(link.pageId);
    const currentPageId = editorAPI.pages.getCurrentPageId();
    const selectedComponentRef = selection.selectors.getSingleSelectedComponent(
      editorAPI.store.getState(),
    );
    const isRichText = isRichTextComp(editorAPI, selectedComponentRef);

    // create dummy link for rich text to save text selection
    const dummyLinkId = isRichText
      ? ownProps.saveLink({
          type: 'AnchorLink',
          pageId: link.pageId,
          routerId: link.routerId,
          innerRoute: link.innerRoute,
        })
      : null;

    const getAddCompCallback =
      link.selection === AnchorDataType.Section
        ? getAddSectionCb
        : getAddAnchorCb;

    const callback = getAddCompCallback(
      editorAPI,
      link,
      dummyLinkId,
      selectedComponentRef,
      currentPageId,
      targetPageId,
      ownProps.origin,
      ownProps.saveLink,
      ownProps.sendBIAddCompFlow,
      ownProps.biOrigin,
    );

    if (isRichText) {
      return editorAPI.text.getCurrentTextManager().onDataChange(callback);
    }

    callback();
  },
});

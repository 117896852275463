import type { EditorAPI } from '#packages/editorAPI';
import { http } from '#packages/util';
import {
  SITE_BUSINESS_DATA_URL,
  SITE_BUSINESS_NAME_URL,
} from './siteGlobalDataConsts';
import type {
  BusinessType,
  SiteBusinessDataFromService,
} from './siteGlobalDataTypes';
import constants from '#packages/constants';

const getSiteBusinessDataHeaders = (editorAPI: EditorAPI): Headers =>
  new Headers({
    Authorization:
      editorAPI.dsRead.platform.getAppDataByApplicationId('-666')?.instance,
  });

const getSiteBusinessNameHeaders = (editorAPI: EditorAPI): Headers =>
  new Headers({
    Authorization: editorAPI.dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance,
  });

const getSiteBusinessDataUrl = (editorAPI: EditorAPI): string => {
  const metaSiteId = editorAPI.site.getMetaSiteId();
  return `${SITE_BUSINESS_DATA_URL}?metaSiteId=${metaSiteId}`;
};

export const getSiteBusinessData = async (
  editorAPI: EditorAPI,
): Promise<SiteBusinessDataFromService> => {
  const url = getSiteBusinessDataUrl(editorAPI);

  return http.fetchJson(url, {
    headers: getSiteBusinessDataHeaders(editorAPI),
    method: 'GET',
  });
};

export const setSiteBusinessData = async (
  editorAPI: EditorAPI,
  businessType: BusinessType,
): Promise<BusinessType> => {
  const url = getSiteBusinessDataUrl(editorAPI);

  return http.fetchJson(url, {
    headers: getSiteBusinessDataHeaders(editorAPI),
    method: 'POST',
    body: JSON.stringify(businessType),
  });
};

export const setSiteBusinessName = async (
  editorAPI: EditorAPI,
  businessName: string,
): Promise<void> => {
  return http.fetchJson(SITE_BUSINESS_NAME_URL, {
    headers: getSiteBusinessNameHeaders(editorAPI),
    method: 'PATCH',
    body: JSON.stringify({
      properties: {
        businessName,
      },
    }),
  });
};

export function isBusinessTypeValid(
  data: SiteBusinessDataFromService,
): boolean {
  return !!data?.industryId && !!data?.structureId && !!data?.displayName;
}

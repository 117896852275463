import TitleWithListTemplate from '../common/titleWithListTemplate';
import React from 'react';
import { createProduct } from '../common/utils';
import dataProvider from '../../dataProvider';
import * as util from '#packages/util';
import type { Product } from '../common/types';
import * as stateManagement from '#packages/stateManagement';
import ascendBi from '../common/bi/ascendBi';
import * as utils from '../common/utils';
import constants from '../common/constants';
import SectionTitle from '../common/sectionTitle';

const TAB_NAME = 'CustomerManagement';
interface CustomerManagementPanelProps {
  createCrmProduct(name: string): Product;
  navigateToCustomerManagementHome(): void;
  sendBi(event: object, parameters: object): void;
  biBaseParams: any;
  playVideo(): any;
}

class CustomerManagementPanel extends React.Component<CustomerManagementPanelProps> {
  componentDidMount() {
    const { sendBi, biBaseParams } = this.props;

    sendBi(ascendBi.events.TAB_VIEW, {
      ...biBaseParams,
      tab_name: TAB_NAME,
    });
  }

  onPlayVideo = () => {
    const { sendBi, biBaseParams, playVideo } = this.props;
    playVideo();
    sendBi(ascendBi.events.ASCEND_PLAY_HELP_VIDEO, {
      ...biBaseParams,
      tab_name: TAB_NAME,
    });
  };

  onHoverVideoIcon = () => {
    const { sendBi, biBaseParams } = this.props;
    sendBi(ascendBi.events.ASCEND_HOVER_VIDEO_ICON, {
      ...biBaseParams,
      tab_name: TAB_NAME,
    });
  };

  render() {
    return (
      <TitleWithListTemplate
        title="AscendButton_CustomerManagement_Relationships_Title"
        subtitle="AscendButton_CustomerManagement_Relationships_Text"
        sectionTitle={
          <SectionTitle
            title="AscendButton_CustomerManagement_Tools_SectionLabel"
            bubbleTitle="AscendButton_CustomerManagement_Tools_Video_Title"
            bubbleText="AscendButton_CustomerManagement_Tools_Video_Text"
            onPlayVideo={this.onPlayVideo}
            onHoverVideoIcon={this.onHoverVideoIcon}
            videoThumbnailSrc="https://wixmp-5ab3ce0ca26e3d0b1623b5b6.wixmp.com/CRM.png"
          />
        }
        items={dataProvider.crmProductItemIds.map((name) =>
          this.props.createCrmProduct(name),
        )}
      />
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const biBaseParams = ascendBi.biUtils.getBiBaseParams({ editorAPI, state });
  const playVideo = () =>
    utils.playVideoOnEditor(editorAPI, constants.VIDEO_IDS.CRM);
  return {
    biBaseParams,
    playVideo,
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    enrichCreateCrmProduct: (bi: AnyFixMe) => (name: AnyFixMe) =>
      createProduct('CustomerManagement', name, editorAPI, bi),
    navigateToCustomerManagementHome: () =>
      utils.navigateToAscend(editorAPI, 'crmHome'),
    sendBi: (event: AnyFixMe, parameters: AnyFixMe) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
  };
};

const mergeProps = (
  stateProps: AnyFixMe,
  dispatchProps: AnyFixMe,
  ownProps: AnyFixMe,
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    createCrmProduct: dispatchProps.enrichCreateCrmProduct({
      sendBi: dispatchProps.sendBi,
      biBaseParams: stateProps.biBaseParams,
    }),
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CustomerManagementPanel);
ConnectedComponent.pure = CustomerManagementPanel;

export default ConnectedComponent;

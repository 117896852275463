import _ from 'lodash';
import { arrayUtils } from '#packages/util';
import { selectionSelectors } from '#packages/stateManagement';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { ComponentsGetApi } from './componentsGetApi';

const MODES_WITH_DEFAULT_ANIMATIONS = {
  DEFAULT: true,
  HOVER: true,
};

export function createComponentsModesApi({
  editorAPI,
  componentsGetApi,
}: {
  editorAPI: EditorAPI;
  componentsGetApi: ComponentsGetApi;
}) {
  function changeModeAndUpdateSelection(
    compPointer: CompRef,
    modeToActivate: AnyFixMe,
    modeToDeactivate?: AnyFixMe,
  ) {
    const selectedComp = editorAPI.selection.getSelectedComponents();
    const editorState = editorAPI.store.getState();
    const focusedComp = selectionSelectors.getFocusedContainer(editorState);

    if (
      !_.isEmpty(selectedComp) &&
      !editorAPI.dsRead.components.modes.isComponentDisplayedInModes(
        _.head(selectedComp),
        [modeToActivate],
      )
    ) {
      editorAPI.selection.selectComponentByCompRef(focusedComp);
    }

    if (modeToActivate) {
      editorAPI.dsActions.components.modes.activateComponentMode(
        compPointer,
        modeToActivate,
      );
    } else if (modeToDeactivate) {
      editorAPI.dsActions.components.modes.deactivateComponentMode(
        compPointer,
        modeToDeactivate,
      );
    }
  }

  function getDefaultSingleModeBehaviors() {
    return {
      defaultInAnimtaion: {
        action: 'modeIn',
        name: 'FadeIn',
        delay: 0,
        duration: 0.35,
      },
      defaultOutAnimtaion: {
        action: 'modeOut',
        name: 'FadeOut',
        delay: 0,
        duration: 0.35,
      },
    };
  }

  function getModes(compRef: CompRef) {
    return editorAPI.dsRead.components.modes.getModes(compRef);
  }

  function isModefulComponent(compRef: CompRef) {
    return !_.isEmpty(getModes(compRef));
  }

  function activateComponentMode(compPointer: CompRef, modeId: AnyFixMe) {
    changeModeAndUpdateSelection(compPointer, modeId);
    editorAPI.panelManager.closePanelByName(
      'compPanels.dynamicPanels.applyPanel',
    );
  }

  function deactivateComponentMode(compPointer: CompRef, modeId: AnyFixMe) {
    changeModeAndUpdateSelection(compPointer, null, modeId);
    editorAPI.panelManager.closePanelByName(
      'compPanels.dynamicPanels.applyPanel',
    );
  }

  function applyCurrentToAllModes(compRefs: CompRef[]) {
    if (!_.isEmpty(compRefs)) {
      arrayUtils.applyForAll(
        editorAPI.dsActions.components.modes.applyCurrentToAllModes,
      )(compRefs);
      editorAPI.dsActions.waitForChangesApplied(function () {
        editorAPI.tabIndicationState.activateApplyAnimation(false);
      });
      editorAPI.history.add('comp applyCurrentToAllModes');
    }
  }

  function applyComponentToMode(components: AnyFixMe, activeModeId: AnyFixMe) {
    editorAPI.dsActions.components.modes.applyComponentToMode(
      _.cloneDeep(components),
      activeModeId,
    );
  }

  function isCompDefaultModeActive(compRef: CompRef) {
    const DEFAULT_MODE_TYPE =
      // @ts-expect-error
      editorAPI.dsRead.components.modes.getTypes().DEFAULT;
    const defaultMode: AnyFixMe = _.head(
      editorAPI.dsRead.components.modes.getModesByType(
        compRef,
        DEFAULT_MODE_TYPE,
      ),
    );
    if (defaultMode) {
      const activeModes =
        editorAPI.dsRead.components.modes.getComponentActiveModeIds(compRef);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      return _.includes(Object.keys(activeModes), defaultMode.modeId);
    }
    return false;
  }

  function isActiveModesWithDefaultAnimations(compPointer: CompRef) {
    const allModes = editorAPI.dsRead.components.modes.getModes(compPointer);
    const activeModesIds =
      editorAPI.dsRead.components.modes.getComponentActiveModeIds(compPointer);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const activeModesDefinitions = _.filter(allModes, (modeDefinition) =>
      _.has(activeModesIds, modeDefinition.modeId),
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    return _.every(activeModesDefinitions, (modeDefinition) =>
      _.get(MODES_WITH_DEFAULT_ANIMATIONS, modeDefinition.type, false),
    );
  }

  function addDefaultSingleModeAnimations(compRefs: CompRef | CompRef[]) {
    arrayUtils.applyForAll((compRef: CompRef) => {
      const firstAncestorWithMode =
        editorAPI.dsRead.components.modes.getFirstAncestorWithActiveModes(
          compRef,
        );

      if (
        firstAncestorWithMode &&
        isActiveModesWithDefaultAnimations(firstAncestorWithMode)
      ) {
        const defaultBehaviors = getDefaultSingleModeBehaviors();
        const isAnimatable = editorAPI.components.is.animatable(
          compRef,
          defaultBehaviors.defaultInAnimtaion.action,
        );

        if (isAnimatable) {
          editorAPI.dsActions.components.behaviors.update(
            compRef,
            defaultBehaviors.defaultInAnimtaion,
          );
          editorAPI.dsActions.components.behaviors.update(
            compRef,
            defaultBehaviors.defaultOutAnimtaion,
          );
        }
      }
    })(compRefs);
  }

  function getFirstAncestorOrSelfWithModeDefinitions(compPointer: CompRef) {
    return componentsGetApi.findAncestor(
      compPointer,
      (ancestorRef) => !!editorAPI.components.modes.getModes(ancestorRef),
      {
        includeSelf: true,
        includeScopeOwner: true,
      },
    );
  }

  function areComponentsOfSameModefulContainer(compRefs: CompRef[]) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length <= 1) {
      return true;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const modefulContainers = _.map(
      compsArr,
      editorAPI.components.modes.getFirstAncestorWithActiveModes,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    return _.every(
      modefulContainers,
      _.partial(_.isEqual, modefulContainers[0]),
    );
  }

  return {
    getModes,
    isModefulComponent,
    activateComponentMode,
    deactivateComponentMode,
    applyCurrentToAllModes,
    applyComponentToMode,
    isCompDefaultModeActive,
    addDefaultSingleModeAnimations,
    getFirstAncestorOrSelfWithModeDefinitions,
    areComponentsOfSameModefulContainer,
  };
}

export type ComponentsModesApi = ReturnType<typeof createComponentsModesApi>;

import React from 'react';

import { workspace } from '#packages/util';
import constants from '#packages/constants';

import connect from './mobileElementsSettingsHeaderConnect';
import {
  MobileElementsSettingsHeaderComponent,
  NewWorkspaceMobileElementsSettingsHeaderComponent,
} from './mobileElementsSettingsHeaderComponent';

interface MobileElementsSettingsHeaderProps {
  title: string;
  helpId: string;
  minimize: () => void;
  openHelpCenter: (helpId: string, props?: any, biHelpParams?: any) => void;
}

const MobileElementsSettingsHeader: React.FC<
  MobileElementsSettingsHeaderProps
> = (props) => {
  const Component = workspace.isNewWorkspaceEnabled()
    ? NewWorkspaceMobileElementsSettingsHeaderComponent
    : MobileElementsSettingsHeaderComponent;

  const handleHelpClick = () => {
    const biHelpParams = {
      panel_name: props.title,
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
      learn_more: false,
    };
    props.openHelpCenter(props.helpId, null, biHelpParams);
  };

  return (
    <Component
      title={props.title}
      onCloseClick={props.minimize}
      onHelpClick={handleHelpClick}
    />
  );
};

export default connect(MobileElementsSettingsHeader);

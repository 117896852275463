import React from 'react';

import * as coreBi from '#packages/coreBi';

import connect from './testSiteRCConnect';
import { TestSiteRCFrame } from './testSiteRCFrame';

import type { SendBiFunction } from 'types/bi';
import * as util from '#packages/util';
import constants from '#packages/constants';
import type { Dispatch } from 'types/redux';
import { Action } from '../../actionsButtons/testSite/testSite';
import { translate } from '#packages/i18n';

interface TestSiteRCDropPanelProps {
  togglePreview: (
    callback?: Function,
    params?: { biParams?: AnyFixMe },
  ) => void;
  publishRC: (origin?: string) => void;
  sendBI: SendBiFunction;
  isDevModeOn: boolean;
  setSiteUserPreferences: FunctionFixMe;
  dispatch: Dispatch;
  actionChoice: string;
}

class TestSiteRCDropPanel extends React.Component<TestSiteRCDropPanelProps> {
  preview = () => {
    util.editorWixRecorder.addLabel('preview click');
    this.props.sendBI(coreBi.events.preview.PREVIEW_CLICK, {
      origin: this.props.actionChoice === 'Preview' ? 'preview' : 'test_site',
      is_dev_mode: this.props.isDevModeOn,
    });

    this.props.dispatch(
      this.props.setSiteUserPreferences(
        constants.USER_PREFS.TEST_SITE.TEST_SITE_BUTTON_TITLE,
        Action.PREVIEW,
      ),
    );
    this.props.togglePreview();
  };
  publishRC = () => {
    const origin =
      this.props.actionChoice === 'Preview' ? 'preview' : 'test_site';
    this.props.sendBI(coreBi.events.topbar.start_creating_rc_click, {
      origin,
      src: 38,
    });
    this.props.publishRC(`${origin}_dropdown`);
    this.props.dispatch(
      this.props.setSiteUserPreferences(
        constants.USER_PREFS.TEST_SITE.TEST_SITE_BUTTON_TITLE,
        Action.TEST_SITE,
      ),
    );
  };
  onLearnMoreClick = (action: Action) => {
    switch (action) {
      case Action.TEST_SITE:
        this.props.sendBI(
          coreBi.events.testSite.TEST_SITE_BUTTON_DROPDOWN_LEARN_MORE,
          {
            panel_name: 'test_site_dropdown_menu',
            help_id: translate(
              'TopBar_DevMode_Preview_Options_Dropdown_TestSite_LearnMore_URL',
            ),
            learn_more: true,
            origin: 'panel',
            category: 'test_site',
          },
        );
        window.open(
          translate(
            'TopBar_DevMode_Preview_Options_Dropdown_TestSite_LearnMore_URL',
          ),
          '_blank',
        );
        break;
      case Action.PREVIEW:
        this.props.sendBI(
          coreBi.events.testSite.TEST_SITE_BUTTON_DROPDOWN_LEARN_MORE,
          {
            panel_name: 'test_site_dropdown_menu',
            help_id: translate(
              'TopBar_DevMode_Preview_Options_Dropdown_Preview_LearnMore_URL',
            ),
            learn_more: true,
            origin: 'panel',
            category: 'preview',
          },
        );
        window.open(
          translate(
            'TopBar_DevMode_Preview_Options_Dropdown_Preview_LearnMore_URL',
          ),
          '_blank',
        );
        break;
      default:
        break;
    }
  };
  render() {
    return (
      <TestSiteRCFrame
        publishRC={this.publishRC}
        preview={this.preview}
        onLearnMoreClick={this.onLearnMoreClick}
      />
    );
  }
}

const ConnectedComponent = connect(TestSiteRCDropPanel);
ConnectedComponent.pure = TestSiteRCDropPanel;

export default ConnectedComponent;

import constants from '#packages/constants';
import { mobile, mouseActions, preview } from '#packages/stateManagement';
import { fixedStage } from '#packages/util';
import type { MapStateToProps } from 'types/redux';

export interface BlockLayerStateProps {
  isMobileEditor: boolean;
  siteScale: number;
  previewHeight: number;
  isZoomModeTransitionActive: boolean;
  withShadow: boolean;
  shouldRemoveBottomBlockAndResizeShadow: boolean;
}

export interface BlockLayerOwnProps {
  className?: string;
}
export interface BlockLayerProps
  extends BlockLayerStateProps,
    BlockLayerOwnProps {}

export const mapStateToProps: MapStateToProps<
  BlockLayerStateProps,
  BlockLayerOwnProps
> = ({ state, editorAPI }) => {
  const siteScale = editorAPI.getSiteScale();
  const isMobileEditor = mobile.selectors.isMobileEditor(state);
  const previewHeight = editorAPI.dsRead?.site.getHeight() || 0;
  const isZoomModeTransitionActive =
    editorAPI.zoomMode.isZoomModeTransitionActive();

  const isPerformingMouseMoveAction =
    mouseActions.selectors.isPerformingMouseMoveAction(state);
  const registeredMouseMoveAction =
    editorAPI.mouseActions.getRegisteredMouseMoveAction();
  const isSectionDrag =
    isPerformingMouseMoveAction &&
    registeredMouseMoveAction?.type ===
      constants.MOUSE_ACTION_TYPES.SECTION_DRAG;
  const previewMode = preview.selectors.getPreviewMode(state);
  const previewType = previewMode
    ? preview.selectors.getPreviewType(state)
    : undefined;
  const shouldRemoveBottomBlockAndResizeShadow =
    fixedStage.shouldRenderFixedTopGap(previewMode, previewType);
  const withShadow = fixedStage.isFixedStageEnabled()
    ? !isSectionDrag
    : !isMobileEditor && !isSectionDrag;

  return {
    siteScale,
    previewHeight,
    isMobileEditor,
    isZoomModeTransitionActive,
    withShadow,
    shouldRemoveBottomBlockAndResizeShadow,
  };
};

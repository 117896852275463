// @ts-nocheck
import _ from 'lodash';
import wixFormsConstants from './constants';
import * as tpa from '#packages/tpa';
import * as util from '#packages/util';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import * as addPanelInfra from '#packages/addPanelInfra';

const installApp = (
  editorAPI,
  {
    categoryId,
    structure,
    coords,
    preset,
    theme,
    formRef,
    select_form,
    dropContainer,
  },
) => {
  const addApp = (resolve, reject, compRef) => {
    const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
    const biInitiator = tpa.constants.BI.initiator.EDITOR;
    const options = {
      originType: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
      disableAddCompleteSave: true,
    };
    const callback = (data) => (data?.success ? resolve(data) : reject(data));
    const platformOrigin = {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        containerRef: compRef,
        type: InstallationOriginType.AddPanel,
        preset,
        theme,
        coords,
        select_form,
      },
    };
    tpa.services.tpaAddAppService.addApp(
      editorAPI,
      wixFormsConstants.FORM_BUILDER_APP_ID,
      null,
      null,
      type,
      biInitiator,
      false,
      {},
      options,
      callback,
      platformOrigin,
    );
  };

  return new Promise(function (resolve, reject) {
    if (formRef) {
      return addApp(resolve, reject, formRef);
    }

    return addPanelInfra.addPanelUtils
      .attemptToAddComponent(
        editorAPI,
        structure,
        categoryId || 'contact',
        preset,
        [],
        preset,
        null,
        null,
        {
          position: coords,
          skipHistorySnapshot: true,
          dropContainer,
        },
      )
      .then((compRef) => {
        const formRefChild = getFormRef(editorAPI, compRef);
        return addApp(resolve, reject, formRefChild);
      });
  });
};

export const addForm = (
  editorAPI,
  {
    structure,
    coords,
    preset,
    theme,
    formRef,
    categoryId,
    select_form = true,
    dropContainer,
  },
) => {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.ADD_CONTACT_FORM,
  );
  return Promise.resolve(
    installApp(editorAPI, {
      structure,
      coords,
      preset,
      theme,
      formRef,
      categoryId,
      select_form,
      dropContainer,
    }).then(() => {
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.ADD_CONTACT_FORM,
      );

      if (select_form) {
        return editorAPI.selection.getSelectedComponents()[0];
      }
    }),
  );
};

const onDrop = (editorAPI, preset, theme) => (coords, compDef, dropContainer) =>
  addForm(editorAPI, {
    structure: compDef.structure,
    coords,
    preset,
    theme,
    dropContainer,
  });

const onClick = (editorAPI, preset, theme) => (structure) =>
  addForm(editorAPI, { structure, coords: null, preset, theme });

const getFormRef = (editorAPI, containerRef) => {
  const getCompDescendants = () => {
    const getChildren = (component) =>
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(component);
    return getDescendants(getChildren, containerRef);
  };
  const children = getCompDescendants();
  return children.find(
    (compRef) =>
      editorAPI.components.getType(compRef) === wixFormsConstants.componentType,
  );
};

const getFormStructure = (structure) => {
  const getCompStructureDescendants = () => {
    const getChildren = (component) => component.components;
    return getDescendants(getChildren, structure);
  };
  const formStructureChildren = getCompStructureDescendants();
  const appWidget = formStructureChildren.find(
    (child) =>
      child?.data?.applicationId === wixFormsConstants.FORM_BUILDER_APP_ID,
  );
  return appWidget.components[0];
};

const getDescendants = (getChildren, component) => {
  const children = getChildren(component);

  return _(children)
    .flatMap((c) => getDescendants(getChildren, c))
    .union(children)
    .value();
};

const getPresetBiName = (preset: string) => {
  return (
    {
      [wixFormsConstants.FormPreset.STRIP01]: 'Columns_ColumnsContact_2',
      [wixFormsConstants.FormPreset.STRIP02]: 'Columns_ColumnsContact_4',
      [wixFormsConstants.FormPreset.STRIP03]: 'Columns_ColumnsContact_6',
      [wixFormsConstants.FormPreset.STRIP04]: 'Columns_ColumnsContact_5',
    }[preset] || preset
  );
};

export const addFormToContainer = (
  categoryId,
  sectionTitle,
  editorAPI,
  preset,
  coords,
  structure,
) => {
  const formStructure = getFormStructure(structure);

  return addPanelInfra.addPanelUtils
    .attemptToAddComponent(
      editorAPI,
      structure,
      categoryId,
      sectionTitle,
      [],
      getPresetBiName(preset),
      null,
      null,
      {
        position: coords,
        skipHistorySnapshot: true,
      },
    )
    .then((containerRef) => {
      const formRef = getFormRef(editorAPI, containerRef);

      return addForm(editorAPI, {
        structure: formStructure,
        coords: _.pick(formStructure.layout, ['x', 'y']),
        preset,
        theme: null,
        formRef,
        select_form: false,
      }).then(() => containerRef);
    });
};

const onDropToContainer =
  (categoryId, sectionTitle) => (editorAPI, preset) => (coords, compDef) =>
    addFormToContainer(
      categoryId,
      sectionTitle,
      editorAPI,
      preset,
      coords,
      compDef.structure,
    );

const onClickToContainer =
  (categoryId, sectionTitle) =>
  (editorAPI, preset) =>
  (structure, _sectionTitle, _tags, _itemId, _editorAPI, { position } = {}) => {
    return addFormToContainer(
      categoryId,
      sectionTitle,
      editorAPI,
      preset,
      position,
      structure,
    );
  };

const createWidget = (layout, id, controllerType, mobileHints) => ({
  componentType: 'platform.components.AppWidget',
  style: {
    skin: 'platform.components.skins.AppWidgetLoaderSkin',
    style: {},
  },
  id,
  data: {
    type: 'AppController',
    name: 'wix-forms-controller',
    controllerType,
    applicationId: wixFormsConstants.FORM_BUILDER_APP_ID,
    id: 'form-builder-widget',
  },
  mobileHints,
  layout,
  components: [
    {
      type: 'Container',
      components: [],
      skin: wixFormsConstants.skin,
      layout: _.merge(
        {
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        _.pick(layout, ['width', 'height']),
      ),
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            controllerId: 'form-builder-widget',
            isPrimary: true,
            config: '{"isDummyForm":true}',
            role: 'form_Role',
          },
        ],
      },
      style: wixFormsConstants.formStyle,
      componentType: wixFormsConstants.componentType,
    },
  ],
});

const getFormWidget = ({
  layout,
  mobileLayout,
  id,
  controllerType = wixFormsConstants.CONTROLLER_TYPES.WIX_FORMS,
  mobileHints,
}) =>
  mobileLayout
    ? _.merge({}, createWidget(layout, id, controllerType, mobileHints), {
        mobileStructure: createWidget(mobileLayout, id, controllerType),
      })
    : createWidget(layout, id, controllerType, mobileHints);

export {
  onDrop,
  onClick,
  onDropToContainer,
  onClickToContainer,
  getFormWidget,
};

import type { EntryPoint } from '#packages/apilib';
import { createEditorEventRegistryApi } from './api';
import { EditorEventRegistryApiKey } from './publicApiKey';

export const EditorEventRegistryEntrypoint: EntryPoint = {
  name: 'EditorEventRegistryApi',
  declareAPIs: () => [EditorEventRegistryApiKey],
  getDependencyAPIs: () => [],
  attach(shell) {
    shell.contributeAPI(EditorEventRegistryApiKey, () =>
      createEditorEventRegistryApi(),
    );
  },
};

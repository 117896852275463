import _ from 'lodash';
import { sections } from '#packages/util';

import * as tpaAddRemoveDSService from './tpaAddRemoveDSService';
import * as bi from '../bi/bi';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  AppData,
  PageRef,
  CompRef,
  TPAWidget,
} from 'types/documentServices';

interface Widget {
  allPages: boolean;
  tpaWidgetId: string;
  wixPageId: string;
}

interface Page {
  pageId: string;
  shouldNavigate: boolean;
}

interface AddWidgetOptions {
  componentType: 'WIDGET';
  widget: Widget;
}

interface AddPageOptions {
  componentType: 'PAGE';
  page: Page;
}

type EnhanceWithCommonOptions<T> = T & {
  compId: string;
  containerRef?: CompRef;
  callback: Function;
  appDefinitionId: string;
};

export type AddTpaComponentInitialOptions = AddWidgetOptions | AddPageOptions;

type AddTPAComponentOptions =
  EnhanceWithCommonOptions<AddTpaComponentInitialOptions>;
type AddTPAComponentPageOptions = EnhanceWithCommonOptions<AddPageOptions>;

const addComponent = function (
  editorAPI: EditorAPI,
  compId: string,
  options: AddTpaComponentInitialOptions,
  callback?: Function,
) {
  const addOptions = (options || {}) as AddTPAComponentOptions;

  const originalCallback = _.isFunction(callback) ? callback : _.noop;
  let appData: AppData;

  if (!addOptions.appDefinitionId) {
    const origCompData = editorAPI.selection.getSelectedComponentData();
    appData = editorAPI.dsRead.tpa.app.getData(origCompData.applicationId);
    addOptions.appDefinitionId = appData.appDefinitionId;
  }

  addOptions.compId = compId;

  if (addOptions.componentType === 'WIDGET' && sections.isSectionsEnabled()) {
    const targetPage = editorAPI.pages.getReference(
      addOptions?.widget?.wixPageId,
    );

    addOptions.containerRef = targetPage
      ? editorAPI.sections.getPageSectionsSortedByStageOrder(targetPage)?.[0]
      : undefined;
  }

  addOptions.callback = function (data: any) {
    if (data.onError) {
      originalCallback(data);
      return;
    }
    appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(
      originalOptions.appDefinitionId,
    );
    if (originalOptions.componentType === 'WIDGET') {
      navigateToWidgetPageIfNeeded(editorAPI, originalOptions);
      editorAPI.dsActions.waitForChangesApplied(function () {
        originalCallback({ compId: data.comp.id });

        const widget = editorAPI.dsRead.tpa.app.getWidgetDataFromTPAWidgetId(
          originalOptions.appDefinitionId,
          originalOptions?.widget?.tpaWidgetId,
        );
        sendAddWidgetBiEvent(editorAPI, appData, data.comp, widget?.widgetId);
      });
    } else if (originalOptions.componentType === 'PAGE') {
      const widgetData = editorAPI.dsRead.tpa.app.getWidgetDataFromTPAPageId(
        originalOptions.appDefinitionId,
        originalOptions?.page?.pageId,
      );

      if (widgetData) {
        navigateToSectionIfNeeded(
          editorAPI,
          data.page,
          data.sectionId,
          widgetData,
          originalOptions,
          callback,
        );
        sendSectionBiEvent(
          editorAPI,
          appData,
          data.page,
          data.sectionId,
          widgetData.widgetId,
        );
      }
    }
  };
  const originalOptions = _.clone(addOptions);

  tpaAddRemoveDSService.preAddComponent(
    editorAPI,
    originalOptions.appDefinitionId,
    addOptions,
    () => {
      editorAPI.dsActions.tpa.comp.add(addOptions);
    },
  );
};

function triggerPageCallback(
  editorAPI: EditorAPI,
  pageRef: PageRef,
  sectionId: string,
  callback: Function,
) {
  editorAPI.dsActions.waitForChangesApplied(function () {
    const pageData = editorAPI.dsRead.pages.data.get(pageRef.id);
    callback({
      compId: sectionId,
      pageRef,
      pageUriSEO: pageData?.pageUriSEO,
      title: pageData?.title,
    });
  });
}

const navigateToWidgetPageIfNeeded = function (
  editorAPI: EditorAPI,
  options: { widget?: { wixPageId?: string; shouldNavigate?: boolean } },
) {
  const curPageId = editorAPI.dsRead.pages.getFocusedPageId();
  const pageId = options?.widget?.wixPageId || curPageId;
  const shouldNavigate = options?.widget?.shouldNavigate ?? true;
  if (pageId !== curPageId && shouldNavigate) {
    editorAPI.pages.navigateTo(pageId, function () {
      editorAPI.panelManager.closePanelByName('tpa.compPanels.tpaSettings');
    });
  }
};

const navigateToSectionIfNeeded = function (
  editorAPI: EditorAPI,
  pageRef: PageRef,
  sectionId: string,
  widgetData: TPAWidget,
  options: AddTPAComponentPageOptions,
  callback: Function,
) {
  const shouldNavigate = options?.page?.shouldNavigate ?? true;
  if (shouldNavigate) {
    editorAPI.pages.navigateTo(pageRef.id, function () {
      triggerPageCallback(editorAPI, pageRef, sectionId, callback);
      editorAPI.panelManager.closePanelByName('tpa.compPanels.tpaSettings');
    });
  } else {
    triggerPageCallback(editorAPI, pageRef, sectionId, callback);
  }
};

const sendAddWidgetBiEvent = function (
  editorAPI: EditorAPI,
  appData: AppData,
  compRef: CompRef,
  widgetId: string,
) {
  const pagePointer = editorAPI.components.getPage(compRef);
  const pageId = pagePointer && pagePointer.id;
  const params = {
    app_id: appData.appDefinitionId,
    app_site_id: appData.instanceId,
    instance_id: compRef.id,
    widget_id: widgetId,
    page_id: pageId,
  };
  editorAPI.bi.event(bi.events.EXTENSION_ADDED, params);
};

const sendSectionBiEvent = function (
  editorAPI: EditorAPI,
  appData: AppData,
  pageRef: PageRef,
  sectionId: string,
  widgetId: string,
) {
  const params = {
    app_id: appData.appDefinitionId,
    app_site_id: appData.instanceId,
    instance_id: sectionId,
    widget_id: widgetId,
    page_id: pageRef.id,
  };
  editorAPI.bi.event(bi.events.EXTENSION_ADDED, params);
};

export { addComponent };

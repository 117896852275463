// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import ReactDOM from 'reactDOM';
import _ from 'lodash';
import constants from '#packages/constants';
import * as util from '#packages/util';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import bubbleOnHoverMixin from '../mixins/bubbleOnHoverMixin';
import * as imageFallbackMixin from '../mixins/imageFallbackMixin';
import presetItem from './presetItem';
import presetAreaItem from './presetAreaItem';

import SectionWrapper from '../sectionWrapper';
import { cx } from '#packages/util';

const TOOLTIP_DELAY = 10000;

function getMaxDimensions(items) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    items,
    function (res, item) {
      const { rect } = item.preset;
      res.width = Math.max(res.width, rect.x + rect.width);
      res.height = Math.max(res.height, rect.y + rect.height);
      return res;
    },
    { width: 0, height: 0 },
  );
}

function getMixins() {
  const mixins = [bubbleOnHoverMixin, imageFallbackMixin];
  return mixins;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'presetSection',
  mixins: getMixins(),
  propTypes: {
    hide: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    props: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
    additionalBehaviours: PropTypes.shape({
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      labelMode: PropTypes.oneOf(_.values(addPanelDataConsts.LABEL_BEHAVIOUR))
        .isRequired,
      infoIcon: PropTypes.oneOf(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/values
        _.values(addPanelDataConsts.INFO_ICON_BEHAVIOUR),
      ).isRequired,
      hoverImageAction: PropTypes.oneOf(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/values
        _.values(addPanelDataConsts.HOVER_IMAGE_ACTIONS),
      ),
    }).isRequired,
    showSectionHeader: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    additionalScaling: PropTypes.number,
    appDefinitionId: PropTypes.string,
    getAdditionalItemProps: PropTypes.func,
    setDragVectorShapeItem: PropTypes.func,
    ignoreStyleMutations: PropTypes.bool,
    hasSubCategory: PropTypes.bool,
  },
  getOnClick() {
    return this.props.props.onClick || this.props.onClick;
  },
  getItemProps(item, itemIndex) {
    const hasInfoIcon =
      this.props.additionalBehaviours.infoIcon ===
      addPanelDataConsts.INFO_ICON_BEHAVIOUR.HOVER;
    const iconEnabledForComp =
      this.props.additionalBehaviours.iconEnabledForComps[
        item.structure.componentType
      ];
    const infoIcon = _.isFunction(iconEnabledForComp)
      ? iconEnabledForComp(item.structure.data)
      : iconEnabledForComp;

    const onClick =
      this.props.props.onClick || item.onClick || this.props.onClick;
    const isOnClickOverridden = onClick !== this.props.onClick;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const props = _.assign(
      {},
      item,
      {
        onClick,
        onDrop: this.props.props.onDrop || this.props.onDrop || item.onDrop,
        onItemDrag: this.props.onItemDrag,
        appDefinitionId: this.props.appDefinitionId,
        labelMode: this.props.additionalBehaviours.labelMode,
        hoverImageAction: this.props.additionalBehaviours.hoverImageAction,
        scaleProportion: this.props.additionalBehaviours.scaleProportion,
        infoIcon:
          hasInfoIcon &&
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign(infoIcon, { mouseEnterCallback: this.hideBubble }),
        image: this.props.props.image,
        imageHover: this.props.props.imageHover,
        sectionTitle: this.props.title,
        ignoreStyleMutations: this.props.ignoreStyleMutations,
        presetTitle: this.props.presetTitle,
        categoryId: this.props.categoryId,
        itemHoverCallback: this.props.itemHoverCallback,
        itemHoverArg: {
          sectionIndex: this.props.sectionIndex,
          sectionTitle: this.props.title,
          itemIndex,
        },
        overrideClass: this.props.overrideClass,
        setDragVectorShapeItem: this.props.setDragVectorShapeItem,
        getImagePosition: this.getImagePosition,
        getPastePosition: this.props.getPastePosition,
        onComponentAddedToStage: isOnClickOverridden
          ? this.props.onComponentAddedToStage
          : _.noop,
      },
      this.props.getAdditionalItemProps &&
        this.props.getAdditionalItemProps(item),
    );

    if (this.props.onMouseEnter) {
      props.onMouseEnter = this.props.onMouseEnter;
    }
    if (this.props.onMouseLeave) {
      props.onMouseLeave = this.props.onMouseLeave;
    }

    if (item.shape) {
      props.shape = _.clone(item.shape);
    }
    return props;
  },
  getSectionDimensions(scaling) {
    const maxDimensions = getMaxDimensions(this.props.props.items);
    return this.props.isImageSection || this.props.liveTextSection
      ? {}
      : {
          width: maxDimensions.width * (scaling || 1),
          height: maxDimensions.height * (scaling || 1),
        };
  },
  isRetinaImage() {
    const retinaImage = this.props?.props?.retinaImage;
    const devicePixelRatio = util.browserUtil.getDevicePixelRatio();
    return devicePixelRatio >= 2 && retinaImage;
  },
  getImageSectionStyle() {
    if (this.isRetinaImage()) {
      const dimensions = this.props?.props?.retinaImage?.dimensions;
      return {
        width: dimensions.width / 2,
        height: dimensions.height / 2,
      };
    }
    return this.props?.props?.imageStyle ?? {};
  },
  getSectionItemsStyle() {
    const style = this.getSectionDimensions();
    if (this.props.additionalScaling && !this.props.isImageSection) {
      const scale = `scale(${this.props.additionalScaling},${this.props.additionalScaling})`;
      const transformOrigin = '0 0';
      const backfaceVisibility = 'hidden';
      style.WebkitTransform = scale;
      style.msTransform = scale;
      style.transform = scale;
      style.msTransformOrigin = transformOrigin;
      style.WebkitTransformOrigin = transformOrigin;
      style.transformOrigin = transformOrigin;
      style.WebkitBackfaceVisibility = backfaceVisibility;
      style.backfaceVisibility = backfaceVisibility;
    }
    return style;
  },
  getSectionImageSrc() {
    let image;
    if (this.isRetinaImage()) {
      image = this.props?.props?.retinaImage?.src;
    } else {
      image = this.props?.props?.image;
    }
    return util.media.getMediaUrl(image, this.getFallbackImage(image));
  },
  showLabels() {
    return this.props.additionalBehaviours.labelMode !== 'none';
  },
  showFooter() {
    return this.props.sectionFooter && !this.props.sectionFooter.hide;
  },
  getSectionClasses() {
    return {
      'items-wrapper': true,
      'image-items-wrapper': this.props.isImageSection,
    };
  },
  getCssClasses() {
    const withFooter = this.showFooter();
    const withLabels = this.showLabels();
    const classes = {
      'section-wrapper': true,
      'preset-section': true,
      'no-space-before-section': !this.props.showSectionHeader,
      'no-header-sibling': this.showSiblingHeader(),

      'with-footer': withFooter,
      'without-footer': !withFooter,

      'with-labels': withLabels,
      'without-labels': !withLabels,
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    return _.keys(_.pickBy(classes, Boolean)).join(' ');
  },
  showSiblingHeader() {
    const nextSection = this.props.nextSibling;
    return (
      nextSection &&
      nextSection.type === addPanelDataConsts.SECTIONS_TYPES.PRESET &&
      !nextSection.showSectionHeader
    );
  },
  getSectionWrapperProps() {
    if (this.props.sectionFooter) {
      return _.merge({}, this.props, {
        sectionFooter: {
          sectionTitle: this.props.title,
          getMediaPlayerVideoObject:
            util.backgroundUtils.getMediaPlayerVideoObject,
          onClick: this.getOnClick(),
        },
      });
    }
    return this.props;
  },

  getImagePosition() {
    if (this.refs.sectionImage) {
      return _.pick(
        ReactDOM.findDOMNode(this.refs.sectionImage).getBoundingClientRect(),
        ['left', 'top'],
      );
    }
    return null;
  },

  getSectionImageProps() {
    const attr = {
      src: this.getSectionImageSrc(),
    };
    if (this.props?.props?.items?.[0]?.preset?.shape) {
      attr.useMap = `#${this.props.id}`;
    }
    return attr;
  },

  getPresetItems() {
    const itemClass = this.props?.props?.items?.[0]?.preset?.shape
      ? presetAreaItem
      : presetItem;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const items = _.map(
      this.props.props.items,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (item, index) {
        return React.createElement(
          itemClass,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({}, { key: item.id }, this.getItemProps(item, index)),
        );
      }, this),
    );

    if (itemClass === presetAreaItem) {
      return React.createElement('map', { name: this.props.id }, items);
    }

    return items;
  },

  getItemClasses() {
    const itemClasses = {
      items: true,
      'has-label': this.showLabels(),
      'disabled-preset-section': this.props.isDisabled,
    };

    return itemClasses;
  },

  showHintForDisabledSection() {
    if (this.props.isDisabled) {
      const layout = this.refs.itemsWrapper.getBoundingClientRect();
      const editorAPI = this.getEditorAPI();
      const bubbleClass = {
        classPath: this.props.onHoverDisabledTooltipClassPath,
        props: {},
      };
      const targetLayout = {
        height: 0,
        width: 0,
        top: layout.top,
        left: layout.right,
      };
      const propsForBubble = {
        noArrow: true,
        shake: true,
        behindPopUps: true,
        alignment: constants.UI.TOOLTIP.ALIGNMENT.RIGHT,
        timeoutForMouseOverBubble: TOOLTIP_DELAY,
        closeTriggers: ['onMouseLeave'],
      };
      const shouldNotHideOnMouseLeaveTarget = true;

      editorAPI.showFloatingBubble(
        bubbleClass,
        targetLayout,
        propsForBubble,
        shouldNotHideOnMouseLeaveTarget,
      );
    }
  },

  render() {
    return (
      <SectionWrapper
        className={this.getCssClasses()}
        {...this.getSectionWrapperProps()}
      >
        <div
          ref="itemsWrapper"
          style={this.getSectionDimensions(this.props.additionalScaling || 1)}
          data-automation-id={this.props.automationId}
          onMouseEnter={() => {
            this.showHintForDisabledSection();
          }}
          onMouseMove={(e) => {
            if (this.props.parentType === 'ADD_PANEL') {
              this.showBubbleOnMouseMove(e);
            }
          }}
          onMouseLeave={(e) => {
            this.hideBubbleOnMouseLeave(e);
          }}
          className={cx(this.getSectionClasses())}
        >
          <div
            style={this.getSectionItemsStyle()}
            className={cx(this.getItemClasses())}
          >
            {this.props.props.image ? (
              <img
                ref="sectionImage"
                key="section_image"
                {...this.getSectionImageProps()}
                style={{
                  ...this.getImageSectionStyle(),
                  ...this.getSectionImageProps()?.style,
                }}
              />
            ) : null}
            {this.getPresetItems()}
          </div>
        </div>
      </SectionWrapper>
    );
  },
});

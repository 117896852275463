import * as util from '#packages/util';

import * as topBarMapper from './topBarMapper';
import TopBar from './topBar';

import * as newTopBarMapper from '../nextTopBarComponent/topBarMapper';
import NewTopBar from '../nextTopBarComponent/topBar';

import connect from './topBarConnect';

const { mapStateToProps, mapDispatchToProps } = util.topBar.isNewTopBarEnabled()
  ? newTopBarMapper
  : topBarMapper;
const Component = util.topBar.isNewTopBarEnabled() ? NewTopBar : TopBar;

export default connect(mapStateToProps, mapDispatchToProps)(Component);

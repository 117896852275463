import { sections as sectionsUtils } from '#packages/util';
import { events } from '#packages/coreBi';

import { MigrationFlow } from '../types';
import { isMigrationCancelled } from './reporter';
import { MIGRATION_SKIP_LIMIT } from '../constants';

import type { SectionsMigrationScope as Scope } from '../scope';
import { getMigrationSkipCount } from './migrationSkipCounter';

const isRestrictedFlowForUpdate = (flow: MigrationFlow) =>
  flow === MigrationFlow.Editor2Anchors ||
  flow === MigrationFlow.Editor2Fix ||
  flow === MigrationFlow.PerPageADI ||
  flow === MigrationFlow.PerPageEditor;

export function shouldUpdateMigrationVersionAfterFailure(
  scope: Scope,
  {
    flow,
    error,
  }: {
    flow: MigrationFlow;
    error: Error;
  },
) {
  if (isRestrictedFlowForUpdate(flow)) {
    return false;
  }

  if (isMigrationCancelled(error)) {
    const skipCount = getMigrationSkipCount(scope);

    if (skipCount < MIGRATION_SKIP_LIMIT) {
      sectionsUtils.updateSiteSectionsEditorData(scope.editorAPI, {
        isSectionsEnabled: false,
        migrationSkipCount: skipCount + 1,
      });

      return false;
    }

    scope.editorAPI.bi.event(
      events.sectionsMigration.MIGRATION_SKIPPED_THIRD_TIME,
      {
        flow,
      },
    );

    return true;
  }

  return true;
}

import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import { translate } from '#packages/i18n';

export const NoLink: React.FC = () => {
  return (
    <>
      {
        <div className="symbol-container">
          <Symbol name="noLink" />
        </div>
      }

      <h3>{translate('LINK_PANEL_PAGE_DROP_DOWN_Title')}</h3>
      <p>{translate('LINK_PANEL_PAGE_DROP_DOWN_Text')}</p>
    </>
  );
};

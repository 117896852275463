import { isMeshLayoutEnabled } from '#packages/layout';
import { sections } from '#packages/util';
import constants from '#packages/constants';
import { getPageSectionsWithLayoutSortedByStageOrder } from '../../api';

import type { Scope } from '#packages/sections';
import type { CompRef } from 'types/documentServices';
import type { ViewerChangesData } from 'types/core';

const adjustLastSectionToPageBottom = (
  scope: Scope,
  pageRef: CompRef,
  pageBottom: number,
) => {
  // TODO: check https://jira.wixpress.com/browse/WEED-28036
  if (isMeshLayoutEnabled()) {
    return;
  }

  const { components } = scope;
  const pageSections = getPageSectionsWithLayoutSortedByStageOrder(
    scope,
    pageRef,
  );
  const [lastSection] = pageSections.slice(-1);

  if (!lastSection) return;

  const lastSectionBottom = lastSection.layout.y + lastSection.layout.height;
  const bottomPadding = pageBottom - lastSectionBottom;

  if (bottomPadding <= 0) return;

  components.layout.resizeTo(
    lastSection.ref,
    {
      height: lastSection.layout.height + bottomPadding,
    },
    { dontAddToUndoRedoStack: true },
  );
};

const adjustPageHeight = (scope: Scope) => {
  const { editorAPI } = scope;
  const pageRef = editorAPI.pages.getCurrentPage();

  const defaultPageHeight = editorAPI.isMobileEditor()
    ? constants.CONSTRAINS.PAGE_MIN_HEIGHT.MOBILE
    : constants.CONSTRAINS.PAGE_MIN_HEIGHT.DESKTOP;

  const minPageHeight =
    (
      editorAPI.pages.properties.get(pageRef) as
        | undefined
        | { minHeight: number }
    )?.minHeight ?? defaultPageHeight;

  if (minPageHeight === sections.constants.SECTION_MIN_HEIGHT) {
    return;
  }

  // TODO; do we need propertties.minHeight update?
  // https://wix.slack.com/archives/C04EX7N2BCP/p1689155461778919
  editorAPI.pages.properties.update(pageRef, {
    minHeight: sections.constants.SECTION_MIN_HEIGHT,
  });

  adjustLastSectionToPageBottom(scope, pageRef, minPageHeight);
};

// viewMode.set === navigate to desktop/mobile
// documentMode.setComponentViewMode === change view mode (editor/preview)
const CHANGES_TO_REACT = [
  'pages.navigateTo',
  'viewMode.set',
  'documentMode.setComponentViewMode',
];

export const createAdjustPageMinHeightOnViewerChanges = (scope: Scope) => {
  adjustPageHeight(scope);

  return (changes: ViewerChangesData) => {
    if (!changes?.some((change) => CHANGES_TO_REACT.includes(change))) return;

    adjustPageHeight(scope);
  };
};

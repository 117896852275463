// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import * as util from '#packages/util';
import React from 'react';
import { FocusPanelFrame } from '../frames';
const SHOUTOUT_EDITOR_PANEL_CLOSE_TEMPLATE_FRAME = {
  evid: 91,
  src: 36,
  endpoint: 'shout',
  sampleRatio: 0,
  fields: {
    is_first: 'bool',
    msid: 'guid',
    referral_info: 'string',
  },
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'shoutOutDialog',
  mixins: [core.mixins.editorAPIMixin, util.postMessageMixin],
  propTypes: {
    frameUrl: PropTypes.object,
  },
  onClose() {
    const params = {
      is_first: true,
      referral_info: 'editor panel',
    };
    this.getEditorAPI().bi.event(
      SHOUTOUT_EDITOR_PANEL_CLOSE_TEMPLATE_FRAME,
      params,
    );
    return true;
  },
  UNSAFE_componentWillMount() {
    this.setPostMessageHandler(null, this._onMessageFromDialog);
  },
  _onMessageFromDialog(message) {
    if (message.type === 'cancel') {
      this.getEditorAPI().panelManager.closePanelByName(
        'panels.focusPanels.shoutOutTemplatesPanel',
      );
    }
  },
  render() {
    return (
      <div className="shoutout-templates-panel">
        <FocusPanelFrame
          panelName={'panels.focusPanels.shoutOutTemplatesPanel'}
          shouldHideHeader={true}
          onClose={this.onClose}
          className="shoutout-templates-panel"
        >
          <iframe
            id="shoutOutTemplates"
            ref="iframe"
            width="100%"
            height="100%"
            src={this.props.frameUrl}
            frameBorder="0"
            allowFullScreen
          />
        </FocusPanelFrame>
      </div>
    );
  },
});

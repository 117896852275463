import type { EntryPoint } from '#packages/apilib';
import { CampaignsApiKey, EditorAPIKey } from '#packages/apis';
import { createCampaignsApi } from './campaignsApi';

export const CampaignsApiEntryPoint: EntryPoint = {
  name: 'CampaignsApi',
  declareAPIs: () => [CampaignsApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(CampaignsApiKey, () => createCampaignsApi(shell));
  },
};

import { BaseEntryScope, Hooks, createEntryPoint } from '#packages/apilib';
import { WixCodeWorkspaceApi } from './api';
import { WixCodeWorkspaceApiKey } from './publicApiKey';
import { wixCodeWorkspaceStore } from './store';
export class Scope extends BaseEntryScope {
  store = this.declareStore(wixCodeWorkspaceStore);

  slots = {
    veloTreeComponents: this.declareComponentSlot(),
    wixCodeLoaderComponents: this.declareComponentSlot(),
    consoleComponents: this.declareComponentSlot(),
    codeEditorComponents: this.declareComponentSlot(),
    openBuildLogButtonComponents: this.declareComponentSlot(),
  };

  hooks = this.declareApi(() => ({
    devModeEnabled: Hooks.createHook(),
  }));
}

export const WixCodeWorkspaceEntrypoint = createEntryPoint({
  name: 'WixCodeWorkspaceEntrypoint',
  publicApi({ contributeApi }) {
    contributeApi(WixCodeWorkspaceApiKey, WixCodeWorkspaceApi);
  },
  Scope,
  initialize() {},
});

import { BasePublicApi } from '#packages/apilib';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '#packages/constants';
import assistants from '../assistant/assistantRegistry';
import { PANEL_WIDTH } from '../constants';

import type { AIAssistantPanelScope } from './aiAssistantPanelScope';
import type { OpenAIAssistantPanelProps } from './aiAssistantPanelTypes';

export function openAIAssistantPanel(
  scope: AIAssistantPanelScope,
  props?: OpenAIAssistantPanelProps,
) {
  const { workspaceRightPanelApi, store } = scope;

  setConfig(scope, {
    assistantId: props.assistantId,
    origin: props.origin,
  });

  const config = store.getConfig();

  workspaceRightPanelApi.open('aiAssistant', {
    panelName: WORKSPACE_RIGHT_PANEL_NAMES.AI_ASSISTANT,
    panelWidth: PANEL_WIDTH,
    title: config.title,
    onClose: config.onCloseAssistant,
    leavePanelsOpen: props.leavePanelsOpen,
    renderHeader: false,
  });
}

function setConfig(
  scope: AIAssistantPanelScope,
  { assistantId, origin }: Omit<OpenAIAssistantPanelProps, 'leavePanelsOpen'>,
) {
  if (!Object.keys(assistants).includes(assistantId)) {
    throw new Error(`Assistant with ID: ${assistantId} is not registered`);
  }

  const config = assistants[assistantId](
    scope.editorAPI,
    scope.editorAPI.store.dispatch,
    origin,
  );

  scope.store.setAssistantConfig(config);
}

export class AIAssistantApi extends BasePublicApi<AIAssistantPanelScope> {
  openAIAssistantPanel = this.bindScope(openAIAssistantPanel);
  setConfig = this.bindScope(setConfig);
}

import experiment from 'experiment';
import * as stateManagement from '#packages/stateManagement';
import { WorkspaceModesApiKey, FixedStageApiKey } from '#packages/apis';
import { sections } from '#packages/util';
import type { MapDispatchToProps, MapStateToProps } from 'types/redux';
import type {
  LeftBarDispatchProps,
  LeftBarOwnProps,
  LeftBarStateProps,
} from './leftBar';
import type { EditorState } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import { allowed } from '#packages/editorRestrictions';

const { unhighlightMenu } = stateManagement.leftBar.actions;

const { isHighlighted } = stateManagement.leftBar.selectors;
const { isInInteractionMode } = stateManagement.interactions.selectors;
const { getIsMobileWizardEnabled } =
  stateManagement.mobile.selectors.mobileWizard;

const isLeftBarHighlighted = (state: EditorState) =>
  isHighlighted(state) && !getIsMobileWizardEnabled(state) && !state.hideTools;

const isDevModeEnabled = (editorAPI: EditorAPI) =>
  editorAPI.developerMode.isEnabled();

export const mapStateToProps: MapStateToProps<
  LeftBarStateProps,
  LeftBarOwnProps
> = ({ state, editorAPI }) => {
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const isHighlighted = isLeftBarHighlighted(state);
  const withOverlay = isHighlighted && !isInInteractionMode(state);
  const devModeEnabled = isDevModeEnabled(editorAPI);
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  const isStageFixedWidth = fixedStageAPI.isStageFixedInDesktop();
  const isMobileEditor = editorAPI.isMobileEditor();
  const shouldShowAiButton =
    allowed('left-bar_ai.visible') &&
    experiment.isOpen('se_aiToolsLeftBar') &&
    sections.isSectionsEnabled() &&
    !isMobileEditor;
  const shouldShowLayersButton = !devModeEnabled;

  return {
    hideTools: state.hideTools,
    isHighlighted,
    withOverlay,
    currentWorkspaceMode: workspaceModesApi.getMode(),
    isStageFixedWidth,
    shouldShowAiButton,
    shouldShowLayersButton,
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  LeftBarDispatchProps,
  LeftBarOwnProps
> = {
  unhighlightMenu,
};

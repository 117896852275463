import type { EntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  WorkspaceModesApiKey,
} from '#packages/apis';
import { createToursAPI, ToursApiKey } from './api';

export const EditorToursEntryPoint: EntryPoint = {
  name: 'editorTours',
  declareAPIs: () => [ToursApiKey],
  getDependencyAPIs: () => [
    EditorAPIKey,
    EditorCoreApiKey,
    WorkspaceModesApiKey,
  ],
  attach(shell) {
    shell.contributeAPI(ToursApiKey, () => createToursAPI(shell));
  },
};

export { ToursApiKey };

import { layoutUtils } from '#packages/layoutUtils';
import { interactions } from '#packages/stateManagement';
import type { CompLayout } from 'types/documentServices';

import type { UpdateLayoutArgs } from '../common';
import type {
  DragMoveToInterceptionScope,
  DragMoveToInterceptionResult,
} from './types';

export function onDragStartInteraction(
  scope: DragMoveToInterceptionScope,
): DragMoveToInterceptionResult {
  const { editorAPI, compRefs } = scope;
  const state = editorAPI.store.getState();

  if (interactions.selectors.isInInteractionMode(state)) {
    interactions.actions.beforeInteractionDragStart(editorAPI, compRefs[0]);
    if (!interactions.selectors.isShownOnlyOnVariant(editorAPI, compRefs[0])) {
      interactions.actions.onInteractionDragStart(editorAPI);
    }
    return { isHandled: true };
  }

  return { isHandled: false };
}

const isInteractionDrag = (scope: DragMoveToInterceptionScope) => {
  const { compRefs, editorAPI } = scope;
  const state = editorAPI.store.getState();
  return (
    interactions.selectors.isInInteractionMode(state) &&
    !interactions.selectors.isShownOnlyOnVariant(editorAPI, compRefs[0])
  );
};

function updateTransformOnDrag(
  scope: DragMoveToInterceptionScope,
  { mouseCoordinates, isShiftPressed }: UpdateLayoutArgs,
) {
  const { mousePositionInitial: initMousePosition, editorAPI } = scope;
  const offsetX = mouseCoordinates.pageX - initMousePosition.x;
  const offsetY = mouseCoordinates.pageY - initMousePosition.y;

  interactions.actions.onInteractionDrag(
    editorAPI,
    offsetX,
    offsetY,
    isShiftPressed,
  );
}

export function onDragMoveUpdateLayoutInteraction(
  scope: DragMoveToInterceptionScope,
  _args: UpdateLayoutArgs,
  layout: CompLayout,
): DragMoveToInterceptionResult {
  const { editorAPI, compRefs } = scope;
  const state = editorAPI.store.getState();

  if (
    interactions.selectors.isInInteractionMode(state) &&
    !layoutUtils.canMoveByInteractionsLayoutConstraints(
      editorAPI,
      compRefs,
      layout,
    )
  ) {
    return { isHandled: true };
  }

  return { isHandled: false };
}

export function onDragMoveInteraction(
  scope: DragMoveToInterceptionScope,
  args: UpdateLayoutArgs,
): DragMoveToInterceptionResult {
  const isInteraction = isInteractionDrag(scope);
  if (isInteraction) {
    updateTransformOnDrag(scope, args);

    return { isHandled: true };
  }

  return { isHandled: false };
}

export function onDragEndInteraction(
  scope: DragMoveToInterceptionScope,
): DragMoveToInterceptionResult {
  const { editorAPI, compRefs } = scope;
  const state = editorAPI.store.getState();

  const isInteractionMode = interactions.selectors.isInInteractionMode(state);
  const isCompFocusMode = editorAPI.componentFocusMode.isEnabled();

  if (!isInteractionMode) {
    return { isHandled: false };
  }

  if (isCompFocusMode) {
    editorAPI.componentFocusMode.showCompOnTopIfNeeded();
  } else {
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop([
      interactions.selectors.getInteractionTriggerRef(state).id,
    ]);
  }

  if (!interactions.selectors.isShownOnlyOnVariant(editorAPI, compRefs[0])) {
    editorAPI.store.dispatch(interactions.actions.onInteractionDragEnd());
  }
  interactions.actions.afterInteractionDrag(editorAPI);

  return { isHandled: true };
}

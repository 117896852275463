import React from 'react';
import styles from './tabLabelWithIcon.scss';
import { translate } from '#packages/i18n';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

export interface TabLabelWithIconProps {
  text: string;
  iconName?: string;
}

export const TabLabelWithIcon: React.FC<TabLabelWithIconProps> = ({
  text,
  iconName,
}) => {
  return (
    <div className={styles.label}>
      {!!iconName && <Symbol name={iconName} />}
      <div className={styles.text}> {translate(text)}</div>
    </div>
  );
};

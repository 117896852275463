import React from 'react';
import { Text } from '@wix/wix-base-ui';
import constants from '#packages/constants';
import * as BaseUI from '#packages/baseUI';
import { cx } from '#packages/util';
import MobileOnlyIndicator from '../../mobileOnly/mobileOnlyIndicator';

export interface ComponentLabelsProps {
  infoText?: string;
  innerNickname?: string;
  componentType?: string;
  componentNickname?: string;
  secondaryNickname?: string;

  canDragFromDisplayNameLabel?: boolean;

  componentUIColor?: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  componentUITheme?: ValueOf<typeof constants.COMPONENT_UI_THEMES>;

  isDimmed?: boolean;

  isLabelBottom?: boolean;
  isLabelBelowLine?: boolean;
  isLabelLarge?: boolean;
  isPinnedToPage?: boolean;
  isMobileOnlyNonNativeComponent?: boolean;

  labelInlineStyles?: React.CSSProperties;

  automationId?: string;

  showComponentType?: boolean;
  showComponentNickname?: boolean;
  shouldShowConnectionIndication?: boolean;

  onDisplayNameLabelMouseDown?: (
    ev: React.MouseEvent<Element, MouseEvent>,
  ) => void;
  openHelpCenter?: (
    helpId: string,
    props?: object,
    biHelpParams?: object,
  ) => void;
}

class ComponentLabels extends React.Component<ComponentLabelsProps> {
  render() {
    const {
      isDimmed,
      isLabelBottom,
      isLabelLarge,
      isLabelBelowLine,
      labelInlineStyles,
      automationId,
      isMobileOnlyNonNativeComponent,
      showComponentType,
      componentType,
      openHelpCenter,
      componentUIColor,
      componentUITheme,
      isPinnedToPage,
      showComponentNickname,
      componentNickname,
      secondaryNickname,
      innerNickname,
      shouldShowConnectionIndication,
      canDragFromDisplayNameLabel,
      infoText,
      onDisplayNameLabelMouseDown = () => {},
    } = this.props;

    const componentNicknameClasses = componentNickname && {
      'label-comp-nickname': true,
      'components-ui-color-orange':
        componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
    };

    return (
      <span
        style={labelInlineStyles}
        data-aid={automationId}
        className={cx({
          'component-labels': true,
          'label-small': !isLabelLarge,
          'label-large': isLabelLarge,
          'label-above-line': !isLabelBelowLine,
          dimmed: isDimmed,
          'label-bottom': isLabelBottom,
          'label-top': !isLabelBottom,
        })}
      >
        {isMobileOnlyNonNativeComponent &&
        showComponentType &&
        componentType ? (
          <MobileOnlyIndicator
            openHelpCenter={openHelpCenter}
            componentUIColor={componentUIColor}
            componentUITheme={componentUITheme}
            className="component-labels-indicator"
            dimmed={isDimmed}
          />
        ) : null}
        {isPinnedToPage && (
          <span
            key="pinModeIndicator"
            className={cx({
              'symbol-container': true,
              'connected-comp':
                showComponentNickname && shouldShowConnectionIndication,
              'components-ui-color-orange':
                componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
            })}
          >
            <BaseUI.symbol
              name="pinModeIndicator"
              className="symbol-pinModeIndicator"
            />
          </span>
        )}
        {showComponentType && componentType && (
          <span
            key="componentTypeLabel"
            data-aid="display-name-label"
            onMouseDown={onDisplayNameLabelMouseDown}
            className={cx(
              'label-comp-type',
              'label-comp-type_se-madefor-font',
              {
                draggable: canDragFromDisplayNameLabel,
                'components-ui-color-orange':
                  componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
                'components-ui-theme-dark':
                  componentUITheme === constants.COMPONENT_UI_THEMES.DARK,
              },
            )}
          >
            <Text
              skin="secondary"
              size={isLabelLarge ? 'small' : 'tiny'}
              shouldTranslate={false}
              enableEllipsis={false}
            >
              {componentType}
            </Text>
          </span>
        )}

        {showComponentNickname && (
          <BaseUI.tooltip
            value="PLATFORM_CONNECTED_ELEMENT_SETTINGS_TOOLTIP"
            styleType="normal"
            alignment={constants.UI.TOOLTIP.ALIGNMENT.TOP}
            key="componentNicknamesLabel"
            disabled={!shouldShowConnectionIndication}
            shouldTranslate={true}
          >
            <span>
              {componentNickname && (
                <span
                  key="componentNicknameLabel"
                  className={cx(componentNicknameClasses)}
                >
                  {componentNickname}
                </span>
              )}
              {secondaryNickname && (
                <span
                  key="componentSecondaryNicknameLabel"
                  className={cx({
                    'secondary-nickname': true,
                    ...componentNicknameClasses,
                  })}
                >
                  {secondaryNickname}
                </span>
              )}
              {innerNickname && (
                <span
                  key="componentInnerNickNameLabel"
                  className={cx({
                    'inner-nickname': true,
                    ...componentNicknameClasses,
                  })}
                >
                  {innerNickname}
                </span>
              )}
            </span>
          </BaseUI.tooltip>
        )}

        {infoText && shouldShowConnectionIndication && (
          <BaseUI.infoIcon
            key="infoIcon"
            text={infoText}
            symbolName="connectedInfoIcon_v2"
            automationId="connect-icon"
            className="label-info-icon-container"
          />
        )}
      </span>
    );
  }
}

export default ComponentLabels;

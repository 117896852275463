import type { Scope } from '#packages/sections';

import { enforceSectionsLayoutOnHomepage } from './enforceSectionsLayoutOnHomepage';
import { enforceSupportMobile } from './enforceSupportMobile';
import { createSectionsEnforcementOnViewerChanges } from './enforcement';
import { createAddBlankSectionOnViewerChanges } from './addBlankSection';
import { createAdjustPageMinHeightOnViewerChanges } from './adjustPageMinHeight';
import { createBeforePageNavigateEnforcement } from './createBeforePageNavigateEnforcement';
import { createAfterPageNavigateEnforcement } from './createAfterPageNavigateEnforcement';
import { enforceClearSectionsTransformations } from './enforceClearSectionsTransformations';

const bootstrapEnforcement = (scope: Scope) => {
  const { editorAPI } = scope;

  const enforceSectionsOnViewerChanges =
    createSectionsEnforcementOnViewerChanges(scope);
  const addBlankSectionIfNeeded = createAddBlankSectionOnViewerChanges(scope);
  const adjustPageMinHeight = createAdjustPageMinHeightOnViewerChanges(scope);
  const enforceSectionContainerBeforeNavigation =
    createBeforePageNavigateEnforcement(scope);
  const clearSectionsTransformations =
    enforceClearSectionsTransformations(scope);

  editorAPI.pages.hooks.beforePageNavigate.tap(
    enforceSectionContainerBeforeNavigation,
  );
  editorAPI.registerPageNavigationCallback(
    createAfterPageNavigateEnforcement(scope),
  );
  editorAPI.registerToViewerChange(enforceSectionsOnViewerChanges);
  editorAPI.registerToViewerChange(addBlankSectionIfNeeded);
  editorAPI.registerToViewerChange(adjustPageMinHeight);
  editorAPI.registerToViewerChange(clearSectionsTransformations);

  enforceSectionsLayoutOnHomepage(scope);
  enforceSupportMobile(scope);
};

export { bootstrapEnforcement };

import _ from 'lodash';
import { EditorAPIKey } from '#packages/apis';
import * as util from '#packages/util';
import * as staticAssets from '#packages/staticAssets';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import experiment from 'experiment';
import type { Shell } from '#packages/apilib';
import * as mediaManagerPanelInfra from '#packages/mediaManagerPanelInfra';
import { addPanelUtils, mediaManagerUtils } from '#packages/addPanelInfra';
import { ErrorReporter } from '@wix/editor-error-reporter';

const mediaManagerPanelUtils = mediaManagerPanelInfra.utils;

const { VECTOR_ART_DEFAULT_PATH } = constants.VECTOR_IMAGE_DEFAULTS;

export function createPromotionsApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  let userRegisteredCategory: AnyFixMe;

  function getUserRegisteredCategory() {
    const userProfile = stateManagement.userProfile.selectors.getUserProfile(
      editorAPI.store.getState(),
    );
    return userProfile?.post_reg_category?.value || '';
  }

  function getPromotionImageSrc(promotionName: AnyFixMe) {
    const promotionImageCompiled = _.template(
      'panels/focusPanels/<%= promotionName %>PromotionPopup.png',
    );
    const promotionImageRetinaCompiled = _.template(
      'panels/focusPanels/<%= promotionName %>PromotionPopupRetina.png',
    );
    const devicePixelRatio = util.browserUtil.getDevicePixelRatio();

    if (devicePixelRatio >= 2) {
      return util.media.getMediaUrl(
        staticAssets.staticAssetsMap[
          promotionImageRetinaCompiled({
            promotionName,
          }) as keyof typeof staticAssets.staticAssetsMap
        ],
      );
    }
    return util.media.getMediaUrl(
      staticAssets.staticAssetsMap[
        promotionImageCompiled({
          promotionName,
        }) as keyof typeof staticAssets.staticAssetsMap
      ],
    );
  }

  function initMusicPromo() {
    if (
      experiment.isOpen('musicPromotionFixed') ||
      experiment.isOpen('musicPromotionRotation')
    ) {
      const musicPromoAPI = editorAPI.promotions.music;

      editorAPI.dsRead.tpa.app.registerOnInstalled(
        constants.MUSIC_PROMO.APP_DEFINITION_ID,
        musicPromoAPI.displayPopup,
      );

      if (musicPromoAPI.isActive()) {
        musicPromoAPI.displayPopup();
      }
    }
  }

  function onPhotoAppInstalled() {
    const PHOTO_PROMO_POPUP_DISPLAYED = 'photo-promo-popup-displayed';
    if (
      !stateManagement.userPreferences.selectors.getSessionUserPreferences(
        PHOTO_PROMO_POPUP_DISPLAYED,
      )(editorAPI.store.getState())
    ) {
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          PHOTO_PROMO_POPUP_DISPLAYED,
          true,
        ),
      );
      editorAPI.promotions.photography.displayPopup();
    }
  }

  function initPhotographyPromo() {
    if (
      experiment.isOpen('photographyPromotionFixed') ||
      experiment.isOpen('photographyPromotionRotation')
    ) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(
        constants.PHOTOGRAPHY_PROMO.APP_DEFINITION_IDS,
        function (appDefinitionId) {
          editorAPI.dsRead.tpa.app.registerOnInstalled(
            appDefinitionId,
            onPhotoAppInstalled,
          );
        },
      );
    }
  }

  function initPromotions() {
    userRegisteredCategory = getUserRegisteredCategory().toLowerCase();

    initMusicPromo();
    initPhotographyPromo();
  }

  function isMusicActive() {
    if (
      experiment.isOpen('musicPromotionFixed') ||
      experiment.isOpen('musicPromotionRotation')
    ) {
      const templateId =
        editorAPI.dsRead.generalInfo.getSiteOriginalTemplateId();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      const isMusicTemplate = _.includes(
        constants.MUSIC_PROMO.TEMPLATE_IDS,
        templateId,
      );
      const isMusicAppInstalled = editorAPI.dsRead.tpa.app.isInstalled(
        constants.MUSIC_PROMO.APP_DEFINITION_ID,
      );

      return (
        isMusicTemplate ||
        isMusicAppInstalled ||
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        _.includes(userRegisteredCategory, 'music')
      );
    }

    return false;
  }

  function displayMusicPopup() {
    editorAPI.panelManager.openPanel('panels.focusPanels.promotionModalPanel', {
      className: 'music-promo-popup',
      headerFirstLineText: 'MUSIC_PROMOTION_HEADER_2',
      imageSrc: getPromotionImageSrc('music'),
      bodyText: 'MUSIC_PROMOTION_BODY_LINE1',
      bodyLinkText: 'MUSIC_PROMOTION_BODY_B4_ENDS',
      bodyBottomText: 'MUSIC_PROMOTION_BODY_EASY',
      actionText: 'MUSIC_PROMOTION_BUTTON_SUBMIT',
      actionUrl: 'MUSIC_PROMOTION_BUTTON_SUBMIT_URL',
      biEvent: coreBi.events.musicPromotionPopup.submit_video_clicked,
    });
  }

  function isPhotographyActive() {
    if (
      experiment.isOpen('photographyPromotionFixed') ||
      experiment.isOpen('photographyPromotionRotation')
    ) {
      const templateId = editorAPI.dsRead.generalInfo
        .getSiteOriginalTemplateId()
        .toUpperCase();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      const isPhotographyTemplate = _.includes(
        constants.PHOTOGRAPHY_PROMO.TEMPLATE_IDS,
        templateId,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      const isAnyPhotographyAppInstalled = _.some(
        constants.PHOTOGRAPHY_PROMO.APP_DEFINITION_IDS,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/bind
        _.bind(editorAPI.dsRead.tpa.app.isInstalled, editorAPI),
      );
      const isUserCategoryPhotographyRelated =
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        _.includes(userRegisteredCategory, 'photography') ||
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        _.includes(userRegisteredCategory, 'design');

      return (
        isPhotographyTemplate ||
        isAnyPhotographyAppInstalled ||
        isUserCategoryPhotographyRelated
      );
    }

    return false;
  }

  function displayPhotographyPopup() {
    editorAPI.panelManager.openPanel('panels.focusPanels.promotionModalPanel', {
      className: 'photo-promo-popup',
      headerFirstLineText: 'PHOTO_PROMO_POP_HEADER1',
      headerSecondLineText: 'PHOTO_PROMO_POP_HEADER2',
      imageSrc: getPromotionImageSrc('photo'),
      bodyText: 'PHOTO_PROMO_POP_TEXT1',
      isImageLinkActive: false,
      actionText: 'PHOTO_PROMO_POP_BUTTON_TEXT',
      actionUrl: 'PHOTO_PROMO_POP_BUTTON_URL',
      biEvent: coreBi.events.photographyPromotionPopup.submit_video_clicked,
    });
  }

  const displayUAPromo = async () => {
    try {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.HELP_UKRAINE,
      );

      const openMediaManager = (
        category: AnyFixMe,
        openSource: AnyFixMe,
        options: AnyFixMe,
      ) => {
        return mediaManagerUtils.openMediaManager(
          editorAPI,
          category,
          openSource,
          options,
        );
      };

      const openSource = 'change_vector_art';
      const { items, info } = await openMediaManager(
        editorAPI.mediaServices.mediaManager.categories.VECTOR_ART,
        openSource,
        {
          multiSelect: true,
          path: VECTOR_ART_DEFAULT_PATH,
        },
      );

      if (!items) {
        return;
      }

      const addImagePromises = items.map((item) => {
        const getCompStructure =
          mediaManagerPanelUtils.getComponentStructureCreator(item, {
            isSocialIcon: false,
          });

        if (_.isFunction(getCompStructure)) {
          const compDef = getCompStructure(
            item,
            info,
            editorAPI.components.buildDefaultComponentStructure,
          );

          return addPanelUtils.attemptToAddComponent(editorAPI, compDef);
        }

        return null;
      });

      const images = await Promise.all(addImagePromises);

      await editorAPI.waitForChangesAppliedAsync();

      const externalLink = editorAPI.dsRead.data.createItem('ExternalLink');
      externalLink.url = 'https://www.wix.com/stands-with-ukraine/';

      images.forEach((compRef) => {
        editorAPI.components.data.update(compRef, { link: externalLink });
      });

      editorAPI.mediaServices.setFocusToEditor();

      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.HELP_UKRAINE,
      );
    } catch (error: MaybeError) {
      error.name = `[UkraineHelp]: ${error.name}`;
      ErrorReporter.captureException(error);
    }
  };

  const isUAPromotionActive = () => {
    return (
      util.url.getParameterByName('deeplink') ===
      'mediapanel!public/98285ba8ab164d17a935742bd019e150/ed8054c8d49f41bb92efb3cb42c5a85c'
    );
  };

  return {
    initPromotions,
    music: {
      isActive: isMusicActive,
      displayPopup: displayMusicPopup,
    },
    photography: {
      isActive: isPhotographyActive,
      displayPopup: displayPhotographyPopup,
    },
    helpUkraine: {
      isActive: isUAPromotionActive,
      display: displayUAPromo,
    },
  };
}

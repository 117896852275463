// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: [],
      sections: [addPanel.sections.getSections().button.iconButtonsSection],
    };
  },
};

// @ts-nocheck
import * as core from '#packages/core';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Composites,
  Divider,
  TextLabel,
  Thumbnails,
  Tooltip,
} from '@wix/wix-base-ui';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as coreBi from '#packages/coreBi';
import * as baseUI from '#packages/baseUI';
import { FocusPanelFrame } from '../frames';
import { translate } from '#packages/i18n';
import * as helpIds from '#packages/helpIds';

const IMAGE_QUALITY_BI_ORIGIN = 'global';
const IMAGE_QUALITY_PRESET_CUSTOM = 'custom';
const IMAGE_QUALITY_PRESET_NAMES = [
  'none',
  'mild',
  'regular',
  'moderate',
  'strong',
  'maximum',
];
const IMAGE_QUALITY_DEFAULT_INDEX = 2;
const IMAGE_QUALITY_VALUES = [
  { unsharpMask: { radius: 0.0, amount: 0.0, threshold: 0.0 }, quality: 90 },
  { unsharpMask: { radius: 0.33, amount: 1.0, threshold: 0.0 } },
  { unsharpMask: { radius: 0.66, amount: 1.0, threshold: 0.01 } },
  { unsharpMask: { radius: 1.2, amount: 1.0, threshold: 0.01 } },
  { unsharpMask: { radius: 2.0, amount: 1.0, threshold: 0.0 } },
  { unsharpMask: { radius: 4.0, amount: 1.0, threshold: 0.0 } },
];

const IMAGE_QUALITY_THUMBS_OPTIONS = [
  {
    value: 0,
    label: 'IMAGE_QUALITY_NAME_NONE',
    illustration: React.createElement(
      Tooltip,
      {
        content: 'IMAGE_QUALITY_NAME_NONE_TOOLTIP',
        alignment: 'TOP',
        marginTop: 23,
      },
      React.createElement(symbols.symbol, { name: 'image-quality-none' }),
    ),
  },
  {
    value: 1,
    label: 'IMAGE_QUALITY_NAME_GLOBAL_MILD',
    illustration: React.createElement(
      Tooltip,
      {
        content: 'IMAGE_QUALITY_NAME_GLOBAL_MILD_TOOLTIP',
        alignment: 'TOP',
        marginTop: 23,
      },
      React.createElement(symbols.symbol, { name: 'image-quality-mild' }),
    ),
  },
  {
    value: 2,
    label: 'IMAGE_QUALITY_NAME_GLOBAL_REGULAR',
    illustration: React.createElement(
      Tooltip,
      {
        content: 'IMAGE_QUALITY_NAME_GLOBAL_REGULAR_TOOLTIP',
        alignment: 'TOP',
        marginTop: 23,
      },
      React.createElement(symbols.symbol, { name: 'image-quality-regular' }),
    ),
  },
  {
    value: 3,
    label: 'IMAGE_QUALITY_NAME_GLOBAL_MODERATE',
    illustration: React.createElement(
      Tooltip,
      {
        content: 'IMAGE_QUALITY_NAME_GLOBAL_MODERATE_TOOLTIP',
        alignment: 'TOP',
        marginTop: 23,
      },
      React.createElement(symbols.symbol, { name: 'image-quality-moderate' }),
    ),
  },
  {
    value: 4,
    label: 'IMAGE_QUALITY_NAME_GLOBAL_STRONG',
    illustration: React.createElement(
      Tooltip,
      {
        content: 'IMAGE_QUALITY_NAME_GLOBAL_STRONG_TOOLTIP',
        alignment: 'TOP',
        marginTop: 23,
      },
      React.createElement(symbols.symbol, { name: 'image-quality-strong' }),
    ),
  },
  {
    value: 5,
    label: 'IMAGE_QUALITY_NAME_GLOBAL_MAXIMUM',
    illustration: React.createElement(
      Tooltip,
      {
        content: 'IMAGE_QUALITY_NAME_GLOBAL_MAXIMUM_TOOLTIP',
        alignment: 'TOP',
        marginTop: 23,
      },
      React.createElement(symbols.symbol, { name: 'image-quality-maximum' }),
    ),
  },
];

/**
 * returns the index of current applied quality/unsharpMask object
 * @param currentQuality
 * @param presetCollection
 */
function getPresetIndex(currentQuality, presetCollection) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  return _.findIndex(presetCollection, function (quality) {
    return _.isEqual(quality, currentQuality);
  });
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'siteImageOptimization',
  propTypes: {
    unsharpMask: PropTypes.shape({
      radius: PropTypes.number,
      amount: PropTypes.number,
      threshold: PropTypes.number,
    }),
  },

  getInitialState() {
    const value =
      this.getGlobalImageQualityData() ||
      IMAGE_QUALITY_VALUES[IMAGE_QUALITY_DEFAULT_INDEX];
    const selectedIndex = getPresetIndex(value, IMAGE_QUALITY_VALUES);
    return {
      selectedIndex,
      selectedValue: value,
    };
  },

  getImageQualityPresetsOptions() {
    return IMAGE_QUALITY_THUMBS_OPTIONS;
  },

  linkedQualityPresets() {
    return {
      value: this.state.selectedIndex,
      requestChange: function (val) {
        this.setState({
          selectedValue: IMAGE_QUALITY_VALUES[val],
          selectedIndex: val,
        });
      }.bind(this),
    };
  },

  handleApplyQuality() {
    if (this.isApplyDisabled()) {
      return;
    }
    this.updateGlobalImageQualityData(this.state.selectedValue);
    this.sendQualityApplyBI(this.state.selectedIndex);
    this.closeImageQualityPanel();
  },

  sendQualityApplyBI(selectedIndex) {
    const { selectedValue } = this.state;
    this.getEditorAPI().bi.event(
      coreBi.events.siteImageQualitySettingsPanel
        .site_image_quality_settings_panel_apply,
      {
        origin: IMAGE_QUALITY_BI_ORIGIN,
        preset:
          IMAGE_QUALITY_PRESET_NAMES[selectedIndex] ||
          IMAGE_QUALITY_PRESET_CUSTOM,
        unsharp_amount: selectedValue.unsharpMask.amount * 100,
        unsharp_radius: selectedValue.unsharpMask.radius * 100,
        unsharp_threshold: selectedValue.unsharpMask.threshold * 100,
      },
    );
  },

  closeImageQualityPanel() {
    const editorApi = this.getEditorAPI();
    editorApi.panelManager.closePanelByName(this.props.panelName);
  },

  isApplyDisabled() {
    //apply is disabled on one of those cases:
    // 1. value doesn't exist in presets(custom unsharpMask)
    // 2. there is no value and the current selection is on default
    // 3. selection values are equal to current data

    const currentData = this.getGlobalImageQualityData();

    return (
      this.state.selectedIndex === -1 ||
      (!this.getGlobalImageQualityData() &&
        this.state.selectedIndex === IMAGE_QUALITY_DEFAULT_INDEX) ||
      _.isEqual(currentData, this.state.selectedValue)
    );
  },

  getGlobalImageQualityData() {
    const imageQuality = this.getEditorAPI().dsRead.media.imageQuality.get();
    return _.isEmpty(imageQuality)
      ? null
      : _.pick(imageQuality, ['unsharpMask', 'quality']);
  },

  updateGlobalImageQualityData(value) {
    const editorAPI = this.getEditorAPI();
    editorAPI.dsActions.media.imageQuality.reset();
    editorAPI.dsActions.media.imageQuality.update(value);
    editorAPI.dsActions.wixapps.classics.reloadApp('blog');
    this.getEditorAPI().history.add(
      'site image quality panel - update quality',
    );
  },

  render() {
    return (
      <FocusPanelFrame
        panelName={this.props.panelName}
        title={translate('SITE_IMAGE_QUALITY_SETTINGS_PANEL_HEADER_TITLE')}
        ref="frame"
        helpId={helpIds.SETTINGS.IMAGE_OPTIMIZATION}
        className="site-image-optimisation-panel"
      >
        <Composites.Thumbnails>
          <TextLabel
            value="SITE_IMAGE_QUALITY_SETTINGS_PANEL_DESCRIPTION"
            enableEllipsis={false}
          />
          <Thumbnails
            value={this.linkedQualityPresets().value}
            onChange={this.linkedQualityPresets().requestChange}
            maxThumbsPerRow={3}
            borderType="thumbnailAndLabel"
            options={this.getImageQualityPresetsOptions()}
          />
        </Composites.Thumbnails>

        <Divider long={true} />
        <div className="action-buttons">
          <baseUI.button2
            label="SITE_IMAGE_QUALITY_SETTINGS_PANEL_CANCEL_BUTTON"
            onClick={this.closeImageQualityPanel}
            className="default image-optimisation-cancel"
          />
          <baseUI.button2
            label="SITE_IMAGE_QUALITY_SETTINGS_PANEL_APPLY_BUTTON"
            disabled={this.isApplyDisabled()}
            onClick={this.handleApplyQuality}
            className="default image-optimisation-done"
          />
        </div>
      </FocusPanelFrame>
    );
  },
});

import _ from 'lodash';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import { layoutUtils } from '#packages/layoutUtils';
import type { CompLayout } from 'types/documentServices';

const COMP_IDS_CANNOT_BE_SELECTED_BY_LASSO = [
  'SITE_PAGES',
  'PAGES_CONTAINER',
  'SITE_HEADER',
  'SITE_FOOTER',
];

const REAL_LASSO_DRAG_THRESHOLD = 4;

let allPageCompsAndLayoutsByType: AnyFixMe;
let componentsContainedInLasso: AnyFixMe;
let currentLassoLayout: AnyFixMe;
let initMousePosition: AnyFixMe;
let isRealLasso: AnyFixMe;
let editorAPI: AnyFixMe;
let compsBeforeLasso: AnyFixMe;
let selectedComps;
let shouldCancelLastSelection: AnyFixMe;
let isSingleSelectionCompSelected: AnyFixMe;
const { translateToViewerCoordinates, getStageLayout } =
  stateManagement.domMeasurements.selectors;
const { getIsComponentVisibleInCurrentMode } =
  stateManagement.components.selectors;

function isRealLassoDrag(event: AnyFixMe) {
  if (isRealLasso) {
    return true;
  }

  const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);

  const realPageY = mouseCoordinates.pageY;
  const realPageX = mouseCoordinates.pageX;

  const xDistance = Math.abs(realPageX - initMousePosition.x);
  const yDistance = Math.abs(realPageY - initMousePosition.y);

  isRealLasso =
    xDistance > REAL_LASSO_DRAG_THRESHOLD &&
    yDistance > REAL_LASSO_DRAG_THRESHOLD;
  return isRealLasso;
}

function getLassoRectOffsetLeft() {
  if (!util.fixedStage.isFixedStageEnabled()) {
    return 0;
  }
  const { x: stageX, left: stageLeft } = getStageLayout(
    editorAPI.store.getState(),
  );
  const previewMarginLeft = stageLeft - stageX;
  return previewMarginLeft;
}

function getCurrentLassoLayoutFromEvent(event: AnyFixMe) {
  const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
  const stageLeftMargin = getLassoRectOffsetLeft();
  const mousePosition = _.cloneDeep(initMousePosition);
  if (stageLeftMargin) {
    mousePosition.x += stageLeftMargin;
    mouseCoordinates.pageX += stageLeftMargin;
  }

  const minX = Math.min(mouseCoordinates.pageX, mousePosition.x);
  const minY = Math.min(mouseCoordinates.pageY, mousePosition.y);
  const maxX = Math.max(mouseCoordinates.pageX, mousePosition.x);
  const maxY = Math.max(mouseCoordinates.pageY, mousePosition.y);

  return {
    x: minX,
    y: minY,
    width: maxX - minX,
    height: maxY - minY,
  };
}

function getComponentLayoutPair(component: AnyFixMe) {
  const compLayout = editorAPI.components.layout.getRelativeToScreen(component);

  const stageLeftMargin = getLassoRectOffsetLeft();
  compLayout.bounding.x += stageLeftMargin;

  if (
    editorAPI.components.getType(component) ===
    'wysiwyg.viewer.components.WRichText'
  ) {
    updateTextLayoutWithEffectiveLayout(component, compLayout);
  }

  return {
    component,
    layout: compLayout,
  };
}

function updateTextLayoutWithEffectiveLayout(
  component: AnyFixMe,
  compLayout: CompLayout,
) {
  const effectiveTextLayout =
    editorAPI.components.layout.getEffectiveTextDimensions(component);
  if (effectiveTextLayout) {
    compLayout = {
      ...compLayout,
      width: effectiveTextLayout.width,
      height: effectiveTextLayout.height,
      x: compLayout.x + effectiveTextLayout.dx,
      y: compLayout.y + effectiveTextLayout.dy,
      bounding: {
        ...compLayout.bounding,
        width: effectiveTextLayout.width,
        height: effectiveTextLayout.height,
        x: compLayout.bounding.x + effectiveTextLayout.dx,
        y: compLayout.bounding.y + effectiveTextLayout.dy,
      },
    };
  }

  return compLayout;
}

function getComponentSelectionType(componentLayoutPair: AnyFixMe) {
  if (
    editorAPI.components.is.fullWidth(componentLayoutPair.component) ||
    editorAPI.components.layout.isHorizontallyStretchedToScreen(
      componentLayoutPair.component,
    )
  ) {
    return 'fullWidth';
  }

  return 'standard';
}

function startLasso(_editorAPI: AnyFixMe, params: AnyFixMe) {
  editorAPI = _editorAPI;
  const currentPageId = editorAPI.dsRead.pages.getFocusedPageId();
  selectedComps = params.selectedComponents;
  const idsNotToBeSelectedByLasso = COMP_IDS_CANNOT_BE_SELECTED_BY_LASSO.concat(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _.map(selectedComps, 'id'),
  );
  const pageComponents = editorAPI.components.getAllComponents(
    currentPageId,
    function shouldCompBeSelectedByLasso(component: AnyFixMe) {
      return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        !_.includes(idsNotToBeSelectedByLasso, component.id) &&
        !editorAPI.components.is.page(component)
      );
    },
  );
  compsBeforeLasso = params.prevSelectedComps;
  shouldCancelLastSelection =
    compsBeforeLasso !== selectedComps &&
    params.initMousePosition.isSpecialKeyPressed;

  isSingleSelectionCompSelected =
    editorAPI.selection.isSingleSelectionComponent(selectedComps);

  initMousePosition = _.clone(params.initMousePosition);
  isRealLasso = false;

  componentsContainedInLasso = [];
  currentLassoLayout = _.pick(initMousePosition, ['x', 'y']);
  const leftOffset = getLassoRectOffsetLeft();

  currentLassoLayout.x += leftOffset;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  allPageCompsAndLayoutsByType = _(pageComponents)
    .map(getComponentLayoutPair)
    .groupBy(getComponentSelectionType)
    .value();
}

function onLasso(event: AnyFixMe) {
  if (isRealLassoDrag(event)) {
    if (isSingleSelectionCompSelected) {
      editorAPI.selection.deselectComponents();
      isSingleSelectionCompSelected = false;
    }

    if (shouldCancelLastSelection) {
      editorAPI.selection.selectComponentByCompRef(compsBeforeLasso);
      shouldCancelLastSelection = false;
    }

    currentLassoLayout = getCurrentLassoLayoutFromEvent(event);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    allPageCompsAndLayoutsByType.standard = _.isUndefined(
      allPageCompsAndLayoutsByType.standard,
    )
      ? []
      : allPageCompsAndLayoutsByType.standard;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    allPageCompsAndLayoutsByType.fullWidth = _.isUndefined(
      allPageCompsAndLayoutsByType.fullWidth,
    )
      ? []
      : allPageCompsAndLayoutsByType.fullWidth;

    const currentEditorState = editorAPI.store.getState();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/concat
    componentsContainedInLasso = _(allPageCompsAndLayoutsByType.standard)
      .concat(allPageCompsAndLayoutsByType.fullWidth)
      .filter((compLayoutPair) =>
        layoutUtils.isLayoutIntersectsRect(
          compLayoutPair.layout.bounding,
          currentLassoLayout,
        ),
      )
      .filter(
        (compLayoutPair) =>
          !editorAPI.columns.isColumn(compLayoutPair.component),
      )
      .filter((compLayoutPair) =>
        editorAPI.components.is.selectable(compLayoutPair.component),
      )
      .filter((compLayoutPair) =>
        getIsComponentVisibleInCurrentMode(
          editorAPI.dsRead,
          compLayoutPair.component,
          currentEditorState,
        ),
      )
      .value();

    editorAPI.lasso.setCandidates(componentsContainedInLasso);
    editorAPI.lasso.setLayout(currentLassoLayout);
  }
}

function endLasso(event: AnyFixMe) {
  const specialKeyPressed = util.browserUtil.isSpecialKeyPressed(event);
  if (!_.isEmpty(componentsContainedInLasso)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    let componentsToBeSelected = _.map(componentsContainedInLasso, 'component');
    if (componentsToBeSelected.length > 1) {
      componentsToBeSelected = _.reject(
        componentsToBeSelected,
        editorAPI.selection.isSingleSelectionComponent,
      );
    }
    if (
      componentsToBeSelected.length > 1 ||
      (componentsToBeSelected.length === 1 &&
        compsBeforeLasso &&
        compsBeforeLasso.length > 0)
    ) {
      editorAPI.bi.event(coreBi.events.selection.MULTISELECT, {
        num_of_elements_selected: componentsToBeSelected.length,
        selection_method: 'lasso',
      });
    }

    editorAPI.closeCompPanelIfExists();
    if (specialKeyPressed) {
      editorAPI.selection.addComponentToSelectionByRef(componentsToBeSelected);
    } else {
      editorAPI.selection.selectComponentByCompRef(componentsToBeSelected);
    }
  }
  editorAPI.lasso.clear();
}

export default {
  start: startLasso,
  on: onLasso,
  end: endLasso,
  type: constants.MOUSE_ACTION_TYPES.LASSO,
};

import { searchModule } from '../searchModule';
import { Integrations } from '../constants';

import type { EditorAPI } from '#packages/editorAPI';

export const integrateLeftBarToSearch = (editorAPI: EditorAPI) => {
  searchModule.addIntegration({
    type: Integrations.LEFT_PANELS,
    transform: (result) => {
      const {
        extras: { panelID },
      } = result;

      return {
        ...result,
        onEnter: () => {
          editorAPI.panelManager.openPanel(panelID, {});
        },
        onLeave: () => {
          editorAPI.panelManager.closePanelByName(panelID);
        },
      };
    },
  });
};

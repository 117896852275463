import { searchModule, Integrations } from '#packages/editorSearch';
import constants from '#packages/constants';

import {
  getSiteMenuAPI,
  type SiteMenuAPIDispatchProps,
  type SiteMenuAPIStateProps,
  type SiteMenuAPIOwnProps,
} from '../menuBar/menuBarListItems/siteMenu/siteMenuAPI';
import {
  getSettingsMenuAPI,
  type SettingsMenuAPIDispatchProps,
  type SettingsMenuAPIStateProps,
  type SettingsMenuAPIOwnProps,
} from '../menuBar/menuBarListItems/settingsMenu/settingsMenuAPI';
import {
  getToolsMenuAPI,
  type ToolsMenuAPIDispatchProps,
  type ToolsMenuAPIStateProps,
  type ToolsMenuAPIOwnProps,
} from '../menuBar/menuBarListItems/toolsMenu/toolsMenuAPI';
import {
  getHelpMenuAPI,
  type HelpMenuAPIDispatchProps,
  type HelpMenuAPIStateProps,
  type HelpMenuAPIOwnProps,
} from '../menuBar/menuBarListItems/helpMenu/helpMenuAPI';
import {
  type CodeMenuAPIDispatchProps,
  type CodeMenuAPIOwnProps,
  type CodeMenuAPIStateProps,
  getCodeMenuAPI,
} from '../menuBar/menuBarListItems/codeMenu/codeMenuAPI';

const {
  MENU_BAR_ITEMS: { SITE, SETTINGS, TOOLS, HELP, CODE },
  SITE_MENU_ITEMS: { PUBLISH },
} = constants.ROOT_COMPS.TOPBAR;

export interface MenusSearchIntegrationStateProps
  extends SiteMenuAPIStateProps,
    SettingsMenuAPIStateProps,
    ToolsMenuAPIStateProps,
    CodeMenuAPIStateProps,
    HelpMenuAPIStateProps {}

export interface MenusSearchIntegrationDispatchProps
  extends SiteMenuAPIDispatchProps,
    SettingsMenuAPIDispatchProps,
    ToolsMenuAPIDispatchProps,
    CodeMenuAPIDispatchProps,
    HelpMenuAPIDispatchProps {}

export interface MenusSearchIntegrationOwnProps
  extends SiteMenuAPIOwnProps,
    SettingsMenuAPIOwnProps,
    ToolsMenuAPIOwnProps,
    CodeMenuAPIOwnProps,
    HelpMenuAPIOwnProps {}

export interface MenusSearchIntegrationProps
  extends MenusSearchIntegrationStateProps,
    MenusSearchIntegrationDispatchProps,
    MenusSearchIntegrationOwnProps {}

const createMenusAPI = (props: MenusSearchIntegrationProps) => {
  const siteMenuAPI = getSiteMenuAPI(props);

  return {
    [SITE]: {
      ...siteMenuAPI,
      [PUBLISH]: () => {
        props.openPanel('panels.focusPanels.confirmPublishPanel');
      },
    },
    [SETTINGS]: getSettingsMenuAPI(props),
    [TOOLS]: getToolsMenuAPI(props),
    [HELP]: getHelpMenuAPI(props),
    [CODE]: getCodeMenuAPI(props),
  };
};

export const integrateMenus = (props: MenusSearchIntegrationProps) => {
  let menusAPI = createMenusAPI(props);

  searchModule.addIntegration({
    type: Integrations.TOP_BAR_MENU,
    transform: (result) => {
      const {
        extras: { menuID, itemID },
      } = result;

      const itemAction = (menusAPI as AnyFixMe)[menuID][itemID];

      return {
        ...result,
        onSubmit: itemAction,
      };
    },
  });

  const updateIntegration = (props: MenusSearchIntegrationProps) => {
    menusAPI = createMenusAPI(props);
  };

  return {
    update: updateIntegration,
  };
};

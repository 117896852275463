import React, { type FC } from 'react';
import { cx, hoc, sections } from '#packages/util';
import { ReadonlyModeApiKey } from '#packages/apis';
import { inlinePopup, interactions } from '#packages/stateManagement';
import { Rulers } from '#packages/rulers';
import { Actions } from './Actions/Actions';
import {
  LandingPageIndicationBottom,
  LandingPageIndicationTop,
} from './LandingPageIndication/LandingPageIndication';
import { StageSideAreaBorder } from '../StageSideAreaBorder/StageSideAreaBorder';

import {
  mapDispatchToProps,
  mapStateToProps,
  type StageSideAreaDispatchProps,
  type StageSideAreaStateProps,
} from './StageSideAreaMapper';
import { AUTOMATION_IDS } from './constants';
import styles from './StageSideArea.scss';

export interface StageSideAreaOwnProps {}

interface StageSideAreaProps
  extends StageSideAreaOwnProps,
    StageSideAreaStateProps,
    StageSideAreaDispatchProps {}

export interface StageSideArea {
  left: number;
  width: number;
  sectionSegmentWidth: number;
  height: number;
}

const StageSideAreaComponent: FC<StageSideAreaProps> = ({
  sectionsLikeWithLayout,
  areas,
  setHoveredSection,
  selectComponentIfNeeded,
  hoveredSectionLikeRef,
  openRightClickMenu,
  isStageZoomMode,
  isMobileEditor,
  showHorizontalRuler,
  shouldRenderStageSideAreaBorder,
  hasOpenCompPanels,
}) => (
  <>
    {areas.map((area, areaIndex) => {
      const isRightArea = areaIndex === areas.length - 1;

      return (
        <div
          data-hook={`stage-side-area-container-${
            isRightArea ? 'right' : 'left'
          }`}
          className={styles.container}
          style={{
            left: area.left,
            width: area.width,
            height: area.height,
            //to create a new stacking context for container
            transform: hasOpenCompPanels ? 'translateX(0)' : 'none',
          }}
          key={area.left}
        >
          {isRightArea && showHorizontalRuler && (
            <Rulers rulerDirection="horizontal" />
          )}

          {sectionsLikeWithLayout.map((sectionLike) => {
            const border = (
              <StageSideAreaBorder
                position={isRightArea ? 'right' : 'left'}
                isMobileEditor={isMobileEditor}
                isZoomOut={isStageZoomMode}
              />
            );
            const isSectionHovered =
              sectionLike.ref.id === hoveredSectionLikeRef?.id;

            return (
              <div
                key={sectionLike.ref.id}
                className={cx(styles.stageSideArea, {
                  [styles.highlighted]:
                    !isStageZoomMode &&
                    sectionLike.ref.id === hoveredSectionLikeRef?.id,
                  [styles.left]: !isRightArea,
                })}
                style={{
                  top: sectionLike.layout.y,
                  height: sectionLike.layout.height,
                  width: area.sectionSegmentWidth,
                  justifyContent: shouldRenderStageSideAreaBorder
                    ? 'space-between'
                    : 'center',
                }}
                data-hook={AUTOMATION_IDS.STAGE_SIDE_AREA}
                data-comp-id={sectionLike.ref.id}
                onMouseMove={(e) => {
                  e.stopPropagation();
                }}
                onMouseEnter={() => setHoveredSection(sectionLike.ref)}
                onMouseLeave={() => setHoveredSection(null)}
                onClick={() =>
                  selectComponentIfNeeded(sectionLike.ref, 'off_stage_area')
                }
                onContextMenu={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openRightClickMenu(e, sectionLike.ref);
                }}
              >
                {isSectionHovered && (
                  <>
                    {shouldRenderStageSideAreaBorder && border}
                    {isRightArea && <Actions sectionLike={sectionLike} />}
                    {shouldRenderStageSideAreaBorder && border}
                  </>
                )}
              </div>
            );
          })}
          {isRightArea && <LandingPageIndicationTop />}
          {isRightArea && <LandingPageIndicationBottom />}
        </div>
      );
    })}
  </>
);

const ConnectedStageSideArea = hoc.connect(
  hoc.STORES.MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(StageSideAreaComponent);

const StageSideArea = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  ({ state, editorAPI }) => {
    return (
      state.editorIsInit &&
      !editorAPI.host.getAPI(ReadonlyModeApiKey).isReadonlyModeEnabled() &&
      sections.isSectionsEnabled() &&
      !editorAPI.dsRead?.pages.popupPages.isPopupOpened() &&
      !inlinePopup.selectors.isInlinePopupOpened(state) &&
      !editorAPI.imageCrop.isCropMode() &&
      !interactions.selectors.isInInteractionMode(state)
    );
  },
)(ConnectedStageSideArea);

export const DesktopStageSideArea = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  ({ editorAPI }) => editorAPI.isDesktopEditor(),
)(StageSideArea);

export const MobileStageSideArea = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  ({ editorAPI }) => editorAPI.isMobileEditor(),
)(StageSideArea);

// @ts-nocheck
import React from 'react';
import * as util from '#packages/util';
import * as leftBar from '#packages/leftBar';
import * as panels from '#packages/panels';
import * as core from '#packages/core';
import * as stateManagement from '#packages/stateManagement';
import { translate } from '#packages/i18n';

import { Background as BackgroundView } from '#packages/background';
import createReactClass from 'create-react-class';

const isNewWorkspaceEnabled = util.workspace.isNewWorkspaceEnabled();

const { leftBar: leftBarStore } = stateManagement;
const { getDeviceType } = leftBarStore.selectors;

const TRANSLATION = {
  TITLE: {
    mobile: 'BGPP_PANEL_HEADER_MOBILE_TITLE',
    desktop: 'BGPP_PANEL_HEADER_DSKTOP_TITLE',
  },
};

// eslint-disable-next-line react/prefer-es6-class
const BackgroundPanelComponent = createReactClass({
  displayName: 'backgroundPanel',
  mixins: [core.mixins.editorAPIMixin],

  getTranslation(key) {
    const editorAPI = this.getEditorAPI();
    const state = editorAPI.store.getState();
    const editorType = getDeviceType(state);
    return translate(TRANSLATION[key][editorType]);
  },

  render() {
    const isMobile = this.getEditorAPI().isMobileEditor();
    const backgroundProps = { isBackgroundPanel: true };

    const LeftPanelFrame = isNewWorkspaceEnabled
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    return (
      <LeftPanelFrame
        panelName={this.props.panelName}
        label={this.getTranslation('TITLE')}
        key="leftPanelFrame"
        ref="leftPanelFrame"
        panelClass="bg-panel"
        helpId={
          isMobile
            ? 'f0d8190e-e2da-48be-9f44-7ecff4518a82'
            : 'f11a3bb9-cff0-46d4-92ff-cc8cd9d30468'
        }
        className="bg-panel"
      >
        <BackgroundView {...backgroundProps} />
      </LeftPanelFrame>
    );
  },
});

const BackgroundPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
)(BackgroundPanelComponent);
BackgroundPanel.pure = BackgroundPanelComponent;

export default BackgroundPanel;

import _ from 'lodash';
import { SectionsApiKey } from '#packages/apis';

import constants from '#packages/constants';
import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { editorWixRecorder, fedopsLogger, sections } from '#packages/util';
import { layoutUtils } from '#packages/layoutUtils';
import { adaptAddingComponentStructure } from './adaptAddingComponentStructure';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  CompRef,
  CompStructure,
  PopupContainerProperties,
} from 'types/documentServices';
import type { EditorInteractionName } from 'types/fedops';

const {
  getVariantId,
  isInInteractionMode,
  getInteractionTriggerRef,
  getInteractionContainerToAddTo,
} = stateManagement.interactions.selectors;
const {
  shouldContainerGrowToFitContent,
  getContainerToFitContent,
  getCompPastePositionToFitContainer,
} = stateManagement.copyPaste.selectors;
const { getPreviewPosition, getViewPort } =
  stateManagement.domMeasurements.selectors;
const { showCompAddedInMobileNotificationIfNeeded } =
  stateManagement.mobile.actions;
const { showUserActionNotification } = stateManagement.notifications.actions;

const ADDING_METHOD_ORIGIN_MAP: AnyFixMe = {
  paste_file: 'paste',
};

const getComponentsInTheCenterOfPreview = (editorAPI: EditorAPI) => {
  const previewDimensions = getPreviewPosition(editorAPI.store.getState());

  const previewCenter = {
    x: previewDimensions.width / 2,
    y: previewDimensions.height / 2,
  };

  return editorAPI.selection.getComponentsByXY(
    previewCenter.x,
    previewCenter.y,
  );
};

const isAllowedToAddIntoSection = (
  compDef: CompStructure,
  positionOverride: { x: number; y: number },
): boolean =>
  sections.isSectionsEnabled() &&
  !positionOverride &&
  !sections.isRestrictedToAddIntoSectionByCompType(compDef.componentType);

function scrollToAddedComponentIfNeeded(
  editorAPI: EditorAPI,
  compRef: CompRef,
  animationDurationSec: number = 0,
): void {
  const componentsInTheCenter = getComponentsInTheCenterOfPreview(editorAPI);

  const isCompOverCenterPreview = componentsInTheCenter.find(
    (compPointerUnderPoint) => compPointerUnderPoint.id === compRef.id,
  );

  if (!isCompOverCenterPreview) {
    const previewDimensions = getPreviewPosition(editorAPI.store.getState());
    const previewCenterY = previewDimensions.height / 2;

    const compLayoutRelativeToScreen =
      editorAPI.components.layout.getRelativeToScreen(compRef);
    const compCenterYRelativeToScreen =
      compLayoutRelativeToScreen.y + compLayoutRelativeToScreen.height / 2;

    editorAPI.ui.scroll.animateScroll(
      { y: compCenterYRelativeToScreen - previewCenterY },
      animationDurationSec,
    );
  }
}

function isStripStructure(editorAPI: AnyFixMe, structure: AnyFixMe) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(
      constants.ADD_PANEL.SECTION_COMP_TYPES,
      structure.componentType,
    ) && editorAPI.components.is.fullWidthByStructure(structure)
  );
}

function calcCompPastePosition(
  editorAPI: EditorAPI,
  compDef: CompStructure,
  id: string,
  interactionContainerRef: CompRef,
  position?: { x: number; y: number },
) {
  const containerToAddTo = getContainerToAddTo(
    editorAPI,
    compDef,
    interactionContainerRef,
    position,
  );

  if (
    sections.isSectionsEnabled() &&
    isStretchedComp(editorAPI, compDef) &&
    editorAPI.sections.isSection(containerToAddTo)
  ) {
    return layoutUtils.getPositionInsideParentCloseToViewportCenter(
      editorAPI,
      getViewPort(editorAPI.store.getState(), true),
      containerToAddTo,
      compDef,
    );
  }

  const pastePosition =
    editorAPI.pasteLogic.addPanelPasteLogic.getPastePosition(
      editorAPI,
      compDef.layout,
      containerToAddTo.id,
      id,
      [compDef],
    );

  if (isStretchedComp(editorAPI, compDef) && !sections.isSectionsEnabled()) {
    pastePosition.y = editorAPI.dsRead.pages.popupPages.isPopupOpened()
      ? getStripYForAddingInPopup(editorAPI, compDef, 30)
      : getStripYForAdding(editorAPI, compDef, 30);

    editorAPI.setSiteScale({
      requestedScale: 1,
      scrollY: pastePosition.y,
    });
  }

  return pastePosition;
}

function isStretchedComp(editorAPI: AnyFixMe, compDef: CompStructure) {
  const isHorizontallyStretchedToScreen =
    editorAPI.components.layout.isHorizontallyStretchedToScreenByStructure(
      compDef,
    );
  return (
    isStripStructure(editorAPI, compDef) || isHorizontallyStretchedToScreen
  );
}

function getYMostPositionFor(
  topOrBottom: AnyFixMe,
  editorAPI: EditorAPI,
  containerPointer: AnyFixMe,
  offset: AnyFixMe,
) {
  const compPointers =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
      containerPointer,
    );
  let isYMostStrip = true;
  topOrBottom = topOrBottom || 'bottom';
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const mostY = _.reduce(
    compPointers,
    function (prevMostY, compPointer) {
      const layout = editorAPI.components.layout.get_rect(compPointer);
      const curMostY =
        topOrBottom === 'bottom' ? layout.y + layout.height : layout.y;
      if (
        (topOrBottom === 'bottom' && curMostY > prevMostY) ||
        (topOrBottom === 'top' && curMostY < prevMostY)
      ) {
        isYMostStrip = editorAPI.components.is.fullWidth(compPointer);
        return curMostY;
      }
      return prevMostY;
    },
    0,
  );

  return mostY + (isYMostStrip ? 0 : offset);
}

function getStripYForAdding(
  editorAPI: AnyFixMe,
  compDef: CompStructure,
  offset: AnyFixMe,
) {
  return getYMostPositionFor(
    'bottom',
    editorAPI,
    editorAPI.pages.getFocusedPage(),
    offset,
  );
}

function getStripYForAddingInPopup(
  editorAPI: AnyFixMe,
  compDef: AnyFixMe,
  offset: AnyFixMe,
) {
  let topMost;

  const popupContainer = editorAPI.pages.popupPages.getPopupContainer();
  const popupContainerProps: PopupContainerProperties =
    editorAPI.components.properties.get(popupContainer);
  switch (popupContainerProps.verticalAlignment) {
    case 'top':
    case 'center':
      return getYMostPositionFor('bottom', editorAPI, popupContainer, offset);
    case 'bottom':
      topMost = getYMostPositionFor('top', editorAPI, popupContainer, -offset);
      return topMost - compDef.layout.height;
  }
}

function getContainerToAddTo(
  editorAPI: EditorAPI,
  compDef: CompStructure,
  interactionTriggerRef: CompRef,
  positionOverride?: { x: number; y: number },
) {
  if (interactionTriggerRef) {
    return getInteractionContainerToAddTo(editorAPI, interactionTriggerRef);
  }
  if (editorAPI.componentFocusMode.isEnabled()) {
    return editorAPI.componentFocusMode.getCompRefToAttachChildren();
  }
  const spotlightStageContainer = editorAPI.spotlightStage.getContainer();

  if (spotlightStageContainer) {
    const currComps = [spotlightStageContainer];
    while (!_.isEmpty(currComps)) {
      const comp = currComps.shift();
      if (editorAPI.components.is.containableByStructure(compDef, comp)) {
        return comp;
      }
      currComps.push(
        ...editorAPI.components
          .getChildren(comp)
          .filter((c) => editorAPI.components.is.container(c)),
      );
    }
  }

  const isStretchedCompStructure = isStretchedComp(editorAPI, compDef);

  if (isStretchedCompStructure) {
    if (editorAPI.dsRead.pages.popupPages.isPopupOpened()) {
      return editorAPI.pages.popupPages.getPopupContainer();
    }
  }

  const addPanelAddByClickPlugins = editorAPI.pluginService.getAllPluginsOfType(
    editorAPI.pluginService.pluginConstants.ADD_PANEL_ADD_BY_CLICK_CONTAINER,
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const pluginThatCanBeApplied = _.find(addPanelAddByClickPlugins, (plugin) =>
    _.invoke(plugin, 'condition', editorAPI),
  );

  if (pluginThatCanBeApplied) {
    return pluginThatCanBeApplied.getOverrides(editorAPI);
  }

  if (isAllowedToAddIntoSection(compDef, positionOverride)) {
    const section = sections.getSectionLikeContainerToPaste(editorAPI, [
      compDef,
    ]);

    if (section) {
      return section;
    }
  }

  return editorAPI.pages.getPrimaryContainer();
}

export function shouldShowAddToEmptyMobilePageWarnNotification(
  editorAPI: EditorAPI,
  dropContainer?: CompRef,
): boolean {
  if (!editorAPI.isMobileEditor() || !sections.isSectionsEnabled()) {
    return false;
  }
  if (dropContainer && !editorAPI.sections.isSectionLike(dropContainer)) {
    return false;
  }

  const mobilePageSections = editorAPI.sections.getPageSections(
    editorAPI.pages.getFocusedPage(),
  );
  return mobilePageSections.length === 0;
}

export function showAddToEmptyMobilePageWarnNotification(editorAPI: EditorAPI) {
  editorAPI.panelManager.closeAllPanels();
  editorAPI.store.dispatch(
    showUserActionNotification({
      message: 'section_hidden_mobile_notification_body',
      title: 'section_hidden_mobile_notification_body',
      type: constants.NOTIFICATIONS.TYPES.WARNING,
      link: {
        caption: 'section_hidden_mobile_notification_cta',
        onNotificationLinkClick: () => {
          editorAPI.panelManager.openPanel(
            constants.ROOT_COMPS.LEFTBAR.HIDDEN_ELEMENTS_PANEL_NAME,
          );
        },
      },
    }),
  );
}

function appendAdditionalInteractionNames(
  interactionNames: EditorInteractionName[],
  compStructure: AnyFixMe,
) {
  const ret = interactionNames.slice();

  if (compStructure.componentType === 'wixui.StylableButton') {
    ret.push(
      fedopsLogger.INTERACTIONS.STYLABLE.ADD_STYLABLE_BUTTON_FROM_ADD_PANEL,
    );
  } else if (
    compStructure.componentType === 'wysiwyg.viewer.components.SiteButton'
  ) {
    ret.push(fedopsLogger.INTERACTIONS.ADD_SITE_BUTTON_FROM_ADD_PANEL);
  }

  return ret;
}

function attemptToAddComponent(
  editorAPI: EditorAPI,
  componentStructure: CompStructure,
  categoryId?: string,
  sectionTitle?: string,
  sectionTags?: AnyFixMe,
  itemId?: string,
  origin?: string,
  appDefinitionId?: string,
  {
    position,
    skipHistorySnapshot,
    dropContainer,
  }: {
    position?: { x: number; y: number };
    skipHistorySnapshot?: boolean;
    dropContainer?: CompRef;
  } = {},
  addingMethod: string = 'click',
) {
  const isSectionsEnabled = sections.isSectionsEnabled();
  const state = editorAPI.store.getState();
  const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
  const addCompUnderInteraction = isInInteractionMode(state);
  const interactionTriggerRef =
    addCompUnderInteraction && getInteractionTriggerRef(state);
  const compDef = adaptAddingComponentStructure(editorAPI, componentStructure);

  const containerToAddTo =
    dropContainer ||
    getContainerToAddTo(editorAPI, compDef, interactionTriggerRef, position);
  const isSectionLikeContainer =
    isSectionsEnabled && editorAPI.sections.isSectionLike(containerToAddTo);

  if (
    shouldShowAddToEmptyMobilePageWarnNotification(editorAPI, containerToAddTo)
  ) {
    showAddToEmptyMobilePageWarnNotification(editorAPI);
  }

  if (isSectionLikeContainer) {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.COMP_ATTACHED_TO_SECTION,
    );
  }

  if (!editorAPI.components.is.allowedToContainMoreChildren(containerToAddTo)) {
    return;
  }

  if (
    !editorAPI.components.is.containableByStructure(compDef, containerToAddTo)
  ) {
    editorAPI.store.dispatch(
      stateManagement.panels.actions.closePanelByName(
        constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
      ),
    );
    editorAPI.store.dispatch(
      stateManagement.addPanel.actions.showCannotAddComponentNotification(
        componentStructure,
        containerToAddTo,
      ),
    );
    return;
  }

  if (shouldContainerGrowToFitContent(editorAPI, containerToAddTo, compDef)) {
    const containerToFitContent = getContainerToFitContent(
      editorAPI,
      containerToAddTo,
    );
    const containerLayout = editorAPI.components.layout.get_rect(
      containerToFitContent,
    );
    const containerLayoutToFitToContent =
      layoutUtils.getContainerLayoutToFitContent(
        containerLayout,
        // @ts-expect-error
        compDef.layout,
      );

    const pastePosition =
      sections.getPositionForFWEPlacementInSection(
        editorAPI,
        containerToAddTo,
        [compDef],
      ) ||
      getCompPastePositionToFitContainer(
        editorAPI.store.getState(),
        containerLayout,
        compDef.layout,
      );

    if (sections.isSectionsEnabled()) {
      const lastFWEChild = sections.getLastFWEChildInContainer(
        editorAPI,
        containerToFitContent,
      );

      if (lastFWEChild) {
        containerLayoutToFitToContent.height += compDef.layout.height;
      }

      editorAPI.components.layout.updateAndAdjustLayout(
        containerToFitContent,
        containerLayoutToFitToContent,
        true,
      );
    } else {
      editorAPI.components.layout.update(
        containerToFitContent,
        containerLayoutToFitToContent,
      );
    }
    _.merge(compDef.layout, pastePosition);
  } else {
    const pastePosition =
      position ||
      calcCompPastePosition(
        editorAPI,
        compDef,
        categoryId,
        containerToAddTo,
        position,
      );
    _.merge(compDef.layout, pastePosition);
  }

  return new Promise<CompRef>((resolve) =>
    editorAPI.components.add(
      containerToAddTo,
      compDef,
      undefined,
      function (compRef) {
        function addComponentCallback() {
          if (!isStageZoomMode) {
            editorAPI.selection.selectComponentByCompRef(compRef);
          }

          if (isSectionLikeContainer) {
            editorAPI.setAttachCandidate(
              { comp: containerToAddTo, useAnimation: true },
              true,
            );

            editorAPI.host
              .getAPI(SectionsApiKey)
              .hooks.compAttachedToSection.fire({ compRef });
          }

          editorAPI.openFirstTimeOrDeprecationPanel(compRef);
          if (compDef.type !== 'Page' && !skipHistorySnapshot) {
            editorAPI.history.add('added component', {
              isAddingComponent: true,
            });
          }

          if (addCompUnderInteraction) {
            editorAPI.bi.event(
              coreBi.events.interactions.interaction_mode_add_component,
              {
                component_id: compRef.id,
                interaction_id: getVariantId(state),
                component_type: compDef.componentType,
                parent_component_id: interactionTriggerRef.id,
                parent_component_type: editorAPI.components.getType(
                  interactionTriggerRef,
                ),
              },
            );
          }

          const component_type =
            compDef.componentTypeForBI || compDef.componentType;
          const containerType = editorAPI.components.getType(containerToAddTo);

          editorWixRecorder.addLabel(`${component_type} added to stage`);

          editorAPI.bi.event(
            coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE,
            Object.assign(
              {
                ...editorAPI.bi.getComponentsBIParams([compRef])[0],
                origin: origin || 'add_panel',
                category: categoryId,
                section: sectionTitle,
                component_type,
                component_id: compRef.id,
                // removing trailing underscore with digits: blabla_123 => blabla
                preset_id: (itemId || '').replace(/_\d+$/, ''),
                adding_method: ADDING_METHOD_ORIGIN_MAP[origin] || addingMethod,
                preset_data_skin:
                  (compDef.style as AnyFixMe)?.skin ||
                  compDef.skin ||
                  compDef.style,
                preset_data_tags: sectionTags,
                page_id:
                  editorAPI.pages.getFocusedPage() &&
                  editorAPI.pages.getFocusedPage().id,
                target_component: containerType,
                target_component_id: containerToAddTo?.id,
                target_component_type:
                  editorAPI.componentFocusMode.getMenuType() ?? containerType,
              },
              core.utils.addPanelBiUtils(compDef.props),
              appDefinitionId ? { app_id: appDefinitionId } : {},
            ),
          );

          if (editorAPI.isMobileEditor()) {
            editorAPI.store.dispatch(
              showCompAddedInMobileNotificationIfNeeded(),
            );
            scrollToAddedComponentIfNeeded(editorAPI, compRef);
          }
          fedopsLogger.interactionEnded(
            appendAdditionalInteractionNames(
              [fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL],
              compDef,
            ),
            {
              paramsOverrides: {
                component_type: compDef.componentType,
                method: 'click',
              },
            },
          );
          ErrorReporter.breadcrumb('added component from add panel', {
            method: 'click',
            appDefId: appDefinitionId,
            compRef,
            parentComponent: containerToAddTo,
          });

          editorAPI.pasteLogic.addPanelPasteLogic.setLastAddedComponent(
            compRef,
          );
          resolve(compRef);
        }

        if (addCompUnderInteraction) {
          core.utils.addUtil.addComponentUnderInteractionCallback(
            editorAPI,
            compRef,
            containerToAddTo,
            state,
          );
          editorAPI.waitForChangesApplied(addComponentCallback);
          return;
        }
        addComponentCallback();
      },
      'add_panel',
      null,
      null,
      'preset',
    ),
  );
}

export {
  isStripStructure,
  isStretchedComp,
  getContainerToAddTo,
  calcCompPastePosition,
  attemptToAddComponent,
  appendAdditionalInteractionNames,
  scrollToAddedComponentIfNeeded,
  getComponentsInTheCenterOfPreview,
};

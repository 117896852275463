import React, { type FC, useEffect, useCallback } from 'react';
import { Text, TextButton, Button, Tooltip } from '@wix/wix-base-ui';
import {
  ConfirmSmall,
  MinusSmall,
  CircleSmallFilledSmall,
} from '@wix/wix-ui-icons-common';
import { useTranslation } from 'react-i18next';
import { cx } from '#packages/util';
import {
  getBusinessAppSetupStepDataHook,
  AUTOMATION_IDS,
} from '../../automationIds';
import { BI_EVENTS_IDS } from '../../biEvents';
import { usePrevious } from '../../hooks/usePrevious';
import styles from './AppSetupStep.scss';

import type { AppSetupStep as AppSetupStepModel } from '../../services/setup/types';
import type { BiEventFields } from 'types/bi';

export interface AppSetupStepProps {
  step: AppSetupStepModel;
  isPriority: boolean;
  order: number;
  sendBi: (evid: number, biParams: BiEventFields) => void;
}

export const AppSetupStep: FC<AppSetupStepProps> = ({
  step,
  isPriority,
  order,
  sendBi,
}) => {
  const reportStepActionBiEvent = useCallback(
    (actionName: 'main action' | 'skip' | 'undo') => {
      sendBi(BI_EVENTS_IDS.SETUP_STEP_ACTION_CLICKED, {
        app_id: step.appDefId,
        target: actionName,
        targetName: step.title,
        assetCampaignGuid: step.metadata.campaignId,
        Asset_location_from_group_list: order,
      });
    },
    [sendBi, step, order],
  );

  const onMainAction = useCallback(() => {
    step.action.execute();
    reportStepActionBiEvent('main action');
  }, [reportStepActionBiEvent, step]);

  const onSkip = useCallback(() => {
    step.skip();
    reportStepActionBiEvent('skip');
  }, [reportStepActionBiEvent, step]);

  const onUnskip = useCallback(() => {
    step.unskip();
    reportStepActionBiEvent('undo');
  }, [reportStepActionBiEvent, step]);

  const renderContent = () => {
    switch (step.status) {
      case 'todo':
        return (
          <>
            <TodoStepIcon isDimmed={!step.isPermitted} />
            <StepTitle title={step.title} isDimmed={false} />
            <TodoStepActions
              step={step}
              isPriority={isPriority}
              onMainAction={onMainAction}
              onSkip={onSkip}
            />
          </>
        );
      case 'skipped':
        return (
          <>
            <SkippedStepIcon isDimmed={!step.isPermitted} />
            <StepTitle title={step.title} isDimmed />
            <SkippedStepActions step={step} onUnskip={onUnskip} />
          </>
        );
      case 'completed':
        return (
          <>
            <CompletedStepIcon isDimmed={!step.isPermitted} />
            <StepTitle title={step.title} isDimmed isCrossed />
          </>
        );
      default:
        throw new Error(`Unexpected setup step status "${step.status}"`);
    }
  };

  const prevStatus = usePrevious(step.status);
  useEffect(() => {
    if (step.status === 'completed' && step.status !== prevStatus) {
      sendBi(BI_EVENTS_IDS.SETUP_STEP_COMPLETED, {
        app_id: step.appDefId,
        target: 'main action',
        targetName: step.title,
      });
    }
  }, [sendBi, step, prevStatus]);

  useEffect(() => {
    sendBi(BI_EVENTS_IDS.SETUP_STEP_LOADED, {
      app_id: step.appDefId,
      targetName: step.title,
      assetCampaignGuid: step.metadata.campaignId,
      Asset_location_from_group_list: order,
    });
  }, [sendBi, step, order]);

  return (
    <div
      className={styles.container}
      data-hook={getBusinessAppSetupStepDataHook(step.id)}
    >
      {renderContent()}
    </div>
  );
};

const TodoStepIcon: FC<{ isDimmed: boolean }> = ({ isDimmed }) => (
  <CircleSmallFilledSmall
    className={cx(styles.iconTodo, { [styles.iconDimmed]: isDimmed })}
  />
);

const SkippedStepIcon: FC<{ isDimmed: boolean }> = ({ isDimmed }) => (
  <MinusSmall
    className={cx(styles.iconSkipped, { [styles.iconDimmed]: isDimmed })}
  />
);

const CompletedStepIcon: FC<{ isDimmed: boolean }> = ({ isDimmed }) => (
  <ConfirmSmall
    data-hook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_COMPLETED_ICON}
    className={cx(styles.iconCompleted, { [styles.iconDimmed]: isDimmed })}
  />
);

const StepTitle: FC<{
  title: string;
  isDimmed: boolean;
  isCrossed?: boolean;
}> = ({ title, isDimmed, isCrossed }) => {
  const [t] = useTranslation();

  return (
    <div className={cx(styles.title, { [styles.titleCrossed]: !!isCrossed })}>
      <Text
        dataHook="setup-step-title-text"
        customEllipsisProps={{ maxLines: 2 }}
        size="small"
        skin={isDimmed ? 'placeholder' : 'standard'}
        weight="normal"
        shouldTranslate={false}
      >
        {t(title)}
      </Text>
    </div>
  );
};

const SkippedStepActions: FC<{
  step: AppSetupStepModel;
  onUnskip: () => void;
}> = ({ step, onUnskip }) => {
  const [t] = useTranslation();
  return (
    <div className={styles.actions}>
      <Text
        size="tiny"
        skin="placeholder"
        weight="normal"
        shouldTranslate={false}
      >
        {t('App_Manager_Ecomm_Segment_Skipped_Text')}
      </Text>
      <Tooltip
        disabled={step.isPermitted}
        shouldTranslate={false}
        content={t(
          'App_Manager_Ecomm_Segment_Tooltip_Item_Disabled_User_Permission_Text',
        )}
      >
        <TextButton
          size="tiny"
          skin="standard"
          weight="normal"
          shouldTranslate={false}
          onClick={onUnskip}
          disabled={!step.isPermitted}
          dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_UNSKIP}
        >
          {t('App_Manager_Ecomm_Segment_Undo_Button')}
        </TextButton>
      </Tooltip>
    </div>
  );
};

const TodoStepActions: FC<{
  step: AppSetupStepModel;
  isPriority: boolean;
  onMainAction: () => void;
  onSkip: () => void;
}> = ({ step, isPriority, onMainAction, onSkip }) => {
  const [t] = useTranslation();
  const ctaClassNames = [styles.actionButton, 'btn-sm'];
  if (!isPriority && step.isPermitted) {
    ctaClassNames.push('btn-inverted');
  }
  const canSkip = !step.isMandatory && step.isPermitted;
  return (
    <div className={styles.actions}>
      {canSkip ? (
        <TextButton
          size="tiny"
          shouldTranslate={false}
          onClick={onSkip}
          dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_SKIP}
        >
          {t('App_Manager_Ecomm_Segment_Skip_Button')}
        </TextButton>
      ) : null}
      <Tooltip
        disabled={step.isPermitted}
        shouldTranslate={false}
        content={t(
          'App_Manager_Ecomm_Segment_Tooltip_Item_Disabled_User_Permission_Text',
        )}
      >
        <Button
          className={cx(...ctaClassNames)}
          shouldTranslate={false}
          onClick={onMainAction}
          disabled={!step.isPermitted}
          dataHook={AUTOMATION_IDS.MY_BUSINESS.APP_SETUP.STEP_MAIN_ACTION}
        >
          {t(step.action.label)}
        </Button>
      </Tooltip>
    </div>
  );
};

// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

const { openHelpCenter, closePanelByNameAction } =
  stateManagement.panels.actions;

//TYPE WAS GENERATED, remove this line when reviewed
interface ConfirmResetAndCloseProps {
  onConfirm: FunctionFixMe;
  openHelpCenter: FunctionFixMe;
}

class ConfirmResetAndClose extends React.Component<ConfirmResetAndCloseProps> {
  static displayName = 'confirmResetAndClose';

  static propTypes = {
    onConfirm: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
  };

  getDescription1 = () => {
    return translate('PLATFORM_Popup_open_close_reset_and_lock_description1');
  };

  getDescription2 = () => {
    return translate('PLATFORM_Popup_open_close_reset_and_lock_description2');
  };

  getLearnMoreLabel = () => {
    return translate('PLATFORM_Popup_open_close_reset_and_lock_learn_more');
  };

  onClose = () => {
    this.props.closePanel();
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  onConfirm = () => {
    this.props.onConfirm();
    this.onClose();
  };

  render() {
    return (
      <FocusPanelFrame
        shouldHideHeader={true}
        automationId="confirmResetAndClose"
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
      >
        <Composites.PopupSmallSize>
          <PanelHeader
            onHelp={this.props.openHelpCenter}
            onClose={this.onClose}
            className="dark"
          >
            <TextLabel
              type="T11"
              value="PLATFORM_Popup_open_close_reset_and_lock_title"
            />
          </PanelHeader>

          <Composites.PanelContent>
            <Composites.RichTextWithIllustration>
              <Illustration>
                <symbols.symbol name="confirmClosingWidgetIllustration" />
              </Illustration>

              <Composites.RichText>
                <RichText type="T01">
                  {this.getDescription1()}
                  <br />
                  <br />
                  {this.getDescription2()}
                  <br />
                  <a onClick={this.props.openHelpCenter}>
                    {this.getLearnMoreLabel()}
                  </a>
                </RichText>
              </Composites.RichText>
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>

          <Composites.ActionSetHorizontal>
            <Button
              automationId="confirmCloseCancel"
              onClick={this.onClose}
              className="secondary"
            >
              <TextLabel value="PLATFORM_Popup_open_close_reset_and_lock_cancel" />
            </Button>
            <Button
              automationId="confirmCloseConfirm"
              onClick={this.onConfirm}
              className="primary"
            >
              <TextLabel value="PLATFORM_Popup_open_close_reset_and_lock_reset_button" />
            </Button>
          </Composites.ActionSetHorizontal>
        </Composites.PopupSmallSize>
      </FocusPanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch, { panelName }) => ({
  closePanel: () => dispatch(closePanelByNameAction(panelName)),
  openHelpCenter: () =>
    dispatch(openHelpCenter('e1de2826-3df0-4a7f-a4e9-1aa538f34224')),
});

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  undefined,
  mapDispatchToProps,
)(ConfirmResetAndClose);

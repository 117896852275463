import React, { type FunctionComponent, type ReactElement } from 'react';
import { TextLabel } from '@wix/wix-base-ui';

import constants from '#packages/constants';
import { workspace } from '#packages/util';

import withCustomDropPanelBehavior from '../hocs/withCustomDropPanelBehavior';

import Button from '../topBarButton/topBarButtonWithTooltip';

const { BUTTONS } = constants.ROOT_COMPS.TOPBAR;

interface RedoButtonProps {
  tooltip?: ReactElement;
  disabled?: boolean;
  onClick: () => void;
  onMouseLeave?: () => void;
  onMouseEnter?: () => void;
}

const RedoButton: FunctionComponent<RedoButtonProps> = (props) => {
  const symbolName = workspace.isNewWorkspaceEnabled()
    ? 'redo_NewWorkspace'
    : 'redo2_v2';

  return (
    <Button
      className="top-bar-button-redo"
      automationId="top-bar-button-redo"
      symbolName={symbolName}
      disabled={props.disabled}
      tooltip={props.tooltip}
      onClick={props.onClick}
      onMouseLeave={props.onMouseLeave}
      onMouseEnter={props.onMouseEnter}
    />
  );
};

RedoButton.defaultProps = {
  tooltip: (
    <TextLabel value="TOPBAR_REDO_TOOLTIP_TITLE" enableEllipsis={false} />
  ),
};

const ConnectedRedoButton = withCustomDropPanelBehavior(BUTTONS.REDO)(
  RedoButton,
) as React.ComponentType<RedoButtonProps>;

export default ConnectedRedoButton;

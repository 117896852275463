import addPanelDataConsts from '#packages/addPanelDataConsts';
import { languages } from '#packages/util';

import superAppsConstants from '../../superApps/superAppsConstants';
import { getSuperAppsAddPanelStyles } from '../../superApps/superAppsCustomData';
import { addCompOnClick, addCompOnDrop } from '../addPanelAddCompService';
import { openAppMarketTab } from '../../appMarket/services/appMarketTabService';

const appDefinitionId = superAppsConstants.WIXMUSIC.APP_DEF_ID;
const widgetId = superAppsConstants.WIXMUSIC.WIDGET;
const SECTION_INDEX_IN_GROUP = 1;

const registerSection = function (editorAPI: AnyFixMe) {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.MEDIA,
    1,
    SECTION_INDEX_IN_GROUP,
    () => getSection(editorAPI),
  );
};

const biOptions = {
  origin: 'add_panel',
  sectionTitle: 'add_section_label_proplayer',
  category: 'media',
};

const addApp = function (
  compStructure: AnyFixMe,
  sectionTitle: AnyFixMe,
  tags: AnyFixMe,
  id: AnyFixMe,
  editorAPI: AnyFixMe,
  addOptions: AnyFixMe,
) {
  return addCompOnClick(
    editorAPI,
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    id,
    biOptions,
    addOptions,
  );
};

const onDrop = function (
  layoutParams: AnyFixMe,
  compPreset: AnyFixMe,
  parentComponent: AnyFixMe,
  editorAPI: AnyFixMe,
) {
  return addCompOnDrop(
    editorAPI,
    appDefinitionId,
    widgetId,
    layoutParams,
    compPreset,
    parentComponent,
    () => {},
    biOptions,
  );
};

function getSectionImage() {
  return getImageURLPerLangAndVer(
    languages.getLanguageCode(),
    'wixMusicPlayerSection2_',
  );
}

function getImageURLPerLangAndVer(lang: AnyFixMe, fileSeries: AnyFixMe) {
  return `addPanelData/sections/wixMusicPlayerSection_${lang}/${fileSeries}${lang}.png`;
}

function getSectionItems() {
  return [
    {
      id: 'Music_Prop_Player_2',
      appDefinitionId,
      slug: 'wix-music',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 680,
          height: 210,
          x: 14,
          y: 71,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          applicationId: '16',
          tpaData: {
            type: 'TPAData',
            content:
              "{'wmp::paymentsId':'0352442c-26dc-415d-81f8-79d681c7efcf'}",
          },
          widgetId,
        },
        style: {
          type: 'TopLevelStyle',
          style: getSuperAppsAddPanelStyles(appDefinitionId, 'compact'),
          componentClassName: '',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
      },
      preset: {
        rect: { width: 324, height: 160, x: 0, y: 0 },
        label: 'add_preset__WixMusic_Compact',
      },
    },
    {
      id: 'Music_Prop_Player_1',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 350,
          height: 700,
          x: 315,
          y: 18,
          scale: 1,
          rotationInDegrees: 0,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        appDefinitionId,
        slug: 'wix-music',
        data: {
          type: 'TPAWidget',
          applicationId: '16',
          widgetId,
        },
        style: 'tpaw0',
      },
      preset: {
        rect: {
          width: 324,
          height: 470,
          x: 0,
          y: 160,
        },
        label: 'add_preset__WixMusic_Classic',
      },
    },
  ];
}

const getSection = function (editorAPI: AnyFixMe) {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_proplayer',
    subNavigationTitle: 'add_section_label_proplayer',
    presetTitle: 'add_section_label_proplayer',
    tooltipTitle: 'add_section_label_proplayer',
    sectionName: 'wixMusicSection',
    id: 'add_panel_section_label_wix_music',
    automationId: 'add-panel-section-wixMusicSection',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      iconEnabledForComps: {},
    },
    props: {
      onClick: addApp,
      onDrop,
      image: getSectionImage(),
      items: getSectionItems(),
    },
    help: {
      hide: false,
      text: 'add_section_info_text_proplayer',
    },
    teaser: {
      id: 'proplayer_section_teaser',
      hide: false,
      title: 'add_section_teaser_title_proplayer',
      text: 'add_section_teaser_text_proplayer',
      linkText: 'add_category_teaser_link',
      learnMore() {
        const appMarketPanelProps = {
          urlParams: {
            slug: appDefinitionId,
          },
        };
        openAppMarketTab(appMarketPanelProps, editorAPI.panelManager.openPanel);
      },
    },
  };
};

export { registerSection };

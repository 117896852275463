import { events as biEvents } from '#packages/coreBi';
import { bi as biStateManagement } from '#packages/stateManagement';
import { editorWixRecorder, fixedStage, sections } from '#packages/util';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { ZoomOutOwnProps } from './zoomOut';
import { WorkspaceRightPanelApiKey, ZoomModeApiKey } from '#packages/apis';

const EXIT_ZOOM_OUT_ORIGIN = 'exit_zoom_out';
export interface ZoomOutStateProps {
  isZoomOutMode: boolean;
  isSectionsEnabled: boolean;
  siteScale: number;
  isStageShifted: boolean;
}

export const mapStateToProps: MapStateToProps<
  ZoomOutStateProps,
  ZoomOutOwnProps
> = ({ editorAPI }) => ({
  isZoomOutMode: editorAPI.zoomMode.isInZoomMode(),
  isSectionsEnabled: sections.isSectionsEnabled(),
  siteScale: editorAPI.getSiteScale(),
  isStageShifted: editorAPI.zoomMode.isShrinkedStageZoomOutActive(),
});

export interface ZoomOutDispatchProps {
  enterZoomMode: () => void;
  exitZoomMode: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  ZoomOutDispatchProps,
  ZoomOutOwnProps
> = {
  enterZoomMode:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI }) => {
      editorAPI.zoomMode.enterZoomMode({
        biParams: { origin: 'topbar' },
        clickedByUser: true,
      });
      editorWixRecorder.addLabel('zoom out top bar clicked');
      dispatch(
        biStateManagement.actions.event(biEvents.topbar.top_bar_click, {
          category: 'zoomout',
          is_published: editorAPI.dsRead?.generalInfo.isSitePublished(),
          state: fixedStage.getTopBarStateBiParamValue(editorAPI),
        }),
      );
    },
  exitZoomMode:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI }) => {
      const workspaceRightPanelAPI = editorAPI.host.getAPI(
        WorkspaceRightPanelApiKey,
      );
      const zoomModeApi = editorAPI.host.getAPI(ZoomModeApiKey);
      const isStretchShiftingLeftPanelOpened =
        zoomModeApi.isShrinkedStageZoomOutActive();
      if (workspaceRightPanelAPI.isOpen()) {
        workspaceRightPanelAPI.close(EXIT_ZOOM_OUT_ORIGIN);
      }

      editorAPI.zoomMode.exitZoomMode({
        biParams: {
          origin: 'topbar',
          zoom_mode_type: isStretchShiftingLeftPanelOpened ? '75%' : '50%',
        },
      });

      editorWixRecorder.addLabel('zoom out top bar clicked');
      dispatch(
        biStateManagement.actions.event(biEvents.topbar.top_bar_click, {
          category: 'zoomin',
          is_published: editorAPI.dsRead?.generalInfo.isSitePublished(),
          state: fixedStage.getTopBarStateBiParamValue(editorAPI),
        }),
      );
    },
};

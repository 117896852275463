// @ts-nocheck
import _ from 'lodash';
import * as helpIds from '#packages/helpIds';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { waitForAddedCompRef } from '#packages/componentsAddUtils';
import { utils as themeUtils } from '#packages/theme';
import * as stateManagement from '#packages/stateManagement';
import * as serializedComponentPlugins from '../../serializedComponentPlugins';
import collectMediaIds from '../../../utils/copyPaste/collectMediaIds';
import createCopyPasteUtils, {
  PasteActionOrigins,
} from './createCopyPasteUtils';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { WIX_PRO_GALLERY } from '@wix/app-definition-ids';
import type {
  CompStructure,
  CompRef,
  Point,
  CompLayout,
} from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { ComponentsAddResult } from '#packages/components';

const { showCompPastedInMobileNotificationIfNeeded } =
  stateManagement.mobile.actions;

function createPaste(editorAPI: EditorAPI) {
  const copyPasteUtils = createCopyPasteUtils(editorAPI);

  function addComponent(
    componentStructure,
    {
      parentContainerRef,
      overrideParentContainerRef,
      appWidgetAncestor,
      isCrossSite,
    },
  ): ComponentsAddResult {
    if (editorAPI.addComponentPlugins[componentStructure.componentType]) {
      return editorAPI.addComponentPlugins[componentStructure.componentType](
        editorAPI,
        componentStructure,
        {
          parentContainerRef,
          isCrossSite,
        },
      );
    }

    if (editorAPI.addComponentPlugins['*']) {
      editorAPI.addComponentPlugins['*'](
        editorAPI,
        componentStructure,
        appWidgetAncestor,
        overrideParentContainerRef,
      );
    }

    const onCompPasteCallback = (compRef: CompRef) => {
      if (editorAPI.isMobileEditor()) {
        editorAPI.store.dispatch(showCompPastedInMobileNotificationIfNeeded());
      }
      editorAPI.pasteLogic.pasteContext.setLastAddedComponent(compRef);
    };

    return editorAPI.components.add(
      parentContainerRef,
      componentStructure,
      componentStructure.id,
      onCompPasteCallback,
    );
  }

  function getComponentPastePosition({
    component,
    componentData,
    primaryContainerId,
    rightClickPosition,
    groupPastePosition,
    defaultGroupPastePosition,
    pageLayout,
    scroll,
    originAction,
    forcedPasteContainer,
  }: {
    originAction: PasteActionOrigins;
    forcedPasteContainer?: CompRef;
  }) {
    const { originalPasteContainer, overridePasteContainer } =
      copyPasteUtils.getPasteContainers(
        component,
        componentData,
        primaryContainerId,
        rightClickPosition,
        groupPastePosition,
      );
    const componentStageLayout = copyPasteUtils.getComponentStageLayout(
      component,
      groupPastePosition,
      rightClickPosition,
      originalPasteContainer,
    );
    const componentPosition = _.pick(componentStageLayout, ['x', 'y']);

    const overrideContainerIsPage = editorAPI.utils.isPage(
      overridePasteContainer,
    );

    if (forcedPasteContainer) {
      return {
        componentPosition: componentStageLayout,
        pasteContainer: forcedPasteContainer,
        overridePasteContainer: forcedPasteContainer,
      };
    }

    // Apply page layout & scroll position to future paste position
    let pasteContainer;

    if (
      originAction === PasteActionOrigins.AddPanelAdd &&
      !util.sections.isSectionsEnabled()
    ) {
      // Add panel's default position already took page/scroll into account
      pasteContainer = originalPasteContainer;
    } else if (_.isEqual(overridePasteContainer, originalPasteContainer)) {
      if (overrideContainerIsPage) {
        componentPosition.x =
          componentPosition.x - pageLayout.x + scroll.scrollLeft;
        componentPosition.y =
          componentPosition.y - pageLayout.y + scroll.scrollTop;
      }

      pasteContainer = originalPasteContainer;
    } else {
      const pasteContainerPos =
        editorAPI.components.layout.getRelativeToScreenConsideringScroll(
          overridePasteContainer,
        );
      componentPosition.x -= pasteContainerPos.x;
      componentPosition.y -= pasteContainerPos.y;

      if (
        editorAPI.components.is.fullWidth(overridePasteContainer) &&
        !editorAPI.isMobileEditor()
      ) {
        componentPosition.x -= pageLayout.x;
      }

      pasteContainer = overridePasteContainer;
    }

    componentPosition.x += componentData.positionRelativeToSnug.x;
    componentPosition.y += componentData.positionRelativeToSnug.y;

    const overridePastePosition = copyPasteUtils.getOverridePastePosition({
      component,
      componentData,
      layoutRelativeToStructure: defaultGroupPastePosition,
      componentLayout: componentStageLayout,
      positionToPaste: componentPosition,
      rightClickPosition,
      originAction,
    });

    if (overridePastePosition) {
      componentPosition.x = overridePastePosition.x;
      componentPosition.y = overridePastePosition.y;
    }

    return { componentPosition, pasteContainer, overridePasteContainer };
  }

  const getPositionForPaste = (
    originalRightClickPosition: Point | null,
    componentLayout: CompLayout,
  ) => {
    const stageLayoutWidth =
      stateManagement.domMeasurements.selectors.getStageLayout(
        editorAPI.store.getState(),
      ).width;
    const mobileStageWidth = stageLayoutWidth - editorAPI.site.getSiteX();
    const desktopStageWidth = util.fixedStage.isFixedStageEnabled()
      ? editorAPI.site.getWidth()
      : stageLayoutWidth;

    const stageWidth = editorAPI.isMobileEditor()
      ? mobileStageWidth
      : desktopStageWidth;

    const { scrollLeft = 0 } = editorAPI.scroll.get();

    const MAX_ALLOWED_COMP_SIZE_OUTSIDE_STAGE = 0.5;

    if (
      originalRightClickPosition &&
      originalRightClickPosition.x +
        scrollLeft +
        componentLayout.width * MAX_ALLOWED_COMP_SIZE_OUTSIDE_STAGE >
        stageWidth
    ) {
      return {
        ...originalRightClickPosition,
        x:
          stageWidth -
          scrollLeft -
          componentLayout.width * MAX_ALLOWED_COMP_SIZE_OUTSIDE_STAGE,
      };
    }

    return originalRightClickPosition;
  };

  async function pasteAndReturnNewComponents({
    originAction /* paste|duplicate|addPanelAdd */,
    components,
    componentsData,
    guid,
    snugLayoutRelativeToStructure,
    rightClickPosition = null,
    isCrossSite,
    elementId,
    forcedPasteContainer,
  }: {
    originAction: PasteActionOrigins;
    components: CompStructure[];
    forcedPasteContainer?: CompRef;
  }) {
    const scroll = editorAPI.ui.scroll.getScroll();
    const { id: primaryContainerId } = editorAPI.pages.getPrimaryContainer();
    const pagesContainerRef = editorAPI.dsRead.siteSegments.getPagesContainer();
    const pageLayout =
      editorAPI.components.layout.getRelativeToScreen(pagesContainerRef);
    const recalculateOnViewportTouch = !components.some(
      ({ _dataId }) => componentsData[_dataId].appWidgetAncestor,
    );
    rightClickPosition = copyPasteUtils.isValidRCPosition(rightClickPosition)
      ? rightClickPosition
      : null;
    const defaultGroupPastePosition = copyPasteUtils.getDefaultPastePosition(
      originAction,
      snugLayoutRelativeToStructure,
      guid,
      primaryContainerId,
      recalculateOnViewportTouch,
      scroll,
      components,
    );

    const clickPositionForPaste = getPositionForPaste(
      rightClickPosition,
      snugLayoutRelativeToStructure,
    );

    const groupPastePosition =
      clickPositionForPaste || defaultGroupPastePosition;

    const results = await Promise.all(
      components.map(async (component) => {
        const componentData = componentsData[component._dataId];

        const { componentPosition, pasteContainer, overridePasteContainer } =
          getComponentPastePosition({
            component,
            componentData,
            primaryContainerId,
            clickPositionForPaste,
            groupPastePosition,
            defaultGroupPastePosition,
            pageLayout,
            scroll,
            originAction,
            forcedPasteContainer,
          });
        Object.assign(component.layout, componentPosition);

        component =
          serializedComponentPlugins.runPastePluginOnSerializedComponent(
            component,
            editorAPI,
          );

        if (
          !editorAPI.components.is.containableByStructure(
            component,
            pasteContainer,
          )
        ) {
          return null;
        }

        const newPointer = await waitForAddedCompRef(
          addComponent(component, {
            parentContainerRef: pasteContainer,
            overrideParentContainerRef: overridePasteContainer,
            appWidgetAncestor: componentData.appWidgetAncestor,
            isCrossSite,
            originAction,
            elementId,
          }),
        );

        if (!newPointer) {
          // eslint-disable-next-line no-console
          console.warn('component was not added', component);
          return null;
        }

        return {
          originCompId: component.originCompId,
          targetCompId: newPointer.id,
          newPointer,
        };
      }),
    );

    return results.filter(Boolean);
  }

  function openHelpCenter(helpId) {
    return () => editorAPI.panelManager.openHelpCenter(helpId);
  }

  function openMediaManager() {
    editorAPI.mediaServices.mediaManager.open(
      editorAPI.mediaServices.mediaManager.categories.ALL_MEDIA,
      { path: 'home' },
    );
  }

  function showCantPasteNotification() {
    editorAPI.showUserActionNotification({
      title: 'Notifications_CantPasteElements_Text',
      message: 'Notifications_CantPasteElements_Text',
      type: 'warning',
      link: {
        caption: 'Notifications_Learn_More_Link',
        onNotificationLinkClick: openHelpCenter(
          helpIds.HELP_CENTER.CANNOT_PASTE_COMPONENTS,
        ),
      },
    });
  }

  function showUploadSuccessNotification() {
    editorAPI.showUserActionNotification({
      title: 'Notifications_UploadingPastedFiles_Text',
      message: 'Notifications_UploadingPastedFiles_Text',
      type: 'info',
      link: {
        caption: 'Notifications_Open_SiteFiles_Link',
        onNotificationLinkClick: openMediaManager,
      },
    });
  }

  function transferMediaFiles({ mediaIds, uploadToken }) {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.PASTE_COPY_MEDIA_FILES,
    );
    return editorAPI.mediaServices
      .transferMediaItems(mediaIds, uploadToken)
      .then(
        () => {
          showUploadSuccessNotification();
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.PASTE_COPY_MEDIA_FILES,
          );
        },
        (error) =>
          ErrorReporter.captureException(error, {
            tags: { transferMediaFiles: true },
          }),
      );
  }

  function waitForChangesApplied(payload) {
    return new Promise((resolve) => {
      editorAPI.dsActions.waitForChangesApplied(() => {
        resolve(payload);
      });
    });
  }

  function canApplyModeFromClipboard() {
    const clipboardItem = editorAPI.clipboard.getItem();
    const isComponentClipboard =
      clipboardItem.type === constants.CLIPBOARD_ITEM_TYPE.COMPONENT;
    const clipboardCompData = clipboardItem.value;

    if (
      !(
        isComponentClipboard &&
        clipboardCompData &&
        clipboardCompData.containerWithModes
      )
    ) {
      return false;
    }

    const componentsApi = editorAPI.components;
    const editorState = editorAPI.store.getState();
    const selectedCompsRefs =
      stateManagement.selection.selectors.getSelectedCompsRefs(editorState);
    if (!selectedCompsRefs || selectedCompsRefs.length !== 1) {
      return false;
    }

    const selectedComponent = selectedCompsRefs[0];
    if (!_.isEqual(selectedComponent, clipboardCompData.containerWithModes)) {
      return false;
    }

    const modes = componentsApi.modes.getModes(selectedComponent);
    if (!modes || !modes.length) {
      return false;
    }

    const activeModeIds =
      componentsApi.modes.getComponentActiveModeIds(selectedComponent);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/size
    if (_.size(activeModeIds) !== 1) {
      return false;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const activeModeId = _.keys(activeModeIds)[0];
    const inactiveModeIds = _(modes)
      .reject({ modeId: activeModeId })
      .map('modeId')
      .value();

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    return _.every(clipboardCompData.components, ({ _dataId }) => {
      const { compRef } = clipboardCompData.componentsData[_dataId];
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      const displayedInInactiveModes = _.some(
        inactiveModeIds,
        (inactiveModeId) =>
          componentsApi.modes.isComponentDisplayedInModes(compRef, [
            inactiveModeId,
          ]),
      );

      return (
        displayedInInactiveModes &&
        !componentsApi.modes.isComponentDisplayedInModes(compRef, [
          activeModeId,
        ])
      );
    });
  }

  function isAllowedToPaste(payload) {
    if (
      copyPasteUtils.isAllowedToPaste(
        payload.components,
        payload.componentsData,
      )
    ) {
      return payload;
    }

    if (editorAPI.isMobileEditor()) {
      editorAPI.openCantCopyPastePanel();
    }

    return Promise.reject({ reason: 'prohibited' });
  }

  function prepareCrossSite(payload) {
    const { metaData, options } = payload;
    const { isCrossSite } = options;
    let { components } = payload;

    if (!isCrossSite) {
      return payload;
    }

    const crossSiteMediaIds = collectMediaIds(components);
    const hasCrossSiteMediaContent = crossSiteMediaIds.length > 0;
    const hasCrossSiteUnsupportedComponents =
      copyPasteUtils.hasCrossSiteUnsupportedComponents(components);

    if (!hasCrossSiteMediaContent && hasCrossSiteUnsupportedComponents) {
      showCantPasteNotification();
    }

    components = copyPasteUtils.getCrossSiteDuplicatableComponents(components);
    if (util.isCustomMenusEnabled()) {
      components = copyPasteUtils.normalizeComponents(components);
    }
    if (!components.length) {
      return Promise.reject({ reason: 'filtered' });
    }

    if (!options.shouldApplyTargetTheme) {
      components = themeUtils.applyThemeToSerializedComponents(
        components,
        metaData.theme,
      );
    }

    if (!editorAPI.clipboard.isAlreadyPasted(metaData.guid)) {
      const { id: primaryContainerId } = editorAPI.pages.getPrimaryContainer();
      const { snugLayoutRelativeToStructure, originalScrollPosition } =
        metaData;

      editorAPI.clipboard.setLastPasted(metaData.guid);
      editorAPI.pasteLogic.pasteContext.setPasteData(
        snugLayoutRelativeToStructure,
        primaryContainerId,
        originalScrollPosition,
      );
    }

    return Object.assign({}, payload, {
      components,
      hasCrossSiteUnsupportedComponents,
    });
  }

  const flattenCompsTree = (comp: CompStructure): CompStructure[] => {
    if (!comp.components?.length) {
      return comp;
    }
    return [comp, ...comp.components.flatMap(flattenCompsTree)];
  };

  const flattenComponents = (comps: CompStructure[]): CompStructure[] => {
    return comps.flatMap(flattenCompsTree);
  };

  const shouldCompletelyFitIntoContainer = (compStructure: CompStructure) => {
    return compStructure?.data?.appDefinitionId === WIX_PRO_GALLERY;
  };

  const getMinContainerHeightByChildren = (
    components: CompStructure[],
  ): number => {
    if (!components) return 0;
    const allComps = flattenComponents(components);

    const componentToFitIntoContainer = allComps.filter(
      shouldCompletelyFitIntoContainer,
    );

    return componentToFitIntoContainer.reduce((acc, current) => {
      const currentHeight = current?.layout?.height || 0;
      return Math.max(currentHeight, acc);
    }, 0);
  };

  async function prepareContainer(payload) {
    const { components, metaData } = payload;

    if (util.sections.isSectionsEnabled()) {
      const hasFWEs = components.some((compDef) =>
        editorAPI.addPanelInfra.addPanelUtils.isStretchedComp(
          editorAPI,
          compDef,
        ),
      );

      const minContainerHeightByChildren =
        getMinContainerHeightByChildren(components);

      const targetContainer = util.sections.getSectionLikeContainerToPaste(
        editorAPI,
        components,
      );

      if (!targetContainer) return payload;

      const targetContainerLayout =
        editorAPI.components.layout.get_size(targetContainer);

      if (hasFWEs) {
        editorAPI.components.layout.updateAndAdjustLayout(
          targetContainer,
          {
            height:
              targetContainerLayout.height +
              metaData.snugLayoutRelativeToStructure.height,
          },
          true,
        );

        await editorAPI.waitForChangesAppliedAsync();
      } else if (minContainerHeightByChildren > targetContainerLayout.height) {
        editorAPI.components.layout.updateAndAdjustLayout(
          targetContainer,
          {
            height: minContainerHeightByChildren,
          },
          true,
        );

        await editorAPI.waitForChangesAppliedAsync();
      }
    }

    return payload;
  }

  async function performPaste(payload) {
    const { components, componentsData, options, metaData } = payload;

    editorAPI.panelManager.closeAllPanels();
    const pointerPairs = await pasteAndReturnNewComponents({
      originAction: options.originAction,
      components,
      componentsData,
      guid: metaData.guid,
      snugLayoutRelativeToStructure: metaData.snugLayoutRelativeToStructure,
      rightClickPosition: options.rightClickPosition,
      isCrossSite: options.isCrossSite,
      elementId: options.elementId,
      forcedPasteContainer: options.forcedPasteContainer,
    });

    const newComponentPointers = pointerPairs.map((pair) => pair.newPointer);
    const originalComponentPointers = pointerPairs.map((pair) =>
      editorAPI.components.get.byId(pair.originCompId),
    );

    return Object.assign({}, payload, {
      pointerPairs,
      newComponentPointers,
      originalComponentPointers,
    });
  }

  function applyMode(payload) {
    const { isPerformingMouseMoveAction } =
      stateManagement.mouseActions.selectors;
    if (isPerformingMouseMoveAction(editorAPI.store.getState())) {
      return payload;
    }

    return waitForChangesApplied()
      .then(() => {
        editorAPI.selection.selectComponentByCompRef(
          payload.newComponentPointers,
        );
        editorAPI.openFirstTimeOrDeprecationPanel(payload.newComponentPointers);
        if (payload.shouldShowApplyModeFromClipboardSuggestion) {
          editorAPI.updateState({
            applyModeFromClipboardSuggestion: {
              selectedComponents: payload.newComponentPointers,
              container: payload.prePasteSelectedComponents,
            },
          });
        }
      })
      .then(() => payload);
  }

  function handleMediaFilesTransfer(payload) {
    const { isCrossSite } = payload.options;
    const { siteUploadToken } = payload.metaData;
    const { hasCrossSiteUnsupportedComponents } = payload;

    let hasMediaContent;

    if (isCrossSite) {
      const mediaIds = collectMediaIds(payload.components);

      hasMediaContent = mediaIds.length > 0;
      if (hasMediaContent) {
        transferMediaFiles({
          mediaIds,
          uploadToken: siteUploadToken,
        }).then(() => {
          if (hasCrossSiteUnsupportedComponents) {
            showCantPasteNotification();
          }
        });
      }
    }

    return Object.assign({}, payload, { hasMediaContent });
  }

  /**
   * @param {Array<Component>} components Array of components
   * @param {Object} componentsData
   * @param {boolean} componentsData.isShowOnAllPages
   * @param {Object} componentsData.compRef
   * @param {string} componentsData.compRef.id
   * @param {string} componentsData.compRef.type
   * @param {Object} componentsData.translationData
   * @param {Array<string>} componentsData.dataItemIds
   * @param {Object} componentsData.positionRelativeToSnug
   * @param {number} componentsData.positionRelativeToSnug.x
   * @param {number} componentsData.positionRelativeToSnug.y
   *
   * @param {Object} metaData
   * @param {Object} metaData.theme
   * @param {Object} metaData.theme.colors
   * @param {Object} metaData.theme.fonts
   * @param {Object} metaData.theme.styles
   * @param {string} metaData.originMetaSiteId
   * @param {string} metaData.guid
   * @param {Object} metaData.snugLayoutRelativeToStructure
   * @param {Object} metaData.originalScrollPosition
   * @param {stringList} metaData.siteUploadToken
   *
   * @param {Object } options
   * @param {boolean} options.shouldApplyTargetTheme
   * @param {boolean} options.isCrossSite
   * @param {Object} options.rightClickPosition
   * @param {string} options.originAction
   * @param {Object} options.forcedPasteContainer
   *
   * @typedef {Object} Result
   * @property {boolean} success
   * @property {boolean} hasMediaContent
   * @property {array} newComponentPointers array of new components refs
   *
   * @returns {Result}
   **/
  function pasteComponentWrappers(
    components,
    componentsData,
    metaData,
    options,
  ) {
    const shouldShowApplyModeFromClipboardSuggestion =
      canApplyModeFromClipboard();
    const editorState = editorAPI.store.getState();
    const prePasteSelectedComponents =
      stateManagement.selection.selectors.getSelectedCompsRefs(editorState);

    // Commented fields are for documentation purposes. These props would be added during payload processing
    const payload = {
      components,
      componentsData,
      metaData,
      options,
      prePasteSelectedComponents,
      shouldShowApplyModeFromClipboardSuggestion,
      /*, pointerPairs, newComponentPointers, hasMediaContent, hasCrossSiteUnsupportedComponents, originalComponentPointers */
    };

    return new Promise((resolve) => resolve(payload))
      .then(isAllowedToPaste)
      .then(prepareCrossSite)
      .then(prepareContainer)
      .then(performPaste)
      .then(applyMode)
      .then(waitForChangesApplied)
      .then(handleMediaFilesTransfer)
      .then((_payload) => ({
        success: true,
        hasMediaContent: _payload.hasMediaContent,
        newComponentPointers: _payload.newComponentPointers,
        originalComponentPointers: _payload.originalComponentPointers,
      }))
      .catch((error) =>
        error.reason
          ? { success: false, reason: error.reason }
          : Promise.reject(error),
      );
  }

  return {
    showCantPasteNotification,
    canApplyModeFromClipboard,
    transferMediaFiles,
    pasteComponentWrappers,
    pasteAndReturnNewComponents,
  };
}

export default createPaste;

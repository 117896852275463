import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import { translate } from '#packages/i18n';
import * as editorUtils from '@wix/santa-editor-utils';
import * as stylableSantaFlatten from '@wix/stylable-santa-flatten/consts';
import type {
  BIParams,
  Palette,
  PanelEventList,
  StylablePanelHost,
} from '@wix/stylable-panel';
import type { Coordinates, DimensionUnits } from '@wix/stylable-panel-common';
import experiment from 'experiment';
import _ from 'lodash';
import type { CompRef } from 'types/documentServices';
import type { StylablePanelProps } from './stylablePanelProps';
import type {
  EditorPalette,
  ForceStateSelectors,
  PopupPanelMap,
  PopupPanelProps,
  IStylablePanelHost,
} from './stylablePanelTypes';
import { type ColorName } from '#packages/theme';

const { getSelectedComponents } = editorUtils.stylable;

const PANEL_PREFIX = 'panels.toolPanels.stylableEditor';
const STATE_LIST_DIALOG_NAME = `${PANEL_PREFIX}.stateListDialog`;
const { wixMediaUrlFormatter } = stylableSantaFlatten;
const { GRADIENT_CATEGORY } = constants.STYLABLE.EDITOR;

const COLOR_DEFAULTS_HELP_ID = '22c46b7e-7074-48f4-bb5d-da04dafa92f4';

const getStylablePanelHelpId = (helpLinkKey: string) =>
  helpIds.STYLABLE_PANEL[helpLinkKey as keyof typeof helpIds.STYLABLE_PANEL]; // Returns active help link ID

export function getStylablePanelHost(
  getProps: () => StylablePanelProps,
  getPanelMap: () => PopupPanelMap,
  getEditorPalettes: () => EditorPalette[],
  getColorPalettes: () => Palette[],
  reportBI: (event: PanelEventList, params?: BIParams) => void,
  popOpenPanel: () => void,
  getForceState: () => ForceStateSelectors,
): IStylablePanelHost {
  const {
    showUserActionNotification: onEditorNotify,
    forceState: onForceState,
    revertForceState: onRevertForceState,
    getTextStyles,
    getFontThemes,
    getCurrentSelectableFontsWithParams: getSelectableFonts,
    getFontsDropDownItems: getFontsItems,
    customTranslations,
    dimensionUnits = {},
    onSiteColorChange,
    openThemePanelColorTab,
    isNewPaletteOpen,
    visibleThemeColors,
    editorAPI,
  } = getProps();

  const experiments: StylablePanelHost['experiments'] = {
    fixTextThemeBehavior: experiment.isOpen(
      'se_stylablePanelFixTextThemeBehavior',
    ),
    fixCustomColorToSiteThemeConversion: experiment.isOpen(
      'se_stylablePanelFixCustomColorToSiteThemeConversion',
    ),
    isNewPalette: isNewPaletteOpen,
  };
  let defaultColorsLength = 2;

  const editorDimensionUnits: DimensionUnits = {};

  function removeUserColor(
    index: number,
    userAddedColors: string[],
    userCustomAddedColors: string[],
  ) {
    const { setUserAddedColors, setUserCustomAddedColors } = getProps();
    const indexWithoutDefaultColors = index - defaultColorsLength;

    const allColors = [
      ...new Set([...userAddedColors, ...userCustomAddedColors]),
    ];

    const colorToRemove = allColors[indexWithoutDefaultColors];

    if (colorToRemove) {
      const indexToRemoveFromSite =
        userCustomAddedColors.indexOf(colorToRemove);
      const indexToRemoveFromSession = userAddedColors.indexOf(colorToRemove);

      if (indexToRemoveFromSite >= 0) {
        userCustomAddedColors.splice(indexToRemoveFromSite, 1);
        setUserCustomAddedColors(userCustomAddedColors);
      }

      if (indexToRemoveFromSession >= 0) {
        userAddedColors.splice(indexToRemoveFromSession, 1);
        setUserAddedColors(userAddedColors);
      }
    }

    if (index < defaultColorsLength && defaultColorsLength !== 0) {
      defaultColorsLength -= 1;
    }
  }

  function getDimensionUnits(dimensionUnitsFromProps: DimensionUnits) {
    return Object.keys(dimensionUnitsFromProps).length > 0
      ? dimensionUnitsFromProps
      : editorDimensionUnits;
  }

  return {
    /**
     * Stylable panel related experiments
     */
    experiments,
    /**
     * Highlights or cancels highlights for a component element on the stage
     * Triggered when an element is hovered in the panel element tree
     * @function
     * @param {string | null} selector - Selector for the element to
     * highlight, or null for removing highlights
     */
    onHighlight: (selector: string | null) => {
      const {
        stylableEditor: { stylableDriver, stylesheetPath },
        selectedComponent: selectedComponentOrComponents,
        getElementLayout,
        clearHighlights,
        setHighlights,
      } = getProps();

      const selectedComponents: CompRef[] = getSelectedComponents(
        selectedComponentOrComponents,
      );

      if (selector) {
        const sheetDriver = stylableDriver.getStylesheet(stylesheetPath);
        if (sheetDriver) {
          const compRef = _.head(selectedComponents);
          setHighlights(
            compRef,
            getElementLayout(compRef, sheetDriver.getTargetSelector(selector)),
          );
        }
      } else {
        clearHighlights();
      }
    },

    /**
     * Opens a StylablePanel panel wrapped in an editor tool panel
     * Triggers when the panel asks for the panel to open
     * @function
     * @param {string} name - Name of the panel to open
     * @param {Object} panelProps - Props to pass to the panel component
     */
    onOpenPanel: (
      name: string,
      panelProps: PopupPanelProps,
      coordinates?: Coordinates,
    ) => {
      const openPanelAction = getPanelMap()[name];
      if (!openPanelAction) {
        return;
      }

      openPanelAction(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, panelProps, {
          closeStateListDialog: () => {
            getProps().closePanel(STATE_LIST_DIALOG_NAME);
            popOpenPanel();
          },
          onClose: () => {
            if (panelProps.onClose) {
              panelProps.onClose();
            }
            popOpenPanel();
          },
        }),
        coordinates,
      );
    },

    /**
     * Updates the editor user colors/gradients with a newly added color/gradient
     * Triggered when a user color/gradient is added in the panel
     * @function
     * @param {string} value - New user color/gradient
     * @param {string} [category] - Category of the added value
     */
    onUserColorAdd: (value: string, category?: string) => {
      const {
        userAddedColors: userAddedColorsProp,
        userCustomAddedColors: userCustomAddedColorsProp,
        setUserCustomAddedColors,
        userAddedGradients: userAddedGradientsProp,
        setUserAddedGradients,
      } = getProps();

      if (category === GRADIENT_CATEGORY) {
        const userAddedGradients = _.cloneDeep(userAddedGradientsProp) || [];
        userAddedGradients.push(value);

        setUserAddedGradients(userAddedGradients);
      } else {
        const userAddedColors = _.cloneDeep(userAddedColorsProp) || [];
        const userCustomAddedColors =
          _.cloneDeep(userCustomAddedColorsProp) || [];
        userAddedColors.push(value);
        userCustomAddedColors.push(value);

        setUserCustomAddedColors(userCustomAddedColors);
      }
    },

    /**
     * Updates the editor user colors/gradients after removing color/gradient
     * Triggered when a user color/gradient is removed from the panel
     * @function
     * @param {number} index - Index of the user color/gradient to remove
     * @param {string} [category] - Category of the added value
     */
    onUserColorRemove: (index: number, category?: string) => {
      const {
        userAddedColors: userAddedColorsProp,
        userCustomAddedColors: userCustomAddedColorsProp,
        userAddedGradients: userAddedGradientsProp,
        setUserAddedGradients,
      } = getProps();

      if (category === GRADIENT_CATEGORY) {
        const userAddedGradients = _.cloneDeep(userAddedGradientsProp) || [];
        userAddedGradients.splice(index, 1);

        setUserAddedGradients(userAddedGradients);
      } else {
        const userAddedColors = _.cloneDeep(userAddedColorsProp) || [];
        const userCustomAddedColors =
          _.cloneDeep(userCustomAddedColorsProp) || [];

        removeUserColor(index, userAddedColors, userCustomAddedColors);
      }
    },

    /**
     * Displays a user notification
     * Triggered when the panel has a notification
     * @function
     */
    onEditorNotify,

    /**
     * Forces a state override on the editor stage
     * Triggered when a state is selected or hovered in the part state selector
     * @function
     */
    onForceState: (selection: string) => {
      //Save data locally
      const forceStateSelectors = getForceState();
      forceStateSelectors.forceState = selection;
      onForceState(
        forceStateSelectors.forceState,
        forceStateSelectors.selectionSelector,
      );
    },

    /**
     * Reverts a force state override on the editor stage
     * Triggered when a state is hovered-out in the part state selector
     * @function
     */
    revertForceState: () => {
      const forceStateSelectors = getForceState();
      forceStateSelectors.forceState = undefined;
      forceStateSelectors.selectionSelector = undefined;
      onRevertForceState();
    },
    /**
     * signals the compnoent that an element was selected
     * @param selectionSelector - selector for selected element
     */
    onElementSelect: (selectionSelector: string) => {
      const forceStateSelectors = getForceState();
      forceStateSelectors.selectionSelector = selectionSelector;
      onForceState(
        forceStateSelectors.forceState,
        forceStateSelectors.selectionSelector,
      );
    },

    /**
     * Gets the editor selectable font themes
     * Triggered when the text controller is rendered
     * @function
     */
    getFontThemes: getFontThemes ?? getTextStyles,

    /**
     * Gets the editor selectable fonts
     * Triggered when the font family selector is rendered
     * @function
     */
    getSelectableFonts,
    getFontsItems,

    /**
     * Gets the editor palettes
     * Triggered when the StylablePanel fill picker is opened
     * @function
     */
    getPalettes: () => getColorPalettes(),

    /**
     * Array of color names with filtered out unset Accent Colors
     */
    visibleThemeColors,

    /**
     * Translates the given translation key
     * Triggered when a key is in need of translation in the panel
     * @function
     * @param {string} key - Key to translate
     * @param {object} - options for lodash template string (key:string, value:string)
     * @returns {string} Translated string
     */
    translate: (key: AnyFixMe, options: AnyFixMe) => translate(key, options),

    /**
     * Report BI events
     * @function
     * @param {WixBIEvent} event
     * @param {WixBIParam[]} [params]
     */
    reportBI,

    /**
     * Set help link ID for a specific context
     * @function
     * @param {string} id - HelpId to set
     */
    setHelpLinkId: (id: string) => {
      const { setHelpId } = getProps();
      if (setHelpId) {
        setHelpId(getStylablePanelHelpId(id));
      }
    },

    openColorDefaultsHelp: () => {
      editorAPI.panelManager.openHelpCenter(COLOR_DEFAULTS_HELP_ID);
    },

    getLinkedThemeColorFromColorDefault: (colorName: string | null) => {
      const linkedColors = editorAPI.theme.colors.getAllLinkedColors() ?? {};

      if (!colorName) return;
      const colorNumber = Number(colorName?.match(/color_(\d{2})/)?.[1]);
      const isAccentColor = colorNumber >= 41 && colorNumber <= 44;
      if (isAccentColor) return;
      return linkedColors[colorName as ColorName];
    },

    customTranslations,
    defaultImage: `${wixMediaUrlFormatter}(03f22a3cb4d543b9b7ffcaae2f53a15f.jpg, 6016, 4016)`,
    dimensionUnits: getDimensionUnits(dimensionUnits),
    formatters: [wixMediaUrlFormatter],
    onSiteColorChange: onSiteColorChange || openThemePanelColorTab,
    isCustomCssEditingActive: false,
  };
}

// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import { ToolPanelFrame } from '../../frames';
import * as symbols from '@wix/santa-editor-symbols';
import * as baseUI from '#packages/baseUI';
import {
  Button,
  ColorPickerInput,
  Composites,
  CustomScroll,
  Divider,
  PanelHeader,
  TextLabel,
  Thumbnails,
  Tooltip,
} from '@wix/wix-base-ui';

function template() {
  return (
    <div className="main-content-change-qab-icon">
      <PanelHeader
        onHelp={this.openHelpPanel}
        onClose={this.close}
        onMouseDown={this.startContentDrag}
      >
        <TextLabel
          value="mobile_quick_actions_set_up_choose_icon_header"
          type="T05"
        />
      </PanelHeader>

      {this.state.renderIcons ? (
        <div key="qab-icons-wrapper" className="qab-icons-wrapper">
          <CustomScroll>
            <div className="thumbnails-qab-wrapper">
              {this.props.qabUploadedSvg ? (
                <div
                  key="changeUploadedCustomIcon"
                  onClick={this.openMediaManager}
                  className="change-uploaded-custom-icon"
                >
                  <Tooltip
                    shouldTranslate={true}
                    closeOnMouseClick={true}
                    content="mobile_quick_actions_set_up_change_icon_tooltip"
                  >
                    <symbols.symbol name="video-player-change-poster" />
                  </Tooltip>
                </div>
              ) : null}

              <Thumbnails
                value={this.props.currentIcon}
                options={this.getThumbnailOptions()}
                onChange={this.onThumbnailChange}
                addThumbnailProps={this.getAddThumbnailProps()}
              />
            </div>
          </CustomScroll>

          {this.shouldRenderChangeColors() ? (
            <Composites.ButtonLargeFixedBottom
              key="change-color-icon-qab"
              className="change-color-icon"
            >
              <Button
                onClick={this.switchToChangeColor}
                automationId="change-color-icon"
              >
                {translate('mobile_quick_actions_set_up_change_color_button')}
              </Button>
            </Composites.ButtonLargeFixedBottom>
          ) : null}
        </div>
      ) : null}

      {!this.state.renderIcons ? (
        <div key="qab-icon-change-color" className="qab-icon-change-color">
          <div className="btn-back-wrapper">
            <baseUI.button
              label="mobile_quick_actions_set_up_change_icon_back_button"
              icon="arrowLeftSmall"
              onClick={this.switchToIconsList}
              className="btn-back"
            />
          </div>

          <Divider long={true} />

          <div className="change-color-content-wrapper">
            <TextLabel
              value="mobile_quick_actions_set_up_change_icon_color"
              className="color-picker-text"
            />

            <ColorPickerInput
              value={this.getCurrentColor()}
              onClick={this.openColorPicker}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

const MEDIA_MANAGER_OPEN_SOURCE = 'qab_change_icon';
const CUSTOM_THUMBNAIL_VALUE = 'my_uploads_images';
const BASIC_SHAPES_PATH =
  'public/ab62ca24cd194952aad0707eead0c0f7/6e81b28a-62f2-4dad-9665-a38767d78666';
const CHANGE_QAB_HELP_CENTER = 'c318bb09-a14c-49da-8c9e-205e83d11df8';
const COLORING_SCHEMA_NAMES = new Set(['brand', 'brand inv']);
const SVG_SUFFIX = '.svg';
const CUSTOM_SVG_SIZE = 51;

// @TODO import it from theme
function isThemeColor(color) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  return _.includes(color, 'color_');
}

function createThumbnail(symbolName, value, illustration) {
  return {
    value,
    illustration:
      illustration ||
      React.createElement(symbols.symbol, {
        name: symbolName,
        style: { fill: '#2B576E' },
      }),
  };
}

//TYPE WAS GENERATED, remove this line when reviewed
interface ChangeQabIconProps {
  top: number;
  left: number;
  panelName: string;
  options: Array<AnyFixMe>;
  onChange: FunctionFixMe;
  currentIcon: string;
  currentColor: string;
  openMediaManager: FunctionFixMe;
  openPanel: FunctionFixMe;
  getStyle: FunctionFixMe;
  updateStyle: FunctionFixMe;
  getColorByTheme: FunctionFixMe;
  openHelpCenter: FunctionFixMe;
  currentIconRef: AnyFixMe;
  mediaCategory: string;
  qabUploadedSvg?: string;
  closePanelByName: FunctionFixMe;
}

class ChangeQabIcon extends React.Component<ChangeQabIconProps> {
  static displayName = 'ChangeQabIcon';

  static propTypes = {
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    panelName: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    currentIcon: PropTypes.string.isRequired,
    currentColor: PropTypes.string.isRequired,
    openMediaManager: PropTypes.func.isRequired,
    openPanel: PropTypes.func.isRequired,
    getStyle: PropTypes.func.isRequired,
    updateStyle: PropTypes.func.isRequired,
    getColorByTheme: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
    currentIconRef: PropTypes.object.isRequired,
    mediaCategory: PropTypes.string.isRequired,
    qabUploadedSvg: PropTypes.string,
    closePanelByName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    if (
      !props.qabUploadedSvg &&
      !props.options.find((option) => option.value === props.currentIcon)
    ) {
      props.setQabUploadedSvg(props.currentIcon, props.currentIconRef.id);
    }

    this.state = {
      renderIcons: true,
    };
  }

  close = () => {
    this.props.closePanelByName(this.props.panelName);
  };

  shouldRenderChangeColors = () => {
    return (
      this.props.isColoring &&
      !(
        this.props.currentIcon === this.props.qabUploadedSvg &&
        this.props.isUploadedButtonVectorArt
      )
    );
  };

  onThumbnailChange = (newVal) => {
    this.props.onChange(newVal);
  };

  openHelpPanel = () => {
    this.props.openHelpCenter(CHANGE_QAB_HELP_CENTER);
  };

  getThumbnailOptions = () => {
    const defaultThumbnails = this.props.options.map((option) =>
      createThumbnail(option.symbolName, option.value),
    );
    defaultThumbnails.pop();
    if (this.props.qabUploadedSvg) {
      const overrideIllustration =
        this.props.uploadedSvg &&
        React.createElement('div', {
          style: {
            backgroundImage: `url(${util.media.getQabUploadedSvgUrl(
              this.props.qabUploadedSvg,
            )})`,
            width: CUSTOM_SVG_SIZE,
            height: CUSTOM_SVG_SIZE,
            backgroundSize: '30px auto',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            // this filter will convert black color to the target color `#2b576e` - jira #WEED-20792
            filter:
              'invert(30%) sepia(10%) saturate(2399%) hue-rotate(157deg) brightness(94%) contrast(91%)',
          },
        });
      defaultThumbnails.unshift(
        createThumbnail(
          CUSTOM_THUMBNAIL_VALUE,
          this.props.qabUploadedSvg,
          overrideIllustration,
        ),
      );
    }
    return defaultThumbnails;
  };

  openMediaManager = () => {
    this.props.openMediaManager(
      this.props.mediaCategory,
      MEDIA_MANAGER_OPEN_SOURCE,
      {
        multiSelect: false,
        path: BASIC_SHAPES_PATH,
        callback: (image) => {
          if (image) {
            const svgId = image[0].fileName.split('.')[0];
            this.props.onChange(svgId);
            this.props.setQabUploadedSvg(svgId, this.props.currentIconRef.id);
          }
        },
      },
    );
  };

  switchToIconsList = () => {
    this.setState({
      renderIcons: true,
    });
  };

  switchToChangeColor = () => {
    this.setState({
      renderIcons: false,
    });
  };

  getCurrentColor = () => {
    return this.props.currentColor;
  };

  onChangeColor = (newColor) => {
    let color = newColor;
    if (isThemeColor(newColor)) {
      color = this.props.getColorByTheme(newColor);
    }
    const currentStyle = this.props.getStyle(this.props.currentIconRef);
    currentStyle.style.properties.color = color;
    this.props.updateStyle(this.props.currentIconRef, currentStyle);
  };

  openColorPicker = () => {
    const style = this.props.getStyle(this.props.currentIconRef);
    this.props.openColorPicker({
      value: style?.style?.properties?.color || '#000000',
      onChange: this.onChangeColor,
      position: {
        top: this.props.top + 10,
        left: this.props.left + 10,
      },
      previewOnHover: true,
    });
  };

  getAddThumbnailProps = () => {
    return this.props.qabUploadedSvg
      ? null
      : {
          onAddThumbnailClick: this.openMediaManager,
          label: '',
          isFirst: true,
        };
  };

  render() {
    return React.createElement(ToolPanelFrame, {
      panelName: 'panels.toolPanels.changeQabIcon',
      isSingleInstance: true,
      isBlockingLayerFullScreen: false,
      shouldBlockOuterScroll: false,
      contentStyle: _.pick(this.props, ['top', 'left']),
      contentClass: 'change-qab-icon-content-wrapper',
      renderContent: (startContentDrag) => {
        this.startContentDrag = startContentDrag;
        return template.call(this);
      },
    });
  }
}

const mapStateToProps = ({ state, editorAPI }, props) => {
  const qabStyle = editorAPI.components.style.get(props.selectedComponents[0]);
  const currentIconRef =
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(
      props.selectedComponents[0],
    )[props.iconIndex];
  const currentIcon = props.getCurrentIcon();
  const svgFileName = currentIcon + SVG_SUFFIX;
  const svgInfo = editorAPI.media.vectorImage.getSvgInfoFromCache(svgFileName);
  if (!svgInfo) {
    editorAPI.media.vectorImage.fetchSvg(svgFileName, _.noop);
  }

  return {
    openColorPicker({ onChange, value, position, previewOnHover }) {
      editorAPI.openColorPicker(value, position, {
        onChange,
        allowPaletteEditing: true,
        enableHistory: true,
        previewOnHover,
        overridePosition: true,
        panelMode: 'solid',
      });
    },
    currentIcon,
    currentIconRef,
    getStyle: editorAPI.components.style.get,
    openPanel: editorAPI.panelManager.openPanel,
    getColorByTheme: editorAPI.theme.colors.get,
    updateStyle: editorAPI.components.style.update,
    openMediaManager: editorAPI.mediaServices.mediaManager.open,
    uploadedSvg: editorAPI.media.vectorImage.getSvgFromCache(svgFileName),
    mediaCategory: editorAPI.mediaServices.mediaManager.categories.VECTOR_ART,
    currentColor:
      editorAPI.components.style.get(currentIconRef).style.properties.color,
    isColoring: COLORING_SCHEMA_NAMES.has(
      qabStyle?.style?.properties?.colorScheme,
    ),
    isUploadedButtonVectorArt: util.media.isSvgVectorArt(svgInfo),
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    qabUploadedSvg: stateManagement.mobile.selectors.qab.qabUploadedSvg(
      state,
      currentIconRef.id,
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setQabUploadedSvg(svgId, compId) {
    dispatch(
      stateManagement.mobile.actions.qab.setQabUploadedSvg(svgId, compId),
    );
  },
  closePanelByName(panelName) {
    dispatch(stateManagement.panels.actions.closePanelByNameAction(panelName));
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(ChangeQabIcon);

import React from 'react';
import {
  Button,
  Heading,
  PanelHeaderCenteredText,
  Text,
  ToggleSwitch,
} from '@wix/wix-base-ui';

import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';

import { PanelCloseOrigin, frames } from '#packages/panels';

const { CustomPanelFrame, FocusPanelFrame } = frames;

interface MobileFriendlyPanelFrameProps {
  panelName: string;
  onHelp: () => void;
  onClose: (origin: string) => void;
  isMobileOptimizedSite: boolean;
  toggleMobileOptimized: () => void;
}

export const MobileFriendlyPanelFrame: React.FC<
  MobileFriendlyPanelFrameProps
> = (props) => (
  <FocusPanelFrame panelName={props.panelName} shouldHideHeader={true}>
    <div className="mobile-friendly-panel">
      <PanelHeaderCenteredText
        title="Mobile_Friendly_Settings_Panel_Header_Title"
        subtitle="Mobile_Friendly_Settings_Panel_Header_Sub_Title"
        onHelp={props.onHelp}
        onClose={() => props.onClose('X')}
      />
      <div className="mobile-friendly-panel-content">
        <div className="mobile-friendly-settings">
          <div className="mobile-friendly-settings-block">
            <div className="mobile-friendly-settings-block-icon">
              <baseUI.symbol name="mobileFriendly" />
            </div>
            <div className="mobile-friendly-settings-block-info">
              <div className="mobile-friendly-settings-block-title">
                {translate('Mobile_Friendly_Settings_Panel_Toogle_Label')}
              </div>
              <div className="mobile-friendly-settings-block-description">
                {translate('Mobile_Friendly_Settings_Panel_Toogle_Text')}
              </div>
            </div>

            <div className="mobile-friendly-settings-block-control">
              <ToggleSwitch
                value={props.isMobileOptimizedSite}
                onChange={props.toggleMobileOptimized}
                className="mobile-friendly-toggle"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-friendly-panel-footer">
        <div className="mobile-friendly-panel-footer-content">
          <div className="mobile-friendly-learn-more">
            <span className="mobile-friendly-learn-more-text">
              {translate('Mobile_Friendly_Settings_Panel_Link_to_KB_text')}
            </span>
            <Button
              onClick={props.onHelp}
              className="btn-text mobile-friendly-learn-more-link"
            >
              {translate('Mobile_Friendly_Settings_Panel_Link_to_KB_link')}
            </Button>
          </div>
          <Button
            onClick={() => props.onClose('close')}
            className="mobile-friendly-done-btn btn-confirm-secondary btn-md"
          >
            {translate('Mobile_Friendly_SETTINGS_PANEL_CLOSE_BUTTON')}
          </Button>
        </div>
      </div>
    </div>
  </FocusPanelFrame>
);

export const NewWorkspaceMobileFriendlyPanelFrame: React.FC<
  MobileFriendlyPanelFrameProps
> = (props) => (
  <CustomPanelFrame
    panelName={props.panelName}
    className="new-workspace-mobile-friendly-panel"
    onOuterClick={() => props.onClose(PanelCloseOrigin.OUT_OF_FOCUS)}
    onEscKeyPress={() => props.onClose(PanelCloseOrigin.ESCAPE_PRESS)}
    onCloseButtonClick={() =>
      props.onClose(PanelCloseOrigin.HEADER_CLOSE_BUTTON)
    }
    onHelpButtonClick={props.onHelp}
    primaryButtonText={translate('Mobile_Friendly_SETTINGS_PANEL_CLOSE_BUTTON')}
    onPrimaryButtonClick={() => props.onClose(PanelCloseOrigin.CONFIRM_BUTTON)}
  >
    <div className="mobile-friendly-panel-header">
      <Heading shouldTranslate={false} appearance="h1" multiline>
        {translate('Mobile_Friendly_Settings_Panel_Header_Title')}
      </Heading>
      <Heading shouldTranslate={false} appearance="h4" multiline>
        {translate('Mobile_Friendly_Settings_Panel_Header_Sub_Title')}
      </Heading>
    </div>
    <div className="mobile-friendly-panel-content">
      <baseUI.symbol name="mobileFriendly_NewWorkspace" />
      <div className="mobile-friendly-panel-content-text">
        <Heading shouldTranslate={false} appearance="h4">
          {translate('Mobile_Friendly_Settings_Panel_Toogle_Label')}
        </Heading>
        <Text
          shouldTranslate={false}
          enableEllipsis={false}
          skin="secondary"
          size="small"
        >
          {translate('Mobile_Friendly_Settings_Panel_Toogle_Text')}
        </Text>
      </div>
      <ToggleSwitch
        value={props.isMobileOptimizedSite}
        onChange={props.toggleMobileOptimized}
      />
    </div>
  </CustomPanelFrame>
);

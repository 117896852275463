import React from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';

interface LanguageRowMidProps {
  isSelected: boolean;
  flagUrl: string;
  languageName: string;
  isPrimary: boolean;
  published: boolean;
  onSelect(): void;
  invisible: boolean;
  index: number;
}

const LanguageRowMid: React.FC<LanguageRowMidProps> = ({
  isSelected,
  flagUrl,
  languageName,
  isPrimary,
  published,
  invisible,
  onSelect,
  index,
}) => {
  let subtitle: string;
  if (isPrimary) {
    subtitle = 'SiteLanguages_Panel_Label_MainLanguage';
  } else {
    subtitle = published
      ? 'SiteLanguages_Panel_Label_Visible'
      : 'SiteLanguages_Panel_Label_Hidden';
  }

  return (
    <div
      onClick={onSelect}
      className={util.cx(
        'languageRow',
        'languageRowMid',
        'languageRowMid_se-madefor-font',
        {
          languageRowSelected: isSelected,
        },
      )}
      data-aid={`my-site-languages-panel-option-${index}`}
    >
      <div className="languageRowFlag">
        <div
          className={util.cx([
            'flag-icon',
            { 'flag-icon-invisible': invisible },
          ])}
        >
          <img src={flagUrl} />
          {invisible && (
            <symbols.symbol
              name="hiddenPageStatus"
              key="invisiableLanguage-symbol"
            />
          )}
        </div>
      </div>

      <Tooltip
        content={translate('Multilingual_Mode_LangMenu_Activate_Tooltip', {
          Lang_Name: languageName,
        })}
        shouldTranslate={false}
        disabled={published || isPrimary}
      >
        <div className="languageRowTextContainer">
          <div data-aid={`my-site-languages-panel-option-language-${index}`}>
            {languageName}
          </div>
          <div className="languageRowSubtitle">{translate(subtitle)}</div>
        </div>
      </Tooltip>
    </div>
  );
};

export default LanguageRowMid;

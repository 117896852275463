import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { HistoryApi } from './api';
import { HistoryApiKey } from './publicApiKey';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const HistoryEntrypoint = createEntryPoint({
  name: 'HistoryEntrypoint',
  publicApi({ contributeApi }) {
    contributeApi(HistoryApiKey, HistoryApi);
  },
  Scope,
  initialize() {},
});

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const PROGALLERY_WIDGET_ID = '142bb34d-3439-576a-7118-683e690a1e0d';
const RICOS_WIDGET_ID = 'aa86b56d-3c49-44fd-9976-963695e7815d';

const connectableTpaWidgetIds = [PROGALLERY_WIDGET_ID, RICOS_WIDGET_ID];

function isNotWidgetPluginComponent(editorAPI: EditorAPI, compRef: CompRef) {
  return !editorAPI.platform.widgetPlugins.isWidgetPluginComponent(compRef);
}

export default {
  customizeTranslate: true,
  allowConnectToDB(editorAPI: EditorAPI, compRef: CompRef) {
    const { widgetId } = editorAPI.components.data.get(compRef) || {};
    return connectableTpaWidgetIds.includes(widgetId);
  },
  applyMetaDataToFirstChild: {
    controlledByParent: true,
  },
  stretchable: isNotWidgetPluginComponent,
  verticallyMovable: isNotWidgetPluginComponent,
  horizontallyMovable: isNotWidgetPluginComponent,
  verticallyResizable: isNotWidgetPluginComponent,
  horizontallyResizable: isNotWidgetPluginComponent,
  duplicatable: isNotWidgetPluginComponent,
};

// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import * as mixins from '#packages/mixins';
import React from 'react';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'superAppAddElementsPanel',
  mixins: [core.mixins.editorAPIMixin, mixins.dragToStageMixin],
  getInitialState() {
    return {
      firstTimeInfoBoxHeight: 0,
    };
  },
  propTypes: {
    superApp: PropTypes.object.isRequired,
  },
  isMobile() {
    return (
      this.getEditorAPI().dsRead.viewMode.get() ===
      this.getEditorAPI().dsRead.viewMode.VIEW_MODES.MOBILE
    );
  },
  needToNotifyInnerNavigationToCalculateSections(height) {
    this.setState({ firstTimeInfoBoxHeight: height });
  },
  getSections() {
    // TODO: [ImplementForNewAddPanelAPI] logic here for newAddPanelAPI cc: vladislavz@wix.com
    const editorAPI = this.getEditorAPI();

    let { sections } = this.props.superApp.panel.addElements;

    if (_.isFunction(sections)) {
      sections = sections(editorAPI);
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (_.isUndefined(sections)) {
      // TODO: [ImplementForNewAddPanelAPI]
      sections = editorAPI.addPanel.getCategory(
        //here
        this.props.superApp.panel.addElements.categoryId,
      ).items;

      sections = sections[0].sections;
    }

    //create full deep copy of sections if they not equal to sections in previous render
    if (!_.isEqual(sections, this.lastComputedSectionItems)) {
      this.lastComputedSectionItems = _.cloneDeep(sections);
    }

    return this.lastComputedSectionItems;
  },
  getTeaser() {
    let { teaser } = this.props.superApp.panel.addElements;
    if (_.isFunction(teaser)) {
      teaser = teaser(this.getEditorAPI());
    }
    return teaser;
  },
  getCategoryViewProps() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, this.props, {
      changeStateOnInnerNavigationOpen: this.changeStateOnInnerNavigationOpen,
      firstTimeInfoBoxHeight: this.state.firstTimeInfoBoxHeight,
      onItemDrag: this.startItemDrag,
    });
  },
  changeStateOnInnerNavigationOpen(newState) {
    this.props.onChangeStateOnInnerNavigationOpen(newState);
  },
  goToDesktop() {
    this.getEditorAPI().setDesktopEditor();
  },
  render() {
    const data = this.props.superApp.panel.addElements;
    const isMobile = this.isMobile();
    const CategoryView = this.getEditorAPI().addPanelInfra.categoryView;

    return (
      <div className="super-app-add-elements-panel category-view-wrapper">
        {!isMobile ? (
          <CategoryView
            key="categoryView"
            teaser={this.getTeaser()}
            origin="vertical_button"
            items={this.getSections()}
            {...this.getCategoryViewProps()}
          />
        ) : null}

        {isMobile ? (
          <div key="mobileView" className="super-app-mobile-view">
            <symbols.symbol
              name={this.props.superApp.panel.addElements.mobileImage}
            />

            <p className="bigger-text">
              {translate(data.disabledInMobileMessageTitle)}
            </p>
            <p>
              {translate(data.disabledInMobileMessageDescription)}{' '}
              <a onClick={this.goToDesktop}>
                {translate(data.disabledInMobileMessageDescriptionLink)}
              </a>
            </p>
          </div>
        ) : null}
      </div>
    );
  },
});

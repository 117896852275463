import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { createMediaServicesApi } from './api';
import { MediaServicesApiKey } from './publicApiKey';

export const MediaServicesEntrypoint: EntryPoint = {
  name: 'MediaServicesApi',
  declareAPIs: () => [MediaServicesApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(MediaServicesApiKey, () =>
      createMediaServicesApi(shell),
    );
  },
};

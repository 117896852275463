import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { createColumnsApi } from './columnsWrapper';
import { ColumnsApiKey } from './publicApiKey';
import { SectionsApiKey } from '#packages/apis';

export const ColumnsEntrypoint: EntryPoint = {
  name: 'ColumnsApi',
  declareAPIs: () => [ColumnsApiKey],
  getDependencyAPIs: () => [EditorAPIKey, SectionsApiKey],
  attach(shell) {
    shell.contributeAPI(ColumnsApiKey, () => createColumnsApi(shell));
  },
};

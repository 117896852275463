import React, { useEffect, useState } from 'react';
import { cx, SafeInjectHtml } from '#packages/util';

import type HtmlPreviewThumbnailManager from '../../htmlPreviewThumbnail/htmlPreviewThumbnailManager';

import styles from './RawThumbnail.scss';

interface RawHtmlPreviewThumbnailProps {
  previewHtmlManager: HtmlPreviewThumbnailManager;
  originalPresetWidth: number;
  displayWidth: number;
  onPreviewReady?: () => void;
}

export const RawHtmlPreviewThumbnail: React.FC<
  RawHtmlPreviewThumbnailProps
> = ({
  previewHtmlManager,
  originalPresetWidth,
  displayWidth,
  onPreviewReady,
}) => {
  const [contentHTML, setContentHTML] = useState(undefined);
  const isLoading = !contentHTML;
  const scale = displayWidth / originalPresetWidth;

  useEffect(() => {
    const htmlPreviewPromise = Promise.resolve(
      previewHtmlManager.previewHtml || previewHtmlManager.previewHtmlPromise,
    );

    htmlPreviewPromise.then((value) => {
      setContentHTML(value);
      onPreviewReady?.();
    });
    // eslint-disable-next-line
  }, [previewHtmlManager]);

  return (
    <div
      className={cx(styles.wrapper, {
        [styles.loading]: isLoading,
      })}
    >
      <SafeInjectHtml
        tag="div"
        className={styles.preview}
        style={{ transform: `scale(${scale})` }}
        html={contentHTML}
      />
    </div>
  );
};

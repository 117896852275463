import type { Shell } from '#packages/apilib';
import type { EntryPoint } from '#packages/apilib';
import { createExperimentsApi } from './api';
import { ExperimentsApiKey } from './publicApiKey';

export const ExperimentsEntrypoint: EntryPoint = {
  name: 'ExperimentsApi',
  declareAPIs: () => [ExperimentsApiKey],
  attach(shell: Shell) {
    shell.contributeAPI(ExperimentsApiKey, () => createExperimentsApi());
  },
};

import constants from '#packages/constants';
import { panels, userPreferences } from '#packages/stateManagement';
import * as util from '#packages/util';
import { WIX_ACCESSIBILITY_WIZARD } from '@wix/app-definition-ids';
import { site as siteUserPreferences } from '../userPreferences/userPreferences';
import { WorkspaceRightPanelApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';

const EDITOR_APP_ID = 'editorAppId';
const ACCESSIBILITY_WIZARD_ORIGIN = 'accessibility_wizard';

const { ADVANCED_SETTINGS } = constants.USER_PREFS.ACCESSIBILITY;

export function setAdvancedSettings(
  editorAPI: EditorAPI,
  appData: AnyFixMe,
  token: AnyFixMe,
  { value = false },
) {
  const areSitePrefsLoaded = userPreferences.selectors.getSitePrefFetchStatus(
    editorAPI.store.getState(),
  );
  if (!areSitePrefsLoaded) {
    return Promise.reject(new Error('Site preferences are not loaded'));
  }
  return siteUserPreferences.set(
    editorAPI,
    { appDefinitionId: EDITOR_APP_ID },
    token,
    {
      [ADVANCED_SETTINGS]: value,
    },
  );
}

export function getAdvancedSettings(
  editorAPI: AnyFixMe,
  appData: AnyFixMe,
  token: AnyFixMe,
) {
  const areSitePrefsLoaded = userPreferences.selectors.getSitePrefFetchStatus(
    editorAPI.store.getState(),
  );
  if (!areSitePrefsLoaded) {
    return Promise.reject(new Error('Site preferences are not loaded'));
  }
  return siteUserPreferences
    .get(editorAPI, { appDefinitionId: EDITOR_APP_ID }, token, [
      ADVANCED_SETTINGS,
    ])
    .then((results) => (results as AnyFixMe)[ADVANCED_SETTINGS]);
}

export interface AccessibilityWizardApi {
  openSidePanel: ({
    editorQuery,
  }: {
    editorQuery: Record<string, string>;
  }) => void;
}

const CANCELLED_BY_TIMEOUT_ERROR_MESSAGE =
  'requestAppPublicApi cancelled by timeout';
const API_NOT_FOUND_ERROR_MESSAGE = 'apiNotFoundError';
const CHECK_INTERVAL_SEC = 1000;
const MAX_ATTEMPTS_FOR_REQUEST = 3;

const isAccessibilityApi = (
  potentialAPI: GetAccessibilityWizardApiResponse,
): potentialAPI is AccessibilityWizardApi =>
  Boolean((potentialAPI as AccessibilityWizardApi)?.openSidePanel);

type GetAccessibilityWizardApiResponse = AccessibilityWizardApi | null | {};

/**
 * We are going to call editorAPI.dsRead.platform.getAppPublicApi recursively.
 * When using a11y wizard deeplink, we try to open it before it was actually loaded.
 * Because of this, editorAPI.dsRead.platform.getAppPublicApi can fail.
 * We will try again in CHECK_INTERVAL_SEC seconds.
 */
const requestAppPublicApi = async (
  editorAPI: EditorAPI,
  appDefinitionId: string,
) => {
  const requestRecursively = (
    attempt: number = 1,
  ): Promise<AccessibilityWizardApi> =>
    Promise.race([
      editorAPI.dsRead.platform.getAppPublicApi(
        appDefinitionId,
      ) as GetAccessibilityWizardApiResponse,
      /**
       * Sometimes editorAPI.dsRead.platform.getAppPublicApi doesn't respond at all.
       * For such cases, we try again in CHECK_INTERVAL_SEC seconds
       */
      util.promiseUtils.waitFor(CHECK_INTERVAL_SEC).then(() => {
        throw new Error(CANCELLED_BY_TIMEOUT_ERROR_MESSAGE);
      }),
    ])
      .then((response) => {
        if (isAccessibilityApi(response)) {
          return response;
        }

        throw new Error(API_NOT_FOUND_ERROR_MESSAGE);
      })
      .catch((error) => {
        const isExpectedError =
          error.message === CANCELLED_BY_TIMEOUT_ERROR_MESSAGE ||
          error.message === API_NOT_FOUND_ERROR_MESSAGE ||
          error.message === `App with ID ${appDefinitionId} not found`;
        const hasMoreAttempts = attempt < MAX_ATTEMPTS_FOR_REQUEST;

        const shouldTryRequestingAPIAgain = hasMoreAttempts && isExpectedError;

        if (shouldTryRequestingAPIAgain) {
          return util.promiseUtils
            .waitFor(CHECK_INTERVAL_SEC)
            .then(() => requestRecursively(attempt + 1));
        }

        throw error;
      });

  return requestRecursively();
};

export const openAccessibilityWizard = async (editorAPI: EditorAPI) => {
  const workspaceRightPanelApi = editorAPI.host.getAPI(
    WorkspaceRightPanelApiKey,
  );

  const isSidePanelOpened = panels.selectors.isSidePanelOpenedWithAppDefId(
    editorAPI.store.getState(),
    WIX_ACCESSIBILITY_WIZARD,
  );

  if (isSidePanelOpened) {
    return;
  }

  if (workspaceRightPanelApi.isOpen()) {
    workspaceRightPanelApi.close(ACCESSIBILITY_WIZARD_ORIGIN);
  }

  editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'a11y_wizard' } });

  if (!editorAPI.dsRead.platform.isAppActive(WIX_ACCESSIBILITY_WIZARD)) {
    await editorAPI.tpa.installAppIfNeeded(WIX_ACCESSIBILITY_WIZARD);
  }

  const publicApi = await requestAppPublicApi(
    editorAPI,
    WIX_ACCESSIBILITY_WIZARD,
  );

  const editorQuery = util.url.parseUrl(window.document.location.href).query;
  publicApi.openSidePanel({ editorQuery });
};

// @ts-nocheck
import _ from 'lodash';
import * as styles from '#packages/styles';
import utils from '../../utils/multiComponentsUtils';

const advancedStyleConstants =
  styles.advancedStyle.advancedStyleConstants.multiComponents;
const getMultiComponentAdapter = (editorAPI, components, groupMapping) => {
  const getCompSkin = (comp) => editorAPI.components.skin.get(comp);
  const getCompStyle = (comp) => editorAPI.components.style.get(comp);
  const getSkinDef = (skin) => editorAPI.theme.skins.getSkinDefinition(skin);

  const calcCommonStyle = (styleByComp, skinDefByComp, designMapping) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const groups = _.keys(groupMapping.groups);

    const getCommonGroupValue = (g, acc, prefix = '') => {
      const paramsValuesFrequency = utils.getParamsValuesFrequency(
        g,
        prefix,
        styleByComp,
        skinDefByComp,
        designMapping,
      );
      if (!_.isEmpty(paramsValuesFrequency)) {
        acc[`${prefix}${g}`] = utils.transformToNumberIfNecessary(
          groupMapping,
          g,
          utils.maxInFrequency(paramsValuesFrequency),
        );
      }
      return acc;
    };

    return groups.reduce((acc, g) => {
      acc = getCommonGroupValue(g, acc);
      if (utils.groupHasAlpha(groupMapping.groups[g])) {
        acc = _.merge(
          acc,
          getCommonGroupValue(g, acc, advancedStyleConstants.ALPHA_PREFIX),
        );
      }
      if (utils.groupHasShadow(groupMapping.groups[g])) {
        acc = _.merge(
          acc,
          getCommonGroupValue(g, acc, advancedStyleConstants.SHADOW_PREFIX),
        );
      }
      return acc;
    }, {});
  };

  const onStyleParamChanged = (designMapping, key, val, source) => {
    const { prefix, groupKey } = utils.getPrefixAndGroup(key);
    const mappings = components.reduce((acc, component) => {
      acc[component.id] = [
        { mapping: designMapping[component.id][groupKey], val, source, prefix },
      ];
      return acc;
    }, {});
    return createStyleUpdates(mappings);
  };

  const onStyleParamsChanged = (designMapping, keysToUpdates, newStyle) => {
    const groupsAndPrefix = keysToUpdates.map(utils.getPrefixAndGroup);
    const mappings = components.reduce((acc, component) => {
      acc[component.id] = groupsAndPrefix.map(({ groupKey, prefix }) => ({
        mapping: designMapping[component.id][groupKey],
        val: newStyle.style.properties[`${prefix}${groupKey}`],
        prefix,
      }));
      return acc;
    }, {});
    return createStyleUpdates(mappings);
  };

  const createStyleUpdates = (mappings) =>
    components.reduce((arr, component) => {
      const compStyle = getCompStyle(component);
      const adaptedStyle = utils.adapterTransformation(
        mappings[component.id],
        compStyle,
      );
      if (adaptedStyle) {
        arr = arr.concat([{ style: adaptedStyle, componentRef: component }]);
      }
      return arr;
    }, []);

  const updateStyleForComponents = (updates) =>
    updates.forEach(({ componentRef, style }) =>
      editorAPI.components.style.update(componentRef, style),
    );

  return {
    getType() {
      return advancedStyleConstants.compType;
    },
    getSkin() {
      return advancedStyleConstants.skinName;
    },
    getSkinDefinition(styleGroups) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
      const groupsWithTranslationProp = _.reduce(
        groupMapping.groups,
        (acc, groupDef, group) => {
          acc[group] = groupDef.label
            ? _.merge(groupDef, { shouldTranslate: false })
            : groupDef;
          return acc;
        },
        {},
      );
      const existingGroups = _.pick(groupsWithTranslationProp, styleGroups);

      return {
        params: existingGroups,
        compParts: groupMapping.compParts,
        sections: groupMapping.sections,
      };
    },
    getStyle(styleByComp, skinDefByComp, designMapping) {
      return {
        style: {
          properties: calcCommonStyle(
            styleByComp,
            skinDefByComp,
            designMapping,
          ),
        },
      };
    },
    onStyleParamChanged(designMapping, key, val, source) {
      return new Promise((resolve) =>
        editorAPI.waitForChangesApplied(() => {
          const updates = onStyleParamChanged(designMapping, key, val, source);
          updateStyleForComponents(updates);
          resolve();
        }),
      );
    },
    updateStyle(designMapping, currentStyle, newStyle) {
      return new Promise((resolve) =>
        editorAPI.waitForChangesApplied(() => {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/keys
          const styleParamsToUpdate = _(newStyle.style.properties)
            .keys()
            .filter(
              (key) =>
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line you-dont-need-lodash-underscore/starts-with
                _.startsWith(key, advancedStyleConstants.SHADOW_PREFIX) ||
                newStyle.style.properties[key] !==
                  currentStyle.style.properties[key],
            )
            .value();
          const updates = onStyleParamsChanged(
            designMapping,
            styleParamsToUpdate,
            newStyle,
          );
          updateStyleForComponents(updates);
          resolve();
        }),
      );
    },
    getStyleByComp() {
      return components.reduce((acc, comp) => {
        acc[comp.id] = getCompStyle(comp)?.style?.properties ?? {};
        return acc;
      }, {});
    },
    getSkinDefByComp() {
      return components.reduce((acc, comp) => {
        const skinDef = getSkinDef(getCompSkin(comp));
        acc[comp.id] = skinDef;
        return acc;
      }, {});
    },
  };
};

export default getMultiComponentAdapter;

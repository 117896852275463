import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorAPIKey, SectionsMigrationApiKey } from '#packages/apis';

import { SectionizerApiKey } from './publicApiKey';
import { SectionizerPublicApi } from './api';

export class SectionizerScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  sectionsMigrationApi = this.useDependency(SectionsMigrationApiKey);
}

export const SectionizerEntryPoint = createEntryPoint({
  name: 'Sectionizer',
  Scope: SectionizerScope,
  initialize() {},
  publicApi({ contributeApi }) {
    contributeApi(SectionizerApiKey, SectionizerPublicApi);
  },
});

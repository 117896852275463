import _ from 'lodash';
import constants from '#packages/constants';
import { performance } from '#packages/util';
import * as utils from '@wix/santa-editor-utils';
import * as coreBi from '#packages/coreBi';
import type { FrameMeasuringScope } from './measureFrameRateEntrypoint';

type EditorEvents = (typeof import('@/coreBi'))['events']['editor'];

const { animationFrameUtils } = utils;

const includedActions = [
  constants.MOUSE_ACTION_TYPES.DRAG,
  constants.MOUSE_ACTION_TYPES.RESIZE,
  constants.MOUSE_ACTION_TYPES.SECTION_DRAG,
  constants.MOUSE_ACTION_TYPES.ROTATE,
  constants.MOUSE_ACTION_TYPES.DRAG_PUSH,
  constants.MOUSE_ACTION_TYPES.RESIZE_PUSH,
];

type ActionType = ValueOf<typeof constants.MOUSE_ACTION_TYPES>;

interface MeasuringState {
  isMeasuring: boolean;
  actionType: ActionType | null;
  frameCounter: number;
  totalDragDistance: number;
  prevMousePosition: { x: number; y: number } | null;
  prevViewerRenderCounter: number;
  start: number | null;
  componentType: string;
  results: number[];
}

const getInitialMeasuringState = (): MeasuringState => ({
  isMeasuring: false,
  actionType: null,
  prevMousePosition: null,
  frameCounter: 0,
  totalDragDistance: 0,
  prevViewerRenderCounter: 0,
  start: null,
  results: [],
  componentType: '',
});

const getActionEvent = (actionType: ActionType, editorEvents: EditorEvents) => {
  switch (actionType) {
    case constants.MOUSE_ACTION_TYPES.DRAG:
      return editorEvents.FRAME_RATE_DRAG;
    case constants.MOUSE_ACTION_TYPES.RESIZE:
      return editorEvents.FRAME_RATE_RESIZE;
    case constants.MOUSE_ACTION_TYPES.SECTION_DRAG:
      return editorEvents.FRAME_RATE_SECTION_DRAG;
    case constants.MOUSE_ACTION_TYPES.ROTATE:
      return editorEvents.FRAME_RATE_ROTATE;
    case constants.MOUSE_ACTION_TYPES.DRAG_PUSH:
      return editorEvents.FRAME_RATE_DRAG_PUSH;
    case constants.MOUSE_ACTION_TYPES.RESIZE_PUSH:
      return editorEvents.FRAME_RATE_RESIZE_PUSH;
  }
};

const averageAndReport = (
  scope: FrameMeasuringScope,
  measuringState: MeasuringState,
  reportFrameRate: AnyFixMe,
  editorEvents: EditorEvents,
  FRAMES_COUNT_THRESHOLD: number,
  duration: number,
) => {
  const updatedState = closeMeasure(measuringState, FRAMES_COUNT_THRESHOLD);
  if (updatedState.results.length) {
    const avgFps = parseInt(
      (_.sum(updatedState.results) /
        updatedState.results.length) as unknown as string,
      10,
    );
    reportFps(
      scope,
      avgFps,
      updatedState,
      reportFrameRate,
      editorEvents,
      duration,
    );
  }

  return updatedState;
};

const reportFps = (
  scope: FrameMeasuringScope,
  fps: number,
  measuringState: MeasuringState,
  reportFrameRate: AnyFixMe,
  editorEvents: EditorEvents,
  duration: number,
) => {
  const actionEvent = getActionEvent(measuringState.actionType, editorEvents);

  if (actionEvent && fps > 0) {
    const componentBiCommonFields =
      'component_id' in actionEvent.fields
        ? scope.editorAPI.bi.getComponentsBIParams(
            scope.editorAPI.selection.getSelectedComponents(),
          )[0]
        : {};

    const reportAction = reportFrameRate(
      actionEvent,
      Object.assign(
        {
          frames_per_second: fps,
          duration,
          pageContext: scope.store.getPageContext(),
          totalJSHeapSize: (performance as any).memory?.totalJSHeapSize,
          usedJSHeapSize: (performance as any).memory?.usedJSHeapSize,
        },
        componentBiCommonFields,
        'component_type' in actionEvent.fields
          ? {
              component_type: measuringState.componentType,
            }
          : {},
        'devMode' in actionEvent.fields
          ? {
              devMode: scope.editorAPI.developerMode.isEnabled() ? 'on' : 'off',
            }
          : {},
        'distance' in actionEvent.fields
          ? {
              distance: measuringState.totalDragDistance,
            }
          : {},
      ),
    );
    scope.editorAPI.store.dispatch(reportAction);
  }
};

const getFpsByState = (state: MeasuringState) => {
  const endTime = performance.now();
  return (1000 * state.frameCounter) / (endTime - state.start);
};

const clearMeasure = (prevState: MeasuringState): MeasuringState => ({
  ...prevState,
  start: null,
  frameCounter: 0,
});

const closeMeasure = (
  prevState: MeasuringState,
  FRAMES_COUNT_THRESHOLD: number,
): MeasuringState => {
  if (!prevState.start) {
    return prevState;
  }
  const results =
    prevState.frameCounter >= FRAMES_COUNT_THRESHOLD
      ? prevState.results.concat(getFpsByState(prevState))
      : prevState.results;

  return {
    ...clearMeasure(prevState),
    results,
  };
};

const measureFrame = (actionType: ActionType, prevState: MeasuringState) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (actionType && _.includes(includedActions, actionType)) {
    return _.defaults(
      {
        actionType,
        isMeasuring: true,
        frameCounter: prevState.frameCounter + 1,
        start: prevState.start || performance.now(),
      },
      prevState,
    );
  }

  return {};
};

export const measureAndReportFrameRate = (
  scope: FrameMeasuringScope,
  getViewerRendersCounter: AnyFixMe,
  isPerformingMouseMoveAction: AnyFixMe,
  reportFrameRate: AnyFixMe,
  FRAMES_COUNT_THRESHOLD: number,
) => {
  const editorEvents = coreBi.events.editor;
  const getRegisteredMouseMoveAction =
    scope.editorAPI.mouseActions.getRegisteredMouseMoveAction;

  const measuringState = getInitialMeasuringState();

  const updateMeasuringState = (newState: Partial<MeasuringState>) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(measuringState, newState);
  };

  scope.baseDragApi.hooks.drag.tap(({ position }) => {
    const { prevMousePosition } = measuringState;
    if (prevMousePosition) {
      const delta = Math.round(
        Math.sqrt(
          Math.pow(prevMousePosition.y - position.y, 2) +
            Math.pow(prevMousePosition.x - position.x, 2),
        ),
      );

      const newTotalDragDistance = measuringState.totalDragDistance + delta;
      updateMeasuringState({
        totalDragDistance: newTotalDragDistance,
      });
    }
    updateMeasuringState({
      prevMousePosition: position,
    });
  });

  let dragStartTime: AnyFixMe;

  const onAnimationFrame = () => {
    animationFrameUtils.request(onAnimationFrame);
    const editorState = scope.editorAPI.store.getState();

    const performingMouseMoveAction = isPerformingMouseMoveAction(editorState);

    const viewerRendersCounter = getViewerRendersCounter(editorState);
    if (!performingMouseMoveAction && !measuringState.isMeasuring) {
      return;
    }

    if (!dragStartTime) {
      dragStartTime = performance.now();
    }

    const viewerRendered =
      viewerRendersCounter !== measuringState.prevViewerRenderCounter;
    updateMeasuringState({
      prevViewerRenderCounter: viewerRendersCounter,
      componentType: scope.editorAPI.components.getType(
        scope.editorAPI.selection.getSelectedComponents(),
      ),
    });

    if (performingMouseMoveAction) {
      if (viewerRendered) {
        updateMeasuringState(
          measureFrame(getRegisteredMouseMoveAction().type, measuringState),
        );
      } else {
        updateMeasuringState(
          closeMeasure(measuringState, FRAMES_COUNT_THRESHOLD),
        );
      }
    } else {
      const duration = Math.round(performance.now() - dragStartTime);
      dragStartTime = null;

      averageAndReport(
        scope,
        measuringState,
        reportFrameRate,
        editorEvents,
        FRAMES_COUNT_THRESHOLD,
        duration,
      );
      updateMeasuringState(getInitialMeasuringState());
    }
  };

  onAnimationFrame();
};

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';

/*
 TODO: memoization making it work much faster. data !!!won't!!! be true after changes.
 TODO: Avi Marcus working on making DAL faster. try the original functions after his change.
 function isTPASectionSubPage (pageId) {
 pageId = pageId || this.props.pageId;
 return core.editorAPI.pages.isPageContainsTPAMultiSectionComp(pageId);
 }

 function canUnhide(pageId){
 pageId = pageId || this.props.pageId;
 var pageData = core.editorAPI.dsRead.pages.data.get(pageId);
 return pageData.hidePage && !isTPASectionSubPage(pageId);
 }

 function showRemoveButton(pageId) {
 pageId = pageId || this.props.pageId;
 var notHomePage = core.editorAPI.homePage.get() !== pageId;
 return notHomePage && !isTPASectionSubPage(pageId);
 }

 function showDuplicateButton(pageId) {
 pageId = pageId || this.props.pageId;
 return core.editorAPI.pages.isDuplicable(pageId);
 }
 */

function isTPASectionSubPage(editorAPI, pageId) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(isTPASectionSubPage.cache[pageId])) {
    isTPASectionSubPage.cache[pageId] =
      editorAPI.dsRead.pages.isPageContainsTPAMultiSectionComp(pageId);
  }
  return isTPASectionSubPage.cache[pageId];
}

isTPASectionSubPage.cache = {};

function canRemove(editorAPI, pageId) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(canRemove.cache[pageId])) {
    canRemove.cache[pageId] = !isTPASectionSubPage(editorAPI, pageId);
  }
  return canRemove.cache[pageId];
}

canRemove.cache = {};

function canDuplicate(editorAPI, pageId) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(canDuplicate.cache[pageId])) {
    canDuplicate.cache[pageId] = editorAPI.pages.isDuplicable(pageId);
  }
  return canDuplicate.cache[pageId];
}

canDuplicate.cache = {};

function convertPageNameToUrl(title) {
  const dash = '-';
  const pageUrlWithoutSpaces = title.replace(/\s/g, dash);
  const validator = util.validate.byInvalidCharacters(
    util.validationExpressions.invalidUrlCharacters,
  );

  return validator(pageUrlWithoutSpaces)
    ? pageUrlWithoutSpaces.toLowerCase()
    : 'blank';
}

export { canRemove, canDuplicate, isTPASectionSubPage, convertPageNameToUrl };

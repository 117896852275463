import { fedopsLogger } from '#packages/util';
import {
  pinModeActions,
  userPreferencesSelectors,
  userPreferencesActions,
} from '#packages/stateManagement';
import constants from '#packages/constants';
import {
  calcPinDockingDirectionForNotPined,
  calcPinDockingOffsetsDefaults,
} from '#packages/pinModeUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

async function pin(
  editorAPI: EditorAPI,
  {
    selectedComponents,
    biOrigin,
  }: {
    selectedComponents: CompRef[];
    biOrigin: string;
  },
) {
  fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.AUTO_PIN_TO_SCREEN);

  const compRef = selectedComponents[0];
  const dockingDirection = calcPinDockingDirectionForNotPined(
    editorAPI,
    compRef,
  );
  const dockingOffsets = calcPinDockingOffsetsDefaults(dockingDirection);

  await editorAPI.components.layout.pinToDirection(compRef, {
    dockingDirection,
    dockingOffsets,
    biOrigin,
  });

  await editorAPI.waitForChangesAppliedAsync();

  fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.AUTO_PIN_TO_SCREEN);

  editorAPI.history.add('Auto pinned to screen');

  const pinToScreenCount =
    ((userPreferencesSelectors.getSessionUserPreferences(
      constants.USER_PREFS.PIN_TO_SCREEN.PIN_COUNT,
    )(editorAPI.store.getState()) as number) || -1) + 1; //todo verify calc

  editorAPI.store.dispatch(
    userPreferencesActions.setSessionUserPreferences(
      constants.USER_PREFS.PIN_TO_SCREEN.PIN_COUNT,
      pinToScreenCount,
    ),
  );

  editorAPI.pinMode.checkSizeRestrictionsAndShowNotification(biOrigin);
}

export function handlePinClick(
  editorAPI: EditorAPI,
  {
    selectedComponents,
    biOrigin,
  }: {
    selectedComponents: CompRef[];
    biOrigin: string;
  },
) {
  const isPinned = editorAPI.components.layout.isPinned(selectedComponents);

  editorAPI.closeRightClickMenu();

  if (!isPinned) {
    pin(editorAPI, { selectedComponents, biOrigin });
  }

  editorAPI.waitForChangesApplied(() => {
    editorAPI.store.dispatch(
      pinModeActions.open(isPinned ? 'rcm_reopen' : 'rcm'),
    );
  });
}

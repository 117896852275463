import { translate } from '#packages/i18n';

export const BUSINESS_TYPE_PANEL_ORIGIN = 'ai_writer_panel';
export const PANEL_TYPE = 'AI Page Writer';
export const ERROR_STATE_PANEL_PATH = 'panels.focusPanels.aiWriterErrorPanel';
export const AI_PAGE_WRITER_CONTEXT = 'AI_PAGE_WRITER_CONTEXT';
export const AI_PAGE_WRITER_ORIGIN = 'ai_page_writer';
export const MAX_DESCRIPTION_LENGTH = 300;
export const MAX_TONE_LENGTH = 30;
export const TONE_OF_VOICE = [
  {
    label: translate('ai_text_to_page_panel_tone_type_professional'),
    id: 'ai_text_to_page_panel_tone_type_professional',
  },
  {
    label: translate('ai_text_to_page_panel_tone_type_enthusiastic'),
    id: 'ai_text_to_page_panel_tone_type_enthusiastic',
  },
  {
    label: translate('ai_text_to_page_panel_tone_type_casual'),
    id: 'ai_text_to_page_panel_tone_type_casual',
  },
  {
    label: translate('ai_text_to_page_panel_tone_type_confident'),
    id: 'ai_text_to_page_panel_tone_type_confident',
  },
  {
    label: translate('ai_text_to_page_panel_tone_type_funny'),
    id: 'ai_text_to_page_panel_tone_type_funny',
  },
];
export const AI_WRITER_SESSION_KEY = 'aiWriterSessionValues';

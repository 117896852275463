import _ from 'lodash';
import React from 'react';
import { hoc, snapToUtils } from '#packages/util';
import type { AlignmentLine, SnapData, SnapTextLabel } from '#packages/util';
import type { StateMapperArgs } from 'types/redux';

const textLabelWidth = 16;
const textLabelHeight = 13;

interface SnapLayerProps {
  snapData: SnapData[];
}

class SnapLayer extends React.Component<SnapLayerProps> {
  static displayName = 'snapLayer';

  getRectanglesDrawData = () => {
    const rectanglesDrawData = _.flatMap(
      this.props.snapData,
      'rectanglesDrawData',
    );

    return rectanglesDrawData;
  };

  getLines = () => {
    return this.props.snapData
      .filter(({ type }) => type === 'alignmentLine')
      .map((alignmentLine) => (alignmentLine as AlignmentLine).line)
      .filter(Boolean);
  };

  renderLine = (alignmentLine: AlignmentLine, index: number) => {
    const lineData = alignmentLine?.line;

    if (!lineData) return null;

    return (
      <line
        data-aid="snap-to-line"
        x1={lineData[0].x}
        y1={lineData[0].y}
        x2={lineData[1].x}
        y2={lineData[1].y}
        key={`snapLine${index}`}
        style={alignmentLine.style}
      />
    );
  };

  renderRectangles = (alignmentLine: AlignmentLine, index: number) => {
    const rectangles = alignmentLine.rectanglesDrawData.map((rect, i) => (
      <rect
        key={`rectDrawData${i}`}
        x={rect.x}
        y={rect.y}
        height={rect.height}
        width={rect.width}
        style={rect.style}
      />
    ));

    return (
      <React.Fragment key={`snap-data-${index}`}>{rectangles}</React.Fragment>
    );
  };

  renderTextLabel = (snapData: SnapTextLabel, index: number) => {
    return (
      <g
        x={String(snapData.position.x)}
        y={String(snapData.position.y)}
        key={`textLabel-${index}`}
      >
        <rect
          x={String(snapData.position.x - textLabelWidth / 2)}
          y={String(snapData.position.y - textLabelHeight / 2 - 1)}
          rx="2"
          ry="2"
          width={textLabelWidth}
          height={textLabelHeight}
          fill="#ff00ff"
          className="textbox"
        />
        <text
          x={String(snapData.position.x)}
          y={String(snapData.position.y)}
          fontWeight="bold"
          dominantBaseline="middle"
          textAnchor="middle"
        >
          {snapData.value}
        </text>
      </g>
    );
  };

  renderSnapData = (snapData: SnapData, index: number) => {
    if (snapData.type === 'alignmentLine') {
      return (
        <React.Fragment key={`snap-data-${index}`}>
          {this.renderRectangles(snapData, index)}
          {this.renderLine(snapData, index)}
        </React.Fragment>
      );
    }

    if (snapData.type === 'textLabel') {
      return this.renderTextLabel(snapData, index);
    }

    return null;
  };

  render() {
    if (snapToUtils.isNewStageGuidesEnabled() && this.props.snapData) {
      return (
        <div key="layer" className="snap-layer">
          <svg>
            {this.props.snapData.map((data, i) => this.renderSnapData(data, i))}
          </svg>
        </div>
      );
    }
    return this.props.snapData ? (
      <div key="layer" className="snap-layer">
        <svg>
          {this.getRectanglesDrawData().map(
            (rectDrawData, rectDrawDataIndex) => (
              <rect
                key={`rectDrawData${rectDrawDataIndex}`}
                x={rectDrawData.x}
                y={rectDrawData.y}
                height={rectDrawData.height}
                width={rectDrawData.width}
                style={rectDrawData.style}
              />
            ),
          )}
          {this.getLines().map((snapLine, snapLineIndex) => (
            <line
              key={`snapLine${snapLineIndex}`}
              data-aid="snap-to-line"
              x1={snapLine[0].x}
              y1={snapLine[0].y}
              x2={snapLine[1].x}
              y2={snapLine[1].y}
            />
          ))}
        </svg>
      </div>
    ) : null;
  }
}

const mapStateToProps = ({ state }: StateMapperArgs) => {
  return { snapData: state.snapData };
};

export default hoc.connect(hoc.STORES.MOUSE_OPS, mapStateToProps)(SnapLayer);

import type { EditorAPI } from '#packages/editorAPI';
import type { ThunkAction, Dispatch } from 'types/redux';

const getEditorAPI: ThunkAction<EditorAPI> = (
  _dispatch,
  _getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openHelpCenter(helpItem: string, props: AnyFixMe, biHelpParams: AnyFixMe) {
      editorAPI.panelManager.openHelpCenter(helpItem, props, biHelpParams);
    },
    createDefaultData(id: string) {
      return editorAPI.dsRead.data.createItem(id);
    },
  };
};

import { editorModel } from '#packages/util';

import { MIGRATION_FLOWS } from './constants';
import { initAfterSiteCreationDone } from './api/runAfterSiteCreation';
import { initAfterSectionMigrationDone } from './api/runAfterSectionsMigration';

import type { Scope } from './scope';
import type { MigrationFlow } from './types';

function resolveMigrationFlow(scope: Scope): MigrationFlow | undefined {
  const { isInsideEditorX, isInsideAppStudio } = scope.editorParamsAPI;

  if (isInsideEditorX || isInsideAppStudio || editorModel.isImpersonated) {
    return;
  }

  if (window.siteCreationController) return MIGRATION_FLOWS.SITE_CREATION;
  return MIGRATION_FLOWS.SECTIONS_MIGRATION;
}

export async function initMigration(scope: Scope) {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  const migrationFlow = resolveMigrationFlow(scope);

  if (!migrationFlow) {
    scope.migrationHook.resolve();
    return;
  }

  scope.migrationService.setFlow(migrationFlow);

  if (migrationFlow === MIGRATION_FLOWS.SITE_CREATION) {
    await initAfterSiteCreationDone(scope);
  } else if (migrationFlow === MIGRATION_FLOWS.SECTIONS_MIGRATION) {
    await initAfterSectionMigrationDone(scope);
  }
}

import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';
import * as util from '#packages/util';

import withSendBi from '../withSendBi';
import { mapStateToProps, mapDispatchToProps } from './withToolsItemsMapper';

import { mapTopBarPropsToToolItems as deriveToolsItems } from '../../menuBar/menuBarListItems/menuStructure';

import type { IMenuBarListItem } from '../../menuBar/types';
import type { ToolsListItemsProps } from '../../menuBar/menuBarListItems/toolsMenu/toolsMenuStructure';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export type ToolsItem = IMenuBarListItem;

type WithToolsItemsProps = ToolsListItemsProps;

interface ComponentWithToolsItemsProps {
  items: IMenuBarListItem[];
}

const withToolsItems = <P extends WithToolsItemsProps>(
  WrappedComponent: ComponentType<P & ComponentWithToolsItemsProps>,
) => {
  class WithToolsItems extends Component<WithToolsItemsProps> {
    render() {
      const items = deriveToolsItems(this.props as ToolsListItemsProps);

      return React.createElement(
        WrappedComponent,
        Object.assign({}, this.props as P, {
          items,
        }),
      );
    }
  }

  return _.flow(
    connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
    withSendBi,
  )(WithToolsItems);
};

export default withToolsItems;

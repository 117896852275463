// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import React from 'react';
import constants from '#packages/constants';
import PopupTemplate from './popupTemplate';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'outOfGridDesktopPanel',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <PopupTemplate
        panelName={this.props.panelName}
        h1text="Notification_Box_Out_Page_Gridlines_Title"
        imageClassName="page-out-of-grid"
        bodyText="Notification_Box_Out_Page_Gridlines_Text"
        helpId="b712fa83-f939-4ec4-ab68-b7f968d90fab"
        dontShowAgainKey={
          constants.USER_PREFS.OUT_OF_GRID.DESKTOP.OUT_OF_GRID_DONT_SHOW_AGAIN
        }
        userPrefType="site"
        className="out-of-grid-desktop-panel"
      />
    );
  },
});

import * as allTools from './tools';
import _ from 'lodash';

import type {
  GetToolsProps,
  Tool,
  Tools,
  ToolsKeys,
} from '../aiAssistantTypes';

const getTools = ({
  editorAPI,
  dispatch,
  payloads,
  toolsIds,
}: GetToolsProps) => {
  if (toolsIds === undefined) {
    return _.mapValues(allTools, (tool: Tool) => {
      return tool(editorAPI, dispatch, payloads);
    });
  } else if (!Array.isArray(toolsIds)) {
    throw new Error(
      'toolsIds should be provided as an array of strings or undefined if you want to use all tools',
    );
  } else {
    return toolsIds.reduce((tools: Tools, toolsId: ToolsKeys) => {
      if (!Object.keys(allTools).includes(toolsId)) {
        throw new Error(`tool with ID: ${toolsId} is not registered`);
      }

      tools[toolsId] = allTools[toolsId](editorAPI, dispatch, payloads);
      return tools;
    }, {} as Tools);
  }
};

export default getTools;

import React from 'react';
import { SwitchLayoutPanel } from './components/switchLayoutPanel';
import { SwitchLayoutStore } from './switchLayoutStore';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  SwitchLayoutApiKey,
  HistoryApiKey,
  EditorPaasApiKey,
  WorkspaceRightPanelApiKey,
  TextClassificationApiKey,
  ListClassificationApiKey,
  PreviewerApiKey,
  QuickEditApiKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
} from '#packages/apis';
import {
  SwitchLayoutApi,
  switchLayout,
  reloadResults,
  clearLayoutOptions,
  isInSwitchLayoutMode,
  isSwitchLayoutAvailable,
  scanSwitchLayoutPreset,
  isSelectedComponentSupported,
} from './switchLayoutApi';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { SectionsApiKey } from '#packages/sections';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '#packages/constants';
import type { SwitchLayoutConfig } from './switchLayoutApi';
import type { WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES } from '#packages/constants';
import { ScanSwitchLayoutPresetPanel } from './components/scanSwitchLayoutPreset/scanSwitchLayoutPresetPanel';
import experiment from 'experiment';
import { getSectionToSwitchLayout } from './switchLayoutUtil';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  editorPaasApi = this.useDependency(EditorPaasApiKey);
  sectionsAPI = this.useDependency(SectionsApiKey);
  historyAPI = this.useDependency(HistoryApiKey);
  quickEditAPI = this.useDependency(QuickEditApiKey);
  textClassificationApi = this.useDependency(TextClassificationApiKey);
  listClassificationApi = this.useDependency(ListClassificationApiKey);
  store = this.declareStore(SwitchLayoutStore);
  workspaceRightPanelApi = this.useDependency(WorkspaceRightPanelApiKey);
  previewerApi = this.useDependency(PreviewerApiKey);
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
}

const contributeSwitchLayoutPanel = (scope: Scope) => {
  const { workspaceRightPanelApi, editorRestrictionsApi } = scope;

  workspaceRightPanelApi.contributePanelContent(
    () => React.createElement(SwitchLayoutPanel),
    {
      panelName: WORKSPACE_RIGHT_PANEL_NAMES.SWITCH_LAYOUT,
      tabTitle: 'edit_section_panel_tab_layout_label',
      iconName: 'sectionLayout',
      isTabDisabled: () => {
        return (
          !isSwitchLayoutAvailable(scope) ||
          !isSelectedComponentSupported(scope) ||
          !editorRestrictionsApi.allowed('switch-layout_tab.visible')
        );
      },
      onOpen: (
        { sectionRef, origin }: SwitchLayoutConfig,
        groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
      ) => {
        switchLayout(
          scope,
          {
            sectionRef,
            origin,
          },
          groupType,
        );
      },
    },
  );
};

const contributeScanSwitchLayoutPresetPanel = (scope: Scope) => {
  const { workspaceRightPanelApi } = scope;

  workspaceRightPanelApi.contributePanelContent(
    () => React.createElement(ScanSwitchLayoutPresetPanel),
    {
      panelName: WORKSPACE_RIGHT_PANEL_NAMES.SCAN_SWITCH_LAYOUT_PRESET,
      tabTitle: 'Scan',
      onOpen: ({ sectionRef }) => {
        scanSwitchLayoutPreset(scope, { sectionRef });
      },
    },
  );
};

export const SwitchLayoutEntryPoint = createEntryPoint({
  name: 'SwitchLayout',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(SwitchLayoutApiKey, SwitchLayoutApi);
  },
  async initialize(scope) {
    const { editorCoreApi, editorPermissionsApi, editorRestrictionsApi } =
      scope;

    await editorCoreApi.hooks.initReady.promise;

    editorRestrictionsApi.set(
      ['switch-layout_tab.visible', 'switch-layout_tab-scan-preset.visible'],
      () => editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
    );

    if (isSwitchLayoutAvailable(scope)) {
      contributeSwitchLayoutPanel(scope);
    }

    if (
      editorRestrictionsApi.allowed('switch-layout_tab-scan-preset.visible') &&
      experiment.isOpen('se_scanSwitchLayoutPreset')
    ) {
      contributeScanSwitchLayoutPresetPanel(scope);
    }

    scope.editorAPI.pages.hooks.beforePageNavigate.tap(async () => {
      if (isInSwitchLayoutMode(scope)) {
        clearLayoutOptions(scope);
        scope.store.setIsLoading(true);
      }
    });
    scope.editorAPI.registerPageNavigationCallback(() => {
      if (isInSwitchLayoutMode(scope)) {
        const sectionRef = getSectionToSwitchLayout(scope.editorAPI);
        if (sectionRef) {
          reloadResults(scope, {
            sectionRef,
            origin: scope.store.getBiData().origin,
          });
        }
      }
    });
  },
});

import { BaseEntryScope, Hooks, createEntryPoint } from '#packages/apilib';
import {
  BaseResizeAndPushApiKey,
  EditorAPIKey,
  EditorCoreApiKey,
  ComponentsApiKey,
  CopyPasteApiKey,
  HistoryApiKey,
  SelectionApiKey,
  WorkspaceApiKey,
  PublishManagerApiKey,
  LayoutApiKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
  WorkspaceModesApiKey,
} from '#packages/apis';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { WorkspaceModes } from '#packages/workspaceModes';
import experiment from 'experiment';

import { SectionsApi } from './api';
import { SectionsApiKey } from './publicApiKey';
import { bootstrap } from './bootstrap/bootstrap';
import { updateIsSectionsEnabledByEditorData } from './bootstrap/conduction';
import { createEnforcementService } from './services/enforcement';
import { createSectionOnPageEnforcementService } from './bootstrap/enforcement/enforceSectionOnPageService';
import { createLayoutConstraintsService } from './bootstrap/constraints';

// TODO add detach method to VadimJs or remove it
// import { destroySections } from './destroy';

import type { CompRef } from 'types/documentServices';

export class Scope extends BaseEntryScope {
  baseResizeAndPush = this.useDependency(BaseResizeAndPushApiKey);
  components = this.useDependency(ComponentsApiKey);
  copyPaste = this.useDependency(CopyPasteApiKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  editorAPI = this.useDependency(EditorAPIKey);
  history = this.useDependency(HistoryApiKey);
  selection = this.useDependency(SelectionApiKey);
  workspace = this.useDependency(WorkspaceApiKey);
  publishManager = this.useDependency(PublishManagerApiKey);
  layout = this.useDependency(LayoutApiKey);
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  workspaceModesApi = this.useDependency(WorkspaceModesApiKey);

  hooks = this.declareApi(() => ({
    compAttachedToSection: Hooks.createHook<{
      compRef: CompRef;
    }>(),
  }));

  enforcement = this.declareApi(createEnforcementService);
  enforceSectionOnPageService = this.declareApi(
    createSectionOnPageEnforcementService,
  );
  layoutConstraints = this.declareApi(createLayoutConstraintsService);
}

const setRestrictions = (scope: Scope) => {
  scope.editorRestrictionsApi.set(
    [
      'sections_empty-state.visible',
      'sections_resize-handler.visible',
      'site-segment_resize-handler.visible',
      'sections_add-section-button.visible',
      'actions-bar.visible',
    ],
    () => scope.editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
  );
  scope.editorRestrictionsApi.set(
    [
      'sections_child-boxes.visible',
      'sections_empty-state-add-panel.visible',
      'sections_empty-state-title-text.visible',
      'action-bar_hide-section.visible',
      'sections_widget-stage-action.visible',
    ],
    () => scope.workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
  scope.editorRestrictionsApi.set(
    ['sections_quick-edit-stage-action.visible'],
    () =>
      scope.workspaceModesApi.isMode(WorkspaceModes.FULL) ||
      !experiment.isOpen('se_openQuickEditOnZoomStageClick'),
  );
  scope.editorRestrictionsApi.set(
    [
      'sections_select-sections-like.interactive',
      'sections_section-box-labels.visible',
      'sections_container-pointer-events.interactive',
      'sections_site-segment-empty-state-lite-mode.visible',
    ],
    () => !scope.workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
};

export const SectionsApiEntryPoint = createEntryPoint({
  name: 'SectionsEntrypoint',
  publicApi({ contributeApi }) {
    contributeApi(SectionsApiKey, SectionsApi);
  },
  Scope,
  async initialize(scope) {
    await updateIsSectionsEnabledByEditorData(scope);
    await scope.editorCoreAPI.hooks.initReady.promise;
    setRestrictions(scope);

    bootstrap(scope)
      .catch((error) => {
        error.name = `[SectionsBootstrap]: ${error.name}`;
        ErrorReporter.captureException(error);
      })
      .finally(() => {
        scope.editorCoreAPI.hooks.sectionsBootstrapFinished.resolve();
      });
  },
  // TODO add detach method to VadimJs or remove it
  //   detach(shell) {
  //     destroySections(shell);
  //   },
});

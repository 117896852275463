import React from 'react';
import _ from 'lodash';
import experiment from 'experiment';

import constants from '#packages/constants';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';

import { topBarMenuOpen } from '@wix/bi-logger-editor/v2';
import { biLogger } from '#packages/util';

import { ArenaDropPanel } from '../../dropPanel/arenaDropPanel';
import ArenaDropPanelDealer from '../../dropPanel/arenaDropPanelDealer';
import codeDropPanelCorvidBanner from '../../dropPanel/codeDropPanelCorvidBanner';
import UpdatePanel from '../../dropPanel/upgradePanel/upgradePanel';

import { getSiteListItemsByProps } from './siteMenu/siteMenuStructure';
import { getSettingsListItemsByProps } from './settingsMenu/settingsMenuStructure';
import { getToolsListItemsByProps } from './toolsMenu/toolsMenuStructure';
import { getHelpListItemsByProps } from './helpMenu/helpMenuStructure';
import { getCodeListItemsByProps } from './codeMenu/codeMenuStructure';

import type { SendBiFunction } from 'types/bi';
import type {
  SiteListItemsOwnProps,
  SiteListItemsStateProps,
  SiteListItemsDispatchProps,
} from './siteMenu/siteMenuStructure';
import type {
  SettingsListItemsOwnProps,
  SettingsListItemsStateProps,
  SettingsListItemsDispatchProps,
} from './settingsMenu/settingsMenuStructure';
import type {
  HelpListItemsOwnProps,
  HelpListItemsStateProps,
  HelpListItemsDispatchProps,
} from './helpMenu/helpMenuStructure';
import type {
  ToolsListItemsOwnProps,
  ToolsListItemsStateProps,
  ToolsListItemsDispatchProps,
  ToolsListItemsProps,
} from './toolsMenu/toolsMenuStructure';
import type {
  CodeListItemsOwnProps,
  CodeListItemsStateProps,
  CodeListItemsDispatchProps,
} from './codeMenu/codeMenuStructure';
import type {
  IMenuBarListItem,
  IMenuBarListItemDefault,
  ITopBarMenuItemOrButton,
} from '../types';
import type { UIResourceRestriction } from '#packages/editorRestrictions';

const shouldBeIncluded = (item: IMenuBarListItem) => {
  return (
    (!item.experiment || experiment.isOpen(item.experiment)) &&
    (!item.condition || item.condition())
  );
};

const isDefaultItem = (
  item: IMenuBarListItem,
  // @ts-expect-error
): item is IMenuBarListItemDefault => !item.isSeparator && !item.isTitleOnly;

const getFilteredPanelListItems = <P>(
  props: P,
  overriddenMenu: keyof typeof MENU_BAR_ITEMS,
  getItemsFunc: (props: P) => IMenuBarListItem[],
  overrides: AnyFixMe,
): IMenuBarListItem[] =>
  _(props)
    .thru(getItemsFunc)
    .filter((item) => {
      if (shouldBeIncluded(item)) {
        if (isDefaultItem(item) && item.children) {
          item.children = item.children.filter(shouldBeIncluded);
        }
        return true;
      }
      return false;
    })
    .thru((item) => util.overrideUtils.applyOverrides(item, overrides))
    .value();

const { TOPBAR } = constants.ROOT_COMPS;
const { MENU_BAR_ITEMS } = TOPBAR;

export interface MenuBarItemsOwnProps
  extends SiteListItemsOwnProps,
    SettingsListItemsOwnProps,
    HelpListItemsOwnProps,
    CodeListItemsOwnProps,
    ToolsListItemsOwnProps {
  isUsingExternalUpgradeData: () => boolean;
  fallbackToDefaultUpgradePanel: (errorType: string) => void;
  isInLocalDevMode: boolean;
  sendBIOnUpgradeHover: (hasSaleIndication: boolean) => void;
}

export interface MenuBarItemsStateProps
  extends SiteListItemsStateProps,
    SettingsListItemsStateProps,
    HelpListItemsStateProps,
    CodeListItemsStateProps,
    ToolsListItemsStateProps {
  isDeveloperModeEnabled: boolean;
  isUserOwner: boolean;
  isWixCodeProvisioned: boolean;
  hasNewReleasesNotification: boolean;
  overriddenMenus?: AnyFixMe;
  overriddenSiteMenu?: AnyFixMe;
  overriddenHelpMenu?: AnyFixMe;
  overriddenSettingsMenu?: AnyFixMe;
  overriddenToolsMenu?: AnyFixMe;
  overriddenCodeMenu?: AnyFixMe;
}

export interface MenuBarItemsDispatchProps
  extends SiteListItemsDispatchProps,
    SettingsListItemsDispatchProps,
    HelpListItemsDispatchProps,
    CodeListItemsDispatchProps,
    ToolsListItemsDispatchProps {
  getFeedbackNewCommentsCounter: () => number | '99+';
  toggleDeveloperMode: (options?: { notifyApps: boolean }) => void;
  sendBi: SendBiFunction;
  isAllowed: (uxResourceName: UIResourceRestriction) => boolean;
}

export interface MenuBarItemsProps
  extends MenuBarItemsOwnProps,
    MenuBarItemsStateProps,
    MenuBarItemsDispatchProps {}

const isUpgradeButtonOnTheRight = () => util.sections.isSectionsEnabled();

const getLeftMenuBarItems = (
  props: MenuBarItemsProps,
): ITopBarMenuItemOrButton[] => {
  const isNewTopBarEnabled = util.topBar.isNewTopBarEnabled();
  const { isAllowed } = props;
  return [
    {
      key: MENU_BAR_ITEMS.SITE,
      automationId: 'menu-bar-item-site',
      dropPanelAutomationId: 'drop-panel-site',
      title: 'TOPBAR_Site_Label',
      getCounter: () => props.getFeedbackNewCommentsCounter(),
      getListItems: () =>
        getFilteredPanelListItems(
          props,
          MENU_BAR_ITEMS.SITE,
          getSiteListItemsByProps,
          props.overriddenSiteMenu,
        ),
      onMouseEnter: (): void => {
        biLogger.report(
          topBarMenuOpen({
            menu_name: MENU_BAR_ITEMS.SITE,
          }),
        );
      },
    },
    {
      key: MENU_BAR_ITEMS.SETTINGS,
      automationId: 'menu-bar-item-settings',
      dropPanelAutomationId: 'drop-panel-settings',
      title: 'TOPBAR_NewSettings_Label',
      getListItems: () =>
        getFilteredPanelListItems(
          props,
          MENU_BAR_ITEMS.SETTINGS,
          getSettingsListItemsByProps,
          props.overriddenSettingsMenu,
        ),
      onMouseEnter: (): void => {
        biLogger.report(
          topBarMenuOpen({
            menu_name: MENU_BAR_ITEMS.SETTINGS,
          }),
        );
      },
      condition: () => isAllowed('top-bar_menu-settings.visible'),
    },
    {
      key: MENU_BAR_ITEMS.TOOLS,
      automationId: 'menu-bar-item-tools',
      dropPanelAutomationId: 'drop-panel-tools',
      title: 'Topbar_Tools_Label',
      isChecklist: true,
      getListItems: () =>
        mapTopBarPropsToToolItems(props as ToolsListItemsProps),
      condition: () =>
        !isNewTopBarEnabled && isAllowed('top-bar_menu-tools.visible'),
    },
    experiment.isOpen('specs.wix.devModeUI')
      ? {
          key: MENU_BAR_ITEMS.CODE,
          automationId: 'menu-bar-item-code',
          dropPanelAutomationId: 'drop-panel-code',
          title: 'Topbar_Corvid_Label',
          getListItems: () =>
            getFilteredPanelListItems(
              props,
              MENU_BAR_ITEMS.CODE,
              getCodeListItemsByProps,
              props.overriddenCodeMenu,
            ),
          condition: () => isAllowed('top-bar_menu-code.visible'),
        }
      : {
          key: MENU_BAR_ITEMS.CODE,
          automationId: 'menu-bar-item-code',
          panelClassName: 'top-bar-drop-down-panel_code',
          dropPanelAutomationId: 'drop-panel-code',
          title: experiment.isOpen('se_devModeTitle')
            ? 'TopBar_DevMode_Label'
            : 'Topbar_Corvid_Label',
          getOverrides: _.noop,
          devToolsButtonEnabled: true,
          DropPanel(ownProps) {
            return React.createElement(codeDropPanelCorvidBanner, {
              ...ownProps,
              sendBi: props.sendBi,
              toggleDeveloperMode: props.toggleDeveloperMode,
              isDeveloperModeEnabled: props.isDeveloperModeEnabled,
              isWixCodeProvisioned: props.isWixCodeProvisioned,
              devToolsButtonEnabled: true,
            });
          },
          condition: () => isAllowed('top-bar_menu-code.visible'),
        },
    {
      key: constants.ROOT_COMPS.TOPBAR.BUTTONS.ARENA,
      automationId: 'top-bar-button-arena',
      title: 'Topbar_Professional_Label',
      condition: () =>
        isNewTopBarEnabled && isAllowed('top-bar_menu-arena.visible'),
      onClick() {
        props.sendBi(coreBi.events.topbar.arena_tooltip_opened, {
          clickType: 'click',
        });
      },
      onMouseEnter() {
        props.sendBi(coreBi.events.topbar.arena_tooltip_opened, {
          clickType: 'hover',
        });
      },
      tooltipData: {},
      DropPanel(ownProps) {
        if (experiment.isOpen('se_showArenaDropPanelWithDealer')) {
          return React.createElement(ArenaDropPanelDealer, ownProps);
        }
        return React.createElement(ArenaDropPanel, ownProps);
      },
    },
    {
      key: MENU_BAR_ITEMS.HELP,
      automationId: 'menu-bar-item-help',
      dropPanelAutomationId: 'drop-panel-help',
      title: 'TOPBAR_HELP_TOOLTIP_TITLE',
      hasNotification:
        props.hasNewReleasesNotification &&
        isAllowed('top-bar_menu-help-new-releases.interactive'),
      getListItems: () =>
        getFilteredPanelListItems(
          props,
          MENU_BAR_ITEMS.HELP,
          getHelpListItemsByProps,
          props.overriddenHelpMenu,
        ),
    },
    {
      key: MENU_BAR_ITEMS.UPGRADE,
      automationId: 'menu-bar-item-upgrade',
      title: 'TOPBAR_Upgrade_Label',
      className: 'menu-bar-upgrade-item',
      condition: () => props.isUserOwner && !isNewTopBarEnabled,
    },
    {
      key: MENU_BAR_ITEMS.UPGRADE,
      automationId: 'menu-bar-item-upgrade',
      title: 'TOPBAR_Upgrade_Label',
      className: 'menu-bar-upgrade-item',
      panelClassName: 'upgrade-drop-panel-top-bar',
      condition: () =>
        props.isUserOwner && isNewTopBarEnabled && !isUpgradeButtonOnTheRight(),
      DropPanel(ownProps) {
        return React.createElement(UpdatePanel, {
          ...ownProps,
          isExternalUpgrade: props.isUsingExternalUpgradeData(),
          fallbackToDefaultUpgradePanel: props.fallbackToDefaultUpgradePanel,
        });
      },
    },
  ];
};

const getRightMenuBarItems = (
  props: MenuBarItemsProps,
): ITopBarMenuItemOrButton[] => {
  const isNewTopBarEnabled = util.topBar.isNewTopBarEnabled();
  const highlightUpgradeClassName = experiment.getValue(
    'se_highlightUpgradeButton',
  );
  const shouldHighlightUpgrade = !!highlightUpgradeClassName;
  return [
    {
      key: MENU_BAR_ITEMS.UPGRADE,
      automationId: 'menu-bar-item-upgrade',
      title: 'TOPBAR_Upgrade_Label',
      className: util.cx({
        'menu-bar-upgrade-item': true,
        [highlightUpgradeClassName]: shouldHighlightUpgrade,
      }),
      panelClassName: 'upgrade-drop-panel-top-bar',
      onMouseEnter: (hasSaleIndication: boolean): void => {
        props.sendBIOnUpgradeHover(hasSaleIndication);
      },
      condition: () => {
        return (
          props.isUserOwner &&
          isNewTopBarEnabled &&
          isUpgradeButtonOnTheRight() &&
          !props.isInLocalDevMode
        );
      },
      DropPanel(ownProps) {
        return React.createElement(UpdatePanel, {
          ...ownProps,
          isExternalUpgrade: props.isUsingExternalUpgradeData(),
          fallbackToDefaultUpgradePanel: props.fallbackToDefaultUpgradePanel,
        });
      },
    },
  ];
};

export function mapTopBarPropsToToolItems(
  props: ToolsListItemsProps & { overriddenToolsMenu?: AnyFixMe },
): IMenuBarListItem[] {
  return getFilteredPanelListItems(
    props,
    MENU_BAR_ITEMS.TOOLS,
    getToolsListItemsByProps,
    props.overriddenToolsMenu,
  );
}

export default function getMenuItemsByProps(
  props: MenuBarItemsProps,
  type: 'left' | 'right' = 'left',
) {
  return _(props)
    .thru(type === 'left' ? getLeftMenuBarItems : getRightMenuBarItems)
    .thru(
      <T>(item: T): T =>
        util.overrideUtils.applyOverrides(item, props.overriddenMenus),
    )
    .reject((item) => item.condition && !item.condition())
    .value();
}

import _ from 'lodash';
import PropTypes from 'prop-types';
import coreUtilsLib from 'coreUtilsLib';
import { mapStateToProps, mapDispatchToProps } from './gfppMapper';
import type {
  GFPPOwnProps,
  GFPPStateProps,
  GFPPDispatchProps,
} from './gfppMapper';
import type { GFPPAction } from '@wix/editor-component-types';
import React from 'react';
import * as UA from '#packages/uiAnimations';
import GfppButtonGenerator from './gfppButtonGenerator';
import { browserUtil, cx, hoc } from '#packages/util';

interface GFPPProps extends GFPPOwnProps, GFPPStateProps, GFPPDispatchProps {}

class GFPP extends React.Component<GFPPProps> {
  static displayName = 'GFPP';

  UNSAFE_componentWillMount() {
    this.props.setComponentPreviewState(
      _.head(this.props.selectedComponents).id,
      this.props.previewState || null,
    );
  }

  componentWillUnmount() {
    this.props.setGFPPMeasureFunc(null);
    this.props.setComponentPreviewState(
      _.head(this.props.selectedComponents).id,
      null,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: GFPPProps) {
    const hasSelectedComponentChanged = !_.isEqual(
      this.props.selectedComponents,
      nextProps.selectedComponents,
    );
    if (hasSelectedComponentChanged) {
      this.props.setGfppIsCollapsed(true);
      this.props.setComponentPreviewState(
        _.head(this.props.selectedComponents).id,
        null,
      );
      this.props.setComponentPreviewState(
        _.head(nextProps.selectedComponents).id,
        nextProps.previewState || null,
      );
    }
  }

  getButtonProps = (button: GFPPAction) => {
    const props = Object.assign(
      {
        gfppData: this.props.gfppData,
      },
      button,
    );

    if (button.isApplied) {
      Object.assign(props, {
        label: button.label_active || button.label,
        icon: button.icon_active || button.icon,
        tooltip: button.tooltip_active || button.tooltip,
      });
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (button.helpId && _.isUndefined(button.onClick)) {
      props.onClick = () => {
        this.props.openHelpCenter(button.helpId);
      };
    }

    props.selectedComponent = this.props.selectedComponents;

    return props;
  };

  gfppDomNodeSize: undefined | { width: unknown; height: unknown } = undefined;

  updateGFPPMeasureFunc = (gfppDomNode: AnyFixMe) => {
    this.props.setGFPPMeasureFunc(() => {
      if (gfppDomNode) {
        const gfppRect =
          coreUtilsLib.domMeasurements.getBoundingRect(gfppDomNode);

        this.gfppDomNodeSize = _.pick(gfppRect, ['width', 'height']);
      }

      return this.gfppDomNodeSize;
    });
  };

  getButtons = () => {
    return this.props.buttons;
  };

  stopEventPropagation = (e: AnyFixMe) => {
    e.stopPropagation();
  };

  getKey = () => {
    const { selectedComponents } = this.props;

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/size
    return `${_.head(selectedComponents).id}_${_.size(selectedComponents)}`;
  };

  getButtonClasses = (button: GFPPAction) => {
    return {
      disabled: button.isDisabled,
      selected: button.isSelected,
      applied: button.isApplied,
    };
  };

  getDataCompId = () =>
    this.props.selectedComponents.map((comp) => comp.id).join(',');

  render() {
    return (
      <UA.gfppAnimation>
        <ul
          id="gfpp"
          data-hook="gfpp-buttons-list"
          data-comp-id={this.getDataCompId()}
          ref={this.updateGFPPMeasureFunc}
          key={this.getKey()}
          onMouseDown={this.stopEventPropagation}
          onMouseUp={this.stopEventPropagation}
          onDoubleClick={this.stopEventPropagation}
          onContextMenu={(e) => {
            this.stopEventPropagation(e);
            browserUtil.preventDefaultRightClick(e);
          }}
          onMouseMove={this.stopEventPropagation}
          style={this.props.style}
          onClick={this.stopEventPropagation}
          className="gfpp"
        >
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.getButtons(), (button) => (
            <GfppButtonGenerator
              key={button.icon || button.label || button.tooltip}
              {..._.omit(this.getButtonProps(button), 'className')}
              className={cx(
                this.getButtonClasses(button),
                button.className || '',
              )}
            />
          ))}
        </ul>
      </UA.gfppAnimation>
    );
  }
}

//@ts-expect-error
GFPP.propTypes = {
  selectedComponents: PropTypes.arrayOf(PropTypes.object).isRequired,
  buttons: PropTypes.arrayOf(PropTypes.object).isRequired,
  previewState: PropTypes.string,
  setComponentPreviewState: PropTypes.func.isRequired,
  openHelpCenter: PropTypes.func.isRequired,

  style: PropTypes.object,

  setGFPPMeasureFunc: PropTypes.func.isRequired,
  toggleGfpp: PropTypes.func.isRequired,
  setGfppIsCollapsed: PropTypes.func.isRequired,
  gfppIsCollapsed: PropTypes.bool.isRequired,
};

const Connected = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(GFPP);

Connected.pure = GFPP;

export default Connected;

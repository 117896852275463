import type { EditorAPI } from '#packages/editorAPI';
import type { ThunkAction, Dispatch } from 'types/redux';

import constants from '#packages/constants';
import { biLogger } from '#packages/util';
import { topBarMenuItemHover } from '@wix/bi-logger-editor/v2';

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openSectionsMigrationPanel: () =>
      editorAPI.panelManager.openPanel(
        constants.PANELS.PROMOTIONAL.OPTIONAL_SECTIONS_MIGRATION_PANEL,
        {
          origin: 'top_bar_notification',
        },
      ),
    reportOnHover: () =>
      biLogger.report(
        topBarMenuItemHover({
          menu_item_name: 'update_editor_banner',
        }),
      ),
  };
};

export type MapDispatchToPropsType = ReturnType<typeof mapDispatchToProps>;

import { fedopsLogger } from '#packages/util';
import type { CompRef } from 'types/documentServices';
import * as addPanelUtils from '../../../addPanel/addPanelUtils';
import { BI_ORIGIN } from '../../constants';

export const attemptToAddComponent = (
  editorAPI: AnyFixMe,
  {
    compDef,
    sectionTitle,
    tags,
    itemId,
    appDefinitionId,
    categoryId,
    position,
  }: AnyFixMe,
): Promise<CompRef> => {
  fedopsLogger.interactionStarted(
    addPanelUtils.appendAdditionalInteractionNames(
      [fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL],
      compDef,
    ),
    {
      paramsOverrides: {
        component_type: compDef.componentType,
        method: 'click',
      },
    },
  );

  if (appDefinitionId) {
    return editorAPI.tpa
      .installAppIfNeeded(appDefinitionId, { resolveBeforeSave: true })
      .then(() => {
        return addPanelUtils.attemptToAddComponent(
          editorAPI,
          compDef,
          categoryId,
          sectionTitle,
          tags,
          itemId,
          BI_ORIGIN,
          appDefinitionId,
          {
            position,
          },
        );
      });
  }
  return addPanelUtils.attemptToAddComponent(
    editorAPI,
    compDef,
    categoryId,
    sectionTitle,
    tags,
    itemId,
    BI_ORIGIN,
    undefined,
    {
      position,
    },
  );
};

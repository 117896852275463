import PropTypes from 'prop-types';
import React from 'react';

import * as baseUI from '#packages/baseUI';
import * as coreBi from '#packages/coreBi';
import { TextLabel, Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import {
  WarningCircleBold,
  AlertCircleBold,
} from '@wix/wix-ui-icons-common/classic-editor';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import type { IconInfo } from 'types/core';
import experiment from 'experiment';
import NodeEmptyState, {
  type EmptyStateData,
} from './nodeEmptyState/nodeEmptyState';

export interface NodeProps {
  id: string;
  parent: NodeProps;
  level: number;
  label: string;
  onClick?: (e: React.MouseEvent) => void;
  isSelected?: boolean;
  isHidden?: boolean;
  onToggleVisibility?: (e: React.MouseEvent) => void;
  onToggleSpotlight?: (e: React.MouseEvent) => void;
  moveToIndex?: FunctionFixMe;
  childNodes?: Array<NodeProps>;
  hasChildNodes?: boolean;
  areChildNodesExpanded: boolean;
  setChildNodesExpanded?: (nextIsExpanded: boolean) => void;
  isSpotlight?: boolean;
  selectedCompId?: string;
  startDrag?: Function;
  openOutOfGridHelpCenter: (e?: React.MouseEvent) => void;
  icon?: IconInfo;
  isSOAP: boolean;
  isDevModeEnabled: boolean;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
  dataHook?: string;
  componentType: string;
  isLandingPage: boolean;
  isLightBoxMode: boolean;
  shouldShowWarningIcon: boolean;
  emptyStateData?: EmptyStateData;
  isComponentWithinPage: boolean;
  repositionComponentInParent: () => void;
}

class Node extends React.Component<NodeProps> {
  static displayName = 'LayersNode';

  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    isSelected: PropTypes.bool,
    isHidden: PropTypes.bool,
    onToggleVisibility: PropTypes.func,
    onToggleSpotlight: PropTypes.func,
    moveToIndex: PropTypes.func.isRequired,
    childNodes: PropTypes.array,
    shouldShowChildren: PropTypes.bool,
    isSpotlight: PropTypes.bool,
  };

  itemRef = React.createRef<HTMLDivElement>();
  sortList = React.createRef<{ startDrag: Function }>();

  getReactClass = () => {
    return Node;
  };

  componentDidMount() {
    if (this.props.isSelected) {
      this.scrollToComponent();
    }
  }

  scrollToComponent() {
    if ((this.itemRef?.current as any)?.scrollIntoViewIfNeeded) {
      (this.itemRef?.current as any)?.scrollIntoViewIfNeeded();
    } else {
      this.itemRef?.current?.scrollIntoView();
    }
  }

  componentDidUpdate(prevProps: Readonly<NodeProps>) {
    if (this.props.isSelected && prevProps.isSelected === false) {
      this.scrollToComponent();
    }
  }

  startDrag = (index: number, event: React.MouseEvent) => {
    this.sortList?.current.startDrag(index, event);
  };

  toggleChildrenExpanded = async () => {
    const nextIsExpanded = !this.props.areChildNodesExpanded;

    this.props.setChildNodesExpanded(nextIsExpanded);
    this.props.sendBi(coreBi.events.layers.layer_hierarchy_click, {
      target: nextIsExpanded ? 'open' : 'close',
      layer_name: this.props.label,
    });
  };

  shouldShowSpotlightAndShowIcons = () => {
    return this.props.isDevModeEnabled && this.props.level > 0;
  };

  hoverBiEvent = () => {
    this.props.sendBi(coreBi.events.layers.hover_element_layers_panel, {
      layer_name: this.props.label,
      component_type: this.props.componentType,
      parent_component_type: this.props.parent?.componentType,
      is_landing_page: this.props.isLandingPage,
      is_popup: this.props.isLightBoxMode,
    });
  };

  renderWarningIconTooltip() {
    return (
      <div>
        {translate('Layers_Gridlines_Tooltip')}{' '}
        <span
          className="warning-icon-tooltip-link"
          onClick={this.props.openOutOfGridHelpCenter}
        >
          {translate('Layers_Gridlines_Tooltip_Learn_More_Link')}
        </span>
      </div>
    );
  }

  renderExpandedChildNodesStateOrEmpty() {
    const Node = this.getReactClass();
    if (!this.props.areChildNodesExpanded) return null;

    if (this.props.emptyStateData)
      return (
        <NodeEmptyState
          titleLabel={this.props.emptyStateData?.titleLabel}
          ctaLabel={this.props.emptyStateData?.ctaLabel}
          onClick={this.props.emptyStateData?.onClick}
        />
      );

    return (
      <baseUI.sortByDragList
        key="sortList"
        // @ts-expect-error
        ref={this.sortList}
        onItemMoved={this.props.moveToIndex}
        className="sort-list"
      >
        {this.props.childNodes.map((child, childIndex) => (
          <Node
            startDrag={this.startDrag.bind(this, childIndex)}
            key={child.id}
            {...child}
          />
        ))}
      </baseUI.sortByDragList>
    );
  }

  shouldRenderTextComponentFromWBU =
    experiment.isOpen('se_emptyHeaderFooterInLayers') && this.props.level === 0;

  renderNotInViewPortIconTooltip() {
    return (
      <div>
        {translate('Layers_Viewport_Tooltip_Body')}{' '}
        <span
          className="warning-icon-tooltip-link"
          onClick={this.props.repositionComponentInParent}
        >
          {translate('Layers_Viewport_Tooltip_Cta')}
        </span>
      </div>
    );
  }

  renderOutFromViewPortIndication() {
    return (
      <baseUI.tooltip
        value={this.renderNotInViewPortIconTooltip()}
        shouldTranslate={true}
        interactive={true}
        alignment={constants.UI.TOOLTIP.ALIGNMENT.RIGHT}
        styleType={constants.UI.TOOLTIP.STYLE_TYPE.NORMAL}
      >
        <div data-aid="warning-icon" className="warning-icon-container">
          <AlertCircleBold className="warning-icon warning-icon-red" />
        </div>
      </baseUI.tooltip>
    );
  }

  renderOutOfGridIndication() {
    return (
      <baseUI.tooltip
        value={this.renderWarningIconTooltip()}
        shouldTranslate={true}
        interactive={true}
        alignment={constants.UI.TOOLTIP.ALIGNMENT.RIGHT}
        styleType={constants.UI.TOOLTIP.STYLE_TYPE.NORMAL}
      >
        <div data-aid="warning-icon" className="warning-icon-container">
          <WarningCircleBold className="warning-icon" />
        </div>
      </baseUI.tooltip>
    );
  }

  maybeShowWarningIndication() {
    if (!this.props.isComponentWithinPage) {
      return this.renderOutFromViewPortIndication();
    }
    if (this.props.shouldShowWarningIcon) {
      return this.renderOutOfGridIndication();
    }
    return null;
  }

  render() {
    const isHeadline = this.props.level === 0;
    return (
      <div
        data-aid={`layers-node-${this.props.id}`}
        className={cx('node', {
          SOAP: this.props.isSOAP,
          headline: isHeadline,
          component: !isHeadline,
          'clickable-headline': isHeadline,
        })}
      >
        <div
          data-aid={this.props.level > 0 ? 'layer-item' : 'layer-header'}
          onMouseDown={(e) => {
            if (this.props.startDrag) {
              this.props.startDrag(e);
            }
          }}
          className={cx('item', {
            SOAP: this.props.isSOAP,
            selected: this.props.isSelected,
            hidden: this.props.isHidden,
            spotlight: this.props.isSpotlight,
            header: isHeadline,
          })}
          onMouseEnter={() => this.hoverBiEvent()}
        >
          <div className="item-inner" ref={this.itemRef}>
            {(this.props.hasChildNodes || this.props.emptyStateData) && (
              <span
                onMouseDown={this.toggleChildrenExpanded}
                key="toggleShowChildren"
                data-aid="toggle-show-children"
                className="toggle-show-children"
              >
                {this.props.areChildNodesExpanded ? (
                  <baseUI.symbol
                    name="triangle-down"
                    key="triangle-down"
                    className="open"
                  />
                ) : (
                  <baseUI.symbol
                    name="triangle-right"
                    key="triangle-right"
                    className="close"
                  />
                )}
              </span>
            )}

            <div
              className={cx('node-info', {
                'extra-margin': this.props.emptyStateData
                  ? false
                  : !this.props.hasChildNodes,
              })}
            >
              {this.props.icon && this.props.level > 0 ? (
                <span key="icon" className="icon">
                  <baseUI.MenuIcon icon={this.props.icon} />
                </span>
              ) : null}

              <span
                key={this.props.id}
                onClick={this.props.onClick}
                className="title"
              >
                {this.shouldRenderTextComponentFromWBU ? (
                  <Text
                    size="small"
                    skin="standard"
                    weight="thin"
                    shouldTranslate={false}
                  >
                    {this.props.label}
                  </Text>
                ) : (
                  <TextLabel
                    value={this.props.label}
                    type="T17"
                    shouldTranslate={false}
                  />
                )}
              </span>

              {this.shouldShowSpotlightAndShowIcons() ? (
                <span
                  onClick={this.props.onToggleSpotlight}
                  key="spotlight-handle"
                  data-aid="layer-toggle-spotlight"
                  className="toggle-spotlight"
                >
                  <baseUI.tooltip
                    value="Layers_SpotlightOff_Tooltip"
                    shouldTranslate={true}
                  >
                    <div>
                      {this.props.isSpotlight ? (
                        <symbols.symbol name="spotlight" key="spotlightOn" />
                      ) : null}
                    </div>
                  </baseUI.tooltip>
                  <baseUI.tooltip
                    value="Layers_SpotlightOn_Tooltip"
                    shouldTranslate={true}
                  >
                    <div>
                      {!this.props.isSpotlight ? (
                        <symbols.symbol name="spotlight" key="spotlightOff" />
                      ) : null}
                    </div>
                  </baseUI.tooltip>
                </span>
              ) : null}

              {this.shouldShowSpotlightAndShowIcons() ? (
                <span
                  onClick={this.props.onToggleVisibility}
                  key="visibility-handle"
                  data-aid="layer-toggle-visibility"
                  className="toggle-visibility"
                >
                  <baseUI.tooltip
                    value="Layers_Hide_Tooltip"
                    shouldTranslate={true}
                  >
                    <div>
                      {!this.props.isHidden ? (
                        <baseUI.symbol name="showComp" key="showSymbol" />
                      ) : null}
                    </div>
                  </baseUI.tooltip>
                  <baseUI.tooltip
                    value="Layers_Show_Tooltip"
                    shouldTranslate={true}
                  >
                    <div>
                      {this.props.isHidden ? (
                        <baseUI.symbol
                          name="hiddenPageStatus"
                          key="hideSymbol"
                        />
                      ) : null}
                    </div>
                  </baseUI.tooltip>
                </span>
              ) : null}
              {this.maybeShowWarningIndication()}
            </div>
          </div>
        </div>

        {this.renderExpandedChildNodesStateOrEmpty()}
      </div>
    );
  }
}

export default Node;

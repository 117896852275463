import { manageMenuPopupShow } from '@wix/bi-logger-editor/v2';
import * as stateManagement from '#packages/stateManagement';
import { EditorAPIKey } from '#packages/apis';
import type { Shell } from '#packages/apilib';
import constants from '#packages/constants';
import { createMenuApi } from './menuAPI';
import {
  PAGES_MENU_ID,
  ADVANCED_MENU_TYPE,
  MEMBERS_MENU_CONTROLLER,
  MENUS_TYPE_BEFORE_ADVANCED_MENUS_MIGRATION_KEY,
  IS_ADVANCED_MENUS_MIGRATION_PANEL_SHOWN,
  CUSTOM_MENUS_USAGE_TYPES,
  MEMBERS_SUB_MENUS_IDS,
  type SiteMenusType,
} from '../constants';
import {
  biLogger,
  isAdvancedMenuOpen,
  isAdvancedMenusMigrationOpen,
} from '#packages/util';
import type { CompRef } from 'types/documentServices';
import type { MenuItem } from '@wix/document-services-types/src';

const { getSiteUserPreferences, getUserPreferences } =
  stateManagement.userPreferences.selectors;

export function createAdvancedMenu(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const menuAPI = createMenuApi(editorAPI);

  const getMenuCompRef = (compRef?: CompRef) =>
    compRef || editorAPI.selection.getSelectedComponents()[0];

  const getComponentData = (compRef: CompRef) =>
    editorAPI.components.data.get(compRef);

  const getMenuIdFromMenuRef = (menuRef: string) => menuRef.replace('#', '');

  const getMenuRef = (compRef?: CompRef) => {
    const { menuRef } = getComponentData(getMenuCompRef(compRef));

    return menuRef;
  };

  const createMenu = (compRef?: CompRef, items?: MenuItem[]) => {
    const menuId = menuAPI.createCustomMenu(
      {
        menuType: ADVANCED_MENU_TYPE,
      },
      items,
    );
    const menuRef = getMenuCompRef(compRef);

    menuAPI.connect(menuId, menuRef);
  };

  const resetMenu = (compRef?: CompRef) => {
    menuAPI.connect(PAGES_MENU_ID, getMenuCompRef(compRef));
    const menuRef = getMenuRef(compRef);

    menuAPI.removeMenu(getMenuIdFromMenuRef(menuRef));
  };

  const isAdvanced = (compRef?: CompRef) => {
    if (!isAdvancedMenuOpen()) {
      return false;
    }

    const menuRef = getMenuRef(compRef);

    if (!menuRef) {
      const { controllerType } = getComponentData(getMenuCompRef(compRef));

      return controllerType === MEMBERS_MENU_CONTROLLER;
    }

    return isAdvancedByMenuRef(menuRef);
  };

  const isAdvancedByMenuRef = (menuRef: string) => {
    if (!isAdvancedMenuOpen()) {
      return false;
    }

    const { menuType, id } =
      menuAPI.getMenu(getMenuIdFromMenuRef(menuRef)) || {};

    if (menuType === ADVANCED_MENU_TYPE) {
      return true;
    }

    return id && MEMBERS_SUB_MENUS_IDS.includes(id);
  };

  const getMenusTypeBeforeMigration = () =>
    getSiteUserPreferences<SiteMenusType>(
      MENUS_TYPE_BEFORE_ADVANCED_MENUS_MIGRATION_KEY,
    )(editorAPI.store.getState());

  const openMigrationPanelIfNeeded = (source?: string) => {
    if (!isAdvancedMenusMigrationOpen() || !isAdvancedMenuOpen()) {
      return;
    }

    const menusTypeBeforeMigration = getMenusTypeBeforeMigration();

    if (!CUSTOM_MENUS_USAGE_TYPES.includes(menusTypeBeforeMigration)) {
      return;
    }

    const editorState = editorAPI.store.getState();

    const isPanelAlreadyShown = (['global', 'site'] as const).some((prefType) =>
      getUserPreferences(prefType)(IS_ADVANCED_MENUS_MIGRATION_PANEL_SHOWN)(
        editorState,
      ),
    );

    if (!isPanelAlreadyShown) {
      editorAPI.panelManager.openPanel(
        constants.PANELS.PROMOTIONAL.ADVANCED_MENUS_MIGRATION_PANEL,
        { menusTypeBeforeMigration },
        true,
      );

      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setGlobalUserPreferences(
          IS_ADVANCED_MENUS_MIGRATION_PANEL_SHOWN,
          true,
        ),
      );

      biLogger.report(
        manageMenuPopupShow({
          origin: source || 'settings-gfpp',
          name: `adv_menus_migration_popup_${menusTypeBeforeMigration}`,
        }),
      );
    }
  };

  return {
    createMenu,
    resetMenu,
    isAdvanced,
    isAdvancedByMenuRef,
    getMenusTypeBeforeMigration,
    openMigrationPanelIfNeeded,
  };
}

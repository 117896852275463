// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import _ from 'lodash';
import constants from '#packages/constants';
import React from 'react';
import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';

//TYPE WAS GENERATED, remove this line when reviewed
interface FullStagePanelProps {
  token: string;
  url: string;
  displaySectionedPanel: FunctionFixMe;
  initialData?: any;
  openedFullStagePanels: Array<AnyFixMe>;
}

class FullStagePanel extends React.Component<FullStagePanelProps> {
  static displayName = 'fullStagePanel';

  static propTypes = {
    token: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    displaySectionedPanel: PropTypes.func.isRequired,
    initialData: PropTypes.any,
    openedFullStagePanels: PropTypes.array.isRequired,
  };

  componentDidMount() {
    this.props.displaySectionedPanel(false);
  }

  componentWillUnmount() {
    if (this.props.openedFullStagePanels.length === 0) {
      this.props.displaySectionedPanel(true);
    }
  }

  render() {
    return (
      <PlatformPanelApplicationFrame
        token={this.props.token}
        url={this.props.url}
        initialData={this.props.initialData}
        panelClass="full-stage-frame"
        width="100%"
        height="100%"
      />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  displaySectionedPanel: (shouldShow) =>
    dispatch(stateManagement.sectionedPanel.actions.displayPanel(shouldShow)),
});

const mapStateToProps = ({ state }) => {
  const openedPanels = stateManagement.panels.selectors.selectOpenPanels(state);
  return {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    openedFullStagePanels: _.filter(openedPanels, {
      frameType: constants.PANEL_TYPES.FULL_STAGE,
    }),
  };
};

export default util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  mapStateToProps,
  mapDispatchToProps,
)(FullStagePanel);

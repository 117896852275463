import constants from '#packages/constants';
import type { ThunkAction } from 'types/redux';

const togglePreview =
  (callback?: Function, params?: { biParams?: AnyFixMe }): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    if (!editorAPI.savePublish.isSavePublishLocked()) {
      editorAPI.savePublish.lockSavePublish();
      editorAPI.preview.togglePreview(callback, {
        biParams: { origin: constants.BI.PREVIEW.ORIGIN.TOP_BAR },
        ...params,
      });
      window.setTimeout(editorAPI.savePublish.unlockSavePublish, 500);
    }
  };

export default togglePreview;

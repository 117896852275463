// @ts-nocheck
import _ from 'lodash';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const states = constants.INLINE_POPUP.STATES;

function getRelevantPanel(editorAPI, compRef) {
  const wasInitialyOpen = util.inlinePopupUtils.isTargetOpen(
    editorAPI,
    compRef,
  );
  gfppDataUtils.togglePanel(
    editorAPI,
    wasInitialyOpen ? 'customizeDesign' : 'design',
    {
      onPreviewStateChange: (state) => {
        if (!state) {
          return;
        }
        const func =
          state === states.OPEN
            ? stateManagement.inlinePopup.actions.open
            : stateManagement.inlinePopup.actions.close;
        editorAPI.store
          .dispatch(
            func(util.inlinePopupUtils.getToggleTarget(editorAPI, compRef)),
          )
          .then(() => {
            if (state === states.OPEN) {
              editorAPI.store.dispatch(
                stateManagement.selection.actions.setFocusedContainer(
                  util.inlinePopupUtils.getToggleTarget(editorAPI, compRef),
                ),
              );
            }
          });
      },
      getDefaultState: () =>
        util.inlinePopupUtils.isTargetOpen(editorAPI, compRef)
          ? states.OPEN
          : states.CLOSED,
      onCustomizeDesignCallBack: () =>
        editorAPI.store.dispatch(
          stateManagement.inlinePopup.actions.close(
            util.inlinePopupUtils.getToggleTarget(editorAPI, compRef),
          ),
        ),
      withAdditionalBehaviors: true,
      title: 'Mobile_Menu_Icon_Design_Header',
    },
  );
}

export default {
  mobileEnabledActions: [
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HIDE,
    ACTIONS.HELP,
  ],
  enabledActions: [
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HIDE,
    ACTIONS.HELP,
  ],
  presetActions: {
    [ACTIONS.DESIGN]: {
      isSelected: (editorAPI) =>
        gfppDataUtils.isPanelOpen(editorAPI, 'design') ||
        gfppDataUtils.isPanelOpen(editorAPI, 'customizeDesign'),
      onClick: getRelevantPanel,
    },
    [ACTIONS.ANIMATION]: {
      isSupported: (editorAPI, compRefs) =>
        !util.inlinePopupUtils.isTargetOpen(editorAPI, _.head(compRefs)),
    },
    [ACTIONS.HELP]: {
      helpId: 'edd2f793-2320-4d8a-ad8f-e1764e6420f8',
      mobileHelpId: 'edd2f793-2320-4d8a-ad8f-e1764e6420f8',
    },
  },
};

import * as util from '#packages/util';
import constants from '#packages/constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { AddPageButton } from '../utils';

export interface PanelProps {
  origin: string;
  biCategory: string;
  clickOrigin?: AddPageButton;
  isAdvancedMenuFlow?: boolean;
  pageAddedCallback?: (pageId: string) => void;
}

export const openAddPagePanel = (
  editorAPI: EditorAPI,
  panelProps: PanelProps,
) => {
  util.fedopsLogger.interactionStarted(
    util.fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_OPEN,
  );
  editorAPI.panelManager.openPanel(
    constants.PANEL_NAMES.ADD_PAGES_PANEL,
    {
      ...panelProps,
    },
    true,
  );
};

import * as util from '#packages/util';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import _ from 'lodash';
import { PanelResolveType } from '@wix/platform-editor-sdk';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  EditorClientSpecMapEntry,
  PagesData,
} from 'types/documentServices';
import experiment from 'experiment';
import * as stateManagement from '#packages/stateManagement';
import { actions } from '#packages/popups';

const { isDomainConnected } = stateManagement.domain.selectors;

const hasSection = function (pageData: AnyFixMe) {
  return !!pageData.tpaApplicationId;
};

const showPremiumMessageWhenDeletingMultiSection = function (
  dsRead: AnyFixMe,
  multiSectionApplicationId: AnyFixMe,
  premiumTpaChildrenCount: AnyFixMe,
) {
  const isMultiSectionPremium = dsRead.tpa.app.isPremium(
    multiSectionApplicationId,
  );

  // show message for premium app when deleting multi section and it contains other premium apps
  if (isMultiSectionPremium && premiumTpaChildrenCount > 1) {
    return true;
  } else if (!isMultiSectionPremium && premiumTpaChildrenCount > 0) {
    return true;
  }

  return false;
};

const isMandatorySection = function (
  editorAPI: EditorAPI,
  pageData: PagesData,
  applications: EditorClientSpecMapEntry[],
) {
  let appData: EditorClientSpecMapEntry;
  if (applications && applications.length) {
    appData = applications.find(
      (app) => app.applicationId === pageData.tpaApplicationId,
    );
  } else {
    appData = editorAPI.tpa.app.getData(pageData.tpaApplicationId);
  }

  const widgets = appData?.widgets;
  const sectionWidgetId =
    widgets &&
    Object.keys(widgets).find((widgetId: string) => {
      return widgets?.[widgetId]?.appPage?.id === pageData.tpaPageId;
    });
  const result = widgets?.[sectionWidgetId]?.autoAddToSite;
  if (result === undefined) {
    //backward compatible
    return true;
  }
  return result;
};

const sendBiForTpasOnADeletedPage = function (
  editorAPI: AnyFixMe,
  tpasOnPageBiParams: AnyFixMe[] = [],
) {
  util.editorWixRecorder.addLabel(
    `${tpasOnPageBiParams[0]?.component_type} component removed`,
  );
  editorAPI.bi.reportBI(util.bi.events.COMPONENT_REMOVED, tpasOnPageBiParams);
};

const deletePageWithoutShowingMessage = function (
  editorAPI: AnyFixMe,
  dsActions: AnyFixMe,
  pageId: AnyFixMe,
  completeCallback: AnyFixMe,
  tpasOnPageBiParams: AnyFixMe,
  options: AnyFixMe,
) {
  const isPopup = editorAPI.pages.popupPages.isPopup(pageId);
  if (isPopup) {
    actions.openBehaviors.removeOpenBehaviorFromAllPages(editorAPI);
  }
  editorAPI.pages.beforePagesRemoveHook(
    [pageId],
    function () {
      dsActions.pages.remove(pageId);
      if (completeCallback) {
        completeCallback();
      }
      sendBiForTpasOnADeletedPage(editorAPI, tpasOnPageBiParams);
    },
    options,
  );
};

const actualRemovePage = function (
  editorAPI: EditorAPI,
  dsRead: AnyFixMe,
  dsActions: AnyFixMe,
  pageId: AnyFixMe,
  pageData: AnyFixMe,
  removePagePlugins: AnyFixMe,
  shouldShowPageDeleteMessage: AnyFixMe,
  tpasOnPageBiParams: AnyFixMe,
  completeCallback: AnyFixMe,
  onCancelCallback: AnyFixMe,
  options?: AnyFixMe,
) {
  const pageTpaData = dsRead.tpa.section.getPageData(pageId);
  const shouldDeleteApp = dsRead.tpa.app.shouldDeleteWholeApp(pageData);
  const isPageHasSectionAndDeletePlugin =
    pageTpaData.hasSection &&
    removePagePlugins[dsRead.tpa.constants.COMP_TYPES.TPA_SECTION];
  const isBlogPage = dsRead.wixapps.classics.isBlogPage(pageId);
  if (isBlogPage) {
    if (removePagePlugins.blog) {
      removePagePlugins.blog();
    }
    editorAPI.pages.beforePagesRemoveHook(
      [pageId],
      function () {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(dsRead.pages.getPagesData(), function (data) {
          if (dsRead.wixapps.classics.isBlogPage(data.id)) {
            dsActions.pages.remove(data.id);
            sendBiForTpasOnADeletedPage(editorAPI, tpasOnPageBiParams);
          }
        });
        if (completeCallback) {
          completeCallback();
        }
      },
      {},
    );
  } else if (isPageHasSectionAndDeletePlugin && shouldDeleteApp) {
    removePagePlugins[dsRead.tpa.constants.COMP_TYPES.TPA_SECTION](
      pageId,
      pageTpaData.title,
      pageTpaData.appData,
      shouldShowPageDeleteMessage,
      completeCallback,
      tpasOnPageBiParams,
      options,
    );
  } else if (shouldShowPageDeleteMessage) {
    let pageHierarchyProps = {};
    if (experiment.isOpen('specs.promote-seo.enableStaticPageHierarchy')) {
      const isParentPage = editorAPI.pages.isParentPage(pageId);
      const sitePublicUrl = editorAPI.site.getSitePublicUrl();
      const childPageTitles = editorAPI.pages.getChildPagesTitles(pageId);
      pageHierarchyProps = {
        isParentPage,
        sitePublicUrl,
        hasDomain: isDomainConnected(editorAPI.dsRead),
        childPageTitles,
      };
    }
    const panelProps = {
      pageTitle: pageData.title,
      onConfirm: deletePageWithoutShowingMessage.bind(
        null,
        editorAPI,
        dsActions,
        pageId,
        completeCallback,
        tpasOnPageBiParams,
        options,
      ),
      isPopup: pageData.isPopup,
      ...pageHierarchyProps,
      onCancel() {
        onCancelCallback();
      },
    };
    editorAPI.panelManager.openPanel(
      'panels.messagePanels.deletePageMessage',
      panelProps,
      true,
    );
  } else {
    deletePageWithoutShowingMessage(
      editorAPI,
      dsActions,
      pageId,
      completeCallback,
      tpasOnPageBiParams,
      options,
    );
  }
};

export const removePage = function (
  editorAPI: EditorAPI,
  dsRead: AnyFixMe,
  dsActions: AnyFixMe,
  removePagePlugins: AnyFixMe,
  pageId: AnyFixMe,
  onRemove?: AnyFixMe,
  onCancel?: AnyFixMe,
  options?: AnyFixMe,
) {
  const pageRef = dsRead.pages.getReference(pageId);
  const pageData = dsRead.pages.data.get(pageId);
  const premiumTpaChildren = dsRead.tpa.getPremiumTpaChildrenOnPage(
    pageRef,
    pageData.tpaApplicationId,
    true,
  );
  const isBlogPage = dsRead.wixapps.classics.isBlogPage(pageId);
  const tpaChildrenRefs = dsRead.components.getTpaChildren(pageRef);
  let tpaChildrenRefsWithBlog = _.clone(tpaChildrenRefs);
  if (isBlogPage) {
    tpaChildrenRefsWithBlog = tpaChildrenRefsWithBlog.concat(
      dsRead.components.getBlogChildren(pageRef),
    );
  }
  const tpasOnPageBiParams = editorAPI.bi.getComponentsBIParams(
    tpaChildrenRefsWithBlog,
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(tpasOnPageBiParams, function (params) {
    // @ts-expect-error
    params.removal_method = 'delete_page';
  });

  const isMultiSectionInstalled =
    hasSection(pageData) &&
    dsRead.tpa.app.isMultiSectionInstalled(pageData.tpaApplicationId);

  const _isMandatorySection = isMandatorySection(
    editorAPI,
    pageData,
    premiumTpaChildren,
  );

  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  if (platformAPI.pageReplace.isReplacer(pageRef)) {
    platformAPI.pageReplace
      .openDeleteReplacerPageModal(pageRef)
      .then((result: PanelResolveType) => {
        const params = {
          app_id: pageData.managingAppDefId,
          host_app_id: editorAPI.dsRead.routers.getRouterDataForPageIfExist(
            pageData.id,
          )?.appDefinitionId,
          page_name: pageData.title,
          page_id: pageRef.id,
          target: '',
        };

        if (result === PanelResolveType.MAIN_ACTION) {
          params.target = 'delete_and_reset';
          editorAPI.bi.event(
            util.bi.events.REMOVE_COMPONENT_SECOND_STEP_CLICK,
            params,
          );
          actualRemovePage(
            editorAPI,
            dsRead,
            dsActions,
            pageId,
            pageData,
            removePagePlugins,
            false,
            tpasOnPageBiParams,
            onRemove,
            onCancel,
            options,
          );
        } else {
          params.target = 'cancel';
          editorAPI.bi.event(
            util.bi.events.REMOVE_COMPONENT_SECOND_STEP_CLICK,
            params,
          );
          onCancel();
        }
      });
  } else if (
    _isMandatorySection &&
    ((premiumTpaChildren.length > 0 && !isMultiSectionInstalled) ||
      (isMultiSectionInstalled &&
        showPremiumMessageWhenDeletingMultiSection(
          dsRead,
          pageData.tpaApplicationId,
          premiumTpaChildren.length,
        )))
  ) {
    const pageTitle = pageData?.title || '';
    const firstPremiumTpaChild = premiumTpaChildren[0];

    editorAPI.panelManager.openPanel(
      'tpaPanels.confirmation.deletePageContainsPremiumTpa',
      {
        appName: firstPremiumTpaChild.appDefinitionName,
        appDefId: firstPremiumTpaChild.appDefinitionId,
        appCount: premiumTpaChildren.length,
        pageTitle,
        onConfirm() {
          actualRemovePage(
            editorAPI,
            dsRead,
            dsActions,
            pageId,
            pageData,
            removePagePlugins,
            false,
            tpasOnPageBiParams,
            onRemove,
            onCancel,
            options,
          );

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
          _.forEach(premiumTpaChildren, function (tpaAppData, index) {
            const params = {
              app_id: tpaAppData.appDefinitionId,
              app_site_id: tpaAppData.instanceId,
              instance_id: tpaChildrenRefsWithBlog[index].id,
              is_last_tpa: dsRead.tpa.app.isLastAppComp(
                tpaAppData.applicationId,
              )
                ? 1
                : 0,
              component_type: 'tpa',
            };
            editorAPI.bi.event(
              util.bi.events.REMOVE_COMPONENT_SECOND_STEP_CLICK,
              params,
            );
          });
        },
        onCancel() {
          onCancel();
        },
      },
      true,
    );
  } else if (isBlogPage) {
    editorAPI.panelManager.openPanel(
      'blog.blogDeleteConfirmation',
      {
        onConfirm() {
          actualRemovePage(
            editorAPI,
            dsRead,
            dsActions,
            pageId,
            pageData,
            removePagePlugins,
            false,
            tpasOnPageBiParams,
            onRemove,
            onCancel,
          );
        },
        onCancel() {
          onCancel();
        },
      },
      true,
    );
  } else if (platformAPI.pages.isPageEssential(pageId)) {
    actualRemovePage(
      editorAPI,
      dsRead,
      dsActions,
      pageId,
      pageData,
      removePagePlugins,
      true,
      tpasOnPageBiParams,
      onRemove,
      onCancel,
      options,
    );
  } else {
    const { shouldShowEditorRemovePanel } = options || {};
    let shouldShowPageDeleteMessage =
      typeof shouldShowEditorRemovePanel === 'boolean'
        ? shouldShowEditorRemovePanel
        : true;
    if (!_isMandatorySection) {
      shouldShowPageDeleteMessage = false;
    }

    actualRemovePage(
      editorAPI,
      dsRead,
      dsActions,
      pageId,
      pageData,
      removePagePlugins,
      shouldShowPageDeleteMessage,
      tpasOnPageBiParams,
      onRemove,
      onCancel,
      options,
    );
  }
};

import TitleWithListTemplate from '../common/titleWithListTemplate';
import React from 'react';
import dataProvider from '../../dataProvider';
import { createProduct } from '../common/utils';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as utils from '../common/utils';
import type { Product } from '../common/types';
import ascendBi from '../common/bi/ascendBi';
import constants from '../common/constants';
import * as stateManagement from '#packages/stateManagement';
import SectionTitle from '../common/sectionTitle';

const TAB_NAME = 'MarketingSEO';
interface MarketingPanelProps {
  createMarketingProduct(name: string): Product;
  navigateToMarketingHome(): void;
  sendBi(event: object, parameters: object): void;
  biBaseParams: any;
  playVideo(): any;
}

class MarketingPanel extends React.Component<MarketingPanelProps> {
  componentDidMount() {
    const { sendBi, biBaseParams } = this.props;

    sendBi(ascendBi.events.TAB_VIEW, {
      ...biBaseParams,
      tab_name: TAB_NAME,
    });
  }

  onPlayVideo = () => {
    const { sendBi, biBaseParams, playVideo } = this.props;
    sendBi(ascendBi.events.ASCEND_PLAY_HELP_VIDEO, {
      ...biBaseParams,
      tab_name: TAB_NAME,
    });
    return playVideo();
  };

  onHoverVideoIcon = () => {
    const { sendBi, biBaseParams } = this.props;
    sendBi(ascendBi.events.ASCEND_HOVER_VIDEO_ICON, {
      ...biBaseParams,
      tab_name: TAB_NAME,
    });
  };

  render() {
    return (
      <TitleWithListTemplate
        title="AscendButton_MarketingSEO_SiteTraffic_Title"
        subtitle="AscendButton_MarketingSEO_SiteTraffic_Text"
        buttonText={translate('AscendButton_MarketingSEO_SiteTraffic_CTA')}
        sectionTitle={
          <SectionTitle
            title="AscendButton_MarketingSEO_Tools_SectionLabel"
            bubbleTitle="AscendButton_MarketingSEO_Tools_Video_Title"
            bubbleText="AscendButton_MarketingSEO_Tools_Video_Text"
            onPlayVideo={this.onPlayVideo}
            onHoverVideoIcon={this.onHoverVideoIcon}
            videoThumbnailSrc="https://wixmp-5ab3ce0ca26e3d0b1623b5b6.wixmp.com/MKT.png"
          />
        }
        onButtonClicked={() => {
          this.props.sendBi(ascendBi.events.PRODUCT_CLICK, {
            ...this.props.biBaseParams,
            tab_name: 'MarketingSEO',
            product_name: 'marketingHome',
          });
          this.props.navigateToMarketingHome();
        }}
        items={dataProvider.marketingProductItemIds
          .map((name) => this.props.createMarketingProduct(name))
          .filter((product) => product.isAvailable())}
      />
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const biBaseParams = ascendBi.biUtils.getBiBaseParams({ editorAPI, state });
  const playVideo = () =>
    utils.playVideoOnEditor(editorAPI, constants.VIDEO_IDS.MKT);

  return {
    biBaseParams,
    playVideo,
  };
};

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    enrichCreateMarketingProduct: (bi: AnyFixMe) => (name: AnyFixMe) =>
      createProduct('MarketingSEO', name, editorAPI, bi),
    navigateToMarketingHome: () =>
      utils.navigateToAscend(editorAPI, 'marketingHome'),
    sendBi: (event: AnyFixMe, parameters: AnyFixMe) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
  };
};

const mergeProps = (
  stateProps: AnyFixMe,
  dispatchProps: AnyFixMe,
  ownProps: AnyFixMe,
) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    createMarketingProduct: dispatchProps.enrichCreateMarketingProduct({
      sendBi: dispatchProps.sendBi,
      biBaseParams: stateProps.biBaseParams,
    }),
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(MarketingPanel);
ConnectedComponent.pure = MarketingPanel;

export default ConnectedComponent;

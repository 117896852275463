import constants from '#packages/constants';
import { newAddPanelAPI } from '#packages/newAddPanelAPI';
import type { EditorAPI } from '#packages/editorAPI';
import { Integrations } from '../constants';
import { searchModule } from '../searchModule';
import { createPanelIntents, IntentType } from './createPanelIntents';

interface AddPanelIntent {
  type: IntentType;
  props?: {
    category: string;
    sectionId: string;
  };
}

function getAddPanelTransformFunction(addPanelIntents: AnyFixMe) {
  return (result: AnyFixMe) => {
    const { categoryID, sectionName } = result.extras;

    const categoryAndSection = newAddPanelAPI.getCategoryAndSectionSync(
      categoryID,
      sectionName,
    );

    if (!categoryAndSection?.section) {
      return;
    }

    const { category, section } = categoryAndSection;

    return {
      ...result,
      onEnter: () => {
        addPanelIntents.set({
          type: IntentType.OPEN,
          props: {
            category: category.id,
            sectionId: section?.sectionName,
          },
        });
      },
      onLeave: () => {
        addPanelIntents.set({
          type: IntentType.CLOSE,
        });
      },
    };
  };
}

export const integrateNewAddPanelDesktopToSearch = (editorAPI: EditorAPI) => {
  const addPanelIntents = createPanelIntents<AddPanelIntent>(
    editorAPI,
    constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME,
  );

  searchModule.addIntegration({
    type: Integrations.ADD_PANEL,
    checkVisibility: () => {
      return !editorAPI.isMobileEditor();
    },
    transform: getAddPanelTransformFunction(addPanelIntents),
  });
};

export const integrateNewAddPanelMobileToSearch = (editorAPI: EditorAPI) => {
  const addPanelIntents = createPanelIntents<AddPanelIntent>(
    editorAPI,
    constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME,
  );

  searchModule.addIntegration({
    type: Integrations.MOBILE_ADD_PANEL,
    checkVisibility: () => {
      return editorAPI.isMobileEditor();
    },
    transform: getAddPanelTransformFunction(addPanelIntents),
  });
};

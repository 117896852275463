import React, { useEffect, useState } from 'react';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import type { ThunkAction, Dispatch } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import {
  DeleteAppModal,
  DeleteAppOriginSource,
} from '@wix/editor-platform-host-integration/ui';
import type { PanelResolveType } from '@wix/platform-editor-sdk';
import ListParser, { type PanelTag } from '../ListParser/listParser';
import { WixBaseUiEnvironmentProvider } from '@wix/wix-base-ui';

const Z_INDEX = 1710;

const PlatformDeleteAppModal: React.FC<
  PlatformDeleteAppModalProps & PlatformDeleteAppModalDispatchProps
> = (props) => {
  const [appMarketingData, setAppMarketingData] = useState<any>();
  const [appCustomContent, setAppCustomContent] = useState<{
    noteText?: string | PanelTag[];
    helpId?: string;
  }>();
  const [keepModalOpen, setKeepModalOpen] = useState<boolean>(true);

  const { noteText, helpId } = appCustomContent;

  const { getAppMarketingData, getAppCustomDeletePanelContent } = props;

  useEffect(() => {
    util.keyboardShortcuts.setContext(
      util.keyboardShortcuts.CONTEXTS.DELETE_APP_MODAL,
    );

    util.keyboardShortcuts.registerContext(
      util.keyboardShortcuts.CONTEXTS.DELETE_APP_MODAL,
      {
        esc: () => {
          setKeepModalOpen(false);
        },
      },
    );
  }, []);

  useEffect(() => {
    if (!appMarketingData) {
      getAppMarketingData().then((appData) => {
        setAppMarketingData({
          iconUrl: appData?.appIcon,
        });
      });
    }
  }, [appMarketingData, getAppMarketingData]);

  useEffect(() => {
    if (!appCustomContent) {
      getAppCustomDeletePanelContent().then(setAppCustomContent);
    }
  }, [appCustomContent, getAppCustomDeletePanelContent]);

  const getAppNote = () =>
    noteText ? (
      <WixBaseUiEnvironmentProvider madefor>
        <ListParser description={noteText} size="small" skin="secondary" />
      </WixBaseUiEnvironmentProvider>
    ) : null;

  return appCustomContent ? (
    <DeleteAppModal
      keepModalOpen={keepModalOpen}
      onClose={props.handleClose}
      zIndex={Z_INDEX}
      origin={props.origin}
      appName={props.appName}
      appNote={getAppNote()}
      appIconUrl={appMarketingData?.iconUrl}
      displayFootnote={props.isPremium}
      openHelpCenter={props.getOpenHelpCenter(helpId)}
    />
  ) : null;
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: PlatformDeleteAppModalProps,
): PlatformDeleteAppModalDispatchProps => {
  const lastKeyboardContext = util.keyboardShortcuts.getContext();
  const defaultHelpId = 'e54ed9ea-4095-4b72-9727-43a446eafd05';

  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const handleClose = (resolveType: PanelResolveType) => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    util.keyboardShortcuts.setContext(lastKeyboardContext);
    ownProps.onClose({ status: resolveType });
  };

  const getOpenHelpCenter = (appCustomHelpId: string) => () =>
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        appCustomHelpId ?? defaultHelpId,
      ),
    );

  const getAppCustomDeletePanelContent = async () => {
    const appApi = await editorAPI.platform.getAppEditorApi(
      ownProps.appDefinitionId,
    );
    const customContent = await appApi?.getCustomDeletePanelContent({
      compId: ownProps.compId,
    });
    return {
      noteText: customContent?.descriptionText,
      helpId: customContent?.helpId,
    };
  };

  const getAppMarketingData = () =>
    editorAPI.dsRead.tpa.appMarket.getDataAsync(ownProps.appDefinitionId);

  const isPremium = editorAPI.dsRead.tpa.app.isPremiumByAppDefinitionId(
    ownProps.appDefinitionId,
  );

  const appName = editorAPI.dsRead.platform.getAppDataByAppDefId(
    ownProps.appDefinitionId,
  )?.appDefinitionName;

  return {
    handleClose,
    getOpenHelpCenter,
    getAppCustomDeletePanelContent,
    getAppMarketingData,
    isPremium,
    appName,
  };
};

interface PlatformDeleteAppModalProps {
  panelName: string;
  origin: { source: DeleteAppOriginSource; name?: string };
  compId?: string;
  appDefinitionId: string;
  onClose(resolveType: { status: PanelResolveType }): void;
}

interface PlatformDeleteAppModalDispatchProps {
  getOpenHelpCenter(appCustomHelpId: string): () => void;
  handleClose(resolveType: PanelResolveType): void;
  getAppCustomDeletePanelContent: () => Promise<{
    noteText?: string | PanelTag[];
    helpId?: string;
  }>;
  getAppMarketingData: () => Promise<AnyFixMe>;
  isPremium: boolean;
  appName: string;
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapDispatchToProps,
)(PlatformDeleteAppModal);

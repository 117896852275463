import type {
  CompRef,
  StyleOverridesGroupedByVariant,
  StyleRef,
} from '@wix/document-services-types';
import type { EditorAPI } from '#packages/editorAPI';
import * as platformEvents from 'platformEvents';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import _ from 'lodash';
import experiments from 'experiment';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';

type NotifyPresetChangedFn = (presetId: string) => void;

const EXTERNAL_PRESET_PREFIX = 'externalPreset';

const isExternalPreset = (presetId: string) =>
  presetId.startsWith(EXTERNAL_PRESET_PREFIX);

const notifyPlatformBuilderPresetChanged = (
  editorAPI: EditorAPI,
  controllerRef: CompRef,
) => {
  const notifyPresetChanged = getNotifyPresetChanged(editorAPI, controllerRef);

  return (presetId: string) => {
    if (!isExternalPreset(presetId)) {
      editorAPI.store.dispatch(
        stateManagement.platform.actions.changePresetInCurrentContext(
          controllerRef,
          {
            layoutPresetId: presetId,
            stylePresetId: presetId,
          },
        ),
      );
    }

    notifyPresetChanged(presetId);
  };
};

const getChangeBlocksPresetAndNotify = (
  editorAPI: EditorAPI,
  controllerRef: CompRef,
  refComponent: CompRef,
) => {
  const notifyPresetChanged = getNotifyPresetChanged(editorAPI, controllerRef);

  return (presetId: string) => {
    if (!isExternalPreset(presetId)) {
      const mobileVariant = editorAPI.mobile.getMobileVariant();

      editorAPI.store.dispatch(
        stateManagement.platform.actions.changePresetInCurrentContext(
          refComponent,
          {
            layoutPresetId: presetId,
            stylePresetId: presetId,
          },
        ),
      );

      if (editorAPI.isMobileEditor() && mobileVariant) {
        const selectedComponent = _.head(
          editorAPI.selection.getSelectedComponents(),
        );
        const stylesOverrides =
          editorAPI.dsRead.appStudioWidgets.getStyleOverridesGroupedByVariant(
            selectedComponent,
          );

        stylesOverrides.forEach(
          ({ compRef, overridesByVariant }: StyleOverridesGroupedByVariant) => {
            if (
              overridesByVariant[mobileVariant.id] ||
              !overridesByVariant.default
            ) {
              return;
            }

            const compMobileVariantPointer =
              editorAPI.components.variants.getPointer(compRef, [
                mobileVariant,
              ]);

            if (!compMobileVariantPointer) {
              return;
            }

            overridesByVariant.default.forEach((styleData) => {
              editorAPI.dsActions.components.stylable.update(
                compMobileVariantPointer,
                styleData as StyleRef,
              );
            });
          },
        );
      }
    }

    notifyPresetChanged(presetId);
  };
};

export const getNotifyPresetChanged = (
  editorAPI: EditorAPI,
  controllerRef: CompRef,
): NotifyPresetChangedFn => {
  let id: number | string;

  const compType = editorAPI.components.getType(controllerRef);
  // TODO: remove any after merge https://github.com/wix-private/editor-platform/pull/6395
  const editorPlatformHostIntegrationAPI = editorAPI.host.getAPI(
    EditorPlatformHostIntegrationAPI,
  ) as any;

  const isManifestBuilderComponentPresetsPanelOpen = experiments.isOpen(
    'specs.responsive-editor.manifestBuilderComponentPresetsPanel',
  );

  const isBuilderComponent =
    isManifestBuilderComponentPresetsPanelOpen &&
    editorPlatformHostIntegrationAPI.manifest.isBuilderComponentType(compType);

  if (isBuilderComponent) {
    const [, , appDefinitionIdAndWidgetId] = compType.split('.');
    const [appDefinitionId] = appDefinitionIdAndWidgetId.split('_');

    id = appDefinitionId;
  } else {
    const appDefId = editorAPI.components.data.get(controllerRef).applicationId;
    id = editorAPI.platform.getAppDataByAppDefId(appDefId).applicationId;
  }

  return (preset) => {
    const notifier = isManifestBuilderComponentPresetsPanelOpen
      ? 'notifyApplicationByAppDefId'
      : 'notifyApplication';

    editorAPI.platform[notifier](
      id as never,
      platformEvents.factory.globalDesignPresetChanged({
        preset,
        componentRef: controllerRef,
      }),
    );
  };
};

export const getOnPresetChanged = (
  editorAPI: EditorAPI,
  controllerRef: CompRef,
): NotifyPresetChangedFn => {
  const notifyPresetChanged = getNotifyPresetChanged(editorAPI, controllerRef);
  const refComponent =
    editorAPI.dsRead.components.refComponents.getRefHostCompPointer(
      controllerRef,
    );

  if (
    experiments.isOpen(
      'specs.responsive-editor.manifestBuilderComponentPresetsPanel',
    )
  ) {
    return notifyPlatformBuilderPresetChanged(editorAPI, controllerRef);
  }

  return refComponent &&
    util.appStudioUtils.isResponsiveBlocksWidget(editorAPI, refComponent)
    ? getChangeBlocksPresetAndNotify(editorAPI, controllerRef, refComponent)
    : notifyPresetChanged;
};

export const getSelectedWidgetPresetId = async (
  editorAPI: EditorAPI,
  componentRef: CompRef,
): Promise<string | undefined> => {
  const compData = editorAPI.components.data.get(componentRef);
  const appDefId = compData?.applicationId || compData?.appDefinitionId;
  const appEditorAPI = await editorAPI.platform.getAppEditorApi(appDefId);
  return appEditorAPI?.getSelectedWidgetDesignPresetId?.({
    widgetRef: componentRef,
  });
};

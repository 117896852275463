import createWixData from '@wix/wix-data-platformized-client';
import { Segment } from '@wix/ambassador-data-v1-data-service/types';
import { HttpClient } from '@wix/http-client';

import type { EditorAPI } from '#packages/editorAPI';

export const createWixCodeCollectionsAPI = (editorAPI: EditorAPI) => {
  return createWixData({
    authHeader: editorAPI.dsRead.wixCode.getClientSpec().instance,
    gridAppId: editorAPI.wixCode.fileSystem.getViewerInfo().gridAppId,
    segment: Segment.SANDBOX,
    httpClient: new HttpClient(),
  });
};

import React from 'react';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import AddPageCarouselGallery from './addPageCarouselGallery';
import {
  Button,
  Composites,
  Illustration,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import {
  mapDispatchToProps,
  type AddPagePreInstallAppDispatchProps,
  type AddPagePreInstallAppOwnProps,
} from './addPagePreInstallAppMapper';

export interface AddPagePreInstallAppProps
  extends AddPagePreInstallAppDispatchProps,
    AddPagePreInstallAppOwnProps {}

const AddPagePreInstallApp = (props: AddPagePreInstallAppProps) => {
  const { sendBICarouselArrow } = props;
  const {
    appDefinitionId,
    mainTitle,
    subTitle,
    appNumPages,
    secondaryNumPages,
    appNumPagesTitle,
    appNumPagesDescription,
    secondaryNumPagesTitle,
    secondaryNumPagesDescription,
    appSymbolName,
    secondarySymbolName,
    mainActionTitle,
    images,
  } = props.category.application;
  return (
    <div className="add-page-pre-install-app">
      <div className="add-page-pre-install-app-content">
        <Composites.RichTextLabeled className="app-page-pre-install-texts">
          <TextLabel
            className="app-page-pre-install-title"
            type="T08"
            value={mainTitle}
          />
          <RichText
            className="app-page-pre-install-description"
            type="T05"
            children={translate(subTitle)}
          />
        </Composites.RichTextLabeled>
        <Composites.RichTextWithIllustration>
          <Illustration
            className="app-page-illustration"
            children={<symbols.symbol name={appSymbolName} />}
          />
          <Composites.RichTextLabeled>
            <TextLabel
              type="T05"
              className="num-pages-title"
              shouldTranslate={false}
              value={translate(appNumPagesTitle, { num_pages: appNumPages })}
            />
            <RichText
              type="T02"
              className="num-pages-description"
              children={translate(appNumPagesDescription)}
            />
          </Composites.RichTextLabeled>
        </Composites.RichTextWithIllustration>
        <Composites.RichTextWithIllustration>
          {secondarySymbolName && (
            <Illustration
              className="app-page-illustration"
              children={<symbols.symbol name={secondarySymbolName} />}
            />
          )}
          {secondaryNumPagesTitle && secondaryNumPagesDescription && (
            <Composites.RichTextLabeled>
              <TextLabel
                type="T05"
                className="num-pages-title"
                shouldTranslate={false}
                value={translate(secondaryNumPagesTitle, {
                  num_pages: secondaryNumPages,
                })}
              />
              <RichText
                type="T02"
                className="num-pages-description"
                children={translate(secondaryNumPagesDescription)}
              />
            </Composites.RichTextLabeled>
          )}
        </Composites.RichTextWithIllustration>
        <Button onClick={props.addApp} className="primary add-app-page-button">
          <TextLabel type="T01" value={mainActionTitle} />
        </Button>
      </div>
      {images.length > 0 ? (
        <AddPageCarouselGallery
          onMoveLeft={() => sendBICarouselArrow('Left')}
          onMoveRight={() => sendBICarouselArrow('right')}
          key={appDefinitionId}
          galleryUnique={appDefinitionId}
          images={images}
        />
      ) : null}
    </div>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(AddPagePreInstallApp);

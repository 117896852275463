import constants from '#packages/constants';

function navigateTo(
  editorAPI: AnyFixMe,
  appData: AnyFixMe,
  token: AnyFixMe,
  options: AnyFixMe,
) {
  return new Promise<void>(function (resolve) {
    editorAPI.dsActions.platform.pages.navigateTo(options.pageUrl, function () {
      if (!editorAPI.preview.isInPreviewMode()) {
        editorAPI.preview.togglePreview(resolve, {
          biParams: { origin: constants.BI.PREVIEW.ORIGIN.PLATFORM },
        });
      } else {
        resolve();
      }
    });
  });
}

export { navigateTo };

import _ from 'lodash';
import { EditorAPIKey, LayoutApiKey } from '#packages/apis';
import * as core from '#packages/core';
import {
  arrayUtils,
  compIconUtils,
  keyboardShortcuts,
  appStudioUtils,
} from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as ArrangementWrapper from './arrangementFacade';
import * as BehaviorsWrapper from './behaviorsFacade';
import * as ComponentsMetaDataWrapper from './componentsMetaDataFacade';

import {
  componentSelectors,
  selectionSelectors,
  userPreferencesSelectors,
  userPreferencesActions,
  interactionsSelectors,
  hoverBoxActions,
  multilingualActions,
} from '#packages/stateManagement';
import * as removeUtil from './removeUtil';
import * as coreBi from '#packages/coreBi';
import constants, { type MatchSizeValue } from '#packages/constants';
import { Hooks, type Shell } from '#packages/apilib';
import { utils as gfppDataUtils } from '#packages/gfppData';
import {
  getComponentCodeNickname,
  areComponentsOfSamePage as areComponentsOfSamePageInner,
  getComponentsWithoutAncestorsInArray,
} from '#packages/documentServices';

//TODO rm import
//eslint-disable-next-line @wix/santa-editor/scoped-imports
import * as serializedComponentPlugins from '@/rEditor/app/serializedComponentPlugins';

import { createComponentsGetApi } from './componentsGetApi';
import { createComponentsDataApi } from './componentsDataApi';
import { createComponentsDesignApi } from './componentsDesignApi';
import { createComponentsPropertiesApi } from './componentsPropertiesApi';
import { createComponentsAlignmentApi } from './createComponentsAlignmentApi';
import { createComponentsStyleApi } from './componentsStyleApi';
import { createComponentsStylableApi } from './componentsStylableApi/componentsStylableApi';
import { createComponentsModesApi } from './componentsModesApi';
import { createComponentsReparentApi } from './componentsReparentApi';
import { createComponentsShowOnAllPagesApi } from './componentsShowOnAllPagesApi';
import { createComponentsGroupApi } from './componentsGroupApi';
import { getDisplayName as getDisplayNameInner } from './displayName/getDisplayName';

import type {
  DSRead,
  CompRef,
  Size,
  CompStructure,
} from 'types/documentServices';
import type { IconInfo } from 'types/core';
import type { ComponentAddedToStageData } from './types';

export function createComponentsApi(shell: Shell) {
  const hooks = {
    componentDeleted: Hooks.createHook<{
      compType: string;
      compRefs: CompRef[];
    }>(),
    componentAddedToStage: Hooks.createHook<ComponentAddedToStageData>(),
    componentDesignUpdated: Hooks.createHook<{
      compRef: CompRef;
      designItem: { type: string };
    }>(),
    wouldShowModal: Hooks.createHook<{ components: CompRef[] }, boolean>(),
  };
  const editorAPI = shell.getAPI(EditorAPIKey) as any;
  const componentsGetApi = createComponentsGetApi({
    editorAPI,
  });
  const componentsDataApi = createComponentsDataApi({
    editorAPI,
  });
  const componentsDesignApi = createComponentsDesignApi({
    editorAPI,
  });
  const componentsPropertiesApi = createComponentsPropertiesApi({
    editorAPI,
  });
  const componentsAlignmentApi = createComponentsAlignmentApi({
    editorAPI,
  });
  const componentsStyleApi = createComponentsStyleApi({
    editorAPI,
  });
  const componentsStylableApi = createComponentsStylableApi({
    editorAPI,
    componentsStyleApi,
  });
  const componentsModesApi = createComponentsModesApi({
    editorAPI,
    componentsGetApi,
  });
  const componentsReparentApi = createComponentsReparentApi({
    editorAPI,
    componentsModesApi,
  });
  const componentsShowOnAllPagesApi = createComponentsShowOnAllPagesApi({
    editorAPI,
    componentsGetApi,
  });
  const componentsGroupApi = createComponentsGroupApi({
    editorAPI,
  });
  //TODO FIX EDITOR_API
  const BOX_SLIDE_SHOW_COMP_TYPE = 'wysiwyg.viewer.components.BoxSlideShow';
  const STRIP_SLIDE_SHOW_COMP_TYPE =
    'wysiwyg.viewer.components.StripContainerSlideShow';

  function areComponentsOfSamePage(compRefs: CompRef[]) {
    if (!compRefs) {
      return false;
    }
    if (!Array.isArray(compRefs)) {
      return true;
    }
    return areComponentsOfSamePageInner(editorAPI.dsRead, compRefs);
  }

  function hasRuntimeDataOrConnected(compRefs: CompRef[]) {
    const haveRuntimeData = hasRuntimeData(compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    const repeaterParentIsConnected = _.some(
      _.castArray(compRefs),
      (compRef) => {
        const repeaterParent =
          editorAPI.components.getAncestorRepeater(compRef);
        if (repeaterParent) {
          return !_.isEmpty(
            editorAPI.dsRead.platform.controllers.connections.get(
              repeaterParent,
            ),
          );
        }
        return false;
      },
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    const isConnected = _.some(
      _.castArray(compRefs),
      (compRef) =>
        !_.isEmpty(
          editorAPI.dsRead.platform.controllers.connections.get(compRef),
        ),
    );
    return haveRuntimeData || isConnected || repeaterParentIsConnected;
  }

  function hasRuntimeData(compRefs: CompRef[]) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return _.some(_.castArray(compRefs), (compRef) =>
      editorAPI.dsRead.components.data.hasRuntimeChanges(compRef),
    );
  }

  const getNonRemovableTPAChild = (compRef: CompRef): CompRef | undefined => {
    return findTpaChild(
      compRef,
      (child) => !editorAPI.components.is.removable(child),
    );
  };
  const getNonDuplicatableTPAChild = (
    compRef: CompRef,
  ): CompRef | undefined => {
    return findTpaChild(
      compRef,
      (child) => !editorAPI.components.is.duplicatable(child),
    );
  };

  function getAdditionalComponentsToDeselect(
    compsToDeselect: CompRef[],
    selectedComponents: CompRef[],
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(selectedComponents, function (compRef) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      return _.some(compsToDeselect, (compToBeRemoved) =>
        componentsGetApi.isDescendantOfComp(compRef, compToBeRemoved),
      );
    });
  }

  function canBeMasterChild(compRefs: CompRef | CompRef[], compType?: string) {
    const compsArr = arrayUtils.asArray(compRefs);

    if (compsArr.length > 1) {
      return false;
    }
    const singleCompRef = _.head(compsArr);
    if (compType) {
      return editorAPI.dsRead.layouters.canBeMasterChild(
        singleCompRef,
        compType,
      );
    }
    return (
      editorAPI.dsRead.layouters.canBeMasterChild(
        singleCompRef,
        BOX_SLIDE_SHOW_COMP_TYPE,
      ) ||
      editorAPI.dsRead.layouters.canBeMasterChild(
        singleCompRef,
        STRIP_SLIDE_SHOW_COMP_TYPE,
      )
    );
  }

  function onPreviewReady() {
    const previewReadyPlugins = core.utils.componentPreviewReadyPlugins;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(previewReadyPlugins, function (plugin) {
      plugin(editorAPI);
    });

    try {
      const isPageDesignButtonHidden =
        userPreferencesSelectors.getSiteUserPreferences(
          constants.USER_PREFS.IS_PAGE_DESIGN_BUTTON_HIDDEN,
        )(editorAPI.store.getState());

      if (isPageDesignButtonHidden) return;

      const shouldPageDesignButtonHide =
        gfppDataUtils.shouldHidePageDesignButton(editorAPI);

      if (!shouldPageDesignButtonHide) return;

      editorAPI.store.dispatch(
        userPreferencesActions.setSiteUserPreferences(
          constants.USER_PREFS.IS_PAGE_DESIGN_BUTTON_HIDDEN,
          true,
        ),
      );
    } catch (error) {
      ErrorReporter.captureException(error, {
        tags: {
          failedToInitializeHidePageDesign: true,
        },
      });
    }
  }

  function getPage(compRefs: CompRef | CompRef[]) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length === 1 || areComponentsOfSamePage(compsArr)) {
      return editorAPI.dsRead.components.getPage(_.head(compsArr));
    }

    return null;
  }

  function reset() {
    editorAPI.clipboard.removeItem();
  }

  function getComponentsWhichDontHaveAncestorsInTheArray(
    compRefs: CompRef | CompRef[],
  ) {
    return getComponentsWithoutAncestorsInArray(
      editorAPI.dsRead,
      arrayUtils.asArray(compRefs),
    );
  }

  /**
   * Function set order for components according its index
   * in all components array, basically it use for fix layer position(z-index)
   * @param {Array} compRefs array of compRefs objects
   */
  function normalizeOrder(compRefs: CompRef[]) {
    if (compRefs.length <= 1) {
      return compRefs;
    }
    const allComponents: CompRef[] = editorAPI.components.getAllComponents();
    return allComponents.reduceRight((result: CompRef[], item: CompRef) => {
      const el = compRefs.find((compRef: CompRef) =>
        editorAPI.dsRead.utils.isSameRef(compRef, item),
      );
      if (el) {
        result.push(el);
      }
      return result;
    }, []);
  }

  /**
   * @param {Array} compRefs array of compRefs objects
   * @return {Array} Array of uniq components ordered by its index in all components
   */
  function getUniqOrderedComponents(compRefs: CompRef[]) {
    let components = arrayUtils.asArray(compRefs);
    components = getComponentsWhichDontHaveAncestorsInTheArray(components);
    components = normalizeOrder(components);

    return components;
  }

  function cut(compPointers: CompRef[]) {
    let selectedComps = editorAPI.selection.getSelectedComponents();

    selectedComps =
      getComponentsWhichDontHaveAncestorsInTheArray(selectedComps);
    compPointers = getComponentsWhichDontHaveAncestorsInTheArray(compPointers);

    if (_.isEqual(selectedComps, compPointers)) {
      editorAPI.selection.deselectComponents();
    }

    if (
      editorAPI.isMobileEditor() &&
      compPointers?.some(
        (compRef: CompRef) => !editorAPI.components.is.duplicatable(compRef),
      )
    ) {
      editorAPI.mobile.notifyCantCutNonMobileOnly();
      return;
    }

    if (editorAPI.components.is.duplicatable(compPointers)) {
      editorAPI.copyPaste.copy(compPointers, true);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(compPointers, function (compRef) {
        removeUtil.cutComponent(
          editorAPI.dsRead,
          editorAPI.dsActions,
          compRef,
          editorAPI.cutComponentPlugins,
        );
      });
    }
  }

  function add(
    containerRef: CompRef,
    compDef: CompStructure,
    optionalId?: string,
    onCompAddCallback?: (compRef: CompRef) => void,
    origin?: string,
    options?: { optionalIndex?: number; showAddToHeaderPanel?: boolean },
    addFunction?: typeof editorAPI.dsActions.components.add,
    contentSource?: string,
    appDefinitionId?: string,
  ): ComponentsAddResult {
    options = options || {};
    editorAPI.mouseActions.turnOffMouseEvents();
    const originalAddComponentFunc =
      addFunction || editorAPI.dsActions.components.add;
    const { optionalIndex } = options;

    const addInner = (): ComponentsAddResult =>
      core.utils.addUtil.addComponent(editorAPI, {
        originalAddComponentFunc,
        containerRef,
        compDef,
        optionalId,
        onCompAddCallback,
        origin,
        optionalIndex,
        contentSource,
        appDefinitionId,
      });

    const addInnerAfter = (compRef: CompRef) => {
      editorAPI.store.dispatch(multilingualActions.componentChanged());

      if (
        editorAPI.utils.isSameRef(
          containerRef,
          editorAPI.dsRead.siteSegments.getHeader(),
        ) &&
        options.showAddToHeaderPanel !== false
      ) {
        //todo Shimi_Liderman 31/07/2016 12:18 check with Shtekel if he added a mechanism for this type of operations
        editorAPI.panelHelpers.openDragToHeaderPanel();
      }

      const commandPressedForKeepPanelOpen =
        keyboardShortcuts.isPressed.command();
      const isOldAddPanelOpened = editorAPI.panelManager.isPanelOpened(
        constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
      );
      const isNewAddPanelOpened = editorAPI.panelManager.isPanelOpened(
        constants.ROOT_COMPS.LEFTBAR.NEW_ADD_PANEL_NAME,
      );
      const isAddPanelOpened = isOldAddPanelOpened || isNewAddPanelOpened;

      if (isAddPanelOpened) {
        const closeAddPanel = !commandPressedForKeepPanelOpen;
        if (closeAddPanel) {
          editorAPI.panelManager.closePanelByName(
            constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
          );
        } else {
          editorAPI.bi.event(
            coreBi.events.shortcuts.keep_add_panel_open_add_comp,
          );
        }
      }

      if (componentsModesApi.isModefulComponent(containerRef) && compRef.id) {
        const activeModeIds =
          editorAPI.components.modes.getComponentActiveModeIds(containerRef);
        editorAPI.components.modes.showComponentOnlyInModesCombination(
          compRef,
          Object.keys(activeModeIds),
        );
        editorAPI.tabIndicationState.activateAddAnimation(true);
        componentsModesApi.addDefaultSingleModeAnimations(compRef);
      }

      return compRef;
    };

    try {
      const compRefOrAddResult = addInner();
      if (!compRefOrAddResult) {
        return;
      }

      if (!('hooks' in compRefOrAddResult)) {
        throw new Error(
          'invalid `ComponentAddResult` value (should be an object with hooks property).',
        );
      }

      const { hooks } = compRefOrAddResult;
      const waitForCompRef = hooks.waitForCompRef.then((compRef) => {
        addInnerAfter(compRef);
        return compRef;
      });

      return {
        hooks: {
          waitForCompRef,
          waitForChangesApplied: waitForCompRef.then(
            () => hooks.waitForChangesApplied,
          ),
        },
      };
    } catch (error) {
      return {
        hooks: {
          waitForCompRef: Promise.reject(error),
          waitForChangesApplied: Promise.reject(error),
        },
      };
    }
  }

  function addAndAdjustLayout(
    containerRef: CompRef,
    compDef: CompStructure,
    optionalId: string,
    onCompAddCallback: (compRef: CompRef) => void,
  ) {
    const originalAddComponentFunc =
      editorAPI.dsActions.components.addAndAdjustLayout;
    return core.utils.addUtil.addComponent(editorAPI, {
      originalAddComponentFunc,
      containerRef,
      compDef,
      optionalId,
      onCompAddCallback,
    });
  }

  function removePopupWithMessagePanel(
    popupRef: CompRef,
    removeOrigin: string,
  ) {
    removeUtil.removePopupWithMessagePanel(editorAPI, popupRef, removeOrigin);
  }

  function remove(
    compRefs: CompRef | CompRef[],
    removeArgs?: { isReplacingComp?: boolean; preventPanelClose?: boolean },
    origin?: string,
  ): Promise<boolean> {
    const editorState = editorAPI.store.getState();
    const focusedContainer =
      selectionSelectors.getFocusedContainer(editorState);
    const appContainer = selectionSelectors.getAppContainer(editorState);
    const components = arrayUtils.asArray(compRefs);

    if (!editorAPI.components.is.removable(components)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(components, function (component) {
        const compType = editorAPI.components.getType(component);
        if (editorAPI.cannotRemovePlugins[compType]) {
          editorAPI.cannotRemovePlugins[compType](component);
        }
        if (editorAPI.cannotRemovePlugins['*']) {
          editorAPI.cannotRemovePlugins['*'](component);
        }
      });

      return Promise.resolve(false);
    }

    const selectedComponents = editorAPI.selection.getSelectedComponents();

    // TODO: Remove when mobile hide is supported in app studio
    const isAppStudioMobileHide =
      appStudioUtils.isAppStudio() && editorAPI.isMobileEditor();

    const appContainerExistsAfterRemove =
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      appContainer && !_.find(components, appContainer);
    const focusedContainerExistsAfterRemove =
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      focusedContainer && !_.find(components, focusedContainer);

    if (appContainerExistsAfterRemove) {
      editorAPI.selection.selectComponentByCompRef(appContainer);
    } else if (focusedContainerExistsAfterRemove) {
      editorAPI.selection.selectComponentByCompRef(focusedContainer);
    } else if (
      (!removeArgs || !removeArgs.isReplacingComp) &&
      !isAppStudioMobileHide
    ) {
      const compsToDeselect = components.concat(
        getAdditionalComponentsToDeselect(components, selectedComponents),
      );
      editorAPI.selection.deselectComponents(compsToDeselect);
    }

    componentsModesApi.addDefaultSingleModeAnimations(compRefs);

    let completeCallback = _.noop;

    const container = componentsGetApi.getContainer(compRefs);
    if (editorAPI.components.is.controlledByParent(compRefs)) {
      const isContainerSelected = _.isEqual(container, selectedComponents?.[0]);

      if (isContainerSelected) {
        completeCallback = function () {
          if (editorAPI.components.is.exist(container)) {
            if (!removeArgs?.preventPanelClose) {
              editorAPI.panelManager.closeAllPanels();
            }

            editorAPI.selection.selectComponentByCompRef(container);
          } else {
            editorAPI.selection.deselectComponents();
          }
        };
      }
    }

    if (
      !_.isEmpty(components) &&
      componentSelectors.isReferredComponent(_.head(components))
    ) {
      completeCallback = function () {
        editorAPI.store.dispatch(hoverBoxActions.clearHoverBox());
      };
    }

    const wouldShowModal = hooks.wouldShowModal
      .fire({ components })
      .some((v) => v === true);

    const beforePromise = editorAPI.platform.beforeRemoveComponents(components);

    const removePromise = beforePromise.then(() => {
      return removeUtil
        .removeComponent(shell, components, removeArgs, origin)
        .then(() => {
          if (!wouldShowModal) {
            editorAPI.store.dispatch(multilingualActions.componentChanged());
          }
          ErrorReporter.breadcrumb('component removed', { origin, compRefs });
          completeCallback();

          return true;
        });
    });

    if (componentsModesApi.isModefulComponent(container)) {
      editorAPI.dsActions.waitForChangesApplied(function () {
        editorAPI.tabIndicationState.activateDeleteAnimation();
      });
    }

    return removePromise;
  }

  function reparentComponentToPage(compRefs: CompRef[], keepPosition: boolean) {
    const compsArr = arrayUtils.asArray(compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const updatedCompRefs = _.map(compsArr, (compRef) =>
      editorAPI.dsActions.components.reparentComponentToPage(
        compRef,
        keepPosition,
      ),
    );

    return compsArr.length > 1 ? updatedCompRefs : updatedCompRefs[0];
  }

  function getType(compRefs: CompRef | CompRef[]) {
    return componentSelectors.getCompType(compRefs, editorAPI.dsRead);
  }

  function getTPAChildren(compRef: CompRef) {
    return editorAPI.dsRead.components.getTPAChildren(compRef);
  }

  function findTpaChild(
    compRef: CompRef,
    predicate: (childCompRef: CompRef) => boolean,
  ): CompRef | undefined {
    const dsRead = editorAPI.dsRead as DSRead;
    return (
      dsRead.components
        .getTpaChildren(compRef)
        .find((childCompRef) => predicate(childCompRef)) ??
      dsRead.components.get
        .byType(constants.COMP_TYPES.APP_CONTROLLER, compRef)
        .find((childCompRef) => predicate(childCompRef)) ??
      dsRead.components.get
        .byType(constants.COMP_TYPES.REF_COMPONENT, compRef)
        .find((childCompRef) => predicate(childCompRef))
    );
  }

  function getDisplayName(compRef: CompRef, compTypeOverride?: string): string {
    return getDisplayNameInner(editorAPI, compRef, compTypeOverride);
  }

  function getNickname(compRef: CompRef) {
    return getComponentCodeNickname(editorAPI.dsRead, compRef);
  }

  function getIconInfo(
    compRef: CompRef,
    containerSize?: Size,
    origin?: string,
  ): IconInfo {
    const dsRead = editorAPI.dsRead;

    const compType = dsRead.components.getType(compRef);

    return compIconUtils.getCompIconInfo(
      compRef,
      compType,
      containerSize,
      editorAPI,
      origin,
    );
  }

  function moveToFooter(compRef: CompRef) {
    const PADDING_FROM_FOOTER = 15;
    const notControlledByParent = _.negate(
      editorAPI.components.is.controlledByParent,
    );
    const compToMove = notControlledByParent(compRef)
      ? compRef
      : editorAPI.components.findAncestor(compRef, notControlledByParent, {
          includeScopeOwner: true,
        });
    const attachedCompLayout =
      editorAPI.components.layout.getRelativeToStructure(compToMove);
    const footerContainer = editorAPI.dsRead.siteSegments.getFooter();
    const footerLayout = editorAPI.components.layout.get_size(footerContainer);

    const updatedCompRef = editorAPI.components.setContainer(
      compToMove,
      footerContainer,
    );

    editorAPI.components.layout.update(updatedCompRef, {
      y:
        attachedCompLayout.y -
        attachedCompLayout.bounding.y +
        PADDING_FROM_FOOTER,
    });

    const isAttachedCompBottomExceedsFooterBottom =
      footerLayout.height <
      attachedCompLayout.bounding.height + PADDING_FROM_FOOTER;
    if (isAttachedCompBottomExceedsFooterBottom) {
      editorAPI.components.layout.update(footerContainer, {
        height: attachedCompLayout.bounding.height + 2 * PADDING_FROM_FOOTER,
      });
    }
  }

  function getDefaultBiParams(compRefs: CompRef[]) {
    const components = arrayUtils.asArray(compRefs);
    return {
      componentType: editorAPI.components.getType(compRefs),
      component_id: components[0].id,
    };
  }

  type Distribution = 'horizontal' | 'vertical' | 'verticalAndHorizontal';
  function distribute(compRefs: CompRef[], distribution: Distribution) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      editorAPI.dsActions.components.multiComponents.distribute(
        compsArr,
        distribution,
      );
    }
    editorAPI.history.add(`component - distribute - ${distribution}`);
  }

  function matchSize(compRefs: CompRef[], matchSizeValue: MatchSizeValue) {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      editorAPI.dsActions.components.multiComponents.matchSize(
        compsArr,
        matchSizeValue,
      );
    }
    editorAPI.history.add(`component - matchSize - ${matchSizeValue}`);
  }

  function canDistribute(
    compRefs: CompRef[],
    distribution: ValueOf<typeof constants.COMP_ALIGNMENT_OPTIONS>,
  ): boolean {
    return editorAPI.dsRead.components.multiComponents.canDistribute(
      compRefs,
      distribution,
    );
  }

  function canDistributeAllDirections(compRefs: CompRef[]): boolean {
    return (
      compRefs &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      _.every(
        constants.COMP_ALIGNMENT_OPTIONS,
        _.partial(canDistribute, compRefs),
      )
    );
  }

  function canMatchSize(
    compRefs: CompRef[],
    matchSizeValue: MatchSizeValue,
  ): boolean {
    return editorAPI.dsRead.components.multiComponents.canMatchSize(
      compRefs,
      matchSizeValue,
    );
  }

  function canMatchAllSizes(compRefs: CompRef[]) {
    return (
      compRefs &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      _.every(
        constants.COMP_MATCH_SIZE_OPTIONS,
        _.partial(canMatchSize, compRefs),
      )
    );
  }

  function getSelectedCompVariantPointerIfExists() {
    const state = editorAPI.store.getState();
    return interactionsSelectors.getCompVariantPointer(state);
  }

  function getRestrictions(
    compRefs: CompRef | CompRef[],
    restrictions: AnyFixMe,
  ): AnyFixMe {
    const compsArr = arrayUtils.asArray(compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      restrictions,
      function (result: Record<string, AnyFixMe>, restriction) {
        result[restriction] = editorAPI.components.is[restriction](compsArr);
        return result;
      },
      {},
    );
  }

  function setNickname(compRef: CompRef, value: string) {
    editorAPI.dsActions.components.code.setNickname(compRef, value);
    editorAPI.history.debouncedAdd('comp nick name changed');
  }

  function isDescendantOfFrozen(comp: CompRef, possibleFrozenParent: CompRef) {
    return (
      editorAPI.components.isDescendantOfComp(comp, possibleFrozenParent) &&
      editorAPI.components.layout.isFixedPosition(possibleFrozenParent)
    );
  }

  function serialize(
    componentPointer: AnyFixMe,
    dataItemPointer: AnyFixMe,
    ignoreChildren: AnyFixMe,
    maintainIdentifiers: AnyFixMe,
    flatMobileStructuresMap: AnyFixMe,
    structureEnricher: AnyFixMe,
    useOriginalLanguage: AnyFixMe,
  ) {
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    const serializedComponent = editorAPI.dsRead.components.serialize(
      componentPointer,
      dataItemPointer,
      ignoreChildren,
      maintainIdentifiers,
      flatMobileStructuresMap,
      structureEnricher,
      useOriginalLanguage,
    );
    if (serializedComponent) {
      return serializedComponentPlugins.runSerializeComponentPlugin(
        serializedComponent,
        editorAPI,
      );
    }
  }

  /**
   * convert/fix xy values to a flip direction
   * @param {string} flipValue
   * @return {'vertical'|'horizontal'|'both'}
   */
  function getFlipDirection(flipValue: AnyFixMe) {
    switch (flipValue) {
      case 'x':
        return 'vertical';
      case 'y':
        return 'horizontal';
      case 'xy':
        return 'both';
      default:
        return flipValue;
    }
  }

  /**
   * Flip a flippable component
   * @param {object[]} compRefs
   * @param {'x'|'y'|'vertical'|'horizontal'} direction
   */
  function flip(compRefs: CompRef[], direction: AnyFixMe) {
    if (!editorAPI.components.is.flippable(compRefs)) {
      return;
    }
    const angle =
      editorAPI.components.layout.get_rotationInDegrees(compRefs) ?? 0;
    const flipProp = getFlipDirection(
      editorAPI.components.properties.get(compRefs)?.flip ?? 'none',
    );
    let axisTransform;
    let newFlip;
    switch (flipProp) {
      case 'horizontal':
      case 'vertical':
        newFlip = flipProp !== direction ? 'both' : 'none';
        break;
      case 'both':
        axisTransform = { vertical: 'horizontal', horizontal: 'vertical' };
        newFlip = axisTransform[direction as keyof typeof axisTransform];
        break;
      default:
        newFlip = direction;
    }

    // Order matters! we must update layout before properties for th changes to be batched together
    editorAPI.components.layout.update(
      compRefs,
      { rotationInDegrees: 360 - angle },
      true,
    );
    editorAPI.components.properties.update(compRefs, { flip: newFlip });
  }

  const hasResponsiveLayout = (compRef: CompRef) =>
    Boolean(
      compRef && editorAPI.dsRead.components.responsiveLayout?.get(compRef),
    );

  const dangerouslyGetCascadeVariants = (selectedComponent: CompRef) => {
    const variantPointers =
      interactionsSelectors.getComponentCompVariantPointersCascade(
        editorAPI,
        selectedComponent,
      );

    if (hasResponsiveLayout(selectedComponent) && editorAPI.isMobileEditor()) {
      const mobileVariant = editorAPI.mobile.getMobileVariant();
      const compMobileVariantPointer = editorAPI.components.variants.getPointer(
        selectedComponent,
        [mobileVariant],
      );
      variantPointers.unshift(compMobileVariantPointer);
    }

    return variantPointers;
  };
  const isDescendantOfBlocksWidget = (compRefOrRefs: CompRef | CompRef[]) => {
    return componentSelectors.isDescendantOfBlocksWidget(
      compRefOrRefs,
      editorAPI.dsRead,
    );
  };

  const {
    getAllComponents,
    getAllComponents_DEPRECATED_BAD_PERFORMANCE,
    getAllComponentsFromFull_DEPRECATED_BAD_PERFORMANCE,
    getRootComponents,
    getAncestors_DEPRECATED_BAD_PERFORMANCE,
    findAncestor,
    someAncestor,
    getAncestorRepeaterItem,
    getAncestorRepeater,
    getSiblings,
    getChildren_DEPRECATED_BAD_PERFORMANCE,
    getChildren,
    hasChildren,
    getChildrenRecursivelyWithResolvers,
    hasChildrenOrScopedChildren,
    getChildrenOrScopedChildren,
    getContainer,
    getContainer_DEPRECATED_BAD_PERFORMANCE,
    getContainerOrScopeOwner,
    getScopeOwner,
    findScopeOwner,
    isDescendantOfComp,
    isAncestorOfComp,
    isAncestorOfCompOrCompScope,
  } = componentsGetApi;

  return {
    hooks,
    getAllComponents,
    getAllComponents_DEPRECATED_BAD_PERFORMANCE,
    getAllComponentsFromFull_DEPRECATED_BAD_PERFORMANCE,
    getRootComponents,
    getAncestors_DEPRECATED_BAD_PERFORMANCE,
    findAncestor,
    someAncestor,
    getAncestorRepeaterItem,
    getAncestorRepeater,
    dangerouslyGetCascadeVariants,
    get: {
      byAncestor_DEPRECATED_BAD_PERFORMACE:
        componentsGetApi.byAncestor_DEPRECATED_BAD_PERFORMACE,
      byXYRelativeToStructure: componentsGetApi.byXYRelativeToStructure,
      byType: componentsGetApi.byType,
      byType_DEPRECATED_BAD_PERFORMANCE:
        componentsGetApi.byType_DEPRECATED_BAD_PERFORMANCE,
    },
    canBeMasterChild,
    onPreviewReady,
    getPage,
    getComponentsWhichDontHaveAncestorsInTheArray,
    getUniqOrderedComponents,
    normalizeOrder,
    reset,
    isShowOnAllPages: componentsShowOnAllPagesApi.isShowOnAllPages,
    isShowOnSomePages: componentsShowOnAllPagesApi.isShowOnSomePages,
    hasRuntimeDataOrConnected,
    hasRuntimeData,
    getNonRemovableTPAChild,
    getNonDuplicatableTPAChild,
    serialize,
    cut,
    add,
    addAndAdjustLayout,
    remove,
    removePopupWithMessagePanel,
    setContainer: componentsReparentApi.setContainer,
    reparentComponentToPage,
    getType,
    getSiblings,
    getChildren_DEPRECATED_BAD_PERFORMANCE,
    getChildren,
    hasChildren,
    getTPAChildren,
    getChildrenRecursivelyWithResolvers,
    hasChildrenOrScopedChildren,
    getChildrenOrScopedChildren,
    getDisplayName,
    getNickname,
    getIconInfo,
    getContainer,
    getContainer_DEPRECATED_BAD_PERFORMANCE,
    getContainerOrScopeOwner,
    getScopeOwner,
    findScopeOwner,
    moveToFooter,
    isDescendantOfComp,
    isDescendantOfBlocksWidget,
    isAncestorOfComp,
    isAncestorOfCompOrCompScope,
    getDefaultBiParams,
    areComponentsOfSamePage,
    getSelectedCompVariantPointerIfExists,
    flip,
    groupComponents: componentsGroupApi.group,
    ungroup: componentsGroupApi.ungroup,
    data: componentsDataApi,
    // The if part of the designData editor patch and should be removed when merging
    design: componentsDesignApi,
    properties: componentsPropertiesApi,
    alignment: {
      align: componentsAlignmentApi.align,
      canAlign: componentsAlignmentApi.canAlign,
      canAlignAllDirections: componentsAlignmentApi.canAlignAllDirections,
    },
    multiComponents: {
      distribute,
      matchSize,
      canDistribute,
      canDistributeAllDirections,
      canMatchSize,
      canMatchAllSizes,
    },
    layout: shell.getAPI(LayoutApiKey),
    style: {
      get: componentsStyleApi.get,
      update: componentsStyleApi.update,
      fork: componentsStyleApi.fork,
    },
    stylable: {
      update: componentsStylableApi.update,
      createEmptyStyle: componentsStylableApi.createEmptyStyle,
      removeRefIfEmptyStyle: componentsStylableApi.removeRefIfEmptyStyle,
    },
    arrangement: ArrangementWrapper.create(editorAPI),
    getRestrictions,
    is: ComponentsMetaDataWrapper.create(editorAPI),
    behaviors: BehaviorsWrapper.create(editorAPI),
    code: {
      setNickname,
    },
    canToggleShowOnAllPages:
      componentsShowOnAllPagesApi.canToggleShowOnAllPages,
    shouldDisableShowOnAllPages:
      componentsShowOnAllPagesApi.shouldDisableShowOnAllPages,
    shouldHideShowOnAllPages:
      componentsShowOnAllPagesApi.shouldHideShowOnAllPages,
    isDescendantOfFrozen,
    toggleShowOnAllPages: componentsShowOnAllPagesApi.toggleShowOnAllPages,
    modes: {
      getModes: componentsModesApi.getModes,
      activateComponentMode: componentsModesApi.activateComponentMode,
      deactivateComponentMode: componentsModesApi.deactivateComponentMode,
      applyCurrentToAllModes: componentsModesApi.applyCurrentToAllModes,
      applyComponentToMode: componentsModesApi.applyComponentToMode,
      isCompDefaultModeActive: componentsModesApi.isCompDefaultModeActive,
      addDefaultSingleModeAnimations:
        componentsModesApi.addDefaultSingleModeAnimations,
      getFirstAncestorOrSelfWithModeDefinitions:
        componentsModesApi.getFirstAncestorOrSelfWithModeDefinitions,
      areComponentsOfSameModefulContainer:
        componentsModesApi.areComponentsOfSameModefulContainer,
    },
  };
}

export type ComponentsAddResult = core.utils.addUtil.AddComponentResult;
export type ComponentsApi = ReturnType<typeof createComponentsApi>;

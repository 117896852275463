/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { EditorAPIKey, WixCodeWorkspaceApiKey } from '#packages/apis';
import * as util from '#packages/util';
import constants from '#packages/constants';
import experiment from 'experiment';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import * as platformEvents from 'platformEvents';
import type { Shell } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const { exitInteractionModeIfNeeded } = stateManagement.interactions.actions;

export function createDeveloperModeApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey) as AnyFixMe; //circular types
  const WIX_CODE_TOPBAR_HEIGHT = util.workspace.isNewWorkspaceEnabled()
    ? constants.UI.WIX_CODE_TOPBAR_HEIGHT + 1
    : constants.UI.WIX_CODE_TOPBAR_HEIGHT;
  const MAXIMUM_MARGIN_FROM_TOOPBAR = 54;
  const { splitterAPI } = util;

  const getSiteUserPreferences = (key: AnyFixMe) =>
    stateManagement.userPreferences.selectors.getSiteUserPreferences(key)(
      editorAPI.store.getState(),
    );
  const setSiteUserPreferences = (key: AnyFixMe, value: AnyFixMe) =>
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        key,
        value,
      ),
    );
  const getGlobalUserPreferences = (key: AnyFixMe) =>
    stateManagement.userPreferences.selectors.getGlobalUserPreferences(key)(
      editorAPI.store.getState(),
    );
  const setGlobalUserPreferences = (key: AnyFixMe, value: AnyFixMe) =>
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        key,
        value,
      ),
    );
  const { openSection, closeSection } = stateManagement.sectionedPanel.actions;

  const shouldOpenPropertiesPanelAsDockedSection = () => {
    const { getOverriddenMenuItems } = stateManagement.topBar.selectors;
    const overriddenMenuItems = getOverriddenMenuItems(
      editorAPI.store.getState(),
    );
    const propertiesPanelOverridden =
      overriddenMenuItems?.[constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.TOOLS]?.[
        constants.OVERRIDDEN.TOP_BAR.MENU_ITEMS.TOOLS_PROPERTIES_PANEL.KEY
      ];
    const { SHOULD_OPEN_AS_SECTION } =
      constants.OVERRIDDEN.TOP_BAR.MENU_ITEMS.TOOLS_PROPERTIES_PANEL;

    const overriddenShouldOpenAsSection = _.get(
      propertiesPanelOverridden,
      SHOULD_OPEN_AS_SECTION,
      false,
    );

    return (
      experiment.isOpen('se_propertiesAsSection') ||
      overriddenShouldOpenAsSection
    );
  };

  // developer mode status

  function toggle({
    notifyApps = true,
  }: {
    notifyApps?: boolean;
  } = {}) {
    exitInteractionModeIfNeeded(editorAPI);
    const firstInstall = !editorAPI.wixCode.isProvisioned();
    if (firstInstall) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.CODE_TOP_BAR_FIRST_INSTALL,
      );
    }

    const newState = !isEnabled();

    //todo: should remove developerToolBarEnabled from store after merging the experiment 'se_propertiesAsSection'
    const developerToolBarNewState =
      newState && !shouldOpenPropertiesPanelAsDockedSection();

    const changes: AnyFixMe = {
      developerModeEnabled: newState,
      developerToolBarEnabled: developerToolBarNewState,
    };
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.DEVELOPER_MODE_ENABLED,
      newState,
    );
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.DEVELOPER_TOOLBAR_ENABLED,
      developerToolBarNewState,
    );

    const exposedToWixCode = getGlobalUserPreferences(
      constants.USER_PREFS.DEVELOPER_MODE.EXPOSED_TO_WIX_CODE,
    );
    if (!exposedToWixCode) {
      setGlobalUserPreferences(
        constants.USER_PREFS.DEVELOPER_MODE.EXPOSED_TO_WIX_CODE,
        true,
      );
    }

    if (newState && editorAPI.store.getState().viewTools.toolbarEnabled) {
      changes.toolbarEnabled = false;
    }

    if (
      newState &&
      !getSiteUserPreferences(constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME)
    ) {
      firstTimeActions();
    }

    changes.showHiddenComponents = shouldShowHiddenComponents();

    if (!newState) {
      editorAPI.selection.deselectComponents();
      editorAPI.panelManager.closeAllPanelsOfType(
        constants.PANEL_TYPES.FULL_STAGE,
      );
      showHiddenComponentsInLayersPanel(editorAPI);
    }

    setContext(createContext(constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE));
    editorAPI.dsActions.documentMode.showHiddenComponents(
      changes.showHiddenComponents,
    );
    editorAPI.dsActions.documentMode.showControllers(newState);

    if (shouldOpenPropertiesPanelAsDockedSection()) {
      if (newState) {
        editorAPI.store.dispatch(
          openSection(constants.DOCKED_PANEL_SECTIONS.PROPERTIES),
        );
      } else {
        editorAPI.store.dispatch(
          closeSection(constants.DOCKED_PANEL_SECTIONS.PROPERTIES),
        );
      }
    }

    editorAPI.updateEditorViewTools(changes);

    if (notifyApps) {
      const installedEditorApps = editorAPI.platform.getInstalledEditorApps();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(installedEditorApps, (installedApp) =>
        editorAPI.dsActions.platform.notifyApplication(
          installedApp.applicationId,
          platformEvents.factory.developerModeChanged({ value: newState }),
        ),
      );
    }
    if (newState) {
      editorAPI.host.getAPI(WixCodeWorkspaceApiKey).hooks.devModeEnabled.fire();
    }

    editorAPI.developerMode.ui.idePane.unMinimize();
  }

  function isEnabled() {
    const currentViewToolsState = editorAPI.getViewTools();
    if (!currentViewToolsState) {
      return false;
    }
    return !!currentViewToolsState.developerModeEnabled;
  }

  function sendDevModeBiEvent(sub_origin: AnyFixMe) {
    const biParams = {
      category: 'dev_mode',
      status: true,
      origin: 'auto',
      sub_origin,
      is_data_on: false,
      is_first: !editorAPI.wixCode.isProvisioned(),
    };
    editorAPI.bi.event(coreBi.events.topbar.top_bar_CODE_menu_click, biParams);
  }

  function preformDevModeOn(changes: AnyFixMe) {
    if (
      getSiteUserPreferences(constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME)
    ) {
      return;
    }
    setSiteUserPreferences(
      constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME,
      true,
    );
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.DEVELOPER_MODE_ENABLED,
      changes.developerModeEnabled,
    );
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.DEVELOPER_TOOLBAR_ENABLED,
      changes.developerToolBarEnabled,
    );

    setContext(createContext(constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE));
    editorAPI.dsActions.documentMode.showHiddenComponents(
      changes.showHiddenComponents,
    );
    editorAPI.dsActions.documentMode.showControllers(true);

    modifyAndSaveTreePaneSplitter(
      'fileTreeSplitter',
      splitterAPI.collapse,
      'right',
    );

    if (shouldOpenPropertiesPanelAsDockedSection()) {
      editorAPI.store.dispatch(
        openSection(constants.DOCKED_PANEL_SECTIONS.PROPERTIES),
      );
    }

    editorAPI.updateEditorViewTools(changes);
    editorAPI.developerMode.ui.idePane.unMinimize();
  }

  function enableViaQueryParam() {
    const changes = {
      developerModeEnabled: true,
      developerToolBarEnabled: !shouldOpenPropertiesPanelAsDockedSection(),
      showHiddenComponents: true,
    };
    preformDevModeOn(changes);
    sendDevModeBiEvent('query');
  }

  function enableWithMarketingConfig() {
    const changes = {
      developerModeEnabled: true,
      developerToolBarEnabled: !shouldOpenPropertiesPanelAsDockedSection(),
      showHiddenComponents: true,
    };
    preformDevModeOn(changes);

    const globalAutoDevModeTimestemp = getGlobalUserPreferences(
      constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME_AUTO_DEV_MODE_ON,
    );

    if (!globalAutoDevModeTimestemp) {
      setGlobalUserPreferences(
        constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME_AUTO_DEV_MODE_ON,
        new Date().getTime(),
      );
      sendDevModeBiEvent('exposure');
    }
  }

  function firstTimeActions() {
    editorAPI.selection.deselectComponents();
    setSiteUserPreferences(
      constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME,
      true,
    );
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.SHOW_HIDDEN_COMPS,
      true,
    );
  }

  function shouldShowHiddenComponents() {
    return getSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.SHOW_HIDDEN_COMPS,
    );
  }

  // context

  function createContext(type: AnyFixMe, data?: AnyFixMe) {
    return {
      type,
      data,
    };
  }

  function setContext(context: AnyFixMe) {
    editorAPI.updateState({ devModeContext: context });
  }

  function getContext() {
    if (!editorAPI.isPreviewReady()) {
      return createContext(constants.DEVELOPER_MODE.CONTEXT_TYPES.UNKNOWN);
    }
    let context = editorAPI.store.getState().devModeContext;
    if (context.type === constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE) {
      const pageId = editorAPI.dsRead.pages.getFocusedPageId();
      const data = {
        id: editorAPI.dsRead.pages.getFocusedPageId(),
        displayName: editorAPI.pages.getPageTitle(pageId),
        isPopup: editorAPI.dsRead.pages.popupPages.isPopup(pageId),
      };

      context = createContext(
        constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE,
        data,
      );
    }

    return context;
  }

  // ui (splitters)

  function getInitialSplitterState(splitterKey: AnyFixMe) {
    const DEFAULT_CODE_PANEL_HEIGHT = 320 - WIX_CODE_TOPBAR_HEIGHT;
    const DEFAULT_BOTTOM_PANE_HEIGHT = `${
      WIX_CODE_TOPBAR_HEIGHT + DEFAULT_CODE_PANEL_HEIGHT
    }px`;
    const DEFAULT_FILE_TREE_PANE_WIDTH = '296px';

    switch (splitterKey) {
      case `ideSplitter_${constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE}`:
        return splitterAPI.getInitialState(
          `calc(100% - ${DEFAULT_BOTTOM_PANE_HEIGHT})`,
          'bottom',
        );
      case `ideSplitter_${constants.DEVELOPER_MODE.CONTEXT_TYPES.FILE}`:
      case `ideSplitter_${constants.DEVELOPER_MODE.CONTEXT_TYPES.COLLECTION}`:
        return splitterAPI.getInitialState('0', 'top');
      case 'ideSplitter':
        return splitterAPI.getInitialState(
          `calc(100% - ${DEFAULT_BOTTOM_PANE_HEIGHT})`,
          'bottom',
        );
      case 'consoleSplitter':
        return splitterAPI.getInitialState(
          `calc(100% - ${DEFAULT_BOTTOM_PANE_HEIGHT})`,
          'bottom',
        );
      case 'fileTreeSplitter': {
        const state = getSiteUserPreferences(
          constants.USER_PREFS.DEVELOPER_MODE.FILE_PANE_SPLITTER,
        );

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
        return _.isNil(state)
          ? splitterAPI.getInitialState(DEFAULT_FILE_TREE_PANE_WIDTH, false)
          : state;
      }
    }
  }

  function getSplitterState(splitterKey: AnyFixMe) {
    return (
      editorAPI.store.getState()[splitterKey] ||
      getInitialSplitterState(splitterKey)
    );
  }

  function getFromSplitterAPI(splitterKey: AnyFixMe, action: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/drop
    const actionArgs = _.drop(arguments, 2);
    const splitterState = getSplitterState(splitterKey);
    return action.apply(null, [splitterState].concat(actionArgs));
  }

  function modifySplitterState(
    splitterKey: AnyFixMe,
    action: AnyFixMe,
    side: AnyFixMe,
    callback?: AnyFixMe,
  ) {
    //@ts-expect-error
    const newSplitterState = getFromSplitterAPI.apply(null, arguments);
    const updatedEditorState: AnyFixMe = {};
    updatedEditorState[splitterKey] = newSplitterState;
    editorAPI.updateState(updatedEditorState, callback);

    return updatedEditorState;
  }

  function setSplitPosition(
    splitterKey: AnyFixMe,
    firstPanePercent: AnyFixMe,
    relativePosition: AnyFixMe,
    splitterSize: AnyFixMe,
  ) {
    const splitterReachedBottom =
      relativePosition + WIX_CODE_TOPBAR_HEIGHT >= splitterSize;
    const splitterReachedTop = relativePosition <= MAXIMUM_MARGIN_FROM_TOOPBAR;
    if (splitterReachedBottom) {
      return modifySplitterState(splitterKey, splitterAPI.collapse, 'bottom');
    } else if (splitterReachedTop) {
      return modifySplitterState(splitterKey, splitterAPI.collapse, 'top');
    }
    return modifySplitterState(
      splitterKey,
      splitterAPI.setSplitPosition,
      firstPanePercent,
    );
  }

  function modifyAndSaveTreePaneSplitter(
    splitterKey: AnyFixMe,
    action: AnyFixMe,
    side: AnyFixMe,
    callback?: AnyFixMe,
  ) {
    //@ts-expect-error
    const newSplitterState = modifySplitterState.apply(null, arguments);

    setSiteUserPreferences(
      constants.USER_PREFS.DEVELOPER_MODE.FILE_PANE_SPLITTER,
      newSplitterState.fileTreeSplitter,
    );
  }

  function getPaneState(splitterKey: AnyFixMe) {
    const splitterState = getSplitterState(splitterKey);
    if (splitterAPI.isCollapsed(splitterState, 'top')) {
      return constants.DEVELOPER_MODE.CONTAINER_STATES.MAXIMIZED;
    } else if (splitterAPI.isCollapsed(splitterState, 'bottom')) {
      return constants.DEVELOPER_MODE.CONTAINER_STATES.MINIMIZED;
    }
    return constants.DEVELOPER_MODE.CONTAINER_STATES.CUSTOM;
  }

  function getIdeSplitterKey() {
    return 'ideSplitter';
  }

  function callForCurrentIdeContext(func: AnyFixMe, ..._args: AnyFixMe[]) {
    const args = _.tail(arguments);
    return func.apply(null, [getIdeSplitterKey()].concat(args));
  }

  function getConsoleSplitterKey() {
    return 'consoleSplitter';
  }

  function minimizedByUser() {
    const consoleSplitterKey = getConsoleSplitterKey();
    const consolePanelState = getPaneState(consoleSplitterKey);

    return (
      editorAPI.store.getState()[consoleSplitterKey] &&
      consolePanelState === constants.DEVELOPER_MODE.CONTAINER_STATES.MINIMIZED
    );
  }

  function reportIdeResizeBI(
    relativePosition: AnyFixMe,
    splitterSize: AnyFixMe,
  ) {
    editorAPI.bi.event(coreBi.events.developerMode.IDE_TOPBAR_RESIZE, {
      old_hight: editorAPI.developerMode.ui.idePane.getSplitPosition(), //the height properties are 'hight' in the BI catalog
      new_hight: `${((100 * relativePosition) / splitterSize).toPrecision(4)}%`,
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
    });
  }

  function setIdePaneSplitPosition(
    firstPanePercent: AnyFixMe,
    relativePosition: AnyFixMe,
    splitterSize: AnyFixMe,
  ) {
    reportIdeResizeBI(relativePosition, splitterSize);
    callForCurrentIdeContext(
      setSplitPosition,
      firstPanePercent,
      relativePosition,
      splitterSize,
    );
  }

  function reportTreePaneToggle() {
    const state = editorAPI.developerMode.ui.treePane.isOpen()
      ? 'close'
      : 'open';
    editorAPI.bi.event(coreBi.events.developerMode.LEFT_TREE_TOGGLE, {
      state,
      site_id: editorAPI.dsRead.generalInfo.getSiteId(),
    });
  }

  function toggleTreePane(callback: AnyFixMe) {
    reportTreePaneToggle();
    modifyAndSaveTreePaneSplitter(
      'fileTreeSplitter',
      splitterAPI.toggleCollapse,
      'left',
      callback,
    );
  }

  function showHiddenComponentsInLayersPanel(editorAPI: EditorAPI) {
    const hiddenComps =
      editorAPI.dsRead.deprecatedOldBadPerformanceApis.components
        .getAllComponents()
        .filter((x: CompRef) => editorAPI.dsRead.renderPlugins.isCompHidden(x));
    hiddenComps.forEach((compRef: CompRef) =>
      editorAPI.dsActions.renderPlugins.showComp(compRef),
    );
  }

  // public api

  return {
    createContext,
    setContext,
    getContext,
    isEnabled,
    enableViaQueryParam,
    enableWithMarketingConfig,
    toggle,
    ui: {
      idePane: {
        getSplitPosition: _.partial(
          callForCurrentIdeContext,
          getFromSplitterAPI,
          splitterAPI.getSplitPosition,
        ),
        setSplitPosition: setIdePaneSplitPosition,
        maximize: _.partial(
          callForCurrentIdeContext,
          modifySplitterState,
          splitterAPI.collapse,
          'top',
        ),
        unMaximize: _.partial(
          callForCurrentIdeContext,
          modifySplitterState,
          splitterAPI.unCollapse,
          'top',
        ),
        toggleMaximize: _.partial(
          callForCurrentIdeContext,
          modifySplitterState,
          splitterAPI.toggleCollapse,
          'top',
        ),
        minimize: _.partial(
          callForCurrentIdeContext,
          modifySplitterState,
          splitterAPI.collapse,
          'bottom',
        ),
        unMinimize: _.partial(
          callForCurrentIdeContext,
          modifySplitterState,
          splitterAPI.unCollapse,
          'bottom',
        ) as any,
        toggleMinimize: _.partial(
          callForCurrentIdeContext,
          modifySplitterState,
          splitterAPI.toggleCollapse,
          'bottom',
        ),
        revertSize: _.partial(
          callForCurrentIdeContext,
          modifySplitterState,
          splitterAPI.unCollapse,
        ),
        getState: _.partial(callForCurrentIdeContext, getPaneState),
      },
      consolePane: {
        getSplitPosition: _.partial(
          getFromSplitterAPI,
          getConsoleSplitterKey(),
          splitterAPI.getSplitPosition,
        ),
        setSplitPosition: _.partial(setSplitPosition, getConsoleSplitterKey()),
        minimize: _.partial(
          modifySplitterState,
          getConsoleSplitterKey(),
          splitterAPI.collapse,
          'bottom',
        ),
        unMinimize: _.partial(
          modifySplitterState,
          getConsoleSplitterKey(),
          splitterAPI.unCollapse,
          'bottom',
        ),
        minimizedByUser: _.partial(minimizedByUser),
        getState: _.partial(getPaneState, getConsoleSplitterKey()),
      },
      treePane: {
        getSplitPosition: _.partial(
          getFromSplitterAPI,
          'fileTreeSplitter',
          splitterAPI.getSplitPosition,
        ),
        setSplitPosition: _.partial(
          modifyAndSaveTreePaneSplitter,
          'fileTreeSplitter',
          splitterAPI.setSplitPosition,
        ),
        toggle: toggleTreePane,
        isOpen: _.negate(
          _.partial(
            getFromSplitterAPI,
            'fileTreeSplitter',
            splitterAPI.isCollapsed,
            'left',
          ) as any,
        ),
      },
      topbarHeight: `${WIX_CODE_TOPBAR_HEIGHT}px`,
    },
  };
}

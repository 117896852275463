import { selection, domMeasurements } from '#packages/stateManagement';
import constants from '#packages/constants';

import * as selectionBoxCommons from '../selectionBoxCommons';
import { getNavControlsMeasurements } from '../../compControls/compControls.utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { StateMapperArgs } from 'types/redux';

const { getFocusedContainer, getSingleSelectedComponent } = selection.selectors;
const { getPreviewPosition } = domMeasurements.selectors;
const { COMP_TYPES } = constants;

const ALLOWED_CONTAINER_TYPES: string[] = [
  COMP_TYPES.STRIP_COLUMNS_CONTAINER,
  COMP_TYPES.REPEATER,
];

const getIndicationStyle = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  previewPosition: {
    top: number;
    left: number;
  },
) => {
  const isShowOnFixedPosition =
    editorAPI.components.layout.isShowOnFixedPosition([compRef]);

  const containerLayout =
    editorAPI.components.layout.getRelativeToScreen(compRef);

  const layout = selectionBoxCommons.getBoundingStyle(
    isShowOnFixedPosition,
    containerLayout,
    [compRef],
    0,
  );

  if (layout.position === 'fixed') {
    return {
      ...layout,
      top: layout.top + previewPosition.top,
      left: layout.left + previewPosition.left,
    };
  }

  return layout;
};

const getParentContainerOfType = (
  editorAPI: EditorAPI,
  child: CompRef,
  allowedCompTypes: string[],
) => {
  let parent: CompRef =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(child);
  let parentType: string;

  while (parent && !allowedCompTypes.includes(parentType)) {
    parent =
      editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(parent);
    parentType = editorAPI.components.getType(parent);
  }

  return parent;
};

const defaultState = {
  containerStyle: null as AnyFixMe,
  componentLabel: null as AnyFixMe,
  navControlsStyle: null as AnyFixMe,
};

export const mapStateToProps = ({ editorAPI, state }: StateMapperArgs) => {
  const previewPosition = getPreviewPosition(state);
  const selectedComponent = getSingleSelectedComponent(state);
  const focusedContainer = getFocusedContainer(state);

  if (selectedComponent?.id !== focusedContainer?.id) {
    return defaultState;
  }

  const parentContainer = getParentContainerOfType(
    editorAPI,
    focusedContainer,
    ALLOWED_CONTAINER_TYPES,
  );

  if (!parentContainer) {
    return defaultState;
  }

  const { navControlsStyle } = getNavControlsMeasurements(
    editorAPI,
    parentContainer,
  );

  const focusedContainerType = editorAPI.components.getType(focusedContainer);

  return {
    componentLabel: editorAPI.components.getDisplayName(parentContainer),
    containerStyle: ALLOWED_CONTAINER_TYPES.includes(focusedContainerType)
      ? getIndicationStyle(editorAPI, parentContainer, previewPosition)
      : null,
    navControlsStyle,
  };
};

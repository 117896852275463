import type { AddPanelProps as AddPanelComponentProps } from '@wix/add-panel-component';
import * as uiAnimations from '#packages/uiAnimations';
import { media, serviceTopology } from '#packages/util';
import { MobileElementsPanelContent } from '#packages/mobileEditor';
import { PersonalizedPreview, ThemedPreview } from './liveSections';
import { getConditionHelpers, getRegistrySections } from './newAddPanelAPI';

export type NewAddPanelUtils = AddPanelComponentProps['utils'];

interface CreateUtilsArgs {
  theme: NewAddPanelUtils['theme'];
  translate: NewAddPanelUtils['translate'];
  getSuperAppData: NewAddPanelUtils['getSuperAppData'];
  hasSectionStyle: NewAddPanelUtils['hasSectionStyle'];
  shouldUseTextThemes: NewAddPanelUtils['shouldUseTextThemes'];
  getLiveChatSectionColors: NewAddPanelUtils['getLiveChatSectionColors'];
  getBlankSectionStructure: NewAddPanelUtils['getBlankSectionStructure'];
  shouldShowMyDesignsFooter: NewAddPanelUtils['shouldShowMyDesignsFooter'];
}

export const createUtils = ({
  theme,
  translate,
  getSuperAppData,
  hasSectionStyle,
  getLiveChatSectionColors,
  getBlankSectionStructure,
  shouldUseTextThemes,
  shouldShowMyDesignsFooter,
}: CreateUtilsArgs): NewAddPanelUtils => ({
  media,
  translate,
  serviceTopology: {
    editorAssetsBaseUrl: serviceTopology.editorAssetsBaseUrl,
    itemsDataBaseUrl:
      serviceTopology.scriptsLocationMap['add-panel-data-classic-editor'],
  },
  conditionHelpers: getConditionHelpers(),
  theme: theme as unknown as AddPanelComponentProps['utils']['theme'],
  uiAnimations,
  getSuperAppData,
  hasSectionStyle,
  ThemedPreview, //mb think in future, and implement it in add panel internally.
  PersonalizedPreview, //mb think in future, and implement it in add panel internally.
  getLiveChatSectionColors,
  MobileToolsPanel: MobileElementsPanelContent,
  getBlankSectionStructure,
  getRegistrySections,
  shouldUseTextThemes,
  shouldShowMyDesignsFooter,
});

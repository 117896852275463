// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import { utils } from '#packages/core';
import { cx, hoc, uiUtils } from '#packages/util';
import constants from '#packages/constants';
import { domMeasurements, leftBar } from '#packages/stateManagement';
import baseDragAndPush from '../../utils/mouseMoveActions/baseDragAndPush';
import { events } from '#packages/coreBi';
import React from 'react';
import * as BaseUI from '#packages/baseUI';
import SectionControls from './sectionControls';

const {
  shiftSection,
  deleteSection,
  switchSections,
  getPageSections,
  getHeaderSection,
  duplicateSection,
  getSectionSizeForBI,
} = utils.sectionsReorganizeUtil;

const SPACE_HANDLE_TOP_THRESHOLD = 30;
const biEvents = events.sectionReorganize;
const { SEPARATOR_LINE_LENGTH } = constants.ROOT_COMPS.MOBILE_EDITOR;

function isCoordInRect(rect, x, y) {
  return x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
}

function isMousePositionOnStageRight(
  mousePosition,
  siteScale,
  isMobile,
  stageRect,
  checkFrom,
) {
  let scaledStagedWidth;
  if (isMobile) {
    scaledStagedWidth = 320 * siteScale;
  } else {
    scaledStagedWidth = (stageRect.right - stageRect.left) * siteScale;
  }

  const scaledMouseX = mousePosition.x * siteScale;

  if (checkFrom === 'left') return scaledMouseX <= scaledStagedWidth;

  return scaledMouseX >= scaledStagedWidth;
}

function getActiveSection(sections) {
  if (this.props.selectedSection) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(
      sections,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (section) {
        return _.isEqual(section.comps, this.props.selectedSection.comps);
      }, this),
    );
  }

  const { stageMousePosition } = this.state;
  if (stageMousePosition) {
    for (let i = 0; i < sections.length; i++) {
      if (
        stageMousePosition.y >= sections[i].position.top &&
        stageMousePosition.y <= sections[i].position.bottom
      ) {
        return sections[i];
      }
    }
  }

  return null;
}

function getSectionIndexAboveHoveredGap(sections) {
  if (!this.props.selectedSection && this.state.stageMousePosition) {
    for (let i = 0; i < sections.length - 1; i++) {
      if (
        this.state.stageMousePosition.y > sections[i].position.bottom &&
        this.state.stageMousePosition.y < sections[i + 1].position.top
      ) {
        return i;
      }
    }
  }

  return -1;
}

function isDraggingSection(section) {
  return (
    this.props.selectedSection &&
    _.isEqual(section.comps, this.props.selectedSection.comps)
  );
}

function isHoveringSectionVerticalArea(section) {
  if (!this.state.stageMousePosition) {
    return false;
  }

  const yPosition = this.state.stageMousePosition.y;
  return (
    yPosition >= section.position.top && yPosition <= section.position.bottom
  );
}

function isSectionHoveredOnStageRight(section) {
  const isSectionHovered = isHoveringSectionVerticalArea.call(this, section);
  return (
    isSectionHovered &&
    isMousePositionOnStageRight(
      this.state.stageMousePosition,
      this.props.siteScale,
      this.props.isMobileEditor,
      this.props.stageRect,
      'right',
    )
  );
}

function getSpacerHandleSectionIndex(sections, stageMousePosition) {
  if (this.state.isSpacing && this.props.selectedSection) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/bind, you-dont-need-lodash-underscore/find-index
    return _.findIndex(sections, _.bind(isDraggingSection, this));
  }

  for (let i = 0; i < sections.length; i++) {
    const prevSection = i > 0 ? sections[i - 1] : null;
    if (
      stageMousePosition.y <=
        sections[i].position.top + SPACE_HANDLE_TOP_THRESHOLD &&
      (!prevSection ||
        stageMousePosition.y >
          prevSection.position.top + SPACE_HANDLE_TOP_THRESHOLD)
    ) {
      return i;
    }
  }
  return -1;
}

function shouldConsiderHeaderAndFooter() {
  return !(this.props.isPageLandingPage || this.props.isPopupOpened);
}

function getSectionSeparatorsY(originalSections) {
  const sections = _.clone(originalSections);
  const separatorTops = [];
  if (shouldConsiderHeaderAndFooter.call(this)) {
    sections.unshift(this.props.headerSection);
  }
  for (let i = 0; i < sections.length; i++) {
    const currSection = sections[i];
    const prevSection = i > 0 ? sections[i - 1] : null;

    if (prevSection && prevSection.position.bottom < currSection.position.top) {
      separatorTops.push(currSection.position.top);
    }

    separatorTops.push(currSection.position.bottom);
  }
  return separatorTops;
}

function getActiveSectionProps(sections, spacerProps) {
  if (!sections || spacerProps?.isSpacerHovered) {
    return null;
  }

  const activeSection = getActiveSection.call(this, sections);
  if (!activeSection) {
    return null; // todo - handle spacer section
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
  const actionIndex = _.findIndex(sections, activeSection);

  if (activeSection.isStaticSection) {
    return {
      layout: this.getSectionLayout(activeSection),
      siteScale: this.props.siteScale,
      isStaticSection: true,
    };
  }

  const isDragging = isDraggingSection.call(this, activeSection);

  const sendReorderPanelBiEvent = (actionType) => {
    this.props.sendBi(biEvents.reorder_panel_clicked, {
      editor_view_mode: this.props.deviceType,
      action_clicked: actionType,
    });
  };

  return {
    section: activeSection,
    siteScale: this.props.siteScale,
    layout: this.getSectionLayout(activeSection),
    showDragHighlight: !this.props.isMobileEditor || this.props.isInZoomMode,
    isDragging,
    isInZoomMode: this.props.isInZoomMode,
    isInCropMode: this.props.isCropMode,
    isSpacing: this.state.isSpacing,
    tooltipId: 'reorganize-section-tooltip',
    isFirstSection:
      actionIndex === 0 || sections?.[actionIndex - 1]?.isStaticSection,
    isLastSection:
      actionIndex === sections.length - 1 ||
      sections?.[actionIndex + 1]?.isStaticSection,
    isSectionRemovable: activeSection.isRemovable,
    onDragHandleMouseDown: (e) => {
      this.startSectionDrag(e, sections, activeSection);
    },
    onDuplicateClicked: () => {
      this.props.duplicateSection(
        sections,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        _.findIndex(sections, activeSection),
      );
      sendReorderPanelBiEvent('duplicate');
    },
    onDeleteClicked: () => {
      this.props
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        .deleteSection(sections, _.findIndex(sections, activeSection))
        .then((removed) => {
          if (removed) {
            this.props.historyAdd('Delete Section');
            sendReorderPanelBiEvent('delete');
          }
        });
    },
    onUpClicked: () => {
      this.shiftSection(sections, activeSection, -1);
      sendReorderPanelBiEvent('up');
    },
    onDownClicked: () => {
      this.shiftSection(sections, activeSection, 1);
      sendReorderPanelBiEvent('down');
    },
  };
}

function isHoveringByLayout(layout, mousePosition) {
  return (
    mousePosition.y >= layout.y && mousePosition.y <= layout.y + layout.height
  );
}

function getGapSectionProps(originalSections) {
  if (!originalSections) {
    return null;
  }
  const sections = _.clone(originalSections);
  sections.unshift(this.props.headerSection);
  const sectionAboveGapIndex = getSectionIndexAboveHoveredGap.call(
    this,
    sections,
  );
  if (sectionAboveGapIndex === -1) {
    return null;
  }

  const gapLayout = {
    y: sections[sectionAboveGapIndex].position.bottom,
    height:
      sections[sectionAboveGapIndex + 1].position.top -
      sections[sectionAboveGapIndex].position.bottom,
  };

  return {
    isGap: true,
    isInCropMode: this.props.isCropMode,
    layout: gapLayout,
    siteScale: this.props.siteScale,
    onDeleteClicked: function () {
      this.closeGapBetweenSections(sections, sectionAboveGapIndex);
    }.bind(this),
  };
}

function getHeaderOrFooterSectionProps() {
  const mousePosition = this.state.stageMousePosition;

  if (!mousePosition) {
    return null;
  }

  if (!shouldConsiderHeaderAndFooter.call(this)) {
    return null;
  }

  let hoveredSegmentProps;
  if (isHoveringByLayout(this.props.headerLayout, mousePosition)) {
    hoveredSegmentProps = {
      layout: this.props.headerLayout,
      isHeader: true,
    };
  } else if (isHoveringByLayout(this.props.footerLayout, mousePosition)) {
    hoveredSegmentProps = {
      layout: this.props.footerLayout,
      isFooter: true,
    };
  }

  if (!hoveredSegmentProps) {
    return null;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign(hoveredSegmentProps, {
    siteScale: this.props.siteScale,
  });
}

interface SectionsReorganizeProps {
  isMobileEditor: boolean;
  isSectionDragging?: boolean;
  isCompDragging?: boolean;
  isResizing?: boolean;
  isInZoomMode?: boolean;
  isHidden?: boolean;
  sections?: unknown[];
  selectedSection?: unknown;
  stageLayout?: unknown;
  halfOpacityTools?: unknown;
  siteScale: number;
}

export class SectionsReorganizePure extends React.Component<SectionsReorganizeProps> {
  displayName: 'sectionsReorganize';
  propTypes: {
    isMobileEditor: PropTypes.bool.isRequired;
    sections: PropTypes.array;
    selectedSection: PropTypes.object;
    isSectionDragging: PropTypes.bool;
    isCompDragging: PropTypes.bool;
    stageLayout: PropTypes.object.isRequired;
    siteScale: PropTypes.number.isRequired;
    isResizing: PropTypes.bool;
    isInZoomMode: PropTypes.bool;
  };
  constructor(props) {
    super(props);
    this.state = {
      isSpacing: false,
      stageMousePosition: null,
    };
  }
  componentDidMount() {
    this._throttledUpdateMousePosition = _.throttle(
      this.updateMousePosition,
      100,
    );
    window.addEventListener('mousemove', this._throttledUpdateMousePosition);
  }
  componentWillUnmount() {
    window.removeEventListener('mousemove', this._throttledUpdateMousePosition);
  }
  updateMousePosition = (e) => {
    if (!isCoordInRect(this.props.stageLayout, e.clientX, e.clientY)) {
      if (this.state.stageMousePosition) {
        this.setState({
          stageMousePosition: null,
        });
      }
    } else {
      // cant change native event (not easily anyhow..)
      e = _.pick(e, ['clientX', 'clientY', 'pageX', 'pageY']);
      const updatedEvent = this.props.toViewerCoordinates(e);
      const stageMousePosition = {
        x: updatedEvent.pageX,
        y: updatedEvent.pageY,
      };

      this.setState({ stageMousePosition });
    }
  };
  getSectionControlsProps = (sections, spacerProps) => {
    return (
      getHeaderOrFooterSectionProps.call(this) ||
      getGapSectionProps.call(this, sections) ||
      getActiveSectionProps.call(this, sections, spacerProps)
    );
  };
  getSpacerProps = (sections) => {
    if (
      (this.props.isCompDragging || this.props.isSectionDragging) &&
      !this.state.isSpacing
    ) {
      return null;
    }
    const { stageMousePosition } = this.state;
    if (!sections || !stageMousePosition) {
      return null;
    }
    const spacerHandleSectionIndex = getSpacerHandleSectionIndex.call(
      this,
      sections,
      stageMousePosition,
    );
    const isHoveredLeftOfStageRight = isMousePositionOnStageRight(
      this.state.stageMousePosition,
      this.props.siteScale,
      this.props.isMobileEditor,
      this.props.stageRect,
      'left',
    );
    const activeSection = getActiveSection.call(this, sections);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const activeActionIndex = _.findIndex(sections, activeSection);
    const isHoveredOnStageRight = activeSection
      ? isSectionHoveredOnStageRight.call(this, activeSection)
      : false;
    if (
      spacerHandleSectionIndex === -1 ||
      !isHoveredLeftOfStageRight ||
      isHoveredOnStageRight
    ) {
      return null;
    }

    const width = this.props.isMobileEditor ? '320px' : '100%';
    return {
      style: {
        top:
          this.getSectionLayout(sections[spacerHandleSectionIndex]).y *
          this.props.siteScale,
        width: `calc(${width} * ${this.props.siteScale} + 2px)`,
      },
      onMouseDown: (e) => {
        BaseUI.tooltipManager.hide('zoom_mode_spacer_id');
        this.startSpacing(e, sections, spacerHandleSectionIndex);
      },
      isSpacerHovered: spacerHandleSectionIndex === activeActionIndex,
    };
  };
  getSeparatorLines = (sections) => {
    const separatorsY = getSectionSeparatorsY.call(this, sections);

    const { stageLayout } = this.props;
    const scrollWidth = uiUtils.getScrollbarWidth();
    let scaledStageWidth =
      (stageLayout.width - scrollWidth) * this.props.siteScale;
    if (this.props.isMobileEditor) {
      scaledStageWidth = 320 * this.props.siteScale;
    }

    const marginFromScaledLayout =
      (stageLayout.width - scrollWidth - scaledStageWidth) / 2;

    const headerSectionResizing = this.isHeaderSectionResizing();

    return _.flatMap(
      separatorsY,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (y) {
        y *= this.props.siteScale;

        return [
          {
            x1: marginFromScaledLayout - SEPARATOR_LINE_LENGTH,
            x2: marginFromScaledLayout,
            y,
            isSectionResizing:
              headerSectionResizing && this.props.headerSectionBottom === y,
          },
          {
            x1: marginFromScaledLayout + scaledStageWidth,
            x2:
              marginFromScaledLayout + scaledStageWidth + SEPARATOR_LINE_LENGTH,
            y,
            isSectionResizing:
              headerSectionResizing && this.props.headerSectionBottom === y,
          },
        ];
      }, this),
    );
  };
  isHeaderSectionSelected = () => {
    return _.head(this.props.selectedComponents)?.id === 'SITE_HEADER';
  };
  isHeaderSectionResizing = () => {
    return this.props.isResizing && this.isHeaderSectionSelected();
  };
  closeGapBetweenSections = (sections, sectionAboveGapIndex) => {
    const gapSize =
      sections[sectionAboveGapIndex + 1].position.top -
      sections[sectionAboveGapIndex].position.bottom;
    for (let i = sectionAboveGapIndex + 1; i < sections.length; i++) {
      this.props.shiftSection(sections[i], -1 * gapSize);
    }

    const roundedGapSize = Math.round(gapSize);

    this.props.sendBi(biEvents.SPACE_DELETED, {
      site_id: this.props.siteId,
      page_id: this.props.focusedPageId,
      space_size: roundedGapSize,
    });

    this.props.historyAdd('Delete Space');
  };
  shiftSection = (sections, activeSection, direction) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const sectionIndex = _.findIndex(sections, activeSection);
    const shiftedIndex = sectionIndex + direction;

    if (shiftedIndex < 0 || shiftedIndex >= sections.length) {
      return;
    }

    let topSection = activeSection;
    let bottomSection = sections[shiftedIndex];
    if (direction === -1) {
      topSection = sections[shiftedIndex];
      bottomSection = activeSection;
    }

    this.props.switchSections(sections, topSection, bottomSection);
    this.props.historyAdd('Move Section');
  };
  startSectionDrag = (e, sections, sectionToDrag) => {
    this.props.setPageSections(sections, sectionToDrag);
    this.props.registerMouseMoveAction(
      utils.sectionDrag,
      {
        sections,
        evt: e,
        minY: this.props.pageTop,
        draggedSection: sectionToDrag,
        onDragEnd: this.onSectionDragEnd.bind(
          this,
          sectionToDrag,
          sectionToDrag.position.top,
        ),
      },
      true,
    );
  };
  onSectionDragEnd = (section, originalPositionTop) => {
    section = this.props.selectedSection || section;

    if (originalPositionTop !== section.position.top) {
      this.props.sendBi(biEvents.REORDER_SECTION, {
        page_id: this.props.focusedPageId,
        position_before: originalPositionTop,
        position_after: section.position.top,
        section_size: getSectionSizeForBI(
          section.position.bottom - section.position.top,
        ),
      });
    }

    this.props.clearPageSections();
  };
  startSpacing = (e, sections, selectedSectionIndex) => {
    const firstsBottomComp = sections[selectedSectionIndex]?.comps?.[0];
    if (!firstsBottomComp) {
      return;
    }

    const draAndPush = {
      ...baseDragAndPush,
      end: () => {
        baseDragAndPush.end();
        this.onSpaceEnd();
      },
    };

    this.props.registerMouseMoveAction(draAndPush, {
      comps: [firstsBottomComp],
      evt: e,
    });

    this.setState({ isSpacing: true });
    this.props.setPageSections(sections, sections[selectedSectionIndex]);
    this.props.sendBi(biEvents.zoom_mode_spacer_drag);
    return;
  };
  onSpaceEnd = () => {
    this.props.clearPageSections();
    this.setState({ isSpacing: false });

    this.props.historyAdd('Change Sections Space');
  };
  getSectionLayout = (section) => {
    if (section.layout) {
      return section.layout;
    }

    if (
      (this.props.isSectionDragging || this.state.isSpacing) &&
      !_.isEmpty(section.comps)
    ) {
      const sectionLayout = this.props.sectionLayout(section.comps);

      return sectionLayout.bounding || sectionLayout;
    }

    return {
      y: section.position.top,
      height: section.position.bottom - section.position.top,
    };
  };
  render() {
    const { sections } = this.props;
    const spacerProps = this.getSpacerProps(sections);
    const sectionControlsProps = this.getSectionControlsProps(
      sections,
      spacerProps,
    );

    return (
      <div className="sections-reorganize-exp" data-aid="sections-reorganize">
        <svg className="section-separators">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.getSeparatorLines(sections), (line, lineIndex) => (
            <line
              key={`separator-${lineIndex}`}
              x1={line.x1}
              x2={line.x2}
              y1={line.y}
              y2={line.y}
              className={cx({ 'section-separator': true })}
            />
          ))}
        </svg>

        {sectionControlsProps && !this.state.isSpacing ? (
          <SectionControls
            key={`${sectionControlsProps.layout.y}_${sectionControlsProps.layout.height}`}
            isMobileEditor={this.props.isMobileEditor}
            {...sectionControlsProps}
          />
        ) : null}

        {spacerProps &&
        (!this.props.isMobileEditor || this.props.siteScale !== 1) ? (
          <BaseUI.tooltip
            key="spacer_tooltip"
            id="zoom_mode_spacer_id"
            value="ZoomOut_Change_Space_Tooltip"
            alignment={constants.UI.TOOLTIP.ALIGNMENT.CENTER}
            delay="0"
            disabled={this.state.isSpacing}
          >
            <div
              key="spacer"
              style={spacerProps.style}
              onMouseDown={spacerProps.onMouseDown}
              className="spacer-handle"
            >
              <BaseUI.symbol name="spacerHandleDots" />
            </div>
          </BaseUI.tooltip>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ editorAPI, state }, ownProps) => {
  return {
    siteId: editorAPI.dsRead.generalInfo.getSiteId(),
    pageTop: editorAPI.siteSegments.getPagesContainerAbsLayout().y,
    sections: ownProps.sections || getPageSections(editorAPI),
    stageRect: domMeasurements.selectors.getStageRect(state),
    isCropMode: editorAPI.imageCrop.isCropMode(),
    deviceType: leftBar.selectors.getDeviceType(state),
    stageLayout: domMeasurements.selectors.getStageLayout(state),
    isInZoomMode: editorAPI.zoomMode.isInZoomMode(),
    headerLayout: editorAPI.components.layout.getRelativeToScreen(
      editorAPI.dsRead.siteSegments.getHeader(),
    ),
    footerLayout: editorAPI.components.layout.getRelativeToScreen(
      editorAPI.dsRead.siteSegments.getFooter(),
    ),
    sectionLayout: (compRef) =>
      editorAPI.components.layout.getRelativeToScreen(compRef),
    focusedPageId: editorAPI.dsRead.pages.getFocusedPageId(),
    headerSection: getHeaderSection(editorAPI),
    isPopupOpened: editorAPI.dsRead.pages.popupPages.isPopupOpened(),
    isPageLandingPage: editorAPI.isCurrentPageLandingPage(),
    selectedComponents: editorAPI.selection.getSelectedComponents(),
    headerSectionBottom: getHeaderSection(editorAPI)?.position?.bottom,
  };
};

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

const mapDispatchToProps = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    sendBi: editorAPI.bi.event,
    historyAdd: editorAPI.history.add,
    shiftSection: (section, offset) => shiftSection(editorAPI, section, offset),
    deleteSection: (sections, deletedSectionIndex) =>
      deleteSection(editorAPI, sections, deletedSectionIndex),
    switchSections: (sections, topSection, bottomSection) =>
      switchSections(editorAPI, sections, topSection, bottomSection),
    setPageSections: editorAPI.setPageSections,
    duplicateSection: (sections, origSectionIdx) =>
      duplicateSection(editorAPI, sections, origSectionIdx),
    toViewerCoordinates: (e: MouseEvent) =>
      domMeasurements.selectors.translateToViewerCoordinates(editorAPI, e),
    clearPageSections: editorAPI.clearPageSections,
    registerMouseMoveAction: editorAPI.mouseActions.registerMouseMoveAction,
  };
};

export const SectionsReorganize = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SectionsReorganizePure);

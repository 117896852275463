import React, { type CSSProperties } from 'react';
import * as UI from '#packages/baseUI';
import { hoc } from '#packages/util';
import { translate } from '#packages/i18n';
import { createStructuredSelector } from '../selectors';
import { shouldShowMoveToFooterSel, moveToFooterStyleSel } from '../selectors';

interface LayoutMoveToFooterOwnProps {
  onMouseDown: (e: React.MouseEvent) => void;
}

interface LayoutMoveToFooterStateProps {
  shouldShowMoveToFooter: boolean;
  moveToFooterStyle: CSSProperties;
}

interface LayoutMoveToFooterProps
  extends LayoutMoveToFooterOwnProps,
    LayoutMoveToFooterStateProps {}

const LayoutMoveToFooter: React.FC<LayoutMoveToFooterProps> = (props) => {
  if (!props.shouldShowMoveToFooter) {
    return null;
  }

  return (
    <span
      data-aid="move-to-footer"
      key="moveToFooter"
      style={props.moveToFooterStyle}
      onMouseDown={props.onMouseDown}
      className="move-to-footer"
    >
      {translate('OnStage_MoveToFooter_Button')}
      <UI.symbol name="moveToFooterArrow" />
    </span>
  );
};

const layoutMoveToFooterPropsSel =
  createStructuredSelector<LayoutMoveToFooterStateProps>({
    shouldShowMoveToFooter: shouldShowMoveToFooterSel,
    moveToFooterStyle: moveToFooterStyleSel,
  });

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  layoutMoveToFooterPropsSel,
)(LayoutMoveToFooter);

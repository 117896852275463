import {
  enforceSectionContainer,
  isAllowedToRunEnforcement,
} from './enforceSectionContainer';

import type { CompRef } from 'types/documentServices';
import type { Scope } from '#packages/sections';

export const createSectionOnPageEnforcementService = (scope: Scope) => {
  const { editorAPI } = scope;
  const pagesEnforcementMap: Record<string, boolean> = {};

  const enforceOnPage = async (pageRef: CompRef) => {
    const isEnforcingOnCurrentPage = pagesEnforcementMap[pageRef.id];
    if (isEnforcingOnCurrentPage) return;

    pagesEnforcementMap[pageRef.id] = true;
    await enforceSectionContainer(scope, pageRef);
    pagesEnforcementMap[pageRef.id] = false;
  };

  const doEnforceSectionContainerOnPage = async (pageRef: CompRef) => {
    if (!isAllowedToRunEnforcement(editorAPI, pageRef)) return;

    await enforceOnPage(pageRef);
  };

  const forceEnforcement = async (pageRef: CompRef) => {
    await enforceOnPage(pageRef);
  };

  return {
    run: doEnforceSectionContainerOnPage,
    _force: forceEnforcement,
  };
};

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import React from 'react';
import { translate } from '#packages/i18n';
import * as Symbols from '@wix/santa-editor-symbols';

interface ApplyModeFromClipboardSuggestionProps {
  text: string;
  linkActionText: string;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<ApplyModeFromClipboardSuggestionProps>({
  displayName: 'applyModeFromClipboardSuggestion',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    text: PropTypes.string.isRequired,
    linkActionText: PropTypes.string.isRequired,
  },
  componentWillUnmount() {
    this.getEditorAPI().copyPaste.hideApplyModeFromClipboardSuggestion();
  },
  close() {
    this.getEditorAPI().copyPaste.hideApplyModeFromClipboardSuggestion();
  },
  onMouseDown(e: AnyFixMe) {
    e.stopPropagation();
  },
  onLinkClick(e: AnyFixMe) {
    e.preventDefault();
    this.getEditorAPI().copyPaste.removePastedComponentAndApplyModeFromClipboard();
  },
  render() {
    return (
      <div
        onMouseDown={this.onMouseDown}
        style={{
          padding: '6px',
        }}
        className="quick-tip"
      >
        <div onClick={this.close} className="close-quick-tip">
          <span>
            <Symbols.symbol name="bubble-close" />
          </span>
        </div>

        <div className="quick-tip-image">
          <Symbols.symbol name="idea-bulb" />
        </div>

        <div
          style={{
            textAlign: 'left',
          }}
          className="text-container"
        >
          <div className="text">{translate(this.props.text)}</div>
          <div onClick={this.onLinkClick} className="text link-action">
            {translate(this.props.linkActionText)}
          </div>
        </div>
      </div>
    );
  },
});

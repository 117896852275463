import React, { useMemo, useState } from 'react';
import * as util from '#packages/util';
import { TextInput, TextLabel } from '@wix/wix-base-ui';

interface PasswordProps {
  label: string;
  onBlur: (value: string, isValid: boolean) => void;
  dataHook?: string;
  placeholder: string;
  invalidMessage: string;
  onMarkedInvalid: () => void;
  showStubWhenEmpty: boolean;
  validateInitialValue: boolean;
}

const PASSWORD_MAX_LENGTH = 15;
const validator = (value: string) => util.validate.notEmpty(value);

const Password: React.FC<PasswordProps> = ({
  label,
  onBlur,
  dataHook,
  placeholder,
  invalidMessage,
  onMarkedInvalid,
  showStubWhenEmpty,
  validateInitialValue,
}) => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const stubValue = useMemo(
    () =>
      showStubWhenEmpty && !isFocused && !isDirty && !value ? '######' : '',
    [showStubWhenEmpty, isFocused, isDirty, value],
  );
  const enforcedValidationStatus = useMemo(
    () => (validateInitialValue && !isDirty ? validator(value) : undefined),
    [validateInitialValue, isDirty, value],
  );

  const handleChange = (newValue: string, newIsValid: boolean) => {
    setIsDirty(true);

    setValue(newValue);
    setIsValid(newIsValid);

    if ((isValid || !isDirty) && !newIsValid) {
      onMarkedInvalid();
    }
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
    onBlur(value, isValid);
  };

  return (
    <div className="password-control">
      <TextLabel
        value={label}
        className="password-label"
        shouldTranslate={false}
      />
      <TextInput
        key="password-control"
        type={isFocused ? 'text' : 'password'}
        value={stubValue || value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={handleChange}
        maxLength={PASSWORD_MAX_LENGTH}
        allowInvalidChange={true}
        validateInitialValue={false}
        isValid={enforcedValidationStatus}
        validateOnValuePropChange={false}
        validator={validator}
        placeholder={placeholder}
        invalidMessage={invalidMessage}
        dataHook={dataHook}
        shouldTranslate={false}
      />
    </div>
  );
};

export default Password;

import type { ElementIdentifier, ComponentRef } from '@wix/platform-editor-sdk';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import { findConnectedComponentByRole } from './gfppTargetUtils';

export const getCompRefByRole = (
  editorAPI: EditorAPI,
  elementIdentifier: ElementIdentifier,
  controllerRef: CompRef,
  containingOnly: boolean,
) => {
  const connectedComponents = containingOnly
    ? editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getChildrenFromFull(
        controllerRef,
        true,
      )
    : editorAPI.dsRead.platform.controllers.connections.getConnectedComponents(
        controllerRef,
        true,
      );

  return findConnectedComponentByRole(
    editorAPI,
    connectedComponents,
    elementIdentifier.role,
    elementIdentifier.subRole,
  ) as ComponentRef;
};

export const findConnectionByRole = (
  elementIdentifier: ElementIdentifier,
  primaryConnections: ElementIdentifier[],
) => {
  return primaryConnections.find((primaryConnection) => {
    if (elementIdentifier.subRole) {
      return (
        elementIdentifier.role === primaryConnection?.role &&
        elementIdentifier.subRole === primaryConnection?.subRole
      );
    }
    return elementIdentifier.role === primaryConnection?.role;
  });
};

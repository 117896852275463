import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 299,
        height: 373,
        x: 34,
        y: 26,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'mpyol',
      },
      parent: 'comp-kgt5y9k9',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'mpyol',
        },
        svgId: '2428012b6769448081719f3fde9f0f78.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'mpyol',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'mpyol',
        },
        overrideColors: {
          color1: '#1D2CF3',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#F75E5E',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'mpyol',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'mpyol',
        },
      },
      scopedTransformations: {
        'variants-kgt5y9kv': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'mpyol',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'mpyol',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 210,
          height: 262,
          x: 34,
          y: 25,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5y9kx',
          author: 'studio',
        },
      },
    },
    {
      type: 'Container',
      components: [
        {
          type: 'Component',
          skin: 'skins.viewer.mediaOverlayControlsDefaultSkin',
          layout: {
            width: 50,
            height: 50,
            x: 96,
            y: 95,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.MediaOverlayControls',
          metaData: {
            pageId: 'mpyol',
          },
          parent: 'comp-kgt5y9ky6',
          props: {
            type: 'MediaOverlayControlsProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'mpyol',
            },
            playerId: 'comp-kgt5y9ky6',
          },
          design: {
            type: 'MediaControlsDesignData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'mpyol',
            },
            iconsDefaultDesign: {
              type: 'VectorImageDesignData',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'mpyol',
              },
              overrideColors: {
                color1: '#000000',
              },
              shapeStyle: {
                opacity: 0.8,
                strokeWidth: 1,
                stroke: '#000000',
                strokeOpacity: 1,
                enableStroke: false,
              },
            },
            icons: [
              {
                type: 'ControlIconDesignData',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'mpyol',
                },
                name: 'playButton',
                svgId: '0da768_661bc0cafffa432db3753ad5d17e4f10.svg',
              },
            ],
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'mpyol',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName:
              'wysiwyg.viewer.components.MediaOverlayControls',
            styleType: 'custom',
            skin: 'skins.viewer.mediaOverlayControlsDefaultSkin',
          },
          mobileStructure: {
            layout: {
              width: 35,
              height: 35,
              x: 67,
              y: 67,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            metaData: {
              originalCompId: 'comp-kgt5y9mj',
              author: 'studio',
            },
          },
        },
        {
          type: 'Component',
          skin: 'skins.viewer.mediaControlsNoControlsSkin',
          layout: {
            width: 51,
            height: 44,
            x: 192,
            y: 196,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.MediaControls',
          metaData: {
            pageId: 'mpyol',
          },
          parent: 'comp-kgt5y9ky6',
          props: {
            type: 'MediaControlsProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'mpyol',
            },
            showStoryboard: 'none',
            playerId: 'comp-kgt5y9ky6',
          },
          design: {
            type: 'MediaControlsDesignData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'mpyol',
            },
            iconsDefaultDesign: {
              type: 'VectorImageDesignData',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'mpyol',
              },
              overrideColors: {
                color1: '#ffffff',
              },
              shapeStyle: {
                opacity: 1,
                strokeWidth: 1,
                stroke: '#000000',
                strokeOpacity: 1,
                enableStroke: false,
              },
            },
            icons: [
              {
                type: 'ControlIconDesignData',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'mpyol',
                },
                name: 'volumeOn',
                svgId: '0da768_4026746ebab74627a513aa0df1511dea.svg',
              },
              {
                type: 'ControlIconDesignData',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'mpyol',
                },
                name: 'volumeOff',
                svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg',
              },
              {
                type: 'ControlIconDesignData',
                metaData: {
                  isPreset: false,
                  schemaVersion: '1.0',
                  isHidden: false,
                  pageId: 'mpyol',
                },
                name: 'noAudio',
                svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg',
                iconDesign: {
                  type: 'VectorImageDesignData',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    pageId: 'mpyol',
                  },
                  overrideColors: {
                    color1: '#a9a9a9',
                  },
                },
              },
            ],
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'mpyol',
            },
            style: {
              properties: {},
              propertiesSource: {},
              groups: {},
            },
            componentClassName: 'wysiwyg.viewer.components.MediaControls',
            styleType: 'custom',
            skin: 'skins.viewer.mediaControlsNoControlsSkin',
          },
          mobileStructure: {
            layout: {
              width: 51,
              height: 31,
              x: 119,
              y: 139,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            metaData: {
              originalCompId: 'comp-kgt5y9mq',
              author: 'studio',
            },
          },
        },
      ],
      skin: 'wysiwyg.viewer.skins.mediaPlayerSkin',
      layout: {
        width: 240,
        height: 240,
        x: 66,
        y: 67,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.MediaPlayer',
      metaData: {
        pageId: 'mpyol',
      },
      parent: 'comp-kgt5y9k9',
      props: {
        type: 'MediaPlayerProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'mpyol',
        },
        autoplay: true,
        playerInteraction: {
          click: 'none',
          rollIn: 'none',
          rollOut: 'none',
          allowReplay: true,
        },
        mute: true,
        loop: true,
        disableAudio: false,
        playerAudioInteraction: {
          rollIn: 'none',
          rollOut: 'none',
        },
        animatePoster: 'none',
        flip: 'none',
      },
      design: {
        type: 'MediaPlayerDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'mpyol',
        },
        background: {
          type: 'BackgroundMedia',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'mpyol',
          },
          mediaRef: {
            type: 'WixVideo',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'mpyol',
            },
            title: 'Cartoon Star',
            videoId: '11062b_2ce25faff3bd45cead7feb59808531ad',
            qualities: [
              {
                quality: '480p',
                width: 480,
                height: 480,
                url: 'video/11062b_2ce25faff3bd45cead7feb59808531ad/480p/mp4/file.mp4',
              },
              {
                quality: '720p',
                width: 720,
                height: 720,
                url: 'video/11062b_2ce25faff3bd45cead7feb59808531ad/720p/mp4/file.mp4',
              },
              {
                quality: '1080p',
                width: 1080,
                height: 1080,
                url: 'video/11062b_2ce25faff3bd45cead7feb59808531ad/1080p/mp4/file.mp4',
              },
            ],
            posterImageRef: {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'mpyol',
              },
              title: '',
              uri: '11062b_2ce25faff3bd45cead7feb59808531adf000.png',
              description:
                'public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae',
              width: 2000,
              height: 2000,
              alt: '',
            },
            opacity: 1,
            duration: 8,
            preload: 'auto',
            artist: {
              id: '',
              name: '',
            },
            generatedPosters: [
              '11062b_2ce25faff3bd45cead7feb59808531adf000.png',
              '11062b_2ce25faff3bd45cead7feb59808531adf001.png',
              '11062b_2ce25faff3bd45cead7feb59808531adf002.png',
              '11062b_2ce25faff3bd45cead7feb59808531adf003.png',
            ],
            hasAudio: false,
            fps: '30/1',
            adaptiveVideo: [
              {
                format: 'hls',
                url: 'site/media/video/11062b_2ce25faff3bd45cead7feb59808531ad/da01a1fc-ad34-44bc-90e8-cc9413c466e6/repackage/hls',
              },
            ],
            mediaFeatures: ['alpha'],
          },
          alignType: 'center',
          fittingType: 'fill',
          colorOverlay: '',
          colorOverlayOpacity: 0,
        },
      },
      style: 'mp1',
      mobileStructure: {
        layout: {
          width: 170,
          height: 170,
          x: 56,
          y: 54,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5y9ky6',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 300,
        height: 53,
        x: 34,
        y: 326,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'mpyol',
      },
      parent: 'comp-kgt5y9k9',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'mpyol',
        },
        text: `<h4 class="font_4" style="font-size:35px; text-align:center"><span style="font-family:oswald-medium,oswald,sans-serif;"><span style="font-size:35px"><span style="color:#FFDE5F">${translate(
          'interactions_preset_sale_animation_text',
        )}</span></span></span></h4>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'mpyol',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 170,
          height: 37,
          x: 54,
          y: 233,
          scale: 0.819226750261233,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'mpyol',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5y9ms',
          author: 'studio',
        },
      },
    },
  ],
  layout: {
    width: 375,
    height: 435,
    x: 302,
    y: 41,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'mpyol',
  },
  parent: 'mpyol',
  variants: {
    'variants-kgt5y9kv': {
      componentId: 'comp-kgt5y9k9',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'mpyol',
      },
    },
  },
  transitions: {
    type: 'TransitionData',
    'timing-function': 'ease-in-out',
    duration: 0.4,
    delay: 0,
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'mpyol',
    },
  },
  transformations: {
    type: 'TransformData',
    origin: {
      x: {
        value: 50,
        type: 'percentage',
      },
      y: {
        value: 50,
        type: 'percentage',
      },
    },
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'mpyol',
    },
  },
  scopedTransformations: {
    'variants-kgt5y9kv': {
      type: 'TransformData',
      zLayer: 'BELOW_PINNED',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'mpyol',
      },
      scale: {
        x: 1.05,
        y: 1.05,
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'mpyol',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_15',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'theme',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 318,
      x: 20,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5y9k9',
      author: 'studio',
    },
  },
  activeModes: {},
};

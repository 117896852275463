// @ts-nocheck
import _ from 'lodash';
import utils, { PARAM_SEPERATOR } from '../../utils/multiComponentsUtils';
import { arrayUtils } from '#packages/util';
import {
  initialize,
  getCompType,
  getComponentsTypes,
  getCompRole,
  getCompStyle,
  getCompStyleByTab,
  getCompRoleOverride,
  getSkinStyleParamOverrides,
  getSkin,
  SHADOW_PREFIX,
  compType,
  skinName,
  calculateCommonStyle,
  extractCommonConfiguration,
} from './widgetDesignHelpers';

const getWidgetDesignComponentAdapter = ({
  editorAPI,
  tabs,
  connectedComponents,
}) => {
  const {
    supportedTabs,
    components,
    compRoleOverrides,
    skinDefPerTab,
    compSkinsPerTab,
  } = initialize(editorAPI, tabs, connectedComponents);

  const getMappedParams = (comp, param, index) => {
    const styleParamOverrides = _.get(
      supportedTabs[index].styleParamOverrides,
      getCompType(editorAPI, comp),
      {},
    );

    const mappedParams =
      _.get(styleParamOverrides, param) ||
      _.get(
        getSkinStyleParamOverrides(
          skinDefPerTab,
          index,
          getSkin(editorAPI, comp),
        ),
        param,
      );
    if (mappedParams) {
      return mappedParams;
    }
    return Object.values(styleParamOverrides).find((overrides) =>
      overrides.includes(param),
    )
      ? []
      : [param];
  };

  const getStyleParamMapping = (comp, param, index) => {
    const [styleParam, role] = param.split(PARAM_SEPERATOR);
    if (
      !role ||
      role === getCompRoleOverride(editorAPI, compRoleOverrides, comp)
    ) {
      return getMappedParams(comp, styleParam, index);
    }
  };

  const getTabLabels = () => supportedTabs.map((tab) => tab.label);

  const getTabsContent = () =>
    supportedTabs.map((tab) => ({
      label: tab.label,
      tooltip: tab.tooltip,
      tooltipData: tab.tooltipData,
    }));

  const openDashboardPanel = (
    appDefinitionId: string,
    options: { closeOtherPanels?: boolean; url?: string },
  ) => {
    const appData =
      editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId);
    editorAPI.platform.panels.openDashboardPanel(appData, options);
  };

  const getSelectedComponents = (index) => components[index];

  const isSingleComponentType = (componentTypes) => componentTypes.length === 1;

  const getSkinDefinitionForTab = (index) =>
    skinDefPerTab[index].skinDefinition;

  const getSkinStyleParamOverridesByCompForTab = (index) =>
    skinDefPerTab[index].skinStyleParamOverridesByComp;

  const calcCommonStyle = (index) => {
    const styleByComp = getCompStyleByTab(editorAPI, supportedTabs, components)[
      index
    ];
    const skinDefByComp = compSkinsPerTab[index];
    const compIdsByRole = components[index].reduce((acc, comp) => {
      const compRole = getCompRoleOverride(editorAPI, compRoleOverrides, comp);
      const currentCompIds = _.get(acc, compRole);
      if (currentCompIds) {
        currentCompIds.push(comp.id);
        return acc;
      }
      return _.set(acc, compRole, [comp.id]);
    }, {});

    const params = getSkinDefinitionForTab(index)?.params || {};
    const compsStylesOverrides = getSkinStyleParamOverridesByCompForTab(index);

    const paramKeys = Object.keys(params);
    return calculateCommonStyle(
      params,
      paramKeys,
      styleByComp,
      skinDefByComp,
      compIdsByRole,
      compsStylesOverrides,
    );
  };

  const calcCommonConfiguration = (index) => {
    const compsConfigurations = components[index]
      .map(
        (compRef) =>
          editorAPI.platform.controllers.getConnectedComponentStageData(compRef)
            ?.panel?.design,
      )
      .filter((configuration) => configuration !== undefined);

    return extractCommonConfiguration(compsConfigurations);
  };

  const getStyleParamUpdates = (key, val, source, index) => {
    const compsInTab = components[index];
    const { prefix, groupKey } = utils.getPrefixAndGroup(key);
    const mappings = compsInTab.reduce((acc, component) => {
      acc[component.id] = [
        {
          mapping: getStyleParamMapping(component, groupKey, index),
          val,
          source,
          prefix,
        },
      ];
      return acc;
    }, {});
    return createStyleUpdates(mappings, compsInTab);
  };

  const onStyleParamsChanged = (keysToUpdates, newStyle, index) => {
    const groupsAndPrefix = keysToUpdates.map(utils.getPrefixAndGroup);
    const compsInTab = components[index];
    const mappings = compsInTab.reduce((acc, component) => {
      acc[component.id] = groupsAndPrefix.map(({ groupKey, prefix }) => ({
        mapping: getStyleParamMapping(component, groupKey, index),
        val: newStyle.style.properties[`${prefix}${groupKey}`],
        overrideValue:
          newStyle.style?.propertiesOverride[`${prefix}${groupKey}`],
        prefix,
      }));
      return acc;
    }, {});
    return createStyleUpdates(mappings, compsInTab);
  };

  const createStyleUpdates = (mappings, comps) =>
    comps.reduce((arr, component) => {
      const compStyle = getCompStyle(editorAPI, component);
      const adaptedStyle = utils.adapterTransformation(
        mappings[component.id],
        compStyle,
      );
      if (adaptedStyle) {
        arr = arr.concat([{ style: adaptedStyle, componentRef: component }]);
      }
      return arr;
    }, []);

  const updateStyleForComponents = (updates) =>
    updates.forEach(({ componentRef, style }) =>
      editorAPI.components.style.update(componentRef, style),
    );

  const onStyleParamChanged = (key, val, source, index) => {
    return new Promise((resolve) =>
      editorAPI.waitForChangesApplied(() => {
        const updates = getStyleParamUpdates(key, val, source, index);
        updateStyleForComponents(updates);
        resolve();
      }),
    );
  };

  const updateStyle = (currentStyle, newStyle, index) => {
    return new Promise((resolve) =>
      editorAPI.waitForChangesApplied(() => {
        const styleParamsToOverride = Object.keys(
          newStyle.style.propertiesOverride || {},
        );
        const styleParamsToUpdate = Object.keys(
          newStyle.style.properties || {},
        ).filter(
          (key) =>
            key.startsWith(SHADOW_PREFIX) ||
            styleParamsToOverride.includes(key) ||
            newStyle.style.properties[key] !==
              currentStyle.style.properties[key],
        );

        const updates = onStyleParamsChanged(
          styleParamsToUpdate,
          newStyle,
          index,
        );
        updateStyleForComponents(updates);
        resolve();
      }),
    );
  };

  const getTabIndexForComponent = (comp) => {
    return supportedTabs.findIndex((tab) => {
      const { compTypes = [], roles = [] } = tab?.groups;
      return (
        compTypes.includes(editorAPI.components.getType(comp)) ||
        roles.includes(getCompRole(editorAPI, comp))
      );
    });
  };

  const getType = (index) => {
    const selectedComponents = getSelectedComponents(index);
    const seclectedComponentsTypes = getComponentsTypes(
      editorAPI,
      selectedComponents,
    );

    return isSingleComponentType(seclectedComponentsTypes)
      ? seclectedComponentsTypes[0]
      : compType;
  };

  const getCompSkin = (index) => {
    const selectedComponents = getSelectedComponents(index);
    const seclectedComponentsTypes = getComponentsTypes(
      editorAPI,
      selectedComponents,
    );

    return isSingleComponentType(seclectedComponentsTypes)
      ? getSkin(editorAPI, selectedComponents[0])
      : skinName;
  };

  return {
    getTabLabels,

    getTabsContent,

    openDashboardPanel,

    getSelectedComponents,

    getType,

    getConfiguration: calcCommonConfiguration,

    getSkin: getCompSkin,

    getSkinDefinitionForTab,

    getStyle(index) {
      const isMultiSelect = arrayUtils.isMultiselect(components[index]);

      return {
        style: {
          properties: calcCommonStyle(index),
          // FYI: Logic is taken as reference from another style panel
          // Link: https://github.com/wix-private/santa-editor/blob/379783228690f7be1719c31af81763d4d9e8e3d6/santa-editor/packages/designPanel/panelTypes/changeStylePanelMapper.ts#L150
          // We don't return propertiesOverride in case of multiselect
          ...(!isMultiSelect && {
            propertiesOverride:
              getCompStyle(editorAPI, components[index])?.style
                ?.propertiesOverride ?? {},
          }),
        },
      };
    },

    onStyleParamChanged,

    updateStyle,

    getTabIndexForComponent,
  };
};

export default getWidgetDesignComponentAdapter;

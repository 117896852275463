import React, { useEffect } from 'react';
import experiment from 'experiment';
import * as panels from '#packages/panels';
import { Illustration, Composites, RichText } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import { fedopsLogger } from '#packages/util';
import { TRANSLATIONS } from './utils/translations';

export interface IDeleteMenuSetPanelOwnProps {
  menuNameToDelete: string;
  menuNameToSubstituteWith: string;

  onConfirm(): void;
  onCancel?(): void;
}

interface IDeleteMenuSetPanelProps extends IDeleteMenuSetPanelOwnProps {
  panelName: string; // it's provided on panel opening
}

export const DeleteMenuSetPanel: React.FC<IDeleteMenuSetPanelProps> = ({
  panelName,
  onConfirm,
  onCancel,
  menuNameToDelete,
  menuNameToSubstituteWith,
}) => {
  useEffect(() => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_DELETE_MENU_SET_PANEL,
    );
  }, []);

  const text = (
    <RichText type="T01">
      <p>
        {translate(TRANSLATIONS.DESCRIPTION_1, {
          menu_name_to_delete: menuNameToDelete,
          menu_name_to_substitute: menuNameToSubstituteWith,
        })}
      </p>
      <p>{translate(TRANSLATIONS.DESCRIPTION_2)}</p>
    </RichText>
  );

  return experiment.isOpen('se_removeOldMessagePanelFrame') ? (
    <panels.frames.MessagePanelFrame
      panelName={panelName}
      title={translate(TRANSLATIONS.PANEL_TITLE, {
        menu_name_to_delete: menuNameToDelete,
      })}
      confirmLabel={translate(TRANSLATIONS.CONFIRM_LABEL)}
      cancelLabel={translate(TRANSLATIONS.CANCEL_LABEL)}
      onConfirm={onConfirm}
      onCancel={onCancel}
      theme="destructive"
      illustration={<Symbol name="delete_trash_illustration" />}
    >
      {text}
    </panels.frames.MessagePanelFrame>
  ) : (
    <panels.frames.OldMessagePanelFrame
      panelName={panelName}
      panelTitle={translate(TRANSLATIONS.PANEL_TITLE, {
        menu_name_to_delete: menuNameToDelete,
      })}
      confirmLabel={TRANSLATIONS.CONFIRM_LABEL}
      cancelLabel={TRANSLATIONS.CANCEL_LABEL}
      onConfirm={onConfirm}
      onCancel={onCancel}
      type="danger"
    >
      <Composites.RichTextWithIllustration>
        <Illustration>
          <Symbol name="delete_trash_illustration" />
        </Illustration>
        <Composites.RichText>{text}</Composites.RichText>
      </Composites.RichTextWithIllustration>
    </panels.frames.OldMessagePanelFrame>
  );
};

import type { MapStateToProps, MapDispatchToPropsFunction } from 'types/redux';
import * as stateManagement from '#packages/stateManagement';
import type {
  MenuBarStateProps,
  MenuBarDispatchProps,
  MenuBarOwnProps,
} from './menuBar.types';
import { EditorPermissionsApiKey } from '#packages/apis';

const LAST_ACTIVE_SHOWN_SALE_SESSION = 'lastActiveShownSaleSession';

export const mapStateToProps: MapStateToProps<
  MenuBarStateProps,
  MenuBarOwnProps
> = ({ state, editorAPI }) => {
  const stateFromAPI = editorAPI.store.getState();

  const getSitePreferences = (name: string) => {
    return stateManagement.userPreferences.selectors.getSiteUserPreferences(
      name,
    )(stateFromAPI);
  };

  return {
    initialSelectedSubItem:
      stateManagement.topBar.selectors.getDropPanelActiveItem(state),

    lastActiveShownSaleSession: getSitePreferences(
      LAST_ACTIVE_SHOWN_SALE_SESSION,
    ) as string,

    sitePreferencesFetchDone:
      stateManagement.userPreferences.selectors.getSitePreferencesFetchDone(
        stateFromAPI,
      ) as boolean,

    saleEndDate: editorAPI.campaigns.personalSale.getSaleEndDate(),

    isSalePeriod: editorAPI.campaigns.personalSale.isActiveCampaign(),

    hasDesignPermissions: editorAPI.host
      .getAPI(EditorPermissionsApiKey)
      .has('CLASSIC-EDITOR.EDIT-DESIGN'),
  };
};

export const mapDispatchToProps: MapDispatchToPropsFunction<
  MenuBarDispatchProps,
  MenuBarOwnProps
> = (dispatch) => {
  const setSitePreferences = (name: string, value: string | boolean): void => {
    dispatch(
      stateManagement.userPreferences.actions.setSitePreferences('site')(
        name,
        value,
      ),
    );
  };

  return {
    setLastActiveShownSaleSession(id: string): void {
      setSitePreferences(LAST_ACTIVE_SHOWN_SALE_SESSION, id);
    },
  };
};

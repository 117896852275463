import _ from 'lodash';
import { componentSelectors } from '#packages/stateManagement';
import { arrayUtils } from '#packages/util';
import {
  getOneComponentIfSiblings,
  findComponentAncestorMatchesCondition,
  someComponentAncestorMatchesCondition,
  isRepeater,
  hasComponentChildrenOrScopedChildren,
  getComponentChildrenOrScopedChildren,
  getComponentsContainer,
  findComponentScopeOwner,
  getComponentScopeOwner,
  getComponentsContainerOrScopeOwner,
  getComponentsContainer_DEPRECATED_BAD_PERFORMANCE,
  isComponentAncestorOfComp,
  isComponentAncestorOfCompOrCompScope,
  type FindComponentAncestorMatchesConditionOptions,
} from '#packages/documentServices';
import type { CompRef, DSRead } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

export function createComponentsGetApi({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) {
  const getComponentsByAncestor_DEPRECATED_BAD_PERFORMANCE: DSRead['deprecatedOldBadPerformanceApis']['components']['get']['byAncestor'] =
    (compRef) =>
      editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.get.byAncestor(
        compRef,
      );

  function getComponentsByXYRelativeToStructure(
    x: number,
    y: number,
    margin?: AnyFixMe,
    pageId?: string,
  ) {
    return componentSelectors.filterVisibleComponents(
      editorAPI.dsRead.components.get.byXYRelativeToStructure(
        x,
        y,
        // @ts-expect-error
        margin,
        pageId,
      ),
      editorAPI.dsRead,
    );
  }

  function getComponentsByType(
    compType: string,
    rootComp?: CompRef,
  ): CompRef[] {
    return componentSelectors.getComponentsByType(
      compType,
      rootComp,
      editorAPI.dsRead,
    );
  }

  function getComponentsByType_DEPRECATED_BAD_PERFORMANCE(
    compType: string,
    rootComp: CompRef,
  ): CompRef[] {
    return componentSelectors.getComponentsByType_DEPRECATED_BAD_PERFORMANCE(
      compType,
      rootComp,
      editorAPI.dsRead,
    );
  }

  function getAllComponents(pageId?: string, predicate?: () => boolean) {
    return componentSelectors.filterVisibleComponents(
      editorAPI.dsRead.components.getAllComponents(pageId, predicate),
      editorAPI.dsRead,
    );
  }

  function getAllComponents_DEPRECATED_BAD_PERFORMANCE(
    pageId?: string,
    predicate?: () => boolean,
  ) {
    return componentSelectors.filterVisibleComponents(
      editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getAllComponents(
        pageId,
        predicate,
      ),
      editorAPI.dsRead,
    );
  }

  const getAllComponentsFromFull_DEPRECATED_BAD_PERFORMANCE: DSRead['components']['getAllComponentsFromFull'] =
    (...args) =>
      editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getAllComponentsFromFull(
        ...args,
      );

  const getAncestors_DEPRECATED_BAD_PERFORMANCE: DSRead['components']['getAncestors'] =
    (compRef) =>
      editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getAncestors(
        compRef,
      );

  /**
   Returns first ancestor matches condition
   */
  function findAncestor(
    compRefOrRefs: CompRef | CompRef[],
    condition: (ancestorRef: CompRef) => boolean,
    options?: FindComponentAncestorMatchesConditionOptions,
  ) {
    const compRef = getOneComponentIfSiblings(editorAPI.dsRead, compRefOrRefs);
    return findComponentAncestorMatchesCondition(
      editorAPI.dsRead,
      compRef,
      condition,
      options,
    );
  }

  /**
   Returns true if one of ancestors matches condition
   */
  function someAncestor(
    compRefOrRefs: CompRef | CompRef[],
    condition: (ancestorRef: CompRef) => boolean,
    options?: FindComponentAncestorMatchesConditionOptions,
  ) {
    const compRef = getOneComponentIfSiblings(editorAPI.dsRead, compRefOrRefs);
    return someComponentAncestorMatchesCondition(
      editorAPI.dsRead,
      compRef,
      condition,
      options,
    );
  }

  function getAncestorRepeaterItem(
    compRefOrRefs: CompRef | CompRef[],
  ): CompRef | null {
    const compRef = Array.isArray(compRefOrRefs)
      ? compRefOrRefs[0]
      : compRefOrRefs;

    if (!editorAPI.components.is.repeatedComponent(compRef)) {
      return null;
    }

    return findAncestor(
      compRef,
      (ancestorRef) => editorAPI.components.is.repeaterItem(ancestorRef),
      {
        includeScopeOwner: true,
      },
    );
  }

  function getAncestorRepeater(
    compRefOrRefs: CompRef | CompRef[],
  ): CompRef | null {
    const compRef = Array.isArray(compRefOrRefs)
      ? compRefOrRefs[0]
      : compRefOrRefs;
    if (!editorAPI.components.is.repeatedComponent(compRef)) {
      return null;
    }

    return findAncestor(
      compRef,
      (ancestorRef) => isRepeater(editorAPI.dsRead, ancestorRef),
      {
        includeScopeOwner: true,
      },
    );
  }

  function getSiblings(compRefs: CompRef | CompRef[]): CompRef[] {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length === 1) {
      return editorAPI.dsRead.components.getSiblings(compsArr[0]);
    } else if (compsArr.length > 1) {
      if (!editorAPI.components.layout.containsSiblingsOnly(compsArr)) {
        throw new Error(
          'Cannot obtain siblings of the given components since they are not siblings',
        );
      }
      const allChildren = editorAPI.dsRead.components.getSiblings(compsArr[0]);
      const onlySiblings = _.reject(allChildren, (compRef) =>
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/some
        _.some(compsArr, { id: compRef.id }),
      );

      return onlySiblings;
    }
  }

  function getChildren(
    compRefs: CompRef | CompRef[],
    isRecursive?: boolean,
  ): CompRef[] {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      return [];
    }
    return editorAPI.dsRead.components.getChildren(compsArr[0], isRecursive);
  }

  function getChildren_DEPRECATED_BAD_PERFORMANCE(
    compRefs: CompRef | CompRef[],
    isRecursive?: boolean,
  ): CompRef[] {
    const compsArr = arrayUtils.asArray(compRefs);
    if (compsArr.length > 1) {
      return [];
    }
    return editorAPI.dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
      compsArr[0],
      isRecursive,
    );
  }

  function hasChildren(compRefs: CompRef | CompRef[]) {
    return getChildren(compRefs).length > 0;
  }

  function getChildrenRecursivelyWithResolvers(
    compRefs: CompRef | CompRef[],
    predicate: (ref: CompRef) => boolean,
  ): CompRef[] {
    const components = arrayUtils.asArray(compRefs);

    return components.reduce((resCompRefs: CompRef[], compRef: CompRef) => {
      const children = editorAPI.components.getChildren(compRef);

      if (predicate(compRef) && children.length) {
        return [
          ...resCompRefs,
          compRef,
          ...getChildrenRecursivelyWithResolvers(children, predicate),
        ];
      }

      return [...resCompRefs, compRef];
    }, []);
  }

  function getRootComponents(pageId?: string): CompRef[] {
    const masterPageRef = editorAPI.components.get.byId(
      editorAPI.pages.getMasterPageId(),
    );

    const rootComponents =
      editorAPI.dsRead.components.getChildren(masterPageRef);

    if (pageId === masterPageRef.id) {
      return rootComponents;
    }

    const pageIds = pageId ? [pageId] : editorAPI.pages.getPageIdList();
    const pages = pageIds.map((id: string) =>
      editorAPI.components.get.byId(id),
    );

    const pagesContainerIndex = rootComponents.findIndex((compRef: CompRef) =>
      editorAPI.utils.isSameRef(
        compRef,
        editorAPI.siteSegments.getPagesContainer(),
      ),
    );

    rootComponents.splice(pagesContainerIndex + 1, 0, ...pages);

    return rootComponents;
  }

  function hasChildrenOrScopedChildren(compRef: CompRef) {
    return hasComponentChildrenOrScopedChildren(editorAPI.dsRead, compRef);
  }

  function getChildrenOrScopedChildren(compRef: CompRef) {
    return getComponentChildrenOrScopedChildren(editorAPI.dsRead, compRef);
  }

  function getContainer(compRefOrRefs: CompRef | CompRef[]) {
    return getComponentsContainer(
      editorAPI.dsRead,
      arrayUtils.asArray(compRefOrRefs),
    );
  }

  function getContainer_DEPRECATED_BAD_PERFORMANCE(
    compRefOrRefs: CompRef | CompRef[],
  ) {
    return getComponentsContainer_DEPRECATED_BAD_PERFORMANCE(
      editorAPI.dsRead,
      arrayUtils.asArray(compRefOrRefs),
    );
  }

  function getContainerOrScopeOwner(compRefOrRefs: CompRef | CompRef[]) {
    return getComponentsContainerOrScopeOwner(
      editorAPI.dsRead,
      arrayUtils.asArray(compRefOrRefs),
    );
  }

  function getScopeOwner(compRef: CompRef) {
    return getComponentScopeOwner(editorAPI.dsRead, compRef);
  }

  function findScopeOwner(
    compRef: CompRef,
    condition: (scopeOwnerRef: CompRef) => boolean,
  ) {
    return findComponentScopeOwner(editorAPI.dsRead, compRef, condition);
  }

  function isDescendantOfComp(
    compRefOrRefs: CompRef | CompRef[],
    possibleAncestor: AnyFixMe,
  ) {
    return arrayUtils
      .asArray(compRefOrRefs)
      .every((compRef: CompRef) =>
        editorAPI.dsRead.components.isDescendantOfComp(
          compRef,
          possibleAncestor,
        ),
      );
  }

  function isAncestorOfComp(compRef: CompRef, possibleDescendant: CompRef) {
    return isComponentAncestorOfComp(
      editorAPI.dsRead,
      compRef,
      possibleDescendant,
    );
  }

  function isAncestorOfCompOrCompScope(
    compRef: CompRef,
    possibleDescendant: CompRef,
  ) {
    return isComponentAncestorOfCompOrCompScope(
      editorAPI.dsRead,
      compRef,
      possibleDescendant,
    );
  }

  return {
    byAncestor_DEPRECATED_BAD_PERFORMACE:
      getComponentsByAncestor_DEPRECATED_BAD_PERFORMANCE,
    byXYRelativeToStructure: getComponentsByXYRelativeToStructure,
    byType: getComponentsByType,
    byType_DEPRECATED_BAD_PERFORMANCE:
      getComponentsByType_DEPRECATED_BAD_PERFORMANCE,
    getAllComponents,
    getAllComponents_DEPRECATED_BAD_PERFORMANCE,
    getAllComponentsFromFull_DEPRECATED_BAD_PERFORMANCE,
    getAncestors_DEPRECATED_BAD_PERFORMANCE,
    someAncestor,
    findAncestor,
    getAncestorRepeaterItem,
    getAncestorRepeater,
    getSiblings,
    getChildren,
    getChildren_DEPRECATED_BAD_PERFORMANCE,
    hasChildren,
    hasChildrenOrScopedChildren,
    getChildrenOrScopedChildren,
    getChildrenRecursivelyWithResolvers,
    getRootComponents,
    getContainer,
    getContainer_DEPRECATED_BAD_PERFORMANCE,
    getContainerOrScopeOwner,
    getScopeOwner,
    findScopeOwner,
    isDescendantOfComp,
    isAncestorOfComp,
    isAncestorOfCompOrCompScope,
  };
}

export type ComponentsGetApi = ReturnType<typeof createComponentsGetApi>;

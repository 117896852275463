import * as textControls from '#packages/textControls';
import { EditorAPIKey } from '#packages/apis';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import createCopyPasteUtils, {
  PasteActionOrigins,
} from '@/rEditor/app/APISections/copyPasteWrapper/createCopyPasteUtils';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import createPaste from '@/rEditor/app/APISections/copyPasteWrapper/createPaste';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import * as snapshotUtil from '@/rEditor/utils/snapshotUtil';
import * as httpService from './httpService';

import type { Shell } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';

const {
  fetchCollection,
  fetchItemPreview,
  fetchItemSource,
  renameItem,
  postItem,
  deleteItem,
} = httpService;

export const createSavedComponentsApi = (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const copyPasteUtils = createCopyPasteUtils(editorAPI);
  const pasteWrapper = createPaste(editorAPI);

  const getPreviewSnapshot = (components: CompRef[]) => {
    const snugLayoutRelativeToStructure =
      editorAPI.components.layout.getRelativeToScreen(components);
    const { width, height } = snugLayoutRelativeToStructure;
    let { x, y } = snugLayoutRelativeToStructure;
    const targetContainer = editorAPI.pages.getPrimaryContainer();
    const targetContainerIsPage = editorAPI.utils.isPage(targetContainer);

    if (!targetContainerIsPage) {
      const targetContainerLayout =
        editorAPI.components.layout.getRelativeToScreen(targetContainer);
      x -= targetContainerLayout.x;
      y -= targetContainerLayout.y;
    }

    const snapshots = components.map((compRef: AnyFixMe) => {
      const relativeToScreenLayout =
        editorAPI.components.layout.getRelativeToScreen(compRef);
      const left = relativeToScreenLayout.x - x;
      const top = relativeToScreenLayout.y - y;
      const innerHTML =
        editorAPI.documentServices.snapshot.getSnapshot(compRef);

      return {
        left,
        top,
        innerHTML,
      };
    });

    const uploadedFonts =
      textControls.fontUtils.getUploadedFonts(editorAPI) || [];

    const previewData = {
      body: snapshotUtil.getBodyHTML(snapshots, width, height),
      style: uploadedFonts.reduce((acc, font) => acc + font.fontFace, ''),
      cssLinks: textControls.fontUtils.getAllFontsCss(
        editorAPI.dsRead.theme.fonts.getCharacterSet(),
      ),
    };

    const previewDimension = {
      width: Math.ceil(width),
      height: Math.ceil(height),
    };

    return {
      previewDimension,
      previewData,
      previewTemplate: snapshotUtil.getEjsTemplate(),
    };
  };

  const filterUnsupportedComponents = (compRefs: CompRef | CompRef[]) => {
    const originalComponents = util.array.asArray(compRefs);
    const supportedComponents = originalComponents.filter((comp) =>
      editorAPI.components.is.allowedAddToSavedComponent(comp),
    );

    return {
      supportedComponents,
      hasUnsupportedComponents:
        originalComponents.length > supportedComponents.length,
    };
  };

  const prepareComponentsForSave = (compRefs: CompRef[]) => {
    const orderedComponents =
      editorAPI.components.getUniqOrderedComponents(compRefs);

    const metaData = copyPasteUtils.getMetaDataForCopy();
    const serializedData = copyPasteUtils.prepareComponentsForCopy(
      orderedComponents,
      {},
      editorAPI.pasteLogic.pasteContext,
    );

    const { previewDimension, previewData, previewTemplate } =
      getPreviewSnapshot(orderedComponents);

    return {
      metaData,
      serializedData,
      previewDimension,
      previewData,
      previewTemplate,
    };
  };

  const pasteComponents = (
    savedData: AnyFixMe,
    metaData: AnyFixMe,
    options: {
      shouldApplyTargetTheme: boolean;
      pastePosition: null | AnyFixMe;
      elementId?: string;
      pasteContainer?: CompRef;
    } = { shouldApplyTargetTheme: false, pastePosition: null },
    biFields = {},
  ) => {
    const targetMetaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
    const originAction = PasteActionOrigins.AddPanelAdd;

    return pasteWrapper
      .pasteComponentWrappers(
        savedData.components,
        savedData.componentsData,
        {
          theme: savedData.theme,
          originMetaSiteId: metaData.metaSiteId,
          guid: metaData.guid,
          snugLayoutRelativeToStructure:
            savedData.snugLayoutRelativeToStructure,
          originalScrollPosition: savedData.originalScrollPosition,
          siteUploadToken: metaData.siteUploadToken,
        },
        {
          isCrossSite: targetMetaSiteId !== metaData.metaSiteId,
          shouldApplyTargetTheme: options.shouldApplyTargetTheme,
          rightClickPosition: options.pastePosition,
          forcedPasteContainer: options.pasteContainer,
          originAction,
        },
      )
      .then((result) => {
        util.editorWixRecorder.addLabel(
          `${savedData[0]?.componentType} added to stage`,
        );
        const newCompPointer = (result as any)?.newComponentPointers?.[0];

        const componentBiFields = newCompPointer
          ? editorAPI.bi.getComponentsBIParams([newCompPointer])
          : {};

        editorAPI.bi.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
          ...componentBiFields,
          origin: originAction,
          component_id: options.elementId,
          page_id:
            editorAPI.pages.getFocusedPage() &&
            editorAPI.pages.getFocusedPage().id,
          ...biFields,
        });
        return result;
      });
  };

  return {
    pasteComponents,
    prepareComponentsForSave,
    filterUnsupportedComponents,
    getPreviewSnapshot,

    httpService: {
      fetchCollection,
      fetchItemPreview,
      fetchItemSource,
      renameItem,
      postItem,
      deleteItem,
    },
  };
};

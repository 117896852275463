// @ts-nocheck
import _ from 'lodash';
import musicThemeSection from '../music/live/section2';
import videoCategory from '../video/videoCategory';
import * as addPanel from '#packages/oldAddPanel';

export default {
  getCategorySections() {
    const videoSections = videoCategory.getCategorySections();
    const mediaSections = addPanel.sections.getSections().media;
    const filteredMediaSections = _.omit(
      mediaSections,
      Object.keys(videoSections),
    );
    return addPanel.dataUtil.mergeWithCommonSections(
      filteredMediaSections,
      Object.assign({ musicThemeSection }, videoSections),
    );
  },
};

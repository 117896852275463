// @ts-nocheck
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';
import { array } from '#packages/util';

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 395,
      height: 180,
      x: -29.5,
      y: 271,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 44,
          originalValue: 500,
          type: 'BOTTOM_PARENT',
          locked: true,
          targetComponent: 'comp-ioimgv7l',
        },
      ],
      fixedPosition: false,
    },
  });

  // add form to
  // components: [
  //     component_0,
  //     component_1,
  //     component_2: {
  //         components: [
  //             component_20,
  //             component_21,
  //             form
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: array.immutableSplice(structure.components, 2, 1, {
      ...structure.components[2],
      components: array.immutableSplice(
        structure.components[2].components,
        2,
        0,
        form,
      ),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.STRIP03);
  onClick = onClick(wixFormsConstants.FormPreset.STRIP03);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};

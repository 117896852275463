import _ from 'lodash';

import constants from '#packages/constants';
import * as util from '#packages/util';
import { layoutUtils } from '#packages/layoutUtils';
import { mobile, domMeasurements, copyPaste } from '#packages/stateManagement';
import type { Viewport } from '#packages/stateManagement';

import * as pasteLogicCalculations from './pasteLogicCalculations';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  CompRef,
  Size,
  CompStructure,
  Rect,
} from 'types/documentServices';
import type { ScrollPosition } from 'types/core';
import type { Point } from './types';

const { getMobileFramePosition } = mobile.selectors;
const { getViewPort } = domMeasurements.selectors;
const { shouldContainerGrowToFitContent, getContainerToFitContent } =
  copyPaste.selectors;

const isComponentPositionAboveContainer = (compPosition: Point) =>
  compPosition.y < 0;

const isComponentPositionExceedsContainerRight = (
  pastePosition: Point,
  containerLayout: Size,
  componentLayout: Rect,
) => pastePosition.x + componentLayout.width > containerLayout.width;

const doesContainerExceedsViewPort = (
  containerLayout: Size,
  viewPort: Viewport,
) => containerLayout.height > viewPort.stageLayout.height;

export interface ComponentPasteLogicConfig {
  centerOnFirstPaste?: boolean;
}
export default class ComponentPasteLogic {
  private originalCompPosition = {} as Point;
  private originalScrollPosition: number = 0;
  private firstCompPosition = {} as Point;
  private lastCompPosition = {} as Point;
  private lastCompContainer = '';
  private lastScrollPosition: ScrollPosition = null;
  private lastAddedComponent: CompRef = null;
  private isCopyPasteFlow: boolean;

  private centerOnFirstPasteFromConfig: boolean;

  constructor(config: ComponentPasteLogicConfig = {}) {
    this.centerOnFirstPasteFromConfig = config.centerOnFirstPaste;
  }

  get centerOnFirstPaste() {
    return (
      this.centerOnFirstPasteFromConfig ?? util.sections.isSectionsEnabled()
    );
  }

  setPasteData(
    componentLayout: Rect,
    containerId: string,
    scrollPosition: ScrollPosition,
  ) {
    this.originalScrollPosition = scrollPosition.scrollTop;
    this.originalCompPosition = { x: componentLayout.x, y: componentLayout.y };
    this.firstCompPosition = { x: componentLayout.x, y: componentLayout.y };
    this.lastCompPosition = { x: componentLayout.x, y: componentLayout.y };
    this.lastCompContainer = containerId;
    this.lastScrollPosition = scrollPosition;
    this.isCopyPasteFlow = true;
  }

  resetPastePosition() {
    this.firstCompPosition = {} as Point;
    this.lastCompPosition = {} as Point;
    this.lastAddedComponent = null;
  }

  setLastAddedComponent(compRef: CompRef) {
    this.lastAddedComponent = compRef;
  }

  getPastePosition(
    editorAPI: EditorAPI,
    componentLayout: Rect,
    containerId: string,
    recalculateOnViewportTouch = true,
    components: CompStructure[] = [],
  ) {
    const editorScroll = editorAPI.ui.scroll.getScroll();
    const lastAddedComponentExists =
      this.lastAddedComponent &&
      editorAPI.components.get.byId(this.lastAddedComponent.id);
    const isMultipleComponentsPaste = components.length > 1;
    let lastAndCurrentComponentsHaveDifferentWidth = false;

    if (lastAddedComponentExists && !isMultipleComponentsPaste) {
      const lastAddedComponentLayout =
        editorAPI.components.layout.getRelativeToScreen(
          this.lastAddedComponent,
        );

      lastAndCurrentComponentsHaveDifferentWidth =
        lastAddedComponentLayout.width !== componentLayout.width;
    }

    if (
      !_.isEqual(editorScroll, this.lastScrollPosition) ||
      (!this.isCopyPasteFlow &&
        (!lastAddedComponentExists ||
          lastAndCurrentComponentsHaveDifferentWidth))
    ) {
      this.resetPastePosition();
    }

    const containerRef = editorAPI.components.get.byId(containerId);
    const isPage = editorAPI.utils.isPage(containerRef);

    this.handleContainerChange(containerId);

    const pastePosition = isPage
      ? this.getComponentPositionInPage(
          editorAPI,
          containerId,
          componentLayout,
          recalculateOnViewportTouch,
          components,
        )
      : this.getComponentPositionInContainer(
          editorAPI,
          containerRef,
          componentLayout,
          components,
        );

    this.lastCompPosition = pastePosition;
    this.lastCompContainer = containerId;
    this.lastScrollPosition = editorScroll;

    return _.clone(pastePosition);
  }

  getComponentPositionInContainer(
    editorAPI: EditorAPI,
    containerRef: CompRef,
    componentLayout: Rect,
    components: CompStructure[],
  ) {
    //TODO try to combine this method with getComponentPositionInPage
    let pastePosition = {} as Point;

    const containerLayout =
      editorAPI.components.layout.getRelativeToScreen(containerRef);

    const viewPort = getViewPort(editorAPI.store.getState(), true);

    let shouldInitPosition = false;

    if (this.isFirstPaste()) {
      if (shouldContainerGrowToFitContent(editorAPI, containerRef)) {
        const containerToFitContent = getContainerToFitContent(
          editorAPI,
          containerRef,
        );
        const containerCurrentLayout = editorAPI.components.layout.get(
          containerToFitContent,
        );
        const containerLayoutToFitToContent =
          layoutUtils.getContainerLayoutToFitContent(
            containerCurrentLayout,
            componentLayout,
          );

        editorAPI.components.layout.update(
          containerToFitContent,
          containerLayoutToFitToContent,
        );
      }
      if (doesContainerExceedsViewPort(containerLayout, viewPort)) {
        pastePosition =
          pasteLogicCalculations.getCenteredPositionInContainerAndViewPort(
            editorAPI,
            containerLayout,
            viewPort,
            componentLayout,
          );
      } else {
        pastePosition = pasteLogicCalculations.getCenteredPositionInContainer(
          containerLayout,
          componentLayout,
        );
      }

      shouldInitPosition = true;
    } else {
      pastePosition = pasteLogicCalculations.getNextComponentPosition(
        this.lastCompPosition,
      );

      if (
        pasteLogicCalculations.cannotFitInContainer(
          pastePosition,
          componentLayout,
          containerLayout,
        )
      ) {
        pastePosition = pasteLogicCalculations.getNewStackComponentPosition(
          this.firstCompPosition,
        );
        shouldInitPosition = true;
      }
    }

    if (isComponentPositionAboveContainer(pastePosition)) {
      pastePosition.y = 0;
    }

    if (
      isComponentPositionExceedsContainerRight(
        pastePosition,
        containerLayout,
        componentLayout,
      )
    ) {
      pastePosition.x = pasteLogicCalculations.getCenteredPositionInContainer(
        containerLayout,
        componentLayout,
      ).x;
    }

    pastePosition = this.getClampedPosition(
      editorAPI,
      util.sections.isSectionsEnabled()
        ? {
            x: pastePosition.x,
            y: containerLayout.y + pastePosition.y,
          }
        : pastePosition,
      componentLayout,
      components,
    );

    if (util.sections.isSectionsEnabled()) {
      pastePosition.y -= containerLayout.y;

      if (this.isFirstPaste()) {
        const { width: relativeWidth } =
          editorAPI.components.layout.get_size(containerRef);

        // back to relative positioning
        pastePosition.x -= (containerLayout.width - relativeWidth) / 2;
      }
    }

    if (shouldInitPosition) {
      this.firstCompPosition = pastePosition;
    }

    return pastePosition;
  }

  private getClampedPosition(
    editorAPI: EditorAPI,
    position: Point,
    componentLayout: Rect,
    components: CompStructure[],
  ) {
    if (!util.sections.isSectionsEnabled()) return position;

    if (
      components.some(
        (comp) => comp.componentType === constants.COMP_TYPES.SECTION,
      )
    ) {
      return position;
    }

    const {
      y: [lowerBound, upperBound],
      x: [leftBound, rightBound],
    } = util.sections.getPastePositionBoundaries(editorAPI, components);

    const clampedPositionY = _.clamp(
      position.y,
      lowerBound,
      // TODO: we already subtracted `componentLayout.height` inside `getPastePositionBoundaries`. Why again?
      upperBound - componentLayout.height,
    );

    const clampedPositionX = _.clamp(
      position.x,
      leftBound,
      rightBound - componentLayout.width,
    );

    return {
      x: editorAPI.isMobileEditor() ? position.x : clampedPositionX,
      y: clampedPositionY,
    };
  }

  isFirstPaste() {
    return _.isEmpty(this.firstCompPosition);
  }

  isContainerChanged(containerId: string) {
    return containerId !== this.lastCompContainer;
  }

  scrollToPastePositionIfNeeded(
    editorAPI: EditorAPI,
    viewPort: Viewport,
    compPosition: Point,
  ) {
    if (!pasteLogicCalculations.isComponentInViewPort(viewPort, compPosition)) {
      editorAPI.ui.scroll.setScroll({
        scrollLeft: 0,
        scrollTop: compPosition.y,
      });
    }
  }

  handleContainerChange(containerId: string) {
    if (!this.lastCompContainer) {
      this.lastCompContainer = containerId;
    }

    if (this.isContainerChanged(containerId)) {
      this.resetPastePosition();
    }
  }

  isFirstPasteInViewPortCenter(
    editorAPI: EditorAPI,
    viewPort: Viewport,
    containerId: string,
  ) {
    return (
      this.centerOnFirstPaste ||
      (this.isContainerChanged(containerId) &&
        pasteLogicCalculations.cannotFitIntoPageHeight(
          editorAPI,
          viewPort,
          this.originalCompPosition,
        ))
    );
  }

  getComponentPositionInPage(
    editorAPI: EditorAPI,
    pageId: string,
    componentLayout: Rect,
    recalculateOnViewportTouch: boolean,
    components: CompStructure[],
  ) {
    const state = editorAPI.store.getState();
    const viewPort = _.cloneDeep(getViewPort(state, true));

    if (util.fixedStage.isFixedStageEnabled()) {
      const siteWidth = editorAPI.site.getWidth();
      Object.assign(viewPort, { width: siteWidth, rightX: siteWidth });
      Object.assign(viewPort.stageLayout, {
        width: siteWidth,
        right: siteWidth,
      });
    }

    if (this.isFirstPaste()) {
      return this.getAndUpdatePositionForFirstPaste(
        editorAPI,
        viewPort,
        componentLayout,
        pageId,
        components,
      );
    }

    let pastePosition = pasteLogicCalculations.getNextComponentPosition(
      this.lastCompPosition,
    );

    const mobileFramePosition = getMobileFramePosition(state);

    const cannotFitIntoStage = editorAPI.isMobileEditor()
      ? pasteLogicCalculations.cannotFitIntoMobileStage(
          mobileFramePosition,
          pastePosition,
        )
      : recalculateOnViewportTouch &&
        pasteLogicCalculations.cannotFitIntoViewPort(
          viewPort,
          pastePosition,
          componentLayout,
        );

    if (cannotFitIntoStage) {
      pastePosition = pasteLogicCalculations.getNewStackComponentPosition(
        this.firstCompPosition,
      );

      this.firstCompPosition = pastePosition;
    }

    pastePosition = this.getClampedPosition(
      editorAPI,
      pastePosition,
      componentLayout,
      components,
    );

    return pastePosition;
  }

  getAndUpdatePositionForFirstPaste(
    editorAPI: EditorAPI,
    viewPort: Viewport,
    componentLayout: Rect,
    containerId: string,
    components: CompStructure[],
  ) {
    let pastePosition = {} as Point;

    if (this.isFirstPasteInViewPortCenter(editorAPI, viewPort, containerId)) {
      pastePosition = pasteLogicCalculations.getCenteredCompPosition(
        editorAPI,
        viewPort,
        componentLayout,
      );
      if (isComponentPositionAboveContainer(pastePosition)) {
        pastePosition.y = 0;
      }
    } else if (this.isContainerChanged(containerId)) {
      pastePosition = this.originalCompPosition;
      this.scrollToPastePositionIfNeeded(editorAPI, viewPort, pastePosition);
    } else {
      const editorScroll = editorAPI.ui.scroll.getScroll();
      const originalX = this.originalCompPosition.x || 0;
      const originalY = this.originalCompPosition.y || 0;

      pastePosition = {
        x: originalX,
        y: originalY - this.originalScrollPosition + editorScroll.scrollTop,
      };
    }

    pastePosition = this.getClampedPosition(
      editorAPI,
      pastePosition,
      componentLayout,
      components,
    );

    this.firstCompPosition = pastePosition;

    return pastePosition;
  }
}

import _ from 'lodash';
import {
  SelectionApiKey,
  EditorAPIKey,
  PanelManagerApiKey,
  FixedStageApiKey,
} from '#packages/apis';
import type { Shell } from '#packages/apilib';
import {
  userPreferences,
  panels,
  notifications,
  attachCandidate,
} from '#packages/stateManagement';
import { tokenService } from '#packages/platform';
import { link, fedopsLogger } from '#packages/util';
import LazyComponent from '#packages/lazyComponent';
import { NOTIFICATIONS } from '#packages/helpIds';
import constants from '#packages/constants';
import { events } from '#packages/coreBi';
import * as progressBarUtils from './progressBarUtils';

import {
  OUT_OF_GRID_CONFIG,
  ORGANIZE_IMAGES_PANEL_NAME,
  AVIARY_PANEL_NAME,
  PAGES_AND_POPUPS_PANEL_NAME,
  NOTIFICATION_RATE,
} from './constants';

const { USER_PREFS } = constants;
const { isDraggingToSameParentComponent } = attachCandidate.selectors;

export function createPanelHelpersApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const selectionAPI = shell.getAPI(SelectionApiKey);
  const panelManagerAPI = shell.getAPI(PanelManagerApiKey);
  const progressBar = progressBarUtils.createProgressBar(shell);
  const { store } = editorAPI;

  function imagesChangedCallback(
    onImagesChanged: AnyFixMe,
    newImageDataList: AnyFixMe,
  ) {
    if (onImagesChanged) {
      onImagesChanged(newImageDataList);
    }
  }

  function getImagePanelsProps(
    galleryType: AnyFixMe,
    onImagesChanged: AnyFixMe,
  ) {
    const data = selectionAPI.getSelectedComponentData();

    return {
      isPreset: data.metaData.isPreset,
      imagesChanged: imagesChangedCallback.bind(null, onImagesChanged),
      createImageDataItem: editorAPI.dsRead.data.createItem.bind(null, 'Image'),
      galleryType,
    };
  }

  function openOrUpdatePanel(
    panelName: string,
    panelProps: AnyFixMe,
    leavePanelsOpen?: boolean,
  ) {
    if (!panelManagerAPI.isPanelOpened(panelName)) {
      panelManagerAPI.openPanel(panelName, panelProps, leavePanelsOpen);
    } else if (panelProps) {
      panelManagerAPI.updatePanelProps(panelName, panelProps);
    }
  }

  function shouldNotShowOutOfGridPanel(
    outOfGridConfig: AnyFixMe,
    compType: AnyFixMe,
  ) {
    const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
    const dontShowAgain = userPreferences.selectors.getSiteUserPreferences(
      outOfGridConfig.DONT_SHOW_AGAIN_KEY,
    )(store.getState());

    const isComponentForbidden =
      OUT_OF_GRID_CONFIG.FORBIDDEN_COMP_TYPES.includes(compType);

    const isPopupOpened = editorAPI.dsRead.pages.popupPages.isPopupOpened();
    const isGridPanelHiddenForPopup =
      isPopupOpened &&
      !editorAPI.components.is.gridLinesVisible(
        editorAPI.pages.popupPages.getPopupContainer(),
      );

    const isWidgetPage = editorAPI.dsRead.pages.isWidgetPage(
      editorAPI.dsRead.pages.getFocusedPageId(),
    );

    const isStageFixedDesktop = fixedStageAPI.isStageFixedInDesktop();

    return (
      dontShowAgain ||
      isComponentForbidden ||
      isGridPanelHiddenForPopup ||
      isWidgetPage ||
      isStageFixedDesktop
    );
  }

  function openOrganizeImagesPanel(onImagesChanged: (data: unknown) => void) {
    store.dispatch(panels.actions.openOrganizeImagesPanel(onImagesChanged));
  }

  function openOrganizeSocialIconsPanel(onImagesChanged: FunctionFixMe) {
    panelManagerAPI.openPanel(
      ORGANIZE_IMAGES_PANEL_NAME,
      getImagePanelsProps('socialIcons', onImagesChanged),
    );
  }

  function openAviaryPanel(imageLink: string) {
    panelManagerAPI.openPanel(
      AVIARY_PANEL_NAME,
      {
        imageLink,
        onDialogResult() {
          panelManagerAPI.closePanelByName(AVIARY_PANEL_NAME);
        },
      },
      true,
    );
  }

  function isPagesPanelOpen() {
    return panelManagerAPI.isPanelOpened(PAGES_AND_POPUPS_PANEL_NAME);
  }

  function openPagesPanel(panelProps: AnyFixMe, leavePanelsOpen?: boolean) {
    const openPanelInteraction = fedopsLogger.mapInteraction(
      fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
    );
    const closePanelInteraction = fedopsLogger.mapInteraction(
      fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
    );

    const extendedPanelProps = {
      token: tokenService.generateStaticGlobalPanelToken('pagesPanel'),
      frameType: constants.PANEL_TYPES.LEFT,
      openPanelInteraction,
      closePanelInteraction,
      ...panelProps,
    };

    editorAPI.setForceOpenPagesPanelEventCounter();
    openPanelInteraction.start();
    openOrUpdatePanel(
      PAGES_AND_POPUPS_PANEL_NAME,
      extendedPanelProps,
      leavePanelsOpen,
    );
  }

  function closePagesPanel() {
    panelManagerAPI.closePanelByName(PAGES_AND_POPUPS_PANEL_NAME);
  }

  function openMobileElementsPanel() {
    if (!editorAPI.isMobileEditor()) {
      return;
    }
    const panelName = 'mobileEditor.mobileElementsPanel';
    const keySuffix = new Date().getTime();

    const panelKey = LazyComponent.isLoaded(panelName)
      ? panelName + keySuffix
      : `${panelName}loading`;

    const panelProps = {
      key: panelKey,
      mobileElementId: 'mobileView',
    };

    openOrUpdatePanel(panelName, panelProps);
  }

  function handleOutOfGrid(
    extraProps?: AnyFixMe,
    shouldOpenOutOfContainerGrid?: boolean,
  ) {
    let outOfGridConfig;

    if (shouldOpenOutOfContainerGrid) {
      outOfGridConfig = OUT_OF_GRID_CONFIG.CONTAINER;
    } else if (editorAPI.isMobileEditor()) {
      outOfGridConfig = OUT_OF_GRID_CONFIG.MOBILE;
    } else {
      outOfGridConfig = OUT_OF_GRID_CONFIG.DESKTOP;
    }

    const compType = editorAPI.components.getType(
      editorAPI.selection.getSelectedComponents(),
    );

    editorAPI.bi.event(events.stage.OUT_OF_GRIDLINES, {
      ...editorAPI.bi.getComponentsBIParams(
        editorAPI.selection.getSelectedComponents(),
      )[0],
      component_type: compType,
      source_name: shouldOpenOutOfContainerGrid ? 'strip' : 'page',
      is_same_parent_component: isDraggingToSameParentComponent(
        editorAPI.store.getState(),
      ),
    });

    if (shouldNotShowOutOfGridPanel(outOfGridConfig, compType)) {
      return;
    }

    let outOfGridCount =
      userPreferences.selectors.getSessionUserPreferences<number>(
        outOfGridConfig.OUT_OF_GRID_COUNT_KEY,
      )(store.getState()) || 0;
    if (outOfGridCount % outOfGridConfig.NOTIFICATION_RATE === 0) {
      if (!editorAPI.isMobileEditor()) {
        const onNotificationLinkClick = () => {
          panelManagerAPI.openHelpCenter(NOTIFICATIONS.OUT_OF_GRIDLINES);
        };
        store.dispatch(
          notifications.actions.showUserActionNotification({
            message: 'Notifications_Gridlines_Text',
            title: 'Out Of Gridlines',
            type: 'info',
            link: {
              caption: 'Notifications_Learn_More_Link',
              onNotificationLinkClick,
            },
          }),
        );
      } else {
        panelManagerAPI.openPanel(outOfGridConfig.PANEL_NAME, extraProps, true);
      }
    }

    outOfGridCount += 1;
    store.dispatch(
      userPreferences.actions.setSessionUserPreferences(
        outOfGridConfig.OUT_OF_GRID_COUNT_KEY,
        outOfGridCount,
      ),
    );
  }

  function openDragToHeaderPanel() {
    const dontShowAgain = userPreferences.selectors.getSiteUserPreferences(
      USER_PREFS.DRAG_TO_HEADER.DONT_SHOW_AGAIN,
    )(store.getState());

    if (dontShowAgain) {
      return;
    }

    let dragToHeaderCount =
      userPreferences.selectors.getSessionUserPreferences<number>(
        USER_PREFS.DRAG_TO_HEADER.DRAG_COUNT,
      )(store.getState()) || 0;
    if (dragToHeaderCount % NOTIFICATION_RATE === 0) {
      const onNotificationLinkClick = () => {
        panelManagerAPI.openHelpCenter(NOTIFICATIONS.ATTACH_TO_HEADER);
      };
      store.dispatch(
        notifications.actions.showUserActionNotification({
          message: 'Notifications_Attached_Header_Text',
          title: 'Attached To Header',
          type: 'info',
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick,
          },
        }),
      );
    }

    dragToHeaderCount += 1;
    store.dispatch(
      userPreferences.actions.setSessionUserPreferences(
        USER_PREFS.DRAG_TO_HEADER.DRAG_COUNT,
        dragToHeaderCount,
      ),
    );
  }

  function shouldNotificationBeShown(notificationMessage: string) {
    const { notifications } = store.getState();
    const notificationInstancesOnScreen = _.findKey(notifications, [
      'message',
      notificationMessage,
    ]);
    return (
      !userPreferences.selectors.getSiteUserPreferences(
        USER_PREFS.DRAG_TO_HEADER.DONT_SHOW_AGAIN,
      )(store.getState()) && !notificationInstancesOnScreen
    );
  }

  function openAttachToSOSPPanel() {
    const doNotShowAgain = Boolean(
      userPreferences.selectors.getSiteUserPreferences(
        USER_PREFS.SOSP.PANELS.ATTACH_DO_NOT_SHOW_AGAIN,
      )(store.getState()),
    );

    if (doNotShowAgain) {
      return;
    }

    let attachToSOSPCount =
      userPreferences.selectors.getSessionUserPreferences<number>(
        USER_PREFS.SOSP.PANELS.ATTACH_COUNT,
      )(store.getState()) || 0;

    if (attachToSOSPCount % NOTIFICATION_RATE === 0) {
      const onNotificationLinkClick = () => {
        panelManagerAPI.openHelpCenter(NOTIFICATIONS.ATTACH_TO_SOSP);
      };
      store.dispatch(
        notifications.actions.showUserActionNotification({
          message: 'Notifications_Attached_SOSP_Text',
          title: 'Attached To SOSP',
          type: 'info',
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick,
          },
        }),
      );
    }
    attachToSOSPCount += 1;
    store.dispatch(
      userPreferences.actions.setSessionUserPreferences(
        USER_PREFS.SOSP.PANELS.ATTACH_COUNT,
        attachToSOSPCount,
      ),
    );
  }

  function openDetachedFromSOSPPanel() {
    const doNotShowAgain = Boolean(
      userPreferences.selectors.getSiteUserPreferences(
        USER_PREFS.SOSP.PANELS.DETACH_DO_NOT_SHOW_AGAIN,
      )(store.getState()),
    );

    if (doNotShowAgain) {
      return;
    }

    let detachFromSOSPCount =
      userPreferences.selectors.getSessionUserPreferences<number>(
        USER_PREFS.SOSP.PANELS.DETACH_COUNT,
      )(store.getState()) || 0;

    if (detachFromSOSPCount % NOTIFICATION_RATE === 0) {
      const onNotificationLinkClick = () => {
        panelManagerAPI.openHelpCenter(NOTIFICATIONS.DETACH_FROM_SOSP);
      };
      store.dispatch(
        notifications.actions.showUserActionNotification({
          message: 'Notifications_Detached_SOSP_Text',
          title: 'Detached From SOSP',
          type: 'info',
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick,
          },
        }),
      );
    }

    detachFromSOSPCount += 1;
    store.dispatch(
      userPreferences.actions.setSessionUserPreferences(
        USER_PREFS.SOSP.PANELS.DETACH_COUNT,
        detachFromSOSPCount,
      ),
    );
  }

  function openPinModeSizeRestrictionPanel() {
    const dontShowAgain = userPreferences.selectors.getSiteUserPreferences(
      constants.PANELS.PIN_TOO_BIG.PIN_TOO_BIG_DONT_SHOW_AGAIN,
    )(store.getState());

    if (!dontShowAgain) {
      const onNotificationLinkClick = () => {
        panelManagerAPI.openHelpCenter(NOTIFICATIONS.PINNED_TOO_BIG);
      };
      store.dispatch(
        notifications.actions.showUserActionNotification({
          message: 'Notifications_Pinned_Large_Element_Text',
          title: 'Pinned Large Element',
          type: 'warning',
          link: {
            caption: 'Notifications_Learn_More_Link',
            onNotificationLinkClick,
          },
        }),
      );
    }
  }

  function openLinkPanel(props: AnyFixMe, shouldCloseParentPanels: AnyFixMe) {
    panelManagerAPI.openPanel(
      constants.PANEL_NAMES.LINK_PANEL,
      link.getLinkPanelProps(props),
      !shouldCloseParentPanels,
    );
  }

  function openFirstSaveSitePanel() {
    panelManagerAPI.openPanel(
      'savePublish.panels.save.saveYourSitePanel',
      {
        title: 'TOPBAR_SITE_UPGRADE_SAVE_FIRST_MSG_TITLE',
        messageBody: 'TOPBAR_SITE_UPGRADE_SAVE_FIRST_MSG_BODY',
      },
      true,
    );
  }

  return {
    openAviaryPanel,
    openAttachToSOSPPanel,
    openDetachedFromSOSPPanel,

    openDragToHeaderPanel,
    openFirstSaveSitePanel,
    openLinkPanel,
    openMobileElementsPanel,
    openOrganizeImagesPanel,
    openOrganizeSocialIconsPanel,
    openPinModeSizeRestrictionPanel,

    getImagePanelsProps,

    ...progressBar,

    isPagesPanelOpen,
    openPagesPanel,
    closePagesPanel,

    handleOutOfGrid,
    shouldNotificationBeShown,
  };
}

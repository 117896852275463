import { domMeasurements, type StageLayout } from '#packages/stateManagement';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';

const { getStageLayout } = domMeasurements.selectors;

export const SCROLL_DURATION_SECONDS = 0.5;
export const TOP_OFFSET_FOR_BIG_COMPONENTS = 60;

const getScrollTopValue = (
  stageLayout: StageLayout,
  compLayout: CompLayout,
  siteScale: number,
) => {
  if (compLayout.height > stageLayout.height) {
    return (compLayout.y - TOP_OFFSET_FOR_BIG_COMPONENTS) * siteScale;
  }

  const compCenter = compLayout.y + compLayout.height / 2;

  return (compCenter - stageLayout.height / 2) * siteScale;
};

export const scrollToComponent = (editorAPI: EditorAPI, compRef: CompRef) => {
  const stageLayout = getStageLayout(editorAPI.store.getState());
  const compLayout = editorAPI.components.layout.getRelativeToScreen(compRef);
  const siteScale = editorAPI.getSiteScale();

  editorAPI.ui.scroll.animateScroll(
    {
      y: getScrollTopValue(stageLayout, compLayout, siteScale),
    },
    SCROLL_DURATION_SECONDS,
  );
};

export const scrollToComponentIfHigherThanViewport = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const stageLayout = getStageLayout(editorAPI.store.getState());
  const compLayout = editorAPI.components.layout.getRelativeToScreen(compRef);

  if (compLayout.height > stageLayout.height) {
    const scrollValue = compLayout.y - TOP_OFFSET_FOR_BIG_COMPONENTS;

    editorAPI.ui.scroll.animateScroll(
      {
        y: scrollValue,
      },
      SCROLL_DURATION_SECONDS,
    );
  }
};

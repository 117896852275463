import React from 'react';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import { getCodeMenuAPI } from './codeMenuAPI';
import type { SendBiFunction } from 'types/bi';
import type { IMenuBarListItem } from '../../types';
import type {
  CodeMenuAPIOwnProps,
  CodeMenuAPIStateProps,
  CodeMenuAPIDispatchProps,
} from './codeMenuAPI';
import styles from './codeMenuItem.scss';
import DevModeToggle from '../../../DevModeToggle/DevModeToggle';

const { CODE_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

export interface CodeListItemsOwnProps extends CodeMenuAPIOwnProps {}
export interface CodeListItemsStateProps extends CodeMenuAPIStateProps {
  isFirstSave: boolean;
  siteId: string;
  isUserOwner: boolean;
}
export interface CodeListItemsDispatchProps extends CodeMenuAPIDispatchProps {
  sendBi: SendBiFunction;
}

export interface CodeListItemsProps
  extends CodeListItemsOwnProps,
    CodeListItemsStateProps,
    CodeListItemsDispatchProps {}

export const getCodeListItemsByProps = (
  props: CodeListItemsProps,
): IMenuBarListItem[] => {
  const codeAPI = getCodeMenuAPI(props);

  return [
    {
      key: CODE_MENU_ITEMS.ENABLE_DEVELOPER_MODE,
      automationId: 'list-item-enable-developer-mode',
      leftTitle: 'TopBar_DevMode_Enable_Developer_Mode',
      rightTitle: 'TopBar_DevMode_Enable_Developer_Mode_Promotion_Title',
      skipClickClosePanel: true,
      disableIllustrationClick: true,
      titleClassName: styles.tabTitle,
      selectedClassName: styles.selectedTab,
      description: 'TopBar_DevMode_Enable_Developer_Mode_Promotion_Text',
      linkText: 'TopBar_DevMode_Enable_Developer_Mode_Promotion_Link_Text',
      onLinkClick: () => {
        props.sendBi(coreBi.events.topbar.top_bar_CODE_menu_click, {
          category: 'learn_more',
        });
        window.open(
          translate(
            'TopBar_DevMode_Enable_Developer_Mode_Promotion_Learn_More_Link_URL',
          ),
        );
      },
      symbolName: 'devModeTooltipVeloLogo',
      onClick: () => {
        codeAPI[CODE_MENU_ITEMS.CODE_TOGGLE_DEV_TOOLS]();
      },
      suffix: (_props: any) => {
        return (
          <DevModeToggle
            toggle={codeAPI[CODE_MENU_ITEMS.CODE_TOGGLE_DEV_TOOLS]}
          />
        );
      },
    },
    {
      isSeparator: true,
    },
    {
      key: CODE_MENU_ITEMS.EMBED_HTML,
      automationId: 'list-item-embed-html',
      leftTitle: 'TopBar_DevMode_Embed_HTML',
      rightTitle: 'TopBar_DevMode_Embed_HTML_Promotion_Title',
      description: 'TopBar_DevMode_Embed_HTML_Promotion_Text',
      symbolName: 'devModeTooltipEmbedHTML',
      onClick() {
        codeAPI[CODE_MENU_ITEMS.EMBED_HTML]();

        props.sendBi(coreBi.events.topbar.top_bar_CODE_menu_click, {
          category: 'embed_html',
        });
      },
    },
    {
      key: CODE_MENU_ITEMS.ADD_A_CODE_SNIPPET,
      automationId: 'list-item-add-a-code-snippet',
      leftTitle: 'TopBar_DevMode_Add_3rd_Party_Code_Snippet',
      rightTitle: 'TopBar_DevMode_Add_3rd_Party_Code_Snippet_Promotion_Title',
      description: 'TopBar_DevMode_Add_3rd_Party_Code_Snippet_Promotion_Text',
      symbolName: 'devModeTooltipCodeSnippet',
      onClick() {
        codeAPI[CODE_MENU_ITEMS.ADD_A_CODE_SNIPPET]();

        props.sendBi(coreBi.events.topbar.top_bar_CODE_menu_click, {
          category: 'add_a_3rd_party_code_snippet',
        });
      },
    },
    {
      isSeparator: true,
    },
    {
      key: CODE_MENU_ITEMS.API_REFERENCES,
      automationId: 'list-item-api-references',
      leftTitle: 'TopBar_DevMode_View_API_Reference',
      rightTitle: 'TopBar_DevMode_View_API_Reference_Promotion_Title',
      description: 'TopBar_DevMode_View_API_Reference_Promotion_Text',
      symbolName: 'devModeTooltipApiReference',
      onClick() {
        codeAPI[CODE_MENU_ITEMS.API_REFERENCES]();

        props.sendBi(coreBi.events.topbar.top_bar_CODE_menu_click, {
          category: 'api_references',
        });
      },
    },
    {
      key: CODE_MENU_ITEMS.VIDEO_TUTORIALS,
      automationId: 'list-item-video-tutorials',
      leftTitle: 'TopBar_DevMode_Watch_Video_Tutorials',
      rightTitle: 'TopBar_DevMode_Watch_Video_Tutorials_Promotion_Title',
      description: 'TopBar_DevMode_Watch_Video_Tutorials_Promotion_Text',
      symbolName: 'devModeTooltipVideoTutorials',
      onClick() {
        codeAPI[CODE_MENU_ITEMS.VIDEO_TUTORIALS]();

        props.sendBi(coreBi.events.topbar.top_bar_CODE_menu_click, {
          category: 'video_tutorials',
        });
      },
    },
    {
      isSeparator: true,
    },
    {
      key: CODE_MENU_ITEMS.HIRE_A_DEVELOPER,
      automationId: 'list-item-hire-a-developer',
      leftTitle: 'TopBar_DevMode_Hire_A_Developer',
      rightTitle: 'TopBar_DevMode_Hire_A_Developer_Promotion_Title',
      description: 'TopBar_DevMode_Hire_A_Developer_Promotion_Text',
      symbolName: 'devModeTooltipHireDeveloper',
      onClick() {
        codeAPI[CODE_MENU_ITEMS.HIRE_A_DEVELOPER]();

        props.sendBi(coreBi.events.topbar.top_bar_CODE_menu_click, {
          category: 'hire_a_developer',
        });
      },
    },
  ];
};

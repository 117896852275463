import React from 'react';

import { TRANSLATION_KEYS } from '../../consts';
import {
  Progress98,
  Progress92,
  Progress89,
  ProgressLow,
} from './components/progressImages';

interface Category {
  title: string;
  subtitle: string;
  result: string;
  image: JSX.Element;
}

export const categories: Category[] = [
  {
    title: TRANSLATION_KEYS.RESULTS_TEXT_SECTION_TITLE,
    subtitle: TRANSLATION_KEYS.RESULTS_TEXT_SECTION_BODY,
    result: '98',
    image: <Progress98 />,
  },
  {
    title: TRANSLATION_KEYS.RESULTS_IMAGES_SECTION_TITLE,
    subtitle: TRANSLATION_KEYS.RESULTS_IMAGES_SECTION_BODY,
    result: '92',
    image: <Progress92 />,
  },
  {
    title: TRANSLATION_KEYS.RESULTS_LINKS_SECTION_TITLE,
    subtitle: TRANSLATION_KEYS.RESULTS_LINKS_SECTION_BODY,
    result: '89',
    image: <Progress89 />,
  },
  {
    title: TRANSLATION_KEYS.RESULTS_DOMAIN_SECTION_TITLE,
    subtitle: TRANSLATION_KEYS.RESULTS_DOMAIN_SECTION_BODY,
    result: 'Low',
    image: <ProgressLow />,
  },
] as const;

import type { Scope } from './scope';
import type { InnerRouteInfo } from './types';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';

export function getInnerRouteFromCurrentNavInfo(scope: Scope): InnerRouteInfo {
  const { editorAPI } = scope;
  const rootNavigationInfo =
    editorAPI.dsRead.pages.getRootNavigationInfo() || {};
  return {
    innerRoute: rootNavigationInfo?.innerRoute,
    routerId: rootNavigationInfo?.routerDefinition?.routerId,
  };
}

export function updateSaveState(scope: Scope) {
  const { editorAPI } = scope;

  if (
    editorAPI.store.getState().savingStatus ===
    constants.PROGRESS_STATUS.IDLE_SAVED
  ) {
    editorAPI.updateState({ savingStatus: constants.PROGRESS_STATUS.IDLE });
  }
  editorAPI.store.dispatch(
    stateManagement.leavePopup.actions.setLeavePopup(true),
  );
}

export const getOpenedInlinePopup = (scope: Scope) =>
  stateManagement.inlinePopup.selectors.getOpenedInlinePopup(
    scope.editorAPI.store.getState(),
  );

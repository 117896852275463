import React from 'react';
import { Composites, Divider, InfoIcon, TextLabel } from '@wix/wix-base-ui';

import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import { hoc, validate } from '#packages/util';

import { mapDispatchToProps } from './AddressLink.mapper';

import type { AddressLink as TAddressLink } from 'types/documentServices';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

type AddressLinkComponentProps = {
  link: TAddressLink;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TAddressLink): void;
} & ReturnType<typeof mapDispatchToProps>;

interface AddressLinkComponentState {
  link: TAddressLink;
  isAddressValid: boolean;
}

class AddressLinkComponent extends React.Component<
  AddressLinkComponentProps,
  AddressLinkComponentState
> {
  constructor(props: AddressLinkComponentProps) {
    super(props);
    this.state = {
      link: props.link || props.createDefaultData('AddressLink'),
      isAddressValid: false,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(this.state.isAddressValid);
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
    }

    if (this.state.isAddressValid !== prevState.isAddressValid) {
      this.props.onValidationUpdate(this.state.isAddressValid);
    }
  }

  private validateAddress = (
    addressValidation: string,
    onSuccess: () => void,
    onError: () => void,
  ) => {
    this.setState({ isAddressValid: false });

    return (
      addressValidation &&
      validate.validateAddress(
        addressValidation,
        ({ address }: AnyFixMe) => {
          const { link } = this.state;
          link.address = address;
          this.setState({ isAddressValid: true, link });
          onSuccess();
        },
        onError,
      )
    );
  };

  private setAddress = (address: AnyFixMe) => {
    const { link } = this.state;
    this.setState({
      link: {
        ...link,
        address,
      },
    });
  };

  private handleAddressValidation = (isValid: boolean): void => {
    this.setState({
      isAddressValid: isValid,
    });
  };

  render() {
    return (
      <>
        <Composites.TextInputLabeled>
          <InfoIcon text="Google_Maps_Settings_Information_Address_Tooltip" />
          <TextLabel value="mobile_quick_actions_set_up_address_label" />
          <baseUI.textInput
            key="address"
            asyncValidator={this.validateAddress}
            invalidMessage="Validation_V13_Google_Maps"
            placeholder="mobile_quick_actions_location_placeholder_text"
            value={this.state.link.address}
            onValidationStatus={this.handleAddressValidation}
            focus={true}
            maxLength={1024}
            validateInitialValue={true}
            onChange={this.setAddress}
            validateOnBlurOnly={true}
            blurOnEnterKey={true}
          />
        </Composites.TextInputLabeled>
        <Divider />

        <p className="extra-info">
          {translate('LINK_PANEL_ADDRESS_DESCRIPTION')}
        </p>
      </>
    );
  }
}

export const AddressLink = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(AddressLinkComponent);

AddressLink.pure = AddressLinkComponent;

import _ from 'lodash';
import { hoc, biLogger } from '#packages/util';
import React, {
  type Dispatch,
  type FC,
  type MutableRefObject,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import dragBox from '../../../addPanel/dragToStage/dragBox';
import {
  DynamicMediaBox,
  NoSearchResults,
  SearchLoader,
  UploadButton,
} from '#packages/mediaManagerPanelInfra';
import addPanelDragToStage from '../../../addPanel/dragToStage/addPanelDragToStage';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './dynamicMediaBoxWrapperMapper';
import {
  DYNAMIC_MEDIA_CONTENT_WIDTH,
  SEARCH_ITEMS,
  SEARCH_STATUS,
} from './dynamicMediaBoxWrapperConsts';
import { BI_ORIGIN } from '../../constants';
import { addMenuImageSearch } from '@wix/bi-logger-editor/v2';

import type { DynamicMediaSection } from '@wix/add-panel-common';
import type { DraggableItem } from '../../../addPanel/dragToStage/AddPanelDragHandler';
import type { InferComponentProps } from '#packages/apilib';
import type { MediaManagerItem } from '#packages/mediaServices';

const { RESULTS, NO_RESULTS, LOADING } = SEARCH_STATUS;

interface CategoryWithItemIds {
  [id: string]: string[];
}

export interface SearchSectionOwnProps {
  query?: string;
  resetQuery?: () => void;
  startItemDrag: (
    event: React.MouseEvent,
    dragItemData: DraggableItem,
  ) => Promise<void>;
  section: DynamicMediaSection;
  siteMediaToken: string;
}

type SearchSectionProps = InferComponentProps<
  null,
  typeof mapDispatchToProps,
  SearchSectionOwnProps
>;

const addSearchItemsToSection = (
  section: DynamicMediaSection,
  query: string,
  onMediaLoaded: (
    items: MediaManagerItem[],
    categoryWithItemIds: CategoryWithItemIds,
  ) => void,
): void => {
  section.props = {
    items: SEARCH_ITEMS.map((item) => ({ ...item, query, onMediaLoaded })),
  };
};

const loadCallback = (
  loadsCountRef: MutableRefObject<number>,
  setStatus: Dispatch<SetStateAction<SEARCH_STATUS>>,
  searchItemsIds: MutableRefObject<CategoryWithItemIds[]>,
) => {
  return (
    items: MediaManagerItem[],
    categoryWithItemIds: CategoryWithItemIds,
  ) => {
    loadsCountRef.current++;
    searchItemsIds.current.push(categoryWithItemIds);
    const stillLoadingResults = loadsCountRef.current < SEARCH_ITEMS.length;
    if (stillLoadingResults) return;
    const hasItems = searchItemsIds.current.some(
      (category) => Object.values(category)[0].length,
    );
    if (hasItems) {
      setStatus(RESULTS);
    } else {
      setStatus(NO_RESULTS);
    }
  };
};

const DynamicMediaBoxPure: FC<SearchSectionProps> = ({
  query,
  section,
  resetQuery,
  handleItemClick,
  onItemDragStart,
  openMediaManager,
}) => {
  const [searchStatus, setSearchStatus] = useState<SEARCH_STATUS>(null);
  const lastQuery = useRef<string>(null);
  const loadsCountRef = useRef<number>(0);
  const searchItemsIds = useRef<CategoryWithItemIds[]>([]);

  useEffect(() => {
    if (searchStatus === LOADING) {
      return;
    }
    const success = searchStatus === RESULTS;
    const jsonData = success ? JSON.stringify(searchItemsIds.current) : '';
    biLogger.report(
      addMenuImageSearch({
        jsonData,
        target: lastQuery.current,
        success,
      }),
    );
  }, [searchStatus]);

  if (query) {
    const newQuery = lastQuery.current !== query;
    if (newQuery) {
      lastQuery.current = query;
      loadsCountRef.current = 0;
      searchItemsIds.current = [];
      setSearchStatus(LOADING);
    }
    const onMediaLoaded = loadCallback(
      loadsCountRef,
      setSearchStatus,
      searchItemsIds,
    );
    addSearchItemsToSection(section, query, onMediaLoaded);
  }

  const hasResultsOrNotSearch = searchStatus === RESULTS || !searchStatus;

  return (
    <>
      {searchStatus === LOADING && (
        <SearchLoader label={'Media_Panel_Results_Loader'} />
      )}

      {searchStatus === NO_RESULTS && (
        <NoSearchResults
          buttonLabel={'Media_Panel_Upload_Image_Button'}
          shouldShowButton={true}
          onButtonClick={() =>
            openMediaManager(resetQuery, null, 'uploadImagesButton')
          }
        />
      )}

      <div
        style={{
          visibility: hasResultsOrNotSearch ? 'visible' : 'hidden',
        }}
      >
        <DynamicMediaBox
          {...section}
          key={query ?? 'dynamicMediaBox'}
          onClick={handleItemClick}
          showSectionHeader={true}
          biOriginBase={BI_ORIGIN}
          onItemDrag={onItemDragStart}
          disabledHeaderMargins={true}
          disabledContentPaddings={true}
          contentStyle={{
            width: DYNAMIC_MEDIA_CONTENT_WIDTH,
            minWidth: DYNAMIC_MEDIA_CONTENT_WIDTH,
          }}
        />
      </div>

      {searchStatus === RESULTS && (
        <UploadButton
          label={'Media_Panel_Upload_Image_Button'}
          onButtonClick={() =>
            openMediaManager(resetQuery, null, 'uploadImagesButton')
          }
        />
      )}
    </>
  );
};

export const DynamicMediaBoxWrapper = _.flowRight(
  hoc.withDragToStage(addPanelDragToStage, dragBox),
  hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps, mapDispatchToProps),
)(DynamicMediaBoxPure);

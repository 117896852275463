import constants from '#packages/constants';
import type {
  MapStateToProps,
  MapDispatchToProps,
  Dispatch,
  ThunkAction,
} from 'types/redux';
import type { ToolsButtonOwnProps } from './tools';
import { FixedStageApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';
import { biLogger, fixedStage } from '#packages/util';
import { topBarClick } from '@wix/bi-logger-editor/v2';

const { MENU_BAR_ITEMS } = constants.ROOT_COMPS.TOPBAR;

export interface ToolsButtonStateProps {
  panelName: string;
  isPanelOpen: boolean;
  isZoomMode: boolean;
  isLabelShown: boolean;
}

export interface ToolsButtonDispatchProps {
  reportToolsClicked: () => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatct,
  getState,
  { editorAPI },
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  ToolsButtonStateProps,
  ToolsButtonOwnProps
> = ({ editorAPI }) => {
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const openedDropPanel = editorAPI.topBarMenuBar.getOpenedDropDown();

  return {
    panelName: MENU_BAR_ITEMS.TOOLS,
    isPanelOpen: openedDropPanel === MENU_BAR_ITEMS.TOOLS,
    isZoomMode: editorAPI.zoomMode.isStageZoomMode(),
    isLabelShown: !fixedStageAPI.isTopBarMinimized(),
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  ToolsButtonDispatchProps,
  ToolsButtonOwnProps
> = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    reportToolsClicked: () => {
      biLogger.report(
        topBarClick({
          category: 'tools',
          is_published: editorAPI.generalInfo.isSitePublished(),
          state: fixedStage.getTopBarStateBiParamValue(editorAPI),
        }),
      );
    },
  };
};

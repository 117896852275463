// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import * as compPanelInfra from '#packages/compPanelInfra';
import * as core from '#packages/core';

import React from 'react';
import AppPartChangeStyleDesignPanel from './appPartChangeStyleDesignPanel';
import WixappsFontCustomizationPanel from './wixappsFontCustomizationPanel';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'wixappsDesignCustomizationPanel',
  mixins: [
    compPanelInfra.compPanelMixin,
    util.propTypesFilterMixin,
    compPanelInfra.wixappsPanelMixin,
  ],
  propTypes: {
    styleCustomizationRules: PropTypes.array.isRequired,
    compType: PropTypes.string.isRequired,
    panelTitle: PropTypes.string.isRequired,
    panelStyle: PropTypes.object.isRequired,
    customizeTheme: PropTypes.bool,
  },

  getPanelTitle() {
    return this.props.panelTitle;
  },
  getStyleId() {
    const rule = this._getStyleCustomizationRule();
    return this.linkedCustomization(rule).value;
  },
  getCompRefs() {
    return this.getEditorAPI().selection.getSelectedComponents();
  },
  updateStyle(styleDef) {
    this.getEditorAPI().dsActions.wixapps.styles.update(
      this.getStyleId(),
      styleDef,
    );
  },
  firstSystemStyleForComp() {
    const editorAPI = this.getEditorAPI();
    return _.head(
      core.styleManager.getUniqueThemeStyleList(
        editorAPI.theme,
        editorAPI.components,
        this.props.compType,
      ),
    );
  },
  getStyleDef() {
    const editorAPI = this.getEditorAPI();
    return (
      editorAPI.theme.styles.get(
        this.getStyleId(),
        editorAPI.components.getPage(this.getCompRefs()).id,
      ) || this.firstSystemStyleForComp()
    );
  },
  setStyleId(value) {
    this.props.styleCustomizationRules.forEach(function (rule) {
      this.setCustomizationValue(rule, value, true);
    }, this);
  },
  customizeDesignCallBack() {},
  getFieldIds() {
    return this.props.styleCustomizationRules.map(function (customization) {
      return customization.fieldId;
    });
  },
  getFormat() {
    const rule = this._getStyleCustomizationRule();
    return rule.format;
  },
  getView() {
    const rule = this._getStyleCustomizationRule();
    return rule.view;
  },
  _getStyleCustomizationRule() {
    return _.head(this.props.styleCustomizationRules);
  },
  getForType() {
    const rule = this._getStyleCustomizationRule();
    return rule.forType;
  },
  getPanelProps() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(this.filteredProps(), { style: this.props.panelStyle });
  },

  render() {
    return (
      <div>
        {this.props.compType !== 'wysiwyg.viewer.components.WRichText' ? (
          <AppPartChangeStyleDesignPanel
            key="changeStyleDesign"
            getTitle={this.getPanelTitle}
            compType={this.props.compType}
            currentStyleId={this.getStyleId()}
            selectedComponent={this.getCompRefs()}
            updateStyle={this.updateStyle}
            getStyle={this.getStyleDef}
            setStyleId={this.setStyleId}
            {...this.getPanelProps()}
          />
        ) : null}

        {this.props.compType === 'wysiwyg.viewer.components.WRichText' ? (
          <WixappsFontCustomizationPanel
            fieldIds={this.getFieldIds()}
            forType={this.getForType()}
            format={this.getFormat()}
            key="wixappsFontCustomization"
            selectedComponent={this.props.selectedComponent}
            title={this.getPanelTitle()}
            view={this.getView()}
            customizeTheme={this.props.customizeTheme}
            {...this.getPanelProps()}
          />
        ) : null}
      </div>
    );
  },
});

import styles from './styles/textItem.scss';
import _ from 'lodash';
import React, {
  type FC,
  type CSSProperties,
  useEffect,
  useRef,
  useState,
} from 'react';
import { cx, fedopsLogger, textUtils } from '#packages/util';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import { toggleButton as ToggleButton } from '#packages/baseUI';
import {
  ckUtils,
  getTextTooltip,
  iconButton as IconButton,
  iconsDropDown as IconsDropDown,
  textEditingUtils,
  textLinkControl as TextLinkControl,
} from '#packages/textControls';
import { ControlHeader } from '@wix/wix-base-ui';
import { mapDispatchToProps, mapStateToProps } from './textItemMapper';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import { QuickEditScope } from '../quickEditEntryPoint';
import AiTextCreatorEntryPoint from './aiTextCreatorEntryPoint';
import TextEditorWrapper from './textEditorWrapper';
import TextWithRuntimeDataControl from './textWithRuntimeDataControl';
import {
  ALIGNMENT_TYPES,
  BOLD,
  CK_DISABLED,
  ITALIC,
  LTR,
  RTL,
  TEXT_DATA_HOOKS,
  TEXT_LIST_TYPES,
  TEXT_LIST_TYPES_RTL,
  UNDERLINE,
} from '../consts';
import { reportDropDownSelection } from '../quickEditBi';
import { useUXResourceRestrictions } from '#packages/editorRestrictions';

import type { CmdState, TextManager } from 'types/data';
import type { QuickEditControlOwnProps } from '@wix/editor-elements-types/quickEditControls';

type TextItemWrapperProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  QuickEditControlOwnProps
>;

const { SMALL } = constants.UI.TOOLTIP.STYLE_TYPE;
const { getTextDirection, getInnerText } = textUtils;

const TextItem: FC<TextItemWrapperProps> = ({
  compRef,
  isConnectedToDataBinding,
  onMainActionClick,
  onHelp,
  isTitle,
  compText,
  isSelected,
  setIsSelected,
  stopTextEditing,
  execListCommand,
  reportExecCommand,
  openLinkDialogPanel,
  closeAiTextGeneratorPanelIfOpen,
  openEditTextPanelOnStage,
  monitorValueChanges,
  shouldShowAiTextGenerator,
}: TextItemWrapperProps) => {
  const [cmdState, setCmdState] = useState<CmdState>(null);
  const textManager = useRef<TextManager>(null);
  const [isMoreOptionsVisible, isTooltipVisible] = useUXResourceRestrictions([
    'quick-edit_text-more-options.visible',
    'quick-edit_text-tooltip.visible',
  ]);

  useEffect(() => {
    monitorValueChanges(compText);
  }, [monitorValueChanges, compText]);

  useEffect(() => {
    if (!isSelected && textManager.current) {
      stopTextEditing();
      closeAiTextGeneratorPanelIfOpen();
      textManager.current = null;
      setCmdState(null);
    }
    if (isSelected && !textManager.current) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.QUICK_EDIT_PANEL.TEXT_EDITOR_LOAD,
      );
    }
  }, [isSelected, stopTextEditing, closeAiTextGeneratorPanelIfOpen]);

  const onTextManagerReady = (manager: TextManager) => {
    textManager.current = manager;
    setCmdState(manager.getCommandsStates());
  };

  const onDataChanged = (_compText: string) => {
    textManager.current.setData(_compText);
    textManager.current.focus();
    textManager.current.selectAll();
  };

  const execCommand = (commandName: string, params: any = {}) => {
    textManager.current.execCommand(commandName, params);
    reportExecCommand(commandName);
  };

  const getBoldProps = () => ({
    name: 'textBold',
    instanceId: 'textBold',
    tooltipValue: getTextTooltip(BOLD, 'B'),
    value: cmdState.bold,
    onChange: (value: boolean) => execCommand(BOLD, { value }),
    secondaryColors: true,
    smallSize: true,
  });

  const getItalicProps = () => ({
    name: 'textItalic',
    instanceId: 'textItalic',
    tooltipValue: getTextTooltip(ITALIC, 'I'),
    value: cmdState.italic,
    onChange: (value: boolean) => execCommand(ITALIC, { value }),
    secondaryColors: true,
    smallSize: true,
  });

  const getUnderlineProps = () => ({
    name: 'textUnderline',
    instanceId: 'textUnderline',
    tooltipValue: getTextTooltip(UNDERLINE, 'U'),
    value: cmdState.underline,
    onChange: (value: boolean) => execCommand(UNDERLINE, { value }),
    secondaryColors: true,
    smallSize: true,
  });

  const getLinkProps = () => {
    const isLinkButtonDisabled = cmdState.wixLink === CK_DISABLED;
    const hasLink = !_.isEmpty(cmdState.wixLink) && !isLinkButtonDisabled;
    const tooltipKey = textManager.current.hasSelection()
      ? 'text_editor_link_disabled_2'
      : 'text_editor_link_disabled';
    return {
      name: 'textLink',
      instanceId: 'textLink',
      tooltipValue: isLinkButtonDisabled ? tooltipKey : getTextTooltip('link'),
      value: hasLink,
      onChange: () => openLinkDialogPanel(cmdState, textManager.current),
      disabled: isLinkButtonDisabled,
      onUnlink: () => execCommand('wixUnlink'),
      onExpand: () => ckUtils.expandSelectionToLinks(textManager.current),
      secondaryColors: true,
      smallSize: true,
    };
  };

  const getBulletsItems = () => {
    return cmdState.bidirtl ? TEXT_LIST_TYPES_RTL : TEXT_LIST_TYPES;
  };

  const getBulletsIconName = () => {
    return cmdState.bidirtl ? 'textListBulletsRight' : 'textListBullets';
  };

  const getTextAlignment = () =>
    Object.values(ALIGNMENT_TYPES).find(
      (alignmentTypeCandidate) => cmdState[alignmentTypeCandidate],
    );

  const getPlaceholderStyle = (): CSSProperties => ({
    direction: getTextDirection(compText),
    textAlign: getTextDirection(compText) === 'ltr' ? 'left' : 'right',
    fontFamily: 'Madefor, HelveticaNeue, sans-serif',
  });

  const getTextDirectionName = () =>
    cmdState.bidirtl ? 'textWriteDirectionRight' : 'textWriteDirectionLeft';

  const getLabelProps = () => ({
    text: translate(
      isTitle ? 'edit_section_title_name' : 'edit_section_paragraph_name',
    ),
  });

  return (
    <>
      {isConnectedToDataBinding ? (
        <TextWithRuntimeDataControl
          compRef={compRef}
          labelProps={getLabelProps()}
          onMainActionClick={onMainActionClick}
        />
      ) : (
        <div className={styles.textComponent}>
          <ControlHeader
            labelProps={getLabelProps()}
            infoIconProps={
              isTooltipVisible
                ? {
                    text: 'edit_section_text_tooltip',
                    linkText: 'edit_section_generic_element_tooltip_cta',
                    onLinkClick: onHelp,
                  }
                : undefined
            }
          />
          {cmdState ? (
            <div className={styles.buttonRow}>
              <ToggleButton
                className={styles.button}
                {...getBoldProps()}
                data-hook={TEXT_DATA_HOOKS.BOLD_BUTTON}
              />
              <ToggleButton
                className={styles.button}
                {...getItalicProps()}
                data-hook={TEXT_DATA_HOOKS.ITALIC_BUTTON}
              />
              <ToggleButton
                className={styles.button}
                {...getUnderlineProps()}
                data-hook={TEXT_DATA_HOOKS.UNDERLINE_BUTTON}
              />
              <TextLinkControl
                key="linkDropdownOption"
                // @ts-expect-error
                tooltipStyleType={SMALL}
                {...getLinkProps()}
                data-hook={TEXT_DATA_HOOKS.LINK_BUTTON}
              />
              <div className={styles.buttonsDivider} />
              <IconsDropDown
                // @ts-expect-error
                value={getTextAlignment()}
                onChange={(value: string) => {
                  execCommand(value);
                  reportDropDownSelection(value);
                }}
                items={ALIGNMENT_TYPES}
                tooltipValue={getTextTooltip('alignment')}
                tooltipStyleType={SMALL}
                className="align-dd"
                smallSize={true}
              />
              <IconsDropDown
                // @ts-expect-error-next-line
                value={textEditingUtils.getListType(cmdState)}
                onChange={(value: string) => {
                  execListCommand(value, cmdState, textManager.current);
                  reportDropDownSelection(value);
                }}
                items={getBulletsItems()}
                tooltipValue={getTextTooltip('bullets_and_numbering')}
                tooltipStyleType={SMALL}
                fixedIconName={getBulletsIconName()}
                className="bullets-dd"
                smallSize={true}
              />
              <IconButton
                onClick={() => {
                  execCommand(cmdState.bidirtl ? LTR : RTL);
                }}
                key="richTextWriteLTR"
                name={getTextDirectionName()}
                tooltipValue={getTextTooltip('text_direction')}
                tooltipStyleType={SMALL}
                smallSize={true}
                automationId={TEXT_DATA_HOOKS.TEXT_DIRECTION_BUTTON}
              />
            </div>
          ) : (
            <div
              className={styles.textPlaceholder}
              onClick={() => setIsSelected()}
              style={getPlaceholderStyle()}
              data-hook={TEXT_DATA_HOOKS.PLACEHOLDER}
            >
              {getInnerText(compText)}
            </div>
          )}

          {isSelected ? (
            <div className={styles.textEditingContent}>
              <div
                className={cx({ [styles.textEditorWrapper]: !!cmdState })}
                data-hook={TEXT_DATA_HOOKS.TEXT_EDITOR}
              >
                <TextEditorWrapper
                  compText={compText}
                  compRef={compRef}
                  onTextManagerReady={onTextManagerReady}
                  onCommandsStateChange={setCmdState}
                />
              </div>
              {cmdState && shouldShowAiTextGenerator ? (
                <AiTextCreatorEntryPoint
                  compRef={compRef}
                  onDataChanged={onDataChanged}
                />
              ) : null}
            </div>
          ) : null}

          {cmdState && isMoreOptionsVisible ? (
            <div className={styles.editText}>
              {translate('edit_section_rich_text_more_options_text')}
              <a className={styles.link} onClick={openEditTextPanelOnStage}>
                {translate('edit_section_rich_text_more_options_cta')}
              </a>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default connectWithScope(
  () => QuickEditScope,
  TextItem,
  mapStateToProps,
  mapDispatchToProps,
) as React.ComponentType<QuickEditControlOwnProps>;

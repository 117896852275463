// @ts-nocheck
import gfppDataUtils from '../../utils/gfppDataUtils';
import experiment from 'experiment';
import constants from '#packages/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const aiToolsPanel = 'compPanels.dynamicPanels.aiToolsPanel';

const isAiToolsPanelForImageEnabled = experiment.isOpen(
  'se_aiToolsPanelForImage',
);

function isAIToolsPanelOpen(editorAPI: EditorAPI) {
  return editorAPI.panelManager.isPanelOpened(aiToolsPanel);
}

function getMainActions(editorAPI) {
  const isAiImageExperimentOpen = experiment.isOpen('specs.mdst.AiTextToImage');
  const isPhotoStuidoExperimentOpen = experiment.isOpen('se_photoStudioGfpp');
  const isAiImageToolsIconEnabled =
    isAiImageExperimentOpen ||
    experiment.isOpen('specs.mdst.AiEditing') ||
    experiment.isOpen('specs.mdst.AiEraser');

  const changeImage = {
    label: 'gfpp_mainaction_image',
    actionType: ACTIONS.CHANGE_MEDIA,
    isApplied: gfppDataUtils.getDataDefinedFn('uri'),
    onClick(editorAPI, compRef, origin) {
      editorAPI.mediaServices.changeWPhoto(compRef, origin);
    },
  };

  const editImage = {
    label: 'gfpp_main_action_edit_image',
    automationId: 'gfpp-edit-image-aid',
    onClick(editorAPI, compRef, origin) {
      editorAPI.mediaServices.adjustWPhoto(compRef, origin);
    },
  };

  const aiImageGeneratorIcon = {
    label: undefined,
    icon: 'sparklesBold',
    tooltip: 'add_section_label_ai_image',
    className: 'gfpp-btn_ai-icon',
    automationId: 'gfpp-ai-image-creator-aid',
    onClick: (editorAPI, compRef) => {
      editorAPI.mediaServices.generateWPhoto(compRef, 'editor-image-gfpp');
    },
  };

  const aiImageToolsIcon = {
    label: undefined,
    icon: !isAIToolsPanelOpen(editorAPI)
      ? 'sparklesBoldAnimation'
      : 'sparklesBold',
    tooltip: 'add_section_label_ai_image',
    className: 'gfpp-btn_ai-icon_animation',
    automationId: 'gfpp-ai-image-tools-aid',
    onClick: gfppDataUtils.getTogglePanelFn('aiTools'),
    isSelected: gfppDataUtils.getPanelStateFn('aiTools'),
    customBI: {
      action_name: 'ai_tools_menu',
      icon: 'ai_tools_menu',
    },
  };

  const mainActions = [changeImage];

  if (isAiToolsPanelForImageEnabled && isAiImageToolsIconEnabled) {
    mainActions.unshift(aiImageToolsIcon);
  } else if (isAiImageExperimentOpen) {
    mainActions.unshift(aiImageGeneratorIcon);
  }

  if (isPhotoStuidoExperimentOpen) {
    mainActions.push(editImage);
  }

  return mainActions;
}

const wphotoGfppData = {
  translateAction: ACTIONS.SETTINGS,
  mainActions: getMainActions,
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.CROP,
    ACTIONS.FILTERS,
    ACTIONS.ANIMATION,
    ACTIONS.LINK,
    ACTIONS.HELP,
  ],
  mobileEnabledActions(editorAPI, compRef) {
    const actions = [
      ACTIONS.SETTINGS,
      ACTIONS.HIDE,
      ACTIONS.HELP,
      ACTIONS.ANIMATION,
    ];
    if (!editorAPI.components.is.descendantOfRepeaterItem(compRef)) {
      actions.push(ACTIONS.CROP);
    }
    return actions;
  },
  presetActions: {
    help: {
      helpId: '41a182e0-3b77-4731-af2f-38c6dbf6e982',
      mobileHelpId: 'da88cf75-37c5-4bcc-b1b6-9c0df84c29e0',
    },
    link: {
      onClick(editorAPI, compRef) {
        editorAPI.closeCompPanelIfExists();
        editorAPI.openLinkPanel({
          link: editorAPI.components.data.get(compRef).link,
          callback(newLinkData) {
            const { onClickBehavior } =
              editorAPI.components.properties.get(compRef);
            const isSwitchedToUrl =
              newLinkData && onClickBehavior === 'disabled';
            const isSwitchedToNonUrl =
              onClickBehavior === 'goToLink' && !newLinkData;

            if (isSwitchedToUrl || isSwitchedToNonUrl) {
              editorAPI.components.properties.update(compRef, {
                onClickBehavior: isSwitchedToUrl ? 'goToLink' : 'disabled',
              });
            }

            editorAPI.components.data.update(compRef, { link: newLinkData });
          },
        });
      },
    },
  },
};

export default wphotoGfppData;

import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import _ from 'lodash';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import { translate } from '#packages/i18n';
import * as panels from '#packages/panels';
import * as leftBar from '#packages/leftBar';
import * as baseUI from '#packages/baseUI';
import { cx, workspace } from '#packages/util';

import MobileElementSettings from './mobileElementSettings/mobileElementSettings';
import { getMobileElementsData } from './mobileElementsData';

const ACTION_BAR_ID = 'actionBar';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

function getMobileElementById(editorAPI: AnyFixMe, mobileElementId: AnyFixMe) {
  const elementsData = getMobileElementsData(editorAPI);

  return elementsData.find(function (elementData) {
    return elementData.id.toLowerCase() === mobileElementId;
  });
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<{ mobileElementId: string }>({
  displayName: 'mobileElementsPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    mobileElementId: PropTypes.string,
  },
  getInitialState() {
    return {
      selectedMobileElement: getMobileElementById(
        this.getEditorAPI(),
        this.props.mobileElementId,
      ),
    };
  },
  componentDidMount() {
    this.getEditorAPI().selection.deselectComponents();
  },
  isItemSelected(mobileElementItemId: AnyFixMe) {
    return (
      this.state.selectedMobileElement &&
      this.state.selectedMobileElement.id === mobileElementItemId
    );
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.mobileElementId !== this.props.mobileElementId) {
      const mobileElementToSelect = getMobileElementById(
        this.getEditorAPI(),
        nextProps.mobileElementId,
      );
      this.setState({
        selectedMobileElement: mobileElementToSelect,
      });
    }
  },
  selectMobileElement(selectedMobileElement: AnyFixMe) {
    this.getEditorAPI().bi.event(
      coreBi.events.mobileEditor.mobileTools.MOBILE_ELEMENT_CLICK,
      { element_name: selectedMobileElement.id },
    );

    if (_.isFunction(selectedMobileElement.onClick)) {
      selectedMobileElement.onClick();
    } else {
      this.setMobileElement(selectedMobileElement);
    }
  },
  setMobileElement(selectedMobileElement: AnyFixMe) {
    this.setState({ selectedMobileElement });
  },
  getMobileElementSettingsProps() {
    return Object.assign(this.state.selectedMobileElement, {
      onClick: () => this.setMobileElement(),
    });
  },
  getMobileElementProps(mobileElementItem: AnyFixMe) {
    return Object.assign(mobileElementItem, {
      callback: this.selectMobileElement.bind(this, mobileElementItem),
    });
  },
  getClass(mobileElementItem: AnyFixMe) {
    return _.defaults(mobileElementItem.classNames, {
      'item-in-list': true,
      selected: this.isItemSelected(mobileElementItem.id),
    });
  },
  isActionBarOpened() {
    return this.isItemSelected(ACTION_BAR_ID);
  },
  getPanelClass() {
    return cx({
      'mobile-elements-panel': true,
      'mobile-elements-panel-new-workspace': isNewWorkspace,
    });
  },
  renderSettingsPanel() {
    if (this.state.selectedMobileElement) {
      return (
        <MobileElementSettings {...this.getMobileElementSettingsProps()} />
      );
    }
  },
  render() {
    const editorAPI = this.getEditorAPI();
    const LeftPanelFrame = isNewWorkspace
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    return (
      <LeftPanelFrame
        ref="mobileElementsPanel"
        panelName={this.props.panelName}
        panelClass={this.getPanelClass()}
        label={translate('mobile_features_section_title_mobile_tools')}
        onContextMenu={(e: AnyFixMe) => {
          e.preventDefault();
        }}
        className={cx('mobile-elements-panel', {
          'action-bar-menu-open': this.isActionBarOpened(),
        })}
        sidePanel={isNewWorkspace ? this.renderSettingsPanel() : null}
      >
        {isNewWorkspace ? null : (
          <>
            {this.renderSettingsPanel()}
            <div className="panel-shadow" />
          </>
        )}
        <div className="items-wrapper">
          {getMobileElementsData(editorAPI).map((mobileElementItem) => (
            <div
              key={mobileElementItem.id}
              className={cx(this.getClass(mobileElementItem))}
            >
              <baseUI.listItemWithSymbol
                key="itemWithSymbol"
                {...this.getMobileElementProps(mobileElementItem)}
              />
            </div>
          ))}
        </div>
      </LeftPanelFrame>
    );
  },
});

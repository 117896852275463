// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { cx } from '#packages/util';

import * as UI from '#packages/baseUI';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  fixedIconName?: string;
}

export default class extends React.Component<Props> {
  static displayName = 'iconsDropDown';

  static propTypes = {
    fixedIconName: PropTypes.string,
  };

  render() {
    return (
      <UI.tooltip
        value={this.props.tooltipValue}
        disabled={!this.props.tooltipValue}
        styleType={this.props.tooltipStyleType}
      >
        <div
          style={{
            display: 'inline-block',
            verticalAlign: 'middle',
          }}
        >
          <UI.dropDown.select
            ref="dropdown"
            shouldTranslate={false}
            value={this.props.value}
            onChange={this.props.onChange}
            optionsWidth={56}
            template={this.props.fixedIconName ? this.getFixedIcon : undefined}
            className={cx({
              'icons-dd': true,
              [this.props.className]: true,
              small: this.props.smallSize,
            })}
          >
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.items, (item, itemIndex) => (
              <UI.dropDown.option
                value={item}
                key={itemIndex}
                className={`icons-dd-option ${item}`}
              >
                <UI.symbol name={itemIndex} />
              </UI.dropDown.option>
            ))}
          </UI.dropDown.select>
        </div>
      </UI.tooltip>
    );
  }

  getFixedIcon = () => {
    if (this.props.fixedIconName) {
      return React.createElement(UI.symbol, {
        name: this.props.fixedIconName,
      });
    }
  };

  isExpanded = () => {
    return this.refs.dropdown.isExpanded();
  };
}

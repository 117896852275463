import type { EntryPoint } from '#packages/apilib';
import { BaseResizeAndPushApiKey, EditorAPIKey } from '#packages/apis';
import { createBaseResizeAndPushApi } from './baseResizeAndPush';

export const BaseResizeAndPushEntrypoint: EntryPoint = {
  name: 'BaseResizeAndPush',
  declareAPIs: () => [BaseResizeAndPushApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(BaseResizeAndPushApiKey, () =>
      createBaseResizeAndPushApi(shell),
    );
  },
};

@charset "UTF-8";
:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._nested-drop-panel-list_17ri1_8 {
  min-width: 180px; }
  ._nested-drop-panel-list_17ri1_8 ._list-item_17ri1_10 {
    font-family: HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;
    font-family: var(--wbu-font-stack, HelveticaNeueW01-45Ligh, HelveticaNeueW02-45Ligh, HelveticaNeueW10-45Ligh, HelveticaNeueW31, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif);
    font-weight: var(--wbu-font-weight-regular);
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    color: #000624;
    padding: 6px 0;
    cursor: pointer; }
    ._nested-drop-panel-list_17ri1_8 ._list-item_17ri1_10:hover {
      background-color: #e7f0ff; }
    ._nested-drop-panel-list_17ri1_8 ._list-item_17ri1_10 ._list-item-title_17ri1_22 {
      padding-left: 18px; }
    ._nested-drop-panel-list_17ri1_8 ._list-item-separator_17ri1_24 {
      padding: 0; }
      ._nested-drop-panel-list_17ri1_8 ._list-item-separator_17ri1_24 ._separator_17ri1_26 {
        margin-left: 0;
        margin: 11px 18px;
        box-sizing: content-box;
        border: 0;
        height: 1px;
        background-color: #dfe5eb; }

._nestedListButton_17ri1_34 > ._text-button_17ri1_34 {
  padding: 0 12px;
  min-width: 100%;
  display: flex;
  justify-content: space-between; }

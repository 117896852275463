import React, { useEffect, type ComponentClass, type FC } from 'react';

import { frames } from '#packages/panels';
import { translate } from '#packages/i18n';
import { biLogger, hoc } from '#packages/util';

import { clickOnPanel } from '@wix/bi-logger-editor/v2';
import { Button, Checkbox, Text } from '@wix/wix-base-ui';

import styles from './PublishErrorPanel.scss';
import { PUBLISH_ERROR_PANEL_DEV, TRANSLATIONS, dataHooks } from './constants';
import type { PublishErrorPanelDevUIProps } from './PublishErrorPanelTypes';
import { mapStateToProps } from './PublishErrorPanelDevMapper';

const PublishErrorPanelDevUI: FC<PublishErrorPanelDevUIProps> = ({
  isIgnorePublishErrorValue,
  setIsIgnorePublishErrorValue,
  handleClose,
  openBuildLogButtonComponent,
  handlePublishAnyway,
  onHelp,
  sendBiOnOpen,
  deploymentId,
}) => {
  useEffect(() => {
    sendBiOnOpen(PUBLISH_ERROR_PANEL_DEV);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOpenBuildLogButtonComponent = (
    OpenBuildLogButtonComponent: FC<any> | ComponentClass<any, any>,
    deploymentId: string,
  ) => <OpenBuildLogButtonComponent deploymentId={deploymentId} />;

  useEffect(() => {
    biLogger.report(
      //@ts-expect-error
      clickOnPanel({
        panel_name: PUBLISH_ERROR_PANEL_DEV,
        action_name: isIgnorePublishErrorValue
          ? 'checbox click'
          : 'checbox unclick',
      }),
    );
  }, [isIgnorePublishErrorValue]);

  return (
    <frames.CustomPanelFrame
      title={translate(TRANSLATIONS.DEV.TITLE)}
      dataHook={dataHooks.publishErrorDevPanel}
      onOuterClick={() => handleClose('outer_click', PUBLISH_ERROR_PANEL_DEV)}
      onCloseButtonClick={() => handleClose('x', PUBLISH_ERROR_PANEL_DEV)}
      onHelpButtonClick={() => onHelp(PUBLISH_ERROR_PANEL_DEV)}
      panelName="publish-error-dev-panel"
      sideActions={
        <div className={styles.devUI}>
          <div className={styles.checkboxWrapper}>
            <Checkbox
              label={TRANSLATIONS.DEV.SKIP_STEP_CHECKBOX}
              labelAfterSymbol
              value={isIgnorePublishErrorValue}
              vAlign="CENTER"
              onChange={setIsIgnorePublishErrorValue}
            />
          </div>

          <div className={styles.buttons}>
            <Button
              dataHook={dataHooks.publishAnyway}
              className="btn-confirm-secondary btn-md"
              onClick={() =>
                handlePublishAnyway('publish_anyway', PUBLISH_ERROR_PANEL_DEV)
              }
            >
              {translate(TRANSLATIONS.DEV.SECONDARY_CTA)}
            </Button>

            <div
              data-hook={dataHooks.openBuildLog}
              onClick={() =>
                handleClose('openBuildLog', PUBLISH_ERROR_PANEL_DEV)
              }
            >
              {getOpenBuildLogButtonComponent(
                openBuildLogButtonComponent,
                deploymentId,
              )}
            </div>
          </div>
        </div>
      }
    >
      {
        <Text size="medium" skin="secondary" enableEllipsis={false}>
          {TRANSLATIONS.DEV.DESCRIPTION}
        </Text>
      }
    </frames.CustomPanelFrame>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(PublishErrorPanelDevUI);

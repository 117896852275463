import * as stateManagement from '#packages/stateManagement';
import { PanelResolveType } from '@wix/platform-editor-sdk';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import { deleteReplacerAppAndResetModal } from './pageReplaceService';

import type { AppData } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import experiment from 'experiment';

function openUninstallProgressBar(
  editorAPI: EditorAPI,
  appDefinitionIds: string[],
): void {
  const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionIds[0]);
  const panelProps = {
    title: translate('PLATFORM_Delete_Modal_Apps_Progress_Bar_Header', {
      App_Name: appData.appDefinitionName,
    }),
    progressMax: appDefinitionIds.length,
    taskDisplayName: translate('PLATFORM_Delete_Modal_Apps_Progress_Bar_Text1'),
    step: 0,
    isDone: false,
  };
  editorAPI.panelManager.openPanel(
    'panels.focusPanels.progressBarPanel',
    panelProps,
    false,
  );
}

function closeUninstallProgressBar(
  editorAPI: EditorAPI,
  success: boolean,
  appsToDelete: string[],
): void {
  const panelProps = success
    ? {
        step: appsToDelete.length,
        isDone: true,
        taskDisplayName: translate(
          'PLATFORM_Delete_Modal_Apps_Progress_Bar_Text3',
        ),
      }
    : {
        isDone: true,
      };
  editorAPI.panelManager.updatePanelProps(
    'panels.focusPanels.progressBarPanel',
    panelProps,
  );
}

const progressCallback = (
  editorAPI: EditorAPI,
  appsToDelete: string[],
  appDefinitionId: string,
) => {
  const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
  const panelProps = {
    step: appsToDelete.indexOf(appDefinitionId),
    title: translate('PLATFORM_Delete_Modal_Apps_Progress_Bar_Header', {
      App_Name: appData.appDefinitionName,
    }),
    taskDisplayName: translate('PLATFORM_Delete_Modal_Apps_Progress_Bar_Text1'),
  };
  editorAPI.panelManager.updatePanelProps(
    'panels.focusPanels.progressBarPanel',
    panelProps,
  );
};

const uninstallAppAsync = (
  editorAPI: EditorAPI,
  appsToDelete: string[],
): Promise<void> =>
  new Promise((resolve, reject) => {
    editorAPI.dsActions.platform.uninstall(
      appsToDelete,
      resolve,
      reject,
      (appDefId) => progressCallback(editorAPI, appsToDelete, appDefId),
    );
  });

const beforePagesRemoveHook = (editorAPI: AnyFixMe, pages: AnyFixMe) =>
  new Promise((resolve, reject) => {
    try {
      editorAPI.pages.beforePagesRemoveHook(pages, resolve);
    } catch (e) {
      reject(e);
    }
  });

const openUninstallConfirmationPanel = (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  options?: { leavePanelsOpen?: boolean },
): Promise<ConfirmationPanelObject> =>
  new Promise((resolve) => {
    if (
      stateManagement.platform.selectors.isAppWithReplacerPage(
        editorAPI,
        appDefinitionId,
      )
    ) {
      return deleteReplacerAppAndResetModal(editorAPI, appDefinitionId).then(
        resolve,
      );
    }
    if (experiment.isOpen('se_useNewUninstallFlows')) {
      editorAPI.panelManager.openPanel(
        'platformPanels.PlatformDeleteAppModal',
        {
          appDefinitionId,
          onClose: resolve,
          origin: { source: 0 },
        },
        { leavePanelsOpen: options?.leavePanelsOpen ?? true },
      );
    } else {
      const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
      const appName = appData?.appDefinitionName;
      editorAPI.panelManager.openPanel(
        'platformPanels.PlatformUninstallConfirmationPanel',
        { appName, appDefinitionId, onPanelClose: resolve },
        { leavePanelsOpen: options?.leavePanelsOpen ?? true },
      );
    }
  });

const getAppsPages = (editorAPI: EditorAPI, appsToDelete: string[]) => {
  const pagesData = editorAPI.dsRead.pages.getPagesData();
  return appsToDelete.reduce((result, appDefId) => {
    const data = editorAPI.platform.getAppDataByAppDefId(appDefId);
    return result.concat(
      pagesData
        .filter(
          (page) =>
            page.managingAppDefId === data.appDefinitionId ||
            page.appDefinitionId === data.appDefinitionId,
        )
        .map((pageData) => pageData.id),
    );
  }, []);
};

export interface UninstallAppOptions {
  origin?: string;
  onSuccess?: () => void;
  onFailure?: (error: Error) => void;
  leavePanelsOpen?: boolean;
}

interface ConfirmationPanelObject {
  status: string;
  appsToDelete?: string[];
}

const uninstallApp = async (
  editorAPI: EditorAPI,
  appData: AppData,
  shouldShowModal = true,
  { origin, onSuccess, onFailure, leavePanelsOpen }: UninstallAppOptions = {},
) => {
  const appDefinitionId = appData?.appDefinitionId;
  if (!appDefinitionId) {
    throw new Error('appData is undefined, must contain appDefinitionId');
  }
  const confirmationStatus: ConfirmationPanelObject = shouldShowModal
    ? await openUninstallConfirmationPanel(editorAPI, appDefinitionId, {
        leavePanelsOpen,
      })
    : { status: PanelResolveType.MAIN_ACTION };
  if (confirmationStatus.status === PanelResolveType.MAIN_ACTION) {
    const appsToDelete = (confirmationStatus.appsToDelete || []).concat(
      appDefinitionId,
    );
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.UNINSTALL_APP,
    );
    openUninstallProgressBar(editorAPI, appsToDelete);
    const pages = getAppsPages(editorAPI, appsToDelete);
    editorAPI.history.add('before remove app');
    try {
      await beforePagesRemoveHook(editorAPI, pages);
      await uninstallAppAsync(editorAPI, appsToDelete);
      await stateManagement.platform.actions.saveInBackground(editorAPI, true);
      closeUninstallProgressBar(editorAPI, true, appsToDelete);

      if (onSuccess) {
        onSuccess();
      }

      editorAPI.bi.event(util.bi.events.COMPONENT_REMOVED, {
        removal_method: origin,
        app_id: appDefinitionId,
        app_site_id: appData.instanceId,
      });
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.UNINSTALL_APP,
      );
    } catch (e) {
      closeUninstallProgressBar(editorAPI, false, appsToDelete);
      editorAPI.history.undo();
      ErrorReporter.captureException(e, {
        tags: { appInstallFailed: appDefinitionId },
        extra: { appData },
      });

      if (onFailure) {
        onFailure(e as Error);
      }

      throw e;
    }
  } else {
    ErrorReporter.captureException(
      new Error(`Application remove canceled by user.`),
      {
        tags: { appInstallFailed: appDefinitionId },
        extra: { appData },
      },
    );
    throw new Error('Application remove canceled.');
  }
};

export { uninstallApp };

import * as util from '#packages/util';
import experiment from 'experiment';
import type { EditorAPI } from '#packages/editorAPI';
import type { Scope } from './switchLayoutEntryPoint';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { BiData } from './switchLayoutStore';
import type { LayoutWithMetadata } from './switchLayoutStore';
import { BasePublicApi } from '#packages/apilib';
import {
  getSectionTopicIfExists,
  mapComponentTypes,
  getEmptyTypeMapping,
  fetchSuggestionsPaas,
  isLayoutSupportedForPaaS,
} from './switchLayoutPAASUtil';
import {
  SWITCH_LAYOUT_PANEL_NAME,
  SELECT_SECTION_BI_ORIGIN,
  type OriginalCompMappingData,
  ENTER_SWITCH_LAYOUT,
  SWITCH_LAYOUT_PANEL_WIDTH,
  SWITCH_LAYOUT_ORIGIN,
  SWITCH_LAYOUT_PANEL_TYPE,
} from './consts';
import {
  WORKSPACE_RIGHT_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
} from '#packages/constants';
import { fedopsLogger } from '#packages/util';
import { handleRenderForComparisonTool } from './pocTools/switchLayoutComparisonToolUtils';
import * as coreBi from '#packages/coreBi';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { CeType } from '#packages/presetApi';
import type { EditorInteractionName } from 'types/fedops';
import {
  fetchSuggestionsBruteForce,
  LayoutParser,
  isLayoutSupportedForBruteForce,
  getBruteForcePresets,
} from './switchLayoutBruteForceUtil';
import { switchLayoutUnsupportedLayoutOpen } from '@wix/bi-logger-editor/v2';
import { translate } from '#packages/i18n';

export interface SwitchLayoutConfig {
  sectionRef?: CompRef;
  origin: string;
}

export const isOriginalCompMappingDataEmpty = (
  originalCompMapping: OriginalCompMappingData,
) => {
  return !!(
    originalCompMapping.data.unsupported &&
    Object.entries(originalCompMapping).length < 2
  );
};

const getBiSuggestedResultStructure = (
  SuggestedResults: any[],
  familySource: string,
) => {
  return SuggestedResults?.map((result, index) => {
    return {
      id: result.id,
      familySource,
      index,
    };
  });
};

function openWorkspaceRightPanel(
  store: any,
  scope: Scope,
  groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
) {
  const { workspaceRightPanelApi, editorAPI } = scope;

  workspaceRightPanelApi.open(SWITCH_LAYOUT_ORIGIN, {
    panelName: WORKSPACE_RIGHT_PANEL_NAMES.SWITCH_LAYOUT,
    panelWidth: SWITCH_LAYOUT_PANEL_WIDTH,
    title: translate('section_designs_panel_title'),
    helpId: 'a2e9c897-a990-49b1-bddc-8a584d3bb6c6',
    onHelp: () => {
      editorAPI.bi.event(coreBi.events.switchLayout.switchLayoutHelpClick, {
        ...store.getBiData(),
        action: 'click',
        panel_name: SWITCH_LAYOUT_PANEL_NAME,
      });
    },
    onClose: (origin: string) => {
      const biData = store.getBiData();
      util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.EDITOR);
      editorAPI.bi.event(coreBi.events.switchLayout.switchLayoutPanelClosed, {
        ...biData,
        origin,
        // TODO fix panel_open_time when going from quick edit tab to switch layout and back,
        // TODO panel_open_time is not initialized, since bi data is not set
        // TODO this is NaN
        time_spent: new Date().getTime() - biData.panel_open_time?.getTime?.(),
      });

      const selectedLayoutId = store.getSelectedLayoutId();
      const isOriginalSelected =
        selectedLayoutId === store.getOriginalLayout().id;
      editorAPI.bi.event(coreBi.events.switchLayout.switchLayoutLayoutApplied, {
        ...biData,
        layout_type: isOriginalSelected ? 'original_layout' : 'optional',
        components_amount: store.getOriginalLayout().typeMapping.count,
        layout_id: selectedLayoutId,
        layout_index: store
          .getLayoutOptions()
          .findIndex(
            (layout: LayoutWithMetadata) => layout.id === selectedLayoutId,
          ),
      });
      store.setIsSwitchLayoutPanelOpen(false);
      ErrorReporter.setTags({
        isInSwitchLayoutMode: false,
      });
    },
    groupType,
  });
}

function switchInitialFedOps(
  store: any,
  editorAPI: EditorAPI,
  sectionRef: CompRef,
  sectionsAPI: any,
  origin: string,
) {
  const openingPanel = origin !== SELECT_SECTION_BI_ORIGIN;
  let fedopsEvent: EditorInteractionName;
  if (!openingPanel) {
    fedopsEvent = fedopsLogger.SWITCH_LAYOUT.SELECT_NEW_SECTION;
  } else {
    fedopsEvent = store.getIsFirstTime()
      ? fedopsLogger.SWITCH_LAYOUT.OPEN_PANEL_FIRST_TIME
      : fedopsLogger.SWITCH_LAYOUT.OPEN_PANEL;
    store.setIsFirstTime(false);
  }
  fedopsLogger.interactionStarted(fedopsEvent);

  editorAPI.selection.selectComponentByCompRef(sectionRef);
  editorAPI.history.add(ENTER_SWITCH_LAYOUT);

  ErrorReporter.setTags({
    isInSwitchLayoutMode: true,
  });
  store.setIsSwitchLayoutPanelOpen(true);
  store.setLayoutOptions([]);
  store.setIsLoading(true);

  const biData = resetBiData(store, origin, sectionRef, openingPanel);
  if (!openingPanel) {
    editorAPI.bi.event(
      coreBi.events.switchLayout.switchLayoutSwitchBetweenSections,
      biData,
    );
  } else {
    editorAPI.bi.event(
      coreBi.events.switchLayout.switchLayoutPanelOpened,
      biData,
    );
  }

  return { biData, fedopsEvent };
}

export async function switchLayout(
  scope: Scope,
  { sectionRef, origin }: SwitchLayoutConfig,
  groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
) {
  const {
    store,
    editorAPI,
    sectionsAPI,
    quickEditAPI,
    textClassificationApi,
    listClassificationApi,
  } = scope;
  sectionRef = sectionRef || sectionsAPI.getFocusedSection();
  if (editorAPI.selection.getSelectedComponentId() !== sectionRef.id) {
    editorAPI.selection.selectComponentByCompRef(sectionRef);
  }

  // eslint-disable-next-line prefer-const
  let { biData, fedopsEvent } = switchInitialFedOps(
    store,
    editorAPI,
    sectionRef,
    sectionsAPI,
    origin,
  );
  groupType =
    !groupType && quickEditAPI.isQuickEditAvailable()
      ? WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION
      : groupType;
  openWorkspaceRightPanel(store, scope, groupType);

  const switchLayoutExperiments = {
    isSwitchLayoutMaxPaasPresetsOpen: experiment.isOpen(
      'se_switchLayoutMaxPaasPresets',
    ),
    isFilterListPresetsOpen: experiment.isOpen('se_filterListPresets'),
  };

  // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
  const serialized = editorAPI.components.serialize(
    sectionRef,
    undefined, // dataItemPointer
    undefined, // ignoreChildren
    true, // maintainIdentifiers
  );

  const typeMapping = mapComponentTypes(
    editorAPI,
    serialized,
    getEmptyTypeMapping(),
  );

  typeMapping.textClassificationData = {};
  typeMapping.listData = {};

  if (!typeMapping) {
    return [];
  }

  const originalLayout = {
    compRef: sectionRef,
    snapShot: editorAPI.documentServices.snapshot.getSnapshot(
      editorAPI.components.get.byId(sectionRef.id),
    ),
    id: sectionRef.id,
    typeMapping,
  };

  store.setOriginalLayout(originalLayout);
  store.setSelectedLayoutId(originalLayout.id);
  store.setSelectedLayoutHistory(originalLayout.id);

  let presets = [];
  let engine = '';
  const topicDisplayName =
    getSectionTopicIfExists(originalLayout.compRef, editorAPI) || CeType.About;
  const sectionName = editorAPI.sections.getName(originalLayout.compRef);
  const biJsonString = JSON.stringify(
    Object.values(store.getOriginalLayout().typeMapping.bi),
  );
  const unknownComponentTypesString = getUnknownComponentStringForBi(
    originalLayout.typeMapping.data.other?.map((item: any) => item.serialized),
  );
  const isLayoutSupportedPAAS = await isLayoutSupportedForPaaS(
    originalLayout.typeMapping,
    textClassificationApi,
    listClassificationApi,
    editorAPI.pages.getCurrentPageId(),
    originalLayout.compRef,
    editorAPI,
    switchLayoutExperiments,
    store.getBiData(),
  );
  let switchLayoutSendGetLayoutIdeasStarted;
  if (isLayoutSupportedPAAS) {
    engine = 'paas';
    fedopsLogger.interactionStarted(fedopsLogger.SWITCH_LAYOUT.GET_SUGGESTIONS);
    switchLayoutSendGetLayoutIdeasStarted = performance.now();
    editorAPI.bi.event(
      coreBi.events.switchLayout.switchLayoutSendGetLayoutIdeas,
      {
        ...biData,
        components_amount: originalLayout.typeMapping.count,
        inputJson: biJsonString,
        section_name_technical: topicDisplayName,
        section_name_personal: sectionName,
        is_list: originalLayout.typeMapping.listData.isList,
        black_list: unknownComponentTypesString,
      },
    );

    try {
      presets = await editorAPI.store.dispatch(
        fetchSuggestionsPaas(
          originalLayout.typeMapping,
          switchLayoutExperiments,
          topicDisplayName,
        ),
      );
    } catch (err) {
      ErrorReporter.captureException(err, {
        tags: { switchLayoutFlow: true, fetchSuggestionsPassFlow: true },
      });
    }
  }

  const callBruteForceforSuggestions =
    (!presets || !presets.length) && isBruteForceEnabled(scope);

  if (callBruteForceforSuggestions) {
    engine = 'bruteforce';

    if (!isLayoutSupportedPAAS) {
      // log start interaction because we didnt log it before
      fedopsLogger.interactionStarted(
        fedopsLogger.SWITCH_LAYOUT.GET_SUGGESTIONS,
      );
      switchLayoutSendGetLayoutIdeasStarted = performance.now();
      editorAPI.bi.event(
        coreBi.events.switchLayout.switchLayoutSendGetLayoutIdeas,
        {
          ...biData,
          components_amount: originalLayout.typeMapping.count,
          inputJson: biJsonString,
          section_name_technical: topicDisplayName,
          section_name_personal: sectionName,
          is_list: originalLayout.typeMapping.listData.isList,
          black_list: unknownComponentTypesString,
        },
      );
    }

    const bruteResults = await getBruteForceLayouts(
      scope,
      sectionRef,
      serialized,
    );

    if (bruteResults.presets) {
      presets = bruteResults.presets;
    }
  }

  if (presets) {
    store.setLayoutOptions(presets);
  }

  // end interaction only of started (paas supported and\or bruteforce enabled)
  if (switchLayoutSendGetLayoutIdeasStarted) {
    const biSuggestedResultSting = JSON.stringify(
      getBiSuggestedResultStructure(presets, 'api'),
    );
    const switchLayoutSendGetLayoutIdeasEnded = performance.now();
    if (!presets || !presets.length) {
      util.biLogger.report(
        switchLayoutUnsupportedLayoutOpen({
          ...biData,
          components_amount: originalLayout.typeMapping.count,
          inputJson: biJsonString,
          section_name_technical: topicDisplayName,
          section_name_personal: sectionName,
          component_type: editorAPI.components.getType(sectionRef),
          is_list: originalLayout.typeMapping.listData.isList,
          panel_type: SWITCH_LAYOUT_PANEL_TYPE,
          black_list: unknownComponentTypesString,
        }),
      );
    } else {
      editorAPI.bi.event(
        coreBi.events.switchLayout.switchLayoutRecievedLayoutIdeas,
        {
          ...biData,
          resultCount: presets ? presets.length : 0,
          components_amount: originalLayout.typeMapping.count,
          result: biSuggestedResultSting,
          section_name_technical: topicDisplayName,
          section_name_personal: sectionName,
          is_list: originalLayout.typeMapping.listData.isList,
          duration: Math.round(
            switchLayoutSendGetLayoutIdeasEnded -
              switchLayoutSendGetLayoutIdeasStarted,
          ),
          engine_type: engine,
          black_list: unknownComponentTypesString,
        },
      );
    }
    fedopsLogger.interactionEnded(fedopsLogger.SWITCH_LAYOUT.GET_SUGGESTIONS);
  } else {
    editorAPI.bi.event(
      coreBi.events.switchLayout.switchLayoutSectionNotSupported,
      {
        ...biData,
        components_amount: originalLayout.typeMapping.count,
        inputJson: biJsonString,
        section_name_technical: topicDisplayName,
        section_name_personal: sectionName,
        component_type: editorAPI.components.getType(sectionRef),
        is_list: originalLayout.typeMapping.listData.isList,
        panel_type: SWITCH_LAYOUT_PANEL_TYPE,
        black_list: unknownComponentTypesString,
      },
    );
  }

  store.setIsLoading(false);
  fedopsLogger.interactionEnded(fedopsEvent);
  util.keyboardShortcuts.setContext(
    util.keyboardShortcuts.CONTEXTS.SWITCH_LAYOUT_PANEL,
  );
  return presets;
}

function getUnknownComponentStringForBi(unknownComponents: CompStructure[]) {
  const unknownMap: any[] = [];
  unknownComponents?.forEach((item) => {
    const existing = unknownMap.find(
      (type) => type.componentType === item.componentType,
    );
    if (!existing) {
      unknownMap.push({
        count: 1,
        componentType: item.componentType,
        compIds: [item.id],
      });
    } else {
      existing.count++;
      existing.compIds.push(item.id);
    }
  });
  return JSON.stringify(unknownMap);
}

async function getParsedLayout(
  scope: Scope,
  sectionRef: CompRef,
  originalSerialized: CompStructure,
) {
  let textClassifications;
  try {
    textClassifications =
      await scope.textClassificationApi.getTextClassification(
        scope.editorAPI.pages.getCurrentPageId(),
        sectionRef,
      );
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: { switchLayoutFlow: true, fetchSuggestionsBruteForceFlow: true },
    });
  }

  const layout = LayoutParser.Parse(
    originalSerialized,
    scope.editorAPI,
    textClassifications,
  );
  return layout;
}

async function getBruteForceLayouts(
  scope: Scope,
  sectionRef: CompRef,
  originalSerialized: CompStructure,
) {
  let presets;
  let bruteForceDisqualifications;

  const layout = await getParsedLayout(scope, sectionRef, originalSerialized);
  const isLayoutSupported = await isLayoutSupportedForBruteForce(layout);
  if (isLayoutSupported) {
    try {
      const { disqualifications, brutePresets } = await getBruteForcePresets(
        scope,
        originalSerialized,
        layout,
      );
      bruteForceDisqualifications = disqualifications;
      presets = brutePresets;
    } catch (err) {
      ErrorReporter.captureException(err, {
        tags: {
          switchLayoutFlow: true,
          fetchSuggestionsBruteForceFlow: true,
        },
      });
    }
  }
  return {
    bruteForceDisqualifications,
    presets,
    mapping: layout.componentsCategories,
  };
}

async function switchLayoutForComparison(
  scope: Scope,
  { sectionRef }: SwitchLayoutConfig,
  useFilter: boolean,
) {
  if (!isComparisonToolEnabled(scope)) {
    return null;
  }

  const { editorAPI } = scope;
  editorAPI.selection.selectComponentByCompRef(sectionRef);
  ErrorReporter.setTags({
    isInSwitchLayoutMode: true,
  });

  let presets: any[] = [];
  let bruteForceDisqualifications = {};
  // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
  const originalSerialized = editorAPI.components.serialize(
    sectionRef,
    undefined, // dataItemPointer
    undefined, // ignoreChildren
    true, // maintainIdentifiers
  );

  try {
    const textClassifications =
      await scope.textClassificationApi.getTextClassification(
        editorAPI.pages.getCurrentPageId(),
        sectionRef,
      );
    const layout = LayoutParser.Parse(
      originalSerialized,
      scope.editorAPI,
      textClassifications,
    );
    const isLayoutSupported = await isLayoutSupportedForBruteForce(layout);
    if (isLayoutSupported) {
      const { suggestions, disqualifications, normalizedSuggestionsAndTitles } =
        await fetchSuggestionsBruteForce(
          editorAPI,
          useFilter,
          originalSerialized,
          layout,
        );
      bruteForceDisqualifications = disqualifications;
      await handleRenderForComparisonTool(
        normalizedSuggestionsAndTitles,
        originalSerialized,
        editorAPI,
      );

      presets = suggestions;
    }
  } catch (err) {
    ErrorReporter.captureException(err, {
      tags: {
        switchLayoutFlow: true,
        fetchSuggestionsBruteForceFlow: true,
      },
    });
  }

  const resultsCount = presets.length;
  return {
    bruteForceDisqualifications,
    resultsCount,
    editorSectionId: sectionRef.id,
  };
}

export function reloadResults(
  scope: Scope,
  { sectionRef, origin }: SwitchLayoutConfig,
) {
  if (sectionRef.id === scope.store.getSelectedLayoutId()) {
    return;
  }
  if (
    isSwitchLayoutAvailable(scope) &&
    scope.store.getIsSwitchLayoutPanelOpen() &&
    !scope.store.getIsSelecting()
  ) {
    const biData = scope.store.getBiData();
    const selectedLayoutId = scope.store.getSelectedLayoutId();
    const originalLayout = scope.store.getOriginalLayout();
    const isOriginalSelected = selectedLayoutId === originalLayout.id;
    const shouldSendBI =
      !isOriginalSelected || origin !== SELECT_SECTION_BI_ORIGIN;
    if (shouldSendBI) {
      scope.editorAPI.bi.event(
        coreBi.events.switchLayout.switchLayoutLayoutApplied,
        {
          ...biData,
          layout_type: isOriginalSelected ? 'original_layout' : 'optional',
          components_amount: originalLayout.typeMapping.count,
          layout_id: selectedLayoutId,
          layout_index: scope.store
            .getLayoutOptions()
            .findIndex((layout) => layout.id === selectedLayoutId),
        },
      );
    }
    switchLayout(scope, { sectionRef, origin });
  }
}

export async function clearLayoutOptions({ store }: Scope) {
  store.setLayoutOptions([]);
}

export function isInSwitchLayoutMode({ store }: Scope): boolean {
  return store.getIsSwitchLayoutPanelOpen();
}

function isSwitchLayoutLoading(scope: Scope) {
  const { store } = scope;
  return (
    isSwitchLayoutAvailable(scope) &&
    store.getIsSwitchLayoutPanelOpen() &&
    store.getIsLoading()
  );
}

function isSwitchLayoutSelecting(scope: Scope) {
  const { store } = scope;
  return (
    isSwitchLayoutAvailable(scope) &&
    store.getIsSwitchLayoutPanelOpen() &&
    store.getIsSelecting()
  );
}

export function isSwitchLayoutAvailable(scope: Scope) {
  const isSectionsEnabled = util.sections.isSectionsEnabled();
  const isMultilingualEnabled =
    scope.editorAPI.language.multilingual.isEnabled();

  return (
    (isSectionsEnabled && !isMultilingualEnabled) ||
    experiment.isOpen('se_internalSwitchLayout')
  );
}

export function isSelectedComponentSupported({ sectionsAPI }: Scope): boolean {
  return !!sectionsAPI.getFocusedSection();
}

export function isComparisonToolEnabled(scope: Scope) {
  return (
    experiment.isOpen('se_switchLayoutComparisonTool') &&
    isSwitchLayoutAvailable(scope)
  );
}

export function isBruteForceEnabled(scope: Scope) {
  return (
    experiment.isOpen('se_switchLayoutBruteForce') &&
    isSwitchLayoutAvailable(scope)
  );
}

function getComponentForSwitchLayout({ store }: Scope) {
  return store.getOriginalLayout();
}

function resetBiData(
  store: any,
  origin: string,
  compRef: CompRef,
  createNewParentCorrelationId: boolean,
) {
  const biData: BiData = {
    panel_correlation_id: createNewParentCorrelationId
      ? util.guidUtils.getGUID()
      : store.getBiData().panel_correlation_id,
    section_correlation_id: util.guidUtils.getGUID(),
    origin,
    component_id: compRef.id,
    panel_name: SWITCH_LAYOUT_PANEL_NAME,
    panel_open_time: new Date(),
    is_designer: experiment.isOpen('se_SwitchLayoutDesignerPresets'),
  };
  store.setBiData(biData);
  return store.getBiData();
}

function preventUndo(scope: Scope) {
  const snapshotLabel =
    scope.editorAPI.documentServices.history.getUndoLastSnapshotLabel();
  return isInSwitchLayoutMode(scope) && snapshotLabel === ENTER_SWITCH_LAYOUT;
}

function shouldRedo({ store }: Scope) {
  const selectedLayoutHistory = store.getSelectedLayoutHistory();
  const { selectionHistoryPoints, selectionHistoryIndex } =
    selectedLayoutHistory;
  return !(selectionHistoryIndex === selectionHistoryPoints.length - 1);
}

function onUndo({ store }: Scope) {
  const selectedLayoutHistory = store.getSelectedLayoutHistory();
  const { selectionHistoryPoints, selectionHistoryIndex } =
    selectedLayoutHistory;
  const historyId = selectionHistoryPoints[selectionHistoryIndex - 1];
  store.setSelectedLayoutId(historyId);
  store.setSelectedLayoutHistory(historyId, selectionHistoryIndex - 1);
}

function onRedo(scope: Scope) {
  if (shouldRedo(scope)) {
    const selectedLayoutHistory = scope.store.getSelectedLayoutHistory();
    const { selectionHistoryPoints, selectionHistoryIndex } =
      selectedLayoutHistory;
    const historyId = selectionHistoryPoints[selectionHistoryIndex + 1];
    scope.store.setSelectedLayoutId(historyId);
    scope.store.setSelectedLayoutHistory(historyId, selectionHistoryIndex + 1);
  }
}

export async function scanSwitchLayoutPreset(
  { workspaceRightPanelApi, sectionsAPI, editorAPI }: Scope,
  { sectionRef }: AnyFixMe,
) {
  sectionRef = sectionRef || sectionsAPI.getFocusedSection();
  editorAPI.selection.selectComponentByCompRef(sectionRef);
  workspaceRightPanelApi.open('sectionScan', {
    panelName: WORKSPACE_RIGHT_PANEL_NAMES.SCAN_SWITCH_LAYOUT_PRESET,
    panelWidth: 400,
    title: translate('section scan'),
    helpId: 'a2e9c897-a990-49b1-bddc-8a584d3bb6c6',
    onClose: () => {},
    onHelp: () => {},
    enterZoomMode: false,
  });
}

export class SwitchLayoutApi extends BasePublicApi<Scope> {
  openSwitchLayoutPanel = this.bindScope(switchLayout);
  switchLayoutForComparison = this.bindScope(switchLayoutForComparison);
  reloadResults = this.bindScope(reloadResults);
  clearLayoutOptions = this.bindScope(clearLayoutOptions);
  isInSwitchLayoutMode = this.bindScope(isInSwitchLayoutMode);
  isSwitchLayoutLoading = this.bindScope(isSwitchLayoutLoading);
  isSwitchLayoutSelecting = this.bindScope(isSwitchLayoutSelecting);
  isSwitchLayoutAvailable = this.bindScope(isSwitchLayoutAvailable);
  isComparisonToolEnabled = this.bindScope(isComparisonToolEnabled);
  isBruteForceEnabled = this.bindScope(isBruteForceEnabled);
  getComponentForSwitchLayout = this.bindScope(getComponentForSwitchLayout);
  onUndo = this.bindScope(onUndo);
  onRedo = this.bindScope(onRedo);
  preventUndo = this.bindScope(preventUndo);
  scanSwitchLayoutPreset = this.bindScope(scanSwitchLayoutPreset);
}

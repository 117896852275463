export {
  addForm as addWixForm,
  addFormToContainer as addWixFormToContainer,
} from './categories/contact/wixForms/utils';

export { addAppointment as addAppointmentField } from './categories/developer/appointmentField/utils';

export { default as databaseWelcomePanelView } from './categories/database/databaseWelcome/databaseWelcome';

////////////

import * as util from '#packages/util';
const { deprecateOldAddPanel } = util.addPanel;

import * as addPanelDefinitions from '#packages/addPanelDefinitions';
import {
  applyOverrideForCategories as _applyOverrideForCategories,
  createCategoriesSectionsAccessor,
} from './categories/addData';
import {
  getDesignPanelTitleByComp as _getDesignPanelTitleByComp,
  getSectionsByComponentType as _getSectionsByComponentType,
} from './designData/designData';
import _manager from './utils/dataRegistrationManager';

const accessor = createCategoriesSectionsAccessor(
  addPanelDefinitions.categoriesDefinition,
);

export const getCategories = deprecateOldAddPanel(
  accessor.getCategories,
  'getCategories',
);
export const getCategory = deprecateOldAddPanel(
  accessor.getCategory,
  'getCategory',
);
export const applyOverrideForCategories = deprecateOldAddPanel(
  _applyOverrideForCategories,
  'applyOverrideForCategories',
);
export const getSectionsByComponentType = deprecateOldAddPanel(
  _getSectionsByComponentType,
  'getSectionsByComponentType',
);
export const manager = deprecateOldAddPanel(_manager, 'manager');

export const getDesignPanelTitleByComp = _getDesignPanelTitleByComp;

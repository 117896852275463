// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import { translate } from '#packages/i18n';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.IMAGE,
  hide: false,
  title: 'image_section_image_title_my_uploads',
  subNavigationTitle: 'image_section_image_title_my_uploads',
  presetTitle: 'image_section_image_title_my_uploads',
  tooltipTitle: 'image_section_image_title_my_uploads',
  subNavigationHide: false,
  showSectionHeader: true,
  props: {
    items: [
      {
        title: 'add_section_label_imageuploader',
        openSource: 'add_panel_my_image_uploads',
        biItemName: 'my_image',
        desc: 'add_section_info_title_imageuploader',
        image: 'addPanelData/sections/imageSection/imageUploader.png',
        path: 'private',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_uploads_add'),
        },
      },
    ],
  },
  help: {
    hide: false,
    text: 'image_section_image_my_uploads_info_text',
  },
};

import * as helpIds from '#packages/helpIds';
import type { SectionCategoryDefinition } from '../types';

const SECTION_SCALE_FACTOR = 0.17;

const AUTOMATION_IDS = {
  SAVED_SECTION_EMPTY_STATE: 'saved-section-empty-state',
};

const CREATE_WITH_AI_CATEGORY_ID = 'CREATE_WITH_AI_CATEGORY_ID';

const CreateWithAICategory: SectionCategoryDefinition = {
  id: CREATE_WITH_AI_CATEGORY_ID,
  order: -2,
  isDivider: false,
  name: 'add_section_ai_section_generate_button',
  tooltipTitle: 'create sectiction with AI tooltip',
  tooltipDescription: 'create sectiction with AI description',
  title: 'ai_creator',
  longTitle: 'create sectiction with AI tooltip title',
  linkText: 'create sectiction with AI tooltip cta',
  helpId: undefined,
  icon: 'sparklesSmall',
};

const SavedSectionCategoryDivider: SectionCategoryDefinition = {
  id: 'SAVED_SECTION_CATEGORY_DIVIDER_ID',
  order: 99,
  isDivider: true,
  name: undefined,
  title: undefined,
  longTitle: undefined,
  tooltipTitle: undefined,
  tooltipDescription: undefined,
};

const SavedSectionCategory: SectionCategoryDefinition = {
  id: 'SAVED_SECTION_CATEGORY_ID',
  order: 100,
  isDivider: false,
  name: 'add_section_saved_sections_category_name',
  tooltipTitle: 'add_section_saved_sections_category_tooltip_title',
  tooltipDescription: 'add_section_saved_sections_category_tooltip',
  title: 'Saved Sections',
  longTitle: 'add_section_saved_sections_category_title',
  linkText: 'add_section_saved_sections_category_tooltip_cta',
  helpId: helpIds.addSectionPanel.SAVED_SECTIONS,
};

export {
  AUTOMATION_IDS,
  CREATE_WITH_AI_CATEGORY_ID,
  CreateWithAICategory,
  SavedSectionCategory,
  SavedSectionCategoryDivider,
  SECTION_SCALE_FACTOR,
};

import { ComponentsApiKey, ScrollApiKey, EditorAPIKey } from '#packages/apis';
import { selection } from '#packages/stateManagement';

import type { Shell } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';

export function createSpotlightStageApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const componentsApi = shell.getAPI(ComponentsApiKey);
  const scrollApi = shell.getAPI(ScrollApiKey);
  const { store } = editorAPI;

  function getContainer(): CompRef {
    const focusedContainer = selection.selectors.getFocusedContainer(
      store.getState(),
    );

    return componentsApi.is.spotlightStageContainer(focusedContainer)
      ? focusedContainer
      : null;
  }

  function scrollByDelta(yDelta: number) {
    editorAPI.components.scroll.by(getContainer(), 0, yDelta, () => {
      // Trigger re-render of hoverBox/editBox and so on
      scrollApi.scrollTo({ scrollTop: 0 });
    });
  }

  return {
    getContainer,
    scrollByDelta,
  };
}

/*eslint max-lines: [2, { "max": 5000, "skipComments": true, "skipBlankLines": true}]*/
import { t } from '#packages/i18n';
import {
  BANDSINTOWN,
  INSTAGRAM_FEED,
  WIX_BLOG,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_FORMS,
  WIX_GROUPS,
  WIX_MUSIC,
  WIX_PRO_GALLERY,
  WIX_RESERVATIONS,
  WIX_STORES,
  WIX_VIDEO,
} from '@wix/app-definition-ids';
import { CeType } from '#packages/presetApi';
import {
  APPS_PRIORITY_MAP,
  BLOG_AS_HOMEPAGE_STRUCTURE_IDS,
  POSITION,
  PRO_GALLERY_STRUCTURE_IDS,
} from './siteCreationConstants';

import type { CompStructure } from '@wix/document-services-types';
import type { CeTypeToSection } from './siteCreationUtils';

export interface PositionCfg {
  appCeType: CeType;
  positionCeType: CeType;
  position: POSITION;
  removeSection?: CeType;
  blockingApps?: string[];
  blockingStructureIds?: string[];
}

interface AppIdToPositionCfg {
  [appId: string]: PositionCfg;
}

export const sortWidgetsByPriority = (
  installedAppDefIds: string[],
): string[] => {
  return [...installedAppDefIds].sort((a, b) => {
    const positionCfg = getWidgetPositionCfg(a);
    if (positionCfg?.position === POSITION.BEFORE) {
      return APPS_PRIORITY_MAP[a] - APPS_PRIORITY_MAP[b];
    }
    return APPS_PRIORITY_MAP[b] - APPS_PRIORITY_MAP[a];
  });
};

function getAnchorName(ceType: CeType): string {
  return t(`Section_Name_${ceType}`);
}

export const appDefIdToSectionStructureMap: {
  [appDefId: string]: CompStructure | AnyFixMe;
} = {
  [WIX_BLOG]: {
    type: 'Container',
    metaData: {
      sig: '5cl-832',
      pageId: 'o23tu',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '5cl-831',
          pageId: 'o23tu',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 1166,
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2lvf7',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '5cl-301',
            pageId: 'o23tu',
          },
          applicationId: '3741',
          appDefinitionId: '14bcded7-0066-7c35-14d7-466cb3f09103',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '5cl-824',
              pageId: 'o23tu',
            },
            content:
              '{"presetId":"postListLargeEditorial","__settings-event__":{"ts":1641392709766,"payload":{"type":"pagination/PAGINATION_LOAD_PAGE","payload":{"entityType":"posts","page":1}}}}',
          },
          widgetId: '813eb645-c6bd-4870-906d-694f30869fd9',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '5cl-837',
            pageId: 'o23tu',
          },
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 's4h-23',
            pageId: 'o23tu',
            basedOnSignature: '5cl-825',
          },
          style: {
            properties: {
              'param_color_post-post-list-backgroundColor': 'color_11',
              'param_number_layout-post-list-layoutType': '15',
              'param_boolean_blog-isViewCountEnabled': 'false',
              'param_boolean_blog-isCommentCountEnabled': 'false',
              'param_boolean_blog-isAuthorNameEnabled': 'false',
              'param_color_post-post-list-linkHashtagColor': 'color_18',
              'param_boolean_blog-isReadingTimeEnabled': 'false',
              'param_number_layout-post-list-list-layoutSpacing': '12',
              'param_color_post-post-list-titleColor': 'color_15',
              'param_color_post-post-list-borderColor': 'color_12',
              'param_number_layout-post-list-pg-grid-sidesPadding': '24',
              'param_boolean_blog-isLikeCountEnabled': 'false',
              'alpha-param_color_post-post-list-borderColor': '0.75',
              'param_boolean_blog-isCoverImageEnabled': 'true',
              'param_number_layout-post-list-list-imageRatio': '3',
              'param_color_post-post-list-descriptionColor': 'color_15',
              'param_number_layout-post-list-pg-grid-contentHeight': '320',
              param_color_postListWidgetBackgroundColor: 'color_11',
              'param_boolean_blog-isPostPublishDateEnabled': 'false',
              'param_boolean_blog-isPostDescriptionEnabled': 'true',
              'param_number_layout-post-list-list-margins': '0',
              'alpha-param_color_post-post-list-backgroundColor': '1',
              'alpha-param_color_postListWidgetBackgroundColor': '0',
              param_color_postListWidgetSliderArrowsColor: 'color_15',
              'alpha-param_color_postListWidgetSliderArrowsColor': '1',
              'param_font_post-pg-grid-post-list-titleFont':
                '{"family":"syne","displayName":"Heading 2","style":{"bold":false,"italic":false,"underline":false},"size":22,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
              'param_font_post-pg-grid-post-list-descriptionFont':
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":16,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
              'param_color_post-post-list-overlayColor': 'rgba(0,0,0,0.55)',
              'alpha-param_color_post-post-list-overlayColor': '0.55',
              'param_font_post-pg-side-by-side-post-list-titleFont':
                '{"family":"syne","displayName":"Heading 2","style":{"bold":false,"italic":false,"underline":false},"size":28,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
              'param_font_post-pg-side-by-side-post-list-descriptionFont':
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":16,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
              'param_font_post-list-post-list-titleFont':
                '{"family":"syne","displayName":"Heading 2","style":{"bold":false,"italic":false,"underline":false},"size":18,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
              'param_font_post-list-post-list-descriptionFont':
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":14,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
              'param_font_post-post-list-titleFont':
                '{"family":"syne","displayName":"Heading 2","style":{"bold":false,"italic":false,"underline":false},"size":28,"preset":"Custom","editorKey":"font_2","fontStyleParam":true,"value":"font:normal normal normal 28px/35px syne,sans-serif;"}',
              'param_font_post-post-list-descriptionFont':
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":16,"preset":"Custom","editorKey":"font_8","fontStyleParam":true,"value":"font:normal normal normal 16px/20px questrial,sans-serif;"}',
              'param_boolean_blog-isConfigurableLineClampingEnabled': true,
            },
            propertiesSource: {
              'param_color_post-post-list-backgroundColor': 'theme',
              'param_number_layout-post-list-layoutType': 'value',
              'param_boolean_blog-isViewCountEnabled': 'value',
              'param_boolean_blog-isCommentCountEnabled': 'value',
              'param_boolean_blog-isAuthorNameEnabled': 'value',
              'param_color_post-post-list-linkHashtagColor': 'theme',
              'param_boolean_blog-isReadingTimeEnabled': 'value',
              'param_number_layout-post-list-list-layoutSpacing': 'value',
              'param_color_post-post-list-titleColor': 'theme',
              'param_color_post-post-list-borderColor': 'theme',
              'param_number_layout-post-list-pg-grid-sidesPadding': 'value',
              'param_boolean_blog-isLikeCountEnabled': 'value',
              'alpha-param_color_post-post-list-borderColor': 'value',
              'param_boolean_blog-isCoverImageEnabled': 'value',
              'param_number_layout-post-list-list-imageRatio': 'value',
              'param_color_post-post-list-descriptionColor': 'theme',
              'param_number_layout-post-list-pg-grid-contentHeight': 'value',
              param_color_postListWidgetBackgroundColor: 'theme',
              'param_boolean_blog-isPostPublishDateEnabled': 'value',
              'param_boolean_blog-isPostDescriptionEnabled': 'value',
              'param_number_layout-post-list-list-margins': 'value',
              'alpha-param_color_post-post-list-backgroundColor': 'value',
              'alpha-param_color_postListWidgetBackgroundColor': 'value',
              param_color_postListWidgetSliderArrowsColor: 'theme',
              'alpha-param_color_postListWidgetSliderArrowsColor': 'value',
              'param_font_post-pg-grid-post-list-titleFont': 'value',
              'param_font_post-pg-grid-post-list-descriptionFont': 'value',
              'param_color_post-post-list-overlayColor': 'value',
              'alpha-param_color_post-post-list-overlayColor': 'value',
              'param_font_post-pg-side-by-side-post-list-titleFont': 'value',
              'param_font_post-pg-side-by-side-post-list-descriptionFont':
                'value',
              'param_font_post-list-post-list-titleFont': 'value',
              'param_font_post-list-post-list-descriptionFont': 'value',
              'param_font_post-post-list-titleFont': 'value',
              'param_font_post-post-list-descriptionFont': 'value',
              'param_boolean_blog-isConfigurableLineClampingEnabled': 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-ky1mpmyi',
        mobileStructure: {
          layout: {
            width: 320,
            height: 1744,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-ky1mpmyi',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 1166,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'o23tu',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-818',
        pageId: 'o23tu',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '5cl-293',
        pageId: 'o23tu',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      recommendedScale: 1,
      recommendedWidth: 320,
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-829',
        pageId: 'o23tu',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-830',
          pageId: 'o23tu',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-822',
        pageId: 'o23tu',
      },
      name: getAnchorName(CeType.NewBlogCustomFeed),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.NewBlogCustomFeed,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 1744,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2lvf7',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_STORES]: {
    type: 'Container',
    metaData: {
      sig: '9so-328',
      pageId: 'tuckg',
      basedOnSignature: '9so-22',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '9so-327',
          pageId: 'tuckg',
          basedOnSignature: '9so-319',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 30,
          x: 0,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: {
            left: {
              px: 30,
              vw: 0,
            },
            right: {
              px: 30,
              vw: 0,
            },
          },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2csmv',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'nvj-325',
            pageId: 'tuckg',
          },
          applicationId: '1025',
          appDefinitionId: '1380b703-ce81-ff05-f115-39571d94dfcd',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '3vq-5',
              pageId: 'tuckg',
            },
            content:
              '{"presetId":"Wix_Store_Slider_Product_Gallery_1","FILTERS":{"data":[{"filterType":"CATEGORY","show":true,"selected":[]},{"filterType":"PRICE","show":true},{"filterType":"OPTIONS","show":true}]}}',
          },
          widgetId: '139a41fd-0b1d-975f-6f67-e8cbdf8ccc82',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '9so-329',
            pageId: 'tuckg',
            basedOnSignature: '9so-320',
          },
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '9so-321',
            pageId: 'tuckg',
            basedOnSignature: '3vq-10',
          },
          style: {
            properties: {
              param_boolean_full_width: true,
              param_number_full_width_margin: '30',
            },
            propertiesSource: {
              param_boolean_full_width: 'value',
              param_number_full_width_margin: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kxxcjuj8',
        mobileStructure: {
          layout: {
            width: 320,
            height: 343,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxxcjuj8',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 363,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'tuckg',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-15',
        pageId: 'tuckg',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '430-270',
        pageId: 'tuckg',
      },
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-483',
        pageId: 'tuckg',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-481',
          pageId: 'tuckg',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'bua-491',
        pageId: 'tuckg',
      },
      name: getAnchorName(CeType.StoresGridGalleryWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.StoresGridGalleryWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 343,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2csmv',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_BOOKINGS]: {
    type: 'Container',
    metaData: {
      sig: '9so-441',
      pageId: 'nvtzw',
      basedOnSignature: '9so-435',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '9so-442',
          pageId: 'nvtzw',
          basedOnSignature: '9so-432',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 100,
          x: 0,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: {
            left: {
              px: 30,
              vw: 0,
            },
            right: {
              px: 30,
              vw: 0,
            },
          },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2izon',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-771',
            pageId: 'nvtzw',
          },
          applicationId: '3466',
          appDefinitionId: '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
          referenceId: '96ca8372-845e-40c2-8f2c-90af879f5cef',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: 'u5s-770',
              pageId: 'nvtzw',
            },
            content: '{"presetId":"Wix_Bookings_Offering_List_Widget_Grid"}',
          },
          widgetId: 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '9so-433',
            pageId: 'nvtzw',
            basedOnSignature: '3vq-410',
          },
          shouldBeForceConverted: true,
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '3vq-35',
            pageId: 'nvtzw',
          },
          style: {
            properties: {
              param_font_OFFERING_NAME_FONT:
                '{"editorKey":"font_2","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":24,"fontFamily":"syne","value":"","displayName":"Heading 2","htmlTag":"h2","family":"syne"}',
              param_font_OFFERING_TAGLINE_FONT:
                '{"editorKey":"font_8","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":16,"fontFamily":"questrial","value":"","displayName":"Paragraph 2","htmlTag":"p","family":"questrial"}',
              param_font_OFFERING_MORE_INFO_LABEL_FONT:
                '{"editorKey":"font_8","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":16,"fontFamily":"questrial","value":"","displayName":"Paragraph 2","htmlTag":"","family":"questrial"}',
              param_font_OFFERING_DETAILS_FONT:
                '{"editorKey":"font_8","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":16,"fontFamily":"questrial","value":"","displayName":"Paragraph 2","htmlTag":"p","family":"questrial"}',
              param_font_BUTTON_TEXT_FONT:
                '{"editorKey":"font_8","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":16,"fontFamily":"questrial","value":"","displayName":"Paragraph 2","htmlTag":"","family":"questrial"}',
              param_font_CATEGORY_NAME_FONT:
                '{"editorKey":"font_8","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":16,"fontFamily":"questrial","value":"","displayName":"Paragraph 2","htmlTag":"","family":"questrial"}',
              param_font_MULTI_OFFERINGS_TITLE_FONT:
                '{"editorKey":"font_2","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":32,"fontFamily":"syne","value":"","displayName":"Heading 2","htmlTag":"h1","family":"syne"}',
              param_font_MOBILE_CATEGORY_NAME_FONT:
                '{"editorKey":"font_8","lineHeight":"1.4em","style":{"bold":false,"italic":false},"weight":"normal","size":14,"fontFamily":"questrial","value":"","displayName":"Paragraph 2","htmlTag":"","family":"questrial"}',
            },
            propertiesSource: {
              param_font_OFFERING_NAME_FONT: 'value',
              param_font_OFFERING_TAGLINE_FONT: 'value',
              param_font_OFFERING_MORE_INFO_LABEL_FONT: 'value',
              param_font_OFFERING_DETAILS_FONT: 'value',
              param_font_BUTTON_TEXT_FONT: 'value',
              param_font_CATEGORY_NAME_FONT: 'value',
              param_font_MULTI_OFFERINGS_TITLE_FONT: 'value',
              param_font_MOBILE_CATEGORY_NAME_FONT: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kxx2kmje',
        mobileStructure: {
          layout: {
            width: 320,
            height: 1507,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxx2kmje',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 657,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'nvtzw',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-519',
        pageId: 'nvtzw',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-783',
        pageId: 'nvtzw',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-530',
        pageId: 'nvtzw',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-531',
          pageId: 'nvtzw',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-523',
        pageId: 'nvtzw',
      },
      name: getAnchorName(CeType.BookingsWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.BookingsWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 1507,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2izon',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [INSTAGRAM_FEED]: {
    type: 'Container',
    metaData: {
      sig: '9so-574',
      pageId: 'zsjla',
      basedOnSignature: '9so-566',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '9so-573',
          pageId: 'zsjla',
          basedOnSignature: '9so-567',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 1321,
          x: 0,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2rsle',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'wtd-1043',
            pageId: 'zsjla',
          },
          applicationId: '4882',
          appDefinitionId: '14635256-b183-1c71-a4d2-f55179b80e8a',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: 'wtd-1042',
              pageId: 'zsjla',
            },
            content:
              '{"___settingsEvent":{"event":"reset","payload":{"id":1641206749026,"value":{}}}}',
          },
          widgetId: '14635280-8c8d-5346-b643-691f84f48973',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '5cl-168',
            pageId: 'zsjla',
          },
        },
        style: 'tpaw0',
        originCompId: 'comp-kxx2sfne',
        mobileStructure: {
          layout: {
            width: 320,
            height: 937,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxx2sfne',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 1381,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'zsjla',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-1425',
        pageId: 'zsjla',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1467',
        pageId: 'zsjla',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      recommendedScale: 1,
      recommendedWidth: 320,
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1432',
        pageId: 'zsjla',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-1433',
          pageId: 'zsjla',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1429',
        pageId: 'zsjla',
      },
      name: getAnchorName(CeType.Instagram_Widget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.Instagram_Widget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 937,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2rsle',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_GROUPS]: {
    type: 'Container',
    metaData: {
      sig: 'fe7-63',
      pageId: 'rn5ix',
      basedOnSignature: 'fe7-60',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: 'jfj-575',
          pageId: 'rn5ix',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 283,
          x: 1,
          y: 18,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-ky3i8bix',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'jfj-410',
            pageId: 'rn5ix',
          },
          applicationId: '11813',
          appDefinitionId: '148c2287-c669-d849-d153-463c7486a694',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: 'jfj-418',
              pageId: 'rn5ix',
            },
            content:
              '{"groupListLayout":"sideBySide","imageRatio":"square","___settingsEvent":{"event":"reset","payload":{"id":1641506184317,"value":{}}}}',
          },
          widgetId: '0a9f687f-7e00-4576-a8e1-9415844b8f44',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'jfj-578',
            pageId: 'rn5ix',
          },
        },
        style: 'tpaw0',
        originCompId: 'comp-ky3icp7n',
        mobileStructure: {
          layout: {
            width: 320,
            height: 447,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-ky3icp7n',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 319,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'rn5ix',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'jfj-101',
        pageId: 'rn5ix',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'jfj-579',
        pageId: 'rn5ix',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'jfj-116',
        pageId: 'rn5ix',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'jfj-117',
          pageId: 'rn5ix',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-822',
        pageId: 'o23tu',
      },
      name: t('Section_Name_Groups'),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.Widget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 447,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-ky3i8bix',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_PRO_GALLERY]: {
    type: 'Container',
    metaData: {
      sig: 'wgp-51',
      pageId: 'a8lai',
      basedOnSignature: 'vdy-1272',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: 'wgp-50',
          pageId: 'a8lai',
          basedOnSignature: 'vdy-1155',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1131,
          height: 720,
          x: -76,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: {
            left: {
              px: 30,
              vw: 0,
            },
            right: {
              px: 30,
              vw: 0,
            },
          },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kyb6ge37',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'vdy-109',
            pageId: 'a8lai',
          },
          applicationId: '14134',
          appDefinitionId: '14271d6f-ba62-d045-549b-ab972ae1f70e',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: 'vdy-108',
              pageId: 'a8lai',
            },
            content: '{"presetId":"gridProGallery"}',
          },
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'vdy-1280',
            pageId: 'a8lai',
          },
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'vdy-1149',
            pageId: 'a8lai',
          },
          style: {
            properties: {
              param_boolean_allowSocial: 'false',
              param_boolean_shouldUnDock: 'false',
              param_color_itemIconColor: 'color_11',
              param_font_loadMoreButtonText: '"Load More"',
              'alpha-param_color_itemOpacity': '0.6',
              param_color_titleColorExpand: 'color_15',
              param_number_newItemsDetails: '1',
              param_color_loadMoreButtonFontColor: 'color_15',
              param_color_itemDescriptionFontColor: 'color_11',
              'alpha-param_color_te-background-color-picker': '1',
              'alpha-param_color_itemDescriptionFontColor': '1',
              param_number_gridStyle: '1',
              'param_font_text-editor-font':
                '{"family":"avenir-lt-w01_85-heavy1475544","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
              param_font_itemDescriptionFont:
                '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":"15px","preset":"Body-M","editorKey":"font_8","fontStyleParam":true,"displayName":"Paragraph 2"}',
              'param_font_text-editor-font-1499774301866':
                '{"family":"avenir-lt-w01_35-light1475496","style":{"bold":false,"italic":false,"underline":false},"size":40,"preset":"Custom","editorKey":"font_2","fontStyleParam":true}',
              param_boolean_allowTitle: 'false',
              param_number_gallerySize: '48',
              'param_number_te-padding-slider': '50',
              param_color_itemOpacity: 'color_14',
              param_boolean_allowDescription: 'false',
              param_color_loadMoreButtonColor: 'color_11',
              param_number_newItemsLocation: '0',
              param_number_scrollDirection: '0',
              'alpha-param_color_itemFontColorSlideshow': '1',
              param_color_loadMoreButtonBorderColor: 'color_15',
              'alpha-param_color_foreColor': '1',
              'alpha-param_color_itemIconColor': '1',
              param_number_collageDensity: '80',
              param_number_loveCounter: '1',
              param_number_galleryLayout: '-2',
              param_color_itemFontColorSlideshow: 'color_15',
              'alpha-param_color_loadMoreButtonBorderColor': '1',
              param_color_itemFontColor: 'color_11',
              param_font_loadMoreButtonFont:
                '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"13","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              'alpha-param_color_backColor': '1',
              'alpha-param_color_titleColorExpand': '1',
              'alpha-param_color_itemIconColorSlideshow': '1',
              param_font_itemFontSlideshow:
                '{"family":"helvetica-w01-bold","displayName":"Basic Heading","style":{"bold":false,"italic":false,"underline":false},"size":22,"preset":"Custom","editorKey":"font_5","fontStyleParam":true}',
              param_number_numberOfImagesPerRow: '3',
              param_font_titleFontExpand:
                '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Page-title","editorKey":"font_2","size":"18px","fontStyleParam":true,"displayName":"Page Title"}',
              param_number_galleryVerticalAlign: '0',
              param_color_itemIconColorSlideshow: 'color_15',
              param_color_foreColor: 'color_12',
              param_color_bgColorExpand: 'color_11',
              param_number_galleryHorizontalAlign: '0',
              'alpha-param_color_actionsColorExpand': '1',
              'alpha-param_color_loadMoreButtonColor': '1',
              'alpha-param_color_itemFontColor': '1',
              'alpha-param_color_descriptionColorExpand': '1',
              'alpha-param_color_itemDescriptionFontColorSlideshow': '1',
              param_color_descriptionColorExpand: 'color_15',
              'alpha-param_color_loadMoreButtonFontColor': '1',
              param_color_actionsColorExpand: 'color_15',
              'alpha-param_color_bgColorExpand': '1',
              param_font_itemDescriptionFontSlideshow:
                '{"family":"helvetica-w01-light","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
              param_color_backColor: 'color_12',
              param_font_descriptionFontExpand:
                '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_35-light1475496","preset":"Body-M","editorKey":"font_8","size":"15px","fontStyleParam":true,"displayName":"Paragraph 2"}',
              param_font_itemFont:
                '{"style":{"bold":false,"italic":false,"underline":false},"family":"avenir-lt-w01_85-heavy1475544","preset":"Heading-S","editorKey":"font_6","size":"20px","fontStyleParam":true,"displayName":"Small Heading"}',
              param_color_itemDescriptionFontColorSlideshow: 'color_15',
              param_boolean_addPanel: 'true',
              param_boolean_dummyBooly: 'true',
              param_boolean_allowDownload: 'false',
              'param_color_te-background-color-picker': 'color_17',
              param_number_arrowsVerticalPosition: '0',
              param_font_galleryLayoutType: '"presets"',
              param_number_designedPresetId: '5',
              param_boolean_isInAdi: 'false',
              param_color_overlayGradientColor1: 'color_11',
              'alpha-param_color_overlayGradientColor1': '1',
              param_color_overlayGradientColor2: 'color_11',
              'alpha-param_color_overlayGradientColor2': '1',
              param_color_arrowsColor: 'color_11',
              'alpha-param_color_arrowsColor': '1',
              param_color_textBoxFillColor: 'color_12',
              'alpha-param_color_textBoxFillColor': '1',
              param_color_customButtonFontColorForHover: 'color_15',
              'alpha-param_color_customButtonFontColorForHover': '1',
              param_color_externalCustomButtonColor: 'color_18',
              'alpha-param_color_externalCustomButtonColor': '0',
              param_color_externalCustomButtonBorderColor: 'color_15',
              'alpha-param_color_externalCustomButtonBorderColor': '1',
              param_color_customButtonFontColor: 'color_11',
              'alpha-param_color_customButtonFontColor': '1',
              param_color_customButtonColor: 'color_11',
              'alpha-param_color_customButtonColor': '0',
              param_color_customButtonBorderColor: 'color_11',
              'alpha-param_color_customButtonBorderColor': '1',
              param_color_navigationArrows_container_backgroundColor:
                'color_11',
              'alpha-param_color_navigationArrows_container_backgroundColor':
                '0.6',
              param_color_itemBorderColor: 'color_15',
              'alpha-param_color_itemBorderColor': '1',
              param_color_textBoxBorderColor: 'color_15',
              'alpha-param_color_textBoxBorderColor': '1',
              param_color_itemShadowOpacityAndColor: 'color_15',
              'alpha-param_color_itemShadowOpacityAndColor': '0.2',
              param_color_imageLoadingColor: 'color_12',
              'alpha-param_color_imageLoadingColor': '1',
              param_color_oneColorAnimationColor: 'color_11',
              'alpha-param_color_oneColorAnimationColor': '1',
              param_font_customButtonFontForHover:
                '{"family":"avenir-lt-w01_85-heavy1475544","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
              param_font_customButtonFont:
                '{"family":"avenir-lt-w01_85-heavy1475544","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":15,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
              param_boolean_previewHover: 'false',
              param_font_jsonStyleParams:
                '"{\\"layoutParams\\":{\\"repeatingGroupTypes\\":\\"2v\\"},\\"cubeImages\\":true,\\"rotatingCropRatios\\":\\"25%/40%,25%/60%,25%/60%,25%/40%\\",\\"imageMargin\\":2,\\"gridStyle\\":1,\\"scrollDirection\\":1,\\"titlePlacement\\":\\"SHOW_ON_HOVER\\",\\"cubeType\\":\\"crop\\"}"',
              param_font_m_jsonStyleParams:
                '"{\\"groupTypes\\":\\"2v\\",\\"layoutParams\\":{\\"repeatingGroupTypes\\":\\"2v\\"},\\"cubeImages\\":true,\\"rotatingCropRatios\\":\\"50%/35%,50%/65%,50%/65%,50%/35%\\",\\"imageMargin\\":2,\\"gridStyle\\":1,\\"scrollDirection\\":1,\\"titlePlacement\\":\\"SHOW_ON_HOVER\\"}"',
            },
            propertiesSource: {
              param_boolean_allowSocial: 'value',
              param_boolean_shouldUnDock: 'value',
              param_color_itemIconColor: 'theme',
              param_font_loadMoreButtonText: 'value',
              'alpha-param_color_itemOpacity': 'value',
              param_color_titleColorExpand: 'theme',
              param_number_newItemsDetails: 'value',
              param_color_loadMoreButtonFontColor: 'theme',
              param_color_itemDescriptionFontColor: 'theme',
              'alpha-param_color_te-background-color-picker': 'value',
              'alpha-param_color_itemDescriptionFontColor': 'value',
              param_number_gridStyle: 'value',
              'param_font_text-editor-font': 'value',
              param_font_itemDescriptionFont: 'value',
              'param_font_text-editor-font-1499774301866': 'value',
              param_boolean_allowTitle: 'value',
              param_number_gallerySize: 'value',
              'param_number_te-padding-slider': 'value',
              param_color_itemOpacity: 'theme',
              param_boolean_allowDescription: 'value',
              param_color_loadMoreButtonColor: 'theme',
              param_number_newItemsLocation: 'value',
              param_number_scrollDirection: 'value',
              'alpha-param_color_itemFontColorSlideshow': 'value',
              param_color_loadMoreButtonBorderColor: 'theme',
              'alpha-param_color_foreColor': 'value',
              'alpha-param_color_itemIconColor': 'value',
              param_number_collageDensity: 'value',
              param_number_loveCounter: 'value',
              param_number_galleryLayout: 'value',
              param_color_itemFontColorSlideshow: 'theme',
              'alpha-param_color_loadMoreButtonBorderColor': 'value',
              param_color_itemFontColor: 'theme',
              param_font_loadMoreButtonFont: 'value',
              'alpha-param_color_backColor': 'value',
              'alpha-param_color_titleColorExpand': 'value',
              'alpha-param_color_itemIconColorSlideshow': 'value',
              param_font_itemFontSlideshow: 'value',
              param_number_numberOfImagesPerRow: 'value',
              param_font_titleFontExpand: 'value',
              param_number_galleryVerticalAlign: 'value',
              param_color_itemIconColorSlideshow: 'theme',
              param_color_foreColor: 'theme',
              param_color_bgColorExpand: 'theme',
              param_number_galleryHorizontalAlign: 'value',
              'alpha-param_color_actionsColorExpand': 'value',
              'alpha-param_color_loadMoreButtonColor': 'value',
              'alpha-param_color_itemFontColor': 'value',
              'alpha-param_color_descriptionColorExpand': 'value',
              'alpha-param_color_itemDescriptionFontColorSlideshow': 'value',
              param_color_descriptionColorExpand: 'theme',
              'alpha-param_color_loadMoreButtonFontColor': 'value',
              param_color_actionsColorExpand: 'theme',
              'alpha-param_color_bgColorExpand': 'value',
              param_font_itemDescriptionFontSlideshow: 'value',
              param_color_backColor: 'theme',
              param_font_descriptionFontExpand: 'value',
              param_font_itemFont: 'value',
              param_color_itemDescriptionFontColorSlideshow: 'theme',
              param_boolean_addPanel: 'value',
              param_boolean_dummyBooly: 'value',
              param_boolean_allowDownload: 'value',
              'param_color_te-background-color-picker': 'theme',
              param_number_arrowsVerticalPosition: 'value',
              param_font_galleryLayoutType: 'value',
              param_number_designedPresetId: 'value',
              param_boolean_isInAdi: 'value',
              param_color_overlayGradientColor1: 'theme',
              'alpha-param_color_overlayGradientColor1': 'value',
              param_color_overlayGradientColor2: 'theme',
              'alpha-param_color_overlayGradientColor2': 'value',
              param_color_arrowsColor: 'theme',
              'alpha-param_color_arrowsColor': 'value',
              param_color_textBoxFillColor: 'theme',
              'alpha-param_color_textBoxFillColor': 'value',
              param_color_customButtonFontColorForHover: 'theme',
              'alpha-param_color_customButtonFontColorForHover': 'value',
              param_color_externalCustomButtonColor: 'theme',
              'alpha-param_color_externalCustomButtonColor': 'value',
              param_color_externalCustomButtonBorderColor: 'theme',
              'alpha-param_color_externalCustomButtonBorderColor': 'value',
              param_color_customButtonFontColor: 'theme',
              'alpha-param_color_customButtonFontColor': 'value',
              param_color_customButtonColor: 'theme',
              'alpha-param_color_customButtonColor': 'value',
              param_color_customButtonBorderColor: 'theme',
              'alpha-param_color_customButtonBorderColor': 'value',
              param_color_navigationArrows_container_backgroundColor: 'theme',
              'alpha-param_color_navigationArrows_container_backgroundColor':
                'value',
              param_color_itemBorderColor: 'theme',
              'alpha-param_color_itemBorderColor': 'value',
              param_color_textBoxBorderColor: 'theme',
              'alpha-param_color_textBoxBorderColor': 'value',
              param_color_itemShadowOpacityAndColor: 'theme',
              'alpha-param_color_itemShadowOpacityAndColor': 'value',
              param_color_imageLoadingColor: 'theme',
              'alpha-param_color_imageLoadingColor': 'value',
              param_color_oneColorAnimationColor: 'theme',
              'alpha-param_color_oneColorAnimationColor': 'value',
              param_font_customButtonFontForHover: 'value',
              param_font_customButtonFont: 'value',
              param_boolean_previewHover: 'value',
              param_font_jsonStyleParams: 'value',
              param_font_m_jsonStyleParams: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kyb6m91s',
        mobileStructure: {
          layout: {
            width: 320,
            height: 560,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kyb6m91s',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 785,
      x: -1,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'a8lai',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'vdy-33',
        pageId: 'a8lai',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'vdy-1281',
        pageId: 'a8lai',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'vdy-48',
        pageId: 'a8lai',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'vdy-49',
          pageId: 'a8lai',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-822',
        pageId: 'o23tu',
      },
      name: getAnchorName(CeType.ProGalleryWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.ProGalleryWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        x: 0,
        y: 0,
        width: 320,
        height: 560,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kyb6ge37',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_VIDEO]: {
    type: 'Container',
    metaData: {
      sig: 'eqk-543',
      pageId: 'pn5rf',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '9so-314',
          pageId: 'pn5rf',
          basedOnSignature: '9so-257',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 883,
          x: 0,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2pr3s',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-1578',
            pageId: 'pn5rf',
          },
          applicationId: '5797',
          appDefinitionId: '14409595-f076-4753-8303-9a86f9f71469',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: 'u5s-1577',
              pageId: 'pn5rf',
            },
            content: '{"presetId":"classic"}',
          },
          widgetId: '144097ea-fea0-498e-ade7-e6de40127106',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '9so-266',
            pageId: 'pn5rf',
            basedOnSignature: '430-268',
          },
          modifiedByUser: true,
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '3vq-123',
            pageId: 'pn5rf',
          },
          style: {
            properties: {
              param_color_buttonsAndLinksColor: 'color_18',
              param_color_backgroundColor: 'color_11',
              param_color_textColor: 'color_15',
              'alpha-param_color_buttonsAndLinksColor': '1',
              param_color_mainButtonTextColor: 'color_11',
              'alpha-param_color_mainButtonTextColor': '1',
              param_font_textFont:
                '{"family":"proxima-n-w01-reg","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"15","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              param_font_channelId: '"a1ca9dac7ee44d52a41868329471105b"',
              'alpha-param_color_textColor': '1',
              param_boolean_showChannelTitle: 'true',
              'alpha-param_color_backgroundColor': '1',
              param_number_videoInfoPosition: '1',
              param_boolean_showVideoPublisherInList: 'false',
              param_font_titleFont:
                '{"family":"proxima-n-w01-reg","style":{"bold":false,"italic":false,"underline":false},"size":50,"preset":"Custom","editorKey":"font_4","fontStyleParam":true}',
              param_boolean_showSignIn: 'false',
              param_number_videosInRow: '3',
              param_boolean_showChannelCategories: 'false',
              param_boolean_showChannelInfo: 'true',
              param_number_thumbnailSpacing: '0',
              param_number_containerMargins: '0',
              param_number_showInfoAction: '1',
              param_number_layoutStyle: '1',
              param_number_channelLayout: '1',
              param_number_mainItemType: '2',
              param_number_mainItemTitlePosition: '1',
              param_number_videoListVisibility: '1',
              param_number_mainItemOverlayOpacity: '35',
              param_number_thumbnailOverlayOpacity: '40',
              param_number_sliderHeight: '383',
              param_number_showMediaType: '2',
              param_number_numberOfRows: '1',
              param_number_playType: '1',
              param_number_compactShowVideoListType: '1',
              param_number_mainActionStyleType: '1',
              param_number_settingsVersion: '4',
              param_number_showExpandedVideoListInfo: '1',
              param_number_expandedTheme: '1',
              param_number_navigationArrowsBehavior: '0',
              param_number_thumbnailButtonsBehavior: '1',
              param_number_navigationArrowsPosition: '0',
              param_number_textAlignment: '1',
              param_number_loadMoreButtonType: '1',
              param_number_videoEndOption: '1',
              param_boolean_autoPlay: 'false',
              param_boolean_enableVideosSharing: 'true',
              param_boolean_showChannelShare: 'true',
              param_boolean_showSearch: 'true',
              param_boolean_showChannelTags: 'true',
              param_boolean_showChannelVideos: 'true',
              param_boolean_showVideoTitle: 'true',
              param_boolean_showFullInfo: 'true',
              param_boolean_showVideoTitleInList: 'true',
              param_boolean_showVideoDescriptionInList: 'true',
              param_boolean_showVideoDurationInList: 'true',
              param_boolean_showTitleForMainItem: 'true',
              param_boolean_showPublisherForMainItem: 'true',
              param_boolean_showExpandedVideoInfo: 'true',
              param_boolean_enableExpandedVideosSharing: 'true',
              param_boolean_showExpandedSignIn: 'false',
              param_boolean_showExpandedChannelTitle: 'false',
              param_boolean_showExpandedVideoTitle: 'false',
              param_boolean_showExpandedVideoList: 'true',
              param_boolean_isSingleVideo: 'false',
              param_boolean_allowComments: 'false',
              param_boolean_stretchToFullWidth: 'false',
              param_boolean_useOptimalFontSizes: 'true',
              param_color_compactMenuActionsColor: 'color_11',
              'alpha-param_color_compactMenuActionsColor': '1',
              param_color_compactMenuBackgroundColor: 'color_15',
              'alpha-param_color_compactMenuBackgroundColor': '1',
              param_color_mainButtonColor: 'color_18',
              param_color_thumbnailOverlayColor: 'rgba(0,0,0,0.4)',
              'alpha-param_color_thumbnailOverlayColor': '0.4',
              param_color_thumbnailButtonsColor: 'rgba(255,255,255,1)',
              'alpha-param_color_thumbnailButtonsColor': '1',
              param_font_language: '""',
            },
            propertiesSource: {
              param_color_buttonsAndLinksColor: 'theme',
              param_color_backgroundColor: 'theme',
              param_color_textColor: 'theme',
              'alpha-param_color_buttonsAndLinksColor': 'value',
              param_color_mainButtonTextColor: 'theme',
              'alpha-param_color_mainButtonTextColor': 'value',
              param_font_textFont: 'value',
              param_font_channelId: 'value',
              'alpha-param_color_textColor': 'value',
              param_boolean_showChannelTitle: 'value',
              'alpha-param_color_backgroundColor': 'value',
              param_number_videoInfoPosition: 'value',
              param_boolean_showVideoPublisherInList: 'value',
              param_font_titleFont: 'value',
              param_boolean_showSignIn: 'value',
              param_number_videosInRow: 'value',
              param_boolean_showChannelCategories: 'value',
              param_boolean_showChannelInfo: 'value',
              param_number_thumbnailSpacing: 'value',
              param_number_containerMargins: 'value',
              param_number_showInfoAction: 'value',
              param_number_layoutStyle: 'value',
              param_number_channelLayout: 'value',
              param_number_mainItemType: 'value',
              param_number_mainItemTitlePosition: 'value',
              param_number_videoListVisibility: 'value',
              param_number_mainItemOverlayOpacity: 'value',
              param_number_thumbnailOverlayOpacity: 'value',
              param_number_sliderHeight: 'value',
              param_number_showMediaType: 'value',
              param_number_numberOfRows: 'value',
              param_number_playType: 'value',
              param_number_compactShowVideoListType: 'value',
              param_number_mainActionStyleType: 'value',
              param_number_settingsVersion: 'value',
              param_number_showExpandedVideoListInfo: 'value',
              param_number_expandedTheme: 'value',
              param_number_navigationArrowsBehavior: 'value',
              param_number_thumbnailButtonsBehavior: 'value',
              param_number_navigationArrowsPosition: 'value',
              param_number_textAlignment: 'value',
              param_number_loadMoreButtonType: 'value',
              param_number_videoEndOption: 'value',
              param_boolean_autoPlay: 'value',
              param_boolean_enableVideosSharing: 'value',
              param_boolean_showChannelShare: 'value',
              param_boolean_showSearch: 'value',
              param_boolean_showChannelTags: 'value',
              param_boolean_showChannelVideos: 'value',
              param_boolean_showVideoTitle: 'value',
              param_boolean_showFullInfo: 'value',
              param_boolean_showVideoTitleInList: 'value',
              param_boolean_showVideoDescriptionInList: 'value',
              param_boolean_showVideoDurationInList: 'value',
              param_boolean_showTitleForMainItem: 'value',
              param_boolean_showPublisherForMainItem: 'value',
              param_boolean_showExpandedVideoInfo: 'value',
              param_boolean_enableExpandedVideosSharing: 'value',
              param_boolean_showExpandedSignIn: 'value',
              param_boolean_showExpandedChannelTitle: 'value',
              param_boolean_showExpandedVideoTitle: 'value',
              param_boolean_showExpandedVideoList: 'value',
              param_boolean_isSingleVideo: 'value',
              param_boolean_allowComments: 'value',
              param_boolean_stretchToFullWidth: 'value',
              param_boolean_useOptimalFontSizes: 'value',
              param_color_compactMenuActionsColor: 'theme',
              'alpha-param_color_compactMenuActionsColor': 'value',
              param_color_compactMenuBackgroundColor: 'theme',
              'alpha-param_color_compactMenuBackgroundColor': 'value',
              param_color_mainButtonColor: 'theme',
              param_color_thumbnailOverlayColor: 'value',
              'alpha-param_color_thumbnailOverlayColor': 'value',
              param_color_thumbnailButtonsColor: 'value',
              'alpha-param_color_thumbnailButtonsColor': 'value',
              param_font_language: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kxx2w771',
        mobileStructure: {
          layout: {
            width: 320,
            height: 250,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxx2w771',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 955,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'pn5rf',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-1177',
        pageId: 'pn5rf',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '9so-265',
        pageId: 'pn5rf',
        basedOnSignature: 'u5s-1643',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      recommendedScale: 1,
      recommendedWidth: 320,
      orderIndex: 0,
      modifiedByUser: true,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1190',
        pageId: 'pn5rf',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-1191',
          pageId: 'pn5rf',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1181',
        pageId: 'pn5rf',
      },
      name: getAnchorName(CeType.VideoWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.VideoWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 250,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2pr3s',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_MUSIC]: {
    type: 'Container',
    metaData: {
      sig: '430-151',
      pageId: 'vafoj',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '3vq-201',
          pageId: 'vafoj',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 210,
          x: 0,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2q22h1',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-1670',
            pageId: 'vafoj',
          },
          applicationId: '6445',
          appDefinitionId: '13bb5d67-1add-e770-a71f-001277e17c57',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: 'u5s-1669',
              pageId: 'vafoj',
            },
            content: '{"presetId":"Music_Prop_Player_2"}',
          },
          widgetId: '13bb5dc2-a098-5a74-502b-6bac84e80b23',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-1954',
            pageId: 'vafoj',
          },
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '3vq-132',
            pageId: 'vafoj',
          },
          style: {
            properties: {
              param_boolean_trackListShow: 'false',
              'param_boolean_wmp::isMigrated': 'true',
              param_number_layout: '1',
              'param_number_wmp::styleParamsVersion': '3',
              'param_font_wmp::numberTracksShown': '{}',
              param_color_backgroundColor: 'color_11',
              'alpha-param_color_backgroundColor': '1',
              param_color_borderColor: 'color_15',
              'alpha-param_color_borderColor': '1',
              param_color_dividersColor: 'color_12',
              'alpha-param_color_dividersColor': '100',
              param_color_allTextColor: 'color_15',
              'alpha-param_color_allTextColor': '1',
              param_color_activeTrackColor: 'color_18',
              'alpha-param_color_activeTrackColor': '1',
              param_color_controlBarColor: 'color_15',
              'alpha-param_color_controlBarColor': '1',
              param_font_trackTitleFont:
                '{"family":"questrial","displayName":"Paragraph 1","style":{"bold":false,"italic":false,"underline":false},"size":"22","preset":"Body-L","editorKey":"font_7","fontStyleParam":true}',
              param_font_listTrackTitleFont:
                '{"family":"questrial","displayName":"Paragraph 1","style":{"bold":false,"italic":false,"underline":false},"size":"22","preset":"Custom","editorKey":"font_7","fontStyleParam":true}',
              param_font_albumArtistFont:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              param_font_listAlbumArtistFont:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
              param_font_trackFont:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              param_font_activeTrackButtonsFont:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              param_font_buyShareLinksFont:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
            },
            propertiesSource: {
              param_boolean_trackListShow: 'value',
              'param_boolean_wmp::isMigrated': 'value',
              param_number_layout: 'value',
              'param_number_wmp::styleParamsVersion': 'value',
              'param_font_wmp::numberTracksShown': 'value',
              param_color_backgroundColor: 'theme',
              'alpha-param_color_backgroundColor': 'value',
              param_color_borderColor: 'theme',
              'alpha-param_color_borderColor': 'value',
              param_color_dividersColor: 'theme',
              'alpha-param_color_dividersColor': 'value',
              param_color_allTextColor: 'theme',
              'alpha-param_color_allTextColor': 'value',
              param_color_activeTrackColor: 'theme',
              'alpha-param_color_activeTrackColor': 'value',
              param_color_controlBarColor: 'theme',
              'alpha-param_color_controlBarColor': 'value',
              param_font_trackTitleFont: 'value',
              param_font_listTrackTitleFont: 'value',
              param_font_albumArtistFont: 'value',
              param_font_listAlbumArtistFont: 'value',
              param_font_trackFont: 'value',
              param_font_activeTrackButtonsFont: 'value',
              param_font_buyShareLinksFont: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kxx2xiae',
        mobileStructure: {
          layout: {
            width: 320,
            height: 601,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxx2xiae',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 270,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'vafoj',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-1234',
        pageId: 'vafoj',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1955',
        pageId: 'vafoj',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1247',
        pageId: 'vafoj',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-1248',
          pageId: 'vafoj',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1238',
        pageId: 'vafoj',
      },
      name: getAnchorName(CeType.MusicWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.MusicWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 601,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2q22h1',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [BANDSINTOWN]: {
    type: 'Container',
    metaData: {
      sig: 'len-434',
      pageId: 'bgjd2',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '3vq-230',
          pageId: 'bgjd2',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 600,
          x: 0,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2qya61',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '3vq-235',
            pageId: 'bgjd2',
          },
          applicationId: '8218',
          appDefinitionId: '1405ef82-0ee0-65fb-88a1-2f172aa3573c',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '3vq-234',
              pageId: 'bgjd2',
            },
            content: '{"title":"Artist Name"}',
          },
          widgetId: '1405f006-9eda-ce95-96c3-403f44d9e58a',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-2470',
            pageId: 'bgjd2',
          },
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '3vq-233',
            pageId: 'bgjd2',
          },
          style: {
            properties: {
              param_color_titleTextColor: 'color_15',
              'alpha-param_color_titleTextColor': '1',
              param_color_menuTextColor: 'color_15',
              'alpha-param_color_menuTextColor': '1',
              param_color_eventTextColor: 'color_15',
              'alpha-param_color_eventTextColor': '1',
              param_color_eventDescriptionColor: 'color_15',
              'alpha-param_color_eventDescriptionColor': '1',
              param_color_titleBackgroundColor: 'color_11',
              'alpha-param_color_titleBackgroundColor': '1',
              param_color_menuBackgroundColor: 'color_11',
              'alpha-param_color_menuBackgroundColor': '1',
              param_color_oddBackgroundColor: 'color_11',
              'alpha-param_color_oddBackgroundColor': '1',
              param_color_evenBackgroundColor: 'color_11',
              'alpha-param_color_evenBackgroundColor': '1',
              param_color_dividerColor: 'color_13',
              'alpha-param_color_dividerColor': '1',
              param_color_borderColor: 'color_15',
              'alpha-param_color_borderColor': '1',
              param_color_iconColor: 'color_15',
              'alpha-param_color_iconColor': '1',
              param_color_iconBackgroundColor: 'color_11',
              'alpha-param_color_iconBackgroundColor': '1',
              param_color_buttonsTextColor: 'color_15',
              'alpha-param_color_buttonsTextColor': '1',
              param_color_buttonStrokeColor: 'color_15',
              'alpha-param_color_buttonStrokeColor': '1',
              param_font_titleFontStyle:
                '{"family":"syne","displayName":"Heading 6","style":{"bold":false,"italic":false,"underline":false},"size":"25","preset":"Heading-S","editorKey":"font_6","fontStyleParam":true}',
              param_font_menuFontStyle:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              param_font_eventFont:
                '{"family":"questrial","displayName":"Paragraph 1","style":{"bold":false,"italic":false,"underline":false},"size":"22","preset":"Body-L","editorKey":"font_7","fontStyleParam":true}',
              param_font_eventDescriptionFont:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              param_font_trackFontStyle:
                '{"family":"questrial","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              param_font_buttonsFont:
                '{"family":"questrial","displayName":"Paragraph 1","style":{"bold":false,"italic":false,"underline":false},"size":"22","preset":"Body-L","editorKey":"font_7","fontStyleParam":true}',
              param_boolean_showTitle: 'true',
            },
            propertiesSource: {
              param_color_titleTextColor: 'theme',
              'alpha-param_color_titleTextColor': 'value',
              param_color_menuTextColor: 'theme',
              'alpha-param_color_menuTextColor': 'value',
              param_color_eventTextColor: 'theme',
              'alpha-param_color_eventTextColor': 'value',
              param_color_eventDescriptionColor: 'theme',
              'alpha-param_color_eventDescriptionColor': 'value',
              param_color_titleBackgroundColor: 'theme',
              'alpha-param_color_titleBackgroundColor': 'value',
              param_color_menuBackgroundColor: 'theme',
              'alpha-param_color_menuBackgroundColor': 'value',
              param_color_oddBackgroundColor: 'theme',
              'alpha-param_color_oddBackgroundColor': 'value',
              param_color_evenBackgroundColor: 'theme',
              'alpha-param_color_evenBackgroundColor': 'value',
              param_color_dividerColor: 'theme',
              'alpha-param_color_dividerColor': 'value',
              param_color_borderColor: 'theme',
              'alpha-param_color_borderColor': 'value',
              param_color_iconColor: 'theme',
              'alpha-param_color_iconColor': 'value',
              param_color_iconBackgroundColor: 'theme',
              'alpha-param_color_iconBackgroundColor': 'value',
              param_color_buttonsTextColor: 'theme',
              'alpha-param_color_buttonsTextColor': 'value',
              param_color_buttonStrokeColor: 'theme',
              'alpha-param_color_buttonStrokeColor': 'value',
              param_font_titleFontStyle: 'value',
              param_font_menuFontStyle: 'value',
              param_font_eventFont: 'value',
              param_font_eventDescriptionFont: 'value',
              param_font_trackFontStyle: 'value',
              param_font_buttonsFont: 'value',
              param_boolean_showTitle: 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kxx34l7h',
        mobileStructure: {
          layout: {
            width: 320,
            height: 907,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxx34l7h',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 660,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'bgjd2',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-1339',
        pageId: 'bgjd2',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-2471',
        pageId: 'bgjd2',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1346',
        pageId: 'bgjd2',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-1347',
          pageId: 'bgjd2',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1343',
        pageId: 'bgjd2',
      },
      name: getAnchorName(CeType.BandsInTownWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.BandsInTownWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 907,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2qya61',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_RESERVATIONS]: {
    type: 'Container',
    metaData: {
      sig: 'eqk-869',
      pageId: 'isycn',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '9so-29',
          pageId: 'isycn',
          basedOnSignature: '5cl-263',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1176,
          height: 260,
          x: -98,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: {
            left: {
              px: 30,
              vw: 0,
            },
            right: {
              px: 30,
              vw: 0,
            },
          },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx2r7b51',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-2467',
            pageId: 'isycn',
          },
          applicationId: '8542',
          appDefinitionId: '1475ab65-206b-d79a-856d-fa10bdb479ea',
          widgetId: '1475ab8e-8a07-922f-8f2e-ff96eb488fc1',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '5cl-264',
            pageId: 'isycn',
          },
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'eqk-610',
            pageId: 'isycn',
          },
          style: {
            properties: {
              'param_color_color-1': 'rgba(255, 255, 255, 1)',
              'param_color_color-2': 'rgba(232, 230, 230, 1)',
              'param_color_color-3': 'rgba(199, 199, 199, 1)',
              'param_color_color-4': 'rgba(89, 89, 89, 1)',
              'param_color_color-5': 'rgba(0, 0, 0, 1)',
              'param_color_color-6': 'rgba(229, 240, 254, 1)',
              'param_color_color-7': 'rgba(200, 224, 254, 1)',
              'param_color_color-8': 'rgba(0, 87, 225, 1)',
              'param_color_color-13': 'rgba(237, 88, 41, 1)',
              'param_color_color-18': 'rgba(128, 21, 232, 1)',
              param_color_background: 'rgba(255, 255, 255, 1)',
              'param_color_wixorder.border': 'rgba(199, 199, 199, 1)',
              'param_color_wixorder.menubackground': 'rgba(199, 199, 199, 1)',
              'param_color_wixorder.checkoutinputborder':
                'rgba(199, 199, 199, 1)',
              'param_color_wixorder.orderingcheckoutpopupdescription':
                'rgba(199, 199, 199, 1)',
              'param_color_wixseating-bg-fill': 'rgba(255, 255, 255, 1)',
              'param_color_wixseating-bg-border-color': 'rgba(0, 87, 225, 1)',
              'param_color_wixseating-title-color': 'rgba(0, 87, 225, 1)',
              'param_color_wixseating-subtitle-color': 'rgba(0, 0, 0, 1)',
              'param_color_wixseating-btn-fill': 'rgba(0, 87, 225, 1)',
              'param_color_wixseating-btn-txt': 'rgba(255, 255, 255, 1)',
              'param_color_wixseating-field-fill': 'rgba(255, 255, 255, 1)',
              'param_color_wixseating-field-txt': 'rgba(0, 0, 0, 1)',
              'param_color_wixseating-dropdown-bg': 'rgba(255, 255, 255, 1)',
              'param_color_wixseating-dropdown-txt': 'rgba(0, 0, 0, 1)',
              'param_color_wixseating-bg-fill-custom': 'color_11',
              'param_color_wixseating-bg-border-color-custom': 'color_18',
              'param_color_wixseating-title-color-custom': 'color_18',
              'param_color_wixseating-subtitle-color-custom': 'color_15',
              'param_color_wixseating-btn-fill-custom': 'color_18',
              'param_color_wixseating-btn-txt-custom': 'color_11',
              'param_color_wixseating-field-fill-custom': 'color_11',
              'param_color_wixseating-field-txt-custom': 'color_15',
              'param_color_wixseating-dropdown-bg-custom':
                'rgba(255, 255, 255, 1)',
              'param_color_wixseating-dropdown-txt-custom': 'rgba(0, 0, 0, 1)',
              'param_number_wixseating.layout': '1',
              'param_boolean_wixseating.description.display': 'false',
              'param_number_wixseating.alignment': '1',
              'param_number_wixseating.selected.preset': '-1',
              'param_number_wixseating-bg-border-size': '1',
              'param_number_wixseating-btn-border-size': '0',
              'param_number_wixseating-field-border-size': '1',
              'param_number_wixseating-btn-border-radius': '2',
              'param_number_wixseating-field-border-radius': '2',
              'param_number_wixseating-bg-border-size-custom': '1',
              'param_number_wixseating-btn-border-size-custom': '0',
              'param_number_wixseating-field-border-size-custom': '1',
              'param_number_wixseating-btn-border-radius-custom': '2',
              'param_number_wixseating-field-border-radius-custom': '2',
              'param_boolean_wixseating.has.custom': 'true',
              'param_font_wixseating-title-font-custom':
                '{"family":"fahkwang","displayName":"Heading 5","style":{"bold":false,"italic":false,"underline":false},"size":"27","preset":"Heading-M","editorKey":"font_5","fontStyleParam":true}',
              'param_font_wixseating-subtitle-font-custom':
                '{"family":"helvetica neue","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              'param_font_wixseating-field-font-custom':
                '{"family":"helvetica neue","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              'param_font_wixseating-btn-font-custom':
                '{"family":"helvetica neue","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":"16","preset":"Body-M","editorKey":"font_8","fontStyleParam":true}',
              'alpha-param_color_wixseating-title-color-custom': '1',
              'alpha-param_color_wixseating-bg-border-color-custom': '1',
              'alpha-param_color_wixseating-btn-fill-custom': '1',
              'alpha-param_color_wixseating-btn-txt-custom': '1',
              'alpha-param_color_wixseating-field-fill-custom': '1',
              'alpha-param_color_wixseating-subtitle-color-custom': '1',
              'alpha-param_color_wixseating-field-txt-custom': '1',
              'alpha-param_color_wixseating-bg-fill-custom': '1',
            },
            propertiesSource: {
              'param_color_color-1': 'value',
              'param_color_color-2': 'value',
              'param_color_color-3': 'value',
              'param_color_color-4': 'value',
              'param_color_color-5': 'value',
              'param_color_color-6': 'value',
              'param_color_color-7': 'value',
              'param_color_color-8': 'value',
              'param_color_color-13': 'value',
              'param_color_color-18': 'value',
              param_color_background: 'value',
              'param_color_wixorder.border': 'value',
              'param_color_wixorder.menubackground': 'value',
              'param_color_wixorder.checkoutinputborder': 'value',
              'param_color_wixorder.orderingcheckoutpopupdescription': 'value',
              'param_color_wixseating-bg-fill': 'value',
              'param_color_wixseating-bg-border-color': 'value',
              'param_color_wixseating-title-color': 'value',
              'param_color_wixseating-subtitle-color': 'value',
              'param_color_wixseating-btn-fill': 'value',
              'param_color_wixseating-btn-txt': 'value',
              'param_color_wixseating-field-fill': 'value',
              'param_color_wixseating-field-txt': 'value',
              'param_color_wixseating-dropdown-bg': 'value',
              'param_color_wixseating-dropdown-txt': 'value',
              'param_color_wixseating-bg-fill-custom': 'theme',
              'param_color_wixseating-bg-border-color-custom': 'theme',
              'param_color_wixseating-title-color-custom': 'theme',
              'param_color_wixseating-subtitle-color-custom': 'theme',
              'param_color_wixseating-btn-fill-custom': 'theme',
              'param_color_wixseating-btn-txt-custom': 'theme',
              'param_color_wixseating-field-fill-custom': 'theme',
              'param_color_wixseating-field-txt-custom': 'theme',
              'param_color_wixseating-dropdown-bg-custom': 'value',
              'param_color_wixseating-dropdown-txt-custom': 'value',
              'param_number_wixseating.layout': 'value',
              'param_boolean_wixseating.description.display': 'value',
              'param_number_wixseating.alignment': 'value',
              'param_number_wixseating.selected.preset': 'value',
              'param_number_wixseating-bg-border-size': 'value',
              'param_number_wixseating-btn-border-size': 'value',
              'param_number_wixseating-field-border-size': 'value',
              'param_number_wixseating-btn-border-radius': 'value',
              'param_number_wixseating-field-border-radius': 'value',
              'param_number_wixseating-bg-border-size-custom': 'value',
              'param_number_wixseating-btn-border-size-custom': 'value',
              'param_number_wixseating-field-border-size-custom': 'value',
              'param_number_wixseating-btn-border-radius-custom': 'value',
              'param_number_wixseating-field-border-radius-custom': 'value',
              'param_boolean_wixseating.has.custom': 'value',
              'param_font_wixseating-title-font-custom': 'value',
              'param_font_wixseating-subtitle-font-custom': 'value',
              'param_font_wixseating-field-font-custom': 'value',
              'param_font_wixseating-btn-font-custom': 'value',
              'alpha-param_color_wixseating-title-color-custom': 'value',
              'alpha-param_color_wixseating-bg-border-color-custom': 'value',
              'alpha-param_color_wixseating-btn-fill-custom': 'value',
              'alpha-param_color_wixseating-btn-txt-custom': 'value',
              'alpha-param_color_wixseating-field-fill-custom': 'value',
              'alpha-param_color_wixseating-subtitle-color-custom': 'value',
              'alpha-param_color_wixseating-field-txt-custom': 'value',
              'alpha-param_color_wixseating-bg-fill-custom': 'value',
            },
            groups: {},
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kxx37p5v',
        mobileStructure: {
          layout: {
            width: 320,
            height: 458,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxx37p5v',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 320,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'isycn',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-1382',
        pageId: 'isycn',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-2495',
        pageId: 'isycn',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      recommendedScale: 1,
      recommendedWidth: 320,
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1389',
        pageId: 'isycn',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-1390',
          pageId: 'isycn',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx2gpmq',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1386',
        pageId: 'isycn',
      },
      name: getAnchorName(CeType.RestaurantsReservationsWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.RestaurantsReservationsWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 458,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx2r7b51',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_FORMS]: {
    type: 'Container',
    metaData: {
      sig: '5cl-1393',
      pageId: 'j64ds',
    },
    components: [
      {
        type: 'Container',
        metaData: {
          sig: '5cl-1392',
          pageId: 'j64ds',
        },
        id: 'comp-kxyofzy6',
        components: [
          {
            type: 'Container',
            metaData: {
              sig: '5cl-1391',
              pageId: 'j64ds',
            },
            id: 'comp-kxyofzz11',
            components: [
              {
                type: 'Component',
                metaData: {
                  sig: '5cl-1244',
                  pageId: 'j64ds',
                },
                id: 'comp-kxyofzz31',
                skin: 'wysiwyg.viewer.skins.WRichTextThemeSkin',
                layout: {
                  width: 510,
                  height: 41,
                  x: 40,
                  y: 20,
                  scale: 1,
                  rotationInDegrees: 0,
                  fixedPosition: false,
                },
                componentType: 'wysiwyg.viewer.components.WRichText',
                parent: 'comp-kxyofzz11',
                data: {
                  type: 'StyledText',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '5cl-1238',
                    pageId: 'j64ds',
                  },
                  text: '<h5 class="font_5" style="text-align: center; font-size: 29px;"><span style="font-size:29px;">Subscribe Form</span></h5>',
                  stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                  linkList: [],
                  id: 'dataItem-kxyofzz4',
                },
                props: {
                  type: 'WRichTextProperties',
                  metaData: {
                    schemaVersion: '1.0',
                    sig: '8y4-705',
                    autoGenerated: false,
                    pageId: 'j64ds',
                  },
                  id: 'propItem-kxyofzz5',
                  isHidden: false,
                  brightness: 1,
                  packed: true,
                  verticalText: false,
                },
                connections: {
                  type: 'ConnectionList',
                  id: 'connection-kxyofzz7',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '8y4-728',
                    pageId: 'j64ds',
                  },
                  items: [
                    {
                      type: 'WixCodeConnectionItem',
                      role: 'text2',
                    },
                    {
                      type: 'ConnectionItem',
                      role: 'title_Role',
                      controllerId: 'dataItem-kxyofzz0',
                      config: '{"collectionFieldKey":""}',
                      isPrimary: true,
                    },
                  ],
                },
                style: 'txtTheme',
                mobileStructure: {
                  layout: {
                    width: 260,
                    height: 28,
                    x: 30,
                    y: 20,
                    scale: 0.74,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  props: {
                    type: 'WRichTextProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      sig: 'rt1-46',
                      autoGenerated: false,
                      pageId: 'j64ds',
                      basedOnSignature: 'rt1-23',
                    },
                    isHidden: false,
                    brightness: 1,
                    packed: true,
                    verticalText: false,
                  },
                  metaData: {
                    originalCompId: 'comp-kxyofzz31',
                    author: 'duplicate',
                  },
                },
              },
              {
                type: 'Component',
                metaData: {
                  sig: '5cl-1245',
                  pageId: 'j64ds',
                },
                id: 'comp-kxyofzz71',
                skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
                layout: {
                  width: 444,
                  height: 56,
                  x: 40,
                  y: 81,
                  scale: 1,
                  rotationInDegrees: 0,
                  fixedPosition: false,
                },
                componentType: 'wysiwyg.viewer.components.inputs.TextInput',
                parent: 'comp-kxyofzz11',
                data: {
                  type: 'TextInput',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '430-283',
                    pageId: 'j64ds',
                  },
                  value: '',
                  textType: 'email',
                  maxLength: 250,
                  pattern: '^.+@.+\\.[a-zA-Z]{2,63}$',
                  label: '',
                  placeholder: 'e.g., email@example.com',
                  id: 'dataItem-kxyofzza',
                },
                props: {
                  type: 'TextInputProperties',
                  metaData: {
                    schemaVersion: '1.0',
                    sig: '5cl-905',
                    autoGenerated: false,
                    pageId: 'j64ds',
                  },
                  id: 'propItem-kxyofzzc',
                  required: true,
                  inputHeight: 56,
                  inputHeightMobile: 53,
                  labelMargin: 15,
                  labelPadding: 1,
                  textAlignment: 'left',
                  textPadding: 24,
                  placeholder: 'e.g., email@example.com',
                  defaultTextType: 'placeholderText',
                },
                connections: {
                  type: 'ConnectionList',
                  id: 'connection-kxyofzzd1',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '430-285',
                    pageId: 'j64ds',
                  },
                  items: [
                    {
                      type: 'WixCodeConnectionItem',
                      role: 'input1',
                    },
                    {
                      type: 'ConnectionItem',
                      role: 'field_role_text',
                      controllerId: 'dataItem-kxyofzz0',
                      config:
                        '{"crmLabel":"e.g., email@example.com","fieldType":"email","crmType":"email","crmTag":"main","collectionFieldKey":"email","label":"Email"}',
                      isPrimary: true,
                      subRole: 'subRole_email',
                    },
                  ],
                },
                style: {
                  type: 'ComponentStyle',
                  id: 'style-ky1n22xh',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '5cl-945',
                    pageId: 'j64ds',
                  },
                  style: {
                    properties: {
                      'alpha-bg': '1',
                      'alpha-bgd': '1',
                      'alpha-bge': '0.45',
                      'alpha-bgf': '1',
                      'alpha-bgh': '1',
                      'alpha-brd': '1',
                      'alpha-brde': '1',
                      'alpha-brdf': '1',
                      'alpha-brdh': '1',
                      'alpha-btn_brd': '0.55',
                      bg: 'color_11',
                      bgd: '#FFFFFF',
                      bge: '#FF4040',
                      bgf: 'color_11',
                      bgh: 'color_11',
                      'boxShadowToggleOn-shd': 'false',
                      brd: '#373B4D',
                      brdd: '#DBDBDB',
                      brde: '#FF4040',
                      brdf: 'color_15',
                      brdh: '#373B4D',
                      brw: '2px',
                      brwd: '1',
                      brwe: '2px',
                      brwf: '2px',
                      brwh: '2px',
                      btn_brd: 'color_15',
                      btn_brw: '1',
                      btn_fnt: 'font_8',
                      fnt: 'italic normal normal 14px/1.4em georgia',
                      fnt2: 'font_8',
                      fntlbl: 'italic normal normal 14px/1.4em georgia',
                      rd: '0px',
                      shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                      txt: '#000000',
                      'txt-placeholder': 'color_14',
                      txt2: '#373B4D',
                      txtd: '#DBDBDB',
                      txtlbl: '#373B4D',
                      txtlblrq: '#373B4D',
                    },
                    propertiesSource: {
                      'alpha-bg': 'value',
                      'alpha-bgd': 'value',
                      'alpha-bge': 'value',
                      'alpha-bgf': 'value',
                      'alpha-bgh': 'value',
                      'alpha-brd': 'value',
                      'alpha-brde': 'value',
                      'alpha-brdf': 'value',
                      'alpha-brdh': 'value',
                      'alpha-btn_brd': 'value',
                      bg: 'theme',
                      bgd: 'value',
                      bge: 'value',
                      bgf: 'theme',
                      bgh: 'theme',
                      'boxShadowToggleOn-shd': 'value',
                      brd: 'value',
                      brdd: 'value',
                      brde: 'value',
                      brdf: 'theme',
                      brdh: 'value',
                      brw: 'value',
                      brwd: 'value',
                      brwe: 'value',
                      brwf: 'value',
                      brwh: 'value',
                      btn_brd: 'theme',
                      btn_brw: 'value',
                      btn_fnt: 'theme',
                      fnt: 'value',
                      fnt2: 'theme',
                      fntlbl: 'value',
                      rd: 'value',
                      shd: 'value',
                      txt: 'value',
                      'txt-placeholder': 'theme',
                      txt2: 'value',
                      txtd: 'value',
                      txtlbl: 'value',
                      txtlblrq: 'value',
                    },
                    groups: {},
                  },
                  componentClassName:
                    'wysiwyg.viewer.components.inputs.TextInput',
                  pageId: '',
                  compId: 'comp-kxyofzz71',
                  styleType: 'custom',
                  skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
                },
                mobileStructure: {
                  layout: {
                    width: 269,
                    height: 53,
                    x: 30,
                    y: 68,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  metaData: {
                    originalCompId: 'comp-kxyofzz71',
                    author: 'duplicate',
                  },
                },
              },
              {
                type: 'Component',
                metaData: {
                  sig: '5cl-1246',
                  pageId: 'j64ds',
                },
                id: 'comp-kxyofzze',
                skin: 'wysiwyg.viewer.skins.button.BasicButton',
                layout: {
                  width: 73,
                  height: 56,
                  x: 478,
                  y: 81,
                  scale: 1,
                  rotationInDegrees: 0,
                  fixedPosition: false,
                },
                componentType: 'wysiwyg.viewer.components.SiteButton',
                parent: 'comp-kxyofzz11',
                data: {
                  type: 'LinkableButton',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '8y4-715',
                    pageId: 'j64ds',
                  },
                  label: 'Join',
                  link: {
                    type: 'FormSubmitButtonLink',
                    metaData: {
                      isPreset: false,
                      schemaVersion: '1.0',
                      isHidden: false,
                      sig: '8y4-716',
                      pageId: 'j64ds',
                    },
                  },
                  id: 'dataItem-kxyofzzg',
                },
                props: {
                  type: 'ButtonProperties',
                  metaData: {
                    schemaVersion: '1.0',
                    sig: '8y4-717',
                    pageId: 'j64ds',
                  },
                  id: 'propItem-kxyofzzi',
                  align: 'center',
                  margin: 0,
                },
                connections: {
                  type: 'ConnectionList',
                  id: 'connection-kxyofzzi2',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '8y4-729',
                    pageId: 'j64ds',
                  },
                  items: [
                    {
                      type: 'WixCodeConnectionItem',
                      role: 'button1',
                    },
                    {
                      type: 'ConnectionItem',
                      role: 'button_Role',
                      controllerId: 'dataItem-kxyofzz0',
                      config: '{"collectionFieldKey":""}',
                      isPrimary: true,
                    },
                  ],
                },
                style: {
                  type: 'ComponentStyle',
                  id: 'style-ky1n1gsq',
                  metaData: {
                    isPreset: true,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '5cl-940',
                    pageId: 'j64ds',
                  },
                  style: {
                    properties: {
                      'boxShadowToggleOn-shd': 'false',
                      'alpha-txt': '1',
                      bgh: 'color_11',
                      'alpha-brdh': '1',
                      bg: 'color_15',
                      'alpha-brd': '1',
                      brw: '1',
                      'alpha-txth': '1',
                      fnt: 'font_8',
                      shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
                      txt: 'color_11',
                      rd: '0px',
                      brdh: 'color_15',
                      brd: 'color_15',
                      'alpha-bg': '1',
                      'alpha-bgh': '0',
                      txth: 'color_15',
                    },
                    propertiesSource: {
                      'boxShadowToggleOn-shd': 'value',
                      'alpha-txt': 'value',
                      bgh: 'theme',
                      'alpha-brdh': 'value',
                      bg: 'theme',
                      'alpha-brd': 'value',
                      brw: 'value',
                      'alpha-txth': 'value',
                      fnt: 'theme',
                      shd: 'value',
                      txt: 'theme',
                      rd: 'value',
                      brdh: 'theme',
                      brd: 'theme',
                      'alpha-bg': 'value',
                      'alpha-bgh': 'value',
                      txth: 'theme',
                    },
                    groups: {},
                  },
                  componentClassName: 'wysiwyg.viewer.components.SiteButton',
                  pageId: '',
                  compId: '',
                  styleType: 'custom',
                  skin: 'wysiwyg.viewer.skins.button.BasicButton',
                },
                mobileStructure: {
                  layout: {
                    width: 120,
                    height: 42,
                    x: 100,
                    y: 131,
                    scale: 1,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  metaData: {
                    originalCompId: 'comp-kxyofzze',
                    author: 'duplicate',
                  },
                },
              },
              {
                type: 'Component',
                metaData: {
                  sig: '5cl-1390',
                  pageId: 'j64ds',
                },
                id: 'comp-kxyofzzj',
                skin: 'wysiwyg.viewer.skins.WRichTextThemeSkin',
                layout: {
                  width: 309,
                  height: 24,
                  x: 135,
                  y: 155,
                  scale: 1,
                  rotationInDegrees: 0,
                  fixedPosition: false,
                },
                componentType: 'wysiwyg.viewer.components.WRichText',
                parent: 'comp-kxyofzz11',
                data: {
                  type: 'StyledText',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '5cl-1384',
                    pageId: 'j64ds',
                  },
                  text: '<p class="font_8" style="text-align: center; font-size: 17px;"><span style="font-size:17px;">Thanks for subscribing!</span></p>',
                  stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
                  linkList: [],
                  id: 'dataItem-kxyofzzk',
                },
                props: {
                  type: 'WRichTextProperties',
                  metaData: {
                    schemaVersion: '1.0',
                    sig: '8y4-723',
                    autoGenerated: false,
                    pageId: 'j64ds',
                  },
                  id: 'propItem-kxyofzzk1',
                  isHidden: false,
                  brightness: 1,
                  packed: true,
                  verticalText: false,
                },
                connections: {
                  type: 'ConnectionList',
                  id: 'connection-kxyofzzl',
                  metaData: {
                    isPreset: false,
                    schemaVersion: '1.0',
                    isHidden: false,
                    sig: '8y4-727',
                    pageId: 'j64ds',
                  },
                  items: [
                    {
                      type: 'WixCodeConnectionItem',
                      role: 'text1',
                    },
                    {
                      type: 'ConnectionItem',
                      role: 'message_Role',
                      controllerId: 'dataItem-kxyofzz0',
                      config: '{"collectionFieldKey":""}',
                      isPrimary: true,
                    },
                  ],
                },
                style: 'txtTheme',
                mobileStructure: {
                  layout: {
                    width: 260,
                    height: 20,
                    x: 30,
                    y: 183,
                    scale: 0.82353,
                    rotationInDegrees: 0,
                    fixedPosition: false,
                  },
                  props: {
                    type: 'WRichTextProperties',
                    metaData: {
                      schemaVersion: '1.0',
                      sig: 'rt1-47',
                      autoGenerated: false,
                      pageId: 'j64ds',
                      basedOnSignature: 'rt1-24',
                    },
                    isHidden: false,
                    brightness: 1,
                    packed: true,
                    verticalText: false,
                  },
                  metaData: {
                    originalCompId: 'comp-kxyofzzj',
                    author: 'duplicate',
                  },
                },
              },
            ],
            skin: 'wysiwyg.viewer.skins.FormContainerSkin',
            layout: {
              width: 593,
              height: 199,
              x: 0,
              y: 0,
              scale: 1,
              rotationInDegrees: 0,
              fixedPosition: false,
            },
            componentType: 'wysiwyg.viewer.components.FormContainer',
            parent: 'comp-kxyofzy6',
            connections: {
              type: 'ConnectionList',
              id: 'connection-kxyofzz3',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '5cl-904',
                pageId: 'j64ds',
              },
              items: [
                {
                  type: 'WixCodeConnectionItem',
                  role: 'form1',
                },
                {
                  type: 'ConnectionItem',
                  role: 'form_Role',
                  controllerId: 'dataItem-kxyofzz0',
                  config:
                    '{"preset":"subscribers08","labels":["contacts-contacted_me"],"emailId":"","columns":2,"errorMessage":"Fill in missing fields.","secondsToResetForm":8,"successActionType":"SHOW_MESSAGE","styles":{"input":{"bg":"color_11","bge":"color_11","bgf":"color_11","bgh":"color_11","brd":"color_15","brde":"#f60419","brdf":"color_18","brdh":"color_15","fnt":"font_8","txt":"color_15","txt2":"color_14"},"box":{"bg":"color_11"}},"formName":"Subscribe Form","plugins":[{"id":"get-subscribers"}],"textPadding":24,"spaceBetweenRows":10,"spaceBetweenCols":12,"paymentStatus":"get-started","showFieldsTitle":true,"labelKeys":[null,"custom.subscribe-to-our-newsletter-dont-miss-out"],"formLabelKey":"custom.subscribe-to-our-newsletter-dont-miss-out","collectionId":"comp-kxyofzz11_subscribers08","theme":"preset01"}',
                  isPrimary: true,
                },
              ],
            },
            style: {
              type: 'ComponentStyle',
              id: 'style-kxyofzz2',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                sig: '5cl-845',
                pageId: 'j64ds',
              },
              style: {
                properties: {
                  bg: 'color_11',
                },
                propertiesSource: {
                  bg: 'theme',
                },
              },
              componentClassName: 'wysiwyg.viewer.components.FormContainer',
              styleType: 'custom',
              skin: 'wysiwyg.viewer.skins.FormContainerSkin',
            },
            mobileStructure: {
              layout: {
                width: 320,
                height: 223,
                x: 0,
                y: 0,
                scale: 1,
                rotationInDegrees: 0,
                fixedPosition: false,
              },
              metaData: {
                originalCompId: 'comp-kxyofzz11',
                author: 'duplicate',
              },
            },
          },
        ],
        layout: {
          width: 593,
          height: 199,
          x: 193,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'platform.components.AppWidget',
        parent: 'comp-kxyoc2zs',
        data: {
          type: 'AppController',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '8y4-696',
            pageId: 'j64ds',
          },
          applicationId: '14ce1214-b278-a7e4-1373-00cebd1bef7c',
          name: 'wix-forms-controller',
          controllerType: 'getSubscribers',
          id: 'dataItem-kxyofzz0',
        },
        mobileHints: {
          type: 'MobileHints',
          id: 'mobileHints-kxyp5i4p',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'eqk-589',
            pageId: 'j64ds',
          },
        },
        connections: {
          type: 'ConnectionList',
          id: 'connection-kxyofzz1',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '8y4-697',
            pageId: 'j64ds',
          },
          items: [
            {
              type: 'WixCodeConnectionItem',
              role: 'getSubscribers1',
            },
          ],
        },
        style: 'appWidget1',
        custom: {
          state: 'duplicatable-get-subscribers',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 223,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxyofzy6',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 199,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'j64ds',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: '8y4-43',
        pageId: 'j64ds',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '430-257',
        pageId: 'j64ds',
      },
      offsetX: 0,
      offsetY: 0,
      offsetOrigin: 'leftTop',
      orderIndex: 0,
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '127-727',
        pageId: 'j64ds',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: '8y4-56',
          pageId: 'j64ds',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxyppgpo',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1386',
        pageId: 'isycn',
      },
      name: getAnchorName(CeType.GetSubscribersWidget),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.GetSubscribersWidget,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 223,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxyoc2zs',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
  [WIX_EVENTS]: {
    type: 'Container',
    metaData: {
      sig: '3vq-217',
      pageId: 'lbw24',
    },
    components: [
      {
        type: 'Component',
        metaData: {
          sig: '5yl-56',
          pageId: 'lbw24',
          basedOnSignature: 'eqk-583',
        },
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1116,
          height: 766,
          x: -68,
          y: 30,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          docked: {
            left: {
              px: 0,
              vw: 0,
            },
            right: {
              px: 0,
              vw: 0,
            },
          },
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        parent: 'comp-kxx32bu4',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-2159',
            pageId: 'lbw24',
          },
          applicationId: '7140',
          appDefinitionId: '140603ad-af8d-84a5-2c80-a0f60cb47351',
          tpaData: {
            type: 'TPAData',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              sig: '127-196',
              pageId: 'lbw24',
            },
            content:
              '{"presetId":"cards","___settingsEvent":{"event":"reset","payload":{"id":1641215236593,"value":{}}}}',
          },
          widgetId: '1440e92d-47d8-69be-ade7-e6de40127106',
        },
        mobileHints: {
          type: 'MobileHints',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: '3vq-402',
            pageId: 'lbw24',
          },
        },
        style: {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            sig: 'u5s-2155',
            pageId: 'lbw24',
          },
          style: {
            properties: {
              param_number_cardsPerRow: '3',
              param_number_listLayout: '3',
              param_number_cardDateAndLocationFontSize: '16',
              param_boolean_listShowMainTitle: 'false',
              param_number_widgetType: '2',
            },
            propertiesSource: {
              param_number_cardsPerRow: 'value',
              param_number_listLayout: 'value',
              param_number_cardDateAndLocationFontSize: 'value',
              param_boolean_listShowMainTitle: 'value',
              param_number_widgetType: 'value',
            },
          },
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        originCompId: 'comp-kxx32mbw',
        mobileStructure: {
          layout: {
            width: 320,
            height: 1329,
            x: 0,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-kxx32mbw',
            author: 'duplicate',
          },
        },
      },
    ],
    layout: {
      width: 980,
      height: 826,
      x: 0,
      y: 0,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.viewer.components.ClassicSection',
    parent: 'lbw24',
    props: {
      type: 'ClassicSectionProperties',
      metaData: {
        schemaVersion: '1.0',
        sig: 'u5s-2127',
        pageId: 'lbw24',
      },
    },
    mobileHints: {
      type: 'MobileHints',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '430-266',
        pageId: 'lbw24',
      },
    },
    design: {
      type: 'MediaContainerDesignData',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-2306',
        pageId: 'lbw24',
      },
      background: {
        type: 'BackgroundMedia',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          sig: 'u5s-2304',
          pageId: 'lbw24',
        },
        color: '{color_11}',
        colorOpacity: 1,
        alignType: 'center',
        fittingType: 'fill',
        scrollType: 'none',
        colorOverlay: '',
        colorOverlayOpacity: 0,
        showOverlayForMediaType: 'WixVideo',
      },
      charas: 'design-kxx33ovx',
      dataChangeBehaviors: [],
    },
    anchor: {
      type: 'AnchorInfo',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: 'u5s-1386',
        pageId: 'isycn',
      },
      name: getAnchorName(CeType.EventsSingle),
    },
    contentRole: {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: CeType.EventsSingle,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        sig: '6eb-47',
        pageId: 'c1dmp',
      },
    },
    mobileStructure: {
      layout: {
        width: 320,
        height: 1329,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      metaData: {
        originalCompId: 'comp-kxx32bu4',
        author: 'duplicate',
      },
    },
    activeModes: {},
  },
};

function getProGalleryPositionConfig(structureId: string): PositionCfg {
  let positionCfg;
  const appCeType = {
    appCeType: CeType.ProGalleryWidget,
  };
  switch (structureId) {
    case 'a28c5e5ea1945e5fa4ca1728':
      positionCfg = {
        positionCeType: CeType.Welcome,
        position: POSITION.AFTER,
      };
      break;
    case 'a8c41ae5ef3d8694abd189b1':
      positionCfg = {
        positionCeType: CeType.Client,
        position: POSITION.AFTER,
      };
      break;
    default:
      positionCfg = {
        positionCeType: CeType.Contact,
        position: POSITION.BEFORE,
      };
      break;
  }
  return { ...appCeType, ...positionCfg };
}

export function appendProGalleryIfNeeded(widgetsDefIds: string[]): void {
  const { structureId } = window.siteCreationController;
  if (PRO_GALLERY_STRUCTURE_IDS.includes(structureId)) {
    widgetsDefIds.push(WIX_PRO_GALLERY);
  }
}

const checkCeTypeExists = (
  ceTypeToSectionIndex: CeTypeToSection,
  ceType: CeType,
) => ceTypeToSectionIndex[ceType] !== undefined;

export const getWidgetPositionCfg = (
  appDefId: string,
  ceTypeToSectionIndex: CeTypeToSection = {},
): PositionCfg => {
  const { structureId } = window.siteCreationController;
  const isBlogAsHomepage = BLOG_AS_HOMEPAGE_STRUCTURE_IDS.includes(structureId);

  const widgetsPositionsCfg: AppIdToPositionCfg = {
    [WIX_STORES]: {
      appCeType: CeType.StoresGridGalleryWidget,
      positionCeType: checkCeTypeExists(ceTypeToSectionIndex, CeType.Product)
        ? CeType.Product
        : CeType.Welcome,
      position: POSITION.AFTER,
      removeSection: CeType.Product,
      blockingStructureIds: BLOG_AS_HOMEPAGE_STRUCTURE_IDS,
    },
    [WIX_BOOKINGS]: {
      appCeType: CeType.BookingsWidget,
      positionCeType: checkCeTypeExists(ceTypeToSectionIndex, CeType.Service)
        ? CeType.Service
        : CeType.Contact,
      removeSection: CeType.Service,
      position: POSITION.AFTER,
    },
    [WIX_BLOG]: {
      appCeType: CeType.NewBlogCustomFeed,
      positionCeType: isBlogAsHomepage ? CeType.Welcome : CeType.Contact,
      position: POSITION.AFTER,
      blockingApps: isBlogAsHomepage ? [] : [WIX_STORES],
    },
    [INSTAGRAM_FEED]: {
      appCeType: CeType.Instagram_Widget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [WIX_GROUPS]: {
      appCeType: CeType.Widget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [WIX_FORMS]: {
      appCeType: CeType.GetSubscribersWidget,
      positionCeType: CeType.Contact,
      position: POSITION.LAST,
    },
    [WIX_RESERVATIONS]: {
      appCeType: CeType.RestaurantsReservationsWidget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [BANDSINTOWN]: {
      appCeType: CeType.BandsInTownWidget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [WIX_VIDEO]: {
      appCeType: CeType.VideoWidget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [WIX_EVENTS]: {
      appCeType: CeType.EventsSingle,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [WIX_MUSIC]: {
      appCeType: CeType.MusicWidget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [WIX_PRO_GALLERY]: getProGalleryPositionConfig(structureId),
  };

  return widgetsPositionsCfg[appDefId];
};

import constants from '#packages/constants';
import { panels } from '#packages/stateManagement';
import { cx, hoc, sections } from '#packages/util';
import { PanelHeader } from '@wix/wix-base-ui';
import React, { type FC, useEffect } from 'react';
import { PanelCloseOrigin } from '#packages/panels';
import styles from './leftPanelFrame.scss';
import type { DispatchProps } from './leftPanelFrameMapper';
import { mapDispatchToProps } from './leftPanelFrameMapper';

export interface OwnProps {
  panelName: string;
  panelIndex?: number;
  panelClass: string;
  automationId?: string;
  label?: string | React.ReactNode;
  styleOverride?: React.CSSProperties;
  className?: string;
  fullHeight?: boolean;
  dynamicWidth?: boolean;
  hideHeader?: boolean;
  helpId?: string;
  onContextMenu?: React.MouseEventHandler;
  sidePanel?: React.ReactNode; // Allows for custom component to be rendered to the right of the panel
  headerActionsBar?: React.ReactNode;
  closeSymbol?: React.ReactNode;
  helpSymbol?: React.ReactNode;
  frameContentStyle?: React.CSSProperties;
  onHelpClicked?(): void;
  onBackClicked?(): void;
  onClose?(): void;
  backTooltipContent?: string;
  removeDivider?: boolean;
  showHeader?: boolean;
  headerTheme?: 'standard' | 'ai' | 'destructive' | 'premium';
  subtitle?: React.ReactNode;
}

interface WithPanelFrameProps {
  blockOuterScroll: React.MouseEventHandler;
  onContextMenu?: React.MouseEventHandler;
  close(origin?: string): void;
  openHelpCenter(helpId: string, props?: object, biHelpParams?: object): void;
  helpId?: string;
  id?: string;
}

interface LeftPanelFrameProps
  extends OwnProps,
    DispatchProps,
    WithPanelFrameProps {}

const LeftPanelFrame: FC<LeftPanelFrameProps> = ({
  closeOpenedLeftPanel,
  unhighlightMenu,
  label,
  close,
  openHelpCenter,
  helpId,
  onHelpClicked,
  onBackClicked,
  headerActionsBar,
  headerTheme,
  closeSymbol,
  helpSymbol,
  styleOverride,
  panelClass,
  dynamicWidth,
  automationId,
  blockOuterScroll,
  onContextMenu,
  hideHeader,
  id,
  frameContentStyle,
  className,
  children,
  sidePanel,
  backTooltipContent,
  removeDivider,
  showHeader,
  subtitle,
}) => {
  useEffect(() => {
    closeOpenedLeftPanel();
    unhighlightMenu();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = () => close(PanelCloseOrigin.HEADER_CLOSE_BUTTON);

  const renderTitle = () =>
    label || showHeader ? (
      <PanelHeader
        onClose={onClose}
        // @ts-expect-error
        onHelp={openHelpCenter}
        onBack={onBackClicked}
        noHelpBtn={!helpId && !onHelpClicked}
        actionsBar={headerActionsBar}
        closeSymbol={closeSymbol}
        helpSymbol={helpSymbol}
        className="left-panel-frame-header"
        backTooltipContent={backTooltipContent}
        removeDivider={removeDivider}
        subtitle={subtitle || null}
        theme={headerTheme}
      >
        {label}
      </PanelHeader>
    ) : (
      <PanelHeader
        noHelpBtn
        noCloseBtn
        className={styles.emptyHeader}
        removeDivider={removeDivider}
      />
    );

  const panelClassName = cx({
    [styles.leftPanelFrame]: true,
    [panelClass]: true,
  });

  const panelContentClassName = cx('left-panel-frame-content', className);

  return (
    <div
      style={styleOverride}
      className={panelClassName}
      data-dynamic-width={dynamicWidth || sections.isSectionsEnabled()}
      data-hook="left-panel-frame"
      data-aid={automationId}
      onWheel={blockOuterScroll}
      onContextMenu={onContextMenu}
    >
      <div className={styles.contentContainer}>
        {hideHeader ? null : renderTitle()}
        <div
          id={id}
          style={frameContentStyle}
          className={panelContentClassName}
        >
          {children}
        </div>
      </div>
      {sidePanel ?? null}
    </div>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(
  panels.hoc.panelFrame(constants.PANEL_TYPES.LEFT)(LeftPanelFrame),
) as React.ComponentType<OwnProps>;

import React from 'react';
import * as BaseUI from '#packages/baseUI';
import {
  Composites,
  Divider,
  InfoIcon,
  Slider,
  TextLabel,
  ToggleSwitch,
} from '@wix/wix-base-ui';

import { CUSTOMIZE_ALL_DEFAULTS, EFFECT_LIMITS, EffectTypes } from '../effects';
import type { IEffectsPanelProps } from '../effectsPanel';
import type { ITransformationData } from '../../../interactionsTransformFacade';

import {
  ALIGNMENT_MODES_XY,
  getOriginFromValue,
  getSelectedValueFromOrigin,
} from './customizeUtils';
import * as coreBi from '#packages/coreBi';

type IProps = Pick<
  IEffectsPanelProps,
  'customTransform' | 'transformation' | 'sendBI' | 'variantId'
>;

interface IState {
  keepScaleProportions: boolean;
  verticalScaleValue: number;
  horizontalScaleValue: number;
  horizontalSkewValue: number;
  verticalSkewValue: number;
  rotateValue: number;
}

export class CustomizeAllPanel extends React.Component<IProps, IState> {
  constructor(props: AnyFixMe) {
    super(props);

    this.state = {
      keepScaleProportions:
        this.props.transformation.scale.x === this.props.transformation.scale.y,
      verticalScaleValue: this.props.transformation.scale.y,
      horizontalScaleValue: this.props.transformation.scale.x,
      horizontalSkewValue: this.props.transformation.skew.x,
      verticalSkewValue: this.props.transformation.skew.y,
      rotateValue: this.props.transformation.rotate,
    };
  }

  setTransform(transformationData: ITransformationData) {
    this.props.customTransform({
      ...this.props.transformation,
      ...transformationData,
    });
  }

  handleAlignmentChange = (value: string) => {
    const origin = getOriginFromValue(value);
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.CUSTOM,
        parameter_name: 'effect_origin',
        old_value: this.props.transformation.origin as AnyFixMe, // NOTE: valid type is 'string' | 'number' | 'boolean'
        new_value: value,
      },
    );
    this.setTransform({ origin });
  };

  handleScaleChange = (value: number) => {
    const scale = value / 100;
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.CUSTOM,
        parameter_name: 'scale',
        old_value: this.props.transformation.scale.x,
        new_value: scale,
      },
    );
    this.setTransform({ scale: { x: scale, y: scale } });
    this.setState({ horizontalScaleValue: scale, verticalScaleValue: scale });
  };

  handleHorizontalSkewChange = (value: number) => {
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.CUSTOM,
        parameter_name: 'hor_skew',
        old_value: this.props.transformation.skew.x,
        new_value: value,
      },
    );
    this.setTransform({
      skew: {
        x: value,
        y: this.props.transformation.skew.y,
      },
    });
    this.setState({ horizontalSkewValue: value });
  };

  handleVerticalSkewChange = (value: number) => {
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.CUSTOM,
        parameter_name: 'ver_skew',
        old_value: this.props.transformation.skew.y,
        new_value: value,
      },
    );
    this.setTransform({
      skew: {
        x: this.props.transformation.skew && this.props.transformation.skew.x,
        y: value,
      },
    });
    this.setState({ verticalSkewValue: value });
  };

  handleRotateChange = (value: number) => {
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.CUSTOM,
        parameter_name: 'rotation_value',
        old_value: this.props.transformation.rotate,
        new_value: value,
      },
    );
    this.setTransform({ rotate: value });
    this.setState({ rotateValue: value });
  };

  handleHorizontalScaleChange = (value: number) => {
    const horizontalScaleValue = value / 100;
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.CUSTOM,
        parameter_name: 'horizontal-scale',
        old_value: this.props.transformation.scale.x,
        new_value: horizontalScaleValue,
      },
    );
    this.setTransform({
      scale: { x: horizontalScaleValue, y: this.props.transformation.scale.y },
    });
    this.setState({ horizontalScaleValue });
  };

  handleVerticalScaleChange = (value: number) => {
    const verticalScaleValue = value / 100;
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.CUSTOM,
        parameter_name: 'horizontal-scale',
        old_value: this.props.transformation.scale.x,
        new_value: verticalScaleValue,
      },
    );
    this.setTransform({
      scale: { x: this.props.transformation.scale.x, y: verticalScaleValue },
    });
    this.setState({ verticalScaleValue });
  };

  toggleKeepScaleProportions = (value: boolean) => {
    const scaleY = this.props.transformation.scale.y;
    const scaleX = this.props.transformation.scale.x;
    if (scaleY !== scaleX && value) {
      this.setState({
        keepScaleProportions: value,
        verticalScaleValue: CUSTOMIZE_ALL_DEFAULTS.scale.y,
        horizontalScaleValue: CUSTOMIZE_ALL_DEFAULTS.scale.x,
      });
      this.setTransform({
        scale: {
          x: CUSTOMIZE_ALL_DEFAULTS.scale.x,
          y: CUSTOMIZE_ALL_DEFAULTS.scale.y,
        },
      });
    } else {
      this.setState({
        keepScaleProportions: value,
        verticalScaleValue: scaleY,
        horizontalScaleValue: scaleX,
      });
    }
  };

  render() {
    const {
      keepScaleProportions,
      verticalScaleValue,
      horizontalScaleValue,
      horizontalSkewValue,
      verticalSkewValue,
      rotateValue,
    } = this.state;
    const { origin } = this.props.transformation;
    const originValue = getSelectedValueFromOrigin(origin);

    return (
      <div className="customize-effect-panel" data-aid="customize-effect-panel">
        <Divider long={true} />
        <BaseUI.sectionDividerLabeled
          label="interactions_effects_custom_scale_section_title"
          infoText="interactions_effects_custom_scale_section_tooltip"
        />
        <Divider long={true} />

        <Composites.ToggleSwitch
          key="keepScaleProportionsToggle"
          className="keep-scale-proportions-toggle"
        >
          <ToggleSwitch
            value={keepScaleProportions}
            label="interactions_effects_custom_scale_proportions_label"
            onChange={this.toggleKeepScaleProportions}
          />
        </Composites.ToggleSwitch>

        <Divider long={true} />

        {!keepScaleProportions && (
          <Composites.SliderLabeled key="horizontal-scale">
            <InfoIcon text="interactions_effects_custom_scale_tooltip" />
            <TextLabel value="interactions_effects_custom_scale_horizontal" />
            <Slider
              value={Math.round(horizontalScaleValue * 100)}
              onChange={this.handleHorizontalScaleChange}
              unit="percent"
              min={EFFECT_LIMITS.SHRINK.MIN}
              max={EFFECT_LIMITS.GROW.MAX}
              inputMax={EFFECT_LIMITS.GROW.INPUT_MAX}
              step={1}
            />
          </Composites.SliderLabeled>
        )}
        {!keepScaleProportions && (
          <Composites.SliderLabeled key="vertical-scale">
            <InfoIcon text="interactions_effects_custom_scale_tooltip" />
            <TextLabel value="interactions_effects_custom_scale_vertical" />
            <Slider
              value={Math.round(verticalScaleValue * 100)}
              onChange={this.handleVerticalScaleChange}
              unit="percent"
              min={EFFECT_LIMITS.SHRINK.MIN}
              max={EFFECT_LIMITS.GROW.MAX}
              inputMax={EFFECT_LIMITS.GROW.INPUT_MAX}
              step={1}
            />
          </Composites.SliderLabeled>
        )}

        {keepScaleProportions && (
          <Composites.SliderLabeled key="scale">
            <InfoIcon text="interactions_effects_custom_scale_tooltip" />
            <TextLabel value="interactions_effects_custom_scale_label" />
            <Slider
              value={Math.round(horizontalScaleValue * 100)}
              onChange={this.handleScaleChange}
              unit="percent"
              min={EFFECT_LIMITS.SHRINK.MIN}
              max={EFFECT_LIMITS.GROW.MAX}
              inputMax={EFFECT_LIMITS.GROW.INPUT_MAX}
              step={1}
            />
          </Composites.SliderLabeled>
        )}

        <Divider long={true} />
        <BaseUI.sectionDividerLabeled label="interactions_effects_custom_transform_section_title" />
        <Divider long={true} />

        <Composites.SliderLabeled key="rotate">
          <InfoIcon text="interactions_effects_custom_rotate_tooltip" />
          <TextLabel value="interactions_effects_custom_rotate_label" />
          <Slider
            value={rotateValue}
            onChange={this.handleRotateChange}
            unit={'angle'}
            min={EFFECT_LIMITS.ROTATE.CUSTOM_MIN}
            max={EFFECT_LIMITS.ROTATE.MAX}
            inputMax={EFFECT_LIMITS.ROTATE.INPUT_MAX}
            step={1}
          />
        </Composites.SliderLabeled>

        <Divider long={true} />

        <Composites.SliderLabeled key="horizontal-skew">
          <TextLabel value="interactions_effects_custom_skew_horizontal_label" />
          <Slider
            value={horizontalSkewValue}
            onChange={this.handleHorizontalSkewChange}
            unit={'angle'}
            min={EFFECT_LIMITS.SKEW.MIN}
            max={EFFECT_LIMITS.SKEW.MAX}
            step={1}
          />
        </Composites.SliderLabeled>

        <Divider long={true} />

        <Composites.SliderLabeled key="vertical-skew">
          <TextLabel value="interactions_effects_custom_skew_vertical_label" />
          <Slider
            value={verticalSkewValue}
            onChange={this.handleVerticalSkewChange}
            unit={'angle'}
            min={EFFECT_LIMITS.SKEW.MIN}
            max={EFFECT_LIMITS.SKEW.MAX}
            step={1}
          />
        </Composites.SliderLabeled>

        <Divider long={true} />
        <BaseUI.sectionDividerLabeled label="interactions_effects_custom_starting_point_section_title" />
        <Divider long={true} />

        <BaseUI.alignment
          options={ALIGNMENT_MODES_XY}
          label="interactions_effects_custom_starting_point_apply_effect_label"
          value={originValue}
          onChange={this.handleAlignmentChange}
          infoIconProps={{
            text: 'interactions_effects_custom_starting_point_apply_effect_tooltip',
          }}
        />
      </div>
    );
  }
}

import _ from 'lodash';
import React, { useState, useMemo } from 'react';
import { Text, TextInputMultiline } from '@wix/wix-base-ui';
import {
  feedbackFormButtonClick,
  feedbackFormResult,
  feedbackFormStartTyping,
} from '@wix/bi-logger-editor/v2';

import { useTranslation } from 'react-i18next';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as panels from '#packages/panels';
import { hoc, biLogger } from '#packages/util';
import { FacesRating } from '#packages/baseUI';

import {
  dataHooks,
  defaultTranslationKeys,
  FeedbackPanelCloseSource,
  HELP_ID,
  ratingToBiNameMap,
} from './feedbackPanel.constants';
import styles from './feedbackPanel.scss';

import type { MapDispatchToProps } from 'types/redux';

type Translations = typeof defaultTranslationKeys;

export interface FeedbackPanelResult {
  closeSource: FeedbackPanelCloseSource;
}

export interface FeedbackPanelOwnProps {
  origin?: string;
  onClose?: (data: FeedbackPanelResult) => void;
  panelName?: string;
  translationsKeysOverrides?: {
    [k in keyof typeof defaultTranslationKeys]?: (typeof defaultTranslationKeys)[k];
  };
}

interface FeedBackPanelDispatchProps {
  closePanel: (closeOrigin: FeedbackPanelCloseSource) => void;
  showSuccessNotification: () => void;
  openHelpCenter: (helpId: string) => void;
}

interface FeedbackPanelProps
  extends FeedbackPanelOwnProps,
    FeedBackPanelDispatchProps {}

const FeedbackPanel: React.FC<FeedbackPanelProps> = ({
  panelName,
  closePanel,
  onClose,
  translationsKeysOverrides,
  origin,
  showSuccessNotification,
  openHelpCenter,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [rating, setRating] = useState<number | undefined>(undefined);
  const [translate] = useTranslation();

  const translationsKeys: Translations = {
    ...defaultTranslationKeys,
    ...translationsKeysOverrides,
  };

  const close = (closeSource: FeedbackPanelCloseSource) => {
    closePanel(closeSource);
    onClose?.({ closeSource });
  };

  const handleCloseButtonClicked = () => {
    biLogger.report(
      feedbackFormButtonClick({
        questionId: origin,
        buttonType: 'finalAction',
        buttonName: FeedbackPanelCloseSource.HeaderCloseButton,
      }),
    );
    close(FeedbackPanelCloseSource.HeaderCloseButton);
  };
  const handleOutsideClicked = () => {
    close(FeedbackPanelCloseSource.OuterOverlay);
  };
  const handleEscPressed = () => {
    close(FeedbackPanelCloseSource.KeyboardEscapeKey);
  };

  const sendUserStartTyping = useMemo(() => {
    return _.once(() => {
      biLogger.report(
        feedbackFormStartTyping({
          questionId: origin,
        }),
      );
    });
  }, [origin]);

  const handleInputChanged = (newValue: string) => {
    sendUserStartTyping();
    setInputValue(newValue);
  };

  const handleSubmitButtonClicked = () => {
    biLogger.report(
      feedbackFormButtonClick({
        questionId: origin,
        buttonType: 'finalAction',
        buttonName: FeedbackPanelCloseSource.FooterSubmitButton,
      }),
    );
    biLogger.report(
      feedbackFormResult({
        questionId: origin,
        score_string: ratingToBiNameMap[rating],
        review: inputValue,
      }),
    );
    close(FeedbackPanelCloseSource.FooterSubmitButton);
    showSuccessNotification();
  };
  const handleCancelButtonClicked = () => {
    biLogger.report(
      feedbackFormButtonClick({
        questionId: origin,
        buttonType: 'finalAction',
        buttonName: FeedbackPanelCloseSource.FooterCancelButton,
      }),
    );
    close(FeedbackPanelCloseSource.FooterCancelButton);
  };

  const handleRatingChange = (newRating: number) => {
    biLogger.report(
      feedbackFormButtonClick({
        questionId: origin,
        buttonType: 'score',
        buttonName: ratingToBiNameMap[newRating],
      }),
    );
    setRating(newRating);
  };
  const handleHelpButtonClicked = () => openHelpCenter(HELP_ID);

  const isSubmitButtonDisabled = rating === undefined;

  return (
    <panels.frames.CustomPanelFrame
      panelName={panelName}
      title={translate(translationsKeys.modalTitle)}
      primaryButtonText={translate(translationsKeys.submitButtonText)}
      onPrimaryButtonClick={handleSubmitButtonClicked}
      primaryButtonProps={{ disabled: isSubmitButtonDisabled }}
      secondaryButtonText={translate(translationsKeys.cancelButtonText)}
      onSecondaryButtonClick={handleCancelButtonClicked}
      onCloseButtonClick={handleCloseButtonClicked}
      className={styles.modal}
      onOuterClick={handleOutsideClicked}
      onEscKeyPress={handleEscPressed}
      onHelpButtonClick={handleHelpButtonClicked}
      dataHook={dataHooks.root}
    >
      <div className={styles.content}>
        <Text weight="thin" size="small" shouldTranslate={false}>
          {translate(translationsKeys.ratingLabel)}
        </Text>
        <FacesRating
          value={rating}
          onChange={handleRatingChange}
          maxValue={3}
          descriptionValues={translationsKeys.ratingDescriptions.map((key) =>
            translate(key),
          )}
          className={styles.rating}
          dataHook={dataHooks.facesRating}
        />

        <Text shouldTranslate={false} weight="thin" size="small">
          {translate(translationsKeys.textareaLabel)}
        </Text>
        <TextInputMultiline
          className={styles.textarea}
          shouldTranslate={false}
          placeholder={translate(translationsKeys.textareaPlaceholder)}
          value={inputValue}
          onChange={handleInputChanged}
          dataHook={dataHooks.textInput}
          growOnFocus={false}
        />
      </div>
    </panels.frames.CustomPanelFrame>
  );
};

const mapDispatchToProps: MapDispatchToProps<
  FeedBackPanelDispatchProps,
  FeedbackPanelOwnProps
> = (dispatch, ownProps) => {
  return {
    closePanel: (closeOrigin) =>
      dispatch(
        stateManagement.panels.actions.closePanelByName(
          ownProps.panelName,
          closeOrigin,
        ),
      ),
    showSuccessNotification: () => {
      dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message:
            ownProps.translationsKeysOverrides?.notificationMessage ??
            defaultTranslationKeys.notificationMessage,
          shouldTranslate: true,
          type: constants.NOTIFICATIONS.TYPES.SUCCESS,
        }),
      );
    },
    openHelpCenter: (helpId) => {
      dispatch(stateManagement.panels.actions.openHelpCenter(helpId));
    },
  };
};

FeedbackPanel.defaultProps = {
  translationsKeysOverrides: {},
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(FeedbackPanel);

/*eslint max-lines: [2, { "max": 2089, "skipComments": true, "skipBlankLines": true}]*/
/*this file is huge, please don't make it even bigger, try to decompose it*/
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const googleMapsNewDefaultData = {
  id: 'googleMapDefaultData',
  locations: [
    {
      type: 'GeoMapLocation',
      address: '500 Terry Francois Street, 6th Floor. San Francisco, CA 94158',
      title: 'Wix Office',
      description: '',
      link: null as AnyFixMe,
      linkTitle: '',
      latitude: 37.77065,
      longitude: -122.387301,
      pinIcon:
        'https://static.parastorage.com/services/santa-resources/resources/editor/designPanel/panelTypes/google_maps_pin_icon.png',
      pinColor: '',
    },
  ],
  defaultLocation: 0,
  type: 'GeoMap',
  mapStyle: [] as AnyFixMe,
};

function createDefaultMetaData() {
  return {
    isHidden: false,
    isPreset: true,
    schemaVersion: '1.0',
  };
}

export interface AdditionalImageData {
  type: 'Image';
  title: string;
  uri: string;
  description: string;
  width: number;
  height: number;
  id: string;
  metaData: MetaData;
}

function getImagesArray(): AdditionalImageData[] {
  return [
    {
      type: 'Image',
      title: translate('add_default_text1_image1'),
      uri: 'a9ff3b_61fe61f3eb4c4adeb5835a166de10689.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'i01ch4',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image2'),
      uri: 'a9ff3b_5f378900670848919283755eb67a5949.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1922,
      id: 'i1119k',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image3'),
      uri: 'a9ff3b_7125cf82b18841c2961ce0a241b78826.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'i2lj2',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image4'),
      uri: 'a9ff3b_1d0b83b8723c4dc39192405fd083142a.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'i321j6',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image5'),
      uri: 'a9ff3b_f69457ef952c4aa4ba805827a02331c4.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'i41crh',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image6'),
      uri: 'a9ff3b_f2f5a5f6dac648e8a23cd07144c67530.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'i56rx',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image7'),
      uri: 'a9ff3b_42da54d3813d45d4b968f5d693c1d29c.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1920,
      id: 'image_217e',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image8'),
      uri: 'a9ff3b_a2cb5c4ffde14da5a2f47610c4587ad1.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1920,
      id: 'image_14p9',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image9'),
      uri: 'a9ff3b_2919fbec56cb4c3086b8159d5aa04b2e.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'image_kae',
      metaData: createDefaultMetaData(),
    },

    {
      type: 'Image',
      title: translate('add_default_text1_image10'),
      uri: 'a9ff3b_ffc94924b5634693a8686d91ab95b0bb.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'image_tty',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image11'),
      uri: 'a9ff3b_ee9cb7f87e424de7a2d49bcb20ea25f1.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'image_1tta',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image12'),
      uri: 'a9ff3b_f48ad132eda944eba22352d9dc140fb5.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'image_1hwt',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image13'),
      uri: 'a9ff3b_06ae2bbfb3904aeebd681a5b9e7262d7.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'image_tfr',
      metaData: createDefaultMetaData(),
    },
    {
      type: 'Image',
      title: translate('add_default_text1_image14'),
      uri: 'a9ff3b_efb829d794c1430ebfc6fbd3ef39f46e.jpg',
      description: translate('add_default_text1_gallerydescription'),
      width: 1920,
      height: 1280,
      id: 'image_1fav',
      metaData: createDefaultMetaData(),
    },
  ];
}

export interface MetaData {
  isPreset: boolean;
  schemaVersion: string;
  isHidden: boolean;
}

export interface MainMenuItem {
  type: 'PageLink' | 'ExternalLink' | 'BasicMenuItem' | 'CustomMenu';
  id: string;
  metaData?: {} | MetaData;
  pageId?: string;
  target?: string;
  anchorDataId?: string;
  link?: string;
  url?: string;
  isVisible?: boolean;
  isVisibleMobile?: boolean;
  items?: string[];
  label?: string;
}

const mainMenuItems: MainMenuItem[] = [
  {
    id: 'CUSTOM_MAIN_MENU',
    items: [
      '#BasicMenuItem1',
      '#BasicMenuItem2',
      '#BasicMenuItem3',
      '#BasicMenuItem4',
    ],
    type: 'CustomMenu',
  },
  {
    type: 'PageLink',
    id: '1fti',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
    },
    pageId: '#mainPage2',
    target: '_self',
  },
  {
    type: 'ExternalLink',
    target: '_blank',
    anchorDataId: undefined,
    id: 'rfv',
    url: '',
  },
  {
    type: 'ExternalLink',
    target: '_blank',
    anchorDataId: undefined,
    id: 'ttt',
    url: '',
  },
  {
    type: 'BasicMenuItem',
    isVisible: true,
    isVisibleMobile: true,
    items: [],
    label: translate('add_default_text4_Hmenu'),
    link: '#1fti',
    id: 'BasicMenuItem1',
    metaData: {},
  },
  {
    type: 'BasicMenuItem',
    isVisible: true,
    isVisibleMobile: true,
    items: [],
    label: translate('add_default_text5_Hmenu'),
    link: '#rfv',
    id: 'BasicMenuItem2',
    metaData: {},
  },
  {
    type: 'BasicMenuItem',
    isVisible: true,
    isVisibleMobile: true,
    items: [],
    label: translate('add_default_text6_Hmenu'),
    link: '#rfv',
    id: 'BasicMenuItem3',
    metaData: {},
  },
  {
    type: 'BasicMenuItem',
    isVisible: true,
    isVisibleMobile: true,
    items: [],
    label: translate('add_default_text7_Hmenu'),
    link: '#ttt',
    id: 'BasicMenuItem4',
    metaData: {},
  },
];

export interface ComponentDefaultData {
  componentType: string;
  componentTypeSkinOverride?: string;
  takeMeasuredLayout?: boolean;
  addClickArea?: number;
  label?: string;
  dataId?: string;
  propId?: string;
  siteId?: string;
  panelLayout?: { width: number; height: number };
  addPanelLayout?: { width: number; height: number };
  designPanelLayout?: { width: number; height: number };
  stageLayout?: { width: number; height: number };
  additionalMatserPageData?: MainMenuItem[];
  data?: {
    [key: string]: unknown;
  };
  props?: {
    [key: string]: unknown;
  };
  stageProps?: unknown;
  dataQuery?: string;
  stageData?: {
    items: Omit<ReturnType<typeof getImagesArray>[0], 'id'>[];
  };
  additionalData?: AdditionalImageData[];
  isContainer?: boolean;
}

const COMPONENTS_DEFAULT_DATA: Record<string, ComponentDefaultData> = {
  'wysiwyg.viewer.components.MatrixGallery': {
    componentType: 'wysiwyg.viewer.components.MatrixGallery',
    label: translate('add_preset_Grid'),
    dataId: 'matrixDefaultData',
    propId: 'matrixDefaultProp',
    addPanelLayout: {
      width: 568,
      height: 568,
    },
    designPanelLayout: {
      width: 440,
      height: 416,
    },
    data: {
      id: 'matrixDefaultData',
      items: [
        '#i01ch4',
        '#i1119k',
        '#i2lj2',
        '#i321j6',
        '#i41crh',
        '#i56rx',
        '#image_217e',
        '#image_14p9',
        '#image_kae',
      ],
      type: 'ImageList',
    },
    additionalData: getImagesArray(),
    props: {
      numCols: 3,
      maxRows: 3,
      incRows: 2,
      margin: 15,
      alignText: 'center',
      expandEnabled: true,
      imageMode: 'clipImage',
      goToLinkText: 'Go to link',
      galleryImageOnClickAction: 'disabled',
      showMoreLabel: 'Show More',
      type: 'MatrixGalleryProperties',
    },
    stageData: {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      items: _.map(getImagesArray(), function (item) {
        return _.omit(item, 'id');
      }),
    },
    stageProps: {
      galleryImageOnClickAction: 'zoomMode',
    },
  },
  'wysiwyg.viewer.components.PaginatedGridGallery': {
    componentType: 'wysiwyg.viewer.components.PaginatedGridGallery',
    label: translate('add_preset_Animatedgrid'),
    dataId: 'PaginatedGridDefaultData',
    propId: 'PaginatedGridDefaultProp',
    addPanelLayout: {
      width: 568,
      height: 568,
    },
    designPanelLayout: {
      width: 440,
      height: 416,
    },
    data: {
      id: 'PaginatedGridDefaultData',
      items: [
        '#i01ch4',
        '#i1119k',
        '#i2lj2',
        '#i321j6',
        '#i41crh',
        '#i56rx',
        '#image_217e',
        '#image_14p9',
        '#image_kae',
      ],
      type: 'ImageList',
    },
    additionalData: getImagesArray(),
    props: {
      autoplay: false,
      autoplayInterval: 3,
      expandEnabled: true,
      galleryImageOnClickAction: 'disabled',
      goToLinkText: 'Go to link',
      imageMode: 'clipImage',
      margin: 15,
      maxRows: 3,
      numCols: 3,
      showAutoplay: true,
      showCounter: true,
      showNavigation: true,
      transDuration: 1,
      transition: 'seq_crossFade_All',
      type: 'PaginatedGridGalleryProperties',
    },
    stageData: {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      items: _.map(getImagesArray(), function (item) {
        return _.omit(item, 'id');
      }),
    },
    stageProps: {
      galleryImageOnClickAction: 'zoomMode',
    },
  },
  'wysiwyg.viewer.components.SlideShowGallery': {
    componentType: 'wysiwyg.viewer.components.SlideShowGallery',
    label: translate('add_preset_Slideshow'),
    dataId: 'slideShowDefaultData',
    propId: 'slideShowDefaultProp',
    addPanelLayout: {
      width: 568,
      height: 300,
    },
    designPanelLayout: {
      width: 440,
      height: 256,
    },
    data: {
      id: 'slideShowDefaultData',
      items: [
        '#i01ch4',
        '#i1119k',
        '#i2lj2',
        '#i321j6',
        '#i41crh',
        '#i56rx',
        '#image_217e',
        '#image_14p9',
        '#image_kae',
      ],
      type: 'ImageList',
    },
    additionalData: getImagesArray(),
    props: {
      imageMode: 'clipImage',
      transition: 'swipeHorizontal',
      autoplayInterval: 5,
      autoplay: false,
      transDuration: 1.0,
      bidirectional: true,
      reverse: false,
      showAutoplay: true,
      showNavigation: true,
      showExpand: false,
      showSocial: false,
      showCounter: true,
      expandEnabled: true,
      goToLinkText: 'Go to link',
      galleryImageOnClickAction: 'disabled',
      type: 'SlideShowGalleryProperties',
    },
    stageData: {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      items: _.map(getImagesArray(), function (item) {
        return _.omit(item, 'id');
      }),
    },
    stageProps: {
      galleryImageOnClickAction: 'zoomMode',
    },
  },
  'wysiwyg.viewer.components.SliderGallery': {
    componentType: 'wysiwyg.viewer.components.SliderGallery',
    label: translate('add_preset_Slider'),
    dataId: 'sliderDefaultData',
    propId: 'sliderDefaultProp',
    addPanelLayout: {
      width: 568,
      height: 300,
    },
    designPanelLayout: {
      width: 440,
      height: 256,
    },
    data: {
      id: 'sliderDefaultData',
      items: [
        '#i01ch4',
        '#i1119k',
        '#i2lj2',
        '#i321j6',
        '#i41crh',
        '#i56rx',
        '#image_217e',
        '#image_14p9',
        '#image_kae',
      ],
      type: 'ImageList',
    },
    additionalData: getImagesArray(),
    props: {
      aspectRatio: 1.3333333333333333,
      aspectRatioPreset: '4:3',
      autoplay: false,
      autoplayInterval: 0,
      expandEnabled: true,
      galleryImageOnClickAction: 'disabled',
      goToLinkText: 'Go to link',
      type: 'SliderGalleryProperties',
      imageMode: 'clipImage',
      loop: true,
      margin: 15,
      maxSpeed: 7,
      showAutoplay: false,
      showCounter: true,
      transDuration: 0,
    },
    stageData: {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      items: _.map(getImagesArray(), function (item) {
        return _.omit(item, 'id');
      }),
    },
    stageProps: {
      galleryImageOnClickAction: 'zoomMode',
    },
  },

  'core.components.Container': {
    componentType: 'mobile.core.components.Container',
    label: 'container',
    isContainer: true,
    addPanelLayout: {
      width: 284,
      height: 120,
    },
    stageLayout: {
      width: 568,
      height: 240,
    },
    designPanelLayout: {
      width: 220,
      height: 96,
    },
  },
  'mobile.core.components.Container': {
    componentType: 'mobile.core.components.Container',
    isContainer: true,
    componentTypeSkinOverride: 'core.components.Container',
    label: 'container',
    addPanelLayout: {
      width: 284,
      height: 120,
    },
    stageLayout: {
      width: 568,
      height: 240,
    },
    designPanelLayout: {
      width: 220,
      height: 96,
    },
  },

  'wysiwyg.viewer.components.InlinePopupToggle': {
    componentType: 'wysiwyg.viewer.components.InlinePopupToggle',
    isContainer: false,
    label: 'inlinePopupToggle',
    designPanelLayout: {
      width: 48,
      height: 48,
    },
    addPanelLayout: {
      width: 48,
      height: 48,
    },
  },

  'wysiwyg.viewer.components.MenuToggle': {
    componentType: 'wysiwyg.viewer.components.MenuToggle',
    isContainer: false,
    label: 'inlinePopupToggle',
    designPanelLayout: {
      width: 48,
      height: 48,
    },
    addPanelLayout: {
      width: 48,
      height: 48,
    },
  },

  'wysiwyg.viewer.components.BgImageStrip': {
    componentType: 'wysiwyg.viewer.components.BgImageStrip',
    label: 'BgImageStrip',
    propId: 'bgImageStripDefaultProp',
    addPanelLayout: {
      width: 608,
      height: 119,
    },
    stageLayout: {
      width: 568,
      height: 362,
    },
    designPanelLayout: {
      width: 480,
      height: 96,
    },
    props: {
      id: 'bgImageStripDefaultProp',
      alignType: 'center',
      fittingType: 'fill',
      type: 'BgImageStripUnifiedProperties',
    },
  },

  'wysiwyg.viewer.components.menus.DropDownMenu': {
    componentType: 'wysiwyg.viewer.components.menus.DropDownMenu',
    label: 'DropDownMenu',
    dataId: 'CustomMenuDefaultData',
    propId: 'dropDownDefaultProp',
    addPanelLayout: {
      width: 304,
      height: 30,
    },
    designPanelLayout: {
      width: 240,
      height: 24,
    },
    stageLayout: {
      width: 708,
      height: 50,
    },
    data: {
      menuRef: 'CUSTOM_MAIN_MENU',
      id: 'DropDownMenuDataRef',
      type: 'CustomMenuDataRef',
    },
    dataQuery: '#MAIN_MENU',
    additionalMatserPageData: mainMenuItems,
    props: {
      alignButtons: 'center',
      alignText: 'center',
      moreButtonLabel: 'More',
      moreItemHeight: 15,
      sameWidthButtons: false,
      stretchButtonsToMenuWidth: true,
      type: 'HorizontalMenuProperties',
    },
  },
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': {
    componentType: 'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu',
    label: 'VerticalMenu',
    dataId: 'CustomMenuDefaultData',
    propId: 'verticalDefaultProp',
    addPanelLayout: {
      width: 88,
      height: 150,
    },
    designPanelLayout: {
      width: 66,
      height: 120,
    },
    stageLayout: {
      width: 100,
      height: 200,
    },
    dataQuery: '#MAIN_MENU',
    data: {
      id: 'VerticalMenuDataRef',
      menuRef: 'CUSTOM_MAIN_MENU',
      type: 'CustomMenuDataRef',
    },
    additionalMatserPageData: mainMenuItems,
    props: {
      itemsAlignment: 'left',
      subMenuOpenSide: 'right',
      type: 'VerticalMenuProperties',
      ifRef: true,
    },
  },

  'wysiwyg.viewer.components.FiveGridLine': {
    componentType: 'wysiwyg.viewer.components.FiveGridLine',
    label: 'FiveGridLine',
    propId: 'fiveGridLineDefaultProp',
    addClickArea: 15,
    addPanelLayout: {
      width: 142,
      height: 7,
    },
    stageLayout: {
      width: 284,
      height: 7,
    },
    designPanelLayout: {
      width: 110,
      height: 5,
    },
    props: {
      fullScreenModeOn: false,
      type: 'FiveGridLineProperties',
    },
  },

  fiveGridLineFullWidth: {
    componentType: 'wysiwyg.viewer.components.FiveGridLine',
    label: 'fiveGridLineFullWidth',
    propId: 'fullLineDefaultProp',
    addClickArea: 15,
    addPanelLayout: {
      width: 304,
      height: 7,
    },
    designPanelLayout: {
      width: 240,
      height: 5,
    },
    props: {
      fullScreenModeOn: true,
      type: 'FiveGridLineProperties',
    },
  },
  'wysiwyg.viewer.components.VerticalLine': {
    componentType: 'wysiwyg.viewer.components.VerticalLine',
    label: 'VerticalLine',
    addClickArea: 15,
    addPanelLayout: {
      width: 5,
      height: 140,
    },
    designPanelLayout: {
      width: 5,
      height: 110,
    },
  },

  'wixui.MusicPlayer': {
    componentType: 'wixui.MusicPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'musicPlayerDefaultData',
    propId: 'musicPlayerDefaultProps',
    addPanelLayout: {
      width: 304,
      height: 70,
    },
    designPanelLayout: {
      width: 300,
      height: 70,
    },
    stageLayout: {
      width: 320,
      height: 70,
    },
    data: {
      id: 'musicPlayerDefaultData',
      type: 'MusicPlayerData',
      artistName: 'Artist Name',
      trackName: 'Track Name',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: 'wix-audio-demo.mp3',
        uri: '931f1b_f9c64a4fb00c4cef859c77b5eab38b3f.mp3',
        duration: 64,
      },
    },
    props: {
      id: 'musicPlayerDefaultProps',
      type: 'MusicPlayerProperties',
      layout: 'classic',
      showCover: true,
      showArtistName: true,
      showTrackName: true,
      showProgressBar: true,
      showTimeStamp: true,
    },
  },

  'wixui.MusicPlayer.customProps1': {
    componentType: 'wixui.MusicPlayer',
    componentTypeSkinOverride: 'wixui.MusicPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'musicPlayerDefaultData',
    propId: 'musicPlayerCustomProps1',
    addPanelLayout: {
      width: 130,
      height: 40,
    },
    designPanelLayout: {
      width: 130,
      height: 40,
    },
    data: {
      id: 'musicPlayerDefaultData',
      type: 'MusicPlayerData',
      artistName: 'Artist Name',
      trackName: 'Track Name',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: 'wix-audio-demo.mp3',
        uri: '931f1b_f9c64a4fb00c4cef859c77b5eab38b3f.mp3',
        duration: 64,
      },
    },
    props: {
      id: 'musicPlayerCustomProps1',
      type: 'MusicPlayerProperties',
      layout: 'classic',
      showCover: false,
      showArtistName: false,
      showTrackName: false,
      showProgressBar: false,
      showTimeStamp: true,
    },
  },

  'wixui.MusicPlayer.customProps2': {
    componentType: 'wixui.MusicPlayer',
    componentTypeSkinOverride: 'wixui.MusicPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'musicPlayerDefaultData',
    propId: 'musicPlayerCustomProps2',
    addPanelLayout: {
      width: 154,
      height: 40,
    },
    designPanelLayout: {
      width: 154,
      height: 40,
    },
    data: {
      id: 'musicPlayerDefaultData',
      type: 'MusicPlayerData',
      artistName: 'Artist Name',
      trackName: 'Track Name',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: 'wix-audio-demo.mp3',
        uri: '931f1b_f9c64a4fb00c4cef859c77b5eab38b3f.mp3',
        duration: 64,
      },
    },
    props: {
      id: 'musicPlayerCustomProps2',
      type: 'MusicPlayerProperties',
      layout: 'classic',
      showCover: false,
      showArtistName: false,
      showTrackName: false,
      showProgressBar: true,
      showTimeStamp: false,
    },
  },

  'wixui.MusicPlayer.customProps3': {
    componentType: 'wixui.MusicPlayer',
    componentTypeSkinOverride: 'wixui.MusicPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'musicPlayerDefaultData',
    propId: 'musicPlayerCustomProps3',
    addPanelLayout: {
      width: 304,
      height: 40,
    },
    designPanelLayout: {
      width: 300,
      height: 40,
    },
    stageLayout: {
      width: 320,
      height: 70,
    },
    data: {
      id: 'musicPlayerDefaultData',
      type: 'MusicPlayerData',
      artistName: 'Artist Name',
      trackName: 'Track Name',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: 'wix-audio-demo.mp3',
        uri: '931f1b_f9c64a4fb00c4cef859c77b5eab38b3f.mp3',
        duration: 64,
      },
    },
    props: {
      id: 'musicPlayerCustomProps3',
      type: 'MusicPlayerProperties',
      layout: 'classic',
      showCover: true,
      showArtistName: false,
      showTrackName: true,
      showProgressBar: false,
      showTimeStamp: false,
    },
  },
  'wixui.MusicPlayer.customProps4': {
    componentType: 'wixui.MusicPlayer',
    componentTypeSkinOverride: 'wixui.MusicPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'musicPlayerDefaultData',
    propId: 'musicPlayerCustomProps4',
    addPanelLayout: {
      width: 70,
      height: 70,
    },
    stageLayout: {
      width: 70,
      height: 70,
    },
    data: {
      id: 'musicPlayerDefaultData',
      type: 'MusicPlayerData',
      artistName: 'Artist Name',
      trackName: 'Track Name',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: 'wix-audio-demo.mp3',
        uri: '931f1b_f9c64a4fb00c4cef859c77b5eab38b3f.mp3',
        duration: 64,
      },
    },
    props: {
      id: 'musicPlayerCustomProps4',
      type: 'MusicPlayerProperties',
      layout: 'icon',
      iconId: 'icon0',
    },
  },

  'wixui.MusicPlayer.customProps5': {
    componentType: 'wixui.MusicPlayer',
    componentTypeSkinOverride: 'wixui.MusicPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'musicPlayerDefaultData',
    propId: 'musicPlayerCustomProps5',
    addPanelLayout: {
      width: 70,
      height: 70,
    },
    stageLayout: {
      width: 70,
      height: 70,
    },
    data: {
      id: 'musicPlayerDefaultData',
      type: 'MusicPlayerData',
      artistName: 'Artist Name',
      trackName: 'Track Name',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: 'wix-audio-demo.mp3',
        uri: '931f1b_f9c64a4fb00c4cef859c77b5eab38b3f.mp3',
        duration: 64,
      },
    },
    props: {
      id: 'musicPlayerCustomProps5',
      type: 'MusicPlayerProperties',
      layout: 'icon',
      iconId: 'icon1',
    },
  },

  'wixui.MusicPlayer.customProps6': {
    componentType: 'wixui.MusicPlayer',
    componentTypeSkinOverride: 'wixui.MusicPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'musicPlayerDefaultData',
    propId: 'musicPlayerCustomProps6',
    addPanelLayout: {
      width: 70,
      height: 70,
    },
    stageLayout: {
      width: 70,
      height: 70,
    },
    data: {
      id: 'musicPlayerDefaultData',
      type: 'MusicPlayerData',
      artistName: 'Artist Name',
      trackName: 'Track Name',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: 'wix-audio-demo.mp3',
        uri: '931f1b_f9c64a4fb00c4cef859c77b5eab38b3f.mp3',
        duration: 64,
      },
    },
    props: {
      id: 'musicPlayerCustomProps6',
      type: 'MusicPlayerProperties',
      layout: 'icon',
      iconId: 'icon2',
    },
  },
  'wixui.StylableButton': {
    componentType: 'wixui.StylableButton',
    label: 'StylableButton',
    dataId: 'stylableButtonDefaultData',
    propId: 'stylableButtonDefaultProps',
    addPanelLayout: {
      width: 142,
      height: 40,
    },
    designPanelLayout: {
      width: 110,
      height: 32,
    },
    data: {
      label: translate('add_default_text1_textbutton'),
      svgId: 'b861b040274141de9c08999ec3b76edf.svg',
      type: 'StylableButton',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      },
    },
    props: {
      type: 'StylableButtonProperties',
    },
  },
  'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer': {
    componentType:
      'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
    label: translate('add_preset_Audioplayer'),
    dataId: 'singleAudioPlayerDefaultData',
    propId: 'singleAudioPlayerDefaultProp',
    addPanelLayout: {
      width: 304,
      height: 60,
    },
    designPanelLayout: {
      width: 320,
      height: 64,
    },
    data: {
      id: 'singleAudioPlayerDefaultData',
      artist: translate('add_default_text2_audioplayer'),
      originalFileName: '',
      track: translate('add_default_text1_audioplayer'),
      uri: '',
      type: 'SingleAudioPlayer',
    },
    props: {
      autoplay: false,
      loop: false,
      showArtist: true,
      showTrack: true,
      uri: '',
      volume: 50,
      type: 'SingleAudioPlayerProperties',
    },
  },
  'wysiwyg.viewer.components.AudioPlayer': {
    componentType: 'wysiwyg.viewer.components.AudioPlayer',
    label: translate('add_preset_Playbutton'),
    dataId: 'audioPlayerDefaultProp',
    addPanelLayout: {
      width: 40,
      height: 40,
    },
    designPanelLayout: {
      width: 45,
      height: 48,
    },
    data: {
      id: 'audioPlayerDefaultProp',
      volume: 100,
      visible: true,
      uri: '',
      type: 'AudioPlayer',
    },
  },

  'wysiwyg.viewer.components.WFacebookLike': {
    componentType: 'wysiwyg.viewer.components.WFacebookLike',
    propId: 'facebookDefaultProp',
    addPanelLayout: {
      width: 250,
      height: 40,
    },
    data: {
      type: 'WFacebookLike',
      urlFormat: 'hashBang',
    },
    props: {
      id: 'facebookDefaultProp',
      action: 'like',
      colorScheme: 'light',
      font: 'helvetica',
      layout: 'standard',
      send: false,
      show_faces: false,
      type: 'WFacebookLikeProperties',
    },
  },
  'wysiwyg.viewer.components.WGooglePlusOne': {
    componentType: 'wysiwyg.viewer.components.WGooglePlusOne',
    propId: 'googlePlusDefaultProp',
    addPanelLayout: {
      width: 250,
      height: 40,
    },
    props: {
      id: 'googlePlusDefaultProp',
      annotation: 'inline',
      size: 'standard',
      type: 'WGooglePlusOneProperties',
    },
  },
  'wysiwyg.viewer.components.WTwitterTweet': {
    componentType: 'wysiwyg.viewer.components.WTwitterTweet',
    propId: 'twitterTweetDefaultProp',
    dataId: 'twitterTweetDefaultData',
    addPanelLayout: {
      width: 100,
      height: 20,
    },
    data: {
      id: 'twitterTweetDefaultData',
      accountToFollow: '',
      defaultText: '',
      urlFormat: 'hashBang',
      type: 'TwitterTweet',
    },
    props: {
      id: 'twitterTweetDefaultProp',
      dataCount: 'horizontal',
      dataLang: 'en',
      type: 'WTwitterTweetProperties',
    },
  },
  'wysiwyg.viewer.components.GoogleMap': {
    componentType: 'wysiwyg.viewer.components.GoogleMap',
    propId: 'googleMapDefaultProp',
    label: translate('add_preset_GoogleMap'),
    dataId: 'googleMapDefaultData',
    addPanelLayout: {
      width: 608,
      height: 140,
    },
    stageLayout: {
      width: 608,
      height: 280,
    },
    designPanelLayout: {
      width: 480,
      height: 112,
    },
    data: googleMapsNewDefaultData,
    props: {
      id: 'googleMapDefaultProp',
      mapDragging: true,
      showDirectionsLink: true,
      mapType: 'ROADMAP',
      showMapType: true,
      showPosition: true,
      showStreetView: true,
      showZoom: true,
      language: util.languages.getLanguageCode(),
      type: 'GoogleMapProperties',
    },
  },
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': {
    componentType:
      'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
    label: translate('add_preset_Subscribeform'),
    propId: 'subscribeFormDefaultProp',
    dataId: 'subscribeFormDefaultData',
    addPanelLayout: {
      width: 608,
      height: 100,
    },
    designPanelLayout: {
      width: 480,
      height: 100,
    },
    data: {
      id: 'subscribeFormDefaultData',
      bccEmailAddress: '',
      emailFieldLabel: 'Email',
      errorMessage: 'Please provide a valid email',
      lastNameFieldLabel: 'Last Name',
      phoneFieldLabel: 'Mobile Number',
      submitButtonLabel: 'Subscribe Now',
      subscribeFormTitle: 'Subscribe for Updates',
      successMessage: 'Congrats! You’re subscribed',
      textDirection: 'left',
      toEmailAddress: '',
      firstNameFieldLabel: 'Name',
      type: 'SubscribeForm',
    },
    props: {
      id: 'subscribeFormDefaultProp',
      hiddenEmailField: true,
      hiddenFirstNameField: false,
      hiddenLastNameField: false,
      hiddenPhoneField: false,
      requiredEmailField: true,
      requiredFirstNameField: false,
      requiredLastNameField: false,
      requiredPhoneField: false,
      type: 'SubscribeFormProperties',
    },
  },
  'wysiwyg.viewer.components.ContactForm': {
    componentType: 'wysiwyg.viewer.components.ContactForm',
    label: translate('add_preset_Contactform'),
    propId: 'contactFormDefaultProp',
    dataId: 'contactFormDefaultData',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 284,
      height: 20 /*the height in the viewer is set base on width - will be updated */,
    },
    designPanelLayout: {
      width: 220,
      height: 20 /*the height in the viewer is set base on width - will be updated */,
    },
    data: {
      id: 'contactFormDefaultData',
      addressFieldLabel: translate(
        'Contact_Form_Settings_Customize_Form_Fields_Address_default',
      ),
      bccEmailAddress: '',
      emailFieldLabel: translate(
        'Contact_Form_Settings_Customize_Form_Fields_Email_default',
      ),
      errorMessage: translate(
        'Contact_Form_Settings_Messages_Error_message_for_email_fields_default',
      ),
      messageFieldLabel: translate(
        'Contact_Form_Settings_Customize_Form_Fields_Messages_default',
      ),
      nameFieldLabel: translate(
        'Contact_Form_Settings_Customize_Form_Fields_Name_default',
      ),
      phoneFieldLabel: translate(
        'Contact_Form_Settings_Customize_Form_Fields_Phone_default',
      ),
      subjectFieldLabel: translate(
        'Contact_Form_Settings_Customize_Form_Fields_Subject_default',
      ),
      submitButtonLabel: translate(
        'Contact_Form_Settings__Send_button_text_default',
      ),
      successMessage: translate(
        'Contact_Form_Settings_Messages_Success_message_default',
      ),
      textDirection: 'left',
      toEmailAddress: '',
      type: 'ContactForm',
    },
    props: {
      id: 'contactFormDefaultProp',
      hidden_addressFieldLabel: false,
      hidden_emailFieldLabel: true,
      hidden_messageFieldLabel: true,
      hidden_nameFieldLabel: true,
      hidden_phoneFieldLabel: false,
      hidden_subjectFieldLabel: true,
      required_addressFieldLabel: false,
      required_emailFieldLabel: true,
      required_messageFieldLabel: false,
      required_nameFieldLabel: true,
      required_phoneFieldLabel: false,
      required_subjectFieldLabel: false,
      type: 'ContactFormProperties',
    },
  },
  'wysiwyg.viewer.components.DynamicContactForm': {
    componentType: 'wysiwyg.viewer.components.DynamicContactForm',
    label: translate('add_preset_Contactform'),
    dataId: 'dynamicContactFormDefaultData',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 284,
      height: 20 /*the height in the viewer is set base on width - will be updated */,
    },
    designPanelLayout: {
      width: 220,
      height: 20 /*the height in the viewer is set base on width - will be updated */,
    },
    data: {
      id: 'dynamicContactFormDefaultData',
      bccEmailAddress: '',
      errorMessage: translate(
        'Contact_Form_Settings_Messages_Error_message_for_email_fields_default',
      ),
      submitButtonLabel: translate(
        'Contact_Form_Settings__Send_button_text_default',
      ),
      successMessage: translate(
        'Contact_Form_Settings_Messages_Success_message_default',
      ),
      textDirection: 'left',
      toEmailAddress: '',
      formName: '',
      type: 'DynamicContactForm',
    },
    props: {
      type: 'DefaultProperties',
    },
  },
  'wysiwyg.viewer.components.SiteButton': {
    componentType: 'wysiwyg.viewer.components.SiteButton',
    label: 'SiteButton',
    dataId: 'siteButtonDefaultData',
    propId: 'siteButtonDefaultProp',
    addPanelLayout: {
      width: 142,
      height: 40,
    },
    designPanelLayout: {
      width: 110,
      height: 32,
    },
    data: {
      label: translate('add_default_text1_textbutton'),
      link: null,
      type: 'LinkableButton',
    },
    props: {
      align: 'center',
      margin: 0,
      type: 'ButtonProperties',
    },
  },
  'wysiwyg.viewer.components.WPhoto': {
    componentType: 'wysiwyg.viewer.components.WPhoto',
    label: 'SiteButton',
    dataId: 'wphotoDefaultData',
    propId: 'wphotoDefaultProp',
    designPanelLayout: {
      width: 220,
      height: 160,
    },
    addPanelLayout: {
      width: 180,
      height: 160,
    },
    data: {
      id: 'wphotoDefaultData',
      type: 'Image',
      alt: '',
      description: '',
      height: 1280,
      title: '',
      uri: 'edb491_a5f1565968db4168a7b1931d503faaa2~mv2_d_1920_1280_s_2.jpg',
      width: 1920,
    },
    props: {
      id: 'wphotoDefaultProp',
      displayMode: 'fill',
      onClickBehavior: '',
      type: 'WPhotoProperties',
    },
  },
  'wysiwyg.viewer.components.inputs.TextInput': {
    componentType: 'wysiwyg.viewer.components.inputs.TextInput',
    label: 'TextInput',
    dataId: 'textInputDefaultData',
    propId: 'textInputDefaultProp',
    designPanelLayout: {
      width: 240,
      height: 34,
    },
    addPanelLayout: {
      width: 240,
      height: 43,
    },
    data: {
      id: 'textInputDefaultData',
      type: 'TextInput',
      placeholder: translate('design_section_Text_Input_default_placeholder'),
    },
    props: {
      id: 'textInputDefaultProp',
      type: 'TextInputProperties',
      textPadding: 12,
      textAlignment: 'left',
      defaultTextType: 'placeholderText',
    },
  },
  'wysiwyg.viewer.components.inputs.FileUploader': {
    componentType: 'wysiwyg.viewer.components.inputs.FileUploader',
    label: 'FileUploader',
    dataId: 'fileUploaderDefaultData',
    propId: 'fileUploaderDefaultProp',
    designPanelLayout: {
      width: 240,
      height: 64,
    },
    addPanelLayout: {
      width: 240,
      height: 43,
    },
    data: {
      type: 'FileUploader',
      buttonLabel: translate(
        'design_section_File_Uploader_default_button_label',
      ),
      placeholderLabel: translate(
        'design_section_File_Uploader_default_placeholder',
      ),
    },
    props: {
      type: 'FileUploaderProperties',
      isHidden: false,
      buttonAlignment: 'ltr',
      filesAlignment: 'center',
      showPlaceholder: true,
      filesType: 'Image',
      filesSizeLimit: 15,
    },
  },
  'wysiwyg.viewer.components.inputs.TextAreaInput': {
    componentType: 'wysiwyg.viewer.components.inputs.TextAreaInput',
    label: 'TextAreaInput',
    dataId: 'textAreaInputDefaultData',
    propId: 'textAreaInputDefaultProp',
    designPanelLayout: {
      width: 240,
      height: 55,
    },
    addPanelLayout: {
      width: 240,
      height: 70,
    },
    data: {
      id: 'textAreaDefaultData',
      placeholder: translate('design_section_Text_Area_default_placeholder'),
    },
    props: {
      id: 'textAreaInputDefaultProp',
      type: 'TextAreaInputProperties',
      textPadding: 12,
      textAlignment: 'left',
      defaultTextType: 'placeholderText',
    },
  },
  'wixui.SignatureInput': {
    componentType: 'wixui.SignatureInput',
    label: 'SignatureInput',
    dataId: 'signatureInputDefaultData',
    propId: 'signatureInputDefaultProp',
    designPanelLayout: {
      width: 240,
      height: 105,
    },
    addPanelLayout: {
      width: 240,
      height: 105,
    },
    data: {
      id: 'signatureInputDefaultData',
      type: 'SignatureInput',
      titleText: translate(
        'design_section_SignatureInput_default_title_default_title',
      ),
      clearButtonText: translate(
        'design_section_SignatureInput_default_title_default_clear_text',
      ),
    },
    props: {
      id: 'signatureInputDefaultProp',
      type: 'SignatureInputProperties',
      direction: 'ltr',
      titlePaddingStart: 1,
      titleMarginBottom: 8,
    },
  },
  'wysiwyg.viewer.components.inputs.ComboBoxInput': {
    componentType: 'wysiwyg.viewer.components.inputs.ComboBoxInput',
    label: 'ComboBoxInput',
    dataId: 'comboBoxInputDefaultData',
    propId: 'comboBoxInputDefaultProp',
    designPanelLayout: {
      width: 240,
      height: 44,
    },
    addPanelLayout: {
      width: 240,
      height: 43,
    },
    data: {
      id: 'comboBoxInputDefaultData',
      value: '',
      options: [],
    },
    props: {
      id: 'comboBoxInputDefaultProp',
      type: 'comboBoxInputProperties',
      textAlignment: 'left',
      textPadding: 12,
      placeholder: {
        value: translate('design_section_Dropdown_default_placeholder'),
        text: translate('design_section_Dropdown_default_placeholder'),
      },
    },
  },
  'wysiwyg.viewer.components.inputs.RadioGroup': {
    componentType: 'wysiwyg.viewer.components.inputs.RadioGroup',
    label: 'RadioGroup',
    dataId: 'radioGroupDefaultData',
    propId: 'radioGroupDefaultProp',
    designPanelLayout: {
      width: 110,
      height: 75,
    },
    addPanelLayout: {
      width: 144,
      height: 91,
    },
    data: {
      id: 'radioGroupDefaultData',
      options: [
        {
          id: 'dataItem-ix03jgwd1',
          type: 'RadioButton',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          value: 'Radio button1',
          label: translate('design_section_Radio_Button_default_option1'),
        },
        {
          id: 'dataItem-ix03jgwf',
          type: 'RadioButton',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          value: 'Radio button2',
          label: translate('design_section_Radio_Button_default_option2'),
        },
        {
          id: 'dataItem-ix03jgwf1',
          type: 'RadioButton',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          value: 'Radio button3',
          label: translate('design_section_Radio_Button_default_option3'),
        },
      ],
      defaultValue: 'Radio button1',
      value: 'Radio button1',
    },
    props: {
      id: 'radioGroupDefaultProp',
      type: 'RadioGroupProperties',
      alignment: 'left',
      layout: 'vertical',
      buttonsMargin: 12,
      spacing: 12,
      buttonSize: 16,
    },
  },
  'wysiwyg.viewer.components.inputs.CheckboxGroup': {
    componentType: 'wysiwyg.viewer.components.inputs.CheckboxGroup',
    label: 'CheckboxGroup',
    dataId: 'checkboxGroupDefaultData',
    propId: 'checkboxGroupDefaultProp',
    designPanelLayout: {
      width: 110,
      height: 75,
    },
    addPanelLayout: {
      width: 144,
      height: 91,
    },
    data: {
      id: 'checkboxGroupDefaultData',
      options: [
        {
          id: 'dataItem-ix03jgwd1',
          type: 'CheckboxInput',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          value: 'Checkbox1',
          label: translate('design_section_Checkbox_Group_default_option1'),
          checked: true,
        },
        {
          id: 'dataItem-ix03jgwf',
          type: 'CheckboxInput',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          value: 'Checkbox2',
          label: translate('design_section_Checkbox_Group_default_option2'),
        },
        {
          id: 'dataItem-ix03jgwf1',
          type: 'CheckboxInput',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          value: 'Checkbox3',
          label: translate('design_section_Checkbox_Group_default_option3'),
          checked: true,
        },
      ],
    },
    props: {
      id: 'checkboxGroupDefaultProp',
      type: 'CheckboxGroupProperties',
      alignment: 'left',
      layout: 'vertical',
      buttonsMargin: 12,
      spacing: 12,
      buttonSize: 16,
    },
  },
  'wysiwyg.viewer.components.inputs.DatePicker': {
    componentType: 'wysiwyg.viewer.components.inputs.DatePicker',
    label: 'DatePicker',
    dataId: 'datePickerDefaultData',
    propId: 'datePickerDefaultProp',
    designPanelLayout: {
      width: 240,
      height: 44,
    },
    addPanelLayout: {
      width: 240,
      height: 43,
    },
    data: {
      id: 'datePickerDefaultData',
    },
    props: {
      id: 'datePickerDefaultProp',
      type: 'DatePickerProperties',
      textAlignment: 'left',
      textPadding: 12,
      dateFormat: 'MM/DD/YYYY',
      defaultTextType: 'today',
    },
  },
  'wysiwyg.viewer.components.inputs.Checkbox': {
    componentType: 'wysiwyg.viewer.components.inputs.Checkbox',
    label: 'Checkbox',
    dataId: 'checkboxDefaultData',
    propId: 'checkboxDefaultProp',
    designPanelLayout: {
      width: 240,
      height: 20,
    },
    addPanelLayout: {
      width: 240,
      height: 40,
    },
    data: {
      value: '',
      checked: true,
      label: translate('design_section_Checkbox_default_label'),
    },
    props: {
      id: 'checkboxDefaultProp',
      type: 'CheckboxProperties',
      spacing: 11,
      buttonSize: 14,
      alignment: 'left',
    },
  },
  'wysiwyg.viewer.components.Video': {
    componentType: 'wysiwyg.viewer.components.Video',
    label: 'Video',
    dataId: 'videoDefaultData',
    propId: 'videoDefaultProp',
    designPanelLayout: {
      width: 440,
      height: 320,
    },
    addPanelLayout: {
      width: 440,
      height: 320,
    },
    data: {
      id: 'videoDefaultData',
      type: 'Video',
      videoId: 'PMWmYf2043g',
      videoType: 'YOUTUBE',
    },
    props: {
      id: 'videoDefaultProp',
      autoplay: false,
      lightTheme: false,
      loop: false,
      showControls: 'always_show',
      showinfo: false,
      type: 'VideoProperties',
    },
  },
  'wysiwyg.viewer.components.mobile.TinyMenu': {
    componentType: 'wysiwyg.viewer.components.mobile.TinyMenu',
    label: 'Tiny Menu',
    dataId: 'CUSTOM_MAIN_MENU',
    propId: 'tinyMenuDefaultProp',
    designPanelLayout: {
      width: 48,
      height: 48,
    },
    addPanelLayout: {
      width: 48,
      height: 48,
    },
    data: {
      id: 'CUSTOM_MAIN_MENU',
      items: ['#BasicMenuItem1', '#BasicMenuItem2', '#BasicMenuItem3'],
      type: 'CustomMenu',
    },
    props: {
      id: 'tinyMenuDefaultProp',
      direction: 'left',
      type: 'TinyMenuProperties',
    },
    additionalMatserPageData: mainMenuItems,
    dataQuery: '#MAIN_MENU',
  },
  'wysiwyg.viewer.components.HeaderContainer': {
    componentType: 'wysiwyg.viewer.components.HeaderContainer',
    isContainer: true,
    label: 'Header',
    designPanelLayout: {
      width: 480,
      height: 96,
    },
    addPanelLayout: {
      width: 608,
      height: 96,
    },
  },
  'wysiwyg.viewer.components.FooterContainer': {
    componentType: 'wysiwyg.viewer.components.FooterContainer',
    isContainer: true,
    label: 'Header',
    designPanelLayout: {
      width: 480,
      height: 96,
    },
    addPanelLayout: {
      width: 608,
      height: 96,
    },
  },
  'wysiwyg.viewer.components.ScreenWidthContainer': {
    componentType: 'wysiwyg.viewer.components.ScreenWidthContainer',
    isContainer: true,
    designPanelLayout: {
      width: 480,
      height: 96,
    },
    addPanelLayout: {
      width: 608,
      height: 96,
    },
  },
  pageContainer: {
    componentType: 'mobile.core.components.Container',
    componentTypeSkinOverride: 'mobile.core.components.Page',
    isContainer: true,
    label: 'Header',
    designPanelLayout: {
      width: 70,
      height: 55,
    },
    addPanelLayout: {
      width: 70,
      height: 55,
    },
  },
  'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu': {
    componentType:
      'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
    label: 'Vertical Anchors Menu',
    dataId: 'anchorsMenuDefaultData',
    propId: 'anchorsMenuDefaultProp',
    designPanelLayout: {
      width: 32,
      height: 60,
    },
    addPanelLayout: {
      width: 32,
      height: 60,
    },
    data: {
      id: 'anchorsMenuDefaultData',
      hiddenAnchorIds: { c1dmp: ['PAGE_TOP_ANCHOR'] },
      pageTopLabel: translate('ANCHOR_MENU_MANAGER_TOP_LABEL'),
      pageBottomLabel: 'Bottom of Page',
      type: 'VerticalAnchorsMenu',
    },
    props: {
      id: 'anchorsMenuDefaultProp',
      itemsAlignment: 'right',
      orientation: 'right',
      type: 'VerticalAnchorsMenuProperties',
    },
  },
  // !!!
  WRichTextStyle1: {
    componentType: 'wysiwyg.viewer.components.WRichText',
    dataId: 'WRichTextStyle1DefaultData',
    propId: 'WRichTextStyle1DefaultProp',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 304,
      height: 10,
    },
    data: {
      id: 'WRichTextStyle1DefaultData',
      text: `<h1 class="font_0">${translate(
        'add_default_text1_sitetitle',
      )}</h1>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
    props: {
      id: 'WRichTextStyle1DefaultProp',
      type: 'WRichTextProperties',
      brightness: 1,
    },
  },
  WRichTextStyle2: {
    componentType: 'wysiwyg.viewer.components.WRichText',
    dataId: 'WRichTextStyle2DefaultData',
    propId: 'WRichTextStyle2DefaultProp',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 304,
      height: 10,
    },
    data: {
      id: 'WRichTextStyle2DefaultData',
      text: `<h2 class="font_2">${translate(
        'add_default_text1_pagetitle',
      )}</h2>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
    props: {
      id: 'WRichTextStyle2DefaultProp',
      type: 'WRichTextProperties',
      brightness: 1,
    },
  },
  WRichTextStyle3: {
    componentType: 'wysiwyg.viewer.components.WRichText',
    dataId: 'WRichTextStyle3DefaultData',
    propId: 'WRichTextStyle3DefaultProp',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 304,
      height: 10,
    },
    data: {
      id: 'WRichTextStyle3DefaultData',
      text: `<h4 class="font_4">${translate(
        'add_default_text1_largeheading',
      )}</h4>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
    props: {
      id: 'WRichTextStyle3DefaultProp',
      type: 'WRichTextProperties',
      brightness: 1,
    },
  },
  WRichTextStyle4: {
    componentType: 'wysiwyg.viewer.components.WRichText',
    dataId: 'WRichTextStyle4DefaultData',
    propId: 'WRichTextStyle4DefaultProp',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 304,
      height: 10,
    },
    data: {
      id: 'WRichTextStyle4DefaultData',
      text: `<h6 class="font_6">${translate(
        'add_default_text1_smallheading',
      )}</h6>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
    props: {
      id: 'WRichTextStyle4DefaultProp',
      type: 'WRichTextProperties',
      brightness: 1,
    },
  },
  WRichTextStyle5: {
    componentType: 'wysiwyg.viewer.components.WRichText',
    dataId: 'WRichTextStyle5DefaultData',
    propId: 'WRichTextStyle5DefaultProp',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 304,
      height: 10,
    },
    data: {
      id: 'WRichTextStyle5DefaultData',
      text: `<p class="font_7">${translate(
        'add_default_text1_paragraphL',
      )}</p>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
    props: {
      id: 'WRichTextStyle5DefaultProp',
      type: 'WRichTextProperties',
      brightness: 1,
    },
  },
  WRichTextStyle6: {
    componentType: 'wysiwyg.viewer.components.WRichText',
    dataId: 'WRichTextStyle6DefaultData',
    propId: 'WRichTextStyle6DefaultProp',
    takeMeasuredLayout: true,
    addPanelLayout: {
      width: 304,
      height: 10,
    },
    data: {
      id: 'WRichTextStyle6DefaultData',
      text: `<p class="font_8">${translate(
        'add_default_text1_paragraphM',
      )}</p>`,
      type: 'StyledText',
      stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
    },
    props: {
      id: 'WRichTextStyle6DefaultProp',
      type: 'WRichTextProperties',
      brightness: 1,
    },
  },
  'wysiwyg.viewer.components.PopupCloseTextButton': {
    componentType: 'wysiwyg.viewer.components.PopupCloseTextButton',
    label: 'PopupCloseTextButton',
    dataId: 'popupCloseTextButtonDefaultData',
    propId: 'popupCloseTextButtonDefaultProps',
    addPanelLayout: {
      width: 142,
      height: 40,
    },
    designPanelLayout: {
      width: 110,
      height: 32,
    },
    data: {
      label: translate('POPUPS_Close_Button_Default_Text'),
      link: null,
      type: 'LinkableButton',
    },
    props: {
      align: 'center',
      margin: 0,
      type: 'ButtonProperties',
    },
  },
  'wysiwyg.viewer.components.HoverBox': {
    componentType: 'wysiwyg.viewer.components.PopupCloseTextButton',
    label: 'HoverBox',
    dataId: 'hoverBoxDefaultData',
    propId: 'hoverBoxDefaultProps',
    designPanelLayout: {
      width: 110,
      height: 32,
    },
    props: {
      align: 'center',
      margin: 0,
      type: 'HoverBoxProperties',
    },
  },
  'wixui.StylableHorizontalMenu': {
    componentType: 'wixui.StylableHorizontalMenu',
    label: 'StylableHorizontalMenu',
    dataId: 'CustomMenuDefaultData',
    propId: 'stylableHorizontalDefaultProp',
    addPanelLayout: {
      width: 304,
      height: 30,
    },
    designPanelLayout: {
      width: 240,
      height: 24,
    },
    stageLayout: {
      width: 708,
      height: 50,
    },
    data: {
      menuRef: 'CUSTOM_MAIN_MENU',
      id: 'StylableHorizontalMenuDataRef',
      type: 'CustomMenuDataRef',
    },
    dataQuery: '#MAIN_MENU',
    additionalMatserPageData: mainMenuItems,
    props: {
      type: 'StylableHorizontalMenuProperties',
      menuMode: 'wrap',
      submenuMode: 'columnMenu',
      submenuOrder: 'vertical',
    },
  },
};

export default {
  getComponentDefault(
    compClassName: keyof typeof COMPONENTS_DEFAULT_DATA,
    panelLayoutType?: AnyFixMe,
    siteId?: AnyFixMe,
  ): ComponentDefaultData {
    let compDef = _.cloneDeep(COMPONENTS_DEFAULT_DATA[compClassName]);
    if (compDef) {
      compDef.siteId = siteId;

      if (panelLayoutType === addPanelDataConsts.PANEL_TYPES.DESIGN_PANEL) {
        compDef.panelLayout = compDef.designPanelLayout;
      } else {
        compDef.panelLayout = compDef.addPanelLayout;
      }

      if (compDef.data) {
        compDef.data = _.defaults(compDef.data, {
          metaData: createDefaultMetaData(),
        });
      }

      if (compDef.props) {
        compDef.props = _.merge(compDef.props, {
          metaData: createDefaultMetaData(),
        });
      }

      compDef = _.omit(compDef, ['addPanelLayout', 'designPanelLayout']);
    }
    return compDef;
  },
};

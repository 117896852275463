import React from 'react';
import * as panels from '#packages/panels';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import {
  mapStateToProps,
  mapDispatchToProps,
  type QrCodePublishedSitePanelStateProps,
  type QrCodePublishedSitePanelDispatchProps,
} from './qrCodePublishedSitePanelMapper';

import { Text, TextButton } from '@wix/wix-base-ui';

export interface QrCodePublishedSitePanelOwnProps {
  panelName: string;
}

export interface QrCodePublishedSitePanelProps
  extends QrCodePublishedSitePanelStateProps,
    QrCodePublishedSitePanelDispatchProps,
    QrCodePublishedSitePanelOwnProps {}

class QrCodePublishedSitePanel extends React.Component<QrCodePublishedSitePanelProps> {
  closePanel = () => {
    this.props.closePanelByName(this.props.panelName);
  };

  handleGoToMobileEditor = () => {
    this.props.sendBi(
      coreBi.events.mobileEditor.MOBILE_QRCODE_GO_TO_MOBILE_EDITOR,
      {
        origin: this.props.panelName,
      },
    );
    this.props.sendBi(coreBi.events.mobileEditor.MOBILE_EDITOR_READY, {
      origin: this.props.panelName,
      isFirst: this.props.hasUserSeenMobileEditor,
    });
    this.props.goToMobileEditor();
  };

  handleHelpClick = () => {
    this.props.openHelpCenter('4bb45b5d-5269-403e-9980-012851ad6fc8');
    this.props.sendBi(coreBi.events.help.HELP_CLICK, {
      panel_name: this.props.panelName,
      origin: 'panel',
      helpId: '4bb45b5d-5269-403e-9980-012851ad6fc8',
    });
  };

  render() {
    return (
      <panels.frames.PromotionalPanelFrame
        panelName={this.props.panelName}
        className="qrcode-published-modal"
        onCloseButtonClick={this.closePanel}
        title={translate('QR_View_Mobile_Site_Modal_Title')}
        primaryButtonText={translate('QR_View_Mobile_Site_Modal_Done_Button')}
        onPrimaryButtonClick={this.closePanel}
        onHelpButtonClick={this.handleHelpClick}
        illustration={
          <img
            className="qrcode-published-modal-image"
            src={this.props.qrCodeUrl}
          />
        }
        footnote={
          this.props.isDesktopEditor ? (
            <footer>
              <div className="qrcode-published-modal-footer">
                <Text
                  skin="standard"
                  size="small"
                  weight="normal"
                  enableEllipsis={false}
                >
                  {'QR_View_Mobile_Site_Modal_Footer_Text'}
                </Text>
                &nbsp;
                <TextButton
                  className="qrcode-published-modal-link"
                  size="small"
                  onClick={() => {
                    this.closePanel();
                    this.handleGoToMobileEditor();
                  }}
                >
                  {'QR_View_Mobile_Site_Modal_Footer_Link'}
                </TextButton>
              </div>
            </footer>
          ) : null
        }
      >
        <Text
          skin="standard"
          size="medium"
          weight="normal"
          enableEllipsis={false}
        >
          {'QR_View_Mobile_Site_Modal_Text'}
        </Text>
      </panels.frames.PromotionalPanelFrame>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(QrCodePublishedSitePanel);

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import * as superAppBi from '../../../bi/superAppBi';
import React from 'react';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import * as symbols from '@wix/santa-editor-symbols';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'WelcomeSlide',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    onClose: PropTypes.func.isRequired,
    nextSlide: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    details: PropTypes.string.isRequired,
    nextButtonLabel: PropTypes.string.isRequired,
    teaser: PropTypes.string,
    installedImage: PropTypes.string.isRequired,
  },
  onNextClick() {
    const superAppProps = this.props.superApp;
    const params = {
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(
      superAppBi.events.firstTimeTour[this.props.type]
        .STEP_2_WELCOME_CONTINUE_CLICK,
      params,
    );
    this.props.nextSlide();
  },
  onClose() {
    const superAppProps = this.props.superApp;
    const params = {
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(
      superAppBi.events.firstTimeTour[this.props.type]
        .STEP_2_WELCOME_CLOSE_CLICK,
      params,
    );
    this.props.onClose();
  },
  render() {
    return (
      <div className="welcome-slide">
        <div onClick={this.onClose} className="close">
          <symbols.symbol
            name="headerCloseButton"
            className="headerCloseButton"
          />
        </div>
        <symbols.symbol
          name={this.props.installedImage}
          className="installed-image"
        />
        <h1 className="title">{translate(this.props.title)}</h1>

        <p className="sub-title">{translate(this.props.details)}</p>
        {this.props.teaser ? (
          <p key="teaser">{translate(this.props.teaser)}</p>
        ) : null}

        <baseUI.button
          label={this.props.nextButtonLabel}
          onClick={this.onNextClick}
          className="welcome-add-btn"
        />
      </div>
    );
  },
});

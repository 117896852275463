// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const title = 'add_section_label_applicationforms';
const presetTitle = 'add_section_label_applicationforms_preset';
const tooltipTitle = 'add_section_label_applicationforms_tooltip_title';
const subNavigationTitle = 'add_section_label_application_sidebar';
const helpText = 'add_section_label_applicationforms_tooltip_description';

const getJobApplicationSection = (editorAPI) => ({
  jobApplicationSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title,
    topTitle: title,
    tooltipTitle,
    automationId: 'add-panel-section-jobApplicationSection',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    showSectionHeader: true,
    subNavigationTitle,
    subNavigationHide: false,
    presetTitle,
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsJobApplicationSection_en/formsJobApplicationSection_en.v7.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Job_Application_Forms_1',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION05,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION05,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 559,
              height: 514,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: { rect: { width: 162, height: 174, x: 0, y: 0 }, tags: null },
        },
        {
          id: 'Job_Application_Forms_2',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION06,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION06,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 480,
              height: 908,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 136, x: 174, y: 0 },
            tags: null,
          },
        },

        {
          id: 'Job_Application_Forms_3',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION07,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION07,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 547,
              height: 770,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 174, x: 0, y: 174 },
            tags: null,
          },
        },
        {
          id: 'Job_Application_Forms_4',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION08,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.JOB_APPLICATION08,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 428,
              height: 584,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.MULTI_STEP_FORM,
          }),
          preset: {
            rect: { width: 162, height: 174, x: 162, y: 174 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: helpText,
    },
  },
});

export default (editorAPI) => getJobApplicationSection(editorAPI);

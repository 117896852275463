import React from 'react';
import * as baseUI from '#packages/baseUI';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import * as symbols from '@wix/santa-editor-symbols';
import * as stateManagement from '#packages/stateManagement';
import type { CompRef } from 'types/documentServices';
import { translate } from '#packages/i18n';
import { TextLabel, Tooltip } from '@wix/wix-base-ui';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

const actions = stateManagement.mobile.actions.interactionsHiddenItems;
const timeoutDelay = 500;

export interface InteractionsMobileHiddenItemDef {
  label: string;
  compRef: CompRef;
  compId: string;
  svgName?: string;
  iconUrl?: string;
  css?: React.CSSProperties;
  pageId: string;
  previewReAddComponent: (compRef: CompRef, pageId: string) => void;
  stopPreviewReAddComponent: (compRef: CompRef) => void;
  reAddInteractionsComponent: (compId: string, pageId: string) => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

export interface InteractionsMobileHiddenItemProps
  extends InteractionsMobileHiddenItemDef {}

export class InteractionsMobileHiddenItem extends React.Component<InteractionsMobileHiddenItemProps> {
  static displayName = 'InteractionsMobileHiddenItem';

  state = {
    timeoutDelayFinished: false,
  };

  showComponent = () => {
    this.props.reAddInteractionsComponent(this.props.compId, this.props.pageId);
  };

  private timeoutId: number;

  cancelTimeout = () => {
    window.clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
  };

  stopPreviewReAddComponent = () => {
    this.cancelTimeout();
    if (this.state.timeoutDelayFinished) {
      this.setState({ timeoutDelayFinished: false });
      this.props.stopPreviewReAddComponent(this.props.compRef);
    }
  };

  previewReAddComponent = () => {
    const alreadyDuringPreview = !!(
      this.timeoutId || this.state.timeoutDelayFinished
    );
    if (alreadyDuringPreview) {
      return;
    }

    this.timeoutId = window.setTimeout(() => {
      this.setState({ timeoutDelayFinished: true });
      const { compRef, pageId } = this.props;
      this.props.previewReAddComponent(compRef, pageId);
    }, timeoutDelay);
    this.props.sendBi(coreBi.events.layers.hover_hidden_element, {
      component_id: this.props.compId,
      component_type: this.props.compRef.type,
      current_page_name: this.props.pageId,
    });
  };

  getCompIconStyle() {
    return this.props.css
      ? Object.assign({}, this.props.css, {
          backgroundImage: `url(${this.props.iconUrl})`,
        })
      : {};
  }

  getHoverInteractionSymbol() {
    return (
      <symbols.symbol
        key="hoverInteractionIcon"
        name="hoverInteraction"
        className="hover-symbol"
      />
    );
  }

  render() {
    return (
      <div
        className="interaction-mobile-hidden-item"
        onClick={this.showComponent}
        onMouseEnter={this.previewReAddComponent}
        onMouseLeave={this.stopPreviewReAddComponent}
      >
        <div className="icon" style={this.getCompIconStyle()}>
          {this.props.svgName && (
            <symbols.symbol key="hiddenItemIcon" name={this.props.svgName} />
          )}
        </div>
        <div className="label">
          <span>
            <TextLabel
              shouldTranslate={false}
              value={this.props.label}
              enableEllipsis={true}
              automationId="text-label"
            />
            {this.getHoverInteractionSymbol()}
            <div>
              <TextLabel
                className="hover-label"
                type="T12"
                value="mobile_hidden_items_section_title_interactions"
              />
            </div>
          </span>
        </div>
        <Tooltip content="mobile_hidden_items_show_tooltip">
          <div className="show-button">
            <baseUI.symbol name="showComp" key="show-icon" className="show" />{' '}
            <span className="text">
              {translate('mobile_hidden_items_show_button')}
            </span>
          </div>
        </Tooltip>
      </div>
    );
  }
}

const dispatchToProps = {
  previewReAddComponent: actions.previewReAddComponent,
  stopPreviewReAddComponent: actions.stopPreviewReAddComponent,
  reAddInteractionsComponent: actions.reAddInteractionsComponent,
};

export default util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  null,
  dispatchToProps,
)(InteractionsMobileHiddenItem);

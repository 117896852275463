import * as util from '#packages/util';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const { CATEGORIES_ID } = addPanelDataConsts;
const addPanelOverrideUtil = util.overrideUtils.addPanel;

interface InteractionsAddPanelDescriptor {
  isReapeaterItem: boolean;
}
interface GroupDefinition {
  sections: string[];
}

interface CategoryDefinition {
  categoryId: string;
  groups: GroupDefinition[];
}

const defaultInteractionsCategoriesDefinitions: CategoryDefinition[] = [
  {
    categoryId: CATEGORIES_ID.TEXT,
    groups: [
      {
        sections: ['section_1', 'titlesSection', 'paragraphsSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.IMAGE,
    groups: [
      {
        sections: [
          'myUploadsSection',
          'imageCollectionsSection',
          'mySocialImagesSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.BUTTON,
    groups: [
      {
        sections: [
          'cashierButtons',
          'stylableButtonTheme',
          'section_1',
          'section_2',
          'stylableButtonSection',
          'textButtonsSection',
          'iconButtonsSection',
          'fileDownloadButtonsSection',
          'fileUploaderSection',
          'iTunesDownloadButtonSection',
        ],
      },
      {
        sections: ['payButtonSection', 'paypalButtonsSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.BOX,
    groups: [
      {
        sections: ['section_1', 'section_2', 'containerBoxesSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.DECORATIVE,
    groups: [
      {
        sections: [
          'myLinesSection',
          'themedLinesSection',
          'basicShapesSection',
          'arrowsSection',
          'horizontalLinesSection',
          'verticalLinesSection',
        ],
      },
      {
        sections: [
          'featuredSection',
          'iconsSection',
          'animalNatureSection',
          'logosBadgesSection',
          'charactersSection',
        ],
      },
      {
        sections: ['videoBoxSection', 'videoBoxTransparentSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.GALLERY,
    groups: [
      {
        sections: [
          'gridGalleriesSection',
          'sliderGalleriesSection',
          'moreGalleriesSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.MEDIA,
    groups: [
      {
        sections: ['socialVideoSection', 'videoBoxSection'],
      },
      {
        sections: [
          'musicThemeSection',
          'spotifyPlayerSection',
          'iTunesDownloadButtonSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.NAVIGATION,
    groups: [
      {
        sections: [
          'menuMySection',
          'menuThemeSection',
          'stylableMenuTheme',
          'horizontalMenusSection',
          'verticalMenusSection',
          'verticalAnchorsMenuSection',
          'languageSelectorSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.CONTACT,
    groups: [
      {
        sections: [
          'contactSection',
          'paymentSection',
          'multiStepSection',
          'donationsSection',
          'salesLeadSection',
          'jobApplicationSection',
          'supportSection',
          'testimonialSection',
          'feedbackSection',
          'contestsSection',
          'requestSection',
          'waiverSection',
          'quizSection',
          'priceQuotesSection',
          'subscribersSection',
        ],
      },
      {
        sections: [
          'googleMapsSquareSection',
          'googleMapsGenericSection',
          'googleMapsStripSection',
        ],
      },
      {
        sections: ['lineSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.BOX_SLIDE_SHOW,
    groups: [
      {
        sections: ['boxSlideShow980Section', 'boxSlideShowSection'],
      },
      {
        sections: [],
      },
      {
        sections: ['stateBoxSection'],
      },
      {
        sections: ['progressBarSection', 'paginationSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.REPEATER,
    groups: [
      {
        sections: ['repeaterSection', 'gridSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.SOCIAL,
    groups: [
      {
        sections: [
          'socialBarSection',
          'facebookSection',
          'twitterSection',
          'pinterestSection',
          'vkSection',
          'lineSection',
        ],
      },
      {
        sections: ['youtubeSocialSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.DEVELOPER,
    groups: [
      {
        sections: ['textInputSection', 'textAreaSection', 'richTextBoxSection'],
      },
      {
        sections: [
          'radioGroupSection',
          'checkboxGroupSection',
          'checkboxSection',
          'selectionSection',
          'selectionTagsListSection',
          'datePickerSection',
          'timePickerSection',
          'sliderSection',
        ],
      },
      {
        sections: [
          'fileUploaderSection',
          'ratingsInputSection',
          'ratingsDisplaySection',
          'addressInputSection',
          'toggleSwitchSection',
          'captchaSection',
          'signatureInputSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.EMBEDS,
    groups: [
      {
        sections: ['htmlFlashSection'],
      },
      {
        sections: ['socialVideoSection'],
      },
      {
        sections: ['spotifyPlayerSection', 'iTunesDownloadButtonSection'],
      },
    ],
  },
];

const repeaterInteractionsCategoriesDefinitions: CategoryDefinition[] = [
  {
    categoryId: CATEGORIES_ID.TEXT,
    groups: [
      {
        sections: ['section_1', 'titlesSection', 'paragraphsSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.IMAGE,
    groups: [
      {
        sections: [
          'myUploadsSection',
          'imageCollectionsSection',
          'mySocialImagesSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.BUTTON,
    groups: [
      {
        sections: [
          'cashierButtons',
          'stylableButtonTheme',
          'section_1',
          'section_2',
          'stylableButtonSection',
          'textButtonsSection',
          'iconButtonsSection',
          'fileDownloadButtonsSection',
          'fileUploaderSection',
          'iTunesDownloadButtonSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.BOX,
    groups: [
      {
        sections: ['section_1', 'section_2', 'containerBoxesSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.DECORATIVE,
    groups: [
      {
        sections: [
          'myLinesSection',
          'themedLinesSection',
          'basicShapesSection',
          'arrowsSection',
          'horizontalLinesSection',
          'verticalLinesSection',
        ],
      },
      {
        sections: [
          'featuredSection',
          'iconsSection',
          'animalNatureSection',
          'logosBadgesSection',
          'charactersSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.GALLERY,
    groups: [
      {
        sections: [
          'gridGalleriesSection',
          'sliderGalleriesSection',
          'moreGalleriesSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.MEDIA,
    groups: [
      {
        sections: ['socialVideoSection'],
      },
      {
        sections: ['musicThemeSection', 'iTunesDownloadButtonSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.SOCIAL,
    groups: [
      {
        sections: ['socialBarSection'],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.DEVELOPER,
    groups: [
      {
        sections: ['textInputSection', 'textAreaSection', 'richTextBoxSection'],
      },
      {
        sections: [
          'radioGroupSection',
          'checkboxGroupSection',
          'checkboxSection',
          'selectionSection',
          'selectionTagsListSection',
          'datePickerSection',
          'timePickerSection',
          'sliderSection',
        ],
      },
      {
        sections: [
          'fileUploaderSection',
          'ratingsInputSection',
          'ratingsDisplaySection',
          'addressInputSection',
          'toggleSwitchSection',
          'captchaSection',
          'signatureInputSection',
        ],
      },
    ],
  },
  {
    categoryId: CATEGORIES_ID.EMBEDS,
    groups: [
      {
        sections: ['htmlFlashSection'],
      },
      {
        sections: ['socialVideoSection'],
      },
      {
        sections: ['iTunesDownloadButtonSection'],
      },
    ],
  },
];

function getCategoriesDefinitions({
  isReapeaterItem,
}: AnyFixMe): CategoryDefinition[] {
  if (isReapeaterItem) {
    return repeaterInteractionsCategoriesDefinitions;
  }
  return defaultInteractionsCategoriesDefinitions;
}

export function getInteractionsAddPanelOverrides(
  interactionAddPanelDescriptor: InteractionsAddPanelDescriptor,
): CategoryDefinition[] {
  return addPanelOverrideUtil.mapOverrideConfigToCategoriesGroupSchema(
    getCategoriesDefinitions(interactionAddPanelDescriptor),
  );
}

import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import * as commonKeyboardActions from '../util/commonKeyboardActions';

import type { EditorAPI } from '#packages/editorAPI';

const AI_WRITER_SHORTCUTS_CONFIG = {};

const { editor: editorKeyboardMap } = keyboardShortcutsActionMap;

function addAiWriterShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, (event?: KeyboardEvent) => void> = {};
  keysToMerge[editorKeyboardMap.SAVE.key] = () =>
    commonKeyboardActions.saveSiteFromKeyboard(editorAPI);

  keysToMerge[editorKeyboardMap.PUBLISH_SITE.key] = (event: KeyboardEvent) =>
    commonKeyboardActions.publishSiteFromKeyboard(editorAPI, event);

  keysToMerge[editorKeyboardMap.TOGGLE_PREVIEW.key] = (event: KeyboardEvent) =>
    commonKeyboardActions.togglePreviewFromKeyboard(editorAPI, event);

  Object.assign(AI_WRITER_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addAiWriterShortcuts(editorAPI);
  },
  shortcuts: AI_WRITER_SHORTCUTS_CONFIG,
};

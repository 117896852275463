// @ts-nocheck
import type { EditorAPI } from '#packages/rEditor';
import { translate } from '#packages/i18n';
import experiment from 'experiment';
import { contactFormDeprecationConfiguration } from '../constants/contactFormComponentDeprecationConstants';

export type DeprecatedComponents = {
  [key in string]: {
    helpId?: string;
    defaultDeprecationModalOverrides?: {
      onContinue?: () => void;
      title?: string;
      description?: {
        firstSentenceText?: string;
        secondSentenceText?: string;
        thirdSentenceText?: string;
      };
      mainCtaText?: string;
    };
    modalToOpen?: string;
  };
};

const deprecatedComponents: DeprecatedComponents = {
  /*
  ExampleComponent: {
    modalToOpen: 'componentDeprecation.panels.componentDeprecationPanel',
    helpId: 'f7055245-a1e4-4d1b-947d-46356832eace',
  },
  */
  'wysiwyg.viewer.components.DynamicContactForm':
    contactFormDeprecationConfiguration,
  'wysiwyg.viewer.components.ContactForm': contactFormDeprecationConfiguration,
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm':
    contactFormDeprecationConfiguration,
};

const SITE_WITH_DEPRECATED_COMPONENT_EXPERIMENTS_LIST = [
  'se_oldContactFormDeprecation',
];

function siteHasDeprecatedComponents(editorAPI: EditorAPI) {
  return editorAPI.components
    .getAllComponents_DEPRECATED_BAD_PERFORMANCE()
    .some(
      (compRef) =>
        editorAPI.documentServices.components.getType(compRef) ===
        'wixui.UnsupportedComponent',
    );
}

function isAnyDeprecationSiteExperimentOpen() {
  return SITE_WITH_DEPRECATED_COMPONENT_EXPERIMENTS_LIST.some((exp: string) =>
    experiment.isOpen(exp),
  );
}

const getPageAppDefId = (editorAPI: EditorAPI, pageData) =>
  editorAPI.dsRead.tpa.section.getPageData(pageData.id)?.appData
    ?.appDefinitionId;

function getHelpIdByComponentType(componentType): string {
  return deprecatedComponents[componentType]?.helpId
    ? deprecatedComponents[componentType]?.helpId
    : '6b15adba-4a08-406a-8cfb-0aebbb2f5b42';
}

function getModalToOpenOverridesByComponentType(componentType) {
  return deprecatedComponents[componentType]?.defaultDeprecationModalOverrides;
}

function getModalToOpenByComponentType(componentType) {
  return deprecatedComponents[componentType]?.modalToOpen
    ? deprecatedComponents[componentType]?.modalToOpen
    : 'componentDeprecation.panels.componentDeprecationPanel';
}

function getCompName(editorAPI: EditorAPI, compRef: CompRef) {
  const compData = editorAPI.components.data.get(compRef);
  return translate('component_label_deprecated', {
    ComponentName: compData.migratedFrom.split('.').pop(),
  });
}

export {
  siteHasDeprecatedComponents,
  getPageAppDefId,
  getModalToOpenByComponentType,
  getHelpIdByComponentType,
  getCompName,
  isAnyDeprecationSiteExperimentOpen,
  getModalToOpenOverridesByComponentType,
};

// @ts-nocheck
import ReactDOM from 'reactDOM';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Bubble } from '@wix/wix-base-ui';

import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import { cx } from '#packages/util';

import HowToVideoTooltipContent from '../howToVideoTooltipContent/howToVideoTooltipContent';

function template() {
  return (
    <div
      className={cx('anchors-navigation open', {
        'larger-than-window': this.state.largerThanWindow,
      })}
    >
      <div className="anchors-nav-section-wrapper">
        <ul key="sections" className="anchors-nav-sections">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.sectionsWithHeaders, (section) => (
            <li
              onClick={() => {
                this.handleClickAnchor(section);
              }}
              onMouseEnter={() => {
                this.handleMouseEnterAnchor(section);
              }}
              onMouseLeave={() => {
                this.handleMouseLeaveAnchor();
              }}
              key={section.id}
              className={cx('anchors-nav-section-item', {
                selected: this.state.selected.id === section.id,
              })}
            >
              <div className="anchors-nav-pin" />
              {this.isTooltipOpen(section) ? (
                <Bubble
                  contentStyle={this.getBubbleContentStyle()}
                  arrowStyle={this.getBubbleArrowStyle()}
                  arrowAlignment="left"
                  key={`bubble-${section.id}`}
                >
                  <HowToVideoTooltipContent
                    onClose={this.handleCloseTooltip}
                    onMouseEnter={() => {
                      this.handleMouseEnterTooltip(section);
                    }}
                    onMouseLeave={this.handleMouseLeaveTooltip}
                    sectionKey={section.title}
                  />
                </Bubble>
              ) : null}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const GAP_FROM_TOP = 72;
const GAP_FROM_BOTTOM = 24;
const LONG_DELAY = 1000;
const SHORT_DELAY = 350;

function getFromPrefIsTooltipClosed(editorAPI) {
  return stateManagement.userPreferences.selectors.getSessionUserPreferences(
    'isHowToVideoTooltipClosed',
  )(editorAPI.store.getState());
}

function setInPrefIsTooltipClosed(editorAPI, value) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      'isHowToVideoTooltipClosed',
      Boolean(value),
    ),
  );
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'anchorsNavigation',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    sectionsWithHeaders: PropTypes.array.isRequired,
    allSections: PropTypes.array.isRequired,
    categoryId: PropTypes.string.isRequired,
    setTopSection: PropTypes.func.isRequired,
    notifyCategoryViewOnStateChange: PropTypes.func.isRequired,
    selected: PropTypes.object.isRequired,
    disableTooltip: PropTypes.bool,
    overrideTooltipId: PropTypes.string,
    timeoutHandler: PropTypes.object,
  },
  getDefaultProps() {
    return {
      disableTooltip: false,
      overrideTooltipId: null,
      timeoutHandler: window,
    };
  },
  getInitialState() {
    return {
      hover: false,
      selected: this.props.selected,
      largerThanWindow: false,
      openedTooltipId: null,
      prevOpenedTooltipId: null,
      isTooltipClosed: getFromPrefIsTooltipClosed(this.getEditorAPI()),
    };
  },
  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.checkIfLargerThanWindow);
  },
  componentDidMount() {
    this.mounted = true;
    this.checkIfLargerThanWindow();
    this.props.notifyCategoryViewOnStateChange(true);

    this.openTooltipAsync(this.state.selected.id, LONG_DELAY);
  },
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.isLongDelay = nextProps.categoryId !== this.props.categoryId;
    this.setSelectedSection(nextProps.selected);
  },
  componentDidUpdate() {
    this.checkIfLargerThanWindow();
  },
  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfLargerThanWindow);
    this.mounted = false;
  },
  render() {
    return template.call(this);
  },
  openTooltipAsync(sectionId, delay) {
    this.props.timeoutHandler.clearTimeout(this.tooltipTimeout);
    this.tooltipTimeout = this.props.timeoutHandler.setTimeout(
      function () {
        if (this.mounted === true) {
          this.setState({
            openedTooltipId: sectionId,
            prevOpenedTooltipId: sectionId,
          });
        }
      }.bind(this),
      delay,
    );
  },
  checkIfLargerThanWindow() {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode) {
      return;
    }
    const largerThanWindow =
      window.innerHeight -
        domNode.offsetHeight -
        GAP_FROM_TOP -
        GAP_FROM_BOTTOM <
      0;
    if (this.state.largerThanWindow !== largerThanWindow) {
      this.setState({ largerThanWindow });
    }
  },
  setSelectedSection(section) {
    const tooltipDelay = this.isLongDelay ? LONG_DELAY : SHORT_DELAY;
    this.isLongDelay = false;
    const callback = this.openTooltipAsync.bind(this, section.id, tooltipDelay);
    this.setState({ selected: section }, callback);
  },
  handleClickAnchor(section) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const sectionOriginalIndex = _.findIndex(this.props.allSections, {
      id: section.id,
    });
    this.props.setTopSection(sectionOriginalIndex, true);
    this.props.timeoutHandler.clearTimeout(this.tooltipTimeout);
    const callback = _.partial(
      setInPrefIsTooltipClosed,
      this.getEditorAPI(),
      false,
    );
    this.setState(
      {
        selected: section,
        selectionMade: true,
        openedTooltipId: section.id,
        prevOpenedTooltipId: section.id,
        isTooltipClosed: false,
      },
      callback,
    );
    const biEventId =
      coreBi.events.addPanel.ADD_MENU_INNER_NAVIGATION_CHOOSE_SECTION_CLICK;
    const biEventParams = {
      category: this.props.categoryId,
      section: section.title,
    };
    this.getEditorAPI().bi.event(biEventId, biEventParams);
  },
  handleMouseEnterAnchor(section) {
    this.props.timeoutHandler.clearTimeout(this.tooltipTimeout);
    this.setState({ openedTooltipId: section.id, hover: true });
  },
  handleMouseLeaveAnchor() {
    this.props.timeoutHandler.clearTimeout(this.tooltipTimeout);
    this.tooltipTimeout = this.props.timeoutHandler.setTimeout(
      function () {
        this.setState({
          openedTooltipId: this.state.prevOpenedTooltipId,
          hover: false,
        });
      }.bind(this),
      SHORT_DELAY,
    );
  },
  handleMouseEnterTooltip(section) {
    this.props.timeoutHandler.clearTimeout(this.tooltipTimeout);
    this.setState({ openedTooltipId: section.id, hover: true });
  },
  handleMouseLeaveTooltip() {
    this.props.timeoutHandler.clearTimeout(this.tooltipTimeout);
    this.setState({
      openedTooltipId: this.state.prevOpenedTooltipId,
      hover: false,
    });
  },
  handleCloseTooltip(e) {
    e.stopPropagation();
    const callback = _.partial(
      setInPrefIsTooltipClosed,
      this.getEditorAPI(),
      true,
    );
    this.setState({ isTooltipClosed: true, hover: false }, callback);
  },
  isTooltipOpen(section) {
    if (this.state.largerThanWindow) {
      return false;
    }

    if (this.props.overrideTooltipId) {
      return this.props.overrideTooltipId === section.id;
    }

    const shouldBeClosed = this.state.isTooltipClosed && !this.state.hover;
    const isDisabledByContainer = this.props.disableTooltip;
    const isIdMatches = this.state.openedTooltipId === section.id;
    return !shouldBeClosed && !isDisabledByContainer && isIdMatches;
  },
  getBubbleContentStyle() {
    return {
      position: 'absolute',
      left: '43px',
      top: '-16px',
      width: '240px',
    };
  },
  getBubbleArrowStyle() {
    return {
      position: 'absolute',
      left: '35px',
      top: '1px',
    };
  },
});

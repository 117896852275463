import _ from 'lodash';
import React from 'react';
import constants from '#packages/constants';
import { cx } from '#packages/util';
import * as UA from '#packages/uiAnimations';

import Tab, { type TabDefinition } from './tab/tab';

function getAnimationState(props: TabsControllerProps) {
  if (!props.tabIndicationState) {
    return {
      tabsAnimationStatus: _.times(props.tabs.length, _.constant(false)),
      tabsAnimationText: '',
    };
  }

  switch (props.tabIndicationState.indication) {
    case 'DELETE':
      return {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        tabsAnimationStatus: _.map(props.tabs, 'active'),
        tabsAnimationText: 'HOVER_BOX_NAVBAR_INDICATION_DELETED',
      };
    case 'APPLY':
      if (props.tabIndicationState.activeMode) {
        return {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/map
          tabsAnimationStatus: _.map(props.tabs, 'active'),
          tabsAnimationText: 'HOVER_BOX_NAVBAR_INDICATION_APPLIED',
        };
      }
      return {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        tabsAnimationStatus: _(props.tabs)
          .map('active')
          .map(_.negate(_.identity))
          .value(),
        tabsAnimationText: 'HOVER_BOX_NAVBAR_INDICATION_APPLIED',
      };
    case 'ADD':
      if (props.tabIndicationState.activeMode) {
        return {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/map
          tabsAnimationStatus: _.map(props.tabs, 'active'),
          tabsAnimationText: 'HOVER_BOX_NAVBAR_INDICATION_ATTACHED',
        };
      }
      return {
        tabsAnimationStatus: _.times(props.tabs.length, _.constant(true)),
        tabsAnimationText: 'HOVER_BOX_NAVBAR_INDICATION_ATTACHED',
      };
    default:
      return {
        tabsAnimationStatus: _.times(props.tabs.length, _.constant(false)),
      };
  }
}

interface TabsControllerProps {
  tabs: TabDefinition[];
  translate?: boolean;
  tabIndicationState?: {
    indication?: string;
    activeMode?: boolean;
  };
  type: string;
  onTabClick: (tab: TabDefinition) => void;
  isPositionTop: boolean;
  componentUIColor: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  componentUITheme: ValueOf<typeof constants.COMPONENT_UI_THEMES>;
  tabIndicationComponent: React.Component | null;
  className?: string;
  customTabs: string[];
}

export default class extends React.Component<TabsControllerProps> {
  static displayName = 'tabsController';

  state = getAnimationState(this.props);

  UNSAFE_componentWillReceiveProps(nextProps: TabsControllerProps) {
    this.setState(getAnimationState(nextProps));
  }

  getReversedTabs = () => {
    return _(this.props.tabs).cloneDeep().reverse();
  };

  getTabControllerClasses = () => {
    return {
      'tabs-controller': true,
      'components-ui-color-orange':
        this.props.componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
      'components-ui-theme-light':
        this.props.componentUITheme === constants.COMPONENT_UI_THEMES.LIGHT,
    };
  };

  tabIndexClass = (index: number) => {
    if (this.props.tabs.length === 1) {
      return 'left-most-tab right-most-tab';
    } else if (index === this.props.tabs.length - 1) {
      return 'right-most-tab';
    } else if (index === 0) {
      return 'left-most-tab';
    }
    return '';
  };

  render() {
    return (
      <div className={cx(this.props.className, this.getTabControllerClasses())}>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.tabs, (t, tIndex) => (
          <UA.tabIndicationAnimation
            selectors={{ label: '.label', action: '.action' }}
            key={`${tIndex}-${'id' in t ? t.id : t.modeId}`}
            direction={this.props.isPositionTop ? 1 : -1}
            immediatePlay={this.state.tabsAnimationStatus[tIndex]}
          >
            <Tab
              tab={t}
              tabClass={this.tabIndexClass(tIndex)}
              translate={this.props.translate}
              onTabClick={this.props.onTabClick}
              tabsAnimationText={this.state.tabsAnimationText}
              tabIndicationComponent={this.props.tabIndicationComponent}
              customTabs={this.props.customTabs}
            />
          </UA.tabIndicationAnimation>
        ))}
      </div>
    );
  }
}

import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 246,
        height: 21,
        x: 40,
        y: 45,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'tel6d',
      },
      parent: 'comp-kguvwtec',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'tel6d',
        },
        text: `<h2 class="font_2" style="font-size:20px; line-height:1em"><span style="font-size:20px"><span style="font-family:brandon-grot-w01-light,sans-serif"><span style="color:#292929">${translate(
          'interactions_preset_event_text_1',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'tel6d',
        },
        brightness: 1,
        packed: false,
        minHeight: 18,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 260,
          height: 16,
          x: 16,
          y: 16,
          scale: 0.7692307692307693,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'tel6d',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvwtel3',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 579,
        height: 63,
        x: 40,
        y: 76,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'tel6d',
      },
      parent: 'comp-kguvwtec',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'tel6d',
        },
        text: `<h2 class="font_2" style="font-size: 54px; line-height: 1em;"><span style="font-size:54px;"><span style="color:#000000"><span style="font-family:playfairdisplay-bold,playfair display,serif"><span style="letter-spacing:0em">${translate(
          'interactions_preset_event_text_2',
        )}</span></span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'tel6d',
        },
        brightness: 1,
        packed: false,
        minHeight: 55,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 260,
          height: 30,
          x: 16,
          y: 44,
          scale: 0.7407407407407407,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'tel6d',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvwtem2',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 246,
        height: 18,
        x: 40,
        y: 147,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'tel6d',
      },
      parent: 'comp-kguvwtec',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'tel6d',
        },
        text: `<h2 class="font_2" style="font-size:14px; line-height:1em"><span style="font-weight:bold"><span style="font-size:14px"><span style="color:rgb(41, 41, 41); font-family:brandon-grot-w01-light,sans-serif">${translate(
          'interactions_preset_event_text_3',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'tel6d',
        },
        brightness: 1,
        packed: false,
        minHeight: 18,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 260,
          height: 13,
          x: 16,
          y: 92,
          scale: 0.7692308,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'tel6d',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvwten2',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 424,
        height: 50,
        x: 40,
        y: 214,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'tel6d',
      },
      parent: 'comp-kguvwtec',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'tel6d',
        },
        text: `<p class="font_8" style="font-size: 15px; line-height: 1.5em;"><span style="letter-spacing:0.03em"><span style="font-family:avenir-lt-w01_35-light1475496,sans-serif"><span style="font-size:15px"><span style="color:#000000">${translate(
          'interactions_preset_event_text_4',
        )}</span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'tel6d',
        },
        brightness: 1,
        packed: false,
        minHeight: 50,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 247,
          height: 57,
          x: 16,
          y: 125,
          scale: 0.8666666666666667,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'tel6d',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kguvwtep',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 64,
        height: 63,
        x: 611,
        y: 264,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'tel6d',
      },
      parent: 'comp-kguvwtec',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'tel6d',
        },
        label: '',
        svgId: '11062b_099a271ce3574cd393e8edbf7553c9c1.svg',
      },
      props: {
        type: 'StylableButtonProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'tel6d',
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'tel6d',
        },
      },
      scopedTransformations: {
        'variants-kguvwtej': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'tel6d',
          },
          translate: {
            x: {
              type: 'px',
              value: 28.421052631578952,
            },
            y: {
              type: 'px',
              value: 27.36842105263158,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'tel6d',
        },
        style: {
          properties: {
            '$st-css':
              ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #000000}.root:hover{border: 1px solid #000000;background: rgba(0,0,0,0.85)}.root:hover::label{color: #000000}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;color: #FFFFFF;font-family: futura-lt-w01-book,sans-serif;font-size: 12px;letter-spacing: 0em;margin: 0px 4px 0px 0px;display: none}.root::icon{transition: inherit;fill: #FFFFFF;margin: 0px 0px 0px 4px;display: initial;width: 23px;height: 23px}.root:hover::icon{\n    fill: #FFFFFF}",
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
      mobileStructure: {
        layout: {
          width: 69,
          height: 69,
          x: 211,
          y: 220,
          scale: 0.9333333333333333,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kguvwteq',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 675,
    height: 327,
    x: 152,
    y: 86,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'tel6d',
  },
  parent: 'tel6d',
  variants: {
    'variants-kguvwtej': {
      componentId: 'comp-kguvwtec',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'tel6d',
      },
    },
  },
  transitions: {
    type: 'TransitionData',
    'timing-function': 'ease-in-out',
    duration: 0.4,
    delay: 0,
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'tel6d',
    },
  },
  transformations: {
    type: 'TransformData',
    origin: {
      x: {
        value: 50,
        type: 'percentage',
      },
      y: {
        value: 50,
        type: 'percentage',
      },
    },
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'tel6d',
    },
  },
  scopedTransformations: {
    'variants-kguvwtej': {
      type: 'TransformData',
      zLayer: 'BELOW_PINNED',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'tel6d',
      },
      scale: {
        x: 0.95,
        y: 0.95,
      },
      rotate: 0,
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'tel6d',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: '#D3E1F0',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 289,
      x: 20,
      y: 5,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kguvwtec',
      author: 'studio',
    },
  },
  activeModes: {},
};

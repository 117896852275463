import { DATA_BINDING } from '@wix/app-definition-ids';
import type { CompRef } from '@wix/document-services-types';
import { BasePublicApi } from '#packages/apilib';
import type { Scope } from './scope';

async function isDatasetUsingCursors(
  scope: Scope,
  { controllerRef }: { controllerRef: CompRef },
): Promise<boolean> {
  const { editorAPI } = scope;
  const databindingPublicApi =
    editorAPI.dsRead.platform.getAppPublicApi(DATA_BINDING);
  return databindingPublicApi.isDatasetUsingCursors({ controllerRef });
}

export class WixDataApi extends BasePublicApi<Scope> {
  isDatasetUsingCursors = this.bindScope(isDatasetUsingCursors);
}

import React from 'react';
import {
  Composites,
  InfoIcon,
  RadioButtons,
  TextLabel,
} from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';
import type { SignUpPrivacyNoteType } from 'types/documentServices';

export interface PrivacyNoteTypeOption {
  label: string;
  value: SignUpPrivacyNoteType;
}

interface SignUpSettingsCommunitySectionProps {
  privacyNoteType: SignUpPrivacyNoteType;
  privacyNoteTypeOptions: PrivacyNoteTypeOption[];
  onPrivacyNoteTypeChange: (privacyNoteType: SignUpPrivacyNoteType) => void;
  shouldShowJoinCommunityCheckbox: boolean;
  joinCommunityStatus: boolean;
  onJoinCommunityStatusChange: (joinCommunityStatus: boolean) => void;
}

const SignUpSettingsCommunitySection: React.FC<
  SignUpSettingsCommunitySectionProps
> = ({
  privacyNoteType,
  privacyNoteTypeOptions,
  onPrivacyNoteTypeChange,
  shouldShowJoinCommunityCheckbox,
  joinCommunityStatus,
  onJoinCommunityStatusChange,
}) => (
  <>
    <Composites.RadioButtonsLabeled className="privacy-note-type-option">
      <InfoIcon text="NewPages_Panel_Signup_Privacy_Note_Type_tooltip" />
      <TextLabel value="NewPages_Panel_Signup_Privacy_Note_Type_title" />
      <RadioButtons
        value={privacyNoteType}
        options={privacyNoteTypeOptions}
        onChange={onPrivacyNoteTypeChange}
      />
    </Composites.RadioButtonsLabeled>
    {shouldShowJoinCommunityCheckbox && (
      <baseUI.toggle
        name="switch"
        className="option join-community-option"
        label="Member_Login_Settings_Community_Join"
        value={joinCommunityStatus}
        onChange={onJoinCommunityStatusChange}
      />
    )}
  </>
);

export default SignUpSettingsCommunitySection;

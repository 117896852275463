import type { EditorAPI } from '#packages/editorAPI';
import button from './button/buttonCategory';
import gallery from './gallery/galleryCategory';
import image from './image/imageCategory';
import imageDynamic from './imageDynamic/imageDynamicCategory';
import box from './box/boxCategory';
import contact from './contact/contactCategory';
import menu from './menu/menuCategory';
import decorative from './decorative/decorativeCategory';
import navigation from './navigation/navigationCategory';
import media from './media/mediaCategory';
import embeds from './embeds/embedsCategory';
import shape from './shape/shapeCategory';
import vectorShape from './vectorShape/vectorShapeCategory';
import vectorArt from './vectorArt/vectorArtCategory';
import music from './music/musicCategory';
import social from './social/socialCategory';
import video from './video/videoCategory';
import * as list from './list/listCategory';
import other from './other/otherCategory';
import text from './text/textCategory';
import strip from './strip/stripCategory';
import page from './page/pageCategory';
import boxSlideShow from './boxSlideShow/boxSlideShowCategory';
import hoverBox from './hoverBox/hoverBoxCategory';
import popup from './popup/popupCategory';
import developer from './developer/developerCategory';
import repeater from './repeater/repeaterCategory';
import members from './members/membersCategory';
import membersWelcome from './membersWelcome/membersWelcomeCategory';
import newBlogWelcome from './newBlogWelcome/blogWelcomeCategory';
import database from './database/databaseCategory';
import bookingsWelcomeCategory from './bookingsWelcome/bookingsWelcomeCategory';
import bookingsCategory from './bookings/bookingsCategory';
import onlineProgramsWelcomeCategory from './onlineProgramsWelcome/onlineProgramsWelcomeCategory';
import onlineProgramsCategory from './onlinePrograms/onlineProgramsCategory';
import eventsWelcome from './eventsWelcome/eventsWelcomeCategory';
import savedComponents from './savedComponents/savedComponentsCategory';
import savedComponentsWelcome from './savedComponentsWelcome/savedComponentsWelcomeCategory';

const categoriesMapCache = new WeakMap();

function _uncachedGetCategoriesMap(editorAPI: EditorAPI) {
  return {
    button: button.getCategorySections(),
    gallery: gallery.getCategorySections(),
    box: box.getCategorySections(),
    strip: strip.getCategorySections(editorAPI),
    image: image.getCategorySections(),
    imageDynamic: imageDynamic.getCategorySections(),
    contact: contact.getCategorySections(editorAPI),
    menu: menu.getCategorySections(),
    decorative: decorative.getCategorySections(),
    navigation: navigation.getCategorySections(),
    media: media.getCategorySections(),
    embeds: embeds.getCategorySections(),
    shape: shape.getCategorySections(),
    vectorShape: vectorShape.getCategorySections(),
    vectorArt: vectorArt.getCategorySections(),
    music: music.getCategorySections(),
    social: social.getCategorySections(),
    text: text.getCategorySections(),
    video: video.getCategorySections(),
    list,
    other: other.getCategorySections(),
    page: page.getCategorySections(),
    members: members.getCategorySections(),
    membersWelcome: membersWelcome.getCategorySections(),
    bookings: bookingsCategory.getCategorySections(editorAPI),
    bookingsWelcome: bookingsWelcomeCategory.getCategorySections(),
    onlineProgramsWelcome: onlineProgramsWelcomeCategory.getCategorySections(),
    onlinePrograms: onlineProgramsCategory.getCategorySections(editorAPI),
    eventsWelcome: eventsWelcome.getCategorySections(),
    newBlogWelcome: newBlogWelcome.getCategorySections(),
    boxSlideShow: boxSlideShow.getCategorySections(editorAPI),
    hoverBox: hoverBox.getCategorySections(),
    popup: popup.getCategorySections(editorAPI),
    developer: developer.getCategorySections(editorAPI),
    repeater: repeater.getCategorySections(),
    database: database.getCategorySections(),
    savedComponents: savedComponents.getCategorySections(),
    savedComponentsWelcome: savedComponentsWelcome.getCategorySections(),
  };
}

export default {
  getCategoriesMap(editorAPI: AnyFixMe) {
    let categoriesMap = categoriesMapCache.get(editorAPI);

    if (!categoriesMap) {
      categoriesMap = _uncachedGetCategoriesMap(editorAPI);
      categoriesMapCache.set(editorAPI, categoriesMap);
    }

    return categoriesMap;
  },
};

import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 299,
        height: 300,
        x: 6,
        y: 6,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'zpbca',
      },
      parent: 'comp-kgt5sail',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        svgId: '06f469158f5346a3be7e4100e38370cd.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'zpbca',
        },
        displayMode: 'stretch',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        overrideColors: {
          color1: '#2E55DC',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 1,
          stroke: '#423737',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
      },
      scopedTransformations: {
        'variants-kgt5saiw': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'zpbca',
          },
          translate: {
            x: {
              type: 'px',
              value: -7,
            },
            y: {
              type: 'px',
              value: 23,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: 'i8yjldko',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 276,
          height: 286,
          x: -12,
          y: 7,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5saix2',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      layout: {
        width: 303,
        height: 304,
        x: 6,
        y: 6,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'zpbca',
      },
      parent: 'comp-kgt5sail',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        title: '',
        uri: '643a0bdb152046d3b9b7795e19397c90.jpg',
        description: '',
        width: 3840,
        height: 5073,
        alt: 'Waves',
        crop: {
          x: 0,
          y: 621,
          width: 3840,
          height: 3831,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'Waves',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'zpbca',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
      },
      scopedTransformations: {
        'variants-kgt5saiw': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'zpbca',
          },
          translate: {
            x: {
              type: 'px',
              value: 27,
            },
            y: {
              type: 'px',
              value: -9,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 263,
          height: 267,
          x: 13,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5saiz4',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 52,
        height: 52,
        x: 256,
        y: 6,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'zpbca',
      },
      parent: 'comp-kgt5sail',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        label: '',
        svgId: '98ba408c354ab496d16c2bbb3403fe57.svg',
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
      },
      scopedTransformations: {
        'variants-kgt5saiw': {
          rotate: 0,
          translate: {
            x: {
              type: 'px',
              value: 42,
            },
            y: {
              type: 'px',
              value: -27,
            },
          },
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'zpbca',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        style: {
          properties: {
            '$st-css':
              '.root:hover::icon {\n    fill: #000000;\n}:import{-st-from: \'wix-ui-santa/index.st.css\';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #FFFFFF;border: 2px solid #212121}.root:hover{\n    background: rgba(255,255,255,0.8)}.root:disabled{background: #E2E2E2}.root:disabled::label{color: #8F8F8F}.root:disabled::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-size: 14px;font-family: playfairdisplay-bold,"playfair display",serif;color: #000000}.root::icon{transition: inherit;fill: #000000;display: initial;width: 47px;height: 47px}.root:hover::label{color: #FFFFFF}@media value(mobileMediaQuery) {\n    .root::icon {\n        width: 28px;\n        height: 28px;\n    }\n}',
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
      mobileStructure: {
        layout: {
          width: 68,
          height: 68,
          x: 211,
          y: 0,
          scale: 0.9333333333333333,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5saj3',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 230,
        height: 40,
        x: 50,
        y: 16,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'zpbca',
      },
      parent: 'comp-kgt5sail',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
        text: `<h6 class="font_6" style="font-size:27px"><span style="font-size: 22px;"><span style="font-family: proxima-n-w01-reg;"><span style="font-weight: normal;"><span style="font-style: normal;"><span style="font-variant: normal;"><span style="color: rgb(66, 55, 55);"><span style="font-family:anton,sans-serif;"><span style="color:#1D2CF3"><span style="font-size:27px">${translate(
          'interactions_preset_remote_escapes_title',
        )}</span></span></span></span></span></span></span></span></span></h6>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'zpbca',
        },
        brightness: 1,
        packed: false,
        minHeight: 30,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'zpbca',
        },
      },
      scopedTransformations: {
        'variants-kgt5saiw': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'zpbca',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 87,
          height: 52,
          x: 41,
          y: 62,
          scale: 0.6791690061763055,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'zpbca',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5saj5',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 312,
    height: 318,
    x: 332,
    y: 91,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'zpbca',
  },
  parent: 'zpbca',
  variants: {
    'variants-kgt5saiw': {
      componentId: 'comp-kgt5sail',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'zpbca',
      },
    },
  },
  scopedTransformations: {
    'variants-kgt5saiw': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'zpbca',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'zpbca',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 280,
      x: 23,
      y: 18,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5sail',
      author: 'studio',
    },
  },
  activeModes: {},
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '#packages/core';
import * as pageMenuItemHelpers from '../../../utils/pageMenuItemHelpers';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import PageSettingsPanelTabsSubpage from '../../../settings/pageSettingsPanelTabsSubpage';
import PageSettingsDesktopCommon from './pageSettingsDesktopCommon';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'pageSettingsDesktopSubpage',
  propTypes: {
    menuItem: PropTypes.object.isRequired,
    actions: PropTypes.object,
    closeSettingsPanel: PropTypes.func.isRequired,
  },
  render() {
    return (
      <PageSettingsDesktopCommon
        title={this.props.menuItem.label}
        closeSettingsPanel={this.props.closeSettingsPanel}
        helpId="2f65df85-2eba-4b41-a9bf-2a841a5e5a53"
      >
        <PageSettingsPanelTabsSubpage
          ref="pageSettingsPanelTabs"
          {...this.props}
        />

        <footer>
          <baseUI.button
            label="Pages_Settings_Footer_Done"
            onClick={() => {
              this.props.closeSettingsPanel('done');
            }}
          />
        </footer>
      </PageSettingsDesktopCommon>
    );
  },
  shouldShowDuplicateButton() {
    return pageMenuItemHelpers.canDuplicate(
      this.getEditorAPI(),
      this.props.menuItem,
    );
  },
  shouldShowRemoveButton() {
    return pageMenuItemHelpers.canRemoveMenuItem(
      this.getEditorAPI(),
      this.props.menuItem,
    );
  },
  duplicatePage() {
    this.props.actions.duplicate(this.props.menuItem);
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_duplicate_page_click,
    );
  },
  deletePage() {
    const page = this.props.menuItem;
    const editorAPI = this.getEditorAPI();
    this.props.closeSettingsPanel('deletePage');
    editorAPI.dsActions.waitForChangesApplied(
      _.partial(pageMenuItemHelpers.removeMenuItem, editorAPI, page),
    );
    editorAPI.bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_delete_page_click,
    );
  },
});

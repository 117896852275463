import type { EditorAPI } from '#packages/editorAPI';
import type { AddPanelSection } from './types';
import { AddPanelSectionTypes } from '#packages/addPanelDataConsts';
import { createTPASectionAPI } from './tpaSectionAPI';
import { createPresetSectionAPI } from './presetSectionAPI';
import { createLiveSectionAPI } from './liveSectionAPI';
import { createImageSectionAPI } from './imageSectionAPI';
import { createDataSectionAPI } from './dataSectionAPI';
import { createDataHelpers } from './dataHelpers';

export const createAddPanelAPI = (editorAPI: EditorAPI) => {
  const tpaSectionAPI = createTPASectionAPI(editorAPI);
  const presetSectionAPI = createPresetSectionAPI(editorAPI);
  const liveSectionAPI = createLiveSectionAPI(editorAPI);
  const imageSectionAPI = createImageSectionAPI(editorAPI);
  const dataSectionAPI = createDataSectionAPI(editorAPI);

  const { getSectionData, resetSectionData } = createDataHelpers(editorAPI);

  const addFirstSectionItem = async (section: AddPanelSection) => {
    switch (section.type) {
      case AddPanelSectionTypes.DATA:
        await dataSectionAPI.add(section);
        break;

      case AddPanelSectionTypes.IMAGE:
        await imageSectionAPI.add(section);
        break;

      case AddPanelSectionTypes.PERSONALIZED:
      case AddPanelSectionTypes.THEME:
        await liveSectionAPI.add(section);
        break;

      case AddPanelSectionTypes.PRESET:
        if (tpaSectionAPI.isTPAPreset(section)) {
          await tpaSectionAPI.add(section);
          break;
        }
        await presetSectionAPI.add(section);
        break;

      default:
        break;
    }
  };

  const addComponent = async (section: AddPanelSection) => {
    await addFirstSectionItem(section);
  };

  return {
    getSectionData,
    addComponent,
    resetSectionData,
  };
};

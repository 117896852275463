:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._panel_1j5ln_7 {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 400px;
  height: 146px;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  display: flex;
  gap: 6px;
  z-index: 10000;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.08);
  box-sizing: border-box; }
  ._panel_1j5ln_7 .content {
    display: flex; }
  ._panel_1j5ln_7 ._contentWrapper_1j5ln_25 {
    padding: 12px 0 12px 12px;
    display: flex;
    gap: 16px;
    width: 100%; }
    ._panel_1j5ln_7 ._contentWrapper_1j5ln_25 ._imageWrapper_1j5ln_30 {
      display: flex;
      align-items: center; }
      ._panel_1j5ln_7 ._contentWrapper_1j5ln_25 ._imageWrapper_1j5ln_30 ._aiIllustration_1j5ln_33 {
        width: 72px;
        height: 72px; }
    ._panel_1j5ln_7 ._contentWrapper_1j5ln_25 ._mainContentWrapper_1j5ln_36 {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 12px; }
      ._panel_1j5ln_7 ._contentWrapper_1j5ln_25 ._mainContentWrapper_1j5ln_36 ._textContentWrapper_1j5ln_41 {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 2px;
        text-align: start; }
        ._panel_1j5ln_7 ._contentWrapper_1j5ln_25 ._mainContentWrapper_1j5ln_36 ._textContentWrapper_1j5ln_41 .weight-bold {
          font-weight: var(--wix-font-weight-bold); }
  ._panel_1j5ln_7 ._closeButton_1j5ln_49 svg {
    height: 9.6px;
    width: 9.6px; }

import styles from './styles/quickEditPanel.scss';
import React, { useEffect, useState } from 'react';
import { cx } from '#packages/util';
import { mapDispatchToProps, mapStateToProps } from './quickEditPanelMapper';
import { QUICK_EDIT_PANEL_WIDTH } from '../consts';
import { QuickEditEngineScope } from '../quickEditEngineEntryPoint';
import ComponentControlWrapper, {
  type QuickEditControlWrapperInnerOwnProps,
} from './componentControlWrapper';
import {
  Composites,
  PanelHeader,
  Preloader,
  WixBaseUiEnvironmentProvider,
} from '@wix/wix-base-ui';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import { InnerDrillIn } from './innerDrillIn';

import type { CompRef } from 'types/documentServices';
import type { WixBaseUiEnvironmentProviderProps } from '@wix/wix-base-ui/src/util/WixBaseUiEnvironment/WixBaseUiEnvironment';

export interface QuickEditPanelOwnProps
  extends QuickEditControlWrapperInnerOwnProps {
  shouldDisplayEmptyState: boolean;
  rootControlCompRef: CompRef;
  isLoading: boolean;
  onPanelReload: (compRef: CompRef) => void;
  baseUIOverrides: Partial<WixBaseUiEnvironmentProviderProps>;
  onPanelUnload: () => void;
  setSectionCorrelationId: () => void;
  panelStateId: string;
  selectedComponentId: string;
  onSelectedComponentChanged: (compId: string) => void;
  EmptyState: React.FC<{}>;
  panelTitle?: string;
  onHelp?: () => void;
  internalDrillIns?: boolean;
  allowedRootComponents: string[];
}

type QuickEditPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  QuickEditPanelOwnProps
>;

const QuickEditPanel: React.FC<QuickEditPanelProps> = ({
  shouldDisplayEmptyState,
  rootControlCompRef,
  isLoading,
  EmptyState,
  onPanelReload,
  baseUIOverrides,
  onPanelUnload,
  setSectionCorrelationId,
  getOverrideCompControls,
  selectedComponentId,
  onSelectedComponentChanged,
  onControlClick,
  onComponentChanged,
  isComponentFiltered,
  getPermissionsConfig,
  openComponentPanelDrillIn,
  onDrillInAction,
  onLinkAction,
  onControlHover,
  onControlLeave,
  onMainActionClick,
  getMainActionLabel,
  onGfppAction,
  getThumbnail,
  onBack,
  panelTitle,
  onHelp,
  panelStateId,
  internalDrillIns,
  contributedDrillInPanels,
  drillInPanelsContent,
  onPanelOpenInternalDrillIn,
  onPanelReloadWithInternalDrillIns,
  onPanelCloseWithInternalDrillIns,
  openComponentPanelInternalDrillIn,
  openQuickEditInternalDrillIn,
  mapCompTypeToDrillInPanel,
}: QuickEditPanelProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  useEffect(() => {
    if (internalDrillIns) {
      onPanelOpenInternalDrillIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    return () => {
      onPanelUnload();
      if (internalDrillIns) {
        onPanelCloseWithInternalDrillIns();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onPanelUnload]);
  useEffect(() => {
    onSelectedComponentChanged(selectedComponentId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedComponentId]);
  useEffect(() => {
    setIsVisible(false);
    if (rootControlCompRef || shouldDisplayEmptyState) {
      setTimeout(() => {
        setIsVisible(true);
      }, 10);
    }
    onPanelReload(rootControlCompRef);
    if (internalDrillIns) {
      onPanelReloadWithInternalDrillIns(rootControlCompRef);
    }
    // Create new id for the section that is going to be mounted, when old section unmounts
    return () => setSectionCorrelationId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootControlCompRef?.id, panelStateId]);

  const getContent = () => {
    if (isLoading) {
      return (
        <Composites.Preloader className={styles.preloader}>
          <Preloader className="large" />
        </Composites.Preloader>
      );
    } else if (shouldDisplayEmptyState) {
      return <EmptyState />;
    }
    return (
      <ComponentControlWrapper
        getOverrideCompControls={getOverrideCompControls}
        compRef={rootControlCompRef}
        rootControlCompRef={rootControlCompRef}
        shouldRenderDivider={false}
        onControlClick={onControlClick}
        onComponentChanged={onComponentChanged}
        hasListAncestor={false}
        onControlHover={onControlHover}
        onControlLeave={onControlLeave}
        onMainActionClick={onMainActionClick}
        getMainActionLabel={getMainActionLabel}
        isComponentFiltered={isComponentFiltered}
        getPermissionsConfig={getPermissionsConfig}
        openComponentPanelDrillIn={
          internalDrillIns
            ? openComponentPanelInternalDrillIn
            : openComponentPanelDrillIn
        }
        onDrillInAction={
          internalDrillIns ? openQuickEditInternalDrillIn : onDrillInAction
        }
        onLinkAction={onLinkAction}
        getThumbnail={getThumbnail}
        onGfppAction={onGfppAction}
        mapCompTypeToDrillInPanel={mapCompTypeToDrillInPanel}
      />
    );
  };

  const quickEditPanelContent = (
    <>
      <div
        key={rootControlCompRef?.id}
        className={cx(styles.quickEditPanel, {
          [styles.visible]: isLoading || isVisible,
        })}
        data-hook="quick-edit-panel"
      >
        {internalDrillIns && (
          <PanelHeader
            className="dragger dark"
            defaultCursor
            noCloseBtn={true}
            noHelpBtn={!onHelp}
            shouldTranslate={false}
            onHelp={onHelp}
          >
            {panelTitle}
          </PanelHeader>
        )}
        <div style={{ width: QUICK_EDIT_PANEL_WIDTH }}>
          <WixBaseUiEnvironmentProvider
            inputBorders
            madefor
            {...baseUIOverrides}
          >
            {getContent()}
          </WixBaseUiEnvironmentProvider>
          <div className={styles.footer} />
        </div>
      </div>
      {internalDrillIns &&
        drillInPanelsContent.map((panelContent) => {
          const panelConfig = contributedDrillInPanels[panelContent.uniqueId];
          return (
            <InnerDrillIn
              key={panelContent.uniqueId}
              mainPanelTitle={panelTitle}
              zIndex={panelConfig.zIndex}
              isOpened={panelConfig.isOpen}
              onBack={onBack}
              panelContent={panelConfig.isOpen ? [panelContent] : []}
              onBackTooltip={panelConfig.backTooltip}
              drillInPanelTitle={panelConfig.panelTitle}
            />
          );
        })}
    </>
  );

  return internalDrillIns ? (
    <div className={styles.panelWrapper}>{quickEditPanelContent}</div>
  ) : (
    quickEditPanelContent
  );
};

export default connectWithScope(
  () => QuickEditEngineScope,
  QuickEditPanel,
  mapStateToProps,
  mapDispatchToProps,
);

// @ts-nocheck
import * as core from '#packages/core';

export default {
  mixins: [core.mixins.editorAPIMixin],
  getUpgradeText() {
    return this.props.superApp.isPremium(this.getEditorAPI())
      ? ''
      : 'SiteApp_WixStores_AddAppFTUE_Step1_UpgradeText';
  },
  getUpgradeLinkText() {
    return this.props.superApp.isPremium(this.getEditorAPI())
      ? ''
      : 'SiteApp_WixStores_AddAppFTUE_Step1_UpgradeText_LinkPart';
  },
};

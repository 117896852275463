import React, { useCallback, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Divider, Button, CustomScroll } from '@wix/wix-base-ui';

import * as stateManagement from '#packages/stateManagement';
import * as panels from '#packages/panels';
import { hoc, fedopsLogger, isMainMenuFlowEnabled } from '#packages/util';
import { translate } from '#packages/i18n';
import * as helpIds from '#packages/helpIds';

import { MenuItem } from './MenuItem/MenuItem';

import { TRANSLATIONS_MAP } from './utils/translations';
import { createMenuApi } from '../../API/menuAPI';
import { cleanId, getAttachableMenus, isMainMenu } from '../../utils/utils';
import { AUTOMATION_IDS } from './utils/automationIds';
import { DELETE_MENU_SET_PANEL_NAME } from '../../constants';

import type { StateMapperArgs } from 'types/redux';
import type { MenuData, CompRef, CompData } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { IDeleteMenuSetPanelOwnProps } from '../DeleteMenuSetPanel/DeleteMenuSetPanel';
import { menuBiLogger } from '../../bi/menuBiLogger';
import { withMenuBi } from '../../bi/MenuBi';

const {
  frames: { ToolPanelFrame },
} = panels;
const {
  STORES: { EDITOR_API },
  connect,
} = hoc;

const { getData: getSelectedComponentData } =
  stateManagement.components.selectors;
const { openHelpCenter } = stateManagement.panels.actions;

type MenuId = string;

interface IOwnProps {
  top: number;
  left: number;
  panelName: string;
  selectedComponents: CompRef[];
}

interface IStoreProps {
  menus: MenuData[];
  selectedMenuId: MenuId;
}

interface IDispatchProps {
  createMenuWithHomePage(): MenuId;
  duplicateMenu({
    menuId,
    name,
    itemsOnly,
  }: {
    menuId: MenuId;
    name?: string;
    itemsOnly?: boolean;
  }): MenuId;
  renameMenu(menuId: MenuId, name: string): void;
  removeMenu(menuId: MenuId): void;
  attachMenu(menuId: MenuId, compRef: CompRef): void;
  attachMenuToSelectedComponent(menuId: MenuId): void;
  getMenuComponentsByMenuId(menuId: MenuId): CompRef[];
  openDeleteMenuSetPanel(props: IDeleteMenuSetPanelOwnProps): void;
  addHistory(label: string): void;
  openMainMenuArticle(): void;
}

type IProps = IOwnProps & IStoreProps & IDispatchProps;

const MenuSetsPanelComponent: React.FC<IProps> = (props) => {
  const {
    top,
    left,
    panelName,
    menus,
    selectedMenuId,
    renameMenu,
    duplicateMenu,
    attachMenuToSelectedComponent,
    attachMenu,
    removeMenu,
    getMenuComponentsByMenuId,
    createMenuWithHomePage,
    openDeleteMenuSetPanel,
    addHistory,
    openMainMenuArticle,
  } = props;

  const [editingItemId, _setEdit] = useState<string | null>(null);
  const startingMenuSetId = useRef(selectedMenuId);

  const setEdit = (id?: string) => {
    _setEdit(id);
    if (id) {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.RENAME_ITEM,
      );
    }
  };

  useEffect(() => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_MENU_SETS_PANEL,
    );
  }, []);

  const handleItemSelect = (selectedMenuId: string) => {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.SELECT_MENU_SET,
    );
    const { attachMenuToSelectedComponent } = props;

    attachMenuToSelectedComponent(selectedMenuId);
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.SELECT_MENU_SET,
    );
  };

  const handleItemRename = useCallback(
    (menuId: MenuId, name: string) => {
      const menu = menus.find((menu) => menu.id === menuId);
      menuBiLogger.logMenuItemRename(menu, menu.name, name);
      renameMenu(menuId, name);
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.RENAME_ITEM,
      );
      addHistory('rename menu set');
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [renameMenu, menus],
  );

  const handleItemDuplicate = useCallback(
    (menuId: MenuId, name: string) => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.DUPLICATE_MENU_SET,
      );
      const duplicatedMenuId = duplicateMenu({ menuId, name });

      if (duplicatedMenuId) {
        attachMenuToSelectedComponent(duplicatedMenuId);
        setEdit(duplicatedMenuId);
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.DUPLICATE_MENU_SET,
        );

        addHistory('duplicate menu set');
      }
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [duplicateMenu, attachMenuToSelectedComponent],
  );

  const handleItemEdit = useCallback(
    (id: string | null) => {
      const isItemChanged = editingItemId !== id;
      setEdit(isItemChanged ? id : null);
    },
    [editingItemId],
  );

  const handleItemDelete = useCallback(
    (menuIdToDelete: MenuId) => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.DELETE_MENU_SET,
      );

      const menuToDelete = menus.find((menu) => menu.id === menuIdToDelete);
      const menuComponentsWithDeletedDataset =
        getMenuComponentsByMenuId(menuIdToDelete);

      const mainMenu = menus.find(isMainMenu);
      const firstRemainingMenu = menus.find(
        (menu) => menu.id !== menuIdToDelete,
      );

      const menuToSubstituteWith = isMainMenuFlowEnabled()
        ? mainMenu || firstRemainingMenu
        : firstRemainingMenu;

      const deleteMenu = () => {
        if (menuComponentsWithDeletedDataset.length > 0) {
          menuComponentsWithDeletedDataset.forEach((menuComponent) => {
            attachMenu(menuToSubstituteWith.id, menuComponent);
          });
        }
        removeMenu(menuIdToDelete);
        menuBiLogger.logMenuSetDeleted(
          menuIdToDelete,
          menuComponentsWithDeletedDataset.length,
        );
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.DELETE_MENU_SET,
        );
      };

      if (menuComponentsWithDeletedDataset.length > 0) {
        openDeleteMenuSetPanel({
          onConfirm: deleteMenu,
          onCancel: () => {
            fedopsLogger.interactionEnded(
              fedopsLogger.INTERACTIONS.MANAGE_MENU.DELETE_MENU_SET,
            );
          },
          menuNameToDelete: menuToDelete.name,
          menuNameToSubstituteWith: menuToSubstituteWith.name,
        });
      } else {
        deleteMenu();
      }

      addHistory('delete menu set');
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [menus, attachMenu, removeMenu, getMenuComponentsByMenuId],
  );

  const handleCreateButtonClick = useCallback(
    () => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.CREATE_MENU_SET,
      );
      const { createMenuWithHomePage, attachMenuToSelectedComponent } = props;

      const menuId = createMenuWithHomePage();

      if (menuId) {
        menuBiLogger.logCreateMenuSetClicked(menuId);
        attachMenuToSelectedComponent(menuId);
        setEdit(menuId);
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.CREATE_MENU_SET,
        );
        addHistory('add menu set');
      }
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createMenuWithHomePage, attachMenuToSelectedComponent],
  );

  const handleClose = useCallback(
    () => {
      if (selectedMenuId !== startingMenuSetId.current) {
        menuBiLogger.logMenuSetSelected(selectedMenuId);
        addHistory('attach menu set');
      }
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedMenuId, startingMenuSetId.current],
  );

  return (
    <ToolPanelFrame
      panelName={panelName}
      contentStyle={{
        top,
        left,
      }}
      lightHeader
      isBlockingLayerFullScreen={false}
      shouldBlockOuterScroll={false}
      helpId={helpIds.MENU_PANEL.MANAGE_ALL_MENUS}
      headerTitle={translate(TRANSLATIONS_MAP.panelHeader)}
      contentWrapperClass="menu-sets-panel"
      onClose={handleClose}
    >
      <Divider long />
      <div
        className="menu-sets-panel__content"
        data-aid={AUTOMATION_IDS.MENUS_LIST.CONTAINER}
      >
        <CustomScroll heightRelativeToParent="100%">
          {menus.map((menu) => {
            const allowDelete = isMainMenuFlowEnabled()
              ? menus.length > 1 && !isMainMenu(menu)
              : menus.length > 1;

            return (
              <MenuItem
                key={menu.id}
                menu={menu}
                allowDelete={allowDelete}
                onRename={handleItemRename}
                onDuplicate={handleItemDuplicate}
                onEdit={handleItemEdit}
                onDelete={handleItemDelete}
                onSelect={handleItemSelect}
                onContextMenuOpen={menuBiLogger.logMenuSetContextMenuOpened}
                isSelected={selectedMenuId === menu.id}
                isEditing={editingItemId === menu.id}
                onMainMenuHelpClick={openMainMenuArticle}
              />
            );
          })}
          <Button
            automationId={AUTOMATION_IDS.MENUS_LIST.CREATE_MENU_BUTTON}
            onClick={handleCreateButtonClick}
            className="btn-text menu-sets-panel__add-button"
          >
            {translate(TRANSLATIONS_MAP.addMenuButtonText)}
          </Button>
        </CustomScroll>
      </div>
    </ToolPanelFrame>
  );
};

const mapStateToProps = (
  { editorAPI }: StateMapperArgs,
  ownProps: IOwnProps,
): IStoreProps => {
  const menuAPI = createMenuApi(editorAPI);
  const { selectedComponents } = ownProps;

  const allMenus = menuAPI.getAll();
  const menus = getAttachableMenus(allMenus);

  const compData: CompData = getSelectedComponentData(
    selectedComponents,
    editorAPI.dsRead,
  );
  const selectedMenuId = cleanId(compData.menuRef);

  return { menus, selectedMenuId };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

const mapDispatchToProps = (
  dispatch: AnyFixMe,
  ownProps: IOwnProps,
): IDispatchProps => {
  const editorAPI = dispatch(getEditorAPI);
  const { selectedComponents } = ownProps;

  const menuAPI = createMenuApi(editorAPI);

  const {
    createMenuWithHomePage: create,
    renameMenu,
    duplicateMenu,
    connect: attachMenu,
    removeMenu,
    getDesktopMenuComponentsByMenuId,
  } = menuAPI;

  const compRef = selectedComponents[0];

  const createMenuWithHomePage = () =>
    create(translate(TRANSLATIONS_MAP.ITEM.initialTitle));

  const attachMenuToSelectedComponent = (menuId: MenuId) =>
    attachMenu(menuId, compRef);

  const openDeleteMenuSetPanel = (props: IDeleteMenuSetPanelOwnProps) => {
    const leavePanelsOpen = true;

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MANAGE_MENU.OPEN_DELETE_MENU_SET_PANEL,
    );

    editorAPI.panelManager.openPanel(
      DELETE_MENU_SET_PANEL_NAME,
      props,
      leavePanelsOpen,
    );
  };

  const addHistory = editorAPI.history.add;

  const openMainMenuArticle = () =>
    dispatch(openHelpCenter(helpIds.MENU_PANEL.MAIN_MENU));

  return {
    createMenuWithHomePage,
    renameMenu,
    duplicateMenu,
    attachMenu,
    attachMenuToSelectedComponent,
    removeMenu,
    getMenuComponentsByMenuId: getDesktopMenuComponentsByMenuId,
    openDeleteMenuSetPanel,
    addHistory,
    openMainMenuArticle,
  };
};

export const MenuSetsPanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withMenuBi,
)(MenuSetsPanelComponent);

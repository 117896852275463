// @ts-nocheck
import _ from 'lodash';
import { waitForAddedCompRef } from '#packages/componentsAddUtils';
import { layoutUtils } from '#packages/layoutUtils';
import constants from '../constants/closeButtonsConstants';
import presets from '../presets/closeButtonsPresets';

async function addIconButton(editorAPI) {
  await addCloseButton(editorAPI, constants.ICON_TYPE, { y: 20, right: 20 });
}

async function addTextButton(editorAPI) {
  await addCloseButton(editorAPI, constants.TEXT_TYPE, {
    center: 0,
    bottom: 20,
  });
}

async function addCloseButton(editorAPI, buttonType, computedLayout) {
  const popupPointer = editorAPI.pages.popupPages.getCurrentPopup();
  const containerPointer = editorAPI.pages.popupPages.getPopupContainer();
  const containerLayout = editorAPI.components.layout.get(containerPointer);
  containerLayout.width = Math.min(980, containerLayout.width); // TODO: popups, // HACK: need to remove it asap
  const buttonDefinition = getDefinitionWithCalculateButtonLayout(
    presets[buttonType](editorAPI),
    containerLayout,
    computedLayout,
  );

  await waitForAddedCompRef(
    editorAPI.components.add(containerPointer, buttonDefinition),
  );
  editorAPI.history.add(
    `added close ${buttonType} button to popup ${popupPointer.id}`,
    { isAddingComponent: true },
  );
}

function getDefinitionWithCalculateButtonLayout(
  buttonDefinition,
  containerLayout,
  computedLayout,
) {
  return _.defaults(
    {
      layout: layoutUtils.convertAnyPositionToLeftTopPosition(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, buttonDefinition.layout, computedLayout),
        containerLayout,
      ),
    },
    buttonDefinition,
  );
}

function removeIconButton(editorAPI) {
  removeCloseButton(editorAPI, constants.ICON_TYPE);
}

function removeTextButton(editorAPI) {
  removeCloseButton(editorAPI, constants.TEXT_TYPE);
}

function removeCloseButton(editorAPI, buttonType) {
  const popupPointer = editorAPI.pages.popupPages.getCurrentPopup();
  const buttonPointers =
    editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
      buttonType,
      popupPointer,
    );

  if (!_.isEmpty(buttonPointers)) {
    editorAPI.components.remove(buttonPointers).then((removed) => {
      if (removed) {
        editorAPI.history.add(
          `removed close buttons from popup ${popupPointer.id}`,
        );
      }
    });
  }
}

export { addIconButton, addTextButton, removeIconButton, removeTextButton };

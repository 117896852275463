import _ from 'lodash';
import { fedopsLogger } from '#packages/util';
import { BasePublicApi } from '#packages/apilib';
import { files } from '@wix/santa-editor-utils';
import {
  handleAddImage,
  type ImageOrigin,
} from './addCompsHandlers/addImageHandler';
import { HELP_IDS, TRANSLATION_KEYS } from './consts';
import { isImageMimeType } from '@wix/santa-editor-utils';
import {
  getBiOrigin,
  reportUnsupportedFileTypeToBi,
  reportUploadStartedToBi,
} from './reportToBi';
import { translate } from '#packages/i18n';

import type { EditorAPI } from '#packages/editorAPI';
import type { ImageUploadToStageScope } from './imageUploadToStageEntryPoint';
import type { CompRef } from '@wix/document-services-types';
import { browserUtil } from '#packages/util';

const reportUploadStarted = (
  file: File,
  origin: ImageOrigin,
  filesInBatch: number,
  sectionId?: string,
) => {
  reportUploadStartedToBi(origin, file, filesInBatch, sectionId);
  fedopsLogger.interactionStarted(
    origin === 'paste_file'
      ? fedopsLogger.INTERACTIONS.PASTE_FILE_FROM_KEYBOARD
      : fedopsLogger.INTERACTIONS.DRAG_DROP_FILE_FROM_OS,
  );
};

const reportUnsupportedFileType = (
  editorAPI: EditorAPI,
  file: File,
  origin: ImageOrigin,
  filesInBatch: number,
) => {
  reportUnsupportedFileTypeToBi(origin, file, filesInBatch);

  editorAPI.showUserActionNotification({
    message: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE, {
      FileType: (file.type.split('/')[1] || file.type).toUpperCase(),
    }),
    type: 'error',
    link: {
      caption: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE_READ_MORE),
      onNotificationLinkClick: () =>
        editorAPI.panelManager.openHelpCenter(HELP_IDS.UNSUPPORTED_FILE),
    },
    shouldTranslate: false,
  });
};

const reportUploadDone = (origin: ImageOrigin) => {
  fedopsLogger.interactionEnded(
    origin === 'paste_file'
      ? fedopsLogger.INTERACTIONS.PASTE_FILE_FROM_KEYBOARD
      : fedopsLogger.INTERACTIONS.DRAG_DROP_FILE_FROM_OS,
  );
};

const isSupportedImage = (type: string) => {
  if (type === 'image/heic' || type === 'image/heif') {
    return false;
  }
  return isImageMimeType(type);
};

const getImageItems = (event: ClipboardEvent): DataTransferItem[] => {
  const { items } = (event.clipboardData ||
    // @ts-expect-error
    event.originalEvent.clipboardData) as DataTransfer;

  return Array.from(items).filter((item) => {
    return item.kind === 'file';
  });
};

const validate = (event: ClipboardEvent): boolean => {
  const filesToPaste = getImageItems(event);

  return !_.isEmpty(filesToPaste);
};

const uploadAndAddImage = (
  editorAPI: EditorAPI,
  file: File,
  origin: ImageOrigin,
  filesInBatch: number,
  sectionId?: string,
) => {
  reportUploadStarted(file, origin, filesInBatch, sectionId);
  if (isSupportedImage(file.type)) {
    const mediaAuthToken =
      editorAPI.documentServices.generalInfo.media.getMediaAuthToken();
    const uploadFilePromise = files.uploadSiteFile(mediaAuthToken, file, [
      getBiOrigin(origin),
    ]);
    const reader = new FileReader();
    reader.onload = async () => {
      await handleAddImage(
        editorAPI,
        file,
        uploadFilePromise,
        origin,
        filesInBatch,
      );
    };
    reader.readAsDataURL(file);
  } else {
    reportUnsupportedFileType(editorAPI, file, origin, filesInBatch);
    return;
  }
  reportUploadDone(origin);
};

export const handleImageUpload = (
  { editorAPI }: ImageUploadToStageScope,
  event: ClipboardEvent,
) => {
  const itemsToPaste = getImageItems(event);

  if (browserUtil.isFirefox() || _.isEmpty(itemsToPaste)) {
    return;
  }

  const supportedImages = itemsToPaste.filter((item) =>
    isSupportedImage(item.type),
  );

  if (_.isEmpty(supportedImages)) {
    editorAPI.showUserActionNotification({
      message: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE, {
        FileType: supportedImages
          .map((file) => (file.type.split('/')[1] || file.type).toUpperCase())
          .join(', '),
      }),
      type: 'error',
      link: {
        caption: translate(TRANSLATION_KEYS.UNSUPPORTED_FILE_READ_MORE),
        onNotificationLinkClick: () =>
          editorAPI.panelManager.openHelpCenter(HELP_IDS.UNSUPPORTED_FILE),
      },
      shouldTranslate: false,
    });
    return;
  }

  supportedImages.forEach((itemToPaste: DataTransferItem) => {
    const fileBlob = itemToPaste.getAsFile();
    uploadAndAddImage(editorAPI, fileBlob, 'paste_file', itemsToPaste.length);
  });
};

const handleDraggedFiles = (
  editorAPI: EditorAPI,
  filesToUpload: FileList,
  sectionRef: CompRef,
) => {
  Array.from(filesToUpload).forEach((file) =>
    uploadAndAddImage(
      editorAPI,
      file,
      'desktop_file',
      filesToUpload.length,
      sectionRef?.id,
    ),
  );
};

export class ImageUploadToStageApi extends BasePublicApi<ImageUploadToStageScope> {
  handleUpload = this.bindScope(handleImageUpload);
  validate = validate;
  handleDraggedFiles = handleDraggedFiles;
}

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '#packages/core';
import { translate } from '#packages/i18n';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import * as baseUI from '#packages/baseUI';
import * as symbols from '@wix/santa-editor-symbols';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'promotionModalPanel',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    headerFirstLineText: PropTypes.string,
    headerSecondLineText: PropTypes.string,
    imageSrc: PropTypes.string,
    bodyText: PropTypes.string,
    bodyLinkText: PropTypes.string,
    isImageLinkActive: PropTypes.bool,
    actionText: PropTypes.string,
    actionUrl: PropTypes.string,
    biEvent: PropTypes.object,
  },
  getDefaultProps() {
    return {
      isImageLinkActive: true,
    };
  },
  getImageContainerClass() {
    return this.props.isImageLinkActive ? 'a' : 'div';
  },
  handleAction(origin) {
    this.sendSubmitVideoClickedBI(origin);
    if (window) {
      window.open(translate(this.props.actionUrl));
    }
  },
  sendSubmitVideoClickedBI(origin) {
    const params = {
      origin,
    };
    this.getEditorAPI().bi.event(this.props.biEvent, params);
  },
  close() {
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  render() {
    const ImageContainerClass = this.getImageContainerClass();

    return (
      <FocusPanelFrame
        panelName={this.props.panelName}
        shouldHideHeader={true}
        shouldHideHelp={true}
        className={`promo-popup-panel ${this.props.className}`}
      >
        <header className="panel-header dark">
          <button
            onClick={() => {
              this.close();
            }}
            className="close"
          >
            <symbols.symbol name="headerCloseButton" />
          </button>
          <div className="title-line-1">
            {translate(this.props.headerFirstLineText)}
          </div>
          {this.props.headerSecondLineText ? (
            <div key="headerSecondLine" className="title-line-2">
              {translate(this.props.headerSecondLineText)}
            </div>
          ) : null}
          <ImageContainerClass
            onClick={
              this.props.isImageLinkActive
                ? this.handleAction.bind(this, 'image')
                : _.noop
            }
            className="image-wrapper"
          >
            <img ref="image" src={this.props.imageSrc} />
            <div className="overlay-box-1" />
            <div className="overlay-box-2" />
            <div className="overlay-box-3" />
          </ImageContainerClass>
        </header>
        <div className="content">
          <p>
            {translate(this.props.bodyText)}
            {this.props.bodyLinkText ? (
              <a
                key="link"
                onClick={() => {
                  this.handleAction('date kink');
                }}
              >
                {translate(this.props.bodyLinkText)}
              </a>
            ) : null}
          </p>

          {this.props.bodyBottomText ? (
            <p key="bodyBottomText">{translate(this.props.bodyBottomText)}</p>
          ) : null}
          <baseUI.button
            label={this.props.actionText}
            onClick={() => {
              this.handleAction('submission button');
            }}
          />
        </div>
      </FocusPanelFrame>
    );
  },
});

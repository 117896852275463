import { BaseEntryScope, BasePublicApi } from '#packages/apilib';
import { ComponentsApiKey, EditorAPIKey } from '#packages/apis';
import type * as DS from '@wix/document-services-types';
import type { ComponentsApi } from '#packages/components';
import constants from '#packages/constants';

const { COMP_TYPES } = constants;

export class DataBindingScope extends BaseEntryScope {
  editorApi = this.useDependency(EditorAPIKey);
  componentsApi: ComponentsApi = this.useDependency(ComponentsApiKey);
}

const DATABINDING_APP_ID = 'dataBinding';
const DATASET_CONTROLLER_TYPE = 'dataset';

function getDataBindingConnection(
  scope: DataBindingScope,
  compRef: DS.CompRef,
): DS.Connection | undefined {
  // @ts-expect-error TS is failing to resolve the correct type
  const connections: DS.Connection[] =
    scope.editorApi.platform.controllers.connections.get(compRef);

  return connections.find((connection: DS.Connection) => {
    const appData = scope.componentsApi.data.get(connection.controllerRef);

    return appData?.applicationId === DATABINDING_APP_ID;
  });
}

function isDatasetController(scope: DataBindingScope, compRef: DS.CompRef) {
  if (scope.componentsApi.getType(compRef) !== COMP_TYPES.APP_CONTROLLER) {
    return false;
  }

  const data = scope.componentsApi.data.get(compRef);

  return (
    data &&
    data.applicationId === DATABINDING_APP_ID &&
    data.controllerType === DATASET_CONTROLLER_TYPE
  );
}

export class DataBindingApi extends BasePublicApi<DataBindingScope> {
  getDataBindingConnection = this.bindScope(getDataBindingConnection);
  isDatasetController = this.bindScope(isDatasetController);
}

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';

import * as core from '#packages/core';
import * as util from '#packages/util';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import {
  InstallationOriginType,
  InstallInitiator,
  EditorType,
} from '@wix/platform-editor-sdk';

import * as blogUtils from '../../utils/blogUtils';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'BlogFirstTimeTour',
  mixins: [core.mixins.editorAPIMixin, util.propTypesFilterMixin],
  installApp(done) {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.ADD_BLOG_FROM_6TH_BUTTON,
    );
    const editorAPI = this.getEditorAPI();
    const callback = (data = {}) => {
      if (!data.error) {
        if (blogUtils.siteHasOldBlog(editorAPI)) {
          blogUtils.registerBlogButton(editorAPI);
        }
        util.fedopsLogger.interactionEnded(
          util.fedopsLogger.INTERACTIONS.ADD_BLOG_FROM_6TH_BUTTON,
        );
        done();
      }
    };
    import('@/tpa').then((tpa) => {
      blogUtils.addNewBlog(
        editorAPI,
        {
          biOrigin: tpa.constants.BI.type.ADD_APP_APP_BUTTON,
          platformOrigin: {
            type: EditorType.Classic,
            initiator: InstallInitiator.Editor,
            info: {
              type: InstallationOriginType.AppPanel,
            },
          },
        },
        callback,
      );
    });
  },
  getNewBlogLabel() {
    return blogUtils.siteHasOldBlog(this.getEditorAPI())
      ? blogUtils.newSuperAppButtonMigrationNewBlog
      : blogUtils.newSuperAppButtonLabel;
  },
  getBannerScr() {
    if (util.workspace.isNewWorkspaceEnabled()) {
      return util.browserUtil.isRetina()
        ? 'wixBlog/appMenuPanel/newWorkspace_firstTimeTour@x2.v2.png'
        : 'wixBlog/appMenuPanel/newWorkspace_firstTimeTour.v2.png';
    }

    return 'wixBlog/appMenuPanel/firstTimeTour.png';
  },
  openAppMarket: () => blogUtils.openNewBlogInAppMarket(this.getEditorAPI()),
  render() {
    const bannerImgSrc = this.getBannerScr();

    return (
      <superAppMenuInfra.firstTimeTourPanels.installAppSlide
        installApp={this.installApp}
        origin="promotionalPanel"
        superAppPanelName={blogUtils.newSuperAppPanelName}
        superAppButtonLabel={this.getNewBlogLabel()}
        superAppPanelClassName={blogUtils.newSuperAppPanelClassName}
        superAppSymbolName={blogUtils.newSuperAppSymbolName}
        title="SiteApp_NewBlog_First_Time_Title"
        subTitle="SiteApp_NewBlog_First_Time_Subtitle"
        pros={[
          'SiteApp_NewBlog_First_Time_Bullet_1',
          'SiteApp_NewBlog_First_Time_Bullet_2',
          'SiteApp_NewBlog_First_Time_Bullet_3',
        ]}
        nextButtonLabel="SiteApp_NewBlog_First_Time_Button"
        openAppMarketLabel="SiteApp_NewBlog_First_Time_LinkCaption"
        openAppMarket={this.openAppMarket}
        loadingLabel="SiteApp_NewBlog_LoadingMessage"
        installImage="newBlogManager"
        bannerImgSrc={util.media.getMediaUrl(bannerImgSrc)}
        {...this.filteredProps()}
      />
    );
  },
});

import React from 'react';
import experiment from 'experiment';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import { OldMessagePanelFrame } from '../../frames';
import * as panels from '#packages/panels';
import { connectConfirmPublishPanel } from './confirmPublishPanel.mapper';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import constants from '#packages/constants';

export interface ConfirmPublishPanelDispatchProps {
  closePanel(panelName: string): void;
  publishSiteIfAvailable(origin: string): void;
}

export interface ConfirmPublishPanelOwnProps {
  panelName: string;
}

type ConfirmPublishPanelProps = ConfirmPublishPanelDispatchProps &
  ConfirmPublishPanelOwnProps;

const TRANSLATIONS = {
  HEADER: 'Editor_Search_Publish_Popup_Header',
  TEXT: 'Editor_Search_Publish_Popup_Text',
  CTA_SECONDARY: 'Editor_Search_Publish_Popup_Secondary_CTA',
  CTA_PRIMARY: 'Editor_Search_Publish_Popup_Primary_CTA',
};

class ConfirmPublishPanel extends React.Component<ConfirmPublishPanelProps> {
  static displayName = 'ConfirmPublishPanel';

  getKeyboardShortcuts = () => {
    return {
      esc: this.handleClose,
    };
  };

  handleClose = () => {
    this.props.closePanel(this.props.panelName);
  };

  handleConfirm = () => {
    this.props.publishSiteIfAvailable(constants.BI.HELP.ORIGIN.EDITOR_SEARCH);
    this.handleClose();
  };

  render() {
    const { panelName } = this.props;

    return experiment.isOpen('se_removeOldMessagePanelFrame') ? (
      <panels.frames.MessagePanelFrame
        panelName={panelName}
        title={translate(TRANSLATIONS.HEADER)}
        onConfirm={this.handleConfirm}
        confirmLabel={translate(TRANSLATIONS.CTA_PRIMARY)}
        cancelLabel={translate(TRANSLATIONS.CTA_SECONDARY)}
        illustration={<symbols.symbol name="confirmPublish" />}
      >
        <RichText type="T01">{translate(TRANSLATIONS.TEXT)}</RichText>
      </panels.frames.MessagePanelFrame>
    ) : (
      <OldMessagePanelFrame
        //MessagePanelFrame is a legacy component with broken typings;
        //Currently it only allows props from top-level HOC, so you have to ts-ignore it for now.
        panelName={panelName}
        panelTitle={translate(TRANSLATIONS.HEADER)}
        close={this.handleClose}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        onConfirm={this.handleConfirm}
        confirmLabel={TRANSLATIONS.CTA_PRIMARY}
        cancelLabel={TRANSLATIONS.CTA_SECONDARY}
        shouldCloseOnConfirm={true}
        shouldHideHeader={false}
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <symbols.symbol name="confirmPublish" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">{translate(TRANSLATIONS.TEXT)}</RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  }
}

export default connectConfirmPublishPanel(ConfirmPublishPanel);

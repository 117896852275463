import _ from 'lodash';
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const designPanelName = 'designPanel.designPanel';
const settingsPanelName = 'compPanels.panels.BoxSlideShow.settingsPanel';

function getDesignPanelProps(editorAPI: AnyFixMe, compRef: AnyFixMe) {
  return {
    compType: editorAPI.components.getType(compRef),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    compRef: _.isArray(compRef) ? _.head(compRef) : compRef,
  };
}

const BOX_SLIDE_SHOW_MANAGES_SLIDES_PANEL_NAME =
  'rEditor.panels.boxSlideShowManageSlidesPanel';

export default {
  untranslatable: true,
  mainActions: [
    {
      label:
        constants.BOX_SLIDE_SHOW.GFPP.ACTIONS.LABELS
          .REGULAR_SLIDESHOW_SETTINGS_MANAGE_SLIDES,
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(
        BOX_SLIDE_SHOW_MANAGES_SLIDES_PANEL_NAME,
      ),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        const panelProps = {
          selectedComponent: compRef,
          origin,
        };
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          BOX_SLIDE_SHOW_MANAGES_SLIDES_PANEL_NAME,
          panelProps,
        );
      },
    },
    {
      label: 'gfpp_main_action_customize_current_slide',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.BACKGROUND),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.BACKGROUND),
      automationId: 'gfpp-button-background',
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
    ACTIONS.MOBILE_BACKGROUND_SETTINGS,
  ],
  presetActions: {
    help: {
      helpId: '386c66d1-ce08-4ec7-a569-ce051f4a10ab',
      mobileHelpId: 'c87be5fd-fa3a-4eb5-887e-13ae266ad306',
    },
    design: {
      isSelected(editorAPI: AnyFixMe) {
        return gfppDataUtils.findPanel(
          editorAPI,
          designPanelName,
          'wysiwyg.viewer.components.BoxSlideShow',
        );
      },
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          designPanelName,
          { ...getDesignPanelProps(editorAPI, compRef), origin },
          editorAPI.components.getType(compRef),
        );
      },
    },
    settings: {
      isSelected(editorAPI: AnyFixMe) {
        return gfppDataUtils.findPanel(
          editorAPI,
          settingsPanelName,
          'wysiwyg.viewer.components.BoxSlideShow',
        );
      },
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        const compType = editorAPI.components.getType(compRef);
        const panelProps = {
          selectedComponent: compRef,
          compType,
          origin,
        };
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          settingsPanelName,
          panelProps,
          compType,
        );
      },
    },
  },
};

// @ts-nocheck
import React from 'react';
import _ from 'lodash';
import colorDrop from './colorDrop';

import ColorDrop from './colorDrop';
import TextColor from './textColor';
import constants from '../utils/constants';
import * as UI from '#packages/baseUI';

export default class extends React.Component {
  static displayName = 'backColorDropDown';

  static defaultProps = {
    colorDrop: true,
  };

  render() {
    return (
      <span>
        {!this.shouldRenderDropOwn() ? (
          <TextColor
            onClick={() => {
              this.props.onClick(false);
            }}
            tooltipValue={this.props.tooltipValue}
            key="backColorButton"
            tooltipStyleType={this.props.tooltipStyleType}
            isBackColor={true}
            value={this.props.color}
          />
        ) : null}

        {this.shouldRenderDropOwn() ? (
          <UI.tooltip
            value={this.props.tooltipValue}
            styleType={this.props.tooltipStyleType}
            key="backColorDropdown"
          >
            <span>
              <UI.dropDown.select
                ref="dropdown"
                shouldTranslate={false}
                value={this.getDropDownValue()}
                onChange={this.handleChange}
                optionsWidth={40}
                toggleIcon={false}
                template={this.getTemplate}
                setSelectedAnyway={true}
                doNotFocus={this.props.doNotFocus}
                className="back-color-dd"
              >
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(constants.TEXT_BACK_COLOR_TYPES, (item, itemIndex) => (
                  <UI.dropDown.option
                    value={item}
                    key={itemIndex}
                    className="icons-dd-option"
                  >
                    <UI.symbol name={itemIndex} />
                  </UI.dropDown.option>
                ))}
                {this.props.colorDrop ? (
                  <UI.dropDown.option
                    key="colorDropOption"
                    value="customColor"
                    className="icons-dd-option"
                  >
                    <ColorDrop colorDropOnly={true} fill={this.props.color} />
                  </UI.dropDown.option>
                ) : null}
              </UI.dropDown.select>
            </span>
          </UI.tooltip>
        ) : null}
      </span>
    );
  }

  shouldRenderDropOwn = () => {
    return this.props.color !== null;
  };

  getDropDownValue = () => {
    return this.props.color === null ? 'noColor' : 'customColor';
  };

  getTemplate = () => {
    return React.createElement(colorDrop, {
      fill: this.props.color,
      isBackColor: true,
    });
  };

  handleChange = (value) => {
    if (value === 'noColor' && this.props.handleNoColor) {
      this.props.handleNoColor();
    }
    if (value === 'customColor' && this.props.onClick) {
      this.props.onClick(true);
    }
  };

  isExpanded = () => {
    return this.refs.dropdown.isExpanded();
  };
}

import React from 'react';
import { Text } from '@wix/wix-base-ui';
import { cx } from '#packages/util';
import type { UISkin } from '../skins/skins.types';

import styles from './StageActionButton.scss';

interface StageActionButtonProps {
  dataHook: string;
  label?: string;
  onClick: () => void;
  Icon?: React.JSXElementConstructor<any>;
  skin: UISkin;
  className?: string;
}

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

export const StageActionButton: React.FC<StageActionButtonProps> = ({
  dataHook,
  label,
  onClick,
  Icon,
  skin,
  className,
}) => {
  return (
    <div
      data-hook={dataHook}
      className={cx(styles.container, className, {
        [styles.hasIcon]: Boolean(Icon),
        [styles.primary]: skin === 'primary',
        [styles.secondary]: skin === 'secondary',
        [styles.lightLarge]: skin === 'lightLarge',
        [styles.primarySoap]: skin === 'primary-soap',
        [styles.secondarySoap]: skin === 'secondary-soap',
        [styles.lightLargeSoap]: skin === 'lightLarge-soap',
        [styles.noLabel]: !label,
      })}
      onClick={(event) => {
        stopPropagation(event);
        onClick();
      }}
      // section box and mouse catchers have their mouseDown handlers, so we need to block the propagation here
      onMouseDown={stopPropagation}
    >
      {Icon && (
        <div className={styles.icon}>
          <Icon />
        </div>
      )}
      {label && (
        <Text size="small" weight="normal" shouldTranslate={false}>
          {label}
        </Text>
      )}
    </div>
  );
};

import React from 'react';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';

interface ISuperAppTooltip {
  title: string;
  text: string;
  label: string;
  image: string;
  onClick: (e: unknown) => void;
}

const DropshippingTooltip = (props: ISuperAppTooltip) => (
  <superAppMenuInfra.superAppTooltip {...props} />
);

export default DropshippingTooltip;

import constants from '#packages/constants';
import { translate } from '#packages/i18n';

const { CODE_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

export interface CodeMenuAPIDispatchProps {
  addACodeSnippet: () => void;
  embedHTML: () => void;
  enableDeveloperMode: (options?: { notifyApps: boolean }) => void;
}

export interface CodeMenuAPIStateProps {
  isFirstSave: boolean;
  isDraftMode: boolean;
}

export interface CodeMenuAPIOwnProps {}

export interface CodeMenuAPIProps
  extends CodeMenuAPIOwnProps,
    CodeMenuAPIStateProps,
    CodeMenuAPIDispatchProps {}

export const getCodeMenuAPI = (props: CodeMenuAPIProps) => {
  return {
    [CODE_MENU_ITEMS.CODE_TOGGLE_DEV_TOOLS]: () => {
      props.enableDeveloperMode();
    },
    [CODE_MENU_ITEMS.HIRE_A_DEVELOPER]: () => {
      window.open(translate('TopBar_DevMode_Hire_Developer_Link'));
    },
    [CODE_MENU_ITEMS.API_REFERENCES]: () => {
      window.open(translate('Corvid_DropMenu_API_URL'));
    },
    [CODE_MENU_ITEMS.VIDEO_TUTORIALS]: () => {
      window.open(translate('Corvid_DropMenu_Videos_URL'));
    },
    [CODE_MENU_ITEMS.ADD_A_CODE_SNIPPET]: () => {
      props.addACodeSnippet();
    },
    [CODE_MENU_ITEMS.EMBED_HTML]: () => {
      props.embedHTML();
    },
  };
};

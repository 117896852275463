import * as stateManagement from '#packages/stateManagement';
import type { CompRef, ViewModes } from 'types/documentServices';
import constants, {
  type BackgroundData,
  type VideoData,
} from '#packages/constants';
import {
  backgroundUtils,
  textUtils,
  imageTransform,
  type FittingType,
} from '#packages/util';
import type { AnimationsScope } from '../../scope';
import {
  type AnimationKitDefinition,
  type AnimationRules,
  type AnimationType,
  type Behavior,
  type BehaviorsAnimationRules,
  type EffectsAnimationRules,
  type ParallaxEffectName,
  type SiteAnimationKit,
  type TextAnimationRules,
  type VideoAnimationRules,
  type ViewMode,
} from '../types';
import {
  ANIMATION_KIT_DATA_NAMESPACE,
  BLACK_LISTED_ITERATABLE_COMPONENTS,
  COMPS_WITH_BG_SCROLL,
  COMPS_WITH_TRANSPARENT_BACKGROUND,
  MAX_TILE_HEIGHT,
  MAX_TILE_WIDTH,
  PARALLAX_FITTING_EFFECTS,
  videoTypes,
} from '../constants';

const { components } = stateManagement;
const { getBehaviors } = components.selectors;
const { fittingTypes } = imageTransform;

const { COLUMN, MEDIA_PLAYER, FORM_CONTAINER, APP_WIDGET, TEXT } =
  constants.COMP_TYPES;

export const createGlobalAnimationsApi = ({
  editorAPI,
  addPresetApi,
  previewApi,
  animationsApiV1: animationsApi,
}: AnimationsScope) => {
  function getAnimationRules(animationKitName: string) {
    const animationRules =
      addPresetApi
        .getAnimationKits()
        .find((kit: AnimationKitDefinition) => kit.title === animationKitName)
        ?.animationRules || {};

    return animationRules;
  }

  async function animateComponents(
    compRef: CompRef,
    animationRules: AnimationRules,
  ) {
    const compType = editorAPI.components.getType(compRef);

    if (shouldNotAnimate(compRef, compType)) {
      return;
    }

    switch (compType) {
      case COLUMN:
        setBgScrollEffects(
          compRef,
          compType,
          animationRules as BehaviorsAnimationRules,
        );
        break;
      case APP_WIDGET:
        await setAppWidgetEffects(compRef, animationRules);
        break;
      default:
        await setComponentsEffects(compRef, compType, animationRules);
        break;
    }
  }

  function shouldNotAnimate(compRef: CompRef, compType: string) {
    return (
      isTransparentBg(compRef) &&
      COMPS_WITH_TRANSPARENT_BACKGROUND.includes(compType)
    );
  }

  function isTransparentBg(compRef: CompRef) {
    const { style } = editorAPI.components.style.get(compRef) || {};
    const { background } = editorAPI.components.design.get(compRef) || {};
    const isColorBgTransparent =
      Number(style?.properties?.['alpha-bg']) === 0 ||
      Number(background?.colorOpacity) === 0 ||
      Number(background?.colorLayers?.[0]?.opacity) === 0;
    const mediaBg = background?.mediaRef;
    const isMediaBgTransparent = mediaBg?.opacity === 0;

    const isTransparentBg = mediaBg
      ? isMediaBgTransparent
      : isColorBgTransparent;

    return isTransparentBg;
  }

  async function setComponentsEffects(
    compRef: CompRef,
    compType: string,
    animationRules: AnimationRules,
  ) {
    const ANIMATION_TYPES = ['entrance'] as const;

    await animationsApi.cleanAllAnimations(compRef);

    await Promise.all(
      ANIMATION_TYPES.map(async (animationType) => {
        const effectDataDesktop = getEffectDataByViewMode(
          animationRules,
          compRef,
          compType,
          'DESKTOP',
          animationType,
        );
        const effectDataMobile = getEffectDataByViewMode(
          animationRules,
          compRef,
          compType,
          'MOBILE',
          animationType,
        );

        const compRefMobile: CompRef = { id: compRef.id, type: 'MOBILE' };

        if (effectDataDesktop) {
          await animationsApi.setAnimation(compRef, {
            animationType,
            effectData: effectDataDesktop,
          });
          if (effectDataMobile) {
            await animationsApi.setAnimation(compRefMobile, {
              animationType,
              effectData: effectDataMobile,
            });
          } else if (effectDataMobile === null) {
            //effectDataMobile is set to null if animation on mobile was deleted, so we should remove animation on mobile and cascading
            await animationsApi.removeAnimation(compRefMobile, animationType);
          }
        }
      }),
    );
  }

  function getEffectDataByViewMode(
    animationRules: AnimationRules,
    compRef: CompRef,
    compType: string,
    viewMode: keyof ViewModes,
    animationType: AnimationType,
  ) {
    const componentsWithRulesForSubTypes = [MEDIA_PLAYER, TEXT];

    if (componentsWithRulesForSubTypes.includes(compType)) {
      const animationRulesBySubType = getRulesBySubType(
        compRef,
        compType,
        animationRules,
        viewMode,
        animationType,
      );

      return animationRulesBySubType;
    }

    const generalAnimationsRules = (animationRules as EffectsAnimationRules)[
      compType
    ]?.effects;

    return (
      generalAnimationsRules &&
      generalAnimationsRules?.[viewMode]?.[animationType]
    );
  }

  function getRulesBySubType(
    compRef: CompRef,
    compType: string,
    animationRules: AnimationRules,
    viewMode: keyof ViewModes,
    animationType: AnimationType,
  ) {
    switch (compType) {
      case MEDIA_PLAYER:
        return getRulesByVideoType(
          compRef,
          compType,
          animationRules,
          viewMode,
          animationType,
        );
      case TEXT:
        return getRulesByTextType(
          compRef,
          compType,
          animationRules,
          viewMode,
          animationType,
        );
    }
  }

  function getRulesByTextType(
    compRef: CompRef,
    compType: string,
    animationRules: AnimationRules,
    viewMode: keyof ViewModes,
    animationType: AnimationType,
  ) {
    const { text: componentsRawHtml } = editorAPI.components.data.get(compRef);
    const textType = textUtils.getRootTag(componentsRawHtml);

    const textTypePath =
      (animationRules as TextAnimationRules)[compType]?.[textType]?.effects ??
      (animationRules as EffectsAnimationRules)[compType]?.effects;

    return textTypePath && textTypePath?.[viewMode]?.[animationType];
  }

  function getRulesByVideoType(
    compRef: CompRef,
    compType: string,
    animationRules: AnimationRules,
    viewMode: keyof ViewModes,
    animationType: AnimationType,
  ) {
    const { background } = editorAPI.components.design.get(compRef) || {};
    const videoType = getVideoType(background);
    const animationRulesByVideoType = (animationRules as VideoAnimationRules)[
      compType
    ]?.[videoType]?.effects;

    return (
      animationRulesByVideoType &&
      animationRulesByVideoType?.[viewMode]?.[animationType]
    );
  }

  function setBgScrollEffects(
    compRef: CompRef,
    compType: string,
    animationRules: BehaviorsAnimationRules,
  ) {
    const { background } = editorAPI.components.design.get(compRef) || {};
    const backgroundType = getBackgroundType(background);

    removeBehaviors(compRef);

    setEffectForEachViewMode(
      animationRules,
      compType,
      backgroundType,
      compRef,
      background,
    );
  }

  function getBackgroundType(background: BackgroundData) {
    const mediaTypes = constants.MEDIA_TYPES;
    const compMediaType = background?.mediaRef?.type;

    if (compMediaType === mediaTypes.VIDEO) {
      return mediaTypes.VIDEO;
    } else if (compMediaType === mediaTypes.IMAGE) {
      return mediaTypes.IMAGE;
    }

    return mediaTypes.COLOR;
  }

  function getVideoType(background: BackgroundData) {
    const videoData = background?.mediaRef as VideoData;
    const isTransparent = videoData?.mediaFeatures?.includes('alpha');
    const isVideoMask = !!videoData?.mask;

    if (isTransparent) {
      return videoTypes.TRANSPARENT_VIDEO;
    } else if (isVideoMask) {
      return videoTypes.VIDEO_MASK;
    }

    return videoTypes.VIDEO_BOX;
  }

  function setEffectForEachViewMode(
    animationRules: BehaviorsAnimationRules,
    compType: string,
    backgroundType: string,
    compRef: CompRef,
    backgroundData: BackgroundData,
  ) {
    const { DESKTOP, MOBILE } = editorAPI.dsRead.viewMode.VIEW_MODES;
    const VIEW_MODES = [DESKTOP, MOBILE];

    VIEW_MODES.forEach((viewMode) => {
      const scrollBgAnimation =
        animationRules[compType]?.[backgroundType]?.behaviors[
          viewMode as ViewMode
        ];

      if (scrollBgAnimation) {
        editorAPI.components.behaviors.update(compRef, scrollBgAnimation);

        const effectName = scrollBgAnimation.name;

        if (
          PARALLAX_FITTING_EFFECTS.includes(effectName as ParallaxEffectName) &&
          backgroundData
        ) {
          editorAPI.components.design.update(
            compRef,
            {
              background: {
                ...backgroundData,
                fittingType: getFittingTypeForParallax(backgroundData),
              },
            },
            false,
          );
        }
      }
    });
  }

  function removeBehaviors(compRef: CompRef) {
    const behaviors = (getBehaviors(compRef, editorAPI.dsRead) || []) as
      | Behavior[]
      | [];

    behaviors.forEach(({ name, action, viewMode }) =>
      editorAPI.components.behaviors.remove(compRef, name, action, viewMode),
    );
  }

  function getFittingTypeForParallax(backgroundData: BackgroundData) {
    if (backgroundData?.mediaRef?.type === 'WixVideo') {
      return backgroundData.fittingType;
    }

    const currentFittingType = backgroundData.fittingType;
    const imageData = backgroundData.mediaRef;

    const tile = fittingTypes.TILE as Extract<FittingType, 'tile'>;
    const scaleToFill = fittingTypes.SCALE_TO_FILL as Extract<
      FittingType,
      'fill'
    >;

    const isSmaller = backgroundUtils.isSmallerFromContainer(
      imageData.width,
      imageData.height,
      MAX_TILE_WIDTH,
      MAX_TILE_HEIGHT,
    );
    const isTile =
      currentFittingType === tile ||
      (isSmaller && currentFittingType !== scaleToFill);

    return isTile ? tile : scaleToFill;
  }

  async function setAppWidgetEffects(
    compRef: CompRef,
    animationRules: AnimationRules,
  ) {
    const widgetTypeRef = editorAPI.components.getChildren(compRef)[0];
    const widgetType = editorAPI.components.getType(widgetTypeRef);

    if (widgetType === FORM_CONTAINER) {
      animateFormComponents(widgetTypeRef, widgetType, animationRules);
    }

    await setComponentsEffects(compRef, widgetType, animationRules);
  }

  async function animateFormComponents(
    widgetTypeRef: CompRef,
    widgetType: string,
    animationRules: AnimationRules,
  ) {
    // we need to animate all comps in form with the same animation as form container
    const compsInForm = editorAPI.components.getChildren(widgetTypeRef);
    await Promise.all(
      compsInForm.map((compRef) =>
        setComponentsEffects(compRef, widgetType, animationRules),
      ),
    );
  }

  function getComponentsToAnimate(
    rootCompRef: CompRef,
    components: CompRef[] = [],
  ): CompRef[] {
    const children = editorAPI.components.getChildren(rootCompRef);

    children.forEach((child) => {
      const compType = editorAPI.components.getType(child);
      components.push(child);

      if (!BLACK_LISTED_ITERATABLE_COMPONENTS.includes(compType)) {
        getComponentsToAnimate(child, components);
      }
    });
    return components;
  }

  function getComponentsOnPage(sectionsRef: CompRef[]) {
    const compsOnPage = sectionsRef.flatMap((sectionRef) =>
      getComponentsToAnimate(sectionRef),
    );

    return compsOnPage;
  }

  /* Public API functions */

  async function applyGlobalAnimations(
    compRef: CompRef,
    animationKitName?: string,
  ) {
    const siteAnimationKit = getSiteAnimationKit();
    const animationRules = animationKitName
      ? getAnimationRules(animationKitName)
      : getAnimationRules(siteAnimationKit?.title);

    if (!animationRules) {
      return;
    }
    const compType = editorAPI.components.getType(compRef);
    const shouldNotExtractComponentsInside =
      !editorAPI.sections.isSectionLike(compRef) &&
      !editorAPI.columns.isStrip(compRef);

    if (shouldNotExtractComponentsInside) {
      animateComponents(compRef, animationRules);
      return;
    }

    setBgScrollEffects(
      compRef,
      compType,
      animationRules as BehaviorsAnimationRules,
    );

    const allCompsInSection = getComponentsToAnimate(compRef);
    await Promise.all(
      allCompsInSection.map((compRef) =>
        animateComponents(compRef, animationRules),
      ),
    );
  }

  function getSiteAnimationKit() {
    const animationKit =
      // @ts-expect-error
      editorAPI.site.features.get(
        ANIMATION_KIT_DATA_NAMESPACE.NAME,
      ) as SiteAnimationKit;

    if (!animationKit?.isConnected) {
      return;
    }

    return animationKit;
  }

  function setSiteAnimationKit(title: string, isConnected: boolean) {
    // @ts-expect-error
    editorAPI.site.features.update(ANIMATION_KIT_DATA_NAMESPACE.NAME, {
      title,
      isConnected,
      type: ANIMATION_KIT_DATA_NAMESPACE.TYPE,
    });
  }

  function startPreview(sectionsRef: CompRef[], animationKitName: string) {
    const animationRules = getAnimationRules(animationKitName);
    const compsOnPage = getComponentsOnPage(sectionsRef);

    compsOnPage.forEach((compRef) => {
      let compType = editorAPI.components.getType(compRef);

      if (compType === APP_WIDGET) {
        const widgetTypeRef = editorAPI.components.getChildren(compRef)[0];
        const widgetType = editorAPI.components.getType(widgetTypeRef);
        compType = widgetType;
      }

      const effectData = getEffectDataByViewMode(
        animationRules,
        compRef,
        compType,
        'DESKTOP',
        'entrance',
      );

      if (effectData && !COMPS_WITH_BG_SCROLL.includes(compType)) {
        previewApi.start(compRef, effectData);
      }
    });
  }

  function stopPreview(sectionsRef: CompRef[]) {
    const compsOnPage = getComponentsOnPage(sectionsRef);

    previewApi.stop(compsOnPage);
  }

  function shouldUpdateTextComponent(
    originalTextRawHtml: string,
    updatedTextRawHtml: string,
  ) {
    const originalTextType = textUtils.getRootTag(originalTextRawHtml);
    const updatedTextType = textUtils.getRootTag(updatedTextRawHtml);

    return originalTextType !== updatedTextType;
  }

  return {
    applyGlobalAnimations,
    getSiteAnimationKit,
    setSiteAnimationKit,
    startPreview,
    stopPreview,
    shouldUpdateTextComponent,
  };
};

import type { EntryPoint } from '#packages/apilib';
import { EditorParamsApiKey } from '#packages/apis';
import type { ViewerName } from 'types/core';
import type { MigrationFlow } from '#packages/sectionsMigration';
import type { WixBaseUiEnvironmentProviderProps } from '@wix/wix-base-ui/src/util/WixBaseUiEnvironment/WixBaseUiEnvironment';

export interface EditorParams {
  isInsideEditorX: boolean;
  isInsideAppStudio: boolean;
  isSectionsExperienceEnabled?: boolean;
  sectionsMigrationFlow: MigrationFlow;
  sectionsMigrationVersion: string;
  siteGenerationWizard: boolean;
  siteCreationWizard: boolean;
  originalTemplateId: string;
  appsToInstall: string;
  isLocal: boolean;
  isDebug: boolean;
  isQA: boolean;
  isTestRunner: boolean;
  isBiErrorsAndFedopsEnabled: boolean;
  isRollout: boolean;
  isTabDuplicated: boolean;
  shouldMeasureFrameRate: boolean;
  viewerName: ViewerName;
  esi: string;
  siteId: string;
  metaSiteId: string;
  baseUIOverrides: Partial<WixBaseUiEnvironmentProviderProps>;
}

export function createEditorParamsEntryPoint(
  editorParams: EditorParams,
): EntryPoint {
  return {
    name: 'EditorParams',
    declareAPIs: () => [EditorParamsApiKey],
    attach: (shell) => {
      shell.contributeAPI(EditorParamsApiKey, () => editorParams);
    },
  };
}

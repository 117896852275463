import type { EditorAPI } from '#packages/editorAPI';
import { panels } from '#packages/stateManagement';
import { addPanel, workspace } from '#packages/util';
import _ from 'lodash';
import type { PanelData } from './openPanelByURL.types';
import { getAllPanels } from './openPanelByURLData';

function getNormalizedPanelKey(openPanelParam: string | null) {
  return openPanelParam?.split('.', 1)[0]?.toLowerCase();
}

function getPropsFromConfig(panelData: PanelData, panelPropValues: string[]) {
  if (
    panelData &&
    panelPropValues.length &&
    typeof panelData.propsGenerator === 'function'
  ) {
    return panelData.propsGenerator(...panelPropValues);
  }

  return {};
}

const toLowerCase = (s: string) => s.toLowerCase();

function createPropsForPanel(panelPathData: string, panelData: PanelData) {
  const panelPropValues = panelPathData.split('.').slice(1);
  const props = getPropsFromConfig(panelData, panelPropValues.map(toLowerCase));
  return _.pickBy(props);
}

function shouldOpenPanel(openPanelParam: string, editorAPI: EditorAPI) {
  return !!getOpenPanelConfig(openPanelParam, editorAPI);
}

function getOpenPanelConfig(openPanelParam: string, editorAPI: EditorAPI) {
  const panelKey = getNormalizedPanelKey(openPanelParam);

  if (panelKey) {
    if (panelKey === 'newaddpanel' && !addPanel.isNewPanelEnabled()) {
      return null;
    }

    const allPanels = getAllPanels();

    const panelData = allPanels[panelKey];

    if (!panelData) {
      return null;
    }

    if (
      typeof panelData.shouldOpenPanelByRestrictions === 'function' &&
      !panelData.shouldOpenPanelByRestrictions(editorAPI)
    ) {
      return null;
    }

    return panelData;
  }

  return null;
}

function getOpenPanelQueryParam(
  editorQuery: Record<string, string>,
): string | null {
  for (const key of Object.keys(editorQuery)) {
    if (key.toLowerCase() === 'openpanel') {
      return editorQuery[key];
    }
  }

  return null;
}

function openPanel(editorQuery: Record<string, string>, editorAPI: EditorAPI) {
  const openPanelParam = getOpenPanelQueryParam(editorQuery);
  const panelData = getOpenPanelConfig(openPanelParam, editorAPI);

  if (panelData) {
    const panelName = panelData.name;
    const panelProps = createPropsForPanel(openPanelParam, panelData);
    if (panelData.openFunction) {
      panelData.openFunction(editorAPI);
    } else if (workspace.isNewWorkspaceEnabled()) {
      editorAPI.store.dispatch(
        panels.actions.openLeftPanel(panelName, panelProps),
      );
    } else {
      editorAPI.panelManager.openPanel(panelName, panelProps);
    }
  }
}

export { openPanel, shouldOpenPanel };

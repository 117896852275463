import React from 'react';
import { translate } from '#packages/i18n';
import { cx } from '#packages/util';

export interface DefaultTabDefinition {
  id: string;
  label: string;
  active: boolean;
}

export interface ModefulTabDefinition {
  modeId: string;
  label: string;
  active: boolean;
}

export type TabDefinition = DefaultTabDefinition | ModefulTabDefinition;

interface TabProps {
  tab: TabDefinition;
  onTabClick: (tab: TabDefinition) => void;
  translate?: boolean;
  tabClass: string;
  tabsAnimationText?: string;
  tabIndicationComponent: React.Component | null;
  customTabs: string[];
}

export default class extends React.Component<TabProps> {
  static displayName = 'selectionBoxTab';

  getTabLabel = () => {
    return this.props.translate
      ? translate(this.props.tab.label)
      : this.props.tab.label;
  };

  render() {
    return (
      <label
        onClick={() => {
          this.props.onTabClick(this.props.tab);
        }}
        className={cx(
          'label-controller',
          'tab',
          this.props.tabClass,
          ...(this.props.customTabs ? this.props.customTabs : []),
          {
            active: this.props.tab.active,
            'tab-with-indication': !!this.props.tabIndicationComponent,
          },
        )}
      >
        {this.props.tabIndicationComponent}
        <div className="label">{this.getTabLabel()}</div>
        <div key={this.props.tabsAnimationText} className="action">
          {translate(this.props.tabsAnimationText)}
        </div>
      </label>
    );
  }
}

import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import pageCategories from '../../../utils/pageCategories';
import * as actions from '../../../api/actions/actions';
import pagesView from '../pagesView';
import type { EditorAPI } from '#packages/editorAPI';
import type { ISettingsData } from '../../pagesPanel';
import type { CompRef, PagesData } from 'types/documentServices';
import { sendNavigateToPageBiEvent } from '../../../utils';

const CUSTOM_REGISTRATION_APP_DEF_ID = '5f4fa4f1-5afd-4ac0-8a85-e5ac1d2b9b7d';
const CUSTOM_LOGIN_APP_DEF_ID = 'bbe1406a-31f5-4f3f-9e0a-b39dfd25274f';
const DEFAULT_SIGN_UP_PAGE_ID = 'defaultSignup';
const DEFAULT_SIGN_IN_PAGE_ID = 'defaultSignIn';
const FORM_TYPES = {
  SIGN_IN: 'signIn',
  SIGN_UP: 'signUp',
} as const;
export type IFormType = (typeof FORM_TYPES)[keyof typeof FORM_TYPES];

const translationKeys = {
  [FORM_TYPES.SIGN_UP]: {
    default: `NewPages_Member_Signup_Default_Title`,
    custom: `NewPages_Member_Signup_Custom_Title`,
    developer: `NewPages_Member_Signup_Developer_Title`,
  },
  [FORM_TYPES.SIGN_IN]: {
    default: `NewPages_Member_SignIn_Default_Title`,
    custom: `NewPages_Member_SignIn_Custom_Title`,
    developer: `NewPages_Member_SignIn_Developer_Title`,
  },
};

const getPageTitle = (
  editorAPI: EditorAPI,
  appDefId: string,
  type: IFormType,
  customFormPageId: string,
) => {
  const pages = editorAPI.pages.popupPages.getDataList();
  const managedPopup = pages.find((page) => page.managingAppDefId === appDefId)
    ?.id;

  if (!customFormPageId) {
    return translate(translationKeys[type].default);
  }

  return managedPopup === customFormPageId
    ? translate(translationKeys[type].custom)
    : translate(translationKeys[type].developer);
};

const getCustomPageIdIfExist = (
  popups: PagesData[],
  getCustomPageId: () => string,
  setCustomPageId: (id: string) => void,
) => {
  const savedCustomPageId = getCustomPageId();
  const isCustomPageExist = popups.find(
    (page) => page.id === savedCustomPageId,
  );
  if (savedCustomPageId && !isCustomPageExist) {
    setCustomPageId(null);
  }
  return isCustomPageExist ? savedCustomPageId : null;
};

const getPages = (
  { editorAPI }: { editorAPI: EditorAPI },
  onShowSettings: (settingsData: Partial<ISettingsData>) => void,
) => {
  const popups = editorAPI.pages.popupPages.getDataList();
  const customAuthPagesIds = {
    signUp: getCustomPageIdIfExist(
      popups,
      () => editorAPI.siteMembers.getCustomSignupPageId(),
      (id: string) => editorAPI.siteMembers.setCustomSignupPageId(id),
    ),
    signIn: getCustomPageIdIfExist(
      popups,
      () => editorAPI.siteMembers.getCustomSignInPageId(),
      (id: string) => editorAPI.siteMembers.setCustomSignInPageId(id),
    ),
  };
  const customAuthPagesActions = {
    signUp: actions.signup(
      editorAPI,
      onShowSettings,
      customAuthPagesIds.signUp,
    ),
    signIn: actions.signIn(
      editorAPI,
      onShowSettings,
      customAuthPagesIds.signIn,
    ),
  };

  const pages = [
    {
      id: customAuthPagesIds.signUp || DEFAULT_SIGN_UP_PAGE_ID,
      typeSymbol: 'regularPageType',
      title: getPageTitle(
        editorAPI,
        CUSTOM_REGISTRATION_APP_DEF_ID,
        FORM_TYPES.SIGN_UP,
        customAuthPagesIds.signUp,
      ),
      actions: customAuthPagesActions.signUp,
    },
    {
      id: customAuthPagesIds.signIn || DEFAULT_SIGN_IN_PAGE_ID,
      typeSymbol: 'regularPageType',
      title: getPageTitle(
        editorAPI,
        CUSTOM_LOGIN_APP_DEF_ID,
        FORM_TYPES.SIGN_IN,
        customAuthPagesIds.signIn,
      ),
      actions: customAuthPagesActions.signIn,
    },
  ];

  return pages;
};

const getNavigateTo =
  (
    { editorAPI }: { editorAPI: EditorAPI },
    biPanelName: string,
    onShowSettings: (settingsData: Partial<ISettingsData>) => void,
  ) =>
  (pageId: AnyFixMe) => {
    const customSignupPageId = editorAPI.siteMembers.getCustomSignupPageId(); // might be nullish
    const customSignInPageId = editorAPI.siteMembers.getCustomSignInPageId(); // might be nullish
    const isSignUpItemClicked =
      pageId === DEFAULT_SIGN_UP_PAGE_ID || pageId === customSignupPageId;
    const isSignInItemClicked =
      pageId === DEFAULT_SIGN_IN_PAGE_ID || pageId === customSignInPageId;
    let clickedPageId: AnyFixMe;

    if (isSignUpItemClicked) {
      clickedPageId = customSignupPageId;
    }
    if (isSignInItemClicked) {
      editorAPI.bi.event(coreBi.events.pages.navigate_login_settings);
      clickedPageId = customSignInPageId;
    }
    const formType = isSignUpItemClicked
      ? FORM_TYPES.SIGN_UP
      : FORM_TYPES.SIGN_IN;

    if (clickedPageId && clickedPageId !== editorAPI.pages.getFocusedPageId()) {
      editorAPI.pages.navigateTo(clickedPageId, function () {
        editorAPI.selection.selectComponentByCompRef(
          editorAPI.pages.popupPages.getPopupContainer(
            clickedPageId,
          ) as CompRef,
        );
      });
      onShowSettings({
        pageId: clickedPageId,
        tabType: 'page',
        formType,
        menuId: undefined,
      });
      sendNavigateToPageBiEvent(editorAPI, {
        pageId: clickedPageId,
        panelName: biPanelName,
        biCategory: pageCategories.SIGNUP.biCategory,
      });
    } else if (!clickedPageId) {
      // Since we don't want the wrong page to be presented nor the wrong
      // page to be mark as selected we first close any open pop up.
      editorAPI.pages.popupPages.close();
      onShowSettings({
        pageId: clickedPageId,
        tabType: 'page',
        formType,
        menuId: undefined,
      });
    }
  };

const mapEditorAPIToSignUpPagesViewProps = (
  { editorAPI }: { editorAPI: EditorAPI },
  {
    biPanelName,
    onShowSettings,
    onHelp,
  }: {
    biPanelName: string;
    onShowSettings: (settingsData: Partial<ISettingsData>) => void;
    onHelp: (helpId: string) => void;
  },
) => {
  return {
    pages: getPages({ editorAPI }, onShowSettings),
    navigateTo: getNavigateTo({ editorAPI }, biPanelName, onShowSettings),
    biEvent: editorAPI.bi.event,
    biCategory: pageCategories.SIGNUP.biCategory,
    renameEnabled: false,
    onHelp: _.partial(onHelp, pageCategories.SIGNUP.helpId),
  };
};

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

export default connect(
  EDITOR_API,
  mapEditorAPIToSignUpPagesViewProps,
)(renderWhenMutated(pagesView));

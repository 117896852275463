import _ from 'lodash';
import { EditorAPIKey, BiApiKey } from '#packages/apis';
import * as stateManagement from '#packages/stateManagement';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import createCopyPasteUtils from '@/rEditor/app/APISections/copyPasteWrapper/createCopyPasteUtils';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import * as clipboardStorage from '@/rEditor/app/APISections/clipboardStorage/clipboardStorage';
import type { Shell } from '#packages/apilib';
import type {
  ClipboardCompItem,
  ClipboardPageItem,
  ClipboardItem,
} from './types';

const { isPasteDisabled } = stateManagement.featuresOverridden.selectors;

export function createClipboardApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const biApi = shell.getAPI(BiApiKey);
  const storage = clipboardStorage.createInstance(editorAPI, biApi);
  const copyPasteUtils = createCopyPasteUtils(editorAPI);

  function setItem(value: AnyFixMe, type: AnyFixMe) {
    const metaData = copyPasteUtils.getMetaDataForCopy();

    storage.set({
      type,
      value,
      metaData,
    });
  }

  function removeItem() {
    storage.remove();
  }

  function getItem(): ClipboardItem {
    if (isPasteDisabled(editorAPI.store.getState())) {
      return {};
    }

    const clipboardItem = storage.get();

    if (!clipboardItem) {
      return {};
    }

    return clipboardItem as ClipboardCompItem | ClipboardPageItem;
  }

  function isCrossSite() {
    //@ts-expect-error
    const { metaData } = getItem();

    if (!metaData) {
      return false;
    }

    return metaData.metaSiteId !== editorAPI.dsRead.generalInfo.getMetaSiteId();
  }

  function hasItem() {
    //@ts-expect-error
    const { value } = getItem();

    return Boolean(value);
  }

  function hasItemOfType(type: AnyFixMe) {
    const clipboardItem = getItem();

    //@ts-expect-error
    return Boolean(clipboardItem.value) && clipboardItem.type === type;
  }

  function isAlreadyPasted(clipboardGuid: AnyFixMe) {
    const { clipboard } = editorAPI.store.getState();
    const lastPastedClipboardGuid = clipboard.lastPasted;

    return clipboardGuid === lastPastedClipboardGuid;
  }

  function setLastPasted(clipboardGuid: AnyFixMe) {
    const { clipboard } = editorAPI.store.getState();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newClipboard = _.assign({}, clipboard, { lastPasted: clipboardGuid });

    editorAPI.updateState({ clipboard: newClipboard });
  }

  return {
    setItem,
    removeItem,
    getItem,
    hasItem,
    hasItemOfType,
    isCrossSite,
    isAlreadyPasted,
    setLastPasted,
  };
}

import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorRestrictionsApiKey,
  PanelManagerApiKey,
  SelectionApiKey,
} from '#packages/apis';
import { ReadonlyModeApiKey } from './publicApiKey';
import { ReadonlyModeApi } from './readonlyModeApi';
import { ReadonlyModeStore } from './readonlyModeStore';

export class ReadonlyModeScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  restrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  panelManagerApi = this.useDependency(PanelManagerApiKey);
  selectionApi = this.useDependency(SelectionApiKey);

  store = this.declareStore(ReadonlyModeStore);
}

export const ReadonlyModeEntryPoint = createEntryPoint({
  name: 'ReadonlyMode',
  Scope: ReadonlyModeScope,
  publicApi({ contributeApi }) {
    contributeApi(ReadonlyModeApiKey, ReadonlyModeApi);
  },
});

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as WixPromoteSEOPanel from './tabs/SEO/WixPromoteSEOPanel';
import SEOPanelHOC from './tabs/SEO/SeoPanelHOC';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import _ from 'lodash';
import * as core from '#packages/core';
import tabPageInfoMainMenu from './tabs/tabPageInfoMainMenu';
import tabLayout from './tabs/tabLayout';
import tabMobileLayout from './tabs/tabMobileLayout';
import tabPermissions from './tabs/tabPermissions';
import React from 'react';
import TabsHolder from './tabs/pageSettingsPanelTabsHolder';
import { sections } from '#packages/util';
import PageSettingsLayoutTab from './tabs/PageSettingsLayoutTab/PageSettingsLayoutTab';
import PermissionsTab from './tabs/PageSettingsPermissionsTab/permissionsTab';
import experiment from 'experiment';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'pageSettingsPanelTabsNew',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    menuItem: PropTypes.object.isRequired,
    selectedTabType: PropTypes.string,
    setActiveTabType: PropTypes.func.isRequired,
    setHelpIds: PropTypes.func.isRequired,
    disabledTabs: PropTypes.arrayOf(PropTypes.string),
  },

  isCustomErrorPage(pageId) {
    const editorAPI = this.getEditorAPI();
    return editorAPI.pages.isCustomErrorPage(pageId);
  },

  getInitialState() {
    return {
      advancedSeoPanel: null,
    };
  },

  isTabDisabled(tab) {
    return this.props.disabledTabs?.includes(tab.tag);
  },

  getSEOPanel() {
    if (this.state.advancedSeoPanel) {
      return this.state.advancedSeoPanel;
    }
    WixPromoteSEOPanel.getSEOPanels().then((advancedSeoPanel) => {
      //eslint-disable-line new-cap
      this.setState({ advancedSeoPanel });
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
      const helpIds = _.reduce(
        advancedSeoPanel.tabs,
        (acc, tab) => {
          acc[tab.name] = tab.helpArticleId;
          return acc;
        },
        {},
      );
      this.props.setHelpIds(helpIds);
    });
    return null;
  },

  getSEOTabs() {
    const seoPanel = this.getSEOPanel();

    if (!seoPanel) {
      return [
        {
          tag: constants.PAGE_SETTINGS_TABS.SEO,
          name: translate('constants.PAGE_SETTINGS_TABS.TITLE_KEYS.BASIC_SEO'),
          props: {
            menuItem: this.props.menuItem,
          },
        },
      ];
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(seoPanel.tabs, (tab) => ({
      tag: tab.name,
      name: translate(tab.label_key),
      panelClass: SEOPanelHOC(tab.PanelClass), //eslint-disable-line new-cap
      props: {
        pageData: this.props.menuItem.pageData,
        tabName: tab.name,
        setActiveTab: this.props.setActiveTabType,
      },
    }));
  },

  filterTabs(tabs) {
    _.remove(tabs, (tab) => !tab.panelClass);
  },

  getTabsPanel() {
    const editorAPI = this.getEditorAPI();
    if (editorAPI.isMobileEditor()) {
      return [
        {
          tag: constants.PAGE_SETTINGS_TABS.MOBILE_LAYOUT,
          name: translate('Mobile_Pages_Settings_Header_Text'),
          panelClass: sections.isSectionsEnabled()
            ? PageSettingsLayoutTab
            : tabMobileLayout,
          props: {
            menuItem: this.props.menuItem,
          },
        },
      ];
    }

    const tabs = [
      {
        tag: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
        name: translate('Pages_Info_Tab_Title'),
        panelClass: tabPageInfoMainMenu,
        props: {
          menuItem: this.props.menuItem,
        },
      },
    ];

    if (!this.props.hideLayoutTab) {
      tabs.push({
        tag: constants.PAGE_SETTINGS_TABS.LAYOUT,
        name: translate('Pages_Layouts_Tab_Title'),
        panelClass: sections.isSectionsEnabled()
          ? PageSettingsLayoutTab
          : tabLayout,
        props: {
          menuItem: this.props.menuItem,
        },
      });
    }

    if (this.isCustomErrorPage(this.props.menuItem.pageData.id)) {
      this.filterTabs(tabs);
      return tabs;
    }

    const showNewPagePermissions = experiment.isOpen(
      'se_page_permissions_improvements',
    );

    tabs.push({
      tag: constants.PAGE_SETTINGS_TABS.PERMISSIONS,
      name: translate('Pages_Permissions_Tab_Title'),
      panelClass: showNewPagePermissions ? PermissionsTab : tabPermissions,
      props: {
        pageData: this.props.menuItem.pageData,
      },
    });

    tabs.push(...this.getSEOTabs());
    this.filterTabs(tabs);
    return tabs;
  },

  enhanceTabs(tabs) {
    return tabs.map((tab) => ({ ...tab, disabled: this.isTabDisabled(tab) }));
  },

  render() {
    const tabs = this.getTabsPanel();
    return <TabsHolder tabs={this.enhanceTabs(tabs)} {...this.props} />;
  },
});

import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey, SelectedComponentApiKey } from '#packages/apis';
import { createSelectedComponentApi } from './selectedComponentWrapper';

export const SelectedComponentEntrypoint: EntryPoint = {
  name: 'SelectedComponentApi',
  declareAPIs: () => [SelectedComponentApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(SelectedComponentApiKey, () =>
      createSelectedComponentApi(shell),
    );
  },
};

// @ts-nocheck
import _ from 'lodash';
import * as core from '#packages/core';

const { SnapToHandler } = core.utils;
let snapDirection;
let snapToHandler;

function init(editorAPI, selectedComponent, _snapDirection) {
  snapDirection = _snapDirection;
  snapToHandler = new SnapToHandler(editorAPI, selectedComponent);
}

function snapIfPossible(editorAPI, layout, alterWidth) {
  const filterPredicate = _(isVerticalOverLapping)
    .negate()
    .partial(layout, alterWidth)
    .value();
  const closestLayoutDistance = snapToHandler.getClosestLayoutDistance(
    editorAPI,
    layout,
    snapDirection,
    filterPredicate,
  );
  if (Math.abs(closestLayoutDistance.y) < 5) {
    if (snapDirection.top && snapDirection.bottom) {
      layout.y += closestLayoutDistance.y;
      layout.height -= closestLayoutDistance.y;
    } else if (snapDirection.bottom) {
      layout.height += closestLayoutDistance.y;
    }
  }
}

const isVerticalOverLapping = (
  ourComponentLayout,
  alterWidth,
  candidateLayout,
) => {
  const ourComponentWidth = ourComponentLayout.width
    ? ourComponentLayout.width
    : alterWidth;

  const ourComponentLeft = ourComponentLayout.x;
  const ourComponentRight = ourComponentLeft + ourComponentWidth;

  const candidateLeft = candidateLayout.x;
  const candidateRight = candidateLeft + candidateLayout.width;

  let isOverLapping = false;
  if (candidateLeft >= ourComponentLeft && candidateLeft <= ourComponentRight) {
    isOverLapping = true;
  } else if (
    candidateRight >= ourComponentLeft &&
    candidateRight <= ourComponentRight
  ) {
    isOverLapping = true;
  } else if (
    ourComponentLeft >= candidateLeft &&
    ourComponentLeft <= candidateRight
  ) {
    isOverLapping = true;
  } else if (
    ourComponentRight >= candidateLeft &&
    ourComponentRight <= candidateRight
  ) {
    isOverLapping = true;
  }

  return isOverLapping;
};

function waitForChangesAppliedAndDrawSnapLines(
  minimalEditorAPI,
  layout,
  selectedComponent,
) {
  minimalEditorAPI.waitForChangesApplied(function () {
    // we wait for changes to be applied to make sure layout constraint didn't change our layout during update
    if (snapToHandler) {
      const updatedLayout =
        minimalEditorAPI.getRelativeToScreen(selectedComponent);
      if (minimalEditorAPI.snapToEnabled && layout.rotationInDegrees === 0) {
        drawSnapLines(minimalEditorAPI.snapDataSetter, updatedLayout);
      }
    }
  });
}

function drawSnapLines(snapDataSetter, layout) {
  const snapData = snapToHandler.getSnapData(layout, snapDirection, true);
  snapDataSetter(snapData);
}

function clear(editorAPI) {
  snapToHandler = null;
  snapDirection = null;
  editorAPI.snapData.clear();
}

export { init, snapIfPossible, waitForChangesAppliedAndDrawSnapLines, clear };

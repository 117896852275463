import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';

import type {
  DragLimits,
  FloatingModeBarOwnProps,
  FloatingModeBarStateProps,
} from './floatingModeBar';
import constants from '#packages/constants';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import { FixedStageApiKey, EditorUIApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';

type FloatingModeBarDispatchProps =
  | ReturnType<typeof getDraggableSlotsActions>
  | {};

const { getDraggableSlotsActions } = stateManagement.draggableSlots.actions;

const { getStageLayout } = stateManagement.domMeasurements.selectors;

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const getDragLimits = (
  state: stateManagement.EditorState,
  isScrollableStage: boolean,
  isStageCut: boolean,
): DragLimits => {
  const { top, bottom, right, left, x } = getStageLayout(state);
  const xLimits = [left];
  const yLimits = [top];

  if (right > 0) {
    xLimits.push(right);
  }

  if (bottom > 0) {
    yLimits.push(bottom);
  }

  if (util.fixedStage.isFixedStageEnabled()) {
    xLimits[0] = x;
    yLimits[0] -= constants.UI.FIXED_STAGE_MARGIN_TOP;
    if (xLimits[1] && isScrollableStage && isStageCut) {
      xLimits[1] += constants.UI.EDITING_AREA_RIGHT_MARGIN;
    }
  }

  return {
    x: xLimits,
    y: yLimits,
  };
};

const mapStateToProps: MapStateToProps<
  FloatingModeBarStateProps,
  FloatingModeBarOwnProps
> = ({ state, editorAPI }) => {
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  return {
    dragLimits: getDragLimits(
      state,
      fixedStageAPI.isStageHorizontallyScrollable(),
      editorAPI.host.getAPI(EditorUIApiKey).stage.shouldCutEditingArea(),
    ),
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  FloatingModeBarDispatchProps,
  FloatingModeBarOwnProps
> = (dispatch, ownProps) => {
  const editorAPI = dispatch(getEditorAPI);
  if (!ownProps.draggableExtentionSlot) {
    return {};
  }
  return getDraggableSlotsActions(editorAPI, ownProps.draggableExtentionSlot);
};

export default connect(EDITOR_API, mapStateToProps, mapDispatchToProps);

import { ErrorReporter } from '@wix/editor-error-reporter';
import { createDefaultDesignWithOverrides } from './utils';
import type { CompRef } from '@wix/document-services-types';
import type { Scope } from './scope';
import _ from 'lodash';
import { DEFAULT_STYLE_PROPERTIES_FOR_DISIGN_PANEL } from './constants';

const getColorLayersOverrides = (scope: Scope, compRef: CompRef) => {
  if (!compRef) {
    return;
  }

  const compDesign = scope.components.design.get(compRef);
  if (compDesign) {
    return;
  }

  const compStyle = scope.components.style.get([compRef]);

  if (!compStyle) {
    return {};
  }

  const newAlphaValue =
    compStyle.style?.properties?.['alpha-bgctr'] ||
    compStyle.style?.properties?.['alpha-bg'] ||
    0;

  const newBgColorValue =
    compStyle.style?.properties?.bgctr ||
    compStyle.style?.properties?.bg ||
    'color_11';

  return {
    colorLayers: [
      {
        type: 'SolidColorLayer',
        opacity: +newAlphaValue,
        fill: {
          type: 'SolidColor',
          color: newBgColorValue,
        },
      },
    ],
  };
};

const getStyleOverrides = (scope: Scope, compRef: CompRef) => {
  if (!compRef) {
    return;
  }

  const compStyle = scope.components.style.get([compRef]);

  if (!compStyle) {
    throw new Error('Failed to get style for component');
  }
  const styleProperties = { ...compStyle.style?.properties };

  Object.keys(DEFAULT_STYLE_PROPERTIES_FOR_DISIGN_PANEL).forEach((key) => {
    // sometimes there is a border property as opposed to border top and bottom, migrate it for better ux
    if (key.startsWith('brw')) {
      styleProperties[key] ??=
        styleProperties.brw || DEFAULT_STYLE_PROPERTIES_FOR_DISIGN_PANEL[key];
      return;
    }

    const shouldAddShadowToggleProperty =
      key === 'shd' &&
      (!styleProperties[key] || styleProperties[key]?.includes('inset'));

    // we need to switch off toggle when there is no shadow or if the shadow is not supported
    if (shouldAddShadowToggleProperty) {
      styleProperties['boxShadowToggleOn-shd'] = 'false';
    }

    //insets dont work correctly with 360 control
    if (key === 'shd' && styleProperties[key]?.includes('inset')) {
      styleProperties[key] = DEFAULT_STYLE_PROPERTIES_FOR_DISIGN_PANEL[key];
    }

    styleProperties[key] ??= DEFAULT_STYLE_PROPERTIES_FOR_DISIGN_PANEL[key];
  });

  const hasCompStyleChanged = !_.isEqual(
    compStyle.style.properties,
    styleProperties,
  );

  if (!hasCompStyleChanged) return;

  compStyle.style.properties = styleProperties;

  return compStyle;
};

async function beforeMigration(scope: Scope) {
  const { autosaveManager, savePublish, history } = scope.editorAPI;
  history.add('BEFORE_SITE_SEGMENT_BG_MIGRATION');
  autosaveManager.init({ enabled: false });
  savePublish.lockSavePublish();
}

async function afterMigration(scope: Scope) {
  const { autosaveManager, savePublish, history } = scope.editorAPI;
  history.amend();
  autosaveManager.init({ enabled: true }, true);
  savePublish.unlockSavePublish();
  scope.siteSegmentMediaBgMigrationHook.resolve();
}

const handleDesignUpdate = async (scope: Scope, compRef: CompRef) => {
  try {
    const designOverrides = getColorLayersOverrides(scope, compRef);
    const styleOverrides = getStyleOverrides(scope, compRef);

    if (!designOverrides && !styleOverrides) return;

    await scope.editorAPI.documentServices.transactions.runAndWaitForApproval(
      async () => {
        if (styleOverrides) {
          scope.components.style.update(compRef, styleOverrides, true);
        }
        if (designOverrides) {
          scope.components.design.update(
            compRef,
            createDefaultDesignWithOverrides(designOverrides),
            false,
            true,
          );
        }
      },
    );
  } catch (error) {
    ErrorReporter.captureException(error, {
      tags: {
        siteSegmentInitDesignItem: true,
      },
    });
  }
};

export const initSiteSegmentMigration = async (scope: Scope) => {
  await beforeMigration(scope);

  const compsListForDesignEnforcement = [
    scope.editorAPI.siteSegments.getHeader(),
    scope.editorAPI.siteSegments.getFooter(),
  ];

  await Promise.allSettled(
    compsListForDesignEnforcement.map(async (compRef) => {
      await handleDesignUpdate(scope, compRef);
    }),
  );

  await afterMigration(scope);
};

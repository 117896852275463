import _ from 'lodash';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import * as superApps from '../superApps/superApps';
import constants from '../constants/constants';
import { editorWixRecorder } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as addPanelInfra from '#packages/addPanelInfra';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { PlatformOrigin } from '#packages/platform';
import type { AddWidgetOptions } from '../superApps/superApps';
import {
  InstallInitiator,
  EditorType,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';

const { WIDGET_INSTALLED_AFTER_COMPONENT_ADDED_TO_STAGE: addAppBiEvent } =
  coreBi.events.addPanel;

function addAppBi(
  editorAPI: EditorAPI,
  appDefId: string,
  compId: string,
  compStructure: CompStructure,
  category: string,
  sectionTitle: string,
  tags: AnyFixMe,
  itemId: string,
  origin: string,
  clickOrDrag: string,
) {
  const currentPage = editorAPI.pages.getFocusedPage();
  editorWixRecorder.addLabel(`${compStructure.componentType} added to stage`);
  editorAPI.bi.event(addAppBiEvent, {
    app_id: appDefId,
    category,
    preset_id: itemId,
    component_id: compId,
    section: sectionTitle,
    adding_method: clickOrDrag,
    origin: origin || 'add_panel',
    page_id: currentPage?.id,
    component_type: compStructure.componentType,
    preset_data_skin:
      (compStructure.style as AnyFixMe).skin || compStructure.skin,
  });
}

function setPublicData(
  compRef: CompRef,
  compStructure: CompStructure,
  editorAPI: AnyFixMe,
) {
  return _.attempt(function (compStructure) {
    const compProps =
      JSON.parse(_.get(compStructure, 'data.tpaData.content')) || {};
    _.forOwn(compProps, (value, key) => {
      editorAPI.dsActions.tpa.data.set(compRef, key, value);
    });
  }, compStructure);
}

const addCompOnClick = function (
  editorAPI: EditorAPI,
  appDefId: string,
  widgetId: string,
  compStructure: CompStructure,
  tags: AnyFixMe,
  id: string,
  {
    origin,
    sectionTitle,
    category,
  }: {
    origin?: string;
    sectionTitle?: string;
    category?: string;
  } = {},
  {
    position: addPosition,
    useRelativeToContainerLayout,
  }: {
    position?: { x: number; y: number } | void;
    useRelativeToContainerLayout?: boolean;
  } = {},
) {
  return new Promise<CompRef>(function (resolve) {
    util.fedopsLogger.interactionStarted(
      [
        util.fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
        util.fedopsLogger.INTERACTIONS.ADD_TPA_FROM_ADD_PANEL,
      ],
      {
        paramsOverrides: {
          component_type: compStructure?.componentType,
          method: 'click',
          app_id: appDefId,
        },
      },
    );
    const layoutWithoutPosition = getLayoutOfCompStructure(compStructure);
    const position =
      addPosition ||
      addPanelInfra.addPanelUtils.calcCompPastePosition(
        editorAPI,
        compStructure,
        id,
        null,
      );
    const layoutWithPosition = { ...layoutWithoutPosition, ...position };
    const options = completeAddWidgetOptions(
      editorAPI,
      appDefId,
      compStructure,
      tags,
      {
        ...(origin ? { origin } : undefined),
        presetId: id,
        category,
        section: sectionTitle,
        addingMethod: 'click',
        useRelativeToContainerLayout,
      },
    );
    options.cb = function (compRef: CompRef) {
      util.fedopsLogger.interactionEnded(
        [
          util.fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
          util.fedopsLogger.INTERACTIONS.ADD_TPA_FROM_ADD_PANEL,
        ],
        {
          paramsOverrides: {
            component_type: compStructure?.componentType,
            method: 'click',
            app_id: appDefId,
          },
        },
      );
      ErrorReporter.breadcrumb('added component from add panel', {
        method: 'click',
        appDefId,
        compRef,
      });
      setPublicData(compRef, compStructure, editorAPI);
      addAppBi(
        editorAPI,
        appDefId,
        compRef.id,
        compStructure,
        category,
        sectionTitle,
        tags,
        id,
        origin,
        'click',
      );
      resolve(compRef);
    };
    superApps.addWidget(appDefId, widgetId, layoutWithPosition, options);
  });
};

const addRefWidgetOnClick = superApps.onRefWidgetClick;

const addRefWidgetOnDrop = superApps.onRefWidgetDrop;

const addCompOnDrop = function (
  editorAPI: EditorAPI,
  appDefId: string,
  widgetId: string,
  layoutParams: { x?: number; y?: number },
  compPreset: AnyFixMe,
  parentComponent: CompRef,
  callback: FunctionFixMe,
  {
    origin,
    useRelativeToContainerLayout,
  }: {
    origin?: string;
    useRelativeToContainerLayout?: boolean;
  } = {},
) {
  return new Promise<CompRef>(function (resolve) {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.ADD_TPA_FROM_ADD_PANEL,
    );
    const layout = getLayoutOfCompStructure(compPreset.structure);
    const position = { x: layoutParams.x || 0, y: layoutParams.y || 0 };
    _.merge(layout, position);

    const options: AddWidgetOptions = completeAddWidgetOptions(
      editorAPI,
      appDefId,
      compPreset.structure,
      compPreset?.tags,
      {
        ...(origin ? { origin } : undefined),
        presetId: compPreset.itemId,
        category: compPreset.categoryId,
        section: compPreset.sectionTitle,
        addingMethod: 'drag',
        useRelativeToContainerLayout,
      },
    );

    if (util.sections.isSectionsEnabled()) {
      options.containerRef = parentComponent;
      options.useRelativeToContainerLayout =
        options.useRelativeToContainerLayout ?? true;
    } else {
      options.parentContainerRef = parentComponent;
    }

    options.cb = function (compRef) {
      util.fedopsLogger.interactionEnded(
        [
          util.fedopsLogger.INTERACTIONS.ADD_COMP_FROM_ADD_PANEL,
          util.fedopsLogger.INTERACTIONS.ADD_TPA_FROM_ADD_PANEL,
        ],
        {
          paramsOverrides: {
            component_type: compPreset.structure?.componentType,
            method: 'drag',
            app_id: appDefId,
          },
        },
      );
      ErrorReporter.breadcrumb('added component from add panel', {
        method: 'drag',
        appDefId,
        compRef,
        parentComponent,
      });
      setPublicData(compRef, compPreset.structure, editorAPI);
      addAppBi(
        editorAPI,
        appDefId,
        compRef.id,
        compPreset.structure,
        compPreset.categoryId,
        compPreset.sectionTitle,
        compPreset.tags,
        compPreset.itemId,
        origin,
        'drag',
      );
      if (_.isFunction(callback)) {
        callback(compRef);
      }
      resolve(compRef);
    };
    superApps.addWidget(appDefId, widgetId, layout, options);
  });
};

function getLayoutOfCompStructure(compStructure: AnyFixMe) {
  return {
    width: compStructure?.layout?.width,
    height: compStructure?.layout?.height,
  };
}

function getValidOrigin(
  editorAPI: EditorAPI,
  options: { origin?: string },
  appDefId: string,
) {
  const optionsFromUsage = options.origin;

  if (optionsFromUsage) {
    return optionsFromUsage;
  }

  // default behaviour from legacy usage
  return editorAPI.dsRead.tpa.app.isInstalled(appDefId)
    ? constants.BI.type.ADD_WIDGET
    : constants.BI.type.ADD_APP_ADD_PANEL;
}

const getComponentName = (
  editorAPI: EditorAPI,
  appDefId: string,
  compStructure: any,
): string => {
  const appData = editorAPI.tpa.app.getDataByAppDefId(appDefId);
  const widgetId = compStructure.data.widgetId;
  const componentName = appData?.components?.find(
    (c: AnyFixMe) => c.componentId === widgetId,
  )?.name;

  return componentName;
};
function completeAddWidgetOptions(
  editorAPI: EditorAPI,
  appDefId: string,
  compStructure: CompStructure,
  tags: AnyFixMe,
  options: {
    origin?: string;
    dontStretch?: boolean;
    styleId?: CompStructure['style'];
    parentContainerRef?: CompRef;
    containerRef?: CompRef;
    useRelativeToContainerLayout?: boolean;
    presetId?: string;
    category?: string;
    section?: string;
    addingMethod?: 'drag' | 'click';
    cb?: (compRef: CompRef) => void;
    componentName?: string;
    platformOrigin?: PlatformOrigin;
  } = {},
) {
  const completeOptions: AddWidgetOptions = {
    ...options,
    styleId: compStructure?.style || null,
    biOrigin: getValidOrigin(editorAPI, options, appDefId),
    componentName: getComponentName(editorAPI, appDefId, compStructure), // Used to distinguish tpaWidgets
    platformOrigin: options.platformOrigin || {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.AddPanel,
      },
    },
  };

  if (tags) {
    completeOptions.dontStretch = !tags.isFullWidth;
  }

  if (editorAPI.dsRead.pages.isWidgetPage(editorAPI.pages.getFocusedPageId())) {
    completeOptions.parentContainerRef = editorAPI.pages.getPrimaryContainer();
  }

  return completeOptions;
}

export {
  addCompOnClick,
  addCompOnDrop,
  addRefWidgetOnDrop,
  addRefWidgetOnClick,
};

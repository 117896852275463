import React, { useCallback } from 'react';
import * as util from '#packages/util';
import { AppList } from '../appList/appList';
import { BadgeIndicator, Tooltip } from '@wix/wix-base-ui';
import { InstalledAppActions } from '../installedAppActions/installedAppActions';
import { usePrivateApps } from '../privateAppsProvider/privateAppsProvider';
import styles from './privateAppsLists.scss';
import {
  hasUpdates,
  isTestVersion,
  TEST_VERSION,
} from '../../utils/appDetailsUtils';
import { translate } from '#packages/i18n';
import { AvailableAppActions } from '../availableAppActions/availableAppActions';
import { NewBlockButton } from '../newBlockButton/newBlockButton';
import type { AppData } from '../privateAppsProvider/privateAppsProvider';
import experiments from 'experiment';
import {
  isAutoUpdatePending,
  isAlphaApp,
  parseAppVersion,
  openBlocksEditor,
  OpenBlocksEditorOrigins,
} from '../../utils/utils';
import UpdateInProgressIndication from '../common/updateInProgressIndication/updateInProgressIndication';

function getVersionValue(app: AppData): string | undefined {
  if (isAlphaApp(app.blocksVersion)) {
    return translate('blocks-private-apps.AppDiscovery_App_List_Alpha_Tag');
  }
  if (isTestVersion(app)) {
    return translate(
      'blocks-private-apps.AppDiscovery_App_Versions_Test_Version_Title',
    );
  }
  return hasUpdates(app)
    ? util.appStudioUtils.formatVersion(app.version)
    : undefined;
}

export const PrivateAppsLists: React.FC = () => {
  const { installedApps, availableApps, isStudioAccount } = usePrivateApps();

  const rednerInstalledAppContentAfter = (app: AppData) => {
    if (!hasUpdates(app)) {
      return null;
    }
    if (
      isAutoUpdatePending(
        app.version,
        app.latestVersion,
        app?.appFields.installedVersion,
      )
    ) {
      return <UpdateInProgressIndication version={app.latestVersion} />;
    }
    if (app?.appFields.installedVersion === TEST_VERSION) {
      return null;
    }
    return (
      <Tooltip
        content={translate(
          'blocks-private-apps.AppDiscovery_Installed_List_Update_Available_Tooltip',
          { versionNumber: parseAppVersion(app.latestVersion) },
        )}
        shouldTranslate={false}
      >
        <div className={styles.pendingUpdatePimple}>
          <BadgeIndicator dataHook="pendingUpdateBadge" skin="standard" />
        </div>
      </Tooltip>
    );
  };

  const onOpenBlockEditor = useCallback(() => {
    openBlocksEditor(
      OpenBlocksEditorOrigins.PRIVATE_APPS_PANEL,
      isStudioAccount,
    );
  }, [isStudioAccount]);

  return (
    <>
      <div className={styles.listsWrapper}>
        <AppList
          apps={installedApps}
          label={'blocks-private-apps.AppDiscovery_Sidebar_Subheader1'}
          emptyStateLabel={
            'blocks-private-apps.AppDiscovery_Sidebar_Installed_Empty_Text'
          }
          dataHook="installedAppsSection"
          renderActions={(app) => (
            <InstalledAppActions origin="app item" app={app} />
          )}
          rowParams={(app: AppData) => ({
            className: 'installed-app-item',
            contentAfter: rednerInstalledAppContentAfter(app),
            value: getVersionValue(app),
          })}
        />
        {availableApps.length ? (
          <AppList
            apps={availableApps}
            className={styles.availableAppsList}
            label={'blocks-private-apps.AppDiscovery_Sidebar_Subheader2'}
            dataHook="availableAppsSection"
            renderActions={(app) => (
              <AvailableAppActions origin="app item" app={app} />
            )}
            rowParams={() => ({
              className: 'available-app-item',
            })}
          />
        ) : null}
      </div>
      <div className={styles.buttonContainer}>
        {experiments.isOpen('se_privateAppsPanel') && (
          <NewBlockButton onClick={onOpenBlockEditor} />
        )}
      </div>
    </>
  );
};

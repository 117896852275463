import * as bi from '../bi/bi';
import type { EditorAPI } from '#packages/editorAPI';

function openPlatformFailedAlert(editorAPI: EditorAPI, appName: string) {
  editorAPI.panelManager.openPanel(
    'savePublish.panels.common.failPanel',
    {
      titleKey: 'Notification_Box_App_Installation_Error_Title',
      description: 'Notification_Box_App_Installation_Error_Text',
      steps: [
        'Notification_Box_App_Installation_Error_Step1',
        'Notification_Box_App_Installation_Error_Step2',
      ],
      symbolName: 'serverError_NewWorkspace',
      helpMessage: '',
      helpLinkMessage: '',
      helpLink: '',
      translateArgs: {
        description: {
          appName,
        },
      },
    },
    false,
  );
}

function openProvisionFailedAlert(
  openPanel: (
    panelName: string,
    panelProps?: object,
    leavePanelsOpen?: boolean,
  ) => void,
  appDefinitionId: string,
  closeOtherPanels: boolean,
) {
  openPanel(
    'savePublish.panels.common.failPanel',
    {
      titleKey: 'tpa_messages_dashboard_before_save_error_title',
      description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
      steps: [
        'tpa_messages_upgrade_before_save_error_step_1',
        'tpa_messages_upgrade_before_save_error_step_2',
        'tpa_messages_dashboard_before_save_error_step_3',
      ],
      helpMessage: 'tpa_messages_dashboard_before_save_error_support',
      helpLinkMessage: 'tpa_messages_upgrade_before_save_error_support_link',
      helpLink: '',
      biEvent: bi.events.TPA_MESSAGE_LOADED,
      biParams: {
        app_id: appDefinitionId,
        description: 'provision failed',
        type: 'provision_failed',
      },
    },
    !closeOtherPanels,
  );
}

function openSaveFailedAlertForAppMarket(editorAPI: EditorAPI) {
  editorAPI.panelManager.openPanel(
    'savePublish.panels.common.failPanel',
    {
      titleKey: 'tpa_messages_dashboard_before_save_error_title',
      description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
      steps: [
        'tpa_messages_upgrade_before_save_error_step_1',
        'tpa_messages_upgrade_before_save_error_step_2',
        'tpa_messages_dashboard_before_save_error_step_3',
      ],
      helpMessage: 'tpa_messages_dashboard_before_save_error_support',
      helpLinkMessage: 'tpa_messages_upgrade_before_save_error_support_link',
      helpLink: '',
      biEvent: bi.events.TPA_MESSAGE_LOADED,
      biParams: {
        description: 'save for app market failed',
        type: 'save_for_app_market_failed',
      },
    },
    true,
  );
}

function openSaveFailedAlertForUpgrade(
  editorAPI: EditorAPI,
  appDefinitionId: string,
) {
  editorAPI.panelManager.openPanel(
    'savePublish.panels.common.failPanel',
    {
      titleKey: 'tpa_messages_upgrade_before_save_error_title',
      description: 'tpa_messages_upgrade_before_save_error_description',
      steps: [
        'tpa_messages_upgrade_before_save_error_step_1',
        'tpa_messages_upgrade_before_save_error_step_2',
        'tpa_messages_upgrade_before_save_error_step_3',
      ],
      helpMessage: 'tpa_messages_upgrade_before_save_error_support',
      helpLinkMessage: 'tpa_messages_upgrade_before_save_error_support_link',
      helpLink: '', //TODO: get real url
      biEvent: bi.events.TPA_MESSAGE_LOADED,
      biParams: {
        app_id: appDefinitionId,
        description: 'Error on upgrade TPA from template site',
        type: 'upgrade_save_error',
      },
    },
    true,
  );
}

const openSaveFailedAlertForDashboardUrl = function (
  editorAPI: EditorAPI,
  appDefinitionId: string,
) {
  editorAPI.panelManager.openPanel(
    'savePublish.panels.common.failPanel',
    {
      titleKey: 'tpa_messages_dashboard_before_save_error_title',
      description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
      steps: [
        'tpa_messages_upgrade_before_save_error_step_1',
        'tpa_messages_upgrade_before_save_error_step_2',
        'tpa_messages_dashboard_before_save_error_step_3',
      ],
      helpMessage: 'tpa_messages_dashboard_before_save_error_support',
      helpLinkMessage: 'tpa_messages_upgrade_before_save_error_support_link',
      helpLink: '',
      biEvent: bi.events.TPA_MESSAGE_LOADED,
      biParams: {
        app_id: appDefinitionId,
        description: 'Error on open dashboard from template site',
        type: 'dashboard_save_error',
      },
    },
    true,
  );
};

const openSaveFailedAlertForTemplateDashboardUrl = function (
  editorAPI: EditorAPI,
  appDefinitionId: string,
) {
  editorAPI.panelManager.openPanel(
    'savePublish.panels.common.failPanel',
    {
      titleKey: 'tpa_messages_dashboard_before_save_error_title',
      description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
      steps: [
        'tpa_messages_upgrade_before_save_error_step_1',
        'tpa_messages_upgrade_before_save_error_step_2',
        'tpa_messages_dashboard_before_save_error_step_3',
      ],
      helpMessage: 'tpa_messages_dashboard_before_save_error_support',
      helpLinkMessage: 'tpa_messages_upgrade_before_save_error_support_link',
      helpLink: '',
      biEvent: bi.events.TPA_MESSAGE_LOADED,
      biParams: {
        app_id: appDefinitionId,
        description: 'Error on open dashboard from template site',
        type: 'dashboard_save_error',
      },
    },
    true,
  );
};

function openSaveFailedAlertForPackageBuying(editorAPI: EditorAPI) {
  editorAPI.panelManager.openPanel('savePublish.panels.common.failPanel', {
    titleKey: 'PUBLISH_ERROR_TITLE_LABEL',
    description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
    steps: ['SAVE_PUBLISH_ERROR_OPTION_1', 'SAVE_PUBLISH_ERROR_OPTION_2'],
    helpMessage: 'SAVE_ERROR_LEARN_MORE_LABEL',
    helpLinkMessage: 'SAVE_PUBLISH_ERROR_LEARN_MORE_LINK',
    helpLink: '',
  });
}

export {
  openPlatformFailedAlert,
  openProvisionFailedAlert,
  openSaveFailedAlertForAppMarket,
  openSaveFailedAlertForUpgrade,
  openSaveFailedAlertForDashboardUrl,
  openSaveFailedAlertForTemplateDashboardUrl,
  openSaveFailedAlertForPackageBuying,
};

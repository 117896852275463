// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as wixStoreEditorActionsService from '../../wixStoreEditorActionsService';
import * as utils from '@wix/santa-editor-utils';

const appDefinitionId = '5e762334-899c-4e32-a7ad-b80f03471dfb';
const isStudio = utils.isOnWixStudio();

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  id: 'wixStoresCurrencyConverter',
  hide: false,
  title: 'add_section_label_currencyConverter',
  subNavigationTitle: 'add_section_label_currencyConverter',
  presetTitle: 'add_section_label_currencyConverter',
  tooltipTitle: 'add_section_label_currencyConverter',
  sectionName: 'currencyConverter',
  appDefinitionId,
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
    scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
    iconEnabledForComps: [],
  },
  props: {
    image: isStudio
      ? 'wixStore/sections/studio/currencyConverter.png'
      : 'wixStore/sections/currencyConverter.png',
    ...(isStudio ? {} : { imageStyle: { height: 37, margin: 11 } }),
    imageHover: null,
    onDrop: wixStoreEditorActionsService.onWidgetDrop(),
    onClick: wixStoreEditorActionsService.onWidgetClick(),
    items: [
      {
        id: 'Wix_Store_Currency_Converter',
        structure: {
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: isStudio ? 200 : 130,
            height: isStudio ? 40 : 45,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            appDefinitionId,
            widgetId: '2fee3364-0407-4de0-b186-832be61142b6',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'tpaw0',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
        },
        preset: {
          rect: isStudio
            ? { width: 200, height: 99, x: 0, y: 0 }
            : { width: 171, height: 57, x: 0, y: 0 },
          label: '',
        },
      },
    ],
    compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
  },
};

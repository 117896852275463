import type { EntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  ComponentsApiKey,
} from '#packages/apis';

import { createBiApi } from './api';
import { BiApiKey } from './publicApiKey';
export * from './api';

export const BiEntrypoint: EntryPoint = {
  name: 'BiApi',
  declareAPIs: () => [BiApiKey],
  getDependencyAPIs: () => [EditorAPIKey, ComponentsApiKey, EditorCoreApiKey],
  attach(shell) {
    shell.contributeAPI(BiApiKey, () => createBiApi(shell));
  },
  async extend(shell) {
    const biApi = shell.getAPI(BiApiKey);
    await shell.getAPI(EditorCoreApiKey).hooks._dsIsReady.promise;
    biApi.initBI();
  },
};

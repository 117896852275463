import type { FileInput, FileOutput } from '@wix/background-media-utils';
export enum MediaType {
  Picture = 'picture',
  Video = 'video',
  Music = 'music',
  Document = 'document',
  Shape = 'shape',
  Swf = 'swf',
  Archive = 'archive',
}

export interface MediaManagerItemBase {
  id: string;
  source?: string;
  name?: string;
  mediaType: string;
  title: string;
  fileName: string;
  tags?: string[];
  vendor?: string;
  reference?: string;
  fileInput: FileInput;
  fileOutput: FileOutput;
  fileUrl: string;
  uri: string;
}

export interface MediaManagerImage extends MediaManagerItemBase {
  mediaType: MediaType.Picture;
  width: number;
  height: number;
}

export interface MediaManagerVideo extends MediaManagerItemBase {
  mediaType: MediaType.Video;
  width: number;
  height: number;
}
export interface MediaManagerMusic extends MediaManagerItemBase {
  mediaType: MediaType.Music;
}
export interface MediaManagerDocument extends MediaManagerItemBase {
  mediaType: MediaType.Document;
}
export interface MediaManagerShape extends MediaManagerItemBase {
  mediaType: MediaType.Shape;
  uri: string;
}
export interface MediaManagerSwf extends MediaManagerItemBase {
  mediaType: MediaType.Swf;
}
export interface MediaManagerArchive extends MediaManagerItemBase {
  mediaType: MediaType.Archive;
}

export interface MediaManagerInfo {
  path: string;
}

export type MediaManagerItem =
  | MediaManagerImage
  | MediaManagerVideo
  | MediaManagerMusic
  | MediaManagerDocument
  | MediaManagerShape
  | MediaManagerSwf
  | MediaManagerArchive;

import React from 'react';
import experiment from 'experiment';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import {
  Composites,
  Illustration,
  RichText,
  TextLabel,
  TextInput,
} from '@wix/wix-base-ui';
import * as BaseUI from '#packages/baseUI';
import { editorWixRecorder, SafeInjectHtml } from '#packages/util';
import { AddPresetApiKey } from '#packages/apis';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type {
  SendBiFunction,
  BiEventDefinition,
  BiEventFields,
} from 'types/bi';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
  Dispatch,
} from 'types/redux';

import {
  DISPLAY_NAME,
  AUTOMATION_IDS,
  NOTIFICATION_DEFAULT_KEYS,
  NOTIFICATION_PANEL_TIMEOUT,
  NOTIFICATION_SAVED_SECTION_KEYS,
} from './constants';
import { MessagePanelFrame, OldMessagePanelFrame } from '../../frames';

const {
  hoc: {
    connect,
    STORES: { EDITOR_API },
  },
} = util;
const { notifications, savedComponents, panels } = stateManagement;

interface IStateProps {
  defaultComponentName: string;
  shouldShowNotification: boolean;
  shouldOpenPanel: boolean;
  isSection: boolean;
}

interface IDispatchProps {
  save: (selectedComponents: CompRef[], compName: string) => Promise<void>;
  close: () => void;
  sendBI: SendBiFunction;
  onSaveSucceed: (
    shouldShowNotification: boolean,
    shouldOpenPanel: boolean,
    isSection: boolean,
  ) => void;
  openHelpCenter: (
    helpId: string,
    props?: object,
    biHelpParams?: {
      panel_name: string;
      origin: ValueOf<typeof constants.BI.HELP.ORIGIN>;
    },
  ) => void;
}

interface IOwnProps {
  selectedComponents: CompRef[];
  hasUnsupportedComponents: boolean;
  funnelId: string;
  panelName: string;
  origin: string;
  openPanelInteraction: ReturnType<typeof util.fedopsLogger.mapInteraction>;
}

interface IState {
  componentName: string;
  isValid: boolean;
  isPristine: boolean;
  isSubmitting: boolean;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

interface Content {
  panelTitle: string;
  illustrationName: string;
  inputLabel: string;
  inputPlaceholder: string;
  unsupportedComponentsWarningText: string;
  unsupportedComponentsCTA: string;
  helpId: string;
}

const content: {
  components: Content;
  sections: Content;
} = {
  components: {
    illustrationName: 'myDesignSave',
    panelTitle: 'MyDesigns_SaveDesign_Popup_Title',
    inputLabel: 'MyDesigns_SaveDesign_Popup_Name_Label',
    inputPlaceholder: 'MyDesigns_SaveDesign_Popup_Name_Placeholder',
    unsupportedComponentsWarningText:
      'MyDesigns_SaveDesign_Popup_Cannot_Save_Elements_Text',
    unsupportedComponentsCTA:
      'MyDesigns_SaveDesign_Popup_Cannot_Save_Elements_Link',
    helpId: helpIds.HELP_CENTER.SAVE_TO_MY_ELEMENTS_HELP_ITEM,
  },
  sections: {
    illustrationName: 'mySectionSave',
    panelTitle: 'add_section_saved_sections_save_modal_header',
    inputLabel: 'add_section_saved_sections_save_modal_text',
    inputPlaceholder: 'add_section_saved_sections_save_modal_placeholder',
    unsupportedComponentsWarningText:
      'add_section_saved_sections_save_modal_not_saved_warning',
    unsupportedComponentsCTA:
      'add_section_saved_sections_save_modal_not_saved_learn_more_cta',
    helpId: helpIds.addSectionPanel.SAVED_SECTIONS,
  },
};

class SaveToMyElementsPanel extends React.Component<IProps, IState> {
  static displayName = DISPLAY_NAME;

  static getDerivedStateFromProps(
    nextProps: Readonly<IProps>,
    prevState: IState,
  ) {
    if (prevState.componentName) {
      return prevState;
    }
    return {
      ...prevState,
      componentName: nextProps.defaultComponentName,
      isValid: true,
      isPristine: !nextProps.defaultComponentName,
    };
  }

  content: Content = this.props.isSection
    ? content.sections
    : content.components;

  state: IState = {
    componentName: '',
    isValid: false,
    isPristine: true,
    isSubmitting: false,
  };
  automationIds = AUTOMATION_IDS;

  componentDidMount() {
    editorWixRecorder.addLabel('saveToMyElementsPanel panel opened');
    this.props.sendBI(coreBi.events.panels.PANEL_OPENED);
    this.props?.openPanelInteraction?.end?.();
  }

  isDisabled() {
    return (
      this.state.isPristine || !this.state.isValid || this.state.isSubmitting
    );
  }

  validator = (value: string): boolean => {
    const isValid = this.validateContent(value);
    this.setState({ isValid });
    return isValid;
  };

  handleHelp = () => {
    const biHelpParams = {
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
    };

    this.props.openHelpCenter(this.content.helpId, null, biHelpParams);
  };

  handleComponentNameChange = (componentName: AnyFixMe) =>
    this.setState((state: IState) => ({
      ...state,
      componentName: componentName.trim(),
      isPristine: false,
    }));

  handleKeyDown = (e: React.KeyboardEvent) => {
    const ENTER_KEY = 13;
    if (e.keyCode === ENTER_KEY) {
      this.submit();
    }
  };

  submit = () => {
    if (!this.state.isValid || this.state.isSubmitting) {
      return;
    }
    this.setState({
      isSubmitting: true,
    });

    this.props.close();

    this.props
      .save(this.props.selectedComponents, this.state.componentName)
      .finally(this.handleSaved);

    // Optimistic UI
    this.handleSaveSucceed();
  };

  getShortcuts = () => ({
    esc: () => this.props.close(),
  });

  render() {
    const { panelName, close, hasUnsupportedComponents } = this.props;
    const { componentName } = this.state;
    const modalBody = (
      <RichText>
        <Composites.TextInputLabeled>
          <TextLabel value={this.content.inputLabel} />
          <TextInput
            automationId={this.automationIds.INPUT}
            value={componentName}
            // @ts-expect-error
            validateOnBlurOnly={true}
            onKeyDown={this.handleKeyDown}
            validateInitialValue={false}
            validator={this.validator}
            maxLength={255}
            autoSelect={true}
            placeholder={this.content.inputPlaceholder}
            invalidMessage="MyDesigns_RenameDesign_Popup_Rename_Error_Empty"
            onChange={this.handleComponentNameChange}
          />
        </Composites.TextInputLabeled>
        {hasUnsupportedComponents ? (
          <Composites.RichText key="unsupported-components-warning">
            <RichText>
              <span>
                {translate(this.content.unsupportedComponentsWarningText)}
              </span>
              <SafeInjectHtml tag="span" html="&nbsp;" />
              <a href="#" onClick={this.handleHelp}>
                {translate(this.content.unsupportedComponentsCTA)}
              </a>
            </RichText>
          </Composites.RichText>
        ) : null}
      </RichText>
    );

    return experiment.isOpen('se_removeOldMessagePanelFrame') ? (
      <MessagePanelFrame
        panelName={panelName}
        className="add-to-my-elements-panel"
        title={translate(this.content.panelTitle)}
        onConfirm={this.submit}
        confirmLabel={translate('MyDesigns_SaveDesign_Popup_Button_Save')}
        cancelLabel={translate('MyDesigns_SaveDesign_Popup_Button_Cancel')}
        onHelp={this.handleHelp}
        confirmBtnProps={{ disabled: this.isDisabled() }}
        illustration={<BaseUI.symbol name={this.content.illustrationName} />}
      >
        <div className="save-to-my-elements-content">{modalBody}</div>
      </MessagePanelFrame>
    ) : (
      <OldMessagePanelFrame
        panelName={panelName}
        className="add-to-my-elements-panel"
        panelTitle={translate(this.content.panelTitle)}
        close={close}
        keyboardShortcuts={this.getShortcuts()}
        onConfirm={this.submit}
        confirmLabel="MyDesigns_SaveDesign_Popup_Button_Save"
        cancelLabel="MyDesigns_SaveDesign_Popup_Button_Cancel"
        onHelp={this.handleHelp}
        shouldCloseOnConfirm={false}
        confirmDisabled={this.isDisabled()}
        shouldHideHeader={false}
      >
        <div className="save-to-my-elements-content">
          <Illustration>
            <BaseUI.symbol name={this.content.illustrationName} />
          </Illustration>
          {modalBody}
        </div>
      </OldMessagePanelFrame>
    );
  }

  private handleSaved = () => {
    this.setState({
      isSubmitting: false,
    });
  };

  private handleSaveSucceed = () => {
    const { shouldShowNotification, shouldOpenPanel, isSection } = this.props;

    this.props.onSaveSucceed(
      shouldShowNotification,
      shouldOpenPanel,
      isSection,
    );
  };

  private validateContent = (value: string): boolean => Boolean(value.trim());
}

const openAddPanel = () =>
  panels.actions.updateOrOpenPanel(
    constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
    {
      origin: 'saveToMyElementsPanel',
      category: addPanelDataConsts.CATEGORIES_ID.SAVED_COMPONENTS,
    },
  );

const showSuccessNotification = (
  onNotificationLinkClick: () => void,
  origin: string,
  keys: {
    title: string;
    message: string;
    linkCaption: string;
  },
) =>
  notifications.actions.showUserActionNotification({
    type: constants.NOTIFICATIONS.TYPES.INFO,
    title: keys.title,
    message: keys.message,
    link: {
      caption: keys.linkCaption,
      onNotificationLinkClick,
    },
    timeout: NOTIFICATION_PANEL_TIMEOUT,
    origin,
  });

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps> = (
  { state, editorAPI },
  ownProps,
) => {
  const addPresetAPI = editorAPI.host.getAPI(AddPresetApiKey);
  const firstSelectedComponent = ownProps.selectedComponents[0];
  const isSection = editorAPI.sections.isSection(firstSelectedComponent);
  const isSavedSection = util.sections.isSectionsEnabled() && isSection;

  const savedSections = isSavedSection
    ? addPresetAPI.sections.getSavedSections()
    : [];

  const isSavedSectionsEmpty = savedSections.length === 0;
  const defaultComponentName = isSavedSection
    ? addPresetAPI.sections.getSavedSectionDefaultName(
        editorAPI.sections.getName(firstSelectedComponent),
      )
    : savedComponents.selectors.getNextComponentDefaultName(state);

  return {
    isSection,
    shouldShowNotification: isSavedSection
      ? !isSavedSectionsEmpty
      : !savedComponents.selectors.getIsEmpty(state),
    shouldOpenPanel: isSavedSection
      ? isSavedSectionsEmpty
      : savedComponents.selectors.getIsEmpty(state),
    defaultComponentName,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IOwnProps> = (
  dispatch: Dispatch,
  props: AnyFixMe,
) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const isSection = editorAPI.sections.isSection(props.selectedComponents[0]);
  const isSavedSection = util.sections.isSectionsEnabled() && isSection;
  const loadSavedSections =
    editorAPI.host.getAPI(AddPresetApiKey).sections.loadSavedSections;
  const openSavedSectionsCategory = (origin: string) =>
    editorAPI.host.getAPI(AddPresetApiKey).sections.openSavedSectionsCategory({
      origin,
    });

  return {
    save: async (selectedComponents: CompRef[], name: string) => {
      const type = isSavedSection ? constants.COMP_TYPES.SECTION : 'my-design';
      await dispatch(
        savedComponents.actions.saveItem(selectedComponents, name, type, {
          funnelId: props.funnelId,
          fullSaved: !props.hasUnsupportedComponents,
          origin: props.origin,
        }),
      );

      if (isSavedSection) {
        loadSavedSections();
      }
    },

    close: () =>
      dispatch(panels.actions.closePanelByNameAction(props.panelName)),

    sendBI: (event: BiEventDefinition, params: BiEventFields) =>
      dispatch(
        stateManagement.bi.actions.event(
          event,
          Object.assign(
            {
              funnel_id: props.funnelId,
            },
            params,
          ),
        ),
      ),

    onSaveSucceed: (
      shouldShowNotification: boolean,
      shouldOpenPanel: boolean,
      isSection: boolean,
    ) => {
      if (shouldOpenPanel) {
        if (isSection) {
          openSavedSectionsCategory(props.origin);
        } else {
          dispatch(openAddPanel());
        }
      }

      if (shouldShowNotification) {
        dispatch(
          showSuccessNotification(
            isSection
              ? () => openSavedSectionsCategory('notification')
              : () => dispatch(openAddPanel()),
            props.origin,
            isSection
              ? NOTIFICATION_SAVED_SECTION_KEYS
              : NOTIFICATION_DEFAULT_KEYS,
          ),
        );
      }
    },

    openHelpCenter: (
      helpId: string,
      props?: AnyFixMe,
      biHelpParams?: AnyFixMe,
    ) => dispatch(panels.actions.openHelpCenter(helpId, props, biHelpParams)),
  };
};

const ConnectedComponent = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SaveToMyElementsPanel);

export default ConnectedComponent;

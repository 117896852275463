import * as coreBi from '#packages/coreBi';
import { fedopsLogger, editorWixRecorder, publishUtil } from '#packages/util';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { getSiteMenuAPI } from './siteMenuAPI';

import type { SendBiFunction } from 'types/bi';
import type { EditorVersionsInfo } from 'types/core';
import type { PublishInteractionStartedSource } from 'types/fedops/publishInteraction';
import type { IMenuBarListItem } from '../../types';
import type {
  SiteMenuAPIOwnProps,
  SiteMenuAPIStateProps,
  SiteMenuAPIDispatchProps,
} from './siteMenuAPI';
import type { MenuBarItemsDispatchProps } from '../menuStructure';
import experiment from 'experiment';

const { SITE_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

const viewSiteText = util.workspace.isNewWorkspaceEnabled()
  ? 'TOPBAR_SITE_VIEW_SITE_DESKTOP_TEXT'
  : 'TOPBAR_SITE_VIEW_SITE_PROMOTION_TEXT';

export interface SiteListItemsOwnProps extends SiteMenuAPIOwnProps {}
export interface SiteListItemsStateProps extends SiteMenuAPIStateProps {
  isWixStudioUser: boolean;
  isFirstSave: boolean;
  isDraftMode: boolean;
  userPermissions: string[];
  isUserOwner: boolean;
  hasSiteBeenADI: boolean;
  canSiteBeRestoredToADI: boolean;
  canUserPublish: boolean;
  isDeveloperModeEnabled: boolean;
  shouldSaveBeforePublish: boolean;
  isTemplate: boolean;
  isSavedInCurrentSession: boolean;
  isSitePublished: boolean;
  isMobileEditor: boolean;
  isPreviewMode: boolean;
  siteWasOnlyAutoPublished: boolean;
  isPremium: boolean;
  numberOfManualPublish: number;
  devMode: 'on' | 'off';
  clickId: string;
}
export interface SiteListItemsDispatchProps extends SiteMenuAPIDispatchProps {
  sendBi: SendBiFunction;
  getFeedbackNewCommentsCounter: () => '99+' | number;
  getEditorVersionsInfo: () => undefined | EditorVersionsInfo;
}

export interface SiteListItemsProps
  extends SiteListItemsOwnProps,
    SiteListItemsStateProps,
    SiteListItemsDispatchProps,
    MenuBarItemsDispatchProps {}

export const getSiteListItemsByProps = (
  props: SiteListItemsProps,
): IMenuBarListItem[] => {
  const siteMenuAPI = getSiteMenuAPI(props);
  const permissions: string[] = props.userPermissions || []; // Sometimes undefiend in tests

  const isSiteBranchesActive = util.wixCodeUtils.isSiteBranchesActive();

  const { isAllowed } = props;
  const isTestSiteExperimentIsOn = experiment.isOpen(
    'specs.wixCode.TestSiteEntryPoint',
  );

  return [
    {
      key: SITE_MENU_ITEMS.SAVE,
      automationId: 'list-item-save',
      leftTitle: 'TOPBAR_Site_Save',
      rightTitle: 'TOPBAR_SITE_SAVE_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_SAVE_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'siteSaveYourWork_NewWorkspace'
        : 'menubarSaveSitePromotion_v2',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.SAVE]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'save',
        });
      },
    },
    {
      key: SITE_MENU_ITEMS.PREVIEW,
      automationId: 'list-item-preview',
      leftTitle: 'TOPBAR_PREVIEW_TOOLTIP_TITLE',
      rightTitle: 'TOPBAR_SITE_PREVIEW_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_PREVIEW_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'sitePreviewYourSite_NewWorkspace'
        : 'menubarPreviewSitePromotion_v2',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.PREVIEW]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'preview',
        });
        editorWixRecorder.addLabel('preview click');
        props.sendBi(coreBi.events.preview.PREVIEW_CLICK, {
          origin: constants.BI.PREVIEW.ORIGIN.MENU_BAR,
        });
      },
    },
    {
      key: SITE_MENU_ITEMS.FEEDBACK,
      automationId: 'list-item-feedback',
      leftTitle: props.isWixStudioUser
        ? 'TOPBAR_GetClientFeedback_Label'
        : 'TOPBAR_GetFeedback_Label',
      rightTitle: isAllowed('top-bar_menu-site-feedback.interactive')
        ? 'TOPBAR_GetFeedback_Promotion_Title'
        : 'TOPBAR_GetFeedback_Promotion_Title_Disabled',
      description: isAllowed('top-bar_menu-site-feedback.interactive')
        ? 'TOPBAR_GetFeedback_Promotion_Text'
        : 'TOPBAR_GetFeedback_Promotion_Text_Disabled',
      symbolName: isNewWorkspace
        ? 'siteGetFeedback_NewWorkspace'
        : 'menubarfeedbackPromotion_v2',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.FEEDBACK]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'feedback',
        });
      },
      getCounter: () => props.getFeedbackNewCommentsCounter(),
      isDisabled: !isAllowed('top-bar_menu-site-feedback.interactive'),
      condition() {
        const editorVersionsInfo = props.getEditorVersionsInfo();
        const isLastSaveInOldEditor =
          editorVersionsInfo?.isEligibleForSwitch &&
          !props.isSavedInCurrentSession &&
          !props.isTemplate;
        return !isLastSaveInOldEditor && props.isUserOwner;
      },
    },
    {
      key: SITE_MENU_ITEMS.PUBLISH,
      automationId: 'list-item-publish',
      leftTitle: 'TOPBAR_Site_Publish',
      rightTitle: !props.canUserPublish
        ? 'TOPBAR_SITE_PUBLISH_PROMOTION_TITLE_DISABLED'
        : 'TOPBAR_SITE_PUBLISH_PROMOTION_TITLE',
      description: !props.canUserPublish
        ? 'TOPBAR_SITE_PUBLISH_PROMOTION_TEXT_DISABLED'
        : 'TOPBAR_SITE_PUBLISH_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'sitePublish_NewWorkspace'
        : 'menubarPublishSitePromotion_v2',
      isDisabled: !props.canUserPublish,
      onClick() {
        const sourceOfStart: PublishInteractionStartedSource =
          'topBar_siteMenu_publishItem';

        // NOTE: if "shouldSaveBeforePublish", instead of publish, there will be save and "save success panel".
        // Then user should publish from the "save success panel".
        // see site menuAPI - https://github.com/wix-private/santa-editor/blob/064453d878dc7af7d7f5eec868e8e1cb04a5497d/santa-editor/packages/topBar/src/main/menuBar/menuBarListItems/siteMenu/menuAPI.ts#L29-L33
        if (!props.shouldSaveBeforePublish) {
          // NOTE: https://github.com/wix-private/santa-editor/issues/37029
          fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
            paramsOverrides: {
              sourceOfStart,
            },
          });
        }

        siteMenuAPI[SITE_MENU_ITEMS.PUBLISH]({
          origin: 'topBar_siteMenu_publishItem',
          sourceOfStart,
        });

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'publish',
        });
        editorWixRecorder.addLabel('publish click');
        const currentViewMode = publishUtil.getPublishViewMode(
          props.isMobileEditor,
          props.isPreviewMode,
        );
        const numberOfManualPublish = props.numberOfManualPublish
          ? props.numberOfManualPublish + 1
          : 1;

        props.sendBi(coreBi.events.publish.PUBLISH_CLICK, {
          origin: 'menubar',
          viewModeSource: currentViewMode,
          is_first_manual_publish:
            props.siteWasOnlyAutoPublished || !props.isSitePublished,
          isPremium: props.isPremium,
          numberOfManualPublish,
          devMode: props.devMode,
          clickId: props.clickId,
        });
      },
    },
    {
      key: SITE_MENU_ITEMS.VIEW_SITE,
      automationId: 'list-item-view-site',
      leftTitle: util.workspace.isNewWorkspaceEnabled()
        ? 'TOPBAR_SITE_VIEW_SITE_DESKTOP'
        : 'TOPBAR_SITE_VIEWSITE',
      rightTitle: props.isSitePublished
        ? 'TOPBAR_SITE_VIEW_SITE_PROMOTION_TITLE'
        : 'TOPBAR_SITE_VIEW_SITE_PROMOTION_TITLE_DISABLED',
      description: props.isSitePublished
        ? viewSiteText
        : 'TOPBAR_SITE_VIEW_SITE_PROMOTION_TEXT_DISABLED',
      symbolName: isNewWorkspace
        ? 'siteViewPublished_NewWorkspace'
        : 'menubarViewPublishedPromotion_v2',
      isDisabled: !props.isSitePublished,
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.VIEW_SITE]();

        if (props.isSitePublished) {
          props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
            category: 'view_site',
          });
        }
      },
    },
    {
      key: SITE_MENU_ITEMS.VIEW_SITE_MOBILE,
      automationId: 'list-item-view-site-mobile',
      leftTitle: 'TOPBAR_SITE_VIEWSITE_MOBILE',
      rightTitle: props.isSitePublished
        ? 'TOPBAR_SITE_VIEW_SITE_MOBILE_TITLE'
        : 'TOPBAR_SITE_VIEW_SITE_MOBILE_TITLE_DISABLED',
      description: props.isSitePublished
        ? 'TOPBAR_SITE_VIEW_SITE_MOBILE_TEXT'
        : 'TOPBAR_SITE_VIEW_SITE_MOBILE_TEXT_DISABLED',
      symbolName: 'topBarSiteMenuViewSiteMobile',
      isDisabled: !props.isSitePublished,
      condition: () => {
        return util.workspace.isNewWorkspaceEnabled();
      },
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.VIEW_SITE_MOBILE]();
        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'view_site_on_mobile',
        });
      },
    },
    {
      isSeparator: true,
    },
    {
      key: SITE_MENU_ITEMS.CREATE_RELEASE_CANDIDATE,
      automationId: 'list-item-create-release-candidate',
      leftTitle: isTestSiteExperimentIsOn
        ? 'TOPBAR_SITE_DEPLOY_TEST_SITE'
        : 'TOPBAR_SITE_CREATE_TEST_SITE',
      rightTitle: isTestSiteExperimentIsOn
        ? 'TOPBAR_SITE_DEPLOY_TEST_SITE_PROMOTION_TITLE'
        : 'TOPBAR_SITE_CREATE_TEST_SITE_PROMOTION_TITLE',
      description: isTestSiteExperimentIsOn
        ? 'TOPBAR_SITE_DEPLOY_TEST_SITE_PROMOTION_TEXT'
        : 'TOPBAR_SITE_CREATE_TEST_SITE_PROMOTION_TEXT',
      symbolName: 'siteCreateTestSite',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.CREATE_RELEASE_CANDIDATE]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'Create RC',
          src: 38,
        });
        props.sendBi(coreBi.events.topbar.start_creating_rc_click, {
          origin: 'top_bar',
        });
      },
      condition() {
        const isPermitted = isAllowed(
          'top-bar_menu-site-create-release-candidate.visible',
        );
        if (experiment.isOpen('specs.wixCode.TestSiteEntryPoint')) {
          return isPermitted;
        }
        return props.canUserPublish && props.isSitePublished && isPermitted;
      },
    },
    {
      key: SITE_MENU_ITEMS.RELEASE_MANAGER,
      automationId: 'list-item-release-manager',
      leftTitle: 'TOPBAR_SITE_RELEASE_MANAGER',
      rightTitle: isAllowed('top-bar_menu-site-release-manager.interactive')
        ? 'TOPBAR_SITE_RELEASE_MANAGER_PROMOTION_TITLE'
        : 'TOPBAR_SITE_RELEASE_MANAGER_PROMOTION_TITLE_DISABLED',
      description: isAllowed('top-bar_menu-site-release-manager.interactive')
        ? 'TOPBAR_SITE_RELEASE_MANAGER_PROMOTION_TEXT'
        : 'TOPBAR_SITE_RELEASE_MANAGER_PROMOTION_TEXT_DISABLED',
      symbolName: isNewWorkspace
        ? 'siteReleaseManager_NewWorkspace'
        : 'menubarReleaseManagerPromotion_v2',
      isDisabled: !isAllowed('top-bar_menu-site-release-manager.interactive'),
      condition() {
        return (
          !props.isFirstSave && !props.isDraftMode && !isSiteBranchesActive
        );
      },
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.RELEASE_MANAGER]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'Release Manager',
        });
        props.sendBi(
          coreBi.events.publish.publish_rc_goto_release_manager_click,
          {
            origin: 'top bar',
          },
        );
      },
    },
    {
      key: SITE_MENU_ITEMS.SITE_BRANCHES,
      automationId: 'list-item-site-branches',
      leftTitle: 'TOPBAR_SITE_SITE_BRANCHES',
      rightTitle: 'TOPBAR_SITE_SITE_BRANCHES_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_SITE_BRANCHES_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'siteReleaseManager_NewWorkspace'
        : 'menubarReleaseManagerPromotion_v2',
      isDisabled: !isAllowed('top-bar_menu-site-branches.interactive'),
      condition() {
        return !props.isFirstSave && !props.isDraftMode && isSiteBranchesActive;
      },
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.SITE_BRANCHES]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'Site Branches',
        });
        props.sendBi(
          coreBi.events.publish.publish_rc_goto_release_manager_click,
          {
            origin: 'top bar',
          },
        );
      },
    },
    {
      key: SITE_MENU_ITEMS.REVISIONS,
      automationId: 'list-item-site-revisions',
      leftTitle: 'TOPBAR_Site_Site_Revisions',
      rightTitle: isAllowed('top-bar_menu-site-history.interactive')
        ? 'TOPBAR_SITE_HISTORY_PROMOTION_TITLE'
        : 'TOPBAR_SITE_HISTORY_PROMOTION_TITLE_DISABLED',
      description: isAllowed('top-bar_menu-site-history.interactive')
        ? 'TOPBAR_SITE_HISTORY_PROMOTION_TEXT'
        : 'TOPBAR_SITE_HISTORY_PROMOTION_TEXT_DISABLED',
      symbolName: isNewWorkspace
        ? 'siteSiteHistory_NewWorkspace'
        : 'menubarSiteHistoryPromotion_v2',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.REVISIONS]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'revisions',
        });
      },
      isDisabled: !isAllowed('top-bar_menu-site-history.interactive'),
    },
    {
      isSeparator: true,
    },
    {
      key: SITE_MENU_ITEMS.CREATE_NEW_SITE,
      automationId: 'list-item-site-create-new-site',
      leftTitle: 'TOPBAR_SITE_CREATE',
      rightTitle: 'TOPBAR_SITE_CREATE_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_CREATE_PROMOTION_TEXT',
      symbolName: 'menubarCreateNewSite',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.CREATE_NEW_SITE]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'createNewSite',
        });
      },
    },
    {
      key: SITE_MENU_ITEMS.DUPLICATE,
      automationId: 'list-item-duplicate',
      leftTitle: 'TOPBAR_Settings_Duplicate_Label',
      rightTitle:
        !permissions.includes('my-account.duplicate-site') ||
        !isAllowed('top-bar_menu-site-duplicate.interactive')
          ? 'TOPBAR_Settings_Duplicate_Promotion_Title_Disabled'
          : 'TOPBAR_Settings_Duplicate_Promotion_Title',
      description:
        !permissions.includes('my-account.duplicate-site') ||
        !isAllowed('top-bar_menu-site-duplicate.interactive')
          ? 'TOPBAR_Settings_Duplicate_Promotion_Text_Disabled'
          : 'TOPBAR_Settings_Duplicate_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'siteDuplicate_NewWorkspace'
        : 'menubarDuplicateSite',
      isDisabled:
        props.isDraftMode ||
        !permissions.includes('my-account.duplicate-site') ||
        !isAllowed('top-bar_menu-site-duplicate.interactive'),
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.DUPLICATE]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'duplicate',
        });
      },
    },
    {
      key: SITE_MENU_ITEMS.UNPUBLISH,
      automationId: 'list-item-unpublish',
      leftTitle: 'TOPBAR_SITE_UNPUBLISH',
      rightTitle: props.isSitePublished
        ? 'TOPBAR_SITE_UNPUBLISH_PROMOTION_TITLE'
        : 'TOPBAR_SITE_VIEW_SITE_PROMOTION_TITLE_DISABLED',
      description: props.isSitePublished
        ? 'TOPBAR_SITE_UNPUBLISH_PROMOTION_TEXT'
        : 'TOPBAR_SITE_VIEW_SITE_PROMOTION_TEXT_DISABLED',
      symbolName: 'topBarSiteMenuUnpublish',
      isDisabled: !props.isSitePublished || !props.canUserPublish,
      condition() {
        return Boolean(props.numberOfManualPublish);
      },
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.UNPUBLISH]();
        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'unpublish',
        });
      },
    },
    {
      key: SITE_MENU_ITEMS.TRANSFER,
      automationId: 'list-item-transfer',
      symbolName: isNewWorkspace
        ? 'siteTransfer_NewWorkspace'
        : 'menubarTransferSite',
      leftTitle: 'TOPBAR_Settings_TransferSite_Label',
      rightTitle:
        !permissions.includes('my-account.transfer-site') ||
        !isAllowed('top-bar_menu-site-transfer.interactive')
          ? 'TOPBAR_Settings_TransferSite_Promotion_Title_Disabled'
          : 'TOPBAR_Settings_TransferSite_Promotion_Title',
      description:
        !permissions.includes('my-account.transfer-site') ||
        !isAllowed('top-bar_menu-site-transfer.interactive')
          ? 'TOPBAR_Settings_TransferSite_Promotion_Text_Disabled'
          : 'TOPBAR_Settings_TransferSite_Promotion_Text',
      isDisabled:
        props.isDraftMode ||
        !permissions.includes('my-account.transfer-site') ||
        !isAllowed('top-bar_menu-site-transfer.interactive'),
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.TRANSFER]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'transfer',
        });
      },
    },
    {
      key: SITE_MENU_ITEMS.MOVE_TO_TRASH,
      automationId: 'list-item-site-move-to-trash',
      leftTitle: 'Topbar_Site_MoveToTrash_Title',
      symbolName: isNewWorkspace ? 'siteMoveToTrash_NewWorkspace' : 'trash',
      rightTitle:
        !permissions.includes('my-account.delete-site') ||
        !isAllowed('top-bar_menu-site-trash.interactive')
          ? 'Topbar_Site_MoveToTrash_Promotion_Title_Disabled'
          : 'Topbar_Site_MoveToTrash_Promotion_Title',
      description:
        !permissions.includes('my-account.delete-site') ||
        !isAllowed('top-bar_menu-site-trash.interactive')
          ? 'Topbar_Site_MoveToTrash_Promotion_Text_Disabled'
          : 'Topbar_Site_MoveToTrash_Promotion_Text',
      isDisabled:
        !permissions.includes('my-account.delete-site') ||
        !isAllowed('top-bar_menu-site-trash.interactive'),
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.MOVE_TO_TRASH]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'moveSiteToTrash',
        });
      },
      condition() {
        return !props.isFirstSave && !props.isDraftMode;
      },
    },
    {
      isSeparator: true,
    },
    {
      key: SITE_MENU_ITEMS.UPDATE_EDITOR,
      automationId: 'list-item-site-update-editor',
      leftTitle: 'TOPBAR_SITE_UPDATE',
      rightTitle: 'TOPBAR_SITE_UPDATE_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_UPDATE_PROMOTION_TEXT',
      symbolName: 'topBarSiteMenuUpdateEditor',
      condition() {
        return !util.sections.isSectionsEnabled();
      },
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.UPDATE_EDITOR]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'updateEditor',
        });
      },
    },
    {
      isSeparator: true,
      condition() {
        return !util.sections.isSectionsEnabled();
      },
    },
    {
      key: SITE_MENU_ITEMS.BACK_TO_ADI,
      automationId: 'list-item-site-back-to-adi',
      leftTitle: 'TOPBAR_BacktoADI_Label',
      rightTitle: 'TOPBAR_BacktoADI_Promotion_Title',
      description: 'TOPBAR_BacktoADI_Promotion_Text',
      symbolName: isNewWorkspace
        ? 'BackToADITopBar_NewWorkspace'
        : 'BackToADITopBar',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.BACK_TO_ADI]();
      },
      isDisabled: !isAllowed('top-bar_menu-site-back-to-adi.interactive'),
      condition() {
        return (
          props.hasSiteBeenADI &&
          props.canSiteBeRestoredToADI &&
          !util.sections.isSectionsEnabled()
        );
      },
    },
    {
      isSeparator: true,
      condition() {
        return (
          props.hasSiteBeenADI &&
          props.canSiteBeRestoredToADI &&
          !util.sections.isSectionsEnabled()
        );
      },
    },
    {
      key: SITE_MENU_ITEMS.SITE_DASHBOARD,
      automationId: 'list-item-site-dashboard',
      leftTitle: 'TOPBAR_SITE_DASHBOARD',
      rightTitle: 'TOPBAR_SITE_DASHBOARD',
      description: 'TOPBAR_SITE_DASHBOARD_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'settingsDashboard_NewWorkspace'
        : 'menubarMySitesPromotion_v2',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.SITE_DASHBOARD]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'SITE_DASHBOARD',
        });
      },
    },
    {
      isSeparator: true,
    },
    {
      key: SITE_MENU_ITEMS.EXIT,
      automationId: 'list-item-exit',
      leftTitle: 'TOPBAR_Site_Logout',
      rightTitle: 'TOPBAR_SITE_EXIT_PROMOTION_TITLE',
      description: 'TOPBAR_SITE_EXIT_PROMOTION_TEXT',
      symbolName: isNewWorkspace
        ? 'siteExit_NewWorkspace'
        : 'menubarExitEditorPromotion_v2',
      onClick() {
        siteMenuAPI[SITE_MENU_ITEMS.EXIT]();

        props.sendBi(coreBi.events.topbar.top_bar_SITE_menu_click, {
          category: 'logout',
        });
      },
    },
  ];
};

import React from 'react';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import * as helpIds from '#packages/helpIds';
import FloatingModeBar from '../floatingModeBar/floatingModeBar';
import type { MapStateToProps } from 'types/redux';

const { exitInteraction, playInteraction, stopPlayInteraction } =
  stateManagement.interactions.actions;
const {
  getInteractionTriggerRef,
  getVariantId,
  isInteractionPlaying,
  doesInteractionVariantHaveOverrides,
  interactionDuration,
} = stateManagement.interactions.selectors;

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

interface InteractionModeBarDispatchProps {
  exitMode(): void;
  triggerDisplayName: string;
  secondaryAction(): void;
  openHelpCenter(): void;
}

const InteractionModeBar: React.FC<
  InteractionModeBarDispatchProps & InteractionModeBarStateProps
> = ({
  exitMode,
  triggerDisplayName,
  secondaryAction,
  openHelpCenter,
  secondaryActionInProgress,
  secondaryActionDisabled,
  secondaryActionDuration,
}) => {
  const title = (
    <>
      {translate('interactions_topbar_mode_label', {
        component_display_name_key: triggerDisplayName,
      })}
    </>
  );
  return (
    <FloatingModeBar
      draggableExtentionSlot={
        stateManagement.draggableSlots.DRAGGABLE_SLOTS.INTERACTION_MODE_BAR
      }
      className="interaction-mode-bar"
      title={title}
      onExitMode={exitMode}
      secondaryAction={secondaryAction}
      exitModeLabelKey="interactions_topbar_mode_exit_button"
      secondaryActionContent={'interactions_topbar_play_button'}
      onOpenHelpCenter={openHelpCenter}
      secondaryActionProgressContent={'interactions_topbar_stop_button'}
      secondaryActionInProgress={secondaryActionInProgress}
      secondaryActionDisabled={secondaryActionDisabled}
      secondaryActionDuration={secondaryActionDuration}
    />
  );
};
const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;
const mapDispatchToProps = (
  dispatch: AnyFixMe,
): InteractionModeBarDispatchProps => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openHelpCenter: () =>
      editorAPI.panelManager.openHelpCenter(helpIds.INTERACTIONS.GFPP),
    exitMode: () => {
      const state = editorAPI.store.getState();
      const triggerRef = getInteractionTriggerRef(state);
      const compType = editorAPI.components.getType(triggerRef);
      dispatch(exitInteraction());
      editorAPI.bi.event(coreBi.events.interactions.exit_interaction_mode, {
        component_id: triggerRef.id,
        component_type: compType,
        interaction_id: getVariantId(state),
        origin: 'modeBar',
      });
    },
    triggerDisplayName: editorAPI.components.getDisplayName(
      getInteractionTriggerRef(editorAPI.store.getState()),
    ),
    secondaryAction: async () => {
      const state = editorAPI.store.getState();
      const triggerRef = getInteractionTriggerRef(state);
      const compType = editorAPI.components.getType(triggerRef);

      if (state.interactions.isPlaying) {
        await dispatch(stopPlayInteraction(triggerRef));
      } else {
        await dispatch(playInteraction(state.interactions.triggerRef));
        await editorAPI.waitForChangesAppliedAsync();
        editorAPI.bi.event(
          coreBi.events.interactions.interaction_go_to_preview,
          {
            component_id: triggerRef.id,
            component_type: compType,
            interaction_id: getVariantId(state),
          },
        );
        editorAPI.selection.selectComponentByCompRef(triggerRef);
      }
    },
  };
};

export interface InteractionModeBarStateProps {
  secondaryActionInProgress: boolean;
  secondaryActionDisabled: boolean;
  secondaryActionDuration: number;
}

export interface InteractionModeBarOwnProps {}

export const mapStateToProps: MapStateToProps<
  InteractionModeBarStateProps,
  InteractionModeBarOwnProps
> = ({ state, editorAPI }) => ({
  secondaryActionInProgress: isInteractionPlaying(state),
  secondaryActionDisabled: !doesInteractionVariantHaveOverrides(
    editorAPI,
    state,
  ),
  secondaryActionDuration: interactionDuration(state),
});

InteractionModeBar.displayName = 'InteractionModeBar';

const Connected = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(InteractionModeBar);

Connected.pure = InteractionModeBar;

export default Connected;

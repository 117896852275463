import React from 'react';
import { workspace } from '#packages/util';
import { TextLabel } from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';
import { UploadButton } from './uploadButton';
import { cx } from '#packages/util';
import styles from './noSearchResults.scss';

interface NoSearchResultsProps {
  shouldShowButton: boolean;
  onButtonClick?: () => void;
  buttonLabel: string;
}

export const NoSearchResults: React.FC<NoSearchResultsProps> = ({
  shouldShowButton,
  onButtonClick,
  buttonLabel,
}) => {
  return (
    <div
      key="noSearchResults"
      className={cx('no-search-results-wrapper', styles.noSearchResultsWrapper)}
    >
      <Symbols.symbol
        name={
          workspace.isNewWorkspaceEnabled()
            ? 'media-panel-no-search-results-illustration-new-workspace'
            : 'media-panel-no-search-results-illustration'
        }
      />
      <div className={styles.noSearchResultsTitle}>
        <TextLabel
          value="Media_Panel_Results_No_Results_Title"
          type="T09"
          enableEllipsis={false}
        />
      </div>
      <div className={styles.noSearchResultsText}>
        <TextLabel
          value="Media_Panel_Results_No_Results_Text"
          type="T02"
          enableEllipsis={false}
        />
      </div>
      {shouldShowButton ? (
        <UploadButton label={buttonLabel} onButtonClick={onButtonClick} />
      ) : null}
    </div>
  );
};

import _ from 'lodash';
import { WIX_BLOG } from '@wix/app-definition-ids';

import * as util from '#packages/util';
import * as blog from '#packages/blog';
import { utils as platformUtils } from '#packages/platform';

import * as tpaAddRemoveDSService from './tpaAddRemoveDSService';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, AppData, CompData } from 'types/documentServices';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import type { ErrorPanelResolveType } from '#packages/platformPanels';
import experiment from 'experiment';

const additionalOnDeleteCallback = {
  [WIX_BLOG]: (editorAPI: EditorAPI) => {
    const options = { newBlogDeletion: true };
    blog.registerNewBlogButton(editorAPI, options);
    if (blog.siteHasOldBlog(editorAPI)) {
      blog.registerBlogButton(editorAPI, options);
    }
  },
};

const deleteWidget = function (
  editorAPI: EditorAPI,
  compRef: CompRef,
  showMessage: boolean,
) {
  const compData = editorAPI.components.data.get(compRef);
  const isPremium = editorAPI.dsRead.tpa.app.isPremium(compData.applicationId);
  const appData = editorAPI.dsRead.tpa.app.getData(compData.applicationId);
  const hasSection = editorAPI.dsRead.tpa.app.hasSections(appData);

  return new Promise<void>((resolve) => {
    if (isPremium && !hasSection && showMessage) {
      editorAPI.panelManager.openPanel(
        'tpaPanels.confirmation.premiumTPADelete',
        {
          appName: appData.appDefinitionName,
          appDefId: appData.appDefinitionId,
          onConfirm() {
            onDeleteWidgetConfirm(editorAPI, compData, compRef, appData);
            resolve();
          },
        },
        true,
      );
    } else {
      tpaAddRemoveDSService.deleteWidget(editorAPI, compRef);
      resolve();
    }
  });
};

const onDeleteWidgetConfirm = function (
  editorAPI: EditorAPI,
  compData: CompData,
  compRef: CompRef,
  appData: AppData,
) {
  const isLastComp = editorAPI.dsRead.tpa.app.isLastAppComp(
    compData.applicationId,
  );
  tpaAddRemoveDSService.deleteWidget(editorAPI, compRef);

  const params = {
    app_id: appData.appDefinitionId,
    app_site_id: appData.instanceId,
    instance_id: compRef.id,
    is_last_tpa: isLastComp ? 1 : 0,
    component_type: 'tpa',
  };

  editorAPI.bi.event(util.bi.events.REMOVE_COMPONENT_SECOND_STEP_CLICK, params);
};

const actualDeleteSection = function (
  editorAPI: EditorAPI,
  pageId: string,
  appData: AppData,
  completeCallback?: () => void,
  biParams?: Object,
  options?: Object,
) {
  editorAPI.pages.beforePagesRemoveHook(
    [pageId],
    function () {
      tpaAddRemoveDSService.deleteSection(editorAPI, pageId, biParams);
      if (completeCallback) {
        completeCallback();
      }
    },
    options,
  );
};

const openDeletePageMessagePanel = function (
  editorAPI: EditorAPI,
  pageName: string,
  pageId: string,
  appData: AppData,
  completeCallback: () => void,
  tpasOnPageBiParams?: Object,
  options?: Object,
) {
  editorAPI.panelManager.openPanel(
    'panels.messagePanels.deletePageMessage',
    {
      pageTitle: pageName,
      onConfirm() {
        actualDeleteSection(
          editorAPI,
          pageId,
          appData,
          completeCallback,
          tpasOnPageBiParams,
          options,
        );
      },
    },
    true,
  );
};

const onConfirmDelete = function (
  editorAPI: EditorAPI,
  pageId: string,
  appData: AppData,
  completeCallback?: () => void,
  tpasOnPageBiParams?: Object,
) {
  actualDeleteSection(
    editorAPI,
    pageId,
    appData,
    completeCallback,
    tpasOnPageBiParams,
  );

  let secondStepBiParams = _.clone(tpasOnPageBiParams);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  secondStepBiParams = _.map(secondStepBiParams, function (tpaBiParams) {
    return _.pick(tpaBiParams, [
      'app_id',
      'app_site_id',
      'component_id',
      'component_type',
      'instance_id',
      'is_last_tpa',
    ]);
  });
  _.invoke(additionalOnDeleteCallback, [appData.appDefinitionId], editorAPI);
  editorAPI.bi.reportBI(
    util.bi.events.REMOVE_COMPONENT_SECOND_STEP_CLICK,
    secondStepBiParams,
  );
};

const getCustomSectionDeletePanelProps = function (
  appName: string,
  pageName: string,
  customContent: AnyFixMe,
  onConfirm: () => void,
) {
  return {
    headerText:
      customContent.title ??
      translate('Page_Settings_Delete_Page_and_App_Title'),
    shouldShowIllustration: true,
    symbol: 'deleteGeneralIcon_NewWorkspace',
    topDescriptionText:
      customContent.descriptionText ??
      translate('Page_Settings_Delete_Page_and_App_Text', {
        app_name: appName,
        page_name: pageName,
      }),
    mainActionText: translate(
      'Page_Settings_Delete_Page_and_App_Delete_Button',
    ),
    secondaryActionText: translate(
      'Page_Settings_Delete_Page_and_App_Cancel_Button',
    ),
    onPanelClose: async (panelResolve: ErrorPanelResolveType) => {
      if (panelResolve === constants.PANEL_RESOLVE_TYPE.MAIN_ACTION) {
        await onConfirm();
      }
    },
  };
};

const openSectionDeletePanel = async function (
  editorAPI: EditorAPI,
  appData: AppData,
  pageName: string,
  pageId: string,
  completeCallback?: () => void,
  tpasOnPageBiParams?: Object,
) {
  const onConfirm = () => {
    onConfirmDelete(
      editorAPI,
      pageId,
      appData,
      completeCallback,
      tpasOnPageBiParams,
    );
  };
  const appApi = await editorAPI.platform.getAppEditorApi(
    appData.appDefinitionId,
  );
  const customContent = await appApi?.getCustomDeletePanelContent({
    compId: pageId,
  });
  if (!_.isEmpty(customContent)) {
    const panelProps = getCustomSectionDeletePanelProps(
      appData.appDefinitionName,
      pageName,
      customContent,
      onConfirm,
    );
    editorAPI.panelManager.openPlatformPanel({
      panelName: 'platformPanels.platformErrorPanel',
      panelProps,
    });
  } else {
    await editorAPI.panelManager.openPanel(
      'tpaPanels.confirmation.sectionDelete',
      {
        appName: appData.appDefinitionName,
        pageName,
        appDefId: appData.appDefinitionId,
        onConfirm,
      },
      true,
    );
  }
};

const openSectionDeleteMultipleAppsPanel = function (
  editorAPI: EditorAPI,
  appData: AppData,
  pageName: string,
  pageId: string,
  completeCallback: () => void,
  tpasOnPageBiParams: Object,
  dependentAppsData: AnyFixMe,
) {
  editorAPI.panelManager.openPanel(
    'tpaPanels.confirmation.sectionDeleteMultipleApps',
    {
      appName: appData.appDefinitionName,
      pageName,
      appDefId: appData.appDefinitionId,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      dependentAppNames: _.map(dependentAppsData, 'appDefinitionName'),
      onConfirm() {
        onConfirmDelete(
          editorAPI,
          pageId,
          appData,
          completeCallback,
          tpasOnPageBiParams,
        );
      },
    },
    true,
  );
};

const deleteSection = async function (
  editorAPI: EditorAPI,
  pageId: string,
  pageName: string,
  appData: AppData,
  showMessage?: boolean,
  completeCallback?: () => void,
  tpasOnPageBiParams?: Object,
  options?: Object,
) {
  if (experiment.isOpen('se_useNewUninstallFlows')) {
    return editorAPI.platform.applications.uninstall(appData, showMessage, {
      onSuccess: completeCallback,
      origin: 'deleteSection',
    });
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(tpasOnPageBiParams, tpasOnPageBiParams);
  const isMultiSection = editorAPI.dsRead.tpa.app.isMultiSectionInstalled(
    // @ts-expect-error
    appData.applicationId,
  );
  const dependentAppsData =
    // @ts-expect-error
    editorAPI.dsRead.tpa.app.getInstalledDependentAppsData(
      appData.applicationId,
    );
  if (showMessage && !_.isEmpty(dependentAppsData)) {
    openSectionDeleteMultipleAppsPanel(
      editorAPI,
      appData,
      pageName,
      pageId,
      completeCallback,
      tpasOnPageBiParams,
      dependentAppsData,
    );
  } else if (showMessage && !isMultiSection) {
    await openSectionDeletePanel(
      editorAPI,
      appData,
      pageName,
      pageId,
      completeCallback,
      tpasOnPageBiParams,
    );
  } else if (showMessage) {
    openDeletePageMessagePanel(
      editorAPI,
      pageName,
      pageId,
      appData,
      completeCallback,
      tpasOnPageBiParams,
      options,
    );
  } else {
    actualDeleteSection(
      editorAPI,
      pageId,
      appData,
      completeCallback,
      tpasOnPageBiParams,
      options,
    );
  }
};
const displaySectionDeleteMessage = async function (
  editorAPI: EditorAPI,
  compRef: CompRef,
) {
  const compData = editorAPI.components.data.get(compRef);
  const appData = editorAPI.dsRead.tpa.app.getData(compData.applicationId);

  return platformUtils.displaySectionDeleteMessage(editorAPI, compRef, appData);
};

export { deleteWidget, deleteSection, displaySectionDeleteMessage };

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as symbols from '@wix/santa-editor-symbols';
import { TextLabel, InfoIcon } from '@wix/wix-base-ui';

import * as core from '#packages/core';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as helpIds from '#packages/helpIds';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

const SECTION_HEADER_TOOLTIP_MARGIN = 5;
const PANEL_NAME = 'panels.focusPanels.howToVideoPlayer';
const PLAYER_ORIGIN = 'ADD_PANEL';
const LEARN_MORE = 'add_section_label_description_learn_more';

function isShown(prop) {
  return this.props[prop] && !this.props[prop].hide;
}

function shouldRenderWithVideo(sectionKey) {
  const videoIdKey = util.keyGenerator.videoIdKey(sectionKey);
  return util.media.getVideoIfAvailable(sectionKey, videoIdKey);
}

function getVideoThumbnailIfAvailable(sectionKey) {
  return shouldRenderWithVideo(sectionKey)
    ? translate(util.keyGenerator.thumbnailKey(sectionKey))
    : null;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'sectionHeader',
  mixins: [core.mixins.editorAPIMixin],
  getSectionHeaderStyle() {
    let panelConst;
    if (this.props.parentType === addPanelDataConsts.PANEL_TYPES.DESIGN_PANEL) {
      panelConst = addPanelDataConsts.DESIGN_PANEL;
    } else {
      panelConst = addPanelDataConsts.ADD_PANEL;
    }
    return {
      width: panelConst.LIVE_COMPONENT_CATEGORY_CONTENT,
      minHeight: panelConst.SECTION_HEADER_HEIGHT,
    };
  },

  getInfoIconProps() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(
      {
        title: util.keyGenerator.titleKey(this.props.title) || this.props.title,
        text: util.keyGenerator.descriptionKey(this.props.title),
        small: false,
        shouldTranslate: this.shouldTranslate(),
        shouldCloseOnMouseClick: true,
        tooltipMarginLeft: this.getToolTipMargin(),
        tooltipMarginRight: this.getToolTipMargin(),
        customSymbol: this.getVideoSymbol(),
        alignment: constants.UI.TOOLTIP.ALIGNMENT.RIGHT,
        closeOnContentMouseClick: true,
      },
      helpIds.SECTIONS[this.props.title]
        ? {
            linkText: LEARN_MORE,
            onLinkClick: this.handleClickHelp,
          }
        : {},
      shouldRenderWithVideo(this.props.title)
        ? {
            illustration: this.getIllustration(),
            onIllustrationClick: this.handleClickPlay,
          }
        : {},
    );
  },
  isInfoShown() {
    return isShown.call(this, 'help');
  },
  getToolTipMargin() {
    return SECTION_HEADER_TOOLTIP_MARGIN;
  },
  shouldTranslate() {
    return this.props?.shouldTranslate ?? true;
  },
  getIllustration() {
    return React.createElement(
      'div',
      { className: 'info-icon-image-container' },
      [
        React.createElement('img', {
          src: getVideoThumbnailIfAvailable(this.props.title),
          className: 'info-icon-image',
          key: 'info-icon-image',
        }),
        React.createElement(symbols.symbol, {
          name: 'play-video',
          key: 'play-video',
        }),
      ],
    );
  },
  getVideoSymbol() {
    const symbolName = this.props.help?.symbolName;
    return symbolName
      ? React.createElement(symbols.symbol, {
          name: symbolName,
        })
      : null;
  },
  handleClickHelp() {
    const biParams = {
      component: util.keyGenerator.titleKey(this.props.title),
      learn_more: true,
      panel_name: PANEL_NAME,
      origin: constants.BI.HELP.ORIGIN.ADD_PANEL,
    };
    this.getEditorAPI().panelManager.openHelpCenter(
      helpIds.SECTIONS[this.props.title],
      null,
      biParams,
    );
  },
  handleClickPlay() {
    const editorAPI = this.getEditorAPI();
    const videoId = translate(util.keyGenerator.videoIdKey(this.props.title));
    const biParams = {
      tooltipname: util.keyGenerator.titleKey(this.props.title),
      videoID: videoId,
    };
    editorAPI.bi.event(coreBi.events.videoTooltip.THUMBNAIL_CLICK, biParams);
    editorAPI.panelManager.openPanel(
      PANEL_NAME,
      {
        videoId,
        origin: PLAYER_ORIGIN,
      },
      true,
    );
  },
  renderInfoIcon() {
    if (this.props.disableInfoIcon) {
      return null;
    }

    const key = `${this.props.id}infoIcon`;

    const { CustomInfoIcon } = this.props;
    if (CustomInfoIcon) {
      const { title, text } = this.getInfoIconProps();
      return (
        <div key={key} className="sub-category-info-icon">
          <CustomInfoIcon
            title={translate(title)}
            description={translate(text)}
          />
        </div>
      );
    }

    return (
      <InfoIcon
        key={key}
        className={util.cx({
          'sub-category-info-icon': true,
          'sub-category-add-panel-info-icon': true,
        })}
        {...this.getInfoIconProps()}
      />
    );
  },
  render() {
    return this.props.showSectionHeader ? (
      <div
        style={this.getSectionHeaderStyle()}
        key="header"
        className={`section-header ${this.props.type}`}
      >
        <div
          className={util.cx({
            'title-line': true,
            'title-line-sub-category': this.props.hasSubCategory,
          })}
        >
          <TextLabel
            value={
              this.props.hasSubCategory
                ? this.props.presetTitle
                : this.props.title
            }
            shouldTranslate={this.shouldTranslate()}
            ellipsisProps={{ disabled: true }}
            type={isNewWorkspace ? 'T07' : 'T18'}
            className={util.cx({
              title: true,
              'new-title-sub-category': this.props.hasSubCategory,
            })}
          />
          {this.renderInfoIcon()}
        </div>
      </div>
    ) : null;
  },
});

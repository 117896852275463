import { sections as sectionsUtils, fedopsLogger, bi } from '#packages/util';
import { bootstrapCorePlugins, bootstrapPlugins } from './plugins';
import { createBeforePublish } from './monitoring/beforePublish';
import { bootstrapEnforcement } from './enforcement';
import { bootsrapComponentsContribution } from '../components';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { sections, mouseActions, contextMenu } from '#packages/stateManagement';

import type { Scope } from '../scope';

const bootstrapInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.BOOTSTRAP,
);

export const bootstrap = async (scope: Scope) => {
  const { editorAPI, workspace, publishManager } = scope;

  bootstrapCorePlugins(scope);

  if (!sectionsUtils.isSectionsEnabled()) return;

  fedopsLogger.updateBiGlobalParams({
    is_sections_enabled: true,
  });

  bootstrapInteraction.start();

  bi.biLoggerUpdateDefaults({
    isSection: true,
  });

  ErrorReporter.setTags({
    sections: true,
  });

  window.document.documentElement.classList.add('sections-experience');

  const clearHoveredSectionLike = () => {
    editorAPI.store.dispatch(sections.actions.setHoveredSectionLike(null));
  };

  editorAPI.registerPageNavigationCallback(clearHoveredSectionLike);
  workspace.hooks.stageMouseLeave.tap(() => {
    const state = editorAPI.store.getState();
    const isPerformingMouseMoveAction =
      mouseActions.selectors.isPerformingMouseMoveAction(state);
    const isContextMenuOpen = contextMenu.selectors.isOpen(state);

    if (!isPerformingMouseMoveAction && !isContextMenuOpen) {
      clearHoveredSectionLike();
    }
  });

  scope.layoutConstraints.bootstrap();

  bootstrapPlugins(scope);

  editorAPI.documentMode.enablePageAutoGrowForDetachedComponents(false);

  const beforePublish = createBeforePublish(scope);

  publishManager.hooks.beforePublish.tap(beforePublish);

  bootstrapEnforcement(scope);

  editorAPI.tpa.section.defaults.isContainable.set(true);

  bootsrapComponentsContribution(scope);

  bootstrapInteraction.end();
};

import { mobile } from '#packages/stateManagement';
import { hoc, sections, workspace } from '#packages/util';
import { useEffect } from 'react';
import type { StateMapperArgs } from 'types/redux';
import './bodyStylesSetter.scss';

const mobileWizardSelectors = mobile.selectors.mobileWizard;

const isNewWorkspace = workspace.isNewWorkspaceEnabled();
const getBodyBackgroundColor = () => {
  const newWorkspaceColor = sections.isSectionsEnabled()
    ? '#eff0f3'
    : '#f7f8f8';
  return isNewWorkspace ? newWorkspaceColor : '#f0f3f5';
};

interface Props {
  isMobileEditor: boolean;
  isZoomMode: boolean;
  isMobileWizardEnabled: boolean;
  isSectionsEnabled: boolean;
}

function BodyStylesSetter(props: Props): null {
  const backgroundColorNeeded =
    !props.isMobileWizardEnabled &&
    (props.isSectionsEnabled || props.isMobileEditor || props.isZoomMode);

  useEffect(() => {
    document.body.style.setProperty(
      '--bodyBackgroundColor',
      backgroundColorNeeded ? getBodyBackgroundColor() : '',
    );
  }, [backgroundColorNeeded]);

  return null;
}

const mapStateToProps = ({ editorAPI, state }: StateMapperArgs) => {
  const isMobileEditor = editorAPI.isMobileEditor();
  const isZoomMode = editorAPI.zoomMode.isInZoomMode();
  const isMobileWizardEnabled =
    mobileWizardSelectors.getIsMobileWizardEnabled(state);
  const isSectionsEnabled = sections.isSectionsEnabled();
  return {
    isMobileEditor,
    isZoomMode,
    isMobileWizardEnabled,
    isSectionsEnabled,
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(BodyStylesSetter);

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { runInContext } from '../../../common/utils';
import { EditorType, InstallInitiator } from '@wix/platform-editor-sdk';
import { widgetPlugins } from './widgetPlugins';

function isApplicationInstalled(editorAPI, appData, token, options) {
  return new Promise(function (resolve, reject) {
    if (!options?.appDefinitionId) {
      reject(new Error('options must contain appDefinitionId'));
      return;
    }

    const isAppInstalled = editorAPI.dsRead.tpa.app.isInstalled(
      options.appDefinitionId,
    );
    resolve(isAppInstalled);
  });
}

function addApplication(editorAPI, appData, token, options) {
  return new Promise(function (resolve, reject) {
    if (!options?.appDefinitionId) {
      reject(new Error('options must contain appDefinitionId'));
      return;
    }

    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.INSTALL_APP,
    );

    if (
      options.appDefinitionId ===
      editorAPI.platform.editorApps.WIX_CODE.appDefId
    ) {
      editorAPI.wixCode.provision({
        onSuccess: resolve,
        onError: reject,
      });

      return;
    }

    options = {
      ...options,
      platformOrigin: {
        type: EditorType.Classic,
        initiator: InstallInitiator.App,
        info: {
          appDefinitionId: appData?.appDefinitionId,
          ...options.originInfo,
        },
      },
      biData: {
        ...options.biData,
        ...options.originInfo?.biData,
      },
    };

    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.tpa
        .addApplication(options.appDefinitionId, options)
        .then(function (data) {
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.INSTALL_APP,
          );

          resolve(data);
        })
        .catch(function (error) {
          ErrorReporter.captureException(error, {
            tags: { appInstallFailed: options.appDefinitionId },
            extra: { appData, options },
          });

          reject(error);
        }),
    );
  });
}

function addComponent(editorAPI, appData, token, options) {
  return new Promise(function (resolve, reject) {
    options = options || {};
    if (!options.componentType) {
      reject(new Error('options must contain componentType'));
      return;
    }

    if (!options.appDefinitionId) {
      options.appDefinitionId = appData.appDefinitionId;
    }

    if (options.componentType === 'WIDGET') {
      if (!_.isObject(options.widget)) {
        reject(new Error('options must contain a widget object'));
        return;
      }
      options.widget.tpaWidgetId = options.widget.widgetId;
    } else if (options.componentType === 'PAGE') {
      if (!_.isObject(options.page)) {
        reject(new Error('options must contain a page object'));
        return;
      }
      if (options.page.isHidden && !_.isBoolean(options.page.isHidden)) {
        reject(new Error('isHidden param should be of type boolean'));
        return;
      }
    }

    runInContext(appData.appDefinitionId, editorAPI, () =>
      editorAPI.tpa
        .addComponent(options)
        .then(function (data) {
          resolve(data);
        })
        .catch(function (error) {
          reject(error);
        }),
    );
  });
}

function isAppSectionInstalled(editorAPI, appData, token, options) {
  return new Promise(function (resolve, reject) {
    if (!options?.sectionId) {
      reject(new Error('options must contain sectionId'));
      return;
    }

    let { applicationId } = appData;
    if (options.appDefinitionId) {
      const otherAppData = editorAPI.dsRead.tpa.app.getDataByAppDefId(
        options.appDefinitionId,
      );
      applicationId = otherAppData?.applicationId;
    }

    const isSectionInstalled =
      editorAPI.dsRead.tpa.section.isSectionInstalledByTpaPageId(
        applicationId,
        options.sectionId,
      );
    resolve(isSectionInstalled);
  });
}
const add = {
  application: addApplication,
  component: addComponent,
};

export { add, isApplicationInstalled, isAppSectionInstalled, widgetPlugins };

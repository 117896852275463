import React, { useEffect, useRef } from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { PromotionalList, TextLabel, Text } from '@wix/wix-base-ui';
import * as util from '#packages/util';

import styles from './promotionalPanel.scss';

type ThemeName = 'default' | 'premium';

const DefaultListItemsSymbol = React.createElement(symbols.symbol, {
  name: 'check',
});

interface PromotionalPanelProps {
  automationId?: string;
  className?: string;
  themeName?: ThemeName;
  title?: string;
  subtitle?: string;
  symbol?: React.ReactNode;
  listItems: string[];
  listItemsSymbol?: React.ReactNode;
  renderFooter?: () => React.ReactNode;
  renderList?: () => React.ReactNode;
  setListHeight?: (height: number) => void;
}

const PromotionalPanel: React.FunctionComponent<PromotionalPanelProps> = (
  props,
) => {
  const listRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    props.setListHeight?.(listRef.current?.offsetHeight || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const className = util.cx(
    styles.panel,
    props.className,
    styles[props.themeName],
  );
  const list = props.renderList ? (
    props.renderList()
  ) : (
    <PromotionalList
      items={props.listItems}
      symbol={props.listItemsSymbol}
      shouldTranslate={false}
    />
  );

  const title = (
    <Text
      skin="secondary"
      size="large"
      weight="bold"
      enableEllipsis={false}
      shouldTranslate={false}
    >
      {props.title}
    </Text>
  );

  return (
    <div data-aid={props.automationId} className={className}>
      <div className={styles.content}>
        <div className={styles.textContainer}>
          {props.title ? title : null}
          {props.subtitle ? (
            <TextLabel
              value={props.subtitle}
              enableEllipsis={false}
              shouldTranslate={false}
              type="T07"
              className={styles.subtitle}
            />
          ) : null}
          <div ref={listRef} className={styles.listContainer}>
            {list}
          </div>
        </div>
        {props.symbol ? (
          <div className={styles.symbolContainer}>{props.symbol}</div>
        ) : null}
      </div>
      {props.renderFooter ? (
        <div className={styles.footer}>{props.renderFooter()}</div>
      ) : null}
    </div>
  );
};

PromotionalPanel.displayName = 'PromotionalPanel';
PromotionalPanel.defaultProps = {
  listItemsSymbol: DefaultListItemsSymbol,
  themeName: 'default',
};

export default PromotionalPanel;

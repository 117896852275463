// @ts-nocheck
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Button,
  Composites,
  CustomScroll,
  Divider,
  Illustration,
  InfoIcon,
  RadioButtons,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../utils/constants';
import { LOGIN_FIRST_OPTIONS } from '../../../utils/customSignup';
import { AUTH_METHODS } from './authSettingsDesktopMapper';
import OpenSignupSettingsDashboardButton from './OpenSignupSettingsDashboardButton';

const SignupSettingsCustomDesktop = (props) => {
  const editFormLinkTitleKey =
    props.authMethod === AUTH_METHODS.SIGN_IN
      ? 'NewPages_Member_SignIn_Custom_SignIn_Edit_Full_Text'
      : 'NewPages_Member_Signup_Custom_Signup_Edit_Full_Text';

  return (
    <div className="site-members-settings-pages-panel">
      {props.isCustomSignupExists() ? (
        <div key="customSignupSettings" className="custom-signup">
          <Composites.ButtonLeft>
            <Button
              onClick={props.focusOnRegistrationForm}
              className="btn-text"
            >
              <span>
                <symbols.symbol name="editAction" />
              </span>
              <span>{translate(editFormLinkTitleKey)}</span>
            </Button>
          </Composites.ButtonLeft>
          <Divider long={true} />

          <div className="page-settings-panel-pp-content settings settings-margin">
            <CustomScroll heightRelativeToParent="100%">
              <Composites.RadioButtonsLabeled className="settings-section">
                <InfoIcon
                  shouldTranslate={true}
                  text="NewPages_Panel_Signup_Login_First_Info_Tooltip"
                />
                <TextLabel value="Pages_Member_Signup_Display_Settings_Dropdown_Label" />
                <RadioButtons
                  value={props.loginDialogFirst.toString()}
                  options={props.getLoginFirstOptions()}
                  onChange={(value) =>
                    props.toggleLoginDialogFirst(
                      value === LOGIN_FIRST_OPTIONS[1].value,
                    )
                  }
                />
              </Composites.RadioButtonsLabeled>
              <Divider long={true} />
              <div className="settings-button-container">
                <OpenSignupSettingsDashboardButton
                  onClick={props.openDashboard}
                />
              </div>
            </CustomScroll>

            {props.isSiteSaved() ? (
              <Composites.RichText
                key="manage-site-members-container"
                className="actions"
              >
                <RichText className="light">
                  {translate(
                    'NewPages_Member_Signup_Default_Top_Members_Area_Notice',
                  )}
                  <a
                    target="_blank"
                    href={props.getSiteMembersUrl()}
                  >{` ${translate(
                    'NewPages_Member_Signup_Default_Top_Members_Area_Notice_Link',
                  )}`}</a>
                </RichText>
              </Composites.RichText>
            ) : null}
          </div>
        </div>
      ) : null}

      {!props.isCustomSignupExists() ? (
        <div key="customSignupSplash" className="first-time-custom-signup">
          <Composites.RichTextWithIllustrationVertical className="first-form-splash-container">
            <Illustration>
              <symbols.symbol name="customSignupEmptyState" />
            </Illustration>
            <TextLabel
              type="T16"
              enableEllipsis={false}
              value={props.translationKeys.bannerTitle}
              shouldTranslate={true}
              className="text-label-centered"
            />
            <RichText type="T05" className="paragraph-centered">
              {translate(props.translationKeys.bannerText)}
            </RichText>
          </Composites.RichTextWithIllustrationVertical>

          <Button
            onClick={props.createCustomSignup}
            className="add-button btn-confirm-primary"
          >
            <span>
              {translate('NewPages_Member_Signup_Custom_Signup_Banner_Button')}
            </span>
          </Button>
          <RichText className="learn-more-text-container">
            <a
              onClick={() => {
                props.openHelpPanel(
                  constants.FIND_OUT_MORE_HELP_ID[props.authMethod],
                );
              }}
            >
              {translate(
                'NewPages_Member_Signup_Custom_Signup_Banner_Find_Out_More',
              )}
            </a>
          </RichText>
        </div>
      ) : null}
    </div>
  );
};

SignupSettingsCustomDesktop.propTypes = {
  isCustomSignupExists: PropTypes.func.isRequired,
  focusOnRegistrationForm: PropTypes.func.isRequired,
  getAutoApprovalOptions: PropTypes.func.isRequired,
  toggleAutoApprove: PropTypes.func.isRequired,
  getLoginFirstOptions: PropTypes.func.isRequired,
  toggleLoginDialogFirst: PropTypes.func.isRequired,
  createCustomSignup: PropTypes.func.isRequired,
  autoApproval: PropTypes.bool.isRequired,
  openHelpPanel: PropTypes.func.isRequired,
  loginDialogFirst: PropTypes.bool.isRequired,
  authMethod: PropTypes.string.isRequired,
  openDashboard: PropTypes.func.isRequired,
};

export default SignupSettingsCustomDesktop;

import { createReduxStore } from '#packages/apilib';
import type { KitDefinition } from '@wix/editor-kits';
import type { LayoutFamilyDefinition } from '@wix/editor-site-generator';
import type { AnimationKitDefinition } from '#packages/animations';

import type {
  PageLabel,
  AddPageCategory,
  SectionCategoryDefinition,
  SectionPresetDefinition,
} from './types';

export interface SectionsStore {
  selectedCategory: number;
  categories: SectionCategoryDefinition[];
  aiCategories: SectionCategoryDefinition[];
  panelOpenCount: number;
  savedSections: SectionPresetDefinition[];
}

export interface PagesStore {
  labels: PageLabel[];
  selectedCategoryId: string;
  categories: AddPageCategory[];
}

interface AddPresetState {
  pages: PagesStore;
  sections: SectionsStore;
  kitDefinitions: KitDefinition[];
  animationKits: AnimationKitDefinition[];
  layoutFamilies: LayoutFamilyDefinition[];
}

const getInitialState = (): AddPresetState => ({
  layoutFamilies: [],
  kitDefinitions: [],
  animationKits: [],
  sections: {
    categories: [],
    aiCategories: [],
    panelOpenCount: 0,
    selectedCategory: null,
    savedSections: [],
  },
  pages: {
    labels: [],
    categories: [],
    selectedCategoryId: '',
  },
});

export const AddPresetStore = createReduxStore({
  getInitialState,
  selectors: {
    getSectionsSelectedCategory: ({ sections }: AddPresetState): number =>
      sections.selectedCategory,
    getSectionsCategories: ({
      sections,
    }: AddPresetState): SectionCategoryDefinition[] => sections.categories,
    getSectionsAiCategories: ({
      sections,
    }: AddPresetState): SectionCategoryDefinition[] => sections.aiCategories,
    getPagesSelectedCategoryId: ({ pages }: AddPresetState): string =>
      pages.selectedCategoryId,
    getPagesCategories: ({ pages }: AddPresetState): AddPageCategory[] =>
      pages.categories,
    getPagesLabels: ({ pages }: AddPresetState): PageLabel[] => pages.labels,
    getSectionPanelOpenCount: ({ sections }: AddPresetState): number =>
      sections.panelOpenCount,
    getSavedSections: ({ sections }: AddPresetState) => sections.savedSections,
    getLayoutFamilies: ({ layoutFamilies }: AddPresetState) => layoutFamilies,
    getKitDefinitions: ({ kitDefinitions }: AddPresetState) => kitDefinitions,
    getAnimationKits: ({ animationKits }: AddPresetState) => animationKits,
  },
  actions: {
    setSectionsSelectedCategory: (
      state: AddPresetState,
      selectedCategory: number,
    ) => ({ ...state, sections: { ...state.sections, selectedCategory } }),
    setSectionsCategories: (
      state: AddPresetState,
      categories: SectionCategoryDefinition[],
      aiCategories?: SectionCategoryDefinition[],
    ) => ({
      ...state,
      sections: { ...state.sections, categories, aiCategories },
    }),
    setLayoutFamilies: (
      state: AddPresetState,
      layoutFamilies: LayoutFamilyDefinition[],
    ) => ({ ...state, layoutFamilies }),
    setKitDefinitions: (
      state: AddPresetState,
      kitDefinitions: KitDefinition[],
    ) => ({ ...state, kitDefinitions }),
    setAnimationKits: (
      state: AddPresetState,
      animationKits: AnimationKitDefinition[],
    ) => ({ ...state, animationKits }),
    updateSectionPanelOpenCount: (state: AddPresetState) => ({
      ...state,
      sections: {
        ...state.sections,
        panelOpenCount: state.sections.panelOpenCount + 1,
      },
    }),
    setPagesSelectedCategoryId: (
      state: AddPresetState,
      selectedCategoryId: string,
    ) => ({ ...state, pages: { ...state.pages, selectedCategoryId } }),
    setPagesLabels: (state: AddPresetState, labels: PageLabel[]) => ({
      ...state,
      pages: { ...state.pages, labels },
    }),
    setPagesCategories: (
      state: AddPresetState,
      categories: AddPageCategory[],
    ) => ({
      ...state,
      pages: {
        ...state.pages,
        categories,
        selectedCategoryId: categories[0]?.id,
      },
    }),
    setCategoryByUniquePagesUri: (
      state: AddPresetState,
      uniquePagesUri: string,
    ) => {
      const categoryIndex = state.pages.categories.findIndex(
        ({ uniquePagesUriSeo }) => uniquePagesUriSeo === uniquePagesUri,
      );
      const selectedCategoryId =
        categoryIndex >= 0
          ? state.pages.categories[categoryIndex].id
          : state.pages.selectedCategoryId;
      return { ...state, pages: { ...state.pages, selectedCategoryId } };
    },
    setSavedSections: (
      state: AddPresetState,
      savedSections: SectionPresetDefinition[],
    ) => ({ ...state, sections: { ...state.sections, savedSections } }),
    removeSavedSection: (state, sectionIdToRemove: string) => {
      return {
        ...state,
        sections: {
          ...state.sections,
          savedSections: state.sections.savedSections.filter(
            (section) => section._id !== sectionIdToRemove,
          ),
        },
      };
    },
    renameSavedSection: (state, id: string, name: string) => {
      return {
        ...state,
        sections: {
          ...state.sections,
          savedSections: state.sections.savedSections.map((section) =>
            section._id === id
              ? {
                  ...section,
                  sectionTitleKey: name,
                }
              : section,
          ),
        },
      };
    },
  },
});

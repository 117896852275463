import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

import type {
  ThunkAction,
  MapStateToProps,
  MapDispatchToProps,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  MobileFrameOwnProps,
  MobileFrameDispatchProps,
  MobileFrameStateProps,
} from './mobileFrame';

const { getPreviewMode } = stateManagement.preview.selectors;

const mapStateToProps: MapStateToProps<
  MobileFrameStateProps,
  MobileFrameOwnProps
> = ({ state: editorState, editorAPI }) => {
  const { siteScale } = editorState;

  const previewMode = getPreviewMode(editorState);
  const siteX = editorAPI.dsRead && editorAPI.site.getXPos();
  const shouldHideShadow =
    editorAPI.zoomMode.isZoomModeTransitionActive() ||
    util.fixedStage.isFixedStageEnabled();

  return {
    siteScale,
    previewMode,
    siteX,
    shouldHideShadow,
  };
};

const getEditorAPI: ThunkAction = (dispatch, getState, { editorAPI }) => ({
  editorAPI,
  state: getState(),
});

const mapDispatchToProps: MapDispatchToProps<
  MobileFrameDispatchProps,
  MobileFrameOwnProps
> = (dispatch) => {
  const { editorAPI }: { editorAPI: EditorAPI } = dispatch(getEditorAPI);

  return {
    sendBI: (event, params?) =>
      dispatch(stateManagement.bi.actions.event(event, params)),
    refreshMobileFramePosition: (rect) =>
      editorAPI.refreshMobileFramePosition(rect),
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
);

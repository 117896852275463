import React, { useCallback, useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import {
  CustomScroll,
  Checkbox,
  SectionDivider,
  TextLabel,
  TextButton,
} from '@wix/wix-base-ui';
import * as BaseUI from '#packages/baseUI';
import { cx, isAdvancedMenuOpen } from '#packages/util';
import { ToolPanelFrame } from '../../frames';
import { Add } from '@wix/wix-ui-icons-common/classic-editor';

import {
  AUTOMATION_IDS,
  MULTISELECT_PANEL_NAME,
} from './multiselect.constants';
import { multiselectConnect } from './multiselect.mapper';
import type {
  IMultiselectPanelOwnProps,
  IMultiselectPanelItem,
} from './multiselect.types';

interface IMultiselectPanelDispatchedProps {
  closePanel(): void;
  addPage(): void;
}

type IMultiselectPanelProps = IMultiselectPanelOwnProps &
  IMultiselectPanelDispatchedProps;

const checkItemsReducer = (
  acc: { [id: string]: boolean },
  item: IMultiselectPanelItem,
) => ({ ...acc, [item.id]: true });

const SECTION_DIVIDER_CLASSNAME = 'multiselect-panel__section-name';
const MAIN_SECTION_CLASSNAME = cx(
  SECTION_DIVIDER_CLASSNAME,
  'multiselect-panel__section-name--main',
);

export const MultiselectPanelComponent = ({
  items: sections,
  top,
  left,
  header,
  submitLabel,
  helpId,
  selectAllLabel,
  onSubmit,
  onClose,
  onSelectAll,
  onSelectItem,
  closePanel,
  onMount,
  addPage,
}: IMultiselectPanelProps) => {
  const checkableItems: IMultiselectPanelItem[] = useMemo(() => {
    return sections.reduce(
      (acc, section) => [
        ...acc,
        // eslint-disable-next-line lodash/prop-shorthand
        ..._.flatMap(section.groups, (group) => group.items),
      ],
      [],
    );
  }, [sections]);

  const checkableItemsCount = useMemo(
    () => new Set(checkableItems.map((item) => item.id)).size,
    [checkableItems],
  );

  const initialCheckedItems = useMemo(
    () => checkableItems.filter((item) => item.checked),
    [checkableItems],
  );

  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>(
    {},
  );

  useEffect(
    () => {
      setCheckedItems(initialCheckedItems.reduce(checkItemsReducer, {}));
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sections],
  );

  useEffect(
    () => {
      onMount?.();
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const numberOfCheckedItems = useMemo(
    () => Object.values(checkedItems).filter(Boolean).length,
    [checkedItems],
  );

  const checkAllIndeterminate =
    numberOfCheckedItems > 0 && numberOfCheckedItems < checkableItemsCount;

  const checkAllValue = numberOfCheckedItems === checkableItemsCount;

  const handleCheckAllClick = useCallback(
    () => {
      onSelectAll?.(!checkAllValue);

      if (checkAllValue) {
        setCheckedItems({});
        return;
      }

      setCheckedItems(checkableItems.reduce(checkItemsReducer, {}));
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkAllValue],
  );

  const handleCheck = useCallback(
    (id: string, value: boolean, sectionId: string) => {
      const newCheckedItems = {
        ...checkedItems,
        [id]: value,
      };

      if (onSelectItem) {
        onSelectItem(id, value, sectionId);
      }

      setCheckedItems(newCheckedItems);
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checkedItems],
  );

  const onMainActionClicked = () => {
    onSubmit({
      selectedIds: Object.entries(checkedItems)
        .filter(([_, value]) => value)
        .map(([id]) => id),
    });
    closePanel();
  };

  return (
    <ToolPanelFrame
      panelName={MULTISELECT_PANEL_NAME}
      helpId={helpId}
      headerTitle={header}
      contentWrapperClass="multiselect-panel"
      lightHeader
      contentStyle={{
        top,
        left,
      }}
      onClose={onClose}
      automationId={AUTOMATION_IDS.PANEL}
      withHeaderStripe={false}
      withHeaderDivider
    >
      <div className="multiselect-panel__wrapper">
        <CustomScroll heightRelativeToParent="100%">
          <div className="multiselect-panel__content">
            <Checkbox
              className="multiselect-panel__checkbox"
              label={selectAllLabel}
              labelAfterSymbol={true}
              indeterminate={checkAllIndeterminate}
              value={checkAllValue}
              onChange={handleCheckAllClick}
              shouldTranslate={false}
              automationId={AUTOMATION_IDS.SELECT_ALL}
            />
            {sections.map((section) => {
              return (
                <div
                  key={section.id}
                  className="multiselect-panel__section"
                  data-aid={AUTOMATION_IDS.getSectionAid(section.automationId)}
                >
                  <SectionDivider
                    className={
                      section.id === 'main'
                        ? MAIN_SECTION_CLASSNAME
                        : SECTION_DIVIDER_CLASSNAME
                    }
                    automationId={AUTOMATION_IDS.SECTION_LABEL}
                  >
                    {section.label}
                    {section.id === 'main' && isAdvancedMenuOpen() && (
                      <TextButton
                        prefixIcon={
                          <Add className="multiselect-panel__add-icon" />
                        }
                        onClick={addPage}
                        size="small"
                        className="multiselect-panel__add-button"
                      >
                        {'custom_menu_manage_menu_new_page_cta'}
                      </TextButton>
                    )}
                  </SectionDivider>
                  {section.groups.map((group) => (
                    <div
                      className="multiselect-panel__folder"
                      data-aid={AUTOMATION_IDS.GROUP}
                    >
                      {group.label && (
                        <TextLabel
                          className="multiselect-panel__folder-name"
                          type="T02"
                          shouldTranslate={false}
                          value={group.label}
                          automationId={AUTOMATION_IDS.GROUP_LABEL}
                        />
                      )}
                      {group.items.map((item) => (
                        <Checkbox
                          key={item.id}
                          className="multiselect-panel__checkbox"
                          shouldTranslate={false}
                          label={item.label}
                          labelAfterSymbol={true}
                          value={Boolean(checkedItems[item.id])}
                          onChange={(value: boolean) =>
                            handleCheck(item.id, value, section.id)
                          }
                          automationId={AUTOMATION_IDS.ITEM}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
          <div className="button-wrapper">
            <BaseUI.button
              label={submitLabel}
              onClick={onMainActionClicked}
              className="mainButton"
              shouldTranslate={false}
              automationId={AUTOMATION_IDS.SUBMIT_BUTTON}
            />
          </div>
        </CustomScroll>
      </div>
    </ToolPanelFrame>
  );
};

export const MultiselectPanel = multiselectConnect(MultiselectPanelComponent);

import _ from 'lodash';
import createReactClass from 'create-react-class';
import experiment from 'experiment';
import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import React from 'react';
import { MessagePanelFrame, OldMessagePanelFrame } from '../../frames';
import * as baseUI from '#packages/baseUI';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';
import constants from '#packages/constants';

const MLBI = coreBi.events.multilingual;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'changeStyleMessage',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return experiment.isOpen('se_removeOldMessagePanelFrame') ? (
      <MessagePanelFrame
        panelName={this.props.panelName}
        confirmLabel={translate('Notification_Box_Got_It')}
        dontShowAgainKey={
          constants.USER_PREFS.MULTILINGUAL.STYLING_COMPONENT.DONT_SHOW_AGAIN
        }
        userPrefType="site"
        title={translate('Notification_Box_Multilingual_Design_Change_Title')}
        onToggleDontShowAgain={this.onDontShowAgainChange}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        className="change-style"
        illustration={<baseUI.symbol name="plaster" />}
      >
        <RichText type="T01">
          {translate('Notification_Box_Multilingual_Design_Change_Text')}
        </RichText>
      </MessagePanelFrame>
    ) : (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        confirmLabel="Notification_Box_Got_It"
        dontShowAgainKey={
          constants.USER_PREFS.MULTILINGUAL.STYLING_COMPONENT.DONT_SHOW_AGAIN
        }
        userPrefType="site"
        panelTitle={translate(
          'Notification_Box_Multilingual_Design_Change_Title',
        )}
        onDontShowAgainChange={this.onDontShowAgainChange}
        onConfirm={this.onConfirm}
        onCancel={this.onCancel}
        className="change-style"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="plaster" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">
              {translate('Notification_Box_Multilingual_Design_Change_Text')}
            </RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  },

  sendBI(event: string, parameters: object) {
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(
      event,
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        {
          esi: util.editorModel.editorSessionId,
          site_id: editorAPI.dsRead.generalInfo.getSiteId(),
        },
        parameters,
      ),
    );
  },

  onDontShowAgainChange(dontShowAgain: boolean) {
    this.sendBI(MLBI.styling_dont_show, { toggle_state: dontShowAgain });
  },
});

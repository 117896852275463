// @ts-nocheck
import _ from 'lodash';
import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as platformEvents from 'platformEvents';
import * as util from '#packages/util';
import {
  layoutUtils,
  updateLayoutUtil,
  layoutTransitionsUtil,
} from '#packages/layoutUtils';
import { hasPinDockingSizeRestrictionsIssues } from '#packages/pinModeUtils';
import getBlockedResizeNotification from './blockedResizeNotifications/blockedResizeNotifications';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  CompLayout,
  ProportionStructure,
  PopupContainerProperties,
} from 'types/documentServices';
import { resizeHooks } from '#packages/layoutResize';

const { isMultiselect } = util.array;
const { getSelectedCompRestrictions } = stateManagement.selection.selectors;
const { isInInteractionMode } = stateManagement.interactions.selectors;
const { beforeInteractionResizeStart, restorePreviousTransformations } =
  stateManagement.interactions.actions;
const { SnapToHandler } = core.utils;

type DirectionName = 'left' | 'right' | 'top' | 'bottom';

let compsToResize: CompRef[];
let resizeTransition: layoutTransitionsUtil.Resize;
let isShiftKeyDown: boolean;
let directionName: DirectionName;
let snapToHandler: SnapToHandler;
let snapDirections: ValuesOf<typeof snapDirectionMap>;
let proportional: boolean;
let ignoreTouchingGridLines: boolean;
let editorAPI: EditorAPI;
let initProportionStructure: ProportionStructure;

const { DragConstraintsHandler } = core.utils;
let dragConstraintHandlers: DragConstraintsHandler[] = [];
let initialRatio: number;
let initAbsLayout: CompLayout;
let lastShortcutContext: string;
let lastWantedLayout: CompLayout;

let layoutUpdateCtx: layoutUtils.LayoutUpdateCtx;

const EXTEND_LAYOUT_BY_TYPE_MAP = {
  'wysiwyg.viewer.components.PopupContainer'(layout) {
    const popupLayout: PopupContainerProperties =
      editorAPI.components.properties.get(compsToResize);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, layout, {
      symmetry: {
        horizontal: popupLayout.horizontalAlignment === 'center',
        vertical: popupLayout.verticalAlignment === 'center',
      },
    });
  },
};

const snapDirectionMap = {
  right: { right: true },
  left: { left: true },
  top: { top: true },
  bottom: { bottom: true },
  topRight: { top: true, right: true },
  topLeft: { top: true, left: true },
  bottomRight: { bottom: true, right: true },
  bottomLeft: { bottom: true, left: true },
} as const;

const euclidDirectionMap = {
  right: { x: 1, y: 0 },
  left: { x: -1, y: 0 },
  top: { x: 0, y: -1 },
  bottom: { x: 0, y: 1 },
  topRight: { x: 1, y: -1 },
  topLeft: { x: -1, y: -1 },
  bottomRight: { x: 1, y: 1 },
  bottomLeft: { x: -1, y: 1 },
} as const;

const resizeShortcutContextName = util.keyboardShortcuts.CONTEXTS.RESIZE;

const rotateContext = {
  esc: () => {
    editorAPI.mouseActions.turnOffMouseEvents();
    if (shouldEnableProportionalScaling()) {
      editorAPI.components.layout.updateRelativeToScreenAndPreserveProportions(
        compsToResize,
        initAbsLayout,
        initProportionStructure,
      );
    } else {
      editorAPI.components.layout.updateRelativeToScreen(
        compsToResize,
        initAbsLayout,
        true,
      );
    }
    unsetContext();
    editorAPI.bi.event(coreBi.events.shortcuts.esc_during_action, {
      action: constants.MOUSE_ACTION_TYPES.RESIZE,
    });
  },
};

util.keyboardShortcuts.registerContext(
  resizeShortcutContextName,
  rotateContext,
);
const setContext = () => {
  if (!lastShortcutContext) {
    lastShortcutContext = util.keyboardShortcuts.getContext();
    util.keyboardShortcuts.setContext(resizeShortcutContextName);
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.SET_RESIZE_SHORTCUT_CONTEXT,
    );
  }
};

const unsetContext = () => {
  if (lastShortcutContext) {
    util.keyboardShortcuts.setContext(lastShortcutContext);
    lastShortcutContext = null;
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.SET_RESIZE_SHORTCUT_CONTEXT,
    );
  }
};

const showBlockedResizeNotification = () => {
  let compType = editorAPI.components.getType(compsToResize);
  if (compType === constants.COMP_TYPES.APP_WIDGET) {
    const firstWidgetChild = editorAPI.dsRead.components.getChildren(
      _.head(compsToResize),
    )[0];
    compType = editorAPI.components.getType(firstWidgetChild);
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(constants.BLOCKED_RESIZE_NOTIFICATION_COMPS, compType)) {
    const currentLayout = editorAPI.components.layout.get_size(compsToResize);
    if (lastWantedLayout.width < currentLayout.width) {
      editorAPI.store.dispatch(
        stateManagement.notifications.actions.showUserActionNotification(
          getBlockedResizeNotification(editorAPI)[compType],
        ),
      );
    }
  }
};

function getEuclidDirection(dirName: DirectionName) {
  return euclidDirectionMap[dirName];
}

function getSnapDirection(dirName: DirectionName) {
  return snapDirectionMap[dirName];
}

function getMousePosition(mouseEvent) {
  return { x: mouseEvent.pageX, y: mouseEvent.pageY };
}

function shouldEnableProportionalResizeForSpecialComps(
  overrideProportionalResize,
) {
  const editorState = editorAPI.store.getState();

  const proportionalEnabledDirections = _.pick(snapDirectionMap, [
    'topRight',
    'topLeft',
    'bottomRight',
    'bottomLeft',
  ]);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const isCornerResize = _.includes(
    proportionalEnabledDirections,
    snapDirections,
  );

  const { resizeOnlyProportionally } = getSelectedCompRestrictions(editorState);

  return (
    (resizeOnlyProportionally || !isShiftKeyDown) &&
    isCornerResize &&
    overrideProportionalResize
  );
}

function shouldEnableProportionalScaling(): boolean {
  const overrideProportionalResize =
    editorAPI.components.is.overrideProportionalResize(compsToResize);

  if (
    overrideProportionalResize === true ||
    overrideProportionalResize === false
  ) {
    return shouldEnableProportionalResizeForSpecialComps(
      overrideProportionalResize,
    );
  }
  return (
    (isShiftKeyDown ||
      editorAPI.components.is.group(compsToResize) ||
      isMultiselect(compsToResize)) &&
    editorAPI.components.is.proportionallyResizable(compsToResize)
  );
}

function shouldExtendLayout(compType) {
  return _.has(EXTEND_LAYOUT_BY_TYPE_MAP, compType);
}

function extendLayout(layout, compType) {
  return EXTEND_LAYOUT_BY_TYPE_MAP[compType](layout);
}

function getResizeRatio(initLayout: CompLayout) {
  if (editorAPI.components.is.repeaterItem(compsToResize)) {
    const parent = editorAPI.components.getContainer(compsToResize);

    const repeaterLayout = editorAPI.components.layout.get_size(parent);
    const repeaterItems = editorAPI.components.getChildren(parent);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const itemIndex = _.findIndex(repeaterItems, compsToResize[0]);

    if (directionName === 'right' || directionName === 'left') {
      const amountOfItemsInRow =
        repeaterLayout.width > initLayout.width
          ? Math.floor(repeaterLayout.width / initLayout.width)
          : 1;

      const itemIndexInRow = (itemIndex + 1) % amountOfItemsInRow;
      if (itemIndexInRow === 0) {
        return amountOfItemsInRow;
      }
      return itemIndexInRow;
    }

    if (directionName === 'bottom' || directionName === 'top') {
      const amountOfItemsInColumn =
        repeaterLayout.height > initLayout.height
          ? Math.floor(repeaterLayout.height / initLayout.height)
          : 1;

      const itemIndexInColumn = (itemIndex + 1) % amountOfItemsInColumn;
      if (itemIndexInColumn === 0) {
        return amountOfItemsInColumn;
      }
      return itemIndexInColumn;
    }
  }
  return 1;
}

function setupResizeTransition(event) {
  const mouse = getMousePosition(event);
  const euclidDirection = getEuclidDirection(directionName);
  initAbsLayout =
    editorAPI.components.layout.getRelativeToScreen(compsToResize);
  const compType = editorAPI.components.getType(compsToResize);

  proportional = shouldEnableProportionalScaling();

  if (shouldExtendLayout(compType)) {
    initAbsLayout = extendLayout(initAbsLayout, compType);
  }

  initialRatio = getResizeRatio(initAbsLayout);

  resizeTransition = new layoutTransitionsUtil.Resize(
    initAbsLayout,
    mouse,
    euclidDirection,
    proportional,
    initialRatio,
  );

  initProportionStructure = editorAPI.components.layout.getProportionStructure(
    compsToResize,
    euclidDirection,
  );
}

function snapToTheClosestLayout(layout, isAltKeyDown) {
  const closestLayoutDistance = snapToHandler.getClosestLayoutDistance(
    editorAPI,
    layout,
    snapDirections,
  );
  const shouldSnapHorizontally = Math.abs(closestLayoutDistance.x) < 5;
  const shouldSnapVertically = Math.abs(closestLayoutDistance.y) < 5;

  if (shouldSnapHorizontally) {
    if (snapDirections.right) {
      if (shouldEnableProportionalScaling() && layout.height) {
        layout.height += closestLayoutDistance.x;
      }
      layout.width += closestLayoutDistance.x;
    } else if (snapDirections.left) {
      if (shouldEnableProportionalScaling() && layout.height) {
        layout.height -= closestLayoutDistance.x;
      }
      layout.x += closestLayoutDistance.x;
      layout.width -= closestLayoutDistance.x;
    }
  }

  if (shouldSnapVertically) {
    if (snapDirections.top) {
      if (shouldEnableProportionalScaling() && layout.width) {
        layout.width -= closestLayoutDistance.y;
      }
      layout.y += closestLayoutDistance.y;
      layout.height -= closestLayoutDistance.y;
    } else if (snapDirections.bottom) {
      if (shouldEnableProportionalScaling() && layout.width) {
        layout.width += closestLayoutDistance.y;
      }
      layout.height += closestLayoutDistance.y;
    }
  }

  if (!isAltKeyDown) {
    const similarSizeCandidates = snapToHandler.getSimilarSizeCandidates(
      editorAPI,
      layout,
      snapDirections,
    );
    const similarHeightCandidate =
      similarSizeCandidates.potentialSimilarHeightCandidate;
    const similarWidthCandidate =
      similarSizeCandidates.potentialSimilarWidthCandidate;

    if (similarHeightCandidate) {
      if (snapDirections.top) {
        layout.y += layout.height - similarHeightCandidate.layout.height;
      }
      layout.height = similarHeightCandidate.layout.height;
    }

    if (similarWidthCandidate) {
      if (snapDirections.left) {
        layout.x += layout.width - similarWidthCandidate.layout.width;
      }
      layout.width = similarWidthCandidate.layout.width;
    }
  }

  return layout;
}

function snapToGridLines(layout) {
  if (snapDirections.right) {
    layout.width -= layout.width % 5;
  } else if (snapDirections.left) {
    const incrementXVal = layout.x % 5;
    layout.x -= incrementXVal;
    layout.width += incrementXVal;
  }

  if (snapDirections.top) {
    const incrementYValue = layout.y % 5;
    layout.y -= incrementYValue;
    layout.height += incrementYValue;
  } else if (snapDirections.bottom) {
    layout.height -= layout.height % 5;
  }
}

function drawSnapLines(layout, isAltKeyDown) {
  const snapData = snapToHandler.getSnapData(
    layout,
    snapDirections,
    ignoreTouchingGridLines,
    undefined,
    constants.MOUSE_ACTION_TYPES.RESIZE,
    isAltKeyDown,
  );
  editorAPI.snapData.set(snapData);
}
function getComponentsToResize(
  components: CompRef[],
  editorAPI: EditorAPI,
): CompRef[] {
  if (editorAPI.components.is.verticallyResizableByChildren(components)) {
    return editorAPI.components.getChildren(components);
  }

  if (editorAPI.components.is.verticallyResizableByContainer(components)) {
    return [editorAPI.components.getContainer(components)];
  }

  return components;
}

function startResize(
  _editorAPI: EditorAPI,
  params: {
    comps: CompRef[];
    directionName: DirectionName | string;
    evt: MouseEvent;
  },
) {
  editorAPI = _editorAPI;

  editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(false);

  compsToResize = getComponentsToResize(
    editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
      params.comps,
    ),
    editorAPI,
  );
  ignoreTouchingGridLines = editorAPI.dsRead.pages.isWidgetPage(
    editorAPI.pages.getFocusedPageId(),
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const selectedCompIds = _.map(compsToResize, 'id');
  const currentEditorState = editorAPI.store.getState();
  const isInteractionMode = isInInteractionMode(currentEditorState);
  const isComponentFocusMode = editorAPI.componentFocusMode.isEnabled();

  if (!isInteractionMode && !isComponentFocusMode) {
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(selectedCompIds);
    editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity(
      selectedCompIds,
      0.9,
    );
  }

  isShiftKeyDown = params.evt.shiftKey;
  layoutUpdateCtx = new layoutUtils.LayoutUpdateCtx(
    editorAPI,
    compsToResize[0],
  );
  directionName = params.directionName;
  snapToHandler = new SnapToHandler(editorAPI, params.comps);
  snapDirections = getSnapDirection(params.directionName);
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  dragConstraintHandlers = _.map(compsToResize, function (comp) {
    return new DragConstraintsHandler(editorAPI, comp);
  });

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(dragConstraintHandlers, function (handler) {
    handler.highlightDragConstraints();
  });

  setupResizeTransition(params.evt);
  const compRef = editorAPI.components.get.byId(selectedCompIds[0]);

  if (isInteractionMode) {
    beforeInteractionResizeStart(editorAPI, compRef);
  }

  editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
    platformEvents.factory.componentResizeStarted({ compRef }),
  );

  resizeHooks.onResizeStart.fire({ compRef: compsToResize[0] });
}

function resize(event) {
  const mouse = getMousePosition(event);
  const isAltKeyDown = event.altKey; // Do not use event in an async context. read more: https://goo.gl/5ScdzU

  if (isShiftKeyDown !== event.shiftKey) {
    isShiftKeyDown = event.shiftKey;
    setupResizeTransition(event);
  }

  let layout = resizeTransition.getLayout(mouse);

  if (
    editorAPI.getViewTools().snapToEnabled &&
    layout.rotationInDegrees === 0
  ) {
    layout = snapToTheClosestLayout(layout, isAltKeyDown);
  }

  const previousLayout =
    editorAPI.components.layout.getRelativeToScreen(compsToResize);
  layout = updateLayoutUtil.getLayoutRelativeToScreenAfterConstraints(
    editorAPI,
    compsToResize[0],
    layout,
    previousLayout,
  );
  const state = editorAPI.store.getState();
  if (isInInteractionMode(state)) {
    const layoutConsideringConstrains = _.defaults({}, layout, previousLayout);
    if (
      !layoutUtils.canMoveByInteractionsLayoutConstraints(
        editorAPI,
        compsToResize,
        layoutConsideringConstrains,
      )
    ) {
      return;
    }
  }

  const { updatedLayout, keepProportions } =
    updateLayoutUtil.runLayoutRelativeToScreenPlugin(
      editorAPI,
      compsToResize[0],
      layout,
      proportional,
    );

  lastWantedLayout = updatedLayout;

  if (keepProportions) {
    editorAPI.components.layout.updateRelativeToScreenAndPreserveProportions(
      compsToResize,
      updatedLayout,
      initProportionStructure,
    );
  } else {
    editorAPI.components.layout.updateRelativeToScreen(
      compsToResize,
      updatedLayout,
      true,
      layoutUpdateCtx,
    );
  }

  resizeHooks.onResize.fire({ proportionally: proportional });

  editorAPI.dsActions.waitForChangesApplied(function () {
    // we wait for changes to be applied to make sure layout constraint didn't change our layout during update
    if (snapToHandler) {
      const updatedLayout =
        editorAPI.components.layout.getRelativeToScreen(compsToResize);
      if (isAltKeyDown) {
        snapToGridLines(updatedLayout);
      } else if (
        editorAPI.getViewTools().snapToEnabled &&
        updatedLayout.rotationInDegrees === 0
      ) {
        drawSnapLines(updatedLayout, isAltKeyDown);
      }
    }
  });
}

function endResize() {
  if (util.snapToUtils.isNewStageGuidesEnabled()) {
    util.snapToUtils.snapCloseToBorder(
      editorAPI,
      compsToResize,
      typeof constants.MOUSE_ACTION_TYPES.RESIZE,
    );
  }

  snapToHandler = null;
  editorAPI.snapData.clear();
  const currentEditorState = editorAPI.store.getState();
  const isInteractionMode = isInInteractionMode(currentEditorState);
  const isCompFocusMode = editorAPI.componentFocusMode.isEnabled();

  if (!isInteractionMode && !isCompFocusMode) {
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(null);
    editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity([]);
  }

  editorAPI.history.add('Resize component');

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(dragConstraintHandlers, function (handler) {
    handler.removeHighlightDragConstraints();
  });

  dragConstraintHandlers = [];

  if (
    editorAPI.components.layout.isPinned(compsToResize) &&
    hasPinDockingSizeRestrictionsIssues(editorAPI, compsToResize[0])
  ) {
    editorAPI.panelHelpers.openPinModeSizeRestrictionPanel();
  }

  if (isInteractionMode) {
    restorePreviousTransformations(editorAPI);
  }

  editorAPI.store.dispatch(
    stateManagement.multilingual.actions.componentChanged(),
  );

  editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);

  editorAPI.dsActions.waitForChangesApplied(showBlockedResizeNotification);

  resizeHooks.onResizeEnd.fire();
}

const moduleToExport = {
  start: startResize,
  on: resize,
  end: endResize,
  type: constants.MOUSE_ACTION_TYPES.RESIZE,
};

_.merge(moduleToExport, {
  setContext,
  unsetContext,
});

export default moduleToExport;

import styles from './workspaceRightPanel.scss';
import React from 'react';
import { Scope } from '../workspaceRightPanelEntryPoint';
import { useUXResourceRestrictions } from '#packages/editorRestrictions';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import { InnerRightPanel } from './innerRightPanel';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './workspaceRightPanelMapper';
import { cx } from '#packages/util';

export interface OwnProps {}

type WorkspaceRightPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

const WorkspaceRightPanelPure: React.FC<WorkspaceRightPanelProps> = (props) => {
  const [isCloseBtnVisible] = useUXResourceRestrictions([
    'workspace-right-panel-close-btn.visible',
  ]);
  const {
    helpId,
    panelWidth,
    title,
    onHelp,
    isOpened,
    onClose,
    onBack,
    panelContent,
    contributedDrillInPanels,
    drillInPanelsContent,
    panelName,
    groupType,
    changeTab,
    getTabs,
    contextMenuConfig,
    hiddenControls,
    renderHeader,
  } = props;

  return (
    <div
      data-hook="workspace-right-panel"
      className={cx(styles.workspaceRightPanel, {
        [styles.hiddenControls]: hiddenControls,
      })}
      style={{
        width: panelWidth,
        transform: `translateX(${isOpened ? 0 : panelWidth}px)`,
      }}
    >
      <InnerRightPanel
        helpId={helpId}
        panelWidth={panelWidth}
        title={title}
        onHelp={onHelp}
        isOpened={isOpened}
        onClose={onClose}
        noCloseBtn={!isCloseBtnVisible}
        panelContent={panelContent}
        panelName={panelName}
        contextMenuConfig={contextMenuConfig}
        groupType={groupType}
        changeTab={changeTab}
        getTabs={getTabs}
        isSecondaryPanel={false}
        renderHeader={renderHeader}
      />
      {drillInPanelsContent.map((panelContent) => {
        const panelConfig = contributedDrillInPanels[panelContent.uniqueId];
        return (
          <InnerRightPanel
            key={`drillIn-${panelContent.uniqueId}`}
            panelWidth={panelWidth}
            title={title}
            secondaryPanelTitle={panelConfig.panelTitle}
            onHelp={onHelp}
            onBack={onBack}
            onBackTooltip={panelConfig.backTooltip}
            noCloseBtn={true}
            isOpened={panelConfig.isOpen}
            panelContent={panelConfig.isOpen ? [panelContent] : []}
            panelName={panelConfig.panelName}
            isSecondaryPanel={true}
            zIndex={panelConfig.zIndex}
            renderHeader={renderHeader}
          />
        );
      })}
    </div>
  );
};

export const WorkspaceRightPanel = connectWithScope(
  () => Scope,
  WorkspaceRightPanelPure,
  mapStateToProps,
  mapDispatchToProps,
);

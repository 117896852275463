import type { EditorAPI } from '#packages/editorAPI';
import type {
  MediaManagerImage,
  MediaManagerInfo,
} from '#packages/mediaServices';

export interface MediaManagerOptions {
  callback(items?: MediaManagerImage[], info?: MediaManagerInfo): void;
  multiSelect?: boolean;
  path?: string;
  translation?:
    | string
    | {
        submitButton: string;
      };
}

export interface MediaManagerResolvedData {
  items?: MediaManagerImage[];
  info?: MediaManagerInfo;
}

interface MediaManager {
  open(
    category: string,
    openSource: string,
    options: MediaManagerOptions,
  ): void;
  categories: Record<string, string>;
}

export const openMediaManager = (
  editorAPI: EditorAPI,
  category: string,
  openSource: string,
  options: Omit<MediaManagerOptions, 'callback'>,
): Promise<MediaManagerResolvedData> => {
  const { mediaManager } = editorAPI.mediaServices as {
    mediaManager: MediaManager;
  };
  const resolvedCategory =
    mediaManager.categories[category] || mediaManager.categories.IMAGE;

  return new Promise((resolve) => {
    mediaManager.open(resolvedCategory, openSource, {
      ...options,
      callback: (items, info) => resolve({ items, info }),
    });
  });
};

// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.THEME,
  hide: false,
  title: 'add_section_label_theme_strip',
  subNavigationTitle: 'theme_strip_title',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  },
  props: {
    compTypes: ['wysiwyg.viewer.components.BgImageStrip'],
    verticalMargin: 30,
    horizontalMargin: 20,
    scaleProportion: {
      x: 0.5,
      y: 0.5,
    },
  },
  help: {
    hide: false,
    text: 'add_section_info_text_theme_general',
  },
};

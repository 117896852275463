// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';

const { actions, selectors } = stateManagement.multilingual;

export default {
  mapStateToProps: ({ editorAPI }) => ({
    isLanguageChanging: selectors.isChanging(editorAPI.store.getState()),
    nextLanguage: selectors.nextLanguage(editorAPI.store.getState()),
  }),
  mapDispatchToProps: (dispatch) => ({
    setCurrentLanguage: (languageCode) =>
      dispatch(actions.setCurrentLanguageThunk(languageCode)),
    completeLanguageChange: () => dispatch(actions.completeLanguageChange()),
  }),
};

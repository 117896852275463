import { type ToolsEntryPayload } from '@wix/editor-platform-host-integration-apis';

import { PanelCloseOrigin } from '#packages/panels';

import platformConsts from '../common/constants';
import type { AddonsScope } from './scope';

export const createAddonsPrivateApi = (scope: AddonsScope) => {
  const registry = new Map<string, ToolsEntryPayload>();
  let memoizedAddons = [] as ToolsEntryPayload[];

  const registerAddon = (payload: ToolsEntryPayload) => {
    if (!registry.has(payload.appDefinitionId)) {
      registry.set(payload.appDefinitionId, payload);
      memoizedAddons = [...registry.values()];
    }
  };

  const removeRegisteredAddon = (payload: ToolsEntryPayload) => {
    if (registry.has(payload.appDefinitionId)) {
      registry.delete(payload.appDefinitionId);
      memoizedAddons = [...registry.values()];
    }
  };

  const getRegisteredAddons = () => {
    return memoizedAddons;
  };

  const openAddonToolPanel = (appDefinitionId: string) => {
    return registry.get(appDefinitionId)?.onClick();
  };

  const closeAddonToolPanel = async (_appDefinitionId: string) => {
    return scope.panelManager.closePanelByName(
      platformConsts.panelTypes.TOOL_FLOATING,
      PanelCloseOrigin.API_METHOD_CALL,
    );
  };

  const closeAddonsMarketPanel = () => {
    scope.panelManager.closePanelByName(
      platformConsts.panelTypes.ADDONS_MARKET,
    );
  };

  return {
    closeAddonsMarketPanel,
    openAddonToolPanel,
    closeAddonToolPanel,
    registerAddon,
    removeRegisteredAddon,
    getRegisteredAddons,
  };
};

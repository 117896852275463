import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorCoreApiKey, WorkspaceApiKey } from '#packages/apis';
import { init } from './init';

export class Scope extends BaseEntryScope {
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  workspaceApi = this.useDependency(WorkspaceApiKey);
}

export const JiraIssueCollectorEntryPoint = createEntryPoint({
  name: 'JiraIssueCollector',
  Scope,
  initialize(scope) {
    void init(scope);
  },
});

// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import dataRegistrationManager from '../utils/dataRegistrationManager';
import * as addPanelDefinitions from '#packages/addPanelDefinitions';
import sectionsData from './sectionsData';
import { overrideUtils } from '#packages/util';
import * as addPanel from '#packages/oldAddPanel';

const { sectionsDefinition } = addPanelDefinitions;

function getCategorySectionsMap(categorySectionsIds, categorySectionsObj) {
  const categorySectionsGroups = categorySectionsIds;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    categorySectionsGroups,
    (acc, sectionGroups, categoryName) => {
      const sectionsDefinitionObj = categorySectionsObj[categoryName];
      acc[categoryName] = sectionGroups.map((group) =>
        _.defaults(
          {
            sections: group.sections.map((sectionName) =>
              _.defaults({ sectionName }, sectionsDefinitionObj[sectionName]),
            ),
          },
          group,
        ),
      );
      return acc;
    },
    {},
  );
}

function resolveRefs(sections, categoryId) {
  if (!sections) {
    return;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(sections, (section) =>
    section.__isRef ? resolveSectionReference(section, categoryId) : section,
  );
}

const resolveSectionReference = (section, categoryId) =>
  _.defaults(
    section,
    _.get(
      addPanel.sections.getSections(),
      `${categoryId}.${section.sectionName}`,
    ),
  );

function resolveGroupRefs(groups, categoryId) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(groups, (group) =>
    _.defaults(
      {
        sections: resolveRefs(group.sections, categoryId),
      },
      group,
    ),
  );
}

function getOverrideSections(categoryDefinition, groupIndex, fallback) {
  const groups = categoryDefinition.items || categoryDefinition.groups;
  return groups[groupIndex]?.sections ?? fallback;
}

function getDefaultTitle(categoryDefinition, groupIndex) {
  const groups = categoryDefinition.items || categoryDefinition.groups;
  return groups[groupIndex]?.title;
}

function applyOverrideForCategories(defaultCategories, overriddenCategories) {
  return _(defaultCategories)
    .thru((categories) =>
      overrideUtils.applyOverrides(categories, overriddenCategories, 'id'),
    )
    .map((category) => {
      const overrides = resolveGroupRefs(
        category.groups || category.items,
        category.id,
      );
      const overriddenItems = overrides.map((item, index) =>
        _.defaults(
          {
            title: item.title || getDefaultTitle(category, index),
            sections: overrideUtils.applyOverrides(
              getOverrideSections(category, index, item.sections),
              item.sections,
              'sectionName',
            ),
          },
          item,
        ),
      );
      return _.defaults({ items: overriddenItems }, category);
    })
    .value();
}

function createCategoriesSectionsAccessor(categoriesDefinition) {
  let categorySectionsMap = null;

  function lazyInitCategorySectionsMap(editorAPI) {
    categorySectionsMap = getCategorySectionsMap(
      sectionsDefinition.get(editorAPI),
      sectionsData.getCategoriesMap(editorAPI),
    );
  }

  function getProcessedCategoryById(editorApi, categoryId) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const rawCategory = _.find(categoriesDefinition.get(editorApi), {
      id: categoryId,
    });
    return getProcessedCategory(editorApi, rawCategory);
  }

  function getProcessedCategory(editorApi, rawCategory) {
    const modifiedCategory = getCategoryWithSections(editorApi, rawCategory);

    addIdToSections(modifiedCategory);

    return modifiedCategory;
  }

  function getCategories(editorApi) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const categories = _.map(
      categoriesDefinition.get(editorApi),
      getProcessedCategory.bind(null, editorApi),
    );
    return _.reject(categories, (category) =>
      category.type === addPanelDataConsts.CATEGORY_DIVIDER
        ? false
        : !category.title,
    );
  }

  function applyContributedSections(
    editorAPI,
    currentCategorySections,
    categoryId,
  ) {
    const registeredSectionsByGroups =
      dataRegistrationManager.registration.getRegisteredSectionsForCategory(
        categoryId,
      ) || {};
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(registeredSectionsByGroups, (sectionsByIndex, groupIndex) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(sectionsByIndex, (sectionFunction, sectionIndex) => {
        const contributedSection = sectionFunction(editorAPI);
        if (contributedSection) {
          currentCategorySections[groupIndex].sections.splice(
            _.toNumber(sectionIndex),
            0,
            contributedSection,
          );
        }
      });
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const registeredSectionsAtStart = _.map(
      dataRegistrationManager.registration.getRegisteredSectionsAtStart(
        categoryId,
      ),
      (sectionFunction) => sectionFunction(editorAPI),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const registeredSectionsAtEnd = _.map(
      dataRegistrationManager.registration.getRegisteredSectionsAtEnd(
        categoryId,
      ),
      (sectionFunction) => sectionFunction(editorAPI),
    );

    _.head(currentCategorySections).sections = [
      ...(registeredSectionsAtStart || []),
      ..._.head(currentCategorySections).sections,
    ];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/last
    _.last(currentCategorySections).sections = [
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/last
      ..._.last(currentCategorySections).sections,
      ...(registeredSectionsAtEnd || []),
    ];
  }

  function getSections(editorAPI, categoryId) {
    const currentCategorySections = categorySectionsMap[categoryId] || [
      { sections: [] },
    ];

    applyContributedSections(editorAPI, currentCategorySections, categoryId);

    return currentCategorySections;
  }

  function getCategoryWithSections(editorApi, category) {
    const modifiedCategory = _.cloneDeep(category);
    if (
      dataRegistrationManager.override.sections &&
      dataRegistrationManager.override.sections.get(category.id)
    ) {
      modifiedCategory.items = getItemsDynamicallyByFunction(
        editorApi,
        category,
      );
      Object.defineProperty(modifiedCategory, 'items', {
        get: getItemsDynamicallyByFunction.bind(null, editorApi, category),
      });
    } else if (
      dataRegistrationManager.override.getRegisteredOverrideGroupsForCategory(
        category.id,
      )
    ) {
      const groupsOverrideFunction =
        dataRegistrationManager.override.getRegisteredOverrideGroupsForCategory(
          category.id,
        );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      modifiedCategory.items = _.map(
        groupsOverrideFunction(editorApi),
        (group) =>
          _.defaults(
            { sections: setDisabledItems(editorApi, group.sections) },
            group,
          ),
      );
    } else {
      lazyInitCategorySectionsMap(editorApi);

      modifiedCategory.items = getSections(editorApi, category.id);
    }
    return modifiedCategory;
  }

  function addIdToSections(category) {
    const items = category.items.reduce(
      (acc, item) => [...acc, ...item.sections],
      [],
    );
    items.forEach((item, index) => {
      item.id = item.id || category.id + item.type + index;
    });
  }

  function setDisabledItems(editorApi, dynamicItems) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(dynamicItems, function (item) {
      if (item.isDisabledFn) {
        item.isDisabled = item.isDisabledFn(editorApi);
      }
      return item;
    });
  }

  function getItemsDynamicallyByFunction(editorApi, category) {
    const overrideFunction = dataRegistrationManager.override.sections.get(
      category.id,
    );
    const dynamicItems = setDisabledItems(
      editorApi,
      overrideFunction(editorApi, category),
    );
    const relatedAppFunction = dataRegistrationManager.end.get(category.id);
    const items = relatedAppFunction
      ? dynamicItems.concat(relatedAppFunction(category.id))
      : dynamicItems;
    return items;
  }

  return {
    getCategory: getProcessedCategoryById,
    getCategories,
  };
}

export { createCategoriesSectionsAccessor, applyOverrideForCategories };

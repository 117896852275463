import type { EditorAPI } from '#packages/editorAPI';

import type { ConditionStructure, ConditionTranslations } from '../types';

const METADATA_KEY = 'isSitePublishRequired';
const TRANSLATION_KEY = 'isSitePublishCondition';

export interface SitePublishCondition {
  extras: {
    [METADATA_KEY]?: boolean;
  };
  translations: {
    [TRANSLATION_KEY]?: ConditionTranslations;
  };
}

export const getSitePublishConditionStructure = (
  editorAPI: EditorAPI,
): ConditionStructure<
  typeof METADATA_KEY,
  typeof TRANSLATION_KEY,
  SitePublishCondition
> => ({
  metadataKey: METADATA_KEY,
  translationsKey: TRANSLATION_KEY,
  check: () => !editorAPI.dsRead.generalInfo.isSitePublished(),
  getAction: () => () =>
    editorAPI.panelManager.openPanel('panels.focusPanels.confirmPublishPanel'),
});

import { BasePublicApi } from '#packages/apilib';
import _ from 'lodash';
import type { Scope } from './scope';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompLayout, CompRef } from '@wix/document-services-types';
import * as util from '#packages/util';
import { components, multilingual } from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';

const { asArray } = util.array;

const layoutsAreEq = (res1: CompLayout, res2: CompLayout) =>
  _.isEqualWith(res1, res2, (v1, v2) => {
    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return Math.abs(v1 - v2) < 1.5;
    }
    return undefined;
  });

function reportErrorIfLayoutDiffers(
  editorAPI: EditorAPI,
  res1: CompLayout,
  res2: CompLayout,
  compRefs: CompRef | CompRef[],
) {
  const isEq = layoutsAreEq(res1, res2);
  if (isEq) {
    return;
  }
  const compRef = asArray(compRefs)[0];
  const node = document
    .querySelector<HTMLIFrameElement>('#preview')
    .contentDocument.getElementById(compRef.id);

  const type = editorAPI.dsRead.components.getType(compRef);
  //eslint-disable-next-line no-console
  console.log(node);
  //eslint-disable-next-line no-console
  console.error(`not eq`, compRef, type, _.cloneDeep(res1), _.cloneDeep(res2));
}

function shouldReport(scope: Scope, compRef: CompRef) {
  const { editorAPI } = scope;
  const state = editorAPI.store.getState();

  if (components.selectors.hasResponsiveLayout(compRef, editorAPI.dsRead)) {
    return false;
  }

  if (multilingual.selectors.isTranslating(state)) {
    return false;
  }
  return true;
}

export function initDebugLayoutsOnSelect(scope: Scope) {
  const { selectionApi } = scope;

  const sentRefs = new Set<string>();
  selectionApi.hooks.selectionChanged.tap(({ compRefs }) => {
    if (compRefs.length !== 1) {
      return;
    }
    const compRef = compRefs[0];
    if (!shouldReport(scope, compRef)) {
      return;
    }
    const { biApi, editorAPI } = scope;

    if (sentRefs.has(compRef.id)) {
      return;
    }

    const domLayout =
      editorAPI.components.layout.getMeasuredLayoutRelativeToScreen(compRef);
    const structureLayout =
      editorAPI.dsRead.components.layout.getRelativeToScreen(compRef);

    if (layoutsAreEq(domLayout, structureLayout)) {
      return;
    }

    const type = editorAPI.dsRead.components.getType(compRef);

    biApi.event(coreBi.events.editor.BOUNDING_BOX_ISSUES, {
      methodName: 'getRelativeToScreen',
      component_id: compRef.id,
      component_type: type,
      layout_structure: JSON.stringify(structureLayout),
      layout_dom: JSON.stringify(domLayout),
    });
    sentRefs.add(compRef.id);
  });
}

export function debugLayouts(scope: Scope) {
  const { editorAPI } = scope;
  const compRefs = editorAPI.dsRead.deprecatedOldBadPerformanceApis.components
    .getAllComponents()
    .filter(editorAPI.dsRead.components.isRenderedOnSite)
    .filter((compRef) => {
      if (editorAPI.dsRead.components.responsiveLayout?.get(compRef)) {
        return false;
      }
      return true;
    });
  //eslint-disable-next-line no-console
  console.log(compRefs);

  compRefs.forEach((compRef) => {
    const domLayout =
      editorAPI.components.layout.getMeasuredLayoutRelativeToScreen(compRef);
    const structureLayout =
      editorAPI.dsRead.components.layout.getRelativeToScreen(compRef);
    reportErrorIfLayoutDiffers(editorAPI, domLayout, structureLayout, compRef);
  });
}

export class DebugLayoutPublicApi extends BasePublicApi<Scope> {}

export const NO_ZOOM_SCALE = 1;
import constants from '#packages/constants';

const { SECTION, FOOTER, HEADER } = constants.COMP_TYPES;

export enum WorkspaceModes {
  FULL = 'FULL',
  LITE = 'LITE',
}

export const MOBILE = 'MOBILE';
export const DESKTOP = 'DESKTOP';

export const DEFAULT_WORKSPACE_MODE = WorkspaceModes.FULL;

export const MODES_TO_SCALE_MAP = {
  [MOBILE]: {
    [WorkspaceModes.FULL]: NO_ZOOM_SCALE,
    [WorkspaceModes.LITE]: NO_ZOOM_SCALE,
  },
  [DESKTOP]: {
    [WorkspaceModes.FULL]: NO_ZOOM_SCALE,
    [WorkspaceModes.LITE]: 0.75,
  },
};

export const LITE_MODE_ALLOWED_SELECTABLE_COMPS: string[] = [
  SECTION,
  HEADER,
  FOOTER,
];

export const SCALE_TRANSITION_DURATION = 0.5;

export const WORKSPACE_MODES_LOCAL_STORAGE_KEY = 'WORKSPACE_MODE_ACTIVE_MODE';

import { sections as sectionsUtils } from '#packages/util';
import { MigrationOrigin } from '../constants';

export const getMigrationOrigin = (): MigrationOrigin => {
  const originQueryParam = sectionsUtils.getForceMigrationOriginQueryParam();

  switch (originQueryParam) {
    case MigrationOrigin.TopBarBanner:
    case MigrationOrigin.SiteMenu:
      return originQueryParam as MigrationOrigin;
    default:
      return MigrationOrigin.Init;
  }
};

// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import pageSettingsTabMixin from './pageSettingsTabMixin';
import * as pageUtils from '../../utils/pageUtils';
import experiment from 'experiment';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import * as pageMenuItemHelpers from '../../utils/pageMenuItemHelpers';
import CONSTANTS from '../../utils/constants';

function getValidators(editorAPI, pageId) {
  const initialChecks = [
    {
      callback: util.validate.notEmptyString,
      message: 'Pages_Info_URL_Error_Tooltip',
    },
    {
      callback: util.validate.emoji,
      message: 'Pages_Info_URL_Error_Tooltip',
    },
    {
      callback: util.validate.byInvalidCharacters(
        util.validationExpressions.invalidUrlCharacters,
      ),
      message: 'Pages_Info_URL_Error_Tooltip',
    },
  ];
  if (editorAPI.dsRead.generalInfo.urlFormat.isSlash()) {
    return initialChecks.concat([
      {
        callback: _.negate(
          editorAPI.pages.data.pageUriSEO.isForbidden.bind(null, pageId),
        ),
        message: 'Pages_Info_Forbidden_URI_SEO_Error_Tooltip',
      },
      {
        callback: _.negate(
          editorAPI.pages.data.pageUriSEO.isDuplicate.bind(null, pageId),
        ),
        message: 'Pages_Info_Duplicate_URI_SEO_Error_Tooltip',
      },
    ]);
  }
  return initialChecks;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'seoUrlSettings',
  mixins: [pageSettingsTabMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated
  render() {
    return (
      <div
        key="section-page-address"
        className={this.getClassName('seo-section-page-address')}
      >
        <span className="seo-section-title-label">
          {translate('Pages_Info_Address_Title')}
        </span>
        <div className="seo-section-site-url">
          <span className="page-address-prefix">http://www.../</span>
          <baseUI.textInput
            maxLength={CONSTANTS.NAME_MAX_LENGTH}
            validator={this.getPageUriSEOValidators()}
            invalidMessage={this.getPageUriSEOInvalidMessages()}
            valueLink={this.linkUrlProperty('pageUriSEO')}
            inputFocusTooltipMessage={this.props.inputFocusTooltipMessage}
            infoText="Pages_Info_Address_Tooltip"
            className="control-text-input-seo"
          />
        </div>
        {this.shouldShowLinkToPage() ? (
          <a
            key="page-web-address"
            href={pageMenuItemHelpers.getPublicUrl(
              this.getEditorAPI(),
              this.props.pageData,
            )}
            target="_blank"
          >
            <baseUI.button label="Pages_SEO_Goto_URL" className="btn-text" />
          </a>
        ) : null}
        {this.isUriSEOTranslated() ? (
          <span key="googleAttribution" className="translated-by">
            {translate('Pages_Info_URL_Popup_Google')}{' '}
            <baseUI.symbol name="google-logo" />
          </span>
        ) : null}
      </div>
    );
  },

  propTypes: {
    pageData: PropTypes.object.isRequired,
  },

  shouldShowLinkToPage() {
    return (
      this.props.shouldShowLinkToPage &&
      this.getEditorAPI().dsRead.generalInfo.isSitePublished()
    );
  },

  linkUrlProperty(prop) {
    const pageId = this.getPageId();
    const editorAPI = this.getEditorAPI();
    const newObjValue = {};
    const converter = editorAPI.dsRead.generalInfo.urlFormat.isSlash()
      ? editorAPI.pages.data.pageUriSEO
      : pageUtils;

    return {
      value: this.getValueFromPageData(prop),
      requestChange(newValue) {
        newObjValue[prop] =
          newValue && converter.convertPageNameToUrl(newValue);
        editorAPI.pages.data.update(pageId, newObjValue);
      },
    };
  },

  isUriSEOTranslated() {
    const translationData = this.getValueFromPageData('translationData');
    return (
      experiment.isOpen('urlFormatGoogleTranslate') &&
      translationData &&
      translationData.uriSEOTranslated
    );
  },

  getPageUriSEOValidators() {
    const validators = getValidators(this.getEditorAPI(), this.getPageId());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(validators, 'callback');
  },

  getPageUriSEOInvalidMessages() {
    const validators = getValidators(this.getEditorAPI(), this.getPageId());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(validators, 'message');
  },

  getClassName(defaultClassName) {
    return util.inheritClassName(this.props, defaultClassName);
  },
});

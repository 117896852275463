:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._publishPipeline_huw2r_7 ._wrapper_nonDevMode_huw2r_7 {
  margin-top: 12px; }
  ._publishPipeline_huw2r_7 ._wrapper_nonDevMode_huw2r_7 g {
    fill: #116dff; }
  ._publishPipeline_huw2r_7 ._wrapper_nonDevMode_huw2r_7 .control-text-button {
    vertical-align: text-bottom; }
  ._publishPipeline_huw2r_7 ._wrapper_nonDevMode_huw2r_7 .control-text {
    vertical-align: middle; }
  ._publishPipeline_huw2r_7 ._wrapper_nonDevMode_huw2r_7 ._bulletWrapper_huw2r_15 {
    margin-bottom: 24px; }
    ._publishPipeline_huw2r_7 ._wrapper_nonDevMode_huw2r_7 ._bulletWrapper_huw2r_15 ._bullet_huw2r_15 {
      display: flex; }
      ._publishPipeline_huw2r_7 ._wrapper_nonDevMode_huw2r_7 ._bulletWrapper_huw2r_15 ._bullet_huw2r_15 .tooltip-on-ellipsis-content.singleLine {
        margin-left: 7px; }

._publishPipeline_huw2r_7 ._devUI_huw2r_22 {
  display: flex; }
  ._publishPipeline_huw2r_7 ._devUI_huw2r_22 ._checkboxWrapper_huw2r_24 {
    width: 346px;
    align-self: center; }
    ._publishPipeline_huw2r_7 ._devUI_huw2r_22 ._checkboxWrapper_huw2r_24 svg:not(:root) {
      overflow: visible; }
  ._publishPipeline_huw2r_7 ._devUI_huw2r_22 ._buttons_huw2r_29 {
    display: flex;
    gap: 10px;
    justify-content: space-between; }

._publishPipeline_huw2r_7 .control-base-modal-footer {
  align-items: center; }

._publishPipeline_huw2r_7 .control-message-modal.control-message-modal.with-illustration,
._publishPipeline_huw2r_7 .control-custom-modal.control-custom-modal {
  width: 600px; }

// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import React from 'react';

const permanentBorderSelector = stateManagement.permanentBorder.selectors;

//TYPE WAS GENERATED, remove this line when reviewed
interface PermanentBorderProps {
  isComponentSelected?: boolean;
  bounding?: AnyFixMe;
}

class PermanentBorder extends React.Component<PermanentBorderProps> {
  static displayName = 'permanentBorder';

  static propTypes = {
    isComponentSelected: PropTypes.bool,
    bounding: PropTypes.object,
  };

  getStyle = () => {
    return {
      width: this.props.bounding?.width ?? 0,
      height: this.props.bounding?.height ?? 0,
      left: this.props.bounding?.x ?? 0,
      top: this.props.bounding?.y ?? 0,
    };
  };

  render() {
    return !this.props.isComponentSelected && this.props.bounding ? (
      <div
        key="permanentComponentBorder"
        style={this.getStyle()}
        className="permanent-component-border"
      />
    ) : null;
  }
}

function mapStateToProps({ editorAPI, state }) {
  const component = permanentBorderSelector.getComponent(state);

  return component
    ? {
        bounding:
          editorAPI.components.layout.getRelativeToScreen(component)?.bounding,
        isComponentSelected: editorAPI.selection.isComponentSelected(component),
      }
    : {};
}

const ConnectedPermanentBorder = util.hoc.connect(
  util.hoc.STORES.MOUSE_OPS,
  mapStateToProps,
)(PermanentBorder);

ConnectedPermanentBorder.pure = PermanentBorder;

export default ConnectedPermanentBorder;

import type { Dispatch, StateMapperArgs } from 'types/redux';
import type { EditorState } from '#packages/stateManagement';
import * as stateManagement from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import { biLogger, fedopsLogger } from '#packages/util';
import { PublishManagerApiKey } from '#packages/apis';

import { panelHeaderClose, clickOnPanel } from '@wix/bi-logger-editor/v2';

import { HELP_ID, PANEL_NAME } from './constants';
import type {
  PublishErrorPanelDispatchProps,
  PublishErrorPanelMergeProps,
  PublishErrorPanelOwnProps,
  PublishErrorPanelStateProps,
  PublishPipelineProvider,
} from './PublishErrorPanelTypes';

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;

export function mapDispatchToProps(
  dispatch: Dispatch,
): PublishErrorPanelDispatchProps {
  const editorAPI = dispatch(getEditorAPI);

  return {
    closePanel: (
      isIgnorePublishErrorValue: boolean,
      origin: string,
      panelName: string,
    ) => {
      if (isIgnorePublishErrorValue) {
        dispatch(
          stateManagement.savePublish.actions.setIsPublishErrorIgnoreInCurrentSession(
            true,
          ),
        );
      }

      biLogger.report(
        panelHeaderClose({
          status: isIgnorePublishErrorValue,
          panel_name: panelName,
          origin,
        }),
      );

      return editorAPI.panelManager.closePanelByName(PANEL_NAME);
    },
    publishAnyway: ({
      deploymentId,
      closePanel,
      isIgnorePublishErrorValue,
      origin,
      panelName,
    }) => {
      const dsPublishOptions = { deploymentId };
      fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.PUBLISH, {
        paramsOverrides: {
          sourceOfStart: 'publishPipelinePanel',
        },
      });

      editorAPI.host.getAPI(PublishManagerApiKey).publish({ dsPublishOptions });
      closePanel(isIgnorePublishErrorValue, origin, panelName);
    },
    onHelp: (panelName: string, helpId: string = HELP_ID) => {
      biLogger.report(
        //@ts-expect-error
        clickOnPanel({
          panel_name: panelName,
          action_name: `helper click ${helpId}`,
        }),
      );

      return editorAPI.panelManager.openHelpCenter(helpId);
    },
  };
}

export function mapStateToProps({
  editorAPI,
}: StateMapperArgs): PublishErrorPanelStateProps {
  const isDevMode = editorAPI.developerMode.isEnabled();

  return {
    isDevMode,
  };
}

export function mergeProps(
  StateProps: PublishErrorPanelStateProps,
  { closePanel, publishAnyway, onHelp }: PublishErrorPanelDispatchProps,
  { deploymentId }: PublishErrorPanelOwnProps,
): PublishErrorPanelMergeProps {
  const publishPipelineProvider: PublishPipelineProvider = {
    deploymentId,
    closePanel,
  };

  return {
    ...StateProps,
    publishAnyway,
    closePanel,
    onHelp,
    deploymentId,
    publishAnywayTrigger: (
      isIgnorePublishErrorValue: boolean,
      origin: string,
      panelName: string,
    ) =>
      publishAnyway({
        ...publishPipelineProvider,
        isIgnorePublishErrorValue,
        origin,
        panelName,
      }),
  };
}

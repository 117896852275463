import _ from 'lodash';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import type { MapDispatchToProps, MapStateToProps } from 'types/redux';
import type {
  LanguagePanelDispatchProps,
  LanguagePanelExternalProps,
  LanguagePanelStateProps,
} from './languagePanel';
import { MultilingualPages } from '#packages/stateManagement';

const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
const { DONT_SHOW_AGAIN: didShowPublish } =
  constants.USER_PREFS.MULTILINGUAL.PUBLISH_SUCCESS_PANEL;
const { DONT_SHOW_AGAIN: didShowUnpublish } =
  constants.USER_PREFS.MULTILINGUAL.UNPUBLISH_PANEL;
const { openHelpCenter } = stateManagement.panels.actions;

const mapStateToProps: MapStateToProps<
  LanguagePanelStateProps,
  LanguagePanelExternalProps
> = ({ editorAPI, state, dsRead }) => {
  const { language } = dsRead;
  const isPreviewMode = editorAPI.preview.isInPreviewMode();

  return {
    currentLanguageCode: language.current.get(),
    originalLanguage: language.original.get(),
    translationLanguages: language.get(),
    isEnabled: language.multilingual.isEnabled(),
    siteId: dsRead.generalInfo.getSiteId(),
    didShowPublishSuccessPanel: getSiteUserPreferences(didShowPublish)(
      state,
    ) as boolean,
    didShowUnpublishPanel: getSiteUserPreferences(didShowUnpublish)(
      state,
    ) as boolean,
    isPreviewMode,
    isCurrentLanguageSecondary: language.isCurrentLanguageSecondary(),
    biHosting: isPreviewMode ? 'preview' : 'editor',
  };
};

const mapDispatchToProps: MapDispatchToProps<
  LanguagePanelDispatchProps,
  LanguagePanelExternalProps
> = (dispatch, ownProps) => ({
  setCurrentLanguage: (languageCode) =>
    dispatch(
      stateManagement.multilingual.actions.setCurrentLanguageThunk(
        languageCode,
      ),
    ),
  startLanguageChange: (languageCode) =>
    dispatch(
      stateManagement.multilingual.actions.startLanguageChange(languageCode),
    ),
  updateLanguageStatus: (languageCode, status) =>
    dispatch(
      stateManagement.multilingual.actions.updateLanguageStatusThunk(
        languageCode,
        status,
      ),
    ),
  closeOpenedPanels: () =>
    dispatch(stateManagement.panels.actions.closeOpenedPanels()),
  deselectAll: () =>
    dispatch(stateManagement.selection.actions.clearSelectedComponents()),
  sendBI: (event, parameters, siteId) =>
    dispatch(
      stateManagement.bi.actions.event(
        event,
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(
          {
            esi: util.editorModel.editorSessionId,
            site_id: siteId,
          },
          parameters,
        ),
      ),
    ),
  openUnpublishPanel: (lang) =>
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        'panels.focusPanels.languageUnpublishPanel',
        { lang },
        false,
      ),
    ),
  openPublishSuccessPanel: (languageName) =>
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        'panels.focusPanels.languagePublishSuccessPanel',
        { languageName },
        false,
      ),
    ),
  openPanel: () => {
    return dispatch(
      stateManagement.multilingual.actions.sendOpenDashboardEventThunk(
        ownProps.biOrigin,
        MultilingualPages.MANAGE_LANGUAGES,
      ),
    );
  },
  openHelpCenter: () => {
    dispatch(
      openHelpCenter(helpIds.MULTILINGUAL.MY_SITE_LANGUAGES, undefined, {
        origin: 'manage_languages_top_bar_drop_menu',
      }),
    );
  },
});

export default {
  mapStateToProps,
  mapDispatchToProps,
};

import React from 'react';

import { hoc, cx, preventContextMenu, workspace } from '#packages/util';
import { Divider } from '@wix/wix-base-ui';
import OpenedLeftPanels from '../openedLeftPanels/openedLeftPanels';
import LeftBarMenu from '../leftBarMenu/leftBarMenu';
import LeftBarOverlay from './leftBarOverlay';
import AiButton from '../aiButton/aiButton';
import LayersButton from '../layersButton/layersButton';

import type { EditorState } from '#packages/stateManagement';

import styles from './leftBar.scss';

import { mapStateToProps, mapDispatchToProps } from './leftBarMapper';

export interface LeftBarOwnProps {}

export interface LeftBarStateProps {
  hideTools: EditorState['hideTools'];
  isHighlighted: boolean;
  withOverlay: boolean;
  currentWorkspaceMode: string;
  isStageFixedWidth: boolean;
  shouldShowAiButton: boolean;
  shouldShowLayersButton: boolean;
}

export interface LeftBarDispatchProps {
  unhighlightMenu: () => void;
}

interface LeftBarProps
  extends LeftBarOwnProps,
    LeftBarStateProps,
    LeftBarDispatchProps {}

const LeftBar: React.FC<LeftBarProps> = (props) => {
  const { shouldShowAiButton, shouldShowLayersButton } = props;

  const layersButtonElement = (
    <React.Fragment>
      <Divider long />
      <LayersButton />
    </React.Fragment>
  );

  return (
    <>
      {props.withOverlay ? (
        <LeftBarOverlay
          withoutShadow={workspace.isNewWorkspaceFTETourEnabled()}
          onOuterClick={props.unhighlightMenu}
        />
      ) : null}
      <div
        onContextMenu={preventContextMenu}
        className={cx({
          [styles.wrapper]: true,
          [styles.hidden]: props.hideTools,
        })}
        data-hook={'left-bar-container'}
        key={props.currentWorkspaceMode}
      >
        <div className={styles.bar}>
          <LeftBarMenu isHighlighted={props.isHighlighted} />
          {shouldShowAiButton && <AiButton />}
          {shouldShowLayersButton && layersButtonElement}
        </div>
        <OpenedLeftPanels />
      </div>
    </>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LeftBar);

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';

const colorConvertion = util.colors;
const BASE_COLOR_TO_REPLACE = '#FFFFFF';
const BASE_REPLACE_COLOR_1 = '#eff2f6';
const BASE_REPLACE_COLOR_2 = '#EFF3F6';

const colorToReplaceBaseOnCategory = {
  button: BASE_REPLACE_COLOR_1,
  text: BASE_REPLACE_COLOR_1,
  menu: BASE_REPLACE_COLOR_1,
  contact: BASE_REPLACE_COLOR_1,

  gallery: BASE_REPLACE_COLOR_2,
  box: BASE_REPLACE_COLOR_2,
  shape: BASE_REPLACE_COLOR_2,
  music: BASE_REPLACE_COLOR_2,
};

function isHexColor(hexColor) {
  return !!hexColor && /^#(([0-9|a-f|A-F]){3}){1,2}$/.test(hexColor);
}

function isRgbColor(value) {
  if (value === null) {
    return { isRgb: false };
  }
  const split = value.replace(/[^0-9,\.]/g, '').split(',');

  if (split.length !== 3 && split.length !== 4) {
    return { isRgb: false };
  }

  const alpha = split[3];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every
  const validRgb = _.every(split.slice(0, 3), function (number) {
    return number !== '' && number >= 0 && number <= 255;
  });

  if (!validRgb) {
    return { isRgb: false };
  }

  if (alpha && !(alpha >= 0 && alpha <= 1)) {
    return { isRgb: false };
  }

  return {
    isRgb: true,
    rgbArr: split,
  };
}

function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

function rgbToHex(rgbArr) {
  return `#${componentToHex(parseInt(rgbArr[0], 10))}${componentToHex(
    parseInt(rgbArr[1], 10),
  )}${componentToHex(parseInt(rgbArr[2], 10))}`;
}

function getCssColor(color) {
  if (isHexColor(color)) {
    return color;
  }
  const colorArr = color.split(',');
  return rgbToHex(colorArr);
}

function checkFontColorAndReplaceIfNeeded(colorName, themeAPI, categoryId) {
  let color = colorName;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(colorName, 'color_')) {
    color = getCssColor(themeAPI.colors.get(colorName));
  }
  const hexVal = getColorTeReplaceIfNeeded(color, categoryId);
  if (hexVal?.wasReplaced) {
    return hexVal.value;
  }
  return color;
}

function handleExceedingFontForTextComp(
  categoryId,
  maxFontSize,
  compDef,
  previewJson,
  themeAPI,
  langCode,
) {
  const compText = compDef.data.text;
  const indexOfFont = compText.indexOf('font_');
  const themedFont = compText.substr(indexOfFont, 6);
  const fontVal = themeAPI.fonts.get(themedFont);
  const fontSize = getFontSizeFromFontStr(fontVal);
  const colorName = fontVal.split(' ')[5];
  let fontStr;
  let colorStyle;
  let styleStr;
  if (fontSize > maxFontSize) {
    fontStr = changeFontStringToMaxFont(fontVal, maxFontSize, langCode);
    fontStr = fontStr.substring(0, fontStr.indexOf('{'));
    fontStr = fontStr.replace(/\+/g, ' ');
    colorStyle = checkFontColorAndReplaceIfNeeded(
      colorName,
      themeAPI,
      categoryId,
    );
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (!_.includes(colorName, 'color_')) {
    const hexVal = getColorTeReplaceIfNeeded(colorName);
    if (hexVal?.wasReplaced) {
      colorStyle = hexVal.value;
      const arr = fontVal.split(' ').splice(-1, 1);
      fontStr = arr.join(' ');
    }
  } else if (fontSize <= maxFontSize) {
    colorStyle = checkFontColorAndReplaceIfNeeded(
      colorName,
      themeAPI,
      categoryId,
    );
  }
  if (fontStr) {
    styleStr = compText.replace(
      `class="${themedFont}"`,
      `style="color:${colorStyle};font:${fontStr}"`,
    );
    previewJson.data[compDef.dataId].text = styleStr;
  }
}

function changeBordersNotDividingInScale(styleObject, scalePropY) {
  const borderProps = ['brw', 'brwT', 'brwB', 'brwH', 'sz1', 'sz2', 'sz3'];
  if (
    !styleObject ||
    !styleObject.style ||
    !styleObject.style.properties ||
    !scalePropY ||
    scalePropY === 1
  ) {
    return {};
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    styleObject.style.properties,
    function (result, propVal, propKey) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(borderProps, propKey)) {
        const currBorder = parseInt(propVal, 10);
        if (currBorder % (1 / scalePropY)) {
          let newBrd = currBorder + (currBorder % (1 / scalePropY));
          if (newBrd > 8) {
            // limit the max border so it will look good on add panel
            newBrd = 8;
          }
          result[propKey] = `${newBrd}px`;
        }
      }
      return result;
    },
    {},
  );
}

/*we want to limit the shadow in the add panel so the shadow will not exeed normal comp borders (offset - 10 , blur - 15, distance -15)*/
function limitShadowOnAddPanel(styleObject) {
  const shadeProps = [
    'shd',
    'ishd',
    'bls_shd',
    'bls_shd_input',
    'bls_shd_subscribe_button',
    'bls_shd_input_rtl',
    'llf_shd',
    'llt_shd',
    'shadow',
    'shd1',
    'boxShadow',
    'insetShadow',
    'dropShadow',
  ];
  if (!styleObject || !styleObject.style || !styleObject.style.properties) {
    return {};
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    styleObject.style.properties,
    function (result, propVal, propKey) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      if (_.includes(shadeProps, propKey)) {
        const shadowArr = propVal.split(' ');
        const offsetX = parseInt(shadowArr[0], 10);
        const offsetY = parseInt(shadowArr[1], 10);
        const blur = parseInt(shadowArr[2], 10);
        const spread = parseInt(shadowArr[3], 10);
        if (offsetX > 10 || offsetX < -10) {
          shadowArr[0] = offsetX > 10 ? '10px' : '-10px';
        }
        if (offsetY > 10 || offsetY < -10) {
          shadowArr[1] = offsetY > 10 ? '10px' : '-10px';
        }
        if (blur > 15) {
          shadowArr[2] = '15px';
        }
        if (spread > 10) {
          shadowArr[3] = '10px';
        }
        result[propKey] = shadowArr.join(' ');
      }
      return result;
    },
    {},
  );
}

function getFontExceedingFromMaxSize(styleObject, maxFontSize, themeAPI) {
  if (!maxFontSize) {
    return {};
  }

  if (!styleObject || !styleObject.style || !styleObject.style.properties) {
    return {};
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    styleObject.style.properties,
    function (result, propVal, propKey) {
      const hasThemedFontProp =
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        typeof propVal === 'string' && _.includes(propVal, 'font_');
      const hasValueFontProps =
        typeof propVal === 'string' ? propVal.split(' ').length > 4 : false;
      if (hasThemedFontProp || hasValueFontProps) {
        const fontVal = hasValueFontProps
          ? propVal
          : themeAPI.fonts.get(propVal);
        const fontSize = getFontSizeFromFontStr(fontVal);
        if (fontSize > maxFontSize) {
          result[propKey] = fontVal;
        }
      }
      return result;
    },
    {},
  );
}

function getFontSizeFromFontStr(fontVal) {
  return parseInt(fontVal.split(' ')[3].split('/')[0].replace('px', ''), 10);
}

function changeFontStringToMaxFont(fontVal, maxFontSize, langCode) {
  const fontArr = fontVal.split(' ');
  const sizeArr = fontArr[3].split('/');
  sizeArr[0] = `${maxFontSize}px`;
  fontArr[3] = sizeArr.join('/');
  const fontFamily = fontArr[4];
  fontArr[4] = util.fontLangsFallbacks.getFontFallbackIfNeeded(
    langCode,
    fontFamily,
  );
  return fontArr.join(' ');
}

function generateCustomStyle(
  styleObject,
  fontsExceedFromMax,
  maxFontSize,
  bordersWithScaleNotDividedInScale,
  shadowWithLimitOnAddPanel,
  colorNeededToBeReplaced,
  langCode,
) {
  const clonedStyle = _.cloneDeep(styleObject);
  clonedStyle.id = _.uniqueId('previewStyle');
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(fontsExceedFromMax, function (propVal, propKey) {
    clonedStyle.style.properties[propKey] = changeFontStringToMaxFont(
      propVal,
      maxFontSize,
      langCode,
    );
  });
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(bordersWithScaleNotDividedInScale, function (propVal, propKey) {
    clonedStyle.style.properties[propKey] = propVal;
  });
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(shadowWithLimitOnAddPanel, function (propVal, propKey) {
    clonedStyle.style.properties[propKey] = propVal;
  });
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(colorNeededToBeReplaced, function (propVal, propKey) {
    clonedStyle.style.properties[propKey] = propVal;
  });
  return clonedStyle;
}

/*check the base color and 10% darker colors and replaces with the base replace color with the same brigntness - goal to identify all greys closer to white*/
function checkIfNeedToReplaceColorsCloseToWhite(color, categoryId) {
  for (let i = 0; i <= 10; i++) {
    const hsbColor = colorConvertion.hexToHsb(BASE_COLOR_TO_REPLACE);
    hsbColor.brightness -= i;
    const colorWithChangedBrightness = colorConvertion.hsbToHex(hsbColor);
    if (color.toLowerCase() === colorWithChangedBrightness.toLowerCase()) {
      const replaceColorHsb = colorConvertion.hexToHsb(
        colorToReplaceBaseOnCategory[categoryId] || BASE_REPLACE_COLOR_1,
      );
      replaceColorHsb.brightness -= i;
      return colorConvertion.hsbToHex(replaceColorHsb);
    }
  }
  return null;
}

/*checks all lighter colors that can't be seen on white - light yellow , light blue etc. and replace than with the same color but higher saturation*/
function checkIfNeedToReplaceLightColors(color) {
  const hsbColor = colorConvertion.hexToHsb(color);
  if (hsbColor.brightness === 100) {
    if (hsbColor.hue <= 250 && hsbColor.saturation < 10) {
      hsbColor.saturation = 10;
      return colorConvertion.hsbToHex(hsbColor);
    } else if (hsbColor.hue > 250 && hsbColor.saturation < 5) {
      hsbColor.saturation = 5;
      return colorConvertion.hsbToHex(hsbColor);
    }
  }
  return null;
}

function replaceColorToBlack() {
  return '#000000';
}

function getColorTeReplaceIfNeeded(color, categoryId) {
  const isHex = typeof color === 'string' && isHexColor(color);
  const rgbObj = typeof color === 'string' && isRgbColor(color);
  let hexVal;
  if (!rgbObj.isRgb && !isHex) {
    return null;
  }
  if (rgbObj.isRgb) {
    hexVal = rgbToHex(rgbObj.rgbArr.slice(0, 3));
  } else if (isHex) {
    hexVal = color;
  }
  let replaceColor;
  if (categoryId === 'text') {
    replaceColor = replaceColorToBlack();
  } else {
    replaceColor = checkIfNeedToReplaceColorsCloseToWhite(hexVal, categoryId);
  }
  if (!replaceColor) {
    replaceColor = checkIfNeedToReplaceLightColors(hexVal);
  }
  if (replaceColor) {
    return {
      value: replaceColor,
      wasReplaced: true,
    };
  }
  return null;
}

function changeColorsNotSuitableFotAddPanel(styleObject, categoryId) {
  if (!styleObject || !styleObject.style || !styleObject.style.properties) {
    return {};
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _.reduce(
    styleObject.style.properties,
    function (result, propVal, propKey) {
      const hexVal = getColorTeReplaceIfNeeded(propVal, categoryId);
      if (!hexVal) {
        return result;
      }
      if (hexVal.wasReplaced) {
        result[propKey] = hexVal.value;
      }
      return result;
    },
    {},
  );
}

export {
  handleExceedingFontForTextComp,
  changeBordersNotDividingInScale,
  limitShadowOnAddPanel,
  getFontExceedingFromMaxSize,
  generateCustomStyle,
  changeColorsNotSuitableFotAddPanel,
  getColorTeReplaceIfNeeded,
};

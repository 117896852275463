import { createMenuApi } from '../../API/menuAPI';
import { createMenuSetFromPagesPanelData } from './utils';
import { PAGES_MENU_ID } from '../../constants';

import { cleanId } from '../../utils/utils';

import type { CompStructure } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import { isCustomMenusEnabled, isMainMenuFlowEnabled } from '#packages/util';

function getMenuId(
  editorAPI: EditorAPI,
  isMainMenu = false,
  originMenuId?: string,
): string {
  const menuAPI = createMenuApi(editorAPI);
  const customMenus = menuAPI.getCustomMenus();

  if (isMainMenuFlowEnabled()) {
    const hasMenuComponentsOnDesktop =
      menuAPI.getMenuComponentsWithCustomMenus().length > 0;

    const mainMenu = menuAPI.getMainMenu();

    if (isMainMenu || !hasMenuComponentsOnDesktop) {
      return mainMenu.id;
    }

    // For some reason some menu components have attached "CUSTOM_MAIN_MENU". WTF
    if (originMenuId && originMenuId !== PAGES_MENU_ID) {
      return menuAPI.duplicateMenu({ menuId: originMenuId, itemsOnly: true });
    }

    return menuAPI.createCustomMenu();
  }

  if (customMenus.length === 0) {
    return createMenuSetFromPagesPanelData(editorAPI);
  }

  return customMenus[0].id;
}

export const connectMainMenuToComponent = (
  editorAPI: EditorAPI,
  componentStructure: CompStructure,
): CompStructure => {
  const mainMenuId = isCustomMenusEnabled()
    ? getMenuId(editorAPI, true)
    : PAGES_MENU_ID;

  componentStructure.data = {
    ...componentStructure.data,
    menuRef: `#${mainMenuId}`,
  };

  return componentStructure;
};

export const adaptMenuComponentStructure: (
  editorAPI: EditorAPI,
  componentStructure: CompStructure,
) => CompStructure = (editorAPI, componentStructure) => {
  const menuAPI = createMenuApi(editorAPI);
  const originalMenuRef = componentStructure?.data?.menuRef;
  const isAdvancedMenu =
    originalMenuRef &&
    editorAPI.temporaryAdvancedMenuAPI?.isAdvancedByMenuRef(originalMenuRef);

  if (isAdvancedMenu || isCustomMenusEnabled()) {
    const originalMenuId = originalMenuRef ? cleanId(originalMenuRef) : null;

    const newMenuId = isAdvancedMenu
      ? menuAPI.duplicateMenu({
          menuId: originalMenuId,
          itemsOnly: false,
        })
      : getMenuId(editorAPI, false, originalMenuId);

    componentStructure.data = {
      menuRef: `#${newMenuId}`,
      type: 'CustomMenuDataRef',
    };
  } else if (!originalMenuRef) {
    componentStructure.data = {
      ...componentStructure.data,
      menuRef: `#${PAGES_MENU_ID}`,
      type: 'CustomMenuDataRef',
    };
  }

  return componentStructure;
};

// import * as commonKeyboardActions from '../util/commonKeyboardActions';
import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import * as coreBi from '#packages/coreBi';
import { bi } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

const ADD_SECTION_PANEL_SHORTCUTS_CONFIG = {};
const keyboardMap = keyboardShortcutsActionMap.editor;

function addAddSectionPanelModeShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};

  keysToMerge[keyboardMap.CLOSE_PANELS.key] = () => {
    if (editorAPI.zoomMode.isZoomedByUser()) {
      editorAPI.zoomMode.exitZoomMode({
        zoomScale: 0.5,
        biParams: { origin: 'keyboard' },
        clickedByUser: editorAPI.zoomMode.isZoomedByUser(),
      });
    } else {
      editorAPI.zoomMode.exitZoomMode({ biParams: { origin: 'keyboard' } });
    }

    editorAPI.store.dispatch(
      bi.actions.event(coreBi.events.editor.ESCAPE_BUTTON_CLICKED, {
        mode: 'add_section_panel',
      }),
    );
  };
  Object.assign(ADD_SECTION_PANEL_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addAddSectionPanelModeShortcuts(editorAPI);
  },
  shortcuts: ADD_SECTION_PANEL_SHORTCUTS_CONFIG,
};

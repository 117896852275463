import { sections } from '#packages/util';
import { applyBigElementsLogicIfNeeded } from '../../../utils';
import { isSectionLike } from '../../../api';
import { sectionsService } from '#packages/platform';

import type { ComponentAddedToStageData } from '#packages/components';
import type { Scope } from '#packages/sections';

export const handleComponentAddedToStage = async (
  scope: Scope,
  data: ComponentAddedToStageData,
) => {
  const { components, editorAPI, history } = scope;
  const { compRef } = data;

  // Cannot add into ref component
  const ancestors = editorAPI.dsRead.components.getAncestors(compRef);
  const sectionAncestorIndex = ancestors.findIndex((ancestor) =>
    isSectionLike(scope, ancestor),
  );
  if (sectionAncestorIndex < 0) return;
  const isSectionDirectAncestor = isSectionLike(
    scope,
    components.getContainer(compRef),
  );

  const sectionRef = ancestors[sectionAncestorIndex];
  // rearrangeNewSectionElement / applyBigElementsLogicIfNeeded works with the child of the section, even if the added component is nested under it.
  const ancestorChildOfSection = ancestors[sectionAncestorIndex - 1] || compRef;

  const isFWE = components.is.fullWidth(compRef);
  if (data.type === 'drag' && isFWE) {
    await sections.rearrangeNewSectionElement(editorAPI, {
      component: ancestorChildOfSection,
      container: sectionRef,
      mouseY: data.mousePosition.y,
    });
    history.amend();
    scope.hooks.compAttachedToSection.fire({
      compRef,
    });
  }

  scope.enforcement.setEnforcementEnabled(false);

  if (!sectionsService.isAddingUnifiedWidget()) {
    await applyBigElementsLogicIfNeeded(
      scope,
      ancestorChildOfSection,
      isSectionDirectAncestor,
    ).finally(() => {
      scope.enforcement.setEnforcementEnabled(true);
    });
  }
};

import { i18n } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';

import { getTextComponentStructure } from './textComponentForWelcomeTour';
import { calcPolygon } from './calcPolygon';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
interface ComponentLayout {
  x: number;
  y: number;
  width: number;
  height: number;
}
type ComponentPosition = Pick<ComponentLayout, 'x' | 'y'>;
type PreviewPosition = Pick<DOMRect, 'top' | 'left' | 'height' | 'width'>;
const getPageWidth = () =>
  window.innerWidth ||
  window.document.documentElement.clientWidth ||
  window.document.body.clientWidth;
const getPageHeight = () =>
  window.innerHeight ||
  window.document.documentElement.clientHeight ||
  window.document.body.clientHeight;
const TOOLTIP_WIDTH = 288;
const TOOLTIP_HEIGHT = 410;
const TOP_BAR = 90;
const BOTTOM_BAR = 55;
const STAGE_RIGHT_MARGIN = 40;
const TEXT_COMP_TYPE = 'wysiwyg.viewer.components.WRichText';
const DISABLE_BACKDROP_EVENTS = 'tour-backdrop-disable-pointer-events';
let textComponentToDelete: CompRef;

export enum OpenAgainStates {
  SHOULD_OPEN_AGAIN = 'shouldOpenAgain',
  SHOULD_NOT_OPEN_AGAIN = 'shouldNotOpenAgain',
  NEVER_OPENED = 'neverOpened',
}

const findFirstComponentOnScreen = (
  editorAPI: EditorAPI,
  components: CompRef[],
): CompRef => {
  const componentsWithLayouts = components.map((component) => ({
    ...component,
    layout:
      editorAPI.components.layout.getRelativeToScreenConsideringScroll(
        component,
      ),
  }));
  const [firstComponent] = componentsWithLayouts.sort(
    (aComponent, bComponent) => aComponent.layout.y - bComponent.layout.y,
  );

  delete firstComponent.layout;

  return firstComponent;
};

export const getFirstSectionComponentByTypes = (
  editorAPI: EditorAPI,
  compTypes: string[],
): CompRef | undefined => {
  const pageRef = editorAPI.pages.getCurrentPage();
  const [firstSection] = editorAPI.sections.getPageSections(pageRef);

  for (let i = 0; i < compTypes.length; i++) {
    const [component] = editorAPI.components.get.byType(
      compTypes[i],
      firstSection,
    );

    if (component) return component;
  }
};

const getFullPageHeight = (editorAPI: EditorAPI) =>
  editorAPI.components.layout.measure.getBoundingClientRect({
    id: 'masterPage',
    type: 'DESKTOP',
  }).height + STAGE_RIGHT_MARGIN;

export const getTooltipOffsetForHelpAction = (panelHelpSelector: string) => {
  const dropPanelHelp = window.document.querySelector(panelHelpSelector);
  const { left = 0 } = dropPanelHelp?.getBoundingClientRect() ?? {};
  const GAP = 46;
  const OFFSET = 24;
  const MENU_OFFSET = 435;

  if (getPageWidth() > left + MENU_OFFSET + TOOLTIP_WIDTH + GAP) {
    return { offsetX: OFFSET, offsetY: OFFSET };
  }

  return { offsetX: -MENU_OFFSET - TOOLTIP_WIDTH - GAP, offsetY: OFFSET };
};

export const getTooltipOffsetXForInitAction = () => {
  const BOTTOM_GAP = 170;
  const LEFT_GAP = 205;

  const left = TOOLTIP_WIDTH + LEFT_GAP;

  const offsetX = getPageWidth() > left ? getPageWidth() - left : 0;
  const offsetY = getPageHeight() - TOOLTIP_HEIGHT - BOTTOM_GAP;

  return { offsetY, offsetX };
};

export const getTooltipOffsetForQuickEditAction = () => {
  const offsetY = 115;
  const LEFT_GAP = 374;
  const offsetX = getPageWidth() - TOOLTIP_WIDTH - LEFT_GAP;

  return { offsetY, offsetX };
};

export const getTooltipOffsetForZoomOutAction = (editorAPI: EditorAPI) => {
  const MIN_WIDTH = 1530;

  return {
    ...(getPageWidth() < MIN_WIDTH && {
      offsetY: getTooltipOffsetYForZoomOutAction(editorAPI),
    }),
    offsetX: getTooltipOffsetXForInitAction().offsetX,
  };
};

const getTooltipOffsetYForZoomOutAction = (editorAPI: EditorAPI) => {
  const { height } =
    stateManagement.domMeasurements.selectors.getPreviewPosition(
      editorAPI.store.getState(),
    );

  return height > TOOLTIP_HEIGHT + BOTTOM_BAR
    ? height - TOOLTIP_HEIGHT - BOTTOM_BAR
    : TOP_BAR;
};

export const getTooltipOffsetYForStageAction = (editorAPI: EditorAPI) => {
  /**
   * Tourmaker does not respect the situation when
   * there is not enough vertical space for the tooltip to be rendered
   * -> we adjust position of it ourselves
   */
  const textComponent = getFirstTextComponentIfExists(editorAPI);

  if (!textComponent) {
    return { offsetY: 0 }; // If there is no text component on the stage we dont need to calculate an offset
  }

  const textLayout: ComponentLayout =
    editorAPI.components.layout.getRelativeToScreen(textComponent);
  const textComponentBottomY = textLayout.y + textLayout.height;
  const pageHeight = getFullPageHeight(editorAPI);
  const spaceUnderComponent = pageHeight - textComponentBottomY;
  const isEnoughSpace = spaceUnderComponent > TOOLTIP_HEIGHT;
  const offsetY = isEnoughSpace ? 0 : spaceUnderComponent - TOOLTIP_HEIGHT;

  return { offsetY };
};

export const getFirstTextComponent = async (
  editorAPI: EditorAPI,
): Promise<CompRef> => {
  return (
    getFirstTextComponentIfExists(editorAPI) ||
    (await addTextComponent(editorAPI))
  );
};

const getFirstTextComponentIfExists = (
  editorAPI: EditorAPI,
): CompRef | undefined => {
  const focusedPageId = editorAPI.pages.getFocusedPageId();
  const pageRef = editorAPI.dsRead.pages.getReference(focusedPageId);
  const textComponents =
    editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
      TEXT_COMP_TYPE,
      pageRef,
    );

  return textComponents.length < 2
    ? textComponents[0]
    : findFirstComponentOnScreen(editorAPI, textComponents);
};

export const addTextComponent = (
  editorAPI: EditorAPI,
  textPlaceholder = i18n.t('First-Time-Tour-Text-Placeholder-OnStage'),
): Promise<CompRef> =>
  new Promise((resolve) => {
    const componentStructure = getTextComponentStructure(textPlaceholder);

    editorAPI.components.add(
      editorAPI.pages.getCurrentPage(),
      componentStructure,
      undefined,
      (compRef: CompRef) => {
        textComponentToDelete = compRef;
        resolve(compRef);
      },
    );
  });

export const getTooltipOffsetXForLeftBarLabels = (selector: string) => {
  const labelElements = window.document.querySelectorAll(selector);
  const AMOUNT_OF_HIGHLIGHTED = 3;
  return (
    Array.from(labelElements)
      .slice(0, AMOUNT_OF_HIGHLIGHTED)
      .reduce((prevMax: number, element: Element) => {
        const { width } = element.getBoundingClientRect();

        return Math.max(width, prevMax);
      }, 0) + 50
  );
};

export const deleteTextComponentIfNeed = (editorAPI: EditorAPI) => {
  if (textComponentToDelete) {
    editorAPI.components.remove(textComponentToDelete);
    textComponentToDelete = null;
  }
};

const TOP_SPACE = 17;

export const getSectionsBackdropClipPath = (
  editorAPI: EditorAPI,
  componentsToHighlight: CompRef[],
  topPadding = TOP_SPACE,
  ledge = 0,
) => {
  const [header] = componentsToHighlight;

  const headerLayout: ComponentLayout =
    editorAPI.components.layout.getRelativeToScreen(header);
  const height = componentsToHighlight.reduce(
    (acc, curr) =>
      editorAPI.components.layout.getRelativeToScreen(curr).height + acc,
    0,
  );

  // scroll component to view port and return new position
  const headerPositionsRelativeToViewport: ComponentPosition =
    scrollCompToViewport(editorAPI, headerLayout);

  const LEFT_BAR = 40;
  const y =
    headerPositionsRelativeToViewport.y -
    (headerPositionsRelativeToViewport.y - TOP_BAR) / 2;
  const top = y > TOP_BAR ? y + topPadding : TOP_BAR + topPadding;

  return calcPolygon(
    top,
    headerPositionsRelativeToViewport.x +
      (headerLayout.width + LEFT_BAR) / 4 -
      ledge,
    (headerLayout.width + LEFT_BAR) / 2 + 2 * ledge,
    height / 2,
  );
};

const scrollCompToViewport = (
  editorAPI: EditorAPI,
  compLayout: ComponentLayout,
): ComponentPosition => {
  const previewPosition: PreviewPosition =
    stateManagement.domMeasurements.selectors.getPreviewPosition(
      editorAPI.store.getState(),
    );
  const scrollTop = editorAPI.site.getScroll().y;
  const compPositionTop = compLayout.y + previewPosition.top;

  const topInvisibleDelta = scrollTop - compPositionTop;
  const bottomInvisibleDelta =
    compPositionTop + compLayout.height - getPageHeight() - scrollTop;

  let nextScrollTop = scrollTop;

  if (bottomInvisibleDelta > 0) {
    nextScrollTop = scrollTop + bottomInvisibleDelta;
  } else if (topInvisibleDelta > 0) {
    nextScrollTop = scrollTop - topInvisibleDelta;
  }

  if (nextScrollTop !== scrollTop) {
    editorAPI.scroll.scrollTo({
      scrollTop: nextScrollTop,
    });
  }

  return {
    x: compLayout.x + previewPosition.left,
    y: compPositionTop - nextScrollTop,
  };
};

export const removeBackdropClipPath = (backdropElementSelector: string) => {
  const backdropElement = window.document.querySelector<HTMLElement>(
    backdropElementSelector,
  );

  if (backdropElement) {
    backdropElement.style.clipPath = 'none';
  }
};

export const setShouldOpenAgainState =
  (editorAPI: EditorAPI, key: string) =>
  (value = false) => {
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        key,
        value,
      ),
    );
  };

export const getShouldOpenAgainState = (editorAPI: EditorAPI, key: string) => {
  const stateValue =
    stateManagement.userPreferences.selectors.getGlobalUserPreferences(key)(
      editorAPI.store.getState(),
    );
  if (typeof stateValue === 'boolean') {
    return stateValue
      ? OpenAgainStates.SHOULD_OPEN_AGAIN
      : OpenAgainStates.SHOULD_NOT_OPEN_AGAIN;
  }
  return OpenAgainStates.NEVER_OPENED;
};

export const scrollComponentToViewport = (
  editorAPI: EditorAPI,
  component: CompRef,
) => {
  const componentLayout: ComponentLayout =
    editorAPI.components.layout.getRelativeToScreen(component);

  return scrollCompToViewport(editorAPI, componentLayout);
};

export const getBackdropClipPath = (
  editorAPI: EditorAPI,
  componentToHighlight: CompRef,
) => {
  const componentLayout: ComponentLayout =
    editorAPI.components.layout.getRelativeToScreen(componentToHighlight);

  // scroll component to view port and return new position
  const componentPositionRelativeToViewport: ComponentPosition =
    scrollCompToViewport(editorAPI, componentLayout);

  // calculate a polygon with a rectangular hole inside
  return calcPolygon(
    componentPositionRelativeToViewport.y,
    componentPositionRelativeToViewport.x,
    componentLayout.width,
    componentLayout.height,
  );
};

export const setBackdropClipPath = (
  backdropElementSelector: string,
  polygon: string,
) => {
  const backdropElement = window.document.querySelector<HTMLElement>(
    backdropElementSelector,
  );

  if (backdropElement) {
    backdropElement.style.clipPath = polygon;
  }
};

export const setBackdropEventsState =
  (backdropWrapperSelector: string) =>
  (state = false) => {
    const backdropWrapper = document.querySelector(backdropWrapperSelector);

    if (state) {
      backdropWrapper?.classList.add(DISABLE_BACKDROP_EVENTS);
    } else {
      backdropWrapper?.classList.remove(DISABLE_BACKDROP_EVENTS);
    }
  };

export const getExitZoomMode =
  (editorAPI: EditorAPI, origin: string) => async () => {
    await editorAPI.zoomMode.exitZoomMode({
      biParams: { origin },
    });
  };

export const getEnterZoomMode =
  (editorAPI: EditorAPI, origin: string) => async () => {
    await editorAPI.zoomMode.enterZoomMode({
      biParams: { origin },
    });
  };

export const setHovered = (editorAPI: EditorAPI, section: CompRef) => {
  editorAPI.store.dispatch(
    stateManagement.sections.actions.setHoveredSectionLike(section),
  );
};

export const deselectComponents = (editorAPI: EditorAPI) => {
  editorAPI.selection.deselectComponents();
  setHovered(editorAPI, null);
};

export const selectComponent = async (
  editorAPI: EditorAPI,
  component: CompRef,
  origin: string,
) => {
  await editorAPI.selection.selectComponentByCompRef(component, {
    origin,
  });
};

export const sendBI = (
  editorAPI: EditorAPI,
  panelName: string,
  origin: string,
) => {
  editorAPI.bi.event(coreBi.events.panels.CLICK_OUTSIDE_PANEL, {
    panel_name: panelName,
    origin,
  });
};

export const getHighlightLeftBarMenu = (editorAPI: EditorAPI) => () => {
  editorAPI.store.dispatch(stateManagement.leftBar.actions.highlightMenu());
};
export const getUnHighlightLeftBarMenu = (editorAPI: EditorAPI) => () => {
  editorAPI.store.dispatch(stateManagement.leftBar.actions.unhighlightMenu());
};

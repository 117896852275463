import type constants from '#packages/constants';
import type { ScrollPosition } from 'types/core';
import type {
  CompRef,
  CompStructure,
  CompLayout,
  Point,
} from 'types/documentServices';

interface CopyMetaData {
  guid: string;
  copiedOnMobile: boolean;
  metaSiteId: string;
  siteUploadToken: string;
}

interface ClipboardCompData {
  compRef: CompRef;
  isShowOnAllPages: boolean;
  positionRelativeToSnug: Point;
}

export interface ClipboardCompValue {
  components: (CompStructure & { _dataId: string })[];
  componentsData: {
    [key: string]: ClipboardCompData;
  };
  originalScrollPosition: ScrollPosition;
  snugLayoutRelativeToStructure: CompLayout;
  theme: AnyFixMe;
}

interface ClipboardPageValue {
  page: CompStructure;
  theme: AnyFixMe;
}

export interface ClipboardCompItem {
  type: typeof constants.CLIPBOARD_ITEM_TYPE.COMPONENT;
  metaData: CopyMetaData;
  value: ClipboardCompValue;
}

export interface ClipboardPageItem {
  type: typeof constants.CLIPBOARD_ITEM_TYPE.PAGE;
  metaData: CopyMetaData;
  value: ClipboardPageValue;
}

export type ClipboardItem = ClipboardCompItem | ClipboardPageItem | object;

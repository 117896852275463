import {
  AssistantAction,
  type IAssistantAction,
  type IAssistantTask,
} from '@wix/tour-maker';
import { EditorAPIKey } from '#packages/apis';
import constants from '#packages/constants';
import { getStepName } from '../utils/getStepName';
import {
  getTooltipOffsetForZoomOutAction,
  getTooltipOffsetXForLeftBarLabels,
  getFirstSectionComponentByTypes,
  getTooltipOffsetYForStageAction,
  getTooltipOffsetXForInitAction,
  getTooltipOffsetForHelpAction,
  getSectionsBackdropClipPath,
  deleteTextComponentIfNeed,
  scrollComponentToViewport,
  getUnHighlightLeftBarMenu,
  getHighlightLeftBarMenu,
  setShouldOpenAgainState,
  setBackdropEventsState,
  removeBackdropClipPath,
  setBackdropClipPath,
  getBackdropClipPath,
  deselectComponents,
  addTextComponent,
  getExitZoomMode,
  selectComponent,
  setHovered,
  sendBI,
} from '../utils/stageUtils';
import { getClickListener, manageAnimation, createActions } from './util';
import { HOOKS, MEDIA, componentTypes } from './constants';
import * as util from '#packages/util';
import type { Shell } from '#packages/apilib';
import { StartOrigin } from './tours.types';
import { exitModes } from '../utils/modesUtils';
import { ToursApiKey } from '../api';

export const TOUR_NAME = 'adiToEditorTour';

const currentLanguage = util.languages.getLanguageCode();
const LEARN_MORE_LINK = {
  href: `https://support.wix.com/${currentLanguage}/article/wix-editor-your-new-site-builder`,
  text: 'FTE_tour_adi_to_ed2_help_learn_more_cta',
};

export const createAdiToEditorTour = (
  shell: Shell,
  startOrigin?: StartOrigin,
): IAssistantTask => {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const tourAPI = shell.getAPI(ToursApiKey);

  const disableEvents = setBackdropEventsState(HOOKS.tourBackdropWrapper);
  const setShouldOpenAgain = setShouldOpenAgainState(
    editorAPI,
    constants.USER_PREFS.SECTIONS_MIGRATION.SHOW_ADI_TO_EDITOR_TOUR,
  );
  const exitZoomMode = getExitZoomMode(editorAPI, TOUR_NAME);
  const highlightLeftBarMenu = getHighlightLeftBarMenu(editorAPI);
  const unHighlightLeftBarMenu = getUnHighlightLeftBarMenu(editorAPI);

  const backDropClickAnimation = (panelName: string) => {
    sendBI(editorAPI, panelName, TOUR_NAME);
    manageAnimation(HOOKS.tourTooltipWrapper, 'tour-card-shake-animation');
  };

  const getBackDropClickListener = getClickListener(HOOKS.tourBackdropWrapper);

  const isHelpMenuStart = startOrigin === StartOrigin.HelpMenu;

  const initAction = (index: number, lastStepIndex: number): IAssistantAction =>
    new AssistantAction('init')
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .withoutHighlight()
      .hook(HOOKS.deviceSwitch)
      .and.withBackdrop()
      .thatIsOff()
      .and.withCallbacks()
      .onEnter(async () => {
        util.keyboardShortcuts.disable();
        unHighlightLeftBarMenu();
      })
      .onExit(async () => {
        await exitModes(editorAPI);
        disableEvents(false);
        util.keyboardShortcuts.enable();
      })
      .and.withTooltip()
      .withTitle('FTE_tour_adi_to_ed2_welcome_title')
      .thatDoesntPreserveBackground()
      .withContent('FTE_tour_adi_to_ed2_welcome_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('startOrigin', startOrigin)
      .withCustomProp('stepName', getStepName(TOUR_NAME, 'addPanel', index))
      .withCustomProp(
        'className',
        'tour-tooltip-opens-down tour-tooltip-panel-intro-step',
      )
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp('mediaSrc', MEDIA.intro)
      .thatOpensDown(getTooltipOffsetXForInitAction())
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .withButton('forwardButton')
      .label('FTE_tour_adi_to_ed2_welcome_start_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_adi_to_ed2_welcome_skip_button')
      .completeOnClick()
      .callback(() => setShouldOpenAgain(true))
      .build();

  const panelAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'pagesPanel';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .hook(HOOKS.addPanel)
      .and.withBehaviors()
      .thatDelays(1000)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.addSection)
      .withAdditionalHighlight(HOOKS.pagesPanel)
      .withAdditionalHighlight(HOOKS.addPanel)
      .withAdditionalHighlight(HOOKS.addSectionLabel)
      .withAdditionalHighlight(HOOKS.pagesPanelLabel)
      .withAdditionalHighlight(HOOKS.addPanelLabel)
      .and.withBehaviors()
      .thatDoesntScrollToElement()
      .and.withBackdrop()
      .thatIsOn()
      .and.withCallbacks()
      .onEnter(async () => {
        util.keyboardShortcuts.disable();
        highlightLeftBarMenu();
        disableEvents(true);
        backDropClickListener.add();
        deselectComponents(editorAPI);
      })
      .onExit(async () => {
        disableEvents(false);
        util.keyboardShortcuts.enable();
        backDropClickListener.remove();
      })
      .and.withTooltip()
      .withTitle('FTE_tour_adi_to_ed2_left_bar_title')
      .withContent('FTE_tour_adi_to_ed2_left_bar_body')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.add)
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_adi_to_ed2_panel_step_counter',
      )
      .thatOpens({
        horizontalDirection: 'right',
        verticalDirection: 'down',
        alignTo: 'top',
        offsetX: getTooltipOffsetXForLeftBarLabels(HOOKS.leftBarLabel),
      })
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(() => {
        if (isHelpMenuStart) {
          highlightLeftBarMenu();
        } else {
          unHighlightLeftBarMenu();
        }
      })
      .withButton('forwardButton')
      .label('FTE_tour_adi_to_ed2_panel_next_button')
      .callback(unHighlightLeftBarMenu)
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_adi_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .build();
  };

  const zoomOutAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'zoomOut';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .hook(HOOKS.zoom)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.actionsBar)
      .withDisablePointerEvents()
      .and.withBehaviors()
      .thatDelays(1000)
      .thatDoesntScrollToElement()
      .and.withCallbacks()
      .onEnter(async () => {
        const pageRef = editorAPI.pages.getCurrentPage();
        const [firstSection, ...restSections] =
          editorAPI.sections.getPageSections(pageRef);
        const header = editorAPI.dsRead.siteSegments.getHeader();
        const footer = editorAPI.dsRead.siteSegments.getFooter();
        await selectComponent(editorAPI, firstSection, TOUR_NAME);
        setHovered(editorAPI, firstSection);
        setBackdropClipPath(
          HOOKS.tourBackdrop,
          getSectionsBackdropClipPath(editorAPI, [
            header,
            firstSection,
            ...restSections,
            footer,
          ]),
        );

        editorAPI.zoomMode.enterZoomMode({
          biParams: { origin: 'AdiToEditorTour' },
        });

        util.keyboardShortcuts.disable();
        disableEvents(true);
        backDropClickListener.add();
      })
      .onExit(async () => {
        removeBackdropClipPath(HOOKS.tourBackdrop);
        await exitZoomMode();
        deselectComponents(editorAPI);
        util.keyboardShortcuts.enable();
        disableEvents(false);
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('FTE_tour_adi_to_ed2_zoom_title')
      .withContent('FTE_tour_adi_to_ed2_zoom_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.zoomOut)
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_adi_to_ed2_panel_step_counter',
      )
      .thatOpensDown(getTooltipOffsetForZoomOutAction(editorAPI))
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(() => {
        if (isHelpMenuStart) {
          deselectComponents(editorAPI);
          highlightLeftBarMenu();
        }
      })
      .withButton('forwardButton')
      .label('FTE_tour_adi_to_ed2_panel_next_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_adi_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .build();
  };

  const dragAndDropAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'dragAndDrop';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .withoutHighlight()
      .hook(HOOKS.zoom)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.actionsBar)
      .withAdditionalHighlight(HOOKS.addSectionButtonContainer)
      .withAdditionalHighlight(HOOKS.sectionNameLabel)
      .withAdditionalHighlight(HOOKS.sectionEdgeResizeButton)
      .and.withBehaviors()
      .thatDelays(1000)
      .and.withCallbacks()
      .onEnter(async () => {
        util.keyboardShortcuts.disable();
        const pageRef = editorAPI.pages.getCurrentPage();
        const [firstSection] = editorAPI.sections.getPageSections(pageRef);

        setBackdropClipPath(
          HOOKS.tourBackdrop,
          getBackdropClipPath(editorAPI, firstSection),
        );
        disableEvents(true);
        backDropClickListener.add();

        const component =
          getFirstSectionComponentByTypes(editorAPI, componentTypes) ||
          (await addTextComponent(editorAPI));

        await selectComponent(editorAPI, component, TOUR_NAME);

        scrollComponentToViewport(editorAPI, component);
      })
      .onExit(() => {
        deleteTextComponentIfNeed(editorAPI);
        removeBackdropClipPath(HOOKS.tourBackdrop);
        deselectComponents(editorAPI);
        util.keyboardShortcuts.enable();
        disableEvents(false);
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('FTE_tour_adi_to_ed2_drag_title')
      .withContent('FTE_tour_adi_to_ed2_drag_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.dragRotate)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_adi_to_ed2_panel_step_counter',
      )
      .thatOpensDown({ offsetY: 11, offsetX: 30 })
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(() => {
        if (isHelpMenuStart) {
          deselectComponents(editorAPI);
          highlightLeftBarMenu();
        }
      })
      .withButton('forwardButton')
      .label('FTE_tour_adi_to_ed2_panel_next_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_adi_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .build();
  };

  const gfppAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'gfppButtons';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .hook(HOOKS.gfppButtons)
      .and.withBehaviors()
      .thatDoesntScrollToElement()
      .and.withCallbacks()
      .onEnter(async () => {
        unHighlightLeftBarMenu();
        const component =
          getFirstSectionComponentByTypes(editorAPI, componentTypes) ||
          (await addTextComponent(editorAPI));

        await selectComponent(editorAPI, component, TOUR_NAME);
        disableEvents(true);
        await setBackdropClipPath(
          HOOKS.tourBackdrop,
          getBackdropClipPath(editorAPI, component),
        );
        util.keyboardShortcuts.disable();
        backDropClickListener.add();
      })
      .onExit(() => {
        deleteTextComponentIfNeed(editorAPI);
        removeBackdropClipPath(HOOKS.tourBackdrop);
        deselectComponents(editorAPI);
        util.keyboardShortcuts.enable();
        disableEvents(false);
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withCustomStage()
      .withDisablePointerEvents()
      .and.withTooltip()
      .withTitle('FTE_tour_adi_to_ed2_gfpp_title')
      .withContent('FTE_tour_adi_to_ed2_gfpp_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.gfpp)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_adi_to_ed2_panel_step_counter',
      )
      .thatOpensRight(getTooltipOffsetYForStageAction(editorAPI))
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(() => {
        if (isHelpMenuStart) {
          deselectComponents(editorAPI);
          highlightLeftBarMenu();
        }
      })
      .withButton('forwardButton')
      .label('FTE_tour_adi_to_ed2_gfpp_finish_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_adi_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .build();
  };

  const helpAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'help';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .withoutHighlight()
      .hook(HOOKS.deviceSwitch)
      .and.withCallbacks()
      .onEnter(async () => {
        deselectComponents(editorAPI);
        disableEvents(true);
        util.keyboardShortcuts.disable();
        backDropClickListener.add();
      })
      .onExit(() => {
        setShouldOpenAgain();
        util.keyboardShortcuts.setContext(
          util.keyboardShortcuts.CONTEXTS.EDITOR,
        );
        util.keyboardShortcuts.enable();
        disableEvents(false);
        highlightLeftBarMenu();
        setShouldOpenAgain();
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.helpMenuBarItem)
      .and.withTooltip()
      .withTitle('FTE_tour_adi_to_ed2_help_title')
      .withContent('FTE_tour_adi_to_ed2_help_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp(
        'className',
        'tour-tooltip-opens-right tour-tooltip-success',
      )
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp('successAnimation', true)
      .withCustomProp('link', LEARN_MORE_LINK)
      .thatOpensDown(getTooltipOffsetXForInitAction())
      .withButton('skipButton')
      .completeOnClick()
      .withButton('forwardButton')
      .label('FTE_tour_adi_to_ed2_help_finish_button')
      .completeOnClick()
      .build();
  };

  const skipAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'skip';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(() => {
      highlightLeftBarMenu();
      tourAPI.stopTour();
    });

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .hook(HOOKS.helpDropDown)
      .and.withCallbacks()
      .onEnter(async () => {
        deselectComponents(editorAPI);
        disableEvents(true);
        editorAPI.topBarMenuBar.openDropDown(
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
          constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_ADI_TO_EDITOR_TOUR,
          false,
          { origin: TOUR_NAME },
        );
        util.keyboardShortcuts.disable();
        backDropClickListener.add();
      })
      .onExit(() => {
        editorAPI.topBarMenuBar.closeDropDown(
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
          constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_ADI_TO_EDITOR_TOUR,
        );
        util.keyboardShortcuts.setContext(
          util.keyboardShortcuts.CONTEXTS.EDITOR,
        );
        util.keyboardShortcuts.enable();
        setShouldOpenAgain();
        backDropClickListener.remove();
        disableEvents(false);
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.helpMenuBarItem)
      .and.withTooltip()
      .withTitle('FTE_tour_adi_to_ed2_skipped_help_title')
      .withContent('FTE_tour_adi_to_ed2_skipped_help_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp('link', LEARN_MORE_LINK)
      .thatOpensRight(getTooltipOffsetForHelpAction(HOOKS.dropPanelHelp))
      .withButton('skipButton')
      .callback(highlightLeftBarMenu)
      .completeOnClick()
      .withButton('forwardButton')
      .label('FTE_tour_adi_to_ed2_help_finish_button')
      .callback(highlightLeftBarMenu)
      .completeOnClick()
      .withButton('backButton')
      .label('FTE_tour_adi_to_ed2_panel_back_button')
      .onClickGoTo(index - 2)
      .build();
  };

  const actionsList = [
    initAction,
    panelAction,
    zoomOutAction,
    dragAndDropAction,
    gfppAction,
    helpAction,
    skipAction,
  ];

  const actions = createActions(actionsList, actionsList.length);

  return {
    id: TOUR_NAME,
    onEnter: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: false });
        editorAPI.panelManager.closeAllPanels();
      },
    },
    onExit: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: true }, true);
        deleteTextComponentIfNeed(editorAPI);
      },
    },
    actions,
  } as IAssistantTask;
};

import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import _ from 'lodash';
import React from 'react';
import { translate } from '#packages/i18n';

interface ConstraintArea {
  top: number;
  left: number;
  height: number;
  width: number;
}

interface ConstraintAreaProps {
  area: ConstraintArea[];
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<ConstraintAreaProps>({
  displayName: 'constraintArea',
  mixins: [core.mixins.editorAPIMixin],
  getPosition(constraintArea: ConstraintArea): React.CSSProperties {
    return {
      top: constraintArea.top,
      left: constraintArea.left,
      height: constraintArea.height,
      width: constraintArea.width,
    };
  },
  render() {
    return (
      <div>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.area, (constraintArea) => (
          <div
            style={this.getPosition(constraintArea)}
            className="negative-constraint-indication constraint-area"
          >
            <div className="box">
              <label>{translate('mobilemenu_Settings_fixed_area_label')}</label>
            </div>
          </div>
        ))}
      </div>
    );
  },
});

import { backgroundUtils, colors } from '#packages/util';
import { EditorRestrictionsApiKey, FixedStageApiKey } from '#packages/apis';

import type { CSSProperties } from 'react';
import type { MapStateToProps } from 'types/redux';
import type { SectionEmptyStateOwnProps } from './SectionEmptyState';

const PERCEIVED_LIGHTNESS_THRESHOLD = 0.5;
const isLightColor = (color: string) =>
  colors.getLumaPerceivedLightness(color) > PERCEIVED_LIGHTNESS_THRESHOLD;

export interface SectionEmptyStateStateProps {
  isLightTextColor: boolean;
  isEmptyStateAddPanelVisible: boolean;
  isEmptyStateTitleTextVisible: boolean;
  style: CSSProperties;
}

export const mapStateToProps: MapStateToProps<
  SectionEmptyStateStateProps,
  SectionEmptyStateOwnProps
> = ({ editorAPI, dsRead }) => {
  const fixedStage = editorAPI.host.getAPI(FixedStageApiKey);
  const backgroundData = dsRead.pages.background.get(
    dsRead.pages.getCurrentPageId(),
    'desktop',
  );

  const pageBackgroundColor = backgroundUtils.getColorValue(
    editorAPI,
    backgroundData.ref.color,
  );

  const isLightTextColor = !isLightColor(pageBackgroundColor);

  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isEmptyStateAddPanelVisible = editorRestrictionsApi.allowed(
    'sections_empty-state-add-panel.visible',
  );
  const isEmptyStateTitleTextVisible = editorRestrictionsApi.allowed(
    'sections_empty-state-title-text.visible',
  );

  const centeredContentLeftOffset = fixedStage.getCenteredComponentsLeft(
    0.5,
    'none',
  );

  const styleForHorizontallyScrollableStage: CSSProperties = {
    margin: 0,
    position: 'absolute',
  };

  return {
    isLightTextColor,
    isEmptyStateAddPanelVisible,
    isEmptyStateTitleTextVisible,
    style: {
      ...centeredContentLeftOffset,
      ...(fixedStage.isStageHorizontallyScrollable()
        ? styleForHorizontallyScrollableStage
        : {}),
    },
  };
};

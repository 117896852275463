// @ts-nocheck
import createReactClass from 'create-react-class';
import React from 'react';
import * as BaseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'cannotAddList',
  render() {
    return (
      <div className="cannot-add-list">
        <BaseUI.symbol name="plaster" className="cannot-add-list-img" />
        <p className="error-title">
          {translate('add_category_list_error_CantAddList_Title')}
        </p>
        <p>{translate('add_category_list_error_CantAddList_Description')}</p>
        <a
          href="https://www.wix.com/support/html5/"
          target="_blank"
          className="support-link"
        >
          https://www.wix.com/support/
        </a>
      </div>
    );
  },
});

import React from 'react';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import { workspace } from '#packages/util';
import { frames } from '#packages/panels';
import { Composites, Illustration, RichText, Text } from '@wix/wix-base-ui';

interface PeleteInteractionMessageProps {
  panelName: string;
  onCancel(): void;
  onConfirm(): void;
}

export default class extends React.Component<PeleteInteractionMessageProps> {
  static displayName = 'deleteInteractionMessage';
  rootDataHook = 'delete-interaction-modal';

  renderInTheNewWorkspace = () => {
    return (
      <frames.MessagePanelFrame
        theme="destructive"
        panelName={this.props.panelName}
        title={translate('interactions_delete_popup_header')}
        confirmLabel={translate('interactions_delete_popup_primary_cta')}
        cancelLabel={translate('interactions_delete_popup_secondary_cta')}
        illustration={<baseUI.symbol name="deleteGeneralIcon_NewWorkspace" />}
        onConfirm={this.props.onConfirm}
        onCancel={this.props.onCancel}
        dataHook={this.rootDataHook}
      >
        <Text
          size="medium"
          weight="normal"
          skin="secondary"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {translate('interactions_delete_popup_text')}
        </Text>
      </frames.MessagePanelFrame>
    );
  };

  render() {
    return workspace.isNewWorkspaceEnabled() ? (
      this.renderInTheNewWorkspace()
    ) : (
      <frames.OldMessagePanelFrame
        panelName={this.props.panelName}
        onConfirm={this.props.onConfirm}
        onCancel={this.props.onCancel}
        confirmLabel="interactions_delete_popup_primary_cta"
        cancelLabel="interactions_delete_popup_secondary_cta"
        type="danger"
        panelTitle={translate('interactions_delete_popup_header')}
        className="delete-interaction-message"
        dataHook={this.rootDataHook}
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="deleteInteractionIllustration" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T01">
              {translate('interactions_delete_popup_text')}
            </RichText>
          </Composites.RichText>
        </Composites.RichTextWithIllustration>
      </frames.OldMessagePanelFrame>
    );
  }
}

// @ts-nocheck
import * as util from '#packages/util';
import editorActionsService from './editorActionsService';
import dataProvider from './dataProvider';

export default ({ editorAPI }) => {
  const actionService = editorActionsService({ editorAPI });

  function hasDeepLink() {
    return (
      util.url.getParameterByName('etpa') ===
        dataProvider.WIX_BOOKINGS_APP_DEF_ID &&
      util.url.getParameterByName('etpaMode') === 'blank'
    );
  }

  function getDeepLinkState() {
    return JSON.parse(util.url.getParameterByName('etpaState') || '{}');
  }

  function handle() {
    if (dataProvider.isWixBookingsInstalled(editorAPI) && hasDeepLink()) {
      handleDeepLink(getDeepLinkState());
    }
  }

  function handleDeepLink(etpaState) {
    if (isDashboardType(etpaState)) {
      actionService.openBackOfficeInEditorFromDeepLink({
        state: 'scheduler/owner/offerings',
        origin: 'wixbookings_deep_link',
      });
    }
  }

  function isDashboardType(etpaState) {
    return (
      !!etpaState && etpaState.type === 'wixbookings.openBookingsDashboard'
    );
  }

  return {
    handle,
  };
};

// @ts-nocheck
import appointmentFieldConstants from '../constants';
import * as appointmentFieldUtils from '../utils';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const getAppointmentFieldSection = (editorAPI) => ({
  appointmentFieldSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_heading_appointment',
    subNavigationTitle: 'add_section_label_appointment',
    sectionName: 'appointmentSection',
    automationId: 'add-panel-section-appointmentSection',
    appDefinitionId: appointmentFieldConstants.APPOINTMENT_FIELD_APP_ID,
    showSectionHeader: true,
    subNavigationHide: false,
    presetTitle: 'add_section_heading_appointment',
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/developer_en/appointmentFieldSection_en.png',
      imageHover: null,
      items: [
        {
          id: 'Appointment_Field_1',
          onDrop: appointmentFieldUtils.onAppointmentDrop(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET1,
          ),
          onClick: appointmentFieldUtils.onAppointmentClick(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET1,
          ),
          structure: appointmentFieldUtils.getAppointmentWidget({
            x: 100,
            y: 10,
            fixedPosition: false,
            width: 320,
            height: 300,
            scale: 1,
            rotationInDegrees: 0,
          }),
          preset: { rect: { width: 162, height: 145, x: 0, y: 0 }, tags: null },
        },
        {
          id: 'Appointment_Field_2',
          onDrop: appointmentFieldUtils.onAppointmentDrop(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET2,
          ),
          onClick: appointmentFieldUtils.onAppointmentClick(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET2,
          ),
          structure: appointmentFieldUtils.getAppointmentWidget({
            x: 200,
            y: 20,
            fixedPosition: false,
            width: 320,
            height: 300,
            scale: 1,
            rotationInDegrees: 0,
          }),
          preset: {
            rect: { width: 162, height: 145, x: 162, y: 0 },
            tags: null,
          },
        },
        {
          id: 'Appointment_Field_3',
          onDrop: appointmentFieldUtils.onAppointmentDrop(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET3,
          ),
          onClick: appointmentFieldUtils.onAppointmentClick(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET3,
          ),
          structure: appointmentFieldUtils.getAppointmentWidget({
            x: 300,
            y: 320,
            fixedPosition: false,
            width: 320,
            height: 95,
            scale: 1,
            rotationInDegrees: 0,
          }),
          preset: {
            rect: { width: 324, height: 95, x: 0, y: 145 },
            tags: null,
          },
        },
        {
          id: 'Appointment_Field_4',
          onDrop: appointmentFieldUtils.onAppointmentDrop(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET4,
          ),
          onClick: appointmentFieldUtils.onAppointmentClick(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET4,
          ),
          structure: appointmentFieldUtils.getAppointmentWidget({
            x: 0,
            y: 240,
            fixedPosition: false,
            width: 320,
            height: 95,
            scale: 1,
            rotationInDegrees: 0,
          }),
          preset: {
            rect: { width: 324, height: 95, x: 0, y: 240 },
            tags: null,
          },
        },
        {
          id: 'Appointment_Field_5',
          onDrop: appointmentFieldUtils.onAppointmentDrop(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET5,
          ),
          onClick: appointmentFieldUtils.onAppointmentClick(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET5,
          ),
          structure: appointmentFieldUtils.getAppointmentWidget({
            x: 0,
            y: 335,
            fixedPosition: false,
            width: 320,
            height: 300,
            scale: 1,
            rotationInDegrees: 0,
          }),
          preset: {
            rect: { width: 162, height: 180, x: 0, y: 335 },
            tags: null,
          },
        },
        {
          id: 'Appointment_Field_6',
          onDrop: appointmentFieldUtils.onAppointmentDrop(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET6,
          ),
          onClick: appointmentFieldUtils.onAppointmentClick(
            editorAPI,
            appointmentFieldConstants.AppointmentPreset.PRESET6,
          ),
          structure: appointmentFieldUtils.getAppointmentWidget({
            x: 0,
            y: 335,
            fixedPosition: false,
            width: 320,
            height: 300,
            scale: 1,
            rotationInDegrees: 0,
          }),
          preset: {
            rect: { width: 162, height: 180, x: 162, y: 335 },
            tags: null,
          },
        },
      ],
      compTypes: [appointmentFieldConstants.dummyComponentType],
    },
  },
});

export default (editorAPI) => getAppointmentFieldSection(editorAPI);

import { promiseUtils } from '#packages/util';
import * as coreBi from '#packages/coreBi';
import type { LegacyBiLogger } from 'types/bi';

const DS_SAVE_TIMEOUT_TO_REPORT = 10 /* sec */ * 1000;

export function initDsSaveTimeoutErrorReporting(
  dsSavePromise: Promise<void>,
  biLogger: LegacyBiLogger,
  options: {
    origin?: string;
    sourceOfStart?: string;
  },
) {
  promiseUtils
    .withPromiseTimeout(dsSavePromise, DS_SAVE_TIMEOUT_TO_REPORT)
    .catch((error) => {
      if (promiseUtils.isPromiseTimeoutError(error)) {
        biLogger.error(coreBi.errors.DS_SAVE_TIMEOUT_ERROR, {
          saveOptions: JSON.stringify(options),
        });
      }
    });
}

// @ts-nocheck
import _ from 'lodash';
import * as textControls from '#packages/textControls';
import * as tpaStyleService from '../../services/tpaStyleService';

const onStyleChange = function (
  editorAPI,
  compId,
  wixParam,
  callback,
  key,
  value,
  displayName,
) {
  const data = {
    key: wixParam,
    param: {
      value: getValueFromFontPicker(
        editorAPI,
        compId,
        wixParam,
        key,
        value,
        displayName,
      ),
    },
    type: 'font',
  };

  tpaStyleService.setStyleParam(editorAPI, compId, data);

  editorAPI.dsActions.waitForChangesApplied(function () {
    const compStyle = editorAPI.dsRead.tpa.style.get(compId);
    callback({
      fontParam: compStyle.style.fonts[data.key],
    });
  });
};

const onStyleChangeWithoutWixParam = function (
  editorAPI,
  initialStyleParams,
  callback,
  changeKey,
  changeValue,
  displayName,
) {
  initialStyleParams = _.isPlainObject(initialStyleParams)
    ? initialStyleParams
    : {};
  if (changeKey === 'theme') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      initialStyleParams,
      getThemeProps(editorAPI, changeValue, displayName),
    );
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  } else if (_.includes(['bold', 'italic', 'underline'], changeKey)) {
    _.set(initialStyleParams, ['style', changeKey], changeValue);
  } else {
    initialStyleParams[changeKey] = changeValue;
  }
  if (displayName) {
    initialStyleParams.displayName = displayName;
  } else {
    delete initialStyleParams.displayName;
  }
  callback({ fontParam: initialStyleParams });
};

const getThemeProps = function (editorAPI, presetKey, displayName) {
  const textPresets = editorAPI.dsRead.tpa.style.getNameToFontsKeyMap();
  const preset = textPresets[presetKey];
  // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
  const currFontString = editorAPI.dsRead.theme.fonts.get(presetKey);
  const fontObj = textControls.fontUtils.parseStyleFont(
    currFontString,
    // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
    editorAPI.dsRead.theme.fonts.getAll(),
    editorAPI.theme.colors.getAll(),
  );

  return {
    preset,
    editorKey: presetKey,
    displayName,
    size: fontObj.size,
    family: fontObj.family.toLowerCase(),
    style: {
      bold: fontObj.bold,
      italic: fontObj.italic,
      underline: false,
    },
  };
};

const getValueFromFontPicker = function (
  editorAPI,
  compId,
  param,
  key,
  value,
  displayName,
) {
  let fontParam = {
    style: {
      italic: false,
      bold: false,
      underline: false,
    },
    family: 'arial',
  };

  const compStyle = editorAPI.dsRead.tpa.style.get(compId);

  if (compStyle.style?.fonts?.[param]) {
    fontParam = compStyle.style.fonts[param];
    delete fontParam.displayName;
    delete fontParam.value;
  }

  switch (key) {
    case 'theme':
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(fontParam, getThemeProps(editorAPI, value, displayName));
      break;

    case 'family':
      fontParam.preset = 'Custom';
      fontParam.family = value;
      break;

    case 'htmlTag':
      fontParam.preset = 'Custom';
      fontParam.htmlTag = value;
      break;

    case 'size':
      fontParam.preset = 'Custom';
      fontParam.size = value;
      break;

    case 'bold':
      fontParam.preset = 'Custom';
      fontParam.style.bold = value;
      break;

    case 'italic':
      fontParam.preset = 'Custom';
      fontParam.style.italic = value;
      break;

    case 'underline':
      fontParam.preset = 'Custom';
      fontParam.style.underline = value;
      break;
  }

  fontParam.fontStyleParam = true;
  return fontParam;
};

export { onStyleChange, onStyleChangeWithoutWixParam };

import {
  gptInjectionToSiteInjectionStart,
  smartWriterCreateTextClick,
  smartWriterUseTextClick,
  aiTextGeneratorAdditionalActions,
  aiTextGeneratorNotificationShown,
  aiTextGeneratorSettingsChanged,
  aiTextGeneratorRequestOutput,
  aiTextGeneratorErrorEvent,
  textTextChanged,
  aiTextGeneratorPanelOpen,
} from '@wix/bi-logger-editor/v2';
import { biLogger, editorModel } from '#packages/util';
import type { BusinessType } from '#packages/siteGlobalData';
import type { QuestionnaireParams, TokenUsage } from './types';
import type { PanelTypes } from './constants';

const sendBIAdditionalAction = ({
  actionName,
  panelType,
}: {
  actionName: string;
  panelType: PanelTypes;
}) => {
  biLogger.report(
    aiTextGeneratorAdditionalActions({
      actionName,
      panelType,
    }),
  );
};

const sendBINotificationShown = ({
  fieldName,
  type,
  message,
  panelType,
}: {
  type: string;
  message: string;
  fieldName: string;
  panelType: PanelTypes;
}) => {
  biLogger.report(
    aiTextGeneratorNotificationShown({ fieldName, type, message, panelType }),
  );
};

const sendBISettingsChanged = ({
  fieldName,
  mandatoryField,
  newValue,
  panelType,
}: {
  fieldName: string;
  mandatoryField: string;
  newValue: string | BusinessType;
  panelType: PanelTypes;
}) => {
  biLogger.report(
    aiTextGeneratorSettingsChanged({
      fieldName,
      mandatoryField,
      newValue:
        typeof newValue === 'object' ? JSON.stringify(newValue) : newValue,
      panelType,
    }),
  );
};

const sendBICreateTextClick = ({
  success,
  query,
  panelType,
  actionOrigin,
}: {
  success: boolean;
  query: QuestionnaireParams;
  panelType: PanelTypes;
  actionOrigin: string;
}) =>
  biLogger.report(
    smartWriterCreateTextClick({
      isAiTextCreator: true,
      panelType,
      success,
      query: JSON.stringify(query),
      actionOrigin,
    }),
  );

const sendBIRequestOutput = ({
  duration,
  query,
  queryOutput,
  pageComponents,
  pageTokens,
  prompt,
  gptParams,
  panelType,
  actionOrigin,
}: {
  duration: number;
  query: QuestionnaireParams;
  queryOutput: object;
  pageComponents: { pageId: string; componentIds: string[] }[];
  pageTokens: { pageId: string; tokens: TokenUsage[] }[];
  prompt: string;
  gptParams: string;
  panelType: PanelTypes;
  actionOrigin: string;
}) => {
  biLogger.report(
    aiTextGeneratorRequestOutput({
      duration,
      panelType,
      query: JSON.stringify(query),
      query_output: JSON.stringify(queryOutput),
      pageComponents: JSON.stringify(pageComponents),
      pageTokens: JSON.stringify(pageTokens),
      prompt,
      gptParams,
      actionOrigin,
    }),
  );
};

const sendBIUseTextBI = ({
  componentIds,
  duration,
  panelType,
  actionOrigin,
}: {
  componentIds: string[];
  duration: number;
  panelType: PanelTypes;
  actionOrigin: string;
}) => {
  biLogger.report(
    smartWriterUseTextClick({
      isAiTextCreator: true,
      clickOrigin: 'autofill',
      panelType,
      component_id: JSON.stringify(componentIds),
      duration,
      actionOrigin,
    }),
  );
};

const sendBIError = ({
  query,
  errorMessage,
  gptParams,
  prompt,
  tokens,
  panelType,
}: {
  query: QuestionnaireParams;
  errorMessage: string;
  gptParams: string;
  prompt: string;
  tokens: TokenUsage[];
  panelType: PanelTypes;
}): void => {
  biLogger.report(
    aiTextGeneratorErrorEvent({
      query: JSON.stringify(query),
      errorMessage,
      panelType,
      gptParams,
      prompt,
      tokens: JSON.stringify(tokens),
    }),
  );
};

const sendBITextChanged = ({
  text,
  textOrigin,
  componentId,
  parentComponentId,
  componentType,
  pageId,
  parentComponentType,
  sectionType,
  actionOrigin,
}: {
  text: string;
  textOrigin: string;
  componentId: string;
  parentComponentId: string;
  componentType: string;
  pageId: string;
  parentComponentType: string;
  sectionType: string;
  actionOrigin: string;
}): void => {
  biLogger.report(
    textTextChanged({
      text,
      textOrigin,
      component_id: componentId,
      component_type: componentType,
      parentComponentId,
      parentComponentType,
      origin: 'autofill (injection to site)',
      pageId,
      sectionType,
      actionOrigin,
    }),
  );
};

const sendBIPanelOpen = ({
  query,
  panelType,
}: {
  query: QuestionnaireParams;
  panelType: PanelTypes;
}) => {
  biLogger.report(
    aiTextGeneratorPanelOpen({
      query: JSON.stringify(query),
      panelType,
    }),
  );
};

const sendBIStartSiteInjectionProcess = ({
  panelType,
  actionOrigin,
}: {
  panelType: PanelTypes;
  actionOrigin: string;
}) => {
  biLogger.report(
    gptInjectionToSiteInjectionStart({
      flowType: panelType,
      esi: editorModel.editorSessionId,
      actionOrigin,
    }),
  );
};

export {
  sendBIAdditionalAction,
  sendBINotificationShown,
  sendBISettingsChanged,
  sendBICreateTextClick,
  sendBIRequestOutput,
  sendBIUseTextBI,
  sendBIError,
  sendBITextChanged,
  sendBIPanelOpen,
  sendBIStartSiteInjectionProcess,
};

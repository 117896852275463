// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as blogUtils from '../../../../utils/blogUtils';

import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';

class DeleteOldBlogPanel extends React.Component {
  static displayName = 'DeleteOldBlogPanel';

  handleHelpClick = (e) => {
    e.preventDefault();
    this.props.openHelpCenter();
  };

  render() {
    return (
      <div className="delete-old-blog-panel">
        <symbols.symbol name="warning" className="warning" />
        <div className="box">
          <h4>{translate('SiteApp_Blog_Migration_Panel_Error_Title')}</h4>

          <p>
            {translate('SiteApp_Blog_Migration_Panel_Error_Description')}
            <span> </span>
            <a
              href="#"
              onClick={(e) => {
                this.handleHelpClick(e);
              }}
            >
              {translate('SiteApp_Blog_Migration_Panel_Error_LearnHow')}
            </a>
          </p>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  openHelpCenter: () =>
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        blogUtils.helpArticles.deleteOldBlog,
      ),
    ),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(DeleteOldBlogPanel);
ConnectedComponent.pure = DeleteOldBlogPanel;

export default ConnectedComponent;

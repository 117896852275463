import experiment from 'experiment';

export const isSectionizerEnabled = (): boolean =>
  experiment.isOpen('se_sectionizer');

export const isFullValidationEnabled = (): boolean =>
  experiment.isOpen('se_sectionsMigrationFullValidation');

export const isHiddenElementsValidationEnabled = (): boolean =>
  !experiment.isOpen('se_sectionsMigrationDisableHiddenValidation');

export const isPredictionDisabled = (): boolean =>
  experiment.isOpen('se_sectionsMigrationDisablePrediction');

export const isSimulateFailureEnabled = (): boolean =>
  experiment.isOpen('se_sectionsMigrationSimulateFailure');

export const isForceMigrationEnabled = (): boolean =>
  experiment.isOpen('se_sectionsForceMigration');

export const isSkippedFTE = (): boolean =>
  experiment.isOpen('se_sectionsMigrationSkipFTE');

export const isEnforceClosedGapsEnabled = (): boolean =>
  experiment.isOpen('se_sectionsMigrationEnforceClosedGaps');

import _ from 'lodash';
import { META_SITE } from '@wix/app-definition-ids';
import experiment from 'experiment';
import constants from '#packages/constants';
import { leftBar } from '#packages/stateManagement';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { isSupportedApp as isSupportedAppManagerApp } from '#packages/appManager';
import { getAppMarketPersistedUserPrefs } from '../appMarket/utils/appMarketUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { AppData } from 'types/documentServices';
import * as util from '#packages/util';
import { getAllAvailableToUpdateApps } from '#packages/privateAppsPanel';

const APP_MARKET_PANEL = 'tpa.compPanels.appMarketPanel';
const APP_MARKET_API_URL = '/_api/managed-apps/v1/complete-setup-count';

const omitRemovedPendingApps = (
  editorAPI: EditorAPI,
  pendingApps: AppData[],
) => {
  const appMarketPrefs: {
    removedPendingApps?: string[];
  } = getAppMarketPersistedUserPrefs(editorAPI);

  if (appMarketPrefs) {
    const removedPendingApps = appMarketPrefs.removedPendingApps || [];
    const pendingAppsMap = _(pendingApps)
      .keyBy('appDefinitionId')
      .omit(removedPendingApps)
      .value();

    pendingApps = Object.values(pendingAppsMap);
  }

  return pendingApps;
};

const getHybridPendingApps = (editorAPI: EditorAPI) => {
  const pendingApps = editorAPI.dsRead.tpa.getPendingApps();
  return omitRemovedPendingApps(editorAPI, pendingApps);
};

const getPremiumPendingApps = (editorAPI: EditorAPI) => {
  return editorAPI.dsRead.tpa.getPremiumPendingApps();
};

const getPendingApps = (
  editorAPI: EditorAPI,
  predicate?: (app: AppData) => boolean,
) => {
  const hybridPendingApps = getHybridPendingApps(editorAPI);
  const premiumPendingApps = getPremiumPendingApps(editorAPI);
  const allPending = _.union(hybridPendingApps, premiumPendingApps);
  return predicate && typeof predicate === 'function'
    ? allPending.filter(predicate)
    : allPending;
};

const getAllPendingAppDefIds = (editorAPI: EditorAPI) => {
  return (
    editorAPI.dsRead.tpa.pending
      .getPendingApps()
      .filter((app) =>
        experiment.isOpen('se_myBusinessDisablePendingAppsFilter')
          ? true
          : editorAPI.dsRead.tpa.app.getDataByAppDefId(app.appDefId),
      )
      // https://wix.slack.com/archives/C3T305VBR/p1605104995374900
      .filter((app) => !editorAPI.dsRead.platform.isAppActive(app.appDefId))
      .map((app) => app.appDefId)
  );
};

const getPendingAppsCount = (editorAPI: EditorAPI) =>
  getAllPendingAppDefIds(editorAPI).length;

const setPanelNotification = (
  editorAPI: EditorAPI,
  panelName: string,
  count: number,
) => {
  editorAPI.store.dispatch(
    leftBar.actions.setNotificationsCount(panelName, count),
  );
};

const setAppMarketNotifications = async (editorAPI: EditorAPI) => {
  const metaSiteInstance =
    editorAPI.dsRead.tpa.app.getDataByAppDefId(META_SITE)?.instance;
  try {
    const appMarketResponse = await util.http.fetchJson(
      `${APP_MARKET_API_URL}?hideWixApps=true`,
      {
        method: 'GET',
        headers: new Headers({ Authorization: metaSiteInstance }),
      },
    );
    setPanelNotification(
      editorAPI,
      APP_MARKET_PANEL,
      appMarketResponse.amountOfApps,
    );
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: { failedToFetchAppMarketCount: true },
    });
  }
};

const setAppManagerNotifications = (editorAPI: EditorAPI) => {
  const pendingApps = getAllPendingAppDefIds(editorAPI);
  const appsToUpdate = getAllAvailableToUpdateApps(editorAPI).map(
    ({ appDefinitionId }) => appDefinitionId,
  );
  const count = pendingApps
    .concat(appsToUpdate)
    .filter((appDefId) => isSupportedAppManagerApp(editorAPI, appDefId)).length;

  setPanelNotification(
    editorAPI,
    constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME,
    count,
  );
};

const updateNotification = function (editorAPI: EditorAPI) {
  setAppMarketNotifications(editorAPI);
  setAppManagerNotifications(editorAPI);
};

const dismiss = (editorAPI: EditorAPI, appDefinitionId: string) => {
  editorAPI.dsActions.tpa.pending.dismiss(appDefinitionId);
  updateNotification(editorAPI);
};

const add = (editorAPI: EditorAPI, appDefinitionId: string) => {
  editorAPI.dsActions.tpa.pending.add(appDefinitionId);
  updateNotification(editorAPI);
};

export {
  getPendingApps,
  getPendingAppsCount,
  updateNotification,
  dismiss,
  add,
  getAllPendingAppDefIds,
};

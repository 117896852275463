/*eslint max-lines: [2, { "max": 2542, "skipComments": true, "skipBlankLines": true}]*/
/*this file is huge, please don't make it even bigger, try to decompose it*/
/* eslint max-statements: ["error", 198] */

import {
  EditorCoreApiKey,
  EditorParamsApiKey,
  InitDocumentServicesApiKey,
} from '#packages/apis';
import _ from 'lodash';
import * as baseUI from '#packages/baseUI';
import * as util from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { createDocumentServices } from '#packages/documentServices';
import { fedopsLogger, fixedStage } from '#packages/util';
import * as platformEvents from 'platformEvents';
import pluginsRegistrar from './pluginsRegistrar';
import * as addPanelInfra from '#packages/addPanelInfra';
import * as newEditorWelcomeScreenWrapper from './APISections/welcomeScreensWrapper';
import updateThemePlugins from './updateThemePlugins';
import * as pasteLogicContext from '../pasteLogic/pasteLogicContext';
import * as blog from '#packages/blog';
import experiment from 'experiment';
import constants from '#packages/constants';
import type {
  EditorState,
  EditorStore,
  LassoCandidate,
  PanelDescriptor,
} from '#packages/stateManagement';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import { createOnPreviewReady } from './onPreviewReady';
import type * as textControls from '#packages/textControls';
import type { OnPreviewReadyType } from './onPreviewReady';
import type {
  CompRef,
  DocumentServicesObject,
  DSAction,
  DSRead,
  GeneralInfoObject,
  CompData,
  LanguageDefinition,
  DocumentServicesCreator,
} from 'types/documentServices';
import type { EditorConfig, ViewerChangesData } from 'types/core';
import type { CampaignInfo, TextManager, ViewToolsState } from 'types/data';
import type {
  AfterDuplicatePlugin,
  AfterPastePlugin,
  CannotRemovePlugin,
  CompNamePlugin,
  DuplicatePlugin,
  RemovePlugin,
} from 'types/editorPlugins';
import type React from 'react';
import type { DealerViewerProps } from '#packages/topBar';
import { openDeprecationPanel } from '../utils/panelUtils';
import type { Shell, AppHost } from '#packages/apilib';
import type { SwitchEditorModeInteractionStartedSource } from 'types/fedops/mobileEditorInteraction';
import { onPreviewModeChanged } from './previewModeHandler';
import type { ApisInEditorAPIType } from './setApisToEditorAPI';
import type { ColorPickerTypes } from '#packages/panels';
import { WorkspaceRightPanelApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';

function assignEditorApi<E, T>(editor: E, vals: T): E & T {
  return Object.assign(editor, vals);
}

// TODO: put explicit values for these URLs in serviceTopology
const MY_ACCOUNT =
  `${util.serviceTopology.dashboardUrl.replace(/create\//, '')}/` ||
  'http://www.wix.com/my-account/';
const domainSelectors = stateManagement.domain.selectors;
const componentsSelectors = stateManagement.components.selectors;
const { exitInteractionModeIfNeeded } = stateManagement.interactions.actions;
const { selectOpenPanels } = stateManagement.panels.selectors;
const { openLeftPanel } = stateManagement.panels.actions;
const { setSelectedCompsRestrictions } = stateManagement.selection.actions;
const { getSelectedCompsRefs } = stateManagement.selection.selectors;
const { isMultiselect, asArray } = util.array;
const { isPerformingMouseMoveAction } = stateManagement.mouseActions.selectors;
const { getPreviewPosition, getSiteScale } =
  stateManagement.domMeasurements.selectors;
const { updatePreviewPos } = stateManagement.domMeasurements.actions;
const {
  isPreviewReady: isPreviewReadySelector,
  getPreviewMode: getPreviewModeSelector,
} = stateManagement.preview.selectors;
const { openSection, closeSection } = stateManagement.sectionedPanel.actions;

export const SITE_SCALE_SCROLL_DURATION_SECS = 0.5;
const SWITCH_TO_MOBILE_EDITOR_ORIGIN = 'switch_to_mobile_editor';

const { isInInteractionMode, isShownOnlyOnVariant } =
  stateManagement.interactions.selectors;
const { updateAttachCandidate } = stateManagement.attachCandidate.actions;

function getEditorConfig(): EditorConfig {
  return {
    topBarHeight: util.topBar.getHeightConst(),
    minTopBarWidth: 1160,
    previewHeight: null,
    previewWidth: null,
    languages: ['latin'],
    extraSiteHeight: 40,
  };
}

const DEFAULT_VIEW_TOOLS = {
  toolbarEnabled: true,
  developerModeEnabled: false,
  showHiddenComponents: true,
  guidelinesEnabled: true,
  sectionsHandlesEnabled: true,
  snapToEnabled: true,
  rulersEnabled: undefined as AnyFixMe,
};

const DEFAULT_CHARACTER_SET = ['latin'];

let floatingBubbleTimeoutHandle: number = null;
let cursorPosition: { x?: number; y?: number } = {};

function getLayoutObjFromClientRect(clientRect: AnyFixMe, previewTop: number) {
  const editorLayoutObj = _.pick(clientRect, [
    'top',
    'left',
    'height',
    'width',
  ]);
  editorLayoutObj.top += previewTop;
  return editorLayoutObj;
}

export function create(shell: Shell, store: EditorStore) {
  const setSiteUserPreferences = (key: AnyFixMe, value: AnyFixMe) =>
    store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        key,
        value,
      ),
    );

  const setSessionUserPreferences = <TValue = unknown>(
    key: string,
    value: TValue,
  ) =>
    store.dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences<TValue>(
        key,
        value,
      ),
    );

  const getSiteUserPreferences = <TValue = unknown>(key: string) =>
    stateManagement.userPreferences.selectors.getSiteUserPreferences<TValue>(
      key,
    )(store.getState());

  const getGlobalUserPreferences = (key: AnyFixMe) =>
    stateManagement.userPreferences.selectors.getGlobalUserPreferences(key)(
      store.getState(),
    );

  function synchronizeSiteSEOToMainPageSEO(): void {
    if (experiment.isOpen('se_removeSeoSiteTagsSync')) {
      return;
    }
    const homePageId = editorAPI.homePage.get();
    const homePageData = editorAPI.pages.data.get(homePageId);

    const seoTitle =
      editorAPI.dsRead.seo.title.get() || homePageData.pageTitleSEO || '';
    const seoDescription =
      editorAPI.dsRead.seo.description.get() ||
      homePageData.descriptionSEO ||
      '';
    const seoKeywords =
      editorAPI.dsRead.seo.keywords.get() || homePageData.metaKeywordsSEO || '';

    const seoData = {
      pageTitleSEO: seoTitle,
      descriptionSEO: seoDescription,
      metaKeywordsSEO: seoKeywords,
    };
    editorAPI.pages.data.update(homePageId, seoData);
  }

  function initTemplateCharacterSet(): void {
    const templateCharacterSet = editorAPI.dsRead.theme.fonts.getCharacterSet();

    //if the template has default char sets or it has arabic and hebrew that was added automatically to it
    //because of bug caused new template to be saved with hebrew and arabic. this prevents it from reaching the users
    //this logic was copied from old html-client
    function isDefaultCharacterSet(charSet: string[]): boolean {
      return _.isEqual(charSet, DEFAULT_CHARACTER_SET);
    }

    function isTemplateWithHebrewArabicBug(charSet: string[]) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      return _.includes(charSet, 'arabic') && _.includes(charSet, 'hebrew');
    }

    if (
      isDefaultCharacterSet(templateCharacterSet) ||
      isTemplateWithHebrewArabicBug(templateCharacterSet)
    ) {
      //should init the template character set
      const wixLanguageCharacterSet =
        editorAPI.dsRead.theme.fonts.getLanguageCharacterSet(
          util.languages.getLanguageCode(),
        );
      if (wixLanguageCharacterSet) {
        //init to wix language character set
        editorAPI.dsActions.theme.fonts.updateCharacterSet(
          wixLanguageCharacterSet,
        );
      } else {
        //init to character sets by IP/GEO
        const characterSetByGeo =
          editorAPI.dsRead.theme.fonts.getCharacterSetByGeo();
        editorAPI.dsActions.theme.fonts.updateCharacterSet(characterSetByGeo);
      }
    }
  }

  function extendEditorApiWithDocumentServices(
    editorAPI: __EditorAPI,
    {
      dsRead,
      dsActions,
      documentServices,
    }: {
      dsRead: DSRead;
      dsActions: DSAction;
      documentServices: DocumentServicesCreator;
    },
  ): void {
    Object.assign(editorAPI, {
      dsRead,
      dsActions,
      // TODO: why we need it here? Why dsRead & dsActions vs documentServices vs _.defaultsDeep(editorAPI, _.omit(documentServices, 'history'));
      documentServices,
    });

    // TODO: why we need it here? Why dsRead & dsActions vs documentServices vs _.defaultsDeep(editorAPI, _.omit(documentServices, 'history'));
    _.defaultsDeep(editorAPI, _.omit(documentServices, 'history'));
  }

  function setDocumentServices(_documentServices: DocumentServicesCreator) {
    const { dsRead, dsActions, documentServices } =
      createDocumentServices(_documentServices);

    if (experiment.isOpen('se_biInteractionsDm')) {
      let curId = 0;

      const nameIdMapping: Record<string, string[]> = {};
      util.fedopsLogger.hooks.addHook({
        onStart: (interactionName, interactionOptions) => {
          const newId = String(++curId);

          _documentServices.SOQ.addCallContext(newId);
          nameIdMapping[interactionName] = nameIdMapping[interactionName] ?? [];
          nameIdMapping[interactionName].push(newId);

          return {
            ...interactionOptions,
            paramsOverrides: {
              ...interactionOptions?.paramsOverrides,
              interactionId: newId,
            },
          };
        },
        onEnd: (interactionName, interactionOptions) => {
          const arr = nameIdMapping[interactionName];
          if (!arr) {
            return;
          }
          const id = nameIdMapping[interactionName].shift();
          if (arr.length === 0) {
            delete nameIdMapping[interactionName];
          }
          _documentServices.SOQ.removeCallContext(id);

          return {
            ...interactionOptions,
            paramsOverrides: {
              ...interactionOptions?.paramsOverrides,
              interactionId: id,
            },
          };
        },
      });
    }

    extendEditorApiWithDocumentServices(editorAPI, {
      dsRead,
      dsActions,
      documentServices,
    });

    editorAPI.store.dispatch(
      stateManagement.services.actions.extendThunkActionWithDocumentServices({
        dsRead,
        dsActions,
      }),
    );

    window.documentServicesReady = true;

    if (window.testApi) {
      Object.assign(window.testApi, { dsRead, dsActions });
    }
    shell.getAPI(InitDocumentServicesApiKey).setDs(documentServices);
    shell.getAPI(EditorCoreApiKey).hooks._dsIsReady.resolve({ dsRead });
  }

  function setSantaPreviewCreator(createSantaPreview: AnyFixMe): void {
    editorAPI.store.dispatch(
      stateManagement.services.actions.loadService(
        'createSantaPreview',
        createSantaPreview,
      ),
    );
  }

  function initPlugins(): void {
    pluginsRegistrar.register(editorAPI);
    updateThemePlugins.register(editorAPI);
  }

  function getViewTools(): EditorState['viewTools'] {
    return _.clone(store.getState().viewTools);
  }

  let cachedUserProfilePromise: AnyFixMe;
  const fetchUserProfile = () => {
    if (cachedUserProfilePromise) {
      return cachedUserProfilePromise;
    }
    const BI_PROFILE_SERVER =
      util.serviceTopology.wixStoresMigrationServiceUrl.replace(
        /ecommerce-migration-web\//,
        'bi-profile-webapp/userprofile',
      ) || 'https://editor.wix.com/_api/wix-bi-profile-webapp/userprofile';

    const POST_REGISTRATION_REQUEST_URL = `${BI_PROFILE_SERVER}?fields=post_reg_category,post_reg_sub_category,wix_code_exposure_flag,finished_partner_onboarding&accept=json`;
    return util.http
      .fetchJson(POST_REGISTRATION_REQUEST_URL)
      .then((result: AnyFixMe) => {
        cachedUserProfilePromise = Promise.resolve(result);
      })
      .catch((e: AnyFixMe) => {
        cachedUserProfilePromise = Promise.reject(e);
      })
      .then(() => cachedUserProfilePromise);
  };

  async function updateUserPreferencesOnPreviewReady(): Promise<void> {
    const siteId = editorAPI.dsRead.generalInfo.getSiteId();
    let areGlobalPrefsLoaded, areSitePrefsLoaded;
    const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
    const isDraft = editorAPI.dsRead.generalInfo.isDraft();
    function invokeCallbacks(success: boolean): void {
      editorAPI.userPreferencesInitCallbacksInvoked = true;
      editorAPI.userPreferencesLoaded = success;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(editorAPI.userPreferencesInitCallbacks, (callback) => {
        callback(success);
      });
    }

    function onPrefsLoaded(): void {
      fetchUserProfile()
        .then(function () {
          areGlobalPrefsLoaded =
            stateManagement.userPreferences.selectors.getGlobalPrefFetchStatus(
              editorAPI.store.getState(),
            );
          areSitePrefsLoaded =
            stateManagement.userPreferences.selectors.getSitePrefFetchStatus(
              editorAPI.store.getState(),
            );
          if (
            areGlobalPrefsLoaded &&
            (areSitePrefsLoaded || isFirstSave || isDraft)
          ) {
            invokeCallbacks(true);
          }
        })
        .catch((e: AnyFixMe) => {
          console.error(e);
          invokeCallbacks(false);
        });
    }

    const userPreferencesPromise = store
      .dispatch(stateManagement.userPreferences.actions.loadGlobalPreferences())
      .then(() => {
        store.dispatch(
          stateManagement.userPreferences.actions.setGlobalPrefFetchStatus(
            true,
          ),
        );
        onPrefsLoaded();
      })
      .catch((e: AnyFixMe) => {
        console.error(e);
        store.dispatch(
          stateManagement.userPreferences.actions.setGlobalPrefFetchStatus(
            false,
          ),
        );
      });

    if (!isFirstSave) {
      const sitePreferencesPromise = store
        .dispatch(
          stateManagement.userPreferences.actions.loadSitePreferences(
            siteId,
            false,
          ),
        )
        .then(() => {
          onPrefsLoaded();
        })
        .catch(() => {
          onPrefsLoaded();
        });

      await Promise.all([userPreferencesPromise, sitePreferencesPromise]);
      return;
    }

    await userPreferencesPromise;
    return;
  }

  function initWelcomeScreen(): void {
    editorAPI.newEditorWelcomeScreenWrapper.initWelcomeScreen(editorAPI);
  }

  function initDevModeValues(): void {
    const showHiddenComponents =
      getSiteUserPreferences<boolean>(
        constants.USER_PREFS.VIEW_TOOLS.SHOW_HIDDEN_COMPS,
      ) ?? editorAPI.getViewTools().showHiddenComponents;
    editorAPI.dsActions.documentMode.showHiddenComponents(showHiddenComponents);
    editorAPI.dsActions.documentMode.showControllers(
      editorAPI.getViewTools().developerModeEnabled,
    );
  }

  async function initPartnerInfo(): Promise<void> {
    const isPotentialPartner =
      await editorAPI.dsRead.partners.isPotentialPartnerAsync();
    store.dispatch(
      stateManagement.helpHome.actions.setPartnerState(isPotentialPartner),
    );
  }

  function shouldOpenPropertiesPanelAsDockedSection(): boolean {
    const { getOverriddenMenuItems } = stateManagement.topBar.selectors;
    const overriddenMenuItems = getOverriddenMenuItems(
      editorAPI.store.getState(),
    );
    const propertiesPanelOverridden =
      overriddenMenuItems?.[constants.ROOT_COMPS.TOPBAR.MENU_BAR_ITEMS.TOOLS]?.[
        constants.OVERRIDDEN.TOP_BAR.MENU_ITEMS.TOOLS_PROPERTIES_PANEL.KEY
      ];
    const { SHOULD_OPEN_AS_SECTION } =
      constants.OVERRIDDEN.TOP_BAR.MENU_ITEMS.TOOLS_PROPERTIES_PANEL;

    const shouldOpenAsSectionOverridden = _.get(
      propertiesPanelOverridden,
      SHOULD_OPEN_AS_SECTION,
      false,
    );

    return (
      experiment.isOpen('se_propertiesAsSection') ||
      shouldOpenAsSectionOverridden
    );
  }

  function initViewToolsFromUserPrefs(): void {
    const viewtoolsConstants = constants.USER_PREFS.VIEW_TOOLS;

    const viewToolsState: ViewToolsState = {
      showHiddenComponents: getSiteUserPreferences(
        viewtoolsConstants.SHOW_HIDDEN_COMPS,
      ),
      developerModeEnabled: getSiteUserPreferences(
        viewtoolsConstants.DEVELOPER_MODE_ENABLED,
      ),
      developerToolBarEnabled:
        getSiteUserPreferences(viewtoolsConstants.DEVELOPER_TOOLBAR_ENABLED) &&
        !shouldOpenPropertiesPanelAsDockedSection(),
      guidelinesEnabled: getSiteUserPreferences(
        viewtoolsConstants.GUIDELINES_ENABLED,
      ),
      sectionsHandlesEnabled: getSiteUserPreferences(
        viewtoolsConstants.SECTIONS_HANDLES_ENABLED,
      ),
      snapToEnabled: getSiteUserPreferences(viewtoolsConstants.SNAPTO_ENABLED),
      rulersEnabled: getSiteUserPreferences(viewtoolsConstants.RULERS_ENABLED),
      toolbarEnabled: getSiteUserPreferences(
        viewtoolsConstants.TOOLBAR_ENABLED,
      ),
    };

    const toolbarEnabled = !util.workspace.isNewWorkspaceEnabled();

    const viewerToolsDefaults = {
      ...DEFAULT_VIEW_TOOLS,
      toolbarEnabled,
    };

    _.defaults(viewToolsState, viewerToolsDefaults);

    store.dispatch(
      stateManagement.services.actions.setState({
        viewTools: viewToolsState,
      }),
    );

    if (shouldOpenPropertiesPanelAsDockedSection()) {
      if (
        getSiteUserPreferences(viewtoolsConstants.DEVELOPER_TOOLBAR_ENABLED)
      ) {
        editorAPI.store.dispatch(
          openSection(constants.DOCKED_PANEL_SECTIONS.PROPERTIES),
        );
      } else {
        editorAPI.store.dispatch(
          closeSection(constants.DOCKED_PANEL_SECTIONS.PROPERTIES),
        );
      }
    }
    store.dispatch(
      stateManagement.rulers.actions.initRulersFromUserPreferences(),
    );
    store.dispatch(stateManagement.rulers.actions.resetHistory());
  }

  function isAutoDevMode(): boolean {
    const editorQueryCaseSensitive =
      util.url.parseUrl(window.document.location.href).query || {};
    return !!editorQueryCaseSensitive.autoDevMode;
  }

  function isCurrentLoading(
    startTimestemp: number,
    endTimestemp: number,
  ): boolean {
    const timeToleranceInSec = 30;
    return !!((endTimestemp - startTimestemp) / 1000 < timeToleranceInSec);
  }

  function updateWixCodeValues(): void {
    if (experiment.isOpen('excludeFromWixCodeMarketingFlow')) {
      return;
    }
    if (isAutoDevMode()) {
      editorAPI.developerMode.enableViaQueryParam();
    } else {
      // exposure flag is set when in a few marketing flows (mini site, is developer, or any future marketing champaign)
      // this flag aims to makes sure a user will get auto dev mode on (only one time per user how exposed to the flag)
      const userProfile = stateManagement.userProfile.selectors.getUserProfile(
        editorAPI.store.getState(),
      );
      const hasWixCodeExposureFlag = userProfile?.wix_code_exposure_flag?.value;

      const firstTimeAutoDevMode =
        constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME_AUTO_DEV_MODE_ON;
      const exposedToFirstTimeAutoDevModeTimestemp =
        getGlobalUserPreferences(firstTimeAutoDevMode);
      const isSameLoading = _.isNumber(exposedToFirstTimeAutoDevModeTimestemp)
        ? isCurrentLoading(
            exposedToFirstTimeAutoDevModeTimestemp,
            new Date().getTime(),
          )
        : false;
      const isFirstTimeAutoDevMode = !!(
        !exposedToFirstTimeAutoDevModeTimestemp || isSameLoading
      );

      const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
      const isUnsavedSite =
        !!editorAPI.generalInfo.isFirstSave() || isDraftMode;

      // this flow should run one time per user
      if (hasWixCodeExposureFlag && isUnsavedSite && isFirstTimeAutoDevMode) {
        editorAPI.developerMode.enableWithMarketingConfig();
      }
    }
  }

  function addCustomColors(color: string, colors: Set<string>) {
    if (
      color &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      !_.includes(color, 'color') &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      !_.includes(color, 'transparent')
    ) {
      colors.add(color);
    }
  }

  function addPageBackgroundColor(
    pageBackgrounds: any,
    viewMode: string,
    colors: Set<string>,
  ): void {
    const color = pageBackgrounds?.[viewMode]?.ref?.color;
    if (color) {
      addCustomColors(color, colors);
    }
  }

  function initUserAddedColors(): void {
    /**
     * Contains all custom colors
     * used by user in components
     */
    const siteColors = editorAPI.dsRead.theme.colors.getCustomUsedInSkins();
    const {
      USER_ADDED: userAddedColorsPrefKey,
      USER_SITE_ADDED: userCustomAddedColorsPrefKey,
    } = constants.USER_PREFS.THEME.COLORS.CUSTOM;

    const pagesData = editorAPI.pages.getPagesData();
    /**
     * Contains all custom colors used by user as
     * page backgrounds can be different for mobile and desktop
     */
    const pagesBackgroundCustomColors = pagesData.reduce(
      (colors: Set<string>, { pageBackgrounds }: any) => {
        addPageBackgroundColor(pageBackgrounds, 'desktop', colors);
        addPageBackgroundColor(pageBackgrounds, 'mobile', colors);
        return colors;
      },
      new Set<string>(),
    );

    /**
     * Probably always empty need to investigate how to set
     */
    const userCustomColors =
      getSiteUserPreferences<unknown[]>(userCustomAddedColorsPrefKey) || [];

    setSessionUserPreferences<unknown[]>(userAddedColorsPrefKey, [
      ...siteColors,
      ...userCustomColors,
      ...pagesBackgroundCustomColors,
    ]);
  }

  function getNewPreviewTop(newHideToolsState?: boolean): number {
    const state = store.getState();
    const isInZoomMode = editorAPI.zoomMode.isInZoomMode();

    const isToolsHidden = state.hideTools;

    const shouldHideTools =
      typeof newHideToolsState === 'undefined'
        ? isToolsHidden &&
          !state.hideToolsAndMaintainStagePosition &&
          !isInZoomMode
        : newHideToolsState;

    let top: number = isInZoomMode
      ? util.topBar.getZoomModeHeightConst()
      : util.topBar.getHeightConst();

    if (shouldHideTools) {
      top -= util.topBar.getOffsetTopWhenToolsHidden();
    }

    if (fixedStage.isFixedStageEnabled()) {
      top += constants.UI.FIXED_STAGE_MARGIN_TOP;
    } else if (editorAPI.isMobileEditor()) {
      top += util.workspace.isNewWorkspaceEnabled()
        ? constants.UI.MOBILE_PREVIEW_TOP_NEW_WORKSPACE
        : constants.UI.MOBILE_PREVIEW_TOP;
    }
    return top;
  }

  function getNewConfig(): EditorConfig {
    return _.defaults(
      {
        previewHeight: editorAPI.dsRead.site.getHeight(),
      },
      store.getState().config,
    );
  }

  const updateStateIfChanged = _.curry(function <
    TKey extends keyof EditorState,
  >(key: TKey, newValue: EditorState[TKey]) {
    if (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      !_.isUndefined(newValue) &&
      !_.isEqual(newValue, store.getState()[key])
    ) {
      const updatedState: Partial<EditorState> = {};
      updatedState[key] = newValue;
      store.dispatch(stateManagement.services.actions.setState(updatedState));
    }
  });

  function getEditBoxRotateKnobRect() {
    return store.getState().editBoxRotateKnobRect;
  }

  function getEditingAreaPosition() {
    return store.getState().editingAreaPosition;
  }

  function getMobileFramePosition() {
    return store.getState().mobileFramePosition;
  }

  function getOpenPanels(): PanelDescriptor[] {
    return selectOpenPanels(store.getState());
  }

  function notifyViewerUpdate(changeData: any): void {
    const state = store.getState();
    const isAppInstallationInProgress =
      stateManagement.platform.selectors.getIsApplicationInstallationStatus(
        state,
      );
    if (isAppInstallationInProgress) {
      return;
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(editorAPI.viewerUpdateCallbacks, function (callback) {
      callback(changeData);
    });
  }

  function setStateWaitForViewer(state: Partial<EditorState>): void {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(editorAPI.pendingStateChanges, state);
  }

  function setStateUpdateCallback(callback: () => Partial<EditorState>): void {
    editorAPI.pendingEditorStateUpdateCallbacks.push(callback);
  }

  function getPendingStateChanges(): Partial<EditorState> {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      editorAPI.pendingEditorStateUpdateCallbacks,
      function (newState: Partial<EditorState>, pendingStateUpdateFunc) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        return _.assign(newState, pendingStateUpdateFunc());
      },
      editorAPI.pendingStateChanges,
    );
  }

  function preventMouseDown(): void {
    window.addEventListener('mousedown', editorAPI.preventEvent, true);
    editorAPI.preventMouseDownTimeout = window.setTimeout(
      editorAPI.allowMouseDown,
      5000,
    );
  }

  function allowMouseDown(): void {
    window.clearTimeout(editorAPI.preventMouseDownTimeout);
    window.removeEventListener('mousedown', editorAPI.preventEvent, true);
  }

  function preventEvent(e: MouseEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  function getViewModeFromDocumentServices(): string {
    return editorAPI.dsRead.viewMode && editorAPI.dsRead.viewMode.get();
  }

  function onViewerChanged() {
    editorAPI.components.layout.clearLayoutCache();
    const state = store.getState();
    const selectedComponents = getSelectedCompsRefs(state);
    const pendingStateChanges = editorAPI.getPendingStateChanges();
    editorAPI.pendingStateChanges = {};
    editorAPI.pendingEditorStateUpdateCallbacks = [];
    const renderedSelectedComps = selectedComponents.filter(
      (compRef) =>
        editorAPI.dsRead.components.is.rendered(compRef) &&
        editorAPI.components.is.exist(compRef),
    );
    const hasOpenedModalsPanels =
      editorAPI.panelManager.getPanelsOfType('focusPanelFrame').length > 0;
    const shouldChangeSelection =
      !_.isEqual(renderedSelectedComps, selectedComponents) &&
      !hasOpenedModalsPanels;
    if (shouldChangeSelection) {
      editorAPI.mouseActions.turnOffMouseEvents();
    }

    const isMobileEditorAfterUpdate =
      getViewModeFromDocumentServices() ===
      editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE;
    const lastPreviewPosition = getPreviewPosition(state);
    const newState = _.defaults(
      {
        config: getNewConfig(),
        isMobileEditor: isMobileEditorAfterUpdate,
      },
      pendingStateChanges,
    );

    store.dispatch(stateManagement.services.actions.setState(newState));
    store.dispatch(
      updatePreviewPos(
        _.defaults({ top: getNewPreviewTop() }, lastPreviewPosition),
      ),
    );
    if (shouldChangeSelection) {
      if (renderedSelectedComps.length > 0) {
        editorAPI.selection.selectComponentByCompRef(renderedSelectedComps);
      } else {
        editorAPI.selection.deselectComponents(selectedComponents);
      }
    } else if (!isPerformingMouseMoveAction(state)) {
      store.dispatch(
        setSelectedCompsRestrictions(
          getCompRestrictions(getSelectedCompsRefs(store.getState())),
        ),
      );
    }

    store.dispatch(stateManagement.highlights.actions.updateHighlights());

    store.dispatch(
      stateManagement.dynamicPages.actions.updateDynamicPagesState(),
    );

    if (experiment.isOpen('se_fetchAndCacheTPAInnerRoutes')) {
      const { tpaInnerRoute, relativeUrl } =
        editorAPI.documentServices.pages.getRootNavigationInfo();
      store.dispatch(
        stateManagement.tpaDynamicPages.actions.adaptStateToCurrentPage(
          'dynamicPagesNavBar',
          (tpaInnerRoute ?? relativeUrl)?.substring(1),
        ),
      );
    }

    if (editorAPI.shouldUpdateEditorScrollAfterHeightUpdate) {
      editorAPI.scroll.applyPreviewScrollToEditorScroll();
      editorAPI.shouldUpdateEditorScrollAfterHeightUpdate = false;
    }

    const currentPage = editorAPI.dsRead.pages.getFocusedPage();
    let pageChanged = false;

    if (
      currentPage &&
      !_.isEqual(
        stateManagement.pages.selectors.getFocusedPage(store.getState()),
        currentPage,
      )
    ) {
      shell
        .getAPI(EditorCoreApiKey)
        .hooks.focusedPageChanged.fire({ pageRef: currentPage });
      store.dispatch(stateManagement.pages.actions.setFocusedPage(currentPage));
      pageChanged = true;
    }

    if (!isPerformingMouseMoveAction(state)) {
      _(store.getState())
        .thru(stateManagement.componentsStore.selectors.getComponentsList)
        .reject(editorAPI.components.is.exist)
        .thru(stateManagement.componentsStore.actions.removeComponentsStore)
        .thru(store.dispatch)
        .value();

      store.dispatch(
        stateManagement.applicationStudio.actions.applyApplicationStudioBehaviors(
          pageChanged,
        ),
      );
    }

    store.dispatch(stateManagement.services.actions.onViewerChanged());

    store.flush();
  }

  function registerInitUserPrefsCallback(
    callback: (isLoaded: boolean) => void,
  ) {
    if (editorAPI.userPreferencesInitCallbacksInvoked) {
      callback(!!editorAPI.userPreferencesLoaded);
    } else {
      editorAPI.userPreferencesInitCallbacks.push(callback);
    }
  }

  function registerPrerequisiteForWelcomeScreen(promise: Promise<any>) {
    editorAPI.prerequisiteForWelcomeScreen.push(promise);
  }

  function registerPostDeepNavigation(cb: Function) {
    editorAPI.postDeepNavigation.push(cb);
  }

  function exitEditor(exitUrl?: string): void {
    window.open(exitUrl || `${MY_ACCOUNT}?referralInfo=EDITOR`, '_self');
  }

  function isTpa(componentType: string): boolean {
    return editorAPI.dsRead.tpa.isTpaByCompType(componentType);
  }

  function registerToViewerChange(
    callback: (changedData: ViewerChangesData) => void,
  ): () => void {
    editorAPI.viewerUpdateCallbacks.push(callback);
    const unregister = () => {
      const index = editorAPI.viewerUpdateCallbacks.indexOf(callback);
      if (index !== -1) {
        editorAPI.viewerUpdateCallbacks.splice(index, 1);
      }
    };
    return unregister;
  }

  function registerRemovePlugin(type: string, plugin: RemovePlugin): void {
    editorAPI.removePlugins[type] = plugin;
  }

  function registerRemovePagePlugin(type: string, plugin: any): void {
    editorAPI.removePagePlugins[type] = plugin;
  }

  function registerComponentsRemoveHandlerPlugin(plugin: any): void {
    editorAPI.componentsRemoveHandlerPlugins.push(plugin);
  }

  function registerHidePlugin(type: string, plugin: any): void {
    editorAPI.hidePlugins[type] = plugin;
  }

  function registerDuplicatePlugin(
    type: string,
    plugin: DuplicatePlugin,
  ): void {
    editorAPI.duplicatePlugins[type] = plugin;
  }

  function registerCannotRemovePlugin(
    compType: string,
    plugin: CannotRemovePlugin,
  ): void {
    editorAPI.cannotRemovePlugins[compType] = plugin;
  }

  function registerCopyComponentPlugin(type: string, plugin: any): void {
    editorAPI.copyComponentPlugins[type] = plugin;
  }

  function registerAddComponentPlugin(type: string, plugin: any): void {
    editorAPI.addComponentPlugins[type] = plugin;
  }

  function registerPasteComponentPlugin(compType: string, plugin: any): void {
    editorAPI.pasteComponentPlugins[compType] =
      editorAPI.pasteComponentPlugins[compType] || [];
    editorAPI.pasteComponentPlugins[compType].push(plugin);
  }

  function registerAfterPasteComponentCallback(plugin: AfterPastePlugin): void {
    editorAPI.afterPasteComponentCallbacks.push(plugin);
  }

  function registerAfterDuplicateComponentCallback(
    plugin: AfterDuplicatePlugin,
  ): void {
    editorAPI.afterDuplicateComponentCallbacks.push(plugin);
  }

  function registerCutComponentPlugin(type: string, plugin: any): void {
    editorAPI.cutComponentPlugins[type] = plugin;
  }

  function registerSerializeComponentPlugin(type: string, plugin: any): void {
    editorAPI.serializeComponentPlugins[type] =
      editorAPI.serializeComponentPlugins[type] || [];
    editorAPI.serializeComponentPlugins[type].push(plugin);
  }

  function registerPageNavigationCallback(callback: any): void {
    editorAPI.pageNavigationCallbacks.push(callback);
  }

  function registerCompNamePlugin(type: string, plugin: CompNamePlugin): void {
    editorAPI.compNamePlugins[type] = plugin;
  }

  function registerSitePublishedCallbacks(callback: () => void): void {
    editorAPI.sitePublishedCallbacks.push(callback);
  }

  function registerDeviceTypeChangeCallbacks(callback: () => void): void {
    editorAPI.deviceTypeChangeCallbacks.push(callback);
  }

  function registerAfterDeviceTypeChangeCallbacks(callback: () => void): void {
    editorAPI.afterDeviceTypeChangeCallbacks.push(callback);
  }

  function setRenderPlugins(isPreview: boolean): void {
    const state = store.getState();

    editorAPI.dsActions.renderPlugins.allowSiteOverflow(isPreview);

    editorAPI.dsActions.renderPlugins.setExtraSiteHeight(
      isPreview ? 0 : editorAPI.editorConfig.extraSiteHeight,
    );
    editorAPI.dsActions.renderPlugins.setPreviewTooltipCallback(
      function (compClientRect, value, props) {
        const bubbleTargetLayout = getLayoutObjFromClientRect(
          compClientRect,
          getPreviewPosition(state).top,
        );
        editorAPI.showFloatingBubble(value, bubbleTargetLayout, props);
        floatingBubbleTimeoutHandle = window.setTimeout(
          editorAPI.hideFloatingBubble,
          constants.PREVIEW.FLOATING_BUBBLE_TIMEOUT,
        );
      },
    );
    editorAPI.dsActions.renderPlugins.setErrorPagesPopUp(function (errorInfo) {
      if (editorAPI.preview.isInPreviewMode) {
        editorAPI.panelManager.openPanel('panels.errorPages.errorPagePanel', {
          errorInfo,
          routerAddress: errorInfo.routerUrl,
        });
      }
    });
  }

  function prepareDocumentForPreviewMode(
    isPreview: boolean,
    disableMeasurement: boolean = false,
  ): void {
    onPreviewModeChanged(editorAPI, isPreview, disableMeasurement);
  }

  function getPreviewMode(): boolean {
    return getPreviewModeSelector(store.getState());
  }

  function isCurrentPageLandingPage(): boolean {
    const pageId = editorAPI.pages.getCurrentPageId();
    return editorAPI.dsRead.pages.isLandingPage(pageId);
  }

  function isDesktopEditor(): boolean {
    return !stateManagement.leftBar.selectors.isMobileEditor(store.getState());
  }

  function isMobileEditor(): boolean {
    return stateManagement.mobile.selectors.isMobileEditor(store.getState());
  }

  function resizeOnlyProportionally(compRef: CompRef | CompRef[]) {
    return editorAPI.components.is.resizeOnlyProportionally(compRef);
  }

  function openCantCopyPastePanel(): void {
    editorAPI.panelManager.openPanelConsideringSitePreference(
      constants.USER_PREFS.CANT_MAKE_CHANGES
        .MOBILE_CANT_MAKE_CHANGE_DONT_SHOW_AGAIN,
      'mobileEditor.panels.cantMakeChangeMobile',
      {},
      true,
    );
  }

  function setDesktopEditor(
    sourceOfStart: SwitchEditorModeInteractionStartedSource,
    preventUndoRedoStack: boolean,
  ): void {
    editorAPI.waitForChangesApplied(() =>
      _setMobileOrDesktopEditor(false, sourceOfStart, preventUndoRedoStack),
    );

    const lastKeyboardContext =
      stateManagement.mobile.selectors.mobileKeyboard.getLastKeyboardContext(
        store.getState(),
      );

    if (lastKeyboardContext) {
      util.keyboardShortcuts.setContext(lastKeyboardContext);
      editorAPI.store.dispatch(
        stateManagement.mobile.actions.mobileKeyboard.setLastKeyboardContext(
          null,
        ),
      );
    }
  }

  function exitModesIfNeeded(editorAPI: EditorAPI) {
    exitInteractionModeIfNeeded(editorAPI);
    if (editorAPI.componentFocusMode.isEnabled()) {
      editorAPI.componentFocusMode.exit();
    }
    const workspaceRightPanelAPI = editorAPI.host.getAPI(
      WorkspaceRightPanelApiKey,
    );
    workspaceRightPanelAPI.close(SWITCH_TO_MOBILE_EDITOR_ORIGIN);
  }

  function setMobileEditor(
    sourceOfStart?: SwitchEditorModeInteractionStartedSource,
    preventUndoRedoStack?: boolean,
  ): void {
    exitModesIfNeeded(editorAPI);
    baseUI.tooltipManager.hide(
      constants.ROOT_COMPS.TOOLTIPS.FIRST_TIME_EXIT_POPUP_MODE,
    );
    editorAPI.waitForChangesApplied(() =>
      _setMobileOrDesktopEditor(true, sourceOfStart, preventUndoRedoStack),
    );

    const lastKeyboardContext = util.keyboardShortcuts.getContext();
    if (lastKeyboardContext)
      editorAPI.store.dispatch(
        stateManagement.mobile.actions.mobileKeyboard.setLastKeyboardContext(
          lastKeyboardContext,
        ),
      );

    util.keyboardShortcuts.setContext(
      util.keyboardShortcuts.CONTEXTS.MOBILE_EDITOR,
    );
  }

  function _setMobileOrDesktopEditor(
    isMobile: boolean,
    sourceOfStart?: SwitchEditorModeInteractionStartedSource,
    preventUndoRedoStack?: boolean,
  ): void {
    const isSameViewMode = isMobile === editorAPI.isMobileEditor();

    const isGoingToDesktopWhileMobileWizardIsOpen =
      !isMobile && editorAPI.mobile.mobileWizard.isEnabled();

    if (isSameViewMode || isGoingToDesktopWhileMobileWizardIsOpen) {
      return;
    }

    if (isMobile) {
      editorAPI.dsActions.waitForChangesApplied(() => {
        editorAPI.mobile.afterMovingToMobileEditorCallback(getPreviewMode());
      });
    }

    if (!isMobile) {
      editorAPI.updateState({
        mobileFramePosition: null,
      });
    }
    const isPreview = getPreviewMode();

    const interactionName = isMobile
      ? fedopsLogger.INTERACTIONS.CHANGE_EDITOR_MODE_TO_MOBILE
      : fedopsLogger.INTERACTIONS.CHANGE_EDITOR_MODE_TO_DESKTOP;

    fedopsLogger.interactionStarted(interactionName, {
      paramsOverrides: {
        sourceOfStart,
        isPreview: `${isPreview}`,
        isZoomOut: `${editorAPI.zoomMode.isInZoomMode()}`,
      },
    });

    const viewMode = isMobile
      ? editorAPI.dsRead.viewMode.VIEW_MODES.MOBILE
      : editorAPI.dsRead.viewMode.VIEW_MODES.DESKTOP;

    if (
      editorAPI.developerMode.getContext().type ===
      constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE
    ) {
      editorAPI.panelManager.closeAllPanels();
    }
    editorAPI.selection.deselectComponents();

    _.invokeMap(editorAPI.deviceTypeChangeCallbacks, _.call);

    if (isPreview) {
      editorAPI.dsActions.documentMode.enableAction('screenIn', false);
    }
    editorAPI.dsActions.viewMode.set(viewMode);

    const documentModeSetters = editorAPI.dsActions.documentMode;
    //editorAPI is here because these operations are not in the Q... :( so they run when the viewer thinks we are still in mobile
    editorAPI.dsActions.waitForChangesApplied(() => {
      _.invokeMap(editorAPI.afterDeviceTypeChangeCallbacks, _.call);
      const isDesktopPreview = isPreview && !isMobile;
      documentModeSetters.resetBehaviors(isPreview);
      documentModeSetters.enableAction('screenIn', isPreview); //editorAPI one
      documentModeSetters.enableAction('load', isPreview);
      documentModeSetters.enableAction('scrollScrub', isPreview);
      documentModeSetters.enableAction('viewportEnter', isPreview);
      documentModeSetters.enableAction('viewportLeave', isPreview);
      documentModeSetters.enableAction('modeChange', isPreview);
      documentModeSetters.enableAction('exit', isPreview);
      documentModeSetters.enableAction('bgScrub', isDesktopPreview);
      documentModeSetters.enableAction('pageTransition', isPreview);
      documentModeSetters.updateAnimationsViewMode(viewMode);

      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        isMobile
          ? platformEvents.factory.switchedToMobileView({})
          : platformEvents.factory.switchedToDesktopView({}),
      );

      ErrorReporter.setTags({
        isDesktopEditor: !isMobile,
        isMobileEditor: isMobile,
      });
    });

    setRenderPlugins(getPreviewMode());

    if (
      !preventUndoRedoStack &&
      isMobile &&
      editorAPI.dsActions.history.canUndo()
    ) {
      editorAPI.history.add('mobile structure was updated');
    }
    const previewMode = getPreviewMode();

    documentModeSetters.setExtraSiteHeight(
      previewMode ? 0 : editorAPI.editorConfig.extraSiteHeight,
    );
    documentModeSetters.resetBehaviors(previewMode);

    editorAPI.store.dispatch(stateManagement.rulers.actions.resetHistory());

    editorAPI.dsActions.waitForChangesApplied(() => {
      editorAPI.store.dispatch(
        stateManagement.inlinePopup.actions.closeAll(true),
      );
    });

    if (!isMobile) {
      editorAPI.dsActions.components.modes.resetAllActiveModes();
    }
  }

  const setEditorMode = (
    isMobile: boolean,
    sourceOfStart: SwitchEditorModeInteractionStartedSource,
    preventUndoRedoStack?: boolean,
  ) =>
    (isMobile ? setMobileEditor : setDesktopEditor)(
      sourceOfStart,
      preventUndoRedoStack,
    );

  function isMobileOptimizedSite(): boolean {
    if (!editorAPI.dsRead || !editorAPI.dsRead.mobile) {
      return false;
    }
    return editorAPI.dsRead.mobile.isOptimized();
  }

  function setMobileOptimizedSite(isOptimized: boolean): void {
    if (!editorAPI.dsActions || !editorAPI.dsActions.mobile) {
      return;
    }
    editorAPI.dsActions.mobile.enableOptimizedView(isOptimized);
  }

  function getLeftBarButtonsBoundingRect(): any {
    return store.getState().leftBar.buttonsBoundingRect;
  }

  function closeRightClickMenu() {
    editorAPI.store.dispatch(
      stateManagement.contextMenu.actions.closeContextMenu(),
    );
  }

  function disableRightClickMenuCloseOnOuterClick(): void {
    editorAPI.rightClickMenuCloseOnOuterClickDisabled = true;
  }

  function enableRightClickMenuCloseOnOuterClick(): void {
    editorAPI.rightClickMenuCloseOnOuterClickDisabled = false;
  }

  function closeCompPanelIfExists(origin: string = 'Click outside'): void {
    const compPanelFramePanels =
      editorAPI.panelManager.getPanelsOfType('compPanelFrame');
    if (compPanelFramePanels.length) {
      if (stateManagement.text.selectors.isTextEditorOpen(store.getState())) {
        store.dispatch(
          stateManagement.text.actions.textEditor.clearWidgetDesignState(),
        );
      }
      editorAPI.panelManager.closeAllPanelsOfType('compPanelFrame', origin);
    }
  }

  function openFirstTimeOrDeprecationPanel(compRef: CompRef): void {
    return openDeprecationPanel(editorAPI, compRef);
  }

  function getCompDragRestrictions(compRef: CompRef | CompRef[]) {
    const state = store.getState();
    if (!compRef || stateManagement.pinMode.selectors.isOpen(state)) {
      return {
        horizontallyMovable: false,
        verticallyMovable: false,
      };
    }

    return editorAPI.components.getRestrictions(compRef, [
      'verticallyMovable',
      'horizontallyMovable',
    ]);
  }

  function getCompMoveRestrictions(compRef: CompRef | CompRef[]) {
    const restrictions = getCompDragRestrictions(compRef);
    if (compRef) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(restrictions, {
        canMoveForward:
          editorAPI.components.arrangement.canMoveForward(compRef),
        canMoveBackward:
          editorAPI.components.arrangement.canMoveBackward(compRef),
      });
    }
    return restrictions;
  }

  function getCompRestrictions(compRef: CompRef | CompRef[]) {
    if (_.isEmpty(compRef)) {
      return {};
    }
    const state = store.getState();
    const isInteractionMode = isInInteractionMode(state);

    const restrictions = getCompMoveRestrictions(compRef);
    const isPinned = editorAPI.components.layout.isPinned(compRef);
    const { isOpenTextEditor } = state;

    const componentRestrictions = [
      'container',
      'containable',
      'movable',
      'alignable',
      'resizable',
      'disabledKnobs',
      'duplicatable',
      'fixed',
      'showLegacyFixedPosition',
      'anchorableFrom',
      'anchorableTo',
      'rotatableByType',
      'controlledByParent',
      'flippable',
    ];
    if (!isPinned) {
      componentRestrictions.push(
        'horizontallyResizable',
        'verticallyResizable',
        'rotatable',
        'duplicatable',
      );
    }
    if (!isOpenTextEditor) {
      componentRestrictions.push('removable');
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      restrictions,
      editorAPI.components.getRestrictions(compRef, componentRestrictions),
    );

    if (isPinned) {
      restrictions.horizontallyResizable = false;
      restrictions.verticallyResizable = false;
      restrictions.rotatable = false;
      restrictions.duplicatable = false;
    }
    if (isOpenTextEditor) {
      restrictions.removable = false;
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(restrictions, {
      resizableSides: editorAPI.components.layout.getResizableSides(compRef),
      canBeFixed: editorAPI.components.layout.canBeFixedPosition(compRef),
      resizeOnlyProportionally: editorAPI.resizeOnlyProportionally(compRef),
      canToggleShowOnAllPages:
        editorAPI.components.canToggleShowOnAllPages(compRef),
      canResizeWithAnchors:
        editorAPI.components.layout.getResizableWithAnchors(compRef),
      canResizeDiagonally:
        editorAPI.components.layout.getResizableDiagonally(compRef),
    });

    if (isInteractionMode) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
      const selectedComp = _.isArray(compRef) ? compRef[0] : compRef;
      const isOnlyOnVariantComp = !!isShownOnlyOnVariant(
        editorAPI,
        selectedComp,
      );

      restrictions.duplicatable = isOnlyOnVariantComp;
      restrictions.removable = isOnlyOnVariantComp;

      if (!isOnlyOnVariantComp) {
        restrictions.alignable = false;
        restrictions.rotatable = false;
        restrictions.flippable = false;
      }
    }

    return restrictions;
  }

  function navigateAndOpenPagesPanel(
    pageId: string,
    panelProps: any,
    cb?: () => void,
    leavePanelsOpen?: boolean,
  ): void {
    const openPanelFn = () => {
      openPagesPanel(panelProps, leavePanelsOpen);
      if (cb) {
        cb();
      }
    };
    if (editorAPI.pages.getCurrentPageId() !== pageId) {
      editorAPI.pages.navigateTo(pageId, () => openPanelFn());
    } else {
      openPanelFn();
    }
  }

  function openComponentPanel(
    compPanelType: string,
    extraProps: any = {},
    options: any = {},
  ) {
    const selectedCompsRefs = getSelectedCompsRefs(store.getState());
    const compRefs = extraProps?.selectedComponent ?? selectedCompsRefs;
    const isResponsiveEditor =
      editorAPI.host.getAPI(EditorParamsApiKey).isInsideEditorX;
    if (experiment.isOpen('se_fixCompPanelOpen') && !isResponsiveEditor) {
      extraProps.selectedComponentBeforeOpen = selectedCompsRefs;
    }
    const componentType =
      extraProps.selectedComponentType ||
      componentsSelectors.getCompType(compRefs, editorAPI.dsRead);
    return store.dispatch(
      stateManagement.panels.actions.openComponentPanelFromType(
        componentType,
        compPanelType,
        {
          experimentIsOpen: experiment.isOpen,
          ...extraProps,
        },
        options,
      ),
    );
  }

  function replaceComponentPanel(compPanelType: string, extraProps?: any) {
    extraProps = extraProps || {};

    return openComponentPanel(compPanelType, {
      ...extraProps,
      useLastPanelPosition: true,
    });
  }

  function openLearnMore(learnMoreKey: string): void {
    // TODO nirm 1/12/15 1:12 PM - create 'Learn More' module mechanism to open links keys.
    if (learnMoreKey && typeof learnMoreKey === 'string') {
      console.info('opening learn more with key - ', learnMoreKey);
    }
  }

  function isPreviewReady(): boolean {
    return isPreviewReadySelector(store.getState());
  }

  function setPortalChildren(origin: string, children: any): void {
    const portalCompsState = _.cloneDeep(store.getState().portalComps);
    if (_.isEmpty(children)) {
      delete portalCompsState[origin];
    } else {
      portalCompsState[origin] = children;
    }

    updateState({ portalComps: portalCompsState });
  }

  function hasExternalDesign(comp: CompRef): boolean {
    return editorAPI.dsRead.tpa.isTpaByCompType(
      editorAPI.components.getType(comp),
    );
  }

  function setAttachCandidate(
    { comp, useAnimation }: { comp: CompRef; useAnimation?: boolean },
    dontWaitForViewer: boolean,
  ) {
    const nextState = {
      comp,
      useAnimation: comp ? Boolean(useAnimation) : false,
    };

    if (dontWaitForViewer) {
      editorAPI.store.dispatch(updateAttachCandidate(nextState));
    } else {
      editorAPI.setStateWaitForViewer({
        attachCandidate: nextState,
      });
    }
  }

  function setConstraintArea(areaBounds: AnyFixMe) {
    editorAPI.setStateWaitForViewer({ constraintArea: areaBounds });
  }

  function setPageSections(
    sections: AnyFixMe,
    selectedSection: AnyFixMe,
  ): void {
    const pageSections = _.clone(store.getState().pageSections);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(pageSections, {
      sections,
      selectedSection,
    });

    editorAPI.updateState({
      pageSections,
    });
  }

  function clearPageSections(): void {
    editorAPI.setPageSections(null, null);
  }

  interface SetSiteScaleConfig {
    requestedScale: number;
    scrollY: number;
    animationDuration?: number;
    onComplete?: () => void;
  }

  function setSiteScale({
    requestedScale,
    scrollY,
    animationDuration = SITE_SCALE_SCROLL_DURATION_SECS,
    onComplete = () => {},
  }: SetSiteScaleConfig): void {
    editorAPI.store.dispatch(stateManagement.inlinePopup.actions.closeAll());

    if (requestedScale !== store.getState().siteScale) {
      editorAPI.updateState({ siteScale: requestedScale });
    }

    const dsAnimationPromise = new Promise<void>((resolve) => {
      editorAPI.dsActions.site.setScrollAndScale(
        0,
        scrollY,
        requestedScale,
        animationDuration,
        '50%',
        // @ts-expect-error
        (_dontUseMeAsResolveParam) => {
          resolve();
        },
      );
    });

    const editorAnimationPromise = new Promise<void>((resolve) => {
      editorAPI.scroll.animateScrollTo(
        { x: 0, y: scrollY },
        animationDuration,
        resolve,
      );
    });

    const editorScrollAnimation = util.scroll.waitForAnimationScrollEnd(() =>
      editorAPI.site.getScroll(),
    );

    Promise.all([
      dsAnimationPromise,
      editorAnimationPromise,
      editorScrollAnimation,
    ]).then(onComplete);
  }

  function updateEditorViewTools(key: string | any, value?: AnyFixMe): void {
    const changes = _.isString(key) ? { [key]: value } : key;

    const updatedViewTools = _.defaults(changes, editorAPI.getViewTools());
    editorAPI.updateState({ viewTools: updatedViewTools });
  }

  function openEditorVersionInfoPanel(): void {
    editorAPI.panelManager.openPanel('rEditor.panels.editorVersionInfoPanel');
  }

  /**
   * shows a bubble object adjacent to the target layout
   * @param value - the text to put in the bubble - can be translation key or react element
   * @param targetLayout - a layout object with properties for position and size - top, left, height, width
   * @param props - props to pass to bubble component
   * @param shouldNotHideOnMouseLeaveTarget - should the bubble not hide when mouse leaves its target
   */
  function showFloatingBubble(
    value: AnyFixMe,
    targetLayout: AnyFixMe,
    props?: AnyFixMe,
    shouldNotHideOnMouseLeaveTarget?: boolean,
  ): void {
    editorAPI.floatingBubble.showImmediate(
      value,
      targetLayout,
      props,
      shouldNotHideOnMouseLeaveTarget,
    );
  }

  function hideFloatingBubble(): void {
    editorAPI.floatingBubble.hide();
    window.clearTimeout(floatingBubbleTimeoutHandle);
  }

  function endMouseMoveActionIfNeeded(e: AnyFixMe) {
    if (editorAPI.mouseActions.getRegisteredMouseMoveAction()) {
      editorAPI.mouseActions.onMouseUp(e);
    }
  }

  function isVerticalScrollbarHidden() {
    const isScrollNeeded =
      window.document.body.clientHeight > window.innerHeight;
    return (
      !isScrollNeeded ||
      (isScrollNeeded && window.document.body.clientWidth === window.innerWidth)
    );
  }

  function onCollectionAdded() {
    editorAPI.toggleEditorStateParam('hasDatabaseCollections', true);
  }

  function toggleEditorStateParam(
    paramName: string,
    specifiedNewState: AnyFixMe,
  ) {
    const setting: AnyFixMe = {};
    setting[paramName] =
      typeof specifiedNewState === 'undefined'
        ? !store.getState()[paramName as keyof EditorState]
        : specifiedNewState;

    editorAPI.updateState(setting);
  }

  function areSectionsHandlesEnabled() {
    return store.getState().viewTools.sectionsHandlesEnabled;
  }

  function toggleSectionsHandles(onSuccess: AnyFixMe, onFail: AnyFixMe) {
    const isEnabled = !editorAPI.sectionsHandles.areSectionsHandlesEnabled();
    editorAPI.updateEditorViewTools('sectionsHandlesEnabled', isEnabled);
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.SECTIONS_HANDLES_ENABLED,
      isEnabled,
    )
      .then(onSuccess)
      .catch(onFail);
  }

  function setSnapData(snapData: AnyFixMe, dontWaitForViewer?: boolean) {
    if (dontWaitForViewer) {
      editorAPI.updateState({ snapData });
    } else {
      editorAPI.setStateWaitForViewer({ snapData });
    }
  }

  function clearSnapData(dontWaitForViewer?: boolean) {
    if (dontWaitForViewer) {
      editorAPI.updateState({ snapData: null });
    } else {
      editorAPI.setStateWaitForViewer({ snapData: null });
    }
  }

  function setLassoCandidates(candidates: LassoCandidate[]) {
    editorAPI.updateState({ lassoCandidates: candidates });
  }

  function setLassoLayout(lassoLayout: AnyFixMe) {
    editorAPI.updateState({ lassoLayout });
  }

  function clearLasso() {
    editorAPI.updateState({ lassoLayout: null, lassoCandidates: [] });
  }

  function openApplyStylePanel(comp: AnyFixMe) {
    editorAPI.panelManager.openPanel('rEditor.panels.applyStylePanel', {
      selectedComp: comp,
    });
  }

  function openHelpCenter(
    helpId: AnyFixMe,
    appDefId: AnyFixMe,
    props: AnyFixMe,
    biHelpParams: AnyFixMe,
  ) {
    console.error(
      'editorAPI.help.openHelpCenter is deprecated. Use panelManager.openHelpCenter instead',
    );
    editorAPI.panelManager.openHelpCenter(helpId, props, biHelpParams);
  }

  function isSameRef(
    compA: CompRef | CompRef[],
    compB: CompRef | CompRef[],
  ): boolean {
    const compArrA = asArray(compA);
    const compArrB = asArray(compB);

    if (isMultiselect(compArrA) || isMultiselect(compArrB)) {
      if (compArrA.length !== compArrB.length) {
        return false;
      }

      const sortedCompArrA = _.sortBy(compArrA, 'id');
      const sortedCompArrB = _.sortBy(compArrB, 'id');

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      const areRefArraysEqual = _.every(
        sortedCompArrA,
        function (compRef, index) {
          return editorAPI.dsRead.utils.isSameRef(
            compRef,
            sortedCompArrB[index],
          );
        },
      );

      return areRefArraysEqual;
    }

    return editorAPI.dsRead.utils.isSameRef(_.head(compArrA), _.head(compArrB));
  }

  function isPage(compRefs: CompRef | CompRef[]): boolean {
    const components = asArray(compRefs);

    if (isMultiselect(components)) {
      return false;
    }

    return editorAPI.dsRead.utils.isPage(components[0]);
  }

  function isSiteSegment(compRefs: AnyFixMe) {
    const components = asArray(compRefs);

    if (isMultiselect(components)) {
      return false;
    }

    const siteSegments = [
      editorAPI.dsRead.siteSegments.getHeader(),
      editorAPI.dsRead.siteSegments.getFooter(),
      editorAPI.dsRead.siteSegments.getSiteStructure(),
    ];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return !!_.find(siteSegments, (siteSegment) =>
      isSameRef(siteSegment, components[0]),
    );
  }

  function getParentIfCompCanBeSelected(compRef: CompRef): CompRef | null {
    if (!editorAPI.components.is.selectable(compRef)) {
      return editorAPI.components.getContainerOrScopeOwner(compRef);
    }
    return null;
  }

  /**
   * @deprecated use editorAPI.components.findAncestor OR editorAPI.components.someAncestor
   */
  function findAncestorMatchesCondition(
    compRefOrRefs: CompRef | CompRef[],
    condition: (ancestorRef: CompRef) => boolean,
  ) {
    return editorAPI.components.findAncestor(compRefOrRefs, condition, {
      includeScopeOwner: true,
    });
  }

  type ValidationResult = ReturnType<
    typeof editorAPI.dsRead.siteName.validate
  > & {
    errorMessage?: string;
  };

  function validateSiteName(name: string): ValidationResult {
    const result: ValidationResult = editorAPI.dsRead.siteName.validate(name);
    const errorKeys: AnyFixMe = {};
    errorKeys[editorAPI.dsRead.siteName.ERRORS.SITE_NAME_IS_EMPTY] =
      'SAVE_SITE_NAME_IS_EMPTY';
    errorKeys[editorAPI.dsRead.siteName.ERRORS.SITE_NAME_IS_NOT_STRING] =
      'SITE_NAME_IS_NOT_STRING';
    errorKeys[editorAPI.dsRead.siteName.ERRORS.SITE_NAME_INVALID_CHARS] =
      'SAVE_SITE_NAME_INVALID_CHARS';
    errorKeys[editorAPI.dsRead.siteName.ERRORS.SITE_NAME_ENDS_WITH_HYPHEN] =
      'SAVE_SITE_NAME_ENDS_WITH_HYPHEN';
    errorKeys[editorAPI.dsRead.siteName.ERRORS.SITE_NAME_TOO_SHORT] =
      'SAVE_SITE_NAME_TOO_SHORT';
    errorKeys[editorAPI.dsRead.siteName.ERRORS.SITE_NAME_TOO_LONG] =
      'SAVE_SITE_NAME_TOO_LONG';
    errorKeys[editorAPI.dsRead.siteName.ERRORS.SITE_NAME_ALREADY_EXISTS] =
      'SAVE_SITE_NAME_ALREADY_EXISTS';

    (result as AnyFixMe).errorMessage = errorKeys[result.errorCode];
    return result;
  }

  function sanitizeSiteName(name: string) {
    return editorAPI.dsActions.siteName.sanitize(name);
  }

  function setPublicUrlIfNeeded(name: string) {
    const state = editorAPI.store.getState();

    if (!domainSelectors.isDomainConnected(editorAPI.dsRead)) {
      editorAPI.dsActions.generalInfo.setPublicUrl(
        domainSelectors.getFreeDomainPrefix(state, editorAPI.dsRead) + name,
      );
    }
  }

  function setSiteNameAsync(
    name: string,
    onSuccess: Function,
    onError: Function,
    shouldValidate = true,
  ) {
    editorAPI.dsActions.siteName.setAsync(
      name,
      (result: AnyFixMe) => {
        if (result.success) {
          setPublicUrlIfNeeded(name);
          if (!shell.getAPI(EditorParamsApiKey).isInsideAppStudio) {
            setSiteNameInBrowserTitle(name);
          }
          onSuccess(result);
          return;
        }

        onError(result);
      },
      onError,
      { validate: shouldValidate },
    );
  }

  function setSiteNameInBrowserTitle(siteName: string) {
    util.browserUtil.setTabTitle(siteName);
  }

  function renameSiteName(
    name: string,
    onSuccess?: () => void,
    onError?: () => void,
  ) {
    let RENAME_SITE_URL =
      util.serviceTopology.editorServerRoot ||
      '//editor.wix.com/html/editor/web';
    RENAME_SITE_URL += `/meta-site-proxy/rename/${editorAPI.dsRead.generalInfo.getMetaSiteId()}`;
    const form = new FormData();
    form.append('newName', name);

    new Promise((resolve, reject) => {
      editorAPI.dsActions.siteName.setAsync(name, resolve, reject);
    })
      .then(() =>
        util.http.fetch(RENAME_SITE_URL, {
          method: 'POST',
          credentials: 'same-origin',
          body: new URLSearchParams(form as any),
        }),
      )
      .then(() => {
        setSiteNameInBrowserTitle(name);

        editorAPI.store.dispatch(
          stateManagement.dealer.actions.fetchDealerPostPublish(true),
        );

        setPublicUrlIfNeeded(name);

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(onError || _.noop);
  }

  function isToolbarEnabled() {
    return store.getState().viewTools.toolbarEnabled;
  }

  function toggleToolbar() {
    const newState = !editorAPI.toolbar.isToolbarEnabled();
    editorAPI.updateEditorViewTools('toolbarEnabled', newState);
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.TOOLBAR_ENABLED,
      newState,
    );
  }

  function isDeveloperToolbarEnabled() {
    return store.getState().viewTools.developerToolBarEnabled;
  }

  function isDeveloperToolbarInCodePanel() {
    const isLocalModeDisabled = !util.localModeUtils.isLocalModeEnabled();
    return isLocalModeDisabled;
  }

  function toggleDeveloperToolbar() {
    if (shouldOpenPropertiesPanelAsDockedSection()) {
      store.dispatch(
        stateManagement.sectionedPanel.actions.toggleSection(
          constants.DOCKED_PANEL_SECTIONS.PROPERTIES,
        ),
      );
      return;
    }

    const isEnablingToolbar = !editorAPI.developerToolBar.isEnabled();
    const isCodePanelMinimized =
      editorAPI.developerMode.ui.idePane.getState() ===
      constants.DEVELOPER_MODE.CONTAINER_STATES.MINIMIZED;
    if (
      isDeveloperToolbarInCodePanel() &&
      isEnablingToolbar &&
      isCodePanelMinimized
    ) {
      editorAPI.developerMode.ui.idePane.unMinimize(_.noop);
    }
    editorAPI.updateEditorViewTools(
      'developerToolBarEnabled',
      isEnablingToolbar,
    );
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.DEVELOPER_TOOLBAR_ENABLED,
      isEnablingToolbar,
    );
  }

  function isDeveloperHiddenComponentsEnabled() {
    return store.getState().viewTools.showHiddenComponents;
  }

  function toggleDeveloperHiddenComponents() {
    const showHiddenComponents =
      !editorAPI.developerHiddenComponents.isEnabled();
    editorAPI.updateEditorViewTools(
      'showHiddenComponents',
      showHiddenComponents,
    );
    editorAPI.dsActions.documentMode.showHiddenComponents(showHiddenComponents);
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.SHOW_HIDDEN_COMPS,
      showHiddenComponents,
    );
  }

  function isGuidelinesEnabled() {
    return store.getState().viewTools.guidelinesEnabled;
  }

  function toggleGuidelines(onSuccess: () => void, onFail: () => void): void {
    const isEnabled = !editorAPI.grid.guidelines.isGuidelinesEnabled();
    editorAPI.updateEditorViewTools('guidelinesEnabled', isEnabled);
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.GUIDELINES_ENABLED,
      isEnabled,
    )
      .then(onSuccess)
      .catch(onFail);
  }

  function isSnapToEnabled() {
    return store.getState().viewTools.snapToEnabled;
  }

  function toggleSnapTo(onSuccess: () => void, onFail: () => void): void {
    const isEnabled = !editorAPI.grid.snapTo.isSnapToEnabled();
    editorAPI.updateEditorViewTools('snapToEnabled', isEnabled);
    setSiteUserPreferences(
      constants.USER_PREFS.VIEW_TOOLS.SNAPTO_ENABLED,
      isEnabled,
    )
      .then(onSuccess)
      .catch(onFail);
  }

  function getSiteSegmentsRefs() {
    const { siteSegments } = editorAPI.dsRead;
    return [
      siteSegments.getFooter(),
      siteSegments.getHeader(),
      siteSegments.getPagesContainer(),
      siteSegments.getSiteStructure(),
    ];
  }

  function getPagesContainerAbsLayout() {
    return stateManagement.siteSegments.selectors.getPagesContainerAbsLayout(
      editorAPI,
    );
  }

  function toggleHalfOpacityTools(halfOpacityTools: AnyFixMe) {
    editorAPI.toggleEditorStateParam('halfOpacityTools', halfOpacityTools);
  }

  function toggleHideToolsBtnBlink(shouldHideToolsBtnBlink: AnyFixMe) {
    editorAPI.toggleEditorStateParam(
      'shouldHideToolsBtnBlink',
      shouldHideToolsBtnBlink,
    );
  }

  function toggleHideTools(
    shouldHide?: boolean,
    shouldKeepPreviewTop?: boolean,
  ) {
    const state = store.getState();

    shouldKeepPreviewTop =
      shouldKeepPreviewTop || state.hideToolsAndMaintainStagePosition;
    const newState =
      typeof shouldHide === 'undefined' ? !state.hideTools : shouldHide;
    const LINK_PANEL = 'panels.toolPanels.linkPanel';
    const openPanels = getOpenPanels();

    editorAPI.hideFloatingBubble();

    const lastPreviewPos = getPreviewPosition(state);
    const newPreviewPosition = shouldKeepPreviewTop
      ? lastPreviewPos
      : _.defaults(
          {
            top: getNewPreviewTop(newState),
          },
          lastPreviewPos,
        );

    editorAPI.updateState({
      hideToolsAndMaintainStagePosition: newState
        ? shouldKeepPreviewTop
        : newState,
      hideTools: newState,
      halfOpacityTools: false,
      shouldHideToolsBtnBlink: false,
    });

    store.dispatch(updatePreviewPos(newPreviewPosition));

    if (newState) {
      baseUI.tooltipManager.hideAll();
      _(openPanels)
        .reject({ frameType: constants.PANEL_TYPES.COMP })
        .reject({ name: LINK_PANEL })
        .forEach(function (panel) {
          editorAPI.panelManager.closePanelByName(panel.name);
        });
    }

    return newState;
  }

  function areToolsHiddenAndStagePositionMaintained() {
    return store.getState().hideToolsAndMaintainStagePosition;
  }

  function areToolsHidden() {
    return store.getState().hideTools;
  }

  function isHalfOpacityTools() {
    return store.getState().halfOpacityTools;
  }

  function addBlog(viewName: AnyFixMe, cb: AnyFixMe) {
    blog.installApp(editorAPI, viewName || 'MediaLeftPage', cb);
  }

  function getCursor() {
    return cursorPosition;
  }

  function setCursor(coords: AnyFixMe) {
    cursorPosition = coords;
  }

  function updateCursorMousePosition(x: AnyFixMe, y: AnyFixMe) {
    const coords = { x, y };
    editorAPI.cursor.set(coords);
  }

  function disableOverlayUpdate() {
    editorAPI.updateState({
      shouldOverlayUpdate: false,
    });
  }

  function enableOverlayUpdate() {
    editorAPI.updateState({
      shouldOverlayUpdate: true,
    });
  }

  function shouldUpdateOverlay() {
    return store.getState().shouldOverlayUpdate;
  }

  function getTopBarOpenedDropPanel() {
    return store.getState().topBar.openedDropPanel;
  }

  function openTopBarDropPanel(
    menuItemKey: string,
    menuSubItemKey?: string,
    shouldRemainOpen?: boolean,
    biParams?: { origin: string },
  ): void {
    const topBarState = store.getState().topBar;
    if (!topBarState.dropPanelDisabled) {
      updateState({
        topBar: _.defaults(
          {
            openedDropPanel: menuItemKey,
            dropPanelActiveItem: menuSubItemKey,
            shouldDropPanelRemainOpen: shouldRemainOpen,
          },
          topBarState,
        ),
      });
      if (editorAPI.openPanelTimeout) {
        window.clearTimeout(editorAPI.openPanelTimeout);
      }
      if (
        menuItemKey &&
        menuItemKey !==
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.LANGUAGE_DROP_PANEL
      ) {
        const temporaryDisableSettingsBiDelay =
          menuItemKey === constants.ROOT_COMPS.TOPBAR.DROP_PANELS.SETTINGS &&
          experiment.isOpen('wos.topbar-roles-and-permissions');
        const isPublished = editorAPI.dsRead.generalInfo.isSitePublished();

        editorAPI.openPanelTimeout = window.setTimeout(
          function () {
            editorAPI.bi.event(coreBi.events.topbar.TOP_BAR_PANEL_OPENED, {
              menu_name: menuItemKey,
              is_published: isPublished,
              origin: biParams?.origin,
            });
          },
          temporaryDisableSettingsBiDelay ? 0 : 3000,
        );
      }
    }
  }

  function closeTopBarDropPanel(
    dropPanelName?: string,
    dropPanelSubItem?: string,
  ) {
    if (!store.getState().topBar.shouldDropPanelRemainOpen) {
      const { openedDropPanel } = store.getState().topBar;

      if (!dropPanelName || dropPanelName === openedDropPanel) {
        openTopBarDropPanel(null, dropPanelSubItem ?? null);
      }
    }
  }

  function setTopBarPagesFilter(filter: AnyFixMe) {
    updateState({ topBarPagesFilter: filter });
  }

  function getTopBarPagesFilter() {
    return store.getState().topBarPagesFilter;
  }

  function editorIsInit() {
    return store.getState().editorIsInit;
  }

  function openPagesPanel(panelProps: AnyFixMe, leavePanelsOpen?: AnyFixMe) {
    const openPanelInteraction = fedopsLogger.mapInteraction(
      fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
    );
    const closePanelInteraction = fedopsLogger.mapInteraction(
      fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
    );

    const extendedPanelProps = {
      openPanelInteraction,
      closePanelInteraction,
      ...panelProps,
    };

    return store.dispatch(
      openLeftPanel(
        constants.ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
        extendedPanelProps,
        leavePanelsOpen,
      ),
    );
  }

  function getForceOpenPagesQuickNavigationEventCounter() {
    return store.getState().eventCounters.forceOpenPagesQuickNavigation;
  }

  function getForceOpenPagesPanelEventCounter() {
    return store.getState().eventCounters.forceOpenPagesPanel;
  }

  function setForceOpenPagesQuickNavigationEventCounter() {
    const { eventCounters } = store.getState();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newEventCounters = _.assign({}, eventCounters, {
      forceOpenPagesQuickNavigation:
        eventCounters.forceOpenPagesQuickNavigation + 1,
    });
    store.dispatch(
      stateManagement.services.actions.setState({
        eventCounters: newEventCounters,
      }),
    );
  }

  function openQuickNavigation(editorAPI: AnyFixMe, origin: AnyFixMe) {
    editorAPI.setForceOpenPagesQuickNavigationEventCounter();
    editorAPI.bi.event(coreBi.events.pages.quick_navigation_clicked, {
      origin,
    });
  }

  function setForceOpenPagesPanelEventCounter() {
    const { eventCounters } = store.getState();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newEventCounters = _.assign({}, eventCounters, {
      forceOpenPagesPanel: eventCounters.forceOpenPagesPanel + 1,
    });
    store.dispatch(
      stateManagement.services.actions.setState({
        eventCounters: newEventCounters,
      }),
    );
  }

  function openLinkPanel(props: object, shouldCloseParentPanel?: boolean) {
    return editorAPI.panelHelpers.openLinkPanel(props, shouldCloseParentPanel);
  }

  function showUserActionNotification(options: AnyFixMe) {
    return store.dispatch(
      stateManagement.notifications.actions.showUserActionNotification(options),
    );
  }

  function isPopUpMode() {
    return Boolean(
      isPreviewReady() && editorAPI.dsRead.pages.popupPages.getCurrentPopupId(),
    );
  }

  function reportAddMenuDrag(params: AnyFixMe) {
    editorAPI.bi.event(coreBi.events.addPanel.ADD_MENU_DRAG_COMPONENT, params);
  }

  function setFocus() {
    const { eventCounters } = store.getState();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newEventCounters = _.assign(eventCounters, {
      focus: eventCounters.focus + 1,
    });
    store.dispatch(
      stateManagement.services.actions.setState({
        eventCounters: newEventCounters,
      }),
    );
  }

  let renderCallbacks: AnyFixMe = [];
  let lastInvokedRenderCallback = -1;

  // TODO: avoid using renderCallbacks, onRenderDone is not a reliable function
  function updateState(newState: Partial<EditorState>, callback?: () => void) {
    if (_.isFunction(callback)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/last
      if (_.last(renderCallbacks) === null) {
        renderCallbacks = [];
        lastInvokedRenderCallback = -1;
      }
      renderCallbacks.push(callback);
    }
    const newStateWithCounter = _.defaults(
      {
        __renderCounter: renderCallbacks.length,
      },
      newState,
    );

    store.dispatch(
      stateManagement.services.actions.setState(newStateWithCounter),
    );
  }

  function onRenderDone(renderCounter: AnyFixMe) {
    let i;
    for (i = lastInvokedRenderCallback + 1; i < renderCounter; i++) {
      lastInvokedRenderCallback++;
      if (_.isFunction(renderCallbacks[i])) {
        renderCallbacks[i]();
      }
      renderCallbacks[i] = null;
    }
  }

  const tooltipTextServiceMap: AnyFixMe = {};

  function registerTooltipText(
    type: AnyFixMe,
    operation: AnyFixMe,
    translationKey: AnyFixMe,
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (_.isUndefined(tooltipTextServiceMap[type])) {
      tooltipTextServiceMap[type] = {};
    }

    tooltipTextServiceMap[type][operation] = translationKey;
  }

  function getSelectedComponentTooltipText(operation: AnyFixMe) {
    const selectedComp = editorAPI.selection.getSelectedComponents();
    return getComponentTooltipText(selectedComp, operation);
  }

  function getComponentTooltipText(comp: AnyFixMe, operation: AnyFixMe) {
    const compType = editorAPI.components.getType(comp);

    return getTooltipText(compType, operation);
  }

  function getTooltipText(type: AnyFixMe, operation: AnyFixMe) {
    if (tooltipTextServiceMap[type]) {
      return tooltipTextServiceMap[type][operation] || null;
    }

    return null;
  }

  /**
   * @param {string} value
   * @param {{top: number, left: number}} parentPanelPosition
   * @param {object} options
   * @param {function} options.onChange
   * @param {function} options.onClose
   * @param {boolean} options.enableHistory
   * @param {boolean} options.allowPaletteEditing
   * @param {boolean} options.previewOnHover
   * @param {boolean} options.overridePosition
   * @returns {Function}
   */
  function openColorPicker(
    value: ColorPickerTypes.ColorPickerValue,
    parentPanelPosition: AnyFixMe,
    options: ColorPickerTypes.ColorPickerPanelOwnProps & {
      overridePosition?: boolean;
      allowPaletteEditing?: boolean;
      showPanelHeader?: boolean;
    },
  ) {
    const PANEL_NAME =
      'panels.toolPanels.colorPicker.colorPickerWithGradientsPanel';

    const {
      overridePosition = false,
      allowPaletteEditing = true,
      allowEyeDropper = true,
      showPanelHeader = true,
      enableHistory = true,
      previewOnHover = true,
      onChange,
      onClose,
      onCancel,
      panelMode,
      customInstanceColors,
      showColorModalConfirmButtons,
      origin,
    } = options;

    const RELATIVE_PANEL_POSITION = {
      top: 72,
      left: -12,
    };
    const position = overridePosition
      ? parentPanelPosition
      : {
          top: parentPanelPosition.top + RELATIVE_PANEL_POSITION.top,
          left: parentPanelPosition.left + RELATIVE_PANEL_POSITION.left,
        };
    const palettePresets =
      allowPaletteEditing && editorAPI.theme.colors.getColorPresets();
    const updateSitePalette =
      allowPaletteEditing && editorAPI.theme.colors.update;
    const notifyClosePanel = (panelName: string = PANEL_NAME) => {
      editorAPI.panelManager.closePanelByName(panelName);
    };
    const notifyOpenPanel = (
      panelName: string,
      panelProps: Record<string, any>,
    ) => {
      editorAPI.panelManager.openPanel(panelName, panelProps, true);

      return () => editorAPI.panelManager.closePanelByName(panelName);
    };

    editorAPI.panelManager.openPanel(
      PANEL_NAME,
      {
        origin,
        value,
        onChange,
        onClose,
        onCancel,
        customInstanceColors,
        enableHistory,
        palettePresets,
        updateSitePalette,
        previewOnHover,
        panelMode,
        allowEyeDropper,
        showPanelHeader,
        notifyClosePanel,
        notifyOpenPanel,
        showColorModalConfirmButtons,
        ...options,
        ...position,
      },
      true,
    );
  }

  let _isMobileDiscoverabilityModalShownInCurrentSession: boolean = false;
  /**
   * @param {boolean} value
   */
  function setMobileDiscoverabilityModalShownInCurrentSession(
    value: AnyFixMe,
  ): void {
    _isMobileDiscoverabilityModalShownInCurrentSession = value;
  }

  function isMobileDiscoverabilityModalShownInCurrentSession(): boolean {
    return Boolean(_isMobileDiscoverabilityModalShownInCurrentSession);
  }

  /** @type editorAPI */
  const _editorAPI1 = {
    store,
    addPanelInfra,
    onRenderDone,
    updateState,
    synchronizeSiteSEOToMainPageSEO,
    initTemplateCharacterSet,
    setDocumentServices,
    setSantaPreviewCreator,
    initPlugins,
    getViewTools,
    updateUserPreferencesOnPreviewReady,
    initWelcomeScreen,
    initViewToolsFromUserPrefs,
    initUserAddedColors,
    refreshPreviewPosition: (newPos: AnyFixMe) =>
      store.dispatch(updatePreviewPos(newPos)),
    refreshEditingAreaPosition: updateStateIfChanged('editingAreaPosition'),
    refreshMobileFramePosition: updateStateIfChanged('mobileFramePosition') as (
      rect: EditorState['mobileFramePosition'],
    ) => void,
    setEditBoxRotateKnobRect: updateStateIfChanged('editBoxRotateKnobRect'),
    getOpenPanels,
    notifyViewerUpdate,
    setStateWaitForViewer,
    setStateUpdateCallback,
    getPendingStateChanges,
    preventMouseDown,
    allowMouseDown,
    preventEvent,
    onViewerChanged,
    registerInitUserPrefsCallback,
    exitEditor,
    isTpa,
    registerToViewerChange,
    registerRemovePlugin,
    registerRemovePagePlugin,
    registerComponentsRemoveHandlerPlugin,
    registerHidePlugin,
    registerDuplicatePlugin,
    registerCannotRemovePlugin,
    registerCopyComponentPlugin,
    registerAddComponentPlugin,
    registerAfterPasteComponentCallback,
    registerAfterDuplicateComponentCallback,
    registerPasteComponentPlugin,
    registerSerializeComponentPlugin,
    registerCutComponentPlugin,
    registerPageNavigationCallback,
    registerCompNamePlugin,
    registerSitePublishedCallbacks,
    registerDeviceTypeChangeCallbacks,
    registerAfterDeviceTypeChangeCallbacks,
    registerPrerequisiteForWelcomeScreen,
    registerPostDeepNavigation,
    setRenderPlugins,
    prepareDocumentForPreviewMode,
    getEditBoxRotateKnobRect,
    getEditingAreaPosition,
    getMobileFramePosition,
    isCurrentPageLandingPage,
    isDesktopEditor,
    isMobileEditor,
    resizeOnlyProportionally,
    openCantCopyPastePanel,
    setDesktopEditor,
    setMobileEditor,
    setEditorMode,
    isMobileOptimizedSite,
    setMobileOptimizedSite,
    getLeftBarButtonsBoundingRect,
    closeRightClickMenu,
    disableRightClickMenuCloseOnOuterClick,
    enableRightClickMenuCloseOnOuterClick,
    closeCompPanelIfExists,
    openFirstTimeOrDeprecationPanel,
    getCompDragRestrictions,
    getCompMoveRestrictions,
    getCompRestrictions,
    isPopUpMode,
    reportAddMenuDrag,
    editorIsInit,

    navigateAndOpenPagesPanel,

    openComponentPanel,
    replaceComponentPanel,

    openLinkPanel,
    openPagesPanel,
    openColorPicker,
    showUserActionNotification,
    setForceOpenPagesQuickNavigationEventCounter,
    getForceOpenPagesQuickNavigationEventCounter,
    openQuickNavigation,

    setForceOpenPagesPanelEventCounter,
    getForceOpenPagesPanelEventCounter,
    openLearnMore,
    isPreviewReady,
    setPortalChildren,
    hasExternalDesign,
    setAttachCandidate,
    setConstraintArea,
    setPageSections,
    clearPageSections,

    sectionsHandles: {
      areSectionsHandlesEnabled,
      toggleSectionsHandles,
    },

    setSiteScale,

    snapData: {
      set: setSnapData,
      clear: clearSnapData,
    },

    lasso: {
      setCandidates: setLassoCandidates,
      setLayout: setLassoLayout,
      clear: clearLasso,
    },

    help: {
      openHelpCenter,
    },

    utils: {
      isSameRef,
      isPage,
      isSiteSegment,
      getParentIfCompCanBeSelected,
      findAncestorMatchesCondition,
    },

    siteName: {
      validate: validateSiteName,
      setAsync: setSiteNameAsync,
      sanitize: sanitizeSiteName,
      rename: renameSiteName,
    },

    updateEditorViewTools,

    toolbar: {
      isToolbarEnabled,
      toggleToolbar,
    },
    developerToolBar: {
      isInCodePanel: isDeveloperToolbarInCodePanel,
      isEnabled: isDeveloperToolbarEnabled,
      toggle: toggleDeveloperToolbar,
    },

    developerHiddenComponents: {
      isEnabled: isDeveloperHiddenComponentsEnabled,
      toggle: toggleDeveloperHiddenComponents,
    },

    grid: {
      guidelines: {
        isGuidelinesEnabled,
        toggleGuidelines,
      },

      snapTo: {
        isSnapToEnabled,
        toggleSnapTo,
      },
    },

    siteSegments: {
      getRefs: getSiteSegmentsRefs,
      getPagesContainerAbsLayout,
    },

    tooltipTextService: {
      registerTooltipText,
      getSelectedComponentTooltipText,
      getComponentTooltipText,
    },

    openEditorVersionInfoPanel,

    styles: {
      openApplyStylePanel,
    },

    newEditorWelcomeScreenWrapper,

    showFloatingBubble,
    hideFloatingBubble,

    getSiteScale: () => getSiteScale(store.getState()),
    endMouseMoveActionIfNeeded,
    isVerticalScrollbarHidden,
    onCollectionAdded,
    toggleEditorStateParam,

    toolsControl: {
      toggleHalfOpacityTools,
      toggleHideToolsBtnBlink,
      toggleHideTools,
      areToolsHiddenAndStagePositionMaintained,
      areToolsHidden,
      isHalfOpacityTools,
    },

    blog: {
      add: addBlog,
    },

    cursor: {
      get: getCursor,
      set: setCursor,
      updateMousePosition: updateCursorMousePosition,
    },

    overlay: {
      disableUpdate: disableOverlayUpdate,
      enableUpdate: enableOverlayUpdate,
      shouldUpdate: shouldUpdateOverlay,
    },

    topBarMenuBar: {
      openDropDown: openTopBarDropPanel,
      closeDropDown: closeTopBarDropPanel,
      getOpenedDropDown: getTopBarOpenedDropPanel,
    },

    topBarPagesFilter: {
      set: setTopBarPagesFilter,
      get: getTopBarPagesFilter,
    },

    setFocus,

    setMobileDiscoverabilityModalShownInCurrentSession,
    isMobileDiscoverabilityModalShownInCurrentSession,
  };

  const _editorAPI2 = _editorAPI1;
  const anyEditor = _editorAPI2 as any;

  const onPreviewReady = createOnPreviewReady(anyEditor, {
    shell,
    fetchUserProfile,
  }) as OnPreviewReadyType;

  const _editorAPI3 = assignEditorApi(_editorAPI2, {
    onPreviewReady,
    documentServices: null as DocumentServicesObject,
    editorConfig: getEditorConfig(),
    pendingStateChanges: {} as Partial<EditorState>,
    pendingEditorStateUpdateCallbacks: [] as (() => Partial<EditorState>)[],
    removePlugins: {} as Record<string, RemovePlugin>,
    removePagePlugins: {} as AnyFixMe,
    componentsRemoveHandlerPlugins: [],
    hidePlugins: {} as AnyFixMe,
    duplicatePlugins: {} as Record<string, DuplicatePlugin>,
    compNamePlugins: {} as Record<string, CompNamePlugin>,
    cannotRemovePlugins: {} as Record<string, CannotRemovePlugin>,
    copyComponentPlugins: {} as AnyFixMe,
    pasteComponentPlugins: {} as AnyFixMe,
    cutComponentPlugins: {} as AnyFixMe,
    addComponentPlugins: {} as AnyFixMe,
    serializeComponentPlugins: {} as AnyFixMe,
    pageNavigationCallbacks: [],
    afterPasteComponentCallbacks: [] as AfterPastePlugin[],
    afterDuplicateComponentCallbacks: [] as AfterDuplicatePlugin[],
    viewerUpdateCallbacks: [onViewerChanged] as Array<
      (changedData: string[]) => void
    >,
    userPreferencesInitCallbacks: [
      updateWixCodeValues,
      anyEditor.initViewToolsFromUserPrefs,
      anyEditor.initUserAddedColors,
      initDevModeValues,
      initPartnerInfo,
    ],
    sitePublishedCallbacks: [],
    prerequisiteForWelcomeScreen: [],
    postDeepNavigation: [],
    deviceTypeChangeCallbacks: [],
    afterDeviceTypeChangeCallbacks: [],
    biConditionedEventsQueue: [],
    registeredHideBubbleOnPreview: false,
    isSavedInCurrentSession: false,
    pasteLogic: pasteLogicContext,
    scrollListeners: [],
    updateHooks: [] as any[],
  });

  const editorAPI = _editorAPI3 as typeof _editorAPI3 &
    ApisInEditorAPIType & {
      host: AppHost;
      dsRead: DSRead;
      dsActions: DSAction;
      _stageTextManager: TextManager;
      _stageLinksHelper: typeof textControls.LinksHelper;
      openPanelTimeout: number;
      preventMouseDownTimeout: number;
      shouldUpdateEditorScrollAfterHeightUpdate: boolean;
      rightClickMenuCloseOnOuterClickDisabled: boolean;
      campaignInfo: CampaignInfo;
      addPanel?: any; //TODO
      waitForChangesApplied: DocumentServicesObject['waitForChangesApplied']; //where it's assigned?
      userPreferencesInitCallbacksInvoked: boolean;
      userPreferencesLoaded: boolean;
      generalInfo: GeneralInfoObject;
      clearDSReadCache: (data: { isDragging: boolean }) => void;
      biConditionedEventsQueue: any[]; //TODO
      // TODO fix documentServices language types and remove it
      language: DocumentServicesObject['language'] & {
        component: {
          getTranslations: (compRef: CompRef) => Record<string, CompData>;
        };
        getFull: () => Array<LanguageDefinition>;
        current: {
          get(): string;
        };
        original: {
          get(): null | LanguageDefinition;
        };
      };
      wixDealerClientApi: {
        DealerAssetsLoader: unknown;
      };
      wixReactDealerViewer: {
        DealerViewer: React.ComponentType<DealerViewerProps>;
      };
      isDealerCssLoaded: boolean;
      compatibility: {
        hasOldApp(): boolean;
        hasAppPage(): boolean;
      };
    } & Omit<DSRead, 'history'>;

  return editorAPI;
}

//eslint-disable-next-line @typescript-eslint/naming-convention
export type __EditorAPI = ReturnType<typeof create>;

// @ts-nocheck
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const manageFieldsPanel = {
  label: 'Contact_Form_Fields_Header_Label',
  isApplied: true,
  isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.MANAGE),
  onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.MANAGE),
};

export default {
  mainActions: [manageFieldsPanel],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.DESIGN,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '3bf1c6fd-b3e5-47f3-9ab4-22beb2a1ce4c',
    },
  },
};

import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { cx, hoc, sections as sectionsUtils } from '#packages/util';
import {
  EditorRestrictionsApiKey,
  WorkspaceRightPanelApiKey,
} from '#packages/apis';
import { rulers } from '#packages/stateManagement';

import { ActionsBar } from './ActionsBar/ActionsBar';

import { mapDispatchToProps, mapStateToProps } from './ActionsMapper';
import { AUTOMATION_IDS } from '../automationIds';
import {
  ACTIONS_AREA_RIGHT_MARGIN,
  ACTIONS_AREA_VERTICAL_PADDING,
  FIXED_STAGE_ACTIONS_OFFSET_LEFT,
} from './Actions.constants';

import styles from './Actions.scss';

import type { SectionWithLayout } from '#packages/sections';
import type { ActionIds } from './Actions.constants';
import type { ActionsGroup } from './Actions.types';
import type { UISkin } from '../../skins/skins.types';

export interface ActionsOwnProps {
  sectionLike: SectionWithLayout;
}

export interface ActionsStateProps {
  actions: Record<
    | 'primaryActions'
    | 'secondaryActions'
    | 'collapsibleActions'
    | 'notCollapsibleAction',
    ActionsGroup
  >;
  skin: UISkin;
  isMobile: boolean;
  isZoomMode: boolean;
  disabledActionsIds: ActionIds[];
  shouldHide: boolean;
  shouldShiftActionsRight: boolean;
}

export interface ActionsDispatchProps {
  onActionClick: (id: ActionIds, e: React.MouseEvent) => void;
  logActionClicked: (actionName: ActionIds, disabledActions: string[]) => void;
  openHelpCenter: (helpId: string) => void;
  setIsSectionActionsBarHovered: (hovered: boolean) => void;
  logActionsHovered: () => void;
}

interface ActionsProps
  extends ActionsOwnProps,
    ActionsStateProps,
    ActionsDispatchProps {}

const getElementHeight = (e: Element) => e.getBoundingClientRect().height;
const sum = <T extends number>(arr: T[]) =>
  arr.reduce((acc, curr) => acc + curr, 0);

const getElementHeights = (node: HTMLElement): [number[], number[]] => {
  const notCollapsible = node.querySelectorAll("[data-collapsible='false']");
  const collabsible = node.querySelectorAll("[data-collapsible='true']");

  return [
    Array.from(notCollapsible, getElementHeight),
    Array.from(collabsible, getElementHeight),
  ];
};

const calcMaxHeight = (max: number, req: number[], notRequired: number[]) => {
  let result = sum(req);

  for (const current of notRequired) {
    const next = result + current;

    if (next >= max) {
      return result;
    }
    result = next;
  }

  return result;
};

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();
const preventDefault = (e: React.MouseEvent) => e.preventDefault();
const onContextMenu = (e: React.MouseEvent) => {
  stopPropagation(e);
  preventDefault(e);
};

const ActionsComponent: React.FC<ActionsProps> = ({
  sectionLike,
  actions,
  isMobile,
  isZoomMode,
  disabledActionsIds,
  onActionClick,
  logActionClicked,
  openHelpCenter,
  setIsSectionActionsBarHovered,
  skin,
  shouldHide,
  logActionsHovered,
  shouldShiftActionsRight,
}) => {
  // need state to trigger rerender to get elements height
  const [actionsBarRef, setActionsBarRef] = useState<HTMLDivElement>(null);
  const [maxHeight, setMaxHeight] = useState(0);

  const setHeight = useCallback(() => {
    if (actionsBarRef) {
      const maxH =
        sectionLike.layout?.height - ACTIONS_AREA_VERTICAL_PADDING * 2;

      const height = calcMaxHeight(maxH, ...getElementHeights(actionsBarRef));

      setMaxHeight(height);
    }
    // eslint-disable-next-line
  }, [
    actionsBarRef,
    sectionLike.layout?.height,
    actions.collapsibleActions.length,
  ]);

  useEffect(() => {
    setHeight();
  }, [setHeight]);

  useEffect(() => {
    return () => {
      setIsSectionActionsBarHovered(false);
    };
  }, [setIsSectionActionsBarHovered]);

  const handleActionClick = useCallback(
    (id: ActionIds, event: React.MouseEvent) => {
      onActionClick(id, event);
      logActionClicked(id, disabledActionsIds);
    },
    [onActionClick, disabledActionsIds, logActionClicked],
  );

  const handleMouseEnter = useCallback(() => {
    setIsSectionActionsBarHovered(true);
    logActionsHovered();
  }, [setIsSectionActionsBarHovered, logActionsHovered]);
  const handleMouseLeave = useCallback(() => {
    setIsSectionActionsBarHovered(false);
  }, [setIsSectionActionsBarHovered]);

  const getActionsAreaOffsetX = () => {
    return shouldShiftActionsRight
      ? FIXED_STAGE_ACTIONS_OFFSET_LEFT
      : -ACTIONS_AREA_RIGHT_MARGIN;
  };

  if (shouldHide) return null;

  return (
    <div
      className={cx(styles.actionsArea, {
        [styles.redesign]: sectionsUtils.isSectionsControlsRedesignEnabled(),
        [styles.zoomMode]: isZoomMode,
        [styles.mobile]: isMobile,
        [styles.fixed]: shouldShiftActionsRight,
      })}
      style={
        {
          '--actions-area-vertical-padding': `${ACTIONS_AREA_VERTICAL_PADDING}px`,
          '--actions-area-offset-x': `${getActionsAreaOffsetX()}px`,
        } as React.CSSProperties
      }
      onClick={stopPropagation}
      onDoubleClick={stopPropagation}
      onContextMenu={onContextMenu}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      data-hook={`${AUTOMATION_IDS.ACTIONS_BAR}-wrapper`}
    >
      <div className={styles.invisibleContainer}>
        <ActionsBar
          onActionClick={handleActionClick}
          isMobile={isMobile}
          ref={setActionsBarRef}
          openHelpCenter={openHelpCenter}
          skin={skin}
          {...actions}
        />
      </div>
      <ActionsBar
        onActionClick={handleActionClick}
        isMobile={isMobile}
        maxHeight={maxHeight}
        dataHook={AUTOMATION_IDS.ACTIONS_BAR}
        openHelpCenter={openHelpCenter}
        skin={skin}
        {...actions}
      />
    </div>
  );
};

type DispatchPropsExcluded = Omit<ActionsProps, keyof ActionsDispatchProps>;

/*
We got a different reference for a hovered sectionLike despite that actual value is the same. As a result, mapDispatchToProps triggers and creates new functions with new refs
mapDispatchToProps result are pure functions. Removing them from the comparison allow us to check meaningful for render values
This dramatically decreases render number of actions bar/action items, and, therefore, increases performance
 */

const Memoized = React.memo(ActionsComponent, (prevProps, nextProps) => {
  const prev: DispatchPropsExcluded = {
    sectionLike: prevProps.sectionLike,
    actions: prevProps.actions,
    isMobile: prevProps.isMobile,
    disabledActionsIds: prevProps.disabledActionsIds,
    skin: prevProps.skin,
    shouldHide: prevProps.shouldHide,
    isZoomMode: prevProps.isZoomMode,
    shouldShiftActionsRight: prevProps.shouldShiftActionsRight,
  };
  const next: DispatchPropsExcluded = {
    sectionLike: nextProps.sectionLike,
    actions: nextProps.actions,
    isMobile: nextProps.isMobile,
    disabledActionsIds: nextProps.disabledActionsIds,
    skin: nextProps.skin,
    shouldHide: nextProps.shouldHide,
    isZoomMode: nextProps.isZoomMode,
    shouldShiftActionsRight: nextProps.shouldShiftActionsRight,
  };

  return _.isEqual(prev, next);
});

const Connected = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(Memoized);

export const Actions = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  ({ editorAPI, state }) => {
    const editorRestrictionsApi = editorAPI.host.getAPI(
      EditorRestrictionsApiKey,
    );
    if (!editorRestrictionsApi.allowed('actions-bar.visible')) {
      return false;
    }
    if (rulers.selectors.isHovered(state)) return false;

    if (sectionsUtils.isSectionsControlsRedesignEnabled()) {
      return true;
    }

    const isWorkspaceRightPanelOpen = editorAPI.host
      .getAPI(WorkspaceRightPanelApiKey)
      .isOpen();

    return !isWorkspaceRightPanelOpen;
  },
)(Connected);

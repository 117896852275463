// @ts-nocheck
import * as stateManagement from '#packages/stateManagement';
const { setSpotlight } = stateManagement.layers.actions;
const { getSpotlightComponent } = stateManagement.layers.selectors;

const setSpotlightComponent = (editorAPI, compRef) => {
  editorAPI.store.dispatch(setSpotlight(compRef));
};

const getAncestors = (editorAPI, compRef) => {
  const ancestors =
    editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(compRef);
  if (ancestors?.length) {
    const lastIndex = ancestors.length - 1;
    if (
      ancestors[lastIndex].id ===
      editorAPI.documentServices.pages.getMasterPageId()
    ) {
      return ancestors.slice(0, lastIndex);
    }
  }

  return ancestors;
};

const changeVisibilityOfAllComponents = (editorAPI, changeVisibilityFunc) => {
  const topContainers = [
    editorAPI.documentServices.siteSegments.getHeader(),
    editorAPI.documentServices.siteSegments.getFooter(),
    editorAPI.documentServices.pages.getFocusedPage(),
  ];

  topContainers.forEach((container) => {
    const allCompsInContainer =
      editorAPI.components.get.byAncestor_DEPRECATED_BAD_PERFORMACE(container);
    allCompsInContainer.forEach((childCompRef) => {
      changeVisibilityFunc(childCompRef);
    });
  });
};

const changeVisibilityOfDirectPathToComponent = (
  editorAPI,
  compRef,
  changeVisibilityFunc,
) => {
  const ancestors = getAncestors(editorAPI, compRef) || [];
  ancestors.forEach((childCompRef) => {
    changeVisibilityFunc(childCompRef);
  });

  changeVisibilityFunc(compRef);
};

const isComponentSpotlighted = (editorAPI, component) => {
  const spotlight = getSpotlightComponent(editorAPI.store.getState());
  return editorAPI.utils.isSameRef(spotlight, component);
};

const turnSpotlightOff = (editorAPI) => {
  const { renderPlugins } = editorAPI.documentServices;
  changeVisibilityOfAllComponents(editorAPI, renderPlugins.showComp);
  setSpotlightComponent(editorAPI, null);
};

const turnSpotlightOn = (editorAPI, component) => {
  const { renderPlugins } = editorAPI.documentServices;
  changeVisibilityOfAllComponents(editorAPI, renderPlugins.hideComp);
  setSpotlightComponent(editorAPI, component);
  changeVisibilityOfDirectPathToComponent(
    editorAPI,
    component,
    renderPlugins.showComp,
  );
};

const toggleSpotlight = (editorAPI, currentCompRef) => {
  if (isComponentSpotlighted(editorAPI, currentCompRef)) {
    turnSpotlightOff(editorAPI);
  } else {
    turnSpotlightOn(editorAPI, currentCompRef);
  }
};

export { isComponentSpotlighted, toggleSpotlight, setSpotlightComponent };

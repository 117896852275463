import type { EntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  SelectionApiKey,
  ComponentsApiKey,
} from '#packages/apis';
import { TextEditorApiKey } from './publicApiKey';
import { createTextEditorApi } from './textEditorApi';
import { textAfterDuplicatePlugin } from './afterDuplicatePlugin';

export const TextEditorApiEntryPoint: EntryPoint = {
  name: 'TextEditorApi',
  declareAPIs: () => [TextEditorApiKey],
  getDependencyAPIs: () => [EditorAPIKey, SelectionApiKey, ComponentsApiKey],
  attach(shell) {
    shell.contributeAPI(TextEditorApiKey, () => createTextEditorApi(shell));

    const editorAPI = shell.getAPI(EditorAPIKey);
    editorAPI.registerAfterDuplicateComponentCallback(textAfterDuplicatePlugin);
  },
};

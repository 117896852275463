import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

// NOTE: code is moved from https://github.com/wix-private/santa-editor/pull/44091

const SITE_SEGMENTS = new Set([
  'SITE_HEADER',
  'PAGES_CONTAINER',
  'SITE_FOOTER',
]);
export function getLastSectionMinHeightBySOAPComponents(
  editorAPI: EditorAPI,
  _lastSectionRef: CompRef,
  {
    layoutInitial,
  }: {
    layoutInitial: { y: number; height: number };
  },
) {
  if (editorAPI.isMobileEditor()) {
    return 0;
  }

  const soapElements = editorAPI.components
    .getChildren(editorAPI.components.get.byId('masterPage'))
    .filter(
      (compRef) =>
        !SITE_SEGMENTS.has(compRef.id) &&
        editorAPI.components.is.visible(compRef),
    );

  if (!soapElements.length) {
    return 0;
  }

  const footer = editorAPI.siteSegments.getFooter();
  const { y: footerY } =
    editorAPI.components.layout.getRelativeToScreen(footer);
  const bottomBySoapElements = Math.max(
    ...soapElements.map((ref) => {
      const layout = editorAPI.components.layout.getRelativeToScreen(ref);
      const fixedPosition = editorAPI.components.layout.get_fixedPosition(ref);
      // reparenting occurs later on in [Thunder?]Bolt, so need to filter SOAPS which reside above footer
      // they can overlap footer a bit, in this case we still need to enlarge
      return fixedPosition || layout.y >= footerY
        ? 0
        : layout.y + layout.height;
    }),
  );

  return bottomBySoapElements - layoutInitial.y;
}

export function getSectionMinHeightBySOAPComponents(
  editorAPI: EditorAPI,
  compRef: CompRef,
  { layoutInitial }: { layoutInitial?: { y: number; height: number } },
) {
  const pageRef = editorAPI.pages.getCurrentPage();
  const pageSections =
    editorAPI.sections.getPageSectionsSortedByStageOrder(pageRef);
  const lastSectionRef = pageSections[pageSections.length - 1];

  if (editorAPI.utils.isSameRef(lastSectionRef, compRef)) {
    return getLastSectionMinHeightBySOAPComponents(editorAPI, lastSectionRef, {
      layoutInitial,
    });
  }

  return 0;
}

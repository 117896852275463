import React from 'react';
import { WithRestrictionsRender } from '#packages/editorRestrictions';
import {
  ContextMenu,
  ContextMenuAction,
  ContextMenuContent,
  SectionDivider,
  Tooltip,
} from '@wix/wix-base-ui';
import { SymbolWrapper } from '#packages/baseUI';
import { translate } from '#packages/i18n';
import styles from './footerContextMenu.scss';
import type { MenuAddItemAction } from '#packages/menu';

interface FooterContextMenuProps {
  addActions: MenuAddItemAction[];
  customButton: React.ReactNode;
  onContextMenuItemsHover: (category: string, target: string) => void;
  category: string;
}

const FooterContextMenu: React.FC<FooterContextMenuProps> = ({
  addActions,
  customButton,
  onContextMenuItemsHover,
  category,
}) => {
  return (
    <div>
      <WithRestrictionsRender name={'menu-panel_add-item'}>
        {({ disabled }) => (
          <ContextMenu
            disabled={disabled}
            alignment="RIGHT"
            dataHook={'footer-context-menu'}
            customButton={customButton}
          >
            <ContextMenuContent>
              {addActions.map((action) => {
                if (action.type === 'divider') {
                  return (
                    <div className={styles.dividerWrapper}>
                      <SectionDivider>
                        <div className={styles.dividerContent}>
                          <span>
                            {translate(
                              'custom_menu_manage_menu_items_section_title',
                            )}
                          </span>
                        </div>
                      </SectionDivider>
                    </div>
                  );
                }

                return (
                  <ContextMenuAction
                    key={action.symbolName}
                    onClick={action.onClick}
                    disabled={action.disabled}
                    dataHook={action.automationId}
                    automationId={action.automationId}
                    className={`sortable-list-item__context-menu-action ${action.automationId}`}
                  >
                    <Tooltip
                      interactive
                      alignment="TOP"
                      disabled={!action.isShowTooltip}
                      content={action.tooltipContent}
                      automationId={`${action.automationId}-tooltip`}
                    >
                      <div
                        className={styles.contextMenuActionWrapper}
                        onMouseEnter={() =>
                          onContextMenuItemsHover(category, action.automationId)
                        }
                      >
                        <SymbolWrapper
                          className="symbol sortable-list-item__context-menu-action-icon"
                          name={action.symbolName}
                        />
                        <span className="sortable-list-item__context-menu-action-text">
                          {action.title}
                        </span>
                      </div>
                    </Tooltip>
                  </ContextMenuAction>
                );
              })}
            </ContextMenuContent>
          </ContextMenu>
        )}
      </WithRestrictionsRender>
    </div>
  );
};

export default FooterContextMenu;

import { sections } from '#packages/util';
import { getClosestSectionLikeForFWE } from '../../../utils';

import type { AfterDuplicatePlugin } from 'types/editorPlugins';
import type { Scope } from '#packages/sections';

export const createAfterDuplicateComponentPlugin =
  (scope: Scope): AfterDuplicatePlugin =>
  async (editorAPI, compRefs, _originalComponentRefs) => {
    const sectionLikeRef = getClosestSectionLikeForFWE(scope, compRefs);
    if (!sectionLikeRef) return;

    const compLayout = editorAPI.components.layout.get_position(compRefs[0]);

    await sections.rearrangeNewSectionElement(editorAPI, {
      container: sectionLikeRef,
      mouseY: compLayout.y,
      component: compRefs[0],
      shouldGrowSection: false,
    });
  };

import _ from 'lodash';
import { translate } from '#packages/i18n';
import experiment from 'experiment';
import * as mediaManagerPanelTypes from '../../mediaManagerPanelTypes/mediaManagerPanelTypes';
import React from 'react';
import { Button, Composites, Preloader, TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import PanelSection from './section';
import Preset from './preset';
import type { MediaManagerItem } from '#packages/mediaServices';
import { MediaType } from '#packages/mediaServices';

const DEFAULT_PRESET_WIDTH = 88;
const PRESET_HEIGHT = 90;
const PRESETS_GAP = 18;

interface PresetSectionProps {
  id: string;
  title: string;
  items: MediaManagerItem[];
  headerActions?: AnyFixMe[];
  options?: {
    loaderContainerHeightInRows?: number;
    mockLoadingItemsCount?: number;
    shouldShowLoaderWhenNoItems?: boolean;
    shouldPresetHaveBlueBackground?: boolean;
    folderId?: string;
  };
  shouldDisplayPresetName?: boolean;
  actionLabel?: string;
  presetWidth?: number;
  automationId?: string;
  contentStyle?: React.CSSProperties;

  action?: () => void;
  helpTitle?: string;
  helpDescription?: string;
  showSectionHeader?: boolean;
  disabledHeaderMargins?: boolean;
  disabledContentPaddings?: boolean;
}

interface PresetSectionState {}

const getItemComponentType = (item: AnyFixMe) => {
  switch (item.mediaType) {
    case MediaType.Picture:
      return 'wysiwyg.viewer.components.WPhoto';
    default:
      return null;
  }
};

class PresetSection extends React.Component<
  PresetSectionProps,
  PresetSectionState
> {
  static displayName = 'MediaManagerPanelPresetSection';
  static propTypes = mediaManagerPanelTypes.presetSection;

  getPresetWidth = () => {
    return this.props.presetWidth || DEFAULT_PRESET_WIDTH;
  };

  getActionLabel = () => {
    return (
      this.props.actionLabel ||
      (experiment.isOpen('se_mediaPanelShowRecommendedMedia')
        ? translate('Media_Panel_Results_Show_All_Label_OnHover')
        : translate('Media_Panel_Results_Show_More_Label_OnHover'))
    );
  };

  hasItems = () => {
    return !_.isEmpty(this.props.items);
  };

  shouldDisplayPresetName = () => {
    return this.props.shouldDisplayPresetName;
  };

  shouldShowItems = () => {
    return this.hasItems();
  };

  getMockItemsCount = () => {
    return this.props?.options?.mockLoadingItemsCount ?? 0;
  };

  shouldShowMocksWhenNoItems = () => {
    const mockItemsCount = this.getMockItemsCount();

    return !this.hasItems() && Boolean(mockItemsCount);
  };

  shouldShowLoaderWhenNoItems = () => {
    return (
      !this.hasItems() &&
      !this.shouldShowMocksWhenNoItems() &&
      (this.props?.options?.shouldShowLoaderWhenNoItems ?? false)
    );
  };

  getLoaderContainerHeight = () => {
    const rowsCount = this.props?.options?.loaderContainerHeightInRows || 1;

    return rowsCount * (PRESET_HEIGHT + PRESETS_GAP);
  };

  getNoItemsLoaderText = () => {
    return 'Media_Panel_Recommended_Loading_Text';
  };

  shouldSetBlueBackgroundToPreset = () => {
    return Boolean(
      this.props?.options?.shouldPresetHaveBlueBackground ?? false,
    );
  };

  getItemPrice = (item: AnyFixMe) => {
    return _.invoke(this.props, 'getItemPrice', item);
  };

  handleItemPurchase = (item: AnyFixMe) => {
    _.invoke(this.props, 'buyItem', item, this.props.id);
  };

  handleItemClick = (item: AnyFixMe, index: AnyFixMe) => {
    _.invoke(this.props, 'onItemClick', {
      id: this.props?.id,
      folderId: this.props?.options?.folderId,
      item,
      index,
      sectionTitle: this.props.title,
    });
  };

  handleItemDrag = (event: AnyFixMe, dragItemInfo: AnyFixMe) => {
    _.invoke(this.props, 'startItemDrag', {
      event,
      dragItemInfo,
      folderId: this.props?.options?.folderId,
      id: this.props?.id,
    });
  };

  registerItem = (item: AnyFixMe) => {
    _.invoke(this.props, 'registerItem', item);
  };

  generateMockItems = () => {
    const mockItemsCount = this.getMockItemsCount();

    return _(mockItemsCount)
      .range()
      .map((i) => ({ id: `mock-${i}` }))
      .value();
  };

  getDataHook = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/to-lower
    return `media-manager-panel-${_.toLower(this.props.id)}_section`;
  };

  render() {
    return (
      <PanelSection
        title={this.props.title}
        headerActions={this.props.headerActions}
        dataHook={this.getDataHook()}
        contentStyle={this.props.contentStyle}
        automationId={this.props.automationId}
        helpTitle={this.props.helpTitle}
        helpDescription={this.props.helpDescription}
        showSectionHeader={this.props.showSectionHeader}
        disabledHeaderMargins={this.props.disabledHeaderMargins}
        disabledContentPaddings={this.props.disabledContentPaddings}
      >
        {this.shouldShowItems() ? (
          <div
            key="section-content-items"
            className="media-manager-panel-preset-section__content"
          >
            <ul className="media-manager-panel-preset-section__presets">
              {this.props.items.map((item, itemIndex) => (
                <li
                  style={{ width: this.getPresetWidth() }}
                  key={item.id}
                  className="media-manager-panel-preset-section__preset"
                >
                  <Preset
                    item={item}
                    index={itemIndex}
                    width={this.getPresetWidth()}
                    priceInfo={this.getItemPrice(item)}
                    onBuyItem={this.handleItemPurchase}
                    onClick={this.handleItemClick}
                    onItemDrag={this.handleItemDrag}
                    registerItem={this.registerItem}
                    shouldDisplayName={this.shouldDisplayPresetName()}
                    isBackgroundBlue={this.shouldSetBlueBackgroundToPreset()}
                    dataCompType={getItemComponentType(item)}
                  />
                </li>
              ))}
            </ul>

            {this.props.action ? (
              <div
                key="sectionAction"
                className="media-manager-panel-preset-section__action-wrapper"
              >
                <Button
                  onClick={() => {
                    this.props.action();
                  }}
                  className="media-manager-panel-preset-section__action btn-sm btn-light"
                >
                  <span className="media-manager-panel-preset-section__action-content-wrapper">
                    <span className="media-manager-panel-preset-section__action-text">
                      {this.getActionLabel()}
                    </span>
                    <symbols.symbol name="arrowRightSmall" />
                  </span>
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}

        {this.shouldShowMocksWhenNoItems() ? (
          <div
            key="section-content-items-mocks"
            className="media-manager-panel-preset-section__content-mocks"
          >
            <ul className="media-manager-panel-preset-section__presets">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.generateMockItems(), (mockItem) => (
                <li
                  key={mockItem.id}
                  className="media-manager-panel-preset-section__preset media-manager-panel-preset-section__preset-mock"
                />
              ))}
            </ul>
          </div>
        ) : null}

        {this.shouldShowLoaderWhenNoItems() ? (
          <div
            style={{ height: this.getLoaderContainerHeight() }}
            key="no-items-loader"
            className="media-manager-panel-preset-section__loader"
          >
            <Composites.Preloader>
              <Preloader className="medium" />
              <TextLabel value={this.getNoItemsLoaderText()} />
            </Composites.Preloader>
          </div>
        ) : null}
      </PanelSection>
    );
  }
}

export default PresetSection;

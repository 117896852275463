import { selection } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

/**
 * Fix for https://jira.wixpress.com/browse/WEED-28295
 * Triggers wixData refresh for wrichText after fontSize change
 */
const triggerRefreshBindingIfNeededMobile = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  if (!editorAPI.isMobileEditor()) return;

  const isConnectedToDataBinding =
    selection.selectors.isSelectedCompConnectedToDataBindingController(
      editorAPI.store.getState(),
      editorAPI.dsRead,
    );

  if (!isConnectedToDataBinding) return;

  editorAPI.dsActions.components.data.update(
    compRef,
    editorAPI.dsRead.components.data.get(compRef),
  );
};

export { triggerRefreshBindingIfNeededMobile };

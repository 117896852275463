import { translate } from '#packages/i18n';
import { notifications, panels } from '#packages/stateManagement';

import { TRANSLATIONS } from '../../../translations';
import { isHeaderOrFooter } from '../../../api';

import type { Scope } from '#packages/sections';
import type { CompRef } from 'types/documentServices';

export const createCannotCopyOrDuplicateSiteSegment =
  (scope: Scope) =>
  ([compRef]: CompRef[]) => {
    const { editorAPI } = scope;

    if (!isHeaderOrFooter(scope, compRef)) return;

    editorAPI.store.dispatch(
      notifications.actions.showUserActionNotification({
        message: translate(
          TRANSLATIONS.NOTIFICATIONS.CANNOT_DUPLICATE_SITE_SEGMENT,
        ),
        type: 'warning',
        shouldTranslate: false,
        link: {
          caption: translate('Notifications_Learn_More_Link'),
          onNotificationLinkClick: () => {
            editorAPI.store.dispatch(
              panels.actions.openHelpCenter(
                '8881ee39-42d9-4f76-a050-9cf6c27a2f2f',
              ),
            );
          },
        },
      }),
    );
  };

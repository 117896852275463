import _ from 'lodash';
import { link } from '#packages/util';
import { ADVANCED_MENU_TYPE, PAGES_MENU_ID } from '../../constants';
import { cleanId, isDropdown, isMembersMenuId } from '../../utils/utils';
import type { CompRef, MenuData, PageLink } from '@wix/document-services-types';
import type { EditorAPI } from '#packages/editorAPI';
import type { MenuAPI } from '../../API/menuAPI';
import type { DataForMigration } from '../getMenusUsageData';

const { isPageLink } = link.linkTypeValidators;

export const getMergedPagesMenuItems = (
  oldMenu: MenuData,
  newMenu: MenuData,
  menuAPI: MenuAPI,
) => {
  const newMenuItems = newMenu.items;
  const newMenuPages = menuAPI.getMenuPagesIds(newMenu.id);

  // pages menu of this site type does not have subitems in structure
  const oldMenuUniqItems = oldMenu.items.filter((oldMenuItem) => {
    if (isDropdown(oldMenuItem)) {
      return !newMenuItems.some(
        (newMenuItem) =>
          isDropdown(newMenuItem) && newMenuItem.label === oldMenuItem.label,
      );
    }
    if (isPageLink(oldMenuItem.link)) {
      const pageId = cleanId((oldMenuItem.link as PageLink).pageId);
      return !newMenuPages.includes(pageId);
    }
    return false;
  });

  const hiddenOldMenuUniqItems = oldMenuUniqItems.map((oldMenuItem) => ({
    ...oldMenuItem,
    isVisible: false,
    isVisibleMobile: false,
  }));

  return [...newMenuItems, ...hiddenOldMenuUniqItems];
};

export const migrateCustomMenusToAdvancedMenus = (
  editorAPI: EditorAPI,
  menuAPI: MenuAPI,
  menuComps: CompRef[],
  dataForMigration: DataForMigration,
) => {
  const { headerMenu, mobileMasterMenu, pagesMenu, menusList } =
    dataForMigration;

  const masterMenu: MenuData =
    headerMenu || mobileMasterMenu || _.head(menusList);

  if (masterMenu) {
    const newPagesMenuItems = getMergedPagesMenuItems(
      pagesMenu,
      masterMenu,
      menuAPI,
    );

    menuAPI.replaceMenuItems(PAGES_MENU_ID, newPagesMenuItems);
  }

  const isMasterMenuMain = !masterMenu || masterMenu.syncWithPages;

  menuComps.forEach((comp) => {
    const compMenu = menuAPI.getMenuByCompRef(comp);

    if (isMembersMenuId(compMenu.id)) {
      return;
    }

    const isConnectedToMasterMenu = isMasterMenuMain
      ? compMenu.syncWithPages
      : masterMenu.id === compMenu.id;

    if (isConnectedToMasterMenu) {
      menuAPI.connect(PAGES_MENU_ID, comp);

      if (!isMasterMenuMain) {
        menuAPI.removeMenu(compMenu.id);
      }
    } else if (compMenu.menuType !== ADVANCED_MENU_TYPE) {
      editorAPI.temporaryAdvancedMenuAPI.createMenu(comp, compMenu.items);
    }
  });

  return editorAPI.waitForChangesAppliedAsync();
};

import React from 'react';
import { createEntryPoint } from '#packages/apilib';
import { ComponentFocusModeBar } from './components/componentFocusModeBar';
import { ComponentFocusModeApi } from './componentFocusModeApi';
import { ComponentFocusModeApiKey } from './publicApiKey';
import { ComponentFocusModeScope } from './componentFocusModeScope';

export const ComponentFocusModeApiEntryPoint = createEntryPoint({
  name: 'ComponentFocusModeAPI',
  Scope: ComponentFocusModeScope,
  publicApi({ contributeApi }) {
    contributeApi(ComponentFocusModeApiKey, ComponentFocusModeApi);
  },
  async initialize({
    workspace,
    editorCoreApi,
    editorAPI,
  }: ComponentFocusModeScope) {
    await editorCoreApi.hooks.initReady.promise;
    workspace.contributeStageComponent(() => <ComponentFocusModeBar />);
    editorAPI.pages.hooks.beforePageNavigate.tap(async () => {
      if (editorAPI.componentFocusMode.isEnabled()) {
        editorAPI.componentFocusMode.exit();
      }
    });
  },
});

import React from 'react';
import {
  Card,
  Icon as UIIcon,
  Button,
  Icons,
  IconLayouts,
  IconColors,
  TooltipCard,
  Title,
} from '@wix/editor-search-ui';
import { RichText, Tooltip } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

import { cx, media } from '#packages/util';

import { AUTOMATION_IDS } from '../../automationIds';
import { IconTypes } from '../../services/searchModule/constants';

import styles from './SearchItem.scss';

interface SearchItemProps {
  description: string;
  title: string;
  cta: string;
  icon: { type: string; value: string };
  isSelected?: boolean;
  isSuccessIconVisible?: boolean;
  onClick(): void;
  onHover(): void;
  onConditionalCTAHover(): void;
  onRef(ref: HTMLElement): void;
  shouldScrollIntoView?: boolean;
  onTooltipEnterLeave(): void;
  onTooltipCTAClick(): void;
  isTooltipForceShown?: boolean;
  tooltipData?: {
    cta: string;
    description: string;
  };
}

export class SearchItem extends React.Component<SearchItemProps> {
  getIcon() {
    const {
      icon: { type, value },
    } = this.props;

    const iconSize = 36;

    if (type === IconTypes.URL) {
      return (
        <img
          width={iconSize}
          height={iconSize}
          alt=""
          src={media.getResizedImage(value, iconSize * 2)}
        />
      );
    }

    const Icon = Icons[value as keyof typeof Icons];
    return Icon ? <Icon /> : <Icons.Settings />;
  }

  handleTooltipCTAClick = (e: React.MouseEvent) => {
    e.preventDefault();
    this.props.onTooltipCTAClick();
  };

  getItemConditionalTooltipProps = () => {
    const {
      onTooltipEnterLeave,
      isTooltipForceShown,
      tooltipData,
      isSelected,
    } = this.props;

    const { cta, description } = tooltipData;

    return {
      content: (
        <RichText>
          <div
            onMouseEnter={onTooltipEnterLeave}
            onMouseLeave={onTooltipEnterLeave}
          >
            {description}
            {cta && (
              <a
                href="#"
                onClick={this.handleTooltipCTAClick}
                data-hook={
                  AUTOMATION_IDS.SEARCH_RESULT_ITEM.CONDITIONAL_TOOLTIP_CTA
                }
                className={cx(styles.tooltipCta, {
                  [styles.active]: isTooltipForceShown,
                })}
              >
                {cta}
              </a>
            )}
          </div>
        </RichText>
      ),
      ...(!isSelected && {
        isOpen: false,
      }),
      ...(isTooltipForceShown && {
        isOpen: isTooltipForceShown,
      }),
    };
  };

  getCTA() {
    const { tooltipData, cta, onClick, onConditionalCTAHover } = this.props;

    if (tooltipData) {
      return (
        <div
          onMouseEnter={onConditionalCTAHover}
          data-hook={AUTOMATION_IDS.SEARCH_RESULT_ITEM.CTA}
        >
          <UIIcon size={18} layout={IconLayouts.Circle} bordered={false}>
            <symbols.symbol name="infoIcon" className={styles.tooltipIcon} />
          </UIIcon>
        </div>
      );
    }
    if (cta) {
      return (
        <Button
          onClick={onClick}
          dataHook={AUTOMATION_IDS.SEARCH_RESULT_ITEM.CTA}
        >
          {cta}
        </Button>
      );
    }

    return null;
  }

  getTitle() {
    const { isSuccessIconVisible, title } = this.props;

    if (!isSuccessIconVisible) {
      return title;
    }

    return (
      <Title
        suffix={
          <Tooltip
            content={'Editor_Search_Results_AppMarket_App_Installed_Tooltip'}
            alignment="TOP"
            marginTop={4}
          >
            <UIIcon
              size={15}
              layout={IconLayouts.Circle}
              colors={IconColors.Success}
              bordered={false}
            >
              <symbols.symbol
                name="check"
                className={styles.installedTooltipIcon}
              />
            </UIIcon>
          </Tooltip>
        }
      >
        {title}
      </Title>
    );
  }

  render() {
    const {
      onClick,
      onHover,
      description,
      isSelected,
      shouldScrollIntoView,
      tooltipData,
      onRef,
    } = this.props;

    const props = {
      onHover,
      title: this.getTitle(),
      description,
      onRef,
      selected: isSelected,
      shouldScrollIntoView,
      cta: this.getCTA(),
      icon: (
        <UIIcon size={36} layout={IconLayouts.Circle}>
          {this.getIcon()}
        </UIIcon>
      ),
    };

    if (tooltipData) {
      return (
        <TooltipCard
          {...props}
          tooltipProps={this.getItemConditionalTooltipProps()}
        />
      );
    }

    return <Card {...props} onClick={onClick} />;
  }
}

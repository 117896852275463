import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { MediaApiKey } from './leftPublicApiKeys';
import { createMediaApi } from './mediaWrapper';

export const MediaEntrypoint: EntryPoint = {
  name: 'MediaApi',
  declareAPIs: () => [MediaApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(MediaApiKey, () => createMediaApi(shell));
  },
};

import React, { type CSSProperties, type FC } from 'react';
import styles from './StageSideAreaBorder.scss';
import { cx } from '#packages/util';
import { AUTOMATION_IDS } from '../StageSideArea/constants';

interface StageSideAreaBorderProps {
  position: 'left' | 'right';
  style?: CSSProperties;
  isMobileEditor?: boolean;
  isZoomOut?: boolean;
}

const lineXMap: Record<
  string,
  Record<string, Record<string, { x1: number | string; x2: number | string }>>
> = {
  desktop: {
    zoomOut: {
      left: { x1: 0, x2: '100%' },
      right: { x1: 0, x2: '100%' },
    },
    zoomIn: {
      left: { x1: 0, x2: 0 }, // doesn't exist in desktop zoom in
      right: { x1: 0, x2: '100%' },
    },
  },
  mobile: {
    zoomOut: {
      left: { x1: 0, x2: '100%' },
      right: { x1: 0, x2: '100%' },
    },
    zoomIn: {
      left: { x1: 0, x2: '100%' },
      right: { x1: 0, x2: '100%' },
    },
  },
};

export const StageSideAreaBorder: FC<StageSideAreaBorderProps> = ({
  style,
  position,
  isMobileEditor,
  isZoomOut,
}) => {
  const editorTypeKey = isMobileEditor ? 'mobile' : 'desktop';
  const zoomKey = isZoomOut ? 'zoomOut' : 'zoomIn';

  const lineProps = {
    y1: 0,
    y2: 0,
    ...lineXMap[editorTypeKey][zoomKey][position],
  };

  return (
    <svg
      data-hook={AUTOMATION_IDS.STAGE_SIDE_AREA_BORDER}
      className={cx(styles.border, styles[position], {
        [styles.mobileEditor]: isMobileEditor,
      })}
      width="100%"
      style={style}
    >
      <line {...lineProps} />
    </svg>
  );
};

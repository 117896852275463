import React from 'react';
import _ from 'lodash';
import {
  Composites,
  ToggleSwitch,
  DropDown,
  DropDownOption,
  TextLabel,
} from '@wix/wix-base-ui';

import * as baseUI from '#packages/baseUI';
import { validate } from '#packages/util';

export class LoginToWixLink extends React.Component<any, any> {
  constructor(props: AnyFixMe) {
    super(props);
    this.state = {
      link: props.link || {
        type: 'LoginToWixLink',
      },
      isValid: true,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(this.state.isValid);
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
    }
    if (this.state.isValid !== prevState.isValid) {
      this.props.onValidationUpdate(this.state.isValid);
    }
  }

  private linkLoginToWixLinkSendEmailProperty = () => {
    const currentLink = this.state.link;

    return {
      value: currentLink ? currentLink.sendMail : '',

      requestChange: (newVal: AnyFixMe) => {
        currentLink.sendMail = newVal;
        currentLink.metaData = {
          schemaVersion: '2.0',
          isPreset: true,
          isHidden: false,
        };
        this.setState({ link: currentLink });
      },
    };
  };

  private changeLinkValue(prop: AnyFixMe, val: AnyFixMe) {
    const currentLink = { ...this.state.link };
    currentLink[prop] = val;
    this.setState({ link: currentLink });
  }

  private linkTo(prop: AnyFixMe) {
    const currentLink = this.state.link;

    return {
      value: currentLink ? currentLink[prop] : '',

      requestChange: (val: AnyFixMe) => this.changeLinkValue(prop, val),
    };
  }

  private linkToAndTrim(prop: AnyFixMe) {
    const currentLink = this.state.link;

    return {
      value: currentLink ? currentLink[prop] : '',
      requestChange: _.flow(_.trim, (val) => this.changeLinkValue(prop, val)),
    };
  }

  private isEmptyStringOrFullUrl(val: string): boolean {
    return val === '' || validate.fullUrl(val);
  }

  render() {
    return (
      <>
        <baseUI.textInput
          ref="postLogin"
          key="postLogin"
          label="LINK_PANEL_POST_LOGIN_INPUT"
          value={this.linkToAndTrim('postLoginUrl').value}
          onChange={this.linkToAndTrim('postLoginUrl').requestChange}
          validator={this.isEmptyStringOrFullUrl}
          invalidMessage="Validation_V2_URL"
          onChangeInValidationStatus={(isValid) => {
            this.setState({ isValid });
          }}
        />
        <baseUI.textInput
          ref="postSignup"
          key="postSignup"
          label="LINK_PANEL_POST_SIGNUP_INPUT"
          value={this.linkToAndTrim('postSignupUrl').value}
          onChange={this.linkToAndTrim('postSignupUrl').requestChange}
          validator={this.isEmptyStringOrFullUrl}
          invalidMessage="Validation_V2_URL"
          onChangeInValidationStatus={(isValid) => {
            this.setState({ isValid });
          }}
        />
        <Composites.DropDownLabeled>
          <TextLabel value="LINK_PANEL_VIEW_DROPDOWN" />
          <DropDown
            value={this.linkTo('dialog').value}
            onChange={this.linkTo('dialog').requestChange}
          >
            {[
              {
                label: 'LINK_PANEL_VIEW_SHOW_LOGIN',
                value: 'showLogin',
              },
              { label: 'LINK_PANEL_VIEW_LOGIN', value: 'login' },
              { label: 'LINK_PANEL_VIEW_SIGNUP', value: 'createUser' },
            ].map((option) => (
              <DropDownOption
                value={option.value}
                label={option.label}
                key={option.value}
              />
            ))}
          </DropDown>
        </Composites.DropDownLabeled>

        <ToggleSwitch
          // @ts-expect-error
          ref="sendMail"
          key="sendMail"
          value={this.linkLoginToWixLinkSendEmailProperty().value}
          label="LINK_PANEL_SEND_MAIL_TOGGLE"
          onChange={this.linkLoginToWixLinkSendEmailProperty().requestChange}
        />

        <baseUI.textInput
          ref="welcomeEmail"
          key="welcomeEmail"
          label="LINK_PANEL_WELCOME_EMAIL_INPUT"
          value={this.linkTo('mailTemplate').value}
          onChange={this.linkTo('mailTemplate').requestChange}
        />
        <baseUI.textInput
          ref="color"
          key="color"
          label="LINK_PANEL_USER_COLOR_INPUT"
          value={this.linkTo('userColor').value}
          onChange={this.linkTo('userColor').requestChange}
        />
        <baseUI.textInput
          ref="name"
          key="name"
          label="LINK_PANEL_BUTTON_NAME"
          value={this.linkTo('loginCompName').value}
          onChange={this.linkTo('loginCompName').requestChange}
        />
      </>
    );
  }
}

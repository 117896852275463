import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import {
  getTransformation,
  scale,
  rotate,
  skew,
  resetEffects,
  customTransform,
  type IOrigin,
  type ISkew,
  type ITransformationData,
} from '../../interactionsTransformFacade';
import {
  getSelectedEffectFromTransformation,
  EFFECT_LIMITS,
  CUSTOMIZE_ALL_DEFAULTS,
} from './effects';
import { getDefaultOrigin } from './customize/customizeUtils';
import * as util from '#packages/util';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  EffectsPanelOwnProps,
  EffectsPanelDispatchProps,
  EffectsPanelStateProps,
} from './effectsPanel';

const { getVariantId } = stateManagement.interactions.selectors;

const getEditorAPI: ThunkAction = (dispatch, getState, { editorAPI }) => ({
  editorAPI,
  state: getState(),
});

export const mapStateToProps: MapStateToProps<
  EffectsPanelStateProps,
  EffectsPanelOwnProps
> = ({ editorAPI }) => {
  const state = editorAPI.store.getState();
  const targetCompRefWithVariant =
    stateManagement.interactions.selectors.getCompVariantPointer(state);

  const transformation = getTransformation(editorAPI, targetCompRefWithVariant);

  return {
    // we assume that scale.x === scale.y
    scaleValue: transformation?.scale?.x, //eslint-disable-line lodash/prefer-get
    skewValue: transformation?.skew,
    rotateValue: transformation?.rotate,
    origin: transformation?.origin,
    transformation,
    variantId: getVariantId(state),
    selectedEffect: getSelectedEffectFromTransformation(transformation),
  };
};

const DEFAULT_ORIGIN = getDefaultOrigin();

export const mapDispatchToProps: MapDispatchToProps<
  EffectsPanelDispatchProps,
  EffectsPanelOwnProps
> = (dispatch) => {
  const { state, editorAPI } = dispatch(getEditorAPI);
  const targetCompRefWithVariant =
    stateManagement.interactions.selectors.getCompVariantPointer(state);

  return {
    grow(
      size: number = EFFECT_LIMITS.GROW.DEFAULT,
      origin: IOrigin = DEFAULT_ORIGIN,
    ) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.EDIT_EFFECT,
      );
      scale(editorAPI, targetCompRefWithVariant, { x: size, y: size }, origin);
    },
    shrink(
      size: number = EFFECT_LIMITS.SHRINK.DEFAULT,
      origin: IOrigin = DEFAULT_ORIGIN,
    ) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.EDIT_EFFECT,
      );
      scale(editorAPI, targetCompRefWithVariant, { x: size, y: size }, origin);
    },
    skew(
      direction: ISkew = {
        x: EFFECT_LIMITS.SKEW.DEFAULT,
        y: 0,
      },
      origin: IOrigin = DEFAULT_ORIGIN,
    ) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.EDIT_EFFECT,
      );
      skew(editorAPI, targetCompRefWithVariant, direction, origin);
    },
    rotate(
      degrees = EFFECT_LIMITS.ROTATE.DEFAULT,
      origin: IOrigin = DEFAULT_ORIGIN,
    ) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.EDIT_EFFECT,
      );
      rotate(editorAPI, targetCompRefWithVariant, degrees, origin);
    },
    customTransform(transformationData: ITransformationData) {
      // TODO: review _.defaults when transformationData will respect null
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.EDIT_EFFECT,
      );
      return new Promise((res) => {
        transformationData = _.defaults(
          {},
          transformationData,
          CUSTOMIZE_ALL_DEFAULTS,
        );
        customTransform(
          editorAPI,
          targetCompRefWithVariant,
          {
            ...CUSTOMIZE_ALL_DEFAULTS,
            ...transformationData,
          },
          res,
        );
      });
    },
    reset() {
      resetEffects(editorAPI, targetCompRefWithVariant);
    },

    sendBI(event, parameters) {
      const defaultBIParams = {
        component_id: targetCompRefWithVariant.id,
        component_type: editorAPI.components.getType(targetCompRefWithVariant),
      };

      dispatch(
        stateManagement.bi.actions.event(event, {
          ...defaultBIParams,
          ...parameters,
        }),
      );
    },
  };
};

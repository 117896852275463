// @ts-nocheck
import _ from 'lodash';
import { jwtUtils, url } from '#packages/util';
import constants from '../constants/constants';
import superAppsConstants from '../superApps/superAppsConstants';
import * as bi from '../bi/bi';
import type { EditorAPI } from '#packages/editorAPI';
import * as coreBi from '#packages/coreBi';

import type { CompRef } from 'types/documentServices';

const VALID_APP_INTENT_EVENTS = [bi.events.APP_INTENT];

function getCurrentDomain() {
  const domainMatch = window.location.hostname.match(/editor\.(.*)/);

  // If could not extract domain, return default domain
  return domainMatch ? `www.${domainMatch[1]}` : 'www.wix.com';
}

function isAppProvisionedAndSiteSaved(editorAPI, appDefId) {
  const tpaAppData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefId) || {};
  const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
  return (
    !(tpaAppData.notProvisioned || tpaAppData.demoMode) &&
    tpaAppData.instanceId &&
    tpaAppData?.permissions?.revoked !== true &&
    !isFirstSave
  );
}

function isInDevMode() {
  return !_.isEmpty(url.getParameterByName('appDefinitionId'));
}

function getAppInMyAccountUrl(metaId, appDefinitionId) {
  const domain = getCurrentDomain();
  return [
    '//',
    domain,
    '/my-account/sites/',
    metaId,
    '/app/',
    appDefinitionId,
    '?referralInfo=editor',
  ].join('');
}

function getOpenedPanel(panelManager, panelName) {
  const openPanels = panelManager.getOpenPanels();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(openPanels, { name: panelName });
}

function isMultiSectionInstanceEnabled(appData, widgetId) {
  if (!widgetId) {
    return false;
  }
  const widgetData = appData.widgets?.[widgetId];

  return widgetData?.appPage?.multiInstanceEnabled;
}

function isBlog(appDefinitionId) {
  return appDefinitionId === superAppsConstants.BLOG.APP_DEF_ID;
}

function sdkVersionIsAtLeast(currentVersion, requiredVersion) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(currentVersion) || _.isUndefined(requiredVersion)) {
    return false;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const currentSplited = _.map(currentVersion.split('.'), function (digit) {
    return parseInt(digit, 10);
  });

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const requiredSplited = _.map(requiredVersion.split('.'), function (digit) {
    return parseInt(digit, 10);
  });

  while (currentSplited.length && requiredSplited.length) {
    const currentVersionPart = currentSplited.shift();
    const requiredVersionPart = requiredSplited.shift();
    if (currentVersionPart > requiredVersionPart) {
      return true;
    } else if (currentVersionPart < requiredVersionPart) {
      return false;
    }
  }

  return currentSplited.length >= requiredSplited.length;
}

function getWindow() {
  return window;
}

function selectComponentAndNavigateIfNeeded(editorAPI, appData, callback) {
  const selectedCompData = editorAPI.selection.getSelectedComponentData();
  const selectedCompAppId = selectedCompData?.applicationId;
  if (
    !selectedCompAppId ||
    selectedCompAppId.toString() !== appData.applicationId.toString()
  ) {
    const data = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(
      appData.appDefinitionId,
    );
    const curPageId = editorAPI.dsRead.pages.getFocusedPageId();
    const compRef = editorAPI.components.get.byId(data.compId, data.pageId);
    if (data.pageId !== curPageId && data.pageId !== 'masterPage') {
      editorAPI.pages.navigateTo(data.pageId, function () {
        editorAPI.selection.selectComponentByCompRef(compRef);
        if (callback) {
          callback();
        }
      });
    } else {
      editorAPI.selection.selectComponentByCompRef(compRef);
      if (callback) {
        callback();
      }
    }
  } else if (callback) {
    callback();
  }
}

interface AppMarketJWTData {
  appDefId: string;
  version: string;
}

const parseAppMarketQueryParam = (jwt: string): AppMarketJWTData | null => {
  try {
    const jwtPayload = jwtUtils.parseJwt<{ data?: string }>(jwt);
    const parsedData = JSON.parse(jwtPayload?.data);

    return {
      appDefId: parsedData.appDefId || parsedData.appId,
      version: parsedData.version,
    };
  } catch (e) {
    return null;
  }
};

function reportBIOnAppIntent(
  editorAPI,
  event,
  appDefinitionId,
  initiator,
  originType,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (!_.includes(VALID_APP_INTENT_EVENTS, event)) {
    return;
  }

  const biParams = getBIAppParams.bind(
    null,
    editorAPI,
    appDefinitionId,
    initiator,
    originType,
  );
  const condition = isAppProvisionedAndSiteSaved.bind(
    null,
    editorAPI,
    appDefinitionId,
  );
  editorAPI.bi.reportBI(event, [biParams], condition);
}

function getAppIntentBiParamsToAddToUrl(editorAPI, appDefinitionId, type) {
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  if (appData?.isWixTPA) {
    const generalParams = {
      msid: editorAPI.dsRead.generalInfo.getMetaSiteId(),
      src: bi.events.APP_INTENT.src,
      evid: bi.events.APP_INTENT.evid,
    };
    const appParams = getBIAppParams(
      editorAPI,
      appDefinitionId,
      constants.BI.initiator.EDITOR,
      type,
    );

    return _.merge(generalParams, appParams);
  }
  return null;
}

function getBIAppParams(editorAPI, appDefId, initiator, type) {
  const tpaAppData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefId) || {};
  return {
    app_id: appDefId,
    instance_id: tpaAppData.instanceId,
    initiator,
    type,
  };
}

const reportAddComponentBIEvent = function (editorAPI, compId, type, biData) {
  biData = biData || {};
  editorAPI.bi.event(bi.events.COMPONENT_ADDED_FROM_MARKET, {
    app_id: biData.app_id,
    instance_id: compId,
    component_type: type || 'wix_component',
    origin: 'ed_market',
    category: biData.category,
    search_term: biData.search_term,
    item_position: biData.item_position,
    referral_info_category: biData.referral_info_category,
    referral_info: biData.referral_info,
    adding_method: biData.adding_method,
    modal_tab_name: biData.modal_tab_name,
  });
};

const reportTpaAddedBiEvent = function (
  editorAPI,
  appDefinitionId,
  widgetId,
  pageId,
  compId,
  isFirstAdd,
  isPermissionsApp,
  biData,
) {
  const isFirstSaved = editorAPI.dsRead.generalInfo.isFirstSave();
  const templateMetaSiteId = isFirstSaved
    ? editorAPI.dsRead.generalInfo.getMetaSiteId()
    : '';
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  biData = biData || {};
  const params = {
    is_permissions_app: isPermissionsApp ? 1 : 0,
    is_first: isFirstAdd ? 1 : 0,
    app_id: appDefinitionId,
    widget_id: widgetId,
    app_site_id: appData?.instanceId,
    instance_id: compId,
    origin: biData.origin || 'ed_market',
    adding_method: biData.adding_method,
    category: biData.category,
    section: biData.section,
    search_term: biData.search_term,
    item_position: biData.item_position,

    referral_info_category: biData.referral_info_category,
    referral_info: biData.referral_info,

    template_guid: templateMetaSiteId,
    modal_tab_name: biData.modal_tab_name,
    page_id: pageId,
  };

  if (biData.market_version) {
    params.market_version = biData.market_version;
  }
  editorAPI.bi.event(bi.events.TPA_ADDED, params);
};

export interface ReportBiForAddedComponentToStageOptions {
  appDefId: string;
  addingMethod: 'click' | 'drag';
  presetId: string;
  section: string;
  category: string;
  origin: string;
  componentName?: string; // Used to distinguish tpaWidgets
}

export const reportBiForAddedComponentToStage = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  options: ReportBiForAddedComponentToStageOptions = {},
) => {
  const componentType = editorAPI.components.getType(compRef);
  const widgetSkin = editorAPI.components.skin.get(compRef);
  const parent = editorAPI.components.getContainer(compRef);
  const parentType = editorAPI.components.getType(parent);

  editorAPI.bi.event(coreBi.events.addPanel.COMPONENT_ADDED_TO_STAGE, {
    ...editorAPI.bi.getComponentsBIParams([compRef])[0],
    component_type: componentType,
    component_id: compRef.id,
    preset_id: options.presetId || '',
    preset_data_skin: widgetSkin,
    page_id: editorAPI.pages.getFocusedPageId(),
    appDefinitionId: options.appDefId,
    target_component: parentType,
    target_component_id: parent?.id,
    category: options.category,
    section: options.section,
    adding_method: options.addingMethod,
    origin: options.origin,
    component_name: options.componentName,
  });
};

const biExport = {
  reportBIOnAppIntent,
  getAppIntentBiParamsToAddToUrl,
  reportAddComponentBIEvent,
  reportTpaAddedBiEvent,
};

export {
  isInDevMode,
  getAppInMyAccountUrl,
  getOpenedPanel,
  isMultiSectionInstanceEnabled,
  isBlog,
  sdkVersionIsAtLeast,
  getWindow,
  selectComponentAndNavigateIfNeeded,
  parseAppMarketQueryParam,
  biExport as bi,
};

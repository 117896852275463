import React from 'react';
import experiment from 'experiment';
import { MessagePanelFrame, OldMessagePanelFrame } from '../frames';

export default class extends React.Component<{ panelName: string }> {
  static displayName = 'exampleMessage';

  onConfirm = () => {
    console.info('onConfirm called');
  };

  render() {
    const modalBody = <div>content here</div>;

    return experiment.isOpen('se_removeOldMessagePanelFrame') ? (
      <MessagePanelFrame
        panelName={this.props.panelName}
        onConfirm={this.onConfirm}
        confirmLabel="OK"
        title="Hello"
      >
        {modalBody}
      </MessagePanelFrame>
    ) : (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        width="500"
        height="500"
        onConfirm={this.onConfirm}
        confirmLabel="OK"
        title="Hello"
      >
        {modalBody}
      </OldMessagePanelFrame>
    );
  }
}

import type React from 'react';
import * as stateManagement from '#packages/stateManagement';
import { gfppModel } from '#packages/gfppData';
import type {
  GFPPAction,
  GFPPActionType,
  GFPPActionsDictionary,
  GFPPData,
} from '@wix/editor-component-types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { MapStateToProps, ThunkAction } from 'types/redux';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import * as platform from '#packages/platform';
import { multilingual, controlsUtils, arrayUtils } from '#packages/util';

const GFPP_ACTIONS = constants.ROOT_COMPS.GFPP.ACTIONS;

export const getDefaultActionsOrder = (): GFPPActionType[] => {
  const DEFAULT_ACTIONS: string[] = [
    GFPP_ACTIONS.AI_TOOLS,
    GFPP_ACTIONS.ADD,
    GFPP_ACTIONS.BACKGROUND,
    GFPP_ACTIONS.MOBILE_BACKGROUND_SETTINGS,
    GFPP_ACTIONS.SCALE_UP,
    GFPP_ACTIONS.SCALE_DOWN,
    GFPP_ACTIONS.SETTINGS,
    GFPP_ACTIONS.SWITCH_LAYOUT,
    GFPP_ACTIONS.SWITCH_LAYOUT_COMPARISON,
    GFPP_ACTIONS.EFFECTS,
    GFPP_ACTIONS.BEHAVIORS,
    GFPP_ACTIONS.PRESETS,
    GFPP_ACTIONS.LAYOUT,
    GFPP_ACTIONS.STYLABLE_LAYOUT,
    GFPP_ACTIONS.DESIGN,
    GFPP_ACTIONS.DIVIDERS,
    GFPP_ACTIONS.CUSTOMIZE_DESIGN,
    GFPP_ACTIONS.MASK,
    GFPP_ACTIONS.FOCAL_POINT,
    GFPP_ACTIONS.CROP,
    GFPP_ACTIONS.FILTERS,
    GFPP_ACTIONS.MOBILE_TEXT_REPLACE,
    GFPP_ACTIONS.ANIMATION,
    GFPP_ACTIONS.MOTION,
    GFPP_ACTIONS.TRANSITION,
    GFPP_ACTIONS.INTERACTION_HIDE,
    GFPP_ACTIONS.LINK,
    GFPP_ACTIONS.TEXT_LINK,
    GFPP_ACTIONS.HIDE,
    GFPP_ACTIONS.STRETCH,
    GFPP_ACTIONS.STRETCH_FOR_COLUMNS,
    GFPP_ACTIONS.APPLY_TO_OTHER_VIEW,
    GFPP_ACTIONS.UPGRADE,
    GFPP_ACTIONS.INTERACTIONS,
    GFPP_ACTIONS.WIDGET_PLUGINS,
    GFPP_ACTIONS.HELP,
    GFPP_ACTIONS.CONNECT,
    GFPP_ACTIONS.ACCESSIBILITY,
    GFPP_ACTIONS.PIN_MODE,
    GFPP_ACTIONS.CUSTOM_CONNECT,
  ];

  return DEFAULT_ACTIONS.filter(Boolean) as GFPPActionType[];
};

const { GFPP_BUTTON_CLICK } = constants.COMP_META_DATA.CONTROLS;

function getActions(
  actions: GFPPActionsDictionary,
  actionOrder: GFPPActionType[],
): GFPPAction[] {
  return actionOrder.map((actionName) => actions[actionName]).filter(Boolean);
}

const setComponentPreviewState =
  (compId: string, previewState?: string): ThunkAction =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.documentMode.setComponentPreviewState(compId, previewState);

const openHelpCenter =
  (helpId: string): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const compRef = editorAPI.selection.getSelectedComponents()[0];
    const selectedCompAppPart =
      editorAPI.selection.getSelectedComponentData()?.appPartName;
    const selectedCompType = editorAPI.selection.getSelectedComponentType();

    const biParams = {
      component_id: compRef.id,
      component: selectedCompAppPart || selectedCompType,
      origin: constants.BI.HELP.ORIGIN.GFPP,
      learn_more: false,
    };

    editorAPI.store.dispatch(
      stateManagement.panels.actions.openHelpCenter(helpId, null, biParams),
    );
  };

const createButtons = (
  selectedComponents: CompRef[],
  toggleGfpp: () => void,
  gfppIsCollapsed: boolean,
  isGfppCollapseEnabled: boolean,
  gfppData: GFPPData,
  editorAPI: EditorAPI,
): GFPPAction[] => {
  const mainActions = gfppData.mainActions || [];
  const extraActions = gfppData.extraActions || [];
  const defaultActions = getActions(
    gfppData.presetActions,
    getDefaultActionsOrder(),
  );

  let buttons = mainActions.concat(defaultActions).concat(extraActions);

  const app_Id = constants.MULTILINGUAL.linguistDefId;
  const component_type = selectedComponents[0]?.type;
  const component_id = selectedComponents[0]?.id;
  const selectedComponentIsGroup =
    editorAPI.components.is.group(selectedComponents);

  const translatable =
    !gfppData.untranslatable &&
    selectedComponents.length === 1 &&
    !selectedComponentIsGroup &&
    (!editorAPI.isMobileEditor() ||
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        selectedComponents[0],
      ));
  const { translateAction } = gfppData;
  const currentLanguageCode = editorAPI.language.current.get();
  const originalLanguage = editorAPI.language.original.get();
  const originalLanguageCode = originalLanguage?.languageCode;

  if (
    originalLanguageCode &&
    currentLanguageCode &&
    originalLanguageCode !== currentLanguageCode &&
    !editorAPI.components.is.customizeTranslate(selectedComponents) &&
    buttons.length
  ) {
    const collapseButton: GFPPAction = {
      icon: 'moreRegular',
      tooltip: 'gfpp_tooltip_moreactions',
      customBI: {
        action_name: 'gfpp_multilingual_expand',
        icon: 'expand',
        component_id,
        component_type,
        app_Id,
      },
      onClick: toggleGfpp,
      automationId: 'gfpp-button-collapse',
    };
    if (translatable) {
      let translateFn;

      if (translateAction) {
        translateFn = gfppData.presetActions[translateAction].onClick;
      } else {
        translateFn = buttons[0].onClick;
      }

      const firstButton: GFPPAction = {
        onClick: translateFn,
        customBI: {
          action_name: 'gfpp_multilingual_translate',
          icon: 'translate',
          component_id,
          component_type,
          app_Id,
        },
        label: 'gfpp_mainaction_edit_translation',
        automationId: 'gfpp-button-translate',
      };

      if (isGfppCollapseEnabled) {
        if (gfppIsCollapsed) {
          buttons = [firstButton, collapseButton];
        } else {
          const slice = buttons.slice(1);
          buttons = [firstButton, collapseButton, ...slice];
        }
      } else {
        buttons = [firstButton, ...buttons.slice(1)];
      }

      const googleTranslateFn =
        multilingual.googleTranslateFactory.getTranslateMethodForComponent(
          editorAPI,
          stateManagement,
          selectedComponents[0],
        );

      if (googleTranslateFn) {
        const googleTranslateButton = {
          onClick: googleTranslateFn,
          customBI: {
            action_name: 'gfpp_multilingual_google_translate',
            icon: 'google translate',
            component_id,
            component_type,
            app_Id,
          },
          label: 'gfpp_mainaction_google_translate',
        };

        buttons = setMultilingualGfppHelp([...buttons], editorAPI);
        buttons = [buttons[0], googleTranslateButton, ...buttons.slice(1)];
      }

      const notSupportedGFPPs: GFPPActionType[] = [
        GFPP_ACTIONS.ANIMATION,
        GFPP_ACTIONS.DESIGN,
        GFPP_ACTIONS.LAYOUT,
        GFPP_ACTIONS.FILTERS,
      ];
      buttons
        .filter((button) =>
          notSupportedGFPPs.includes(button.icon as GFPPActionType),
        )
        .forEach((button) => {
          button.isDisabled = true;
          button.tooltip = 'gfpp_tooltip_animate_disabled_multilingual';
        });
    } else if (isGfppCollapseEnabled) {
      if (gfppIsCollapsed) {
        buttons = [collapseButton];
      } else {
        buttons = [collapseButton, ...buttons];
      }
    }
  }

  return buttons;
};

const setMultilingualGfppHelp = (
  buttons: GFPPAction[],
  editorAPI: EditorAPI,
): GFPPAction[] => {
  if (
    !stateManagement.multilingual.services.utils.currentIsOriginal(editorAPI)
  ) {
    const helpItem = buttons.find((btn) => btn.helpId);
    if (helpItem) {
      helpItem.helpId = helpIds.MULTILINGUAL.GFPP;
    }
  }
  return buttons;
};

export interface GFPPOwnProps {
  selectedComponents: CompRef[];
  previewState: string | null;
  style?: React.CSSProperties;
  setGFPPMeasureFunc: (func: (() => void) | null) => void;
  toggleGfpp: () => void;
  setGfppIsCollapsed: (isCollapsed: boolean) => void;
  gfppIsCollapsed: boolean;
  isGfppCollapseEnabled: boolean;
}

export interface GFPPStateProps {
  gfppData: GFPPData;
  buttons: GFPPAction[];
  selectedComponents: CompRef[];
  previewState: string;
}

export const mapStateToProps: MapStateToProps<GFPPStateProps, GFPPOwnProps> = (
  { editorAPI },
  {
    selectedComponents,
    toggleGfpp,
    gfppIsCollapsed,
    isGfppCollapseEnabled = true,
  },
) => {
  const selectedCompRefs = platform.gfppTargetUtils.getSelectedComponents(
    selectedComponents,
    editorAPI,
  );
  const gfppData = gfppModel.getFullComponentGfppData(
    editorAPI,
    selectedCompRefs,
  );
  const buttons = createButtons(
    selectedCompRefs,
    toggleGfpp,
    gfppIsCollapsed,
    isGfppCollapseEnabled,
    gfppData,
    editorAPI,
  );
  const mainSelectedComponents: CompRef[] = arrayUtils
    .asArray(selectedCompRefs)
    .map((selectedComponent) =>
      controlsUtils.getFirstControllableComponent(
        editorAPI,
        selectedComponent,
        GFPP_BUTTON_CLICK,
      ),
    );
  return {
    gfppData,
    buttons,
    selectedComponents: mainSelectedComponents,
    previewState: gfppData.previewState,
  };
};

export const mapDispatchToProps = (dispatch: AnyFixMe) => ({
  setComponentPreviewState: (compId: string, previewState?: string): void =>
    dispatch(setComponentPreviewState(compId, previewState)),

  openHelpCenter: (helpId: string): void => dispatch(openHelpCenter(helpId)),
});

export type GFPPDispatchProps = ReturnType<typeof mapDispatchToProps>;

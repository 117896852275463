// @ts-nocheck
import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import * as stateManagement from '#packages/stateManagement';
import coreUtilsLib from '@wix/santa-core-utils';
import { Composites, Preloader, TextLabel } from '@wix/wix-base-ui';
import * as utils from '@wix/santa-editor-utils';
import model from './tempModel.json';

const { withModules, connect, STORES } = utils.hoc;
const { getCompClass } = stateManagement.componentsLoadService.actions;
const { getWorkerMessagesAPI } =
  stateManagement.rendererPanelsWorkerService.actions;

const Spinner = () => {
  const preLoader = React.createElement(Preloader, {
    key: 'preLoader',
    className: 'medium',
  });
  const label = React.createElement(TextLabel, {
    key: 'label',
    value: 'Loading...',
    shouldTranslate: false,
  });

  return React.createElement(Composites.Preloader, {}, [preLoader, label]);
};

class PlatformPanelApplicationNative extends React.Component {
  constructor(props) {
    super(props);

    this.renderSantaRenderer = (rendererContainerRef) => {
      const eventsManager = coreUtilsLib.eventsManager.create();

      // TODO think of a better way to make sure components are rendered and created with same React/ReactDOM
      const previewFrame = window.frames[1];
      const santaReact = previewFrame.require('react');
      const santaReactDOM = previewFrame.require('reactDOM');

      if (rendererContainerRef) {
        this.rendererContainerRef = rendererContainerRef;
        const { IsolatedRenderer } = this.props.santaRenderer;
        const { withWixCode } = this.props.wixCodeExtension;

        const rendererProps = {
          'data-aid': 'renderer-component',
          id: _.uniqueId('renderer'),
          className: 'renderer-component',
          workerMessagesAPI: this.props.workerMessagesAPI,
          wixCodeScriptUrl: this.getWixCodeScriptUrl(),
          componentsModel: this.getComponentsModel(),
          getCompClass: this.props.getCompClass,
          rootCompsIds: this.getRootCompsIds(),
          eventsManager,
        };
        const RendererWixWixCode = santaReact.createElement(
          withWixCode(IsolatedRenderer),
          rendererProps,
        );
        santaReactDOM.render(RendererWixWixCode, rendererContainerRef);
      } else if (this.rendererContainerRef) {
        santaReactDOM.unmountComponentAtNode(this.rendererContainerRef);
      }
    };
  }

  getWixCodeScriptUrl() {
    // TODO should use metaSiteId and pageId from props to build the url
    return 'https://a370532d-29bd-4ef1-8840-ff0f32e7b8d8.static.pub.wix-code.com/static/v2/bc59538a-5757-45ea-b385-92796efa4578/a370532d-29bd-4ef1-8840-ff0f32e7b8d8/pages/c1dmp.js?empty-if-missing=true&exclude=wix-&module-name=c1dmp';
  }

  // TODO WEED-15441 make this function async and wait for its result
  getComponentsModel() {
    // TODO should use metaSiteId and pageId from props to build the url and fetch the model
    return model;
  }

  getRootCompsIds() {
    // TODO need to understand logic for determining the root ids
    return [_.findKey(model.structure, (comp) => !comp.parent)];
  }

  render() {
    return (
      <div
        ref={this.renderSantaRenderer}
        className="platform-panel-application-native"
      />
    );
  }
}

PlatformPanelApplicationNative.displayName = 'PlatformPanelApplicationNative';

PlatformPanelApplicationNative.propTypes = {
  metaSiteId: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  initialData: PropTypes.any,
  getCompClass: PropTypes.func.isRequired,
  workerMessagesAPI: PropTypes.object.isRequired,
  santaRenderer: PropTypes.shape({
    IsolatedRenderer: PropTypes.func.isRequired,
  }).isRequired,
  wixCodeExtension: PropTypes.shape({
    withWixCode: PropTypes.func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  getCompClass: (compType) => dispatch(getCompClass(compType)),
  workerMessagesAPI: dispatch(getWorkerMessagesAPI()),
});

const panelModules = {};

export default _.flow(
  withModules(panelModules, Spinner),
  connect(STORES.STATE_ONLY, _.noop, mapDispatchToProps),
)(PlatformPanelApplicationNative);

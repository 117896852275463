import { SectionsApiKey } from '#packages/apis';

import constants from '#packages/constants';
import { controlsUtils, sections } from '#packages/util';
import { platform, components, selection } from '#packages/stateManagement';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { MapStateToProps } from 'types/redux';

const OFFSET_FROM_GRID_LINES = 2;

function getComponentUIColor(editorAPI: EditorAPI, comps: CompRef) {
  if (
    editorAPI.components.isShowOnAllPages(comps) ||
    editorAPI.components.isShowOnSomePages(comps)
  ) {
    return constants.COMPONENT_UI_COLORS.ORANGE;
  }

  return constants.COMPONENT_UI_COLORS.BLUE;
}

function getHoverBoxLabelStyles(editorAPI: EditorAPI, compPointer: CompRef) {
  if (editorAPI.components.is.fullWidth(compPointer)) {
    const offsetLeft = editorAPI.isMobileEditor()
      ? 0
      : editorAPI.siteSegments.getPagesContainerAbsLayout().x +
        OFFSET_FROM_GRID_LINES;

    return {
      left: offsetLeft,
    };
  }
}

export interface HoveredComponentLabelsStateProps {
  isHoveredSelectedComp: boolean;
  isMobileOnlyNonNativeComponent: boolean;
  isSection: boolean;
  selectedComponent: CompRef[];
  showConnectIndication: boolean;
  componentUIColor: ValueOf<typeof constants.COMPONENT_UI_COLORS>;
  hoverBoxLabelStyles?: { left: number };
  focusedPageId: string;
  displayName: string;
  enableDevMode: boolean;
  isPinned: boolean;
  compNickname: string;
  compSecondaryNickname: string | null;
  mainCompRef: CompRef;
}

export interface HoveredComponentLabelsOwnProps {
  compPointer: CompRef;
  displayNameOverride?: string;
  automationId?: string;
  isLabelBottom?: boolean;
  calculateIsLabelBottom?: (compPointer: CompRef) => boolean;
}

export const mapStateToProps: MapStateToProps<
  HoveredComponentLabelsStateProps,
  HoveredComponentLabelsOwnProps
> = ({ editorAPI, state, dsRead }, { compPointer, displayNameOverride }) => {
  const mainCompRef = controlsUtils.getFirstControllableComponent(
    editorAPI,
    compPointer,
  );

  const isMobileOnlyNonNativeComponent =
    editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
      compPointer,
    );

  const isSection =
    sections.isSectionsEnabled() &&
    editorAPI.host.getAPI(SectionsApiKey).isSection(mainCompRef);

  const selectedComponent = selection.selectors.getSelectedCompsRefs(state);

  const isHoveredSelectedComp = editorAPI.utils.isSameRef(
    selectedComponent,
    compPointer,
  );

  return {
    isSection,
    isHoveredSelectedComp,
    isMobileOnlyNonNativeComponent,
    selectedComponent,
    showConnectIndication:
      platform.selectors.isConnectedToDataBindingController(
        compPointer,
        dsRead,
      ),
    componentUIColor: getComponentUIColor(editorAPI, compPointer),
    hoverBoxLabelStyles: getHoverBoxLabelStyles(editorAPI, compPointer),
    focusedPageId: dsRead.pages.getFocusedPageId(),
    displayName: displayNameOverride
      ? displayNameOverride
      : editorAPI.components.getDisplayName(compPointer),
    enableDevMode: editorAPI.developerMode.isEnabled(),
    isPinned: editorAPI.components.layout.isPinned(compPointer),
    compNickname: components.selectors.getNickname(mainCompRef, dsRead),
    compSecondaryNickname: components.selectors.getSecondaryNickname(
      mainCompRef,
      editorAPI,
    ),
    mainCompRef,
  };
};

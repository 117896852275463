import type { Shell, SlotKey } from '#packages/apilib';

const duplicatePageCodeConditionSlot: SlotKey<() => boolean> = {
  name: 'duplicatePageCodeCondition slot',
};

export const createDuplicatePageCodeConditionApi = (shell: Shell) => {
  const slot = shell.declareSlot(duplicatePageCodeConditionSlot);

  return {
    contributeShouldDuplicatePageCode: (
      _shell: Shell,
      condition: () => boolean,
    ) => {
      slot.contribute(_shell, condition);
    },
    shouldDuplicatePageCode: () => {
      const condition = slot.getSingleItem()?.contribution;
      return condition ? condition() : true;
    },
  };
};

import React, { type ComponentType } from 'react';
import { useQuery } from 'react-query';

import { SectionsMigrationApiKey } from '#packages/apis';
import * as util from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  MapStateToProps,
  MapDispatchToPropsFunction,
  ThunkAction,
} from 'types/redux';
import type { GroupingItem } from '#packages/sectionsMigration';

export interface ComponentWithInitDataProps {
  isInitialDataFetching: boolean;
  initialData: GroupingItem[];
  pageId: string;
  refetch: AnyFixMe;
}

interface WithInitDataStateProps {
  pageId: string;
}

interface WithInitDataDispatchProps {
  getEditorAPI: () => EditorAPI;
}

interface WithInitDataProps
  extends WithInitDataStateProps,
    WithInitDataDispatchProps {}

const getEditorAPI: ThunkAction = (dispatch, getState, { editorAPI }) =>
  editorAPI;

const mapStateToProps: MapStateToProps<WithInitDataStateProps, unknown> = ({
  editorAPI,
}) => {
  return {
    pageId: editorAPI.pages.getCurrentPageId(),
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  WithInitDataDispatchProps,
  unknown
> = (dispatch) => ({
  getEditorAPI: () => dispatch(getEditorAPI),
});

const FETCH_INITIAL_DATA_KEY = 'FETCH_INITIAL_DATA';

const fetchInitialDataFromDS = (editorAPI: EditorAPI): GroupingItem[] =>
  editorAPI.components.features.get(
    editorAPI.pages.getCurrentPage(),
    util.sections.constants.PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
  )?.sections;

const fetchDefaultInitialData = async (
  editorAPI: EditorAPI,
): Promise<GroupingItem[]> => {
  const pageRef = editorAPI.pages.getCurrentPage();
  const rootComponents = editorAPI.components
    .getChildren(pageRef)
    .filter((compRef) =>
      util.sections.isSectionableComponent(editorAPI.documentServices, compRef),
    );

  return editorAPI.host
    .getAPI(SectionsMigrationApiKey)
    .getPageGroupingByPrediction(pageRef, rootComponents);
};

const fetchInitialData = (editorAPI: EditorAPI) => async () => {
  const initialDataFromDS = fetchInitialDataFromDS(editorAPI);

  if (initialDataFromDS) {
    return initialDataFromDS;
  }

  return fetchDefaultInitialData(editorAPI);
};

const withInitData = <P extends {}>(WrappedComponent: ComponentType<P>) => {
  const Wrapper: React.FC<WithInitDataProps> = (props) => {
    const { pageId, getEditorAPI, ...ownComponentProps } = props;
    const { isFetching, data, refetch } = useQuery(
      `${FETCH_INITIAL_DATA_KEY}-${pageId}`,
      fetchInitialData(getEditorAPI()),
    );

    return (
      <WrappedComponent
        {...(ownComponentProps as P)}
        pageId={pageId}
        initialData={data}
        refetch={refetch}
        isInitialDataFetching={isFetching}
      />
    );
  };

  return util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(Wrapper);
};

export default withInitData;

// import * as commonKeyboardActions from '../util/commonKeyboardActions';
import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import * as coreBi from '#packages/coreBi';
import { bi } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

const LIGHTBOX_MODE_SHORTCUTS_CONFIG = {};
const keyboardMap = keyboardShortcutsActionMap.editor;

function addLightboxModeShortcuts(editorAPI: EditorAPI) {
  const keysToMerge: Record<string, Function> = {};

  keysToMerge[keyboardMap.CLOSE_PANELS.key] = () => {
    editorAPI.pages.popupPages.close();
    editorAPI.store.dispatch(
      bi.actions.event(coreBi.events.editor.ESCAPE_BUTTON_CLICKED, {
        mode: 'lightbox',
      }),
    );
  };
  Object.assign(LIGHTBOX_MODE_SHORTCUTS_CONFIG, keysToMerge);
}

export default {
  init(editorAPI: EditorAPI) {
    addLightboxModeShortcuts(editorAPI);
  },
  shortcuts: LIGHTBOX_MODE_SHORTCUTS_CONFIG,
};

// @ts-nocheck
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import $ from 'zepto';
import * as core from '#packages/core';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';

import React from 'react';
import { FocusPanelFrame } from '../frames';
import { cx } from '#packages/util';
import type { EditorAPI } from '#packages/editorAPI';

function template() {
  return (
    <FocusPanelFrame
      panelName={this.props.panelName}
      shouldHideHelp={true}
      title={translate('AVIARY_PANEL_TITLE')}
      onClose={this.onCloseFrame}
      className="aviary-panel"
    >
      <div className="aviary-dialog">
        <div ref="content" id="aviary_dialog_editor_content_wrapper" />
        <div id="message" className={cx({ hidden: !this.state.showMessage })}>
          <div className="message-bg">
            <span
              id="progress"
              className={cx({ hidden: this.state.counter == null })} //eslint-disable-line no-eq-null
            >
              <span id="counter">{this.state.counter}%</span>
            </span>
            <span id="messageContent">{this.state.messageText}</span>
            <span
              id="cancel-button"
              onClick={this.onCancelClicked}
              className={cx({ hidden: !this.state.showCancelButton })}
            >
              {translate('AVIARY_CANCEL_UPLOAD')}
            </span>
          </div>
        </div>
      </div>
    </FocusPanelFrame>
  );
}

const MAX_IMAGE_PYRAMID_SIZE = 1500;
let IMAGE_PREFIX = util.serviceTopology.htmlEditorRootUrl;
IMAGE_PREFIX = IMAGE_PREFIX
  ? IMAGE_PREFIX.replace(/html\/editor\/?/, '')
  : 'http://editor.wix.com/';

// TODO: tolology
const { mediaServerRoot } = util.serviceTopology;
const host = '//editor.';
const baseUrl = host + mediaServerRoot;
const baseStaticUrl = `//0.static.${mediaServerRoot}/api/add_file`;
const editedTagAttribute = 'edited image';

const wixErrors = {
  AVIARY_MISSING_ID_IN_SKIN: {
    errorCode: 21030,
    desc: 'Missing an id on this._skinParts.content, it is required for Aviary to work',
  },
  AVIARY_DIALOG_WRONG_DATA_TYPE: {
    errorCode: 21031,
    desc: 'Data is missing or Data Type is not an Image Data',
  },
  AVIARY_NOT_LOADED: {
    errorCode: 21032,
    desc: 'Aviary.js was not loaded after 5000ms',
  },
  AVIARY_FORCE_CLOSED: {
    errorCode: 21033,
    desc: 'Aviary dialog was not closed on first try, forced it to close on second try',
  },
  AVIARY_UPLOAD_GET_TICKET_FAILED: {
    errorCode: 21034,
    params: { c1: 'error message' },
    desc: 'Failed on getting ticket for upload.',
  },
  AVIARY_UPLOAD_TO_STATIC_FAILED: {
    errorCode: 21035,
    params: { c1: 'error message' },
    desc: 'Uploading image to statics failed.',
  },
  AVIARY_UPLOAD_UPDATE_MEDIA_FAILED: {
    errorCode: 21036,
    params: { c1: 'error message' },
    desc: 'Updating private media with uploaded file failed.',
  },

  AVIARY_RETURNED_AN_ERROR: {
    errorCode: 21037,
    params: { c1: 'error message', i1: 'error code' },
    desc: 'Aviary editor failed on external script error.',
  },

  AVIARY_REACHED_INNER_SAVE_FUNCTION: {
    errorCode: 21038,
    desc: 'Aviary somehow called its inner save function, this does not supposed to happen.',
  },
};

let featherEditor;

function calcStateFromProps(props) {
  const state = {};
  const iData = props.imageLink.value;
  let { uri } = iData;
  const prefix = /(^https?)|(^data)/.test(uri) ? '' : '/static/media/';
  const mimeType = /(jpg$)|(jpeg$)/i.test(uri) ? 'image/jpeg' : 'image/png';

  if (
    iData.width > MAX_IMAGE_PYRAMID_SIZE ||
    iData.height > MAX_IMAGE_PYRAMID_SIZE
  ) {
    uri = `${uri}_${MAX_IMAGE_PYRAMID_SIZE}`;
  }

  state.tmpImageSrc = IMAGE_PREFIX + prefix + uri;
  state.tmpImageType = mimeType;

  return state;
}

/**
 * Normal HTTP Request
 * @param method
 * @param url
 * @param data
 * @param onerror
 * @param onload
 * @param contentType
 */
function request(method, url, data, onerror, onload, contentType) {
  method = method || 'GET';
  const xhr = new window.XMLHttpRequest();
  const onChange = function () {
    if (xhr.readyState === 4 /* complete */) {
      if (xhr.status === 200 || xhr.status === 304) {
        onload(xhr);
      } else {
        onerror(xhr);
      }
    }
  };
  xhr.open(method, url, true);
  xhr.onreadystatechange = onChange;
  xhr.setRequestHeader('Content-type', contentType || 'text/plain');
  xhr.send(data);
}

function getTicket(url, onTicketResponse) {
  const getTicketValue = function (res) {
    try {
      res = JSON.parse(res.responseText);
    } catch (ticketError) {
      res.status = 'error';
      res.errorDescription = 'Parse error: ticket returned unexpected response';
    }
    onTicketResponse(res);
  };

  request(
    'GET',
    `${url}?accept=json`,
    null,
    getTicketValue,
    getTicketValue,
    'text/plain',
  );
}

function uploadDataUrl(dataUri, onFileUploadEnd) {
  getTicket(
    `${baseUrl}/media/tickets/get`,
    function (res) {
      const ticket = res.responseValue;
      if (!ticket) {
        //LOG.reportError(wixErrors.AVIARY_UPLOAD_GET_TICKET_FAILED, 'AviaryDialog', '_getTicket', {c1: res.errorDescription});
        showErrorMessage(
          'ERROR_AVIARY_TITLE',
          'ERROR_AVIARY_UPLOAD_MESSAGE',
          `Code: ${wixErrors.AVIARY_UPLOAD_GET_TICKET_FAILED.errorCode}`,
        );
        setEditorWaitState.call(this, true);
        return false;
      }
      startUpload.call(this, ticket, dataUri, onFileUploadEnd);
    }.bind(this),
  );
}

/**
 * Get the size in pixels of the image's enclosing component
 * @return {String} the size in pixels as an aspect ratio.
 */
function getOriginalAspectRatio() {
  const editorAPI: EditorAPI = this.getEditorAPI();
  const component = editorAPI.selection.getSelectedComponents();
  const layout = editorAPI.components.layout.get_size(component);
  const componentWidth = layout.width;
  const componentHeight = layout.height;

  const aspectRatio = `${componentWidth}:${componentHeight}`;
  return aspectRatio;
}

function parseMediaResponseXML(response) {
  let upload;
  let success;
  let doc;
  // For IE XDomainRequest
  if (typeof response === 'string') {
    doc = new window.Element('div');
    doc.innerHTML = response;
  } else {
    doc = response;
  }

  if (doc) {
    upload = doc.querySelector('upload');
    success = upload.getAttribute('success');
  } else {
    upload = {
      getAttribute() {
        return 'No Response Error';
      },
    };
    success = false;
  }
  if (util.stringUtils.isTrue(success)) {
    const properties = doc.querySelector('properties');
    const sys_meta = doc.querySelector('sys_meta');
    return {
      status: upload.getAttribute('errorDescription'),
      originalName: sys_meta.getAttribute('original_file'),
      mimeType: sys_meta.getAttribute('mime_type'),
      fileUrl: sys_meta.getAttribute('file_url'),
      iconUrl: sys_meta.getAttribute('icon_url'),
      fileSize: sys_meta.getAttribute('file_size'),
      width: sys_meta.getAttribute('width'),
      height: sys_meta.getAttribute('height'),
      ver: sys_meta.getAttribute('ver'),
      fileName: properties.getAttribute('file_name'),
    };
  }

  return {
    status: 'error',
    origin: upload.getAttribute('origin'),
    errorCode: upload.getAttribute('errorCode'),
    errorDescription: upload.getAttribute('errorDescription'),
  };
}

function startUpload(ticket, dataUri, onFileUploadEnd) {
  const parse = parseMediaResponseXML;

  const parseFileUploadResponse = function () {
    const responseBody = this.responseXML || this.responseText;
    onFileUploadEnd(parse(responseBody));
  };

  const dataObj = { datauri: dataUri, ut: ticket, s: 'media' };
  let data = [];
  let name;
  for (name in dataObj) {
    if (dataObj.hasOwnProperty(name)) {
      data.push(`${name}=${dataObj[name]}`);
    }
  }
  data = data.join('&');
  const callbacks = {
    onLoad: parseFileUploadResponse,
    onError: parseFileUploadResponse,
    onTimeout: parseFileUploadResponse,
    onUploadProgress: onProgress.bind(this),
    onAbort: this.onAbort,
  };
  requestCORS.call(
    this,
    'POST',
    baseStaticUrl,
    data,
    callbacks,
    'application/x-www-form-urlencoded',
    false,
  );
}

function onProgress(event) {
  if (event.lengthComputable) {
    //event.loaded the bytes browser receive
    //event.total the total bytes set by the header

    const percentComplete = Math.round((event.loaded / event.total) * 100);
    this.setState({
      counter: percentComplete,
    });
  }
}

/**
 * CORS HTTP Request (Don't Forget: IE < 10 uses only plaintext with no cookie)
 * @param method
 * @param url
 * @param data
 * @param callbacks
 * An object with one or more 0f the following functions:
 * {
 *     onLoad: function(){}
 *     onError: function(){}
 *     onTimeout: function(){}
 *     onProgress: function(){}
 *     onAbort: function(){}
 * }
 * @param contentType
 * @param withCredentials
 */
function requestCORS(
  method,
  url,
  data,
  callbacks,
  contentType,
  withCredentials,
) {
  method = method || 'GET';
  callbacks = callbacks || {};

  let async = true;
  const isXDomain = typeof XDomainRequest !== 'undefined';

  // If IE < 10
  if (isXDomain) {
    this._xhrObject = new window.XDomainRequest();
    async = undefined;
  } else {
    this._xhrObject = new window.XMLHttpRequest();
  }

  const xhr = this._xhrObject;
  // IE10 issue - xhr.open() should be called before any XHR settings
  xhr.open(method, url, async);

  xhr.timeout = 5 * 60 * 1000;
  xhr.onload = callbacks.onLoad || _.noop;
  xhr.onerror = callbacks.onError || _.noop;
  xhr.ontimeout = callbacks.onTimeout || _.noop;
  xhr.onprogress = callbacks.onProgress || _.noop;

  if (xhr.upload) {
    xhr.upload.addEventListener(
      'progress',
      callbacks.onUploadProgress || _.noop,
      false,
    );
  }
  // If Normal Browser
  if (!isXDomain) {
    xhr.onabort = callbacks.onAbort || _.noop;
    // Assume data is plain text unless stated otherwise
    xhr.setRequestHeader('Content-type', contentType || 'text/plain');
    xhr.withCredentials = Boolean(withCredentials);
  }

  xhr.send(data);
}

function onUploadResponse(response) {
  response = response || {};
  if (response.status === 'error' || response.status === 'timeout') {
    setEditorWaitState.call(this, false);
    //LOG.reportError(wixErrors.AVIARY_UPLOAD_TO_STATIC_FAILED, 'AviaryDialog', '_onUploadResponse', {c1: response.errorDescription});
    showErrorMessage(
      'ERROR_AVIARY_TITLE',
      'ERROR_AVIARY_UPLOAD_MESSAGE',
      `Code: ${wixErrors.AVIARY_UPLOAD_TO_STATIC_FAILED.errorCode}`,
    );
    return false;
  }

  const date = new Date();
  const timestamp = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    [date.getHours(), date.getMinutes(), date.getSeconds()].join(':'),
  ].join('-');

  this._newImageUri = response.fileName;
  response.originalName = `${
    this.props.imageLink.value.title || 'Edited Image'
  } ${timestamp}`;

  finalizeUpload.call(this, response);
}

function createMediaAddRequestXML(response) {
  let r = '<mediaItemList>';
  r += '<mediaItem';
  r += ' mediaType="picture';
  r += '" componentType="photo';
  r += `" fileName="${response.fileName}`;
  r += `" originalFileName="${response.originalName}`;
  r += `" fileSize="${response.fileSize}`;
  r += `" width="${response.width}`;
  r += `" height="${response.height}`;
  r += `" mimeType="${response.mimeType}`;
  r += `" iconURL="${response.iconUrl}`;
  r += `" version="${response.ver}`;
  r += `" tags="${editedTagAttribute}`;
  r += '" />\n';

  r += '</mediaItemList>';
  return r;
}

function finalizeUpload(response) {
  const data = createMediaAddRequestXML(response);
  const url = `${baseUrl}/media/private/add`;

  const onerror = function () {
    //LOG.reportError(wixErrors.AVIARY_UPLOAD_UPDATE_MEDIA_FAILED, 'AviaryDialog', '_finalizeUpload');
    showErrorMessage(
      'ERROR_AVIARY_TITLE',
      'ERROR_AVIARY_UPLOAD_MESSAGE',
      `Code: ${wixErrors.AVIARY_UPLOAD_UPDATE_MEDIA_FAILED.errorCode}`,
    );
    return false;
  };

  const onsuccess = function () {
    closeAndSave.call(this);
  }.bind(this);

  request('POST', url, data, onerror, onsuccess, 'application/xml');
}

function closeAndSave() {
  //LOG.reportEvent(wixEvents.AVIARY_SAVE_SUCCESS, {c1: this._data.get('uri'), i1: this._data.get('originalImageDataRef') ? 1 : 0});
  setNewImageData.call(this);
  enableCloseButton.call(this);
  closeAviary.call(this, { result: 'OK' });
}

/**
 * Close the aviary editor and suppress Dialog Window default 'CANCEL' action
 * @param event
 * the close result (needs to be: reason) for closing the editor
 * Current known values are 'OK', 'CANCEL'
 * @return {Boolean} return false to suppress Dialog Window default 'CANCEL' action
 */
function closeAviary(event) {
  event = event || {};

  if (this.state.disableCloseButton) {
    return;
  }

  this._aviaryCloseCause = event.result || 'CANCEL';
  featherEditor.close();
  //this._skinParts.cancelButton.removeEvent(Constants.CoreEvents.CLICK, this._onAbort);

  //If closing the panel once did not work, than in 2 seconds force close click
  if (!this._forceClose) {
    this.setTimeoutNamed(
      'close',
      function () {
        this._forceClose = true;
      }.bind(this),
      2000,
    );
  }
  /*else {
     LOG.reportError(wixErrors.AVIARY_FORCE_CLOSED, 'AviaryDialog', '_closeAviary');
     }*/
}

function enableCloseButton() {
  this.setState({
    disableCloseButton: false,
  });
}

/**
 * Set the new image data, save original Image Data Ref if one doesn't exist
 */
function setNewImageData() {
  if (!this._newImageSize || !this._newImageUri) {
    return;
  }

  let { originalImageDataRef } = this.props.imageLink.value;

  if (!originalImageDataRef) {
    originalImageDataRef = _.cloneDeep(this.props.imageLink.value);

    delete originalImageDataRef.originalImageDataRef;
  }

  this.props.imageLink.requestChange({
    height: this._newImageSize.height,
    width: this._newImageSize.width,
    uri: this._newImageUri,
    originalImageDataRef,
  });

  this._newImageSize = null;
  this._newImageUri = null;
}

/**
 * Helper function for _setEditorWaitState
 * Show or hide a wait message and a progress indicator if applicable
 * @param text
 * @param showProgress
 * @private
 */
function showEditorWaitMessages(text, showProgress) {
  let state;
  if (text) {
    state = {
      messageText: text,
      showMessage: true,
    };
  } else {
    state = {
      showMessage: false,
    };
  }
  if (showProgress) {
    state.showCancelButton = true;
    // IE < 10 doesn't know how to show upload progress
    if (!window.XDomainRequest) {
      state.counter = 0;
    }
  } else {
    _.merge(state, {
      showCancelButton: false,
      counter: null,
    });
  }

  this.setState(state);
}

function setEditorWaitState(state, text, showProgress) {
  //If something wrong with Aviary, skip
  if (!(featherEditor && window.AV?.paintWidgetInstance)) {
    return;
  }
  // IF true, disable controls and show message
  if (state) {
    featherEditor.disableControls();
    featherEditor.showWaitIndicator();
    this.setState({
      disableCloseButton: true,
    });
    showEditorWaitMessages.call(this, text, showProgress);
  } else {
    // Return to normal
    featherEditor.enableControls();
    featherEditor.hideWaitIndicator();
    this.setState({
      disableCloseButton: false,
      showMessage: false,
    });
  }
}

function showErrorMessage(titleLangId, messageLangId, details) {
  //var title = titleLangId && translate(titleLangId);
  const message = messageLangId && translate(messageLangId);

  // TODO: replace confirm with a proper panel
  const confirmFn = window?.confirm || function () {};
  confirmFn(message, details);
}

const aviaryProxy = {
  reactFunctions: {},
  onLoad: fnFromReactComponent('onLoad'),
  onAviarySave: fnFromReactComponent('onAviarySave'),
  onAviaryClose: fnFromReactComponent('onAviaryClose'),
  onAviaryReady: fnFromReactComponent('onAviaryReady'),
  onAviarySaveButtonClick: fnFromReactComponent('onAviarySaveButtonClick'),
  onError: fnFromReactComponent('onError'),
};

function fnFromReactComponent(fnName) {
  return function () {
    if (aviaryProxy.reactFunctions[fnName]) {
      aviaryProxy.reactFunctions[fnName]();
    }
  };
}

function initAviary() {
  aviaryProxy.reactFunctions = {
    onLoad: this.onLoad,
    onAviarySave: this.onAviarySave,
    onAviaryClose: this.onAviaryClose,
    onAviaryReady: this.onAviaryReady,
    onAviarySaveButtonClick: this.onAviarySaveButtonClick,
    onError: this.onError,
  };

  if (!featherEditor) {
    featherEditor = new window.Aviary.Feather({
      apiKey: '57b23071b',
      apiVersion: 3,
      tools:
        'crop,orientation,enhance,effects,frames,brightness,contrast,saturation,warmth,focus,sharpness,draw,redeye,whiten,blemish,stickers,text',
      maxSize: MAX_IMAGE_PYRAMID_SIZE,
      theme: 'minimum',
      noCloseButton: true,
      displayImageSize: true,
      onLoad: aviaryProxy.onLoad,
      onSave: aviaryProxy.onAviarySave,
      onClose: aviaryProxy.onAviaryClose,
      onReady: aviaryProxy.onAviaryReady,
      onSaveButtonClicked: aviaryProxy.onAviarySaveButtonClick,
      onError: aviaryProxy.onError,
      appendTo: 'aviary_dialog_editor_content_wrapper',
      language: getAviaryLangCode(util.languages.getLanguageCode()),
      launchDelay: 0,
    });
  } else {
    aviaryProxy.onLoad();
  }
}

function getAviaryLangCode(editorLangCode) {
  return editorLangCode ? editorLangCode.toLowerCase() : 'en';
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'aviaryPanel',
  mixins: [
    util.postMessageMixin,
    util.timeoutsMixin,
    core.mixins.editorAPIMixin,
  ],
  propTypes: {
    imageLink: PropTypes.object, // ['uri', 'width', 'height', 'originalImageDataRef']
    onDialogResult: PropTypes.func,
    editorAPI: PropTypes.object.isRequired,
  },

  getInitialState() {
    const stateFromProps = calcStateFromProps(this.props);
    _.merge(stateFromProps, {
      tmpImageId: `some_random_id_${Date.now()}`,
      disableCloseButton: true,
      showMessage: true,
      messageText: null,
      showCancelButton: false,
      counter: null,
    });

    return stateFromProps;
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState(calcStateFromProps(newProps));
  },

  componentDidMount() {
    // TODO: implement this logic
    /*if (!this._data || this._data.getType() !== 'Image'){
         LOG.reportError(wixErrors.AVIARY_DIALOG_WRONG_DATA_TYPE, 'AviaryDialog', '_onDialogOpened');
         return false;
         }*/

    initAviary.call(this);

    //Enable the user to close the dialog after 10 seconds of load time
    this.setTimeoutNamed(
      'aviaryIsDeadTimer',
      function () {
        this.setState({ disableCloseButton: false }); //eslint-disable-line react/no-did-mount-set-state
      }.bind(this),
      10000,
    );

    showEditorWaitMessages.call(
      this,
      translate('AVIARY_LOAD_MESSAGE', 'Loading Image Editor...'),
      false,
    );
  },

  UNSAFE_componentWillMount() {
    const $image = $('<img/>', {
      id: this.state.tmpImageId,
      src: this.state.tmpImageSrc,
      type: this.state.tmpImageType,
      crossOrigin: '',
    });
    this.image = $image.get(0);
  },

  launchAviary() {
    const originalName = translate('AVIARY_ORIGINAL_ASPECT');
    featherEditor.launch({
      image: this.state.tmpImageId,
      cropPresetDefault: originalName,
      cropPresets: [
        [originalName, getOriginalAspectRatio.call(this)],
        'Custom',
      ],
    });

    //Don't open autosave dialog while in Aviary
    //TODO: this._editor.setPostponeAutoSave(60000);
  },

  onLoad() {
    const originalName = translate('AVIARY_ORIGINAL_ASPECT');
    featherEditor.launch({
      image: this.image,
      cropPresetDefault: originalName,
      cropPresets: [
        [originalName, getOriginalAspectRatio.call(this)],
        'Custom',
      ],
    });
  },

  /**
   * The default Aviary save callback, called by onSave
   * We bypass this function and should never reach it.
   * @param imageID
   * @param newURL
   */
  onAviarySave() {
    //LOG.reportError(wixErrors.AVIARY_REACHED_INNER_SAVE_FUNCTION, 'AviaryDialog', '_onAviarySave');
    return false;
  },

  onAviaryClose() {
    this.clearTimeoutNamed('close');
    this._forceClose = false;

    if (this.props.onDialogResult) {
      this.props.onDialogResult(this._aviaryCloseCause);
    }

    // TODO: Implement this
    /*if (this._aviaryCloseCause === 'CANCEL'){
         LOG.reportEvent(wixEvents.AVIARY_CANCEL, {});
         }*/
  },

  /**
   * Hide loaders when Aviary is ready.
   * Called by onReady
   */
  onAviaryReady() {
    this.clearTimeoutNamed('aviaryIsDeadTimer');
    this.setState({
      showMessage: false,
      disableCloseButton: false,
    });
  },

  /**
   * Get the image data from Aviary's canvas element and update the image Data Item
   * Also, save the original image's URI and dimensions to the image Data Item
   * @return {Boolean} return false to suppress Aviary's default save action
   */
  onAviarySaveButtonClick() {
    //Get the list of actions from aviary editor, assume that there will always be one action in the list
    const aviaryActionList = JSON.parse(featherEditor.getActionList());
    const isImageChanged = (aviaryActionList?.actionlist?.length ?? 0) > 1;
    const canvasElement = ReactDOM.findDOMNode(this.refs.content).querySelector(
      '#avpw_canvas_element',
    );
    let dataUri = '';

    if (isImageChanged) {
      setEditorWaitState.call(
        this,
        true,
        translate('AVIARY_WAIT_MESSAGE', 'Uploading image, please wait...'),
        true,
      );
      dataUri = encodeURIComponent(
        canvasElement.toDataURL(this.state.tmpImageType),
      ); //restore the same type as before
      this._newImageSize = {
        width: canvasElement.width,
        height: canvasElement.height,
      };

      /*TODO: LOG.reportEvent(wixEvents.AVIARY_SAVE_CHANGES, {
             c1: this._data.get('uri'),
             i1: this._data.get('originalImageDataRef') ? 1 : 0,
             g1: this._newImageSize.width + 'X' + this._newImageSize.height + '|' + dataUri.length
             });*/
      uploadDataUrl.call(this, dataUri, onUploadResponse.bind(this));
    } else {
      //TODO: LOG.reportEvent(wixEvents.AVIARY_SAVE_NO_CHANGE, {c1: this._data.get('uri'), i1: this._data.get('originalImageDataRef') ? 1 : 0});
      closeAviary.call(this, { result: 'CANCEL' });
    }

    return false;
  },

  onProgress(event) {
    if (event.lengthComputable) {
      //event.loaded the bytes browser receive
      //event.total the total bytes set by the header

      const percentComplete = Math.round((event.loaded / event.total) * 100);
      this.setState({
        counter: percentComplete,
      });
    }
  },

  onAbort() {
    setEditorWaitState.call(this, false);
    //LOG.reportEvent(wixEvents.AVIARY_CANCEL_WHILE_SAVE, {});
    this._xhrObject = null;
    return false;
  },

  onError(errorObj) {
    //LOG.reportError(wixErrors.AVIARY_RETURNED_AN_ERROR, 'AviaryDialog', '_onError', {i1: errorObj.code, c1: errorObj.message});
    showErrorMessage(
      'ERROR_AVIARY_TITLE',
      'ERROR_AVIARY_GENERAL_MESSAGE',
      `Codes: ${wixErrors.AVIARY_RETURNED_AN_ERROR.errorCode}, ${errorObj.code}`,
    );
    return false;
  },

  onCloseFrame() {
    closeAviary.call(this);
  },
  componentWillUnmount() {
    closeAviary.call(this);
  },
  onCancelClicked() {
    if (this._xhrObject) {
      this._xhrObject.abort();

      // XDomainRequest doesn't have an 'onabort' method so polyfilling it
      if (window.XDomainRequest) {
        this.onAbort();
      }
    } else {
      setEditorWaitState.call(this, false);
    }
  },

  render() {
    return template.call(this);
  },
});

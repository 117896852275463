import type { EntryPoint, Shell } from '#packages/apilib';
import * as util from '#packages/util';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  WixCodeWorkspaceApiKey,
} from '#packages/apis';
import _ from 'lodash';

async function loadRepluggableEntryPointsIfNeeded(shell: Shell) {
  if (!util.appStudioUtils.isAppStudio()) {
    const {
      repluggableEntrypoint,
      wixCodeAdapterEntryPoint,
      ToursAdapterAPIEntryPoint,
    } = await import('@/wixCode');
    const entryPoints: EntryPoint[] = [
      repluggableEntrypoint,
      ToursAdapterAPIEntryPoint,
      wixCodeAdapterEntryPoint.entryPoint,
    ];

    if (entryPoints.some((entryPoint) => !entryPoint)) {
      const error = new Error(
        '`loadRepluggableEntryPointsIfNeeded` failed to load entry points',
      );
      if (process.env.NODE_ENV === 'test') {
        // NOTE: this error is spaming the tests, so we are just logging it
        // eslint-disable-next-line no-console
        console.warn(error);
        return;
      }

      throw error;
    }

    shell.addShells(entryPoints);
  }
}
const loadRepluggableEntryPointsIfNeededOnce = _.once(
  loadRepluggableEntryPointsIfNeeded,
);

export const WixCodePackageLoaderEntryPoint: EntryPoint = {
  name: 'WixCodePackageLoader',

  layer: 'SITE',
  getDependencyAPIs() {
    return [EditorAPIKey, WixCodeWorkspaceApiKey, EditorCoreApiKey];
  },
  async extend(shell: Shell) {
    const editorAPI = shell.getAPI(EditorAPIKey);
    shell
      .getAPI(WixCodeWorkspaceApiKey)
      .hooks.devModeEnabled.tapOnce(async () => {
        await loadRepluggableEntryPointsIfNeededOnce(shell);
      });

    await shell.getAPI(EditorCoreApiKey).hooks.initReady.promise;

    if (
      editorAPI.developerMode.isEnabled() ||
      editorAPI.documentServices.wixCode.isProvisioned() // we need this case to support local editor when in Github mode (dev mode can be off, but wixCode UI should be displayed)
    ) {
      await loadRepluggableEntryPointsIfNeededOnce(shell);
    }
  },
};

export { WixCodeWorkspaceEntrypoint } from './workspaceApi/scope';

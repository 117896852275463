import * as util from '#packages/util';

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapStateToProps: null = null;

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    closePanel: editorAPI.panelManager.closePanelByName,
    enableDeveloperMode: () => {
      if (!editorAPI.developerMode.isEnabled()) {
        editorAPI.developerMode.toggle();
      }
    },
    openHelpCenter: (helpId: string, props?: object, biHelpParams?: object) =>
      editorAPI.panelManager.openHelpCenter(helpId, props, biHelpParams),
  };
};

export const connectCorvidDevModePanel = <TComponentProps>(
  Component: React.ComponentClass<TComponentProps>,
) =>
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(util.hoc.renderWhenMutated(Component));

import { SectionsApiKey } from '#packages/apis';
import { translate } from '#packages/i18n';

import { TRANSLATIONS } from '../../../translations';

import type { CompNamePlugin } from 'types/editorPlugins';
import type { Scope } from '#packages/sections';

export const getSectionCompNamePlugin =
  (scope: Scope): CompNamePlugin =>
  (compRef) => {
    const { editorAPI } = scope;

    const sectionName = editorAPI.host
      .getAPI(SectionsApiKey)
      .getSectionName(compRef);

    return translate(TRANSLATIONS.SECTION_NAME, {
      section_name: sectionName,
    });
  };

import addPanelDataConsts from '#packages/addPanelDataConsts';
import type { EditorAPI } from '#packages/editorAPI';
import * as addPanel from '#packages/oldAddPanel';
import {
  onClickToContainer,
  onDropToContainer,
} from '../../contact/wixForms/utils';
import getFifthContactItemExtension from './fifthItem';
import getFourthContactItemExtension from './fourthItem';
import getSecondContactItemExtension from './secondItem';
import getSixthContactItemExtension from './sixthItem';

export default (
  editorAPI: EditorAPI,
  categoryId: string = addPanelDataConsts.CATEGORIES_ID.STRIP,
) => {
  const { stripContainerContactSection } =
    addPanel.sections.getSections().strip;
  const sectionTitle = stripContainerContactSection.title;
  const onDrop = onDropToContainer(categoryId, sectionTitle).bind(
    null,
    editorAPI,
  );
  const onClick = onClickToContainer(categoryId, sectionTitle).bind(
    null,
    editorAPI,
  );

  const itemsExtensions = [
    null,
    getSecondContactItemExtension(onDrop, onClick),
    null,
    getFourthContactItemExtension(onDrop, onClick),
    getFifthContactItemExtension(onDrop, onClick),
    getSixthContactItemExtension(onDrop, onClick),
  ];

  return addPanel.dataUtil.extendPropsItems(
    stripContainerContactSection,
    itemsExtensions,
  );
};

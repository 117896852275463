import React, { useState, type FC } from 'react';

import { biLogger } from '#packages/util';
import * as util from '#packages/util';

import { panelOpened } from '@wix/bi-logger-editor/v2';

import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps,
} from './PublishErrorPanelMapper';
import PublishErrorPanelDevUI from './PublishErrorPanelDevUI';
import PublishErrorPanelNonDevUI from './PublishErrorPanelNonDevUI';
import type { PublishErrorPanelProps } from './PublishErrorPanelTypes';
import styles from './PublishErrorPanel.scss';

const PublishErrorPanel: FC<PublishErrorPanelProps> = ({
  closePanel,
  isDevMode,
  publishAnywayTrigger,
  onHelp,
  deploymentId,
}) => {
  const [isIgnorePublishErrorValue, setIsIgnorePublishErrorValue] =
    useState<boolean>(false);

  const sendBiOnOpen = (panelName: string) => {
    biLogger.report(
      panelOpened({
        panel_name: panelName,
      }),
    );
  };

  const handleClose = (origin: string, panelName: string) => {
    closePanel(isIgnorePublishErrorValue, origin, panelName);
  };

  const handlePublishAnyway = (origin: string, panelName: string) => {
    publishAnywayTrigger(isIgnorePublishErrorValue, origin, panelName);
  };

  return (
    <div className={styles.publishPipeline}>
      {isDevMode ? (
        <PublishErrorPanelDevUI
          isIgnorePublishErrorValue={isIgnorePublishErrorValue}
          setIsIgnorePublishErrorValue={setIsIgnorePublishErrorValue}
          handleClose={handleClose}
          handlePublishAnyway={handlePublishAnyway}
          onHelp={onHelp}
          sendBiOnOpen={sendBiOnOpen}
          deploymentId={deploymentId}
        />
      ) : (
        <PublishErrorPanelNonDevUI
          isIgnorePublishErrorValue={isIgnorePublishErrorValue}
          setIsIgnorePublishErrorValue={setIsIgnorePublishErrorValue}
          handleClose={handleClose}
          handlePublishAnyway={handlePublishAnyway}
          onHelp={onHelp}
          sendBiOnOpen={sendBiOnOpen}
        />
      )}
    </div>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PublishErrorPanel);

import { createReduxStore } from '#packages/apilib';
import type { ContentManager } from '#packages/presetApi';

export interface ContentInjectionState {
  contentManager?: ContentManager;
  isSectionsContentInjected?: boolean;
  isPagesContentInjected?: boolean;
}

const getInitialState = (): ContentInjectionState => ({
  isSectionsContentInjected: false,
  isPagesContentInjected: false,
});

export const ContentInjectionStore = createReduxStore({
  getInitialState,
  selectors: {
    getContentManager: (state: ContentInjectionState) => state.contentManager,
    getIsSectionsContentInjected: (state: ContentInjectionState) =>
      state.isSectionsContentInjected,
    getIsPagesContentInjected: (state: ContentInjectionState) =>
      state.isPagesContentInjected,
  },
  actions: {
    setContentManager: (
      state: ContentInjectionState,
      contentManager: ContentInjectionState['contentManager'],
    ) => {
      return {
        ...state,
        contentManager,
      };
    },
    setIsSectionsContentInjected: (
      state: ContentInjectionState,
      isSectionsContentInjected: ContentInjectionState['isSectionsContentInjected'],
    ) => {
      return {
        ...state,
        isSectionsContentInjected,
      };
    },
    setIsPagesContentInjected: (
      state: ContentInjectionState,
      isPagesContentInjected: ContentInjectionState['isPagesContentInjected'],
    ) => {
      return {
        ...state,
        isPagesContentInjected,
      };
    },
  },
});

import React from 'react';
import _ from 'lodash';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import { translate } from '#packages/i18n';
import mapper from './languagePanelMapper';
import constants from '#packages/constants';
import { Composites, Divider, PanelHeader, TextButton } from '@wix/wix-base-ui';
//@ts-expect-error-next-line
import coreMultilingual from 'coreMultilingual';
import type { LanguageRowProps } from './languageRow';
import LanguageRowMid from './languageRowMid';
import type {
  LanguageDefinition,
  SendBIFunction,
} from 'types/documentServices';
import experiment from 'experiment';
import { LocaleRow } from './localeRow';

type LanguageStatus = LanguageDefinition['status'];

const { LANGUAGE_STATUSES, keyboardContextKey } = constants.MULTILINGUAL;
const { isTranslating } = stateManagement.multilingual.selectors;

const BIOriginToSourceMap = {
  top_bar: 'manage_languages_top_bar_drop_menu',
  secondary_bar: 'manage_languages_secondary_bar_drop_menu',
};

export interface LanguagePanelStateProps {
  isEnabled: boolean;
  isPreviewMode: boolean;
  siteId: string;
  currentLanguageCode?: string;
  originalLanguage?: { [key: string]: any };
  translationLanguages?: any;
  didShowPublishSuccessPanel?: boolean;
  didShowUnpublishPanel?: boolean;
  isCurrentLanguageSecondary: boolean;
}

type SendBiFunction = SendBIFunction extends (...a: infer U) => infer R
  ? (...a: [...U, string]) => R
  : never;

export interface LanguagePanelDispatchProps {
  setCurrentLanguage: (languageCode: string) => void;
  startLanguageChange: (languageCode: string) => void;
  closeOpenedPanels: () => void;
  deselectAll: () => void;
  sendBI: SendBiFunction;
  openPanel: () => void;
  updateLanguageStatus: (
    languageCode: string,
    status: LanguageStatus,
  ) => Promise<void>;
  openPublishSuccessPanel: (languageName: string) => void;
  openUnpublishPanel: (lang: LanguageDefinition) => void;
  openHelpCenter: () => void;
}

export interface LanguagePanelExternalProps {
  biOrigin?: string;
  biHosting?: string;
  closeHandle?: () => void;
  onMouseEnter?: () => void; // Was added to support things like dropPanels.languageDropPanel -> in the topar itself we delegate this to dropDown
  onMouseLeave?: () => void;
}

export interface LanguagePanelProps
  extends LanguagePanelStateProps,
    LanguagePanelDispatchProps,
    LanguagePanelExternalProps {}

class LanguagePanel extends React.Component<LanguagePanelProps, {}> {
  static defaultProps = { closeHandle: _.noop };

  componentDidMount() {
    this.registerKeyboardShortcuts();
    this.lastKeyboardContext = util.keyboardShortcuts.getContext();

    //@ts-expect-error
    util.keyboardShortcuts.getContext(keyboardContextKey);

    if (this.props.biOrigin) {
      this.props.sendBI(
        coreBi.events.multilingual.language_picker_opened,
        { origin: this.props.biOrigin, hosting: this.props.biHosting },
        this.props.siteId,
      );
    }
  }

  componentWillUnmount() {
    if (isTranslating) {
      util.keyboardShortcuts.setContext(
        util.keyboardShortcuts.CONTEXTS.MULTILINGUAL_MODE,
      );
    } else {
      util.keyboardShortcuts.setContext(this.lastKeyboardContext);
    }
    if (this.props.biOrigin) {
      this.props.sendBI(
        coreBi.events.multilingual.language_picker_closed,
        { origin: this.props.biOrigin, hosting: this.props.biHosting },
        this.props.siteId,
      );
    }
  }

  lastKeyboardContext: AnyFixMe;

  setCurrentLanguage(lang: LanguageDefinition) {
    const isQuickEditExperimentOpen = experiment.isOpen(
      'se_openQuickEditWhenSecondaryLanguageSelected',
    );

    if (lang.languageCode !== this.props.currentLanguageCode) {
      this.props.closeOpenedPanels();
      if (!isQuickEditExperimentOpen) {
        this.props.deselectAll();
      }

      if (this.props.isPreviewMode) {
        this.props.setCurrentLanguage(lang.languageCode);
      } else {
        this.props.startLanguageChange(lang.languageCode);
      }

      const is_primary =
        lang.languageCode === this.props.originalLanguage.languageCode;

      this.props.sendBI(
        coreBi.events.multilingual.top_bar_drop_panel_choose_lang,
        {
          lang: lang.languageCode,
          origin: this.props.biOrigin,
          hosting: this.props.biHosting,
          is_visible: is_primary ? null : lang.status === 'Active',
          is_primary,
        },
        this.props.siteId,
      );
    }
  }

  updateLanguageStatus(lang: LanguageDefinition, published: LanguageStatus) {
    const biProps = {
      lang: lang.languageCode,
      origin: 'editor_language_drop_panel',
    };

    if (published) {
      this.props.sendBI(
        coreBi.events.multilingual.top_bar_drop_panel_publish_lang,
        biProps,
        this.props.siteId,
      );

      this.props
        .updateLanguageStatus(lang.code, LANGUAGE_STATUSES.ACTIVE)
        .then(() => {
          if (!this.props.didShowPublishSuccessPanel) {
            this.props.openPublishSuccessPanel(lang.name);
          }
        });
    } else if (!this.props.didShowUnpublishPanel) {
      this.props.openUnpublishPanel(lang);
    } else {
      this.props.sendBI(
        coreBi.events.multilingual.top_bar_drop_panel_unpublish_lang,
        biProps,
        this.props.siteId,
      );
      this.props.updateLanguageStatus(lang.code, LANGUAGE_STATUSES.INACTIVE);
    }
  }

  getLanguageList() {
    return [this.props.originalLanguage, ...this.props.translationLanguages];
  }

  getFlagUrl(lang: AnyFixMe) {
    return coreMultilingual.utils.getFlagIconUrl(
      util.serviceTopology.scriptsLocationMap['linguist-flags'],
      coreMultilingual.flagCountryIconType.rounded,
      lang.countryCode,
    );
  }

  getLanguageName(lang: AnyFixMe) {
    return translate(`locale-dataset.languages.${lang.languageCode}`);
  }

  isHidden(lang: AnyFixMe) {
    return lang.status !== LANGUAGE_STATUSES.ACTIVE;
  }

  isSelected(lang: AnyFixMe) {
    return lang.languageCode === this.props.currentLanguageCode;
  }

  isPrimaryLanguage(lang: AnyFixMe) {
    return lang.languageCode === this.props.originalLanguage.languageCode;
  }

  onManagePanelButtonClick = () => {
    this.props.openPanel();

    const source =
      BIOriginToSourceMap[
        this.props.biOrigin as keyof typeof BIOriginToSourceMap
      ] || 'manage_languages_top_bar_drop_menu';
    this.props.sendBI(
      coreBi.events.multilingual.manage_languages,
      {
        source,
        hosting: this.props.biHosting,
      },
      this.props.siteId,
    );
  };

  registerKeyboardShortcuts() {
    const self = this;
    const currentLanguageSwitchContext = {
      esc() {
        self.props.closeHandle();
      },
    };
    util.keyboardShortcuts.registerContext(
      keyboardContextKey,
      currentLanguageSwitchContext,
    );
  }

  getLanguageSubText(lang: AnyFixMe) {
    return lang.code.toUpperCase();
  }

  makeLanguageRow = (lang: AnyFixMe, index: number) => {
    const languageRowProps: Omit<
      LanguageRowProps,
      'isPreview' | 'languageCode' | 'allowToggle'
    > = {
      key: lang.name,
      index,
      isSelected: this.isSelected(lang),
      isPrimary: this.isPrimaryLanguage(lang),
      flagUrl: this.getFlagUrl(lang),
      languageName: this.getLanguageName(lang),
      published: !this.isHidden(lang),
      invisible: util.multilingual.languages.shouldLanguageBeInvisible(
        this.props.isPreviewMode,
        lang,
      ),
      onSelect: () => this.setCurrentLanguage(lang),
      onChange: (newStatus: LanguageStatus) =>
        this.updateLanguageStatus(lang, newStatus),
    };

    const isUseLocaleUIOpened = experiment.isOpen('specs.ml.UseLocaleUI');

    if (isUseLocaleUIOpened) {
      return (
        <LocaleRow
          locale={lang}
          currentLocaleId={this.props.currentLanguageCode}
          primaryLocaleId={this.props.originalLanguage.languageCode}
          index={index}
          onSelect={() => this.setCurrentLanguage(lang)}
          key={lang.id}
          flagUrl={this.getFlagUrl(lang)}
        />
      );
    }

    return <LanguageRowMid {...languageRowProps} />;
  };

  render() {
    return (
      <div
        key="MLTopBarDropDown"
        className="languages-panel-container languages-panel-container_se-madefor-font"
        data-aid="my-site-languages-panel"
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
      >
        <PanelHeader
          className="light"
          onHelp={this.props.openHelpCenter}
          noCloseBtn
        >
          <span>{translate('SiteLanguages_Panel_Title')}</span>
        </PanelHeader>
        <Divider long />

        <div className="languages-panel-languages-container">
          {this.getLanguageList().map(this.makeLanguageRow)}
        </div>

        <div
          key="MLTopBarManageLangs"
          className="manage-panel-button manage-panel-button-small"
        >
          <Composites.ActionSetVertical>
            <TextButton onClick={this.onManagePanelButtonClick} size="small">
              LangMenu_Manager_Button_Manage_Lang
            </TextButton>
          </Composites.ActionSetVertical>
        </div>
      </div>
    );
  }
}

const ConnectedLanguagePanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapper.mapStateToProps,
  mapper.mapDispatchToProps,
)(LanguagePanel);

ConnectedLanguagePanel.pure = LanguagePanel;

export default ConnectedLanguagePanel;

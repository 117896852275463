import addPanelDataConsts from '#packages/addPanelDataConsts';
import { languages } from '#packages/util';
import superAppsConstants from '../../superApps/superAppsConstants';
import { addCompOnClick, addCompOnDrop } from '../addPanelAddCompService';

const WIX_SOUNDCLOUD_APP_DEF_ID = superAppsConstants.WIX_SOUNDCLOUD.APP_DEF_ID;
const WIX_SOUNDCLOUD_WIDGET_ID = superAppsConstants.WIX_SOUNDCLOUD.WIDGET;
const SECTION_INDEX_IN_GROUP_MEDIA = 2;
const SECTION_INDEX_IN_GROUP_EMBEDS = 0;

const registerSection = function (editorAPI: AnyFixMe) {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.MEDIA,
    1,
    SECTION_INDEX_IN_GROUP_MEDIA,
    () => getSection(addPanelDataConsts.CATEGORIES_ID.MEDIA),
  );
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.EMBEDS,
    2,
    SECTION_INDEX_IN_GROUP_EMBEDS,
    () => getSection(addPanelDataConsts.CATEGORIES_ID.EMBEDS),
  );
};

const getBiOptions = (category: string) => ({
  origin: 'add_panel',
  sectionTitle: 'add_section_label_soundcloud',
  category,
});

const addApp =
  (biCategory: string) =>
  (
    compStructure: AnyFixMe,
    sectionTitle: AnyFixMe,
    tags: AnyFixMe,
    id: AnyFixMe,
    editorAPI: AnyFixMe,
    addOptions: AnyFixMe,
  ) =>
    addCompOnClick(
      editorAPI,
      WIX_SOUNDCLOUD_APP_DEF_ID,
      WIX_SOUNDCLOUD_WIDGET_ID,
      compStructure,
      tags,
      id,
      getBiOptions(biCategory),
      addOptions,
    );

const onDrop =
  (biCategory: string) =>
  (
    layoutParams: AnyFixMe,
    compPreset: AnyFixMe,
    parentComponent: AnyFixMe,
    editorAPI: AnyFixMe,
  ) =>
    addCompOnDrop(
      editorAPI,
      WIX_SOUNDCLOUD_APP_DEF_ID,
      WIX_SOUNDCLOUD_WIDGET_ID,
      layoutParams,
      compPreset,
      parentComponent,
      () => {},
      getBiOptions(biCategory),
    );

function getSectionImage() {
  const lang = languages.getLanguageCode();
  return `addPanelData/sections/soundCloudAudioPlayerSection_${lang}/soundCloudAudioPlayerSection_${lang}.png`;
}

function getSectionItems() {
  return [
    {
      id: 'soundcloud_preset_1',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        layout: {
          width: 482,
          height: 450,
          x: 241,
          y: -38,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        style: 'tpaw0',
        data: {
          type: 'TPAWidget',
          applicationId: 'applicationId',
          widgetId: WIX_SOUNDCLOUD_WIDGET_ID,
        },
        mobileStructure: {
          layout: {
            width: 280,
            height: 261,
            x: 20,
            y: 201,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          isCustomPreset: true,
        },
      },
      preset: {
        rect: { width: 324, height: 110, x: 0, y: 0 },
        label: '',
      },
    },
  ];
}

const getSection = function (biCategory: string) {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_soundcloud',
    subNavigationTitle: 'add_section_label_soundcloud',
    presetTitle: 'add_section_label_soundcloud',
    sectionName: 'soundCloudSection',
    tooltipTitle: 'add_section_label_soundcloud',
    subNavigationHide: false,
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      iconEnabledForComps: {},
    },
    props: {
      imageHover: null as any,
      onClick: addApp(biCategory),
      onDrop: onDrop(biCategory),
      image: getSectionImage(),
      items: getSectionItems(),
    },
    help: {
      hide: false,
      text: 'add_section_info_text_soundcloud',
    },
  };
};

export { registerSection };

// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import React from 'react';
import * as panels from '#packages/panels';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  RadioButtonsWithIllustration,
} from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';

const panelsActions = stateManagement.panels.actions;
const { MINOR, MAJOR } = constants.APP_STUDIO.BUILD_VERSION_TYPES;

// eslint-disable-next-line react/prefer-es6-class
const appBuilderUpdateTypePanel = createReactClass({
  displayName: 'appBuilderUpdateTypePanel',
  propTypes: {
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
  },

  getInitialState() {
    return {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/to-upper
      updateType: _.toUpper(MINOR),
    };
  },

  onUpdate() {
    this.props.onClose();
    this.props.onUpdate(this.state.updateType);
  },

  onCancel() {
    this.props.onClose();
    this.props.onCancel();
  },

  openUpdateHelpCenter() {
    this.props.openHelpCenter('be742601-7900-4fa3-9825-c74972eab4aa', null);
  },

  onRadioButtonClick(updateType) {
    this.setState({
      updateType,
    });
  },

  getTitleAndTextOptions() {
    return [
      {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/to-upper
        value: _.toUpper(MINOR),
        title: 'AppStudio_Publish_UpdateType_Type1_Heading',
        text: 'AppStudio_Publish_UpdateType_Type1_Text',
        shouldTranslate: true,
      },
      {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/to-upper
        value: _.toUpper(MAJOR),
        title: 'AppStudio_Publish_UpdateType_Type2_Heading',
        text: 'AppStudio_Publish_UpdateType_Type2_Text',
        shouldTranslate: true,
      },
    ];
  },

  render() {
    return (
      <panels.frames.FocusPanelFrame
        shouldHideHeader={true}
        panelName="platform.panels.appBuilderUpdateTypePanel"
      >
        <Composites.PopupMediumSize>
          <Composites.BigPanelHeader>
            <PanelHeaderCenteredText
              title="AppStudio_Publish_UpdateType_Title"
              subtitle="AppStudio_Publish_UpdateType_Subtitle"
              titleType="T16"
              shouldTranslate={true}
              onHelp={this.openUpdateHelpCenter}
              onClose={this.onCancel}
              className="dark"
            />
          </Composites.BigPanelHeader>

          <Composites.PanelContent className="update-panel-content">
            <Composites.RadioButtonsWithIllustration>
              <RadioButtonsWithIllustration
                value={this.state.updateType}
                onChange={this.onRadioButtonClick}
                options={this.getTitleAndTextOptions()}
              />
            </Composites.RadioButtonsWithIllustration>
          </Composites.PanelContent>

          <Composites.ActionSetHorizontalSpaced>
            <Button onClick={this.openUpdateHelpCenter} className="btn-text">
              {translate('AppStudio_LeftTree_Components_Update_Help_Link')}
            </Button>
            <Button onClick={this.onUpdate}>
              <span>{translate('AppStudio_Update_Button')}</span>
            </Button>
          </Composites.ActionSetHorizontalSpaced>
        </Composites.PopupMediumSize>
      </panels.frames.FocusPanelFrame>
    );
  },
});

const mapDispatchToProps = (dispatch, { panelName }) => ({
  onClose: () => dispatch(panelsActions.closePanelByName(panelName)),
  openHelpCenter: (helpId, props) =>
    dispatch(stateManagement.panels.actions.openHelpCenter(helpId, props)),
});

export default util.hoc.connect(
  util.hoc.STORES.STATE_AND_DS,
  _.noop,
  mapDispatchToProps,
)(appBuilderUpdateTypePanel);

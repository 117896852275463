import React, { useEffect, useMemo, useState } from 'react';
import { InputLabel } from './InputLabel';
import { ToggleSwitch, Text, Button, Badge } from '@wix/wix-base-ui';
import { AddSmall } from '@wix/wix-ui-icons-common';
import { translate } from '#packages/i18n';
import { PERMISSIONS_TAB_HOOKS } from '../../../../../utils/dataHooks';
import styles from './permissionSection.scss';

import type { Group } from '../../../../../utils/groupsApi';

export interface PermissionSectionProps {
  title: string;
  subtitle: string;
  emptyText?: string;
  createText?: string;
  connectText?: string;
  disabled: boolean;
  hasAvailableGroups?: boolean;
  canCreateNew?: boolean;
  connectedGroups?: Required<Group>[];
  onCreate?: () => void;
  onConnect?: () => void;
  disconnectGroups?: (groups: Required<Group>[]) => Promise<void>;
  dataHook?: string;
}

export const PermissionSection: React.FC<PermissionSectionProps> = ({
  title,
  subtitle,
  emptyText = '',
  createText = '',
  connectText = '',
  disabled,
  hasAvailableGroups = false,
  canCreateNew = false,
  connectedGroups = [],
  onCreate = () => {},
  onConnect = () => {},
  disconnectGroups = async () => {},
  dataHook = PERMISSIONS_TAB_HOOKS.PERMISSIONS_SECTION_CONTAINER,
}) => {
  const [isToggleOn, setIsToggleOn] = useState(connectedGroups.length > 0);
  const hasGroupsConnected = useMemo(
    () => connectedGroups.length > 0,
    [connectedGroups],
  );

  const shouldShowContent = isToggleOn && !disabled;

  useEffect(() => {
    if (disabled && !hasGroupsConnected) {
      setIsToggleOn(false);
    }

    if (hasGroupsConnected) {
      setIsToggleOn(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled, hasGroupsConnected]);

  const handleToggleChange = async () => {
    if (isToggleOn) {
      await disconnectGroups(connectedGroups);
    }

    setIsToggleOn(!isToggleOn);
  };

  const renderCTA = () => {
    if (hasAvailableGroups) {
      return (
        <Button
          dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_SECTION_CONNECT_BUTTON}
          className="btn-md btn-inverted"
          onClick={onConnect}
        >
          {translate(connectText)}
        </Button>
      );
    }

    return (
      <Button
        dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_SECTION_CREATE_BUTTON}
        className="btn-md btn-inverted"
        prefixIcon={<AddSmall />}
        onClick={onCreate}
        disabled={!canCreateNew}
      >
        {translate(createText)}
      </Button>
    );
  };

  const renderContent = () => {
    if (hasGroupsConnected) {
      return (
        <div
          data-hook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_SECTION_CONNECTED_GROUPS}
          className={styles.groupsList}
        >
          {connectedGroups.map((group) => (
            <Badge
              dataHook={
                PERMISSIONS_TAB_HOOKS.PERMISSIONS_SECTION_CONNECTED_GROUPS_TAG
              }
              shouldTranslate={false}
              skin="standard"
              value={group.title}
              key={group.id}
            />
          ))}
        </div>
      );
    }

    return (
      <Text
        dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_SECTION_EMPTY_TEXT}
        skin="placeholder"
        size="small"
        weight="thin"
      >
        {emptyText}
      </Text>
    );
  };

  return (
    <div data-hook={dataHook} className={styles.container}>
      <div className={styles.toggleSwitch}>
        <ToggleSwitch
          dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_SECTION_TOGGLE}
          size={'MEDIUM'}
          value={isToggleOn}
          onChange={handleToggleChange}
          disabled={disabled}
        />
        <InputLabel gap="small" title={title} subtitle={subtitle} />
      </div>
      {shouldShowContent && (
        <>
          <div className={styles.content}>{renderContent()}</div>
          <div className={styles.content}>{renderCTA()}</div>
        </>
      )}
    </div>
  );
};

import { fedopsLogger, isMainMenuFlowEnabled } from '#packages/util';
import { translate } from '#packages/i18n';
import { ErrorReporter } from '@wix/editor-error-reporter';

import { createMenuApi, type CreateMenuData } from '../../API/menuAPI';
import {
  FALLBACK_MENU_TYPE,
  MAIN_MENU_TYPE,
  PAGES_MENU_ID,
  FALLBACK_PAGES_MENU_NAME,
} from '../../constants';
import {
  getDesktopMenuItems,
  getMobileMenuItems,
  transformPagesPanelStructure,
} from './structure';
import { TRANSLATIONS_MAP } from '../../panels/MenuSetsPanel/utils/translations';
import { cleanId } from '../../utils/utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, MenuData } from 'types/documentServices';
import { menuItemTree } from '#packages/baseUI';

const {
  INTERACTIONS: { CUSTOM_MENUS_SETUP },
} = fedopsLogger;

export const createMenuSetFromPagesPanelData = (
  editorAPI: EditorAPI,
  options: Partial<MenuData> = {},
  isMobile: boolean = false,
): string => {
  fedopsLogger.interactionStarted(
    CUSTOM_MENUS_SETUP.CREATE_MENU_SET_FROM_PAGES_PANEL,
  );

  const menuAPI = createMenuApi(editorAPI);

  const pagesMenu = menuAPI.getMenu(PAGES_MENU_ID);
  const pagesMenuItems =
    editorAPI.menus.getExtendedMenuItemsTree(pagesMenu).items;

  const newMenuItems = isMobile
    ? getMobileMenuItems(pagesMenuItems)
    : getDesktopMenuItems(pagesMenuItems);

  const name = isMobile
    ? translate(TRANSLATIONS_MAP.ITEM.migratedMobileMenuTitle)
    : translate(TRANSLATIONS_MAP.ITEM.migratedMenuTitle);

  const menuId = menuAPI.createMenu({
    items: newMenuItems as CreateMenuData['items'],
    name,
    ...options,
  });

  fedopsLogger.interactionEnded(
    CUSTOM_MENUS_SETUP.CREATE_MENU_SET_FROM_PAGES_PANEL,
  );

  const isMultilingualEnabled =
    editorAPI.dsRead.language.multilingual.isEnabled();

  if (isMultilingualEnabled) {
    // here we should wait until actual menu with new Id will be presented at ds
    editorAPI.dsActions.waitForChangesApplied(() =>
      applyMultilingualFromPagesMenu(editorAPI, menuId, isMobile),
    );
  }

  return menuId;
};

export const filterMenus = (
  editorAPI: EditorAPI,
  refs: CompRef[],
  menuId: string = PAGES_MENU_ID,
) =>
  refs.filter((ref) => {
    const { menuRef } = editorAPI.dsRead.components.data.get(ref);
    // in case of old mobile menu there is no menuRef connected for pages menu set
    return menuRef ? cleanId(menuRef) === menuId : true;
  });

export const getMenuComponentsToReconnect = (
  editorAPI: EditorAPI,
  menuId = PAGES_MENU_ID,
): CompRef[] => {
  const menuAPI = createMenuApi(editorAPI);

  const mobileComponents = menuAPI.getMobileMenuComponents();
  const mobileComponentsToReconnect = filterMenus(
    editorAPI,
    mobileComponents,
    menuId,
  );
  const desktopComponents = menuAPI.getDesktopMenuComponentsByMenuId(menuId);

  return [...mobileComponentsToReconnect, ...desktopComponents];
};

export const normalizeMenuItemsVisibility = (
  editorAPI: EditorAPI,
  menu: MenuData,
) => {
  const menuAPI = createMenuApi(editorAPI);
  // @ts-expect-error
  const flattenedTree = menuItemTree.flattenTree(menu.items);

  const itemsToChange = flattenedTree.filter(
    ({ item }) => item.isVisible !== item.isVisibleMobile,
  );

  if (itemsToChange.length === 0) {
    return;
  }

  fedopsLogger.interactionStarted(
    CUSTOM_MENUS_SETUP.NORMALIZE_ITEMS_VISIBILITY,
  );

  itemsToChange.forEach(({ item }) => {
    menuAPI.updateItem(menu.id, item.id, {
      isVisible: true,
      isVisibleMobile: true,
    });
  });

  fedopsLogger.interactionEnded(CUSTOM_MENUS_SETUP.NORMALIZE_ITEMS_VISIBILITY);
};

export const fixMenusConnectedToPages = async (
  editorAPI: EditorAPI,
  menuToAttach: MenuData,
): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);
  const componentsToReconnect = getMenuComponentsToReconnect(
    editorAPI,
    menuToAttach.id,
  );

  // in case previous migration failed to reconnect all components
  if (componentsToReconnect.length > 0) {
    ErrorReporter.captureException(
      new Error('Not connected components after migration'),
      {
        tags: {
          'custom-menus': 'migration',
        },
      },
    );

    fedopsLogger.interactionStarted(
      CUSTOM_MENUS_SETUP.RECONNECT_MENU_COMPONENT_TO_CUSTOM_MENU,
    );
    componentsToReconnect.map((ref) => menuAPI.connect(menuToAttach.id, ref));

    await new Promise<void>((resolve) =>
      editorAPI.waitForChangesApplied(resolve),
    );
    fedopsLogger.interactionEnded(
      CUSTOM_MENUS_SETUP.RECONNECT_MENU_COMPONENT_TO_CUSTOM_MENU,
    );
  }
};

export const makeMainMenu = async (editorAPI: EditorAPI): Promise<void> => {
  fedopsLogger.interactionStarted(CUSTOM_MENUS_SETUP.CREATE_MAIN_MENU);
  const menuAPI = createMenuApi(editorAPI);
  const customMenus = menuAPI.getCustomMenus();
  const [firstCustomMenu] = customMenus;

  menuAPI.updateMenu(firstCustomMenu.id, {
    syncWithPages: true,
    menuType: MAIN_MENU_TYPE,
  });

  await new Promise<void>((resolve) =>
    editorAPI.waitForChangesApplied(resolve),
  );

  fedopsLogger.interactionEnded(CUSTOM_MENUS_SETUP.CREATE_MAIN_MENU);
};

export const fixEmptyPagesMenu = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);
  const [firstCustomMenu] = menuAPI.getCustomMenus();
  const newPagesItems = transformPagesPanelStructure(firstCustomMenu.items);

  menuAPI.replaceMenuItems(PAGES_MENU_ID, newPagesItems);

  await new Promise<void>((resolve) =>
    editorAPI.waitForChangesApplied(resolve),
  );
};

const applyMultilingualFromPagesMenu = (
  editorAPI: EditorAPI,
  menuIdToUpdate: string,
  isMobile: boolean,
) => {
  fedopsLogger.interactionStarted(
    CUSTOM_MENUS_SETUP.APPLY_MULTILINGUAL_TO_NEW_MENU,
  );

  const languageCodes = editorAPI.dsRead.language
    .get()
    .map((language) => language.code);

  languageCodes.forEach(async (languageCode) => {
    const pagesMenuForLanguage = editorAPI.multilingual.menu.get(
      languageCode,
      PAGES_MENU_ID,
    );

    const pagesMenuItemsForLanguage =
      editorAPI.menus.getExtendedMenuItemsTree(pagesMenuForLanguage).items;

    const newMenuItemsForLanguage = isMobile
      ? getMobileMenuItems(pagesMenuItemsForLanguage)
      : getDesktopMenuItems(pagesMenuItemsForLanguage);

    // TODO - should IItemsGetter exported from EditorAPI instead of defined "locally" santa-editor/packages/menu/setupCustomMenus/structure.ts
    editorAPI.multilingual.menu.update(languageCode, menuIdToUpdate, {
      items: newMenuItemsForLanguage,
    });

    await new Promise<void>((resolve) =>
      editorAPI.waitForChangesApplied(resolve),
    );

    fedopsLogger.interactionEnded(
      CUSTOM_MENUS_SETUP.APPLY_MULTILINGUAL_TO_NEW_MENU,
    );
  });
};

/**
 * @see https://jira.wixpress.com/browse/WEED-22170
 * */
export const fixMenusConnectedToFallback = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);
  const fallbackMenu = getFallbackMenu(editorAPI);

  if (fallbackMenu) {
    const componentsToReconnect = getMenuComponentsToReconnect(
      editorAPI,
      fallbackMenu.id,
    );

    if (componentsToReconnect.length > 0) {
      const menuToAttach = isMainMenuFlowEnabled()
        ? menuAPI.getMainMenu()
        : menuAPI.getCustomMenus()[0];

      componentsToReconnect.map((ref) => menuAPI.connect(menuToAttach.id, ref));

      await new Promise<void>((resolve) =>
        editorAPI.waitForChangesApplied(resolve),
      );
    }
  }
};

export const getFallbackMenu = (editorAPI: EditorAPI) =>
  editorAPI.menu.getByType(FALLBACK_MENU_TYPE)[0];

export const createFallbackPagesMenu = (editorAPI: AnyFixMe) => {
  const menuAPI = createMenuApi(editorAPI);
  const pages = menuAPI.getMenu(PAGES_MENU_ID);

  menuAPI.createMenu({
    items: pages.items,
    name: FALLBACK_PAGES_MENU_NAME,
    type: 'CustomMenu',
    menuType: FALLBACK_MENU_TYPE,
    // in case user adds or deletes pages
    syncWithPages: true,
  });
};

import _ from 'lodash';
import React from 'react';
import * as Symbols from '@wix/santa-editor-symbols';

import { cx } from '#packages/util';
import { tooltipManager } from '#packages/baseUI';
import constants from '#packages/constants';

import styles from './leftBarMenuButton.scss';
import MenuButtonLabel from '../menuButtonLabel/menuButtonLabel';
import type { ClickInitiator } from './types';

interface LeftBarMenuButtonProps {
  className: string;
  symbolName: string;
  label: string;
  panelName: string;
  panelProps?: Record<string, any>;
  isSelected: boolean;
  isHighlighted: boolean;
  isEnabled: boolean;
  onClick: (
    panelName: string,
    panelProps: any,
    initiator: ClickInitiator,
  ) => void;
  onHover: (element_name: string) => void;
  notificationCount: number;
  shouldTranslate?: boolean;
  automationId?: string;
  tooltips?: Array<AnyFixMe>;
}

class LeftBarMenuButton extends React.Component<LeftBarMenuButtonProps> {
  static defaultProps = {
    shouldTranslate: true,
  };

  state = {
    hovered: false,
  };

  componentDidMount() {
    this.props.tooltips?.forEach((tooltip) => {
      tooltipManager.createTooltip(false, {
        id: tooltip.id,
        targetNode: this.containerRef?.current,
        value: tooltip.value,
        width: tooltip.width,
        marginsFromElement: 10,
        alignment: constants.UI.TOOLTIP.ALIGNMENT.LEFT,
        closeTriggers: [constants.UI.TOOLTIP.TRIGGERS.OUTER_CLICK],
      });
    });
  }

  containerRef = React.createRef<HTMLDivElement>();

  handleOnClick = (initiator: ClickInitiator = 'button') => {
    if (!this.props.isEnabled) {
      return;
    }

    this.props.onClick(this.props.panelName, this.props.panelProps, initiator);
  };

  handleButtonMouseEnter = () => {
    this.props.onHover(this.props.symbolName);
  };

  handleMouseEnter = () => {
    this.setState({ hovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ hovered: false });
  };

  handleLabelClick = () => {
    if (this.props.isHighlighted) {
      this.handleOnClick();
    }
  };

  handleLabelMouseEnter = () => {
    if (this.props.isHighlighted) {
      this.handleMouseEnter();
    }
  };

  handleLabelMouseLeave = () => {
    if (this.props.isHighlighted) {
      this.handleMouseLeave();
    }
  };

  renderLabelElement = () => (
    <MenuButtonLabel
      text={this.props.label}
      className={cx(styles.labelWrapper, {
        [styles.interactive]: this.props.isHighlighted,
      })}
      shouldTranslate={this.props.shouldTranslate}
      onClick={this.handleLabelClick}
      onMouseEnter={this.handleLabelMouseEnter}
      onMouseLeave={this.handleLabelMouseLeave}
      automationId={`${this.props.automationId}-label`}
    />
  );

  render() {
    return (
      <div className={styles.wrapper} ref={this.containerRef}>
        <button
          onClick={_.debounce(() => this.handleOnClick('button'), 250)}
          onMouseEnter={this.handleButtonMouseEnter}
          className={cx({
            [styles.button]: true,
            [styles.selected]: this.props.isSelected,
            [styles.highlighted]: this.props.isHighlighted,
            [styles.hovered]: this.state.hovered,
            [this.props.className]: true,
          })}
          disabled={!this.props.isEnabled}
          data-hook="left-bar-item"
          data-automation-id={this.props.automationId}
        >
          <Symbols.symbol name={this.props.symbolName} className="icon" />

          {this.props.notificationCount > 0 ? (
            <span className={styles.notifications}>
              {this.props.notificationCount}
            </span>
          ) : null}
        </button>
        {this.renderLabelElement()}
      </div>
    );
  }
}

LeftBarMenuButton.defaultProps = {
  shouldTranslate: true,
};

export default LeftBarMenuButton;

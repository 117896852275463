// @ts-nocheck
import React from 'react';
import * as baseUI from '#packages/baseUI';

function close(editorAPI) {
  editorAPI.floatingBubble.hide();
}

function open(editorAPI) {
  const selectedCompLayout = editorAPI.components.layout.getRelativeToScreen(
    editorAPI.selection.getSelectedComponents()[0],
  );

  const innerTemplate = React.createElement(baseUI.popoverTemplates.quickTip, {
    symbol: 'idea-bulb',
    text: 'Mobile_Menu_Tip_Move_Elements_Text',
    closeAction: editorAPI.floatingBubble.hide,
  });

  const targetLayout = {
    height: 0,
    width: 0,
    top: selectedCompLayout.y + 50 || 0,
    left: selectedCompLayout.x + 500 || 0,
  };

  const propsForBubble = {
    noArrow: true,
    shake: false,
    behindPopUps: true,
    hideMethod: editorAPI.toolsControl.toggleHalfOpacityTools.bind(this, false),
    alignment: 'bottom',
  };

  editorAPI.floatingBubble.show(innerTemplate, targetLayout, propsForBubble, {
    shouldNotHideOnMouseLeaveTarget: true,
    countDisplayTimesOptions: {
      countBy: 'site',
      maxNum: 3,
      uniqueBubbleId: 'quickTipForRightClickMoveTo',
    },
    delay: 300,
  });
}

export { open, close };

import * as React from 'react';
import { connectWithScope } from '#packages/apilib';
import { useTranslation } from 'react-i18next';
import { SetBackground } from '@wix/wix-ui-icons-common/classic-editor';
import {
  mapDispatchToProps,
  mapStateToProps,
  type ImageToBackgroundProps,
} from './imageToBackgroundMapper';
import styles from './imageToBackground.scss';
import { Tooltip } from '@wix/wix-base-ui';
import { AUTOMATION_IDS } from './constants';
import { Scope } from '../scope';

const ImageToBackground: React.FC<ImageToBackgroundProps> = ({
  setImageToBackground,
  toggleBackgroundTargetHighlight,
}) => {
  const [t] = useTranslation();

  const clickHandler = () => {
    toggleBackgroundTargetHighlight(false);
    setImageToBackground();
  };

  return (
    <Tooltip
      content={t('fluid_set_background_button_label')}
      dataHook={AUTOMATION_IDS.TOOLTIP}
      shouldTranslate={false}
    >
      <div
        className={styles.wrapper}
        onClick={clickHandler}
        onMouseDown={(event) => {
          event.stopPropagation();
        }}
        onMouseEnter={() => toggleBackgroundTargetHighlight(true)}
        onMouseLeave={() => toggleBackgroundTargetHighlight(false)}
        data-hook={AUTOMATION_IDS.BUTTON}
      >
        <SetBackground className={styles.backgroundIcon} />
      </div>
    </Tooltip>
  );
};

export default connectWithScope(
  () => Scope,
  ImageToBackground,
  mapStateToProps,
  mapDispatchToProps,
);

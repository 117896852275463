import {
  EditorAPIKey,
  AccountApiKey,
  EditorCoreApiKey,
  EditorParamsApiKey,
  PanelManagerApiKey,
  SavePublishApiKey,
} from '#packages/apis';
import { WizardStore } from './wizardStore';
import { onWizardFinish, WizardApi } from './wizardApi';
import { WizardApiKey } from './publicApiKey';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';

export class Scope extends BaseEntryScope {
  store = this.declareStore(WizardStore);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  savePublishApi = this.useDependency(SavePublishApiKey);
  editorParamsApi = this.useDependency(EditorParamsApiKey);
  editorAPI = this.useDependency(EditorAPIKey);
  panelManagerApi = this.useDependency(PanelManagerApiKey);
  accountApi = this.useDependency(AccountApiKey);
}

export const WizardEntryPoint = createEntryPoint({
  name: 'EditorWizard',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(WizardApiKey, WizardApi);
  },
  async initialize(scope: Scope) {
    const { editorParamsApi, store, editorCoreAPI } = scope;
    if (editorParamsApi.siteGenerationWizard) {
      store.setWizardActive(true);
      editorCoreAPI.hooks.wizardFinish.promise.then(
        ({ wizardType, domainName, shouldRedirectToDashboard }) => {
          onWizardFinish(
            scope,
            wizardType,
            shouldRedirectToDashboard,
            domainName,
          );
        },
      );
    }
  },
});

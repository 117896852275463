// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import popUpCloseTextButtonSections from './componentsPresets/popUpCloseTextButtonSections';
import popUpCloseIconButtonSections from './componentsPresets/popUpCloseIconButtonSections';
import contactFormPresetSections from './componentsPresets/contactFormPresetSections';
import containerPresetSections from './componentsPresets/containerPresetSections';
import galleriesPresetSections from './componentsPresets/galleriesPresetSections';
import googleMapPresetSections from './componentsPresets/googleMapPresetSections';
import audioPlayerPresetSections from './componentsPresets/audioPlayerPresetSections';
import singleAudioPlayerPresetSections from './componentsPresets/singleAudioPlayerPresetSections';
import siteButtonPresetSections from './componentsPresets/siteButtonPresetSections';
import stylableButtonPresetSections from './componentsPresets/stylableButtonPresetSections';
import subscribeFormPresetSections from './componentsPresets/subscribeFormPresetSections';
import stripPresetSections from './componentsPresets/stripPresetSections';
import imageButtonPresetSections from './componentsPresets/imageButtonPresetSections';
import dropDownMenuPresetSections from './componentsPresets/dropDownMenuPresetSections';
import verticalMenuPresetSections from './componentsPresets/verticalMenuPresetSections';
import pagePresetSections from './componentsPresets/pagePresetSections';
import footerPresetSections from './componentsPresets/footerPresetSections';
import headerPresetSections from './componentsPresets/headerPresetSections';
import fiveGridLinePresetSections from './componentsPresets/fiveGridLinePresetSections';
import fullWidthLinePresetSections from './componentsPresets/fullWidthLinePresetSections';
import verticalLinePresetSections from './componentsPresets/verticalLinePresetSections';
import wPhotoPresetSections from './componentsPresets/wPhotoPresetSections';
import documentMediaPresetSections from './componentsPresets/documentMediaPresetSections';
import videoPresetSections from './componentsPresets/videoPresetSections';
import tinyMenuPresetSections from './componentsPresets/tinyMenuPresetSections';
import quickActionBarPresetSections from './componentsPresets/quickActionBarPresetSections';
import verticalAnchorsMenuPresetSections from './componentsPresets/verticalAnchorsMenuPresetSections';
import hoverBoxPresetSections from './componentsPresets/hoverBoxPresetSections';
import gridPresetSections from './componentsPresets/gridPresetSections';
import checkboxPresetSections from './componentsPresets/checkboxPresetSections';
import toggleSwitchPresetSection from './componentsPresets/toggleSwitchPresetSection';
import richTextBoxPresetSection from './componentsPresets/richTextBoxPresetSection';
import datePickerPresetSections from './componentsPresets/datePickerPresetSections';
import selectionPresetSections from './componentsPresets/selectionPresetSections';
import textInputPresetSections from './componentsPresets/textInputPresetSections';
import textAreaPresetSections from './componentsPresets/textAreaPresetSections';
import radioGroupPresetSections from './componentsPresets/radioGroupPresetSections';
import checkboxGroupPresetSections from './componentsPresets/checkboxGroupPresetSections';
import fileUploaderPresetSections from './componentsPresets/fileUploaderPresetSections';
import paginationPresetSections from './componentsPresets/paginationPresetSections';
import sliderPresetSections from './componentsPresets/sliderPresetSections';
import rangeSliderPresetSections from './componentsPresets/rangeSliderPresetSection';
import shareButtonsPresetSection from './componentsPresets/shareButtonsPresetSection';
import ratingsPresetSections from './componentsPresets/ratingsPresetSections';
import languageSelectorPresetSection from './componentsPresets/languageSelectorPresetSection';
import timePickerPresetSection from './componentsPresets/timePickerPresetSection';
import addressInputPresetSection from './componentsPresets/addressInputPresetSection';
import inlinePopupTogglePresetSections from './componentsPresets/inlinePopupTogglePresetSections';
import searchBoxPresetSections from './componentsPresets/searchBoxPresetSections';
import progressBarPresetSections from './componentsPresets/progressBarPresetSection';
import signatureInputPresetSections from './componentsPresets/signatureInputPresetSection';
import selectionTagsListSection from './componentsPresets/selectionTagsListSection';
import stylableHorizontalMenuPresetSection from './componentsPresets/stylableHorizontalMenuPresetSection';
import breadcrumbsPresetSection from './componentsPresets/breadcrumbsPresetSection';
import collapsibleTextPresetsSection from './componentsPresets/collapsibleTextPresetsSection';

const COMPONENTS_PRESET_SECTIONS = {
  'mobile.core.components.Page': pagePresetSections,
  'wysiwyg.viewer.components.HeaderContainer': headerPresetSections,
  'wysiwyg.viewer.components.FooterContainer': footerPresetSections,
  galleries: galleriesPresetSections,
  'mobile.core.components.Container': containerPresetSections,
  'wysiwyg.viewer.components.BgImageStrip': stripPresetSections,
  'wysiwyg.viewer.components.menus.DropDownMenu': dropDownMenuPresetSections,
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu':
    verticalMenuPresetSections,
  'wysiwyg.viewer.components.FiveGridLine': fiveGridLinePresetSections,
  fiveGridLineFullWidth: fullWidthLinePresetSections,
  'wysiwyg.viewer.components.VerticalLine': verticalLinePresetSections,
  'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer':
    singleAudioPlayerPresetSections,
  'wysiwyg.viewer.components.AudioPlayer': audioPlayerPresetSections,
  'wysiwyg.viewer.components.GoogleMap': googleMapPresetSections,
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm':
    subscribeFormPresetSections,
  'wysiwyg.viewer.components.ContactForm': contactFormPresetSections,
  'wysiwyg.viewer.components.DynamicContactForm': contactFormPresetSections,
  'wysiwyg.viewer.components.SiteButton': siteButtonPresetSections,
  'wysiwyg.viewer.components.WPhoto': wPhotoPresetSections,
  'wysiwyg.viewer.components.Video': videoPresetSections,
  'wysiwyg.common.components.imagebutton.viewer.ImageButton':
    imageButtonPresetSections,
  'wysiwyg.viewer.components.documentmedia.DocumentMedia':
    documentMediaPresetSections,
  'wysiwyg.viewer.components.mobile.TinyMenu': tinyMenuPresetSections,
  'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu':
    verticalAnchorsMenuPresetSections,
  'wysiwyg.viewer.components.PopupCloseTextButton':
    popUpCloseTextButtonSections,
  'wysiwyg.viewer.components.PopupCloseIconButton':
    popUpCloseIconButtonSections,
  'wysiwyg.viewer.components.inputs.Checkbox': checkboxPresetSections,
  'wixui.ToggleSwitch': toggleSwitchPresetSection,
  'wixui.RichTextBox': richTextBoxPresetSection,
  'wysiwyg.viewer.components.inputs.TextInput': textInputPresetSections,
  'wysiwyg.viewer.components.inputs.TextAreaInput': textAreaPresetSections,
  'wysiwyg.viewer.components.inputs.DatePicker': datePickerPresetSections,
  'wysiwyg.viewer.components.inputs.ComboBoxInput': selectionPresetSections,
  'wysiwyg.viewer.components.inputs.RadioGroup': radioGroupPresetSections,
  'wysiwyg.viewer.components.inputs.CheckboxGroup': checkboxGroupPresetSections,
  'wysiwyg.viewer.components.QuickActionBar': quickActionBarPresetSections,
  'wysiwyg.viewer.components.HoverBox': hoverBoxPresetSections,
  'wysiwyg.viewer.components.Grid': gridPresetSections,
  'wysiwyg.viewer.components.inputs.FileUploader': fileUploaderPresetSections,
  'wixui.FileUploaderNew': fileUploaderPresetSections,
  'wixui.Pagination': paginationPresetSections,
  'wixui.Slider': sliderPresetSections,
  'wixui.RatingsDisplay': ratingsPresetSections.create(
    'RatingsDisplay',
    'design_section_label_explore_ratings_read',
  ),
  'wixui.RatingsInput': ratingsPresetSections.create(
    'RatingsInput',
    'design_section_label_explore_ratings_write',
  ),
  'wysiwyg.viewer.components.LanguageSelector': languageSelectorPresetSection,
  'wixui.TimePicker': timePickerPresetSection,
  'wixui.AddressInput': addressInputPresetSection,
  'wixui.SearchBox': searchBoxPresetSections,
  'wysiwyg.viewer.components.InlinePopupToggle':
    inlinePopupTogglePresetSections,
  'wysiwyg.viewer.components.MenuToggle': inlinePopupTogglePresetSections,
  'wixui.ProgressBar': progressBarPresetSections,
  'wixui.SignatureInput': signatureInputPresetSections,
  'wixui.StylableButton': stylableButtonPresetSections,
  'wixui.SelectionTagsList': selectionTagsListSection,
  'wixui.StylableHorizontalMenu': stylableHorizontalMenuPresetSection,
  'wixui.Breadcrumbs': breadcrumbsPresetSection,
  'wixui.CollapsibleText': collapsibleTextPresetsSection,
  'wixui.RangeSlider': rangeSliderPresetSections,
  'wixui.ShareButtons': shareButtonsPresetSection,
};

function getPresetSectionsByCompType(
  compType,
  withAdditionalBehaviors = false,
) {
  const componentPresets = COMPONENTS_PRESET_SECTIONS[compType];
  const compPresetSections =
    componentPresets && componentPresets.getDesignSections();
  if (!compPresetSections) {
    return [];
  }

  const presetSections = _.cloneDeep(compPresetSections.sections);
  const sectionHeadersToShow = compPresetSections.titles;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(presetSections, function (presetSection, index) {
    if (!withAdditionalBehaviors) {
      presetSection.additionalBehaviours = {
        labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
        infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
        iconEnabledForComps: [],
        hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
        scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
      }; //TODO - remove this support and make it optional (currently empty values)
    }
    delete presetSection.teaser;

    if (sectionHeadersToShow?.[index]) {
      presetSection.showSectionHeader = true;
      presetSection.title = sectionHeadersToShow[index];
    } else {
      presetSection.showSectionHeader = false;
    }

    presetSection.help = {
      hide: true,
    };
  });

  return presetSections;
}

export { getPresetSectionsByCompType };

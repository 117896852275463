// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import * as bi from '../bi/bi';
import { translate } from '#packages/i18n';

import React from 'react';
import * as panels from '#packages/panels';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'appMarketPermissionsModal',
  mixins: [core.mixins.editorAPIMixin],
  onClose() {
    const params = {
      closing_method: 'X',
      app_id: this.props.appDefinitionId,
    };
    this.getEditorAPI().bi.event(bi.events.PERMISSION_MODAL_CLOSED, params);
    return true;
  },
  getTitle() {
    return translate('app_market_permissions_message_title');
  },
  render() {
    return (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        title={this.getTitle()}
        onClose={this.onClose}
        shouldHideHelp={true}
      >
        <div className="app-market-permissions-modal-container">
          <iframe
            src={this.props.iframeUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            className="app-market-permissions-modal"
          />
        </div>
      </panels.frames.FocusPanelFrame>
    );
  },
});

// @ts-nocheck
import _ from 'lodash';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const IMAGE_DATA_KEYS = [
  'uri',
  'width',
  'height',
  'title',
  'originalImageDataRef',
  'type',
];

function isBgImageDefined(editorAPI, compRef) {
  const compData = editorAPI.components.data.get(compRef);
  return compData?.uri;
}

function openMediaManager(editorAPI, compRef, isImageDefined) {
  let translation;

  if (isImageDefined) {
    translation = {
      submitButton: translate('Image_Media_Change_Image'),
    };
  }

  editorAPI.mediaServices.mediaManager.open(
    editorAPI.mediaServices.mediaManager.categories.IMAGE,
    {
      multiSelect: false,
      translation,
      callback(payload) {
        if (payload) {
          const newImageData = _.pick(payload[0], IMAGE_DATA_KEYS);
          newImageData.uri = payload[0].fileName;
          newImageData.originalImageDataRef = null;
          newImageData.type = 'Image';

          editorAPI.components.data.update(compRef, newImageData, true);

          editorAPI.history.add('Change Image');
        }
        editorAPI.mediaServices.setFocusToEditor();
      },
    },
  );
}

export default {
  mainActions(editorAPI, compRef) {
    const isImageDefined = isBgImageDefined(editorAPI, compRef);

    return [
      {
        label: isImageDefined
          ? 'gfpp_mainaction_applied_strip'
          : 'gfpp_mainaction_strip',
        isApplied: isImageDefined,
        onClick() {
          openMediaManager(editorAPI, compRef, isImageDefined);
        },
      },
    ];
  },
  enabledActions(editorAPI, compRef) {
    let actions = [
      ACTIONS.SETTINGS,
      ACTIONS.DESIGN,
      ACTIONS.ANIMATION,
      ACTIONS.HELP,
    ];

    if (isBgImageDefined(editorAPI, compRef)) {
      actions = actions.concat(ACTIONS.LAYOUT);
    }

    return actions;
  },
  mobileEnabledActions(editorAPI, compRef) {
    const actions = [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION];
    if (isBgImageDefined(editorAPI, compRef)) {
      actions.push(ACTIONS.LAYOUT);
    }

    return actions;
  },
  presetActions: {
    help: {
      helpId: '4da90962-8952-46ac-9e9e-dd7403f2fa61',
    },
  },
};

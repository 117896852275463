import type { EditorAPI } from '@/editorAPI';
import {
  EditorPlatformHostIntegrationAPI,
  type PluginInstallationParams,
} from '@wix/editor-platform-host-integration-apis';
import type { ComponentRef } from '@wix/platform-editor-sdk';
import type { WidgetPointer } from '@wix/editor-platform-host-integration/ui';
import type { CompRef, AppDataComponent } from '@wix/document-services-types';
import * as appMarketTabService from '../appMarket/services/appMarketTabService';
import navigateAndOpenAppService from './navigateAndOpenAppService';

const addWidgetPlugin = async (
  editorAPI: EditorAPI,
  slotComponentRef: ComponentRef,
  widgetRef: WidgetPointer,
): Promise<CompRef> => {
  const slotInfo =
    await editorAPI.platform.widgetPlugins.getWidgetSlot(slotComponentRef);

  if (slotInfo?.pluginInfo !== undefined) {
    await editorAPI.platform.widgetPlugins.uninstallWidgetPlugin(
      slotComponentRef,
    );
  }

  return await editorAPI.platform.widgetPlugins.installWidgetPlugin(
    slotComponentRef,
    widgetRef,
    { origin: 'appMarket' },
  );
};

function populate(
  editorAPI: EditorAPI,
  pluginInstallationParams: PluginInstallationParams,
): Promise<CompRef> {
  return addWidgetPlugin(
    editorAPI,
    pluginInstallationParams.slotComponentRef,
    pluginInstallationParams.widgetRef,
  );
}

const autoInstall = async (
  editorAPI: EditorAPI,
  pluginInstallationParams: PluginInstallationParams,
): Promise<CompRef | null> => {
  const pluginRef = await populate(editorAPI, pluginInstallationParams);

  appMarketTabService.closeAppMarketTab(
    editorAPI.panelManager.closePanelByName,
  );

  editorAPI.panelManager.closeAllPanels();

  return pluginRef;
};

const autoInstallWithNavigation = async (
  editorAPI: EditorAPI,
  pluginInstallationParams: PluginInstallationParams,
): Promise<CompRef | null> => {
  appMarketTabService.closeAppMarketTab(
    editorAPI.panelManager.closePanelByName,
  );

  editorAPI.panelManager.closeAllPanels();

  await navigateAndOpenAppService.navigateToPageAsync(
    editorAPI,
    pluginInstallationParams.pageId,
  );

  await editorAPI.waitForChangesAppliedAsync();

  const pluginRef = await populate(editorAPI, pluginInstallationParams);

  await editorAPI.waitForChangesAppliedAsync();

  await navigateAndOpenAppService.scrollToComponentAsync(
    editorAPI,
    pluginRef.id,
  );

  editorAPI.selection.selectComponentByCompRef(pluginRef);

  return pluginRef;
};

const autoInstallAppWidgetPlugin = async (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  components: AppDataComponent[],
  shouldNavigate: boolean,
): Promise<CompRef | null> => {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const pluginInstallationParams =
    await platformAPI.slots.getPluginInstallationParams(
      appDefinitionId,
      components,
    );

  if (!pluginInstallationParams) return null;

  return shouldNavigate
    ? autoInstallWithNavigation(editorAPI, pluginInstallationParams)
    : autoInstall(editorAPI, pluginInstallationParams);
};

export { autoInstallAppWidgetPlugin };

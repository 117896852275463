import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import * as util from '#packages/util';

const { MEDIA_MANAGER_MEDIA_TYPES } = constants;

const SHUTTERSTOCK_PAYMENT_API = 'https://pix.wix.com';
const ITEMS_TO_LOAD_COUNT = 6;
const RECOMMENDED_ITEMS_TO_LOAD_COUNT = 27;
const RECOMMENDED_SECTION_INITIAL_VISIBLE_ITEMS_COUNT = 9;
const WIX_MEDIA_ITEMS_FULL_INFO_LOAD_MAX_RETRY_COUNT = 3;
const MEDIA_MANAGER_OPEN_SOURCE_BASE = 'media_editor_panel';
const MEDIA_MANAGER_PANEL_TITLE = util.workspace.isNewWorkspaceEnabled()
  ? 'Media_Panel_Header_Title'
  : 'Media_Panel_Header_New_Title';
const MEDIA_PANEL_NEVER_SHOW_RECOMMENDED_CARD_KEY =
  'mediaPanel_sitePrefs_neverShowRecommendedCard';

const BLACK_ARROW_CONFIG = {
  messageUrl: 'https://ws.wixmp.com/message',
  socketUrl: 'wss://ws.wixmp.com/ws',
  tokenUrl: '//files.wix.com/users/get-batoken',
};

const MEDIA_PRODUCER = {
  WIX: 'wixstock',
  SHUTTERSTOCK: 'shutterstock',
};

const SOCKET_STATUSES = {
  INIT: 'INIT',
  GOT_TOKEN: 'GOT_TOKEN',
  GET_TOKEN_FAILED: 'GET_TOKEN_FAILED',
  OPEN_STARTED: 'OPEN_STARTED',
  REOPEN_STARTED: 'REOPEN_STARTED',
  OPENED: 'OPENED',
  CLOSED: 'CLOSED',
};

const TRANSCODING_STATUSES = {
  IN_DOWNLOAD_QUEUE: 'IN-DOWNLOAD-QUEUE',
  DOWNLOADING: 'DOWNLOADING',
  UPLOADING: 'UPLOADING',
  IN_STORAGE: 'IN-STORAGE',
  IN_QUEUE: 'IN-QUEUE',
  PENDING: 'PENDING',
  IN_PROGRESS: 'INPROGRESS',
  PREVIEW_READY: 'PREVIEW_READY',
  READY: 'READY',
  FAILED: 'FAILED',
  NONE: 'NONE',
};

const MEDIA_MANAGER_PANEL_SECTION_ID = {
  ADD_MEDIA: 'add_media',
  CREATE_MEDIA: 'create_media',
  RECOMMENDED_MEDIA_CARD: 'recommended_media_card',
  RECOMMENDED_MEDIA: 'recommended_media',
  SITE_FILES: 'recently_added',
  WIX_IMAGES: 'free_wix_images',
  WIX_ILLUSTRATIONS: 'free_wix_illustrations',
  WIX_VECTOR_ART: 'free_wix_vector_art',
  WIX_SOCIAL_ICONS: 'free_wix_social_icons',
  WIX_VIDEOS: 'free_wix_videos',
  SHUTTERSTOCK_IMAGES: 'shutterstock_images',
  SHUTTERSTOCK_VIDEOS: 'shutterstock_videos',
};

const OPEN_BY_URL_SOURCE = {
  SITE_FILES: 'sitefiles',
  WIX_IMAGES: 'wiximages',
  WIX_VIDEOS: 'wixvideos',
  WIX_EXCLUSIVES_VIDEOS: 'wixvideosexclusives',
  SHUTTERSTOCK_IMAGES: 'shutterstockimages',
  SHUTTERSTOCK_VIDEOS: 'shutterstockvideos',
  UNSPLASH: 'unsplash',
};

const PANEL_BI_SOURCE_SUFFIX = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]: 'site_files',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]: 'free_images',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS]: 'illustrations',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART]: 'vector_art',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS]: 'social_icons',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]: 'free_videos',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]: 'shutterstock_images',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]: 'shutterstock_videos',
};

const SECTIONS_TITLES_MAP = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA]:
    'Media_Panel_Add_Media_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA]:
    'Media_Panel_Edit_ImagesVideos_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]:
    'Media_Panel_Recently_Added_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA]:
    'Media_Panel_Recommended_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]:
    'Media_Panel_Wix_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]:
    'Media_Panel_Wix_Videos_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]:
    'Media_Panel_Shutterstock_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]:
    'Media_Panel_Shutterstock_Videos_Section_Label',
};

const SECTIONS_MEDIA_FROM_WIX_TITLES_MAP = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA]:
    'Media_Panel_Add_Media_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA]:
    'Media_Panel_Edit_ImagesVideos_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]:
    'Media_Panel_Recently_Added_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA]:
    'Media_Panel_Recommended_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]:
    'Media_Panel_MFW_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]:
    'Media_Panel_MFW_Wix_Videos_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]:
    'Media_Panel_Shutterstock_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]:
    'Media_Panel_Shutterstock_Videos_Section_Label',
};

const SEARCH_SECTIONS_TITLES_MAP = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]:
    'Media_Panel_Results_Site_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]:
    'Media_Panel_Results_Wix_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]:
    'Media_Panel_Results_Wix_Videos_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS]:
    'Media_Panel_Results_Illustrations_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART]:
    'Media_Panel_Results_Vector_Art_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS]:
    'Media_Panel_Results_Social_Icons_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]:
    'Media_Panel_Results_Shutterstock_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]:
    'Media_Panel_Results_Shutterstock_Videos_Section_Label',
};

const SEARCH_SECTIONS_MEDIA_FROM_WIX_TITLES_MAP = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]:
    'Media_Panel_Results_Site_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]:
    'Media_Panel_MFW_Results_Wix_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]:
    'Media_Panel_MFW_Results_Wix_Videos_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS]:
    'Media_Panel_MFW_Results_Illustrations_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART]:
    'Media_Panel_MFW_Results_Vector_Art_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS]:
    'Media_Panel_MFW_Results_Social_Icons_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]:
    'Media_Panel_Results_Shutterstock_Images_Section_Label',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]:
    'Media_Panel_Results_Shutterstock_Videos_Section_Label',
};

const PUBLIC_MEDIA_ROOT = {
  IMAGES: 'images',
  ILLUSTRATIONS: 'illustrations',
  VIDEOS: 'videos',
  VECTOR_ART: 'vectorArt',
  SOCIAL_ICONS: 'social_icons',
};

const PUBLIC_MEDIA_ROOT_MEDIA_TYPE_MAP = {
  [PUBLIC_MEDIA_ROOT.IMAGES]: 'picture',
  [PUBLIC_MEDIA_ROOT.ILLUSTRATIONS]: 'picture',
  [PUBLIC_MEDIA_ROOT.SOCIAL_ICONS]: 'picture',
  [PUBLIC_MEDIA_ROOT.VIDEOS]: 'video',
  [PUBLIC_MEDIA_ROOT.VECTOR_ART]: 'shape',
};

const SHUTTERSTOCK_MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
};

const PRICE_INFO_URL_BASE =
  '/_api/wix/digitalGoodsPriceQuote?digitalGoodsSkuGuid=';

const PRICE_INFO_URLS = {
  SHUTTERSTOCK: {
    [SHUTTERSTOCK_MEDIA_TYPE.IMAGE]: `${PRICE_INFO_URL_BASE}32366262-3737-3438-2D38-3537332D3333`,
    [SHUTTERSTOCK_MEDIA_TYPE.VIDEO]: `${PRICE_INFO_URL_BASE}32313337-3765-3164-2D66-6164332D3131`,
  },
  WIX: {
    [MEDIA_MANAGER_MEDIA_TYPES.PICTURE]: `${PRICE_INFO_URL_BASE}a783ee2d-0dfa-4b20-a33c-b0e5a10ed7f8`,
    [MEDIA_MANAGER_MEDIA_TYPES.VIDEO]: `${PRICE_INFO_URL_BASE}bd349da8-aa45-4bda-856d-f2704585c2ff`,
  },
};

const MEDIA_MANAGER_UPLOAD_SOURCES = {
  COMPUTER: 'computer',
  IMPORT: 'import',
  MANAGE: 'manage',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  DROPBOX: 'dropbox',
  GOOGLE_PHOTOS: 'googlePhotos',
  GOOGLE_DRIVE: 'drive',
};

const MEDIA_SOURCES = {
  ALL: 'all',
  PRIVATE: 'private',
  PUBLIC: 'public',
  SHUTTERSTOCK: 'shutterStock',
  UNSPLASH: 'unsplash',
};

const NAVIGATION_TOOLTIP_KEYS = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA]: 'Media_Panel_Empty_Manager',
  [MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA]:
    'Media_Panel_Edit_ImagesVideos',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]: 'Media_Panel_Recently_Added',
  [MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA]: 'Media_Panel_Recommended',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]: 'Media_Panel_Wix_Images',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]: 'Media_Panel_Wix_Videos',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS]:
    'Media_Panel_Wix_Illustrations',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART]: 'Media_Panel_Wix_Vector_Art',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS]:
    'Media_Panel_Wix_Social_Icons',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]:
    'Media_Panel_Shutterstock_Images',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]:
    'Media_Panel_Shutterstock_Videos',
};

const NAVIGATION_MFW_TOOLTIP_KEYS = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.ADD_MEDIA]: 'Media_Panel_Empty_Manager',
  [MEDIA_MANAGER_PANEL_SECTION_ID.CREATE_MEDIA]:
    'Media_Panel_Edit_ImagesVideos',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]: 'Media_Panel_Recently_Added',
  [MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA]: 'Media_Panel_Recommended',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]: 'Media_Panel_MFW_Wix_Images',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]: 'Media_Panel_MFW_Wix_Videos',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS]:
    'Media_Panel_Wix_Illustrations',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART]: 'Media_Panel_Wix_Vector_Art',
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS]:
    'Media_Panel_Wix_Social_Icons',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]:
    'Media_Panel_Shutterstock_Images',
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]:
    'Media_Panel_Shutterstock_Videos',
};

const VIDEO_FILE_TRANSCODING_FAILED_TOOLTIP_MESSAGE =
  'Media_Panel_Upload_Transcoding_Error';

const MEDIA_MANAGER_OPEN_OPTIONS = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]: () => ({
    path: MEDIA_SOURCES.PRIVATE,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.RECOMMENDED_MEDIA]: () => ({
    path: MEDIA_SOURCES.PRIVATE, // TODO: update to specific root, when it's be ready
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]: (
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `${MEDIA_SOURCES.PUBLIC}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS]: (
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `${MEDIA_SOURCES.PUBLIC}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]: (
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `${MEDIA_SOURCES.PUBLIC}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]: () => ({
    path: `${MEDIA_SOURCES.SHUTTERSTOCK}/${SHUTTERSTOCK_MEDIA_TYPE.IMAGE}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]: () => ({
    path: `${MEDIA_SOURCES.SHUTTERSTOCK}/${SHUTTERSTOCK_MEDIA_TYPE.VIDEO}`,
  }),
};

const MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH = {
  [MEDIA_MANAGER_PANEL_SECTION_ID.SITE_FILES]: (searchQuery: AnyFixMe) => ({
    path: `search/${MEDIA_SOURCES.ALL}/${searchQuery}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES]: (
    searchQuery: string,
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `search/${MEDIA_SOURCES.PUBLIC}/${searchQuery}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS]: (
    searchQuery: string,
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `search/${MEDIA_SOURCES.PUBLIC}/${searchQuery}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VECTOR_ART]: (
    searchQuery: string,
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `search/${MEDIA_SOURCES.PUBLIC}/${searchQuery}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_SOCIAL_ICONS]: (
    searchQuery: string,
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `search/${MEDIA_SOURCES.PUBLIC}/${searchQuery}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS]: (
    searchQuery: string,
    publicMediaItemsDeepLink = '',
  ) => ({
    path: `search/${MEDIA_SOURCES.PUBLIC}/${searchQuery}/${publicMediaItemsDeepLink}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES]: (
    searchQuery: AnyFixMe,
  ) => ({
    path: `search/${MEDIA_SOURCES.SHUTTERSTOCK}/${searchQuery}/${SHUTTERSTOCK_MEDIA_TYPE.IMAGE}`,
  }),
  [MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_VIDEOS]: (
    searchQuery: AnyFixMe,
  ) => ({
    path: `search/${MEDIA_SOURCES.SHUTTERSTOCK}/${searchQuery}/${SHUTTERSTOCK_MEDIA_TYPE.VIDEO}`,
  }),
};

const MEDIA_MANAGER_MEDIA_TYPES_SHUTTER_STOCK_MEDIA_TYPES_MAP = {
  [MEDIA_MANAGER_MEDIA_TYPES.PICTURE]: SHUTTERSTOCK_MEDIA_TYPE.IMAGE,
  [MEDIA_MANAGER_MEDIA_TYPES.VIDEO]: SHUTTERSTOCK_MEDIA_TYPE.VIDEO,
};

const WIX_MEDIA_SECTIONS_LIST_FOR_FULL_INFO = [
  MEDIA_MANAGER_PANEL_SECTION_ID.WIX_VIDEOS,
];

const ADD_TO_STAGE_METHOD = {
  CLICK: 'click',
  DRAG: 'drag',
  MEDIA_MANAGER: 'media_manager',
};

const RECOMMENDED_MEDIA_SECTION_ITEMS_WIX_MEDIA_ROOTS = [
  PUBLIC_MEDIA_ROOT.IMAGES,
  PUBLIC_MEDIA_ROOT.VECTOR_ART,
  PUBLIC_MEDIA_ROOT.ILLUSTRATIONS,
  PUBLIC_MEDIA_ROOT.VIDEOS,
];

const RECOMMENDED_CARD_TOPICS = [
  'art',
  'nature',
  'music',
  'lifestyle',
  'sports',
  'home',
];

const RECOMMENDED_MEDIA_QUESTION = {
  disableExposure: true,
  noDontShowAgain: true,
  questionId: `${MEDIA_MANAGER_OPEN_SOURCE_BASE}_recommended_topics_picker`,
  triggerEvent: 'all-categories-button-click',
  feedbackModel: {
    feedbackType: 'question',
    theme: 'light',
    question: {
      title: translate('Media_Panel_Recommended_FeedbackWindow_Title'),
      subTitle: translate('Media_Panel_Recommended_FeedbackWindow_Subtitle'),
      justifyContent: 'start',
    },
    answer: {
      firstLevelType: 'firstlevel-image-tags',
      cancelButton: {
        label: translate(
          'Media_Panel_Recommended_FeedbackWindow_Cancel_Button',
        ),
      },
      applyButton: {
        choice: 'apply',
        label: translate('Media_Panel_Recommended_FeedbackWindow_Apply_Button'),
      },
      tags: [] as AnyFixMe,
    },
  },
};

const RECOMMENDED_MEDIA_BUTTONS = {
  APPLY: 'apply',
  SEE_ALL: 'see_all',
  DOTS: 'three_dots',
  DO_THIS_LATER: 'do_this_later',
  DONT_SHOW_AGAIN: 'dont_show_again',
  SHOW_MORE: 'show_more',
  SETTINGS: 'settings',
  FEEDBACK_APPLY: 'feedback_apply',
  FEEDBACK_CANCEL: 'feedback_cancel',
};

const RECOMMENDED_MEDIA_PRESENCE_TYPE = {
  NO: 'no',
  AS_CARD: 'as_card',
  AS_SECTION: 'as_section_with_images',
};

const CHECKOUT_MEDIA_TYPE = {
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
};

const CHECKOUT_MEDIA_PRODUCER = {
  SHUTTERSTOCK: 'shutterStock',
  WIXSTOCK: 'wixStock',
};

const ITEMS_LOADING_OPTIONS = {
  paging: {
    size: ITEMS_TO_LOAD_COUNT,
  },
};

const DYNAMIC_MEDIA_CONTENT_WIDTH = 304;

export {
  MEDIA_MANAGER_PANEL_TITLE,
  MEDIA_MANAGER_OPEN_SOURCE_BASE,
  MEDIA_MANAGER_PANEL_SECTION_ID,
  PANEL_BI_SOURCE_SUFFIX,
  SHUTTERSTOCK_PAYMENT_API,
  BLACK_ARROW_CONFIG,
  SOCKET_STATUSES,
  TRANSCODING_STATUSES,
  ITEMS_TO_LOAD_COUNT,
  RECOMMENDED_ITEMS_TO_LOAD_COUNT,
  RECOMMENDED_SECTION_INITIAL_VISIBLE_ITEMS_COUNT,
  SECTIONS_TITLES_MAP,
  SECTIONS_MEDIA_FROM_WIX_TITLES_MAP,
  SEARCH_SECTIONS_TITLES_MAP,
  SEARCH_SECTIONS_MEDIA_FROM_WIX_TITLES_MAP,
  PUBLIC_MEDIA_ROOT,
  PUBLIC_MEDIA_ROOT_MEDIA_TYPE_MAP,
  PRICE_INFO_URLS,
  SHUTTERSTOCK_MEDIA_TYPE,
  MEDIA_MANAGER_UPLOAD_SOURCES,
  MEDIA_SOURCES,
  NAVIGATION_TOOLTIP_KEYS,
  NAVIGATION_MFW_TOOLTIP_KEYS,
  VIDEO_FILE_TRANSCODING_FAILED_TOOLTIP_MESSAGE,
  MEDIA_MANAGER_OPEN_OPTIONS_WHEN_SEARCH,
  MEDIA_MANAGER_OPEN_OPTIONS,
  MEDIA_MANAGER_MEDIA_TYPES_SHUTTER_STOCK_MEDIA_TYPES_MAP,
  WIX_MEDIA_SECTIONS_LIST_FOR_FULL_INFO,
  ADD_TO_STAGE_METHOD,
  RECOMMENDED_CARD_TOPICS,
  RECOMMENDED_MEDIA_QUESTION,
  RECOMMENDED_MEDIA_SECTION_ITEMS_WIX_MEDIA_ROOTS,
  RECOMMENDED_MEDIA_BUTTONS,
  MEDIA_PANEL_NEVER_SHOW_RECOMMENDED_CARD_KEY,
  RECOMMENDED_MEDIA_PRESENCE_TYPE,
  MEDIA_PRODUCER,
  OPEN_BY_URL_SOURCE,
  CHECKOUT_MEDIA_TYPE,
  CHECKOUT_MEDIA_PRODUCER,
  WIX_MEDIA_ITEMS_FULL_INFO_LOAD_MAX_RETRY_COUNT,
  ITEMS_LOADING_OPTIONS,
  DYNAMIC_MEDIA_CONTENT_WIDTH,
};

import { domMeasurements } from '#packages/stateManagement';
import { fedopsLogger } from '#packages/util';
import { isSection } from '../../../api';

import type { CompRef } from 'types/documentServices';
import type { Scope } from '#packages/sections';

const resizeInteractions = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.RESIZE,
);

export const createResizeAndPushHooks = (scope: Scope) => {
  const { editorAPI, components } = scope;
  let extraSiteHeightBeforeResize: number;

  const waitForSiteExtraHeightChange = () => {
    let unsubscribe;

    const promise = new Promise<void>((resolve) => {
      unsubscribe = editorAPI.registerToViewerChange(
        (changes: string[] | null) => {
          if (!changes?.includes('documentMode.setExtraSiteHeight')) return;
          resolve();
        },
      );
    });

    return promise.then(unsubscribe);
  };

  const start = async (compRef: CompRef) => {
    if (!isSection(scope, compRef)) return;

    resizeInteractions.start();

    const stageHeight = domMeasurements.selectors.getStageLayout(
      editorAPI.store.getState(),
    ).height;

    const sectionLayout = components.layout.getRelativeToScreen(compRef);
    const footerLayout = components.layout.getRelativeToScreen(
      editorAPI.siteSegments.getFooter(),
    );

    const sectionBottom = sectionLayout.y + sectionLayout.height;
    const footerBottom = footerLayout.y + footerLayout.height;

    const isCloseToFooter = footerBottom - sectionBottom < stageHeight;

    if (!isCloseToFooter) return;

    const nextExtraSiteHeight = Math.min(sectionLayout.height, stageHeight);
    const extraSiteHeightAlreadySet =
      editorAPI.documentMode.getExtraSiteHeight();

    if (extraSiteHeightAlreadySet !== nextExtraSiteHeight) {
      // doubleClick triggers 2 resizes, emits start/end events twice
      // meanwhile documentMode.setExtraSiteHeight is not sync and this creates a race
      // so if current value in DS is the same as the height of a section we ignore it
      extraSiteHeightBeforeResize = extraSiteHeightAlreadySet;
    }

    editorAPI.documentMode.setExtraSiteHeight(nextExtraSiteHeight);
    await waitForSiteExtraHeightChange();
    const { scrollLeft, scrollTop } = editorAPI.scroll.get();
    editorAPI.site.setScroll(scrollLeft, scrollTop);
  };

  const end = async (compRef: CompRef) => {
    if (!isSection(scope, compRef)) return;

    editorAPI.documentMode.setExtraSiteHeight(extraSiteHeightBeforeResize);
    await waitForSiteExtraHeightChange();
    const { scrollLeft, scrollTop } = editorAPI.scroll.get();
    editorAPI.site.setScroll(scrollLeft, scrollTop);

    resizeInteractions.end();
  };

  return {
    start,
    end,
  };
};

// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import _ from 'lodash';
import { Checkbox } from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';

import * as core from '#packages/core';
import constants from '#packages/constants';
import * as BaseUI from '#packages/baseUI';
import {
  imageTransform,
  url,
  serviceTopology,
  backgroundUtils,
  cx,
} from '#packages/util';

const { getColorValue, getMediaType } = backgroundUtils;
const mediaTypes = constants.MEDIA_TYPES;

const PAGE_BG_PREVIEW_WIDTH = 50;
const PAGE_BG_PREVIEW_HEIGHT = 35;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'bgApplyPanelPageItem',
  propTypes: {
    page: PropTypes.object.isRequired,
    itemBgData: PropTypes.object,
    currentPageBgData: PropTypes.object,
    valueLink: PropTypes.object,
    currentPageId: PropTypes.string,
    isCurrentPage: PropTypes.bool,
    isSubMenu: PropTypes.bool,
    isSubMenuLast: PropTypes.bool,
    sendToggleBi: PropTypes.func,
  },
  mixins: [core.mixins.editorAPIMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated

  getInitialState() {
    this.initialBgData = this.props.itemBgData;
    return {
      checked: this.props.isCurrentPage,
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.isValueChanged(nextProps)) {
      this.setState({ checked: nextProps.valueLink.value });
    }
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !this.props.isCurrentPage &&
      (this.isCheckedStateChanged(nextState) || this.isValueChanged(nextProps))
    );
  },

  componentStateChanged() {
    this.props.valueLink.requestChange(
      this.state.checked,
      this.props.page.pageData.id,
      this.initialBgData,
    );
  },

  handleToggleChanged(value) {
    this.setState({ checked: value }, this.componentStateChanged);
    const action = value ? 'on' : 'off';
    this.props.sendToggleBi(this.props.page.pageData.id, action);
  },

  isValueChanged(nextProps) {
    return (
      nextProps.valueLink.value !== null &&
      this.props.valueLink.value !== nextProps.valueLink.value
    );
  },

  isCheckedStateChanged(nextState) {
    return nextState.checked !== this.state.checked;
  },

  getItemClassNames(page) {
    return {
      'bg-apply-panel-item': true,
      'item-hidden': !page.isVisible,
      'current-page': this.props.isCurrentPage,
      'item-first-level': !this.props.isSubMenu,
      'item-second-level': this.props.isSubMenu,
      'item-second-level-last': this.props.isSubMenuLast,
      'item-is-page': page.type.isPage,
    };
  },

  isMediaVideo() {
    return (
      getMediaType(
        this.state.checked
          ? this.props.currentPageBgData.ref
          : this.initialBgData.ref,
      ) === mediaTypes.VIDEO
    );
  },

  getBgImageUrl(data) {
    const color = getColorValue(this.getEditorAPI(), data.ref.color);
    const style = { backgroundColor: color };
    const mediaType = getMediaType(data.ref);
    if (mediaType !== mediaTypes.COLOR) {
      const imageData =
        mediaType === mediaTypes.IMAGE
          ? data.ref.mediaRef
          : data.ref.mediaRef.posterImageRef;
      const isSmaller = backgroundUtils.isSmallerFromContainer(
        imageData.width,
        imageData.height,
        PAGE_BG_PREVIEW_WIDTH,
        PAGE_BG_PREVIEW_HEIGHT,
      );
      const imageScaled = backgroundUtils.getImageDisplayData(
        isSmaller
          ? imageTransform.fittingTypes.TILE
          : imageTransform.fittingTypes.SCALE_TO_FILL,
        data.ref.alignType,
        imageData.uri,
        imageData.width,
        imageData.height,
        PAGE_BG_PREVIEW_WIDTH,
        PAGE_BG_PREVIEW_HEIGHT,
      );
      style.backgroundImage = `url(${url.joinURL(
        serviceTopology.staticMediaUrl,
        imageScaled.uri,
      )})`;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(style, imageScaled.css.container);
    }
    return style;
  },

  render() {
    const { page } = this.props;
    const bgData = this.initialBgData;
    const editorAPI = this.getEditorAPI();

    return (
      <div className={cx(this.getItemClassNames(page))}>
        <label className="item-label">
          {bgData ? (
            <Checkbox
              key="normal-checkbox"
              value={this.state.checked}
              onChange={this.handleToggleChanged}
              disabled={this.props.isCurrentPage}
              labelAfterSymbol={true}
            />
          ) : null}
          {bgData ? (
            <span
              key="page-preview"
              style={this.getBgImageUrl(
                this.state.checked ? this.props.currentPageBgData : bgData,
              )}
              className="page-preview"
            >
              {this.isMediaVideo() ? (
                <div
                  key="page-preview-video-controls"
                  className="page-preview-video-controls"
                >
                  <BaseUI.symbol name="bgVideoIndicator" />
                </div>
              ) : null}
            </span>
          ) : null}
          <span className="page-name">{page.label}</span>
          {page.pageData && editorAPI.pages.isHomePage(page.pageData.id) ? (
            <Symbols.symbol name="pageItemHomeIcon" key="isHome" />
          ) : null}
          {page.pageData &&
          core.utils.tpaTypeUtils.isTPA(page.pageData) &&
          !core.utils.tpaTypeUtils.isTPASpecialPage(
            editorAPI.dsRead,
            page.pageData,
          ) ? (
            <Symbols.symbol name="actionButtonSpecialPages" key="isTpaPage" />
          ) : null}
          {page.pageData &&
          core.utils.blogTypeUtils.isBlogSectionPage(
            editorAPI,
            page.pageData,
          ) ? (
            <Symbols.symbol name="actionButtonBlogPages" key="isBlogPage" />
          ) : null}
          {page.type.isLink ? (
            <Symbols.symbol name="pageItemLinkIcon" key="isLink" />
          ) : null}
          {page.type.isDropdown ? (
            <Symbols.symbol name="pageItemDropdownIcon" key="isDropDown" />
          ) : null}
          {!page.isVisible ? (
            <Symbols.symbol name="pageItemHideIcon" key="isHidden" />
          ) : null}
        </label>
      </div>
    );
  },
});

import React from 'react';
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import * as CompPanelInfra from '#packages/compPanelInfra';
import {
  Composites,
  Divider,
  InfoIcon,
  Slider,
  TextLabel,
  Thumbnails,
} from '@wix/wix-base-ui';
import * as helpIds from '#packages/helpIds';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import { mapStateToProps, mapDispatchToProps } from './transitionPanelMapper';
import {
  DURATION_SETTING,
  DELAY_SETTING,
  TIMING_OPTIONS,
  noneThumbnailProps,
  THUMBNAIL_IMAGE_SIZE,
} from './transitionPanelSettings';
import { TimingFunctionTypes } from './transitionPanelTypes';
import type { ITransitionValue, ITimingOption } from './transitionPanelTypes';
import type { SendBiFunction } from 'types/bi';

interface ITransitionPanelProps extends ITransitionValue {
  frameProps: Object;
  updateTransition: (value: ITransitionValue) => void;
  sendBI: SendBiFunction;
  openHelpCenter: (helpId: string) => void;
  variantId: string;
}

interface ITransitionPanelState {
  timingThumbnails: any;
}

const transitionIconsPath = util.media.getMediaUrl(
  'panels/interactionTransitions/',
);

interface FourStatesThumbnailProps {
  illustration: string;
  illustrationHover: string;
  illustrationSelected: string;
  illustrationSelectedHover: string;
  isSelected: boolean;
}

interface FourStatesThumbnailState {
  currentThumbnail: string;
  isHovered: boolean;
}

class FourStatesThumbnail extends React.Component<
  FourStatesThumbnailProps,
  FourStatesThumbnailState
> {
  constructor(props: AnyFixMe) {
    super(props);
    this.state = {
      isHovered: false,
      currentThumbnail: props.isSelected
        ? props.illustrationSelected
        : props.illustration,
    };
  }

  static getDerivedStateFromProps(
    nextProps: FourStatesThumbnailProps,
    prevState: FourStatesThumbnailState,
  ) {
    if (prevState.isHovered) {
      return {
        isHovered: prevState.isHovered,
        currentThumbnail: nextProps.isSelected
          ? nextProps.illustrationSelectedHover
          : nextProps.illustrationHover,
      };
    }
    return {
      isHovered: prevState.isHovered,
      currentThumbnail: nextProps.isSelected
        ? nextProps.illustrationSelected
        : nextProps.illustration,
    };
  }

  handleMouseEnter = () => {
    this.setState({
      isHovered: true,
      currentThumbnail: this.props.isSelected
        ? this.props.illustrationSelectedHover
        : this.props.illustrationHover,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isHovered: false,
      currentThumbnail: this.props.isSelected
        ? this.props.illustrationSelected
        : this.props.illustration,
    });
  };

  render() {
    return (
      <img
        onMouseOver={this.handleMouseEnter}
        onMouseOut={this.handleMouseLeave}
        src={`${transitionIconsPath}${this.state.currentThumbnail}`}
        style={{
          width: THUMBNAIL_IMAGE_SIZE,
          height: THUMBNAIL_IMAGE_SIZE,
          cursor: 'pointer',
        }}
      />
    );
  }
}

const createThumbnail = (
  timingOption: ITimingOption,
  timingFunction: string,
) => {
  const isSelected = timingOption.value === timingFunction;
  return {
    isSelected,
    value: timingOption.value,
    label: timingOption.label,
    illustration: (
      <FourStatesThumbnail
        isSelected={isSelected}
        illustration={timingOption.illustrationDefault}
        illustrationHover={timingOption.illustrationHover}
        illustrationSelected={timingOption.illustrationSelected}
        illustrationSelectedHover={timingOption.illustrationSelectedHover}
      />
    ),
  };
};

const createThumbnails = (
  timingOptions: ITimingOption[],
  timingFunction: string,
) => {
  return timingOptions.map((timingOption) =>
    createThumbnail(timingOption, timingFunction),
  );
};

const BI_PARAM_MAPPING = {
  duration: 'duration',
  delay: 'delay',
  timingFunction: 'behavior_name',
};

const getDiff = (prevProps: AnyFixMe, props: AnyFixMe) =>
  _.pickBy(prevProps, (val, key) => props[key] !== val);

class TransitionPanel extends React.Component<
  ITransitionPanelProps,
  ITransitionPanelState
> {
  constructor(props: AnyFixMe) {
    super(props);
    this.state = {
      timingThumbnails: createThumbnails(
        TIMING_OPTIONS,
        this.props.timingFunction,
      ),
    };
  }

  componentDidMount() {
    this.initialTransitionValue = this.getTransitionValue();
  }

  componentWillUnmount() {
    const transitionValue = this.getTransitionValue();
    const diff = getDiff(this.initialTransitionValue, transitionValue);

    if (!_.isEmpty(diff)) {
      const biParams = {
        ..._.mapKeys(
          diff,
          (value, key) =>
            BI_PARAM_MAPPING[key as keyof typeof BI_PARAM_MAPPING],
        ),
        interaction_id: this.props.variantId,
      };

      this.props.sendBI(
        coreBi.events.interactions.timing_panel_behavior_saved,
        biParams,
      );
    }
  }

  initialTransitionValue: ITransitionValue;

  getTransitionValue(): ITransitionValue {
    const { duration, delay, timingFunction } = this.props;

    return {
      duration,
      delay,
      timingFunction,
    };
  }

  updateTransitionValue(nextTransitionValue: AnyFixMe) {
    this.props.updateTransition({
      ...this.getTransitionValue(),
      ...nextTransitionValue,
    });
  }

  handleDurationChange = (value: number) => {
    const duration = Number(value.toFixed(1));

    this.updateTransitionValue({ duration });

    this.props.sendBI(coreBi.events.interactions.timing_panel_change_settings, {
      interaction_id: this.props.variantId,
      behavior_name: this.props.timingFunction,
      parameter_name: 'duration',
      old_value: this.props.duration,
      new_value: duration,
    });
  };

  handleDelayChange = (value: number) => {
    const delay = Number(value.toFixed(1));

    this.updateTransitionValue({ delay });

    this.props.sendBI(coreBi.events.interactions.timing_panel_change_settings, {
      interaction_id: this.props.variantId,
      behavior_name: this.props.timingFunction,
      parameter_name: 'delay',
      old_value: this.props.delay,
      new_value: delay,
    });
  };

  handleTimingFunctionClick = (timingFunction: TimingFunctionTypes) => {
    if (this.props.timingFunction === timingFunction) {
      return;
    }
    const timingOptions = TIMING_OPTIONS;
    this.setState({
      timingThumbnails: createThumbnails(timingOptions, timingFunction),
    });
    this.updateTransitionValue({ timingFunction });

    if (timingFunction === TimingFunctionTypes.NONE) {
      this.props.sendBI(
        coreBi.events.interactions.timing_panel_behavior_deleted,
        {
          interaction_id: this.props.variantId,
          behavior_name: timingFunction,
        },
      );
    } else {
      this.props.sendBI(
        coreBi.events.interactions.timing_panel_behavior_click,
        {
          interaction_id: this.props.variantId,
          behavior_name: timingFunction,
        },
      );
    }
  };

  handleLearnMoreClick = () => {
    this.props.openHelpCenter(helpIds.INTERACTIONS.TIMING_PANEL);
  };

  render() {
    return (
      <CompPanelInfra.compPanelFrame
        title="interactions_timing_header"
        helpId={helpIds.INTERACTIONS.TIMING_PANEL}
        automationId="transition-panel"
        contentClass="transition-panel-content"
        {...this.props.frameProps}
      >
        <Composites.SliderLabeled>
          <TextLabel value="interactions_timing_duration_label" />
          <Slider
            value={this.props.duration}
            onChange={this.handleDurationChange}
            min={DURATION_SETTING.min}
            max={DURATION_SETTING.max}
            step={DURATION_SETTING.step}
          />
        </Composites.SliderLabeled>
        <Divider long />
        <Composites.SliderLabeled>
          <TextLabel value="interactions_timing_delay_label" />
          <Slider
            value={this.props.delay}
            onChange={this.handleDelayChange}
            min={DELAY_SETTING.min}
            max={DELAY_SETTING.max}
            step={DELAY_SETTING.step}
          />
        </Composites.SliderLabeled>
        <Divider long />
        <Composites.Thumbnails>
          <InfoIcon
            text="interactions_timing_easing_tooltip_text"
            linkText="interactions_timing_easing_tooltip_link"
            // @ts-expect-error
            shouldCloseOnMouseClick={true}
            closeOnContentMouseClick={true}
            onLinkClick={this.handleLearnMoreClick}
          />
          <TextLabel value="interactions_timing_easing_label" />
          <Thumbnails
            value={this.props.timingFunction}
            onChange={this.handleTimingFunctionClick}
            maxThumbsPerRow={3}
            fixedRatio={true}
            hasBackground={false}
            noneThumbnailProps={noneThumbnailProps}
            options={this.state.timingThumbnails}
          />
        </Composites.Thumbnails>
      </CompPanelInfra.compPanelFrame>
    );
  }
}

const ConnectedTransitionPanel = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  stateManagement.components.hoc.compPanel,
)(TransitionPanel);

export default ConnectedTransitionPanel;

import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import $ from 'zepto';
import _ from 'lodash';
import { Heading } from '@wix/wix-base-ui';

import constants from '#packages/constants';
import * as core from '#packages/core';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import * as panels from '#packages/panels';
import * as baseUI from '#packages/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { biLogger } from '#packages/util';
import { firstPublishDialogClick } from '@wix/bi-logger-editor/v2';

const PREMIUM_BASE_URL = 'https://premium.wix.com/wix/api/';
const URL_PARAMS =
  '?siteGuid=<%= siteGUID %>&referralAdditionalInfo=<%= referralInfo %>';

const CONNECT_TO_NEW_DOMAIN_URL = `${PREMIUM_BASE_URL}domainConsole${URL_PARAMS}`;

enum FirstPublishConsts {
  FIRST_PUBLISH_NEW = `FIRST_PUBLISH_CONNECT_NEW_DOMAIN`,
  FIRST_PUBLISH_EXISTING = `FIRST_PUBLISH_CONNECT_EXISTING_DOMAIN`,
  ENTRY_POINT = 'pp_entry_point',
  GET_NEW_DOMAIN = 'get_new_domain',
  CONNECT_DOMAIN = 'connect_domain',
  STEP = '2',
}

type FirstPublishDomainType =
  | FirstPublishConsts.FIRST_PUBLISH_NEW
  | FirstPublishConsts.FIRST_PUBLISH_EXISTING;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'connectDomainPanel',
  mixins: [core.mixins.editorAPIMixin, util.translationMixin],
  propTypes: {
    isPremium: PropTypes.bool.isRequired,
    isFirstSave: PropTypes.bool,
    isFirstPublish: PropTypes.bool,
    domainSuggestion: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    panelName: PropTypes.string,
    onConnectDomainFlowEnded: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    flowId: PropTypes.oneOf(_.values(constants.CONNECT_DOMAIN.FLOWS))
      .isRequired,
  },
  getInitialState() {
    return {
      actionIndexHovered: -1,
    };
  },
  connectToNewDomain() {
    const DOMAIN_TYPE_NEW = 'CONNECT_NEW_DOMAIN';
    const biEvent =
      coreBi.events.save.siteWasSavedPanel.CONNECT_NEW_DOMAIN_CLICKED;
    let action;
    if (
      this.props.origin === constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH
    ) {
      action = this.getOpenPackagePickerFn(
        FirstPublishConsts.FIRST_PUBLISH_NEW,
        {
          target: FirstPublishConsts.ENTRY_POINT,
          selectedValue: FirstPublishConsts.GET_NEW_DOMAIN,
          step: FirstPublishConsts.STEP,
        },
      );
    } else {
      action = this.openUpgrade.bind(this, DOMAIN_TYPE_NEW);
    }
    if (this.props.isPremium) {
      action = this.openConnectDomainInWindow.bind(this, DOMAIN_TYPE_NEW);
    }
    this.connectDomain(biEvent, action);
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.urlBar.connect_domain_panel_action_click,
      {
        action: 'get_new_domain',
        origin: this.props.origin,
        domain_type: this.props.domainSuggestion ? 'suggested' : 'general',
      },
    );
  },
  connectExistingDomain() {
    const DOMAIN_TYPE_EXISTING = 'CONNECT_EXISTING_DOMAIN';
    const biEvent =
      coreBi.events.save.siteWasSavedPanel.CONNECT_EXISTING_DOMAIN_CLICKED;
    let action;
    if (
      this.props.origin === constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH
    ) {
      action = this.getOpenPackagePickerFn(
        FirstPublishConsts.FIRST_PUBLISH_EXISTING,
        {
          target: FirstPublishConsts.ENTRY_POINT,
          selectedValue: FirstPublishConsts.CONNECT_DOMAIN,
          step: FirstPublishConsts.STEP,
        },
      );
    } else {
      action = this.openUpgrade.bind(this, DOMAIN_TYPE_EXISTING);
    }
    if (this.props.isPremium) {
      action = this.openConnectDomainInWindow.bind(this, DOMAIN_TYPE_EXISTING);
    }

    this.connectDomain(biEvent, action);
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.urlBar.connect_domain_panel_action_click,
      {
        action: 'connect_domain',
        origin: this.props.origin,
        domain_type: this.props.domainSuggestion ? 'suggested' : 'general',
      },
    );
  },
  getOpenPackagePickerFn(
    domainType: FirstPublishDomainType,
    biArgs: {
      target: FirstPublishConsts.ENTRY_POINT;
      selectedValue:
        | FirstPublishConsts.GET_NEW_DOMAIN
        | FirstPublishConsts.CONNECT_DOMAIN;
      step: FirstPublishConsts.STEP;
    },
  ) {
    const action = this.openUpgrade.bind(this, domainType);
    biLogger.report(
      firstPublishDialogClick({
        target: biArgs.target,
        selectedValue: biArgs.selectedValue,
        step: biArgs.step,
      }),
    );
    return action;
  },
  connectDomain(biEvent: AnyFixMe, actionOnClick: AnyFixMe) {
    if (this.props.isFirstSave) {
      this.getEditorAPI().bi.event(biEvent);
    }
    const isListening = this.listenToTabFocusIfNeeded();
    actionOnClick.call(this);
    this.onClose(isListening);
  },
  listenToTabFocusIfNeeded() {
    if (this.props.onConnectDomainFlowEnded) {
      const onEditorFocus = function () {
        //@ts-expect-error
        this.getWindow().off('focus', onEditorFocus);
        //@ts-expect-error
        this.props.onConnectDomainFlowEnded();
      }.bind(this);
      this.getWindow().on('focus', this.onEditorFocus);
      return true;
    }
    return false;
  },
  onEditorFocus() {
    this.getWindow().off('focus', this.onEditorFocus);
    this.props.onConnectDomainFlowEnded();
  },
  getReferralCode(domainType: AnyFixMe) {
    let referralCode = 'edhtml_';

    switch (this.props.flowId) {
      case constants.CONNECT_DOMAIN.FLOWS.SAVE:
        referralCode += 'SAVE_FLOW_';
        referralCode += this.props.isFirstSave ? 'FIRST_SAVE_' : 'SECOND_SAVE_';
        referralCode += domainType;
        break;
      case constants.CONNECT_DOMAIN.FLOWS.TOP_BAR:
        referralCode += `TOP_BAR_SITE_MENU_CONNECT_DOMAIN_${domainType}`;
        break;
      case constants.CONNECT_DOMAIN.FLOWS.PUBLISH:
        referralCode += 'PUBLISH_FLOW_';
        referralCode += this.props.isFirstPublish
          ? 'FIRST_PUBLISH_'
          : 'SECOND_PUBLISH_';
        referralCode += domainType;
        break;
      case constants.CONNECT_DOMAIN.FLOWS.PUBLISH_BUTTON:
        referralCode += 'PUBLISH_FLOW_CONNECT_BUTTON_';
        referralCode += this.props.isFirstPublish
          ? 'FIRST_PUBLISH_'
          : 'SECOND_PUBLISH_';
        referralCode += domainType;
        break;
      case constants.CONNECT_DOMAIN.FLOWS.URL_BAR:
        referralCode += `URL_BAR_CONNECT_DOMAIN_${domainType}`;
        break;
      case constants.CONNECT_DOMAIN.FLOWS.FIRST_MANUAL_PUBLISH:
        referralCode += `${domainType}`;
        break;
      default:
        referralCode = null;
    }

    return referralCode;
  },
  openUpgrade(domainType: AnyFixMe) {
    const referralCode = this.getReferralCode(domainType);
    this.getEditorAPI().account.upgrade(referralCode);
  },
  openConnectDomainInWindow(domainType: AnyFixMe) {
    const url = this.createURLWithParams(CONNECT_TO_NEW_DOMAIN_URL, domainType);
    window.open(url);
  },
  createURLWithParams(url: AnyFixMe, domainType: AnyFixMe) {
    const referralCode = this.getReferralCode(domainType);
    const urlTemplate = _.template(url);
    const siteId = this.getEditorAPI().dsRead.generalInfo.getSiteId();
    return urlTemplate({ siteGUID: siteId, referralInfo: referralCode });
  },
  maybeLater() {
    this.getEditorAPI().bi.event(
      coreBi.events.save.siteWasSavedPanel
        .First_Save_Dialog_2_maybe_later_click,
    );
    this.onClose(false, 'maybe_later');
  },
  onClose(isListening: AnyFixMe, closeOrigin: AnyFixMe) {
    this.getEditorAPI().panelManager.closePanelByName(
      this.props.panelName,
      closeOrigin,
    );
    if (!isListening && this.props.onConnectDomainFlowEnded) {
      this.props.onConnectDomainFlowEnded();
    }
  },
  getWindow() {
    return $(window);
  },
  resetHoverOnAction() {
    this.hoverOnAction(-1);
  },
  hoverOnAction(actionIndex: AnyFixMe) {
    this.setState({ actionIndexHovered: actionIndex });
  },
  isActionHovered(actionIndex: AnyFixMe) {
    return this.state.actionIndexHovered === actionIndex;
  },
  getKeyboardShortcuts() {
    const self = this;
    return {
      enter() {
        self.maybeLater();
      },
      esc() {
        self.onClose(false);
      },
    };
  },
  renderActions() {
    const firstItemIllustration = util.workspace.isNewWorkspaceEnabled()
      ? 'connectNewDomain_NewWorkspace'
      : 'connectNewDomain';
    const secondItemIllustration = util.workspace.isNewWorkspaceEnabled()
      ? 'connectDomainLight_NewWorkspace'
      : 'connectDomainLight';

    return (
      <div className="actions">
        <a
          onClick={this.connectToNewDomain}
          onMouseEnter={() => {
            this.hoverOnAction(1);
          }}
          onMouseLeave={this.resetHoverOnAction}
          className="action"
          data-hook="get-a-new-domain"
        >
          <div className="action-graphics">
            <baseUI.symbol name={firstItemIllustration} />
          </div>
          <div className="action-title">
            {this.translateIfNeeded('PREMIUM_BUTTON_1')}
          </div>
          {!this.isActionHovered(1) ? (
            <div key="optionArrow" className="action-arrow">
              <baseUI.symbol name="arrowWithCircle" />
            </div>
          ) : null}
          {this.isActionHovered(1) ? (
            <div key="optionLink" className="action-arrow">
              <baseUI.symbol name="openLinkInWindow" />
            </div>
          ) : null}
        </a>
        <a
          onClick={this.connectExistingDomain}
          onMouseEnter={() => {
            this.hoverOnAction(2);
          }}
          onMouseLeave={this.resetHoverOnAction}
          className="action"
          data-hook="connect-domain-you-own"
        >
          <div className="action-graphics">
            <baseUI.symbol name={secondItemIllustration} />
          </div>
          <div className="action-title">
            {this.translateIfNeeded('PREMIUM_BUTTON_2')}
          </div>
          {!this.isActionHovered(2) ? (
            <div key="optionArrow" className="action-arrow">
              <baseUI.symbol name="arrowWithCircle" />
            </div>
          ) : null}
          {this.isActionHovered(2) ? (
            <div key="optionLink" className="action-arrow">
              <baseUI.symbol name="openLinkInWindow" />
            </div>
          ) : null}
        </a>
      </div>
    );
  },
  renderNewWorkspace() {
    return (
      <panels.frames.CustomPanelFrame
        panelName={this.props.panelName}
        className="connect-domain-panel"
        dataHook="connect-domain-panel"
        onCloseButtonClick={() => {
          this.onClose(false);
        }}
        onOuterClick={() => {
          this.onClose(false);
        }}
      >
        <header className="connect-domain-header">
          <Heading shouldTranslate={false} appearance="h1" multiline>
            {this.translateIfNeeded(this.props.title)}
          </Heading>
          <Heading shouldTranslate={false} appearance="h4" multiline>
            {this.translateIfNeeded(this.props.subtitle)}
          </Heading>
        </header>
        {this.renderActions()}
      </panels.frames.CustomPanelFrame>
    );
  },
  render() {
    return util.workspace.isNewWorkspaceEnabled() ? (
      this.renderNewWorkspace()
    ) : (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        shouldHideHeader={true}
        frameClassName="save-publish-panel-frame"
        dataHook="connect-domain-panel"
        keyboardShortcuts={this.getKeyboardShortcuts()}
        className="connect-domain-panel save-publish-panel"
      >
        <header className="save-publish-panel-header">
          <button
            onClick={() => {
              this.onClose(false);
            }}
            className="close"
          >
            <symbols.symbol name="headerCloseButton" />
          </button>
          <span className="title">
            {this.translateIfNeeded(this.props.title)}
          </span>
          <span className="subtitle">
            {this.translateIfNeeded(this.props.subtitle)}
          </span>
        </header>

        {this.renderActions()}
      </panels.frames.FocusPanelFrame>
    );
  },
});

import React, { type ComponentType } from 'react';

import { hoc } from '#packages/util';
import {
  type WithCKEditorConnectProps,
  type WithCKEditorOwnProps,
  mapDispatchToProps,
} from './withCKEditor.mapper';

type Subtract<T, K> = Omit<T, keyof K>;

export type CKEditorInjectedProps = WithCKEditorOwnProps &
  WithCKEditorConnectProps;

export const withCKEditor = <T extends WithCKEditorOwnProps>(
  Component: ComponentType<T>,
) => {
  const WithCKEditor: React.FC<
    WithCKEditorConnectProps & WithCKEditorOwnProps
  > = (props) => {
    return props.textManager ? (
      <Component {...(props as T & WithCKEditorConnectProps)} />
    ) : null;
  };

  const connect = hoc.connect(
    hoc.STORES.EDITOR_API,
    undefined,
    mapDispatchToProps,
  );

  return connect(WithCKEditor) as React.ComponentType<
    Subtract<T, WithCKEditorConnectProps>
  >;
};

import constants from '#packages/constants';

const {
  USER_ADDED: USER_COLORS_KEY,
  USER_SITE_ADDED: USER_CUSTOM_COLORS_KEY,
  USER_ADDED_GRADIENTS: USER_GRADIENTS_KEY,
} = constants.USER_PREFS.THEME.COLORS.CUSTOM;

interface UserPreferencesDef {
  type: 'site' | 'session';
  key: string;
  propName: string;
  updatePropName: string;
}

export const userPreferencesDefs: UserPreferencesDef[] = [
  {
    type: 'session',
    key: USER_COLORS_KEY,
    propName: 'userAddedColors',
    updatePropName: 'setUserAddedColors',
  },
  {
    type: 'site',
    key: USER_CUSTOM_COLORS_KEY,
    propName: 'userCustomAddedColors',
    updatePropName: 'setUserCustomAddedColors',
  },
  {
    type: 'site',
    key: USER_GRADIENTS_KEY,
    propName: 'userAddedGradients',
    updatePropName: 'setUserAddedGradients',
  },
];

export interface StylablePanelWithUserPreferencesProps {
  userAddedColors?: string[];
  setUserAddedColors: (userAddedColors: string[]) => void;
  userCustomAddedColors?: string[];
  setUserCustomAddedColors: (userCustomAddedColors: string[]) => void;
  userAddedGradients?: string[];
  setUserAddedGradients: (userAddedGradients: string[]) => void;
}

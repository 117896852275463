// @ts-nocheck
import * as util from '#packages/util';

const { keyboardShortcuts } = util;
let i = 0;
const contextList = [];

function generateUniqueContext(prefix) {
  return `${(prefix || '') + keyboardShortcuts.CONTEXTS.AUTOMATIC}_${i++}`;
}

export default {
  UNSAFE_componentWillMount() {
    contextList.push(keyboardShortcuts.getContext());
    this.myKeyboardContextName = generateUniqueContext(
      this.keyboardShortcutsContextNamePrefix,
    );
    keyboardShortcuts.registerContext(
      this.myKeyboardContextName,
      this.getCustomKeyboardShortcuts(),
    );
    keyboardShortcuts.setContext(this.myKeyboardContextName);
  },
  componentWillUnmount() {
    keyboardShortcuts.unregisterContext(this.myKeyboardContextName);

    if (this.myKeyboardContextName === keyboardShortcuts.getContext()) {
      const prevContext = contextList.pop();
      keyboardShortcuts.setContext(prevContext);
    }

    contextList.splice(contextList.indexOf(this.myKeyboardContextName), 1);
  },

  getCustomKeyboardShortcuts() {
    if (this.getKeyboardShortcuts) {
      return this.getKeyboardShortcuts();
    }
    return this.getDefaultShortcuts();
  },

  getDefaultShortcuts() {
    return {};
  },
};

import React from 'react';

import { translate } from '#packages/i18n';
import { cx, hoc } from '#packages/util';
import {
  type LayersButtonDispatchProps,
  type LayersButtonStateProps,
  mapDispatchToProps,
  mapStateToProps,
} from './layersButtonMapper';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import styles from './layersButton.scss';
import { Tooltip } from '@wix/wix-base-ui';
import MenuButtonLabel from '../menuButtonLabel/menuButtonLabel';
import { withRestrictions } from '#packages/editorRestrictions';

export interface LayersButtonOwnProps {}

export interface LayersButtonProps
  extends LayersButtonOwnProps,
    LayersButtonStateProps,
    LayersButtonDispatchProps {}

const LayersButton: React.FC<LayersButtonProps> = ({
  isLayersPanelDisplayed,
  toggleLayersPanel,
  isStageZoomMode,
  isEnabled,
}) => (
  <div className={styles.wrapper}>
    <Tooltip
      content={translate('TOPBAR_ZoomOutSection_Layers_Disabled_Tooltip')}
      shouldTranslate={false}
      alignment="RIGHT"
      disabled={!isStageZoomMode}
      maxWidth={500}
    >
      <button
        data-aid="leftbar-layers-button"
        disabled={!isEnabled}
        className={cx(styles.button, {
          [styles.active]: isLayersPanelDisplayed,
          [styles.disabled]: isStageZoomMode,
        })}
        onClick={toggleLayersPanel}
      >
        <Symbol name="leftBarLayers" className={styles.symbol} />
      </button>
    </Tooltip>

    {!isStageZoomMode && (
      <MenuButtonLabel
        automationId="leftbar-layers-button-label"
        className={styles.floatingBlock}
        text={translate('LEFTBAR_LAYERS_LABEL')}
        shouldTranslate={false}
      />
    )}
  </div>
);

export default withRestrictions('left-bar_layers-button')(
  hoc.connect(
    hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(LayersButton),
);

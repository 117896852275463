import React from 'react';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import constants, {
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
} from '#packages/constants';
import { biLogger, controlsUtils, translationUtils } from '#packages/util';
import {
  hasComponentPanel,
  loadComponentPanel,
} from '#packages/componentModel';
import {
  quickEditPanelOrigin,
  getCompDisplayNameOverrideMap,
  getStageDisplayNameOverrideMap,
  getMainActionLabelOverrideMap,
  QUICK_EDIT_COMPS_WITH_MAIN_ACTIONS,
  COMPONENT_TYPES_WITH_BACKGROUND_TO_CHECK,
  QUICK_EDIT_COMPS_TO_SELECT_AND_EDIT_PARENT,
  QUICK_EDIT_COMPONENTS_WITH_RESTRICTED_CHILDREN,
  QUICK_EDIT_RESTRICTED_COMPONENTS_AND_ITS_CHILDREN,
  NUMBER_OF_FIRST_LEVEL_DRILL_IN_PANELS,
  QUICK_EDIT_COMPONENTS_TO_DRILL_IN_WITH_OWN_DISPLAY_NAME,
  QUICK_EDIT_COMPONENTS_SECONDARY_ACTION_NOT_SETTINGS,
} from './consts';
import QuickEditCompPanelWrapper from './components/quickEditCompPanelWrapper';
import QuickEditDrillInPanelWrapper from './components/quickEditDrillInPanelWrapper';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { quickEditPanelReportings } from '@wix/bi-logger-editor/v2';
import { columnsUtils, gfppModel } from '#packages/gfppData';
import { EditorRestrictionsApiKey } from '#packages/apis';

import { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { GFPPActionType } from '@wix/editor-component-types';
import type { QuickEditControlOwnProps } from '@wix/editor-elements-types/quickEditControls';
import type { QuickEditEngineScope } from './quickEditEngineEntryPoint';
import type {
  DrillInPanelConfig,
  DrillInContribution,
} from './quickEditEngineStore';
import type { GFPPData } from '@wix/editor-component-types';

const {
  isInInteractionMode,
  getVariantId,
  getComponentInteractionsVariantsDefIfExist,
} = stateManagement.interactions.selectors;
const { GFPP_BUTTON_CLICK } = constants.COMP_META_DATA.CONTROLS;
const { ACTIONS: GFPP_ACTIONS } = constants.ROOT_COMPS.GFPP;

const { TEXT, COLUMN, SECTION, STRIP_COLUMNS_CONTAINER } = constants.COMP_TYPES;

export interface ControlGfppActionsData {
  mainActionType: GFPPActionType;
  mainActionLabel: QuickEditControlOwnProps['mainActionLabel'];
  secondaryActionLabel: QuickEditControlOwnProps['secondaryActionLabel'];
  onSecondaryActionClick: QuickEditControlOwnProps['onSecondaryActionClick'];
}

export const getCompPanelTypeByActionType = (
  actionType: QuickEditControlActions | GFPPActionType,
): string => {
  switch (actionType) {
    case QuickEditControlActions.BACKGROUND:
      return constants.componentPanels.background;
    case QuickEditControlActions.SETTINGS:
      return constants.componentPanels.settings;
    case QuickEditControlActions.MANAGE:
      return constants.componentPanels.manage;
  }
};

export const getCompSuffix = (compType: string): string =>
  compType.split('.').pop();

export const loadPanelByAction = (
  componentType: string,
  action: QuickEditControlActions,
): Promise<any> => {
  const panelType = getCompPanelTypeByActionType(action);
  try {
    if (hasComponentPanel(componentType, panelType)) {
      return loadComponentPanel(componentType, panelType);
    }
    return import('@/compPanels').then(({ panels }) => {
      return panels[getCompSuffix(componentType)][panelType];
    });
  } catch (e) {
    biLogger.report(
      quickEditPanelReportings({
        component_type: componentType,
        error_type: `load${action}Panel`,
      }),
    );
    ErrorReporter.captureException(e, {
      tags: { quickEditPanel: true, loadPanelByAction: true },
      extra: {
        action,
        panelType,
        componentType,
      },
    });
  }
};

export const getIsTitle = (editorAPI: EditorAPI, compRef: CompRef) => {
  return !!editorAPI.components.data.get(compRef).text.match(new RegExp(/^<h/));
};

export const areChildrenRestricted = (compType: string): boolean => {
  return QUICK_EDIT_COMPONENTS_WITH_RESTRICTED_CHILDREN.has(compType);
};

export const isNotTransparent = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
): boolean => {
  const isSectionComp = compType === SECTION;
  const isBgEditVisibleOnEmptySection =
    isSectionComp &&
    editorAPI.host
      .getAPI(EditorRestrictionsApiKey)
      .allowed('quick-edit_background-empty-section.visible');
  const shouldNotCheck =
    !COMPONENT_TYPES_WITH_BACKGROUND_TO_CHECK.has(compType);
  if (shouldNotCheck || isBgEditVisibleOnEmptySection) {
    return true;
  }
  const design = editorAPI.components.design.get(compRef);
  return (
    !!design?.background?.colorOpacity ||
    (design?.background?.mediaRef &&
      design?.background?.mediaRef?.opacity !== 0)
  );
};

export const canChangeBackground = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
): boolean => {
  if (compType !== STRIP_COLUMNS_CONTAINER) {
    return true;
  }
  const isSingleColumn = editorAPI.columns.isSingleColumnStrip(compRef);
  return columnsUtils.shouldShowChangeBackgroundAction(
    editorAPI,
    compRef,
    isSingleColumn,
  );
};

export const shouldFilterByBackground = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
): boolean =>
  !isNotTransparent(editorAPI, compRef, compType) ||
  !canChangeBackground(editorAPI, compRef, compType);

export const isCompAndChildrenRestricted = (compType: string): boolean => {
  return QUICK_EDIT_RESTRICTED_COMPONENTS_AND_ITS_CHILDREN.has(compType);
};

export const isSingleColumnInStripWithoutBackground = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const parentComp =
    editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
  const isSingleColumnStrip = editorAPI.columns.isSingleColumnStrip(parentComp);
  const compAndParentHaveBackground =
    !shouldFilterByBackground(editorAPI, compRef, COLUMN) &&
    !shouldFilterByBackground(editorAPI, parentComp, STRIP_COLUMNS_CONTAINER);
  return isSingleColumnStrip && !compAndParentHaveBackground;
};

export const getCompDisplayNameOverride = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
): string => {
  if (
    compType === COLUMN &&
    isSingleColumnInStripWithoutBackground(editorAPI, compRef)
  ) {
    compType = STRIP_COLUMNS_CONTAINER;
  }
  const displayNameOverrideKey = getCompDisplayNameOverrideMap[compType];
  const displayName =
    typeof displayNameOverrideKey === 'function'
      ? displayNameOverrideKey(editorAPI, compRef)
      : translate(displayNameOverrideKey);
  return displayName ? displayName : '';
};

export const getStageDisplayNameOverride = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): string => {
  let compNameOverrideEntry = editorAPI.components.getType(compRef);
  if (compNameOverrideEntry === TEXT) {
    const isTitle = getIsTitle(editorAPI, compRef);
    compNameOverrideEntry = isTitle ? 'Text_Title' : 'Text_Paragraph';
  }
  if (
    compNameOverrideEntry === COLUMN &&
    isSingleColumnInStripWithoutBackground(editorAPI, compRef)
  ) {
    compNameOverrideEntry = STRIP_COLUMNS_CONTAINER;
  }
  const displayNameOverrideKey =
    getStageDisplayNameOverrideMap[compNameOverrideEntry];
  return displayNameOverrideKey ? translate(displayNameOverrideKey) : null;
};

const getMainActionLabelOverride = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
): string => {
  const mainActionLabelOverrideKey = getMainActionLabelOverrideMap[compType];
  if (!mainActionLabelOverrideKey) {
    return '';
  }
  const mainActionLabel =
    typeof mainActionLabelOverrideKey === 'function'
      ? translate(mainActionLabelOverrideKey(editorAPI, compRef))
      : translate(mainActionLabelOverrideKey);
  return mainActionLabel ? mainActionLabel : '';
};

const shouldGetSettingsAction = (compType: string) =>
  !QUICK_EDIT_COMPONENTS_SECONDARY_ACTION_NOT_SETTINGS.has(compType);

const getSettingsAction = (compGfppData: GFPPData) => {
  const settingsAction = compGfppData.mainActions.find(
    (action) => action.id == GFPP_ACTIONS.SETTINGS,
  );
  return (
    compGfppData.presetActions[GFPP_ACTIONS.SETTINGS] || settingsAction || null
  );
};

export const getControlGfppActionsData = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compType: string,
  setIsSelected: (action?: QuickEditControlActions | GFPPActionType) => void,
  getMainActionLabel?: (compRef: CompRef) => string,
): ControlGfppActionsData => {
  if (!QUICK_EDIT_COMPS_WITH_MAIN_ACTIONS.has(compType)) {
    return {
      mainActionType: null,
      mainActionLabel: '',
      secondaryActionLabel: '',
      onSecondaryActionClick: null,
    };
  }

  const compGfppData = gfppModel.getFullComponentGfppData(
    editorAPI,
    [compRef],
    quickEditPanelOrigin,
  );
  const mainActionType = compGfppData.mainActions[0].actionType;
  const mainActionFromBridge = getMainActionLabel?.(compRef);
  const mainActionLabel =
    getMainActionLabelOverride(editorAPI, compRef, compType) ||
    translationUtils.translateIfNeeded(
      mainActionFromBridge || compGfppData.mainActions[0].label,
    );
  const settingsAction = shouldGetSettingsAction(compType)
    ? getSettingsAction(compGfppData)
    : null;
  const secondaryAction = settingsAction || compGfppData.mainActions[1];
  const secondaryActionLabel = settingsAction
    ? translate('edit_section_tpa_secondary_button')
    : translationUtils.translateIfNeeded(secondaryAction?.label);
  const onSecondaryActionClick = secondaryAction
    ? () => {
        setIsSelected(secondaryAction.actionType);
        const compRefForClick = controlsUtils.getFirstControllableComponent(
          editorAPI,
          compRef,
          GFPP_BUTTON_CLICK,
        );
        if (secondaryAction.onClick) {
          secondaryAction.onClick(
            editorAPI,
            [compRefForClick],
            quickEditPanelOrigin,
          );
        } else {
          // @ts-expect-error
          secondaryAction.execute();
        }
      }
    : null;
  const areBothActionsIdentical =
    compGfppData.mainActions[0] &&
    secondaryAction?.id &&
    compGfppData.mainActions[0]?.id === secondaryAction?.id;
  return areBothActionsIdentical
    ? {
        mainActionType,
        mainActionLabel: mainActionLabel || secondaryActionLabel,
        secondaryActionLabel: '',
        onSecondaryActionClick: null,
      }
    : {
        mainActionType,
        mainActionLabel,
        secondaryActionLabel,
        onSecondaryActionClick,
      };
};

export const hasSettingsPanel = async (
  componentType: string,
): Promise<boolean> => {
  return !!(await loadPanelByAction(
    componentType,
    QuickEditControlActions.SETTINGS,
  ));
};

export const getCompPanelName = (
  compType: string,
  action: QuickEditControlActions | GFPPActionType,
): string => {
  return `compPanels.panels.${getCompSuffix(
    compType,
  )}.${getCompPanelTypeByActionType(action)}`;
};

export const shouldSelectAndEditParent = (compType: string) => {
  return QUICK_EDIT_COMPS_TO_SELECT_AND_EDIT_PARENT.has(compType);
};

const getDrillInConditionFunction = (
  scope: QuickEditEngineScope,
  drillInIndex: number,
) => {
  return () => {
    const uniqueId =
      scope.drillInContentSlot.getItems(true)[drillInIndex].uniqueId;
    return scope.store.getContributedDrillInPanels()[uniqueId]?.isOpen;
  };
};

export const getCurrentDrillInUniqueIdFromContentSlot = (
  scope: QuickEditEngineScope,
) => {
  const drillInContents = scope.drillInContentSlot.getItems(true);
  return drillInContents[drillInContents.length - 1].uniqueId;
};

export const isComponentInInteractionMode = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const state = editorAPI.store.getState();
  if (!isInInteractionMode(state)) {
    return false;
  }
  const variantId = getVariantId(state);
  const compRefWithVariantIdExists = [
    compRef,
    ...editorAPI.components.getAncestors(compRef),
  ].some((ref) => {
    const compInteractionIfExists = getComponentInteractionsVariantsDefIfExist(
      editorAPI,
      ref,
    )?.Hover;
    if (!compInteractionIfExists) {
      return false;
    }
    const compInteractionVariantId = compInteractionIfExists[0].id;
    return compInteractionVariantId === variantId;
  });
  return compRefWithVariantIdExists;
};

export const closeDrillInPanel = (scope: QuickEditEngineScope) => {
  const { drillInContentSlot, store } = scope;
  const uniqueId =
    store.getActiveDrillInUniqueIds()[
      store.getActiveDrillInUniqueIds().length - 1
    ];
  const onClose = scope.store.getActiveDrillInOnClose();
  onClose?.();
  store.closeDrillIn();
  const drillInUniqueIds =
    store.getContributedDrillInPanels()[uniqueId].drillInUniqueIds;
  drillInUniqueIds.forEach((id) =>
    drillInContentSlot.discardBy((item) => item.uniqueId === id),
  );
};

const contributeDrillInPanelContent = (
  scope: QuickEditEngineScope,
  { panelConfig, panelComponent }: DrillInContribution,
) => {
  const { drillInContentSlot, store } = scope;
  const drillInIndex = drillInContentSlot.getItems(true).length;
  const conditionFunction = getDrillInConditionFunction(scope, drillInIndex);

  drillInContentSlot.contribute(panelComponent, conditionFunction);

  const uniqueId = getCurrentDrillInUniqueIdFromContentSlot(scope);
  store.contributeDrillInPanel({
    ...panelConfig,
    isOpen: false,
    zIndex: null,
    uniqueId,
  });
  return uniqueId;
};

const contributeMultipleDrillInPanels = (
  scope: QuickEditEngineScope,
  drillInPanelsContributions: Array<DrillInContribution>,
  drillInUniqueIds: Array<string>,
) => {
  const drillInPanelsData = drillInPanelsContributions.map((drillInPanel) => {
    const drillInId = contributeDrillInPanelContent(scope, drillInPanel);
    drillInUniqueIds.push(drillInId);
    return {
      panelName: drillInPanel.panelConfig.panelName,
      panelId: drillInId,
    };
  });
  return drillInPanelsData;
};

export const onOpenQuickEditWithInternalDrillIns = (
  scope: QuickEditEngineScope,
  rootControlCompRef: CompRef,
  componentControlWrapperProps: any,
  drillInProps?: { onDrillInPanelOpen: any; onDrillInPanelClose: any },
) => {
  const { editorAPI, store } = scope;
  const compPanelDrillIn = {
    panelConfig: {
      panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_COMP_PANEL,
      panelTitle: '',
      onClose: () => {
        store.setCurrentEditedCompId(null);
        if (drillInProps?.onDrillInPanelClose) {
          const { openTime, panel_name, component_id, component_type } =
            store.getLastOpenedDrillInPanelBiData();
          drillInProps.onDrillInPanelClose(
            openTime,
            panel_name,
            component_id,
            component_type,
          );
        }
        store.removeLastDrillInBiData();
      },
      backTooltip: translate('edit_section_back_to_content_tooltip'),
    },
    panelComponent: () =>
      React.createElement(QuickEditCompPanelWrapper, {
        getDrillInAction: (): any => {
          return null;
        },
        isCompInInteractionMode: (compRef: CompRef) =>
          isComponentInInteractionMode(editorAPI, compRef),
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        getPanelStyle: (panelType: QuickEditControlActions) => {
          return {};
        },
        onCompPanelLoaded: (
          compId: string,
          panelName: string,
          compType: string,
        ) => {
          const openTime = performance.now();
          drillInProps?.onDrillInPanelOpen?.(
            openTime,
            panelName,
            compId,
            compType,
          );
          store.setDrillInPanelBiData({
            openTime,
            component_id: compId,
            panel_name: panelName,
            component_type: compType,
          });
        },
      }),
  };

  const quickEditDrillIn = {
    panelConfig: getQuickEditDrillInPanelConfig(scope, rootControlCompRef),
    panelComponent: () =>
      React.createElement(QuickEditDrillInPanelWrapper, {
        drillInPanelIndex: 1,
        getDrillInPanelData: (drillInPanelIndex: number) =>
          store.getDrillInPanels()[drillInPanelIndex],
        onDrillInPanelLoaded: (
          compId: string,
          panelName: string,
          compType: string,
        ) => {
          const openTime = performance.now();
          drillInProps?.onDrillInPanelOpen?.(
            openTime,
            panelName,
            compId,
            compType,
          );
          store.setDrillInPanelBiData({
            openTime,
            component_id: compId,
            panel_name: panelName,
            component_type: compType,
          });
        },
        ...componentControlWrapperProps,
      }),
  };

  const drillInUniqueIds = store.getMainPanelDrillInUniqueIds();
  const drillInPanelsData = contributeMultipleDrillInPanels(
    scope,
    [compPanelDrillIn, quickEditDrillIn],
    drillInUniqueIds,
  );
  store.setMainPanelDrillInUniqueIds(drillInUniqueIds);
  store.setDrillInPanels(
    drillInPanelsData.map((data) => ({ panelData: data })),
  );
};

const openDrillInPanel = (
  scope: QuickEditEngineScope,
  uniqueId: string,
  updatedConfig?: Partial<DrillInPanelConfig>,
  drillInPanels?: Array<DrillInContribution>,
) => {
  const { store } = scope;
  const zIndex = store.getActiveDrillInUniqueIds().length + 1;
  store.openDrillIn(uniqueId, zIndex, updatedConfig);
  if (drillInPanels) {
    const drillInIds = store.getActiveDrillInDrillInUniqueIds();
    const drillInPanelsData = contributeMultipleDrillInPanels(
      scope,
      drillInPanels,
      drillInIds,
    );
    store.updateDrillInConfig(uniqueId, {
      drillInUniqueIds: drillInIds,
    });
    return drillInPanelsData;
  }
};

const getDrillInPanelByName = (
  scope: QuickEditEngineScope,
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
) => {
  const panels = scope.store
    .getDrillInPanels()
    .filter((drillInPanel) => drillInPanel.panelData.panelName === panelName);
  return panels;
};

export const openComponentPanelDrillInInternal = (
  scope: QuickEditEngineScope,
  title?: string,
) => {
  const compPanelId = getDrillInPanelByName(
    scope,
    WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_COMP_PANEL,
  )[0].panelData.panelId;
  openDrillInPanel(scope, compPanelId, {
    panelTitle: title,
  });
};

const getDrillInPanelTitle = (
  scope: QuickEditEngineScope,
  compRef: CompRef,
) => {
  let compRefForDisplayName = compRef;
  if (
    !QUICK_EDIT_COMPONENTS_TO_DRILL_IN_WITH_OWN_DISPLAY_NAME.has(
      scope.editorAPI.components.getType(compRef),
    )
  ) {
    compRefForDisplayName = scope.editorAPI.components.getContainer(
      scope.editorAPI.components.getContainer(compRef),
    );
  }
  return scope.editorAPI.components.getDisplayName(compRefForDisplayName);
};

export const openQuickEditDrillInInternal = (
  scope: QuickEditEngineScope,
  compRef: CompRef,
  componentControlWrapperProps: any,
  drillInProps?: { onDrillInPanelOpen: any; onDrillInPanelClose: any },
) => {
  const drillInTitle = getDrillInPanelTitle(scope, compRef);
  const quickEditDrillInPanelId = getDrillInPanelByName(
    scope,
    WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_DRILL_IN,
  ).filter((drillInPanel) => !drillInPanel.compRef)[0].panelData.panelId;
  scope.store.updateDrillInPanel(quickEditDrillInPanelId, compRef);
  const quickEditDrillInIndex = scope.store.getDrillInPanels().length;
  const quickEditDrillIn = {
    panelConfig: getQuickEditDrillInPanelConfig(scope, compRef, drillInProps),
    panelComponent: () =>
      React.createElement(QuickEditDrillInPanelWrapper, {
        drillInPanelIndex: quickEditDrillInIndex,
        getDrillInPanelData: (drillInPanelIndex: number) =>
          scope.store.getDrillInPanels()[drillInPanelIndex],
        onDrillInPanelLoaded: (
          compId: string,
          panelName: string,
          compType: string,
        ) => {
          const openTime = performance.now();
          drillInProps?.onDrillInPanelOpen?.(
            openTime,
            panelName,
            compId,
            compType,
          );
          scope.store.setDrillInPanelBiData({
            openTime,
            component_id: compId,
            panel_name: panelName,
            component_type: compType,
          });
        },
        ...componentControlWrapperProps,
      }),
  };
  const backTooltip =
    quickEditDrillInIndex === NUMBER_OF_FIRST_LEVEL_DRILL_IN_PANELS
      ? { backTooltip: translate('edit_section_back_to_content_tooltip') }
      : {
          backTooltip: translate('edit_section_back_to_drillin_tooltip', {
            componentName: drillInTitle,
          }),
        };
  const drillInPanelsData = openDrillInPanel(
    scope,
    quickEditDrillInPanelId,
    { ...backTooltip, panelTitle: drillInTitle },
    [quickEditDrillIn],
  );
  scope.store.addDrillInPanels(
    drillInPanelsData.map((data) => ({ panelData: data })),
  );
};

const getQuickEditDrillInPanelConfig = (
  scope: QuickEditEngineScope,
  rootCompRef: CompRef,
  drillInProps?: { onDrillInPanelOpen: any; onDrillInPanelClose: any },
) => ({
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_DRILL_IN,
  panelTitle: '',
  onClose: () => {
    const { store } = scope;
    const quickEditDrillInPanels = getDrillInPanelByName(
      scope,
      WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES.QUICK_EDIT_DRILL_IN,
    ).filter((drillInPanel) => !!drillInPanel.compRef);
    const uniqueId =
      quickEditDrillInPanels[quickEditDrillInPanels.length - 1].panelData
        .panelId;
    store.removeDrillInPanel();
    store.updateDrillInPanel(uniqueId, null);
    store.setCurrentEditedCompId(null);

    if (drillInProps?.onDrillInPanelClose) {
      const { openTime, panel_name, component_id, component_type } =
        store.getLastOpenedDrillInPanelBiData();
      drillInProps.onDrillInPanelClose(
        openTime,
        panel_name,
        component_id,
        component_type,
      );
    }
    store.removeLastDrillInBiData();
  },
  backTooltip: translate('edit_section_back_to_content_tooltip'),
});

export const isDrillInOpen = (scope: QuickEditEngineScope) => {
  return Boolean(scope.store.getActiveDrillInUniqueIds().length);
};

export const discardAllDrillInPanels = (scope: QuickEditEngineScope) => {
  const { store, drillInContentSlot } = scope;
  store.removeAllDrillInPanels();
  drillInContentSlot.discardBy(() => true);
};

export const closeAllDrillInPanels = (scope: QuickEditEngineScope) => {
  const { drillInContentSlot, store } = scope;
  const mainPanelDrillInUniqueIds = store.getMainPanelDrillInUniqueIds();
  const mainPanelDrillInsConfigs = mainPanelDrillInUniqueIds.map(
    (id: string) => {
      return store.getContributedDrillInPanels()[id];
    },
  );
  store.removeAllDrillInPanels();
  mainPanelDrillInsConfigs.forEach((panelConfig) => {
    store.contributeDrillInPanel({
      ...panelConfig,
      isOpen: false,
      zIndex: null,
    });
  });
  drillInContentSlot.discardBy(
    (item: any) => !mainPanelDrillInUniqueIds.includes(item.uniqueId),
  );
};

import React from 'react';
import _ from 'lodash';

import * as util from '#packages/util';

interface MobileFrameComponentProps {
  frameStyle?: any;
  className?: string;
  frameless?: boolean;
  noShadow?: boolean;
  onMobileDeviceBtnClick?: (event: any) => void;
}

export const MobileFrameComponent = React.forwardRef<
  HTMLDivElement,
  MobileFrameComponentProps
>((props, ref) => {
  return (
    <div style={props.frameStyle} className={props.className} ref={ref}>
      {/*TODO is the block-layer bottom is needed? Check it for the new layout */}
      <div className="block-layer bottom" />
      <div className="mobile-frame-top" />
      <div key="mobile-site-shadow" className="site-shadow" />
      <div
        onClick={props.onMobileDeviceBtnClick}
        className="mobile-frame-bottom"
      />
    </div>
  );
});

MobileFrameComponent.displayName = 'MobileFrameComponent';

interface MobileFrameVerticalScrollWrapperProps {
  withShadowExtraSpace?: boolean;
  withFrameExtraSpace?: boolean;
}

const MobileFrameVerticalScrollWrapper: React.FC<
  MobileFrameVerticalScrollWrapperProps
> = (props) => {
  const className = util.cx({
    'mobile-frame-scroll-wrapper': true,
    'with-shadow-extra-space': props.withShadowExtraSpace,
    'with-frame-extra-space': props.withFrameExtraSpace,
  });

  return (
    <div className={className}>
      <div className="mobile-frame-scroll-container">{props.children}</div>
      <div className="mobile-frame-scroll-cover" />
    </div>
  );
};

export const NewWorkspaceMobileFrameComponent = React.forwardRef<
  HTMLDivElement,
  MobileFrameComponentProps
>((props, ref) => {
  const withoutCustomStyle = _.isEmpty(props.frameStyle);

  return (
    <MobileFrameVerticalScrollWrapper
      withShadowExtraSpace={withoutCustomStyle}
      withFrameExtraSpace={withoutCustomStyle && !props.frameless}
    >
      <div style={props.frameStyle} className={props.className} ref={ref}>
        <div className="mobile-frame-top" />
        {props.noShadow ? null : <div className="site-shadow" />}
        <div
          onClick={props.onMobileDeviceBtnClick}
          className="mobile-frame-bottom"
        />
      </div>
    </MobileFrameVerticalScrollWrapper>
  );
});

NewWorkspaceMobileFrameComponent.displayName =
  'NewWorkspaceMobileFrameComponent';

import { translate } from '#packages/i18n';
import { events } from '#packages/coreBi';
import { sections as sectionsUtils } from '#packages/util';
import { PAGE_SECTIONS_EDITOR_DATA_NAMESPACE } from '../constants';
import {
  errorReporter,
  getIsTPAPage,
  getMigrationOrigin,
  isAnchor,
  isPredictionDisabled,
} from '../utils';
import {
  composeSectionsFromPageChildren,
  getPredictedGrouping,
  normalizeAndValidateGrouping,
  groupMissedComponents,
  collapseRedundantGroups,
} from '../grouping';
import { MigrationFlow } from '../types';

import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from '../scope';

export interface GroupingItem {
  name?: string;
  contentRole?: string;
  children: string[];
}

function getGroupingByContentRole(
  scope: Scope,
  pageRef: CompRef,
  rootComponents: CompRef[],
): GroupingItem[] {
  const { documentServices } = scope.editorAPI;
  const {
    components: { features },
  } = documentServices;

  const grouping = rootComponents
    .filter((compRef: CompRef) => !isAnchor(documentServices, compRef))
    .map((compRef: CompRef) => {
      const contentRole = features.get(compRef, 'contentRole')?.contentRole;
      const name =
        features.get(compRef, 'naming')?.name ??
        documentServices.pages.getPageTitle(pageRef.id);

      return {
        name,
        contentRole,
        children: [compRef.id],
      };
    });

  // collapse empty TPA sections with undefined content role
  return collapseRedundantGroups(grouping);
}

function getGroupingBySectionizer(
  scope: Scope,
  pageRef: CompRef,
  rootComponents: CompRef[],
): GroupingItem[] {
  const { documentServices } = scope.editorAPI;
  const sectionizerGrouping = documentServices.components.features.get(
    pageRef,
    PAGE_SECTIONS_EDITOR_DATA_NAMESPACE,
  )?.sections;

  if (!sectionizerGrouping) {
    const isTPAPage = getIsTPAPage(documentServices, pageRef);

    if (rootComponents.length >= 2 && !isTPAPage) {
      return getGroupingByHeuristic(scope, pageRef, rootComponents);
    }

    return getSinglePageGrouping(scope, pageRef, rootComponents);
  }

  const grouping = groupMissedComponents(
    documentServices,
    rootComponents,
    sectionizerGrouping,
  );

  const normalizedSectionsList = normalizeAndValidateGrouping(
    documentServices,
    grouping,
    rootComponents,
  );

  return normalizedSectionsList.map((section) => ({
    ...section,
    name: section.name ? translate(section.name) : undefined,
  }));
}

export async function getGroupingByPrediction(
  scope: Scope,
  pageRef: CompRef,
  rootComponents: CompRef[],
  flow?: MigrationFlow,
): Promise<GroupingItem[]> {
  const { documentServices } = scope.editorAPI;

  try {
    const predictionGrouping = await getPredictedGrouping(
      documentServices,
      pageRef.id,
    );

    const grouping = groupMissedComponents(
      documentServices,
      rootComponents,
      predictionGrouping,
    );

    return normalizeAndValidateGrouping(
      documentServices,
      grouping,
      rootComponents,
    );
  } catch (error: MaybeError) {
    scope.editorAPI.bi.event(events.sectionsMigration.PREDICTION_ERROR, {
      flow: flow || MigrationFlow.Editor,
      revision: documentServices.generalInfo.getRevision(),
      errorMessage: error.message || error.statusText,
      origin: getMigrationOrigin(),
    });

    errorReporter(error, {
      pageGrouping: true,
      predictionGrouping: true,
    });

    return getGroupingByHeuristic(scope, pageRef, rootComponents);
  }
}

function getGroupingByHeuristic(
  scope: Scope,
  pageRef: CompRef,
  rootComponents: CompRef[],
): GroupingItem[] {
  const { documentServices } = scope.editorAPI;

  const grouping = composeSectionsFromPageChildren(
    documentServices,
    pageRef,
    rootComponents,
  );

  return normalizeAndValidateGrouping(
    documentServices,
    grouping,
    rootComponents,
  );
}

function getSinglePageGrouping(
  scope: Scope,
  pageRef: CompRef,
  rootComponents: CompRef[],
): GroupingItem[] {
  const { documentServices } = scope.editorAPI;

  return [
    {
      name: documentServices.pages.getPageTitle(pageRef.id),
      contentRole: undefined,
      children: rootComponents.map(({ id }: CompRef) => id),
    },
  ];
}

export async function getPageGrouping(
  scope: Scope,
  pageRef: CompRef,
  flow: MigrationFlow,
): Promise<GroupingItem[]> {
  const { documentServices } = scope.editorAPI;

  const rootComponents = documentServices.components
    .getChildren(pageRef)
    .filter((compRef: CompRef) =>
      sectionsUtils.isSectionableComponent(documentServices, compRef),
    );

  try {
    switch (flow) {
      case MigrationFlow.Template:
        return getGroupingBySectionizer(scope, pageRef, rootComponents);
      case MigrationFlow.ADI:
      case MigrationFlow.PerPageADI:
        return getGroupingByContentRole(scope, pageRef, rootComponents);
      case MigrationFlow.Editor:
      case MigrationFlow.Editor2Fix:
      case MigrationFlow.HeavySite:
      case MigrationFlow.PerPageEditor:
        const isTPAPage = getIsTPAPage(documentServices, pageRef);
        const isHomePage = pageRef.id === documentServices.homePage.get();

        if (rootComponents.length <= 1) {
          return getSinglePageGrouping(scope, pageRef, rootComponents);
        }

        if (isHomePage && !isPredictionDisabled()) {
          return await getGroupingByPrediction(
            scope,
            pageRef,
            rootComponents,
            flow,
          );
        }

        if (isTPAPage) {
          return getSinglePageGrouping(scope, pageRef, rootComponents);
        }

        return getGroupingByHeuristic(scope, pageRef, rootComponents);
    }
  } catch (error) {
    errorReporter(error, {
      pageGrouping: true,
    });

    return getSinglePageGrouping(scope, pageRef, rootComponents);
  }
}

import { Scope } from '../scope';
import {
  setImageAsComponentBackground,
  getMainBackgroundCandidate,
  sendImageToBackgroundEntryPointActionBI,
} from '../api';
import { highlights } from '#packages/stateManagement';
import constants from '#packages/constants';
import { guidUtils } from '#packages/util';

interface ImageBackgroundDispatchProps {
  setImageToBackground: () => void;
  toggleBackgroundTargetHighlight: (shouldHighlightBgTarget: boolean) => void;
}
interface ImageBackgroundStateProps {
  bgCandidateCompType: string;
}
interface ImageBackgroundOwnProps {}

interface ImageToBackgroundProps
  extends ImageBackgroundOwnProps,
    ImageBackgroundStateProps,
    ImageBackgroundDispatchProps {}

const mapStateToProps = (scope: Scope): ImageBackgroundStateProps => {
  const [selectedComponent] = scope.selection.getSelectedComponents();
  const backgroundTargetCompRef = getMainBackgroundCandidate(
    scope,
    selectedComponent,
  );
  const bgCandidateCompType = scope.components.getType(backgroundTargetCompRef);

  return { bgCandidateCompType };
};

const mapDispatchToProps = (scope: Scope): ImageBackgroundDispatchProps => {
  return {
    setImageToBackground: () => {
      const startTime = performance.now();
      const selectedImageData = scope.selection.getSelectedComponentData();

      const [selectedComponent] =
        scope.editorAPI.selection.getSelectedComponents();

      const backgroundTargetCompRef = getMainBackgroundCandidate(
        scope,
        selectedComponent,
      );

      if (backgroundTargetCompRef) {
        setImageAsComponentBackground(
          scope,
          backgroundTargetCompRef,
          selectedImageData,
          startTime,
          {
            origin: 'button',
            action_name: 'set_as_background',
            action_type: 'click',
            correlationId: guidUtils.getGUID(),
          },
        );
      }
    },
    toggleBackgroundTargetHighlight: (shouldHighlightBgTarget) => {
      const [selectedComponent] =
        scope.editorAPI.selection.getSelectedComponents();

      const backgroundTargetCompRef = getMainBackgroundCandidate(
        scope,
        selectedComponent,
      );

      if (backgroundTargetCompRef) {
        sendImageToBackgroundEntryPointActionBI(
          scope,
          backgroundTargetCompRef,
          {
            origin: 'button',
            action_name: 'set_as_background',
            action_type: 'hover',
          },
        );

        if (shouldHighlightBgTarget) {
          scope.editorAPI.store.dispatch(
            highlights.actions.addCompsToHighlights(
              [backgroundTargetCompRef],
              constants.UI.HIGHLIGHTS.TYPES.NORMAL_WITH_OVERLAY,
            ),
          );
        } else {
          scope.editorAPI.store.dispatch(highlights.actions.clearHighlights());
        }
      }
    },
  };
};

export { mapDispatchToProps, mapStateToProps, type ImageToBackgroundProps };

import type { Scope } from '#packages/sections';
import { fixSingleSectionOverheight } from './fixSingleSectionOverheight';
import { removeSectionsGaps } from './removeSectionsGaps';
import { ErrorReporter } from '@wix/editor-error-reporter';

export const enforceSectionsLayoutOnHomepage = async (scope: Scope) => {
  const { editorAPI } = scope;
  const pageRef = editorAPI.pages.getCurrentPage();

  try {
    const isLayoutUpdated = await fixSingleSectionOverheight(
      scope,
      pageRef,
      true,
    );
    if (isLayoutUpdated) {
      await removeSectionsGaps(scope, pageRef);
    }
  } catch (error: MaybeError) {
    error.name = `[SectionsOnBootstrapEnforcement]: ${error.name}`;
    ErrorReporter.captureException(error);
  }
};

import constants, { RCM_ORIGIN } from '#packages/constants';
import {
  sections as sectionsUtils,
  fedopsLogger,
  designData,
} from '#packages/util';
import { utils } from '#packages/sections';
import gfppDataUtils from '../../utils/gfppDataUtils';
import {
  EditorRestrictionsApiKey,
  QuickEditApiKey,
  SwitchLayoutApiKey,
  DesignerPanelsApiKey,
} from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { GFPPActionType } from '@wix/editor-component-types';
import experiment from 'experiment';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const moveInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.MOVE,
);

const isStageOrMoreActions = (origin: RCM_ORIGIN) =>
  [RCM_ORIGIN.ACTIONS_MORE_ACTION, RCM_ORIGIN.STAGE].includes(origin);

const ifSectionsAndSwitchExperience = <T extends (editorAPI: EditorAPI) => any>(
  getAction: T,
) => {
  return (editorAPI: EditorAPI) => {
    if (
      !sectionsUtils.isSectionsEnabled() ||
      !editorAPI.host.getAPI(SwitchLayoutApiKey).isSwitchLayoutAvailable()
    )
      return null;
    return getAction(editorAPI);
  };
};

const ifSectionsAndDesignersScan = <T extends (editorAPI: EditorAPI) => any>(
  getAction: T,
) => {
  return (editorAPI: EditorAPI) => {
    if (
      !sectionsUtils.isSectionsEnabled() ||
      !experiment.isOpen('se_addDesignerSection')
    )
      return null;
    return getAction(editorAPI);
  };
};

const ifComparisonToolExperience = <T extends (editorAPI: EditorAPI) => any>(
  getAction: T,
) => {
  return (editorAPI: EditorAPI) => {
    if (
      !sectionsUtils.isSectionsEnabled() ||
      !editorAPI.host.getAPI(SwitchLayoutApiKey).isComparisonToolEnabled()
    )
      return null;
    return getAction(editorAPI);
  };
};

const ifSectionsExperience = <T extends (editorAPI: EditorAPI) => any>(
  getAction: T,
) => {
  return (editorAPI: EditorAPI) => {
    if (!sectionsUtils.isSectionsEnabled()) return null;
    return getAction(editorAPI);
  };
};

const getSwitchLayoutAction = (isPresetAction: boolean) =>
  ifSectionsAndSwitchExperience(() => {
    return {
      automationId: 'gfpp-button-switch-layout',
      label: isPresetAction ? null : `gfpp_mainaction_section_designs`,
      id: ACTIONS.SWITCH_LAYOUT,
      icon: isPresetAction ? 'sectionLayoutBold' : null,
      tooltip: 'section_action_bar_section_layouts_tooltip',
      onClick: (editorAPI: EditorAPI, compRefs: CompRef[], origin: string) => {
        const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
        switchLayoutAPI.openSwitchLayoutPanel({
          sectionRef: compRefs[0],
          origin,
        });
      },
      isDisabled: (editorAPI: EditorAPI, [compRef]: CompRef[]) => {
        return editorAPI.components.getChildren(compRef).length === 0;
      },
    };
  });

const getDesignersScanPresetAction = () =>
  ifSectionsAndDesignersScan(() => ({
    label: 'Scan Section',
    shouldTranslate: false,
    id: ACTIONS.SCAN_DESIGNER_PRESET,
    onClick: (editorAPI: EditorAPI, compRefs: CompRef[]) => {
      const designerPanelsApi = editorAPI.host.getAPI(DesignerPanelsApiKey);
      designerPanelsApi.openDesignerScanPresetPanel(compRefs[0]);
    },
  }));

const getQuickEditAction = (isRightClick: boolean) =>
  ifSectionsExperience(() => ({
    automationId: 'gfpp-button-edit-section',
    label: isRightClick
      ? `RightClick_Menu_Edit_Section_Label`
      : `gfpp_mainaction_edit_section_label`,
    id: ACTIONS.QUICK_EDIT,
    onClick: (editorAPI: EditorAPI, compRefs: CompRef[], origin: string) => {
      const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
      quickEditAPI.openPanel({
        rootControlCompRef: compRefs[0],
        origin,
      });
    },
  }));

const getSwitchLayoutForComparisonAction = (isPresetAction: boolean) =>
  ifComparisonToolExperience(() => {
    return {
      automationId: 'gfpp-button-switch-layout-comparison',
      label: isPresetAction
        ? null
        : `gfpp_mainaction_section_designs_comparison`,
      icon: isPresetAction ? 'sectionLayoutBold' : null,
      onClick: (editorAPI: EditorAPI, compRefs: CompRef[], origin: string) => {
        const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
        switchLayoutAPI.switchLayoutForComparison(
          {
            sectionRef: compRefs[0],
            origin,
          },
          false,
        );
      },
      isDisabled: (editorAPI: EditorAPI, [compRef]: CompRef[]) => {
        return editorAPI.components.getChildren(compRef).length === 0;
      },
    };
  });

const getChangeBackgroundAction = (editorAPI: EditorAPI) => {
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isClassicSectionGfppVisible = editorRestrictionsApi.allowed(
    'gfpp_classic-section.visible',
  );
  if (editorAPI.isMobileEditor() || !isClassicSectionGfppVisible) return null;

  const selectedSection = editorAPI.sections.getSelectedSectionLike();

  const { label, icon, tooltip, backgroundMediaType } =
    gfppDataUtils.getDataGfppWithBackgroundIcon(editorAPI, selectedSection, {
      label: 'RightClick_Menu_Section_Change_Background_Label',
      tooltip: '',
    });

  return {
    label,
    isApplied: true,
    isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.BACKGROUND),
    onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.BACKGROUND, {
      style: sectionsUtils.getCompPanelOpenPosition(editorAPI),
      biParams: { bg_media_type: backgroundMediaType },
    }),
    isDisabled: (editorAPI: EditorAPI) => editorAPI.zoomMode.isStageZoomMode(),
    automationId: 'gfpp-button-background',
    icon,
    tooltip,
  };
};

const getScrollEffectsAction = (editorAPI: EditorAPI) => {
  return {
    isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.EFFECTS),
    onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.EFFECTS, {
      style: sectionsUtils.getCompPanelOpenPosition(editorAPI),
    }),
    automationId: 'gfpp-button-effects',
    isSupported: (editorAPI: EditorAPI) => {
      const editorRestrictionsApi = editorAPI.host.getAPI(
        EditorRestrictionsApiKey,
      );
      return editorRestrictionsApi.allowed('gfpp_classic-section.visible');
    },
    isDisabled: (editorAPI: EditorAPI, [compRef]: CompRef[]) => {
      return (
        editorAPI.zoomMode.isStageZoomMode() ||
        gfppDataUtils.shouldDisableBackgroundEffectsByDesignData(
          editorAPI,
          compRef,
        )
      );
    },
  };
};

const getRenameAction = ifSectionsExperience((editorAPI) => {
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isClassicSectionGfppVisible = editorRestrictionsApi.allowed(
    'gfpp_classic-section.visible',
  );
  if (!isClassicSectionGfppVisible) return;
  return {
    label: 'RightClick_Menu_Section_Settings_Label',
    automationId: 'gfpp-button-rename-section',
    onClick(editorAPI: EditorAPI, [compRef]: CompRef[], origin: string) {
      utils.openSectionNamePanel(editorAPI, compRef, {
        origin,
      });
    },
    isDisabled: (editorAPI: EditorAPI) => editorAPI.zoomMode.isStageZoomMode(),
  };
});

const getMoveUpAction = ifSectionsExperience(() => ({
  label: 'RightClick_Menu_Section_Move_Up_Label',
  icon: 'sectionMoveUp',
  automationId: 'gfpp-button-section-move-up',
  async onClick(editorAPI: EditorAPI, [compRef]: CompRef[]) {
    moveInteraction.start();
    editorAPI.closeRightClickMenu();
    await editorAPI.sections.moveUp(compRef);
    moveInteraction.end();
  },
  isDisabled: (editorAPI: EditorAPI, [compRef]: CompRef[]) =>
    !editorAPI.sections.canMoveUp(compRef),
}));

const getMoveDownAction = ifSectionsExperience(() => ({
  label: 'RightClick_Menu_Section_Move_Down_Label',
  icon: 'sectionMoveDown',
  automationId: 'gfpp-button-section-move-down',
  async onClick(editorAPI: EditorAPI, [compRef]: CompRef[]) {
    moveInteraction.start();
    editorAPI.closeRightClickMenu();
    await editorAPI.sections.moveDown(compRef);
    moveInteraction.end();
  },
  isDisabled: (editorAPI: EditorAPI, [compRef]: CompRef[]) =>
    !editorAPI.sections.canMoveDown(compRef),
}));

const getMainActions = (
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
  origin: string,
) => {
  let actions;
  const isQuickEditAvailable = editorAPI.host
    .getAPI(QuickEditApiKey)
    .isQuickEditAvailable();
  if (isQuickEditAvailable) {
    actions = gfppDataUtils.isChangeBackgroundWithIconOnlyEnabled()
      ? [
          getDesignersScanPresetAction(),
          getQuickEditAction(false),
          getChangeBackgroundAction,
        ]
      : [
          getDesignersScanPresetAction(),
          getChangeBackgroundAction,
          getQuickEditAction(false),
        ];
  } else {
    actions = [
      getSwitchLayoutAction(false),
      getSwitchLayoutForComparisonAction(false),
      getChangeBackgroundAction,
    ];
  }

  if (isStageOrMoreActions(origin as RCM_ORIGIN)) {
    const quickEditAction = isQuickEditAvailable
      ? [getQuickEditAction(true)]
      : [];
    actions = [
      getMoveUpAction,
      getMoveDownAction,
      ...quickEditAction,
      getSwitchLayoutAction(false),
      getSwitchLayoutForComparisonAction(false),
      getRenameAction,
      getChangeBackgroundAction,
    ];
  }

  return actions.map((getter) => getter(editorAPI)).filter(Boolean);
};

const getMobileMainActions = (
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
  origin: string,
) => {
  let actions = [getChangeBackgroundAction];

  if (isStageOrMoreActions(origin as RCM_ORIGIN)) {
    actions = [getMoveUpAction, getMoveDownAction, ...actions];
  }

  return actions.map((getter) => getter(editorAPI)).filter(Boolean);
};

const getPresetActions = (editorAPI: EditorAPI) => {
  const isQuickEditAvailable = editorAPI.host
    .getAPI(QuickEditApiKey)
    .isQuickEditAvailable();
  const switchLayoutAction = isQuickEditAvailable
    ? getSwitchLayoutAction(true)(editorAPI)
    : null;
  const switchLayoutComparisonAction = isQuickEditAvailable
    ? getSwitchLayoutForComparisonAction(true)(editorAPI)
    : null;
  // if switchLayout actions exist -> create map entry for each that exists
  const switchLayoutActionMapEntry = switchLayoutAction
    ? { [ACTIONS.SWITCH_LAYOUT]: { ...switchLayoutAction } }
    : {};
  const switchLayoutComparisonActionMapEntry = switchLayoutComparisonAction
    ? {
        [ACTIONS.SWITCH_LAYOUT_COMPARISON]: {
          ...switchLayoutComparisonAction,
        },
      }
    : {};
  // add existing switchLayout map entries to presetActions map
  const actions = {
    ...switchLayoutActionMapEntry,
    ...switchLayoutComparisonActionMapEntry,
    [ACTIONS.EFFECTS]: getScrollEffectsAction(editorAPI),
    [ACTIONS.HELP]: {
      helpId: '6654a2b5-f96c-45fc-a675-67ee679bb8b8',
      mobileHelpId: '8037d9d3-53e3-449b-ba57-b19009ce4508',
    },
  };
  return actions;
};

function mobileEnabledActions(
  editorAPI: EditorAPI,
  compRef: CompRef,
): GFPPActionType[] {
  const hasDividers = designData.hasDividersDesign(editorAPI, compRef);
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const shouldIncludeShapeDividers =
    editorRestrictionsApi.allowed('gfpp_classic-section.visible') &&
    hasDividers;

  return (
    [
      ACTIONS.MOBILE_BACKGROUND_SETTINGS,
      ACTIONS.EFFECTS,
      ACTIONS.HIDE,
      ACTIONS.HELP,
    ] as GFPPActionType[]
  ).concat(shouldIncludeShapeDividers ? ACTIONS.DIVIDERS : []);
}

function enabledActions(editorAPI: EditorAPI) {
  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const shouldIncludeShapeDividers = editorRestrictionsApi.allowed(
    'gfpp_classic-section.visible',
  );
  return (
    [
      ACTIONS.SWITCH_LAYOUT,
      ACTIONS.SWITCH_LAYOUT_COMPARISON,
      ACTIONS.EFFECTS,
      ACTIONS.HELP,
    ] as GFPPActionType[]
  ).concat(shouldIncludeShapeDividers ? ACTIONS.DIVIDERS : []);
}

export default {
  untranslatable: true,
  mainActions: getMainActions,
  mobileMainActions: getMobileMainActions,
  presetActions: getPresetActions,
  enabledActions,
  mobileEnabledActions,
};

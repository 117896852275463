import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import { fixedStage } from '#packages/util';

const { undo, redo } = stateManagement.history.actions;

const mapStateToProps = ({ editorAPI }: AnyFixMe) => {
  const idePaneState = editorAPI.developerMode.ui.idePane.getState();
  return {
    disabled:
      idePaneState === constants.DEVELOPER_MODE.CONTAINER_STATES.MAXIMIZED,
    isZoomMode: editorAPI.zoomMode.isInZoomMode(),
    isSitePublished: editorAPI.dsRead?.generalInfo.isSitePublished(),
    undoLastSnapshotParams:
      editorAPI.documentServices?.history.getUndoLastSnapshotParams(),
    redoLastSnapshotParams:
      editorAPI.documentServices?.history.getRedoLastSnapshotParams(),
    topBarStateBIParamValue: fixedStage.getTopBarStateBiParamValue(editorAPI),
  };
};

const mapDispatchToProps = {
  undo,
  redo,
};

export { mapStateToProps, mapDispatchToProps };

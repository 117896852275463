import React from 'react';

import { fedopsLogger, workspace } from '#packages/util';

import connect from './multilingualComponentChangedPanelConnect';
import {
  MultilingualComponentChangedPanelFrame,
  NewWorkspaceMultilingualComponentChangedPanelFrame,
} from './multilingualComponentChangedPanelFrame';

interface MultilingualComponentChangedPanelProps {
  panelName?: string;
  closePanel: (dontShowAgain: boolean) => void;
  sendClosedBi: (buttonName: string, dontShowAgain: boolean) => void;
  undo: () => void;
}

class MultilingualComponentChangedPanel extends React.Component<MultilingualComponentChangedPanelProps> {
  private isNewWorkspace: boolean = workspace.isNewWorkspaceEnabled();

  state = {
    dontShowAgain: false,
  };

  componentWillUnmount() {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MULTILINGUAL.COMPONENT_CHANGED_PANEL_CLOSE,
    );
  }

  closePanel = (button_name: AnyFixMe) => {
    const { dontShowAgain } = this.state;
    this.props.closePanel(dontShowAgain);
    this.props.sendClosedBi(button_name, dontShowAgain);

    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.MULTILINGUAL.COMPONENT_CHANGED_PANEL_CLOSE,
    );
  };

  onCancel = () => {
    this.closePanel('keep');
  };

  onClose = () => {
    this.closePanel('x');
  };

  onConfirm = () => {
    this.props.undo();
    this.closePanel('undo');
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  render() {
    const FrameComponent = this.isNewWorkspace
      ? NewWorkspaceMultilingualComponentChangedPanelFrame
      : MultilingualComponentChangedPanelFrame;

    return (
      <FrameComponent
        panelName={this.props.panelName}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        onClose={this.onClose}
        onCancel={this.onCancel}
        onConfirm={this.onConfirm}
        dontShowAgain={this.state.dontShowAgain}
        onDontShowAgainChange={(dontShowAgain) =>
          this.setState({ dontShowAgain })
        }
      />
    );
  }
}

export default connect(MultilingualComponentChangedPanel);

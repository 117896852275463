import experiment from 'experiment';

export const getIsHeaderDiscoverabilityExperiment = () => {
  return (
    experiment.getValue('se_headerDiscoverability') === 'B' ||
    experiment.getValue('se_headerDiscoverability') === 'C'
  );
};
export const getIsHeaderScrollPanelExperiment = () => {
  return experiment.isOpen('se_headerScrollPanelDiscoverability');
};

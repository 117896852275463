import * as stateManagement from '#packages/stateManagement';
import { SectionsMigrationApiKey } from '#packages/apis';
import { HELP_ID } from './constants';

import type { MapDispatchToProps, ThunkAction } from 'types/redux';
import type { ForceMigrationOptions } from '#packages/sectionsMigration';

export interface DispatchProps {
  closePanel: (panelName: string) => void;
  openHelpCenter: () => void;
  forceMigrate: (migrationOptions: ForceMigrationOptions) => void;
}

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, any> = {
  closePanel:
    (panelName: string): ThunkAction =>
    (dispatch) => {
      dispatch(stateManagement.panels.actions.closePanelByName(panelName));
    },
  openHelpCenter: (): ThunkAction => (dispatch) =>
    dispatch(stateManagement.panels.actions.openHelpCenter(HELP_ID)),
  forceMigrate:
    (migrationOptions: ForceMigrationOptions): ThunkAction =>
    (dispatch, getState, { host }) => {
      const sectionsMigrationApi = host.getAPI(SectionsMigrationApiKey);
      void sectionsMigrationApi.refreshWithForceMigration(migrationOptions);
    },
};

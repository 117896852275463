import _ from 'lodash';
import * as core from '#packages/core';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import constants from '#packages/constants';
import experiment from 'experiment';
import { translate } from '#packages/i18n';

import React from 'react';
import GeneralContent from './generalContent';
import { cx } from '#packages/util';
import type { InnerPanelProps } from './types';
import { SiteWasSavedPanelCloseSource } from '../save/siteWasSavedPanel';

function template(this: AnyFixMe) {
  return (
    <GeneralContent
      {...this.getGeneralContentProps()}
      className={cx(
        `connect-domain-content ${this.props.className}`,
        this.getGeneralContentProps()?.className,
      )}
    />
  );
}

const CONNECT_DOMAIN_IN_PROGRESS_PANEL =
  'savePublish.panels.connectDomainInProgress';

const generalContentNoPremiumKey = experiment.isOpen('spanishUpgradeText')
  ? 'UPGRADE_NOW_FOR_SPAIN_ONLY'
  : 'SAVE_PUBLISH_WHATS_NEXT_UPGRADE_LINK';

const newWorkspacePersonalSaleSymbolNames = {
  50: 'connectDomain_50_NewWorkspace',
};

function getPersonalSaleProps(discountPercentage: AnyFixMe) {
  const subtitle = translate(
    'SAVE_PUBLISH_WHATS_NEXT_PERSONAL_SALE_TEXT_DYNAMIC',
    {
      discount: _.toString(discountPercentage),
    },
  );

  const symbolName = util.workspace.isNewWorkspaceEnabled()
    ? newWorkspacePersonalSaleSymbolNames[
        discountPercentage as keyof typeof newWorkspacePersonalSaleSymbolNames
      ]
    : undefined;

  const imageSrc = symbolName
    ? undefined
    : util.media.getMediaUrl(
        `savePublish/panels/content/connectDomain_${discountPercentage}.png`,
      );

  return {
    imageSrc,
    symbolName,
    className: 'personal-sale-campaign',
    subtitle,
  };
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass<InnerPanelProps>({
  displayName: 'connectDomain',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    className: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
    sitePublishedDomain: PropTypes.string,
    currentDomain: PropTypes.string,
    isDomainConnected: PropTypes.bool,
    isFirstPublish: PropTypes.bool,
    isSitePremium: PropTypes.bool,
    isFirstSave: PropTypes.bool,
    actionCallback: PropTypes.func,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/values
    flowId: PropTypes.oneOf(_.values(constants.CONNECT_DOMAIN.FLOWS))
      .isRequired,
  },
  getGeneralContentProps() {
    const editorAPI = this.getEditorAPI();
    const props = {
      className: '',
      symbolName: util.workspace.isNewWorkspaceEnabled()
        ? 'connectDomain_NewWorkspace'
        : 'connectDomain',
      mainTitle: translate('SAVE_SITE_SAVED_WHATS_NEXT_LABEL'),
      title: translate('SAVE_PUBLISH_WHATS_NEXT_CONNECT_LINE1'),
      actionLabel: translate(
        this.props.isSitePremium
          ? 'SAVE_PUBLISH_WHATS_NEXT_CONNECT_LINK'
          : generalContentNoPremiumKey,
      ),
      shouldTranslate: false,
      actionCallback: this.panelAction,
      subtitle: translate('SAVE_PUBLISH_WHATS_NEXT_CONNECT_LINE2'),
    };

    if (
      editorAPI.campaigns.personalSale.isActiveCampaign() &&
      !this.props.isSitePremium
    ) {
      const discountPercentage =
        editorAPI.campaigns.personalSale.getDiscountValue();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(props, getPersonalSaleProps(discountPercentage));
    }

    return props;
  },
  panelAction() {
    if (this.props.actionCallback) {
      this.props.actionCallback();
    }
    this.props.closePanel({
      closeSource:
        SiteWasSavedPanelCloseSource.InnerPanelConnectDomainActionLink,
    });

    const isPublishedSiteDomainInProgress = this.isSiteBeingConnectedToDomain();
    if (isPublishedSiteDomainInProgress) {
      this.openConnectDomainInProgressPanel();
    } else if (!this.props.isDomainConnected && this.props.isSitePremium) {
      this.getEditorAPI().account.openConnectDomain(this.props.flowId);
    } else {
      this.getEditorAPI().account.upgrade(this.getUpgradeReferralCode());
    }
  },
  getUpgradeReferralCode() {
    let referralCode = 'edhtml_';

    switch (this.props.flowId) {
      case constants.CONNECT_DOMAIN.FLOWS.PUBLISH:
        const publishType = this.props.isFirstPublish
          ? 'FIRST_PUBLISH'
          : 'SECOND_PUBLISH';
        referralCode += `PUBLISH_FLOW_${publishType}`;
        break;
      case constants.CONNECT_DOMAIN.FLOWS.SAVE:
        referralCode += 'SAVE_FLOW_SECOND_SAVE_AND_PUBLISHED';
        break;
      default:
        referralCode = null;
    }

    return referralCode;
  },
  isSiteBeingConnectedToDomain() {
    const { sitePublishedDomain } = this.props;
    const { dsRead } = this.getEditorAPI();

    return (
      this.isUnconnectedDomainPremium() &&
      util.domain.isDomainConnected(dsRead, sitePublishedDomain)
    );
  },
  isUnconnectedDomainPremium() {
    return !this.props.isDomainConnected && this.props.isSitePremium;
  },
  openConnectDomainInProgressPanel() {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.openPanel(
      CONNECT_DOMAIN_IN_PROGRESS_PANEL,
      {
        connectedDomainURL: this.props.sitePublishedDomain,
        currentPublicURL: this.props.currentDomain,
      },
      true,
    );
  },
  render: template,
});

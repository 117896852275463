import { fedopsLogger } from '#packages/util';

import { createMenuApi } from '../../API/menuAPI';
import { PAGES_MENU_ID } from '../../constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { MenuItem } from 'types/documentServices';

const {
  INTERACTIONS: { CUSTOM_MENUS_SETUP },
} = fedopsLogger;

export const transformPagesParentsToFolderParents = (
  menuItems: MenuItem[],
): MenuItem[] =>
  menuItems.reduce((acc: MenuItem[], menuItem) => {
    const children = menuItem.items;

    if (menuItem.link && children.length > 0) {
      const { link, id, ...folder } = menuItem;
      const menuItemWithoutChildren = {
        ...menuItem,
        items: [] as AnyFixMe,
      };

      return [
        ...acc,
        {
          ...(folder as MenuItem),
          items: [menuItemWithoutChildren, ...children],
        },
      ];
    }

    return [...acc, menuItem];
  }, []);
/**
 * I the new menus structure it is not allowed to have pages as folders
 * We creating folder from every page with same name and placing items and page itself inside
 * Pages without children and folders are remaining untouched
 * */
export const fixPagesMenuSubItems = async (editorAPI: EditorAPI) => {
  fedopsLogger.interactionStarted(CUSTOM_MENUS_SETUP.FIX_PAGES_MENUS_SUB_ITEMS);
  const menuAPI = createMenuApi(editorAPI);
  const pagesMenu = menuAPI.getMenu(PAGES_MENU_ID);

  const languageCodes = editorAPI.dsRead.language
    .get()
    .map((language) => language.code);

  // update for main language
  const newItems = transformPagesParentsToFolderParents(pagesMenu.items);
  menuAPI.replaceMenuItems(PAGES_MENU_ID, newItems);
  fedopsLogger.interactionEnded(CUSTOM_MENUS_SETUP.FIX_PAGES_MENUS_SUB_ITEMS);

  await new Promise<void>((resolve) =>
    editorAPI.waitForChangesApplied(resolve),
  );

  if (languageCodes.length === 0) {
    return;
  }

  const appliedMainLanguageItems = menuAPI.getMenu(PAGES_MENU_ID).items;

  fedopsLogger.interactionStarted(
    CUSTOM_MENUS_SETUP.FIX_PAGES_MENUS_SUB_ITEMS_MULTILINGUAL,
  );

  // update for every secondary language
  languageCodes.forEach((languageCode) => {
    const pagesMenuForLanguage = editorAPI.multilingual.menu.get(
      languageCode,
      PAGES_MENU_ID,
    );

    /**
     * For some reason, it is possible, that not every language has a pages menu
     * @see https://jira.wixpress.com/browse/WEED-22293
     * */
    if (!pagesMenuForLanguage) {
      return;
    }

    const newMenuItemsForLanguage = transformPagesParentsToFolderParents(
      pagesMenuForLanguage.items,
    );

    /**
     * If lengths are different then something went wrong
     * Just return will lead to less damage
     * In secondary languages folder will be not translated
     * */
    if (newMenuItemsForLanguage.length !== appliedMainLanguageItems.length) {
      return;
    }

    /**
     * We need to sync translation with new folders
     * if in main language label of the folder is equal to label of the first child
     * We will apply new name to the folder in secondary lang
     * */
    const mergedItems = newMenuItemsForLanguage.map((item, index) => {
      const itemFromMainLang = appliedMainLanguageItems[index];
      // only folders
      if (item.link) {
        return item;
      }
      // when first child's label in primary language is the same as folder's
      if (itemFromMainLang.label !== itemFromMainLang?.items[0]?.label) {
        return item;
      }
      // and folders labels are the same in both languages
      if (itemFromMainLang.label !== item.label) {
        return item;
      }

      return {
        ...item,
        id: itemFromMainLang.id,
        label: item?.items[0]?.label || item.label,
      };
    });

    editorAPI.multilingual.menu.update(languageCode, PAGES_MENU_ID, {
      items: mergedItems,
    });
  });

  fedopsLogger.interactionEnded(
    CUSTOM_MENUS_SETUP.FIX_PAGES_MENUS_SUB_ITEMS_MULTILINGUAL,
  );

  await new Promise<void>((resolve) =>
    editorAPI.waitForChangesApplied(resolve),
  );
};

import experiment from 'experiment';

export const isBusinessDescriptionFromFunnelEnabled = (): boolean =>
  experiment.isOpen('se_takeBusinessDescriptionFromFunnel');

export const isInjectionByDefaultEnabled =
  experiment.getValue('se_templateInjectionByDefault') !== 'A';

export const isInjectionOnCloseEnabled = (): boolean =>
  experiment.getValue('se_templateInjectionByDefault') === 'B';

export const isInjectionWithoutFormEnabled = (): boolean =>
  experiment.getValue('se_templateInjectionByDefault') === 'C';

import _ from 'lodash';
import constants from '#packages/constants';
import * as boxSlideshow from '#packages/boxSlideshow';
import { utils as multiStateBoxUtils } from '#packages/multiStateBox';
import { utils as tabsUtils } from '#packages/tabs';
import * as stateManagement from '#packages/stateManagement';
import * as dragUtils from '../../../utils/mouseMoveActions/dragUtils';
import { arrayUtils, controlsUtils } from '#packages/util';
import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import { BaseDragApiKey } from '#packages/apis';
import { onViewStateChange, getViewStates } from './widgetViewState';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';

const MULTI_STATE_BOX_TYPE = 'wixui.MultiStateBox';
const TABS_BOX_TYPE = 'wixui.Tabs';

const { FOCUS_BOX } = constants.COMP_META_DATA.CONTROLS;
const { boxSlideShowUtils } = boxSlideshow.utils;

const { getPreviewPosition, getMousePosition } =
  stateManagement.domMeasurements.selectors;
const { getFocusedContainer, getSelectedCompsRefs, getContainerTabDefinition } =
  stateManagement.selection.selectors;

const LABEL_OFFSET_LEFT = 1;
const LABEL_OFFSET_TOP = -16;

function getComponentUIColor(editorAPI: AnyFixMe, comps: AnyFixMe) {
  if (
    editorAPI.components.isShowOnAllPages(comps) ||
    editorAPI.components.isShowOnSomePages(comps)
  ) {
    return constants.COMPONENT_UI_COLORS.ORANGE;
  }

  return constants.COMPONENT_UI_COLORS.BLUE;
}

function getComponentUITheme(selectedCompIsChildOfFocused: AnyFixMe) {
  return selectedCompIsChildOfFocused
    ? constants.COMPONENT_UI_THEMES.LIGHT
    : constants.COMPONENT_UI_THEMES.DARK;
}

export interface TabDef {
  modeId: string;
  type: string;
  label: string;
  params: any;
  active: boolean;
}

interface CompTypeModeHandlerEntry {
  [compType: string]: (focusedContainer: CompRef, tabDef: TabDef) => void;
}

function getMapCompTypeToModeHandler(
  editorAPI: EditorAPI,
): CompTypeModeHandlerEntry {
  return {
    [constants.COMP_TYPES.HOVER_BOX]: (
      focusedContainer: CompRef,
      tabDef: TabDef,
    ) => {
      editorAPI.bi.event(coreBi.events.interactions.hoverbox_change_tab, {
        component_id: focusedContainer.id,
        component_type: constants.COMP_TYPES.HOVER_BOX,
        tab_name: translate(tabDef.label),
      });
    },
  };
}

const mapStateToProps = (
  { editorAPI, state, dsRead }: AnyFixMe,
  props: AnyFixMe,
) => {
  const focusedContainer = props.focusedContainer || getFocusedContainer(state);
  const selectedComponents = getSelectedCompsRefs(state);
  const focusedContainerType = editorAPI.components.getType(focusedContainer);
  const tabsDef =
    props.tabsDef ||
    getContainerTabDefinition(focusedContainer, dsRead, state, editorAPI);

  const [containerToBeDragged] = dragUtils.getCompToBeDragged(
    editorAPI,
    arrayUtils.asArray(focusedContainer),
  );

  const containerDragRestrictions =
    editorAPI.getCompDragRestrictions(containerToBeDragged);
  const isMobileEditor = editorAPI.isMobileEditor();
  const isMobileOnlyNonNativeComponent =
    isMobileEditor &&
    editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
      focusedContainer,
    );

  const selectedCompIsChildOfFocused = !_.isEqual(
    _.head(selectedComponents),
    focusedContainer,
  );

  const parentOfFocusedContainerSettings = () => {
    const focusedParent: CompRef =
      editorAPI.components.getContainerOrScopeOwner(focusedContainer);
    const tabsDefParent = getContainerTabDefinition(
      focusedParent,
      dsRead,
      state,
      editorAPI,
    );
    const selectParent = () => {
      editorAPI.selection.selectComponentByCompRef(focusedParent);
    };
    const parentContainerLayout: CompLayout =
      editorAPI.components.layout.stage.getRelativeToScreen(focusedParent);

    return {
      focusedParent,
      tabsDefParent,
      selectParent,
      parentContainerLayout,
    };
  };

  return {
    focusedContainer,
    focusedContainerType,
    containerToBeDragged,
    canDragContainer:
      containerDragRestrictions.horizontallyMovable ||
      containerDragRestrictions.verticallyMovable,
    containerLayout:
      editorAPI.components.layout.stage.getRelativeToScreen(focusedContainer),
    previewPosition: getPreviewPosition(state),
    componentUIColor: getComponentUIColor(editorAPI, focusedContainer),
    componentUITheme: getComponentUITheme(selectedCompIsChildOfFocused),
    selectedCompIsChildOfFocused,
    isMobileEditor,
    isShowOnFixedPosition: editorAPI.components.layout.isShowOnFixedPosition([
      focusedContainer,
    ]),
    modesAvailableInView:
      editorAPI.components.modes.getComponentModesAvailableInView(
        focusedContainer,
      ),
    modes: editorAPI.components.modes.getModes(focusedContainer),
    tabsDef,
    isMobileOnlyNonNativeComponent,
    activeModeIds:
      editorAPI.components.modes.getComponentActiveModeIds(focusedContainer),
    ...parentOfFocusedContainerSettings(),
    viewStates: getViewStates(editorAPI, focusedContainer),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;
const getEditorState = (dispatch: AnyFixMe, getState: AnyFixMe) => getState;

const mapDispatchToProps = (dispatch: AnyFixMe, props: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  const getState = dispatch(getEditorState);
  const focusedContainer =
    props.focusedContainer || getFocusedContainer(getState());

  const delegatedCompRef = controlsUtils.getFirstControllableComponent(
    editorAPI,
    focusedContainer,
    FOCUS_BOX,
  );

  const focusedContainerType: string =
    editorAPI.components.getType(focusedContainer);

  function getContainerCompLabelStyles(editorAPI: EditorAPI) {
    return {
      left: editorAPI.isMobileEditor() ? 0 : LABEL_OFFSET_LEFT,
      top: LABEL_OFFSET_TOP,
    };
  }

  const focusedContainerTypeMapper: Record<
    string,
    {
      changeState: (
        editorAPI: EditorAPI,
        compRef: CompRef,
        index: number,
      ) => void;
    }
  > = {
    [MULTI_STATE_BOX_TYPE]: multiStateBoxUtils,
    [TABS_BOX_TYPE]: tabsUtils,
  };

  const { changeState } =
    focusedContainerTypeMapper[focusedContainerType] ?? boxSlideShowUtils;

  const onSelectIndex = (index: number) =>
    changeState(editorAPI, delegatedCompRef, index);

  return {
    selectFocusedContainer() {
      editorAPI.selection.selectComponentByCompRef(focusedContainer);
    },
    afterComponentModeActivation: (tabDef: TabDef) => {
      const handler =
        getMapCompTypeToModeHandler(editorAPI)[focusedContainerType];
      if (_.isFunction(handler)) {
        handler(focusedContainer, tabDef);
      }
    },

    getBaseDrag: () => editorAPI.host.getAPI(BaseDragApiKey),
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    activateComponentMode: editorAPI.components.modes.activateComponentMode,
    deactivateComponentMode: editorAPI.components.modes.deactivateComponentMode,
    closePanelByName: editorAPI.panelManager.closePanelByName,
    selectComponentByCompRef: editorAPI.selection.selectComponentByCompRef,
    biError: editorAPI.dsActions.bi.error,
    containerCompDisplayName:
      editorAPI.components.getDisplayName(focusedContainer),
    containerCompLabelStyles: getContainerCompLabelStyles(editorAPI),
    registerMouseMoveAction: editorAPI.mouseActions.registerMouseMoveAction,
    getMousePosition: (event: AnyFixMe) => getMousePosition(editorAPI, event),
    onNext: () => boxSlideShowUtils.moveNextSlide(editorAPI, delegatedCompRef),
    onPrev: () => boxSlideShowUtils.movePrevSlide(editorAPI, delegatedCompRef),
    onSelectIndex,
    onViewStateChange: (state: string) => {
      onViewStateChange(editorAPI, focusedContainer, state);
    },
  };
};

export { mapStateToProps, mapDispatchToProps };

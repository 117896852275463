import * as util from '#packages/util';

export const SITE_READINESS_URL = `${util.serviceTopology.editorRootUrl}_serverless/editor-client-service/site-readiness`;

export const TRANSLATION_KEYS = {
  ENTRY_TITLE: 'readiness_check_entry_title',
  ENTRY_BODY: 'readiness_check_entry_body',
  ENTRY_BUTTON: 'readiness_check_entry_button',
  LOADER_TEXT: 'readiness_check_loader_text',
  RESULTS_TITLE: 'readiness_check_results_title',
  RESULTS_SUBTITLE: 'readiness_check_results_subtitle',
  RESULTS_TEXT_SECTION_TITLE: 'readiness_check_results_text_section_title',
  RESULTS_TEXT_SECTION_BODY: 'readiness_check_results_text_section_body',
  RESULTS_IMAGES_SECTION_TITLE: 'readiness_check_results_images_section_title',
  RESULTS_IMAGES_SECTION_BODY: 'readiness_check_results_images_section_body',
  RESULTS_LINKS_SECTION_TITLE: 'readiness_check_results_links_section_title',
  RESULTS_LINKS_SECTION_BODY: 'readiness_check_results_links_section_body',
  RESULTS_DOMAIN_SECTION_TITLE: 'readiness_check_results_domain_section_title',
  RESULTS_DOMAIN_SECTION_BODY: 'readiness_check_results_domain_section_body',
  RESULTS_NEXT_STEP_LABEL: 'readiness_check_results_next_step_label',
  RESULTS_NEXT_STEP_BUTTON: 'readiness_check_results_next_step_button',
};

export const PANEL_NAMES = {
  CHECK_READINESS: 'siteReadiness.panels.checkReadinessPanel',
  SITE_READINESS: 'siteReadiness.panels.siteReadinessPanel',
};

import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey, MouseActionsApiKey } from '#packages/apis';
import { createMouseActionsApi } from './mouseActionsWrapper';

export const MouseActionsEntrypoint: EntryPoint = {
  name: 'MouseActionsApi',
  declareAPIs: () => [MouseActionsApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(MouseActionsApiKey, () => createMouseActionsApi(shell));
  },
};

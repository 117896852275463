import React, { PureComponent } from 'react';
import _ from 'lodash';
import * as coreBi from '#packages/coreBi';
import type { SendBiFunction } from 'types/bi';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import { withRestrictions } from '#packages/editorRestrictions';
import withSendBi from '../hocs/withSendBi';
import Button from '../topBarButton/topBarButtonWithTooltip';
import TooltipContent from '../tooltipContent';

import { mapStateToProps, mapDispatchToProps } from './searchMappers';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const sendBiOnClick = (
  sendBi: SendBiFunction,
  isSitePublished: boolean,
  topBarStateBIParamValue: string,
): void => {
  util.editorWixRecorder.addLabel('search top bar clicked');
  sendBi(coreBi.events.topbar.top_bar_click, {
    category: 'search',
    is_published: isSitePublished,
    state: topBarStateBIParamValue,
  });
};

const sendBiOnMouseEnter = (sendBi: SendBiFunction): void => {
  sendBi(coreBi.events.editorSearch.topBarTooltipShown, {
    clickType: 'hover',
  });
};

const TOOLTIP_DATA = {
  title: 'Editor_Search_Icon_Tooltip_Title',
  text: 'Editor_Search_Icon_Tooltip_Description',
};

interface TopBarSearchProps {
  className?: string;
  openSearchPanel: () => void;
  sendBi: SendBiFunction;
  toggled: boolean;
  isSitePublished: boolean;
  isLabelShown: boolean;
  topBarStateBIParamValue: string;
  disabled?: boolean;
}

class TopBarSearch extends PureComponent<TopBarSearchProps> {
  handleClick = () => {
    this.props.openSearchPanel();
    sendBiOnClick(
      this.props.sendBi,
      this.props.isSitePublished,
      this.props.topBarStateBIParamValue,
    );
  };

  handleMouseEnter = () => {
    sendBiOnMouseEnter(this.props.sendBi);
  };

  render() {
    const tooltip = this.props.disabled ? null : (
      <TooltipContent tooltipData={TOOLTIP_DATA} />
    );
    const className = util.cx('top-bar-search', this.props.className);
    const label = util.topBar.isNewTopBarEnabled()
      ? translate('Topbar_Searchbar_Text')
      : null;
    const symbolName = util.workspace.isNewWorkspaceEnabled()
      ? 'editorSearchButton_NewWorkspace'
      : 'editorSearchButton';

    return (
      <div className={className}>
        <Button
          disabled={this.props.disabled}
          className={util.cx('top-bar-button-search', {
            minimized: !this.props.isLabelShown,
          })}
          automationId="top-bar-button-editor-search"
          symbolName={symbolName}
          symbolClassName="small-paddings"
          label={this.props.isLabelShown && label}
          toggled={this.props.toggled}
          tooltip={tooltip}
          onClick={this.handleClick}
          onMouseEnter={this.handleMouseEnter}
        />
      </div>
    );
  }
}

const ConnectedTopBarSearch = _.flow(
  withRestrictions('top-bar_search', { withTooltip: false }),
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withSendBi,
)(TopBarSearch);

ConnectedTopBarSearch.pure = TopBarSearch;

export default ConnectedTopBarSearch;

import type { MimeType } from '@wix/santa-editor-utils';

export const supportedFileTypes: { [mimeType in MimeType]: boolean } = {
  'image/jpg': true,
  'image/png': true,
  'image/gif': true,
  'image/jpeg': true,
  'image/jpe': false,
  'image/jfif': false,
  'image/bmp': false,
  'image/heic': false,
  'image/heif': false,
  'image/tiff': false,
  'image/tif': false,
  'image/webp': false,
  'image/jp2': false,
  'image/jpg2': false,
  'image/j2k': false,
  'image/jpf': false,
  'image/jpm': false,
  'image/j2c': false,
  'image/jpc': false,
  'image/jpx': false,
  'image/arw': false,
  'image/srw': false,
  'image/nef': false,
  'image/cr2': false,
  'image/cr3': false,
  'image/crw': false,
  'image/rwl': false,
  'image/rw2': false,
  'image/raw': false,
  'image/raf': false,
  'image/pef': false,
  'image/orf': false,
  'image/mrw': false,
  'image/dng': false,
  'image/sr2': false,
  'image/srf': false,
  'image/kdc': false,
  'image/k25': false,
  'image/dcr': false,
  'image/x3f': false,
  'image/erf': false,
  'image/3fr': false,
  'video/avi': false,
};

export const TRANSLATION_KEYS = {
  UNSUPPORTED_FILE: 'notifications_error_image_UnsupportedFile',
  UNSUPPORTED_FILE_READ_MORE: 'notifications_error_image_UnsupportedFile_cta',
  ERROR_UPLOADING_FILE:
    'notifications_error_image_Can’tCompleteUploadTechnical',
  ERROR_UPLOADING_FILE_READ_MORE:
    'notifications_error_image_Can’tCompleteUploadTechnical_cta',
  UPLOAD_SUCCESS: 'notifications_success_image_upload',
};

export const HELP_IDS = {
  UNSUPPORTED_FILE: '739a2868-2cd6-43ec-9fe3-f7861a64cc72',
  ERROR_UPLOADING_FILE: '818bc2c8-2c70-4e24-aa4f-9f5cbde29de2',
};

import * as stateManagement from '#packages/stateManagement';
import { sections as sectionsUtils } from '#packages/util';

import { getSendBi } from '../../../topBarComponent/topBarMapper';
import {
  mapStateToProps as mapStateToMenuAPIProps,
  mapDispatchToProps as mapDispatchToMenuAPIProps,
} from './helpMenuAPIMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  HelpListItemsOwnProps,
  HelpListItemsStateProps,
  HelpListItemsDispatchProps,
} from './helpMenuStructure';

const mapStateToProps: MapStateToProps<
  HelpListItemsStateProps,
  HelpListItemsOwnProps
> = (...args) => {
  const [{ state, editorAPI }] = args;

  return {
    ...mapStateToMenuAPIProps(...args),

    inInteractionMode:
      stateManagement.interactions.selectors.isInInteractionMode(state),
    hasNewReleasesNotification:
      stateManagement.newReleases.selectors.hasNotification(state),
    isChatVisible: stateManagement.unifiedChat.selectors.isChatVisible(state),
    isChatLoaded: stateManagement.unifiedChat.selectors.isChatLoaded(state),
    isSiteMigratedFromAdi: state.editorIsInit
      ? sectionsUtils.isSiteMigratedFromAdi(editorAPI)
      : false,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  HelpListItemsDispatchProps,
  HelpListItemsOwnProps
> = (dispatch, ownProps) => ({
  ...mapDispatchToMenuAPIProps(dispatch, ownProps),

  sendBi: getSendBi(dispatch),
});

export { mapStateToProps, mapDispatchToProps };

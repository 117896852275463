import React, { type CSSProperties } from 'react';
import _ from 'lodash';
import type { StateMapperArgs } from 'types/redux';
import { getComponentUIColor, getLayout, getLayoutStyle } from './common';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import { cx } from '#packages/util';
import type { CompRef } from 'types/documentServices';
import constants from '#packages/constants';

const COLUMNS_CONTAINER = 'StripColumnsContainer';

const componentsSelectors = stateManagement.components.selectors;
const { getSelectedCompsRefs } = stateManagement.selection.selectors;

type SubCompsData = Array<{ style: CSSProperties; className: string }>;

interface SubCompsProps {
  subCompsData: SubCompsData;
}

class EditBoxSubComps extends React.Component<SubCompsProps> {
  static displayName = 'EditBoxSubComps';
  render() {
    return (
      <>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
        {_.map(this.props.subCompsData, (data, componentIndex) => (
          <div
            key={componentIndex}
            style={data.style}
            className={data.className}
          />
        ))}
      </>
    );
  }
}

function mapStateToProps({
  editorAPI,
  state: editorState,
  dsRead,
}: StateMapperArgs) {
  const comps: CompRef[] = getSelectedCompsRefs(editorState);

  const isMultiselect = util.array.isMultiselect(comps);

  const subComps: CompRef[] = isMultiselect
    ? comps
    : editorAPI.components.getChildren(comps);

  const compType = componentsSelectors.getCompTypeSuffix(comps, dsRead);

  const hasSubComps = compType === COLUMNS_CONTAINER || isMultiselect;

  let subCompsData: SubCompsData = [];
  if (hasSubComps) {
    subCompsData = subComps.map((subComp) => {
      return {
        style: getLayoutStyle(editorAPI, getLayout(editorAPI, subComp)),
        className: cx({
          layout: true,
          'components-ui-color-orange':
            getComponentUIColor(editorAPI, subComp) ===
            constants.COMPONENT_UI_COLORS.ORANGE,
        }),
      };
    });
  }
  return { subCompsData };
}

const ConnectedSubComps = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
)(EditBoxSubComps);

ConnectedSubComps.pure = EditBoxSubComps;

export default ConnectedSubComps;

import * as textControls from '#packages/textControls';
import { gfppModel } from '#packages/gfppData';
import { getLinksHelper } from './textEditorWrapperMapper';
import constants, { TextEditorLocation } from '#packages/constants';
import { quickEditPanelOrigin } from '../consts';
import { scrollToComponent } from '../quickEditUtils';
import { reportTextExecCommand } from '../quickEditBi';
import { EditorRestrictionsApiKey } from '#packages/apis';
import { getIsTitle } from '#packages/quickEditEngine';
import experiment from 'experiment';

import type { CmdState, TextManager } from 'types/data';
import type { QuickEditControlOwnProps } from '@wix/editor-elements-types/quickEditControls';
import type { QuickEditScope } from '../quickEditEntryPoint';

const { QUICKEDIT } = TextEditorLocation;

export const mapStateToProps = (
  { editorAPI }: QuickEditScope,
  { compRef }: QuickEditControlOwnProps,
) => {
  const compText = editorAPI.components.data.get(compRef).text;
  return {
    isTitle: getIsTitle(editorAPI, compRef),
    compText,
    shouldShowAiTextGenerator:
      (experiment.getValue('se_aiTextGeneratorGfppIcon') === 'B' ||
        experiment.getValue('se_aiTextGeneratorGfppIcon') === 'C' ||
        experiment.isOpen('se_gfppAiButtonUI')) &&
      editorAPI.host
        .getAPI(EditorRestrictionsApiKey)
        .allowed('quick-edit_text-ai-text-generator.visible'),
  };
};

export const mapDispatchToProps = (
  { editorAPI, workspaceRightPanelApi, store }: QuickEditScope,
  { compRef }: QuickEditControlOwnProps,
) => {
  const { section_correlation_id, panel_correlation_id } =
    store.getQuickEditBiData();
  return {
    closeAiTextGeneratorPanelIfOpen: () => {
      editorAPI.closeCompPanelIfExists(
        constants.componentPanels.aiTextGenerator,
      );
    },
    openLinkDialogPanel: (cmdState: CmdState, textManager: TextManager) => {
      textControls.textEditingUtils.openLinkDialog(
        editorAPI,
        compRef,
        cmdState,
        textManager.execCommand,
        getLinksHelper,
        textManager,
        {
          origin: quickEditPanelOrigin,
          biOrigin: quickEditPanelOrigin,
          section_correlation_id,
          panel_correlation_id,
        },
      );
    },
    execListCommand: (
      newListType: any,
      cmdState: CmdState,
      textManager: TextManager,
    ) => {
      textControls.textEditingUtils.execListCommand(
        editorAPI,
        newListType,
        textManager.execCommand,
        cmdState,
      );
      reportTextExecCommand(newListType, compRef);
    },
    stopTextEditing: () => {
      editorAPI.text.setCurrentTextEditor(null, QUICKEDIT);
    },
    openEditTextPanelOnStage: async () => {
      await workspaceRightPanelApi.close(quickEditPanelOrigin);
      await scrollToComponent(editorAPI, compRef);
      const compGfppData = gfppModel.getFullComponentGfppData(
        editorAPI,
        [compRef],
        quickEditPanelOrigin,
      );

      const isAiGfppButton =
        (experiment.getValue('se_aiTextGeneratorGfppIcon') === 'B' ||
          experiment.isOpen('se_gfppAiButtonUI')) &&
        experiment.isOpen('se_aiTextGenerator');

      const mainActionIndex = isAiGfppButton ? 1 : 0;

      compGfppData.mainActions[mainActionIndex].onClick(
        editorAPI,
        [compRef],
        quickEditPanelOrigin,
      );
    },
    reportExecCommand: (commandName: string) =>
      reportTextExecCommand(commandName, compRef),
  };
};

import type { EditorAPI } from '#packages/editorAPI';

function isPasteEnabled(editorAPI: EditorAPI) {
  return (
    editorAPI.clipboard.hasItem() &&
    editorAPI.isDesktopEditor() &&
    !editorAPI.components.layout.isPinned(
      editorAPI.selection.getSelectedComponents(),
    )
  );
}

export { isPasteEnabled };

// @ts-nocheck
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as tpaUtils from '../utils/tpaUtils';
import superAppsConstants from '../superApps/superAppsConstants';
import * as tpaAlertsService from './tpaAlertsService';

const isSelectedComponentHybrid = function (editorAPI) {
  const origCompData = editorAPI.selection.getSelectedComponentData();

  return editorAPI.dsRead.tpa.app.isHybrid(origCompData.applicationId);
};

const isSiteSaved = function (editorAPI) {
  return (
    !editorAPI.dsRead.generalInfo.isFirstSave() &&
    !editorAPI.dsRead.generalInfo.isDraft()
  );
};

const getSelectedComponentAppData = function (editorAPI) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  const applicationId = origCompData?.applicationId;
  return editorAPI.dsRead.tpa.app.getData(applicationId);
};

function getDashboardPanelUrl(editorAPI, appData, panelCompId, params = {}) {
  let baseUrl;
  let { applicationId } = appData;

  const bizManagerBaseUrl = util?.serviceTopology?.businessManagerUrl ?? '';
  if (params.isTranslationDashboard) {
    const lang = editorAPI.dsRead.language.current.get();
    const msid = editorAPI.dsRead.generalInfo.getMetaSiteId();
    // TODO JIRA ML-3512: change sidePanelSection to be based on appDefId (appData.appDefinitionId) instead of "business"
    baseUrl = `${bizManagerBaseUrl}/${msid}/multilingual?widgetId=${params.widgetId}&secondaryLanguage=${lang}&sidePanelSection=business`;
    applicationId = editorAPI.dsRead.tpa.app.getDataByAppDefId(
      superAppsConstants.MULTI_LINGUAL_APP_DEF_ID,
    )?.applicationId;
  } else {
    baseUrl = `${bizManagerBaseUrl}/external?appDefId=${appData.appDefinitionId}`;
  }
  const urlParams = {
    url: baseUrl,
    deviceType: 'desktop',
    applicationId,
    origCompId: editorAPI.selection.getSelectedComponentId(),
    origin: params.origin,
    hash: params.state,
    bi: params.bi,
  };

  return editorAPI.dsRead.tpa.app.url.getSettingsModalUrl(
    urlParams,
    panelCompId,
  );
}

const getDashboardAppUrlAsync = function (editorAPI, options, callback) {
  const appData = getSelectedComponentAppData(editorAPI) || {};
  const isHybrid = isSelectedComponentHybrid(editorAPI);
  const isSaved = isSiteSaved(editorAPI);
  const isAppProvisionedOnServer =
    editorAPI.dsRead.tpa.app.isAppProvisionedOnServer(appData.applicationId);

  const onSaveSuccess = function () {
    if (options?.editorVersion === true) {
      callback(getDashboardPanelUrl(editorAPI, appData));
    } else {
      callback(
        tpaUtils.getAppInMyAccountUrl(
          editorAPI.dsRead.generalInfo.getMetaSiteId(),
          appData.appDefinitionId,
        ),
      );
    }
  };

  if (isHybrid && isSaved && isAppProvisionedOnServer) {
    onSaveSuccess();
  } else if (!isSaved || !isAppProvisionedOnServer) {
    // TODO: non hybrid apps can fit here, this hole being used by some non hybrid apps to provision. we need to provide a better way to provision and fix it.
    const onSaveError = function () {
      tpaAlertsService.openSaveFailedAlertForTemplateDashboardUrl(
        editorAPI,
        appData.appDefinitionId,
      );
      callback(null);
    };
    editorAPI.saveManager.saveInBackground(
      onSaveSuccess,
      onSaveError,
      'getDashboardAppUrlAsync',
    );
  } else {
    callback(null);
  }
};

const getEditorGfppData = function (editorAPI) {
  const origCompData = editorAPI.selection.getSelectedComponentData();
  if (origCompData) {
    const appData = editorAPI.dsRead.tpa.app.getData(
      origCompData.applicationId,
    );
    if (appData.appDefinitionId) {
      const marketData = editorAPI.dsRead.tpa.appMarket.getData(
        appData.appDefinitionId,
      );
      if (marketData?.editorGfpp) {
        return marketData.editorGfpp;
      }
    }
  }

  return {
    upgradeText: translate('tpa_package_picker_upgrade_title'),
    openInDashboardText: translate('tpa_open_dashboard'),
  };
};

export {
  getDashboardAppUrlAsync,
  getDashboardPanelUrl as getDashboardUrl,
  getEditorGfppData,
};

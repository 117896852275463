import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';

import type { LayersButtonOwnProps } from './layersButton';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';

export interface LayersButtonStateProps {
  isLayersPanelDisplayed: boolean;
  isStageZoomMode: boolean;
  isEnabled: boolean;
}

export const mapStateToProps: MapStateToProps<
  LayersButtonStateProps,
  LayersButtonOwnProps
> = ({ state, editorAPI }) => {
  return {
    isLayersPanelDisplayed:
      stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state),
    isStageZoomMode: editorAPI.zoomMode.isStageZoomMode(),
    isEnabled: editorAPI.editorIsInit(),
  };
};

export interface LayersButtonDispatchProps {
  toggleLayersPanel: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  LayersButtonDispatchProps,
  LayersButtonOwnProps
> = {
  toggleLayersPanel:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI }) => {
      const origin = 'layers_left_bar';
      const state = editorAPI.store.getState();
      const isLayersPanelDisplayed =
        stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state);

      if (isLayersPanelDisplayed) {
        return dispatch(stateManagement.layersPanel.actions.hideLayersPanel());
      }
      editorAPI.bi.event(coreBi.events.panels.PANEL_OPENED, {
        panel_name: 'panels.toolPanels.layersPanel',
        origin,
      });

      return dispatch(
        stateManagement.layersPanel.actions.showLayersPanel(origin),
      );
    },
};

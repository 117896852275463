// @ts-nocheck
import appointmentFieldConstants from './constants';
import * as tpa from '#packages/tpa';
import _ from 'lodash';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import * as addPanelInfra from '#packages/addPanelInfra';

const installAppointmentApp = (
  editorAPI,
  { structure, coords, preset, dropContainer },
): Promise<void> => {
  const addApp = (resolve, reject, compRef) => {
    const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
    const biInitiator = tpa.constants.BI.initiator.EDITOR;
    const options = {
      originType: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
      disableAddCompleteSave: true,
    };
    const callback = (data) => (data?.success ? resolve(data) : reject(data));
    const platformOrigin = {
      type: isResponsiveEditor() ? EditorType.Responsive : EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        compRef,
        type: InstallationOriginType.AddPanel,
        preset,
        coords,
      },
    };
    tpa.services.tpaAddAppService.addApp(
      editorAPI,
      appointmentFieldConstants.APPOINTMENT_FIELD_APP_ID,
      null,
      null,
      type,
      biInitiator,
      false,
      {},
      options,
      callback,
      platformOrigin,
    );
  };

  return new Promise(function (resolve, reject) {
    return addPanelInfra.addPanelUtils
      .attemptToAddComponent(
        editorAPI,
        structure,
        'developer',
        preset,
        [],
        preset,
        null,
        null,
        {
          position: coords,
          skipHistorySnapshot: true,
          dropContainer,
        },
      )
      .then((compRef) => {
        return addApp(resolve, reject, compRef);
      });
  });
};

export const addAppointment = (editorAPI, args) => {
  const { structure, coords, preset, dropContainer } = args;

  return Promise.resolve(
    installAppointmentApp(editorAPI, {
      structure,
      coords,
      preset,
      dropContainer,
    }),
  );
};

const onAppointmentDrop =
  (editorAPI, preset) => (coords, compDef, dropContainer) =>
    addAppointment(editorAPI, {
      structure: compDef.structure,
      coords,
      dropContainer,
      preset,
    });

const onAppointmentClick = (editorAPI, preset) => (structure) =>
  addAppointment(editorAPI, { structure, coords: null, preset });

const createWidget = (layout, id, controllerType, mobileHints) => ({
  componentType: 'platform.components.AppWidget',
  style: {
    skin: 'platform.components.skins.AppWidgetLoaderSkin',
    style: {},
  },
  id,
  data: {
    type: 'AppController',
    name: 'appointment-field-controller',
    controllerType,
    applicationId: appointmentFieldConstants.APPOINTMENT_FIELD_APP_ID,
    id: 'appointment-field-widget',
    settings: '{}',
  },
  mobileHints,
  layout,
  components: [
    {
      type: 'Container',
      components: [],
      skin: appointmentFieldConstants.skin,
      layout: _.merge(
        {
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        _.pick(layout, ['width', 'height', 'x', 'y']),
      ),
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            controllerId: 'appointment-field-widget',
            isPrimary: true,
            config: '{"isDummyAppointmentField":true}',
            role: 'appointment-field_Role',
          },
        ],
      },
      componentType: appointmentFieldConstants.dummyComponentType,
    },
  ],
});

const getAppointmentWidget = (
  layout,
  mobileLayout,
  id,
  controllerType = appointmentFieldConstants.CONTROLLER_TYPES.APPOINTMENT_FIELD,
  mobileHints,
) =>
  mobileLayout
    ? _.merge({}, createWidget(layout, id, controllerType, mobileHints), {
        mobileStructure: createWidget(mobileLayout, id, controllerType),
      })
    : createWidget(layout, id, controllerType, mobileHints);

export { onAppointmentDrop, onAppointmentClick, getAppointmentWidget };

import type { EditorAPI } from '#packages/editorAPI';
import { translate } from '#packages/i18n';
import { pageReplaceService } from '#packages/platform';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type { PageRef } from 'types/documentServices';
import { DefaultActionTypes } from '../../panel/types';
import type { IAppPageAction } from '../appsPages';
import {
  ConfirmCircle,
  PageAdd,
  ExternalLink,
  Replace,
  Delete,
} from '@wix/wix-ui-icons-common/classic-editor';
import type { PageVariant } from '@wix/platform-editor-sdk';

export const replacerActionsType = {
  CREATE_CUSTOM: 'create_custom_page',
  ADD_PAGE_VARIANT: 'add_page_variant',
  ACTIVE: 'active_page_replacer',
  DELETE: 'delete_page_replacer',
  VIEW_APP_PAGES: 'view_app_pages',
  RENAME_CUSTOM: 'rename_custom_page',
};

const addCreateCustomAction = (editorAPI: EditorAPI, bindedPageId?: string) => {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);

  return {
    title: translate(
      'PLATFORM_flow_customization_page_context_menu_create_custom_page_label',
    ),
    symbol: Replace,
    onClick: async (pageId: string) => {
      await platformAPI.pageReplace.createBlankReplacerPage(
        editorAPI.pages.getReference(bindedPageId ?? pageId) as PageRef,
      );
      pageReplaceService.updateCollapseMenuIfNeeded(
        editorAPI,
        bindedPageId ?? pageId,
        false,
      );
    },
    type: replacerActionsType.CREATE_CUSTOM,
  };
};

const addPageVariantAction = (
  editorAPI: EditorAPI,
  pageTitle: string,
  pageManagingAppDefId: string,
  variants: PageVariant[],
  pageRef: PageRef,
) => {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  return {
    title: translate(
      'PLATFORM_flow_customization_page_context_menu_create_variant_label',
    ),
    symbol: PageAdd,
    onClick: () =>
      platformAPI.pageReplace.openPageVariantsPanel(
        pageTitle,
        variants,
        pageManagingAppDefId,
        pageRef,
      ),
    type: replacerActionsType.ADD_PAGE_VARIANT,
    disabled: !(variants?.length > 0),
    disabledText:
      'PLATFORM_flow_customization_page_context_menu_create_variant_disabled_tooltip',
  };
};

const addActiveAction = (editorAPI: EditorAPI, originalPageId: string) => ({
  title: translate(
    'PLATFORM_flow_customization_page_context_menu_activate_label',
  ),
  symbol: ConfirmCircle,
  onClick: (pageId: string) => {
    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);

    platformAPI.pageReplace.setReplacerPage(
      editorAPI.pages.getReference(originalPageId) as PageRef,
      editorAPI.pages.getReference(pageId) as PageRef,
      true,
    );
  },
  type: replacerActionsType.ACTIVE,
});

const addDeleteAction = (editorAPI: EditorAPI) => ({
  title: translate(
    'PLATFORM_flow_customization_page_context_menu_delete_label',
  ),
  symbol: Delete,
  onClick: (pageId: string) => {
    editorAPI.pages.remove(pageId);
  },
  type: replacerActionsType.DELETE,
});

const addViewAppPagesAction = (
  editorAPI: EditorAPI,
  tabAppDefinitionId: string,
) => {
  const appData =
    editorAPI.dsRead.platform.getAppDataByAppDefId(tabAppDefinitionId);
  return {
    title: translate(
      'PLATFORM_flow_customization_app_page_context_menu_view_in_vertical_label',
      { menu_title: appData?.appDefinitionName },
    ),
    symbol: ExternalLink,
    onClick: () => {
      editorAPI.openPagesPanel({
        selectedTabId: tabAppDefinitionId,
        initialTabId: tabAppDefinitionId,
      });
    },
    type: replacerActionsType.VIEW_APP_PAGES,
  };
};

const isThereVariationForPage = (pageId: string, replacers: string[]) => {
  const pageVariations = replacers?.filter(
    (variationId: string) => variationId !== pageId,
  );
  return pageVariations ? pageVariations.length > 0 : false;
};

const addVariationActionsIfNeeded = (
  editorAPI: EditorAPI,
  pageRef: PageRef,
  pageReplacers: string[],
  replacerOf: string,
  isVariant: boolean,
  pageManagingAppDefId: string,
  tabAppDefinitionId: string,
  defaultActions: Record<DefaultActionTypes, any>,
  pageTitle: string,
): IAppPageAction[] => {
  const actions: IAppPageAction[] = [];
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  if (
    editorAPI.developerMode.isEnabled() &&
    platformAPI.pageReplace.isReplaceable(pageManagingAppDefId, pageRef) &&
    !isThereVariationForPage(pageRef.id, pageReplacers)
  ) {
    actions.push(addCreateCustomAction(editorAPI));
  }
  const pageAvailableVariants =
    platformAPI.pageReplace.getPageAvailableVariants(
      pageManagingAppDefId,
      pageRef as PageRef,
    );
  if (pageAvailableVariants) {
    actions.push(
      addPageVariantAction(
        editorAPI,
        pageTitle,
        pageManagingAppDefId,
        pageAvailableVariants,
        pageRef,
      ),
    );
  }

  if (!pageReplacers && !replacerOf) {
    return actions;
  }

  const isInactive = replacerOf
    ? !platformAPI.pageReplace.isReplacerActive(pageRef)
    : platformAPI.pageReplace.isReplacerActive({
        ...pageRef,
        id: pageReplacers?.[0],
      });

  if (isInactive && !isVariant) {
    actions.push(addActiveAction(editorAPI, replacerOf ?? pageRef.id));
  }
  if (isVariant) {
    actions.push(defaultActions[DefaultActionTypes.RENAME_TYPE]({}));
    actions.push(addDeleteAction(editorAPI));
    return actions;
  }

  if (replacerOf) {
    const { managingAppDefId } = editorAPI.pages.data.get(replacerOf);
    if (pageManagingAppDefId) {
      actions.push(
        addViewAppPagesAction(
          editorAPI,
          pageManagingAppDefId === tabAppDefinitionId
            ? managingAppDefId
            : pageManagingAppDefId,
        ),
      );
    } else {
      actions.push(defaultActions[DefaultActionTypes.RENAME_TYPE]({}));
      actions.push(addDeleteAction(editorAPI));
    }
  }

  return actions;
};

const getReplacersCollapserActions = (
  editorAPI: EditorAPI,
  pageId: string,
  pageManagingAppDefId: string,
  pageTitle: string,
): IAppPageAction[] => {
  const actions: IAppPageAction[] = [];
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const pageRef = editorAPI.pages.getReference(pageId);
  const pageVariants = platformAPI.pageReplace.getPageAvailableVariants(
    pageManagingAppDefId,
    pageRef as PageRef,
  );

  if (pageVariants) {
    actions.push(
      addPageVariantAction(
        editorAPI,
        pageTitle,
        pageManagingAppDefId,
        pageVariants,
        pageRef as PageRef,
      ),
    );
  }

  return actions;
};

export { addVariationActionsIfNeeded, getReplacersCollapserActions };

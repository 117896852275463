import type { MapStateToProps } from 'types/redux';

import type {
  TestSiteRCButtonOwnProps as OwnProps,
  TestSiteRCButtonStateProps as StateProps,
} from './testSiteRC';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';

export const mapStateToProps: MapStateToProps<StateProps, OwnProps> = ({
  editorAPI,
}) => ({
  testSiteButtonActionLabel:
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      constants.USER_PREFS.TEST_SITE.TEST_SITE_BUTTON_TITLE,
    )(editorAPI.store.getState()) as string,
});

import * as helpIds from '#packages/helpIds';
import * as stateManagement from '#packages/stateManagement';
import { headerScrollTypes } from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

interface Scope {
  editorAPI: EditorAPI;
}

function isHeaderFrozen({ editorAPI }: Scope, compRef: CompRef) {
  const fixedPosition = editorAPI.components.layout.get_fixedPosition(compRef);

  return (
    compRef.id === 'SITE_HEADER' &&
    headerScrollTypes.getSelectedHeaderType(
      fixedPosition,
      editorAPI.components.behaviors.get(compRef),
      editorAPI.viewMode.get(),
    ).value.scrollType === 'freezes'
  );
}

function isFooterFrozen(scope: Scope, compRef: CompRef) {
  return (
    compRef.id === 'SITE_FOOTER' &&
    scope.editorAPI.components.layout.get_fixedPosition(compRef)
  );
}

export function shouldShowFreezeWarning(scope: Scope, compRef: CompRef) {
  const compLayout = scope.editorAPI.components.layout.get_size(compRef);

  return (
    (isFooterFrozen(scope, compRef) || isHeaderFrozen(scope, compRef)) &&
    compLayout.height > 200
  );
}

export function showFreezeWarning(scope: Scope, compRef: CompRef) {
  const { editorAPI } = scope;
  const compType = editorAPI.components.getType(compRef);

  const helpId =
    compType === 'wysiwyg.viewer.components.FooterContainer'
      ? helpIds.NOTIFICATIONS.FROZEN_FOOTER
      : helpIds.NOTIFICATIONS.FROZEN_HEADER;

  const label =
    compType === 'wysiwyg.viewer.components.FooterContainer'
      ? 'Notifications_Frozen_Footer_Text'
      : 'Notifications_Frozen_Header_Text';

  const onNotificationLinkClick = () => {
    editorAPI.panelManager.openHelpCenter(helpId);
  };

  editorAPI.store.dispatch(
    stateManagement.notifications.actions.showUserActionNotification({
      message: label,
      title: label,
      type: 'warning',
      link: {
        caption: 'Notifications_Learn_More_Link',
        onNotificationLinkClick,
      },
    }),
  );
}

import { EditorAPIKey, EditorCoreApiKey } from '#packages/apis';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { WixStorePackageStore } from './wixStorePackageStore';
import { WixStoreApiKey } from './publicApiKey';
import { initIsNewStoreCategoryUser, WixStoreApi } from './wixStoreApi';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  store = this.declareStore(WixStorePackageStore);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
}

export const WixStoreEntryPoint = createEntryPoint({
  name: 'WixStore',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(WixStoreApiKey, WixStoreApi);
  },
  async initialize(scope: Scope) {
    await scope.editorCoreAPI.hooks.stageIsReady.promise;
    initIsNewStoreCategoryUser(scope);
  },
});

import { SECTIONS_TYPES } from '#packages/addPanelDataConsts';
import { constants as mediaPanelInfraConstants } from '#packages/mediaManagerPanelInfra';
import constants from '#packages/constants';

const { MEDIA_MANAGER_PANEL_SECTION_ID } = mediaPanelInfraConstants;
const { MEDIA_MANAGER_MEDIA_TYPES } = constants;

export const wixIllustrationsSection = {
  type: SECTIONS_TYPES.DYNAMIC_MEDIA_SECTIONS_BOX,
  title: 'add_section_label_wix_illustrations',
  subNavigationTitle: 'add_section_heading_wix_illustrations',
  automationId: 'addPanel_imageDynamic_wixIllustrationsSection',
  hide: false,
  props: {
    biOriginBase: 'santa-editor',
    items: [
      {
        id: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS,
        mediaType: MEDIA_MANAGER_MEDIA_TYPES.PICTURE,
        automationId: 'addPanel_imageDynamic_wixIllustrationsSection',
      },
    ],
  },
  help: {
    hide: false,
    text: 'add_section_heading_wix_illustrations_tooltip_description',
  },
};

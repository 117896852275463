// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import $ from 'zepto';
import * as compPanelInfra from '#packages/compPanelInfra';
import * as bi from '../../bi/bi';
import * as util from '#packages/util';
import { SafeInjectHtml } from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import * as tpaUtils from '../../utils/tpaUtils';
import * as tpaCompNameService from '../../services/tpaCompNameService';
import * as tpaHelpService from '../../services/tpaHelpService';
import * as tpaAlertsService from '../../services/tpaAlertsService';
import tpaConstants from '../../constants/constants';
import withOnCloseMessage from '../../common/withOnCloseMessage';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';

import React from 'react';
import * as baseUI from '#packages/baseUI';
import * as panels from '#packages/panels';
import { cx } from '#packages/util';

function template() {
  return (
    <panels.frames.TpaSettingsPanelFrame
      title={this.getAppName()}
      width={this.state.width}
      height={this.state.isNewVersion ? this.state.height : null}
      ref="tpaSettingsFrame"
      top={`${util.topBar.getHeightConst() + 24}px`}
      left={`${60 + 18}px`}
      onHelpClicked={this.onHelpClicked}
      shouldAddScroll={!this.state.isNewVersion}
      className={cx({
        'tpa-settings-panel-frame-padding': !this.state.isNewVersion,
        version2: this.state.isNewVersion,
      })}
      {...this.getFrameProps()}
    >
      <div
        style={this.getDialogStyle()}
        className={cx({
          'tpa-settings-iframe-wrapper': true,
          'tpa-settings-iframe-wrapper-padding': !this.state.isNewVersion,
        })}
      >
        {this.shouldShowPreloader() ? (
          <div
            key="preloader"
            className="tpa-preloader"
            data-hook="tpa-settings-preloader"
          >
            <div>
              <baseUI.preloader />
              <div className="text">{translate('tpa_loading_settings')}.</div>
            </div>
          </div>
        ) : null}
        {this.state.overlay === this.OVERLAY_STATE.UNRESPONSIVE ? (
          <div key="unavailableMessage" className="tpa-unresponsive">
            <header>
              <baseUI.symbol name="alert" />
              {translate('tpa_oops')}
            </header>
            <div id="textContainer">
              <SafeInjectHtml
                tag="span"
                id="text"
                html={this.getUnresponsiveText()}
              />
              <span
                id="dismissButton"
                onClick={this.refs?.tpaSettingsFrame?.openHelpCenter}
              >
                <a> {this.getAppName()} </a>
              </span>
              <SafeInjectHtml
                tag="span"
                id="text_2"
                html={this.getUnresponsiveContact()}
              />
            </div>
          </div>
        ) : null}
        <iframe
          scrolling="no"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
          width="100%"
          height={this.getIframeHeight()}
          id="tpaSettingsFrame"
          src={this.state.url}
          className="tpa-iframe"
        />
      </div>
    </panels.frames.TpaSettingsPanelFrame>
  );
}

const HEADER_HEIGHT = 54;
const VERSION_2_MAX_HEIGHT = 582 - HEADER_HEIGHT;

function getContentHeight(isNewVersion, contentHeight) {
  contentHeight = parseInt(contentHeight, 10);
  return isNewVersion
    ? Math.min(VERSION_2_MAX_HEIGHT, contentHeight)
    : contentHeight;
}

// eslint-disable-next-line react/prefer-es6-class
const tpaSettings = createReactClass({
  ALIVE_TIMEOUT: 20000,
  STYLES_READY_SDK_VERSION: '1.64.0',
  OVERLAY_STATE: {
    PRELOADER: 'preloader',
    UNRESPONSIVE: 'unresponsive',
    NONE: 'none',
  },
  mixins: [compPanelInfra.compPanelMixin],
  displayName: 'TPASettings',
  propTypes: {
    width: PropTypes.string,
  },
  getDefaultProps() {
    return {
      panelName: 'tpa.compPanels.tpaSettings',
    };
  },
  getInitialState() {
    const editorAPI = this.getEditorAPI();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    this.selectedComponent = _.isArray(this.props.selectedComponent)
      ? this.props.selectedComponent[0]
      : this.props.selectedComponent;
    this.data = editorAPI.components.data.get(this.selectedComponent);
    this.appData = editorAPI.dsRead.tpa.app.getData(this.data.applicationId);
    this.maxScreenHeight = $(window).height() - 126;
    const isNewVersion = !!this.props.isNewVersion;
    this.helpId = '';

    if (this.appDataIsValid()) {
      const contentSize = this.getContentSize(isNewVersion);
      const isNotProvisioned =
        this.appData.notProvisioned || this.appData.demoMode;
      return {
        url: isNotProvisioned ? undefined : this.getUrl(),
        width: this.props.width || contentSize.width,
        height: contentSize.height,
        overlay: this.OVERLAY_STATE.PRELOADER,
        isNewVersion,
      };
    }
    return {
      width: 400,
      height: 200,
    };
  },

  appDataIsValid() {
    const appHasMissingData =
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array, you-dont-need-lodash-underscore/size
      (_.isArray(this.appData) && _.size(this.appData) === 0) ||
      (_.isObject(this.appData) && _.isEmpty(this.appData));
    return !appHasMissingData;
  },

  componentDidMount() {
    const editorAPI = this.getEditorAPI();

    if (!this.isAppReady(this.props)) {
      this._appAliveTimeout = window.setTimeout(
        this.onAppAliveTimeoutExpires,
        this.ALIVE_TIMEOUT,
      );
    }

    editorAPI.store.dispatch(stateManagement.leftBar.actions.collapseMenu());

    if (this.appDataIsValid()) {
      const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
      if (this.appData.notProvisioned || this.appData.demoMode || isDraftMode) {
        const onSuccess = () => {
          this.reportTpaAddedBiEvent(editorAPI);
          this.setState({
            url: this.getUrl(),
          });
        };
        const onError = _.partial(
          tpaAlertsService.openProvisionFailedAlert,
          editorAPI.panelManager.openPanel,
          this.appData.appDefinitionId,
        );
        editorAPI.saveManager.saveInBackground(
          onSuccess,
          onError,
          'tpaSettings',
        );
      }
    } else {
      //eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        overlay: this.OVERLAY_STATE.UNRESPONSIVE,
      });
    }

    this.panelOpenTime = this.getTime();
    const params = {
      app_id: this.appData.appDefinitionId,
      app_site_id: this.appData.instanceId,
      instance_id: this.props.origCompId,
    };
    editorAPI.bi.event(bi.events.TPA_OPEN_SETTINGS, params);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.helpId = nextProps?.articleId;
    const editorAPI = this.getEditorAPI();
    if (!editorAPI.components.is.exist(this.props.selectedComponent)) {
      editorAPI.panelManager.closePanelByName(this.props.panelName);
    }
    if (this.isAppReady(nextProps)) {
      this.setState({
        overlay: this.OVERLAY_STATE.NONE,
      });
    }
  },

  reportTpaAddedBiEvent(editorAPI) {
    const isFirstSaved = editorAPI.dsRead.generalInfo.isFirstSave();
    const templateMetaSiteId = isFirstSaved
      ? editorAPI.dsRead.generalInfo.getMetaSiteId()
      : '';
    const isWixApp = this.appData.by === 'Wix'; // TODO - need to get from data
    const params = {
      is_permissions_app: isWixApp ? 0 : 1,
      is_first: 1,
      app_id: this.appData.appDefinitionId,
      widget_id: this.data.widgetId,
      app_site_id: this.appData.instanceId,
      instance_id: this.props.origCompId,
      category: '',
      section: '',
      search_term: '',
      item_position: '',

      referral_info_category: '',
      referral_info: '',

      template_guid: templateMetaSiteId,
      modal_tab_name: '',
      origin: 'template_provision',
      adding_method: 'template',

      page_id: editorAPI.dsRead.pages.getFocusedPageId(),
    };

    editorAPI.bi.event(bi.events.TPA_ADDED, params);
  },

  getContentSize(isNewVersion) {
    const settingsData = this.isSection()
      ? this.appData.widgets[this.data.widgetId].settings
      : this.appData.sectionSettings;
    return {
      height: getContentHeight(isNewVersion, settingsData.height) || 0,
      width: parseInt(settingsData.width, 10) || 0,
    };
  },

  isSection() {
    return this.appData.widgets && this.data.widgetId;
  },

  getUrl() {
    const editorAPI = this.getEditorAPI();
    const options = {
      width: this.props.width,
      isNewVersion: this.props.isNewVersion,
      bi: tpaUtils.bi.getAppIntentBiParamsToAddToUrl(
        editorAPI,
        this.appData.appDefinitionId,
        tpaConstants.BI.type.GFPP_SETTINGS,
      ),
    };

    if (this.props.cssPerBreakpoint) {
      options.cssPerBreakpoint = true;
    }

    return editorAPI.dsRead.tpa.app.url.getSettingsUrl(
      this.appData.applicationId,
      this.data.widgetId,
      this.props.origCompId,
      options,
    );
  },

  getDialogStyle() {
    let height = '100%';
    if (!this.state.isNewVersion) {
      height =
        this.state.overlay === this.OVERLAY_STATE.NONE
          ? '100%'
          : _.min([this.maxScreenHeight, this.state.height]);
    }
    return {
      height,
      position: 'relative',
    };
  },

  getIframeHeight() {
    return this.state.isNewVersion ? '100%' : this.state.height;
  },

  onAppAliveTimeoutExpires() {
    this.showUnresponsiveOverlay();
  },

  showUnresponsiveOverlay() {
    if (!this.isAppReady(this.props)) {
      this.reportBIAppNotResponsive();
      this.setState({ overlay: this.OVERLAY_STATE.UNRESPONSIVE });
    } else {
      this.clearAliveTimeout();
      this.setState({ overlay: this.OVERLAY_STATE.NONE });
    }
  },

  reportBIAppNotResponsive() {
    const editorAPI = this.getEditorAPI();
    const widgetData = _.get(this.appData.widgets, this.data.widgetId);
    const reportParams = {
      endpoint: widgetData?.settings?.url,
      app_id: this.appData.appDefinitionId,
    };
    editorAPI.dsActions.bi.error(bi.errors.APP_IS_NOT_RESPONSIVE, reportParams);
  },

  getTime() {
    return Date.now();
  },

  sendClosePanelBI() {
    const editorAPI = this.getEditorAPI();
    const selectedComponentId = this.props.origCompId;

    const params = {
      app_id: this.appData.appDefinitionId,
      app_site_id: this.appData.instanceId,
      instance_id: selectedComponentId,
      seconds_opened: parseInt(
        (this.getTime() - this.panelOpenTime) / 1000,
        10,
      ),
    };
    editorAPI.bi.event(bi.events.TPA_CLOSE_SETTINGS, params);
  },

  componentWillUnmount() {
    this.clearAliveTimeout();
    this.sendClosePanelBI();
  },

  clearAliveTimeout() {
    if (this._appAliveTimeout) {
      window.clearTimeout(this._appAliveTimeout);
    }
  },

  isAppReady(nextProps) {
    const sdkVersion = nextProps?.sdkVersion || this.props.sdkVersion;
    const SDKStylesReadySupported = tpaUtils.sdkVersionIsAtLeast(
      sdkVersion,
      this.STYLES_READY_SDK_VERSION,
    );

    if (SDKStylesReadySupported) {
      return this.shouldShowSettingsPanelFromStyleReadyEvent(nextProps);
    }

    return this.shouldShowSettingsPanelFromIsAliveEvent(nextProps);
  },

  shouldShowSettingsPanelFromStyleReadyEvent(props) {
    return props?.stylesReady || false;
  },

  shouldShowSettingsPanelFromIsAliveEvent(props) {
    return props?.isAlive || false;
  },

  shouldShowPreloader() {
    return (
      !this.isAppReady(this.props) &&
      this.state.overlay === this.OVERLAY_STATE.PRELOADER
    );
  },

  onHelpClicked() {
    const editorAPI = this.getEditorAPI();
    const biEvent = bi.events.TPA_HELP_CLICK;
    const { widgetId } = this.data;
    const biParams = {
      widget_id: widgetId,
      app_id: this.appData.appDefinitionId,
      origin: 'settings',
    };

    const { panelName } = this.props;
    const helpPanelProps = {
      openPanelBiEvent: biEvent,
      openPanelBiParams: _.merge({ help_status: 'open' }, biParams),
      closePanelBiEvent: biEvent,
      closePanelBiParams: _.merge({ help_status: 'close' }, biParams),
      origin: 'settings',
    };
    const biHelpParams = {
      panel_name: panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      component: editorAPI.selection.getSelectedComponentType(),
      learn_more: false,
    };

    if (this.helpId) {
      editorAPI.panelManager.openHelpCenter(
        this.helpId,
        helpPanelProps,
        biHelpParams,
      );
    } else {
      tpaHelpService
        .getHelpId(
          editorAPI,
          this.appData.appDefinitionId,
          widgetId,
          tpaHelpService.HELP_END_POINTS.SETTINGS,
        )
        .then(function (helpId) {
          editorAPI.panelManager.openHelpCenter(
            helpId,
            helpPanelProps,
            biHelpParams,
          );
        });
    }
  },

  getUnresponsiveText() {
    const appName = this.appData.appDefinitionName;
    if (appName) {
      return translate('tpa_unavail_problems', {
        appName,
      });
    }
  },

  getAppName() {
    const editorAPI = this.getEditorAPI();
    if (!editorAPI.components.is.exist(this.props.selectedComponent)) {
      return '';
    }
    return tpaCompNameService.getCompName(editorAPI, this.selectedComponent);
  },

  getUnresponsiveContact() {
    if (!_.isEmpty(this.appData)) {
      return translate('tpa_unavail_problems_2');
    }
  },

  render: template,
});

export default withOnCloseMessage(tpaSettings);

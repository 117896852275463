import React from 'react';
import { cx, hoc } from '#packages/util';
import { createStructuredSelector } from '../../selectors/selector';
import { groupAncestorStyleSel } from './editBox/layout/selectors';

interface GroupSelectionFrameOwnProps {}

interface GroupSelectionFrameStateProps {
  groupAncestorStyle: React.CSSProperties;
}

interface GroupSelectionFrameProps
  extends GroupSelectionFrameOwnProps,
    GroupSelectionFrameStateProps {}

class GroupSelectionFrame extends React.Component<GroupSelectionFrameProps> {
  render() {
    return (
      <div>
        {this.props.groupAncestorStyle && (
          <div
            key="markContainer"
            style={this.props.groupAncestorStyle}
            className={cx('layout', 'secondary-component')}
          />
        )}
      </div>
    );
  }
}

const groupSelectionFramePropsSel =
  createStructuredSelector<GroupSelectionFrameStateProps>({
    groupAncestorStyle: groupAncestorStyleSel,
  });

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  groupSelectionFramePropsSel,
)(GroupSelectionFrame);

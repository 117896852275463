import _ from 'lodash';

import * as coreBi from '#packages/coreBi';
import * as core from '#packages/core';
import constants from '#packages/constants';
import { sections } from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const { SnapToHandler } = core.utils;

let editorAPI: EditorAPI,
  initMousePosition: AnyFixMe,
  originalPageMinHeight: AnyFixMe,
  newPageMinHeight: AnyFixMe,
  pageRef: CompRef,
  didResize: boolean,
  bottomOfComps: AnyFixMe,
  snapToHandler: AnyFixMe;
const snapDirections = { top: true };

function getMousePosition(mouseEvent: AnyFixMe) {
  return { x: mouseEvent.pageX, y: mouseEvent.pageY };
}

function startPageResize(_editorAPI: EditorAPI, params: AnyFixMe) {
  editorAPI = _editorAPI;
  const currentPageId = editorAPI.dsRead.pages.getPrimaryPageId();
  initMousePosition = getMousePosition(params.evt);
  pageRef = editorAPI.dsRead.pages.getReference(currentPageId);
  snapToHandler = new SnapToHandler(editorAPI, pageRef);

  // @ts-expect-error
  bottomOfComps = editorAPI.pages.getPageBottomByComponents(pageRef.id);
  originalPageMinHeight = editorAPI.components.layout.get_size(pageRef).height;
}

function snapToTheClosestLayout(layout: AnyFixMe) {
  if (snapToHandler) {
    const closestLayoutDistance = snapToHandler.getClosestLayoutDistance(
      editorAPI,
      layout.bounding,
      snapDirections,
    );
    if (Math.abs(closestLayoutDistance.x) < 5) {
      layout.x += closestLayoutDistance.x;
      layout.bounding.x += closestLayoutDistance.x;
    }
    if (Math.abs(closestLayoutDistance.y) < 5) {
      layout.y += closestLayoutDistance.y;
      layout.bounding.y += closestLayoutDistance.y;
    }
  }
}

function drawSnapLines(_editorAPI: EditorAPI, layout: AnyFixMe) {
  const snapDirection = { top: true, bottom: true };
  const ignoreTouchingGridLines = true;
  const snapData = snapToHandler.getSnapData(
    layout,
    snapDirection,
    ignoreTouchingGridLines,
  );
  _editorAPI.snapData.set(snapData);
}

function onPageResize(event: AnyFixMe) {
  const mouse = getMousePosition(event);
  const delta = mouse.y - initMousePosition.y;
  newPageMinHeight = Math.max(getPageNewMinHeight(delta), bottomOfComps);
  editorAPI.pages.properties.update(pageRef, { minHeight: newPageMinHeight });

  editorAPI.dsActions.waitForChangesApplied(function () {
    // we wait for changes to be applied to make sure layout constraint didn't change our layout during update
    if (snapToHandler) {
      const updatedLayout =
        editorAPI.components.layout.getRelativeToScreen(pageRef);
      snapToTheClosestLayout(updatedLayout);
      drawSnapLines(editorAPI, updatedLayout);
    }
  });

  didResize = !_.isEqual(newPageMinHeight, originalPageMinHeight);
}

function reset() {
  editorAPI.pages.properties.update(pageRef, { minHeight: null });
  addToHistory();
}

function endResize() {
  if (didResize) {
    addToHistory();
    editorAPI.bi.event(coreBi.events.pageResize.PAGE_MIN_HEIGHT_RESIZE, {
      yCoordinate_new: newPageMinHeight,
      yCoordinate_old: originalPageMinHeight,
    });

    didResize = false;
  }

  editorAPI.snapData.clear();
  snapToHandler = null;
}

function addToHistory() {
  editorAPI.history.add('Resize page');
}

function getMinHeightConstraint() {
  if (sections.isSectionsEnabled()) {
    return sections.constants.SECTION_MIN_HEIGHT;
  }

  return editorAPI.isMobileEditor()
    ? constants.CONSTRAINS.PAGE_MIN_HEIGHT.MOBILE
    : constants.CONSTRAINS.PAGE_MIN_HEIGHT.DESKTOP;
}

function getPageNewMinHeight(delta: AnyFixMe) {
  const newMinHeight = originalPageMinHeight + delta;
  return Math.max(newMinHeight, getMinHeightConstraint());
}

export default {
  start: startPageResize,
  on: onPageResize,
  end: endResize,
  reset,
  type: constants.MOUSE_ACTION_TYPES.RESIZE_PAGE,
};

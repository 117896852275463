// @ts-nocheck
import _ from 'lodash';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import constants from '#packages/constants';
import * as util from '#packages/util';
import disableKeyboardShortcutsMixin from '../../mixins/disableKeyboardShortcutsMixin';
import panelCloseOrigin from '../panelCloseOrigin';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import { PanelHeader } from '@wix/wix-base-ui';

const CLOSE_METHOD = {
  OUT_OF_FOCUS: panelCloseOrigin.OUT_OF_FOCUS,
  X_BUTTON: 'x_button',
};

//https://github.com/facebook/react/blob/master/CHANGELOG.md#new-helpful-warnings
//if typeof str == 'string' and str is a number convert to type number
function stringNumberToNumber(str) {
  const number = Number(str);
  if (number == str) {
    //eslint-disable-line eqeqeq
    return number;
  }

  return str;
}
// eslint-disable-next-line react/prefer-es6-class
const FocusPanelFrameClass = createReactClass({
  mixins: [disableKeyboardShortcutsMixin, util.draggableMixin],
  displayName: constants.PANEL_TYPES.FOCUS,
  propTypes: {
    dataHook: PropTypes.string,
    automationId: PropTypes.string,
    blockOuterScroll: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
    panelName: PropTypes.string.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    title: PropTypes.string,
    headerClassName: PropTypes.string,
    frameClassName: PropTypes.string,
    shouldHideHeader: PropTypes.bool,
    shouldHideHelp: PropTypes.bool,
    shouldNotCloseOnBlur: PropTypes.bool,
    keyboardShortcuts: PropTypes.object,
    shouldExtendDefaultShortcuts: PropTypes.bool,
    centered: PropTypes.bool,
    top: PropTypes.number,
    left: PropTypes.number,
    platformSidePanelWidth: PropTypes.number,
    isPlatformSidePanelAboveCurrentModal: PropTypes.bool,
    subtitle: PropTypes.element,
    draggable: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      centered: true,
    };
  },

  getHeaderClassName() {
    return this.props.headerClassName || 'panel-header';
  },
  getFrameProps() {
    return {
      style: {
        width: this.props.width,
        height: this.props.height,
      },
    };
  },
  shouldHideHeader() {
    return this.props.shouldHideHeader;
  },
  shouldCloseFrame(closeMethod) {
    if (this.props.onClose) {
      return this.props.onClose(closeMethod) === true;
    }
    return true;
  },
  onXButtonClick() {
    this.closeFrameIfNeeded(CLOSE_METHOD.X_BUTTON);
  },
  closeFrameIfNeeded(closeMethod) {
    if (this.shouldCloseFrame(closeMethod)) {
      this.props.close();
    }
  },
  handleBlur() {
    if (!this.props.shouldNotCloseOnBlur) {
      this.closeFrameIfNeeded(CLOSE_METHOD.OUT_OF_FOCUS);
    }
  },
  getClassName(defaultClassName) {
    return util.inheritClassName(this.props, defaultClassName);
  },

  getFocusPanelSizeAndMargin() {
    const size = {};
    if (this.props.width) {
      size.width = stringNumberToNumber(this.props.width);
    }
    if (this.props.height) {
      size.height = stringNumberToNumber(this.props.height);
    }
    if (this.props.platformSidePanelWidth) {
      if (this.props.isPlatformSidePanelAboveCurrentModal && size.width) {
        size.width =
          typeof size.width === 'string' && size.width.includes('%')
            ? `calc(${size.width} - ${this.props.platformSidePanelWidth}px)`
            : `min(${size.width}px, calc(100% - ${this.props.platformSidePanelWidth}px))`;
        size.marginRight = this.props.platformSidePanelWidth;
      }
    }

    return size;
  },
  render() {
    return (
      <div
        onWheel={this.props.blockOuterScroll}
        onContextMenu={(e) => {
          if (this.props.onContextMenu) {
            this.props.onContextMenu(e);
          }
        }}
        data-hook={this.props.dataHook}
        data-aid={this.props.automationId}
        className={util.cx(
          'panel-frame-container',
          'focus-panel-frame-z-index',
          this.props.frameClassName,
          {
            'screen-centered-frame': this.props.centered,
          },
        )}
        style={
          this.props.centered
            ? {}
            : { top: this.props.top, left: this.props.left }
        }
      >
        <div
          style={this.getFocusPanelSizeAndMargin()}
          className={this.getClassName('focus-panel-frame')}
          data-aid={'focus-panel-frame'}
        >
          {!this.shouldHideHeader() ? (
            <PanelHeader
              key="header"
              onClose={this.onXButtonClick}
              onHelp={this.props.openHelpCenter}
              noHelpBtn={this.props.shouldHideHelp}
              className={`${this.getHeaderClassName()} dark`}
              subtitle={this.props.subtitle || null}
              onMouseDown={this.props.draggable ? this.startDrag : null}
            >
              <span>{this.props.title}</span>
            </PanelHeader>
          ) : null}
          <div
            style={{
              backgroundColor: this.props.containerBackground,
            }}
            className={
              this.shouldHideHeader()
                ? 'focus-panel-frame-content-no-header'
                : 'focus-panel-frame-content'
            }
          >
            {this.props.children}
          </div>
        </div>
        <div
          onClick={this.handleBlur}
          style={{
            backgroundColor: this.props.overlayBackground,
          }}
          className="dark-frame-overlay"
        />
      </div>
    );
  },
});

const mapStateToProps = ({ state }) => ({
  platformSidePanelWidth:
    stateManagement.panels.selectors.selectPlatformSidePanelWidth(state),
  isPlatformSidePanelAboveCurrentModal:
    stateManagement.panels.selectors.isPlatformSidePanelAboveCurrentModal(
      state,
    ),
});

const FocusPanelFrame = _.flow(
  stateManagement.panels.hoc.panelFrame(constants.PANEL_TYPES.FOCUS),
  util.hoc.connect(util.hoc.STORES.STATE_ONLY, mapStateToProps),
)(FocusPanelFrameClass);

FocusPanelFrame.pure = FocusPanelFrameClass;

export default FocusPanelFrame as AnyFixMe;

import * as stateManagement from '#packages/stateManagement';

import type { EditorStore } from '#packages/stateManagement';

export function createSelectionByClickStore(store: EditorStore) {
  const selectionActions = stateManagement.selection.actions;

  // TODO: review pos type
  function setLastClickType(clickType: 'left' | 'right'): void {
    store.dispatch(selectionActions.setLastClickType(clickType));
  }

  function setLastSelectionClickPosition(pos: any): void {
    store.dispatch(selectionActions.setLastSelectionClickPos(pos));
  }

  return {
    setLastSelectionClickPosition,
    setLastClickType,
  };
}

export type SelectionByClickStore = ReturnType<
  typeof createSelectionByClickStore
>;

// @ts-nocheck
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_mainaction_members_bar',
      isApplied: true,
      helpId: helpIds.GFPP.SITE_REGION_CONTAINER,
    },
  ],

  enabledActions: [ACTIONS.HELP],

  mobileEnabledActions: [ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.SITE_REGION_CONTAINER,
      mobileHelpId: helpIds.GFPP.SITE_REGION_CONTAINER_MOBILE,
    },
  },
};

import type { EditorAPI } from '#packages/editorAPI';
import { bi as tpaBi } from '#packages/tpa';
import {
  type BiBaseEvent,
  type BiErrorEvent,
  getOriginInfo,
  type BiInstalledEvent,
  reportBIOnPlatformAppAdded,
  reportBIOnPlatformTryToInstall,
  reportBIOnPlatformAppError,
  type PlatformOrigin,
} from '../../bi/bi';
import * as stateManagement from '#packages/stateManagement';
import { silentConstants } from './silentInstallConstants';

const { getIsSessionInitializedWithWizard } =
  stateManagement.siteCreation.selectors;

const fireError = (editorAPI: EditorAPI, eventData: BiErrorEvent) => {
  reportBIOnPlatformAppError(editorAPI, eventData);
};

const shouldFireAppEngagement = (
  editorAPI: EditorAPI,
  appDefId: string,
): boolean => {
  const isSiteCreationFlow = getIsSessionInitializedWithWizard(
    editorAPI.store.getState(),
  );
  return (
    isSiteCreationFlow && silentConstants.APPS_TO_SEND_ENGAGEMENT.has(appDefId)
  );
};

const fireAttempt = (editorAPI: EditorAPI, eventData: BiBaseEvent) => {
  reportBIOnPlatformTryToInstall(editorAPI, eventData);
};

const fireStartInstallingApps = (
  editorAPI: EditorAPI,
  appNamesMap: { [appDefId: string]: string },
): void => {
  const appsToInstall = Object.values(appNamesMap).join(',');
  editorAPI.bi.event(
    tpaBi.events['SILENT_INSTALL_APPS_START' as keyof typeof tpaBi.events],
    { appsToInstall },
  );
};

const fireRequestBeginSilentInstallingApps = (
  editorAPI: EditorAPI,
  appsToInstall: string[],
  origin: PlatformOrigin,
  payload: string,
): void => {
  editorAPI.bi.event(
    tpaBi.events[
      'SILENT_INSTALL_APPS_REQUEST_BEGIN' as keyof typeof tpaBi.events
    ],
    { appsToInstall, origin: getOriginInfo(origin), payload },
  );
};

const fireInstalled = (editorAPI: EditorAPI, eventData: BiInstalledEvent) => {
  reportBIOnPlatformAppAdded(editorAPI, eventData);
  if (shouldFireAppEngagement(editorAPI, eventData.app_id)) {
    const instanceId = editorAPI.platform.getAppDataByAppDefId(eventData.app_id)
      ?.instanceId;
    editorAPI.bi.event(tpaBi.events.APP_ENGAGEMENT, {
      premium_intent: 'FREE',
      app_id: eventData.app_id,
      instance_id: instanceId,
    });
  }
};

export {
  fireInstalled,
  fireError,
  fireAttempt,
  fireStartInstallingApps,
  fireRequestBeginSilentInstallingApps,
};

// @ts-nocheck
import * as UI from '#packages/baseUI';
import constants from '#packages/constants';
import * as core from '#packages/core';
import type { EditorAPI } from '#packages/editorAPI';
import { hoc } from '#packages/util';
import type { AppData, CompRef } from '@wix/document-services-types';
import { ClientSDK, Platform } from '@wix/installation-manager-client-sdk';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'reactDOM';
import type { MapDispatchToProps } from 'types/redux';
import * as appMarketHandlers from '../appMarket/appMarketHandlers';
import * as appMarketUtils from '../appMarket/utils/appMarketUtils';
import * as bi from '../bi/bi';
import tpaConstants from '../constants/constants';
import * as tpaAddRemoveDSService from '../services/tpaAddRemoveDSService';
import * as tpaAppInstallingService from '../services/tpaAppInstallingService';
import * as tpaUtils from '../utils/tpaUtils';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import { HttpClient } from '@wix/http-client';

interface AppInstallationResult {
  addedCompsRef?: CompRef[];
  page: {
    id?: string;
    type?: string;
    pageId?: string;
    sectionId?: string;
  };
  sectionId?: string;
  success: boolean;
}

const reportTpaAddedBiEvent: (
  editorApi: EditorAPI,
  appDefId: string,
  isWixApp: boolean,
  addingMethod: string,
  pageId: string,
  compId: string,
  isFirstAdd: boolean,
  category: string,
  referralInfo: string,
) => void = (
  editorApi,
  appDefId,
  isWixApp,
  addingMethod,
  pageId,
  compId,
  isFirstAdd,
  category,
  referralInfo,
) => {
  const params = {
    adding_method: addingMethod,
    section: 'related_apps',
    category,
    origin: referralInfo,
    app_id: appDefId,
    is_first: isFirstAdd ? 1 : 0,
    is_permissions_app: isWixApp ? 0 : 1,
  };

  const appData = editorApi.dsRead.tpa.app.getDataByAppDefId(appDefId);
  const isFirstSaved = editorApi.dsRead.generalInfo.isFirstSave();
  const templateMetaSiteId = isFirstSaved
    ? editorApi.dsRead.generalInfo.getMetaSiteId()
    : '';

  const extendedParams = {
    widget_id: '',
    app_site_id: appData.instanceId,
    instance_id: compId,
    search_term: '',
    search_result_position: '',

    referral_info_category: category,
    referral_info: referralInfo,

    template_guid: templateMetaSiteId, // meta site id - only when template
    modal_tab_name: '',
    page_id: pageId,
  };

  editorApi.bi.event(bi.events.TPA_ADDED, { ...params, ...extendedParams });
};

const handleInstalledSectionApp = ({
  editorAPI,
  appDefId,
  marketData,
}: {
  editorAPI: EditorAPI;
  appDefId: string;
  marketData: AppData;
}) => {
  const curPageId = editorAPI.dsRead.pages.getFocusedPageId();
  const data = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(appDefId);
  if (data) {
    if (data.pageId !== curPageId) {
      editorAPI.pages.navigateTo(data.pageId);
    }
  }

  editorAPI.panelManager.openPanel(
    'tpaPanels.message.sectionAlreadyInstalled',
    {
      appName: marketData?.name,
      appIcon: marketData?.colorSvgIcon || marketData?.appIcon,
      appDefId,
    },
  );
};

const addWixSectionApp = ({
  editorAPI,
  appDefId,
  marketData,
  reportTPAAddedBI,
}: {
  editorAPI: EditorAPI;
  appDefId: string;
  marketData: AppData;
  reportTPAAddedBI: (sectionId: string) => void;
}) => {
  const appName = marketData?.name;
  tpaAppInstallingService.prePageAppInstalling(editorAPI, appName, appDefId, {
    showPageAddedPanel: !marketData?.doNotShowPageAddedPanel,
  });
  tpaAddRemoveDSService.addSection(editorAPI, appDefId, {
    platformOrigin: {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.AppPanel,
      },
    },
    callback(obj: AppInstallationResult) {
      if (obj.page) {
        editorAPI.pages.navigateTo(obj.page.id, function () {
          const sectionPointer =
            editorAPI.dsRead.tpa.section.getSectionRefByPageId(obj.page.id);
          editorAPI.selection.selectComponentByCompRef(sectionPointer);
        });
      }

      if (!marketData?.doNotShowPageAddedPanel) {
        tpaAppInstallingService.onPageAppInstallingCompleted(
          editorAPI,
          appName,
          appDefId,
          obj,
        );
      }
      reportTPAAddedBI(obj.sectionId);
    },
  });
};

const handleWixOrInstalledApp = ({
  editorAPI,
  appDefId,
  pageId,
  layout,
  marketData,
  reportTPAAddedBI,
}: {
  editorAPI: EditorAPI;
  appDefId: string;
  pageId: string;
  layout: { x: number; y: number };
  marketData: AppData;
  reportTPAAddedBI: (sectionId: string) => void;
}) => {
  const options = {
    pageId,
    layout,
    callback: (obj: AppInstallationResult) => {
      reportTPAAddedBI(obj.sectionId);
    },
    origin: {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.AddPanel,
      },
    },
  };

  const type = appMarketUtils.getAppType(marketData);

  if (type === tpaConstants.APP.TYPE.PLATFORM_ONLY) {
    tpaAddRemoveDSService.addPlatform(editorAPI, appDefId, options);
  } else {
    tpaAddRemoveDSService.addWidget(editorAPI, appDefId, options);
  }
};

const addThirdPartyApp = ({
  editorAPI,
  appDefId,
}: {
  editorAPI: EditorAPI;
  appDefId: string;
}) => {
  const clientSDK = new ClientSDK({
    platform: Platform.EDITOR,
    metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    httpClient: new HttpClient(),
    editorSDK: {
      addApp: (options) => appMarketHandlers.addApp(editorAPI, options),
    },
  });
  clientSDK.installApp({ appDefId });
};

const addApp: (
  editorAPI: EditorAPI,
  appDefId: string,
  isWixApp: boolean,
  layout: { x: number; y: number },
  addingMethod: string,
  isFirstAdd: boolean,
  category: string,
  referralInfo: string,
) => void = (
  editorAPI,
  appDefId,
  isWixApp,
  layout,
  addingMethod,
  isFirstAdd,
  category,
  referralInfo,
) => {
  if (!appDefId) return;
  const isAppInstalled = editorAPI.dsRead.tpa.app.isInstalled(appDefId, true);
  const pageId = editorAPI.dsRead.pages.getFocusedPageId();
  const reportTPAAddedBI = (sectionId: string) => {
    reportTpaAddedBiEvent(
      editorAPI,
      appDefId,
      isWixApp,
      addingMethod,
      pageId,
      sectionId,
      isFirstAdd,
      category,
      referralInfo,
    );
  };

  editorAPI.dsRead.tpa.appMarket.getDataAsync(appDefId).then((marketData) => {
    if (marketData.hasSection && isAppInstalled) {
      handleInstalledSectionApp({ editorAPI, appDefId, marketData });
      return;
    }

    if (marketData.hasSection && isWixApp) {
      addWixSectionApp({
        editorAPI,
        appDefId,
        marketData,
        reportTPAAddedBI,
      });
      return;
    }

    if (isAppInstalled || isWixApp) {
      handleWixOrInstalledApp({
        editorAPI,
        appDefId,
        pageId,
        layout,
        marketData,
        reportTPAAddedBI,
      });
      return;
    }

    addThirdPartyApp({
      editorAPI,
      appDefId,
    });
  });
};

const reportTpaAddClickBiEvent = (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  isWixApp: boolean,
  addingMethod: string,
  isFirstAdd: boolean,
  category: string,
  referralInfo: string,
) => {
  tpaUtils.bi.reportBIOnAppIntent(
    editorAPI,
    bi.events.APP_INTENT,
    appDefinitionId,
    tpaConstants.BI.initiator.EDITOR,
    tpaConstants.BI.type.ADD_APP,
  );
  const params = {
    adding_method: addingMethod,
    section: 'related_apps',
    category,
    origin: referralInfo,
    app_id: appDefinitionId,
    is_first: isFirstAdd ? 1 : 0,
    is_permissions_app: isWixApp ? 0 : 1,
  };

  editorAPI.bi.event(bi.events.TPA_ADD_CLICK, params);
};

// eslint-disable-next-line react/prefer-es6-class
const tpaAddPanelAppComponent = createReactClass({
  displayName: 'tpaAddPanelApp',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    return (
      <div
        onMouseEnter={() => {
          this.onMouseEnter(this.props.index);
        }}
        onMouseLeave={() => {
          this.onMouseLeave(this.props.index);
        }}
        onClick={this.onClick}
        className="box-app-wrapper"
      >
        <a onMouseDown={this.onMouseDown} className="box-app">
          <div
            style={{ backgroundImage: `url(${this.props.box.appIcon})` }}
            className="box-app-logo"
          />
        </a>
        <div className="content">
          <div className="title ellipsis">{this.props.box.name}</div>
          <label className="ellipsis">
            {this.props.box.teaser || this.props.box.relatedAppsTeaser}
          </label>
        </div>
        <a onClick={this.onPlusClick.bind(this, this.props.index)}>
          <span className="plus-tooltip">
            <UI.tooltip
              value="tpa_add_panel_add_to_site"
              alignment={constants.UI.TOOLTIP.ALIGNMENT.TOP}
            >
              <div>
                <UI.symbol name="plusBig" className="plus" />
              </div>
            </UI.tooltip>
          </span>
        </a>
      </div>
    );
  },
  propTypes: {
    referralInfo: PropTypes.string.isRequired,
    category: PropTypes.string,
    box: PropTypes.object,
    onItemDrag: PropTypes.func,
  },

  onMouseEnter(boxIndex) {
    this.setState({ hover: true, selected: boxIndex });
  },

  onMouseLeave() {
    this.setState({ hover: false });
  },

  onMouseDown(e) {
    const self = this;
    if (!_.isFunction(this.props.onItemDrag)) {
      return;
    }

    const boundingRect = ReactDOM.findDOMNode(this).getBoundingClientRect();
    const rect = _.pick(
      _.merge({}, boundingRect, {
        top: boundingRect.top + 30,
        left: boundingRect.left + 41,
        width: 60,
        height: 60,
      }),
      ['left', 'top', 'width', 'height'],
    );
    let width, height;
    const hasSections = this.props.box.hasSection;

    if (hasSections) {
      width = 980;
      height = 500;
    } else {
      width = this.props.box.widgets[0].defaultWidth;
      height = this.props.box.widgets[0].defaultHeight;
    }

    this.props.onItemDrag(e, {
      rect,
      onDrop(layout, cfg) {
        self.onMouseDrop(layout, cfg);
      },
      structure: {
        layout: {
          width,
          height,
        },
      },
      selectedApp: this.props.box,
    });
  },

  onMouseDrop(layout, cfg) {
    const editorAPI = this.getEditorAPI();
    const { appDefinitionId } = cfg.selectedApp;
    const isWixApp = cfg.selectedApp.by === 'Wix';
    const isFirstAdd = this.isFirstAdd(editorAPI, appDefinitionId);
    const { category, referralInfo } = this.props;

    reportTpaAddClickBiEvent(
      editorAPI,
      appDefinitionId,
      isWixApp,
      'drag',
      isFirstAdd,
      category,
      referralInfo,
    );
    addApp(
      editorAPI,
      appDefinitionId,
      isWixApp,
      {
        width: cfg.structure.layout.width,
        height: cfg.structure.layout.height,
        x: layout.x,
        y: layout.y,
      },
      'drag',
      isFirstAdd,
      category,
      referralInfo,
    );
  },

  onClick(event) {
    if (this.svgClicked(event)) {
      return;
    }

    const { addAppAndReportBi, box, category, referralInfo } = this.props;

    addAppAndReportBi({ app: box, category, referralInfo });
  },

  svgClicked(event) {
    return (
      event.target.tagName === 'svg' ||
      event.target.tagName === 'circle' ||
      event.target.tagName === 'path'
    );
  },

  onPlusClick(index, event) {
    if (!this.svgClicked(event)) {
      return;
    }

    const { addAppAndReportBi, box, category, referralInfo } = this.props;

    addAppAndReportBi({ app: box, category, referralInfo });
  },

  isFirstAdd(editorAPI, appDefId) {
    return !editorAPI.dsRead.tpa.app.isInstalled(appDefId, true);
  },
});

export interface TpaAddPanelAppBoxDispatchProps {
  addApp: (
    editorAPI: EditorAPI,
    appDefId: string,
    isWixApp: boolean,
    layout: { x: number; y: number },
    addingMethod: string,
    isFirstAdd: boolean,
    category: string,
    referralInfo: string,
  ) => void;
  addAppAndReportBi: (params: {
    category: string;
    referralInfo: string;
    app: {
      appDefinitionId: string;
      slug: string;
      widgets: {
        defaultWidth: number;
        defaultHeight: number;
      }[];
      name: string;
      appIcon: string;
      hasSection: boolean;
      teaser: string;
      by: string;
      categories: string[];
      automationId: string;
      weights?: {
        categories: {
          [categoryRelatedAppsId: string]: number;
        };
      };
    };
  }) => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  TpaAddPanelAppBoxDispatchProps,
  any
> = (dispatch) => {
  const editorAPI = dispatch((dispatch, getState, { editorAPI }) => editorAPI);

  return {
    addApp,
    addAppAndReportBi({ app, category, referralInfo }) {
      const isWixApp = app.by === 'Wix';
      const appDefId = app.appDefinitionId;
      const isFirstAdd = !editorAPI.dsRead.tpa.app.isInstalled(appDefId, true);

      reportTpaAddClickBiEvent(
        editorAPI,
        appDefId,
        isWixApp,
        'direct_add',
        isFirstAdd,
        category,
        referralInfo,
      );

      addApp(
        editorAPI,
        appDefId,
        isWixApp,
        null,
        'direct_add',
        isFirstAdd,
        category,
        referralInfo,
      );
    },
  };
};

const ConnectedTpaAddPanelApp = hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(tpaAddPanelAppComponent);

ConnectedTpaAddPanelApp.pure = tpaAddPanelAppComponent;

export default ConnectedTpaAddPanelApp;

import { events } from '#packages/coreBi';

import {
  DEBUG,
  BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL,
  MigrationOrigin,
} from '../constants';
import {
  debugReporter,
  isFullValidationEnabled,
  isHiddenElementsValidationEnabled,
  isEnforceClosedGapsEnabled,
} from '../utils';
import {
  collectSnapshots,
  compareSnapshots,
  invalidSnapshotsStatistics,
  type PageSnapshots,
} from '../validator';
import { migratePagesToSectionsTransaction } from '../migration';
import { MigrationFlow } from '../types';
import { getPageGrouping } from './getPageGrouping';
import { saveBeforeMigration } from './saveMigration';
import { beforeMigration } from './beforeMigration';
import { afterSuccessMigration, afterFailedMigration } from './afterMigration';

import { enforceClosedGaps } from '../utils/enforceClosedGaps';

import type { CompRef } from 'types/documentServices';
import type { SectionsMigrationScope as Scope } from '../scope';
import { EditorCoreApiKey } from '#packages/apis';
import experiment from 'experiment';

function validateSnapshots(
  scope: Scope,
  {
    flow,
    pagesRefs,
    removedComponents,
    validationSnapshots,
    isFullValidationMode,
  }: {
    flow: MigrationFlow;
    pagesRefs: CompRef[];
    removedComponents: Set<string>;
    validationSnapshots: PageSnapshots[];
    isFullValidationMode: boolean;
  },
) {
  const afterMigrationValidationSnapshots = collectSnapshots(
    scope.editorAPI.documentServices,
    pagesRefs,
    isFullValidationMode,
  );

  const invalidSnapshots = compareSnapshots(
    validationSnapshots,
    afterMigrationValidationSnapshots,
    removedComponents,
    isFullValidationMode,
  );

  if (invalidSnapshots.length) {
    if (isFullValidationMode) {
      const { visibility, layout, level } =
        invalidSnapshotsStatistics(invalidSnapshots);

      throw new Error(
        `Migration invalid components: ${
          invalidSnapshots.length
        };\n Invalid visibility: ${visibility};\n Invalid layout: ${layout};\n Invalid level: ${level};\n ${invalidSnapshots
          .map((item) => JSON.stringify(item))
          .join('\n')}
      )}`,
      );
    } else if (
      flow === MigrationFlow.Editor ||
      flow === MigrationFlow.PerPageEditor
    ) {
      // always throw error for visibility issue in Editor flow
      throw new Error(
        `Migration hidden components: ${
          invalidSnapshots.length
        };\n ${invalidSnapshots.map((item) => JSON.stringify(item)).join('\n')}
      )}`,
      );
    } else {
      scope.editorAPI.bi.event(events.sectionsMigration.SITE_STRUCTURE_ERROR, {
        flow,
        origin,
        template_id:
          scope.editorAPI.documentServices.generalInfo.getSiteOriginalTemplateId(),
        commonAmount: invalidSnapshots.length,
        invalidData: JSON.stringify(invalidSnapshots),
      });
    }
  }
}

export async function runSectionMigration(
  scope: Scope,
  {
    origin,
    flow,
    pagesRefs,
  }: {
    origin: MigrationOrigin;
    flow: MigrationFlow;
    pagesRefs: CompRef[];
  },
) {
  if (pagesRefs.length === 0) {
    return;
  }

  const { documentServices, history: historyAPI } = scope.editorAPI;
  const startTime = performance.now();

  beforeMigration(scope, { flow, origin, pagesRefs });
  const initialHistoryLabel = historyAPI.getUndoLastSnapshotLabel();

  try {
    historyAPI.add(BEFORE_SECTIONS_MIGRATION_HISTORY_LABEL);

    const [pagesGrouping] = await Promise.all([
      Promise.all(
        pagesRefs.map((pageRef) => getPageGrouping(scope, pageRef, flow)),
      ),
      (flow === MigrationFlow.Editor ||
        flow === MigrationFlow.Editor2Fix ||
        flow === MigrationFlow.PerPageEditor) &&
        saveBeforeMigration(scope),
    ]);

    await documentServices.transactions.runAndWaitForApproval(async () => {
      const isFullValidationMode = isFullValidationEnabled();
      const isHiddenElementsValidationMode =
        isHiddenElementsValidationEnabled();
      let validationSnapshots;

      if (isFullValidationMode || isHiddenElementsValidationMode) {
        validationSnapshots = collectSnapshots(
          documentServices,
          pagesRefs,
          isFullValidationMode,
        );
      }

      const { createdSections, removedComponents } =
        await migratePagesToSectionsTransaction(scope, {
          flow,
          pagesRefs,
          pagesGrouping,
        });

      if (isEnforceClosedGapsEnabled()) {
        await enforceClosedGaps(documentServices);
      }

      debugReporter(scope, DEBUG.LOG, 'Number of pages', pagesRefs.length);
      debugReporter(scope, DEBUG.LOG, 'Created sections', createdSections);

      if (validationSnapshots) {
        validateSnapshots(scope, {
          flow,
          pagesRefs,
          removedComponents,
          validationSnapshots,
          isFullValidationMode,
        });
      }

      await afterSuccessMigration(scope, {
        flow,
        origin,
        startTime,
        pagesRefs,
      });
      if (
        experiment.isOpen('se_resetModifiedByUserAfterMigration') &&
        flow === MigrationFlow.Template
      ) {
        const editorCoreAPI = scope.editorAPI.host.getAPI(EditorCoreApiKey);
        editorCoreAPI.hooks.stageIsReadyAndVisible.promise.then(() => {
          documentServices.mobileConversion.resetModifiedByUserIndications();
        });
      }
    });
  } catch (error) {
    await afterFailedMigration(scope, {
      error,
      flow,
      origin,
      pagesRefs,
      initialHistoryLabel,
    });

    return Promise.reject(error);
  }
}

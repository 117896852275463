import { hoc } from '#packages/util';
import { createStructuredSelector } from '../selectors/selector';

export const connectMouseOps = <T>(
  mappers: T, //TODO add typing
) =>
  hoc.connect(
    hoc.STORES.MOUSE_OPS,
    createStructuredSelector(mappers),
    () => ({}), //by default redux is passing {dispatch} to props
    undefined,
    undefined,
    false, //disabling errorBoundary
  );

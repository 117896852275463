import React from 'react';
import { translate } from '#packages/i18n';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Heading, Text, TextButton } from '@wix/wix-base-ui';
import * as helpIds from '#packages/helpIds';
import constants from '#packages/constants';
import { AUTOMATION_IDS } from '../consts';
import { origin } from '../addSectionPanelUtil';
import styles from './savedSectionEmptyState.scss';

import type { EditorAPI } from '#packages/editorAPI';

interface SavedSectionEmptyStateProps {
  openHelpCenter: GetFieldType<EditorAPI, 'panelManager.openHelpCenter'>;
}

const SavedSectionEmptyState: React.FC<SavedSectionEmptyStateProps> = ({
  openHelpCenter,
}) => {
  return (
    <div
      data-aid={AUTOMATION_IDS.SAVED_SECTION_EMPTY_STATE}
      className={styles.container}
    >
      <Symbol name="savedSectionsEmptyState" className={styles.illustration} />
      <div className={styles.textContentWrapper}>
        <Heading appearance="h4" shouldTranslate={false}>
          {translate('add_section_saved_sections_empty_state_title')}
        </Heading>

        <div className={styles.textWrapper}>
          <Text
            skin="standard"
            size="small"
            weight="thin"
            shouldTranslate={false}
            enableEllipsis={false}
          >
            {translate('add_section_saved_sections_empty_state_text')}
          </Text>
        </div>

        <TextButton
          skin="standard"
          size="small"
          weight="thin"
          shouldTranslate={false}
          className={styles.learnMoreBtn}
          onClick={() =>
            openHelpCenter(helpIds.addSectionPanel.SAVED_SECTIONS, null, {
              origin,
              component: 'savedSectionsWelcome',
              panel_name: constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
            })
          }
        >
          {translate('add_section_saved_sections_empty_state_cta')}
        </TextButton>
      </div>
    </div>
  );
};

export default SavedSectionEmptyState;

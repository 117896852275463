:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._loaderPanel_gj1pq_7 ._loadingContentWrapper_gj1pq_7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px; }

._loaderPanel_gj1pq_7 .control-base-modal-header {
  display: none; }

._loaderPanel_gj1pq_7 .control-custom-modal {
  width: 503px;
  min-width: 503px; }
  ._loaderPanel_gj1pq_7 .control-custom-modal .control-base-modal-body {
    padding: 0 24px 36px 24px; }
  ._loaderPanel_gj1pq_7 .control-custom-modal .control-custom-modal-content-wrapper {
    padding: 0; }
  ._loaderPanel_gj1pq_7 .control-custom-modal .control-custom-modal-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 455px;
    height: 413px;
    padding: 0;
    flex-direction: column;
    gap: 12px; }
  ._loaderPanel_gj1pq_7 .control-custom-modal .control-preloader {
    width: 27px;
    height: 54px;
    margin-left: -14px; }
    ._loaderPanel_gj1pq_7 .control-custom-modal .control-preloader::before {
      color: #0021ff; }
    ._loaderPanel_gj1pq_7 .control-custom-modal .control-preloader::after {
      color: #5ff2ae; }

._loaderPanel_gj1pq_7 .control-text {
  letter-spacing: 1px; }

._panel_gj1pq_45 .control-base-modal-header {
  border: none; }

._panel_gj1pq_45 .control-custom-modal {
  width: 503px;
  min-width: 503px;
  height: 572px; }
  ._panel_gj1pq_45 .control-custom-modal .control-custom-modal-content-wrapper {
    padding: 0 24px; }

._panel_gj1pq_45 .control-base-modal-header-btn-bar {
  top: 12px;
  right: 12px; }

._panel_gj1pq_45 ._contentWrapper_gj1pq_59 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center; }
  ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._connectDomain_gj1pq_65 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e7f0ff;
    padding: 0 18px;
    height: 60px;
    text-align: start; }
    ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._connectDomain_gj1pq_65 .control-text {
      color: #3b4057; }
  ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 1px solid #dfe5eb;
    border-radius: 4px;
    width: 455px;
    height: 376px;
    margin-top: 12px; }
    ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 18px;
      gap: 35px;
      border-bottom: 1px solid #dfe5eb; }
      ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._text_gj1pq_91 {
        display: flex;
        flex-direction: column;
        align-items: start;
        width: 340px;
        text-align: start; }
      ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        width: 36px; }
        ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 ._result_gj1pq_104 {
          font-weight: 500; }
        ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 ._progressPlaceholder_gj1pq_106 {
          position: absolute; }
        ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 ._progressImage98_gj1pq_108 {
          position: absolute;
          top: -11px;
          left: -6.5px; }
        ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 ._progressImage92_gj1pq_112 {
          position: absolute;
          top: -5.5px;
          left: -6.5px; }
        ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 ._progressImage89_gj1pq_116 {
          position: absolute;
          top: 0;
          left: -7.5px; }
        ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 ._progressImageLow_gj1pq_120 {
          position: absolute;
          top: 0;
          left: 5px; }
        ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 ._categoriesWrapper_gj1pq_75 ._category_gj1pq_84 ._progressImageWrapper_gj1pq_97 .control-text {
          position: absolute; }
  ._panel_gj1pq_45 ._contentWrapper_gj1pq_59 .weight-bold {
    font-weight: var(--wix-font-weight-bold); }

import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import type { PanelData } from '../textAndLinkPanel';
import { biManager } from '../../topBarComponent/mapTopBarPropsToButtons';
import { Action } from '../../actionsButtons/testSite/testSite';
import type { TestSitePanelProps } from './testSitePanel';

export const deriveTestSitePanelDataFromProps = (
  props: TestSitePanelProps,
): PanelData => {
  if (props.actionChoice === Action.TEST_SITE) {
    return {
      title: 'TopBar_DevMode_Preview_TestSiteCTA_Tooltip_Title',
      body: 'TopBar_DevMode_Preview_TestSiteCTA_Tooltip_Description',
      links: [
        {
          titleKey:
            'TopBar_DevMode_Preview_TestSiteCTA_Tooltip_LearnMore_Link_Text',
          onClick() {
            props.sendBi(
              coreBi.events.testSite.TEST_SITE_BUTTON_DROPDOWN_LEARN_MORE,
              {
                panel_name: 'test_site_hover_window',
                help_id: translate(
                  'TopBar_DevMode_Preview_TestSiteCTA_Tooltip_LearnMore_Link_URL',
                ),
                learn_more: true,
                origin: 'panel',
                category: 'test_site',
              },
            );
            window.open(
              translate(
                'TopBar_DevMode_Preview_TestSiteCTA_Tooltip_LearnMore_Link_URL',
              ),
            );
          },
        },
      ],
      onUnmounted: () => biManager.cancelBi('Test Site'),
    };
  }
  return {
    title: 'TopBar_DevMode_Preview_PreviewCTA_Tooltip_Title',
    body: 'TopBar_DevMode_Preview_PreviewCTA_Tooltip_Description',
    links: [
      {
        titleKey:
          'TopBar_DevMode_Preview_PreviewCTA_Tooltip_LearnMore_Link_Text',
        onClick() {
          props.sendBi(
            coreBi.events.testSite.TEST_SITE_BUTTON_DROPDOWN_LEARN_MORE,
            {
              panel_name: 'preview_hover_window',
              help_id: translate(
                'TopBar_DevMode_Preview_PreviewCTA_Tooltip_LearnMore_Link_URL',
              ),
              learn_more: true,
              origin: 'panel',
              category: 'preview',
            },
          );
          window.open(
            translate(
              'TopBar_DevMode_Preview_PreviewCTA_Tooltip_LearnMore_Link_URL',
            ),
          );
        },
      },
    ],
    onUnmounted: () => biManager.cancelBi('Preivew'),
  };
};

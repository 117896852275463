import React, { type FC } from 'react';

import styles from './../styles.scss';

export const Progress98: FC = () => (
  <svg
    width="43"
    height="52"
    viewBox="0 0 43 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.progressImage98}
  >
    <mask
      id="mask0_3562_36771"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="43"
      height="52"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.561 11.5H42.539L43 47.5L20 51.5L0.5 38.5L17.5 0.5L24.561 29.5V11.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3562_36771)">
      <circle
        cx="24.561"
        cy="29.061"
        r="16.061"
        stroke="url(#paint0_linear_3562_36771)"
        strokeWidth="3"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3562_36771"
        x1="-2.26829"
        y1="50.5244"
        x2="37.7317"
        y2="16.378"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0021FF" />
        <stop offset="0.524941" stopColor="#116DFF" />
        <stop offset="1" stopColor="#5FF2AE" />
      </linearGradient>
    </defs>
  </svg>
);

export const Progress92: FC = () => (
  <svg
    width="43"
    height="46"
    viewBox="0 0 43 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.progressImage92}
  >
    <mask
      id="mask0_3562_36789"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="43"
      height="46"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.561 5.5H42.539L43 41.5L20 45.5L0.5 32.5L8.5 0L24.561 23.5V5.5Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3562_36789)">
      <circle
        cx="24.561"
        cy="23.061"
        r="16.061"
        stroke="url(#paint0_linear_3562_36789)"
        strokeWidth="3"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3562_36789"
        x1="-2.26829"
        y1="44.5244"
        x2="37.7317"
        y2="10.378"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0021FF" />
        <stop offset="0.524941" stopColor="#116DFF" />
        <stop offset="1" stopColor="#5FF2AE" />
      </linearGradient>
    </defs>
  </svg>
);

export const Progress89: FC = () => (
  <svg
    width="44"
    height="41"
    viewBox="0 0 44 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.progressImage89}
  >
    <mask
      id="mask0_3562_36807"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="44"
      height="41"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.561 0.500488H43.539L44 36.5005L21 40.5005L1.5 27.5005L0.5 7.00049L25.561 18.5005V0.500488Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3562_36807)">
      <circle
        cx="25.561"
        cy="18.0615"
        r="16.061"
        stroke="url(#paint0_linear_3562_36807)"
        strokeWidth="3"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3562_36807"
        x1="-1.26829"
        y1="39.5249"
        x2="38.7317"
        y2="5.37853"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0021FF" />
        <stop offset="0.524941" stopColor="#116DFF" />
        <stop offset="1" stopColor="#5FF2AE" />
      </linearGradient>
    </defs>
  </svg>
);

export const ProgressLow: FC = () => (
  <svg
    width="31"
    height="41"
    viewBox="0 0 31 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.progressImageLow}
  >
    <mask
      id="mask0_3562_36826"
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="31"
      height="41"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.561 0.500977H30.539L31 36.501L8 40.501L0 39.501L12.561 18.501V0.500977Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_3562_36826)">
      <circle
        cx="12.561"
        cy="18.062"
        r="16.061"
        stroke="url(#paint0_linear_3562_36826)"
        strokeWidth="3"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3562_36826"
        x1="-14.2683"
        y1="39.5254"
        x2="25.7317"
        y2="5.37902"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.055" stopColor="#FF0000" />
        <stop offset="0.825" stopColor="#FFCB11" />
        <stop offset="1" stopColor="#EFF25F" />
      </linearGradient>
    </defs>
  </svg>
);

export const ProgressPlaceholder: FC = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.progressPlaceholder}
  >
    <circle
      cx="17.561"
      cy="18.061"
      r="16.061"
      stroke="#DFE5EB"
      strokeWidth="3"
    />
  </svg>
);

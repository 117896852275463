import type { Scope } from './workspaceRightPanelEntryPoint';
import {
  getCurrentDrillInUniqueIdFromContentSlot,
  getDrillInConditionFunction,
} from './workspaceRightPanelUtils';
import { BasePublicApi } from '#packages/apilib';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { panels } from '#packages/stateManagement';
import constants from '#packages/constants';
import type {
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  WORKSPACE_RIGHT_PANEL_NAMES,
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
} from '#packages/constants';
import { WORKSPACE_RIGHT_PANEL_TAB_GROUP_TITLES } from '#packages/constants';
import type React from 'react';
import type { ZoomModeConfig } from '#packages/zoomMode';
import experiment from 'experiment';
import { zoomMode } from '#packages/util';
import { translate } from '#packages/i18n';

const SITE_SCALE_WHEN_CUSTOM_ZOOM_LABEL = 0.65;

const { ADD_SECTION_PANEL_NAME, DESIGN_PANEL_NAME } =
  constants.ROOT_COMPS.LEFTBAR;

const leftPanelsToClose = [ADD_SECTION_PANEL_NAME, DESIGN_PANEL_NAME];
const { closePanelByName } = panels.actions;

export interface ContextMenuConfig {
  onContextMenuOpen: () => void;
  items: ContextMenuItem[];
}

export interface ContextMenuItem {
  id: string;
  icon: string;
  label: string;
  onClick: () => void;
}

export interface PanelOpenConfig {
  panelName: WORKSPACE_RIGHT_PANEL_NAMES;
  panelWidth: number;
  title: string;
  helpId?: string;
  onClose?: (closeOrigin: string) => void;
  onHelp?: () => void;
  groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES;
  contextMenuConfig?: ContextMenuConfig;
  drillInUniqueIds?: Array<string>;
  enterZoomMode?: boolean;
  zoomModeConfig?: Partial<ZoomModeConfig>;
  leavePanelsOpen?: boolean;
  renderHeader?: boolean;
}

export interface PanelContributedConfig {
  panelName: WORKSPACE_RIGHT_PANEL_NAMES;
  tabTitle?: string;
  isTabDisabled?: () => boolean;
  iconName?: string;
  onOpen: (
    openConfig: any,
    groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  ) => void;
}

export interface DrillInPanelConfig {
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES;
  uniqueId?: string;
  panelTitle?: string;
  backTooltip?: string;
  onClose: () => void;
  isOpen?: boolean;
  drillInUniqueIds?: Array<string>;
  zIndex?: number;
}

export interface DrillInContribution {
  panelConfig: DrillInPanelConfig;
  panelComponent: React.ComponentType<{}>;
}

export interface ContributedPanelData {
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES | WORKSPACE_RIGHT_PANEL_NAMES;
  panelId: string;
}

function contributePanelContent(
  scope: Scope,
  item: React.ComponentType<{}>,
  panelData: PanelContributedConfig,
) {
  const { contentSlot, store } = scope;
  contentSlot.contribute(
    item,
    () => scope.store.getActivePanelName() === panelData.panelName,
  );
  store.contributePanel(panelData);
  const updatePanelConfig = (newPanelConfig: Partial<PanelOpenConfig>) => {
    if (panelData.panelName === store.getActivePanelName()) {
      store.updateActivePanelConfig(newPanelConfig);
    }
  };
  return updatePanelConfig;
}

export function contributeDrillInPanelContent(
  scope: Scope,
  { panelConfig, panelComponent }: DrillInContribution,
) {
  const { drillInContentSlot, store } = scope;
  const drillInIndex = drillInContentSlot.getItems(true).length;
  const conditionFunction = getDrillInConditionFunction(scope, drillInIndex);

  drillInContentSlot.contribute(panelComponent, conditionFunction);

  const uniqueId = getCurrentDrillInUniqueIdFromContentSlot(scope);
  store.contributeDrillInPanel({
    ...panelConfig,
    isOpen: false,
    zIndex: null,
    uniqueId,
  });
  return uniqueId;
}

function contributeMultipleDrillInPanels(
  scope: Scope,
  drillInPanelsContributions: Array<DrillInContribution>,
  drillInUniqueIds: Array<string>,
) {
  const drillInPanelsData = drillInPanelsContributions.map((drillInPanel) => {
    const drillInId = contributeDrillInPanelContent(scope, drillInPanel);
    drillInUniqueIds.push(drillInId);
    return {
      panelName: drillInPanel.panelConfig.panelName,
      panelId: drillInId,
    };
  });
  return drillInPanelsData;
}

function open(
  scope: Scope,
  origin: string,
  openConfig: PanelOpenConfig,
  drillInPanels?: Array<DrillInContribution>,
) {
  const { editorAPI, store, zoomModeApi, fixedStageApi } = scope;

  ErrorReporter.setTags({
    isWorkspaceRightPanelOpen: true,
  });
  const groupType =
    store.getActivePanelName() === openConfig.panelName
      ? store.getGroupType()
      : openConfig.groupType;

  const title =
    ('title' in openConfig && openConfig.title) ||
    (groupType &&
      translate(WORKSPACE_RIGHT_PANEL_TAB_GROUP_TITLES[groupType])) ||
    store.getTitle();

  const groupConfig: Pick<PanelOpenConfig, 'groupType' | 'title'> = {
    groupType,
    title,
  };
  const config = {
    ...openConfig,
    ...groupConfig,
  };

  store.open(config);
  const shouldEnterZoomMode = openConfig.enterZoomMode ?? true;
  const shouldEnableFixedStage = fixedStageApi.isStageFixedInDesktop();

  if (shouldEnterZoomMode) {
    if (!openConfig.zoomModeConfig) {
      openConfig.zoomModeConfig = {};
    }
    if (
      experiment.isOpen('se_differentStageZoomModes') ||
      zoomMode.isNewZoomLabelEnabled()
    ) {
      openConfig.zoomModeConfig.zoomScale =
        openConfig.zoomModeConfig.zoomScale ||
        SITE_SCALE_WHEN_CUSTOM_ZOOM_LABEL;
    }
    if (shouldEnableFixedStage) {
      openConfig.zoomModeConfig.zoomScale =
        editorAPI.zoomMode.getSiteScaleForPanelsOpen();
    }

    zoomModeApi.enterZoomMode({
      ...openConfig.zoomModeConfig,
      biParams: { origin },
      shouldCenterComponentSelection: true,
    });
  }

  if (!openConfig?.leavePanelsOpen) {
    leftPanelsToClose.forEach((panelName) => {
      editorAPI.store.dispatch(closePanelByName(panelName));
    });
  }

  if (drillInPanels) {
    const drillInUniqueIds = store.getActivePanelDrillInUniqueIds();
    const drillInPanelsData = contributeMultipleDrillInPanels(
      scope,
      drillInPanels,
      drillInUniqueIds,
    );
    store.updateActivePanelConfig({
      drillInUniqueIds,
    });
    return drillInPanelsData;
  }
}

function updateActivePanelTitle(scope: Scope, title: string) {
  const { store } = scope;
  store.updateActivePanelConfig({ title });
}

export function openDrillInPanel(
  scope: Scope,
  uniqueId: string,
  updatedConfig?: Partial<DrillInPanelConfig>,
  drillInPanels?: Array<DrillInContribution>,
) {
  const { store } = scope;
  const zIndex = store.getActiveDrillInUniqueIds().length + 1;
  store.openDrillIn(uniqueId, zIndex, updatedConfig);
  if (drillInPanels) {
    const drillInIds = store.getActiveDrillInDrillInUniqueIds();
    const drillInPanelsData = contributeMultipleDrillInPanels(
      scope,
      drillInPanels,
      drillInIds,
    );
    store.updateDrillInConfig(uniqueId, {
      drillInUniqueIds: drillInIds,
    });
    return drillInPanelsData;
  }
}

export function closeDrillInPanel(scope: Scope) {
  const { drillInContentSlot, store } = scope;
  const uniqueId =
    store.getActiveDrillInUniqueIds()[
      store.getActiveDrillInUniqueIds().length - 1
    ];
  const onClose = scope.store.getActiveDrillInOnClose();
  onClose?.();
  store.closeDrillIn();
  const drillInUniqueIds =
    store.getContributedDrillInPanels()[uniqueId].drillInUniqueIds;
  drillInUniqueIds.forEach((id) =>
    drillInContentSlot.discardBy((item) => item.uniqueId === id),
  );
}

function discardAllDrillInPanels(scope: Scope) {
  const { store, drillInContentSlot } = scope;
  store.removeAllDrillInPanels();
  drillInContentSlot.discardBy(() => true);
}

export function closeAllDrillInPanels(scope: Scope) {
  const { drillInContentSlot, store } = scope;
  const mainPanelDrillInUniqueIds = store.getActivePanelDrillInUniqueIds();
  const mainPanelDrillInsConfigs = mainPanelDrillInUniqueIds.map(
    (id: string) => {
      return store.getContributedDrillInPanels()[id];
    },
  );
  store.removeAllDrillInPanels();
  mainPanelDrillInsConfigs.forEach((panelConfig) => {
    store.contributeDrillInPanel({
      ...panelConfig,
      isOpen: false,
      zIndex: null,
    });
  });
  drillInContentSlot.discardBy(
    (item: any) => !mainPanelDrillInUniqueIds.includes(item.uniqueId),
  );
}

export function onHelp(scope: Scope) {
  const onHelp = scope.store.getOnHelp();
  if (typeof onHelp === 'function') {
    onHelp();
  }
}

export function close(
  scope: Scope,
  closeOrigin: string,
  shouldExitZoomMode: boolean = true,
): Promise<void> {
  const { editorAPI } = scope;

  ErrorReporter.setTags({
    isWorkspaceRightPanelOpen: false,
  });
  if (!scope.store.getIsOpened()) {
    return;
  }
  if (scope.store.getActiveDrillInUniqueIds().length) {
    discardAllDrillInPanels(scope);
  }
  const onClose = scope.store.getOnClose();
  if (typeof onClose === 'function') {
    onClose(closeOrigin);
  }
  scope.store.close();
  if (!shouldExitZoomMode) {
    return Promise.resolve();
  }

  return editorAPI.zoomMode?.isZoomedByUser()
    ? scope.zoomModeApi.enterZoomMode({
        zoomScale: 0.5,
        biParams: { origin },
        shouldCenterComponentSelection: true,
      })
    : scope.zoomModeApi.exitZoomMode({
        biParams: {
          origin: 'WorkspaceRightPanel',
          zoom_mode_type: zoomMode.isNewZoomLabelEnabled() ? '75%' : '50%',
        },
        originalScrollY: editorAPI.documentServices.site.getScroll()?.y || 0,
      }); // TODO: use currently opened panel as origin
}

export function isOpen(scope: Scope) {
  return scope.store.getIsOpened();
}

export function isDrillInOpen({ store }: Scope) {
  return Boolean(store.getActiveDrillInUniqueIds().length);
}

export function getPanelWidth(scope: Scope) {
  return scope.store.getPanelWidth();
}

export class WorkspaceRightPanelApi extends BasePublicApi<Scope> {
  contributePanelContent = this.bindScope(contributePanelContent);
  contributeDrillInPanel = this.bindScope(contributeDrillInPanelContent);
  open = this.bindScope(open);
  close = this.bindScope(close);
  isOpen = this.bindScope(isOpen);
  getPanelWidth = this.bindScope(getPanelWidth);
  isDrillInOpen = this.bindScope(isDrillInOpen);
  openDrillInPanel = this.bindScope(openDrillInPanel);
  closeAllDrillInPanels = this.bindScope(closeAllDrillInPanels);
  updateActivePanelTitle = this.bindScope(updateActivePanelTitle);
}

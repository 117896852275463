import { getMediaSrc } from '../utils/getMediaSrc';
import * as util from '#packages/util';
import constants from '#packages/constants';

export const HOOKS = {
  addPanel: util.addPanel.isNewPanelEnabled()
    ? '[data-automation-id="leftBarButton-addPanelInfra.newAddPanel"]'
    : '[data-automation-id="leftBarButton-addPanelInfra.addPanel"]',
  addPanelLabel: util.addPanel.isNewPanelEnabled()
    ? '[data-aid="leftBarButton-addPanelInfra.newAddPanel-label"]'
    : '[data-aid="leftBarButton-addPanelInfra.addPanel-label"]',
  pagesPanel: '[data-automation-id="leftBarButton-pages.pagesPanel"]',
  pagesPanelLabel: '[data-aid="leftBarButton-pages.pagesPanel-label"]',
  addSection:
    '[data-automation-id="leftBarButton-addPreset.panels.addSectionPanel"]',
  addSectionLabel:
    '[data-aid="leftBarButton-addPreset.panels.addSectionPanel-label"]',
  stage: '[data-aid="edit-box-layout"]',
  preview: '[data-aid="top-bar-button-preview"]',
  publish: '[data-aid="top-bar-group-button-publish-wrapper"]',
  deviceSwitch: '[data-aid="top-bar-device-switch-section"]',
  helpWelcome: '[data-aid="list-item-help-welcome-tour"]',
  helpDropDown: '[data-aid="drop-panel-help"]',
  helpMenuBarItem: '[data-aid="menu-bar-item-help"]',
  tourBackdrop: '[data-hook="tour-backdrop"]',
  tourBackdropWrapper: '#tour-backdrop-wrapper',
  tourTooltipWrapper: '#tour-tooltip-wrapper',
  leftBar: '[data-hook="left-bar-container"]',
  actionsBar: '[data-hook="actions-bar-wrapper"]',
  addSectionButtonContainer: '[data-hook="add-section-button-container"]',
  sectionNameLabel: '[data-hook="section-name-label"]',
  sectionEdgeResizeButton: '[data-hook="section-edge-resize-button"]',
  smartWriter: '[data-aid="gfpp-smart-writer-aid"]',
  zoom: '[data-hook="top-bar-button-zoom"]',
  gfppButtons: '[data-hook="gfpp-buttons-list"]',
  leftBarLabel: '[data-hook="left-bar-item"] + div',
  forwardButton: '[data-aid="tour-tooltip-button-forwardButton"]',
  dropPanelHelp: '[data-aid="drop-panel-help"]',
  addSectionPanel: '[data-aid="add-section-panel"]',
  rightPanel: '[data-hook="workspace-right-panel"]',
};

export const MEDIA = getMediaSrc({
  addPanel: {
    regular: 'editorTours/welcomeTour/addPanel.mp4',
    retina: 'editorTours/welcomeTour/addPanelRetina.mp4',
  },
  pagesPanel: {
    regular: 'editorTours/welcomeTour/pages.mp4',
    retina: 'editorTours/welcomeTour/pagesRetina.mp4',
  },
  stage: {
    regular: 'editorTours/welcomeTour/textOnStage.mp4',
    retina: 'editorTours/welcomeTour/textOnStageRetina.mp4',
  },
  deviceSwitch: {
    regular: 'editorTours/welcomeTour/mobile.mp4',
    retina: 'editorTours/welcomeTour/mobileRetina.mp4',
  },
  publish: {
    regular: 'editorTours/welcomeTour/viewAndPublis.mp4',
    retina: 'editorTours/welcomeTour/viewAndPublishRetina.mp4',
  },
  leftBar: {
    regular: 'editorTours/welcomeTour/addPanel.mp4',
    retina: 'editorTours/welcomeTour/addPanelRetina.mp4',
  },
  smartWriter: {
    regular: 'editorTours/welcomeTour/smartWriter.mp4',
    retina: 'editorTours/welcomeTour/smartWriterRetina.mp4',
  },
  add: {
    regular: 'editorTours/adiToEditorTour/add.mp4',
    retina: 'editorTours/adiToEditorTour/add.mp4',
  },
  dragRotate: {
    regular: 'editorTours/adiToEditorTour/dragRotate.mp4',
    retina: 'editorTours/adiToEditorTour/dragRotate.mp4',
  },
  gfpp: {
    regular: 'editorTours/adiToEditorTour/gfpp.mp4',
    retina: 'editorTours/adiToEditorTour/gfpp.mp4',
  },
  intro: {
    regular: 'editorTours/adiToEditorTour/intro.mp4',
    retina: 'editorTours/adiToEditorTour/intro.mp4',
  },
  zoomOut: {
    regular: 'editorTours/adiToEditorTour/zoomOut.mp4',
    retina: 'editorTours/adiToEditorTour/zoomOut.mp4',
  },
  introE2E: {
    regular: 'editorTours/editorToEditorTour/intro.mp4',
    retina: 'editorTours/editorToEditorTour/intro.mp4',
  },
  changeSec: {
    regular: 'editorTours/editorToEditorTour/changeSec.mp4',
    retina: 'editorTours/editorToEditorTour/changeSec.mp4',
  },
  addSec: {
    regular: 'editorTours/editorToEditorTour/addSec.mp4',
    retina: 'editorTours/editorToEditorTour/addSec.mp4',
  },
  quickEdit: {
    regular: 'editorTours/editorToEditorTour/quickEdit.mp4',
    retina: 'editorTours/editorToEditorTour/quickEdit.mp4',
  },
});

export const componentTypes = [
  constants.COMP_TYPES.SITE_BUTTON,
  constants.COMP_TYPES.ICON_BUTTON,
  constants.COMP_TYPES.STYLABLE_BUTTON,
  constants.COMP_TYPES.TEXT,
  constants.COMP_TYPES.SHAPE,
  constants.COMP_TYPES.FIVE_GRID_LINE,
  constants.COMP_TYPES.MEDIA_PLAYER,
  constants.COMP_TYPES.VERTICAL_LINE,
  constants.COMP_TYPES.PHOTO,
  constants.COMP_TYPES.DOCUMENT_MEDIA,
  constants.COMP_TYPES.ANCHORS_MENU,
];

import React, { PureComponent } from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import withSendBi from '../../../hocs/withSendBi';
import { mapDispatchToProps } from './backToEditorFromZoomMappers';
import BackToEditorButton from '../backToEditor';
import type { BackToEditorFromZoomDispatchProps } from './backToEditorFromZoomMappers';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

interface BackToEditorFromZoomButtonProps
  extends BackToEditorFromZoomDispatchProps {}

class BackToEditorFromZoomButton extends PureComponent<BackToEditorFromZoomButtonProps> {
  handleClick = () => {
    this.props.exitMode();
  };

  render() {
    const label = translate('Topbar_ZoomOutReorder_Exit_Button');
    return <BackToEditorButton label={label} onClick={this.handleClick} />;
  }
}

const ConnectedBackToEditorFromZoomButton = _.flow(
  connect(EDITOR_API, null, mapDispatchToProps),
  withSendBi,
)(BackToEditorFromZoomButton);

ConnectedBackToEditorFromZoomButton.pure = BackToEditorFromZoomButton;

export default ConnectedBackToEditorFromZoomButton;

import type { EditorAPI } from '@wix/editor-elements-types/editor';
import type { Dispatch, DispatchMapperArgs } from 'types/redux';
import type { EditorState } from '#packages/stateManagement';
import * as util from '#packages/util';

const EDITOR_BASE_URL = 'https://editor.wix.com/';
const CODE_PACKAGES_URL = 'wix-code-ide-server/';

interface DispatcherConfig {
  baseUrl: string;
  editorAPI: EditorAPI;
}

export enum RequestParser {
  Json,
  Text,
}

interface HttpDispatcherOptions {
  buildUrl: () => string;
  buildSiHeader: () => string;
  requestParser?: RequestParser;
  abort?: boolean;
}

type HttpDispatcherParamsInitializer<T extends Array<any>> = (
  config: DispatcherConfig,
  ...args: T
) => HttpDispatcherOptions;

export const httpDispatcher = <T extends Array<any> = Array<any>>(
  paramsInitializer: HttpDispatcherParamsInitializer<T>,
) => {
  const fetch = async (editorAPI: EditorAPI, ...args: T): Promise<string> => {
    const { buildUrl, buildSiHeader, requestParser, abort } = paramsInitializer(
      { editorAPI, baseUrl: `${EDITOR_BASE_URL}${CODE_PACKAGES_URL}` },
      ...args,
    );

    if (abort) {
      return null;
    }

    const response = await util.http.fetch(
      buildUrl(),
      securityHeader(buildSiHeader()),
    );

    if (!response.ok) {
      if (response.status === 404) {
        return '';
      }

      return parseNetworkError(response);
    }

    switch (requestParser) {
      case RequestParser.Json:
        return response.json();
      case RequestParser.Text:
      default:
        return response.text();
    }
  };

  return (...args: T) =>
    (
      dispatch: Dispatch,
      getState: () => EditorState,
      { editorAPI }: DispatchMapperArgs,
    ) =>
      fetch(editorAPI, ...args);
};

const securityHeader = (siHeader: string): RequestInit => ({
  mode: 'cors',
  credentials: 'include',
  headers: {
    'x-wix-si': siHeader,
  },
});

const parseNetworkError = async (response: AnyFixMe): Promise<string> => {
  let details;
  try {
    const resObj = (await response.json()) as any;
    details = resObj.Message || resObj.errorDescription;
  } catch {
    details = response.statusText;
  }

  return details;
};

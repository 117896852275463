import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 786,
        height: 519,
        x: 12,
        y: 13,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'xzig1',
      },
      parent: 'comp-kguvw9vg',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'xzig1',
        },
        title: '',
        uri: '11062b_7c72239f5a8f47eeac75678a875bd8f6~mv2.jpg',
        description: '',
        width: 3360,
        height: 2240,
        alt: 'Two Models',
        name: 'Two Models',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'xzig1',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      style: 'wp2',
    },
    {
      type: 'Container',
      components: [
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 208,
            height: 18,
            x: 20,
            y: 25,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'xzig1',
          },
          parent: 'comp-kguvw9vq1',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'xzig1',
            },
            text: `<h2 class="font_2" style="font-size:14px"><span style="color:#000000"><span style="font-family:barlow-medium,barlow,sans-serif"><span style="font-size:14px">${translate(
              'interactions_preset_showcase_text_1',
            )}</span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'xzig1',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          style: 'txtNew',
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 208,
            height: 27,
            x: 20,
            y: 54,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'xzig1',
          },
          parent: 'comp-kguvw9vq1',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'xzig1',
            },
            text: `<h2 class="font_2" style="font-size:23px"><span style="color:#000000"><span style="font-size:23px"><span style="font-family:georgia,palatino,book antiqua,palatino linotype,serif"><span style="letter-spacing:0.05em">${translate(
              'interactions_preset_showcase_text_2',
            )}</span></span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'xzig1',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          style: 'txtNew',
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.line.SolidLine',
          layout: {
            width: 42,
            height: 5,
            x: 20,
            y: 105,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.FiveGridLine',
          metaData: {
            pageId: 'xzig1',
          },
          parent: 'comp-kguvw9vq1',
          props: {
            type: 'FiveGridLineProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'xzig1',
            },
            fullScreenModeOn: false,
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'xzig1',
            },
            style: {
              properties: {
                'alpha-brd': '1',
                brd: '#000000',
                lnw: '1',
              },
              propertiesSource: {
                'alpha-brd': 'value',
                brd: 'value',
                lnw: 'value',
              },
              groups: {},
            },
            componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.line.SolidLine',
          },
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 208,
            height: 40,
            x: 20,
            y: 161,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'xzig1',
          },
          parent: 'comp-kguvw9vq1',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'xzig1',
            },
            text: `<p class="font_8" style="font-size:16px"><span style="color:#000000"><span style="font-family:barlow-medium,barlow,sans-serif"><span style="font-size:16px">${translate(
              'interactions_preset_showcase_text_3',
            )}</span></span></span></p>\n\n<p class="font_8" style="font-size:16px"><span style="color:#000000"><span style="font-family:barlow-medium,barlow,sans-serif"><span style="font-size:16px">${translate(
              'interactions_preset_showcase_text_4',
            )}</span></span></span></p>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'xzig1',
            },
            brightness: 1,
            packed: false,
            minHeight: 40,
            verticalText: false,
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-in-out',
            duration: 0.4,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'xzig1',
            },
          },
          scopedTransformations: {
            'variants-kguvw9vn': {
              type: 'TransformData',
              rotate: 0,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'xzig1',
              },
              translate: {
                x: {
                  type: 'px',
                  value: 0,
                },
                y: {
                  type: 'px',
                  value: -40,
                },
              },
            },
          },
          style: 'txtNew',
        },
      ],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 240,
        height: 226,
        x: 510,
        y: 57,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'xzig1',
      },
      parent: 'comp-kguvw9vg',
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xzig1',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xzig1',
        },
      },
      scopedTransformations: {
        'variants-kguvw9vn': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xzig1',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xzig1',
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-brd': '1',
            bg: '#FFFFFF',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brw: '0',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'theme',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
    },
    {
      type: 'Component',
      skin: 'wixui.skins.Skinless',
      layout: {
        width: 142,
        height: 40,
        x: 528,
        y: 229,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wixui.StylableButton',
      metaData: {
        pageId: 'xzig1',
      },
      parent: 'comp-kguvw9vg',
      data: {
        type: 'StylableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xzig1',
        },
        label: translate('interactions_preset_showcase_link_text'),
        svgId: '6eea42305e4348869bdaf7b2d13b1bb5.svg',
      },
      props: {
        type: 'StylableButtonProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'xzig1',
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xzig1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xzig1',
        },
      },
      scopedTransformations: {
        'variants-kguvw9vn': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xzig1',
          },
          rotate: 0,
        },
        'variants-kgz77vq1': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xzig1',
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xzig1',
        },
        style: {
          properties: {
            '$st-css':
              ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: rgba(0,0,0,0)}.root:hover{background: #FFFFFF;border: 0px solid rgb(0, 0, 0)}.root:hover::label{\n    color: rgba(0,0,0,0.75)}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit;justify-content: flex-start}.root::label{transition: inherit;font-size: 12px;letter-spacing: 0em;font-family: barlow-medium,barlow,sans-serif;color: #000000;margin: 0px 5.5px 0px 0px}.root::icon{transition: inherit;fill: #000000;width: 28px;height: 28px;margin: 0px 0px 0px 5.5px}.root:hover::icon{fill: #000000}",
          },
          propertiesSource: {
            '$st-css': 'value',
          },
        },
        componentClassName: 'wixui.StylableButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wixui.skins.Skinless',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 810,
    height: 551,
    x: 85,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'xzig1',
  },
  parent: 'xzig1',
  variants: {
    'variants-kguvw9vn': {
      componentId: 'comp-kguvw9vg',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'xzig1',
      },
    },
  },
  scopedTransformations: {
    'variants-kguvw9vn': {
      type: 'TransformData',
      zLayer: 'BELOW_PINNED',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'xzig1',
      },
      rotate: 0,
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'xzig1',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};

import React, { type ReactNode } from 'react';
// @ts-expect-error
import coreMultilingual from 'coreMultilingual';
import * as symbols from '@wix/santa-editor-symbols';

import * as util from '#packages/util';

import TopBarButton from '../topBarButton/topBarButtonWithTooltip';

const customSymbol = React.createElement(symbols.symbol, { name: 'arrowDown' });

type TODOAny = any;

export interface TopBarFlagButtonProps {
  className?: string;
  automationId?: string;
  countryCode?: string; // Language code and country code may be null!
  languageCode?: string;
  toolTip?: ReactNode;
  dropDownButtonProps?: TODOAny;
  shouldCurrentLanguageInvisible: boolean;
  shouldHideLanguageIcon: boolean;
  onClick: () => void;
}

class TopBarFlagButton extends React.Component<TopBarFlagButtonProps> {
  componentDidMount() {
    util.multilingual.localeDatasetTranslationsFetcher.addTranslations();
  }

  getFlagUrl(countryCode: AnyFixMe) {
    return coreMultilingual.utils.getFlagIconUrl(
      util.serviceTopology.scriptsLocationMap['linguist-flags'],
      coreMultilingual.flagCountryIconType.rounded,
      countryCode,
    );
  }

  oldDesign() {
    return (
      <div className="flag-icon">
        {this.props.countryCode ? (
          <img
            key="top-bar-flag-icon"
            src={this.getFlagUrl(this.props.countryCode)}
            alt={this.props.languageCode}
          />
        ) : null}
      </div>
    );
  }

  newDesign() {
    const { shouldCurrentLanguageInvisible } = this.props;
    return (
      <>
        {!this.props.shouldHideLanguageIcon && (
          <div
            className={util.cx([
              'flag-icon',
              { 'flag-icon-invisible': shouldCurrentLanguageInvisible },
            ])}
          >
            {this.props.countryCode ? (
              <>
                <img
                  key="top-bar-flag-icon"
                  src={this.getFlagUrl(this.props.countryCode)}
                  alt={this.props.languageCode}
                />
                {shouldCurrentLanguageInvisible && (
                  <symbols.symbol
                    name="hiddenPageStatus"
                    key="languageInvisible-symbol"
                  />
                )}
              </>
            ) : null}
          </div>
        )}
        <div className="languageCode">
          {this.props.languageCode?.toUpperCase()}
        </div>
        <div>{customSymbol}</div>
      </>
    );
  }

  render() {
    const buttonClassName = util.topBar.isNewTopBarEnabled()
      ? 'language-selector-top-bar-button-new'
      : 'language-selector-top-bar-button';

    return (
      <TopBarButton
        tooltip={this.props.toolTip}
        automationId="top-bar-button-flag"
        onClick={this.props.onClick}
        {...this.props.dropDownButtonProps}
        className={util.cx(
          buttonClassName,
          this.props.dropDownButtonProps?.className,
        )}
      >
        {util.topBar.isNewTopBarEnabled() ? this.newDesign() : this.oldDesign()}
      </TopBarButton>
    );
  }
}

export default TopBarFlagButton;

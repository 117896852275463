import React, { useContext, useEffect, useRef } from 'react';
import * as stateManagement from '#packages/stateManagement';
import type { CompRef } from 'types/documentServices';
import constants from '#packages/constants';
import { cx, hoc } from '#packages/util';
import {
  mapStateToProps,
  mapDispatchToProps,
  type CompRefNode,
  type LayoutAndTranformationsMap,
} from './interactionsEditBoxMapper';
import { XYIndicator } from './components/xyIndicator';
import { ConnectingLine } from './components/connectingLine';
import { SelectedOriginalLayout } from './components/selectedOriginalLayout';
import { TriggerLayout } from './components/triggerLayout';

const { InteractionsContext, reRenderConsumers } =
  stateManagement.interactions.context;

export interface EditBoxRefs<T> extends React.RefObject<T> {
  current: T | null;
  isEditBoxRefs: boolean;
  layoutRef: React.RefObject<T>;
  originalLayoutRef: React.RefObject<T>;
}

export const isEditBoxRefs = (
  ref: React.Ref<HTMLDivElement>,
): ref is EditBoxRefs<HTMLDivElement> => {
  return (ref as EditBoxRefs<HTMLDivElement>).isEditBoxRefs;
};

export interface InteractionsEditBoxProps {
  selectedCompRef: CompRef;
  selectedShownOnlyInVariant: boolean;
  exitMode: () => void;
  triggerTree: CompRefNode;
  componentUIColor: string;
  navOffsetLeft: number | string;
  shouldConsiderNavControlsOffset: boolean;
  selectTrigger: () => void;
  layoutAndTranformationsMap: LayoutAndTranformationsMap;
  renderRegularEditBox: () => any;
  registerDragMouseMoveAction: (
    event: React.MouseEvent,
    isDraggingWithHandle?: boolean,
  ) => void;
}

let lastHoveredElem: HTMLElement;
const addHoverClass = (elem: HTMLElement) => {
  if (lastHoveredElem) {
    lastHoveredElem.classList.remove('hovered');
  }
  elem.classList.add('hovered');

  lastHoveredElem = elem;
};

const editBoxRefs: EditBoxRefs<HTMLDivElement> = {
  current: null,
  isEditBoxRefs: true,
  layoutRef: null,
  originalLayoutRef: null,
};

let layoutBoundingBox: AnyFixMe;
let originalLayoutBoundingBox: AnyFixMe;

function InteractionsEditBox({
  selectedCompRef,
  selectedShownOnlyInVariant,
  componentUIColor,
  exitMode,
  triggerTree,
  selectTrigger,
  navOffsetLeft,
  shouldConsiderNavControlsOffset,
  layoutAndTranformationsMap,
  renderRegularEditBox,
  registerDragMouseMoveAction,
}: InteractionsEditBoxProps) {
  const { offsetX, offsetY, dragging, rotateDisabled, skewDisabled } =
    useContext(InteractionsContext);

  editBoxRefs.layoutRef = useRef(null);
  editBoxRefs.originalLayoutRef = useRef(null);

  useEffect(
    () => {
      const layoutElem = editBoxRefs.layoutRef.current;
      const originalLayoutElem = editBoxRefs.originalLayoutRef.current;

      if (layoutElem && originalLayoutElem) {
        layoutBoundingBox = layoutElem.getBoundingClientRect();
        originalLayoutBoundingBox = originalLayoutElem.getBoundingClientRect();
      }

      return () => {
        layoutBoundingBox = originalLayoutBoundingBox = undefined;
      };
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedCompRef,
      offsetX,
      offsetY,
      editBoxRefs.layoutRef?.current?.style?.transform,
      editBoxRefs.originalLayoutRef?.current?.style?.transform,
    ],
  );

  useEffect(() => {
    reRenderConsumers();
  }, [selectedCompRef]);

  const triggerData = layoutAndTranformationsMap[triggerTree.compRef.id];

  return (
    <div
      className={cx('interactions-edit-box', {
        'trigger-not-selected': !triggerData.isSelected,
        'selected-shown-only-in-variant': selectedShownOnlyInVariant,
        'components-ui-color-orange':
          componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE,
      })}
    >
      <TriggerLayout
        dragging={dragging}
        ref={editBoxRefs}
        triggerTree={triggerTree}
        selectTrigger={selectTrigger}
        layoutAndTranformationsMap={layoutAndTranformationsMap}
        selectedOffsetX={offsetX}
        selectedOffsetY={offsetY}
        navOffsetLeft={
          shouldConsiderNavControlsOffset ? navOffsetLeft : undefined
        }
        rotateDisabled={rotateDisabled}
        skewDisabled={skewDisabled}
        renderRegularEditBox={renderRegularEditBox}
        exitMode={exitMode}
        addHoverClass={addHoverClass}
        registerDragMouseMoveAction={registerDragMouseMoveAction}
      />

      {triggerData.isSelected && (
        <SelectedOriginalLayout
          ref={editBoxRefs.originalLayoutRef}
          selected={triggerData}
        />
      )}

      {layoutBoundingBox && originalLayoutBoundingBox && (
        <ConnectingLine
          layoutBoundingBox={layoutBoundingBox}
          originalLayoutBoundingBox={originalLayoutBoundingBox}
        />
      )}

      {layoutBoundingBox && (
        <XYIndicator
          dragging={dragging}
          layoutBoundingBox={layoutBoundingBox}
          offsetX={offsetX}
          offsetY={offsetY}
        />
      )}
    </div>
  );
}

const ConnectedComponent = hoc.connect(
  hoc.STORES.MOUSE_OPS,
  mapStateToProps,
  mapDispatchToProps,
)(InteractionsEditBox);

ConnectedComponent.pure = InteractionsEditBox;

export default ConnectedComponent;

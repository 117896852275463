import { cx, hoc } from '#packages/util';
import React, { useState } from 'react';
import experiment from 'experiment';
import { Text } from '@wix/wix-base-ui';
import { AUTOMATION_IDS } from '../constants';
import styles from './SectionsDropZone.scss';
import {
  mapStateToProps,
  type SectionsDropZoneStateProps,
} from './SectionsDropZoneMappers';
import { useTranslation } from 'react-i18next';

export interface SectionDropZoneOwnProps {
  style: React.CSSProperties;
  isDragging: boolean;
}

interface SectionDropZoneProps
  extends SectionDropZoneOwnProps,
    SectionsDropZoneStateProps {}

const SectionsDropZoneComponent: React.FC<SectionDropZoneProps> = ({
  style,
  left,
  width,
  isDragging,
}) => {
  const { t } = useTranslation();
  const addDragText = t('section_add_drag_placeholder');
  const addReleaseText = t('section_add_release_placeholder');

  const [isSectionHovered, setIsSectionHovered] = useState(false);

  const shouldShowAddReleaseText =
    isDragging && experiment.isOpen('se_releaseDragText') && isSectionHovered;

  const handleMouseEnter = () => {
    setIsSectionHovered(true);
  };
  const handleMouseLeave = () => {
    setIsSectionHovered(false);
  };

  return (
    <div
      className={cx(styles.sectionsDropZoneWrapper, {
        [styles.isDragging]: isDragging,
      })}
      style={{
        left,
        width,
        ...style,
      }}
      data-hook={AUTOMATION_IDS.DROPZONE}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.sectionsDropZoneBody}>
        <Text size="small" shouldTranslate={false} enableEllipsis={false}>
          <span className={styles.sectionsDropZoneBody__text}>
            {shouldShowAddReleaseText ? addReleaseText : addDragText}
          </span>
        </Text>
      </div>
    </div>
  );
};

export const SectionsDropZone = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(SectionsDropZoneComponent);

import type { Dispatch, DispatchMapperArgs } from 'types/redux';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import {
  privateAppsInstallAppClick,
  privateAppsInstallAppSuccess,
} from '@wix/bi-logger-blocks-consumption/v2';

import * as tpa from '#packages/tpa';
import * as util from '#packages/util';
import constants from '#packages/constants';
import type { EditorState } from '#packages/stateManagement';
import * as stateManagement from '#packages/stateManagement';

import { TEST_VERSION } from './appDetailsUtils';
import type { AppData } from '../components/privateAppsProvider/privateAppsProvider';

const biData = { builderType: 'classic', origin: 'app details page' };
const { ADD_APPS_PANEL_NAME } = constants.ROOT_COMPS.LEFTBAR;

export function getAppInstallVersion(
  app: AppData,
  versionToInstall?: string,
): string {
  return util.appStudioUtils.isAppMajorVersionBuilt(app.latestVersion)
    ? versionToInstall ?? app.latestVersion
    : TEST_VERSION;
}

export interface InstallAppOptions {
  app: AppData;
  version: string;
}

export interface InstallAppDispatchProps {
  installApp: (
    app: AppData,
    versionToInstall?: string,
  ) => ReturnType<ReturnType<typeof installApp>>;
}

export const installApp =
  ({ app, version }: InstallAppOptions) =>
  (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: DispatchMapperArgs,
  ) =>
    new Promise(function (resolve, reject) {
      util.biLogger.report(
        privateAppsInstallAppClick({ appId: app.appDefinitionId, ...biData }),
      );
      editorAPI.panelManager.closeAllPanels();
      const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
      const biInitiator = tpa.constants.BI.initiator.EDITOR;
      const isCodePackageOnly =
        util.appStudioUtils.getAppType(app) ===
        util.appStudioUtils.AppType.CodePackage;

      const callback = (data: any) => {
        if (data?.success) {
          util.fedopsLogger.interactionEnded(
            util.fedopsLogger.INTERACTIONS.BLOCKS.IMPORT_APP,
          );
          util.biLogger.report(
            privateAppsInstallAppSuccess({
              appId: app.appDefinitionId,
              version,
              ...biData,
            }),
          );

          if (isCodePackageOnly) {
            editorAPI.panelManager.openPanel(ADD_APPS_PANEL_NAME, {
              urlParams: {},
              selectedSection: {
                id: 'private-apps',
                props: {
                  initialAppDefId: data.appDefinitionId,
                },
              },
            });
          }

          return resolve(data);
        }
        return reject(data);
      };

      const platformOrigin = {
        type: EditorType.Classic,
        initiator: InstallInitiator.Editor,
        info: {
          type: InstallationOriginType.IMPORT_PANEL,
        },
      };

      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.BLOCKS.IMPORT_APP,
      );

      try {
        tpa.services.tpaAddAppService.addApp(
          editorAPI,
          app.appDefinitionId,
          null,
          null,
          type,
          biInitiator,
          false,
          {},
          {
            appVersion:
              !version.startsWith('^') && version !== 'latest'
                ? `^${version}`
                : version,
            shouldOpenFakeProgressBar: true,
            headlessInstallation: false,
            disableAddPanel: isCodePackageOnly,
          },
          callback,
          platformOrigin,
        );
      } catch (e) {
        reject(e);
      }
    }).catch(() => {
      const ERROR_PANEL_NAME = 'tpa.panels.appImportErrorPanel';

      const onClose = () => {
        dispatch(
          stateManagement.panels.actions.closePanelByNameAction(
            ERROR_PANEL_NAME,
          ),
        );
      };

      dispatch(
        stateManagement.panels.actions.updateOrOpenPanel(ERROR_PANEL_NAME, {
          onClose,
        }),
      );
    });

/*eslint max-lines: [2, { "max": 1400, "skipComments": true, "skipBlankLines": true}]*/
import type React from 'react';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import { utils as coreUtils } from '#packages/core';
import { translate } from '#packages/i18n';
import { pagesAPI } from '#packages/pages';
import {
  isApplicationInstalled,
  origin as addPageOrigin,
  getPagesExternalPresetOptions,
  getAddPagePanelPlatformOrigin,
  shouldDisablePostInstallSecondaryAction,
} from '../addPagePanelUtil';
import {
  getFontsUrls,
  getSiteThemeMap,
  extractStructurePromise,
  fixSerializedApplicationIds,
  getAppDefinitionToApplicationIdMap,
  installAppsByOrder,
  translatePageSectionsNamesIfNeeded,
  getCategoryIndex,
  getCategoryWithAddedProps,
  getPreviewComponentsEntryFromStructure,
  removeTransparentBoxesIfNeeded,
  convertBusinessTypeToBiString,
  fixStructureMobileHints,
  filterCategory,
  overridePageCompsStructureIfApplicable,
} from '../../addPresetUtil';
import { WorkspaceRightPanelApiKey } from '#packages/apis';
import { loadPages, loadPagesCategories } from '../../addPresetApi';
import { WIX_FORMS, WIX_PRO_GALLERY } from '@wix/app-definition-ids';
import experiment from 'experiment';
import {
  REPLACE_PAGE_NOTIFICATION_TIMEOUT,
  SCANNING_WIDTH,
  TPA_CLONE_DATA_APPS,
} from '../../addPresetConsts';
import * as platformEvents from 'platformEvents';

import type { SiteGlobalDataApi } from '#packages/siteGlobalData';
import type { ContentInjectionApi } from '#packages/contentInjection';
import type { EditorInteractionName } from 'types/fedops';
import type { PreviewData } from '#packages/previewer';
import type {
  ThemeMap,
  PagePresetDefinition,
  AddPageCategory,
  PageLabel,
  PreviewComponentsEntry,
} from '../../types';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  CompStructure,
  SerializedPageStructure,
} from 'types/documentServices';
import type { AddPresetScope } from '../../addPresetApiEntry';

const mapApplicationToFedopsEvent = {
  [WIX_PRO_GALLERY]:
    util.fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_INSTALL_PRO_GALLERY,
  [WIX_FORMS]: util.fedopsLogger.INTERACTIONS.ADD_PAGE_PANEL_INSTALL_FORMS,
};

const { closePanelByNameAction } = stateManagement.panels.actions;
const { showUserActionNotification } = stateManagement.notifications.actions;

const SINGLE_PREVIEW_MODAL = 'addPreset.panels.singlePreviewModal';
const REPLACE_PAGE_MODAL = 'addPreset.panels.replacePageModal';

let addPageInProgress = false;

const DEFAULT_LANGUAGE = 'en';
const PAGE_SCALE_FACTOR = 0.22;
const BLANK_PAGE_DEFAULT_TITLE = translate('Pages_Menu_Add_New_Page');

const UPGRADE_referralAdditionalInfo = 'SANTA_EDITOR_ADD_PAGES_PANEL';
const UPGRADE_404_ERROR_PAGE = 'Error404_AddPagePanelFlow';

const { ROOT_COMPS } = constants;

export interface AddPagePanelOwnProps {
  origin: string;
  panelName: string;
  biCategory: string;
  panelOpenOrigin: string;
  pageAddedCallback: (pageId: string) => void;
  isAdvancedMenuFlow?: boolean;
}

export interface AddPagePanelStateProps {
  numOfColumns: number;
  labels: PageLabel[];
  categories: AddPageCategory[];
  initialSelectedCategory: number;
  language: string;
  currentSiteThemeMap: ThemeMap;
  isPremiumSite: boolean;
  currentSiteFontsUrls: string[];
}

export interface AddPagePanelDispatchProps {
  createPreviewComponents: (
    previewData: PreviewData,
  ) => Promise<React.ReactElement[]>;
  getPreviewComponentsEntry: (
    pages: PagePresetDefinition[],
    columnWidth: number,
    containerWidth: number,
    onPreviewReady: (
      pageId: string,
      fedopsInteractionKey: EditorInteractionName,
    ) => void,
    category: AddPageCategory,
  ) => Promise<PreviewComponentsEntry>;
  createSinglePreviewerComponent: (
    page: PagePresetDefinition,
    width: number,
    height: number,
    onPreviewReady: (
      pageId: string,
      fedopsInteractionKey: EditorInteractionName,
    ) => void,
    pageStructure?: CompStructure,
  ) => Promise<React.ReactElement[]> | null;
  loadPages(
    categories: AddPageCategory[],
    selectedCategory: number,
    language: string,
  ): Promise<PagePresetDefinition[]>;
  setSelectedCategoryId(selectedCategoryId: string): void;
  shouldShowUniquePageMessage(selectedCategory: AddPageCategory): boolean;
  addBlankPage(): void;
  addPage(
    pageDef: PagePresetDefinition,
    selectedCategory: AddPageCategory,
    panelOpenOrigin: string,
    addPageOrigin: string,
    pageStructure?: CompStructure,
  ): Promise<void>;
  sendPreviewClickEvent: (
    pagePresetDefinition: PagePresetDefinition,
    category: AddPageCategory,
  ) => void;
  sendCategoryChangeEvent(categoryName: string, isInstalled: boolean): void;
  openHelpCenter(helpId: string): void;
  sendContentScrolledEvent(scrollTop: number, categoryName: string): void;
  getPageIdByUri(uri: string): string;
  isReplacePage: (pageDef: PagePresetDefinition) => boolean;
  sendThumbnailsLoadedEvent(
    scrollTop: string,
    categoryName: string,
    duration: number,
  ): void;
  openPreviewThumbnail: (
    pageDef: PagePresetDefinition,
    category: AddPageCategory,
    previewComponent: React.ReactElement,
    width: number,
    height: number,
    useExtraPreviewHeight: boolean,
    addPageFn: () => void,
  ) => void;
  isApplicationInstalled: (appDefinitionId: string) => boolean;
  upgrade: (category: AddPageCategory, origin?: string) => void;
  closeModal: () => void;
  sendUpgradeTooltipShowEvent: () => void;
  shouldDisablePostInstallSecondaryAction: (appDefinitionId: string) => boolean;
}

function setNonLandingPage(pageJson: CompStructure): void {
  pageJson.data.isLandingPage = false;
  pageJson.data.isMobileLandingPage = false;
}

function setPageUriSEO(pageJson: CompStructure, pageUriSEO: string): void {
  pageJson.data.pageUriSEO = pageUriSEO;
}

function setPageHidden(pageJson: CompStructure): void {
  pageJson.data.hidePage = true;
}

function getNumOfColumns(innerWidth: number): number {
  if (innerWidth < 900) {
    return 1;
  } else if (innerWidth < 1200) {
    return 2;
  }
  return 3;
}

function closeOpenedPanelsOnPageAdd(editorAPI: EditorAPI): void {
  editorAPI.panelManager.closeAllPanels();
  const workspaceRightPanelAPI = editorAPI.host.getAPI(
    WorkspaceRightPanelApiKey,
  );
  if (workspaceRightPanelAPI.isOpen()) {
    workspaceRightPanelAPI.close(addPageOrigin);
  }
}

export const mapStateToProps = (
  scope: AddPresetScope,
): AddPagePanelStateProps => {
  const { editorAPI } = scope;
  const numOfColumns = getNumOfColumns(window.innerWidth);

  const isFirstSave =
    editorAPI.dsRead.generalInfo.isFirstSave() ||
    editorAPI.dsRead.generalInfo.isDraft();

  const categories = scope.store
    .getPagesCategories()
    .filter((category: AddPageCategory) => !(isFirstSave && category.isPremium))
    .filter(filterCategory(editorAPI))
    .map(getCategoryWithAddedProps);

  return {
    numOfColumns,
    labels: scope.store.getPagesLabels(),
    categories,
    initialSelectedCategory:
      getCategoryIndex(categories, scope.store.getPagesSelectedCategoryId()) ||
      0,
    language: experiment.isOpen('se_addPageLanguages')
      ? util.editorModel.languageCode
      : DEFAULT_LANGUAGE,
    currentSiteThemeMap: getSiteThemeMap(editorAPI),
    isPremiumSite: editorAPI.site.isPremium(),
    currentSiteFontsUrls: getFontsUrls(editorAPI),
  };
};

function addPageAndNavigate(
  editorAPI: EditorAPI,
  pageTitle: string,
  pageJson?: any,
  callback?: (newMenuItemId?: string, newPageId?: string) => void,
  appIds: string[] = [],
  isAdvancedMenuFlow?: boolean,
): void {
  const pageAddedCallback = (newMenuItemId?: string, newPageId?: string) => {
    if (pageJson) {
      editorAPI.panelHelpers.updateProgressBar(
        1,
        translate('add_page_preset_progress_bar_text2'),
      );
    }

    if (!isAdvancedMenuFlow) {
      editorAPI.panelManager.openPanel(
        ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
        {
          renameEnabled: true,
          origin: addPageOrigin,
        },
        true,
      );
    }

    if (callback) {
      callback(newMenuItemId, newPageId);
    }

    const pageRef = editorAPI.pages.getCurrentPage();
    removeTransparentBoxesIfNeeded(editorAPI, pageRef);
  };

  const currentPageId = editorAPI.pages.getCurrentPageId();
  const menus = editorAPI.menus.getMenus();
  const selectedMenuItemId = editorAPI.menus.getSelectedMenuItemByPageId(
    menus,
    currentPageId,
  ).id;
  let addPageFn;

  if (appIds.some((appDefId) => TPA_CLONE_DATA_APPS.has(appDefId))) {
    addPageFn = (title: string, pageStructure: SerializedPageStructure) => {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.ADD_PAGE_AND_CLONE_TPA_DATA,
      );
      const pageRef = editorAPI.pages.addAndCloneTpaCompData(
        title,
        pageStructure,
      );
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.ADD_PAGE_AND_CLONE_TPA_DATA,
      );
      return pageRef;
    };
  }

  editorAPI.menus.addPage(
    'CUSTOM_MAIN_MENU',
    selectedMenuItemId,
    pageAddedCallback,
    pageTitle,
    pageJson,
    addPageFn,
    isAdvancedMenuFlow,
  );
}

const addPageStartReport = (
  editorAPI: EditorAPI,
  siteGlobalDataAPI: SiteGlobalDataApi,
  contentInjectionAPI: ContentInjectionApi,
  origin: string,
  source: string,
  name: string,
  target: string,
  app_list: string,
  fedopsKey: EditorInteractionName | null,
  category?: string,
) => {
  const { industryId, structureId } = siteGlobalDataAPI.getBusinessType();
  const isPagesContentInjected =
    contentInjectionAPI.getIsPagesContentInjected();
  editorAPI.bi.event(coreBi.events.pages.addPage.add_new_page_click, {
    origin,
    source,
    category: category || 'page',
    name,
    target,
    app_list,
    business_type: convertBusinessTypeToBiString(industryId, structureId),
    is_injected_content: isPagesContentInjected,
  });
  if (fedopsKey) {
    util.fedopsLogger.interactionStarted(fedopsKey);
  }
};

const addPageEndReport = (
  editorAPI: EditorAPI,
  siteGlobalDataAPI: SiteGlobalDataApi,
  contentInjectionAPI: ContentInjectionApi,
  origin: string,
  source: string,
  name: string,
  target: string,
  app_list: string,
  page_id: string,
  fedopsKey: EditorInteractionName | null,
  panelOpenOrigin?: string,
  category?: string,
) => {
  const { industryId, structureId } = siteGlobalDataAPI.getBusinessType();
  const isPagesContentInjected =
    contentInjectionAPI.getIsPagesContentInjected();
  editorAPI.bi.event(coreBi.events.pages.addPage.page_added, {
    origin,
    source,
    category: category || 'page',
    name,
    target,
    app_list,
    page_id,
    button: panelOpenOrigin,
    business_type: convertBusinessTypeToBiString(industryId, structureId),
    is_injected_content: isPagesContentInjected,
  });
  if (fedopsKey) {
    util.fedopsLogger.interactionEnded(fedopsKey);
  }
};

const getPageIdByUri = (editorAPI: EditorAPI, uri: string) => {
  const pagesData = editorAPI.pages.getPagesData();
  let pageId: string;

  pagesData.forEach((pageData) => {
    if (pageData.pageUriSEO === uri) {
      pageId = pageData.id;
    }
  });

  return pageId;
};

const isReplacePage = (editorAPI: EditorAPI, pageDef: PagePresetDefinition) => {
  const { uniquePageUriSeo } = pageDef;
  return !!(uniquePageUriSeo && getPageIdByUri(editorAPI, uniquePageUriSeo));
};

async function handlePageExternalPreset(
  editorAPI: EditorAPI,
  siteGlobalDataAPI: SiteGlobalDataApi,
  contentInjectionAPI: ContentInjectionApi,
  pageData: PagePresetDefinition,
  biCategory: string,
  panelOpenOrigin: string,
  origin: string,
  pageAddedCallback?: (pageId?: string) => void,
): Promise<void> {
  const { externalPresetType, externalPresetId } = pageData;
  const externalPresetDefinition = getPagesExternalPresetOptions(
    editorAPI,
  ).find(({ value }) => value === externalPresetType);
  addPageStartReport(
    editorAPI,
    siteGlobalDataAPI,
    contentInjectionAPI,
    origin,
    biCategory,
    externalPresetId,
    externalPresetDefinition.label,
    externalPresetDefinition.value,
    null,
    externalPresetDefinition.biCategory,
  );
  if (!externalPresetDefinition) {
    throw new Error('Could not find such external preset definition.');
  }
  const newPageIds = await externalPresetDefinition.handler(
    externalPresetId,
    editorAPI.bi.event,
  );

  pageAddedCallback?.(newPageIds[0]);

  addPageEndReport(
    editorAPI,
    siteGlobalDataAPI,
    contentInjectionAPI,
    origin,
    biCategory,
    externalPresetId,
    externalPresetDefinition.label,
    externalPresetType,
    newPageIds.join(','),
    null,
    panelOpenOrigin,
    externalPresetDefinition.biCategory,
  );
}

const addPage = async (
  editorAPI: EditorAPI,
  siteGlobalDataAPI: SiteGlobalDataApi,
  contentInjectionAPI: ContentInjectionApi,
  pageData: PagePresetDefinition,
  selectedCategory: AddPageCategory,
  isPremiumSite: boolean,
  getPageIdByUri: (pageUriSEO: string) => string,
  biCategory: string,
  panelOpenOrigin: string,
  addPageOrigin: string,
  origin: string,
  pageAddedCallback?: (pageId?: string) => void,
  isAdvancedMenuFlow: boolean = false,
  pageStructure?: CompStructure,
) => {
  if (!isPremiumSite && (selectedCategory.isPremium || pageData.isPremium)) {
    editorAPI.panelManager.openPanel(
      'panels.focusPanels.customErrorPagePanel',
      {},
      true,
    );
    return;
  }

  if (pageData.uniquePageUriSeo) {
    const existingPageWithSameUriId = getPageIdByUri(pageData.uniquePageUriSeo);
    if (existingPageWithSameUriId) {
      handleReplacePage(
        existingPageWithSameUriId,
        editorAPI,
        siteGlobalDataAPI,
        contentInjectionAPI,
        pageData,
        selectedCategory,
        isPremiumSite,
        getPageIdByUri,
        biCategory,
        panelOpenOrigin,
        addPageOrigin,
      );
      return;
    }
  }

  if (addPageInProgress) {
    return;
  }
  addPageInProgress = true;

  if (pageData.externalPresetType && pageData.externalPresetId) {
    if (isAdvancedMenuFlow) {
      pagesAPI.closeAddPagePanel(editorAPI);
    } else {
      closeOpenedPanelsOnPageAdd(editorAPI);
    }

    await handlePageExternalPreset(
      editorAPI,
      siteGlobalDataAPI,
      contentInjectionAPI,
      pageData,
      biCategory,
      panelOpenOrigin,
      origin,
      pageAddedCallback,
    );
    addPageInProgress = false;
    return;
  }

  addPageStartReport(
    editorAPI,
    siteGlobalDataAPI,
    contentInjectionAPI,
    origin,
    biCategory,
    pageData.pageTitleKey,
    selectedCategory.name,
    pageData.appIds.join(','),
    util.fedopsLogger.INTERACTIONS.ADD_PAGE_FROM_ADD_PAGE_PANEL,
  );

  const isFirstSave =
    editorAPI.dsRead.generalInfo.isFirstSave() ||
    editorAPI.dsRead.generalInfo.isDraft();

  let pageJson = null;
  let pageJsonResolved;
  let pageJsonPromise;
  if (!pageStructure) {
    pageJsonPromise = fetch(pageData.pageJsonUrl);
    pageJsonResolved = await extractStructurePromise(pageJsonPromise);
  }

  if (pageData.appIds.length) {
    pageJson = pageStructure || pageJsonResolved;
    const { canAddApps, appDefId, widgetId } =
      await editorAPI.platform.canAddAppsInStructure(pageData.appIds, pageJson);
    if (!canAddApps) {
      addPageEndReport(
        editorAPI,
        siteGlobalDataAPI,
        contentInjectionAPI,
        origin,
        biCategory,
        pageData.pageTitleKey,
        selectedCategory.name,
        pageData.appIds.join(','),
        null,
        util.fedopsLogger.INTERACTIONS.ADD_PAGE_FROM_ADD_PAGE_PANEL,
        panelOpenOrigin,
      );
      addPageInProgress = false;
      const applicationId =
        editorAPI.platform.getAppDataByAppDefId(appDefId).applicationId;
      editorAPI.platform.notifyApplication(
        applicationId,
        platformEvents.factory.solveAddWidgetLimitation({
          origin,
          widgetId,
        }),
      );
      return;
    }
  }

  if (isAdvancedMenuFlow) {
    pagesAPI.closeAddPagePanel(editorAPI);
  } else {
    closeOpenedPanelsOnPageAdd(editorAPI);
  }

  editorAPI.panelHelpers.openProgressBar(
    {
      title: translate('add_page_preset_progress_bar_header'),
      totalSteps: 1,
      currentStep: 0,
      taskDisplayName: translate('add_page_preset_progress_bar_text1'),
    },
    isAdvancedMenuFlow,
  );

  if (pageData.appIds?.length > 0) {
    await installAppsByOrder(
      editorAPI,
      pageData.appIds,
      getAddPagePanelPlatformOrigin(),
      mapApplicationToFedopsEvent,
    );
  }

  const addPageCallback = (newMenuItemId?: string, newPageId?: string) => {
    if (pageData.appIds?.length > 0) {
      const onSuccess = () => {};
      const onError = (e: Error) => {
        throw e;
      };
      editorAPI.saveManager.saveInBackground(
        onSuccess,
        onError,
        'addPagePanel',
        {
          overrideTitle: 'SAVE_CHOOSE_DOMAIN_AFTER_ADD_PAGE_TITLE_TITLE',
          overrideSubtitle: 'SAVE_CHOOSE_DOMAIN_AFTER_ADD_PAGE_TITLE_SUBTITLE',
          overrideActionButtonLabel:
            'SAVE_CHOOSE_DOMAIN_AFTER_ADD_PAGE_BUTTON_DONE',
          shouldConnectDomainAfterSilentFirstSave: isFirstSave,
          sourceOfStart: 'addPagePanel_bgSave',
        },
      );
    }

    editorAPI.documentServices.waitForChangesApplied(
      editorAPI.panelHelpers.closeProgressBar,
    );

    pageAddedCallback?.(newPageId);

    const pageRef = editorAPI.pages.getReference(newPageId);
    const contentSource = 'preset';
    coreUtils.componentSourceFeatureUtils.updateAllChildrenSourceData(
      editorAPI,
      {
        parentRef: pageRef,
        source: contentSource,
        changedOverride: false,
      },
    );

    addPageEndReport(
      editorAPI,
      siteGlobalDataAPI,
      contentInjectionAPI,
      origin,
      biCategory,
      pageData.pageTitleKey,
      selectedCategory.name,
      pageData.appIds.join(','),
      newPageId,
      util.fedopsLogger.INTERACTIONS.ADD_PAGE_FROM_ADD_PAGE_PANEL,
      panelOpenOrigin,
    );

    editorAPI.pages.hooks.pageAddedToStage.fire({
      pageRef,
      origin: 'addPagePanel',
    });
  };

  pageJson = pageStructure || pageJsonResolved;

  fixStructureMobileHints(pageJson);
  setNonLandingPage(pageJson);
  translatePageSectionsNamesIfNeeded(pageJson);
  overridePageCompsStructureIfApplicable(pageJson);
  if (pageData.uniquePageUriSeo) {
    setPageUriSEO(pageJson, pageData.uniquePageUriSeo);

    if (constants.CUSTOM_ERROR_PAGE.DEFAULT_URL === pageData.uniquePageUriSeo) {
      setPageHidden(pageJson);
    }
  }

  if (pageData.appIds?.length > 0) {
    const appDefinitionToApplicationIdMap = getAppDefinitionToApplicationIdMap(
      editorAPI,
      pageData.appIds,
    );

    fixSerializedApplicationIds(pageJson, appDefinitionToApplicationIdMap);
  }

  addPageAndNavigate(
    editorAPI,
    translate('Pages_Menu_Add_New_Page_Preset_Default_Name', {
      categoryName: translate(selectedCategory.label),
    }),
    pageJson,
    addPageCallback,
    pageData.appIds,
    isAdvancedMenuFlow,
  );
};

function handleReplacePage(
  existingPageWithSameUriId: string,
  editorAPI: EditorAPI,
  siteGlobalDataAPI: SiteGlobalDataApi,
  contentInjectionAPI: ContentInjectionApi,
  pageData: PagePresetDefinition,
  selectedCategory: AddPageCategory,
  isPremiumSite: boolean,
  getPageIdByUri: (pageUriSEO: string) => string,
  biCategory: string,
  panelOpenOrigin: string,
  addPageOrigin: string,
) {
  editorAPI.bi.event(coreBi.events.pages.addPage.replace_page_click, {
    origin: addPageOrigin,
    name: pageData.pageTitleKey,
    page_id: existingPageWithSameUriId,
  });
  editorAPI.panelManager.openPanel(
    REPLACE_PAGE_MODAL,
    {
      replacePage: async () => {
        editorAPI.bi.event(coreBi.events.pages.addPage.replace_page_click, {
          origin: 'replace_popup',
          name: pageData.pageTitleKey,
          page_id: existingPageWithSameUriId,
        });
        if (existingPageWithSameUriId === editorAPI.pages.getFocusedPageId()) {
          await editorAPI.pages.navigateToAsync(
            editorAPI.dsRead.homePage.get(),
          );
        }
        editorAPI.dsActions.pages.remove(existingPageWithSameUriId, () => {
          closeOpenedPanelsOnPageAdd(editorAPI);
          addPage(
            editorAPI,
            siteGlobalDataAPI,
            contentInjectionAPI,
            pageData,
            selectedCategory,
            isPremiumSite,
            getPageIdByUri,
            biCategory,
            panelOpenOrigin,
            'replace_popup',
            origin,
          ).then(() => {
            editorAPI.store.dispatch(
              showUserActionNotification({
                message: 'Notifications_addPagePreset_404_Replace_Text',
                title: 'Notifications_addPagePreset_404_Replace_Text',
                type: 'info',
                timeout: REPLACE_PAGE_NOTIFICATION_TIMEOUT,
                link: {
                  caption: 'Notifications_addPagePreset_404_Replace_Link',
                  onNotificationLinkClick: () => {
                    const biParams =
                      editorAPI.documentServices.history.getUndoLastSnapshotParams();
                    editorAPI.history.undo();
                    editorAPI.bi.event(
                      coreBi.events.editor.undo_redo_component_change,
                      {
                        type: 'undo',
                        origin: 'addPagePanelNotification',
                        pageId: biParams?.currentPage,
                        date_of_action: biParams?.date_of_action,
                        actionName: biParams?.label,
                        component_id: biParams?.component_id,
                        component_type: biParams?.component_type,
                      },
                    );
                  },
                },
              }),
            );
          });
        });
      },
      closeModal: () => {
        editorAPI.panelManager.closePanelByName(REPLACE_PAGE_MODAL);
      },
      openHelpCenter: editorAPI.panelManager.openHelpCenter,
    },
    true,
  );
}

export const mapDispatchToProps = (
  scope: AddPresetScope,
  ownProps: AddPagePanelOwnProps,
): AddPagePanelDispatchProps => {
  const { editorAPI, contentInjectionAPI, siteGlobalDataAPI, wixStoreAPI } =
    scope;
  const {
    origin,
    biCategory,
    panelOpenOrigin,
    panelName,
    pageAddedCallback,
    isAdvancedMenuFlow,
  } = ownProps;

  const isPremiumSite = editorAPI.site.isPremium();
  const { industryId, structureId } = siteGlobalDataAPI.getBusinessType();

  addPageInProgress = false;

  const shouldShowUniquePageMessage = (
    selectedCategory: AddPageCategory,
  ): boolean => {
    return (
      (!isPremiumSite && selectedCategory.isPremium) ||
      Boolean(
        selectedCategory.uniquePagesUriSeo &&
          getPageIdByUri(editorAPI, selectedCategory.uniquePagesUriSeo),
      )
    );
  };

  const upgrade = (
    category: AddPageCategory,
    origin: string = 'AddPagePanel_top',
  ) => {
    if (category.uniquePagesUriSeo === 'error404') {
      editorAPI.bi.event(
        coreBi.events.customErrorPage.custom_error_page_panel,
        {
          action: 'upgrade',
          flow: origin,
        },
      );
      editorAPI.bi.event(coreBi.events.editor.UPGRADE_EVENT, {
        origin: 'Error404_AddPagePanelFlow',
      });
      editorAPI.account.upgrade(UPGRADE_404_ERROR_PAGE);
    } else {
      editorAPI.account.upgrade(UPGRADE_referralAdditionalInfo);
    }
  };

  return {
    createPreviewComponents: async (previewData: PreviewData) => {
      const previewsData = [previewData];
      const scaleValue = PAGE_SCALE_FACTOR;
      return await scope.previewerApi.createPreviewComponents({
        previewsData,
        scaleValue,
      });
    },
    getPreviewComponentsEntry: async (
      pages: PagePresetDefinition[],
      columnWidth: number,
      containerWidth: number,
      onPreviewReady: (
        pageId: string,
        fedopsInteractionKey: EditorInteractionName,
      ) => void,
    ): Promise<PreviewComponentsEntry> => {
      const previewerPages: PagePresetDefinition[] = pages.filter(
        (page) => page.shouldUsePreviewer,
      );
      if (!previewerPages.length) {
        return {};
      }

      const compStructures = await Promise.all(
        previewerPages.map(({ pageJsonUrl }) =>
          util.http.fetchJson(pageJsonUrl),
        ),
      );

      const compDefsAndStructures = previewerPages.map(
        (pageDef: PagePresetDefinition, index: number) => {
          return { ...pageDef, injectedStructure: compStructures[index] };
        },
      );

      const injectedCompDefsAndStructures = compDefsAndStructures.map(
        (compDefAndStructure) => {
          return compDefAndStructure;
        },
      );

      const previewerReactComponents =
        await scope.previewerApi.createPreviewComponents({
          previewsData: injectedCompDefsAndStructures.map(
            (injectedCompDefAndStructure: any, index: number) => {
              return {
                structure: injectedCompDefAndStructure?.injectedStructure,
                displayWidth: columnWidth,
                baseWidth: SCANNING_WIDTH,
                onReady: () =>
                  onPreviewReady(
                    previewerPages[index]?._id,
                    util.fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
                      .ADD_PAGE_PREVIEWER_PREVIEW_LOAD,
                  ),
              };
            },
          ),
          scaleValue: PAGE_SCALE_FACTOR,
        });

      const previewComponentsEntry = getPreviewComponentsEntryFromStructure(
        injectedCompDefsAndStructures,
        previewerReactComponents,
      );
      return previewComponentsEntry;
    },
    createSinglePreviewerComponent: async (
      page: PagePresetDefinition,
      width: number,
      height: number,
      onPreviewReady: (
        pageId: string,
        fedopsInteractionKey: EditorInteractionName,
      ) => void,
      pageStructure?: CompStructure,
    ): Promise<React.ReactElement[]> | null => {
      const pageCompStructure: CompStructure =
        pageStructure || (await util.http.fetchJson(page.pageJsonUrl));

      const previewerReactComponents =
        await scope.previewerApi.createPreviewComponents({
          previewsData: [
            {
              structure: pageCompStructure,
              displayWidth: width,
              baseWidth: SCANNING_WIDTH,
              displayMaxHeight: height,
              isScrollable: true,
              isClickable: false,
              onReady: () =>
                onPreviewReady(
                  page._id,
                  util.fedopsLogger.INTERACTIONS.ADD_PAGE_PREVIEW_LOAD
                    .ADD_PAGE_PREVIEWER_PREVIEW_LOAD,
                ),
            },
          ],
        });

      return previewerReactComponents;
    },
    loadPages: async (
      categories: AddPageCategory[],
      selectedCategory: number,
      language: string,
    ): Promise<PagePresetDefinition[]> => {
      if (categories.length === 0) {
        loadPagesCategories(scope);
        return [];
      }
      return loadPages(scope, categories[selectedCategory].id, language);
    },
    setSelectedCategoryId: (selectedCategoryId: string) => {
      scope.store.setPagesSelectedCategoryId(selectedCategoryId);
    },
    shouldShowUniquePageMessage,
    addBlankPage: () => {
      if (addPageInProgress) {
        return;
      }
      addPageInProgress = true;

      if (isAdvancedMenuFlow) {
        pagesAPI.closeAddPagePanel(editorAPI);
      } else {
        closeOpenedPanelsOnPageAdd(editorAPI);
      }

      addPageStartReport(
        editorAPI,
        siteGlobalDataAPI,
        contentInjectionAPI,
        origin,
        biCategory,
        'blank',
        'blank',
        '',
        util.fedopsLogger.INTERACTIONS.ADD_BLANK_PAGE_FROM_ADD_PAGE_PANEL,
      );

      const addBlankPageCallback = (
        newMenuItemId?: string,
        newPageId?: string,
      ) => {
        pageAddedCallback?.(newPageId);

        addPageEndReport(
          editorAPI,
          siteGlobalDataAPI,
          contentInjectionAPI,
          origin,
          biCategory,
          'blank',
          'blank',
          '',
          newPageId,
          util.fedopsLogger.INTERACTIONS.ADD_BLANK_PAGE_FROM_ADD_PAGE_PANEL,
          panelOpenOrigin,
        );
      };

      addPageAndNavigate(
        editorAPI,
        BLANK_PAGE_DEFAULT_TITLE,
        null,
        addBlankPageCallback,
        [],
        isAdvancedMenuFlow,
      );
    },
    sendPreviewClickEvent: (
      pagePresetDefinition: PagePresetDefinition,
      category: AddPageCategory,
    ) => {
      editorAPI.bi.event(coreBi.events.pages.addPage.page_preview_click, {
        origin,
        category: 'page',
        target: category.title,
        name: pagePresetDefinition.pageTitleKey,
        business_type: convertBusinessTypeToBiString(industryId, structureId),
        is_injected_content: contentInjectionAPI.getIsPagesContentInjected(),
      });
    },
    sendContentScrolledEvent: (scrollTop: number, categoryName: string) => {
      editorAPI.bi.event(coreBi.events.pages.addPage.panel_content_scrolled, {
        scrollTop,
        target: categoryName,
        business_type: convertBusinessTypeToBiString(industryId, structureId),
      });
    },
    sendThumbnailsLoadedEvent: (
      phaseName: string,
      category: string,
      duration: number,
    ) => {
      editorAPI.bi.event(coreBi.events.pages.addPage.thumbnails_load_finished, {
        duration,
        category,
        phaseName,
        business_type: convertBusinessTypeToBiString(industryId, structureId),
        is_injected_content: contentInjectionAPI.getIsPagesContentInjected(),
      });
    },
    sendCategoryChangeEvent: (categoryName: string, isInstalled: boolean) => {
      editorAPI.bi.event(coreBi.events.pages.addPage.tab_click, {
        target: categoryName,
        is_installed: isInstalled,
        business_type: convertBusinessTypeToBiString(industryId, structureId),
      });
    },
    addPage: async (
      pageData: PagePresetDefinition,
      selectedCategory: AddPageCategory,
      panelOpenOrigin: string,
      addPageOrigin: string,
      pageStructure?: CompStructure,
    ) => {
      await addPage(
        editorAPI,
        siteGlobalDataAPI,
        contentInjectionAPI,
        pageData,
        selectedCategory,
        isPremiumSite,
        (uri: string) => getPageIdByUri(editorAPI, uri),
        biCategory,
        panelOpenOrigin,
        addPageOrigin,
        origin,
        pageAddedCallback,
        isAdvancedMenuFlow,
        pageStructure,
      );
    },
    getPageIdByUri: (uri: string) => {
      return getPageIdByUri(editorAPI, uri);
    },
    isReplacePage: (pageDef: PagePresetDefinition) => {
      return isReplacePage(editorAPI, pageDef);
    },
    openPreviewThumbnail: (
      pagePresetDefinition: PagePresetDefinition,
      category: AddPageCategory,
      previewComponent: React.ReactElement,
      width: number,
      height: number,
      useExtraPreviewHeight: boolean,
      addPage: () => void,
    ) => {
      editorAPI.panelManager.openPanel(
        SINGLE_PREVIEW_MODAL,
        {
          pagePresetDefinition,
          isReplacePage: isReplacePage(editorAPI, pagePresetDefinition),
          addPage,
          closeModal: () => {
            editorAPI.panelManager.closePanelByName(SINGLE_PREVIEW_MODAL);
          },
          isPremiumSite,
          category,
          upgrade,
          openHelpCenter: editorAPI.panelManager.openHelpCenter,
          shouldShowUniquePageMessage,
          width,
          height,
          previewComponent,
          useExtraPreviewHeight,
        },
        true,
      );
    },
    isApplicationInstalled: (appDefinitionId: string) => {
      return isApplicationInstalled(editorAPI, appDefinitionId);
    },
    upgrade,
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    closeModal: () => {
      editorAPI.store.dispatch(closePanelByNameAction(panelName));
    },
    sendUpgradeTooltipShowEvent: () => {
      editorAPI.bi.event(coreBi.events.commonControls.Tooltip_OpenClose, {
        panel_name: panelName,
        control_name: 'crown',
        section_name: '404 tab',
        state: '',
      });
    },
    shouldDisablePostInstallSecondaryAction: (appDefinitionId: string) => {
      return shouldDisablePostInstallSecondaryAction(
        wixStoreAPI,
        appDefinitionId,
      );
    },
  };
};

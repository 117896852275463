import React from 'react';
import { Text as TextBaseUI } from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';
import { translate as t } from '#packages/i18n';

import { DangerMessagePanelFrame, MessagePanelFrame } from '../../frames';
import { workspace } from '#packages/util';

const Text: AnyFixMe = TextBaseUI;

interface ValidationErrorPanelProps {
  onConfirm: () => void;
  helpMessage?: string;
  helpLinkMessage?: string;
  helpSuffix?: string;
  helpLink?: string;
  errorCode?: number;
  errorCodeText?: string;
  openHelpCenter?: (helpId: string) => void;
  panelName?: string;
}

const ValidationErrorPanel = (props: ValidationErrorPanelProps) => (
  <DangerMessagePanelFrame
    panelName={props.panelName}
    title="Notification_Box_Data_Curruption_Error_Title"
    confirmLabel="Notification_Box_Data_Curruption_Error_Reload_Button"
    iconName="plasterRed"
    validationError={true}
    closeWithoutCalling={true}
    onConfirm={props.onConfirm}
    helpMessage={props.helpMessage}
    openHelpCenter={props.openHelpCenter}
    helpLinkMessage={props.helpLinkMessage}
    helpSuffix={props.helpSuffix}
    helpLink={props.helpLink}
    errorCode={props.errorCode}
    errorCodeText={props.errorCodeText}
  >
    <span>{t('Notification_Box_Data_Curruption_Error_Text')}</span>
  </DangerMessagePanelFrame>
);

const NewValidationErrorPanel = (props: ValidationErrorPanelProps) => {
  const footnote = props.helpMessage && props.helpLinkMessage && (
    <Text
      size="small"
      weight="normal"
      skin="secondary"
      enableEllipsis={false}
      shouldTranslate={false}
    >
      <span>{t(props.helpMessage)}</span>
      <a onClick={() => props.openHelpCenter(props.helpLink)}>
        {` ${t(props.helpLinkMessage)}`}
      </a>
      <span>{t(props.helpSuffix)}</span>
      <span>
        {t(props.errorCodeText, {
          status_code: props.errorCode,
        })}
      </span>
    </Text>
  );

  return (
    <MessagePanelFrame
      panelName={props.panelName}
      title={t('Notification_Box_Data_Curruption_Error_Title')}
      confirmLabel={t('Notification_Box_Data_Curruption_Error_Reload_Button')}
      illustration={<baseUI.symbol name={'serverError_NewWorkspace'} />}
      onConfirm={props.onConfirm}
      footnote={footnote}
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {t('Notification_Box_Data_Curruption_Error_Text')}
      </Text>
    </MessagePanelFrame>
  );
};
const exportedPanel = workspace.isNewWorkspaceEnabled()
  ? NewValidationErrorPanel
  : ValidationErrorPanel;

export default exportedPanel;

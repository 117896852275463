import { BasePublicApi } from '#packages/apilib';
import constants from '#packages/constants';

import type { AnimationPanelOwnProps } from './animationPanel/types';
import { createAnimationsApi } from './api/v1';
import { AnimationsScope } from './scope';
import { createGlobalAnimationsApi } from './api/v3/globalAnimationsApi';

const toggleAnimationPanel = (
  { panelManager }: AnimationsScope,
  props: AnimationPanelOwnProps,
) => {
  panelManager.toggleComponentPanel(
    constants.componentPanels.animation,
    props,
    {
      panelLoader: () =>
        import('./animationPanel').then((m) => m.AnimationPanel),
    },
  );
};

export class AnimationsApi extends BasePublicApi<AnimationsScope> {
  private animationsApiV1 = createAnimationsApi(this.scope);
  private globalAnimationsApi = createGlobalAnimationsApi(this.scope);

  toggleAnimationPanel = this.bindScope(toggleAnimationPanel);
  isGfppHighlighted = this.scope.animationPanelApi.isGfppHighlighted;
  isGfppDisabled = this.scope.animationPanelApi.isGfppDisabled;
  isGfppShown = this.scope.animationPanelApi.isGfppShown;
  hasAnimations = this.scope.animationPanelApi.hasAnimations;

  cleanAllAnimations = this.animationsApiV1.cleanAllAnimations;
  findExistingAnimation = this.animationsApiV1.findExistingAnimation;
  getEffectData = this.animationsApiV1.getEffectData;
  getMobileOverrideEffectData =
    this.animationsApiV1.getMobileOverrideEffectData;
  setAnimation = this.animationsApiV1.setAnimation;
  removeAnimation = this.animationsApiV1.removeAnimation;

  applyGlobalAnimations = this.globalAnimationsApi.applyGlobalAnimations;
}

import constants from '#packages/constants';
import { withUserPreferences } from '#packages/higherOrderComponents';
import { cx } from '#packages/util';
import { hoc, isResponsiveEditor, stylable } from '@wix/santa-editor-utils';
import { wixMediaUrlFormatter } from '@wix/stylable-santa-flatten/consts';
import { Button } from '@wix/wix-base-ui';
import type {
  Palette,
  StylablePanelHost,
  StylePanelView,
} from '@wix/stylable-panel';
import type { Coordinates } from '@wix/stylable-panel-common';
import type { BIParams } from '@wix/stylable-panel-controllers';
import experiment from 'experiment';
import _ from 'lodash';
import React from 'react';
import biEvents from './events.json';
import { getStylablePanelHost } from './stylablePanelHost';
import type {
  StylablePanelDispatchProps,
  StylablePanelProps,
  StylablePanelStateProps,
} from './stylablePanelProps';
import { mapDispatchToProps, mapStateToProps } from './stylablePanelProps';
import type {
  CompData,
  EditorPalette,
  ForceStateSelectors,
  MediaManagerImage,
  PopupPanelMap,
  PopupPanelProps,
} from './stylablePanelTypes';
import { StylePanelPlane } from './stylablePanelTypes';
import { userPreferencesDefs } from './stylablePanelUserPreferences';

// import { PanelEventList } from '@wix/stylable-panel';
type PanelEventList = AnyFixMe;

const {
  getVariantPath,
  getPanelId,
  withStylableChange,
  withReloadVariant,
  stylableChangeConsts: { VARIANT_CLASSNAME },
} = stylable;
const {
  withModules,
  connect,
  STORES: { EDITOR_API },
} = hoc;

const {
  COLOR_ROWS,
  INITIAL_PALETTE_COLORS,
  PALETTE_IGNORED_KEYS,
  PALETTE_ROOT_COLORS_INDICES,
  PAGE_CUSTOMIZATION_PANEL,
  DESIGN_PANEL_THEME_REF,
  EDITORX_THEME_REF,
} = constants.STYLABLE.EDITOR;

const CSS_BUTTON_LABEL = 'CSS';
const CSS_PANEL_ID = 'cssPanel';
const PANEL_TOP_OFFSET = 72;
const PANEL_LEFT_OFFSET = -12;
const PARENT_PANEL_RIGHT_OFFSET = 12;
const PANEL_PREFIX = 'panels.toolPanels.stylableEditor';
const PANEL_OMITTED_PROPS = [
  'siteVarsDriver', // siteVarsDriver is taken from the stylable editor service
];
const DEFAULT_INITIAL_VIEW: StylePanelView = {
  // page: StylePanelPage.CustomizationPanel,
  page: PAGE_CUSTOMIZATION_PANEL,
};
const DEFAULT_PLANE: StylePanelPlane = StylePanelPlane.Vertical;

export const getStylablePanelPlane = (
  plane?: StylePanelPlane,
): StylePanelPlane => plane || DEFAULT_PLANE;

interface StylablePanelState {
  variantReady: boolean;
  customPanel?: React.ComponentType;
}

interface IPanelCompTypeOverridesMap {
  SlideshowButton: string;
}

const panelCompTypeOverridesMap: IPanelCompTypeOverridesMap = {
  SlideshowButton: 'StylableButton',
};

async function setComponentConfig(props: StylablePanelProps) {
  const {
    stylableEditor,
    variant,
    compType,
    getExternalComponentDefinition,
    getConfigOverrides,
  } = props;

  const panelCompType = getPanelId(compType);

  const externalComponentConfig =
    await getExternalComponentDefinition(compType);

  const cacheKey = `${getVariantPath(variant)}_${VARIANT_CLASSNAME}`;

  if (
    !_.isEqual(
      stylableEditor.selectionCache[cacheKey]?.componentConfig,
      externalComponentConfig,
    )
  ) {
    delete stylableEditor.selectionCache[cacheKey];
  }

  if (externalComponentConfig) {
    stylableEditor.setComponentExternalConfig(
      panelCompType,
      externalComponentConfig,
    );
  }

  const overridePanelCompType =
    panelCompTypeOverridesMap[
      panelCompType as keyof IPanelCompTypeOverridesMap
    ] || panelCompType;

  const configOverrides = getConfigOverrides(overridePanelCompType);

  if (configOverrides) {
    stylableEditor.setVariantConfigOverrides(
      getVariantPath(variant),
      VARIANT_CLASSNAME,
      configOverrides,
    );
  }
}

class StylablePanel extends React.Component<
  StylablePanelProps,
  StylablePanelState
> {
  public static displayName = 'StylablePanel';
  public readonly state: StylablePanelState = { variantReady: false };

  /**
   * A map between StylablePanel panel names and functions to open them
   */
  private panelMap: PopupPanelMap = {};

  /**
   * Editor DS color palettes in the editor format
   */
  private editorPalettes: EditorPalette[] = [];

  /**
   * Editor DS color palettes in the StylablePanel format
   */
  private colorPalettes: Palette[] = [];

  /**
   * Get component data for later BI usage
   */
  private compData: CompData;

  /**
   * force state local state
   */
  private forceStateSelectors: ForceStateSelectors = {
    forceState: undefined,
    selectionSelector: undefined,
  };

  /** @type {StylablePanelHost} */
  private stylablePanelAPI: StylablePanelHost;

  openPanels: string[] = [];
  panelOpenTimestamp = new Date().getTime();
  hasChanged = false;

  constructor(props: StylablePanelProps) {
    super(props);

    this.compData = this.props.getSelectedComponentId();

    this.stylablePanelAPI = getStylablePanelHost(
      () => this.props,
      () => this.panelMap,
      () => this.editorPalettes,
      () => this.colorPalettes,
      this.reportBI,
      () => this.openPanels.pop(),
      () => this.forceStateSelectors,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: StylablePanelProps) {
    if (nextProps.dimensionUnits !== this.stylablePanelAPI.dimensionUnits) {
      this.stylablePanelAPI.dimensionUnits = nextProps.dimensionUnits ?? {};
    }
  }

  public render() {
    if (!this.state.variantReady) {
      return null;
    }

    const { plane = DEFAULT_PLANE, stretched } = this.props;
    const editorXTheme = isResponsiveEditor();

    return (
      <div
        data-aid="stylable-panel"
        className={cx('stylable-panel-inner', 'stylable-panel-inner-tall', {
          'stylable-panel-inner-vertical-collapse':
            plane === StylePanelPlane.VerticalCollapse,
          [DESIGN_PANEL_THEME_REF]: !editorXTheme,
          [EDITORX_THEME_REF]: editorXTheme,
          'stylable-panel-inner-stretched': stretched,
        })}
      >
        {this.renderWixStyleEditor()}
        {experiment.isOpen('se_stylableCSSPanel')
          ? this.renderCSSPanelButton()
          : null}
      </div>
    );
  }

  public async componentDidMount() {
    const { setUserColors, getCustomPanel, compType } = this.props;

    this.setColorPalettes();
    setUserColors();

    this.setPanelMap();

    const customPanel = await getCustomPanel(compType);

    //eslint-disable-next-line react/no-did-mount-set-state
    this.setState({
      variantReady: true,
      customPanel,
    });
  }

  public componentWillUnmount() {
    this.onClose(this.compData.id);
  }

  /**
   * On Panel Change
   * @function
   * @param {string} css - The changed CSS
   */
  private onChange = (css: string) => {
    this.hasChanged = true;
    return this.props.updateStyle(css);
  };

  /**
   * Renders a custom stylable panel provided from Editor Elements
   * @function
   * @returns {func | undefined} Element - React element for the component
   */
  private renderCustomPanel = (panelProps: any) => {
    const {
      stylablePanelLib: { WixStyleEditor, ...panelComps },
    } = this.props;
    const { customPanel: CustomPanel } = this.state;

    return (
      <CustomPanel
        {...panelProps}
        components={panelComps}
        selectedComponent={this.props.selectedComponent}
      />
    );
  };

  /**
   * Renders the WixStyleEditor component from the StylablePanel library
   * @function
   * @returns {func} Element - React element for the component
   */
  private renderWixStyleEditor = () => {
    const {
      stylablePanelLib: { WixStyleEditor },
      stylableEditor,
      initialView = DEFAULT_INITIAL_VIEW,
      plane = DEFAULT_PLANE,
    } = this.props;
    const { customPanel } = this.state;

    return (
      <WixStyleEditor
        stylableEditor={stylableEditor}
        panelHost={this.stylablePanelAPI}
        onChange={this.onChange}
        plane={plane}
        initialView={initialView}
        customPanel={customPanel && this.renderCustomPanel}
      />
    );
  };

  private renderCSSPanelButton = () => (
    <Button
      className="css-panel-button"
      onClick={() =>
        this.openPanelById(CSS_PANEL_ID)({
          value: this.props.getStyleCss(),
          onChange: this.props.updateStyle,
          onClose: () => this.openPanels.pop(),
        })
      }
    >
      {CSS_BUTTON_LABEL}
    </Button>
  );

  /**
   * On Panel Close
   * @function
   */
  private onClose = (compId: string) => {
    const {
      stylableEditor: { stylableDriver, stylesheetPath },
    } = this.props;
    const jsonData = JSON.stringify(
      stylableDriver.getStylesheet(stylesheetPath).AST.toResult().css,
    );
    // this.reportBI(PanelEventList.STYLABLE_PANEL_CLOSE, {
    this.reportBI('STYLABLE_PANEL_CLOSE', {
      id: compId,
      jsonData: JSON.stringify(jsonData),
      duration: new Date().getTime() - this.panelOpenTimestamp,
    });
    if (this.hasChanged) {
      // this.reportBI(PanelEventList.STYLABLE_SETTINGS_CHANGED, { id: compId });
      this.reportBI('STYLABLE_SETTINGS_CHANGED', { id: compId });
    }
  };

  /**
   * Report BI events
   * @function
   * @param {WixBIEvent} event
   * @param {WixBIParam[]} [params]
   */
  private reportBI = (event: PanelEventList, params?: BIParams) => {
    const { reportBI } = this.props;

    if (!event || !reportBI) {
      return;
    }

    const { compType } = this.props;
    const componentId = this.props.getSelectedComponentId();
    const biParams = Object.assign({}, params, {
      compType,
      component_type: compType,
      component_id: componentId?.id,
    });
    reportBI(biEvents[event as keyof typeof biEvents], [biParams]);
  };

  /**
   * Sets the StylablePanel panel name to editor open panel function map
   * @function
   */
  private setPanelMap = () => {
    if (Object.keys(this.panelMap).length !== 0) {
      return;
    }

    const {
      stylablePanelLib: {
        FillPicker,
        ColorPicker,
        SingleShadowInput,
        TextShadowLayerInput,
        StateListDialog,
        ImagePicker,
      },
    } = this.props;

    this.panelMap = {
      [FillPicker.panelName]: this.openPanelById('fillPickerPanel'),
      [ColorPicker.panelName]: this.openPanelById('colorPickerPanel'),
      [SingleShadowInput.panelName]: this.openPanelById('boxShadowPickerPanel'),
      [TextShadowLayerInput.panelName]: this.openPanelById(
        'textShadowPickerPanel',
      ),
      [StateListDialog.panelName]: this.openPanelById('stateListDialog'),
      [ImagePicker.panelName]: this.openMediaManager,
    };
  };

  /**
   * Sets the color palettes used in the panel
   * @function
   */
  private setColorPalettes = () => {
    this.editorPalettes = this.props
      .getColorPresets()
      .map((palette) => _.omit(palette, PALETTE_IGNORED_KEYS));

    this.colorPalettes = this.editorPalettes.map((palette) => ({
      columns: _(palette)
        // eslint-disable-next-line you-dont-need-lodash-underscore/keys
        .omit(_.keys(INITIAL_PALETTE_COLORS))
        .values()
        .chunk(COLOR_ROWS)
        .value(),
      previewIndices: PALETTE_ROOT_COLORS_INDICES as unknown as number[],
    }));
  };

  private getPopupPanelPosition = (coordinates?: Coordinates) => {
    const { style: parentPosition, useMouseEvent } = this.props;

    const stylablePanelWidth = constants.STYLABLE.EDITOR.DEFAULT_PANEL_WIDTH;

    if (!parentPosition && !coordinates && !useMouseEvent) {
      const parentPanelCoordinates = this.props.parentPanelCoordinates;

      if (parentPanelCoordinates) {
        const canStylablePanelBePlacedToTheRightOfParentPanel =
          parentPanelCoordinates.right +
            PARENT_PANEL_RIGHT_OFFSET +
            stylablePanelWidth <
          this.props.stageLayout.right;
        const stylablePanelLeftPosition =
          canStylablePanelBePlacedToTheRightOfParentPanel
            ? parentPanelCoordinates.right + PARENT_PANEL_RIGHT_OFFSET
            : parentPanelCoordinates.x -
              stylablePanelWidth -
              PARENT_PANEL_RIGHT_OFFSET;

        return {
          left: `${stylablePanelLeftPosition}px`,
          top: `${parentPanelCoordinates.top}px`,
        };
      }
    }

    if (parentPosition?.left && parentPosition?.top) {
      return {
        top: `${parentPosition.top + PANEL_TOP_OFFSET}px`,
        left: `${parentPosition.left + PANEL_LEFT_OFFSET}px`,
      };
    }
    if (coordinates) {
      return {
        top: `${coordinates.y}px`,
        left: `${coordinates.x}px`,
      };
    }
    if (event && useMouseEvent) {
      return {
        // TODO: get rid of window.event
        // https://jira.wixpress.com/browse/STYL-1076
        top: `${(event as MouseEvent).clientY}px`,
        left: `${(event as MouseEvent).clientX}px`,
      };
    }
    return {
      top: `${window.innerHeight / 2}px`,
      left: `${window.innerWidth / 2}px`,
    };
  };

  /**
   * Opens a panel based on its ID
   * @function
   * @param {string} panelId - ID of the panel to open
   * @returns {func} Function that will open the panel, receiving panel props
   */
  private openPanelById =
    (panelId: string) =>
    (panelProps: PopupPanelProps, coordinates?: Coordinates) => {
      const { openPanel, updatePanelProps } = this.props;

      const panelName = `${PANEL_PREFIX}.${panelId}`;
      const openProps: PopupPanelProps = _.omit(
        panelProps,
        PANEL_OMITTED_PROPS,
      );

      if (panelName === this.openPanels[this.openPanels.length - 1]) {
        updatePanelProps(panelName, openProps);
        return;
      }

      const position = this.getPopupPanelPosition(coordinates);
      openPanel(panelName, openProps, position);
      this.openPanels.push(panelName);
    };

  /**
   * Opens the Wix Media Manager and calls onChange when an image is selected
   * @function
   * @param {Object} panelProps - Props provided by the StylablePanel, contains the onChange callback
   */
  private openMediaManager = (panelProps: AnyFixMe) => {
    const { openMediaManager, mediaManagerCategory } = this.props;

    openMediaManager(mediaManagerCategory, {
      multiSelect: false,
      callback: (images?: MediaManagerImage[]) => {
        if (images?.length) {
          const { uri, width, height } = images[0];
          panelProps.onChange(
            `${wixMediaUrlFormatter}(${uri}, ${width}, ${height})`,
          );
        }
      },
    });
  };
}

const panelModules = {
  stylablePanelLib: () => import('stylable-panel'),
};

const ConnectedStylablePanel: AnyFixMe = _.flow(
  withModules(panelModules),
  withUserPreferences(userPreferencesDefs),
  withReloadVariant(async (props: StylablePanelProps) => {
    await setComponentConfig(props);
    props.revertForceState(); // TODO remove this when implementing STYL-847. This is workaround. see: https://jira.wixpress.com/browse/STYL-847
  }),
  connect(
    EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
    (
      stateProps: StylablePanelStateProps,
      dispatchProps: StylablePanelDispatchProps,
      ownProps: StylablePanelProps,
    ) => ({
      ...ownProps,
      ...stateProps,
      ...dispatchProps,
      showUserActionNotification:
        ownProps.showUserActionNotification ||
        dispatchProps.showUserActionNotification,
    }),
  ),
  withStylableChange(),
)(StylablePanel);
ConnectedStylablePanel.pure = StylablePanel;

export default ConnectedStylablePanel;

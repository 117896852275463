// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { HorizontalTabs } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  menuItem: AnyFixMe;
  selectedTabType?: string;
  setActiveTabType: FunctionFixMe;
  tabs?: Array<{
    tag: string;
    name: string;
    panelClass?: AnyFixMe;
    props?: AnyFixMe;
    helpId?: string;
  }>;
}

export default class extends React.Component<Props> {
  static displayName = 'pageSettingsTabsHolder';

  static propTypes = {
    menuItem: PropTypes.object.isRequired,
    selectedTabType: PropTypes.string,
    setActiveTabType: PropTypes.func.isRequired,
    tabs: PropTypes.PropTypes.arrayOf(
      PropTypes.shape({
        tag: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        panelClass: PropTypes.string.element,
        props: PropTypes.object,
        helpId: PropTypes.string,
        disabled: PropTypes.boolean,
      }),
    ),
  };

  changeHandler = (value) => {
    this.props.setActiveTabType(value);
  };

  getTabs = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const tabs = _.map(this.props.tabs, (tab) => ({
      label: tab.name,
      value: tab.tag,
      disabled: !!tab.disabled,
    }));
    return tabs;
  };

  render() {
    return (
      <div>
        <baseUI.tabs.holder
          value={this.props.selectedTabType}
          className="top page-settings-tabs"
        >
          <HorizontalTabs
            value={this.props.selectedTabType}
            onChange={this.changeHandler}
            options={this.getTabs()}
            shouldTranslate={false}
            maxVisibleTabs={4}
            tabsToSkipOnClick={2}
          />

          <baseUI.tabs.content className="page-settings-panel-pp-content">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.tabs, (tab) => {
              const TabClass = tab.panelClass;

              return (
                <baseUI.tabs.tab name={tab.tag} key={tab.tag}>
                  <TabClass {...tab.props} />
                </baseUI.tabs.tab>
              );
            })}
          </baseUI.tabs.content>
        </baseUI.tabs.holder>
      </div>
    );
  }
}

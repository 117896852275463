import { createThemedAction } from '../utils/themedAction';
import type { ThemedActionCreationData } from '../utils/textAction';
import type { CkOff } from '#packages/textControls';

const lineHeightActionCreationData: Readonly<
  ThemedActionCreationData<string | CkOff>
> = {
  execCommand: (textManager, lineHeight) => {
    if (lineHeight === undefined) {
      textManager.commands.lineHeight('', { execWithoutFocus: true });
    } else {
      textManager.commands.lineHeight(lineHeight);
    }
  },
  getWixRichTextCommandState: (textManager) => {
    const lineHeight = textManager.getCommandsStates().lineHeight;
    if (lineHeight !== '') {
      return lineHeight;
    }

    return;
  },
  execRevertCommand: (textManager) =>
    textManager.commands.lineHeight('', { execWithoutFocus: true }),
  createUpdatedStyleProperties: (lineHeight) => ({ lineHeight }),
  getPropertyValue: (styleProperties) => styleProperties.lineHeight,
  getThemeValue: (theme) => theme && theme.lineHeight,
};

export const lineHeightAction = createThemedAction(
  lineHeightActionCreationData,
);

._container_1pwcd_1 {
  display: flex;
  flex-direction: column; }

._smallGap_1pwcd_5 {
  gap: 3px; }

._bigGap_1pwcd_8 {
  gap: 6px; }

._inputTitle_1pwcd_11 .control-text.weight-bold {
  font-weight: 700; }

import { languages, fedopsLogger, http } from '#packages/util';
import experiment from 'experiment';
import { ErrorReporter } from '@wix/editor-error-reporter';

import { ConditionsManager } from './conditionsManager/conditionsManager';
import { editorSearchBiLogger } from '../biLogger/biLogger';
import { applyIntegrations } from './searchIntegrations/integrations';

import { SEARCH_CATEGORIES_LIST, ErrorType } from './constants';

import type { Integrations } from './constants';
import type {
  CategoryResults,
  TransformedCategoryResults,
  SearchResultItem,
  TransformedResult,
} from './types';

import type { Integration } from './integrationsTypes';
import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';

const SEARCH_ENDPOINT = 'https://editor.wix.com/_api/editor-search/v1/search';
const SEARCH_LIMIT_FOR_CATEGORY = 100;

enum SEARCH_ENVIRONMENTS {
  TEST = 'TEST',
  PRODUCTION = 'PRODUCTION',
}

interface ISearchRequestConfig {
  query: string;
  authorizationToken: string;
}

interface IntegrationsMap {
  [key: string]: Integration<any>;
}

export class SearchModule {
  private readonly integrationsMap: IntegrationsMap = {};
  private conditionsManager: ConditionsManager;

  private processUnknownResultItem(resultItem: SearchResultItem) {
    ErrorReporter.captureException(
      new Error('Editor Search: Unknown search result item'),
      {
        tags: {
          editorSearch: true,
        },
        extra: {
          resultItem,
        },
      },
    );
  }

  private fetchResults(
    { query, authorizationToken }: ISearchRequestConfig,
    signal: AbortSignal,
  ) {
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_FETCH,
    );

    const requestHeaders = new Headers({
      Authorization: authorizationToken,
      'Content-Type': 'application/json',
      'X-Wix-Language': languages.getLanguageCode(),
    });

    const requestParams = {
      signal,
      method: 'POST',
      headers: requestHeaders,
      body: JSON.stringify({
        query,
        namespace: 'CLASSIC_EDITOR',
        environment: experiment.isOpen('se_EditorSearchTestEnv')
          ? SEARCH_ENVIRONMENTS.TEST
          : SEARCH_ENVIRONMENTS.PRODUCTION,
        categories: SEARCH_CATEGORIES_LIST.map((category) => ({
          id: category,
          limit: SEARCH_LIMIT_FOR_CATEGORY,
        })),
      }),
    };

    return http
      .fetchJson(SEARCH_ENDPOINT, requestParams)
      .then((response: AnyFixMe) => {
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_FETCH,
        );
        return response;
      })
      .catch((error: AnyFixMe) => {
        if (
          error instanceof DOMException &&
          error.code === DOMException.ABORT_ERR
        ) {
          fedopsLogger.interactionEnded(
            fedopsLogger.INTERACTIONS.EDITOR_SEARCH.RESULT_FETCH,
          );
          return Promise.reject({
            type: ErrorType.ABORT,
            message: error.message,
          });
        }

        ErrorReporter.captureException(
          new Error(`Editor Search: ${error.status} ${error.statusText}`),
          {
            tags: {
              editorSearch: true,
            },
            extra: {
              requestParams,
            },
          },
        );

        if (error instanceof Error) {
          return Promise.reject({
            type: ErrorType.NETWORK,
            message: error.message,
          });
        }

        return Promise.reject({
          type: ErrorType.REQUEST,
          message: error.request.statusText,
          status: error.request.status,
        });
      });
  }

  init = (editorAPI: EditorAPI) => {
    this.conditionsManager = new ConditionsManager(editorAPI);

    editorSearchBiLogger.init((descriptor, params) => {
      editorAPI.store.dispatch(
        stateManagement.bi.actions.event(descriptor, params),
      );
    });
    applyIntegrations(editorAPI);
  };

  addIntegration<T extends Integrations>(data: Integration<T>) {
    this.integrationsMap[data.type] = data;
  }

  search = (
    config: ISearchRequestConfig,
  ): { promise: Promise<TransformedCategoryResults[]>; abort(): void } => {
    const controller = new AbortController();
    const { signal } = controller;
    const promise = this.fetchResults(config, signal).then(
      this.transformResults,
    );

    return {
      promise,
      abort: () => controller.abort(),
    };
  };

  transformItem(item: SearchResultItem) {
    const integration: Integration<any> =
      this.integrationsMap[item.extras.type];
    if (!integration) {
      this.processUnknownResultItem(item);

      return null;
    }

    if (integration.checkVisibility) {
      if (!integration.checkVisibility(item)) {
        return null;
      }
    }

    const failedCondition =
      this.conditionsManager.getFailedConditionForResult(item);

    if (failedCondition) {
      if (integration.customConditionalResult) {
        const conditionalResult = integration.customConditionalResult(
          item,
          failedCondition,
        );

        if (!conditionalResult) {
          this.processUnknownResultItem(item);

          return null;
        }

        return conditionalResult;
      }

      return this.conditionsManager.getConditionalResult(item, failedCondition);
    }

    const transformedItem = integration.transform(item);

    if (!transformedItem) {
      this.processUnknownResultItem(item);

      return null;
    }

    return transformedItem;
  }

  private readonly transformResults = (results: {
    categories: CategoryResults[];
  }) =>
    results.categories.map((category) => ({
      id: category.id,
      items: this.transformCategoryItemsResults(category.entities),
      rawItems: category.entities,
    }));

  private readonly transformCategoryItemsResults = (
    categoryItems: SearchResultItem[],
  ): TransformedResult<SearchResultItem>[] =>
    categoryItems.reduce((processedItems, currentItem) => {
      const transformedItem = this.transformItem(currentItem);

      if (transformedItem) {
        processedItems.push(transformedItem);
      }

      return processedItems;
    }, []);
}

export const searchModule = new SearchModule();

import { media, tpaUtils, workspace } from '#packages/util';
import { superApps } from '#packages/tpa';
import constants from '#packages/constants';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import type { EditorAPI } from '#packages/editorAPI';

const superAppId = 'wixStore';
const STORES_APP_DEF_ID = tpaUtils.getStoresAppDefId();
const GALLERY_WIDGET_ID = '1380bba0-253e-a800-a235-88821cf3f8a4';
const APP_NAME = 'WixStores';
const APP_NAME_FOR_APP_MARKET = 'wix-stores';
const wixStoreLeftBarPanelParams = {
  panelName: constants.SUPER_APPS.WIX_STORE_PANEL_NAME,
  panelProps: {},
  label: 'SiteApp_WixStores_Button_Hover_Caption',
  promotionalLabel: 'SiteApp_WixStores_Button_Promotional_Hover_Caption',
  className: 'wix-store-panel',
  symbolName: workspace.isNewWorkspaceEnabled()
    ? 'leftBarButtonStore'
    : 'leftBarWixStorePanel',
};

const STORES_MEMBER_PAGES = ['order_history', 'checkout'];
let hasDropshippingIntentExist = false;

const setDropshippingIntentExist = function (intent: AnyFixMe) {
  hasDropshippingIntentExist = intent;
};

const getDropshippingIntentExist = function () {
  return hasDropshippingIntentExist;
};

const isMembersPage = function (page: AnyFixMe) {
  return STORES_MEMBER_PAGES.includes(page.tpaPageId);
};

const getAppName = function () {
  return APP_NAME;
};

const getAppNameForAppMarket = function () {
  return APP_NAME_FOR_APP_MARKET;
};

const getGalleryWidgetId = function () {
  return GALLERY_WIDGET_ID;
};

const hasPremium = (editorAPI: EditorAPI) =>
  editorAPI.premiumFeatures.get().includes('HasECommerce') &&
  superApps.isPremium(STORES_APP_DEF_ID);

const isAppInstalled = function ({ dsRead }: EditorAPI) {
  return dsRead.platform.isAppActive(STORES_APP_DEF_ID);
};

const getAppExtensionsWidgets = function (callback: AnyFixMe) {
  return superApps.getExtensions(STORES_APP_DEF_ID, callback);
};

const getMainAppPageId = function () {
  return superApps.getFirstAppCompPageId(STORES_APP_DEF_ID).pageId;
};

const getAppTeaserInfo = function () {
  return {
    id: addPanelDataConsts.CATEGORIES_ID.STORE,
    hide: true,
    title: 'add_category_teaser_title_store',
    text: 'add_category_teaser_text_store',
    linkText: 'category_store_teaser_store_link',
    helpId: '23862857-e4f2-4205-87da-592e3cad64de',
  };
};

const getElementsTeaserInfo = function (editorAPI: AnyFixMe) {
  if (isAppInstalled(editorAPI)) {
    return {
      id: addPanelDataConsts.CATEGORIES_ID.STORE,
      hide: false,
      title: 'add_category_teaser_title_store2',
      text: 'add_category_teaser_text_store2',
      image: 'packages/wixStore/images/addPanelTeaser.png',
      video: '',
    };
  }
  return null;
};

const getLearnMoreData = function () {
  return {
    viewA: {
      helpCenterPopUp: {
        link: '23862857-e4f2-4205-87da-592e3cad64de',
        image: media.getMediaUrl(
          'superAppMenuInfra/TipsFromWixsBloogingPros.png',
        ),
      },
      supportForumsPopUp: {
        link: 'https://www.wix.com/support/html5/wix-stores/',
        image: media.getMediaUrl('superAppMenuInfra/ViewTheBlogHelpCenter.png'),
      },
      wixTipsBlog: {
        link: 'https://www.wix.com/blog/category/ecommerce/',
        image: media.getMediaUrl(
          'superAppMenuInfra/OpenTheBlogSupportForum.png',
        ),
      },
    },
    viewB: [
      {
        title: 'Help Topics',
        link: 'https://www.wix.com/support/html5/wixstores/',
        data: [
          {
            link: 'https://www.wix.com/support/html5/wixstores/setting-up-your-store/tutorial/activating-wixstores',
            description: 'Activating WixStores',
            image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
          },
          {
            link: 'https://www.wix.com/support/html5/wixstores/managing-your-store/tutorial/managing-your-products-for-wixstores',
            description: 'Managing Your Products For WixStores',
            image: 'packages/wixStore/images/learnMoreHelpTopics2.png', //TODO: change to the real assets once we get them
          },
          {
            link: 'https://www.wix.com/support/html5/wixstores/managing-your-store/tutorial/managing-collections-in-wixstores',
            description: 'Managing Collections For WixStores',
            image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
          },
          {
            link: 'https://www.wix.com/support/html5/wixstores/managing-your-store/tutorial/setting-up-payment-in-wixstores',
            description: 'Settings up payment in WixStores',
            image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
          },
        ],
      },
      {
        title: 'Video Tutorials',
        link: '',
        data: [
          {
            link: 'https://www.youtube.com/watch?v=gPfvzQJZ9Dc',
            description: 'How to Manage Your Online Store',
            image: 'packages/wixStore/images/learnMoreVideoTutorials1.png', //TODO: change to the real assets once we get them
          },
          {
            link: 'https://www.youtube.com/watch?v=mViId_-1vA8',
            description: 'How to Manage Your Online Store Products',
            image: 'packages/wixStore/images/learnMoreVideoTutorials2.png', //TODO: change to the real assets once we get them
          },
        ],
      },
      {
        title: 'Tips from Wix blog',
        link: 'https://www.wix.com/blog/category/ecommerce/',
        data: [
          {
            link: 'http://www.wix.com/blog/2015/02/top-5-web-skills-you-need-to-learn-to-run-your-own-business/',
            description:
              'Top 5 Web Skills You Need To Learn To Run Your Own Business',
            image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
          },
          {
            link: 'http://www.wix.com/blog/2014/09/5-tips-for-great-product-photography/',
            description: '5 Tips for Great Product Photography',
            image: 'packages/wixStore/images/learnMoreHelpTopics2.png', //TODO: change to the real assets once we get them
          },
          {
            link: 'http://www.wix.com/blog/2014/08/find-products-to-sell-online/',
            description: 'How to Find a Great Product to Sell Online',
            image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
          },
          {
            link: 'http://www.wix.com/blog/2014/08/20-ways-to-score-more-sales-for-your-business/',
            description: '20 Ways to Score More Sales for Your Business',
            image: 'packages/wixStore/images/learnMoreHelpTopics2.png', //TODO: change to the real assets once we get them
          },
        ],
      },
    ],
  };
};

const getUpgradeTooltipData = function () {
  return {
    title: 'SiteApp_WixStores_UpgradeTooltip_Title',
    text: 'SiteApp_WixStores_UpgradeTooltip_Paragraph1',
    label: 'SiteApp_WixStores_UpgradeTooltip_Button_Label',
    image: 'menubarUpgradePromotionDark',
  };
};

const getFirstTimeTourTemplateData = function () {
  return {
    title: 'SiteApp_WixStores_TemplateFTUE_Step1_Title',
    subtitle: 'SiteApp_WixStores_TemplateFTUE_Step1_SubTitle',
    bullets: [
      'SiteApp_WixStores_TemplateFTUE_Step1_Bullet1',
      'SiteApp_WixStores_TemplateFTUE_Step1_Bullet2',
      'SiteApp_WixStores_TemplateFTUE_Step1_Bullet3',
      'SiteApp_WixStores_TemplateFTUE_Step1_Bullet4',
    ],
    cta: 'SiteApp_WixStores_TemplateFTUE_Step1_NextButton_Caption',
  };
};

const getDropshippingTooltipData = function () {
  return {
    title: 'SiteApp_WixStores_FindProducts_Info_Title',
    text: 'SiteApp_WixStores_FindProducts_Info_Text',
    label: 'SiteApp_WixStores_FindProducts_Info_Button',
    image: 'storeMultipleItems',
  };
};

export {
  superAppId,
  hasPremium,
  isAppInstalled,
  isMembersPage,
  getAppExtensionsWidgets,
  getAppName,
  getAppNameForAppMarket,
  getGalleryWidgetId,
  getAppTeaserInfo,
  getElementsTeaserInfo,
  getLearnMoreData,
  getMainAppPageId,
  getUpgradeTooltipData,
  getFirstTimeTourTemplateData,
  getDropshippingTooltipData,
  getDropshippingIntentExist,
  setDropshippingIntentExist,
  wixStoreLeftBarPanelParams,
};

import React, { type FC, type ReactElement } from 'react';
import * as util from '#packages/util';
import TopBarButton from '../topBarButton/topBarButtonWithTooltip';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
//@ts-expect-error-next-line
import coreMultilingual from 'coreMultilingual';

const customSymbol = React.createElement(symbols.symbol, { name: 'arrowDown' });

interface LanguagePanelButtonProps {
  onClick: () => void;
  regionCode?: string;
  languageCode: string;
  tooltip: ReactElement;
  countryCode?: string;
}

export const LanguagePanelButton: FC<LanguagePanelButtonProps> = ({
  onClick,
  tooltip,
  languageCode,
  regionCode,
  countryCode,
}) => {
  const getFlagUrl = (countryCode: string) => {
    return coreMultilingual.utils.getFlagIconUrl(
      util.serviceTopology.scriptsLocationMap['linguist-flags'],
      coreMultilingual.flagCountryIconType.rounded,
      countryCode,
    );
  };

  const localeName = regionCode
    ? `${translate(
        `locale-dataset.languages.${languageCode}`,
      )} (${regionCode.toUpperCase()})`
    : translate(`locale-dataset.languages.${languageCode}`);

  return (
    <TopBarButton
      tooltip={tooltip}
      automationId="top-bar-button-flag"
      onClick={onClick}
      className="language-selector-top-bar-button-new"
    >
      {countryCode && (
        <div className="flagWrapper">
          <img src={getFlagUrl(countryCode)} alt={countryCode} />
        </div>
      )}
      <div className="localeNameWrapper">
        <div className="localeName">{localeName}</div>
      </div>
      <div>{customSymbol}</div>
    </TopBarButton>
  );
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '#packages/core';
import * as stateManagement from '#packages/stateManagement';
import designPanelMixin from '../core/designPanelMixin';
import React from 'react';
import DesignPanel from '../core/designPanel';

const { panelPosition } = stateManagement.domMeasurements.hoc;
const getSelectedComponents = (selectedComponent, multiSelectedComponents) =>
  multiSelectedComponents
    ? multiSelectedComponents
    : _.toArray(selectedComponent);

// eslint-disable-next-line react/prefer-es6-class
const ChangeDataDesignPanel = createReactClass({
  displayName: 'changeDataDesignPanel',
  mixins: [designPanelMixin, core.mixins.editorAPIMixin],
  render() {
    return <DesignPanel ref="designPanel" {...this.getPropsForDesignPanel()} />;
  },
  getStateFromProps(props) {
    return {
      currentDataItem: this.getEditorAPI().components.data.get(
        props.selectedComponent,
      ),
    };
  },
  changePermanentState(compDef) {
    if (compDef.data) {
      const { selectedComponent, multiSelectedComponents } = this.props;
      const selectedComponents = getSelectedComponents(
        selectedComponent,
        multiSelectedComponents,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(selectedComponents, (compRef) => {
        this.getEditorAPI().components.data.update(compRef, compDef.data);
      });
      this.setState({ currentDataItem: compDef.data });
    }
  },
  changeTempState(compDef) {
    if (compDef.data) {
      const { selectedComponent, multiSelectedComponents } = this.props;
      const selectedComponents = getSelectedComponents(
        selectedComponent,
        multiSelectedComponents,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(selectedComponents, (compRef) => {
        this.getEditorAPI().components.data.update(compRef, compDef.data);
      });
    }
  },
  maintainOriginalState() {
    if (this.state.currentDataItem) {
      const { selectedComponent, multiSelectedComponents } = this.props;
      const selectedComponents = getSelectedComponents(
        selectedComponent,
        multiSelectedComponents,
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(selectedComponents, (compRef) => {
        this.getEditorAPI().components.data.update(
          compRef,
          this.state.currentDataItem,
        );
      });
    }
  },
  isSelectedItem(compDef) {
    return compDef.data === this.state.currentDataItem;
  },
});

const WrappedPanel = panelPosition()(ChangeDataDesignPanel);
WrappedPanel.pure = ChangeDataDesignPanel;

export default WrappedPanel;

import * as helpIds from '#packages/helpIds';
import constants from '#packages/constants';
import { array } from '#packages/util';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import * as stateManagement from '#packages/stateManagement';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const POPUP_CONSTS = constants.COMP_PANELS.POPUP;

const popupHelpers = {
  isPopup(editorAPI: EditorAPI, selectedComponents: CompRef | CompRef[]) {
    const [component] = array.asArray(selectedComponents);

    return Boolean(editorAPI.dsRead.pages.popupPages.isPopup(component?.id));
  },
};

const getPageBackgroundAction = (
  editorAPI: EditorAPI,
  pageRef: CompRef | CompRef[],
) => {
  const { label, icon, tooltip, backgroundMediaType } =
    gfppDataUtils.getDataGfppWithBackgroundIcon(
      editorAPI,
      Array.isArray(pageRef) ? pageRef[0] : pageRef,
      {
        label: 'Popups_OverlayGFPP_Secondary_Button',
        tooltip: '',
      },
    );

  return {
    label,
    isApplied: true,
    isSelected: gfppDataUtils.getPanelStateFn(
      POPUP_CONSTS.OVERLAY.DESIGN_PANEL.NAME,
    ),
    onClick(editorApi: EditorAPI, compRefs: CompRef[], origin: string) {
      gfppDataUtils.toggleComponentPanel(
        editorAPI,
        POPUP_CONSTS.OVERLAY.DESIGN_PANEL.NAME,
        { origin, biParams: { bg_media_type: backgroundMediaType } },
      );
    },
    icon,
    tooltip,
  };
};

export default {
  untranslatable: true,
  mainActions(editorAPI: EditorAPI, selectedComponents: CompRef | CompRef[]) {
    if (popupHelpers.isPopup(editorAPI, selectedComponents)) {
      return [
        {
          label: 'Popups_OverlayGFPP_MainAction_Button',
          isApplied: true,
          isSelected: gfppDataUtils.getPanelStateFn(
            POPUP_CONSTS.OVERLAY.SETTINGS_PANEL.NAME,
          ),
          onClick(editorApi: EditorAPI, compRefs: CompRef[], origin: string) {
            gfppDataUtils.toggleComponentPanel(
              editorAPI,
              POPUP_CONSTS.OVERLAY.SETTINGS_PANEL.NAME,
              { origin },
            );
          },
        },
        getPageBackgroundAction(editorAPI, selectedComponents),
      ];
    }

    return [
      {
        label: 'gfpp_mainaction_page_background',
        isApplied: true,
        onClick(editorApi: EditorAPI, compRefs: CompRef[], origin: string) {
          const panel = constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME;

          const panelProps = Object.assign(
            {},
            { origin },
            { selectedView: constants.DESIGN_PANEL.VIEWS.BACKGROUND },
          );
          editorAPI.store.dispatch(
            stateManagement.panels.actions.openLeftPanel(panel, panelProps),
          );
        },
      },
    ];
  },
  enabledActions(
    editorAPI: EditorAPI,
    selectedComponents: CompRef | CompRef[],
  ) {
    if (popupHelpers.isPopup(editorAPI, selectedComponents)) {
      return [ACTIONS.SETTINGS, ACTIONS.HELP];
    }

    const isPageDesignButtonHidden =
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        constants.USER_PREFS.IS_PAGE_DESIGN_BUTTON_HIDDEN,
      )(editorAPI.store.getState());

    if (isPageDesignButtonHidden) return [ACTIONS.HELP];

    return [ACTIONS.DESIGN, ACTIONS.HELP];
  },
  mobileMainActions(
    editorAPI: EditorAPI,
    selectedComponents: CompRef | CompRef[],
  ) {
    const isPopup = popupHelpers.isPopup(editorAPI, selectedComponents);
    const label = isPopup
      ? 'Popups_OverlayGFPP_MainAction_Button'
      : 'gfpp_mainaction_page_background';
    return [
      {
        label,
        isApplied: true,
        onClick(editorApi: EditorAPI, compRefs: CompRef[], origin: string) {
          if (isPopup) {
            gfppDataUtils.toggleComponentPanel(
              editorAPI,
              POPUP_CONSTS.OVERLAY.SETTINGS_PANEL.NAME,
              { origin },
            );
          } else {
            editorAPI.panelManager.openPanel('rEditor.panels.backgroundPanel', {
              origin,
            });
          }
        },
      },
    ];
  },
  mobileEnabledActions(
    editorAPI: EditorAPI,
    selectedComponents: CompRef | CompRef[],
  ) {
    if (popupHelpers.isPopup(editorAPI, selectedComponents)) {
      return [ACTIONS.SETTINGS, ACTIONS.HELP];
    }
    return [ACTIONS.HELP];
  },
  presetActions(editorAPI: EditorAPI, selectedComponents: CompRef | CompRef[]) {
    if (popupHelpers.isPopup(editorAPI, selectedComponents)) {
      return {
        help: helpIds.UTILS.getGfppHelpIds(helpIds.COMP_TYPES.POPUP_OVERLAY),

        settings: {
          isSelected() {
            return gfppDataUtils.isPanelOpen(
              editorAPI,
              POPUP_CONSTS.OVERLAY.SETTINGS_PANEL.NAME,
            );
          },
          onClick(editorApi: EditorAPI, compRefs: CompRef[], origin: string) {
            gfppDataUtils.toggleComponentPanel(
              editorAPI,
              POPUP_CONSTS.OVERLAY.SETTINGS_PANEL.NAME,
              { origin },
            );
          },
        },
      };
    }

    return {
      help: {
        helpId: helpIds.GFPP.PAGE,
        mobileHelpId: 'f0d8190e-e2da-48be-9f44-7ecff4518a82',
      },
    };
  },
};

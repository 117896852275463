import * as util from '#packages/util';
import type { StateProps, DispatchProps, OwnProps } from './types';
import { HelpBlock } from './types';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import * as helpIds from '#packages/helpIds';
import { startWelcomeTour } from '#packages/editorTours';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import HelpHome from './helpHome';
import { getVideoItems } from './utils';

const isUserPartner = (state: AnyFixMe) => {
  const userProfile =
    stateManagement.userProfile.selectors.getUserProfile(state);
  return userProfile?.finished_partner_onboarding?.value ?? false;
};

const HELP_ARTICLES = [
  {
    title: 'Editor_Help_Panel_Article_Linking_Element',
    helpId: 'f7599307-a686-4def-93a4-d11fd8352627',
  },
  {
    title: 'Editor_Help_Panel_Article_Anchors',
    helpId: '4932c49c-7282-48f0-8fa2-42d929981c40',
  },
  {
    title: 'Editor_Help_Panel_Article_Adding_Strip',
    helpId: '89091618-83b5-46b2-8eef-d42ba0e623ed',
  },
];

const getHireProLink = (languageCode: string, countryCode: string): string =>
  languageCode === 'en' && countryCode === 'USA'
    ? 'https://www.wix.com/marketplace/brief-auto-match?entry=editor&origin=Editor-help'
    : 'https://www.wix.com/marketplace/dynamic-brief?entry=generalMenu&serviceType=1&innerType=1&origin=Editor-help';

const BLOCK_FILTERS: Partial<Record<HelpBlock, (options?: any) => boolean>> = {
  [HelpBlock.WELCOME_TOUR]: ({ isWelcomeTourOpen, isMobileEditor }) =>
    isWelcomeTourOpen && !isMobileEditor,
  [HelpBlock.HIRE_PRO]: ({ isPartner, isPotentialPartner, isStudio }) =>
    !isStudio && !isPartner && !isPotentialPartner,
};

const ALL_BLOCKS = [
  HelpBlock.ARTICLES,
  HelpBlock.HIRE_PRO,
  HelpBlock.MEDIA_CAROUSEL,
  HelpBlock.WELCOME_TOUR,
  HelpBlock.EDITOR_SEARCH,
] as HelpBlock[];
const getRelevantBlocks = (
  state: AnyFixMe,
  editorAPI: AnyFixMe,
): HelpBlock[] => {
  const isMobileEditor =
    stateManagement.leftBar.selectors.isMobileEditor(state);
  const isWelcomeTourOpen = util.tours.isWelcomeTourEnabled();

  const isPartner = isUserPartner(state);
  const isPotentialPartner =
    stateManagement.helpHome.selectors.isPotentialPartner(state);
  const isStudio = editorAPI.dsRead?.generalInfo?.getUserInfo()?.isStudio;

  const options = {
    isMobileEditor,
    isWelcomeTourOpen,
    isPartner,
    isPotentialPartner,
    isStudio,
  };
  return ALL_BLOCKS.filter((blockName) => {
    const typeFilter = BLOCK_FILTERS[blockName] || (() => true);
    return typeFilter(options);
  });
};

const mapStateToProps: MapStateToProps<StateProps> = ({ state, editorAPI }) => {
  const { languageCode, geo: countryCode } = util.editorModel;

  return {
    blocks: getRelevantBlocks(state, editorAPI),
    popularHelpArticles: HELP_ARTICLES,
    hireProLink: getHireProLink(languageCode, countryCode),
    videoItems: getVideoItems({ isPartner: isUserPartner(state) }),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const getIsMobileEditor = (dispatch: AnyFixMe, getState: AnyFixMe) =>
  stateManagement.leftBar.selectors.isMobileEditor(getState());

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  ownProps,
) => {
  const { closePanelByName } = stateManagement.panels.actions;
  const isMobileEditor = dispatch(getIsMobileEditor);

  const sendBi = (eventType: AnyFixMe, biParams = {}) =>
    dispatch(stateManagement.bi.actions.event(eventType, biParams));

  const close = () => closePanelByName('rEditor.HelpHomePanel');

  return {
    sendBi,
    onHireProClick: () =>
      sendBi(coreBi.events.topbar.arena_button_clicked, {
        origin: 'help_home',
      }),
    openHelpCenter: (helpId?: string) => {
      const { openHelpCenter, openHelpCenterHome } =
        stateManagement.panels.actions;

      dispatch(close());

      if (helpId) {
        dispatch(openHelpCenter(helpId));
        sendBi(coreBi.events.helpHome.help_home_article_click, {
          article_id: helpId,
        });
      } else {
        const HOME_PAGE_ID = isMobileEditor
          ? helpIds.HELP_CENTER.PAGE_TYPES.MOBILE
          : helpIds.HELP_CENTER.PAGE_TYPES.DESKTOP;

        dispatch(openHelpCenterHome(HOME_PAGE_ID, null, null));
        sendBi(coreBi.events.helpHome.help_home_help_center_click);
      }
    },
    close: () => dispatch(close()),
    startWelcomeTour: () => {
      dispatch(close());
      startWelcomeTour(dispatch(getEditorAPI));
      sendBi(coreBi.events.helpHome.help_home_tour_click);
    },
    openSearch: () => {
      dispatch(close());
      dispatch(
        stateManagement.editorSearch.actions.openEditorSearchPanel({
          origin: 'help_home',
        }),
      );
      sendBi(coreBi.events.helpHome.help_home_search_click);
    },
    openHowToVideo: (videoId: string) => {
      const PANEL_NAME = 'panels.focusPanels.howToVideoPlayer';
      const { openPanelAction } = stateManagement.panels.actions;

      dispatch(
        openPanelAction({
          name: PANEL_NAME,
          props: {
            origin: 'help_home',
            videoId,
          } as any,
        }),
      );

      sendBi(coreBi.events.helpHome.help_home_video_click, {
        video_id: videoId,
      });
    },
    onVideoScrollClick: () =>
      sendBi(coreBi.events.helpHome.help_home_video_scroll_click),
    onOpen: (blocks, articles) => {
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.HELP_HOME_OPEN,
      );

      const { origin } = ownProps;

      const blocksStr = blocks.join(';');
      const articlesStr = articles
        .map(({ helpId }) => `article_${helpId}`)
        .join(',');
      const visibleSections = blocksStr.replace(
        HelpBlock.ARTICLES,
        articlesStr,
      );

      sendBi(coreBi.events.helpHome.help_home_panel_open, {
        origin,
        visible_sections: visibleSections,
        articles: articlesStr,
      });
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(HelpHome);

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';

const { backgroundUtils } = util;
const { getMediaPlayerVideoObject } = backgroundUtils;
const documentIcons = {
  pdf: '40d80b8089c8418a961cb75d96d491f7.png',
  doc: 'b8964aa7650d463fac3b77e0d00e8f12.png',
  docx: 'b8964aa7650d463fac3b77e0d00e8f12.png',
  ppt: 'a41192b40f324972a1d919a8350965f8.png',
  ppx: 'a41192b40f324972a1d919a8350965f8.png',
  xls: '757aeccaa7f4409b93913c55e36a194d.png',
  xlsx: '757aeccaa7f4409b93913c55e36a194d.png',
  odp: 'ac1fbe0592434c58889947de5714b3c7.png',
  odt: 'ac1fbe0592434c58889947de5714b3c7.png',
  other: '046956f7d55f4143a30b3b54669ee243.png',
};

const PREFERRED_SIZE = 640;
const MIN_SIZE = 240;

const createStyleStructure = (overrides) =>
  _.merge(
    {
      type: 'ComponentStyle',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      },
      style: {
        properties: {},
        propertiesSource: {},
        groups: {},
      },
    },
    overrides,
  );

const getSizeByWidth = (width, aspect) => ({ width, height: width / aspect });

const getSizeByHeight = (height, aspect) => ({
  width: height * aspect,
  height,
});

const getMediaProportionSize = (aspectRatio) => {
  const landscape = aspectRatio > 1;

  const isAspectInRange =
    aspectRatio < PREFERRED_SIZE / MIN_SIZE &&
    aspectRatio > MIN_SIZE / PREFERRED_SIZE;
  if (isAspectInRange) {
    return landscape
      ? getSizeByWidth(PREFERRED_SIZE, aspectRatio)
      : getSizeByHeight(PREFERRED_SIZE, aspectRatio);
  }

  return landscape
    ? getSizeByHeight(MIN_SIZE, aspectRatio)
    : getSizeByWidth(MIN_SIZE, aspectRatio);
};

const getAspectRatio = (videoData) => {
  const quality = _.head(videoData.qualities);
  return quality.width / quality.height;
};

const getMediaPlayerContainerStructure = (
  videoData,
  videoSize,
  editorBuildDefaultComponentStructure,
) => {
  const structure = _.omit(
    editorBuildDefaultComponentStructure(
      'wysiwyg.viewer.components.MediaPlayer',
    ),
    'data',
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const hasAlpha = _.includes(videoData.mediaFeatures, 'alpha');

  structure.design.background = {
    type: 'BackgroundMedia',
    mediaRef: videoData,
    alignType: 'center',
    fittingType: 'fill',
    imageOverlay: null,
    colorOverlay: '',
    colorOverlayOpacity: 0,
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(structure, {
    type: 'Container',
    layout: {
      width: videoSize.width,
      height: videoSize.height,
    },
    style: createStyleStructure({
      skin: 'wysiwyg.viewer.skins.mediaPlayerSkin',
    }),
  });

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  _.assign(structure.props, {
    autoplay: true,
    loop: true,
    mute: true,
    disableAudio: false,
    playerInteraction: {
      click: hasAlpha ? 'none' : 'toggle',
      rollIn: 'none',
      rollOut: 'none',
    },
  });

  return structure;
};

const getMediaPlayerOverlayControlsStructure = (
  overlaySize,
  videoSize,
  editorBuildDefaultComponentStructure,
) => {
  const overlay = _.omit(
    editorBuildDefaultComponentStructure(
      'wysiwyg.viewer.components.MediaOverlayControls',
    ),
    'data',
  );

  overlay.layout = {
    width: overlaySize,
    height: overlaySize,
    x: (videoSize.width - overlaySize) / 2,
    y: (videoSize.height - overlaySize) / 2,
  };

  overlay.design = {
    type: 'MediaControlsDesignData',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    iconsDefaultDesign: {
      type: 'VectorImageDesignData',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      shapeStyle: {
        opacity: 0.8,
        strokeOpacity: 1,
        stroke: '#000000',
        enableStroke: false,
        strokeWidth: 1,
      },
      overrideColors: {
        color1: '#000000',
      },
    },
    icons: [
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'playButton',
        svgId: '0da768_661bc0cafffa432db3753ad5d17e4f10.svg', //icon #05, 1 color play in circle
      },
    ],
  };

  overlay.skin = 'skins.viewer.mediaOverlayControlsDefaultSkin';

  overlay.style = {
    type: 'TopLevelStyle',
    id: 'mocntDefault',
    style: {
      groups: {},
      properties: {},
      propertiesSource: {},
    },
    styleType: 'custom',
    skin: 'skins.viewer.mediaOverlayControlsDefaultSkin',
  };

  return overlay;
};

const getMediaPlayerControlsStructure = (
  videoData,
  videoSize,
  controlsHeight,
  editorBuildDefaultComponentStructure,
) => {
  const controls = _.omit(
    editorBuildDefaultComponentStructure(
      'wysiwyg.viewer.components.MediaControls',
    ),
    'data',
  );

  controls.props.showStoryboard = 'none';

  controls.layout = {
    width: videoSize.width,
    height: controlsHeight,
    x: 0,
    y: videoSize.height - controlsHeight,
  };

  controls.design = {
    type: 'MediaControlsDesignData',
    metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
    iconsDefaultDesign: {
      type: 'VectorImageDesignData',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      shapeStyle: {
        opacity: 1,
        strokeOpacity: 1,
        stroke: '#000000',
        enableStroke: false,
        strokeWidth: 1,
      },
      overrideColors: {
        color1: '#ffffff',
      },
    },
    icons: [
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'volumeOn',
        svgId: '0da768_4026746ebab74627a513aa0df1511dea.svg',
      },
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'volumeOff',
        svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg',
      },
      {
        type: 'ControlIconDesignData',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        name: 'noAudio',
        svgId: '0da768_c6d2d796452644a6a4a243c3afb7b781.svg',
        iconDesign: {
          type: 'VectorImageDesignData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          overrideColors: {
            color1: '#a9a9a9',
          },
        },
      },
    ],
  };

  controls.skin = 'skins.viewer.mediaControlsNoControlsSkin';

  controls.style = {
    type: 'TopLevelStyle',
    id: 'mcntLight',
    style: {
      groups: {},
      properties: {},
      propertiesSource: {},
    },
    styleType: 'custom',
    skin: 'skins.viewer.mediaControlsNoControlsSkin',
  };

  return controls;
};

function getMediaPlayerStructure(
  item,
  info,
  editorBuildDefaultComponentStructure,
) {
  const videoData = getMediaPlayerVideoObject(item, info);
  const aspectRatio = getAspectRatio(videoData);
  const videoSize = getMediaProportionSize(aspectRatio);

  const overlaySize = 86;
  const controlsHeight = 44;

  const structure = getMediaPlayerContainerStructure(
    videoData,
    videoSize,
    editorBuildDefaultComponentStructure,
  );
  const overlay = getMediaPlayerOverlayControlsStructure(
    overlaySize,
    videoSize,
    editorBuildDefaultComponentStructure,
  );
  const controls = getMediaPlayerControlsStructure(
    videoData,
    videoSize,
    controlsHeight,
    editorBuildDefaultComponentStructure,
  );

  structure.components = [overlay, controls];

  return structure;
}

const calcImageCompDimensions = ({ width, height }, maxSize = 600) => {
  const aspect = Math.min(maxSize / width, maxSize / height);

  if (width > maxSize || height > maxSize) {
    width = Math.floor(width * aspect);
    height = Math.floor(height * aspect);
  }

  return {
    width,
    height,
  };
};

function getPictureStructure(item, info, editorBuildDefaultComponentStructure) {
  const { width, height, uri, title, fileInput } = item;
  const MAX_SIZE = 600;
  const { width: layoutWidth, height: layoutHeight } = calcImageCompDimensions(
    { width, height },
    MAX_SIZE,
  );

  const defaultStructure = editorBuildDefaultComponentStructure(
    'wysiwyg.viewer.components.WPhoto',
  );

  return _.merge(defaultStructure, {
    layout: {
      width: layoutWidth,
      height: layoutHeight,
    },
    data: {
      width,
      height,
      uri,
      title,
      hasAnimation: !!fileInput?.animated,
    },
    style: createStyleStructure({
      componentClassName: 'wysiwyg.viewer.components.WPhoto',
      skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
    }),
  });
}

const getImageXStructure = (item, info, buildDefaultCompStructure) => {
  const structure = buildDefaultCompStructure('wixui.ImageX');
  const { width, height } = item;
  const { width: layoutWidth, height: layoutHeight } = calcImageCompDimensions({
    width,
    height,
  });
  const imageData = _.pick(item, ['uri', 'width', 'height', 'fileInput']);

  return _.merge(structure, {
    layout: {
      width: layoutWidth,
      height: layoutHeight,
    },
    data: {
      image: imageData,
      scopedData: [],
    },
    skin: 'wixui.skins.ImageX',
    style: {
      type: 'StylesInBreakpoint',
      skin: 'wixui.skins.ImageX',
      stylesInBreakpoints: [
        {
          pageId: '',
          compId: '',
          type: 'ComponentStyle',
          styleType: 'custom',
          componentClassName: 'wixui.ImageX',
          skin: 'wixui.skins.ImageX',
          style: {
            propertiesSource: {},
            groups: {},
            properties: {
              borderWidth: '0',
              'boxShadowToggleOn-boxShadow': 'false',
              boxShadow: '3.54px 3.54px 10px 0px rgba(0,0,0,0.33)',
              borderColor: '#ffffff',
              cornerRadius: '0',
              backgroundColor: '#000000',
              'alpha-backgroundColor': 0,
            },
          },
        },
      ],
    },
  });
};

const getDocumentStructure = (item) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/split
  const suffix = _.split(item.uri, '.').pop();

  return {
    type: 'Component',
    componentType: 'wysiwyg.viewer.components.documentmedia.DocumentMedia',
    layout: {
      width: 90,
      height: 140,
    },
    data: {
      type: 'Image',
      title: item.title,
      uri: documentIcons[suffix] || documentIcons.other,
      description: '',
      width: 105,
      height: 141,
      link: {
        docId: item.fileUrl,
        name: item.title,
        type: 'DocumentLink',
      },
    },
    style: createStyleStructure({
      skin: 'skins.viewer.documentmedia.DocumentMediaSkin',
    }),
    props: {
      type: 'DocumentMediaProperties',
      showTitle: false,
    },
  };
};

const getMusicPlayerStructure = (item) => {
  const { title } = item;
  // eslint-disable-next-line you-dont-need-lodash-underscore/last-index-of
  const extensionStartIndex = _.lastIndexOf(title, '.');

  return {
    type: 'Component',
    componentType: 'wixui.MusicPlayer',
    layout: {
      width: 300,
      height: 70,
    },
    data: {
      type: 'MusicPlayerData',
      trackName:
        extensionStartIndex === -1
          ? title
          : title.slice(0, extensionStartIndex),
      artistName: '',
      coverRef: {
        type: 'Image',
        title: 'wix-audio-demo-cover.jpg',
        uri: 'f9a47e_e7631916c7e4468091c47929b7acdf8a~mv2.png',
        height: 400,
        width: 400,
      },
      audioRef: {
        type: 'WixAudio',
        title: item.title,
        uri: item.uri,
        duration: Math.floor(item.fileInput.duration / 1000),
      },
    },
    props: {
      id: 'musicPlayerDefaultProps',
      type: 'MusicPlayerProperties',
      showCover: true,
      showArtistName: true,
      showTrackName: true,
      showProgressBar: true,
      showTimeStamp: true,
    },
    style: createStyleStructure({ skin: 'MusicPlayer_1' }),
  };
};

const getVectorImageStructure = (item) => ({
  type: 'Component',
  componentType: 'wysiwyg.viewer.components.VectorImage',
  layout: {
    width: 200,
    height: 200,
  },
  data: {
    type: 'VectorImage',
    svgId: item.uri,
    title: item.title,
  },
  props: {
    type: 'VectorImageProperties',
    displayMode: 'fit',
  },
  design: {
    type: 'VectorImageDesignData',
    shapeStyle: {
      opacity: 1,
    },
  },
  style: createStyleStructure({
    type: 'TopLevelStyle',
    skin: 'skins.viewer.VectorImageSkin',
  }),
});

const getFlashStructure = (item) => ({
  type: 'Component',
  componentType: 'wysiwyg.viewer.components.FlashComponent',
  layout: {
    width: item.width,
    height: item.height,
  },
  data: {
    type: 'LinkableFlashComponent',
    title: item.title,
    uri: item.fileName,
    metaData: {
      isPreset: true,
      schemaVersion: '1.0',
      isHidden: false,
    },
    placeHolderImage: {
      type: 'Image',
      metaData: {
        isPreset: false,
        schemaVersion: '2.0',
        isHidden: false,
      },
      title: 'Default Flash',
      uri: '7ff659c252dfd1ca5c76533695cb9611.wix_mp',
      description: 'Some description here',
      width: 128,
      height: 128,
      alt: '',
    },
  },
  props: {
    type: 'FlashComponentProperties',
    metaData: {
      schemaVersion: '1.0',
    },
    displayMode: 'original',
  },
  style: createStyleStructure({
    skin: 'wysiwyg.viewer.skins.FlashComponentSkin',
  }),
});

const getSocialIconStructure = (item) => getSocialBarStructure([item]);

const getSocialBarStructure = (items) => {
  const itemsCount = items.length;
  const iconSize = 39;
  const iconsSpacing = 10;
  const width = (itemsCount - 1) * (iconSize + iconsSpacing) + iconSize;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const icons = _.map(items, (mediaItem) => ({
    type: 'Image',
    metaData: { isPreset: false, schemaVersion: '2.0', isHidden: false },
    title: mediaItem.title,
    uri: mediaItem.uri,
    description: '',
    width: 200,
    height: 200,
    alt: '',
    link: {
      type: 'ExternalLink',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      url: '',
      target: '_blank',
    },
  }));

  return {
    type: 'Component',
    componentType: 'wysiwyg.viewer.components.LinkBar',
    skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
    layout: {
      width,
      height: iconSize,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    data: {
      type: 'ImageList',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      items: icons,
    },
    props: {
      type: 'LinkBarProperties',
      metaData: { schemaVersion: '1.0' },
      gallery: 'social_icons',
      iconSize,
      spacing: iconsSpacing,
      bgScale: 1,
      orientation: 'HORIZ',
    },
    style: {
      type: 'TopLevelStyle',
      id: 'lb1',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      style: { groups: { displayer: {} } },
      componentClassName: '',
      pageId: '',
      compId: '',
      styleType: 'system',
      skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
    },
    activeModes: {},
  };
};

export {
  getPictureStructure as picture,
  getImageXStructure as imageX,
  getDocumentStructure as document,
  getDocumentStructure as archive,
  getMusicPlayerStructure as music,
  getMediaPlayerStructure as video,
  getSocialBarStructure as socialBar,
  getSocialIconStructure as socialIcon,
  getVectorImageStructure as shape,
  getFlashStructure as swf,
};

import _ from 'lodash';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { AppData } from 'types/documentServices';
import type { EditorInteractionName } from 'types/fedops';
import type { CompLayout, CompRef } from 'types/documentServices';
import { WIX_PRO_GALLERY } from '@wix/app-definition-ids';
import { overridable } from '@wix/santa-editor-utils';

import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';

import tpaConstants from '../constants/constants';
import * as tpaAlertsService from './tpaAlertsService';
import * as pendingAppsService from './pendingAppsService';
import type { EditorAPI } from '#packages/editorAPI';
import { tpaUtils, editorWixRecorder } from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import { reportBiForAddedComponentToStage } from '../utils/tpaUtils';
import type { PlatformOrigin } from '#packages/platform';
import type { AddAppData } from '@wix/editor-platform-host-integration-apis';
import type { ProvisionErrorData } from '#packages/stateManagement';
import {
  EditorType,
  InstallInitiator,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';
import experiment from 'experiment';

class TpaInstallationError extends Error {}

let appDefCurrentlyInstalling: string[] = [];

const platformActions = stateManagement.platform.actions;
const INSTALLATION_FLOWS_TO_LOG = {
  '14724f35-6794-cd1a-0244-25fd138f9242': {
    name: 'wix_forum',
    children: {
      '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9': 'members_area',
      '14cffd81-5215-0a7f-22f8-074b0e2401fb': 'member_account_info',
      '14ce28f7-7eb0-3745-22f8-074b0e2401fb': 'profile_card',
      '14dbef06-cc42-5583-32a7-3abd44da4908': 'about',
      '14ebe801-d78a-daa9-c9e5-0286a891e46f': 'followers',
      '14dbefd2-01b4-fb61-32a7-3abd44da4908': 'all_members',
      '14f25924-5664-31b2-9568-f9c5ed98c9b1': 'notifications',
      '14f25dc5-6af3-5420-9568-f9c5ed98c9b1': 'settings',
    },
  },
  [tpaUtils.getStoresAppDefId()]: {
    name: 'wix_stores',
    children: {
      '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9': 'members_area',
      '14cffd81-5215-0a7f-22f8-074b0e2401fb': 'member_account_info',
      '14ce28f7-7eb0-3745-22f8-074b0e2401fb': 'profile_card',
      '1505b775-e885-eb1b-b665-1e485d9bf90e': 'my_addresses',
      '4aebd0cb-fbdb-4da7-b5d1-d05660a30172': 'my_wallet',
    },
  },
  '13d21c63-b5ec-5912-8397-c3a5ddb27a97': {
    name: 'wix_bookings',
    children: {
      '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9': 'members_area',
      '14cffd81-5215-0a7f-22f8-074b0e2401fb': 'member_account_info',
      '14ce28f7-7eb0-3745-22f8-074b0e2401fb': 'profile_card',
    },
  },
  '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9': {
    name: 'members_area',
    children: {
      '14cffd81-5215-0a7f-22f8-074b0e2401fb': 'member_account_info',
      '14ce28f7-7eb0-3745-22f8-074b0e2401fb': 'profile_card',
    },
  },
};

function buildInteractionName(appDefId: string) {
  const isParentInstallation = appDefCurrentlyInstalling.length === 0;
  const parentId = isParentInstallation
    ? appDefId
    : appDefCurrentlyInstalling[0];
  const childId = isParentInstallation ? null : appDefId;

  const { name: parentName, children } =
    INSTALLATION_FLOWS_TO_LOG[parentId] || {};

  if (parentName) {
    if (children[childId as keyof typeof children] || isParentInstallation) {
      return ['install', parentName, children[childId as keyof typeof children]]
        .filter(Boolean)
        .join('_') as EditorInteractionName;
    }
  }

  return null;
}

function logStart(appDefId: string) {
  const interaction = buildInteractionName(appDefId);

  if (interaction) {
    util.fedopsLogger.interactionStarted(interaction);
  }
}

function logEnd(appDefId: string, data: { success?: boolean }) {
  if (!data?.success) {
    ErrorReporter.captureException(
      new TpaInstallationError(`Installation Failed: ${appDefId}`),
      {
        tags: {
          platformEditor: true,
        },
        extra: {
          appDefId,
          appDefCurrentlyInstalling,
          data,
        },
      },
    );
    return;
  }

  const interaction = buildInteractionName(appDefId);

  if (interaction) {
    util.fedopsLogger.interactionEnded(interaction);
  }
}

function markInstallationStarted(appDefId: string) {
  logStart(appDefId);
  appDefCurrentlyInstalling.push(appDefId);
}

function markInstallationDone(appDefId: string, data: object) {
  appDefCurrentlyInstalling = _.without(appDefCurrentlyInstalling, appDefId);
  logEnd(appDefId, data);
}

const preAddComponent = function (
  editorAPI: EditorAPI,
  appDefId: string,
  options: AnyFixMe,
  onComplete: FunctionFixMe,
) {
  firstSaveIfNeeded(editorAPI, appDefId, () => {
    if (!appDefCurrentlyInstalling.includes(appDefId)) {
      markInstallationStarted(appDefId);
      const pageBeforeInstall = editorAPI.dsRead.pages.getFocusedPageId();
      options = options || {};
      options.onError = _.partial(onError, editorAPI, appDefId);

      const callback = options.callback || _.noop;
      options.callback = function (data: AnyFixMe) {
        markInstallationDone(appDefId, data);
        const targetPage = options?.widget?.wixPageId || pageBeforeInstall;
        const pageAfterInstall = editorAPI.dsRead.pages.getFocusedPageId();
        if (
          data.comp &&
          editorAPI.components.is.exist(data.comp) &&
          pageAfterInstall === targetPage
        ) {
          editorAPI.selection.selectComponentByCompRef(data.comp);
        }
        onAddComplete(
          editorAPI,
          appDefId,
          tpaConstants.HISTORY.COMPONENT,
          _.partial(callback, data),
          pageBeforeInstall,
          pageAfterInstall,
          data,
          options.resolveBeforeSave,
        );
      };
      closeAddPanel(editorAPI);
    }

    onComplete();
  });
};

export interface AddWidgetBIParams {
  addingMethod: 'drag' | 'click';
  category: string;
  section: string;
  presetId: string;
}

export type AddWidgetOptions = {
  widgetId: string;
  layout: Partial<CompLayout>;
  callback: ({ comp, onError }: { comp?: CompRef; onError: string }) => void;
  dontStretch: boolean;
  styleId: string;
  parentContainerRef: CompRef;
  biOrigin: string;
  origin: PlatformOrigin;
  onError: (editorAPI: EditorAPI, appDefId: string) => void;
  resolveBeforeSave?: AnyFixMe;
  componentName: string;
  containerRef?: CompRef;
} & AddWidgetBIParams;

const enlargeContainerIfNeeded = async (
  editorAPI: EditorAPI,
  options: AddWidgetOptions,
  appDefId: string,
) => {
  const widgetHeight = options.layout?.height;
  if (
    !options?.containerRef ||
    !widgetHeight ||
    !editorAPI.sections.isSection(options?.containerRef)
  ) {
    return;
  }
  const shouldWidgetFitIntoContainer = appDefId === WIX_PRO_GALLERY;
  if (!shouldWidgetFitIntoContainer) return;

  const containerHeight = editorAPI.components.layout.get_size(
    options?.containerRef,
  )?.height;

  if (containerHeight && widgetHeight > containerHeight) {
    editorAPI.components.layout.updateAndAdjustLayout(
      options.containerRef,
      {
        height: widgetHeight,
      },
      true,
    );

    await editorAPI.waitForChangesAppliedAsync();
  }
};

const addWidget = async function (
  editorAPI: EditorAPI,
  appDefId: string,
  options: AddWidgetOptions,
): Promise<CompRef> {
  await firstSaveIfNeededAsync(editorAPI, appDefId);
  await enlargeContainerIfNeeded(editorAPI, options, appDefId);
  const originalCallback = options?.callback;
  const addWidgetAsyncFunction: () => Promise<CompRef> = () =>
    new Promise((resolve) => {
      addWidgetSync(editorAPI, appDefId, {
        ...options,
        callback: (data) => {
          originalCallback?.(data);
          if (data?.onError) {
            onError(editorAPI, appDefId);
          }
          resolve(data?.comp);
        },
      });
    });
  if (
    experiment.isOpen('se_installWidgetOnUnifiedFlow') &&
    !editorAPI.platform.isAppActive(appDefId)
  ) {
    let componentAdded = false;
    return editorAPI.store.dispatch(
      platformActions.installApp(
        appDefId,
        {
          ...options,
          callback: (data) => {
            if (data?.comp) {
              componentAdded = true;
            }
            return data?.comp;
          },
        },
        {
          onError() {
            onError(editorAPI, appDefId);
          },
          onAppInstallationComplete: () => {
            if (componentAdded) {
              return;
            }
            return addWidgetAsyncFunction();
          },
        },
      ),
    );
  }

  return addWidgetAsyncFunction();
};

const addWidgetSync = function (
  editorAPI: EditorAPI,
  appDefId: string,
  options: AddWidgetOptions,
) {
  if (appDefCurrentlyInstalling.includes(appDefId)) {
    return;
  }

  markInstallationStarted(appDefId);

  const callback = options.callback || _.noop;
  const originalCallback = !!options.callback;
  const componentExists = (comp: CompRef) =>
    comp && editorAPI.components.is.exist(comp);
  options.callback = function (data) {
    markInstallationDone(appDefId, data);
    if (componentExists(data.comp)) {
      reportBiForAddedComponentToStage(editorAPI, data.comp, {
        appDefId,
        category: options.category,
        section: options.section,
        addingMethod: options.addingMethod,
        presetId: options.presetId,
        origin:
          //  Se line with `options.origin = options.platformOrigin`
          typeof options.biOrigin === 'object'
            ? JSON.stringify(options.biOrigin)
            : options.biOrigin,
        componentName: options.componentName,
      });

      const componentType = editorAPI.components.getType(data.comp);

      editorWixRecorder.addLabel(`${componentType} added to stage`);
    }
    const onAddCompleteCallback = (data: AnyFixMe) => {
      if (componentExists(data.comp)) {
        editorAPI.selection.selectComponentByCompRef(data.comp);
      }

      if (_.isFunction(callback)) {
        callback(data);
      }
    };

    onAddComplete(
      editorAPI,
      appDefId,
      tpaConstants.HISTORY.WIDGET,
      _.partial(onAddCompleteCallback, data),
      null,
      null,
      data,
      options.resolveBeforeSave,
    );
  };

  options.onError = originalCallback
    ? _.partial(options.callback, {
        onError: `provision for widget for appDefId ${appDefId} failed`,
      })
    : _.partial(onError, editorAPI, appDefId);

  options.origin = options?.origin || {
    type: EditorType.Classic,
    initiator: InstallInitiator.Editor,
    info: {
      type: InstallationOriginType.AddPanel,
    },
  };

  const widgetPointer = editorAPI.dsActions.tpa.widget.add(appDefId, options);

  closeAddPanel(editorAPI);

  return widgetPointer;
};

const onError = function (editorAPI: EditorAPI, appDefId: string) {
  markInstallationDone(appDefId, { success: false });
  tpaAlertsService.openProvisionFailedAlert(
    editorAPI.panelManager.openPanel,
    appDefId,
    true,
  );
  //will close other open panels
};

const addSection = async (
  editorAPI: EditorAPI,
  appDefId: string,
  options: AnyFixMe,
) => {
  firstSaveIfNeeded(editorAPI, appDefId, function () {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (!_.includes(appDefCurrentlyInstalling, appDefId)) {
      markInstallationStarted(appDefId);
      const pageBeforeInstall = editorAPI.dsRead.pages.getFocusedPageId();
      options = options || {};

      const callback = options.callback || _.noop;
      const originalCallback = !!options.callback;

      options.callback = function (data: AnyFixMe) {
        markInstallationDone(appDefId, data);
        onAddComplete(
          editorAPI,
          appDefId,
          tpaConstants.HISTORY.SECTION,
          _.partial(callback, data),
          pageBeforeInstall,
          data?.page?.id,
          data,
          options.resolveBeforeSave,
        );
      };

      options.biData = { origin: options.origin };

      if (options.platformOrigin) {
        options.origin = options.platformOrigin;
        delete options.platformOrigin;
      }

      const onErrorCallback = originalCallback
        ? _.partial(options.callback, {
            onError: `provision for section for appDefId ${appDefId} failed`,
          })
        : _.partial(onError, editorAPI, appDefId);

      editorAPI.store.dispatch(
        platformActions.installApp(appDefId, options, {
          onError: onErrorCallback,
        }),
      );
      closeAddPanel(editorAPI);
    } else if (options?.callback) {
      options.callback({
        page: {
          id: editorAPI.dsRead.pages.getFocusedPageId(),
        },
      });
    }
  });
};

const addMultiSection = function (
  editorAPI: EditorAPI,
  appDefId: string,
  options: AnyFixMe,
) {
  firstSaveIfNeeded(editorAPI, appDefId, function () {
    const callback = options.callback || _.noop;
    options.callback = function (data: AnyFixMe) {
      onAddComplete(
        editorAPI,
        appDefId,
        tpaConstants.HISTORY.MULTI_SECTION,
        _.partial(callback, data),
        null,
        null,
        data,
        options.resolveBeforeSave,
      );
    };

    // @ts-expect-error
    editorAPI.dsActions.tpa.section.addMultiSection(appDefId, options);

    closeAddPanel(editorAPI);
  });
};

const addSubSection = function (
  editorAPI: EditorAPI,
  appDefId: string,
  options: AnyFixMe,
) {
  firstSaveIfNeeded(editorAPI, appDefId, function () {
    const callback = options.callback || _.noop;
    options.callback = function (data: AnyFixMe) {
      onAddComplete(
        editorAPI,
        appDefId,
        tpaConstants.HISTORY.SUB_SECTION,
        _.partial(callback, data),
        null,
        null,
        data,
        options.resolveBeforeSave,
      );
    };

    // @ts-expect-error
    editorAPI.dsActions.tpa.section.addSubSection(appDefId, options);

    closeAddPanel(editorAPI);
  });
};

const getSnapshotOptions = function (
  pageBeforeInstall: AnyFixMe,
  pageAfterInstall: AnyFixMe,
  innerRouteFromBeforeInstall: AnyFixMe,
) {
  if (pageBeforeInstall !== pageAfterInstall) {
    return {
      actionType: constants.DS_ACTIONS.ADD_PAGE,
      currentPage: pageBeforeInstall,
      currentInnerRoute: innerRouteFromBeforeInstall,
      nextPage: pageAfterInstall,
    };
  }
  return undefined;
};

const getLastSnapshotIdIfNeeded = function (editorAPI: EditorAPI) {
  const undoLastSnapshotId =
    editorAPI.dsActions.history.getUndoLastSnapshotId();
  const undoLastSnapshotLabel =
    editorAPI.dsActions.history.getUndoLastSnapshotLabel();
  return undoLastSnapshotLabel === tpaConstants.HISTORY.BEFORE_ADDING_APP
    ? undoLastSnapshotId
    : undefined;
};

const onAddComplete = function (
  editorAPI: EditorAPI,
  appDefId: string,
  historyText: string,
  callback: (...args: AnyFixMe) => void,
  pageBeforeInstall: string,
  pageAfterInstall: string,
  result: AnyFixMe,
  resolveBeforeSave?: boolean,
  disableSave?: boolean,
  innerRouteFromBeforeInstall?: object,
) {
  pendingAppsService.updateNotification(editorAPI);

  if (_.isEmpty(appDefCurrentlyInstalling) && !util.url.noAppSave()) {
    if (result?.success && !disableSave) {
      editorAPI.history.add(
        historyText,
        getSnapshotOptions(
          pageBeforeInstall,
          pageAfterInstall,
          innerRouteFromBeforeInstall,
        ),
        getLastSnapshotIdIfNeeded(editorAPI),
      );

      if (resolveBeforeSave) {
        callback(result);
        callback = _.noop;
      }

      const saveAppAfterAdd = () => {
        editorAPI.saveManager.saveInBackground(
          callback,
          function (e: Error | { message: string } = { message: '' }) {
            tpaAlertsService.openProvisionFailedAlert(
              editorAPI.panelManager.openPanel,
              appDefId,
              true,
            );
            e.message +=
              ' - CRITICAL: save failed on add complete with no uninstall';
            ErrorReporter.captureException(e);
            callback(e); //will close other open panels
          },
          'saveAppAfterAdd',
          {
            sourceOfStart: 'tpaAddRemove_bgSave',
          },
        );
      };

      if (!editorAPI.savePublish.canSaveOrPublish()) {
        editorAPI.editorEventRegistry.registerOnce(
          editorAPI.editorEventRegistry.constants.events.SAVE_PUBLISH_UNLOCKED,
          saveAppAfterAdd,
        );

        return;
      }

      saveAppAfterAdd();
    } else {
      callback(result);
    }
  } else {
    callback(result);
  }
};

const deleteWidget = function (editorAPI: EditorAPI, compRef: CompRef) {
  editorAPI.dsActions.tpa.widget.delete(compRef);
  editorAPI.panelManager.closePanelByName('tpa.compPanels.tpaSettings');
};

const deleteSection = function (
  editorAPI: AnyFixMe,
  pageId: AnyFixMe,
  tpasOnPageBiParams: AnyFixMe,
) {
  editorAPI.dsActions.tpa.section.delete(pageId);
  editorAPI.history.add('deleting a tpa section');
  util.editorWixRecorder.addLabel(
    `${tpasOnPageBiParams.component_type} component removed`,
  );
  editorAPI.bi.reportBI(util.bi.events.COMPONENT_REMOVED, tpasOnPageBiParams);
  editorAPI.components.reset();
  editorAPI.saveManager.saveInBackground(_.noop, _.noop, 'tpaDeleteSection');
};

const closeAddPanel = function (editorAPI: EditorAPI) {
  const commandPressedForKeepPanelOpen =
    util.keyboardShortcuts.isPressed.command();
  const shouldCloseAddPanel = !commandPressedForKeepPanelOpen;

  if (shouldCloseAddPanel) {
    editorAPI.panelManager.closePanelByName(
      constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
    );
  } else {
    editorAPI.bi.event(coreBi.events.shortcuts.keep_add_panel_open_add_comp);
  }
};

const firstSaveIfNeeded = function (
  editorAPI: EditorAPI,
  appDefId: string,
  callback: (argument?: AnyFixMe) => void,
) {
  const isFirstSave = editorAPI.dsRead.generalInfo.isFirstSave();
  if (isFirstSave && !util.url.noAppSave()) {
    editorAPI.saveManager.saveInBackground(
      callback,
      function (e: Error | { message: string } = { message: '' }) {
        tpaAlertsService.openProvisionFailedAlert(
          editorAPI.panelManager.openPanel,
          appDefId,
          true,
        );
        e.message += ' - CRITICAL: save failed on first save with no uninstall';
        ErrorReporter.captureException(e);
        //will close other open panels
      },
      'saveBeforeAddingApp',
    );
  } else {
    callback();
  }
};

const firstSaveIfNeededAsync = (editorAPI: EditorAPI, appDefId: string) => {
  return new Promise((resolve) => {
    firstSaveIfNeeded(editorAPI, appDefId, (data: any) => resolve(data));
  });
};

function getInnerRouteFromCurrentNavInfo(editorAPI: EditorAPI) {
  const rootNavigationInfo =
    editorAPI.dsRead.pages.getRootNavigationInfo() || {};

  return {
    innerRoute: rootNavigationInfo?.innerRoute,
    routerId: rootNavigationInfo?.routerDefinition?.routerId,
  };
}

const addPlatform = overridable(
  (editorAPI: EditorAPI, appDefId: string, options: AnyFixMe) => {
    firstSaveIfNeeded(editorAPI, appDefId, function () {
      if (!appDefCurrentlyInstalling.includes(appDefId)) {
        markInstallationStarted(appDefId);
        const pageBeforeInstall = editorAPI.dsRead.pages.getFocusedPageId();
        const innerRouteFromBeforeInstall =
          getInnerRouteFromCurrentNavInfo(editorAPI);

        const doneCallback = (
          data: AddAppData | AppData | ProvisionErrorData,
        ) => {
          markInstallationDone(appDefId, data);
          const pageAfterInstall = editorAPI.dsRead.pages.getFocusedPageId();
          const successErrorCallback = options.callback || _.noop;
          onAddComplete(
            editorAPI,
            appDefId,
            tpaConstants.HISTORY.PLATFORM,
            _.partial(successErrorCallback, data),
            pageBeforeInstall,
            pageAfterInstall,
            data,
            options.resolveBeforeSave,
            options.disableAddCompleteSave,
            innerRouteFromBeforeInstall,
          );
        };

        options.skipActiveApps = false;
        options.headlessInstallation = options.headlessInstallation ?? true;

        editorAPI.store.dispatch(
          platformActions.installApp(appDefId, options, {
            onError: doneCallback,
            onAppInstallationComplete: doneCallback,
          }),
        );
        closeAddPanel(editorAPI);
      }
    });
  },
);

const clearAppsCurrentlyInstalling = function () {
  appDefCurrentlyInstalling = [];
};

export {
  addWidget,
  addWidgetSync,
  preAddComponent,
  addSection,
  addSubSection,
  addMultiSection,
  addPlatform,
  deleteWidget,
  deleteSection,
  clearAppsCurrentlyInstalling,
};

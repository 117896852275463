import _ from 'lodash';
import experiment from 'experiment';
import { EditorAPIKey } from '#packages/apis';

import {
  userPreferences,
  panels,
  components,
  helpPanel,
  panelsSelectors,
} from '#packages/stateManagement';
import { definitions } from '#packages/panels';

import type { CompRef } from 'types/documentServices';
import type {
  PanelDescriptor,
  PanelOptions,
  ComponentPanelOptions,
  PanelFilterFunction,
} from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import type { PanelType } from '#packages/constants';
import type { Shell } from '#packages/apilib';

const { selectPanelFilters } = panelsSelectors;

export function createPanelManagerApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const { store } = editorAPI;

  function openPlatformPanel(options: AnyFixMe) {
    store.dispatch(panels.actions.openPlatformPanel(options));
  }

  /**
   * Update or open panel
   * @param panelName - uniq panel name
   * @param panelProps - panel props for panel react component
   * @param {boolean} optionsOrLeavePanelsOpen - [deprecated] use `options.leavePanelsOpen` instead
   * @param {PanelOptions} optionsOrLeavePanelsOpen - `options` object
   * @param optionsOrLeavePanelsOpen.leavePanelsOpen - leave other panels open
   * @param optionsOrLeavePanelsOpen.panelLoader - panel loader function which returns promise with panel react component
   *
   * @example
   * // open panel
   * panelManager.openPanel('myOwesomePanelUniqName', { title: 'I Am Owesome Panel' }, {
   *  panelLoader: () => import('./path/to/myOwesomePanel').then(m => m.MyOwesomePanel),
   *  leavePanelsOpen: true,
   * }));
   */
  function openPanel(
    panelName: string,
    panelProps?: object,
    optionsOrLeavePanelsOpen?: PanelOptions | boolean,
  ) {
    store.dispatch(
      panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        optionsOrLeavePanelsOpen,
      ),
    );
  }

  /**
   * Opens a component panel - component panels are rendered in a position related to the component, not pre-defined
   * @param panelName the package export path of the panel i.e. "compPanels.panels.SiteButton.settingsPanel"
   * @param {object} [panelProps] props that will be passed on to the requested panel when created (according to the panel's declared PropTypes i.e. "title", "position" etc.)
   * @param options - ComponentPanelOptions
   */
  function openComponentPanel(
    panelName: string,
    panelProps?: object,
    options?: ComponentPanelOptions,
  ) {
    const props: object = {
      // Some panels which use the default GFPP buttons expect `experimentIsOpen` function
      experimentIsOpen: experiment.isOpen,
      ...(panelProps || {}),
    };
    store.dispatch(
      panels.actions.openComponentPanel(panelName, props, options),
    );
  }

  function toggleComponentPanel(
    panelName: string,
    panelProps?: { origin?: string } & { [key: string]: any },
    options?: ComponentPanelOptions,
  ) {
    if (isPanelOpened(panelName)) {
      closePanelByName(panelName, panelProps?.origin);
      return;
    }

    openComponentPanel(panelName, panelProps, options);
  }

  /**
   * Replaces an open component panel (or opens the requested panel if no panel was open) - will render component panel in the position the last component panel was open
   * @param panelName the package export path of the panel i.e. "compPanels.panels.SiteButton.settingsPanel"
   * @param {object} [panelProps] props that will be passed on to the requested panel when created (according to the panel's declared PropTypes i.e. "title", "position" etc.)
   */
  function replaceComponentPanel(panelName: string, panelProps: object) {
    store.dispatch(
      panels.actions.openComponentPanel(
        panelName,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, panelProps, { useLastPanelPosition: true }),
      ),
    );
  }

  function openHelpCenter(
    helpId: string,
    props?: object,
    biHelpParams?: object,
  ) {
    store.dispatch(panels.actions.openHelpCenter(helpId, props, biHelpParams));
  }

  function openHelpCenterHome(
    type: string,
    props: object,
    biHelpParams: object,
  ) {
    store.dispatch(
      panels.actions.openHelpCenterHome(type, props, biHelpParams),
    );
  }

  function closeHelpCenter() {
    store.dispatch(helpPanel.actions.closeHelpCenter());
  }

  function hasDeprecationPanel(
    editorAPI: EditorAPI,
    compType: string,
  ): boolean {
    const compDeprecationPanel =
      definitions.deprecationPanelDefinitions.definitions[compType];
    if (!compDeprecationPanel) {
      return false;
    }
    return _.isFunction(compDeprecationPanel.isDisabled)
      ? !compDeprecationPanel.isDisabled(editorAPI)
      : true;
  }

  function shouldOpenDeprecationPanel(
    editorAPI: EditorAPI,
    compRef: CompRef,
  ): boolean {
    const compType = editorAPI.components.getType(compRef);
    const panelDefinitions =
      definitions.deprecationPanelDefinitions.definitions[compType];
    if (!panelDefinitions) {
      return false;
    }
    const isDisabled =
      _.isFunction(panelDefinitions.isDisabled) &&
      panelDefinitions.isDisabled(editorAPI);
    const compSuffix = components.selectors.getCompTypeSuffix(
      compRef,
      editorAPI.dsRead,
    );
    return (
      !isDisabled &&
      !userPreferences.selectors.getSiteUserPreferences(
        `${definitions.deprecationPanelDefinitions.DEPRECATION_PANEL_PREF_KEY}_${compSuffix}`,
      )(store.getState())
    );
  }

  function openDeprecationPanel(compType: string): void {
    const panelDefinitions =
      definitions.deprecationPanelDefinitions.definitions[compType];
    if (panelDefinitions) {
      store.dispatch(
        panels.actions.openDeprecationPanel(
          'compPanels.dynamicPanels.deprecationPanel',
          {
            ...panelDefinitions,
            //@ts-expect-error
            DEPRECATION_PANEL_PREF_KEY:
              definitions.deprecationPanelDefinitions
                .DEPRECATION_PANEL_PREF_KEY,
          },
        ),
      );
    }
  }

  /**
   * Close an open panel that has the provided package export path
   * @param panelName the package export path of the panel i.e. "compPanels.panels.SiteButton.settingsPanel"
   * @param closeOrigin the control or user action that is closing the panel (see panelCloseOrigin.js)
   */
  function closePanelByName(panelName: string, closeOrigin?: string) {
    store.dispatch(panels.actions.closePanelByName(panelName, closeOrigin));
  }

  /**
   * Close all panels with the provided frame type
   * @param {string} frameType type of frame, according to the frame's declared displayName
   */
  function closeAllPanelsOfType(frameType: PanelType, origin?: string) {
    store.dispatch(panels.actions.closeAllPanelsOfType(frameType, origin));
  }

  /**
   * Close all open panels
   */
  function closeAllPanels() {
    store.dispatch(panels.actions.closeOpenedPanels());
  }

  /**
   * Get an array of open panels descriptors
   *
   * @typedef {Object} PanelDescriptor
   * @property {string} name the package export path of the panel i.e. "compPanels.panels.SiteButton.settingsPanel"
   * @property {string} frameType type of frame, according to the frame's declared displayName
   * @property {string} props props that are passed on to the panel
   *
   * @return {Array.<PanelDescriptor>}
   */
  function getOpenPanels(): PanelDescriptor[] {
    return panels.selectors.selectOpenPanels(store.getState());
  }

  /**
   * returns if a specific panel is opened
   * @param panelName - string
   */
  function isPanelOpened(panelName: string): boolean {
    return !!getOpenPanels().find(({ name }) => name === panelName);
  }

  /**
   * Register a type for a panel that is already opened and does not have a type yet
   * SHOULD ONLY BE USED BY FRAMES. Happens inside the HigherOrderComponent panelFrame.
   * @param panelName the package export path of the panel i.e. "compPanels.panels.SiteButton.settingsPanel"
   * @param frameType type of frame, according to the frame's declared displayName
   * @param singleInstance {boolean} if true, opening another panel of this type will result in props update only
   */
  function registerPanelType(
    panelName: string,
    frameType: string,
    singleInstance: boolean,
  ) {
    store.dispatch(
      panels.actions.setPanelType(panelName, frameType, singleInstance),
    );
  }

  /**
   * Notify open panels that have registered for a mouseDown event that it occured.
   * @param {object} evt the mouseDown event
   */
  function notifyMouseDown() {
    store.dispatch(panels.actions.closePanelsOnStageMouseDown());
  }

  /**
   * Update panel props
   *
   * @param {string} panelName
   * @param {Object} props
   */
  function updatePanelProps(panelName: string, props: object) {
    store.dispatch(panels.actions.updatePanel(panelName, props));
  }

  /**
   * Get all panels with the provided frame type
   * @param frameType type of frame, according to the frame's declared displayName
   *
   * @return {Array.<PanelDescriptor>}
   */
  function getPanelsOfType(frameType: PanelType): PanelDescriptor[] {
    const openPanels = panels.selectors.selectOpenPanels(store.getState());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(openPanels, { frameType });
  }

  /**
   *
   * @param {string} compType
   * @param {object} controllerData
   */
  function openFirstTimePanel(compType: string, controllerData = {}) {
    store.dispatch(panels.actions.openFirstTimePanel(compType, controllerData));
  }

  /**
   * find platform panel from open panel by  panel type
   *
   * @param {string} panelType
   */
  function findPlatformPanelByType(panelType: string) {
    const openedPanels = panels.selectors.selectOpenPanels(store.getState());
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    return _.some(openedPanels, ['props.type', panelType]);
  }

  /**
   * Opens a panel if SitePreference condition returns true
   * @param sitePreferenceKey - site preference key to query
   * @param restOpenPanelProps - openPanel props - see openPanel doc
   */
  function openPanelConsideringSitePreference(
    sitePreferenceKey: string,
    ...restOpenPanelProps: Parameters<typeof openPanel>
  ): void {
    if (userPreferences.selectors.getSiteUserPreferences(sitePreferenceKey)) {
      openPanel(...restOpenPanelProps);
    }
  }

  /**
   * Add a filter that can cancel opening panels
   * @param filterName
   * @param filter
   */
  function addPanelFilter(filterName: string, filter: PanelFilterFunction) {
    store.dispatch(panels.actions.addPanelFilter(filterName, filter));
  }

  /**
   * Check if the opening of the panel is blocked
   * @param panelDescriptor
   */
  function isPanelBlockedByFilter(panelDescriptor: PanelDescriptor) {
    const panelFilters = selectPanelFilters(store.getState());

    return Object.values(panelFilters).some((filter) =>
      filter(panelDescriptor),
    );
  }

  return {
    openPanel,
    openComponentPanel,
    toggleComponentPanel,
    replaceComponentPanel,
    closePanelByName,
    closeAllPanels,
    getOpenPanels,
    registerPanelType,
    getPanelsOfType,
    closeAllPanelsOfType,
    isPanelOpened,
    openHelpCenter,
    openHelpCenterHome,
    closeHelpCenter,
    notifyMouseDown,
    hasDeprecationPanel,
    openDeprecationPanel,
    openPanelConsideringSitePreference,
    shouldOpenDeprecationPanel,
    openPlatformPanel,
    openFirstTimePanel,
    updatePanelProps,
    findPlatformPanelByType,
    addPanelFilter,
    isPanelBlockedByFilter,
  };
}

import { cx } from '#packages/util';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import React, { useEffect, memo } from 'react';
import ComponentControlWrapper, {
  type QuickEditControlWrapperInnerOwnProps,
} from './componentControlWrapper';
import styles from './styles/quickEditCompPanelWrapper.scss';
import { QuickEditEngineScope } from '../quickEditEngineEntryPoint';
import type { DrillInPanelData } from '../quickEditEngineStore';

interface QuickEditDrillInPanelWrapperOwnProps
  extends QuickEditControlWrapperInnerOwnProps {
  drillInPanelIndex: number;
  getDrillInPanelData: (drillInPanelIndex: number) => DrillInPanelData;
  onDrillInPanelLoaded: (
    compId: string,
    panelName: string,
    compType: string,
  ) => void;
}

type QuickEditDrillInPanelWrapperProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  QuickEditDrillInPanelWrapperOwnProps
>;

const QuickEditDrillInPanelWrapper: React.FC<QuickEditDrillInPanelWrapperProps> =
  memo(
    ({
      compRef,
      onPanelLoaded,
      getOverrideCompControls,
      onControlClick,
      onComponentChanged,
      isComponentFiltered,
      getPermissionsConfig,
      openComponentPanelDrillIn,
      onDrillInAction,
      onLinkAction,
      onGfppAction,
      onControlHover,
      onControlLeave,
      onMainActionClick,
      getMainActionLabel,
    }: QuickEditDrillInPanelWrapperProps) => {
      useEffect(() => {
        if (compRef) onPanelLoaded();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [compRef.id]);

      if (!compRef) return null;
      return (
        <div
          className={cx(styles.quickEditCompPanelWrapper)}
          data-hook="quick-edit-drill-in-wrapper"
        >
          <ComponentControlWrapper
            compRef={compRef}
            rootControlCompRef={null}
            shouldRenderDivider={false}
            hasListAncestor={false}
            onControlHover={onControlHover}
            onControlLeave={onControlLeave}
            onMainActionClick={onMainActionClick}
            getMainActionLabel={getMainActionLabel}
            getOverrideCompControls={getOverrideCompControls}
            onControlClick={onControlClick}
            onComponentChanged={onComponentChanged}
            isComponentFiltered={isComponentFiltered}
            getPermissionsConfig={getPermissionsConfig}
            openComponentPanelDrillIn={openComponentPanelDrillIn}
            onDrillInAction={onDrillInAction}
            onLinkAction={onLinkAction}
            onGfppAction={onGfppAction}
            isMainComponent={true}
          />
        </div>
      );
    },
    (prevProps, nextProps) => {
      return prevProps.compRef.id === nextProps.compRef.id;
    },
  );

QuickEditDrillInPanelWrapper.displayName = 'QuickEditDrillInPanelWrapper';

const mapStateToProps = (
  {}: QuickEditEngineScope,
  {
    drillInPanelIndex,
    getDrillInPanelData,
  }: QuickEditDrillInPanelWrapperOwnProps,
) => {
  const compRef = getDrillInPanelData(drillInPanelIndex).compRef;
  return {
    compRef,
  };
};

const mapDispatchToProps = (
  { editorAPI }: QuickEditEngineScope,
  {
    drillInPanelIndex,
    getDrillInPanelData,
    onDrillInPanelLoaded,
  }: QuickEditDrillInPanelWrapperOwnProps,
) => {
  const drillInPanelData = getDrillInPanelData(drillInPanelIndex);
  const panelName = drillInPanelData.panelData.panelName;
  const compRef = drillInPanelData.compRef;
  const compType = editorAPI.components.getType(compRef);
  return {
    onPanelLoaded: () => {
      onDrillInPanelLoaded(compRef.id, panelName, compType);
    },
  };
};

export default connectWithScope(
  () => QuickEditEngineScope,
  QuickEditDrillInPanelWrapper,
  mapStateToProps,
  mapDispatchToProps,
);

import experiment from 'experiment';
import type { Scope } from './scope';
import {
  getIsSiteReady,
  getSiteState,
  updatePopupShownCounter,
  getPopupShownCounter,
  setInitialBiData,
} from './siteReadinessApi';
import { PANEL_NAMES } from './consts';

const TRIGGER_TIME = 5000;

export async function init(scope: Scope) {
  const isSiteReadinessTestEnabled = experiment.isOpen('se_siteReadinessTest');
  const { isDomainConnected, isSitePremium } = getSiteState(scope);
  const { editorCoreAPI, accountApi, panelManagerAPI } = scope;
  const isSiteReady = await getIsSiteReady(scope);
  const showPopup =
    isSiteReady &&
    accountApi.isUserOwner() &&
    !isDomainConnected &&
    !isSitePremium;

  if (!(showPopup || isSiteReadinessTestEnabled)) {
    return;
  }

  await editorCoreAPI.hooks.stageIsReady.promise;
  await editorCoreAPI.hooks.stageIsReadyAndVisible.promise.then(() => {
    setTimeout(() => {
      const popupShownCounter = getPopupShownCounter(scope);
      if (popupShownCounter >= 2) {
        return;
      }

      const isPopupShownFirstTime = popupShownCounter > 0;
      const origin = isPopupShownFirstTime
        ? 'next_time_trigger'
        : 'first_trigger';

      panelManagerAPI.openPanel(PANEL_NAMES.CHECK_READINESS, {
        origin,
        is_premium: isSitePremium,
      });
      updatePopupShownCounter(scope);
      setInitialBiData(scope, origin);
    }, TRIGGER_TIME);
  });
}

import React from 'react';
import { InfoIcon, TextLabel } from '@wix/wix-base-ui';

import * as symbols from '@wix/santa-editor-symbols';

import addPanelDataConsts from '#packages/addPanelDataConsts';
import constants from '#packages/constants';
import { SectionHeader } from '#packages/baseUI';
import { workspace } from '#packages/util';
import { translate } from '#packages/i18n';

const SECTION_HEADER_TOOLTIP_MARGIN = 5;

interface SectionTitleProps {
  title: string;
  onPlayVideo?: () => void;
  onHoverVideoIcon?: () => void;
  videoThumbnailSrc?: string;
  bubbleText?: string;
  bubbleTitle?: string;
}

export default class SectionTitle extends React.Component<SectionTitleProps> {
  private isNewWorkspace: boolean = workspace.isNewWorkspaceEnabled();

  styleHeader = {
    width: addPanelDataConsts.ADD_PANEL.LIVE_COMPONENT_CATEGORY_CONTENT,
    minHeight: addPanelDataConsts.ADD_PANEL.SECTION_HEADER_HEIGHT,
  };

  getInfoIconProps() {
    const { bubbleTitle, bubbleText, onPlayVideo, onHoverVideoIcon } =
      this.props;
    return {
      title: bubbleTitle,
      text: bubbleText,
      small: false,
      shouldCloseOnMouseClick: true,
      tooltipMarginLeft: this.getToolTipMargin(),
      tooltipMarginRight: this.getToolTipMargin(),
      customSymbol: this.getVideoSymbol(),
      alignment: constants.UI.TOOLTIP.ALIGNMENT.RIGHT,
      closeOnContentMouseClick: true,
      illustration: this.getIllustration(),
      onIllustrationClick: onPlayVideo,
      onTooltipOpen: onHoverVideoIcon,
    };
  }

  getToolTipMargin() {
    return SECTION_HEADER_TOOLTIP_MARGIN;
  }

  getIllustration() {
    const Symbol = symbols.symbol;
    return (
      <div className="info-icon-image-container">
        <img className="info-icon-image" src={this.props.videoThumbnailSrc} />
        <Symbol name="play-video" key="play-video" />
      </div>
    );
  }
  getVideoSymbol() {
    const Symbol = symbols.symbol;
    return <Symbol name="info_video_tooltip_icon" />;
  }

  render() {
    if (this.isNewWorkspace) {
      return (
        <SectionHeader
          titleAutomationId="divider-title"
          infoIconAutomationId="video-icon-helper"
          className="new-workspace-ascend-section-title"
          title={translate(this.props.title)}
          tooltipTitle={translate(this.props.bubbleTitle)}
          tooltipText={translate(this.props.bubbleText)}
          tooltipIllustration={this.getIllustration()}
          infoIconSymbol={this.getVideoSymbol()}
          onTooltipIllustrationClick={this.props.onPlayVideo}
          onTooltipOpen={this.props.onHoverVideoIcon}
          closeOnTooltipClick={true}
        />
      );
    }

    return (
      <div
        style={this.styleHeader}
        key="header"
        className="ascend-section-title"
      >
        <div className="title-line">
          <TextLabel
            value={this.props.title}
            ellipsisProps={{ disabled: true }}
            type="T18"
            automationId="divider-title"
            className="title new-title-sub-category"
          />
          {this.props.onPlayVideo && (
            // @ts-expect-error
            <InfoIcon
              automationId="video-icon-helper"
              key="infoIcon"
              className="info-icon"
              {...this.getInfoIconProps()}
            />
          )}
        </div>
      </div>
    );
  }
}

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '#packages/core';
import * as pageMenuItemHelpers from '../../../utils/pageMenuItemHelpers';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import DynamicPageSettingsPanelTabs from '../../../settings/dynamicPageSettingsPanelTabs';
import PageSettingsPanelTabs from '../../../settings/pageSettingsPanelTabs';
import PageSettingsDesktopCommon from './pageSettingsDesktopCommon';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  setActiveTab(activeTab) {
    this.setState({ activeTab });
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_tab_click,
      { category: activeTab },
    );
  },
  getInitialState() {
    return {
      activeTab: this.props.activeTab || this.getDefaultTabName(),
    };
  },
  componentWillReceiveProps(nextProps) {
    if (nextProps.activeTab && this.props.activeTab !== nextProps.activeTab) {
      this.setActiveTab(nextProps.activeTab);
    } else {
      const newPageId = nextProps.menuItem.pageData.id;
      const oldPageId = this.props.menuItem.pageData.id;
      if (newPageId !== oldPageId) {
        this.tryToPreserveSelectedTab(newPageId, oldPageId);
      }
    }
  },
  tryToPreserveSelectedTab(newPageId, oldPageId) {
    const editorAPI = this.getEditorAPI();
    const newPageDynamic =
      !!editorAPI.dsRead.routers.getRouterDataForPageIfExist(newPageId);
    const oldPageDynamic =
      !!editorAPI.dsRead.routers.getRouterDataForPageIfExist(oldPageId);
    const changeFromStaticToDynamicOrViceVersa =
      (newPageDynamic && !oldPageDynamic) ||
      (!newPageDynamic && oldPageDynamic);

    if (changeFromStaticToDynamicOrViceVersa) {
      this.setActiveTab(this.getDefaultTabName(newPageId));
    } else if (newPageDynamic && oldPageDynamic) {
      const newPageTabs = this.getDynamicPageTabs(newPageId);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const sameTabAsActive = _.find(newPageTabs, {
        title: this.state.activeTab,
      });
      if (!sameTabAsActive) {
        this.setActiveTab(this.getDefaultTabName(newPageId));
      }
    }
  },
  getHelpId() {
    if (this.isDynamicPage()) {
      const tabs = this.getDynamicPageTabs(this.props.menuItem.pageData.id);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const activeTab = _.find(tabs, { title: this.state.activeTab });
      if (!activeTab.helpId) {
        return '995c5103-5599-4725-9e4f-c6ae4a47bf35';
      }
      return activeTab.helpId;
    }

    return '2f65df85-2eba-4b41-a9bf-2a841a5e5a53';
  },
  displayName: 'pageSettingsDesktop',
  propTypes: {
    menuItem: PropTypes.object.isRequired,
    activeTab: PropTypes.string,
    actions: PropTypes.object,
    closeSettingsPanel: PropTypes.func.isRequired,
  },
  render() {
    return (
      <PageSettingsDesktopCommon
        title={this.props.menuItem.label}
        closeSettingsPanel={this.props.closeSettingsPanel}
        helpId={this.getHelpId()}
      >
        {!this.isDynamicPage() ? (
          <PageSettingsPanelTabs
            key="pageSettingsPanelTabs"
            setActiveTab={this.setActiveTab}
            selectedTab={this.state.activeTab}
            ref="pageSettingsPanelTabs"
            {...this.props}
          />
        ) : null}

        {this.isDynamicPage() ? (
          <DynamicPageSettingsPanelTabs
            developerModeEnabled={this.isDeveloperModeEnabled()}
            tabs={this.getDynamicPageTabs(this.props.menuItem.pageData.id)}
            key="dynamicPageSettingsPanelTabs"
            setActiveTab={this.setActiveTab}
            selectedTab={this.state.activeTab}
            ref="dynamicPageSettingsPanelTabs"
            {...this.props}
          />
        ) : null}

        <footer>
          {!this.isDynamicPage() ? (
            <div key="pageSettingsControls" className="page-settings-controls">
              {this.shouldShowDuplicateButton() ? (
                <span
                  key="page-settings-controls-duplicate"
                  onClick={this.duplicatePage}
                  className="duplicate"
                >
                  <span>
                    <symbols.symbol name="duplicate" />
                  </span>
                  {translate('Pages_Settings_Footer_Duplicate')}
                </span>
              ) : null}

              {this.shouldShowRemoveButton() ? (
                <span
                  key="page-settings-controls-remove"
                  onClick={this.deletePage}
                  className="remove"
                >
                  <span>
                    <symbols.symbol name="pageDelete" />
                  </span>
                  {translate('Pages_Settings_Footer_Delete')}
                </span>
              ) : null}
            </div>
          ) : null}

          <baseUI.button
            label="Pages_Settings_Footer_Done"
            onClick={this.doneClickHandler}
          />
        </footer>
      </PageSettingsDesktopCommon>
    );
  },
  shouldShowDuplicateButton() {
    return pageMenuItemHelpers.canDuplicate(
      this.getEditorAPI(),
      this.props.menuItem,
    );
  },
  shouldShowRemoveButton() {
    return pageMenuItemHelpers.canRemoveMenuItem(
      this.getEditorAPI(),
      this.props.menuItem,
    );
  },
  duplicatePage() {
    this.props.actions.duplicate(this.props.menuItem);
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_duplicate_page_click,
    );
  },
  deletePage() {
    const page = this.props.menuItem;
    const editorAPI = this.getEditorAPI();
    this.props.closeSettingsPanel('deletePage');
    editorAPI.dsActions.waitForChangesApplied(
      _.partial(pageMenuItemHelpers.removeMenuItem, editorAPI, page),
    );
    editorAPI.bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_delete_page_click,
    );
  },
  getDefaultTabName(pageId) {
    pageId = pageId || this.props.menuItem.pageData?.id;
    if (this.isDynamicPage(pageId)) {
      const tabs = this.getDynamicPageTabs(pageId);
      return !_.isEmpty(tabs) ? tabs[0].title : 'Page Info';
    }

    return 'page';
  },
  getDynamicPageTabs(pageId) {
    const editorAPI = this.getEditorAPI();
    return editorAPI.pages.dynamicPages.getDynamicPageActions(
      pageId,
      'pageSettings',
    );
  },
  doneClickHandler() {
    this.props.closeSettingsPanel('done');
  },
  isDynamicPage(pageId) {
    const editorAPI = this.getEditorAPI();
    pageId = pageId || this.props.menuItem.pageData?.id;
    if (pageId) {
      return !!editorAPI.dsRead.routers.getRouterDataForPageIfExist(pageId);
    }
    return false;
  },
  isDeveloperModeEnabled() {
    const editorAPI = this.getEditorAPI();
    return editorAPI.developerMode.isEnabled();
  },
});

// @ts-nocheck
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_box',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.DESIGN),
      onClick: gfppDataUtils.getTogglePanelFn(ACTIONS.DESIGN),
    },
  ],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.HELP, ACTIONS.ANIMATION],
  mobileEnabledActions: [],
  presetActions: {
    help: {
      helpId: 'dbf8b94a-3026-4f68-8197-a1be2ba8c86c',
    },
  },
};

import _ from 'lodash';
import * as util from '#packages/util';
import constants from '#packages/constants';
import * as reselect from 'reselect';
import * as stateManagement from '#packages/stateManagement';

import type { MapStateToProps } from 'types/redux';
import type { PanelDescriptor, EditorState } from '#packages/stateManagement';
import type { CompRef } from 'types/documentServices';

const { selectOpenPanels } = stateManagement.panels.selectors;

function getNonCompPanels(openPanels: PanelDescriptor[]): PanelDescriptor[] {
  const frameTypesToIgnore: string[] = [
    constants.PANEL_TYPES.COMP,
    constants.PANEL_TYPES.FULL_STAGE,
    constants.PANEL_TYPES.DOCKED_PANEL,
    constants.PANEL_TYPES.RIGHT,
  ];

  if (util.workspace.isNewWorkspaceEnabled()) {
    frameTypesToIgnore.push(constants.PANEL_TYPES.LEFT);
  }

  return _.reject(openPanels, (panel) =>
    frameTypesToIgnore.some(
      (frameToIgnore) => panel.frameType?.includes(frameToIgnore),
    ),
  );
}

export interface OpenedPanelOwnProps {
  openPanels?: PanelDescriptor[]; // Only for EditorX overriding
  isMobileEditor: boolean;
  withHelpPanel?: boolean;
  isFullStagePanel?: boolean;
  selectedComponents: CompRef[];
  helpPanelProps: EditorState['helpProps'];
}

export interface OpenedPanelStateProps {
  openPanels: PanelDescriptor[];
}

function getFullStagePanel(openPanels: PanelDescriptor[]): PanelDescriptor[] {
  return _.compact(
    openPanels.filter(
      (panelDef) => panelDef.frameType === constants.PANEL_TYPES.FULL_STAGE,
    ),
  );
}

const getIsFullStagePanelSel = (
  state: EditorState,
  props: OpenedPanelOwnProps,
): boolean => props.isFullStagePanel;

const getOpenPanelsSel = reselect.createSelector(
  [selectOpenPanels, getIsFullStagePanelSel],
  (openPanels, isFullStagePanel) => {
    return isFullStagePanel
      ? getFullStagePanel(openPanels)
      : getNonCompPanels(openPanels);
  },
);

export const mapStateToProps: MapStateToProps<
  OpenedPanelStateProps,
  OpenedPanelOwnProps
> = ({ state }, ownProps) => ({
  openPanels: ownProps?.openPanels || getOpenPanelsSel(state, ownProps),
});

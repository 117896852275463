import { PanelManagerApiKey, EditorAPIKey } from '#packages/apis';
import type { Shell } from '#packages/apilib';
import * as stateManagement from '#packages/stateManagement';

let panelController: string | null = null;

const { openPlatformPanel } = stateManagement.panels.actions;

const editorKey = 'Editor';

const Errors = {
  FAKE_OPEN: 'Cannot use Progress Bar Panel - Fake progress bar is open.',
  OPEN_BY_OTHER_APP: (appDefId: string) =>
    `Progress Bar Panel is open by other app - ${appDefId}.`,
  OPEN_BY_THIS_APP: (appDefId: string) =>
    `Progress Bar Panel wad already open by this app - ${appDefId}.`,
  CLOSE_BY_OTHER_APP: (appDefId: string) =>
    `Progress Bar Panel can be closed only by the app that opened it - ${appDefId}.`,
  UPDATE_NOT_OPEN: `Progress Bar Panel is not open.`,
  UPDATE_OTHER_APP: (appDefId: string) =>
    `Progress Bar Panel can be updated only by the app that opened it - ${appDefId}.`,
};

export function createProgressBar(shell: Shell) {
  const panelManagerAPI = shell.getAPI(PanelManagerApiKey);
  const editorAPI = shell.getAPI(EditorAPIKey);

  const isFakeProgressBarPanelOpen = () => {
    return panelManagerAPI
      .getOpenPanels()
      .some(
        (panel) => panel.name === 'panels.focusPanels.fakeProgressBarPanel',
      );
  };

  const isProgressBarPanelOpen = () => {
    return panelManagerAPI
      .getOpenPanels()
      .some((panel) => panel.name === 'panels.focusPanels.progressBarPanel');
  };

  const isProgressBarOpen = () =>
    isProgressBarPanelOpen() || isFakeProgressBarPanelOpen();

  function openProgressBar(
    {
      title,
      totalSteps,
      currentStep,
      taskDisplayName,
      illustration,
      hideText = false,
    }: {
      title: string;
      totalSteps: number;
      currentStep?: number;
      taskDisplayName?: string;
      illustration?: string;
      hideText?: boolean;
    },
    leavePanelsOpen: boolean = false,
    appDefinitionId: string = editorKey,
  ): void {
    const isEditorControl = panelController === editorKey;
    if (isEditorControl || isFakeProgressBarPanelOpen()) return;

    if (panelController === appDefinitionId) {
      throw Error(Errors.OPEN_BY_THIS_APP(panelController));
    }
    if (panelController !== null) {
      throw Error(Errors.OPEN_BY_OTHER_APP(panelController));
    }
    const panelProps = {
      title,
      progressMax: totalSteps,
      step: currentStep,
      taskDisplayName,
      illustration,
      isDone: false,
      hideText,
    };

    editorAPI.store.dispatch(
      openPlatformPanel({
        panelName: 'panels.focusPanels.progressBarPanel',
        panelProps,
        panelMetaData: {
          closeAllOtherPanels: !leavePanelsOpen,
          closeWithUserIntent: false,
          resolve: progressBarWasClosed,
        },
      }),
    );

    panelController = appDefinitionId;
  }

  function updateProgressBar(
    currentStep: number,
    stepTitle?: string,
    title?: string,
    appDefinitionId: string = editorKey,
  ): void {
    const isEditorControl = panelController === editorKey;
    const initiatedByApp = appDefinitionId !== editorKey;
    if ((isEditorControl && initiatedByApp) || isFakeProgressBarPanelOpen()) {
      return;
    }

    if (panelController === null) {
      throw Error(Errors.UPDATE_NOT_OPEN);
    }

    if (appDefinitionId !== panelController) {
      throw Error(Errors.UPDATE_OTHER_APP(panelController));
    }

    const panelProps = {
      step: currentStep,
    } as any;

    if (stepTitle !== null) {
      panelProps.taskDisplayName = stepTitle;
    }
    if (title) {
      panelProps.title = title;
    }

    panelManagerAPI.updatePanelProps(
      'panels.focusPanels.progressBarPanel',
      panelProps,
    );
  }

  function closeProgressBar(
    isError: boolean = false,
    appDefinitionId: string = editorKey,
  ) {
    return new Promise<void>((resolve, reject) => {
      const isEditorControl = panelController === editorKey;
      const initiatedByApp = appDefinitionId !== editorKey;
      if ((isEditorControl && initiatedByApp) || isFakeProgressBarPanelOpen()) {
        return resolve();
      }

      if (appDefinitionId !== panelController) {
        reject(new Error(Errors.CLOSE_BY_OTHER_APP(panelController)));
      }

      const panelProps = isError
        ? { isError: true, panelClosed: resolve }
        : { isDone: true, panelClosed: resolve };

      panelManagerAPI.updatePanelProps(
        'panels.focusPanels.progressBarPanel',
        panelProps,
      );

      panelController = null;
      return resolve();
    });
  }

  function progressBarWasClosed() {
    panelController = null;
  }

  return {
    openProgressBar,
    updateProgressBar,
    closeProgressBar,
    progressBarWasClosed,
    isProgressBarOpen,
  };
}

// @ts-nocheck
import _ from 'lodash';
import * as bi from '../bi/bi';
import constants from '../constants/constants';
import * as stateManagement from '#packages/stateManagement';
import experiment from 'experiment';

const validateRoutersPagesMapping = function (dsRead) {
  const routers = dsRead.routers.get.all();
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const pageIds = _.map(dsRead.pages.getPagesData(), 'id');
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const pageIdsInRouters = _.reduce(
    routers,
    (result, router) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      result.push(_.values(router?.pages));
      return _.flatten(result);
    },
    [],
  );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/every, you-dont-need-lodash-underscore/includes
  return _.every(pageIdsInRouters, (pageId) => _.includes(pageIds, pageId));
};

const prePageAppInstalling = function (
  editorAPI,
  appName,
  appDefId,
  {
    showPageAddedPanel = false,
  }: {
    showPageAddedPanel?: boolean;
    showProgressBar?: boolean;
  } = {},
) {
  if (showPageAddedPanel) {
    editorAPI.store.dispatch(
      stateManagement.platform.actions.setIsAppInstallationInProgress(true),
    );
    editorAPI.dsActions.documentMode.enableRenderTPAsIframe(false);
    editorAPI.history.add(constants.HISTORY.BEFORE_ADDING_APP);

    if (experiment.isOpen('dm_platformWorkerConcurrentEditing')) {
      return;
    }
    editorAPI.dsActions.initAutosave(
      editorAPI.autosaveManager.getAutosaveConfig({ enabled: false }),
    );
  }
};

const onPageAppInstallingCompleted = function (
  editorAPI,
  appName,
  appDefId,
  data,
  { origin }: { origin?: string } = {},
) {
  const tpaInstallationSuccessful = data?.success;
  editorAPI.dsActions.documentMode.enableRenderTPAsIframe(true);
  editorAPI.store.dispatch(
    stateManagement.platform.actions.setIsAppInstallationInProgress(false),
  );
  editorAPI.notifyViewerUpdate();
  if (
    tpaInstallationSuccessful &&
    validateRoutersPagesMapping(editorAPI.dsRead)
  ) {
    if (experiment.isOpen('dm_platformWorkerConcurrentEditing')) {
      return;
    }
    editorAPI.dsActions.initAutosave(
      editorAPI.autosaveManager.getAutosaveConfig({ enabled: true }),
    );
  } else {
    onPageAppInstallingFailed(editorAPI, appName, appDefId, true, { origin });
  }
};

const onPageAppInstallingFailed = function (
  editorAPI,
  appName,
  appDefId,
  shouldUndo,
  { origin }: { origin?: string } = {},
) {
  editorAPI.panelManager.updatePanelProps(
    'panels.focusPanels.fakeProgressBarPanel',
    { isError: true },
  );
  editorAPI.bi.event(bi.events.PLATFORM_APP_FAILED, {
    app_name: appName,
    app_id: appDefId,
    ...(origin ? { origin } : {}),
  });

  if (shouldUndo) {
    editorAPI.dsActions.waitForChangesApplied(function () {
      editorAPI.history.undo();
    });
  }
};

export {
  prePageAppInstalling,
  onPageAppInstallingCompleted,
  onPageAppInstallingFailed,
};

// @ts-nocheck
import React from 'react';
import { DATA_BINDING } from '@wix/app-definition-ids';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as baseUI from '#packages/baseUI';
import * as platform from '#packages/platform';
import _ from 'lodash';
import * as core from '#packages/core';
import dynamicPageSettingsNoDevModeTab from './tabs/dynamicPageSettingsNoDevMode';
import tabLayout from './tabs/tabLayout';
import tabPermissions from './tabs/tabPermissions';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin],
  displayName: 'dynamicPageSettingsPanelTabs',
  propTypes: {
    menuItem: PropTypes.object.isRequired,
    selectedTab: PropTypes.string,
    setActiveTab: PropTypes.func.isRequired,
    tabs: PropTypes.array.isRequired,
  },
  render() {
    return (
      <div>
        <baseUI.tabs.holder
          value={this.props.selectedTab}
          onChange={(value) => {
            this.props.setActiveTab(value);
          }}
          className="top page-settings-tabs dynamic-page-settings-tabs"
        >
          <baseUI.tabs.header>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.tabs, (tab, tabIndex) => (
              <baseUI.tabs.label
                tabsAmount={this.props.tabs.length}
                key={tab.title + tabIndex}
                htmlFor={tab.title}
              >
                {tab.title}
              </baseUI.tabs.label>
            ))}
          </baseUI.tabs.header>

          <baseUI.tabs.content className="page-settings-panel-content">
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.props.tabs, (tab, tabIndex) => (
              <baseUI.tabs.tab
                key={tabIndex}
                name={tab.title}
                children={this.getTabContent(tab, tabIndex)}
              />
            ))}
          </baseUI.tabs.content>
        </baseUI.tabs.holder>
      </div>
    );
  },
  componentWillMount() {
    const editorAPI = this.getEditorAPI();
    const pageRef = editorAPI.components.get.byId(
      this.props.menuItem.pageData.id,
    );
    if (pageRef) {
      const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
      this.routerData = routerRef
        ? editorAPI.dsRead.routers.get.byRef(routerRef)
        : '';
    } else {
      this.routerData = '';
    }
  },
  getInitialState() {
    return {
      iframeLoaded: false,
    };
  },
  shouldComponentUpdate(nextProps) {
    const editorAPI = this.getEditorAPI();
    const pageRef = editorAPI.components.get.byId(
      this.props.menuItem.pageData.id,
    );
    if (pageRef) {
      const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
      const newRouterData = routerRef
        ? editorAPI.dsRead.routers.get.byRef(routerRef)
        : '';
      if (!_.isEqual(newRouterData, this.routerData)) {
        this.routerData = newRouterData;
        return true;
      }
    } else {
      this.routerData = '';
    }

    if (this.props.developerModeEnabled !== nextProps.developerModeEnabled) {
      return true;
    }
    if (
      this.props.menuItem.pageData.id !== nextProps.menuItem.pageData.id ||
      (nextProps.selectedTab &&
        this.props.selectedTab !== nextProps.selectedTab)
    ) {
      return true;
    }
    return false;
  },
  getPostMessageHandler(token) {
    const editorAPI = this.getEditorAPI();
    const pageRef = editorAPI.components.get.byId(
      this.props.menuItem.pageData.id,
    );
    const initialData = {
      routerRef: editorAPI.dsRead.routers.getRouterRef.byPage(pageRef),
      publicUrl: editorAPI.dsRead.generalInfo.getPublicUrl(),
      pageRef,
      origin: 'dynamic_page_settings_panel_tab',
    };
    return platform.platformPostMessageService.createPostMessageListener(
      {
        token,
        initialData,
      },
      token,
    );
  },
  getAppData() {
    const editorAPI = this.getEditorAPI();
    const pageRef = editorAPI.components.get.byId(
      this.props.menuItem.pageData.id,
    );
    const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
    if (routerRef) {
      const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);
      return {
        appDefId: routerData.appDefinitionId,
        applicationId: editorAPI.dsRead.platform.getAppDataByAppDefId(
          routerData.appDefinitionId,
        )?.applicationId,
      };
    }
  },
  getIframeFullSrc(url, appDefId, applicationId) {
    const editorAPI = this.getEditorAPI();
    const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(appDefId);
    return platform.utils.getFullApplicationUrl(
      appData,
      url,
      appDefId,
      applicationId,
    );
  },
  iframeLoadedHandler() {
    //todo: do we need this?
  },

  getTabContent(tab, tabIndex) {
    const consts = platform.constants;
    const className = this.state.iframeLoaded
      ? 'content-iframe loaded'
      : 'content-iframe';
    if (_.isObject(tab) && tab.url) {
      const editorAPI = this.getEditorAPI();
      const APPS_THAT_HAVE_TO_HAVE_DEV_TOOLS_TO_SHOW_PANEL = [
        editorAPI.dsRead.platform.editorApps.DYNAMIC_PAGES.appDefId,
        DATA_BINDING,
      ];
      const appData = this.getAppData();
      if (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        _.includes(
          APPS_THAT_HAVE_TO_HAVE_DEV_TOOLS_TO_SHOW_PANEL,
          appData.appDefId,
        ) &&
        !this.props.developerModeEnabled
      ) {
        return React.createElement(
          dynamicPageSettingsNoDevModeTab,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({}, this.props, {
            key: `dynamic-page-default-tab${this.props.menuItem.pageData.id}${tabIndex}${appData.appDefId}`,
          }),
        );
      }

      return React.createElement(
        baseUI.iframe,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, this.props, {
          key: `dynamic-page-iframe${this.props.menuItem.pageData.id}${tabIndex}${appData.appDefId}`,
          ref: `dynamic-page-iframe${this.props.menuItem.pageData.id}${appData.appDefId}`,
          className,
          appData,
          name: this.props.token,
          src: this.getIframeFullSrc(
            tab.url,
            appData.appDefId,
            appData.applicationId,
          ),
          onPostMessage: this.getPostMessageHandler(this.props.token),
          onLoad: this.iframeLoadedHandler,
        }),
      );
    }
    switch (tab.type) {
      case consts.pages.page_settings.layout.type:
      case consts.pages.page_settings.layout.fallbackType:
        return React.createElement(
          tabLayout,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({}, this.props, {
            menuItem: this.props.menuItem,
            key: `tab-layout${tabIndex}${this.props.menuItem.pageData.id}`,
          }),
        );
      case consts.pages.page_settings.permissions.type:
      case consts.pages.page_settings.permissions.fallbackType:
        return React.createElement(
          tabPermissions,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({}, this.props, {
            pageData: this.props.menuItem.pageData,
            key: `tab-premissions${tabIndex}${this.props.menuItem.pageData.id}`,
          }),
        );
    }
  },
});

import type { AppData } from 'types/documentServices';
import type { Shell } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import type { UninstallAppOptions } from '../services/uninstallService';
import { uninstallApp } from '../services/uninstallService';
import * as stateManagement from '#packages/stateManagement';

export default (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const uninstall = (
    appData: AppData,
    shouldShowModal = true,
    options: UninstallAppOptions = {},
  ) => {
    return uninstallApp(editorAPI, appData, shouldShowModal, options);
  };

  const isSilentInstallRunning = (): boolean =>
    stateManagement.platform.selectors.isSilentInstallRunning(
      editorAPI.store.getState(),
    );

  return {
    isSilentInstallRunning,
    uninstall,
  };
};

import { waitForAddedCompRef } from '#packages/componentsAddUtils';
/* eslint-disable no-console */
import type {
  CompRef,
  CompStructure,
  SerializedCompStructure,
} from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import dividerStrip from '../switchLayoutComparisonToolDividerStrip';
import {
  removeIDsFromSuggestion,
  getSuggestionLayoutY,
} from '../switchLayoutUtil';
import { postPaasSwitchDataFixer } from '../switchLayoutPAASUtil';
import { DIVIDER_STRIP_HEIGHT } from '../consts';
import { EditorPaasApiKey } from '#packages/apis';
import { SectionsApiKey } from '#packages/apis';

export interface NormalizedSuggestionAndTitle {
  normalizedSuggestion: CompStructure;
  title: string;
}

/**
 * This code is only for POC purposes, and will not be used in production.
 * renderAllBruteForceOptionsAndGetBottomYPosition will add all the serialized suggestions to the page
 * below the original strip.
 * @param normalizedSuggestionsAndTitles
 * @param editorAPI
 * @param originalStripBottomYPosition
 */
const renderAllBruteForceOptionsAndGetBottomYPosition = async (
  normalizedSuggestionsAndTitles: NormalizedSuggestionAndTitle[],
  editorAPI: EditorAPI,
  originalStripBottomYPosition: number,
) => {
  const sectionsAPI = editorAPI.host.getAPI(SectionsApiKey);
  let currentSuggestionCompPointer: CompRef;
  let lowestStripBottomYPosition = 0;
  for (const normalizedSuggestionAndTitle of normalizedSuggestionsAndTitles) {
    // eslint-disable-next-line no-loop-func
    await new Promise((resolve: any) => {
      removeIDsFromSuggestion(
        normalizedSuggestionAndTitle.normalizedSuggestion,
      );
      const suggestionLayoutY = getSuggestionLayoutY(
        editorAPI,
        currentSuggestionCompPointer,
        originalStripBottomYPosition,
      );
      const dividerStripSerialized: CompStructure = dividerStrip;
      // @ts-expect-error
      dividerStripSerialized.components[0].components[0].data.text = `<h1 class=\"font_0 divider\" style=\"font-size: 36px; text-align: center;\"><span style=\"font-size:30px;\"><span style=\"color:#000000\"><span style=\"font-family:oswald-medium,oswald,sans-serif\">DIVIDER - ${normalizedSuggestionAndTitle.title}</span></span></span></h1>`;
      dividerStripSerialized.layout.y = 0;
      normalizedSuggestionAndTitle.normalizedSuggestion.layout.y = 0;
      editorAPI.waitForChangesApplied(async () => {
        const dividerSection = sectionsAPI.getBlankSectionStructure({
          height: DIVIDER_STRIP_HEIGHT,
          y: suggestionLayoutY,
          name: `${normalizedSuggestionAndTitle.title} - divider`,
        });

        const currentDividerCompPointer = await waitForAddedCompRef(
          editorAPI.components.add(
            editorAPI.pages.getFocusedPage(),
            dividerSection,
          ),
        );

        await editorAPI.waitForChangesAppliedAsync();

        await waitForAddedCompRef(
          editorAPI.components.add(
            currentDividerCompPointer,
            dividerStripSerialized,
          ),
        );
        await editorAPI.waitForChangesAppliedAsync();

        if (
          normalizedSuggestionAndTitle.normalizedSuggestion.componentType ===
          'wysiwyg.viewer.components.ClassicSection'
        ) {
          currentSuggestionCompPointer = editorAPI.pages.getFocusedPage();
          normalizedSuggestionAndTitle.normalizedSuggestion.layout.y =
            suggestionLayoutY + DIVIDER_STRIP_HEIGHT;
        } else {
          const suggestionSection = sectionsAPI.getBlankSectionStructure({
            height:
              normalizedSuggestionAndTitle.normalizedSuggestion?.layout?.height,
            y: suggestionLayoutY + DIVIDER_STRIP_HEIGHT,
            name: `${normalizedSuggestionAndTitle.title} - divider`,
          });

          currentSuggestionCompPointer = await waitForAddedCompRef(
            editorAPI.components.add(
              editorAPI.pages.getFocusedPage(),
              suggestionSection,
            ),
          );
        }
        await editorAPI.waitForChangesAppliedAsync();

        if (
          normalizedSuggestionAndTitle.normalizedSuggestion.componentType !=
            'wysiwyg.viewer.components.StripColumnsContainer' &&
          normalizedSuggestionAndTitle.normalizedSuggestion.componentType !=
            'wysiwyg.viewer.components.ClassicSection'
        ) {
          const stripStructure =
            editorAPI.documentServices.components.buildDefaultComponentStructure(
              'wysiwyg.viewer.components.StripColumnsContainer',
            );
          stripStructure.components = [
            normalizedSuggestionAndTitle.normalizedSuggestion as SerializedCompStructure,
          ];

          currentSuggestionCompPointer = await waitForAddedCompRef(
            editorAPI.components.add(
              currentSuggestionCompPointer,
              stripStructure,
            ),
          );

          await editorAPI.waitForChangesAppliedAsync();
        } else {
          currentSuggestionCompPointer = await waitForAddedCompRef(
            editorAPI.components.add(
              currentSuggestionCompPointer,
              normalizedSuggestionAndTitle.normalizedSuggestion,
            ),
          );

          await editorAPI.waitForChangesAppliedAsync();
        }

        await editorAPI.waitForChangesAppliedAsync();
        await setTimeout(() => {
          const currentLayout = editorAPI.components.layout.get_rect(
            currentSuggestionCompPointer,
          );

          const currentStripBottomY = currentLayout.y + currentLayout.height;
          if (currentStripBottomY > lowestStripBottomYPosition) {
            lowestStripBottomYPosition = currentStripBottomY;
          }
          resolve();
        }, 100);
      });
    });
  }
  return lowestStripBottomYPosition;
};

export const handleRenderForComparisonTool = async (
  normalizedSuggestionsAndTitles: any,
  originalSerialized: CompStructure,
  editorAPI: EditorAPI,
) => {
  const originalStripBottomYPosition =
    originalSerialized.layout.y + originalSerialized.layout.height;
  const lowestStripBottomYPosition =
    await renderAllBruteForceOptionsAndGetBottomYPosition(
      normalizedSuggestionsAndTitles,
      editorAPI,
      originalStripBottomYPosition,
    );

  return lowestStripBottomYPosition;
};

export const renderPaaSResultsForComparisonTool = async (
  filteredPresets: any[],
  editorAPI: EditorAPI,
  lowestStripBottomYPosition: number,
  originalLayoutTypeMapping: AnyFixMe,
) => {
  const sectionsAPI = editorAPI.host.getAPI(SectionsApiKey);
  const editorPAASApi = editorAPI.host.getAPI(EditorPaasApiKey);
  const pageRef = editorAPI.pages.getFocusedPage();

  await filteredPresets?.reduce(
    async (prevPromise: Promise<number>, preset: any) => {
      const dividerStripSerialized = JSON.parse(JSON.stringify(dividerStrip));
      dividerStripSerialized.layout.y = 0;
      dividerStripSerialized.components[0].components[0].data.text = `<h1 class=\"font_0\" style=\"font-size: 36px; text-align: center;\"><span style=\"font-size:20px;\"><span style=\"color:#000000\"><span style=\"font-family:oswald-medium,oswald,sans-serif\">PAAS - ${preset.id}</span></span></span></h1>`;
      const topPosition = await prevPromise;

      const dividerSection = sectionsAPI.getBlankSectionStructure({
        height: DIVIDER_STRIP_HEIGHT,
        y: topPosition,
        name: `${preset.id} - divider`,
      });

      const currentDividerCompPointer = await waitForAddedCompRef(
        editorAPI.components.add(
          editorAPI.pages.getFocusedPage(),
          dividerSection,
        ),
      );

      await editorAPI.waitForChangesAppliedAsync();

      await waitForAddedCompRef(
        editorAPI.components.add(
          currentDividerCompPointer,
          dividerStripSerialized,
        ),
      );
      await editorAPI.waitForChangesAppliedAsync();

      const newSection = sectionsAPI.getBlankSectionStructure({
        height: 0,
        y: topPosition + DIVIDER_STRIP_HEIGHT,
        name: preset.id,
      });

      const currentSuggestionCompPointer = await waitForAddedCompRef(
        editorAPI.components.add(pageRef, newSection),
      );
      await editorAPI.waitForChangesAppliedAsync();

      await editorPAASApi.addSectionPreset(
        preset.preset,
        currentSuggestionCompPointer,
        0,
      );
      await editorAPI.waitForChangesAppliedAsync();

      postPaasSwitchDataFixer(
        editorAPI,
        originalLayoutTypeMapping,
        currentSuggestionCompPointer,
      );
      await editorAPI.waitForChangesAppliedAsync();

      const addedSectionLayout = editorAPI.components.layout.get_rect(
        currentSuggestionCompPointer,
      );

      const childrenLayouts = editorAPI.components
        .getChildren_DEPRECATED_BAD_PERFORMANCE(currentSuggestionCompPointer)
        .map(editorAPI.components.layout.get_size);
      addedSectionLayout.height = childrenLayouts.reduce(
        (height: number, childLayout) => height + childLayout.height,
        0,
      );
      return (
        addedSectionLayout.y + addedSectionLayout.height + DIVIDER_STRIP_HEIGHT
      );
    },
    Promise.resolve(lowestStripBottomYPosition + DIVIDER_STRIP_HEIGHT),
  );
};

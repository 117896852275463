import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { createPinModeApi } from './pinModeWrapper';
import { PinModeApiKey } from './publicApiKey';

export const PinModeEntrypoint: EntryPoint = {
  name: 'PinModeApi',
  declareAPIs: () => [PinModeApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(PinModeApiKey, () => createPinModeApi(shell));
  },
};

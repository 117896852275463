// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import { translate } from '#packages/i18n';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_theme_text',
  subNavigationTitle: 'add_section_label_theme_text',
  presetTitle: 'add_section_label_theme_text',
  tooltipTitle: 'add_section_label_theme_text',
  automationId: 'add-panel-section-textThemeSection',
  liveTextSection: true,
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    //text section can't have label or icon
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  },
  props: {
    items: [
      {
        id: 'WRichTextStyleFont0',
        liveTextToShow: 'add_menu_text_theme_1',
        themeName: 'font_0',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont0',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 43,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<h1 class="font_0">${translate(
              'text_editor_theme_new_1',
            )}</h1>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: {
          rect: { width: 310 },
          label: 'add_menu_text_theme_1_description',
          labelClasses: 'align-left',
          tags: null,
        },
      },
      {
        id: 'WRichTextStyleFont1',
        liveTextToShow: 'add_menu_text_theme_2',
        themeName: 'font_2',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 43,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<h2 class="font_2">${translate(
              'text_editor_theme_new_2',
            )}</h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: {
          rect: { width: 310 },
          label: 'add_menu_text_theme_2_description',
          labelClasses: 'align-left',
          tags: null,
        },
      },
      {
        id: 'WRichTextStyleFont2',
        liveTextToShow: 'add_menu_text_theme_3',
        themeName: 'font_3',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 65,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<h3 class="font_3">${translate(
              'text_editor_theme_new_3',
            )}</h3>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: { rect: { width: 310 }, label: '', tags: null },
      },
      {
        id: 'WRichTextStyleFont3',
        liveTextToShow: 'add_menu_text_theme_4',
        themeName: 'font_4',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 43,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<h4 class="font_4">${translate(
              'text_editor_theme_new_4',
            )}</h4>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: { rect: { width: 310 }, label: '', tags: null },
      },
      {
        id: 'WRichTextStyleFont5',
        liveTextToShow: 'add_menu_text_theme_5',
        themeName: 'font_5',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 27,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<h5 class="font_5">${translate(
              'text_editor_theme_new_5',
            )}</h5>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: { rect: { width: 310 }, label: '', tags: null },
      },
      {
        id: 'WRichTextStyleFont6',
        liveTextToShow: 'add_menu_text_theme_6',
        themeName: 'font_6',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 24,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<h6 class="font_6">${translate(
              'text_editor_theme_new_6',
            )}</h6>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: { rect: { width: 310 }, label: '', tags: null },
      },
      {
        id: 'WRichTextStyleFont7',
        liveTextToShow: 'add_menu_text_theme_paragraph1',
        themeName: 'font_7',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 32,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<p class="font_7">${translate(
              'add_menu_text_theme_paragraph1',
            )}</p>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: { rect: { width: 310 }, label: '', tags: null },
      },
      {
        id: 'WRichTextStyleFont8',
        liveTextToShow: 'add_menu_text_theme_paragraph2',
        themeName: 'font_8',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 32,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<p class="font_8">${translate(
              'add_menu_text_theme_paragraph2',
            )}</p>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: { rect: { width: 310 }, label: '', tags: null },
      },
      {
        id: 'WRichTextStyleFont9',
        liveTextToShow: 'add_menu_text_theme_paragraph3',
        themeName: 'font_9',
        className: 'live-text',
        structure: {
          id: 'WRichTextStyleFont1',
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 310,
            height: 32,
            x: 7,
            y: 0,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            text: `<p class="font_9">${translate(
              'add_menu_text_theme_paragraph3',
            )}</p>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          style: {
            type: 'TopLevelStyle',
            id: 'txtNew',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          },
        },
        preset: { rect: { width: 310 }, label: '', tags: null },
      },
    ],
    compTypes: ['wysiwyg.viewer.components.WRichText'],
  },
  help: {
    hide: false,
    text: 'add_section_info_text_theme_general',
  },
};

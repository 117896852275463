import React from 'react';
import { Button, Divider, InfoIcon, TextLabel } from '@wix/wix-base-ui';

import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import { hoc, isMainMenuFlowEnabled } from '#packages/util';

import { TRANSLATIONS_MAP } from '../utils/translations';
import { AUTOMATION_IDS } from '../utils/automationIds';
import { createMenuApi } from '../../../API/menuAPI';

import { isMainMenu } from '../../../utils/utils';

import UnsupportedMenuWarning from './UnsupportedMenuWarning';

import type { StateMapperArgs } from 'types/redux';
import type { MenuData } from 'types/documentServices';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export enum HeaderCheckboxValues {
  Main = 'Main',
  Custom = 'Custom',
}

type IStoreProps = ReturnType<typeof mapStateToProps>;

interface IOwnProps {
  onButtonClick(): void;
  onInfoIconLinkClick(): void;
  showMenuItemsCaption: boolean;
  menuId: string;
  isMobile: boolean;
  isTiny: boolean;
  openUpgradeMenuContainerPanel(): void;
}

interface IHeaderProps extends IOwnProps, IStoreProps {}

export const HeaderComponent: React.FC<IHeaderProps> = ({
  onButtonClick,
  onInfoIconLinkClick,
  showMenuItemsCaption,
  menuItemsCaption,
  attachedMenuName,
  secondaryButton,
  tooltipDescription,
  label,
  unsupportedMenuWarningTooltip,
  unsupportedMenuWarningActionLabel,
  openUpgradeMenuContainerPanel,
}) => {
  return (
    <header>
      <div className="menu-manage-panel__header">
        <div className="menu-manage-panel__header-description">
          <TextLabel shouldTranslate={false} value={label} />
          <div className="menu-manage-panel__header-icon">
            <InfoIcon
              automationId={AUTOMATION_IDS.HEADER.INFO_ICON}
              shouldTranslate={false}
              text={tooltipDescription}
              linkText={translate(TRANSLATIONS_MAP.HEADER.TOOLTIP.linkTitle)}
              onLinkClick={onInfoIconLinkClick}
            />
          </div>
        </div>
        <div className="menu-manage-panel__header-menu-title">
          <TextLabel
            type="T03"
            shouldTranslate={false}
            value={attachedMenuName}
            automationId={AUTOMATION_IDS.HEADER.MENU_LABEL}
          />
          <UnsupportedMenuWarning
            text={unsupportedMenuWarningTooltip}
            actionLabel={unsupportedMenuWarningActionLabel}
            onActionClick={openUpgradeMenuContainerPanel}
          />
          <Button
            className={`btn-sm ${
              secondaryButton ? 'btn-confirm-secondary' : ''
            }`}
            onClick={onButtonClick}
            automationId={AUTOMATION_IDS.HEADER.CHANGE_MENU_BUTTON}
          >
            {translate(TRANSLATIONS_MAP.HEADER.CHANGE_LABEL)}
          </Button>
        </div>
      </div>
      {showMenuItemsCaption && (
        <>
          <Divider long />
          <baseUI.sectionDividerLabeled label={menuItemsCaption} />
        </>
      )}
    </header>
  );
};

const getAttachedMenuName = (
  attachedMenu: MenuData,
  isMainMenuFlow: boolean,
  isMobile: boolean,
  isMainMenu: boolean,
) => {
  if (!isMainMenuFlow) {
    return attachedMenu.name;
  }

  if (isMobile) {
    return isMainMenu
      ? translate(TRANSLATIONS_MAP.MAIN_MENU_FLOW.MENU_NAMES.MOBILE.main)
      : translate(TRANSLATIONS_MAP.MAIN_MENU_FLOW.MENU_NAMES.MOBILE.custom);
  }

  return isMainMenu
    ? translate(TRANSLATIONS_MAP.MAIN_MENU_FLOW.MENU_NAMES.main)
    : translate(TRANSLATIONS_MAP.MAIN_MENU_FLOW.MENU_NAMES.custom);
};

const getTooltipDescription = (
  isMainMenuFlow: AnyFixMe,
  isMobile: AnyFixMe,
) => {
  if (!isMainMenuFlow) {
    return translate(TRANSLATIONS_MAP.HEADER.TOOLTIP.description);
  }

  return isMobile
    ? translate(
        TRANSLATIONS_MAP.MAIN_MENU_FLOW.PANEL_TOOLTIP.MOBILE.DESCRIPTION,
      )
    : translate(TRANSLATIONS_MAP.MAIN_MENU_FLOW.PANEL_TOOLTIP.DESCRIPTION);
};

const getMenuItemsCaption = (
  isMainMenuFlow: boolean,
  isMobile: boolean,
  isMainMenu: boolean,
) => {
  if (!isMainMenuFlow) {
    return TRANSLATIONS_MAP.HEADER.MENU_ITEMS;
  }

  if (!isMobile) {
    return isMainMenu
      ? TRANSLATIONS_MAP.MAIN_MENU_FLOW.EDIT.main
      : TRANSLATIONS_MAP.MAIN_MENU_FLOW.EDIT.custom;
  }

  // in mobile we show caption for custom menu only
  return TRANSLATIONS_MAP.MAIN_MENU_FLOW.EDIT.MOBILE.custom;
};

const mapStateToProps = (
  { editorAPI }: StateMapperArgs,
  ownProps: IOwnProps,
) => {
  const { menuId, isMobile, isTiny } = ownProps;
  const isMainMenuFlow = isMainMenuFlowEnabled();

  const menuAPI = createMenuApi(editorAPI);
  const menuData = menuAPI.getMenu(menuId);

  const isMain = isMainMenu(menuData);

  const hasSubSubnestedItems = menuAPI.getMenuDepth(menuId) === 3;
  const warnUnsupportedMenu =
    isMain && isMobile && isTiny && hasSubSubnestedItems;

  const unsupportedMenuWarning = {
    unsupportedMenuWarningTooltip: warnUnsupportedMenu
      ? 'custom_menu_manage_menu_mobile_sub_sub_item_warning_tooltip'
      : null,
    unsupportedMenuWarningActionLabel: warnUnsupportedMenu
      ? 'custom_menu_manage_menu_mobile_sub_sub_item_warning_tooltip_link'
      : null,
  };

  const attachedMenuName = getAttachedMenuName(
    menuData,
    isMainMenuFlow,
    isMobile,
    isMain,
  );

  const menuItemsCaption = getMenuItemsCaption(
    isMainMenuFlow,
    isMobile,
    isMain,
  );

  const tooltipDescription = getTooltipDescription(isMainMenuFlow, isMobile);

  const label = isMainMenuFlow
    ? translate(TRANSLATIONS_MAP.MAIN_MENU_FLOW.HEADER.label)
    : translate(TRANSLATIONS_MAP.HEADER.LABEL);

  return {
    menuItemsCaption,
    attachedMenuName,
    tooltipDescription,
    label,
    secondaryButton: isMainMenuFlow,
    ...unsupportedMenuWarning,
  };
};

export const Header = connect(EDITOR_API, mapStateToProps)(HeaderComponent);

import { translate } from '#packages/i18n';
import { notifications } from '#packages/stateManagement';
import { biLogger } from '#packages/util';
import constants from '#packages/constants';

import { TRANSLATIONS } from '../../../translations';
import { isHeaderOrFooter } from '../../../api';

import type { Scope } from '#packages/sections';
import type { EditorAPI } from '#packages/editorAPI';
import type { CannotRemovePlugin } from 'types/editorPlugins';
import type { CompRef } from 'types/documentServices';
import { clickOnGoToLayoutSettingsForHideHeaderAndFooterFeature } from '@wix/bi-logger-editor/v2';

export const showCannotRemoveSiteSegmentNotification = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  return editorAPI.store.dispatch(
    notifications.actions.showUserActionNotification({
      message: translate(
        TRANSLATIONS.NOTIFICATIONS.CANNOT_REMOVE_SITE_SEGMENT_B.TEXT,
      ),
      type: 'info',
      shouldTranslate: false,
      link: {
        caption: translate(
          TRANSLATIONS.NOTIFICATIONS.CANNOT_REMOVE_SITE_SEGMENT_B.LINK,
        ),
        onNotificationLinkClick: () => {
          editorAPI.panelHelpers.openPagesPanel({
            initialSettingsTabType: constants.PAGE_SETTINGS_TABS.LAYOUT,
          });

          const [params] = editorAPI.bi.getComponentsBIParams([compRef]);

          biLogger.report(
            clickOnGoToLayoutSettingsForHideHeaderAndFooterFeature({
              ...params,
              origin: 'delete notification',
            }),
          );
        },
      },
    }),
  );
};

export const createCannotRemoveSiteSegmentPlugin =
  (scope: Scope): CannotRemovePlugin =>
  (compRef) => {
    if (!isHeaderOrFooter(scope, compRef)) return;
    showCannotRemoveSiteSegmentNotification(scope.editorAPI, compRef);
  };

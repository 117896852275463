// @ts-nocheck
import * as helpIds from '#packages/helpIds';

const getBlockedResizeNotification = (editorAPI) => ({
  'wysiwyg.viewer.components.StateBox': {
    message: 'Notifications_multiStateBox_cannotResize_Text',
    title: 'Notifications_multiStateBox_cannotResize_Text',
    type: 'warning',
    link: {
      caption: 'Notifications_Learn_More_Link',
      onNotificationLinkClick: () =>
        editorAPI.panelManager.openHelpCenter(
          helpIds.NOTIFICATIONS.RESIZE_BLOCKED_STATE_BOX,
        ),
    },
  },
  'wixui.AccordionContainer': {
    message: 'accordion_notification_cannot_resize_container',
    title: 'accordion_notification_cannot_resize_container',
    type: 'warning',
    link: {
      caption: 'Notifications_Learn_More_Link',
      onNotificationLinkClick: () =>
        editorAPI.panelManager.openHelpCenter(
          '1d1a1c32-96c7-4329-a458-34582ee15147',
        ),
    },
  },
});

export default getBlockedResizeNotification;

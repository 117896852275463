import React from 'react';
import { Composites, Divider, TextInput, TextLabel } from '@wix/wix-base-ui';

import { hoc, validate } from '#packages/util';

import { mapDispatchToProps } from './EmailLink.mapper';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

interface TEmailLink {
  id?: string;
  type: string;
  subject?: string;
  recipient?: string;
}

type EmailLinkComponentProps = {
  link: TEmailLink;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TEmailLink): void;
} & ReturnType<typeof mapDispatchToProps>;

interface EmailLinkComponentState {
  prevLink: TEmailLink;
  link: TEmailLink;
  isRecipientValid: boolean;
  isSubjectValid: boolean;
}

const isValidSubject = (subject: AnyFixMe) => {
  return validate.noHTMLTags(subject);
};

const isValidEmail = (recipient: AnyFixMe) => {
  return (
    !!recipient && validate.email(recipient) && validate.notEmpty(recipient)
  );
};

class EmailLinkComponent extends React.Component<
  EmailLinkComponentProps,
  EmailLinkComponentState
> {
  constructor(props: AnyFixMe) {
    super(props);
    const link = props.link || props.createDefaultData('EmailLink');
    this.state = {
      prevLink: link,
      link,
      isRecipientValid: !!link.recipient,
      isSubjectValid: true,
    };
  }

  static getDerivedStateFromProps(
    props: EmailLinkComponentProps,
    state: EmailLinkComponentState,
  ) {
    const nextLink =
      state.prevLink?.id !== props.link?.id ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(
      this.state.isRecipientValid && this.state.isSubjectValid,
    );
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
    }
    if (
      (this.state.isRecipientValid && this.state.isSubjectValid) !==
      (prevState.isRecipientValid && prevState.isSubjectValid)
    ) {
      this.props.onValidationUpdate(
        this.state.isRecipientValid && this.state.isSubjectValid,
      );
    }
  }

  private setRecipient = (recipient: AnyFixMe) => {
    const { link } = this.state;
    this.setState({
      link: {
        ...link,
        recipient,
      },
    });
  };

  private handleRecipientValidation = (isValid: AnyFixMe) => {
    this.setState({
      isRecipientValid: isValid,
    });
  };

  private handleSubjectValidation = (isValid: AnyFixMe) => {
    this.setState({
      isSubjectValid: isValid,
    });
  };

  private setSubject = (subject: AnyFixMe) => {
    const { link } = this.state;
    this.setState({
      link: {
        ...link,
        subject: encodeURIComponent(subject),
      },
    });
  };

  render() {
    return (
      <>
        <Composites.TextInputLabeled>
          <TextLabel
            value="LINK_PANEL_EMAIL_INPUT_LABEL"
            automationId="link-panel-email-address-label"
          />
          <TextInput
            key="emailAddress"
            validator={isValidEmail}
            invalidMessage="Validation_V1_Email"
            placeholder="LINK_PANEL_EMAIL_INPUT_DEFAULT"
            validateInitialValue={false}
            onChange={this.setRecipient}
            value={this.state.link.recipient}
            onValidationStatus={this.handleRecipientValidation}
            focus={true}
          />
        </Composites.TextInputLabeled>

        <Divider />

        <Composites.TextInputLabeled>
          <TextLabel
            value="LINK_PANEL_EMAIL_SUBJUCT"
            automationId="link-panel-email-address-subject-label"
          />
          <TextInput
            key="emailAddressSubject"
            dataHook="link-panel-email-subject"
            validator={isValidSubject}
            invalidMessage="Validation_V21_InvalidInput(<>)"
            placeholder="LINK_PANEL_EMAIL_SUBJECT_DEFAULT"
            validateInitialValue={false}
            onChange={this.setSubject}
            value={
              this.state.link.subject
                ? decodeURIComponent(this.state.link.subject)
                : ''
            }
            onValidationStatus={this.handleSubjectValidation}
          />
        </Composites.TextInputLabeled>
      </>
    );
  }
}

export const EmailLink = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(EmailLinkComponent);

EmailLink.pure = EmailLinkComponent;

import _ from 'lodash';

import experiment from 'experiment';
import * as stateManagement from '#packages/stateManagement';
import { link, overrideUtils, sections } from '#packages/util';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

import {
  getPagesToShow,
  getDefaultSelectedTab,
  convertLink,
  shouldShowPageSection,
  convertLinkPageIdToPageId,
  getAllPagesVariations,
  getNonLinkablePages,
  getMainPagesIds,
} from './utils';
import { LINK_TYPES } from './constants';
import { AnchorDataType } from './components/AnchorLink/AnchorLink.mapper';

import type { EditorAPI } from '#packages/editorAPI';
import type { Anchor } from '#packages/util';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type {
  ThunkAction,
  MapStateToProps,
  MapDispatchToProps,
} from 'types/redux';
import type { PagesData, CompRef } from 'types/documentServices';
import type { LinkPanelComponentOwnProps, Link } from './LinkPanel';
import type { AnchorData } from './components/AnchorLink/AnchorLink.mapper';
import { utils } from '#packages/menu';

const { getPagesWithVerticalsDynamic } = utils;

export interface LinkPanelComponentStateProps {
  link: Link;
  anchors: (AnchorData | Anchor)[];
  onSave: (link: Link, prevLink?: Link) => string;
  onCancel: (link: Link) => void;
  pages: PagesData[];
  pagesForAnchors: PagesData[];
  selectedComponentType: string;
  initialTabToDisplay: LINK_TYPES;
  isDevModeEnabled: boolean;
  siteId: string;
  visibleSectionsData: SectionsData[];
}

export interface LinkPanelComponentDispatchProps {
  closePanel: () => void;
  sendBI: (eventType: BiEventDefinition, biParams: BiEventFields) => void;
  getTargetComp: (linkObj: Link) => { id: string; type: string };
  fetchAppPagesInnerRoutesToStore: () => void;
}

export interface SectionsData {
  for: LINK_TYPES;
  key: string;
  automationId: string;
  label: string;
  condition?: () => boolean;
  experiment?: string;
}

const { getPagesForLinkPanel, getAnchors, getSectionAnchors } = link;

const getLinkSectionsData = (
  editorAPI: EditorAPI,
  visibleSections: { [key in LINK_TYPES]?: boolean },
  overriddenHeaders: AnyFixMe,
  pages: PagesData[],
) => {
  const defaultData: SectionsData[] = [
    {
      for: LINK_TYPES.NoLink,
      key: 'noLinkTabLabel',
      automationId: 'link-panel-no-link',
      label: 'LINK_PANEL_TAB_NONE',
    },
    {
      for: LINK_TYPES.PageLink,
      condition: () => shouldShowPageSection(pages),
      key: 'pageLinkTabLabel',
      automationId: 'link-panel-page-link',
      label: 'LINK_PANEL_TAB_PAGE',
    },
    {
      for: LINK_TYPES.ExternalLink,
      key: 'externalLinkTabLabel',
      automationId: 'link-panel-external-link',
      label: 'LINK_PANEL_TAB_WEB_ADDRESS',
    },
    {
      for: LINK_TYPES.AnchorLink,
      key: 'anchorLinkTabLabel',
      automationId: 'link-panel-anchor-link',
      label: sections.isSectionsEnabled()
        ? 'LINK_PANEL_TAB_SECTION'
        : 'LINK_PANEL_TAB_ANCHOR',
    },
    {
      for: LINK_TYPES.EdgeAnchorLinks,
      key: 'edgeAnchorLinksLabel',
      automationId: 'link-panel-edge-anchor-link',
      label: 'LINK_PANEL_TAB_TOPandBOTTOM',
    },
    {
      for: LINK_TYPES.DocumentLink,
      key: 'docLinkTabLabel',
      automationId: 'link-panel-document-link',
      label: 'LINK_PANEL_TAB_DOCUMENT',
    },
    {
      for: LINK_TYPES.EmailLink,
      key: 'emailLinkTabLabel',
      automationId: 'link-panel-email-link',
      label: 'LINK_PANEL_TAB_EMAIL',
    },
    {
      for: LINK_TYPES.PhoneLink,
      key: 'phoneLinkTabLabel',
      automationId: 'link-panel-phone-link',
      label: 'LINK_PANEL_TAB_PHONE',
    },
    {
      for: LINK_TYPES.WhatsAppLink,
      condition: () => editorAPI.isMobileEditor(),
      key: 'whatsAppLinkTabLabel',
      automationId: 'link-panel-whatsapp-link',
      label: 'mobile_quick_actions_set_up_default_WhatsApp_phone',
    },
    {
      for: LINK_TYPES.AddressLink,
      condition: () => editorAPI.isMobileEditor(),
      key: 'addressLinkTabLabel',
      automationId: 'link-panel-address-link',
      label: 'mobile_quick_actions_set_up_default_Address',
    },
    {
      for: LINK_TYPES.PopupLink,
      key: 'popupLinkTabLabel',
      automationId: 'link-panel-popup-link',
      label: 'LINK_PANEL_TAB_POP-UP',
    },
    {
      for: LINK_TYPES.LoginToWixLink,
      experiment: 'wixstaff',
      key: 'homePageLoginTabLabel',
      automationId: 'link-panel-login-to-wix-link',
      label: 'LINK_PANEL_HOME_PAGE_LOGIN',
    },
  ];

  const overriddenData = overrideUtils.applyOverrides(
    defaultData,
    overriddenHeaders,
  );

  return _(overriddenData)
    .reject((header: SectionsData) => !_.get(visibleSections, header.for, true))
    .reject(
      (header: SectionsData) =>
        header.experiment && !experiment.isOpen(header.experiment),
    )
    .reject((header: SectionsData) => header.condition && !header.condition())
    .value();
};

const getAnchorData = (
  editorAPI: EditorAPI,
  anchorRef: CompRef,
  type: AnchorDataType,
  anchorName: string,
  anchorDataId: string,
): AnchorData => {
  const { id: pageId } = editorAPI.components.getPage(anchorRef);

  return {
    anchorName,
    pageId: `#${pageId}`,
    anchorDataId: `#${anchorDataId}`,
    y:
      type === AnchorDataType.Footer
        ? Infinity
        : editorAPI.components.layout.get_position(anchorRef).y,
    type,
  };
};

const createAnchorData = (
  editorAPI: EditorAPI,
  anchorRef: CompRef,
  type: AnchorDataType,
): AnchorData => {
  const anchorData = editorAPI.components.anchor.get(anchorRef);
  const name = editorAPI.components.getDisplayName(anchorRef);

  if (!anchorData) {
    editorAPI.components.anchor.update(anchorRef, { name });
    const id = editorAPI.components.anchor.get(anchorRef)?.id;

    return getAnchorData(editorAPI, anchorRef, type, name, id);
  }

  return getAnchorData(editorAPI, anchorRef, type, name, anchorData.id);
};

const getAnchorsData = (editorAPI: EditorAPI): AnchorData[] => {
  const anchorData = getAnchors(editorAPI).map((anchor) => ({
    ...anchor,
    type: AnchorDataType.Anchor,
  }));
  const sectionAnchorData = getSectionAnchors(editorAPI).map(
    (sectionAnchor) => ({
      ...sectionAnchor,
      type: AnchorDataType.Section,
    }),
  );

  const anchors = [...anchorData, ...sectionAnchorData].sort(
    (a, b) => a.y - b.y,
  );

  if (!isResponsiveEditor()) {
    const footer = editorAPI.siteSegments.getFooter();
    const footerAnchor = createAnchorData(
      editorAPI,
      footer,
      AnchorDataType.Footer,
    );

    return [...anchors, footerAnchor];
  }

  return anchors;
};

const filterPagesForAnchors = (
  editorAPI: EditorAPI,
  pages: PagesData[],
): PagesData[] => {
  const state = editorAPI.store.getState();

  return pages.filter((pageData: PagesData) => {
    const appId = pageData.appDefinitionId || pageData.managingAppDefId;

    if (appId) {
      const pageSubPages =
        stateManagement.tpaDynamicPages.selectors.getAllSubPagesInnerRoutes(
          state,
          appId,
          pageData.id,
        );
      return pageSubPages.length === 0;
    }

    return true;
  });
};

const getPages = (editorAPI: EditorAPI, showVerticalsDynamicPages: boolean) => {
  if (showVerticalsDynamicPages) {
    const pages = getPagesWithVerticalsDynamic(editorAPI);
    const mainPagesIds = getMainPagesIds(pages);

    return pages.filter((page) => !mainPagesIds.includes(page.id));
  }

  return getPagesForLinkPanel(editorAPI);
};

export const mapStateToProps: MapStateToProps<
  LinkPanelComponentStateProps,
  LinkPanelComponentOwnProps
> = ({ editorAPI }, props) => {
  const {
    hidePageLinks,
    visibleSections,
    callback,
    onCancelCallback,
    defaultLinkType,
    showVerticalsDynamicPages,
  } = props;
  const overridenHeaders = stateManagement.linkPanel.selectors.getOverridden(
    editorAPI.store.getState(),
  );
  const dynamicPages = editorAPI.dsRead.routers.pages.getDynamicPagesList();
  const anchors = sections.isSectionsEnabled()
    ? getAnchorsData(editorAPI)
    : getAnchors(editorAPI);
  const pages = props.pages ?? getPages(editorAPI, showVerticalsDynamicPages);

  const pagesForLinkPanel = getPagesForLinkPanel(editorAPI);
  const pagesForAnchors =
    props.pagesForAnchors ||
    filterPagesForAnchors(editorAPI, pagesForLinkPanel);

  const popups = editorAPI.pages.popupPages.getDataListOrdered();

  const convertedLink = convertLink(
    editorAPI,
    props.link,
    anchors,
    pagesForAnchors,
    visibleSections,
  );

  const link =
    convertedLink ||
    (defaultLinkType
      ? editorAPI.dsRead.data.createItem(defaultLinkType)
      : null);

  const allPageVariations = getAllPagesVariations(editorAPI, pages);
  const nonLinkablePages = getNonLinkablePages(editorAPI, pages);

  const pagesToShow = getPagesToShow(
    pages,
    dynamicPages,
    hidePageLinks,
    allPageVariations,
    nonLinkablePages,
  );

  const visibleSectionsData = getLinkSectionsData(
    editorAPI,
    visibleSections,
    overridenHeaders,
    pagesToShow,
  );

  return {
    link,
    onSave: callback,
    onCancel: onCancelCallback,
    pages: pagesToShow,
    pagesForAnchors,
    visibleSectionsData,
    selectedComponentType: editorAPI.selection.getSelectedComponentType(),
    initialTabToDisplay: (props.initialTabToDisplay ||
      getDefaultSelectedTab(link, popups, visibleSectionsData)) as LINK_TYPES,
    isDevModeEnabled: editorAPI.developerMode.isEnabled(),
    siteId: editorAPI.dsRead.generalInfo.getSiteId(),
    anchors,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (_d, _s, { editorAPI }) =>
  editorAPI;

const getTargetCompRef = (editorAPI: EditorAPI, linkObj: Link): CompRef => {
  if (link.linkTypeValidators.isSectionLink(linkObj)) {
    return editorAPI.sections
      .getPageSections(
        editorAPI.components.get.byId(
          convertLinkPageIdToPageId(linkObj.pageId),
        ),
      )
      .find(
        (section) =>
          editorAPI.components.anchor.get(section)?.id ===
          convertLinkPageIdToPageId(linkObj.anchorDataId),
      );
  }

  if (
    link.linkTypeValidators.isAnchorLink(linkObj) ||
    (link.linkTypeValidators.isDynamicPageLink(linkObj) && linkObj.anchorDataId)
  ) {
    const data = editorAPI.data.getById(
      convertLinkPageIdToPageId(linkObj.anchorDataId),
    );
    return editorAPI.components.get.byId(data?.compId);
  }

  if (
    link.linkTypeValidators.isPageLink(linkObj) ||
    link.linkTypeValidators.isDynamicPageLink(linkObj)
  ) {
    return editorAPI.components.get.byId(
      convertLinkPageIdToPageId(linkObj.pageId),
    );
  }

  return null;
};

export const mapDispatchToProps: MapDispatchToProps<
  LinkPanelComponentDispatchProps,
  LinkPanelComponentOwnProps
> = (dispatch, ownProps) => ({
  sendBI(eventType, biParams) {
    dispatch(stateManagement.bi.actions.event(eventType, biParams));
  },
  closePanel() {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    editorAPI.panelManager.closePanelByName(ownProps.panelName);
  },
  getTargetComp(linkObj) {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const compRef = getTargetCompRef(editorAPI, linkObj);
    return { id: compRef?.id, type: editorAPI.components.getType(compRef) };
  },

  fetchAppPagesInnerRoutesToStore() {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const appPages = editorAPI.pages
      .getPagesData()
      .filter((page) => page.appDefinitionId ?? page.managingAppDefId);

    appPages.forEach((page) => {
      dispatch(
        stateManagement.tpaDynamicPages.actions.fetchPageInnerRoutesInState(
          page.id,
        ),
      );
    });
  },
});

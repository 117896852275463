import _ from 'lodash';
import experiment from 'experiment';

import { EditorAPIKey } from '#packages/apis';

import * as core from '#packages/core';
import {
  isNewPagesPanelEnabled,
  link,
  isCustomMenusEnabled,
  isMainMenuFlowEnabled,
  googleTranslate,
  sections,
  isCustomOrAdvancedMenuEnabled,
  isAdvancedMenuOpen,
} from '#packages/util';
import { translate } from '#packages/i18n';
import type { Shell } from '#packages/apilib';

import type {
  PagesData,
  MenuItem,
  CompRef,
  Link,
  SerializedPageStructure,
} from 'types/documentServices';
import * as coreBi from '#packages/coreBi';
import { createMenuApi as _createMenuApi } from './API/menuAPI';
import { PAGES_MENU_ID } from './constants';
import type { LinkPanelComponentOwnProps } from '#packages/panels';

const {
  linkTypeValidators: { isPageLink, isSectionLink },
} = link;

interface IPagesMenuItemType {
  raw: string | undefined;
  isDropdown: boolean;
  isLink: boolean;
  isPage: boolean;
  isPopupLink: boolean;
  isSectionLink?: boolean;
}

export interface IPageMenuItem extends Omit<MenuItem, 'type'> {
  type: IPagesMenuItemType;
  parent: IPageMenuItem;
  pageData: Pick<
    PagesData,
    | 'id'
    | 'tpaApplicationId'
    | 'managingAppDefId'
    | 'tpaPageId'
    | 'pageSecurity'
    | 'title'
  > | null;
  position: number;
  appDefinitionId: string | null;
  isCustomErrorPage: boolean;
  isHomePage: boolean;
  isOldBlog: boolean;
}

export function createMenuApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const { menuLogic } = core.utils;
  const IS_LINK_REGEX = /Link$/;
  const NEW_PAGE_TITLE_ID = translate('Pages_Menu_Add_New_Page');
  const NEW_LINK_TITLE_ID = translate('Pages_Menu_Add_New_Link');

  function sanitizeHash(str: AnyFixMe) {
    return str.replace('#', '');
  }

  function getMenuItemTypeData(menuItem: AnyFixMe): IPagesMenuItemType {
    const menuItemType = menuItem.link?.type;
    const linkPageId = menuItem.link?.pageId;
    const isPage = menuItemType === 'PageLink';
    const isPopupLink =
      linkPageId &&
      editorAPI.dsRead.pages.popupPages.isPopup(linkPageId.replace('#', ''));

    return {
      raw: menuItemType,
      isDropdown: !menuItemType,
      isLink: (!isPage && IS_LINK_REGEX.test(menuItemType)) || isPopupLink,
      isPage: isPage && !isPopupLink,
      isPopupLink,
    };
  }

  function createMenuItemWithPageData(
    menuItem: AnyFixMe,
    pageData: AnyFixMe,
    parent: AnyFixMe,
    position: AnyFixMe,
  ): Omit<IPageMenuItem, 'items'> {
    const tpaApplicationId = pageData?.tpaApplicationId;
    // TODO: once dev center impl page type icon url use it instead of 'appDefinitionId'
    const appDefinitionId = tpaApplicationId
      ? editorAPI.dsRead.tpa.app.getData(tpaApplicationId).appDefinitionId
      : null;
    const isCustomErrorPage =
      pageData && editorAPI.pages.isCustomErrorPage(pageData.id, pageData);
    const type = getMenuItemTypeData(menuItem);
    return {
      id: menuItem.id,
      type,
      label: menuItem.label,
      parent,
      isVisible: menuItem.isVisible,
      isVisibleMobile: menuItem.isVisibleMobile,
      pageData: pageData
        ? _.pick(pageData, [
            'id',
            'tpaApplicationId',
            'managingAppDefId',
            'tpaPageId',
            'pageSecurity',
            'title',
          ])
        : null,
      link: menuItem.link,
      position,
      appDefinitionId,
      isCustomErrorPage,
      isHomePage: editorAPI.pages.isHomePage(pageData?.id),
      isOldBlog: pageData?.appInnerID
        ? core.utils.blogTypeUtils.isBlog(editorAPI, pageData)
        : false,
    };
  }

  function extendMenuItemsTreeWithData(
    menuItemsTree: AnyFixMe,
    pagesDataMap: AnyFixMe,
    filterFunction: AnyFixMe,
    parent?: AnyFixMe,
  ) {
    const res = {
      items: [] as AnyFixMe,
      pagesCount: 0,
      multiplePagesInMenu: undefined as AnyFixMe,
    };

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(menuItemsTree, function (menuItem, index) {
      const type = getMenuItemTypeData(menuItem);
      const pageData = type.isPage
        ? pagesDataMap[sanitizeHash(menuItem.link.pageId)]
        : null;

      if (!filterFunction || filterFunction(menuItem, pageData)) {
        const newItem = createMenuItemWithPageData(
          menuItem,
          pageData,
          parent,
          index,
        );

        if (menuItem.items.length > 0) {
          const childrenTree = extendMenuItemsTreeWithData(
            menuItem.items,
            pagesDataMap,
            filterFunction,
            newItem,
          );
          //@ts-expect-error
          newItem.items = childrenTree.items;
          res.pagesCount += childrenTree.pagesCount;
        } else {
          //@ts-expect-error
          newItem.items = [];
        }

        if (type.isPage) {
          res.pagesCount++;
        }

        res.items.push(newItem);
      }
    });

    return res;
  }

  function shouldUseOriginalLanguage(): boolean {
    const currentLanguage = editorAPI.dsRead.language.current.get();
    const originalLanguage =
      editorAPI.dsRead.language.original.get()?.languageCode;
    return !(
      currentLanguage &&
      originalLanguage &&
      currentLanguage !== originalLanguage
    );
  }

  function findParentOfItem(menuData: AnyFixMe, predicate: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(menuData.items, predicate)
      ? menuData
      : // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        _.find(menuData.items, function (item) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/find
          return _.find(item.items, predicate);
        });
  }

  function findItem(menuData: AnyFixMe, predicate: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(findParentOfItem(menuData, predicate).items, predicate);
  }

  function moveMenuItem(
    rawMenuData: AnyFixMe,
    itemId: AnyFixMe,
    newParentId: AnyFixMe,
    newIndex: AnyFixMe,
    takeSnapshot: AnyFixMe,
    callback?: AnyFixMe,
  ) {
    const currentParent = findParentOfItem(rawMenuData, { id: itemId });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const currentIndex = _.findIndex(currentParent.items, { id: itemId });
    const currentItem = currentParent.items[currentIndex];

    currentParent.items.splice(currentIndex, 1);

    let newParent = newParentId
      ? findItem(rawMenuData, { id: newParentId })
      : rawMenuData;
    newParent = newParent || rawMenuData;

    newParent.items = newParent.items || [];
    newParent.items.splice(newIndex, 0, currentItem);

    editorAPI.dsActions.waitForChangesApplied(() => {
      editorAPI.dsActions.menu.update(rawMenuData.id, rawMenuData);
      if (takeSnapshot) {
        editorAPI.history.add('moving a page');
      }

      if (_.isFunction(callback)) {
        editorAPI.dsActions.waitForChangesApplied(callback);
      }
      if (rawMenuData.appId) {
        editorAPI.dsActions.platform.notifyApplication(rawMenuData.appId, {
          eventType: 'appMenuReorder',
          eventPayload: {
            menuId: rawMenuData.id,
            menuItemId: itemId,
            parentId: newParentId,
            index: newIndex,
          },
        });
      }
    });
  }

  function duplicatePageInternal(pageId: string) {
    const pageRef = editorAPI.components.get.byId(pageId);
    const newPageRef = editorAPI.pages.duplicate(pageId);
    const pageData = editorAPI.components.data.get(pageRef);
    const newTitle = core.utils.pageTitleUtils.generateDuplicatedTitle(
      pageData.title,
    );
    const pageUriSEO = (
      editorAPI.dsRead.generalInfo.urlFormat.isSlash()
        ? editorAPI.pages.data.pageUriSEO
        : core.utils.pageTitleUtils
    ).convertPageNameToUrl(newTitle);

    editorAPI.pages.data.update(
      newPageRef.id,
      {
        title: newTitle,
        pageUriSEO,
      },
      true,
    );

    editorAPI.pages.settings.duplicateGroupsPermissions(pageId, newPageRef.id);

    return newPageRef.id;
  }

  function getMenuItemByPageId(pageId: AnyFixMe, menuItems: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(menuItems, function (menuItem) {
      return menuItem.link && menuItem.link.pageId === `#${pageId}`;
    });
  }

  interface MenuItemPositionInfo {
    itemIndex: null | number;
    parentId: string;
  }

  function getMenuItemPositionInfo(
    menuItems: AnyFixMe[],
    menuItemId: string,
    parentId: string,
  ): MenuItemPositionInfo {
    const positionInfo: MenuItemPositionInfo = {
      itemIndex: null,
      parentId,
    };

    for (let i = 0; i < menuItems.length; i++) {
      const item = menuItems[i];

      if (item.id === menuItemId) {
        positionInfo.itemIndex = i;

        return positionInfo;
      }

      if (item.items?.length) {
        const subItemPositionInfo = getMenuItemPositionInfo(
          item.items,
          menuItemId,
          item.id,
        );

        if (subItemPositionInfo.itemIndex !== null) {
          return subItemPositionInfo;
        }
      }
    }

    return positionInfo;
  }

  function moveMenuItemAfterTarget(
    newMenuData: AnyFixMe,
    menuItemId: AnyFixMe,
    targetMenuItemId: AnyFixMe,
    callback: AnyFixMe,
  ) {
    const targetMenuItemPositionInfo = getMenuItemPositionInfo(
      newMenuData.items,
      targetMenuItemId,
      null,
    );
    moveMenuItem(
      newMenuData,
      menuItemId,
      targetMenuItemPositionInfo.parentId,
      targetMenuItemPositionInfo.itemIndex + 1,
      false,
      callback,
    );
  }

  function duplicateMenuItemOfTypePage(
    menuId: AnyFixMe,
    pageId: AnyFixMe,
    precedingMenuItemId: AnyFixMe,
    callback: AnyFixMe,
  ) {
    const newPageId = duplicatePageInternal(pageId);
    editorAPI.dsActions.waitForChangesApplied(function () {
      const useOriginalLanguage = shouldUseOriginalLanguage();
      const newMenu = editorAPI.menu.getById(menuId, useOriginalLanguage);
      const newMenuItem = getMenuItemByPageId(newPageId, newMenu.items);
      moveMenuItemAfterTarget(
        newMenu,
        newMenuItem.id,
        precedingMenuItemId,
        function () {
          editorAPI.pages.navigateTo(newPageId);
          editorAPI.history.add('duplicating a page', { currentPage: pageId });
          callback(newMenuItem.id, newPageId);
        },
      );
    });
  }

  function duplicateFolder(
    menuId: AnyFixMe,
    menuItem: AnyFixMe,
    callback: AnyFixMe,
  ) {
    const newTitle = core.utils.pageTitleUtils.generateDuplicatedTitle(
      menuItem.label,
    );

    const newMenuItemId = editorAPI.dsActions.menu.addItem(menuId, {
      link: menuItem.link,
      label: newTitle,
      isVisible: menuItem.isVisible,
      isVisibleMobile: menuItem.isVisibleMobile,
    });

    editorAPI.dsActions.waitForChangesApplied(function () {
      const useOriginalLanguage = shouldUseOriginalLanguage();
      const newMenu = editorAPI.menu.getById(menuId, useOriginalLanguage);
      moveMenuItemAfterTarget(newMenu, newMenuItemId, menuItem.id, function () {
        editorAPI.history.add('duplicating a dropdown');
        callback(newMenuItemId);
      });
    });
  }

  function getMenus() {
    const pagesData = editorAPI.pages.getPagesData();
    const allMenusData = editorAPI.dsRead.menu.getAll();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _(allMenusData)
      .filter(['id', 'CUSTOM_MAIN_MENU'])
      .map((menuData) => ({
        id: menuData.id,
        menuItems: getExtendedMenuItemsTree(menuData, pagesData).items,
      }))
      .value();
  }

  function duplicateLink(
    menuId: AnyFixMe,
    menuItem: AnyFixMe,
    callback: AnyFixMe,
  ) {
    const newTitle = core.utils.pageTitleUtils.generateDuplicatedTitle(
      menuItem.label,
    );

    const newMenuItemId = editorAPI.dsActions.menu.addItem(menuId, {
      link: menuItem.link,
      label: newTitle,
      isVisible: menuItem.isVisible,
      isVisibleMobile: menuItem.isVisibleMobile,
    });

    editorAPI.dsActions.waitForChangesApplied(function () {
      const useOriginalLanguage = shouldUseOriginalLanguage();
      const newMenu = editorAPI.menu.getById(menuId, useOriginalLanguage);
      moveMenuItemAfterTarget(newMenu, newMenuItemId, menuItem.id, function () {
        editorAPI.history.add('duplicating a link');
        callback(newMenuItemId);
      });
    });
  }

  function canDuplicate(menuItem: AnyFixMe) {
    if (menuItem.type.isPage && menuItem.pageData) {
      return editorAPI.pages.isDuplicable(menuItem.pageData.id);
    }

    if (menuItem.type.isDropdown) {
      return !isNewPagesPanelEnabled();
    }

    return menuItem.type.isLink;
  }

  function duplicateMenuItem(
    menuId: AnyFixMe,
    menuItem: AnyFixMe,
    callback: AnyFixMe,
  ) {
    if (menuItem.type.isPage) {
      duplicateMenuItemOfTypePage(
        menuId,
        menuItem.pageData.id,
        menuItem.id,
        callback,
      );
    } else if (menuItem.type.isDropdown) {
      duplicateFolder(menuId, menuItem, callback);
    } else if (menuItem.type.isLink) {
      duplicateLink(menuId, menuItem, callback);
    } else {
      console.error('not supported menu type');
    }
  }

  function removeMenuItem(
    menuId: AnyFixMe,
    menuItem: AnyFixMe,
    multiPageMenu: AnyFixMe,
  ) {
    if (!menuItem.pageData) {
      editorAPI.pages.navigateTo(editorAPI.homePage.get(), function () {
        editorAPI.dsActions.menu.removeItem(menuId, menuItem.id, true);
        editorAPI.history.add('removing menu item');
        if (menuItem.type.isPage) {
          editorAPI.bi.event(
            coreBi.events.pages.pagesPanel.static_page_deleted,
            {
              page_id: menuItem.pageData.id,
            },
          );
        }
      });
    } else if (!multiPageMenu) {
      editorAPI.panelManager.openPanel(
        'panels.messagePanels.deleteLastPageMessage',
        {
          isLastPage: true,
        },
        true,
      );
    } else {
      const pageId = editorAPI.dsRead.pages.getFocusedPageId();
      //TODO: add code here when hook of removing menu items linked to page will no longer work or when it will be possible to remove pages from other menus
      editorAPI.pages.remove(menuItem.pageData.id, function () {
        editorAPI.history.add('removing a page', {
          currentPage: pageId,
        });
        if (menuItem.type.isPage) {
          editorAPI.bi.event(
            coreBi.events.pages.pagesPanel.static_page_deleted,
            {
              page_id: menuItem.pageData.id,
            },
          );
        }
      });
    }
  }

  function updateLink(
    menuId: AnyFixMe,
    menuItem: AnyFixMe,
    linkData: AnyFixMe,
  ) {
    const cb = function (newLinkData: AnyFixMe) {
      editorAPI.dsActions.menu.updateItem(menuId, menuItem.id, {
        link: newLinkData,
      });
      editorAPI.history.add('updating menu link');
    };

    openLinkPanel(cb, linkData);
  }

  function performPageUpdate(
    pageId: AnyFixMe,
    data: AnyFixMe,
    historyMessage: AnyFixMe,
  ) {
    editorAPI.pages.data.update(pageId, data);

    // TODO: Add tests
    if (isCustomOrAdvancedMenuEnabled() && data.title) {
      const menuAPI = _createMenuApi(editorAPI);
      menuAPI.renamePageOnAllMenus(pageId, data.title);
    }

    editorAPI.history.debouncedAdd(historyMessage);
  }

  function shouldOverridePageUriSEO(title: AnyFixMe) {
    //TODO: check what does this means especially last part
    const shouldOverride =
      !editorAPI.dsRead.generalInfo.urlFormat.isSlash() ||
      !editorAPI.dsRead.generalInfo.isSitePublished() ||
      title === translate('Pages_Menu_Add_New_Page');
    const { language } = editorAPI.dsRead;

    if (language.multilingual.isEnabled()) {
      return !language.isCurrentLanguageSecondary() && shouldOverride;
    }

    return shouldOverride;
  }

  function renamePage(pageId: string, oldTitle: string, newTitle: string) {
    const data: Partial<PagesData> = { title: newTitle };

    if (!newTitle || oldTitle === newTitle) {
      return;
    }

    if (shouldOverridePageUriSEO(oldTitle)) {
      const converter = editorAPI.dsRead.generalInfo.urlFormat.isSlash()
        ? editorAPI.pages.data.pageUriSEO
        : core.utils.pageTitleUtils;
      let convertedPageUriSEO = converter.convertPageNameToUrl(newTitle);
      if (convertedPageUriSEO) {
        data.pageUriSEO = convertedPageUriSEO;
        performPageUpdate(pageId, data, 'Rename Page');
      } else {
        googleTranslate(newTitle, function (translation: AnyFixMe) {
          convertedPageUriSEO = converter.convertPageNameToUrl(translation);
          if (convertedPageUriSEO) {
            data.pageUriSEO = convertedPageUriSEO;
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/assign
            data.translationData = _.assign({} || data.translationData, {
              uriSEOTranslated: true,
            });
          }
          performPageUpdate(pageId, data, 'Rename Page');
        });
      }
    } else {
      performPageUpdate(pageId, data, 'Rename Page');
    }
  }

  function getExtendedMenuItemsTree(
    menuData: AnyFixMe,
    pagesDataList?: AnyFixMe,
  ) {
    pagesDataList = pagesDataList || editorAPI.dsRead.pages.getPagesData();
    const pagesDataMap = _.keyBy(pagesDataList, 'id');
    const dynamicPagesIdsMap = _buildDynamicPagesIdsMap();

    const shouldBeOnMenu = function (menuItem: AnyFixMe, pageData: AnyFixMe) {
      if (!pageData) {
        return true;
      }
      return !menuLogic.isSpecialPage(editorAPI, pageData, dynamicPagesIdsMap);
    };

    const extendedMenuItemsTreeWithData = extendMenuItemsTreeWithData(
      menuData.items,
      pagesDataMap,
      shouldBeOnMenu,
    );
    extendedMenuItemsTreeWithData.multiplePagesInMenu =
      extendedMenuItemsTreeWithData.pagesCount > 1;
    return extendedMenuItemsTreeWithData;
  }

  /**
   * @deprecated
   * @use getItemByPageId from menu package
   */
  const getSelectedMenuItemByPageId = (
    menus: AnyFixMe,
    selectedPageId: AnyFixMe,
  ): AnyFixMe => {
    let selectedMenuItem = null;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    const isSelectedMenuItem = _.some(menus, ({ id, menuItems }) => {
      const flatMenuItems = _.flatMap(menuItems, (menuItem) => [
        menuItem,
        ...menuItem.items,
      ]);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const foundMenuItem = _.find(flatMenuItems, [
        'pageData.id',
        selectedPageId,
      ]);
      selectedMenuItem = { id: foundMenuItem?.id, menuId: id };
      return foundMenuItem;
    });

    return isSelectedMenuItem ? selectedMenuItem : {};
  };

  function findMenuItemById(menuItems: AnyFixMe, menuItemId: AnyFixMe) {
    const flatMenuItems = _.flatMap(menuItems, (menuItem) => [
      menuItem,
      ...menuItem.items,
    ]);
    return flatMenuItems.find((menuItem) => menuItem.id === menuItemId);
  }

  function addPage(
    menuId: string,
    selectedMenuItemId: string,
    doneCB: (newMenuItemId?: string, newPageId?: string) => void,
    pageTitle?: string,
    pageStructure?: SerializedPageStructure,
    addPageFn?: (
      title: string,
      pageStructure: SerializedPageStructure,
    ) => CompRef,
    isAdvancedMenuFlow: boolean = false,
  ) {
    addPageFn = addPageFn || editorAPI.pages.add;
    const newPage = addPageFn(pageTitle || NEW_PAGE_TITLE_ID, pageStructure);
    if (newPage) {
      editorAPI.dsActions.waitForChangesApplied(function () {
        const useOriginalLanguage = shouldUseOriginalLanguage();
        const newMenu = editorAPI.menu.getById(menuId, useOriginalLanguage);
        const newMenuItems = newMenu.items;
        const newMenuItem = getMenuItemByPageId(newPage.id, newMenuItems);
        // TODO: when it will be possible to add page to other menu other then main this code should be added here
        // (cause there is a hook that menu item is added to main menu straight after page is added)
        // if that hook will be active add this code
        // if(menuId !== 'CUSTOM_MAIN_MENU'){
        //  //remove menu item from main menu
        //  //add menu item to desired menu
        // }
        let target = selectedMenuItemId;

        // Add page to the end of the list if experiment is on
        if (isNewPagesPanelEnabled() || isAdvancedMenuFlow) {
          const pagesItems = newMenuItems.filter(({ link }) =>
            isPageLink(link),
          );
          const lastPageItem =
            pagesItems.length > 0 ? pagesItems[pagesItems.length - 1] : null;
          target = lastPageItem.id;
        }

        moveMenuItemAfterTarget(newMenu, newMenuItem.id, target, function () {
          editorAPI.history.add('adding new page', {
            actionType: 'addPage',
            selectedMenuItemId: target,
            nextPage: newPage.id,
          });

          if (isAdvancedMenuFlow) {
            editorAPI.menu.updateItem('CUSTOM_MAIN_MENU', newMenuItem.id, {
              isVisible: false,
              isVisibleMobile: false,
            });
            return doneCB(newMenuItem.id, newPage.id);
          }

          editorAPI.pages.navigateTo(newPage.id, () =>
            doneCB(newMenuItem.id, newPage.id),
          );
        });
      });
    }
    return newPage;
  }

  function openLinkPanel(
    callback: AnyFixMe,
    link?: AnyFixMe,
    options?: LinkPanelComponentOwnProps,
  ) {
    editorAPI.openLinkPanel({
      visibleSections: {
        NoLink: false,
      },
      hidePageLinks: false,
      showVerticalsDynamicPages: true,
      link,
      origin: 'pagesPanel',
      showAllPopups: true,
      pageTabTitle: 'LINK_PANEL_TAB_ANCHOR',
      pageTabPageDropDownTitle: 'PLATFORM_LINK_MENU_PAGE_PAGE_OPTION',
      anchorTabPageDropDownTitle: 'LINK_PANEL_PAGE_DROP_DOWN_PAGELINK_LABEL',
      title: 'What do you want to link to?', //TODO: should be a key!!!!
      callback: _.once(callback),
      ...options,
    });
  }

  function getLinkLabel(newLinkData: Link) {
    if (experiment.isOpen('se_menuLinksLabels')) {
      const menuAPI = _createMenuApi(editorAPI);
      return menuAPI.getNewLinkLabel(newLinkData);
    }

    const isSectionLabel =
      sections.isSectionsEnabled() && isSectionLink(newLinkData);

    if (isSectionLabel) {
      return newLinkData.anchorName;
    }

    return null;
  }

  function addLink(
    menuId: AnyFixMe,
    selectedMenuItemId: AnyFixMe,
    doneCB: AnyFixMe,
    options?: AnyFixMe,
  ) {
    const cb = async function (newLinkData: Link) {
      const label = await getLinkLabel(newLinkData);
      const newMenuItemId = editorAPI.dsActions.menu.addItem(menuId, {
        link: newLinkData,
        label: label || NEW_LINK_TITLE_ID,
      });

      editorAPI.dsActions.waitForChangesApplied(function () {
        const useOriginalLanguage = shouldUseOriginalLanguage();
        const newMenu = editorAPI.menu.getById(menuId, useOriginalLanguage);
        moveMenuItemAfterTarget(
          newMenu,
          newMenuItemId,
          selectedMenuItemId,
          function () {
            doneCB(newMenuItemId);
            editorAPI.history.add('adding new link');
          },
        );
      });
    };

    openLinkPanel(cb, null, options);
  }

  function addFolder(
    menuId: AnyFixMe,
    selectedMenuItemId: AnyFixMe,
    doneCB: (createdMenuItemId: string) => void,
  ) {
    const NEW_FOLDER_TITLE_ID = isMainMenuFlowEnabled()
      ? translate('PagesPanel_Actions_Folder_New_Default_Text')
      : translate(
          experiment.isOpen('se_pagesPanelDropdownPlaceholder')
            ? 'NewPages_Panel_Menu_Dropdown_Default_Label'
            : 'Pages_Menu_Add_New_Category',
        );

    const newMenuItemId = editorAPI.dsActions.menu.addItem(menuId, {
      label: NEW_FOLDER_TITLE_ID,
      link: null,
    });

    editorAPI.dsActions.waitForChangesApplied(function () {
      if (
        isMainMenuFlowEnabled() ||
        (isAdvancedMenuOpen() && menuId === PAGES_MENU_ID)
      ) {
        doneCB(newMenuItemId);
      } else {
        const useOriginalLanguage = shouldUseOriginalLanguage();
        const newMenu = editorAPI.menu.getById(menuId, useOriginalLanguage);

        moveMenuItemAfterTarget(
          newMenu,
          newMenuItemId,
          selectedMenuItemId,
          function () {
            doneCB(newMenuItemId);
            editorAPI.history.add('adding new page header');
          },
        );
      }
    });
  }

  function createThinMenuItem(
    menuItem: AnyFixMe,
    pageData: AnyFixMe,
    type?: AnyFixMe,
  ) {
    return {
      id: menuItem.id,
      type: type || getMenuItemTypeData(menuItem),
      label: menuItem.label,
      pageData: pageData ? { id: pageData.id } : null,
    };
  }

  function extendWithPageDataRecursively(
    menuItemsTree: AnyFixMe,
    pagesDataMap: AnyFixMe,
    filterFunction: AnyFixMe,
    menuItemProcessedCallback: AnyFixMe,
  ) {
    const res: AnyFixMe = [];

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(menuItemsTree, function (menuItem) {
      let newItem: AnyFixMe;
      const type = getMenuItemTypeData(menuItem);
      const pageData = type.isPage
        ? pagesDataMap[sanitizeHash(menuItem.link.pageId)]
        : null;

      if (!filterFunction || filterFunction(menuItem, pageData)) {
        newItem = createThinMenuItem(menuItem, pageData, type);
        if (menuItem.items.length > 0) {
          newItem.items = extendWithPageDataRecursively(
            menuItem.items,
            pagesDataMap,
            filterFunction,
            menuItemProcessedCallback,
          );
        } else {
          newItem.items = [];
        }

        res.push(newItem);
        if (menuItemProcessedCallback) {
          menuItemProcessedCallback(newItem, pageData);
        }
      }
    });
    return res;
  }

  function extendMenuItemsTreeWithPageData(
    rawMenuTree: AnyFixMe,
    pagesDataList: AnyFixMe,
    filterFunction: AnyFixMe,
    menuItemProcessedCallback: AnyFixMe,
  ) {
    const pagesDataMap = _.keyBy(pagesDataList, 'id');
    return extendWithPageDataRecursively(
      rawMenuTree,
      pagesDataMap,
      filterFunction,
      menuItemProcessedCallback,
    );
  }

  function convertPagesDataListToThinMenuItemsList(
    pagesDataList: AnyFixMe,
    menuTree: AnyFixMe,
  ) {
    function traceTree(items: AnyFixMe) {
      let pageData, type, pageDataIndex;
      items.forEach((item: AnyFixMe) => {
        type = getMenuItemTypeData(item);
        if (type.isPage) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
          pageDataIndex = _.findIndex(pagesDataList, {
            id: sanitizeHash(item.link.pageId),
          });
          if (pageDataIndex > -1) {
            pageData = pagesDataList[pageDataIndex];
            res[pageDataIndex] = createThinMenuItem(item, pageData);
          }
        }

        if (item.items.length > 0) {
          traceTree(item.items);
        }
      });
    }

    const res: AnyFixMe = [];
    traceTree(menuTree);
    return _.compact(res);
  }

  function _buildDynamicPagesIdsMap() {
    const dynamicPagesIdsMap: Record<string, boolean> = {};
    const routers = editorAPI.dsRead.routers.get.all();
    _.forOwn(routers, (router) => {
      _.forOwn(router.pages, (pageId) => {
        dynamicPagesIdsMap[pageId as any as string] = true;
      });
    });
    return dynamicPagesIdsMap;
  }

  const convertPageToMockedMenuItem = (page: AnyFixMe) => ({
    id: `mockedMenuId-pageid-${page.id}`,
    label: page.title,
    pageData: { id: page.id },

    type: {
      isDropdown: false,
      isLink: false,
      isPage: true,
      isPopupLink: false,
      raw: 'PageLink',
    },
  });

  /*
     with special pages made as subpages of section pages.
     Example: tree with 2 roots [Blog, SinglePost] becomes tree with Blog as a root and SinglePost as
     a subpage [Blog[SinglePost]]
     Only pages are includes, Dropdowns and Links are excluded.
     */
  function getMenuItemsForIdeTree() {
    const menuTree = editorAPI.dsRead.menu.getById('CUSTOM_MAIN_MENU').items;
    const pagesDataList = editorAPI.dsRead.pages.getPagesData();
    const sectionPagesIdsMap = _.keyBy(
      menuLogic.getSectionPagesIds(editorAPI, pagesDataList),
    );
    const sectionPagesMenuItems: AnyFixMe = [];
    const dynamicPagesIdsMap = _buildDynamicPagesIdsMap();

    const filterFn = function isRegularPageOrNonPageWithChildren(
      menuItem: AnyFixMe,
      pageData: AnyFixMe,
    ) {
      if (!pageData && menuItem.items.length === 0) {
        return false; // not-pages are excluded. exception: when a not-page has children
      }
      if (
        pageData &&
        menuLogic.isSpecialPage(editorAPI, pageData, dynamicPagesIdsMap)
      ) {
        return false; // special pages are excluded
      }
      return true;
    };

    const rememberSectionPages = function (menuItem: AnyFixMe) {
      if (menuItem.pageData?.id && sectionPagesIdsMap[menuItem.pageData.id]) {
        sectionPagesMenuItems.push(menuItem); // remember section pages, later special pages will be added into them
      }
    };

    // 1. Build tree with regular pages only
    const tree = extendMenuItemsTreeWithPageData(
      menuTree,
      pagesDataList,
      filterFn,
      rememberSectionPages,
    );

    // 2. Add missing special pages
    sectionPagesMenuItems.forEach((menuItem: AnyFixMe) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const pageData = _.find(pagesDataList, { id: menuItem.pageData.id });
      const specialPages = _.reject(
        menuLogic.getSpecialPagesListBySectionPageOrBySibling(
          editorAPI,
          pageData,
          pagesDataList,
        ),
        _.partial(
          core.utils.tpaTypeUtils.isPageNotVisibleInPagesMenu,
          editorAPI.dsRead,
        ),
      );

      const convertedPages = convertPagesDataListToThinMenuItemsList(
        specialPages,
        menuTree,
      );

      //After migration related to CustomMenus lots of pages is now don't have related menu items, so we need to mock them so Corvid would work as expected.
      const specialPagesMenuItems =
        convertedPages.length === specialPages.length
          ? convertedPages
          : specialPages.map(convertPageToMockedMenuItem);

      menuItem.items.push(...specialPagesMenuItems);
    });

    return tree;
  }

  return {
    _buildDynamicPagesIdsMap,
    getMenuItemTypeData,
    getExtendedMenuItemsTree,
    shouldUseOriginalLanguage,
    duplicateMenuItem,
    moveMenuItem,
    getMenus,
    canDuplicate,
    removeMenuItem,
    updateLink,
    renamePage,
    getSelectedMenuItemByPageId,
    findMenuItemById,
    addPage,
    addLink,
    addFolder,
    getMenuItemsForIdeTree,
    moveMenuItemAfterTarget,
    getMenuItemByPageId,
    /**
     * @deprecated - Do NOT use it if you weren't asked directly about it
     * @returns {boolean}
     */
    __UNSAFE__isCustomMenusEnabled: isCustomMenusEnabled,
  };
}

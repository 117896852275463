import {
  sectionResizeButtonHovered,
  stretchHandle,
} from '@wix/bi-logger-editor/v2';

import { biLogger } from '#packages/util';
import { ClassicSectionResizeAndPushApiKey } from '#packages/apis';

import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { MouseEvent } from 'react';
import type { ResizeHandleOwnProps } from './ResizeHandle';

export interface ResizeHandleStateProps {
  isMobileEditor: boolean;
}

export const mapStateToProps: MapStateToProps<
  ResizeHandleStateProps,
  ResizeHandleOwnProps
> = ({ editorAPI }) => {
  return {
    isMobileEditor: editorAPI.isMobileEditor(),
  };
};

export interface ResizeHandleDispatchProps {
  startResize: (event: MouseEvent) => void;
  removeBottomGap: () => void;
  logResizeHandleHover: (event: MouseEvent) => void;
  logResizeDoubleClicked: () => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  ResizeHandleDispatchProps,
  ResizeHandleOwnProps
> = (dispatch: Dispatch, { compRef }) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const logResizeStart = (sectionLikeToResize: CompRef) => {
    const [
      {
        parent_component_type,
        parent_component_id,
        component_id,
        component_type,
      },
    ] = editorAPI.bi.getComponentsBIParams([sectionLikeToResize]);
    biLogger.report(
      stretchHandle({
        parent_component_type,
        parent_component_id,
        component_id,
        component_type,
        origin: 'sectionResizeHandle',
      }),
    );
  };

  const logResizeHandleHover = () => {
    const [{ component_id, component_type }] =
      editorAPI.bi.getComponentsBIParams([compRef]);

    const getSectionLikeName = () => {
      if (editorAPI.sections.isSection(compRef)) {
        return editorAPI.sections.getName(compRef);
      }

      return editorAPI.sections.isHeader(compRef) ? 'header' : 'footer';
    };

    biLogger.report(
      sectionResizeButtonHovered({
        component_id,
        component_type,
        section_name: getSectionLikeName(),
      }),
    );
  };

  const logResizeDoubleClicked = () => {
    const [
      {
        parent_component_type,
        parent_component_id,
        component_id,
        component_type,
      },
    ] = editorAPI.bi.getComponentsBIParams([compRef]);

    biLogger.report(
      stretchHandle({
        parent_component_type,
        parent_component_id,
        component_id,
        component_type,
        origin: 'sectionResizeHandleDoubleClick',
      }),
    );
  };

  const startResize = (event: MouseEvent) => {
    editorAPI.selection.selectComponentByCompRef(compRef, {
      origin: 'resize_handle',
    });

    editorAPI.mouseActions.registerMouseMoveAction(
      editorAPI.host.getAPI(ClassicSectionResizeAndPushApiKey),
      {
        evt: event,
        comps: [compRef],
        directionName: 'bottom',
      },
    );

    logResizeStart(compRef);
  };

  const removeBottomGap = async () => {
    await editorAPI.waitForChangesAppliedAsync();
    editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
    editorAPI.sections.removeBottomGap(compRef);
    await editorAPI.waitForChangesAppliedAsync();
    editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(
      !editorAPI.zoomMode.isInZoomMode(),
    );
  };

  return {
    startResize,
    logResizeHandleHover,
    logResizeDoubleClicked,
    removeBottomGap,
  };
};

import * as stateManagement from '#packages/stateManagement';
import * as platformEvents from 'platformEvents';
import { isMeshLayoutEnabled } from '#packages/layout';
import type { BaseDragPlugin } from './common';
import type { CompRef } from 'types/documentServices';

export const NotifyAndFinalPlugin: BaseDragPlugin = {
  init: ({ pluginFactoryScope, hooks }) => {
    const { editorAPI, selectedComp } = pluginFactoryScope;

    const tapDragEnd = async ({
      selectedCompReparented,
    }: {
      selectedCompReparented: CompRef[];
    }) => {
      editorAPI.store.dispatch(
        stateManagement.multilingual.actions.componentChanged(),
      );
      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.componentDragEnded({ compRef: selectedComp[0] }),
      );

      // wait for component is re-parented to attach candidate if needed
      await editorAPI.dsActions.waitForChangesAppliedAsync();

      editorAPI.components.layout.openCompOutOfGridPanelIfNeeded(
        selectedCompReparented,
      );
    };

    if (isMeshLayoutEnabled()) {
      const unsubscribe =
        editorAPI.components.layout.__mesh.hooks.moveByTransitionEndAfter.tap(
          ({ compRefUpdatedMap }) => {
            unsubscribe();

            tapDragEnd({
              selectedCompReparented: selectedComp.map(
                (compRef) => compRefUpdatedMap.get(compRef.id) ?? compRef,
              ),
            });
          },
        );
    } else {
      hooks.endDrag.tap(() =>
        tapDragEnd({ selectedCompReparented: selectedComp }),
      );
    }
  },
};

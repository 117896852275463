// @ts-nocheck
import _ from 'lodash';

import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import { translate } from '#packages/i18n';
import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import experiment from 'experiment';
import * as platform from '#packages/platform';
import { isDataModeOn } from '#packages/wixData';
import { DesignerPanelsApiKey } from '#packages/apis';
import constants from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';
import { isCustomMenusEnabled, bi } from '#packages/util';
import { DefaultActionTypes } from '../../panel/types/handlers';
import { addVariationActionsIfNeeded } from './appReflowDefaultActions';
import {
  getActionSymbolByName,
  getActionSymbolByType,
  getCustomSymbolAction,
} from './symbols';

import * as menu from '#packages/menu';

import type {
  RouterViewActions,
  RouterAction,
  OnRenameHandler,
  OnItemAddedHandler,
} from '../../panel/types';
import type { ISettingsData } from '../../panel/pagesPanel';
import type { GroupActionRawData, PageGroupAction } from '../appsPages';
import type { PopupAction } from '../popupsPages';

const NON_PLATFORM_APP_DEFAULT_SETTINGS = [
  {
    title: translate('Pages_Actions_Page_Settings'),
    type: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
  },
  {
    title: translate(constants.PAGE_SETTINGS_TABS.TITLE_KEYS.BASIC_SEO),
    type: constants.PAGE_SETTINGS_TABS.SEO,
  },
];

const reportActionBi = (editorAPI, page_id, title, source) => {
  editorAPI.bi.event(coreBi.events.pages.pagesPanel.quick_actions_menu_select, {
    page_id,
    source,
    category: title,
    type: 'page',
  });
  if (title === 'edit' && source === 'custom_sign_in') {
    editorAPI.bi.event(coreBi.events.pages.login_edit_clicked, {
      origin: 'menu',
    });
  }
};

const reportTabBi = (editorAPI, title, source) => {
  editorAPI.bi.event(
    coreBi.events.pages.pagesPanel.tab_clicked_an_item_at_settings_menu,
    {
      source,
      category: title,
    },
  );
};

const getOnClickApplicationNotification =
  (editorAPI, applicationId, eventType, eventPayload?) => (id?: string) => {
    const fullEventPayload = _.defaults(eventPayload, {
      origin: 'pagesPanel',
      publicUrl: editorAPI.dsRead.generalInfo.getPublicUrl(),
      pageRef: id ? editorAPI.dsRead.pages.getReference(id) : null,
    });

    editorAPI.dsActions.platform.notifyApplication(applicationId, {
      eventType,
      eventPayload: fullEventPayload,
    });
  };

const isDeveloperModeEnabledOrDataModeIsOn = (editorAPI) =>
  editorAPI.developerMode.isEnabled() || isDataModeOn(editorAPI);

const dynamicPage = (
  editorAPI,
  dynamicPageId,
  applicationId,
  routerRef,
  onShowSettings,
  isDesktop,
  biCategory,
): RouterAction[] => {
  if (!isDesktop) {
    const mobileDynamicPageSettings = {
      title: translate('Mobile_Pages_Actions_Page_Settings'),
      ...getActionSymbolByName('settings_action'),
      onClick: (id) => {
        onShowSettings({
          tabType: constants.PAGE_SETTINGS_TABS.MOBILE_LAYOUT,
          pageId: id,
        });
        reportActionBi(editorAPI, id, 'dynamicPageMobileSettings', biCategory);
      },
    };
    return [mobileDynamicPageSettings];
  }

  let actions = [];

  if (isDeveloperModeEnabledOrDataModeIsOn(editorAPI)) {
    const rawActions = editorAPI.pages.dynamicPages.getDynamicPageActions(
      dynamicPageId,
      'pageActions',
    );

    actions = rawActions.map((action) => {
      const { title, type, event, permissions } = action;
      const onClick = getOnClickApplicationNotification(
        editorAPI,
        applicationId,
        event,
        { routerRef },
      );
      return {
        title,
        ...getActionSymbolByType(action),
        onClick: (id, newTitle) => {
          if (type === platform.constants.pages.page_actions.rename.type) {
            editorAPI.pages.data.update(id, { title: newTitle });
            const currentTitle = editorAPI.pages.data.get(id)?.title;
            if (title !== currentTitle) {
              editorAPI.history.debouncedAdd('Rename Page');
            }
          } else {
            onClick(id);
          }
          reportActionBi(editorAPI, id, event, biCategory || 'router');
        },
        permissions:
          editorAPI.platform.permissions.addGrantedToPermissions(permissions),
        isRename: type === platform.constants.pages.page_actions.rename.type,
      };
    });
  }

  const rawSettings = editorAPI.pages.dynamicPages.getDynamicPageActions(
    dynamicPageId,
    'pageSettings',
    [
      platform.constants.pages.page_settings.pageInfo.type,
      platform.constants.pages.page_settings.seo.type,
    ],
  );

  if (experiment.isOpen('se_addDesignerPage')) {
    actions.push({
      title: 'Pages CMS',
      symbolName: 'addPagePagesPanel',
      onClick: () => {
        const designerPanelsApi = editorAPI.host.getAPI(DesignerPanelsApiKey);
        designerPanelsApi.openSavePresetPanel('page');
      },
    });
  }

  const settings = rawSettings.map((action) => ({
    title: action.title,
    ...getActionSymbolByType(action),
    onClick: (id) => {
      onShowSettings({ pageId: dynamicPageId, tabType: action.type });
      reportActionBi(editorAPI, id, action.event, biCategory || 'router');
    },
    isSettings: true,
    permissions: editorAPI.platform.permissions.addGrantedToPermissions(
      action.permissions,
    ),
  }));

  return [...settings, ...actions];
};

// Here they set up the actions in the first menu
const signup = (editorAPI, onShowSettings, customSignupPageId) =>
  [
    {
      title: translate('Pages_Actions_Page_Settings'),
      ...getActionSymbolByName('settings_action'),
      onClick: (id) => {
        // Here they open the signup seb menu
        onShowSettings({
          pageId: id,
          tabType: 'page',
          formType: 'signUp',
        });
        reportActionBi(editorAPI, id, 'tabPageInfo', 'custom_signup');
      },
      isSettings: true,
    },
  ].concat(
    // In case the custom sign up is already registered we adds the action to edit the settings and delete
    customSignupPageId
      ? [
          {
            title: translate('Pages_Actions_Page_Edit'),
            symbolName: 'editAction',
            onClick: (id) => {
              editorAPI.panelManager.closeAllPanels();
              editorAPI.pages.navigateTo(id, () => {
                const type = 'wysiwyg.viewer.components.FormContainer';
                const currentPopup =
                  editorAPI.pages.popupPages.getCurrentPopup();
                const formContainer = editorAPI.components
                  .getChildren_DEPRECATED_BAD_PERFORMANCE(currentPopup, true)
                  .find((ref) => type === editorAPI.components.getType(ref));
                editorAPI.selection.selectComponentByCompRef(formContainer);
              });
              reportActionBi(editorAPI, id, 'edit', 'custom_signup');
            },
            isSettings: true,
          },
          {
            title: translate('Pages_Actions_Page_Delete_Custom_Signup'),
            ...getActionSymbolByName('delete_action'),
            onClick: (id, title) => {
              editorAPI.dsActions.waitForChangesApplied(function () {
                editorAPI.pages.remove(
                  id,
                  () => {
                    editorAPI.siteMembers.setCustomSignupPageId(null);
                    editorAPI.history.add('removed custom signup page');
                  },
                  _.noop,
                  { shouldShowEditorRemovePanel: true },
                );
              });
              editorAPI.bi.event(
                coreBi.events.topbar.pages.page_or_pop_up_deleted,
                {
                  page_or_popup: 'pop up',
                  page_popup_string_id: id,
                  page_popup_name: title,
                },
              );
              reportActionBi(editorAPI, id, 'delete', 'custom_signup');
            },
          },
        ]
      : [],
  );

// Here they set up the actions in the first menu
const signIn = (
  editorAPI: EditorAPI,
  onShowSettings: (settingsData: Partial<ISettingsData>) => void,
  customSignInPageId: string,
) =>
  [
    {
      title: translate('Pages_Actions_Page_Settings'),
      ...getActionSymbolByName('settings_action'),
      onClick: (id) => {
        // Here they open the signup seb menu
        onShowSettings({
          pageId: id,
          tabType: 'page',
          formType: 'signIn',
        });
        reportActionBi(editorAPI, id, 'tabPageInfo', 'custom_sign_in');
      },
      isSettings: true,
    },
  ].concat(
    // In case the custom sign up is already registered we adds the action to edit the settings and delete
    customSignInPageId
      ? [
          {
            title: translate('Pages_Actions_Page_Edit'),
            symbolName: 'editAction',
            onClick: (id: string) => {
              editorAPI.panelManager.closeAllPanels();
              editorAPI.pages.navigateTo(id, () => {
                const type = 'wysiwyg.viewer.components.FormContainer';
                const currentPopup =
                  editorAPI.pages.popupPages.getCurrentPopup();
                const formContainer = editorAPI.components
                  .getChildren_DEPRECATED_BAD_PERFORMANCE(currentPopup, true)
                  .find((ref) => type === editorAPI.components.getType(ref));
                editorAPI.selection.selectComponentByCompRef(formContainer);
              });
              reportActionBi(editorAPI, id, 'edit', 'custom_sign_in');
            },
            isSettings: true,
          },
          {
            title: translate('Pages_Actions_Page_Delete_Custom_Signup'),
            ...getActionSymbolByName('delete_action'),
            onClick: (id: string, title: string) => {
              editorAPI.dsActions.waitForChangesApplied(function () {
                editorAPI.pages.remove(
                  id,
                  () => {
                    editorAPI.siteMembers.setCustomSignInPageId(null);
                    editorAPI.history.add('removed custom sign in page');
                  },
                  _.noop,
                  { shouldShowEditorRemovePanel: true },
                );
              });
              editorAPI.bi.event(
                coreBi.events.topbar.pages.page_or_pop_up_deleted,
                {
                  page_or_popup: 'pop up',
                  page_popup_string_id: id,
                  page_popup_name: title,
                },
              );
              reportActionBi(editorAPI, id, 'delete', 'custom_sign_in');
            },
          },
        ]
      : [],
  );

const popup = (
  editorAPI: EditorAPI,
  isDesktop: boolean,
  onItemAdded: OnItemAddedHandler,
  onRename: OnRenameHandler,
): PopupAction[] | [] => {
  if (!isDesktop) {
    return [];
  }
  return [
    {
      title: translate('Pages_Actions_Page_Settings'),
      ...getActionSymbolByName('settings_action'),
      onClick: (id) => {
        const popupContainerRef =
          editorAPI.pages.popupPages.getPopupContainer();
        editorAPI.selection.selectComponentByCompRef(popupContainerRef);
        editorAPI.openComponentPanel('settingsPanel', {
          initiatorName: 'quickActions',
        });
        reportActionBi(editorAPI, id, 'open_popup_settings_panel', 'lightbox');
      },
      isSettings: true,
    },
    {
      title: translate('Pages_Actions_Page_Rename'),
      ...getActionSymbolByName('rename_action'),
      onClick: (id, title) => {
        const currentTitle = editorAPI.pages.data.get(id)?.title;
        if (title !== currentTitle) {
          editorAPI.pages.data.update(id, { title });
          editorAPI.history.debouncedAdd('Rename Page');
          reportActionBi(editorAPI, id, 'rename', 'lightbox');
        }
        onRename();
      },
      isRename: true,
    },
    {
      title: translate('Pages_Actions_Page_Duplicate'),
      ...getActionSymbolByName('duplicate_action'),
      isDisabled: editorAPI.dsRead.language.isCurrentLanguageSecondary(),
      onClick: (id) => {
        const popupPageRef = editorAPI.components.get.byId(id);
        const popupPageData = editorAPI.components.data.get(popupPageRef);
        const newPopup = editorAPI.pages.popupPages.duplicate(id);
        const newTitle = core.utils.pageTitleUtils.generateDuplicatedTitle(
          popupPageData.title,
        );

        const convertTitleToUriSEO =
          editorAPI.dsRead.generalInfo.urlFormat.isSlash()
            ? editorAPI.pages.data.pageUriSEO.convertPageNameToUrl
            : core.utils.pageTitleUtils.convertPageNameToUrl;

        editorAPI.pages.data.update(
          newPopup.id,
          {
            title: newTitle,
            pageUriSEO: convertTitleToUriSEO(newTitle),
          },
          true,
        );

        editorAPI.pages.navigateTo(newPopup.id);
        editorAPI.history.add('popup page was duplicated', { currentPage: id });

        onItemAdded(newPopup.id);
        reportActionBi(editorAPI, id, 'duplicate', 'lightbox');
      },
    },
    {
      title: translate('Pages_Actions_Page_Delete'),
      ...getActionSymbolByName('delete_action'),
      onClick: (id, title) => {
        editorAPI.dsActions.waitForChangesApplied(function () {
          const pageRef = editorAPI.dsRead.components.get.byId(id);
          const [popupCompRef] =
            editorAPI.dsRead.components.getChildren(pageRef);

          editorAPI.pages.remove(id, () => {
            editorAPI.bi.event(bi.events.COMPONENT_REMOVED, {
              removal_method: 'pages_panel',
              component_type: constants.COMP_TYPES.POPUP_CONTAINER,
              component_id: popupCompRef.id,
            });

            editorAPI.history.add('removed popup');
          });
        });
        editorAPI.bi.event(coreBi.events.topbar.pages.page_or_pop_up_deleted, {
          page_or_popup: 'pop up',
          page_popup_string_id: id,
          page_popup_name: title,
        });
        reportActionBi(editorAPI, id, 'delete', 'lightbox');
      },
    },
  ];
};

const getCanShowRouterActions = (
  routerType: string,
  isDesktop: boolean,
  editorAPI: EditorAPI,
  isSpecialRouter: boolean,
) => {
  const isDataBindingAppRouterType = routerType === 'Data';
  if (
    !isDesktop ||
    (!isDeveloperModeEnabledOrDataModeIsOn(editorAPI) && !isSpecialRouter) ||
    (!isDataBindingAppRouterType &&
      !editorAPI.developerMode.isEnabled() &&
      !isSpecialRouter)
  ) {
    return false;
  }

  return true;
};

const router = (
  editorAPI: EditorAPI,
  isDesktop: boolean,
  applicationId: number,
  prefix: string,
  biCategory?: string,
  isSpecialRouter?: boolean,
  routerType?: string,
): RouterAction[] => {
  const canShowRouterActions = getCanShowRouterActions(
    routerType,
    isDesktop,
    editorAPI,
    isSpecialRouter,
  );

  if (!canShowRouterActions) {
    return null;
  }

  const routerRef = editorAPI.dsRead.routers.getRouterRef.byPrefix(prefix);
  const rawActions =
    editorAPI.pages.dynamicPages.getRouterActionByPrefix(prefix);
  return rawActions.map(({ title, icon, event, type, callback }) => {
    const onClick = getOnClickApplicationNotification(
      editorAPI,
      applicationId,
      event,
      { routerRef },
    );
    return {
      title,
      ...getCustomSymbolAction(icon),
      type,
      event,
      onClick: (id) => {
        if (callback) {
          callback(id);
        } else {
          onClick(id);
        }
        reportTabBi(editorAPI, title, biCategory || 'router');
      },
    };
  });
};

const routerView = (
  editorAPI: EditorAPI,
  isDesktop: boolean,
  applicationId: number,
  prefix: string,
  biCategory?: string,
  isSpecialRouter?: boolean,
  routerType?: string,
): RouterViewActions => {
  const canShowRouterActions = getCanShowRouterActions(
    routerType,
    isDesktop,
    editorAPI,
    isSpecialRouter,
  );

  if (!canShowRouterActions) {
    return null;
  }

  const routerRef = editorAPI.dsRead.routers.getRouterRef.byPrefix(prefix);
  const rawActions =
    editorAPI.pages.dynamicPages.getRouterViewActionsByPrefix(prefix);

  if (
    rawActions &&
    (rawActions.mainAction || !_.isEmpty(rawActions.secondaryActions))
  ) {
    const getOnClick = (event: string, title: string) => () => {
      getOnClickApplicationNotification(editorAPI, applicationId, event, {
        routerRef,
      })();

      reportTabBi(editorAPI, title, biCategory || 'router');
    };

    const { mainAction, secondaryActions } = rawActions;

    return {
      mainAction: mainAction && {
        event: mainAction.event,
        title: mainAction.title,
        biActionName: mainAction.biActionName,
        onClick: getOnClick(mainAction.event, mainAction.title),
      },
      secondaryActions:
        secondaryActions &&
        secondaryActions.map((action) => ({
          event: action.event,
          ...getCustomSymbolAction(action.icon),
          tooltipContent: action.tooltip,
          biActionName: action.biActionName,
          onClick: getOnClick(action.event, action.tooltip),
        })),
    };
  }
};

const menuTab = (editorAPI, appDefinitionId, isDesktop) => {
  if (!isDesktop || !appDefinitionId) {
    return null;
  }

  const appRouterActions = editorAPI.pages.dynamicPages.getActionByAppDefId(
    appDefinitionId,
    'routerActions',
  );
  const applicationId =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId)
      ?.applicationId;

  return appRouterActions.map((action) => ({
    title: action.title,
    ...getCustomSymbolAction(action.icon),
    onClick:
      action.callback ||
      getOnClickApplicationNotification(editorAPI, applicationId, action.event),
  }));
};

const application = (editorAPI, appDefinitionId, isDesktop, biCategory) => {
  if (!isDesktop || !appDefinitionId) {
    return null;
  }

  const appData =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId);

  const applicationRawActions =
    editorAPI.pages.appPages.getApplicationActions(appDefinitionId);

  if (!editorAPI.dsRead.platform.hasAppManifest(appDefinitionId)) {
    applicationRawActions.push({
      title: translate('NewPages_Panel_CustomElement_App_Actions_Delete', {
        app_name: appData?.appDefinitionName,
      }),
      icon: 'delete',
      callback: () => editorAPI.platform.applications.uninstall(appData),
    });
  }

  return applicationRawActions.map(
    ({ title, icon, event, callback, type, tooltip, permissions }) => {
      const onClick = getOnClickApplicationNotification(
        editorAPI,
        appData?.applicationId,
        event,
      );
      return {
        title,
        type,
        event,
        tooltip,
        ...getCustomSymbolAction(icon),
        onClick: (id) => {
          if (callback) {
            callback(id);
          } else {
            onClick(id);
          }
          reportTabBi(editorAPI, title, biCategory);
        },
        permissions:
          editorAPI.platform.permissions.addGrantedToPermissions(permissions),
      };
    },
  );
};

const getOnClickByEvent = ({
  editorAPI,
  applicationId,
  event,
  biCategory,
}: {
  editorAPI: EditorAPI;
  applicationId: number;
  event: string;
  biCategory: string;
}) => {
  const onClickByEvent = getOnClickApplicationNotification(
    editorAPI,
    applicationId,
    event,
  );
  return (id) => {
    const data = editorAPI.dsRead.data.getById(id);
    if (data.type === 'BasicMenuItem') {
      id = data.link.pageId.replace('#', '');
    }

    onClickByEvent(id);

    reportActionBi(editorAPI, id, event, biCategory || 'router');
  };
};

const getDefaultActionsMap = ({
  defaultActions,
  editorAPI,
  onRename,
  biCategory,
  pageId,
  applicationId,
}: {
  defaultActions: MenuItemAction[];
  editorAPI: EditorAPI;
  onRename: OnRenameHandler;
  biCategory: string;
  pageId: string;
  applicationId: number;
}): Record<DefaultActionTypes, any> => {
  const renameDefault = ({
    title,
    event,
  }: {
    title: string;
    icon: string;
    event: string;
  }) => {
    return {
      onClick: event
        ? getOnClickApplicationNotification(editorAPI, applicationId, event)
        : (id, newTitle) => {
            const data = editorAPI.dsRead.data.getById(id);
            if (data.type === 'BasicMenuItem') {
              id = data.link.pageId.replace('#', '');
            }
            if (isCustomMenusEnabled()) {
              const menuAPI = menu.api.createMenuApi(editorAPI);
              menuAPI.renamePageInCustomMenus(id, newTitle);
            }
            editorAPI.pages.data.update(id, { title: newTitle });
            onRename();
            const currentTitle = editorAPI.pages.data.get(id)?.title;
            if (title !== currentTitle) {
              editorAPI.history.debouncedAdd('Rename Page');
            }
            reportActionBi(editorAPI, id, event, biCategory || 'router');
          },
      title: title ?? translate('Pages_Actions_Page_Rename'),
      ...getActionSymbolByName('rename_action'),
      isRename: true,
      type: DefaultActionTypes.RENAME_TYPE,
    };
  };
  const deleteDefault = ({
    title,
    event,
  }: {
    title: string;
    icon: string;
    event: string;
  }) => {
    return {
      onClick: event
        ? getOnClickApplicationNotification(editorAPI, applicationId, event)
        : (id) => {
            const data = editorAPI.dsRead.data.getById(id);
            if (data.type === 'BasicMenuItem') {
              id = data.link.pageId.replace('#', '');
            }
            editorAPI.dsActions.waitForChangesApplied(function () {
              editorAPI.pages.remove(pageId, () =>
                editorAPI.history.add('deleting a page', {
                  currentPage: pageId,
                }),
              );
            });
            reportActionBi(editorAPI, id, event, biCategory || 'router');
          },
      title: title ?? translate('Pages_Actions_Page_Delete'),
      ...getActionSymbolByName('delete_action'),
      isRemove: true,
      type: DefaultActionTypes.DELETE_TYPE,
    };
  };
  const removeDefault = () => {
    return {
      ...getActionSymbolByName('delete_action'),
      isRemove: true,
      type: DefaultActionTypes.REMOVE_TYPE,
    };
  };
  const getDefaultActionByTypeIfExist = (type) => {
    const defaultAction = defaultActions.find(
      (action) => action.defaultActionType === type,
    );

    if (!defaultAction) {
      return null;
    }

    return ({
      title,
      event,
    }: {
      title: string;
      icon: string;
      event: string;
    }) => {
      return {
        ...defaultAction,
        title: title ?? defaultAction.title,
        ...getCustomSymbolAction(defaultAction.symbolName),
        onClick: event
          ? getOnClickByEvent({ editorAPI, applicationId, event, biCategory })
          : defaultAction.onClick,
        type,
      };
    };
  };
  return {
    [DefaultActionTypes.RENAME_TYPE_DEPRECATED]: renameDefault,
    [DefaultActionTypes.RENAME_TYPE]: renameDefault,
    [DefaultActionTypes.DELETE_TYPE]: deleteDefault,
    [DefaultActionTypes.REMOVE_TYPE]: removeDefault,
    [DefaultActionTypes.HIDE_SHOW_TYPE]: getDefaultActionByTypeIfExist(
      DefaultActionTypes.HIDE_SHOW_TYPE,
    ),
    [DefaultActionTypes.SET_AS_HOMEPAGE_TYPE]: getDefaultActionByTypeIfExist(
      DefaultActionTypes.SET_AS_HOMEPAGE_TYPE,
    ),
    [DefaultActionTypes.DUPLICATE_TYPE]: getDefaultActionByTypeIfExist(
      DefaultActionTypes.DUPLICATE_TYPE,
    ),
    [DefaultActionTypes.COPY_TYPE]: getDefaultActionByTypeIfExist(
      DefaultActionTypes.COPY_TYPE,
    ),
  };
};

interface PageActionsOptions {
  appDefinitionId: string;
  isDesktop: boolean;
  biCategory: string;
  onShowSettings: Function;
  onRename: OnRenameHandler;
  defaultActions?: MenuItemAction[];
  replacers?: string[];
  variants?: string[];

  isVariant?: boolean;
  replacerOf?: string;
  tabAppDefinitionId: string;
}

const applicationPage = (
  editorAPI: EditorAPI,
  pageId: string,
  {
    appDefinitionId,
    isDesktop,
    biCategory,
    onShowSettings,
    onRename,
    defaultActions = [],
    replacers,
    isVariant,
    replacerOf,
    tabAppDefinitionId,
  }: PageActionsOptions,
  pageTitle: string,
) => {
  if (!isDesktop) {
    return null;
  }

  const applicationId =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId)
      ?.applicationId;

  const pageRef = editorAPI.pages.getReference(pageId);
  const rawActions = editorAPI.pages.appPages.getPageActions(
    appDefinitionId,
    pageRef,
  );

  const defaultActionsMap = getDefaultActionsMap({
    defaultActions,
    editorAPI,
    onRename,
    biCategory,
    pageId,
    applicationId,
  });

  /* As the defaultActions array is received only for main pages, there are cases (e.g for application pages) where
   * we won't get any a default implementation for some defualt types.
   * for such cases, we filter out actions that were received without onClick / event implementation.
   */
  const filterNonimplementedDefaultActions = ({ type, onClick, event }) => {
    const isNotDefault = !Object.values(DefaultActionTypes).includes(type);
    const hasDefaultImplementation = !!defaultActionsMap[type];
    const hasEventHandler = !!onClick || !!event;

    if (isNotDefault || hasDefaultImplementation || hasEventHandler) {
      return true;
    }

    return false;
  };

  const rawActionsWithoutNonimplementedDefaultActions = rawActions.filter(
    filterNonimplementedDefaultActions,
  );

  const actions = rawActionsWithoutNonimplementedDefaultActions.map(
    ({ title, icon, type, event, permissions }) => {
      const onClickByEvent = getOnClickByEvent({
        editorAPI,
        applicationId,
        event,
        biCategory,
      });

      const preDefinedAction = defaultActionsMap[type]
        ? defaultActionsMap[type]({ title, icon, type, event, permissions })
        : null;

      const action = {
        title,
        ...getCustomSymbolAction(icon),
        onClick: onClickByEvent,
        isRename: false,
        isRemove: false,
        permissions:
          editorAPI.platform.permissions.addGrantedToPermissions(permissions),
        type: 'custom',
      };

      return {
        ...action,
        ...(preDefinedAction ?? {}),
      };
    },
  );
  const variationActions = addVariationActionsIfNeeded(
    editorAPI,
    pageRef,
    replacers,
    replacerOf,
    isVariant,
    appDefinitionId,
    tabAppDefinitionId,
    defaultActionsMap,
    pageTitle,
  );

  const getSettings = (rawSettings) =>
    rawSettings.map((setting) => ({
      title: setting.title,
      ...getActionSymbolByType(setting),
      onClick: (id) => {
        onShowSettings({ pageId, tabType: setting.type });
        reportActionBi(editorAPI, id, setting.event, biCategory);
      },
      isSettings: true,
      permissions: editorAPI.platform.permissions.addGrantedToPermissions(
        setting?.permissions,
      ),
      type: setting.type,
    }));

  const rawSettings = editorAPI.pages.appPages.getPageSettings(
    appDefinitionId,
    [
      platform.constants.pages.page_settings.pageInfo.type,
      platform.constants.pages.page_settings.seo.type,
    ],
    pageRef,
  );

  const settings = getSettings(rawSettings);

  const nonPlatformAppsActions = getNonPlatformAppsPageActionsAndSettings(
    editorAPI,
    {
      pageId,
      appDefinitionId,
      applicationId,
      biCategory,
      onRename,
    },
    getSettings,
  );

  return [
    ...settings,
    ...variationActions,
    ...actions,
    ...nonPlatformAppsActions,
  ];
};

interface NonPlatformApplicationsActionsOptions {
  pageId: string;
  appDefinitionId: string;
  applicationId: number;
  biCategory: string;
  onRename: OnRenameHandler;
}

const getNonPlatformAppsPageActionsAndSettings = (
  editorAPI: EditorAPI,
  {
    pageId,
    appDefinitionId,
    applicationId,
    biCategory,
    onRename,
  }: NonPlatformApplicationsActionsOptions,
  getSettings: (rawSettings) => ReturnType<typeof getSettings>,
) => {
  if (editorAPI.dsRead.platform.hasAppManifest(appDefinitionId)) {
    return [];
  }

  const actions = [];

  const defaultActions = [
    {
      defaultActionType: DefaultActionTypes.DUPLICATE_TYPE,
      title: translate('Pages_Actions_Page_Duplicate'),
      onClick: () => editorAPI.pages.duplicate(pageId),
    },
  ];

  const defaultActionsMap = getDefaultActionsMap({
    editorAPI,
    defaultActions,
    onRename,
    biCategory,
    pageId,
    applicationId,
  });

  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);

  if (platformAPI.pages.isPageDuplicatable(pageId)) {
    actions.push(defaultActionsMap[DefaultActionTypes.DUPLICATE_TYPE]({}));
  }

  actions.push(defaultActionsMap[DefaultActionTypes.RENAME_TYPE]({}));

  if (!platformAPI.pages.isPageEssential(pageId)) {
    actions.push(defaultActionsMap[DefaultActionTypes.DELETE_TYPE]({}));
  }

  const settings = getSettings(NON_PLATFORM_APP_DEFAULT_SETTINGS);

  return [...actions, ...settings];
};

const groupActions = (
  editorAPI: EditorAPI,
  appDefinitionId: string,
  groupId: string,
  groupActions: GroupActionRawData[],
  biCategory: string,
): PageGroupAction[] | null => {
  if (!editorAPI.isDesktopEditor()) {
    return null;
  }

  if (!groupActions) {
    return null;
  }

  if (!groupActions.length) {
    return [];
  }

  const applicationId =
    editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId)
      ?.applicationId;

  return groupActions.map(({ title, icon, event, tooltip, permissions }) => {
    const onClick = getOnClickApplicationNotification(
      editorAPI,
      applicationId,
      event,
      { groupId },
    );

    return {
      title,
      tooltip,
      ...getCustomSymbolAction(icon),
      onClick: () => {
        onClick();
        reportActionBi(editorAPI, groupId, event, biCategory);
      },
      permissions:
        editorAPI.platform.permissions.addGrantedToPermissions(permissions),
    };
  });
};

export {
  dynamicPage,
  popup,
  menuTab,
  router,
  routerView,
  application,
  applicationPage,
  getOnClickApplicationNotification,
  signup,
  signIn,
  groupActions,
};

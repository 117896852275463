import React from 'react';
import style from './switchLayoutPanel.scss';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

export interface ResultThumbnailProps {
  resultItem: any;
  isSelected: boolean;
  isSelecting: boolean;
  onClick: () => void;
  render(item: any): any;
}

export const ResultThumbnail: React.FC<ResultThumbnailProps> = (props) => {
  const { resultItem, isSelected, isSelecting, onClick, render } = props;
  return (
    <div
      data-hook="switch-layout-result-thumbnail"
      className={cx(style['result-thumbnail'], {
        [style['is-selected']]: isSelected,
        [style['is-selecting']]: isSelecting,
      })}
      onClick={onClick}
    >
      <div className={style['selected-check']}>
        <Symbol name="selectionVIcon" />
      </div>
      {render(resultItem)}
    </div>
  );
};

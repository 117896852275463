// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import _ from 'lodash';
import * as util from '#packages/util';
import constants from '#packages/constants';
import React from 'react';
import * as BaseUI from '#packages/baseUI';
import { Checkbox } from '@wix/wix-base-ui';
import { cx } from '#packages/util';
const { imageTransform, serviceTopology, backgroundUtils } = util;
const { getColorValue, getMediaType } = backgroundUtils;
const mediaTypes = constants.MEDIA_TYPES;
//
const SLIDE_BG_PREVIEW_WIDTH = 50;
const SLIDE_BG_PREVIEW_HEIGHT = 35;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  mixins: [core.mixins.editorAPIMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated
  displayName: 'slideShowBgApplyPanelItem',
  propTypes: {
    slide: PropTypes.object.isRequired,
    slideTitle: PropTypes.string.isRequired,
    itemBgData: PropTypes.object,
    isCurrentSlide: PropTypes.bool,
    currentSlideBgData: PropTypes.object,
    valueLink: PropTypes.object,
  },

  mediaTypes,

  getInitialState() {
    this.origin = this.props.origin;
    this.initialBgData = this.props.itemBgData;
    return {
      checked: this.isCurrentSlide(),
    };
  },

  isCurrentSlide() {
    return this.props.isCurrentSlide;
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.isValueChanged(nextProps)) {
      this.setState({ checked: nextProps.valueLink.value });
    }
  },

  componentStateChanged() {
    this.props.valueLink.requestChange(
      this.state.checked,
      this.props.slide,
      this.initialBgData,
    );
  },
  //
  handleToggleChanged(value) {
    this.setState({ checked: value }, this.componentStateChanged);
  },
  //
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !this.isCurrentSlide() &&
      (this.isCheckedStateChanged(nextState) || this.isValueChanged(nextProps))
    );
  },
  //
  isValueChanged(nextProps) {
    return (
      nextProps.valueLink.value !== null &&
      this.props.valueLink.value !== nextProps.valueLink.value
    );
  },
  //
  isCheckedStateChanged(nextState) {
    return nextState.checked !== this.state.checked;
  },
  //
  //
  //
  getItemClassNames() {
    return {
      'slideShow-apply-panel-item': true,
      'current-slide': this.isCurrentSlide(),
    };
  },
  //
  //
  //
  getMediaType(data) {
    return getMediaType(data);
  },
  //
  getCurrentSlideData() {
    return this.props.currentSlideBgData;
  },
  getBgStyleByBackgroundType(data) {
    const color = getColorValue(this.getEditorAPI(), data.color);
    const style = { backgroundColor: color };
    const mediaType = this.getMediaType(data);
    if (mediaType !== mediaTypes.COLOR) {
      const imageData =
        mediaType === mediaTypes.IMAGE
          ? data.mediaRef
          : data.mediaRef.posterImageRef;
      const isSmaller = backgroundUtils.isSmallerFromContainer(
        imageData.width,
        imageData.height,
        SLIDE_BG_PREVIEW_WIDTH,
        SLIDE_BG_PREVIEW_HEIGHT,
      );
      const imageScaled = backgroundUtils.getImageDisplayData(
        isSmaller
          ? imageTransform.fittingTypes.TILE
          : imageTransform.fittingTypes.SCALE_TO_FILL,
        data.alignType,
        imageData.uri,
        imageData.width,
        imageData.height,
        SLIDE_BG_PREVIEW_WIDTH,
        SLIDE_BG_PREVIEW_HEIGHT,
      );
      style.backgroundImage = `url(${util.url.joinURL(
        serviceTopology.staticMediaUrl,
        imageScaled.uri,
      )})`;
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(style, imageScaled.css.container);
    }
    return style;
  },
  //
  getBgImageUrl(data) {
    let style;
    switch (this.origin) {
      case 'strip':
        style = this.getBgStyleByBackgroundType(data);
        break;

      case 'box':
        // if (experiment.isOpen('se_balata') && experiment.isOpen('se_designData')){
        style = this.getBgStyleByBackgroundType(data);
        // } else {
        //     style = {backgroundColor: getColorValue(this.getEditorAPI(), data.style.properties.bg)};
        // }
        break;
    }

    return style;
  },

  render() {
    const { slide } = this.props;
    const currentSlideBgData = this.getCurrentSlideData();
    const bgData = this.initialBgData;

    return (
      <div className={cx(this.getItemClassNames(slide))}>
        <label className="item-label">
          {bgData ? (
            <Checkbox
              key="normal-checkbox"
              value={this.state.checked}
              onChange={this.handleToggleChanged}
              disabled={this.isCurrentSlide()}
              labelAfterSymbol={true}
            />
          ) : null}

          {bgData ? (
            <span
              key="slide-preview"
              style={this.getBgImageUrl(
                this.state.checked ? currentSlideBgData : bgData,
              )}
              className="slide-preview"
            >
              {this.getMediaType(
                this.state.checked ? currentSlideBgData : bgData,
              ) === this.mediaTypes.VIDEO ? (
                <div
                  key="slide-preview-video-controls"
                  className="slide-preview-video-controls"
                >
                  <BaseUI.symbol name="bgVideoIndicator" />
                </div>
              ) : null}
            </span>
          ) : null}

          <span className="slide-name">{this.props.slideTitle}</span>
        </label>
      </div>
    );
  },
});

// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['add_section_label_site_search'],
      sections: [
        addPanel.sections.getSections().developer.searchBoxSection.getSection(),
      ],
    };
  },
};

import React from 'react';
import {
  ContextMenu,
  ContextMenuAction,
  TextLabel,
  TextButton,
  Tooltip,
} from '@wix/wix-base-ui';
import type { PageGroupAction } from '../../../api/appsPages';
import * as coreBi from '#packages/coreBi';
import * as symbols from '@wix/santa-editor-symbols';

class GroupTitle extends React.Component<GroupTitleProps> {
  handleContextMenuToggle = (isOpen: boolean) => {
    if (isOpen) {
      this.props.biEvent(
        coreBi.events.pages.pagesPanel.tab_clicked_on_settings,
        {
          category: this.props.appDefinitionId,
          source: 'dynamic_collection_pages',
        },
      );
    }
  };

  getActionSymbol = (action: any) => {
    if (action?.symbol && action?.symbolName) {
      return <action.symbol className="symbol" data-hook={action.symbolName} />;
    }
    if (action?.symbolName) {
      return (
        <symbols.symbol
          name={action.symbolName}
          className="context-menu-action-icon"
        />
      );
    }
    return null;
  };

  getActions = () =>
    this.props.actions.length > 1 ? (
      <ContextMenu
        key={`page-group-context-menu-${this.props.title}`}
        automationId="pages-group-context-menu"
        className="pages-group-context-menu"
        maxWidth={'350px'}
        customButton={
          <symbols.symbol
            name="settingsAction"
            className="group-context-menu-symbol"
          />
        }
        onToggle={(isOpen: boolean) => {
          this.handleContextMenuToggle(isOpen);
        }}
      >
        {this.props.actions.map((action, index) => {
          return (
            <ContextMenuAction
              onClick={() => action.onClick()}
              key={`group-action-${this.props.title}-${action.title}-${index}`}
              automationId="pages-group-action"
            >
              {this.getActionSymbol(action)}
              <span>{action.title}</span>
            </ContextMenuAction>
          );
        })}
      </ContextMenu>
    ) : (
      <Tooltip content={this.props.actions[0].tooltip} shouldTranslate={false}>
        <TextButton
          size="tiny"
          onClick={() => this.props.actions[0].onClick()}
          shouldTranslate={false}
          className="group-single-action-button"
        >
          {this.props.actions[0].title}
        </TextButton>
      </Tooltip>
    );

  render() {
    return (
      <div className="group-container">
        <TextLabel
          type="T04"
          automationId="group-title"
          value={this.props.title}
          shouldTranslate={false}
          className="group-title-inner"
        />
        {this.props.actions && this.props.actions.length > 0
          ? this.getActions()
          : null}
      </div>
    );
  }
}

interface GroupTitleProps {
  title: string;
  actions?: PageGroupAction[] | null;
  biEvent: Function;
  appDefinitionId: string;
}

export default GroupTitle;

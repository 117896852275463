import * as coreBi from '#packages/coreBi';
import type { EditorAPI } from '#packages/editorAPI';
import type { InteractionsStore } from './createInteractionsStore';

export function createInteractionsBI({
  editorAPI,
  interactionsStore,
}: {
  editorAPI: EditorAPI;
  interactionsStore: InteractionsStore;
}) {
  function exitInteractionMode() {
    const triggerRef = interactionsStore.getInteractionTriggerRef();
    const compType = editorAPI.components.getType(triggerRef);
    editorAPI.bi.event(coreBi.events.interactions.exit_interaction_mode, {
      component_id: triggerRef?.id,
      interaction_id: interactionsStore.getVariantId(),
      component_type: compType,
      origin: 'overlayClick',
    });
  }

  return {
    exitInteractionMode,
  };
}

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import * as editorRefreshTPADataAfterUpgrade from '../handlers/editorRefreshTPADataAfterUpgrade';
import * as bi from '../bi/bi';
import * as tpaAlertsService from '../services/tpaAlertsService';

const { premiumUtils } = util;

const TYPE = {
  BENEFITS: 'tpaPanels.packagePicker.benefitsModal',
  CYCLES: 'tpaPanels.packagePicker.cyclesModal',
  EXTERNAL: 'tpaPanels.packagePicker.externalBenefitsModal',
  PACKAGES: 'tpaPanels.packagePicker.packagePickerModal',
};

const BI_TYPE = {
  BENEFITS: 'offering',
  CYCLES: 'cycle',
  PACKAGES: 'package',
  EXTERNAL: 'external',
};

const getUpgradeUrl = function (
  editorAPI,
  appData,
  vendorProductId,
  paymentCycle,
  pp_type,
  origin,
  premiumIntent,
) {
  const referralAdditionalInfo = `tpa_${origin}_ed_14`;
  return editorAPI.dsRead.tpa.billing.getAppUpgradeUrl(
    appData.applicationId,
    vendorProductId,
    paymentCycle,
    {
      referralAdditionalInfo,
      pp_type,
      premiumIntent,
    },
  );
};

const upgradeToPremiumAndClosePickerModal = function (
  editorAPI,
  appDefinitionId,
  vendorProductId,
  paymentCycle,
  pp_type,
  origin,
  closePanelCallback,
  premiumIntent,
) {
  const isSaved =
    !editorAPI.dsRead.generalInfo.isFirstSave() &&
    !editorAPI.dsRead.generalInfo.isDraft();
  const appData = editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
  if (!isSaved) {
    tpaAlertsService.openSaveFailedAlertForPackageBuying(editorAPI);
  } else {
    const upgradeUrl = getUpgradeUrl(
      editorAPI,
      appData,
      vendorProductId,
      paymentCycle,
      pp_type,
      origin,
      premiumIntent,
    );
    window.open(upgradeUrl);
    editorRefreshTPADataAfterUpgrade.trackAppUpgrade(
      editorAPI,
      appData.applicationId,
    );
  }

  if (_.isFunction(closePanelCallback)) {
    closePanelCallback();
  }
  editorAPI.bi.event(bi.events.CLICK_UPGRADE_IN_PP, {
    pp_type,
    pp_origin: origin,
    app_site_id: appData.instanceId,
    app_id: appData.appDefinitionId,
  });
};

const pickPickerForm = function (editorAPI, marketData, appData, options = {}) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  const relevantData = _.assign(
    _.pick(marketData, [
      'name',
      'appIcon',
      'colorSvgIcon',
      'packages',
      'features',
      'price',
      'upgradeBenefits',
      'upgradeTitle',
      'upgradedToYearly',
      'appDefinitionId',
    ]),
    _.pick(appData, 'vendorProductId'),
  );

  relevantData.packages = _.reject(marketData.packages, { id: 'oneTime' });

  if (appData?.openPremiumPage) {
    relevantData.panelName = TYPE.EXTERNAL;
    const params = {
      appDefId: appData.appDefinitionId,
      referralAdditionalInfo: options.origin,
    };
    if (options.premiumIntent) {
      params.premiumIntent = options.premiumIntent;
    }
    relevantData.upgradeUrl =
      editorAPI.dsRead.tpa.billing.getSiteUpgradeUrl(params);
    return relevantData;
  }

  relevantData.panelName = TYPE.EXTERNAL;
  relevantData.externalPackages = true;
  relevantData.upgradeUrl = premiumUtils.getPremiumUrl(editorAPI, {
    appDefinitionId: appData.appDefinitionId,
    origin: 'GFPP',
  });
  return relevantData;
};

export { upgradeToPremiumAndClosePickerModal, pickPickerForm, TYPE, BI_TYPE };

import { highlights, interactions } from '#packages/stateManagement';
import { shouldEnableQuickEdit } from '../quickEditApi';
import {
  getCurrentEditedSection,
  reSelectQuickEditRootComponentIfNeeded,
  updatePanelContextMenu,
} from '../quickEditUtils';
import { setSectionCorrelationId } from '../quickEditBi';
import { ALLOWED_ROOT_QUICK_EDIT_COMPONENTS } from '../consts';

import type { CompRef } from 'types/documentServices';
import type { QuickEditScope } from '../quickEditEntryPoint';

export const mapStateToProps = (scope: QuickEditScope) => {
  const { store, editorAPI, selectionApi, editorParamsAPI } = scope;
  const rootControlCompRef = getCurrentEditedSection(editorAPI);
  return {
    panelStateId: editorAPI.viewMode.get(),
    isLoading: store.getIsQuickEditLoading(),
    baseUIOverrides: editorParamsAPI.baseUIOverrides,
    shouldDisplayEmptyState: !shouldEnableQuickEdit(scope, rootControlCompRef),
    rootControlCompRef,
    selectedComponentId:
      selectionApi.getSelectedComponentId() || rootControlCompRef?.id,
  };
};

export const mapDispatchToProps = (scope: QuickEditScope) => {
  const { store, editorAPI, workspaceRightPanelApi, editorRestrictionsApi } =
    scope;
  return {
    setSectionCorrelationId: () => setSectionCorrelationId(store),
    onPanelUnload: () => {
      interactions.actions.exitInteractionModeIfNeeded(editorAPI);
      editorAPI.store.dispatch(highlights.actions.clearHighlights());
    },
    onSelectedComponentChanged: (newSelectedCompId: string) => {
      const newSelectedRef = editorAPI.components.get.byId(newSelectedCompId);
      if (
        ALLOWED_ROOT_QUICK_EDIT_COMPONENTS.has(
          editorAPI.components.getType(newSelectedRef),
        ) &&
        newSelectedCompId !== store.getCurrentEditedCompId()
      ) {
        if (workspaceRightPanelApi.isDrillInOpen()) {
          workspaceRightPanelApi.closeAllDrillInPanels();
        }
        store.setCurrentEditedCompId(newSelectedCompId);
      }
    },
    onPanelReload: (compRef: CompRef) => {
      interactions.actions.exitInteractionModeIfNeeded(editorAPI, false);
      editorAPI.store.dispatch(highlights.actions.clearHighlights());
      if (workspaceRightPanelApi.isDrillInOpen()) {
        workspaceRightPanelApi.closeAllDrillInPanels();
      }
      if (!scope.store.getIsNavigationInProcessOnQuickEdit()) {
        const newSelectedRoot = reSelectQuickEditRootComponentIfNeeded(scope);
        if (!newSelectedRoot) {
          if (compRef) {
            updatePanelContextMenu(scope, compRef);
          } else {
            const isStageInteractive = editorRestrictionsApi.allowed(
              'rEditor_stage-container.interactive',
            );
            if (isStageInteractive) {
              workspaceRightPanelApi.close('click_outside');
            }
          }
        }
      }
    },
  };
};

import addPanelDataConsts from '#packages/addPanelDataConsts';
import { addCompOnClick } from '../addPanelAddCompService';

const appDefinitionId = '949cfcc9-2a3f-4a96-bd6c-11d9d82763a4';
const widgetId = 'aa86b56d-3c49-44fd-9976-963695e7815d';
const SECTION_INDEX_IN_GROUP = 3;

const registerSection = function (editorAPI: AnyFixMe) {
  editorAPI.addPanel.registerSectionToCategoryByGroup(
    addPanelDataConsts.CATEGORIES_ID.DATABASE,
    0,
    SECTION_INDEX_IN_GROUP,
    () => getSection(),
  );
};

const biOptions = {
  origin: 'vertical_button',
  sectionTitle: 'add_section_richcontent_title',
  category: 'richcontent',
};

const addApp = function (
  compStructure: AnyFixMe,
  sectionTitle: AnyFixMe,
  tags: AnyFixMe,
  id: AnyFixMe,
  editorAPI: AnyFixMe,
  addOptions: AnyFixMe,
) {
  return addCompOnClick(
    editorAPI,
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    id,
    biOptions,
    addOptions,
  );
};

function getSectionItems() {
  return [
    {
      id: 'add_richcontent',
      action: addPanelDataConsts.CLICK_ACTION.DATA.ADD_RICHCONTENT,
      appDefinitionId,
      title: 'ADD_SECTION_RICHCONTENT_COMP1_TITLE',
      desc: 'ADD_SECTION_RICHCONTENT_COMP1_DESCRIPTION',
      tooltip: 'ADD_SECTION_RICHCONTENT_COMP1_DESCRIPTION',
      image: 'addPanelData/sections/dataBinding/addRichContent.png',
      symbol: 'plusBig',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
          canBeStretched: true,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          appDefinitionId,
          widgetId,
        },
        style: 'tpaw0',
      },
    },
  ];
}

const getSection = function () {
  return {
    type: addPanelDataConsts.SECTIONS_TYPES.DATA,
    hide: false,
    title: 'ADD_SECTION_RICHCONTENT_TITLE',
    appDefinitionId,
    subNavigationTitle: 'ADD_SECTION_RICHCONTENT_SUBTITLE',
    presetTitle: 'ADD_SECTION_RICHCONTENT_TITLE',
    tooltipTitle: 'ADD_SECTION_RICHCONTENT_TOOLTIP_TITLE',
    sectionName: 'richContent',
    id: 'add_panel_section_rich_content',
    automationId: 'add-panel-section-richContent',
    subNavigationHide: false,
    showSectionHeader: true,
    onClick: addApp,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
      iconEnabledForComps: {},
    },
    props: {
      items: getSectionItems(),
    },
  };
};

export { registerSection, getSection };

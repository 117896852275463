import constants from '#packages/constants';
import { editorDataFunction } from '@wix/editor-component-data-resolver';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  getGfppData: editorDataFunction(() => ({
    mainActions: [
      {
        label: 'gfpp_mainaction_memberlogin',
        isApplied: true,
        actionType: ACTIONS.HELP,
        helpId: 'a5f57bae-b654-40f3-beeb-a6534384c4fb',
      },
    ],
    enabledActions: [
      ACTIONS.SETTINGS,
      ACTIONS.DESIGN,
      ACTIONS.ANIMATION,
      ACTIONS.HELP,
    ],
    mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
    helpId: 'f274c7fb-2841-4e74-83c1-0fda2fe192d2',
  })),
};

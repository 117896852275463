// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as core from '#packages/core';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import * as BaseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import SectionControlsButton from './sectionControlsButton';
import constants from '#packages/constants';
import { cx } from '#packages/util';

const { getDeviceType } = stateManagement.leftBar.selectors;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'sectionControls',
  propTypes: {
    isMobileEditor: PropTypes.bool,
    layout: PropTypes.object.isRequired,
    siteScale: PropTypes.number.isRequired,
    showDragHighlight: PropTypes.bool,
    isDragging: PropTypes.bool,
    isGap: PropTypes.bool,
    isSpacing: PropTypes.bool,
    isHeader: PropTypes.bool,
    isFooter: PropTypes.bool,
    isStaticSection: PropTypes.bool,
    isInCropMode: PropTypes.bool,
    isSectionRemovable: PropTypes.bool,
    onDragHandleMouseDown: PropTypes.func,
    onUpClicked: PropTypes.func,
    onDownClicked: PropTypes.func,
    onDuplicateClicked: PropTypes.func,
    onDeleteClicked: PropTypes.func,
  },
  mixins: [core.mixins.editorAPIMixin],
  getDefaultProps() {
    return {
      isMobileEditor: false,
      showDragHighlight: false,
    };
  },
  getInitialState() {
    return {
      isHoveringControlButton: false,
    };
  },
  UNSAFE_componentWillMount() {
    this._setControlButtonHoverState = _.debounce(
      this.setControlButtonHoverState,
      100,
    );
    this.throttledBi = _.throttle(this.bi, 50, {
      leading: false,
      trailing: true,
    });
  },
  getSectionControlsClasses() {
    return {
      'section-controls': true,
      'show-highlight':
        this.props.showDragHighlight || this.state.isHoveringControlButton,
      'no-drag-handle':
        this.state.isHoveringControlButton || this.props.isSpacing,
      'header-footer-blocker':
        this.props.isHeader ||
        this.props.isFooter ||
        this.props.isStaticSection,
    };
  },
  getSectionStyle() {
    return {
      top: this.props.layout.y * this.props.siteScale,
      height: this.props.layout.height * this.props.siteScale,
    };
  },
  getHighlightStyle() {
    const siteWidth = this.props.isMobileEditor ? '320px' : '100%';

    const style = {
      width: `calc(${siteWidth} * ${this.props.siteScale} + 2px)`,
    };

    if (this.props.siteScale === 1) {
      style.pointerEvents = 'none';
    }

    return style;
  },
  setControlButtonHoverState(state) {
    if (this.state.isHoveringControlButton !== state) {
      this.setState({
        isHoveringControlButton: state,
      });
    }
  },
  shouldDisplayReorderButtons() {
    const preventReorder =
      this.props.isHeader ||
      this.props.isFooter ||
      this.props.isStaticSection ||
      this.props.isGap ||
      this.props.isDragging ||
      this.props.isInCropMode;

    return !preventReorder;
  },
  getControlButtonProps() {
    const self = this;
    return {
      onMouseEnter() {
        self._setControlButtonHoverState(true);
      },
      onMouseLeave() {
        self._setControlButtonHoverState(false);
      },
    };
  },
  getButtonsWrapperStyle() {
    let left = `calc(25% + 100% * ${this.props.siteScale} + 36px)`;
    if (this.props.isMobileEditor) {
      left = `calc(50% + 320px / 2 * ${this.props.siteScale} + 36px)`;
    }

    return {
      height: this.props.layout.height * this.props.siteScale,
      left,
    };
  },

  bi(event) {
    const editorAPI = this.getEditorAPI();
    const state = editorAPI.store.getState();
    editorAPI.store.dispatch(
      stateManagement.bi.actions.event(event, {
        editor_view_mode: getDeviceType(state),
      }),
    );
  },

  onHeaderOver() {
    this.throttledBi(coreBi.events.sectionReorganize.header_tooltip_hover);
  },

  onFooterOver() {
    this.throttledBi(coreBi.events.sectionReorganize.footer_tooltip_hover);
  },

  render() {
    const sectionStyle = this.getSectionStyle();

    return (
      <div
        style={sectionStyle}
        className={cx(this.getSectionControlsClasses())}
      >
        <div
          style={this.getHighlightStyle()}
          onMouseDown={this.props.onDragHandleMouseDown}
          className={`highlight ${this.props.isDragging ? 'with-shadow' : ''}`}
        >
          {/* <baseUI.tooltip id="{this.props.tooltipId}" value="ONSTAGE_DRAG_STRIP_TOOLTIP" alignment="{constants.UI.TOOLTIP.ALIGNMENT.LEFT}" delay="0" disabled="{this.props.isDragging}"> */}
          {/* <div class="drag-handle" style="{{height: sectionStyle.height}}"> */}
          {/* <baseUI.symbol name="reorderHandleDots" class="drag-handle-dots" /> */}
          {/* </div> */}
          {/* </baseUI.tooltip> */}
        </div>

        {this.shouldDisplayReorderButtons() ? (
          <div
            key="reorder-buttons"
            style={this.getButtonsWrapperStyle()}
            className="section-buttons-wrapper"
          >
            <div className="section-buttons">
              <SectionControlsButton
                onClick={
                  this.props.isFirstSection ? _.noop : this.props.onUpClicked
                }
                tooltip="ZoomOut_Section_Tooltip_Move_Up"
                symbol="sectionMoveUp"
                isDisabled={this.props.isFirstSection}
                {...this.getControlButtonProps()}
              />
              <SectionControlsButton
                onClick={
                  this.props.isLastSection ? _.noop : this.props.onDownClicked
                }
                tooltip="ZoomOut_Section_Tooltip_Move_Down"
                symbol="sectionMoveDown"
                isDisabled={this.props.isLastSection}
                {...this.getControlButtonProps()}
              />
              {!this.props.isMobileEditor ? (
                <SectionControlsButton
                  key="duplicate_btn"
                  onClick={this.props.onDuplicateClicked}
                  tooltip="ZoomOut_Section_Tooltip_Copy_Section"
                  symbol="sectionDuplicate"
                  {...this.getControlButtonProps()}
                />
              ) : null}
              {this.props.isSectionRemovable ? (
                <SectionControlsButton
                  key="remove_btn"
                  onClick={this.props.onDeleteClicked}
                  tooltip={
                    this.props.isMobileEditor
                      ? 'ZoomOut_Section_Tooltip_Hide_Section'
                      : 'ZoomOut_Section_Tooltip_Delete_Section'
                  }
                  symbol={
                    this.props.isMobileEditor
                      ? 'sectionMobileHide'
                      : 'sectionDelete'
                  }
                  {...this.getControlButtonProps()}
                />
              ) : null}
            </div>
          </div>
        ) : null}

        {this.props.isGap && !this.props.isInCropMode ? (
          <div
            key="gap-delete"
            style={this.getButtonsWrapperStyle()}
            className="section-buttons-wrapper"
          >
            <div className="section-buttons">
              <SectionControlsButton
                onClick={this.props.onDeleteClicked}
                symbol="sectionDelete"
                label={translate('ZoomOut_Delete_Space_Tooltip')}
                {...this.getControlButtonProps()}
              />
            </div>
          </div>
        ) : null}

        {this.props.isHeader || this.props.isFooter ? (
          <div
            key="header-footer-info"
            style={this.getButtonsWrapperStyle()}
            className="section-buttons-wrapper"
          >
            <div className="section-buttons">
              {this.props.isHeader &&
              !(this.props.isMobileEditor && this.props.siteScale === 1) ? (
                <BaseUI.tooltip
                  key="header-info"
                  value="ZoomOut_Header_Tooltip"
                  styleType="small"
                  alignment={constants.UI.TOOLTIP.ALIGNMENT.BOTTOM}
                >
                  <div
                    onMouseEnter={this.onHeaderOver}
                    className="header-footer-info"
                  >
                    <BaseUI.symbol name="infoIcon" className="info-icon" />
                    <span className="info-text">
                      {translate('ZoomOut_Header')}
                    </span>
                  </div>
                </BaseUI.tooltip>
              ) : null}

              {this.props.isFooter ? (
                <BaseUI.tooltip
                  key="footer-info"
                  value="ZoomOut_Footer_Tooltip"
                  styleType="small"
                  alignment={constants.UI.TOOLTIP.ALIGNMENT.TOP}
                >
                  <div
                    onMouseEnter={this.onFooterOver}
                    className="header-footer-info"
                  >
                    <BaseUI.symbol name="infoIcon" className="info-icon" />
                    <span className="info-text">
                      {translate('ZoomOut_Footer')}
                    </span>
                  </div>
                </BaseUI.tooltip>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  },
});

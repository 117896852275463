// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import { Button, Composites, TextLabel } from '@wix/wix-base-ui';

const actions = stateManagement.mobile.actions.mobileWizard;

//TYPE WAS GENERATED, remove this line when reviewed
interface MobileWizardFirstStepProps {
  nextStep?: FunctionFixMe;
  skipWizard?: FunctionFixMe;
}

class MobileWizardFirstStep extends React.Component<MobileWizardFirstStepProps> {
  static displayName = 'mobileWizardFirstStep';

  static propTypes = {
    nextStep: PropTypes.func,
    skipWizard: PropTypes.func,
  };

  render() {
    return (
      <div
        data-aid="mobile-wizard-first-step"
        className="mobile-wizard-first-step"
      >
        <img
          src={util.media.getMediaUrl(
            'mobileEditor/mobileWizard/mobile-wizard-welcome.png',
          )}
          className="step-image"
        />

        <TextLabel
          value="Mobile_Wizard_Welcome_FirstTime_Title"
          enableEllipsis={false}
          type="T13"
          className="step-header"
        />

        <div className="step-sub-header">
          <TextLabel
            value="Mobile_Wizard_Welcome_FirstTime_Text1"
            enableEllipsis={false}
            type="T03"
          />
          <br />
          <TextLabel
            value="Mobile_Wizard_Welcome_FirstTime_Text2"
            enableEllipsis={false}
            type="T03"
          />
        </div>

        <Composites.ActionSetVertical>
          <Button
            onClick={() => {
              this.props.nextStep();
            }}
            automationId="mobile-wizard-lets-go-button"
            key="mobile-wizard-first-step-next-button"
          >
            <span>{translate('Mobile_Wizard_Welcome_Go_Button')}</span>
          </Button>
          <Button
            automationId="mobile-wizard-skip-button"
            key="mobile-wizard-first-step-skip"
            onClick={() => {
              this.props.skipWizard();
            }}
            className="btn-sm btn-text"
          >
            <span>{translate('Mobile_Wizard_Welcome_Skip_Link')}</span>
          </Button>
        </Composites.ActionSetVertical>
      </div>
    );
  }
}

const dispatchToProps = (dispatch) => ({
  nextStep() {
    dispatch(actions.setNextStep());
  },
  skipWizard() {
    dispatch(actions.firstStepSkipClicked());
  },
});

export default util.hoc.connect(
  [],
  _.noop,
  dispatchToProps,
)(MobileWizardFirstStep);

import _ from 'lodash';

import { BasePublicApi } from '#packages/apilib';
import { add, amend } from './add';
import {
  clear,
  performUndoUntilLabel,
  performUndoUntilId,
  redo,
  undo,
  getUndoLastSnapshotLabel,
  getRedoLastSnapshotLabel,
} from './undoRedo';

import type { Scope } from './scope';

export class HistoryApi extends BasePublicApi<Scope> {
  add = this.bindScope(add);
  amend = this.bindScope(amend);
  undo = this.bindScope(undo);
  redo = this.bindScope(redo);
  clear = this.bindScope(clear);
  getUndoLastSnapshotLabel = this.bindScope(getUndoLastSnapshotLabel);
  getRedoLastSnapshotLabel = this.bindScope(getRedoLastSnapshotLabel);
  debouncedAdd = _.debounce(this.add, 500); //todo check
  performUndoUntilLabel = this.bindScope(performUndoUntilLabel);
  performUndoUntilId = this.bindScope(performUndoUntilId);
}

export const DEFAULT_WITH_FILL_LAYERS_SKIN_NAME =
  'wysiwyg.viewer.skins.screenwidthcontainer.DefaultWithFillLayers';

export const SCROLL_SUFFIX = '-scrl';

export const TRANSPARENT_SITE_SEGMENT_SKIN =
  'wysiwyg.viewer.skins.screenwidthcontainer.TransparentScreen';

export const DEFAULT_STYLE_PROPERTIES_FOR_DISIGN_PANEL: Record<string, string> =
  {
    shd: '0.00px 1.00px 4px 0px rgba(0,0,0,0.6)',
    'alpha-brd': '1',
    brd: 'color_11',
    rd: '0px 0px 0px 0px',
    brwt: '0px',
    brwb: '0px',
  };

export const initialDesignItem = {
  type: 'MediaContainerWithDividers',
  background: {
    type: 'BackgroundMedia',
    colorLayers: [
      {
        type: 'SolidColorLayer',
        opacity: 0,
        fill: {
          type: 'SolidColor',
          color: 'color_11',
        },
      },
    ],
    colorOverlay: '',
  },
};

export const DESIGN_STYLE_PROPERTIES_MAPPER: Record<
  string,
  { field_name: string; tab_name: string }
> = {
  'alpha-bg': { field_name: 'opacity', tab_name: 'fill' },
  bg: { field_name: 'color', tab_name: 'fill' },
  bgctr: { field_name: 'center_bg', tab_name: 'fill' },
  'alpha-bgctr': { field_name: 'center_bg_opacity', tab_name: 'fill' },
  shc: { field_name: 'shadow_color', tab_name: 'fill' },

  'alpha-brd': { field_name: 'border_opacity', tab_name: 'border' },
  brd: { field_name: 'border_color', tab_name: 'border' },
  brwt: { field_name: 'border_width_top', tab_name: 'border' },
  brwb: { field_name: 'border_width_bottom', tab_name: 'border' },
  brw: { field_name: 'border_width', tab_name: 'border' },
  brd2: { field_name: 'inner_border_color', tab_name: 'border' },
  'alpha-brd2': { field_name: 'inner_border_opacity', tab_name: 'border' },
  innerLineSize: { field_name: 'inner_line_size', tab_name: 'border' },
  outerLineSize: { field_name: 'outer_line_size', tab_name: 'border' },

  rd: { field_name: 'border_radius', tab_name: 'corners' },

  shd: { field_name: 'box_shadow', tab_name: 'shadow' },

  bgPosition: { field_name: 'bg_position', tab_name: 'spacing' },
  bordersPosition: { field_name: 'borders_position', tab_name: 'spacing' },
  lineGap: { field_name: 'line_gap', tab_name: 'spacing' },
};

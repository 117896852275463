import { newAddPanelAPI } from '#packages/newAddPanelAPI';
import type { SectionData } from '../types';

export const getAllowedChildrenTypes = async (
  allowedSections: SectionData[],
) => {
  const allowedCompTypes: string[] = [];
  await Promise.all(
    allowedSections.map(async ({ sectionName, category }) => {
      const categoryAndSection = await newAddPanelAPI.getCategoryAndSection(
        category,
        sectionName,
      );
      if (categoryAndSection) {
        categoryAndSection.section?.props?.compTypes?.forEach(
          (compType: string) => {
            allowedCompTypes.push(compType);
          },
        );
      }
    }),
  );
  return [...new Set(allowedCompTypes)];
};

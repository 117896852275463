import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import type { EditorAPI } from '#packages/editorAPI';
import type { Product } from './types';
import * as tpa from '#packages/tpa';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as stateManagement from '#packages/stateManagement';
import ascendBi from './bi/ascendBi';
import { tpaUtils } from '#packages/util';
import constants from '#packages/constants';
import type { SendBiFunction } from 'types/bi';

const WIX_STORE_APP_DEF_ID = tpaUtils.getStoresAppDefId();
const WIX_EVENTS_APP_DEF_ID = '140603ad-af8d-84a5-2c80-a0f60cb47351';
const WIX_BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';
const WIX_CHAT_APP_DEF_ID = '14517e1a-3ff0-af98-408e-2bd6953c36a2';

export const PANEL_ORIGIN = 'ASCEND_PANEL';
export const PLAYER_PANEL = 'panels.focusPanels.howToVideoPlayer';

export const openAddPanelForms = (editorAPI: EditorAPI) => {
  editorAPI.store.dispatch(
    stateManagement.panels.actions.updateOrOpenPanel(
      constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
      {
        origin: 'ascend',
        category: addPanelDataConsts.CATEGORIES_ID.CONTACT,
      },
    ),
  );
};

export const openChatSettings = (editorAPI: EditorAPI) => {
  tpa.appMarketHandlers.openApp(editorAPI, {
    params: {
      appDefinitionId: WIX_CHAT_APP_DEF_ID,
    },
  });
};

export const openChatInAppMarket = (editorAPI: EditorAPI) => {
  const appMarketPanelProps = {
    urlParams: {
      openMarketOrigin: PANEL_ORIGIN,
      slug: WIX_CHAT_APP_DEF_ID,
    },
    origin: PANEL_ORIGIN,
  };

  tpa.appMarketTabService.openAppMarketTab(
    appMarketPanelProps,
    editorAPI.panelManager.openPanel,
  );
};

const productsJumpTable = {
  ChatInbox: (editorAPI: EditorAPI) => openChatInAppMarket(editorAPI),
  Chat: (editorAPI: EditorAPI) =>
    editorAPI.dsRead.tpa.app.isInstalled(WIX_CHAT_APP_DEF_ID)
      ? openChatSettings(editorAPI)
      : openChatInAppMarket(editorAPI),
  Forms: (editorAPI: EditorAPI) => openAddPanelForms(editorAPI),
};

export const getIsProductAvailable = (
  product: string,
  editorAPI: EditorAPI,
) => {
  if (product === 'FacebookAds') {
    return editorAPI.dsRead.tpa.app.isInstalled(WIX_STORE_APP_DEF_ID);
  }
  if (product === 'Coupons') {
    return (
      editorAPI.dsRead.tpa.app.isInstalled(WIX_STORE_APP_DEF_ID) ||
      editorAPI.dsRead.tpa.app.isInstalled(WIX_EVENTS_APP_DEF_ID) ||
      editorAPI.dsRead.tpa.app.isInstalled(WIX_BOOKINGS_APP_DEF_ID)
    );
  }
  return true;
};

export const createProduct = (
  type: string,
  name: string,
  editorAPI: EditorAPI,
  bi: { sendBi: SendBiFunction; biBaseParams: AnyFixMe },
): Product => ({
  id: name,
  name: `AscendButton_${type}_Tools_${name}_Title`,
  description: `AscendButton_${type}_Tools_${name}_Text`,

  getLogo: () => ({
    logo: <symbols.symbol name={`ascend${name}Logo`} />,
    displayName: `${name}-product`,
  }),

  onClick: () => {
    bi.sendBi(ascendBi.events.PRODUCT_CLICK, {
      ...bi.biBaseParams,
      tab_name: type,
      product_name: name,
    });
    return productsJumpTable[name as keyof typeof productsJumpTable]
      ? productsJumpTable[name as keyof typeof productsJumpTable](editorAPI)
      : navigateToAscend(editorAPI, name);
  },

  isAvailable: () => getIsProductAvailable(name, editorAPI),
});

const isSiteSaved = (editorAPI: EditorAPI) =>
  !editorAPI.dsRead.generalInfo.isFirstSave();
const isDraft = (editorAPI: EditorAPI) =>
  editorAPI.dsRead.generalInfo.isDraft();

export const navigateToAscend = (editorAPI: EditorAPI, tab: string) => {
  if (!isSiteSaved(editorAPI) || isDraft(editorAPI)) {
    editorAPI.saveManager.openSaveReminderPanel(
      'TOPBAR_SITE_DASHBOARD_SAVE_FIRST_MSG_BODY',
      'TOPBAR_SITE_SETTINGS_SAVE_FIRST_MSG_TITLE',
      'TOPBAR_SETTINGS_BUTTON_SAVE',
    );
  } else {
    editorAPI.account.openSettings({ tab });
  }
};

export const openAscendPackagePicker = (editorAPI: EditorAPI, msid: string) => {
  if (!isSiteSaved(editorAPI) || isDraft(editorAPI)) {
    editorAPI.saveManager.openSaveReminderPanel(
      'TOPBAR_UPGRADE_SAVE_FIRST_TEXT',
      'TOPBAR_SITE_SETTINGS_SAVE_FIRST_MSG_TITLE',
      'TOPBAR_SETTINGS_BUTTON_SAVE',
    );
  } else {
    const packagePickerUrl = stateManagement.ascend.selectors.getAscendUrl({
      msid,
      slug: 'editor',
      origin: '6th_button',
    });
    window.open(packagePickerUrl);
  }
};

export const playVideoOnEditor = (editorAPI: EditorAPI, videoId: AnyFixMe) =>
  editorAPI.panelManager.openPanel(
    PLAYER_PANEL,
    {
      videoId,
      origin: PANEL_ORIGIN,
    },
    true,
  );

// @ts-nocheck
import _ from 'lodash';
import * as core from '#packages/core';
const THEME_STYLE_ADD_PANEL = {
  'wysiwyg.viewer.components.MatrixGallery': ['mg2', 'mg3'],
  'wysiwyg.viewer.components.SlideShowGallery': ['ssg3'],
  'wysiwyg.viewer.components.SliderGallery': ['sg2'],
  'wysiwyg.viewer.components.PaginatedGridGallery': ['pagg2', 'pagg3'],

  'wysiwyg.viewer.components.SiteButton': ['b1', 'b2', 'b3', 'b4'],
  'wysiwyg.viewer.components.PopupCloseTextButton': ['b1', 'b2', 'b3', 'b4'],

  'mobile.core.components.Container': ['c1', 'c2', 'c3', 'c4'],
  'wysiwyg.viewer.components.BgImageStrip': ['bgis1', 'bgis2'],

  'wysiwyg.viewer.components.FiveGridLine': ['hl1', 'hl2', 'hl3', 'hl4'],
  fiveGridLineFullWidth: ['hl1', 'hl2', 'hl3', 'hl4'],
  'wysiwyg.viewer.components.VerticalLine': ['vl1', 'vl2', 'vl3', 'vl4'],

  'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer': [
    'SingleAudioPlayer_1',
  ],
  'wysiwyg.viewer.components.AudioPlayer': ['ap1', 'ap2'],
  'wixui.MusicPlayer': ['MusicPlayer_1'],

  'wysiwyg.viewer.components.WFacebookLike': ['fbl1'],
  'wysiwyg.viewer.components.WGooglePlusOne': ['gp1'],
  'wysiwyg.viewer.components.WTwitterTweet': ['twt1'],

  'wysiwyg.viewer.components.ContactForm': ['cf1', 'cf2'],
  'wysiwyg.viewer.components.DynamicContactForm': ['cf1', 'cf2'],
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': [
    'SubscribeForm_1',
  ],
  'wysiwyg.viewer.components.GoogleMap': ['gm1'],
  'wysiwyg.viewer.components.mobile.TinyMenu': ['tm1', 'tm2'],

  'wysiwyg.viewer.components.HorizontalMenu': ['rt1', 'rt2', 'rt3'],
  'wysiwyg.viewer.components.menus.DropDownMenu': ['ddm1', 'ddm2', 'ddm3'],
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': [
    'VerticalMenu_1',
  ],
  'wysiwyg.viewer.components.InlinePopupToggle': [
    'inlinePopupToggleSkin16',
    'inlinePopupToggleSkin4',
  ],
  'wysiwyg.viewer.components.MenuToggle': [
    'inlinePopupToggleSkin16',
    'inlinePopupToggleSkin4',
  ],

  'wysiwyg.viewer.components.WPhoto': ['wp1', 'wp2', 'wp3', 'wp4'],
  'wysiwyg.viewer.components.Video': ['v1'],
  'wysiwyg.viewer.components.FooterContainer': ['fc1', 'fc2', 'fc3'],
  'wysiwyg.viewer.components.HeaderContainer': ['hc1', 'hc2'],
  'wysiwyg.viewer.components.ScreenWidthContainer': [
    'sc1',
    'sc2',
    'sc3',
    'sc4',
  ],
  pageContainer: ['p1', 'p2', 'p3'],
  'wixui.StylableButton': ['stButton1', 'stButton2', 'stButton3', 'stButton4'],
  'wixui.StylableHorizontalMenu': ['stMenu1', 'stMenu2', 'stMenu3'],

  WRichTextStyle1: ['txtNew'],
  WRichTextStyle2: ['txtNew'],
  WRichTextStyle3: ['txtNew'],
  WRichTextStyle4: ['txtNew'],
  WRichTextStyle5: ['txtNew'],
  WRichTextStyle6: ['txtNew'],
};

export default {
  getComponentThemeStyles(compName, themeModule, componentModule) {
    const styleIds = THEME_STYLE_ADD_PANEL[compName];
    const styleObjects = _.without(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(styleIds, (styleId) =>
        core.styleManager.getSystemStyleById(
          themeModule,
          componentModule,
          styleId,
          compName,
        ),
      ),
      undefined,
    );
    const uniqueThemeStyleArr = [];
    /*remove duplicate themed styles*/
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(styleObjects, function (styleObject) {
      if (_.isEmpty(uniqueThemeStyleArr)) {
        uniqueThemeStyleArr.push(styleObject);
      } else {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        const isEqualToUniqueStyle = _.find(
          uniqueThemeStyleArr,
          (uniqueStyleObj) =>
            core.styleManager.isEqualWithoutTypeConsideration(
              styleObject,
              uniqueStyleObj,
            ),
        );
        if (!isEqualToUniqueStyle) {
          uniqueThemeStyleArr.push(styleObject);
        }
      }
    });
    return uniqueThemeStyleArr;
  },
  getThemeStylesArr(categoryCompTypesArr, themeModule, componentMoudle) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      categoryCompTypesArr,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (res, compType) {
        const styles = this.getComponentThemeStyles(
          compType,
          themeModule,
          componentMoudle,
        );
        if (_.isEmpty(styles)) {
          return res;
        }
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        return res.concat(_.map(styles, 'id'));
      }, this),
      [],
    );
  },
  getPersonalizeStylesArr(themeModule, componentMoudle, categoryCompTypesArr) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      categoryCompTypesArr,
      function (res, compType) {
        const styles = core.styleManager.getUniqueCustomStyleList(
          themeModule,
          componentMoudle,
          compType,
        );
        if (_.isEmpty(styles)) {
          return res;
        }
        return res.concat(styles);
      },
      [],
    );
  },
};

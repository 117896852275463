import React from 'react';

import * as utils from '@wix/santa-editor-utils';
import * as symbols from '@wix/santa-editor-symbols';

import * as util from '#packages/util';

import type { DraggableSlotId } from '#packages/stateManagement';

import connect from './floatingModeBarConnect';
import {
  FloatingModeBarSecondaryActions,
  NewWorkspaceFloatingModeBarSecondaryActions,
} from './floatingModeBarSecondaryActions';

const EXCLUDED_CLASSNAMES_FROM_DRAG = [
  'button-content',
  'control-button',
  'language-mode-bar__btn-auto-translate',
  'symbol-arrowDown',
  'language-mode-bar__btn-language__flag',
  'symbol-googleTranslateMenuItem',
  'tooltip-on-ellipsis-content',
];

export interface DragLimits {
  y?: number[];
  x?: number[];
}

export interface FloatingModeBarStateProps {
  dragLimits: DragLimits;
}

export interface FloatingModeBarOwnProps {
  title: string | JSX.Element;
  className?: string;
  draggableExtentionSlot?: DraggableSlotId;
  secondaryAction?: () => void;
  onExitMode(): void;
  exitModeLabelKey: string;
  secondaryActionContent?: string;
  onOpenHelpCenter?(): void;
  content?: string | JSX.Element;
  onStartDrag?: () => void;
  onEndDrag?: () => void;
  secondaryActionBar?: JSX.Element;
  secondaryActionProgressContent?: string;
  secondaryActionInProgress?: boolean;
  secondaryActionDuration?: number;
  secondaryActionDisabled?: boolean;
}

interface FloatingModeBarProps
  extends FloatingModeBarOwnProps,
    FloatingModeBarStateProps {
  startDrag?: (e: React.MouseEvent, limits: any) => void;
}

interface FloatingModeBarState {
  isDragging: boolean;
}

class FloatingModeBar extends React.Component<
  FloatingModeBarProps,
  FloatingModeBarState
> {
  static displayName = 'FloatingModeBar';

  private isNewWorkspace = util.workspace.isNewWorkspaceEnabled();
  private isSectionsMigrationBannerEnabled =
    util.sections.isSectionsMigrationBannerEnabled();

  state = {
    isDragging: false,
  };

  getDragLimits = () => {
    return this.props.dragLimits;
  };

  renderDefaultSecondaryActionBar() {
    const SecondaryActionBar = this.isNewWorkspace
      ? NewWorkspaceFloatingModeBarSecondaryActions
      : FloatingModeBarSecondaryActions;

    return (
      <SecondaryActionBar
        exitModeLabelKey={this.props.exitModeLabelKey}
        onExitMode={this.props.onExitMode}
        onOpenHelpCenter={this.props.onOpenHelpCenter}
        secondaryActionContent={this.props.secondaryActionContent}
        secondaryAction={this.props.secondaryAction}
        secondaryActionInProgress={this.props.secondaryActionInProgress}
        secondaryActionDuration={this.props.secondaryActionDuration}
        secondaryActionProgressContent={
          this.props.secondaryActionProgressContent
        }
        secondaryActionDisabled={this.props.secondaryActionDisabled}
      />
    );
  }

  render() {
    return (
      <div
        data-aid="floating-mode-bar"
        className={util.cx(
          'floating-mode-bar',
          this.props.className,
          this.state.isDragging ? 'dragging' : '',
          {
            'with-top-bar-banner': this.isSectionsMigrationBannerEnabled,
          },
        )}
        onMouseDown={(e) => {
          const { classList } = e.target as HTMLElement;
          if (
            !EXCLUDED_CLASSNAMES_FROM_DRAG.some(
              (className) =>
                classList.contains(className) || classList.length === 0,
            )
          ) {
            this.setState({ isDragging: true });
            if (this.props.onStartDrag) this.props.onStartDrag();
            this.props.startDrag(e, this.getDragLimits());
          }
        }}
        onMouseUp={() => {
          if (this.props.onEndDrag) this.props.onEndDrag();
          this.setState({ isDragging: false });
        }}
      >
        <div className="floating-mode-bar__main">
          <div className="drag-icon-wrapper">
            <symbols.symbol name="dragHandler" />
          </div>
          <div className="title-section-wrapper">
            {this.props.title && (
              <div className="floating-mode-bar__title">{this.props.title}</div>
            )}
            {this.props.content}
          </div>
        </div>
        <div className="floating-mode-bar__secondary">
          {this.props.secondaryActionBar
            ? this.props.secondaryActionBar
            : this.renderDefaultSecondaryActionBar()}
        </div>
      </div>
    );
  }
}

const Connected = connect(utils.hoc.draggable(FloatingModeBar));

Connected.pure = FloatingModeBar as any;

export default Connected;

import React from 'react';
import { Animation } from '@wix/wix-ui-icons-common/classic-editor';
import styles from './HeaderScrollIcon.scss';
import { cx } from '#packages/util';

export default function HeaderScrollIcon({
  className,
}: {
  className?: string;
}) {
  return <Animation className={cx(className, styles.headerScrollIcon)} />;
}

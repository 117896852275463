// @ts-nocheck
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import gfppDataUtils from './gfppDataUtils';

const componentSelectors = stateManagement.components.selectors;

function getManageItemAction() {
  return {
    label: 'gfpp_mainaction_listsandgrids',
    className: 'manage-items',
    automationId: 'gfpp-button-manage-items',
    isApplied: true,
    onClick(editorAPI, compRefs) {
      let selectedItemIndex = 0;
      let selectedComponents = compRefs;
      if (componentSelectors.isRepeaterItem(compRefs, editorAPI.dsRead)) {
        const repeaterPtr = componentSelectors.getContainer(
          compRefs,
          editorAPI.dsRead,
        );
        const repeaterItems = editorAPI.components.getChildren(repeaterPtr);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        selectedItemIndex = _.findIndex(repeaterItems, compRefs[0]);
        editorAPI.selection.selectComponentByCompRef(repeaterPtr);
        selectedComponents = [repeaterPtr];
      }

      gfppDataUtils.toggleComponentPanel(
        editorAPI,
        'compPanels.panels.Repeater.managePanel',
        {
          selectedComponents,
          selectedItemIndex,
        },
      );
    },
  };
}

export default {
  getManageItemAction,
};

import React from 'react';
import { RichText, Composites } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import { PERMISSIONS_TAB_HOOKS } from '../../../../utils/dataHooks';
import styles from './membersDashboardLink.scss';

interface MembersDashboardLinkProps {
  metaSiteId: string;
}

export const MembersDashboardLink: React.FC<MembersDashboardLinkProps> = ({
  metaSiteId,
}) => {
  const href = `//www.wix.com/dashboard/${metaSiteId}/member-permissions/members/`;

  return (
    <Composites.RichText key="members-links-container">
      <RichText
        key="manage-site-members-container"
        className={styles.controlRichText}
      >
        <Symbol name="goToURLAction" className={styles.symbol} />
        <a
          data-hook={PERMISSIONS_TAB_HOOKS.MEMBERS_DASHBOARD_LINK}
          href={href}
          target="_blank"
        >
          {translate('NewPages_Panel_Permissions_Member_ManageMembers_Link')}
        </a>
      </RichText>
    </Composites.RichText>
  );
};

import * as stateManagement from '#packages/stateManagement';

export const {
  _relativeToStructureLayoutSel,
  relativeToStructureLayoutSel,
  relativeToStructureOrigLayoutSel,
} = stateManagement.stateMapperArgsSelectors.layoutSels;

export type RelativeToStructureLayout = ReturnType<
  typeof relativeToStructureLayoutSel
>;

import React, { type RefObject } from 'react';
import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import * as CompPanelInfra from '#packages/compPanelInfra';
import * as helpIds from '#packages/helpIds';
import * as util from '#packages/util';
import TextStylingSection from './textStylingSection';

import { mapStateToProps, mapDispatchToProps } from './textSettingsPanelMapper';

interface ITextSettingsPanelProps {
  frameProps: Object;
  themeColors: any;
  openColorPicker: () => void;
}

export class TextSettingsPanel extends React.Component<ITextSettingsPanelProps> {
  componentDidMount() {
    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.OPEN_EDIT_TEXT_PANEL,
    );
  }

  selfRef: RefObject<HTMLElement> = React.createRef();
  render() {
    return (
      <CompPanelInfra.compPanelFrame
        title="interactions_text_design_header"
        helpId={helpIds.INTERACTIONS.TEXT_SETTINGS}
        automationId="text-setting-panel"
        contentClass="settings-panel text-settings-panel"
        ref={this.selfRef}
        {...this.props.frameProps}
      >
        <TextStylingSection
          key="text-styling-options"
          openColorPicker={this.props.openColorPicker}
          themeColors={this.props.themeColors}
        />
      </CompPanelInfra.compPanelFrame>
    );
  }
}

const ConnectedTextSettingsPanel = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  stateManagement.components.hoc.compPanel,
)(TextSettingsPanel);

export default ConnectedTextSettingsPanel;

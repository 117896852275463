import { createReduxStore } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';

export interface SwitchLayoutStore {}

export interface OriginalLayout {
  compRef: CompRef | null;
  snapShot: string | null;
  id: string;
  typeMapping: any;
}

export interface LayoutWithMetadata {
  id: string;
  preset: any;
  isPaasPreset: boolean;
}

export interface BiData {
  component_id: string;
  panel_correlation_id: string;
  section_correlation_id: string;
  origin: string;
  panel_name: string;
  panel_open_time: Date;
  is_designer: boolean;
}

export interface SelectedLayoutHistory {
  selectionHistoryPoints: string[];
  selectionHistoryIndex: number;
}

export interface SwitchLayoutState {
  suggestions: {
    suggestionsCount: number;
    bruteForceDisqualifications: any;
    filteredPaasPresetsCount: number;
    times: {
      suggestionTime: number;
      renderFirstSwitch: number;
      renderAllSwitch: number;
      renderPAAS: number;
    };
  };
  isLoading: boolean;
  isSelecting: boolean;
  layoutOptions: Array<LayoutWithMetadata>;
  originalLayout: OriginalLayout;
  selectedLayoutId: string;
  isSwitchLayoutPanelOpen: boolean;
  biData: BiData;
  selectedLayoutHistory: SelectedLayoutHistory;
  isFirstTime: boolean;
}

const shouldUpdateSelectedLayoutHistoryList = (
  selectedLayoutHistory: SelectedLayoutHistory,
): string[] => {
  const { selectionHistoryPoints, selectionHistoryIndex } =
    selectedLayoutHistory;
  if (selectionHistoryIndex + 1 < selectionHistoryPoints.length) {
    const updatedList = selectionHistoryPoints.filter((_, i) => {
      return i <= selectionHistoryIndex;
    });
    return updatedList;
  }
  return selectionHistoryPoints;
};

const updateSelectedLayoutHistory = (
  currentSelectedLayoutHistory: SelectedLayoutHistory,
  selectedLayoutId: string,
  index?: number,
): SelectedLayoutHistory => {
  const updatedSelectedLayoutHistoryList =
    shouldUpdateSelectedLayoutHistoryList(currentSelectedLayoutHistory);
  return {
    selectionHistoryPoints:
      index === undefined
        ? [...updatedSelectedLayoutHistoryList, selectedLayoutId]
        : currentSelectedLayoutHistory.selectionHistoryPoints,
    selectionHistoryIndex:
      index ?? currentSelectedLayoutHistory.selectionHistoryIndex + 1,
  };
};

const getInitialState = (): SwitchLayoutState => ({
  suggestions: {
    suggestionsCount: 0,
    bruteForceDisqualifications: {},
    filteredPaasPresetsCount: 0,
    times: {
      suggestionTime: 0,
      renderFirstSwitch: 0,
      renderAllSwitch: 0,
      renderPAAS: 0,
    },
  },
  isLoading: false,
  isSelecting: false,
  layoutOptions: [],
  originalLayout: {
    compRef: null,
    snapShot: null,
    id: null,
    typeMapping: null,
  },
  selectedLayoutId: undefined,
  isSwitchLayoutPanelOpen: false,
  biData: {
    component_id: null,
    section_correlation_id: null,
    panel_correlation_id: null,
    origin: null,
    panel_name: null,
    panel_open_time: null,
    is_designer: null,
  },
  selectedLayoutHistory: {
    selectionHistoryPoints: [],
    selectionHistoryIndex: -1,
  },
  isFirstTime: true,
});

export const SwitchLayoutStore = createReduxStore({
  getInitialState,
  selectors: {
    getSuggestions: (state: SwitchLayoutState) => state.suggestions,
    getLayoutOptions: (state: SwitchLayoutState) => state.layoutOptions,
    getIsLoading: (state: SwitchLayoutState) => state.isLoading,
    getIsSelecting: (state: SwitchLayoutState) => state.isSelecting,
    getOriginalLayout: (state: SwitchLayoutState) => state.originalLayout,
    getSelectedLayoutId: (state: SwitchLayoutState) => state.selectedLayoutId,
    getIsSwitchLayoutPanelOpen: (state: SwitchLayoutState) =>
      state.isSwitchLayoutPanelOpen,
    getBiData: (state: SwitchLayoutState) => state.biData,
    getSelectedLayoutHistory: (state: SwitchLayoutState) =>
      state.selectedLayoutHistory,
    getIsFirstTime: (state: SwitchLayoutState) => state.isFirstTime,
  },
  actions: {
    setSuggestions: (
      state: SwitchLayoutState,
      suggestions: SwitchLayoutState['suggestions'],
    ) => {
      return {
        ...state,
        suggestions,
      };
    },
    setLayoutOptions: (
      state: SwitchLayoutState,
      layoutOptions: SwitchLayoutState['layoutOptions'],
    ) => {
      return {
        ...state,
        layoutOptions,
      };
    },
    setIsLoading: (
      state: SwitchLayoutState,
      isLoading: SwitchLayoutState['isLoading'],
    ) => {
      return {
        ...state,
        isLoading,
      };
    },
    setOriginalLayout: (
      state: SwitchLayoutState,
      originalLayout: SwitchLayoutState['originalLayout'],
    ) => {
      return {
        ...state,
        originalLayout,
      };
    },
    setSelectedLayoutId: (
      state: SwitchLayoutState,
      selectedLayoutId: SwitchLayoutState['selectedLayoutId'],
    ) => {
      return {
        ...state,
        selectedLayoutId,
      };
    },
    setIsSelecting: (
      state: SwitchLayoutState,
      isSelecting: SwitchLayoutState['isSelecting'],
    ) => {
      return {
        ...state,
        isSelecting,
      };
    },
    setBiData: (
      state: SwitchLayoutState,
      biData: SwitchLayoutState['biData'],
    ) => {
      return {
        ...state,
        biData,
      };
    },
    setIsSwitchLayoutPanelOpen: (
      state: SwitchLayoutState,
      isSwitchLayoutPanelOpen: SwitchLayoutState['isSwitchLayoutPanelOpen'],
    ) => {
      const selectedLayoutHistory: SelectedLayoutHistory = {
        selectionHistoryPoints: [],
        selectionHistoryIndex: -1,
      };
      return {
        ...state,
        isSwitchLayoutPanelOpen,
        selectedLayoutHistory,
      };
    },
    setSelectedLayoutHistory: (
      state: SwitchLayoutState,
      selectedLayoutId: SwitchLayoutState['selectedLayoutId'],
      index?: number,
    ) => {
      const currentSelectedLayoutHistory = state.selectedLayoutHistory;
      const selectedLayoutHistory = updateSelectedLayoutHistory(
        currentSelectedLayoutHistory,
        selectedLayoutId,
        index,
      );
      return {
        ...state,
        selectedLayoutHistory,
      };
    },
    setIsFirstTime: (
      state: SwitchLayoutState,
      isFirstTime: SwitchLayoutState['isFirstTime'],
    ) => {
      return {
        ...state,
        isFirstTime,
      };
    },
  },
});

import React, { useEffect, useState } from 'react';
import { translate } from '#packages/i18n';
import { cx, hoc } from '#packages/util';
import {
  Composites,
  RichText,
  Search,
  TextLabel,
  TextInput,
  Spacer,
} from '@wix/wix-base-ui';
import { events } from '#packages/coreBi';
import {
  getBusinessTypeByString,
  suggestItemsToOptions,
  useInputWithInputSuggestions,
} from './withSuggestions/';
import { Suggestion } from '#packages/baseUI';
import {
  content,
  DISPLAY_NAME,
  FIELD_NAME_BUSINESS_NAME,
  FIELD_NAME_BUSINESS_TYPE,
  FIELD_NAME_TONE_OF_VOICE,
  TONE_OF_VOICE,
  YES,
} from './constants';
import { MessagePanelFrame } from '../../frames';
import {
  mapDispatchToProps,
  mapStateToProps,
  mergeProps,
} from './changeBusinessTypePanel.mapper';
import type { BiEventDefinition } from 'types/bi';
import type { BusinessTypePanelProps } from './types';
import styles from './changeBusinessTypePanel.scss';
import experiment from 'experiment';
import { biLogger } from '#packages/util';
import {
  contentInjectionSiteBusinessTypeClickSuggestion,
  contentInjectionSiteBusinessTypeBtSelected,
  aiTextGeneratorSettingsChanged,
} from '@wix/bi-logger-editor/v2';
import { smartForm } from '#packages/util';

const {
  changeBusinessType: { openSuggestions, clickSuggestion, changeSubmit },
} = events;

// TODO: move to BaseUI.BusinessTypeInput
export const ChangeBusinessTypePanel: React.FC<BusinessTypePanelProps> = ({
  close,
  sendCloseBI,
  sendOpenBI,
  sendSuggestionBi,
  panelName,
  openHelpCenter,
  businessName,
  businessTypeName,
  saveBusinessType,
  saveBusinessName,
  getContentManager,
  initiateContentManager,
  isBusinessTypeValid,
  onBusinessDataUpdated,
  saveToneOfVoiceToSitePreferences,
  initToneOfVoice,
  showToneSuggestions,
  sessionId,
  origin,
}) => {
  const businessTypeInput = useInputWithInputSuggestions(
    businessTypeName,
    getContentManager,
    initiateContentManager,
  );
  const [isBusinessTypeFocused, setIsBusinessTypeFocused] = useState(false);
  const businessNameControl = smartForm.useSmartField<string>(businessName, [
    smartForm.requiredValidator(),
  ]);
  const [toneOfVoice, setToneOfVoice] = useState(initToneOfVoice);
  const [initialPanelParams, setInitialPanelParams] = useState<{
    businessType: string;
    businessName: string;
    toneOfVoice: string;
  } | null>(null);

  const showBusinessName = experiment.isOpen('se_createSectionWithAi');

  useEffect(() => {
    if (initialPanelParams) return;

    setInitialPanelParams({
      businessType: businessTypeInput.value,
      businessName: businessNameControl.value,
      toneOfVoice,
    });
  }, [
    businessTypeInput.value,
    businessNameControl.value,
    toneOfVoice,
    initialPanelParams,
  ]);

  useEffect(() => {
    sendOpenBI();
  }, [sendOpenBI]);

  useEffect(() => {
    if (
      isBusinessTypeFocused &&
      businessTypeInput.suggestions?.results?.length
    ) {
      sendSuggestionBi(openSuggestions, FIELD_NAME_BUSINESS_TYPE);
    }
  }, [
    sendSuggestionBi,
    isBusinessTypeFocused,
    businessTypeInput.suggestions?.results,
  ]);

  const handleHelp = () => {
    openHelpCenter(content.helpId);
  };

  const sendSettingsChanged = () => {
    if (businessTypeInput.value !== initialPanelParams.businessType) {
      biLogger.report(
        aiTextGeneratorSettingsChanged({
          fieldName: FIELD_NAME_BUSINESS_TYPE,
          mandatoryField: YES,
          newValue: businessTypeInput.value,
          sessionId,
          panelType: origin,
        }),
      );
    }
    if (businessNameControl.value !== initialPanelParams.businessName) {
      biLogger.report(
        aiTextGeneratorSettingsChanged({
          fieldName: FIELD_NAME_BUSINESS_NAME,
          mandatoryField: YES,
          newValue: businessNameControl.value,
          sessionId,
          panelType: origin,
        }),
      );
    }

    if (toneOfVoice !== initialPanelParams.toneOfVoice) {
      biLogger.report(
        aiTextGeneratorSettingsChanged({
          fieldName: FIELD_NAME_TONE_OF_VOICE,
          mandatoryField: YES,
          newValue: toneOfVoice,
          sessionId,
          panelType: origin,
        }),
      );
    }
  };

  const handleSubmit = async () => {
    const businessType = getBusinessTypeByString(
      businessTypeInput.value,
      businessTypeInput.suggestions,
    );

    if (isBusinessTypeValid(businessType)) {
      sendSuggestionBi(
        changeSubmit,
        FIELD_NAME_BUSINESS_TYPE,
        businessType,
        businessTypeInput.value,
      );
      await saveBusinessType(businessType);
    }

    if (showBusinessName) {
      sendSuggestionBi(
        changeSubmit,
        FIELD_NAME_BUSINESS_NAME,
        null,
        businessNameControl.value,
      );
      await saveBusinessName(businessNameControl.value);
    }

    if (showToneSuggestions) {
      saveToneOfVoiceToSitePreferences(toneOfVoice);

      biLogger.report(
        contentInjectionSiteBusinessTypeBtSelected({
          business_type: JSON.stringify(businessTypeName),
          origin,
          fieldName: FIELD_NAME_TONE_OF_VOICE,
          newValue: toneOfVoice,
        }),
      );
    }

    onBusinessDataUpdated?.({
      businessType,
      businessName: businessNameControl.value,
    });

    sendSettingsChanged();

    close();
  };

  const handleSuggest = (
    businessTypeName: string,
    event: BiEventDefinition,
  ) => {
    const businessType = getBusinessTypeByString(
      businessTypeName,
      businessTypeInput.suggestions,
    );

    businessTypeInput.setValue(businessTypeName);
    sendSuggestionBi(
      event,
      FIELD_NAME_BUSINESS_TYPE,
      businessType,
      businessTypeName,
    );
  };

  const handleCancel = (origin: string) => {
    sendCloseBI(origin);
    close();
  };

  const handleBusinessTypeFocus = () => {
    setIsBusinessTypeFocused(true);
  };

  const handleBusinessTypeBlur = () => {
    setIsBusinessTypeFocused(false);
  };

  const handleSuggestionSelect = (params: {
    label: string;
    type: string;
    value: string | { value: string; label: string };
  }) => {
    const value =
      typeof params.value === 'string' ? params.value : params.value.value;

    biLogger.report(
      contentInjectionSiteBusinessTypeClickSuggestion({
        business_type: JSON.stringify(businessTypeName),
        origin,
        fieldName: FIELD_NAME_TONE_OF_VOICE,
        newValue: value,
      }),
    );

    if (value !== toneOfVoice) {
      setToneOfVoice(value);
    }
  };

  const shownOptions = suggestItemsToOptions(businessTypeInput.suggestions);
  const businessTypeValid =
    !businessTypeInput.isDirty ||
    shownOptions.includes(businessTypeInput.value);
  const businessNameValid =
    !businessNameControl.isDirty || businessNameControl.isValid;
  const formValid = businessTypeValid && businessNameValid;

  return (
    <MessagePanelFrame
      panelName={panelName}
      illustration={null}
      className={cx(styles.businessTypePanel, 'business-type-panel')}
      title={translate(content.panelTitle)}
      confirmLabel={translate(content.confirm)}
      confirmBtnProps={{ disabled: !formValid }}
      onConfirm={handleSubmit}
      cancelLabel={translate(content.cancel)}
      onCancel={handleCancel}
      sideActions={
        <RichText type="T04">
          {translate(content.modalSubSubtitleText)}
        </RichText>
      }
      cancelOnClickOutside={false}
      dataHook={content.dataHook}
    >
      <div className={styles.businessTypePanelContent}>
        <RichText>
          <span className={styles.subTitleText}>
            {`${translate(content.modalSubtitleText)}  `}
          </span>
          <a href="#" onClick={handleHelp}>
            {translate(content.learnMoreCTA)}
          </a>
        </RichText>
        {showBusinessName ? (
          <div className={styles.fields}>
            <Spacer type="Spacer06" />
            <Composites.TextInputLabeled>
              <TextLabel
                type="T02"
                value="content_injection_change_modal_site_name"
              />
              <TextInput
                value={businessNameControl.value}
                shouldTranslate={false}
                className={styles.multilineInput}
                onChange={(v) => businessNameControl.setValue(v)}
                dataHook="business-name-input"
                placeholder={translate(
                  'ai_template_injection_panel_site_name_placeholder',
                )}
              />
            </Composites.TextInputLabeled>
            <Composites.TextInputLabeled>
              <TextLabel value={content.inputLabel} />
              <Search
                value={businessTypeInput.value}
                options={shownOptions}
                placeholder={content.inputPlaceholder}
                className={styles.businessTypeInput}
                focus={!businessTypeName}
                onItemClick={(name: string) =>
                  handleSuggest(name, clickSuggestion)
                }
                onChange={businessTypeInput.setValue}
                onEnter={(name: string) => handleSuggest(name, clickSuggestion)}
                onFocus={handleBusinessTypeFocus}
                onBlur={handleBusinessTypeBlur}
                clearButton
                onClear={() => businessTypeInput.setValue('')}
              />
            </Composites.TextInputLabeled>
          </div>
        ) : (
          <>
            <TextLabel
              value={content.inputLabel}
              className={styles.searchLabel}
            />
            <Search
              value={businessTypeInput.value}
              options={shownOptions}
              onClear={() => businessTypeInput.setValue('')}
              placeholder={content.inputPlaceholder}
              className={styles.searchInput}
              clearButton
              focus={!businessTypeName}
              onItemClick={(name: string) =>
                handleSuggest(name, clickSuggestion)
              }
              onChange={businessTypeInput.setValue}
              onEnter={(name: string) => handleSuggest(name, clickSuggestion)}
              onFocus={handleBusinessTypeFocus}
              onBlur={handleBusinessTypeBlur}
            />
          </>
        )}
        {showToneSuggestions && (
          <div className={styles.fields}>
            <Composites.TextInputLabeled>
              <TextLabel value="content_injection_change_modal_tone_of_voice_label" />
              <Suggestion
                selectedValue={toneOfVoice}
                onSelect={handleSuggestionSelect}
                controls={TONE_OF_VOICE}
                shouldTranslate={false}
              />
            </Composites.TextInputLabeled>
          </div>
        )}
      </div>
    </MessagePanelFrame>
  );
};

ChangeBusinessTypePanel.displayName = DISPLAY_NAME;

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ChangeBusinessTypePanel);

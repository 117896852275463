import _ from 'lodash';
import PropTypes from 'prop-types';
import constants from '#packages/constants';
import * as baseUI from '#packages/baseUI';
import React from 'react';
import * as Symbols from '@wix/santa-editor-symbols';
import { cx } from '#packages/util';

const { tooltipManager } = baseUI;

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  className: string;
  symbolName: string;
  label: string;
  panelName: string;
  automationId?: string;
  isSelected: boolean;
  isMobileEditor: boolean;
  onClick: FunctionFixMe;
  onHover: FunctionFixMe;
  isMenuCollapsed?: boolean;
  notificationCount: number;
  isFirstPanel?: boolean;
  collapseOnClick?: boolean;
  isButtonOpen?: boolean;
  hintProps?: AnyFixMe;
  tooltips?: Array<AnyFixMe>;
}

export default class extends React.Component<Props> {
  static displayName = 'leftBarButton';

  static propTypes = {
    className: PropTypes.string.isRequired,
    symbolName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    panelName: PropTypes.string,
    automationId: PropTypes.string,
    isSelected: PropTypes.bool.isRequired,
    isMobileEditor: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onHover: PropTypes.func.isRequired,
    isMenuCollapsed: PropTypes.bool,
    notificationCount: PropTypes.number.isRequired,
    isFirstPanel: PropTypes.bool,
    collapseOnClick: PropTypes.bool,
    isButtonOpen: PropTypes.bool,
    hintProps: PropTypes.object,
    tooltips: PropTypes.array,
  };

  state = {
    activeState: null as AnyFixMe,
  };

  containerRef = React.createRef<HTMLLIElement>();

  componentDidMount() {
    this.setButtonTooltips();
  }

  setButtonTooltips = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(this.props.tooltips, (tooltip) => {
      tooltipManager.createTooltip(false, {
        id: tooltip.id,
        targetNode: this.containerRef && this.containerRef.current,
        value: tooltip.value,
        width: tooltip.width,
        alignment: constants.UI.TOOLTIP.ALIGNMENT.LEFT,
        closeTriggers: [constants.UI.TOOLTIP.TRIGGERS.OUTER_CLICK],
        onOpen: this.setState.bind(this, { activeState: 'force-hover' }),
        onClose: this.setState.bind(this, { activeState: null }),
      });
    });
  };

  closeTooltips = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(this.props.tooltips, function (tooltip) {
      if (tooltipManager.isDisplayed(tooltip.id)) {
        tooltipManager.hide(tooltip.id);
      }
    });
  };

  shouldShowNotificationCounter = () => {
    return this.props.notificationCount > 0;
  };

  handleOnClick = () => {
    this.closeTooltips();
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  handleOnHover = () => {
    this.props.onHover(this.props.symbolName);
  };

  getSelectedButtonSvgProps = () => {
    if (this.props.isFirstPanel) {
      return {
        name: 'leftBarButtonEarTop',
        className: cx('ear', 'ear-top'),
      };
    }

    return {
      name: 'leftBarButtonEarMiddle',
      className: cx('ear', 'ear-middle'),
    };
  };

  shouldShowSelectedSvg = () => {
    return this.props.isMenuCollapsed && this.props.isSelected;
  };

  render() {
    return (
      <li
        ref={this.containerRef}
        onClick={this.handleOnClick}
        onMouseEnter={this.handleOnHover}
        className={cx({
          'left-bar-item': true,
          selected: this.props.isSelected,
          'first-panel': this.props.isFirstPanel,
          'wait-for-click': this.props.collapseOnClick,
          [this.state.activeState]: this.state.activeState,
          [this.props.className]: true,
        })}
        data-hook="left-bar-item"
        data-automation-id={this.props.automationId}
      >
        {this.shouldShowSelectedSvg() ? (
          <Symbols.symbol
            key="selectedSvg"
            {...this.getSelectedButtonSvgProps()}
          />
        ) : null}
        <Symbols.symbol name={this.props.symbolName} className="icon" />
        <span className="mask" />
        <span className="text">{this.props.label}</span>
        {this.shouldShowNotificationCounter() ? (
          <span key="notificationCount" className="notifications">
            {this.props.notificationCount}
          </span>
        ) : null}
      </li>
    );
  }
}

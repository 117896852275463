// @ts-nocheck
import PropTypes from 'prop-types';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  RichText,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

const { setSiteUserPreferences } = stateManagement.userPreferences.actions;
const { DONT_SHOW_AGAIN } =
  constants.USER_PREFS.MULTILINGUAL.PUBLISH_SUCCESS_PANEL;

//TYPE WAS GENERATED, remove this line when reviewed
interface LanguagePublishSuccessPanelProps {
  panelName?: string;
  languageName: string;
}

class LanguagePublishSuccessPanel extends React.Component<LanguagePublishSuccessPanelProps> {
  static displayName = 'languagePublishSuccessPanel';

  static propTypes = {
    panelName: PropTypes.string,
    languageName: PropTypes.string.isRequired,
  };

  componentDidMount() {
    this.props.setDontShowAgain(true);
  }

  getTitle = () => {
    return translate('Show_Language_Popup_Success_Title', {
      language: this.props.languageName,
    });
  };

  getCTA = () => {
    return translate('Show_Language_Popup_Success_Text', {
      language: this.props.languageName,
    });
  };

  getButtonText = () => {
    return translate('Show_Language_Popup_Success_Button');
  };

  onClose = () => {
    this.props.closePanel();
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  render() {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        shouldHideHeader={true}
        shouldNotCloseOnBlur={true}
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName="languagePublishSuccess"
        className="language-publish-success-panel popup-medium"
      >
        <Composites.BigPanelHeaderWithIllustration>
          <PanelHeaderCenteredText
            title={this.getTitle()}
            shouldTranslate={false}
            onClose={this.onClose}
          />
          <PanelHeaderIllustration blueStripHeight={92}>
            <symbols.symbol
              name="successMessageIllustration"
              key="illustrationSymbol"
            />
          </PanelHeaderIllustration>
        </Composites.BigPanelHeaderWithIllustration>

        <Composites.PanelContent>
          <Composites.RichText>
            <RichText type="T06">{this.getCTA()}</RichText>
          </Composites.RichText>
        </Composites.PanelContent>

        <Composites.ActionSetHorizontal>
          <Button
            automationId="language-publish-success-panel-confirm-btn"
            onClick={this.onClose}
            className="btn-md"
          >
            <span>{this.getButtonText()}</span>
          </Button>
        </Composites.ActionSetHorizontal>
      </FocusPanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch, { panelName }) => ({
  closePanel: () => {
    dispatch(stateManagement.panels.actions.closePanelByNameAction(panelName));
  },
  setDontShowAgain: (value) =>
    dispatch(setSiteUserPreferences(DONT_SHOW_AGAIN, value)),
});

export default util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  null,
  mapDispatchToProps,
)(LanguagePublishSuccessPanel);

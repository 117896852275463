import { SiteAppsServer } from '@wix/ambassador-site-apps-server/http';
import * as utils from '@wix/santa-editor-utils';

import {
  getAppData,
  getInstalledAppData,
  checkIsAppReadyForAppManager,
  compareAppsByAppManagerPredefinedList,
} from './appMarket.utils';
import { devCenterFacade } from '#packages/serverFacade';

const SiteAppsInstallationService =
  SiteAppsServer('/_api/site-apps/').SiteAppsInstallation();

export const getWixApps = (appsList: string[]) =>
  devCenterFacade
    .appV2Query(
      appsList.map((id) => ({ id })),
      utils.editorModel.languageCode,
    )
    .then(({ apps }) =>
      apps.sort(compareAppsByAppManagerPredefinedList).map(getAppData),
    );

export const getInstalledApps = (authorizationToken: string) =>
  SiteAppsInstallationService({
    Authorization: authorizationToken,
  })
    .getInstalledApps({ lang: utils.editorModel.languageCode })
    .then(({ installedApps }) =>
      installedApps
        .filter(checkIsAppReadyForAppManager)
        .sort((descriptor1, descriptor2) =>
          compareAppsByAppManagerPredefinedList(
            descriptor1.app,
            descriptor2.app,
          ),
        )
        .map(getInstalledAppData),
    );

import { PRICING_PLANS } from '@wix/app-definition-ids';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import { getProtectionType } from '../../../utils/permissions';
import { isPageProtectedWithPassword } from '../../../utils/permissions';
import { membersGroupsApi } from '../../../utils/groupsApi';

import type { EditorAPI } from '@/editorAPI';
import type {
  PermissionsTabDispatchProps,
  PermissionsTabOwnProps,
  PermissionsTabStateProps,
} from './permissionsTab';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { PageSecurity } from 'types/documentServices';
import type { PermissionState } from '../../../utils/constants';
import type { Group } from '../../../utils/groupsApi';

export interface UpdatePageSecurityDataProps {
  pageSecurity: PageSecurity;
  privacyType: PermissionState;
}

export interface PageGroupData {
  groupsForPage: Required<Group>[];
  adminRestrictedPage: string[];
  availableGroups: Required<Group>[];
}

export interface RolesPanelProps {
  panelName: string;
  groups: Required<Group>[];
  headerTitle: string;
  selectedGroups: Required<Group>[];
  applyButtonLabel: string;
  applyButtonCallback: (groups: Group[]) => Promise<void>;
  addNewButtonLabel: string;
  addNewButtonCallback: () => void;
  disableAddNew: boolean;
}

export interface OpenDashboardModalProps {
  path: string;
  onClose: () => void;
}

export const mapStateToProps: MapStateToProps<
  PermissionsTabStateProps,
  PermissionsTabOwnProps
> = ({ editorAPI }, { pageData }) => {
  const getShouldShowProtectionType = () => {
    const dsRead = editorAPI.dsRead;
    const pageRef = dsRead.pages.getFocusedPage();
    const routerRef = dsRead.routers.getRouterRef.byPage(pageRef);

    return (
      !routerRef ||
      dsRead.routers.get.byRef(routerRef)?.config.type !== 'private'
    );
  };

  return {
    shouldShowProtectionType: getShouldShowProtectionType(),
    initialFocusedProtectionType: getProtectionType(editorAPI, pageData),
    isPageProtectedWithPassword: isPageProtectedWithPassword(
      editorAPI,
      pageData,
    ),
    metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
    isSiteSaved:
      !editorAPI.dsRead.generalInfo.isFirstSave() &&
      !editorAPI.dsRead.generalInfo.isDraft(),
    permissions: editorAPI.dsRead.generalInfo.getUserPermissions(),
    isPricingPlansAppInstalled:
      editorAPI.documentServices.tpa.app.isInstalled(PRICING_PLANS),
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  PermissionsTabDispatchProps,
  PermissionsTabOwnProps
> = (dispatch, { pageData }) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const pageId = pageData.id;

  const openHelpCenter = (helpId: string) => {
    editorAPI.panelManager.openHelpCenter(helpId, null, {
      origin: constants.BI.HELP.ORIGIN.PAGES_PANEL,
      learn_more: true,
    });
  };

  const updatePageSecurityData = ({
    pageSecurity,
    privacyType,
  }: UpdatePageSecurityDataProps) => {
    editorAPI.pages.data.update(pageId, { pageSecurity });
    editorAPI.bi.event(
      coreBi.events.topbar.pages.top_bar_PAGES_settings_privacy_changed,
      {
        privacy: privacyType,
        page_id: pageId,
      },
    );
  };

  const updatePagePassword = (password: string) => {
    return editorAPI.pages.permissions.updatePassword(pageId, password);
  };

  const removePagePassword = () => {
    return editorAPI.pages.permissions.removePassword(pageId);
  };

  const fetchPageGroupData = async (pageId: string): Promise<PageGroupData> => {
    const groupsApi = membersGroupsApi(editorAPI);

    const [groupsForPage, adminRestrictedPage, availableGroups] =
      await Promise.all([
        groupsApi.getGroupsForPage({ pageId }),
        groupsApi.getPagesOnlyForAdmin(),
        groupsApi.getAvailableRoles(),
      ]);

    return {
      groupsForPage,
      adminRestrictedPage,
      availableGroups,
    };
  };

  const updateGroupsPermissions = async (groupsToUpdate: Group[]) => {
    const groupsApi = membersGroupsApi(editorAPI);
    await groupsApi.updateGroupsPermissions({ pageId, groupsToUpdate });
  };

  const openRolesPanel = (panelProps: RolesPanelProps) => {
    editorAPI.panelManager.openPanel(
      'panels.toolPanels.rolesManagerPanel',
      panelProps,
      true,
    );
  };

  const openDashboardModal = ({ onClose, path }: OpenDashboardModalProps) => {
    editorAPI.saveManager.saveInBackground(
      () => {
        editorAPI.panelManager.closePanelByName(
          'panels.toolPanels.rolesManagerPanel',
          'out_of_focus',
        );
        const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
        const url = `//www.wix.com/dashboard/${metaSiteId}${path}`;
        const modalProps = {
          url,
          onClose,
          helpId: '',
          isBareMode: true,
          width: '100%',
          height: '100%',
        };
        editorAPI.panelManager.openPanel(
          'tpa.compModals.tpaSettingsModal',
          modalProps,
          true,
        );
      },
      () => {},
      'openRolesPageModal',
    );
  };

  const openPricingPlansInAppMarket = () => {
    const panelProps = {
      urlParams: {
        openAppDefId: PRICING_PLANS,
        openMarketOrigin: 'pageSettings',
        appDefinitionId: PRICING_PLANS,
      },
    };

    editorAPI.panelManager.openPanel(
      'tpa.compPanels.appMarketPanel',
      panelProps,
      true,
    );
  };

  return {
    openDashboardModal,
    openRolesPanel,
    openHelpCenter,
    updatePageSecurityData,
    updatePagePassword,
    removePagePassword,
    fetchPageGroupData,
    updateGroupsPermissions,
    openPricingPlansInAppMarket,
  };
};

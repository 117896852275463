import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import {
  seoPanel,
  emailMarketingPanel,
  googleAdsPanel,
  facebookAdsPanel,
  socialMediaPanel,
  marketingIntegrationsPanel,
} from './panels';

const renderExtraTabs = () => {
  return [
    {
      title: 'Promote_BusinessTools_EmailMarketing_Tab',
      template: emailMarketingPanel,
      index: 0,
    },
    {
      title: 'Promote_BusinessTools_GoogleAds_Tab',
      template: googleAdsPanel,
      index: 1,
    },
    {
      title: 'Promote_BusinessTools_FacebookAds_Tab',
      template: facebookAdsPanel,
      index: 2,
    },
    {
      title: 'Promote_BusinessTools_SEO_Tab',
      template: seoPanel,
      index: 3,
    },
    {
      title: 'Promote_BusinessTools_SocialMediaMarketing_Tab',
      template: socialMediaPanel,
      index: 4,
    },
    {
      title: 'Promote_BusinessTools_MarketingIntegrations_Tab',
      template: marketingIntegrationsPanel,
      index: 5,
    },
  ];
};

const getPromoteSuperApp = () => {
  return {
    id: 'promote',
    appDefId: 'promote',
    appName: 'promote',
    firstTimeTour: {
      templateFlow: [] as AnyFixMe[],
      manuallyInstalledFlow: [] as AnyFixMe[],
      promotionalFlow: [] as AnyFixMe[],
    },
    panel: {
      name: constants.SUPER_APPS.PROMOTE_PANEL_NAME,
      label: translate('Promote_marketing_panel_title'),
      pages: {
        isHidden: true,
      },
      manage: {
        isHidden: true,
      },
      learnMore: {
        isHidden: true,
      },
      addElements: {
        isHidden: true,
      },
    },
    extraTabs: renderExtraTabs(),
    customHeader: () => null as null,
    isAppInstalled: () => true,
  };
};

export default { getPromoteSuperApp };

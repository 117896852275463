// @ts-nocheck
import _ from 'lodash';
import dataProvider from './dataProvider';
import * as stateManagement from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

function fetchApiEndpoint(url, options = {}) {
  const instance = options.instance || '';

  const opts = _.merge(
    {
      headers: {
        'x-wix-scheduler-instance': instance,
        Authorization: instance,
      },
    },
    options,
  );

  return fetch(url, opts)
    .then((response) => response.json())
    .then((response) =>
      response.errorCode ? Promise.reject(response) : Promise.resolve(response),
    );
}

function getBookingsStatus(instance) {
  return fetchApiEndpoint('/_api/bookings-web-user/owner/business/setup', {
    instance,
  });
}

function getSetupStepFromStatus(bookingsStatus) {
  const { SETUP_STEPS } = dataProvider;

  if (!_.isEmpty(bookingsStatus)) {
    if (
      bookingsStatus.fromTemplate &&
      bookingsStatus.numOfOfferings > 0 &&
      !bookingsStatus.modifiedOffering
    ) {
      return SETUP_STEPS.editService;
    }

    if (bookingsStatus.numOfOfferings === 0) {
      return SETUP_STEPS.createService;
    }

    if (!bookingsStatus.reviewedWorkingHours) {
      return SETUP_STEPS.workingHours;
    }

    if (!bookingsStatus.reviewedEmail) {
      return SETUP_STEPS.email;
    }

    if (!bookingsStatus.hasPayments) {
      return SETUP_STEPS.payment;
    }

    return SETUP_STEPS.done;
  }
  return SETUP_STEPS.undefined;
}

export function getPostRegistrationSubCategory(editorAPI: EditorAPI): string {
  const userProfile = stateManagement.userProfile.selectors.getUserProfile(
    editorAPI.store.getState(),
  );
  return userProfile?.post_reg_sub_category?.value ?? null;
}

function getPostRegistrationCategory(editorAPI: EditorAPI): string {
  const userProfile = stateManagement.userProfile.selectors.getUserProfile(
    editorAPI.store.getState(),
  );
  return userProfile?.post_reg_category?.value ?? null;
}

function isPostRegistrationCategoryRelevantForBookings(
  editorAPI: EditorAPI,
): Promise<boolean> {
  const postRegistrationCategory = getPostRegistrationCategory(editorAPI);
  return dataProvider.RELEVANT_POST_REGS.includes(postRegistrationCategory);
}

function shouldShow6thButton(editorAPI: EditorAPI): Promise<boolean> {
  const hasIntroFunnelAppIntent =
    stateManagement.introFunnel.selectors.hasAppIntent(
      editorAPI.store.getState(),
    );
  return (
    hasIntroFunnelAppIntent ||
    isPostRegistrationCategoryRelevantForBookings(editorAPI)
  );
}

export { getSetupStepFromStatus, getBookingsStatus, shouldShow6thButton };

import * as tpa from '#packages/tpa';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export interface WelcomeSectionDispatchProps {
  installApp: (appDefinitionId: string) => Promise<void>;
  openApplicationHelpPanel: (helpId: string) => void;
  notifyApplication: (
    appDefinitionId: string,
    eventName: string,
    params?: object,
  ) => void;
  closeAddPanel: () => void;
}

export const mapDispatchToProps = (
  dispatch: AnyFixMe,
): WelcomeSectionDispatchProps => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    installApp(appDefinitionId) {
      return new Promise(function (resolve, reject) {
        const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
        const biInitiator = tpa.constants.BI.initiator.EDITOR;
        const options = { originType: tpa.constants.BI.type.ADD_APP_ADD_PANEL };
        const callback = (data: AnyFixMe) =>
          data?.success ? resolve(data) : reject(data);
        const platformOrigin = {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AddPanel,
          },
        };
        tpa.services.tpaAddAppService.addApp(
          editorAPI,
          appDefinitionId,
          null,
          null,
          type,
          biInitiator,
          false,
          {},
          options,
          callback,
          platformOrigin,
        );
      });
    },
    openApplicationHelpPanel(helpId) {
      editorAPI.panelManager.openHelpCenter(helpId);
    },
    notifyApplication(appDefinitionId, eventName, params) {
      const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
      if (appData?.applicationId) {
        editorAPI.dsActions.platform.notifyApplication(appData.applicationId, {
          eventType: eventName,
          eventPayload: params || {},
        });
      }
    },
    closeAddPanel() {
      editorAPI.panelManager.closeAllPanelsOfType('leftPanelFrame');
    },
  };
};

import { tpaDynamicPages } from '#packages/stateManagement';
import { BasePublicApi } from '#packages/apilib';
import { appStudioUtils } from '#packages/util';
import type { BlocksScope } from './blocksEntryPoint';

interface BlocksBiData {
  isBlocks: boolean;
  tpa_component_type?: string;
  tpa_session_id?: string;
}

const getBlocksBiData = ({ editorAPI, selection, pages }: BlocksScope) => {
  const compRef = selection.getSelectedComponents()[0];
  const pageId = pages.getCurrentPage()?.id;

  // When running unit tests compRef or pageId can be undefined
  if (!compRef || !pageId) {
    return { isBlocks: false };
  }

  const isBlocks = appStudioUtils.isResponsiveBlocksWidget(
    { dsRead: editorAPI.dsRead },
    compRef,
  );

  let blocksBiData: BlocksBiData = { isBlocks };

  if (isBlocks) {
    blocksBiData = {
      ...blocksBiData,
      tpa_component_type: editorAPI.pages.data.get(pageId)?.tpaPageId,
      tpa_session_id: tpaDynamicPages.selectors.getCurrentPageSessionId(
        editorAPI.store.getState(),
      ),
    };
  }

  return blocksBiData;
};

export class BlocksApi extends BasePublicApi<BlocksScope> {
  getBlocksBiData = this.bindScope(getBlocksBiData);
}

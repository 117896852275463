import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      layout: {
        width: 174,
        height: 136,
        x: 53,
        y: 50,
        scale: 1,
        rotationInDegrees: 270,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'jzll1',
      },
      parent: 'comp-kgujst0z',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        title: '',
        uri: '74f558_169ab73012024017922a614abf15fd7f~mv2.jpg',
        description: '',
        width: 4483,
        height: 2985,
        alt: 'joel-filipe-pfX-GsJMtDY-unsplash.jpg',
        crop: {
          x: 0,
          y: 0,
          width: 3645,
          height: 2971,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'joel-filipe-pfX-GsJMtDY-unsplash.jpg',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'jzll1',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        author: 'studio',
        offsetX: 50,
        offsetY: 10,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqkgwup2',
        recommendedScale: 1,
        recommendedWidth: 180,
        recommendedHeight: 143,
        orderIndex: 0,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
      },
      scopedTransformations: {
        'variants-kgujst17': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'jzll1',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 230,
        height: 32,
        x: 25,
        y: 173,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'jzll1',
      },
      parent: 'comp-kgujst0z',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        text: `<p class="font_7" style="font-size:24px; text-align:center"><span style="font-size:24px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span style="color:#FFFFFF"><span style="font-family:suez one,serif">${translate(
          'interactions_preset_discover_world_title',
        )}</span></span></span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'jzll1',
        },
        brightness: 1,
        packed: false,
        minHeight: 32,
        verticalText: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        author: 'studio',
        offsetX: -5,
        offsetY: 5,
        offsetOrigin: 'leftBottom',
        originalCompId: 'comp-kgqkgwur',
        recommendedScale: 0.78571427,
        recommendedWidth: 186,
        recommendedHeight: 50,
        orderIndex: 1,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
      },
      scopedTransformations: {
        'variants-kgujst17': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'jzll1',
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: -40.59943181818182,
            },
          },
          scale: {
            x: 1.1,
            y: 1.1,
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 205,
        height: 28,
        x: 37,
        y: 225,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'jzll1',
      },
      parent: 'comp-kgujst0z',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        text: `<h6 class="font_6" style="font-size:18px; text-align:center"><span style="font-size: 22px;"><span style="font-family: proxima-n-w01-reg;"><span style="font-weight: normal;"><span style="font-style: normal;"><span style="font-variant: normal;"><span style="color: rgb(66, 55, 55);"><span style="font-family:barlow-medium,barlow,sans-serif;"><span style="font-size:18px"><span style="color:#FFFFFF">${translate(
          'interactions_preset_discover_world_subtitle',
        )}</span></span></span></span></span></span></span></span></span></h6>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'jzll1',
        },
        brightness: 1,
        packed: false,
        minHeight: 18,
        verticalText: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        author: 'studio',
        offsetX: -35,
        offsetY: 20,
        offsetOrigin: 'leftBottom',
        originalCompId: 'comp-kgqkgwut',
        recommendedScale: 1,
        recommendedWidth: 260,
        recommendedHeight: 28,
        orderIndex: 2,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-out',
        duration: 0.2,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
      },
      scopedTransformations: {
        'variants-kgujst17': {
          type: 'TransformData',
          hidden: true,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'jzll1',
          },
        },
      },
      style: 'txtNew',
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
      layout: {
        width: 69,
        height: 24,
        x: 103,
        y: 229,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.LinkBar',
      metaData: {
        pageId: 'jzll1',
      },
      parent: 'comp-kgujst0z',
      data: {
        type: 'ImageList',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        items: [
          {
            type: 'Image',
            metaData: {
              isPreset: false,
              schemaVersion: '2.0',
              isHidden: false,
              pageId: 'jzll1',
            },
            title: 'Twitter',
            uri: '01ab6619093f45388d66736ec22e5885.png',
            width: 200,
            height: 200,
            alt: '',
          },
          {
            type: 'Image',
            metaData: {
              isPreset: false,
              schemaVersion: '2.0',
              isHidden: false,
              pageId: 'jzll1',
            },
            title: 'Instagram',
            uri: '81af6121f84c41a5b4391d7d37fce12a.png',
            width: 200,
            height: 200,
            alt: '',
          },
        ],
      },
      props: {
        type: 'LinkBarProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'jzll1',
        },
        gallery: 'social_icons',
        iconSize: 24,
        spacing: 21,
        bgScale: 1,
        orientation: 'HORIZ',
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        author: 'studio',
        offsetX: 88,
        offsetY: -20,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqkgwuu3',
        recommendedScale: 1,
        recommendedWidth: 85,
        recommendedHeight: 35,
        orderIndex: 3,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
      },
      scopedTransformations: {
        'variants-kgujst17': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'jzll1',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'jzll1',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {
            displayer: {},
          },
        },
        componentClassName: 'wysiwyg.viewer.components.LinkBar',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.LinkBarNoBGSkin',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 280,
    height: 281,
    x: 350,
    y: 109,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'jzll1',
  },
  parent: 'jzll1',
  mobileHints: {
    type: 'MobileHints',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'jzll1',
    },
    author: 'studio',
    originalCompId: 'comp-kgqkgwsm',
    recommendedScale: 1,
    recommendedWidth: 280,
    recommendedHeight: 291,
  },
  variants: {
    'variants-kgujst17': {
      componentId: 'comp-kgujst0z',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'jzll1',
      },
    },
  },
  scopedTransformations: {
    'variants-kgujst17': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'jzll1',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'jzll1',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: '#000000',
        'boxShadowToggleOn-shd': 'false',
        brd: '#999997',
        brw: '0px',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};

import _ from 'lodash';
import constants from '#packages/constants';
import { EditorAPIKey } from '#packages/apis';
import { scroll } from '#packages/util';
import { domMeasurements, preview } from '#packages/stateManagement';

import type { Shell } from '#packages/apilib';
import type { AnimateScrollPosition, ScrollPosition } from 'types/core';

type ScrollListener = (event: MouseEvent) => void;

export function createScrollApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const { store } = editorAPI;

  let scrollPosition: ScrollPosition = {
    scrollTop: 0,
    scrollLeft: 0,
  };

  let scrollListeners: ScrollListener[] = [];

  function getStageScroll(): ScrollPosition {
    return scrollPosition;
  }

  let scrollNode: HTMLElement;

  function _fastScrollTo(newScrollPosition: Partial<ScrollPosition>) {
    if (!scrollNode) {
      scrollNode = document.getElementById(
        constants.ROOT_COMPS.SELECTOR_ID.SCROLLABLE_EDITOR_STAGE,
      );
    }
    if (newScrollPosition.scrollTop !== undefined) {
      scrollNode.scrollTop = newScrollPosition.scrollTop;
    }
    if (scrollNode.scrollLeft !== undefined) {
      scrollNode.scrollLeft = newScrollPosition.scrollLeft;
    }
    registerNewScrollPosition({
      scrollLeft: scrollNode.scrollLeft,
      scrollTop: scrollNode.scrollTop,
    });
  }

  function registerNewScrollPosition(
    newScrollPosition: Partial<ScrollPosition>,
  ) {
    scrollPosition = { ...scrollPosition, ...newScrollPosition };

    editorAPI.dsActions.site.setScroll(
      newScrollPosition.scrollLeft,
      newScrollPosition.scrollTop,
    );
  }

  function scrollTo(newScrollPosition: Partial<ScrollPosition>) {
    editorAPI.updateState({ scrollTo: newScrollPosition });
  }

  function animateScrollTo(
    position: AnimateScrollPosition,
    duration: number,
    onComplete: () => void,
  ) {
    editorAPI.updateState({
      animateScrollTo: {
        position,
        duration,
        onComplete,
      },
    });
  }

  function clearScrollTo(): void {
    editorAPI.updateState({
      scrollTo: null,
      animateScrollTo: null,
    });
  }

  function addScrollListener(listener: ScrollListener) {
    scrollListeners.push(listener);
  }

  function removeScrollListener(listener: ScrollListener) {
    scrollListeners = scrollListeners.filter((current) => current !== listener);
  }

  function resetScrollListeners() {
    scrollListeners = [];
  }

  const handleStageScroll = _.debounce((event: MouseEvent) => {
    scrollListeners.forEach((listener) => {
      listener(event);
    });
    store.dispatch(domMeasurements.actions.initDomMeasurements());
  }, 300);

  async function applyPreviewScrollToEditorScroll(): Promise<void> {
    if (!preview.selectors.getPreviewMode(store.getState())) {
      await scroll.waitForAnimationScrollEnd(() =>
        editorAPI.dsRead.site.getScroll(),
      );

      const siteScroll = editorAPI.dsRead.site.getScroll();
      scrollTo({ scrollLeft: siteScroll.x, scrollTop: siteScroll.y });
    }
  }

  const isScrollingEnabled = () =>
    editorAPI.dsRead?.site.isScrollingEnabled() ?? true;

  return {
    get: getStageScroll,
    _fastScrollTo,
    registerNewScrollPosition,
    scrollTo,
    animateScrollTo,
    clearScrollTo,
    handleStageScroll,

    addScrollListener,
    removeScrollListener,
    resetScrollListeners,
    applyPreviewScrollToEditorScroll,

    isEnabled: isScrollingEnabled,
  };
}

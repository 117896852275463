import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  ImageUploadToStageApiKey,
  TextUploadToStageApiKey,
} from '#packages/apis';
import {
  PasteClipboardDataToStageApi,
  bootstrap,
} from './pasteClipboardDataToStageApi';
import { PasteClipboardDataToStageApiKey } from './publicApiKey';

/**
 * This package handles data pasting by iterating through the
 * apis array and call the 'validate' and 'handleUpload'
 * methods of every API object inside of the array.
 * When adding a new paste handler make sure to use the same interface structure:
 * validate(event: ClipboardEvent): boolean;
 * handleUpload(event: ClipboardEvent): void;
 */

export class PasteClipboardDataToStageScope extends BaseEntryScope {
  pasteHandlers = [
    this.useDependency(TextUploadToStageApiKey),
    this.useDependency(ImageUploadToStageApiKey),
  ];
}

export const PasteClipboardDataToStageEntryPoint = createEntryPoint({
  name: 'PasteClipboardDataToStage',
  Scope: PasteClipboardDataToStageScope,
  publicApi({ contributeApi }) {
    contributeApi(
      PasteClipboardDataToStageApiKey,
      PasteClipboardDataToStageApi,
    );
  },
  initialize(scope: PasteClipboardDataToStageScope) {
    bootstrap(scope);
  },
});

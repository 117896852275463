interface PageMigrationCounter {
  /**
   * Increments the migration count for a given pageId.
   *
   * @param {string} pageId - The ID of the page.
   */
  incrementMigrationCount: (pageId: string) => void;

  /**
   * Retrieves the migration count for a given pageId.
   *
   * @param {string} pageId - The ID of the page.
   * @returns {number} The migration count, or null if not found.
   */
  getMigrationCount: (pageId: string) => number;
}

const MIGRATION_COUNTS_KEY = 'pageMigrationCounts';

const pageFailedMigrationCounter: PageMigrationCounter = {
  incrementMigrationCount: (pageId: string): void => {
    try {
      const storedCountsStr = sessionStorage.getItem(MIGRATION_COUNTS_KEY);
      const storedCounts = storedCountsStr ? JSON.parse(storedCountsStr) : {};

      storedCounts[pageId] = (storedCounts[pageId] ?? 0) + 1;

      sessionStorage.setItem(
        MIGRATION_COUNTS_KEY,
        JSON.stringify(storedCounts),
      );
    } catch (error) {
      console.error('Error updating migration count:', error);
    }
  },

  getMigrationCount: (pageId: string): number => {
    try {
      const storedCountsStr = sessionStorage.getItem(MIGRATION_COUNTS_KEY);
      if (storedCountsStr === null) {
        return 0;
      }

      const storedCounts = JSON.parse(storedCountsStr);
      return storedCounts[pageId] ?? 0;
    } catch (error) {
      console.error('Error retrieving migration count:', error);
      return 0;
    }
  },
};

export { pageFailedMigrationCounter };

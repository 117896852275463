import { sections } from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const MOBILE_MENU_AS_CONTAINER_ID = 'MENU_AS_CONTAINER';
const { isInInteractionMode } = stateManagement.interactions.selectors;

export default {
  showLegacyFixedPosition: (editorAPI: EditorAPI, compRef: CompRef) => {
    const isHeaderContainer = editorAPI.utils.isSameRef(
      editorAPI.components.getContainer(compRef),
      editorAPI.dsRead.siteSegments.getHeader(),
    );
    return (
      !editorAPI.zoomMode.isStageZoomMode() &&
      !isInInteractionMode(editorAPI.store.getState()) &&
      editorAPI.components.layout.canBeFixedPosition(compRef) &&
      isHeaderContainer
    );
  },
  containable(
    editorAPI: EditorAPI,
    compRef: CompRef,
    potentialParent?: CompRef,
  ) {
    if (!compRef) return false;

    const masterPage = editorAPI.dsRead.siteSegments.getSiteStructure();
    const header = editorAPI.siteSegments.getHeader();

    if (sections.isSectionsEnabled()) {
      if (!potentialParent) return false;
      const isHeaderOrItsChild = editorAPI.components.isDescendantOfComp(
        potentialParent,
        header,
      );

      return isHeaderOrItsChild && potentialParent.id !== masterPage.id;
    }

    return (
      compRef.id !== MOBILE_MENU_AS_CONTAINER_ID &&
      editorAPI.components.getPage(compRef).id === masterPage.id
    );
  },
};

export const PANEL_CONTENT = {
  title: 'FTE_Tour_partners_update_modal_title',
  subtitle: 'FTE_Tour_partners_update_modal_subtitle',
  items: [
    'FTE_Tour_partners_update_modal_bullet1_text',
    'FTE_Tour_partners_update_modal_bullet2_text',
    'FTE_Tour_partners_update_modal_bullet3_text',
  ],
  primaryButtonText: 'FTE_Tour_partners_update_modal_button',
  secondaryButtonText: 'FTE_Tour_partners_update_modal_learn_more_cta',
  footnote: 'FTE_Tour_partners_update_modal_note',
};

export const HELP_ID = 'bcacd69d-0637-484b-a5ca-50159fc3c6bb';

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as customizationControls from './customizationControls/customizationControls';
import React from 'react';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'Customization',
  propTypes: {
    name: PropTypes.string.isRequired,
    valueLink: PropTypes.shape({
      value: PropTypes.any,
      requestChange: PropTypes.func,
    }),
  },
  mixins: [util.propTypesFilterMixin],
  getCustomizationControl() {
    const customizationControl = customizationControls[this.props.name];
    if (customizationControl === undefined) {
      throw new Error(`an adapter for ${this.props.name} is not available`);
    }

    return customizationControl;
  },
  shouldTranslate() {
    let booleanValue = true;
    if (this.props.shouldTranslate) {
      booleanValue = { true: true, false: false }[this.props.shouldTranslate];
    }
    return booleanValue;
  },
  render() {
    const CustomizationControl = this.getCustomizationControl();

    return (
      <CustomizationControl
        valueLink={this.props.valueLink}
        shouldTranslateLabel={this.shouldTranslate()}
        {...this.filteredProps()}
      />
    );
  },
});

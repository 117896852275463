import { BaseEntryScope, createEntryPoint, Hooks } from '#packages/apilib';
import { init } from './init';
import { SiteSegmentMediaBackgroundPublicApi } from './api';
import { SiteSegmentMediaBackgroundApiKey } from './publicApiKey';
import {
  EditorCoreApiKey,
  ComponentsApiKey,
  EditorAPIKey,
  SectionsApiKey,
  EditorParamsApiKey,
} from '#packages/apis';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  components = this.useDependency(ComponentsApiKey);
  sections = this.useDependency(SectionsApiKey);
  editorParamsAPI = this.useDependency(EditorParamsApiKey);

  siteSegmentMediaBgMigrationHook = this.declareApi(Hooks.createPromiseHook);
}

export const SiteSegmentMediaBackgroundEntryPoint = createEntryPoint({
  name: 'SiteSegmentMediaBackground',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(
      SiteSegmentMediaBackgroundApiKey,
      SiteSegmentMediaBackgroundPublicApi,
    );
  },
  initialize(scope) {
    init(scope);
  },
});

import constants from '#packages/constants';
import type { CompRef, DocumentServicesObject } from 'types/documentServices';

export function isAnchor(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) {
  return (
    documentServices.components.getType(compRef) === constants.COMP_TYPES.ANCHOR
  );
}

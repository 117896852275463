import {
  mapDispatchToStoresWelcomeSectionProps,
  type WixStoreAddPanelDispatchProps,
} from '#packages/wixStore';
import { wrapWithPropsTransformer } from '../utils';

export interface StoresWelcomeProps {
  storesMainAction: () => void;
  storesSecondaryAction: () => void;
}

export const storesWelcomeMapper = wrapWithPropsTransformer<
  WixStoreAddPanelDispatchProps,
  StoresWelcomeProps
>(mapDispatchToStoresWelcomeSectionProps, (props) => ({
  storesMainAction: props.installApp,
  storesSecondaryAction: props.openAppMarket,
}));

import React, { Component } from 'react';
import _ from 'lodash';

import * as util from '#packages/util';
import * as UI from '#packages/baseUI';
import constants from '#packages/constants';

import SectionWrapper from '../sectionWrapper';

export interface ListItem {
  structure?: {
    componentType?: string;
  };
  id: string;
  desc: string;
  image: string;
  title: string;
  symbol?: string;
  helpId?: string;
  tooltip?: string;
  compType?: string;
  category?: string;
  openSource?: string;
  biItemName?: string;
  learnMoreText?: string;
  disabledImage?: string;
  shouldTranslate?: boolean;
  translation?: {
    submitButton: string;
  };
  path?: string;
}

export interface ListSectionProps {
  title: string;
  automationId: string;
  callback: (item: ListItem) => void;
  shouldBeDisabled?: (item: ListItem) => boolean;
  getDisabledToolTip?: (item: ListItem) => string;
  children?: React.ReactNode;
  props: {
    items: ListItem[];
    class?: string;
  };
  largeTooltipContent?: string;
  onLargeTooltipHovered?: () => void;
  openHelpCenter: (
    helpId: string,
    props?: AnyFixMe,
    biParams?: AnyFixMe,
  ) => void;
}

export default class ListSection extends Component<ListSectionProps> {
  getItemImageUrl(item: AnyFixMe) {
    let { image } = item;
    if (this.isDisabled(item)) {
      image = item.disabledImage;
    }
    return util.media.getMediaUrl(image);
  }

  getTooltip(item: AnyFixMe) {
    return !this.props.largeTooltipContent && this.isDisabled(item)
      ? this.props.getDisabledToolTip(item)
      : item.tooltip;
  }

  isDisabled(item: AnyFixMe) {
    return (
      !!item.disabledImage &&
      this.props.shouldBeDisabled &&
      this.props.shouldBeDisabled(item)
    );
  }

  handleLearnMoreClick = (item: ListItem) => {
    const biParams = {
      component: this.props.title,
      control_name: item.title,
      learn_more: true,
      origin: constants.BI.HELP.ORIGIN.ADD_PANEL,
    };

    this.props.openHelpCenter(item.helpId, null, biParams);
  };

  getItemProps(item: AnyFixMe) {
    return {
      componentType: item?.structure?.componentType,
      onLargeTooltipHovered: this.props.onLargeTooltipHovered,
      url: this.getItemImageUrl(item),
      title: item.title,
      desc: item.desc,
      symbol: item.symbol,
      shouldTranslate: item.shouldTranslate,
      callback: this.props.callback.bind(null, item),
      largeTooltipContent: this.props.largeTooltipContent,
      disabled: this.isDisabled(item),
      tooltip: this.getTooltip(item),
      onLearnMoreClick: () => this.handleLearnMoreClick(item),
      learnMoreText: item.learnMoreText,
    };
  }

  render() {
    return (
      <SectionWrapper
        className={this.props.props.class || 'list-section'}
        {...this.props}
      >
        <div data-automation-id={this.props.automationId} className="items">
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
          {_.map(this.props.props.items, (item, itemIndex) => (
            <UI.listItemWithImage
              key={item.title + itemIndex}
              {...this.getItemProps(item)}
            />
          ))}
        </div>
      </SectionWrapper>
    );
  }
}

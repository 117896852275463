import * as stateManagement from '#packages/stateManagement';
import { DATA_BINDING } from '@wix/app-definition-ids';

import type { EditorAPI } from '#packages/editorAPI';
import type { DataBindingAppState } from './types';
import type { EditorState } from '#packages/stateManagement';

const defaultState: DataBindingAppState = {
  schemas: undefined,
  dataMode: undefined,
  actions: {},
  actionsBySchema: {},
  openDataPanelOnStart: undefined,
  premium: undefined,
  premiumLimits: undefined,
  isPremiumBusinessElite: undefined,
  collectionItemCounts: undefined,
  migratedFormCollectionsExist: false,
  enterpriseBrandName: undefined,
  premiumPlanName: undefined,
};

export const getAppState = (editorAPI: EditorAPI): DataBindingAppState =>
  selectAppState(editorAPI.store.getState());

export const selectAppState = (state: EditorState): DataBindingAppState => {
  return (
    stateManagement.appsStore.selectors.getAppStore(state, DATA_BINDING) ||
    defaultState
  );
};

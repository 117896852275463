// @ts-nocheck
import _ from 'lodash';
import * as core from '#packages/core';
import tpaPages from './tpaPages';
import blogPages from './blogPages';

function getTpaSubPagesList(editorAPI, tpaApplicationId, pagesDataList) {
  return tpaPages.getSpecialPagesDataListByTPAId(
    editorAPI,
    tpaApplicationId,
    pagesDataList,
  );
}

function getBlogSubPagesList(editorAPI, pagesDataList) {
  return blogPages.getSpecialPagesDataList(
    editorAPI,
    pagesDataList || editorAPI.dsRead.pages.getPagesData(),
  );
}

export default {
  isSpecialPage(editorAPI, pageData, __) {
    return (
      core.utils.blogTypeUtils.isBlogSpecialPage(pageData) ||
      core.utils.tpaTypeUtils.isTPASpecialPage(editorAPI.dsRead, pageData) ||
      core.utils.dynamicTypeUtils.isDynamicPage(editorAPI, pageData)
    );
  },

  isSubPageMenuItem(editorAPI, pageData) {
    return (
      this.isSpecialPage(editorAPI, pageData) &&
      !core.utils.tpaTypeUtils.isPageNotVisibleInPagesMenu(
        editorAPI.dsRead,
        pageData,
      )
    );
  },

  getSectionPagesIds(editorAPI, pagesData) {
    const tpaSectionPagesDataList = tpaPages.getSectionPagesDataList(
      editorAPI,
      pagesData,
    );
    const blogSectionPagesDataList = blogPages.getSectionPagesDataList(
      editorAPI,
      pagesData,
    );

    return _.union(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(tpaSectionPagesDataList, 'id'),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(blogSectionPagesDataList, 'id'),
    );
  },

  getSpecialPagesListBySectionPageOrBySibling(
    editorAPI,
    pageData,
    pagesDataList,
  ) {
    if (pageData) {
      if (core.utils.blogTypeUtils.isBlog(editorAPI, pageData)) {
        return getBlogSubPagesList(editorAPI, pagesDataList);
      }
      if (core.utils.tpaTypeUtils.isTPA(pageData)) {
        return getTpaSubPagesList(
          editorAPI,
          pageData.tpaApplicationId,
          pagesDataList,
        );
      }
    }
    return [];
  },
};

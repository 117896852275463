import { BasePublicApi } from '#packages/apilib';

import { runSectionMigration } from './runSectionMigration';
import { runAnchorMigration } from './runAnchorMigration';
import { getUnlabeledPages } from './getUnlabeledPages';
import { refreshWithForceMigration } from './refreshWithForceMigration';
import { getPageGrouping, getGroupingByPrediction } from './getPageGrouping';

import type { SectionsMigrationScope as Scope } from '../scope';

function isMigrationInProgress(scope: Scope): boolean {
  return scope.migrationStatus.isInProgress();
}

function hasMigrationProcessed(scope: Scope): boolean {
  return scope.migrationStatus.hasProcessed();
}

export class SectionsMigrationPublicApi extends BasePublicApi<Scope> {
  runSectionMigration = this.bindScope(runSectionMigration);
  runAnchorMigration = this.bindScope(runAnchorMigration);
  getUnlabeledPages = this.bindScope(getUnlabeledPages);
  getPageGrouping = this.bindScope(getPageGrouping);
  getPageGroupingByPrediction = this.bindScope(getGroupingByPrediction);
  isMigrationInProgress = this.bindScope(isMigrationInProgress);
  hasMigrationProcessed = this.bindScope(hasMigrationProcessed);
  refreshWithForceMigration = this.bindScope(refreshWithForceMigration);
}

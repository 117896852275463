import React, { Component } from 'react';

import * as util from '#packages/util';

import ListSection, { type ListSectionProps } from '../listSection';

import type {
  AccountSettingsItem,
  OpenAccountSettingsOptions,
} from './accountSettingsListSectionType';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './accountSettingsListSectionMapper';

const accountSettingsPathParams = '?referralInfo=embed-editor-panel';

export interface AccountSettingsListSectionDispatchProps {
  openAccountSettings(opt: OpenAccountSettingsOptions): void;
}

export type AccountSettingsListSectionOwnProps = {
  onClick(
    structure: object,
    sectionTitle: string,
    sectionTags: string,
    id: string,
    editorAPI: object,
    additionalData: object,
  ): void;
} & ListSectionProps;

type AccountSettingsListSectionProps = AccountSettingsListSectionOwnProps &
  AccountSettingsListSectionDispatchProps;

class AccountSettingsListSection extends Component<AccountSettingsListSectionProps> {
  onItemClick = (item: AccountSettingsItem) => {
    const { accountSettingsPath } = item;

    this.props.openAccountSettings({
      path: accountSettingsPath + accountSettingsPathParams,
    });

    this.props.onClick(
      item.structure,
      this.props.title,
      /*sectionTags*/ undefined,
      item.id,
      /*editorAPI*/ undefined,
      {
        nonComponent: true,
      },
    );
  };

  render() {
    return <ListSection callback={this.onItemClick} {...this.props} />;
  }
}

const ConnectedAccountSettingsListSection = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettingsListSection);

export default ConnectedAccountSettingsListSection;

import React from 'react';
import { translate } from '#packages/i18n';
import { frames } from '#packages/panels';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Composites,
  Button,
  RichText,
  Illustration,
  PanelHeader,
} from '@wix/wix-base-ui';
import {
  mapDispatchToProps,
  mapStateToProps,
} from './componentDeprecationPanelMapper';
import * as util from '#packages/util';
import styles from './componentDeprecationPanel.scss';

interface ComponentDeprecationPanelProps {
  isWelcomePopup: boolean;
  onHelp: () => void;
  onContinue: () => void;
  onClose: () => void;
  contentOverrides: {
    title?: string;
    mainCtaText?: string;
    description?: {
      firstSentenceText?: string;
      secondSentenceText?: string;
      thirdSentenceText?: string;
    };
  };
}

const ComponentDeprecationPanel = ({
  isWelcomePopup,
  onHelp,
  onContinue,
  onClose,
  contentOverrides: { title, description, mainCtaText },
}: ComponentDeprecationPanelProps) => {
  return (
    <frames.FocusPanelFrame
      shouldHideHeader={true}
      panelName={'componentDeprecation.componentDeprecationPanel'}
    >
      <Composites.PopupSmallSize>
        <PanelHeader onClose={onClose} onHelp={onHelp} />
        <Composites.PanelContent>
          <Illustration className={styles.deprecationPanelIllustration}>
            <symbols.symbol name="unsupportedComponentIllustration" />
          </Illustration>
          <Composites.RichText>
            <RichText type="T13" className={styles.deprecationPanelTitle}>
              {translate(
                title ||
                  (isWelcomePopup
                    ? 'Component_Deprecation_Generic_Welcome_Popup_Title'
                    : 'Component_Deprecation_Generic_GFPP_Popup_Title'),
              )}
            </RichText>
          </Composites.RichText>
          <Composites.RichText>
            <RichText type="T06">
              <p>
                {translate(
                  description?.firstSentenceText ||
                    (isWelcomePopup
                      ? 'Component_Deprecation_Generic_Welcome_Popup_Text_1'
                      : 'Component_Deprecation_Generic_GFPP_Popup_Text_1'),
                )}
                <br />
                <br />
                {translate(
                  description?.secondSentenceText ||
                    (isWelcomePopup
                      ? 'Component_Deprecation_Generic_Welcome_Popup_Text_2'
                      : 'Component_Deprecation_Generic_GFPP_Popup_Text_2'),
                )}
                <br />
                <br />
                {translate(
                  description?.thirdSentenceText ||
                    (isWelcomePopup
                      ? 'Component_Deprecation_Generic_Welcome_Popup_Text_3'
                      : 'Component_Deprecation_Generic_GFPP_Popup_Text_3'),
                )}
              </p>
            </RichText>
          </Composites.RichText>
          <Composites.ActionSetVertical>
            <Button onClick={onContinue}>
              {translate(
                mainCtaText ||
                  (isWelcomePopup
                    ? 'Component_Deprecation_Generic_Welcome_Popup_CTA'
                    : 'Component_Deprecation_Generic_GFPP_Popup_CTA'),
              )}
            </Button>
          </Composites.ActionSetVertical>
        </Composites.PanelContent>
      </Composites.PopupSmallSize>
    </frames.FocusPanelFrame>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(ComponentDeprecationPanel);

import React from 'react';
import { Text, RichText, Spacer, Divider } from '@wix/wix-base-ui';
import { frames } from '#packages/panels';

export interface SectionizerValidationPromtProps {
  onConfirm: () => void;
  onCancel: () => void;
  pageTitles: string[];
  panelName?: string;
}

const SectionizerValidationPromt = (props: SectionizerValidationPromtProps) => {
  const pageList: any = (
    <ul>
      {props.pageTitles.map((title: string) => (
        <li>- "{title}"</li>
      ))}
    </ul>
  );

  return (
    <frames.MessagePanelFrame
      panelName={props.panelName}
      title={'Label All The Sections in this Site'}
      confirmLabel={'Publish Anyway'}
      onConfirm={props.onConfirm}
      cancelLabel={'Back to Editing'}
      onCancel={props.onCancel}
      illustration={null}
      theme="destructive"
    >
      <Text
        size="small"
        weight="normal"
        skin="standard"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        The following pages are missing information about the sections in them:
      </Text>

      <Spacer type="Spacer02" />

      <Text
        size="small"
        weight="bold"
        skin="standard"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {pageList}
      </Text>

      <Spacer type="Spacer04" />
      <Divider long />
      <Spacer type="Spacer04" />

      <RichText>
        What's next?
        <Spacer type="Spacer02" />
        Open <b>Tools &#8594; Sectionizer</b> and make sure that:
        <Spacer type="Spacer02" />
        <ol>
          <li>
            1. All elements that currently belongs to the page are put under the
            right section.
          </li>
          <li>2. All sections are named</li>
        </ol>
      </RichText>
    </frames.MessagePanelFrame>
  );
};

export default SectionizerValidationPromt;

import * as util from '#packages/util';

export const convertPageNameToUrl = (title: string) => {
  const dash = '-';
  const pageUrlWithoutSpaces = title.replace(/\s/g, dash);
  const validator = util.validate.byInvalidCharacters(
    util.validationExpressions.invalidUrlCharacters,
  );

  return validator(pageUrlWithoutSpaces)
    ? pageUrlWithoutSpaces.toLowerCase()
    : 'blank';
};

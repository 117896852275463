import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import { AUTOMATION_IDS } from '../automationIds';

import { ArrowDown, ArrowUp, SectionLayout } from '@wix/wix-ui-icons-common';
import {
  Animation as AnimationIcon,
  ContextMenu,
  Duplicate as DuplicateIcon,
  Edit as EditIcon,
  Hide as HideIcon,
  Delete as RemoveIcon,
  Rename as RenameIcon,
  Save as SaveIcon,
  Settings as SettingsIcon,
} from '@wix/wix-ui-icons-common/classic-editor';
import HeaderScrollIcon from '../../HeaderScrollIcon/HeaderScrollIcon';

import { A11yIndicator } from './components/A11yIndicator';
import { ACTION_BAR_ORIGIN, ActionIds } from './Actions.constants';

import { TRANSLATIONS } from '../../../translations';

import type { ActionDef } from './Actions.types';
import experiment from 'experiment';
import {
  getIsHeaderDiscoverabilityExperiment,
  getIsHeaderScrollPanelExperiment,
} from './utils';
import { QuickEditApiKey } from '#packages/apis';

const {
  MoveUp,
  MoveDown,
  Duplicate,
  Rename,
  QuickEdit,
  SwitchLayout,
  SwitchLayoutComparison,
  Remove,
  Hide,
  ShowMore,
  Accessibility,
  Settings,
  ScanPreset,
  Animation,
  ScanSwitchLayoutPreset,
} = ActionIds;

const TOOLTIPS_TRANSLATIONS = TRANSLATIONS.ACTIONS_BAR.TOOLTIPS;
const isHeaderDiscovrabilityExperiment = getIsHeaderDiscoverabilityExperiment();
const isHeaderScrollPanelExperiment = getIsHeaderScrollPanelExperiment();

const shouldChangeSettingConfig =
  isHeaderDiscovrabilityExperiment || isHeaderScrollPanelExperiment;

export const actionsDefinitions: Record<ActionIds, ActionDef> = {
  [MoveUp]: {
    id: ActionIds.MoveUp,
    icon: ArrowUp,
    getTooltipData: () => ({
      text: translate(TOOLTIPS_TRANSLATIONS.MOVE_UP),
    }),
    dataHook: AUTOMATION_IDS.ACTION.MOVE_UP,
    isEnabled: (editorAPI, compRef) => editorAPI.sections.canMoveUp(compRef),
  },
  [MoveDown]: {
    id: ActionIds.MoveDown,
    icon: ArrowDown,
    getTooltipData: () => ({
      text: translate(TOOLTIPS_TRANSLATIONS.MOVE_DOWN),
    }),
    dataHook: AUTOMATION_IDS.ACTION.MOVE_DOWN,
    isEnabled: (editorAPI, compRef) => editorAPI.sections.canMoveDown(compRef),
  },
  [Rename]: {
    id: ActionIds.Rename,
    icon: RenameIcon,
    getTooltipData: () => ({
      text: translate(TOOLTIPS_TRANSLATIONS.RENAME),
    }),
    dataHook: AUTOMATION_IDS.ACTION.RENAME,
    isVisible: (editorAPI) =>
      !editorAPI.zoomMode.isStageZoomMode() &&
      !editorAPI.host.getAPI(QuickEditApiKey).isQuickEditAvailable(),
  },
  [QuickEdit]: {
    id: ActionIds.QuickEdit,
    icon: EditIcon,
    isVisible: (editorAPI) => {
      return editorAPI.host.getAPI(QuickEditApiKey).isQuickEditAvailable();
    },
    getTooltipData: (editorAPI, compRef, isDisabled) => {
      if (isDisabled) {
        return {
          text: translate(TOOLTIPS_TRANSLATIONS.QUICK_EDIT_EMPTY_STATE),
          customLink: {
            text: TOOLTIPS_TRANSLATIONS.QUICK_EDIT_EMPTY_STATE_LINK,
            action: () => {
              editorAPI.panelManager.openPanel(
                constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
                {
                  origin: ACTION_BAR_ORIGIN,
                },
              );
            },
          },
        };
      }
      return {
        text: translate(TOOLTIPS_TRANSLATIONS.QUICK_EDIT),
      };
    },
    dataHook: AUTOMATION_IDS.ACTION.QUICK_EDIT,
  },
  [SwitchLayout]: {
    id: ActionIds.SwitchLayout,
    icon: SectionLayout,
    getTooltipData: () => ({
      text: translate(TOOLTIPS_TRANSLATIONS.SWITCH_LAYOUT),
    }),
    dataHook: AUTOMATION_IDS.ACTION.SWITCH_LAYOUT,
    isEnabled: (editorAPI, compRef) =>
      editorAPI.components.getChildren(compRef).length > 0,
  },
  [SwitchLayoutComparison]: {
    id: ActionIds.SwitchLayoutComparison,
    icon: SectionLayout,
    getTooltipData: () => ({
      text: translate(TOOLTIPS_TRANSLATIONS.SWITCH_LAYOUT_COMPARISON),
    }),
    dataHook: AUTOMATION_IDS.ACTION.SWITCH_LAYOUT_COMPARISON,
  },
  [ScanSwitchLayoutPreset]: {
    id: ActionIds.ScanSwitchLayoutPreset,
    icon: SaveIcon,
    isVisible: () => experiment.isOpen('se_scanSwitchLayoutPreset'),
    getTooltipData: () => ({
      text: 'Scan Switch Layout Preset',
    }),
    dataHook: AUTOMATION_IDS.ACTION.SCAN_SWITCH_LAYOUT_PRESET,
  },
  [ScanPreset]: {
    id: ActionIds.ScanPreset,
    icon: SaveIcon,
    isVisible: () => experiment.isOpen('se_addDesignerSection'),
    getTooltipData: () => ({
      text: 'Scan Designer Preset',
    }),
    dataHook: AUTOMATION_IDS.ACTION.DESIGNER_SCAN_PRESET,
  },
  [Duplicate]: {
    id: ActionIds.Duplicate,
    icon: DuplicateIcon,
    dataHook: AUTOMATION_IDS.ACTION.DUPLICATE,
    isEnabled: (editorAPI, compRef) =>
      editorAPI.components.is.duplicatable(compRef),
    getTooltipData: (editorAPI, compRef, isDisabled) => {
      if (isDisabled) {
        const nonDuplicatableData =
          editorAPI.sections.getNonDuplicatableData(compRef);

        if (nonDuplicatableData?.type === 'containsNonDuplicatableTPA') {
          return {
            text: translate(
              TOOLTIPS_TRANSLATIONS.CANNOT_DUPLICATE_SECTION_WITH_WIDGET,
              {
                widget_name: editorAPI.components.getDisplayName(
                  nonDuplicatableData.nonDuplicatableTPARef,
                ),
              },
            ),
            helpId: 'faa30f31-4afb-41cf-858a-5271e436f75b',
          };
        }
      }

      return { text: translate(TOOLTIPS_TRANSLATIONS.DUPLICATE) };
    },
  },
  [Remove]: {
    id: ActionIds.Remove,
    icon: RemoveIcon,
    dataHook: AUTOMATION_IDS.ACTION.REMOVE,
    isEnabled: (editorAPI, compRef) =>
      editorAPI.components.is.removable(compRef),
    getTooltipData: (editorAPI, compRef, isDisabled) => {
      if (isDisabled) {
        const nonRemovableData =
          editorAPI.sections.getNonRemovableData(compRef);

        if (nonRemovableData?.type === 'lastSection') {
          return {
            text: translate(TOOLTIPS_TRANSLATIONS.CANNOT_REMOVE_LAST_SECTION),
          };
        }
        if (nonRemovableData?.type === 'containsNonRemovableTPA') {
          return {
            text: translate(
              TOOLTIPS_TRANSLATIONS.CANNOT_REMOVE_SECTION_WITH_WIDGET,
              {
                widget_name: editorAPI.components.getDisplayName(
                  nonRemovableData.nonRemovableTPARef,
                ),
              },
            ),
            helpId: 'ba3940d3-b9d8-4437-abd8-3f77f93085ce',
          };
        }
      }

      return { text: translate(TOOLTIPS_TRANSLATIONS.REMOVE) };
    },
  },
  [Hide]: {
    id: ActionIds.Hide,
    icon: HideIcon,
    dataHook: AUTOMATION_IDS.ACTION.HIDE,
    isEnabled: (editorAPI, compRef) =>
      editorAPI.components.is.removable(compRef),
    getTooltipData: (editorAPI, compRef, isDisabled) => {
      if (isDisabled) {
        const nonRemovableData =
          editorAPI.sections.getNonRemovableData(compRef);

        if (nonRemovableData?.type === 'lastSection') {
          return {
            text: translate(
              'section_action_bar_disable_mobile_hide_last_section_tooltip',
            ),
          };
        }
        if (nonRemovableData?.type === 'containsNonRemovableTPA') {
          return {
            text: translate(
              TOOLTIPS_TRANSLATIONS.CANNOT_HIDE_SECTION_WITH_WIDGET,
              {
                widget_name: editorAPI.components.getDisplayName(
                  nonRemovableData.nonRemovableTPARef,
                ),
              },
            ),
            helpId: 'dfe9342e-3642-46a1-a29c-ce9fe94c32ae',
          };
        }
      }

      return { text: translate(TOOLTIPS_TRANSLATIONS.HIDE) };
    },
  },
  [ShowMore]: {
    id: ActionIds.ShowMore,
    icon: ContextMenu,
    getTooltipData: () => ({
      text: translate(TOOLTIPS_TRANSLATIONS.SHOW_MORE),
    }),
    dataHook: AUTOMATION_IDS.ACTION.SHOW_MORE,
  },
  [Accessibility]: {
    id: ActionIds.Accessibility,
    icon: A11yIndicator,
    getTooltipData: () => ({
      text: translate(TOOLTIPS_TRANSLATIONS.ACCESSIBILITY),
    }),
    dataHook: AUTOMATION_IDS.ACTION.ACCESSIBILITY,
    isVisible: (editorAPI, compRef) =>
      editorAPI.accessibility.isAccessibilitySupported(compRef),
  },
  [Settings]: {
    id: Settings,
    icon: SettingsIcon,
    getIcon: (editorAPI, compRef) => {
      if (!shouldChangeSettingConfig) return SettingsIcon;

      const isHeaderComp = editorAPI.sections.isHeader(compRef);
      return isHeaderComp ? HeaderScrollIcon : SettingsIcon;
    },
    getTooltipData: (editorAPI, compRef) => {
      const tooltipMapByType = {
        [constants.COMP_TYPES.HEADER]: shouldChangeSettingConfig
          ? TOOLTIPS_TRANSLATIONS.HEADER_SETTINGS_DISCOVERABILITY
          : TOOLTIPS_TRANSLATIONS.HEADER_SETTINGS,
        [constants.COMP_TYPES.FOOTER]: TOOLTIPS_TRANSLATIONS.FOOTER_SETTINGS,
      };

      return {
        text: translate(
          tooltipMapByType[
            editorAPI.components.getType(
              compRef,
            ) as keyof typeof tooltipMapByType
          ],
        ),
      };
    },
    dataHook: AUTOMATION_IDS.ACTION.SETTINGS,
  },
  [Animation]: {
    id: Animation,
    icon: AnimationIcon,
    getTooltipData: (editorAPI, compRef) => {
      const tooltipMapByType = {
        [constants.COMP_TYPES.FOOTER]: TOOLTIPS_TRANSLATIONS.FOOTER_ANIMATION,
      };

      return {
        text: translate(
          tooltipMapByType[
            editorAPI.components.getType(
              compRef,
            ) as keyof typeof tooltipMapByType
          ],
        ),
      };
    },
    dataHook: AUTOMATION_IDS.ACTION.ANIMATION,
  },
};

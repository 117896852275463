// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

import { ToolPanelFrame } from '../../frames';
import AddColorContent from './addColorContent';

interface Props {
  valueLink: {
    value?: string;
    requestChange?: FunctionFixMe;
  };
  paletteColors: AnyFixMe;
  closeHandler: FunctionFixMe;
  onPreview: FunctionFixMe;
  onCancel: FunctionFixMe;
  siteCustomColors?: Array<string>;
  closePanel: () => void;
  sendBI: (event: coreBi.BiEventDefinition, parameters: Object) => void;
  origin?: string;
}

class AddColorPanel extends React.Component<Props> {
  static displayName = 'addColorPanel';

  static propTypes = {
    valueLink: PropTypes.shape({
      value: PropTypes.string,
      requestChange: PropTypes.func,
    }).isRequired,
    paletteColors: PropTypes.object.isRequired,
    closeHandler: PropTypes.func.isRequired,
    onPreview: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    siteCustomColors: PropTypes.arrayOf(PropTypes.string),
    closePanel: PropTypes.func.isRequired,
    sendBI: PropTypes.func.isRequired,
    origin: PropTypes.string,
  };

  close = () => {
    this.props.sendBI(coreBi.events.colorPicker.ADD_COLOR_PANEL_CLOSED, {});
    this.props.closePanel();
  };

  render() {
    return (
      <ToolPanelFrame
        ref="frame"
        contentWrapperClass="custom-color-picker-panel"
        panelName={this.props.panelName}
        title={this.props.title}
        contentStyle={_.pick(this.props, ['top', 'left'])}
      >
        <AddColorContent
          siteCustomColors={this.props.siteCustomColors}
          paletteColors={this.props.paletteColors}
          valueLink={this.props.valueLink}
          origin={this.props.origin}
          sendBI={this.props.sendBI}
          onPreview={this.props.onPreview}
          onCancel={this.props.onCancel}
          closeHandler={this.close}
        />
      </ToolPanelFrame>
    );
  }
}

const getEditorAPI = (dispatch, getState, { editorAPI }) => editorAPI;

const mapDispatchToProps = (dispatch, props) => {
  const editorAPI = dispatch(getEditorAPI);
  const compRef = editorAPI.selection.getSelectedComponents()[0];

  return {
    closePanel() {
      dispatch(
        stateManagement.panels.actions.closePanelByName(props.panelName),
      );
    },
    sendBI(event: string, parameters: Object) {
      const defaultBIParams = {
        component_id: compRef?.id,
      };

      dispatch(
        stateManagement.bi.actions.event(event, {
          ...defaultBIParams,
          ...parameters,
        }),
      );
    },
  };
};

const ConnectedAddColorPanel = util.hoc.connect(
  util.hoc.STORES.STORE_ONLY,
  undefined,
  mapDispatchToProps,
)(AddColorPanel);

export default ConnectedAddColorPanel;

// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import * as newBlogBi from '../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../utils/blogUtils';
import React from 'react';
import BlogPromoSlide from '../blogPromoSlide/blogPromoSlide';

const ACTION_SWITCH = 'switch';
const ACTION_LATER = 'later';

class OldBlogMigrationSlide extends React.Component {
  static displayName = 'OldBlogMigrationSlide';

  static defaultProps = {
    panelName: blogUtils.superAppPanelName,
    title: 'SiteApp_Blog_Migration_Promo_Title',
    subTitle: 'SiteApp_Blog_Migration_Promo_Subtitle',
    learnMoreLabel: 'SiteApp_Blog_Migration_Promo_LearnMore',
    pros: [
      'SiteApp_Blog_Migration_Promo_Bullet_1',
      'SiteApp_Blog_Migration_Promo_Bullet_2',
      'SiteApp_Blog_Migration_Promo_Bullet_3',
    ],
    buttons: [
      {
        key: ACTION_SWITCH,
        label: 'SiteApp_Blog_Migration_Promo_Button_Switch',
      },
      {
        key: ACTION_LATER,
        label: 'SiteApp_Blog_Migration_Promo_Button_Later',
        className: 'btn-confirm-secondary',
      },
    ],
    helpArticleId: blogUtils.helpArticles?.oldBlogMigration,
  };

  onButtonClick = (action) => {
    switch (action) {
      case ACTION_SWITCH:
        this.props.startQuickMigrationProp(this.props.sendBi, {
          action,
          origin: newBlogBi.ORIGINS.OLD_BLOG_6TH_BUTTON,
        });
        break;
      case ACTION_LATER:
        this.props.markBlogMigrationPromoAsSeenProp(this.props.sendBi, {
          action,
          origin: newBlogBi.ORIGINS.OLD_BLOG_6TH_BUTTON,
        });
        this.props.onClose();
        this.props.openParentPanel();
        break;
    }
  };

  onCloseClick = () => {
    this.props.sendBi(newBlogBi.events.BLOG_QUICK_MIGRATION_ACTION, {
      action: 'close',
      origin: newBlogBi.ORIGINS.OLD_BLOG_6TH_BUTTON,
      instance_id: null,
    });
  };

  onLearnMoreClick = () => {
    this.props.sendBi(newBlogBi.events.BLOG_QUICK_MIGRATION_LEARN_MORE, {
      role: 'owner',
      action: 'switch',
      origin: newBlogBi.ORIGINS.OLD_BLOG_6TH_BUTTON,
      instance_id: null,
    });
  };

  render() {
    return (
      <BlogPromoSlide
        onCloseClick={this.onCloseClick}
        onButtonClick={this.onButtonClick}
        onLearnMoreClick={this.onLearnMoreClick}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({ editorAPI }) => ({
  openParentPanel: () =>
    editorAPI.panelManager.openPanel(blogUtils.superAppPanelName),
  startQuickMigrationProp: _.partial(blogUtils.startQuickMigration, editorAPI),
  markBlogMigrationPromoAsSeenProp: _.partial(
    blogUtils.markBlogMigrationPromoAsSeen,
    editorAPI,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  sendBi: (event, parameters) =>
    dispatch(stateManagement.bi.actions.event(event, parameters)),
});

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(OldBlogMigrationSlide);
ConnectedComponent.pure = OldBlogMigrationSlide;

export default ConnectedComponent;

// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as baseUI from '#packages/baseUI';
import React from 'react';
import { CustomScroll } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface ObjectPropertyEditorProps {
  propertyData: AnyFixMe;
  value: AnyFixMe;
  openMediaManager: FunctionFixMe;
  onChange: FunctionFixMe;
  resolveDefinition: FunctionFixMe;
  automationId?: string;
}

class ObjectPropertyEditor extends React.Component<ObjectPropertyEditorProps> {
  static displayName = 'objectPropertyEditor';

  static propTypes = {
    propertyData: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    openMediaManager: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    resolveDefinition: PropTypes.func.isRequired,
    automationId: PropTypes.string,
  };

  getSortedProperties = () => {
    const { propertiesOrder, properties } = this.props.propertyData;
    return propertiesOrder
      ? // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        _.map(propertiesOrder, (propName) =>
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({ propName }, properties[propName]),
        )
      : [];
  };

  getPropertiesUIComponents = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _(this.state.properties)
      .map((property) =>
        baseUI.utils.generateUICompByPropertySchema(
          this.props.resolveDefinition({ [property.propName]: property }),
          this.props.value[property.propName],
          this.onChange,
          { openMediaManager: this.props.openMediaManager },
        ),
      )
      .thru(baseUI.utils.generateSeparatorBetweenComponents)
      .value();
  };

  onChange = (propertyName, newValue) => {
    this.props.onChange(
      _.defaults({ [propertyName]: newValue }, this.props.value),
    );
  };

  state = {
    properties: this.getSortedProperties(),
  };

  render() {
    return (
      <div
        data-aid={this.props.automationId}
        className="object-properties-editor"
      >
        <CustomScroll flex="1">
          <div>{this.getPropertiesUIComponents()}</div>
        </CustomScroll>
      </div>
    );
  }
}

export default ObjectPropertyEditor;

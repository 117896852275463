import { workspace, appStudioUtils } from '#packages/util';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();
const isAppStudio = appStudioUtils.isAppStudio();

export interface FailPanelMultiKeyStepDef {
  key: string;
  isLink: boolean;
  translationLink?: string;
}

export interface SavePublishErrorPanelData {
  titleKey?: string;
  description?: string;
  steps?: Array<string | FailPanelMultiKeyStepDef[]>;
  helpMessage?: string;
  helpLinkMessage?: string;
  helpSuffix?: string;
  stepsTitle?: string;
  showSteps?: boolean;
  showFooterText?: boolean;
  cancelLabel?: string;
  confirmLabel?: string;
  headerHelpLink?: string;
  helpLink?: string;
  symbolName?: string;
  hideSymbol?: boolean;
  errorDescription?: {
    text: string;
    secondaryText?: string;
    helpText?: string;
    helpId?: string;
  };
  errorCode?: string;
  errorCodeText?: string;
  isNewWorkspace?: boolean;
  className?: string;
}

const savePublishErrorPanelData: {
  [errorType: string]: SavePublishErrorPanelData;
} = {
  SITE_NAME_TAKEN_PANEL_DATA: {
    titleKey: 'ERROR_SITE_NOT_RENAMED_TITLE_LABEL',
    description: 'ERROR_SITE_NOT_RENAMED_BODY_SUBTITLE',
    steps: [
      'ERROR_SITE_NOT_RENAMED_STEP1_TEXT',
      'ERROR_SITE_NOT_RENAMED_STEP2_TEXT',
      'ERROR_SITE_NOT_RENAMED_STEP3_TEXT',
    ],
    stepsTitle: 'ERROR_SITE_NOT_RENAMED_TRY_STEPS',
  },
  SAVE_DISABLED_ON_SERVER_PANEL_DATA: {
    titleKey: 'SAVE_ERROR_TITLE_LABEL',
    description: 'Save_Publish_ServerErrorLabel_OnSave',
    showSteps: false,
    showFooterText: false,
  },
  SAVE_USER_NOT_AUTHORIZED_FOR_SITE: {
    titleKey: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_HEADER_TITLE',
    description: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_BODY_INTRO',
    steps: [
      'SAVE_SITE_ERROR_WRONG_ACCOUNT_STEP_1',
      [
        { key: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_STEP_2_PART1', isLink: false },
        { key: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_STEP_2_LINK', isLink: true },
        { key: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_STEP_2_PART3', isLink: false },
      ],
      'SAVE_SITE_ERROR_WRONG_ACCOUNT_STEP_3',
    ],
    helpMessage: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_1',
    helpLinkMessage: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_2_LINK',
    helpSuffix: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_3',
    headerHelpLink: '0db00750-3bf1-4990-abac-136094522f98',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    confirmLabel: 'SAVE_SITE_ERROR_WRONG_ACCOUNT_CTA',
    symbolName: isNewWorkspace
      ? 'userLoggedIntoDifferentAccError_NewWorkspace'
      : null,
    isNewWorkspace,
  },
  PUBLISH_USER_NOT_AUTHORIZED_FOR_SITE: {
    titleKey: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_HEADER_TITLE',
    description: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_BODY_INTRO',
    steps: [
      'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_STEP_1',
      [
        { key: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_STEP_2_PART1', isLink: false },
        { key: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_STEP_2_LINK', isLink: true },
        { key: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_STEP_2_PART3', isLink: false },
      ],
      'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_STEP_3',
    ],
    helpMessage: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_1',
    helpLinkMessage: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_2_LINK',
    helpSuffix: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_3',
    headerHelpLink: 'a0a7719e-2a92-40af-90b3-7585e99e7f8f',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    confirmLabel: 'PUBLISH_SITE_ERROR_WRONG_ACCOUNT_CTA',
    symbolName: isNewWorkspace
      ? 'userLoggedIntoDifferentAccError_NewWorkspace'
      : null,
    isNewWorkspace,
  },
  SAVE_NOT_LOGGED_IN: {
    titleKey: 'SAVE_SITE_ERROR_LOGGED_OUT_HEADER_TITLE',
    description: 'SAVE_SITE_ERROR_LOGGED_OUT_BODY_INTRO',
    steps: [
      'SAVE_SITE_ERROR_LOGGED_OUT_STEP_1',
      [
        { key: 'SAVE_SITE_ERROR_LOGGED_OUT_STEP_2_PART1', isLink: false },
        { key: 'SAVE_SITE_ERROR_LOGGED_OUT_STEP_2_LINK', isLink: true },
        { key: 'SAVE_SITE_ERROR_LOGGED_OUT_STEP_2_PART3', isLink: false },
      ],
      'SAVE_SITE_ERROR_LOGGED_OUT_STEP_3',
    ],
    helpMessage: 'SAVE_SITE_ERROR_LOGGED_OUT_TROUBLESHOOT_1',
    helpLinkMessage: 'SAVE_SITE_ERROR_LOGGED_OUT_TROUBLESHOOT_2_LINK',
    helpSuffix: 'SAVE_SITE_ERROR_LOGGED_OUT_TROUBLESHOOT_3',
    headerHelpLink: '2a6bbfad-9213-487c-81b1-d13e3ffad368',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    symbolName: isNewWorkspace
      ? 'userLoggedOutError_NewWorkspace'
      : 'userLoggedOut',
    confirmLabel: 'SAVE_SITE_ERROR_LOGGED_OUT_CTA',
    isNewWorkspace,
  },
  PUBLISH_NOT_LOGGED_IN: {
    titleKey: 'PUBLISH_SITE_ERROR_LOGGED_OUT_HEADER_TITLE',
    description: 'PUBLISH_SITE_ERROR_LOGGED_OUT_BODY_INTRO',
    steps: [
      'PUBLISH_SITE_ERROR_LOGGED_OUT_STEP_1',
      [
        { key: 'PUBLISH_SITE_ERROR_LOGGED_OUT_STEP_2_PART1', isLink: false },
        { key: 'PUBLISH_SITE_ERROR_LOGGED_OUT_STEP_2_LINK', isLink: true },
        { key: 'PUBLISH_SITE_ERROR_LOGGED_OUT_STEP_2_PART3', isLink: false },
      ],
      'PUBLISH_SITE_ERROR_LOGGED_OUT_STEP_3',
    ],
    helpMessage: 'PUBLISH_SITE_ERROR_LOGGED_OUT_TROUBLESHOOT_1',
    helpLinkMessage: 'PUBLISH_SITE_ERROR_LOGGED_OUT_TROUBLESHOOT_2_LINK',
    helpSuffix: 'PUBLISH_SITE_ERROR_LOGGED_OUT_TROUBLESHOOT_3',
    headerHelpLink: '9fbb7b1c-5ace-457d-80ba-a9a639f5d0ca',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    symbolName: isNewWorkspace
      ? 'userLoggedOutError_NewWorkspace'
      : 'userLoggedOut',
    confirmLabel: 'PUBLISH_SITE_ERROR_LOGGED_OUT_CTA',
    isNewWorkspace,
  },
  PUBLISH_DISABLED_ON_SERVER_PANEL_DATA: {
    titleKey: 'PUBLISH_ERROR_TITLE_LABEL',
    description: 'Save_Publish_ServerErrorLabel_OnPublish',
    showSteps: false,
    showFooterText: false,
  },
  PUBLISH_RC_FAILED_ON_SERVER_PANEL_DATA: {
    titleKey: 'Replace_Release_Candidate_Note_Title',
    description: 'Replace_Release_Candidate_Note_Text',
    showSteps: false,
    showFooterText: false,
    cancelLabel: 'Replace_Release_Candidate_Note_Cancel_Button',
    confirmLabel: 'Replace_Release_Candidate_Note_Continue_Button',
    helpMessage: 'SAVE_ERROR_LEARN_MORE_LABEL',
    helpLinkMessage: 'SAVE_PUBLISH_ERROR_LEARN_MORE_LINK',
    helpLink: '3365fbc2-1e03-46c8-88c0-f93b2c50ca2f',
  },
  PUBLISH_TEST_SITE_FAILED_ON_SERVER_PANEL_DATA: {
    titleKey: 'Replace_Test_Site_Note_Title',
    description: 'Replace_Test_Site_Note_Text',
    showSteps: false,
    showFooterText: false,
    cancelLabel: 'Replace_Test_Site_Note_Cancel_Button',
    confirmLabel: 'Replace_Test_Site_Note_Continue_Button',
    helpMessage: 'SAVE_ERROR_LEARN_MORE_LABEL',
    helpLinkMessage: 'SAVE_PUBLISH_ERROR_LEARN_MORE_LINK',
    helpLink: '3365fbc2-1e03-46c8-88c0-f93b2c50ca2f',
  },
  PUBLISH_TEST_SITE_EXISTS_PANEL_DATA: {
    titleKey: 'Release_Candidate_Replace_Title',
    description: 'Release_Candidate_Replace_Text',
    showSteps: false,
    showFooterText: false,
    cancelLabel: 'Release_Candidate_Replace_Button2',
    confirmLabel: 'Release_Candidate_Replace_Button1',
    helpMessage: 'SAVE_ERROR_LEARN_MORE_LABEL',
    helpLinkMessage: 'SAVE_PUBLISH_ERROR_LEARN_MORE_LINK',
    helpLink: '3365fbc2-1e03-46c8-88c0-f93b2c50ca2f',
    symbolName: 'publish_test_site_override_warning',
  },
  NAME_SITE_AGAIN_PANEL_DATA: {
    titleKey: 'ERROR_NAME_SITE_AGAIN_TITLE_LABEL',
    description: 'ERROR_NAME_SITE_AGAIN_SUBTITLE',
    steps: [
      'ERROR_NAME_SITE_AGAIN_STEP1_TEXT',
      'ERROR_NAME_SITE_AGAIN_STEP2_TEXT',
      'ERROR_NAME_SITE_AGAIN_STEP3_TEXT',
    ],
    stepsTitle: 'ERROR_NAME_SITE_AGAIN_TRY_STEPS',
  },
  SAVE_ERROR_DEFAULT_PANEL_DATA: {
    headerHelpLink: '1fc917c9-a79f-4693-989d-a5c00264dd51',
    titleKey: isAppStudio
      ? 'AppStudio_Save_Error_Modal_Header'
      : 'SAVE_SITE_ERROR_SERVER_HEADER_TITLE',
    errorDescription: isAppStudio
      ? {
          text: 'AppStudio_Save_Error_Modal_Body',
          secondaryText: '',
        }
      : {
          text: 'SAVE_SITE_ERROR_SERVER_BODY_1',
          secondaryText: 'SAVE_SITE_ERROR_SERVER_BODY_2',
        },
    helpMessage: 'SAVE_SITE_ERROR_SERVER_TROUBLESHOOT_1',
    helpLinkMessage: 'SAVE_SITE_ERROR_SERVER_TROUBLESHOOT_2_LINK',
    helpSuffix: 'SAVE_SITE_ERROR_SERVER_TROUBLESHOOT_3',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    symbolName: isNewWorkspace ? 'serverError_NewWorkspace' : 'serverError',
    confirmLabel: 'SAVE_SITE_ERROR_SERVER_CTA',
    isNewWorkspace,
  },
  DEFAULT_PANEL_DATA_NO_ERROR_CODE: {
    titleKey: 'SAVE_SITE_ERROR_SERVER_HEADER_TITLE',
    errorDescription: {
      text: 'SAVE_SITE_ERROR_SERVER_BODY_NO_CODE',
      helpText: 'SAVE_SITE_ERROR_SERVER_LINK',
      helpId: '1fc917c9-a79f-4693-989d-a5c00264dd51',
    },
    symbolName: isNewWorkspace ? 'noInternetError_NewWorkspace' : 'serverError',
    confirmLabel: 'SAVE_SITE_ERROR_SERVER_CTA',
    isNewWorkspace,
  },
  PUBLISH_ERROR_DEFAULT_PANEL_DATA: {
    titleKey: 'PUBLISH_SITE_ERROR_SERVER_HEADER_TITLE',
    headerHelpLink: 'e3ed1e46-6b1b-4637-8177-1285c0724ded',
    errorDescription: {
      text: 'PUBLISH_SITE_ERROR_SERVER_BODY_NO_CODE',
    },
    helpMessage: 'PUBLISH_SITE_ERROR_SERVER_TROUBLESHOOT_1',
    helpLinkMessage: 'PUBLISH_SITE_ERROR_SERVER_TROUBLESHOOT_2_LINK',
    helpSuffix: 'PUBLISH_SITE_ERROR_SERVER_TROUBLESHOOT_3',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    confirmLabel: 'PUBLISH_SITE_ERROR_SERVER_CTA',
    symbolName: isNewWorkspace ? 'serverError_NewWorkspace' : undefined,
    isNewWorkspace,
  },
  VALIDATION_ERROR: {
    showSteps: false,
    helpMessage: 'Notification_Box_Data_Curruption_Error_Troubleshoot_1',
    helpLinkMessage:
      'Notification_Box_Data_Curruption_Error_Troubleshoot_2_Link',
    helpSuffix: 'Notification_Box_Data_Curruption_Error_Troubleshoot_3',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    errorCodeText: 'SAVE_PUBLISH_SITE_ERROR_CODE',
  },
  SITE_DELETED: {
    titleKey: 'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_HEADER_TITLE',
    description: 'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_BODY_INTRO',
    steps: [
      [
        {
          key: 'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_STEP_1_PART1',
          isLink: false,
        },
        {
          key: 'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_STEP_1_LINK',
          isLink: true,
        },
        {
          key: 'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_STEP_1_PART3',
          isLink: false,
        },
      ],
      'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_STEP_2',
      'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_STEP_3',
    ],
    helpLink: '96356b41-5758-455b-b41d-95fabf9b016d',
    confirmLabel: 'SAVE_PUBLISH_SITE_ERROR_SITE_DELETED_CTA',
    symbolName: isNewWorkspace
      ? 'siteDeletedError_NewWorkspace'
      : 'siteDeleted',
    isNewWorkspace,
  },
  SESSION_EXPIRED: {
    headerHelpLink: '762e26b8-746a-4c71-8589-b72322a3a01a',
    titleKey: 'SAVE_SITE_ERROR_SESSION_EXPIRED_HEADER_TITLE',
    errorDescription: {
      text: 'SAVE_SITE_ERROR_SESSION_EXPIRED_BODY_NO_CODE',
    },
    helpMessage: 'SAVE_SITE_ERROR_SESSION_EXPIRED_TROUBLESHOOT_1',
    helpLinkMessage: 'SAVE_SITE_ERROR_SESSION_EXPIRED_TROUBLESHOOT_2_LINK',
    helpSuffix: 'SAVE_SITE_ERROR_SESSION_EXPIRED_TROUBLESHOOT_3',
    helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
    symbolName: isNewWorkspace ? 'serverError_NewWorkspace' : 'serverError',
    confirmLabel: 'SAVE_SITE_ERROR_SESSION_EXPIRED_CTA',
    isNewWorkspace,
  },
  SAVE_CONNECTION_OFFLINE: {
    titleKey: 'SAVE_SITE_ERROR_INTERNET_HEADER_TITLE',
    description: 'SAVE_SITE_ERROR_INTERNET_BODY',
    confirmLabel: 'SAVE_SITE_ERROR_INTERNET_CTA',
    symbolName: isNewWorkspace ? 'noInternetError_NewWorkspace' : 'noInternet',
    isNewWorkspace,
  },
  PUBLISH_CONNECTION_OFFLINE: {
    titleKey: 'PUBLISH_SITE_ERROR_INTERNET_HEADER_TITLE',
    description: 'PUBLISH_SITE_ERROR_INTERNET_BODY',
    confirmLabel: 'PUBLISH_SITE_ERROR_INTERNET_CTA',
    symbolName: isNewWorkspace ? 'noInternetError_NewWorkspace' : 'noInternet',
    isNewWorkspace,
  },
};

export default savePublishErrorPanelData;

import * as util from '#packages/util';
import { layoutTransitionsUtil, updateLayoutUtil } from '#packages/layoutUtils';
import { OptimisticDragHandler } from './optimisticDragHandlerThunderbolt';
import {
  onDragStartInteraction,
  onDragMoveInteraction,
  onDragMoveUpdateLayoutInteraction,
  onDragEndInteraction,
} from './interactionsInteceptor';
import { isMeshLayoutEnabled } from '#packages/layout';
import type { CompLayout, CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { BaseDragPlugin, UpdateLayoutArgs } from '../common';
import type { DragMoveToInterceptionScope, DragMoveToScope } from './types';

const { isMultiselect } = util.array;

const PARENT_TYPES_TO_CONSIDER_LAYOUT_CHANGE = {
  'wysiwyg.viewer.components.Group': true,
};

function shouldConsiderParentLayoutChange(
  editorAPI: EditorAPI,
  selectedComps: CompRef[],
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return selectedComps.map(
    (comp) =>
      !!PARENT_TYPES_TO_CONSIDER_LAYOUT_CHANGE[
        editorAPI.components.getType(
          editorAPI.components.getContainer(comp),
        ) as keyof typeof PARENT_TYPES_TO_CONSIDER_LAYOUT_CHANGE
      ],
  );
}

function getInterceptorScope(
  scope: DragMoveToScope,
): DragMoveToInterceptionScope {
  const {
    editorAPI,
    selectedComp: compRefs,
    initMousePosition: mousePositionInitial,
  } = scope;

  return {
    editorAPI,
    compRefs,
    mousePositionInitial,
  };
}

function onDragMoveUpdateLayout(
  scope: DragMoveToScope,
  moveLayoutArgs: UpdateLayoutArgs,
) {
  const {
    editorAPI,
    selectedComp,
    dragTransition,
    optimisticDragHandler,
    initialCompLayout: layoutRelativeToScreenInitial,
    isHorizontallyMovable,
    considerParentLayoutChange,
    isVerticallyMovable,
    initCompLayoutsRelativeToScreen: layoutsRelativeToScreenInitial,
    initCompLayouts: layoutsRelativeToParentInitial,
  } = scope;
  const {
    currentMousePosition,
    fireOverrideLayoutHook,
    fireBeforeLayoutUpdateHook,
  } = moveLayoutArgs;

  const layoutInitial = {
    relativeToScreen: layoutRelativeToScreenInitial,
  };
  const layoutUpdated = {
    relativeToScreen: dragTransition.getLayout(
      currentMousePosition,
    ) as CompLayout,
  };

  if (isMultiselect(selectedComp) || isMeshLayoutEnabled()) {
    // NOTE: under the mesh experiment, we need aplly constrains at the verry start.
    layoutUpdated.relativeToScreen =
      updateLayoutUtil.getLayoutRelativeToScreenAfterConstraints(
        editorAPI,
        // @ts-expect-error
        selectedComp,
        layoutUpdated.relativeToScreen,
        layoutInitial.relativeToScreen,
      );
  }

  const { isHandled } = onDragMoveUpdateLayoutInteraction(
    getInterceptorScope(scope),
    moveLayoutArgs,
    layoutUpdated.relativeToScreen,
  );
  if (isHandled) {
    return;
  }

  if (!isHorizontallyMovable) {
    layoutUpdated.relativeToScreen.x = layoutInitial.relativeToScreen.x;
  }
  if (!isVerticallyMovable) {
    layoutUpdated.relativeToScreen.y = layoutInitial.relativeToScreen.y;
  }

  layoutUpdated.relativeToScreen.bounding = editorAPI.utils.getBoundingLayout(
    layoutUpdated.relativeToScreen,
  );

  layoutUpdated.relativeToScreen = fireOverrideLayoutHook(
    layoutUpdated.relativeToScreen,
  );

  fireBeforeLayoutUpdateHook(layoutUpdated.relativeToScreen);

  const { deltaX, deltaY } = dragTransition.getLayoutDelta(
    layoutUpdated.relativeToScreen,
  );

  if (isMeshLayoutEnabled()) {
    scope.meshMoveByTransition.update({
      deltaX,
      deltaY,
    });
    return;
  }

  selectedComp.forEach((compRef, i) => {
    const positionInitial = {
      relativeToParent: layoutsRelativeToParentInitial[i],
      relativeToScreen: layoutsRelativeToScreenInitial[i],
    };
    const positionUpdated = {
      relativeToParent: {
        x: positionInitial.relativeToParent.x + deltaX,
        y: positionInitial.relativeToParent.y + deltaY,
      },
      relativeToScreen: {
        x: positionInitial.relativeToScreen.x + deltaX,
        y: positionInitial.relativeToScreen.y + deltaY,
      },
    };

    if (
      optimisticDragHandler.canOptimisticDrag(positionUpdated.relativeToParent)
    ) {
      const positionOfContainer = {
        relativeToScreen: {
          x:
            positionInitial.relativeToScreen.x -
            positionInitial.relativeToParent.x,
          y:
            positionInitial.relativeToScreen.y -
            positionInitial.relativeToParent.y,
        },
      };

      optimisticDragHandler.updateLayout(
        compRef,
        positionUpdated.relativeToParent,
        positionOfContainer.relativeToScreen,
      );

      return;
    }

    if (considerParentLayoutChange[i]) {
      editorAPI.components.layout.updateRelativeToScreen(
        compRef,
        positionUpdated.relativeToScreen,
        true,
      );

      return;
    }

    editorAPI.components.layout.update(
      compRef,
      positionUpdated.relativeToParent,
      true,
    );
  });
}

function onDragMove(scope: DragMoveToScope, args: UpdateLayoutArgs) {
  const { isHandled } = onDragMoveInteraction(getInterceptorScope(scope), args);
  if (isHandled) {
    return;
  }

  onDragMoveUpdateLayout(scope, args);
}

function onDragStart(scope: DragMoveToScope) {
  const { isHandled } = onDragStartInteraction(getInterceptorScope(scope));
  if (isHandled) {
    return;
  }

  const { editorAPI, selectedComp } = scope;

  editorAPI.dsActions.documentMode.enableShouldUpdateJsonFromMeasureMap(false);

  const isCompFocusMode = editorAPI.componentFocusMode.isEnabled();
  if (!isCompFocusMode) {
    const selectedCompIds = selectedComp.map((comp) => comp.id);
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(selectedCompIds);
    editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity(
      selectedCompIds,
      0.9,
    );
  }
}

function onDragEnd(scope: DragMoveToScope) {
  const { isHandled } = onDragEndInteraction(getInterceptorScope(scope));
  if (isHandled) {
    return;
  }

  const { editorAPI } = scope;
  const isCompFocusMode = editorAPI.componentFocusMode.isEnabled();

  if (isCompFocusMode) {
    editorAPI.componentFocusMode.showCompOnTopIfNeeded();
  } else {
    editorAPI.dsActions.renderPlugins.setCompsToShowOnTop(null);
  }

  editorAPI.dsActions.renderPlugins.setCompsToShowWithOpacity([]);

  editorAPI.dsActions.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
}

async function onDragEndDragLayout(scope: DragMoveToScope) {
  if (isMeshLayoutEnabled()) {
    scope.meshMoveByTransition.end();
    return;
  }

  await scope.optimisticDragHandler.finish();
}

/** @deprecated check DragMoveToPluginMesh */
export const DragMoveToPlugin: BaseDragPlugin = {
  init: ({ pluginFactoryScope, hooks }) => {
    const { editorAPI, selectedComp, initMousePosition } = pluginFactoryScope;

    const initialCompLayout =
      editorAPI.components.layout.getRelativeToScreen(selectedComp);

    const isHorizontallyMovable =
      editorAPI.components.is.horizontallyMovable(selectedComp);
    const isVerticallyMovable =
      editorAPI.components.is.verticallyMovable(selectedComp);

    const optimisticDragHandler = new OptimisticDragHandler(
      editorAPI,
      selectedComp,
    );

    const initCompLayoutsRelativeToScreen = selectedComp.map((comp: AnyFixMe) =>
      editorAPI.components.layout.getRelativeToScreen(comp),
    );

    const initCompLayouts = selectedComp.map((comp: AnyFixMe) =>
      editorAPI.components.layout.get(comp),
    );
    const considerParentLayoutChange = shouldConsiderParentLayoutChange(
      editorAPI,
      selectedComp,
    );

    const dragTransition = new layoutTransitionsUtil.Drag(
      initialCompLayout,
      initMousePosition,
      initCompLayoutsRelativeToScreen,
    );

    const scope: DragMoveToScope = {
      editorAPI,
      isHorizontallyMovable,
      isVerticallyMovable,
      initialCompLayout,
      selectedComp,
      optimisticDragHandler,
      dragTransition,
      initMousePosition,
      considerParentLayoutChange,
      //todo refactor
      initCompLayoutsRelativeToScreen,
      initCompLayouts,
      meshMoveByTransition: isMeshLayoutEnabled()
        ? editorAPI.components.layout.__mesh.moveByTransition(selectedComp)
        : null,
    };

    hooks.onDragStart.tap(() => onDragStart(scope));
    hooks.updateLayout.tap((args) => onDragMove(scope, args));
    hooks.endDragLayout.tap(() => onDragEndDragLayout(scope));
    hooks.endDrag.tap(() => onDragEnd(scope));
  },
};

// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  TextLabel,
} from '@wix/wix-base-ui';
import { ConfirmationPanelFrame } from '../../frames';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  panelName?: string;
}

export default class extends React.Component<Props> {
  static displayName = 'AutopilotUpdatePanel';

  static propTypes = {
    panelName: PropTypes.string,
  };

  render() {
    return (
      <ConfirmationPanelFrame
        automationId={this.props.panelName}
        panelName={this.props.panelName}
        shouldHideFooter={true}
        shouldHideHeader={true}
        className="autopilot-update-panel"
      >
        <Composites.PopupMediumSize>
          <Composites.BigPanelHeaderWithIllustration>
            <PanelHeaderCenteredText
              title="OfflineInstallation_PopUp_Title"
              titleType="T13"
              shouldTranslate={true}
              shouldHideCloseButton={true}
            />
            <PanelHeaderIllustration blueStripHeight={80}>
              <symbols.symbol name="autopilotUpdate" key="illustrationSymbol" />
            </PanelHeaderIllustration>
          </Composites.BigPanelHeaderWithIllustration>

          <Composites.PanelContent>
            <Composites.RichTextWithIllustration className="composite-centered">
              <Composites.RichTextLabeled className="composite-centered">
                <TextLabel
                  value="OfflineInstallation_PopUp_Text1"
                  shouldTranslate={true}
                  type="T01"
                />
                <TextLabel
                  value="OfflineInstallation_PopUp_Text2"
                  shouldTranslate={true}
                  type="T01"
                />
              </Composites.RichTextLabeled>
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>

          <Composites.ActionSetVertical>
            <Button onClick={this.refresh}>
              {translate('OfflineInstallation_PopUp_Refresh_Button')}
            </Button>
          </Composites.ActionSetVertical>
        </Composites.PopupMediumSize>
      </ConfirmationPanelFrame>
    );
  }

  refresh = () => {
    util.windowInteractionUtils.reloadPageWithoutQuestion();
  };
}

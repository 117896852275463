import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text, TextButton } from '@wix/wix-base-ui';
import { cx, hoc } from '#packages/util';
import { withRestrictions } from '#packages/editorRestrictions';
import { AUTOMATION_IDS } from '../constants';
import { mapStateToProps } from './SectionEmptyState.mapper';
import styles from './SectionsEmptyState.scss';

import type { SectionEmptyStateStateProps } from './SectionEmptyState.mapper';

export interface SectionEmptyStateOwnProps {
  onAddElementsClick: React.MouseEventHandler;
  onAddSectionClick: React.MouseEventHandler;
}

interface SectionEmptyStateProps
  extends SectionEmptyStateOwnProps,
    SectionEmptyStateStateProps {}

const SectionEmptyStateComponent: React.FC<SectionEmptyStateProps> = ({
  onAddSectionClick,
  onAddElementsClick,
  isLightTextColor,
  isEmptyStateAddPanelVisible,
  isEmptyStateTitleTextVisible,
  style,
}) => {
  const { t } = useTranslation();

  const renderLinks = () => {
    if (isEmptyStateAddPanelVisible) {
      return (
        <Trans i18nKey="section_blank_empty_state_text_2">
          <TextButton
            size="small"
            skin="standard"
            weight="normal"
            shouldTranslate={false}
            dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_SECTION}
            onClick={onAddSectionClick}
          />
          <TextButton
            size="small"
            skin="standard"
            weight="normal"
            shouldTranslate={false}
            dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_ELEMENT}
            onClick={onAddElementsClick}
          />
        </Trans>
      );
    }

    return (
      <TextButton
        size="small"
        skin="standard"
        weight="normal"
        dataHook={AUTOMATION_IDS.EMPTY_STATE.ADD_SECTION}
        onClick={onAddSectionClick}
      >
        lite_editor_blank_section_empty_state_text
      </TextButton>
    );
  };

  return (
    <div
      data-hook={AUTOMATION_IDS.EMPTY_STATE.CONTAINER}
      className={cx(styles.emptyStateContainer, {
        [styles.light]: isLightTextColor,
      })}
      style={style}
    >
      {isEmptyStateTitleTextVisible && (
        <Text
          size="small"
          skin="secondary"
          weight="normal"
          shouldTranslate={false}
        >
          {t('section_blank_empty_state_text_1')}
        </Text>
      )}
      <div
        className={styles.linksContainer}
        onMouseDown={(event) => event.stopPropagation()}
      >
        {renderLinks()}
      </div>
    </div>
  );
};

export const SectionEmptyState = withRestrictions('sections_empty-state')(
  hoc.connect(
    hoc.STORES.EDITOR_API,
    mapStateToProps,
    null,
  )(SectionEmptyStateComponent),
);

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import _ from 'lodash';
import mobileSettingsBackToTop from './mobileSettingsBackToTop';
import mobileSettingsActionBar from './mobileSettingsActionBar';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mobileElementSettings',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    id: PropTypes.string,
  },
  render() {
    const templates = {
      backToTop: mobileSettingsBackToTop,
      actionBar: mobileSettingsActionBar,
    };

    return React.createElement(
      templates[this.props.id],
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({}, this.props),
    );
  },
});

import React, { useEffect, useState, type FC } from 'react';

import { translate } from '#packages/i18n';
import { Text } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { frames } from '#packages/panels';
import { hoc } from '#packages/util';

import styles from './styles.scss';
import type { SiteReadinessPanelProps } from './siteReadinessPanelTypes';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './siteReadinessPanelMapper';
import { TRANSLATION_KEYS } from '../../consts';
import { LoadingContent } from './components/loadingContent';
import { Categories } from './components/categories';

const { CustomPanelFrame } = frames;
const LOADING_TIME = 2000;

const SiteReadinessPanel: FC<SiteReadinessPanelProps> = ({
  closePanel,
  openConnectDomain,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, LOADING_TIME);
  }, [setIsLoading]);

  return (
    <CustomPanelFrame
      panelName="siteReadinessPanel"
      dataHook="site-readiness-panel"
      className={isLoading ? styles.loaderPanel : styles.panel}
      onCloseButtonClick={isLoading ? null : () => closePanel('X')}
      onEscKeyPress={() => closePanel('Esc')}
    >
      {isLoading ? (
        <LoadingContent />
      ) : (
        <div
          className={styles.contentWrapper}
          data-hook="site-readiness-main-content"
        >
          <Symbol name="aiIllustration" />
          <div>
            <Text size="medium" weight="bold" shouldTranslate={false}>
              {translate(TRANSLATION_KEYS.RESULTS_TITLE)}
            </Text>
            <Text
              size="small"
              weight="thin"
              skin="secondary"
              shouldTranslate={false}
              customEllipsisProps={{ maxLines: 2 }}
            >
              {translate(TRANSLATION_KEYS.RESULTS_SUBTITLE)}
            </Text>
          </div>

          <Categories openConnectDomain={openConnectDomain} />
        </div>
      )}
    </CustomPanelFrame>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(SiteReadinessPanel);

// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import SectionHeader from './sectionHeader/sectionHeader';
import SectionFooter from './sectionFooter/sectionFooter';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  className?: string;
  hasSubCategory?: boolean;
  getHeightOfScroll?: FunctionFixMe;
  handleMouseEnter?: FunctionFixMe;
  handleMouseLeave?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'sectionWrapper';

  static propTypes = {
    hasSubCategory: PropTypes.bool,
    getHeightOfScroll: PropTypes.func,
    handleMouseEnter: PropTypes.func,
    handleMouseLeave: PropTypes.func,
    title: PropTypes.string,
  };

  getSectionFooterProps = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, this.props.sectionFooter, {
      setSessionUserPreferences:
        stateManagement.userPreferences.actions.setSessionUserPreferences,
      addPanelBiEvent: coreBi.events.addPanel,
      categoryId: this.props.categoryId,
      sectionName: this.props.sectionName,
    });
  };

  render() {
    return (
      <div
        data-section-title={translate(this.props.title)}
        data-tooltip-bounds="true"
        style={this.props.style}
        className={util.inheritClassName(this.props, 'section-wrapper')}
      >
        <SectionHeader {...this.props} />
        {this.props.children}
        {this.props.sectionFooter && !this.props.sectionFooter.hide ? (
          <SectionFooter key="footer" {...this.getSectionFooterProps()} />
        ) : null}
      </div>
    );
  }
}

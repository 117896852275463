// @ts-nocheck
import React from 'react';
import * as util from '#packages/util';
import PropTypes from 'prop-types';
import _ from 'lodash';
import editorConstants from '#packages/constants';
import constants from '../../../utils/constants';
import * as pageSettingsDesktopMapper from './pageSettingsDesktopMapper';
import * as coreBi from '#packages/coreBi';
import ApplicationSettingsPanelTabs from '../../../settings/applicationSettingsPanelTabs';
import PageSettingsPanelTabs from '../../../settings/pageSettingsPanelTabs';
import PageSettingsDesktopCommon from './pageSettingsDesktopCommon';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

class PageSettingsDesktop extends React.Component {
  constructor(props) {
    super(props);

    this.setHelpIds = (helpIds) => {
      this.setState({ helpIds });
    };

    this.setActiveTabType = (activeTabType) => {
      this.setState({ activeTabType });
    };

    this.setActiveTabTypeAndSendBI = (activeTabType) => {
      this.setState({ activeTabType });
      this.props.sendBi(
        coreBi.events.topbar.pages.top_bar_PAGES_settings_tab_click,
        { category: activeTabType },
      );
    };

    this.getValidActiveTabTypeForApplicationPage = (
      pageId,
      proposedActiveTabType,
    ) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      const tabTypes = _.map(this.props.applicationTabs, 'type');
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      return _.includes(tabTypes, proposedActiveTabType)
        ? proposedActiveTabType
        : this.getDefaultTabType(pageId, this.props.applicationTabs);
    };

    this.tryToPreserveSelectedTab = (newPageData, oldPageData, nextProps) => {
      const newPageBelongsToApp =
        !!this.props.getRouterDataForPageIfExist(newPageData.id) ||
        !!newPageData?.managingAppDefId;
      const oldPageBelongsToApp =
        !!this.props.getRouterDataForPageIfExist(oldPageData.id) ||
        !!oldPageData?.managingAppDefId;
      const switchTabsControl =
        (newPageBelongsToApp && !oldPageBelongsToApp) ||
        (!newPageBelongsToApp && oldPageBelongsToApp);

      if (switchTabsControl) {
        this.setActiveTabType(this.getDefaultTabType(newPageData.id));
      } else if (newPageBelongsToApp && oldPageBelongsToApp) {
        const newPageTabs = nextProps.applicationTabs;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        const sameTabAsActive = _.find(newPageTabs, {
          type: this.state.activeTabType,
        });
        if (!sameTabAsActive) {
          this.setActiveTabType(this.getDefaultTabType(newPageData.id));
        }
      }
    };

    this.getHelpId = () => {
      if (this.props.isCustomErrorPage) {
        return constants.CUSTOM_ERROR_PAGE_HELP_ID;
      }

      if (this.props.isApplicationOverridingSettingTab) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        const activeTab = _.find(this.props.applicationTabs, {
          type: this.state.activeTabType,
        });
        if (!activeTab || !activeTab.helpId) {
          return '995c5103-5599-4725-9e4f-c6ae4a47bf35';
        }
        return activeTab.helpId;
      }

      return (
        constants.STATIC_PAGES_HELP_IDS[this.state.activeTabType] ||
        _.get(this.state.helpIds, this.state.activeTabType)
      );
    };

    this.getDefaultTabType = (pageId, dynamicTabs) => {
      pageId = pageId || this.props.menuItem.pageData?.id;
      if (this.props.isApplicationOverridingSettingTab) {
        const tabs = dynamicTabs || this.props.applicationTabs;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        const pageInfoTab = _.find(tabs, [
          'type',
          editorConstants.PAGE_SETTINGS_TABS.PAGE_INFO,
        ]);
        return pageInfoTab ? pageInfoTab.type : tabs[0].type;
      }

      return editorConstants.PAGE_SETTINGS_TABS.PAGE_INFO;
    };

    let activeTabType =
      this.props.activeTabType &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/values
        _.values(editorConstants.PAGE_SETTINGS_TABS),
        this.props.activeTabType,
      )
        ? this.props.activeTabType
        : this.getDefaultTabType();
    if (this.props.isApplicationOverridingSettingTab) {
      activeTabType = this.getValidActiveTabTypeForApplicationPage(
        this.props.menuItem.pageData.id,
        this.props.activeTabType,
      );
    }

    this.state = {
      activeTabType,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.activeTabType &&
      this.props.activeTabType !== nextProps.activeTabType
    ) {
      this.setActiveTabType(nextProps.activeTabType);
    }

    const newPageData = nextProps.menuItem.pageData;
    const oldPageData = this.props.menuItem.pageData;

    if (newPageData.id !== oldPageData.id) {
      if (nextProps.activeTabType) {
        if (this.props.isApplicationOverridingSettingTab) {
          this.setActiveTabType(
            this.getValidActiveTabTypeForApplicationPage(
              newPageData.id,
              nextProps.activeTabType,
            ),
          );
        } else {
          this.setActiveTabType(nextProps.activeTabType);
        }
      } else {
        this.tryToPreserveSelectedTab(newPageData, oldPageData, nextProps);
      }
    }
  }

  render() {
    return (
      <PageSettingsDesktopCommon
        title={this.props.menuItem.label}
        closeSettingsPanel={this.props.closeSettingsPanel}
        helpId={this.getHelpId()}
      >
        {!this.props.isApplicationOverridingSettingTab ? (
          <PageSettingsPanelTabs
            key="pageSettingsPanelTabs"
            setActiveTabType={this.setActiveTabTypeAndSendBI}
            selectedTabType={this.state.activeTabType}
            setHelpIds={this.setHelpIds}
            {...this.props}
          />
        ) : (
          <ApplicationSettingsPanelTabs
            developerModeEnabled={this.props.isDeveloperModeEnabled}
            applicationTabs={this.props.applicationTabs}
            setActiveTabType={this.setActiveTabTypeAndSendBI}
            selectedTabType={this.state.activeTabType}
            key="applicationSettingsPanelTabs"
            setHelpIds={this.setHelpIds}
            {...this.props}
          />
        )}
      </PageSettingsDesktopCommon>
    );
  }
}

PageSettingsDesktop.displayName = 'pageSettingsDesktopNew';
PageSettingsDesktop.propTypes = {
  sendBi: PropTypes.func.isRequired,
  getRouterDataForPageIfExist: PropTypes.func.isRequired,
  isApplicationOverridingSettingTab: PropTypes.bool.isRequired,
  isDeveloperModeEnabled: PropTypes.bool.isRequired,
  isCustomErrorPage: PropTypes.bool.isRequired,
  applicationTabs: PropTypes.array.isRequired,
  menuItem: PropTypes.object.isRequired,
  actions: PropTypes.object,
  activeTabType: PropTypes.string,
  closeSettingsPanel: PropTypes.func.isRequired,
  hideLayoutTab: PropTypes.bool,
};

const Connected = connect(
  EDITOR_API,
  pageSettingsDesktopMapper.mapStateToProps,
  pageSettingsDesktopMapper.mapDispatchToProps,
)(PageSettingsDesktop);
Connected.pure = PageSettingsDesktop;
export default Connected;

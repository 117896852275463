import _ from 'lodash';
import * as util from '#packages/util';
import { imageOrBgReplacedOnStage } from '@wix/bi-logger-editor/v2';
import { vectorImageUtils } from '@wix/santa-editor-utils';
import constants from '#packages/constants';
import { EditorAPIKey } from '#packages/apis';
import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import { layoutUtils } from '#packages/layoutUtils';
import type { CompRef } from 'types/documentServices';
import * as imageKit from '@wix/image-kit';
import * as mediaManagerAPI from '@wix/media-manager-kit';
import {
  MediaImageStudio,
  MediaImageStudioEvents,
  MediaImageStudioMode,
} from '@wix/media-image-studio-opener';
import type {
  MediaImageStudioUploadedFileMeta,
  MediaImageStudioOptions,
} from '@wix/media-image-studio-opener';
import * as UserFeedbackOpener from 'UserFeedbackOpener';
import * as videoMakerOpener from '@wix/video-maker-opener';

import { getSiteVideoQuota } from './videoQuota';
import './setMediaFrame';

import type { Shell } from '#packages/apilib';
import type { MediaManagerVideo } from './mediaManager.types';

const videoBiEvents = coreBi.events.singleVideoPlayer;
const { backgroundUtils } = util;
const {
  getMediaPlayerVideoObject,
  getChangeVideoBiEventFields,
  getStoryboardDisplayValue,
} = backgroundUtils;
const { setSessionUserPreferences } = stateManagement.userPreferences.actions;
const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;

const { getMediaPath } = stateManagement.userPreferences.selectors;
const { CLIPART_DATA_KEYS } = constants.UI;
const {
  VECTOR_IMAGE_CATEGORY,
  VECTOR_SHAPE_DEFAULT_PATH,
  VECTOR_ART_DEFAULT_PATH,
} = constants.VECTOR_IMAGE_DEFAULTS;
const DEFAULT_ALPHA_VIDEO_PATH =
  'public/5d54b10e-e5e3-416b-9a2f-9316773f2a5b/078ecd58-f828-44f6-ac91-8cafbf5dbdae';
const DEFAULT_VIDEO_PATH =
  'public/8e256233-1752-4026-9341-54036e7f875e/d6b6b972-952c-4ab0-b46a-a6c05a0a94a5';
const imageMediaSource = 'change_image';
const vectorArtMediaSource = 'change_vector_art';
const shapeMediaSource = 'change_basic_shape';
const videoMediaSource = 'change_video_box';

const FREE_FROM_WIX_ROOT = 'public';

/**
 *
 * @param {string} fpsString   e.g. "30" | "23.59" | "900/30"
 * @returns {number} fps as float
 */
function parseFPS(fpsString: AnyFixMe) {
  const fpsArr = fpsString.split('/');
  return fpsArr.length === 2
    ? parseFloat(fpsArr[0]) / parseFloat(fpsArr[1])
    : parseFloat(fpsArr[0]);
}

function hasSlowMo(fpsString: AnyFixMe) {
  return parseFPS(fpsString) > 50;
}

function prepareFileName(originalFileName: AnyFixMe) {
  return _.truncate(originalFileName, { length: 40, omission: '' });
}

/**
 *
 * @param {string} fpsString   e.g. "30" | "23.59" | "900/30"
 * @returns {number}
 */
function getPlaybackSpeedByFPS(fpsString: AnyFixMe) {
  return fpsString && hasSlowMo(fpsString) ? 30 / parseFPS(fpsString) : 1;
}

function getCalculatedCropData(
  sWidth: AnyFixMe,
  sHeight: AnyFixMe,
  tWidth: AnyFixMe,
  tHeight: AnyFixMe,
  fittingType: AnyFixMe,
) {
  if (
    fittingType === imageKit.fittingTypes.SCALE_TO_FILL ||
    fittingType === imageKit.fittingTypes.LEGACY_FULL
  ) {
    // adjust target rectangle
    const scaleFactor = Math.min(tWidth / sWidth, tHeight / sHeight);
    const width = sWidth * scaleFactor;
    const height = sHeight * scaleFactor;
    return {
      width: Math.round(width),
      height: Math.round(height),
      x: Math.round((tWidth - width) / 2),
      y: Math.round((tHeight - height) / 2),
    };
  }

  return null;
}

function changeDataByMediaFeatures(data: AnyFixMe, newFeatures: AnyFixMe) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  const hasAlpha = _.includes(newFeatures, 'alpha');
  const newData = _.cloneDeep(data);

  if (hasAlpha) {
    newData.cssStyle = {
      cssBorder: null,
      cssBorderRadius: null,
      cssBoxShadow: null,
    };

    newData.background.imageOverlay = null;
    newData.background.colorOverlay = '';
  } else {
    newData.background.filterEffect = null;
  }

  return newData;
}

interface OpenMediaStudioParams {
  imageFileId?: string;
  mode?: MediaImageStudioMode;
  initiator: string;
  isUpload?: boolean;
}

interface PhotoFileInfo {
  id: string;
  name: string;
  width: number;
  height: number;
  hasAnimation?: boolean;
}

export const createMediaServicesApi = (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  function updateWPhotoCompData(
    compRef: CompRef,
    fileInfo: PhotoFileInfo,
    addingMethod?: string,
    origin?: string,
  ) {
    const { fittingTypes } = util.imageTransform;
    const compData = editorAPI.components.data.get(compRef);
    const compLayout = editorAPI.components.layout.get_size(compRef);
    const currentDisplayMode =
      editorAPI.components.properties.get(compRef).displayMode;
    const { width, height, hasAnimation } = fileInfo;

    const newImageData = {
      width,
      height,
      uri: fileInfo.id,
      name: fileInfo.name,
      alt: fileInfo.name,
      crop: null as AnyFixMe,
      focalPoint: null as AnyFixMe,
      originalImageDataRef: null as AnyFixMe,
      hasAnimation,
    };

    // set new crop data if crop is allowed on the new type of image
    if (
      compData.crop &&
      editorAPI.imageCrop.isCropAllowed(newImageData.uri) &&
      !util.url.isExternalUrl(newImageData.uri)
    ) {
      const cropDimensions = getCalculatedCropData(
        compLayout.width,
        compLayout.height,
        width,
        height,
        currentDisplayMode,
      );

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      newImageData.crop = _.assign({}, compData.crop, cropDimensions);
    }

    editorAPI.components.data.update(compRef, newImageData, true);

    util.biLogger.report(
      imageOrBgReplacedOnStage({
        addingMethod,
        origin,
        component_id: compRef.id,
        component_type: editorAPI.components.getType(compRef),
      }),
    );

    if (currentDisplayMode === util.imageTransform.fittingTypes.LEGACY_FULL) {
      editorAPI.components.properties.update(
        compRef,
        { displayMode: fittingTypes.SCALE_TO_FILL },
        true,
      );
    }
  }

  function openChangeImageModal(compRef: CompRef, source = imageMediaSource) {
    const compData = editorAPI.components.data.get(compRef);
    const { dispatch, getState } = editorAPI.store;
    const editorState = getState();
    const path = getMediaPath(compData.uri)(editorState);
    return new Promise<AnyFixMe>((resolve) => {
      editorAPI.mediaServices.mediaManager.open(
        editorAPI.mediaServices.mediaManager.categories.IMAGE,
        source,
        {
          translation: {
            title: translate('Image_Media_Choose_Image'),
            submitButton: translate(
              'MMGR_submitbutton_onstage_image_uploads_choose',
            ),
          },
          path,
          multiSelect: false,
          callback(payload: { fileName: string }[], info: AnyFixMe) {
            if (!payload) {
              return;
            }

            const { fileName } = _.head(payload);

            dispatch(
              setSessionUserPreferences(
                `last_media_path_${fileName}`,
                info.path,
              ),
            );

            setFocusToEditor();
            resolve(payload);
          },
        },
      );
    });
  }

  async function changeWPhoto(compRef: CompRef, source: string) {
    const payload = await openChangeImageModal(compRef, source);
    const {
      width,
      height,
      fileName,
      title,
      fileInput,
    }: {
      width: number;
      height: number;
      fileName: string;
      title: string;
      fileInput: any;
    } = _.head(payload);
    updateWPhotoCompData(
      compRef,
      {
        width,
        height,
        id: fileName,
        name: title,
        hasAnimation: !!fileInput?.animated,
      },
      'media',
    );
    editorAPI.history.add('Change Image');
  }

  function adjustWPhoto(
    compRef: CompRef,
    initiator: string,
    mode: MediaImageStudioMode = MediaImageStudioMode.Adjust,
  ) {
    const compData = editorAPI.components.data.get(compRef);
    const params: OpenMediaStudioParams = {
      imageFileId: compData.uri,
      initiator,
      mode,
    };
    openMediaStudio(params, (fileInfo: MediaImageStudioUploadedFileMeta) => {
      const { width, height, id, name } = fileInfo;
      updateWPhotoCompData(
        compRef,
        {
          width,
          height,
          id,
          name: prepareFileName(name),
        },
        'adjust',
        initiator,
      );

      editorAPI.history.add('Adjust Image');
      setFocusToEditor();
    });
  }

  function generateWPhoto(compRef: CompRef, initiator?: string) {
    const params = {
      isUpload: false,
      mode: MediaImageStudioMode.Generate,
      initiator,
    };
    openMediaStudio(params, (fileInfo: MediaImageStudioUploadedFileMeta) => {
      const { width, height, id, name } = fileInfo;
      updateWPhotoCompData(
        compRef,
        {
          width,
          height,
          id,
          name: prepareFileName(name),
        },
        'ai_image',
        initiator,
      );

      editorAPI.history.add('Generate Image');
      setFocusToEditor();
    });
  }

  function openMediaStudio(
    params: OpenMediaStudioParams,
    callback: (fileInfo: MediaImageStudioUploadedFileMeta) => void,
  ) {
    const { mediaStudio } = editorAPI.mediaServices;

    const fileUploadedSubscription = mediaStudio.once(
      MediaImageStudioEvents.UploadedFile,
      (fileInfo) => {
        callback(fileInfo);
        setFocusToEditor();
      },
    );

    const pStudioClosedSubscription = mediaStudio.once(
      MediaImageStudioEvents.Close,
      () => {
        fileUploadedSubscription.remove();
        pStudioClosedSubscription.remove();
      },
    );

    const fileKeyName = params.imageFileId?.includes('/')
      ? 'fileUrl'
      : 'fileId';

    mediaStudio.show({
      [fileKeyName]: params.imageFileId,
      mode: params.mode ?? MediaImageStudioMode.Adjust,
      initiator: params.initiator,
      isUpload: params.isUpload,
    });
  }

  function adjustImageX(compRef: CompRef | CompRef[], initiator: string) {
    return new Promise((resolve) => {
      const { mediaStudio } = editorAPI.mediaServices;
      const compData = editorAPI.components.data.get(compRef);

      const { image } = compData;

      const fileUploadedSubscription = mediaStudio.once(
        MediaImageStudioEvents.UploadedFile,
        (fileInfo: MediaImageStudioUploadedFileMeta) => {
          setFocusToEditor();

          const { width, height, id } = fileInfo;
          const newImageData = {
            width,
            height,
            uri: id,
          };

          resolve(newImageData);
        },
      );

      const pStudioClosedSubscription = mediaStudio.once(
        MediaImageStudioEvents.Close,
        () => {
          fileUploadedSubscription.remove();
          pStudioClosedSubscription.remove();
        },
      );

      mediaStudio.show({
        fileId: image.uri,
        mode: MediaImageStudioMode.Adjust,
        initiator,
      });
    });
  }

  function changeClipArt(compRef: CompRef, category: AnyFixMe) {
    const compData = editorAPI.components.data.get(compRef);
    const state = editorAPI.store.getState();

    const path = getMediaPath(compData.uri)(state);

    editorAPI.mediaServices.mediaManager.open(category, {
      multiSelect: false,
      translation: {
        submitButton: translate(
          'MMGR_submitbutton_onstage_clipart_freeclipart_choose',
        ),
      },
      path,
      callback(payload: AnyFixMe, info: AnyFixMe) {
        if (!payload) {
          return;
        }

        const newClipartData = _.pick(
          payload[0],
          CLIPART_DATA_KEYS,
        ) as AnyFixMe;
        newClipartData.uri = payload[0].fileName;
        newClipartData.originalImageDataRef = null;
        newClipartData.crop = null;
        editorAPI.store.dispatch(
          setSessionUserPreferences(
            `last_media_path_${newClipartData.uri}`,
            info.path,
          ),
        );
        editorAPI.components.data.update(compRef, newClipartData, true);

        editorAPI.history.add('Change Clipart');
        setFocusToEditor();
      },
    });
  }

  function changeVectorImage(
    compRef: CompRef,
    category: AnyFixMe,
    defaultPath: AnyFixMe,
    callback: AnyFixMe,
  ) {
    const state = editorAPI.store.getState();
    const data = editorAPI.components.data.get(compRef);
    const svgId = data?.svgId;
    const svgInfo = editorAPI.media.vectorImage.getSvgInfoFromCache(svgId);
    const { svgType } = svgInfo;
    const defaultPathBySvgType =
      svgType === 'shape' ? VECTOR_SHAPE_DEFAULT_PATH : VECTOR_ART_DEFAULT_PATH;
    const openSource =
      svgType === 'shape' ? shapeMediaSource : vectorArtMediaSource;
    const path =
      getMediaPath(svgId)(state) || defaultPath || defaultPathBySvgType;

    editorAPI.mediaServices.mediaManager.open(
      editorAPI.mediaServices.mediaManager.categories[
        category || VECTOR_IMAGE_CATEGORY
      ],
      openSource,
      {
        multiSelect: false,
        callback(items: AnyFixMe, info: AnyFixMe) {
          if (_.isEmpty(items)) {
            return;
          }

          const title = items?.[0]?.title ?? '';
          const newSvgId = items?.[0]?.uri;

          vectorImageUtils.replaceVectorImage(
            editorAPI,
            compRef,
            data,
            title,
            newSvgId,
            callback,
          );

          editorAPI.store.dispatch(
            setSessionUserPreferences(
              `last_media_path_${newSvgId}`,
              info.path || '',
            ),
          );
          setFocusToEditor();
        },
        path,
      },
    );
  }

  function changeShape(compRef: CompRef) {
    const { mediaServices } = editorAPI;
    mediaServices.mediaManager.open(
      mediaServices.mediaManager.categories.SHAPE,
      shapeMediaSource,
      {
        multiSelect: false,
        callback(items: AnyFixMe) {
          if (_.isEmpty(items)) {
            return;
          }

          const newSkinName = util.svgUrlParser.parseSvgSkinFromUrl(
            items[0].fileUrl,
          );
          const styleDataItem = editorAPI.components.style.get(compRef);

          if (newSkinName === styleDataItem.skin) {
            return;
          }

          styleDataItem.skin = newSkinName;

          editorAPI.components.style.update(compRef, styleDataItem);
          editorAPI.history.add('Change Shape');
          setFocusToEditor();
        },
        translation: {
          submitButton: translate('Shape_Media_Change_Shape'),
        },
      },
    );
  }

  function changeVideo(
    compRef: CompRef,
    category = editorAPI.mediaServices.mediaManager.categories.VIDEO,
    origin: string = 'video_player_gfpp',
  ) {
    const { dispatch, getState } = editorAPI.store;
    const data = editorAPI.components.design.get(compRef);
    const mediaData = data.background?.mediaRef ?? {};
    const properties = editorAPI.components.properties.get(compRef);

    const mobileCompRef = { id: compRef.id, type: 'MOBILE' as const };
    const mobileProperties = editorAPI.components.properties.get(mobileCompRef);

    const layout = editorAPI.components.layout.get(compRef);
    const { videoId } = mediaData;
    const mediaFeatures = mediaData.mediaFeatures || [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const defaultVideoPath = _.includes(mediaFeatures, 'alpha')
      ? DEFAULT_ALPHA_VIDEO_PATH
      : DEFAULT_VIDEO_PATH;
    const mmgrMediaPath =
      getSessionUserPreferences(`last_media_path_${videoId}`)(getState()) ||
      defaultVideoPath;
    editorAPI.mediaServices.mediaManager.open(category, videoMediaSource, {
      multiSelect: false,
      callback(payload: MediaManagerVideo[], info: AnyFixMe) {
        if (!payload) {
          return;
        }
        const fileInfo = _.head(payload);
        const mediaObj = getMediaPlayerVideoObject(fileInfo, info);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        const isAlpha = _.includes(mediaObj.mediaFeatures, 'alpha');

        //layout modification transparent video
        if (isAlpha) {
          const { width, height } =
            _.maxBy(mediaObj.qualities, 'width') || ({} as AnyFixMe);
          const mediaAspect = width / height;
          const limits = editorAPI.dsRead.components.layout.getLimits(
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
            _.isArray(compRef) ? compRef[0] : compRef,
          );
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          const layoutWithLimits = _.assign({}, limits, layout);
          const newLayout = layoutUtils.getLayoutWithAspectRatio(
            layoutWithLimits,
            mediaAspect,
            null,
          );
          editorAPI.components.layout.update(compRef, newLayout, true);
        }

        //maintain media data
        const newData = changeDataByMediaFeatures(data, mediaObj.mediaFeatures);
        newData.background.mediaRef = mediaObj;

        /* mediaRef overrides */
        let hasMask = false;
        if (isAlpha) {
          newData.background.mediaRef.mask = null;
        } else {
          hasMask = mediaData.mask?.svgId;
          if (hasMask) {
            newData.background.mediaRef.mask = mediaData.mask;
          }
        }

        editorAPI.components.design.update(compRef, newData, true);

        // maintain properties
        const mediaProps = {};
        if (properties?.autoplay) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign(mediaProps, {
            autoplay: true,
            mute: true,
          });
        }

        editorAPI.components.properties.update(compRef, mediaProps);
        const hasMobileMask = mobileProperties?.overrideMask;
        if (hasMobileMask) {
          const mobileProps: AnyFixMe = {};

          if (isAlpha) {
            mobileProps.overrideMask = null;
          } else {
            mobileProps.overrideMask = mobileProperties.overrideMask;
          }

          editorAPI.components.properties.update(mobileCompRef, mobileProps);
        }

        // UPDATE CONTROLS

        // get media controls
        const children =
          editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compRef);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        const mediaControlsRef = _.find(children, function (controlRef) {
          return (
            editorAPI.components.getType(controlRef) ===
            'wysiwyg.viewer.components.MediaControls'
          );
        });

        // get media controls  properties;
        const mediaControlsProperties =
          editorAPI.components.properties.get(mediaControlsRef);
        const currentShowStoryboard = mediaControlsProperties?.showStoryboard;
        mediaControlsProperties.showStoryboard = getStoryboardDisplayValue(
          mediaObj,
          currentShowStoryboard,
        );
        editorAPI.components.properties.update(
          mediaControlsRef,
          mediaControlsProperties,
          true,
        );

        // UPDATE UNDO STACK

        editorAPI.history.add('Change Video');
        editorAPI.store.dispatch(
          stateManagement.bi.actions.event(
            videoBiEvents.video_player_video_changed,
            getChangeVideoBiEventFields(payload[0], origin, {
              hasMask: hasMask || hasMobileMask,
            }),
          ),
        );

        // UPDATE PATH
        dispatch(
          setSessionUserPreferences(
            `last_media_path_${mediaObj.videoId}`,
            info.path || '',
          ),
        );

        setFocusToEditor();
      },
      path: mmgrMediaPath,
    });
  }

  /**
   * used when media manager iframe closes , needs to return the focus to the editor
   */
  function setFocusToEditor() {
    const editor = window.document.querySelector('#editor') as HTMLElement;
    if (editor) {
      const { document } = window;
      const y = document.documentElement.scrollTop || document.body.scrollTop;
      editor.focus();
      document.documentElement.scrollTop = document.body.scrollTop = y;
    }
  }

  function getSiteUploadToken() {
    return editorAPI.dsRead.generalInfo.media.getSiteUploadToken();
  }

  function transferChunk(payload: AnyFixMe) {
    const FILES_API_BASE_URL = '//files.wix.com';
    return util.http.fetch(`${FILES_API_BASE_URL}/site/media/editor/copy`, {
      method: 'POST',
      credentials: 'include',
      headers: new Headers({
        'content-type': 'application/json; charset=utf-8',
      }),
      body: JSON.stringify(payload),
    });
  }

  const MAX_TRANSFER_SIZE = 100;
  function transferMediaItems(ids: AnyFixMe, originSiteToken: AnyFixMe) {
    const targetSiteToken = getSiteUploadToken();
    const items = ids.map((id: AnyFixMe) => ({
      item_id: id,
      item_type: 'file',
    }));

    const chunks = _.chunk(items, MAX_TRANSFER_SIZE);
    return Promise.all(
      chunks.map((chunk) =>
        transferChunk({
          destination: { site_token: targetSiteToken },
          site_token: originSiteToken,
          items: chunk,
        }),
      ),
    );
  }

  type UnsafeOpenMethod = (
    categoryName: AnyFixMe,
    appIdOrOptions: AnyFixMe,
    options?: AnyFixMe,
  ) => void;

  function createSafeOpen(unsafeOpenMethod: UnsafeOpenMethod) {
    return (categoryName: AnyFixMe, ...args: AnyFixMe[]) => {
      let options;
      let appID;
      if (args.length === 1) {
        options = args[0];
      } else {
        appID = args[0];
        options = args[1];
      }
      if (
        stateManagement.schoolMode.selectors.isEnabled(
          editorAPI.store.getState(),
        )
      ) {
        options = Object.assign({}, options, {
          restrictK12Content: true,
          path: FREE_FROM_WIX_ROOT,
          onlyCurrentUser: true,
        });
      }
      if (appID) {
        unsafeOpenMethod(categoryName, appID, options);
      } else {
        unsafeOpenMethod(categoryName, options);
      }
    };
  }

  return {
    initMediaManager: (config: AnyFixMe) => {
      editorAPI.mediaServices.mediaManager = mediaManagerAPI.create(
        Object.assign(config, {
          origin: mediaManagerAPI.origins.EDITOR,
        }),
      );

      if (
        stateManagement.schoolMode.selectors.isEnabled(
          editorAPI.store.getState(),
        )
      ) {
        editorAPI.mediaServices.mediaManager.open = createSafeOpen(
          editorAPI.mediaServices.mediaManager.open,
        );
      }
    },
    mediaManager: null as AnyFixMe,
    initMediaStudio: (config: MediaImageStudioOptions) => {
      editorAPI.mediaServices.mediaStudio = new MediaImageStudio(config);
    },
    mediaStudio: null as MediaImageStudio | null,
    initVideoMaker: () => {
      editorAPI.mediaServices.videoMaker =
        new videoMakerOpener.VideoMakerOpener();
    },
    videoMaker: null as AnyFixMe,
    initUserFeedback: (config: AnyFixMe) => {
      const UserFeedbackOpenerCtor = UserFeedbackOpener.default;

      editorAPI.mediaServices.userFeedback = new UserFeedbackOpenerCtor(config);
    },
    userFeedback: null as AnyFixMe,
    hasSlowMo,
    getPlaybackSpeedByFPS,
    setFocusToEditor,
    openChangeImageModal,
    changeWPhoto,
    changeVectorImage,
    changeClipArt,
    changeVideo,
    changeShape,
    generateWPhoto,
    adjustWPhoto,
    adjustImageX,
    openMediaStudio,

    getSiteUploadToken,
    getSiteVideoQuota: () => getSiteVideoQuota(getSiteUploadToken()),
    transferMediaItems,
  };
};

// @ts-nocheck
import constants from '#packages/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  enabledActions: [ACTIONS.SETTINGS],
  mobileEnabledActions: [],
  presetActions: {},
};

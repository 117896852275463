import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey, TabIndicationApiKey } from '#packages/apis';
import { createTabIndicationApi } from './tabIndicationState';

export const TabIndicationEntrypoint: EntryPoint = {
  name: 'TabIndicationApi',
  declareAPIs: () => [TabIndicationApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(TabIndicationApiKey, () =>
      createTabIndicationApi(shell),
    );
  },
};

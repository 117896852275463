import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import PropTypes from 'prop-types';
import React from 'react';
import * as panels from '#packages/panels';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  PromotionalList,
  TextLabel,
} from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

//TYPE WAS GENERATED, remove this line when reviewed
interface DynamicPagesStartingPanelProps {
  sendBi: FunctionFixMe;
  closePanel: FunctionFixMe;
  openHelpCenter: FunctionFixMe;
  staticPagesCount: number;
  panelName: string;
  notifyDataBindingAppTooManyPages: FunctionFixMe;
}

class DynamicPagesStartingPanel extends React.Component<DynamicPagesStartingPanelProps> {
  static displayName = 'DynamicPagesStartingPanel';

  static propTypes = {
    sendBi: PropTypes.func.isRequired,
    closePanel: PropTypes.func.isRequired,
    openHelpCenter: PropTypes.func.isRequired,
    staticPagesCount: PropTypes.number.isRequired,
    notifyDataBindingAppTooManyPages: PropTypes.func.isRequired,
  };

  getContentItems = () => {
    return [
      'Heavy_Sites_Checkpoint2_Bullet1',
      'Heavy_Sites_Checkpoint2_Bullet2',
      'Heavy_Sites_Checkpoint2_Bullet3',
    ];
  };

  handleMainAction = () => {
    this.props.sendBi(
      coreBi.events.pages.pagesPanel.dynamic_pages_suggestion_action_click,
      {
        pages_count: this.props.staticPagesCount,
      },
    );
    this.props.sendBi(coreBi.events.addPanel.DATA_MODE_ON_ACTIVATION_CLICK, {
      status: true,
      origin: 'manual',
      entry_point: 'too_many_pages_suggestion',
    });
    this.props.notifyDataBindingAppTooManyPages();
    this.props.closePanel();
  };

  getHeaderBiHelpParams = () => {
    return {
      learn_more: false,
      origin: 'DynamicPagesStartingPanel',
      panel_name: this.props.panelName,
    };
  };

  getLearnMoreBiHelpParams = () => {
    return {
      learn_more: true,
      origin: 'DynamicPagesStartingPanel',
      panel_name: this.props.panelName,
    };
  };

  onHelp = (biHelpParams: AnyFixMe) => {
    this.props.openHelpCenter(
      'e253951f-b0a3-4c88-aae5-2796eb72dc83',
      {},
      biHelpParams,
    );
  };

  render() {
    return (
      <panels.frames.FocusPanelFrame
        automationId={this.props.panelName}
        panelName={this.props.panelName}
        shouldHideHeader={true}
        className="dynamic-pages-starting-panel"
      >
        <Composites.PopupLargeSize className="composite-full-height">
          <Composites.BigPanelHeaderWithIllustration>
            <PanelHeaderCenteredText
              title={translate('Heavy_Sites_Checkpoint2_Title')}
              subtitle={translate('Heavy_Sites_Checkpoint2_Subtitle', {
                Page_Number: this.props.staticPagesCount,
              })}
              titleType="T16"
              // @ts-expect-error
              shouldTranslate={false}
              onHelp={this.onHelp.bind(this, this.getHeaderBiHelpParams())}
              onClose={this.props.closePanel}
            />
            <PanelHeaderIllustration>
              <symbols.symbol name="dataModeIntro" />
            </PanelHeaderIllustration>
          </Composites.BigPanelHeaderWithIllustration>
          <Composites.PanelContent>
            <Composites.PromotionalList>
              <PromotionalList items={this.getContentItems()} />
            </Composites.PromotionalList>
          </Composites.PanelContent>

          <Composites.ActionSetVertical>
            <Button onClick={this.handleMainAction} className="primary">
              <TextLabel value="Heavy_Sites_Checkpoint2_Start_Button" />
            </Button>
            <a
              onClick={this.onHelp.bind(this, this.getLearnMoreBiHelpParams())}
            >
              {translate('Heavy_Sites_Checkpoint2_Link')}
            </a>
          </Composites.ActionSetVertical>
        </Composites.PopupLargeSize>
      </panels.frames.FocusPanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch: AnyFixMe, ownProps: AnyFixMe) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    closePanel: () =>
      editorAPI.panelManager.closePanelByName(ownProps.panelName),
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    sendBi: editorAPI.bi.event,
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(DynamicPagesStartingPanel);

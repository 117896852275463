import React, { useEffect } from 'react';
import { RichText, Button, InfoIcon } from '@wix/wix-base-ui';
import {
  hoc,
  isCustomMenusEnabled,
  isMainMenuFlowEnabled,
  isNewPagesPanelEnabled,
  cx,
} from '#packages/util';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { useTranslation } from 'react-i18next';

import * as stateManagement from '#packages/stateManagement';
import { api } from '#packages/menu';
import * as coreBi from '#packages/coreBi';
import { addPanelUtils } from '#packages/addPanelInfra';

import type { StateMapperArgs } from 'types/redux';
import type { CompRef, PagesData } from '@wix/document-services-types';
import type { EditorAPI } from '#packages/editorAPI';

const { createMenuApi } = api;

export const FIRST_TIME_IS_SHOWN = 'custom_menus_pages_panel_first_time_shown';

export interface CustomMenusFirstTimeExperienceOwnProps {}

interface IProps
  extends CustomMenusFirstTimeExperienceOwnProps,
    ReturnType<typeof mapStateToProps>,
    ReturnType<typeof mapDispatchToProps> {}

export const CustomMenusFirstTimeComponent: React.FC<IProps> = ({
  onOpen,
  openHelpCenter,
  onClose,
  showPanel,
  showManageMenuButton,
  openMenuManager,
}) => {
  const [translate] = useTranslation();

  useEffect(() => {
    if (showPanel) {
      onOpen();
    }
  }, [showPanel, onOpen]);

  if (!showPanel) {
    return null;
  }

  return (
    <div
      className="custom-menus-first-time"
      data-hook="custom-menus-first-time"
    >
      <div className="custom-menus-first-time__header">
        <Symbol
          name="pagesFirstTimeExperienceIllustration"
          className="custom-menus-first-time__image"
        />
        <RichText className="custom-menus-first-time_title" type="T02">
          <p>{translate('Pages_Menu_Custom_Menus_Onboarding_Message_Title')}</p>
        </RichText>
      </div>

      <RichText
        className={cx('custom-menus-first-time_text', {
          'single-footer-btn': !showManageMenuButton,
        })}
        type="T02"
      >
        <p>{translate('Pages_Menu_Custom_Menus_Onboarding_Message_Text')}</p>
      </RichText>
      <div
        className={cx('custom-menus-first-time__footer', {
          'single-btn': !showManageMenuButton,
        })}
      >
        {showManageMenuButton && (
          <Button
            automationId="custom-menus-first-time-manage-menu-button"
            className="btn-sm btn-confirm-secondary custom-menus-first-time-manage-menu-button"
            onClick={openMenuManager}
          >
            {translate('Pages_Menu_Custom_Menus_Onboarding_Message_CTA')}
          </Button>
        )}
        <Button
          automationId="custom-menus-first-time-help-button"
          className="btn-text custom-menus-first-time-help-button"
          onClick={openHelpCenter}
        >
          {translate('Pages_Menu_Custom_Menus_Onboarding_Message_Link')}
        </Button>
      </div>

      <button
        className="custom-menus-first-time_close"
        data-hook="custom-menus-first-time-close-button"
        onClick={onClose}
      >
        <Symbol name="close" />
      </button>
    </div>
  );
};

export const CustomMenusUpdatedFirstTimeComponent: React.FC<IProps> = ({
  onOpen,
  openHelpCenter,
  showManageMenuButton,
  openMenuManager,
}) => {
  const [translate] = useTranslation();

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onOpen, []);

  return (
    <div
      className="custom-menus-first-time custom-menus-first-time_updated"
      data-hook="custom-menus-first-time"
    >
      <div className="custom-menus-first-time__header-icon">
        <InfoIcon
          automationId="pages-panel-first-time-info-icon"
          text={'Pages_Menu_Custom_Menus_Banner_Tooltip_Text'}
          linkText={'Pages_Menu_Custom_Menus_Banner_Tooltip_Link'}
          onLinkClick={openHelpCenter}
        />
      </div>
      <div className="custom-menus-first-time__header">
        <RichText className="custom-menus-first-time_title" type="T07">
          <p>{translate('Pages_Menu_Custom_Menus_Banner_Title')}</p>
        </RichText>
      </div>

      <RichText
        className={cx('custom-menus-first-time_text', {
          'single-footer-btn': !showManageMenuButton,
        })}
        type="T02"
      >
        <p>{translate('Pages_Menu_Custom_Menus_Banner_Text')}</p>
      </RichText>
      <div
        className={cx('custom-menus-first-time__footer', {
          'single-btn': !showManageMenuButton,
        })}
      >
        {showManageMenuButton && (
          <Button
            automationId="custom-menus-first-time-manage-menu-button"
            className="btn-sm btn-confirm-secondary custom-menus-first-time-manage-menu-button"
            onClick={openMenuManager}
          >
            {translate('Pages_Menu_Custom_Menus_Banner_CTA')}
          </Button>
        )}
      </div>
    </div>
  );
};

const getMenuComponentsOnCurrentPage = (editorAPI: EditorAPI): CompRef[] => {
  const menuAPI = createMenuApi(editorAPI);

  const header = editorAPI.dsRead.pages.getReference('SITE_HEADER');
  const footer = editorAPI.dsRead.pages.getReference('SITE_FOOTER');
  const masterPage = editorAPI.dsRead.pages.getReference('masterPage');
  const focusedPage = editorAPI.pages.getFocusedPage();

  return [header, focusedPage, footer, masterPage].flatMap(
    menuAPI.getMenuComponentsWithCustomMenus,
  );
};

const getMenusInsideLightBoxes = (
  editorAPI: EditorAPI,
): { popup: PagesData; menu: CompRef; container: CompRef }[] => {
  const menuAPI = createMenuApi(editorAPI);
  const popups = editorAPI.pages.popupPages.getDataList();
  if (popups.length === 0) {
    return [];
  }
  const containersPairs = popups.map((popup) => ({
    popup,
    container: editorAPI.pages.popupPages.getPopupContainer(popup) as CompRef,
  }));

  return containersPairs
    .map(({ popup, container }) => {
      const menus = menuAPI.getMenuComponents(container);

      if (menus.length === 0) {
        return null;
      }

      return {
        popup,
        container,
        menu: menus[0],
      };
    })
    .filter(Boolean);
};

const getFirstMenu = (editorAPI: EditorAPI): CompRef | null => {
  const menusOnPage = getMenuComponentsOnCurrentPage(editorAPI);

  if (menusOnPage.length > 0) {
    return menusOnPage[0];
  }

  const menusInPopups = getMenusInsideLightBoxes(editorAPI);
  if (menusInPopups.length > 0) {
    return menusInPopups[0].menu;
  }

  return null;
};

const mapStateToProps = ({ state, editorAPI }: StateMapperArgs) => {
  const isShown =
    stateManagement.userPreferences.selectors.getSiteUserPreferences<boolean>(
      FIRST_TIME_IS_SHOWN,
    )(state);

  const showPanelBeShown = isCustomMenusEnabled() && !isShown;

  return {
    showPanel: showPanelBeShown,
    // getting menu components data is a complex operation, so it's better not to ask it if we don't need it
    showManageMenuButton:
      showPanelBeShown && isMainMenuFlowEnabled() && getFirstMenu(editorAPI),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openHelpCenter: () =>
      dispatch(
        stateManagement.panels.actions.openHelpCenter(
          'eae322b5-7c01-463d-a897-1ef25f2c705e',
          null,
          { panel_name: 'pages_panel_first_time_experience_learn_more' },
        ),
      ),
    onOpen: () => {
      dispatch(
        stateManagement.bi.actions.event(
          {
            evid: coreBi.events.pages.pagesPanel.custom_menus_first_time_shown
              .evid,
          },
          {
            message: 'pages_panel_custom_menus_first_time',
          },
        ),
      );
    },
    onClose: () => {
      dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          FIRST_TIME_IS_SHOWN,
          true,
        ),
      );
      dispatch(
        stateManagement.bi.actions.event(
          {
            evid: coreBi.events.pages.pagesPanel.custom_menus_first_time_close
              .evid,
          },
          {
            message: 'pages_panel_custom_menus_first_time',
            button_name: 'close',
          },
        ),
      );
    },
    openMenuManager: async () => {
      let firstMenuComponentOnPageRef =
        getMenuComponentsOnCurrentPage(editorAPI)[0];

      dispatch(
        stateManagement.bi.actions.event(
          {
            evid: coreBi.events.pages.pagesPanel.custom_menus_first_time_close
              .evid,
          },
          {
            message: 'pages_panel_custom_menus_first_time',
            button_name: 'manage_menu',
          },
        ),
      );

      // there could be a case when this handler is called in moment of transition between pages, so we need to handle it
      if (!firstMenuComponentOnPageRef) {
        const { menu, popup } = getMenusInsideLightBoxes(editorAPI)[0] || {};
        if (!menu) {
          return;
        }
        firstMenuComponentOnPageRef = menu;
        // we need to wait for lightBox to open
        await editorAPI.pages.navigateToAsync(popup.id);
      }

      dispatch(
        stateManagement.selection.actions.selectComponents([
          firstMenuComponentOnPageRef,
        ]),
      );

      addPanelUtils.scrollToAddedComponentIfNeeded(
        editorAPI,
        firstMenuComponentOnPageRef,
      );

      dispatch(
        stateManagement.panels.actions.openComponentPanel(
          'menu.panels.MenuManagePanel',
          {
            useLastPanelPosition: true,
          },
        ),
      );
    },
  };
};

export const CustomMenusFirstTime = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(
  isNewPagesPanelEnabled()
    ? CustomMenusUpdatedFirstTimeComponent
    : CustomMenusFirstTimeComponent,
);

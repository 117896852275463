import { componentsStore, selection } from '#packages/stateManagement';
import constants from '#packages/constants';

// @TODO
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import type { ComponentEditorMetaDataDefinition } from '@/componentModel/componentsMetaData/types';
import { BaseAnimationAPI } from './baseAnimationsApi';
import type { ScrollEffectData } from '../types';
import type { CompRef } from '@wix/document-services-types';
import type { EditorAPI } from '#packages/editorAPI';

export class ScrollAnimationAPI extends BaseAnimationAPI {
  // @TODO
  defaultCompRestrictions: Map<string, ComponentEditorMetaDataDefinition>;

  constructor(editorAPI: EditorAPI) {
    super(editorAPI);
    this.defaultCompRestrictions = new Map();
  }

  protected getTriggerType() {
    return 'view-progress';
  }

  protected getTriggerParams() {
    return {
      trigger: this.getTriggerType(),
      params: {},
    };
  }

  get reactionParams() {
    return {
      type: 'Scrub',
    };
  }

  protected get emptyEffectObj() {
    return {
      type: 'ScrubAnimation',
      name: '',
      value: {
        type: 'ScrubAnimationOptions',
        namedEffect: null as null,
      },
    };
  }

  previewAnimation(compRef: CompRef, effectObj: ScrollEffectData) {
    // @TODO perhaps for Scroll preview, we should save compRef of the element being previewed in state
    this.defaultCompRestrictions.set(
      compRef.id,
      this.editorAPI.getCompRestrictions(compRef),
    );

    this.editorAPI.components.layout.stage.freeze(compRef);

    const newRestrictions = {
      ...this.defaultCompRestrictions.get(compRef.id),
      // it would be better to not use `horizontally-` and `vertically-` keys,
      // but it seems like selection box only takes them into account
      movable: false,
      horizontallyMovable: false,
      verticallyMovable: false,
      resizable: false,
      verticallyResizable: false,
      horizontallyResizable: false,
      rotatable: false,
      verticallyRotatable: false,
      horizontallyRotatable: false,
    };

    this.editorAPI.store.dispatch(
      componentsStore.actions.updateComponentStore(
        compRef,
        constants.COMPONENTS_STORE_KEYS.META_DATA_OVERRIDES,
        newRestrictions,
      ),
    );

    this.editorAPI.store.dispatch(
      selection.actions.setSelectedCompsRestrictions(newRestrictions),
    );

    this.editorAPI.components.behaviors.previewScrubAnimations(
      {
        [effectObj.name]: {
          ...effectObj.value,
          targetId: compRef.id,
          variants: [],
        },
      },
      {
        [effectObj.name]: {
          ...this.findTrigger(compRef),
          componentId: compRef.id,
          trigger: this.getTriggerType(),
        },
      },
    );
  }

  async stopPreviewAnimation(compRef: CompRef) {
    this.editorAPI.components.behaviors.stopPreviewScrubAnimations();

    // a workaround because layout is recalculated faster then animation stop.
    // This causes edit box to move to the old component position
    await new Promise((r) => setTimeout(r, 0));

    this.editorAPI.components.layout.stage.unfreeze(compRef);

    this.editorAPI.store.dispatch(
      componentsStore.actions.updateComponentStore(
        compRef,
        constants.COMPONENTS_STORE_KEYS.META_DATA_OVERRIDES,
        this.defaultCompRestrictions.get(compRef.id),
      ),
    );

    this.editorAPI.store.dispatch(
      selection.actions.setSelectedCompsRestrictions(
        this.defaultCompRestrictions.get(compRef.id),
      ),
    );

    this.defaultCompRestrictions.delete(compRef.id);
  }

  updatePreviewAnimation(compRef: CompRef, effectObj: ScrollEffectData) {
    this.editorAPI.dsActions.components.behaviors.updateScrubAnimationsPreview(
      {
        [effectObj.name]: {
          ...effectObj.value,
          targetId: compRef.id,
          variants: [],
        },
      },
      {
        [effectObj.name]: {
          ...this.findTrigger(compRef),
          componentId: compRef.id,
          trigger: this.getTriggerType(),
        },
      },
    );
  }
}

import constants from '#packages/constants';
import experiment from 'experiment';
import type { Scope } from '../scope';
import type { CompRef } from '@wix/document-services-types';

function enableAutoFill(scope: Scope, selectedComponents: CompRef[]) {
  const [selectedImage] = selectedComponents;
  const imageAutofillEnabled =
    scope.components.properties.get(selectedImage)?.autoFill;
  if (selectedImage && !imageAutofillEnabled) {
    scope.components.properties.update(
      selectedImage,
      {
        autoFill: true,
      },
      {
        dontAddToUndoRedoStack: true,
      },
    );
  }
}

const bootstrapImageAutoFill = (scope: Scope) => {
  if (experiment.isOpen('se_imageSettingsPanelAutofillContentChange')) {
    scope.selection.hooks.selectionChanged.tap(({ compRefs }) => {
      if (scope.components.getType(compRefs) !== constants.COMP_TYPES.PHOTO)
        return;

      enableAutoFill(scope, compRefs);
    });
  }
};

export { bootstrapImageAutoFill };

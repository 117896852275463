import type { FlattenedObject } from '../types';

export const flattenObject = (obj: any): FlattenedObject => {
  const flattened: FlattenedObject = {};

  const flattenRecursive = (cur: any, prop = ''): void => {
    if (typeof cur === 'object' && cur !== null) {
      Object.entries(cur).forEach(([key, value]) => {
        flattenRecursive(value, key);
      });
    } else {
      flattened[prop] = cur;
    }
  };

  flattenRecursive(obj);

  return flattened;
};

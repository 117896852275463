// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as offeringListWidgetConsts from './offeringListWidgetConsts';
import editorActionsService from '../../common/editorActionsService';
import experiments from 'experiment';

const addPanelConsts = addPanelDataConsts;

const createGridLayout = (isEditorX, isWixStudio) => {
  const id = isEditorX
    ? offeringListWidgetConsts.GRID_EDITOR_X_PRESET_ID
    : offeringListWidgetConsts.GRID_PRESET_KEY;

  return {
    id,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        width: 980,
        height: 558,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '2269',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: `{"presetId":"${id}"}`,
        },
        widgetId: 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1',
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: isWixStudio
        ? { width: 324, height: 194, x: 0, y: 0 }
        : { width: 302, height: 191, x: 10, y: 9 },
      label: '',
      tags: null,
    },
  };
};

const createClassicLayout = (isEditorX, isWixStudio) => {
  const editorRect = { width: 302, height: 379, x: 10, y: 221 };
  const id = isEditorX
    ? offeringListWidgetConsts.CLASSIC_EDITOR_X_PRESET_ID
    : offeringListWidgetConsts.CLASSIC_PRESET_KEY;

  const getRectByEditor = () => {
    if (isWixStudio) {
      return { width: 324, height: 387, x: 0, y: 194 };
    }
    return isEditorX ? { width: 304, height: 379, x: 10, y: 221 } : editorRect;
  };

  return {
    id,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        width: 980,
        height: 558,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '2269',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: `{"presetId":"${id}"}`,
        },
        widgetId: 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1',
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: getRectByEditor(),
      label: '',
      tags: null,
    },
  };
};

const createStripLayout = (isEditorX, isWixStudio) => {
  const editorRect = { width: 302, height: 137, x: 10, y: 620 };
  const id = isEditorX
    ? offeringListWidgetConsts.STRIP_EDITOR_X_PRESET_ID
    : offeringListWidgetConsts.STRIP_PRESET_KEY;

  const getRectByEditor = () => {
    if (isWixStudio) {
      return { width: 324, height: 134, x: 0, y: 581 };
    }
    return isEditorX ? { width: 304, height: 137, x: 10, y: 620 } : editorRect;
  };

  return {
    id,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        width: 980,
        height: 558,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '2269',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: `{"presetId":"${id}"}`,
        },
        widgetId: 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1',
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: getRectByEditor(),
      label: '',
      tags: null,
    },
  };
};

const createOverlappingLayout = (isEditorX, isWixStudio) => {
  const editorRect = { width: 302, height: 316, x: 10, y: 777 };
  const id = isEditorX
    ? offeringListWidgetConsts.OVERLAPPING_EDITOR_X_PRESET_ID
    : offeringListWidgetConsts.OVERLAPPING_PRESET_KEY;

  const getRectByEditor = () => {
    if (isWixStudio) {
      return { width: 324, height: 320, x: 0, y: 715 };
    }
    return isEditorX ? { width: 304, height: 316, x: 10, y: 777 } : editorRect;
  };

  return {
    id,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        width: 980,
        height: 558,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '2269',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: `{"presetId":"${id}"}`,
        },
        widgetId: 'cc882051-73c9-41a6-8f90-f6ebc9f10fe1',
      },
      style: {
        type: 'TopLevelStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: getRectByEditor(),
      label: '',
      tags: null,
    },
  };
};

export default function ({ editorAPI, isEditorX = false }) {
  const isWixStudio = experiments.isOpen(
    'specs.responsive-editor.BookingsChangeImagesOnAddPanel',
  );

  const actionsService = editorActionsService({ editorAPI });

  const offeringListWidgetGridLayout = createGridLayout(isEditorX, isWixStudio);
  const offeringListWidgetClassicLayout = createClassicLayout(
    isEditorX,
    isWixStudio,
  );
  const offeringListWidgetStripLayout = createStripLayout(
    isEditorX,
    isWixStudio,
  );
  const offeringListWidgetOverlappingLayout = createOverlappingLayout(
    isEditorX,
    isWixStudio,
  );

  const offeringListWidgetItems = [
    offeringListWidgetGridLayout,
    offeringListWidgetClassicLayout,
    offeringListWidgetStripLayout,
    offeringListWidgetOverlappingLayout,
  ];

  const getImagePath = () => {
    if (isWixStudio) {
      return offeringListWidgetConsts.CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_WIX_STUDIO_IMAGE;
    }
    return isEditorX
      ? offeringListWidgetConsts.CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_EDITOR_X_IMAGE
      : offeringListWidgetConsts.CLASSIC_OVERLAPPING_STRIP_GRID_WIDGET_IMAGE_V2;
  };

  return {
    id: 'wixBookingsOfferingListWidgets',
    type: addPanelConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'SiteApp_WixBookings_AddElement_OfferingList',
    subNavigationTitle: 'SiteApp_WixBookings_AddElement_OfferingList',
    presetTitle: 'SiteApp_WixBookings_AddElement_OfferingList',
    tooltipTitle: 'SiteApp_WixBookings_AddElement_OfferingList',
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelConsts.LABEL_BEHAVIOUR.ALWAYS,
      infoIcon: addPanelConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelConsts.HOVER_IMAGE_ACTIONS.NONE,
      scaleProportion: addPanelConsts.DEFAULT_SCALE_FOR_PRESET,
      iconEnabledForComps: [],
    },
    props: {
      image: getImagePath(),
      imageHover: null,
      onDrop: actionsService.onWidgetDrop,
      onClick: actionsService.onWidgetClick,
      items: offeringListWidgetItems,
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
  };
}

import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import { SelectionApiKey } from './publicApiKey';
import { createSelectionApi } from './selectionWrapper';
import type { SelectComponentsByRefInterceptor } from './types';

export const SelectionEntrypoint: EntryPoint = {
  name: 'SelectionApi',
  declareAPIs: () => [SelectionApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(SelectionApiKey, () => createSelectionApi(shell));
  },
};

export type { SelectComponentsByRefInterceptor };

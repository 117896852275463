import _ from 'lodash';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import experiment from 'experiment';

const COMPONENTS_LIVE_SECTIONS: { [K: string]: AnyFixMe } = {
  'mobile.core.components.Page': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_page',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_page',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['mobile.core.components.Page'],
      verticalMargin: 24,
      horizontalMargin: 15,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_page',
  },
  'wysiwyg.viewer.components.HeaderContainer': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_header',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_header',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.HeaderContainer'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_header',
  },
  'wysiwyg.viewer.components.FooterContainer': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_footer',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_footer',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.FooterContainer'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_footer',
  },
  'wysiwyg.viewer.components.InlinePopupToggle': {
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'Mobile_Menu_Icon_Design_Section_Themed_Icons_Label',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.InlinePopupToggle'],
      verticalMargin: 20,
      horizontalMargin: 16,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
  },
  'wysiwyg.viewer.components.MenuToggle': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: !experiment.isOpen('se_studioCopyDesign'),
      title: 'design_section_label_my_strip',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'Mobile_Menu_Icon_Design_Section_Themed_Icons_Label',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.MenuToggle'],
      verticalMargin: 20,
      horizontalMargin: 16,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
  },
  'wysiwyg.viewer.components.ScreenWidthContainer': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_strip',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_strip',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.ScreenWidthContainer'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_strip',
  },
  galleries: {
    //my: {
    //    'type': addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
    //    'hide': false,
    //    'title': 'design_section_label_my_gallery',
    //    'showSectionHeader': true
    //},
    //theme: {
    //    'type': addPanelDataConsts.SECTIONS_TYPES.THEME,
    //    'hide': false,
    //    'title': 'design_section_label_theme_gallery',
    //    'showSectionHeader': true
    //},
    props: {
      compTypes: [
        'wysiwyg.viewer.components.MatrixGallery',
        'wysiwyg.viewer.components.SlideShowGallery',
        'wysiwyg.viewer.components.SliderGallery',
      ],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.25,
        y: 0.25,
      },
    },
    title: 'design_title_gallery',
  },
  'mobile.core.components.Container': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_box',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_box',
      showSectionHeader: true,
    },
    props: {
      compTypes: [
        'mobile.core.components.Container',
        'core.components.Container',
      ],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_box',
  },
  'wysiwyg.viewer.components.BgImageStrip': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_strip',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_strip',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.BgImageStrip'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_strip',
  },
  'wysiwyg.viewer.components.menus.DropDownMenu': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_Hmenu',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_Hmenu',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.menus.DropDownMenu'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 10,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_Hmenu',
  },
  'wysiwyg.viewer.components.ExpandableMenu': {
    title: 'Expandable_Menu_Design_Header',
  },
  'wysiwyg.common.components.verticalmenu.viewer.VerticalMenu': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_Vmenu',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_Vmenu',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.common.components.verticalmenu.viewer.VerticalMenu'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 10,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_Vmenu',
  },
  'wysiwyg.viewer.components.FiveGridLine': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_shape',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_shape',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.FiveGridLine'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 10,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_Hline',
  },
  fiveGridLineFullWidth: {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_shape',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_shape',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['fiveGridLineFullWidth'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 10,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_FWline',
  },
  'wysiwyg.viewer.components.VerticalLine': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_shape',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_shape',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.VerticalLine'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 10,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_Vline',
  },
  'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_audioplayer',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_audioplayer',
      showSectionHeader: true,
    },
    props: {
      compTypes: [
        'wysiwyg.common.components.singleaudioplayer.viewer.SingleAudioPlayer',
      ],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 10,
      scaleProportion: {
        x: 0.75,
        y: 0.75,
      },
    },
    title: 'design_title_audioplayer',
  },
  'wysiwyg.viewer.components.AudioPlayer': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_playbutton',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_playbutton',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.AudioPlayer'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 10,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_playbutton',
  },
  'wysiwyg.viewer.components.GoogleMap': {
    title: 'design_title_googelmap',
  },
  'wysiwyg.common.components.subscribeform.viewer.SubscribeForm': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_subscribe',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_subscribe',
      showSectionHeader: true,
    },
    props: {
      compTypes: [
        'wysiwyg.common.components.subscribeform.viewer.SubscribeForm',
      ],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_subscribeform',
  },
  'wysiwyg.viewer.components.ContactForm': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_contact',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_contact',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.ContactForm'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_contactform',
  },
  'wysiwyg.viewer.components.DynamicContactForm': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_contact',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_contact',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.DynamicContactForm'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_contactform',
  },
  'wysiwyg.viewer.components.SiteButton': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_button',
      showSectionHeader: true,
      compTypes: [
        'wysiwyg.viewer.components.SiteButton',
        'wysiwyg.viewer.components.PopupCloseTextButton',
      ],
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_button',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.SiteButton'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 18,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_textbutton',
  },
  'wysiwyg.viewer.components.inputs.FileUploader': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_file_upload_input',
      hide: false,
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.FileUploader'],
      verticalMargin: 24,
      horizontalMargin: 0,
      maxFontSize: 12,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'Upload_Button_Design_Header',
  },
  'wixui.FileUploaderNew': {
    props: {
      compTypes: ['wixui.FileUploaderNew'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 18,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'Upload_Button_Design_Header',
  },
  'wysiwyg.viewer.components.inputs.ComboBoxInput': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_combobox_input',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.ComboBoxInput'],
      verticalMargin: 20,
      horizontalMargin: 0,
      maxFontSize: 14,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_dropdown',
  },
  'wysiwyg.viewer.components.inputs.TextAreaInput': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_text_area_input',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.TextAreaInput'],
      verticalMargin: 20,
      horizontalMargin: 0,
      maxFontSize: 14,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_text_area',
  },
  'wysiwyg.viewer.components.inputs.TextInput': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_text_input',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.TextInput'],
      verticalMargin: 20,
      horizontalMargin: 0,
      maxFontSize: 14,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_text_input',
  },
  'wysiwyg.viewer.components.inputs.Checkbox': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_checkbox',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.Checkbox'],
      verticalMargin: 20,
      horizontalMargin: 0,
      maxFontSize: 16,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_checkbox',
  },
  'wixui.SignatureInput': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_signature',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wixui.SignatureInput'],
      verticalMargin: 16,
      horizontalMargin: 0,
      maxFontSize: 12,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'signatureInput_Design_Header_Label',
  },
  'wysiwyg.viewer.components.inputs.RadioGroup': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_radio_group_input',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.RadioGroup'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 12,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_radiobutton',
  },
  'wysiwyg.viewer.components.inputs.CheckboxGroup': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_checkbox_group_input',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.CheckboxGroup'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 12,

      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_checkbox_group',
  },
  'wysiwyg.viewer.components.inputs.DatePicker': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      title: 'design_section_label_my_datepicker_input',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.inputs.DatePicker'],
      verticalMargin: 26,
      horizontalMargin: 0,
      maxFontSize: 14,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'design_title_date_picker',
  },
  'wysiwyg.viewer.components.Grid': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'wysiwyg.viewer.components.Grid',
      showSectionHeader: true,
      compTypes: ['wysiwyg.viewer.components.Grid'],
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'wysiwyg.viewer.components.Grid',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.Grid'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 8,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'TABLE_DESIGN_HEADER',
  },
  'wysiwyg.viewer.components.WPhoto': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_image',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_image',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.WPhoto'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.5,
        y: 0.5,
      },
    },
    title: 'design_title_image',
  },
  'wysiwyg.viewer.components.Video': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_video',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_video',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.Video'],
      verticalMargin: 24,
      horizontalMargin: 20,
      scaleProportion: {
        x: 0.25,
        y: 0.25,
      },
    },
    title: 'design_title_video',
  },
  'wysiwyg.viewer.components.mobile.TinyMenu': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'mobilemenu_design_section_my',
      showSectionHeader: true,
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'mobilemenu_design_section_theme',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.mobile.TinyMenu'],
      verticalMargin: 20,
      horizontalMargin: 16,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'mobilemenu_design_title',
  },
  'wysiwyg.viewer.components.LoginButton': {
    title: 'design_title_memberlogin',
  },
  'wysiwyg.viewer.components.LoginSocialBar': {
    title: 'design_title_login_social_bar',
  },
  'wysiwyg.viewer.components.AdminLoginButton': {
    title: 'design_title_webmaster',
  },
  'wysiwyg.viewer.components.svgshape.SvgShape': {
    title: 'design_title_shape',
  },
  VectorImageShape: {
    title: 'VECTOR_IMAGE_DESIGN_PANEL_HEADER_SHAPE_TITLE',
  },
  VectorImageArt: {
    title: 'VECTOR_IMAGE_DESIGN_PANEL_HEADER_ART_TITLE',
  },
  VectorImageUGC: {
    title: 'VECTOR_IMAGE_DESIGN_PANEL_HEADER_ART_TITLE',
  },
  'wysiwyg.common.components.imagebutton.viewer.ImageButton': {
    title: 'design_title_iconbutton',
  },
  'wysiwyg.viewer.components.documentmedia.DocumentMedia': {
    title: 'design_title_document',
  },
  'wysiwyg.viewer.components.wixhomepage.HomePageLogin': {
    title: 'design_title_home_page_login',
  },
  'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu': {
    props: {
      compTypes: [
        'wysiwyg.common.components.verticalanchorsmenu.viewer.VerticalAnchorsMenu',
      ],
      verticalMargin: 20,
      horizontalMargin: 16,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'anchor_menu_vertical_design_header_label',
  },
  'wysiwyg.viewer.components.PopupCloseIconButton': {
    title: 'POPUPS_X_DesignPanel_Title',
  },
  'wysiwyg.viewer.components.PopupCloseTextButton': {
    my: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED,
      hide: false,
      title: 'design_section_label_my_button',
      showSectionHeader: true,
      compTypes: [
        'wysiwyg.viewer.components.SiteButton',
        'wysiwyg.viewer.components.PopupCloseTextButton',
      ],
    },
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: false,
      title: 'design_section_label_theme_button',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.PopupCloseTextButton'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 18,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'POPUPS_X_DesignPanel_Title',
  },
  'wysiwyg.viewer.components.HoverBox': {
    myDesign: {
      type: addPanelDataConsts.SECTIONS_TYPES.PERSONALIZED_DESIGN,
      hide: false,
      title: 'hover_box_personalized_section',
      showSectionHeader: true,
      compTypes: ['wysiwyg.viewer.components.HoverBox'],
    },
    props: {
      compTypes: ['wysiwyg.viewer.components.HoverBox'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 18,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
    title: 'hover_box_design_header_label',
  },
  'wysiwyg.viewer.components.BoxSlideShow': {
    title: 'box_slide_show_design_header_label',
  },
  'wysiwyg.viewer.components.StateBox': {
    title: 'multiStateBox_Design_Header_Label',
  },
  'wysiwyg.viewer.components.StateStrip': {
    title: 'multiStateBox_Design_Header_Label',
  },
  'wysiwyg.viewer.components.BoxSlideShowSlide': {
    title: 'Regular_Slideshow_Change_Background_Title',
  },
  'wysiwyg.viewer.components.StripContainerSlideShow': {
    title: 'box_slide_show_design_header_label',
  },
  'wysiwyg.viewer.components.MediaOverlayControls': {
    title: 'BIG_PLAY_CONTROL_DESIGN_PANEL_HEADER_TITLE',
  },
  'wysiwyg.viewer.components.QuickActionBar': {
    title: 'mobile_quick_actions_design_title',
  },
  'wysiwyg.viewer.components.LanguageSelector': {
    title: 'LangMenu_Design_Header_Label',
  },
  'wixui.Pagination': {
    title: 'Pagination_Bar_Pagination_Bar_Design_Header_Label',
  },
  'wixui.ToggleSwitch': {
    title: 'design_title_toggleswitch',
  },
  'wixui.MusicPlayer': {
    title: 'audio_player_design_header',
  },
  'wixui.SearchBox': {
    title: 'Site_Search_SearchBar_Design_Header',
  },
  'wixui.VideoPlayer': {
    title: 'design_title_videoplayer',
  },
  'wixui.Slider': {
    title: 'slider_design_header_label',
  },
  'wixui.ProgressBar': {
    title: 'progressBar_Design_Header_Label',
  },
  'wixui.RatingsDisplay': {
    title: 'design_title_ratings_read',
  },
  'wixui.RichTextBox': {
    title: 'rich_text_box_design_header_label',
  },
  'wixui.RatingsInput': {
    title: 'design_title_ratings_write',
  },
  'wixui.TimePicker': {
    title: 'time_input_design_title',
  },
  'wixui.AddressInput': {
    title: 'address_input_design_header_label',
  },
  'wixui.ProGallery': {
    title: 'Native_ProGallery_DesignPanel_Title',
  },
  'wixui.StylableButton': {
    title: 'StylablePanel_Design_Button_Header',
    theme: {
      type: addPanelDataConsts.SECTIONS_TYPES.THEME,
      hide: !experiment.isOpen('se_stylableButtonThemes'),
      title: 'design_section_label_theme_button',
      showSectionHeader: true,
    },
    props: {
      compTypes: ['wixui.StylableButton'],
      verticalMargin: 24,
      horizontalMargin: 20,
      maxFontSize: 18,
      scaleProportion: {
        x: 1,
        y: 1,
      },
    },
  },
  'wixui.SelectionTagsList': {
    title: 'selection_tags_design_header_label',
  },
  'wixui.StylableHorizontalMenu': {
    title: 'StylablePanel_Design_Horizontal_Menu_Header',
  },
  'wixui.CollapsibleText': {
    title: 'StylablePanel_Design_Header_Ellipsis_Text',
  },
  'wixui.Breadcrumbs': {
    title: 'StylablePanel_Design_Breadcrumbs_Header',
  },
  'wixui.RangeSlider': {
    title: 'wixui_RangeSlider_panelTitle_design',
  },
  'dsgnsys.WutAvatar': {
    title: 'dsgnsys_WutAvatar_panelTitle_design',
  },
  'dsgnsys.WutAvatarGroup': {
    title: 'dsgnsys_WutAvatarGroup_panelTitle_design',
  },
  'wixui.ShareButtons': {
    title: 'wixui_sharebuttons_design_panel_header',
  },
  'wixui.AccordionContainer': {
    title: 'accordion_design_panel_header',
  },
  'wixui.SelectableContainerInput': {
    title: 'wixui_SelectableContainerInput_panelTitle_design',
  },
};

function getDesignPanelTitleByComp(compType: string) {
  if (
    !COMPONENTS_LIVE_SECTIONS[compType] ||
    !COMPONENTS_LIVE_SECTIONS[compType].title
  ) {
    return '';
  }
  return COMPONENTS_LIVE_SECTIONS[compType].title;
}

function getSectionByCompType(compType: string, sectionName: string) {
  if (
    !COMPONENTS_LIVE_SECTIONS[compType] ||
    !COMPONENTS_LIVE_SECTIONS[compType][sectionName] ||
    !COMPONENTS_LIVE_SECTIONS[compType].props
  ) {
    return null;
  }

  const section = _.cloneDeep(COMPONENTS_LIVE_SECTIONS[compType][sectionName]);
  section.props = _.cloneDeep(COMPONENTS_LIVE_SECTIONS[compType].props);
  section.additionalBehaviours = {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    iconEnabledForComps: [],
  }; //TODO - remove this support and make it optional

  return section;
}

function getLiveComponentSectionsByCompType(compType: string) {
  const liveSections = [];
  const mySection = getSectionByCompType(compType, 'my');
  const themeSection = getSectionByCompType(compType, 'theme');

  const myDesignSection = getSectionByCompType(compType, 'myDesign');
  const themeDesignSection = getSectionByCompType(compType, 'themeDesign');

  if (myDesignSection) {
    liveSections.push(myDesignSection);
  }
  if (themeDesignSection) {
    liveSections.push(themeDesignSection);
  }

  if (mySection) {
    liveSections.push(mySection);
  }
  if (themeSection) {
    liveSections.push(themeSection);
  }

  return liveSections;
}

export { getLiveComponentSectionsByCompType, getDesignPanelTitleByComp };

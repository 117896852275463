import React from 'react';

import { Text } from '@wix/wix-base-ui';

import { frames } from '#packages/panels';

export interface SectionizerErrorPanelProps {
  title: string;
  message: string;
  panelName?: string;
}

const SectionizerErrorPanel = (props: SectionizerErrorPanelProps) => {
  return (
    <frames.MessagePanelFrame
      panelName={props.panelName}
      title={props.title}
      confirmLabel={'Ok'}
      illustration={null}
      theme="destructive"
    >
      <Text
        size="medium"
        weight="normal"
        skin="secondary"
        enableEllipsis={false}
        shouldTranslate={false}
      >
        {props.message}
      </Text>
    </frames.MessagePanelFrame>
  );
};

export default SectionizerErrorPanel;

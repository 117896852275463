import _ from 'lodash';

import { url, platform as platformUtils } from '#packages/util';
import { isSupportedApp as isSupportedAppManagerApp } from '#packages/appManager';
import * as settings from '../settings/settings';
import * as blog from '#packages/blog';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';

import { parseAppMarketQueryParam } from '../utils/tpaUtils';
import * as appMarketTabService from '../appMarket/services/appMarketTabService';

import type { EditorAPI } from '#packages/editorAPI';
import { utils } from '#packages/rEditor';

interface ComponentPageData {
  pageId: string;
  compId: string;
}

const { getPreviewMode } = stateManagement.preview.selectors;
const BLOG_APPDEF_ID = '61f33d50-3002-4882-ae86-d319c1a249ab';

const navigateAndScrollToCompByCompPageData = (
  editorAPI: EditorAPI,
  firstCompPageData: ComponentPageData,
  postNavigationCallback?: () => void,
) => {
  const scrollToComponent = () => {
    editorAPI.pages.scrollToComponent(firstCompPageData.compId, {
      onComplete: postNavigationCallback,
      onUpdate: keepEditorScrollAlignedToPreview.bind(null, editorAPI),
    });
  };

  if (editorAPI.platform.applications.isSilentInstallRunning()) {
    return;
  }
  if (
    firstCompPageData.pageId === editorAPI.pages.getMasterPageId() ||
    firstCompPageData.pageId === editorAPI.dsRead.pages.getFocusedPageId()
  ) {
    scrollToComponent();
  } else {
    editorAPI.pages.navigateTo(firstCompPageData.pageId, scrollToComponent);
  }
};

const keepEditorScrollAlignedToPreview = function (editorAPI: EditorAPI) {
  const state = editorAPI.store.getState();
  if (!getPreviewMode(state)) {
    editorAPI.shouldUpdateEditorScrollAfterHeightUpdate = true;
    editorAPI.scroll.applyPreviewScrollToEditorScroll();
  }
};

function scrollToComponentAsync(editorAPI: EditorAPI, compId: string) {
  return new Promise<void>((resolve) => {
    editorAPI.pages.scrollToComponent(compId, {
      onComplete: () => resolve(),
      onUpdate: keepEditorScrollAlignedToPreview.bind(null, editorAPI),
    });
  });
}

function navigateToPageAsync(editorAPI: EditorAPI, pageId: string) {
  return new Promise<void>((resolve) => {
    if (
      editorAPI.platform.applications.isSilentInstallRunning() ||
      pageId === editorAPI.pages.getMasterPageId() ||
      pageId === editorAPI.dsRead.pages.getFocusedPageId()
    ) {
      return resolve();
    }
    editorAPI.pages.navigateTo(pageId, () => {
      resolve();
    });
  });
}

const openSettingsPanel = function (editorAPI: EditorAPI, appDefId: string) {
  if (editorAPI.platform.applications.isSilentInstallRunning()) {
    return;
  }
  const compRef = selectAndReturnComp(editorAPI, appDefId);
  if (compRef) {
    settings.open(editorAPI, compRef);
  }
};

const openBlogSettings = function (editorAPI: EditorAPI) {
  editorAPI.store.dispatch(stateManagement.leftBar.actions.collapseMenu());
  editorAPI.panelManager.openPanel(constants.SUPER_APPS.BLOG_PANEL_NAME, {});
};

const openSettings = function (editorAPI: EditorAPI, appDefId: string) {
  if (appDefId === BLOG_APPDEF_ID) {
    openBlogSettings(editorAPI);
  } else {
    navigateAndOpenAppService.openSettingsPanel(editorAPI, appDefId);
  }
};

const selectAndReturnComp = function (editorAPI: EditorAPI, appDefId: string) {
  const compData = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(
    appDefId,
    true,
  );
  if (compData) {
    const compRef = editorAPI.components.get.byId(
      compData.compId,
      compData.pageId,
    );
    editorAPI.selection.selectComponentByCompRef(compRef);
    return compRef;
  }
  return null;
};

const getAppManagerAppDefId = ({
  editorAPI,
  appMarketJWT,
}: {
  editorAPI: EditorAPI;
  appMarketJWT: string;
}): string | void => {
  const isTrulyQS = url.isTrue('preferAppManager');

  if (isTrulyQS && appMarketJWT) {
    const appDefId = parseAppMarketQueryParam(appMarketJWT)?.appDefId;
    return isSupportedAppManagerApp(editorAPI, appDefId) ? appDefId : null;
  }
};

const handleIfComingFromETPA = (editorAPI: EditorAPI) =>
  new Promise<void>((resolve) => {
    if (editorAPI.platform.applications.isSilentInstallRunning()) {
      return Promise.resolve();
    }
    const appDefId = url.getParameterByName('etpa');
    const appMarketParams = url.getParameterByName('appMarketParams');
    const slug = url.getParameterByName('slug');
    const marketRoute = url.getParameterByName('marketRoute');
    const data = appDefId
      ? editorAPI.dsRead.tpa.app.getFirstAppCompPageId(appDefId)
      : undefined;
    if (data) {
      const etpaMode = url.getParameterByName('etpaMode') || 'settings';
      const postNavCB =
        etpaMode === 'settings' ? openSettings : selectAndReturnComp;

      navigateAndOpenAppService.navigateAndScrollToCompByAppDefId(
        editorAPI,
        appDefId,
        () => {
          postNavCB(editorAPI, appDefId);
          resolve();
        },
      );
    } else if (appDefId || appMarketParams || slug || marketRoute) {
      const appManagerAppDefId = getAppManagerAppDefId({
        editorAPI,
        appMarketJWT: appMarketParams,
      });

      if (appManagerAppDefId) {
        const leavePanelsOpened = true;

        editorAPI.store.dispatch(
          stateManagement.panels.actions.updateOrOpenPanel(
            constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME,
            { selectedAppId: appManagerAppDefId },
            leavePanelsOpened,
          ),
        );
      } else {
        appMarketTabService.openAppMarketTab(
          {
            urlParams: {
              openAppDefId: appDefId,
              appMarketParams,
              openMarketOrigin: url.getParameterByName('referral_info'),
              slug,
              marketRoute,
            },
          },
          editorAPI.panelManager.openPanel,
        );
      }
    }

    if (!data) {
      resolve();
    }
  });

function navigateAndScrollToCompByAppDefId(
  editorAPI: EditorAPI,
  appDefId: string,
  postNavigationCallback: () => void,
) {
  if (editorAPI.platform.applications.isSilentInstallRunning()) {
    return;
  }
  let firstCompPageData: ComponentPageData;

  if (appDefId === BLOG_APPDEF_ID) {
    firstCompPageData = {
      pageId: blog.getBlogPageId(editorAPI),
      compId: undefined,
    };
  } else {
    firstCompPageData = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(
      appDefId,
      true,
    );
  }

  if (!firstCompPageData) {
    const siteComponents =
      editorAPI.components.getAllComponents_DEPRECATED_BAD_PERFORMANCE();
    const firstAppControlledComponentRef = siteComponents.find(
      (compRef) =>
        editorAPI.components.data.get(compRef)?.applicationId === appDefId,
    );

    const [firstConnectedComponentRef] =
      editorAPI.platform.controllers.connections.getConnectedComponents(
        firstAppControlledComponentRef,
      );

    if (firstConnectedComponentRef) {
      const pageRef = editorAPI.components.getPage(firstConnectedComponentRef);

      firstCompPageData = {
        pageId: pageRef.id,
        compId: firstConnectedComponentRef.id,
      };
    }
  }

  if (firstCompPageData) {
    navigateAndScrollToCompByCompPageData(
      editorAPI,
      firstCompPageData,
      postNavigationCallback,
    );
  }
}

function navigateAndOpenSettingsForAppDefId(
  editorAPI: EditorAPI,
  appDefId: string,
) {
  if (editorAPI.platform.applications.isSilentInstallRunning()) {
    return Promise.resolve();
  }
  navigateAndOpenAppService.navigateAndScrollToCompByAppDefId(
    editorAPI,
    appDefId,
    _.partial(openSettings, editorAPI, appDefId),
  );
}

function plainNavigateAndOpenPanelsByURLIfNeeded(editorAPI: EditorAPI): void {
  const editorQueryCaseSensitive =
    url.parseUrl(window.document.location.href).query || {};
  const editorQuery = _.mapKeys(
    editorQueryCaseSensitive,
    function (value, key) {
      return key.toLowerCase();
    },
  );
  const openPanelIfNeeded = _.partial(
    utils.openPanelByURL.openPanel,
    editorQuery,
    editorAPI,
  );
  if (editorQuery.pageid) {
    editorAPI.pages.navigateTo(editorQuery.pageid, openPanelIfNeeded);
  } else if (editorQuery.tpapageid) {
    const allSitePages = editorAPI.pages.getPagesData();
    const tpaPage = allSitePages.filter(
      (page) => page.tpaPageId === editorQuery.tpapageid,
    );
    if (tpaPage.length > 0) {
      editorAPI.pages.navigateTo(tpaPage[0].id, openPanelIfNeeded);
    }
  } else {
    openPanelIfNeeded();
  }
}

function navigateAndOpenPanelsByURLIfNeeded(editorAPI: EditorAPI) {
  const { navigateAndOpenPanelsByURLIfNeeded } =
    platformUtils.wrapFunctionsWithPreventOnSilent(
      {
        navigateAndOpenPanelsByURLIfNeeded:
          plainNavigateAndOpenPanelsByURLIfNeeded,
      },
      editorAPI.platform.applications.isSilentInstallRunning(),
    );
  navigateAndOpenPanelsByURLIfNeeded(editorAPI);
}

// object to spy on in unit tests
const navigateAndOpenAppService = {
  openSettingsPanel,
  navigateAndOpenSettingsForAppDefId,
  navigateAndScrollToCompByAppDefId,
  navigateAndScrollToCompByCompPageData,
  navigateToPageAsync,
  scrollToComponentAsync,
  handleIfComingFromETPA,
  navigateAndOpenPanelsByURLIfNeeded,
};

export default navigateAndOpenAppService;

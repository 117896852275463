import type { StateMapperArgs } from 'types/redux';
import { WixCodeWorkspaceApiKey } from '#packages/apis';
import type { PublishErrorPanelDevStateProps } from './PublishErrorPanelTypes';

export function mapStateToProps({
  editorAPI,
}: StateMapperArgs): PublishErrorPanelDevStateProps {
  const openBuildLogButtonComponent = editorAPI.host
    .getAPI(WixCodeWorkspaceApiKey)
    .getOpenBuildLogButton();

  return {
    openBuildLogButtonComponent,
  };
}

import addPanelDataConsts from '#packages/addPanelDataConsts';
import popupContactSection from '../popup/popupContactSection/section';
import popupSubscribeSection from '../popup/popupSubscribeSection/section';
import popupSaleSection from '../popup/popupSaleSection/section';
import interactionsHoverSection from '../box/interactionsHoverSection/interactionsHoverSection';
import * as addPanel from '#packages/oldAddPanel';

export default {
  getCategorySections(editorAPI: AnyFixMe) {
    const sections = addPanel.sections.getSections();

    return {
      ...sections.boxSlideShow,
      hoverBoxSection: sections.hoverBox.hoverBoxSection,
      ...(sections.stateBox && {
        stateBoxSection: sections.stateBox.stateBoxSection,
      }),
      ...(sections.stateBox && {
        stateStripSection: sections.stateBox.stateStripSection,
      }),
      interactionsHoverSection,
      popupContactSection: popupContactSection(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.BOX_SLIDE_SHOW,
      ),
      popupSubscribeSection: popupSubscribeSection(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.BOX_SLIDE_SHOW,
      ),
      popupSaleSection: popupSaleSection(
        editorAPI,
        addPanelDataConsts.CATEGORIES_ID.BOX_SLIDE_SHOW,
      ),
    };
  },
};

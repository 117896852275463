import _ from 'lodash';
import { EditorAPIKey } from '#packages/apis';
import * as stateManagement from '#packages/stateManagement';
import type { Shell } from '#packages/apilib';

export function createFloatingBubbleApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  let floatingBubbleShowTimeoutId: AnyFixMe;
  const COUNT_BY_SESSION = 'session';
  const COUNT_BY_SITE = 'site';

  function countBubbleDisplayTimes(countDisplayTimesOptions: AnyFixMe) {
    let timesBubbleWasDisplayed = 0;
    const { countBy, maxNum, uniqueBubbleId } = countDisplayTimesOptions;
    if (_.isEmpty(countBy) || !maxNum || _.isEmpty(uniqueBubbleId)) {
      throw new Error(
        'please provide all parameters for countDisplayTimesOptions',
      );
    }

    if (countBy === COUNT_BY_SESSION) {
      timesBubbleWasDisplayed =
        stateManagement.userPreferences.selectors.getSessionUserPreferences<number>(
          uniqueBubbleId,
        )(editorAPI.store.getState()) || 0;
    } else if (countBy === COUNT_BY_SITE) {
      timesBubbleWasDisplayed =
        stateManagement.userPreferences.selectors.getSiteUserPreferences<number>(
          uniqueBubbleId,
        )(editorAPI.store.getState()) || 0;
    } else {
      throw new Error(
        'please provide correct countBy method for countDisplayTimesOptions',
      );
    }
    return timesBubbleWasDisplayed;
  }

  function incrementDisplayTimes(
    countDisplayTimesOptions: AnyFixMe,
    timesBubbleWasDisplayed: AnyFixMe,
  ) {
    const { countBy, uniqueBubbleId } = countDisplayTimesOptions;
    if (countBy === COUNT_BY_SESSION) {
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSessionUserPreferences(
          uniqueBubbleId,
          timesBubbleWasDisplayed + 1,
        ),
      );
    } else if (countBy === COUNT_BY_SITE) {
      editorAPI.store.dispatch(
        stateManagement.userPreferences.actions.setSiteUserPreferences(
          uniqueBubbleId,
          timesBubbleWasDisplayed + 1,
        ),
      );
    }
  }

  function show(
    value: AnyFixMe,
    targetLayout: AnyFixMe,
    bubbleProps: AnyFixMe,
    options: AnyFixMe,
  ) {
    const {
      shouldNotHideOnMouseLeaveTarget,
      countDisplayTimesOptions,
      delay,
      onComplete,
    } = options;

    let timesBubbleWasDisplayed: AnyFixMe;
    if (countDisplayTimesOptions) {
      timesBubbleWasDisplayed = countBubbleDisplayTimes(
        countDisplayTimesOptions,
      );
      if (timesBubbleWasDisplayed >= countDisplayTimesOptions.maxNum) {
        return;
      }
    }
    floatingBubbleShowTimeoutId = window.setTimeout(function () {
      floatingBubbleShowTimeoutId = null;

      showImmediate(
        value,
        targetLayout,
        bubbleProps,
        shouldNotHideOnMouseLeaveTarget,
      );

      if (countDisplayTimesOptions) {
        incrementDisplayTimes(
          countDisplayTimesOptions,
          timesBubbleWasDisplayed,
        );
      }

      if (_.isFunction(onComplete)) {
        onComplete();
      }
    }, delay);
  }

  function showImmediate(
    value: AnyFixMe,
    targetLayout: AnyFixMe,
    bubbleProps: AnyFixMe,
    shouldNotHideOnMouseLeaveTarget: AnyFixMe,
  ) {
    editorAPI.updateState({
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      floatingBubble: _.assign({}, editorAPI.store.getState().floatingBubble, {
        value,
        targetLayout,
        bubbleProps,
        shouldNotHideOnMouseLeaveTarget,
      }),
    });
  }

  function hide() {
    const floatingBubbleEmptyState = {
      value: null as AnyFixMe,
      targetLayout: null as AnyFixMe,
      bubbleProps: {},
      shouldNotHideOnMouseLeaveTarget: false,
    };
    if (
      !_.isEqual(
        editorAPI.store.getState().floatingBubble,
        floatingBubbleEmptyState,
      )
    ) {
      editorAPI.updateState({
        floatingBubble: floatingBubbleEmptyState,
      });
    }

    if (floatingBubbleShowTimeoutId) {
      window.clearTimeout(floatingBubbleShowTimeoutId);
    }
  }
  return {
    show,
    hide,
    showImmediate,
  };
}

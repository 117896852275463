import {
  EditorAPIKey,
  ContentInjectionApiKey,
  SiteGlobalDataApiKey,
} from '#packages/apis';
import {
  ContentInjectionApi,
  initiateContentManager,
  isContentInjectionAvailableForSections,
} from './contentInjectionApi';
import { BaseEntryScope, createEntryPoint, Hooks } from '#packages/apilib';
import { ContentInjectionStore } from './contentInjectionStore';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  store = this.declareStore(ContentInjectionStore);
  siteGlobalDataAPI = this.useDependency(SiteGlobalDataApiKey);
  hooks = this.declareApi(() => ({
    contentManagerReady: Hooks.createPromiseHook(),
  }));
}

export const ContentInjectionEntryPoint = createEntryPoint({
  name: 'ContentInjection',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(ContentInjectionApiKey, ContentInjectionApi);
  },
  initialize(scope: Scope) {
    if (isContentInjectionAvailableForSections()) {
      initiateContentManager(scope);
    }
  },
});

import { sections as sectionsUtils } from '#packages/util';

import type {
  RegularSkinGetter,
  RegularSkinSchema,
  SOAPSkinSchema,
  SOAPSkinGetter,
} from '../skins/skins.types';
import {
  getCurrentEditorMode,
  getSectionLikeSelectionType,
  pickSkinFromSchema,
} from '../skins/skins.utils';

const sectionSchema: RegularSkinSchema = {
  desktop: {
    hovered: 'secondary',
    selected: 'primary',
  },
  desktopZoomOut: {
    hovered: 'secondary',
    selected: 'primary',
  },
};
const sectionSchemaQuickEditBO: RegularSkinSchema = {
  desktop: {
    hovered: 'lightLarge',
    selected: 'lightLarge',
  },
  desktopZoomOut: {
    hovered: 'lightLarge',
    selected: 'lightLarge',
  },
};

const siteSegmentSchema: SOAPSkinSchema = {
  desktop: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
  },
  desktopZoomOut: {
    hovered: 'secondary-soap',
    selected: 'primary-soap',
  },
};

const siteSegmentSchemaQuickEditBO: SOAPSkinSchema = {
  desktop: {
    hovered: 'lightLarge-soap',
    selected: 'lightLarge-soap',
  },
  desktopZoomOut: {
    hovered: 'lightLarge-soap',
    selected: 'lightLarge-soap',
  },
};

export const getSectionStageActionsSkin: RegularSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  const schema = sectionsUtils.isSectionsControlsRedesignEnabled()
    ? sectionSchema
    : sectionSchemaQuickEditBO;

  const mode = getCurrentEditorMode(editorAPI);
  const selectionType = getSectionLikeSelectionType(
    editorAPI,
    compRef,
    selectionData,
  );

  return pickSkinFromSchema(schema, mode, selectionType);
};

export const getSiteSegmentStageActionsSkin: SOAPSkinGetter = (
  editorAPI,
  compRef,
  selectionData,
) => {
  const schema = sectionsUtils.isSectionsControlsRedesignEnabled()
    ? siteSegmentSchema
    : siteSegmentSchemaQuickEditBO;

  const mode = getCurrentEditorMode(editorAPI);
  const selectionType = getSectionLikeSelectionType(
    editorAPI,
    compRef,
    selectionData,
  );

  return pickSkinFromSchema(schema, mode, selectionType);
};

import constants from '#packages/constants';
import type { CompStructure, CompRef } from 'types/documentServices';
import switchLayoutRulesTypes from './switchLayoutRulesTypes';
import { getLayoutY } from './switchLayoutUtil';
import { rulesMap } from './switchLayoutRulesMap';
import { focalPoint } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

export interface HeuristicCompTypeMap {
  [componentType: string]: (
    editorAPI: EditorAPI,
    suggestedComp: CompStructure,
    parentId: string,
    currentComp: CompStructure,
    currentRootSerialized?: CompStructure,
    currentPostNormalizeFunctions?: any[],
    currentPostRenderOperations?: Array<
      (newRootCompRef: CompRef, scopeEditorAPI: EditorAPI) => void
    >,
    rules?: any,
  ) => CompStructure;
}

export interface RegularCompTypeMap {
  [componentType: string]: (
    editorAPI: EditorAPI,
    suggestedComp: any,
    parentId: string,
    parentCompType: string,
    currentComp: CompStructure,
    currentRootSerialized?: CompStructure,
    currentPostNormalizeFunctions?: any[],
    currentPostRenderOperations?: Array<
      (newRootCompRef: CompRef, scopeEditorAPI: EditorAPI) => void
    >,
    rules?: any,
  ) => Promise<CompStructure | null>;
}

export interface PostMigrationTypeMap {
  [componentType: string]: (
    editorAPI: EditorAPI,
    parentId: string,
    migratedComp: CompStructure,
    currentRootSerialized?: CompStructure,
    currentPostNormalizeFunctions?: any[],
    currentPostRenderOperations?: Array<
      (newRootCompRef: CompRef, scopeEditorAPI: EditorAPI) => void
    >,
    rules?: any,
  ) => Promise<CompStructure | null>;
}

export const heuristicsMap: HeuristicCompTypeMap = {
  [constants.COMP_TYPES.PHOTO]: (
    editorAPI,
    suggestedComp,
    parentId,
    currentComp,
    currentRootSerialized,
    currentPostNormalizeFunctions,
  ) => {
    switch (currentComp.componentType) {
      case constants.COMP_TYPES.COLUMN:
      case constants.COMP_TYPES.SECTION: {
        let uri = currentComp.design.background.mediaRef?.uri;
        if (!uri) {
          const column = (
            currentRootSerialized.components as CompStructure[]
          ).find((column) => column.design.background.mediaRef?.uri);
          if (column) {
            uri = column.design.background.mediaRef.uri;
            const currentBackground = currentComp.design.background;
            currentPostNormalizeFunctions.push(
              (suggestedRootComp: AnyFixMe) => {
                const mergedColumn = suggestedRootComp.components.find(
                  ({ id }: AnyFixMe) => parentId === id,
                );
                mergedColumn.design.background = currentBackground;
              },
            );
          } else if (currentRootSerialized.design.background.mediaRef?.uri) {
            uri = currentRootSerialized.design.background.mediaRef.uri;
            const currentBackground = currentComp.design.background;
            currentPostNormalizeFunctions.push(
              (suggestedRootComp: AnyFixMe) => {
                suggestedRootComp.design.background = currentBackground;
              },
            );
          }
        }

        delete suggestedComp?.data?.crop;
        return {
          ...suggestedComp,
          style: suggestedComp.style ? suggestedComp.style : 'wp2',
          parent: parentId,
          data: {
            ...suggestedComp.data,
            uri: uri || suggestedComp.data.uri,
          },
        };
      }
      default:
        return;
    }
  },
  [constants.COMP_TYPES.COLUMN]: (
    editorAPI,
    suggestedComp,
    parentId,
    currentComp,
  ) => {
    switch (currentComp.componentType) {
      case constants.COMP_TYPES.PHOTO: {
        return {
          ...suggestedComp,
          parent: parentId,
          design: {
            ...suggestedComp.design,
            background: {
              ...suggestedComp.design.background,
              mediaRef: {
                ...suggestedComp.design.background.mediaRef,
                uri:
                  currentComp.data.uri ||
                  suggestedComp.design.background.mediaRef.uri,
              },
            },
          },
          components: suggestedComp.components,
        };
      }
      case constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER:
      case constants.COMP_TYPES.SECTION: {
        return {
          ...suggestedComp,
          style: currentComp.style ? currentComp.style : suggestedComp.style,
          parent: parentId,
          design: {
            ...suggestedComp.design,
            background: {
              ...currentComp.design.background,
            },
          },
          components: suggestedComp.components,
        };
      }
      default: {
        return {
          ...suggestedComp,
          style: currentComp.style ? currentComp.style : suggestedComp.style,
          parent: parentId,
          components: suggestedComp.components,
        };
      }
    }
  },
  [constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER]: (
    editorAPI,
    suggestedComp,
    parentId,
    currentComp,
  ) => {
    switch (currentComp.componentType) {
      case constants.COMP_TYPES.PHOTO: {
        return {
          ...suggestedComp,
          parent: parentId,
          design: {
            ...suggestedComp.design,
            background: {
              ...suggestedComp.design.background,
              mediaRef: {
                ...suggestedComp.design.background.mediaRef,
                uri:
                  currentComp.data.uri ||
                  suggestedComp.design.background.mediaRef.uri,
              },
            },
          },
          components: suggestedComp.components,
        };
      }
      case constants.COMP_TYPES.COLUMN:
      case constants.COMP_TYPES.SECTION: {
        return {
          ...suggestedComp,
          // style: currentComp.style ? currentComp.style : suggestedComp.style,
          parent: parentId,
          design: {
            ...suggestedComp.design,
            background: {
              ...currentComp.design.background,
            },
          },
          components: suggestedComp.components,
        };
      }
      default: {
        return {
          ...suggestedComp,
          style: currentComp.style ? currentComp.style : suggestedComp.style,
          parent: parentId,
          components: suggestedComp.components,
        };
      }
    }
  },
  [constants.COMP_TYPES.SECTION]: (
    editorAPI,
    suggestedComp,
    parentId,
    currentComp,
  ) => {
    switch (currentComp.componentType) {
      case constants.COMP_TYPES.PHOTO: {
        return {
          ...suggestedComp,
          parent: parentId,
          design: {
            ...suggestedComp.design,
            background: {
              ...suggestedComp.design.background,
              mediaRef: {
                ...suggestedComp.design.background.mediaRef,
                uri:
                  currentComp.data.uri ||
                  suggestedComp.design.background.mediaRef.uri,
              },
            },
          },
          components: suggestedComp.components,
        };
      }
      case constants.COMP_TYPES.COLUMN:
      case constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER: {
        return {
          ...suggestedComp,
          // style: currentComp.style ? currentComp.style : suggestedComp.style,
          parent: parentId,
          design: {
            ...suggestedComp.design,
            background: {
              ...currentComp.design.background,
            },
          },
          components: suggestedComp.components,
        };
      }
      default: {
        return {
          ...suggestedComp,
          style: currentComp.style ? currentComp.style : suggestedComp.style,
          parent: parentId,
          components: suggestedComp.components,
        };
      }
    }
  },
};

export const componentsMap: RegularCompTypeMap = {
  [constants.COMP_TYPES.TEXT]: async (
    editorAPI,
    suggestedComp,
    parentId,
    parentCompType,
    currentComp,
    currentRootSerialized,
    currentPostNormalizeFunctions,
    currentPostRenderOperations,
    rules,
  ) => {
    const layoutY = getLayoutY(suggestedComp, currentComp);
    const originCompId = suggestedComp.id;

    const serializedTextComponent = {
      ...currentComp,
      style: currentComp.style,
      connections: suggestedComp.connections,
      parent: parentId,
      data: {
        ...currentComp.data,
      },
      layout: {
        ...suggestedComp.layout,
        y: layoutY,
      },
      components: suggestedComp.components,
      originCompId,
    };

    // Rules - rendering text component for getting its height and validate with rules.
    // get text type: title/subtitle/paragraph/..
    const textType =
      suggestedComp?.connections?.items[0].role.split('Layout')[0];
    // get relevant rules for current text type
    const textRulesPerTextType = rules?.texts?.[textType];
    if (textRulesPerTextType) {
      const isValid = await rulesMap[constants.COMP_TYPES.TEXT](
        editorAPI,
        serializedTextComponent,
        switchLayoutRulesTypes.SIZE,
        textRulesPerTextType?.size,
      );
      if (!isValid) return null;
    }

    // serializedTextComponent.data.text = suggestedComp.data.text;
    return serializedTextComponent;
  },
  [constants.COMP_TYPES.SITE_BUTTON]: async (
    editorAPI,
    suggestedComp,
    parentId,
    parentCompType,
    currentComp,
  ) => {
    const mergedComp = currentComp;
    return {
      ...mergedComp,
      parent: parentId,
      layout: {
        ...suggestedComp.layout,
        width: Math.max(suggestedComp.layout.width, currentComp.layout.width),
      },
      data: currentComp.data,
    };
  },
};

export const postMigrationComponentsMap: PostMigrationTypeMap = {
  [constants.COMP_TYPES.PHOTO]: async (editorAPI, parentId, migratedComp) => {
    migratedComp.data.focalPoint = await focalPoint.getFocalPoint(
      migratedComp.data.uri,
    );
    if (!(migratedComp as any).stateRefs) {
      (migratedComp as any).stateRefs = {};
    }

    return migratedComp;
  },
  [constants.COMP_TYPES.COLUMN]: async (editorAPI, parentId, migratedComp) => {
    if (
      migratedComp.design &&
      migratedComp.design?.background &&
      migratedComp.design.background?.mediaRef &&
      migratedComp.design.background.mediaRef?.type === 'Image'
    ) {
      migratedComp.design.background.mediaRef.focalPoint =
        await focalPoint.getFocalPoint(
          migratedComp.design.background.mediaRef.uri,
        );
    }
    return migratedComp;
  },
};

import React from 'react';
import * as stateManagement from '#packages/stateManagement';
import { Button, Text, IconButton } from '@wix/wix-base-ui';
import * as helpIds from '#packages/helpIds';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import * as symbols from '@wix/santa-editor-symbols';
import type { MapStateToProps } from 'types/redux';

import AutoTranslationMode from './autoTranslationMode';
import FloatingModeBar from '../floatingModeBar/floatingModeBar';
import { MultilingualPages } from '#packages/stateManagement';
import experiment from 'experiment';

const { openHelpCenter } = stateManagement.panels.actions;

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

interface LanguageModeStateState {
  isDragging: boolean;
}
class LanguageModeBar extends React.Component<
  LanguageModeStateProps & LanguageModeBarDispatchProps,
  LanguageModeStateState
> {
  state = {
    isDragging: false,
  };

  componentDidMount() {
    util.keyboardShortcuts.setContext(
      util.keyboardShortcuts.CONTEXTS.MULTILINGUAL_MODE,
    );
    this.props.sendOpenedBI(this.props.currentLanguageCode);
  }

  componentWillUnmount() {
    util.keyboardShortcuts.setContext(util.keyboardShortcuts.CONTEXTS.EDITOR);
    this.props.sendClosedBI(this.props.currentLanguageCode);
  }

  getTitle = () => {
    const defaultTitle = (
      <div className="language-mode-bar__title">
        <symbols.symbol name="modeBarGlobe" />
      </div>
    );

    return isNewWorkspace ? undefined : defaultTitle;
  };

  handleTranslationManagerClick = () => {
    this.props.openTranslationManagerPanel(this.props.currentLanguageCode);
    this.props.sendOpenedDashboardBI(
      'editor',
      this.props.currentLanguageCode,
      'mode_bar',
    );
  };

  newWorkspaceActionBar = () => {
    return (
      <div className="floating-mode-bar__btn-wrapper">
        {this.autoTranslateButton()}
        {this.translationManagerButton()}
        {this.helpButton()}
      </div>
    );
  };

  helpButton = () => (
    <>
      <div className="language-mode-bar__btn-help-wrapper">
        <IconButton
          skin="standard"
          priority="secondary"
          onClick={this.props.openHelpCenter}
        >
          <symbols.symbol name="question_mark" />
        </IconButton>
      </div>
    </>
  );

  autoTranslateButton = () => <AutoTranslationMode />;

  translationManagerButton = () => (
    <div className="language-mode-bar__container-translation-manager-btn">
      <Button
        automationId="language-mode-bar-manage-translations-button"
        className="btn-sm"
        onClick={this.handleTranslationManagerClick}
      >
        {translate('Multilingual_Mode_OneClick_ManageTranslations_Button')}
      </Button>
    </div>
  );

  render() {
    const isUseLocaleUIOpened = experiment.isOpen('specs.ml.UseLocaleUI');

    const currentLocaleName =
      this.props.currentRegionCode && isUseLocaleUIOpened
        ? `${translate(
            `locale-dataset.languages.${this.props.currentLanguageCode}`,
          )} (${this.props.currentRegionCode.toUpperCase()})`
        : translate(
            `locale-dataset.languages.${this.props.currentLanguageCode}`,
          );

    return (
      <FloatingModeBar
        draggableExtentionSlot={
          stateManagement.draggableSlots.DRAGGABLE_SLOTS.MULTILINGUAL_MODE_BAR
        }
        title={this.getTitle()}
        className={util.cx([
          'language-mode-bar',
          {
            'with-label-border': isNewWorkspace,
          },
        ])}
        content={
          <>
            <div className="language-mode-bar__now-editing">
              <Text
                size="small"
                shouldTranslate={false}
                dataHook="language-mode-bar-pick-language-button-text"
              >
                {translate('LangMenu_Manager_ModeBar_NowEditing_Label', {
                  Lang_Name: currentLocaleName,
                })}
              </Text>
            </div>
            {!isNewWorkspace && <AutoTranslationMode />}
          </>
        }
        onStartDrag={() => this.setState({ isDragging: true })}
        onEndDrag={() => this.setState({ isDragging: false })}
        onOpenHelpCenter={this.props.openHelpCenter}
        secondaryActionBar={
          isNewWorkspace ? this.newWorkspaceActionBar() : undefined
        }
      />
    );
  }
}

interface LanguageModeBarDispatchProps {
  openHelpCenter(): void;
  sendOpenedBI(lang: string): void;
  sendClosedBI(lang: string): void;
  openTranslationManagerPanel(currentLanguageCode: string): void;
  sendOpenedDashboardBI(hosting: string, lang: string, origin: string): void;
}

interface LanguageModeStateProps {
  currentLanguageCode: string;
  currentCountryCode: string;
  currentRegionCode: string | undefined;
}

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): LanguageModeBarDispatchProps => {
  const sendBI = (eventId: AnyFixMe, lang: AnyFixMe) =>
    dispatch(
      stateManagement.bi.actions.event(eventId, {
        lang,
        origin: 'unknown',
      }),
    );

  return {
    openHelpCenter: () => {
      dispatch(
        openHelpCenter(helpIds.MULTILINGUAL.TRANSLATION_MODE, undefined, {
          origin: 'manage_languages_secondary_bar_drop_menu',
        }),
      );
    },
    openTranslationManagerPanel: (currentLanguageCode: string) => {
      dispatch(
        stateManagement.multilingual.actions.sendOpenDashboardEventThunk(
          'FLOATING_BAR',
          MultilingualPages.TRANSLATION_MANAGER,
          currentLanguageCode,
          'site',
        ),
      );
    },
    sendOpenedBI: (lang) =>
      sendBI(coreBi.events.multilingual.multilingual_mode_opened, lang),
    sendClosedBI: (lang) =>
      sendBI(coreBi.events.multilingual.multilingual_mode_closed, lang),
    sendOpenedDashboardBI: (hosting, lang, origin) => {
      dispatch(
        stateManagement.bi.actions.event(
          coreBi.events.multilingual.dashboard_start_open,
          {
            hosting,
            lang,
            origin,
          },
        ),
      );
    },
  };
};

const mapStateToProps: MapStateToProps<LanguageModeStateProps> = ({
  dsRead: { language },
}) => {
  const originalLanguage = language.original.get();
  const languages = []
    .concat(originalLanguage, language.get())
    .filter((lang) => lang !== undefined);
  const currentLanguageCode = language.current.get();
  const currentLanguage = languages.find(
    (lang) => lang.languageCode === currentLanguageCode,
  );

  return {
    currentLanguageCode,
    currentCountryCode: currentLanguage.countryCode,
    currentRegionCode: currentLanguage.regionCode,
  };
};

const Connected = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LanguageModeBar);

Connected.pure = LanguageModeBar;

export default Connected;

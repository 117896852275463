import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import * as baseUI from '#packages/baseUI';
import * as helpIds from '#packages/helpIds';
import { hoc, cx } from '#packages/util';
import { translate } from '#packages/i18n';

import { convertPageIdToLinkPageId } from '../../utils';
import { PageSelection } from '../PageSelection/PageSelection';
import { mapStateToProps, mapDispatchToProps } from './PopupLink.mapper';
import type { TPageLink } from '../../types';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export interface PopupLinkComponentOwnProps {
  showAllPopups: boolean;
  link: TPageLink;
  origin: string;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TPageLink): void;
  onPopupAdded(link: TPageLink): void;
  onCreateButtonClick(): void;
}

interface PopupLinkComponentProps
  extends PopupLinkComponentOwnProps,
    ReturnType<typeof mapDispatchToProps>,
    ReturnType<typeof mapStateToProps> {}

interface PopupLinkComponentState {
  link: TPageLink;
  prevLink: TPageLink;
}

export class PopupLinkComponent extends React.Component<
  PopupLinkComponentProps,
  PopupLinkComponentState
> {
  private popups: any[];

  constructor(props: AnyFixMe) {
    super(props);
    const link = props.link || this.props.createDefaultData('PageLink');

    this.popups = this.getPopups();

    this.state = {
      prevLink: link,
      link,
    };
  }

  static getDerivedStateFromProps(
    props: PopupLinkComponentProps,
    state: PopupLinkComponentState,
  ) {
    const nextLink =
      state.prevLink?.id !== props.link?.id ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(!!this.state.link.pageId);
  }
  UNSAFE_componentWillReceiveProps(newProps: AnyFixMe) {
    if (newProps.popups !== this.props.popups) {
      this.popups = this.getPopups();
    }
  }
  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
      this.props.onValidationUpdate(!!this.state.link.pageId);
    }
  }

  private getPopups() {
    const { popups, focusedPageId, checkIsPagePopup, showAllPopups } =
      this.props;

    const isFocusedPagePopup = checkIsPagePopup(focusedPageId);
    if (!showAllPopups && isFocusedPagePopup) {
      return popups.filter((popup) => popup.id !== focusedPageId);
    }

    return popups;
  }

  private setPageId = (page: AnyFixMe) => {
    const { link } = this.state;
    const pageId = convertPageIdToLinkPageId(page?.id);

    this.setState({
      link: {
        ...link,
        pageId,
      },
    });
  };

  private openLearnMore = () => {
    this.props.openHelpCenter(
      this.props.isMobileEditor
        ? helpIds.MOBILE_ONLY.LINK_PANEL.POPUP_LINK
        : helpIds.LIGHTBOX.ABOUT,
    );
  };

  private getAddLightBoxBtnLabel = () => {
    return 'LINK_PANEL_TAB_ADD_NEW_POP-UP_LINK';
  };

  private getNoPopusTextKey = () => {
    if (this.props.isMobileEditor) {
      return 'MOBILE_LINK_PANEL_TAB_POP-UP_TEXT';
    }

    return 'LINK_PANEL_TAB_POP-UP_TEXT';
  };

  private handleCreatePopupClick = () => {
    this.props.openAddPanelPopupSection();
    this.props.onCreateButtonClick();
  };

  render() {
    if (!this.popups.length) {
      return (
        <div className="no-popups">
          <div className="symbol-container">
            <Symbol name="noPopup" />
          </div>
          <h3>
            {translate(
              this.props.isMobileEditor
                ? 'MOBILE_LINK_PANEL_TAB_POP-UP_TITLE'
                : 'LINK_PANEL_TAB_POP-UP_TITLE',
            )}
          </h3>

          <p>{translate(this.getNoPopusTextKey())}</p>

          {!this.props.isMobileEditor ? (
            <baseUI.button
              automationId="link-panel-popup-link-add-lightbox-button"
              className="btn-confirm-secondary btn-add-lightbox"
              icon="plus"
              label={this.getAddLightBoxBtnLabel()}
              onClick={this.handleCreatePopupClick}
            />
          ) : null}

          <baseUI.button
            automationId="link-panel-popup-link-learn-more-button"
            label={
              this.props.isMobileEditor
                ? 'MOBILE_LINK_PANEL_NO_ANCHOR_TEXT_ADDPANEL_MENUS_LINK'
                : 'LINK_PANEL_LIGHTBOX_SECONDARY_BUTTON'
            }
            className={cx('btn-text', {
              'no-margins': this.props.isMobileEditor,
            })}
            onClick={this.openLearnMore}
          />
        </div>
      );
    }

    return (
      <>
        <PageSelection
          origin={this.props.origin}
          pages={this.popups}
          onPageSelect={this.setPageId}
          pageDropDownTitle={translate('LINK_PANEL_TAB_POP-UP_DROP_DOWN_LABEL')}
          link={this.state.link}
        />

        {!this.props.isMobileEditor ? (
          <p key="extraInfo" className="extra-info">
            {translate('LINK_PANEL_TAB_ADD_NEW_POP-UP_TEXT')}&nbsp;
            <baseUI.button
              automationId="link-panel-popup-link-add-lightbox-button"
              label="LINK_PANEL_TAB_ADD_NEW_POP-UP_LINK"
              onClick={this.handleCreatePopupClick}
              className="btn-text"
            />
          </p>
        ) : null}
      </>
    );
  }
}

export const PopupLink = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PopupLinkComponent);

PopupLink.pure = PopupLinkComponent;

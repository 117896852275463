import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import type { MapDispatchToProps } from 'types/redux';
import type { MultilingualGoogleTranslateWarningPanelOwnProps } from './multilingualGoogleTranslateWarningPanel';

const { setSiteUserPreferences } = stateManagement.userPreferences.actions;
const { closePanelByNameAction } = stateManagement.panels.actions;
const { event: sendBiEvent } = stateManagement.bi.actions;

const {
  google_translate_cancel_modal_clicked,
  auto_translate_confirm_clicked,
  dont_show_this_again_clicked,
} = coreBi.events.multilingual;

const { DONT_SHOW_AGAIN: DONT_SHOW_AGAIN_KEY } =
  constants.USER_PREFS.MULTILINGUAL.GOOGLE_TRANSLATE_WARNING;

export interface MultilingualGoogleTranslateWarningDispatchProps {
  closePanel(dontShowAgain: boolean): void;
  sendCancelBi(type: 'x' | 'cancel'): void;
  sendConfirmBi(): void;
  sendDontShowAgainBi(): void;
}

const mapDispatchToProps: MapDispatchToProps<
  MultilingualGoogleTranslateWarningDispatchProps,
  MultilingualGoogleTranslateWarningPanelOwnProps
> = (dispatch, props) => {
  return {
    sendCancelBi: (type: 'x' | 'cancel') => {
      dispatch(
        sendBiEvent(google_translate_cancel_modal_clicked, {
          words_in_credit: props.wordsRemain,
          words_translated: props.translatedWords,
          origin:
            props.source === 'site-translation' ? 'floating_bar' : 'editor',
          type,
          is_credit_enough: true,
          words_remain: props.wordsRemain - props.contentLength,
          words_needed: 0,
        }),
      );
    },

    sendConfirmBi: () => {
      dispatch(
        sendBiEvent(auto_translate_confirm_clicked, {
          words_in_credit: props.wordsRemain,
          words_translated: props.translatedWords,
          origin: 'editor',
          is_credit_enough: true,
          words_remain: props.wordsRemain - props.contentLength,
          words_needed: 0,
        }),
      );
    },

    sendDontShowAgainBi: () => {
      dispatch(
        sendBiEvent(dont_show_this_again_clicked, {
          words_in_credit: props.wordsRemain,
          words_translated: props.translatedWords,
          origin:
            props.source === 'site-translation' ? 'floating_bar' : 'editor',
          is_credit_enough: true,
          words_remain: props.wordsRemain - props.contentLength,
          words_needed: 0,
        }),
      );
    },

    closePanel: (dontShowAgain: boolean) => {
      dispatch(setSiteUserPreferences(DONT_SHOW_AGAIN_KEY, dontShowAgain));
      dispatch(closePanelByNameAction(props.panelName));
    },
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
);

import _ from 'lodash';

import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

import { ErrorReporter } from '@wix/editor-error-reporter';
import { createSelectionCompsCache } from './createSelectionCompsCache';
import { createSelectionByClickStore } from './createSelectionByClickStore';
import { getComponentsByXY as getComponentsByXYInner } from './getComponentsByXY';

import type { MouseEvent } from 'react';
import type { SelectionBaseApi } from '../selectionBaseApi/createSelectionBaseApi';
import type { SelectionFocusApi } from '../selectionFocus/createSelectionFocusApi';
import type { SelectionKeyboardAndMouseApi } from '../createSelectionKeyboardAndMouseApi';

import type { InteractionsApi } from '../interactions/createInteractionsApi';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompLayout } from 'types/documentServices';
import type { SelectionHooks } from '../createSelectionHooks';

export function createSelectionByClickApi({
  editorAPI,
  selectionHooks,
  selectionBaseApi,
  selectionFocusApi,
  selectionKeyboardAndMouseApi,
  interactionsApi,
}: {
  editorAPI: EditorAPI;
  selectionHooks: SelectionHooks;
  selectionBaseApi: SelectionBaseApi;
  selectionFocusApi: SelectionFocusApi;
  selectionKeyboardAndMouseApi: SelectionKeyboardAndMouseApi;
  interactionsApi: InteractionsApi;
}) {
  const { isMultiselect } = util.array;
  const componentsSelectors = stateManagement.components.selectors;
  const componentsByXYCache = createSelectionCompsCache();
  const selectionByClickStore = createSelectionByClickStore(editorAPI.store);

  let explicitlySelectedComp: CompRef = null;
  function isComponentExplicitlySelected(): boolean {
    return explicitlySelectedComp !== null;
  }

  let activeModalComponent: CompRef = null;
  function setModalComponent(component: CompRef): void {
    activeModalComponent = component;
  }

  function removeCompsOutsideContainer(comps: CompRef[], container: CompRef) {
    const { isDescendantOfComp } = editorAPI.components;
    return comps.filter(
      (compRef) =>
        isDescendantOfComp(compRef, container) || compRef.id === container.id,
    );
  }

  function shouldFindOverlappingComp(
    compToBeSelected: CompRef,
    selectedComponent: CompRef,
  ) {
    const notAlreadySelected = !editorAPI.utils.isSameRef(
      compToBeSelected,
      selectedComponent,
    );

    if (util.sections.isSectionsEnabled()) {
      const sectionParent =
        editorAPI.sections.getClosestSection(compToBeSelected);
      return sectionParent
        ? editorAPI.utils.isSameRef(sectionParent, selectedComponent) &&
            notAlreadySelected
        : notAlreadySelected;
    }

    return notAlreadySelected;
  }

  function getDynastyToBeSelectedBeforeComp(compRef: CompRef): CompRef[] {
    const closestAncestor = editorAPI.components.findAncestor(
      compRef,
      (ancestorRef) =>
        editorAPI.components.is.selectedBeforeDescendants(ancestorRef, compRef),
      { includeScopeOwner: true },
    );

    const dynasty = closestAncestor
      ? _(closestAncestor)
          .thru(getDynastyToBeSelectedBeforeComp)
          .flatten()
          .value()
      : [];

    return _.compact([closestAncestor].concat(dynasty));
  }

  function replaceCompToBeSelectedConsideringAncestors(
    compToBeSelected: CompRef,
  ): CompRef {
    const dynasty = getDynastyToBeSelectedBeforeComp(compToBeSelected);

    if (dynasty.length === 0) {
      return compToBeSelected;
    }

    const closestAncestor = dynasty[0];
    const greatestAncestor = dynasty[dynasty.length - 1];

    const isCloseCousin =
      selectionBaseApi.isAncestorOfSelectedComponents(closestAncestor);
    if (isCloseCousin) {
      return compToBeSelected;
    }

    const isFarCousin =
      selectionBaseApi.isAncestorOfSelectedComponents(greatestAncestor);
    const isGreatestAncestorSelected =
      selectionBaseApi.isComponentSelected(greatestAncestor);

    if (isFarCousin || isGreatestAncestorSelected) {
      const dynastyUntilSelectedComponent = _.dropRightWhile(
        dynasty,
        (ancestor) =>
          selectionBaseApi.isAncestorOfSelectedComponents(ancestor) ||
          selectionBaseApi.isComponentSelected(ancestor),
      );
      return (
        dynastyUntilSelectedComponent[
          dynastyUntilSelectedComponent.length - 1
        ] || compToBeSelected
      );
    } else if (isFarCousin) {
      return closestAncestor;
    }

    return greatestAncestor;
  }

  function getFirstOverlappingComponent(
    compToBeSelected: CompRef,
    event: MouseEvent,
  ): CompRef {
    let componentsUnderClick = getComponentsUnderClick(event);
    componentsUnderClick = componentsUnderClick.filter(
      editorAPI.components.is.selectable,
    );

    componentsUnderClick = componentsUnderClick.filter(function (comp) {
      return !(
        ['SITE_PAGES', 'PAGES_CONTAINER'].includes(comp.id) ||
        editorAPI.utils.isPage(comp) ||
        editorAPI.utils.isSameRef(compToBeSelected, comp) ||
        editorAPI.components.getType(comp) ===
          'wysiwyg.viewer.components.Column' ||
        editorAPI.components.is.descendantOfRepeaterItem(comp) ||
        editorAPI.components.is.group(
          editorAPI.components.getContainer(comp),
        ) ||
        componentsSelectors.isDescendantOfAppWidget(comp, editorAPI.dsRead)
      );
    }) as CompRef[];

    return componentsUnderClick[0];
  }

  function canSelectCompConsideringSpotlightStage(comp: CompRef): boolean {
    const focusedContainer = selectionFocusApi.getFocusedContainer();
    return util.spotlightStageUtils.canSelectCompConsideringSpotlightStage(
      editorAPI,
      focusedContainer,
      comp,
    );
  }

  function isFullyCovered(
    overlappingCompLayout: CompLayout,
    overlappedCompLayout: AnyFixMe,
  ) {
    return (
      overlappingCompLayout.bounding.x <= overlappedCompLayout.bounding.x &&
      overlappedCompLayout.bounding.x + overlappedCompLayout.bounding.width <=
        overlappingCompLayout.bounding.x +
          overlappingCompLayout.bounding.width &&
      overlappingCompLayout.bounding.y <= overlappedCompLayout.bounding.y &&
      overlappedCompLayout.bounding.y + overlappedCompLayout.bounding.height <=
        overlappingCompLayout.bounding.y + overlappingCompLayout.bounding.height
    );
  }

  function shouldChangeOverlappingComp(
    currentSelectionCandidate: CompRef,
    overlappedComponent: CompRef,
  ) {
    if (
      !overlappedComponent ||
      !canSelectCompConsideringSpotlightStage(overlappedComponent)
    ) {
      return false;
    }
    const overlapRatio = 0.7;
    const currentOverlappingCompLayout =
      editorAPI.components.layout.getRelativeToScreenConsideringScroll(
        currentSelectionCandidate,
      );
    const overlappedCompLayout = !interactionsApi.isInInteractionMode()
      ? editorAPI.components.layout.getRelativeToScreenConsideringScroll(
          overlappedComponent,
        )
      : (() => {
          const compBoundingBox =
            editorAPI.documentServices.components.layout.measure.getBoundingClientRect(
              overlappedComponent,
            );
          const overlappedCompLayout = {
            ...compBoundingBox,
            y: compBoundingBox.absoluteTop,
            x: compBoundingBox.absoluteLeft,
          };
          return {
            ...overlappedCompLayout,
            bounding: overlappedCompLayout,
          };
        })();

    const currentSelectionCompArea =
      currentOverlappingCompLayout.width * currentOverlappingCompLayout.height;
    const overlappedCompArea =
      overlappedCompLayout.width * overlappedCompLayout.height;
    return (
      isFullyCovered(currentOverlappingCompLayout, overlappedCompLayout) &&
      currentSelectionCompArea * overlapRatio >= overlappedCompArea
    );
  }

  function replaceComponentSelectedIfNeeded(
    compToBeSelected: CompRef,
    selectedComponent: CompRef,
    event: MouseEvent,
  ): CompRef {
    const alreadySelected =
      selectionBaseApi.isComponentSelected(compToBeSelected);

    if (
      alreadySelected ||
      selectionKeyboardAndMouseApi.isDirectSelection(event)
    ) {
      return compToBeSelected;
    }

    if (shouldFindOverlappingComp(compToBeSelected, selectedComponent)) {
      const overlappingComponent = getFirstOverlappingComponent(
        compToBeSelected,
        event,
      );

      if (shouldChangeOverlappingComp(compToBeSelected, overlappingComponent)) {
        return overlappingComponent;
      }
    }

    return replaceCompToBeSelectedConsideringAncestors(compToBeSelected);
  }

  function getComponentsUnderClick(event: MouseEvent): CompRef[] {
    const { clientX: x, clientY: y } =
      selectionKeyboardAndMouseApi.getViewerMouseCoordinates(event);
    const cacheContext = {
      scopes: selectionBaseApi.getSelectedComponentsScopes(),
    };
    let comps = componentsByXYCache.get(x, y, cacheContext);

    if (!comps) {
      comps = getComponentsByXY(x, y);

      if (
        interactionsApi.isInInteractionMode() &&
        interactionsApi.showInteractionModeControls()
      ) {
        comps = removeCompsOutsideContainer(
          comps,
          interactionsApi.getInteractionTriggerRef(),
        );
      }
      if (editorAPI.componentFocusMode.isEnabled()) {
        comps = removeCompsOutsideContainer(
          comps,
          editorAPI.componentFocusMode.getCompRef(),
        );
      }

      componentsByXYCache.set(x, y, cacheContext, comps);
    }

    return comps;
  }

  function getNonGroupCompUnderCursor(event: MouseEvent): CompRef {
    const compsUnderClick = getComponentsUnderClick(event).filter((comp) =>
      canSelectCompConsideringSpotlightStage(comp),
    );
    if (compsUnderClick.length === 0) {
      return null;
    }

    const componentsUnderClickSet = new Set(
      compsUnderClick.map((comp) => comp.id),
    );
    let currIndex = 0;
    let compOnTop = compsUnderClick[currIndex];

    if (compOnTop) {
      if (interactionsApi.isInInteractionMode()) {
        return compOnTop || null;
      }
      let compParentWithOverflowFlag =
        editorAPI.layouters.getParentCompWithOverflowHidden(compOnTop);
      while (
        editorAPI.components.is.group(compOnTop) ||
        !editorAPI.components.is.selectable(compOnTop) ||
        (compParentWithOverflowFlag &&
          !componentsUnderClickSet.has(compParentWithOverflowFlag.id))
      ) {
        currIndex++;
        compOnTop = compsUnderClick[currIndex];
        compParentWithOverflowFlag =
          compOnTop &&
          editorAPI.layouters.getParentCompWithOverflowHidden(compOnTop);
      }
    } else {
      while (
        editorAPI.components.is.group(compOnTop) ||
        !editorAPI.components.is.selectable(compOnTop)
      ) {
        currIndex++;
        compOnTop = compsUnderClick[currIndex];
      }
    }

    return compOnTop || null;
  }

  function getComponentUnderClickToBeSelected(event: MouseEvent): CompRef {
    const selectedComponents = selectionBaseApi.getSelectedComponents();
    let compToBeSelected = getNonGroupCompUnderCursor(event);

    if (!compToBeSelected) {
      const focusedPage = editorAPI.pages.getFocusedPage();
      if (editorAPI.dsRead.pages.isWidgetPage(focusedPage.id)) {
        return editorAPI.components.getChildren(focusedPage)[0];
      }
      return null;
    }

    compToBeSelected = replaceComponentSelectedIfNeeded(
      compToBeSelected,
      selectedComponents[0],
      event,
    );

    return compToBeSelected;
  }

  function shouldEnableMouseUpSelectionConsideringAncestors(
    event: MouseEvent,
  ): boolean {
    const compToBeSelected = getNonGroupCompUnderCursor(event);
    const selectedBeforeDescendantsAncestors =
      getDynastyToBeSelectedBeforeComp(compToBeSelected);

    if (selectedBeforeDescendantsAncestors.length === 0) {
      return false;
    }

    const closestAncestor = selectedBeforeDescendantsAncestors[0];
    const greatestAncestor =
      selectedBeforeDescendantsAncestors[
        selectedBeforeDescendantsAncestors.length - 1
      ];

    const isFarCousin =
      selectionBaseApi.isAncestorOfSelectedComponents(greatestAncestor);
    const isCloseCousin =
      selectionBaseApi.isAncestorOfSelectedComponents(closestAncestor);
    const isGreatestAncestorSelected =
      selectionBaseApi.isComponentSelected(greatestAncestor);

    return (isFarCousin && !isCloseCousin) || isGreatestAncestorSelected;
  }

  function isComponentVisible(compRef: CompRef) {
    return (
      editorAPI.dsRead.components.is.visible(compRef) &&
      componentsSelectors.getIsComponentVisibleInCurrentMode(
        editorAPI.dsRead,
        compRef,
        editorAPI.store.getState(),
      )
    );
  }

  function getComponentsByXY(x: number, y: number): CompRef[] {
    return getComponentsByXYInner(editorAPI, x, y).filter(
      (compRef) =>
        isComponentVisible(compRef) &&
        selectionBaseApi.isComponentScopeSelected(compRef),
    );
  }

  function getComponentsByXYConsideringUnselectedScopes(
    x: number,
    y: number,
  ): CompRef[] {
    return getComponentsByXYInner(editorAPI, x, y).filter((compRef) =>
      isComponentVisible(compRef),
    );
  }

  function getComponentUnderClickToBeHovered(event: MouseEvent): CompRef {
    const compToBeSelected = getNonGroupCompUnderCursor(event);
    if (!compToBeSelected) {
      return null;
    }

    const selectedComponents = selectionBaseApi.getSelectedComponents();
    const selectedComponent = selectedComponents[0];
    const replacedComponent = replaceComponentSelectedIfNeeded(
      compToBeSelected,
      selectedComponent,
      event,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(selectedComponents, replacedComponent)
      ? compToBeSelected
      : replacedComponent;
  }

  function getComponentToBeMarkedByHoverBox(
    event: MouseEvent,
    isDragging: boolean,
  ): CompRef {
    const comp =
      (isDragging && explicitlySelectedComp) ||
      getComponentUnderClickToBeHovered(event);
    return activeModalComponent && comp ? activeModalComponent : comp;
  }

  // TODO: ensure we can change it to void
  function selectNonGroupCompUnderCursor(
    event: MouseEvent,
    _siteScale: number,
  ): void | CompRef[] {
    let nonGroupComp = getNonGroupCompUnderCursor(event);
    nonGroupComp =
      nonGroupComp && activeModalComponent
        ? activeModalComponent
        : nonGroupComp;
    const isLeftClick = selectionKeyboardAndMouseApi.isLeftClick(event);
    const compToBeSelected =
      replaceCompToBeSelectedConsideringAncestors(nonGroupComp);

    if (selectionBaseApi.isComponentSelected(compToBeSelected)) {
      return null;
    }

    if (selectionKeyboardAndMouseApi.isMultiSelectKeyPressed(event)) {
      multiselectComponentByClick(compToBeSelected);
      return;
    }

    selectSingleComponentByClick(compToBeSelected, isLeftClick);
    return;
  }

  function selectSingleComponentByClick(
    compToBeSelected: CompRef,
    isLeftClick: boolean,
  ): void {
    editorAPI.closeCompPanelIfExists();
    const newCompRef =
      editorAPI.utils.getParentIfCompCanBeSelected(compToBeSelected);
    if (newCompRef) {
      compToBeSelected = newCompRef;
    }
    const compType = editorAPI.components.getType(compToBeSelected);

    ErrorReporter.breadcrumb('clicked on component', {
      compToBeSelected,
      compType,
      isLeftClick,
    });

    selectionBaseApi.selectComponentByCompRef(compToBeSelected);

    if (isLeftClick) {
      editorAPI.openFirstTimeOrDeprecationPanel(compToBeSelected);
    }
  }

  async function selectComponentByClick(event: MouseEvent): Promise<void> {
    const isLeftClick = selectionKeyboardAndMouseApi.isLeftClick(event);
    selectionByClickStore.setLastClickType(isLeftClick ? 'left' : 'right');
    let compToBeSelected = getComponentUnderClickToBeSelected(event);

    if (
      activeModalComponent &&
      compToBeSelected &&
      activeModalComponent.id !== compToBeSelected.id
    ) {
      compToBeSelected = activeModalComponent;
      explicitlySelectedComp = null;
    } else {
      explicitlySelectedComp = compToBeSelected;
    }

    const isAlreadySelected =
      selectionBaseApi.isComponentSelected(compToBeSelected);
    const currentSelected = selectionBaseApi.getSelectedComponents();

    selectionBaseApi.setPrevComponentsAction(currentSelected);

    const interceptionResult =
      selectionHooks.selectComponentByCompClickInterceptor.intercept({
        compToBeSelected,
      });

    if (interceptionResult.isCanceled) {
      return;
    }

    if (!compToBeSelected) {
      selectionBaseApi.deselectComponents();
      return;
    }

    if (
      selectionKeyboardAndMouseApi.isMultiSelectKeyPressed(event) &&
      !isAlreadySelected &&
      !interactionsApi.isInInteractionMode()
    ) {
      const isSectionLikeSelected =
        util.sections.isSectionsEnabled() &&
        currentSelected.some((comp) => editorAPI.sections.isSectionLike(comp));

      if (isSectionLikeSelected) {
        selectionBaseApi.deselectComponents(currentSelected);
      }

      multiselectComponentByClick(compToBeSelected, event);
      selectionByClickStore.setLastSelectionClickPosition(null);
    } else {
      if (!isMultiselect(currentSelected)) {
        const isCompShowOnFixedPosition =
          editorAPI.components.layout.isShowOnFixedPosition(compToBeSelected);
        let lastClickPosition;
        const mouseCoordinates =
          selectionKeyboardAndMouseApi.getViewerMouseCoordinates(event);
        if (isCompShowOnFixedPosition) {
          lastClickPosition = {
            x: mouseCoordinates.clientX,
            y: mouseCoordinates.clientY,
          };
        } else {
          const editorScroll = editorAPI.ui.scroll.getScroll();
          lastClickPosition = {
            x: mouseCoordinates.clientX + editorScroll.scrollLeft,
            y: mouseCoordinates.clientY + editorScroll.scrollTop,
          };
        }

        selectionByClickStore.setLastSelectionClickPosition(lastClickPosition);
      }

      if (
        !selectionKeyboardAndMouseApi.isDirectSelection(event) &&
        (isAlreadySelected ||
          shouldEnableMouseUpSelectionConsideringAncestors(event))
      ) {
        selectionKeyboardAndMouseApi.setIsMouseUpSelectionEnabled(true);
        return;
      }
      selectSingleComponentByClick(compToBeSelected, isLeftClick);
    }
  }

  function multiselectComponentByClick(
    compUnderClick: CompRef,
    event?: MouseEvent,
  ): void {
    if (selectionBaseApi.isComponentSelected(compUnderClick)) {
      if (selectionKeyboardAndMouseApi.isRightClick(event)) {
        return;
      }

      selectionBaseApi.deselectComponents(compUnderClick);
      return;
    }

    editorAPI.closeCompPanelIfExists();
    selectionBaseApi.addComponentToSelectionByRef(compUnderClick);
  }

  function isSelectedCompUnderCursorAndBeneathSiteSegment(
    selectedComponents: CompRef[],
    mouseDownEvent: MouseEvent,
  ): boolean {
    const componentsUnderClick = getComponentsUnderClick(mouseDownEvent);

    const primaryPage = editorAPI.dsRead.pages.getReference(
      editorAPI.dsRead.pages.getPrimaryPageId(),
    );
    const siteSegments = editorAPI.siteSegments.getRefs().concat(primaryPage);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
    const siteSegmentIndex = _.findIndex(
      componentsUnderClick,
      function (compUnderClick) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/some
        return _.some(siteSegments, compUnderClick);
      },
    );

    if (siteSegmentIndex === -1) {
      return false;
    }

    return selectedComponents.some((selectedComp) =>
      componentsUnderClick.some(
        (compUnderClick, compUnderClickIndex) =>
          editorAPI.utils.isSameRef(selectedComp, compUnderClick) &&
          compUnderClickIndex > siteSegmentIndex,
      ),
    );
  }

  return {
    getComponentsByXY,
    getComponentsByXYConsideringUnselectedScopes,
    getComponentsUnderClick,
    getNonGroupCompUnderCursor,
    getComponentToBeMarkedByHoverBox,
    getComponentUnderClickToBeSelected,
    setModalComponent,
    selectComponentByClick,
    selectNonGroupCompUnderCursor,
    isComponentExplicitlySelected,
    isSelectedCompUnderCursorAndBeneathSiteSegment,
  };
}

export type SelectionByClickApi = ReturnType<typeof createSelectionByClickApi>;

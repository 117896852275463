import _ from 'lodash';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { isOnEditorX } from '@wix/santa-editor-utils';
import type { EditorAPI } from '@/editorAPI';

export interface Group {
  id?: string;
  title?: string;
  disabled?: boolean;
  checked?: boolean;
  type?: string;
}

export interface Permission {
  id?: string;
  context?: string;
  resourceIds?: string[];
}

const ALL_MEMBERS_ROLE = '00000000-0000-0000-0000-000000000000';
const ALL_MEMBERS_ROLE_TITLE = translate(
  'NewPages_Panel_Permissions_Member_Roles_Default_Role',
);
const ADMINS_ROLE = '00000000-0000-0000-0000-000000000001';
const ADMINS_ROLE_TITLE = translate(
  'NewPages_Panel_Permissions_Member_Roles_Admins_Role',
);

interface Role {
  title: string;
  disabled: boolean;
}

const SYSTEM_ROLES: Record<string, Role | undefined> = {
  [ALL_MEMBERS_ROLE]: {
    title: ALL_MEMBERS_ROLE_TITLE,
    disabled: false,
  },
  [ADMINS_ROLE]: {
    title: ADMINS_ROLE_TITLE,
    disabled: true,
  },
};

const getAdminGroup = () => ({
  title: ADMINS_ROLE_TITLE,
  id: ADMINS_ROLE,
  disabled: true,
  checked: true,
  type: 'role',
});

const getAllMembersGroup = (checked = true) => ({
  title: ALL_MEMBERS_ROLE_TITLE,
  id: ALL_MEMBERS_ROLE,
  checked,
});
// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/includes
const BASE_URL = `//${
  isOnEditorX() ? 'create.editorx.com' : 'editor.wix.com'
}/_api/members-groups-web/v1/groups`;

const badgeGroupType = 'badge_with_permissions';

const getMembersGroupsUrl = {
  add: (groupId: string) => `${BASE_URL}/${groupId}/permissions`,
  remove: (groupId: string) => `${BASE_URL}/${groupId}/permissions/delete`,
  getPagesForGroup: (groupId: string) => `${BASE_URL}/${groupId}/permissions`,
  getForPage: (pageId: string) => `${BASE_URL}/resources/${pageId}`,
  getAvailable: (type?: string) =>
    `${BASE_URL}${(type && `?type=${type}`) || ''}`,
};

interface ConstructPermissionRequestProps {
  pageId: string;
  groupId: string;
}

const constructPermissionRequest = ({
  pageId,
  groupId,
}: ConstructPermissionRequestProps) => ({
  groupId,
  permissions: [
    {
      context: 'Pages',
      resourceIds: [pageId],
      id: 'cce6b124-b14c-4383-aee9-5e0814811ae2',
    },
  ],
});

const fetchFromEndpoint = (
  metaSiteId: string,
  { url }: { url: string },
  metaSiteInstance: string,
) =>
  util.http.fetchJson(url, {
    credentials: 'same-origin',
    method: 'GET',
    headers: new Headers({ Authorization: metaSiteInstance }),
  });

const postToEndpoint = (
  metaSiteId: string,
  { url, pageId, groupId }: { url: string; pageId: string; groupId: string },
  metaSiteInstance: string,
) =>
  util.http.fetch(url, {
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(constructPermissionRequest({ pageId, groupId })),
    headers: new Headers({ Authorization: metaSiteInstance }),
  });

const normalizeGroupsResponse = ({
  shouldCheck = false,
  data,
}: {
  groupType?: string;
  shouldCheck?: boolean;
  data: { groups?: Group[] };
}) => {
  const groups = data?.groups ?? [];

  return _.uniqBy(
    groups.map(
      ({
        id,
        type = 'role',
        title,
        disabled = false,
        checked = shouldCheck,
      }) => ({
        id,
        title: SYSTEM_ROLES[id]?.title || title,
        disabled: SYSTEM_ROLES[id]?.disabled || disabled,
        checked,
        type,
      }),
    ),
    'id',
  );
};

const ensureAdminsRole = (roles: Required<Group>[]) =>
  [getAdminGroup()].concat(_.reject(roles, { id: ADMINS_ROLE }));

const isRolesGroup = (group: Required<Group>) =>
  group.type === 'role' && group.id !== ADMINS_ROLE;

const isPansGroup = (group: Required<Group>) => group.type === 'plan';

const isBadgesGroup = (group: Required<Group>) => group.type === badgeGroupType;

const isAdminGroup = (group: Required<Group>) => group.id === ADMINS_ROLE;

const getGroupsToCopy = (
  sourcePageId: string,
  groupsForPage: Required<Group>[],
  adminRestrictedPage: string[],
) => {
  const hasSpecific =
    !_.isEmpty(groupsForPage) ||
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    _.some(adminRestrictedPage, (id) => id === sourcePageId);
  return hasSpecific ? ensureAdminsRole(groupsForPage) : groupsForPage;
};

const membersGroupsApi = (editorAPI: EditorAPI) => {
  const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
  const metaSiteInstance = editorAPI.dsRead.platform.getAppDataByApplicationId(
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;

  const getAvailableRoles = () =>
    fetchFromEndpoint(
      metaSiteId,
      { url: getMembersGroupsUrl.getAvailable() },
      metaSiteInstance,
    ).then((data) => normalizeGroupsResponse({ data }));

  const getAvailablePlans = () =>
    fetchFromEndpoint(
      metaSiteId,
      { url: getMembersGroupsUrl.getAvailable('plan') },
      metaSiteInstance,
    ).then((data) => normalizeGroupsResponse({ data, groupType: 'plan' }));

  const getAvailableBadges = () =>
    fetchFromEndpoint(
      metaSiteId,
      { url: getMembersGroupsUrl.getAvailable(badgeGroupType) },
      metaSiteInstance,
    ).then((data) =>
      normalizeGroupsResponse({ data, groupType: badgeGroupType }),
    );

  const getPagesOnlyForAdmin = () =>
    fetchFromEndpoint(
      metaSiteId,
      { url: getMembersGroupsUrl.getPagesForGroup(ADMINS_ROLE) },
      metaSiteInstance,
    ).then(
      (response) =>
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find
        _.find(response?.permissions as Permission[], { context: 'Pages' })
          ?.resourceIds ?? [],
    );

  const getGroupsForPage = ({ pageId }: { pageId: string }) =>
    fetchFromEndpoint(
      metaSiteId,
      { url: getMembersGroupsUrl.getForPage(pageId) },
      metaSiteInstance,
    ).then((data) => normalizeGroupsResponse({ data, shouldCheck: true }));

  const updateGroupsPermissions = ({
    pageId,
    groupsToUpdate,
  }: {
    pageId: string;
    groupsToUpdate: Group[];
  }) =>
    Promise.all(
      groupsToUpdate.map(({ id, checked }) =>
        checked
          ? postToEndpoint(
              metaSiteId,
              { url: getMembersGroupsUrl.add(id), pageId, groupId: id },
              metaSiteInstance,
            )
          : postToEndpoint(
              metaSiteId,
              { url: getMembersGroupsUrl.remove(id), pageId, groupId: id },
              metaSiteInstance,
            ),
      ),
    );

  const duplicateGroupsPermissions = (
    sourcePageId: string,
    destinationPageRef: string,
  ) =>
    Promise.all([
      getGroupsForPage({ pageId: sourcePageId }),
      getPagesOnlyForAdmin(),
    ]).then(([groupsForPage, adminRestrictedPage]) =>
      Promise.all(
        getGroupsToCopy(sourcePageId, groupsForPage, adminRestrictedPage).map(
          ({ id }) =>
            postToEndpoint(
              metaSiteId,
              {
                url: getMembersGroupsUrl.add(id),
                pageId: destinationPageRef,
                groupId: id,
              },
              metaSiteInstance,
            ),
        ),
      ),
    );

  return {
    getAvailableRoles,
    getAvailablePlans,
    getAvailableBadges,
    getPagesOnlyForAdmin,
    getGroupsForPage,
    updateGroupsPermissions,
    duplicateGroupsPermissions,
  };
};

export {
  getAdminGroup,
  getAllMembersGroup,
  membersGroupsApi,
  ensureAdminsRole,
  badgeGroupType,
  isRolesGroup,
  isPansGroup,
  isBadgesGroup,
  isAdminGroup,
};

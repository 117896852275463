// @ts-nocheck
import _ from 'lodash';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const openSource = 'change_flash';

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_flash',
      label_active: 'gfpp_mainaction_applied_flash',
      isApplied: gfppDataUtils.getDataDefinedFn('uri'),
      onClick(editorAPI, compRef) {
        const { mediaServices } = editorAPI;
        mediaServices.mediaManager.open(
          mediaServices.mediaManager.categories.FLASH,
          openSource,
          {
            translation: {
              submitButton: translate(
                gfppDataUtils.isDataDefined(editorAPI, compRef, 'uri')
                  ? 'Flash_Media_Change_Flash'
                  : 'Flash_Media_Add_Flash',
              ),
            },
            multiSelect: false,
            callback(items) {
              if (!_.isEmpty(items)) {
                const file = items[0];
                const data = editorAPI.components.data.get(compRef);

                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line you-dont-need-lodash-underscore/assign
                _.assign(
                  data,
                  { uri: file.fileName },
                  _.pick(file, ['width', 'height', 'title']),
                );

                editorAPI.components.data.update(compRef, data);
              }
              editorAPI.mediaServices.setFocusToEditor();
            },
          },
        );
      },
    },
  ],
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.ANIMATION,
    ACTIONS.LINK,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '64927a60-278c-4202-91e7-09eb3ba5d141',
    },
  },
};

import React, { type MouseEvent } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  ColorPickerInput,
  Composites,
  Divider,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';

import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';

import { FocusPanelFrame } from '../frames';

import type {
  ThunkAction,
  MapDispatchToProps,
  MapStateToProps,
} from 'types/redux';
import type { SendBiFunction } from 'types/bi';

const getDsActions: ThunkAction = (dispatch, getState, { dsActions }) =>
  dsActions;
const X_BUTTON = 'x_button';
const CLOSE_METHOD = {
  DONE: 'done',
  X: 'X',
  CANCEL: 'cancel',
  CLICK_OUTSIDE: 'click outside',
};

interface BrowserThemeColorPanelOwnProps {
  panelName: string;
}

interface BrowserThemeColorPanelStateProps {
  color?: string;
  openColorPicker: FunctionFixMe;
  themeColorToHex: FunctionFixMe;
}

interface BrowserThemeColorPanelDispatchProps {
  closePanel: () => void;
  setColorInDS: (color: string) => void;
  dispatchBIEvent: SendBiFunction;
}

interface BrowserThemeColorPanelProps
  extends BrowserThemeColorPanelOwnProps,
    BrowserThemeColorPanelStateProps,
    BrowserThemeColorPanelDispatchProps {}

interface BrowserThemeColorPanelState {
  color: string | undefined;
}

class BrowserThemeColorPanel extends React.Component<BrowserThemeColorPanelProps> {
  static displayName = 'browserThemeColorPanel';

  static propTypes = {
    color: PropTypes.string,
    closePanel: PropTypes.func.isRequired,
    openColorPicker: PropTypes.func.isRequired,
    themeColorToHex: PropTypes.func.isRequired,
    setColorInDS: PropTypes.func.isRequired,
  };

  state: BrowserThemeColorPanelState = {
    color: this.props.color || '#fff',
  };

  onConfirm = () => {
    this.props.dispatchBIEvent(
      coreBi.events.mobileBrowserThemeColorPanel.DONE_BROWSER_THEME_COLOR_PANEL,
      {
        close_method: CLOSE_METHOD.DONE,
        color: this.state.color,
      },
    );
    this.props.setColorInDS(this.state.color);
    this.props.closePanel();
  };

  onCancel = () => {
    this.props.dispatchBIEvent(
      coreBi.events.mobileBrowserThemeColorPanel
        .CLOSE_BROWSER_THEME_COLOR_PANEL,
      {
        close_method: CLOSE_METHOD.CANCEL,
      },
    );
    this.props.closePanel();
  };

  onClosePanel = (closeMethod: string) => {
    this.props.dispatchBIEvent(
      coreBi.events.mobileBrowserThemeColorPanel
        .CLOSE_BROWSER_THEME_COLOR_PANEL,
      {
        close_method:
          closeMethod === X_BUTTON
            ? CLOSE_METHOD.X
            : CLOSE_METHOD.CLICK_OUTSIDE,
      },
    );
    this.props.closePanel();
  };

  openColorPicker = (event: MouseEvent) => {
    const { color } = this.state;
    const position = { left: event.clientX, top: event.clientY };
    this.props.openColorPicker(color, position, {
      onChange: this.handleBackgroundColorChange,
      allowPaletteEditing: false,
    });
    this.props.dispatchBIEvent(
      coreBi.events.mobileBrowserThemeColorPanel.choose_color_clicked,
      { editor_view_mode: 'mobile' },
    );
  };

  handleBackgroundColorChange = (themeColorId: string) => {
    const colorInHex = this.props.themeColorToHex(themeColorId);

    this.setState({ color: colorInHex || undefined });
  };

  render() {
    return (
      <FocusPanelFrame
        automationId={this.props.panelName}
        panelName={this.props.panelName}
        title={translate('mobile_browser_theme_header')}
        showHeader={true}
        helpId="5a1c5cdd-a608-4f3b-b65d-dd6b98d29e8c"
        onClose={this.onClosePanel}
      >
        <div className="browser-theme-color-settings-panel">
          <div className="preview-area">
            <div className="mobile-frame-container">
              <div className="mobile-frame">
                <div className="mobile-frame-top" />
                <div className="site-shadow" />
              </div>
              <div className="preview-container">
                <div
                  style={{ backgroundColor: this.state.color }}
                  className="color-changeable-area"
                >
                  <div className="address-area" />
                </div>
                <Divider long={true} />
              </div>
            </div>
          </div>
          <div className="sidebar">
            <Composites.ColorSelectLabeled>
              <TextLabel value="mobile_browser_theme_color" />
              <ColorPickerInput
                value={this.state.color}
                onClick={this.openColorPicker}
              />
            </Composites.ColorSelectLabeled>
            <Divider long={false} />
            <Composites.RichText>
              <RichText>
                {translate('mobile_browser_theme_preview_text')}
              </RichText>
            </Composites.RichText>
          </div>
        </div>
        <Divider long={true} />
        <Composites.ActionSetHorizontal>
          <Button
            onClick={() => {
              this.onCancel();
            }}
            className="btn-confirm-secondary btn-md no-margin"
          >
            <span>{translate('mobile_browser_theme_cancel_button')}</span>
          </Button>
          <Button
            onClick={() => {
              this.onConfirm();
            }}
            className="btn-md no-margin"
          >
            <span>{translate('mobile_browser_theme_done_button')}</span>
          </Button>
        </Composites.ActionSetHorizontal>
      </FocusPanelFrame>
    );
  }
}

const mapStateToProps: MapStateToProps<
  BrowserThemeColorPanelStateProps,
  BrowserThemeColorPanelOwnProps
> = ({ editorAPI }) => ({
  themeColorToHex: editorAPI.theme.colors.get,
  openColorPicker: editorAPI.openColorPicker,
  color: editorAPI.dsRead.browserThemeColor.get(),
});

const mapDispatchToProps: MapDispatchToProps<
  BrowserThemeColorPanelDispatchProps,
  BrowserThemeColorPanelOwnProps
> = (dispatch, { panelName }) => ({
  closePanel: () => {
    dispatch(stateManagement.panels.actions.closePanelByName(panelName));
  },
  setColorInDS: (color: string) => {
    dispatch(getDsActions).browserThemeColor.set(color);
  },
  dispatchBIEvent: (...args) => {
    dispatch(stateManagement.bi.actions.event(...args));
  },
});

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BrowserThemeColorPanel);

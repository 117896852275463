import React from 'react';
import { Text, Divider, SectionDivider } from '@wix/wix-base-ui';
import { MemberRoleSection } from './MembersPermissionSections/memberRoleSection';
import { PricingPlansSection } from './MembersPermissionSections/pricingPlansSection';
import { BadgesSection } from './MembersPermissionSections/badgesSection';
import { MembersDashboardLink } from './membersDashboardLink';
import styles from './membersPermission.scss';
import { PermissionSection } from './CommonComponents';
import { MembersAccessTypes } from './membersAccessTypes';
import {
  isPansGroup,
  isAdminGroup,
  isBadgesGroup,
  isRolesGroup,
} from '../../../../utils/groupsApi';
import CONSTANTS from '../../../../utils/constants';

import type { MemberAccessPermission } from '../../../../utils/constants';
import type { Group } from '../../../../utils/groupsApi';
import type { RolesPanelProps } from '../permissionsTabMapper';

const { MEMBER_ACCESS_PERMISSIONS } = CONSTANTS;

interface MembersPermissionsProps {
  metaSiteId: string;
  isSiteSaved: boolean;
  permissions?: string[];
  membersAccessPermissions: MemberAccessPermission;
  groupsForPage: Required<Group>[];
  availableGroups: Required<Group>[];
  isPricingPlansAppInstalled: boolean;
  handleMembersAccessChange: (memberAccessType: MemberAccessPermission) => void;
  updateGroupsPermissions: (groupsToUpdate: Group[]) => Promise<void>;
  openRolesPanel: (panelProps: RolesPanelProps) => void;
  openDashboardModal: (path: string) => void;
  openPricingPlansInAppMarket: () => void;
}

export const MembersPermissions: React.FC<MembersPermissionsProps> = ({
  metaSiteId,
  permissions,
  isSiteSaved,
  groupsForPage,
  availableGroups,
  membersAccessPermissions,
  handleMembersAccessChange,
  updateGroupsPermissions,
  openDashboardModal,
  openRolesPanel,
}) => {
  const adminGroup = groupsForPage.filter(isAdminGroup);

  const rolesForPage = groupsForPage.filter(isRolesGroup);
  const availableRoles = availableGroups.filter(isRolesGroup);

  const plansForPage = groupsForPage.filter(isPansGroup);
  const availablePlans = availableGroups.filter(isPansGroup);

  const badgesForPage = groupsForPage.filter(isBadgesGroup);
  const availableBadges = availableGroups.filter(isBadgesGroup);

  const isForAll = membersAccessPermissions === MEMBER_ACCESS_PERMISSIONS.ALL;
  const isBadgesOptionsOn = availableBadges.length > 0;
  const canCreateNewRole = permissions?.includes(
    'my-account.view-site-members',
  );

  return (
    <>
      <div>
        <SectionDivider className={styles.title}>
          <Text skin="secondary" size="small" weight="bold" shouldTranslate>
            {'NewPages_Panel_Permissions_Member_Roles_Options_Title'}
          </Text>
        </SectionDivider>
        <MembersAccessTypes
          accessType={membersAccessPermissions}
          onAccessChange={handleMembersAccessChange}
        />
        <div className={styles.memberSections}>
          <MemberRoleSection
            disabled={isForAll}
            rolesForPage={rolesForPage}
            availableRoles={availableRoles}
            permissions={permissions}
            openRolesPanel={openRolesPanel}
            openDashboardModal={openDashboardModal}
            updateGroupsPermissions={updateGroupsPermissions}
          />
          <Divider long />
          <PricingPlansSection
            disabled={isForAll}
            plansForPage={plansForPage}
            availablePlans={availablePlans}
            permissions={permissions}
            openRolesPanel={openRolesPanel}
            openDashboardModal={openDashboardModal}
            updateGroupsPermissions={updateGroupsPermissions}
          />
          {isBadgesOptionsOn && (
            <>
              <Divider long />
              <BadgesSection
                disabled={isForAll}
                badgesForPage={badgesForPage}
                availableBadges={availableBadges}
                permissions={permissions}
                openRolesPanel={openRolesPanel}
                openDashboardModal={openDashboardModal}
                updateGroupsPermissions={updateGroupsPermissions}
              />
            </>
          )}
          <Divider long />
          <PermissionSection
            title="NewPages_Panel_Permissions_Site_Collaborator_Section_Title"
            subtitle="NewPages_Panel_Permissions_Site_Collaborator_Section_Subtitle"
            connectedGroups={adminGroup}
            disabled={true}
          />
        </div>
      </div>
      {canCreateNewRole && isSiteSaved && (
        <MembersDashboardLink metaSiteId={metaSiteId} />
      )}
    </>
  );
};

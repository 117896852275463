import React, { type FC, useState } from 'react';
import {
  DropDown,
  DropDownOption,
  Composites,
  TextLabel,
  TextInputMultiline,
  InfoIcon,
  Button,
} from '@wix/wix-base-ui';
import {
  AiInfoBlock,
  AiTermsAndConditions,
  BusinessInfo,
} from '#packages/baseUI';
import { connectWithScope } from '#packages/apilib';
import { biLogger } from '#packages/util';
import { translate } from '#packages/i18n';
import {
  aiTextGeneratorAdditionalActions,
  aiTextGeneratorSettingsChanged,
  smartWriterCreateTextClick,
} from '@wix/bi-logger-editor/v2';

import type { SectionCreatorPromptParams } from '../aiSectionCreatorTypes';
import styles from './promptCreator.scss';
import { AddPresetScope } from '../../../../addPresetApiEntry';
import {
  mapStateToProps,
  mapDispatchToProps,
  type PromptCreatorStateProps,
  type PromptCreatorDispatchProps,
} from './promptCreatorMapper';
import { SparklesBoldSmall } from '@wix/wix-ui-icons-common/classic-editor';
import { AI_SECTION_CREATOR_PANEL_TYPE } from '../aiSectionCreatorConsts';
import type { BusinessTypeTypes } from '#packages/panels';
import { smartForm } from '#packages/util';

export interface PromptCreatorOwnProps {
  cachedData: { categoryId: string; description: string };
  sessionId: string;
  onSubmit: (params: SectionCreatorPromptParams) => void;
}

const MAX_DESCRIPTION_LENGTH = 400;

type PromptCreatorProps = PromptCreatorOwnProps &
  PromptCreatorStateProps &
  PromptCreatorDispatchProps;

const BUSINESS_TYPE_PANEL_ORIGIN = 'section_creator';

const getDescirptionErrorMessage = (
  error: Array<
    smartForm.ValidationErrorMessage | smartForm.ValidationErrorMessageWithData
  >,
) => {
  const errorMessages = error.map((error) => {
    if (error.message === smartForm.VALIDATOR_ERROR_MESSAGES.MAX_LENGTH_ERROR) {
      return translate(
        'add_section_ai_panel_free_text_tooltip_error_max_length',
      );
    }
    if (error.message === smartForm.VALIDATOR_ERROR_MESSAGES.REQUIRED_ERROR) {
      return translate('add_section_ai_panel_free_text_error_required');
    }
    return '';
  });
  return errorMessages.join(', ');
};

const PromptCreator: FC<PromptCreatorProps> = ({
  categories,
  cachedData,
  sessionId,
  handleSubmitEvent,
}) => {
  const [categoryId, setCategoryId] = useState<string>(
    cachedData.categoryId || categories[0]?.id,
  );

  const descirptionFieldControls = smartForm.useSmartField<string>(
    cachedData.description,
    [smartForm.maxLengthValidator(MAX_DESCRIPTION_LENGTH)],
  );

  const isFormInvalid =
    !descirptionFieldControls.isValid && descirptionFieldControls.isDirty;

  const handleSubmitButtonClick = () => {
    const isFormValid = descirptionFieldControls.isValid;

    biLogger.report(
      smartWriterCreateTextClick({
        isAiTextCreator: true,
        sessionId,
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
        success: isFormValid,
      }),
    );

    if (!isFormValid) return;

    handleSubmitEvent({
      categoryId,
      description: descirptionFieldControls.value,
    });
  };

  const sendAdditionalActionEvent = (actionName: string) => {
    biLogger.report(
      aiTextGeneratorAdditionalActions({
        actionName,
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
        sessionId,
      }),
    );
  };

  const handleInfoIconHover = (fieldName: string) =>
    sendAdditionalActionEvent(`${fieldName} -> info button -> hover`);

  const handleCategoryChange = (value: string) => {
    biLogger.report(
      aiTextGeneratorSettingsChanged({
        fieldName: 'section type',
        mandatoryField: 'yes',
        newValue: value,
        sessionId,
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
      }),
    );
    setCategoryId(value);
  };

  const handleDescriptionChange = (value: string) =>
    descirptionFieldControls.setValue(value);

  const handleDescriptionBlur = () => {
    biLogger.report(
      aiTextGeneratorSettingsChanged({
        fieldName: 'text include',
        mandatoryField: 'yes',
        newValue: descirptionFieldControls.value,
        sessionId,
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
      }),
    );

    descirptionFieldControls.setDirty();
  };

  const handleBusinessDataChange = (
    data: BusinessTypeTypes.BusinessInfoData,
  ) => {
    biLogger.report(
      aiTextGeneratorSettingsChanged({
        fieldName: 'site info',
        mandatoryField: 'yes',
        newValue: JSON.stringify(data),
        sessionId,
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
      }),
    );
  };

  return !categories ? (
    <div>Sorry, we have an issue on our side, please try again later</div>
  ) : (
    <div
      className={styles.wrapper}
      data-hook="ai-section-creator_prompt-creator"
    >
      <AiInfoBlock
        title={translate('add_section_ai_panel_title')}
        text={translate('add_section_ai_panel_generate_subtitle')}
      />
      <div className={styles.formWrapper}>
        <BusinessInfo
          origin={BUSINESS_TYPE_PANEL_ORIGIN}
          onTooltipOpen={() => handleInfoIconHover('Site info')}
          onChangeInfoClick={() =>
            sendAdditionalActionEvent("click to open 'create for' panel")
          }
          onBusinessDataUpdated={handleBusinessDataChange}
          infoIconText="add_section_ai_site_info_tooltip_text"
        />
        <Composites.DropDownLabeled>
          <TextLabel
            type="T02"
            shouldTranslate={false}
            value={translate(
              'add_section_ai_panel_section_type_dropdown_label',
            )}
          />
          <InfoIcon
            shouldTranslate={false}
            onTooltipOpen={() => handleInfoIconHover('Section type')}
            text={translate('add_section_ai_section_type_tooltip_text')}
          />
          <DropDown
            value={categoryId}
            shouldTranslate={false}
            dataHook="ai-section-creator_category"
            onChange={handleCategoryChange}
          >
            {categories.map((category) => (
              <DropDownOption
                shouldTranslate={false}
                key={`option_${category.id}`}
                value={category.id}
                label={translate(category.name)}
              />
            ))}
          </DropDown>
        </Composites.DropDownLabeled>
        <Composites.TextInputLabeled>
          <TextLabel
            type="T02"
            shouldTranslate={false}
            value={translate('add_section_ai_panel_free_text_label')}
          />
          <InfoIcon
            shouldTranslate={false}
            onTooltipOpen={() => handleInfoIconHover('text include')}
            text={translate('add_section_ai_free_text_tooltip_text')}
          />
          <TextInputMultiline
            value={descirptionFieldControls.value}
            shouldTranslate={false}
            className={styles.multilineInput}
            onChange={handleDescriptionChange}
            onBlur={handleDescriptionBlur}
            dataHook="ai-section-creator_description"
            isValid={
              !descirptionFieldControls.isDirty ||
              descirptionFieldControls.isValid
            }
            invalidMessage={getDescirptionErrorMessage(
              descirptionFieldControls.errors,
            )}
            placeholder={translate(
              'add_section_ai_panel_free_text_placeholder',
            )}
          />
        </Composites.TextInputLabeled>
      </div>
      <div className={styles.submitWrapper}>
        <Button
          dataHook="ai-section-creator_submit"
          onClick={handleSubmitButtonClick}
          className="btn-md btn-ai-primary"
          disabled={isFormInvalid}
          suffixIcon={<SparklesBoldSmall />}
        >
          {translate('add_section_ai_create_section_generate_cta')}
        </Button>
      </div>
      <div className={styles.termsWrapper}>
        <AiTermsAndConditions
          translationKey={'add_section_ai_panel_generate_legal'}
          panelType={AI_SECTION_CREATOR_PANEL_TYPE}
          sessionId={sessionId}
        />
      </div>
    </div>
  );
};

export default connectWithScope(
  () => AddPresetScope,
  PromptCreator,
  mapStateToProps,
  mapDispatchToProps,
);

import constants, { WORKSPACE_RIGHT_PANEL_NAMES } from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import { sections } from '#packages/util';
import {
  QuickEditApiKey,
  DesignerPanelsApiKey,
  EditorRestrictionsApiKey,
} from '#packages/apis';
import experiment from 'experiment';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { GFPPAction } from '@wix/editor-component-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const HEADER_SETTINGS_PANEL_NAME =
  'compPanels.panels.HeaderContainer.settingsPanel';
const isHeaderDiscoverabilityExperiment =
  gfppDataUtils.getIsHeaderDiscoverabilityExperiment();

const isHeaderScrollPanelExperiment =
  gfppDataUtils.getIsHeaderScrollPanelExperiment();
const shouldChangeScrollGFPP = gfppDataUtils.getShouldChangeHeaderScrollGFPP();

const toggleLayoutPanel = gfppDataUtils.getTogglePanelFn(ACTIONS.SETTINGS);

const getShouldShowScrollSettingsAsTextDesktop = () =>
  experiment.getValue('se_headerDiscoverability') === 'B';

const getMobileSettingsButtonlable = () => {
  if (shouldChangeScrollGFPP) {
    return 'gfpp_mainaction_headerscroll_behavior';
  }

  return 'mobile_gfpp_mainaction_headerscroll';
};

function getDesignerScanAction(): GFPPAction[] {
  return experiment.isOpen('se_addDesignerSection')
    ? [
        {
          label: 'Scan Header',
          shouldTranslate: false,
          onClick: (editorAPI: EditorAPI, compRefs: CompRef[]) => {
            const designerPanelsApi =
              editorAPI.host.getAPI(DesignerPanelsApiKey);
            designerPanelsApi.openDesignerScanPresetPanel(compRefs[0]);
          },
        },
      ]
    : [];
}

function getScrollBehaviorAction() {
  if (experiment.getValue('se_headerDiscoverability') !== 'B') return [];

  const action = {
    label: 'gfpp_mainaction_headerscroll_behavior',
    panelName: HEADER_SETTINGS_PANEL_NAME,
    isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.SETTINGS),
    onClick: async (
      editorAPI: EditorAPI,
      compRef?: CompRef[],
      origin?: string,
      optionalPropsOverrides: Record<string, unknown> = {},
    ) => {
      const panelPosition =
        shouldChangeScrollGFPP &&
        sections.getHeaderPanelPosition(editorAPI, origin);

      if (panelPosition) {
        optionalPropsOverrides.style ??= panelPosition;
      }

      toggleLayoutPanel(editorAPI, compRef, origin, optionalPropsOverrides);
    },
  };

  return [action];
}

function getQuickEditAction(editorAPI: EditorAPI) {
  const action = {
    label: `gfpp_mainaction_edit_section_label`,
    onClick: (editorAPI: EditorAPI, compRefs: CompRef[], origin: string) => {
      const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
      quickEditAPI.openPanel({
        rootControlCompRef: compRefs[0],
        origin,
      });
    },
    panelName: WORKSPACE_RIGHT_PANEL_NAMES.QUICK_EDIT,
  };
  const isQuickEditAvailable = editorAPI.host
    .getAPI(QuickEditApiKey)
    .isQuickEditAvailable();

  const isQuickEditNeeded =
    isQuickEditAvailable && !getShouldShowScrollSettingsAsTextDesktop();

  return isQuickEditNeeded ? [action] : [];
}

function getDesignAction(editorAPI: EditorAPI) {
  return {
    label: editorAPI.zoomMode.isStageZoomMode()
      ? 'RightClick_Menu_Header_Zoom_Out_Mode_Zoom_Label'
      : 'gfpp_mainaction_header',
    isApplied: true,
    isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.DESIGN),
    onClick: async (
      editorAPI: EditorAPI,
      compRef?: CompRef[],
      origin?: string,
      optionalPropsOverrides: Record<string, unknown> = {},
    ) => {
      const panelPosition =
        isHeaderDiscoverabilityExperiment &&
        sections.getHeaderPanelPosition(editorAPI, origin);
      if (editorAPI.zoomMode.isStageZoomMode()) {
        await editorAPI.zoomMode.exitZoomMode({
          biParams: { origin },
        });
      }
      const shouldShowOnlyAdvancedStylingDesignPanel =
        gfppDataUtils.siteSegmentBackgroundSupported(editorAPI, compRef?.[0]);

      optionalPropsOverrides.onlyAdvancedStyling =
        shouldShowOnlyAdvancedStylingDesignPanel;
      optionalPropsOverrides.startWithAdvanced =
        shouldShowOnlyAdvancedStylingDesignPanel;

      if (shouldShowOnlyAdvancedStylingDesignPanel) {
        const prevBiParams =
          typeof optionalPropsOverrides?.biParams === 'object'
            ? optionalPropsOverrides?.biParams
            : {};

        const biParams = {
          panel_name: constants.PANELS.ADVANCED_SITE_SEGMENT_DESIGN_PANEL,
          ...prevBiParams,
        };

        optionalPropsOverrides.biParams = biParams;
      }

      if (panelPosition) {
        optionalPropsOverrides.style ??= panelPosition;
      }

      gfppDataUtils.getTogglePanelFn(ACTIONS.DESIGN)(
        editorAPI,
        compRef,
        origin,
        optionalPropsOverrides,
      );
    },
  };
}

export default {
  untranslatable: true,
  mainActions: (editorAPI: EditorAPI) => {
    const designAction = getDesignAction(editorAPI);
    const quickEditAction = getQuickEditAction(editorAPI);
    const scrollBehaviorAction = getScrollBehaviorAction();

    const editorRestrictionsApi = editorAPI.host.getAPI(
      EditorRestrictionsApiKey,
    );
    const isHeaderGfppVisible = editorRestrictionsApi.allowed(
      'gfpp_header.visible',
    );
    const designerScanAction = getDesignerScanAction();
    const designActionIfAvailable = isHeaderGfppVisible ? [designAction] : [];

    return [
      ...designerScanAction,
      ...designActionIfAvailable,
      ...quickEditAction,
      ...scrollBehaviorAction,
    ];
  },
  enabledActions: () => {
    const settingsActionIfNeeded = getShouldShowScrollSettingsAsTextDesktop()
      ? []
      : [ACTIONS.SETTINGS];

    return sections.isSectionsEnabled()
      ? [...settingsActionIfNeeded, ACTIONS.HELP]
      : [...settingsActionIfNeeded, ACTIONS.DESIGN, ACTIONS.HELP];
  },
  mobileEnabledActions: () => {
    return [ACTIONS.HELP];
  },
  mobileMainActions: [
    {
      label: getMobileSettingsButtonlable(),
      isApplied: true,
      panelName: HEADER_SETTINGS_PANEL_NAME,
      automationId: 'gfpp-button-layout',
      onClick: toggleLayoutPanel,
    },
  ],
  presetActions: () => {
    const shouldChangeScrollTooltip =
      experiment.getValue('se_headerDiscoverability') === 'C' ||
      isHeaderScrollPanelExperiment;
    const scrollBehaviorTooltip = shouldChangeScrollTooltip
      ? 'gfpp_headerscroll_tooltip'
      : 'desktop_headerscroll_panel_title';

    return {
      help: {
        helpId: helpIds.GFPP.HEADER,
        mobileHelpId: helpIds.GFPP.HEADER_SCROLL_MOBILE,
      },
      settings: {
        tooltip: sections.isSectionsEnabled()
          ? scrollBehaviorTooltip
          : 'gfpp_tooltip_settings',
        panelName: HEADER_SETTINGS_PANEL_NAME,
      },
    };
  },
};

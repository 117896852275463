import React from 'react';
import * as util from '#packages/util';
import {
  WixBaseUiEnvironmentProvider,
  ActionItem,
  CustomScroll,
  Divider,
  Tooltip,
} from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  AddCircle,
  DataTable,
  Page,
  Data,
} from '@wix/wix-ui-icons-common/classic-editor';
import { mapStateToProps, mapDispatchToProps } from './welcomePage.mapper';
import type {
  WelcomePageOwnProps,
  WelcomePageDispatchProps,
  WelcomePageStateProps,
} from './welcomePage.mapper';
import WithLoader from '../preloader/withLoader';
import WelcomePageHeader from './header';
import styles from './welcomePage.scss';
import { actionInMainTabClicked } from '../../bi';
import LimitsNotificationBanner from '../limitsNotificationBanner/limitsNotificationBanner';

export interface WelcomePageProps
  extends WelcomePageOwnProps,
    WelcomePageStateProps,
    WelcomePageDispatchProps {}

const WelcomePage: React.FC<WelcomePageProps> = (props) => {
  const {
    canAddComponents,
    canEditSiteStructure,
    canQueryData,
    canSaveSchemas,
    hasUserCollections,
    hasDynamicPages,
    canOpenConnectionsList,
  } = props;
  const isFirstTimeExperience = !hasDynamicPages && !hasUserCollections;

  return (
    <WixBaseUiEnvironmentProvider madefor>
      <CustomScroll heightRelativeToParent="100%">
        <LimitsNotificationBanner isInMainTab={true} />
        <div data-hook="main-page" className={styles.welcomePage}>
          <WithLoader
            isLoading={props.isLoading}
            title="SiteApp_WixData_DataManager_Tab_Loading_Text"
          >
            <div className={styles.shadowedContainer}>
              <WelcomePageHeader
                canEditSiteStructure={canEditSiteStructure}
                canSaveSchemas={canSaveSchemas}
                isFirstTimeExperience={isFirstTimeExperience}
                openAddPresetPanel={props.openAddPresetPanel}
                openCreateCollectionPanel={props.openCreateCollectionPanel}
                openVideoPanel={props.openVideoPanel}
                sendBi={props.sendBi}
                openHelpCenter={props.openHelpCenter}
                onSettingsIconClick={props.openContentManagerAdvancedSettings}
              />
            </div>
            {(hasDynamicPages || hasUserCollections) && (
              <div className={styles.shadowedContainer}>
                {canOpenConnectionsList && (
                  <>
                    <ActionItem
                      title={
                        'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_MANAGE_CONNECTIONS'
                      }
                      size="small"
                      onClick={() => {
                        props.openPageConnections();
                      }}
                      actionIcon={<Symbol name="arrowRightThin" />}
                      illustration={
                        <Data data-hook="manage-connections-icon" />
                      }
                      disabledIllustration={
                        <Data
                          data-hook="manage-connections-icon"
                          fillOpacity={0.3}
                        />
                      }
                      dataHook="manage-pages-page-connections"
                      disabled={!canEditSiteStructure || !canSaveSchemas}
                    />
                    <Divider />
                  </>
                )}
                {hasDynamicPages && (
                  <Tooltip
                    content="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_MANAGE_PAGES_NO_PERMISSIONS"
                    disabled={canEditSiteStructure && canSaveSchemas}
                  >
                    <ActionItem
                      title={'CONTENT_MANAGER_6_BUTTON_MAIN_MENU_MANAGE_PAGES'}
                      size="small"
                      onClick={() => {
                        props.navigateToDynamicPagesPanel();
                        props.sendBi(actionInMainTabClicked, {
                          actionName: 'manage_dynamic_pages',
                          isEmptyState: isFirstTimeExperience,
                        });
                      }}
                      actionIcon={<Symbol name="arrowRightThin" />}
                      illustration={<Page data-hook="manage-pages-icon" />}
                      disabledIllustration={
                        <Page data-hook="manage-pages-icon" fillOpacity={0.3} />
                      }
                      dataHook="manage-pages-option"
                      disabled={!canEditSiteStructure || !canSaveSchemas}
                    />
                  </Tooltip>
                )}

                {hasUserCollections && (
                  <>
                    {hasDynamicPages && <Divider />}
                    <Tooltip
                      content="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_MANAGE_CONTENT_NO_PERMISSIONS"
                      disabled={canQueryData}
                    >
                      <ActionItem
                        title="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_MANAGE_CONTENT"
                        size="small"
                        onClick={() => {
                          props.navigateToManageContent();
                          props.sendBi(actionInMainTabClicked, {
                            actionName: 'manage_content',
                            isEmptyState: isFirstTimeExperience,
                          });
                        }}
                        actionIcon={<Symbol name="arrowRightThin" />}
                        illustration={
                          <DataTable data-hook="manage-content-icon" />
                        }
                        disabledIllustration={
                          <DataTable
                            data-hook="manage-content-icon"
                            fillOpacity={0.3}
                          />
                        }
                        dataHook="manage-content-option"
                        disabled={!canQueryData}
                      />
                    </Tooltip>
                    <Divider />
                    <Tooltip
                      content="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_ADD_CONTENT_ELEMENTS_NO_PERMISSIONS"
                      disabled={canAddComponents}
                    >
                      <ActionItem
                        title="CONTENT_MANAGER_6_BUTTON_MAIN_MENU_ADD_CONTENT_ELEMENTS"
                        size="small"
                        onClick={() => {
                          props.navigateToDataPanel();
                          props.sendBi(actionInMainTabClicked, {
                            actionName: 'add_content_element',
                            isEmptyState: isFirstTimeExperience,
                          });
                        }}
                        actionIcon={<Symbol name="arrowRightThin" />}
                        illustration={
                          <AddCircle data-hook="add-content-elements-icon" />
                        }
                        disabledIllustration={
                          <AddCircle
                            data-hook="add-content-elements-icon"
                            fillOpacity={0.3}
                          />
                        }
                        dataHook="add-content-elements-option"
                        disabled={!canAddComponents}
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            )}
          </WithLoader>
        </div>
      </CustomScroll>
    </WixBaseUiEnvironmentProvider>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(WelcomePage);

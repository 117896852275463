import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import type { SendBiFunction } from 'types/bi';

export interface OwnProps {
  isHighlighted: boolean;
}

export interface StateProps {
  superAppsDesktopCount: number;
  leftBarMenuItems: AnyFixMe[];
  leftBarNotifications: Record<string, number>;
  selectedPanelName?: string;
  isEnabled: boolean;
  isZoomedByUser?: boolean;
  isMobileHiddenItemsTooltipEnabled: boolean;
  didDismissMobileHiddenItemsTooltip: boolean;
}

export interface DispatchProps {
  sendBIButtonsCount: () => void;
  sendBIButtonClick: (category: string) => void;
  sendBIButtonHover: (element_name: string) => void;
  sendBI: SendBiFunction;
  openPanel: (panelName: string, panelProps: object) => void;
  closePanel: () => void;
  dismissMobileHiddenElementsTooltip: () => void;
  setIsMobileHiddenItemsTooltipEnabled: (isEnabled: boolean) => void;
  deselectComponents: () => void;
}

const { selectOpenLeftPanels } = stateManagement.panels.selectors;

const {
  getDesktopSuperAppsCount,
  getOverrideItems,
  getMenuItems,
  getNotifications,
  getDidDismissMobileHiddenItemsTooltip,
  getIsMobileHiddenItemsTooltipEnabled,
} = stateManagement.leftBar.selectors;

export const mapStateToProps: MapStateToProps<StateProps, OwnProps> = ({
  state,
  editorAPI,
}) => {
  const { editorIsInit } = state;
  const isMobileEditor = editorAPI.isMobileEditor();
  const isPopUpMode = editorAPI.isPopUpMode();
  const openedPanel = selectOpenLeftPanels(state)?.[0];
  const selectedPanelName = openedPanel?.name;
  const didDismissMobileHiddenItemsTooltip =
    getDidDismissMobileHiddenItemsTooltip(state);
  const isMobileHiddenItemsTooltipEnabled =
    getIsMobileHiddenItemsTooltipEnabled(state);

  const overriddenItems = getOverrideItems(state, isMobileEditor);
  const superAppsDesktopCount = getDesktopSuperAppsCount(state);

  const allMenuItems = getMenuItems(state, isPopUpMode);
  const leftBarMenuItems = util.overrideUtils.applyOverrides(
    allMenuItems,
    overriddenItems,
    isMobileEditor ? 'panelName' : 'key',
  );

  const leftBarNotifications = getNotifications(state);

  return {
    superAppsDesktopCount,
    leftBarMenuItems,
    leftBarNotifications,
    selectedPanelName,
    isEnabled: editorIsInit,
    didDismissMobileHiddenItemsTooltip,
    isMobileHiddenItemsTooltipEnabled,
  };
};

export const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = {
  sendBIButtonsCount:
    (): ThunkAction =>
    (dispatch, getState, { editorAPI }) =>
      dispatch(
        stateManagement.leftBar.actions.sendBIButtonsCount(
          editorAPI.isPopUpMode(),
        ),
      ),
  sendBIButtonClick: stateManagement.leftBar.actions.sendBIButtonClick,
  sendBIButtonHover: stateManagement.leftBar.actions.sendBIButtonHover,
  sendBI: stateManagement.bi.actions.event,
  openPanel: stateManagement.panels.actions.openLeftPanel,
  dismissMobileHiddenElementsTooltip: () =>
    stateManagement.leftBar.actions.updateDidDismissMobileHiddenItemsTooltip(
      true,
    ),
  setIsMobileHiddenItemsTooltipEnabled: (isEnabled: boolean) =>
    stateManagement.leftBar.actions.updateIsMobileHiddenItemsTooltipEnabled(
      isEnabled,
    ),
  closePanel: stateManagement.panels.actions.closeOpenedPanels,
  deselectComponents:
    (): ThunkAction =>
    (_d, _s, { editorAPI }) =>
      editorAPI.selection.deselectComponents(),
};

import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
} from 'types/redux';
import type {
  CheckReadinessPanelOwnProps,
  CheckReadinessPanelStateProps,
  CheckReadinessPanelDispatchProps,
} from './checkReadinessPanelTypes';
import { PANEL_NAMES } from '../../consts';
import { SiteReadinessApiKey } from '#packages/apis';

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: { editorAPI: EditorAPI },
): EditorAPI => editorAPI;

const mapStateToProps: MapStateToProps<
  CheckReadinessPanelStateProps,
  CheckReadinessPanelOwnProps
> = () => ({});

const mapDispatchToProps: MapDispatchToProps<
  CheckReadinessPanelDispatchProps,
  CheckReadinessPanelOwnProps
> = (dispatch, _ownProps: CheckReadinessPanelOwnProps) => {
  const editorAPI = dispatch(getEditorAPI);
  const siteReadinessApi = editorAPI.host.getAPI(SiteReadinessApiKey);
  return {
    closePanel: () => {
      editorAPI.panelManager.closePanelByName(PANEL_NAMES.CHECK_READINESS, 'X');
    },
    openSiteReadinessPanel: () => {
      siteReadinessApi.reportClickInsidePanel('click_see_your_score');
      editorAPI.panelManager.openPanel(PANEL_NAMES.SITE_READINESS, {
        origin: siteReadinessApi.getOrigin(),
      });
    },
  };
};

export { mapStateToProps, mapDispatchToProps };

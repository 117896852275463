import _ from 'lodash';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as toolBarLogic from './toolBarLogic';
import type { EditorAPI } from '#packages/editorAPI';
import * as coreBi from '#packages/coreBi';
import type { StateMapperArgs } from 'types/redux';
import { showUserActionNotification } from './commonMappers';
import { setComponentLayoutChange } from './toolBarUpdateLayout';
import {
  selectedCompsSel,
  compRestrictionsSel,
  isCompControlledByParentSel,
} from './toolBarSelectors';
import { relativeToStructureLayoutSel } from '../selectors/layoutSelectors';
import {
  createSelector,
  createStructuredSelector,
} from '../../selectors/selector';
import { editorAPISel, editorStateSel } from '../selectors/rootSelectors';
import { editorWixRecorder, fixedStage } from '#packages/util';
import type { CompRef } from 'types/documentServices';

const { isInInteractionMode, isShownOnlyOnVariant } =
  stateManagement.interactions.selectors;
const { translateComponentTo } = stateManagement.interactions.actions;

const { pinMode, selection } = stateManagement;
const { getPreviewPosition, getStageLayout, getEditorContentLayout } =
  stateManagement.domMeasurements.selectors;

const getComponentLayout = (
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
) => {
  let layout = null;
  if (selectedComponents?.length) {
    layout = editorAPI.components.layout.containsSiblingsOnly(
      selectedComponents,
    )
      ? editorAPI.components.layout.get(selectedComponents)
      : editorAPI.components.layout.get(selectedComponents[0]);
  }
  return layout;
};

const mapNotMouseOpsStateToProps = createSelector(
  [editorAPISel, editorStateSel, selectedCompsSel],
  (editorAPI, editorState, selectedComponents) => {
    const isPinMode = pinMode.selectors.isOpen(editorState);
    const inInteractionMode = isInInteractionMode(editorState);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    const compRef = _.isArray(selectedComponents)
      ? selectedComponents[0]
      : selectedComponents;
    const isInteractionComponent =
      inInteractionMode && !isShownOnlyOnVariant(editorAPI, compRef);

    const flip =
      editorAPI.components.properties.get(selectedComponents)?.flip ?? '';

    return {
      hidden: isPinMode,
      isInteractionComponent,
      toolsHidden: editorAPI.toolsControl.areToolsHidden(),
      halfOpacityTools: editorAPI.toolsControl.isHalfOpacityTools(),
      components: selectedComponents,
      previewTopPosition: getPreviewPosition(editorState).top,
      shouldDisableShowOnAllPages: Boolean(
        inInteractionMode ||
          (selectedComponents &&
            editorAPI.components.shouldDisableShowOnAllPages(
              selectedComponents,
            )),
      ),
      isPage: Boolean(
        selectedComponents && editorAPI.utils.isPage(selectedComponents),
      ),
      isComponentShowOnAllPages: Boolean(
        selectedComponents &&
          editorAPI.components.isShowOnAllPages(selectedComponents),
      ),
      isPasteEnabled: inInteractionMode
        ? !isInteractionComponent
        : toolBarLogic.isPasteEnabled(editorAPI),
      componentType: editorAPI.selection.getSelectedComponentType(),
      isCurrentPageWidgetPage: editorAPI.dsRead.pages.isWidgetPage(
        editorAPI.pages.getFocusedPageId(),
      ),
      compDeleteTooltipText:
        editorAPI.tooltipTextService.getComponentTooltipText(
          selectedComponents,
          'DELETE',
        ),
      compLayout: getComponentLayout(editorAPI, selectedComponents),
      compBIParams: editorAPI.bi.getComponentsBIParams(selectedComponents),
      copyPasteAPI: _.pick(editorAPI.copyPaste, [
        'duplicate',
        'paste',
        'pastePage',
        'copy',
        'hasComponentToPaste',
        'hasPageToPaste',
      ]),
      selectedMenuItem: editorAPI.mainMenu.panelContext.getSelectedMenuItem(),
      canDistributeAllDirections:
        !isInteractionComponent &&
        editorAPI.components.multiComponents.canDistributeAllDirections(
          selectedComponents,
        ),
      canMatchAllSizes:
        !isInteractionComponent &&
        editorAPI.components.multiComponents.canMatchAllSizes(
          selectedComponents,
        ),
      isMobileEditor: editorAPI.isMobileEditor(),
      canAlignAllDirections:
        !isInteractionComponent &&
        editorAPI.components.alignment.canAlignAllDirections(
          selectedComponents,
        ),
      arrangementAPI: _.pick(editorAPI.components.arrangement, [
        'moveToFront',
        'moveForward',
        'moveToBack',
        'moveBackward',
      ]),
      isRightPanelOpen:
        stateManagement.panels.selectors.isDockedSectionOpen(editorState),
      isDevModeOpened:
        stateManagement.userPreferences.selectors.getSiteUserPreferences(
          constants.USER_PREFS.DEVELOPER_MODE.FIRST_TIME,
        )(editorState),
      stageLayout: getStageLayout(editorState),
      topBarCloseToolsUsed:
        stateManagement.userPreferences.selectors.getSiteUserPreferences(
          'topBarCloseToolsUsed',
        )(editorState),
      isFlippedHoriz: ['y', 'horizontal', 'both'].includes(flip),
      isFlippedVert: ['x', 'vertical', 'both'].includes(flip),
      editorContentLayout: getEditorContentLayout(editorState),
      isFixedStageEnabled: fixedStage.isFixedStageEnabled(),
    };
  },
);

const mapperRefactoredToSelectors = createStructuredSelector({
  componentsRestrictions: compRestrictionsSel,
  isCompControlledByParent: isCompControlledByParentSel,
});

const mapStateToProps = (mapperArgs: StateMapperArgs) => {
  return {
    ...mapNotMouseOpsStateToProps(mapperArgs), //TODO move all to selectors
    ...mapperRefactoredToSelectors(mapperArgs),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    showUserActionNotification: (data: AnyFixMe) => {
      dispatch(showUserActionNotification(data));
    },
    onHelp(helpId: AnyFixMe, biParams: AnyFixMe) {
      editorAPI.panelManager.openHelpCenter(helpId, null, biParams);
    },
    openLayersPanel() {
      const origin = 'toolbar';
      editorWixRecorder.addLabel('panels.toolPanels.layersPanel panel opened');
      editorAPI.bi.event(coreBi.events.panels.PANEL_OPENED, {
        panel_name: 'panels.toolPanels.layersPanel',
        origin,
      });
      dispatch(stateManagement.layersPanel.actions.showLayersPanel(origin));
    },
    setComponentLayoutChange: (key: AnyFixMe, value: AnyFixMe) =>
      dispatch(setComponentLayoutChange(key, value)),
    toggleToolbar: editorAPI.toolbar.toggleToolbar,
    addHistory: editorAPI.history.add,
    toggleShowOnAllPages: () => {
      const editorState = editorAPI.store.getState();
      const selectedComponents =
        selection.selectors.getSelectedCompsRefs(editorState);
      editorAPI.components.toggleShowOnAllPages(selectedComponents);
    },
    toggleFixedPosition: () => {
      const editorState = editorAPI.store.getState();
      const selectedComponents =
        selection.selectors.getSelectedCompsRefs(editorState);
      editorAPI.components.layout.toggleFixedPosition(selectedComponents);
    },
    reportBI: editorAPI.bi.event,
    isCrossSitePaste: editorAPI.clipboard.isCrossSite,
    distribute: editorAPI.components.multiComponents.distribute,
    matchSize: editorAPI.components.multiComponents.matchSize,
    alignComp: editorAPI.components.alignment.align,
    removeComp: editorAPI.components.remove,
    toggleFlipHoriz: () => {
      const editorState = editorAPI.store.getState();
      const selectedComponents =
        selection.selectors.getSelectedCompsRefs(editorState);
      editorAPI.components.flip(selectedComponents, 'horizontal');
    },
    toggleFlipVert: () => {
      const editorState = editorAPI.store.getState();
      const selectedComponents =
        selection.selectors.getSelectedCompsRefs(editorState);
      editorAPI.components.flip(selectedComponents, 'vertical');
    },
    translateComponentTo: (axis: AnyFixMe, amountToMove: AnyFixMe) => {
      const editorState = editorAPI.store.getState();
      const compRef = selection.selectors.getSelectedCompsRefs(editorState)[0];
      translateComponentTo(editorAPI, compRef, axis, amountToMove);
    },
    reportComponentResized: () => {
      editorAPI.bi.event(coreBi.events.editBox.RESIZE, {
        origin: 'toolbar',
        appId:
          editorAPI.components.data.get(
            editorAPI.selection.getSelectedComponents()[0],
          )?.appDefinitionId || null,
      });
    },
  };
};

export { mapStateToProps, mapDispatchToProps, relativeToStructureLayoutSel };

import * as coreBi from '#packages/coreBi';
import * as tpa from '#packages/tpa';
import * as popups from '#packages/popups';
import * as customSignupUtils from '../../../utils/customSignup';
import {
  AUTH_METHODS,
  authMapper,
  findCompRef,
  type ISettingsData,
} from './authSettingsDesktopMapper';
import type { EditorAPI } from '#packages/editorAPI';
import type { DSRead } from 'types/documentServices';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

const { CUSTOM_LOGIN_APP_DEF_ID, CUSTOM_REGISTRATION_APP_DEF_ID } =
  customSignupUtils;

const FORM_CONTAINER_TYPE = 'platform.components.AppWidget';

const installFormBuilderIfNeeded = (editorAPI: AnyFixMe) => {
  return new Promise((resolve, reject) => {
    const type = tpa.constants.APP.TYPE.PLATFORM_ONLY;
    const biInitiator = tpa.constants.BI.initiator.EDITOR;
    const options = { originType: tpa.constants.BI.type.ADD_APP_ADD_PANEL };

    const callback = (response: AnyFixMe) =>
      response?.success ? resolve(response) : reject(response);
    const platformOrigin = {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.PAGES_PANEL,
      },
    };

    tpa.services.tpaAddAppService.addApp(
      editorAPI,
      CUSTOM_LOGIN_APP_DEF_ID,
      null,
      null,
      type,
      biInitiator,
      false,
      {},
      options,
      callback,
      platformOrigin,
    );
  });
};

const createSignInFormInLightbox = ({ editorAPI }: AnyFixMe) => {
  installFormBuilderIfNeeded(editorAPI).then(() => {
    editorAPI.dsActions.waitForChangesApplied(async () => {
      editorAPI.history.add('add custom sign in page', {
        isAddingComponent: true,
      });
      const allPopups = editorAPI.pages.popupPages.getDataList();
      const customLoginPopup = allPopups.find(
        (popup: any) => popup.managingAppDefId === CUSTOM_LOGIN_APP_DEF_ID,
      );

      if (!customLoginPopup) {
        throw new Error('cant find custom login popup ID');
      }

      const popupId = customLoginPopup.id;
      // In case no component is being selected the `removeOpenBehaviorFromAllPages` explodes.
      if (!editorAPI.selection.getSelectedComponents()?.[0]) {
        editorAPI.selection.selectComponentByCompRef(
          findCompRef(editorAPI, popupId, FORM_CONTAINER_TYPE),
        );
      }
      editorAPI.siteMembers.setCustomSignInPageId(popupId);
      popups.actions.openBehaviors.removeOpenBehaviorFromAllPages(editorAPI);
    });
  });
};

export default (
  {
    editorAPI,
    dsRead,
  }: {
    editorAPI: EditorAPI;
    dsRead: DSRead;
  },
  {
    navigateToVeloPanel,
  }: {
    navigateToVeloPanel: {
      navigate: (settingsData: Partial<ISettingsData>) => void;
      shouldNavigateToVeloPanel: () => boolean;
    };
  },
) => {
  const getCustomSignInPageId = () =>
    editorAPI.siteMembers.getCustomSignInPageId();
  const getCustomSignupPageId = () =>
    editorAPI.siteMembers.getCustomSignupPageId();

  const authSharedAPI = authMapper({
    editorAPI,
    dsRead,
    authMethod: AUTH_METHODS.SIGN_IN,
    getThisMethodCustomAuthPageId: getCustomSignInPageId,
    getSecondMethodCustomAuthPageId: getCustomSignupPageId,
    APP_DEF_IDS: {
      THIS_APP_DEF_ID: CUSTOM_LOGIN_APP_DEF_ID,
      SECOND_APP_DEF_ID: CUSTOM_REGISTRATION_APP_DEF_ID,
    },
    bi_quick_actions_menu_select_source: 'custom_sign_in',
    bi_panel_name: 'sign_in_settings',
    translationKeys: {
      customTitle: 'NewPages_Member_SignIn_Custom_Title',
      defaultTitle: 'NewPages_Member_SignIn_Default_Title',
      developerTitle: 'NewPages_Member_SignIn_Developer_Title',
    },
    theOtherAuthMethod: {
      getTheOtherCustomAuthPageId: getCustomSignupPageId,
    },
    navigateToVeloPanel,
  });

  return {
    authMethod: AUTH_METHODS.SIGN_IN,
    ...authSharedAPI,
    removeCustomSignup: () => {
      editorAPI.siteMembers.setCustomSignInPageId(null);
      editorAPI.pages.navigateTo(editorAPI.dsRead.pages.getPrimaryPageId());
    },
    assignCustomSignup: (pageId: AnyFixMe) =>
      editorAPI.siteMembers.setCustomSignInPageId(pageId),
    createCustomSignup: () => {
      const msid = editorAPI.dsRead.generalInfo.getMetaSiteId();
      editorAPI.bi.event(coreBi.events.signup.create_custom_login, { msid });
      editorAPI.panelManager.closeAllPanels();
      return createSignInFormInLightbox({ editorAPI });
    },
    translationKeys: {
      defaultTopBanner: 'NewPages_Member_SignIn_Default_Top_Banner',
      dropdownInfoText: 'NewPages_Panel_SignIn_Top_Dropdown_Info_Text',
      dropdownTitle: 'NewPages_Panel_SignIn_Top_Dropdown_Title',
      topInfoKey: 'NewPages_Member_SignIn_Developer_Top_Info',
      bannerTitle: 'NewPages_Member_SignIn_Custom_SignIn_Banner_Title',
      bannerText: 'NewPages_Member_SignIn_Custom_SignIn_Banner_Text',
    },
    helpId: '24a93274-080f-4caa-b5c8-38df01b1b6d6',
  };
};

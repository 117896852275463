import { createReduxStore } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';
import type { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';
import type {
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_NAMES,
} from '#packages/constants';
import type React from 'react';

export interface DrillInPanelData {
  panelData: ContributedPanelData;
  compRef?: CompRef;
}

export interface DrillInPanelConfig {
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES;
  uniqueId?: string;
  panelTitle?: string;
  backTooltip?: string;
  onClose: () => void;
  isOpen?: boolean;
  drillInUniqueIds?: Array<string>;
  zIndex?: number;
}

export interface DrillInContribution {
  panelConfig: DrillInPanelConfig;
  panelComponent: React.ComponentType<{}>;
}

export interface ContributedPanelData {
  panelName: WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES | WORKSPACE_RIGHT_PANEL_NAMES;
  panelId: string;
}

export interface QuickEditInnerPanelBiData {
  openTime: number;
  panel_name: string;
  component_id: string;
  component_type: string;
}

export interface QuickEditEngineState {
  currentEditedCompId: string;
  componentPanelType: QuickEditControlActions;
  componentPanelContent: React.FC;
  drillInPanels: Array<DrillInPanelData>;
  drillInPanelsBiData: Array<QuickEditInnerPanelBiData>;
  contributedDrillInPanels: {
    [uniqueId: string]: DrillInPanelConfig;
  };
  activeDrillInUniqueIds: Array<string>;
  mainPanelDrillInUniqueIds: Array<string>;
}

const getInitialState = (): QuickEditEngineState => ({
  currentEditedCompId: '',
  drillInPanels: [],
  drillInPanelsBiData: [],
  componentPanelType: null,
  componentPanelContent: null,
  contributedDrillInPanels: {},
  activeDrillInUniqueIds: [],
  mainPanelDrillInUniqueIds: [],
});

export const QuickEditEngineStore = createReduxStore({
  getInitialState,
  selectors: {
    getCurrentEditedCompId: (state: QuickEditEngineState) =>
      state.currentEditedCompId,
    getComponentPanelType: (state: QuickEditEngineState) =>
      state.componentPanelType,
    getComponentPanelContent: (state: QuickEditEngineState) =>
      state.componentPanelContent,
    getContributedDrillInPanels: (state: QuickEditEngineState) =>
      state.contributedDrillInPanels,
    getActiveDrillInUniqueIds: (state: QuickEditEngineState) =>
      state.activeDrillInUniqueIds,
    getActiveDrillInOnClose: (state: QuickEditEngineState) => {
      const activeDrillInPanelKey =
        state.activeDrillInUniqueIds[state.activeDrillInUniqueIds.length - 1];
      return state.contributedDrillInPanels[activeDrillInPanelKey].onClose;
    },
    getActiveDrillInDrillInUniqueIds: (state: QuickEditEngineState) => {
      const activeDrillInPanelKey =
        state.activeDrillInUniqueIds[state.activeDrillInUniqueIds.length - 1];
      return state.contributedDrillInPanels[activeDrillInPanelKey]
        .drillInUniqueIds;
    },
    getDrillInPanels: (state: QuickEditEngineState) => state.drillInPanels,
    getMainPanelDrillInUniqueIds: (state: QuickEditEngineState) =>
      state.mainPanelDrillInUniqueIds,
    getLastOpenedDrillInPanelBiData: (state: QuickEditEngineState) => {
      const drillInPanelsBiData = state.drillInPanelsBiData;
      return drillInPanelsBiData[drillInPanelsBiData.length - 1];
    },
  },
  actions: {
    setCurrentEditedCompId: (
      state: QuickEditEngineState,
      currentEditedCompId: string,
    ) => {
      return {
        ...state,
        currentEditedCompId,
      };
    },
    setComponentPanelType: (
      state: QuickEditEngineState,
      componentPanelType: QuickEditControlActions,
    ) => {
      return {
        ...state,
        componentPanelType,
      };
    },
    setComponentPanelContent: (
      state: QuickEditEngineState,
      componentPanelContent: React.FC,
    ) => {
      return {
        ...state,
        componentPanelContent,
      };
    },
    setMainPanelDrillInUniqueIds: (
      state: QuickEditEngineState,
      drillInUniqueIds: Array<string>,
    ) => {
      return {
        ...state,
        mainPanelDrillInUniqueIds: drillInUniqueIds,
      };
    },
    contributeDrillInPanel: (state, newPanel) => {
      return {
        ...state,
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [newPanel.uniqueId]: newPanel,
        },
      };
    },
    setDrillInPanels: (
      state: QuickEditEngineState,
      drillInPanels: Array<DrillInPanelData>,
    ) => {
      return {
        ...state,
        drillInPanels,
      };
    },
    addDrillInPanels: (
      state: QuickEditEngineState,
      drillInPanels: Array<DrillInPanelData>,
    ) => {
      return {
        ...state,
        drillInPanels: [...state.drillInPanels, ...drillInPanels],
      };
    },
    removeDrillInPanel: (state: QuickEditEngineState) => {
      return {
        ...state,
        drillInPanels: state.drillInPanels.slice(0, -1),
      };
    },
    removeAllDrillInPanels: (state: QuickEditEngineState) => {
      return {
        ...state,
        contributedDrillInPanels: {},
        activeDrillInUniqueIds: [],
      };
    },
    openDrillIn: (
      state,
      uniqueId: string,
      zIndex: number,
      updatedConfig?: Partial<DrillInPanelConfig>,
      panelProps?: any,
    ) => {
      const drillInConfig = state.contributedDrillInPanels[uniqueId];
      return {
        ...state,
        activeDrillInUniqueIds: [...state.activeDrillInUniqueIds, uniqueId],
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [uniqueId]: {
            ...drillInConfig,
            isOpen: true,
            zIndex,
            drillInUniqueIds: [],
            ...updatedConfig,
            panelProps,
          },
        },
      };
    },
    updateDrillInConfig: (
      state: QuickEditEngineState,
      uniqueId: string,
      newConfig: Partial<DrillInPanelConfig>,
    ) => {
      const originalConfig = state.contributedDrillInPanels[uniqueId];
      return {
        ...state,
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [uniqueId]: { ...originalConfig, ...newConfig },
        },
      };
    },
    updateDrillInPanel: (
      state: QuickEditEngineState,
      uniqueId: string,
      compRef?: CompRef,
    ) => {
      const drillInPanels = state.drillInPanels;
      const drillInIndex = drillInPanels.findIndex(
        (panel) => panel.panelData.panelId === uniqueId,
      );
      drillInPanels[drillInIndex].compRef = compRef;
      return {
        ...state,
        drillInPanels,
      };
    },
    closeDrillIn: (state: QuickEditEngineState) => {
      const panelIndex = state.activeDrillInUniqueIds.length - 1;
      const uniqueId = state.activeDrillInUniqueIds[panelIndex];
      const drillInConfig = state.contributedDrillInPanels[uniqueId];
      const activeDrillInUniqueIds = state.activeDrillInUniqueIds;
      activeDrillInUniqueIds.pop();
      return {
        ...state,
        activeDrillInUniqueIds,
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [uniqueId]: {
            ...drillInConfig,
            isOpen: false,
            panelTitle: '',
            zIndex: null,
          },
        },
      };
    },
    setDrillInPanelBiData: (
      state: QuickEditEngineState,
      biData: QuickEditInnerPanelBiData,
    ) => {
      return {
        ...state,
        drillInPanelsBiData: [...state.drillInPanelsBiData, biData],
      };
    },
    removeLastDrillInBiData: (state: QuickEditEngineState) => {
      const drillInPanelsBiData = state.drillInPanelsBiData;
      drillInPanelsBiData.pop();
      return {
        ...state,
        drillInPanelsBiData,
      };
    },
  },
});

// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import editorActionsService from '../../../common/editorActionsService';
import * as sitePrefsService from '../../../common/sitePrefsService';
import dataProvider from '../../../common/dataProvider';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { CustomScroll } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

class ManageBookingsPanelView extends React.Component {
  static displayName = 'manageBookingsPanel';

  renderEmptyPanelState = () => {
    const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();
    return isNewWorkspace ? (
      <div>
        <symbols.symbol name="bookingsPanaIllustration_NewWorkspace" />
      </div>
    ) : (
      <div className="manage-bookings" />
    );
  };

  render() {
    return (
      <div className="wix-bookings-manage-panel">
        <CustomScroll heightRelativeToParent="100%">
          {this.renderEmptyPanelState()}
          <p className="manage-panel-description">
            {translate('SiteApp_WixBookings_Manage_Instructor')}
          </p>
          <div className="buttons-container">
            <baseUI.button
              label="SiteApp_WixBookings_Manage_Services"
              onClick={this.props.openDashboardManager}
              className="btn-confirm-primary manage-btn"
            />
          </div>
        </CustomScroll>
      </div>
    );
  }
}

ManageBookingsPanelView.displayName = 'BookingsManagePanel';

ManageBookingsPanelView.propTypes = {
  openDashboardManager: PropTypes.func.isRequired,
  getBookingsSetupStep: PropTypes.func.isRequired,
};

const ManageBookingsPanelMapper = ({ editorAPI }) => {
  const actionsService = editorActionsService({ editorAPI });

  function openDashboardManager() {
    return actionsService.openDashboard(
      dataProvider.BOOKINGS_BO_PAGES.offeringList,
    );
  }

  function getBookingsSetupStep() {
    const instance = dataProvider.getInstance(editorAPI);
    return sitePrefsService
      .getBookingsStatus(instance)
      .then((bookingStatus) =>
        sitePrefsService.getSetupStepFromStatus(bookingStatus),
      );
  }

  return {
    openDashboardManager,
    getBookingsSetupStep,
  };
};

export default connect(
  EDITOR_API,
  ManageBookingsPanelMapper,
)(renderWhenMutated(ManageBookingsPanelView));

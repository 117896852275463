import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import type { StateMapperArgs } from 'types/redux';
import { hoc } from '#packages/util';

function getElementsByOrigin(comps: AnyFixMe, origin: AnyFixMe) {
  //eslint-disable-line react/display-name
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(comps, function (obj) {
    return React.createElement(
      obj.reactClass,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign({}, obj.props, { origin }),
    );
  });
}

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  comps?: AnyFixMe;
}

class Portal extends React.Component<Props> {
  static displayName = 'Portal (AKA Comps Day-Care)';

  static propTypes = {
    comps: PropTypes.object,
  };

  getChildren = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      this.props.comps,
      function (res, comps, origin) {
        return res.concat(getElementsByOrigin(comps, origin));
      },
      [],
    );
  };

  render() {
    if (_.isEmpty(this.props.comps)) {
      return null;
    }
    return <div id="portal">{this.getChildren()}</div>;
  }
}

const mapStateToProps = ({ state }: StateMapperArgs) => {
  return {
    comps: state.portalComps,
  };
};

export default hoc.connect(hoc.STORES.MOUSE_OPS, mapStateToProps)(Portal);

import { interactions } from '#packages/stateManagement';

import ComponentPasteLogic from './ComponentPasteLogic';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { Point } from './types';
import type { CompStructure } from '@wix/document-services-types';
export interface OffsetObject {
  xOffset: number;
  yOffset: number;
}

const { isInInteractionMode, getVariantPointers } = interactions.selectors;

export class AddPanelPasteLogic extends ComponentPasteLogic {
  private lastCompTypeAdded: string = '';

  constructor() {
    super({ centerOnFirstPaste: true });
  }

  getPastePosition(
    editorAPI: EditorAPI,
    componentLayout: AnyFixMe,
    containerId: string,
    newCompCategory?: AnyFixMe,
    components?: CompStructure[],
  ): Point {
    if (newCompCategory !== this.lastCompTypeAdded) {
      this.resetPastePosition();
    }
    this.lastCompTypeAdded = newCompCategory;
    return super.getPastePosition(
      editorAPI,
      componentLayout,
      containerId,
      true,
      components,
    );
  }

  getInteractionsOffsetPastePosition(
    editorAPI: EditorAPI,
    parentComp: CompRef,
  ): OffsetObject | null {
    const state = editorAPI.store.getState();

    if (isInInteractionMode(state)) {
      const parentCompVariantPointer =
        editorAPI.dsRead.components.variants.getPointer(
          parentComp,
          getVariantPointers(state),
        );
      const parentTransformations =
        editorAPI.dsRead.components.transformations.get(
          parentCompVariantPointer,
        );
      if (
        parentTransformations?.translate?.x?.value ||
        parentTransformations?.translate?.y?.value
      ) {
        return {
          xOffset: parentTransformations.translate.x.value * -1,
          yOffset: parentTransformations.translate.y.value * -1,
        };
      }
    }

    return null;
  }
}

export default AddPanelPasteLogic;

import * as util from '#packages/util';

import OldSaveTextStyleConfirmation from './oldSaveTextStyleConfirmation';
import NewWorkspaceSaveTextStyleConfirmation from './newWorkspaceSaveTextStyleConfirmation';

const SaveTextStyleConfirmation = util.workspace.isNewWorkspaceEnabled()
  ? NewWorkspaceSaveTextStyleConfirmation
  : OldSaveTextStyleConfirmation;

export default SaveTextStyleConfirmation;

import constants from '#packages/constants';
import { fedopsLogger } from '#packages/util';
import { searchModule } from '../searchModule';
import { createPanelIntents, IntentType } from './createPanelIntents';
import { Integrations } from '../constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { PanelType } from '#packages/constants';

const { ROOT_COMPS } = constants;

interface FedopsInteraction {
  start: () => void;
  end: () => void;
}

interface PagesPanelIntent {
  type: IntentType;
  props: {
    selectedTabId: string;
    openPanelInteraction?: FedopsInteraction;
    closePanelInteraction?: FedopsInteraction;
    frameType?: PanelType;
  };
}

const openPanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.PAGES_PANEL.OPEN_PANEL,
);
const closePanelInteraction = fedopsLogger.mapInteraction(
  fedopsLogger.INTERACTIONS.PAGES_PANEL.CLOSE_PANEL,
);

export const integratePagesPanelToSearch = (editorAPI: EditorAPI) => {
  const pagesPanelIntents = createPanelIntents<PagesPanelIntent>(
    editorAPI,
    ROOT_COMPS.LEFTBAR.MENUS_AND_PAGES_PANEL_NAME,
  );

  searchModule.addIntegration({
    type: Integrations.PAGES_PANEL,
    transform: (result) => {
      const tabId = result.extras.tabID;

      return {
        ...result,
        onEnter: () => {
          pagesPanelIntents.set({
            type: IntentType.OPEN,
            props: {
              selectedTabId: tabId,
              openPanelInteraction,
              closePanelInteraction,
              frameType: constants.PANEL_TYPES.LEFT,
            },
          });
        },
        onLeave: () => {
          pagesPanelIntents.set({
            type: IntentType.CLOSE,
            props: { selectedTabId: tabId },
          });
        },
      };
    },
  });
};

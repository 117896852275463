import { Hooks } from '#packages/apilib';

import type { CompRef } from 'types/documentServices';
import type {
  SelectComponentsByClickInterceptorData,
  SelectComponentsByRefInterceptorData,
} from './types';

export function createSelectionHooks() {
  return {
    selectComponentByCompClickInterceptor:
      Hooks.createInterceptorHook<SelectComponentsByClickInterceptorData>({
        isCancelAllowed: true,
      }),
    selectComponentByCompRefInterceptor:
      Hooks.createInterceptorHook<SelectComponentsByRefInterceptorData>({
        isUpdateDataAllowed: true,
        isCancelAllowed: true,
      }),
    selectionChanged: Hooks.createHook<{ compRefs: CompRef[] }>(),
  };
}

export type SelectionHooks = ReturnType<typeof createSelectionHooks>;

import { sections } from '#packages/util';
import { getSectionMinHeightByAnchors } from './sectionLayout/getSectionMinHeightByAnchors';
import { getSectionMinHeightBySOAPComponents } from './sectionLayout/getSectionMinHeightBySOAPComponents';

import type { LayoutConstraint } from '#packages/layoutUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

// TODO use scope and fix unit tests
export const sectionLayoutConstraint: LayoutConstraint = {
  shouldConstrain(editorAPI: EditorAPI, compRef: CompRef): boolean {
    return editorAPI.sections.isSection(compRef);
  },
  applyConstraint(editorAPI, compRef, layoutUpdated, layoutInitial) {
    const minHeightByAnchors = getSectionMinHeightByAnchors(
      editorAPI,
      compRef,
      {
        layoutInitial,
      },
    );
    const minHeightBySOAPComponents = getSectionMinHeightBySOAPComponents(
      editorAPI,
      compRef,
      {
        layoutInitial,
      },
    );

    layoutUpdated.height = Math.max(
      layoutUpdated.height,
      sections.constants.SECTION_MIN_HEIGHT,
      minHeightByAnchors,
      minHeightBySOAPComponents,
    );
  },
};

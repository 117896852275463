import React, {
  Component,
  type ComponentType,
  type MouseEvent,
  type ReactElement,
  type ReactNode,
} from 'react';
import DropDown from '../dropDown/dropDown';

export interface WithDropDownOnHoverDropDownProps {
  panelTopMargin?: number;
}

export interface WithDropDownOnHoverProps {
  dropPanelContent: ReactElement | null;
  dropPanelKey: string;
  handlerKey?: string;
  openedDropPanel?: string;
  panelClassName?: string;
  delayedFadeOut: boolean;
  openDropPanel: (key: string) => void;
  closeDropPanel: () => void;
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
  tooltip?: ReactNode;
}

export interface ComponentWithDropDownOnHoverProps {
  onMouseEnter: (event: MouseEvent) => void;
  onMouseLeave: (event: MouseEvent) => void;
  tooltip?: ReactNode;
}

const withDropDownOnHover = <P extends WithDropDownOnHoverProps>(
  WrappedComponent: ComponentType<P & ComponentWithDropDownOnHoverProps>,
) => {
  class WithDropDownOnHover extends Component<
    WithDropDownOnHoverProps & WithDropDownOnHoverDropDownProps
  > {
    handleMouseEnter = (event: AnyFixMe) => {
      const { handlerKey, dropPanelKey } = this.props;

      this.props.openDropPanel(handlerKey || dropPanelKey);
      this.props.onMouseEnter?.(event);
    };

    handleMouseLeave = (event: AnyFixMe) => {
      this.props.closeDropPanel();
      this.props.onMouseLeave?.(event);
    };

    render() {
      const { dropPanelKey, openedDropPanel, tooltip } = this.props;
      const isOpen = dropPanelKey === openedDropPanel;

      const wrappedComponent = React.createElement(
        WrappedComponent,
        Object.assign({}, this.props as P, {
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave,
          tooltip: isOpen ? null : tooltip,
        }),
      );

      return (
        <DropDown
          isOpen={isOpen}
          delayedFadeOut={this.props.delayedFadeOut}
          onPanelMouseEnter={() => this.props.openDropPanel(dropPanelKey)}
          onPanelMouseLeave={() => this.props.closeDropPanel()}
          panelContent={this.props.dropPanelContent}
          panelTopMargin={this.props.panelTopMargin}
          panelClassName={this.props.panelClassName}
        >
          {wrappedComponent}
        </DropDown>
      );
    }
  }

  return WithDropDownOnHover;
};

export default withDropDownOnHover;

import { DATA_BINDING } from '@wix/app-definition-ids';
import experiment from 'experiment';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { ComponentEditorMetaDataDefinition } from '../types';

const metaData: ComponentEditorMetaDataDefinition = {
  pinnable: false,
  skipInQuickEditPanel: true,
  skipInLayersPanel: (editorAPI: EditorAPI, compRef: CompRef) => {
    if (
      experiment.isOpen('specs.wixDataClient.ClassicInspectorPanel') ||
      experiment.isOpen('specs.wixDataClient.NewClassicBindingExperience')
    ) {
      return true;
    }

    const compData = editorAPI.components.data.get(compRef);
    if (compData) {
      return compData.applicationId !== DATA_BINDING;
    }
    return true;
  },
  showLegacyFixedPosition: false,
};

export default metaData;

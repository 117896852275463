// @ts-nocheck
import PropTypes from 'prop-types';
import React from 'react';
import { cx } from '#packages/util';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  src?: string;
  isSelected?: boolean;
}

export default class extends React.Component<Props> {
  static displayName = 'mobileThumbnail';

  static propTypes = {
    src: PropTypes.string,
    isSelected: PropTypes.bool,
  };

  render() {
    return (
      <span
        className={cx({
          'mobile-thumbnail': true,
          selected: this.props.isSelected,
        })}
      >
        <div className="mobile-thumbnail-screen">
          {this.props.src ? (
            <img
              key="mobile-thumbnail-image"
              src={this.props.src}
              className="mobile-thumbnail-image"
            />
          ) : null}
        </div>
        <span className="mobile-thumbnail-device-button" />

        {this.props.isSelected ? (
          <span
            key="mobile-thumbnail-selected-v-sign"
            className="selected-v-sign"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g fill="none" fillRule="evenodd">
                <circle cx="12" cy="12" r="12" fill="#FFF" />
                <circle cx="12" cy="12" r="10" fill="#009FFF" />
                <path
                  fill="#FFF"
                  d="M11.1499222,13.7938281 L15.5378669,9.26123263 C15.6872475,9.09577013 15.9092806,9 16.1435075,9 C16.3777344,9 16.5997675,9.09577013 16.7491481,9.26123263 C17.0836173,9.62225026 17.0836173,10.1523585 16.7491481,10.5133762 L11.9568204,15.4543098 C11.8868531,15.6237016 11.7639811,15.7761547 11.5940174,15.8891202 C11.3944441,16.0017519 11.1516692,16.0301538 10.9276584,15.9670767 C10.7036476,15.9039997 10.5200753,15.7555466 10.4237965,15.5596088 L8.11722963,12.876444 C7.87962339,12.4563257 8.0127279,11.9400019 8.43031503,11.661959 C8.62930791,11.5470787 8.87299901,11.5174134 9.09777727,11.5807066 C9.32255552,11.6439997 9.50611318,11.79397 9.60053588,11.9914704 L11.1499222,13.7938281 Z"
                />
              </g>
            </svg>
          </span>
        ) : null}
      </span>
    );
  }
}

import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { Composites, TextLabel, Thumbnails } from '@wix/wix-base-ui';

import { hoc } from '#packages/util';
import { translate } from '#packages/i18n';

import { convertPageIdToLinkPageId } from '../../utils';
import { mapStateToProps, mapDispatchToProps } from './EdgeAnchorLink.mapper';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export interface TEdgeAnchorLink {
  id?: string;
  type: string;
  pageId: string;
  anchorName: string;
  anchorDataId: string;
}

type EdgeAnchorLinkComponentProps = {
  link: TEdgeAnchorLink;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TEdgeAnchorLink): void;
} & ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

interface EdgeAnchorLinkComponentState {
  prevLink: TEdgeAnchorLink;
  link: TEdgeAnchorLink;
}

export class EdgeAnchorLinkComponent extends React.Component<
  EdgeAnchorLinkComponentProps,
  EdgeAnchorLinkComponentState
> {
  constructor(props: AnyFixMe) {
    super(props);

    const link = props.link || this.getAnchorLink('SCROLL_TO_TOP');

    this.state = {
      prevLink: link,
      link,
    };
  }

  static getDerivedStateFromProps(
    props: EdgeAnchorLinkComponentProps,
    state: EdgeAnchorLinkComponentState,
  ) {
    const nextLink =
      state.prevLink?.id !== props.link?.id ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(true);
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
    }
  }

  private getAnchorLink(newAnchorId: AnyFixMe) {
    const masterPageLinkId = convertPageIdToLinkPageId(
      this.props.siteStructureId,
    );

    const anchorLink: AnyFixMe = {
      ...this.props.createDefaultData('AnchorLink'),
      anchorDataId: newAnchorId,
      pageId: masterPageLinkId,
    };

    if (newAnchorId === 'SCROLL_TO_TOP') {
      anchorLink.anchorName = translate(
        'LINK_PANEL_PAGE_ANCHOR_SCROLL_TO_TOP_NAME',
      );
    } else {
      anchorLink.anchorName = translate(
        'LINK_PANEL_PAGE_ANCHOR_SCROLL_TO_BOTTOM_NAME',
      );
    }

    return anchorLink;
  }

  private setAnchor = (newAnchorId: AnyFixMe) => {
    const link = this.getAnchorLink(newAnchorId);

    this.setState({
      link,
    });
  };

  private getEdgeAnchors() {
    return [
      {
        value: 'SCROLL_TO_TOP',
        label: 'LINK_PANEL_TAB_TOPandBOTTOM_ TOP',
        illustration: React.createElement(Symbol, {
          name: 'anchorTop',
        }),
      },
      {
        value: 'SCROLL_TO_BOTTOM',
        label: 'LINK_PANEL_TAB_TOPandBOTTOM_ BOTTOM',
        illustration: React.createElement(Symbol, {
          name: 'anchorBottom',
        }),
      },
    ];
  }

  render() {
    return (
      <>
        <TextLabel
          value="LINK_PANEL_TAB_TOPandBOTTOM_LABEL"
          enableEllipsis={false}
        />
        <Composites.Thumbnails>
          <Thumbnails
            value={this.state.link.anchorDataId}
            onChange={this.setAnchor}
            maxThumbsPerRow={2}
            borderType="thumbnailAndLabel"
            options={this.getEdgeAnchors()}
          />
        </Composites.Thumbnails>
      </>
    );
  }
}

export const EdgeAnchorLink = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(EdgeAnchorLinkComponent);

EdgeAnchorLink.pure = EdgeAnchorLinkComponent;

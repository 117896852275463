// @ts-nocheck
import _ from 'lodash';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as superAppsCustomData from '../../superApps/superAppsCustomData';
import * as addPanelAddCompService from '../addPanelAddCompService';
import * as proGalleriesSectionConsts from './proGalleriesSectionConsts';
import { isResponsiveEditor } from '@wix/santa-editor-utils';
import experiments from 'experiment';

const appDefinitionId = '14271d6f-ba62-d045-549b-ab972ae1f70e';
const widgetId = '142bb34d-3439-576a-7118-683e690a1e0d';

const registerSection = function (editorAPI) {
  editorAPI.addPanel.registerSectionAtStart('gallery', getSection);
};

const addApp = function (
  compStructure,
  sectionTitle,
  tags,
  id,
  editorAPI,
  addOptions,
) {
  return addPanelAddCompService.addCompOnClick(
    editorAPI,
    appDefinitionId,
    widgetId,
    compStructure,
    tags,
    id,
    { origin: 'add_panel', sectionTitle, category: 'gallery' },
    addOptions,
  );
};

const onDrop = function (layoutParams, compPreset, parentComponent, editorAPI) {
  return addPanelAddCompService.addCompOnDrop(
    editorAPI,
    appDefinitionId,
    widgetId,
    layoutParams,
    compPreset,
    parentComponent,
    _.noop,
  );
};

const getSection = function () {
  const isNewAddPanelExperimentOpen = experiments.isOpen(
    'se_editorxNewProGalleryAddPanelSectionDesign',
  );
  const shouldUseNewDesign =
    isResponsiveEditor() && isNewAddPanelExperimentOpen;
  let sectionImage = proGalleriesSectionConsts.ADD_PANEL_SECTION_IMAGE;
  let sectionRetinaImage =
    proGalleriesSectionConsts.ADD_PANEL_SECTION_RETINA_IMAGE;

  if (shouldUseNewDesign) {
    sectionImage = proGalleriesSectionConsts.EDITOR_X_ADD_PANEL_SECTION_IMAGE;
    sectionRetinaImage =
      proGalleriesSectionConsts.EDITOR_X_ADD_PANEL_SECTION_RETINA_IMAGE;
  }

  return {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'add_section_label_progallery_v2',
    subNavigationTitle: 'add_section_label_progallery_v2',
    presetTitle: 'add_section_label_progallery_v2',
    tooltipTitle: 'add_section_label_progallery_v2',
    sectionName: 'proGalleriesSection',
    automationId: 'add-panel-section-proGalleriesSection',
    appDefinitionId,
    subNavigationHide: false,
    showSectionHeader: true,
    sectionNumber: 0,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.HOVER,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.VIDEO,
      iconEnabledForComps: {},
    },
    props: {
      onClick: addApp,
      onDrop,
      image: sectionImage,
      retinaImage: sectionRetinaImage,
      items: getItems(shouldUseNewDesign),
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
    help: { hide: false, text: 'add_section_info_text_progallery' },
  };
};

const getItems = function (shouldUseNewDesign) {
  const newGalleries = [
    {
      id: 'gridProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 570,
          height: 569,
          x: 0,
          y: 0,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: 'a3815ff1-316f-4a23-8de9-7a8737688038',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'gridProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            "[{'action':{'sourceId':'comp-j3rfcxog','type':'comp','name':'screenIn'},'behavior':{'targetId':'lq5h5','type':'site','name':'prefetchPages','params':{'prefetchFilters':{'id':['lq5h5']}}}}]",
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 173,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j3rfcxog',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 162, height: 182, x: 0, y: 0 },
        label: 'add_preset_pro_galleries_grid_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-1',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'thumbnailProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 568,
          height: 568,
          x: 0,
          y: 198,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '72187da9-188d-4d8d-8754-0599700a1bcf',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'thumbnailProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j3u10dhb","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j3u10dhb',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 162, height: 182, x: 162, y: 0 },
        label: 'add_preset_pro_galleries_thumbnail_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-2',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'stackedProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 568,
          height: 1039,
          x: 0,
          y: 52,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '88867a85-11eb-455f-8b54-0e7956142552',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'stackedProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41sbvu1","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41sbvu1',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 162, height: 208, x: 0, y: 182 },
        label: 'add_preset_pro_galleries_stacked_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-3',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'masonryProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 568,
          height: 1039,
          x: 0,
          y: 52,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '88867a85-11eb-455f-8b54-0e7956142552',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'masonryProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41sbvu1","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41sbvu1',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 162, height: 208, x: 162, y: 182 },
        label: 'add_preset_pro_galleries_masonry_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-4',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'collageProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1216,
          height: 384,
          x: -118,
          y: 237,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '88867a85-11eb-455f-8b54-0e7956142552',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'collageProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41sbvu1","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41sbvu1',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 324, height: 136, x: 0, y: 390 },
        label: 'add_preset_pro_galleries_collage_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-5',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'showcaseProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1216,
          height: 464,
          x: -118,
          y: 236,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '88867a85-11eb-455f-8b54-0e7956142552',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'showcaseProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41sbvu1","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41sbvu1',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 324, height: 156, x: 0, y: 526 },
        label: 'add_preset_pro_galleries_showcase_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-6',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'gridSliderProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1216,
          height: 416,
          x: -142,
          y: 236,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '88867a85-11eb-455f-8b54-0e7956142552',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'gridSliderProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41sbvu1","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41sbvu1',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 324, height: 144, x: 0, y: 682 },
        label: 'add_preset_pro_galleries_slider_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-7',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'accordionProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 980,
          height: 416,
          x: 0,
          y: 275,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '88867a85-11eb-455f-8b54-0e7956142552',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'accordionProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41sbvu1","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41sbvu1',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 324, height: 144, x: 0, y: 826 },
        label: 'add_preset_pro_galleries_accordion_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-8',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'slideDeckProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1216,
          height: 660,
          x: -118,
          y: 125,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '88867a85-11eb-455f-8b54-0e7956142552',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'slideDeckProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41sbvu1","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41sbvu1',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 324, height: 166, x: 0, y: 970 },
        label: 'add_preset_pro_galleries_slide_deck_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-9',
          ignoreMargins: true,
        },
      },
    },
    {
      id: 'sliderProGallery',
      structure: {
        type: 'Component',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        layout: {
          width: 1216,
          height: 472,
          x: -112,
          y: 236,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        data: {
          type: 'TPAWidget',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          applicationId: '1574',
          referenceId: '40bc889a-02c7-49ee-8d0c-1c4ada8665a3',
          widgetId: '142bb34d-3439-576a-7118-683e690a1e0d',
        },
        style: {
          type: 'TopLevelStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
          },
          style: superAppsCustomData.getSuperAppsAddPanelStyles(
            appDefinitionId,
            'sliderProGallery',
          ),
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
        },
        behaviors: {
          type: 'ObsoleteBehaviorsList',
          items:
            '[{"action":{"sourceId":"comp-j41krf33","type":"comp","name":"screenIn"},"behavior":{"targetId":"lq5h5","type":"site","name":"prefetchPages","params":{"prefetchFilters":{"id":["lq5h5"]}}}}]',
        },
        mobileStructure: {
          layout: {
            width: 320,
            height: 146,
            x: 0,
            y: 420,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          metaData: {
            originalCompId: 'comp-j41krf33',
            author: 'duplicate',
          },
        },
        activeModes: {},
      },
      preset: {
        rect: { width: 324, height: 158, x: 0, y: 1136 },
        label: 'add_preset_pro_galleries_slider_label',
        tags: { isFullWidth: false },
        video: {
          poster: 'poster-white-1x1.png',
          source: 'addpanel/gallery-pro/v1/gallery-video-thumb-pro-10',
          ignoreMargins: true,
        },
      },
    },
  ];
  return newGalleries.map((item) => {
    if (shouldUseNewDesign) {
      item.preset = proGalleriesSectionConsts.EDITOR_X_ITEMS_PRESETS[item.id]
        ? proGalleriesSectionConsts.EDITOR_X_ITEMS_PRESETS[item.id]
        : item.preset;
      item.preset.video = null;
    }
    return item;
  }); // eslint-disable-line @wix/santa/no-experiment-get-value-boolean
};

export { registerSection, getSection };

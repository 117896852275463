import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import {
  Button,
  TextLabel,
  Composites,
  RichText,
  Illustration,
} from '@wix/wix-base-ui';

export interface ErrorStateContent {
  title: string;
  learnMore?: string;
  symbolName: string;
  actionText: string;
  description: string;
  actionHandler: () => void;
  learnMoreAction?: () => void;
}

export const ErrorStateContent = ({
  symbolName,
  title,
  description,
  actionHandler,
  actionText,
  learnMoreAction,
  learnMore,
}: ErrorStateContent) => {
  return (
    <div className="error-state-wrapper">
      <Composites.RichTextWithIllustrationVertical className="content-width-xl">
        <Illustration>
          <symbols.symbol name={symbolName} />
        </Illustration>
        <Composites.RichTextLabeled>
          <TextLabel type="T09" value={title} enableEllipsis={false} />
          <RichText className="light" type="T07">
            {translate(description)}
          </RichText>
        </Composites.RichTextLabeled>
        <Composites.ButtonLarge>
          <Button onClick={actionHandler}>{translate(actionText)}</Button>
        </Composites.ButtonLarge>
        {learnMoreAction && (
          <a onClick={learnMoreAction}>{translate(learnMore)}</a>
        )}
      </Composites.RichTextWithIllustrationVertical>
    </div>
  );
};

import {
  fedopsLogger,
  sections as sectionsUtils,
  editorModel,
} from '#packages/util';
import {
  reportMigrationNotPossible,
  runSectionMigration,
  runAnchorMigration,
  skipMigration,
  resolveMigration,
  migrationFlowValidation,
} from './api';
import {
  getPagesVisibleInMenu,
  getPagesToMigrateIntoSections,
  getPagesToMigrateAnchors,
  hasPagesWithAnchorsInRoot,
  hasPagesWithoutSectionsInRoot,
  getMigrationSkipCount,
  getMigrationOrigin,
} from './utils';
import { MigrationFlow } from './types';

import type { SectionsMigrationScope as Scope } from './scope';
import type { CompRef } from 'types/documentServices';

function resolveMigrationFlow(scope: Scope): MigrationFlow {
  const { sectionsMigrationFlow, isInsideEditorX, isInsideAppStudio } =
    scope.editorParamsAPI;

  if (isInsideEditorX || isInsideAppStudio || editorModel.isImpersonated) {
    return;
  }

  const isSectionsEnabled =
    sectionsUtils.isSectionsEnabled() ||
    sectionsUtils.getSiteSectionsEditorData(scope.editorAPI)?.isSectionsEnabled;

  if (!isSectionsEnabled && sectionsMigrationFlow) {
    return sectionsMigrationFlow;
  }

  if (!isSectionsEnabled) {
    return;
  }

  const pagesToCheck = getPagesVisibleInMenu(scope.editorAPI.documentServices);

  if (
    hasPagesWithoutSectionsInRoot(
      scope.editorAPI.documentServices,
      pagesToCheck,
    )
  ) {
    return MigrationFlow.Editor2Fix;
  }

  if (
    hasPagesWithAnchorsInRoot(scope.editorAPI.documentServices, pagesToCheck)
  ) {
    return MigrationFlow.Editor2Anchors;
  }
}

function resolvePagesToMigrate(
  scope: Scope,
  migrationFlow: MigrationFlow,
): CompRef[] {
  const documentServices = scope.editorAPI.documentServices;

  return migrationFlow === MigrationFlow.Editor2Anchors
    ? getPagesToMigrateAnchors(documentServices)
    : getPagesToMigrateIntoSections(documentServices);
}

export const initMigration = async (scope: Scope) => {
  const migrationFlow = resolveMigrationFlow(scope);
  const migrationOrigin = getMigrationOrigin();

  if (!migrationFlow) {
    skipMigration(scope);

    return;
  }

  const pagesRefs = resolvePagesToMigrate(scope, migrationFlow);

  if (
    pagesRefs.length === 0 &&
    migrationFlow !== MigrationFlow.Editor2Anchors
  ) {
    resolveMigration(scope, {
      flow: migrationFlow,
      origin: migrationOrigin,
    });

    return;
  }

  const {
    isValidAmountOfPages,
    isValidOnBoarding,
    isValidVersion,
    isAfterFailedMigration,
  } = migrationFlowValidation(scope, migrationFlow, pagesRefs);
  const currentMigrationSkipCount = getMigrationSkipCount(scope);

  if (
    !isValidAmountOfPages ||
    !isValidOnBoarding ||
    !isValidVersion ||
    isAfterFailedMigration
  ) {
    skipMigration(scope);

    reportMigrationNotPossible(scope, {
      flow: migrationFlow,
      origin: migrationOrigin,
      hasTooManyPages: !isValidAmountOfPages,
      isNotOnBoarding: !isValidOnBoarding,
      hasBeenFailedMigrated: !isValidVersion,
    });

    return;
  }

  scope.editorCoreAPI.hooks.sectionsMigrationStarted.resolve({
    migrationSkipCount: currentMigrationSkipCount,
  });

  try {
    fedopsLogger.appLoadingPhaseStart('sections-migration-init');

    if (migrationFlow === MigrationFlow.Editor2Anchors) {
      await runAnchorMigration(scope, {
        flow: migrationFlow,
        origin: migrationOrigin,
        pagesRefs,
      });
    } else {
      await runSectionMigration(scope, {
        flow: migrationFlow,
        origin: migrationOrigin,
        pagesRefs,
      });
    }

    fedopsLogger.appLoadingPhaseFinish('sections-migration-init');

    scope.editorCoreAPI.hooks.sectionsMigrationReady.resolve({
      isMigrated: true,
    });
  } catch (error: MaybeError) {
    scope.editorCoreAPI.hooks.sectionsMigrationReady.resolve({
      isMigrated: false,
      error,
    });
  }
};

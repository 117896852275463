// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '#packages/core';
import * as baseUI from '#packages/baseUI';
import * as stateManagement from '#packages/stateManagement';

function getTargetLayout(editorAPI) {
  const coords = editorAPI.cursor.get();

  return {
    height: 0,
    width: 0,
    top: coords.y || 0,
    left: coords.x || 0,
  };
}

function provideDelayBeforeNextQuickTip(editorAPI, sessionDisabledId, delay) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      sessionDisabledId,
      true,
    ),
  );
  window.setTimeout(function () {
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        sessionDisabledId,
        false,
      ),
    );
  }, delay);
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'overlayWithQuickTip',
  mixins: [core.mixins.editorAPIMixin],

  UNSAFE_componentWillMount() {
    this.quickTipShowTimeoutId = this.showQuickTip();
  },

  componentWillUnmount() {
    if (this.isQuickTipShown) {
      if (this.props.delayBeforeNext) {
        provideDelayBeforeNextQuickTip(
          this.getEditorAPI(),
          this.sessionDisabledId,
          this.props.delayBeforeNext,
        );
      }
      this.closeQuickTip();
    }
    if (this.quickTipShowTimeoutId) {
      window.clearTimeout(this.quickTipShowTimeoutId);
    }
  },

  showQuickTip() {
    const editorAPI = this.getEditorAPI();
    const sessionId = this.props.quickTipSession.id + this.props.hoveredComp.id;

    const sessionQuickTipShown =
      stateManagement.userPreferences.selectors.getSessionUserPreferences(
        sessionId,
      )(editorAPI.store.getState()) || 0;

    this.sessionDisabledId = `${sessionId}-disabled`;

    if (
      sessionQuickTipShown >= this.props.quickTipSession.maxNum ||
      stateManagement.userPreferences.selectors.getSessionUserPreferences(
        this.sessionDisabledId,
      )(editorAPI.store.getState())
    ) {
      return null;
    }

    return window.setTimeout(
      function () {
        const targetLayout = getTargetLayout(editorAPI);
        const innerTemplate = React.createElement(
          baseUI.popoverTemplates.quickTip,
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign(this.props.templateInfo, {
            closeAction: this.closeQuickTip,
          }),
        );

        this.quickTipShowTimeoutId = null;
        this.isQuickTipShown = true;
        editorAPI.store.dispatch(
          stateManagement.userPreferences.actions.setSessionUserPreferences(
            sessionId,
            sessionQuickTipShown + 1,
          ),
        );

        editorAPI.showFloatingBubble(
          innerTemplate,
          targetLayout,
          this.props.propsForBubble,
          this.props.shouldNotHideOnMouseLeaveTarget,
        );
      }.bind(this),
      this.props.delayBeforeShow,
    );
  },

  closeQuickTip() {
    this.getEditorAPI().hideFloatingBubble();
    this.getEditorAPI().overlay.enableUpdate();
    this.isQuickTipShown = false;
  },

  render() {
    return this.props.children;
  },
});

import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey, LayoutersApiKey } from '#packages/apis';
import { createLayoutersApi } from './layoutersWrapper';

export const LayoutersEntrypoint: EntryPoint = {
  name: 'LayoutersApi',
  declareAPIs: () => [LayoutersApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(LayoutersApiKey, () => createLayoutersApi(shell));
  },
};

import { LEGACY_WIX_APPS_NAMES } from '#packages/constants';

export enum WixAppsAppNames {
  News = 'news',
  FAQ = 'faq',
  Menus = 'menu',
}

export const PART_NAME_TO_DATASTORE_ID: Record<
  LEGACY_WIX_APPS_NAMES,
  WixAppsAppNames
> = {
  [LEGACY_WIX_APPS_NAMES.FAQ]: WixAppsAppNames.FAQ,
  [LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS]: WixAppsAppNames.Menus,
  [LEGACY_WIX_APPS_NAMES.NEWS_A]: WixAppsAppNames.News,
  [LEGACY_WIX_APPS_NAMES.NEWS_B]: WixAppsAppNames.News,
};

export const TRANSLATIONS = {
  PROGRESS_BAR: {
    TITLE: 'news_exporting_to_collection_progress_bar_header',
    STEP_1: 'news_exporting_to_collection_progress_bar_text1',
    STEP_2: 'news_exporting_to_collection_progress_bar_text2',
    STEP_3: 'news_exporting_to_collection_progress_bar_text3',
  },
};

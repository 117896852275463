:root {
  --g-top-bar-height: 48px;
  --g-mobile-top: 59px;
  --g-mobile-bottom: 94px;
  --g-mobile-preview-max-height: 512px; }

._container_1nqge_7 {
  position: absolute;
  pointer-events: none;
  top: var(--stage-action-container-margin);
  z-index: 102;
  display: flex; }
  ._container_1nqge_7._horizontallyCentered_1nqge_13 {
    top: calc(50% - var(--stage-action-button-height)/2); }
  ._container_1nqge_7 ._lightLarge_1nqge_15 {
    top: 24px;
    left: 24px; }

._button_1nqge_19 {
  margin-right: 12px;
  cursor: pointer;
  pointer-events: all; }
  ._button_1nqge_19:last-child {
    margin-right: 0; }

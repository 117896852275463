import { BasePublicApi } from '#packages/apilib';
import constants from '#packages/constants';
import * as styles from '#packages/styles';
import { array, sections, biLogger } from '#packages/util';
import experiment from 'experiment';
import {
  DEFAULT_WITH_FILL_LAYERS_SKIN_NAME,
  DESIGN_STYLE_PROPERTIES_MAPPER,
  SCROLL_SUFFIX,
  TRANSPARENT_SITE_SEGMENT_SKIN,
} from './constants';
import * as stateManagement from '#packages/stateManagement';
import { undoRedoComponentChange } from '@wix/bi-logger-editor/v2';

import type { Scope } from './scope';
import type { CompRef } from '@wix/document-services-types';

const { styleUtil } = styles.advancedStyle;

const advancedSiteSegmentBackgroundEnabled = (
  scope: Scope,
  selectedComponent: CompRef | CompRef[],
) => {
  const component = array.asSingle(selectedComponent);
  return (
    experiment.isOpen('se_siteSegmentMediaBackground') &&
    sections.isSectionsEnabled() &&
    compDesignExistsAndHasFills(scope, component) &&
    scope.sections.isHeaderOrFooter(component)
  );
};

export const compDesignExistsAndHasFills = (
  scope: Scope,
  compRef: CompRef | CompRef[],
) => {
  const component = array.asSingle(compRef);
  const componentDesign = scope.components.design.get(component);

  if (!componentDesign) {
    return false;
  }
  const colorLayers = componentDesign?.background?.colorLayers;
  // if colorLayers exist, but is an empty array, the panel will not open. !colorLayers is a valid value (bg of type None)
  return !colorLayers || colorLayers.length > 0;
};

const getShouldMigrateSiteSegment = (
  scope: Scope,
  selectedComponent: CompRef,
) => {
  const isSiteSegment = scope.sections.isHeaderOrFooter(selectedComponent);
  const componentStyle = scope.components.style.get([
    array.asSingle(selectedComponent),
  ]);

  const isAlreadyMigarated =
    componentStyle.skin === DEFAULT_WITH_FILL_LAYERS_SKIN_NAME;

  return isSiteSegment && !isAlreadyMigarated;
};

const migrateSiteSegmentOnChangeIfApplicable = async (
  scope: Scope,
  selectedComponent: CompRef,
  showNotificationFn: (
    options: stateManagement.ShowUserActionNotificationParam,
  ) => void,
) => {
  await scope.editorAPI.waitForChangesAppliedAsync();

  if (!getShouldMigrateSiteSegment(scope, selectedComponent)) return;

  const componentStyle = scope.components.style.get([
    array.asSingle(selectedComponent),
  ]);
  if (!componentStyle) {
    return;
  }

  const skinDefinitionFunction = scope.editorAPI.theme.skins.getSkinDefinition;

  const skinParams = styleUtil.getStyleForSkin(
    skinDefinitionFunction,
    DEFAULT_WITH_FILL_LAYERS_SKIN_NAME,
    null,
  );
  const prevSkin = componentStyle.skin;
  componentStyle.skin = DEFAULT_WITH_FILL_LAYERS_SKIN_NAME;

  // to transfer the properties from old skin to the default of the new one
  styleUtil.mergeCurrentSkinIntoDefaultWithFillLayers(
    skinParams.properties,
    componentStyle.style.properties,
  );
  Object.assign(componentStyle.style, {
    properties: skinParams.properties,
    propertiesSource: skinParams.propertiesSource,
  });
  //needed for bg scroll to apply correctly with fill layers (fill layers scroll alpha depends on style alpha-bg)
  if (componentStyle.style.properties['bg-scrl']) {
    componentStyle.style.properties['alpha-bg'] = '0';
  }

  const lastSnapshot =
    scope.editorAPI.dsActions.history.getUndoLastSnapshotId();
  scope.components.style.update(selectedComponent, componentStyle);

  //we do not show notification for transparent site segment skin
  if (prevSkin === TRANSPARENT_SITE_SEGMENT_SKIN) return;

  const isHeader = scope.sections.isHeader(selectedComponent);
  const notificationMessage = isHeader
    ? 'StylablePanel_Design_Scroll_Changes_Notification_Text'
    : 'StylablePanel_Design_Scroll_Changes_Footer_Notification_Text';

  showNotificationFn({
    message: notificationMessage,
    type: 'info',
    shouldTranslate: true,
    link: {
      caption: 'StylablePanel_Design_Scroll_Changes_Notification_Link',
      onNotificationLinkClick: async () => {
        await scope.editorAPI.history.performUndoUntilId(lastSnapshot, false);
        biLogger.report(
          undoRedoComponentChange({
            actionName: 'undo_migrate_site_segment',
            component_id: array.asSingle(selectedComponent).id,
            component_type: componentStyle.componentClassName,
            origin: 'notification',
          }),
        );
      },
    },
  });
};

const getBiValuesAdvancedStyle = (paramName: string) => {
  const tabAndState =
    DESIGN_STYLE_PROPERTIES_MAPPER[paramName.replace(SCROLL_SUFFIX, '')];
  const mouse_state = paramName.includes(SCROLL_SUFFIX) ? 'scroll' : 'regular';

  const biValues = {
    ...tabAndState,
    mouse_state,
    panel_name: constants.PANELS.ADVANCED_SITE_SEGMENT_DESIGN_PANEL,
  };

  return biValues;
};
export class SiteSegmentMediaBackgroundPublicApi extends BasePublicApi<Scope> {
  advancedSiteSegmentBackgroundEnabled = this.bindScope(
    advancedSiteSegmentBackgroundEnabled,
  );
  compDesignExistsAndHasFills = this.bindScope(compDesignExistsAndHasFills);
  migrateSiteSegmentOnChangeIfApplicable = this.bindScope(
    migrateSiteSegmentOnChangeIfApplicable,
  );
  getBiValuesAdvancedStyle = getBiValuesAdvancedStyle;
}

// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import React from 'react';
import { CustomScroll, Checkbox } from '@wix/wix-base-ui';

import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as core from '#packages/core';
import * as pagesMenu from '#packages/pagesMenu';
import * as coreBi from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';
import * as Panels from '#packages/panels';
import PageItem from './bgApplyPanelPageItem';

// NOTE: use `?.` because of circular dependency lead to `undefined` in unit tests.
const menuLogic = pagesMenu.specialPages?.menuLogic;
const { biEvents } = util.backgroundUtils;
const { getDeviceType } = stateManagement.leftBar.selectors;

function flattenPagesList(pages, flattenedPages, sectionPageIdsList, menu) {
  flattenedPages = flattenedPages || [];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(
    pages,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/bind
    _.bind(function (page) {
      if (page.pageData) {
        flattenedPages.push(page);
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        if (_.includes(sectionPageIdsList, page.pageData.id)) {
          const specialMenuItems =
            this.getEditorAPI().mainMenu.convertPagesDataListToMenuItemsList(
              menuLogic.getSpecialPagesListBySectionPageOrBySibling(
                this.getEditorAPI(),
                page.pageData,
              ),
              menu,
            );
          flattenPagesList.call(
            this,
            specialMenuItems,
            flattenedPages,
            sectionPageIdsList,
            menu,
          );
        }
      }
      flattenPagesList.call(
        this,
        page.items,
        flattenedPages,
        sectionPageIdsList,
        menu,
      );
    }, this),
  );

  return _.uniqBy(flattenedPages, 'id');
}

function addTpaPages(items, editorAPI) {
  let isTPAExists = false;
  //TODO: change to support all menus  - only brings pages of main menu
  const menu = editorAPI.dsRead.mainMenu.getMenu();
  const pagesData = editorAPI.dsRead.pages.getPagesData();
  const sectionPageIdsList = menuLogic.getSectionPagesIds(editorAPI, pagesData);
  let specialMenuItems;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(items, function (item) {
    let isTPA;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (item.pageData && _.includes(sectionPageIdsList, item.pageData.id)) {
      isTPA = core.utils.tpaTypeUtils.isTPA(item.pageData);
      if (isTPAExists && isTPA) {
        return;
      }
      specialMenuItems = editorAPI.mainMenu.convertPagesDataListToMenuItemsList(
        menuLogic.getSpecialPagesListBySectionPageOrBySibling(
          editorAPI,
          item.pageData,
          pagesData,
        ),
        menu,
      );
      item.items = item.items.concat(specialMenuItems);
      if (isTPA) {
        isTPAExists = true;
      }
    }
  });
}

function addDynamicPages(editorAPI) {
  const dynamicPages = editorAPI.pages.dynamicPages.getMenuItems();
  return _.flatMap(dynamicPages, 'items');
}

function getData(editorAPI, pageId) {
  const state = editorAPI.store.getState();
  const device = getDeviceType(state);
  return editorAPI.dsRead.pages.background.get(pageId, device);
}

/**
 * request data change for all items that exist is appliedPages array
 * @param props
 * @param editorAPI
 * @param currentPageBgData
 * @param appliedPages [{checked: boolean, originalPageBgData: object, pageId: string}]
 */
function applyPageBgData(props, editorAPI, currentPageBgData, appliedPages) {
  let bgData;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(appliedPages, function (page) {
    if (page.checked) {
      bgData = setPageBgData(
        _.cloneDeep(currentPageBgData),
        page.pageId,
        editorAPI,
      );
    } else {
      bgData = setPageBgData(
        _.cloneDeep(page.originalPageBgData),
        page.pageId,
        editorAPI,
      );
      //bgData = page.originalPageBgData;
    }
    props.requestChange(bgData, null, page.pageId);
  });
  if (appliedPages.length > 0) {
    props.undoHandler(props.undoOrigins.APPLY_PANEL);
  }
}

/**
 * create new bg data while preserving existing data id's
 * @param currentBgData
 * @param pageId
 * @param editorAPI
 * @returns {*}
 */
function setPageBgData(currentBgData, pageId, editorAPI) {
  const state = editorAPI.store.getState();
  const device = getDeviceType(state);
  const oldBgData = editorAPI.dsRead.pages.background.get(pageId, device);

  //same media type
  if (
    currentBgData.ref.mediaRef &&
    oldBgData.ref.mediaRef &&
    currentBgData.ref.mediaRef.type === oldBgData.ref.mediaRef.type
  ) {
    currentBgData.ref.mediaRef.id = oldBgData.ref.mediaRef.id;
    if (currentBgData.ref.mediaRef.type === 'WixVideo') {
      currentBgData.ref.mediaRef.posterImageRef.id =
        oldBgData.ref.mediaRef.posterImageRef.id;
      if (currentBgData.ref.imageOverlay) {
        currentBgData.ref.imageOverlay.id = oldBgData.ref.imageOverlay?.id;
      }
    }
    // not the same media type
  } else if (currentBgData.ref.mediaRef) {
    delete currentBgData.ref.mediaRef.id;
    if (currentBgData.ref.mediaRef.type === 'WixVideo') {
      delete currentBgData.ref.mediaRef.posterImageRef.id;
      if (currentBgData.ref.imageOverlay) {
        delete currentBgData.ref.imageOverlay.id;
      }
    }
    //no media, we have to reset those fields since DS page.updateBackground is merging previous values
  } else {
    currentBgData.ref.mediaRef = null;
    currentBgData.ref.colorOverlay = '';
    currentBgData.ref.imageOverlay = null;
    currentBgData.ref.scrollType = 'scroll';
  }

  //copy top level 'BackgroundMedia' id
  currentBgData.ref.id = oldBgData.ref.id;

  return currentBgData;
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'backgroundApplyPanel',
  mixins: [
    core.mixins.editorAPIMixin,
    util.draggableMixin,
    ReactLinkedStateMixin,
  ], //eslint-disable-line react/no-deprecated

  getInitialState() {
    this.selectedItems = [];
    this.appliedPages = [];
    this.currentPageId = this.getEditorAPI().dsRead.pages.getPrimaryPageId();
    this.currentPageBgData = getData(this.getEditorAPI(), this.currentPageId);
    this.siteMenuItems = this.getTrimmedMenuItems();
    this.flatSitePages = this.getFlatPagesList();
    this.itemsProps = this.getItemsProps();

    return {
      markAllPagesDisplay: false,
      indeterminate: false,
    };
  },

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.markAllPagesDisplay !== nextState.markAllPagesDisplay ||
      this.state.indeterminate !== nextState.indeterminate ||
      this.appliedPages.length > 0
    );
  },

  componentWillUnmount() {
    biEvents.applyToOtherEvent(
      this.getEditorAPI(),
      coreBi.events.backgroundPanel.panel_apply_to_other_pages_ok,
      {
        all_pages_selected: (
          this.flatSitePages.length ===
          this.selectedItems.length + 1
        ).toString(),
        num_unselected_pages:
          this.flatSitePages.length - this.selectedItems.length,
        num_selected_pages: this.selectedItems.length,
      },
    );
  },

  updatePages() {
    applyPageBgData(
      this.props,
      this.getEditorAPI(),
      this.currentPageBgData,
      this.appliedPages,
    );
    this.appliedPages = [];
  },

  getCurrentPageMediaType(currPageId: string, editorAPI) {
    const state = editorAPI.store.getState();
    const device = getDeviceType(state);
    const currentPageBgData = editorAPI.dsRead.pages.background.get(
      this.currentPageId,
      device,
    );
    return currentPageBgData.ref.mediaRef?.type;
  },

  handleSelectAllChange(checked) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.itemsProps,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (item) {
        const pageId = item.page.pageData.id;
        if (pageId === this.currentPageId) {
          return;
        }
        if (checked) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (!_.includes(this.selectedItems, pageId)) {
            this.selectedItems.push(pageId);
            this.appliedPages.push({
              checked,
              originalPageBgData: item.itemBgData,
              pageId,
            });
          }
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        } else if (_.includes(this.selectedItems, pageId)) {
          _.pull(this.selectedItems, pageId);
          this.appliedPages.push({
            checked,
            originalPageBgData: item.itemBgData,
            pageId,
          });
        }
        const action = checked ? 'on' : 'off';
        this.sendToggleBiEvent(pageId, action);
      }, this),
    );
    this.setState(
      { markAllPagesDisplay: checked, indeterminate: false },
      this.updatePages,
    );
  },

  pagesStateChange() {
    let value = null;
    if (this.flatSitePages.length === this.selectedItems.length + 1) {
      value = true;
    }

    if (this.selectedItems.length === 0) {
      value = false;
    }

    return {
      value,
      requestChange: function (checked, pageId, originalPageBgData) {
        if (checked && !(pageId === this.currentPageId)) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/includes
          if (!_.includes(this.selectedItems, pageId)) {
            this.selectedItems.push(pageId);
          }
        } else {
          _.pull(this.selectedItems, pageId);
        }
        //fill appliedPages array . actual update will occur sync or async according to the need of state update
        this.appliedPages.push({ checked, originalPageBgData, pageId });
        const markAllPagesDisplay =
          this.flatSitePages.length === this.selectedItems.length + 1;
        const indeterminate =
          !markAllPagesDisplay && this.selectedItems.length > 0;
        if (
          markAllPagesDisplay !== this.state.markAllPagesDisplay ||
          indeterminate !== this.state.indeterminate
        ) {
          const newState = {
            markAllPagesDisplay,
            indeterminate,
          };
          this.setState(newState, this.updatePages);
        } else {
          this.updatePages();
        }
      }.bind(this),
    };
  },

  getFlatPagesList() {
    const editorAPI = this.getEditorAPI();
    const pagesData = editorAPI.dsRead.pages.getPagesData();
    let mainMenu = editorAPI.mainMenu.getMenuItemsTreeForMainMenu(pagesData);
    mainMenu = mainMenu.concat(addDynamicPages(this.getEditorAPI()));
    const sectionPageIdsList = menuLogic.getSectionPagesIds(
      editorAPI,
      pagesData,
    );
    const menu = editorAPI.dsRead.mainMenu.getMenu();
    return flattenPagesList.call(this, mainMenu, [], sectionPageIdsList, menu);
  },

  getTrimmedMenuItems() {
    let mainMenu = this.getMenuItemsTreeWithPagesOnly();
    addTpaPages(mainMenu, this.getEditorAPI());
    mainMenu = mainMenu.concat(addDynamicPages(this.getEditorAPI()));
    return mainMenu;
  },

  buildDynamicPagesIdsMap() {
    const dynamicPagesIdsMap = {};
    const routers = this.getEditorAPI().dsRead.routers.get.all();
    _.forOwn(routers, (router) => {
      _.forOwn(router.pages, (pageId) => {
        dynamicPagesIdsMap[pageId] = true;
      });
    });
    return dynamicPagesIdsMap;
  },

  getMenuItemsTreeWithPagesOnly() {
    const editorAPI = this.getEditorAPI();
    const dynamicPagesIdsMap = this.buildDynamicPagesIdsMap();

    const isPageOrContainsItems = function (menuItem, pageData) {
      return (
        (pageData &&
          !menuLogic.isSpecialPage(editorAPI, pageData, dynamicPagesIdsMap)) ||
        (!pageData && menuItem.items.length > 0)
      );
    };

    return editorAPI.mainMenu.extendMenuItemsTreeWithPageData(
      editorAPI.dsRead.pages.getPagesData(),
      isPageOrContainsItems,
    );
  },

  getItemsProps() {
    const itemsProps = [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.flatSitePages,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (page) {
        itemsProps.push({
          page,
          currentPageId: this.currentPageId,
          currentPageBgData: this.currentPageBgData,
          itemBgData: getData(this.getEditorAPI(), page.pageData.id),
          isCurrentPage:
            this.currentPageId === (page.type.isPage && page.pageData.id),
        });
      }, this),
    );
    return itemsProps;
  },

  getPagesProps(page) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    let props = _.find(this.itemsProps, { page: { id: page.id } });
    if (!props) {
      props = { page };
    }
    return props;
  },

  getSubPagesProps(subPage, subPageIndex, lastIndex) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const subPageProps = _.find(this.itemsProps, { page: { id: subPage.id } });
    subPageProps.isSubMenu = true;
    subPageProps.isSubMenuLast = subPageIndex === lastIndex;
    return subPageProps;
  },

  sendToggleBiEvent(targetPageId: string, action: string) {
    const mediaType = this.getCurrentPageMediaType(
      this.currPageId,
      this.getEditorAPI(),
    );
    biEvents.applyToOtherEvent(
      this.getEditorAPI(),
      coreBi.events.backgroundPanel.bg_apply_to_other_toggle,
      {
        target_page_id: targetPageId,
        action,
        pageId: this.currentPageId,
        category: mediaType,
      },
    );
  },

  render() {
    return (
      <Panels.frames.ToolPanelFrame
        helpId="18df7de7-066e-49e6-b005-f01c72d4f680"
        contentWrapperClass="bg-apply-panel background-sub-panel"
        headerTitle={translate('BGPP_SETTINGS_APLLY_PANEL_HEADER_TITLE')}
        {..._.omit(this.props, 'children')}
      >
        <div className="bg-apply-panel-wrapper">
          <div className="bg-apply-panel-description">
            {translate('BGPP_SETTINGS_APLLY_PANEL_DESCRIPTION')}
          </div>

          <div className="bg-apply-panel-all">
            <Checkbox
              labelAfterSymbol={true}
              value={this.state.markAllPagesDisplay}
              onChange={this.handleSelectAllChange}
              indeterminate={this.state.indeterminate}
              label="BGPP_SETTINGS_APLLY_TO_ALL"
            />
          </div>

          <CustomScroll>
            <div className="bg-apply-panel-scrollbox bg-settings-panel-scrollbox">
              <ul className="bg-apply-panel-scrollbox-list">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.siteMenuItems, (page, pageIndex) => (
                  <li key={`page${pageIndex}`}>
                    <PageItem
                      valueLink={this.pagesStateChange()}
                      ref={`page${pageIndex}`}
                      {...this.getPagesProps(page)}
                      sendToggleBi={this.sendToggleBiEvent}
                    />
                    {page.items?.length ? (
                      <ul key="pageItems">
                        {/* TODO: Fix this the next time the file is edited. */}
                        {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                        {_.map(page.items, (subPage, subPageIndex) => (
                          <li key={`page${pageIndex}subPage${subPageIndex}`}>
                            <PageItem
                              valueLink={this.pagesStateChange()}
                              ref={`page${pageIndex}subPage${subPageIndex}`}
                              {...this.getSubPagesProps(
                                subPage,
                                subPageIndex,
                                page.items.length - 1,
                              )}
                              sendToggleBi={this.sendToggleBiEvent}
                            />
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </ul>
            </div>
          </CustomScroll>
        </div>
      </Panels.frames.ToolPanelFrame>
    );
  },
});

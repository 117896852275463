// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import * as superAppBi from '../../bi/superAppBi';
import * as experiments from '../../utils/experiments';
import constants from '#packages/constants';
import React from 'react';
import _ from 'lodash';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import { BackButton, Composites, CustomScroll } from '@wix/wix-base-ui';
import { cx } from '#packages/util';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'superAppLearnMorePanel',
  mixins: [core.mixins.editorAPIMixin],

  getUrl(urlType, viewType) {
    return viewType === 'viewA'
      ? this.props.superApp.panel.learnMore.data[viewType][urlType].link
      : urlType;
  },

  openPanel(urlType, viewType) {
    const editorAPI = this.getEditorAPI();
    const biHelpParams = {
      panel_name: urlType,
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
      component: this.props.superApp.appName,
      learn_more: true,
    };
    editorAPI.panelManager.openHelpCenter(
      this.getUrl(urlType, viewType),
      null,
      biHelpParams,
    );
  },

  reportBiEvent(category) {
    const superAppProps = this.props.superApp;
    const params = {
      category,
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(
      superAppBi.events.LEARN_MORE_PANEL_CATEGORY_CLICK,
      params,
    );
  },

  getLearnMoreDataForViewB() {
    return this.props.superApp.panel.learnMore.data.viewB;
  },

  isLearnMoreExperimentOpen() {
    return experiments.isLearnMoreExperimentOn();
  },

  render() {
    const backButtonData = this.props.superApp.panel.learnMore.backButton;
    const rootClass = this.props.superApp.panel.learnMore.rootClass;
    return (
      <div
        className={cx({
          'site-app-learn-more': true,
          [rootClass]: !!rootClass,
        })}
        data-hook="learn-more-page"
      >
        <CustomScroll heightRelativeToParent="100%">
          {backButtonData && (
            <Composites.ButtonLeft>
              <BackButton
                label={backButtonData.label}
                onClick={() =>
                  this.props.selectTabByName(backButtonData.backTabName)
                }
              />
            </Composites.ButtonLeft>
          )}
          {!this.isLearnMoreExperimentOpen() ? (
            <div
              key="firstView"
              className={cx({ 'first-view': !backButtonData })}
            >
              {this.props.superApp.panel.learnMore.data.viewA.wixVideos ? (
                <a
                  key="wixVideos"
                  onClick={() => {
                    this.reportBiEvent('Videos');
                  }}
                  target="_blank"
                  href={
                    this.props.superApp.panel.learnMore.data.viewA.wixVideos
                      .link
                  }
                  className="image-container"
                >
                  <div className="active-layer" />
                  <img
                    src={
                      this.props.superApp.panel.learnMore.data.viewA.wixVideos
                        .image
                    }
                    alt="Support Videos"
                  />

                  <div className="link-container">
                    <p>
                      {translate(
                        this.props.superApp.panel.learnMore.section5HeaderViewA,
                      )}
                    </p>
                    <symbols.symbol name="arrowWithStates" />
                  </div>
                </a>
              ) : null}
              {!this.props.superApp.panel.learnMore.data.viewA.helpCenterPopUp
                .isHidden ? (
                <div
                  key="helpCenterPopUp"
                  onClick={() => {
                    this.openPanel('helpCenterPopUp', 'viewA');
                    this.reportBiEvent('HelpCenter');
                  }}
                  className="image-container"
                >
                  <div className="active-layer" />
                  <img
                    src={
                      this.props.superApp.panel.learnMore.data.viewA
                        .helpCenterPopUp.image
                    }
                    alt="WixStores Help Center"
                  />

                  <div className="link-container">
                    <p>
                      {translate(
                        this.props.superApp.panel.learnMore.section1HeaderViewA,
                      )}
                    </p>
                    <symbols.symbol name="arrowWithStates" />
                  </div>
                </div>
              ) : null}
              {!this.props.superApp.panel.learnMore.data.viewA
                .supportForumsPopUp.isHidden ? (
                <a
                  key="supportForumsPopUp"
                  onClick={() => {
                    this.reportBiEvent('Forums');
                  }}
                  target="_blank"
                  href={
                    this.props.superApp.panel.learnMore.data.viewA
                      .supportForumsPopUp.link
                  }
                  className="image-container"
                >
                  <div className="active-layer" />
                  <img
                    src={
                      this.props.superApp.panel.learnMore.data.viewA
                        .supportForumsPopUp.image
                    }
                    alt="WixStores Support Forum"
                  />

                  <div className="link-container">
                    <p>
                      {translate(
                        this.props.superApp.panel.learnMore.section2HeaderViewA,
                      )}
                    </p>
                    <symbols.symbol name="arrowWithStates" />
                  </div>
                </a>
              ) : null}
              {!this.props.superApp.panel.learnMore.data.viewA.wixTipsBlog
                .isHidden ? (
                <a
                  key="wixTipsBlog"
                  onClick={() => {
                    this.reportBiEvent('FromTheWixBlog');
                  }}
                  target="_blank"
                  href={
                    this.props.superApp.panel.learnMore.data.viewA.wixTipsBlog
                      .link
                  }
                  className="image-container"
                >
                  <div className="active-layer" />
                  <img
                    src={
                      this.props.superApp.panel.learnMore.data.viewA.wixTipsBlog
                        .image
                    }
                    alt="Tips from the Wix Blog"
                  />

                  <div className="link-container">
                    <p>
                      {translate(
                        this.props.superApp.panel.learnMore.section3HeaderViewA,
                      )}
                    </p>
                    <symbols.symbol name="arrowWithStates" />
                  </div>
                </a>
              ) : null}
              {this.props.superApp.panel.learnMore.data.viewA
                .wixHireDesignPartner ? (
                <a
                  key="wixHireDesignPartner"
                  onClick={() => {
                    this.reportBiEvent('HireDesignPartner');
                  }}
                  target="_blank"
                  href={
                    this.props.superApp.panel.learnMore.data.viewA
                      .wixHireDesignPartner.link
                  }
                  className="image-container"
                >
                  <div className="active-layer" />
                  <img
                    src={
                      this.props.superApp.panel.learnMore.data.viewA
                        .wixHireDesignPartner.image
                    }
                    alt="Hire a Wix Design Partner"
                  />

                  <div className="link-container">
                    <p>
                      {translate(
                        this.props.superApp.panel.learnMore.section4HeaderViewA,
                      )}
                    </p>
                    <symbols.symbol name="arrowWithStates" />
                  </div>
                </a>
              ) : null}
              {this.props.superApp.panel.learnMore.data.viewA.helpCenterTab ? (
                <a
                  key="helpCenterTab"
                  onClick={() => {
                    this.reportBiEvent('HelpCenter');
                  }}
                  target="_blank"
                  href={
                    this.props.superApp.panel.learnMore.data.viewA.helpCenterTab
                      .link
                  }
                  className="image-container"
                >
                  <div className="active-layer" />
                  <img
                    src={
                      this.props.superApp.panel.learnMore.data.viewA
                        .helpCenterTab.image
                    }
                  />

                  <div className="link-container">
                    <p>
                      {translate(
                        this.props.superApp.panel.learnMore.section1HeaderViewA,
                      )}
                    </p>
                    <symbols.symbol name="arrowWithStates" />
                  </div>
                </a>
              ) : null}
            </div>
          ) : null}
          {this.isLearnMoreExperimentOpen() ? (
            <div key="secondView" className="second-view">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.getLearnMoreDataForViewB(), (data) => (
                <div className="section-container">
                  <div className="section-top">
                    <h4 className="title">{translate(data.title)}</h4>
                    <a href={data.link} className="see-all-link">
                      See All
                    </a>
                  </div>
                  {/* TODO: Fix this the next time the file is edited. */}
                  {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                  {_.map(data.data, (topic) => (
                    <div
                      onClick={() => {
                        this.openPanel(topic.link);
                      }}
                      className="content-wrapper"
                    >
                      <img src={topic.image} alt={``} />
                      <div className="description">{topic.description}</div>
                      <span className="arrow-wrapper">
                        <symbols.symbol
                          name="actionArrowRight"
                          className="actionArrowRight"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
        </CustomScroll>
      </div>
    );
  },
});

import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  HistoryApiKey,
  PreviewerApiKey,
  WorkspaceRightPanelApiKey,
} from '#packages/apis';
import { AiWriterPanelStore } from './aiWriterPanelStore';
import { AiWriterApi, openAiWriterPanel } from './aiWriterAPI';
import { AiWriterApiKey } from './publicApiKey';
import { WORKSPACE_RIGHT_PANEL_NAMES } from '#packages/constants';
import React from 'react';
import aiWriterPanel from './aiWriterPanel';

export class AiWriterPanelScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  store = this.declareStore(AiWriterPanelStore);
  historyAPI = this.useDependency(HistoryApiKey);
  workspaceRightPanelApi = this.useDependency(WorkspaceRightPanelApiKey);
  previewerApi = this.useDependency(PreviewerApiKey);
}

const contributeAiWriterPanel = (scope: AiWriterPanelScope) => {
  const { workspaceRightPanelApi } = scope;

  workspaceRightPanelApi.contributePanelContent(
    () => React.createElement(aiWriterPanel),
    {
      panelName: WORKSPACE_RIGHT_PANEL_NAMES.AI_PAGE_WRITER,
      tabTitle: 'edit_section_panel_tab_layout_label',
      iconName: 'image_effect_icon',
      onOpen: () => {
        openAiWriterPanel(scope);
      },
    },
  );
};

export const AiWriterPanelEntryPoint = createEntryPoint({
  name: 'AiWriterPanel',
  Scope: AiWriterPanelScope,
  publicApi({ contributeApi }) {
    contributeApi(AiWriterApiKey, AiWriterApi);
  },
  async initialize(scope: AiWriterPanelScope) {
    await scope.editorCoreAPI.hooks._dsIsReady.promise;
    contributeAiWriterPanel(scope);
  },
});

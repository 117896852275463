import { http } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';

export default async function fetchPrediction(
  metaSiteInstance: string,
  predictionInput: AnyFixMe,
) {
  const response = await http
    .fetch(
      'https://editor.wix.com/_api/predictor-service/v1/invoke/model/texttype-catboost-1-0',
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: new Headers({
          Authorization: metaSiteInstance,
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          model_id: 'texttype-catboost-1-0',
          triggered_by: 'editor',
          predictionEntities: {
            uuid: {
              entity_id: '',
            },
          },
          features: predictionInput,
        }),
      },
    )
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      ErrorReporter.captureException(error, {
        tags: {
          fetchSuggestionsBruteForceFlow: true,
        },
      });
    });

  return response;
}

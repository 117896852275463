import stripColumnsContainer from './customActions/stripColumnsContainer';
import column from './customActions/column';
import type { RCMAction } from './types';
import type { EditorAPI } from '#packages/editorAPI';

const customActions: Record<
  string,
  RCMAction[] | ((editorAPI: EditorAPI) => RCMAction[])
> = {
  'wysiwyg.viewer.components.StripColumnsContainer': stripColumnsContainer,
  'wysiwyg.viewer.components.Column': column,
};

export default customActions;

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import $ from 'zepto';
import _ from 'lodash';
import * as util from '#packages/util';
import { StageAnchorsContainer } from '#packages/anchors';
import * as dragUtils from '../../utils/mouseMoveActions/dragUtils';
import dragAttempt from '../../utils/mouseMoveActions/dragAttempt';
import lassoDrag from '../../utils/lassoMouseMoveActions/lassoDrag';
import * as stateManagement from '#packages/stateManagement';
import { isSectionsOnStageEnabled } from '#packages/sectionsOnStage';
import React, { type MouseEvent, type DragEvent } from 'react';
import { MobileStageSideArea } from '#packages/sections';
import CompControls from '../compControls/compControls';
import AttachHighlight from '../attachHighlight/attachHighlight';
import ConstraintArea from '../constraintArea/constraintArea';
import SnapLayer from '../snapLayer/snapLayer';
import HighlightsLayer from '../highlightsLayer/highlightsLayer';
import PermanentBorder from '../permanentBorder/permanentBorder';
import HoverBox from '../selectionBox/hoverBox/hoverBox';
import { AddSection } from '../addSection/AddSection';
import Overlay from '../selectionBox/overlay';
import MarginsIndicators from '../marginsIndicators/marginsIndicators';
import Lasso from '../lasso/lasso';
import { BaseDragApiKey, ImageUploadToStageApiKey } from '#packages/apis';
import {
  mapStateToProps,
  isValidCompToHover,
  mapDispatchToProps,
} from './mouseCatcherMapper';
import constants from '#packages/constants';

import type { EditorState } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

import type { SectionWithLayout } from '#packages/sections';
import type { CompRef } from 'types/documentServices';
import type { AddSectionImperativeAPI } from '../addSection/AddSection.types';
import type { Section } from '#packages/sectionsOnStage';

const { isSectionsEnabled } = util.sections;

const { translateToViewerCoordinates, getMousePosition } =
  stateManagement.domMeasurements.selectors;
const { getSelectedCompsRefs } = stateManagement.selection.selectors;

const { isInInteractionMode, inInteractionForbiddenDragComponent } =
  stateManagement.interactions.selectors;

function getDragStrategy(editorAPI: EditorAPI, compPointer: CompRef[]) {
  if (!canBeDragged(editorAPI, compPointer)) {
    return dragAttempt;
  }
  return editorAPI.host.getAPI(BaseDragApiKey);
}

const LEFT_BUTTON_ZEPTO = 1;

function canBeDragged(editorAPI: EditorAPI, selectedComp: CompRef[]) {
  const compRestriction = editorAPI.getCompDragRestrictions(selectedComp);
  return (
    compRestriction.horizontallyMovable || compRestriction.verticallyMovable
  );
}

function isMouseAboveSelectedComponents(
  editorAPI: EditorAPI,
  e: MouseEvent,
  componeneUnderMouse?: CompRef,
) {
  const compUnderMouse =
    componeneUnderMouse ||
    editorAPI.selection.getComponentUnderClickToBeSelected(e);
  return editorAPI.selection.isComponentSelected(compUnderMouse);
}

function isLassoActivated(editorAPI: EditorAPI) {
  return !!editorAPI.store.getState().lassoLayout;
}

const isMouseAboveSelectedOnInteraction = (
  editorAPI: EditorAPI,
  state: EditorState,
  compUnderMouse: CompRef,
  isMouseAboveSelected: boolean,
): boolean => {
  if (isInInteractionMode(state)) {
    const currentSelectedComponent = getSelectedCompsRefs(state)[0];
    return (
      isMouseAboveSelected ||
      (editorAPI.utils.isSameRef(
        currentSelectedComponent,
        editorAPI.components.getContainer(compUnderMouse),
      ) &&
        editorAPI.components.is.controlledByParent(compUnderMouse))
    );
  }
  return true;
};

type MouseCatcherStateProps = ReturnType<typeof mapStateToProps>;

//TYPE WAS GENERATED, remove this line when reviewed
interface MouseCatcherProps extends MouseCatcherStateProps {
  isInteractionMode: boolean;
  hoverBoxOverlay: AnyFixMe;
  siteScale: number;
  isSectionDragging: boolean;
  shouldShowSpotlightStage: boolean;
  shouldShowCompControls: boolean;
  editorIsInit: boolean;
  isCompDraggable: boolean;
  appContainer: AnyFixMe;
  selectedComponents?: AnyFixMe | Array<AnyFixMe>;
  performingMouseMoveAction?: boolean;
  isDragging?: boolean;
  isResizing?: boolean;
  isRotating?: boolean;
  isPinMode?: boolean;
  compPanel?: AnyFixMe;
  editingAreaLayout?: AnyFixMe;
  previewPosition?: AnyFixMe;
  previewMode: boolean;
  tabIndicationState: AnyFixMe;
  setHoverBox: FunctionFixMe;
  clearHoverBox: FunctionFixMe;
  calculateIsLabelBottom: FunctionFixMe;
  sectionsOnStage: Section[];
  hoveredSectionOnStageIndex: number;
  isLeftBarHighlighted: boolean;
  isStageZoomMode: boolean;
  areMouseEventsAllowed: boolean;
  currentPageSectionsLike: SectionWithLayout[];
  fixedStageEnabled: boolean;

  setIsMouseOverStage: (isMouseOverStage: boolean) => void;
  setHoveredSectionOnStageIndex: (index: number) => void;
  setHoveredSectionLike: (
    e: MouseEvent,
    compToHover: CompRef,
    currentPageSectionsLike: SectionWithLayout[],
  ) => void;
  getEditorAPI: () => EditorAPI;
  collapseLeftBarHighlightMenu: () => void;
  onContextMenu?: (ev: MouseEvent) => void;
}

interface MousePosition {
  x: number;
  y: number;
  isShiftPressed: boolean;
  isSpecialKeyPressed: boolean;
  isAltKeyPressed: boolean;
}

export interface Point {
  x: number;
  y: number;
}

class MouseCatcher extends React.Component<MouseCatcherProps> {
  static displayName = 'mouseCatcher';
  static propTypes = {
    selectedComponents: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    config: PropTypes.object,
    onContextMenu: PropTypes.func,
    performingMouseMoveAction: PropTypes.bool,
    hoveredComp: PropTypes.object,
    isDragging: PropTypes.bool,
    isResizing: PropTypes.bool,
    isRotating: PropTypes.bool,
    isPinMode: PropTypes.bool,
    compPanel: PropTypes.object,
    editingAreaLayout: PropTypes.object,
    previewPosition: PropTypes.object,
    applyModeFromClipboardSuggestion: PropTypes.object,
    tabIndicationState: PropTypes.object.isRequired,
    constraintArea: PropTypes.object,
  };

  compsBeforeSelection: CompRef[] | null;
  shouldStartDragging: boolean;
  shouldDragAndCopy: boolean;
  shouldStartLasso: boolean;
  initMousePosition: MousePosition | null;
  isWaitingForUpdate: boolean;
  addSectionRef = React.createRef<AddSectionImperativeAPI>();

  getEditorAPI = () => this.props.getEditorAPI();

  UNSAFE_componentWillMount() {
    this.compsBeforeSelection = null;
    this.shouldStartDragging = false;
    this.shouldDragAndCopy = false;
    this.shouldStartLasso = false;
    this.initMousePosition = null;
    this.isWaitingForUpdate = false;
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).on('mouseenter', this.onMouseEnter);
  }

  componentWillUnmount() {
    $(ReactDOM.findDOMNode(this)).off('mouseenter', this.onMouseEnter);
  }

  UNSAFE_componentWillReceiveProps(nextProps: MouseCatcherProps) {
    const editorAPI = this.getEditorAPI();
    const { hoveredComp } = this.props;

    if (this.shouldStartDragging) {
      const compsToBeDragged = dragUtils.getCompToBeDragged(
        editorAPI,
        nextProps.selectedComponents,
      );
      const dragStrategy =
        !_.isEmpty(compsToBeDragged) &&
        getDragStrategy(editorAPI, compsToBeDragged);
      if (dragStrategy) {
        editorAPI.mouseActions.registerMouseMoveAction(dragStrategy, {
          initMousePosition: this.initMousePosition,
          selectedComp: compsToBeDragged,
          shouldDragAndCopy: this.shouldDragAndCopy,
        });
      }
    } else if (this.shouldStartLasso) {
      editorAPI.mouseActions.registerMouseMoveAction(lassoDrag, {
        initMousePosition: this.initMousePosition,
        prevSelectedComps: this.compsBeforeSelection,
        selectedComponents: nextProps.selectedComponents,
      });
    }

    this.shouldStartDragging = false;
    this.shouldStartLasso = false;
    this.shouldDragAndCopy = false;

    if (
      hoveredComp &&
      (!editorAPI.components.is.exist(hoveredComp) ||
        !editorAPI.components.is.visible(hoveredComp))
    ) {
      this.props.clearHoverBox();
    }
  }

  isSectionDragToStageInProgress() {
    const registeredMouseMoveAction =
      this.getEditorAPI().mouseActions.getRegisteredMouseMoveAction();

    return (
      registeredMouseMoveAction?.type ===
      constants.MOUSE_ACTION_TYPES.ADD_SECTION_PANEL_DRAG_TO_STAGE
    );
  }

  onMouseDown = (event: MouseEvent) => {
    const editorAPI = this.getEditorAPI();
    util.keyboardShortcuts.enable();
    const state = editorAPI.store.getState();

    this.initMousePosition = getMousePosition(editorAPI, event);
    this.shouldStartLasso =
      editorAPI.mouseActions.shouldInitLassoToolOnMouseDown(event);
    const compUnderMouse =
      editorAPI.selection.getComponentUnderClickToBeSelected(event);
    const isMouseAboveSelected = isMouseAboveSelectedComponents(
      editorAPI,
      event,
      compUnderMouse,
    );
    this.shouldStartDragging =
      !this.shouldStartLasso &&
      (!editorAPI.selection.isMultiSelectKeyPressed(event) ||
        isMouseAboveSelected);
    this.shouldDragAndCopy =
      this.shouldStartDragging && this.initMousePosition.isAltKeyPressed;

    if (editorAPI.selection.isHiddenComponentDraggedNext()) {
      editorAPI.selection.setIsHiddenComponentDraggedNext(false);
    } else if (!isLassoActivated(editorAPI)) {
      this.compsBeforeSelection = this.props.selectedComponents;

      if (this.props.isStageZoomMode || !this.props.areMouseEventsAllowed) {
        if (!compUnderMouse) {
          editorAPI.selection.deselectComponents();
        }
        return;
      }

      editorAPI.selection.selectComponentByClick(event);
      const isMouseAboveUpdatedSelected = isMouseAboveSelectedComponents(
        editorAPI,
        event,
        compUnderMouse,
      );
      this.shouldStartDragging =
        this.shouldStartDragging &&
        isMouseAboveSelectedOnInteraction(
          editorAPI,
          state,
          compUnderMouse,
          isMouseAboveUpdatedSelected,
        ) &&
        !inInteractionForbiddenDragComponent(editorAPI, compUnderMouse) &&
        editorAPI.selection.isComponentExplicitlySelected();
    }

    editorAPI.closeRightClickMenu();
    editorAPI.panelManager.notifyMouseDown();
  };

  handleLassoMouseDown = (event: MouseEvent) => {
    const editorAPI = this.getEditorAPI();
    this.shouldStartLasso =
      editorAPI.mouseActions.shouldInitLassoToolOnMouseDown(event);
  };

  onMouseUp = (e: MouseEvent) => {
    const editorAPI = this.getEditorAPI();

    const isRightClick = e.button === 2;

    if (!isRightClick) {
      this.deselectClickedCompIfNeeded(e);

      if (
        editorAPI.selection.getIsMouseUpSelectionEnabled() &&
        !isLassoActivated(editorAPI) &&
        !this.props.isStageZoomMode &&
        this.props.areMouseEventsAllowed
      ) {
        editorAPI.selection.selectNonGroupCompUnderCursor(
          e,
          this.props.siteScale,
        );
      }
    }

    this.updateMousePosition(e);
    editorAPI.selection.setIsMouseUpSelectionEnabled(false);
    this.initMousePosition = null;
    this.shouldStartDragging = false;
    this.shouldDragAndCopy = false;
    this.shouldStartLasso = false;
  };

  onMouseMove = (event: MouseEvent) => {
    const editorAPI = this.getEditorAPI();
    const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);

    if (!editorAPI.mouseActions.getRegisteredMouseMoveAction()) {
      this.updateHoverBox(event);
    }

    editorAPI.cursor.updateMousePosition(
      mouseCoordinates.pageX,
      mouseCoordinates.pageY,
    );
  };

  onMouseEnter = (e: MouseEvent) => {
    //@ts-expect-error
    if (e.which !== LEFT_BUTTON_ZEPTO) {
      this.getEditorAPI().endMouseMoveActionIfNeeded(e);
    }
  };

  updateHoverBox = (e: MouseEvent) => {
    if (this.props.isReadonlyMode || this.isWaitingForUpdate) {
      return;
    }
    this.isWaitingForUpdate = true;

    const editorAPI = this.getEditorAPI();
    e.persist();

    editorAPI.dsActions.waitForChangesApplied(() => {
      this.isWaitingForUpdate = false;

      const hoveredComp = editorAPI.selection.getComponentToBeMarkedByHoverBox(
        e,
        this.shouldStartDragging,
      );

      const { hoverBoxOverlay } = this.props; //TODO move

      if (!this.props.hoveredComp && hoveredComp) {
        if (isValidCompToHover(editorAPI, hoveredComp)) {
          this.props.setHoverBox(hoveredComp, hoverBoxOverlay);
        }
      } else if (this.props.hoveredComp && !hoveredComp) {
        this.props.clearHoverBox();
      } else if (this.props.hoveredComp && hoveredComp) {
        if (!isValidCompToHover(editorAPI, hoveredComp)) {
          this.props.clearHoverBox();
        } else if (this.props.hoveredComp.id !== hoveredComp.id) {
          this.props.setHoverBox(hoveredComp, hoverBoxOverlay);
        }
      }

      if (isSectionsOnStageEnabled() || isSectionsEnabled()) {
        if (isSectionsOnStageEnabled()) {
          this.updateHoveredSectionOnStage(e);
        }
        this.updateMousePosition(e);
      }

      if (isSectionsEnabled()) {
        this.props.setHoveredSectionLike(
          e,
          hoveredComp,
          this.props.currentPageSectionsLike,
        );
      }
    });
  };

  updateHoveredSectionOnStage = (event: React.MouseEvent) => {
    const {
      sectionsOnStage,
      hoveredSectionOnStageIndex,
      setHoveredSectionOnStageIndex,
    } = this.props;
    const editorAPI = this.getEditorAPI();

    const { pageY } = translateToViewerCoordinates(editorAPI, event);

    const newHoveredSectionIndex = sectionsOnStage.findIndex(
      ({ top, bottom }) => top <= pageY && pageY <= bottom,
    );
    if (newHoveredSectionIndex !== hoveredSectionOnStageIndex) {
      setHoveredSectionOnStageIndex(newHoveredSectionIndex);
    }
  };

  updateMousePosition = (event: MouseEvent) => {
    const ref = this.addSectionRef.current;

    if (!ref) {
      return;
    }

    const editorAPI = this.getEditorAPI();
    const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
    ref.setMousePosition({
      x: mouseCoordinates.pageX,
      y: mouseCoordinates.pageY,
    });
  };

  handleMouseEnter = () => {
    this.props.setIsMouseOverStage(true);
  };

  handleMouseLeave = () => {
    this.clearHoverBox();
    this.props.setIsMouseOverStage(false);
  };

  clearHoverBox = () => {
    // TODO: don’t call while drag @Vadym
    this.props.clearHoverBox();
  };

  deselectClickedCompIfNeeded = (event: MouseEvent) => {
    const editorAPI = this.getEditorAPI();

    if (
      editorAPI.selection.isMultiSelectKeyPressed(event) &&
      this.initMousePosition
    ) {
      const clickedComp =
        editorAPI.selection.getComponentUnderClickToBeSelected(event);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const wasCompSelectedBeforeClick = !!_.find(
        this.compsBeforeSelection,
        clickedComp,
      );
      const mouseCoordinates = translateToViewerCoordinates(editorAPI, event);
      const wasMouseStill = !this.isPointsDistanceBiggerThan(
        this.initMousePosition,
        {
          x: mouseCoordinates.pageX,
          y: mouseCoordinates.pageY,
        },
        2,
      );
      if (wasCompSelectedBeforeClick && wasMouseStill) {
        editorAPI.selection.setIsMouseUpSelectionEnabled(false);
        editorAPI.selection.deselectComponents(clickedComp);
      }
    }
  };

  isPointsDistanceBiggerThan = (
    point1: Point,
    point2: Point,
    distance: number,
  ) => {
    const pointsDistanceSquare =
      Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2);
    return Math.sqrt(pointsDistanceSquare) > distance;
  };

  onDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  onDrop = (e: DragEvent<HTMLDivElement>) => {
    if (e.dataTransfer.files.length > 0) {
      const editorAPI = this.getEditorAPI();

      const sectionRef = editorAPI.sections.getHoveredSectionLike();
      editorAPI.selection.selectComponentByCompRef(sectionRef);

      editorAPI.host
        .getAPI(ImageUploadToStageApiKey)
        .handleDraggedFiles(editorAPI, e.dataTransfer.files, sectionRef);

      e.preventDefault();
    }
  };

  renderLasso = () => {
    if (this.props.isReadonlyMode) {
      return null;
    }

    if (this.props.fixedStageEnabled) {
      return (
        <div
          className="lassoWrapper"
          onMouseDown={this.handleLassoMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseUp={this.onMouseUp}
        >
          <Lasso />
        </div>
      );
    }

    return <Lasso />;
  };

  render() {
    return (
      <div
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}
        onContextMenu={this.props.onContextMenu}
        onMouseMove={this.onMouseMove}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className={util.cx({
          parentSizeAbs: true,
          mouseCatcher: true,
          draggable: this.props.isCompDraggable,
        })}
        onDragOver={this.onDragOver}
        onDrop={this.onDrop}
      >
        {this.props.MouseCatcherComponents.map((slot) => (
          <slot.contribution key={slot.uniqueId} />
        ))}

        <Overlay hoveredComp={this.props.hoveredComp} />
        {this.props.constraintArea ? (
          <ConstraintArea
            key="constraintArea"
            area={this.props.constraintArea}
          />
        ) : null}
        {!this.isSectionDragToStageInProgress() && <AttachHighlight />}
        {this.renderLasso()}
        {!this.props.isReadonlyMode && <HoverBox />}
        {!this.props.isReadonlyMode && isSectionsOnStageEnabled() && (
          <AddSection ref={this.addSectionRef} />
        )}

        {!this.props.isInteractionMode && <HighlightsLayer />}
        <PermanentBorder />
        <MobileStageSideArea />
        {this.props.shouldShowCompControls ? (
          <CompControls
            key="compControls"
            isDragging={this.props.isDragging}
            isResizing={this.props.isResizing}
            isRotating={this.props.isRotating}
            performingMouseMoveAction={this.props.performingMouseMoveAction}
            hoveredComp={this.props.hoveredComp}
            clearHoverBox={this.clearHoverBox}
            panel={this.props.compPanel}
            previewPosition={this.props.previewPosition}
            siteScale={this.props.siteScale}
            isPinMode={this.props.isPinMode}
            onContextMenu={this.props.onContextMenu}
            isSectionDragging={this.props.isSectionDragging}
            appContainer={this.props.appContainer}
            tabIndicationState={this.props.tabIndicationState}
            applyModeFromClipboardSuggestion={
              this.props.applyModeFromClipboardSuggestion
            }
            isInteractionMode={this.props.isInteractionMode}
          />
        ) : null}
        {!this.isSectionDragToStageInProgress() && (
          <SnapLayer key="snapLayer" />
        )}
        {!this.props.previewMode &&
        this.props.editorIsInit &&
        !this.props.isStageZoomMode &&
        !this.props.isPinMode &&
        !this.props.shouldShowSpotlightStage ? (
          <StageAnchorsContainer
            key="anchorsContainer"
            onContextMenu={this.props.onContextMenu}
          />
        ) : null}
        <MarginsIndicators />
      </div>
    );
  }
}

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MouseCatcher as AnyFixMe);

ConnectedComponent.pure = MouseCatcher;

export default ConnectedComponent;

import type { DocumentServicesObject } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import constants from '#packages/constants';
import { site as siteUserPreferences } from '../editor/userPreferences/userPreferences';

const EDITOR_APP_ID = 'editorAppId';
const { ADVANCED_SETTINGS } = constants.USER_PREFS.ACCESSIBILITY;

export const createAPI = (
  documentServices: DocumentServicesObject,
  editorAPI: EditorAPI,
) => {
  const setAdvancedSettings = (value: boolean) => {
    return siteUserPreferences.set(
      editorAPI,
      { appDefinitionId: EDITOR_APP_ID },
      'token',
      {
        [ADVANCED_SETTINGS]: value,
      },
    );
  };

  const getAdvancedSettings = () => {
    return siteUserPreferences
      .get(editorAPI, { appDefinitionId: EDITOR_APP_ID }, 'token', [
        ADVANCED_SETTINGS,
      ])
      .then((results) => (results as AnyFixMe)[ADVANCED_SETTINGS]);
  };

  const hostAPIs = {
    setAdvancedSettings,
    getAdvancedSettings,
  };

  return hostAPIs;
};

import { biLogger } from '#packages/util';
import { mediaStudioUtils } from '#packages/addPanelInfra';
import { addMenuImageSectionButtonClick } from '@wix/bi-logger-editor/v2';

import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import type { PhotoStudioOptions } from '@wix/add-panel-common';
import type { ActionSectionItem } from '@wix/add-panel-component';
import type {
  Dispatch,
  StateMapperArgs,
  MapDispatchToProps,
} from 'types/redux';

export interface ImageMapperProps {
  openPhotoStudio: (
    item: ActionSectionItem,
    options: PhotoStudioOptions,
  ) => void;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const imageMapper: MapDispatchToProps<ImageMapperProps, any> = (
  dispatch: Dispatch,
) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openPhotoStudio: (
      { biItemName }: ActionSectionItem,
      { mode }: PhotoStudioOptions,
    ) => {
      biLogger.report(
        addMenuImageSectionButtonClick({
          section: biItemName,
        }),
      );
      editorAPI.mediaServices.openMediaStudio(
        {
          mode,
          initiator: 'editor-add-panel',
        },
        mediaStudioUtils.createAddImageToStageCallback(
          editorAPI,
          biItemName,
          'addPanel',
        ),
      );
    },
  };
};

import { BasePublicApi } from '#packages/apilib';
import { sections } from '#packages/util';
import {
  DEFAULT_WORKSPACE_MODE,
  DESKTOP,
  MOBILE,
  MODES_TO_SCALE_MAP,
  NO_ZOOM_SCALE,
  WorkspaceModes,
} from './consts';
import {
  exitDevModeIfNeeded,
  getModeFromLocalStorage,
  setSiteScale,
  updateLocalStorage,
  validateMode,
} from './utils';
import {
  reportModeChange,
  reportModeChangeSuccess,
  setBiDefaults,
} from './workspaceModesBi';
import experiment from 'experiment';

import type { WorkspaceModesScope } from './workspaceModesEntryPoint';

export const switchToFullMode = (
  scope: WorkspaceModesScope,
  origin?: string,
) => {
  setMode(scope, WorkspaceModes.FULL, origin);
};

const setModeInternal = (scope: WorkspaceModesScope, mode: WorkspaceModes) => {
  validateMode(mode);
  exitDevModeIfNeeded(scope);
  updateLocalStorage(mode);
  scope.store.setActiveWorkspaceMode(mode);
  setSiteScale(scope, mode, true);
  setBiDefaults(mode);
};

export const setMode = (
  scope: WorkspaceModesScope,
  mode: WorkspaceModes,
  origin: string,
) => {
  if (isMode(scope, mode)) {
    return;
  }
  reportModeChange(mode, origin);
  setModeInternal(scope, mode);
  reportModeChangeSuccess(mode, origin);
};

export const initWorkspaceMode = (scope: WorkspaceModesScope) => {
  let activeMode = getModeFromLocalStorage();
  if (!activeMode) {
    const isNewUser = !scope.editorAPI.dsRead.account.hasSites();
    activeMode = isNewUser ? WorkspaceModes.LITE : DEFAULT_WORKSPACE_MODE;
  }
  setBiDefaults(activeMode);
  setModeInternal(scope, activeMode);
  scope.editorCoreAPI.hooks.workspaceModesInit.resolve();
};

export const getMode = (scope: WorkspaceModesScope): WorkspaceModes => {
  if (isWorkspaceModesAvailable(scope)) {
    return scope.store.getActiveWorkspaceMode() ?? DEFAULT_WORKSPACE_MODE;
  }
  return DEFAULT_WORKSPACE_MODE;
};

export const isMode = (
  scope: WorkspaceModesScope,
  mode: WorkspaceModes,
): boolean => {
  validateMode(mode);
  return getMode(scope) === mode;
};

const getWorkspaceModeDefaultScale = (scope: WorkspaceModesScope): number => {
  if (isWorkspaceModesAvailable(scope)) {
    const mode = getMode(scope);
    const device = scope.editorAPI.isMobileEditor() ? MOBILE : DESKTOP;
    return MODES_TO_SCALE_MAP[device][mode];
  }
  return NO_ZOOM_SCALE;
};

const setSelectionInterceptorEnabled = (
  { store }: WorkspaceModesScope,
  enabled: boolean = true,
) => {
  store.setSelectionInterceptorEnabled(enabled);
};

export const isWorkspaceModesAvailable = (
  _scope: WorkspaceModesScope,
): boolean => {
  return experiment.isOpen('se_liteEditor') && sections.isSectionsEnabled();
};

export class WorkspaceModesApi extends BasePublicApi<WorkspaceModesScope> {
  setMode = this.bindScope(setMode);
  getMode = this.bindScope(getMode);
  isMode = this.bindScope(isMode);
  isWorkspaceModesAvailable = this.bindScope(isWorkspaceModesAvailable);
  getWorkspaceModeDefaultScale = this.bindScope(getWorkspaceModeDefaultScale);
  switchToFullMode = this.bindScope(switchToFullMode);
  setSelectionInterceptorEnabled = this.bindScope(
    setSelectionInterceptorEnabled,
  );
}

import * as util from '#packages/util';
import styles from './addSectionThumbnail.scss';
import React, { useState, useRef } from 'react';
import { calcRelativeHeight } from '../../addPresetUtil';
import { Tooltip, Text } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import { ADD_SECTION_PANEL_CONTENT_WIDTH } from '../../addPresetConsts';
import ThumbnailDragBox from '../../previewDrag/thumbnailDragBox';
import { SavedSectionCategory } from '../consts';
import { VideoBold } from '@wix/wix-ui-icons-common/classic-editor';

import type { Point } from '#packages/rEditor';
import type { WithStartItemDrag } from '#packages/util';
import type { DraggableItem } from '#packages/addPanelInfra';
import type { CompRef, CompStructure } from 'types/documentServices';
import type {
  SectionPresetDefinition,
  SectionCategoryDefinition,
  ExtraPreviewProps,
} from '../../types';
import type { AddSectionPanelDispatchProps } from '../addSectionPanelMapper';
import type { ThumbnailDragBoxProps } from '../../previewDrag/thumbnailDragBox';
import { AddSectionThumbnailOverlay } from './addSectionThumbnailOverlay';
import { AddSectionContextMenu } from './addSectionContextMenu';

interface AddSectionThumbnailProps extends WithStartItemDrag {
  categoryDefinition: SectionCategoryDefinition;
  sectionDefinition: SectionPresetDefinition;
  onSectionClick: AddSectionPanelDispatchProps['onSectionClick'];
  onSectionAddFromContextMenu: AddSectionPanelDispatchProps['onSectionAddFromContextMenu'];
  onSectionDragEnd: AddSectionPanelDispatchProps['onSectionDragEnd'];
  getBlankSectionStructure: AddSectionPanelDispatchProps['getBlankSectionStructure'];
  stageEntryIndex: number;
  isAppInstallRequired: boolean;
  appName: string;
  isPresetReady: boolean;
  renderPreview: (props: ExtraPreviewProps) => JSX.Element;
  compStructureToAdd?: CompStructure | null;
}

const getCategoryThumbnailBorderWidth = (
  category: SectionCategoryDefinition,
): number => (category.id === SavedSectionCategory.id ? 6 : 0);

const stopPropagation = (e: React.MouseEvent) => e.stopPropagation();

const AddSectionThumbnail = ({
  categoryDefinition,
  startItemDrag,
  onSectionClick,
  onSectionAddFromContextMenu,
  onSectionDragEnd,
  sectionDefinition,
  getBlankSectionStructure,
  stageEntryIndex,
  isAppInstallRequired,
  appName,
  isPresetReady,
  renderPreview,
  compStructureToAdd,
}: AddSectionThumbnailProps) => {
  const { title, presetJsonUrl, previewHeight, isVideo, originalPresetWidth } =
    sectionDefinition;
  const [isDragging, setIsDragging] = useState(false);
  const [isAddingSection, setIsAddingSection] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const stageEntryIndexRef = useRef(stageEntryIndex);
  const stageEntryIndexOnDropRef = useRef(0);
  const thumbnailRef = useRef<HTMLDivElement>(null);

  const thumbnailBorderWidth =
    getCategoryThumbnailBorderWidth(categoryDefinition);

  const displayWidth =
    ADD_SECTION_PANEL_CONTENT_WIDTH - thumbnailBorderWidth * 2;

  const height = calcRelativeHeight(
    previewHeight,
    displayWidth,
    originalPresetWidth,
  );

  stageEntryIndexRef.current = stageEntryIndex;

  const dragItem = (e: React.MouseEvent) => {
    if (isAddingSection) {
      return;
    }
    setIsDragging(false);
    const boxSize = {
      height: Math.floor(height),
      width: ADD_SECTION_PANEL_CONTENT_WIDTH,
    };
    let structurePromise: Promise<Response> | null = null;
    const thumbnailBoundingBox = thumbnailRef.current.getBoundingClientRect();
    startItemDrag(e, {
      overrideDragBoxEl: function HtmlThumbnailDragBoxWrapper(
        props: ThumbnailDragBoxProps,
      ) {
        return (
          <ThumbnailDragBox
            {...props}
            isDragging={isDragging}
            setIsActive={setIsActive}
            setIsAddingSection={setIsAddingSection}
            isAddingSection={isAddingSection}
            height={'auto'}
            width={displayWidth}
            renderPreview={renderPreview}
          />
        );
      },
      itemId: title,
      section: categoryDefinition.id,
      structure: getBlankSectionStructure({ ...boxSize, y: 0 }),
      disableFullWidthPlaceholder: true,
      getBoxSize: () => boxSize,
      onItemDrag: () => {
        setIsDragging(true);
        if (!structurePromise) {
          structurePromise = fetch(presetJsonUrl);
        }

        stageEntryIndexOnDropRef.current = stageEntryIndexRef.current;
      },
      onDrop: (point: Point, item: DraggableItem, attachCandidate: CompRef) => {
        onSectionDragEnd(
          attachCandidate,
          structurePromise,
          point,
          categoryDefinition,
          sectionDefinition,
          stageEntryIndexOnDropRef.current,
          compStructureToAdd,
        );
      },
      rect: {
        ...boxSize,
        // @ts-expect-error
        left: thumbnailBoundingBox.left,
        top: thumbnailBoundingBox.top,
      },
    });
  };

  const handleSectionClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isDragging || isAddingSection) {
      return;
    }
    setIsAddingSection(true);
    onSectionClick(
      sectionDefinition,
      categoryDefinition,
      stageEntryIndex,
      compStructureToAdd,
    );
  };

  const handleContextMenuAdd = (applyTargetTheme: boolean) => {
    if (isDragging || isAddingSection) {
      return;
    }
    setIsAddingSection(true);
    onSectionAddFromContextMenu(
      sectionDefinition,
      categoryDefinition,
      stageEntryIndex,
      applyTargetTheme,
      compStructureToAdd,
    );
  };

  const handleContextMenuToggle = (isOpen: boolean) => {
    setIsActive(isOpen);
  };

  const isSavedSectionCategory =
    categoryDefinition.id === SavedSectionCategory.id;

  const buttonContentTranslated = translate(
    'add_section_preset_vertical_uninstalled_preset_button',
    { app_name: appName },
  );

  const previewProps: ExtraPreviewProps = {
    isDragging,
    setIsActive,
    isAddingSection,
    setIsAddingSection,
    height,
    width: displayWidth,
  };

  return (
    <Tooltip
      content={
        isVideo
          ? translate('section_add_section_video_tooltip')
          : translate('add_section_tooltip_drag')
      }
      shouldTranslate={false}
      openDelay={300}
      positionByMouse
      alignment="TOP"
      disabled={isAppInstallRequired || isSavedSectionCategory} // TODO: fix saved sections tooltip behaviour when a context menu is opened
      dataHook="drag-and-drop-tooltip"
      className={styles.container}
    >
      <div
        className={util.cx(styles.addSectionThumbnail, {
          complete: isPresetReady,
          [styles.active]: isActive,
          [styles.savedSectionItem]: isSavedSectionCategory,
          [styles.hover]: isHovered,
        })}
        style={
          {
            '--borderWidth': `${thumbnailBorderWidth}px`,
          } as React.CSSProperties
        }
        onMouseDown={dragItem}
        ref={thumbnailRef}
        onClick={handleSectionClick}
        data-aid="add-section-thumbnail"
        data-section-name={sectionDefinition.sectionTitleKey}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isAppInstallRequired && (
          <AddSectionThumbnailOverlay
            isHovered={isHovered}
            buttonContentTranslated={buttonContentTranslated}
            handleSectionClick={handleSectionClick}
          />
        )}
        {renderPreview(previewProps)}

        {isVideo ? (
          <div className={styles.videoIcon}>
            <VideoBold />
          </div>
        ) : null}

        {isSavedSectionCategory && (
          <div
            className={styles.contextMenuWrapper}
            // we have a click handler to add a section, so this allows us not to add a section when a user clicks on a context menu
            onClick={stopPropagation}
            // the same, but the drag handler
            onMouseDown={stopPropagation}
          >
            <AddSectionContextMenu
              sectionDefinition={sectionDefinition}
              addSection={handleContextMenuAdd}
              onToggle={handleContextMenuToggle}
            />
          </div>
        )}

        {sectionDefinition.sectionTitleKey &&
          (isSavedSectionCategory ? (
            <div className={styles.addSectionThumbnailTitleTop}>
              <Text
                size="small"
                weight="thin"
                children={util.translationUtils.translateIfNeeded(
                  sectionDefinition.sectionTitleKey,
                )}
                shouldTranslate={false}
                dataHook="add-section-thumbnail-text"
              />
            </div>
          ) : (
            <div className={styles.addSectionThumbnailTitleBottom}>
              <Text
                skin="secondary"
                size="tiny"
                weight="thin"
                children={util.translationUtils.translateIfNeeded(
                  sectionDefinition.sectionTitleKey,
                )}
                shouldTranslate={false}
                dataHook="add-section-thumbnail-text"
              />
            </div>
          ))}
      </div>
    </Tooltip>
  );
};

export default AddSectionThumbnail;

// @ts-nocheck
import React from 'react';
import { Composites, TextLabel, InfoIcon } from '@wix/wix-base-ui';
import { WixBaseUISliderWithBI } from '#packages/baseUI';

export default class extends React.Component {
  static displayName = 'SliderCustomization';

  getMinValue = () => {
    return parseInt(this.props.minVal, 10);
  };

  getMaxValue = () => {
    return parseInt(this.props.maxVal, 10);
  };

  render() {
    <Composites.SliderLabeled>
      <InfoIcon text={this.props.infoText} />

      <TextLabel value={this.props.label} />

      <WixBaseUISliderWithBI
        value={this.props.valueLink.value}
        onChange={this.props.valueLink.requestChange}
        min={this.getMinValue()}
        max={this.getMaxValue()}
        step={this.props.step}
        inputMin={this.getMinValue()}
        inputMax={this.getMaxValue()}
        label={this.props.label}
      />
    </Composites.SliderLabeled>;
  }
}

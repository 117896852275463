import React from 'react';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import {
  ContextMenu,
  ContextMenuAction,
  Symbol as UISymbol,
  Divider,
} from '@wix/wix-base-ui';

import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import { cx, biLogger } from '#packages/util';
import {
  myElementsDeleteFromLibrary,
  myElementsConfirmDeletionFromLibrary,
  myDesignsRenamePresetClick,
  myDesignsRenamePresetSave,
} from '@wix/bi-logger-editor/v2';
import { connectWithScope } from '#packages/apilib';

import { AddPresetScope } from '../../addPresetApiEntry';
import { renameSavedSection, removeSavedSection } from '../../addPresetApi';

import styles from './addSectionContextMenu.scss';

import type { SectionPresetDefinition } from '../../types';
import type { EditorAPI } from '#packages/editorAPI';

interface ContextMenuActionType {
  id: string;
  name: string;
  icon: string;
  onClick: () => void;
}

interface ContextMenuDivider {
  isDivider: true;
}

type ContextMenuItem = ContextMenuActionType | ContextMenuDivider;

interface AddSectionContextMenuOwnProps {
  sectionDefinition: SectionPresetDefinition;
  onToggle: (newValue: boolean) => void;
  addSection: (shouldApplyOriginTheme: boolean) => void;
}

interface AddSectionContextMenuDispatchProps {
  renameSection: () => void;
  removeSection: () => void;
}

interface AddSectionContextMenuProps
  extends AddSectionContextMenuOwnProps,
    AddSectionContextMenuDispatchProps {}

const AddSectionContextMenuComponent: React.FC<AddSectionContextMenuProps> = ({
  addSection,
  removeSection,
  renameSection,
  onToggle,
}) => {
  const actions: ContextMenuItem[] = [
    {
      id: 'add-section-context-menu-add',
      icon: 'addAction',
      name: 'add_section_saved_sections_section_context_menu_add_label',
      onClick: () => addSection(true),
    },
    {
      id: 'add-section-context-menu-add-with-theme',
      icon: 'applyTo',
      name: 'add_section_saved_sections_section_context_menu_add_themed_label',
      onClick: () => addSection(false),
    },
    {
      id: 'add-section-context-menu-rename',
      icon: 'renameAction',
      name: 'add_section_saved_sections_section_context_menu_rename_label',
      onClick: renameSection,
    },
    {
      isDivider: true,
    },
    {
      id: 'add-section-context-menu-delete',
      icon: 'deleteAction',
      name: 'add_section_saved_sections_section_context_menu_delete_label',
      onClick: removeSection,
    },
  ];

  return (
    <ContextMenu
      onToggle={onToggle}
      className={cx(styles.contextMenu, 'flat')}
      dataHook="add-section-item-context-menu"
    >
      {actions.map((action, i) => {
        if ('isDivider' in action) {
          return <Divider key={`divider_${i}`} />;
        }

        return (
          <ContextMenuAction
            key={action.id}
            onClick={action.onClick}
            dataHook={action.id}
          >
            <UISymbol>
              <Symbol className={styles.contextMenuSymbol} name={action.icon} />
            </UISymbol>
            <span>{translate(action.name)}</span>
          </ContextMenuAction>
        );
      })}
    </ContextMenu>
  );
};

const mapDispatchToProps = (
  scope: AddPresetScope,
  { sectionDefinition }: AddSectionContextMenuOwnProps,
): AddSectionContextMenuDispatchProps => {
  const editorAPI: EditorAPI = scope.editorAPI;

  return {
    renameSection: () => {
      editorAPI.panelManager.openPanel(
        constants.PANELS.SAVED_COMPONENTS.RENAME_COMPONENT_PANEL_NAME,
        {
          name: sectionDefinition.sectionTitleKey,
          onConfirm: (newName: string) => {
            renameSavedSection(scope, sectionDefinition._id, newName).then(
              () => {
                biLogger.report(
                  myDesignsRenamePresetSave({
                    element_id: sectionDefinition._id,
                    element_name: newName,
                    content_type: constants.COMP_TYPES.SECTION,
                  }),
                );
              },
            );
          },
          translations: {
            panelTitle: 'add_section_saved_sections_rename_modal_header',
            confirmLabel: 'add_section_saved_sections_rename_modal_main_cta',
            cancelLabel:
              'add_section_saved_sections_rename_modal_secondary_cta',
            inputLabel: 'add_section_saved_sections_rename_modal_text',
            inputPlaceholder:
              'add_section_saved_sections_rename_modal_placeholder',
            inputInvalidMessage:
              'add_section_saved_sections_rename_modal_validation_error',
          },
        },
        true,
      );
      biLogger.report(
        myDesignsRenamePresetClick({
          content_type: constants.COMP_TYPES.SECTION,
          element_name: sectionDefinition.sectionTitleKey,
        }),
      );
    },
    removeSection: () => {
      editorAPI.panelManager.openPanel(
        constants.PANELS.SAVED_COMPONENTS.DELETE_COMPONENT_PANEL_NAME,
        {
          name: sectionDefinition.sectionTitleKey,
          onConfirm: () =>
            removeSavedSection(scope, sectionDefinition._id)
              .then(() =>
                biLogger.report(
                  myElementsConfirmDeletionFromLibrary({
                    content_type: constants.COMP_TYPES.SECTION,
                    element_id: sectionDefinition._id,
                    success: true,
                  }),
                ),
              )
              .catch((err) =>
                biLogger.report(
                  myElementsConfirmDeletionFromLibrary({
                    content_type: constants.COMP_TYPES.SECTION,
                    element_id: sectionDefinition._id,
                    success: false,
                    error_msg: err?.toString?.() || err,
                  }),
                ),
              ),
          translations: {
            panelTitle: 'add_section_saved_sections_delete_modal_title',
            confirmLabel: 'add_section_saved_sections_delete_modal_main_cta',
            cancelLabel:
              'add_section_saved_sections_delete_modal_secondary_cta',
            paragraph1: 'add_section_saved_sections_delete_modal_body',
            paragraph2: 'add_section_saved_sections_delete_modal_body2',
          },
        },
        true,
      );

      biLogger.report(
        myElementsDeleteFromLibrary({
          content_type: constants.COMP_TYPES.SECTION,
          element_id: sectionDefinition._id,
        }),
      );
    },
  };
};

export const AddSectionContextMenu = connectWithScope(
  () => AddPresetScope,
  AddSectionContextMenuComponent,
  undefined,
  mapDispatchToProps,
);

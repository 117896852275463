// @ts-nocheck
import createReactClass from 'create-react-class';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import experiment from 'experiment';
import { Tooltip, InfoIcon } from '@wix/wix-base-ui';

import * as utils from '@wix/santa-editor-utils';

import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import {
  PALETTE_SEQUENCES,
  PALETTE_COLORS,
  isNewColorPaletteOpen,
  utils as themeUtils,
} from '#packages/theme';

import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import * as higherOrderComponents from '#packages/higherOrderComponents';
import constants from '#packages/constants';
import * as baseUI from '#packages/baseUI';
import { fontUtils } from '#packages/textControls';

import colorPickerConstants from '../constants';
import { ToolPanelFrame } from '../../../frames';
import SiteCustomColors from '../siteCustomColors';
import { TRANSLATIONS } from './TRANSLATIONS';
import {
  NewWorkspaceColorPickerPanelContentHeader,
  ColorPickerPanelContentHeader,
} from './colorPickerPanelContent';

const isNewWorkspace = util.workspace.isNewWorkspaceEnabled();

function mainContentTemplate() {
  const ContentHeader = isNewWorkspace
    ? NewWorkspaceColorPickerPanelContentHeader
    : ColorPickerPanelContentHeader;

  return (
    <div className="main-content">
      <ContentHeader
        onMouseDown={this.startContentDrag}
        onClose={this.close}
        title={translate(TRANSLATIONS.HEADER)}
      />
      <div className="choose-color-content">
        <div className="panel-section site-colors-section">
          <div className="section-left">
            {translate(TRANSLATIONS.THEME_TITLE)}
          </div>
          <div className="section-gap" />
          {this.isPaletteChangeAllowed() ? (
            <Tooltip
              shouldTranslate={false}
              alignment="RIGHT"
              content={translate(TRANSLATIONS.EDIT_THEME_TOOLTIP)}
              marginRight={6}
              marginLeft={6}
            >
              <div className="section-right">
                <a onClick={this.handleChangeColorButtonClick}>
                  {this.props.changePaletteLabel ||
                    translate(TRANSLATIONS.EDIT_THEME_LINK)}
                </a>
              </div>
            </Tooltip>
          ) : null}
        </div>

        <baseUI.paletteDisplayer
          palette={this.getPaletteColors()}
          isSelectable={true}
          onPreview={this.previewColor}
          value={this.getColorInHex(this.state.selectedColor)}
          onChange={this.selectColor}
          className="panel-section palette-displayer-wrapper"
        />

        <div className="panel-section custom-colors-header">
          <span className="custom-colors-title-section">
            {translate(TRANSLATIONS.CUSTOM_COLORS_TITLE)}
          </span>
          <InfoIcon
            text={translate(TRANSLATIONS.CUSTOM_COLORS_TOOLTIP)}
            shouldTranslate={false}
            alignment="RIGHT"
            onTooltipOpen={this.handleCustomColorsTooltipOpen}
          />
        </div>

        <SiteCustomColors
          customColors={this.siteCustomColors}
          isEditable={true}
          onPreview={this.previewColor}
          paletteColors={this.state.paletteColors}
          openAddColorPanel={this.openAddColorPanel}
          valueLink={{
            value: this.getColorInHex(this.state.selectedColor),
            requestChange: this.selectColor,
          }}
          origin={colorPickerConstants.ORIGIN.COLOR_PICKER}
          className="custom-colors-section"
        />

        <div className="panel-section footer">
          <div className="section-left">
            <span>+</span>
            <a onClick={this.addColorLinkClicked}>
              {translate(TRANSLATIONS.ADD_CUSTOM_COLOR_LINK)}
            </a>
          </div>
          <div className="section-right">
            {this.isThemeColor(this.state.colorToPreview)
              ? this.getPaletteColors()[this.state.colorToPreview]
              : this.state.colorToPreview}
          </div>
        </div>
      </div>
    </div>
  );
}

const { measuring } = utils.hoc;
const { updatePanelAction, updateOrOpenPanel, openHelpCenter } =
  stateManagement.panels.actions;
const stylableEditorActions = stateManagement.stylableEditor.actions;

const componentsSelectors = stateManagement.components.selectors;

const { getStageLayout } = stateManagement.domMeasurements.selectors;

function normalizeColor(color) {
  if (!color) {
    return null;
  }

  //this is because ADI write colors with {} :( and we had to fix production..
  if (color.indexOf('{color') === 0) {
    color = color.replace('{', '').replace('}', '');
  }

  if (color === 'transparent') {
    return colorPickerConstants.NO_COLOR;
  }

  if (
    color.indexOf('#') !== 0 &&
    color.indexOf('color') !== 0 &&
    color !== colorPickerConstants.NO_COLOR
  ) {
    return color.indexOf('r') === 0 ? color : `rgba(${color})`; // normalize colors which are saved in the format of "{r}, {g}, {b}, {a}"
  }
  return color;
}

function getChooseRootColorPanelPosition(colorPickerFramePosition) {
  return {
    top: `${colorPickerFramePosition.top - window.pageYOffset + 15}px`,
    left: `${colorPickerFramePosition.left - window.pageXOffset + 204}px`,
  };
}

function getAddColorPanelPosition(panelPosition) {
  return {
    top: `${panelPosition.top - window.pageYOffset + 115}px`,
    left: `${panelPosition.left + window.pageXOffset + 48}px`,
  };
}

// eslint-disable-next-line react/prefer-es6-class
const ColorPickerPanel = createReactClass({
  displayName: 'colorPickerPanel',
  propTypes: {
    customInstanceColors: PropTypes.array,
    displayNoColor: PropTypes.bool,
    panelName: PropTypes.string,
    customCompsAddedColors: PropTypes.array,
    setCustomCompsAddedColors: PropTypes.func,
    customUserAddedColors: PropTypes.array,
    setCustomUserAddedColors: PropTypes.func,

    previewOnHover: PropTypes.bool,
    enableHistory: PropTypes.bool,

    // IMPORTANT! provide palettes in order to enable palette manipulation flow
    palettePresets: PropTypes.array,
    updateSitePalette: PropTypes.func,

    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isBlockingLayerFullScreen: PropTypes.bool,
    shouldBlockOuterScroll: PropTypes.bool,
    openHelpCenter: PropTypes.func.isRequired,
    updatePanelProps: PropTypes.func.isRequired,
    openPanel: PropTypes.func.isRequired,
    stylableEditorSetUserColors: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    value: PropTypes.any,
    onChange: PropTypes.func,
    valueLink: util.valueLink.valueLinkPropType,
    reportMeasurements: PropTypes.func.isRequired,
    measurements: PropTypes.object.isRequired,
    changePaletteLabel: PropTypes.string,
    onPaletteChange: PropTypes.func,
    openThemePanelColorTab: PropTypes.func.isRequired,
    sendBI: PropTypes.func,
  },
  mixins: [core.mixins.editorAPIMixin],
  getDefaultProps() {
    return {
      previewOnHover: true,
    };
  },
  getInitialState() {
    const color = this.getColorFromProps();
    this.fixThemeFontsColorIfNeeded(color);
    const selectedColor = this.getColorInHex(color);

    const state = {
      selectedColor,
      colorToPreview: selectedColor,
      selectedPalette: this.getEditorAPI().theme.colors.getAll(),
    };
    this.initialPalette = state.selectedPalette;
    this.siteCustomColors = this.getCustomColorsInOrderAndSaveOrder();
    return state;
  },
  componentDidMount() {
    this.mounted = true;
  },

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      paletteColors: this.getEditorAPI().theme.colors.getAll(),
    });
  },

  componentWillUnmount() {
    this.mounted = false;

    if (this.props.onClose) {
      this.props.onClose();
    }
    this.resetPreviewColor();
  },

  fixThemeFontsColorIfNeeded(color) {
    const editorAPI = this.getEditorAPI();
    let originalColor = util.valueLink.getValueFromProps(this.props);
    const isThemeColors =
      this.isThemeColor(originalColor) && this.isThemeColor(color);
    if (
      isThemeColors &&
      originalColor !== color &&
      editorAPI.theme.colors.get(color) ===
        editorAPI.theme.colors.get(originalColor)
    ) {
      color = fontUtils.toThemeColor(color);
      originalColor = fontUtils.toThemeColor(originalColor);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
      const fixedFonts = _.reduce(
        // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
        editorAPI.theme.fonts.getAll(),
        (acc, value, fontKey) => {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/replace
          acc[fontKey] = _.replace(value, originalColor, color);
          return acc;
        },
        {},
      );
      // eslint-disable-next-line @wix/santa-editor/deprecatedFontsApi
      editorAPI.theme.fonts.update(fixedFonts);
    }
  },

  getFrameContentStyle() {
    return _.pick(this.props, ['top', 'left']);
  },

  /************************** Basic Info Retrieval Methods **************************/
  getPaletteColors() {
    const palette = this.state.paletteColors;
    return _.omit(palette, 'paletteName', 'paletteTags');
  },
  getColorFromProps() {
    const editorAPI = this.getEditorAPI();
    let color = util.valueLink.getValueFromProps(this.props);
    const isTheme = this.isThemeColor(color);
    if (
      color &&
      isTheme &&
      themeUtils.isThemeColorOutOfPaletteRange(
        color,
        isNewColorPaletteOpen(editorAPI),
      )
    ) {
      const hashColor = editorAPI.theme.colors.get(color);
      color =
        PALETTE_COLORS.find(
          (paletteColor) =>
            editorAPI.theme.colors.get(paletteColor) === hashColor,
        ) || color;
    }
    return normalizeColor(color);
  },
  /************************** Selected Color Manipulations **************************/
  getContentElementOffset() {
    return (
      this.props.measurements.contentOffset ||
      this.props.measurements.initialPos ||
      {}
    );
  },
  getCustomColorsInOrderAndSaveOrder() {
    const savedColors = this.props.customCompsAddedColors || [];
    const currentColors = this.props.customUserAddedColors || [];
    let result;

    if (savedColors) {
      const intersectingColors = _.intersection(savedColors, currentColors);
      const newColors = _.difference(currentColors, savedColors);
      result = intersectingColors.concat(newColors);
    } else {
      result = currentColors;
    }

    this.props.setCustomCompsAddedColors(result);

    return result.concat(_.difference(this.props.customInstanceColors, result));
  },
  getColorBrightness(color) {
    const colorRgb = util.colors.hexToRgb(color);
    const colorHsb = util.colors.rgbToHsb(colorRgb);
    if (colorHsb.brightness >= 80 && colorHsb.saturation < 50) {
      return 'Dark';
    }
    return 'Bright';
  },
  isThemeColor(color) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(color, 'color_');
  },
  isPaletteChangeAllowed() {
    return this.props.palettePresets && !this.getEditorAPI().isMobileEditor();
  },
  getColorInHex(color) {
    const { rgbToHex } = util.colors;
    color = normalizeColor(color);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(color, 'rgba')) {
      return rgbToHex(util.colors.rgbaStringToObject(color));
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    } else if (_.includes(color, 'rgb')) {
      return rgbToHex(util.colors.rgbStringToObject(color));
    }
    return color;
  },
  getColorInOriginalFormat(color) {
    let originalValue = this.getColorFromProps();
    originalValue = normalizeColor(originalValue);
    const { hexToRgb } = util.colors;
    if (color === colorPickerConstants.NO_COLOR) {
      return color;
    }

    const colorInHex = this.isThemeColor(color)
      ? this.getPaletteColors()[color]
      : color;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(originalValue, 'rgba')) {
      const colorObj = hexToRgb(colorInHex);
      colorObj.alpha = util.colors.rgbaStringToObject(originalValue).alpha;
      return util.colors.rgbaObjectToString(colorObj);
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    } else if (_.includes(originalValue, 'rgb')) {
      return util.colors.rgbObjectToString(hexToRgb(colorInHex));
    }
    return color;
  },
  areColorsEqual(color1, color2) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (_.includes(color1, 'color') && _.includes(color2, 'color')) {
      return color1 === color2;
    }

    return this.getColorInHex(color1) === this.getColorInHex(color2);
  },

  /************************** Palettes Manipulations **************************/
  EDIT_PALETTE_LEARN_MORE_HELP_ID: '7b56b01e-1104-485d-8bcc-9690f8c9592a',
  getRootColors() {
    return _.pick(
      this.getPaletteColors(),
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      _.keys(PALETTE_SEQUENCES),
    );
  },
  getRootColorBoxClasses(color) {
    return {
      'root-color-box': true,
      'white-color': util.colors.getDistanceToWhite(color) < 3,
    };
  },
  getPaletteList() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(this.props.palettePresets, function (preset) {
      return _.omit(preset, 'paletteName', 'paletteTags');
    });
  },
  openChangePaletteColor(colorName) {
    const self = this;
    const panelPosition = getChooseRootColorPanelPosition(
      this.getContentElementOffset(),
    );
    const colorInHex = this.getPaletteColors()[colorName];

    this.props.sendBI(coreBi.events.colorPicker.EDIT_SITE_COLORS_CLICK, {
      target: colorPickerConstants.ORIGIN.SINGLE_COLOR,
      value: colorName,
    });

    const addColorPanelProps = {
      valueLink: {
        value: colorInHex,
        requestChange(color) {
          const selectedPalette = _.clone(self.state.selectedPalette);
          selectedPalette[colorName] = color;
          self.setSelectedPalette(selectedPalette);
        },
      },
      siteCustomColors: this.siteCustomColors,
      paletteColors: this.state.paletteColors,
      top: panelPosition.top,
      left: panelPosition.left,
      onPreview(color) {
        const previewedPalette = _.clone(self.state.selectedPalette);
        previewedPalette[colorName] = color;
        self.setPaletteToDocument(previewedPalette);
      },
      onCancel: this.setPaletteToDocument.bind(
        this,
        _.clone(this.state.selectedPalette),
      ),
      origin: colorPickerConstants.ORIGIN.SINGLE_COLOR,
    };
    this.props.openPanel(
      'panels.toolPanels.colorPicker.addColorPanel',
      addColorPanelProps,
      true,
    );
  },
  selectColor(color, shouldCloseDialog) {
    if (color !== this.state.selectedColor) {
      const self = this;
      this.setState(
        {
          selectedColor: color,
        },
        function () {
          self.handleChange(color, false, false);
          self.addColorHistoryEntryIfNeeded();
        },
      );
    } else if (shouldCloseDialog) {
      this.close();
    }
  },
  previewColor(color, isMouseOut) {
    if (experiment.isOpen('colorPickerChangeColorOnClickExp')) {
      return;
    }

    if (this.mounted) {
      this.setState({
        colorToPreview: color,
      });
    }

    if (this.props.previewOnHover) {
      this.handleChange(color, true, isMouseOut);
    }
  },
  handleChange(color, isHover, isMouseOut) {
    util.valueLink.callOnChangeIfExists(
      this.props,
      this.getColorInOriginalFormat(color),
      { isHover, isMouseOut },
    );
  },
  setSelectedPalette(palette) {
    if (this.mounted) {
      this.setState(
        {
          selectedPalette: palette,
        },
        this.setPaletteToDocument.bind(this, palette),
      );
    } else {
      this.setPaletteToDocument(palette);
    }
  },
  setPaletteToDocument(palette) {
    this.props.updateSitePalette(palette);
  },
  computeNewPaletteFromRootColor(colorName, newRootColor) {
    const currentPalette = this.getPaletteColors();
    const updatedColors = util.colors.createGradientColorsFromRoot(
      newRootColor,
      currentPalette,
      PALETTE_SEQUENCES[colorName],
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(currentPalette, updatedColors);
  },
  setSelectedPaletteFromList(palette, paletteIndex) {
    this.props.sendBI(
      coreBi.events.colorPicker.EDIT_SITE_COLORS_PALETTE_SELECT,
      {
        value: paletteIndex,
      },
    );

    this.setSelectedPalette(palette);
  },
  setSelectedPaletteFromRootColor(colorName, newRootColor) {
    const self = this;
    const updatedPalette = this.computeNewPaletteFromRootColor(
      colorName,
      newRootColor,
    );
    if (this.mounted) {
      this.setState(
        {
          selectedPalette: updatedPalette,
        },
        self.setPaletteToDocument.bind(this, updatedPalette),
      );
    } else {
      self.setPaletteToDocument(updatedPalette);
    }
  },
  setPaletteToDocumentFromRootColor(colorName, newRootColor) {
    const updatedPalette = this.computeNewPaletteFromRootColor(
      colorName,
      newRootColor,
    );
    this.setPaletteToDocument(updatedPalette);
  },
  addCustomColor(newCustomColor) {
    const customColorsAddedByUser =
      _.cloneDeep(this.props.customUserAddedColors) || [];
    customColorsAddedByUser.unshift(newCustomColor);
    this.props.setCustomUserAddedColors(customColorsAddedByUser);
    this.props.stylableEditorSetUserColors();
    this.selectColor(newCustomColor);
  },
  openAddColorPanel(origin) {
    const panelPosition = getAddColorPanelPosition(
      this.getContentElementOffset(),
    );

    this.props.openPanel(
      'panels.toolPanels.colorPicker.addColorPanel',
      {
        valueLink: {
          value: this.state.selectedColor,
          requestChange: this.addCustomColor,
        },
        paletteColors: this.state.paletteColors,
        onPreview: _.debounce(this.previewColor, 100),
        top: panelPosition.top,
        left: panelPosition.left,
        origin,
        onCancel: this.previewColor.bind(this, this.state.selectedColor),
      },
      true,
    );
  },
  addColorLinkClicked() {
    this.props.sendBI(coreBi.events.colorPicker.ADD_COLOR_LINK_CLICKED, {});
    this.openAddColorPanel(colorPickerConstants.ORIGIN.ADD_LINK);
  },
  openChooseRootColorPanel(color, colorName) {
    const panelPosition = getChooseRootColorPanelPosition(
      this.getContentElementOffset(),
    );

    this.props.sendBI(coreBi.events.colorPicker.EDIT_SITE_COLORS_CLICK, {
      target: colorPickerConstants.ORIGIN.COLUMN_COLOR,
      value: colorName,
    });

    this.props.openPanel(
      'panels.toolPanels.colorPicker.addColorPanel',
      {
        valueLink: {
          value: color,
          requestChange: this.setSelectedPaletteFromRootColor.bind(
            this,
            colorName,
          ),
        },
        siteCustomColors: this.siteCustomColors,
        paletteColors: this.state.paletteColors,
        top: panelPosition.top,
        left: panelPosition.left,
        onPreview: _.debounce(
          this.setPaletteToDocumentFromRootColor.bind(this, colorName),
          100,
        ),
        onCancel: this.setPaletteToDocument.bind(
          this,
          _.clone(this.state.selectedPalette),
        ),
        origin: colorPickerConstants.ORIGIN.COLUMN_COLOR,
      },
      true,
    );
  },
  handlePaletteListPreview(palette) {
    // eslint-disable-next-line @wix/santa/no-falsy-experiment
    if (!experiment.isOpen('colorPickerChangePaletteOnClickExp')) {
      this.setPaletteToDocument(palette);
    }
  },
  handleDoneEditingPaletteClicked() {
    this.props.sendBI(coreBi.events.colorPicker.EDIT_MAIN_COLOR_OK, {});

    this.addPaletteHistoryEntryIfNeeded();
    this.initialPalette = this.state.selectedPalette;
    this.setPaletteToDocument(this.state.selectedPalette); // make sure palette preview is being reset to the selected palette

    this.close();
  },
  arePalettesEqual(palette1, palette2) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const intersectedKeys = _.intersection(_.keys(palette1), _.keys(palette2));
    return _.isEqual(
      _.pick(palette1, intersectedKeys),
      _.pick(palette2, intersectedKeys),
    );
  },

  /************************** General panel methods **************************/
  addPaletteHistoryEntryIfNeeded() {
    if (this.props.enableHistory) {
      if (
        !this.arePalettesEqual(this.state.selectedPalette, this.initialPalette)
      ) {
        this.getEditorAPI().history.add('palette colors updated');
      }
    }
  },
  addColorHistoryEntryIfNeeded() {
    if (this.props.enableHistory) {
      if (
        !this.areColorsEqual(this.state.selectedColor, this.getColorFromProps())
      ) {
        this.getEditorAPI().history.add(
          `new color chosen - ${this.state.selectedColor}`,
        );
      }
    }
  },
  resetPreviewColor() {
    if (this.state.colorToPreview !== this.state.selectedColor) {
      this.previewColor(this.state.selectedColor);
    }
  },
  adjustPanelPosition() {
    const DEFAULT_MARGIN = 10;
    const CUSTOM_ROOT_COLOR_SUB_PANEL_LEFT = 204;
    const CUSTOM_ROOT_COLOR_SUB_PANEL_WIDTH = 210;
    const CUSTOM_ROOT_COLOR_SUB_PANEL_MARGIN =
      CUSTOM_ROOT_COLOR_SUB_PANEL_LEFT +
      CUSTOM_ROOT_COLOR_SUB_PANEL_WIDTH +
      DEFAULT_MARGIN;

    const currentOffset = this.getContentElementOffset();
    const stageLayout = getStageLayout(this.getEditorAPI().store.getState());
    const maxLeft = stageLayout.right - CUSTOM_ROOT_COLOR_SUB_PANEL_MARGIN;
    const frameContentHeight = this.props.measurements.contentHeight || 0;
    const maxTop =
      window.pageYOffset + (stageLayout.bottom - frameContentHeight);

    return {
      top: Math.min(currentOffset.top, maxTop),
      left: Math.min(currentOffset.left, maxLeft),
    };
  },
  close() {
    this.props.sendBI(coreBi.events.colorPicker.MAIN_PANEL_CLOSED, {});

    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },
  openHelpCenter(helpId) {
    const biHelpParams = {
      component: this.getEditorAPI().selection.getSelectedComponentType(),
      learn_more: true,
      origin: constants.BI.HELP.ORIGIN.PANEL,
      panel_name: this.props.panelName,
    };
    this.props.openHelpCenter(helpId, null, biHelpParams);
  },
  handleChangeColorButtonClick() {
    if (this.props.onPaletteChange) {
      this.props.onPaletteChange();
    } else {
      this.props.openThemePanelColorTab();
    }

    const editorAPI = this.getEditorAPI();
    const selectedComponent = editorAPI.selection.getSelectedComponents()[0];
    const componentType = componentsSelectors.getCompType(
      selectedComponent,
      editorAPI.dsRead,
    );

    this.props.sendBI(
      coreBi.events.colorPicker.CHANGE_SITE_COLORS_LINK_CLICKED,
      {
        component_type: componentType,
      },
    );
  },
  handleCustomColorsTooltipOpen() {
    this.props.sendBI(coreBi.events.colorPicker.MY_COLORS_TOOLTIP_OPEN, {
      panel_name: this.props.panelName,
    });
  },
  render() {
    return React.createElement(ToolPanelFrame, {
      automationId: 'colorPickerPanel',
      panelName: this.props.panelName,
      isSingleInstance: true,
      useScroll: false,
      contentWrapperClass: 'color-picker-panel',
      isBlockingLayerFullScreen: this.props.isBlockingLayerFullScreen,
      shouldBlockOuterScroll: this.props.shouldBlockOuterScroll,
      contentStyle: this.getFrameContentStyle(),
      contentClass: 'color-picker-panel-content-wrapper',
      measurements: this.props.measurements,
      reportMeasurements: this.props.reportMeasurements,
      selectedColor: this.state.selectedColor,
      renderContent: (startContentDrag) => {
        this.startContentDrag = startContentDrag;
        return mainContentTemplate.call(this);
      },
    });
  },
});

const userPreferencesDefs = [
  {
    type: 'session',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.COMPS_ADDED,
    propName: 'customCompsAddedColors',
    updatePropName: 'setCustomCompsAddedColors',
  },
  {
    type: 'session',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_ADDED,
    propName: 'customUserAddedColors',
    updatePropName: 'setCustomUserAddedColors',
  },
];

const getEditorAPI = (_dispatch, _getState, { editorAPI }) => editorAPI;

const mapDispatchToProps = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  const compRef = editorAPI.selection.getSelectedComponents()[0];

  return {
    openHelpCenter(helpId, props, biHelpParams) {
      dispatch(openHelpCenter(helpId, props, biHelpParams));
    },
    openPanel(panelName, panelProps, leavePanelsOpen) {
      dispatch(updateOrOpenPanel(panelName, panelProps, leavePanelsOpen));
    },
    updatePanelProps(panelName, props) {
      dispatch(updatePanelAction(panelName, props));
    },
    stylableEditorSetUserColors() {
      dispatch(stylableEditorActions.setUserColors());
    },
    openThemePanelColorTab() {
      const panel = constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME;

      const panelOptions = {
        selectedView: constants.DESIGN_PANEL.VIEWS.COLOR,
        origin: 'ColorPicker',
      };

      dispatch(
        stateManagement.panels.actions.openLeftPanel(panel, panelOptions),
      );
    },
    sendBI(event: string, parameters: Object) {
      const defaultBIParams = {
        component_id: compRef?.id,
      };

      dispatch(
        stateManagement.bi.actions.event(event, {
          ...defaultBIParams,
          ...parameters,
        }),
      );
    },
  };
};

const WrappedColorPickerPanel = _.flowRight(
  stateManagement.bi.hoc.reportBiEventOnUiChange,
  higherOrderComponents.withUserPreferences(userPreferencesDefs),
  util.hoc.connect(
    util.hoc.STORES.STATE_ONLY,
    _.constant({}),
    mapDispatchToProps,
    undefined,
    false,
  ),
  measuring,
)(ColorPickerPanel);

WrappedColorPickerPanel.pure = ColorPickerPanel;

export default WrappedColorPickerPanel;

import React from 'react';
import QuickEditPanel from './components/classicQuickEditPanel';
import {
  EditorAPIKey,
  PreviewApiKey,
  PreviewerApiKey,
  SectionsApiKey,
  EditorCoreApiKey,
  QuickEditApiKey,
  SelectionApiKey,
  EditorParamsApiKey,
  WorkspaceRightPanelApiKey,
  EditorPermissionsApiKey,
  EditorRestrictionsApiKey,
  WorkspaceModesApiKey,
} from '#packages/apis';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  QuickEditApi,
  openQuickEditPanel,
  type QuickEditConfig,
  isQuickEditAvailable,
  isQuickEditPanelOpen,
} from './quickEditApi';
import { QuickEditStore } from './quickEditStore';
import {
  WORKSPACE_RIGHT_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
} from '#packages/constants';
import { WorkspaceModes } from '#packages/workspaceModes';
import {
  reSelectQuickEditRootComponentIfNeeded,
  getComponentControlWrapperProps,
} from './quickEditUtils';
import { CONTENT_PERMISSION_ZOOM_MODE_SCALE } from './consts';
import EmptyState from './components/emptyState';

export class QuickEditScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  selectionApi = this.useDependency(SelectionApiKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  sectionsAPI = this.useDependency(SectionsApiKey);
  editorParamsAPI = this.useDependency(EditorParamsApiKey);
  previewAPI = this.useDependency(PreviewApiKey);
  previewerApi = this.useDependency(PreviewerApiKey);
  store = this.declareStore(QuickEditStore);
  workspaceRightPanelApi = this.useDependency(WorkspaceRightPanelApiKey);
  editorPermissionsApi = this.useDependency(EditorPermissionsApiKey);
  editorRestrictionsApi = this.useDependency(EditorRestrictionsApiKey);
  workspaceModesApi = this.useDependency(WorkspaceModesApiKey);
}

const pageNavigationCallback = (scope: QuickEditScope) => () => {
  if (isQuickEditPanelOpen(scope)) {
    scope.store.setIsQuickEditLoading(false);
    scope.store.setIsNavigationInProcessOnQuickEdit(false);
    reSelectQuickEditRootComponentIfNeeded(scope);
  }
};

const beforePageNavigateTap = (scope: QuickEditScope) => async () => {
  if (isQuickEditPanelOpen(scope)) {
    scope.store.setIsQuickEditLoading(true);
    scope.store.setIsNavigationInProcessOnQuickEdit(true);
    scope.editorAPI.selection.deselectComponents();
  }
};

const setRestrictions = (scope: QuickEditScope) => {
  const { editorPermissionsApi, editorRestrictionsApi, workspaceModesApi } =
    scope;
  editorRestrictionsApi.set(
    [
      'quick-edit_full-empty-state.visible',
      'quick-edit_comps-tooltip.visible',
      'quick-edit_comps-secondary-action-button.visible',
      'quick-edit_comps-action-button.interactive',
      'quick-edit_text-ai-text-generator.visible',
    ],
    () => editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
  );

  editorRestrictionsApi.set(
    'quick-edit_background-empty-section.visible',
    () => !editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN'),
  );
  editorRestrictionsApi.set('quick-edit_empty-state-default-keys.visible', () =>
    workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
  editorRestrictionsApi.set(
    'quick-edit_open-on-click.interactive',
    () => !workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
  editorRestrictionsApi.set(
    [
      'quick-edit_drillin-action.visible',
      'quick-edit_text-more-options.visible',
      'quick-edit_text-tooltip.visible',
    ],
    () =>
      editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN') &&
      workspaceModesApi.isMode(WorkspaceModes.FULL),
  );
};

const getUpdatePanelConfigFn = (scope: QuickEditScope) => {
  const quickEditPanelProps = {
    EmptyState: () => React.createElement(EmptyState),
    ...getComponentControlWrapperProps(scope),
  };
  return scope.workspaceRightPanelApi.contributePanelContent(
    () => React.createElement(QuickEditPanel, quickEditPanelProps),
    {
      panelName: WORKSPACE_RIGHT_PANEL_NAMES.QUICK_EDIT,
      tabTitle: 'edit_section_panel_tab_content_label',
      iconName: 'editPencil',
      onOpen: (
        { rootControlCompRef, origin }: QuickEditConfig,
        groupType?: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
      ) => {
        openQuickEditPanel(scope, { rootControlCompRef, origin }, groupType);
      },
    },
  );
};

const quickEditContentPermissionOpener = async (scope: QuickEditScope) => {
  await scope.editorAPI.waitForChangesAppliedAsync();
  openQuickEditPanel(
    scope,
    {
      rootControlCompRef: reSelectQuickEditRootComponentIfNeeded(scope),
      origin: 'content-permission',
      zoomScale: CONTENT_PERMISSION_ZOOM_MODE_SCALE,
    },
    WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES.EDIT_SECTION,
  );
};

const registerExitPreviewOpenQuickEditIfNeeded = (scope: QuickEditScope) => {
  if (scope.editorPermissionsApi.has('CLASSIC-EDITOR.EDIT-DESIGN')) {
    return;
  }
  scope.previewAPI.registerExitPreview(() =>
    quickEditContentPermissionOpener(scope),
  );
  quickEditContentPermissionOpener(scope);
};

const waitForStageReady = async ({ editorCoreApi }: QuickEditScope) => {
  await Promise.all([
    editorCoreApi.hooks.stageIsReady.promise,
    editorCoreApi.hooks.stageIsReadyAndVisible.promise,
  ]);
};

const waitForDSReady = async ({ editorCoreApi }: QuickEditScope) => {
  return editorCoreApi.hooks.initReady.promise;
};

const registerPageNavigationCallback = (scope: QuickEditScope) => {
  scope.editorAPI.registerPageNavigationCallback(pageNavigationCallback(scope));
};

const setQuickEditUpdatePanelConfigFn = (scope: QuickEditScope) => {
  scope.store.setQuickEditUpdatePanelConfigFn(getUpdatePanelConfigFn(scope));
};

const setHookBeforeNavigation = (scope: QuickEditScope) => {
  scope.editorAPI.pages.hooks.beforePageNavigate.tap(
    beforePageNavigateTap(scope),
  );
};

export const QuickEditEntryPoint = createEntryPoint({
  name: 'QuickEdit',
  Scope: QuickEditScope,
  publicApi({ contributeApi }) {
    contributeApi(QuickEditApiKey, QuickEditApi);
  },
  async initialize(scope: QuickEditScope) {
    await waitForDSReady(scope);
    if (!isQuickEditAvailable(scope)) {
      return;
    }
    setRestrictions(scope);
    setQuickEditUpdatePanelConfigFn(scope);
    setHookBeforeNavigation(scope);
    registerPageNavigationCallback(scope);
    await waitForStageReady(scope);
    registerExitPreviewOpenQuickEditIfNeeded(scope);
  },
});

import React, { type FunctionComponent, PureComponent } from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import { translate } from '#packages/i18n';
import type { ProgressStatus } from '#packages/constants';
import {
  PREVIEW_DATA,
  progressButtonClassNames,
} from '../../topBarComponent/topBarData';

import withSendBi, { type WithSendBiProps } from '../../hocs/withSendBi';
import { mapDispatchToProps, mapStateToProps } from './previewMappers';

import TopBarButton from '../../topBarButton/topBarButtonWithTooltip';
import TooltipContent from '../../tooltipContent';

interface PreviewButtonTooltipProps {
  previewingStatus: ProgressStatus;
  isFixedStageEnabled: boolean;
}

const PreviewButtonTooltip: FunctionComponent<PreviewButtonTooltipProps> = (
  props,
) => {
  const { tooltipData } =
    PREVIEW_DATA[props.previewingStatus] ||
    PREVIEW_DATA[constants.PROGRESS_STATUS.IDLE];

  const stageType = props.isFixedStageEnabled ? 'fluid' : 'regular';

  const tooltipContentForCurrentStageType =
    tooltipData[stageType] || tooltipData.regular;

  return <TooltipContent tooltipData={tooltipContentForCurrentStageType} />;
};

export interface PreviewButtonOwnProps {
  disabled?: boolean;
}

export interface PreviewButtonStateProps {
  status?: ProgressStatus;
  isSitePublished?: boolean;
  isDevModeOn: boolean;
  topBarStateBIParamValue: string;
  isFixedStageEnabled: boolean;
}

export interface PreviewButtonDispatchProps {
  togglePreview: (
    callback?: Function,
    params?: { biParams?: AnyFixMe },
  ) => void;
}

type PreviewButtonProps = WithSendBiProps &
  PreviewButtonOwnProps &
  PreviewButtonStateProps &
  PreviewButtonDispatchProps;

class PreviewButton extends PureComponent<PreviewButtonProps> {
  handleClick = () => {
    this.props.togglePreview();
    util.editorWixRecorder.addLabel('preview top bar clicked');
    this.props.sendBi(coreBi.events.topbar.top_bar_click, {
      category: 'preview',
      is_published: this.props.isSitePublished,
      state: this.props.topBarStateBIParamValue,
    });
    util.editorWixRecorder.addLabel('preview click');
    this.props.sendBi(coreBi.events.preview.PREVIEW_CLICK, {
      origin: constants.BI.PREVIEW.ORIGIN.TOP_BAR,
      is_dev_mode: this.props.isDevModeOn,
    });
  };

  render() {
    const tooltip = (
      <PreviewButtonTooltip
        previewingStatus={this.props.status}
        isFixedStageEnabled={this.props.isFixedStageEnabled}
      />
    );

    const disabled =
      this.props.disabled ||
      this.props.status === constants.PROGRESS_STATUS.IN_PROGRESS;

    const className = util.cx(
      'top-bar-button-preview',
      progressButtonClassNames[this.props.status],
    );

    return (
      <TopBarButton
        disabled={disabled}
        label={translate('TOPBAR_PREVIEW_TOOLTIP_TITLE')}
        tooltip={tooltip}
        primary={true}
        onClick={this.handleClick}
        className={className}
        automationId="top-bar-button-preview"
      />
    );
  }
}

const ConnectedPreviewButton = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  withSendBi,
)(PreviewButton) as React.ComponentType<PreviewButtonOwnProps>;

(ConnectedPreviewButton as AnyFixMe).pure = PreviewButton;

export default ConnectedPreviewButton;

import type { CompRef } from 'types/documentServices';
import { getInteractionsLeftBarOverrides } from './interactionsLeftBarOverrides';
import { getInteractionsAddPanelOverrides } from './interactionsAddPanelOverrides';
import * as stateManagement from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

const componentSelectors = stateManagement.components.selectors;

export function getOverriddenInteractionsState(
  editorAPI: EditorAPI,
  triggerRef: CompRef,
) {
  const isContainable = editorAPI.components.is.container(triggerRef);
  const isReapeaterItem = componentSelectors.isRepeaterItem(
    triggerRef,
    editorAPI.dsRead,
  );
  return {
    leftBar: {
      overriddenButtons: getInteractionsLeftBarOverrides(isContainable),
    },
    addPanel: {
      overridden: getInteractionsAddPanelOverrides({ isReapeaterItem }),
    },
  };
}

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import * as util from '#packages/util';
import React from 'react';
import WelcomeSlide from './welcomeSlide';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'WelcomeSlideWithNavigation',
  mixins: [core.mixins.editorAPIMixin, util.propTypesFilterMixin],
  propTypes: {
    superAppMainPageId: PropTypes.string.isRequired,
    isFirstSlide: PropTypes.bool.isRequired,
  },
  componentDidMount() {
    if (
      this.props.isFirstSlide ||
      this.getEditorAPI().dsRead.pages.getFocusedPageId() !==
        this.props.superAppMainPageId
    ) {
      this._navigateToAppMainPage();
    }
  },
  _navigateToAppMainPage() {
    return this.getEditorAPI().pages.navigateTo(this.props.superAppMainPageId);
  },
  render() {
    return <WelcomeSlide type="template" {...this.filteredProps()} />;
  },
});

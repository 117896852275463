import type { AppHost } from '#packages/apilib';
import {
  ComponentsApiKey,
  PagesApiKey,
  HistoryApiKey,
  MenuApiKey,
  DeveloperModeApiKey,
  ComponentFocusModeApiKey,
  ConcurrentUsersApiKey,
  EditorUIApiKey,
  AccountApiKey,
  ThemeApiKey,
  BiApiKey,
  SiteApiKey,
  PlatformApiKey,
  FeedbackApiKey,
  CampaignsApiKey,
  SavePublishApiKey,
  SaveManagerApiKey,
  PublishManagerApiKey,
  AutosaveManagerApiKey,
  PanelManagerApiKey,
  PanelHelpersApiKey,
  MediaServicesApiKey,
  GTMServiceApiKey,
  ColumnsApiKey,
  PreviewApiKey,
  SelectionApiKey,
  PinModeApiKey,
  MobileEditorApiKey,
  MaskCropApiKey,
  TextEditorApiKey,
  MainMenuApiKey,
  PromotionsApiKey,
  TpaApiKey,
  FloatingBubbleApiKey,
  EditorEventRegistryApiKey,
  SectionsApiKey,
  ClipboardApiKey,
  CopyPasteApiKey,
  HomePageApiKey,
  LayoutersApiKey,
  MediaApiKey,
  MouseActionsApiKey,
  PluginServiceApiKey,
  SavedComponentsApiKey,
  ScrollApiKey,
  SelectedComponentApiKey,
  TabIndicationApiKey,
  WixCodeApiKey,
  SchoolModeApiKey,
  A11yApiKey,
  ZoomModeApiKey,
  ComponentPanelPreloaderApiKey,
  SpotlightStageApiKey,
  AdvancedMenuApiKey,
  AscendApiKey,
  ComponentsStageModesApiKey,
} from '#packages/apis';

export function getApisObjectForEditorAPI(host: AppHost) {
  return {
    accessibility: host.getAPI(A11yApiKey),
    account: host.getAPI(AccountApiKey),
    autosaveManager: host.getAPI(AutosaveManagerApiKey),
    bi: host.getAPI(BiApiKey),
    campaigns: host.getAPI(CampaignsApiKey),
    clipboard: host.getAPI(ClipboardApiKey),
    columns: host.getAPI(ColumnsApiKey),
    components: host.getAPI(ComponentsApiKey),
    componentsStageModes: host.getAPI(ComponentsStageModesApiKey),
    componentPanelPreloader: host.getAPI(ComponentPanelPreloaderApiKey),
    concurrentUsers: host.getAPI(ConcurrentUsersApiKey),
    copyPaste: host.getAPI(CopyPasteApiKey),
    developerMode: host.getAPI(DeveloperModeApiKey),
    editorEventRegistry: host.getAPI(EditorEventRegistryApiKey),
    componentFocusMode: host.getAPI(ComponentFocusModeApiKey),
    feedback: host.getAPI(FeedbackApiKey),
    floatingBubble: host.getAPI(FloatingBubbleApiKey),
    GTMService: host.getAPI(GTMServiceApiKey),
    history: host.getAPI(HistoryApiKey),
    homePage: host.getAPI(HomePageApiKey),
    imageCrop: host.getAPI(MaskCropApiKey),
    layouters: host.getAPI(LayoutersApiKey),
    mainMenu: host.getAPI(MainMenuApiKey),
    media: host.getAPI(MediaApiKey),
    mediaServices: host.getAPI(MediaServicesApiKey),
    menus: host.getAPI(MenuApiKey),
    mobile: host.getAPI(MobileEditorApiKey),
    mouseActions: host.getAPI(MouseActionsApiKey),
    pages: host.getAPI(PagesApiKey),
    panelHelpers: host.getAPI(PanelHelpersApiKey),
    panelManager: host.getAPI(PanelManagerApiKey),
    pinMode: host.getAPI(PinModeApiKey),
    platform: host.getAPI(PlatformApiKey),
    pluginService: host.getAPI(PluginServiceApiKey),
    preview: host.getAPI(PreviewApiKey),
    promotions: host.getAPI(PromotionsApiKey),
    publishManager: host.getAPI(PublishManagerApiKey),
    saveManager: host.getAPI(SaveManagerApiKey),
    savePublish: host.getAPI(SavePublishApiKey),
    savedComponents: host.getAPI(SavedComponentsApiKey),
    schoolMode: host.getAPI(SchoolModeApiKey),
    scroll: host.getAPI(ScrollApiKey),
    spotlightStage: host.getAPI(SpotlightStageApiKey),
    sections: host.getAPI(SectionsApiKey),
    selectedComponent: host.getAPI(SelectedComponentApiKey),
    selection: host.getAPI(SelectionApiKey),
    site: host.getAPI(SiteApiKey),
    tabIndicationState: host.getAPI(TabIndicationApiKey),
    temporaryAdvancedMenuAPI: host.getAPI(AdvancedMenuApiKey),
    text: host.getAPI(TextEditorApiKey),
    theme: host.getAPI(ThemeApiKey),
    tpa: host.getAPI(TpaApiKey),
    ui: host.getAPI(EditorUIApiKey),
    wixCode: host.getAPI(WixCodeApiKey),
    zoomMode: host.getAPI(ZoomModeApiKey),
    ascend: host.getAPI(AscendApiKey),
  };
}

export type ApisInEditorAPIType = ReturnType<typeof getApisObjectForEditorAPI>;

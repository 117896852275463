import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import { sections } from '#packages/util';
import {
  EditorRestrictionsApiKey,
  QuickEditApiKey,
  DesignerPanelsApiKey,
} from '#packages/apis';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { GFPPAction } from '@wix/editor-component-types';
import experiment from 'experiment';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const getToggleDesignPanel = (startWithAdvanced: boolean) => {
  return gfppDataUtils.getTogglePanelFn(ACTIONS.DESIGN, {
    startWithAdvanced,
    onlyAdvancedStyling: startWithAdvanced,
  });
};

function getDesignerScanAction(): GFPPAction[] {
  return experiment.isOpen('se_addDesignerSection')
    ? [
        {
          label: 'Scan Footer',
          shouldTranslate: false,
          onClick: (editorAPI: EditorAPI, compRefs: CompRef[]) => {
            const designerPanelsApi =
              editorAPI.host.getAPI(DesignerPanelsApiKey);
            designerPanelsApi.openDesignerScanPresetPanel(compRefs[0]);
          },
        },
      ]
    : [];
}

export default {
  untranslatable: true,
  mainActions: (editorAPI: EditorAPI) => {
    const designAction = {
      label: editorAPI.zoomMode.isStageZoomMode()
        ? 'RightClick_Menu_Footer_Zoom_Out_Mode_Zoom_Label'
        : 'gfpp_mainaction_footer',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.DESIGN),
      onClick: async (
        editorAPI: EditorAPI,
        compRef?: CompRef[],
        origin?: string,
        optionalPropsOverrides?: Record<string, unknown>,
      ) => {
        if (editorAPI.zoomMode.isStageZoomMode()) {
          const compLayout = editorAPI.components.layout.getRelativeToScreen(
            editorAPI.siteSegments.getFooter(),
          );

          await editorAPI.zoomMode.exitZoomMode({
            biParams: { origin },
            originalScrollY: compLayout.y,
          });
        }

        const shouldShowOnlyAdvancedStylingDesignPanel =
          gfppDataUtils.siteSegmentBackgroundSupported(editorAPI, compRef?.[0]);

        const toggleDesignPanelFn = getToggleDesignPanel(
          shouldShowOnlyAdvancedStylingDesignPanel,
        );

        if (shouldShowOnlyAdvancedStylingDesignPanel) {
          const prevBiParams =
            typeof optionalPropsOverrides?.biParams === 'object'
              ? optionalPropsOverrides?.biParams
              : {};

          const biParams = {
            panel_name: constants.PANELS.ADVANCED_SITE_SEGMENT_DESIGN_PANEL,
            ...prevBiParams,
          };

          optionalPropsOverrides = {
            ...optionalPropsOverrides,
            biParams,
          };
        }

        toggleDesignPanelFn(editorAPI, compRef, origin, optionalPropsOverrides);
      },
    };

    const quickEditAction = {
      label: `gfpp_mainaction_edit_section_label`,
      onClick: (editorAPI: EditorAPI, compRefs: CompRef[], origin: string) => {
        const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
        quickEditAPI.openPanel({
          rootControlCompRef: compRefs[0],
          origin,
        });
      },
    };
    const isQuickEditAvailable = editorAPI.host
      .getAPI(QuickEditApiKey)
      .isQuickEditAvailable();
    const quickEditActionIfAvailable = isQuickEditAvailable
      ? [quickEditAction]
      : [];
    const editorRestrictionsApi = editorAPI.host.getAPI(
      EditorRestrictionsApiKey,
    );
    const isFooterGfppVisible = editorRestrictionsApi.allowed(
      'gfpp_footer.visible',
    );
    const designerScanAction = getDesignerScanAction();
    const designActionIfAvailable = isFooterGfppVisible ? [designAction] : [];

    return [
      ...designerScanAction,
      ...designActionIfAvailable,
      ...quickEditActionIfAvailable,
    ];
  },
  enabledActions: () => {
    return sections.isSectionsEnabled()
      ? [ACTIONS.SETTINGS, ACTIONS.HELP]
      : [ACTIONS.SETTINGS, ACTIONS.DESIGN, ACTIONS.HELP];
  },
  mobileEnabledActions: [ACTIONS.ANIMATION, ACTIONS.HELP],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.FOOTER,
    },
  },
};

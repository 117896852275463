import type { Shell } from '#packages/apilib';
import { EditorAPIKey, PagesApiKey } from '#packages/apis';
import experiment from 'experiment';

import updateOperationsPlugins from '../../updateOperationsPlugins';
import * as stateManagement from '#packages/stateManagement';
const { isDomainConnected } = stateManagement.domain.selectors;

type Callback = () => void;

export function createHomePageApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const pagesApi = shell.getAPI(PagesApiKey);

  function getHomePage() {
    return editorAPI.dsRead.homePage.get();
  }

  function actualSetHomePage(pageId: string, callback?: Callback) {
    const childPageIds = pagesApi.getChildPagesIds(pageId);
    const clearPageIds = [pageId, ...childPageIds];
    clearPageIds.forEach((currentPageId) => {
      editorAPI.pages.data.update(currentPageId, { parentPageId: null });
    });
    editorAPI.dsActions.homePage.set(pageId);
    updateOperationsPlugins.setHomePagePlugins.run(editorAPI, pageId);
    if (callback) {
      callback();
    }
  }

  function setHomePage(pageId: AnyFixMe, callback?: Callback) {
    if (experiment.isOpen('specs.promote-seo.enableStaticPageHierarchy')) {
      if (pagesApi.isParentPage(pageId)) {
        const sitePublicUrl = editorAPI.site.getSitePublicUrl();
        const childPageTitles = pagesApi.getChildPagesTitles(pageId);
        const panelProps = {
          childPageTitles,
          hasDomain: isDomainConnected(editorAPI.dsRead),
          onCancel: () => {},
          onConfirm: () => actualSetHomePage(pageId, callback),
          sitePublicUrl,
        };
        editorAPI.panelManager.openPanel(
          'panels.messagePanels.parentHomePageMessage',
          panelProps,
          true,
        );
        return;
      }
      actualSetHomePage(pageId, callback);
    } else {
      editorAPI.dsActions.homePage.set(pageId);
      updateOperationsPlugins.setHomePagePlugins.run(editorAPI, pageId);
      if (callback) {
        callback();
      }
    }
  }
  return {
    get: getHomePage,
    set: setHomePage,
  };
}

import * as coreBi from '#packages/coreBi';
import type {
  MediaManagerImage,
  MediaManagerInfo,
} from '#packages/mediaServices';
import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import _ from 'lodash';
import React from 'react';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { CompStructure } from 'types/documentServices';
import type { Dispatch } from 'types/redux';
import type { MediaManagerResolvedData } from '../../utils/mediaManager';
import { openMediaManager } from '../../utils/mediaManager';
import { getImageCompDef } from './imageSectionUtils';
import type { ListItem } from './listSection';
import ListSection from './listSection';
import type { MapStateToProps } from 'types/redux';

let onItemSelectedCallBackInvoker: AnyFixMe = null;
const { setSessionUserPreferences } = stateManagement.userPreferences.actions;

interface ImageSectionStateProps {
  isMobileEditor: boolean;
}

interface ImageSectionDispatchProps {
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
  setItemLastPathSessionUserPreferences: (
    item: MediaManagerImage,
    info: MediaManagerInfo,
  ) => void;
  openMediaManagerForCategory: (
    imageCategory: ListItem,
  ) => Promise<MediaManagerResolvedData>;
  setFocusToEditor: () => void;
}
interface ImageSectionProps
  extends ImageSectionDispatchProps,
    ImageSectionStateProps {
  mediaManagerCallbackOverride?: (
    items: MediaManagerImage[],
    info: MediaManagerInfo,
  ) => void;
  title: string;
  onClick: (
    compDef: CompStructure,
    sectionTitle: string,
    sectionTags: string,
  ) => void;
  openHelpCenter: (
    helpId: string,
    props?: AnyFixMe,
    biParams?: AnyFixMe,
  ) => void;
  props: {
    items: ListItem[];
    class?: string;
  };
  automationId: string;
}

export class ImageSectionPure extends React.Component<ImageSectionProps> {
  static displayName = 'imageSection';

  onItemClick = (imageCategory: ListItem): void => {
    this.props.sendBi(
      coreBi.events.addPanel.ADD_MENU_IMAGE_SECTION_BUTTON_CLICK,
      {
        section: this.props.title,
        sub_section: imageCategory.biItemName,
      },
    );

    this.props
      .openMediaManagerForCategory(imageCategory)
      .then(({ items, info }: MediaManagerResolvedData) => {
        if (this.props.mediaManagerCallbackOverride) {
          this.props.mediaManagerCallbackOverride(items, info);
          return;
        }

        this.onItemsSelected(imageCategory.compType, items, info);
        this.props.setFocusToEditor();
      });
  };

  onItemSelectedCallBack = (
    compType: AnyFixMe,
    items: AnyFixMe,
    info: AnyFixMe,
  ) => {
    if (!items) {
      return;
    }

    items.forEach((item: MediaManagerImage) => {
      this.props.setItemLastPathSessionUserPreferences(item, info);
      this.addCompFromImageItem(compType, item);
    });
  };

  componentDidMount = () => {
    onItemSelectedCallBackInvoker = _.once(this.onItemSelectedCallBack);
  };

  onItemsSelected = (
    compType: AnyFixMe,
    items: AnyFixMe,
    info: MediaManagerInfo,
  ) => {
    onItemSelectedCallBackInvoker.call(this, compType, items, info);
  };

  addCompFromImageItem = (compType: AnyFixMe, imageItem: AnyFixMe) => {
    this.props.onClick(
      getImageCompDef(compType, imageItem, this.props.isMobileEditor),
      this.props.title,
      '',
    );
  };

  render() {
    return <ListSection callback={this.onItemClick} {...this.props} />;
  }
}

const mapStateToProps: MapStateToProps<ImageSectionStateProps> = ({
  editorAPI,
}) => ({
  isMobileEditor: editorAPI.isMobileEditor(),
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

const mapDispatchToProps = (dispatch: Dispatch): ImageSectionDispatchProps => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    sendBi: editorAPI.bi.event,
    setItemLastPathSessionUserPreferences: (
      item: MediaManagerImage,
      info: MediaManagerInfo,
    ) => {
      dispatch(
        setSessionUserPreferences(
          `last_media_path_${item.fileName}`,
          info.path,
        ),
      );
    },
    openMediaManagerForCategory: (imageCategory: ListItem) =>
      openMediaManager(
        editorAPI,
        imageCategory.category,
        imageCategory.openSource,
        {
          multiSelect: true,
          path: imageCategory.path,
          translation: imageCategory.translation,
        },
      ),
    setFocusToEditor: editorAPI.mediaServices.setFocusToEditor,
  };
};

export const ImageSection = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(ImageSectionPure);

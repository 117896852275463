import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import * as tpa from '#packages/tpa';
import * as wixStoreDataProvider from './wixStoreDataProvider';
import * as platform from '#packages/platform';
import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';
import { translate } from '#packages/i18n';
import { tpaUtils, url } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import constants from '#packages/constants';
import type { OnDropCallback } from '#packages/addPanelInfra';
import {
  InstallationOriginType,
  InstallInitiator,
  EditorType,
} from '@wix/platform-editor-sdk';

import * as wixStoresBi from './bi/wixStoresBi';
import type {
  CompRef,
  CompLayout,
  CompStructure,
} from 'types/documentServices';
import type { PlatformOrigin } from '#packages/platform';

const ecomAppDefId = tpaUtils.getStoresAppDefId();
const galleryWidgetId = wixStoreDataProvider.getGalleryWidgetId();
let _editorAPI: EditorAPI;
const init = function (editorAPI: EditorAPI) {
  _editorAPI = editorAPI;
};

const getAppDefIdAndEcomAppDefIdAsFallBack = (appDefId: AnyFixMe) =>
  appDefId || tpaUtils.getStoresAppDefId();

const closeCurrentWixStorePanel = function () {
  const currentPanel = _editorAPI.panelManager.getOpenPanels()[0]
    ? _editorAPI.panelManager.getOpenPanels()[0].name
    : '';
  _editorAPI.panelManager.closePanelByName(currentPanel);
};

export type AddWidgetBIParams = tpa.superApps.AddWidgetBIParams;

function addWidgetToSite(
  widgetId: string,
  appDefId: string,
  presetId: string,
  layout: Partial<CompLayout>,
  cb: (compRef: CompRef) => void,
  {
    containerRef,
    biParams,
    useRelativeToContainerLayout,
    componentDefinition,
  }: {
    containerRef?: CompRef;
    biParams?: tpa.superApps.AddWidgetBIParams;
    useRelativeToContainerLayout?: boolean;
    componentDefinition?: CompStructure;
  } = {},
) {
  return new Promise<CompRef>((resolve) => {
    const callback = (compRef: CompRef) => {
      if (cb) cb(compRef);
      resolve(compRef);
    };

    const biOrigin = tpa.constants.BI.type.ADD_WIDGET;
    if (
      appDefId &&
      appDefId !== ecomAppDefId &&
      !_editorAPI.platform.isAppActive(appDefId)
    ) {
      const type = tpa.constants.APP.TYPE.WIDGET;
      const biInitiator = tpa.constants.BI.initiator.EDITOR;
      const options = Object.assign(
        {},
        { widgetId, containerRef, useRelativeToContainerLayout },
        layout,
      );
      tpa.services.tpaAddAppService.addApp(
        _editorAPI,
        appDefId,
        null,
        null,
        type,
        biInitiator,
        false,
        {},
        options,
        callback,
        {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AppPanel,
          },
        },
      );
    } else {
      const options: tpa.superApps.AddWidgetOptions = {
        presetId,
        biOrigin,
        componentDefinition,
        platformOrigin: {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AppPanel,
          },
        },
        cb: callback,
        containerRef,
        useRelativeToContainerLayout,
        ...biParams,
      };
      tpa.superApps.addWidget(
        appDefId || tpaUtils.getEcomAppDefId(),
        widgetId,
        layout,
        options,
      );
    }
    closeCurrentWixStorePanel();
  });
}

const openAppDashboardInEditor = function (
  appOptions: {
    state?: string;
    origin?: string;
    leavePanelsOpen?: boolean;
  } = {},
  appDefId?: string,
) {
  const params = {
    ...appOptions,
    onClose: refreshAppComponents.bind(undefined, appDefId),
  };
  return tpa.superApps.openDashboardUrl(
    getAppDefIdAndEcomAppDefIdAsFallBack(appDefId),
    params,
    {
      type: tpa.constants.BI.type.APP_MANAGE_BUTTON,
    },
  );
};

const refreshAppComponents = function (appDefId?: string) {
  tpa.superApps.refreshAppCompsForAppDefId(
    getAppDefIdAndEcomAppDefIdAsFallBack(appDefId),
  );
};

const addAppToSite = function (
  biOrigin: string,
  isPreInstallation: boolean = false,
  appDefId?: string,
  platformOrigin?: PlatformOrigin,
) {
  return new Promise<void>((resolve) => {
    const callback = function (obj: AnyFixMe) {
      if (!isPreInstallation && obj.page) {
        _editorAPI.pages.navigateTo(obj.page.id, function () {
          const sectionPointer = tpa.superApps.getSectionRefByPageId(
            obj.page.id,
          );
          _editorAPI.selection.selectComponentByCompRef(sectionPointer);
        });
      }
      resolve();
    };

    tpa.superApps.addApp(getAppDefIdAndEcomAppDefIdAsFallBack(appDefId), {
      callback,
      biOrigin,
      platformOrigin: platformOrigin || {
        type: EditorType.Classic,
        initiator: InstallInitiator.Editor,
        info: {
          type: isPreInstallation
            ? InstallationOriginType.INTRO_FUNNEL
            : InstallationOriginType.AppPanel,
        },
      },
      isPreInstallation,
      showProgressBar: false,
    });
  });
};

const onWidgetClick = function (cb: AnyFixMe) {
  return function (
    event: AnyFixMe,
    sectionTitle: AnyFixMe,
    tags: AnyFixMe,
    presetId: AnyFixMe,
  ) {
    return addWidgetToSite(
      event.data.widgetId,
      event.data.appDefinitionId,
      presetId,
      event.layout,
      cb,
      {
        biParams: {
          addingMethod: 'click',
          section: sectionTitle,
          category: 'store',
          presetId,
        },
      },
    );
  };
};

const onWidgetDrop = function (cb: AnyFixMe): OnDropCallback {
  return function (position, event, containerRef) {
    const layout = {
      ...position,
      width: event.structure.layout.width,
      height: event.structure.layout.height,
    };

    return addWidgetToSite(
      event.structure.data.widgetId,
      event.structure.data.appDefinitionId,
      event.itemId,
      layout,
      cb,
      {
        containerRef,
        biParams: {
          addingMethod: 'drag',
          section: event.sectionTitle,
          category: event.categoryId,
          presetId: event.itemId,
        },
      },
    );
  };
};

const registerLeftBarPanel = function (
  storesWasSilentlyInstalled: boolean,
  editorAPI: EditorAPI,
) {
  const can6thButtonBeRegistered = superAppMenuInfra.button.canBeRegistered(
    _editorAPI,
    {
      appDefId: ecomAppDefId,
    },
  );

  if (!can6thButtonBeRegistered) {
    return;
  }

  const leftBarParams = wixStoreDataProvider.wixStoreLeftBarPanelParams;

  if (storesWasSilentlyInstalled) {
    superAppMenuInfra.button.registerCollapsedButton(
      _editorAPI,
      leftBarParams.panelName,
      leftBarParams.label,
      leftBarParams.className,
      leftBarParams.symbolName,
    );
  } else {
    superAppMenuInfra.button.registerButton(
      _editorAPI,
      wixStoreDataProvider.superAppId,
      wixStoreDataProvider.isAppInstalled(editorAPI),
      leftBarParams.panelName,
      leftBarParams.label,
      leftBarParams.promotionalLabel,
      leftBarParams.className,
      leftBarParams.symbolName,
    );
  }
};

const unRegisterLeftBarPanel = function () {
  const leftBarParams = wixStoreDataProvider.wixStoreLeftBarPanelParams;
  superAppMenuInfra.button.unRegisterButton(
    _editorAPI,
    leftBarParams.panelName,
  );
};

const firstTimePanelOpen = function (state: string, type: string = 'click') {
  const params = {
    app_id: tpaUtils.getStoresAppDefId(),
    app_name: wixStoreDataProvider.getAppName(),
    type,
    state,
  };
  _editorAPI.bi.event(wixStoresBi.events.VIEW_STORES_PANEL_IN_EDITOR, params);
};

const onAppInstalled = async function (
  editorAPI: EditorAPI,
  options: any = {},
) {
  const { origin: platformOrigin }: { origin: PlatformOrigin } = options;
  const appDefId = tpaUtils.getStoresAppDefId();
  const shouldDisableOpenAppPanel =
    platform.utils.shouldDisableOpenAppPanelOnInstall(
      editorAPI,
      platformOrigin,
    );

  await tpa.superApps.waitForAppCompleteInstallation(editorAPI, appDefId);

  const leavePanelsOpened = true;
  if (!shouldDisableOpenAppPanel) {
    editorAPI.store.dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME,
        {
          selectedAppId: appDefId,
        },
        leavePanelsOpened,
      ),
    );
  }
};

const onAppDeleted = function () {
  unRegisterLeftBarPanel();
};

const addSuperAppSectionPage = async function (
  managingAppDefId: string,
  origin?: string,
) {
  await tpa.superApps.addSection(
    tpaUtils.getEcomAppDefId(),
    galleryWidgetId,
    origin,
  );

  const pageId = _editorAPI.dsRead.pages.getFocusedPageId();
  _editorAPI.pages.data.update(pageId, {
    managingAppDefId,
  });
  return pageId;
};

const handleUpgrade = function (appDefId?: string) {
  tpa.superApps.upgrade(
    getAppDefIdAndEcomAppDefIdAsFallBack(appDefId),
    true,
    'edhtml_TOP_PANEL',
  );
};

const setStyleParams = function (styleParams: AnyFixMe, appDefId?: string) {
  return function (comp: AnyFixMe) {
    styleParams.forEach((styleParam: AnyFixMe) =>
      tpa.superApps.setStyleParam(
        getAppDefIdAndEcomAppDefIdAsFallBack(appDefId),
        comp.id,
        styleParam,
      ),
    );
  };
};

const skipFirstTimeTour = function () {
  _editorAPI.registerInitUserPrefsCallback(() => {
    _editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        `${wixStoreDataProvider.superAppId}/in_first_time_tour`,
        'false',
      ),
    );
  });
};

const doFirstSave = async (origin?: string) => {
  return new Promise<void>((resolve, reject) => {
    const isFirstSave = _editorAPI.dsRead.generalInfo.isFirstSave();
    const hasNoAppSave = url.noAppSave();

    if (!isFirstSave || hasNoAppSave) {
      resolve();
      return;
    }

    registerFirstSaveLoader({ leavePanelsOpen: true });

    _editorAPI.saveManager.saveInBackground(
      resolve,
      (error: Error) => {
        tpa.services.tpaAlertsService.openProvisionFailedAlert(
          _editorAPI.panelManager.openPanel,
          ecomAppDefId,
          true,
        );
        error.message +=
          ' - CRITICAL: save failed on first save with no uninstall';
        ErrorReporter.captureException(error);
        reject(error);
      },
      origin,
      {
        sourceOfStart: 'wixStore_bgSave',
      },
    );
  });
};

interface IRegisterFirstSaveLoaderParams {
  leavePanelsOpen: boolean;
}
const registerFirstSaveLoader = (params: IRegisterFirstSaveLoaderParams) => {
  const key = translate('SiteApp_WixStores_AppInstalled_Loader');
  _editorAPI.savePublish.registerFirstSaveCallback(() =>
    setTimeout(_editorAPI.panelHelpers.closeProgressBar, 2000),
  );
  _editorAPI.panelHelpers.openProgressBar(
    {
      title: key,
      totalSteps: 3,
      currentStep: 1,
    },
    params.leavePanelsOpen,
  );
  setTimeout(() => _editorAPI.panelHelpers.updateProgressBar(2), 4000);
  setTimeout(() => _editorAPI.panelHelpers.updateProgressBar(3), 8000);
};

const openStoresAppMarket = () => {
  const appMarketPanelProps = {
    urlParams: {
      slug: ecomAppDefId,
    },
  };
  tpa.appMarketTabService.openAppMarketTab(
    appMarketPanelProps,
    _editorAPI.panelManager.openPanel,
  );
};

export {
  addWidgetToSite,
  addAppToSite,
  addSuperAppSectionPage,
  closeCurrentWixStorePanel,
  doFirstSave,
  firstTimePanelOpen,
  handleUpgrade,
  init,
  onAppDeleted,
  onAppInstalled,
  onWidgetClick,
  onWidgetDrop,
  openAppDashboardInEditor,
  refreshAppComponents,
  registerLeftBarPanel,
  setStyleParams,
  skipFirstTimeTour,
  openStoresAppMarket,
};

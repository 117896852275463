import addPanelDataConsts from '#packages/addPanelDataConsts';
import _ from 'lodash';
import { getAddPanelSection } from '../common/data/wixStoresSectionsProvider';

function getWixStoreGroups(sectionsArrayFunction: AnyFixMe) {
  return [
    {
      sections: sectionsArrayFunction(),
      title: '',
    },
  ];
}

export function register(editorAPI: AnyFixMe) {
  editorAPI.addPanel.registerOverrideGroupsForCategory(
    addPanelDataConsts.CATEGORIES_ID.STORE,
    _.partial(getWixStoreGroups, () => getAddPanelSection(editorAPI)),
  );
}

export function unregister(addPanelActions: AnyFixMe) {
  addPanelActions.unRegisterOverrideGroupsForCategory(
    addPanelDataConsts.CATEGORIES_ID.STORE,
  );
}

import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import type { AppData } from '../components/privateAppsProvider/privateAppsProvider';
import type { Dispatch, DispatchMapperArgs } from 'types/redux';
import { isMinorUpdatesOn } from './utils';
import { privateAppsUpdateAppVersion } from '@wix/bi-logger-blocks-consumption/v2';
import { updateAppSuccess } from '@wix/bi-logger-blocks-data/v2';
import { TEST_VERSION, hasMajorUpdate, hasUpdates } from './appDetailsUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { PlatformAppDescription } from '@wix/document-services-types';
import { pendingAppsService } from '#packages/tpa';
import type { EditorState } from '#packages/stateManagement';
const { fetchMultipleAppsFromDevCenter } = stateManagement.platform.actions;

export interface UpdateAppOptions {
  app: AppData;
  version?: string;
  biParams?: { origin: UpdateOrigin };
}
type UpdateOrigin =
  | 'app item'
  | 'context menu'
  | 'app details page'
  | 'automatic';

export interface BiParams {
  origin: UpdateOrigin;
  version: string;
  appId: string;
}

export interface UpdateAppDispatchProps {
  updateApp: (options: UpdateAppOptions) => Promise<unknown>;
}

export const reportBiUpdateApp = (options: BiParams) =>
  util.biLogger.report(privateAppsUpdateAppVersion(options));

export const reportBiUpdateAppSuccess = ({
  appDefId,
  origin,
  installedVersion,
  fullAppVersion,
}: {
  appDefId: string;
  origin: UpdateOrigin;
  installedVersion: string;
  fullAppVersion: string;
}) => {
  fetchMultipleAppsFromDevCenter([appDefId]).then(({ apps }) => {
    const [appInfo] = apps;
    util.biLogger.report(
      updateAppSuccess({
        appId: appDefId,
        builderType: 'classic',
        origin,
        appLatestVersion: appInfo?.latestVersion,
        version: installedVersion,
        fullVersion: fullAppVersion,
      }),
    );
  });
};

export const updateApp =
  ({
    app,
    version = util.appStudioUtils.formatSemver(
      app.latestVersion,
      isMinorUpdatesOn(app?.appFields?.installedVersion),
    ),
    biParams,
  }: UpdateAppOptions) =>
  async (
    dispatch: Dispatch,
    getState: () => EditorState,
    { editorAPI }: DispatchMapperArgs,
  ) => {
    const isReponsiveBlocksApp = util.appStudioUtils.isResponsiveBlocksVersion(
      app.blocksVersion,
    );
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_APP,
      {
        customParams: {
          origin: biParams?.origin,
          appDefId: app.appDefinitionId,
          appVersion: app?.version,
          installedVersion: app?.appFields?.installedVersion,
          updateVersion: version,
        },
      },
    );
    dispatch(stateManagement.selection.actions.clearSelectedComponents());
    dispatch(
      stateManagement.platform.actions.updateApp({
        applicationId: String(app.applicationId),
        appVersion:
          version !== 'latest' && !version.startsWith('^')
            ? util.appStudioUtils.formatSemver(version, true)
            : version,
        ...(!isReponsiveBlocksApp
          ? {
              payload: {
                updateType: 'MAJOR',
              },
            }
          : null),
      }),
    ).then(() => {
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.BLOCKS.UPDATE_APP,
        {
          customParams: {
            origin: biParams?.origin,
            appDefId: app.appDefinitionId,
            appVersion: app?.version,
            installedVersion: app?.appFields?.installedVersion,
            updateVersion: version,
          },
        },
      );

      reportBiUpdateAppSuccess({
        appDefId: app.appDefinitionId,
        origin: biParams?.origin,
        installedVersion: app?.appFields?.installedVersion,
        fullAppVersion: app?.version,
      });

      pendingAppsService.updateNotification(editorAPI);
    });
  };

export const shouldShowUpdateAction = (app: AppData): boolean => {
  if (hasUpdates(app) && app?.appFields?.installedVersion !== TEST_VERSION) {
    if (util.appStudioUtils.isResponsiveBlocksVersion(app.blocksVersion)) {
      return (
        hasMajorUpdate(app) ||
        !isMinorUpdatesOn(app?.appFields?.installedVersion)
      );
    }

    return true;
  }

  return false;
};

export const getAllAvailableToUpdateApps = (editorAPI: EditorAPI) => {
  const apps = editorAPI.dsRead.platform.getInstalledEditorApps();
  return apps.reduce(
    (acc: AppData[], appDescription: PlatformAppDescription) => {
      const app: AppData = {
        ...editorAPI.dsRead.platform.getAppDataByAppDefId(
          appDescription.appDefinitionId,
        ),
        latestVersion: stateManagement.platform.selectors.getLatestVersions(
          editorAPI.store.getState(),
        )[appDescription.appDefinitionId],
      } as AppData;
      if (shouldShowUpdateAction(app)) {
        acc.push(app);
      }
      return acc;
    },
    [],
  );
};

import constants from '#packages/constants';
import { ErrorReporter } from '@wix/editor-error-reporter';
import type { Scope } from '#packages/sections';

const { COMP_TYPES } = constants;

const COMP_TYPES_ALLOWED_TO_LIVE_ON_PAGE = new Set<string>([
  COMP_TYPES.SECTION,
  COMP_TYPES.ANCHOR,
  COMP_TYPES.ANCHORS_MENU,
]);

export const createBeforePublish = ({ editorAPI }: Scope) => {
  return () => {
    const currentPage = editorAPI.pages.getCurrentPage();
    const pageChildren = editorAPI.components
      .getChildren(currentPage)
      .filter((compRef) => !editorAPI.components.layout.isPinned(compRef))
      .filter((compRef) => {
        const compType = editorAPI.components.getType(compRef);
        return !COMP_TYPES_ALLOWED_TO_LIVE_ON_PAGE.has(compType);
      });

    if (!pageChildren.length) return;

    const pageChildrenTypes = pageChildren.map(editorAPI.components.getType);

    ErrorReporter.captureMessage(
      'Not allowed page components found before publish',
      {
        extra: {
          compTypes: pageChildrenTypes,
          pageId: currentPage.id,
        },
      },
    );
  };
};

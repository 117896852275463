import { type DocumentServicesObject } from 'types/documentServices';

const HEADER_TO_PAGES_GAP_NAME = 'headerToPagesGap';

interface GapFixerParams {
  distance: number;
}

const fixHeaderToPagesGap = (
  documentServices: DocumentServicesObject,
  { distance }: GapFixerParams,
): void => {
  // @ts-expect-error
  documentServices.siteSegments.updateGaps({ [HEADER_TO_PAGES_GAP_NAME]: 0 });

  const headerRef = documentServices.siteSegments.getHeader();
  const { height: currentHeight } =
    documentServices.components.layout.get(headerRef);
  const newHeight = currentHeight + distance;
  const updatedLayout = {
    height: newHeight,
  };
  documentServices.components.layout.update(headerRef, updatedLayout);
};

const getGaps = (ds: DocumentServicesObject): { [gapName: string]: number } =>
  // @ts-expect-error
  ds.siteSegments.getGaps();

export const enforceClosedGaps = async (
  documentServices: DocumentServicesObject,
): Promise<void> => {
  const requiredTasks = getGaps(documentServices);

  if (requiredTasks[HEADER_TO_PAGES_GAP_NAME]) {
    const distance = requiredTasks[HEADER_TO_PAGES_GAP_NAME];

    fixHeaderToPagesGap(documentServices, { distance });
    await documentServices.waitForChangesAppliedAsync();
  }
};

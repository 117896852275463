// @ts-nocheck
import _ from 'lodash';
import myLinesSection from '../shape/live/section1';
import themedLinesSection from '../shape/live/section2';
import videoCategory from '../video/videoCategory';
import * as addPanel from '#packages/oldAddPanel';

export default {
  getCategorySections() {
    const videoSections = videoCategory.getCategorySections();
    const filteredDecorativeSections = _.omit(
      addPanel.sections.getSections().decorative,
      Object.keys(videoSections),
    );
    return addPanel.dataUtil.mergeWithCommonSections(
      filteredDecorativeSections,
      Object.assign({ myLinesSection, themedLinesSection }, videoSections),
    );
  },
};

import React, { type FC } from 'react';
import { hoc } from '#packages/util';
import { AiTextCreatorEntryPoint } from '#packages/baseUI';
import {
  mapStateToProps,
  mapDispatchToProps,
} from './aiTextCreatorEntryPointMapper';
import { quickEditPanelOrigin } from '../consts';
import type { AiTextCreatorEntryPointProps } from './aiTextCreatorEntryPointTypes';

const AiTextCreatorEntryPointQuickEdit: FC<AiTextCreatorEntryPointProps> = ({
  openAiTextGeneratorPanel,
  onDataChanged,
}) => {
  const openAiTextGeneratorPanelQuickEdit = () => {
    openAiTextGeneratorPanel(onDataChanged);
  };

  return (
    <AiTextCreatorEntryPoint
      small
      withBigToSmallAppearance
      origin={quickEditPanelOrigin}
      onEntryPointClick={openAiTextGeneratorPanelQuickEdit}
    />
  );
};

const AiTextCreatorEntryPointConnect = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(AiTextCreatorEntryPointQuickEdit);

export default AiTextCreatorEntryPointConnect;

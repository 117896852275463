// @ts-nocheck
import React from 'react';
import { translate } from '#packages/i18n';
import SeoUrlSettings from '../seoUrlSettings';
import GooglePreviewExpandable from '../googlePreviewExpandable';
import experiment from 'experiment';
import { cx } from '#packages/util';

export default class extends React.Component {
  static displayName = 'blogSinglePostSeo';

  render() {
    return (
      <div className="seo-google tab-inner">
        <p className="title">{translate('Pages_Blog_Post_SEO_Title')}</p>
        <GooglePreviewExpandable
          isExpanded={true}
          shouldHideExpandingButton={true}
          pageData={this.props.menuItem.pageData}
          previewDescriptionText={translate('Pages_Blog_Post_SEO_Placeholder')}
          title={`${translate('Pages_Blog_Post_SEO_Preview')} | ${
            this.props.siteName
          }`}
        />
        {experiment.isOpen('se_blogChangeSinglePostPageName') ? (
          <SeoUrlSettings
            key="blogSeoTabChangePageName"
            pageData={this.props.menuItem.pageData}
            inputFocusTooltipMessage="SEO_URL_Changing_Warning"
            className={cx({ 'blog-seo-setting': true })}
          />
        ) : null}
      </div>
    );
  }
}

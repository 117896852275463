import { PANEL_TYPES } from '#packages/addPanelDataConsts';
import * as coreBi from '#packages/coreBi';
import * as leftBar from '#packages/leftBar';
import { newAddPanelAPI } from '#packages/newAddPanelAPI';
import { frames } from '#packages/panels';
import * as stateManagement from '#packages/stateManagement';
import {
  bi,
  fedopsLogger,
  hoc,
  keyGenerator,
  languages,
  sections,
  workspace,
} from '#packages/util';
import {
  Action,
  type AnySection,
  SectionType,
  AddPanel as AddPanelInnerComponent,
  type Category,
  type DispatchActionFn,
  ErrorState,
  HelpActionInitiator,
  Skin,
} from '@wix/add-panel-component';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as symbols from '@wix/santa-editor-symbols';
import { Preloader, TextButton } from '@wix/wix-base-ui';
import _ from 'lodash';
import type { MouseEventHandler } from 'react';
import { DynamicMediaBoxWrapper } from './components/dynamicMediaBoxWrapper/dynamicMediaBoxWrapper';
import React, {
  type ComponentType,
  type FC,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import type { WithBiLoggerProps } from 'types/bi';
import addPanelDragToStage from '../addPanel/dragToStage/addPanelDragToStage';
import dragBox from '../addPanel/dragToStage/dragBox';
import { BI_ORIGIN } from './constants';
import styles from './newAddPanel.scss';
import addPanelMapper from './newAddPanelMapper';
import type { AddPanelOwnProps, AddPanelProps } from './types';
import { useNewAddPanelData } from './useNewAddPanelData';
import { useTrackMaxScroll } from './useTrackMaxScroll';
import { useTrackCategoryOpen, useTrackSectionOpen } from './useTrackOpen';
import { LogoSection } from './components/logo/logoSection';

import type { CompRef, CompStructure, Point } from 'types/documentServices';
import { useAddPanelUtils } from './useAddPanelUtils';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

const PLAYER_PANEL_NAME = 'panels.focusPanels.howToVideoPlayer';

interface EditorSearchHeaderButton {
  onClick: MouseEventHandler;
  label: string;
}

const sendSentryError = (error: Error): void => {
  ErrorReporter.captureException(error, {
    tags: {
      component: 'new-add-panel',
    },
  });
};

const sendBiError = (
  error: Error,
  biLogger: WithBiLoggerProps['biLogger'],
): void => {
  biLogger.logError(bi.errors.NEW_ADD_PANEL.GENERAL);
};

const locale = languages.getLanguageCode();

const getCurrentCategoryHelpId = (
  currentCategoryId: Category['id'],
  categories: Category[],
) => categories.find(({ id }) => id === currentCategoryId)?.help?.helpId;

const EditorSearchHeaderButton: FC<EditorSearchHeaderButton> = ({
  onClick,
  label,
}) => (
  <div className={styles.headerSearchButtonWrapper}>
    <TextButton
      onClick={onClick}
      dataHook="add-panel-editor-search-button"
      shouldTranslate={false}
      className={styles.headerSearchButton}
      size="small"
      prefixIcon={
        <span className={styles.searchIcon}>
          <symbols.symbol name="editorSearchButton_NewWorkspace" />
        </span>
      }
    >
      {label}
    </TextButton>
  </div>
);

const NewAddPanelComponent: FC<AddPanelProps> = ({
  panelName,
  panelIndex,
  getPastePosition = () => {},
  onComponentAddedToStage = () => {},
  biLogger,
  category,
  sectionId,
  panelManager,
  storePath,
  addPreset,
  addCollection,
  addDataSet,
  addFormDataSet,
  startItemDrag,
  installAppMainAction,
  reportAddMenuDrag,
  openMediaManager,
  openAddSectionPanelSavedCategory,
  addBlankPage,
  openPhotoStudio,
  installAppAndNotify,
  blogMainAction,
  blogSecondaryAction,
  bookingsMainAction,
  myWidgets,
  savedComponents,
  fetchSavedComponentPreview,
  fetchSavedComponentsCollection,
  pasteSavedComponentItem,
  addExistingForm,
  createNewForm,
  deleteSavedComponent,
  renameSavedComponent,
  fileShareMainAction,
  eventsMainAction,
  eventsSecondaryAction,
  pricingPlansMainAction,
  onlineProgramsMainAction,
  groupsMainAction,
  forumMainAction,
  membersMainAction,
  storesMainAction,
  storesSecondaryAction,
  contentManagerMainAction,
  contentManagerSecondaryAction,
  openAccountSettings,
  addAppAndReportBi,
  relatedApps,
  toggleDevMode,
  openWixAppMarket,
  addCompOnClick,
  fetchCategoriesRelatedApps,
  customAddCompOnClick,
  isEditorSearchButtonVisible,
  isEditorSearchButtonVisibleInLeftPanel,
  handleSearchButtonClick,
  openPanelInteraction,
  installSuperAppOnClick,
  installSuperAppOnDrop,
  installAppWithCustomHandlerOnClick,
  installAppWithCustomHandlerOnDrop,
  trackCategoryClick,
  trackSectionClick,
  trackCategoryOpen: trackCategoryOpenInner,
  trackSectionOpen: trackSectionOpenInner,
  origin,
  getLiveChatSectionColors,
  getSuperAppData,
  hasSectionStyle,
  theme,
  trackMaxScroll: trackMaxScrollInner,
  trackHelpFromCategoryViewHeaderClick,
  setPathsState,
  pathsState,
  isMobileEditor,
  fireComponentAdded,
}) => {
  const [translate] = useTranslation();

  const { isDataLoaded, categories, error, deeplink, reloadData } =
    useNewAddPanelData({
      categoryId: category,
      sectionName: sectionId,
      myWidgets,
    });

  const { trackMaxScroll, saveMaxScrollItem } =
    useTrackMaxScroll(trackMaxScrollInner);
  const trackCategoryOpen = useTrackCategoryOpen(trackCategoryOpenInner);
  const trackSectionOpen = useTrackSectionOpen(trackSectionOpenInner);

  const handleError = (error: Error) => {
    sendSentryError(error);
    sendBiError(error, biLogger);
  };

  const [currentCategoryId, setCurrentCategory] = useState(
    () => categories?.[0]?.id,
  );

  const [sideBar, setSideBar] = useState(undefined);

  const handleComponentAddedToStage = (
    compRef: CompRef,
    compDef: CompStructure,
    type: 'click' | 'drag',
    mousePosition?: Point,
  ) => {
    onComponentAddedToStage(compRef, compDef);
    fireComponentAdded({
      compRef,
      origin: 'addPanel',
      type,
      mousePosition,
    });
  };

  // TODO: fix this eslint error
  // eslint-disable-next-line max-statements
  const handleAction: DispatchActionFn = (action: AnyFixMe) => {
    switch (action.type) {
      case Action.FedopsInteractionStarted: {
        fedopsLogger.interactionStarted(action.payload.interaction);

        return;
      }

      case Action.FedopsInteractionEnded: {
        fedopsLogger.interactionEnded(action.payload.interaction);

        return;
      }

      case Action.CloseAddPanel: {
        panelManager.closePanelByName(panelName);
        return;
      }

      case Action.ToggleDevMode: {
        const { categoryId, sectionTitle, itemId } = action.payload;

        toggleDevMode({ categoryId, sectionTitle, itemId });
        panelManager.closePanelByName(panelName);
        return;
      }

      case Action.HelpClick: {
        const { helpId, props, categoryId, initiator, biHelpParams } =
          action.payload;

        panelManager.openHelpCenter(helpId, props, biHelpParams);

        if (initiator === HelpActionInitiator.CategoryViewHeader) {
          trackHelpFromCategoryViewHeaderClick(categoryId);
        }

        return;
      }

      case Action.OpenAccountSettings: {
        const { path, itemId, categoryId, sectionTitle } = action.payload;
        openAccountSettings({ path, itemId, categoryId, sectionTitle });
        break;
      }

      case Action.PlayVideo: {
        const { title } = action.payload;
        const videoId = translate(keyGenerator.videoIdKey(title));

        biLogger.logEvent(coreBi.events.videoTooltip.THUMBNAIL_CLICK, {
          tooltipname: keyGenerator.titleKey(title),
          videoID: videoId,
        });

        panelManager.openPanel(
          PLAYER_PANEL_NAME,
          {
            videoId,
            origin: BI_ORIGIN,
          },
          true,
        );
        return;
      }

      case Action.ComponentClick: {
        const {
          category,
          section,
          customClickAndDropHandler,
          item = {},
          compDef,
        } = action.payload;

        const { id: categoryId } = category;
        const {
          translations: { title: sectionTitle },
          appDefinitionId,
          widgetId,
          props: { isSuperApp, styleParams },
        } = section;
        const { preset = {}, presetId, structure, id: itemId } = item;

        const tags = preset.tags || '';

        storePath(
          structure,
          // mediaManagerPreset,
        );

        if (customAddCompOnClick) {
          customAddCompOnClick({
            compDef,
            sectionTitle,
            tags,
            itemId,
            appDefinitionId: item.appDefinitionId,
            categoryId,
            position: getPastePosition(compDef),
          })?.then((compRef) => {
            handleComponentAddedToStage(compRef, compDef, 'click');
          });

          return;
        }

        if (customClickAndDropHandler) {
          installAppWithCustomHandlerOnClick({
            customClickAndDropHandler,
            appDefinitionId,
            widgetId,
            compDef,
            tags,
            itemId,
            sectionTitle,
            categoryId,
            presetId,
            styleParams,
            item,
            position: getPastePosition(compDef),
          })?.then((compRef) => {
            handleComponentAddedToStage(compRef, compDef, 'click');
          });

          panelManager.closePanelByName(panelName);

          return;
        }

        if (isSuperApp) {
          installSuperAppOnClick({
            appDefinitionId,
            widgetId,
            compStructure: compDef,
            tags,
            itemId,
            sectionTitle,
            categoryId,
            biOrigin: BI_ORIGIN,
            position: getPastePosition(compDef),
          })?.then((compRef) => {
            handleComponentAddedToStage(compRef, compDef, 'click');
          });

          return;
        }

        addCompOnClick({
          compDef,
          sectionTitle,
          tags,
          itemId,
          appDefinitionId: item.appDefinitionId,
          categoryId,
          position: getPastePosition(compDef),
        })?.then((compRef) => {
          handleComponentAddedToStage(compRef, compDef, 'click');
        });

        return;
      }

      case Action.Unmount: {
        const {
          payload: { pathsState },
        } = action;

        setPathsState(pathsState);

        return;
      }

      case Action.StartItemDrag: {
        const {
          event,
          categoryId,
          section,
          rect,
          item = {},
          itemId,
          itemStructure,
        } = action.payload;
        const {
          translations: { title: sectionTitle },
          appDefinitionId,
          widgetId,
          sectionName,
          props: { isSuperApp, styleParams },
        } = section;
        const { customClickAndDropHandler } = item;
        const presetId = item.presetId || item.itemId;
        const onAfterDrop = (compRef: CompRef, mousePosition: Point) =>
          handleComponentAddedToStage(
            compRef,
            itemStructure,
            'drag',
            mousePosition,
          );
        let onDrop;

        if (customClickAndDropHandler) {
          onDrop = installAppWithCustomHandlerOnDrop({
            customClickAndDropHandler,
            sectionTitle,
            categoryId,
            presetId,
            appDefinitionId,
            widgetId,
            compStructure: itemStructure,
            styleParams,
            item,
          });
        }

        if (isSuperApp) {
          onDrop = installSuperAppOnDrop({
            appDefinitionId,
            widgetId,
            biOrigin: BI_ORIGIN,
          });
        }

        reportAddMenuDrag({ itemId, categoryId, sectionTitle });
        startItemDrag(event, {
          categoryId,
          section: sectionName,
          structure: itemStructure,
          tags: item.preset?.tags,
          sectionTitle,
          appDefinitionId,
          itemId,
          rect,
          onDrop,
          onAfterDrop,
        });

        return;
      }

      case Action.OpenMediaManager: {
        const {
          payload: {
            biItemName,
            categoryId,
            sectionName,
            sectionTitle,
            mediaManagerOptions,
          },
        } = action;

        openMediaManager({
          categoryId,
          sectionName,
          sectionTitle,
          mediaManagerOptions,
          biItemName,
        });
        return;
      }

      case Action.OpenPhotoStudio: {
        const {
          payload: { item },
        } = action;
        const { mode } = item.photoStudioOptions;
        openPhotoStudio(item, { mode });
        return;
      }

      case Action.AddBlankPage: {
        const {
          payload: { item },
        } = action;
        addBlankPage(item);
        return;
      }

      case Action.CreateNewForm: {
        createNewForm();
        break;
      }

      case Action.AddExistingForm: {
        addExistingForm();
        break;
      }

      case Action.SectionOpen: {
        const {
          payload: { categoryId, sectionName },
        } = action;
        trackSectionOpen({ categoryId, sectionName });

        return;
      }

      case Action.AddPreset: {
        addPreset();
        return;
      }

      case Action.AddCollection: {
        addCollection();
        return;
      }

      case Action.AddDataSet: {
        const {
          payload: { item },
        } = action;

        addDataSet(item.structure);

        return;
      }

      case Action.AddFormDataSet: {
        const {
          payload: { item },
        } = action;

        addFormDataSet(item.structure);

        return;
      }

      case Action.InstallAppAndNotify: {
        const {
          payload: {
            item: { appDefinitionId },
          },
        } = action;

        installAppAndNotify(appDefinitionId);

        return;
      }

      case Action.BlogMainAction: {
        blogMainAction();
        return;
      }

      case Action.BlogSecondaryAction: {
        blogSecondaryAction();
        return;
      }

      case Action.BookingsMainAction: {
        bookingsMainAction();
        return;
      }

      case Action.SavedComponentsMyDesignsSectionMount: {
        fetchSavedComponentsCollection();
        return;
      }

      case Action.SavedComponentsComponentAppeared: {
        const {
          payload: { id },
        } = action;

        fetchSavedComponentPreview(id);

        return;
      }

      case Action.SavedComponentsStartDrag: {
        const {
          payload: {
            event,
            categoryId,
            sectionName,
            sectionTitle,
            dragItemData: { item, rect },
          },
        } = action;

        reportAddMenuDrag({ itemId: item.id, categoryId, sectionTitle });
        //TODO add onComponentAddedToStage call after component is added to stage
        startItemDrag(event, {
          categoryId,
          section: sectionName,
          sectionTitle,
          itemId: item.id,
          structure: item.structure,
          tags: item.preset?.tags,
          rect,
          onDrop: (pasteCoords, draggedItem, dropContainer) => {
            pasteSavedComponentItem(
              item.itemId,
              false,
              pasteCoords,
              'drag',
              dropContainer,
            );
          },
        });

        return;
      }

      case Action.SavedComponentsFooterCTAClick: {
        openAddSectionPanelSavedCategory('add_panel_my_designs');
        return;
      }

      case Action.MyWidgetsStartDrag: {
        const {
          payload: {
            event,
            sectionName,
            dragItemData: { item, rect },
          },
        } = action;
        //TODO add onComponentAddedToStage call after component is added to stage
        startItemDrag(event, {
          section: sectionName,
          itemId: item.itemId,
          structure: item.structure,
          rect,
          onDrop: (pasteCoords, widgetItem, containerRef) => {
            item.onDrop(pasteCoords, item, containerRef);
          },
        });

        return;
      }

      case Action.SavedComponentsAdd: {
        const {
          payload: { id, applyTheme },
        } = action;

        pasteSavedComponentItem(id, applyTheme, null, 'click');

        return;
      }

      case Action.SavedComponentsRename: {
        const {
          payload: { id, name },
        } = action;

        renameSavedComponent(id, name);

        return;
      }

      case Action.SavedComponentsDelete: {
        const {
          payload: { id, name },
        } = action;

        deleteSavedComponent(id, name);
        return;
      }

      case Action.EventsMainAction: {
        const { category, section } = action.payload;

        eventsMainAction({
          biParams: {
            // there is  only one type of action in  events app - 'click'
            addingMethod: 'click',
            category: category.id,
            section: section.sectionName,
            //  NOTE: probably we don't have an preset id in the new add panel
            presetId: null,
          },
        });
        return;
      }

      case Action.EventsSecondaryAction: {
        eventsSecondaryAction();
        return;
      }

      case Action.WelcomeSectionInstall: {
        const { appDefinitionId } = action.payload.section;
        installAppMainAction(appDefinitionId);
        return;
      }

      case Action.FileShareMainAction: {
        fileShareMainAction();
        return;
      }

      case Action.PricingPlansMainAction: {
        pricingPlansMainAction();
        return;
      }

      case Action.GroupsMainAction: {
        groupsMainAction();
        return;
      }

      case Action.ForumMainAction: {
        forumMainAction();
        return;
      }

      case Action.OnlineProgramsMainAction: {
        onlineProgramsMainAction();
        return;
      }

      case Action.MembersAreaMainAction: {
        membersMainAction();
        return;
      }

      case Action.StoresMainAction: {
        storesMainAction();
        return;
      }

      case Action.StoresSecondaryAction: {
        storesSecondaryAction();
        return;
      }

      case Action.ContentManagerMainAction: {
        contentManagerMainAction();
        return;
      }

      case Action.ContentManagerSecondaryAction: {
        contentManagerSecondaryAction();

        return;
      }

      case Action.AddRelatedApp: {
        const {
          payload: { app, categoryId },
        } = action;

        addAppAndReportBi({
          category: categoryId,
          referralInfo: PANEL_TYPES.ADD_PANEL,
          app,
        });

        return;
      }

      case Action.DragRelatedApp: {
        const {
          payload: {
            categoryId,
            sectionTitle,
            app,
            event,
            dragItemData: { rect, appItem },
          },
        } = action;
        //TODO add onComponentAddedToStage call after component is added to stage
        startItemDrag(event, {
          categoryId,
          section: sectionTitle,
          sectionTitle,
          itemId: app.appDefinitionId,
          structure: appItem.structure,
          rect,
          onDrop: () =>
            addAppAndReportBi({
              category: categoryId,
              referralInfo: PANEL_TYPES.ADD_PANEL,
              app,
            }),
        });

        return;
      }

      case Action.OpenWixAppMarket: {
        const {
          payload: { categoryId, referralInfo },
        } = action;

        openWixAppMarket(categoryId, referralInfo);

        return;
      }

      case Action.SearchButtonClick: {
        handleSearchButtonClick();
        return;
      }

      case Action.ReloadData: {
        reloadData();
        return;
      }

      case Action.CategoryOpen: {
        const {
          payload: { categoryId, bi },
        } = action;

        setCurrentCategory(categoryId);
        trackCategoryOpen({ categoryId, origin, biParams: bi });
        trackMaxScroll();
        return;
      }

      case Action.CategoryClick: {
        const {
          payload: { categoryId, biParams },
        } = action;

        trackCategoryClick(categoryId, biParams);

        return;
      }

      case Action.SectionClick: {
        const {
          payload: { categoryId, sectionName },
        } = action;

        trackSectionClick(categoryId, sectionName);

        return;
      }

      case Action.ComponentMouseEnter: {
        const {
          payload: { metaData },
        } = action;

        saveMaxScrollItem(metaData);

        return;
      }

      case Action.OpenLeftbarSidePanel: {
        const {
          payload: { sideBarComponent },
        } = action;

        setSideBar(sideBarComponent);

        return;
      }
      default:
    }
  };

  useMemo(() => {
    if (relatedApps.length === 0 && categories?.length) {
      fetchCategoriesRelatedApps(categories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, relatedApps]);

  useEffect(() => {
    if (!isDataLoaded || error) {
      return;
    }

    openPanelInteraction?.end();
  }, [isDataLoaded, error, openPanelInteraction]);

  const getExternalSectionComponent = (
    section: AnySection,
  ): ComponentType<any> => {
    switch (section.type) {
      case SectionType.SiteMediaSection:
      case SectionType.DynamicMediaSectionsBox:
      case SectionType.CategorySearchSection:
        return DynamicMediaBoxWrapper;
      case SectionType.Logo:
        return LogoSection;
      default:
        return null;
    }
  };

  const dataVersion = newAddPanelAPI.getVersion();

  const addPanelUtils = useAddPanelUtils({
    getLiveChatSectionColors,
    getSuperAppData,
    hasSectionStyle,
    theme,
  });

  let panelContent;

  const currentCategoryHelpId = currentCategoryId
    ? getCurrentCategoryHelpId(currentCategoryId, categories)
    : null;

  if (error) {
    panelContent = (
      <ErrorState error={error} utils={addPanelUtils} onAction={handleAction} />
    );
  } else if (!isDataLoaded) {
    panelContent = (
      <div className={styles.spinnerWrapper}>
        <Preloader className="medium" />
      </div>
    );
  } else if (categories) {
    panelContent = (
      <AddPanelInnerComponent
        categories={categories}
        dataVersion={dataVersion}
        myWidgets={myWidgets}
        savedComponents={savedComponents}
        relatedApps={relatedApps}
        deepLinkPath={deeplink}
        pathsState={pathsState}
        onError={handleError}
        onAction={handleAction}
        locale={locale}
        getExternalSectionComponent={getExternalSectionComponent}
        isEditorSearchButtonVisible={isEditorSearchButtonVisible}
        utils={addPanelUtils}
        skin={isNewWorkspace ? Skin.ClassicFacelift : Skin.Classic}
      />
    );
  }

  const addPanelDesktopTitle = sections.isSectionsEnabled()
    ? 'add_elements_panel_title'
    : 'add_panel_title';

  const leftPanelHeaderLabelKey = isMobileEditor
    ? 'mobile_add_panel_header_title'
    : addPanelDesktopTitle;

  const LeftPanelFrame = isNewWorkspace
    ? leftBar.LeftPanelFrame
    : frames.LeftPanelFrame;

  return (
    <LeftPanelFrame
      panelClass="add-panel"
      panelName={panelName}
      panelIndex={panelIndex}
      label={isNewWorkspace ? translate(leftPanelHeaderLabelKey) : undefined}
      helpId={isNewWorkspace ? currentCategoryHelpId : undefined}
      dynamicWidth={isNewWorkspace ? true : undefined}
      headerActionsBar={
        // TODO: think about moving new workspaces header to add panel component
        isEditorSearchButtonVisibleInLeftPanel ? (
          <EditorSearchHeaderButton
            onClick={handleSearchButtonClick}
            label={translate('Topbar_Searchbar_Text')}
          />
        ) : null
      }
      sidePanel={sideBar}
    >
      {panelContent}
    </LeftPanelFrame>
  );
};

const connect = _.flowRight(
  hoc.withDragToStage(addPanelDragToStage, dragBox),
  addPanelMapper,
  stateManagement.bi.hoc.withBiLogger,
);

export const NewAddPanel = connect(
  NewAddPanelComponent,
) as ComponentType<AddPanelOwnProps>;

._container_1tqsd_1 {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 18px; }

._toggleSwitch_1tqsd_7 {
  display: flex;
  gap: 12px; }

._content_1tqsd_11 {
  padding-left: 42px; }

._groupsList_1tqsd_14 {
  display: flex;
  gap: 6px;
  flex-wrap: wrap; }

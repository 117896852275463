// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as core from '#packages/core';
import constants from './constants';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import { PanelHeader } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: '404_errorPopup',
  propTypes: {},
  mixins: [core.mixins.editorAPIMixin],
  openErrorPageInNewTab() {
    window.open(this.getConstants().routerAddress);
  },
  getConstants() {
    if (this.constants) {
      return this.constants;
    }
    let retVal = constants[this.props.errorInfo.statusCode];
    if (!retVal) {
      retVal = constants.other;
      retVal = _.clone(retVal);
      retVal.title = translate(retVal.title).replace(
        '{error_code}',
        this.props.errorInfo.statusCode,
      );
    } else {
      retVal = _.clone(retVal);
      retVal.title = translate(retVal.title);
    }
    this.constants = retVal;
    return retVal;
  },
  onClose() {
    this.getEditorAPI().panelManager.closePanelByName(
      'panels.errorPages.errorPagePanel',
    );
  },
  render() {
    return (
      <div className="panel-frame-container screen-centered-frame error-page-panel-frame-z-index ">
        <div className="confirmation-panel-frame info-error-page error-page-panel">
          <PanelHeader
            key="header"
            onClose={this.onClose}
            noHelpBtn
            className="dark"
          >
            <span key="headerTitle">{this.getConstants().title}</span>
          </PanelHeader>
          <div className="content-wrapper">
            <div className="error-logo">
              <baseUI.symbol name={this.getConstants().symbol} />
            </div>

            <div className="description-wrapper">
              <span className="description-line">
                {translate(this.getConstants().errorBodyTop)}
              </span>
              <br />
              <span className="description-line">
                <b>{this.props.routerAddress}</b>
              </span>
              <br />
              <span className="description-line">
                {translate(this.getConstants().errorBodyBottom)}
              </span>
            </div>
          </div>
          <footer>
            <baseUI.button
              key="cancelButton"
              onClick={this.openErrorPageInNewTab}
              label={this.getConstants().btnViewErrorPage}
              className="btn-confirm-secondary  btn-md"
            />
            <baseUI.button
              onClick={this.onClose}
              label={this.getConstants().btnClose}
              className="btn-confirm-primary  btn-md"
            />
          </footer>
        </div>
        <div className="dark-frame-overlay" />
      </div>
    );
  },
});

import {
  startWelcomeTour,
  startNewWorkspaceLeftBarHighlightTour,
  startAdiToEditorTour,
  startEditorToEditorTour,
} from '#packages/editorTours';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';

import { dispatchEditorAPI } from '../../../topBarComponent/topBarMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  HelpMenuAPIOwnProps,
  HelpMenuAPIStateProps,
  HelpMenuAPIDispatchProps,
} from './helpMenuAPI';

const { getOverriddenExternalLinks } = stateManagement.externalLinks.selectors;

const mapStateToProps: MapStateToProps<
  HelpMenuAPIStateProps,
  HelpMenuAPIOwnProps
> = ({ editorAPI, state }) => {
  return {
    isMobileEditor: editorAPI.isMobileEditor(),
    isDeveloperModeEnabled: Boolean(editorAPI.developerMode.isEnabled()),
    isChatVisible: stateManagement.unifiedChat.selectors.isChatVisible(state),
    overriddenExternalLinks: getOverriddenExternalLinks(state),
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  HelpMenuAPIDispatchProps,
  HelpMenuAPIOwnProps
> = (dispatch) => ({
  openHelpCenter(helpId, props, biHelpParams) {
    dispatch(
      stateManagement.panels.actions.openHelpCenter(
        helpId,
        props,
        biHelpParams,
      ),
    );
  },
  openHelpCenterHome(type, props, biHelpParams) {
    dispatch(
      stateManagement.panels.actions.openHelpCenterHome(
        type,
        props,
        biHelpParams,
      ),
    );
  },
  openSearchPanel(origin) {
    dispatch(
      stateManagement.editorSearch.actions.openEditorSearchPanel({ origin }),
    );
  },
  openPanel(panelName, panelProps, leavePanelsOpen) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  showOrHideChat: (visibility) =>
    dispatch(stateManagement.unifiedChat.actions.showOrHideChat(visibility)),
  startWelcomeTour: dispatchEditorAPI(dispatch, (editorAPI, origin) => {
    return util.workspace.isNewWorkspaceFTETourEnabled()
      ? startNewWorkspaceLeftBarHighlightTour(editorAPI, origin)
      : startWelcomeTour(editorAPI, origin);
  }),
  startAdiToEditorTour: dispatchEditorAPI(dispatch, (editorAPI, origin) => {
    return startAdiToEditorTour(editorAPI, origin);
  }),
  startEditorToEditorTour: dispatchEditorAPI(dispatch, (editorAPI, origin) => {
    return startEditorToEditorTour(editorAPI, origin);
  }),

  openNewReleasesFeed() {
    dispatch(
      stateManagement.panels.actions.openRightPanel(
        'rEditor.NewReleasesFeedPanel',
      ),
    );
  },
  openHelpHome() {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.HELP_HOME_OPEN,
    );
    dispatch(
      stateManagement.panels.actions.openRightPanel('rEditor.HelpHomePanel', {
        origin: 'help_menu',
      }),
    );
  },
});

export { mapStateToProps, mapDispatchToProps };

import { createReduxStore } from '#packages/apilib';
import type { WORKSPACE_RIGHT_PANEL_NAMES } from '#packages/constants';
import type {
  PanelContributedConfig,
  PanelOpenConfig,
  DrillInPanelConfig,
} from './workspaceRightPanelApi';

export interface State {
  isOpened: boolean;
  contributedPanels: {
    [key in WORKSPACE_RIGHT_PANEL_NAMES]?: PanelContributedConfig;
  };
  activePanelConfig: PanelOpenConfig;
  contributedDrillInPanels: {
    [uniqueId: string]: DrillInPanelConfig;
  };
  activeDrillInUniqueIds: Array<string>;
}

const getInitialState = (): State => ({
  activePanelConfig: {
    panelName: null,
    panelWidth: 0,
    title: '',
    helpId: '',
    renderHeader: true,
  },
  contributedDrillInPanels: {},
  isOpened: false,
  contributedPanels: {},
  activeDrillInUniqueIds: [],
});

export const WorkspaceRightPanelStore = createReduxStore({
  getInitialState,
  selectors: {
    getIsOpened: (state: State) => state.isOpened,
    getPanelWidth: (state: State) => state.activePanelConfig.panelWidth,
    getTitle: (state: State) => state.activePanelConfig.title,
    getHelpId: (state: State) => state.activePanelConfig.helpId,
    getOnHelp: (state: State) => state.activePanelConfig.onHelp,
    getOnClose: (state: State) => state.activePanelConfig.onClose,
    getActivePanelName: (state: State) => state.activePanelConfig.panelName,
    getActivePanelDrillInUniqueIds: (state: State) =>
      state.activePanelConfig.drillInUniqueIds,
    getGroupType: (state: State) => state.activePanelConfig.groupType,
    getContextMenuConfig: (state: State) =>
      state.activePanelConfig.contextMenuConfig,
    getRenderHeader: (state: State) => state.activePanelConfig.renderHeader,
    getContributedPanels: (state: State) => state.contributedPanels,
    getContributedDrillInPanels: (state: State) =>
      state.contributedDrillInPanels,
    getActiveDrillInUniqueIds: (state: State) => state.activeDrillInUniqueIds,
    getActiveDrillInDrillInUniqueIds: (state: State) => {
      const activeDrillInPanelKey =
        state.activeDrillInUniqueIds[state.activeDrillInUniqueIds.length - 1];
      return state.contributedDrillInPanels[activeDrillInPanelKey]
        .drillInUniqueIds;
    },
    getActiveDrillInPanelName: (state: State) => {
      const activeDrillInPanelKey =
        state.activeDrillInUniqueIds[state.activeDrillInUniqueIds.length - 1];
      return state.contributedDrillInPanels[activeDrillInPanelKey].panelName;
    },
    getActiveDrillInOnClose: (state: State) => {
      const activeDrillInPanelKey =
        state.activeDrillInUniqueIds[state.activeDrillInUniqueIds.length - 1];
      return state.contributedDrillInPanels[activeDrillInPanelKey].onClose;
    },
  },
  actions: {
    open: (
      state,
      {
        panelWidth,
        title,
        helpId,
        onHelp,
        onClose,
        panelName,
        groupType,
        contextMenuConfig,
        drillInPanelName,
        renderHeader = true,
      },
    ) => {
      return {
        ...state,
        activePanelConfig: {
          panelName,
          groupType,
          panelWidth,
          title,
          helpId,
          onHelp,
          onClose,
          contextMenuConfig,
          drillInPanelName,
          drillInUniqueIds: [],
          renderHeader,
        },
        isOpened: true,
      };
    },
    updateActivePanelConfig: (state: State, newConfig: PanelOpenConfig) => {
      return {
        ...state,
        activePanelConfig: {
          ...state.activePanelConfig,
          ...newConfig,
        },
      };
    },
    close: (state: State) => {
      return {
        ...state,
        activePanelConfig: getInitialState().activePanelConfig,
        isOpened: false,
      };
    },
    contributePanel: (state, newPanel) => {
      return {
        ...state,
        contributedPanels: {
          ...state.contributedPanels,
          [newPanel.panelName]: newPanel,
        },
      };
    },
    contributeDrillInPanel: (state, newPanel) => {
      return {
        ...state,
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [newPanel.uniqueId]: newPanel,
        },
      };
    },
    removeDrillInPanel: (state, uniqueId: string) => {
      const { [uniqueId]: toDelete, ...otherPanels } =
        state.contributedDrillInPanels;
      return {
        ...state,
        contributedDrillInPanels: otherPanels,
      };
    },
    removeAllDrillInPanels: (state: State) => {
      return {
        ...state,
        contributedDrillInPanels: {},
        activeDrillInUniqueIds: [],
      };
    },
    openDrillIn: (
      state,
      uniqueId: string,
      zIndex: number,
      updatedConfig?: Partial<DrillInPanelConfig>,
    ) => {
      const drillInConfig = state.contributedDrillInPanels[uniqueId];
      return {
        ...state,
        activeDrillInUniqueIds: [...state.activeDrillInUniqueIds, uniqueId],
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [uniqueId]: {
            ...drillInConfig,
            isOpen: true,
            zIndex,
            drillInUniqueIds: [],
            ...updatedConfig,
          },
        },
      };
    },
    updateDrillInConfig: (
      state: State,
      uniqueId: string,
      newConfig: Partial<DrillInPanelConfig>,
    ) => {
      const originalConfig = state.contributedDrillInPanels[uniqueId];
      return {
        ...state,
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [uniqueId]: { ...originalConfig, ...newConfig },
        },
      };
    },
    closeDrillIn: (state: State) => {
      const panelIndex = state.activeDrillInUniqueIds.length - 1;
      const uniqueId = state.activeDrillInUniqueIds[panelIndex];
      const drillInConfig = state.contributedDrillInPanels[uniqueId];
      const activeDrillInUniqueIds = state.activeDrillInUniqueIds;
      activeDrillInUniqueIds.pop();
      return {
        ...state,
        activeDrillInUniqueIds,
        contributedDrillInPanels: {
          ...state.contributedDrillInPanels,
          [uniqueId]: {
            ...drillInConfig,
            isOpen: false,
            panelTitle: '',
            zIndex: null,
          },
        },
      };
    },
  },
});

import { wrapExternalComponentPanel } from '#packages/compPanelInfra';
import { hasComponentPart, loadComponentPart } from './componentRegistry';
import type { ComponentPanelType } from './componentRegistry';

import type React from 'react';
import type { EditorDataFunction } from '@wix/editor-component-data-resolver';
import type { ComponentPanelDefinition } from '@wix/editor-component-types';

type ComponentPanelData = Record<string, AnyFixMe>;
export type ComponentPanelDataReturn = React.ComponentType | EditorDataFunction;

const componentPanels: ComponentPanelData = {};
const componentPanelsDefinitions: ComponentPanelData = {};

function hasComponentPanel(
  componentType: string,
  componentPanelType: ComponentPanelType | string,
): componentPanelType is ComponentPanelType {
  return hasComponentPart(
    componentType,
    componentPanelType as ComponentPanelType,
  );
}

async function loadComponentPanelDefinition(
  componentType: string,
  componentPanelType: ComponentPanelType | string,
): Promise<ComponentPanelDataReturn> {
  if (!hasComponentPanel(componentType, componentPanelType)) {
    return;
  }

  if (!componentPanelsDefinitions?.[componentType]?.[componentPanelType]) {
    const componentPanelDefinition = await loadComponentPart(
      componentType,
      componentPanelType,
    );

    componentPanelsDefinitions[componentType] =
      componentPanelsDefinitions[componentType] || {};

    componentPanelsDefinitions[componentType][componentPanelType] =
      componentPanelDefinition;
  }

  return componentPanelsDefinitions[componentType][componentPanelType];
}

async function loadComponentPanel(
  componentType: string,
  componentPanelType: ComponentPanelType | string,
): Promise<React.ComponentType> {
  if (!hasComponentPanel(componentType, componentPanelType)) {
    return;
  }

  if (!componentPanels?.[componentType]?.[componentPanelType]) {
    const componentPanelDefinition = (await loadComponentPart(
      componentType,
      componentPanelType,
    )) as ComponentPanelDefinition;

    componentPanels[componentType] = componentPanels[componentType] || {};

    componentPanels[componentType][componentPanelType] =
      wrapExternalComponentPanel({
        componentType,
        componentPanelType,
        componentPanelDefinition,
        componentPanelSource: 'editor-elements-registry',
      });
  }

  return componentPanels[componentType][componentPanelType];
}

export { hasComponentPanel, loadComponentPanel, loadComponentPanelDefinition };

import { createEntryPoint } from '#packages/apilib';
import { DataBindingScope, DataBindingApi } from './dataBinding';

import { DataBindingApiKey } from '#packages/apis';

export const DataBindingEntrypoint = createEntryPoint({
  name: 'DataBindingEntry',
  Scope: DataBindingScope,
  publicApi({ contributeApi }) {
    contributeApi(DataBindingApiKey, DataBindingApi);
  },
  async initialize() {},
});

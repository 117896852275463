import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorAPIKey, ComponentsApiKey } from '#packages/apis';
import { TextUploadToStageApiKey } from './publicApiKey';
import { TextUploadToStageApi } from './textUploadToStageApi';

export class TextUploadToStageScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  components = this.useDependency(ComponentsApiKey);
}

export const TextUploadToStageEntryPoint = createEntryPoint({
  name: 'TextUploadToStage',
  Scope: TextUploadToStageScope,
  publicApi({ contributeApi }) {
    contributeApi(TextUploadToStageApiKey, TextUploadToStageApi);
  },
  async initialize() {},
});

import type { EditorAPI } from '#packages/editorAPI';

const initPro = () => {
  const script = document.createElement('script');
  script.async = true;
  script.src =
    'https://static.parastorage.com/services/classic-editor-resources/1.468.0/pro/index.js';
  document.body.appendChild(script);
};

export const initDebugTools = (editorAPI: EditorAPI) => {
  window.editorAPI = editorAPI;
  initPro();
};

import _ from 'lodash';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { MouseEvent } from 'react';

export function getRecursiveItem(
  compId: string,
  obj: object | boolean | undefined | object[],
  path: string[],
): any {
  // Please see the type definition. We really need it
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys, lodash/prefer-lodash-chain
  return _.keys(obj).map(function (key: any) {
    // eslint-disable-next-line
    // @ts-ignore
    const newObj: object = obj[key];
    const newPath = path.concat(key);
    return {
      key,
      label: key,
      onClick(event: MouseEvent) {
        console.info(compId, newPath.join('.'), newObj);
        event.stopPropagation();
      },
      items:
        _.isString(newObj) || _.isNumber(newObj)
          ? []
          : getRecursiveItem(compId, newObj, newPath),
      item: {
        enabled: true,
      },
    };
  });
}

export const createRefComponentStructure = ({
  appDefinitionId,
  widgetId,
  variationId,
}: {
  appDefinitionId: string;
  widgetId: string;
  variationId: string;
}) => {
  return {
    componentType: 'wysiwyg.viewer.components.RefComponent',
    layout: {
      x: 20,
      y: 20,
      fixedPosition: false,
      width: 200,
      height: 200,
      scale: 1,
      rotationInDegrees: 0,
    },
    type: 'RefComponent',
    style: 'ref1',
    data: {
      type: 'WidgetRef',
      appDefinitionId,
      widgetId,
      variationId,
    },
  };
};

export const generateNicknameForInnerElement = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => {
  const nicknameInWidget =
    editorAPI.platform.controllers.connections.getPrimaryConnection(compRef)
      ?.role ?? 'innerElement';

  let nicknameSuffix = 1;

  while (
    editorAPI.components.code.validateNickname(
      compRef,
      `${nicknameInWidget}${nicknameSuffix}`,
    ) !== 'VALID'
  ) {
    nicknameSuffix++;
  }

  editorAPI.components.code.setNickname(
    compRef,
    `${nicknameInWidget}${nicknameSuffix}`,
  );
};

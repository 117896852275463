import { sections } from '#packages/util';
import { editorPlugins } from '#packages/stateManagement';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, CompStructure } from 'types/documentServices';

const getAnchorsStructure = (_editorAPI: EditorAPI): CompStructure => {
  return {
    type: 'Component',
    skin: 'wysiwyg.common.components.anchor.viewer.skins.AnchorSkin',
    layout: {
      width: 1918,
      height: 21,
      x: 0,
      y: 115,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    componentType: 'wysiwyg.common.components.anchor.viewer.Anchor',
    data: {
      type: 'Anchor',
      metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
      name: 'Anchor 1',
      compId: 'i5rzonck_3',
    },
    style: {
      type: 'TopLevelStyle',
      id: 'Anchor_1',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      } as any,
      style: { groups: {}, properties: {}, propertiesSource: {} },
      componentClassName: '',
      pageId: '',
      compId: '',
      styleType: 'system',
      skin: 'wysiwyg.common.components.anchor.viewer.skins.AnchorSkin',
    },
    id: 'i5rzonck_3',
  };
};

const getAnchorTargetContainer = (
  editorAPI: EditorAPI,
  {
    anchorDef,
    anchorPosition,
    pageRef,
  }: {
    anchorDef: CompStructure;
    anchorPosition?: { y: number };
    pageRef: CompRef;
  },
): CompRef => {
  if (sections.isSectionsEnabled()) {
    const sectionRef = sections.getSectionLikeContainerToPaste(
      editorAPI,
      [anchorDef],
      { position: anchorPosition },
    );

    return sectionRef || pageRef;
  }

  return pageRef;
};

const getAnchorPositionInContainer = (
  editorAPI: EditorAPI,
  {
    anchorDef,
    anchorPosition,
    containerRef,
  }: {
    anchorDef: CompStructure;
    anchorPosition?: { y: number };
    containerRef: CompRef;
  },
): { y: number } => {
  if (anchorPosition) {
    const containerLayout =
      editorAPI.components.layout.getRelativeToStructure(containerRef);

    return {
      y: anchorPosition.y - containerLayout.y,
    };
  }

  const anchorPositionInContainer =
    editorAPI.pasteLogic.addPanelPasteLogic.getPastePosition(
      editorAPI,
      anchorDef.layout,
      containerRef.id,
      'navigation',
      [anchorDef],
    );

  if (
    editorAPI.utils.isPage(containerRef) &&
    !editorAPI.utils.isSameRef(editorAPI.pages.getCurrentPage(), containerRef)
  ) {
    const { getViewPort } = editorPlugins.domMeasurements.selectors;
    const viewPortLayout = getViewPort(editorAPI.store.getState());

    return {
      y: anchorPositionInContainer.y - viewPortLayout.scrollTop,
    };
  }

  return {
    y: anchorPositionInContainer.y,
  };
};

export async function addNewAnchor(
  editorAPI: EditorAPI,
  {
    pageRef,
    anchorPosition,
    origin,
  }: { pageRef: CompRef; anchorPosition?: { y: number }; origin?: string },
): Promise<CompRef> {
  return new Promise<CompRef>((resolve, reject) => {
    try {
      const anchorDef = getAnchorsStructure(editorAPI);
      const containerRef = getAnchorTargetContainer(editorAPI, {
        anchorDef,
        anchorPosition,
        pageRef,
      });
      const positionInContainer = getAnchorPositionInContainer(editorAPI, {
        anchorDef,
        anchorPosition,
        containerRef,
      });

      return editorAPI.components.add(
        containerRef,
        {
          ...anchorDef,
          layout: positionInContainer,
        },
        null,
        (compRef) => {
          editorAPI.history.add('added anchor');

          resolve(compRef);
        },
        origin,
      );
    } catch (error) {
      reject(error);
    }
  });
}

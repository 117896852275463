import React, { Component, type ComponentType } from 'react';
import _ from 'lodash';
import constants, { type ProgressStatus } from '#packages/constants';
import * as util from '#packages/util';

import { dispatchEditorAPI } from '../../topBarComponent/topBarMapper';
import { deriveSavePanelDataFromProps } from '../../topBarComponent/mapTopBarPropsToButtons';
import TextAndLinkPanel from '../../dropPanel/textAndLinkPanel';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';

const { BUTTONS } = constants.ROOT_COMPS.TOPBAR;

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

interface WithSaveDropPanelOwnProps {}

interface WithSaveDropPanelStateProps {
  isFirstSave: boolean;
  isDraftMode: boolean;
  autosaveEnabled: boolean;
  autosaveDisabledOnDS: boolean;
  savingStatus: ProgressStatus;
}

interface WithSaveDropPanelDispatchProps {
  openRevisions: () => void;
}

type WithSaveDropPanelProps = WithSaveDropPanelOwnProps &
  WithSaveDropPanelStateProps &
  WithSaveDropPanelDispatchProps;

export interface ComponentWithSaveDropPanelProps {
  dropPanelContent?: Component | null;
  dropPanelKey?: string;
}

const mapStateToProps: MapStateToProps<
  WithSaveDropPanelStateProps,
  WithSaveDropPanelOwnProps
> = ({ editorAPI, state }) => ({
  isFirstSave: _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false,
  isDraftMode: _.invoke(editorAPI, 'dsRead.generalInfo.isDraft'),
  autosaveEnabled: !!editorAPI.autosaveManager.isAutosaveEnabled(),
  autosaveDisabledOnDS:
    _.invoke(editorAPI, 'dsRead.getAutoSaveInfo')?.shouldAutoSave === false,
  savingStatus: state.savingStatus,
});

const mapDispatchToProps: MapDispatchToProps<
  WithSaveDropPanelDispatchProps,
  WithSaveDropPanelOwnProps
> = (dispatch) => ({
  openRevisions: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openRevisions(),
  ),
});

const initialState: AnyFixMe = {
  dropPanelKey: undefined,
  dropPanelContent: undefined,
};

const withSaveDropPanel = <P extends WithSaveDropPanelProps>(
  WrappedComponent: ComponentType<P & ComponentWithSaveDropPanelProps>,
) => {
  class WithSaveDropPanel extends Component<WithSaveDropPanelProps> {
    state = initialState;

    static getDerivedStateFromProps(props: AnyFixMe) {
      const panelData = deriveSavePanelDataFromProps(props);

      return {
        dropPanelKey: BUTTONS.SAVE,
        dropPanelContent: <TextAndLinkPanel panelData={panelData} />,
      };
    }

    render() {
      const { props } = this;
      const { dropPanelContent, dropPanelKey } = this.state;

      return React.createElement(
        WrappedComponent,
        _.defaults({}, props as P, {
          dropPanelKey,
          dropPanelContent,
        }),
      );
    }
  }

  return connect(
    EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  )(WithSaveDropPanel);
};

export default withSaveDropPanel;

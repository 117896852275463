import React, { Suspense } from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import constants from '#packages/constants';
import { ToolPanelFrame } from '../../../frames';
import * as higherOrderComponents from '#packages/higherOrderComponents';
import * as utils from '@wix/santa-editor-utils';
import { mapDispatchToProps, mapStateToProps } from './ColorPickerPanelMapper';
import type {
  ColorPickerPanelProps,
  ColorPickerValue,
  GradientType,
} from './types';

const WixEditorAdapter = React.lazy(() =>
  import('@wix/color-picker').then((module) => ({
    default: module.WixEditorAdapter,
  })),
);

const { connect, STORES } = utils.hoc;

class ColorPickerPanelFrame extends React.Component<ColorPickerPanelProps> {
  static displayName = 'ColorPickerPanelWithGradients';
  static defaultProps = {
    showPanelHeader: true,
  };

  handleChange = (
    value: ColorPickerValue,
    isPreview?: boolean,
    isOriginalColor?: boolean,
  ) =>
    this.props.onChange(value, {
      isHover: isPreview,
      isMouseOut: !!isOriginalColor,
    });

  render() {
    const {
      updatedValue,
      panelMode,
      getThemeColors,
      getColorDefaults,
      getLinkedThemeColorFromColorDefault,
      openDesignPanelColorView,
      openDesignPanelAdvancedSettingsView,
      openThemeManager,
      addColorHistoryEntry,
      notifyClosePanel,
      notifyOpenPanel,
      onCancel,
      onClose,
      left,
      top,
      previewOnHover,
      panelName,
      isBlockingLayerFullScreen,
      shouldBlockOuterScroll,
      reportMeasurements,
      measurements,
      showColorModalConfirmButtons,
      getCustomColors,
      handleAddCustomColor,
      customAddedGradients,
      customAddedMeshes,
      handleAddGradient,
      handleAddMesh,
      colorResolver,
      componentId,
      componentType,
      msid,
      editorViewMode,
      focusedPageId,
      isDraft,
      isZoomOut,
      viewerName,
      shouldExtendDefaultShortcuts,
      keyboardShortcuts,
      supportNewColorPalette,
      shouldCloseSelf = true,
      openColorDefaultsHelp,
      allowEyeDropper,
      handleAddThemeColor,
      loadImage,
      preserveRGBA,
    } = this.props;

    const supportedGradientTypes: GradientType[] = [
      'GradientLinear',
      'GradientCircle',
      'GradientConic',
    ];

    return (
      <Suspense fallback={null}>
        <WixEditorAdapter
          FrameComp={ToolPanelFrame}
          componentId={componentId}
          componentType={componentType}
          msid={msid}
          editorViewMode={editorViewMode}
          focusedPageId={focusedPageId}
          isDraft={isDraft}
          isZoomOut={isZoomOut}
          viewerName={viewerName}
          frameProps={{
            panelName,
            isBlockingLayerFullScreen,
            shouldBlockOuterScroll,
            reportMeasurements,
            measurements,
            shouldExtendDefaultShortcuts,
            keyboardShortcuts,
          }}
          enableSolidLeanThemePanel={supportNewColorPalette}
          value={updatedValue}
          panelMode={panelMode}
          languageCode={util.editorModel.languageCode}
          left={left}
          top={top}
          themeColorResolver={colorResolver}
          previewOnHover={previewOnHover}
          getCustomColors={getCustomColors}
          getCustomGradientColors={() => customAddedGradients}
          getCustomMeshColors={() => customAddedMeshes}
          onCustomColorAdd={handleAddCustomColor}
          onCustomGradientAdd={handleAddGradient}
          onCustomMeshAdd={handleAddMesh}
          onThemeColorAdd={handleAddThemeColor}
          getThemeColors={getThemeColors}
          getColorDefaults={getColorDefaults}
          getLinkedThemeColorFromColorDefault={
            getLinkedThemeColorFromColorDefault
          }
          openThemeManager={openThemeManager || openDesignPanelColorView}
          addColorHistoryEntry={addColorHistoryEntry}
          notifyClosePanel={notifyClosePanel}
          notifyOpenPanel={notifyOpenPanel}
          onChange={this.handleChange}
          onCancel={onCancel}
          onClose={onClose}
          showColorModalConfirmButtons={showColorModalConfirmButtons}
          shouldCloseSelf={shouldCloseSelf}
          supportedGradientTypes={supportedGradientTypes}
          onEditColorDefaults={openDesignPanelAdvancedSettingsView}
          openColorDefaultsHelp={openColorDefaultsHelp}
          allowEyeDropper={allowEyeDropper}
          loadImage={loadImage}
          preserveRGBA={preserveRGBA}
        />
      </Suspense>
    );
  }
}

const userPreferencesDefs = [
  {
    type: 'session',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_ADDED,
    propName: 'customUserAddedSessionColors',
  },
  {
    type: 'site',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_SITE_ADDED,
    propName: 'userCustomAddedSiteColors',
    updatePropName: 'setUserCustomAddedSiteColors',
  },
  // Tehere is no setter because this list of colors can't be updated
  // and set by color palette template migration theme/colors/migrations/templatesMigration.ts
  {
    type: 'site',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.USER_ADDED,
    propName: 'userCustomAddedSiteColorsTemplateMigration',
  },
  {
    type: 'site',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.ADDED_GRADIENTS,
    propName: 'customAddedGradients',
    updatePropName: 'setCustomAddedGradients',
  },
  {
    type: 'site',
    key: constants.USER_PREFS.THEME.COLORS.CUSTOM.ADDED_MESHES,
    propName: 'customAddedMeshes',
    updatePropName: 'setCustomAddedMeshes',
  },
];

const WrappedColorPickerPanelFrame = _.flowRight(
  higherOrderComponents.withUserPreferences(userPreferencesDefs),
  connect(STORES.EDITOR_API, mapStateToProps, mapDispatchToProps),
)(ColorPickerPanelFrame);

export default WrappedColorPickerPanelFrame;

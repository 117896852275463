import React from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import * as compPanelInfra from '#packages/compPanelInfra';
import {
  Button,
  Composites,
  Illustration,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import interactionsPanelMapper from './interactionsPanelMapper';
import * as stateManagement from '#packages/stateManagement';
import type { InteractionsPanelProps } from './interactonsPanelTypes';

class InteractionsPanel extends React.Component<InteractionsPanelProps> {
  static displayName = 'interactionsPanel';

  getFrameStyle(style: AnyFixMe) {
    return {
      top: style.top,
      left: style.left,
    };
  }

  addInteraction(interactionName: AnyFixMe) {
    this.props.addInteraction(this.props.selectedComponent, interactionName);
  }

  removeInteraction(interactionName: AnyFixMe) {
    this.props.removeInteraction(this.props.selectedComponent, interactionName);
  }

  render() {
    return (
      <compPanelInfra.compPanelFrame
        className="interactions-panel"
        title="interactions_main_header"
        helpId="2b14e875-50e8-4abf-aece-f658c1b6c98a"
        automationId="interactions-panel"
        ref="compPanelFrame"
        onClose={this.props.onPanelClose}
        {...this.props.frameProps}
        contentClass="interactions-panel-content"
      >
        {_.isEmpty(this.props.interactions) ? (
          <div
            key="empty-state-container"
            className="empty-state-container"
            data-aid="empty-state-container"
          >
            <Composites.RichTextWithIllustrationVertical className="content-width-md">
              <Illustration>
                <Symbol name="interactions_empty_state" />
              </Illustration>
              <Composites.RichTextLabeled>
                <TextLabel
                  type="T09"
                  value="interactions_main_empty_state_title"
                />
                <RichText className="light" type="T07">
                  {translate('interactions_main_empty_state_text')}
                </RichText>
              </Composites.RichTextLabeled>
            </Composites.RichTextWithIllustrationVertical>

            <div className="add-interaction-content">
              <Composites.ButtonLarge>
                <Button
                  automationId="add-interaction-button"
                  onClick={this.addInteraction.bind(this, 'Hover')}
                >
                  {translate('interactions_main_empty_state_primary_CTA')}
                </Button>
              </Composites.ButtonLarge>
            </div>
          </div>
        ) : (
          <div className="remove-interaction-wrapper">
            <Composites.RichTextWithIllustrationVertical className="content-width-md">
              <Composites.RichTextLabeled>
                <TextLabel
                  className="interactions-applied-title"
                  enableEllipsis={false}
                  type="T09"
                  value="interactions_main_applied_title"
                />
                <RichText className="light" type="T07">
                  {translate('interactions_main_applied_description')}
                </RichText>
              </Composites.RichTextLabeled>
            </Composites.RichTextWithIllustrationVertical>

            <div className="edit-interaction-content">
              <Composites.ButtonLarge>
                <Button
                  onClick={() =>
                    this.props.enterInteraction(
                      this.props.selectedComponent,
                      this.props.interactions[0].name,
                    )
                  }
                >
                  <span>
                    {translate('interactions_main_applied_edit_button')}
                  </span>
                </Button>
              </Composites.ButtonLarge>
            </div>

            <div className="remove-interaction-content">
              <div
                onClick={this.removeInteraction.bind(
                  this,
                  this.props.interactions[0].name,
                )}
              >
                <TextLabel
                  type="T01"
                  automationId="remove-interaction-button"
                  value="interactions_main_applied_remove_button"
                />
              </div>
            </div>
          </div>
        )}
      </compPanelInfra.compPanelFrame>
    );
  }
}

const ConnectedComponent = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    interactionsPanelMapper.mapStateToProps,
    interactionsPanelMapper.mapDispatchToProps,
  ),
  stateManagement.components.hoc.compPanel,
)(InteractionsPanel);

export default ConnectedComponent;

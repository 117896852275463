import createReactClass from 'create-react-class';
import { mixins } from '#packages/core';
import { getStylesForPersonalizedSection } from './liveComponentSectionUtils';
import liveSectionLoaderMixin from './liveSectionLoaderMixin';

// @ts-expect-error
// eslint-disable-next-line react/prefer-es6-class
export const PersonalizedSection = createReactClass({
  displayName: 'personalizedSection',
  mixins: [liveSectionLoaderMixin, mixins.editorAPIMixin],
  getStylesForSection() {
    const panelLayoutType = this.props.parentType;
    const compTypes = this.props.compTypes || this.props.props.compTypes;
    const editorAPI = this.getEditorAPI();

    return getStylesForPersonalizedSection({
      compTypes,
      panelLayoutType,
      editorAPITheme: editorAPI.theme,
      editorAPIComponents: editorAPI.components,
    });
  },
});

export const PERMISSIONS_TAB_HOOKS = {
  PERMISSIONS_TITLE: 'permissions-title',
  PERMISSIONS_THUMBNAILS: 'permissions-thumbnails',
  LEARN_MORE_LINK: 'learn-more-link',
  PASSWORD_INPUT_LABEL: 'password.input-label',
  PASSWORD_INPUT_MOCK: 'password.input-mock',
  PASSWORD_INPUT: 'password.input',
  MEMBERS_ROLES_ACTION: 'members.roles-action',
  MEMBERS_ROLES_LABEL: 'members.roles-label',
  MEMBERS_PRICING_PLANS_INSTALL: 'members.pricing-plans-install',
  MEMBERS_PRICING_PLANS_ACTION: 'members.pricing-plans-action',
  MEMBERS_PRICING_PLANS_LABEL: 'members.pricing-plans-label',
  MEMBERS_BADGES_ACTION: 'members.badges-action',
  MEMBERS_BADGES_LABEL: 'members.badges-label',
  MEMBERS_DASHBOARD_LINK: 'members.dashboard-link',
  MEMBERS_ACCESS_OPTIONS: 'members.access-options',
  PERMISSIONS_SECTION_CONTAINER: 'permissions-section.container',
  MEMBERS_ACCESS_TYPE_RADIO_BUTTONS: 'members-access-type.radio-buttons',
  PERMISSIONS_SECTION_TOGGLE: 'permissions-section.togle',
  PERMISSIONS_SECTION_CONNECTED_GROUPS: 'permissions-section.connected-groups',
  PERMISSIONS_SECTION_CONNECTED_GROUPS_TAG:
    'permissions-section.connected-groups.tag',
  PERMISSIONS_SECTION_EMPTY_TEXT: 'permissions-section.empty-text',
  PERMISSIONS_SECTION_CREATE_BUTTON: 'permissions-section.create-button',
  PERMISSIONS_SECTION_CONNECT_BUTTON: 'permissions-section.connect-button',
  ROLES_PERMISSION_SECTION: 'roles-permission-section',
  PLANS_PERMISSION_SECTION: 'plans-permission-section',
  BADGES_PERMISSION_SECTION: 'badges-permission-section',
};

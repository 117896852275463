import React, { type FC, useState, useEffect, useRef } from 'react';
import { connectWithScope } from '#packages/apilib';
import { translate } from '#packages/i18n';
import { Button, Text } from '@wix/wix-base-ui';
import type { SectionPresetDefinition } from '../../../../types';
import AddPresetPreviewerPreview from '../../../../previews/addPresetPreviewerPreview';
import { getPreviewerPreviewMaxHeight } from '../../../../addPresetUtil';
import { AddPresetScope } from '../../../../addPresetApiEntry';
import { fedopsLogger, hoc, type WithStartItemDrag } from '#packages/util';
import type { ResultsDisplayDispatchProps } from './resultsDisplayMapper';
import { mapDispatchToProps } from './resultsDisplayMapper';
import { addPanelDragToStage } from '#packages/addPanelInfra';
import constants from '#packages/constants';
import htmlThumbnailDragBox from '../../../../htmlPreviewThumbnail/htmlThumbnailDragBox';
import AddSectionThumbnail from '../../addSectionThumbnail';
import type { CompRef } from 'types/documentServices';
import type {
  OnSectionAddFromContextMenuHandler,
  OnSectionClickHandler,
  OnSectionDragEndHandler,
} from '../../../addSectionPanelMapper';
import { biLogger } from '#packages/util';
import {
  aiTextGeneratorAdditionalActions,
  smartWriterUseTextClick,
} from '@wix/bi-logger-editor/v2';
import { AI_SECTION_CREATOR_PANEL_TYPE } from '../aiSectionCreatorConsts';

import styles from './resultsDisplay.scss';
import { CreateWithAICategory } from '../../../../addSection/consts';
import { AiInfoBlock } from '#packages/baseUI';

export interface ResultsDisplayOwnProps extends WithStartItemDrag {
  categoryId: string;
  sessionId: string;
  sectionDefinitions: SectionPresetDefinition[];
  stageEntryIndex: number;
  onBackButtonClick?: () => void;
  onSectionDragEnd: OnSectionDragEndHandler;
  onSectionClick: OnSectionClickHandler;
  onSectionAddFromContextMenu: OnSectionAddFromContextMenuHandler;
  onSectionAddedToStage?: (sectionRef: CompRef) => void;
}

export type ResultsDisplayProps = ResultsDisplayOwnProps &
  ResultsDisplayDispatchProps;

const ResultsDisplay: FC<ResultsDisplayProps> = ({
  sectionDefinitions,
  sessionId,
  onBackButtonClick,
  onSectionDragEnd,
  onSectionClick,
  onSectionAddFromContextMenu,
  startItemDrag,
  stageEntryIndex,
  getBlankSectionStructure,
  getPreviewerComponents,
  sendSectionPhaseFinishedEvent,
}) => {
  const [previewerComponents, setPreviewerComponents] = useState<any>([]);
  const [readyPreviewers, setReadyPreviewers] = useState<
    Record<string, boolean>
  >({});
  const previewerCreationDuration = useRef(Date.now());

  useEffect(() => {
    setReadyPreviewers({});

    getPreviewerComponents(sectionDefinitions, handlePreviewerReady).then(
      setPreviewerComponents,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionDefinitions]);

  const handlePreviewerReady = (id: string): void => {
    if (readyPreviewers.hasOwnProperty(id)) return;

    setReadyPreviewers((updatedPreviewers) => ({
      ...updatedPreviewers,
      [id]: true,
    }));
  };

  useEffect(() => {
    const readyPreviewersLength = Object.keys(readyPreviewers).length;
    const duration = Date.now() - previewerCreationDuration.current;

    if (readyPreviewersLength === 1)
      sendSectionPhaseFinishedEvent('first thumbnail ready', duration);
    else if (readyPreviewersLength === sectionDefinitions.length)
      sendSectionPhaseFinishedEvent('all thumbnails ready', duration);
  }, [
    readyPreviewers,
    sectionDefinitions.length,
    sendSectionPhaseFinishedEvent,
  ]);

  const handleBackButtonClick = () => {
    biLogger.report(
      aiTextGeneratorAdditionalActions({
        panelType: AI_SECTION_CREATOR_PANEL_TYPE,
        actionName: 'start again',
      }),
    );

    onBackButtonClick?.();
  };

  const isSectionPreviwerReady = (id: string): boolean =>
    readyPreviewers.hasOwnProperty(id);

  const isAllPreviewersReady =
    Object.keys(readyPreviewers).length === sectionDefinitions.length;
  const previewerStatus = isAllPreviewersReady ? 'ready' : 'loading';

  const handleAddSectionEvent = (
    decoratedFunction: any,
    itemIndex: number,
  ): any => {
    return (...args: any) => {
      biLogger.report(
        smartWriterUseTextClick({
          isAiTextCreator: true,
          clickOrigin: 'add_section',
          panelType: AI_SECTION_CREATOR_PANEL_TYPE,
          sessionId,
        }),
      );

      decoratedFunction(...args, itemIndex);
    };
  };

  return (
    <>
      <AiInfoBlock
        title={translate('add_section_ai_results_title')}
        text={translate('add_section_ai_results_subtitle')}
      />
      <div
        data-hook="ai-section-creator_results-display"
        data-previewers-status={previewerStatus}
      >
        {sectionDefinitions?.map((section, index) => (
          <div key={section._id}>
            {
              <AddSectionThumbnail
                onSectionDragEnd={handleAddSectionEvent(
                  onSectionDragEnd,
                  index,
                )}
                getBlankSectionStructure={getBlankSectionStructure}
                onSectionClick={handleAddSectionEvent(onSectionClick, index)}
                compStructureToAdd={
                  previewerComponents[index]?.props?.structure
                }
                onSectionAddFromContextMenu={handleAddSectionEvent(
                  onSectionAddFromContextMenu,
                  index,
                )}
                startItemDrag={startItemDrag}
                key={section._id}
                sectionDefinition={section}
                categoryDefinition={CreateWithAICategory}
                stageEntryIndex={stageEntryIndex}
                isAppInstallRequired={false}
                appName={''}
                isPresetReady={isSectionPreviwerReady(section._id)}
                renderPreview={() => (
                  <AddPresetPreviewerPreview
                    id={section._id}
                    displayMinHeight={getPreviewerPreviewMaxHeight(section)}
                    interactionKey={
                      fedopsLogger.INTERACTIONS.ADD_SECTION_PREVIEW_LOAD
                        .ADD_SECTION_HTML_PREVIEW_LOAD
                    }
                    previewComponent={previewerComponents[index]}
                    isPresetReady={isSectionPreviwerReady(section._id)}
                  />
                )}
              />
            }
          </div>
        ))}
      </div>
      <div className={styles.controls}>
        <div className={styles.controlsTitle}>
          <Text size="small" weight="thin" shouldTranslate={false}>
            {translate('add_section_ai_results_text')}
          </Text>
        </div>
        <Button
          className="btn-sm btn-confirm-secondary"
          dataHook="ai-section-creator_go-back"
          onClick={handleBackButtonClick}
          shouldTranslate={false}
        >
          {translate('add_section_ai_results_cta')}
        </Button>
      </div>
    </>
  );
};

export default connectWithScope(
  () => AddPresetScope,
  hoc.withDragToStage<ResultsDisplayProps>(
    {
      ...addPanelDragToStage,
      type: constants.MOUSE_ACTION_TYPES.ADD_SECTION_PANEL_DRAG_TO_STAGE,
    },
    htmlThumbnailDragBox,
  )(ResultsDisplay),
  null,
  mapDispatchToProps,
);

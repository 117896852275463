import experiment from 'experiment';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  EditorParamsApiKey,
  PanelManagerApiKey,
  ReadonlyModeApiKey,
  WorkspaceApiKey,
} from '#packages/apis';
import { ToursApiKey } from '#packages/editorTours';
import { SectionsMigrationApiKey } from './publicApiKey';
import { SectionsMigrationPublicApi } from './api';
import { initMigration } from './init';
import { initPerPageMigration } from './initPerPageMigration';
import { createMigrationStatus } from './createMigrationStatus';
import { SectionsMigrationBanner } from './components';
import { sections } from '#packages/util';

export class SectionsMigrationScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  panelManager = this.useDependency(PanelManagerApiKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  editorParamsAPI = this.useDependency(EditorParamsApiKey);
  tourApi = this.useDependency(ToursApiKey);
  workspaceAPI = this.useDependency(WorkspaceApiKey);
  readonlyModeApi = this.useDependency(ReadonlyModeApiKey);
  migrationStatus = this.declareApi(createMigrationStatus);
}

export const SectionsMigrationEntryPoint = createEntryPoint({
  name: 'SectionsMigration',
  Scope: SectionsMigrationScope,
  publicApi({ contributeApi }) {
    contributeApi(SectionsMigrationApiKey, SectionsMigrationPublicApi);
  },
  async initialize(scope: SectionsMigrationScope) {
    await scope.editorCoreAPI.hooks._dsIsReady.promise;

    if (experiment.isOpen('se_perPageSectionsMigration')) {
      await initPerPageMigration(scope);
    } else {
      await initMigration(scope);
    }

    scope.workspaceAPI.contributeTopBannerComponent(
      SectionsMigrationBanner,
      () => sections.isSectionsMigrationBannerEnabled(),
    );
  },
});

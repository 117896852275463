import { mobile, selection } from '#packages/stateManagement';
import { fedopsLogger } from '#packages/util';
import { selectors as sectionsSelectors, utils } from '#packages/sections';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export interface SectionNameOwnProps {
  compRef: CompRef;
  className?: string;
}

export interface SectionNameStateProps {
  name: string;
  isSelected: boolean;
  isMobile: boolean;
  isZoomMode: boolean;
  canSelectSection: boolean;
}

export interface SectionNameDispatchProps {
  openRenamePanel: (position: DOMRect, origin: string) => void;
  selectSectionIfNeeded: () => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapStateToProps: MapStateToProps<
  SectionNameStateProps,
  SectionNameOwnProps
> = (stateMapperArgs, { compRef }) => {
  const { state, editorAPI } = stateMapperArgs;

  const selectedComponent =
    selection.selectors.getSingleSelectedComponent(state);

  const name = editorAPI.sections.getName(compRef);
  const isMobile = mobile.selectors.isMobileEditor(state);
  const isZoomMode = editorAPI.zoomMode.isStageZoomMode();
  const isSelected = editorAPI.utils.isSameRef(selectedComponent, compRef);
  const canSelectSection =
    sectionsSelectors.canSelectSectionLike(stateMapperArgs);

  return {
    canSelectSection,
    isMobile,
    isSelected,
    isZoomMode,
    name,
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  SectionNameDispatchProps,
  SectionNameOwnProps
> = (dispatch, { compRef }) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  return {
    openRenamePanel: (position: DOMRect, origin: string) => {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.CLASSIC_SECTIONS.RENAME_PANEL.OPEN,
      );
      const LABEL_OFFSET = 100;
      utils.openSectionNamePanel(
        editorAPI,
        compRef,
        { origin },
        {
          top: position.top,
          left: position.left - LABEL_OFFSET,
        },
      );
    },
    selectSectionIfNeeded() {
      const selectedComponents = editorAPI.selection.getSelectedComponents();

      dispatch(selection.actions.setPrevComponentsAction(selectedComponents));
      dispatch(selection.actions.setLastSelectionClickPos(null));

      if (selectedComponents[0]?.id !== compRef.id) {
        editorAPI.selection.selectComponentByCompRef(compRef, {
          origin: 'label',
        });
      }
    },
  };
};

import React from 'react';
import type { Dispatch, MapDispatchToProps } from 'types/redux';
import { Button } from '@wix/wix-base-ui';
import { useSelectedApp } from '../selectedAppContext/selectedAppContext';
import { translate } from '#packages/i18n';
import * as util from '#packages/util';
import {
  reportBiUpdateApp,
  updateApp,
  type UpdateAppDispatchProps,
  type UpdateAppOptions,
} from '../../utils/updateApp';

interface UpdateAppButtonProps extends UpdateAppDispatchProps {
  version?: string;
  buttonText?: string;
}
const origin = 'app details page';

const UpdateAppButtonComponent: React.FC<UpdateAppButtonProps> = ({
  version,
  updateApp,
  buttonText = translate(
    'blocks-private-apps.AppDiscovery_App_Versions_Change_Version_CTA',
  ),
}) => {
  const { app } = useSelectedApp().selectedApp;
  return (
    <Button
      dataHook="update-private-app-button"
      onClick={() => {
        updateApp({ app, version, biParams: { origin } });
        reportBiUpdateApp({
          appId: app.appDefinitionId,
          version,
          origin,
        });
      }}
    >
      {buttonText}
    </Button>
  );
};

const mapDispatchToProps: MapDispatchToProps<UpdateAppDispatchProps, {}> = (
  dispatch: Dispatch,
) => ({
  updateApp: (updateAppParams: UpdateAppOptions) =>
    dispatch(updateApp(updateAppParams)),
});

export const UpdateAppButton = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(UpdateAppButtonComponent);

// @ts-nocheck
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const PanelName = 'compPanels.panels.MediaControls.designPanel';

const openCustomeDesignPanel = function (
  editorAPI: EditorAPI,
  compRef: CompRef,
  origin: string,
) {
  gfppDataUtils.toggleComponentPanel(editorAPI, PanelName, {
    selectedComponent: compRef,
    origin,
  });
};

export default {
  mainActions: [
    {
      label: 'gfpp_mainaction_MediaControls',
      isSelected: gfppDataUtils.getPanelStateFn(PanelName),
      onClick: openCustomeDesignPanel,
    },
  ],
  enabledActions: [ACTIONS.DESIGN, ACTIONS.SETTINGS, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP],
  presetActions: {
    design: {
      isSelected: gfppDataUtils.getPanelStateFn(PanelName),
      onClick: openCustomeDesignPanel,
    },
    help: {
      helpId: helpIds.GFPP.MEDIA_CONTROLS,
    },
  },
};

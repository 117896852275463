import React from 'react';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import { CustomScroll, Divider } from '@wix/wix-base-ui';
import { settingsClicked } from '../../bi';
import { getYoutubeUrl } from '../utils/youtubeVideoUrl';
import CollectionItem from '../collectionItem';
import LoadCollectionsPlaceholder from '../loadCollectionsPlaceholder';
import { CollectionsSection } from '../collectionsSection/collectionsSection';
import { getGroupedCollectionsSections } from '../utils/collection';
import { CollectionsListHeader } from './collectionsListHeader';
import { ActionButtons } from './actionButtons';
import { mapStateToProps, mapDispatchToProps } from './collectionsList.mapper';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

import type {
  CollectionsListOwnProps,
  CollectionsListStateProps,
  CollectionsListDispatchProps,
} from './collectionsList.mapper';

import styles from './collectionsList.scss';
import LimitsNotificationBanner from '../limitsNotificationBanner/limitsNotificationBanner';
import { EmptyExternalDbItem } from '../emptyExternalDbItem/emptyExternalDbItem';
import { isSharedCollection } from '../utils/sharedCollection';
import { FormsMigrationHelper } from '../../panel/formCollectionsMigration';

export interface CollectionsListProps
  extends CollectionsListDispatchProps,
    CollectionsListOwnProps,
    CollectionsListStateProps {}

const CollectionsList: React.FC<CollectionsListProps> = ({
  collections,
  isLoading,
  openCreateCollectionPanel,
  openVideoPanel,
  openContentManagerCollectionsList,
  openAddPresetPanel,
  selectTabByName,
  isPermittedToAddCollection,
  sendBi,
  title,
  subtitle,
  showActionButtons,
  showVideoTooltip,
  collectionCount,
  isUserCollectionsList,
  collectionsGroup,
  externalConnectors,
  showFormsMigrationHelper,
  ignoreGrouping,
}) => {
  const openCollectionsList = () => {
    sendBi(settingsClicked, {
      actionName: 'view_all_collections',
      isEmptyState: false,
    });
    openContentManagerCollectionsList({ collectionsGroup });
  };

  const { collectionsOutOfSections, collectionsSections } =
    getGroupedCollectionsSections(
      collections,
      externalConnectors,
      ignoreGrouping,
    );

  const renderCollectionsOutOfSections = () =>
    collectionsOutOfSections.map((collection) => (
      <CollectionItem
        key={collection.id}
        collectionId={collection.id}
        collectionName={collection.displayName}
        collectionPermissions={collection.permissions}
        iconName={
          collection?.plugins?.singleItem
            ? 'singleItemCollectionItemIcon'
            : 'userCollectionItemIcon'
        }
        isEditable={true}
        isSharedCollection={isSharedCollection(collection)}
        aid="collection-item"
        sendBi={sendBi}
      />
    ));

  const renderCollectionsSections = () =>
    collectionsSections.map((section) => {
      if (section.isExternalConnector && section.collections.length === 0) {
        return (
          <EmptyExternalDbItem
            key={section.namespace}
            namespace={section.namespace}
          />
        );
      }

      return (
        <CollectionsSection
          key={section.namespace}
          section={section}
          sendBi={sendBi}
        />
      );
    });

  return (
    <div className={styles.collectionsListContainer}>
      <LoadCollectionsPlaceholder
        isLoading={isLoading}
        hasCollections={collections.length > 0}
        emptyStateTitle="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TITLE"
        emptyStateDescription="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_SUBTITLE"
        emptyStateIcon="collectionsManager_emptyState_myCollections"
        dataHook="collections-manager-page"
        buttonTitle="CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_BUTTON"
        onSettingsIconClick={openCollectionsList}
        videoTooltipProps={
          !isResponsiveEditor() && {
            title:
              'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_TITLE',
            subtitle:
              'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_SUBTITLE',
            imageUrl: 'addPanelData/sections/dataBinding/collectionVideo.png',
            openVideoPanel: () => {
              openVideoPanel(
                translate(
                  'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_ARTICLEID',
                ),
                true,
              );
            },
            sendBi,
            videoUrl: getYoutubeUrl(
              translate(
                'CONTENT_MANAGER_6_BUTTON_MANAGE_CONTENT_COLLECTION_EMPTY_STATE_TOOLTIP_VIDEO_ARTICLEID',
              ),
            ),
            isEmptyState: true,
            tabName: 'content_manager',
          }
        }
        sendBi={sendBi}
      >
        <div className={styles.collectionsList}>
          <CustomScroll flex="1">
            {isUserCollectionsList && <LimitsNotificationBanner />}
            <CollectionsListHeader
              title={title}
              subtitle={subtitle}
              collectionCount={collectionCount}
              showVideoTooltip={showVideoTooltip}
              onManage={openCollectionsList}
              openVideoPanel={openVideoPanel}
              sendBi={sendBi}
            />
            <Divider direction="horizontal" long />
            {renderCollectionsOutOfSections()}
            {renderCollectionsSections()}
          </CustomScroll>
          {showFormsMigrationHelper && (
            <FormsMigrationHelper selectTabByName={selectTabByName} />
          )}
          {showActionButtons && isPermittedToAddCollection && (
            <ActionButtons
              onMainAction={openCreateCollectionPanel}
              onSecondaryAction={openAddPresetPanel}
            />
          )}
        </div>
      </LoadCollectionsPlaceholder>
    </div>
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(CollectionsList);

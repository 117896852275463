import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as tpa from '#packages/tpa';
import * as programsListWidgetConsts from './programsListWidgetConsts';
import _ from 'lodash';

const createLayout1 = () => {
  return {
    id: programsListWidgetConsts.PRESET_1_KEY,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        fixedPosition: false,
        scale: 1,
        height: 566,
        rotationInDegrees: 0,
        width: 985,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '1871',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: {},
        },
        widgetId: 'ef4e59bd-625a-484e-9ba1-650d4aecf03b',
      },
      style: {
        type: 'ComponentStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: { width: 294, height: 154, x: 15, y: 15 },
      label: '',
      tags: null as AnyFixMe[],
    },
  };
};

const createLayout2 = () => {
  return {
    id: programsListWidgetConsts.PRESET_2_KEY,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        width: 980,
        height: 558,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '1871',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: {
            itemsPerRow: 2,
            imageShape: 'round',
            layoutType: 'Grid',
            textAlignment: 'center',
            displayDuration: false,
          },
        },
        widgetId: 'ef4e59bd-625a-484e-9ba1-650d4aecf03b',
      },
      style: {
        type: 'ComponentStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: { width: 294, height: 187, x: 15, y: 186 },
      label: '',
      tags: null as AnyFixMe[],
    },
  };
};

const createLayout3 = () => {
  return {
    id: programsListWidgetConsts.PRESET_3_KEY,
    structure: {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      layout: {
        width: 980,
        height: 558,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
      data: {
        type: 'TPAWidget',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        applicationId: '1871',
        tpaData: {
          type: 'TPAData',
          metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
          content: {
            layoutType: 'Side',
            textAlignment: 'center',
            displayDuration: true,
            imageLayoutType: 'Discordantly',
          },
        },
        widgetId: 'ef4e59bd-625a-484e-9ba1-650d4aecf03b',
      },
      style: {
        type: 'ComponentStyle',
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
      },
    },
    preset: {
      rect: { width: 294, height: 237, x: 15, y: 386 },
      label: '',
      tags: null as AnyFixMe[],
    },
  };
};

const setPublicData = (
  compRef: AnyFixMe,
  compStructure: AnyFixMe,
  editorAPI: AnyFixMe,
) => {
  const compProps = _.get(compStructure, 'data.tpaData.content') || null;
  if (!compProps) {
    return;
  }

  Object.keys(compProps).forEach((key) => {
    editorAPI.dsActions.tpa.data.set(compRef, key, compProps[key]);
  });
};

function onWidgetClick(
  compStructure: AnyFixMe,
  sectionTitle: AnyFixMe,
  tags: AnyFixMe,
  presetId: AnyFixMe,
  editorAPI: AnyFixMe,
) {
  const biOptions = {
    origin: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
  };

  tpa.addPanelAddCompService
    .addCompOnClick(
      editorAPI,
      tpa.superApps.superAppsConstants.ONLINE_PROGRAMS.APP_DEF_ID,
      compStructure.data.widgetId,
      compStructure,
      tags,
      presetId,
      biOptions,
    )
    .then((compRef) => setPublicData(compRef, compStructure, editorAPI));
}

function onWidgetDrop(
  layoutParams: AnyFixMe,
  compPreset: AnyFixMe,
  parentComponent: AnyFixMe,
  editorAPI: AnyFixMe,
) {
  const biOptions = {
    origin: tpa.constants.BI.type.ADD_APP_ADD_PANEL,
  };

  tpa.addPanelAddCompService.addCompOnDrop(
    editorAPI,
    tpa.superApps.superAppsConstants.ONLINE_PROGRAMS.APP_DEF_ID,
    compPreset.structure.data.widgetId,
    layoutParams,
    compPreset,
    parentComponent,
    (compRef: AnyFixMe) =>
      setPublicData(compRef, compPreset.structure, editorAPI),
    biOptions,
  );
}

export default function () {
  const programsListWidgetPreset1 = createLayout1();
  const programsListWidgetPreset2 = createLayout2();
  const programsListWidgetPreset3 = createLayout3();

  const programsListWidgetItems = [
    programsListWidgetPreset1,
    programsListWidgetPreset2,
    programsListWidgetPreset3,
  ];

  return {
    id: 'wixOnlineProgramsProgramsListWidgets',
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title: 'SiteApp_OnlinePrograms_AddElement_ProgramsList',
    subNavigationTitle: 'SiteApp_OnlinePrograms_AddElement_ProgramsList',
    presetTitle: 'SiteApp_OnlinePrograms_AddElement_ProgramsList',
    tooltipTitle: 'SiteApp_OnlinePrograms_AddElement_ProgramsList',
    showSectionHeader: true,
    additionalBehaviours: {
      labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.ALWAYS,
      infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
      hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
      scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
      iconEnabledForComps: [] as AnyFixMe[],
    },
    props: {
      image: programsListWidgetConsts.PROGRAMSLIST_PRESETS_IMAGE,
      imageHover: null as AnyFixMe,
      onDrop: onWidgetDrop,
      onClick: onWidgetClick,
      items: programsListWidgetItems,
      compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
    },
  };
}

import { EditorAPIKey } from '#packages/apis';
import type { Shell } from '#packages/apilib';
import constants from '#packages/constants';
import * as services from './services';
import tpaConstants from './constants/constants';
import superAppsConstants from './superApps/superAppsConstants';
import * as superApps from './superApps/superApps';
import * as appMarketUtils from './appMarket/utils/appMarketUtils';
import * as tpaUtils from './utils/tpaUtils';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as settings from './settings/settings';
import {
  InstallationOriginType,
  InstallInitiator,
  EditorType,
} from '@wix/platform-editor-sdk';
import type { PlatformOrigin } from '#packages/platform';
import _ from 'lodash';
import type { CompRef } from 'types/documentServices';

type SubscribeFn<Args extends unknown[] = []> = (...args: Args) => void;

const ALL_TOPICS = '*';

const createPubSub = <EventArgs extends unknown[] = []>() => {
  const subs: Record<string, SubscribeFn<[string, ...EventArgs]>[]> = {};

  function subscribe<T extends string>(
    topic: T,
    cb: SubscribeFn<[string, ...EventArgs]>,
  ) {
    if (!subs[topic]) {
      subs[topic] = [];
    }
    const subscribers = subs[topic];
    subscribers.push(cb);
  }

  function notify(topic: string, ...args: EventArgs) {
    const subscribers = subs[topic] || [];
    const subscribersForAllEvents = subs[ALL_TOPICS] || [];

    subscribers.concat(subscribersForAllEvents).forEach((cb) => {
      cb(topic, ...args);
    });
  }

  return {
    subscribe,
    notify,
  };
};

export function createTpaApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);

  function addApplication(appDefId: AnyFixMe, options: AnyFixMe) {
    return new Promise(function (resolve, reject) {
      if (!appDefId) {
        reject(new Error(`no ${appDefId} given`));
      }
      editorAPI.dsRead.tpa.appMarket
        .getDataAsync(appDefId)
        .then(function (_marketData) {
          const marketData = _marketData as AnyFixMe;
          if (!marketData) {
            reject(
              new Error(
                `Application with ${appDefId} appDefinitionId do not exist.`,
              ),
            );
            return;
          } else if (marketData.error) {
            const error = new Error(
              `${marketData.error}, errorType: ${marketData.errorType}, errorMsg: ${marketData.errorMessage}`,
            );
            reject(error);
            ErrorReporter.captureException(error, {
              tags: {
                appDefIdMissing: true,
                appDefIdErrorType: marketData.errorType,
                appDefIdErrorMessage: marketData.errorMessage,
              },
            });
            return;
          }
          const isWixTPA = marketData.by === 'Wix';
          if (isWixTPA) {
            const isAppInstalled = services.tpaAddAppService.isAppInstalled(
              editorAPI,
              appDefId,
              true,
            );

            if (
              isAppInstalled &&
              !appMarketUtils.canApplicationBeInstalledSeveralTimes(marketData)
            ) {
              reject(new Error('Application already installed.'));
              return;
            }

            const type = appMarketUtils.getAppType(marketData);
            const initiator = tpaConstants.BI.initiator.PLATFORM;
            const biData = { ...options?.biData, adding_method: 'platform' };

            const isPlatformProvisionOnly = (options?: {
              componentTypes?: string[];
            }): boolean => {
              // at this moment only ['PLATFORM'] option is supported for `componentTypes`
              if (options?.componentTypes?.length !== 1) {
                return false;
              }

              return options.componentTypes.includes('PLATFORM');
            };

            const fullOptions = {
              ...options,
              platformProvisionOnly:
                isPlatformProvisionOnly(options) &&
                editorAPI.documentServices.tpa.app.hasEditorPlatformPart(
                  marketData,
                ),
            };

            services.tpaAddAppService.addApp(
              editorAPI,
              appDefId,
              marketData.name,
              null,
              type,
              initiator,
              !isWixTPA,
              biData,
              fullOptions,
              function (data) {
                if (data.onError || !data.instanceId) {
                  reject(new Error(data.onError));
                } else {
                  resolve(data);
                }
              },
              fullOptions?.platformOrigin,
            );
          } else {
            reject(new Error('Only wix apps are allowed'));
          }
        });
    });
  }

  function addComponent(options: AnyFixMe) {
    return new Promise(function (resolve, reject) {
      services.tpaAddComponentService.addComponent(
        editorAPI,
        null,
        options,
        function (data: AnyFixMe) {
          if (data.onError) {
            reject(new Error(data.onError));
          } else {
            resolve(data);
          }
        },
      );
    });
  }

  function installAppIfNeeded(appDefinitionId: string, options: AnyFixMe = {}) {
    return new Promise(function (resolve, reject) {
      if (
        editorAPI.platform.isPlatformAppInstalled(appDefinitionId) ||
        editorAPI.platform.isAppActive(appDefinitionId)
      ) {
        return resolve(
          editorAPI.platform.getAppDataByAppDefId(appDefinitionId),
        );
      }
      const biInitiator = tpaConstants.BI.initiator.EDITOR;
      const type = tpaConstants.APP.TYPE.PLATFORM_ONLY;
      const optionsObj = {
        originType: tpaConstants.BI.type.ADD_APP_ADD_PANEL,
        ...options,
      };
      const callback = (data: AnyFixMe) =>
        data?.success ? resolve(data) : reject(data);
      const platformOrigin = options.platformOrigin || {
        type: EditorType.Classic,
        initiator: InstallInitiator.Editor,
        info: {
          type: InstallationOriginType.AddPanel,
        },
      };
      services.tpaAddAppService.addApp(
        editorAPI,
        appDefinitionId,
        null,
        null,
        type,
        biInitiator,
        false,
        {},
        optionsObj,
        callback,
        platformOrigin,
      );
    });
  }

  const appInstallPubSub = createPubSub<[{ platformOrigin: PlatformOrigin }]>();

  const getUniqueChildrenWidgets = (parentRef: CompRef): CompRef[] => {
    const WIDGET_TYPES = new Set<string>([
      constants.COMP_TYPES.TPA_SECTION,
      constants.COMP_TYPES.TPA_WIDGET,
      constants.COMP_TYPES.TPA_MULTI_SECTION,
      constants.COMP_TYPES.REF_COMPONENT,
    ]);
    const widgetRefs = editorAPI.components
      .getChildrenRecursivelyWithResolvers(
        parentRef,
        (compRef: CompRef): boolean =>
          constants.COMP_TYPES.REF_COMPONENT !==
          editorAPI.components.getType(compRef),
      )
      .filter((childRef: CompRef) => {
        const childType = editorAPI.components.getType(childRef);
        if (childType === constants.COMP_TYPES.REF_COMPONENT) {
          return editorAPI.platform.widgetPlugins.isRefComponentVerticalApp(
            childRef,
          );
        }
        return WIDGET_TYPES.has(childType);
      });
    return _.uniqBy(
      widgetRefs,
      (widgetRef) => editorAPI.components.data.get(widgetRef)?.appDefinitionId,
    );
  };

  return {
    addApplication,
    addComponent,
    installAppIfNeeded,
    getUniqueChildrenWidgets,
    dangerouslySubscribeToAppInstall: appInstallPubSub.subscribe,
    notifyAppInstalled: appInstallPubSub.notify,
    openSettingsPanel: settings.open,
    bi: {
      consts: tpaConstants,
      reportTpaAddedBiEvent: tpaUtils.bi.reportTpaAddedBiEvent,
    },
    services: {
      tpaAddAppService: services.tpaAddAppService,
      tpaAlertsService: services.tpaAlertsService,
      tpaAddRemoveDSService: services.tpaAddRemoveDSService,
      tpaAppInstallingService: services.tpaAppInstallingService,
    },
    superApps: {
      openDashboardUrl: superApps.openDashboardUrl,
      refreshAppCompsForAppDefId: superApps.refreshAppCompsForAppDefId,
      consts: superAppsConstants,
    },
  };
}

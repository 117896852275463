import { EditorAPIKey } from '#packages/apis';
import { ImageUploadToStageApiKey } from './publicApiKey';
import { ImageUploadToStageApi } from './imageUploadToStageApi';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';

export class ImageUploadToStageScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
}

export const ImageUploadToStageEntryPoint = createEntryPoint({
  name: 'ImageUploadToStage',
  Scope: ImageUploadToStageScope,
  publicApi({ contributeApi }) {
    contributeApi(ImageUploadToStageApiKey, ImageUploadToStageApi);
  },
  async initialize() {},
});

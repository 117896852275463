import * as tpa from '#packages/tpa';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  MapDispatchToPropsFunction,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  ForumWelcomeDispatchProps,
  ForumWelcomeOwnProps,
  ForumWelcomeStateProps,
} from './types';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

export const mapStateToProps: MapStateToProps<
  ForumWelcomeStateProps,
  ForumWelcomeOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  ForumWelcomeDispatchProps,
  ForumWelcomeOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    installForumApp: () =>
      tpa.superApps.addApp(tpa.superApps.superAppsConstants.FORUM.APP_DEF_ID, {
        biOrigin: tpa.constants.BI.type.ADD_APP_APP_BUTTON,
        platformOrigin: {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AddPanel,
          },
        },
        callback: (data: AnyFixMe) => {
          const pageId = data?.page?.id;
          if (pageId) {
            editorAPI.pages.navigateTo(pageId, () => {
              const sectionPointer =
                tpa.superApps.getSectionRefByPageId(pageId);
              editorAPI.selection.selectComponentByCompRef(sectionPointer);
            });
          }
        },
      }),
  };
};

import {
  ComponentsApiKey,
  SpotlightStageApiKey,
  ScrollApiKey,
  EditorAPIKey,
  WorkspaceApiKey,
} from '#packages/apis';
import { createSpotlightStageApi } from './spotlightStageApi';
import { bootstrapSpotlightStage } from './bootstrap';

import type { EntryPoint } from '#packages/apilib';

export const SpotlightStageApiEntryPoint: EntryPoint = {
  name: 'SpotlightStageApi',
  declareAPIs: () => [SpotlightStageApiKey],
  getDependencyAPIs: () => [
    ComponentsApiKey,
    EditorAPIKey,
    ScrollApiKey,
    WorkspaceApiKey,
  ],
  async attach(shell) {
    shell.contributeAPI(SpotlightStageApiKey, () =>
      createSpotlightStageApi(shell),
    );
  },

  async extend(shell) {
    bootstrapSpotlightStage(shell);
  },
};

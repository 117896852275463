import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { FixedStageApi } from './api';
import { FixedStageApiKey } from './publicApiKey';
import {
  PreviewApiKey,
  ScrollApiKey,
  EditorAPIKey,
  SelectionApiKey,
  ComponentsApiKey,
} from '#packages/apis';
import { FixedStageStore } from './store';
import { bootstrapStageGuides } from './stageGuides/bootstrap';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  scroll = this.useDependency(ScrollApiKey);
  preview = this.useDependency(PreviewApiKey);
  selection = this.useDependency(SelectionApiKey);
  components = this.useDependency(ComponentsApiKey);

  store = this.declareStore(FixedStageStore);
}

export const FixedStageEntryPoint = createEntryPoint({
  name: 'FixedStage',
  Scope,
  publicApi({ contributeApi }) {
    contributeApi(FixedStageApiKey, FixedStageApi);
  },
  initialize(scope) {
    bootstrapStageGuides(scope);
  },
});

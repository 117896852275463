import { createReduxStore } from '#packages/apilib';
import type { AssistantConfig } from '../assistant/aiAssistantTypes';

import type { AIAssistantPanelState } from './aiAssistantPanelTypes';

const getInitialState = (): AIAssistantPanelState => ({
  config: null,
});

export const AIAssistantPanelStore = createReduxStore({
  getInitialState,
  selectors: {
    getConfig: (state: AIAssistantPanelState) => state.config,
  },
  actions: {
    setAssistantConfig(state: AIAssistantPanelState, config: AssistantConfig) {
      return {
        ...state,
        config,
      };
    },
  },
});

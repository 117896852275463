import { events } from '#packages/coreBi';
import { bi } from '#packages/stateManagement';
import type { MapDispatchToProps } from 'types/redux';
import type { TabPageInfoMainMenuNewOwnProps } from './tabPageInfoMainMenu';

export interface TabPageInfoMainMenuNewDispatchProps {
  sendDropDownOpenEvent: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  TabPageInfoMainMenuNewDispatchProps,
  TabPageInfoMainMenuNewOwnProps
> = (dispatch, ownProps) => ({
  sendDropDownOpenEvent: () => {
    dispatch(
      bi.actions.event(
        events.pages.pagesPanel.ssr_cache_timeframe_dropdown_open,
        {
          origin: 'pages_panel',
          pageId: ownProps.menuItem.pageData.id,
        },
      ),
    );
  },
});

import React from 'react';
import { Composites, TextLabel, Thumbnails } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import { PERMISSIONS_TAB_HOOKS } from '../../../../utils/dataHooks';
import CONSTANTS from '../../../../utils/constants';

import type { PermissionState } from '../../../../utils/constants';

interface PermissionsThumbnailsProps {
  onProtectionChange: (permission: PermissionState) => void;
  focusedProtectionType: PermissionState;
}

const { PERMISSION_STATES } = CONSTANTS;

const THUMBNAILS_OPTIONS = [
  {
    illustration: React.createElement(symbols.symbol, {
      name: 'everyonePermission',
    }),
    value: PERMISSION_STATES.NONE,
    label: 'NewPages_Panel_Permissions_Public_Label',
  },
  {
    illustration: React.createElement(symbols.symbol, {
      name: 'passwordPermission',
    }),
    value: PERMISSION_STATES.PASSWORD,
    label: 'NewPages_Panel_Permissions_Password_Label',
  },
  {
    illustration: React.createElement(symbols.symbol, {
      name: 'membersPermission',
    }),
    value: PERMISSION_STATES.MEMBERS,
    label: 'NewPages_Panel_Permissions_Members_Or_Customers_Label',
  },
];

export const PermissionThumbnails: React.FC<PermissionsThumbnailsProps> = ({
  onProtectionChange,
  focusedProtectionType,
}) => {
  return (
    <Composites.Thumbnails>
      <TextLabel
        value="NewPages_Panel_Permissions_Title"
        dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_TITLE}
      />
      <Thumbnails
        dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_THUMBNAILS}
        value={focusedProtectionType}
        maxThumbsPerRow={3}
        options={THUMBNAILS_OPTIONS}
        borderType="thumbnailAndLabel"
        labelsType="T15"
        onChange={onProtectionChange}
      />
    </Composites.Thumbnails>
  );
};

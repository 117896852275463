import {
  DESKTOP,
  LITE_MODE_ALLOWED_SELECTABLE_COMPS,
  MOBILE,
  MODES_TO_SCALE_MAP,
  NO_ZOOM_SCALE,
  SCALE_TRANSITION_DURATION,
  WORKSPACE_MODES_LOCAL_STORAGE_KEY,
  WorkspaceModes,
} from './consts';
import { getMode, isMode } from './workspaceModesApi';
import { keyboardShortcuts } from '#packages/util';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { SelectComponentsByRefInterceptor } from '#packages/selection';
import type { WorkspaceModesScope } from './workspaceModesEntryPoint';

const filterRestrictedComps = (editorAPI: EditorAPI, comps: CompRef[]) => {
  return comps.filter((compRef) => {
    const compType = editorAPI.components.getType(compRef);
    return LITE_MODE_ALLOWED_SELECTABLE_COMPS.includes(compType);
  });
};

export const checkModeExists = (mode: WorkspaceModes): boolean => {
  const workspaceModes = Object.values(WorkspaceModes);
  return workspaceModes.includes(mode as unknown as WorkspaceModes);
};

export const validateMode = (mode: WorkspaceModes) => {
  if (!mode) {
    throw new TypeError('Parameter "mode" not provided');
  }
  const modeExists = checkModeExists(mode);
  if (!modeExists) {
    throw new TypeError(`Given workspace mode (${mode}) does not exist`);
  }
};

export const exitDevModeIfNeeded = ({ editorAPI }: WorkspaceModesScope) => {
  if (editorAPI.developerMode.isEnabled()) {
    editorAPI.developerMode.toggle();
  }
};

export const waitForDSReady = async ({
  editorCoreAPI,
}: WorkspaceModesScope) => {
  return editorCoreAPI.hooks.initReady.promise;
};

export const setSiteScale = (
  scope: WorkspaceModesScope,
  activeMode: WorkspaceModes = null,
  withTransition: boolean = false,
) => {
  const { editorAPI } = scope;

  editorAPI.panelManager.closeAllPanels();
  editorAPI.selection.deselectComponents();

  const isMobile = editorAPI.isMobileEditor();
  const device = isMobile ? MOBILE : DESKTOP;
  activeMode = activeMode ?? getMode(scope);

  const keyboardContext = isMode(scope, WorkspaceModes.LITE)
    ? keyboardShortcuts.CONTEXTS.WORKSPACE_MODE_LITE
    : null;

  const zoomScale = MODES_TO_SCALE_MAP[device][activeMode];
  if (zoomScale === NO_ZOOM_SCALE) {
    editorAPI.zoomMode.exitZoomMode({
      zoomScale,
      biParams: {
        origin: 'workspaceModeExitZoom',
      },
      keyboardContext,
    });
  } else {
    editorAPI.zoomMode.enterZoomMode({
      zoomScale,
      animationDuration: withTransition ? SCALE_TRANSITION_DURATION : 0,
      enableCompPanels: true,
      keyboardContext,
    });
  }
};
export const registerExitPreview = (scope: WorkspaceModesScope) => {
  scope.previewAPI.registerExitPreview(() => {
    setSiteScale(scope, null, true);
  });
};

export const registerToGoToPreview = (scope: WorkspaceModesScope) => {
  scope.previewAPI.registerToGoToPreview(() => {
    setSiteScale(scope, WorkspaceModes.FULL, true);
  });
};

export const registerDeviceChange = (scope: WorkspaceModesScope) => {
  const { editorAPI } = scope;
  editorAPI.registerAfterDeviceTypeChangeCallbacks(async () => {
    if (isMode(scope, WorkspaceModes.FULL)) {
      return;
    }
    await editorAPI.waitForChangesAppliedAsync();
    setSiteScale(scope);
  });
};

export const registerSelectByCompRefInterceptor = (
  scope: WorkspaceModesScope,
) => {
  const { store, editorAPI, selectionAPI } = scope;

  const workspaceModeSelectionInterceptor: SelectComponentsByRefInterceptor = (
    { compsToBeSelected },
    { update },
  ) => {
    const isLiteMode = isMode(scope, WorkspaceModes.LITE);
    const interceptorEnabled = store.getIsSelectionInterceptorEnabled();
    if (isLiteMode && interceptorEnabled) {
      update({
        compsToBeSelected: filterRestrictedComps(editorAPI, compsToBeSelected),
      });
    }
  };

  selectionAPI.hooks.selectComponentByCompRefInterceptor.tap(
    workspaceModeSelectionInterceptor,
  );
};

export const getModeFromLocalStorage = (): WorkspaceModes => {
  const mode = window.localStorage.getItem(
    WORKSPACE_MODES_LOCAL_STORAGE_KEY,
  ) as WorkspaceModes;
  const modeExists = checkModeExists(mode);
  if (!modeExists) {
    return null;
  }
  return mode;
};

export const updateLocalStorage = (activeMode: WorkspaceModes) => {
  window.localStorage.setItem(WORKSPACE_MODES_LOCAL_STORAGE_KEY, activeMode);
};

import React from 'react';
import constants from '#packages/constants';
import { TextLabel } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

interface InteractionNavProps {
  label: string;
  className?: string;
  interactionType?: string;
  componentUIColor: string;
  considerCompControlsPosition?: boolean;
  handleClick?: (event: React.MouseEvent) => void;
  handleDragMouseDown: (event: React.MouseEvent) => void;
  symbolName?: string;
}

const InteractionNav = ({
  className,
  label,
  handleDragMouseDown,
  componentUIColor,
  handleClick,
  symbolName,
  considerCompControlsPosition,
}: InteractionNavProps) => {
  return (
    <div
      onMouseDown={(e: React.MouseEvent) => {
        if (handleDragMouseDown) {
          e.stopPropagation();
          handleDragMouseDown(e);
        }
      }}
      onClick={handleClick}
      className={`interaction-nav ${className || ''} ${
        considerCompControlsPosition ? 'control-position' : ''
      }${
        componentUIColor === constants.COMPONENT_UI_COLORS.ORANGE
          ? ' components-ui-color-orange'
          : ''
      }`}
    >
      {symbolName && <symbols.symbol name={symbolName} />}
      <TextLabel type="T07" value={label} />
    </div>
  );
};

export default InteractionNav;

import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey, SavedComponentsApiKey } from '#packages/apis';
import { createSavedComponentsApi } from './savedComponentsWrapper';

export const SavedComponentsEntrypoint: EntryPoint = {
  name: 'SavedComponentsApi',
  declareAPIs: () => [SavedComponentsApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(SavedComponentsApiKey, () =>
      createSavedComponentsApi(shell),
    );
  },
};

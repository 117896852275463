import _ from 'lodash';
import {
  dealerService,
  ESTATES,
  parseResponseOffers,
  generateActionFromDealerData,
} from './dealer.common';
import { translateIfExists } from '../../utils';

import type { DealerRawAction } from './dealer.types';
import type { RegularAppData } from '../appMarket/appMarket.types';

import type { EditorAPI } from '#packages/editorAPI';

interface DealerSegmentAppOffer {
  appDefId: string;
  imageSrc: string;
  name: string;
  description: string;
  onManage?: () => void;
  mainCta: DealerRawAction;
}

export interface DealerSegmentApp {
  appDefId: string;
  imageSrc?: string;
  name?: string;
  description?: string;
  onManage?: () => void;
}

export const transformSegmentApps = (
  editorAPI: EditorAPI,
  apps: DealerSegmentAppOffer[],
): DealerSegmentApp[] =>
  apps.map((app) => {
    const { mainCta, ...rest } = app;

    return {
      ...rest,
      onManage: generateActionFromDealerData(editorAPI, app.mainCta),
    };
  });

export const loadSegmentApps = async (metaSiteInstance: string) => {
  const response = await dealerService({
    Authorization: metaSiteInstance,
  }).listOffers({ realEstateId: ESTATES.SEGMENT_APPS });

  const assets = parseResponseOffers<DealerSegmentAppOffer>(response.offers);

  return assets;
};

export const prepareSegmentsAppsData = (
  regularApps: RegularAppData[],
  segmentApps: DealerSegmentApp[],
): RegularAppData[] => {
  const segmentAppsMap = _.keyBy(segmentApps, (app) => app.appDefId);
  const segmentAppIds = segmentApps.map((app) => app.appDefId);

  const compareAppsBySegmentOrder = (
    app1: RegularAppData,
    app2: RegularAppData,
  ) => {
    const app1Index = segmentAppIds.indexOf(app1.id);
    const app2Index = segmentAppIds.indexOf(app2.id);

    if (app1Index === -1) {
      return 1;
    }
    if (app2Index === -1) {
      return -1;
    }

    return app1Index - app2Index;
  };

  return regularApps
    .map((app) => {
      const segmentAppData = segmentAppsMap[app.id];

      return {
        ...app,
        name: translateIfExists(segmentAppData?.name) || app.name,
        icon: segmentAppData?.imageSrc || app.icon,
        description:
          translateIfExists(segmentAppData?.description) || app.description,
      };
    })
    .sort(compareAppsBySegmentOrder);
};

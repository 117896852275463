import { workspace } from '#packages/util';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

export default {
  INVALID_LOGGED_STATUS_PROPS_DATA: {
    NOT_LOGGED_IN: {
      titleKey: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_HEADER_TITLE',
      description: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_BODY_INTRO',
      steps: [
        'GENERAL_EDITOR_ERROR_LOGGED_OUT_STEP_1',
        [
          {
            key: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_STEP_2_PART1',
            isLink: false,
          },
          { key: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_STEP_2_LINK', isLink: true },
          {
            key: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_STEP_2_PART3',
            isLink: false,
          },
        ],
        'GENERAL_EDITOR_ERROR_LOGGED_OUT_STEP_3',
      ],
      helpMessage: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_TROUBLESHOOT_1',
      helpLinkMessage: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_TROUBLESHOOT_2_LINK',
      helpSuffix: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_TROUBLESHOOT_3',
      headerHelpLink: '9414d5cd-507d-4b5f-9ede-ba0971ea9bf8',
      helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
      symbolName: isNewWorkspace
        ? 'userLoggedOutError_NewWorkspace'
        : 'userLoggedOut',
      confirmLabel: 'GENERAL_EDITOR_ERROR_LOGGED_OUT_CTA',
      errorCode: -15,
      isNewWorkspace,
    },
    USER_NOT_AUTHORIZED_FOR_SITE: {
      titleKey: 'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_HEADER_TITLE',
      description: 'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_BODY_INTRO',
      steps: [
        'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_STEP_1',
        [
          {
            key: 'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_STEP_2_PART1',
            isLink: false,
          },
          {
            key: 'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_STEP_2_LINK',
            isLink: true,
          },
          {
            key: 'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_STEP_2_PART3',
            isLink: false,
          },
        ],
        'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_STEP_3',
      ],
      helpMessage: 'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_1',
      helpLinkMessage: 'GENERAL_EDITOR_ERROR_WRONG_ACCOUNT_TROUBLESHOOT_2_LINK',
      helpSuffix: 'SAVE_SITE_ERROR_LOGGED_OUT_TROUBLESHOOT_3',
      headerHelpLink: '4bbdb5d8-cc67-4d77-ad3f-6c4a9a332621',
      helpLink: '267d5e8d-6af0-4caa-9ad2-1baa24f020ec',
      symbolName: isNewWorkspace
        ? 'userLoggedIntoDifferentAccError_NewWorkspace'
        : 'userLoggedOut',
      confirmLabel: 'SAVE_SITE_ERROR_LOGGED_OUT_CTA',
      errorCode: -14,
      isNewWorkspace,
    },
  },
};

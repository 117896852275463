import constants from '#packages/constants';
import type { Shell } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';
import type { PrivateAppsContainerParams } from '../components/privateAppsPanelContainer/privateAppsPanelContainer';

export const createPrivateAppsPanelApi = (shell: Shell) => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  return {
    openPrivateAppsPanel: (props?: PrivateAppsContainerParams) =>
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_APPS_PANEL_NAME,
        {
          urlParams: {},
          selectedSection: {
            id: 'private-apps',
            props,
          },
        },
      ),
  };
};

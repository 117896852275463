import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  GFPPData,
  GFPPAction,
  GFPPActionsDictionary,
  GFPPActionType,
} from '@wix/editor-component-types';

const addGrantedApplicationPermissions = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  gfppAction: GFPPAction,
): GFPPAction => {
  if (gfppAction?.permissions) {
    return {
      ...gfppAction,
      permissions: editorAPI.platform.permissions.addGrantedToPermissions(
        gfppAction.permissions,
      ),
    };
  }
  return <GFPPAction>gfppAction;
};

export default function permissionsGfppEnhancer(
  editorAPI: EditorAPI,
  compRef: CompRef,
  compGfppData: GFPPData,
) {
  compGfppData.presetActions =
    compGfppData.presetActions &&
    Object.keys(compGfppData.presetActions)?.reduce(
      (acc: GFPPActionsDictionary, presetActionName: string) => {
        acc[presetActionName as GFPPActionType] =
          addGrantedApplicationPermissions(
            editorAPI,
            compRef,
            compGfppData.presetActions[presetActionName as GFPPActionType],
          );
        return acc;
      },
      {},
    );
  compGfppData.mainActions = compGfppData.mainActions?.map((action) =>
    addGrantedApplicationPermissions(editorAPI, compRef, action),
  );
  if (Array.isArray(compGfppData.mobileMainActions)) {
    compGfppData.mobileMainActions = compGfppData.mobileMainActions.map(
      (action) => addGrantedApplicationPermissions(editorAPI, compRef, action),
    );
  }
}

import { ADD_PANEL } from '#packages/helpIds';
import { constants as tpaConstants } from '#packages/tpa';
import { getBookingsEditorActionService } from '#packages/wixBookings';
import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  BookingsWelcomeDispatchProps,
  BookingsWelcomeOwnProps,
  BookingsWelcomeStateProps,
} from './types';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

export const mapStateToProps: MapStateToProps<
  BookingsWelcomeStateProps,
  BookingsWelcomeOwnProps
> = ({ editorAPI }) => ({
  welcomeSectionTemplate: editorAPI.addPanelInfra.welcomeSection,
});

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps: MapDispatchToPropsFunction<
  BookingsWelcomeDispatchProps,
  BookingsWelcomeOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    installBookings() {
      const bookingsEditorActionService =
        getBookingsEditorActionService(editorAPI);
      bookingsEditorActionService.addAppToSite(undefined, {
        biOrigin: tpaConstants.BI.type.ADD_APP_ADD_PANEL,
        platformOrigin: {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AddPanel,
          },
        },
      });
      bookingsEditorActionService.sendBookingsStartedFromAddPanelBI();
    },
    openHelpSection() {
      editorAPI.panelManager.openHelpCenter(ADD_PANEL.WIX_BOOKINGS_WELCOME);
    },
  };
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import * as util from '#packages/util';
import React from 'react';
import * as panels from '#packages/panels';

const CLOSE_METHOD = {
  ESC: 'esc',
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  propTypes: {
    iframeUrl: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    closeModalBiEvent: PropTypes.object,
    closeModalBiParams: PropTypes.object,
  },
  displayName: 'appMarketModal',
  mixins: [util.translationMixin, core.mixins.editorAPIMixin],
  render() {
    return (
      <panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        title={this.getTitle()}
        onClose={this.onClose}
        shouldHideHelp={true}
        width={this.props.width}
        height={this.props.height}
        shouldHideHeader={this.props.shouldHideHeader}
        keyboardShortcuts={this.getShortcuts()}
      >
        <div className="app-market-modal-container">
          <iframe
            src={this.props.iframeUrl}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      </panels.frames.FocusPanelFrame>
    );
  },
  getTitle() {
    if (this.props.title) {
      return this.translateIfNeeded(this.props.title);
    }
  },
  onClose(closeMethod) {
    const editorAPI = this.getEditorAPI();
    editorAPI.panelManager.closePanelByName(this.props.panelName);
    if (this.props.onClose) {
      this.props.onClose();
    }
    if (this.props.closeModalBiEvent) {
      const closeModalBiParams = this.props.closeModalBiParams || {};
      closeModalBiParams.closeMethod = closeMethod;
      editorAPI.bi.event(this.props.closeModalBiEvent, closeModalBiParams);
    }
  },
  getShortcuts() {
    const self = this;
    return {
      esc() {
        self.onClose(CLOSE_METHOD.ESC);
      },
    };
  },
});

import React from 'react';
import {
  type PageMarginsIndicatorStyles,
  pageMarginsIndicatorStylesSel,
} from './pageMarginsIndicatorsSelectors';
import { hoc } from '#packages/util';
import { createStructuredSelector } from '../../selectors/selector';
import {
  type ContainerMarginsIndicatorStyles,
  containerMarginsIndicatorStylesSel,
} from './containerMarginsIndicatorsSelectors';

interface MarginsIndicatorsProps {
  pageMarginsIndicatorStyles: PageMarginsIndicatorStyles;
  containerMarginsIndicatorStyles: ContainerMarginsIndicatorStyles;
}

const MarginsIndicators: React.SFC<MarginsIndicatorsProps> = ({
  pageMarginsIndicatorStyles,
  containerMarginsIndicatorStyles,
}) => {
  return (
    <>
      {containerMarginsIndicatorStyles ? (
        <div
          key="containerMarginsIndicator"
          style={containerMarginsIndicatorStyles.indicator}
          className="margins-indicator"
          data-aid="containerOutOfGridlinesIndication"
        >
          {containerMarginsIndicatorStyles.marginLeft ? (
            <div
              key="leftContainerMarginIndicator"
              style={{ width: containerMarginsIndicatorStyles.marginLeft }}
              className="left"
            />
          ) : null}
          {containerMarginsIndicatorStyles.marginRight ? (
            <div
              key="rightContainerMarginIndicator"
              style={{ width: containerMarginsIndicatorStyles.marginRight }}
              className="right"
            />
          ) : null}
        </div>
      ) : null}

      {pageMarginsIndicatorStyles ? (
        <div
          key="pageMarginsIndicator"
          style={pageMarginsIndicatorStyles.indicator}
          data-aid="pageOutOfGridlinesIndication"
          className="margins-indicator"
        >
          {pageMarginsIndicatorStyles.marginLeft ? (
            <div
              key="leftPageMarginIndicator"
              style={{ width: pageMarginsIndicatorStyles.marginLeft }}
              className="left"
            />
          ) : null}
          {pageMarginsIndicatorStyles.marginRight ? (
            <div
              key="rightPageMarginIndicator"
              style={{ width: pageMarginsIndicatorStyles.marginRight }}
              className="right"
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  pageMarginsIndicatorStyles: pageMarginsIndicatorStylesSel,
  containerMarginsIndicatorStyles: containerMarginsIndicatorStylesSel,
});

export default hoc.connect(
  hoc.STORES.MOUSE_OPS,
  mapStateToProps,
)(MarginsIndicators);

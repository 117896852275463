// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';
import section1 from './live/section1';
import section2 from './live/section2';
import stylableMenuTheme from './live/stylableMenuTheme';

export default {
  getCategorySections() {
    return addPanel.dataUtil.mergeWithCommonSections(
      addPanel.sections.getSections().menu,
      { section_1: section1, section_2: section2, stylableMenuTheme },
    );
  },
};

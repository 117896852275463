import _ from 'lodash';
import * as helpIds from '#packages/helpIds';
import columnsGfppUtils from '../../utils/columnsGfppUtils';
import gfppDataUtils from '../../utils/gfppDataUtils';
import constants from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import { fixedStage, animations as animationsUtils } from '#packages/util';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const backgroundPanelName = 'compPanels.panels.Column.backgroundPanel';

const getChangeBackgroundAction = (
  editorAPI: EditorAPI,
  columnRef: CompRef,
) => {
  const { label, icon, tooltip, backgroundMediaType } =
    gfppDataUtils.getDataGfppWithBackgroundIcon(editorAPI, columnRef, {
      label: 'gfpp_mainaction_columns',
      tooltip: '',
    });

  return {
    label,
    isSelected: gfppDataUtils.getPanelStateFn(ACTIONS.BACKGROUND),
    onClick(editorAPI: EditorAPI, compRef: CompRef, origin: AnyFixMe) {
      gfppDataUtils.toggleComponentPanel(editorAPI, backgroundPanelName, {
        selectedComponent: compRef,
        prependNoFillPreset: true,
        origin,
        biParams: { bg_media_type: backgroundMediaType },
      });
    },
    automationId: 'gfpp-button-background',
    icon,
    tooltip,
  };
};

function getMainActions(editorAPI: EditorAPI, columnPtr: CompRef) {
  const columnsContainerPtr = editorAPI.components.getContainer(columnPtr);

  const actions = [
    columnsGfppUtils.getManageColumnsActionIfApplicable(
      editorAPI,
      columnsContainerPtr,
      columnPtr,
    ),
    editorAPI.isDesktopEditor()
      ? getChangeBackgroundAction(editorAPI, columnPtr)
      : null,
  ];

  return _.compact(actions);
}

export default {
  untranslatable: true,
  mainActions: getMainActions,
  enabledActions(editorAPI: EditorAPI, compRef: CompRef) {
    const columnsContainerPtr = editorAPI.components.getContainer(compRef);
    const columnsContainerProperties =
      editorAPI.components.properties.get(columnsContainerPtr);
    let actions;
    if (
      columnsContainerProperties.fullWidth &&
      !fixedStage.isFixedStageEnabled()
    ) {
      actions = [ACTIONS.EFFECTS, ACTIONS.LAYOUT, ACTIONS.HELP];
    } else {
      actions = [ACTIONS.EFFECTS, ACTIONS.HELP];
    }
    return actions;
  },
  mobileMainActions: getMainActions,
  mobileEnabledActions: (editorAPI: EditorAPI) =>
    animationsUtils.isNewAnimationsEnabled(editorAPI)
      ? [
          ACTIONS.ANIMATION,
          ACTIONS.EFFECTS,
          ACTIONS.HIDE,
          ACTIONS.HELP,
          ACTIONS.MOBILE_BACKGROUND_SETTINGS,
        ]
      : [
          ACTIONS.MOTION,
          ACTIONS.HIDE,
          ACTIONS.HELP,
          ACTIONS.MOBILE_BACKGROUND_SETTINGS,
        ],
  presetActions: {
    help: {
      helpId: helpIds.GFPP.COLUMN,
      mobileHelpId: helpIds.GFPP.COLUMN_MOBILE,
    },
  },
};

import type { EntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  DocumentServicesApiKey,
} from '#packages/apis';
import { AppsPanelSectionsApiKey } from '#packages/appsPanelSections';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import experiments from 'experiment';
import { createPrivateAppsPanelApi } from './api/api';
import { createPrivateAppsEventsApi } from './privateAppsEvents/api';
import { PrivateAppsPanelApiKey } from './api/publicApiKey';
import { PrivateAppsEventsApiKey } from './privateAppsEvents/publicApiKey';
import PrivateAppsPanelContainer from './components/privateAppsPanelContainer/privateAppsPanelContainer';
import * as util from '#packages/util';
import { privateAppsPrivateAppsLeftBarClick } from '@wix/bi-logger-blocks-consumption/v2';
import {
  shouldShowUpdateAction,
  updateApp,
  getAllAvailableToUpdateApps,
  reportBiUpdateApp,
  type UpdateAppOptions,
} from './utils/updateApp';
import type { AppData } from './components/privateAppsProvider/privateAppsProvider';
import { isEligible } from '@wix/ambassador-premium-features-manager-v1-feature/http';
import { HttpClient } from '@wix/http-client';
import constants from '#packages/constants';
import { isInstalledAsTestVersion } from './utils/utils';

const { getFreshAppsData } = stateManagement.platform.selectors;

const PrivateAppsEntryPoint: EntryPoint = {
  name: 'PrivateApps',
  getDependencyAPIs: () => [
    AppsPanelSectionsApiKey,
    EditorAPIKey,
    EditorCoreApiKey,
    DocumentServicesApiKey,
  ],
  declareAPIs: () => [PrivateAppsPanelApiKey, PrivateAppsEventsApiKey],
  attach(shell) {
    shell.contributeAPI(PrivateAppsPanelApiKey, () =>
      createPrivateAppsPanelApi(shell),
    );
    shell.contributeAPI(PrivateAppsEventsApiKey, () =>
      createPrivateAppsEventsApi(shell),
    );
  },
  async extend(shell) {
    const privateAppsEventsApi = shell.getAPI(PrivateAppsEventsApiKey);
    const appsPanelSectionsApi = shell.getAPI(AppsPanelSectionsApiKey);
    const editorAPI = shell.getAPI(EditorAPIKey);

    editorAPI.store.dispatch(
      stateManagement.platform.actions.fetchAvailableApps(),
    );

    await shell.getAPI(EditorCoreApiKey).hooks._dsIsReady.promise;
    if (
      experiments.isOpen('se_blocksAutoUpdateForAppsThatInstalledAsTestVersion')
    ) {
      privateAppsEventsApi.subscribeToUserChannelEvent(
        'UPDATE_APP',
        ({ appDefId }: { appDefId: string }) => {
          const app = editorAPI.platform.getAppDataByAppDefId(
            appDefId,
          ) as AppData;

          if (!isInstalledAsTestVersion(app)) {
            return;
          }

          reportBiUpdateApp({
            origin: 'automatic',
            version: 'latest',
            appId: appDefId,
          });

          editorAPI.store.dispatch(
            updateApp({
              app: { ...app, blocksVersion: '2.0.0' },
              version: 'latest',
              biParams: { origin: 'automatic' },
            }),
          );
        },
      );
    }

    const httpClient = new HttpClient({
      getAppToken: () =>
        editorAPI.dsRead.platform.getAppDataByApplicationId(
          constants.APPLICATIONS.META_SITE_APPLICATION_ID,
        )?.instance || '',
    });

    const hasInstalledPrivateApp = () => {
      if (!editorAPI.dsRead) {
        return false;
      }

      const { getInstalledBlocksApps } = stateManagement.platform.selectors;
      const freshAppsData = getFreshAppsData(editorAPI.store.getState());
      const installedApps = getInstalledBlocksApps(editorAPI.dsRead).map(
        (app) => ({
          ...app,
          isPublished: freshAppsData.find(
            ({ appId }: { appId: string }) => appId === app.appDefinitionId,
          )?.isPublished,
        }),
      );

      return Boolean(installedApps.filter((app) => !app.isPublished).length);
    };

    const hasAvailableApps = () => {
      const availableApps = stateManagement.platform.selectors.getAvailableApps(
        editorAPI.store.getState(),
      );

      return availableApps?.length;
    };

    const isStudioAccount = async () => {
      const {
        data: { isEligible: isStudioAccount },
      } = await httpClient.request(
        isEligible({
          uniqueName: 'wix_studio',
          includeParentFeature: false,
        }),
      );

      return isStudioAccount;
    };

    const contributePrivateAppsIfNecessary = async () => {
      const userHasStudioAccount = await isStudioAccount();

      appsPanelSectionsApi.contributeSection(
        shell,
        {
          sectionId: 'private-apps',
          label: translate('AddApps__LeftPanel__PrivateApps'),
          headerText: translate('AddApps__LeftPanel__PrivateApps'),
          component: PrivateAppsPanelContainer,
          helpId: translate(
            'responsive-blocks-packages.Private_Apps_Help_Article',
          ),
          onClick: () => {
            util.biLogger.report(
              privateAppsPrivateAppsLeftBarClick({
                builderType: 'classic',
              }),
            );
          },
        },
        () =>
          userHasStudioAccount ||
          hasInstalledPrivateApp() ||
          hasAvailableApps(),
      );
    };

    void contributePrivateAppsIfNecessary();
  },
};

const components = { PrivateAppsPanel: PrivateAppsPanelContainer };

export { TEST_VERSION } from './utils/appDetailsUtils';

export {
  PrivateAppsEntryPoint,
  components,
  updateApp,
  shouldShowUpdateAction,
  getAllAvailableToUpdateApps,
};
export type { UpdateAppOptions, AppData };

import React from 'react';

import * as coreBi from '#packages/coreBi';
import { workspace } from '#packages/util';
import { translate } from '#packages/i18n';

import type { SendBiFunction } from 'types/bi';

import connect from './saveReminderPanelConnect';
import {
  SaveReminderPanelFrame,
  NewWorkspaceSaveReminderPanelFrame,
} from './saveReminderPanelFrame';

interface SaveReminderPanelProps {
  panelName: string;
  saveMethod: () => void;
  mainText?: string;
  headerText?: string;
  buttonText?: string;
  closePanelByName: (panelName: string) => void;
  sendBi: SendBiFunction;
}

class SaveReminderPanel extends React.Component<SaveReminderPanelProps> {
  static defaultProps = {
    headerText: 'SAVE_REMINDER_MSG_TITLE',
    buttonText: 'SAVE_REMINDER_MSG_BUTTON',
    mainText: 'SAVE_REMINDER_MSG_BODY',
  };

  private isNewWorkspace: boolean = workspace.isNewWorkspaceEnabled();

  UNSAFE_componentWillMount() {
    this.props.sendBi(coreBi.events.save.saveReminder.REMINDER_DISPLAYED, {
      origin: 'pop up message',
    });
  }

  handleSave = () => {
    this.props.sendBi(coreBi.events.save.saveReminder.SAVE_CLICKED, {});
    this.close();
    this.props.saveMethod();
  };

  getKeyboardShortcuts() {
    const self = this;
    return {
      enter() {
        self.close();
        self.handleSave();
      },
      esc() {
        self.close();
      },
    };
  }

  close() {
    this.props.closePanelByName(this.props.panelName);
  }

  render() {
    const PanelFrame = this.isNewWorkspace
      ? NewWorkspaceSaveReminderPanelFrame
      : SaveReminderPanelFrame;
    const confirmLabel = this.isNewWorkspace
      ? translate(this.props.buttonText)
      : this.props.buttonText;
    return (
      <PanelFrame
        panelName={this.props.panelName}
        title={translate(this.props.headerText)}
        confirmLabel={confirmLabel}
        content={translate(this.props.mainText)}
        onConfirm={this.handleSave}
        keyboardShortcuts={this.getKeyboardShortcuts()}
      />
    );
  }
}

export default connect(SaveReminderPanel);

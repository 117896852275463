import constants from '#packages/constants';

import type { ComponentEditorMetaDataDefinition } from '../types';

const metaData: ComponentEditorMetaDataDefinition = {
  allowConnectToDB: true,
  skipInQuickEditPanel: true,
  overrideProportionalResize: true,
  overridesWhenReferred: {
    gfpp: {
      disableActions: [constants.ROOT_COMPS.GFPP.ACTIONS.DESIGN],
    },
  },
};

export default metaData;

import React from 'react';
import { TextLabel, Button } from '@wix/wix-base-ui';
import { cx } from '#packages/util';
import { AIDS } from '../constants';
import styles from './popularArticles.scss';
import { symbol as Symbol } from '@wix/santa-editor-symbols';

import Block from './block';

import { HelpBlock } from '../types';
import type { Props } from '../types';

const PopularArticles: React.FC<Props> = (props) => {
  const onArticleClick = (helpId?: string) => {
    props.openHelpCenter(helpId);
  };

  return (
    <Block title="Editor_Help_Panel_Articles_Title" type={HelpBlock.ARTICLES}>
      {props.popularHelpArticles.map(({ title, helpId }) => (
        <div
          className={styles.popularArticle}
          data-aid={AIDS.ARTICLE}
          data-helpid={helpId}
          onClick={() => onArticleClick(helpId)}
          key={helpId}
        >
          <div className={styles.helpIcon}>
            <Symbol name="helpCenterArticle" />
          </div>

          <TextLabel
            className={styles.helpTitle}
            type="T07"
            value={title}
            enableEllipsis={false}
          />
        </div>
      ))}
      <Button
        className={cx('btn-md', 'btn-inverted', styles.seeAllButton)}
        automationId={AIDS.HELP_CENTER}
        onClick={() => onArticleClick()}
      >
        <TextLabel value="Editor_Help_Panel_Articles_CTA_Button" />
      </Button>
    </Block>
  );
};

export default PopularArticles;

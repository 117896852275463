// @ts-nocheck
import constants from '#packages/constants';

const consts = {
  EDITOR_API_NS: 'editorAPI',
  PLATFORM_PANEL: 'PLATFORM_PANEL',
  PLATFORM_ON_EVENT: 'PLATFORM_ON_EVENT',
  PLATFORM_SDK_ALIVE: 'PLATFORM_SDK_ALIVE',
  PLATFORM_SDK_READY: 'PLATFORM_SDK_READY',
  APP_WIDGET: 'platform.components.AppWidget',
  RENAME_TYPE: 'page_rename',
  SET_AS_HOMEPAGE_TYPE: 'page_set_as_homepage',
  CONTROLLER_COMPONENT_TYPES: [
    'platform.components.AppController',
    'platform.components.AppWidget',
  ],
  TPA_WIDGET_TYPE_TEMPLATE: {
    qname: {
      prefix: '$w',
      shortName: 'IFrame',
    },
    componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
    tagName: '',
    members: null,
    events: null,
    baseTypes: {},
    inheritanceChain: null,
  },
  panelTypes: {
    CHANGE_VARIATIONS: 'platformPanels.changeVariationsPanel',
    COMPONENT_PANEL: 'platformPanels.componentPanel',
    FULL_STAGE: 'platformPanels.fullStagePanel',
    MODAL: 'platformPanels.modalPanel',
    TOOL: 'platformPanels.toolPanel',
    TOOL_FLOATING: 'platformPanels.floatingToolPanel',
    DOCKED: 'platformPanels.sidePanel',
    DASHBOARD: 'platformPanels.dashboardPanel',
    ADDONS_MARKET: 'platformPanels.addonsMarketPanel',
  },
  NATIVE_PANELS: constants.PANELS.NATIVE_PANELS,
  pages: {
    page_settings: {
      layout: {
        type: constants.PAGE_SETTINGS_TABS.LAYOUT,
        fallbackType: 'Pages_Layouts_Tab_Title',
      },
      permissions: {
        type: constants.PAGE_SETTINGS_TABS.PERMISSIONS,
        fallbackType: 'Pages_Permissions_Tab_Title',
      },
      pageInfo: {
        type: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
      },
      seo: {
        type: constants.PAGE_SETTINGS_TABS.SEO,
      },
    },
    page_actions: {
      rename: {
        title: 'Pages_Actions_Page_Rename',
        type: 'Pages_Actions_Page_Rename',
        icon: 'quickActionsRename',
      },
      delete: {
        title: 'Pages_Actions_Page_Delete',
        type: 'page_delete',
        icon: 'deleteAction',
      },
      copy: {
        title: 'Pages_Actions_Page_Copy',
        type: 'page_copy',
        icon: 'copyPaste',
      },
      duplicate: {
        type: 'page_duplicate',
        icon: 'duplicateAction',
      },
    },
  },
  members: {
    router_actions: {
      view_site_members: {
        title: 'Pages_Panel_MemberMenu_SiteMembers_Link',
        type: 'view_site_members',
        icon: 'goToURLAction',
      },
      open_signup_settings: {
        title: 'Pages_Panel_MemberMenu_SignupSettings_Link',
        type: 'open_signup_settings',
        icon: 'membersAction',
      },
    },
  },
  controller: {
    DEFAULT_STATE: 'default',
  },
  GFPP: {
    mainActionNames: {
      MAIN_ACTION1: 'mainAction1',
      MAIN_ACTION2: 'mainAction2',
      DEPRECATED_MAIN_ACTION: 'mainAction',
    },
  },
  PLATFORM_EXTRA_NAMESPACES: {
    APP_BUILDER: ['appBuilder'],
  },
  FORM_COMPONENT_TYPE: 'wysiwyg.viewer.components.FormContainer',
  POPUP_COMPONENT_TYPE: 'wysiwyg.viewer.components.PopupContainer',
};

export default consts;

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import * as baseUI from '#packages/baseUI';
import experiment from 'experiment';

import * as symbols from '@wix/santa-editor-symbols';
import pageMenuConstants from '../utils/constants';
import constants from '#packages/constants';
import { cx } from '#packages/util';

function template() {
  const { isEditMode } = this.state;

  return (
    <div
      onClick={this.onClick}
      onMouseEnter={this.onMouseEnter}
      onMouseLeave={this.onMouseLeave}
      className={cx(this.getTopLevelClasses(this.quickActionsList.length))}
    >
      {!isEditMode ? (
        <div
          key="pageInfo"
          onDoubleClick={this.onDoubleClick}
          className="wrapper-view-mode"
        >
          {this.shouldShowDragSymbol() ? (
            <symbols.symbol
              name="dragIcon"
              key="drag-icon"
              dataHintMyData="hint_my_data"
              className="page-item-drag"
            />
          ) : null}

          <span className="page-title">
            <span className="page-title-name">{this.props.label}</span>
            {this.props.isHome ? (
              <symbols.symbol name="pageItemHomeIcon" key="isHome" />
            ) : null}
            {this.props.isLink ? (
              <symbols.symbol name="pageItemLinkIcon" key="isLink" />
            ) : null}
            {this.props.isDropdown ? (
              <symbols.symbol name="pageItemDropdownIcon" key="isDropDown" />
            ) : null}
            {!util.isCustomMenusEnabled() && !this.props.isVisible ? (
              <symbols.symbol name="pageItemHideIcon" key="isHidden" />
            ) : null}
            {this.props.isRouter ? (
              <symbols.symbol name="pageItemRouterIcon" key="isRouter" />
            ) : null}
            {this.props.routerType === constants.ROUTER_TYPES.CUSTOM_ROUTER ? (
              <symbols.symbol
                name="pageItemCustomerRouterPageIcon"
                key="isDynamicPageCustomRouter"
              />
            ) : null}
            {this.props.routerType === constants.ROUTER_TYPES.DATA_BINDING ? (
              <symbols.symbol
                name="pageItemDataBindingRouterPageIcon"
                key="isDynamicPageDataBinding"
              />
            ) : null}
          </span>

          {this.shouldShowQuickActions() ? (
            <ul key="quickActions" className="pages-quick-actions">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.quickActionsList, (qAction, qActionIndex) => (
                <li key={`qAction${qActionIndex}`}>{qAction}</li>
              ))}
            </ul>
          ) : null}
        </div>
      ) : null}

      {isEditMode ? (
        <baseUI.textInputWithFixedButton
          buttonLabel="Pages_Actions_Rename_Button"
          key="nameInput"
          maxLength={pageMenuConstants.NAME_MAX_LENGTH}
          ref="nameInput"
          selectOnFocus={true}
          onOuterClick={this.handleOuterClick}
          shouldChangeOnOuterClick={true}
          validator={this.validateName}
          invalidMessage="Pages_Info_Name_Error_Tooltip"
          {...this.getInputProps()}
        />
      ) : null}
    </div>
  );
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'pageItem',
  propTypes: {
    label: PropTypes.string,

    value: PropTypes.string,
    onChange: PropTypes.func,

    isVisible: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    depth: PropTypes.number,
    quickActionButtons: PropTypes.number,
    isHome: PropTypes.bool,
    isLink: PropTypes.bool,
    isDropdown: PropTypes.bool,
    isMobile: PropTypes.bool,
    isMobileVisible: PropTypes.bool,
    hasNotification: PropTypes.bool,

    editModeLink: PropTypes.object,
  },

  mixins: [baseUI.inputMixin, util.timeoutsMixin],

  getInitialState() {
    return {
      isEditMode: this.props.editModeLink && this.props.editModeLink.value,
      hasMouseInside: false,
      wasClicked: false,
    };
  },

  getTopLevelClasses(quickActionsCount) {
    const ret = {
      'hidden-page': !this.props.isVisible,
      selected: this.props.isSelected,
      'has-notification': this.props.hasNotification,
      'edit-mode': this.state.isEditMode,
      'pages-tree-item': true,
      'pages-tree-page-item-in-edit-mode': this.state.isEditMode,
      'disable-pages-drag': this.props.isMobile,
    };
    const typeIcon =
      this.props.isHome || this.props.isLink || this.props.isDropdown ? 1 : 0;
    const hidden = this.props.isVisible ? 0 : 1;
    ret[`icon-cnt-${typeIcon + hidden}`] = true;
    ret[`depth-${this.props.depth || 0}`] = true;
    ret[`qa-${quickActionsCount || 0}`] = true;

    if (experiment.isOpen('pagesFirstTimeSteps')) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(this.props.additionalClasses, function (className) {
        ret[className] = true;
      });
    }

    return ret;
  },

  getChildren() {
    const res = [];
    React.Children.forEach(this.props.children, function (qA) {
      if (!qA || !React.isValidElement(qA)) {
        return;
      }

      res.push(React.cloneElement(qA));
    });
    return res;
  },

  getInputProps() {
    return _.omit(
      this.props,
      'label',
      'isVisible',
      'isSelected',
      'onClick',
      'isHome',
      'isLink',
      'isDropdown',
    );
  },

  handleOuterClick() {
    if (this.props.editModeLink && this.props.editModeLink.value) {
      this.props.editModeLink.requestChange(false);
    }
  },

  onDoubleClick() {
    if (!this.props.isDynamicPage && !this.props.isRouter) {
      if (
        this.props.isSelected &&
        this.props.editModeLink &&
        !this.props.editModeLink.value
      ) {
        this.props.editModeLink.requestChange(true);
      }
    }
  },

  lastValidationMessage: null,

  validateName(newPageName) {
    this.lastValidationMessage =
      util.validate.legacyPageNameValidator(newPageName);
    return !this.lastValidationMessage;
  },

  getLastValidationMessage() {
    return this.lastValidationMessage || '';
  },

  render() {
    this.quickActionsList = this.shouldShowQuickActions()
      ? this.getChildren()
      : [];

    return template.call(this);
  },

  shouldShowQuickActions() {
    return (
      (!this.props.isRouter || (this.props.isRouter && !this.props.isMobile)) &&
      (this.state.hasMouseInside ||
        this.props.isSelected ||
        this.props.hasNotification)
    );
  },

  shouldShowDragSymbol() {
    return (
      !this.props.isDynamicPage && !this.props.isRouter && !this.props.isMobile
    );
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      isEditMode: nextProps.editModeLink?.value,
    });
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditMode && !prevState.isEditMode) {
      this.refs.nameInput.focusControl();
    }
  },

  onMouseEnter() {
    this.setState({ hasMouseInside: true });
  },

  onMouseLeave(e) {
    if (!this.state.wasClicked) {
      if (_.isFunction(this.props.onMouseLeave)) {
        this.props.onMouseLeave(e);
      }
      this.setState({ hasMouseInside: false });
    }

    this.setState({ wasClicked: false });
  },

  onClick(e) {
    //TODO: bogdanl rewrite this fix to less ugly solution
    // windows mouse click will trigger undesirable mouse out
    // to prevent this behaviour we set flag in mouse click handler and
    // we are going to check this flag in onMouseLeave function
    this.setState({ wasClicked: true });

    if (this.props.onClick) {
      this.props.onClick(e);
    }
  },
});

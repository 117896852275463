import React from 'react';
import { Button, Divider, RichText, Text } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

import { translate } from '#packages/i18n';

interface PublishRCFrameProps {
  publish: () => void;
  publishRC: () => void;
  branchName: string;
  shouldShowBranchName: boolean;
}

function getPublishButtonText(
  shouldShowBranchName: boolean,
  branchName: string,
) {
  return shouldShowBranchName
    ? translate('SiteBraches_Editor_Publish_Button', {
        branchName,
      })
    : translate('Release_Manager_Dropdown_Publish_Button');
}

export const PublishRCFrame: React.FC<PublishRCFrameProps> = (props) => (
  <div className="publish-rc-drop-panel">
    <div className="container">
      <symbols.symbol name="dropPanel_publish" className="icon" />
      <div className="content">
        <Text
          size="small"
          weight="bold"
          skin="secondary"
          key="publishLabel"
          className="label"
        >
          Release_Manager_Dropdown_Publish_Title
        </Text>
        <RichText key="publishText" className="text">
          <p>{translate('Release_Manager_Dropdown_Publish_Text')}</p>
        </RichText>
        <Button
          automationId="publishButton"
          key="publishButton"
          onClick={() => props.publish()}
          className="button"
        >
          {getPublishButtonText(props.shouldShowBranchName, props.branchName)}
        </Button>
      </div>
    </div>
    <Divider className="divider" />
    <div className="container">
      <symbols.symbol name="dropPanel_rc" className="icon" />
      <div className="content">
        <Text
          size="small"
          weight="bold"
          skin="secondary"
          key="rcLabel"
          className="label"
        >
          Test_Site_Manager_Dropdown_Create_Test_Site_Title
        </Text>
        <RichText key="rcText" className="text">
          <p>{translate('Test_Site_Manager_Dropdown_Create_Test_Site_Text')}</p>
        </RichText>
        <Button
          key="rcButton"
          automationId="rcButton"
          onClick={() => props.publishRC()}
          className="btn-confirm-secondary"
        >
          {translate('Test_Site_Manager_Dropdown_Create_Test_Site_Button')}
        </Button>
      </div>
    </div>
  </div>
);

export const NewWorkspacePublishRCFrame: React.FC<PublishRCFrameProps> = (
  props,
) => (
  <div className="publish-rc-drop-panel">
    <div className="container">
      <div className="content">
        <Text
          key="publishLabel"
          size="small"
          weight="bold"
          skin="secondary"
          className="label"
        >
          Release_Manager_Dropdown_Publish_Title
        </Text>
        <RichText>
          <p>{translate('Release_Manager_Dropdown_Publish_Text')}</p>
        </RichText>
        <Button
          automationId="publishButton"
          onClick={() => props.publish()}
          className="btn-md"
        >
          {getPublishButtonText(props.shouldShowBranchName, props.branchName)}
        </Button>
      </div>
      <div className="illustration">
        <symbols.symbol key="publish_to_all" name="publish_to_all" />
      </div>
    </div>
    <Divider className="divider" long />
    <div className="container">
      <div className="content">
        <Text size="small" weight="bold" skin="secondary">
          Test_Site_Manager_Dropdown_Create_Test_Site_Title
        </Text>
        <RichText>
          <p>{translate('Test_Site_Manager_Dropdown_Create_Test_Site_Text')}</p>
        </RichText>
        <Button
          automationId="rcButton"
          onClick={() => props.publishRC()}
          className="btn-md btn-confirm-secondary"
        >
          {translate('Test_Site_Manager_Dropdown_Create_Test_Site_Button')}
        </Button>
      </div>
      <div className="illustration">
        <symbols.symbol key="publish_test_site" name="publish_test_site" />
      </div>
    </div>
  </div>
);

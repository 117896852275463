import type {
  BaseDragPlugin,
  PluginContext,
  PluginFactoryScope,
} from './common';
import _ from 'lodash';
import * as core from '#packages/core';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import {
  isAnchor,
  calcAnchorArrangementIndexInSection,
} from '#packages/anchors';
import { isMeshLayoutEnabled } from '#packages/layoutOneDockMigration';
import {
  initMoveByTransitionEndInterceptor,
  tapMoveByTransitionEndInterceptorContext as tapMoveByTransitionEndInterceptor,
  waitMoveByTransitionEndResult,
} from './attach/moveTransitionInterceptor';
import type { AttachCandidateResponse } from '#packages/layout';
import type { EditorAPI } from '#packages/editorAPI';
import type { ViewerMouseCoordinates } from '../types';
import type { CompRef } from 'types/documentServices';

const { isInInteractionMode, getInteractionTriggerRef } =
  stateManagement.interactions.selectors;

const { isMultiselect } = util.array;
const { AttachHandler, DragConstraintsHandler } = core.utils;

interface CommonScope {
  // attachUtil: AttachHandler;
  dragConstraintsHandler: core.utils.DragConstraintsHandler;
  editorAPI: EditorAPI;
  selectedComp: CompRef[];
}

interface MultiScope extends CommonScope {
  compsWithAttachUtils: ReturnType<typeof getCompsWithAttachUtils>;
  context: {
    calulateMultiTargetsResult?: ReturnType<typeof calulateMultiTargets>;
  };
}

interface SingleScope extends CommonScope {
  attachUtil: core.utils.AttachHandler;
  context: {
    calulateSingleTargetResult?: ReturnType<typeof calulateSingleTarget>;
  };
}

interface CompToAttachWithAttachCandidate {
  component: CompRef;
  attachCandidate?: AttachCandidateResponse;
}

function getCompsWithAttachUtils(scope: CommonScope, components: CompRef[]) {
  const { editorAPI } = scope;
  return components.map((component) => {
    const compWithAttachHandler = {
      component,
      attachUtil: new AttachHandler(
        editorAPI,
        {
          comp: component,
          layout: editorAPI.components.layout.getRelativeToScreen(component),
        },
        AttachHandler.TYPES.COMP,
        { isDragHandler: true },
      ),
    };

    return compWithAttachHandler;
  });
}

function isAttachAllowedForInteractionMode(scope: CommonScope): boolean {
  const { editorAPI } = scope;
  const state = editorAPI.store.getState();
  const triggerRef = getInteractionTriggerRef(state);
  return (
    editorAPI.components.getType(triggerRef) ===
    constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER
  );
}

function isAttachmentEnabledForComps(
  scope: CommonScope,
  components: CompRef[],
) {
  const { editorAPI } = scope;
  const state = editorAPI.store.getState();
  if (isInInteractionMode(state)) {
    return isAttachAllowedForInteractionMode(scope);
  }
  const containsGroupedComponents = components.some(function (component) {
    return editorAPI.components.is.groupedComponent(component);
  });

  return !containsGroupedComponents;
}

type SimilarAttachCandidates = Record<
  string,
  { attachCandidate?: AttachCandidateResponse; components: CompRef[] }
>;

function groupSimilarAttachCandidate(
  compsToAttachWithAttachCandidate: CompToAttachWithAttachCandidate[],
): SimilarAttachCandidates {
  return compsToAttachWithAttachCandidate.reduce<SimilarAttachCandidates>(
    (res, { attachCandidate, component: comp }) => {
      if (!attachCandidate || !attachCandidate.candidateRef) {
        return res;
      }
      if (res[attachCandidate.candidateRef.id]) {
        res[attachCandidate.candidateRef.id].components.push(comp);
      } else {
        res[attachCandidate.candidateRef.id] = {
          attachCandidate,
          components: [comp],
        };
      }
      return res;
    },
    {},
  );
}

function calulateMultiTargets(scope: MultiScope) {
  const { compsWithAttachUtils } = scope;

  const compsToAttach = compsWithAttachUtils.map(({ component }) => component);
  const compsToAttachWithAttachCandidate = compsWithAttachUtils.map(
    ({ attachUtil, component }): CompToAttachWithAttachCandidate => ({
      attachCandidate: attachUtil.getCurrentAttachCandidate(
        null,
        compsToAttach,
      ),
      component,
    }),
  );

  return compsToAttachWithAttachCandidate;
}

function beforeDropToMultiTargets_Mesh(scope: MultiScope) {
  const CandidateStatus = AttachHandler.ATTACH_CANDIDATE_STATUSES;
  const calulateMultiTargetsResult = calulateMultiTargets(scope);

  scope.context.calulateMultiTargetsResult = calulateMultiTargetsResult;

  const compContainerToByCompId = new Map(
    calulateMultiTargetsResult
      .filter(
        ({ attachCandidate }) =>
          attachCandidate.candidateStatus === CandidateStatus.VALID &&
          attachCandidate.candidateRef,
      )
      .map(({ attachCandidate, component }) => [
        component.id,
        attachCandidate.candidateRef,
      ]),
  );

  tapMoveByTransitionEndInterceptor({
    compContainerToByCompId,
  });
}

async function dropToMultiTargets(scope: MultiScope) {
  const { editorAPI, compsWithAttachUtils } = scope;
  const compsToAttach = compsWithAttachUtils.map(({ component }) => component);
  const CandidateStatus = AttachHandler.ATTACH_CANDIDATE_STATUSES;

  const compsToAttachWithAttachCandidate = isMeshLayoutEnabled()
    ? scope.context.calulateMultiTargetsResult
    : calulateMultiTargets(scope);

  const {
    [CandidateStatus.VALID]: compsForReParenting = [],
    [CandidateStatus.INVALID]: compsForDeletion = [],
    [CandidateStatus.SAME]: compsWithoutAttachCandidate = [],
  } = _.groupBy(compsToAttachWithAttachCandidate, ({ attachCandidate }) =>
    attachCandidate && attachCandidate.candidateRef
      ? attachCandidate.candidateStatus
      : CandidateStatus.SAME,
  );

  let compsReparented: CompRef[] = [];
  if (isMeshLayoutEnabled()) {
    // NOTE: reparenting is handled by moveByTransition
    const { compRefUpdatedMap } = await waitMoveByTransitionEndResult();

    compsReparented = compsForReParenting.map(({ component }) =>
      compRefUpdatedMap.get(component.id),
    );
  } else {
    compsReparented = Object.values(
      groupSimilarAttachCandidate(compsForReParenting),
    )
      .flatMap((compsWithAttachCandidate) =>
        editorAPI.components.setContainer(
          compsWithAttachCandidate.components,
          compsWithAttachCandidate.attachCandidate.candidateRef,
        ),
      )
      .filter(Boolean);
  }

  compsForDeletion.forEach(({ component }) => {
    editorAPI.components.remove(component);
  });

  if (compsReparented.length === 0) {
    return;
  }

  const containedShowOnSomePages = compsToAttach.some(
    editorAPI.components.isShowOnSomePages,
  );

  editorAPI.dsActions.waitForChangesApplied(() => {
    const containsShowOnSomePages = compsReparented.some(
      editorAPI.components.isShowOnSomePages,
    );

    if (containedShowOnSomePages && !containsShowOnSomePages) {
      editorAPI.panelHelpers.openDetachedFromSOSPPanel();
    } else if (!containedShowOnSomePages && containsShowOnSomePages) {
      editorAPI.panelHelpers.openAttachToSOSPPanel();
    }

    editorAPI.selection.selectComponentByCompRef(
      compsReparented.concat(
        compsWithoutAttachCandidate.map(({ component }) => component),
      ),
    );
  });
}

function calulateSingleTarget(
  scope: SingleScope,
  mouseCoordinates: ViewerMouseCoordinates,
) {
  const attachCandidateResponse = scope.attachUtil.getCurrentAttachCandidate(
    null,
    null,
    {
      x: mouseCoordinates.clientX,
      y: mouseCoordinates.clientY,
    },
  );

  return attachCandidateResponse;
}

async function beforeDropToSingleTarget_Mesh(
  scope: SingleScope,
  mouseCoordinates: ViewerMouseCoordinates,
) {
  const calulateSingleTargetResult = calulateSingleTarget(
    scope,
    mouseCoordinates,
  );

  scope.context.calulateSingleTargetResult = calulateSingleTargetResult;

  const { candidateStatus, candidateRef } = calulateSingleTargetResult;
  const compRef = scope.selectedComp[0];
  const compContainerToByCompId =
    candidateStatus === AttachHandler.ATTACH_CANDIDATE_STATUSES.VALID &&
    candidateRef
      ? new Map([[compRef.id, candidateRef]])
      : new Map();

  tapMoveByTransitionEndInterceptor({
    compContainerToByCompId,
  });
}

async function dropToSingleTarget(
  scope: SingleScope,
  mouseCoordinates: ViewerMouseCoordinates,
) {
  const CandidateStatus = AttachHandler.ATTACH_CANDIDATE_STATUSES;
  const { candidateStatus, candidateRef } = isMeshLayoutEnabled()
    ? scope.context.calulateSingleTargetResult
    : calulateSingleTarget(scope, mouseCoordinates);

  const {
    editorAPI,
    selectedComp: [compRef],
  } = scope;

  if (candidateStatus === CandidateStatus.INVALID) {
    editorAPI.components.remove(compRef);
    return;
  }

  // NOTE: CandidateStatus.VALID means reparenting (candidate is new container for component)
  if (candidateStatus === CandidateStatus.VALID) {
    const shouldReselectOnReparenting = isInInteractionMode(
      editorAPI.store.getState(),
    );
    const wasShownOnSomePages = editorAPI.components.isShowOnSomePages(compRef);
    if (shouldReselectOnReparenting) {
      editorAPI.selection.deselectComponents(compRef);
    }

    let attachedComps: CompRef | CompRef[];
    if (isMeshLayoutEnabled()) {
      // NOTE: reparenting is handled by moveByTransition
      const { compRefUpdatedMap } = await waitMoveByTransitionEndResult();

      attachedComps = compRefUpdatedMap.get(compRef.id);
    } else {
      attachedComps = editorAPI.components.setContainer(compRef, candidateRef);
    }

    await editorAPI.dsActions.waitForChangesAppliedAsync();

    if (shouldReselectOnReparenting) {
      editorAPI.selection.selectComponentByCompRef(compRef);
    }

    const isShowOnSomePages =
      editorAPI.components.isShowOnSomePages(attachedComps);
    if (wasShownOnSomePages && !isShowOnSomePages) {
      editorAPI.panelHelpers.openDetachedFromSOSPPanel();
    } else if (!wasShownOnSomePages && isShowOnSomePages) {
      editorAPI.panelHelpers.openAttachToSOSPPanel();
    }

    if (
      util.sections.isSectionsEnabled() &&
      editorAPI.sections.isSectionLike(candidateRef) &&
      editorAPI.components.is.fullWidth(compRef) &&
      // NOTE: anchor height is virtual, so it should not update section layout
      !isAnchor(editorAPI.documentServices, compRef)
    ) {
      util.sections.rearrangeNewSectionElement(editorAPI, {
        mouseY: mouseCoordinates.clientY,
        container: candidateRef,
        component: compRef,
      });
    }
  }

  if (
    util.sections.isSectionsEnabled() &&
    editorAPI.sections.isSection(candidateRef) &&
    isAnchor(editorAPI.documentServices, compRef)
  ) {
    await editorAPI.dsActions.waitForChangesAppliedAsync();

    const anchorRef = compRef;
    const anchorArrangementIndex = calcAnchorArrangementIndexInSection(
      editorAPI.documentServices,
      anchorRef,
    );

    if (
      typeof anchorArrangementIndex === 'number' &&
      anchorArrangementIndex >= 0
    ) {
      editorAPI.components.arrangement.moveToIndex(
        anchorRef,
        anchorArrangementIndex,
        { dontAddToUndoRedoStack: true },
      );
    }
  }
}

function highlightAttachCandidate(
  scope: SingleScope,
  mouseCoordinates: ViewerMouseCoordinates,
  isFocusMode: boolean,
) {
  const { attachUtil, dragConstraintsHandler } = scope;
  const { candidateRef: attachCandidateRef, candidateStatus } =
    attachUtil.getCurrentAttachCandidate(null, null, {
      x: mouseCoordinates.clientX,
      y: mouseCoordinates.clientY,
    });

  const isValid =
    candidateStatus === AttachHandler.ATTACH_CANDIDATE_STATUSES.VALID ||
    isFocusMode;

  if (isValid && attachCandidateRef) {
    attachUtil.highlightAttachCandidate(attachCandidateRef);
    dragConstraintsHandler.highlightDragConstraints();
  } else {
    attachUtil.clearHighlightAttachCandidate(false);
  }
}

const getCommmonScope = (
  pluginFactoryScope: PluginFactoryScope,
): CommonScope => {
  const { selectedComp, editorAPI } = pluginFactoryScope;

  const dragConstraintsHandler = new DragConstraintsHandler(
    editorAPI,
    selectedComp as AnyFixMe, //TODO ask alex
  );
  return { dragConstraintsHandler, editorAPI, selectedComp };
};

const initSingleComponent = (
  { pluginFactoryScope, hooks }: PluginContext,
  commonScope: CommonScope,
) => {
  const { selectedComp, editorAPI } = pluginFactoryScope;
  const initialCompLayout =
    editorAPI.components.layout.getRelativeToScreen(selectedComp);

  const createSingleScope = () => ({
    ...commonScope,
    attachUtil: new AttachHandler(
      editorAPI,
      {
        layout: initialCompLayout,
        comp: selectedComp as AnyFixMe,
      },
      AttachHandler.TYPES.COMP,
      { isDragHandler: true },
    ),
    context: {},
  });
  let singleScope: SingleScope = null;

  hooks.onDragStart.tap(() => {
    singleScope = singleScope || createSingleScope();
  });

  const isFocusMode = editorAPI.componentFocusMode.isEnabled();
  hooks.beforeLayoutUpdate.tap(({ mouseCoordinates }) => {
    highlightAttachCandidate(singleScope, mouseCoordinates, isFocusMode);
  });

  if (isMeshLayoutEnabled()) {
    hooks.beforeEndDragLayout.tap(({ mouseCoordinates }) => {
      void beforeDropToSingleTarget_Mesh(singleScope, mouseCoordinates);
    });
  }

  hooks.endDrag.tap(({ mouseCoordinates }) => {
    void dropToSingleTarget(singleScope, mouseCoordinates);
    singleScope.attachUtil.clearHighlightAttachCandidate(false);
    commonScope.dragConstraintsHandler.removeHighlightDragConstraints();
  });
};

const initMultiComponent = (
  { pluginFactoryScope, hooks }: PluginContext,
  commonScope: CommonScope,
) => {
  const createMultiScope = () => ({
    ...commonScope,
    compsWithAttachUtils: getCompsWithAttachUtils(
      commonScope,
      pluginFactoryScope.selectedComp,
    ),
    context: {},
  });

  let multiScope: MultiScope = isMeshLayoutEnabled()
    ? null
    : createMultiScope();

  hooks.onDragStart.tap(() => {
    multiScope = multiScope || createMultiScope();
  });

  if (isMeshLayoutEnabled()) {
    hooks.beforeEndDragLayout.tap(() => {
      void beforeDropToMultiTargets_Mesh(multiScope);
    });
  }

  hooks.endDrag.tap(() => {
    dropToMultiTargets(multiScope);
    multiScope.dragConstraintsHandler.removeHighlightDragConstraints();
  });
};

export const AttachPlugin: BaseDragPlugin = {
  init: (ctx) => {
    const { pluginFactoryScope } = ctx;
    const { selectedComp } = pluginFactoryScope;
    const commonScope = getCommmonScope(pluginFactoryScope);

    const isAttachToEnabled = isAttachmentEnabledForComps(
      commonScope,
      selectedComp,
    );
    if (!isAttachToEnabled) {
      return;
    }

    if (isMultiselect(selectedComp)) {
      initMultiComponent(ctx, commonScope);
    } else {
      initSingleComponent(ctx, commonScope);
    }

    if (isMeshLayoutEnabled()) {
      initMoveByTransitionEndInterceptor({ editorAPI: commonScope.editorAPI });
    }
  },
};

// @ts-nocheck
import * as util from '#packages/util';
import blogManagePanel from './panels/blogManagePanel';
import blogPageListPanel from './panels/blogPagesPanel';
import * as blogUtils from '../utils/blogUtils';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as blogSectionsManager from '../addPanel/blogSectionsManager';

const createSuperApp = ({
  shouldShowOldBlogMigrationPromo,
  promotionalFlow,
  migrationFlow,
}) => ({
  id: blogUtils.superAppId,
  appDefId: '61f33d50-3002-4882-ae86-d319c1a249ab',
  appName: 'Blog',
  firstTimeTour: {
    templateFlow: [migrationFlow],
    manuallyInstalledFlow: [],
    promotionalFlow: [promotionalFlow],
  },
  panel: {
    name: blogUtils.superAppPanelName,
    manage: {
      title: 'SiteApp_Blog_ManageTab_Header',
      helpId: 'd81d7260-c556-49c4-9798-0a7e8be839bd',
      template: blogManagePanel,
    },
    pages: {
      title: 'SiteApp_Blog_PagesTab_Header',
      helpId: 'cd55a4da-ab10-40df-803e-f9cb5a7f35e2',
      template: blogPageListPanel,
    },
    addElements: {
      title: 'SiteApp_Blog_AddTab_Header',
      helpId: 'c3dd9067-87f2-4044-93b4-f491b35580f6',
      categoryId: addPanelDataConsts.CATEGORIES_ID.BLOG,
      disabledInMobileMessageTitle: 'Siteapp_Blog_AddPanelMobile_Title',
      disabledInMobileMessageDescription:
        'Siteapp_Blog_AddPanelMobile_Description',
      disabledInMobileMessageDescriptionLink:
        'Siteapp_Blog_AddPanelMobile_Description_LinkPart',
      teaser: {
        id: 'category_blog2_teaser',
        hide: false,
        title: 'add_category_teaser_title_blog2',
        text: 'add_category_teaser_text_blog2',
      },
      mobileImage: 'mobileToEditor',
      sections: (editorApi) => {
        const sections = blogSectionsManager.getSections(editorApi);

        return sections.map((section, index) =>
          Object.assign({}, section, { id: index.toString() }),
        );
      },
    },
    learnMore: {
      title: 'SiteApp_Blog_LearnMoreTab_Header',
      section1HeaderViewA: 'SiteApp_Blog_LearnMorePanel_Section1_Header',
      section2HeaderViewA: 'SiteApp_Blog_LearnMorePanel_Section2_Header',
      section3HeaderViewA: 'SiteApp_Blog_LearnMorePanel_Section3_Header',
      data: {
        viewA: {
          helpCenterPopUp: {
            link: 'ccb372ad-1ae2-46c8-90d7-34e6534624a9',
            image: util.media.getMediaUrl(
              'superAppMenuInfra/TipsFromWixsBloogingPros.png',
            ),
          },
          supportForumsPopUp: {
            link: 'https://www.wix.com/support/html5/wix-blog/getting-started-with-wix-blog/',
            image: util.media.getMediaUrl(
              'superAppMenuInfra/ViewTheBlogHelpCenter.png',
            ),
          },
          wixTipsBlog: {
            link: 'http://www.wix.com/blog/tag/blogging-tips', //TODO: change to the right urls
            image: util.media.getMediaUrl(
              'superAppMenuInfra/OpenTheBlogSupportForum.png',
            ),
          },
        },
        viewB: [
          {
            title: 'From the blog help center',
            link: 'https://www.wix.com/support/html5/wix-blog/getting-started-with-wix-blog/',
            data: [
              {
                link: 'https://www.wix.com/support/html5/wixstores/setting-up-your-store/tutorial/activating-wixstores',
                description: 'Customize your blog feed',
                image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
              },
              {
                link: 'https://www.wix.com/support/html5/wixstores/managing-your-store/tutorial/managing-your-products-for-wixstores',
                description: 'Blog manger basics',
                image: 'packages/wixStore/images/learnMoreHelpTopics2.png', //TODO: change to the real assets once we get them
              },
              {
                link: 'https://www.wix.com/support/html5/wixstores/managing-your-store/tutorial/managing-collections-in-wixstores',
                description: 'Adding, editing and deleting blog posts',
                image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
              },
              {
                link: 'https://www.wix.com/support/html5/wixstores/managing-your-store/tutorial/setting-up-payment-in-wixstores',
                description: 'Additional blog post actions',
                image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
              },
            ],
          },
          {
            title: 'Tips from Wix’s blogging pros',
            link: 'http://www.wix.com/blog/tag/blogging-tips/',
            data: [
              {
                link: 'http://www.wix.com/blog/2014/07/20-content-ideas-for-your-website-or-blog/',
                description: '20 Content ideas for your website or blog',
                image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
              },
              {
                link: 'http://www.wix.com/blog/2014/02/no-ones-reading-your-blog-5-effective-copywriting-tips/',
                description:
                  'No ones reading your blog? 5 effective copywriting tips',
                image: 'packages/wixStore/images/learnMoreHelpTopics2.png', //TODO: change to the real assets once we get them
              },
              {
                link: 'http://www.wix.com/blog/2012/08/become-a-successful-freelance-blogger/',
                description: 'How to become a successful freelancer blogger',
                image: 'packages/wixStore/images/learnMoreHelpTopics1.png', //TODO: change to the real assets once we get them
              },
              {
                link: 'http://www.wix.com/blog/2014/05/the-abcs-of-driving-traffic-to-your-website/',
                description: 'The ABCs of driving traffic to your website',
                image: 'packages/wixStore/images/learnMoreHelpTopics2.png', //TODO: change to the real assets once we get them
              },
              {
                link: 'http://www.wix.com/blog/2014/10/creating-blogs-with-wix/',
                description:
                  'Great content, gorgeous design: 16 blogs created with Wix',
                image: 'packages/wixStore/images/learnMoreHelpTopics2.png', //TODO: change to the real assets once we get them
              },
            ],
          },
        ],
      },
    },
  },
  isAppInstalled: shouldShowOldBlogMigrationPromo
    ? () => false
    : blogUtils.siteHasOldBlog,
});

export default createSuperApp;

export const TRANSLATIONS_MAP = {
  panelTitle: 'custom_menu_manage_menu_header_label',
  MAIN_MENU_FLOW: {
    MENU_NAMES: {
      MOBILE: {
        main: 'custom_menu_mobile_manage_menu_main_or_custom_option_main',
        custom: 'custom_menu_mobile_manage_menu_choose_menu_option_custom',
      },
      main: 'custom_menu_manage_menu_choose_menu_option_default',
      custom: 'custom_menu_manage_menu_choose_menu_option_custom',
    },
    HEADER: {
      label: 'custom_menu_manage_menu_type_label',
    },
    PANEL_TITLE: 'custom_menu_manage_menu_items_header_label',
    PANEL_TOOLTIP: {
      DESCRIPTION: 'custom_menu_manage_menu_main_or_custom_tooltip',
      LINK_TITLE: 'custom_menu_manage_menu_main_or_custom_tooltip_link',
      MOBILE: {
        DESCRIPTION: 'custom_menu_mobile_manage_menu_main_or_custom_tooltip',
      },
    },
    PANEL_DESCRIPTION: 'custom_menu_manage_menu_main_or_custom_label',
    RADIO_OPTIONS: {
      MAIN: 'custom_menu_manage_menu_main_or_custom_option_main',
      MAIN_TOOLTIP:
        'custom_menu_manage_menu_main_or_custom_option_main_tooltip',
      MAIN_MOBILE_TOOLTIP:
        'custom_menu_mobile_manage_menu_main_or_custom_option_main_tooltip',
      CUSTOM: 'custom_menu_manage_menu_main_or_custom_option_custom',
    },
    EDIT: {
      MOBILE: {
        custom: 'custom_menu_mobile_manage_menu_items_section_title_custom',
      },
      main: 'custom_menu_manage_menu_items_section_title_main',
      custom: 'custom_menu_manage_menu_items_section_title_custom',
    },
    FOOTER: {
      MOBILE_DISCLAIMER: 'custom_menu_mobile_manage_menu_go_to_desktop_text',
      MOBILE_DISCLAIMER_LINK:
        'custom_menu_mobile_manage_menu_go_to_desktop_link',
    },
  },
  HEADER: {
    LABEL: 'custom_menu_manage_menu_choose_menu_label',
    TOOLTIP: {
      description: 'custom_menu_manage_menu_choose_menu_tooltip',
      linkTitle: 'custom_menu_manage_menu_choose_menu_tooltip_link',
    },
    MENU_ITEMS: 'custom_menu_manage_menu_items_section_title',
    CHANGE_LABEL: 'custom_menu_manage_menu_change_menu_button',
  },
  FOOTER: {
    SHOW_PAGES: {
      title: 'custom_menu_manage_menu_show_page_list_button',
      PANEL: {
        header: 'custom_menu_manage_menu_pages_header_label',
        submitLabel: 'custom_menu_manage_menu_pages_apply_button',
        selectAll: 'custom_menu_manage_menu_pages_select_all_label',
      },
    },
    ADD_MENU_ITEM: 'custom_menu_manage_menu_add_item_CTA',
    ADD_LINK: {
      TOOLTIP: {
        title: 'custom_menu_manage_menu_add_link_title',
        description: 'custom_menu_manage_menu_add_link_text',
        linkTitle: 'custom_menu_manage_menu_add_link_link',
      },
    },
    MENU_ACTIONS: {
      SITE_PAGES: 'custom_menu_manage_menu_add_item_site_pages',
      DYNAMIC_PAGES: 'custom_menu_manage_menu_add_item_dynamic_pages',
      LINK: 'custom_menu_manage_menu_add_item_link',
      ANCHOR: 'custom_menu_manage_menu_add_item_anchor',
      SECTION: 'custom_menu_manage_menu_add_item_section',
      SUBMENU: 'custom_menu_manage_menu_add_item_submenu',
      DROPDOWN: 'custom_menu_manage_menu_add_item_dropdown',
      DROPDOWN_TITLE: 'NewPages_Panel_AddMenuItem_MenuItems_Dropdown_Label',
      CONTAINER: 'custom_menu_manage_menu_add_item_container',
      CONTAINER_BADGE: 'custom_menu_manage_menu_add_item_container_badge',
      MEMBER_PAGES: 'custom_menu_manage_menu_add_item_member_pages',
    },
    ADD_FOLDER: {
      TOOLTIP: {
        title: 'custom_menu_manage_menu_add_dropdown_tooltip_title',
        description: 'custom_menu_manage_menu_add_dropdown_tooltip_text',
        linkTitle: 'custom_menu_manage_menu_add_dropdown_tooltip_link',
      },
    },
    mobileDescription: 'custom_menu_mobile_manage_menu_go_to_desktop_text',
    mobileLinkTitle: 'custom_menu_mobile_manage_menu_go_to_desktop_link',
  },
  ITEM: {
    LINK: {
      newLinkText: 'custom_menu_manage_menu_new_link_default_text',
      placeholder: 'custom_menu_manage_menu_new_link_placeholder_text',
      emptyValidationError: 'custom_menu_manage_menu_link_rename_empty_error',
      renameDoneButton: 'custom_menu_manage_menu_link_rename_done',
    },
    DROPDOWN: {
      newItemText: 'custom_menu_manage_menu_new_dropdown_default_text',
      placeholder: 'custom_menu_manage_menu_new_dropdown_placeholder_text',
      emptyValidationError:
        'custom_menu_manage_menu_dropdown_rename_empty_error',
      renameDoneButton: 'custom_menu_manage_menu_dropdown_rename_done',
    },
    CONTAINER: {
      newItemText: 'custom_menu_manage_menu_new_container_default_text',
      label: 'custom_menu_manage_menu_container_label',
      edit: 'custom_menu_manage_menu_container_edit',
    },
    ACTIONS: {
      deleteLink: 'custom_menu_manage_menu_link_options_delete_item',
      deletePage: 'custom_menu_manage_menu_page_options_remove_from_menu',
      deleteDropdown:
        'custom_menu_manage_menu_dropdown_item_options_delete_item',
      duplicate: 'custom_menu_manage_all_menus_menu_options_duplicate',
      renamePage: 'custom_menu_manage_menu_page_options_rename',
      renameLink: 'custom_menu_manage_menu_link_options_rename_link',
      renameSection: 'custom_menu_manage_menu_link_options_rename_section',
      renameDropdown: 'custom_menu_manage_menu_dropdown_item_options_rename',
      renamePageInMultiLingual:
        'custom_menu_manage_menu_page_options_rename_multilingual',

      moveLinkDown: 'custom_menu_manage_menu_link_options_move_under_submenu',
      movePageDown: 'custom_menu_manage_menu_page_options_move_under_submenu',
      moveFolderDown:
        'custom_menu_manage_menu_dropdown_item_options_move_under_submenu',
      moveFolderUp:
        'custom_menu_manage_menu_dropdown_item_options_move_out_submenu',
      moveLinkUp: 'custom_menu_manage_menu_page_options_move_out_submenu',
      movePageUp: 'custom_menu_manage_menu_link_options_move_out_submenu',

      seoSettingsAction: 'custom_menu_manage_menu_page_options_settings',
      link: 'custom_menu_manage_menu_dropdown_item_options_link',
      unlink: 'custom_menu_manage_menu_page_options_unlink',
      changeLink: 'custom_menu_manage_menu_link_options_change_link',
      renameDone: 'custom_menu_manage_all_menus_custom_rename_done',
    },
    ERRORS: {
      tooShort: 'custom_menu_manage_menu_page_rename_empty_error',
      tooLong: 'is to long - add key',
    },
  },
  NOTIFICATIONS: {
    EDIT_MENU: {
      description: 'Notifications_Custom_Menu_Changed_Menu_Name_Text',
      linkTitle: 'Notifications_Custom_Menu_Changed_Menu_Name_Link',
    },
    RENAME_PAGE: {
      description: 'Notifications_Custom_Menu_Changed_Page_Name_Text',
      linkTitle: 'Notifications_Custom_Menu_Changed_Page_Name_Link',
    },
  },
};

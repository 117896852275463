import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const tinyMenuGfppData = {
  mobileEnabledActions: [ACTIONS.SETTINGS],
  presetActions: {
    help: {
      mobileHelpId: helpIds.GFPP.TINY_MENU,
    },
  },
};

export default tinyMenuGfppData;

// @ts-nocheck
import * as coreBi from '#packages/coreBi';

function desktopOrMobileLabelGenerator(desktopLabel, mobileLabel) {
  return function (editorAPI /*, compPointer*/) {
    if (editorAPI.isMobileEditor()) {
      return mobileLabel;
    }

    return desktopLabel;
  };
}

function sendBI(editorAPI) {
  const isDesktopEditor = editorAPI.isDesktopEditor();
  const event = isDesktopEditor
    ? coreBi.events.columns.COLUMNS_MOVE_LEFT_RIGHT
    : coreBi.events.columns.COLUMNS_MOVE_UP_DOWN;
  editorAPI.bi.event(event, { origin: 'rcm' });
}

export default [
  {
    label: desktopOrMobileLabelGenerator(
      'RightClick_Menu_Move_Column_Left_Label',
      'RightClick_Menu_Mobile_Move_Column_Up_Label',
    ),
    enabled(editorAPI, compPointer) {
      return editorAPI.components.arrangement.canMoveBackward(compPointer);
    },
    onClick(editorAPI, compPointer) {
      editorAPI.columns.move.left(compPointer);
      sendBI(editorAPI);
      editorAPI.closeRightClickMenu();
    },
  },
  {
    label: desktopOrMobileLabelGenerator(
      'RightClick_Menu_Move_Column_Right_Label',
      'RightClick_Menu_Mobile_Move_Column_Down_Label',
    ),
    enabled(editorAPI, compPointer) {
      return editorAPI.components.arrangement.canMoveForward(compPointer);
    },
    onClick(editorAPI, compPointer) {
      editorAPI.columns.move.right(compPointer);
      sendBI(editorAPI);
      editorAPI.closeRightClickMenu();
    },
  },
];

import { isSectionLike, getClosestSectionLike } from '../../../api';
import { isMeshLayoutEnabled } from '#packages/layout';

import type { CompRef } from 'types/documentServices';
import type { Scope } from '#packages/sections';

export const createChildResizeAndPushHooks = (scope: Scope) => {
  const { layout } = scope;

  const end = async (compRef: CompRef) => {
    if (isSectionLike(scope, compRef)) return;

    const sectionLikeParent = getClosestSectionLike(scope, compRef);

    if (!sectionLikeParent) return;

    // apply layout constraint to sectionLike component resized by child
    const currentLayout = layout.get(sectionLikeParent);
    const layoutWithConstraints = layout.getConstrainedLayout(
      sectionLikeParent,
      currentLayout,
      true,
    );

    if (isMeshLayoutEnabled()) {
      if (layoutWithConstraints.height === currentLayout.height) {
        return;
      }

      layout.resizeTo(
        sectionLikeParent,
        {
          height: layoutWithConstraints.height,
        },
        {
          dontAddToUndoRedoStack: true,
        },
      );

      return;
    }

    layout.update(sectionLikeParent, layoutWithConstraints);
  };

  return {
    end,
  };
};

// @ts-nocheck
import _ from 'lodash';
import { overridable } from '@wix/santa-editor-utils';
import * as textControls from '#packages/textControls';
import { translate } from '#packages/i18n';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const getCompRef = (editorAPI: EditorAPI, compRefOrId: CompRef | string) =>
  typeof compRefOrId === 'string'
    ? editorAPI.dsRead.components.get.byId(compRefOrId)
    : compRefOrId;

// EditorX overrides tpaAppIsAlive call for TPA settings,
// They will need to replace the compId from the message with compRef that has variant info of breakpoint.
// In order not to re-write the function and just re-use it with comp ref we want this method to accept compRef.
const appIsAlive = overridable((editorAPI, msg, callback) => {
  editorAPI.panelManager.updatePanelProps('tpa.compPanels.tpaSettings', {
    isAlive: true,
    sdkVersion: msg?.data?.version,
  });
  const addWixHelveticaFonts = true;
  const style = editorAPI.dsRead.tpa.style.getByComp(
    getCompRef(editorAPI, msg.compId),
    addWixHelveticaFonts,
  );
  style.siteTextPresets = addFontThemeDisplayNameToTextPresets(
    style.siteTextPresets,
  );
  callback(style);
});

const addFontThemeDisplayNameToTextPresets = function (textPresets) {
  const stylesMap = _.keyBy(
    textControls.constants.DEFAULT_STYLES_MAP,
    'cssClass',
  );

  return _.transform(
    textPresets,
    function (result, value, key) {
      if (stylesMap[value.editorKey]) {
        value.displayName = translate(stylesMap[value.editorKey].displayName);
        result[key] = value;
      }
    },
    {},
  );
};

export { appIsAlive };

// @ts-nocheck
import wixFormsConstants from '../constants';
import * as wixFormsUtils from '../utils';
import addPanelDataConsts from '#packages/addPanelDataConsts';

const title = 'add_section_label_contactforms';
const presetTitle = 'add_section_label_contactforms_preset';
const tooltipTitle = 'add_section_label_contactforms_tooltip_title';
const subNavigationTitle = 'add_section_label_contact_sidebar';
const helpText = 'add_section_label_contactforms_tooltip_description';

const getContactSection = (editorAPI) => ({
  contactSection: {
    type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
    hide: false,
    title,
    topTitle: title,
    tooltipTitle,
    sectionName: 'contactSection',
    automationId: 'add-panel-section-contactSection',
    appDefinitionId: wixFormsConstants.FORM_BUILDER_APP_ID,
    showSectionHeader: true,
    subNavigationTitle,
    subNavigationHide: false,
    presetTitle,
    additionalBehaviours: {
      labelMode: 'none',
      infoIcon: 'none',
      hoverImageAction: 'scale',
      iconEnabledForComps: {},
    },
    props: {
      image:
        'addPanelData/sections/formsContactSection_en/formsContactSection_en.v8.png',
      imageStyle: wixFormsConstants.imageStyle,
      imageHover: null,
      items: [
        {
          id: 'Contact_Forms_1',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT11,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT11,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 540,
              height: 517,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: { rect: { width: 162, height: 162, x: 0, y: 0 }, tags: null },
        },
        {
          id: 'Contact_Forms_2',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT12,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT12,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 472,
              height: 536,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 162, x: 162, y: 0 },
            tags: null,
          },
        },
        {
          id: 'Contact_Forms_3', // TODO: multi
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT13,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT13,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 462,
              height: 455,
              x: 185,
              y: 15,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
            controllerType: wixFormsConstants.CONTROLLER_TYPES.MULTI_STEP_FORM,
          }),
          preset: {
            rect: { width: 162, height: 181, x: 0, y: 162 },
            tags: null,
          },
        },
        {
          id: 'Contact_Forms_4',
          onDrop: wixFormsUtils.onDrop(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT14,
          ),
          onClick: wixFormsUtils.onClick(
            editorAPI,
            wixFormsConstants.FormPreset.CONTACT14,
          ),
          structure: wixFormsUtils.getFormWidget({
            layout: {
              width: 557,
              height: 758,
              x: 20,
              y: 412,
              scale: 1.0,
              rotationInDegrees: 0.0,
              fixedPosition: false,
            },
          }),
          preset: {
            rect: { width: 162, height: 181, x: 162, y: 162 },
            tags: null,
          },
        },
      ],
      compTypes: [wixFormsConstants.componentType],
    },
    help: {
      hide: false,
      text: helpText,
    },
  },
});

export default (editorAPI) => getContactSection(editorAPI);

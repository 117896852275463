import React from 'react';
import * as symbols from '@wix/santa-editor-symbols';
import { PanelHeader } from '@wix/wix-base-ui';

import { translate } from '#packages/i18n';

interface MobileElementsSettingsHeaderComponentProps {
  title: string;
  onHelpClick: () => void;
  onCloseClick: () => void;
}

export const MobileElementsSettingsHeaderComponent: React.FC<
  MobileElementsSettingsHeaderComponentProps
> = (props) => {
  return (
    <div className="mobile-settings-header">
      <div className="title">{translate(props.title)}</div>
      <div onClick={props.onHelpClick} className="helpSign">
        ?
      </div>
      <div onClick={props.onCloseClick}>
        <symbols.symbol name="arrowRightBig" className="arrow" />
      </div>
    </div>
  );
};

export const NewWorkspaceMobileElementsSettingsHeaderComponent: React.FC<
  MobileElementsSettingsHeaderComponentProps
> = (props) => {
  return (
    <PanelHeader
      onClose={props.onCloseClick}
      onHelp={props.onHelpClick}
      closeSymbol={<symbols.symbol name="arrowLeft" />}
    >
      {translate(props.title)}
    </PanelHeader>
  );
};

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { GFPPActionType } from '@wix/editor-component-types';

import * as util from '#packages/util';
import _ from 'lodash';

const { isAppWidget } = util.appStudioUtils;
import * as stateManagement from '#packages/stateManagement';

const findConnectedComponentByControllerType = (
  editorAPI: EditorAPI,
  connectedComponents: CompRef[],
  controllerType: string,
) => {
  return connectedComponents.find(
    (compRef) =>
      editorAPI.components.data.get(compRef)?.controllerType === controllerType,
  );
};

const findConnectedComponentByRole = (
  editorAPI: EditorAPI,
  connectedComponents: CompRef[],
  role: string,
  subRole?: string,
) => {
  return connectedComponents.find((compRef) => {
    const primaryConnection =
      editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(
        compRef,
      );
    if (subRole) {
      return (
        role === primaryConnection?.role &&
        subRole === primaryConnection?.subRole
      );
    }
    return role === primaryConnection?.role;
  });
};

const getGfppDataFromApp = (editorAPI: EditorAPI, compRef: CompRef) => {
  const gfppDataGetterFn = isAppWidget(editorAPI, compRef)
    ? editorAPI.platform.controllers.getStageData
    : editorAPI.platform.controllers.getConnectedComponentStageData;
  // @ts-expect-error
  return gfppDataGetterFn(compRef)?.gfpp;
};

const getControllerRefOfComponent = (
  compRef: CompRef,
  editorAPI: EditorAPI,
) => {
  const potentialControllerRef =
    editorAPI.dsRead.platform.controllers.connections.getPrimaryConnection(
      compRef,
    )?.controllerRef;
  return potentialControllerRef || (isAppWidget(editorAPI, compRef) && compRef);
};

const getTargetCompRef = (
  target: AnyFixMe,
  controllerRef: CompRef,
  editorAPI: EditorAPI,
) => {
  if (!target) {
    return;
  }

  const { role, subRole, controllerType } = target;
  const connectedComponents =
    editorAPI.dsRead.platform.controllers.connections.getConnectedComponents(
      controllerRef,
    );
  connectedComponents.push(controllerRef);
  return role || subRole
    ? findConnectedComponentByRole(
        editorAPI,
        connectedComponents,
        role,
        subRole,
      )
    : findConnectedComponentByControllerType(
        editorAPI,
        connectedComponents,
        controllerType,
      );
};

const recursivelyGetTargetAction = ({
  editorAPI,
  target,
  deviceType,
  index,
  controllerRef,
}: AnyFixMe): AnyFixMe => {
  const targetRef = getTargetCompRef(target, controllerRef, editorAPI);
  return getTargetAction({
    editorAPI,
    target,
    targetRef,
    deviceType,
    index,
    controllerRef: getControllerRefOfComponent(targetRef, editorAPI),
  });
};

const getTargetAction = ({
  editorAPI,
  target,
  targetRef,
  deviceType,
  index,
  controllerRef,
}: AnyFixMe): AnyFixMe => {
  const { actionId, actionType } = target;
  const gfppDataFromApp = getGfppDataFromApp(editorAPI, targetRef);
  const appGfppData = _.get(gfppDataFromApp, deviceType);
  const mainActions = _.compact([
    appGfppData.mainAction1,
    appGfppData.mainAction2,
  ]);

  if (actionId) {
    const findActionById = (actionList: AnyFixMe) =>
      actionList.find(
        (action: AnyFixMe) =>
          typeof action === 'object' &&
          !action.target &&
          action.actionId === actionId,
      );
    return (
      findActionById(mainActions) ||
      findActionById(Object.values(appGfppData?.iconButtons))
    );
  }

  if (actionType && appGfppData?.iconButtons) {
    const mainActionWithActionType = mainActions.find(
      (action) =>
        typeof action === 'object' && action?.actionType === actionType,
    );
    const iconButtonWithActionType = appGfppData?.iconButtons?.[actionType];
    if (iconButtonWithActionType?.target) {
      const iconButtonTarget = {
        ...iconButtonWithActionType?.target,
        actionType,
      };
      return recursivelyGetTargetAction({
        editorAPI,
        target: iconButtonTarget,
        deviceType,
        index,
        controllerRef,
      });
    }

    return mainActionWithActionType || iconButtonWithActionType;
  }

  const defaultAction = mainActions[index] || mainActions[0];
  if (defaultAction?.target) {
    return recursivelyGetTargetAction({
      editorAPI,
      target: defaultAction.target,
      deviceType,
      index,
      controllerRef,
    });
  }
  return defaultAction;
};

const getSelectedComponents = (
  selectedComponents: CompRef[],
  editorAPI: EditorAPI,
  actionType?: GFPPActionType | 'textSize',
) => {
  const selectedComponent = selectedComponents[0];
  const deviceType = stateManagement.mobile.selectors.getDeviceType(
    editorAPI.store.getState(),
  );
  const gfppAppDataForDeviceType = getGfppDataFromApp(
    editorAPI,
    selectedComponent,
  )?.[deviceType];

  const target =
    gfppAppDataForDeviceType?.target ||
    gfppAppDataForDeviceType?.iconButtons?.[actionType]?.target;
  if (target) {
    const controllerRef = getControllerRefOfComponent(
      selectedComponent,
      editorAPI,
    );
    const targetCompRef =
      controllerRef && getTargetCompRef(target, controllerRef, editorAPI);
    return targetCompRef ? [targetCompRef] : selectedComponents;
  }
  return selectedComponents;
};

export {
  getTargetAction,
  getTargetCompRef,
  getGfppDataFromApp,
  getSelectedComponents,
  findConnectedComponentByRole,
  getControllerRefOfComponent,
};

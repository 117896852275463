// @ts-nocheck
import constants from '#packages/constants';
import gfppDataUtils from '../../utils/gfppDataUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from '@wix/document-services-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;
const regularModeActions = [
  ACTIONS.APPLY_TO_OTHER_VIEW,
  ACTIONS.HELP,
  ACTIONS.DESIGN,
];

const hoverBoxBackgroundPanelName =
  'compPanels.panels.HoverBox.backgroundPanel';
const hoverBoxCustomizeDesignPanelName = 'customizeDesignDataPanel.standalone';

function getActions() {
  return regularModeActions; //TODO add hoverModeActions if in hover mode
}

export default {
  untranslatable: true,
  mainActions: [
    {
      label: 'gfpp_main_action_change_hover_box_background',
      isApplied: true,
      isSelected: gfppDataUtils.getPanelStateFn(hoverBoxBackgroundPanelName),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          hoverBoxBackgroundPanelName,
          { selectedComponent: compRef, origin },
        );
      },
      automationId: 'gfpp-button-background',
    },
  ],
  enabledActions: getActions(),
  mobileEnabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.MOBILE_BACKGROUND_SETTINGS,
  ],
  presetActions: {
    design: {
      isSelected: gfppDataUtils.getPanelStateFn(
        hoverBoxCustomizeDesignPanelName,
      ),
      onClick(editorAPI: EditorAPI, compRef: CompRef, origin: string) {
        gfppDataUtils.toggleComponentPanel(
          editorAPI,
          hoverBoxCustomizeDesignPanelName,
          {
            selectedComponent: compRef,
            overrideTitle: 'hover_box_design_header_label',
            origin,
          },
        );
      },
    },
    help: {
      helpId: 'e98d2677-9601-4028-9b26-b19aa4b23aa6',
      mobileHelpId: 'ecd17734-ca03-4f4b-9711-4a524382efef',
    },
  },
};

import { loadComponentPanelDefinition } from '#packages/componentModel';
import constants from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';
import { constants as textConstants, fontUtils } from '#packages/textControls';
import type { WithStylableChangeProps } from '@wix/santa-editor-utils';
import * as editorUtils from '@wix/santa-editor-utils';
import { isEditorDataFunction } from '@wix/editor-component-data-resolver';
import type { StylePanelView } from '@wix/stylable-panel';
import type {
  DimensionUnits,
  EditorComponentConfig,
} from '@wix/stylable-panel-common';
import type {
  EditorNotification,
  FontItems,
  FontTheme,
  SelectableFonts,
  Translation,
} from '@wix/stylable-panel-controllers';
import experiment from 'experiment';
import _ from 'lodash';
import type { ComponentType } from 'react';
import type { ClientRect, CompRef } from 'types/documentServices';
import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
} from 'types/redux';
import type {
  CompData,
  EditorPalette,
  ElementLayout,
  MediaManagerOptions,
  PopupPanelPosition,
  PopupPanelProps,
  StyleMap,
  StylePanelPlane,
  ThemeColorPreset,
  VerticalPanelOptions,
} from './stylablePanelTypes';
import type { StylablePanelWithUserPreferencesProps } from './stylablePanelUserPreferences';
import { stylablePanelOverrides } from './stylablePanelOverrides';
import { isNewColorPaletteOpen, type ColorName } from '#packages/theme';

const { getSelectedComponents } = editorUtils.stylable;

const panelsActions = stateManagement.panels.actions;
const stylableEditorActions = stateManagement.stylableEditor.actions;
const componentsSelectors = stateManagement.components.selectors;
const notificationsActions = stateManagement.notifications.actions;
const highlightsActions = stateManagement.highlights.actions;
// const highlightsSelectors = stateManagement.highlights.selectors;

const { getEditorData } = stateManagement.components.selectors;
const { getStageLayout } = stateManagement.domMeasurements.selectors;

const HIGHLIGHT_TYPE = constants.UI.HIGHLIGHTS.TYPES.OVERLAY;

export interface StylablePanelStateProps {
  closePanel: (panelName: string) => void;
  forceState: (selector: string, selectionSelector: string) => void;
  revertForceState: () => void;
  getColorPresets: () => ThemeColorPreset[];
  updateSitePalette: (palette: EditorPalette) => void;
  getTextStyles: () => FontTheme[];
  getCurrentSelectableFontsWithParams: () => SelectableFonts[];
  getFontsDropDownItems: (fonts: SelectableFonts[]) => FontItems[];
  getSelectedComponentId: () => CompData;
  mediaManagerCategory: string;
  openMediaManager: (category: string, options: MediaManagerOptions) => void;
  getElementLayout: (compRef: CompRef, selector: string) => ElementLayout[];
  getExternalComponentDefinition: (
    compType: string,
  ) => Promise<EditorComponentConfig>;
  getCustomPanel: (compType: string) => Promise<ComponentType | undefined>;
  getConfigOverrides: (compType: string) => object | undefined;
  reportBI: Function;
  //getHighlights: Function;
  parentPanelCoordinates: DOMRect | undefined;
  stageLayout: stateManagement.StageLayout;
  isNewPaletteOpen: boolean;
  visibleThemeColors: ColorName[];
}

export interface StylablePanelDispatchProps {
  setUserColors: () => void;
  openPanel: (
    panelPath: string,
    panelProps: PopupPanelProps,
    position: PopupPanelPosition,
  ) => void;
  updatePanelProps: (panelName: string, panelProps: PopupPanelProps) => void;
  showUserActionNotification: (notification: EditorNotification) => void;
  clearHighlights: () => void;
  setHighlights: (compRef: CompRef, layouts: ElementLayout[]) => void;
  openThemePanelColorTab: () => void;
}

export interface StylablePanelProps
  extends WithStylableChangeProps,
    StylablePanelStateProps,
    StylablePanelDispatchProps,
    StylablePanelWithUserPreferencesProps {
  /* Passed implicitly */
  selectedComponent: CompRef | CompRef[];
  multiSelectedComponents?: CompRef[];
  compType: string;
  setHelpId?: (helpId: string) => void;
  panelName?: string;
  title?: string;
  style?: { top: number; left: number };
  stretched?: boolean;
  useMouseEvent?: boolean;

  /* Passed from withModules */
  stylablePanelLib: typeof import('stylable-panel');

  /* Passed explicitly */
  breakpointId?: { id: string }; // TODO need this?
  initialView?: StylePanelView;
  plane?: StylePanelPlane;
  customTranslations?: Translation;
  dimensionUnits?: DimensionUnits;
  pageHasPlatformApp?: (page: string, appId: string) => boolean;
  onSiteColorChange?: (option: VerticalPanelOptions) => void;
  getFontThemes?: StylablePanelStateProps['getTextStyles'];
  editorAPI: EditorAPI;
}

async function getCustomPanel(compType: string) {
  const customPanel = await loadComponentPanelDefinition(
    compType,
    'stylablePanelReact',
  );
  return customPanel as ComponentType | undefined;
}

function getExternalComponentDefinition(
  editorAPI: EditorAPI,
  selectedComponents: CompRef[],
) {
  return async (compType: string) => {
    const externalDefinition = await loadComponentPanelDefinition(
      compType,
      'stylablePanel',
    );

    if (!externalDefinition) {
      return undefined;
    }

    return (
      isEditorDataFunction(externalDefinition)
        ? getEditorData(
            externalDefinition,
            editorAPI,
            _.head(selectedComponents),
          )
        : externalDefinition
    ) as EditorComponentConfig;
  };
}

export const mapStateToProps: MapStateToProps<
  StylablePanelStateProps,
  StylablePanelProps
> = (
  { editorAPI, dsRead },
  { selectedComponent: selectedComponentOrComponents, multiSelectedComponents },
) => {
  const { theme, components } = editorAPI.documentServices;
  const selectedComponents = getSelectedComponents(
    selectedComponentOrComponents,
  ) as CompRef[];
  const selected = multiSelectedComponents || selectedComponents;
  const getConfigGetterParams = () => ({
    editorAPI,
    isExperimentOpen: experiment.isOpen,
    compData: componentsSelectors.getData(selectedComponents, dsRead),
    compProperties: componentsSelectors.getProperties(
      selectedComponents,
      dsRead,
    ),
  });

  const forceState = (selector: string, selectionSelector: string) => {
    selected.forEach((compRef) => {
      components.stylable.forceState(compRef, {
        selector,
        selectionSelector,
      });
    });
  };

  const revertForceState = () => {
    selected.forEach((compRef) => {
      components.stylable.revertForceState(compRef);
      return;
    });
  };

  const parentPanelCoordinates = document
    .querySelector('.stylable-panel')
    ?.getBoundingClientRect();

  return {
    pageHasPlatformApp: (page: string, appId: string) => {
      return editorAPI.platform.pageHasPlatformApp
        ? editorAPI.platform.pageHasPlatformApp(page, appId)
        : false;
    },
    closePanel: (panelName: string) =>
      editorAPI.panelManager.closePanelByName(panelName),
    forceState,
    revertForceState,
    getColorPresets: () => theme.colors.getColorPresets(),
    updateSitePalette: (palette: EditorPalette) => theme.colors.update(palette),
    isNewPaletteOpen:
      isNewColorPaletteOpen(editorAPI) &&
      experiment.isOpen('se_stylableNewColorPicker'),
    visibleThemeColors: editorAPI.theme.colors.getVisibleThemeColorsKeys(),
    getTextStyles: () =>
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map(textConstants.TEXT_THEMES_STYLES_MAP, (style: StyleMap) =>
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(
          fontUtils.getStyleFont(
            style.cssClass,
            theme.colors.getAll() as any,
            theme.fonts.getAll(),
          ),
          _.pick(style, ['seoTag', 'cssClass', 'displayName']),
        ),
      ) as FontTheme[],
    getCurrentSelectableFontsWithParams: () =>
      fontUtils.getCurrentSelectableFontsWithParams(
        editorAPI,
        false,
        false,
      ) as SelectableFonts[],
    getFontsDropDownItems: (fonts: SelectableFonts[]) =>
      fontUtils.getFontsDropDownItems(fonts),
    getSelectedComponentId: () =>
      editorAPI.components.data.get(selectedComponents),
    mediaManagerCategory:
      editorAPI.mediaServices.mediaManager.categories.BG_IMAGE,
    openMediaManager: (category: string, options: MediaManagerOptions) =>
      editorAPI.mediaServices.mediaManager.open(category, options),
    getElementLayout: (compRef: CompRef, selector: string): ElementLayout[] =>
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _.map<ClientRect, ElementLayout>(
        components.layout.measure.getInnerElementBoundingClientRects(
          compRef,
          selector,
        ),
        ({ width, height, absoluteLeft, absoluteTop }) => ({
          width,
          height,
          x: absoluteLeft,
          y: absoluteTop,
        }),
      ),
    getExternalComponentDefinition: getExternalComponentDefinition(
      editorAPI,
      selectedComponents,
    ),
    getCustomPanel,
    getConfigOverrides: (panelCompType: string) => {
      const stylablePanelOverride = stylablePanelOverrides[panelCompType];

      if (!stylablePanelOverride) {
        return undefined;
      }

      return stylablePanelOverride(getConfigGetterParams());
    },
    reportBI: editorAPI.bi.reportBI,
    // getHighlights: () => highlightsSelectors.getHighlights(state),
    parentPanelCoordinates,
    stageLayout: getStageLayout(editorAPI.store.getState()),
    editorAPI,
  };
};

export const mapDispatchToProps: MapDispatchToProps<
  StylablePanelDispatchProps,
  StylablePanelProps
> = (dispatch: Dispatch) => ({
  setUserColors() {
    dispatch(stylableEditorActions.setUserColors());
  },
  openPanel(
    panelPath: string,
    panelProps: PopupPanelProps,
    position: PopupPanelPosition,
  ) {
    dispatch(
      panelsActions.updateOrOpenPanel(
        panelPath,
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(panelProps, position),
        true,
      ),
    );
  },
  updatePanelProps(panelName: string, panelProps: PopupPanelProps) {
    dispatch(panelsActions.updatePanel(panelName, panelProps));
  },
  showUserActionNotification(notification: EditorNotification) {
    dispatch(notificationsActions.showUserActionNotification(notification));
  },
  clearHighlights() {
    dispatch(highlightsActions.clearHighlights());
  },
  setHighlights(compRef: CompRef, layouts: ElementLayout[]) {
    dispatch(
      highlightsActions.setHighlights(
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        _.map(layouts, (layout) => ({
          compRef,
          layout,
          type: HIGHLIGHT_TYPE,
        })),
      ),
    );
  },
  openThemePanelColorTab() {
    const panel = constants.ROOT_COMPS.LEFTBAR.DESIGN_PANEL_NAME;

    const panelOptions = {
      selectedView: constants.DESIGN_PANEL.VIEWS.COLOR,
      origin: 'GFPPColorPicker',
    };

    dispatch(stateManagement.panels.actions.openLeftPanel(panel, panelOptions));
  },
});

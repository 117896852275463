import React, { type ComponentType, type FC } from 'react';
import { browserUtil, appStudioUtils } from '#packages/util';
import LazyComponent from '#packages/lazyComponent';

function getWixCodeIDE(
  loadWixCodeWithRepluggableInClassic: boolean,
  CodeEditorComponent: ComponentType,
) {
  if (!loadWixCodeWithRepluggableInClassic) {
    return (
      <LazyComponent
        moduleName="wixCode.components.WixCodeIDE"
        key="wix-code-ide"
        id="wix-code-ide"
      />
    );
  }
  return <CodeEditorComponent />;
}

interface IDEContainerProps {
  loadWixCodeWithRepluggableInClassic: boolean;
  codeEditorComponent: ComponentType;
  toggleMeasureMaps: () => void;
  setIsWixCodeIDELoaded: (isLoaded: boolean) => void;
}
export const IDEContainer: FC<IDEContainerProps> = (props) => {
  const { toggleMeasureMaps, setIsWixCodeIDELoaded } = props;
  React.useMemo(() => {
    toggleMeasureMaps();
  }, [toggleMeasureMaps]);

  React.useEffect(() => {
    return () => {
      setIsWixCodeIDELoaded(false);
    };
  }, [setIsWixCodeIDELoaded]);

  return (
    <div onContextMenu={browserUtil.preventDefaultRightClick} data-aid="ide">
      {appStudioUtils.isAppStudio() ? (
        <LazyComponent
          moduleName="appStudio.components.IDE"
          key="app-builder-code-ide"
          id="app-builder-code-ide"
        />
      ) : (
        getWixCodeIDE(
          props.loadWixCodeWithRepluggableInClassic,
          props.codeEditorComponent,
        )
      )}
    </div>
  );
};

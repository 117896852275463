import { EditorAPIKey, DocumentServicesApiKey } from '#packages/apis';
import { createDuplexerFacade } from './duplexerFacade';
import type { Shell } from '#packages/apilib';
import * as stateManagement from '#packages/stateManagement';
import type { AppData } from '..';
import _ from 'lodash';

const DEBOUNCE_DELAY = 500;

export const createPrivateAppsEventsApi = (shell: Shell) => {
  const documentServicesApi = shell.getAPI(DocumentServicesApiKey) as AnyFixMe;
  const editorAPI = shell.getAPI(EditorAPIKey) as AnyFixMe;

  const duplexerFacade = createDuplexerFacade(shell);

  const registerToSiteChanges = (handler: () => void) => {
    documentServicesApi.platform.registerToAppsCompleted(handler);
    documentServicesApi.registerToSiteChanged(
      _.debounce(handler, DEBOUNCE_DELAY),
    );
  };

  const isTestAppInstalled = () => {
    const { getInstalledBlocksApps } = stateManagement.platform.selectors;
    const installedApps = getInstalledBlocksApps(editorAPI.dsRead) as AppData[];

    return installedApps.some(
      ({ appFields }) => appFields.installedVersion === 'latest',
    );
  };

  const initIfNeeded = _.once(() => {
    registerToSiteChanges(() => {
      if (isTestAppInstalled() && !duplexerFacade.isConnected()) {
        duplexerFacade.connect();
      }
      if (!isTestAppInstalled() && duplexerFacade.isConnected()) {
        duplexerFacade.disconnect();
      }
    });
  });

  return {
    subscribeToUserChannelEvent: (eventName: string, handler: Function) => {
      initIfNeeded();
      duplexerFacade.subscribeToUserChannelEvent(eventName, handler);
    },
  };
};

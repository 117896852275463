import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import type { MapDispatchToProps } from 'types/redux';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import { frames } from '#packages/panels';
import { SafeInjectHtml } from '#packages/util';

const { MessagePanelFrame } = frames;

const { event: sendBiEvent } = stateManagement.bi.actions;

const { translate_content_manually_clicked } = coreBi.events.multilingual;

interface NotEnoughWordsToGTOwnProps {
  panelName: string;
  wordsTranslated: number;
  wordsNeeded: number;
  wordsInCredit: number;
  mainLanguage: string;
  secondaryLanguage: string;
}

interface NotEnoughWordsToGTDispatchProps {
  sendContentManuallyClickedBI(origin: string): void;
  closePanel(): void;
  openPackagesPage(): void;
}

type NotEnoughWordsToGTProps = NotEnoughWordsToGTDispatchProps &
  NotEnoughWordsToGTOwnProps;

class NotEnoughWordsToGT extends React.Component<NotEnoughWordsToGTProps> {
  static propTypes = {
    panelName: PropTypes.string,
  };

  getTitle() {
    return translate('google_translate_notEnoughCredits_modal_header');
  }

  getMessage() {
    return (
      <SafeInjectHtml
        tag="div"
        html={translate('google_translate_notEnoughCredits_modal_subheader', {
          machine_translation_words_needed: this.props.wordsNeeded.toString(),
        })}
      />
    );
  }

  onClose = (origin: string) => {
    this.props.sendContentManuallyClickedBI(origin);
    this.props.closePanel();
  };

  getKeyboardShortcuts() {
    return {
      esc: () => this.onClose('x'),
    };
  }
  render() {
    return (
      <MessagePanelFrame
        panelName={this.props.panelName}
        illustration={null}
        title={this.getTitle()}
        confirmLabel={translate(
          'google_translate_notEnoughCredits_modal_getCredits',
        )}
        onConfirm={this.props.openPackagesPage}
        cancelLabel={translate(
          'google_translate_notEnoughCredits_modal_translateManually',
        )}
        onCancel={(origin) => this.onClose(origin)}
      >
        {this.getMessage()}
      </MessagePanelFrame>
    );
  }
}

const mapDispatchToProps: MapDispatchToProps<
  NotEnoughWordsToGTDispatchProps,
  NotEnoughWordsToGTOwnProps
> = (
  dispatch,
  {
    panelName,
    mainLanguage,
    secondaryLanguage,
    wordsTranslated,
    wordsNeeded,
    wordsInCredit,
  },
) => {
  return {
    sendContentManuallyClickedBI: (origin: string) =>
      dispatch(
        sendBiEvent(translate_content_manually_clicked, {
          is_credit_enough: false,
          origin: 'editor',
          words_remain: 0,
          words_needed: wordsNeeded,
          words_translated: wordsTranslated,
          words_in_credit: wordsInCredit,
          type: origin === 'done_button' ? 'translate manually' : 'x',
        }),
      ),
    closePanel: () =>
      dispatch(stateManagement.panels.actions.closePanelByName(panelName)),
    openPackagesPage: () =>
      dispatch(
        stateManagement.multilingual.actions.sendOpenPackagePickerEventThunk(
          'editor',
          mainLanguage,
          secondaryLanguage,
          wordsTranslated,
        ),
      ),
  };
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(NotEnoughWordsToGT);

import type { ToolsEntryPayload } from '@wix/editor-platform-host-integration-apis';
import { addOnsSelected } from '@wix/bi-logger-editor-platform/v2';
import experiment from 'experiment';

import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
import { biLogger } from '#packages/util';

import { getToolsMenuAPI } from './toolsMenuAPI';

import type { SendBiFunction } from 'types/bi';
import type { IMenuBarListItem } from '../../types';
import type {
  ToolsMenuAPIOwnProps,
  ToolsMenuAPIStateProps,
  ToolsMenuAPIDispatchProps,
} from './toolsMenuAPI';
import { fixedStage } from '#packages/util';

const { TOOLS_MENU_ITEMS } = constants.ROOT_COMPS.TOPBAR;

export interface ToolsListItemsOwnProps extends ToolsMenuAPIOwnProps {}
export interface ToolsListItemsStateProps extends ToolsMenuAPIStateProps {
  isToolBarEnabled: boolean;
  isSectionizerPanelDisplayed?: boolean;
  isAiPersonalizeContentDisplayed?: boolean;
  isLayersPanelDisplayed: boolean;
  inInteractionMode: boolean;
  isRulersEnabled: boolean;
  developerModeFirstTime: boolean;
  isDeveloperToolbarInCodePanel: boolean;
  isDeveloperHiddenComponentsEnabled: boolean;
  isDeveloperToolBarEnabled: boolean;
  isDeveloperModeEnabled: boolean;
  isGuidelinesEnabled: boolean;
  isMobileEditor: boolean;
  isSnapToEnabled: boolean;
  siteId: string;
  deviceType: string;
  addons?: ToolsEntryPayload[];
}
export interface ToolsListItemsDispatchProps extends ToolsMenuAPIDispatchProps {
  toggleSectionizerPanel?: () => void;
  sendBi: SendBiFunction;
}

export interface ToolsListItemsProps
  extends ToolsListItemsOwnProps,
    ToolsListItemsStateProps,
    ToolsListItemsDispatchProps {}

export const getToolsListItemsByProps = (
  props: ToolsListItemsProps,
): IMenuBarListItem[] => {
  const toolsMenuAPI = getToolsMenuAPI(props);

  return [
    {
      key: TOOLS_MENU_ITEMS.TOOLBAR,
      automationId: 'list-item-toolbar',
      isCheckbox: true,
      checkboxValue: props.isToolBarEnabled,
      leftTitle: 'TOPBAR_VIEW_TOOLBAR_TITLE',
      rightTitle: 'TOPBAR_VIEW_TOOLBAR_TITLE',
      description: 'TOPBAR_VIEW_TOOLBAR_BODY',
      symbolName: 'menubarToolbarPromotion_v2',
      onClick() {
        toolsMenuAPI[TOOLS_MENU_ITEMS.TOOLBAR]();

        props.sendBi(coreBi.events.topbar.top_bar_VIEW_menu_click, {
          category: 'toolbar',
          status: props.isToolBarEnabled ? 0 : 1,
        });
        if (props.isToolBarEnabled) {
          props.sendBi(coreBi.events.toolbar.TOOL_BAR_CLOSE_CLICK, {
            origin: 'tools_top_bar',
          });
        }
      },
    },
    {
      key: TOOLS_MENU_ITEMS.SECTIONIZER,
      automationId: 'list-item-sectionizer',
      isCheckbox: true,
      checkboxValue: props.isSectionizerPanelDisplayed,
      leftTitle: 'Sectionizer',
      rightTitle: 'Sectionizer',
      description: 'Sectionizer',
      symbolName: '',
      condition() {
        return experiment.isOpen('se_sectionizer');
      },
      onClick() {
        props.toggleSectionizerPanel();
      },
    },
    {
      key: TOOLS_MENU_ITEMS.LAYERS,
      automationId: 'list-item-layers',
      isCheckbox: true,
      checkboxValue: props.isLayersPanelDisplayed,
      leftTitle: 'TOPBAR_VIEW_LAYERS',
      rightTitle: 'TOPBAR_VIEW_LAYERS_PROMOTION_TITLE',
      description: 'TOPBAR_VIEW_LAYERS_PROMOTION_TEXT',
      symbolName: 'menubarLayersPromotion',
      onClick() {
        toolsMenuAPI[TOOLS_MENU_ITEMS.LAYERS]();

        props.sendBi(coreBi.events.topbar.top_bar_VIEW_menu_click, {
          category: 'layers',
          status: props.isLayersPanelDisplayed ? 0 : 1,
        });
      },
    },
    {
      key: TOOLS_MENU_ITEMS.LAYERS_SEPARATOR,
      isSeparator: true,
    },
    {
      isDisabled: props.inInteractionMode,
      key: TOOLS_MENU_ITEMS.RULERS,
      automationId: 'list-item-rulers',
      isCheckbox: true,
      checkboxValue: props.isRulersEnabled,
      leftTitle: 'TOPBAR_VIEW_RULERS1',
      rightTitle: 'TOPBAR_VIEW_RULERS_TITLE1',
      description: 'TOPBAR_VIEW_RULERS_BODY1',
      symbolName: 'menubarRulersPromotion_v2',
      onClick() {
        toolsMenuAPI[TOOLS_MENU_ITEMS.RULERS]();

        props.sendBi(coreBi.events.topbar.top_bar_VIEW_menu_click, {
          category: 'rulers',
          status: props.isRulersEnabled ? 0 : 1,
          editor_view_mode: props.deviceType,
        });
      },
    },
    {
      key: TOOLS_MENU_ITEMS.GUIDELINES,
      automationId: 'list-item-guidelines',
      isCheckbox: true,
      checkboxValue: props.isGuidelinesEnabled,
      isDisabled: props.isMobileEditor,
      leftTitle: 'TOPBAR_VIEW_GRIDLINES_TITLE',
      rightTitle: 'TOPBAR_VIEW_GRIDLINES_TITLE',
      description: 'TOPBAR_VIEW_GRIDLINES_BODY',
      symbolName: 'menubarGuidesPromotion_v2',
      onClick() {
        toolsMenuAPI[TOOLS_MENU_ITEMS.GUIDELINES]();

        props.sendBi(coreBi.events.topbar.top_bar_VIEW_menu_click, {
          category: 'guidelines',
          status: props.isGuidelinesEnabled ? 0 : 1,
        });
      },
      condition() {
        return !fixedStage.isFixedStageEnabled();
      },
    },
    {
      isDisabled: props.inInteractionMode,
      key: TOOLS_MENU_ITEMS.SNAP,
      automationId: 'list-item-snap',
      isCheckbox: true,
      checkboxValue: props.isSnapToEnabled,
      leftTitle: 'TOPBAR_VIEW_SNAP_TITLE',
      rightTitle: 'TOPBAR_VIEW_SNAP_TITLE',
      description: 'TOPBAR_VIEW_SNAP_BODY',
      symbolName: 'menubarSnapToObjectPromotion_v2',
      onClick() {
        toolsMenuAPI[TOOLS_MENU_ITEMS.SNAP]();

        props.sendBi(coreBi.events.topbar.top_bar_VIEW_menu_click, {
          category: 'snap',
          status: props.isSnapToEnabled ? 0 : 1,
        });
      },
    },
    {
      key: TOOLS_MENU_ITEMS.DEVELOPER_MODE_SEPARATOR,
      isSeparator: true,
      condition() {
        return props.isDeveloperModeEnabled;
      },
    },
    {
      key: TOOLS_MENU_ITEMS.DEVELOPER_MODE_TITLE,
      automationId: 'list-item-developer-mode',
      isTitleOnly: true,
      condition() {
        return props.isDeveloperModeEnabled;
      },
      isChecklist: false,
      skipClickClosePanel: props.developerModeFirstTime,
      leftTitle: 'WixCode_Developer_Mode_Title',
    },
    {
      isDisabled: props.inInteractionMode,
      key: TOOLS_MENU_ITEMS.PROPERTIES_TOOLBAR,
      automationId: 'list-item-properties-toolbar',
      isCheckbox: true,
      checkboxValue: props.isDeveloperToolBarEnabled,
      isNested: false,
      leftTitle: props.isDeveloperToolbarInCodePanel
        ? 'TOPBAR_VIEW_DEVELOPER_MODE_PROPERTIESANDEVENTS_PANEL_TITLE'
        : 'TOPBAR_VIEW_DEVELOPER_MODE_PROPERTIES_PANEL_TITLE',
      rightTitle: props.isDeveloperToolbarInCodePanel
        ? 'TOPBAR_VIEW_DEVELOPER_MODE_PROPERTIESANDEVENTS_PANEL_TITLE'
        : 'TOPBAR_VIEW_DEVELOPER_MODE_PROPERTIES_PANEL_TITLE',
      description: props.isDeveloperToolbarInCodePanel
        ? 'TOPBAR_VIEW_DEVELOPER_MODE_PROPERTIESANDEVENTS_PANEL_BODY'
        : 'TOPBAR_VIEW_DEVELOPER_MODE_PROPERTIES_PANEL_BODY',
      symbolName: props.isDeveloperToolbarInCodePanel
        ? 'menuBarDeveloperModePropertiesInCodePanel'
        : 'menuBarDeveloperModeProperties_v2',
      condition() {
        return props.isDeveloperModeEnabled;
      },
      onClick() {
        props.sendBi(coreBi.events.topbar.top_bar_VIEW_menu_click, {
          category: 'properties_panel',
          status: props.isDeveloperToolBarEnabled ? 0 : 1,
        });

        props.sendBi(coreBi.events.PROPERTIES_PANEL_TOGGLE, {
          site_id: props.siteId,
          state: props.isDeveloperToolBarEnabled ? 'close' : 'open',
          origin: 'tools_menu',
        });

        toolsMenuAPI[TOOLS_MENU_ITEMS.PROPERTIES_TOOLBAR]();
      },
    },
    {
      key: TOOLS_MENU_ITEMS.HIDDEN_ELEMENTS,
      automationId: 'list-item-hidden-elements',
      isCheckbox: true,
      checkboxValue: props.isDeveloperHiddenComponentsEnabled,
      isNested: false,
      leftTitle: 'TOPBAR_VIEW_DEVELOPER_MODE_HIDDEN_ELEMENTS_TITLE',
      rightTitle: 'TOPBAR_VIEW_DEVELOPER_MODE_HIDDEN_ELEMENTS_TITLE',
      description: 'TOPBAR_VIEW_DEVELOPER_MODE_HIDDEN_ELEMENTS_BODY',
      symbolName: 'menuBarDeveloperModeHiddenElements_v2',
      condition() {
        return props.isDeveloperModeEnabled;
      },
      onClick() {
        toolsMenuAPI[TOOLS_MENU_ITEMS.HIDDEN_ELEMENTS]();

        props.sendBi(coreBi.events.topbar.top_bar_VIEW_menu_click, {
          category: 'show_hidden_components',
          status: props.isDeveloperHiddenComponentsEnabled ? 0 : 1,
        });
      },
    },
    {
      key: TOOLS_MENU_ITEMS.ADDONS_SEPARATOR,
      isSeparator: true,
      experiment: 'se_editorAddons',
    },
    {
      key: TOOLS_MENU_ITEMS.ADDONS,
      automationId: 'list-item-addons',
      leftTitle: 'PLATFORM_topbar_tools_addons_label',
      rightTitle: 'PLATFORM_topbar_tools_addons_label',
      description: 'PLATFORM_topbar_tools_addons_label',
      symbolName: null,
      experiment: 'se_editorAddons',
      children: [
        ...(props.addons ?? []).map((addon) => ({
          key: `PLATFORM_ADDON_${addon.appDefinitionId}` as const,
          automationId: `list-item-addon-${addon.appDefinitionId}`,
          translatedLeftTitle: addon.toolsMenuItem.title,
          rightTitle: addon.toolsMenuItem.title,
          description: addon.toolsMenuItem.title,
          symbolName: null,
          onClick: addon.onClick,
        })),
        {
          key: TOOLS_MENU_ITEMS.ADDONS_LIST_SEPARATOR,
          isSeparator: true,
          condition: () => !!props.addons?.length,
        },
        {
          key: TOOLS_MENU_ITEMS.MANAGE_ADDONS,
          automationId: 'list-item-manage-addons',
          leftTitle: 'PLATFORM_topbar_tools_addons_manage_label',
          rightTitle: 'PLATFORM_topbar_tools_addons_manage_label',
          description: 'PLATFORM_topbar_tools_addons_manage_label',
          symbolName: null,
          condition: () => !!props.addons?.length,
          onClick() {
            toolsMenuAPI[TOOLS_MENU_ITEMS.GET_ADDONS]();

            biLogger.report(
              addOnsSelected({
                editorType: 'editor',
                origin: 'tools',
                target_action: 'manage',
                target_type: 'addons',
              }),
            );
          },
        },
        {
          key: TOOLS_MENU_ITEMS.GET_ADDONS,
          automationId: 'list-item-get-addons',
          leftTitle: 'PLATFORM_topbar_tools_addons_get_label',
          rightTitle: 'PLATFORM_topbar_tools_addons_get_label',
          description: 'PLATFORM_topbar_tools_addons_get_label',
          symbolName: null,
          onClick() {
            toolsMenuAPI[TOOLS_MENU_ITEMS.GET_ADDONS]();

            biLogger.report(
              addOnsSelected({
                editorType: 'editor',
                origin: 'tools',
                target_action: 'get',
                target_type: 'addons',
              }),
            );
          },
        },
      ],
    },
  ];
};

import type { EditorAPI } from '#packages/editorAPI';
import { selection } from '#packages/stateManagement';
import { sections } from '#packages/util';
import constants from '#packages/constants';

import type { CompRef } from 'types/documentServices';
import type { ComponentEditorMetaDataDefinition } from '../types';

const footerMetadata: ComponentEditorMetaDataDefinition = {
  pinnable: false,
  arrangeable: false,
  skipInQuickEditPanel: true,
  showLegacyFixedPosition: true,
  showMarginsIndicator: () => sections.isSectionsEnabled(),
  focusable: () => sections.isSectionsEnabled(),
  selectedBeforeDescendants: (editorAPI: EditorAPI, footerRef: CompRef) => {
    if (!sections.isSectionsEnabled()) {
      return false;
    }
    const isRightClick = selection.selectors.isLastClickTypeRight(
      editorAPI.store.getState(),
    );

    const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
    const [selectedComp] = editorAPI.selection.getSelectedComponents();
    const isFooterSelected = editorAPI.utils.isSameRef(selectedComp, footerRef);

    if (isRightClick) {
      return isStageZoomMode || !isFooterSelected;
    }

    return sections.isSectionsEnabled() && !isFooterSelected;
  },
  disabledKnobs: () =>
    sections.isSectionsEnabled()
      ? Object.values(constants.RESIZE_SIDES)
      : undefined,

  gfppVisible: (editorAPI: EditorAPI, footerRef: CompRef) => {
    if (!sections.isSectionsEnabled()) return true;

    if (editorAPI.sections.isFirstTimeParentSectionLikeFocused(footerRef))
      return false;

    const lastClickPos = selection.selectors.getLastSelectionClickPos(
      editorAPI.store.getState(),
    );
    const topComp = sections.getTopComponentByXY(editorAPI, lastClickPos);
    return editorAPI.utils.isSameRef(footerRef, topComp);
  },
};

export default footerMetadata;

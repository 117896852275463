import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import { appMarketHandlers } from '#packages/tpa';
import constants from '#packages/constants';
import { bi, panels } from '#packages/stateManagement';
import { APP_MANAGER_ORIGIN } from '../../constants';
import { getAppVersionToInstall } from '../../services/appMarket/appMarket.utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { RegularAppData } from '../../services/appMarket/appMarket.types';
import type { MyBusinessViewOwnProps } from './myBusiness';
import type { BiEventFields } from 'types/bi';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

export interface MyBusinessViewStateProps {
  metaSiteInstance: string;
}

export interface MyBusinessViewDispatchProps {
  addApp: (app: RegularAppData) => void;
  checkPermissions: (permissions: string[]) => boolean;
  openBusinessManager: (appDefinitionId: string, path: string) => Promise<void>;
  sendBi: (evid: number, biParams: BiEventFields) => void;
  openAppMarket: () => void;
}

export const mapStateToProps: MapStateToProps<
  MyBusinessViewStateProps,
  MyBusinessViewOwnProps
> = ({ dsRead }) => {
  return {
    metaSiteInstance: dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    ).instance,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  MyBusinessViewDispatchProps,
  MyBusinessViewOwnProps
> = (dispatch: FunctionFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  return {
    addApp: (app: RegularAppData) => {
      appMarketHandlers.addApp(editorAPI, {
        appDefId: app.id,
        biData: {
          origin: APP_MANAGER_ORIGIN,
        },
        platformOrigin: {
          initiator: InstallInitiator.Editor,
          type: EditorType.Classic,
          info: {
            type: InstallationOriginType.MY_BUSINESS,
          },
        },
        appVersion: getAppVersionToInstall(app),
      });
    },
    checkPermissions: (permissions: string[]) => {
      const userPermissions = new Set(
        editorAPI.generalInfo.getUserPermissions(),
      );
      return permissions.every((p) => userPermissions.has(p));
    },
    openBusinessManager: (appDefinitionId: string, path: string) => {
      return editorAPI.platform.panels.openDashboardPanel(
        editorAPI.dsRead.platform.getAppDataByAppDefId(appDefinitionId),
        { url: path },
      );
    },
    sendBi: (evid, biParams) => dispatch(bi.actions.event({ evid }, biParams)),
    openAppMarket: () => {
      dispatch(
        panels.actions.openLeftPanel(
          constants.ROOT_COMPS.LEFTBAR.ADD_APPS_PANEL_NAME,
          {
            urlParams: {
              openMarketOrigin: APP_MANAGER_ORIGIN,
            },
          },
        ),
      );
    },
  };
};

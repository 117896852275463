import React from 'react';
import {
  Button,
  Checkbox,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';

import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import { frames } from '#packages/panels';

const { FocusPanelFrame, MessagePanelFrame } = frames;

interface MultilingualComponentChangedPanelFrameProps {
  panelName: string;
  keyboardShortcuts: any;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  dontShowAgain: boolean;
  onDontShowAgainChange: (value: boolean) => void;
}

export const MultilingualComponentChangedPanelFrame: React.FC<
  MultilingualComponentChangedPanelFrameProps
> = (props) => {
  return (
    <FocusPanelFrame
      shouldHideHeader
      shouldNotCloseOnBlur
      keyboardShortcuts={props.keyboardShortcuts}
      panelName={props.panelName}
    >
      <Composites.PopupSmallSize>
        <PanelHeader noHelpBtn onClose={props.onClose} className="dark">
          {translate('Notification_Box_Multilingual_ApplytoAll_Header')}
        </PanelHeader>

        <Composites.PanelContent>
          <Composites.RichTextWithIllustration>
            <Illustration>
              <baseUI.symbol name="componentChanged" />
            </Illustration>

            <Composites.RichText>
              <RichText type="T01">
                {translate('Notification_Box_Multilingual_ApplytoAll_Text')}
              </RichText>
            </Composites.RichText>
          </Composites.RichTextWithIllustration>
        </Composites.PanelContent>

        <Composites.ActionSetHorizontal>
          <Checkbox
            value={props.dontShowAgain}
            label="Notification_Box_Multilingual_ApplytoAll_DontShowAgain"
            labelAfterSymbol={true}
            automationId="confirmMultilingualComponentChangedCheckBox"
            onChange={props.onDontShowAgainChange}
          />
          <Button onClick={props.onCancel} className="secondary">
            <TextLabel value="Notification_Box_Multilingual_ApplytoAll_Apply_Button" />
          </Button>
          <Button
            automationId="undo"
            dataHook="BaseModal-primaryButton"
            onClick={props.onConfirm}
            className="primary"
          >
            <TextLabel value="Notification_Box_Multilingual_ApplytoAll_Undo_Button" />
          </Button>
        </Composites.ActionSetHorizontal>
      </Composites.PopupSmallSize>
    </FocusPanelFrame>
  );
};

export const NewWorkspaceMultilingualComponentChangedPanelFrame: React.FC<
  MultilingualComponentChangedPanelFrameProps
> = (props) => {
  const dontShowAgainCheckbox = (
    <Checkbox
      labelAfterSymbol={true}
      shouldTranslate={false}
      label={translate('Notification_Box_Dont_Show_Again')}
      value={props.dontShowAgain}
      onChange={props.onDontShowAgainChange}
    />
  );

  return (
    <MessagePanelFrame
      panelName={props.panelName}
      title={translate('Notification_Box_Multilingual_ApplytoAll_Header')}
      illustration={<baseUI.symbol name="componentChangedUpdated" />}
      confirmLabel={translate(
        'Notification_Box_Multilingual_ApplytoAll_Undo_Button',
      )}
      onConfirm={props.onConfirm}
      cancelLabel={translate(
        'Notification_Box_Multilingual_ApplytoAll_Apply_Button',
      )}
      onCancel={props.onCancel}
      sideActions={dontShowAgainCheckbox}
    >
      {translate('Notification_Box_Multilingual_ApplytoAll_Text')}
    </MessagePanelFrame>
  );
};

import { sections } from '#packages/util';

import type { LayoutConstraint } from '#packages/layoutUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export const headerLayoutConstraint: LayoutConstraint = {
  shouldConstrain(editorAPI: EditorAPI, compRef: CompRef): boolean {
    return editorAPI.sections.isHeader(compRef);
  },
  applyConstraint(_editorAPI, _compRef, newLayout) {
    newLayout.height = Math.max(
      newLayout.height,
      sections.constants.HEADER_MIN_HEIGHT,
    );
  },
};

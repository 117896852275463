import _ from 'lodash';
import * as gfppData from '#packages/gfppData';
import constants, { type GfppActions } from '#packages/constants';
import * as widgetDesignUtils from '../utils/widgetDesignUtils';
import type { EditorAPI } from '#packages/editorAPI';
import * as gfppTargetUtils from '../utils/gfppTargetUtils';
import * as rEditor from '#packages/rEditor';
import type { AppData, CompRef } from '@wix/document-services-types';
import type {
  GFPPAction,
  GFPPActionsDictionary,
  GFPPActionType,
  GFPPData,
} from '@wix/editor-component-types';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type {
  BehaviorName,
  PlatformizedComponentOptions,
  RawEditorBehavior,
} from '@wix/editor-platform-host-integration-apis';
import { openComponentPanel, openDashboardPanel } from '../api/editor/editor';
import { packagePicker } from '#packages/tpa';

const { ACTIONS, PLATFORM } = constants.ROOT_COMPS.GFPP;
const { POSSIBLE_VALUES: POSSIBLE_ACTIONS } = PLATFORM;

const ALLOWED_OVERRIDE_PANELS: GFPPActionType[] = [
  ACTIONS.ADD,
  ACTIONS.SETTINGS,
  ACTIONS.LAYOUT,
  ACTIONS.DESIGN,
  ACTIONS.UPGRADE,
  ACTIONS.CUSTOM_CONNECT,
];
const ENABLED_ACTIONS_ORDER = {
  desktop: [
    ACTIONS.AI_TOOLS,
    ACTIONS.MANAGE,
    ACTIONS.BACKGROUND,
    ACTIONS.ADD,
    ACTIONS.SETTINGS,
    ACTIONS.LINK,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.APPLY_TO_OTHER_VIEW,
    ACTIONS.CROP,
    ACTIONS.FILTERS,
    ACTIONS.EFFECTS,
    ACTIONS.ANIMATION,
    ACTIONS.STRETCH,
    ACTIONS.STRETCH_FOR_COLUMNS,
    ACTIONS.UPGRADE,
    ACTIONS.HELP,
    ACTIONS.CUSTOM_CONNECT,
    ACTIONS.CONNECT,
    ACTIONS.PIN_MODE,
    ACTIONS.FOCAL_POINT,
  ] as GFPPActionType[],
  mobile: [
    ACTIONS.MANAGE,
    ACTIONS.SCALE_UP,
    ACTIONS.SCALE_DOWN,
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.DESIGN,
    ACTIONS.APPLY_TO_OTHER_VIEW,
    ACTIONS.CROP,
    ACTIONS.FILTERS,
    ACTIONS.EFFECTS,
    ACTIONS.ANIMATION,
    ACTIONS.STRETCH,
    ACTIONS.UPGRADE,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.FOCAL_POINT,
  ] as GFPPActionType[],
};
const ALWAYS_VISIBLE_PANELS = [ACTIONS.HELP, ACTIONS.HIDE];
const MULTI_COMP_STYLE_PANEL =
  'advancedStylePanel.multiComponentAdvancedStylePanel';
const WIDGET_DESIGN_STYLE_PANEL =
  'advancedStylePanel.widgetDesignAdvancedStylePanel';

const GFPP_BEHAVIORS = {
  CUSTOM_ACTION: 'CUSTOM_ACTION',
};
const SETTINGS: BehaviorName = 'settings';
const DASHBOARD: BehaviorName = 'dashboard';

function convertTextSizeToScale(iconButtonsData: AnyFixMe) {
  if (_.has(iconButtonsData, 'textSize')) {
    return {
      ...iconButtonsData,
      scale_up: iconButtonsData.textSize,
      scale_down: iconButtonsData.textSize,
    };
  }

  return _.omit(iconButtonsData, ['scale_up', 'scale_down']);
}

function mapPlatformKeysToActionKeys(
  deviceType: AnyFixMe,
  iconButtonsData: AnyFixMe,
) {
  if (deviceType === 'mobile') {
    return convertTextSizeToScale(iconButtonsData);
  }
  return iconButtonsData;
}

function isActionEnabled(
  editorAPI: AnyFixMe,
  { mobileEnabledActions, enabledActions }: AnyFixMe,
  actionType: AnyFixMe,
) {
  return editorAPI.isMobileEditor()
    ? Boolean(mobileEnabledActions?.includes(actionType))
    : Boolean(enabledActions?.includes(actionType));
}

function resolveOnClickAction(
  currentGfppData: AnyFixMe,
  mainAction: AnyFixMe,
  actionType: AnyFixMe,
) {
  const presetAction = _.get(currentGfppData, ['presetActions', actionType]);
  if (presetAction) {
    return presetAction.onClick;
  }
  return gfppData.utils.resolveOnClickAction(mainAction);
}

function getMainActionsFromApp(
  deviceType: AnyFixMe,
  gfppDataFromApp: AnyFixMe,
) {
  return [
    _.get(gfppDataFromApp, [deviceType, 'mainAction1']),
    _.get(gfppDataFromApp, [deviceType, 'mainAction2']),
  ];
}

type AdapterFunction<T extends keyof RawEditorBehavior> = (
  behavior: PlatformizedComponentOptions['editorBehavior'][T],
) => GFPPAction;

const settingsAdapter: (appData: AppData) => AdapterFunction<typeof SETTINGS> =
  (appData: AppData) => (behavior) => {
    return {
      label: 'gfpp_tooltip_settings',
      onClick: async (editorAPI: EditorAPI) => {
        return await openComponentPanel(
          editorAPI,
          appData,
          '',
          {
            ...behavior?.panelOptions,
            url: behavior.panelOptions?.url,
            componentRef: behavior.componentRef,
            type: SETTINGS,
          },
          null,
        );
      },
    };
  };

const dashboardAdapter: (
  appData: AppData,
) => AdapterFunction<typeof DASHBOARD> = (appData: AppData) => (behavior) => {
  return {
    label: 'gfpp_mainaction_custom_element_manage',
    onClick: async (editorAPI: EditorAPI) =>
      await openDashboardPanel(editorAPI, appData, '', {
        ...behavior?.panelOptions,
        url: behavior.panelOptions?.url,
        componentRef: behavior.componentRef,
      }),
  };
};

const behaviorAdapters = {
  [SETTINGS]: settingsAdapter,
  [DASHBOARD]: dashboardAdapter,
};

function platformizedComponentGFPPResolver(
  editorAPI: EditorAPI,
  componentRefs: CompRef[],
): GFPPData | null {
  const editorPlatformHostIntegrationAPI = editorAPI.host.getAPI(
    EditorPlatformHostIntegrationAPI,
  );
  const { appDefinitionId, editorBehavior: componentBehavior } =
    editorPlatformHostIntegrationAPI.components.getPlatformizedComponentData(
      componentRefs[0],
    ) ?? {};

  if (!componentBehavior) {
    return null;
  }

  const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);

  const mainActions = Object.entries(componentBehavior).reduce(
    (acc, [name, behavior]) => {
      const behaviorName: BehaviorName = name as BehaviorName;
      const adapter = behaviorAdapters[behaviorName](appData);
      if (adapter === undefined) {
        return acc;
      }
      return [...acc, adapter(behavior)];
    },
    [],
  );

  if (_.isEmpty(mainActions)) {
    return null;
  }

  const enabledActions: GFPPActionType[] = [];
  const presetActions: GFPPActionsDictionary = {};

  if (!packagePicker.isFullyUpgraded(editorAPI, appData)) {
    enabledActions.push('upgrade');
    presetActions.upgrade = {
      onClick: () =>
        packagePicker.startUpgradeFlow(editorAPI, appDefinitionId, false, {
          shouldNavigate: false,
        }),
    };
  }

  return {
    mainActions,
    enabledActions,
    presetActions,
    mobileEnabledActions: [],
  };
}

function toUniversalGfppFormat(gfppDefinition: AnyFixMe) {
  if (gfppDefinition === GFPP_BEHAVIORS.CUSTOM_ACTION) {
    throw new Error(
      `GFPP behavior "${GFPP_BEHAVIORS.CUSTOM_ACTION}" can be defined only as an object, string received instead.`,
    );
  }

  if (gfppDefinition?.behavior) {
    // universal contract, nothing to do
    return gfppDefinition;
  }

  if (_.isObject(gfppDefinition)) {
    // deprecated GFPP action
    return {
      ...gfppDefinition,
      behavior: GFPP_BEHAVIORS.CUSTOM_ACTION,
    };
  }

  if (_.isString(gfppDefinition)) {
    // deprecated GFPP primitive, ex: mainAction: 'DEFAULT1'
    return {
      behavior: gfppDefinition,
    };
  }

  return undefined;
}

function getDefaultMainAction({
  universalGfpp,
  componentGfppData,
  index,
}: AnyFixMe) {
  const behavior = universalGfpp?.behavior;
  switch (behavior) {
    case POSSIBLE_ACTIONS.DEFAULT_MAIN_ACTION1:
      return componentGfppData.mainActions?.[0];
    case POSSIBLE_ACTIONS.DEFAULT_MAIN_ACTION2:
      return componentGfppData.mainActions?.[1];
    default:
      return componentGfppData.mainActions?.[index];
  }
}

const getCustomActionPayload = (universalGfpp: AnyFixMe) => {
  return _.omit(universalGfpp, ['behavior', 'target']);
};

function getCustomMainAction({
  universalGfpp,
  mainComponentGfpp,
  isEnabled,
  notifyGfppButtonClicked,
  targetRef,
}: AnyFixMe) {
  const action = {
    onClick(
      editorAPI: AnyFixMe,
      compRef: AnyFixMe,
      origin: AnyFixMe,
      optionalPropsOverrides: AnyFixMe,
    ) {
      const componentRef = targetRef || compRef;
      const { actionType } = universalGfpp;
      if (actionType && isEnabled(editorAPI, mainComponentGfpp, actionType)) {
        const actionOnClick = resolveOnClickAction(
          mainComponentGfpp,
          universalGfpp,
          actionType,
        );
        actionOnClick(editorAPI, componentRef, origin, optionalPropsOverrides);
      }
      if (universalGfpp.actionId) {
        const payload = getCustomActionPayload(universalGfpp);
        if (targetRef) {
          payload.targetRef = targetRef;
        }
        notifyGfppButtonClicked(payload, origin);
      }
    },
    shouldTranslate: false,
    ...universalGfpp,
  };
  return _.omit(action, ['actionId', 'behavior']);
}

function getIconCustomAction({
  universalGfpp,
  notifyGfppButtonClicked,
  buttonName,
  targetRef,
}: AnyFixMe): {
  isSelected(editorAPI: EditorAPI): boolean;
  onClick(): void;
} {
  return {
    isSelected(editorAPI) {
      return editorAPI.panelManager.findPlatformPanelByType(buttonName);
    },
    onClick() {
      const payload = getCustomActionPayload(universalGfpp);
      if (targetRef) {
        payload.targetRef = targetRef;
      }
      notifyGfppButtonClicked(payload);
    },
  };
}

function isCustomAction(universalGfpp: AnyFixMe): boolean {
  return universalGfpp?.behavior === GFPP_BEHAVIORS.CUSTOM_ACTION;
}

function isUpgradeActionName(name: GfppActions): boolean {
  return name === ACTIONS.UPGRADE;
}

function shouldUseDefaultMainAction(universalGfpp: AnyFixMe): boolean {
  return (
    !universalGfpp ||
    universalGfpp?.behavior === POSSIBLE_ACTIONS.DEFAULT ||
    universalGfpp?.behavior === POSSIBLE_ACTIONS.DEFAULT_MAIN_ACTION1 ||
    universalGfpp?.behavior === POSSIBLE_ACTIONS.DEFAULT_MAIN_ACTION2
  );
}

function mergeMainActions(
  mainComponentGfpp: AnyFixMe,
  appMainActions: AnyFixMe,
  deviceType: AnyFixMe,
  isEnabled: AnyFixMe,
  notifyGfppButtonClicked: AnyFixMe,
  controllerRef: AnyFixMe,
  editorAPI: AnyFixMe,
  childGfppData?: AnyFixMe,
) {
  const mainActionsToReturn = appMainActions.reduce(
    (acc: AnyFixMe, rawAction: AnyFixMe, index: AnyFixMe) => {
      const label = rawAction?.label;
      const target = rawAction?.target;
      const targetRef =
        target &&
        gfppTargetUtils.getTargetCompRef(target, controllerRef, editorAPI);
      if (targetRef) {
        const targetGfppData = rEditor.gfppModel.getData(editorAPI, [
          targetRef,
        ]);
        mainComponentGfpp = getMainComponentGfpp(targetGfppData, deviceType);
        rawAction = gfppTargetUtils.getTargetAction({
          editorAPI,
          target,
          targetRef,
          deviceType,
          index,
          controllerRef,
        });
      }

      const universalGfpp = toUniversalGfppFormat(rawAction);
      let action;
      if (isCustomAction(universalGfpp)) {
        action = getCustomMainAction({
          universalGfpp,
          mainComponentGfpp,
          isEnabled,
          notifyGfppButtonClicked,
          targetRef,
        });
      } else if (shouldUseDefaultMainAction(universalGfpp)) {
        action = getDefaultMainAction({
          universalGfpp,
          componentGfppData: childGfppData ?? mainComponentGfpp,
          index,
        });
      } else {
        action = null;
      }

      if (action && label) {
        action.label = label;
      }

      return action ? [...acc, action] : acc;
    },
    [],
  );

  if (deviceType === 'desktop' && _.isEmpty(mainActionsToReturn)) {
    return _.take(mainComponentGfpp.mainActions, 1);
  }
  return mainActionsToReturn;
}

const getMainComponentGfpp = (
  currentGfppData: AnyFixMe,
  deviceType: AnyFixMe,
) => {
  const currentMainActions =
    deviceType === 'desktop'
      ? currentGfppData.mainActions
      : currentGfppData.mobileMainActions;
  return _.defaults({ mainActions: currentMainActions }, currentGfppData);
};

function generateMainActions(
  deviceType: AnyFixMe,
  gfppDataFromApp: AnyFixMe,
  currentGfppData: AnyFixMe,
  notifyGfppButtonClicked: AnyFixMe,
  controllerRef: AnyFixMe,
  editorAPI: AnyFixMe,
) {
  const mainActions = getMainActionsFromApp(deviceType, gfppDataFromApp);
  const mainComponentGfpp = getMainComponentGfpp(currentGfppData, deviceType);
  return mergeMainActions(
    mainComponentGfpp,
    mainActions,
    deviceType,
    isActionEnabled,
    notifyGfppButtonClicked,
    controllerRef,
    editorAPI,
  );
}

const getIconsButtonData = (
  gfppDataFromApp: AnyFixMe,
  deviceType: AnyFixMe,
) => {
  return mapPlatformKeysToActionKeys(
    deviceType,
    gfppDataFromApp?.[deviceType]?.iconButtons,
  );
};

const getWidgetDesign = (gfppData: AnyFixMe, deviceType: AnyFixMe) => {
  return gfppData?.[deviceType]?.widgetDesign;
};

const getWidgetDesignAction = ({
  gfppDataFromApp,
  controllerRef,
  editorAPI,
  deviceType,
  notifyGfppButtonClicked,
}: AnyFixMe) => {
  const getDesignAction = (
    widgetDesign: AnyFixMe,
    compRef = controllerRef,
    selectComponentFn?: AnyFixMe,
  ) => ({
    isSelected(editorAPI: AnyFixMe) {
      const { getPanelStateFn } = gfppData.utils;
      return (
        getPanelStateFn(MULTI_COMP_STYLE_PANEL)(editorAPI) ||
        getPanelStateFn(WIDGET_DESIGN_STYLE_PANEL)(editorAPI)
      );
    },

    onClick(editorAPI: AnyFixMe, _compRef: AnyFixMe, origin: string) {
      if (selectComponentFn) {
        selectComponentFn();
      }

      notifyGfppButtonClicked(
        {
          actionId: widgetDesign?.actionId || 'widgetDesign',
          targetRef: compRef,
        },
        origin,
      );

      return widgetDesignUtils.openWidgetLevelWidgetDesign(
        widgetDesign,
        editorAPI,
        compRef,
      );
    },
  });
  const currentDesignGfpp =
    gfppDataFromApp?.[deviceType]?.iconButtons?.[ACTIONS.DESIGN];

  const target = currentDesignGfpp?.target;
  if (target) {
    const targetRef = gfppTargetUtils.getTargetCompRef(
      target,
      controllerRef,
      editorAPI,
    );

    if (targetRef) {
      const targetWidgetDesign = getWidgetDesign(
        gfppTargetUtils.getGfppDataFromApp(editorAPI, targetRef),
        deviceType,
      );
      if (targetWidgetDesign) {
        const selecttargetFn = () =>
          editorAPI.selection.selectComponentByCompRef(targetRef);
        return getDesignAction(targetWidgetDesign, targetRef, selecttargetFn);
      }
    }
  }
  const widgetDesign = getWidgetDesign(gfppDataFromApp, deviceType);
  if (widgetDesign) {
    return getDesignAction(widgetDesign);
  }
};

const getHelpId = ({
  gfppDataFromApp,
  deviceType,
  controllerRef,
  editorAPI,
}: AnyFixMe) => {
  const helpIdGetter = (gfppData: AnyFixMe) => gfppData?.[deviceType]?.helpId;
  const helpId = helpIdGetter(gfppDataFromApp);
  if (helpId?.target) {
    const targetRef = gfppTargetUtils.getTargetCompRef(
      helpId.target,
      controllerRef,
      editorAPI,
    );
    if (targetRef) {
      const targetGfppData = gfppTargetUtils.getGfppDataFromApp(
        editorAPI,
        targetRef,
      );
      return helpIdGetter(targetGfppData);
    }
  }
  return helpId;
};

const getTargetIconButtonRecursively = ({
  universalGfpp,
  controllerRef,
  editorAPI,
  buttonName,
  deviceType,
}: AnyFixMe): AnyFixMe => {
  const targetRef = gfppTargetUtils.getTargetCompRef(
    universalGfpp?.target,
    controllerRef,
    editorAPI,
  );
  if (targetRef) {
    const targetGfppData = gfppTargetUtils.getGfppDataFromApp(
      editorAPI,
      targetRef,
    );
    const targetIconsButtonData = getIconsButtonData(
      targetGfppData,
      deviceType,
    );
    const data = toUniversalGfppFormat(targetIconsButtonData?.[buttonName]);
    if (data?.target) {
      return getTargetIconButtonRecursively({
        universalGfpp: data,
        controllerRef,
        editorAPI,
        buttonName,
        deviceType,
      });
    }
    return { data, targetRef };
  }
};

function generateIconButtonsOverride(
  deviceType: 'desktop' | 'mobile',
  gfppDataFromApp: AnyFixMe,
  currentGfppData: GFPPData,
  notifyGfppButtonClicked: AnyFixMe,
  controllerRef: CompRef,
  editorAPI: EditorAPI,
  overrideOnlyExistingActions?: boolean,
) {
  const iconButtonsData = getIconsButtonData(gfppDataFromApp, deviceType);
  const overridePresetActions = Object.keys(iconButtonsData || {}).reduce(
    (acc, buttonName) => {
      let universalGfpp = toUniversalGfppFormat(iconButtonsData[buttonName]);
      const targetIconButton = getTargetIconButtonRecursively({
        universalGfpp,
        controllerRef,
        editorAPI,
        buttonName,
        deviceType,
      });
      if (targetIconButton) {
        universalGfpp = targetIconButton.data;
      }

      if (
        ALLOWED_OVERRIDE_PANELS.includes(buttonName as GfppActions) &&
        isCustomAction(universalGfpp)
      ) {
        acc[buttonName] = {
          ...acc[buttonName],
          ...getIconCustomAction({
            universalGfpp,
            notifyGfppButtonClicked,
            buttonName,
            targetRef: targetIconButton?.targetRef,
          }),
        };
      }

      if (iconButtonsData[buttonName]?.permissions) {
        acc[buttonName] = {
          ...acc[buttonName],
          permissions: iconButtonsData[buttonName].permissions,
        };
      }

      return acc;
    },
    {} as AnyFixMe,
  );
  const helpId = getHelpId({
    gfppDataFromApp,
    deviceType,
    controllerRef,
    editorAPI,
  });
  if (helpId) {
    _.set(
      overridePresetActions,
      ['help', deviceType === 'desktop' ? 'helpId' : 'mobileHelpId'],
      helpId,
    );
  }

  const widgetDesignAction = getWidgetDesignAction({
    gfppDataFromApp,
    controllerRef,
    editorAPI,
    deviceType,
    notifyGfppButtonClicked,
  });

  if (widgetDesignAction) {
    _.set(overridePresetActions, ACTIONS.DESIGN, widgetDesignAction);
  }

  if (overrideOnlyExistingActions) {
    return Object.keys(currentGfppData.presetActions || {}).reduce(
      (overriddenActions: GFPPActionsDictionary, actionName: string) => {
        if (
          !gfppDataFromApp?.[deviceType]?.iconButtons?.[actionName]?.behavior ||
          gfppDataFromApp?.[deviceType]?.iconButtons?.[actionName]?.behavior !==
            POSSIBLE_ACTIONS.HIDE
        ) {
          const actionNameType = actionName as GFPPActionType;
          if (actionName in Object.entries(overridePresetActions)) {
            overriddenActions[actionNameType] =
              overridePresetActions[actionName];
          } else {
            overriddenActions[actionNameType] =
              currentGfppData.presetActions[actionNameType];
          }
        }
        return overriddenActions;
      },
      {},
    );
  }

  return { ...currentGfppData.presetActions, ...overridePresetActions };
}

function filterEnabledActions(
  currentEnabledActions: AnyFixMe,
  deviceType: 'desktop' | 'mobile',
  gfppDataFromApp: AnyFixMe,
  shouldShowUpgrade: boolean,
) {
  const iconButtonsData = mapPlatformKeysToActionKeys(
    deviceType,
    _.get(gfppDataFromApp, [deviceType, 'iconButtons']),
  );
  const possibleActions = ENABLED_ACTIONS_ORDER[deviceType];
  if (_.isEmpty(iconButtonsData)) {
    return _.pull(currentEnabledActions, ACTIONS.UPGRADE);
  }

  const enabledActions = currentEnabledActions.filter((name: AnyFixMe) => {
    if (ALWAYS_VISIBLE_PANELS.includes(name)) {
      return true;
    }

    const overrideGfpp = toUniversalGfppFormat(iconButtonsData[name]);

    if (isUpgradeActionName(name)) {
      if (overrideGfpp?.behavior === POSSIBLE_ACTIONS.DEFAULT) {
        return shouldShowUpgrade;
      }
      return overrideGfpp && overrideGfpp.behavior !== POSSIBLE_ACTIONS.HIDE;
    }

    return overrideGfpp?.behavior !== POSSIBLE_ACTIONS.HIDE;
  });

  const filteredLocations = possibleActions.filter((item: AnyFixMe) => {
    const universalGfpp = toUniversalGfppFormat(iconButtonsData[item]);
    const hasOverridePanel =
      ALLOWED_OVERRIDE_PANELS.includes(item) && isCustomAction(universalGfpp);
    const hasEnabledActions = enabledActions.includes(item);

    return hasOverridePanel || hasEnabledActions;
  });

  ALLOWED_OVERRIDE_PANELS.forEach((name) => {
    const universalGfpp = toUniversalGfppFormat(iconButtonsData[name]);

    if (
      isCustomAction(universalGfpp) &&
      !currentEnabledActions.includes(name)
    ) {
      const index = filteredLocations.indexOf(name);
      enabledActions.splice(index, 0, name);
    }
  });
  return enabledActions;
}

function generateEnabledActions(
  deviceType: AnyFixMe,
  gfppDataFromApp: AnyFixMe,
  currentEnabledActions: AnyFixMe,
  shouldShowUpgrade: boolean = true,
) {
  const widgetDesign = getWidgetDesign(gfppDataFromApp, deviceType);
  if (widgetDesign) {
    currentEnabledActions.push(ACTIONS.DESIGN);
  }
  const isThereWidgetPresets = Boolean(
    gfppDataFromApp?.[deviceType]?.widgetPresets,
  );
  if (isThereWidgetPresets) {
    currentEnabledActions.push(ACTIONS.PRESETS);
  }

  return filterEnabledActions(
    currentEnabledActions,
    deviceType,
    gfppDataFromApp,
    shouldShowUpgrade,
  );
}

export {
  filterEnabledActions,
  mergeMainActions,
  getMainActionsFromApp,
  generateMainActions,
  generateIconButtonsOverride,
  generateEnabledActions,
  toUniversalGfppFormat,
  platformizedComponentGFPPResolver,
};

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { CustomScroll } from '@wix/wix-base-ui';
import { hoc } from '@/util';
import { mapDispatchToProps, mapStateToProps } from './permissionsTabMapper';
import CONSTANTS from '../../../utils/constants';
import { PasswordPermission } from './PermissionsTabComponents/passwordPermission';
import { PermissionThumbnails } from './PermissionsTabComponents/permissionsThumbnails';
import { PermissionDescription } from './PermissionsTabComponents/permissionDescription';
import { MembersPermissions } from './PermissionsTabComponents/membersPermissions';
import {
  ensureAdminsRole,
  getAdminGroup,
  getAllMembersGroup,
} from '../../../utils/groupsApi';

import type {
  RolesPanelProps,
  PageGroupData,
  UpdatePageSecurityDataProps,
  OpenDashboardModalProps,
} from './permissionsTabMapper';
import type { PagesData } from 'types/documentServices';
import type {
  PermissionState,
  MemberAccessPermission,
} from '../../../utils/constants';
import type { Group } from '../../../utils/groupsApi';

export interface PermissionsTabOwnProps {
  pageData: PagesData;
}

export interface PermissionsTabStateProps {
  shouldShowProtectionType: boolean;
  initialFocusedProtectionType: PermissionState;
  isPageProtectedWithPassword: boolean;
  isPricingPlansAppInstalled: boolean;
  metaSiteId: string;
  isSiteSaved: boolean;
  permissions: string[];
}

export interface PermissionsTabDispatchProps {
  openHelpCenter: (helpId: string) => void;
  updatePageSecurityData: (props: UpdatePageSecurityDataProps) => void;
  removePagePassword: () => void;
  updatePagePassword: (password: string) => void;
  fetchPageGroupData: (pageId: string) => Promise<PageGroupData>;
  updateGroupsPermissions: (groups: Group[]) => Promise<void>;
  openRolesPanel: (panelProps: RolesPanelProps) => void;
  openDashboardModal: (props: OpenDashboardModalProps) => void;
  openPricingPlansInAppMarket: () => void;
}

interface PermissionsTabProps
  extends PermissionsTabOwnProps,
    PermissionsTabStateProps,
    PermissionsTabDispatchProps {}

const { PERMISSION_STATES, MEMBER_ACCESS_PERMISSIONS } = CONSTANTS;

const PermissionsTab: React.FC<PermissionsTabProps> = ({
  pageData,
  metaSiteId,
  isSiteSaved,
  permissions,
  isPageProtectedWithPassword,
  shouldShowProtectionType,
  initialFocusedProtectionType,
  isPricingPlansAppInstalled,
  updatePageSecurityData,
  openRolesPanel,
  removePagePassword,
  updatePagePassword,
  openHelpCenter,
  fetchPageGroupData,
  updateGroupsPermissions,
  openDashboardModal,
  openPricingPlansInAppMarket,
}) => {
  const [groupsForPage, setGroupsForPage] = useState([getAdminGroup()]);
  const [availableGroups, setAvailableGroups] = useState([getAdminGroup()]);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [plainPassword, setPlainPassword] = useState('');
  const [focusedProtectionType, setFocusedProtectionType] = useState(
    initialFocusedProtectionType,
  );
  const [membersAccessPermissions, setMembersAccessPermissions] =
    useState<MemberAccessPermission>(MEMBER_ACCESS_PERMISSIONS.ALL);

  useEffect(() => {
    refreshRoles(pageData.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData.id]);

  useEffect(() => {
    setHasLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (hasLoaded && focusedProtectionType === PERMISSION_STATES.PASSWORD) {
      savePageSecurityData(PERMISSION_STATES.PASSWORD);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plainPassword]);

  const handleProtectionChange = (newProtectionType: PermissionState) => {
    if (focusedProtectionType !== newProtectionType) {
      setFocusedProtectionType(newProtectionType);
      savePageSecurityData(newProtectionType);
    }
  };

  const savePageSecurityData = (protectionType: PermissionState) => {
    switch (protectionType) {
      case PERMISSION_STATES.NONE:
        updatePageSecurityData({
          privacyType: PERMISSION_STATES.NONE,
          pageSecurity: { requireLogin: false },
        });
        removePagePassword();
        break;

      case PERMISSION_STATES.PASSWORD:
        const isPasswordValid = !_.isEmpty(plainPassword);

        if (isPasswordValid) {
          updatePagePassword(plainPassword);
        }

        if (isPasswordValid || isPageProtectedWithPassword) {
          updatePageSecurityData({
            privacyType: PERMISSION_STATES.PASSWORD,
            pageSecurity: { requireLogin: false },
          });
        }
        break;

      case PERMISSION_STATES.MEMBERS:
        updatePageSecurityData({
          privacyType: PERMISSION_STATES.MEMBERS,
          pageSecurity: { requireLogin: true },
        });
        removePagePassword();
        break;
    }
  };

  const getMembersAccessPermissions = (
    pageId: string,
    groupsForPage: Required<Group>[],
    adminRestrictedPage: string[],
  ) => {
    // groups for page is empty for all members, this is why we also check admins
    // if it is restricted by amy group, admins is included in groupsForPage
    const isAdminOnlyPage = adminRestrictedPage.some((id) => id === pageId);
    const isMembersPage = !_.isEmpty(groupsForPage);
    const isSpecific = isAdminOnlyPage || isMembersPage;

    return isSpecific
      ? MEMBER_ACCESS_PERMISSIONS.SPECIFIC
      : MEMBER_ACCESS_PERMISSIONS.ALL;
  };

  const refreshRoles = (pageId: string) => {
    if (!isSiteSaved) {
      const shouldDisplaySpecificAccessOptions =
        membersAccessPermissions === MEMBER_ACCESS_PERMISSIONS.SPECIFIC;
      const defaultGroupsForPage = shouldDisplaySpecificAccessOptions
        ? [getAdminGroup()]
        : [];

      setGroupsForPage(defaultGroupsForPage);
      return;
    }

    return fetchPageGroupData(pageId).then((data) => {
      const membersAccessPermissions = getMembersAccessPermissions(
        pageId,
        data.groupsForPage,
        data.adminRestrictedPage,
      );

      setGroupsForPage(ensureAdminsRole(data.groupsForPage));
      setAvailableGroups(ensureAdminsRole(data.availableGroups));
      setMembersAccessPermissions(membersAccessPermissions);
    });
  };

  const handleMembersAccessChange = async (
    membersAccessType: MemberAccessPermission,
  ) => {
    const shouldShowAll = membersAccessType === MEMBER_ACCESS_PERMISSIONS.ALL;

    if (!isSiteSaved) {
      const groupsForPage = shouldShowAll ? [] : [getAdminGroup()];
      setMembersAccessPermissions(membersAccessType);
      setGroupsForPage(groupsForPage);
      return;
    }

    setMembersAccessPermissions(membersAccessType);
    const groupsToUpdate: Group[] = shouldShowAll
      ? groupsForPage.map((group) => ({
          ...group,
          checked: false,
        }))
      : [getAdminGroup()];

    const allMembers: Group[] = [getAllMembersGroup(shouldShowAll)];

    await updateGroupsPermissionsWithRefresh(allMembers.concat(groupsToUpdate));
  };

  const updateGroupsPermissionsWithRefresh = async (
    groupsToUpdate: Group[],
  ) => {
    await updateGroupsPermissions(groupsToUpdate);
    await refreshRoles(pageData.id);
  };

  const openDashboardWithRefresh = (path: string) =>
    openDashboardModal({
      path,
      onClose: () => refreshRoles(pageData.id),
    });

  const renderFocusedTab = () => {
    switch (focusedProtectionType) {
      case PERMISSION_STATES.NONE:
        return null;
      case PERMISSION_STATES.PASSWORD:
        return (
          <PasswordPermission
            isPageProtectedWithPassword={isPageProtectedWithPassword}
            plainPassword={plainPassword}
            onPasswordUpdate={setPlainPassword}
          />
        );
      case PERMISSION_STATES.MEMBERS:
        return (
          <MembersPermissions
            metaSiteId={metaSiteId}
            isSiteSaved={isSiteSaved}
            permissions={permissions}
            groupsForPage={groupsForPage}
            availableGroups={availableGroups}
            membersAccessPermissions={membersAccessPermissions}
            isPricingPlansAppInstalled={isPricingPlansAppInstalled}
            handleMembersAccessChange={handleMembersAccessChange}
            updateGroupsPermissions={updateGroupsPermissionsWithRefresh}
            openRolesPanel={openRolesPanel}
            openDashboardModal={openDashboardWithRefresh}
            openPricingPlansInAppMarket={openPricingPlansInAppMarket}
          />
        );
    }
  };

  return (
    <CustomScroll>
      {shouldShowProtectionType && (
        <PermissionThumbnails
          focusedProtectionType={focusedProtectionType}
          onProtectionChange={handleProtectionChange}
        />
      )}
      <PermissionDescription
        focusedProtectionType={focusedProtectionType}
        openHelpCenter={openHelpCenter}
      />
      {renderFocusedTab()}
    </CustomScroll>
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PermissionsTab);

import React from 'react';

import keyboardShortcutsActionMap from '#packages/keyboardShortcuts';
import {
  isCustomMenusEnabled,
  isMainMenuFlowEnabled,
  fedopsLogger,
  hoc,
} from '#packages/util';
import { translate } from '#packages/i18n';
import * as menu from '#packages/menu';
import * as stateManagement from '#packages/stateManagement';

import ItemWithShortcut from '../menuItems/rcmItemWithShortcut';

import type { EditorAPI } from '#packages/editorAPI';

const {
  api: { createMenuApi },
} = menu;

function PagesMenuItemContextMenuTemplate(props: AnyFixMe) {
  return (
    <ul>
      {props.items.map((item: AnyFixMe) => (
        <ItemWithShortcut
          key={item.key}
          automationId={item.automationId}
          item={item.item}
          label={item.label}
          shortcut={item.shortcut}
          onClick={item.onClick}
        />
      ))}
    </ul>
  );
}

const shortcuts = keyboardShortcutsActionMap.editor;

function reportPasteStarted(isCrossSite: AnyFixMe) {
  const FEDOPS_INTERACTION = isCrossSite
    ? fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
    : fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
  fedopsLogger.interactionStarted(FEDOPS_INTERACTION);
}
function reportPasteEnded(isCrossSite: AnyFixMe) {
  const FEDOPS_INTERACTION = isCrossSite
    ? fedopsLogger.INTERACTIONS.PASTE_PAGE_CROSS_SITE
    : fedopsLogger.INTERACTIONS.PASTE_PAGE_SAME_SITE;
  fedopsLogger.interactionEnded(FEDOPS_INTERACTION);
}

function onCopyPage(editorAPI: AnyFixMe, pageId: AnyFixMe) {
  fedopsLogger.interactionStarted(fedopsLogger.INTERACTIONS.COPY_PAGE);

  editorAPI.copyPaste.copyPage(pageId, { origin: 'rcm' });
  editorAPI.closeRightClickMenu();

  fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.COPY_PAGE);
}

function onPastePage(editorAPI: AnyFixMe) {
  reportPasteStarted(editorAPI.clipboard.isCrossSite());

  editorAPI.copyPaste
    .pastePage({
      menuItemToPasteAfter:
        editorAPI.mainMenu.panelContext.getSelectedMenuItem(),
      origin: 'rcm_pages_menu_item',
    })
    .then(() => {
      editorAPI.closeRightClickMenu();
      reportPasteEnded(editorAPI.clipboard.isCrossSite());
    });
}

const createFolder = async (editorAPI: EditorAPI): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);
  const menuItemToPasteAfter =
    editorAPI.mainMenu.panelContext.getSelectedMenuItem();

  const dropdownId = await menuAPI.addDropdown(
    menu.constants.PAGES_MENU_ID,
    menuItemToPasteAfter.id,
    translate('PagesPanel_Actions_Folder_New_Default_Text'),
  );

  editorAPI.store.dispatch(
    stateManagement.pages.actions.setEditItemId(dropdownId),
  );

  // hack. Pages panel calculates the number of opens and only then applies a new state.
  editorAPI.openPagesPanel({});

  editorAPI.closeRightClickMenu();
};

function onPastePageAndApplyTheme(editorAPI: AnyFixMe) {
  reportPasteStarted(editorAPI.clipboard.isCrossSite());

  editorAPI.copyPaste
    .pastePage({
      menuItemToPasteAfter:
        editorAPI.mainMenu.panelContext.getSelectedMenuItem(),
      shouldApplyTargetTheme: true,
      origin: 'rcm_pages_menu_item',
    })
    .then(() => {
      editorAPI.closeRightClickMenu();
      reportPasteEnded(editorAPI.clipboard.isCrossSite());
    });
}

function mapStateToProps({ editorAPI }: AnyFixMe) {
  const menuItem = editorAPI.mainMenu.panelContext.getSelectedMenuItem();
  const items = [];

  const isPageCopyAllowed =
    menuItem?.type.isPage &&
    editorAPI.copyPaste.canCopyPage(menuItem.pageData.id);
  const isPagePasteAllowed = editorAPI.copyPaste.hasPageToPaste();
  const isPagePasteCrossSiteAllowed =
    isPagePasteAllowed && editorAPI.clipboard.isCrossSite();

  // NOTE: we should not show if they are not applicable for item in any case
  if (isPageCopyAllowed) {
    items.push({
      key: 'copy',
      automationId: 'rcm-copy-item',
      label: 'SiteMenu_RightClick_Copy_Page_Label',
      item: { enabled: true },
      shortcut: shortcuts.COPY.label,
      onClick: () => onCopyPage(editorAPI, menuItem.pageData.id),
    });
  }

  items.push({
    key: 'paste',
    automationId: 'rcm-paste-item',
    label: 'SiteMenu_RightClick_Paste_Page_Label',
    item: { enabled: isPagePasteAllowed },
    shortcut: shortcuts.PASTE.label,
    onClick: () => onPastePage(editorAPI),
  });

  // NOTE: we should not show cross-site options if they are not applicable
  if (isPagePasteCrossSiteAllowed) {
    items.push({
      key: 'pasteAndApplyTheme',
      automationId: 'rcm-paste-and-apply-theme-item',
      label: 'SiteMenu_RightClick_PasteandApplyTheme_Page_Label',
      item: { enabled: true },
      shortcut: shortcuts.PASTE_AND_APPLY_THEME.label,
      onClick: () => onPastePageAndApplyTheme(editorAPI),
    });
  }

  if (isCustomMenusEnabled() && !isMainMenuFlowEnabled()) {
    items.push({
      key: 'createFolder',
      automationId: 'rcm-create-folder-item',
      label: 'SiteMenu_RightClick_Create_Folder_Label',
      item: { enabled: true },
      shortcut: keyboardShortcutsActionMap.pagesPanel.NEW_FOLDER.label,
      onClick: () => createFolder(editorAPI),
    });
  }

  return {
    items,
  };
}

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(PagesMenuItemContextMenuTemplate);

// @ts-nocheck
import React from 'react';

import * as util from '#packages/util';
import constants from '#packages/constants';

import { FocusPanelFrame } from '../frames';

import type { EditorAPI } from '#packages/editorAPI';

interface FeedbackPanelProps {
  siteId: string;
  metaSiteId: string;
}

interface FeedbackPanelDispatchProps {
  openHelpCenter: (helpId: string) => void;
  closePanel: () => void;
  openSettings: () => void;
}

function getRelativeUrl() {
  const { baseDomain } = util.serviceTopology;
  return `//${baseDomain}/feedback-ng`;
}

function getBaseUrl() {
  return window.location.protocol + getRelativeUrl();
}

function getHelpId(helpType) {
  switch (helpType) {
    case 'ShareHelpPro':
      return '4ca0f60b-3c8e-48c2-9259-8d70c8564101';
    case 'ReadHelp':
      return '38fac604-38ba-4d83-97eb-3fbc8f702762';
    case 'ReadHelpPro':
      return '4e1993e1-ece8-4478-b553-139926f66bfc';
    default:
      return 'cb9a32fa-b217-4e31-b2e6-905dd8e2116c';
  }
}

export class FeedbackPanelPure extends React.Component<FeedbackPanelProps> {
  static displayName = 'feedbackPanel';
  getIframeSrc() {
    return `${getBaseUrl()}/share?siteId=${this.props.siteId}&metaSiteId=${
      this.props.metaSiteId
    }&origin=editor`;
  }
  componentDidMount() {
    window.addEventListener('message', this.iframeMessageHandler);
  }
  componentWillUnmount() {
    if (this.closeTimeout) {
      window.clearTimeout(this.closeTimeout);
    }
    window.removeEventListener('message', this.iframeMessageHandler);
  }
  iframeMessageHandler = (messageEvent) => {
    const relativeOrigin = messageEvent.origin.substring(
      messageEvent.origin.indexOf('//'),
    );

    if (!relativeOrigin.includes(util.serviceTopology.baseDomain)) {
      return;
    }
    const messageData = messageEvent.data && JSON.parse(messageEvent.data);
    switch (messageData.action) {
      case 'feedbackhelp':
        this.props.openHelpCenter(getHelpId(messageData?.helpType));
        break;
      case 'feedbackclose':
        this.props.close();
        break;
      case 'opensitesettings':
        this.props.openSettings();
        break;
    }
  };
  panelWillClose = () => {
    if (this.closeTimeout) {
      return;
    }
    this.closeTimeout = window.setTimeout(
      function () {
        this.props.close();
      }.bind(this),
      constants.FEEDBACK.CLOSE_PANEL_TIMEOUT,
    );
    if (this.refs?.iframe?.contentWindow) {
      this.refs.iframe.contentWindow.postMessage('panelClosed', getBaseUrl());
    }

    return false;
  };
  render = () => {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        panelName={this.props.panelName}
        shouldHideHeader={true}
        shouldHideHelp={true}
        onClose={this.panelWillClose}
        isBareMode={true}
      >
        <iframe
          src={this.getIframeSrc()}
          width="500"
          height="743"
          ref="iframe"
          className="feedback-iframe"
        />
      </FocusPanelFrame>
    );
  };
}

const getEditorAPI = (dispatch, getState, { editorAPI }): EditorAPI =>
  editorAPI;

const mapStateToProps = ({ editorAPI }): FeedbackPanelProps => {
  return {
    siteId: editorAPI.dsRead.generalInfo.getSiteId(),
    metaSiteId: editorAPI.dsRead.generalInfo.getMetaSiteId(),
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps,
): FeedbackPanelDispatchProps => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openHelpCenter: (helpId) =>
      editorAPI.panelManager.openHelpCenter(helpId, null, {
        component: ownProps.id,
        origin: constants.BI.HELP.ORIGIN.PANEL,
        panel_name: 'panels.focusPanels.feedbackPanel',
      }),
    close: () => editorAPI.panelManager.closePanelByName(ownProps.panelName),
    openSettings: () =>
      editorAPI.account.openSettings({
        tab: 'authorization',
        referrer: 'feedback',
      }),
  };
};

export const FeedbackPanel = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(FeedbackPanelPure);

import _ from 'lodash';
import constants from '#packages/constants';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import type { CompRef } from 'types/documentServices';
import {
  isDraggingSel,
  isResizingSel,
  isRotatingSel,
  isDuringMouseActionSel,
} from '../../selectors/mouseSelectors';
import {
  getComponentUIColor,
  getCompRestrictions,
  getIsSiteSegmentComponent,
} from './common';
import { createSelector } from '../../../selectors/selector';
import {
  editorAPISel,
  editorStateSel,
  dsReadSel,
  editorAPIMouseSel,
} from '../../selectors/rootSelectors';
import {
  selectedCompsSel,
  isMultiselectSel,
} from '../../selectors/selectedCompsSelectors';
import { isSmallSel } from './layout/selectors';
import type { EditorAPI } from '#packages/editorAPI';
import { components } from '#packages/stateManagement';
import { WorkspaceApiKey } from '#packages/apis';

const { getComponentInteractionIfExists } =
  stateManagement.interactions.selectors;

const componentsSelectors = stateManagement.components.selectors;
const {
  isSelectedCompConnectedToDataBindingController,
  getFocusedContainer,
  getSelectedCompsRefs,
} = stateManagement.selection.selectors;

const OFFSET_FROM_GRID_LINES = 2;

const COMPS_WITHOUT_DISPLAY_NAME_LABEL = [
  'wysiwyg.viewer.components.HeaderContainer',
  'wysiwyg.viewer.components.FooterContainer',
  'wysiwyg.viewer.components.ClassicSection',
  'mobile.core.components.Page',
];

function getControllingParentIfExists(
  editorAPI: AnyFixMe,
  singleComp: AnyFixMe,
  compRestrictions: AnyFixMe,
) {
  const notControlledByParent = _.negate(
    editorAPI.components.is.controlledByParent,
  );
  if (compRestrictions.controlledByParent) {
    return editorAPI.components.findAncestor(
      singleComp,
      notControlledByParent,
      { includeScopeOwner: true },
    );
  }
}

const singleCompSel = createSelector([selectedCompsSel], _.head);

export const compDisplayNameSel = createSelector(
  [
    editorAPISel,
    editorStateSel,
    singleCompSel,
    isDuringMouseActionSel,
    isMultiselectSel,
  ],
  (editorAPI, editorState, singleComp, isDuringMouseAction, isMultiselect) =>
    isDuringMouseAction || isMultiselect
      ? ''
      : editorAPI.components.getDisplayName(singleComp),
);

export const compRestrictionsSel = createSelector(
  [editorAPISel],
  getCompRestrictions,
);

export const controllingParentSel = createSelector(
  [editorAPISel, singleCompSel, compRestrictionsSel],
  getControllingParentIfExists,
);

export const parentRestrictionsSel = createSelector(
  [controllingParentSel, editorAPISel],
  (controllingParent, editorAPI) =>
    controllingParent ? editorAPI.getCompRestrictions(controllingParent) : null,
);

export const draggableSel = createSelector(
  [parentRestrictionsSel, compRestrictionsSel, isMultiselectSel],
  (parentRestrictions, compRestrictions, isMultiselect) =>
    parentRestrictions
      ? (parentRestrictions.horizontallyMovable ||
          parentRestrictions.verticallyMovable) &&
        !isMultiselect
      : (compRestrictions.horizontallyMovable ||
          compRestrictions.verticallyMovable) &&
        !isMultiselect,
);

const focusedContainerSel = createSelector(
  [editorStateSel],
  getFocusedContainer,
);

export const shouldShowCompDisplayNameSel = createSelector(
  [
    compDisplayNameSel,
    isDuringMouseActionSel,
    isMultiselectSel,
    focusedContainerSel,
    singleCompSel,
    selectedCompsSel,
    editorAPISel,
  ],
  (
    compDisplayName,
    isDuringMouseAction,
    isMultiselect,
    focusedContainer,
    singleComp,
    comps,
    editorAPI,
  ) => {
    const compType = editorAPI.components.getType(comps);

    const isCompWithoutDisplayNameLabel =
      COMPS_WITHOUT_DISPLAY_NAME_LABEL.includes(compType);

    const isParentAndChildSameComponent = editorAPI.utils.isSameRef(
      focusedContainer,
      singleComp,
    );

    // For ref components inside ref components we want to show an EditBox
    const isReferredComponent = components.selectors.isWidgetInWidget(
      singleComp,
      editorAPI.dsRead,
    );

    return (
      !!compDisplayName &&
      !isDuringMouseAction &&
      !isMultiselect &&
      !isCompWithoutDisplayNameLabel &&
      (!isParentAndChildSameComponent || isReferredComponent)
    );
  },
);

export const mobileOnlyNonNativeComponentSel = createSelector(
  [editorAPIMouseSel, selectedCompsSel],
  (editorAPI: EditorAPI, selectedComponents: CompRef[]) => {
    return (
      selectedComponents.length === 1 &&
      editorAPI.isMobileEditor() &&
      editorAPI.mobile.mobileOnlyComponents.isMobileOnlyNonNativeComponent(
        selectedComponents[0],
      )
    );
  },
);

export const shouldShowPreloaderSel = createSelector(
  [isMultiselectSel, editorStateSel, singleCompSel],
  (isMultiselect, editorState, singleComp) =>
    !isMultiselect &&
    stateManagement.preloadings.selectors.isComponentPreloading(
      editorState,
      singleComp,
    ),
);

export const editBoxClassesSel = createSelector(
  [
    isDraggingSel,
    isResizingSel,
    isRotatingSel,
    isMultiselectSel,
    draggableSel,
    isSmallSel,
  ],
  (isDragging, isResizing, isRotating, isMultiselect, draggable, isSmall) =>
    util.cx({
      'edit-box': true,
      small: isSmall,
      // 'dimmed-handles': ownProps.dimmedHandles,
      draggable,
      dragging: isDragging,
      resizing: isResizing,
      rotating: isRotating,
      'multi-select': isMultiselect,
    }),
);

export const editBoxComponentsSel = createSelector(
  [editorAPISel],
  (editorAPI) => {
    const workspaceAPI = editorAPI.host.getAPI(WorkspaceApiKey);
    return workspaceAPI.getEditBoxComponents();
  },
);

export const editBoxOtherPropsSel = createSelector(
  //TODO split this into selectors
  [editorAPISel, editorStateSel, dsReadSel, singleCompSel],
  (editorAPI, editorState, dsRead, singleComp) => {
    const { getSessionUserPreferences } =
      stateManagement.userPreferences.selectors;
    const { openHelpCenter } = editorAPI.panelManager;
    const comps = getSelectedCompsRefs(editorState);

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const compTypes = _.map(comps, function (comp) {
      return editorAPI.components.getType(comp);
    });
    const compsData = editorAPI.components.data.get(_.head(comps));

    const isSiteSegmentComponent = getIsSiteSegmentComponent(editorAPI);

    const mainCompRef = util.controlsUtils.getFirstControllableComponent(
      editorAPI,
      singleComp,
    );

    const isConnectedToDataBinding =
      isSelectedCompConnectedToDataBindingController(editorState, dsRead);

    const compType = componentsSelectors.getCompTypeSuffix(comps, dsRead);

    const isSectionLikeBehaviour =
      util.sections.isSectionsEnabled() &&
      editorAPI.sections.isSectionLike(mainCompRef);

    const hasLabelOffset =
      !editorAPI.isMobileEditor() &&
      mainCompRef &&
      editorAPI.components.getType(mainCompRef) !==
        constants.COMP_TYPES.CONTAINER;

    return {
      isSectionLikeBehaviour,
      isConnectedToDataBinding,
      getSessionUserPreferences,
      comps,
      compInteraction: getComponentInteractionIfExists(editorAPI, comps),
      compType,
      compTypes,
      isSiteSegmentComponent,
      componentUIColor: getComponentUIColor(editorAPI, comps),
      isHorizontallyStretchedToScreen:
        editorAPI.components.layout.isHorizontallyStretchedToScreen(comps),
      compsWithoutSelectedAncestors:
        editorAPI.components.getComponentsWhichDontHaveAncestorsInTheArray(
          comps,
        ),
      compsData,
      isMobile: dsRead.viewMode.get() === dsRead.viewMode.VIEW_MODES.MOBILE,
      isMobileEditor: editorAPI.isMobileEditor(),
      reAddedHiddenElement:
        editorAPI.mobile.hiddenComponents.getReAddedHiddenElement(),
      dimCompLabels:
        editorAPI.panelManager.getPanelsOfType(constants.PANEL_TYPES.COMP)
          .length > 0,

      isDeveloperModeEnabled: editorAPI.developerMode.isEnabled(),
      compNickname:
        util.sections.isSectionsEnabled() &&
        editorAPI.sections.isSection(mainCompRef)
          ? null
          : componentsSelectors.getNickname(mainCompRef, dsRead),
      compSecondaryNickname: componentsSelectors.getSecondaryNickname(
        mainCompRef,
        editorAPI,
      ),
      compInnerNickname: componentsSelectors.getInnerNickname(
        mainCompRef,
        editorAPI,
      ),
      siteSegmentsLabelsOffset: editorAPI.isMobileEditor()
        ? 0
        : editorAPI.siteSegments.getPagesContainerAbsLayout().x +
          OFFSET_FROM_GRID_LINES,
      compLinkValue:
        compsData && util.link.getLinkValueAsString(editorAPI, compsData.link),
      hasLabelOffset,
      openHelpCenter,
      getState: editorAPI.store.getState,
    };
  },
);

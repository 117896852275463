import React from 'react';
import { hoc, cx, sections } from '#packages/util';
import LeftBarMenuButton from '../leftBarMenuButton/leftBarMenuButton';
import HiddenMobileItemsTooltip from '../leftBarMenuButton/hiddenMobileItemsTooltip/hiddenMobileItemsTooltip';
import styles from './leftBarMenu.scss';
import {
  mapStateToProps,
  mapDispatchToProps,
  type OwnProps,
  type StateProps,
  type DispatchProps,
} from './leftBarMenuMapper';
import experiment from 'experiment';
import * as coreBi from '#packages/coreBi';
import type { ClickInitiator } from '../leftBarMenuButton/types';

const OPEN_TOOLTIP_DELAY_IN_MS = 2000;
const MOBILE_EDITOR_HIDDEN_ITEMS_PANEL_NAME = 'mobileEditor.hiddenItemsPanel';
const isHiddenMobileElementsRedesignWithTooltipEnabled =
  experiment.getValue('se_leftPanelHiddenMobileElementsRedesign') === 'B';

interface LeftBarMenuProps extends OwnProps, StateProps, DispatchProps {}
interface LeftBarMenuState {
  shouldDisplayHiddenMobileItemsTooltip: boolean;
}

class LeftBarMenu extends React.Component<LeftBarMenuProps, LeftBarMenuState> {
  hiddenMobileElementsTimeout: NodeJS.Timeout;

  constructor(props: any) {
    super(props);
    this.state = {
      shouldDisplayHiddenMobileItemsTooltip: false,
    };
  }

  shouldDisplayMobileHiddenTooltip() {
    const hasHiddenMobileElements =
      this.getNotificationCount(MOBILE_EDITOR_HIDDEN_ITEMS_PANEL_NAME) > 0;
    if (
      hasHiddenMobileElements &&
      !this.props.didDismissMobileHiddenItemsTooltip &&
      !this.hiddenMobileElementsTimeout
    ) {
      this.hiddenMobileElementsTimeout = setTimeout(() => {
        this.setState({ shouldDisplayHiddenMobileItemsTooltip: true });
        this.props.sendBI(
          coreBi.events.boxSlideShow.SLIDESHOW_HIDDEN_ELEMENTS_TOOLTIP_APPEAR,
          {
            message: 'mobile_hidden_items_FTE_tooltip_title',
          },
        );
      }, OPEN_TOOLTIP_DELAY_IN_MS);
    }
  }

  dismissMobileHiddenElementsTooltip = () => {
    this.props.dismissMobileHiddenElementsTooltip();
    this.setState({ shouldDisplayHiddenMobileItemsTooltip: false });
  };

  componentDidUpdate(prevProps: LeftBarMenuProps) {
    if (this.props.superAppsDesktopCount !== prevProps.superAppsDesktopCount) {
      this.props.sendBIButtonsCount();
    }

    if (
      isHiddenMobileElementsRedesignWithTooltipEnabled &&
      this.props.isMobileHiddenItemsTooltipEnabled
    ) {
      this.shouldDisplayMobileHiddenTooltip();
    }
  }

  componentWillUnmount() {
    if (this.state.shouldDisplayHiddenMobileItemsTooltip) {
      this.dismissMobileHiddenElementsTooltip();
    }
  }

  isSelected(panelName: string) {
    const { selectedPanelName } = this.props;

    return selectedPanelName && selectedPanelName === panelName;
  }

  onClick = (panelName: string, panelProps: any, initiator: ClickInitiator) => {
    const isSelected = this.isSelected(panelName);
    if (
      this.state.shouldDisplayHiddenMobileItemsTooltip &&
      panelName === MOBILE_EDITOR_HIDDEN_ITEMS_PANEL_NAME
    ) {
      this.dismissMobileHiddenElementsTooltip();
    }
    if (isSelected) {
      this.props.closePanel();
    } else {
      panelProps?.openPanelInteraction?.start();
      panelProps?.firstPresetSectionLoadInteraction?.start();

      if (initiator === 'tooltip') {
        panelProps = { ...panelProps, origin: 'tooltip' };
      }
      this.props.openPanel(panelName, panelProps);
      this.sendPanelBI(panelName, panelProps, initiator);
    }
  };

  sendPanelBI = (
    panelName: string,
    panelProps: any,
    initiator: ClickInitiator,
  ): void => {
    if (initiator === 'button') {
      this.props.sendBIButtonClick(panelName);
    }
    if (panelProps?.openPanelBiEvent) {
      this.props.sendBI(
        panelProps?.openPanelBiEvent,
        panelProps?.openPanelBiParams,
      );
    }
  };

  onHover = (element_name: string) => {
    this.props.sendBIButtonHover(element_name);
  };

  getNotificationCount = (panelName: AnyFixMe) => {
    return this.props.leftBarNotifications[panelName] ?? 0;
  };

  handleListBackgroundClick: React.MouseEventHandler = (e) => {
    if (e.target !== e.currentTarget) return;
    this.props.deselectComponents();
  };

  renderLeftBarMenuButton(item: any) {
    return (
      <LeftBarMenuButton
        label={item.label}
        panelName={item.panelName}
        panelProps={item.panelProps}
        symbolName={item.symbolName}
        automationId={`leftBarButton-${item.panelName}`}
        isSelected={this.isSelected(item.panelName)}
        isHighlighted={this.props.isHighlighted}
        isEnabled={this.props.isEnabled}
        className={item.className}
        tooltips={item.tooltips}
        notificationCount={this.getNotificationCount(item.panelName)}
        onClick={this.onClick}
        onHover={this.onHover}
      />
    );
  }
  render() {
    return (
      <div className={styles.wrapper}>
        <ul className={styles.list} onClick={this.handleListBackgroundClick}>
          {this.props.leftBarMenuItems.map((item) => (
            <li
              key={item.panelName}
              className={cx({
                [styles.item]: true,
                [styles.primary]:
                  !sections.isSectionsEnabled() &&
                  item.className === 'add-panel',
              })}
            >
              {item.panelName === MOBILE_EDITOR_HIDDEN_ITEMS_PANEL_NAME ? (
                <HiddenMobileItemsTooltip
                  shouldDisplayTooltip={
                    this.state.shouldDisplayHiddenMobileItemsTooltip
                  }
                  onActionClick={() =>
                    this.onClick(item.panelName, item.panelProps, 'tooltip')
                  }
                  onDismiss={this.dismissMobileHiddenElementsTooltip}
                >
                  {this.renderLeftBarMenuButton(item)}
                </HiddenMobileItemsTooltip>
              ) : (
                <>{this.renderLeftBarMenuButton(item)}</>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LeftBarMenu);

import React from 'react';
import _ from 'lodash';
import * as platform from '#packages/platform';
import * as baseUI from '#packages/baseUI';
import { hoc } from '#packages/util';
import { translate } from '#packages/i18n';
import type { ThunkAction } from 'types/redux';
import type { AppData } from 'types/documentServices';
import style from './PlatformPanelApplicationFrame.scss';
import { Preloader, RichText } from '@wix/wix-base-ui';
import type { IConsumerType } from '@wix/editor-platform-host-integration';

import { createTokenV2 } from '@wix/ambassador-identity-infra-v1-token/http';
import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import {
  PlatformFrame,
  PlatformFrameContextProvider,
} from '@wix/editor-platform-host-integration/ui';
import type {
  CreateTokenResponse,
  CreateTokenRequestV2,
} from '@wix/ambassador-identity-infra-v1-token/types';
import { HttpClient } from '@wix/http-client';
import { ErrorReporter } from '@wix/editor-error-reporter';
import constants from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';
import type { MapStateToProps } from 'react-redux';
import experiment from 'experiment';

// Created based on the PropTypes
interface PlatformPanelApplicationOwnProps {
  token: string;
  url: string;
  platformConsumerType?: IConsumerType;
  appData?: AppData;
  scrolling?: string;
  initialData?: any;
  panelClass?: string;
  width?: number | string;
  height?: number | string;
  maxHeight?: number;
  showPreloader?: boolean;
  preloaderReachedTimeout?: boolean;
  useEditorContext?: boolean;
  appDefinitionId?: string;
}

interface PlatformPanelApplicationFrameDispatchProps {
  registerToViewerInfoChanged: (
    token: any,
    handleViewerInfoChanged: any,
  ) => Function;
  getOneTimeToken: (
    panelUrl: string,
    appDefinitionId: string,
  ) => Promise<string>;
  getWorkerManager: any;
}

interface PlatformPanelApplicationFrameStateProps {
  useOttToken: boolean;
  isPlatform20Enabled: boolean;
}

type PlatformPanelApplicationFrameProps = PlatformPanelApplicationOwnProps &
  PlatformPanelApplicationFrameDispatchProps &
  PlatformPanelApplicationFrameStateProps;

interface IState {
  iframeUrl?: string;
  tokenError?: boolean;
}

class PlatformPanelApplicationFrame extends React.Component<
  PlatformPanelApplicationFrameProps,
  IState
> {
  static displayName = 'PlatformPanelApplicationFrame';
  static defaultProps = {
    width: '100%',
    height: '100%',
  };

  unregister: AnyFixMe;
  iframe: AnyFixMe;
  ottInterval?: ReturnType<typeof setInterval>;

  constructor(props: PlatformPanelApplicationFrameProps) {
    super(props);
    this.state = {
      iframeUrl: undefined,
      tokenError: false,
    };
  }

  componentDidMount() {
    this.unregister = this.props.registerToViewerInfoChanged(
      this.props.token,
      this.handleViewerInfoChanged,
    );
    this.buildOttIframeUrl();
    this.subscribeOttRefresh();
  }

  componentWillUnmount() {
    if (_.isFunction(this.unregister)) {
      this.unregister();
    }

    if (this.ottInterval !== undefined) {
      clearInterval(this.ottInterval);
    }
  }

  handleViewerInfoChanged = (event: AnyFixMe) => {
    if (_.has(this, ['iframe', 'sendMessage'])) {
      this.iframe.sendMessage({
        intent: 'PLATFORM_ON_EVENT',
        event,
      });
    }
  };

  async buildOttIframeUrl() {
    const { useOttToken, getOneTimeToken, url, appDefinitionId } = this.props;

    if (!useOttToken || !appDefinitionId) {
      this.setState({ iframeUrl: url });
      return;
    }

    try {
      const ottTokenValue = await getOneTimeToken(url, appDefinitionId);

      const oneTimeTokenUrl = new URL(url);
      oneTimeTokenUrl.searchParams.set('authorizationCode', ottTokenValue);

      this.setState({ iframeUrl: oneTimeTokenUrl.toString() });
    } catch (error) {
      this.setState({ tokenError: true });
      ErrorReporter.captureException(error, {
        tags: {
          buildOttIframeUrl: true,
        },
      });
      console.error(error);
    }
  }

  subscribeOttRefresh() {
    const { useOttToken, getOneTimeToken, url, appDefinitionId } = this.props;

    if (!useOttToken || !appDefinitionId) {
      return;
    }

    const HOUR = 3600_000;
    this.ottInterval = setInterval(async () => {
      try {
        const ottTokenValue = await getOneTimeToken(url, appDefinitionId);

        // TODO: it seems there is no current in the iframe, and the error is suppressed  by "?"
        this.iframe.current?.contentWindow?.postMessage(
          {
            type: 'authorizationCode',
            authorizationCode: ottTokenValue,
          },
          new URL(url).origin,
        );
      } catch (error) {
        ErrorReporter.captureException(error, {
          tags: {
            refreshOttIframeToken: true,
          },
        });
        console.error(error);
      }
    }, 3.5 * HOUR);
  }

  getIframeRef = (iframe: AnyFixMe) => {
    this.iframe = iframe;
  };

  createPostMessageHandler = () => {
    return platform.platformPostMessageService.createPostMessageListener(
      {
        token: this.props.token,
        initialData: this.props.initialData,
        origin: this.props.panelClass,
      },
      this.props.token,
    );
  };

  render() {
    const {
      height,
      width,
      maxHeight,
      token,
      appData,
      scrolling,
      showPreloader,
      preloaderReachedTimeout,
      panelClass,
      platformConsumerType,
      isPlatform20Enabled,
    } = this.props;

    return (
      <>
        {this.state.tokenError || preloaderReachedTimeout ? (
          <div
            data-hook={`preloader-timeout-error-container-${token}`}
            className={style.preloaderTimeoutContainer}
            style={{ height, maxHeight }}
          >
            <RichText type="T01" dataHook={`preloader-timeout-text-${token}`}>
              {translate(
                'Platform-blocks-editor.Panel_Preloader_Error_Message',
              )}{' '}
              <a
                target="_blank"
                rel="noreferrer noopener"
                href={translate(
                  'Platform-blocks-editor.Panel_Preloader_Error_Message_Customer_Care_URL',
                )}
              >
                {translate(
                  'Platform-blocks-editor.Panel_Preloader_Error_Message_Customer_Care',
                )}
              </a>
            </RichText>
          </div>
        ) : (
          <>
            {showPreloader ? (
              <div
                data-hook={`preloader-${token}`}
                className={style.preloaderContainer}
                style={{ height, maxHeight }}
              >
                <Preloader className="small" />
              </div>
            ) : undefined}

            <PlatformFrameContextProvider
              getWorkerManager={this.props.getWorkerManager}
            >
              <PlatformFrame appDefinitionId={this.props.appDefinitionId}>
                {(setRef) => {
                  return (
                    <baseUI.iframe
                      onPostMessage={this.createPostMessageHandler()}
                      ref={(ref: any) => {
                        if (isPlatform20Enabled) {
                          /**
                           * TODO: deprecated refs.
                           * need to find another way to get iframe DOM element
                           * or write compatible decorator over baseui.iframe
                           */
                          setRef(ref?.refs?.iframe ?? null);
                        }

                        this.getIframeRef(ref);
                      }}
                      name={token}
                      appData={appData}
                      src={this.state.iframeUrl}
                      scrolling={scrolling || 'no'}
                      width={width}
                      height={height}
                      maxHeight={maxHeight}
                      frameBorder="0"
                      allowFullScreen="allowfullscreen"
                      className={`${panelClass} ${
                        showPreloader ? style.iframeInvisible : ''
                      }`}
                      data-platform-consumer-type={platformConsumerType || ''}
                    />
                  );
                }}
              </PlatformFrame>
            </PlatformFrameContextProvider>
          </>
        )}
      </>
    );
  }
}

const getHttpClient = (editorAPI: EditorAPI) => {
  return new HttpClient({
    getAppToken: () => {
      return editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      )?.instance;
    },
  });
};

const registerToViewerInfoChanged =
  (token: AnyFixMe, handleViewerInfoChanged: AnyFixMe) =>
  (
    _dispatch: AnyFixMe,
    _getState: AnyFixMe,
    { editorAPI }: AnyFixMe,
  ): ThunkAction =>
    editorAPI.dsActions.platform.registerToViewerInfoChanged(
      token,
      handleViewerInfoChanged,
    );

const getOneTimeToken =
  (panelUrl: string, appDefinitionId: string): ThunkAction =>
  async (_dispatch: AnyFixMe, _getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    try {
      const createTokenRequest: CreateTokenRequestV2 = {
        tokenMetadata: {
          appId: appDefinitionId,
          originDomain: new URL(panelUrl).origin,
        },
      };
      const tokenRequest = createTokenV2(createTokenRequest);

      const httpClient = getHttpClient(editorAPI);
      const response =
        await httpClient.request<CreateTokenResponse>(tokenRequest);
      const token = response.data.tokenResult?.token;
      if (!token) {
        throw new Error('Token is not defined');
      }

      const tokenValue = token.value;
      if (!tokenValue) {
        throw new Error('OTT token value is not defined');
      }

      return tokenValue;
    } catch (error) {
      ErrorReporter.captureException(error, {
        tags: {
          getOneTimeToken: true,
        },
      });
      console.error(error);

      throw new Error('One time token could not be generated');
    }
  };

const getWorkerManager =
  (): ThunkAction =>
  async (_dispatch: AnyFixMe, _getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    const isPlatform20Enabled =
      experiment.isOpen('specs.responsive-editor.enablePlatform2_0') ||
      experiment.isOpen('se_enablePlatform2_0');

    if (!isPlatform20Enabled) {
      return null;
    }

    const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);

    return platformAPI.getWorkerManager();
  };

const mapDispatchToProps = {
  registerToViewerInfoChanged,
  getOneTimeToken,
  getWorkerManager,
};

const mapStateToProps: MapStateToProps<
  PlatformPanelApplicationFrameStateProps,
  PlatformPanelApplicationOwnProps
> = ({}, props) => {
  const isPlatformPanelOneTimeTokenOpened = experiment.isOpen(
    'specs.responsive-editor.platformPanelOneTimeToken',
  );

  const isPlatform20Enabled =
    experiment.isOpen('specs.responsive-editor.enablePlatform2_0') ||
    experiment.isOpen('se_enablePlatform2_0');

  return {
    useOttToken: isPlatformPanelOneTimeTokenOpened && props.useEditorContext,
    isPlatform20Enabled,
  };
};
const connected = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(PlatformPanelApplicationFrame);

connected.pure = PlatformPanelApplicationFrame;

export default connected;

// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { translate } from '#packages/i18n';
import { RichText, TextLabel } from '@wix/wix-base-ui';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  title?: string;
  imageWidth?: number;
  ImageHeight?: number;
  siteLink?: string;
  pageTitle?: string;
  pageDesc?: string;
  onOpen?: FunctionFixMe;
  onClose?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'socialSharePreviewTooltip';

  static propTypes = {
    title: PropTypes.string,
    imageWidth: PropTypes.number,
    ImageHeight: PropTypes.number,
    siteLink: PropTypes.string,
    pageTitle: PropTypes.string,
    pageDesc: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  componentDidMount() {
    if (_.isFunction(this.props.onOpen)) {
      this.props.onOpen();
    }
  }

  componentWillUnmount() {
    if (_.isFunction(this.props.onClose)) {
      this.props.onClose();
    }
  }

  getImageStyle = () => {
    const imageUrl = this.props.image;
    return {
      backgroundImage: `url(${imageUrl})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      width: this.props.imageWidth,
      height: this.props.ImageHeight,
    };
  };

  render() {
    return (
      <div className="social-share-preview-tooltip">
        <TextLabel type="T03" value={this.props.title} />
        <div className="preview-container">
          <div style={this.getImageStyle()} className="image-container" />
          <RichText type="T04" shouldTranslate={false} className="link">
            {this.props.pageURL}
          </RichText>

          <RichText type="T03" shouldTranslate={false} className="title">
            {this.props.pageTitle}
          </RichText>

          <RichText type="T02" shouldTranslate={false} className="description">
            {this.props.pageDesc}
          </RichText>
        </div>

        <RichText className="seo-image-desc">
          {translate('socialShare_image_preview_text')}
          <a onClick={this.props.openHelp}>
            {translate('socialShare_image_preview_note_link')}
          </a>
        </RichText>
      </div>
    );
  }
}

import React from 'react';
import {
  Composites,
  Divider,
  RadioButtons,
  TextInput,
  TextLabel,
  Text,
} from '@wix/wix-base-ui';

import { hoc, validate } from '#packages/util';

import { mapDispatchToProps } from './ExternalLink.mapper';
import { RelAttribute } from '../RelAttribute/RelAttribute';
import { LINK_TYPES } from '../../constants';

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

export interface TExternalLink {
  id?: string;
  type: string;
  url?: string;
  target?: string;
  rel?: string[];
}

type ExternalLinkComponentProps = {
  link: TExternalLink;
  hideLinkTarget: boolean;
  origin: string;
  onValidationUpdate(isValid: boolean): void;
  onLinkChange(link: TExternalLink): void;
} & ReturnType<typeof mapDispatchToProps>;

interface ExternalLinkComponentState {
  prevLink: TExternalLink;
  link: TExternalLink;
  isURLValid: boolean;
}

const isValidUrl = (url: string) => {
  return !!url && validate.url(url);
};

export class ExternalLinkComponent extends React.Component<
  ExternalLinkComponentProps,
  ExternalLinkComponentState
> {
  constructor(props: AnyFixMe) {
    super(props);
    const link = props.link || props.createDefaultData('ExternalLink');
    this.state = {
      prevLink: link,
      link: {
        ...link,
        rel: link.rel,
      },
      isURLValid: !!link.url,
    };
  }

  static getDerivedStateFromProps(
    props: ExternalLinkComponentProps,
    state: ExternalLinkComponentState,
  ) {
    const nextLink =
      state.prevLink?.id !== props.link?.id ? props.link : state.link;

    return {
      prevLink: props.link,
      link: nextLink,
    };
  }

  componentDidMount() {
    this.props.onLinkChange(this.state.link);
    this.props.onValidationUpdate(this.state.isURLValid);
  }

  componentDidUpdate(_prevProps: AnyFixMe, prevState: AnyFixMe) {
    if (prevState.link !== this.state.link) {
      this.props.onLinkChange(this.state.link);
    }
    if (this.state.isURLValid !== prevState.isURLValid) {
      this.props.onValidationUpdate(this.state.isURLValid);
    }
  }

  private setURL = (url: AnyFixMe) => {
    const { link } = this.state;
    this.setState({
      link: {
        ...link,
        url,
      },
    });
  };

  private setTarget = (target: AnyFixMe) => {
    const { link } = this.state;
    this.setState({
      link: {
        ...link,
        target,
      },
    });
  };

  private handleURLValidation = (isValid: AnyFixMe) => {
    this.setState({
      isURLValid: isValid,
    });
  };

  private onRelChange = (newRelValue: string[]) => {
    this.setState({
      link: {
        ...this.state.link,
        rel: newRelValue,
      },
    });
  };

  render() {
    const { hideLinkTarget, origin } = this.props;
    const { link } = this.state;

    return (
      <>
        <Composites.TextInputLabeled>
          <TextLabel
            value="LINK_PANEL_WEB_ADDRESS_INPUT_LABEL"
            automationId="link-panel-web-address-label"
          />
          <TextInput
            key="webAddress"
            validator={isValidUrl}
            invalidMessage="Validation_V2_URL"
            placeholder="LINK_PANEL_WEB_ADDRESS_DEFAUL"
            validateInitialValue={false}
            onChange={this.setURL}
            value={this.state.link.url}
            onValidationStatus={this.handleURLValidation}
            focus={true}
          />
        </Composites.TextInputLabeled>

        {!hideLinkTarget && (
          <>
            <Divider key="externalTargetDivider" />
            <Composites.RadioButtonsLabeled key="externalTargetRadio">
              <TextLabel value="LINK_PANEL_WEB_ADDRESS_OPTIONS_LABEL" />
              <RadioButtons
                value={this.state.link?.target}
                onChange={this.setTarget}
                options={[
                  {
                    value: '_blank',
                    label: 'LINK_PANEL_WEB_ADDRESS_OPTION1',
                  },
                  {
                    value: '_self',
                    label: 'LINK_PANEL_WEB_ADDRESS_OPTION2',
                  },
                ]}
                className="open-target-radio-buttons"
              />
            </Composites.RadioButtonsLabeled>
            {this.state.link.target === '_self' && (
              <div className="link-page-description">
                <Text size="small" key="linkDisclaimer" enableEllipsis={false}>
                  LINK_PANEL_PAGE_DESCRIPTION
                </Text>
              </div>
            )}
          </>
        )}

        {
          <>
            <Divider key="externalTargetDivider" />
            <RelAttribute
              origin={origin}
              rel={link.rel}
              onChange={this.onRelChange}
              category={LINK_TYPES.ExternalLink}
            />
          </>
        }
      </>
    );
  }
}

export const ExternalLink = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(ExternalLinkComponent);

ExternalLink.pure = ExternalLinkComponent;

import experiment from 'experiment';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  SiteGlobalDataApiKey,
} from '#packages/apis';
import { sections as sectionsUtils } from '#packages/util';
import { events } from '#packages/coreBi';
import * as stateManagement from '#packages/stateManagement';

import { AiApiKey } from './publicApiKey';
import { AiPublicApi } from './api';
import { INJECTION_BLACK_LIST } from './injectionBlackList';
import type { PanelDescriptor } from '#packages/stateManagement';

export class AiScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  siteGlobalDataAPI = this.useDependency(SiteGlobalDataApiKey);
}

const AI_WELCOME_PANEL = 'ai.panels.welcomePanel';

export const AiEntryPoint = createEntryPoint({
  name: 'AiApi',
  Scope: AiScope,
  publicApi({ contributeApi }) {
    contributeApi(AiApiKey, AiPublicApi);
  },
  async initialize({ editorCoreAPI, editorAPI, siteGlobalDataAPI }: AiScope) {
    await editorCoreAPI.hooks._dsIsReady.promise;

    // check new site before silent installation
    const isNewSite =
      editorAPI.generalInfo.isFirstSave() || editorAPI.generalInfo.isDraft();

    await editorCoreAPI.hooks.initReady.promise;
    await editorCoreAPI.hooks.stageIsReadyAndVisible.promise;
    await siteGlobalDataAPI.getIsSiteGlobalDataReadyPromise();
    await editorCoreAPI.hooks.appsSilentInstallationReady.promise;

    const isInternalDesignerScanFlow = experiment.isOpen(
      'se_addDesignerSection',
    );

    const isBlackListed = INJECTION_BLACK_LIST.has(
      editorAPI.generalInfo.getSiteOriginalTemplateId(),
    );
    const isSchoolMode = stateManagement.schoolMode.selectors.isEnabled(
      editorAPI.store.getState(),
    );
    if (
      ((experiment.isOpen('se_aiInjectionIntoTemplate') ||
        experiment.isOpen('se_aiInjectionIntoTemplateAllPages')) &&
        isNewSite &&
        !isSchoolMode &&
        !isBlackListed &&
        !isInternalDesignerScanFlow &&
        sectionsUtils.isSectionsEnabled()) ||
      experiment.isOpen('se_forceAiInjectionIntoTemplate')
    ) {
      editorAPI.bi.event(events.aiTextGenerator.panelOpenIntent, {
        panelType: 'injection to site',
        is_intend: true,
      });

      editorAPI.panelManager.addPanelFilter(
        'BLOCK_OPEN_PANELS_IF_AI_WELCOME_OPENED',
        (panelDescriptor: PanelDescriptor) => {
          return (
            editorAPI.panelManager.isPanelOpened(AI_WELCOME_PANEL) &&
            (panelDescriptor.frameType === 'leftPanelFrame' ||
              panelDescriptor.name === 'quickEdit')
          );
        },
      );
      editorAPI.panelManager.openPanel(AI_WELCOME_PANEL);
    } else {
      editorAPI.bi.event(events.aiTextGenerator.panelOpenIntent, {
        panelType: 'injection to site',
        is_intend: false,
      });
    }
  },
});

// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import coreUtilsLib from 'coreUtilsLib';

const { blogAppPartNames } = coreUtilsLib;

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_archive',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: 'blog/sections/archiveSection.png',
    imageHover: null,
    items: [
      {
        id: 'Blog_Archive_1',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.AppPartSkin',
          layout: {
            width: 90,
            height: 66,
            x: 16,
            y: 6,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixapps.integration.components.AppPart',
          data: {
            type: 'AppPart',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            appInnerID: '12',
            appPartName: blogAppPartNames.ARCHIVE,
            viewName: 'ArchiveList',
            appLogicCustomizations: [
              {
                type: 'AppPartCustomization',
                forType: 'Option',
                view: 'ArchiveList',
                key: 'comp.hidden',
                value: 'false',
                fieldId: 'itemSeparator',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Option',
                view: 'ArchiveList',
                key: 'showItemsCount',
                value: 'false',
                fieldId: 'vars',
                format: '',
              },
            ],
            appLogicParams: {},
          },
          props: {
            type: 'AppPartProperties',
            metaData: { schemaVersion: '1.0' },
            direction: 'ltr',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'blog_56ab6fa4-95ac-4391-9337-6702b8a77011_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              groups: {},
              properties: { rd: '7px' },
              propertiesSource: { rd: 'value' },
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.AppPartSkin',
          },
          id: 'i74vu2kv_0',
        },
        preset: { rect: { width: 162, height: 78, x: 0, y: 0 }, label: '' },
      },
      {
        id: 'Blog_Archive_2',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.AppPartSkin',
          layout: {
            width: 208,
            height: 42,
            x: 184,
            y: 17,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixapps.integration.components.AppPart',
          data: {
            type: 'AppPart',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            appInnerID: '12',
            appPartName: blogAppPartNames.ARCHIVE,
            viewName: 'ArchiveDropDown',
            appLogicCustomizations: [
              {
                type: 'AppPartCustomization',
                forType: 'Option',
                view: 'ArchiveList',
                key: 'comp.hidden',
                value: 'false',
                fieldId: 'itemSeparator',
                format: '*',
              },
              {
                type: 'AppPartCustomization',
                forType: 'Option',
                view: 'ArchiveList',
                key: 'showItemsCount',
                value: 'false',
                fieldId: 'vars',
                format: '',
              },
            ],
            appLogicParams: {},
          },
          props: {
            type: 'AppPartProperties',
            metaData: { schemaVersion: '1.0' },
            direction: 'ltr',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'blog_56ab6fa4-95ac-4391-9337-6702b8a77011_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: {
              groups: {},
              properties: { rd: '7px' },
              propertiesSource: { rd: 'value' },
            },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.AppPartSkin',
          },
          id: 'i74vu2kw_1',
        },
        preset: { rect: { width: 162, height: 59, x: 162, y: 0 }, label: '' },
      },
    ],
    compTypes: ['wixapps.integration.components.AppPart'],
  },
  help: { hide: false, text: 'add_section_info_text_archive' },
};

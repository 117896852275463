import createReactClass from 'create-react-class';
import experiment from 'experiment';
import * as core from '#packages/core';
import React from 'react';
import { MessagePanelFrame, OldMessagePanelFrame } from '../../frames';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import { Composites, Illustration, RichText } from '@wix/wix-base-ui';

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'deleteLastPageMessage',
  mixins: [core.mixins.editorAPIMixin],
  render() {
    const modalBody = (
      <RichText type="T01">
        <p>{translate('Pages_Settings_Delete_Last_Page_Message')}</p>
        <p>{translate('Pages_Settings_Delete_Last_Page_Message2')}</p>
        <p>
          {translate('Pages_Settings_Delete_Last_Page_Message3')}
          <a
            key="page-settings-tab-privacy-member-url"
            href="https://www.wix.com/support/html5/"
            target="_blank"
          >
            <baseUI.button
              label="Pages_Settings_Delete_Last_Page_Message_Link"
              className="btn-text"
            />
          </a>
          .
        </p>
      </RichText>
    );

    return experiment.isOpen('se_removeOldMessagePanelFrame') ? (
      <MessagePanelFrame
        panelName={this.props.panelName}
        confirmLabel={translate(
          'Pages_Settings_Delete_Last_Page_Message_Confirmation',
        )}
        title={translate('Pages_Settings_Delete_Last_Page_Message_Title')}
        className="delete-page-confirmation"
        illustration={<baseUI.symbol name="plaster" />}
      >
        {modalBody}
      </MessagePanelFrame>
    ) : (
      <OldMessagePanelFrame
        panelName={this.props.panelName}
        confirmLabel="Pages_Settings_Delete_Last_Page_Message_Confirmation"
        panelTitle={translate('Pages_Settings_Delete_Last_Page_Message_Title')}
        className="delete-page-confirmation"
      >
        <Composites.RichTextWithIllustration>
          <Illustration>
            <baseUI.symbol name="plaster" />
          </Illustration>
          <Composites.RichText>{modalBody}</Composites.RichText>
        </Composites.RichTextWithIllustration>
      </OldMessagePanelFrame>
    );
  },
});

import _ from 'lodash';
import { link, fedopsLogger, isAdvancedMenuOpen } from '#packages/util';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import { createPagesApi } from '@/pages-wip';
import {
  type ITreeItem,
  type IAction,
  type IDivider,
  menuItemTree,
} from '#packages/baseUI';

import { createMenuApi } from '../../API/menuAPI';
import { showEditMenuNotification } from './notifications/notifications';
import {
  isDropdown,
  isMenuItemRenamable,
  cleanId,
  isMembersLoginMenuId,
  isMembersMenuId,
  isSupportsSubsubItems,
} from '../../utils/utils';
import { TRANSLATIONS_MAP } from './utils/translations';
import { AUTOMATION_IDS } from './utils/automationIds';

import type { EditorAPI } from '#packages/editorAPI';
import type { MenuItem } from 'types/documentServices';
import type { IMenuConfig } from './utils/config.types';
import { isSlotContainer } from './utils/slotUtils';
import { EditorRestrictionsApiKey } from '#packages/apis';
import { isSubSubItem, isOutOfSubSubCase } from '../../bi/menuBiLogger';

const { getNestingLevel, getPrevSiblingId, getTreeHeight } = menuItemTree;

const {
  linkTypeValidators: { isPageLink, isDynamicPageLink, isSectionLink },
} = link;

const TRANSLATIONS = TRANSLATIONS_MAP.ITEM.ACTIONS;

const divider: IDivider = {
  type: 'divider',
};

type WithActionDecorator = (
  options: IGetActionsParams,
) => (currentActions: IAction[]) => IAction[];

interface IGetActionsParams {
  editorAPI: EditorAPI;
  config: IMenuConfig;
  menuId: string;
  item: ITreeItem<MenuItem>;
  onChangeLink: () => void;
  onRename: () => void;
  onLinkItem: () => void;
  onUnlinkItem: () => void;
  onSeoSettingsClick: () => void;
  onMoveToDropdownClick: (isSubSubCase: boolean) => void;
  onMoveOutOfDropdownClick: (isSubSubCase: boolean) => void;
  onRemoveClick: () => void;
}

type IGetActions = (params: IGetActionsParams) => IAction[];

const initialActions: AnyFixMe = [];

export const getMenuItemActions: IGetActions = (options) => {
  const isInMultiLingualFlow =
    options.editorAPI.language.multilingual.isEnabled() &&
    !stateManagement.multilingual.services.utils.currentIsOriginal(
      options.editorAPI,
    );

  const isLoginMenu = isMembersLoginMenuId(options.menuId);

  if (isLoginMenu) {
    // Allow rename on multilingual
    if (isInMultiLingualFlow) {
      return _.flowRight(
        withRenameAction(options),
        withDivider(options),
        withRemoveAction(options),
      )(initialActions);
    }

    return withRemoveAction(options)(initialActions);
  }

  if (isInMultiLingualFlow) {
    return _.flowRight(
      withRenameAction(options),
      withSeoSettingsAction(options),
      withDivider(options),
      withRemoveAction(options),
    )(initialActions);
  }

  const decoratorsWithOptions = [
    withRemoveDividersOnEdges,
    withChangeLinkAction,
    withRenameAction,
    withDropdownAction,
    withLinkUnlinkAction,
    withSeoSettingsAction,
    withDivider,
    withRemoveAction,
  ].map((decorator) => decorator(options));

  return _.flowRight(...decoratorsWithOptions)(initialActions);
};

const isDivider = (action?: IAction): action is IDivider =>
  action?.type === 'divider';

const removeFirstActionIfDivider = (actions: IAction[]): IAction[] => {
  const [firstAction] = actions;

  return isDivider(firstAction) ? actions.slice(1) : actions;
};

const removeLastActionIfDivider = (actions: IAction[]): IAction[] => {
  const lastAction: IAction | undefined = actions[actions.length - 1];

  return isDivider(lastAction) ? actions.slice(0, -1) : actions;
};

const withRemoveDividersOnEdges: WithActionDecorator = () => (currentActions) =>
  _.flow(removeFirstActionIfDivider, removeLastActionIfDivider)(currentActions);

const withSeoSettingsAction: WithActionDecorator = (options) => (actions) => {
  const {
    editorAPI,
    item: { item },
    onSeoSettingsClick,
  } = options;
  const { link } = item;
  const pagesAPI = createPagesApi(editorAPI);

  const getPageId = (): string | null => {
    if (!link) {
      return null;
    }

    if (isPageLink(link)) {
      return cleanId(link.pageId);
    }

    if (isDynamicPageLink(link)) {
      const router = editorAPI.routers.get.byId(link.routerId);
      const isMembersLink = router?.group === MEMBERS_GROUP_TITLE;

      if (!isMembersLink) {
        return null;
      }

      const membersPagesData =
        pagesAPI.getRouterGroupPagesData(MEMBERS_GROUP_TITLE);

      const dynamicPageData = membersPagesData.find(
        (item) =>
          link.routerId === item.routerId &&
          link.innerRoute === item.innerRoute,
      );

      return dynamicPageData?.pageId;
    }

    return null;
  };

  const pageId = getPageId();

  const shouldDisplaySeoSettingsAction = editorAPI.host
    .getAPI(EditorRestrictionsApiKey)
    .allowed('menu-panel_menu-seo-settings.visible');

  if (!pageId || !shouldDisplaySeoSettingsAction) {
    return actions;
  }

  const leavePanelsOpen = true;

  const action = {
    title: translate(TRANSLATIONS.seoSettingsAction),
    symbolName: 'urlPageType',
    onClick: () => {
      onSeoSettingsClick();

      editorAPI.pages.navigateTo(pageId, () => {
        editorAPI.openPagesPanel(
          {
            initialSettingsTabType: constants.PAGE_SETTINGS_TABS.PAGE_INFO,
            origin: 'menuManager',
          },
          leavePanelsOpen,
        );
      });
    },
  };

  return [action, ...actions];
};

const withRenameAction: WithActionDecorator = (options) => (actions) => {
  const {
    onRename,
    item: { item },
    editorAPI,
    menuId,
  } = options;

  if (!isMenuItemRenamable(editorAPI, menuId, item.id)) {
    return actions;
  }

  let renameKey = TRANSLATIONS.renameLink;

  if (isDropdown(item)) {
    renameKey = TRANSLATIONS.renameDropdown;
  } else if (isPageLink(item.link)) {
    renameKey = TRANSLATIONS.renamePageInMultiLingual;
  } else if (isSectionLink(item.link)) {
    renameKey = TRANSLATIONS.renameSection;
  }

  const title = translate(renameKey);

  const renameItem: IAction = {
    title,
    type: 'action',
    symbolName: 'renameAction',
    onClick: onRename,
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.RENAME,
  };

  return [renameItem, ...actions];
};

const withRemoveAction: WithActionDecorator = (options) => (actions) => {
  const { menuId, editorAPI, config, item, onRemoveClick } = options;
  const menuAPI = createMenuApi(editorAPI, config);
  const { history } = editorAPI;

  let titleKey = TRANSLATIONS.deleteLink;

  if (isPageLink(item.item.link)) {
    titleKey = TRANSLATIONS.deletePage;
  } else if (isDropdown(item.item)) {
    titleKey = TRANSLATIONS.deleteDropdown;
  }

  const removeItem = {
    title: translate(titleKey),
    type: 'action',
    symbolName: 'hideAction',
    onClick: () => {
      onRemoveClick();

      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.DELETE_MENU_ITEM,
      );
      editorAPI.store.dispatch(showEditMenuNotification(menuId));
      menuAPI.removeItem(menuId, item.id);
      history.add('Remove item from menu');
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.MANAGE_MENU.DELETE_MENU_ITEM,
      );
    },
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.REMOVE,
  };

  return [removeItem, ...actions];
};

const withLinkUnlinkAction: WithActionDecorator = (options) => (actions) => {
  const { menuId, editorAPI, item, onLinkItem, onUnlinkItem } = options;
  if (isSlotContainer(item.item)) {
    return actions;
  }
  const isChild = !!item.parentId;

  const menuAPI = createMenuApi(editorAPI);

  const link = {
    title: translate(TRANSLATIONS.link),
    type: 'action',
    symbolName: 'linkAction',
    onClick: onLinkItem,
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.LINK,
  };

  const unLink = {
    title: translate(TRANSLATIONS.unlink),
    type: 'action',
    symbolName: 'unlinkAction',
    onClick: () => {
      editorAPI.store.dispatch(showEditMenuNotification(menuId));
      menuAPI.unlinkItem(menuId, item.id);
      editorAPI.dsActions.waitForChangesApplied(onUnlinkItem);
      editorAPI.history.add('unlink item from menu');
    },
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.UNLINK,
  };

  if (!item.item.link) {
    return [link, ...actions];
  }

  if (item.item.link && !isChild && item.item.items.length > 0) {
    return [unLink, ...actions];
  }

  return actions;
};

const withDivider: WithActionDecorator = () => (actions) => [
  divider,
  ...actions,
];

const withDropdownAction: WithActionDecorator = (options) => {
  return (actions) => {
    const {
      menuId,
      editorAPI,
      item,
      onMoveOutOfDropdownClick,
      onMoveToDropdownClick,
    } = options;
    const isChild = Boolean(item.parentId);
    const menuAPI = createMenuApi(editorAPI);

    const menu = menuAPI.getMenu(menuId);
    // @ts-expect-error
    const nestingLevel = getNestingLevel(menu.items, item);

    const { link } = item.item;
    const linkType =
      isPageLink(link) || isDynamicPageLink(link) ? 'PAGE' : 'LINK';
    const itemType = link ? linkType : 'FOLDER';

    const MOVEUP_LEVEL_TRANSLATIONS = {
      LINK: TRANSLATIONS.moveLinkUp,
      PAGE: TRANSLATIONS.movePageUp,
      FOLDER: TRANSLATIONS.moveFolderUp,
    };
    const MOVEDOWN_LEVEL_TRANSLATIONS = {
      LINK: TRANSLATIONS.moveLinkDown,
      PAGE: TRANSLATIONS.movePageDown,
      FOLDER: TRANSLATIONS.moveFolderDown,
    };

    const moveUpTranslation = MOVEUP_LEVEL_TRANSLATIONS[itemType];
    const moveDownTranslation = MOVEDOWN_LEVEL_TRANSLATIONS[itemType];
    // @ts-expect-error
    const prevItemId = getPrevSiblingId(menu.items, item);
    const prevItem = prevItemId ? menuAPI.getItem(menuId, prevItemId) : null;

    const fromPageTitle = item.parentId
      ? // @ts-expect-error
        menuAPI.getItem(menuId, item.parentId)?.label
      : '';
    // @ts-expect-error
    const toPageTitle = prevItem ? prevItem?.label : '';

    const moveFromDropdown = {
      title: translate(moveUpTranslation, { abovePageTitle: fromPageTitle }),
      type: 'action',
      symbolName: 'unsubpageAction',
      onClick: () => {
        const { items } = menuAPI.getMenu(menuId);
        const isSubSubCase = isOutOfSubSubCase(items, item.item);
        onMoveOutOfDropdownClick(isSubSubCase);

        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.MOVE_OUT_OF_DROPDOWN,
        );
        editorAPI.store.dispatch(showEditMenuNotification(menuId));
        menuAPI.moveFromDropdown(menuId, item.id);
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.MOVE_OUT_OF_DROPDOWN,
        );
        editorAPI.history.add('menu item move from dropdown');
      },
      automationId: AUTOMATION_IDS.ITEM.ACTIONS.MOVE_UP,
    };

    if (isChild) {
      actions = [moveFromDropdown, ...actions];
    }

    const moveToDropdown = {
      title: translate(moveDownTranslation, { abovePageTitle: toPageTitle }),
      type: 'action',
      symbolName: 'subpageAction',
      onClick: async () => {
        const { items } = menuAPI.getMenu(menuId);
        // @ts-expect-error
        const isSubSubCase = isSubSubItem(items, item.item, prevItem);
        onMoveToDropdownClick(isSubSubCase);

        fedopsLogger.interactionStarted(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.MOVE_INTO_DROPDOWN,
        );
        editorAPI.store.dispatch(showEditMenuNotification(menuId));

        menuAPI.moveToDropdown(menuId, item.id, prevItemId);
        fedopsLogger.interactionEnded(
          fedopsLogger.INTERACTIONS.MANAGE_MENU.MOVE_INTO_DROPDOWN,
        );
        editorAPI.history.add('menu item move to dropdown');
      },
      automationId: AUTOMATION_IDS.ITEM.ACTIONS.MOVE_DOWN,
    };

    const isSubNestingAllowed =
      !isMembersMenuId(menuId) &&
      isAdvancedMenuOpen() &&
      isSupportsSubsubItems(editorAPI);

    const MAX_TREE_LEVEL = isSubNestingAllowed ? 3 : 2;

    /** WTF block
     * To ensure we can move item to the deeper level we have to calculate resulting tree height.
     * What we need:
     *  - Item height (it can also have nested or even subnested items)
     *  - Potential parent nesting level (which is the same as item nesting level)
     *  - 1, because we're moving item 1 level deeper
     * Sum of these three things is our potential tree height.
     * If we support such height, it's good. Otherwise, this function would be unavailable
     */
    // @ts-expect-error
    const treeHeight = getTreeHeight(item.item);
    const finalTreeHeight = nestingLevel + treeHeight + 1;

    const isEmptyDropdown = isDropdown(item.item) && treeHeight === 1;
    const isPrevItemMegaMenuContainer = isSlotContainer(prevItem as MenuItem);

    const isNestingPossible = isEmptyDropdown
      ? finalTreeHeight < MAX_TREE_LEVEL
      : finalTreeHeight <= MAX_TREE_LEVEL;

    if (
      Boolean(prevItemId) &&
      isNestingPossible &&
      !isSlotContainer(item.item) &&
      !isPrevItemMegaMenuContainer
    ) {
      actions = [moveToDropdown, ...actions];
    }

    return actions;
  };
};

const MEMBERS_GROUP_TITLE = 'members';

const withChangeLinkAction: WithActionDecorator = (options) => (actions) => {
  const {
    item: { item },
    onChangeLink,
    editorAPI,
  } = options;

  const action = {
    title: translate(TRANSLATIONS.changeLink),
    type: 'action',
    symbolName: 'linkAction',
    onClick: () => onChangeLink(),
    automationId: AUTOMATION_IDS.ITEM.ACTIONS.CHANGE_LINK,
  };

  if (isDynamicPageLink(item.link)) {
    const { routerId } = item.link;

    const router = editorAPI.dsRead.routers.get.byId(routerId);

    if (router?.group === MEMBERS_GROUP_TITLE) {
      return actions;
    }
  }

  if (item.link && !isPageLink(item.link)) {
    return [action, ...actions];
  }

  return actions;
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'lodash';
import React from 'react';
import { TextLabel, RichText } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

import * as core from '#packages/core';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import * as helpIds from '#packages/helpIds';

const PLAYER_ORIGIN = 'ADD_PANEL';
const PANEL_NAME = 'panels.focusPanels.howToVideoPlayer';

// eslint-disable-next-line react/prefer-es6-class
const howToVideoTooltipContent = createReactClass({
  displayName: 'howToVideoTooltipContent',
  mixins: [core.mixins.editorAPIMixin],
  propTypes: {
    sectionKey: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
  },
  isHelpAvailable() {
    return _.has(helpIds.SECTIONS, this.props.sectionKey);
  },
  isVideoAvailable() {
    const videoIdKey = util.keyGenerator.videoIdKey(this.props.sectionKey);
    return util.media.getVideoIfAvailable(this.props.sectionKey, videoIdKey);
  },
  handleClickPlay() {
    const videoId = translate(
      util.keyGenerator.videoIdKey(this.props.sectionKey),
    );
    const editor = this.getEditorAPI();

    const biParams = {
      tooltipname: util.keyGenerator.titleKey(this.props.sectionKey),
      videoID: videoId,
    };
    editor.bi.event(coreBi.events.videoTooltip.THUMBNAIL_CLICK, biParams);
    editor.panelManager.openPanel(
      PANEL_NAME,
      {
        videoId,
        origin: PLAYER_ORIGIN,
      },
      true,
    );
  },
  handleClickHelp() {
    const editorAPI = this.getEditorAPI();
    const helpId = helpIds.SECTIONS[this.props.sectionKey];
    const biParams = {
      component: util.keyGenerator.titleKey(this.props.sectionKey),
      learn_more: true,
      panel_name: PANEL_NAME,
      origin: constants.BI.HELP.ORIGIN.ADD_PANEL,
    };
    editorAPI.panelManager.openHelpCenter(helpId, null, biParams);
  },
  handleClickClose(e) {
    const biParams = {
      tooltipname: util.keyGenerator.titleKey(this.props.sectionKey),
    };
    this.getEditorAPI().bi.event(coreBi.events.videoTooltip.CLOSE, biParams);
    this.props.onClose(e);
  },
  handleMouseEnter(e) {
    this.hoverStartTime = Date.now();
    this.props.onMouseEnter(e);
  },
  handleMouseLeave(e) {
    const biParams = {
      tooltipname: util.keyGenerator.titleKey(this.props.sectionKey),
      duration: Date.now() - this.hoverStartTime,
    };
    this.getEditorAPI().bi.event(coreBi.events.videoTooltip.HOVER, biParams);
    this.props.onMouseLeave(e);
  },
  componentDidMount() {
    this.mounted = true;
    window.setTimeout(
      function () {
        if (this.mounted) {
          const biParams = {
            tooltipname: util.keyGenerator.titleKey(this.props.sectionKey),
          };
          this.getEditorAPI().bi.event(
            coreBi.events.videoTooltip.OPEN,
            biParams,
          );
        }
      }.bind(this),
      1000,
    );
  },
  componentWillUnmount() {
    this.mounted = false;
  },
  render() {
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        className="how-to-video-tooltip-content"
      >
        <div onClick={this.handleClickClose} className="container-close">
          <symbols.symbol name="close" />
        </div>
        <TextLabel
          type="T03"
          value={util.keyGenerator.titleKey(this.props.sectionKey)}
        />
        {this.isVideoAvailable() ? (
          <div
            key="thumbnail"
            onClick={this.handleClickPlay}
            className="container-thumbnail"
          >
            <img
              src={translate(
                util.keyGenerator.thumbnailKey(this.props.sectionKey),
              )}
              alt="how-to video thumbnail"
              className="thumbnail"
            />
            <symbols.symbol name="play-video" />
          </div>
        ) : null}
        <RichText>
          {translate(util.keyGenerator.descriptionKey(this.props.sectionKey))}
          {this.isHelpAvailable() ? (
            <a
              key="link-help"
              onClick={this.handleClickHelp}
              className="video-tooltip-help"
            >
              {translate('add_section_label_description_learn_more')}
            </a>
          ) : null}
        </RichText>
      </div>
    );
  },
});

export default howToVideoTooltipContent;

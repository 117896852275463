import {
  multilingual,
  stateMapperArgsSelectors,
  sections,
} from '#packages/stateManagement';
import {
  EditorRestrictionsApiKey,
  QuickEditApiKey,
  FixedStageApiKey,
} from '#packages/apis';
import constants from '#packages/constants';
import experiments from 'experiment';
import { biLogger, sections as sectionsUtils } from '#packages/util';
import {
  addSectionButton,
  sectionEmptyStateClick,
  sectionShowEmptyState,
} from '@wix/bi-logger-editor/v2';
import { canSelectSectionLike } from '../selectors';

import { AddElementBiOrigin } from './constants';

const {
  mouseSels: { isDraggingSel, isResizingSel },
} = stateMapperArgsSelectors;

import type {
  Dispatch,
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { SectionLikeRect } from '../types';
import type { UISkin } from '../skins/skins.types';
import { type SiteSegmentBoxOwnProps, BoxType } from './SiteSegmentBox';
import { getSiteSegmentStageActionsSkin } from '../StageActions/StageActions.skins';
import { getSiteSegmentResizeHandleSkin } from '../ResizeHandle/ResizeHandle.skins';

type InteractionName = 'view' | 'hover' | 'click';

type SiteSegmentSelection = ReturnType<typeof getSelection>;

export interface AddPanelSectionParams {
  category: string;
  sectionId?: string;
}

const isMultilingual = (editorAPI: EditorAPI) =>
  editorAPI.language.multilingual.isEnabled() &&
  !multilingual.services.utils.currentIsOriginal(editorAPI);

const EMPTY_STATE_MIN_HEIGHT = 70;

const shouldShowEmptyState = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  isSelectedBox: boolean,
  bothBoxesForSameComp: boolean,
  isStageZoomOut: boolean,
  isLiteModeEmptyStateVisible: boolean,
) => {
  if (
    (editorAPI.isMobileEditor() ||
      isMultilingual(editorAPI) ||
      isStageZoomOut) &&
    !isLiteModeEmptyStateVisible
  ) {
    return false;
  }
  const isSmallSection =
    editorAPI.components.layout.getRelativeToScreen(compRef).height <
    EMPTY_STATE_MIN_HEIGHT;

  if (isSmallSection || !editorAPI.sections.isBlankSiteSegment(compRef))
    return false;

  return bothBoxesForSameComp ? isSelectedBox : true;
};

const shouldShowStageActions = (
  editorAPI: EditorAPI,
  {
    compRef,
    hoveredSection,
    hoveredHeaderFooter,
    isSelectedBox,
    bothBoxesForSameComp,
  }: SiteSegmentSelection,
) => {
  if (editorAPI.sections.isBlankSiteSegment(compRef)) return false;
  if (bothBoxesForSameComp) {
    return (
      isSelectedBox &&
      editorAPI.sections.isFirstTimeParentSectionLikeFocused(compRef)
    );
  }

  if (isSelectedBox) {
    return !hoveredHeaderFooter && !hoveredSection;
  }

  return true;
};

export interface SiteSegmentBoxStateProps {
  canSelect: boolean;
  isStageZoomOut: boolean;
  isFocused: boolean;
  isResizing: boolean;
  isDragging: boolean;
  shouldShowEmptyState: boolean;
  isLiteModeEmptyStateVisible: boolean;
  isMobileEditor: boolean;
  compRef: CompRef;
  compRect: SectionLikeRect;
  siteScale: number;
  stageXOffset: number;
  shouldDisplayResizeHandle: boolean;
  shouldHideLabels: boolean;
  bothBoxesForSameComp: boolean;
  isOverlayVisible: boolean;
  areContainerPointerEventsEnabled: boolean;
  isHeader: boolean;
  emptyStateAddPanelDefaultSection?: AddPanelSectionParams;
  stageActionsSkin: UISkin;
  resizeHandleSkin: UISkin;
  getCenteredContainerStyle: (
    offsetLeft: number,
    defaultLeft: string | number,
  ) => {
    left: string | number;
    transform?: string | undefined;
  };
}

const getSelection = (
  editorAPI: EditorAPI,
  ownProps: SiteSegmentBoxOwnProps,
) => {
  const hoveredSection = editorAPI.sections.getHoveredSection();
  const selectedSection = editorAPI.sections.getSelectedSection();
  const focusedSection = editorAPI.sections.getFocusedSection();
  const hoveredHeaderFooter = editorAPI.sections.getHoveredHeaderFooter();
  const selectedHeaderFooter = editorAPI.sections.getSelectedHeaderFooter();
  const focusedHeaderFooter = editorAPI.sections.getFocusedHeaderFooter();
  const isSelectedBox = ownProps.type === BoxType.Selected;
  const isHoveredBox = ownProps.type === BoxType.Hovered;
  const bothBoxesForSameComp = editorAPI.utils.isSameRef(
    selectedHeaderFooter,
    hoveredHeaderFooter,
  );
  const compRef = isSelectedBox
    ? selectedHeaderFooter || focusedHeaderFooter
    : hoveredHeaderFooter;

  return {
    hoveredSection,
    selectedSection,
    focusedSection,
    selectedHeaderFooter,
    hoveredHeaderFooter,
    focusedHeaderFooter,
    isSelectedBox,
    isHoveredBox,
    compRef,
    bothBoxesForSameComp,
  };
};

export const mapStateToProps: MapStateToProps<
  SiteSegmentBoxStateProps,
  SiteSegmentBoxOwnProps
> = (stateMapperArgs, ownProps) => {
  const { editorAPI, state } = stateMapperArgs;
  const fixedStageAPI = editorAPI.host.getAPI(FixedStageApiKey);
  const siteSegmentSelection = getSelection(editorAPI, ownProps);

  const {
    selectedHeaderFooter,
    hoveredHeaderFooter,
    focusedHeaderFooter,
    isSelectedBox,
    isHoveredBox,
    compRef,
    bothBoxesForSameComp,
  } = siteSegmentSelection;

  const siteScale = editorAPI.getSiteScale();
  const stageXOffset = editorAPI.zoomMode.getStageXOffset();

  const compLayout = editorAPI.components.layout.getRelativeToScreen(compRef);
  const isStageZoomOut = editorAPI.zoomMode.isStageZoomMode();
  const isShrinkedStageZoomOut =
    editorAPI.zoomMode.isLeftShrinkedStageZoomOutActive();
  const isDragging = isDraggingSel(stateMapperArgs);
  const translateY = sectionsUtils.getSectionLikeTranslateY(editorAPI, compRef);

  const compRect = {
    width: compLayout.width,
    height: compLayout.height,
    top: compLayout.y + translateY,
    bottom: compLayout.y + compLayout.height + translateY,
  };

  const isFocused =
    focusedHeaderFooter &&
    !editorAPI.utils.isSameRef(focusedHeaderFooter, selectedHeaderFooter);

  const getIsOverlayVisible = () => {
    const isSectionActionsBarHovered =
      sections.selectors.getIsSectionActionsBarHovered(state);

    if (!isHoveredBox) return false;

    return isFocused
      ? isSectionActionsBarHovered
      : !bothBoxesForSameComp || isSectionActionsBarHovered;
  };

  const stageActionsSkin = shouldShowStageActions(
    editorAPI,
    siteSegmentSelection,
  )
    ? getSiteSegmentStageActionsSkin(editorAPI, compRef, {
        selectedSectionLike: selectedHeaderFooter,
        focusedSectionLike: focusedHeaderFooter,
        hoveredSectionLike: hoveredHeaderFooter,
      })
    : undefined;

  const resizeHandleSkin = getSiteSegmentResizeHandleSkin(editorAPI, compRef, {
    selectedSectionLike: selectedHeaderFooter,
    hoveredSectionLike: hoveredHeaderFooter,
    focusedSectionLike: focusedHeaderFooter,
  });

  const isOverlayVisible = getIsOverlayVisible();

  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const areContainerPointerEventsEnabled = editorRestrictionsApi.allowed(
    'sections_container-pointer-events.interactive',
  );
  const isLiteModeEmptyStateVisible = editorRestrictionsApi.allowed(
    'sections_site-segment-empty-state-lite-mode.visible',
  );

  const isHeader = editorAPI.utils.isSameRef(
    compRef,
    editorAPI.siteSegments.getHeader(),
  );
  const headerEmptyStateAddPanelDefaultSection = { category: 'navigation' };
  const footerEmptyStateAddPanelDefaultSection = {
    category: 'strip',
    sectionId: 'stripContainerContactSection',
  };
  const emptyStateAddPanelDefaultSection = isHeader
    ? headerEmptyStateAddPanelDefaultSection
    : footerEmptyStateAddPanelDefaultSection;
  const isMobileEditor = editorAPI.isMobileEditor();
  const shouldHideLabels =
    (isMobileEditor && isHeader) ||
    (isHoveredBox &&
      !isStageZoomOut &&
      editorAPI.utils.isSameRef(selectedHeaderFooter, hoveredHeaderFooter));

  return {
    canSelect: canSelectSectionLike(stateMapperArgs),
    isStageZoomOut,
    isShrinkedStageZoomOut,
    isFocused,
    compRef,
    compRect,
    isDragging,
    isResizing: isResizingSel(stateMapperArgs),
    isMobileEditor,
    shouldShowEmptyState: shouldShowEmptyState(
      editorAPI,
      compRef,
      isSelectedBox,
      bothBoxesForSameComp,
      isStageZoomOut,
      isLiteModeEmptyStateVisible,
    ),
    isLiteModeEmptyStateVisible,
    bothBoxesForSameComp,
    shouldDisplayResizeHandle:
      isHoveredBox &&
      !isDragging &&
      !isShrinkedStageZoomOut &&
      Boolean(resizeHandleSkin) &&
      editorRestrictionsApi.allowed('site-segment_resize-handler.visible'),
    siteScale,
    stageXOffset,
    shouldHideLabels,
    isHeader,
    emptyStateAddPanelDefaultSection: experiments.isOpen(
      'se_defaultSectionEmptyState',
    )
      ? emptyStateAddPanelDefaultSection
      : undefined,
    isOverlayVisible,
    areContainerPointerEventsEnabled,
    stageActionsSkin,
    resizeHandleSkin,
    getCenteredContainerStyle: (offsetLeft, defaultLeft) =>
      fixedStageAPI.getCenteredComponentsLeft(offsetLeft, defaultLeft),
  };
};

export interface SiteSegmentBoxDispatchProps {
  logButtonBi: (interactionName: InteractionName) => void;
  openAddPanel: (options?: AddPanelSectionParams) => void;
  selectComponent: (compRef: CompRef) => void;
  logEmptyStateShownBi: () => void;
  logEmptyStateButtonClick: (type: 'add-element') => void;
  openQuickEditIfNeeded: (origin: string) => void;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  SiteSegmentBoxDispatchProps,
  SiteSegmentBoxOwnProps
> = (dispatch: Dispatch, ownProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const getCompDefaultBiFields = () => {
    const { compRef } = getSelection(editorAPI, ownProps);

    return editorAPI.bi.getComponentsBIParams([compRef])[0];
  };

  return {
    selectComponent: (compRef) => {
      editorAPI.selection.selectComponentByCompRef(compRef);
    },
    logButtonBi: (interactionName: InteractionName) => {
      biLogger.report(
        addSectionButton({
          actionName: interactionName,
        }),
      );
    },
    logEmptyStateShownBi: () => {
      biLogger.report(sectionShowEmptyState(getCompDefaultBiFields()));
    },
    logEmptyStateButtonClick: (type) => {
      biLogger.report(
        sectionEmptyStateClick({
          ...getCompDefaultBiFields(),
          type,
          origin: 'stage',
        }),
      );
    },
    openAddPanel: (options?: AddPanelSectionParams) => {
      editorAPI.panelManager.openPanel(
        constants.ROOT_COMPS.LEFTBAR.ADD_PANEL_NAME,
        {
          origin: AddElementBiOrigin,
          ...options,
        },
      );
    },
    openQuickEditIfNeeded: (origin: string) => {
      const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
      const editorRestrictionsApi = editorAPI.host.getAPI(
        EditorRestrictionsApiKey,
      );
      const isQuickEditOnClickInteractive = editorRestrictionsApi.allowed(
        'quick-edit_open-on-click.interactive',
      );
      if (
        quickEditAPI.isQuickEditAvailable() &&
        !quickEditAPI.isQuickEditPanelOpen() &&
        isQuickEditOnClickInteractive
      ) {
        quickEditAPI.openPanel({ origin });
      }
    },
  };
};

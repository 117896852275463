import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import {
  Composites,
  CustomScroll,
  Divider,
  Thumbnails,
  DropDown,
  DropDownOption,
  TextLabel,
} from '@wix/wix-base-ui';

import * as core from '#packages/core';
import { cx, hoc, backgroundUtils, imageTransform } from '#packages/util';
import { translate } from '#packages/i18n';
import * as panelUtils from '#packages/panelUtils';
import * as BaseUI from '#packages/baseUI';
import * as Panels from '#packages/panels';

import * as bi from '../../../../bi/bi';

import {
  normalFittingOptions,
  scrollMobileFittingOptions,
  fixedMobileFittingOptions,
  excludedMobileFittingOptionsMap,
  effectsThumbnailsOptions,
  effectsThumbnailsMobileOptions,
  parallaxFittingOptions,
  tileFittingOptions,
} from './BgImageSettingsPanel.constants';

import {
  mapStateToProps,
  mapDispatchToProps,
} from './BgImageSettingsPanel.mapper';

const { fittingTypes } = imageTransform;

const MAX_TILE_WIDTH = 600;
const MAX_TILE_HEIGHT = 600;

const defaults = {
  opacity: 1,
  fittingType: fittingTypes.SCALE_TO_FILL,
  alignType: 'center',
  scrollType: 'scroll',
};

export interface BgImageSettingsPanelOwnProps {
  bgData?: AnyFixMe;
  panelName: string;
}

export type BgImageSettingsPanelProps = BgImageSettingsPanelOwnProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

// eslint-disable-next-line react/prefer-es6-class
const BgImageSettingsPanel = createReactClass<BgImageSettingsPanelProps>({
  displayName: 'bgImageSettingsPanel',
  childContextTypes: {
    reportUIChange: PropTypes.func,
  },
  mixins: [
    core.mixins
      .editorAPIMixin /* TODO: remove linkColorPickerMixin/editorAPIMixin */,
    panelUtils.linkColorPickerMixin,
  ],

  getInitialState() {
    this.lastScrollingType = null;
    return {};
  },

  getChildContext() {
    return {
      reportUIChange: this.onUIChange,
    };
  },

  shouldComponentUpdate(nextProps) {
    //if panel is open while media was removed by Undo  close panel and prevent render
    const { bgData } = nextProps;
    if (!bgData.ref.mediaRef || bgData.ref.mediaRef.type !== 'Image') {
      this.props.closePanel();
      return false;
    }
    return true;
  },

  onUIChange(controlName: AnyFixMe) {
    if (controlName === 'slider') {
      this.props.undoHandler(this.props.undoOrigins.IMAGE_PANEL);
    }
  },

  linkedBackgroundAlignType(data: AnyFixMe) {
    const value = data?.ref?.alignType || defaults.alignType;

    return {
      value,
      requestChange: (newValue: AnyFixMe) => {
        _.set(data, ['ref', 'alignType'], newValue);
        this.props.requestChange(data, this.props.undoOrigins.IMAGE_PANEL);
        this.props.markDataChange();
      },
    };
  },

  linkedBackgroundScrollType(data: AnyFixMe) {
    const value = data?.ref?.scrollType || defaults.scrollType;

    return {
      value,
      requestChange: (newValue: AnyFixMe) => {
        if (newValue !== value) {
          this.props.sendBi(bi.events.BG_IMAGE_SETTINGS_PANEL_SCROLL_EFFECT, {
            scroll_type: newValue,
          });
        }
        if (newValue === 'parallax') {
          _.set(
            data,
            ['ref', 'fittingType'],
            this.getFittingTypeForParallax(data),
          );
        } else if (newValue === 'fixed' && this.props.isMobileEditor) {
          _.set(data, 'mediaSizing', 'viewport');
        }
        _.set(data, ['ref', 'scrollType'], newValue);
        this.props.requestChange(data, this.props.undoOrigins.IMAGE_PANEL);
        this.props.markDataChange();
      },
    };
  },

  /**
   * Note: changes of mediaSizing here is a trick for UI purposes.
   * The main logic for mediaSizing update is in documentServices.
   */
  linkedBackgroundFittingType(data: AnyFixMe) {
    const value = data?.ref?.fittingType || defaults.fittingType;
    const mediaSizing = data?.mediaSizing ?? 'viewport';

    // We use fittingTypes.STRECH option only for GUI.
    // actually in the data store it is converted to fittingType.SCALE_TO_FILL and mediaSizing = 'document'
    return {
      value:
        value === fittingTypes.SCALE_TO_FILL && mediaSizing === 'document'
          ? fittingTypes.STRETCH
          : value,
      requestChange: (val: AnyFixMe) => {
        let newValue = val;
        let newMediaSizing = 'viewport';
        if (this.props.isMobileEditor && val === fittingTypes.STRETCH) {
          newMediaSizing = 'document';
          newValue = fittingTypes.SCALE_TO_FILL;
        }
        _.set(data, ['ref', 'fittingType'], newValue);
        _.set(data, 'mediaSizing', newMediaSizing);
        this.props.requestChange(data, this.props.undoOrigins.IMAGE_PANEL);
        this.props.markDataChange();
      },
    };
  },

  linkedBackgroundOpacityData(data: AnyFixMe) {
    const currentOpacity = data?.ref?.mediaRef?.opacity ?? defaults.opacity;
    const value = Math.round(currentOpacity * 100);
    return {
      value,
      requestChange: (val: AnyFixMe) => {
        val /= 100;
        _.set(data, 'ref.mediaRef.opacity', val);
        this.props.requestChange(data);
        this.props.markDataChange();
      },
    };
  },

  linkedBackgroundColor(data: AnyFixMe, base: AnyFixMe, key: AnyFixMe) {
    const value = base[key].replace(/[\{\}]/g, '');
    return {
      value,
      requestChange: (val: AnyFixMe) => {
        base[key] = /color_/.test(val) ? `{${val}}` : val;
        this.props.requestChange(data); //No undo management - color picker manages its own history.
        this.props.markDataChange();
      },
    };
  },

  getColorPickerProps() {
    return {
      useMouseEvent: true,
      colorResolver: this.resolveColor,
      openColorPicker: this.openColorPicker,
      previewOnHover: true,
    };
  },

  getFittingOptions(scrollType: AnyFixMe) {
    if (scrollType === 'parallax') {
      return parallaxFittingOptions;
    }
    return this.props.isMobileEditor
      ? this.getFittingMobileOptions()
      : normalFittingOptions;
  },

  getFittingMobileOptions() {
    const currentScrollType = this.props.bgData?.ref?.scrollType;
    const fittingOptions =
      currentScrollType === 'scroll'
        ? scrollMobileFittingOptions
        : fixedMobileFittingOptions;
    const currentFittingType = this.props.bgData?.ref?.fittingType;
    const excludedOption =
      excludedMobileFittingOptionsMap[
        currentFittingType as keyof typeof excludedMobileFittingOptionsMap
      ];
    if (excludedOption) {
      return _.union(fittingOptions, [excludedOption]);
    }

    return fittingOptions;
  },

  getEffectsThumbOptions() {
    return this.props.isMobileEditor
      ? effectsThumbnailsMobileOptions
      : effectsThumbnailsOptions;
  },

  getFittingTypeForParallax(bgData: AnyFixMe) {
    const currentFittingType = bgData.ref.fittingType;
    const imageData = bgData.ref.mediaRef;

    const legacyFitAndTile = fittingTypes.LEGACY_BG_FIT_AND_TILE;
    const scaleToFill = fittingTypes.SCALE_TO_FILL;

    const isSmaller = backgroundUtils.isSmallerFromContainer(
      imageData.width,
      imageData.height,
      MAX_TILE_WIDTH,
      MAX_TILE_HEIGHT,
    );
    const isTile =
      tileFittingOptions.includes(currentFittingType) ||
      (isSmaller && currentFittingType !== scaleToFill);

    return isTile ? legacyFitAndTile : scaleToFill;
  },

  render() {
    const data = this.props.bgData;

    return (
      <Panels.frames.ToolPanelFrame
        helpId="54c7eb1c-a6c7-46c9-9261-96eecf3bb0eb"
        contentWrapperClass="background-image-settings-panel background-sub-panel"
        headerTitle={translate('BGPP_SETTINGS_IMAGE_PANEL_HEADER_TITLE')}
        {..._.omit(this.props, 'children')}
      >
        <CustomScroll>
          <div className="bg-image-settings-panel-scrollbox bg-settings-panel-scrollbox">
            <Composites.SliderLabeled>
              <TextLabel value="BGPP_SETTINGS_IMAGE_OPACITY_TITLE" />

              <BaseUI.WixBaseUISliderWithBI
                value={parseInt(
                  this.linkedBackgroundOpacityData(data).value,
                  10,
                )}
                onChange={this.linkedBackgroundOpacityData(data).requestChange}
                min={0}
                max={100}
                inputMin={0}
                inputMax={100}
                step={1}
                label="BGPP_SETTINGS_IMAGE_OPACITY_TITLE"
              />
            </Composites.SliderLabeled>

            <Divider long={true} />

            <BaseUI.colorPickerInput
              label="BGPP_SETTINGS_IMAGE_BG_COLOR_TITLE"
              ref="bgColorPickerInput"
              valueLink={this.linkedBackgroundColor(data, data.ref, 'color')}
              {...this.getColorPickerProps()}
              className={cx(
                this.getColorPickerProps()?.className,
                'label-left',
              )}
            />

            <Divider long={true} />
            <BaseUI.sectionDividerLabeled
              label="BGPP_SETTINGS_IMAGE_SCROLL_SECTION_TITLE"
              infoText="BGPP_SETTINGS_IMAGE_SCROLL_SECTION_TOOLTIP"
            />
            <Divider long={true} />

            <Composites.Thumbnails>
              <Thumbnails
                value={this.linkedBackgroundScrollType(data).value}
                onChange={this.linkedBackgroundScrollType(data).requestChange}
                maxThumbsPerRow={3}
                options={this.getEffectsThumbOptions()}
                className="with-labels"
              />
            </Composites.Thumbnails>

            <Divider long={true} />
            <BaseUI.sectionDividerLabeled label="BGPP_SETTINGS_IMAGE_LAYOUT_SECTION_TITLE" />
            <Divider long={true} />

            <Composites.DropDownLabeled>
              <TextLabel value={'BGPP_SETTINGS_IMAGE_FITTING_TITLE'} />
              <DropDown
                value={this.linkedBackgroundFittingType(data).value}
                onChange={this.linkedBackgroundFittingType(data).requestChange}
              >
                {this.getFittingOptions(data.ref.scrollType).map(
                  (
                    option: { value: string; label: string },
                    optionIndex: string,
                  ) => (
                    <DropDownOption
                      key={`fittingTypeOption${optionIndex}`}
                      value={option.value}
                      label={option.label}
                    />
                  ),
                )}
              </DropDown>
            </Composites.DropDownLabeled>
            <Divider long={true} />

            <BaseUI.alignment
              options={BaseUI.commonData.ALIGNMENT_MODES_XY}
              valueLink={this.linkedBackgroundAlignType(data)}
              label="BGPP_SETTINGS_IMAGE_ALIGN_TITLE"
            />
          </div>
        </CustomScroll>
      </Panels.frames.ToolPanelFrame>
    );
  },
});

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(BgImageSettingsPanel);

import * as stateManagement from '#packages/stateManagement';

import type {
  MapStateToProps,
  MapDispatchToProps,
  Dispatch,
  StateMapperArgs,
} from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
import type { PublishPanelOwnProps } from './publishPanel';

export interface PublishPanelDispatchProps {
  openPanel: (panelName: string) => void;
  openAccountSettings: (path: string) => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}

export interface PublishPanelStateProps {
  canUserPublish: boolean;
  isSitePublished: boolean;
  sitePublicUrl: string;
}

const mapStateToProps: MapStateToProps<
  PublishPanelStateProps,
  PublishPanelOwnProps
> = ({ editorAPI }) => ({
  canUserPublish: editorAPI.savePublish.canUserPublish(),
  isSitePublished: editorAPI?.dsRead.generalInfo.isSitePublished() || false,
  sitePublicUrl: editorAPI.site.getSitePublicUrl() || '',
});

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => stateManagement.EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  PublishPanelDispatchProps,
  PublishPanelOwnProps
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openPanel: (panelName: string) => {
      dispatch(stateManagement.panels.actions.updateOrOpenPanel(panelName));
    },
    openAccountSettings: (path: string) => {
      editorAPI.account.openSettings({ path });
    },
    sendBi: editorAPI.bi.event,
  };
};

export { mapStateToProps };

// @ts-nocheck
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import constants from '#packages/constants';
import events from '../bi/events';
import consts from '../common/constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const helpIdsByControllerType = {
  dataset: '7f921134-acd3-4771-ac24-33bbeafefb90',
  router_dataset: '7f921134-acd3-4771-ac24-33bbeafefb90',
};

const DEFAULT_MAIN_ACTION_NAME = 'gfpp_mainaction_dataset';

const { mainActionNames } = consts.GFPP;

const gfppDefinition = {
  untranslatable: true,
  mainActions: getMainActions,
  presetActions: {
    help: {
      helpId(editorAPI, compRef) {
        const { controllerType } = editorAPI.components.data.get(compRef);
        return (
          helpIdsByControllerType[controllerType] ||
          '3c9b0528-699b-475f-818c-2123885cb11f'
        );
      },
    },
  },
  enabledActions: [constants.ROOT_COMPS.GFPP.ACTIONS.HELP],
};

function getMainActions(editorAPI, compRefs) {
  const mainActions = [];
  const controllerStageData = editorAPI.platform.controllers.getStageData(
    _.head(compRefs),
  );
  const mainAction1Id = controllerStageData?.gfpp?.[
    mainActionNames.MAIN_ACTION1
  ]
    ? mainActionNames.MAIN_ACTION1
    : mainActionNames.DEPRECATED_MAIN_ACTION;
  const mainAction1 = controllerStageData?.gfpp?.[mainAction1Id];
  const mainAction2 = controllerStageData?.gfpp?.[mainActionNames.MAIN_ACTION2];
  if (mainAction1) {
    mainActions.push(getMainAction(mainAction1Id, controllerStageData));
  }
  if (mainAction2) {
    mainActions.push(
      getMainAction(mainActionNames.MAIN_ACTION2, controllerStageData),
    );
  }
  return mainActions;
}

function getMainAction(actionName, controllerStageData) {
  const label = getMainActionLabel(actionName, controllerStageData);
  const id = getMainActionId(actionName, controllerStageData);
  return {
    id,
    label,
    shouldTranslate: label === DEFAULT_MAIN_ACTION_NAME,
    onClick: _.partial(notifyControllerGfppClicked, { actionName }),
  };
}

function getMainActionLabel(actionName, controllerStageData) {
  const mainActionName = _.get(controllerStageData, `gfpp.${actionName}.label`);
  return mainActionName || DEFAULT_MAIN_ACTION_NAME;
}

function getMainActionId(actionName, controllerStageData) {
  const mainActionId = _.get(
    controllerStageData,
    `gfpp.${actionName}.actionId`,
  );
  return mainActionId || DEFAULT_MAIN_ACTION_NAME;
}

function init(registerGfpp) {
  registerGfpp('AppController', gfppDefinition);
}

// function notifyControllerGfpp(actionId)

function notifyControllerGfppClicked(
  { actionName, actionId },
  editorAPI: EditorAPI,
  components: CompRef[],
) {
  const controllerRef = _.head(components);
  const compData = editorAPI.components.data.get(controllerRef);
  const controllerConfig =
    editorAPI.dsRead.platform.controllers.settings.get(controllerRef);
  const controllerDisplayName =
    editorAPI.dsRead.platform.controllers.getName(controllerRef);
  const controllerStageData = editorAPI.platform.controllers.getStageData(
    _.head(components),
  );
  const { applicationId, controllerType, widgetId, appDefinitionId } = compData;
  const ooiController = editorAPI.dsRead.tpa.isTpaByCompType(
    editorAPI.components.getType(controllerRef),
  );
  const type = ooiController ? widgetId : controllerType;
  const appId = ooiController ? appDefinitionId : applicationId;
  editorAPI.bi.event(events.CONTROLLER_GFPP_CLICK, {
    action_name: actionId || getMainActionId(actionName, controllerStageData),
    app_name: appId,
    controller_type: type,
    component_id: controllerRef.id,
    pageId: editorAPI.pages.getCurrentPageId(),
  });

  //we store in the document 'dataBinding' and not the real applicationId...
  const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(appId);
  const eventData = {
    controllerRef,
    controllerConfig,
    controllerType,
    controllerDisplayName,
    origin: 'gfpp',
  };
  let event;
  if (actionName === mainActionNames.DEPRECATED_MAIN_ACTION) {
    event = platformEvents.factory.controllerSettingsButtonClicked(eventData);
  } else {
    const mainActionId =
      actionId || getMainActionId(actionName, controllerStageData);
    event = platformEvents.factory.controllerGfppClicked(
      Object.assign(eventData, { id: mainActionId }),
    );
  }
  editorAPI.dsActions.platform.notifyApplication(appData.applicationId, event);
}

export { init, notifyControllerGfppClicked };

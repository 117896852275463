import * as stateManagement from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompVariantPointer, UnitSize } from 'types/documentServices';
import * as util from '#packages/util';
import { getDefaultOrigin } from './panels/effectsPanel/customize/customizeUtils';

interface IPosition {
  x: number;
  y: number;
}

interface IUnits {
  x: UnitSize;
  y: UnitSize;
}

export type IScale = IPosition;
export type ISkew = IPosition;
export type ITranslate = IUnits;
export type IOrigin = IUnits;

export interface ITransformationData {
  scale?: IScale;
  rotate?: number;
  translate?: ITranslate;
  skew?: ISkew;
  origin?: IOrigin;
  hidden?: boolean;
}

export function getTransformation(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
): ITransformationData {
  const targetCompRef = editorAPI.components.get.byId(
    targetCompRefWithVariant.id,
  );

  const variantTransformation = editorAPI.components.transformations.get(
    targetCompRefWithVariant,
  );
  // NOTE: origin has to be taken from targetCompRef
  const regularTransformation =
    editorAPI.components.transformations.get(targetCompRef);
  return {
    ...variantTransformation,
    origin: regularTransformation?.origin || getDefaultOrigin(),
  };
}

export function move(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
  translate: ITranslate,
) {
  return customTransform(editorAPI, targetCompRefWithVariant, { translate });
}

export function scale(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
  scale: IScale,
  origin?: IOrigin,
) {
  return customTransform(editorAPI, targetCompRefWithVariant, {
    scale,
    rotate: undefined,
    skew: undefined,
    origin: origin || undefined,
  });
}

export function rotate(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
  rotate: number,
  origin?: IOrigin,
) {
  return customTransform(editorAPI, targetCompRefWithVariant, {
    scale: undefined,
    rotate,
    skew: undefined,
    origin: origin || undefined,
  });
}

export function skew(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
  skew: ISkew,
  origin?: IOrigin,
) {
  return customTransform(editorAPI, targetCompRefWithVariant, {
    scale: undefined,
    rotate: undefined,
    skew,
    origin: origin || undefined,
  });
}

// NOTE: reset method should reset everything but transform, because drag&drop could be done separately from effects
export function resetEffects(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
) {
  return customTransform(
    editorAPI,
    targetCompRefWithVariant,
    {
      scale: undefined,
      rotate: undefined,
      skew: undefined,
      origin: undefined,
    },
    () => {},
  );
}

export function setVisibility(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
  isHidden: boolean,
) {
  return customTransform(editorAPI, targetCompRefWithVariant, {
    hidden: isHidden,
  });
}

export function customTransform(
  editorAPI: EditorAPI,
  targetCompRefWithVariant: CompVariantPointer,
  transformationData: ITransformationData,
  callback?: () => void,
) {
  const targetCompRef = editorAPI.components.get.byId(
    targetCompRefWithVariant.id,
  );
  // NOTE: transition API expects compRef and not compWithPointer ref
  stateManagement.interactions.actions.setDefaultTransition(
    editorAPI,
    targetCompRef,
  );
  stateManagement.interactions.actions.setDefaultZLayer(editorAPI);

  if (transformationData.hasOwnProperty('origin')) {
    // NOTE: origin has to be saved under compRef
    editorAPI.components.transformations.update(targetCompRef, {
      origin: transformationData.origin,
    });
  }

  const { origin, ...restTransformationData } = transformationData;
  editorAPI.components.transformations.update(
    targetCompRefWithVariant,
    restTransformationData,
  );

  editorAPI.history.add('edit-effect');

  editorAPI.waitForChangesApplied(() => {
    if (typeof callback === 'function') {
      callback();
    }

    requestAnimationFrame(() => {
      stateManagement.interactions.context.reRenderConsumers();
    });

    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.INTERACTIONS_FEATURE.EDIT_EFFECT,
    );
  });
}

import _ from 'lodash';
import constants from '#packages/constants';
import experiment from 'experiment';
import { getComponentGfppData } from '../data/gfppDataMap';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

import type { CompRef, SvgInfo } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  GFPPData,
  GFPPDataRawOrResolver,
  GFPPActionType,
  GFPPValueResolver,
  GFPPAction,
} from '@wix/editor-component-types';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

const RICH_TEXT_COMP_TYPE = 'wysiwyg.viewer.components.WRichText';
const TPA_GALLERY_COMP_TYPES = [
  'tpa.viewer.components.Masonry',
  'tpa.viewer.components.Accordion',
  'tpa.viewer.components.Impress',
  'tpa.viewer.components.Freestyle',
  'tpa.viewer.components.Collage',
  'tpa.viewer.components.Honeycomb',
  'tpa.viewer.components.StripShowcase',
  'tpa.viewer.components.StripSlideshow',
  'tpa.viewer.components.Thumbnails',
  'wysiwyg.viewer.components.tpapps.TPA3DGallery',
  'wysiwyg.viewer.components.tpapps.TPA3DCarousel',
];
const VECTOR_IMAGE_TYPE = 'wysiwyg.viewer.components.VectorImage';
const MEDIA_PLAYER_TYPE = 'wysiwyg.viewer.components.MediaPlayer';
const GOOGLE_MAP_TYPE = 'wysiwyg.viewer.components.GoogleMap';
const MULTI_DESIGN_BLACKLIST = [RICH_TEXT_COMP_TYPE, GOOGLE_MAP_TYPE].concat(
  TPA_GALLERY_COMP_TYPES,
);

function getGfppData(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
  componentGfppData: GFPPDataRawOrResolver,
) {
  return (
    _.isFunction(componentGfppData)
      ? componentGfppData(editorAPI, compRefs)
      : componentGfppData
  ) as GFPPData;
}

function getEnabledActionsForComp(
  editorAPI: EditorAPI,
  compRefs: CompRef[],
  compType: string,
) {
  const isTextSettingsInDesignGfppExperimentOpen = experiment.isOpen(
    'se_textSettingsInDesignGfpp',
  );

  const gfppData = getGfppData(
    editorAPI,
    compRefs,
    getComponentGfppData(compType),
  );

  const richTextEnabledActions =
    isTextSettingsInDesignGfppExperimentOpen &&
    compType === RICH_TEXT_COMP_TYPE &&
    (gfppData.enabledActions as unknown as GFPPValueResolver<GFPPActionType[]>)(
      editorAPI,
      compRefs,
    );

  if (_.isFunction(gfppData?.presetActionsData?.design?.onClick)) {
    return [];
  }
  const enabledActions: GFPPActionType[] =
    richTextEnabledActions || gfppData?.enabledActions || [];
  if (gfppData.additionalCompForGfpp) {
    const additionalGfppData = getGfppData(
      editorAPI,
      [gfppData.additionalCompForGfpp],
      getComponentGfppData(
        editorAPI.components.getType(gfppData.additionalCompForGfpp),
      ),
    );
    return enabledActions.concat(additionalGfppData?.enabledActions || []);
  }
  return enabledActions;
}

function getVectorImageCompType(editorAPI: EditorAPI, compRefs: CompRef[]) {
  const svgTypes: string[] = _.uniq(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _.map(compRefs, (compRef) => {
      const svgInfo = editorAPI.media.vectorImage.getSvgInfoFromCache(
        editorAPI.components.data.get(compRef).svgId,
      );
      // TODO: extend DS.SvgInfo with `color2' (in @wix/document-services-types)
      const { svgType, color2 } = svgInfo as SvgInfo & { color2: unknown };
      return svgType === 'color' && color2 ? 'multicolor' : svgType;
    }),
  );
  return svgTypes.length === 1 ? VECTOR_IMAGE_TYPE : false;
}

function getVideoBoxCompType(editorAPI: EditorAPI, compRefs: CompRef[]) {
  const hasAlpha = (
    compDesign: AnyFixMe, // TODO: Fix this the next time the file is edited.
  ) =>
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(compDesign?.background?.mediaRef?.mediaFeatures, 'alpha');
  const hasMask = (compRef: CompRef, compDesign: AnyFixMe) => {
    const hasMobileMask =
      editorAPI.isMobileEditor() &&
      !!editorAPI.components.properties.get({ id: compRef.id, type: 'MOBILE' })
        ?.overrideMask?.svgId;
    const hasDesktopMask = !!compDesign?.background?.mediaRef?.mask?.svgId;
    return hasMobileMask || hasDesktopMask;
  };
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const alphaMaskMap = _.map(compRefs, (compRef) => {
    const compDesign = editorAPI.components.design.get(compRef);
    return {
      hasAlpha: hasAlpha(compDesign),
      hasMask: hasMask(compRef, compDesign),
    };
  });
  const isSupported =
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    _.every(alphaMaskMap, 'hasAlpha') ||
    // eslint-disable-next-line you-dont-need-lodash-underscore/every
    _.every(alphaMaskMap, 'hasMask') ||
    // eslint-disable-next-line you-dont-need-lodash-underscore/every, you-dont-need-lodash-underscore/some
    (!_.some(alphaMaskMap, 'hasAlpha') && !_.some(alphaMaskMap, 'hasMask'));

  return isSupported ? MEDIA_PLAYER_TYPE : false;
}

function getCommonCompType(editorAPI: EditorAPI, compRefs: CompRef[]) {
  const compTypes: string[] = _.uniq(
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    _.map(compRefs, (compRef) => editorAPI.components.getType(compRef)),
  );

  if (compTypes.length !== 1) {
    return false;
  }

  const compType = compTypes[0];
  if (compType === VECTOR_IMAGE_TYPE) {
    return getVectorImageCompType(editorAPI, compRefs);
  }

  if (compType === MEDIA_PLAYER_TYPE) {
    return getVideoBoxCompType(editorAPI, compRefs);
  }

  return compType;
}

function isMultiDesignEnabled(editorAPI: EditorAPI, compRefs: CompRef[]) {
  const compType = getCommonCompType(editorAPI, compRefs);
  if (!compType) {
    return false;
  }
  const enabledActions = getEnabledActionsForComp(
    editorAPI,
    compRefs,
    compType,
  );
  const isTextSettingsInDesignGfppExperimentOpen = experiment.isOpen(
    'se_textSettingsInDesignGfpp',
  );

  const isExcluded =
    isTextSettingsInDesignGfppExperimentOpen && compType === RICH_TEXT_COMP_TYPE
      ? false
      : MULTI_DESIGN_BLACKLIST.includes(compType);

  // enabledActions.includes could be not a function ("n.includes is not a function" error in sentry[1]).
  // [1]: https://sentry-next.wixpress.com/organizations/santa-editor/issues/21857/
  return (
    !isExcluded &&
    Array.isArray(enabledActions) &&
    enabledActions.includes(ACTIONS.DESIGN)
  );
}

function multipleRichTextCompsAreSelected(
  editorAPI: EditorAPI,
  compRefs: CompRef[] | CompRef,
) {
  if (!Array.isArray(compRefs) || !(compRefs.length > 1)) {
    return false;
  }
  return (
    !isResponsiveEditor() &&
    compRefs.every(
      (compRef) =>
        editorAPI.components.getType(compRef) === RICH_TEXT_COMP_TYPE,
    )
  );
}

function getRichTextMainAction(editorAPI: EditorAPI, compRefs: CompRef[]) {
  if (multipleRichTextCompsAreSelected(editorAPI, compRefs)) {
    const gfppData = getGfppData(
      editorAPI,
      compRefs,
      getComponentGfppData(RICH_TEXT_COMP_TYPE),
    );
    const mainActions = (
      gfppData.mainActions as unknown as GFPPValueResolver<GFPPAction[]>
    )(editorAPI, compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    return _.find(mainActions, ['automationId', 'gfpp_text-edit-aid']);
  }
}

function getRichTextPresetActions(editorAPI: EditorAPI, compRefs: CompRef[]) {
  if (multipleRichTextCompsAreSelected(editorAPI, compRefs)) {
    const gfppData = getGfppData(
      editorAPI,
      compRefs,
      getComponentGfppData(RICH_TEXT_COMP_TYPE),
    );
    return gfppData.presetActions;
  }
}

export default {
  isMultiDesignEnabled,
  getRichTextMainAction,
  multipleRichTextCompsAreSelected,
  getRichTextPresetActions,
};

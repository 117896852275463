// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import PropTypes from 'prop-types';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import {
  Button,
  Composites,
  Divider,
  DropDown,
  TextInput,
  TextLabel,
} from '@wix/wix-base-ui';
import { ToolPanelFrame } from '../../frames';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const { closePanelByNameAction } = stateManagement.panels.actions;

//TYPE WAS GENERATED, remove this line when reviewed
interface AddA11yAttributeProps {
  top?: string;
  left?: string;
  a11ySchema: AnyFixMe;
}

class AddA11yAttribute extends React.Component<AddA11yAttributeProps> {
  static displayName = 'addA11yAttribute';

  static propTypes = {
    top: PropTypes.string,
    left: PropTypes.string,
    a11ySchema: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { initialValues } = props;
    const { attributeType, attributeValue } = initialValues || {};

    this.state = {
      isButtonDisabled: !initialValues,
      attributeType,
      attributeValue,
    };
  }

  isEditMode = () => {
    return !!this.props.initialValues;
  };

  getHeaderTitle = () => {
    const headerTitleKey = this.isEditMode()
      ? 'accessibility_components_editAttribute_header'
      : 'accessibility_components_addAttribute_header';
    return translate(headerTitleKey);
  };

  getSaveButtonText = () => {
    const buttonTextKey = this.isEditMode()
      ? 'accessibility_components_editAttribute_saveButton'
      : 'accessibility_components_addAttribute_addButton';
    return translate(buttonTextKey);
  };

  attributeTypeToTranslationKey = (attributeType) => {
    switch (attributeType) {
      case 'label':
        return 'accessibility_components_addAttribute_enterArialabel_label';
      case 'level':
        return 'accessibility_components_addAttribute_level_label';
      default:
        return 'accessibility_components_addAttribute_attributeValue_label';
    }
  };

  getAvailableA11yAttributes = () => {
    const { properties } = this.props.a11ySchema;
    const currentA11yAttributes = this.props?.compData?.a11y ?? {};
    const filteredProperties = _.pickBy(properties, (property, key) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
      _.isUndefined(currentA11yAttributes[key]),
    );

    if (this.isEditMode()) {
      filteredProperties[this.state.attributeType] =
        properties[this.state.attributeType];
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(filteredProperties, (property, key) => ({
      label: util.a11yUtils.getAttributeTypeTranslations()[key],
      value: key,
    }));
  };

  isTextInput = () => {
    const { attributeType } = this.state;
    if (!attributeType) {
      return;
    }
    const { properties } = this.props.a11ySchema;
    const attributeData = properties[attributeType];
    return !attributeData.enum && attributeData.type === 'string';
  };

  isDropDown = () => {
    const { attributeType } = this.state;
    if (!attributeType) {
      return;
    }
    const { properties } = this.props.a11ySchema;
    const attributeData = properties[attributeType];
    return (
      attributeData.enum ||
      attributeData.type === 'boolean' ||
      attributeData.type === 'number'
    );
  };

  getA11yValuesOptions = (attributeType) => {
    const { properties } = this.props.a11ySchema;
    const attributeData = properties[attributeType];

    let values;
    switch (attributeData.type) {
      case 'number':
        values = _.range(attributeData.minimum, attributeData.maximum + 1);
        break;
      case 'boolean':
        values = [true, false];
        break;
      default:
        values = properties[attributeType].enum;
    }

    return values.map((e) => ({
      label: `${e}`,
      value: e,
    }));
  };

  onAttributeTypeChanged = (attributeType) => {
    this.setState({
      attributeType,
      attributeValue: '',
    });
  };

  onAttributeValueChanged = (attributeValue) => {
    this.setState({
      attributeValue,
      isButtonDisabled: attributeValue.length === 0,
    });
  };

  addAttribute = () => {
    const { updateData } = this.props;
    const { attributeValue, attributeType } = this.state;
    const schemaA11yAttributes = this.props.a11ySchema;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    const schemaKeys = _.keys(schemaA11yAttributes.properties);

    let currA11yAttr = _.pick(this.props?.compData?.a11y ?? {}, schemaKeys);

    if (this.isEditMode()) {
      currA11yAttr = _.omit(currA11yAttr, [
        this.props.initialValues.attributeType,
      ]);
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const newA11ydata = _.assign(currA11yAttr, {
      [attributeType]: attributeValue,
      type: schemaA11yAttributes.type,
    });

    this.props.reportPropertySavedBI(attributeType, attributeValue);
    updateData('a11y', newA11ydata);
    this.close();
  };

  close = () => {
    this.props.closePanel();
  };

  render() {
    return (
      <ToolPanelFrame
        panelName={this.props.panelName}
        headerTitle={this.getHeaderTitle()}
        contentStyle={_.pick(this.props, ['top', 'left'])}
        lightHeader={true}
        automationId="addA11yAttributePanel"
        className="add-a11y-attribute-panel"
      >
        <div className="a11y-attribute-panel-container">
          <Divider long={true} />

          {this.getAvailableA11yAttributes().length > 0 ? (
            <Composites.DropDownLabeled>
              <TextLabel value="accessibility_components_addAttribute_chooseAttribute_label" />
              <DropDown
                automationId="dropDownAttributeType"
                value={this.state.attributeType}
                options={this.getAvailableA11yAttributes()}
                onChange={this.onAttributeTypeChanged}
                placeholder={translate(
                  'accessibility_components_addAttribute_chooseAttribute_placeholder',
                )}
                shouldTranslate={false}
              />
            </Composites.DropDownLabeled>
          ) : null}

          <Divider long={true} />

          {this.isTextInput() ? (
            <>
              <Composites.TextInputLabeled key="dataKey64">
                <TextLabel
                  value={this.attributeTypeToTranslationKey(
                    this.state.attributeType,
                  )}
                  automationId="attributeLabel"
                />
                <TextInput
                  value={this.state.attributeValue}
                  onChange={this.onAttributeValueChanged}
                  placeholder="accessibility_components_addAttribute_enterArialabel_placeholder"
                  automationId="textInputAttribute"
                />
              </Composites.TextInputLabeled>
              <Divider long={true} />
            </>
          ) : null}

          {this.isDropDown() ? (
            <>
              <Composites.DropDownLabeled>
                <TextLabel
                  value={this.attributeTypeToTranslationKey(
                    this.state.attributeType,
                  )}
                  automationId="attributeLabel"
                />
                <DropDown
                  value={this.state.attributeValue}
                  shouldTranslate={false}
                  options={this.getA11yValuesOptions(this.state.attributeType)}
                  onChange={this.onAttributeValueChanged}
                  placeholder={translate(
                    'accessibility_components_addAttribute_dropdown_placeholder',
                  )}
                  automationId="dropDownAttribute"
                />
              </Composites.DropDownLabeled>
              <Divider long={true} />
            </>
          ) : null}

          <Composites.ButtonLargeFixedBottom>
            <Button
              automationId="a11yAttributePanelMainButton"
              disabled={this.state.isButtonDisabled}
              onClick={this.addAttribute}
              className="btn-md"
            >
              {this.getSaveButtonText()}
            </Button>
          </Composites.ButtonLargeFixedBottom>
        </div>
      </ToolPanelFrame>
    );
  }
}

const mapStateToProps = ({ dsRead }, { selectedComponent }) => ({
  a11ySchema: dsRead.components.getA11ySchema(selectedComponent[0]),
  siteId: dsRead.generalInfo.getSiteId(),
});
const mapDispatchToProps = (
  dispatch,
  { siteId, selectedComponents, selectedComponentType, target },
) => ({
  closePanel: () =>
    dispatch(closePanelByNameAction('panels.toolPanels.addA11yAttribute')),
  reportPropertySavedBI: (propName, propValue) => {
    const params = {
      component_id: selectedComponents[0].id,
      componentName: selectedComponentType,
      aria_prop: propName,
      site_id: siteId,
      prop_token: propValue,
      target,
    };
    dispatch(
      stateManagement.bi.actions.event(
        coreBi.events.a11ySettings.addA11yAttribute,
        params,
      ),
    );
  },
});

const Connected = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
)(AddA11yAttribute);

Connected.pure = AddA11yAttribute;

export default Connected;

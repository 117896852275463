import React, { type FC } from 'react';

import { translate } from '#packages/i18n';
import { Text, Preloader } from '@wix/wix-base-ui';

import styles from './../styles.scss';
import { TRANSLATION_KEYS } from '../../../consts';

export const LoadingContent: FC = () => (
  <div className={styles.loadingContentWrapper}>
    <Preloader className="medium" dataHook="site-readiness-loader" />
    <Text size="tiny" className={styles.loaderText} shouldTranslate={false}>
      {translate(TRANSLATION_KEYS.LOADER_TEXT)}
    </Text>
  </div>
);

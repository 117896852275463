import type { ThunkAction } from 'types/redux';
import { hoc } from '#packages/util';

import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

const saveSite =
  (sourceOfStart: SaveInteractionStartedSource) =>
  (
    _dispatch: AnyFixMe,
    _getState: AnyFixMe,
    { editorAPI }: AnyFixMe,
  ): ThunkAction =>
    editorAPI.saveManager.save({ sourceOfStart });

const closePanelByName =
  (panelName: string) =>
  (
    _dispatch: AnyFixMe,
    _getState: AnyFixMe,
    { editorAPI }: AnyFixMe,
  ): ThunkAction =>
    editorAPI.panelManager.closePanelByName(panelName);

const mapDispatchToProps = { saveSite, closePanelByName };

export default hoc.connect(hoc.STORES.EDITOR_API, null, mapDispatchToProps);

// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as higherOrderComponents from '#packages/higherOrderComponents';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import mobileWizardData from './mobileWizardData.json';
import React from 'react';
import MobileWizardSingleStep from './mobileWizardSingleStep';

const selectors = stateManagement.mobile.selectors.mobileWizard;
const actions = stateManagement.mobile.actions.mobileWizard;

const TINY_MENU_POINTER = {
  type: 'MOBILE',
  id: 'TINY_MENU',
};

//TYPE WAS GENERATED, remove this line when reviewed
interface MobileWizardSelectTinyMenuProps {
  availableSkins: Array<AnyFixMe>;
  selectedSkin?: string;
  init?: FunctionFixMe;
  destroy?: FunctionFixMe;
  setTinyMenuSkin?: FunctionFixMe;
  overrideDeprecatedTinyMenuSkinsIfNeeded?: FunctionFixMe;
  changeSkin: FunctionFixMe;
}

class MobileWizardSelectTinyMenu extends React.Component<MobileWizardSelectTinyMenuProps> {
  static displayName = 'mobileWizardSelectTinyMenu';

  static propTypes = {
    availableSkins: PropTypes.array.isRequired,
    selectedSkin: PropTypes.string,
    init: PropTypes.func,
    destroy: PropTypes.func,
    setTinyMenuSkin: PropTypes.func,
    overrideDeprecatedTinyMenuSkinsIfNeeded: PropTypes.func,
    changeSkin: PropTypes.func.isRequired, // from linkStyle
  };

  componentDidMount() {
    this.props.init();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Unfortunately, this method must be here (and not in the actions, as i wanted),
    // because it have to use this.props.changeSkin, that comes from the linkStyle higherOrderComponent
    if (nextProps.changeSkin) {
      nextProps.overrideDeprecatedTinyMenuSkinsIfNeeded(nextProps.changeSkin);
    }
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  setTinyMenuSkin = (option) => {
    this.props.setTinyMenuSkin(this.props.changeSkin, option.value);
  };

  getStepOptions = () => {
    const mobileWizardTinyMenuData = mobileWizardData[TINY_MENU_POINTER.id];
    const availableSkins = _.sortBy(
      this.props.availableSkins,
      (skinName) => mobileWizardTinyMenuData[skinName].order,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(availableSkins, (skin) => {
      const { label, imageSrc, automationId } = _.get(
        mobileWizardTinyMenuData,
        skin,
      );
      return {
        value: skin,
        isSelected: this.props.selectedSkin === skin,
        label,
        imageSrc,
        automationId,
      };
    });
  };

  render() {
    return (
      <MobileWizardSingleStep
        headerLabel="Mobile_Wizard_Welcome_Intro_Title"
        actionToTakeLabel="Mobile_Wizard_Welcome_Intro_Line"
        automationId="mobile-wizard-menu-step"
        options={this.getStepOptions()}
        onOptionSelected={this.setTinyMenuSkin}
      />
    );
  }
}

const stateToProps = ({ state }) => ({
  selectedComponent: TINY_MENU_POINTER,
  availableSkins: selectors.tinyMenu.getAvailableSkins(state),
  selectedSkin:
    selectors.tinyMenu.getSelectedSkin(state) ||
    selectors.getLastSaved(state, 'tinyMenu'),
});

const dispatchToProps = (dispatch) => ({
  init: () => dispatch(actions.initTinyMenuStep()),
  destroy: () =>
    dispatch(actions.setPreviewState(TINY_MENU_POINTER.id, undefined)),
  setTinyMenuSkin: (...args) => dispatch(actions.setSelectedTinyMenu(...args)),
  overrideDeprecatedTinyMenuSkinsIfNeeded: (changeSkinMethod) =>
    dispatch(actions.overrideDeprecatedTinyMenuSkinsIfNeeded(changeSkinMethod)),
});

const connected = _.flow(
  higherOrderComponents.linkStyle,
  util.hoc.connect(util.hoc.STORES.STATE_ONLY, stateToProps, dispatchToProps),
)(MobileWizardSelectTinyMenu);

connected.pure = MobileWizardSelectTinyMenu;
export default connected;

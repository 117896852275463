import { events } from '#packages/coreBi';
import { AddPresetApiKey } from '#packages/apis';
import { bi } from '#packages/stateManagement';
import type { MediaManagerOptions } from '@wix/add-panel-component';
import { openMediaManager } from '../../../utils/mediaManager';
import { BI_ORIGIN } from '../../constants';
import { mediaManagerCallback } from '../../mediaManagerUtils';
import type { MapDispatchToProps } from 'types/redux';

export interface OpenMapperProps {
  openAccountSettings: (params: {
    path: string;
    itemId: string;
    categoryId: string;
    sectionTitle: string;
  }) => void;
  openMediaManager: (props: {
    categoryId: string;
    sectionName: string;
    sectionTitle: string;
    biItemName?: string;
    mediaManagerOptions: MediaManagerOptions;
  }) => void;
  openAddSectionPanelSavedCategory: (origin: string) => void;
}

export const openMapper: MapDispatchToProps<OpenMapperProps, any> = (
  dispatch,
) => {
  const editorAPI = dispatch(
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI,
  );

  const { mediaManager } = editorAPI.mediaServices;

  return {
    openAccountSettings: ({ path, itemId, categoryId, sectionTitle }) => {
      editorAPI.account.openSettings({ path });

      dispatch(
        bi.actions.event(events.addPanel.ADD_MENU_DRAG_COMPONENT, {
          preset_id: itemId,
          category: categoryId,
          section: sectionTitle,
        }),
      );
    },

    openMediaManager: ({
      biItemName,
      categoryId,
      sectionName,
      sectionTitle,
      mediaManagerOptions,
    }) => {
      const MEDIA_MANAGER_ORIGIN = `${BI_ORIGIN}_${categoryId}_${sectionName}${
        biItemName ? `_${biItemName}` : ''
      }`;

      if (biItemName) {
        // TODO: make it more explicit
        // Sends BI from Image category items, because they have biItemName
        dispatch(
          bi.actions.event(
            events.addPanel.ADD_MENU_IMAGE_SECTION_BUTTON_CLICK,
            {
              section: sectionName,
              sub_section: biItemName,
            },
          ),
        );
      } else {
        dispatch(
          bi.actions.event(events.addPanel.ADD_MENU_MORE_CLICK, {
            category: categoryId,
            section: sectionName,
          }),
        );
      }

      openMediaManager(
        editorAPI,
        mediaManager.categories[mediaManagerOptions.categoryName],
        MEDIA_MANAGER_ORIGIN,
        { multiSelect: true, path: mediaManagerOptions.path },
      ).then(({ items, info }) => {
        mediaManagerCallback(editorAPI, {
          items,
          info,
          sectionTitle,
          categoryId,
        });
      });
    },

    openAddSectionPanelSavedCategory: (origin) => {
      editorAPI.host
        .getAPI(AddPresetApiKey)
        .sections.openSavedSectionsCategory({
          origin,
        });
    },
  };
};

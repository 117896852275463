import { createInteractionsBI } from './createInteractionsBI';
import { createInteractionsStore } from './createInteractionsStore';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type {
  SelectComponentsByClickInterceptor,
  SelectComponentsByRefInterceptor,
} from '../types';
import type { SelectionHooks } from '../createSelectionHooks';

export function createInteractionsApi({
  editorAPI,
  selectionHooks,
}: {
  editorAPI: EditorAPI;
  selectionHooks: SelectionHooks;
}) {
  const interactionsStore = createInteractionsStore({ editorAPI });
  const interactionsBi = createInteractionsBI({
    editorAPI,
    interactionsStore,
  });

  function shouldExitInteractionModeOnClick(compRef: CompRef): boolean {
    const triggerRef = interactionsStore.getInteractionTriggerRef();

    if (interactionsStore.isInteractionPlaying()) return false;
    if (!compRef) {
      return true;
    }
    if (editorAPI.components.isDescendantOfComp(compRef, triggerRef))
      return false;
    return triggerRef.id !== compRef.id;
  }

  function isStopInteractionPreviewNeeded(compRef: CompRef) {
    const triggerRef = interactionsStore.getInteractionTriggerRef();

    if (!compRef) return true;
    if (editorAPI.components.isDescendantOfComp(compRef, triggerRef))
      return false;
    if (triggerRef.id === compRef.id) return false;
    return interactionsStore.isInteractionPlaying();
  }

  function exitInteractionMode() {
    interactionsStore.exitInteraction();
    interactionsBi.exitInteractionMode();
  }

  const selectComponentByClickInterceptor: SelectComponentsByClickInterceptor =
    ({ compToBeSelected }, { cancel }) => {
      const isInteractionMode = interactionsStore.isInInteractionMode();
      if (
        isInteractionMode &&
        interactionsStore.showInteractionModeControls()
      ) {
        if (shouldExitInteractionModeOnClick(compToBeSelected)) {
          exitInteractionMode();
          cancel();
          return;
        }

        if (isStopInteractionPreviewNeeded(compToBeSelected)) {
          interactionsStore.stopPlayInteraction(
            interactionsStore.getInteractionTriggerRef(),
          );
          cancel();
          return;
        }
      }
    };

  const selectComponentsByRefInterceptor: SelectComponentsByRefInterceptor = (
    { compsToBeSelected },
    { update },
  ) => {
    if (
      interactionsStore.isInInteractionMode() &&
      interactionsStore.showInteractionModeControls()
    ) {
      const compToBeSelected = compsToBeSelected[0];
      const triggerRef = interactionsStore.getInteractionTriggerRef();
      if (
        !compToBeSelected ||
        (!editorAPI.dsRead.components.isDescendantOfComp(
          compToBeSelected,
          triggerRef,
        ) &&
          triggerRef.id !== compToBeSelected.id)
      ) {
        compsToBeSelected = [triggerRef];
      }
      interactionsStore.onInteractionSelectedComponentsChanged(
        compsToBeSelected[0],
      );

      update({
        compsToBeSelected,
      });
    }
  };

  selectionHooks.selectComponentByCompClickInterceptor.tap(
    selectComponentByClickInterceptor,
  );
  selectionHooks.selectComponentByCompRefInterceptor.tap(
    selectComponentsByRefInterceptor,
  );

  return {
    getInteractionTriggerRef: interactionsStore.getInteractionTriggerRef,
    isInInteractionMode: interactionsStore.isInInteractionMode,
    showInteractionModeControls: interactionsStore.showInteractionModeControls,
    getFirstAncestorWithInteraction:
      interactionsStore.getFirstAncestorWithInteraction,
  };
}

export type InteractionsApi = ReturnType<typeof createInteractionsApi>;

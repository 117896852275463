import React from 'react';

import { workspace, hoc } from '#packages/util';

import {
  mapDispatchToProps,
  type OutOfGridMobilePanelDispatchProps,
} from './outOfGridMobilePanelMapper';
import {
  NewWorkspaceOutOfGridMobilePanelFrame,
  OutOfGridMobilePanelFrame,
} from './outOfGridMobilePanelFrame';

export interface OutOfGridMobilePanelOwnProps {
  panelName: string;
}

interface OutOfGridMobilePanelProps
  extends OutOfGridMobilePanelOwnProps,
    OutOfGridMobilePanelDispatchProps {}

const OutOfGridMobilePanel: React.FC<OutOfGridMobilePanelProps> = (props) => {
  const PanelFrame = workspace.isNewWorkspaceEnabled()
    ? NewWorkspaceOutOfGridMobilePanelFrame
    : OutOfGridMobilePanelFrame;

  return (
    <PanelFrame
      panelName={props.panelName}
      onLearnMoreClick={props.onLearnMoreClick}
    />
  );
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(OutOfGridMobilePanel);

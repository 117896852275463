import * as coreBi from '#packages/coreBi';
import { bi } from '#packages/stateManagement';
import * as textControls from '#packages/textControls';
import { BlocksApiKey } from '#packages/apis';

import type { EditorAPI } from '#packages/editorAPI';
import type { Dispatch, ThunkAction } from 'types/redux';
import type { TextManager, CmdState } from 'types/data';

export interface WithCKEditorOwnProps {
  textManager?: TextManager;
  cmdState: CmdState;
  selectedCmdState: CmdState;
  isEditingRepeatedTextComp: boolean;
  panelName: string;
  defaultBiParams: Record<string, unknown>;
}

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

export const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: WithCKEditorOwnProps,
) => {
  const execCommand = (
    commandName: string,
    params?: AnyFixMe,
    cmdOptions?: AnyFixMe,
    isMouseOut?: boolean,
  ) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    textControls.textEditingUtils.execCommand(
      editorAPI,
      commandName,
      params,
      cmdOptions,
      isMouseOut,
      ownProps.isEditingRepeatedTextComp,
    );
  };

  const execCommandWithoutFocus = (cmdName: AnyFixMe, value: AnyFixMe) => {
    execCommand(cmdName, value, { execWithoutFocus: true });
  };

  const execListCommand = (newListType: any, cmdState: CmdState) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    textControls.textEditingUtils.execListCommand(
      editorAPI,
      newListType,
      execCommand,
      cmdState,
    );
  };

  const focusCkEditor = () => {
    ownProps.textManager.focus();
  };

  const getFormatBlockCss = (cmdState = ownProps.cmdState) =>
    cmdState.formatBlock?.class || 'font_8';

  const sendTextBiEvent = (
    biId: keyof typeof coreBi.events.textControls,
    fields: AnyFixMe,
  ) => {
    dispatch(bi.actions.event(coreBi.events.textControls[biId], fields));
  };

  const sendTextBiEventWithPanelName = (biId: AnyFixMe, fields?: AnyFixMe) => {
    sendTextBiEvent(biId, { panel_name: ownProps.panelName, ...fields });
  };

  const sendTextBiEventWithDefaultParams = (
    biId: AnyFixMe,
    fields?: AnyFixMe,
  ) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    const biParams = editorAPI.host.getAPI(BlocksApiKey).getBlocksBiData();

    sendTextBiEvent(biId, {
      ...ownProps.defaultBiParams,
      ...fields,
      ...biParams,
    });
  };

  const sendTextBiEventWithPanelNameAndDefaultParams = (
    biId: AnyFixMe,
    fields?: AnyFixMe,
  ) => {
    sendTextBiEvent(biId, {
      panel_name: ownProps.panelName,
      ...ownProps.defaultBiParams,
      ...fields,
    });
  };

  return {
    execCommand,
    execCommandWithoutFocus,
    execListCommand,
    focusCkEditor,
    getFormatBlockCss,
    refreshPropsCollector: () => ownProps.textManager.refreshPropsCollector(),

    // bi
    sendTextBiEvent,
    sendTextBiEventWithPanelName,
    sendTextBiEventWithDefaultParams,
    sendTextBiEventWithPanelNameAndDefaultParams,
  };
};

export type WithCKEditorConnectProps = ReturnType<typeof mapDispatchToProps>;

import * as util from '#packages/util';
import getSuperApp from './superApp';
import React from 'react';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';

import { mapStateToProps, mapDispatchToProps } from './wixDataPanel.mapper';
import {
  getFormsMigrationTooltip,
  useFormsMigrationState,
} from './formCollectionsMigration';
import type {
  WixDataPanelStateProps,
  WixDataPanelDispatchProps,
  WixDataPanelPropsOwnProps,
} from './wixDataPanel.mapper';

const TOOLTIP_SEEN_KEY = '6btnPanel.formsMigration.ToooltipSeen';
const HELPER_SEEN_KEY = '6btnPanel.formsMigration.HelperSeen';

const TABS_WIDTH = 188;

const CONTENT_WIDTH = 408;
interface WixDataPanelProps
  extends WixDataPanelPropsOwnProps,
    WixDataPanelStateProps,
    WixDataPanelDispatchProps {}

const WixDataPanel: React.FC<WixDataPanelProps> = (props) => {
  const {
    showUpgradeToPremiumTab,
    hasDynamicPages,
    hasCollections,
    hasWixAppsCollections,
    hasFormsCollections,
    hasExternalCollections,
    isLoading,
    isDataModeOn,
    sendBi,
    isPremium,
    userId,
  } = props;

  const [haveSeenNotification, setHaveSeenNotification] =
    useFormsMigrationState({
      userId,
      preferencesKey: TOOLTIP_SEEN_KEY,
    });

  const formsMigrationHelperState = useFormsMigrationState({
    userId,
    preferencesKey: HELPER_SEEN_KEY,
  });

  const formsMigrationTooltip = getFormsMigrationTooltip({
    hasFormsCollections,
    isPremium,
    haveSeenNotification,
    setHaveSeenNotification,
    sendBi,
  });

  return (
    <superAppMenuInfra.superAppPanel
      {...props}
      panelClass="wix-data-panel"
      superApp={getSuperApp({
        showUpgradeToPremiumTab,
        hasDynamicPages,
        hasCollections,
        hasWixAppsCollections,
        hasFormsCollections,
        hasExternalCollections,
        isLoading,
        isDataModeOn,
        formsMigrationTooltip,
        formsMigrationHelperState,
      })}
      tabsWidth={TABS_WIDTH}
      contentWidth={CONTENT_WIDTH}
    />
  );
};

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(WixDataPanel);

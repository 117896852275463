import type { ThemedActionCreationData } from '../utils/textAction';
import { constants as textControlsConstants } from '#packages/textControls';
import { createThemedAction } from '../utils/themedAction';

const normalizeFontSize = (fontSizeNumber: number) => `${fontSizeNumber}px`;

const fontSizeActionCreationData: Readonly<ThemedActionCreationData<string>> = {
  execCommand: (textManager, fontSize) => {
    textManager.commands.fontSize(fontSize);
  },
  getWixRichTextCommandState: (textManager) => {
    const fontSize = textManager.getCommandsStates().fontSize;
    if (fontSize === textControlsConstants.CK_OFF) {
      return;
    }

    return normalizeFontSize(fontSize);
  },
  execRevertCommand: (textManager) =>
    textManager.commands.fontSize('', { execWithoutFocus: true }),
  createUpdatedStyleProperties: (fontSize) => ({ fontSize }),
  getPropertyValue: (styleProperties) => styleProperties.fontSize,
  getThemeValue: (theme) => theme && theme.size,
};

export const fontSizeAction = createThemedAction(fontSizeActionCreationData);

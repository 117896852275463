// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import mobileSettingsMixin from './mobileSettingsMixin';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import * as baseUI from '#packages/baseUI';
import {
  Composites,
  CustomScroll,
  Divider,
  TextInput,
  ToggleSwitch,
} from '@wix/wix-base-ui';
import MobileElementsSettingsHeader from './mobileElementsSettingsHeader/mobileElementsSettingsHeader';
import { cx } from '#packages/util';

const mobilePreviewActiveImg =
  'mobileEditor/mobileSettings/mobilePreviewActive.png';
const mobilePreviewDisabledImg =
  'mobileEditor/mobileSettings/mobilePreviewDisable.png';
const actionMenuPreviewImg =
  'mobileEditor/mobileSettings/mobilePreviewQuickActionButtons.png';

const SUB_PANEL_NAME = 'panels.toolPanels.mobileActionBarSubPanel';
const SOCIAL_LINKS = 'socialLinks';

const INPUT_MAX_LENGTH = 256;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'mobileSettingsActionBar',
  mixins: [mobileSettingsMixin, core.mixins.editorAPIMixin],
  propTypes: {
    id: PropTypes.string,
    helpId: PropTypes.string,
  },
  getInitialState() {
    return {
      shouldInputFocus: false,
    };
  },
  goToPublishHelp() {
    const publishHelpId = 'c61857c1-9b9f-48f8-ae8b-c46c74427ec8';
    const biHelpParams = {
      component: 'publish_your_site',
      learn_more: true,
      panel_name: this.props.id,
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
    };
    this.getEditorAPI().panelManager.openHelpCenter(
      publishHelpId,
      null,
      biHelpParams,
    );
  },
  openHelpCenter() {
    const biHelpParams = {
      learn_more: true,
      panel_name: this.props.id,
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
    };
    this.getEditorAPI().panelManager.openHelpCenter(
      this.props.helpId,
      null,
      biHelpParams,
    );
  },
  getActions() {
    return [
      {
        id: 'navigationMenu',
        label: 'mobile_action_bar_menu_label',
        symbol: 'mba_menu_bold',
        isForm: false,
      },
      {
        id: 'phone',
        label: 'mobile_action_bar_phone_label',
        symbol: 'mba_phone_bold',
        validators: [util.validate.notEmpty, util.validate.phone],
        invalidMessage: 'Validation_V3_Numeric_Input',
        isForm: true,
      },
      {
        id: 'email',
        label: 'mobile_action_bar_email_label',
        symbol: 'mba_envelope_bold',
        validators: [util.validate.notEmpty, util.validate.email],
        invalidMessage: 'Validation_V1_Email',
        isForm: true,
      },
      {
        id: 'address',
        label: 'mobile_action_bar_address_label',
        symbol: 'mba_map_bold',
        isForm: true,
      },
      {
        id: 'socialLinks',
        label: 'mobile_action_bar_social_label',
        symbol: 'mba_social_bar_bold',
        isForm: false,
      },
    ];
  },
  toggleAction(actionId) {
    const actionsAPI = this.getEditorAPI().mobile.actionBar.actions;
    const enabledActions = actionsAPI.getEnabled();
    const actionData = actionsAPI.get()[actionId];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const { isForm } = _.find(this.getActions(), { id: actionId });
    const defaultActionValue = this.getDefaultActionValue(actionId);
    const actionSetToDefault = actionData === defaultActionValue;
    const setting = {};
    const isSocialLinks = actionId === SOCIAL_LINKS;
    let areThereSocialLinks = false;
    let shouldInputFocus = false;

    enabledActions[actionId] = !enabledActions[actionId];

    this.getEditorAPI().bi.event(
      coreBi.events.mobileEditor.mobileTools.MOBILE_ACTION_BAR_COMPONENT_TOGGLE,
      {
        toggle_name: actionId,
        toggle_status: enabledActions[actionId] ? 'on' : 'off',
        site_id: this.getEditorAPI().dsRead.generalInfo.getSiteId(),
      },
    );

    if (isForm) {
      if (enabledActions[actionId]) {
        setting[actionId] = actionData || defaultActionValue;
        actionsAPI.update(setting);
      } else if (actionSetToDefault) {
        setting[actionId] = '';
        actionsAPI.update(setting);
      }

      shouldInputFocus = enabledActions[actionId];
    } else if (isSocialLinks && enabledActions[actionId]) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/values
      areThereSocialLinks = _(actionData).values().compact().value().length > 0;
      if (!areThereSocialLinks) {
        enabledActions[actionId] = false;
      }
      this.openPanel();
    }

    this.setState({ shouldInputFocus });

    actionsAPI.enable(enabledActions);
  },
  isActionEnabled(actionId) {
    const enabledActions =
      this.getEditorAPI().mobile.actionBar.actions.getEnabled();
    return enabledActions[actionId];
  },
  getDefaultActionValue(actionId) {
    return translate(`mobile_action_bar_${actionId}_default`);
  },
  getPlaceholder(actionId) {
    return `mobile_action_bar_${actionId}_placeholder`;
  },
  changeActionData(actionId, newActionData) {
    const setting = {};
    setting[actionId] = newActionData;
    this.getEditorAPI().mobile.actionBar.actions.update(setting);
  },
  handleBlur(actionId, e) {
    const actionBarAPI = this.getEditorAPI().mobile.actionBar;
    const actionsAPI = actionBarAPI.actions;
    const settings = {};

    if (!e.target.value && actionBarAPI.isEnabled()) {
      settings[actionId] = false;
      actionsAPI.enable(settings);
    }
  },
  getActionValueLink(actionId) {
    const isCurrentActionEnabled = this.isActionEnabled(actionId);

    return {
      value: isCurrentActionEnabled,
      requestChange: this.toggleAction.bind(this, actionId),
    };
  },
  getActionDataValueLink(actionId) {
    const actionsData = this.getEditorAPI().mobile.actionBar.actions.get();

    return {
      value: actionsData[actionId],
      requestChange: this.changeActionData.bind(this, actionId),
    };
  },
  getColorSchemeValueLink() {
    const colorSchemeAPI = this.getEditorAPI().mobile.actionBar.colorScheme;

    return {
      value: colorSchemeAPI.get(),
      requestChange: colorSchemeAPI.set,
    };
  },
  getColorSchemeOptions() {
    return [
      { label: 'mobile_action_bar_colors_dark', value: 'dark' },
      { label: 'mobile_action_bar_colors_light', value: 'light' },
    ];
  },
  openPanel() {
    if (this.isActionBarEnabled()) {
      this.getEditorAPI().panelManager.openPanel(
        SUB_PANEL_NAME,
        {
          top: 194,
          left: 666,
        },
        true,
      );
    }
  },
  isActionBarEnabled() {
    return this.getEditorAPI().mobile.actionBar.isEnabled();
  },
  getMobileActionBarDevicePreview() {
    const imagePath = this.isActionBarEnabled()
      ? mobilePreviewActiveImg
      : mobilePreviewDisabledImg;
    return `url('${util.media.getMediaUrl(imagePath)}')`;
  },
  getMobileActionBarMenuPreview() {
    return `url('${util.media.getMediaUrl(actionMenuPreviewImg)}')`;
  },
  getMobileActionBarMenuPreviewPosition() {
    const actionBarAPI = this.getEditorAPI().mobile.actionBar;
    const actions = actionBarAPI.actions.getEnabled();
    const isColorSchemeLight = actionBarAPI.colorScheme.get() === 'light';

    const actionValues = {
      navigationMenu: 16,
      phone: 8,
      email: 4,
      address: 2,
      socialLinks: 1,
    };

    const colorSchemeValue = isColorSchemeLight * 32;

    const enabledActionSumValue = _(actions)
      .pickBy()
      .map((val, key) => actionValues[key])
      .sum();

    const NUM_OF_SPRITES = 64;
    const backgroundPositionY =
      ((enabledActionSumValue + colorSchemeValue) / (NUM_OF_SPRITES - 1)) * 100;
    return `50% ${backgroundPositionY}%`;
  },
  isSubPanelOpen(actionId) {
    const openPanels = this.getEditorAPI().panelManager.getOpenPanels();
    const isSocialLinks = actionId === SOCIAL_LINKS;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/some
    const socialLinkIsOpen = _.some(openPanels, { name: SUB_PANEL_NAME });

    return isSocialLinks && socialLinkIsOpen;
  },
  getMaxLength() {
    return INPUT_MAX_LENGTH;
  },
  getSymbolElement(symbol) {
    return React.createElement(symbols.symbol, {
      name: symbol,
    });
  },
  render() {
    return (
      <div
        className={cx({
          'mobile-element-settings': true,
          'action-bar-settings': true,
        })}
      >
        <MobileElementsSettingsHeader
          title="mobile_action_bar_title"
          helpId={this.props.helpId}
          minimize={this.props.onClick}
        />
        <div className="content">
          <div className="category-desc">
            <p className="text">
              {translate('mobile_action_bar_text')}{' '}
              <a onClick={this.openHelpCenter} className="learn-more">
                {translate('mobile_action_bar_text_link')}
              </a>
            </p>
          </div>
          <ToggleSwitch
            value={this.getValueLink().value}
            label="mobile_action_bar_toggle_label"
            onChange={this.getValueLink().requestChange}
          />
          <br />

          <div className="action-bar-main-content">
            <div className="action-bar-menu-wrapper">
              <div className="action-bar-menu-title">
                {translate('mobile_action_bar_section_label')}
              </div>
              <div className="action-bar-menu">
                <CustomScroll
                  ref="customScroll"
                  heightRelativeToParent="100%"
                  addScrolledClass={true}
                >
                  <ul>
                    {/* TODO: Fix this the next time the file is edited. */}
                    {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                    {_.map(this.getActions(), (action) => (
                      <li
                        key={action.id}
                        className={cx({
                          'action-bar-item': true,
                          'social-media-open': this.isSubPanelOpen(action.id),
                        })}
                      >
                        {action.isForm ? (
                          <div key={action.id}>
                            <Composites.ToggleSwitchWithSelectiveInput>
                              <Composites.ToggleSwitch>
                                <ToggleSwitch
                                  value={
                                    this.getActionValueLink(action.id).value
                                  }
                                  label={action.label}
                                  onChange={
                                    this.getActionValueLink(action.id)
                                      .requestChange
                                  }
                                  symbol={this.getSymbolElement(action.symbol)}
                                  disabled={!this.isActionBarEnabled()}
                                />
                              </Composites.ToggleSwitch>
                              {this.getActionValueLink(action.id).value ? (
                                <Composites.TextInput key={`${action.id}2`}>
                                  <TextInput
                                    value={
                                      this.getActionDataValueLink(action.id)
                                        .value
                                    }
                                    onChange={
                                      this.getActionDataValueLink(action.id)
                                        .requestChange
                                    }
                                    validator={action.validators}
                                    invalidMessage={action.invalidMessage}
                                    placeholder={this.getPlaceholder(action.id)}
                                    disabled={!this.isActionBarEnabled()}
                                    onBlur={this.handleBlur.bind(
                                      this,
                                      action.id,
                                    )}
                                    maxLength={this.getMaxLength()}
                                    focus={this.state.shouldInputFocus}
                                  />
                                </Composites.TextInput>
                              ) : null}
                            </Composites.ToggleSwitchWithSelectiveInput>

                            <Divider />
                          </div>
                        ) : null}
                        {!action.isForm ? (
                          <div key={action.id}>
                            {action.id !== 'socialLinks' ? (
                              <Composites.ToggleSwitch key={action.id}>
                                <ToggleSwitch
                                  value={
                                    this.getActionValueLink(action.id).value
                                  }
                                  onChange={
                                    this.getActionValueLink(action.id)
                                      .requestChange
                                  }
                                  symbol={this.getSymbolElement(action.symbol)}
                                  disabled={!this.isActionBarEnabled()}
                                  label={action.label}
                                />
                              </Composites.ToggleSwitch>
                            ) : null}

                            {action.id !== 'socialLinks' ? (
                              <Divider key={`${action.id}_devider`} />
                            ) : null}

                            {action.id === 'socialLinks' ? (
                              <Composites.ToggleSwitch key={action.id}>
                                <ToggleSwitch
                                  value={
                                    this.getActionValueLink(action.id).value
                                  }
                                  onChange={
                                    this.getActionValueLink(action.id)
                                      .requestChange
                                  }
                                  symbol={this.getSymbolElement(action.symbol)}
                                  disabled={!this.isActionBarEnabled()}
                                  label={action.label}
                                  onCheck={this.openPanel}
                                />
                              </Composites.ToggleSwitch>
                            ) : null}
                          </div>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                </CustomScroll>
              </div>
              <div className="action-bar-color-scheme">
                <div className="action-bar-color-scheme-picker">
                  <div className="color-scheme-label">
                    {translate('mobile_action_bar_colors_label')}
                  </div>
                  <baseUI.buttonsGroup
                    disabled={!this.isActionBarEnabled()}
                    options={this.getColorSchemeOptions()}
                    valueLink={this.getColorSchemeValueLink()}
                  />
                </div>
                <div
                  style={{
                    backgroundImage: this.getMobileActionBarDevicePreview(),
                  }}
                  className="action-bar-preview"
                >
                  {this.isActionBarEnabled() ? (
                    <div
                      key="actionBarMenuPreview"
                      style={{
                        backgroundImage: this.getMobileActionBarMenuPreview(),
                        backgroundPosition:
                          this.getMobileActionBarMenuPreviewPosition(),
                      }}
                      className="action-bar-menu-preview"
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="action-bar-footer">
            <span>{translate('mobile_action_bar_bottom_text')}</span>
            <span onClick={this.goToPublishHelp} className="action-bar-publish">
              {translate('mobile_action_bar_bottom_link')}
            </span>
          </div>
        </div>
      </div>
    );
  },
});

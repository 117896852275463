import _ from 'lodash';
import React from 'react';
import {
  Composites,
  TextLabel,
  PromotionalList,
  Button,
} from '@wix/wix-base-ui';

import addPanelDataConsts from '#packages/addPanelDataConsts';
import { translate } from '#packages/i18n';
import * as util from '#packages/util';
import {
  mapDispatchToProps,
  type WelcomeSectionDispatchProps,
} from './welcomeSectionMapper';

export interface WelcomeSectionProps {
  bannerImgSrc: string;
  title: string;
  subtitle: string;
  automationId?: string;
  promotionalListItems?: string[];
  numericListItems?: string[];
  mainActionLabel?: string;
  onMainActionClick?: () => void;
  secondaryActionLabel?: string;
  onSecondaryActionClick?: () => void;
  appDefinitionId?: string;
  helpId?: string;
}

class WelcomeSection extends React.Component<
  WelcomeSectionProps & WelcomeSectionDispatchProps
> {
  static displayName = 'WelcomeSection';

  onMainActionClicked = () => {
    if (this.props.onMainActionClick) {
      this.props.onMainActionClick();
    } else if (this.props.appDefinitionId && this.props.installApp) {
      this.props.installApp(this.props.appDefinitionId).then(() => {
        this.props.notifyApplication(
          this.props.appDefinitionId,
          addPanelDataConsts.CLICK_ACTION.EVENT
            .WELCOME_SECTION_MAIN_ACTION_CLICKED,
        );
        this.props.closeAddPanel();
      });
    }
  };

  onSecondaryActionClicked = () => {
    if (this.props.onSecondaryActionClick) {
      this.props.onSecondaryActionClick();
    } else if (this.props.appDefinitionId) {
      this.props.openApplicationHelpPanel(this.props.helpId);
    }
  };

  render() {
    return (
      <div
        data-automation-id={this.props.automationId}
        className="add-panel-welcome-section"
      >
        <img src={this.props.bannerImgSrc} className="banner" />

        <div className="title-container">
          <TextLabel
            automationId="welcome_title"
            enableEllipsis={false}
            type="T16"
            value={this.props.title}
            className="title"
          />
          <TextLabel
            type="T11"
            automationId="welcome_subtitle"
            enableEllipsis={false}
            value={this.props.subtitle}
          />
        </div>

        {this.props.promotionalListItems ? (
          <Composites.PromotionalList key="promotional-list">
            <PromotionalList
              items={this.props.promotionalListItems}
              automationId="welcome_promotional_list"
            />
          </Composites.PromotionalList>
        ) : null}

        {this.props.numericListItems ? (
          <div key="numeric-list" className="composite-promotional-list">
            <div className="control-promotional-list control-promotional-list_numeric">
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.props.numericListItems, (item, itemIndex) => (
                <div key={item} className="promotional-list-item">
                  <TextLabel
                    value={`${itemIndex + 1}.`}
                    type="T05"
                    enableEllipsis={false}
                    shouldTranslate={false}
                  />
                  <TextLabel value={item} type="T05" enableEllipsis={false} />
                </div>
              ))}
            </div>
          </div>
        ) : null}

        <Composites.ActionSetVertical>
          {this.props.mainActionLabel ? (
            <Button
              key="main-action"
              automationId="welcome_main_action"
              onClick={this.onMainActionClicked}
            >
              <span>{translate(this.props.mainActionLabel)}</span>
            </Button>
          ) : null}
          {this.props.secondaryActionLabel ? (
            <Button
              key="second-action"
              automationId="welcome_second_action"
              onClick={this.onSecondaryActionClicked}
              className="btn-text"
            >
              <span>{translate(this.props.secondaryActionLabel)}</span>
            </Button>
          ) : null}
        </Composites.ActionSetVertical>
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(util.hoc.renderWhenMutated(WelcomeSection));

import { AssistantAction, type IAssistantTask } from '@wix/tour-maker';
import { EditorAPIKey } from '#packages/apis';
import constants from '#packages/constants';
import { getStepName } from '../utils/getStepName';
import { HOOKS } from './constants';

import type { Shell } from '#packages/apilib';

export const TOUR_NAME = 'helpTour';

export const createHelpTour = (shell: Shell): IAssistantTask => {
  const editorAPI = shell.getAPI(EditorAPIKey);

  const helpAction = new AssistantAction('help')
    .thatAttachesTo()
    .hook(HOOKS.helpDropDown)
    .and.withCallbacks()
    .onEnter(async () => {
      editorAPI.topBarMenuBar.openDropDown(
        constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
        constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS.HELP_WELCOME_TOUR,
      );
    })
    .onExit(() => {
      editorAPI.topBarMenuBar.closeDropDown(
        constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
      );
    })
    .and.withBackdrop()
    .thatIsOn()
    .and.withTooltip()
    .withTitle('First-Time-Tour-End-Title')
    .withContent('First-Time-Tour-End-Text1')
    .withCustomProp('stepName', getStepName(TOUR_NAME, 'help', 0))
    .withCustomProp('sendBi', editorAPI.bi.event)
    .withCustomProp('className', 'tour-tooltip-opens-right')
    .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top') // EditorX's animation | 'fix' for broken first step animation
    .thatOpensRight({ offsetX: 24, offsetY: 7 })
    .withButton('forwardButton')
    .label('First-Time-Tour-End-GotIt-Button')
    .completeOnClick()
    .and.withCustomStage()
    .withAdditionalHighlight(HOOKS.helpMenuBarItem)
    .build();

  return {
    id: TOUR_NAME,
    onEnter: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: false });
        editorAPI.panelManager.closeAllPanels();
      },
    },
    onExit: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: true }, true);
      },
    },
    actions: [helpAction],
  } as IAssistantTask;
};

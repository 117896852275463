// @ts-nocheck
import * as baseUI from '#packages/baseUI';
import * as core from '#packages/core';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import React from 'react';
import * as superAppBi from '../../../bi/superAppBi';
import button from '../../../utils/button';
import InnerImageGallery from './innerImageGallery/innerImageGallery';

const minimumInstallationDuration = 2000;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'InstallAppSlide',
  propTypes: {
    onClose: PropTypes.func.isRequired,
    nextSlide: PropTypes.func.isRequired,
    installApp: PropTypes.func.isRequired,
    superAppPanelName: PropTypes.string.isRequired,
    superAppButtonLabel: PropTypes.string.isRequired,
    superAppPanelClassName: PropTypes.string.isRequired,
    superAppSymbolName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    pros: PropTypes.arrayOf(PropTypes.string).isRequired,
    teaser: PropTypes.string,
    footer: PropTypes.string,
    upgradeLink: PropTypes.string,
    nextButtonLabel: PropTypes.string.isRequired,
    openAppMarketLabel: PropTypes.string.isRequired,
    openAppMarket: PropTypes.func.isRequired,
    loadingLabel: PropTypes.string.isRequired,
    sliderImages: PropTypes.array,
    installImage: PropTypes.string.isRequired,
    bannerImgSrc: PropTypes.string,
    isNextStepOpeningBackOffice: PropTypes.bool,
  },
  mixins: [core.mixins.editorAPIMixin],
  getInitialState() {
    return {
      isInstalling: false,
      installationDone: false,
      minimumInstallationDurationPassed: false,
    };
  },
  installApp() {
    const superAppProps = this.props.superApp;
    const params = {
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
      origin: this.props.origin,
    };
    this.getEditorAPI().bi.event(
      superAppBi.events.firstTimeTour.promotional.INSTALL_AGREE_CLICK,
      params,
    );

    this.setState({
      isInstalling: true,
    });
    this.props.installApp(this._appInstalled);
    if (this.props.superAppPanelName !== 'wixStore') {
      window.setTimeout(
        this._minimumInstallationDurationPassed,
        minimumInstallationDuration,
      );
    }
  },
  _appInstalled() {
    this._installationStepCompleted('installationDone');
    button.onAppInstalled(
      this.getEditorAPI(),
      this.props.superApp.id,
      this.props.superAppPanelName,
      this.props.superAppButtonLabel,
      this.props.superAppPanelClassName,
      this.props.superAppSymbolName,
    );
  },
  _minimumInstallationDurationPassed() {
    this._installationStepCompleted('minimumInstallationDurationPassed');
  },
  _installationStepCompleted(stepName) {
    const newState = {};
    newState[stepName] = true;
    this.safelySetState(newState);
    if (
      this.state.installationDone &&
      this.state.minimumInstallationDurationPassed
    ) {
      this.props.nextSlide();
    }
  },

  componentDidMount() {
    this._isMounted = true;
  },

  componentWillUnmount() {
    this._isMounted = false;
  },

  safelySetState(newState) {
    if (this._isMounted) {
      this.setState(newState);
    }
  },

  openAppMarket() {
    const superAppProps = this.props.superApp;
    if (superAppProps?.appDefId && superAppProps.appName) {
      const params = {
        app_id: superAppProps.appDefId,
        app_name: superAppProps.appName,
      };
      this.getEditorAPI().bi.event(
        superAppBi.events.firstTimeTour.promotional
          .INSTALL_FOUND_OUT_MORE_CLICK,
        params,
      );
    }
    this.props.openAppMarket();
  },
  onClose() {
    const superAppProps = this.props.superApp;
    const params = {
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(
      superAppBi.events.firstTimeTour.promotional.INSTALL_CLOSE_CLICK,
      params,
    );
    this.props.onClose();
  },
  openAppPremiumPackagePicker() {
    const superAppProps = this.props.superApp;
    const params = {
      origin: 'TooltipUpgradeLink',
      app_id: superAppProps.appDefId,
      app_name: superAppProps.appName,
    };
    this.getEditorAPI().bi.event(superAppBi.events.UPGRADE_CLICK, params);
    superAppProps.openAppPremiumPackagePicker(this.getEditorAPI());
  },
  render() {
    const shouldShowPreloader =
      this.state.isInstalling && !this.props.isNextStepOpeningBackOffice;
    return (
      <div className={`first-time-install-app ${this.props.wixStoreClassName}`}>
        {!this.props.wixStoreClassName ? (
          <div
            onClick={this.onClose}
            key="wixStoreClassName"
            className="close white"
          >
            <symbols.symbol
              name="headerCloseButton"
              className="headerCloseButton"
            />
          </div>
        ) : null}
        {!shouldShowPreloader ? (
          <div key="isInstalling" className="pre-install-section">
            {this.props.sliderImages ? (
              <InnerImageGallery
                key="sliderImages"
                items={this.props.sliderImages}
                autoPlay
                showBullets
              />
            ) : null}

            {this.props.bannerImgSrc ? (
              <img
                key="bannerImage"
                src={this.props.bannerImgSrc}
                className="banner"
              />
            ) : null}

            <h1 className="title">{translate(this.props.title)} </h1>

            <p className="sub-title">{translate(this.props.subTitle)}</p>

            <div>
              <ul className="tip-bullets">
                {this.props.pros.map((pro) => (
                  <li className="bullet" key={pro}>
                    <span className="bullet-symbol">
                      <symbols.symbol
                        name="cyclePickerV"
                        className="cyclePickerV"
                      />
                    </span>
                    <p className="bullet-text">{translate(pro)}</p>
                  </li>
                ))}
              </ul>
            </div>
            {this.props.teaser ? (
              <p key="helpText" className="helper-text">
                {translate(this.props.teaser)}
              </p>
            ) : null}
            <baseUI.button
              label={this.props.nextButtonLabel}
              onClick={this.installApp}
              disabled={this.state.isInstalling}
              icon="plus"
              className="promotional-add-btn"
            />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.openAppMarket();
              }}
              className="promotional-link"
            >
              {translate(this.props.openAppMarketLabel)}
            </a>
            {this.props.footer ? (
              <footer key="footer">
                <p>{translate(this.props.footer)}</p>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    //TODO mb error, check
                    this.openAppPremiumPackagePicker; //eslint-disable-line @typescript-eslint/no-unused-expressions
                  }}
                >
                  {translate(this.props.upgradeLink)}
                </a>
              </footer>
            ) : null}
          </div>
        ) : null}

        {shouldShowPreloader && !this.props.wixStoreClassName ? (
          <div key="installSection" className="install-section">
            <symbols.symbol
              name={this.props.installImage}
              className="installed-image"
            />
            <p>{translate(this.props.loadingLabel)}</p>

            <div className="install-preloader">
              <baseUI.preloader />
            </div>
          </div>
        ) : null}
      </div>
    );
  },
});

// @ts-nocheck
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as compPanelInfra from '#packages/compPanelInfra';
import * as utils from '@wix/santa-editor-utils';
import React from 'react';
import _ from 'lodash';
import PlatformPanelApplicationFrame from './PlatformPanelApplicationFrame';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { IConsumerType } from '@wix/editor-platform-host-integration';
import { ConsumerTypes } from '@wix/editor-platform-host-integration';

const { shouldWidgetHaveAddElements } = stateManagement.platform.selectors;
const { connect, measuring, STORES } = utils.hoc;

interface BannerProps {
  text: string;
  tooltipText: string;
  linkText: string;
  onLinkClick: () => void;
  shouldTranslateTooltip: boolean;
  error: boolean;
}

// eslint-disable-next-line react/prefer-es6-class
const PlatformComponentPanel = createReactClass({
  displayName: 'PlatformComponentPanel',
  mixins: [compPanelInfra.compPanelMixin],
  propTypes: {
    token: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    displayEssentialBanner: PropTypes.bool,
    selectedComponent: PropTypes.array,
    shouldHaveAddElements: PropTypes.func.isRequired,
    openAddElementsPanel: PropTypes.func.isRequired,
    getDisplayName: PropTypes.func.isRequired,
    getFirstControllableComponent: PropTypes.func.isRequired,
    isEssentialComponentsDeleted: PropTypes.func.isRequired,
    initialData: PropTypes.any,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reportMeasurements: PropTypes.func.isRequired,
    measurements: PropTypes.object.isRequired,
    showPreloader: PropTypes.bool,
    preloaderReachedTimeout: PropTypes.bool,
    scrolling: PropTypes.oneOf(['auto', 'no', 'yes']),
    helpId: PropTypes.string,
  },

  getBannerProps(): BannerProps {
    const selectedComponent = this.props.getFirstControllableComponent(
      _.head(this.props.selectedComponent),
    );
    if (
      !this.props.displayEssentialBanner ||
      !this.props.shouldHaveAddElements(selectedComponent) ||
      !this.props.isEssentialComponentsDeleted(selectedComponent)
    ) {
      return;
    }
    return {
      text: 'AppStudio_Essential_Elements_Missing_Settings_Panel_Banner',
      tooltipText: translate(
        'AppStudio_Essential_Elements_Missing_Settings_Panel_Tooltip',
        { Widget_Name: this.props.getDisplayName(selectedComponent) },
      ),
      linkText: translate(
        'AppStudio_Essential_Elements_Missing_Settings_Panel_CTA',
      ),
      onLinkClick: () => this.props.openAddElementsPanel(selectedComponent),
      shouldTranslateTooltip: false,
      error: true,
    };
  },

  getDefaultProps() {
    return {
      width: '300px',
      height: '400px',
      displayEssentialBanner: true,
    };
  },

  getPlatformConsumerType(): IConsumerType | null {
    const id = this.props.componentRef?.id;

    if (!id) {
      return null;
    }
    // eslint-disable-next-line lodash/prefer-includes
    if (id.indexOf('_r_') === -1) {
      return null;
    }

    return ConsumerTypes.PanelBuilder;
  },

  render() {
    return (
      <compPanelInfra.compPanelFrame
        title={this.props.title}
        helpId={this.props.helpId}
        noHelpBtn={!this.props.helpId}
        shouldTranslate={false}
        useNativeScroll={true}
        panelName="platformComponentPanel"
        onPatch={this.onPatch}
        bannerProps={this.getBannerProps()}
        measurements={this.props.measurements}
        reportMeasurements={this.props.reportMeasurements}
        {...this.getFrameProps()}
        style={{
          width: this.props.width,
          ...this.getFrameProps()?.style,
        }}
        headerButtons={this.props.headerButtons}
        onBackClick={
          this.props.headerButtons?.back?.visible
            ? this.props.onBackClick
            : undefined
        }
      >
        <PlatformPanelApplicationFrame
          token={this.props.token}
          url={this.props.url}
          initialData={this.props.initialData}
          panelClass="platformComponentPanel"
          width="100%"
          height={this.props.height}
          maxHeight={this.props.measurements.allowedContentHeight}
          showPreloader={this.props.showPreloader}
          scrolling={this.props.scrolling}
          preloaderReachedTimeout={this.props.preloaderReachedTimeout}
          platformConsumerType={this.getPlatformConsumerType()}
          appDefinitionId={this.props.appDefinitionId}
          useEditorContext={this.props.useEditorContext}
        />
      </compPanelInfra.compPanelFrame>
    );
  },
});

const getEditorAPI = (
  dispatch,
  getState,
  { editorAPI }: DispatchMapperArgs,
): EditorAPI => editorAPI;

interface ControllerConnection {
  componentRef: CompRef;
  connection: object;
}

const getEssentialConnectionRoles = (connections): string[] =>
  _(connections)
    .pickBy({ behavior: { essential: { enabled: true } } })
    .keys()
    .reject((role) => role === '*')
    .value();

const isEssentialRoleMissingFromStage = (
  existingComponentsConnections: ControllerConnection[],
  essentialRoles: string[],
): boolean => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const existingConnections = _.map(
    existingComponentsConnections,
    'connection.role',
  );

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(
    essentialRoles,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    (role) => !_.includes(existingConnections, role),
  );
};

const mapDispatchToProps = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    onBackClick: () => {
      // TODO: change to platformEditorSdkAPI method, when editor SDK PR is merged
      // https://github.com/wix-private/editor-platform/pull/1862
      const panelHeaderButtonClickedEvent = {
        eventType: 'panelHeaderButtonClicked',
        eventPayload: {
          button: 'back',
        },
      };

      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        panelHeaderButtonClickedEvent,
      );
    },
    shouldHaveAddElements: (comp: CompRef): boolean =>
      shouldWidgetHaveAddElements(editorAPI, comp),
    openAddElementsPanel: (selectedComponent: CompRef): void =>
      dispatch(
        stateManagement.platform.actions.openAddElementsPanel(
          selectedComponent,
        ),
      ),
    getDisplayName: (selectedComponent: CompRef): string =>
      editorAPI.components.getDisplayName(selectedComponent),

    isEssentialComponentsDeleted: (controllerRef: CompRef): boolean => {
      const stageData =
        editorAPI.platform.controllers.getStageData(controllerRef);
      const connections = stageData?.connections || {};
      const essentialRoles = getEssentialConnectionRoles(connections);
      const connectedComponentsConnections: ControllerConnection[] =
        editorAPI.platform.controllers.connections.getControllerConnections(
          controllerRef,
        );
      return isEssentialRoleMissingFromStage(
        connectedComponentsConnections,
        essentialRoles,
      );
    },
    getFirstControllableComponent: (selectedComponent: CompRef): CompRef =>
      util.controlsUtils.getFirstControllableComponent(
        editorAPI,
        selectedComponent,
      ),
  };
};

const WrappedPanel = _.flow(
  connect(STORES.EDITOR_API, undefined, mapDispatchToProps),
  measuring,
)(PlatformComponentPanel);
WrappedPanel.pure = PlatformComponentPanel;

export default WrappedPanel;

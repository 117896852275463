import * as UI from '#packages/baseUI';
import * as util from '#packages/util';
import { Tooltip } from '@wix/wix-base-ui';
import React, { type FC, useEffect, useState } from 'react';
import tpaConstants from '../../constants/constants';
import * as superApps from '../../superApps/superApps';
import superAppsConstants from '../../superApps/superAppsConstants';
import { mapDispatchToProps } from './searchAppBoxMapper';
import type { SearchApp, SearchAppBoxDispatchProps } from './types';
import {
  InstallInitiator,
  InstallationOriginType,
  EditorType,
} from '@wix/platform-editor-sdk';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;
const SEARCH_APP_ID = superAppsConstants.SITE_SEARCH.APP_DEF_ID;

const SearchAppBox: FC<SearchAppBoxDispatchProps> = ({ getDataAsync }) => {
  const [app, setApp] = useState<SearchApp>({
    colorSvgIcon: '',
    appIcon: '',
    name: '',
    teaser: '',
  });

  useEffect(
    () => {
      getDataAsync(SEARCH_APP_ID).then(setApp);
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const backgroundImageUrl = app.colorSvgIcon || app.appIcon;

  return (
    <div
      onClick={() => {
        superApps.addApp(SEARCH_APP_ID, {
          biOrigin: tpaConstants.BI.type.ADD_APP_ADD_PANEL,
          callback: () => {
            // TODO: log bi
          },
          platformOrigin: {
            type: EditorType.Classic,
            initiator: InstallInitiator.Editor,
            info: {
              type: InstallationOriginType.AddPanel,
            },
          },
        });
      }}
      className="box-app-wrapper"
      data-automation-id="add-panel-section-site-search"
    >
      <a className="box-app">
        <div
          style={{
            backgroundImage: backgroundImageUrl && `url(${backgroundImageUrl})`,
          }}
          className="box-app-logo"
        />
      </a>
      <div className="content">
        <div className="title ellipsis">{app.name}</div>
        <label className="ellipsis">{app.teaser}</label>
      </div>
      <a>
        <span className="plus-tooltip">
          <Tooltip content="tpa_add_panel_add_to_site" className="edit-tooltip">
            <div>
              <UI.symbol name="plusBig" className="plus" />
            </div>
          </Tooltip>
        </span>
      </a>
    </div>
  );
};

const ConnectedSearchAppBox = connect(
  EDITOR_API,
  null,
  mapDispatchToProps,
)(SearchAppBox);
ConnectedSearchAppBox.pure = SearchAppBox;

export default ConnectedSearchAppBox;

import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      layout: {
        width: 852,
        height: 348,
        x: 8,
        y: 7,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'efgs9',
      },
      parent: 'comp-kgujt8gl',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'efgs9',
        },
        title: '',
        uri: 'ec14061b42d1dc5b809367f7cfda8eff.jpg',
        description: '',
        width: 1280,
        height: 800,
        alt: 'Tropical Paradise',
        crop: {
          x: 0,
          y: 91,
          width: 1280,
          height: 521,
          svgId: '909695c1e003409ba70b5561666c7c4d.svg',
        },
        name: 'Tropical Paradise',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'efgs9',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'efgs9',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: 0,
        offsetOrigin: 'leftTop',
        originalCompId: 'comp-kgqkj3qz2',
        recommendedScale: 1,
        recommendedWidth: 280,
        recommendedHeight: 114,
        orderIndex: 0,
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'efgs9',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
    },
    {
      type: 'Container',
      components: [
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 360,
            height: 17,
            x: 32,
            y: 18,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'efgs9',
          },
          parent: 'comp-kgujt8gv1',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            text: `<h2 class="font_2" style="font-size:14px"><span style="color:#FFFFFF"><span style="font-family:raleway-semibold,raleway,sans-serif"><span style="font-size:14px"><span style="letter-spacing:0.05em">${translate(
              'interactions_preset_vacation_tagline',
            )}</span></span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'efgs9',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            author: 'studio',
            offsetX: 10,
            offsetY: 10,
            offsetOrigin: 'leftTop',
            originalCompId: 'comp-kgqkj3r32',
            recommendedScale: 0.93333334,
            recommendedWidth: 260,
            recommendedHeight: 34,
            orderIndex: 0,
          },
          style: 'txtNew',
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 462,
            height: 47,
            x: 32,
            y: 42,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'efgs9',
          },
          parent: 'comp-kgujt8gv1',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            text: `<h2 class="font_2" style="font-size:41px; line-height:1em"><span style="color:#FFFFFF;"><span style="letter-spacing:0em"><span style="font-weight:bold"><span style="font-family:playfair display,serif"><span style="font-size:41px">${translate(
              'interactions_preset_vacation_title',
            )}</span></span></span></span></span></h2>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'efgs9',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            author: 'studio',
            offsetX: 0,
            offsetY: 10,
            offsetOrigin: 'leftBottom',
            originalCompId: 'comp-kgqkj3r4',
            recommendedScale: 0.7741935,
            recommendedWidth: 260,
            recommendedHeight: 56,
            orderIndex: 1,
          },
          style: 'txtNew',
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.line.SolidLine',
          layout: {
            width: 30,
            height: 5,
            x: 32,
            y: 110,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.FiveGridLine',
          metaData: {
            pageId: 'efgs9',
          },
          parent: 'comp-kgujt8gv1',
          props: {
            type: 'FiveGridLineProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'efgs9',
            },
            fullScreenModeOn: false,
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            author: 'studio',
            offsetX: 0,
            offsetY: 20,
            offsetOrigin: 'leftBottom',
            originalCompId: 'comp-kgqkj3r5',
            recommendedScale: 1,
            recommendedWidth: 28,
            recommendedHeight: 5,
            orderIndex: 2,
          },
          transitions: {
            type: 'TransitionData',
            'timing-function': 'ease-in-out',
            duration: 0.4,
            delay: 0,
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
          },
          transformations: {
            type: 'TransformData',
            origin: {
              x: {
                value: 0,
                type: 'percentage',
              },
              y: {
                value: 50,
                type: 'percentage',
              },
            },
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
          },
          scopedTransformations: {
            'variants-kgujt8gt': {
              type: 'TransformData',
              scale: {
                x: 3.5,
                y: 1,
              },
              rotate: 0,
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
                pageId: 'efgs9',
              },
              translate: {
                x: {
                  type: 'px',
                  value: 33.26330532212884,
                },
                y: {
                  type: 'px',
                  value: 0,
                },
              },
            },
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            style: {
              properties: {
                'alpha-brd': '1',
                brd: '#FFFFFF',
                lnw: '1px',
              },
              propertiesSource: {
                'alpha-brd': 'value',
                brd: 'value',
                lnw: 'value',
              },
              groups: {},
            },
            componentClassName: 'wysiwyg.viewer.components.FiveGridLine',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wysiwyg.viewer.skins.line.SolidLine',
          },
        },
        {
          type: 'Component',
          skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
          layout: {
            width: 312,
            height: 44,
            x: 32,
            y: 125,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.WRichText',
          metaData: {
            pageId: 'efgs9',
          },
          parent: 'comp-kgujt8gv1',
          data: {
            type: 'StyledText',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            text: `<p class="font_7" style="font-size:14px; line-height:1.5em"><span style="font-size:17px"><span style="font-family:proxima-n-w01-reg"><span style="font-weight:normal"><span style="font-style:normal"><span><span style="color:#FFFFFF"><span style="font-family:raleway,sans-serif"><span style="font-size:14px">${translate(
              'interactions_preset_vacation_description',
            )}</span></span></span></span></span></span></span></span></p>`,
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
            linkList: [],
          },
          props: {
            type: 'WRichTextProperties',
            metaData: {
              schemaVersion: '1.0',
              autoGenerated: false,
              pageId: 'efgs9',
            },
            brightness: 1,
            packed: true,
            verticalText: false,
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            author: 'studio',
            offsetX: 0,
            offsetY: 10,
            offsetOrigin: 'leftBottom',
            originalCompId: 'comp-kgqkj3r9',
            recommendedScale: 1,
            recommendedWidth: 260,
            recommendedHeight: 46,
            orderIndex: 3,
          },
          style: 'txtNew',
        },
        {
          type: 'Component',
          skin: 'wixui.skins.Skinless',
          layout: {
            width: 142,
            height: 40,
            x: 32,
            y: 214,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wixui.StylableButton',
          metaData: {
            pageId: 'efgs9',
          },
          parent: 'comp-kgujt8gv1',
          data: {
            type: 'StylableButton',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            label: translate('interactions_preset_vacation_cta'),
            svgId: '820eb164c7ca4456bd04e94ef847042e.svg',
          },
          props: {
            type: 'StylableButtonProperties',
            metaData: {
              schemaVersion: '1.0',
              pageId: 'efgs9',
            },
          },
          mobileHints: {
            type: 'MobileHints',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            author: 'studio',
            offsetX: 50,
            offsetY: 10,
            offsetOrigin: 'leftBottom',
            originalCompId: 'comp-kgtga6c2',
            recommendedScale: 0.93333334,
            recommendedWidth: 160,
            recommendedHeight: 42,
            orderIndex: 4,
          },
          style: {
            type: 'ComponentStyle',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
              pageId: 'efgs9',
            },
            style: {
              properties: {
                '$st-css':
                  ":import{-st-from: 'wix-ui-santa/index.st.css';-st-named: StylableButton}.root{-st-extends: StylableButton;transition: all 0.2s ease, visibility 0s;background: #FFFFFF}.root:hover{border: 0px solid rgb(0, 0, 0);background: rgba(255,255,255,0.8)}.root:hover::label{color: #000000}.root[disabled]{background: #E2E2E2}.root[disabled]::label{color: #8F8F8F}.root[disabled]::icon{fill: #8F8F8F}.root::container{transition: inherit}.root::label{transition: inherit;font-family: futura-lt-w01-book,sans-serif;font-size: 12px;letter-spacing: 0em;margin: 0px 4px 0px 0px;color: #000000}.root::icon{transition: inherit;fill: #FFFFFF;width: 10px;height: 10px;margin: 0px 0px 0px 4px;display: none}.root:hover::icon{fill: #000000}",
              },
              propertiesSource: {
                '$st-css': 'value',
              },
            },
            componentClassName: 'wixui.StylableButton',
            pageId: '',
            compId: '',
            styleType: 'custom',
            skin: 'wixui.skins.Skinless',
          },
        },
      ],
      skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      layout: {
        width: 774,
        height: 273,
        x: 48,
        y: 41,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'mobile.core.components.Container',
      metaData: {
        pageId: 'efgs9',
      },
      parent: 'comp-kgujt8gl',
      mobileHints: {
        type: 'MobileHints',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'efgs9',
        },
        author: 'studio',
        offsetX: 0,
        offsetY: 10,
        offsetOrigin: 'leftBottom',
        originalCompId: 'comp-kgqkj3r11',
        recommendedScale: 1,
        recommendedWidth: 280,
        recommendedHeight: 253,
        orderIndex: 1,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'efgs9',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 50,
            type: 'percentage',
          },
          y: {
            value: 50,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'efgs9',
        },
      },
      scopedTransformations: {
        'variants-kgujt8gt': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'efgs9',
          },
          scale: {
            x: 1.04,
            y: 1.04,
          },
        },
      },
      scopedStyles: {
        'variants-kgujt8gt': {
          type: 'ComponentStyle',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'efgs9',
          },
          style: {
            properties: {
              'alpha-bg': '0',
              'alpha-brd': '1',
              bg: '#F58144',
              'boxShadowToggleOn-shd': 'false',
              brd: 'color_11',
              brw: '1',
              rd: '0px',
              shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            },
            propertiesSource: {
              'alpha-bg': 'value',
              'alpha-brd': 'value',
              bg: 'value',
              'boxShadowToggleOn-shd': 'value',
              brd: 'theme',
              brw: 'value',
              rd: 'value',
              shd: 'value',
            },
            groups: {},
          },
          componentClassName: 'mobile.core.components.Container',
          pageId: '',
          compId: '',
          styleType: 'custom',
          skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'efgs9',
        },
        style: {
          properties: {
            'alpha-bg': '0',
            'alpha-brd': '1',
            bg: '#F58144',
            'boxShadowToggleOn-shd': 'false',
            brd: '#FFFFFF',
            brw: '1',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
          },
          propertiesSource: {
            'alpha-bg': 'value',
            'alpha-brd': 'value',
            bg: 'value',
            'boxShadowToggleOn-shd': 'value',
            brd: 'value',
            brw: 'value',
            rd: 'value',
            shd: 'value',
          },
          groups: {},
        },
        componentClassName: 'mobile.core.components.Container',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 864,
    height: 370,
    x: 56,
    y: 65,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'efgs9',
  },
  parent: 'efgs9',
  mobileHints: {
    type: 'MobileHints',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'efgs9',
    },
    author: 'studio',
    originalCompId: 'comp-kgqkj3pc',
    recommendedScale: 1,
    recommendedWidth: 280,
    recommendedHeight: 325,
  },
  variants: {
    'variants-kgujt8gt': {
      componentId: 'comp-kgujt8gl',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'efgs9',
      },
    },
  },
  scopedTransformations: {
    'variants-kgujt8gt': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'efgs9',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'efgs9',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  activeModes: {},
};

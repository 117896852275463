import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    const sections = [
      addPanel.sections.getSections().button.stylableButtonSection,
    ];

    return {
      titles: ['add_section_label_stylableButton'],
      sections,
    };
  },
};

import type { EditorAPI } from '#packages/editorAPI';
import type { PresetSection } from './types';
import { addPanelUtils } from '#packages/addPanelInfra';

export const createPresetSectionAPI = (editorAPI: EditorAPI) => {
  return {
    async add(section: PresetSection) {
      return addPanelUtils.attemptToAddComponent(
        editorAPI,
        section.props.items[0].structure,
        '',
        section.title,
      );
    },
  };
};

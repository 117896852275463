import constants from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';
import { MediaType } from '#packages/mediaServices';
import { getImageCompDef } from '../sections/listSection/imageSectionUtils';
import { attemptToAddComponent } from '../addPanel/addPanelUtils';
import type { MediaImageStudioUploadedFileMeta } from '@wix/media-image-studio-opener';
const { PHOTO } = constants.COMP_TYPES;

export const createAddImageToStageCallback = (
  editorAPI: EditorAPI,
  biItemName: string,
  origin: 'addPanel' | 'deepLinkPhotoStudio',
) => {
  return async ({
    id,
    name,
    width,
    height,
  }: MediaImageStudioUploadedFileMeta) => {
    const compDef = getImageCompDef(
      PHOTO,
      {
        id,
        source: '',
        name,
        mediaType: MediaType.Picture,
        height,
        width,
        title: name,
        fileName: id,
      },
      editorAPI.isMobileEditor(),
    );
    const compRef = await attemptToAddComponent(
      editorAPI,
      compDef,
      'image',
      '',
      [],
      biItemName,
      origin,
    );
    editorAPI.components.hooks.componentAddedToStage.fire({
      compRef,
      type: 'click',
      origin,
    });
  };
};

import { BaseEntryScope, createEntryPoint, Hooks } from '#packages/apilib';
import {
  ComponentsApiKey,
  EditorAPIKey,
  EditorCoreApiKey,
  EditorParamsApiKey,
} from '#packages/apis';
import { createBIService } from './services/bi';
import { createMigrationService } from './services/migration';
import { initMigration } from './init';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  components = this.useDependency(ComponentsApiKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  editorParamsAPI = this.useDependency(EditorParamsApiKey);

  migrationHook = this.declareApi(Hooks.createPromiseHook);
  biService = this.declareApi(createBIService);
  migrationService = this.declareApi(createMigrationService);
}

export const SiteWidthMigrationEntryPoint = createEntryPoint({
  name: 'SiteWidthMigration',
  Scope,
  async initialize(scope) {
    await initMigration(scope);
  },
});

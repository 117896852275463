// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import { FocusPanelFrame } from '../frames';
import {
  Button,
  Checkbox,
  Composites,
  Illustration,
  PanelHeader,
  RichText,
  TextLabel,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

const { openHelpCenter, closePanelByNameAction } =
  stateManagement.panels.actions;
const { setSessionUserPreferences } = stateManagement.userPreferences.actions;

const { DONT_SHOW_AGAIN } = constants.USER_PREFS.OPEN_WIDGET_CONFIRMATION;

//TYPE WAS GENERATED, remove this line when reviewed
interface ConfirmResetAndCloseProps {
  onConfirm: FunctionFixMe;
  widgetName: string;
}

class ConfirmResetAndClose extends React.Component<ConfirmResetAndCloseProps> {
  static displayName = 'confirmResetAndClose';

  static propTypes = {
    onConfirm: PropTypes.func.isRequired,
    widgetName: PropTypes.string.isRequired,
  };

  state = {
    dontShowAgain: false,
  };

  getDescription1 = () => {
    return translate('PLATFORM_Popup_open_close_unlock_layout_description1', {
      widget_name: this.props.widgetName,
    });
  };

  getDescription2 = () => {
    return translate('PLATFORM_Popup_open_close_unlock_layout_description2');
  };

  getLearnMoreLabel = () => {
    return translate('PLATFORM_Popup_open_close_unlock_layout_learn_more');
  };

  onClose = () => {
    this.props.closePanel();
  };

  getKeyboardShortcuts = () => {
    return {
      esc: this.onClose,
    };
  };

  onConfirm = () => {
    if (this.state.dontShowAgain) {
      this.props.setDontShowAgainPreferences(true);
    }
    this.props.onConfirm();
    this.onClose();
  };

  render() {
    return (
      <FocusPanelFrame
        ref="panelFrame"
        shouldHideHeader={true}
        automationId="confirmOpenWidget"
        keyboardShortcuts={this.getKeyboardShortcuts()}
        panelName={this.props.panelName}
      >
        <Composites.PopupSmallSize>
          <PanelHeader
            onHelp={this.props.openHelpCenter}
            onClose={this.onClose}
            className="dark"
          >
            <TextLabel
              type="T11"
              value="PLATFORM_Popup_open_close_unlock_layout_title"
            />
          </PanelHeader>

          <Composites.PanelContent>
            <Composites.RichTextWithIllustration>
              <Illustration>
                <symbols.symbol name="opeiningWidgetMasageIllustration" />
              </Illustration>

              <Composites.RichText>
                <RichText type="T01" automationId="confirmOpenDescription">
                  {this.getDescription1()}
                  <br />
                  <br />
                  {this.getDescription2()}
                  <br />
                  <a onClick={this.props.openHelpCenter}>
                    {this.getLearnMoreLabel()}
                  </a>
                </RichText>
              </Composites.RichText>
            </Composites.RichTextWithIllustration>
          </Composites.PanelContent>

          <Composites.ActionSetHorizontal>
            <Checkbox
              value={this.state.dontShowAgain}
              label="Notification_Box_Dont_Show_Again"
              labelAfterSymbol={true}
              automationId="confirmOpenWidgetCheckBox"
              onChange={(x) => this.setState({ dontShowAgain: x })}
            />
            <Button
              automationId="confirmOpenCancel"
              _head
              onClick={this.onClose}
              className="secondary"
            >
              <TextLabel value="PLATFORM_Popup_open_close_unlock_layout_cancel" />
            </Button>
            <Button
              automationId="confirmOpenConfirm"
              onClick={this.onConfirm}
              className="primary"
            >
              <TextLabel value="PLATFORM_Popup_open_close_unlock_layout_unlock" />
            </Button>
          </Composites.ActionSetHorizontal>
        </Composites.PopupSmallSize>
      </FocusPanelFrame>
    );
  }
}

const mapDispatchToProps = (dispatch, { panelName }) => ({
  closePanel: () => dispatch(closePanelByNameAction(panelName)),
  openHelpCenter: () =>
    dispatch(openHelpCenter('56c3431c-9964-4512-9313-c4ae0ebb844f')),
  setDontShowAgainPreferences: (dontShowAgain) =>
    dispatch(setSessionUserPreferences(DONT_SHOW_AGAIN, dontShowAgain)),
});

export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  undefined,
  mapDispatchToProps,
)(ConfirmResetAndClose);

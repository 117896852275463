import { events } from '#packages/coreBi';
import { bi } from '#packages/stateManagement';
import type { MapDispatchToPropsFunction, ThunkAction } from 'types/redux';

export interface ToggleDevModeDispatchProps {
  toggleDevMode: (params: {
    itemId: string;
    categoryId: string;
    sectionTitle: string;
  }) => void;
}

const toggleDevModeActionCreator =
  ({ itemId, categoryId, sectionTitle }: AnyFixMe): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    const { event: sendBi } = bi.actions;

    editorAPI.developerMode.toggle();

    dispatch(sendBi(events.addPanel.DEV_MODE_TOGGLE));
    dispatch(
      sendBi(events.addPanel.ADD_MENU_DRAG_COMPONENT, {
        preset_id: itemId,
        category: categoryId,
        section: sectionTitle,
      }),
    );
  };

export const toggleDevModeMapper: MapDispatchToPropsFunction<
  ToggleDevModeDispatchProps,
  any
> = (dispatch) => ({
  toggleDevMode: (params) => {
    dispatch(toggleDevModeActionCreator(params));
  },
});

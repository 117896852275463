// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import { Text, TextButton } from '@wix/wix-base-ui';
import { cx } from '#packages/util';

function template() {
  const title = this.props.translatedTitle || translate(this.props.title);

  return (
    <div
      onClick={() => {
        if (!this.props.isDisabled && !this.props.disableIllustrationClick) {
          this.props.onClick();
        }
      }}
      className={cx('info-drop-panel-illustration', this.props.className, {
        'disabled-action':
          this.props.isDisabled || this.props.disableIllustrationClick,
      })}
    >
      <div className="symbol-container-drop-panel-illustration">
        {this.props.symbolName ? (
          <symbols.symbol
            key={`symbol-${this.props.symbolName}`}
            name={this.props.symbolName}
            className="symbol-drop-panel-illustration"
          />
        ) : null}
        {this.props.image ? (
          <img
            key="image"
            src={this.props.image}
            className="img-drop-panel-illustration"
          />
        ) : null}
      </div>
      <div className="title-info-drop-panel-illustration">
        <Text
          skin="secondary"
          weight="bold"
          enableEllipsis={false}
          shouldTranslate={false}
        >
          {title}
        </Text>
      </div>
      <div className="description-drop-panel-illustration">
        {this.props.translatedDescription || translate(this.props.description)}
      </div>
      {this.props.linkText ? (
        <span>
          <TextButton size="small" onClick={this.props.onLinkClick}>
            {this.props.linkText}
          </TextButton>
        </span>
      ) : null}
      {this.props.promotion ? (
        <div className="promotion-drop-panel-illustration">
          <div>
            <Text
              skin="secondary"
              weight="normal"
              size="tiny"
              enableEllipsis={false}
            >
              {this.props.promotion.label}
            </Text>
          </div>
          <div className="symbol-promotion-drop-panel-illustration">
            <symbols.symbol
              key={`symbol-${this.props.promotion.symbolName}`}
              name={this.props.promotion.symbolName}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

interface DropPanelIllustrationProps {
  title: string;
  description: string;
  symbolName?: string;
  image?: string;
  className?: string;
  isDisabled?: boolean;
  translatedDescription?: string;
  translatedTitle?: string;
  onClick?: () => void;
  linkText?: string;
  promotion?: {
    label: string;
    symbolName: string;
  };
  onLinkClick?(): void;
  disableIllustrationClick?: boolean;
}

class DropPanelIllustration extends React.Component<DropPanelIllustrationProps> {
  render() {
    return template.call(this);
  }
}

DropPanelIllustration.displayName = 'DropPanelIllustration';
DropPanelIllustration.propTypes = {
  title: PropTypes.string.isRequired,
  translatedTitle: PropTypes.string,
  description: PropTypes.string.isRequired,
  translatedDescription: PropTypes.string,
  onClick: PropTypes.func,
  symbolName: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  linkText: PropTypes.string,
  onLinkClick: PropTypes.func,
  disableIllustrationClick: PropTypes.bool,
};

DropPanelIllustration.defaultProps = {
  isDisabled: false,
  className: '',
};

export default DropPanelIllustration;

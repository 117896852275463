import type { EditorAPI } from '#packages/editorAPI';
import { translate } from '#packages/i18n';
import { EditorParamsApiKey } from '#packages/apis';
import * as stateManagement from '#packages/stateManagement';
import {
  silentConstants,
  type SilentInstallAppsOptions,
} from './silentInstallConstants';
import { pendingAppsService } from '#packages/tpa';
import {
  type AppInstallOrigin,
  EditorPlatformHostIntegrationAPI,
  type SilentInstallAppsCallbacks,
} from '@wix/editor-platform-host-integration-apis';
import { tpaAlertsService } from '#packages/tpa';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { fedopsLogger, sections } from '#packages/util';
import {
  fireAttempt,
  fireStartInstallingApps,
  fireRequestBeginSilentInstallingApps,
  fireError,
  fireInstalled,
} from './silentBIUtils';
import { getSilentStateDriver } from './silentStateUtils';
import {
  getCommonAppOptions,
  handleParallelErrors,
  openMyBusinessForApprovedApps,
} from './utils';
import { adjustContainersToChildren } from '../sectionsService';
import experiment from 'experiment';
import type { AppData } from 'types/documentServices';
import * as tpa from '#packages/tpa';

const setIsSilentFlag = (editorAPI: EditorAPI): void => {
  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  editorAPI.store.dispatch(
    stateManagement.platform.actions.setSilentInstallRunning(
      platformAPI.applications.isSilentInstallRunning(),
    ),
  );
};

const showLoaderPanel = (
  stateDriver: ReturnType<typeof getSilentStateDriver>,
  editorAPI: EditorAPI,
  appDefIdsToSilentInstall: string[],
) => {
  editorAPI.panelHelpers.openProgressBar({
    title: translate(stateDriver.getTranslationKey('header')),
    totalSteps: stateDriver.getMaxStep(appDefIdsToSilentInstall.length),
    currentStep: stateDriver.getCurrentStep(),
  });
};

const startFakeProgress = (
  stateDriver: ReturnType<typeof getSilentStateDriver>,
  editorAPI: EditorAPI,
  title: string | undefined | null = undefined,
) => {
  stateDriver.increaseCurrentStep();
  let timesRan = 1;
  editorAPI.panelHelpers.updateProgressBar(
    stateDriver.getCurrentStep(),
    translate(stateDriver.getCurrentStepTranslationKey()),
    title,
  );
  stateDriver.setStepIntervalId(
    window.setInterval(() => {
      if (timesRan === stateDriver.getStepsPerApp()) {
        // stop on last step and let the singleAppCallback initiate a new counter
        window.clearInterval(stateDriver.getStepIntervalId());
        return;
      }
      stateDriver.increaseCurrentStep();
      timesRan++;
      editorAPI.panelHelpers.updateProgressBar(
        stateDriver.getCurrentStep(),
        translate(stateDriver.getCurrentStepTranslationKey()),
        title,
      );
    }, silentConstants.timeoutBetweenFakeSteps),
  );
};

const updateProgress = (
  stateDriver: ReturnType<typeof getSilentStateDriver>,
  editorAPI: EditorAPI,
  appDefIdsToSilentInstall: string[],
  names: Record<string, string>,
  flowId: string,
  origin: AppInstallOrigin,
) => {
  const totalAppsLength = appDefIdsToSilentInstall.length;
  const installedAppLastStep =
    stateDriver.getInstalledCounter() * stateDriver.getStepsPerApp();
  // If application completed installation before it's progress reached the last step
  if (stateDriver.getCurrentStep() !== installedAppLastStep) {
    stateDriver.setCurrentStep(installedAppLastStep);
    editorAPI.panelHelpers.updateProgressBar(
      stateDriver.getCurrentStep(),
      translate(stateDriver.getCurrentStepTranslationKey()),
    );
  }
  if (stateDriver.getInstalledCounter() !== totalAppsLength) {
    const appName =
      names[appDefIdsToSilentInstall[stateDriver.getInstalledCounter()]];
    const newTitle = translate(
      stateDriver.getTranslationKey('headerAfterFetch'),
      {
        App_name: appName,
      },
    );
    startFakeProgress(stateDriver, editorAPI, newTitle);
    fireAttempt(editorAPI, {
      app_id: appDefIdsToSilentInstall[stateDriver.getInstalledCounter()],
      app_name: appName,
      flowId,
      origin,
      allAppsToInstall: appDefIdsToSilentInstall,
    });
  } else {
    stateDriver.setCurrentStep(stateDriver.getMaxStep(totalAppsLength));
    editorAPI.panelHelpers.updateProgressBar(
      stateDriver.getCurrentStep(),
      translate(stateDriver.getTranslationKey('lastStep')),
    );
  }
};

async function platformSilentInstallApps(
  editorAPI: EditorAPI,
  appDefIds: string[] = [],
  options: SilentInstallAppsOptions = {},
): Promise<void> {
  const origin = options.installOrigin || silentConstants.platformOrigin;
  fireRequestBeginSilentInstallingApps(
    editorAPI,
    appDefIds,
    origin,
    silentConstants.InstallationSteps.BEGIN,
  );

  const platformAPI = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const serialInstallation = options.serialInstallation ?? true;

  const stateDriver = getSilentStateDriver();

  const onInstallationBegin: SilentInstallAppsCallbacks['onInstallationBegin'] =
    async (appDefIdsToSilentInstall, names, processFlowId) => {
      fireRequestBeginSilentInstallingApps(
        editorAPI,
        appDefIdsToSilentInstall,
        origin,
        silentConstants.InstallationSteps.APPS_AFTER_ACTIVE_FILTER,
      );
      setIsSilentFlag(editorAPI);
      stateDriver.setStepsPerApp(appDefIdsToSilentInstall);
      showLoaderPanel(stateDriver, editorAPI, appDefIdsToSilentInstall);
      const appId = appDefIdsToSilentInstall[0];
      fireStartInstallingApps(editorAPI, names);
      const firstName = names[appId];
      if (!serialInstallation) {
        fireAttempt(editorAPI, {
          app_id: appId,
          app_name: firstName,
          flowId: processFlowId,
          origin,
          allAppsToInstall: appDefIdsToSilentInstall,
        });
      }
      // Update first app name to title when available
      const newTitle = translate(
        stateDriver.getTranslationKey('headerAfterFetch'),
        {
          App_name: firstName,
        },
      );
      startFakeProgress(stateDriver, editorAPI, newTitle);
      editorAPI.history.add(
        silentConstants.BEFORE_SILENT_INSTALL_HISTORY_LABEL,
      );
      fedopsLogger.interactionStarted(
        serialInstallation
          ? fedopsLogger.INTERACTIONS.PLATFORM.SILENT_INSTALL_APPS_SERIAL
          : fedopsLogger.INTERACTIONS.PLATFORM.SILENT_INSTALL_APPS_PARALLEL,
        {
          customParams: {
            allAppsToInstall: appDefIdsToSilentInstall,
            flowId: processFlowId,
          },
        },
      );
    };

  const beforeAppInstall: SilentInstallAppsCallbacks['beforeAppInstall'] =
    async (appDefId, appDefIds, names, processFlowId) => {
      fireAttempt(editorAPI, {
        app_id: appDefId,
        app_name: names[appDefId],
        flowId: processFlowId,
        origin,
        allAppsToInstall: appDefIds,
      });
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.PLATFORM.SILENT_INSTALL_APP,
        {
          customParams: {
            appInstalled: appDefId,
            allAppsToInstall: appDefIds,
            flowId: processFlowId,
          },
        },
      );
      editorAPI.history.add(
        silentConstants.BEFORE_INSTALL_APP_HISTORY_LABEL + appDefId,
      );
      options.appsOptions = await getCommonAppOptions(
        editorAPI,
        [appDefId],
        options,
        serialInstallation,
      );
    };

  const afterAppInstall: SilentInstallAppsCallbacks['afterAppInstall'] = async (
    appDefId,
    appDefIds,
    names,
    processFlowId,
  ) => {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.PLATFORM.SILENT_INSTALL_APP,
      {
        customParams: {
          appInstalled: appDefId,
          allAppsToInstall: appDefIds,
          flowId: processFlowId,
        },
      },
    );
    editorAPI.tpa.notifyAppInstalled(appDefId, {
      platformOrigin: origin,
    });
    window.clearInterval(stateDriver.getStepIntervalId());
    stateDriver.increaseInstallCounter();
    editorAPI.history.add(
      silentConstants.AFTER_INSTALL_APP_HISTORY_LABEL + appDefId,
    );
    fireInstalled(editorAPI, {
      app_id: appDefId,
      app_name: names[appDefId],
      flowId: processFlowId,
      isProvision: stateDriver.getInstalledCounter() === 1,
      origin,
      allAppsToInstall: appDefIds,
    });
    updateProgress(
      stateDriver,
      editorAPI,
      appDefIds,
      names,
      processFlowId,
      origin,
    );

    if (experiment.isOpen('se_wpAppMarketAutoInstall')) {
      const appData: AppData =
        editorAPI.platform.getAppDataByAppDefId(appDefId);

      await tpa.services.tpaAutoInstallPluginService.autoInstallAppWidgetPlugin(
        editorAPI,
        appData.appDefinitionId,
        appData.components,
        false,
      );
    }
  };

  const onInstallationEnd: SilentInstallAppsCallbacks['onInstallationEnd'] =
    async (appsWithErrors, appDefIds, names, processFlowId) => {
      await adjustContainersToChildren(editorAPI);
      fedopsLogger.interactionEnded(
        serialInstallation
          ? fedopsLogger.INTERACTIONS.PLATFORM.SILENT_INSTALL_APPS_SERIAL
          : fedopsLogger.INTERACTIONS.PLATFORM.SILENT_INSTALL_APPS_PARALLEL,
        {
          customParams: {
            allAppsToInstall: appDefIds,
            flowId: processFlowId,
          },
        },
      );
      setIsSilentFlag(editorAPI);
      editorAPI.panelHelpers.closeProgressBar();
      pendingAppsService.updateNotification(editorAPI);
      openMyBusinessForApprovedApps(editorAPI, appDefIds);
      if (appsWithErrors.length) {
        const appsNamesWithErrors = appsWithErrors.map(
          (appDefId) => names[appDefId],
        );
        tpaAlertsService.openPlatformFailedAlert(
          editorAPI,
          appsNamesWithErrors.join(', '),
        );
      }
      await stateManagement.platform.actions.saveInBackground(editorAPI, false);
    };

  const onInstallationError: SilentInstallAppsCallbacks['onInstallationError'] =
    async (err, errName, appDefId, appDefIds, names, processFlowId) => {
      window.clearInterval(stateDriver.getStepIntervalId());
      let errorDesc;
      try {
        errorDesc = err?.message ? err.message : JSON.stringify(err);
      } catch (e) {
        errorDesc = 'cannot stringify error from Document Management';
      }
      fireError(editorAPI, {
        app_id: appDefId,
        app_name: appDefId ? names[appDefId] : 'Not a specific application',
        flowId: processFlowId,
        error_desc: errorDesc,
        errorCode: errName,
        origin,
        allAppsToInstall: appDefIds,
      });
      if (serialInstallation) {
        if (errName === silentConstants.errNames.ADD_APP_ERR) {
          await editorAPI.history.performUndoUntilLabel(
            silentConstants.BEFORE_INSTALL_APP_HISTORY_LABEL + appDefId,
            true,
          );
        }
        stateDriver.increaseInstallCounter();
        updateProgress(
          stateDriver,
          editorAPI,
          appDefIds,
          names,
          processFlowId,
          origin,
        );
      } else {
        await handleParallelErrors(editorAPI, options, errName);
        setIsSilentFlag(editorAPI);
      }
    };
  const editorParamsApi = editorAPI.host.getAPI(EditorParamsApiKey);

  try {
    await platformAPI.applications.silentInstall(
      appDefIds,
      options,
      {
        platformOrigin: origin,
        shouldInstallPending:
          stateManagement.platform.selectors.shouldInstallPendingSilently(
            editorAPI,
          ) &&
          !editorParamsApi.siteCreationWizard &&
          !editorParamsApi.siteGenerationWizard,
        shouldInstallWidgets:
          serialInstallation &&
          experiment.isOpen('se_installWidgetsInSectionSilently') &&
          sections.isSectionsEnabled(),
        shouldInstallSerial: serialInstallation,
        shouldRestrictActions: options.shouldRestrictActions ?? false,
      },
      {
        onInstallationError,
        onInstallationBegin,
        beforeAppInstall,
        afterAppInstall,
        onInstallationEnd,
      },
    );
  } catch (e) {
    ErrorReporter.captureException(e, {
      tags: { silentInstallApps: true },
      extra: {
        options,
        allAppsToInstall: appDefIds,
      },
    });
    throw e;
  }
}

export { platformSilentInstallApps };

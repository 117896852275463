import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import { EditorAPIKey } from '#packages/apis';

import { QuickEditEngineStore } from './quickEditEngineStore';

export class QuickEditEngineScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  store = this.declareStore(QuickEditEngineStore);
  drillInContentSlot = this.declareComponentSlot();
}

export const QuickEditEngineEntryPoint = createEntryPoint({
  name: 'QuickEditEngine',
  Scope: QuickEditEngineScope,
  publicApi() {},
  async initialize() {},
});

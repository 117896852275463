// @ts-nocheck
import offeringListWidget from './offeringListWidget/offeringListWidget';
import timetableWidget from './timetableWidget/timetableWidget';
import bookButtonWidget from './bookButtonWidget/bookButtonWidget';
import singleServiceWidget from './singleServiceWidget/singleServiceWidget';
import staffListWidget from './staffListWidget/staffListWidget';
import weeklyTimetableWidget from './weeklyTimetableWidget/weeklyTimetableWidget';
import dailyAgendaWidget from './dailyAgendaWidget/dailyAgendaWidget';
import calendarWidget from './calendarWidget/calendarWidget';
import { shoppingBagWidget } from '#packages/wixStore';
import * as stateManagement from '#packages/stateManagement';

export default ({ editorAPI }) => {
  return function () {
    const sections = [
      shoppingBagWidget,
      singleServiceWidget({ editorAPI }),
      offeringListWidget({ editorAPI }),
      bookButtonWidget({ editorAPI }),
      weeklyTimetableWidget({ editorAPI }),
      dailyAgendaWidget({ editorAPI }),
      calendarWidget({ editorAPI }),
    ];
    const isRefComponentsSupported =
      stateManagement.platform.selectors.isRefComponentSupported(editorAPI);
    if (isRefComponentsSupported) {
      sections.push(timetableWidget({ editorAPI }));
      sections.push(staffListWidget({ editorAPI }));
    }
    return sections;
  };
};

import React, { type FunctionComponent, useEffect } from 'react';
import experiment from 'experiment';
import * as stateManagement from '#packages/stateManagement';
import * as helpIds from '#packages/helpIds';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import { bi } from '#packages/stateManagement';
import FloatingModeBar from '../floatingModeBar/floatingModeBar';
import type { EditorAPI } from '#packages/editorAPI';

const { openHelpCenter: openHelpCenterAction } = stateManagement.panels.actions;
const { connect, STORES } = util.hoc;
const { popups } = coreBi.events;
const { getPreviewMode } = stateManagement.preview.selectors;
const contexts = util.keyboardShortcuts.CONTEXTS;

interface LightboxModeBarDispatchProps {
  exitMode(): void;
  openHelpCenter(): void;
  getCurrentPreviewMode(): boolean;
}

const LightboxModeBar: FunctionComponent<LightboxModeBarDispatchProps> = (
  props,
) => {
  useEffect(() => {
    util.keyboardShortcuts.setContext(contexts.LIGHTBOX_MODE);
    return () =>
      util.keyboardShortcuts.setContext(
        props.getCurrentPreviewMode() ? contexts.PREVIEW : contexts.EDITOR,
      );
  }, [props]);
  return (
    <FloatingModeBar
      draggableExtentionSlot={
        stateManagement.draggableSlots.DRAGGABLE_SLOTS.LIGHTBOX_MODE_BAR
      }
      title={translate(
        experiment.isOpen('spec.classic-editor.Lightbox-to-Popup')
          ? 'Topbar_Popup_New_Mode_Label'
          : 'Topbar_LightboxMode_Label',
      )}
      className="lightbox-mode-bar"
      onExitMode={props.exitMode}
      exitModeLabelKey="Topbar_LightboxMode_Exit_Button"
      onOpenHelpCenter={props.openHelpCenter}
    />
  );
};

const exitMode =
  () =>
  (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) => {
    editorAPI.pages.popupPages.close();
    dispatch(
      bi.actions.event(popups.exit_lightbox_mode_click, { origin: 'mode_bar' }),
    );
  };

const openHelpCenter = () => openHelpCenterAction(helpIds.LIGHTBOX.ABOUT);
const getCurrentPreviewMode =
  () =>
  (
    dispatch: AnyFixMe,
    getState: AnyFixMe,
    { editorAPI }: { editorAPI: EditorAPI },
  ) => {
    return getPreviewMode(editorAPI.store.getState());
  };

const mapDispatchToProps = { exitMode, openHelpCenter, getCurrentPreviewMode };

const ConnectedLightboxModeBar = connect(
  STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(LightboxModeBar);

ConnectedLightboxModeBar.pure = LightboxModeBar;

export default ConnectedLightboxModeBar;

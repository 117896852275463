import React, { Component } from 'react';
import * as util from '#packages/util';
import * as panels from '#packages/panels';
import * as symbols from '@wix/santa-editor-symbols';
import constants from '#packages/constants';
import { ChevronRight, ExternalLink } from '@wix/wix-ui-icons-common';
import {
  ActionItem,
  Illustration,
  Heading,
  TextButton,
} from '@wix/wix-base-ui';
import styles from './fourthPublishSuccessPanel.scss';
import { mapStateToProps } from './fourthPublishSuccessPanelMapper';
import { mapDispatchToProps } from './fourthPublishSuccessPanelMapper';
import {
  CONNECT_DOMAIN_ON_4TH_PUBLISH_EXISTING_DOMAIN,
  CONNECT_DOMAIN_ON_4TH_PUBLISH_NEW_DOMAIN,
} from './fourthPublishSuccessPanelConstants';

import type { FourthPublishSuccessPanelProps } from './fourthPublishSuccessPanelTypes';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

class FourthPublishSuccessPanel extends Component<FourthPublishSuccessPanelProps> {
  componentDidMount() {
    this.props.panelOpenBI();
    this.props.postPublishPanelOpenedWhatsNextContentBI();
  }

  handlePublicUrlClick = () => {
    const newWindow = window.open(this.props.publicUrl, '_blank');
    newWindow?.focus();

    this.props.publishViewYourSiteClickBI();
  };

  handleChooseDomainClick = () => {
    this.props.openPremiumSettings(CONNECT_DOMAIN_ON_4TH_PUBLISH_NEW_DOMAIN);

    this.props.connectDomainPanelClickBI('choose_domain');
    this.props.secondPublishConnectDomainClickBI();
  };

  handleConnectDomainClick = () => {
    this.props.openPremiumSettings(
      CONNECT_DOMAIN_ON_4TH_PUBLISH_EXISTING_DOMAIN,
    );

    this.props.connectDomainPanelClickBI('connect_domain');
    this.props.secondPublishConnectDomainClickBI();
  };

  closePanel = () => {
    this.props.closePanel();
    this.props.panelCloseBI();
  };

  render() {
    return (
      <panels.frames.CustomPanelFrame
        panelName={constants.PANELS.FOURTH_PUBLISH_SUCCESS_PANEL.PANEL_NAME}
        onCloseButtonClick={this.closePanel}
        onOuterClick={this.closePanel}
      >
        <div className={styles.fourthPublishSuccessPanel}>
          <div className={styles.fourthPublishSuccessPanel__headingWrapper}>
            <Heading appearance="h2">PUBLISH_SITE_PUBLISHED_TITLE</Heading>
          </div>
          <div className={styles.fourthPublishSuccessPanel__mainWrapper}>
            <div className={styles.fourthPublishSuccessPanel__linkPanel}>
              <TextButton
                onClick={this.handlePublicUrlClick}
                shouldTranslate={false}
              >
                <div
                  className={
                    styles.fourthPublishSuccessPanel__textButtonContent
                  }
                >
                  {this.props.publicUrl}
                  <ExternalLink
                    className={styles.fourthPublishSuccessPanel__externalLink}
                  />
                </div>
              </TextButton>
            </div>

            <div
              className={styles.fourthPublishSuccessPanel__actionItemWrapper}
            >
              <ActionItem
                title="PREMIUM_BUTTON_1"
                illustration={
                  <Illustration>
                    <symbols.symbol name="connectNewDomain_NewWorkspace" />
                  </Illustration>
                }
                description="PREMIUM_BUTTON_1_SUBTITLE"
                actionIcon={<ChevronRight />}
                onClick={this.handleChooseDomainClick}
              />
            </div>

            <div
              className={styles.fourthPublishSuccessPanel__actionItemWrapper}
            >
              <ActionItem
                title="PREMIUM_BUTTON_2"
                illustration={
                  <Illustration>
                    <symbols.symbol name="connectDomainLight_NewWorkspace" />
                  </Illustration>
                }
                description="PREMIUM_BUTTON_2_SUBTITLE"
                actionIcon={<ChevronRight />}
                onClick={this.handleConnectDomainClick}
              />
            </div>
          </div>
        </div>
      </panels.frames.CustomPanelFrame>
    );
  }
}

const connectedPublishSuccessPanel = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(FourthPublishSuccessPanel);

export default connectedPublishSuccessPanel;

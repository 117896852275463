import constants from '#packages/constants';
import * as util from '#packages/util';
import type { EditorAPI } from '#packages/editorAPI';
import * as wixStoreDataProvider from './wixStoreDataProvider';
import * as stateManagement from '#packages/stateManagement';
import * as wixStoresBi from './bi/wixStoresBi';

interface DealerOfferDto {
  offerName: string;
  realEstateId: string;
  offerGuid: string;
  asset: { payloadJson: string } | any;
}

export interface IntentWelcomePanelProps {
  panelName?: string;
  title?: string;
  bullets?: string[];
  cta?: string;
  customizedLink?: string;
  imageName?: string;
  className?: string;
}

interface IntentConfiguration {
  modal?: IntentWelcomePanel;
  showModal: boolean;
}

interface IntentWelcomePanel {
  userPreferencesKey?: string;
  componentProps?: IntentWelcomePanelProps;
}

const intentsPropsMap: { [intentKey: string]: IntentConfiguration } = {
  iZettle: {
    showModal: true,
    modal: {
      userPreferencesKey: 'didDisplayedStoresIZettleWelcomeModal',
      componentProps: {
        title: 'Stores_iZettle_Importer_popup_title',
        bullets: [
          'Stores_iZettle_Importer_popup_bullet1',
          'Stores_iZettle_Importer_popup_bullet2',
          'Stores_iZettle_Importer_popup_bullet3',
        ],
        cta: 'Stores_iZettle_Importer_popup_importCTA',
        customizedLink: 'Stores_iZettle_Importer_popup_customizedLink',
        imageName: 'iZettleLogo',
        className: 'izettle-welcome',
      },
    },
  },
  'dropshipping-intent': {
    showModal: true,
    modal: {
      userPreferencesKey: 'didDisplayedStoresDropshippingModal',
      componentProps: {
        title: 'Stores_Dropship_Welcome_Title',
        bullets: [
          'Stores_Dropship_Welcome_Bullet1',
          'Stores_Dropship_Welcome_Bullet2',
          'Stores_Dropship_Welcome_Bullet3',
        ],
        cta: 'Stores_Dropship_Welcome_Start_Button',
        customizedLink: 'Stores_Dropship_Welcome_Customize_Link',
        imageName: 'onlineStoreManager',
      },
    },
  },
};

const DEALER_STORE_FUNNEL_ID = '6d997e03-e2f7-4b24-911f-bd267b475d43';

function getModalIntentDetails(intentKey: string): IntentWelcomePanel {
  const intentConfig = intentsPropsMap[intentKey].modal;
  return {
    userPreferencesKey:
      intentConfig?.userPreferencesKey ||
      `didDisplayedStores${intentKey}WelcomeModal`,
    componentProps: {
      panelName: intentConfig?.componentProps?.panelName || intentKey,
      title:
        intentConfig?.componentProps?.title ||
        `Stores_${intentKey}_popup_title`,
      bullets: intentConfig?.componentProps?.bullets || [
        `Stores_${intentKey}_popup_bullet1`,
        `Stores_${intentKey}_popup_bullet2`,
        `Stores_${intentKey}_popup_bullet3`,
      ],
      cta:
        intentConfig?.componentProps?.cta ||
        `Stores_${intentKey}_popup_importCTA`,
      customizedLink:
        intentConfig?.componentProps?.customizedLink ||
        `Stores_${intentKey}_popup_customizedLink`,
      imageName: intentConfig?.componentProps?.imageName,
      className: intentConfig?.componentProps?.className || 'intent-welcome',
    },
  };
}

export class WixStoreIntentService {
  private allIntents: string[];
  public intentPromise: Promise<any>;

  constructor(private editorAPI: EditorAPI) {
    this.intentPromise = this.fetchIntents();
  }

  async getModalForIntent() {
    await this.intentPromise;
    const intentsWithModals = this.allIntents.filter(
      (offer) => intentsPropsMap[offer]?.showModal,
    );
    return intentsWithModals.length
      ? getModalIntentDetails(intentsWithModals[0])
      : null;
  }

  fetchIntents() {
    const metaSiteInstance =
      this.editorAPI.dsRead.platform.getAppDataByApplicationId(
        constants.APPLICATIONS.META_SITE_APPLICATION_ID,
      )?.instance;
    return util.http
      .fetchJson(
        `/_api/dealer-offers-serving-service/v1/dealer/serving/offers?realEstateId=${DEALER_STORE_FUNNEL_ID}`,
        {
          contentType: 'application/json',
          credentials: 'same-origin',
          headers: new Headers({ Authorization: metaSiteInstance }),
          method: 'GET',
        },
      )
      .then((response: AnyFixMe) => {
        const dealerOffersResponse: DealerOfferDto[] = response?.offers ?? [];
        const offerNames = dealerOffersResponse.reduce(
          (accOfferNames, offer) =>
            JSON.parse(offer.asset.payloadJson).value === 'true'
              ? [...accOfferNames, offer.offerName]
              : accOfferNames,
          [],
        );

        const hasDropshippingIntent = offerNames.includes(
          'dropshipping-intent',
        );

        wixStoreDataProvider.setDropshippingIntentExist(hasDropshippingIntent);

        if (offerNames.includes('stores-postreg-intent')) {
          const params = {
            is_eligible: wixStoreDataProvider.isAppInstalled(this.editorAPI),
            type: hasDropshippingIntent,
            testName: 'se_wixStores_storesPostRegIntent',
          };
          this.editorAPI.bi.event(
            wixStoresBi.events.FIRST_TIME_STORES_EXPOSURE,
            params,
          );
        }

        if (offerNames.includes('stores-postreg-intent')) {
          this.editorAPI.store.dispatch(
            stateManagement.introFunnel.actions.addIntroFunnelIntent(
              stateManagement.introFunnel.IntroFunnelIntents.STORES,
            ),
          );
        }

        this.allIntents = offerNames;
      });
  }
}

// @ts-nocheck
import constants from '#packages/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  mainActions: [],
  enabledActions: [ACTIONS.SETTINGS, ACTIONS.ANIMATION, ACTIONS.HELP],
  mobileEnabledActions: [ACTIONS.HIDE, ACTIONS.HELP, ACTIONS.ANIMATION],
  presetActions: {
    help: {
      helpId: '44b77f39-3a1c-408d-b376-7ab9717f0ea5',
      mobileHelpId: 'ff2c7aa5-1275-426c-aa31-ad665195b377',
    },
  },
};

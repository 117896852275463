// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getCategorySections() {
    return addPanel.dataUtil.mergeWithCommonSections(
      addPanel.sections.getSections().page,
      {},
    );
  },
};

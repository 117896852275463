// @ts-nocheck
import createReactClass from 'create-react-class';
import * as core from '#packages/core';
import focusPanelMixin from './focusPanelMixin';
import * as coreBi from '#packages/coreBi';
import React from 'react';
import WelcomeVideoPanel from './welcomeVideoPanel';
import * as baseUI from '#packages/baseUI';

const bi = coreBi;

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'newFeaturesVideoPanel',
  mixins: [core.mixins.editorAPIMixin, focusPanelMixin],
  getBIEvent() {
    return bi.events.newFeaturesVideoPanel.NEW_FEATURES_INTRO_VIDEO_CLOSE;
  },
  render() {
    return (
      <WelcomeVideoPanel
        ref="frame"
        title={this.props.title}
        subtitle={this.props.subtitle}
        linkedVideoData={this.props.linkedVideoData}
        panelName={this.props.panelName}
        onCloseByX={this.sendBIEventWhenClosedByX}
        onCloseByESC={this.sendBIEventWhenClosedByESC}
        shouldNotCloseOnBlur={true}
      >
        <div className="buttons">
          <baseUI.button
            label={this.props.buttonLabel}
            onClick={() => {
              this.startNow();
            }}
          />
        </div>
      </WelcomeVideoPanel>
    );
  },
});

import constants from '#packages/constants';
import type { CompRef, DocumentServicesObject } from 'types/documentServices';

export const getDesktopRefById = (
  documentServices: DocumentServicesObject,
  id: string,
): CompRef => {
  const compRef = documentServices.components.get.byId(id);

  if (compRef.type !== documentServices.viewMode.VIEW_MODES.DESKTOP) {
    return <CompRef>{
      ...compRef,
      type: documentServices.viewMode.VIEW_MODES.DESKTOP,
    };
  }

  return compRef;
};

export const isAnchor = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): boolean =>
  documentServices.components.getType(compRef) === constants.COMP_TYPES.ANCHOR;

export const isSection = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): boolean =>
  documentServices.components.getType(compRef) === constants.COMP_TYPES.SECTION;

export const isStrip = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): boolean =>
  documentServices.components.getType(compRef) ===
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER;

export const isAppController = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): boolean =>
  documentServices.components.getType(compRef) ===
  constants.COMP_TYPES.APP_CONTROLLER;

import experiment from 'experiment';
import constants from '#packages/constants';
import { sections as sectionsUtils } from '#packages/util';
import {
  EditorRestrictionsApiKey,
  SwitchLayoutApiKey,
  QuickEditApiKey,
} from '#packages/apis';
import * as stateManagement from '#packages/stateManagement';

import { ActionIds } from './Actions.constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { ActionGroupsWithIds } from './ActionsMapper';

const {
  MoveUp,
  MoveDown,
  Duplicate,
  Rename,
  QuickEdit,
  SwitchLayout,
  SwitchLayoutComparison,
  Remove,
  Hide,
  ShowMore,
  Accessibility,
  ScanPreset,
  Animation,
  Settings,
  ScanSwitchLayoutPreset,
} = ActionIds;

const getEditSectionRelatedActions = (editorAPI: EditorAPI) => {
  const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
  const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);

  const scanSwitchLayoutPresetIfNeeded = experiment.isOpen(
    'se_scanSwitchLayoutPreset',
  )
    ? [ScanSwitchLayoutPreset]
    : [];

  const switchLayoutActionsIfNeeded = switchLayoutAPI.isSwitchLayoutAvailable()
    ? [
        SwitchLayout,
        switchLayoutAPI.isComparisonToolEnabled() && SwitchLayoutComparison,
      ].filter(Boolean)
    : [];

  const designerScanActionIfNeeded = experiment.isOpen('se_addDesignerSection')
    ? [ScanPreset]
    : [];

  const isQuickEditAvailable = quickEditAPI.isQuickEditAvailable();
  const quickEditActionIfNeeded = isQuickEditAvailable ? [QuickEdit] : [];

  return quickEditActionIfNeeded
    .concat(
      designerScanActionIfNeeded,
      switchLayoutActionsIfNeeded,
      scanSwitchLayoutPresetIfNeeded,
    )
    .filter(Boolean);
};

const getSectionSecondaryActions = (editorAPI: EditorAPI): ActionIds[] => {
  const isMobile = editorAPI.isMobileEditor();

  if (sectionsUtils.isSectionsControlsRedesignEnabled()) {
    return [];
  }

  if (!isMobile) {
    const editSectionActions = getEditSectionRelatedActions(editorAPI);

    return editSectionActions.length > 0 ? editSectionActions : [Rename];
  }

  return [];
};

const getSectionCollapsibleActions = (editorAPI: EditorAPI) => {
  const isMobile = editorAPI.isMobileEditor();
  const isZoomMode = editorAPI.zoomMode.isInZoomMode();
  const switchLayoutAPI = editorAPI.host.getAPI(SwitchLayoutApiKey);
  const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
  const isQuickEditAvailable = quickEditAPI.isQuickEditAvailable();

  const editorRestrictionsAPI = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const isHideSectionVisible = editorRestrictionsAPI.allowed(
    'action-bar_hide-section.visible',
  );

  if (isMobile) {
    return isHideSectionVisible ? [Hide] : [];
  }

  if (sectionsUtils.isSectionsControlsRedesignEnabled()) {
    const editSectionActions = getEditSectionRelatedActions(editorAPI);

    if (isZoomMode) {
      const isShrinkedStage = editorAPI.zoomMode.isShrinkedStageZoomOutActive();

      if (isShrinkedStage) {
        return editSectionActions.length > 0
          ? editSectionActions
          : [Duplicate, Remove];
      }

      return [QuickEdit, SwitchLayout];
    }

    return editSectionActions.length > 0
      ? editSectionActions
      : [Duplicate, Remove];
  }

  const actions = switchLayoutAPI.isSwitchLayoutAvailable()
    ? [
        !isQuickEditAvailable && Rename,
        !isQuickEditAvailable && Remove,
        Accessibility,
      ]
    : [
        !isQuickEditAvailable && Duplicate,
        !isQuickEditAvailable && Remove,
        Accessibility,
      ];

  return actions.filter(Boolean);
};

const getSectionGroups = (editorAPI: EditorAPI): ActionGroupsWithIds => {
  const actionGroups: ActionGroupsWithIds = {
    primaryActions: [MoveUp, MoveDown],
    secondaryActions: getSectionSecondaryActions(editorAPI),
    collapsibleActions: getSectionCollapsibleActions(editorAPI),
    notCollapsibleAction: [ShowMore],
  };

  return actionGroups;
};

const getHeaderGroups = (editorAPI: EditorAPI): ActionGroupsWithIds => {
  const isMobile = editorAPI.isMobileEditor();
  const isInZoomMode = editorAPI.zoomMode.isInZoomMode();
  const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
  const isQuickEditAvailable = quickEditAPI.isQuickEditAvailable();

  if (sectionsUtils.isSectionsControlsRedesignEnabled()) {
    if (isMobile) {
      return {
        primaryActions: [Settings],
        secondaryActions: [],
        collapsibleActions: [],
        notCollapsibleAction: [ShowMore],
      };
    }

    const quickEditActionIfNeeded = isQuickEditAvailable ? [QuickEdit] : [];
    return {
      primaryActions: quickEditActionIfNeeded,
      secondaryActions: [],
      collapsibleActions: [],
      notCollapsibleAction: [ShowMore],
    };
  }

  const notCollapsibleActionForSiteSegments = isInZoomMode ? [] : [ShowMore];

  const actionGroupsHeader: ActionGroupsWithIds = {
    primaryActions: isInZoomMode ? [] : [QuickEdit, Settings],
    secondaryActions: [],
    collapsibleActions: [],
    notCollapsibleAction: notCollapsibleActionForSiteSegments,
  };

  return actionGroupsHeader;
};

const getFooterPrimaryActions = (isInZoomMode: boolean, isMobile: boolean) => {
  if (isInZoomMode) return [];
  return isMobile ? [Animation] : [QuickEdit, Settings];
};

const getFooterGroups = (editorAPI: EditorAPI): ActionGroupsWithIds => {
  const isMobile = editorAPI.isMobileEditor();
  const isInZoomMode = editorAPI.zoomMode.isInZoomMode();
  const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);
  const isQuickEditAvailable = quickEditAPI.isQuickEditAvailable();

  if (sectionsUtils.isSectionsControlsRedesignEnabled()) {
    if (isMobile) {
      return {
        primaryActions: [Animation],
        secondaryActions: [],
        collapsibleActions: [],
        notCollapsibleAction: [ShowMore],
      };
    }

    const quickEditActionIfNeeded = isQuickEditAvailable ? [QuickEdit] : [];
    return {
      primaryActions: quickEditActionIfNeeded,
      secondaryActions: [],
      collapsibleActions: [],
      notCollapsibleAction: [ShowMore],
    };
  }

  const notCollapsibleActionForSiteSegments = isInZoomMode ? [] : [ShowMore];

  const actionGroupsFooter: ActionGroupsWithIds = {
    primaryActions: getFooterPrimaryActions(isInZoomMode, isMobile),
    secondaryActions: [],
    collapsibleActions: [],
    notCollapsibleAction: notCollapsibleActionForSiteSegments,
  };

  return actionGroupsFooter;
};

const actionGroupsGetterByType = {
  [constants.COMP_TYPES.HEADER]: getHeaderGroups,
  [constants.COMP_TYPES.FOOTER]: getFooterGroups,
  [constants.COMP_TYPES.SECTION]: getSectionGroups,
};

export const getActionsGroups = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): ActionGroupsWithIds => {
  const compType = editorAPI.components.getType(compRef);

  const isInMultinilgual =
    editorAPI.language.multilingual.isEnabled() &&
    !stateManagement.multilingual.services.utils.currentIsOriginal(editorAPI);

  const primaryActions = editorAPI.sections.isHeaderOrFooter(compRef)
    ? [Settings, QuickEdit]
    : [QuickEdit];

  const multilingualActions: ActionGroupsWithIds = {
    primaryActions,
    secondaryActions: [],
    collapsibleActions: [],
    notCollapsibleAction: [ShowMore],
  };

  if (isInMultinilgual) {
    return multilingualActions;
  }

  return actionGroupsGetterByType[
    compType as keyof typeof actionGroupsGetterByType
  ]?.(editorAPI);
};

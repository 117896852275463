import getTools from '../tools';
// import { getCommonWidgets } from '../widgets';
import type { EditorAPI } from '#packages/editorAPI';
import type { Dispatch } from 'types/redux';

import type { AssistantConfig } from '../aiAssistantTypes';

const getAssistantConfig = (
  editorAPI: EditorAPI,
  dispatch: Dispatch,
  origin: string,
): AssistantConfig => {
  return {
    title: 'Design Assistant',
    assistantName: 'Design Assistant',
    firstUserMessage: 'How can I change the theme color?',
    domainIds: ['0fb5f482-4e09-441f-9a85-05324e27c9b1'],
    origin: 'editor',
    getWidgets: () => [],
    tools: getTools({
      editorAPI,
      dispatch,
      payloads: { origin },
      toolsIds: ['openEditorPanels'],
    }),
    onWidgetAction: () => {},
    theme: 'original',
  };
};

export default getAssistantConfig;

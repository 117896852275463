import type { MapStateToProps } from 'types/redux';
import type { SiteSegmentEmptyStateLiteModeOwnProps } from './SiteSegmentEmptyStateLiteMode';
import { backgroundUtils, colors } from '#packages/util';
import { WorkspaceModesApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';
import type { Dispatch, MapDispatchToProps, ThunkAction } from 'types/redux';

const PERCEIVED_LIGHTNESS_THRESHOLD = 0.5;
const isLightColor = (color: string) =>
  colors.getLumaPerceivedLightness(color) > PERCEIVED_LIGHTNESS_THRESHOLD;

export interface SiteSegmentEmptyStateLiteModeStateProps {
  isLightTextColor: boolean;
}

export interface SiteSegmentEmptyStateLiteModeDispatchProps {
  switchToFullEditor: () => void;
}

export const mapStateToProps: MapStateToProps<
  SiteSegmentEmptyStateLiteModeStateProps,
  SiteSegmentEmptyStateLiteModeOwnProps
> = ({ editorAPI, dsRead }) => {
  const backgroundData = dsRead.pages.background.get(
    dsRead.pages.getCurrentPageId(),
    'desktop',
  );

  const pageBackgroundColor = backgroundUtils.getColorValue(
    editorAPI,
    backgroundData.ref.color,
  );

  // TODO: probably add check for header/footer background instead
  const isLightTextColor = !isLightColor(pageBackgroundColor);

  return {
    isLightTextColor,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  SiteSegmentEmptyStateLiteModeDispatchProps,
  SiteSegmentEmptyStateLiteModeOwnProps
> = (dispatch: Dispatch) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);

  return {
    switchToFullEditor: () => {
      workspaceModesApi.switchToFullMode('site segment empty state');
    },
  };
};

import { EditorPlatformHostIntegrationAPI } from '@wix/editor-platform-host-integration-apis';
import type { EditorAPI } from '#packages/editorAPI';
import type { ComponentRef } from '@wix/platform-editor-sdk';
import { getAppDataAndCompByCompRef } from '#packages/platform';

function getViewStatesFromAppManifest(
  editorAPI: EditorAPI,
  controllerRef: ComponentRef,
) {
  const stageData = editorAPI.platform.controllers.getStageData(controllerRef);
  const viewStates = stageData?.viewStates ?? {};
  return Object.keys(viewStates).reduce(
    (acc, key) => [
      ...acc,
      {
        id: key,
        title: viewStates[key].title,
      },
    ],
    [],
  );
}

export function getViewStates(
  editorAPI: EditorAPI,
  controllerRef: ComponentRef,
) {
  const api = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const { appData, compRef } = getAppDataAndCompByCompRef(
    editorAPI,
    controllerRef,
  );

  if (!appData) {
    return [];
  }

  const currentViewState = api.viewState.getViewState(
    // there is type issue between DM and platform appData
    appData as any,
    compRef,
  );

  return getViewStatesFromAppManifest(editorAPI, compRef as ComponentRef).map(
    (viewState) => ({
      id: viewState.id,
      label: viewState.title,
      active: viewState.id === currentViewState,
    }),
  );
}

export function onViewStateChange(
  editorAPI: EditorAPI,
  componentRef: ComponentRef,
  stateName: string,
): void {
  const api = editorAPI.host.getAPI(EditorPlatformHostIntegrationAPI);
  const data = editorAPI.dsRead.components.data.get(componentRef);

  if (!data) {
    return;
  }

  const { appData, compRef } = getAppDataAndCompByCompRef(
    editorAPI,
    componentRef,
  );

  if (!appData) {
    return;
  }

  // there is type issue between DM and platform appData
  api.viewState.setViewState(appData as any, compRef, stateName);
}

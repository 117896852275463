import React from 'react';
import {
  Composites,
  Checkbox,
  PanelHeaderIllustration,
  PanelHeaderCenteredText,
} from '@wix/wix-base-ui';

import * as Panels from '#packages/panels';
import * as BaseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import { fedopsLogger, hoc, media, workspace } from '#packages/util';
import type { SwitchEditorModeInteractionStartedSource } from 'types/fedops/mobileEditorInteraction';

import type { MapDispatchToProps } from 'types/redux';

const { closePanelByName, openHelpCenter } = stateManagement.panels.actions;
const { setSiteUserPreferences } = stateManagement.userPreferences.actions;

const HELP_ID = workspace.isNewWorkspaceEnabled()
  ? '897cf1f6-7423-4f68-9332-e243cc2e5115'
  : 'fb8be001-e07b-4b0d-bf88-fca3bedc0583';
const DONT_SHOW_AGAIN = constants.USER_PREFS.PUBLISH.MOBILE_DONT_SHOW_AGAIN;

interface DispatchProps {
  closePanel: (event: any, origin?: string) => void;
  openHelpCenter: () => void;
  setMobileDiscoverabilityModalShownInCurrentSession: (value: boolean) => void;
  setEditorMode: (
    editorMode: boolean,
    sourceOfStart: SwitchEditorModeInteractionStartedSource,
  ) => void;
  setDontShowAgain: (value: boolean) => void;
}

interface OwnProps {
  panelName: string;
}

interface DiscoverMobileProps extends OwnProps, DispatchProps {}

class DiscoverMobile extends React.Component<DiscoverMobileProps> {
  state = { dontShowAgain: false };

  componentDidMount() {
    fedopsLogger.interactionEnded(
      fedopsLogger.INTERACTIONS.MOBILE_DISCOVERABILITY.MODAL_SHOW,
    );
    this.props.setMobileDiscoverabilityModalShownInCurrentSession(true);
  }

  handleDontShowAgainToggle = () => {
    this.setState({ dontShowAgain: !this.state.dontShowAgain }, () => {
      this.props.setDontShowAgain(this.state.dontShowAgain);
    });
  };

  handleSwitch = () => {
    this.props.closePanel(null, 'switch to mobile btn');
    this.props.setEditorMode(true, 'discoverMobile_confirmBtn');
  };

  renderSpinner() {
    return (
      <PanelHeaderIllustration blueStripHeight={72}>
        <img
          src={media.getMediaUrl('panels/mobileDiscoverability/Preview.gif')}
          className="illustration"
        />
      </PanelHeaderIllustration>
    );
  }

  renderNewWorkspacePanelFrame() {
    return (
      <Panels.frames.MessagePanelFrame
        dataHook="mobile-discoverability-modal"
        panelName={this.props.panelName}
        theme="standard"
        title={translate('PUBLISH_EDIT_MOBILE_SITE_TITLE')}
        illustration={<BaseUI.symbol name="mobileEdit_NewWorkspace" />}
        confirmLabel={translate('PUBLISH_EDIT_MOBILE_SITE_BUTTON')}
        onConfirm={this.handleSwitch}
        cancelLabel={translate('PUBLISH_EDIT_MOBILE_SITE_CANCEL_BUTTON')}
        onCancel={this.props.closePanel}
        onHelp={this.props.openHelpCenter}
        dontShowAgainKey={DONT_SHOW_AGAIN}
        userPrefType="site"
      >
        {translate('PUBLISH_EDIT_MOBILE_SITE_TEXT')}
      </Panels.frames.MessagePanelFrame>
    );
  }

  render() {
    if (workspace.isNewWorkspaceEnabled()) {
      return this.renderNewWorkspacePanelFrame();
    }

    return (
      <Panels.frames.FocusPanelFrame
        panelName={this.props.panelName}
        shouldHideHeader
        className="mobile-discoverability-modal gif-preview"
      >
        <Composites.BigPanelHeaderWithIllustration>
          <PanelHeaderCenteredText
            title="PUBLISH_CHECK_MOBILE_SITE_TITLE"
            subtitle="PUBLISH_CHECK_MOBILE_SITE_SUBTITLE"
            onHelp={this.props.openHelpCenter}
            onClose={this.props.closePanel}
          />
          {this.renderSpinner()}
        </Composites.BigPanelHeaderWithIllustration>
        <footer>
          <Checkbox
            label="Notification_Box_Dont_Show_Again"
            className="dont-show-again"
            onChange={this.handleDontShowAgainToggle}
            labelAfterSymbol
          />
          <div className="footer-buttons">
            <BaseUI.button
              class="btn-confirm-primary"
              label="PUBLISH_CHECK_MOBILE_SITE_BUTTON"
              onClick={this.handleSwitch}
            />
          </div>
        </footer>
      </Panels.frames.FocusPanelFrame>
    );
  }
}

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (
  dispatch,
  { panelName },
) => ({
  closePanel: (event, origin = 'close') =>
    dispatch(closePanelByName(panelName, origin)),
  openHelpCenter: () => dispatch(openHelpCenter(HELP_ID)),
  setDontShowAgain: (value) =>
    dispatch(setSiteUserPreferences(DONT_SHOW_AGAIN, value)),
  setMobileDiscoverabilityModalShownInCurrentSession: (value: boolean) => {
    const editorAPI = dispatch(getEditorAPI);

    editorAPI.setMobileDiscoverabilityModalShownInCurrentSession(value);
  },
  setEditorMode: (
    value: boolean,
    sourceOfStart: SwitchEditorModeInteractionStartedSource,
  ) => {
    const editorAPI = dispatch(getEditorAPI);

    editorAPI.setEditorMode(value, sourceOfStart);
  },
});

export default hoc.connect<undefined, DispatchProps, OwnProps>(
  hoc.STORES.EDITOR_API,
  undefined,
  mapDispatchToProps,
)(DiscoverMobile);

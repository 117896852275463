import { EVENTS } from '#packages/wixData';
import type { CompStructure } from 'types/documentServices';
import type { MapDispatchToProps } from 'types/redux';
import { notifyDataBindingApplication } from '../../../sections/listSection/dataSectionUtils';
import { BI_ORIGIN } from '../../constants';
import { attemptToAddComponent } from './addComponent';

export interface ContentManagerMapperProps {
  addDataSet: (itemStructure: CompStructure) => void;
  addFormDataSet: (itemStructure: CompStructure) => void;
  addPreset: () => void;
  addCollection: () => void;
}

export const contentManagerMapper: MapDispatchToProps<
  ContentManagerMapperProps,
  any
> = (dispatch) => {
  const editorAPI = dispatch(
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI,
  );

  return {
    addDataSet: (itemStructure) => {
      attemptToAddComponent(editorAPI, {
        compDef: itemStructure,
        sectionTitle: undefined,
        tags: undefined,
        itemId: 'dataset',
        appDefinitionId: 'dataBinding',
        categoryId: 'database',
        position: undefined,
      });
    },
    addFormDataSet: (itemStructure) => {
      attemptToAddComponent(editorAPI, {
        compDef: itemStructure,
        sectionTitle: undefined,
        tags: undefined,
        itemId: 'form_dataset',
        appDefinitionId: 'dataBinding',
        categoryId: 'database',
        position: undefined,
      });
    },
    addPreset: () => {
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.openAddPreset,
        eventPayload: {
          origin: BI_ORIGIN,
        },
      });
    },
    addCollection: () => {
      notifyDataBindingApplication(editorAPI, {
        eventType: EVENTS.createCollectionButtonClicked,
        eventPayload: {
          origin: BI_ORIGIN,
        },
      });
    },
  };
};

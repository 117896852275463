import React, { Component } from 'react';
import * as util from '#packages/util';

import ListSection, { type ListSectionProps } from '../listSection';
import type { TogglesListItem } from './togglesListSectionType';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './togglesListSectionMapper';

export interface TogglesListSectionDispatchProps {
  toggle: (item: TogglesListItem) => void;
  closePanel: () => void;
}

export interface TogglesListSectionStateProps {
  props: {
    items: TogglesListItem[];
  } & ListSectionProps['props'];
}

export type TogglesListSectionOwnProps = {
  props: {
    items: TogglesListItem[];
  } & ListSectionProps['props'];
  onClick(
    structure: object,
    sectionTitle: string,
    sectionTags: string,
    id: string,
    editorAPI: object,
    additionalData: object,
  ): void;
} & ListSectionProps;

type TogglesListSectionProps = TogglesListSectionOwnProps &
  TogglesListSectionDispatchProps;

class TogglesListSection extends Component<TogglesListSectionProps> {
  onItemClick = (item: AnyFixMe) => {
    this.props.onClick(
      item.structure,
      this.props.title,
      /*sectionTags*/ undefined,
      item.id,
      /*editorAPI*/ undefined,
      {
        nonComponent: true,
      },
    );
    this.props.toggle(item);
    this.props.closePanel();
  };

  render() {
    return <ListSection callback={this.onItemClick} {...this.props} />;
  }
}

const ConnectedTogglesListSection = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(TogglesListSection);

export default ConnectedTogglesListSection;

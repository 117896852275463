// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import { translate } from '#packages/i18n';

const resourcePath = 'addPanelData/sections/imageSection/';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.IMAGE,
  hide: false,
  title: 'image_section_image_title_image_collections',
  subNavigationTitle: 'image_section_image_title_image_collections',
  presetTitle: 'image_section_image_title_image_collections',
  tooltipTitle: 'image_section_image_title_image_collections',
  subNavigationHide: false,
  showSectionHeader: true,
  props: {
    items: [
      {
        title: 'add_section_label_wixfreeimages',
        biItemName: 'free_image',
        desc: 'add_section_info_title_wixfreeimages',
        image: `${resourcePath}wixFreeImages.png`,
        openSource: 'add_panel_free_images',
        path: 'public/fc33df1327b64cf7bae45c41b6b7e905/c830ae8ff9cd4053944cf20602f27c29',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_freeimages_add'),
        },
        hintProps: { id: 'FreeImages' },
      },
      {
        title: 'add_section_label_wixfreeillustrations',
        desc: 'add_section_info_title_wixfreeillustrations',
        biItemName: '‘free_illustration’',
        image: `${resourcePath}freeWixIllustrations.png`,
        category: 'IMAGE',
        openSource: 'add_panel_free_illustations',
        path: 'public/384e5c1b7a064df99309799fd7fec65a/98778d26945e4277b15d6fd5f14fdae0',
        compType: 'wysiwyg.viewer.components.WPhoto',
        translation: {
          submitButton: translate('MMGR_submitbutton_addpanel_freeimages_add'),
        },
      },
      {
        title: 'add_section_label_bigstock',
        desc: 'add_section_info_title_bigstock',
        openSource: 'add_panel_shutterstock_images',
        biItemName: 'bigstock',
        image: `${resourcePath}bigStockImages.png`,
        path: 'shutterStock',
      },
    ],
  },
  help: {
    hide: false,
    text: 'image_section_image_image_collections_info_text',
  },
};

import experiment from 'experiment';
import _ from 'lodash';
import type { DocumentServicesObject, CompRef } from 'types/documentServices';
import type {
  SectionDescription,
  MobileComponent,
  DesktopComponent,
} from '../../types';
import type { Behavior } from '@/animations';
import { isStrip } from '../../../utils';

const isDontRemoveStripThatUsedInVeloEnabled = experiment.isOpen(
  'se_migrationDontRemoveStripThatUsedInVelo',
);

function getStripBackground(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
) {
  const [stripColumn] = documentServices.components.getChildren(stripRef);
  const { background } = documentServices.components.design.get(stripColumn);
  return background;
}

function getStripBehaviors(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
) {
  const [stripColumn] = documentServices.components.getChildren(stripRef);
  const stripBehaviors =
    documentServices.components.behaviors.get(stripRef) ?? [];
  const columnBehaviors =
    documentServices.components.behaviors.get(stripColumn) ?? [];
  return [...stripBehaviors, ...columnBehaviors];
}

export function getPageBackground(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
) {
  // TODO investigate the mobile background !== desktop background case
  const { ref } =
    documentServices.pages.background.get(pageRef.id, 'desktop') || {};
  return ref;
}

function isMobileStripInSection(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
  section: SectionDescription,
) {
  if (section.mobile === null) {
    return false;
  }

  const mobileStripRef = documentServices.components.getMobileRef(stripRef);
  const isMobileStripInSection = !!section.mobile.children.find(
    (mobileComponent) => mobileComponent.ref.id === mobileStripRef.id,
  );

  if (!isMobileStripInSection) {
    return false;
  }

  const [stripColumn] = documentServices.components.getChildren(stripRef);
  const [mobileStripColumn] =
    documentServices.components.getChildren(mobileStripRef);

  const stripChildrenIds: Set<string> = new Set(
    documentServices.components.getChildren(stripColumn).map(({ id }) => id),
  );
  const mobileStripChildren: CompRef[] =
    documentServices.components.getChildren(mobileStripColumn);

  return (
    mobileStripChildren.length === stripChildrenIds.size &&
    mobileStripChildren.every(({ id }) => stripChildrenIds.has(id))
  );
}

function hasNoMargins(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
) {
  const stripProperties = documentServices.components.properties.get(stripRef);
  // @ts-expect-error
  return stripProperties.siteMargin === 0;
}

function hasNoDividerShape(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
) {
  const { dividerBottom, dividerTop } =
    documentServices.components.design.get(stripRef);
  return !dividerBottom && !dividerTop;
}

function hasSingleColumn(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
) {
  const stripChildren = documentServices.components.getChildren(stripRef);
  return stripChildren.length === 1;
}

function hasNoLayoutAlignment(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
) {
  const stripColumns = documentServices.components.getChildren(stripRef);
  const stripColumnProperties = documentServices.components.properties.get(
    stripColumns[0],
  );
  // @ts-expect-error
  return stripColumnProperties.alignment === 50;
}

function isRunCodeBehavior(behaviorObj: Behavior): boolean {
  const behaviorType = behaviorObj?.behavior?.type;
  const behaviorName = behaviorObj?.behavior?.name;

  return (
    behaviorType === 'widget' &&
    (behaviorName === 'runCode' || behaviorName === 'runAppCode')
  );
}

function isEntranceAnimation(behaviorObj: Behavior) {
  const behaviorAction = behaviorObj?.action;

  return behaviorAction === 'screenIn';
}

function hasControllerConnection(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): boolean {
  const connections =
    documentServices.platform.controllers.connections.get(compRef);

  return Boolean(Array.isArray(connections) ? connections.length : connections);
}

function hasCustomNickname(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): boolean {
  const nickname = documentServices.components.code.getNickname(compRef);

  return nickname && !nickname.startsWith('columnStrip');
}

export function isForbiddenBehavior(behaviorObj: Behavior): boolean {
  return isEntranceAnimation(behaviorObj) || isRunCodeBehavior(behaviorObj);
}

export function hasForbiddenBehavior(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): boolean {
  // behaviors are set on Strips children - Columns
  const [children] = documentServices.components.getChildren(compRef);
  const behaviors = documentServices.components.behaviors.get(children) || [];
  return behaviors.some((behaviorObj: Behavior) =>
    isForbiddenBehavior(behaviorObj),
  );
}

export function hasNoBackground(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  pageRef: CompRef,
) {
  const pageBackground = getPageBackground(documentServices, pageRef);
  const background = getStripBackground(documentServices, compRef);
  return (
    !background.mediaRef &&
    (background.colorOpacity === 0 ||
      background.color === pageBackground?.color)
  );
}

export function fitSectionLayout(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  section: SectionDescription,
) {
  const compLayout = documentServices.components.layout.get(compRef);
  return (
    section.top === compLayout.y &&
    section.bottom === compLayout.y + compLayout.height
  );
}

export function notOverlapSectionLayout(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
  section: SectionDescription,
) {
  const compLayout = documentServices.components.layout.get(compRef);
  return (
    section.top <= compLayout.y &&
    section.bottom >= compLayout.y + compLayout.height
  );
}

function notOverlapSiblingsLayout(
  documentServices: DocumentServicesObject,
  stripRef: CompRef,
  siblings: CompRef[],
) {
  const stripLayout = documentServices.components.layout.get(stripRef);
  return siblings.every((sibling) => {
    if (sibling.id === stripRef.id) {
      return true;
    }
    const siblingLayout = documentServices.components.layout.get(sibling);
    return (
      stripLayout.y >= siblingLayout.y + siblingLayout.height ||
      siblingLayout.y >= stripLayout.y + stripLayout.height
    );
  });
}

const getPagesWixCode = _.memoize(
  async (documentServices: DocumentServicesObject): Promise<string[]> => {
    try {
      // @ts-expect-error
      const pagesFolder = documentServices.wixCode.fileSystem.getRoots().pages;

      const pages =
        await documentServices.wixCode.fileSystem.getChildren(pagesFolder);

      const filesContentPromises = pages.map((file) =>
        documentServices.wixCode.fileSystem.readFile(file),
      );

      return Promise.all(filesContentPromises);
    } catch {
      return [];
    }
  },
);

async function isNicknameUsedInWixCode(
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): Promise<boolean> {
  if (!isDontRemoveStripThatUsedInVeloEnabled) {
    return false;
  }

  const filesContent = await getPagesWixCode(documentServices);
  const nickname = documentServices.components.code.getNickname(compRef);

  return filesContent.some((file) => file.includes(nickname));
}

async function couldStripBeReplacedWithSection(
  documentServices: DocumentServicesObject,
  {
    pageRef,
    section,
    compRef,
  }: {
    pageRef: CompRef;
    section: SectionDescription;
    compRef: CompRef;
  },
): Promise<boolean> {
  return (
    isStrip(documentServices, compRef) &&
    hasSingleColumn(documentServices, compRef) &&
    hasNoMargins(documentServices, compRef) &&
    hasNoDividerShape(documentServices, compRef) &&
    hasNoLayoutAlignment(documentServices, compRef) &&
    !hasCustomNickname(documentServices, compRef) &&
    !(await isNicknameUsedInWixCode(documentServices, compRef)) &&
    !hasControllerConnection(documentServices, compRef) &&
    !hasForbiddenBehavior(documentServices, compRef) &&
    isMobileStripInSection(documentServices, compRef, section) &&
    (fitSectionLayout(documentServices, compRef, section) ||
      (notOverlapSectionLayout(documentServices, compRef, section) &&
        hasNoBackground(documentServices, compRef, pageRef)))
  );
}

async function couldStripBeMergedToSection(
  documentServices: DocumentServicesObject,
  {
    pageRef,
    section,
    compRef,
    compSiblings,
  }: {
    pageRef: CompRef;
    section: SectionDescription;
    compRef: CompRef;
    compSiblings: CompRef[];
  },
) {
  return (
    isStrip(documentServices, compRef) &&
    hasSingleColumn(documentServices, compRef) &&
    hasNoMargins(documentServices, compRef) &&
    hasNoDividerShape(documentServices, compRef) &&
    hasNoLayoutAlignment(documentServices, compRef) &&
    hasNoBackground(documentServices, compRef, pageRef) &&
    !hasCustomNickname(documentServices, compRef) &&
    !(await isNicknameUsedInWixCode(documentServices, compRef)) &&
    !hasControllerConnection(documentServices, compRef) &&
    !hasForbiddenBehavior(documentServices, compRef) &&
    isMobileStripInSection(documentServices, compRef, section) &&
    notOverlapSectionLayout(documentServices, compRef, section) &&
    notOverlapSiblingsLayout(documentServices, compRef, compSiblings)
  );
}

function flattenStripToSection(
  documentServices: DocumentServicesObject,
  {
    section,
    compRef,
    isSingleChild,
  }: {
    section: SectionDescription;
    compRef: CompRef;
    isSingleChild: boolean;
  },
): SectionDescription {
  const [stripColumn] = documentServices.components.getChildren(compRef);

  const nestedChildren = documentServices.components.getChildren(stripColumn);

  const flattenSectionDescription = { ...section };

  flattenSectionDescription.children = section.children.reduce(
    (children: CompRef[], child: CompRef) => {
      if (child.id === compRef.id) {
        children.push(...nestedChildren);
      } else {
        children.push(child);
      }

      return children;
    },
    [],
  );

  if (isSingleChild) {
    const nestedBackground = getStripBackground(documentServices, compRef);
    const nestedBehaviors = getStripBehaviors(documentServices, compRef);

    flattenSectionDescription.design = {
      ...section.design,
      background: nestedBackground,
    };

    flattenSectionDescription.behaviors = [
      ...section.behaviors,
      ...nestedBehaviors,
    ];
  }

  flattenSectionDescription.childrenToRemove = [
    ...section.childrenToRemove,
    compRef,
  ];
  flattenSectionDescription.childrenToKeepDesktopLayout = [
    ...section.childrenToKeepDesktopLayout,
    ...nestedChildren.map(
      (ref): DesktopComponent => ({
        ref,
        x: documentServices.components.layout.get(ref)?.x,
      }),
    ),
  ];

  return flattenMobileStripToSection(documentServices, {
    section: flattenSectionDescription,
    compRef,
  });
}

function flattenMobileStripToSection(
  documentServices: DocumentServicesObject,
  {
    section,
    compRef,
  }: {
    section: SectionDescription;
    compRef: CompRef;
  },
) {
  const {
    mobile: { children: mobileSectionChildren },
  } = section;

  const mobileStripRef = documentServices.components.getMobileRef(compRef);
  const mobileStripIndex = mobileSectionChildren.findIndex(
    (mobileChild) => mobileChild.ref.id === mobileStripRef.id,
  );
  const mobileStripDescription = mobileSectionChildren[mobileStripIndex];

  const [mobileStripColumn] =
    documentServices.components.getChildren(mobileStripRef);
  const flattenMobileStripChildren: MobileComponent[] =
    documentServices.components
      .getChildren(mobileStripColumn)
      .map((mobileStripChild) =>
        flattenMobileChild(
          documentServices,
          mobileStripChild,
          mobileStripDescription,
        ),
      )
      .filter((item) => Boolean(item));

  const flattenMobileSectionChildren = [...section.mobile.children];

  flattenMobileSectionChildren.splice(
    mobileStripIndex,
    1,
    ...flattenMobileStripChildren,
  );

  return {
    ...section,
    mobile: {
      ...section.mobile,
      children: flattenMobileSectionChildren,
    },
  };
}

export function flattenMobileChild(
  documentServices: DocumentServicesObject,
  mobileCompRef: CompRef,
  mobileStripDescription: MobileComponent,
) {
  const layout = documentServices.components.layout.get(mobileCompRef);

  return layout
    ? {
        desktopParentIndex: mobileStripDescription.desktopParentIndex,
        mobileParentIndex: mobileStripDescription.mobileParentIndex,
        ref: mobileCompRef,
        layout: {
          ...layout,
          y: mobileStripDescription.layout.y + (layout?.y ?? 0),
          height: layout?.height ?? 0,
        },
      }
    : null;
}

async function flattenSingleChildSection(
  documentServices: DocumentServicesObject,
  {
    pageRef,
    section,
  }: {
    pageRef: CompRef;
    section: SectionDescription;
  },
): Promise<SectionDescription> {
  const [compRef] = section.children;
  const couldBeFlattened = await couldStripBeReplacedWithSection(
    documentServices,
    {
      pageRef,
      section,
      compRef,
    },
  );

  return couldBeFlattened
    ? flattenStripToSection(documentServices, {
        section,
        compRef,
        isSingleChild: true,
      })
    : section;
}

async function flattenMultiChildSection(
  documentServices: DocumentServicesObject,
  {
    pageRef,
    section,
  }: {
    pageRef: CompRef;
    section: SectionDescription;
  },
): Promise<SectionDescription> {
  const { children } = section;
  return children.reduce(async (flattenedSection, compRef) => {
    const couldBeFlattened = await couldStripBeMergedToSection(
      documentServices,
      {
        pageRef,
        section: await flattenedSection,
        compRef,
        compSiblings: children,
      },
    );
    return couldBeFlattened
      ? flattenStripToSection(documentServices, {
          section: await flattenedSection,
          compRef,
          isSingleChild: false,
        })
      : flattenedSection;
  }, Promise.resolve(section));
}

export async function removeRedundantNesting(
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sections: SectionDescription[],
): Promise<SectionDescription[]> {
  return Promise.all(
    sections.map(async (section) => {
      const isSingleChildSection = section.children.length === 1;

      return isSingleChildSection
        ? await flattenSingleChildSection(documentServices, {
            pageRef,
            section,
          })
        : await flattenMultiChildSection(documentServices, {
            pageRef,
            section,
          });
    }),
  );
}

import {
  AssistantAction,
  type IAssistantAction,
  type IAssistantTask,
} from '@wix/tour-maker';
import * as stateManagement from '#packages/stateManagement';
import constants from '#packages/constants';
import { EditorAPIKey, QuickEditApiKey } from '#packages/apis';
import { getStepName } from '../utils/getStepName';
import {
  getTooltipOffsetForQuickEditAction,
  getTooltipOffsetForZoomOutAction,
  getFirstSectionComponentByTypes,
  getTooltipOffsetXForInitAction,
  getTooltipOffsetForHelpAction,
  getSectionsBackdropClipPath,
  deleteTextComponentIfNeed,
  getUnHighlightLeftBarMenu,
  getHighlightLeftBarMenu,
  setShouldOpenAgainState,
  setBackdropEventsState,
  removeBackdropClipPath,
  setBackdropClipPath,
  getBackdropClipPath,
  deselectComponents,
  addTextComponent,
  getExitZoomMode,
  selectComponent,
  setHovered,
  sendBI,
} from '../utils/stageUtils';
import { createActions, getClickListener, manageAnimation } from './util';
import { HOOKS, MEDIA, componentTypes } from './constants';
import * as util from '#packages/util';
import type { Shell } from '#packages/apilib';
import { StartOrigin } from './tours.types';
import { exitModes } from '../utils/modesUtils';
import { ToursApiKey } from '../api';
import { serviceTopology } from '#packages/util';

export const TOUR_NAME = 'editorToEditor2Tour';

const currentLanguage = util.languages.getLanguageCode();
const LEARN_MORE_LINK = {
  href: `https://support.wix.com/${currentLanguage}/article/wix-editor-your-new-editor`,
  text: 'FTE_tour_ed_to_ed2_help_learn_more_cta',
};

export const createEditorToEditorTour = (
  shell: Shell,
  startOrigin?: StartOrigin,
): IAssistantTask => {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const tourAPI = shell.getAPI(ToursApiKey);
  const quickEditAPI = editorAPI.host.getAPI(QuickEditApiKey);

  const disableEvents = setBackdropEventsState(HOOKS.tourBackdropWrapper);
  const setShouldOpenAgain = setShouldOpenAgainState(
    editorAPI,
    constants.USER_PREFS.SECTIONS_MIGRATION.SHOW_EDITOR_TO_EDITOR_TOUR,
  );
  const exitZoomMode = getExitZoomMode(editorAPI, TOUR_NAME);
  const highlightLeftBarMenu = getHighlightLeftBarMenu(editorAPI);
  const unHighlightLeftBarMenu = getUnHighlightLeftBarMenu(editorAPI);

  const backDropClickAnimation = (panelName: string) => {
    sendBI(editorAPI, panelName, TOUR_NAME);
    manageAnimation(HOOKS.tourTooltipWrapper, 'tour-card-shake-animation');
  };

  const getBackDropClickListener = getClickListener(HOOKS.tourBackdropWrapper);

  const isHelpMenuStart = startOrigin === StartOrigin.HelpMenu;

  const initAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'init';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .withoutHighlight()
      .hook(HOOKS.deviceSwitch)
      .and.withBackdrop()
      .thatIsOff()
      .and.withCallbacks()
      .onEnter(async () => {
        util.keyboardShortcuts.disable();
        unHighlightLeftBarMenu();
      })
      .onExit(async () => {
        await exitModes(editorAPI);
        disableEvents(false);
        util.keyboardShortcuts.enable();
      })
      .and.withTooltip()
      .withTitle('FTE_tour_ed_to_ed2_welcome_title')
      .thatDoesntPreserveBackground()
      .withContent('FTE_tour_ed_to_ed2_welcome_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp(
        'className',
        'tour-tooltip-opens-down tour-tooltip-panel-intro-step',
      )
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('startOrigin', startOrigin)
      .withCustomProp('stepName', panelName)
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp('mediaSrc', MEDIA.introE2E)
      .thatOpensDown(getTooltipOffsetXForInitAction())
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .withButton('forwardButton')
      .label('FTE_tour_ed_to_ed2_welcome_start_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_ed_to_ed2_welcome_skip_button')
      .completeOnClick()
      .callback(() => setShouldOpenAgain(true))
      .build();
  };

  const zoomOutAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'zoomOut';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .hook(HOOKS.zoom)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.actionsBar)
      .withDisablePointerEvents()
      .and.withBehaviors()
      .thatDelays(1000)
      .thatDoesntScrollToElement()
      .and.withCallbacks()
      .onEnter(async () => {
        const pageRef = editorAPI.pages.getCurrentPage();
        const [firstSection, ...restSections] =
          editorAPI.sections.getPageSections(pageRef);
        const header = editorAPI.dsRead.siteSegments.getHeader();
        const footer = editorAPI.dsRead.siteSegments.getFooter();
        const ACTIONS_BAR_LEDGE = 18;
        const TOP_PADDING = 0;

        const backdropClip = getSectionsBackdropClipPath(
          editorAPI,
          [header, firstSection, ...restSections, footer],
          TOP_PADDING,
          ACTIONS_BAR_LEDGE,
        );

        editorAPI.zoomMode
          .enterZoomMode({
            biParams: { origin: TOUR_NAME },
          })
          .then(() => {
            selectComponent(editorAPI, firstSection, TOUR_NAME);
            setHovered(editorAPI, firstSection);
            setBackdropClipPath(HOOKS.tourBackdrop, backdropClip);
          });

        util.keyboardShortcuts.disable();
        disableEvents(true);
        backDropClickListener.add();
      })
      .onExit(async () => {
        removeBackdropClipPath(HOOKS.tourBackdrop);
        util.keyboardShortcuts.enable();
        disableEvents(false);
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('FTE_tour_ed_to_ed2_zoom_title')
      .withContent('FTE_tour_ed_to_ed2_zoom_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.zoomOut)
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_ed_to_ed2_panel_step_counter',
      )
      .thatOpensDown(getTooltipOffsetForZoomOutAction(editorAPI))
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(async () => {
        if (isHelpMenuStart) {
          deselectComponents(editorAPI);
          highlightLeftBarMenu();
        }
        await exitZoomMode();
      })
      .withButton('forwardButton')
      .label('FTE_tour_ed_to_ed2_panel_next_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_ed_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .callback(exitZoomMode)
      .build();
  };

  const addSectionAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'addSection';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );
    const closeLeftBarPanel = () =>
      editorAPI.store.dispatch(
        stateManagement.panels.actions.closePanelByName(
          constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
          TOUR_NAME,
        ),
      );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .hook(HOOKS.addSection)
      .and.withBehaviors()
      .thatDelays(1000)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.addSectionPanel)
      .and.withBehaviors()
      .thatDoesntScrollToElement()
      .and.withBackdrop()
      .thatIsOn()
      .and.withCallbacks()
      .onEnter(async () => {
        disableEvents(true);
        util.keyboardShortcuts.disable();
        backDropClickListener.add();
        deselectComponents(editorAPI);
        editorAPI.store.dispatch(
          stateManagement.panels.actions.openLeftPanel(
            constants.ROOT_COMPS.LEFTBAR.ADD_SECTION_PANEL_NAME,
            { origin: TOUR_NAME },
          ),
        );
      })
      .onExit(async () => {
        disableEvents(false);
        util.keyboardShortcuts.enable();
        backDropClickListener.remove();
      })
      .and.withTooltip()
      .withTitle('FTE_tour_ed_to_ed2_add_section_title')
      .withContent('FTE_tour_ed_to_ed2_add_section_body')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.addSec)
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_ed_to_ed2_panel_step_counter',
      )
      .thatOpensRight({ offsetX: 513, offsetY: 85 })
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(async () => {
        if (isHelpMenuStart) {
          highlightLeftBarMenu();
        }
        await closeLeftBarPanel();
      })
      .withButton('forwardButton')
      .label('FTE_tour_ed_to_ed2_panel_next_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_ed_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .callback(closeLeftBarPanel)
      .build();
  };

  const quickEditAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'quickEdit';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .withoutHighlight()
      .hook(HOOKS.addSection)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.rightPanel)
      .and.withBehaviors()
      .thatDoesntScrollToElement()
      .and.withBackdrop()
      .thatIsOn()
      .and.withCallbacks()
      .onEnter(async () => {
        disableEvents(true);
        util.keyboardShortcuts.disable();
        backDropClickListener.add();

        const pageRef = editorAPI.pages.getCurrentPage();
        const [sectionRef] = editorAPI.sections.getPageSections(pageRef);

        await selectComponent(editorAPI, sectionRef, TOUR_NAME);
        quickEditAPI.openPanel({
          rootControlCompRef: sectionRef,
          origin: TOUR_NAME,
        });
        setHovered(editorAPI, sectionRef);
      })
      .onExit(async () => {
        disableEvents(false);
        util.keyboardShortcuts.enable();
        backDropClickListener.remove();
        await exitZoomMode();
      })
      .and.withTooltip()
      .withTitle('FTE_tour_ed_to_ed2_quick_edit_title')
      .withContent('FTE_tour_ed_to_ed2_quick_edit_body')
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp(
        // should be replaced once falcon issue
        // with santa-resources will be solved
        // .withCustomProp('mediaSrc', MEDIA.quickEdit)
        'mediaSrc',
        `${serviceTopology.scriptsDomainUrl}/services/santa-resources/packages/santa-resources/resources/editor/editorTours/editorToEditorTour/quickEdit.mp4`,
      )
      .withCustomProp('className', 'tour-tooltip-opens-down')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_ed_to_ed2_panel_step_counter',
      )
      .thatOpensRight(getTooltipOffsetForQuickEditAction())
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(() => {
        if (isHelpMenuStart) {
          deselectComponents(editorAPI);
          highlightLeftBarMenu();
        }
      })
      .withButton('forwardButton')
      .label('FTE_tour_ed_to_ed2_panel_next_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_ed_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .build();
  };

  const dragAndDropAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'dragAndDrop';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .thatWaitForElementToStopMoving()
      .withoutHighlight()
      .hook(HOOKS.zoom)
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.actionsBar)
      .withAdditionalHighlight(HOOKS.addSectionButtonContainer)
      .withAdditionalHighlight(HOOKS.sectionNameLabel)
      .withAdditionalHighlight(HOOKS.sectionEdgeResizeButton)
      .withAdditionalHighlight(HOOKS.gfppButtons)
      .and.withBehaviors()
      .thatDelays(1000)
      .and.withCallbacks()
      .onEnter(async () => {
        util.keyboardShortcuts.disable();
        const pageRef = editorAPI.pages.getCurrentPage();
        const [firstSection] = editorAPI.sections.getPageSections(pageRef);
        const component =
          getFirstSectionComponentByTypes(editorAPI, componentTypes) ||
          (await addTextComponent(editorAPI));

        await selectComponent(editorAPI, component, TOUR_NAME);
        setHovered(editorAPI, firstSection);
        setBackdropClipPath(
          HOOKS.tourBackdrop,
          getBackdropClipPath(editorAPI, firstSection),
        );
        disableEvents(true);
        backDropClickListener.add();
      })
      .onExit(() => {
        deleteTextComponentIfNeed(editorAPI);
        removeBackdropClipPath(HOOKS.tourBackdrop);
        editorAPI.selection.deselectComponents();
        util.keyboardShortcuts.enable();
        disableEvents(false);
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withTooltip()
      .withTitle('FTE_tour_ed_to_ed2_elements_title')
      .withContent('FTE_tour_ed_to_ed2_elements_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('mediaSrc', MEDIA.changeSec)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp(
        'stepCounterLabel',
        'FTE_tour_ed_to_ed2_panel_step_counter',
      )
      .thatOpensDown({ offsetY: 11, offsetX: 30 })
      .withButton('skipButton')
      .onClickGoTo(isHelpMenuStart ? lastStepIndex : lastStepIndex - 1)
      .callback(() => {
        if (isHelpMenuStart) {
          deselectComponents(editorAPI);
          highlightLeftBarMenu();
        }
      })
      .withButton('forwardButton')
      .label('FTE_tour_ed_to_ed2_elements_finish_button')
      .goToNextStep()
      .withButton('backButton')
      .label('FTE_tour_ed_to_ed2_panel_back_button')
      .onClickGoTo(index - 1)
      .build();
  };

  const helpAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'help';
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);
    const backDropClickListener = getBackDropClickListener(
      backDropClickAnimation,
      panelName,
    );

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .withoutHighlight()
      .hook(HOOKS.deviceSwitch)
      .and.withCallbacks()
      .onEnter(async () => {
        deselectComponents(editorAPI);
        disableEvents(true);
        util.keyboardShortcuts.disable();
        backDropClickListener.add();
      })
      .onExit(() => {
        setShouldOpenAgain();
        util.keyboardShortcuts.setContext(
          util.keyboardShortcuts.CONTEXTS.EDITOR,
        );
        util.keyboardShortcuts.enable();
        disableEvents(false);
        highlightLeftBarMenu();
        setShouldOpenAgain();
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.helpMenuBarItem)
      .and.withTooltip()
      .withTitle('FTE_tour_ed_to_ed2_help_title')
      .withContent('FTE_tour_ed_to_ed2_help_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp(
        'className',
        'tour-tooltip-opens-right tour-tooltip-success',
      )
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp('successAnimation', true)
      .withCustomProp('link', LEARN_MORE_LINK)
      .thatOpensDown(getTooltipOffsetXForInitAction())
      .withButton('skipButton')
      .completeOnClick()
      .withButton('forwardButton')
      .label('FTE_tour_ed_to_ed2_help_finish_button')
      .completeOnClick()
      .build();
  };

  const skipAction = (
    index: number,
    lastStepIndex: number,
  ): IAssistantAction => {
    const ACTION_NAME = 'skip';
    const backDropClickListener = getBackDropClickListener(() => {
      highlightLeftBarMenu();
      tourAPI.stopTour();
    });
    const panelName = getStepName(TOUR_NAME, ACTION_NAME, index);

    return new AssistantAction(ACTION_NAME)
      .thatAttachesTo()
      .hook(HOOKS.helpDropDown)
      .and.withCallbacks()
      .onEnter(async () => {
        deselectComponents(editorAPI);
        editorAPI.topBarMenuBar.openDropDown(
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
          constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS
            .HELP_EDITOR_TO_EDITOR_TOUR,
          false,
          { origin: TOUR_NAME },
        );
        util.keyboardShortcuts.disable();
        backDropClickListener.add();
      })
      .onExit(() => {
        editorAPI.topBarMenuBar.closeDropDown(
          constants.ROOT_COMPS.TOPBAR.DROP_PANELS.HELP,
          constants.ROOT_COMPS.TOPBAR.HELP_MENU_ITEMS
            .HELP_EDITOR_TO_EDITOR_TOUR,
        );
        util.keyboardShortcuts.setContext(
          util.keyboardShortcuts.CONTEXTS.EDITOR,
        );
        util.keyboardShortcuts.enable();
        setShouldOpenAgain();
        backDropClickListener.remove();
      })
      .and.withBackdrop()
      .thatIsOn()
      .and.withCustomStage()
      .withAdditionalHighlight(HOOKS.helpMenuBarItem)
      .and.withTooltip()
      .withTitle('FTE_tour_ed_to_ed2_skipped_help_title')
      .withContent('FTE_tour_ed_to_ed2_skipped_help_body')
      .withCustomProp('tourName', TOUR_NAME)
      .withCustomProp('totalSteps', lastStepIndex)
      .withCustomProp('stepName', panelName)
      .withCustomProp('sendBi', editorAPI.bi.event)
      .withCustomProp('className', 'tour-tooltip-opens-right')
      .withCustomProp('customAnimation', 'tour-tooltip-animation-from-the-top')
      .withCustomProp('link', LEARN_MORE_LINK)
      .thatOpensRight(getTooltipOffsetForHelpAction(HOOKS.helpMenuBarItem))
      .withButton('skipButton')
      .callback(highlightLeftBarMenu)
      .completeOnClick()
      .withButton('forwardButton')
      .label('FTE_tour_ed_to_ed2_help_finish_button')
      .callback(highlightLeftBarMenu)
      .completeOnClick()
      .withButton('backButton')
      .label('FTE_tour_ed_to_ed2_panel_back_button')
      .onClickGoTo(index - 2)
      .build();
  };

  const actionsList = [
    initAction,
    zoomOutAction,
    addSectionAction,
    ...(quickEditAPI.isQuickEditAvailable() ? [quickEditAction] : []),
    dragAndDropAction,
    helpAction,
    skipAction,
  ];

  const actions = createActions(actionsList, actionsList.length);

  return {
    id: TOUR_NAME,
    onEnter: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: false });
        editorAPI.panelManager.closeAllPanels();
      },
    },
    onExit: {
      callback: () => {
        editorAPI.autosaveManager.init({ enabled: true }, true);
        deleteTextComponentIfNeed(editorAPI);
      },
    },
    actions,
  } as IAssistantTask;
};

import { isDebugMode, url } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter/dist';

export const getEditorVersion = () => {
  return window.editorModel.editorVersion;
};

export function isContentInjectionLogUrlParam() {
  const contentInjectionLog = url.getParameterByName('contentInjectionLog');
  return isDebugMode() && !!contentInjectionLog;
}

export const validateJsonStructure = (json: string) => {
  return JSON.parse(json);
};

export function reportErrorOnInjection(e: any, contentToInject: string | null) {
  ErrorReporter.captureException(e, {
    tags: { failedToInjectContentInContentInjection: true },
    extra: { contentToInject },
  });
}

export function reportOnNullOrEmptyContent(component: string) {
  ErrorReporter.captureMessage(
    'Content injection Null or Empty data from CAAS',
    {
      tags: { failedToInjectContentInContentInjection: true },
      extra: {
        component,
      },
    },
  );
}

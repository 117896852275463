import type { ISettingsData } from '../pagesPanel';
import type { IMenuItemId } from '#packages/baseUI';
import type React from 'react';

export type OnSettingsChangeHandler = (
  settingsData: Partial<ISettingsData>,
) => void;

export type OnItemAddedHandler = (itemId: string) => void;

export type OnRenameHandler = (itemId?: IMenuItemId, newName?: string) => void;

export enum DefaultActionTypes {
  RENAME_TYPE_DEPRECATED = 'Pages_Actions_Page_Rename',
  RENAME_TYPE = 'page_rename',
  HIDE_SHOW_TYPE = 'page_hide_show',
  SET_AS_HOMEPAGE_TYPE = 'page_set_as_homepage',
  DELETE_TYPE = 'page_delete',
  REMOVE_FROM_MAIN_MENU_TYPE = 'page_remove_from_main_menu',
  REMOVE_TYPE = 'page_remove',
  DUPLICATE_TYPE = 'page_duplicate',
  COPY_TYPE = 'page_copy',
}

export interface BasicAction {
  defaultActionType?: DefaultActionTypes;
  title: string;
  symbolName?: string;
  symbol?: React.FC<any>;
  disabled?: boolean;
  tooltipText?: string;
  onClick: (
    id?: string,
    newTitle?: string,
    biAction?: string,
    source_type?: string,
  ) => void;
}

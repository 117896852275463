// @ts-nocheck
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '#packages/constants';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import mobileWizardFirstStep from './mobileWizardFirstStep';
import mobileWizardSelectTinyMenu from './mobileWizardSelectTinyMenu';
import mobileWizardSelectHeader from './mobileWizardSelectHeader';
import mobileWizardSelectQab from './mobileWizardSelectQab';
import mobileWizardSelectBackToTop from './mobileWizardSelectBackToTop';
import mobileWizardLastStep from './mobileWizardLastStep';
import { Button, Composites } from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';
import ProgressBar from './progressBar';
import { browserUtil, cx } from '#packages/util';

const MOBILE_WIZARD_OPENING_STATE =
  constants.MOBILE.MOBILE_WIZARD.OPENING_STATE;
const selectors = stateManagement.mobile.selectors.mobileWizard;
const actions = stateManagement.mobile.actions.mobileWizard;

// WIZARD UI COMPONENTS
const targetComponents = {
  MOBILE_WIZARD_FIRST_STEP: mobileWizardFirstStep,
  TINY_MENU: mobileWizardSelectTinyMenu,
  SITE_HEADER: mobileWizardSelectHeader,
  QUICK_ACTION_BAR: mobileWizardSelectQab,
  BACK_TO_TOP_BUTTON: mobileWizardSelectBackToTop,
  MOBILE_WIZARD_LAST_STEP: mobileWizardLastStep,
};

//TYPE WAS GENERATED, remove this line when reviewed
interface MobileWizardProps {
  expanded?: boolean;
  currentStep?: string;
  stepsToShow?: Array<string>;
  init?: FunctionFixMe;
  destroy?: FunctionFixMe;
  nextStep?: FunctionFixMe;
  previousStep?: FunctionFixMe;
  wixLogoClick?: FunctionFixMe;
  skipWizard?: FunctionFixMe;
  setStep?: FunctionFixMe;
  userSelections?: AnyFixMe;
}

class MobileWizard extends React.Component<MobileWizardProps> {
  static displayName = 'mobileWizard';

  static propTypes = {
    expanded: PropTypes.bool,
    currentStep: PropTypes.string,
    stepsToShow: PropTypes.arrayOf(PropTypes.string),
    init: PropTypes.func,
    destroy: PropTypes.func,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func,
    wixLogoClick: PropTypes.func,
    skipWizard: PropTypes.func,
    setStep: PropTypes.func,
    userSelections: PropTypes.object,
  };

  state = {
    currentStepIndex: 0,
  };

  onProgressBarStepClick = (stepIndex) => {
    const nextStep = this.props.stepsToShow[stepIndex];
    this.props.setStep(nextStep);
  };

  getCurrentStepComponent = () => {
    const currentStepComponent = targetComponents[this.props.currentStep];
    return currentStepComponent
      ? React.createElement(currentStepComponent, {})
      : undefined;
  };

  componentDidMount() {
    this.props.init();
  }

  componentWillUnmount() {
    this.props.destroy();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/index-of
    const currentStepIndex = _.indexOf(
      nextProps.stepsToShow,
      nextProps.currentStep,
    );
    this.setState({ currentStepIndex });
  }

  shouldShowWizardActions = () => {
    return !(this.isFirstStep() || this.isLastStep());
  };

  shouldShowSkipButton = () => {
    return !(this.isFirstStep() || this.isLastStep());
  };

  isFirstStep = () => {
    return this.state.currentStepIndex === 0;
  };

  isLastStep = () => {
    return this.state.currentStepIndex === this.props.stepsToShow.length - 1;
  };

  render() {
    return (
      <div
        onContextMenu={browserUtil.preventDefaultRightClick}
        data-aid="mobile-wizard"
        className={cx({
          'mobile-wizard': true,
          'mobile-wizard-expanded': this.props.expanded,
        })}
      >
        <div className="mobile-wizard-top-bar">
          <span
            onClick={() => {
              this.props.wixLogoClick();
            }}
            className="top-bar-left-wix-logo"
          >
            <symbols.symbol name="wixLogo" />
          </span>

          {this.shouldShowSkipButton() ? (
            <Button
              automationId="mobileWizard-top-bar-skip"
              key="mobile-wizard-top-bar-skip"
              onClick={() => {
                this.props.skipWizard();
              }}
              className="btn-sm btn-text mobile-wizard-top-bar-skip"
            >
              {translate('Mobile_Wizard_Welcome_Skip_Link')}
            </Button>
          ) : null}
        </div>
        <div className="mobile-wizard-left-half">
          <div className="mobile-wizard-inner">
            <ProgressBar
              current={this.state.currentStepIndex}
              total={this.props.stepsToShow.length}
              onStepClick={(stepIndex) => {
                this.onProgressBarStepClick(stepIndex);
              }}
            />

            <div className="current-step-container">
              {this.getCurrentStepComponent()}
            </div>

            {this.shouldShowWizardActions() ? (
              <Composites.ActionSetHorizontal
                key="alt"
                className="mobile-wizard-actions"
              >
                <Button
                  onClick={() => {
                    this.props.previousStep();
                  }}
                  automationId="mobile-wizard-previous"
                  className="btn-confirm-secondary"
                >
                  <span>
                    {translate('Mobile_Wizard_Welcome_Previous_Button')}
                  </span>
                </Button>
                <Button
                  onClick={() => {
                    this.props.nextStep();
                  }}
                  automationId="mobile-wizard-next"
                >
                  <span>{translate('Mobile_Wizard_Welcome_Next_Button')}</span>

                  <svg width="5" height="9" viewBox="0 0 5 9">
                    <path
                      fill="#FFF"
                      fillRule="evenodd"
                      d="M0 0h1v1H0zm1 1h1v1H1zm1 1h1v1H2zm1 1h1v1H3zm1 1h1v1H4zM3 5h1v1H3zM2 6h1v1H2zM1 7h1v1H1zM0 8h1v1H0z"
                    />
                  </svg>
                </Button>
              </Composites.ActionSetHorizontal>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function stateToProps({ state }) {
  const expanded =
    selectors.getMobileWizardOpeningState(state) ===
    MOBILE_WIZARD_OPENING_STATE.IN_PROGRESS;
  return {
    expanded,
    currentStep: selectors.getCurrentStep(state),
    stepsToShow: selectors.getStepsToShow(state),
    userSelections: selectors.getUserSelections(state),
  };
}

const dispatchToProps = (dispatch) => ({
  init() {
    dispatch(actions.buildWizardSteps());
  },
  destroy() {
    dispatch(actions.clearAfterMobileWizardCloses());
  },
  skipWizard() {
    dispatch(actions.skipWizard());
  },
  nextStep() {
    dispatch(actions.setNextStep());
  },
  previousStep() {
    dispatch(actions.setPreviousStep());
  },
  wixLogoClick() {
    dispatch(actions.wixLogoClick());
  },
  setStep(nextStep) {
    dispatch(actions.setStepFromProgressBar(nextStep));
  },
});

export default util.hoc.connect(
  util.hoc.STORES.STATE_ONLY,
  stateToProps,
  dispatchToProps,
)(MobileWizard);

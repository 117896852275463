import React from 'react';
import { Button, Text } from '@wix/wix-base-ui';
import { connectWithScope, type InferComponentProps } from '#packages/apilib';
import * as symbols from '@wix/santa-editor-symbols';
import { translate } from '#packages/i18n';
import { PromoteScope } from '../../../scope';
import { Bullets } from '../common/bullets';
import { MarketingPanel } from '../common/marketingPanel';
import promoteBi from '../common/bi/promoteBi';

export interface OwnProps {}

type GoogleAdsPanelProps = InferComponentProps<
  typeof mapStateToProps,
  typeof mapDispatchToProps,
  OwnProps
>;

const TAB_NAME = 'GoogleAdsPanel';
const PRODUCT_NAME = 'GoogleAds';

const GoogleAdsPanel = React.memo<GoogleAdsPanelProps>(
  (props: GoogleAdsPanelProps) => {
    React.useEffect(() => {
      props.sendBi(promoteBi.events.TAB_VIEW, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onPressCTA = () => {
      props.sendBi(promoteBi.events.PRODUCT_CLICK, {
        ...props.biBaseParams,
        tab_name: TAB_NAME,
        product_name: PRODUCT_NAME,
      });
      props.navigateToGoogleAds();
    };

    return (
      <MarketingPanel
        logo={
          <symbols.symbol name="promoteGoogleAdsPanelLogo" className="logo" />
        }
        title={
          <Text
            skin="standard"
            size="large"
            weight="bold"
            enableEllipsis={false}
          >
            {'Promote_Google_Ads_Title'}
          </Text>
        }
        body={
          <Bullets
            bullets={[
              'Promote_Google_Ads_Bullet1',
              'Promote_Google_Ads_Bullet2',
              'Promote_Google_Ads_Bullet3',
            ]}
          />
        }
        actions={
          <Button onClick={onPressCTA} className="btn-md">
            {translate('Promote_Google_Ads_Main_Button')}
          </Button>
        }
      />
    );
  },
);

const mapStateToProps = (scope: PromoteScope) => {
  const biBaseParams = promoteBi.biUtils.getBiBaseParams(scope);

  return {
    biBaseParams,
    msid: scope.editorAPI.dsRead.generalInfo.getMetaSiteId(),
  };
};

const mapDispatchToProps = (scope: PromoteScope) => {
  return {
    navigateToGoogleAds: () =>
      scope.editorAPI.account.openSettings({ path: '/google-ads' }),
    sendBi: (event: ValueOf<typeof promoteBi.events>, parameters: object) => {
      promoteBi.report(event, parameters);
    },
  };
};

GoogleAdsPanel.displayName = 'GoogleAdsPanel';

export default connectWithScope(
  () => PromoteScope,
  GoogleAdsPanel,
  mapStateToProps,
  mapDispatchToProps,
);

import type { Scope } from './scope';
import type { CompRef } from 'types/documentServices';
import type { ResizeType } from './types';
import _ from 'lodash';

class ResizeHandler {
  private store;
  private resizeService;

  private RESIZE_HANDLER_THROTTLE = 100;

  constructor(scope: Scope) {
    this.store = scope.store;
    this.resizeService = scope.resizeService;
  }

  private areComponentsEqual(prev: CompRef[], next: CompRef[]): boolean {
    if (prev.length !== next.length) {
      return false;
    }

    const prevSet = new Set(prev.map((comp) => comp.id));

    for (const comp of next) {
      if (!prevSet.has(comp.id)) {
        return false;
      }
    }

    return true;
  }

  onResizeStart = ({ compRef: parentCompRef }: { compRef: CompRef }) => {
    const childrensCompsPositions =
      this.resizeService.getChildrensCompsPositions(parentCompRef);

    const isRepeaterOrChildrenOfRepeater =
      this.resizeService.isRepeaterOrChildrenOfRepeater(parentCompRef);

    this.store.startResize({
      parentCompRef,
      childrensCompsPositions,
      shouldCalculatePositionsOnEveryMove: isRepeaterOrChildrenOfRepeater,
    });
  };

  onResizeMove = _.throttle(
    ({
      resizeType,
      proportionally,
    }: {
      resizeType: ResizeType;
      proportionally?: boolean;
    }) => {
      const parentCompRef = this.store.getParentCompRef();
      const lastHighlightedComponents = this.store.getHighlightedComponents();

      let childrensCompsPositions;

      if (
        proportionally ||
        this.store.getIsProportionally() !== proportionally
      ) {
        childrensCompsPositions =
          this.resizeService.getChildrensCompsPositions(parentCompRef);

        this.store.updateChildrensCompsPositions({
          childrensCompsPositions,
          proportionally,
        });
      } else {
        childrensCompsPositions =
          this.store.getShouldCalculatePositionsOnEveryMove()
            ? this.resizeService.getChildrensCompsPositions(parentCompRef)
            : this.store.getChildrensCompsPositions();
      }

      const childrensBlockingResize =
        this.resizeService.getChildrensBlockingResize(
          parentCompRef,
          childrensCompsPositions,
          { resizeType },
        );

      const shouldUpdateStore = !this.areComponentsEqual(
        childrensBlockingResize,
        lastHighlightedComponents,
      );

      if (shouldUpdateStore) {
        this.store.addHighlightedComponents(childrensBlockingResize);
      }
    },
    this.RESIZE_HANDLER_THROTTLE,
  );

  onResizeEnd = () => {
    this.store.endResize();
  };
}

export const createResizeHandler = (scope: Scope) => new ResizeHandler(scope);

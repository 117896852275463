import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 261,
        height: 100,
        x: 28,
        y: 67,
        scale: 1,
        rotationInDegrees: 328.6846901161898,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'xh88g',
      },
      parent: 'comp-kgt5j4n4',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        alt: '',
        svgId: 'a3c153_c6d17c44a5d242e8b3e1dac1ab02f49d.svg',
        title: 'r.svg',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'xh88g',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        overrideColors: {
          color1: '#F58144',
        },
        shapeStyle: {
          opacity: 1,
          strokeWidth: 4,
          stroke: '#5E97FF',
          strokeOpacity: 1,
          enableStroke: false,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
      },
      transformations: {
        type: 'TransformData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
      },
      scopedTransformations: {
        'variants-kgt5j4no': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xh88g',
          },
          translate: {
            x: {
              type: 'px',
              value: 0,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 228,
          height: 88,
          x: 25,
          y: 43,
          scale: 1,
          rotationInDegrees: 328.6846901161898,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5j4np',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 238,
        height: 34,
        x: 44,
        y: 100,
        scale: 1,
        rotationInDegrees: 329,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'xh88g',
      },
      parent: 'comp-kgt5j4n4',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        text: `<h3 class="font_3" style="font-size:26px; text-align:center; color:#000000;"><span style="font-family:dinneuzeitgroteskltw01-_812426,sans-serif"><span style="font-size:26px"><span style="letter-spacing:0.15em">${translate(
          'interactions_preset_shop_now_text_1',
        )}</span></span></span></h3>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xh88g',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
      },
      scopedTransformations: {
        'variants-kgt5j4no': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xh88g',
          },
          translate: {
            x: {
              type: 'px',
              value: -10,
            },
            y: {
              type: 'px',
              value: -15,
            },
          },
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 180,
          height: 32,
          x: 47,
          y: 70,
          scale: 0.9615384615384616,
          rotationInDegrees: 329,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'xh88g',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5j4nr4',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'skins.viewer.VectorImageSkin',
      layout: {
        width: 5,
        height: 149,
        x: 156,
        y: 45,
        scale: 1,
        rotationInDegrees: 58.93440610376763,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.VectorImage',
      metaData: {
        pageId: 'xh88g',
      },
      parent: 'comp-kgt5j4n4',
      data: {
        type: 'VectorImage',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        alt: '',
        svgId: '2e9fc50e340c4d9c9ba1d2c9de317b17.svg',
        title: 'Line',
      },
      props: {
        type: 'VectorImageProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xh88g',
        },
        displayMode: 'fit',
        flip: 'none',
      },
      design: {
        type: 'VectorImageDesignData',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        shapeStyle: {
          opacity: 1,
        },
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
      },
      scopedTransformations: {
        'variants-kgt5j4no': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xh88g',
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.VectorImage',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'skins.viewer.VectorImageSkin',
      },
      mobileStructure: {
        layout: {
          width: 10,
          height: 298,
          x: 137,
          y: 42,
          scale: 1,
          rotationInDegrees: 58.93440610376763,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5j4nt2',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 202,
        height: 19,
        x: 68,
        y: 126,
        scale: 1,
        rotationInDegrees: 328.4727392307823,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'xh88g',
      },
      parent: 'comp-kgt5j4n4',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
        text: `<h6 class="font_6" style="font-size:16px; text-align:center; color:#000000;"><span style="letter-spacing:0.05em;"><span style="font-family:dinneuzeitgroteskltw01-_812426,sans-serif"><span style="font-size:16px">${translate(
          'interactions_preset_shop_now_text_2',
        )}</span></span></span></h6>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'xh88g',
        },
        brightness: 1,
        packed: true,
        verticalText: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
      },
      transformations: {
        type: 'TransformData',
        hidden: true,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'xh88g',
        },
      },
      scopedTransformations: {
        'variants-kgt5j4no': {
          type: 'TransformData',
          hidden: false,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'xh88g',
          },
          rotate: 0,
        },
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 120,
          height: 16,
          x: 87,
          y: 110,
          scale: 0.84,
          rotationInDegrees: 328.4727392307823,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'xh88g',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5j4nv',
          author: 'studio',
        },
      },
    },
  ],
  skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  layout: {
    width: 321,
    height: 240,
    x: 328,
    y: 130,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'xh88g',
  },
  parent: 'xh88g',
  variants: {
    'variants-kgt5j4no': {
      componentId: 'comp-kgt5j4n4',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'xh88g',
      },
    },
  },
  scopedTransformations: {
    'variants-kgt5j4no': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'xh88g',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'xh88g',
    },
    style: {
      properties: {
        'alpha-bg': '0',
        'alpha-brd': '1',
        bg: '#C8EEF5',
        'boxShadowToggleOn-shd': 'false',
        brd: 'color_15',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'theme',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 195,
      x: 20,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5j4n4',
      author: 'studio',
    },
  },
  activeModes: {},
};

import React, { type ComponentType } from 'react';
import _ from 'lodash';

import { isResponsiveEditor } from '@wix/santa-editor-utils';

import * as tpa from '#packages/tpa';
import * as util from '#packages/util';

import {
  mapDispatchToProps,
  mapStateToProps,
} from './blogWelcomeSectionMapper';
import type {
  BlogWelcomeDispatchProps,
  BlogWelcomeOwnProps,
  BlogWelcomeStateProps,
} from './types';

const magicMigrationTextContent = {
  promotionalList: [
    'Add_NewBlog_Migration_No_Support_Panel_Bullet_1',
    'Add_NewBlog_Migration_No_Support_Panel_Bullet_2',
    'Add_NewBlog_Migration_No_Support_Panel_Bullet_3',
  ],
  title: 'Add_NewBlog_Migration_No_Support_Panel_Title',
  subtitle: 'Add_NewBlog_Migration_No_Support_Panel_Subtitle',
  mainActionLabel: 'Add_NewBlog_Migration_No_Support_Panel_Bullet_CTA',
  secondaryActionLabel: 'Add_NewBlog_Migration_No_Support_Panel_Bullet_Link',
};

const quickMigrationTextContent = {
  promotionalList: [
    'Add_NewBlog_Migration_Promo_Bullet_1',
    'Add_NewBlog_Migration_Promo_Bullet_2',
    'Add_NewBlog_Migration_Promo_Bullet_3',
  ],
  title: 'Add_NewBlog_Migration_Promo_Title',
  subtitle: 'Add_NewBlog_Migration_Promo_Subtitle',
  mainActionLabel: 'Add_NewBlog_Migration_Promo_Button_Switch',
  secondaryActionLabel: 'Add_NewBlog_Migration_Promo_LearnMore',
};

const firstTimeTextContent = {
  promotionalList: [
    'Add_NewBlog_First_Time_Bullet_1',
    'Add_NewBlog_First_Time_Bullet_2',
    'Add_NewBlog_First_Time_Bullet_3',
    'Add_NewBlog_First_Time_Bullet_4',
  ],
  title: 'Add_NewBlog_First_Time_Title',
  subtitle: 'Add_NewBlog_First_Time_Subtitle',
  mainActionLabel: 'Add_NewBlog_First_Time_Button',
  secondaryActionLabel: 'Add_NewBlog_First_Time_LinkCaption',
};

class BlogWelcomeSection extends React.Component<
  BlogWelcomeOwnProps & BlogWelcomeStateProps & BlogWelcomeDispatchProps
> {
  static displayName = 'BlogWelcomeSection';

  state = {
    text: this.getContentText(),
  };

  private isNewWorkspace: boolean = util.workspace.isNewWorkspaceEnabled();

  getContentText() {
    if (
      this.props.siteHasOldBlog &&
      this.props.isNewBlogMagicMigrationEnabled
    ) {
      return magicMigrationTextContent;
    }

    if (
      this.props.siteHasOldBlog &&
      this.props.isNewBlogQuickMigrationEnabled
    ) {
      return quickMigrationTextContent;
    }

    return firstTimeTextContent;
  }

  getMainAction = () => {
    if (
      this.props.siteHasOldBlog &&
      this.props.isNewBlogMagicMigrationEnabled
    ) {
      return this.props.magicMigrateOldBlog;
    }

    if (
      this.props.siteHasOldBlog &&
      this.props.isNewBlogQuickMigrationEnabled
    ) {
      return this.props.migrateOldBlog;
    }

    if (_.isFunction(this.props.installAppOverride)) {
      return _.partial(
        this.props.installAppOverride,
        tpa.superApps.superAppsConstants.NEW_BLOG_APP_DEF_ID,
      );
    }
    return this.props.installBlogApp;
  };

  getSecondaryAction = () => {
    const shouldShowMigationHelp =
      this.props.siteHasOldBlog &&
      (this.props.isNewBlogQuickMigrationEnabled ||
        this.props.isNewBlogMagicMigrationEnabled);
    if (shouldShowMigationHelp) {
      return () =>
        this.props.openHelpCenter('6f26af10-0e5b-44e8-a92f-f89d424822fe');
    }
    if (isResponsiveEditor()) {
      return this.props.openEditorXHelpPanel;
    }
    return this.props.openAppMarket;
  };

  getBannerImgSrc() {
    let { bannerImage: bannerSrc } = this.props;

    if (!bannerSrc) {
      bannerSrc =
        'addPanelData/sections/blogWelcomeSectionOldEditor_en/blogWelcomeSectionOldEditor_en.png';

      if (this.isNewWorkspace) {
        bannerSrc = util.browserUtil.isRetina()
          ? 'addPanelData/sections/blogWelcomeSectionOldEditor_en/newWorkspace_blogWelcomeSectionOldEditor_en@x2.png'
          : 'addPanelData/sections/blogWelcomeSectionOldEditor_en/newWorkspace_blogWelcomeSectionOldEditor_en.png';
      }

      if (isResponsiveEditor()) {
        bannerSrc =
          'addPanelData/sections/blogWelcomeSection_en/blogWelcomeSection_en.png';
      }

      if (
        this.props.siteHasOldBlog &&
        this.props.isNewBlogMagicMigrationEnabled
      ) {
        bannerSrc =
          'addPanelData/sections/blogWelcomeMagicMigration_en/blogWelcomeMagicMigration_en.v2.png';
      }
    }
    return util.media.getMediaUrl(bannerSrc);
  }

  render() {
    const WelcomeSection = this.props.welcomeSectionTemplate;
    const bannerImg = this.getBannerImgSrc();

    return (
      <div>
        <WelcomeSection
          bannerImgSrc={bannerImg}
          title={this.state.text.title}
          subtitle={this.state.text.subtitle}
          promotionalListItems={this.state.text.promotionalList}
          mainActionLabel={this.state.text.mainActionLabel}
          secondaryActionLabel={this.state.text.secondaryActionLabel}
          onMainActionClick={this.getMainAction()}
          onSecondaryActionClick={this.getSecondaryAction()}
        />
      </div>
    );
  }
}

export default util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(
  util.hoc.renderWhenMutated(BlogWelcomeSection),
) as ComponentType<BlogWelcomeOwnProps>;

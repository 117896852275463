import { EditorAPIKey, ScrollApiKey } from '#packages/apis';
import { createScrollApi } from './scrollApi';

import type { EntryPoint } from '#packages/apilib';

export const ScrollApiEntryPoint: EntryPoint = {
  name: 'ScrollApi',
  declareAPIs: () => [ScrollApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  async attach(shell) {
    shell.contributeAPI(ScrollApiKey, () => createScrollApi(shell));
  },
};

import type { MapDispatchToPropsFunction } from 'types/redux';
import * as tpa from '#packages/tpa';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';
import * as util from '#packages/util';
import { wrapWithPropsTransformer } from '../utils';

import type { EditorState } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';
import type { StateMapperArgs } from 'types/redux';
import type { Dispatch } from 'redux';

export interface InstallAppWelcomeDispatchProps {
  installAppMainAction: (appDefinition: string) => void;
}

const getEditorAPI = (
  dispatch: Dispatch,
  getState: () => EditorState,
  { editorAPI }: StateMapperArgs,
): EditorAPI => editorAPI;

const mapDispatchToProps: MapDispatchToPropsFunction<
  InstallAppWelcomeDispatchProps,
  {}
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    installAppMainAction: async (appDefinitionId: string) => {
      if (!appDefinitionId) {
        throw new Error(`add panel app data missing appDefinitionId`);
      }
      const marketData =
        await editorAPI.dsRead.tpa.appMarket.getDataAsync(appDefinitionId);
      const shouldNavigate = marketData?.appFields?.shouldNavigate ?? true;
      const type = tpa.appMarketUtils.getAppType(marketData);
      const options = {
        shouldNavigate,
        headlessInstallation: Boolean(
          tpa.appMarketUtils.isHeadlessInstallation(marketData),
        ),
        showPageAddedPanel: !marketData?.doNotShowPageAddedPanel,
      };
      tpa.services.tpaAddAppService.addApp(
        editorAPI,
        appDefinitionId,
        null,
        null,
        type,
        tpa.constants.BI.type.ADD_APP_ADD_PANEL,
        false,
        { adding_method: 'click' },
        options,
        async () => {
          if (util.sections.isSectionsEnabled()) {
            await editorAPI.waitForChangesAppliedAsync();
            const selectedComponents =
              editorAPI.selection.getSelectedComponents();
            if (selectedComponents.length === 1) {
              editorAPI.components.hooks.componentAddedToStage.fire({
                compRef: selectedComponents[0],
                type: 'click',
                origin: 'add_panel',
              });
            }
          }
        },
        {
          type: EditorType.Classic,
          initiator: InstallInitiator.Editor,
          info: {
            type: InstallationOriginType.AddPanel,
          },
        },
      );
    },
  };
};

export const installAppWelcomeMapper = wrapWithPropsTransformer<
  InstallAppWelcomeDispatchProps,
  {}
>(mapDispatchToProps, (props) => ({
  installAppMainAction: props.installAppMainAction,
}));

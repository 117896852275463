// @ts-nocheck
import React from 'react';
import { Button, Composites, Divider, RichText } from '@wix/wix-base-ui';
import * as compPanelInfra from '#packages/compPanelInfra';
import * as Symbols from '@wix/santa-editor-symbols';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';

class PlatformManageContentPanel extends React.Component<PlatformManageContentPanelProps> {
  mainActionClick = () => {
    this.props.sendBI(coreBi.events.manageContent.actionClick, {
      action_name: 'main',
      origin: mapOriginToBIField(this.props.origin),
    });
    this.props.closePanel(PanelResolveType.MAIN_ACTION);
  };

  secondaryActionClick = () => {
    this.props.sendBI(coreBi.events.manageContent.actionClick, {
      action_name: 'secondary',
    });
    this.props.closePanel(PanelResolveType.SECONDARY_ACTION);
  };

  closeActionClick = () => {
    this.props.sendBI(coreBi.events.panels.PANEL_CLOSED, {
      panel_name: this.props.panelName,
    });
    this.props.closePanel(PanelResolveType.CLOSE_ACTION);
  };

  getFrameProps = () => {
    return util.panelUtils.getFrameProps(this.props);
  };

  render() {
    return (
      <compPanelInfra.compPanelFrame
        title={this.props.title}
        helpId={this.props.helpId}
        noHelpBtn={!this.props.helpId}
        shouldTranslate={false}
        useNativeScroll={true}
        onClose={this.closeActionClick}
        panelName="platformComponentPanel"
        className={util.cx(
          'manage-content-panel',
          this.props.secondaryActionText
            ? 'manage-content-panel-full'
            : 'manage-content-panel-compact',
        )}
        automationId="platform-manage-content-panel"
        {...this.getFrameProps()}
        style={{
          width: '290px',
          ...this.getFrameProps()?.style,
        }}
      >
        <div className="content-section">
          <Composites.RichText>
            <RichText automationId="description-text">
              {this.props.descriptionText}
            </RichText>
          </Composites.RichText>

          <Divider short />
        </div>

        <Composites.ButtonLargeFixedBottom>
          <Button
            onClick={this.mainActionClick}
            className="main-action-button"
            automationId="main-action-button"
          >
            <Symbols.symbol name="light_icon_change" />
            &nbsp;
            <span data-aid="main-action-text">{this.props.mainActionText}</span>
          </Button>

          {this.props.secondaryActionText ? (
            <Button
              onClick={this.secondaryActionClick}
              automationId="secondary-action-button"
              className="secondary-action-button btn-text"
            >
              {this.props.secondaryActionText}
            </Button>
          ) : null}
        </Composites.ButtonLargeFixedBottom>
      </compPanelInfra.compPanelFrame>
    );
  }
}

enum PanelResolveType {
  MAIN_ACTION = 'mainActionClicked',
  SECONDARY_ACTION = 'secActionClicked',
  CLOSE_ACTION = 'closeActionClicked',
}

interface PlatformManageContentPanelProps {
  closePanel(panelResolveType): void;
  openHelpCenter(): void;
  onPanelClose(): void;
  sendBI(): void;
  helpId?: string;
  panelName: string;
  title: string;
  descriptionText: string;
  secondaryActionText?: string;
  mainActionText: string;
  origin?: string;
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const closePanel = (origin) => {
    dispatch(
      stateManagement.panels.actions.closePanelByNameAction(ownProps.panelName),
    );
    ownProps.onPanelClose(origin);
  };

  const openHelpCenter = () =>
    dispatch(stateManagement.panels.actions.openHelpCenter(ownProps.helpId));

  const sendBI = (descriptor, params) =>
    dispatch(stateManagement.bi.actions.event(descriptor, params));

  return {
    closePanel,
    openHelpCenter,
    sendBI,
  };
};

const mapOriginToBIField = (origin: string) => {
  switch (origin) {
    case 'gfpp':
      return 'gfpp';

    case 'settingPanel':
      return 'rce_panel';

    default:
      return origin;
  }
};
export default util.hoc.connect(
  util.hoc.STORES.DS_ONLY,
  null,
  mapDispatchToProps,
)(PlatformManageContentPanel);

import _ from 'lodash';
import constants from '#packages/constants';
import {
  environment,
  editorDataFunction,
  componentInstanceData,
} from '@wix/editor-component-data-resolver';

import type { ComponentEditorMetaDataDefinition } from '../types';

const metaData: ComponentEditorMetaDataDefinition = {
  horizontallyMovable: false,
  movable: false,
  rotatable: false,
  verticallyMovable: false,
  verticallyResizable: false,
  skipInQuickEditPanel: true,
  multiselectable: false,
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values
  disabledKnobs: _.values(constants.RESIZE_SIDES),
  controlledByParent: true,
  selectable: editorDataFunction(
    environment('isMobileEditor'),
    componentInstanceData('skin'),
    (isMobileEditor: boolean, skin: string) =>
      !isMobileEditor && !(skin === 'skins.viewer.mediaControlsNoControlsSkin'),
  ),
};

export default metaData;

import constants from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';
import type { AfterDuplicatePlugin } from 'types/editorPlugins';
import * as stateManagement from '#packages/stateManagement';

const ALIGNMENT_MAP: Record<string, string> = {
  left: 'justifyleft',
  center: 'justifycenter',
  right: 'justifyright',
  justify: 'justifyblock',
} as const;

export const textAfterDuplicatePlugin: AfterDuplicatePlugin = (
  editorAPI: EditorAPI,
  compRefs: CompRef[],
) => {
  if (!editorAPI.isMobileEditor()) return;

  for (const compRef of compRefs) {
    if (editorAPI.components.getType(compRef) === constants.COMP_TYPES.TEXT) {
      const alignmentCache =
        editorAPI.components.properties.get(compRef)?.overrideAlignment;

      if (alignmentCache) {
        editorAPI.selection.selectComponentByCompRef(compRef);
        editorAPI.store.dispatch(
          stateManagement.text.actions.invisibleTextEditor.execCmd({
            isRepeated: false,
            data: {
              commandName: ALIGNMENT_MAP[alignmentCache],
            },
          }),
        );

        editorAPI.components.properties.update(compRef, {
          overrideAlignment: null,
        });
      }
    }
  }
};

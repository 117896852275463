import _ from 'lodash';
import * as util from '#packages/util';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import { dispatchEditorAPI } from '../../../topBarComponent/topBarMapper';

import type { EditorAPI } from '#packages/editorAPI';
import type {
  MapDispatchToPropsFunction,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type {
  SiteMenuAPIOwnProps,
  SiteMenuAPIStateProps,
  SiteMenuAPIDispatchProps,
} from './siteMenuAPI';

const { selectors: savePublishSelectors, actions: savePublishActions } =
  stateManagement.savePublish;

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

const mapStateToProps: MapStateToProps<
  SiteMenuAPIStateProps,
  SiteMenuAPIOwnProps
> = ({ editorAPI }) => {
  const metaSiteId =
    _.invoke(editorAPI, 'dsRead.generalInfo.getMetaSiteId') ||
    util?.editorModel?.metaSiteId;
  const isFirstSave =
    _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
  const isDraftMode =
    _.invoke(editorAPI, 'dsRead.generalInfo.isDraft') || false;
  const canUserPublish = editorAPI.savePublish.canUserPublish();
  const shouldSaveBeforePublish =
    isFirstSave ||
    isDraftMode ||
    savePublishSelectors.getOverridingSaveAndPublish(
      editorAPI.store.getState(),
    );
  const isSitePublished =
    _.invoke(editorAPI, 'dsRead.generalInfo.isSitePublished') || false;
  const sitePublicUrl = editorAPI.site.getSitePublicUrl() || '';

  return {
    metaSiteId,
    isFirstSave,
    isDraftMode,
    canUserPublish,
    shouldSaveBeforePublish,
    isSitePublished,
    sitePublicUrl,
  };
};

const mapDispatchToProps: MapDispatchToPropsFunction<
  SiteMenuAPIDispatchProps,
  SiteMenuAPIOwnProps
> = (dispatch) => ({
  togglePreview: dispatchEditorAPI(dispatch, (editorAPI) => {
    if (!editorAPI.savePublish.isSavePublishLocked()) {
      editorAPI.savePublish.lockSavePublish();
      editorAPI.preview.togglePreview(undefined, {
        biParams: { origin: constants.BI.PREVIEW.ORIGIN.TOP_BAR },
      });
      window.setTimeout(editorAPI.savePublish.unlockSavePublish, 500);
    }
  }),
  getInvalidLoggedUser: async (): Promise<string | null> => {
    const editorAPI = dispatch(getEditorAPI);
    return editorAPI.account.getUserInvalidLoggedStatus();
  },
  handleInvalidLoggedUser: (invalidStatus: string): void => {
    const editorAPI = dispatch(getEditorAPI);
    return editorAPI.account.handleInvalidLoggedUser(invalidStatus);
  },
  sendBiForLoginRelatedError: (): void => {
    const editorAPI = dispatch(getEditorAPI);
    editorAPI.bi.event(coreBi.events.panels.login_related_error_message_show);
  },
  openPanel(panelName, panelProps, leavePanelsOpen) {
    dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        panelName,
        panelProps,
        leavePanelsOpen,
      ),
    );
  },
  myAccountAction: dispatchEditorAPI(dispatch, (editorAPI, action) =>
    editorAPI.account.myAccountAction(action),
  ),
  save: dispatchEditorAPI(dispatch, (editorAPI, origin) => {
    editorAPI.saveManager.save({ origin });
  }),
  saveSite: dispatch(getEditorAPI).saveManager.save,
  publishRC: dispatchEditorAPI(dispatch, (editorAPI, origin) => {
    if (!editorAPI.savePublish.isSavePublishLocked()) {
      void editorAPI.publishManager.publishRC(origin);
    }
  }),
  publish: (origin) => dispatch(savePublishActions.publish({ origin })),
  siteSaveAndPublish: (options) =>
    dispatch(savePublishActions.saveAndPublish(options)),
  sitePublish: (options) => dispatch(savePublishActions.publish(options)),
  openReleaseManager: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openReleaseManager(),
  ),
  openSiteBranchesManager: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openSiteBranchesManager({ inNewTab: true }),
  ),
  openRevisions: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.openRevisions(),
  ),
  setTopBarSiteHistoryUsed() {
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        'topBarSiteHistoryUsed',
        true,
      ),
    );
  },
  goToDashboard: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.account.manage(),
  ),
  exitEditor: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.exitEditor(),
  ),
  openAccountSettings: (path: string) => {
    const editorAPI = dispatch(getEditorAPI);

    editorAPI.account.openSettings({ path });
  },
});

export { mapStateToProps, mapDispatchToProps };

// @ts-nocheck
import _ from 'lodash';
import * as blog from '#packages/blog';
import constants from '#packages/constants';
import superAppsConstants from '../../superApps/superAppsConstants';

const APP_DEF_ID = superAppsConstants.NEW_BLOG_APP_DEF_ID;

function triggerBiEvent(editorAPI, params) {
  const appData = editorAPI.tpa.app.getDataByAppDefId(APP_DEF_ID);
  const isBlogMenuOpen = editorAPI.panelManager.isPanelOpened(
    constants.SUPER_APPS.NEW_BLOG_PANEL_NAME,
  );
  editorAPI.bi.event(
    blog.newBlogBi.events.BLOG_ELEMENT_ADDED,
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(
      {
        instance_id: appData.instanceId,
        origin: isBlogMenuOpen ? '6th_button' : 'add_panel',
        is_first_time_view: false,
      },
      params,
    ),
  );
}

export default triggerBiEvent;

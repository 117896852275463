import _ from 'lodash';
import constants from '#packages/constants';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import * as util from '#packages/util';
import experiment from 'experiment';

const register = (editorAPI: AnyFixMe) => {
  const superAppId = 'ascend';
  const isAppInstalled = true;
  const superAppPanelName = constants.SUPER_APPS.ASCEND_PANEL_NAME;
  const superAppButtonLabel = 'AscendButton_BusinessTools_6thButton_Label';
  const superAppButtonPromotionalLabel = 'SiteApp_Ascend_Button_Hover_Caption';
  const superAppPanelClassName = 'ascend-panel';
  const superAppSymbolName = util.workspace.isNewWorkspaceEnabled()
    ? 'leftBarButtonAscend'
    : 'ascendLeftBarIcon';
  const siteIsPublished = _.invoke(
    editorAPI,
    'dsRead.generalInfo.isSitePublished',
  );

  const showAscendButtonOnNotPublishedExpEnabled = experiment.isOpen(
    'se_wixPromote_showAscendButtonOnNotPublishedSite',
  );
  let buttonRegistered = false;

  const shouldRegisterAscendButton =
    showAscendButtonOnNotPublishedExpEnabled || siteIsPublished;

  if (shouldRegisterAscendButton) {
    registerButton(false);
  } else {
    editorAPI.registerSitePublishedCallbacks(onPublished);
  }

  function onPublished() {
    return !buttonRegistered && registerButton(true);
  }

  function registerButton(isButtonOpen: AnyFixMe) {
    superAppMenuInfra.button.registerButton(
      editorAPI,
      superAppId,
      isAppInstalled,
      superAppPanelName,
      superAppButtonLabel,
      superAppButtonPromotionalLabel,
      superAppPanelClassName,
      superAppSymbolName,
      isButtonOpen,
    );
    buttonRegistered = true;
  }
};

export { register };

import constants from '#packages/constants';
import * as mediaManagerPanelInfra from '#packages/mediaManagerPanelInfra';
import * as stateManagement from '#packages/stateManagement';
import { sections as sectionsUtils } from '#packages/util';
import type { BiErrorDefinition, BiErrorFields } from 'types/bi';
import type {
  MediaManagerPanelMapDispatchToProps,
  MediaManagerPanelMapStateToProps,
} from './MediaManagerPanel.types';
import type { ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';

const {
  constants: { MEDIA_PANEL_NEVER_SHOW_RECOMMENDED_CARD_KEY },
} = mediaManagerPanelInfra;

const mapStateToProps = ({
  dsRead,
  editorAPI,
  state,
}: AnyFixMe): MediaManagerPanelMapStateToProps => {
  const { mediaManager, userFeedback, mediaStudio, videoMaker } =
    editorAPI.mediaServices;
  const focusedPageId = dsRead.pages.getFocusedPageId();
  const userInfo = dsRead.generalInfo.getUserInfo();

  const allSessionUserPreferences =
    stateManagement.userPreferences.selectors.getAllSessionUserPreferences(
      state,
    );

  return {
    sessionUserPreferences: allSessionUserPreferences,
    siteMediaToken: dsRead.generalInfo.media.getSiteUploadToken(),
    metaSiteId: dsRead.generalInfo.getMetaSiteId(),
    metaSiteInstance: dsRead.platform.getAppDataByApplicationId(
      constants.APPLICATIONS.META_SITE_APPLICATION_ID,
    )?.instance,
    userId: userInfo?.userId,
    siteId: dsRead.generalInfo.getSiteId(),
    language: dsRead.generalInfo.getLanguage(),
    focusedPageId,
    focusedPageReference: dsRead.pages.getReference(focusedPageId),
    mediaManager,
    userFeedback,
    mediaStudio,
    videoMaker,
    editorWorkingMode: editorAPI.viewMode.get(),
    isTemplateSite: dsRead.generalInfo.isTemplate(),
    isRecommendedCardAlwaysHidden: Boolean(
      stateManagement.userPreferences.selectors.getSiteUserPreferences(
        MEDIA_PANEL_NEVER_SHOW_RECOMMENDED_CARD_KEY,
      )(state),
    ),
    componentsAPI: editorAPI.components,
    pasteLogicAPI: editorAPI.pasteLogic,
    historyAPI: editorAPI.history,
    selectionAPI: editorAPI.selection,
    mediaAPI: editorAPI.media,
    panelManagerAPI: editorAPI.panelManager,
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI }: { editorAPI: EditorAPI },
) => editorAPI;

const mapDispatchToProps = (
  dispatch: AnyFixMe,
): MediaManagerPanelMapDispatchToProps => ({
  sendBi: (event, fields) =>
    dispatch(stateManagement.bi.actions.event(event, fields)),

  sendBiError: (
    errorDefinition: BiErrorDefinition,
    errorFields?: BiErrorFields,
  ) => dispatch(stateManagement.bi.actions.error(errorDefinition, errorFields)),

  setSessionUserPreferences: (key: string, value: AnyFixMe) => {
    dispatch(
      stateManagement.userPreferences.actions.setSessionUserPreferences(
        key,
        value,
      ),
    );
  },

  getContainerRefWithSectionsEnabled: (compDef) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    return (
      sectionsUtils.getSectionLikeContainerToPaste(editorAPI, [compDef]) ||
      editorAPI.pages.getPrimaryContainer()
    );
  },

  editorSetFocusToEditor: () => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    editorAPI.mediaServices.setFocusToEditor();
  },

  fireComponentAdded: (data) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    return editorAPI.components.hooks.componentAddedToStage.fire(data);
  },

  editorGetPasteComponentPosition: (layout: AnyFixMe, containerId: string) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);
    return editorAPI.pasteLogic.addContext.getPastePosition(
      editorAPI,
      layout,
      containerId,
    );
  },

  setSiteUserPreferences: (key: string, value: AnyFixMe) =>
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        key,
        value,
      ),
    ),
  getComponentBiParams: (compRef) => {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);

    return editorAPI.bi.getComponentsBIParams([compRef])[0] as any as Record<
      string,
      string
    >;
  },
  showCannotAddNotification(componentStructure, containerToAddTo) {
    const editorAPI: EditorAPI = dispatch(getEditorAPI);

    editorAPI.store.dispatch(
      stateManagement.addPanel.actions.showCannotAddComponentNotification(
        componentStructure,
        containerToAddTo,
      ),
    );
  },
});

export { mapStateToProps, mapDispatchToProps };

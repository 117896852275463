import constants from '#packages/constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

// NOTE: code is moved from https://github.com/wix-private/santa-editor/pull/43075

export function getSectionMinHeightByAnchors(
  editorAPI: EditorAPI,
  _compRef: CompRef,
  {
    layoutInitial,
  }: {
    layoutInitial: { y: number; height: number };
  },
) {
  const sectionBottom = layoutInitial.y + layoutInitial.height;
  const anchors = editorAPI.components.get.byType(
    constants.COMP_TYPES.ANCHOR,
    editorAPI.pages.getCurrentPage(),
  );

  if (anchors.length === 0) {
    return 0;
  }

  return anchors.reduce((acc, a) => {
    const { height } = editorAPI.dsRead.components.layout.get(a);
    const { y } = editorAPI.components.layout.getRelativeToScreen(a);
    const anchorBottom = y + height;
    return anchorBottom <= sectionBottom
      ? Math.max(y - layoutInitial.y + height, acc)
      : acc;
  }, 0);
}

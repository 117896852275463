import {
  stateMapperArgsSelectors,
  interactions,
} from '#packages/stateManagement';

const { editorStateSel } = stateMapperArgsSelectors.rootSels;
const { createSelector } = stateMapperArgsSelectors.selector;

export const getIsInteractionModeSel = createSelector(
  [editorStateSel],
  (editorState) => interactions.selectors.isInInteractionMode(editorState),
);

import { isMeshLayoutEnabled } from '#packages/layout';
import { sections as sectionsUtils } from '#packages/util';
import { translate } from '#packages/i18n';
import { scrollToComponent } from '../../../utils';
import { TRANSLATIONS } from '../../../translations';
import { isSectionLike, isSection, rename, getName } from '../../../api';

import type { AfterDuplicatePlugin } from 'types/editorPlugins';
import type { CompRef } from 'types/documentServices';
import type { Scope } from '#packages/sections';

const { shiftComponents, getComponentsBelowPosition } = sectionsUtils;

const updateNameForDuplicateSection = (
  scope: Scope,
  duplicateSectionRef: CompRef,
  originalSectionRef: CompRef,
) => {
  const oldName = getName(scope, originalSectionRef);
  const newName = translate(TRANSLATIONS.SECTION_NAME_COPY, {
    section_name: oldName,
  });
  rename(scope, duplicateSectionRef, newName, true);
};

export const sectionDuplicatePlugin =
  (scope: Scope): AfterDuplicatePlugin =>
  async (_, [componentRef], [originalComponentRef]) => {
    const { components, editorAPI, selection } = scope;
    const containerRef = components.getContainer(componentRef);

    if (
      isSectionLike(scope, containerRef) &&
      components.is.fullWidth(componentRef)
    ) {
      scrollToComponent(editorAPI, componentRef);
      return;
    }

    if (!isSection(scope, componentRef)) return;

    editorAPI.components.transformations.remove(componentRef);

    updateNameForDuplicateSection(scope, componentRef, originalComponentRef);

    // NOTE: mesh layout anchors will shift next sections automatically
    if (!isMeshLayoutEnabled()) {
      const originalComponentLayout =
        components.layout.get_rect(originalComponentRef);

      const componentsBelowPosition = getComponentsBelowPosition(
        editorAPI,
        originalComponentLayout.y,
      );
      const componentsToShift = componentsBelowPosition.filter(
        (ref) => ![componentRef.id, originalComponentRef.id].includes(ref.id),
      );

      shiftComponents(
        editorAPI,
        componentsToShift,
        originalComponentLayout.height,
      );
    }

    await editorAPI.waitForChangesAppliedAsync();

    scrollToComponent(editorAPI, componentRef);

    selection.selectComponentByCompRef(componentRef, {
      origin: 'after_action',
    });
  };

import React from 'react';
import _ from 'lodash';
import experiment from 'experiment';
import { withTranslation, type WithTranslation } from 'react-i18next';

import {
  cx,
  browserUtil,
  editorWixRecorder,
  fixedStage,
  multilingual,
} from '#packages/util';
import * as pages from '#packages/pages';
import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';

import withSendBi, { type ComponentWithSendBiProps } from '../hocs/withSendBi';
import withDelayedFadeOutBehavior, {
  type ComponentWithDelayedFadeOutBehaviorProps,
} from './behaviors/withDelayedFadeOutBehavior';
import withSearchIntegration from './behaviors/withSearchIntegration';
import withSetHeightBehavior from './behaviors/withSetHeightBehavior';
import withDealerUpgradeBehavior, {
  type ComponentWithDealerUpgradeBehaviorProps,
} from './behaviors/withDealerUpgradeBehavior';

import editModeLayout from './layouts/editMode';
import previewModeLayout from './layouts/previewMode';
import zoomModeLayout from './layouts/zoomMode';

import WixLogo from '../wixLogo/wixLogo';
import MenuBar from '../nextMenuBar/menuBar';
import LanguageSelect from '../languageSelect/languageSelect';
import DeviceSwitch from '../deviceSwitch/deviceSwitch';
import UrlBar from '../urlBar/urlBar';
import UndoRedo from '../undoRedo/undoRedo';
import Search from '../search/search';
import Tools from '../tools/tools';
import ZoomOut from '../zoomOut/zoomOut';
import Notification from '../notification/notification';
import SaveButton from '../actionsButtons/save/save';
import PreviewButton from '../actionsButtons/preview/preview';
import BackFromPreviewButton from '../actionsButtons/backToEditor/fromPreview/backToEditorFromPreview';
import BackFromZoomButton from '../actionsButtons/backToEditor/fromZoom/backToEditorFromZoom';
import PublishButton from '../actionsButtons/publish/publish';
import TestSiteButton from '../actionsButtons/testSite/testSite';
import PublishRCButton from '../actionsButtons/publishRC';
import TestSiteRCButton from '../actionsButtons/testSiteRC';
import HideToolsButton from '../hideToolsButton/hideToolsButton';
import TopBarBanner from '../topBarBanner/topBarBanner';
import { BranchIndicator } from '../actionsButtons/branchIndicator/branchIndicator';
import DashboardShortcut from '../dashboardShortcut/dashboardShortcut';
import TopBarButton from '../topBarButton/topBarButton';
import WorkspaceModesToggle from '../workspaceModesToggle/workspaceModesToggle';

import type { ProgressStatus } from '#packages/constants';
import DashboardShortcutTooltip from '../dashboardShortcut/dashboardShortcutTooltip';
import DeviceSwitchWithResize from '../deviceSwitchWithResize/deviceSwitchWithResize';

const { DROP_PANELS } = constants.ROOT_COMPS.TOPBAR;

const topBarClickEvent = coreBi.events.topbar.top_bar_click;

export interface TopBarDispatchProps {
  onMultilingualUpdated: (languageSelectorPreset?: AnyFixMe) => void;
  onSyncFlagButton: () => void;
  closeAllPanels: () => void;
  toggleHideTools: () => void;
  openDropPanel: (panelName: string) => void;
  closeDropPanel: () => void;
  exitEditor: (exitUrl?: string) => void;
  deselectComponents: () => void;
}

export interface TopBarOwnProps {
  isImageCropOn: boolean;
  onContextMenu: () => void;
  fallbackToDefaultUpgradePanel: (err: string) => boolean;
  isUsingExternalUpgradeData: () => boolean;
}

export interface TopBarStateProps {
  tpaMlState?: any; // see componentDidUpdate
  currentLanguageCode?: string;
  isSitePublished: boolean;
  isPreviewMode: boolean;
  isZoomMode: boolean;
  isMinimized: boolean;
  showLanguageSelect?: boolean;
  currentCountryCode?: string;
  currentRegionCode?: string;
  shouldCurrentLanguageInvisible?: boolean;
  overriddenQuickNavigation: AnyFixMe;
  currentPageId: string;
  forceOpenPagesQuickNavigationEventCounter: number;
  isPublishInProgress: boolean;
  isTestSiteInProgress: boolean;
  isSaveInProgress: boolean;
  isBuildInProgress: boolean;
  canUserPublish: boolean;
  isDeveloperModeEnabled: boolean;
  shouldDisplayTestSiteButton: boolean;
  shouldDisplayPublishButton: boolean;
  shouldDisplaySaveButton: boolean;
  publishingStatus: ProgressStatus;
  testSiteStatus: ProgressStatus;
  previewingStatus: ProgressStatus;
  isEditorSearchEnabled: boolean;
  branchId: string;
  isHidden: boolean;
  showTopBarBanner: boolean;
  areToolsHiddenAndStagePositionMaintained: boolean;
  isMobileEditor: boolean;
  topBarBannerTitle: string;
  shouldHideToolsBtnBlink: boolean;
  openedDropPanel: string;
  isSchoolMode: boolean;
  isLightboxMode: boolean;
  isPublishRcVisible: boolean;
  contributedLogo: React.ComponentType<{}> | undefined;
  contributedRightSideComponents: React.ComponentType<{}>[];
  isChangeWorkspaceModesVisible: boolean;
  shouldHideLanguageIcon: boolean;
  topBarStateBIParamValue: string;
  isSectionsMigrationBannerEnabled: boolean;
  contributedTopBannerComponent: React.ComponentType<{}> | undefined;
}

export type TopBarProps = WithTranslation &
  TopBarDispatchProps &
  TopBarOwnProps &
  TopBarStateProps &
  ComponentWithSendBiProps &
  ComponentWithDelayedFadeOutBehaviorProps &
  ComponentWithDealerUpgradeBehaviorProps & {};

class TopBar extends React.Component<TopBarProps> {
  componentDidUpdate(prevProps: AnyFixMe) {
    if (prevProps.tpaMlState !== this.props.tpaMlState) {
      this.props.onMultilingualUpdated();
    }
    if (prevProps.currentLanguageCode !== this.props.currentLanguageCode) {
      this.props.onSyncFlagButton();
    }
  }

  componentDidMount() {
    multilingual.localeDatasetTranslationsFetcher.addTranslations();
  }

  openLanguagesDropdown = () => {
    this.props.closeAllPanels();
    this.props.openDropPanel(DROP_PANELS.LANGUAGE_DROP_PANEL);

    editorWixRecorder.addLabel('multilingual top bar clicked');
    this.props.sendBi(topBarClickEvent, {
      origin: 'editor',
      category: 'multilingual',
      is_published: this.props.isSitePublished,
      state: this.props.topBarStateBIParamValue,
    });
  };

  renderLogo() {
    const ContributedLogo = this.props.contributedLogo;
    return (
      <DashboardShortcut
        openDropPanel={this.props.openDropPanel}
        closeDropPanel={this.props.closeDropPanel}
        openedDropPanel={this.props.openedDropPanel}
        dropPanelKey="logo"
        delayedFadeOut={this.props.delayedFadeOut}
        dropPanelContent={<DashboardShortcutTooltip />}
      >
        <WixLogo
          sendBi={this.props.sendBi}
          isPreviewMode={this.props.isPreviewMode}
          isZoomMode={this.props.isZoomMode}
          isSitePublished={this.props.isSitePublished}
          topBarStateBIParamValue={this.props.topBarStateBIParamValue}
        />
        {this.props.contributedLogo && <ContributedLogo />}
      </DashboardShortcut>
    );
  }

  renderWorkspaceModesToggle() {
    return <WorkspaceModesToggle />;
  }

  shouldDisplayWorkspaceModesToggle() {
    return this.props.isChangeWorkspaceModesVisible;
  }

  renderMenuBar(type?: 'left' | 'right') {
    return (
      <MenuBar
        type={type}
        openDropPanel={this.props.openDropPanel}
        closeDropPanel={this.props.closeDropPanel}
        openedDropPanel={this.props.openedDropPanel}
        delayedFadeOut={this.props.delayedFadeOut}
        isUsingExternalUpgradeData={this.props.isUsingExternalUpgradeData}
        fallbackToDefaultUpgradePanel={this.props.fallbackToDefaultUpgradePanel}
        contributedRightSideComponents={
          this.props.contributedRightSideComponents
        }
      />
    );
  }

  renderLogout() {
    return (
      <TopBarButton
        automationId="top-bar-button-logout"
        label="Logout"
        onClick={() => {
          this.props.exitEditor('https://www.wix.com/education/user-logout');
        }}
      />
    );
  }

  renderLanguageSelect() {
    const { showLanguageSelect } = this.props;

    if (!showLanguageSelect) {
      return null;
    }

    const {
      openedDropPanel,
      currentCountryCode,
      currentLanguageCode,
      shouldCurrentLanguageInvisible,
      currentRegionCode,
    } = this.props;

    return (
      <LanguageSelect
        isOpened={openedDropPanel === DROP_PANELS.LANGUAGE_DROP_PANEL}
        countryCode={currentCountryCode}
        languageCode={currentLanguageCode}
        regionCode={currentRegionCode}
        onButtonClick={this.openLanguagesDropdown}
        onPanelMouseLeave={() => this.props.closeDropPanel()}
        closePanelHandle={() => this.props.closeDropPanel(true)}
        delayedFadeOut={this.props.delayedFadeOut}
        shouldCurrentLanguageInvisible={shouldCurrentLanguageInvisible}
        shouldHideLanguageIcon={this.props.shouldHideLanguageIcon}
      />
    );
  }

  renderQuickNavigation() {
    const isQuickNavigationHidden =
      this.props?.overriddenQuickNavigation?.isHidden;

    if (isQuickNavigationHidden) {
      return null;
    }

    const { currentPageId, forceOpenPagesQuickNavigationEventCounter } =
      this.props;

    return (
      <pages.quickNavigation
        key="quickNavigation"
        forceOpenEventCounter={forceOpenPagesQuickNavigationEventCounter}
        currentPageId={currentPageId}
      />
    );
  }

  renderDeviceSwitch() {
    return fixedStage.isFixedStageEnabled() && this.props.isPreviewMode ? (
      <DeviceSwitchWithResize />
    ) : (
      <DeviceSwitch />
    );
  }

  renderUrlBar() {
    return <UrlBar />;
  }

  renderUndoRedo() {
    return <UndoRedo />;
  }

  renderSearch() {
    if (this.props.isEditorSearchEnabled) {
      return <Search />;
    }
  }

  renderRightSideContributions() {
    return this.props.contributedRightSideComponents.map((Comp, key) => (
      <div className="top-bar-btn-wrapper">
        <Comp key={key} />
      </div>
    ));
  }

  shouldDisablePublish(): boolean {
    return (
      this.props.isPublishInProgress ||
      this.props.isSaveInProgress ||
      this.props.isBuildInProgress ||
      !this.props.canUserPublish
    );
  }
  shouldDisableTestSite(): boolean {
    return (
      this.props.isTestSiteInProgress ||
      this.props.previewingStatus === constants.PROGRESS_STATUS.IN_PROGRESS
    );
  }

  shouldDisplayLogoutButton(): boolean {
    return this.props.isSchoolMode;
  }

  shouldShowPublishRC(): boolean {
    return (
      this.props.isPublishRcVisible &&
      this.props.isDeveloperModeEnabled &&
      this.props.canUserPublish &&
      this.props.isSitePublished
    );
  }

  getPublishButton() {
    const className = cx({
      'top-bar-publish-btn-devmode':
        !experiment.isOpen('specs.wixCode.TestSiteEntryPoint') &&
        this.shouldShowPublishRC(),
    });

    return <PublishButton className={className} />;
  }

  getPublishRCButton() {
    if (!this.shouldShowPublishRC()) {
      return null;
    }

    return (
      <PublishRCButton
        isPanelOpen={this.props.openedDropPanel === DROP_PANELS.PUBLISH_RC}
        openPanel={() => this.props.openDropPanel(DROP_PANELS.PUBLISH_RC)}
        closePanel={() => this.props.closeDropPanel()}
        delayedFadeOut={this.props.delayedFadeOut}
        status={this.props.publishingStatus}
        disabled={this.shouldDisablePublish()}
        sendBi={this.props.sendBi}
      />
    );
  }

  getPublishAndPublishRCButtons() {
    return (
      <div
        className="top-bar-btn-wrapper"
        data-aid="top-bar-group-button-publish-wrapper"
      >
        <div
          className="publish-buttons-wrapper"
          data-aid="top-bar-group-button-publish"
        >
          {this.getPublishButton()}
          {!experiment.isOpen('specs.wixCode.TestSiteEntryPoint') &&
            this.getPublishRCButton()}
        </div>
      </div>
    );
  }
  getTestSiteAndTestSiteRCButtons() {
    return (
      <div
        className="top-bar-btn-wrapper"
        data-aid="top-bar-group-button-test-site-wrapper"
      >
        <div
          className="test-site-buttons-wrapper"
          data-aid="top-bar-group-button-test-site"
        >
          <TestSiteButton />
          <TestSiteRCButton
            isPanelOpen={
              this.props.openedDropPanel === DROP_PANELS.TEST_SITE_RC
            }
            openPanel={() => this.props.openDropPanel(DROP_PANELS.TEST_SITE_RC)}
            closePanel={() => this.props.closeDropPanel()}
            delayedFadeOut={this.props.delayedFadeOut}
            status={this.props.testSiteStatus}
            disabled={this.shouldDisableTestSite()}
            sendBi={this.props.sendBi}
          />
        </div>
      </div>
    );
  }

  renderZoomModeActionsButtons() {
    return (
      <>
        <SaveButton />
        <BackFromZoomButton />
      </>
    );
  }

  renderPreviewModeActionsButtons() {
    return (
      <>
        {this.props.contributedRightSideComponents.map((Comp, key) => (
          <Comp key={key} />
        ))}
        <BackFromPreviewButton />
        {this.props.shouldDisplayPublishButton
          ? this.getPublishAndPublishRCButtons()
          : null}
      </>
    );
  }

  renderBranchIndicator() {
    return (
      this.props.branchId && <BranchIndicator branchId={this.props.branchId} />
    );
  }

  renderEditorModeActionsButtons() {
    return (
      <>
        {this.props.shouldDisplaySaveButton ? <SaveButton /> : null}
        {this.props.isDeveloperModeEnabled &&
        this.props.shouldDisplayTestSiteButton &&
        experiment.isOpen('specs.wixCode.TestSiteEntryPoint') ? (
          this.getTestSiteAndTestSiteRCButtons()
        ) : (
          <PreviewButton />
        )}

        {this.props.shouldDisplayPublishButton
          ? this.getPublishAndPublishRCButtons()
          : null}
      </>
    );
  }

  renderNotification() {
    const { isPreviewMode, isMinimized, t } = this.props;

    if (isPreviewMode) {
      return <Notification>{t('Topbar_Preview_Mode_Status')}</Notification>;
    }

    if (isMinimized) {
      return <Notification>{t('Topbar_ZoomOut_Mode_Status')}</Notification>;
    }
  }

  renderTools() {
    return <Tools />;
  }

  renderZoomOut() {
    return <ZoomOut />;
  }

  getWrapperClassName() {
    const { isHidden, isMinimized, showTopBarBanner } = this.props;
    const { areToolsHiddenAndStagePositionMaintained } = this.props;
    return cx({
      'top-bar-wrapper': true,
      'with-top-bar-banner': showTopBarBanner && !isHidden,
      'is-hidden': isHidden && !isMinimized,
      'is-hidden-completely': areToolsHiddenAndStagePositionMaintained,
    });
  }

  getClassName() {
    const { isPreviewMode, isMinimized } = this.props;

    return cx({
      'main-top-bar': true,
      'next-main-top-bar': true,
      'edit-mode': !isPreviewMode && !isMinimized,
      'zoom-mode': isMinimized,
      'preview-mode': isPreviewMode,
      'with-rounded-buttons': true,
    });
  }

  getLayoutTemplate() {
    if (this.props.isPreviewMode) {
      return previewModeLayout;
    }

    if (this.props.isMinimized) {
      return zoomModeLayout;
    }

    return editModeLayout;
  }

  handleUpperDeckClick = (e: MouseEvent) => {
    if (e.target !== e.currentTarget) return;
    this.props.deselectComponents();
  };

  render() {
    const layout = this.getLayoutTemplate();
    const wrapperClassName = this.getWrapperClassName();
    const className = this.getClassName();
    const shouldShowHideTools =
      !this.props.isPreviewMode &&
      !this.props.isMinimized &&
      !this.props.isMobileEditor &&
      !this.props.isImageCropOn;

    return (
      <div className={wrapperClassName} data-hook="top-bar-wrapper">
        <div
          onContextMenu={browserUtil.preventDefaultRightClick}
          className={className}
        >
          {layout.call(this)}

          {this.props.showTopBarBanner ? (
            <TopBarBanner title={this.props.topBarBannerTitle} />
          ) : null}
        </div>
        {shouldShowHideTools && (
          <HideToolsButton
            isHidden={this.props.isHidden}
            toggleHideTools={this.props.toggleHideTools}
            shouldHideToolsBtnBlink={this.props.shouldHideToolsBtnBlink}
            withSectionsMigrationBanner={
              this.props.isSectionsMigrationBannerEnabled
            }
          />
        )}
      </div>
    );
  }
}

// @ts-expect-error
// needed for debug tools
TopBar.displayName = 'TopBar';

const TopBarWithBehaviors = _.flow(
  withDealerUpgradeBehavior,
  withSearchIntegration,
  withDelayedFadeOutBehavior,
  withSetHeightBehavior,
  withTranslation(),
  withSendBi,
)(TopBar);

export default TopBarWithBehaviors;

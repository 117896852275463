import React from 'react';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import * as panels from '#packages/panels';
import * as leftBar from '#packages/leftBar';
import { hoc, workspace } from '#packages/util';

import type { MapStateToProps, ThunkAction } from 'types/redux';

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

interface LayoutOptimizerPanelProps {
  canOptimize: boolean;
  optimizeLayout: () => void;
  sendBILearnMore: () => void;
  openHelpCenter: (panelName: string, helpId: string) => void;
  panelName?: string;
}

class LayoutOptimizerPanel extends React.Component<LayoutOptimizerPanelProps> {
  static defaultProps = {
    panelName: 'mobileEditor.layoutOptimizerPanel',
  };

  textKey = 'Notification_Box_Mobile_Layout_Optimizer_FirstTime_Body';
  helpId = '1a65ebfc-138a-45df-bd54-5f06c03b5973';
  autoRefreshingLayout = workspace.isNewWorkspaceEnabled()
    ? 'autoRefreshingLayout_NewWorkspace'
    : 'autoRefreshingLayout';

  openHelpCenter = () => {
    this.props.openHelpCenter(this.props.panelName, this.helpId);
  };

  render() {
    const LeftPanelFrame = isNewWorkspace
      ? leftBar.LeftPanelFrame
      : panels.frames.LeftPanelFrame;

    return (
      <LeftPanelFrame
        ref="layoutOptimizerPanel"
        panelName={this.props.panelName}
        panelClass="layout-optimizer-panel"
        helpId={this.helpId}
        label={translate('mobile_layout_optimizer_panel_title')}
        onContextMenu={(e: AnyFixMe) => {
          e.preventDefault();
        }}
        className="layout-optimizer-panel"
      >
        <div className="content">
          <div className="image-container">
            <symbols.symbol name={this.autoRefreshingLayout} />
          </div>
          <div className="text">
            {translate(this.textKey)}
            <span onClick={this.openHelpCenter} className="learn-more">
              {translate(
                'Notification_Box_Mobile_Layout_Optimizer_FirstTime_Learn_More',
              )}
            </span>
          </div>
          <baseUI.button
            label="Notification_Box_Mobile_Layout_Optimizer_FirstTime_Button"
            disabled={!this.props.canOptimize}
            onClick={this.props.optimizeLayout}
            className="optimize-layout-button"
          />
        </div>
      </LeftPanelFrame>
    );
  }
}

const {
  connect,
  STORES: { EDITOR_API },
} = hoc;

const optimizeLayout =
  (): ThunkAction =>
  (_dispatch, _getState, { editorAPI }) => {
    editorAPI.mobile.optimizeLayout();
    editorAPI.panelManager.closeAllPanels();
  };

const sendBILearnMore =
  (): ThunkAction =>
  (_dispatch, _getState, { editorAPI }) => {
    editorAPI.bi.event(
      coreBi.events.mobileEditor.hiddenElements
        .HIDDEN_ELEMENTS_REFRESH_LAYOUT_TOOLTIP_OPEN,
      {},
    );
  };

const openHelpCenter =
  (panelName: string, helpId: string): ThunkAction =>
  (_dispatch, _getState, { editorAPI }) => {
    const biHelpParams = {
      panel_name: panelName,
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
      learn_more: true,
    };
    editorAPI.panelManager.openHelpCenter(helpId, null, biHelpParams);
  };

const mapDispatchToProps = { optimizeLayout, sendBILearnMore, openHelpCenter };

const mapStateToProps: MapStateToProps = ({ editorAPI }) => {
  const siteIsTemplate = editorAPI.dsRead.generalInfo.isFirstSave();
  const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
  const siteChanged = editorAPI.dsActions.history.canUndo();

  const canOptimize = siteChanged || (!siteIsTemplate && !isDraftMode);

  return {
    canOptimize,
  };
};

const conectedComp = connect(
  EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(LayoutOptimizerPanel);

export default conectedComp;

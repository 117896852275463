import type { BiEventDefinition } from 'types/bi';
import type {
  MapStateToProps,
  MapDispatchToProps,
  ThunkAction,
} from 'types/redux';
import { domMeasurements, type StageLayout } from '#packages/stateManagement';

interface LanguageSupportStateProps {
  characterSet: string[];
  editorLayout: StageLayout;
}

interface LanguageSupportDispatchProps {
  sendBI: (biEvent: BiEventDefinition) => void;
  openHelpCenter: (
    helpId: string,
    props?: object,
    biHelpParams?: object,
  ) => void;
  updateCharacterSet: (newCharacterSet: string[]) => void;
  closePanelByName: (panelName: string) => void;
}

interface LanguageSupportOwnProps {
  panelName: string;
  top: number;
  left: number;
}

interface LanguageSupportProps
  extends LanguageSupportStateProps,
    LanguageSupportDispatchProps,
    LanguageSupportOwnProps {}

const mapStateToProps: MapStateToProps<
  LanguageSupportStateProps,
  LanguageSupportOwnProps
> = ({ editorAPI }) => {
  return {
    characterSet: editorAPI.dsRead.theme.fonts.getCharacterSet(),
    editorLayout: domMeasurements.selectors.getEditorContentLayout(
      editorAPI.store.getState(),
    ),
  };
};

const sendBI =
  (biEvent: BiEventDefinition): ThunkAction =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.bi.event(biEvent);

const openHelpCenter =
  (helpId: string, props?: object, biHelpParams?: object): ThunkAction =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.panelManager.openHelpCenter(helpId, props, biHelpParams);

const closePanelByName =
  (panelName: string): ThunkAction =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.panelManager.closePanelByName(panelName);

const updateCharacterSet =
  (newCharacterSet: string[]): ThunkAction =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.dsActions.theme.fonts.updateCharacterSet(newCharacterSet);

const mapDispatchToProps: MapDispatchToProps<
  LanguageSupportDispatchProps,
  LanguageSupportOwnProps
> = {
  sendBI,
  openHelpCenter,
  updateCharacterSet,
  closePanelByName,
};

export { mapStateToProps, mapDispatchToProps, type LanguageSupportProps };

import { CeType } from '#packages/presetApi';

export const SECTIONS_NAMES_WITH_ROLES = [
  { name: 'Section_Name_About', role: CeType.HomepageAbout },
  { name: 'Section_Name_AboutMe', role: CeType.InnerAbout },
  { name: 'Section_Name_TheBusiness', role: CeType.InnerAbout },
  { name: 'Section_Name_ThePeople', role: CeType.InnerAbout },
  { name: 'Section_Name_OurStory', role: CeType.InnerAbout },
  { name: 'Section_Name_OurApproach', role: CeType.InnerAbout },
  { name: 'Section_Name_Welcome', role: CeType.Welcome },
  { name: 'Section_Name_Intro', role: CeType.Welcome },
  { name: 'Section_Name_Features', role: CeType.Feature },
  { name: 'Section_Name_Benefits', role: CeType.Feature },
  { name: 'Section_Name_Facilities', role: CeType.Facilities },
  { name: 'Section_Name_Amenities', role: CeType.Facilities },
  { name: 'Section_Name_Infographics', role: CeType.Infographics },
  { name: 'Section_Name_Timeline', role: CeType.Infographics },
  { name: 'Section_Name_Locations', role: CeType.Text },
  { name: 'Section_Name_Portfolio', role: CeType.Portfolio },
  { name: 'Section_Name_Categories', role: CeType.List },
  { name: 'Section_Name_Gallery', role: CeType.Gallery },
  { name: 'Section_Name_VideoGallery', role: CeType.VideoWidget },
  { name: 'Section_Name_FeaturedVideo', role: CeType.Video },
  { name: 'Section_Name_Promotional', role: CeType.PromotionLightbox },
  { name: 'Section_Name_SpecialOffers', role: CeType.SpecialOffer },
  { name: 'Section_Name_Resume', role: CeType.CV },
  { name: 'Section_Name_Experience', role: CeType.CV },
  { name: 'Section_Name_Education', role: CeType.CV },
  { name: 'Section_Name_Skills', role: CeType.CV },
  { name: 'Section_Name_Brands', role: CeType.Client },
  { name: 'Section_Name_Press', role: CeType.Client },
  { name: 'Section_Name_Services', role: CeType.Service },
  {
    name: 'Section_Name_FeaturedProduct',
    role: CeType.StoresProductSpecificWidget,
  },
  { name: 'Section_Name_Instructions', role: CeType.Feature },
  { name: 'Section_Name_Testimonials', role: CeType.Testimonial },
  { name: 'Section_Name_Team', role: CeType.TeamMember },
  { name: 'Section_Name_Careers', role: CeType.Careers },
  { name: 'Section_Name_JobOpening', role: CeType.Careers },
  { name: 'Section_Name_Requirements', role: CeType.GeneralDescription },
  { name: 'Section_Name_Description', role: CeType.GeneralDescription },
  { name: 'Section_Name_Quote', role: CeType.Quote },
  { name: 'Section_Name_Contact', role: CeType.Contact },
  { name: 'Section_Name_GetaQuote', role: CeType.PriceQuote },
  { name: 'Section_Name_Donate', role: CeType.Donate },
  { name: 'Section_Name_SalesLead', role: CeType.SalesLead },
  { name: 'Section_Name_JobApplication', role: CeType.JobApplication },
  { name: 'Section_Name_Feedback', role: CeType.Feedback },
  { name: 'Section_Name_Legal', role: CeType.Imprint },
  { name: 'Section_Name_PaymentMethods', role: CeType.Feature },
  { name: 'Section_Name_Support', role: CeType.Support },
  { name: 'Section_Name_Decorative', role: CeType.Image },
  { name: 'Section_Name_PageTitle', role: CeType.Text },
  { name: 'Section_Name_Header', role: CeType.Text },
  { name: 'Section_Name_Footer', role: CeType.Text },
  { name: 'Section_Name_NavigationMenu', role: CeType.Text },
  { name: 'Section_Name_Lists', role: CeType.List },
  { name: 'Section_Name_Text', role: CeType.Text },
  { name: 'Section_Name_Instagram', role: CeType.Instagram_Widget },
  { name: 'Section_Name_BlogFeed', role: CeType.BlogPostGallery },
  { name: 'Section_Name_BlogPost', role: CeType.Blog },
  { name: 'Section_Name_Product', role: CeType.StoresProductSpecificWidget },
  { name: 'Section_Name_Products', role: CeType.StoresGridGalleryWidget },
  { name: 'Section_Name_Cart', role: undefined },
  { name: 'Section_Name_ThankYou', role: undefined },
  { name: 'Section_Name_Collection', role: CeType.StoresGridGalleryWidget },
  {
    name: 'Section_Name_StoreCategories',
    role: CeType.StoresGridGalleryWidget,
  },
  {
    name: 'Section_Name_BookaTable',
    role: CeType.RestaurantsReservationsWidget,
  },
  { name: 'Section_Name_Menu', role: CeType.RestaurantsMenus },
  { name: 'Section_Name_OrderOnline', role: CeType.RestaurantsOrders },
  { name: 'Section_Name_BookaService', role: CeType.Bookings },
  { name: 'Section_Name_Service', role: CeType.Service },
  { name: 'Section_Name_Checkout', role: undefined },
  { name: 'Section_Name_Forum', role: CeType.Forum },
  { name: 'Section_Name_Events', role: CeType.EventsMultiple },
  { name: 'Section_Name_EventList', role: CeType.Event },
  { name: 'Section_Name_EventDetails', role: CeType.EventsSingle },
  { name: 'Section_Name_Members', role: CeType.Members_Page },
  { name: 'Section_Name_Albums', role: undefined },
  { name: 'Section_Name_Music', role: CeType.MusicWidget },
  { name: 'Section_Name_PressKit', role: CeType.About },
  { name: 'Section_Name_FeaturedMusic', role: CeType.MusicWidget },
  { name: 'Section_Name_FAQ', role: CeType.FAQ },
  { name: 'Section_Name_Files', role: CeType.Files },
  { name: 'Section_Name_PodcastFeed', role: CeType.MusicWidget },
  { name: 'Section_Name_Episode', role: CeType.MusicWidget },
  { name: 'Section_Name_SearchResults', role: undefined },
  { name: 'Section_Name_Subscribe', role: CeType.GetSubscribersWidget },
  { name: 'Section_Name_PricingPlans', role: CeType.PaidPlans },
  { name: 'Section_Name_Programs', role: undefined },
  { name: 'Section_Name_BookaRoom', role: CeType.BookingsWidget },
  { name: 'Section_Name_CheckAvailability', role: CeType.BookingsWidget },
  { name: 'Section_Name_BusinessInfo', role: CeType.GeneralDescription },
  { name: 'Section_Name_ComingSoon', role: CeType.Welcome },
  { name: 'Section_Name_Clients', role: CeType.Client },
  { name: 'Section_Name_OpeningHours', role: CeType.OpeningHours },
  { name: 'Section_Name_Partners', role: CeType.Client },
  { name: 'Section_Name_Social', role: CeType.Social },
  { name: 'Section_Name_Register', role: CeType.Contact },
  { name: 'Section_Name_HealthForm', role: CeType.Contact },
  { name: 'section_name_blank', role: CeType.Text },
  { name: 'Section_Name_DownloadApp', role: undefined },
  { name: 'Section_Name_Reviews', role: CeType.Testimonial },
  { name: 'Section_Name_NewsList', role: CeType.News },
  { name: 'Section_Name_Volunteer', role: CeType.Contact },
  { name: 'Section_Name_Achievements', role: CeType.Feature },
  { name: 'Section_Name_Program', role: CeType.Service },
  { name: 'Section_Name_Activities', role: CeType.Event },
  { name: 'Section_Name_Schedule', role: CeType.BookingsTimetable },
  { name: 'Section_Name_Project', role: CeType.Project },
  { name: 'Section_Name_Resources', role: CeType.Files },
  { name: 'Section_Name_Navigation', role: undefined },
  { name: 'Section_Name_Classes', role: CeType.Service },
  { name: 'Section_Name_Groups', role: undefined },
  { name: 'Section_Name_Group', role: undefined },
  { name: 'Section_Name_EventInfo', role: CeType.Feature },
  { name: 'Section_Name_ProductList', role: CeType.Project },
  { name: 'Section_Name_Class', role: CeType.Project },
  { name: 'Section_Name_Timetable', role: CeType.BookingsTimetable },
  { name: 'Section_Name_Images', role: CeType.Image },
  { name: 'Section_Name_Accesibility_Statement', role: undefined },
];

export const DEFAULT_CONTENT_ROLE = CeType.About;

export const ELEMENTS_BEHIND_ERROR = {
  title: 'You left an element behind',
  message: `Oops! You forget to mark one or more elements under their section.
  To fix this, mark the elements under Section Panel > Unsectioned.`,
};

export const SECTION_NO_NAME_ERROR = {
  title: 'A section has no name',
  message: `Oops! You forget to name one or more of your sections.
  To rename a section select the purple Section Anchor > Rename`,
};

export const SAVE_SUCCESS_MSG = {
  title: 'Section Panel',
  message: 'Changes were applied. Save site to keep sections structure',
};

import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

const FLOW_NAMES = {
  PROMOTIONAL: 'promotional',
  MANUALLY_INSTALLED: 'manuallyInstalled',
  TEMPLATE: 'template',
} as const;

export type FirstTimeTourFlowName = ValueOf<typeof FLOW_NAMES>;

export interface FirstTimeTourSlide {
  flow: FirstTimeTourFlowName;
  index: number;
}

function getAllSlides(superApp: AnyFixMe, flowName: FirstTimeTourFlowName) {
  switch (flowName) {
    case FLOW_NAMES.PROMOTIONAL:
      return superApp.firstTimeTour.promotionalFlow;
    case FLOW_NAMES.MANUALLY_INSTALLED:
      return superApp.firstTimeTour.manuallyInstalledFlow;
    case FLOW_NAMES.TEMPLATE:
      return superApp.firstTimeTour.templateFlow;
    default:
      // Shouldn't get here
      return [];
  }
}

function findFirstSlide(
  allSlides: FirstTimeTourFlowName[],
  lastSeenSlideName: FirstTimeTourSlide,
): ValueOf<FirstTimeTourFlowName> {
  let slide;
  if (!lastSeenSlideName) {
    slide = allSlides[0];
  } else if (lastSeenSlideName.index === -1) {
    slide = undefined;
  } else {
    slide = allSlides[lastSeenSlideName.index];
  }
  return slide;
}

function getNextSlide(
  editorAPI: EditorAPI,
  superApp: { id: string },
  flowName: FirstTimeTourFlowName,
  lastSlide?: FirstTimeTourFlowName,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/values, you-dont-need-lodash-underscore/includes
  if (!_.includes(_.values(FLOW_NAMES), flowName)) {
    throw new Error(`illegal flowName ${flowName}`);
  }
  const lastSeenSlideKey = `${superApp.id}/first_time_tour_last_seen_slide`;
  const allSlides = getAllSlides(superApp, flowName);
  let nextSlide: FirstTimeTourSlide;
  if (lastSlide !== undefined) {
    nextSlide = allSlides[allSlides.indexOf(lastSlide) + 1];
  } else {
    const lastSeenSlide =
      stateManagement.userPreferences.selectors.getSiteUserPreferences<FirstTimeTourSlide>(
        lastSeenSlideKey,
      )(editorAPI.store.getState());
    const mutatedLastSeenSlide =
      lastSeenSlide && lastSeenSlide.flow !== flowName
        ? // eslint-disable-next-line you-dont-need-lodash-underscore/assign
          _.assign({}, lastSeenSlide, { index: 0 })
        : lastSeenSlide;
    nextSlide = findFirstSlide(allSlides, mutatedLastSeenSlide) as AnyFixMe;
  }

  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences<FirstTimeTourSlide>(
      lastSeenSlideKey,
      {
        flow: flowName,
        index: allSlides.indexOf(nextSlide),
      },
    ),
  );
  return nextSlide;
}

export { FLOW_NAMES, getNextSlide };

// @ts-nocheck
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import _ from 'lodash';
import * as core from '#packages/core';
import createReactClass from 'create-react-class';
import React from 'react';
import { ToolPanelFrame } from '../../frames';
import {
  Button,
  Checkbox,
  Composites,
  CustomScroll,
  Divider,
  Tooltip,
} from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';

const setToggleForGroup = (groupId, isSelected, groups) =>
  groups.map((group) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    group.id === groupId ? _.assign({}, group, { checked: isSelected }) : group,
  );

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'rolesManager',

  mixins: [core.mixins.editorAPIMixin, ReactLinkedStateMixin], //eslint-disable-line react/no-deprecated

  getInitialState() {
    const { selectedGroups, groups } = this.props;
    const currentlySelectedGroups = groups.map(
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      (group) => _.find(selectedGroups, ['id', group.id]) || group,
    );

    return {
      groups: this.props.groups,
      selectedGroups: currentlySelectedGroups,
      currentSelection: currentlySelectedGroups,
    };
  },

  getGroups() {
    return this.state.currentSelection;
  },

  shouldRenderGroups() {
    return this.state.currentSelection.length > 0;
  },

  onCheckboxToggle(groupId, isSelected) {
    this.setState({
      currentSelection: setToggleForGroup(
        groupId,
        isSelected,
        this.state.currentSelection,
      ),
    });
  },

  applyChanges() {
    const { currentSelection, selectedGroups } = this.state;
    const diff = _.differenceWith(currentSelection, selectedGroups, _.isEqual);

    if (diff) {
      this.props.applyButtonCallback(diff);
    }

    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },

  render() {
    return (
      <ToolPanelFrame
        panelName={this.props.panelName}
        headerTitle={translate(this.props.headerTitle)}
        contentClass="roles-manager-panel"
        lightHeader={true}
        contentWrapperClass="roles-manager-panel-wrapper"
      >
        <Divider long={true} />

        <div className="content-section">
          {this.shouldRenderGroups() ? (
            <div key="should-show-checkbox-container">
              <CustomScroll heightRelativeToParent="100%">
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
                {_.map(this.getGroups(), (option, optionIndex) => (
                  <div
                    key={option + optionIndex}
                    className="checkbox-container"
                  >
                    <Checkbox
                      value={option.checked}
                      label={option.title}
                      name={option.id}
                      disabled={option.disabled}
                      labelAfterSymbol={true}
                      onChange={(value) => {
                        this.onCheckboxToggle(option.id, value);
                      }}
                      shouldTranslate={false}
                    />
                  </div>
                ))}
              </CustomScroll>
              <div className="content-section-divider">
                <Divider long={true} />
              </div>
            </div>
          ) : null}
          <Tooltip
            content="NewPages_Plans_Manager_NewPlan_Disabled"
            disabled={!this.props.disableAddNew}
          >
            <Button
              onClick={this.props.addNewButtonCallback}
              className="btn-text add-new-role"
              disabled={this.props.disableAddNew}
            >
              <span>{translate(this.props.addNewButtonLabel)}</span>
            </Button>
          </Tooltip>
        </div>
        <Divider long={true} />
        {this.getGroups() ? (
          <footer key="footer" className="footer-section">
            <Composites.ButtonLarge>
              <baseUI.button
                label={this.props.applyButtonLabel}
                onClick={this.applyChanges}
                className="btn-confirm-primary btn-md pp-apply"
              />
            </Composites.ButtonLarge>
          </footer>
        ) : null}
      </ToolPanelFrame>
    );
  },
});

import _ from 'lodash';
import { registerComponentGfppData } from '#packages/gfppData';
import tpaWidgetGfppData from './components/tpaWidgetGfppData';
import tpaSectionGfppData from './components/tpaSectionGfppData';
import tpaMultiSectionGfppData from './components/tpaMultiSectionGfppData';
import tpaGluedWidgetGfppData from './components/tpaGluedWidgetGfppData';
import type { GFPPDataRawOrResolver } from '@wix/editor-component-types';

const gfppDataToRegister: Record<string, GFPPDataRawOrResolver> = {
  TPAWidget: tpaWidgetGfppData,
  TPASection: tpaSectionGfppData,
  TPAMultiSection: tpaMultiSectionGfppData,
  TPAGluedWidget: tpaGluedWidgetGfppData,
  // TODO: why we have ProGallery as a separate type?
  ProGallery: _.clone(tpaWidgetGfppData),
};

function init() {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(
    gfppDataToRegister,
    (componentGfppData: GFPPDataRawOrResolver, componentType: string) => {
      registerComponentGfppData(componentType, componentGfppData);
    },
  );
}

export { init };

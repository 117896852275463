import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';
import { constants as santaCoreUtilsConstants } from '@wix/santa-core-utils';
import { BaseDragApiKey } from '#packages/apis';

import constants from '#packages/constants';
import * as coreBi from '#packages/coreBi';
const interactionsContext = stateManagement.interactions.context;

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { EditorState } from '#packages/stateManagement';
import { getComponentUIColor } from '../selectionBox/editBox/common';

const { TRANSFORMATIONS_ORDER } = santaCoreUtilsConstants;
const { selectTrigger, exitInteraction } = stateManagement.interactions.actions;

const {
  isComponentVisibleInInteractionMode,
  inInteractionForbiddenDragComponent,
  getInteractionTriggerRef,
  isShownOnlyOnVariant,
  getVariantPointers,
  getVariantId,
} = stateManagement.interactions.selectors;
const { translateToViewerCoordinates } =
  stateManagement.domMeasurements.selectors;

interface FixFullWidthLayoutObject {
  fixByDirectChild: boolean;
}

interface FixFullWidthLayoutForChildrenList {
  [compType: string]: FixFullWidthLayoutObject;
}

const fixFullWidthLayoutForChildrenList: FixFullWidthLayoutForChildrenList = {
  [constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER]: {
    fixByDirectChild: true,
  },
  [constants.COMP_TYPES.COLUMN]: {
    fixByDirectChild: false,
  },
};

const NAV_CONTROLS_CONSIDERING_COMPONENTS = [
  constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER,
  constants.COMP_TYPES.COLUMN,
];

const getFixFullWidthLayoutData = (
  triggerCompRef: CompRef,
  triggerType: string,
  properties: AnyFixMe,
  getAncestors: AnyFixMe,
): FixFullWidthLayoutObject => {
  if (
    triggerType === constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER ||
    triggerType === constants.COMP_TYPES.COLUMN
  ) {
    let isFullWidth = properties.get(triggerCompRef).fullWidth;

    if (triggerType === constants.COMP_TYPES.COLUMN) {
      isFullWidth = properties.get(getAncestors(triggerCompRef)[0]).fullWidth;
    }

    if (isFullWidth) {
      return fixFullWidthLayoutForChildrenList[triggerType];
    }
  }
};

export const getTransformString = (
  transformationsObject: any,
  rotationFromStructure: number = 0,
  rotateDisabled: boolean = false,
  skewDisabled: boolean = false,
  selectedOffsetX?: number,
  selectedOffsetY?: number,
): string => {
  if (!transformationsObject) transformationsObject = {};

  const { scale, rotate = 0, translate, skew } = transformationsObject;

  const transformationsString = {
    translate: translate
      ? `translateX(${translate.x.value}px) translateY(${translate.y.value}px)`
      : '',
    scale: scale ? `scaleX(${scale.x}) scaleY(${scale.y})` : '',
    rotate: `rotate(${
      (rotateDisabled ? 0 : rotate) + rotationFromStructure
    }deg)`,
    skew:
      !skewDisabled && skew ? `skewX(${skew.x}deg) skewY(${skew.y}deg)` : '',
  };

  if (
    typeof selectedOffsetX === 'number' &&
    typeof selectedOffsetY === 'number'
  ) {
    transformationsString.translate = `translateX(${selectedOffsetX}px) translateY(${selectedOffsetY}px)`;
  }

  const transformString = TRANSFORMATIONS_ORDER.reduce(
    (transformString, transformKey) => {
      if (
        transformationsString[
          transformKey as keyof typeof transformationsString
        ]
      ) {
        return `${transformString} ${
          transformationsString[
            transformKey as keyof typeof transformationsString
          ]
        }`;
      }
      return transformString;
    },
    '',
  );

  return transformString.trim();
};

export const getTransformOriginString = (compTransformations: any) => {
  let originString = '';

  if (compTransformations?.origin) {
    const { x, y } = compTransformations.origin;
    originString = `${x.value}% ${y.value}%`;
  }
  return originString;
};

export const getNegativeScaleAndSkewString = (
  transformationsObject: any,
  skewDisabled: boolean = false,
): string => {
  if (!transformationsObject) transformationsObject = {};

  const { scale, skew } = transformationsObject;

  const negativeTransformationsString = {
    scale: scale ? `scaleX(${1 / scale.x}) scaleY(${1 / scale.y})` : '',
    skew:
      !skewDisabled && skew
        ? `skewX(${skew.x * -1}deg) skewY(${skew.y * -1}deg)`
        : '',
  };

  const negativeTransformString = TRANSFORMATIONS_ORDER.reduce(
    (negativeTransformString, transformKey) => {
      if (
        negativeTransformationsString[
          transformKey as keyof typeof negativeTransformationsString
        ]
      ) {
        return `${negativeTransformString} ${
          negativeTransformationsString[
            transformKey as keyof typeof negativeTransformationsString
          ]
        }`;
      }
      return negativeTransformString;
    },
    '',
  );

  return negativeTransformString.trim();
};

export interface CompRefNode {
  compRef: CompRef;
  children: CompRefNode[];
}

export interface ListCompItem {
  compRef: CompRef;
  isTrigger: boolean;
  isSelected: boolean;
  selectable: boolean;
  canCompBeDragged: boolean;
  isShownOnlyInVariant: boolean;
  transformationsObject: object;
  isDirectChildOfTrigger: boolean;
  compTransformations: object;
  calculatedLayout: {
    x: number;
    y: number;
    width: number;
    height: number;
    rotationInDegrees: number;
  };
}

export interface LayoutAndTranformationsMap {
  [key: string]: ListCompItem;
}

const stretchedWidthOffsetMap: { [key: string]: number } = {};

interface BuildListCompItemParams {
  editorAPI: EditorAPI;
  triggerCompRef: CompRef;
  compRef: CompRef;
  selectedCompRef: CompRef;
  isTrigger: boolean;
  isDirectChildOfTrigger: boolean;
  fixFullWidthLayoutData?: FixFullWidthLayoutObject;
}

const buildListCompItem = ({
  editorAPI,
  triggerCompRef,
  compRef,
  selectedCompRef,
  isTrigger,
  isDirectChildOfTrigger,
  fixFullWidthLayoutData,
}: BuildListCompItemParams): ListCompItem => {
  const { layout, transformations } = editorAPI.components;
  const variants = getVariantPointers(editorAPI.store.getState());
  let layoutToUse = layout.get(compRef);

  if (
    isTrigger &&
    (fixFullWidthLayoutData ? fixFullWidthLayoutData.fixByDirectChild : true)
  ) {
    const relativeToScreen = layout.getRelativeToScreen(compRef);
    layoutToUse = relativeToScreen;
  }

  if (fixFullWidthLayoutData) {
    if (
      (isDirectChildOfTrigger && fixFullWidthLayoutData.fixByDirectChild) ||
      (isTrigger && !fixFullWidthLayoutData.fixByDirectChild)
    ) {
      const relativeToScreen = layout.getRelativeToScreen(compRef);
      const layoutOffsetForFullWidthTrigger =
        relativeToScreen.width - layoutToUse.width;
      layoutToUse.width += layoutOffsetForFullWidthTrigger;
      stretchedWidthOffsetMap[compRef.id] = layoutOffsetForFullWidthTrigger;
      layoutToUse.x = relativeToScreen.x;
      if (isTrigger) {
        layoutToUse = {
          ...relativeToScreen,
          x: layoutToUse.x,
          width: layoutToUse.width,
        };
      }
    } else {
      let rootCompToFixBy: CompRef = triggerCompRef;
      if (fixFullWidthLayoutData.fixByDirectChild) {
        const ancestors =
          editorAPI.components.getAncestors_DEPRECATED_BAD_PERFORMANCE(compRef);
        const triggerIndex = ancestors.findIndex(
          (ancestor) => ancestor.id === triggerCompRef.id,
        );
        rootCompToFixBy = ancestors[triggerIndex - 1] || rootCompToFixBy;
      }
      if (stretchedWidthOffsetMap[rootCompToFixBy.id]) {
        let alignment =
          editorAPI.components.properties.get(rootCompToFixBy)?.alignment;
        if (!Number.isInteger(alignment)) {
          alignment = 50;
        }
        const alignmentRatio = alignment / 50;
        layoutToUse.x +=
          (stretchedWidthOffsetMap[rootCompToFixBy.id] / 2) * alignmentRatio;
      }
    }
  }

  return {
    compRef,
    isTrigger,
    canCompBeDragged: !inInteractionForbiddenDragComponent(
      editorAPI,
      selectedCompRef,
    ),
    isSelected: compRef.id === selectedCompRef.id,
    isDirectChildOfTrigger,
    isShownOnlyInVariant: isShownOnlyOnVariant(editorAPI, compRef),
    transformationsObject: transformations.get({
      ...compRef,
      variants,
    }),
    compTransformations: transformations.get(compRef),
    calculatedLayout: {
      x: layoutToUse.x,
      y: layoutToUse.y,
      width: layoutToUse.width,
      height: layoutToUse.height,
      rotationInDegrees: layoutToUse.rotationInDegrees,
    },
    selectable: editorAPI.components.is.selectable(compRef),
  };
};

let triggerTree: CompRefNode;
const layoutAndTranformationsMap: LayoutAndTranformationsMap = {};
let prevSelectedId: CompRef['id'];

interface GetCompRefWithChildrenParams {
  editorAPI: EditorAPI;
  state: EditorState;
  triggerCompRef: CompRef;
  compRef: CompRef;
  selectedCompRef: CompRef;
  isTrigger: boolean;
  isDirectChildOfTrigger: boolean;
  fixFullWidthLayoutData?: FixFullWidthLayoutObject;
}

const getCompRefWithChildren = ({
  editorAPI,
  state,
  triggerCompRef,
  compRef,
  selectedCompRef,
  isTrigger,
  isDirectChildOfTrigger,
  fixFullWidthLayoutData,
}: GetCompRefWithChildrenParams): CompRefNode => {
  layoutAndTranformationsMap[compRef.id] = buildListCompItem({
    editorAPI,
    triggerCompRef,
    compRef,
    selectedCompRef,
    isTrigger,
    isDirectChildOfTrigger,
    fixFullWidthLayoutData,
  });

  const directChildren = editorAPI.documentServices.components
    .getChildren(compRef)
    .filter((childRef) =>
      isComponentVisibleInInteractionMode(editorAPI.dsRead, childRef, state),
    );

  return {
    compRef,
    children: directChildren.map((child) =>
      getCompRefWithChildren({
        editorAPI,
        state,
        triggerCompRef,
        compRef: child,
        selectedCompRef,
        isTrigger: false,
        isDirectChildOfTrigger: isTrigger,
        fixFullWidthLayoutData,
      }),
    ),
  };
};

const removeDeletedCompsFromTree = (
  editorAPI: EditorAPI,
  triggerTree: CompRefNode,
) => {
  let frontier = [triggerTree];

  while (frontier.length) {
    let next: AnyFixMe = [];

    frontier.forEach((node) => {
      const existedChildren = node.children.filter((child) => {
        const isExist = editorAPI.documentServices.components.is.exist(
          child.compRef,
        );
        if (!isExist) {
          delete layoutAndTranformationsMap[child.compRef.id];
        }
        return isExist;
      });

      if (existedChildren.length !== node.children.length) {
        node.children = existedChildren;
        stateManagement.interactions.context.reRenderConsumers();
      }
      next = [...next, ...node.children];
    });
    frontier = next;
  }
};

export const mapStateToProps = ({ editorAPI, state }: AnyFixMe) => {
  const { components, selection } = editorAPI;
  const { getType, properties, getAncestors } = components;

  const triggerCompRef = getInteractionTriggerRef(state);
  const selectedCompRef = selection.getSelectedComponents()[0];
  const selectedShownOnlyInVariant = isShownOnlyOnVariant(
    editorAPI,
    selectedCompRef,
  );
  const triggerType = getType(triggerCompRef);

  const fixFullWidthLayoutData = getFixFullWidthLayoutData(
    triggerCompRef,
    triggerType,
    properties,
    getAncestors,
  );

  const shouldConsiderNavControlsOffset =
    NAV_CONTROLS_CONSIDERING_COMPONENTS.includes(triggerType);

  if (!prevSelectedId || selectedCompRef.id !== prevSelectedId) {
    prevSelectedId = selectedCompRef.id;
    triggerTree = getCompRefWithChildren({
      editorAPI,
      state,
      triggerCompRef,
      compRef: triggerCompRef,
      selectedCompRef,
      isTrigger: true,
      isDirectChildOfTrigger: false,
      fixFullWidthLayoutData,
    });
  } else {
    removeDeletedCompsFromTree(editorAPI, triggerTree);
  }

  const prevCurrentSelectedData =
    layoutAndTranformationsMap[selectedCompRef.id];

  layoutAndTranformationsMap[selectedCompRef.id] = {
    ...prevCurrentSelectedData,
    ...buildListCompItem({
      editorAPI,
      triggerCompRef,
      compRef: selectedCompRef,
      selectedCompRef,
      isTrigger: prevCurrentSelectedData?.isTrigger,
      isDirectChildOfTrigger: prevCurrentSelectedData?.isDirectChildOfTrigger,
      fixFullWidthLayoutData,
    }),
  };

  return {
    selectedCompRef,
    selectedShownOnlyInVariant,
    triggerTree,
    layoutAndTranformationsMap,
    shouldConsiderNavControlsOffset,
    componentUIColor: getComponentUIColor(editorAPI, triggerCompRef),
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

export const mapDispatchToProps = (dispatch: AnyFixMe) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);
  return {
    registerDragMouseMoveAction: (
      event: React.MouseEvent,
      isDraggingWithHandle?: boolean,
    ) => {
      event.stopPropagation();
      const translatedCoordinates = translateToViewerCoordinates(
        editorAPI,
        event,
      );
      const initMousePosition = {
        x: translatedCoordinates.pageX,
        y: translatedCoordinates.pageY,
        isShiftPressed: event.shiftKey,
        isAltKeyPressed: event.altKey,
        isSpecialKeyPressed: util.browserUtil.isSpecialKeyPressed(event),
      };

      interactionsContext.setLayout({
        isDraggingWithHandle: !!isDraggingWithHandle,
      });

      editorAPI.mouseActions.registerMouseMoveAction(
        editorAPI.host.getAPI(BaseDragApiKey),
        {
          selectedComp: editorAPI.selection.getSelectedComponents(),
          shouldDragAndCopy: false,
          initMousePosition,
        },
      );
    },
    exitMode: () => {
      const state = editorAPI.store.getState();
      const triggerRef = getInteractionTriggerRef(state);
      const compType = editorAPI.components.getType(triggerRef);
      dispatch(exitInteraction());
      editorAPI.bi.event(coreBi.events.interactions.exit_interaction_mode, {
        component_id: triggerRef.id,
        interaction_id: getVariantId(state),
        component_type: compType,
        origin: 'hat',
      });
    },
    selectTrigger: () => {
      const triggerRef = getInteractionTriggerRef(editorAPI.store.getState());
      selectTrigger(editorAPI, triggerRef);
    },
  };
};

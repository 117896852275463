import type { Scope } from '#packages/sections';

export const enforceSupportMobile = (scope: Scope) => {
  const masterPageRef = scope.editorAPI.components.get.byId(
    scope.editorAPI.pages.getMasterPageId(),
  );
  const supportMobile =
    scope.editorAPI.components.data.get(masterPageRef).supportMobile;

  if (supportMobile === false) {
    scope.editorAPI.components.data.update(masterPageRef, {
      supportMobile: true,
    });
  }
};

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import * as core from '#packages/core';
import _ from 'lodash';
import * as panelUtils from '#packages/panelUtils';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import { connectBackground } from '#packages/background';
import * as stateManagement from '#packages/stateManagement';
import * as BaseUI from '#packages/baseUI';
import {
  Composites,
  ToggleSwitch,
  Divider,
  CustomScroll,
  TextLabel,
  Thumbnails,
  DropDown,
  DropDownOption,
} from '@wix/wix-base-ui';
import * as Panels from '#packages/panels';

const BASE_IMAGE_URL = 'rEditor/backgroundPanel/overlayPatternIcons/';

const thumbPatternOptions = [
  {
    value: '',
    illustration: React.createElement('img', {
      src: util.media.getMediaUrl(`${BASE_IMAGE_URL}v-overlay-pat-1-icon.png`),
    }),
  },
  {
    value: '',
    illustration: React.createElement('img', {
      src: util.media.getMediaUrl(`${BASE_IMAGE_URL}v-overlay-pat-2-icon.png`),
    }),
  },
  {
    value: '',
    illustration: React.createElement('img', {
      src: util.media.getMediaUrl(`${BASE_IMAGE_URL}v-overlay-pat-3-icon.png`),
    }),
  },
  {
    value: '',
    illustration: React.createElement('img', {
      src: util.media.getMediaUrl(`${BASE_IMAGE_URL}v-overlay-pat-4-icon.png`),
    }),
  },
  {
    value: '',
    illustration: React.createElement('img', {
      src: util.media.getMediaUrl(`${BASE_IMAGE_URL}v-overlay-pat-5-icon.png`),
    }),
  },
  {
    value: '',
    illustration: React.createElement('img', {
      src: util.media.getMediaUrl(`${BASE_IMAGE_URL}v-overlay-pat-6-icon.png`),
    }),
  },
  {
    value: '',
    illustration: React.createElement('img', {
      src: util.media.getMediaUrl(`${BASE_IMAGE_URL}v-overlay-pat-7-icon.png`),
    }),
  },
];
const blackPatternUrls = [
  { uri: '0da768_8b89980121844d37be099f0dbe33e3e3.png', width: 6, height: 6 },
  { uri: '0da768_4371c7e64a49489bb88c536f515f2040.png', width: 6, height: 6 },
  { uri: '0da768_361994b0f154464682a0aaf9724471cc.png', width: 56, height: 56 },
  {
    uri: '0da768_880a3209e3744cd0a96b66835a01b8c5.png',
    width: 256,
    height: 256,
  },
  { uri: '0da768_2729bfbce3874276a7dd057098f93949.png', width: 6, height: 6 },
  { uri: '0da768_8fae2cc5b7bf477fb13faa1f2582dbd2.png', width: 4, height: 6 },
  { uri: '0da768_2326357be59441978a570bec687a94d0.png', width: 3, height: 3 },
];
const whitePatternUrls = [
  { uri: '0da768_5810e2bd76f64c77a67904ad2b073444.png', width: 6, height: 6 },
  { uri: '0da768_46b55443eb984c0eacfbdcb1b67a2b7b.png', width: 6, height: 6 },
  { uri: '0da768_d3e25a05cedd404f807528f6a4750388.png', width: 56, height: 56 },
  {
    uri: '0da768_95dbd595356148718d5876246a20fe90.png',
    width: 256,
    height: 256,
  },
  { uri: '0da768_0ffcd1b0986d43b4aaa061b8b17d4040.png', width: 6, height: 6 },
  { uri: '0da768_0c0d2dc79bc14f6b8275c3b72fec1ab6.png', width: 4, height: 6 },
  { uri: '0da768_09a75bbb10944eafb4539206b6a3c615.png', width: 3, height: 3 },
];

for (let i = 0; i < blackPatternUrls.length; i++) {
  blackPatternUrls[i].type = 'Image';
  whitePatternUrls[i].type = 'Image';
}

const playBackOption = [
  { value: 0.25, label: '0.25x' },
  { value: 0.5, label: '0.50x' },
  { value: 1, label: translate('BGPP_SETTINGS_VIDEO_PLAYBACK_NORMAL') },
  { value: 1.25, label: '1.25x' },
  { value: 1.5, label: '1.50x' },
  { value: 2, label: '2.00x' },
];

const invertPatternBtnGroup = [
  { value: false, label: 'BGPP_SETTINGS_VIDEO_OVERLAY_PATTERN_DARKER' },
  { value: true, label: 'BGPP_SETTINGS_VIDEO_OVERLAY_PATTERN_LIGHTER' },
  //{value: false, label: 'Dark'},
  //{value: true, label: 'Light'}
];

const defaults = {
  loop: true,
  uri: null,
  colorOverlay: '#ffffff',
  colorOverlayOpacity: 0.2,
  scrollType: 'scroll',
  playbackSpeed: 1,
};

function storeLastPatternState(editorAPI, state) {
  const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      `bg_video_settings_pattern_state_${pageId}`,
      state,
    ),
  );
}

function markDataChange(editorAPI) {
  const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      `bg_video_settings_data_changed_${pageId}`,
      true,
    ),
  );
}

function getPatternState(currentPatterns) {
  return currentPatterns === whitePatternUrls;
}

function getCurrentPatternOptions(bgData, editorAPI) {
  if (!bgData.ref.imageOverlay) {
    const pageId = editorAPI.dsRead.pages.getPrimaryPageId();
    const state =
      stateManagement.userPreferences.selectors.getSessionUserPreferences(
        `bg_video_settings_pattern_state_${pageId}`,
      )(editorAPI.store.getState());
    return state ? whitePatternUrls : blackPatternUrls;
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
  } else if (_.find(blackPatternUrls, { uri: bgData.ref.imageOverlay.uri })) {
    return blackPatternUrls;
  }
  return whitePatternUrls;
}

export interface BgVideoSettingsPanelComponent {
  bgData?: AnyFixMe;
  undoHandler?: FunctionFixMe;
  requestChange?: FunctionFixMe;
  undoOrigins?: {
    VIDEO_PANEL: string;
  };
}

// prettier-ignore
// eslint-disable-next-line react/prefer-es6-class
const BgVideoSettingsPanelComponent = createReactClass<BgVideoSettingsPanelComponent>({
  displayName: 'bgVideoSettingsPanel',
  childContextTypes: {
    reportUIChange: PropTypes.func,
  },
  mixins: [core.mixins.editorAPIMixin, panelUtils.linkColorPickerMixin],

  getInitialState() {
    this.lastColor = '';
    this.lastColorOpacity = null;
    this.currentPatterns = getCurrentPatternOptions(
      this.props.bgData,
      this.getEditorAPI(),
    );
    this.patternState = getPatternState(this.currentPatterns);
    this.thumbPatternOptions = this.fillThumbPatternOptions(
      this.currentPatterns,
    );

    return null;
  },

  getChildContext() {
    return {
      reportUIChange: this.onUIChange,
    };
  },

  shouldComponentUpdate(nextProps) {
    //if panel is open while media was removed by Undo.  close panel and prevent render
    const { bgData } = nextProps;
    const editorAPI = this.getEditorAPI();
    if (!bgData.ref.mediaRef || bgData.ref.mediaRef.type !== 'WixVideo') {
      editorAPI.panelManager.closePanelByName(nextProps.panelName);
      return false;
    }
    return true;
  },

  onUIChange(controlName /*, instanceId, event*/) {
    if (controlName.toLowerCase() === 'slider') {
      this.props.undoHandler(this.props.undoOrigins.VIDEO_PANEL);
    }
  },

  getValue(imageOverlay) {
    return imageOverlay?.uri || null;
  },

  onChange(data, val) {
    if (val) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      const currentPattern = _.find(this.currentPatterns, { uri: val });
      this.patternState = getPatternState(this.currentPatterns);
      data.ref.imageOverlay = data.ref.imageOverlay || {};
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(data.ref.imageOverlay, currentPattern);
    } else {
      data.ref.imageOverlay = null;
    }
    storeLastPatternState(this.getEditorAPI(), this.patternState);
    this.props.requestChange(data, this.props.undoOrigins.VIDEO_PANEL);
    markDataChange(this.getEditorAPI());
  },

  applyInversePattern(data, mediaRef) {
    return {
      value: this.patternState,
      requestChange: function (val) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
        const index = _.findIndex(this.currentPatterns, {
          uri: mediaRef.uri,
        });
        this.currentPatterns = val ? whitePatternUrls : blackPatternUrls;
        this.fillThumbPatternOptions(this.currentPatterns);

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign(data.ref.imageOverlay, this.currentPatterns[index]);
        this.patternState = val;
        storeLastPatternState(this.getEditorAPI(), val);
        this.props.requestChange(data, this.props.undoOrigins.VIDEO_PANEL);
        markDataChange(this.getEditorAPI());
      }.bind(this),
    };
  },

  isColorOverlay(data) {
    return (
      !_.isEmpty(data.ref.colorOverlay) &&
      _.isNumber(data.ref.colorOverlayOpacity)
    );
  },

  applyOverlayColor(data) {
    return {
      value: this.isColorOverlay(data),
      requestChange: function (val) {
        if (val) {
          //apply from 'cache' or from defaults
          data.ref.colorOverlay = this.lastColor
            ? this.lastColor
            : data.ref.color;
          data.ref.colorOverlayOpacity = _.isNumber(this.lastColorOpacity)
            ? this.lastColorOpacity
            : defaults.colorOverlayOpacity;
        } else {
          //store last
          this.lastColor = data.ref.colorOverlay;
          this.lastColorOpacity = data.ref.colorOverlayOpacity;
          //clear overlay color
          data.ref.colorOverlay = '';
          data.ref.colorOverlayOpacity = 0;
        }
        this.props.requestChange(data, this.props.undoOrigins.VIDEO_PANEL);
        markDataChange(this.getEditorAPI());
      }.bind(this),
    };
  },

  linkColorWithOpacity(data, base) {
    return {
      value: {
        color: base.colorOverlay.replace(/[\{\}]/g, ''),
        alpha: base.colorOverlayOpacity,
      },
      requestChange: function (colorWithAlpha) {
        base.colorOverlay = /color_/.test(colorWithAlpha.color)
          ? `{${colorWithAlpha.color}}`
          : colorWithAlpha.color;
        base.colorOverlayOpacity = colorWithAlpha.alpha;
        this.props.requestChange(data);
        markDataChange(this.getEditorAPI());
      }.bind(this),
    };
  },

  getColorPickerProps() {
    return {
      colorResolver: this.resolveColor,
      openColorPicker: this.openColorPicker,
      previewOnHover: true,
      useMouseEvent: true,
      enableHistory: true,
    };
  },

  linkedBackgroundData(data, base, key) {
    return {
      value:
        !base || typeof base[key] === 'undefined' ? defaults[key] : base[key],
      requestChange: function (val) {
        base[key] = val;
        this.props.requestChange(data, this.props.undoOrigins.VIDEO_PANEL);
        markDataChange(this.getEditorAPI());
      }.bind(this),
    };
  },

  getPlaybackOptions() {
    return playBackOption;
  },

  fillThumbPatternOptions(currentPatterns) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(currentPatterns, function (option, index) {
      thumbPatternOptions[index].value = currentPatterns[index].uri;
    });
    return thumbPatternOptions;
  },

  getInvertPatternOption() {
    return invertPatternBtnGroup;
  },

  render() {
    const data = this.props.bgData;

    return (
      <Panels.frames.ToolPanelFrame
        helpId="7f54f04e-6e0b-4f73-96e2-6c98dd28e822"
        contentWrapperClass="background-video-settings-panel background-sub-panel"
        headerTitle={translate('BGPP_SETTINGS_VIDEO_PANEL_HEADER_TITLE')}
        {..._.omit(this.props, 'children')}
      >
        <CustomScroll>
          <div className="bg-video-settings-panel-scrollbox bg-settings-panel-scrollbox">
            <div className="background-video-patterns-control">
              <Composites.Thumbnails>
                <TextLabel value="BGPP_SETTINGS_VIDEO_OVERLAY_PATTERN_TITLE" />
                <Thumbnails
                  value={this.getValue(data.ref.imageOverlay)}
                  onChange={(value) => {
                    this.onChange(data, value);
                  }}
                  maxThumbsPerRow={4}
                  borderType="thumbnail"
                  fixedRatio={true}
                  noneThumbnailProps={{ value: null }}
                  options={this.thumbPatternOptions}
                />
              </Composites.Thumbnails>
              {data.ref.imageOverlay ? (
                <BaseUI.buttonsGroup
                  valueLink={this.applyInversePattern(
                    data,
                    data.ref.imageOverlay,
                  )}
                  options={this.getInvertPatternOption()}
                  key="inversePattern"
                  className="overlay-pattern-btn-group"
                />
              ) : null}
            </div>
            <Divider long={true} />
            <Composites.ToggleSwitch>
              <ToggleSwitch
                value={this.applyOverlayColor(data).value}
                label="BGPP_SETTINGS_VIDEO_OVERLAY_COLOR_TITLE"
                onChange={this.applyOverlayColor(data).requestChange}
              />
            </Composites.ToggleSwitch>

            {this.isColorOverlay(data) ? (
              <Divider
                long={false}
                key="color-picker-input-divider"
              />
            ) : null}
            {this.isColorOverlay(data) ? (
              <BaseUI.colorPickerInputWithOpacity
                ref="bgColorPickerInput"
                key="color-picker-input"
                label="BGPP_SETTINGS_VIDEO_COLOR_OPACITY_TITLE"
                valueLink={this.linkColorWithOpacity(data, data.ref)}
                {...this.getColorPickerProps()}
              />
            ) : null}

            <Divider long={true} />
            <BaseUI.sectionDividerLabeled label="BGPP_SETTINGS_VIDEO_PLAYBACK_SECTION_TITLE" />
            {this.getEditorAPI().isDesktopEditor() ? (
              <Divider long={true} key="whptoDividerImageDesc" />
            ) : null}

              <Composites.DropDownLabeled>
                <TextLabel value={translate('BGPP_SETTINGS_VIDEO_PLAYBACK_TITLE')} shouldTranslate={false} />
                <DropDown
                  value={this.linkedBackgroundData(
                    data,
                    data.ref.mediaRef,
                    'playbackSpeed',
                  ).value}
                  onChange={this.linkedBackgroundData(
                    data,
                    data.ref.mediaRef,
                    'playbackSpeed',
                  ).requestChange}
                  shouldTranslate={false}
                >
                  {this.getPlaybackOptions().map(
                    (option: { value: string; label: string }, optionIndex) => (
                      <DropDownOption
                        key={`playbackOption${optionIndex}`}
                        value={option.value}
                        label={option.label}
                        shouldTranslate={false}
                      />
                    ),
                  )}
                </DropDown>
              </Composites.DropDownLabeled>

            <Divider long={true} />
            <Composites.ToggleSwitch>
              <ToggleSwitch
                value={
                  this.linkedBackgroundData(data, data.ref.mediaRef, 'loop')
                    .value
                }
                label="BGPP_SETTINGS_VIDEO_LOOP_TITLE"
                onChange={
                  this.linkedBackgroundData(data, data.ref.mediaRef, 'loop')
                    .requestChange
                }
              />
            </Composites.ToggleSwitch>
          </div>
        </CustomScroll>
      </Panels.frames.ToolPanelFrame>
    );
  },
});

export default connectBackground(BgVideoSettingsPanelComponent);

import React from 'react';
import { Button } from '@wix/wix-base-ui';
import _ from 'lodash';
import type { MapStateToProps } from 'types/redux';
import * as symbols from '@wix/santa-editor-symbols';
import * as coreBi from '#packages/coreBi';
import { translate } from '#packages/i18n';
import * as util from '#packages/util';
import constants from '#packages/constants';
import type { SendBiFunction } from 'types/bi';

import PromotionalPanel from './promotionalPanel/promotionalPanel';
import type { DealerViewerProps } from './dealerUpgradePanel';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const LIST_ITEMS_OFF = [
  translate('Topbar_Arena_DropMenu_Bullet1'),
  translate('Topbar_Arena_DropMenu_Bullet2'),
  translate('Topbar_Arena_DropMenu_Bullet3'),
  translate('Topbar_Arena_DropMenu_Bullet4'),
];

interface ArenaDropPanelStateProps {
  metaSiteId?: string;
  metaSiteInstance?: string;
  dealerViewer?: React.ComponentType<DealerViewerProps>;
}

interface ArenaDropPanelOwnProps {
  sendBi: SendBiFunction;
}

type ArenaDropPanelProps = ArenaDropPanelOwnProps & ArenaDropPanelStateProps;

interface ArenaDropPanelState {
  dealerHasError: boolean;
}

class ArenaDropPanelDealer extends React.Component<
  ArenaDropPanelProps,
  ArenaDropPanelState
> {
  displayName = 'ArenaDropPanel';
  state = {
    dealerHasError: false,
  };

  componentDidMount() {
    if (!this.dealerLoadSuccess) {
      this.renderTimeout = util.dealerUtils.createDealerRenderSuccessTimeout(
        this.fallbackToDefaultPanel,
      );
    }
  }

  componentWillUnmount() {
    util.dealerUtils.clearDealerRenderSuccessTimeout(this.renderTimeout);
  }

  private dealerLoadSuccess: boolean = false;
  private renderTimeout: number;

  onDealerLoadSuccess = () => {
    util.dealerUtils.clearDealerRenderSuccessTimeout(this.renderTimeout);
    this.dealerLoadSuccess = true;
  };

  onConfirmButtonClick = () => {
    window.open(
      'https://www.wix.com/marketplace/brief-auto-match?entry=editor&origin=editor-top-menu',
      '_blank',
    );
    this.props.sendBi(coreBi.events.topbar.arena_button_clicked, {
      origin: 'top bar',
    });
  };

  fallbackToDefaultPanel = () => {
    this.setState({
      dealerHasError: true,
    });
  };

  render() {
    const { dealerViewer: DealerViewer } = this.props;

    if (this.state.dealerHasError) {
      return (
        <div data-hook="fallback-arena-drop-panel-dealer-banner">
          <PromotionalPanel
            automationId="drop-panel-arena"
            title={translate('Topbar_Arena_DropMenu_Title')}
            subtitle={translate('Topbar_Arena_DropMenu_Subtitle')}
            listItems={LIST_ITEMS_OFF}
            symbol={<symbols.symbol name="hirePartner_NewWorkspace" />}
            renderFooter={() => (
              <Button
                automationId="arena-drop-down-btn"
                onClick={this.onConfirmButtonClick}
                className="btn-confirm-primary"
              >
                <span>{translate('Topbar_Arena_DropMenu_Start_Button')}</span>
              </Button>
            )}
          />
        </div>
      );
    }

    return (
      <div data-hook="arena-drop-panel-dealer-banner">
        <DealerViewer
          key="dealerViewer"
          bannerPosition="254f79d4-1efa-4178-b5f1-0138b16593d7"
          onDisplay={this.onDealerLoadSuccess}
          onError={this.fallbackToDefaultPanel}
          metasiteGuid={this.props.metaSiteId}
          signedInstance={this.props.metaSiteInstance}
          {...util.dealerUtils.getDealerViewerBaseProps()}
        />
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<ArenaDropPanelStateProps> = ({
  editorAPI,
}) => {
  const metaSiteInstance = _.invoke(
    editorAPI,
    'dsRead.platform.getAppDataByApplicationId',
    constants.APPLICATIONS.META_SITE_APPLICATION_ID,
  )?.instance;
  const metaSiteId =
    _.invoke(editorAPI, 'dsRead.generalInfo.getMetaSiteId') ||
    util?.editorModel?.metaSiteId;

  return {
    dealerViewer: editorAPI?.wixReactDealerViewer?.DealerViewer,
    metaSiteInstance,
    metaSiteId,
  };
};

export default connect(EDITOR_API, mapStateToProps, {})(ArenaDropPanelDealer);

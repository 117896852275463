import { events } from '#packages/coreBi';
import { sections as sectionsUtils } from '#packages/util';
import { errorReporter } from '../../utils';
import { DEFAULT_CONTENT_ROLE } from '../../constants';

import type { CompRef, DocumentServicesObject } from 'types/documentServices';
import type { SendBiFunction } from 'types/bi';
import type { SectionDescription } from '../types';

export const addSections = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sectionsToCreate: SectionDescription[],
): SectionDescription[] =>
  sectionsToCreate.map((section: SectionDescription) => {
    // TODO: one doc anchors?
    const ref = documentServices.components.add(
      pageRef,
      sectionsUtils.getBlankSectionStructure({
        y: section.top,
        height: Math.max(section.bottom - section.top, 0),
        name: section.name,
      }),
    );

    return {
      ...section,
      ref,
    };
  });

export const setSectionsContentRole = (
  documentServices: DocumentServicesObject,
  pageSections: SectionDescription[],
) =>
  pageSections.forEach((section: SectionDescription) => {
    documentServices.components.features.update(section.ref, 'contentRole', {
      complexity: 'simple',
      type: 'ContainerRole',
      contentRole: section.contentRole ?? DEFAULT_CONTENT_ROLE,
    });
  });

export const reParent = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  parentRef: CompRef,
  componentRef: CompRef,
  sendBI: SendBiFunction,
): boolean => {
  if (documentServices.components.is.containable(componentRef, parentRef)) {
    try {
      documentServices.components.setContainer(componentRef, parentRef);

      return true;
    } catch (error: MaybeError) {
      const componentType = documentServices.components.getType(componentRef);
      const errorMessage = `reParent error: ${error.message}`;

      sendBI(events.sectionsMigration.COMPONENT_MIGRATION_ERROR, {
        pageId: pageRef.id,
        component_id: componentRef.id,
        component_type: componentType,
        errorMessage,
        template_id: documentServices.generalInfo.getSiteOriginalTemplateId(),
      });

      errorReporter(new Error(errorMessage));

      return false;
    }
  } else {
    const componentType = documentServices.components.getType(componentRef);

    sendBI(events.sectionsMigration.COMPONENT_IS_NOT_CONTAINABLE, {
      pageId: pageRef.id,
      component_id: componentRef.id,
      component_type: componentType,
      template_id: documentServices.generalInfo.getSiteOriginalTemplateId(),
    });

    errorReporter(
      new Error(
        `reParent error: component with type: ${componentType} are not containable to section`,
      ),
    );

    return false;
  }
};

export const reParentDesktopChildren = (
  documentServices: DocumentServicesObject,
  pageRef: CompRef,
  sections: SectionDescription[],
  sendBI: SendBiFunction,
) => {
  sections.forEach((section: SectionDescription) => {
    section.children.forEach((componentRef: CompRef) => {
      reParent(documentServices, pageRef, section.ref, componentRef, sendBI);
    });
  });
};

export const removeRedundantChildren = (
  documentServices: DocumentServicesObject,
  sections: SectionDescription[],
): string[] => {
  const removedRedundantComponents: string[] = [];

  sections.forEach((section: SectionDescription) => {
    section.childrenToRemove.forEach((compRef) => {
      const compChildren = documentServices.components.getChildren(compRef);

      documentServices.components.remove(compRef);

      removedRedundantComponents.push(compRef.id, compChildren?.[0]?.id);
    });
  });

  return removedRedundantComponents;
};

export const reParentPropertiesOfRedundant = (
  documentServices: DocumentServicesObject,
  sections: SectionDescription[],
) => {
  sections.forEach((section: SectionDescription) => {
    if (section.design && Object.entries(section.design).length) {
      documentServices.components.design.update(section.ref, section.design);
    }

    if (section.properties && Object.entries(section.properties).length) {
      documentServices.components.properties.update(
        section.ref,
        section.properties,
      );
    }

    if (section.behaviors?.length) {
      section.behaviors.forEach((item: Object) => {
        documentServices.components.behaviors.update(section.ref, item);
      });
    }
  });
};

export const reLayoutDesktopChildren = (
  documentServices: DocumentServicesObject,
  sections: SectionDescription[],
) => {
  sections.forEach((section: SectionDescription) => {
    section.childrenToKeepDesktopLayout.forEach(({ ref, x }) => {
      if (x !== undefined) {
        documentServices.components.layout.update(ref, {
          x,
        });
      }
    });
  });
};

import _ from 'lodash';
import * as tpa from '#packages/tpa';
import * as superAppMenuInfra from '#packages/superAppMenuInfra';
import * as helpIds from '#packages/helpIds';
import dataProvider from './dataProvider';
import * as sitePrefsService from './sitePrefsService';
import constants from '#packages/constants';
import * as wixBookingsBi from './bi/wixBookingsBi';
import * as stateManagement from '#packages/stateManagement';
import * as platform from '#packages/platform';
import type { EditorAPI } from '#packages/editorAPI';
import { sections } from '#packages/util';

import { BOOKINGS_FIRST_TIME_TOUR_SITE_PREF } from './userPrefsService';
import type { OnDropCallback } from '#packages/addPanelInfra';
import type { Rect, CompRef } from 'types/documentServices';
import type { PlatformOrigin } from '#packages/platform';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

function createEditorActionService({ editorAPI }: { editorAPI: EditorAPI }) {
  const {
    WIX_BOOKINGS_APP_DEF_ID,
    superAppId,
    superAppPanelName,
    superAppButtonLabel,
    superAppPanelClassName,
    superAppSymbolName,
  } = dataProvider;

  const bookingsBiEvents = wixBookingsBi.events;
  const isWixBookingsInstalled = dataProvider.isWixBookingsInstalled(editorAPI);

  function refreshAppComponents() {
    tpa.superApps.refreshAppCompsForAppDefId(WIX_BOOKINGS_APP_DEF_ID);
  }

  function navigateToBookingsPage(obj: AnyFixMe) {
    if (obj.page) {
      editorAPI.pages.navigateTo(obj.page.id, function () {
        const sectionPointer = tpa.superApps.getSectionRefByPageId(obj.page.id);
        editorAPI.selection.selectComponentByCompRef(sectionPointer);
      });
    }
  }

  async function preInstallApp(): Promise<void> {
    return new Promise((resolve) => {
      addAppToSite(
        () => resolve(),
        {
          platformOrigin: {
            type: EditorType.Classic,
            initiator: InstallInitiator.Editor,
            info: {
              type: InstallationOriginType.INTRO_FUNNEL,
            },
          },
        },
        { isPreInstallation: true },
      );
    });
  }

  function registerPreInstallationToWelcomeScreen(
    installationPromise: Promise<void>,
  ) {
    editorAPI.registerPrerequisiteForWelcomeScreen(installationPromise);
  }

  function setBookingsPreInstallationIntent() {
    editorAPI.store.dispatch(
      stateManagement.introFunnel.actions.addIntroFunnelIntent(
        stateManagement.introFunnel.IntroFunnelIntents.BOOKINGS,
      ),
    );
    setFirstTimeTour();
  }

  function addAppToSite(
    doneCallback: AnyFixMe,
    {
      // @ts-expect-error
      platformOrigin,
      biOrigin = tpa.constants.BI.type.ADD_APP_APP_BUTTON,
    } = {},
    { isPreInstallation = false } = {},
  ) {
    const superAppsUtils = tpa.superApps;
    const callback = (obj: AnyFixMe) => {
      if (!isPreInstallation) {
        navigateToBookingsPage(obj);
      }
      if (doneCallback) {
        doneCallback();
      }
    };
    return superAppsUtils.addApp(WIX_BOOKINGS_APP_DEF_ID, {
      callback,
      biOrigin: isPreInstallation
        ? tpa.constants.BI.type.ADD_APP_PREINSTALLATION
        : biOrigin,
      platformOrigin,
      isPreInstallation,
    });
  }

  function onWidgetClick(
    event: AnyFixMe,
    sectionTitle: AnyFixMe,
    tags: AnyFixMe,
    presetId: AnyFixMe,
  ) {
    return addWidgetToSite({
      appId: WIX_BOOKINGS_APP_DEF_ID,
      widgetId: event.data.widgetId,
      layout: undefined,
      biParams: {
        addingMethod: 'click',
        category: 'bookings',
        section: sectionTitle,
        presetId,
      },
    });
  }

  const onWidgetDrop: OnDropCallback = async (
    position,
    widgetItem,
    dropContainer,
  ) => {
    const { width, height } = widgetItem.structure.layout;

    return addWidgetToSite({
      appId: WIX_BOOKINGS_APP_DEF_ID,
      widgetId: widgetItem.structure.data.widgetId,
      layout: _.merge(position, {
        width,
        height,
      }),
      containerRef: dropContainer,
      biParams: {
        addingMethod: 'drag',
        category: widgetItem.categoryId,
        presetId: widgetItem.itemId,
        section: widgetItem.sectionTitle,
      },
    });
  };
  function onRefWidgetClick(
    event: AnyFixMe,
    sectionTitle: string,
    tags: string,
    itemId: string,
    _editorAPI: EditorAPI,
    options: {
      addComponentOptions: {
        category: string;
      };
    },
  ) {
    const { addComponentOptions } = options;

    const pasteContainer =
      (sections.isSectionsEnabled() &&
        sections.getSectionLikeContainerToPaste(editorAPI, [])) ||
      editorAPI.pages.getFocusedPage();

    const addPosition =
      editorAPI.pasteLogic.addPanelPasteLogic.getPastePosition(
        editorAPI,
        event.layout,
        pasteContainer.id,
      );
    const layout = _.defaults(addPosition, event.layout);

    editorAPI.panelManager.closePanelByName(
      constants.SUPER_APPS.BOOKINGS_PANEL_NAME,
    );

    return tpa.superApps.addAppBuilderWidgetToSite(
      dataProvider.WIX_BOOKINGS_APP_DEF_ID,
      event.data,
      layout,
      event.overriddenData,
      {
        biOptions: {
          addingMethod: 'click',
          category: addComponentOptions?.category,
          presetId: itemId,
          section: sectionTitle,
        },
        containerRef: pasteContainer,
      },
    );
  }

  const onRefWidgetDrop: OnDropCallback = (
    compPosRelativeToContainer,
    event,
    containerRef,
  ) => {
    const { sectionTitle, itemId, categoryId } = event;

    const layoutRect: Rect = {
      ...compPosRelativeToContainer,
      width: event.structure.layout.width,
      height: event.structure.layout.height,
    };

    editorAPI.panelManager.closePanelByName(
      constants.SUPER_APPS.BOOKINGS_PANEL_NAME,
    );

    return tpa.superApps.addAppBuilderWidgetToSite(
      dataProvider.WIX_BOOKINGS_APP_DEF_ID,
      event.structure.data,
      layoutRect,
      event.structure.overriddenData,
      {
        biOptions: {
          addingMethod: 'drag',
          category: categoryId,
          presetId: itemId,
          section: sectionTitle,
        },
        containerRef,
      },
    );
  };

  function addWidgetToSite({
    appId,
    widgetId,
    layout,
    containerRef,
    biParams,
  }: {
    appId: string;
    widgetId: string;
    layout: Rect;
    containerRef?: CompRef;
    biParams: tpa.superApps.AddWidgetBIParams;
  }) {
    editorAPI.panelManager.closePanelByName(
      constants.SUPER_APPS.BOOKINGS_PANEL_NAME,
    );

    return tpa.superApps.addWidget(appId, widgetId, layout, {
      ...biParams,
      containerRef,
      biOrigin: tpa.constants.BI.type.ADD_WIDGET,
      platformOrigin: {
        type: EditorType.Classic,
        initiator: InstallInitiator.Editor,
        info: {
          type: InstallationOriginType.AppPanel,
        },
      },
    });
  }

  function openBookingsAppManager() {
    const leavePanelsOpened = true;

    editorAPI.store.dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        constants.ROOT_COMPS.LEFTBAR.APP_MANAGER_PANEL_NAME,
        { selectedAppId: WIX_BOOKINGS_APP_DEF_ID },
        leavePanelsOpened,
      ),
    );
  }

  async function onAppInstalled(options: any = {}) {
    const { origin: platformOrigin }: { origin: PlatformOrigin } = options;
    const shouldDisableOpenAppPanel =
      platform.utils.shouldDisableOpenAppPanelOnInstall(
        editorAPI,
        platformOrigin,
      );

    await tpa.superApps.waitForAppCompleteInstallation(
      editorAPI,
      WIX_BOOKINGS_APP_DEF_ID,
    );

    if (!shouldDisableOpenAppPanel) {
      openBookingsAppManager();
    }
  }

  function registerButton() {
    const can6thButtonBeRegistered = superAppMenuInfra.button.canBeRegistered(
      editorAPI,
      {
        appDefId: WIX_BOOKINGS_APP_DEF_ID,
      },
    );

    if (can6thButtonBeRegistered) {
      superAppMenuInfra.button.registerButton(
        editorAPI,
        superAppId,
        isWixBookingsInstalled,
        superAppPanelName,
        superAppButtonLabel,
        superAppButtonLabel,
        superAppPanelClassName,
        superAppSymbolName,
        false,
      );
    }
  }

  function register6thButton() {
    const shouldShowButton = sitePrefsService.shouldShow6thButton(editorAPI);
    if (shouldShowButton || isWixBookingsInstalled) {
      return registerButton();
    }
  }

  async function openDashboard(
    state = '',
    buttonName = 'manage',
    wasOpenedManually = false,
  ): Promise<void> {
    return new Promise((resolve) => {
      const appOptions = {
        to: 'dd',
        origin: 'editor_6th_button',
        state,
        onClose: () => {
          refreshAppComponents();
          resolve();
        },
      };

      const biParams = {
        type: tpa.constants.BI.type.APP_MANAGE_BUTTON,
      };

      tpa.superApps.openDashboardUrl(
        WIX_BOOKINGS_APP_DEF_ID,
        appOptions,
        biParams,
      );

      if (!wasOpenedManually) {
        wixBookingsBi.report(
          editorAPI,
          bookingsBiEvents.MANAGE_PANEL_BUTTON_CLICK,
          {
            app_id: WIX_BOOKINGS_APP_DEF_ID,
            button_name: buttonName,
            app_name: 'WixBookings',
          },
        );
        wixBookingsBi.report(
          editorAPI,
          bookingsBiEvents.BOOKINGS_INTERNAL_OPEN_BO,
          {
            biToken: dataProvider.getBiToken(editorAPI),
            businessId: dataProvider.getInstanceId(editorAPI),
            button_name: buttonName,
          },
        );
      }
    });
  }

  function goToContainingPage() {
    const data = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(
      WIX_BOOKINGS_APP_DEF_ID,
    );
    if (data) {
      editorAPI.pages.navigateTo(data.pageId);
    }
  }

  function openBackOfficeInEditorFromDeepLink({ state = '', origin = '' }) {
    const appOptions = {
      onClose: refreshAppComponents,
      state,
      origin,
    };
    return tpa.superApps.openDashboardUrl(
      dataProvider.WIX_BOOKINGS_APP_DEF_ID,
      appOptions,
      {},
    );
  }

  function openHelp() {
    const biHelpParams = {
      origin: constants.BI.HELP.ORIGIN.LEFTBAR,
      panel_name: superAppPanelName,
      learn_more: true,
    };
    editorAPI.panelManager.openHelpCenter(
      helpIds.ADD_PANEL.WIX_BOOKINGS,
      null,
      biHelpParams,
    );
  }

  function unregisterButton() {
    superAppMenuInfra.button.unRegisterButton(editorAPI, superAppPanelName);
  }

  function onAppDeleted() {
    unregisterButton();
  }

  function handleUpgrade() {
    editorAPI.bi.event(bookingsBiEvents.UPGRADE_POPUP_CLICK, {
      biToken: dataProvider.getBiToken(editorAPI),
      businessId: dataProvider.getInstanceId(editorAPI),
      referral_info: 'editor_button',
    });
    tpa.superApps.upgrade(WIX_BOOKINGS_APP_DEF_ID, true, 'edhtml_TOP_PANEL');
  }

  function sendBookingsGetStartedBiByPanelName(panelName: AnyFixMe) {
    wixBookingsBi.report(editorAPI, bookingsBiEvents.BOOKINGS_GET_STARTED, {
      is_bookings_provisioned: dataProvider.isAppProvisioned(editorAPI),
      panel_name: panelName,
    });
  }

  function sendBookingsStartedFromAddPanelBI() {
    sendBookingsGetStartedBiByPanelName('Bookings_Add_Panel');
  }

  function openPagesPanel(origin?: string) {
    const { pageId } = editorAPI.dsRead.tpa.app.getFirstAppCompPageId(
      WIX_BOOKINGS_APP_DEF_ID,
    );
    if (pageId) {
      editorAPI.navigateAndOpenPagesPanel(pageId, { origin });
    } else {
      editorAPI.openPagesPanel({ origin });
    }
  }

  function setFirstTimeTour() {
    editorAPI.store.dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        BOOKINGS_FIRST_TIME_TOUR_SITE_PREF,
        'true',
      ),
    );
  }

  return {
    addAppToSite,
    preInstallApp,
    registerPreInstallationToWelcomeScreen,
    setBookingsPreInstallationIntent,
    onWidgetDrop,
    onWidgetClick,
    onRefWidgetDrop,
    onRefWidgetClick,
    onAppInstalled,
    openDashboard,
    goToContainingPage,
    register6thButton,
    openHelp,
    onAppDeleted,
    handleUpgrade,
    sendBookingsStartedFromAddPanelBI,
    openPagesPanel,
    openBackOfficeInEditorFromDeepLink,
  };
}

export default createEditorActionService;
export type BookingsEditorActionService = ReturnType<
  typeof createEditorActionService
>;

import addPanelDataConsts from '#packages/addPanelDataConsts';
import savedComponents from './savedComponents';

const savedComponentsSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.GENERAL,
  hide: false,
  title: 'add_section_label_MyDesigns',
  automationId: 'add-panel-section-savedComponentsSection',
  subNavigationHide: false,
  showSectionHeader: true,
  props: {
    template: savedComponents,
  },
  help: {
    hide: false,
    text: 'add_section_info_text_my_general',
  },
};

export default {
  getCategorySections() {
    return {
      savedComponentsSection,
    };
  },
};

// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';
import section1 from './live/section1';
import section2 from './live/section2';
import getStripContainerContactSection from './columnsContactSection/section';

export default {
  getCategorySections(editorAPI) {
    return Object.assign({}, addPanel.sections.getSections().strip, {
      section_1: section1,
      section_2: section2,
      stripContainerContactSection: getStripContainerContactSection(editorAPI),
    });
  },
};

// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as textControls from '#packages/textControls';
import * as compPanelInfra from '#packages/compPanelInfra';
import * as panelUtils from '#packages/panelUtils';
import * as higherOrderComponents from '#packages/higherOrderComponents';
import React from 'react';
import { Composites, Divider, ToggleSwitch, TextLabel } from '@wix/wix-base-ui';
import * as baseUI from '#packages/baseUI';

const compTypeToTemplate = {
  'tpa.viewer.components.Accordion'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Accordion_Background_Color&Opacity"
          valueLink={this.props.linkStyleParam('color3', 'BG_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Accordion_Border" />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Accordion_Border_Color&Opacity"
          valueLink={this.props.linkStyleParam('color4', 'BORDER_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <Composites.SliderLabeled>
          <TextLabel value="CustomDesign_TPA_Accordion_Border_Width" />

          <baseUI.WixBaseUISliderWithBI
            value={this.linkedProperty('borderWidth').value}
            onChange={this.linkedProperty('borderWidth').requestChange}
            max={15}
            inputMax={15}
            label="CustomDesign_TPA_Accordion_Border_Width"
          />
        </Composites.SliderLabeled>

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Accordion_Text_Section" />

        <Divider long={true} />

        <textControls.fontFamily
          valueLink={this.linkTPAGalleryFont()}
          label="CustomDesign_TPA_Accordion_Font"
          fonts={this.getTPAGalleryFonts()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Accordion_Title_Color&Opacity"
          valueLink={this.props.linkStyleParam('color1', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />
      </div>
    );
  },
  'tpa.viewer.components.Collage'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Collage_Rollover_Color&Opacity"
          valueLink={this.props.linkStyleParam('color3', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Collage_Text_Section" />

        <Divider long={true} />

        <textControls.fontFamily
          valueLink={this.linkTPAGalleryFont()}
          label="CustomDesign_TPA_Collage_Font"
          fonts={this.getTPAGalleryFonts()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Collage_Title_Color&Opacity"
          valueLink={this.props.linkStyleParam('color1', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Collage_Description_Color&Opacity"
          valueLink={this.props.linkStyleParam('color2', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />
      </div>
    );
  },
  'tpa.viewer.components.Freestyle'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Freestyle_Border_Color&Opacity"
          valueLink={this.props.linkStyleParam('color1', 'BORDER_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />
        {
          <Composites.SliderLabeled>
            <TextLabel value="CustomDesign_TPA_Freestyle_Border_Width" />

            <baseUI.WixBaseUISliderWithBI
              value={this.linkedProperty('borderWidth').value}
              onChange={this.linkedProperty('borderWidth').requestChange}
              max={15}
              inputMax={15}
              label="CustomDesign_TPA_Freestyle_Border_Width"
            />
          </Composites.SliderLabeled>
        }
      </div>
    );
  },
  'tpa.viewer.components.Impress'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Pagination_Color&Opacity"
          valueLink={this.props.linkStyleParam('color8', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Impress_SlidesColor&Opacity_Section" />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Slide1_Color&Opacity"
          valueLink={this.props.linkStyleParam('color1', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Slide2_Color&Opacity"
          valueLink={this.props.linkStyleParam('color2', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Slide3_Color&Opacity"
          valueLink={this.props.linkStyleParam('color3', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Slide4_Color&Opacity"
          valueLink={this.props.linkStyleParam('color4', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Slide5_Color&Opacity"
          valueLink={this.props.linkStyleParam('color5', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Impress_Text_Section" />

        <Divider long={true} />

        <textControls.fontFamily
          valueLink={this.linkTPAGalleryFont()}
          label="CustomDesign_TPA_Impress_Font"
          fonts={this.getTPAGalleryFonts()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Title_Color&Opacity"
          valueLink={this.props.linkStyleParam('color6', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Impress_Description_Color&Opacity"
          valueLink={this.props.linkStyleParam('color7', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />
      </div>
    );
  },
  'tpa.viewer.components.Masonry'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Masonry_Background_Section" />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Masonry_Background_Color&Opacity"
          valueLink={this.props.linkStyleParam('color3', 'BG_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Masonry_Rollover_Color&Opacity"
          valueLink={this.props.linkStyleParam('color4', 'BG_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Masonry_Text_Section" />

        <Divider long={true} />

        <textControls.fontFamily
          valueLink={this.linkTPAGalleryFont()}
          label="CustomDesign_TPA_Masonry_Font"
          fonts={this.getTPAGalleryFonts()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Masonry_Title_Color&Opacity"
          valueLink={this.props.linkStyleParam('color1', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInput
          label="CustomDesign_TPA_Masonry_Description_Color"
          valueLink={this.props.linkStyleParam('color2', 'COLOR')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Masonry_Shadow_Section" />

        <Divider long={true} />

        <Composites.ToggleSwitch>
          <ToggleSwitch
            value={this.linkedProperty('boxShadowIsOn').value}
            label="CustomDesign_Shadow_EnableToggle"
            onChange={this.linkedProperty('boxShadowIsOn').requestChange}
          />
        </Composites.ToggleSwitch>

        <Divider />

        <panelUtils.shadowControl
          disabled={
            !this.getEditorAPI().components.properties.get(
              this.props.selectedComponent,
            ).boxShadowIsOn
          }
          valueLink={this.linkedProperty('boxShadow')}
        />
      </div>
    );
  },
  'tpa.viewer.components.Thumbnails'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Thumbnails_Background_Color&Opacity"
          valueLink={this.props.linkStyleParam('color3', 'BG_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_Thumbnails_Text_Section" />

        <Divider long={true} />

        <textControls.fontFamily
          valueLink={this.linkTPAGalleryFont()}
          label="CustomDesign_TPA_Thumbnails_Font"
          fonts={this.getTPAGalleryFonts()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Thumbnails_Title_Color&Opacity"
          valueLink={this.props.linkStyleParam('color1', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Thumbnails_Description_Color&Opacity"
          valueLink={this.props.linkStyleParam('color2', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />
      </div>
    );
  },
  'tpa.viewer.components.StripSlideshow'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_StripSlideshow_Background_Color&Opacity"
          valueLink={this.props.linkStyleParam('color3', 'BG_COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.sectionDividerLabeled label="CustomDesign_TPA_StripSlideshow_Text_Section" />

        <Divider long={true} />

        <textControls.fontFamily
          valueLink={this.linkTPAGalleryFont()}
          label="CustomDesign_TPA_StripSlideshow_Font"
          fonts={this.getTPAGalleryFonts()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_StripSlideshow_Title_Color&Opacity"
          valueLink={this.props.linkStyleParam('color1', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_StripSlideshow_Description_Color&Opacity"
          valueLink={this.props.linkStyleParam('color2', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />
      </div>
    );
  },
  'tpa.viewer.components.Honeycomb'() {
    return (
      <div className="advanced-style-panel tpa-gallery-advanced-style-panel">
        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Honeycomb_Border_Color&Opacity"
          valueLink={this.props.linkStyleParam('color5', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />

        <Divider long={true} />

        <baseUI.colorPickerInputWithOpacity
          label="CustomDesign_TPA_Honeycomb_Rollover_Color&Opacity"
          valueLink={this.props.linkStyleParam('color4', 'COLOR_ALPHA')}
          {...this.getColorPickerProps()}
        />
      </div>
    );
  },
};

const tpaGalleryFontsMap = {
  arial: 'arial',
  'ariel black': 'arielBlack',
  'comic sans ms': 'comicSansMS',
  'courier new': 'courierNew',
  georgia: 'georgia',
  impact: 'impact',
  'lucida console': 'lucidaConsole',
  'lucida sans console': 'lucidaSansConsole',
  'palatino linotype': 'palatinoLinotype',
  tahoma: 'tahoma',
  'times new roman': 'timesNewRoman',
  verdana: 'verdana',
};

function getDisplayedFontByValue(fontValue) {
  return _.invert(tpaGalleryFontsMap)[fontValue];
}

// eslint-disable-next-line react/prefer-es6-class
const TpaGalleryAdvancedStylePanel = createReactClass({
  displayName: 'tpaGalleryAdvancedStylePanel',
  mixins: [compPanelInfra.compPanelMixin, panelUtils.linkColorPickerMixin],
  getColorPickerProps() {
    return {
      colorResolver: this.resolveColor,
      openColorPicker: this.openColorPicker,
    };
  },
  getTPAGalleryFonts() {
    const allSelectableFonts =
      textControls.fontUtils.getCurrentSelectableFontsWithParams(
        this.getEditorAPI(),
      );

    return _.chain(allSelectableFonts)
      .find({ lang: 'latin' })
      .thru(function (fontsObj) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/filter
        fontsObj.fonts = _.filter(fontsObj.fonts, function (font) {
          return Boolean(tpaGalleryFontsMap[font.fontFamily]);
        });
        return [fontsObj];
      })
      .value();
  },
  linkTPAGalleryFont() {
    return {
      value: getDisplayedFontByValue(
        this.getEditorAPI().components.properties.get(
          this.props.selectedComponent,
        ).font,
      ),
      requestChange: function (newVal) {
        this.getEditorAPI().components.properties.update(
          this.props.selectedComponent,
          { font: tpaGalleryFontsMap[newVal] },
        );
      }.bind(this),
    };
  },
  render() {
    return compTypeToTemplate[this.props.compType].apply(this);
  },
});

export default higherOrderComponents.linkStyle(TpaGalleryAdvancedStylePanel);

import { mouseActions, siteCreation } from '#packages/stateManagement';
import { getPageSectionsSortedByStageOrder } from '../../api';
import { getLastSectionMinHeightBySOAPComponents } from '../constraints/sectionLayout/getSectionMinHeightBySOAPComponents';

import type { Scope } from '#packages/sections';
import type { ViewerChangesData } from 'types/core';

export const enlargeLastSectionIfNeeded = (scope: Scope) => {
  const { editorAPI, components } = scope;

  editorAPI.waitForChangesApplied(() => {
    if (editorAPI.isMobileEditor()) return;

    const pageRef = editorAPI.pages.getCurrentPage();
    const pageSections = getPageSectionsSortedByStageOrder(scope, pageRef);
    const [lastSectionRef] = pageSections.slice(-1);

    if (!lastSectionRef) {
      return;
    }

    const lastSectionLayout =
      components.layout.getRelativeToScreen(lastSectionRef);
    const lastSectionMainHeight = getLastSectionMinHeightBySOAPComponents(
      editorAPI,
      lastSectionRef,
      {
        layoutInitial: lastSectionLayout,
      },
    );

    if (lastSectionLayout.height < lastSectionMainHeight) {
      components.layout.resizeTo(
        lastSectionRef,
        {
          height: lastSectionMainHeight,
        },
        { dontAddToUndoRedoStack: true },
      );
    }
  });
};

export const createEnlargeLastSectionIfNeeded = (scope: Scope) => {
  const { editorAPI } = scope;
  enlargeLastSectionIfNeeded(scope);

  return (_changes: ViewerChangesData) => {
    const editorState = editorAPI.store.getState();
    const shouldBlockBySiteCreation =
      siteCreation.selectors.getIsSessionInitializedWithWizard(editorState) &&
      !siteCreation.selectors.isSiteCreationFullyDone(editorState);
    if (mouseActions.selectors.isPerformingMouseMoveAction(editorState)) return;
    if (shouldBlockBySiteCreation) return;
    if (editorAPI.zoomMode.isStageZoomMode()) return;
    if (editorAPI.zoomMode.isZoomModeTransitionActive()) return;

    enlargeLastSectionIfNeeded(scope);
  };
};

import type React from 'react';
import _ from 'lodash';
import { DATA_BINDING } from '@wix/app-definition-ids';

import type { StateMapperArgs, ThunkAction } from 'types/redux';
import type { EditorAPI } from '#packages/editorAPI';
import {
  panels,
  components,
  bi,
  userPreferences,
} from '#packages/stateManagement';
import { appMarketHandlers } from '#packages/tpa';
import constants, {
  LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP,
  LEGACY_WIX_APPS_NAMES,
} from '#packages/constants';
import { fedopsLogger, hoc } from '#packages/util';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { translate } from '#packages/i18n';

import type { OwnProps } from './wixAppsDeprecationPanel.types';
import { APP_DEF_IDS, TRANSLATIONS_PER_APP_NAME } from './constants';
import { TRANSLATIONS } from './appDataExport/constants';
import {
  getNewsItems,
  getFAQItems,
  getMenuItems,
} from './appDataExport/legacyAPI';
import { exportNewsToContentManager } from './appDataExport/news/exportNews';
import { updateWixFAQComponentData } from './appDataExport/faq/exportFAQ';
import { exportMenusToContentManager } from './appDataExport/menus/exportMenus';

const getAppPartName = (editorAPI: EditorAPI, ownProps: OwnProps) => {
  const [selectedComponent] = ownProps.selectedComponent;
  const componentData: {
    appPartName?: LEGACY_WIX_APPS_NAMES;
  } = editorAPI.components.data.get(selectedComponent);
  return componentData?.appPartName;
};

const mapStateToProps = (
  { editorAPI, state }: StateMapperArgs,
  ownProps: OwnProps,
) => {
  const appPartName = getAppPartName(editorAPI, ownProps);

  const dataBindingAppData =
    editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);

  const isDataBindingAppInstalled = Boolean(dataBindingAppData);

  return {
    helpId: LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP[appPartName],
    isDataBindingAppInstalled,
    isNewsMigrated: userPreferences.selectors.getSiteUserPreferences(
      constants.USER_PREFS.WIX_APPS_MIGRATION.IS_NEWS_MIGRATED,
    )(state),
    isMenusMigrated: userPreferences.selectors.getSiteUserPreferences(
      constants.USER_PREFS.WIX_APPS_MIGRATION.IS_MENUS_MIGRATED,
    )(state),
    appPartName,
    componentType: appPartName,
    translations: TRANSLATIONS_PER_APP_NAME[appPartName],
  };
};

const getEditorAPI: ThunkAction<EditorAPI> = (
  dispatch,
  getState,
  { editorAPI },
) => editorAPI;

type BICTAClickTarget =
  | 'open-faq'
  | 'install-faq'
  | 'migrate-news'
  | 'migrate-menus';

const mapDispatchToProps = (dispatch: FunctionFixMe, ownProps: OwnProps) => {
  const editorAPI: EditorAPI = dispatch(getEditorAPI);

  const closePanel = () => {
    dispatch(panels.actions.closePanelByName(ownProps.panelName));
  };

  const logCTAClick = (target: BICTAClickTarget) => {
    const [selectedComponent] = ownProps.selectedComponent;

    dispatch(
      bi.actions.event(
        { evid: 1050 },
        {
          target,
          component_id: selectedComponent.id,
        },
      ),
    );
  };

  const addApp = (appDefId: string) => {
    const getTarget = (appDefId: string): BICTAClickTarget => {
      return {
        [APP_DEF_IDS.WIX_FAQ]: 'install-faq',
      }[appDefId] as BICTAClickTarget;
    };

    appMarketHandlers.addApp(editorAPI, { appDefId });

    logCTAClick(getTarget(appDefId));
  };

  const logErrorToSentry = (e: any) => {
    ErrorReporter.captureException(e, {
      tags: {
        wixAppsMigration: true,
      },
      extra: {
        appPartName: getAppPartName(editorAPI, ownProps),
      },
    });
  };

  const copyNewsDataToContentManager = async () => {
    logCTAClick('migrate-news');

    try {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.WIXAPPS.WIXAPPS_MIGRATE_NEWS,
      );
      editorAPI.history.add('migrate-wixapps');
      editorAPI.panelHelpers.openProgressBar({
        title: translate(TRANSLATIONS.PROGRESS_BAR.TITLE),
        totalSteps: 2,
      });
      const newsItems = await getNewsItems(editorAPI);
      await exportNewsToContentManager(editorAPI, newsItems);

      dispatch(
        userPreferences.actions.setSiteUserPreferences(
          constants.USER_PREFS.WIX_APPS_MIGRATION.IS_NEWS_MIGRATED,
          true,
        ),
      );
      await new Promise(editorAPI.saveManager.saveInBackground);

      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.WIXAPPS.WIXAPPS_MIGRATE_NEWS,
      );
    } catch (e) {
      logErrorToSentry(e);
    }

    await editorAPI.panelHelpers.closeProgressBar();
  };

  const installFAQAndExportItems = async () => {
    try {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.WIXAPPS.WIXAPPS_MIGRATE_FAQ,
      );
      editorAPI.history.add('migrate-wixapps');
      const faqItems = await getFAQItems(editorAPI);
      addApp(APP_DEF_IDS.WIX_FAQ);
      closePanel();
      await updateWixFAQComponentData(editorAPI, faqItems);
      await new Promise(editorAPI.saveManager.saveInBackground);
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.WIXAPPS.WIXAPPS_MIGRATE_FAQ,
      );
    } catch (e) {
      logErrorToSentry(e);
    }
  };

  const installRestaurantsMenusAndExportItems = async () => {
    logCTAClick('migrate-menus');

    try {
      fedopsLogger.interactionStarted(
        fedopsLogger.INTERACTIONS.WIXAPPS.WIXAPPS_MIGRATE_MENUS,
      );
      editorAPI.history.add('migrate-wixapps');

      editorAPI.panelHelpers.openProgressBar({
        title: translate('menus_exporting_to_collection_progress_bar_header'),
        totalSteps: 2,
      });
      const menuItems = await getMenuItems(editorAPI);
      closePanel();
      await exportMenusToContentManager(editorAPI, menuItems);

      dispatch(
        userPreferences.actions.setSiteUserPreferences(
          constants.USER_PREFS.WIX_APPS_MIGRATION.IS_MENUS_MIGRATED,
          true,
        ),
      );

      await new Promise(editorAPI.saveManager.saveInBackground);

      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.WIXAPPS.WIXAPPS_MIGRATE_MENUS,
      );
    } catch (e) {
      logErrorToSentry(e);
    }

    await editorAPI.panelHelpers.closeProgressBar();
  };

  const handlerToAppNameMap: Record<LEGACY_WIX_APPS_NAMES, Function> = {
    [LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS]:
      installRestaurantsMenusAndExportItems,
    [LEGACY_WIX_APPS_NAMES.FAQ]: installFAQAndExportItems,
    [LEGACY_WIX_APPS_NAMES.NEWS_A]: copyNewsDataToContentManager,
    [LEGACY_WIX_APPS_NAMES.NEWS_B]: copyNewsDataToContentManager,
  };

  return {
    openHelpCenter: (helpId: string) => {
      dispatch(
        panels.actions.openHelpCenter(helpId, null, {
          learn_more: true,
          component: getAppPartName(editorAPI, ownProps),
        }),
      );
    },

    handleCTAButtonClick: () => {
      const appPartName = getAppPartName(editorAPI, ownProps);
      handlerToAppNameMap[appPartName]();
    },
  };
};

export type ConnectedProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const connectWixAppsSettingsPanel = <TComponentProps>(
  Component: React.ComponentClass<TComponentProps>,
) => {
  const Connected = _.flow(
    hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps, mapDispatchToProps),
    components.hoc.compPanel,
  )(Component);

  Connected.pure = Component;

  return Connected;
};

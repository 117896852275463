import React from 'react';
import { ActionItem, Button, Text, TextButton } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import * as panels from '#packages/panels';
import styles from './pageVariantsPanel.scss';
import type { PageVariant } from '@wix/platform-editor-sdk';
import { platformBiEvents } from '#packages/platform';
import * as platformEvents from 'platformEvents';

import type { MapDispatchToProps, Dispatch } from 'types/redux';
import { hoc } from '#packages/util';

const VARIANTS_ARTICLE_HELP_ID = '70133a53-6e28-4709-a6c3-1b42280b131c';
const PANEL_NAME = 'platformPanels.PageVariantsPanel';

interface PageVariantsPanelDispatchProps {
  openHelpCenter: (helpId: string) => void;
  onClose: () => void;
  onActionItemClick: (
    variant: PageVariant,
    pageManagingAppDefId: string,
  ) => void;
}

interface PageVariantsPanelProps extends PageVariantsPanelDispatchProps {
  panelName: string;
  title: string;
  variants: PageVariant[];
  variantsHelpUrl?: string;
  pageManagingAppDefId: string;
}
export const PageVariantsPanel: React.FC<PageVariantsPanelProps> = ({
  panelName,
  title,
  variants,
  variantsHelpUrl,
  pageManagingAppDefId,
  openHelpCenter,
  onClose,
  onActionItemClick,
}) => {
  return (
    <panels.frames.CustomPanelFrame
      dataHook="page-variant-panel"
      title={title}
      panelName={panelName}
      className={styles.pageVariantsPanel}
      onEscKeyPress={onClose}
      onHelpButtonClick={() => {
        openHelpCenter(VARIANTS_ARTICLE_HELP_ID);
      }}
      onCloseButtonClick={onClose}
      footnote={
        <Text size="small" weight="thin" shouldTranslate={false}>
          {translate('PLATFORM_flow_customization_variant_preset_modal_note')}
        </Text>
      }
    >
      <>
        <div className={styles.subtitle}>
          <Text
            size="medium"
            weight="thin"
            enableEllipsis={false}
            shouldTranslate={false}
          >
            {translate(
              'PLATFORM_flow_customization_variant_preset_modal_subtitle',
            )}
          </Text>
          {variantsHelpUrl ? (
            <TextButton
              dataHook={'learn-more-button'}
              className={styles.linkButton}
              shouldTranslate={false}
              onClick={() => window.open(variantsHelpUrl, '_blank')}
            >
              {translate(
                'PLATFORM_flow_customization_variant_preset_modal_subtitle_learn_more',
              )}
            </TextButton>
          ) : null}
        </div>
        {variants.map((variant) => (
          <ActionItem
            dataHook={'variant-item'}
            title={variant.name}
            description={variant.description}
            size="large"
            onClick={() => onActionItemClick(variant, pageManagingAppDefId)}
            action={
              <Button className="btn-inverted btn-sm">
                {translate(
                  'PLATFORM_flow_customization_variant_preset_modal_main_cta',
                )}
              </Button>
            }
            illustration={
              <img className={styles.illustration} src={variant.src} />
            }
            shouldTranslate={false}
          />
        ))}
      </>
    </panels.frames.CustomPanelFrame>
  );
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
) => editorAPI;

const mapDispatchToProps: MapDispatchToProps<
  PageVariantsPanelDispatchProps,
  PageVariantsPanelProps
> = (dispatch: Dispatch) => {
  const editorAPI = dispatch(getEditorAPI);
  return {
    openHelpCenter: (helpId: string) => {
      dispatch(stateManagement.panels.actions.openHelpCenter(helpId));
    },
    onClose: () => editorAPI.panelManager.closePanelByName(PANEL_NAME),
    onActionItemClick: (variant: PageVariant, pageManagingAppDefId: string) => {
      editorAPI.bi.event(platformBiEvents.addAppVariantPage, {
        target: variant.name,
        page_id: variant.id,
        page_name: variant.name,
        app_id: pageManagingAppDefId,
      });
      const appId =
        editorAPI.platform.getAppDataByAppDefId(
          pageManagingAppDefId,
        ).applicationId;
      editorAPI.platform.notifyApplication(
        appId,
        platformEvents.factory.pageVariantSelected({
          variantId: variant.id,
        }),
      );
      editorAPI.panelManager.closePanelByName(PANEL_NAME);
    },
  };
};

export default hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(PageVariantsPanel);

import type { EditorAPI } from '#packages/editorAPI';
import type { AddPanelDataSection } from './types';
import { addPanelUtils, dataSectionUtils } from '#packages/addPanelInfra';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import { EVENTS } from '#packages/wixData';
import constants from '#packages/constants';

export const createDataSectionAPI = (editorAPI: EditorAPI) => {
  const notifyDataBindingApplication = (eventType: string) => {
    dataSectionUtils.notifyDataBindingApplication(editorAPI, {
      eventType,
      eventPayload: {
        origin: constants.BI.HELP.ORIGIN.EDITOR_SEARCH,
      },
    });
  };

  return {
    async add(section: AddPanelDataSection) {
      const [firstItem] = section.props.items;

      switch (firstItem.action) {
        case addPanelDataConsts.CLICK_ACTION.DATA.ADD_DATASET:
          return addPanelUtils.attemptToAddComponent(
            editorAPI,
            firstItem.structure,
            '',
            section.title,
          );

        case addPanelDataConsts.CLICK_ACTION.DATA.ADD_COLLECTION:
          notifyDataBindingApplication(EVENTS.createCollectionButtonClicked);
          break;

        case addPanelDataConsts.CLICK_ACTION.DATA.ADD_PRESET:
          notifyDataBindingApplication(EVENTS.openAddPreset);
          break;

        case addPanelDataConsts.CLICK_ACTION.DATA.INSTALL_APP_AND_NOTIFY:
          await editorAPI.tpa.installAppIfNeeded(firstItem.appDefinitionId);
          dataSectionUtils.notifyApplication(
            editorAPI,
            firstItem.appDefinitionId,
            {
              eventType: addPanelDataConsts.CLICK_ACTION.EVENT.APP_CLICKED,
              eventPayload: firstItem.params,
            },
          );
          break;

        default:
          break;
      }
    },
  };
};

import { EditorAPIKey } from '#packages/apis';

import { A11yApiKey } from './publicApiKey';
import { createA11yApi } from './a11yApi';

import type { EntryPoint } from '#packages/apilib';

export const A11yApiEntryPoint: EntryPoint = {
  name: 'A11yApi',
  declareAPIs: () => [A11yApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(A11yApiKey, () => createA11yApi(shell));
  },
};

import { editorModel } from '#packages/util';
import constants from '#packages/constants';
import {
  getPresetLockedContent,
  setPresetLockedContent,
} from '../contentLock/contentLockUtil';
import { caasPropNames } from './injectConsts';
import { handleTextInjection, isTextInjection } from './injectTextContent';
import { handleMediaInjection, isMediaInjection } from './injectMediaContent';
import {
  isButtonInjection,
  handleButtonInjection,
} from './injectButtonContent';
import { generateContentToInject } from './injectUtils';

import type { SiteGlobalDataApi } from '#packages/siteGlobalData';
import type {
  CompStructure,
  PageComponent,
} from '@wix/document-services-types';
import type {
  ContentInjectionMapOutput,
  RegularContentInjectionValues,
  ContentInjectionImage,
  ListContentInjectionValues,
} from '../scan/scanTypes';
import type { Scope } from '../contentInjectionEntryPoint';
import type {
  ContentItem,
  ListContentItem,
  ContentManager,
} from '#packages/presetApi';

export const handleInjectPresetContent = (
  scope: Scope,
  presetStructure: CompStructure,
  contentInjectionMap: ContentInjectionMapOutput[],
  presetId: string,
): CompStructure => {
  const contentManager = scope.store.getContentManager();
  if (contentInjectionMap.length === 0 || !contentManager) {
    return presetStructure;
  }
  const presetStructureString = JSON.stringify(presetStructure);
  const updatedPresetStructureString = handleContentInjectionByMap(
    scope,
    presetStructureString,
    contentInjectionMap,
    contentManager,
    presetId,
  );
  const updatedPresetStructure = JSON.parse(updatedPresetStructureString);
  const presetStructureConsideringMobileComponents =
    handleStructureConsideringMobileComponents(
      scope,
      updatedPresetStructure,
      contentInjectionMap,
      presetId,
    );

  return presetStructureConsideringMobileComponents;
};

function handleStructureConsideringMobileComponents(
  scope: Scope,
  presetStructure: CompStructure,
  contentInjectionMap: ContentInjectionMapOutput[],
  presetId: string,
) {
  const { mobileComponents } = presetStructure as PageComponent;
  if (mobileComponents?.length) {
    return handleInjectionWithMobileContent(
      scope,
      presetStructure,
      contentInjectionMap,
      presetId,
    );
  }
  return presetStructure;
}

function handleInjectionWithMobileContent(
  scope: Scope,
  presetStructure: CompStructure,
  contentInjectionMap: ContentInjectionMapOutput[],
  presetId: string,
): CompStructure {
  if (contentInjectionMap.length === 0) {
    return presetStructure;
  }
  const contentManager = scope.store.getContentManager();
  const presetStructureString = JSON.stringify(presetStructure);
  const updatedPresetStructureString = handleContentInjectionByMap(
    scope,
    presetStructureString,
    contentInjectionMap,
    contentManager,
    presetId,
  );
  return JSON.parse(updatedPresetStructureString);
}

function isPresetPage(structureString: string) {
  return (
    JSON.parse(structureString).componentType === constants.COMP_TYPES.PAGE
  );
}

function injectPresetContentByMap(
  isList: boolean,
  presetStructureString: string,
  presetInjectableMap: any,
  contentToInject: any,
) {
  return isList
    ? injectListItemContent(
        presetStructureString,
        presetInjectableMap as ListContentInjectionValues,
        contentToInject as ListContentItem,
      )
    : injectItemContent(
        presetStructureString,
        presetInjectableMap as RegularContentInjectionValues,
        contentToInject as ContentItem,
      );
}

function getContentItemToInject(
  siteGlobalDataAPI: SiteGlobalDataApi,
  contentManager: ContentManager,
  contentInjectionSection: ContentInjectionMapOutput,
  presetId: string,
  isPage: boolean,
) {
  const { ceType, injectable, isList } = contentInjectionSection;

  let contentItem = getPresetLockedContent(
    siteGlobalDataAPI,
    editorModel.metaSiteId,
    presetId,
    isPage,
    ceType,
  );
  if (!contentItem) {
    contentItem = generateContentToInject(
      contentManager,
      ceType,
      isList,
      injectable,
    );
    setPresetLockedContent(
      siteGlobalDataAPI,
      editorModel.metaSiteId,
      presetId,
      contentItem,
      isPage,
      ceType,
    );
  }
  return contentItem;
}

function handleContentInjectionByMap(
  scope: Scope,
  presetStructureString: string,
  contentInjectionMap: ContentInjectionMapOutput[],
  contentManager: ContentManager,
  presetId: string,
): string {
  const isPage = isPresetPage(presetStructureString);
  contentInjectionMap.forEach(
    (contentInjectionSection: ContentInjectionMapOutput) => {
      const { injectable, isList } = contentInjectionSection;

      const contentToInject = getContentItemToInject(
        scope.siteGlobalDataAPI,
        contentManager,
        contentInjectionSection,
        presetId,
        isPage,
      );

      presetStructureString = injectPresetContentByMap(
        isList,
        presetStructureString,
        injectable,
        contentToInject,
      );
    },
  );
  return presetStructureString;
}

function injectItemContent(
  presetStructureString: string,
  presetInjectableMap: RegularContentInjectionValues,
  contentToInject: ContentItem,
): string {
  if (!contentToInject) {
    return presetStructureString;
  }
  for (const injectableMapPropName in presetInjectableMap) {
    if (presetInjectableMap.hasOwnProperty(injectableMapPropName)) {
      const injectableValue = extractInjectableValueFromObject(
        presetInjectableMap,
        injectableMapPropName,
      );
      const valueToInject = extractInjectableValueFromObject(
        contentToInject,
        injectableMapPropName,
      );

      if (isTextInjection(injectableMapPropName)) {
        presetStructureString = handleTextInjection(
          presetStructureString,
          injectableValue as string,
          valueToInject as string,
        );
      }

      if (isMediaInjection(injectableMapPropName)) {
        presetStructureString = handleMediaInjection(
          presetStructureString,
          injectableValue as ContentInjectionImage[],
          valueToInject as ContentItem['media'],
        );
      }

      if (isButtonInjection(injectableMapPropName)) {
        presetStructureString = handleButtonInjection(
          presetStructureString,
          injectableValue as string,
          valueToInject as string,
        );
      }
    }
  }
  return presetStructureString;
}

function injectListItemContent(
  presetStructureString: string,
  listInjectableMap: ListContentInjectionValues,
  contentToInject: ListContentItem,
): string {
  for (const listInjectableMapPropName in listInjectableMap) {
    if (listInjectableMap.hasOwnProperty(listInjectableMapPropName)) {
      const injectableValue = extractListInjectableValueFromObject(
        listInjectableMap,
        listInjectableMapPropName,
      );

      if (isHeaderOrFooterInjection(listInjectableMapPropName)) {
        presetStructureString = injectItemContent(
          presetStructureString,
          injectableValue as RegularContentInjectionValues,
          contentToInject[
            listInjectableMapPropName as keyof ListContentItem
          ] as ContentItem,
        );
      }

      if (isListInjection(listInjectableMapPropName)) {
        presetStructureString = handleInjectListContent(
          presetStructureString,
          injectableValue as RegularContentInjectionValues[],
          contentToInject.items,
        );
      }
    }
  }
  return presetStructureString;
}

function extractInjectableValueFromObject(
  injectable: RegularContentInjectionValues | ContentItem,
  injectablePropName: string,
): string | ContentInjectionImage {
  return injectable[
    injectablePropName as keyof RegularContentInjectionValues
  ] as string | ContentInjectionImage;
}

function extractListInjectableValueFromObject(
  injectable: ListContentInjectionValues,
  injectablePropName: string,
): RegularContentInjectionValues | RegularContentInjectionValues[] {
  return injectable[injectablePropName as keyof ListContentInjectionValues] as
    | RegularContentInjectionValues
    | RegularContentInjectionValues[];
}

function isHeaderOrFooterInjection(propName: string): boolean {
  const { HEADER, FOOTER } = caasPropNames;
  return [HEADER, FOOTER].some((caasPropName) => caasPropName === propName);
}

function isListInjection(propName: string): boolean {
  const { LIST } = caasPropNames;
  return propName === LIST;
}

function handleInjectListContent(
  structureString: string,
  injectableListItems: RegularContentInjectionValues[],
  contentToInject: ContentItem[],
) {
  injectableListItems.forEach(
    (injectableListItem: RegularContentInjectionValues, idx: number) => {
      structureString = injectItemContent(
        structureString,
        injectableListItem,
        contentToInject[idx],
      );
    },
  );
  return structureString;
}

import { createReduxStore } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';
import type {
  PanelOpenConfig,
  ContributedPanelData,
} from '#packages/workspaceRightPanel';
import type { QuickEditControlActions } from '@wix/editor-elements-types/quickEditControls';

export interface QuickEditStore {}

export interface QuickEditInnerPanelBiData {
  openTime: number;
  panel_name: string;
  component_id: string;
  component_type: string;
}

interface QuickEditBiData {
  section_correlation_id: string;
  panel_correlation_id: string;
  parent_component_id: string;
  panelOpenTime: number;
  panelOpenOrigin: string;
  component_id: string;
  component_type: string;
}

interface DrillInPanelData {
  panelData: ContributedPanelData;
  compRef?: CompRef;
}

export interface QuickEditState {
  isQuickEditPanelOpen: boolean;
  isQuickEditLoading: boolean;
  isNavigationInProcess: boolean;
  currentEditedCompId: string;
  updatePanelConfigFn: (config: Partial<PanelOpenConfig>) => void;
  componentPanelType: QuickEditControlActions;
  innerPanelBiData: QuickEditInnerPanelBiData;
  biData: Partial<QuickEditBiData>;
  drillInPanels: Array<DrillInPanelData>;
  drillInPanelsBiData: Array<QuickEditInnerPanelBiData>;
}

const getInitialState = (): QuickEditState => ({
  isQuickEditPanelOpen: false,
  isQuickEditLoading: false,
  currentEditedCompId: '',
  isNavigationInProcess: false,
  componentPanelType: null,
  innerPanelBiData: null,
  biData: null,
  updatePanelConfigFn: () => {},
  drillInPanels: [],
  drillInPanelsBiData: [],
});

export const QuickEditStore = createReduxStore({
  getInitialState,
  selectors: {
    getIsQuickEditPanelOpen: (state: QuickEditState) =>
      state.isQuickEditPanelOpen,
    getCurrentEditedCompId: (state: QuickEditState) =>
      state.currentEditedCompId,
    getComponentPanelType: (state: QuickEditState) => state.componentPanelType,
    getInnerPanelBiData: (state: QuickEditState) => state.innerPanelBiData,
    getQuickEditBiData: (state: QuickEditState) => state.biData,
    getIsNavigationInProcessOnQuickEdit: (state: QuickEditState) =>
      state.isNavigationInProcess,
    getIsQuickEditLoading: (state: QuickEditState) => state.isQuickEditLoading,
    getQuickEditUpdatePanelConfigFn: (state: QuickEditState) =>
      state.updatePanelConfigFn,
    getDrillInPanels: (state: QuickEditState) => state.drillInPanels,
    getLastOpenedDrillInPanelBiData: (state: QuickEditState) => {
      const drillInPanelsBiData = state.drillInPanelsBiData;
      return drillInPanelsBiData[drillInPanelsBiData.length - 1];
    },
  },
  actions: {
    setCurrentEditedCompId: (
      state: QuickEditState,
      currentEditedCompId: string,
    ) => {
      return {
        ...state,
        currentEditedCompId,
      };
    },
    setIsQuickEditPanelOpen: (
      state: QuickEditState,
      isQuickEditPanelOpen: QuickEditState['isQuickEditPanelOpen'],
    ) => {
      return {
        ...state,
        isQuickEditPanelOpen,
      };
    },
    setIsNavigationInProcessOnQuickEdit: (
      state: QuickEditState,
      isNavigationInProcess: boolean,
    ) => {
      return {
        ...state,
        isNavigationInProcess,
      };
    },
    setIsQuickEditLoading: (
      state: QuickEditState,
      isQuickEditLoading: boolean,
    ) => {
      return {
        ...state,
        isQuickEditLoading,
      };
    },
    setComponentPanelType: (
      state: QuickEditState,
      componentPanelType: QuickEditControlActions,
    ) => {
      return {
        ...state,
        componentPanelType,
      };
    },
    setInnerPanelBiData: (
      state: QuickEditState,
      innerPanelBiData: QuickEditInnerPanelBiData,
    ) => {
      return {
        ...state,
        innerPanelBiData,
      };
    },
    setQuickEditBiData: (
      state: QuickEditState,
      biData: QuickEditState['biData'],
    ) => {
      return {
        ...state,
        biData: { ...state.biData, ...biData },
      };
    },
    setQuickEditUpdatePanelConfigFn: (
      state: QuickEditState,
      updatePanelConfigFn: (config: Partial<PanelOpenConfig>) => void,
    ) => {
      return {
        ...state,
        updatePanelConfigFn,
      };
    },
    setDrillInPanels: (
      state: QuickEditState,
      drillInPanels: Array<DrillInPanelData>,
    ) => {
      return {
        ...state,
        drillInPanels,
      };
    },
    addDrillInPanels: (
      state: QuickEditState,
      drillInPanels: Array<DrillInPanelData>,
    ) => {
      return {
        ...state,
        drillInPanels: [...state.drillInPanels, ...drillInPanels],
      };
    },
    removeDrillInPanel: (state: QuickEditState) => {
      return {
        ...state,
        drillInPanels: state.drillInPanels.slice(0, -1),
      };
    },
    updateDrillInPanel: (
      state: QuickEditState,
      uniqueId: string,
      compRef?: CompRef,
    ) => {
      const drillInPanels = state.drillInPanels;
      const drillInIndex = drillInPanels.findIndex(
        (panel) => panel.panelData.panelId === uniqueId,
      );
      drillInPanels[drillInIndex].compRef = compRef;
      return {
        ...state,
        drillInPanels,
      };
    },
    setDrillInPanelBiData: (
      state: QuickEditState,
      biData: QuickEditInnerPanelBiData,
    ) => {
      return {
        ...state,
        drillInPanelsBiData: [...state.drillInPanelsBiData, biData],
      };
    },
    removeLastDrillInBiData: (state: QuickEditState) => {
      const drillInPanelsBiData = state.drillInPanelsBiData;
      drillInPanelsBiData.pop();
      return {
        ...state,
        drillInPanelsBiData,
      };
    },
  },
});

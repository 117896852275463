// @ts-nocheck
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as baseUI from '#packages/baseUI';
import * as core from '#packages/core';
import * as util from '#packages/util';
import linkColorPickerMixin from '../mixins/linkColorPickerMixin';
import React from 'react';
import {
  AngleInput,
  Composites,
  Divider,
  Slider,
  TextLabel,
} from '@wix/wix-base-ui';

const degreesPerRadians = 180 / Math.PI;
const radiansPerDegrees = 1 / degreesPerRadians;

function calculateXYValue(distance, angle) {
  const radAngle = angle * radiansPerDegrees;
  return {
    x: (distance * Math.sin(-radAngle)).toFixed(2),
    y: (distance * Math.cos(-radAngle)).toFixed(2),
  };
}

function calculateAngle(offsetX, offsetY) {
  const radiansAngle = Math.atan2(offsetX, -offsetY);
  return ((180 + radiansAngle * degreesPerRadians) % 360).toFixed(2);
}

function calculateDistance(dx, dy) {
  return parseFloat(Math.sqrt(dx * dx + dy * dy).toFixed(2));
}

//TODO: move these functions to a util
const extractRGBA = /rgba\(([^)]+)\)/;
const isRGBA = /rgba\(/;
function getRGBA(colorString) {
  const rgbaValues = extractRGBA.exec(colorString)[1];
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(rgbaValues.split(','), function (val) {
    return parseFloat(val);
  });
}
function getAlpha(colorValue) {
  if (colorValue.match(isRGBA)) {
    const rgba = getRGBA(colorValue);
    return rgba[3];
  }
  return 1;
}

function createRGBA(colorWithAlpha) {
  const rgba = getRGBA(colorWithAlpha.color);
  rgba[3] = colorWithAlpha.alpha;
  return `rgba(${rgba.join(',')})`;
}

function getSplitValueFromProps(stringToSplit) {
  const value = util.valueLink.getValueFromProps(this.props);
  return value ? value.split(stringToSplit) : [];
}

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'shadowControl',
  mixins: [baseUI.inputMixin, core.mixins.editorAPIMixin, linkColorPickerMixin],
  getDefaultProps() {
    return {
      angle: 0,
      distance: 0,
      size: 0,
      blur: 0,
      color: 'rgba(0,0,0,1)',
    };
  },

  changeAngle(deg) {
    const prevAngle = this.getAngleValue();
    if (Math.round(prevAngle) === Math.round(deg)) return;

    this.oldDistance = this.getDistanceValue();
    this.oldAngle = deg;
    this.handleChange({ angle: deg });
  },
  distanceChanged(newDistance) {
    const prevDistance = this.getDistanceValue();
    if (prevDistance == newDistance) return;
    this.oldAngle = this.getAngleValue();
    this.oldDistance = undefined;
    this.handleChange({ distance: newDistance });
  },
  sizeChanged(newSize) {
    const prevSize = this.getSizeValue();
    if (prevSize == newSize) return;
    this.handleChange({ size: newSize });
  },
  blurChanged(newBlur) {
    const prevBlur = this.getBlurValue();
    if (prevBlur == newBlur) return;
    this.handleChange({ blur: newBlur });
  },

  getAngleValue() {
    const splitValue = getSplitValueFromProps.call(this, ' ');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const shadowValues = _.map(splitValue, function (cornerVal) {
      return parseFloat(cornerVal);
    });

    return calculateAngle(shadowValues[0], shadowValues[1]);
  },
  getDistanceValue() {
    if (this.oldDistance) {
      return this.oldDistance;
    }
    const splitValue = getSplitValueFromProps.call(this, ' ');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const shadowValues = _.map(splitValue, function (cornerVal) {
      return parseFloat(cornerVal);
    });

    return calculateDistance(shadowValues[0], shadowValues[1]);
  },

  getBlurValue() {
    const shadowValues = getSplitValueFromProps.call(this, ' ');
    return parseFloat(shadowValues[2]);
  },

  getSizeValue() {
    const shadowValues = getSplitValueFromProps.call(this, ' ');
    const sizeValue = parseFloat(shadowValues[3]);
    return sizeValue ? sizeValue : 0;
  },
  getColorValue() {
    let colorVal = util.valueLink.getValueFromProps(this.props);
    colorVal = colorVal.substr(colorVal.indexOf('rgb'));
    const regexRGBA = /rgba\(([^)]+)\)/;
    const colorMatch = regexRGBA.exec(colorVal);
    if (colorMatch) {
      return colorMatch[0];
    }
    return 'rgba(0,0,0,1)';
  },

  getIsSpx() {
    const value = this.props.valueLink?.value;
    return value.split(' ').some((part) => /^(-?[\d.]+)spx$/.test(part));
  },

  getUnit() {
    return this.getIsSpx() ? 'spx' : 'px';
  },

  getStringForValues(values) {
    const extendedData = _.defaults(values, {
      distance: this.getDistanceValue(),
      angle: this.getAngleValue(),
      blur: this.getBlurValue(),
      size: this.getSizeValue(),
      color: this.getColorValue(),
    });

    const XYVal = calculateXYValue(extendedData.distance, extendedData.angle);
    const unit = this.getUnit();
    return `${XYVal.x}${unit} ${XYVal.y}${unit} ${extendedData.blur}${unit} ${extendedData.size}${unit} ${extendedData.color}`;
  },

  linkColorWithOpacity() {
    const color = this.getColorValue();
    const alpha = getAlpha(color);

    return {
      value: {
        color,
        alpha,
      },
      requestChange: function (colorWithAlpha) {
        const newColor = createRGBA(colorWithAlpha);
        this.handleChange({ color: newColor });
      }.bind(this),
    };
  },
  getColorPickerProps() {
    return {
      colorResolver: this.resolveColor,
      openColorPicker: this.openColorPicker,
    };
  },
  handleChange(values) {
    util.valueLink.callOnChangeIfExists(
      this.props,
      this.getStringForValues(values),
    );
  },
  render() {
    return !this.props.disabled ? (
      <div key="shadowControl" className="shadow-control">
        <Composites.AngleInputLabeled>
          <TextLabel
            value="CustomDesign_Shadow_Angle"
            disabled={this.props.disabled}
            shouldTranslate={true}
          />
          <AngleInput
            disabled={this.props.disabled}
            value={this.getAngleValue()}
            onChange={this.changeAngle}
          />
        </Composites.AngleInputLabeled>
        <Divider long={false} />
        <Composites.SliderLabeled>
          <TextLabel
            value="CustomDesign_Shadow_Distance"
            shouldTranslate={true}
            disabled={this.props.disabled}
          />
          <Slider
            min={0}
            max={50}
            value={this.getDistanceValue()}
            step={1}
            onChange={this.distanceChanged}
            disabled={this.props.disabled}
            unit={this.getUnit()}
          />
        </Composites.SliderLabeled>
        <Divider long={false} />
        <Composites.SliderLabeled>
          <TextLabel
            value="CustomDesign_Shadow_Size"
            shouldTranslate={true}
            disabled={this.props.disabled}
          />
          <Slider
            min={0}
            max={50}
            value={this.getSizeValue()}
            step={1}
            onChange={this.sizeChanged}
            disabled={this.props.disabled}
            unit={this.getUnit()}
          />
        </Composites.SliderLabeled>
        <Divider long={false} />
        <Composites.SliderLabeled>
          <TextLabel
            value="CustomDesign_Shadow_Blur"
            shouldTranslate={true}
            disabled={this.props.disabled}
          />
          <Slider
            min={0}
            max={50}
            value={this.getBlurValue()}
            step={1}
            onChange={this.blurChanged}
            disabled={this.props.disabled}
            unit={this.getUnit()}
          />
        </Composites.SliderLabeled>
        <Divider long={false} />
        <baseUI.colorPickerInputWithOpacity
          disabled={this.props.disabled}
          isSmallStepper={true}
          label="CustomDesign_Shadow_Color&Opacity"
          valueLink={this.linkColorWithOpacity()}
          {...this.getColorPickerProps()}
        />
        {this.props.disabled ? (
          <div key="blockingLayer" className="blocking-layer" />
        ) : null}
      </div>
    ) : null;
  },
});

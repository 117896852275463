import constants from '#packages/constants';
import { sections } from '#packages/util';

import type { LayoutConstraint } from '#packages/layoutUtils';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const anchorLayoutBoundariesConstraint: LayoutConstraint = {
  shouldConstrain(editorAPI: EditorAPI, compPointer: CompRef): boolean {
    return (
      editorAPI.components.getType(compPointer) === constants.COMP_TYPES.ANCHOR
    );
  },
  getBoundaries(editorAPI: EditorAPI) {
    const pageRef = editorAPI.pages.getCurrentPage();
    const { topY: anchorTopYBoundary, bottomY: anchorBottomYBoundary } =
      sections.getAnchorPositionBoundaries(editorAPI, {
        pageRef,
        useRelativeToStructureLayout: true,
      });

    return {
      top: {
        compTopY: anchorTopYBoundary,
      },
      bottom: {
        compTopY: anchorBottomYBoundary,
      },
    };
  },
};

export const getAnchorLayoutConstraint = () => anchorLayoutBoundariesConstraint;

import type { EntryPoint } from '#packages/apilib';
import { EditorAPIKey, PanelManagerApiKey } from '#packages/apis';
import { createPanelManagerApi } from './panelManagerApi';

export const PanelManagerApiEntryPoint: EntryPoint = {
  name: 'PanelManagerApi',
  declareAPIs: () => [PanelManagerApiKey],
  getDependencyAPIs: () => [EditorAPIKey],
  attach(shell) {
    shell.contributeAPI(PanelManagerApiKey, () => createPanelManagerApi(shell));
  },
};

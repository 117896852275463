import { siteMenusContent } from '@wix/bi-logger-editor/v2';
import { getMenusUsageData } from './getMenusUsageData';
import { setupCustomMenus } from './setupCustomMenus';
import { migrateToAdvancedMenus } from './advancedMenusMigration';
import { biLogger, isAdvancedMenusMigrationOpen } from '#packages/util';
import { createMenuApi } from '../API/menuAPI';
import type { EditorAPI } from '#packages/editorAPI';

export const setupMenus = async (editorAPI: EditorAPI): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);
  const hasCustomMenus = menuAPI.getCustomMenus().length > 0;

  const isEligibleForAdvancedMenusExperience =
    isAdvancedMenusMigrationOpen() && !hasCustomMenus;

  if (isEligibleForAdvancedMenusExperience) {
    (window as any).___advanced_menus_open___ = true;
  } else {
    await setupCustomMenus(editorAPI);
  }

  const { biData, dataForMigration } = getMenusUsageData(editorAPI, menuAPI);

  biLogger.report(siteMenusContent(biData));

  if (isAdvancedMenusMigrationOpen() && !isEligibleForAdvancedMenusExperience) {
    await migrateToAdvancedMenus(editorAPI, dataForMigration);
  }

  editorAPI.history.clear();
};

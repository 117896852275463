// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';
import getAppointmentFieldSections from './appointmentField/appointmentFieldSections';

export default {
  getCategorySections(editorAPI) {
    const appointmentFieldSections = getAppointmentFieldSections(editorAPI);
    return addPanel.dataUtil.mergeWithCommonSections(
      addPanel.sections.getSections().developer,
      appointmentFieldSections,
    );
  },
};

import React, { type MouseEventHandler } from 'react';
import { ArrowUpSmall } from '@wix/wix-ui-icons-common';
import { TreeListItem } from '@wix/wix-base-ui';
import { symbol as Symbol } from '#packages/baseUI';
import { cx } from '#packages/util';

import SectionsItem from './sectionsItem';
import withSelectedComponent from './withSelectedComponent';
import styles from './sectionizerPanel.scss';

import type { SectionChildren } from './sectionizerPanel.types';
import type { WithSelectedComponentsProps } from './withSelectedComponent';

interface UnsectionedTreeOwnProps {
  components: SectionChildren[];
  createAddComponentHandler: (
    component: SectionChildren,
  ) => MouseEventHandler<HTMLButtonElement>;
}

interface UnsectionedTreeProps
  extends UnsectionedTreeOwnProps,
    WithSelectedComponentsProps {}

const UnsectionedTree: React.FC<UnsectionedTreeProps> = (props) => {
  return (
    <div className={cx(styles.tree, styles.unsectionedTree)}>
      <SectionsItem label="Unsectioned">
        <>
          {props.components.map((item: SectionChildren) => (
            <TreeListItem
              label={item.displayName}
              icon={<Symbol name={item.icon.svgName} />}
              key={item.compId}
              selected={item.compId === props.selectedCompId}
              shouldTranslate={false}
              onClick={() => props.scrollToCompAndSelect(item.compId)}
              suffix={
                <div>
                  <button
                    className={styles.contextButton}
                    onClick={props.createAddComponentHandler(item)}
                  >
                    <ArrowUpSmall />
                  </button>
                </div>
              }
            />
          ))}
        </>
      </SectionsItem>
    </div>
  );
};

export default withSelectedComponent(
  UnsectionedTree,
) as React.FC<UnsectionedTreeOwnProps>;

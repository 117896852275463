import { BasePublicApi } from '#packages/apilib';
import type { UIResourceRestriction } from '#packages/editorRestrictions';
import type { SelectComponentsByRefInterceptor } from '#packages/selection';
import type { ReadonlyModeScope } from './readonlyModeEntryPoint';

const restrictionsToSet = [
  'left-bar_add.visible',
  'left-bar_background.visible',
  'left-bar_design.visible',
  'left-bar_mobile-add.visible',
  'left-bar_add-apps.visible',
  'left-bar_app-manager.visible',
  'left-bar_media-manager.visible',
  'left-bar_super-apps.visible',
  'left-bar_hidden-elements.visible',
  'left-bar_layers-button.visible',
  'left-bar_more-options.visible',
  'left-bar_mobile-tools.visible',
  'left-bar_layout-optimizer.visible',
  'left-bar_sections.visible',
  'left-bar_ai.visible',

  'top-bar_tools.interactive',
  'top-bar_search.interactive',
] as UIResourceRestriction[];

const interceptor: SelectComponentsByRefInterceptor = (_, { cancel }) => {
  cancel();
};

function enableReadonlyMode({
  restrictionsApi,
  selectionApi,
  panelManagerApi,
  store,
}: ReadonlyModeScope) {
  store.enableReadonlyMode();

  panelManagerApi.closeAllPanels();
  selectionApi.deselectComponents();

  selectionApi.hooks.selectComponentByCompRefInterceptor.tap(interceptor);

  restrictionsApi.set(
    restrictionsToSet,
    () => !store.getIsReadonlyModeEnabled(),
  );
}

function disableReadonlyMode({ selectionApi, store }: ReadonlyModeScope) {
  store.disableReadonlyMode();

  selectionApi.hooks.selectComponentByCompRefInterceptor.untap(interceptor);
}

function isReadonlyModeEnabled({ store }: ReadonlyModeScope) {
  return store.getIsReadonlyModeEnabled();
}

export class ReadonlyModeApi extends BasePublicApi<ReadonlyModeScope> {
  isReadonlyModeEnabled = this.bindScope(isReadonlyModeEnabled);
  enableReadonlyMode = this.bindScope(enableReadonlyMode);
  disableReadonlyMode = this.bindScope(disableReadonlyMode);
}

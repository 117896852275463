// @ts-nocheck
import _ from 'lodash';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import {
  isRefComponent,
  getRefComponentRootChild,
} from '#packages/documentServices';
import { translate } from '#packages/i18n';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef, DSRead } from 'types/documentServices';

const { shouldWidgetHaveAddElements, getEssentialData } =
  stateManagement.platform.selectors;
const { openAddElementsPanel } = stateManagement.platform.actions;
const { getSessionUserPreferences } = stateManagement.userPreferences.selectors;
const { setSessionUserPreferences } = stateManagement.userPreferences.actions;

function willDeleteOrphanConnections(
  dsRead,
  componentPointers,
  allDeletedComponents,
) {
  allDeletedComponents = _(allDeletedComponents);

  const controllers = allDeletedComponents.filter(function (pointer) {
    return (
      dsRead.components.getType(pointer) === 'platform.components.AppController'
    );
  });

  if (controllers.isEmpty()) {
    return false;
  }

  const deletedIds = allDeletedComponents.map('id').value();

  return controllers
    .map((controllerRef) =>
      dsRead.platform.controllers.connections.getConnectedComponents(
        controllerRef,
      ),
    )
    .some(
      (connectedComponents) =>
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        !_(connectedComponents).map('id').difference(deletedIds).isEmpty(),
    );
}

function confirmOnOrphanConnections(
  editorAPI,
  dsRead,
  deletedComponentsPointers,
  allDeletedComponents,
  actualDelete,
) {
  if (
    willDeleteOrphanConnections(
      dsRead,
      deletedComponentsPointers,
      allDeletedComponents,
    )
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const controllers = _.filter(
      allDeletedComponents,
      (pointer) =>
        dsRead.components.getType(pointer) ===
        'platform.components.AppController',
    );

    const singleController = controllers.length === 1;
    editorAPI.store.dispatch(
      stateManagement.panels.actions.updateOrOpenPanel(
        'platform.panels.appControllerDeleteConfirmationPanel',
        {
          onConfirm: actualDelete,
          isSingleController: singleController,
          controllerDisplayName: singleController
            ? dsRead.platform.controllers.getName(controllers[0])
            : '',
        },
      ),
    );

    return true;
  }

  return false;
}

function resetClipboardIfCopiedComponentDescendsFromDeletedWidget(
  editorAPI,
  dsRead,
  deletedComponentsPointers,
  allDeletedComponents,
) {
  const clipboardData = editorAPI.clipboard.getItem();
  const clipboardComponents = clipboardData?.value?.components ?? [];
  const clipboardItemsData = clipboardData?.value?.componentsData ?? {};

  if (_.isEmpty(clipboardComponents) || _.isEmpty(clipboardItemsData)) {
    return false;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const copiedComponentsAppWidgetAncestors = _(clipboardComponents)
    .map(({ _dataId }) => clipboardItemsData[_dataId].appWidgetAncestor)
    .uniqBy('id')
    .compact()
    .value();

  if (_.isEmpty(copiedComponentsAppWidgetAncestors)) {
    return false;
  }

  if (
    _.intersectionBy(
      allDeletedComponents,
      copiedComponentsAppWidgetAncestors,
      'id',
    )
  ) {
    editorAPI.clipboard.removeItem();
  }

  return false;
}

function selectAppWidget(editorAPI, appWidgetRef) {
  const compRefToSelect = editorAPI.components.is.controlledByParent(
    appWidgetRef,
  )
    ? editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(appWidgetRef)
    : appWidgetRef;
  editorAPI.selection.selectComponentByCompRef(compRefToSelect);
}

function openAddElementsPanelIfNeeded(
  editorAPI,
  dsRead,
  deletedComponentsPointers,
  allDeletedComponents,
  actualDelete,
) {
  const widgetWithAddElements = allDeletedComponents
    .map(
      (compRef) =>
        dsRead.platform.controllers.connections.getPrimaryConnection(
          isRefComponent(dsRead, compRef)
            ? getRefComponentRootChild(dsRead, compRef)
            : compRef,
        )?.controllerRef,
    )
    .find(
      (widgetRef) =>
        widgetRef && shouldWidgetHaveAddElements(editorAPI, widgetRef),
    );

  const shouldOpenAddElements = !getSessionUserPreferences(
    constants.USER_PREFS.ADD_ELEMENTS.ADD_ELEMENTS_OPENED,
  )(editorAPI.store.getState());

  const isInBlocksEditor = editorAPI.host.getAPI({
    name: 'EditorParamsApi',
    public: true,
  })?.isInsideAppStudio;

  if (widgetWithAddElements && shouldOpenAddElements) {
    actualDelete().then(() => {
      if (
        editorAPI.panelManager.isPanelOpened(
          'compPanels.panels.Widget.elementsPanel',
        ) ||
        isInBlocksEditor
      ) {
        return;
      }
      selectAppWidget(editorAPI, widgetWithAddElements);
      editorAPI.store.dispatch(openAddElementsPanel(widgetWithAddElements));
      editorAPI.store.dispatch(
        setSessionUserPreferences(
          constants.USER_PREFS.ADD_ELEMENTS.ADD_ELEMENTS_OPENED,
          true,
        ),
      );
    });
    return true;
  }
  return false;
}

function handleWidgetPluginRemoving(
  editorAPI: EditorAPI,
  dsRead: DSRead,
  deletedComponentsPointers: CompRef[],
  allDeletedComponents,
  actualDelete: () => Promise<void>,
  origin: string,
) {
  const [widgetPluginCompRef] = deletedComponentsPointers;

  if (
    editorAPI.platform.widgetPlugins.isWidgetPluginComponent(
      widgetPluginCompRef,
    )
  ) {
    const slotPlaceholderCompRef =
      editorAPI.platform.widgetPlugins.getSlotPlaceholderRefByContentRef(
        widgetPluginCompRef,
      );
    editorAPI.platform.widgetPlugins.uninstallWidgetPlugin(
      slotPlaceholderCompRef,
      origin,
    );

    return true;
  }

  return false;
}

function displayDeleteNotificationIfNeeded(
  editorAPI: EditorAPI,
  dsRead: DSRead,
  deletedComponentsPointers: CompRef[],
  allDeletedComponents: CompRef[],
  actualDelete: () => Promise<void>,
): boolean {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const essentialComps = _(deletedComponentsPointers)
    .map((comp) => _.defaults({ comp }, getEssentialData(editorAPI, comp)))
    .filter(['isEssential', true])
    .value();

  if (_.isEmpty(essentialComps)) {
    return false;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  const serializedComps = _.map(essentialComps, (compData) =>
    _.defaults(compData, {
      // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
      definition: dsRead.components.serialize(compData.comp),
      container: dsRead.deprecatedOldBadPerformanceApis.components.getContainer(
        compData.comp,
      ),
      displayName: editorAPI.components.getDisplayName(compData.comp),
    }),
  );

  actualDelete().then(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(serializedComps, (compData) => {
      const { definition, container, text, displayName, comp } = compData;
      editorAPI.store.dispatch(
        stateManagement.notifications.actions.showUserActionNotification({
          message: text,
          title: 'Delete essential component in open widget',
          type: 'warning',
          shouldTranslate: false,
          link: {
            caption: translate(
              'AppStudio_Essential_Elements_Missing_Notification_CTA',
              { Element_Name: displayName },
            ),
            onNotificationLinkClick: () => {
              if (editorAPI.components.is.exist(container)) {
                editorAPI.components.add(container, definition, comp.id);
              }
            },
          },
        }),
      );
    });
  });
  return true;
}

export {
  confirmOnOrphanConnections,
  resetClipboardIfCopiedComponentDescendsFromDeletedWidget,
  displayDeleteNotificationIfNeeded,
  openAddElementsPanelIfNeeded,
  handleWidgetPluginRemoving,
};

import type { EditorAPI } from '#packages/editorAPI';
import type { RCMAction } from '../types';
import type { CompRef } from 'types/documentServices';

const desktopCustomActions = [
  {
    label(editorAPI: EditorAPI, compPointer: CompRef) {
      const isSingleColumnStrip =
        editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(compPointer)
          .length === 1;
      if (isSingleColumnStrip) {
        return 'STRIP_CONTAINER_LAYOUTS_ADD_COLUMNS';
      }

      return 'STRIP_CONTAINER_LAYOUTS_ADD_EXTRA_COLUMNS';
    },
    enabled(editorAPI: EditorAPI, compPointer: CompRef) {
      return editorAPI.columns.canAdd(compPointer);
    },
    onClick(editorAPI: EditorAPI, compPointer: CompRef) {
      editorAPI.columns.addColumn(compPointer, 'rcm');
      editorAPI.closeRightClickMenu();
    },
  },
];

export default function (editorAPI: EditorAPI): RCMAction[] {
  if (editorAPI.isMobileEditor()) {
    return [];
  }

  return desktopCustomActions;
}

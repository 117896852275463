// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['Mobile_Menu_Icon_Design_Section_Discover_Icons_Label'],
      sections: [
        addPanel.sections.getSections().designPanelOnly
          .inlinePopupToggleStyleSection,
      ],
    };
  },
};

import _ from 'lodash';
import { googleTranslate } from '#packages/util';
import { translate } from '#packages/i18n';

import { convertPageNameToUrl } from '../utils/utils';

import type {
  PagesData,
  PageRef,
  RouterDynamicPages,
  RoutersDefinition,
} from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

const NEW_PAGE_TITLE = translate('Pages_Menu_Add_New_Page');

type IPageId = string;

export interface IPagesAPI {
  getAllPages: () => PagesData[];
  getPageById: (id: IPageId) => PagesData;
  getDynamicPagesList(): RouterDynamicPages[];
  add(name?: string, page?: Partial<PagesData>): PageRef;
  isDynamicPage(pageId: IPageId): boolean;
  isMembersPage(pageId: IPageId): boolean;
  getHomePageId: () => IPageId;
  rename(id: IPageId, title: string): Promise<void>;
  getCurrentPageId(): IPageId;
  navigateTo(pageId: IPageId, callback?: () => void): void;

  canDuplicatePage(pageId: string): boolean;

  getRouterGroupPagesData(groupName: string): DynamicPageData[];
}

interface DynamicPageData {
  pageId: string;
  innerRoute: string;
  routerId: string;
  title: string;
}

interface IRouterConfig {
  type: 'public' | 'private';
  patterns: {
    [pattern in string]: {
      appData: object;
      // page guid
      page: string;
      title: string;
    };
  };
}

export const createPagesApi = (editorAPI: EditorAPI): IPagesAPI => {
  const { dsRead, dsActions } = editorAPI;

  const getPageById = (id: IPageId) => editorAPI.dsRead.pages.data.get(id);

  const getAllPages = () => dsRead.pages.getPagesData();

  const getDynamicPagesList = () => dsRead.routers.pages.getDynamicPagesList();

  const add = (name: string = NEW_PAGE_TITLE, page?: Partial<PagesData>) =>
    dsActions.pages.add(name, page);

  const isDynamicPage = (pageId: IPageId) =>
    Boolean(dsRead.routers.getRouterDataForPageIfExist(pageId));

  const isMembersPage = (pageId: IPageId) => {
    const membersAppDefId = dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;
    const page = getPageById(pageId);

    return page.managingAppDefId === membersAppDefId;
  };

  const getHomePageId = () => dsRead.homePage.get();

  const rename = (pageId: IPageId, title: string) => {
    const pageData = getPageById(pageId);

    if (!title || pageData.title === title) {
      return Promise.resolve();
    }

    return getPageDataForRename(editorAPI, {
      pageData,
      newLabel: title,
    }).then((updatedPageData) => {
      editorAPI.pages.data.update(pageData.id, updatedPageData);
    });
  };

  const getCurrentPageId = () => dsRead.pages.getCurrentPageId();

  const navigateTo = (pageId: IPageId, callback?: () => void) =>
    dsActions.pages.navigateTo(pageId, callback);

  const canDuplicatePage = (pageId: IPageId) =>
    editorAPI.dsRead.pages.isDuplicable(pageId);

  const getRouterGroupPagesData = (groupName: string): DynamicPageData[] => {
    const dsRouters = editorAPI.dsRead.routers;

    const membersRouters = Object.entries(dsRouters.get.all() || {}).filter(
      ([_routerId, router]) => router.group === groupName,
    );

    return _.flatMap(membersRouters, ([routerId, router]) =>
      getDynamicPagesDataFromRouter({ ...router, id: routerId }),
    );
  };

  return {
    getCurrentPageId,
    getPageById,
    getDynamicPagesList,
    add,
    getAllPages,
    isDynamicPage,
    isMembersPage,
    getHomePageId,
    rename,
    navigateTo,
    canDuplicatePage,
    getRouterGroupPagesData,
  };
};

const getInnerRouteFromRouterPattern = (pattern: string): string =>
  pattern.startsWith('/') ? pattern.slice(1) : pattern;

const getDynamicPagesDataFromRouter = (
  router: RoutersDefinition & { id: string },
): DynamicPageData[] => {
  const routerConfig: string | undefined = (router as any).config;

  if (!routerConfig) {
    return [];
  }

  const config: IRouterConfig = JSON.parse(routerConfig);

  return Object.entries(config.patterns || {}).map(([pattern, item]) => {
    const pageId = router.pages[item.page as AnyFixMe] as AnyFixMe;

    return {
      innerRoute: getInnerRouteFromRouterPattern(pattern),
      routerId: router.id,
      pageId,
      title: item.title,
    };
  });
};

const shouldOverridePageUriSEO = (editorAPI: EditorAPI, title: string) => {
  // TODO: Contribute typings to DS
  const {
    urlFormat: { isSlash },
    isSitePublished,
  } = editorAPI.dsRead.generalInfo;

  return !isSlash() || !isSitePublished() || title === NEW_PAGE_TITLE;
};

const getPageDataForRename = (
  editorAPI: EditorAPI,
  { newLabel, pageData }: { newLabel: string; pageData: PagesData },
): Promise<Partial<PagesData>> => {
  const updatedPageData: Partial<PagesData> = { title: newLabel };
  const needToOverrideSeo = shouldOverridePageUriSEO(editorAPI, pageData.title);

  if (pageData.isPopup || !needToOverrideSeo) {
    return Promise.resolve(updatedPageData);
  }

  // TODO: add types to DS
  const pageNameConverter = editorAPI.dsRead.generalInfo.urlFormat.isSlash()
    ? // TODO: add types to DS
      editorAPI.pages.data.pageUriSEO.convertPageNameToUrl
    : convertPageNameToUrl;

  const convertedPageUriSEO: string = pageNameConverter(newLabel);

  if (convertedPageUriSEO) {
    return Promise.resolve({
      ...updatedPageData,
      pageUriSEO: convertedPageUriSEO,
    });
  }

  const translatedLabel = new Promise<string>((resolve) => {
    googleTranslate(newLabel, function (translation: AnyFixMe) {
      resolve(translation);
    });
  });

  return translatedLabel.then((label) => {
    const convertedLabel = pageNameConverter(label);

    if (convertedLabel) {
      return {
        ...updatedPageData,
        pageUriSEO: convertedLabel,
        translationData: {
          ...pageData.translationData,
          uriSEOTranslated: true,
        },
      };
    }

    return updatedPageData;
  });
};

import type { EditorAPI } from '#packages/editorAPI';

function isEnabled(editorAPI: EditorAPI): boolean {
  return editorAPI.developerMode.isEnabled();
}

// Defualt implementation, relevant only on editorX
function isReadOnly(): boolean {
  return false;
}

export { isEnabled, isReadOnly };

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as util from '#packages/util';
import { cx } from '#packages/util';

export interface RcmItemWithSubItemsRecursiveProps {
  shouldTranslate: boolean;
  label: string;
  item: {
    enabled: boolean;
    shouldShowCount?: boolean;
  };
  items: RcmItemWithSubItemsRecursiveProps[];
}

// eslint-disable-next-line react/prefer-es6-class
const Recursive = createReactClass<RcmItemWithSubItemsRecursiveProps>({
  displayName: 'rcmItemWithSubItemsRecursive',
  mixins: [util.translationMixin],
  getChildren() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _.map(this.props.items, function (item) {
      return React.createElement(Recursive, item);
    });
  },
  render() {
    return (
      <li
        onClick={this.props.onClick}
        className={cx({
          disabled: !this.props.item.enabled,
          hoverable: this.props.item.enabled,
          flex: true,
        })}
      >
        <span className="label label-sub-item">
          <span>{this.translateIfNeeded(this.props.label)}</span>
          {this.props.item.shouldShowCount ? (
            <span key="count">
              {` (${React.Children.count(this.props.children)})`}
            </span>
          ) : null}
        </span>
        <span
          className={cx({
            action: true,
            'action-expand': this.props.items.length > 0,
            'margin-left-auto': true,
            'action-relative': true,
          })}
        />
        <div className="rightClickMenu subMenu">
          <ul>{this.getChildren()}</ul>
        </div>
      </li>
    );
  },
});

export default Recursive;

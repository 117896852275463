import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';

import { SEARCH_CATEGORIES_LIST } from '../searchModule/constants';

import type { BiEventDefinition, SendBiFunction } from 'types/bi';

interface EditorSearchBiContext {
  target: string;
  requestId: string;
  correlationId: string;
}

export interface BISearchResultData {
  id: string;
  categoryID: string;
  index: number;
}
export type ClickOrigins = 'keyboard' | 'mouse';

export const enum BILinkButtonNames {
  HelpCenterHome = 'help_center_home',
  AppMarketHome = 'app_market_home',
  AppMarketCategory = 'app-market-category',
  HelpCenterCategory = 'help-center-category',
}

const SEARCH_CATEGORY_INDEX_MAP = SEARCH_CATEGORIES_LIST.reduce(
  (acc, searchCategory, index) => ({ ...acc, [searchCategory]: index }),
  {},
);

const getClickOrigin = (isKeyboard: boolean): ClickOrigins =>
  isKeyboard ? 'keyboard' : 'mouse';

export class EditorSearchBiLogger {
  private sendBi: SendBiFunction;
  private readonly context: EditorSearchBiContext;
  private openOrigin: string;
  private requestStartTimestamp: number;

  constructor() {
    this.context = {
      correlationId: util.guidUtils.getGUID(),
      requestId: util.guidUtils.getGUID(),
      target: '',
    };
    this.requestStartTimestamp = null;
  }

  init(sendBi: SendBiFunction) {
    this.sendBi = sendBi;
  }

  setOpenOrigin(openOrigin: string) {
    this.openOrigin = openOrigin;
  }

  private resetRequestId() {
    this.context.requestId = util.guidUtils.getGUID();
    this.requestStartTimestamp = Date.now();
  }

  updateSearchQuery(target: string) {
    this.context.target = target;
    this.resetRequestId();
  }

  private resetCorrelationId() {
    this.context.correlationId = util.guidUtils.getGUID();
  }

  private send(event: BiEventDefinition, params: any) {
    if (params.category) {
      params.categoryIndex =
        SEARCH_CATEGORY_INDEX_MAP[
          params.category as keyof typeof SEARCH_CATEGORY_INDEX_MAP
        ];
    }

    this.sendBi(event, {
      ...this.context,
      ...params,
    });
  }

  logSearchQueryCleared(isKeyboard = false) {
    this.send(coreBi.events.editorSearch.searchQueryCleared, {
      clickOrigin: getClickOrigin(isKeyboard),
    });
    this.resetCorrelationId();
  }

  logShowMoreClicked(categoryID: string, isKeyboard = false) {
    this.send(coreBi.events.editorSearch.showMoreClicked, {
      clickOrigin: getClickOrigin(isKeyboard),
      category: categoryID,
    });
  }

  logLinkClicked(
    button: BILinkButtonNames,

    category: string = null,
  ) {
    this.send(coreBi.events.editorSearch.linkClicked, {
      category,
      button,
    });
  }

  logSearchResultCTAClicked(
    { categoryID, id, index }: BISearchResultData,
    isKeyboard = false,
    isTooltipCTA = false,
  ) {
    if (isTooltipCTA) {
      this.logConditionalTooltipCTAClicked({
        categoryID,
        id,
        index,
      });
      return;
    }

    this.send(coreBi.events.editorSearch.searchResultCTAClicked, {
      clickOrigin: getClickOrigin(isKeyboard),
      category: categoryID,
      itemId: id,
      itemIndex: index,
    });
  }

  logSearchResultHovered({ categoryID, id, index }: BISearchResultData) {
    this.send(coreBi.events.editorSearch.searchResultHovered, {
      category: categoryID,
      itemId: id,
      itemIndex: index,
    });
  }

  logConditionalResultCTAHovered({
    categoryID,
    id,
    index,
  }: BISearchResultData) {
    this.send(coreBi.events.editorSearch.conditionalCTAHovered, {
      category: categoryID,
      itemId: id,
      itemIndex: index,
    });
  }

  private logConditionalTooltipCTAClicked({
    categoryID,
    id,
    index,
  }: BISearchResultData) {
    this.send(coreBi.events.editorSearch.conditionalTooltipCTAClicked, {
      category: categoryID,
      itemId: id,
      itemIndex: index,
    });
  }

  logSearchResultReceived({
    success,
    errorMessage,
    count,
    results,
  }: {
    success: boolean;
    errorMessage?: string;
    count?: number;
    results?: string[];
  }) {
    this.send(coreBi.events.editorSearch.searchResultReceived, {
      success,
      errorMessage,
      itemsCount: count,
      loadingDuration: Date.now() - this.requestStartTimestamp,
      result: JSON.stringify(results),
      origin: this.openOrigin,
    });
  }

  logItemViewed({ categoryID, id, index }: BISearchResultData) {
    this.send(coreBi.events.editorSearch.searchResultViewed, {
      category: categoryID,
      itemId: id,
      itemIndex: index,
    });
  }
}

export const editorSearchBiLogger = new EditorSearchBiLogger();

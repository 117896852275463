// @ts-nocheck
import constants from '#packages/constants';

const { ACTIONS } = constants.ROOT_COMPS.GFPP;

export default {
  enabledActions: [
    ACTIONS.SETTINGS,
    ACTIONS.LAYOUT,
    ACTIONS.ANIMATION,
    ACTIONS.HELP,
  ],
  mobileEnabledActions: [
    ACTIONS.LAYOUT,
    ACTIONS.HIDE,
    ACTIONS.HELP,
    ACTIONS.ANIMATION,
  ],
  presetActions: {
    help: {
      helpId: 'c81fed58-1b1e-494c-ad26-2fb56634d580',
    },
  },
};

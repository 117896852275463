import type { Dispatch, ThunkAction } from '../../../../types/redux';
import * as _ from 'lodash';
import { workspace } from '#packages/util';
import { panels, schoolMode } from '#packages/stateManagement';

export const mapDispatchToProps = (dispatch: Dispatch<ThunkAction>) => {
  return {
    openDashboard() {
      dispatch((_dispatch, state, { editorAPI }) => {
        const isFirstSave =
          _.invoke(editorAPI, 'dsRead.generalInfo.isFirstSave') || false;
        const isDraftMode =
          _.invoke(editorAPI, 'dsRead.generalInfo.isDraft') || false;

        const SAVE_YOUR_SITE_PANEL_BODY_KEY = workspace.isNewWorkspaceEnabled()
          ? 'TOPBAR_SITE_DASHBOARD_FIRST_SAVE_SITE_TEXT'
          : 'TOPBAR_SITE_DASHBOARD_SAVE_FIRST_MSG_BODY';

        if (isFirstSave || isDraftMode) {
          return dispatch(
            panels.actions.updateOrOpenPanel(
              'savePublish.panels.save.saveYourSitePanel',
              {
                title: 'TOPBAR_SITE_SETTINGS_SAVE_FIRST_MSG_TITLE',
                messageBody: SAVE_YOUR_SITE_PANEL_BODY_KEY,
              },
              true,
            ),
          );
        }
        if (schoolMode.selectors.isEnabled(editorAPI.store.getState())) {
          editorAPI.schoolMode.openDashboard();
        } else {
          editorAPI.account.manage();
        }
      });
    },
  };
};

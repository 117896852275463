import { constants as mediaManagerPanelConsts } from '#packages/mediaManagerPanelInfra';
import constants from '#packages/constants';
import experiment from 'experiment';

const { MEDIA_MANAGER_MEDIA_TYPES } = constants;
export const {
  MEDIA_MANAGER_PANEL_SECTION_ID,
  DYNAMIC_MEDIA_CONTENT_WIDTH,
  SHUTTERSTOCK_MEDIA_TYPE,
} = mediaManagerPanelConsts;

export enum SEARCH_STATUS {
  LOADING = 'LOADING',
  NO_RESULTS = 'NO_RESULTS',
  RESULTS = 'RESULTS',
}

export const SEARCH_ITEMS = [
  {
    id: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_IMAGES,
    mediaType: MEDIA_MANAGER_MEDIA_TYPES.PICTURE,
    automationId: 'addPanel_imageDynamic_wixImagesSection',
  },
  {
    id: MEDIA_MANAGER_PANEL_SECTION_ID.WIX_ILLUSTRATIONS,
    mediaType: MEDIA_MANAGER_MEDIA_TYPES.PICTURE,
    automationId: 'addPanel_imageDynamic_wixIllustrationsSection',
  },
];

if (experiment.isOpen('se_shutterstockAddPanel')) {
  SEARCH_ITEMS.push({
    id: MEDIA_MANAGER_PANEL_SECTION_ID.SHUTTERSTOCK_IMAGES,
    // @ts-expect-error
    mediaType: SHUTTERSTOCK_MEDIA_TYPE.IMAGE,
    automationId: 'addPanel_imageDynamic_shutterstockImagesSection',
  });
}

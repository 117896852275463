import {
  selection,
  pinMode,
  gridLines,
  attachCandidate,
} from '#packages/stateManagement';
import type { StateMapperArgs } from 'types/redux';
import type { GridLinesContainerProps } from './types';
import constants from '#packages/constants';
import { sections as sectionUtils } from '#packages/util';
import { utils } from '#packages/core';
import { isContainerComp } from '../compControls/compControls.utils';
import type { GridLine } from 'types/core';
import type { EditorState } from '#packages/stateManagement';
import type { EditorAPI } from '#packages/editorAPI';

const { getSelectedCompsRefs } = selection.selectors;

const getAttachCandidateWithGridlines = (editorAPI: EditorAPI) => {
  const attachCandidateComp =
    attachCandidate.selectors.selectAttachCandidateComponent(
      editorAPI.store.getState(),
    );

  const isAttachCandidateWithGridlines =
    !!attachCandidateComp &&
    editorAPI.components.is.showMarginsIndicator(attachCandidateComp);

  return isAttachCandidateWithGridlines ? attachCandidateComp : null;
};

const getFocusedContainer = (editorAPI: EditorAPI, state: EditorState) => {
  const focusedContainer = selection.selectors.getFocusedContainer(state);
  const focusedContainerWithGridLines = !isContainerComp(
    editorAPI,
    focusedContainer,
  )
    ? focusedContainer
    : null;
  const focusedColumnAncestor = editorAPI.components.findAncestor(
    focusedContainer,
    (ancestor) => editorAPI.columns.isColumn(ancestor),
    { includeScopeOwner: true },
  );
  const focusedColumnsContainerAncestor = editorAPI.components.findAncestor(
    focusedContainer,
    (ancestor) => editorAPI.columns.isStrip(ancestor),
    { includeScopeOwner: true },
  );
  const focusedSectionLike = sectionUtils.isSectionsEnabled()
    ? editorAPI.sections.getClosestSectionLike(focusedContainer)
    : null;
  const attachCandidateWithGridLines =
    getAttachCandidateWithGridlines(editorAPI);

  return (
    attachCandidateWithGridLines ||
    focusedContainerWithGridLines ||
    focusedColumnAncestor ||
    focusedColumnsContainerAncestor ||
    focusedSectionLike
  );
};

const getIsPinMode = pinMode.selectors.isOpen;

const isGridLinesVisible = (mapperArgs: StateMapperArgs) => {
  const { editorAPI, state } = mapperArgs;
  let isGridLinesVisibleForCurrentPopup = true;

  if (editorAPI.spotlightStage.getContainer()) {
    return false;
  }

  if (
    editorAPI.isPreviewReady() &&
    editorAPI.dsRead.pages.popupPages.isPopupOpened()
  ) {
    isGridLinesVisibleForCurrentPopup =
      editorAPI.components.is.gridLinesVisible(
        editorAPI.pages.popupPages.getPopupContainer(),
      );
  }

  return (
    editorAPI.isDesktopEditor() &&
    state.viewTools.guidelinesEnabled &&
    !getIsPinMode(state) &&
    isGridLinesVisibleForCurrentPopup
  );
};

const getPageGridLines = ({ editorAPI }: StateMapperArgs): GridLine[] => {
  const attachCandidateWithGridLines =
    getAttachCandidateWithGridlines(editorAPI);
  const pageGridLinesOverrides = gridLines.selectors.getGridLinesOverrides(
    editorAPI.store.getState(),
    { gridLinesType: constants.GRID_LINES_TYPES.PAGE },
  );
  if (pageGridLinesOverrides && !attachCandidateWithGridLines) {
    return pageGridLinesOverrides;
  }

  const isLandingPage = editorAPI.isCurrentPageLandingPage();
  const isPopupPage = Boolean(
    editorAPI.dsRead.pages.popupPages.getCurrentPopupId(),
  );

  const ignoreHorizontals = isLandingPage || isPopupPage;

  return utils.gridLinesUtil.getPageGridLines(editorAPI, ignoreHorizontals);
};

const getContainerGridLines = ({ editorAPI, state }: StateMapperArgs) => {
  const compPointer = getFocusedContainer(editorAPI, state);

  return utils.gridLinesUtil.getContainerGridLines(editorAPI, compPointer);
};

const shouldFadeGridLines = ({ editorAPI, state }: StateMapperArgs) => {
  const focusedContainer = getFocusedContainer(editorAPI, state);
  const selectedComponents = getSelectedCompsRefs(state);

  const attachCandidateWithGridLines =
    getAttachCandidateWithGridlines(editorAPI);
  const isAttachCandidateFadingGridlines =
    attachCandidateWithGridLines &&
    !editorAPI.utils.isPage(attachCandidateWithGridLines);
  const isContainerWithGridlines =
    editorAPI.columns.isStrip(selectedComponents) &&
    editorAPI.components.getChildren(selectedComponents).length > 1 &&
    selectedComponents &&
    editorAPI.components.is.fullWidth(selectedComponents);
  const hasMarginIndicators =
    editorAPI.components.is.showMarginsIndicator(focusedContainer);

  return (
    isAttachCandidateFadingGridlines ||
    hasMarginIndicators ||
    isContainerWithGridlines
  );
};

const shouldShowContainerGridLines = ({
  editorAPI,
  state,
}: StateMapperArgs) => {
  const focusedContainer = getFocusedContainer(editorAPI, state);
  const isAttachCandidateWithGridLines =
    !!getAttachCandidateWithGridlines(editorAPI);
  const hasMarginIndicators =
    editorAPI.components.is.showMarginsIndicator(focusedContainer);
  return isAttachCandidateWithGridLines || hasMarginIndicators;
};

export const mapStateToProps = (
  //TODO use selectors
  mapperArgs: StateMapperArgs,
): GridLinesContainerProps => {
  const isVisible = isGridLinesVisible(mapperArgs);
  if (!isVisible) {
    return { isVisible } as any;
  }
  const isFade = shouldFadeGridLines(mapperArgs);
  const pageGridLines = getPageGridLines(mapperArgs);

  const showContainerGridLines = shouldShowContainerGridLines(mapperArgs);
  const containerGridLines =
    showContainerGridLines && getContainerGridLines(mapperArgs);

  return {
    isFade,
    showContainerGridLines,
    containerGridLines,
    isVisible,
    pageGridLines,
  };
};

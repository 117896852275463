// @ts-nocheck
import _ from 'lodash';
import * as util from '#packages/util';
import * as helpIds from '#packages/helpIds';
import * as bi from '../../bi/bi';
import coreUtilsLib from 'coreUtilsLib';
import * as stateManagement from '#packages/stateManagement';
import constants from '../../constants/constants';
import type { AppData } from 'types/documentServices';

const queryParams = ['openAppDefId', 'appDefinitionId'];

const TARGET_ORIGIN = util.serviceTopology.htmlEditorRootUrl
  ? util.serviceTopology.htmlEditorRootUrl.replace(/html\/editor\/?/, '')
  : 'http://editor.wix.com/';

const shouldOpenAppMarketTab = function () {
  const openPanelQuery = util.url.getParameterByName('openpanel');

  return openPanelQuery.indexOf('market') === 0;
};

const getAppMarketTests = function () {
  let appMarketExperiments = util.url.parseUrlParams(window.location.search); //TODO Switch to get experiments from siteData or something (not from URL)
  appMarketExperiments = appMarketExperiments?.amexperiment;
  return appMarketExperiments || '';
};

const getAppMarketUrlParams = function () {
  const urlParams = {};

  queryParams.forEach(function (paramName) {
    urlParams[paramName] = util.url.getParameterByName(paramName);
  });

  urlParams.tests = getAppMarketTests();

  return urlParams;
};

const getAppMarketOpenPanelUrlParams = function () {
  const openPanelParam = util.url.getParameterByName('openpanel');
  const params = {};

  if (!_.isEmpty(openPanelParam)) {
    const tokens = openPanelParam.split(':');
    const genericParams = getAppMarketUrlParams();

    switch (tokens.length) {
      case 1:
        queryParams.forEach(function (paramName) {
          params[paramName] = genericParams[paramName];
        });
        break;
      case 2:
        params.query = tokens[1];
        break;
      case 3:
        if (tokens[1] === 'Cat') {
          params.categorySlug = tokens[2];
        } else if (tokens[1] === 'appDefId') {
          params.openAppDefId = tokens[2];
        }
        break;
      default:
        params.query = '';
        break;
    }
  }

  return params;
};

const getAppMarketPersistedUserPrefs = function (editorAPI) {
  return (
    stateManagement.userPreferences.selectors.getSiteUserPreferences(
      'app-market',
    )(editorAPI.store.getState()) || {}
  );
};

const setAppMarketPersistedUserPrefs = function (
  editorAPI,
  appMarketUserPrefs,
) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      'app-market',
      appMarketUserPrefs,
    ),
  );
};

const getAppMarketSessionUserPrefs = function (editorAPI) {
  return (
    stateManagement.userPreferences.selectors.getSessionUserPreferences(
      'app-market',
    )(editorAPI.store.getState()) || {}
  );
};

const setAppMarketSessionUserPrefs = function (editorAPI, appMarketUserPrefs) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSessionUserPreferences(
      'app-market',
      appMarketUserPrefs,
    ),
  );
};

const openAppMarketModal = function (openPanel, modalName, options) {
  const panelName = `tpa.compModals.${modalName}`;
  options.width = parseNumberParamToString(options.width);
  options.height = parseNumberParamToString(options.height);

  const panelProps = _.pick(options, [
    'title',
    'width',
    'height',
    'closeModalBiEvent',
    'closeModalBiParams',
    'id',
    'shouldHideHeader',
    'onClose',
    'origin',
  ]);
  panelProps.panelName = panelName;
  panelProps.iframeUrl = options.url;
  panelProps.shouldTranslate = options.shouldTranslateTitle;
  openPanel(panelName, panelProps, true);
};

const parseNumberParamToString = function (sizeParam) {
  if (typeof sizeParam === 'number') {
    sizeParam = sizeParam.toString();
  }
  return sizeParam;
};

const closeAppMarket = function (closePanelByName) {
  closePanelByName('tpa.compPanels.appMarketPanel');
};

const openAppMarketHelp = function (editorAPI) {
  const mergedBiParams = {
    learn_more: false,
    origin: 'panel',
    panel_name: 'tpa.compPanels.appMarketPanel',
  };
  editorAPI.panelManager.openHelpCenter(
    helpIds.EXTERNAL.APP_MARKET,
    null,
    mergedBiParams,
  );
  editorAPI.bi.event(bi.events.MARKET_HELP_CLICK);
};

const origin = function () {
  return coreUtilsLib.urlUtils.origin();
};

const getAppMarketTargetOrigin = function () {
  return TARGET_ORIGIN;
};

const getAppComponents = (app: AppData): AnyFixMe[] =>
  app?.appFields?.appService?.components ||
  app?.data?.components ||
  app?.components ||
  [];

const isPlatformType = (app: AppData): boolean =>
  Boolean(app?.appFields?.platform) ||
  getAppComponents(app).some(
    (comp) => comp.type === 'PLATFORM' || comp.compType === 'PLATFORM',
  );

const isWidgetType = (app: AppData): boolean =>
  Object.values(app?.widgets || {}).some(
    (widget) => !widget.appPage && !!widget.default,
  );

const isSectionType = (app: AppData): boolean =>
  Object.values(app?.widgets || {}).some(
    (widget) => !!widget.appPage && !widget.appPage.hidden,
  );

const isBuilderType = (app: AppData): boolean =>
  getAppComponents(app).some(
    (comp) => comp.type === 'STUDIO' || comp.compType === 'STUDIO_WIDGET',
  );

const isHeadlessInstallation = (app) => {
  const components = getAppComponents(app);
  return (
    _.get(app, 'appFields.platform.platformOnly') ||
    _.get(
      components.find(
        (comp) => comp.type === 'PLATFORM' || comp.compType === 'PLATFORM',
      ),
      'data.platformOnly',
    )
  );
};

const getAppType = function (app: AppData): string {
  if (isHeadlessInstallation(app)) {
    return constants.APP.TYPE.PLATFORM_ONLY;
  } else if (isSectionType(app)) {
    return constants.APP.TYPE.SECTION;
  } else if (isBuilderType(app)) {
    return constants.APP.TYPE.PLATFORM_ONLY;
  } else if (isWidgetType(app)) {
    return constants.APP.TYPE.WIDGET;
  } else if (isPlatformType(app)) {
    return constants.APP.TYPE.PLATFORM_ONLY;
  }
  return constants.APP.TYPE.WIDGET;
};

function canApplicationBeInstalledSeveralTimes(appData: AppData): boolean {
  if (getAppType(appData) !== constants.APP.TYPE.WIDGET) {
    return false;
  }

  return appData?.widgets?.every((widget) => !widget.addOnlyOnce);
}

export {
  openAppMarketModal,
  closeAppMarket,
  openAppMarketHelp,
  shouldOpenAppMarketTab,
  getAppMarketUrlParams,
  getAppMarketOpenPanelUrlParams,
  getAppMarketPersistedUserPrefs,
  setAppMarketPersistedUserPrefs,
  getAppMarketSessionUserPrefs,
  setAppMarketSessionUserPrefs,
  origin,
  getAppMarketTargetOrigin,
  getAppType,
  isBuilderType,
  isHeadlessInstallation,
  canApplicationBeInstalledSeveralTimes,
};

import type { EditorAPI } from '#packages/editorAPI';
import type { LiveSection } from './types';
import {
  addPanelUtils,
  componentsDefaultData,
  liveComponentUtils,
} from '#packages/addPanelInfra';

interface ComponentStyle {
  id: string;
}

interface EditorAPIWithAddPanelInfra extends EditorAPI {
  // add panel infra is loaded async, so isn't present in EditorAPI definitions
  addPanelInfra: EditorAPI['addPanelInfra'] & {
    addPanelStyleManager: {
      getComponentThemeStyles(
        comType: string,
        themeModule: EditorAPI['theme'],
        componentModule: EditorAPI['components'],
      ): ComponentStyle[];
    };
  };
}

export const createLiveSectionAPI = (editorAPI: EditorAPIWithAddPanelInfra) => {
  const getFirstItemStructure = (section: LiveSection) => {
    const [firstCompType] = section.props.compTypes;
    const defaultCompData =
      componentsDefaultData.getComponentDefault(firstCompType);

    if (!editorAPI.addPanelInfra) {
      throw new Error(
        'editorAPI.addPanelInfra is not present. AddPanel editorAPI extensions are not yet loaded',
      );
    }

    const styles =
      editorAPI.addPanelInfra.addPanelStyleManager.getComponentThemeStyles(
        firstCompType,
        editorAPI.theme,
        editorAPI.components,
      );

    return liveComponentUtils.prepareCompData({
      ...defaultCompData,
      key: firstCompType,
      compType: firstCompType,
      style: styles[0].id,
    });
  };

  return {
    async add(section: LiveSection) {
      return addPanelUtils.attemptToAddComponent(
        editorAPI,
        getFirstItemStructure(section),
      );
    },
  };
};

import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';
const { setSiteUserPreferences } = stateManagement.userPreferences.actions;
const { getSiteUserPreferences } = stateManagement.userPreferences.selectors;
import constants from '#packages/constants';
import type { MapStateToProps, MapDispatchToProps } from 'types/redux';
import type { BiEventDefinition, BiEventFields } from 'types/bi';
const { getMobileFramePosition } = stateManagement.mobile.selectors;
const MOBILE_FRAME_FOOTER_HEIGHT = 25;
const QRCODE_EXPOSER_MARGIN_LEFT = 60;
const MOBILE_FRAME_SIDER_WIDTH = 3;
export interface QrCodeExposerStateProps {
  qrCodeUrl?: string;
  shouldQrCodeExposerPanelInitiallyOpen: boolean;
  qrCodeExposerBottomPosition: number;
  qrCodeExposerLeftPosition: number;
}

export interface QrCodeExposerDispatchProps {
  openHelpCenter: (helpId: string) => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
  onComponentMount: () => void;
}

export const mapStateToProps: MapStateToProps<QrCodeExposerStateProps, {}> = ({
  state,
}) => {
  const userSeenPreviewQRCodePanel = getSiteUserPreferences(
    constants.USER_PREFS.PREVIEW.USER_SEEN_PREVIEW_QRCODE_PANEL,
  )(state);
  const mobileFramePosition = getMobileFramePosition(state);
  const mobileFramePositionTop = mobileFramePosition?.top || 0;
  const mobileFramePositionHeight = mobileFramePosition?.height || 0;
  const mobileFramePositionLeft = mobileFramePosition?.left || 0;
  const mobileFramePositionWidth = mobileFramePosition?.width || 0;

  return {
    qrCodeUrl: stateManagement.savePublish.selectors.getQrCodeUrl(state),
    shouldQrCodeExposerPanelInitiallyOpen: !userSeenPreviewQRCodePanel,
    qrCodeExposerBottomPosition:
      window.innerHeight -
      (mobileFramePositionTop +
        mobileFramePositionHeight +
        MOBILE_FRAME_FOOTER_HEIGHT),
    qrCodeExposerLeftPosition:
      mobileFramePositionLeft +
      mobileFramePositionWidth +
      QRCODE_EXPOSER_MARGIN_LEFT +
      MOBILE_FRAME_SIDER_WIDTH * 2,
  };
};

const getEditorAPI = (
  dispatch: AnyFixMe,
  getState: AnyFixMe,
  { editorAPI }: AnyFixMe,
): EditorAPI => editorAPI;

export const mapDispatchToProps: MapDispatchToProps<
  QrCodeExposerDispatchProps,
  {}
> = (dispatch) => {
  const editorAPI = dispatch(getEditorAPI);

  return {
    openHelpCenter: editorAPI.panelManager.openHelpCenter,
    sendBi: editorAPI.bi.event,
    onComponentMount: () => {
      dispatch(
        setSiteUserPreferences(
          constants.USER_PREFS.PREVIEW.USER_SEEN_PREVIEW_QRCODE_PANEL,
          true,
        ),
      );
    },
  };
};

import * as tpa from '#packages/tpa';
import * as appManager from '#packages/appManager';
import { EditorParamsApiKey } from '#packages/apis';

import bookingsPanel from './appMenu/bookingsPanel';
import dataProvider from './common/dataProvider';
import editorActionsService from './common/editorActionsService';
import wixBookingsAddPanelSections from './addPanel/wixBookingsAddPanelSections';
import singleServiceWidget from './addPanel/singleServiceWidget/singleServiceWidget';
import offeringListWidget from './addPanel/offeringListWidget/offeringListWidget';
import timetableWidget from './addPanel/timetableWidget/timetableWidget';
import weeklyTimetableWidget from './addPanel/weeklyTimetableWidget/weeklyTimetableWidget';
import dailyAgendaWidget from './addPanel/dailyAgendaWidget/dailyAgendaWidget';
import calendarWidget from './addPanel/calendarWidget/calendarWidget';
import bookButtonWidget from './addPanel/bookButtonWidget/bookButtonWidget';
import staffListWidget from './addPanel/staffListWidget/staffListWidget';
import wixBookingsDeepLinkService from './common/wixBookingsDeepLinkService';
import * as userPrefsService from './common/userPrefsService';
import * as serviceGenerator from './common/dynamic-content/service-generator';
import * as stateManagement from '#packages/stateManagement';

import type { EditorAPI } from '#packages/editorAPI';

async function setupPromotionalMethods(editorAPI: EditorAPI): Promise<void> {
  const actionService = editorActionsService({ editorAPI });
  const editorParamsApi = editorAPI.host.getAPI(EditorParamsApiKey);

  const hasPreInstallationIntent =
    !stateManagement.platform.selectors.shouldInstallPendingSilently(
      editorAPI,
    ) &&
    !editorParamsApi.siteCreationWizard &&
    !editorParamsApi.siteGenerationWizard &&
    (await userPrefsService.hasPreInstallationIntent(editorAPI));

  if (hasPreInstallationIntent) {
    actionService.setBookingsPreInstallationIntent();
  }

  await appManager.init(editorAPI);

  await actionService.register6thButton();

  tpa.superApps.registerOnAppInstalled(
    dataProvider.WIX_BOOKINGS_APP_DEF_ID,
    actionService.onAppInstalled,
  );
  tpa.superApps.registerOnAppDeleted(
    dataProvider.WIX_BOOKINGS_APP_DEF_ID,
    actionService.onAppDeleted,
  );
}

async function onPreviewReady(editorAPI: EditorAPI) {
  const actionService = editorActionsService({ editorAPI });
  const deepLinkService = wixBookingsDeepLinkService({ editorAPI });

  const bookingsPromotionalMethodsPromise = setupPromotionalMethods(editorAPI);

  actionService.registerPreInstallationToWelcomeScreen(
    bookingsPromotionalMethodsPromise,
  );

  await bookingsPromotionalMethodsPromise;

  deepLinkService.handle();
}

const getBookingsEditorActionService = (editorAPI: AnyFixMe) =>
  editorActionsService({ editorAPI });

const getBookingsDataProvider = () => dataProvider;

const isWixBookingsInstalled = (editorAPI: AnyFixMe) =>
  dataProvider.isWixBookingsInstalled(editorAPI);

const panels = {
  bookingsPanel,
};

export {
  onPreviewReady,
  getBookingsEditorActionService,
  getBookingsDataProvider,
  isWixBookingsInstalled,
  wixBookingsAddPanelSections,
  singleServiceWidget,
  staffListWidget,
  offeringListWidget,
  timetableWidget,
  weeklyTimetableWidget,
  dailyAgendaWidget,
  calendarWidget,
  bookButtonWidget,
  panels,
  serviceGenerator,
};

import React from 'react';
import * as CompPanelInfra from '#packages/compPanelInfra';
import { BackButton, Button, Composites } from '@wix/wix-base-ui';
import * as BaseUI from '#packages/baseUI';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import {
  CustomizeGrowPanel,
  CustomizeShrinkPanel,
  CustomizeSkewPanel,
  CustomizeRotatePanel,
  CustomizeAllPanel,
} from './customize/index';
import { mapDispatchToProps, mapStateToProps } from './effectsPanelMapper';

import { EFFECTS, EffectTypes } from './effects';
import { getDiff } from './effectsPanelBIUtils';

import type {
  IOrigin,
  ISkew,
  ITransformationData,
} from '../../interactionsTransformFacade';
import type { SendBiFunction } from 'types/bi';
const DIFF_SCALE_MULTIPLIER = 100;

export interface EffectsPanelOwnProps {}

export interface EffectsPanelOwnProps {
  frameProps: Object;
}

export interface EffectsPanelDispatchProps {
  grow(size?: number, origin?: IOrigin): void;
  shrink(size?: number, origin?: IOrigin): void;
  skew(direction?: ISkew, origin?: IOrigin): void;
  rotate(degrees?: number, origin?: IOrigin): void;
  customTransform(transformationData: ITransformationData): Promise<void>;
  reset(): void;
  sendBI: SendBiFunction;
}

export interface EffectsPanelStateProps {
  transformation: ITransformationData;
  scaleValue?: number;
  rotateValue?: number;
  skewValue?: ISkew;
  origin?: IOrigin;
  variantId: string;
  selectedEffect: EffectTypes;
}

export type IEffectsPanelProps = EffectsPanelOwnProps &
  EffectsPanelDispatchProps &
  EffectsPanelStateProps;

interface IEffectsPanelState {
  isCustomizingEffect: boolean;
  isCustomizeAll: boolean;
  activeEffect: EffectTypes;
  initialTransformation: ITransformationData;
  initialEffect: EffectTypes;
}

const HELP_ARTICLE_IDS = {
  SINGLE_EFFECT: '7dcd02c5-ca4e-4ed9-8876-871a7e3fe562',
  CUSTOM_EFFECT: '4cdc403c-9ca1-4559-86b9-b8700fa31c95',
};

class PureEffectsPanel extends React.Component<
  IEffectsPanelProps,
  IEffectsPanelState
> {
  constructor(props: IEffectsPanelProps) {
    super(props);

    this.state = {
      isCustomizingEffect: false,
      isCustomizeAll: false,
      activeEffect: null,
      initialTransformation: this.props.transformation,
      initialEffect: this.props.selectedEffect,
    };
  }

  componentWillUnmount(): void {
    const diff: AnyFixMe = getDiff(
      this.state.initialTransformation,
      this.props.transformation,
      this.state.initialEffect,
      this.props.selectedEffect,
    );

    if (!_.isEmpty(diff)) {
      if (diff.scale) {
        const scaleToFixed = (diff.scale * DIFF_SCALE_MULTIPLIER).toFixed(0);
        diff.scale = scaleToFixed;
      }
      this.props.sendBI(
        coreBi.events.interactions.effects_panel_behavior_saved,
        {
          ...diff,
          interaction_id: this.props.variantId,
        },
      );
    }
  }

  isCustomizeEffectPanelVisible = () => {
    return this.state.isCustomizingEffect;
  };

  setEffect(effect: EffectTypes) {
    switch (effect) {
      case EffectTypes.GROW:
        this.props.grow();
        break;
      case EffectTypes.SHRINK:
        this.props.shrink();
        break;
      case EffectTypes.ROTATE:
        this.props.rotate();
        break;
      case EffectTypes.SKEW:
        this.props.skew();
        break;
      case EffectTypes.CUSTOM:
        this.props.customTransform(this.props.transformation).then(() => {
          this.setState({
            isCustomizingEffect: true,
            isCustomizeAll: true,
          });
        });
        break;
      default:
        this.props.reset();
    }
  }

  handleEffectClick = (effect: EffectTypes) => {
    if (effect === EffectTypes.NONE) {
      this.props.sendBI(
        coreBi.events.interactions.effects_panel_behavior_deleted,
        {
          interaction_id: this.props.variantId,
          deleted_behavior_name: this.props.selectedEffect,
        },
      );
    } else {
      this.props.sendBI(
        coreBi.events.interactions.effects_panel_behavior_click,
        {
          interaction_id: this.props.variantId,
          behavior_name: effect,
        },
      );
    }
    this.setEffect(effect);
  };

  handleCustomizeClick = () => {
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_customize_click,
      {
        interaction_id: this.props.variantId,
        behavior_name: this.props.selectedEffect,
      },
    );
    this.setState({
      isCustomizingEffect: true,
    });
  };

  handleBackButtonClick = () => {
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_back_from_custom_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: this.props.selectedEffect,
      },
    );

    this.setState({
      isCustomizingEffect: false,
      isCustomizeAll: false,
      activeEffect: null,
    });
  };

  renderCustomizeEffectPanel(effect: EffectTypes) {
    switch (effect) {
      case EffectTypes.GROW:
        return (
          <CustomizeGrowPanel
            grow={this.props.grow}
            scaleValue={this.props.scaleValue}
            origin={this.props.origin}
            sendBI={this.props.sendBI}
            variantId={this.props.variantId}
          />
        );
      case EffectTypes.SHRINK:
        return (
          <CustomizeShrinkPanel
            shrink={this.props.shrink}
            scaleValue={this.props.scaleValue}
            origin={this.props.origin}
            sendBI={this.props.sendBI}
            variantId={this.props.variantId}
          />
        );
      case EffectTypes.SKEW:
        return (
          <CustomizeSkewPanel
            skew={this.props.skew}
            skewValue={this.props.skewValue}
            origin={this.props.origin}
            sendBI={this.props.sendBI}
            variantId={this.props.variantId}
          />
        );
      case EffectTypes.ROTATE:
        return (
          <CustomizeRotatePanel
            rotate={this.props.rotate}
            rotateValue={this.props.rotateValue}
            origin={this.props.origin}
            sendBI={this.props.sendBI}
            variantId={this.props.variantId}
          />
        );
      default:
        return (
          <CustomizeAllPanel
            customTransform={this.props.customTransform}
            transformation={this.props.transformation}
            sendBI={this.props.sendBI}
            variantId={this.props.variantId}
          />
        );
    }
  }

  render() {
    const isCustomizeEffectPanelVisible = this.isCustomizeEffectPanelVisible();

    return (
      <CompPanelInfra.compPanelFrame
        title={
          isCustomizeEffectPanelVisible
            ? 'interactions_effects_customize_header'
            : 'interactions_effects_header'
        }
        helpId={
          this.state.isCustomizeAll
            ? HELP_ARTICLE_IDS.CUSTOM_EFFECT
            : HELP_ARTICLE_IDS.SINGLE_EFFECT
        }
        automationId="effects-panel"
        contentClass="effects-panel-content"
        {...this.props.frameProps}
      >
        {isCustomizeEffectPanelVisible && [
          <BackButton
            label={'interactions_effects_customize_back_button'}
            onClick={this.handleBackButtonClick}
          />,
          this.renderCustomizeEffectPanel(
            !this.state.isCustomizeAll && this.props.selectedEffect,
          ),
        ]}
        {!isCustomizeEffectPanelVisible && [
          <BaseUI.thumbnails
            value={this.props.selectedEffect}
            onChange={this.handleEffectClick}
            maxThumbsPerRow={3}
            options={EFFECTS}
          />,

          <div
            style={{
              /* :( */
              height: '84px',
              width: '100%',
            }}
          >
            <Composites.ButtonLargeFixedBottom>
              <Button
                disabled={this.props.selectedEffect === EffectTypes.NONE}
                onClick={this.handleCustomizeClick}
                automationId={'customize-effect-button'}
              >
                {translate('interactions_effects_tab_animations_primary_CTA')}
              </Button>
            </Composites.ButtonLargeFixedBottom>
          </div>,
        ]}
      </CompPanelInfra.compPanelFrame>
    );
  }
}

const effectsPanel = _.flow(
  util.hoc.connect(
    util.hoc.STORES.EDITOR_API,
    mapStateToProps,
    mapDispatchToProps,
  ),
  stateManagement.components.hoc.compPanel,
)(PureEffectsPanel);

export { effectsPanel };

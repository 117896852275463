import { biLogger } from '#packages/util';
import type { ImageOrigin } from './addCompsHandlers/addImageHandler';
import {
  editorMediaUploadStarted,
  editorNotificationMediaUpload,
} from '@wix/bi-logger-editor/v2';
import { TRANSLATION_KEYS } from './consts';

export const getBiOrigin = (origin: ImageOrigin) => {
  return origin === 'paste_file'
    ? 'editor_shortcut_paste_on_stage'
    : 'editor_drag_and_drop_on_stage';
};

export const reportSuccessToBi = (
  origin: ImageOrigin,
  blob: File,
  filesInBatch: number,
) =>
  biLogger.report(
    editorNotificationMediaUpload({
      origin: getBiOrigin(origin),
      file_size: Math.round(blob.size / 1024),
      file_format: blob.name.split('.').pop(),
      message: TRANSLATION_KEYS.UPLOAD_SUCCESS,
      type: 'success',
      batch: filesInBatch > 1,
      batch_size: filesInBatch,
    }),
  );

export const reportErrorToBi = (
  origin: ImageOrigin,
  blob: File,
  filesInBatch: number,
) =>
  biLogger.report(
    editorNotificationMediaUpload({
      origin: getBiOrigin(origin),
      file_size: Math.round(blob.size / 1024),
      file_format: blob.name.split('.').pop(),
      message: TRANSLATION_KEYS.ERROR_UPLOADING_FILE,
      type: 'upload_failed',
      batch: filesInBatch > 1,
      batch_size: filesInBatch,
    }),
  );

export const reportUploadStartedToBi = (
  origin: ImageOrigin,
  blob: File,
  filesInBatch: number,
  sectionId: string,
) =>
  biLogger.report(
    editorMediaUploadStarted({
      origin: getBiOrigin(origin),
      file_size: Math.round(blob.size / 1024),
      file_format: blob.name.split('.').pop(),
      target_component_type: 'section',
      target_component_id: sectionId,
      batch: filesInBatch > 1,
      batch_size: filesInBatch,
    }),
  );

export const reportUnsupportedFileTypeToBi = (
  origin: ImageOrigin,
  blob: File,
  filesInBatch: number,
) =>
  biLogger.report(
    editorNotificationMediaUpload({
      origin: getBiOrigin(origin),
      file_size: Math.round(blob.size / 1024),
      file_format: blob.name.split('.').pop(),
      message: TRANSLATION_KEYS.UNSUPPORTED_FILE,
      type: 'incorrect_file_type',
      batch: filesInBatch > 1,
      batch_size: filesInBatch,
    }),
  );

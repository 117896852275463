// @ts-nocheck
import * as core from '#packages/core';

export default {
  mixins: [core.mixins.editorAPIMixin],

  getPageData() {
    return this.props.pageData || this.props.menuItem.pageData;
  },

  getPageId() {
    const pageData = this.getPageData();
    return pageData ? pageData.id : null;
  },

  getValueFromPageData(prop) {
    const pageData = this.getPageData();
    return pageData?.[prop];
  },

  linkPageProperty(prop) {
    const pageId = this.getPageId();
    const editorAPI = this.getEditorAPI();
    const newObjValue = {};

    return {
      value: this.getValueFromPageData(prop),
      requestChange(newValue) {
        editorAPI.history.add('page property change');
        newObjValue[prop] = newValue;
        editorAPI.pages.data.update(pageId, newObjValue);
      },
    };
  },

  linkInversePageProperty(prop) {
    const pageId = this.getPageId();
    const editorAPI = this.getEditorAPI();
    const newObjValue = {};

    return {
      value: !this.getValueFromPageData(prop),
      requestChange(newValue) {
        newObjValue[prop] = !newValue;
        editorAPI.pages.data.update(pageId, newObjValue);
      },
    };
  },
};

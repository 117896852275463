import type { Scope } from '#packages/sections';
import { fixSingleSectionOverheight } from './fixSingleSectionOverheight';
import { ErrorReporter } from '@wix/editor-error-reporter';

type PageNavigationHook = (pageId: string) => Promise<void>;

export const createAfterPageNavigateEnforcement = (
  scope: Scope,
): PageNavigationHook => {
  return async (pageId: string) => {
    const { editorAPI } = scope;
    const pageRef = editorAPI.pages.getReference(pageId);

    try {
      await fixSingleSectionOverheight(scope, pageRef, false);
    } catch (error: MaybeError) {
      error.name = `[SectionsAfterNavigateEnforcement]: ${error.name}`;
      ErrorReporter.captureException(error);
    }
  };
};

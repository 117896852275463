import appsPages, { type PagesGrouped } from './appsPages';
import popupsPages, { type IPopup } from './popupsPages';
import menusPages, { type IPagesPanelMenu } from './menusPages';
import routerPages, { type IRouter } from './routerPages';
import { openAddPagePanel } from './openAddPagePanel';
import { closeAddPagePanel } from './closeAddPagePanel';

import type { EditorAPI } from '#packages/editorAPI';

const getFullPagesStructure = (
  editorAPI: EditorAPI,
  isFlatMenusView?: boolean,
): {
  menus: IPagesPanelMenu[];
  routers: IRouter[];
  appPages: PagesGrouped[];
  popups: IPopup[];
} => {
  const pagesData = editorAPI.dsRead.pages.getPagesData();
  const appPages = appsPages.getAppPages(editorAPI, pagesData);
  const pageIdsToIgnore = appPages.reduce((acc: string[], app) => {
    app.pagesData?.forEach(({ id, replacerOf }) => {
      if (replacerOf) {
        acc.push(id);
      }
    });
    return acc;
  }, []);

  const pagesAppDefId = appPages.reduce(
    (acc, { id }) => ({ ...acc, [id]: true }),
    {},
  );

  return {
    appPages,
    menus: menusPages.getMenus(editorAPI, pagesData, isFlatMenusView),
    routers: routerPages.getRouters(editorAPI, pagesAppDefId, pageIdsToIgnore),
    popups: popupsPages.getPopups(editorAPI, pagesAppDefId),
  };
};

export default {
  popups: {
    getPages: popupsPages.getPopups,
    getActions: popupsPages.getPopupActions,
  },
  appPages: {
    getPages: appsPages.getAppPages,
    getActions: appsPages.getAppPagesDataWithActions,
  },
  menus: {
    getPages: menusPages.getMenus,
    getActions: menusPages.getMenuItemActions,
  },
  routers: {
    getPages: routerPages.getRouters,
    getActions: routerPages.getRouterPagesWithActions,
  },
  getFullPagesStructure,
  openAddPagePanel,
  closeAddPagePanel,
};

import type { GFPPData, GFPPActionType } from '@wix/editor-component-types';
import constants from '#packages/constants';

export const GFPP_ACTIONS = constants.ROOT_COMPS.GFPP.ACTIONS;

export const ENABLED_BY_METADATA = [
  GFPP_ACTIONS.ACCESSIBILITY,
  GFPP_ACTIONS.PIN_MODE,
  GFPP_ACTIONS.STRETCH,
  GFPP_ACTIONS.APPLY_TO_OTHER_VIEW,
  GFPP_ACTIONS.INTERACTIONS,
  GFPP_ACTIONS.INTERACTION_HIDE,
  GFPP_ACTIONS.TRANSITION,
  GFPP_ACTIONS.CONNECT,
].filter(Boolean);

const DEFAULT_ENABLED_ACTIONS = (
  [
    GFPP_ACTIONS.SETTINGS,
    GFPP_ACTIONS.LAYOUT,
    GFPP_ACTIONS.ANIMATION,
    GFPP_ACTIONS.HELP,
    GFPP_ACTIONS.STYLABLE_LAYOUT,
    GFPP_ACTIONS.STYLABLE,
  ] as GFPPActionType[]
).concat(ENABLED_BY_METADATA);

export const DEFAULT_GFPP_DATA: Partial<GFPPData> = {
  enabledActions: DEFAULT_ENABLED_ACTIONS,
};

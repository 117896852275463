import { createReduxStore } from '#packages/apilib';
import type { CompRef } from 'types/documentServices';

export interface FPSMeasurementState {
  fpsPageContext: PageContextState;
}

export interface PageContextState {
  managingAppDefId?: string;
  components: { [key: string]: TpaComponentState };
}

interface TpaComponentState {
  managingAppDefId?: string;
  compRef: CompRef;
}

const getInitialState = (): FPSMeasurementState => ({
  fpsPageContext: {
    components: {},
  },
});

export const MeasureFrameRateStore = createReduxStore({
  getInitialState,
  selectors: {
    getPageContext: (state: FPSMeasurementState) => {
      return state?.fpsPageContext;
    },
  },
  actions: {
    setPageContext: (_: FPSMeasurementState, pageContext: PageContextState) => {
      return {
        fpsPageContext: pageContext,
      };
    },
  },
});

export type MeasureFrameRateStore = ReturnType<typeof MeasureFrameRateStore>;

// @ts-nocheck
import * as core from '#packages/core';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as util from '#packages/util';
import * as helpIds from '#packages/helpIds';
import * as stateManagement from '#packages/stateManagement';
import React from 'react';
import * as compPanelInfra from '#packages/compPanelInfra';
import DesignDataPanel from './designDataPanelComponent';
import { cx } from '#packages/util';

const { panelPosition } = stateManagement.domMeasurements.hoc;

// eslint-disable-next-line react/prefer-es6-class
const DesignDataPanelStandalone = createReactClass({
  displayName: 'designDataPanelStandalone',
  mixins: [core.mixins.editorAPIMixin],

  getPanelFrameProps() {
    let props = util.panelUtils.getFrameProps(this.props);

    props.helpId = helpIds.UTILS.getCustomizeDesignHelpId(this.props.compType);

    if (props.overrideTitle) {
      props.title = props.overrideTitle;
      props = _.omit(props, ['overrideTitle']);
    }

    return props;
  },
  render() {
    return (
      <compPanelInfra.compPanelFrame
        id="customizeDesignPanel"
        title="title"
        helpId={``}
        {...this.getPanelFrameProps()}
        className={cx(
          'design-data-panel-frame',
          this.getPanelFrameProps()?.className,
        )}
      >
        <DesignDataPanel {...this.props} />
      </compPanelInfra.compPanelFrame>
    );
  },
});

export default panelPosition()(DesignDataPanelStandalone);

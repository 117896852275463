import * as stateManagement from '#packages/stateManagement';
import { fedopsLogger } from '#packages/util';

import type {
  MapDispatchToProps,
  MapStateToProps,
  StateMapperArgs,
  ThunkAction,
} from 'types/redux';
import { tracer } from './tracer';
import type { PreviewFrameOwnProps } from './previewFrame';
import type { NotifyPreviewReady, PreviewPositions } from './types';

const { isPerformingMouseMoveAction } = stateManagement.mouseActions.selectors;
const { getPreviewMode, getPreviewPointerEventsOn } =
  stateManagement.preview.selectors;
const { getStageLayout } = stateManagement.domMeasurements.selectors;

const mobileWizardSelectors = stateManagement.mobile.selectors.mobileWizard;

export interface PreviewFrameStateProps {
  siteX: any;
  isPreviewMode: boolean;
  siteHeight: number;
  pointerEventsOn: boolean;
  shouldHidePreviewOverflow: boolean;
  performingMouseMoveAction: boolean;
  clearTextSelectionEventCounter: number;
  stageLayout: stateManagement.StageLayout;
}

export const mapStateToProps: MapStateToProps<
  PreviewFrameStateProps,
  PreviewFrameOwnProps
> = (stateMapperArgs: StateMapperArgs) => {
  const { state: editorState, editorAPI } = stateMapperArgs;
  const { eventCounters } = editorState;

  const isMobileWizardEnabled =
    mobileWizardSelectors.getIsMobileWizardEnabled(editorState);
  const performingMouseMoveAction = isPerformingMouseMoveAction(editorState);

  const siteX = editorAPI.dsRead && editorAPI.site.getSiteX();

  const shouldHidePreviewOverflow =
    editorAPI.isMobileEditor() && !isMobileWizardEnabled;

  const isPreviewMode = getPreviewMode(editorState);
  const pointerEventsOn = getPreviewPointerEventsOn(editorState);

  const siteHeight = editorAPI.dsRead?.site.getHeight() || 0;

  return {
    isPreviewMode,
    pointerEventsOn,
    siteX,
    siteHeight,
    shouldHidePreviewOverflow,
    performingMouseMoveAction,
    clearTextSelectionEventCounter: eventCounters.clearTextSelection,
    // syncs preview with stage container during editor resize in fixed stage
    stageLayout: getStageLayout(editorAPI.store.getState()),
  };
};

const { updatePreviewPos, updateStageLayout } =
  stateManagement.domMeasurements.actions;

// TODO: refactor it, move to appropriate places
const notifyPreviewReady =
  (
    documentServices: AnyFixMe,
    createSantaPreview: AnyFixMe,
    compFactory: AnyFixMe,
  ): ThunkAction =>
  (dispatch, getState, { editorAPI }) => {
    fedopsLogger.appLoadingPhaseFinish('editor-load-viewer');
    fedopsLogger.appLoadingPhaseFinish('editor-load-document-service');
    tracer.trace('notifyPreviewReadyInMapper');
    editorAPI.onPreviewReady(documentServices, createSantaPreview, compFactory);
    tracer.trace('editorAPI.onPreviewReady');
    tracer.disable();
  };

export interface PreviewFrameDispatchProps {
  updatePreviewPos: (rect: PreviewPositions) => void;
  notifyPreviewReady: NotifyPreviewReady;
  updateStageLayout: () => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  PreviewFrameDispatchProps,
  PreviewFrameOwnProps
> = {
  notifyPreviewReady,
  updatePreviewPos,
  updateStageLayout,
};

import React, { type FunctionComponent, useCallback } from 'react';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';

import type { SendBiFunction } from 'types/bi';
import type { SaveInteractionStartedSource } from 'types/fedops/saveInteraction';

import withSendBi from '../../hocs/withSendBi';
import { mapStateToProps, mapDispatchToProps } from './upgradePanelMappers';

import UpgradeDropPanel from '../defaultUpgradePanel';
import DealerUpgradeDropPanel from '../dealerUpgradePanel';

import type { DealerViewerProps } from '../dealerUpgradePanel';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

export interface UpgradePanelStateProps {
  isFirstSave?: boolean;
  isDraftMode?: boolean;
  metaSiteId?: string;
  metaSiteInstance?: string;
  isActivePersonalSaleCampaign: boolean;
  personalSaleCampaignDiscountValue: number;
  dealerViewer?: React.ComponentType<DealerViewerProps>;
}

export interface UpgradePanelDispatchProps {
  save: (options: {
    origin?: string;
    sourceOfStart: SaveInteractionStartedSource;
  }) => void;
  upgrade: (referralAdditionalInfo: string) => void;
}

export interface UpgradePanelOwnProps {
  isExternalUpgrade?: boolean;
  fallbackToDefaultUpgradePanel: (error: string) => void;
  sendBi: SendBiFunction;
  closePanel: () => void;
  recalcPositions?: () => void;
}

type UpgradePanelProps = UpgradePanelDispatchProps &
  UpgradePanelStateProps &
  UpgradePanelOwnProps;

const UpgradePanel: FunctionComponent<UpgradePanelProps> = (
  props: UpgradePanelProps,
) => {
  const getPersonalSaleTranslatedLabel = useCallback(
    (label) => {
      return translate(label, {
        discount: props.personalSaleCampaignDiscountValue,
      });
    },
    [props.personalSaleCampaignDiscountValue],
  );

  const getPersonalSaleCampaignSymbolName = useCallback(
    (symbolPathPrefix) => {
      return `${symbolPathPrefix}_${props.personalSaleCampaignDiscountValue}`;
    },
    [props.personalSaleCampaignDiscountValue],
  );
  return props.isExternalUpgrade ? (
    <DealerUpgradeDropPanel
      dealerViewer={props.dealerViewer}
      metaSiteId={props.metaSiteId}
      metaSiteInstance={props.metaSiteInstance}
      sendBi={props.sendBi}
      fallbackToDefaultUpgradePanel={props.fallbackToDefaultUpgradePanel}
      isFirstSave={props.isFirstSave}
      isDraftMode={props.isDraftMode}
      recalcPositions={props.recalcPositions}
    />
  ) : (
    <UpgradeDropPanel
      save={props.save}
      isFirstSave={props.isFirstSave}
      isDraftMode={props.isDraftMode}
      sendBi={props.sendBi}
      accountUpgrade={props.upgrade}
      getPersonalSaleTranslatedLabel={getPersonalSaleTranslatedLabel}
      getPersonalSaleCampaignSymbolName={getPersonalSaleCampaignSymbolName}
      isActivePersonalSaleCampaign={props.isActivePersonalSaleCampaign}
      personalSaleCampaignDiscountValue={
        props.personalSaleCampaignDiscountValue
      }
      closePanel={props.closePanel}
    />
  );
};

const ConnectedUpgradePanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withSendBi,
)(UpgradePanel);

export default ConnectedUpgradePanel;

import { translate } from '#packages/i18n';
export default {
  type: 'Container',
  components: [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 285,
        height: 138,
        x: 112,
        y: 133,
        scale: 1,
        rotationInDegrees: 90,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'se33c',
      },
      parent: 'comp-kgt5wauz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
        text: `<h2 class="font_2" style="font-size: 43px;"><span style="font-size:43px;"><span style="color:#FFFFFF"><span style="font-family:roboto-bold,roboto,sans-serif">${translate(
          'interactions_preset_profile_name',
        )}</span></span></span></h2>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'se33c',
        },
        brightness: 1,
        packed: false,
        minHeight: 55,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 238,
          height: 41,
          x: 114,
          y: 115,
          scale: 1.0826666666666664,
          rotationInDegrees: 90,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'se33c',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wave',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      layout: {
        width: 193,
        height: 278,
        x: 18,
        y: 17,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WPhoto',
      metaData: {
        pageId: 'se33c',
      },
      parent: 'comp-kgt5wauz',
      data: {
        type: 'Image',
        metaData: {
          isPreset: false,
          schemaVersion: '2.0',
          isHidden: false,
          pageId: 'se33c',
        },
        title: '',
        uri: '11062b_6e3d122e15a046398fcbfb5f6780c979~mv2.jpg',
        description: '',
        width: 4000,
        height: 6000,
        alt: 'Playing Poker',
        name: 'Playing Poker',
      },
      props: {
        type: 'WPhotoProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'se33c',
        },
        displayMode: 'fill',
        onClickBehavior: 'goToLink',
        autoFill: false,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.6,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
      },
      transformations: {
        type: 'TransformData',
        origin: {
          x: {
            value: 0,
            type: 'percentage',
          },
          y: {
            value: 0,
            type: 'percentage',
          },
        },
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wavc': {
          type: 'TransformData',
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'se33c',
          },
          translate: {
            x: {
              type: 'px',
              value: -21.546583850931675,
            },
            y: {
              type: 'px',
              value: -18.03105590062112,
            },
          },
          scale: {
            x: 1.61,
            y: 1.61,
          },
          rotate: 0,
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
        style: {
          properties: {},
          propertiesSource: {},
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.WPhoto',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.photo.NoSkinPhoto',
      },
      mobileStructure: {
        layout: {
          width: 164,
          height: 238,
          x: 17,
          y: 16,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wave3',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
      layout: {
        width: 251,
        height: 48,
        x: 18,
        y: 318,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.WRichText',
      metaData: {
        pageId: 'se33c',
      },
      parent: 'comp-kgt5wauz',
      data: {
        type: 'StyledText',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
        text: `<p class="font_8" style="font-size: 12px; line-height: 1.4em;"><span style="font-size:12px;"><span style="color:#FFFFFF"><span style="font-family:barlow-extralight,barlow,sans-serif"><span style="letter-spacing:0.03em">${translate(
          'interactions_preset_profile_description',
        )}</span></span></span></span></p>`,
        stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        linkList: [],
      },
      props: {
        type: 'WRichTextProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
          pageId: 'se33c',
        },
        brightness: 1,
        packed: false,
        minHeight: 32,
        verticalText: false,
      },
      style: 'txtNew',
      mobileStructure: {
        layout: {
          width: 244,
          height: 54,
          x: 17,
          y: 272,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        props: {
          type: 'WRichTextProperties',
          metaData: {
            schemaVersion: '1.0',
            autoGenerated: false,
            pageId: 'se33c',
          },
          brightness: 1,
          packed: true,
          verticalText: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wavg4',
          author: 'studio',
        },
      },
    },
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.button.BasicButton',
      layout: {
        width: 130,
        height: 37,
        x: 18,
        y: 384,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      componentType: 'wysiwyg.viewer.components.SiteButton',
      metaData: {
        pageId: 'se33c',
      },
      parent: 'comp-kgt5wauz',
      data: {
        type: 'LinkableButton',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
        label: translate('interactions_preset_profile_CTA'),
      },
      props: {
        type: 'ButtonProperties',
        metaData: {
          schemaVersion: '1.0',
          pageId: 'se33c',
        },
        align: 'center',
        margin: 0,
      },
      transitions: {
        type: 'TransitionData',
        'timing-function': 'ease-in-out',
        duration: 0.4,
        delay: 0,
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
      },
      scopedTransformations: {
        'variants-kgt5wavc': {
          type: 'TransformData',
          rotate: 0,
          metaData: {
            isPreset: false,
            schemaVersion: '1.0',
            isHidden: false,
            pageId: 'se33c',
          },
          translate: {
            x: {
              type: 'px',
              value: 65,
            },
            y: {
              type: 'px',
              value: 0,
            },
          },
        },
      },
      style: {
        type: 'ComponentStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
          pageId: 'se33c',
        },
        style: {
          properties: {
            'boxShadowToggleOn-shd': 'false',
            bgh: '#FFFFFF',
            'alpha-brdh': '1',
            bg: '#FFFFFF',
            'alpha-brd': '1',
            brw: '0',
            fnt: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            brdd: '#CCCCCC',
            txt: '#F58144',
            'alpha-bgd': '1',
            rd: '0',
            brdh: '#423737',
            brd: '#423737',
            'alpha-bg': '1',
            'alpha-bgh': '0.71',
            txtd: '#FFFFFF',
            txth: '#F58144',
            bgd: '#CCCCCC',
            'alpha-brdd': '1',
          },
          propertiesSource: {
            'boxShadowToggleOn-shd': 'value',
            bgh: 'value',
            'alpha-brdh': 'value',
            bg: 'value',
            'alpha-brd': 'value',
            brw: 'value',
            fnt: 'value',
            shd: 'value',
            brdd: 'value',
            txt: 'value',
            'alpha-bgd': 'value',
            rd: 'value',
            brdh: 'value',
            brd: 'value',
            'alpha-bg': 'value',
            'alpha-bgh': 'value',
            txtd: 'value',
            txth: 'value',
            bgd: 'value',
            'alpha-brdd': 'value',
          },
          groups: {},
        },
        componentClassName: 'wysiwyg.viewer.components.SiteButton',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.button.BasicButton',
      },
      mobileStructure: {
        layout: {
          width: 120,
          height: 34,
          x: 17,
          y: 351,
          scale: 1,
          rotationInDegrees: 0,
          fixedPosition: false,
        },
        metaData: {
          originalCompId: 'comp-kgt5wavh2',
          author: 'studio',
        },
      },
    },
  ],
  layout: {
    width: 303,
    height: 443,
    x: 338,
    y: 39,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  componentType: 'mobile.core.components.Container',
  metaData: {
    pageId: 'se33c',
  },
  parent: 'se33c',
  variants: {
    'variants-kgt5wavc': {
      componentId: 'comp-kgt5wauz',
      type: 'Hover',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'se33c',
      },
    },
  },
  scopedTransformations: {
    'variants-kgt5wavc': {
      type: 'TransformData',
      rotate: 0,
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
        pageId: 'se33c',
      },
    },
  },
  style: {
    type: 'ComponentStyle',
    metaData: {
      isPreset: false,
      schemaVersion: '1.0',
      isHidden: false,
      pageId: 'se33c',
    },
    style: {
      properties: {
        'alpha-bg': '1',
        'alpha-brd': '1',
        bg: '#F58144',
        'boxShadowToggleOn-shd': 'false',
        brd: '#423737',
        brw: '0',
        rd: '0px',
        shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        'alpha-brd': 'value',
        bg: 'value',
        'boxShadowToggleOn-shd': 'value',
        brd: 'value',
        brw: 'value',
        rd: 'value',
        shd: 'value',
      },
      groups: {},
    },
    componentClassName: 'mobile.core.components.Container',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  },
  mobileStructure: {
    layout: {
      width: 280,
      height: 401,
      x: 20,
      y: 10,
      scale: 1,
      rotationInDegrees: 0,
      fixedPosition: false,
    },
    metaData: {
      originalCompId: 'comp-kgt5wauz',
      author: 'studio',
    },
  },
  activeModes: {},
};

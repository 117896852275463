import _ from 'lodash';
import { isAdvancedMenuOpen, isCustomMenusEnabled } from '#packages/util';
import {
  PAGES_MENU_ID,
  MENUS_USAGE_TYPES,
  type SiteMenusType,
} from '../constants';
import { isMembersMenuId } from '../utils/utils';
import type { siteMenusContentParams } from '@wix/bi-logger-editor/v2/types';
import type { CompRef, MenuData } from '@wix/document-services-types';
import type { EditorAPI } from '#packages/editorAPI';
import type { IPageMenuItem } from '../menuWrapper';
import type { IMenuAPI, MenuAPI } from '../API/menuAPI';

export interface DataForMigration {
  siteMenusType: SiteMenusType;
  menusList: MenuData[];
  pagesMenu: MenuData;
  headerMenu: MenuData | undefined;
  mobileMasterMenu: MenuData | undefined;
}

export interface MenusUsageData {
  dataForMigration: DataForMigration;
  biData: siteMenusContentParams;
}

export const getMenuSetsFromComponents = (
  menuAPI: IMenuAPI,
  rootComp?: CompRef,
) => {
  const menusByComps = menuAPI
    .getMenuComponents(rootComp)
    .reduce<Record<string, MenuData>>((list, comp) => {
      const menu = menuAPI.getMenuByCompRef(comp);
      return isMembersMenuId(menu.id)
        ? list
        : {
            ...list,
            [comp.id]: menu,
          };
    }, {});

  const menusList = Object.values(menusByComps);

  return { menusByComps, menusList };
};

export const getMenusUsageData = (
  editorAPI: EditorAPI,
  menuAPI: MenuAPI,
): MenusUsageData => {
  const pagesMenu = menuAPI.getPagesMenu();

  const { menusList } = getMenuSetsFromComponents(menuAPI);
  const menuCompsNumber = menusList.length;

  const { items: pagesMenuItems, pagesCount: pagesNumber } =
    editorAPI.menus.getExtendedMenuItemsTree(pagesMenu);

  const isExposedToAdvancedMenus = isAdvancedMenuOpen();
  const isExposedToCustomMenus = isCustomMenusEnabled();

  const uniqSetsNumber = _.uniqBy(menusList, 'id').length;

  let siteMenusType: SiteMenusType = MENUS_USAGE_TYPES.NEITHER;
  let customMenusNumber = 0;
  let advancedMenusNumber = 0;
  let headerMenu;
  let mobileMasterMenu;

  const notEmptyItemsNumber = pagesMenuItems.filter(
    (pagesMenuItem: IPageMenuItem) => pagesMenuItem.items.length,
  ).length;

  if (isExposedToAdvancedMenus) {
    advancedMenusNumber = menusList.filter(
      (menu) => menu.id !== PAGES_MENU_ID,
    ).length;

    siteMenusType = MENUS_USAGE_TYPES.ADVANCED;
  } else if (isExposedToCustomMenus) {
    customMenusNumber = menusList.filter((menu) => !menu.syncWithPages).length;

    const isOnlyOneMenuInHeader = () => {
      const headerRef = editorAPI.siteSegments.getHeader();
      const { menusList: headerMenus } = getMenuSetsFromComponents(
        menuAPI,
        headerRef,
      );
      if (headerMenus.length === 1) {
        headerMenu = _.head(headerMenus);
        return true;
      }
      return false;
    };

    const isSameMenuOnMobileAndDesktop = () => {
      const mobileMenusIds = menuAPI
        .getMobileMenuComponents()
        .map((comp) => menuAPI.getMenuByCompRef(comp).id);

      mobileMasterMenu = menusList.find((menu) =>
        mobileMenusIds.includes(menu.id),
      );
      return !!mobileMasterMenu;
    };

    const isProUser =
      notEmptyItemsNumber > 0 ||
      (uniqSetsNumber > 1 &&
        !isOnlyOneMenuInHeader() &&
        !isSameMenuOnMobileAndDesktop());

    siteMenusType = isProUser
      ? MENUS_USAGE_TYPES.CUSTOM_PRO
      : MENUS_USAGE_TYPES.CUSTOM;
  }

  return {
    biData: {
      siteType: siteMenusType,
      is_advanced_menu: isExposedToAdvancedMenus,
      is_custom_menu_exposed: isExposedToCustomMenus,
      is_advanced_menu_in_use: !!advancedMenusNumber,
      is_custom_menu_in_use: !!customMenusNumber,
      numMenus: menuCompsNumber,
      numMenusetsUnique: uniqSetsNumber,
      numPages: pagesNumber,
      numFolder: notEmptyItemsNumber,
      // menusetsComponents: JSON.stringify(menusByComps), //it's 500kb which is too much for BI
    },
    dataForMigration: {
      siteMenusType,
      headerMenu,
      mobileMasterMenu,
      menusList,
      pagesMenu,
    },
  };
};

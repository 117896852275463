import { BasePublicApi } from '#packages/apilib';
import constants from '#packages/constants';
import { isSectionizerEnabled } from './utils';

import type { SectionizerScope as Scope } from './scope';

async function sectionizerValidationPromt(scope: Scope) {
  if (!isSectionizerEnabled()) {
    return;
  }

  const { documentServices } = scope.editorAPI;
  const unlabeledPages = scope.sectionsMigrationApi.getUnlabeledPages();

  if (unlabeledPages.length === 0) {
    return;
  }

  const unlabeledPageTitles = unlabeledPages.map(({ id }) =>
    documentServices.pages.getPageTitle(id),
  );

  return new Promise((resolve, reject) => {
    scope.editorAPI.panelManager.openPanel(
      'sectionizer.panels.sectionizerValidationPromt',
      {
        onConfirm: resolve,
        onCancel: reject,
        pageTitles: unlabeledPageTitles,
      },
    );
  });
}

async function saveSectionizedTemplatePromt(scope: Scope) {
  if (!isSectionizerEnabled()) {
    return;
  }

  const { editorAPI } = scope;
  const isAnySectionsOnTheSite =
    editorAPI.components.get.byType(constants.COMP_TYPES.SECTION).length > 0;

  if (!isAnySectionsOnTheSite) {
    return;
  }

  return new Promise((resolve, reject) => {
    scope.editorAPI.panelManager.openPanel(
      'sectionizer.panels.saveSectionizedTemplatePromt',
      {
        onConfirm: reject,
        onCancel: resolve,
      },
    );
  });
}

export class SectionizerPublicApi extends BasePublicApi<Scope> {
  sectionizerValidationPromt = this.bindScope(sectionizerValidationPromt);
  saveSectionizedTemplatePromt = this.bindScope(saveSectionizedTemplatePromt);
}

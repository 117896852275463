// @ts-nocheck
import _ from 'lodash';
import * as platform from '#packages/platform';
import * as util from '#packages/util';
import * as actions from '../../../api/actions/actions';
import * as coreBi from '#packages/coreBi';
import pagesView from '../pagesView';
import { AddPageButton, sendNavigateToPageBiEvent } from '../../../utils';
import pagesAPI from '../../../api/api';
import { getReplacersCollapserActions } from '../../../api/actions/appReflowDefaultActions';
import { pagesAddPagesSelectionClick } from '@wix/bi-logger-editor/v2';

import { withDynamicPageCount } from './hoc/withDynamicPageCount';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;
const { addPageRouterActionUtils } = util;

const getIframeFullSrc = (relativeEmptyStateUrl, appDefId, appData) =>
  platform.utils.getFullApplicationUrl(
    appData,
    relativeEmptyStateUrl,
    appDefId,
    appData.applicationId,
  );
const getAddButtonData = (
  editorAPI,
  appDefId,
  applicationId,
  isDesktop,
  actionsData,
  biCategory,
) => {
  if (!isDesktop) {
    return { title: null, onClick: null };
  }

  const addPageAction = addPageRouterActionUtils.findAddPageAction(actionsData);

  if (!addPageAction) {
    return { title: null, onClick: null };
  }
  const onClickApplicationNotification =
    actions.getOnClickApplicationNotification(
      editorAPI,
      applicationId,
      addPageAction.event,
    );

  return {
    title: addPageAction.title,
    tooltip: addPageAction.tooltip,
    onClick: (clickOrigin: AddPageButton) => {
      editorAPI.bi.event(
        coreBi.events.pages.pagesPanel.add_page_selection_click,
        {
          category: 'add_dynamic_page',
          origin: 'dynamic_page',
          source: biCategory || 'router',
          button: clickOrigin,
        },
      );
      onClickApplicationNotification();
    },
  };
};

const getSecondaryActionButtonData = (
  editorAPI,
  appDefId,
  applicationId,
  isDesktop,
  actionsData,
  biCategory,
) => {
  if (!isDesktop) {
    return null;
  }

  const secondaryAction = actionsData?.find(
    (action) => action.type === 'add_page_secondary',
  );

  if (!secondaryAction) {
    return null;
  }

  const onClickApplicationNotification =
    actions.getOnClickApplicationNotification(
      editorAPI,
      applicationId,
      secondaryAction.event,
    );

  return {
    title: secondaryAction.title,
    onClick: (clickOrigin: AddPageButton) => {
      util.biLogger.report(
        pagesAddPagesSelectionClick({
          category: 'add_preset',
          origin: 'dynamic_page',
          source: biCategory || 'router',
          button: clickOrigin,
        }),
      );
      onClickApplicationNotification();
    },
  };
};

const mapStateToProps = (
  { editorAPI },
  {
    appData: {
      id,
      title,
      pagesData,
      pagesGroups,
      biCategory,
      emptyStateUrl,
      actions,
    },
    onShowSettings,
    isDesktop,
    onRename,
  },
) => {
  const appDefinitionId = id;
  const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
  const addButtonData = getAddButtonData(
    editorAPI,
    appDefinitionId,
    appData.applicationId,
    isDesktop,
    actions,
    biCategory,
  );

  const secondaryButtonData = getSecondaryActionButtonData(
    editorAPI,
    appDefinitionId,
    appData.applicationId,
    isDesktop,
    actions,
    biCategory,
  );

  const loadingPageId = editorAPI.pages.loading.getPageId();

  return {
    title,
    emptyStateUrl: emptyStateUrl
      ? getIframeFullSrc(emptyStateUrl, appDefinitionId, appData)
      : null,
    pages: pagesAPI.appPages.getActions(
      editorAPI,
      pagesData,
      onShowSettings,
      onRename,
      appDefinitionId,
      biCategory,
    ),
    pagesGroups,
    renameEnabled: isDesktop,
    biCategory,
    appDefinitionId,
    tooltipAdd: addButtonData.tooltip,
    titleAdd: addButtonData.title,
    secondaryButtonData,
    loadingPageId,
  };
};

const getNavigateTo =
  (panelName, biCategory) =>
  (dispatch, getState, { editorAPI }) =>
  (pageId) => {
    const focusedPageId = editorAPI.dsRead.pages.getFocusedPageId();

    if (focusedPageId !== pageId) {
      editorAPI.pages.navigateTo(pageId);
      sendNavigateToPageBiEvent(editorAPI, {
        pageId,
        panelName,
        biCategory,
      });
    }
  };

const sendBiEvent =
  (...args) =>
  (dispatch, getState, { editorAPI }) =>
    editorAPI.bi.event(...args);

const getOnClickAdd =
  (appDefinitionId, isDesktop, actions, biCategory) =>
  (dispatch, getState, { editorAPI }) => {
    const appData = editorAPI.platform.getAppDataByAppDefId(appDefinitionId);
    const addButtonData = getAddButtonData(
      editorAPI,
      appDefinitionId,
      appData.applicationId,
      isDesktop,
      actions,
      biCategory,
    );

    return addButtonData.onClick;
  };

const getCollapserActions =
  (pageData) =>
  (dispatch, getState, { editorAPI }) => {
    return getReplacersCollapserActions(
      editorAPI,
      pageData.id,
      pageData.managingAppDefId,
      pageData.title,
    );
  };

const mapDispatchToProps = (
  dispatch,
  {
    appData: { id, helpId, biCategory, actions },
    isDesktop,
    onHelp,
    biPanelName,
  },
) => ({
  onHelp: _.partial(onHelp, helpId),
  navigateTo: dispatch(getNavigateTo(biPanelName, biCategory)),
  biEvent(...args) {
    dispatch(sendBiEvent(...args));
  },
  onClickAdd: dispatch(getOnClickAdd(id, isDesktop, actions, biCategory)),
  getCollapserActions: (pageData) => dispatch(getCollapserActions(pageData)),
});

export default _.flowRight(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withDynamicPageCount,
  renderWhenMutated,
)(pagesView);

import React from 'react';
import { translate } from '#packages/i18n';
import _ from 'lodash';
import * as util from '#packages/util';
import * as coreBi from '#packages/coreBi';
import { Divider, PromotionalList, RichText } from '@wix/wix-base-ui';
import {
  mapDispatchToProps,
  mapStateToProps,
  type BranchIndicatorPanelDispatchProps,
  type BranchIndicatorPanelStateProps,
} from './branchIndicatorPanelMapper';
import withSendBi, { type WithSendBiProps } from '../../hocs/withSendBi';

interface BranchIndicatorPanelOwnProps {}

type BranchIndicatorPanelProps = BranchIndicatorPanelDispatchProps &
  BranchIndicatorPanelStateProps &
  BranchIndicatorPanelOwnProps &
  WithSendBiProps;

const LIST_ITEMS = [
  'SiteBraches_Editor_TopBar_Modal_Bullet1',
  'SiteBraches_Editor_TopBar_Modal_Bullet2',
  'SiteBraches_Editor_TopBar_Modal_Bullet3',
];
const BranchIndicatorPanel: React.FC<BranchIndicatorPanelProps> = ({
  branchName,
  sendBi,
  branchId,
  openSiteBranchesManager,
}: BranchIndicatorPanelProps) => {
  const openBMVersionsManagerWithBi = () => {
    sendBi(
      coreBi.events.topbar.top_bar_branch_indicator
        .manage_site_branches_clicked,
      {
        site_branch_id: branchId,
        site_branch_name: branchName,
      },
    );
    openSiteBranchesManager();
  };

  return (
    <div className="drop-panel-content code-drop-panel-branch-indicator">
      <div className="header-container">
        <RichText shouldTranslate={false} className="title" type="T03">
          {translate('SiteBraches_Editor_TopBar_Modal_Title')}
        </RichText>
        <RichText shouldTranslate={false} className="version-name" type="T09">
          {branchName}
        </RichText>
      </div>
      <div className="middle-container">
        <PromotionalList items={LIST_ITEMS} shouldTranslate={true} />
        <RichText
          shouldTranslate={false}
          className="description-holder"
          type="T07"
        >
          {translate('SiteBraches_Editor_TopBar_Modal_Text')}
        </RichText>
      </div>
      <div className="footer-container">
        <Divider long={true} className="footer-divider" />
        <RichText
          shouldTranslate={false}
          className="manage-branches-link"
          type="T02"
        >
          <a
            onClick={openBMVersionsManagerWithBi}
            data-automation-id="manage-branches-link"
          >
            {translate('SiteBraches_Editor_TopBar_Modal_Link')}
          </a>
        </RichText>
      </div>
    </div>
  );
};

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

const ConnectedBranchIndicatorPanel = _.flow(
  connect(EDITOR_API, mapStateToProps, mapDispatchToProps),
  withSendBi,
)(BranchIndicatorPanel);

ConnectedBranchIndicatorPanel.pure = BranchIndicatorPanel;
export default ConnectedBranchIndicatorPanel;

import type { EditorAPI } from '#packages/editorAPI';
import type { MediaImageStudio } from '@wix/media-image-studio-opener/dist/src';
import type { UserFeedbackOpener } from '@wix/user-feedback-opener/dist/src/user-feedback-opener';
import type { VideoMakerOpener } from '@wix/video-maker-opener';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { SendBiFunction, SendErrorBiFunction } from 'types/bi';

export interface MediaManagerPanelMapStateToProps {
  sessionUserPreferences: Record<string, any>;
  mediaManager: EditorAPI['mediaServices']['mediaManager'];
  siteMediaToken: string;
  metaSiteId: string;
  metaSiteInstance: string;
  userId: string;
  siteId: string;
  language: string;
  focusedPageId: string;
  isTemplateSite: boolean;
  focusedPageReference: CompRef;
  userFeedback: UserFeedbackOpener;
  mediaStudio: MediaImageStudio;
  videoMaker: VideoMakerOpener;
  editorWorkingMode: string;
  isRecommendedCardAlwaysHidden: boolean;
  componentsAPI: EditorAPI['components'];
  pasteLogicAPI: EditorAPI['pasteLogic'];
  historyAPI: EditorAPI['history'];
  selectionAPI: EditorAPI['selection'];
  mediaAPI: EditorAPI['media'];
  panelManagerAPI: EditorAPI['panelManager'];
}

export interface MediaManagerPanelMapDispatchToProps {
  sendBi: SendBiFunction;
  sendBiError: SendErrorBiFunction;
  setSessionUserPreferences: (key: string, value: AnyFixMe) => void;
  setSiteUserPreferences: (key: string, value: AnyFixMe) => void;
  getComponentBiParams: (compRef: CompRef) => Record<string, string>;
  getContainerRefWithSectionsEnabled: (compDef: CompStructure) => CompRef;
  editorGetPasteComponentPosition: (
    layout: AnyFixMe,
    containerId: string,
  ) => AnyFixMe;
  fireComponentAdded: GetFieldType<
    EditorAPI,
    'components.hooks.componentAddedToStage.fire'
  >;
  editorSetFocusToEditor: () => void;
  showCannotAddNotification: (
    componentStructure: CompStructure,
    containerToAddTo: CompRef,
  ) => void;
}

export interface MediaManagerPanelProps
  extends MediaManagerPanelMapStateToProps,
    MediaManagerPanelMapDispatchToProps {
  panelIndex: number;
  panelName: string;
  shouldOpenMediaStudioOnMount?: boolean;
  shouldOpenVideoMakerOnMount?: boolean;
  shouldOpenMediaManagerOnMount?: boolean;
  mediaManagerOpenPathSourceOnMount?: string;
  startItemDrag: FunctionFixMe;
}

export interface MediaManagerPanelState {
  areAllRegularSectionsLoaded: boolean;
  isSearching: boolean;
  searchQuery: string;
  loadingError: AnyFixMe;
  selectedNavigationSection: AnyFixMe;
  sections: Record<string, AnyFixMe>;
  isSearchBoxSticky: boolean;
  recommendedTopicsList: { id: string }[];
  isRecommendedSectionLoadWithMocks: boolean;
  recommendedMediaCategories: AnyFixMe;
  recommendedMediaCacheId: string;
  recommendedSectionVisibleItemsCount: number;
  shouldHideRecommendedMediaCardForCurrentSession: boolean;
  neverShowRecommendedMedia: boolean;
  recommendedMediaAdapter?: AnyFixMe;
  mediaPriceInfo: AnyFixMe;
}

export enum MediaChannelEventType {
  FileStatusUpdate = 'status_update',
  TrashFoldersRestoreCompleted = 'trash_folders_restore_completed',
  TrashFilesRestoreCompleted = 'trash_files_restore_completed',
  CopyFolder = 'copy_folder',
  CopyFile = 'copy_file',
  TrashEmpty = 'trash_empty',
  OutputStatusUpdate = 'output_status_update',
  StorageUsageUpdate = 'site_usage_update',
  PremiumPlanChanged = 'site_premium_plan_changed',
}

export interface MediaChannelMessage {
  type: MediaChannelEventType;
  info: any;
  file: { file_name: string; op_status: string };
  error: any;
  [key: string]: any;
}

type TDuplexerHandler = (message: MediaChannelMessage) => void;

export interface MediaChannel {
  on: (event: string, handler: TDuplexerHandler) => void;
  off: (event: string, handler: TDuplexerHandler) => void;
}

// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['design_section_label_explore_VAmenu'],
      sections: [
        addPanel.sections.getSections().menu.verticalAnchorsMenuSection,
      ],
    };
  },
};

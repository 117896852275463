// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as baseUI from '#packages/baseUI';
import constants from './imageCropSVGPanelConsts';
import * as imageCropSVGPanelMapper from './imageCropSVGPanelMapper';
import {
  Composites,
  CustomScroll,
  Thumbnails,
  Tooltip,
} from '@wix/wix-base-ui';
import * as Symbols from '@wix/santa-editor-symbols';
import { ToolPanelFrame } from '../../frames';

const {
  connect,
  STORES: { EDITOR_API },
} = util.hoc;

function getThumbnailOptions(
  imageData,
  width,
  height,
  isClip,
  imageUrl,
  maskData,
) {
  return {
    value: maskData.id,
    illustration: React.createElement(baseUI.imageWithMask, {
      imageData,
      imageWidth: width,
      imageHeight: height,
      imageUrl,
      isClip,
      crop: {
        width,
        height,
        x: 0,
        y: 0,
        svgId: maskData.id,
        svgStr: maskData.thumbnail?.replace(
          '<svg ',
          `<svg id=${maskData.thumbId} `,
        ),
        svgDomId: maskData.thumbId,
        maskContour: isClip
          ? null
          : {
              width: 2,
              color: '#dae1e9',
            },
      },
    }),
  };
}

function setPreserveAspectRatioToXMidYMid(svg) {
  return svg?.replace(
    'preserveAspectRatio="none"',
    'preserveAspectRatio="xMidYMid"',
  );
}

function isSvgIdInPanel(svgId) {
  if (!svgId) {
    return false;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(constants.MASKS, { id: svgId });
}

const DEFUALT_MMGR_PATH = 'public/ec9ed40a-6502-4df2-a088-355405d7bd25';
const CHANGE_MASK_DOM_ID = 'svg-panel-change-mask-id';
const { imageTransform } = util;

//TYPE WAS GENERATED, remove this line when reviewed
interface ImageCropSVGPanelProps {
  mediaManager: AnyFixMe;
  imageData: AnyFixMe;
  setFocusToEditor?: FunctionFixMe;
  setCropArea?: FunctionFixMe;
  getCropArea?: FunctionFixMe;
  getCropOriginArea?: FunctionFixMe;
  getMMgrPath?: FunctionFixMe;
  setMMgrPath?: FunctionFixMe;
  getLastCustomMaskId?: FunctionFixMe;
  lastCustomMaskId?: string;
  setLastCustomMaskId?: FunctionFixMe;
  getSVG?: FunctionFixMe;
}

class ImageCropSVGPanel extends React.Component<ImageCropSVGPanelProps> {
  static displayName = 'imageCropSVGPanel';

  static propTypes = {
    mediaManager: PropTypes.object.isRequired,
    imageData: PropTypes.object.isRequired,
    setFocusToEditor: PropTypes.func,
    setCropArea: PropTypes.func,
    getCropArea: PropTypes.func,
    getCropOriginArea: PropTypes.func,
    getMMgrPath: PropTypes.func,
    setMMgrPath: PropTypes.func,
    getLastCustomMaskId: PropTypes.func,
    lastCustomMaskId: PropTypes.string,
    setLastCustomMaskId: PropTypes.func,
    getSVG: PropTypes.func,
    svgId: PropTypes.string,
  };

  static defaultProps = {
    top: '200px',
    left: '300px',
  };

  constructor(props) {
    super(props);
    const cropArea = props.getCropArea();
    const currentId = cropArea?.svgId;

    if (currentId && !isSvgIdInPanel(currentId)) {
      props.setLastCustomMaskId(currentId);
    }

    this.state = {
      scrollPosition: 0,
    };
  }

  componentDidMount() {
    this.scrollToSelectedItem();
  }

  scrollToSelectedItem = () => {
    const items = ReactDOM.findDOMNode(this.refs.maskCropItems);
    const selectedInputNode = items.querySelector('input:checked');

    if (selectedInputNode) {
      const targetElement = selectedInputNode.parentNode;
      const offsetFromTop = 74;
      this.setState({
        scrollPosition: Math.max(targetElement.offsetTop - offsetFromTop, 0),
      });
    }
  };

  getMaskOptions = () => {
    const cropArea = this.props.getCropArea();
    const transformedData = imageTransform.getFromImageData(
      this.props.imageData,
      cropArea.width,
      cropArea.height,
    );

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const thumbnails = _.map(
      constants.MASKS,
      getThumbnailOptions.bind(
        this,
        this.props.imageData,
        constants.MASK_THUMB_SIZE.width,
        constants.MASK_THUMB_SIZE.height,
        false,
        transformedData.uri,
      ),
    );

    const currentMaskThumbnail = this.getCurrentMaskThumbnail();

    if (currentMaskThumbnail) {
      thumbnails.unshift(currentMaskThumbnail);
    }

    return thumbnails;
  };

  selectMask = (svgId, isFromMMgr) => {
    const { x = 0, y = 0, width, height } = this.props.getCropArea();

    // clear svgStr and svgDomId, and replace svgId
    this.props.setCropArea({
      svgId,
      svgStr: '',
      svgDomId: '',
      width,
      height,
      x,
      y,
    });
    if (isFromMMgr && !isSvgIdInPanel(svgId)) {
      this.props.setLastCustomMaskId(svgId);
    }
  };

  openMMgr = () => {
    const { mediaManager } = this.props;
    const category = mediaManager.categories.VECTOR_ART;
    const lastMaskId = this.props.lastCustomMaskId;
    const compId = this.props.imageData.id;
    const path = lastMaskId
      ? this.props.getMMgrPath(`last_svg_mask_path_${compId}`) ||
        DEFUALT_MMGR_PATH
      : DEFUALT_MMGR_PATH;

    this.props.mediaManager.open(category, {
      path,
      multiSelect: false,
      translation: {
        title: translate('CROP_MODE_MMGR_TITLE'),
        submitButton: translate('CROP_MODE_MMGR_SUBMIT_BUTTON'),
      },
      callback: (items, info) => {
        if (items) {
          const svgId = items[0].uri;
          this.selectMask(svgId, true);
          this.props.setMMgrPath(
            `last_svg_mask_path_${compId}`,
            info.path || DEFUALT_MMGR_PATH,
          );
        }
        this.props.setFocusToEditor();
      },
    });
  };

  getAddThumbProps = () => {
    if (this.props.lastCustomMaskId) {
      return false;
    }

    return {
      onAddThumbnailClick: this.openMMgr,
      isFirst: true,
      height: constants.MASK_THUMB_SIZE.height,
    };
  };

  getCurrentMaskThumbnail = () => {
    if (!this.props.lastCustomMaskId) {
      return null;
    }

    const { imageData } = this.props;

    const cropArea = this.props.getCropArea();
    const transformedData = imageTransform.getFromImageData(
      this.props.imageData,
      cropArea.width,
      cropArea.height,
    );

    const thumbnail = getThumbnailOptions.call(
      this,
      imageData,
      constants.MASK_THUMB_SIZE.width,
      constants.MASK_THUMB_SIZE.height,
      true,
      transformedData.uri,
      {
        id: this.props.lastCustomMaskId,
        thumbnail: setPreserveAspectRatioToXMidYMid(
          this.props.getSVG(this.props.lastCustomMaskId),
        ),
        thumbId: CHANGE_MASK_DOM_ID,
      },
    );

    thumbnail.className = 'custom-mask';

    return thumbnail;
  };

  getFrameStyle = () => {
    return _.defaults(
      { width: 288, height: 600 },
      _.pick(this.props, ['top', 'left']),
    );
  };

  render() {
    return (
      <ToolPanelFrame
        panelName={this.props.panelName}
        headerTitle={translate('CROP_MODE_SHAPES_PANEL_HEADER_TITLE')}
        contentStyle={this.getFrameStyle()}
        helpId="9f5cc6cb-6abf-40c5-8f8b-774e2614df18"
        contentWrapperClass="image-mask-panel"
        onClose={this.props.onClose}
      >
        <CustomScroll
          ref="customScroll"
          heightRelativeToParent="100%"
          addScrolledClass={true}
          scrollTo={this.state.scrollPosition}
        >
          <Composites.Thumbnails>
            <Thumbnails
              value={this.props.svgId}
              ref="maskCropItems"
              onChange={this.selectMask}
              maxThumbsPerRow={3}
              fixedRatio={true}
              hasBackground={false}
              borderType="thumbnail"
              addThumbnailProps={this.getAddThumbProps()}
              options={this.getMaskOptions()}
              shouldTranslate={true}
            />
            {/* START: should be in Base UI Lib */}
            {this.props.lastCustomMaskId ? (
              <div
                key="changebutton"
                onClick={this.openMMgr}
                className="image-crop-change-mask-indicator"
              >
                <Tooltip
                  shouldTranslate={true}
                  closeOnMouseClick={true}
                  content="CROP_MODE_SHAPES_PANEL_CUSTOM_REPLACE_TOOLTIP"
                >
                  <Symbols.symbol name="video-player-change-poster" />
                </Tooltip>
              </div>
            ) : null}
            {/* END: should be in Base UI Lib */}
          </Composites.Thumbnails>
        </CustomScroll>
      </ToolPanelFrame>
    );
  }
}

const ConnectImageCropSVGPanel = connect(
  EDITOR_API,
  imageCropSVGPanelMapper.mapStateToProps,
  imageCropSVGPanelMapper.mapDispatchToProps,
)(ImageCropSVGPanel);
ConnectImageCropSVGPanel.pure = ImageCropSVGPanel;

export default ConnectImageCropSVGPanel;

import { ORIGIN } from '../constants';

import type { AIAssistantPanelStateProps } from './aiAssistantPanelTypes';
import type { AIAssistantPanelScope } from './aiAssistantPanelScope';

export const mapStateToProps = (
  scope: AIAssistantPanelScope,
): AIAssistantPanelStateProps => {
  return {
    assistantConfig: scope.store.getConfig(),
  };
};

export const mapDispatchToProps = (scope: AIAssistantPanelScope) => {
  const closeAiAssistant = () => {
    scope.workspaceRightPanelApi.close(ORIGIN, false);
  };

  return { closeAiAssistant };
};

/* eslint-disable max-lines */
/*eslint max-lines: [2, { "max": 1394, "skipComments": true, "skipBlankLines": true}]*/
import experiment from 'experiment';
import { DuplicatePageCodeConditionApiKey, EditorAPIKey } from '#packages/apis';
import { DATA_BINDING, WIX_CODE } from '@wix/app-definition-ids';
import _ from 'lodash';
import * as core from '#packages/core';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import { removePage } from './utils/removePage';
import * as platform from '#packages/platform';
import * as platformEvents from 'platformEvents';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import * as coreBi from '#packages/coreBi';
import * as pageSettings from '#packages/pageSettingsPanel';
import { getCollections } from '#packages/wixData';
import * as menu from '#packages/menu';
import { ErrorReporter } from '@wix/editor-error-reporter';
import { Hooks, type Shell } from '#packages/apilib';
import type {
  CompRef,
  DynamicPageLink,
  // Page interface was removed from ds-types. need a new more correct one. https://github.com/wix-private/document-management/pull/19379/files#diff-c5757dcbb1504926732b150fdb2b45bb534e4c0c0300fe6a32de3082ad1ad9a3L65
  // @ts-expect-error
  Page,
  PageLink,
  PagesDataFromAppManifest,
  RoutersDefinition,
} from 'types/documentServices';
import type { DSRead, PagesData, RouterRef } from 'types/documentServices';
import { isCustomMenusEnabled } from '#packages/util';
import pagesAPI from './api/api';
import type {
  OnItemAddedHandler,
  OnRenameHandler,
  OnSettingsChangeHandler,
} from './panel/types';
import type { PanelProps as AddPagePanelProps } from './api/openAddPagePanel';
import type { IAppPage } from './api/appsPages';
import type { IPagesPanelMenu } from './api/menusPages';
import type { RouterPageData } from './api/routerPages';

import type { IPageMenuItem } from '#packages/menu';
import {
  pagesActions,
  pagesSelectors,
  type PagesState,
  type Trigger,
} from './state';

const {
  api: { createMenuApi },
  utils: { cleanId },
} = menu;

const { getSiteScale } = stateManagement.domMeasurements.selectors;
const { exitInteractionModeIfNeeded } = stateManagement.interactions.actions;
const { getPreviewMode } = stateManagement.preview.selectors;
const { getWidgetPrimaryContainer } =
  stateManagement.applicationStudio.selectors;
const { collectionNamespaceUtils } = util;
const { isTPA } = core.utils.tpaTypeUtils;

function isTpaPage(serializedPage: AnyFixMe = {}) {
  return (
    serializedPage.data &&
    (serializedPage.data.managingAppDefId || isTPA(serializedPage.data))
  );
}

/**
 * API for manipulate/interact with pages and it's structures.
 * @param editorAPI
 * @description Pages API used externally and internally at the Editor via editorAPI.pages
 * @description Use with caution, some methods could be deprecated in future(There would be shown a deprecation message about particular ones)
 * @description Info about deprecation could be found by `@deprecationInfo` tag
 */
// eslint-disable-next-line max-statements
export function createPagesApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const duplicatePageCodeConditionAPI = shell.getAPI(
    DuplicatePageCodeConditionApiKey,
  );
  const shellStore = shell.getStore<{ pages: PagesState }>();

  const hooks = {
    beforePageNavigate: Hooks.createHook<{ pageId: string }, Promise<void>>(),
    updatePageHook: Hooks.createHook<{ pageId: string }>(),
    pageAddedToStage: Hooks.createHook<{ pageRef: CompRef; origin: string }>(),
  };

  function pageChangeCallback(pageId: string, callback: () => void) {
    pageChangeZoomCallback();
    pageChangeGeneralCallback(pageId, callback);
    pageChangeScrollCallback();
    popupNavigationCallback(pageId);

    const pageRef = editorAPI.dsRead.pages.getReference(pageId);
    const pagesData = editorAPI.dsRead.pages.data.get(pageId);

    editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
      platformEvents.factory.focusedPageChanged({ pageRef }),
    );

    const interactionParams = pagesData?.managingAppDefId
      ? {
          customParams: {
            target: {
              managingAppDefId: pagesData.managingAppDefId,
              tpaPageId: pagesData.tpaPageId,
            },
          },
        }
      : null;

    util.fedopsLogger.interactionEnded(
      util.fedopsLogger.INTERACTIONS.NAVIGATE_TO_PAGE,
      interactionParams,
    );
  }

  function pageChangeZoomCallback() {
    if (editorAPI.zoomMode.isStageZoomMode()) {
      editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(false);
    }
  }

  function pageChangeScrollCallback() {
    const state = editorAPI.store.getState();
    if (!getPreviewMode(state)) {
      editorAPI.shouldUpdateEditorScrollAfterHeightUpdate = true;
      editorAPI.scroll.applyPreviewScrollToEditorScroll();
    }
  }

  function popupNavigationCallback(pageId: string) {
    if (!editorAPI.pages.popupPages.isPopup(pageId)) {
      return;
    }
    if (editorAPI.zoomMode.isInZoomMode()) {
      editorAPI.setSiteScale({
        requestedScale: getSiteScale(editorAPI.store.getState()),
        scrollY: 0,
      });
    }
  }

  function provisionWixCode() {
    return new Promise((resolve, reject) => {
      editorAPI.wixCode.provision({
        onSuccess: resolve,
        onError: reject,
      });
    });
  }

  function notifyDataBindingAppTooManyPages() {
    function notifyDataBindingApp() {
      const { applicationId } =
        editorAPI.dsRead.platform.getAppDataByAppDefId(DATA_BINDING);
      editorAPI.dsActions.platform.notifyApplication(
        applicationId,
        platformEvents.factory.addDynamicPageClicked({
          origin: 'too_many_pages_suggestion',
        }),
      );
    }

    if (!editorAPI.wixCode.isProvisioned()) {
      provisionWixCode().then(() => {
        notifyDataBindingApp();
      });
      return;
    }
    notifyDataBindingApp();
  }

  function pageChangeGeneralCallback(pageId: AnyFixMe, callback: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/concat
    _(editorAPI.pageNavigationCallbacks)
      .concat(callback)
      .compact()
      .forEach(function (func) {
        func(pageId);
      });
    editorAPI.dsActions.waitForChangesApplied(() =>
      util.keyboardShortcuts.enable(),
    );
  }

  function convertActionsToSingleFormat(
    pageActionsData: AnyFixMe,
    category: AnyFixMe,
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
    return _.reduce(
      pageActionsData,
      function (res, option) {
        //new format app manifest support
        if (_.isObject(option)) {
          res.push(
            _.defaults(option, {
              category,
              //@ts-expect-error
              id: option.event || option.title || option.type || option.tooltip,
            }),
          );
          return res;
        }

        //old string format app manifest support -
        res.push({
          title: translate(option),
          type: option,
          id: option,
          category,
          actionName: option,
        });
        return res;
      },
      [],
    );
  }

  function buildOpenBehaviorFilter() {
    const PROPS_WHITELIST = ['type', 'name'];
    //@ts-expect-error
    const loadActionDefinition = editorAPI.actions.getDefinition('load');
    const openPopupBehaviorDefinition =
      editorAPI.behaviors.getDefinition('openPopup');

    return {
      action: _.pick(loadActionDefinition, PROPS_WHITELIST),
      behavior: _.pick(openPopupBehaviorDefinition, PROPS_WHITELIST),
    };
  }

  function getPageTitle(dsRead: DSRead, pageId: string) {
    return dsRead.pages.getPageTitle(pageId);
  }

  function getDataBindingApplicationStringValue() {
    return translate('DYNAMIC_PAGES_APP_TYPE_DATA_BINDING');
  }

  function getApplicationStringValue(
    appDefinitionId: AnyFixMe,
    group: RoutersDefinition['group'],
    defaultValue: string = '',
  ) {
    const membersAppDefId =
      editorAPI.dsRead.platform.editorApps.SANTA_MEMBERS.appDefId;

    switch (appDefinitionId) {
      case DATA_BINDING:
        return getDataBindingApplicationStringValue();
      case membersAppDefId:
        const appManifest =
          editorAPI.dsRead.platform.getAppManifest(membersAppDefId);
        return _.get(appManifest, `routers.pagesGroups.${group}.label`);
      case WIX_CODE:
        return translate('DYNAMIC_PAGES_APP_TYPE_CUSTOM_ROUTER');
    }
    return defaultValue;
  }

  function getApplicationType(appDefinitionId: AnyFixMe) {
    return appDefinitionId === DATA_BINDING
      ? constants.ROUTER_TYPES.DATA_BINDING
      : constants.ROUTER_TYPES.CUSTOM_ROUTER;
  }

  /**
   * This function checks is user already shown the dynamic pages starting panel after adding page X.
   * It should be after waitForChangesApplied because this flow only happens after pages.add.
   * @param staticPagesCount - number of static pages in the site
   */
  function openDynamicPagesPopupIfNeeded(staticPagesCount: AnyFixMe) {
    // If to display dynamic popup suggestion (if the page added is the first page after got 50 pages - or the 50th page).
    if (
      experiment.isOpen('se_tooManyPagesLimitation') &&
      staticPagesCount >= constants.STATIC_PAGES.WARNING_NUMBER_OF_STATIC_PAGES
    ) {
      const state = editorAPI.store.getState();
      const showedUserDynamicPagesStartingPanel =
        stateManagement.userPreferences.selectors.getSiteUserPreferences(
          constants.STATIC_PAGES.USER_PREF_FLOW_DYNAMIC_PAGES,
        )(state);
      if (!showedUserDynamicPagesStartingPanel) {
        editorAPI.dsActions.waitForChangesApplied(() => {
          editorAPI.panelManager.openPanel(
            'wixData.dynamicPagesStartingPanel',
            {
              notifyDataBindingAppTooManyPages,
              staticPagesCount,
              origin: `#${staticPagesCount}`,
            },
            true,
          );
          editorAPI.store.dispatch(
            stateManagement.userPreferences.actions.setSiteUserPreferences(
              constants.STATIC_PAGES.USER_PREF_FLOW_DYNAMIC_PAGES,
              true,
            ),
          );
        });
      }
    }
  }

  /**
   * This function adds a page.
   * If serializedPage is not defined, it will be a static page.
   * In this function we're calling getStaticPagesCount() , which is a heavy operation (calculates all static pages and filters dynamic and other pages)
   * So the "let staticPagesCount" is placed instead of const in order not to call the function many times.
   * @param pageTitle - string
   * @param serializedPage - page serialized object
   * @param shouldAddMenuItem - boolean
   * @param ignoreAddPageLimitation - boolean - if to allow adding a static page even if static pages count is max
   */
  function add(
    pageTitle: AnyFixMe,
    serializedPage?: AnyFixMe,
    shouldAddMenuItem?: AnyFixMe,
    ignoreAddPageLimitation?: AnyFixMe,
  ) {
    const tooManyPagesExperimentOpen = experiment.isOpen(
      'se_tooManyPagesLimitation',
    );
    let staticPagesCount = editorAPI.pages.getStaticPagesCount();
    let pageRef = null;
    if (
      ignoreAddPageLimitation ||
      !tooManyPagesExperimentOpen ||
      canAddStaticPage(staticPagesCount) ||
      isTpaPage(serializedPage)
    ) {
      util.fedopsLogger.interactionStarted(
        util.fedopsLogger.INTERACTIONS.ADD_PAGE,
      );
      if (serializedPage) {
        serializedPage.data.pageUriSEO =
          editorAPI.dsRead.pages.data.pageUriSEO.getValid(
            '',
            serializedPage.data.pageUriSEO,
          );
      }
      pageRef = editorAPI.dsActions.pages.add(
        pageTitle,
        serializedPage,
        shouldAddMenuItem,
      );
      staticPagesCount++;
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.ADD_PAGE,
      );
    }
    openDynamicPagesPopupIfNeeded(staticPagesCount);
    return pageRef;
  }

  const closePanelsAndRemoveHighlits = () => {
    editorAPI.selection.deselectComponents();
    editorAPI.selection.clearHighlights();
  };

  const shouldDeselectComponents = (pageInfo: string | DynamicPageLink) => {
    if (typeof pageInfo === 'string') {
      return true;
    }
    const currentUrlPageId = editorAPI.dsRead.pages.getCurrentUrlPageId();
    if (pageInfo.isTpaRoute && currentUrlPageId === pageInfo.routerId) {
      return false;
    }

    return true;
  };

  async function navigateTo(
    pageInfo: string | DynamicPageLink,
    cb?: () => void,
    keepContext?: boolean,
  ) {
    const pageId = typeof pageInfo === 'string' ? pageInfo : pageInfo?.pageId;
    // pageInfo can be pageId or page Object
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.NAVIGATE_TO_PAGE,
    );
    editorAPI.store.dispatch(
      stateManagement.inlinePopup.actions.closeAll(true),
    );

    if (shouldDeselectComponents(pageInfo)) {
      closePanelsAndRemoveHighlits();
    }

    util.keyboardShortcuts.disable();

    let routerId: string;
    // for dynamic pages, we dont have a page id, we get a router id instead
    if (typeof pageInfo === 'object' && 'routerId' in pageInfo) {
      routerId = pageInfo.routerId;
    }

    shellStore.dispatch(pagesActions.startLoading(pageId || routerId));
    await Promise.all(
      hooks.beforePageNavigate.fire({
        pageId,
      }),
    );
    if (editorAPI.zoomMode.isStageZoomMode()) {
      editorAPI.documentMode.enableShouldUpdateJsonFromMeasureMap(true);
    }
    editorAPI.dsActions.pages.navigateTo(pageInfo);

    editorAPI.dsActions.waitForChangesApplied(function () {
      const pageId = editorAPI.dsRead.pages.getFocusedPageId();
      pageChangeCallback(pageId, cb);

      const loadingPageId = getLoadingPageId();
      if (loadingPageId === pageId || loadingPageId === routerId) {
        shellStore.dispatch(pagesActions.endLoading());
      }
    });
    if (keepContext) {
      return;
    }
    editorAPI.developerMode.setContext(
      editorAPI.developerMode.createContext(
        constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE,
      ),
    );
  }

  const navigateToAsync = (
    pageInfo: string | DynamicPageLink,
    keepContext?: boolean,
  ) => {
    return new Promise<void>((resolve) =>
      navigateTo(pageInfo, resolve, keepContext),
    );
  };

  async function navigateToAndScroll(
    pageId: AnyFixMe,
    anchorDataId: AnyFixMe,
    cb: AnyFixMe,
  ) {
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.NAVIGATE_TO_PAGE,
    );
    editorAPI.selection.deselectComponents();
    editorAPI.selection.clearHighlights();
    const shouldChangeLoadingState = getCurrentPage()?.id !== pageId;
    if (shouldChangeLoadingState) {
      shellStore.dispatch(pagesActions.startLoading(pageId));
    }
    await Promise.all(
      hooks.beforePageNavigate.fire({
        pageId: typeof pageId === 'string' ? pageId : pageId?.pageId,
      }),
    );

    editorAPI.dsActions.pages.navigateToAndScroll(
      pageId,
      anchorDataId,
      pageChangeScrollCallback,
    );
    editorAPI.dsActions.waitForChangesApplied(() => {
      pageChangeCallback(pageId, cb);
      if (getLoadingPageId() === pageId && shouldChangeLoadingState) {
        shellStore.dispatch(pagesActions.endLoading());
      }
    });
    editorAPI.developerMode.setContext(
      editorAPI.developerMode.createContext(
        constants.DEVELOPER_MODE.CONTEXT_TYPES.PAGE,
      ),
    );
  }

  function getCurrentPage() {
    return editorAPI.dsRead.pages.getCurrentPage();
  }

  function getCurrentUrlPageTitle() {
    if (!editorAPI.dsRead) {
      return null; //TODO: fix this, the tests are falling because dsRead are not ready yet
    }
    return getPageTitle(editorAPI.dsRead, getCurrentUrlPageId());
  }

  function getCurrentUrlPageId() {
    if (!editorAPI.dsRead) {
      return null; //TODO: fix this, the tests are falling because dsRead are not ready yet
    }
    return editorAPI.dsRead.pages.getCurrentUrlPageId();
  }

  function getPageTitlePublic(pageId: string): string {
    return getPageTitle(editorAPI.dsRead, pageId);
  }

  function removeInternal(
    pageId: string,
    onRemove = _.noop,
    onCancel = _.noop,
    options: AnyFixMe,
  ) {
    removePage(
      editorAPI,
      editorAPI.dsRead,
      editorAPI.dsActions,
      editorAPI.removePagePlugins,
      pageId,
      onRemove,
      onCancel,
      options,
    );
  }
  function remove(
    pageId: AnyFixMe,
    onRemove = _.noop,
    onCancel = _.noop,
    options: AnyFixMe,
  ) {
    const { shouldShowEditorRemovePanel } = options || {};
    ErrorReporter.breadcrumb(util.fedopsLogger.INTERACTIONS.REMOVE_PAGE, {
      isRemovingTpa: shouldShowEditorRemovePanel,
      pageId,
    });
    util.fedopsLogger.interactionStarted(
      util.fedopsLogger.INTERACTIONS.REMOVE_PAGE,
    );
    const removeCallback = () => {
      onRemove();
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.REMOVE_PAGE,
      );
    };
    const cancelCallback = () => {
      onCancel();
      util.fedopsLogger.interactionEnded(
        util.fedopsLogger.INTERACTIONS.REMOVE_PAGE,
      );
    };

    editorAPI.selection.deselectComponents();
    editorAPI.selection.clearHighlights();
    removePage(
      editorAPI,
      editorAPI.dsRead,
      editorAPI.dsActions,
      editorAPI.removePagePlugins,
      pageId,
      removeCallback,
      cancelCallback,
      options,
    );
  }

  const convertPageMenuItemsToFolders = (pageIds: string[]) => {
    const menuAPI = createMenuApi(editorAPI);

    // сonvert menus pages items to folders if they have children
    menuAPI.bulkItemUpdate(
      (menuItem) => {
        const pageId = (menuItem.link as PageLink)?.pageId;
        const linkPageId = pageId && cleanId(pageId);

        if (linkPageId && pageIds.includes(linkPageId)) {
          return menuItem.items.length > 0;
        }

        return false;
      },
      () => ({ link: undefined }),
    );
  };

  function beforePagesRemoveHook(
    pageIdsToRemove: AnyFixMe,
    callback: AnyFixMe,
    options: AnyFixMe,
  ) {
    let homePageId = editorAPI.homePage.get();
    let firstNonHomePageMenuItemId: AnyFixMe;

    if (isCustomMenusEnabled()) {
      convertPageMenuItemsToFolders(pageIdsToRemove);
    }

    const { pageToNavigateAfterRemove } = options || {};
    const getPageIdToNavigateAfterRemove = () =>
      pageToNavigateAfterRemove ? pageToNavigateAfterRemove.id : homePageId;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const isHomePageBeingRemoved = _.includes(pageIdsToRemove, homePageId);
    if (isHomePageBeingRemoved) {
      const firstNonHomePageMenuItem =
        editorAPI.mainMenu.getFirstNonHomePageMenuItem(pageIdsToRemove);
      if (firstNonHomePageMenuItem) {
        firstNonHomePageMenuItemId = firstNonHomePageMenuItem.pageData.id;
      } else {
        const newPageRef = add(translate('Pages_Menu_Add_New_Page'));
        firstNonHomePageMenuItemId = newPageRef.id;
      }
      editorAPI.dsActions.waitForChangesApplied(function () {
        editorAPI.homePage.set(firstNonHomePageMenuItemId);
        homePageId = firstNonHomePageMenuItemId.replace(/^#/, '');
        editorAPI.pages.navigateTo(getPageIdToNavigateAfterRemove(), callback);
      });
    } else {
      editorAPI.pages.navigateTo(getPageIdToNavigateAfterRemove(), callback);
    }
  }

  function isPageExist(pageId: AnyFixMe) {
    return editorAPI.dsRead.pages.doesPageExist(pageId);
  }

  /**
   * @deprecationInfo feel free to use. Will NOT be deprecated
   */
  function isCustomErrorPage(pageId: string | undefined, pageData?: PagesData) {
    const isPremium = editorAPI.site.isPremium();

    if (pageId && isPremium) {
      if (!pageData) {
        pageData = editorAPI.dsRead.data.getById(pageId);
      }
      return (
        pageData &&
        pageData.pageUriSEO === constants.CUSTOM_ERROR_PAGE.DEFAULT_URL
      );
    }

    return false;
  }

  function getCustomErrorPage() {
    const pages = _.invoke(editorAPI, 'pages.getPagesData');
    return pages && pages.find((page: AnyFixMe) => isCustomErrorPage(page.id));
  }

  function hasCustomErrorPage() {
    return !!getCustomErrorPage();
  }

  function getPrimaryContainer() {
    const currentPage = stateManagement.pages.selectors.getFocusedPage(
      editorAPI.store.getState(),
    );
    if (!editorAPI.dsRead.pages.isWidgetPage(currentPage.id)) {
      return currentPage;
    }

    const [appWidget] = editorAPI.components.getChildren(currentPage);

    const [appWidgetChild] =
      editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(appWidget);
    const firstContainerChild = getWidgetPrimaryContainer(
      editorAPI.dsRead,
      appWidgetChild,
    );

    return firstContainerChild || currentPage;
  }

  function isHomePage(pageId: AnyFixMe) {
    return pageId === editorAPI.homePage.get();
  }
  function isDynamicPage(pageId: string) {
    return Boolean(
      editorAPI.dsRead.routers.getRouterDataForPageIfExist(pageId),
    );
  }
  function isParentPage(pageId: string) {
    if (experiment.isOpen('specs.promote-seo.enableStaticPageHierarchy')) {
      const { siteStructure } =
        editorAPI.documentServices.pages.hierarchy.getSiteStructure();
      const isParentPage =
        siteStructure[pageId]?.staticPage?.subPages?.length > 0;
      return isParentPage;
    }
    return false;
  }

  function getChildPagesIds(parentPageId: string) {
    if (experiment.isOpen('specs.promote-seo.enableStaticPageHierarchy')) {
      const { siteStructure } =
        editorAPI.documentServices.pages.hierarchy.getSiteStructure();
      const pageIds = siteStructure[parentPageId]?.staticPage?.subPages || [];
      return pageIds;
    }
    return [];
  }

  function getChildPagesTitles(parentPageId: string) {
    if (experiment.isOpen('specs.promote-seo.enableStaticPageHierarchy')) {
      const { siteStructure } =
        editorAPI.documentServices.pages.hierarchy.getSiteStructure();
      const pageIds = siteStructure[parentPageId]?.staticPage?.subPages || [];
      const pageTitles: string[] = [];
      pageIds.forEach((pageId: string) => {
        const title = siteStructure[pageId]?.staticPage?.title;
        if (title) {
          pageTitles.push(title);
        }
      });
      return pageTitles;
    }
    return [];
  }

  function serialize(pageId: AnyFixMe) {
    // eslint-disable-next-line @wix/santa-editor/dsReadSerializeIsTooExpensive
    return editorAPI.dsRead.pages.serialize(pageId, true);
  }

  function updateData(
    pageId: string,
    data: Partial<PagesData>,
    useOriginalLanguage = false,
  ) {
    if (
      data.title &&
      data.title.trim() === constants.CUSTOM_ERROR_PAGE.DEFAULT_URL
    ) {
      editorAPI.bi.event(
        coreBi.events.customErrorPage.custom_error_page_panel,
        {
          action: 'create_custom_error_page',
          flow: 'pages_quick_actions_menu',
        },
      );
    }
    if (data.isLandingPage) {
      editorAPI.selection.deselectComponents();
      editorAPI.selection.clearHighlights();
    }
    if (data.pageUriSEO) {
      data.pageUriSEO = editorAPI.dsRead.pages.data.pageUriSEO.getValid(
        pageId,
        data.pageUriSEO,
      );
    }
    data = core.utils.updateDataUtil.fixData(data);
    editorAPI.dsActions.pages.data.update(pageId, data, useOriginalLanguage);
    editorAPI.dsActions.waitForChangesApplied(function () {
      const newPageData = editorAPI.pages.data.get(pageId);

      if (
        isCustomErrorPage(pageId, newPageData) &&
        newPageData.hidePage === false
      ) {
        updateData(pageId, {
          hidePage: true,
        });
      }

      hooks.updatePageHook.fire({ pageId });
    });
  }

  function updateBackground(
    pageId: AnyFixMe,
    data: AnyFixMe,
    device: AnyFixMe,
  ) {
    data = core.utils.updateDataUtil.fixData(data);
    editorAPI.dsActions.pages.background.update(pageId, data, device);
  }

  function setTransition(newTransition: AnyFixMe) {
    editorAPI.dsActions.pages.transitions.set(newTransition);
    editorAPI.history.add('page transition');
  }

  function close() {
    exitInteractionModeIfNeeded(editorAPI);
    editorAPI.hideFloatingBubble();
    const page = editorAPI.dsRead.pages.getPrimaryPageId();
    editorAPI.pages.navigateTo(page);
  }

  function getCurrentPopup() {
    const popupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
    return popupId ? editorAPI.components.get.byId(popupId) : null;
  }

  function getCurrentPopupData() {
    const popupId = editorAPI.dsRead.pages.popupPages.getCurrentPopupId();
    return popupId ? editorAPI.dsRead.pages.data.get(popupId) : null;
  }

  function getPopupContainer(popupId?: AnyFixMe) {
    popupId = popupId || editorAPI.dsRead.pages.popupPages.getCurrentPopupId();

    if (!popupId) {
      return null;
    }

    const popupPointer = editorAPI.dsRead.components.get.byId(popupId);

    return _.head(
      editorAPI.dsRead.components.get.byType(
        constants.COMP_TYPES.POPUP_CONTAINER,
        popupPointer,
      ),
    );
  }

  function getOpenBehaviorsForPopups() {
    const isOpenPopupBehavior = buildOpenBehaviorFilter();

    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      _(editorAPI.dsRead.pages.getPageIdList(true))
        .map(_.ary(editorAPI.components.get.byId, 1))
        .flatMap(_.ary(editorAPI.behaviors.get, 1))
        // @ts-expect-error
        .filter(isOpenPopupBehavior)
        .map(function (pair: AnyFixMe) {
          return {
            pageId: pair.action.sourceId,
            popupId: pair.behavior.targetId,
            params: pair.behavior.params,
          };
        })
        .value()
    );
  }

  function getOpenBehaviorsForPopupWithId(popupId: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    return _.filter(editorAPI.pages.popupPages.getOpenBehaviorsForPopups(), {
      popupId,
    });
  }

  function getDataListOrdered() {
    return _.sortBy(editorAPI.dsRead.pages.popupPages.getDataList(), 'id');
  }

  function isFullWidthPopupOpened() {
    if (!editorAPI.dsRead.pages.popupPages.isPopupOpened()) {
      return false;
    }
    const containerRef = editorAPI.pages.popupPages.getPopupContainer();
    return (
      containerRef && editorAPI.components.layout.isFullWidth(containerRef)
    );
  }

  function getMenuItems(defaultApplicationStringValue?: string) {
    function getDynamicPageMenuItem(routerType: AnyFixMe, dpage: AnyFixMe) {
      return {
        id: dpage.id,
        label: dpage.title,
        type: { isPage: true, isDynamicPage: true, routerType },
        isVisible: true,
        isVisibleMobile: true,
        pageData: dpage,
      };
    }

    function getGroupLabel(
      appDefinitionId: AnyFixMe,
      appDefinitionName: AnyFixMe,
      group: AnyFixMe,
    ) {
      const appManifest =
        editorAPI.dsRead.platform.getAppManifest(appDefinitionId);
      const pageGroupName = _.get(
        appManifest,
        `routers.pagesGroups.${group}.label`,
      );
      return pageGroupName
        ? `${pageGroupName} ${translate('DYNAMIC_PAGES_ROUTER_PAGES')}`
        : `${appDefinitionName} (${group})`;
    }

    function groupRoutersByRouterConfigGroup(
      dynamicPagesListByRouter: AnyFixMe,
    ) {
      let retVal: AnyFixMe = [];
      const groupedByApp = _.groupBy(
        dynamicPagesListByRouter,
        'appDefinitionId',
      );
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(groupedByApp, function (val) {
        const grouped = _.groupBy(val, 'group');
        if (grouped.undefined) {
          retVal = retVal.concat(grouped.undefined);
          delete grouped.undefined;
        }
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(grouped, function (arr) {
          const label = getGroupLabel(
            arr[0].appDefinitionId,
            arr[0].appDefinitionName,
            arr[0].group,
          );

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
          const res = _.reduce(
            arr,
            function (acc, routerDef) {
              acc.pages = acc.pages.concat(routerDef.pages);
              return acc;
            },
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line you-dont-need-lodash-underscore/assign
            _.assign({}, arr[0], { prefix: arr[0].prefix, label, pages: [] }),
          );
          retVal = retVal.concat(res);
        });
      });
      return retVal;
    }

    function splitRoutersIntoDataAndNondata(routers: AnyFixMe) {
      return _.groupBy(routers, (router) =>
        router.appDefinitionId === DATA_BINDING
          ? 'dataRouters'
          : 'nondataRouters',
      );
    }

    function groupPagesOfDataRoutersByCollectionNamespaceOrByNamespacelessCollectionId(
      dataRouters: AnyFixMe,
    ) {
      const tabsByCollectionNamespace = {};
      const tabsByNamespacelessCollectionId = {};
      const routelessPages: AnyFixMe = [];

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(dataRouters, (router) => {
        const routerRef = editorAPI.dsRead.routers.getRouterRef.byPrefix(
          router.prefix,
        );
        const { config, pages: pageIdByRole } =
          editorAPI.dsRead.routers.get.byRef(routerRef);

        router.pages.forEach((page: AnyFixMe) => {
          const pageRole = _.findKey(
            pageIdByRole,
            (pageId) => pageId === page.id,
          );

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line you-dont-need-lodash-underscore/find
          const route = _.find(config.patterns, { pageRole });
          if (route) {
            const collectionId = route.config.collection;
            let routerGroupingKey;
            let targetTabsByGroup: AnyFixMe;

            if (
              collectionNamespaceUtils.doesCollectionIdHaveNamespace(
                collectionId,
              )
            ) {
              routerGroupingKey =
                collectionNamespaceUtils.getNamespaceFromCollectionId(
                  collectionId,
                );
              targetTabsByGroup = tabsByCollectionNamespace;
            } else {
              routerGroupingKey = collectionId;
              targetTabsByGroup = tabsByNamespacelessCollectionId;
            }

            if (!targetTabsByGroup[routerGroupingKey]) {
              targetTabsByGroup[routerGroupingKey] = {
                prefix: router.prefix,
                pages: [],
              };
            }
            targetTabsByGroup[routerGroupingKey].pages.push(page);
          } else {
            routelessPages.push(page);
          }
        });
      });

      return {
        tabsByCollectionNamespace,
        tabsByNamespacelessCollectionId,
        routelessPages,
      };
    }

    function getMenuItemLabel(
      name: AnyFixMe,
      applicationStringValue: AnyFixMe,
    ) {
      return `${_.upperFirst(name)} ${translate(
        'DYNAMIC_PAGES_ROUTER_PAGES',
      )} (${applicationStringValue})`;
    }

    function createMenuItem({
      id,
      label,
      prefix,
      routerType,
      pages,
    }: {
      id: string;
      label: string;
      prefix?: string;
      routerType: string;
      pages: Page[];
    }) {
      return {
        id,
        isRoot: true,
        isVisible: true,
        isVisibleMobile: true,
        label,
        prefix,
        routerType,
        type: { isRouter: true },
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/map
        items: _.map(pages, _.partial(getDynamicPageMenuItem, routerType)),
      };
    }

    function createMenuItemsForRouters(routers: AnyFixMe) {
      return _(routers)
        .reject('hide')
        .map((router, key) =>
          createMenuItem({
            id: `router_${key}`,
            label:
              router.label ||
              getMenuItemLabel(
                router.prefix,
                getApplicationStringValue(
                  router.appDefinitionId,
                  router.group,
                  defaultApplicationStringValue ??
                    translate('DYNAMIC_PAGES_APP_TYPE_CUSTOM_ROUTER'),
                ),
              ),
            prefix: router.prefix,
            routerType: getApplicationType(router.appDefinitionId),
            pages: router.pages,
          }),
        )
        .value();
    }

    function createMenuItemsForPagesGroupedByCollectionNamespace(
      tabsByCollectionNamespace: AnyFixMe,
    ) {
      const collections = getCollections(editorAPI);
      const namespaceToDisplayName = collections.reduce((ns, collection) => {
        ns[collection.namespace] = collection.displayNamespace;
        return ns;
      }, {} as AnyFixMe);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      return _(tabsByCollectionNamespace)
        .map((tab, collectionNamespace) =>
          createMenuItem({
            id: `data_router_group_col_ns_${collectionNamespace}`,
            label: getMenuItemLabel(
              namespaceToDisplayName[collectionNamespace] ||
                collectionNamespace,
              getDataBindingApplicationStringValue(),
            ),
            prefix: tab.prefix,
            routerType: constants.ROUTER_TYPES.DATA_BINDING,
            pages: _.sortBy(tab.pages, 'title'),
          }),
        )
        .sortBy('label')
        .value();
    }

    function createMenuItemsForPagesGroupedByCollectionId(
      tabsByCollectionId: AnyFixMe,
    ) {
      const collections = getCollections(editorAPI);
      const getDisplayNameForCollection = (collectionId: AnyFixMe) => {
        const collection = collections.find(({ id }) => id === collectionId);
        return collection?.displayName || collectionId;
      };
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      return _(tabsByCollectionId)
        .map((tab, collectionId) =>
          createMenuItem({
            id: `data_router_group_col_id_${collectionId}`,
            label: getMenuItemLabel(
              getDisplayNameForCollection(collectionId),
              getDataBindingApplicationStringValue(),
            ),
            prefix: tab.prefix,
            routerType: constants.ROUTER_TYPES.DATA_BINDING,
            pages: _.sortBy(tab.pages, 'title'),
          }),
        )
        .sortBy('label')
        .value();
    }

    function createMenuItemForBrokenDynamicPages(brokenDynamicPages: AnyFixMe) {
      return createMenuItem({
        id: 'broken_dynamic_pages',
        label: translate('Pages_Menu_Broken_Pages'),
        routerType: constants.ROUTER_TYPES.DATA_BINDING,
        pages: _.sortBy(brokenDynamicPages, 'title'),
      });
    }

    const routers = groupRoutersByRouterConfigGroup(
      editorAPI.dsRead.routers.pages.getDynamicPagesList(),
    );
    const { dataRouters, nondataRouters } =
      splitRoutersIntoDataAndNondata(routers);
    const {
      tabsByCollectionNamespace,
      tabsByNamespacelessCollectionId,
      routelessPages: brokenDynamicPages,
    } = groupPagesOfDataRoutersByCollectionNamespaceOrByNamespacelessCollectionId(
      dataRouters,
    );
    return [
      ...createMenuItemsForPagesGroupedByCollectionNamespace(
        tabsByCollectionNamespace,
      ),
      ...createMenuItemsForPagesGroupedByCollectionId(
        tabsByNamespacelessCollectionId,
      ),
      ...(brokenDynamicPages.length > 0
        ? [createMenuItemForBrokenDynamicPages(brokenDynamicPages)]
        : []),
      ...createMenuItemsForRouters(nondataRouters),
    ];
  }

  function getRouterPageType(pageId: AnyFixMe) {
    const routerData =
      editorAPI.dsRead.routers.getRouterDataForPageIfExist(pageId);
    if (!routerData) {
      return '';
    }

    return getApplicationStringValue(
      routerData.appDefinitionId,
      routerData.group,
    );
  }

  function getPageInfoAction(pageInfoAction: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(pageInfoAction, {
      title: translate('Pages_Actions_Page_Settings'),
      icon: 'settingsAction',
    });
  }

  function getSeoAction(seoAction: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(seoAction, {
      icon: 'seoSettingsAction',
    });
  }

  function filterAndProcessSetttingsActionsIfAplicable(
    actions: AnyFixMe,
    typesToFilter: AnyFixMe,
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    const filteredActions = _.filter(actions, ({ type }) =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/includes
      _.includes(typesToFilter, type),
    );
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const pageInfo = _.find(filteredActions, {
      type: platform.constants.pages.page_settings.pageInfo.type,
    });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    const seo = _.find(filteredActions, {
      type: platform.constants.pages.page_settings.seo.type,
    });
    const finalActions = [];
    if (pageInfo) {
      finalActions.push(getPageInfoAction(pageInfo));
    }
    if (seo) {
      finalActions.push(getSeoAction(seo));
    }
    return finalActions;
  }

  function getDynamicPageActions(
    pageId: AnyFixMe,
    category: AnyFixMe,
    typesToFilter: AnyFixMe,
  ) {
    const pageRef = editorAPI.dsRead.pages.getReference(pageId);
    if (!pageRef) {
      return [];
    }
    const routerRef = editorAPI.dsRead.routers.getRouterRef.byPage(pageRef);
    if (!routerRef) {
      return [];
    }
    const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);

    const actionsFromManifest =
      editorAPI.platform.routers.getDataFromRoutersAppManifest(
        routerData.appDefinitionId,
        category,
      );
    const convertedActions = convertActionsToSingleFormat(
      actionsFromManifest,
      category,
    );
    let processedActions = configureEditorActions(convertedActions);
    if (category === 'pageSettings' && !_.isEmpty(typesToFilter)) {
      processedActions = filterAndProcessSetttingsActionsIfAplicable(
        processedActions,
        typesToFilter,
      );
    }
    return processedActions || [];
  }

  function filterActionsIfNeeded(actions: AnyFixMe, routerData: AnyFixMe) {
    if (!_.isEmpty(routerData.pages)) {
      return actions ? _.reject(actions, { event: 'removeRouterClicked' }) : [];
    }

    return actions;
  }

  function getRouterActionByPrefix(prefix: AnyFixMe) {
    const routerRef = editorAPI.dsRead.routers.getRouterRef.byPrefix(prefix);
    if (!routerRef) {
      return [];
    }
    const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);
    const routerActions =
      editorAPI.platform.routers.getDataFromRoutersAppManifest(
        routerData.appDefinitionId,
        //@ts-expect-error
        'routerActions',
      );
    const convertedActions = convertActionsToSingleFormat(
      routerActions,
      'routerActions',
    );
    const processedActions = configureEditorActions(convertedActions);
    const filteredActions = filterActionsIfNeeded(processedActions, routerData);
    return filteredActions || [];
  }

  function getRouterViewActionsByPrefix(prefix: string) {
    const routerRef: RouterRef =
      editorAPI.dsRead.routers.getRouterRef.byPrefix(prefix);

    if (!routerRef) {
      return null;
    }

    const routerData = editorAPI.dsRead.routers.get.byRef(routerRef);

    return editorAPI.platform.routers.getDataFromRoutersAppManifest(
      routerData.appDefinitionId,
      'routerViewActions',
      null,
    );
  }

  function getActionByAppDefId(
    appDefinitionId: AnyFixMe,
    category: AnyFixMe,
    typesToFilter: AnyFixMe,
  ) {
    const actions = editorAPI.platform.routers.getDataFromRoutersAppManifest(
      appDefinitionId,
      category,
    );
    const convertedActions = convertActionsToSingleFormat(actions, category);
    let processedActions = configureEditorActions(convertedActions);
    if (category === 'pageSettings' && !_.isEmpty(typesToFilter)) {
      processedActions = filterAndProcessSetttingsActionsIfAplicable(
        processedActions,
        typesToFilter,
      );
    }
    return processedActions || [];
  }

  function configureViewSiteMembersAction(uncompleteAction: AnyFixMe) {
    const siteIsTemplate = editorAPI.dsRead.generalInfo.isTemplate();

    if (!siteIsTemplate) {
      const metaSiteId = editorAPI.site.getMetaSiteId();
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      return _.assign(uncompleteAction, {
        title: translate(
          platform.constants.members.router_actions.view_site_members.title,
        ),
        icon: platform.constants.members.router_actions.view_site_members.icon,
        callback() {
          window.open(
            `https://www.wix.com/my-account/sites/${metaSiteId}/contacts/?tag=contacts-site_members_approved`,
          );
        },
      });
    }

    return null;
  }

  function configureOpenSignUpSettingsAction(uncompleteAction: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(uncompleteAction, {
      title: translate(
        platform.constants.members.router_actions.open_signup_settings.title,
      ),
      icon: platform.constants.members.router_actions.open_signup_settings.icon,
      callback() {
        editorAPI.panelManager.openPanel(
          'panels.focusPanels.siteMembersSettingsPanel',
          { panelName: 'panels.focusPanels.siteMembersSettingsPanel' },
          true,
        );
      },
    });
  }

  function configureRenameAction(uncompleteAction: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign(uncompleteAction, {
      icon: platform.constants.pages.page_actions.rename.icon,
      title: translate(platform.constants.pages.page_actions.rename.title),
    });
  }

  function configureDeleteAction(action: AnyFixMe) {
    return _.defaults(action, {
      icon: platform.constants.pages.page_actions.delete.icon,
      title: translate(platform.constants.pages.page_actions.delete.title),
    });
  }

  function configureEditorActions(actions: AnyFixMe) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _(actions)
      .map(function (action) {
        switch (action.type) {
          case platform.constants.members.router_actions.view_site_members.type:
            return configureViewSiteMembersAction(action);
          case platform.constants.members.router_actions.open_signup_settings
            .type:
            return configureOpenSignUpSettingsAction(action);
          case platform.constants.pages.page_actions.rename.type:
            return configureRenameAction(action);
          case platform.constants.pages.page_actions.delete.type:
            return configureDeleteAction(action);
          default:
            return action;
        }
      })
      .compact()
      .value();
  }

  /**
   * @deprecationInfo feel free to use. Will NOT be deprecated
   */
  function canAddStaticPage(staticPagesCount: AnyFixMe) {
    if (experiment.isOpen('se_tooManyPagesLimitation')) {
      const state = editorAPI.store.getState();
      if (
        stateManagement.pages.selectors.getIsEditorLoadedWithMoreThanMaxStaticPages(
          state,
        )
      ) {
        return true;
      }
      staticPagesCount =
        staticPagesCount || editorAPI.pages.getStaticPagesCount();
      return staticPagesCount < constants.STATIC_PAGES.MAX_STATIC_PAGES_ALLOWED;
    }
    return true;
  }

  const getApplicationSettings = (appDefId: AnyFixMe) =>
    editorAPI.platform.getPagesDataFromAppManifest(
      appDefId,
      'applicationSettings',
      {
        advanced: editorAPI.developerMode.isEnabled(),
        mobile: editorAPI.isMobileEditor(),
      },
    );

  const getPageSettings = (
    appDefId: AnyFixMe,
    typesToFilter: AnyFixMe,
    pageRef: AnyFixMe,
  ) => {
    const settings = editorAPI.platform.getPagesDataFromAppManifest(
      appDefId,
      'pageSettings',
      {
        advanced: editorAPI.developerMode.isEnabled(),
        mobile: editorAPI.isMobileEditor(),
      },
      pageRef,
    );

    if (!_.isEmpty(typesToFilter) && settings) {
      return filterAndProcessSetttingsActionsIfAplicable(
        settings,
        typesToFilter,
      );
    }
    return settings || [];
  };

  const excludeDynamicPageActions = (
    actions: PagesDataFromAppManifest[],
    pageRef: CompRef,
  ) => {
    const pageData = editorAPI.components.data.get(pageRef);
    const isDynamic = core.utils.dynamicTypeUtils.isDynamicPage(
      editorAPI,
      pageData,
    );

    if (!isDynamic) return actions;

    const SET_AS_DYNAMIC_PAGE_EVENT = 'setAsADynamicPage';
    return actions?.filter(
      (action) => action.event !== SET_AS_DYNAMIC_PAGE_EVENT,
    );
  };

  const getAppPageActions = (
    appDefId: AnyFixMe,
    category: AnyFixMe,
    pageRef?: AnyFixMe,
  ) => {
    const allActions = editorAPI.platform.getPagesDataFromAppManifest(
      appDefId,
      category,
      {
        advanced: editorAPI.developerMode.isEnabled(),
        mobile: editorAPI.isMobileEditor(),
      },
      pageRef,
    ) as PagesDataFromAppManifest[];

    const filteredActions = excludeDynamicPageActions(allActions, pageRef);
    const convertedActions = convertActionsToSingleFormat(
      filteredActions,
      category,
    );

    return configureEditorActions(convertedActions) || [];
  };

  const getPageActions = (appDefId: AnyFixMe, pageRef: AnyFixMe) =>
    getAppPageActions(appDefId, 'pageActions', pageRef);

  const getApplicationActions = (appDefId: AnyFixMe) =>
    getAppPageActions(appDefId, 'applicationActions');

  const getPageDescriptor = (appDefId: AnyFixMe, pageRef: AnyFixMe) =>
    editorAPI.platform.getPagesDataFromAppManifest(
      appDefId,
      'pageDescriptors',
      {
        advanced: editorAPI.developerMode.isEnabled(),
        mobile: editorAPI.isMobileEditor(),
      },
      pageRef,
    );

  const duplicateGroupsPermissions = (
    sourcePageId: AnyFixMe,
    destinationPageRef: AnyFixMe,
  ) => {
    const membersGroupsApi =
      pageSettings.utils.groupsApi.membersGroupsApi(editorAPI);
    return membersGroupsApi.duplicateGroupsPermissions(
      sourcePageId,
      destinationPageRef,
    );
  };

  const getFullPagesStructure = () => pagesAPI.getFullPagesStructure(editorAPI);

  const openAddPagePanel = (panelProps: AddPagePanelProps) =>
    pagesAPI.openAddPagePanel(editorAPI, panelProps);

  const getAppPagesActions = (
    pagesData: IAppPage[],
    onShowSettings: OnSettingsChangeHandler,
    onRename: OnRenameHandler,
    appDefId: AnyFixMe,
    biCategory: string,
  ) =>
    pagesAPI.appPages.getActions(
      editorAPI,
      pagesData,
      onShowSettings,
      onRename,
      appDefId,
      biCategory,
    );

  const getPopupsActions = (
    isDesktop: boolean,
    onItemAddedHandler: OnItemAddedHandler,
    onRenameHandler: OnRenameHandler,
  ) =>
    pagesAPI.popups.getActions(
      editorAPI,
      isDesktop,
      onItemAddedHandler,
      onRenameHandler,
    );

  const getMenusActions = (
    isDesktop: boolean,
    onSettingsChangeHandler: OnSettingsChangeHandler,
    onItemsAddedHandler: OnItemAddedHandler,
    onRenameHandler: OnRenameHandler,
    menuItem: IPageMenuItem,
    menu: IPagesPanelMenu,
  ) => {
    const isTranslating = stateManagement.multilingual.selectors.isTranslating(
      editorAPI.store.getState(),
    );
    return pagesAPI.menus.getActions(
      editorAPI,
      isDesktop,
      isTranslating,
      onSettingsChangeHandler,
      onItemsAddedHandler,
      onRenameHandler,
      menuItem,
      menu,
    );
  };

  const getRoutersActions = (
    pagesData: RouterPageData[],
    onSettingsChangeHandler: OnSettingsChangeHandler,
    isDesktop: boolean,
    biCategory: AnyFixMe,
  ) =>
    pagesAPI.routers.getActions(
      editorAPI,
      pagesData,
      onSettingsChangeHandler,
      isDesktop,
      biCategory,
    );

  const getApplicationPageSettingsTabs = (
    pageId: string,
  ): { title: string; type: string }[] => {
    const pageRef = editorAPI.dsRead.pages.getReference(pageId);
    const pageData = editorAPI.pages.data.get(pageId);
    if (pageData?.managingAppDefId) {
      return editorAPI.pages.appPages.getPageSettings(
        pageData.managingAppDefId,
        [],
        pageRef,
      );
    }
    return editorAPI.pages.dynamicPages.getDynamicPageActions(
      pageId,
      'pageSettings',
      [],
    );
  };

  const hasLayoutSettingsTab = (pageId: string) => {
    const applicationTabs = getApplicationPageSettingsTabs(pageId);

    if (!applicationTabs.length) return true;

    return applicationTabs
      .map((tab) => tab.type)
      .includes(constants.PAGE_SETTINGS_TABS.LAYOUT);
  };

  function duplicate(pageId: string) {
    const shouldAddMenuItem = true;
    return editorAPI.dsActions.pages.duplicate(
      pageId,
      shouldAddMenuItem,
      duplicatePageCodeConditionAPI.shouldDuplicatePageCode(),
    );
  }

  function getLoadingPageId() {
    const state = shellStore.getState();
    return pagesSelectors.getLoadingPageId(state);
  }
  function getIsLoading() {
    return !!getLoadingPageId();
  }
  function getLoadingTrigger() {
    const state = shellStore.getState();
    return pagesSelectors.getLoadingTrigger(state);
  }
  function setLoadingTrigger(trigger: Trigger) {
    return shellStore.dispatch(pagesActions.setTrigger(trigger));
  }

  return {
    hooks,
    add,
    duplicate,
    navigateTo,
    navigateToAsync,
    navigateToAndScroll,
    getCurrentPage,
    getCurrentUrlPageTitle,
    getCurrentUrlPageId,
    getFullPagesStructure,
    openAddPagePanel,
    actions: {
      appPages: getAppPagesActions,
      popups: getPopupsActions,
      menus: getMenusActions,
      routers: getRoutersActions,
    },
    getCustomErrorPage,
    hasCustomErrorPage,
    getPageTitle: getPageTitlePublic,
    remove,
    removeInternal,
    openDynamicPagesPopupIfNeeded,
    notifyDataBindingAppTooManyPages,
    canAddStaticPage,
    beforePagesRemoveHook,
    isPageExist,
    getPrimaryContainer,
    isHomePage,
    isDynamicPage,
    isParentPage,
    getChildPagesIds,
    getChildPagesTitles,
    isCustomErrorPage,
    serialize,
    data: {
      update: updateData,
    },
    background: {
      update: updateBackground,
    },
    transitions: {
      set: setTransition,
    },
    popupPages: {
      close,
      getCurrentPopup,
      getCurrentPopupData,
      getPopupContainer,
      getOpenBehaviorsForPopups,
      getOpenBehaviorsForPopupWithId,
      getDataListOrdered,
      isFullWidthPopupOpened,
    },
    dynamicPages: {
      getMenuItems,
      getRouterPageType,
      getDynamicPageActions,
      getRouterActionByPrefix,
      getRouterViewActionsByPrefix,
      getActionByAppDefId,
    },
    appPages: {
      getApplicationSettings,
      getApplicationActions,
      getPageActions,
      getPageSettings,
      getPageDescriptor,
    },
    settings: {
      hasLayoutSettingsTab,
      getApplicationPageSettingsTabs,
      duplicateGroupsPermissions,
    },
    loading: {
      getPageId: getLoadingPageId,
      getTrigger: getLoadingTrigger,
      setTrigger: setLoadingTrigger,
      getIsLoading,
    },
  };
}

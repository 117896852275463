import React, { useEffect, type FC } from 'react';

import { frames } from '#packages/panels';
import { translate } from '#packages/i18n';
import { biLogger } from '#packages/util';

import { Checkbox, Text } from '@wix/wix-base-ui';
import { symbol as Symbol } from '@wix/santa-editor-symbols';
import { clickOnPanel } from '@wix/bi-logger-editor/v2';

import styles from './PublishErrorPanel.scss';
import {
  TRANSLATIONS,
  PUBLISH_ERROR_PANEL_NON_DEV,
  dataHooks,
} from './constants';
import type { PublishErrorPanelNonDevUIProps } from './PublishErrorPanelTypes';

const PublishErrorPanelNonDevUI: FC<PublishErrorPanelNonDevUIProps> = ({
  isIgnorePublishErrorValue,
  setIsIgnorePublishErrorValue,
  handleClose,
  handlePublishAnyway,
  onHelp,
  sendBiOnOpen,
}) => {
  useEffect(() => {
    sendBiOnOpen(PUBLISH_ERROR_PANEL_NON_DEV);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    biLogger.report(
      //@ts-expect-error
      clickOnPanel({
        panel_name: PUBLISH_ERROR_PANEL_NON_DEV,
        action_name: isIgnorePublishErrorValue
          ? 'checbox click'
          : 'checbox unclick',
      }),
    );
  }, [isIgnorePublishErrorValue]);

  return (
    <frames.MessagePanelFrame
      panelName="publish-error-non-dev-panel"
      dataHook={dataHooks.publishErrorNonDevPanel}
      onHelp={() => onHelp(PUBLISH_ERROR_PANEL_NON_DEV)}
      title={translate(TRANSLATIONS.NON_DEV.TITLE)}
      illustration={<Symbol name="installer-builder-empty-state" />}
      confirmOnEnterPress
      cancelOnEscPress
      cancelOnClickOutside
      sideActions={
        <Checkbox
          label={TRANSLATIONS.NON_DEV.SKIP_STEP_CHECKBOX}
          labelAfterSymbol
          value={isIgnorePublishErrorValue}
          vAlign="CENTER"
          onChange={setIsIgnorePublishErrorValue}
        />
      }
      confirmLabel={translate(TRANSLATIONS.NON_DEV.PRIMARY_CTA)}
      cancelLabel={translate(TRANSLATIONS.NON_DEV.SECONDARY_CTA)}
      onCancel={() =>
        handleClose('return_to_editor', PUBLISH_ERROR_PANEL_NON_DEV)
      }
      onSecondaryAction={() =>
        handlePublishAnyway('publish_anyway', PUBLISH_ERROR_PANEL_NON_DEV)
      }
      onConfirm={() => handleClose('button', PUBLISH_ERROR_PANEL_NON_DEV)}
    >
      <Text skin="secondary" enableEllipsis={false}>
        {TRANSLATIONS.NON_DEV.DESCRIPTION_1}
      </Text>

      <div className={styles.wrapper_nonDevMode}>
        <Text skin="secondary" enableEllipsis={false}>
          {TRANSLATIONS.NON_DEV.DESCRIPTION_2}
        </Text>
      </div>
    </frames.MessagePanelFrame>
  );
};

export default PublishErrorPanelNonDevUI;

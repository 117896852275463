// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['time_input_design_discover_more_label'],
      sections: [addPanel.sections.getSections().developer.timePickerSection],
    };
  },
};

import * as addPanel from '#packages/oldAddPanel';
import addPanelDataConsts from '#packages/addPanelDataConsts';
import databaseWelcome from './databaseWelcome/databaseWelcome';

const databaseWelcomeSection = {
  type: addPanelDataConsts.SECTIONS_TYPES.GENERAL,
  hide: false,
  title: 'add_section_label_CMS',
  showSectionHeader: true,
  props: {
    class: 'no-space-before-section invisible-header',
    template: databaseWelcome,
    action: addPanelDataConsts.CLICK_ACTION.DATA.ADD_COLLECTION,
  },
  help: {
    hide: false,
    text: 'add_section_label_database_tooltip_description',
  },
};

export default {
  getCategorySections() {
    return Object.assign(
      { databaseWelcomeSection },
      addPanel.dataUtil.mergeWithCommonSections(
        addPanel.sections.getSections().database,
        {},
      ),
    );
  },
};

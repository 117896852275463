import _ from 'lodash';
import * as stateManagement from '#packages/stateManagement';
import * as platformEvents from 'platformEvents';
import * as componentsUtils from './componentsUtils';
import * as util from '#packages/util';
import type { CompRef } from 'types/documentServices';
const { asArray } = util.array;
const { getBehaviors } = stateManagement.components.selectors;
const { applyForAll } = util.array;

function create(editorAPI: AnyFixMe) {
  function update(
    compRefs: AnyFixMe,
    behavior: AnyFixMe,
    actionName?: AnyFixMe,
    viewMode?: AnyFixMe,
  ) {
    applyForAll((compRef: AnyFixMe) => {
      editorAPI.dsActions.components.behaviors.update(
        compRef,
        behavior,
        actionName,
        viewMode,
      );
      editorAPI.dsActions.platform.notifyAppsOnCustomEvent(
        platformEvents.factory.componentBehaviorChanged({ compRef }),
      );
      componentsUtils.sendBiIfRefComponent(
        editorAPI,
        compRef,
        componentsUtils.REF_COMPONENT_CHANGE_TYPE.BEHAVIORS,
      );
    })(compRefs);
  }

  function get(compRefs: AnyFixMe) {
    return getBehaviors(compRefs, editorAPI.dsRead);
  }

  function remove(
    compRefs: AnyFixMe,
    behaviorName: AnyFixMe,
    actionName: AnyFixMe,
    viewMode?: AnyFixMe,
  ) {
    applyForAll((compRef: AnyFixMe) =>
      editorAPI.dsActions.components.behaviors.remove(
        compRef,
        behaviorName,
        actionName,
        viewMode,
      ),
    )(compRefs);
  }

  function removeAll(compRefs: AnyFixMe) {
    applyForAll(editorAPI.dsActions.components.behaviors.removeAll, compRefs);
  }

  function previewAnimation(
    compRefs: CompRef | CompRef[],
    animationDef: AnyFixMe,
    onComplete: AnyFixMe,
  ) {
    const compsArr = asArray(compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const sequenceIds = _.map(compsArr, (compRef, index) =>
      editorAPI.dsActions.components.behaviors.previewAnimation(
        compRef,
        _.cloneDeep(animationDef),
        index === compsArr.length - 1 ? onComplete : _.noop,
      ),
    );

    return sequenceIds.length > 1 ? sequenceIds : _.head(sequenceIds);
  }

  function stopPreviewAnimation(sequenceIds: AnyFixMe, seek = 1) {
    _(sequenceIds)
      .castArray()
      .forEach((id) =>
        editorAPI.dsActions.components.behaviors.stopPreviewAnimation(id, seek),
      );
  }

  function execute(
    compRefs: CompRef | CompRef[],
    behaviorName: string,
    params?: object,
    callback?: () => void,
  ) {
    if (asArray(compRefs).length > 1) {
      callback = _.noop;
    }
    applyForAll((compRef: AnyFixMe) =>
      editorAPI.dsActions.components.behaviors.execute(
        compRef,
        behaviorName,
        params,
        callback,
      ),
    )(compRefs);
  }

  function getRuntimeState(compRefs: AnyFixMe) {
    const compArr = util.array.asArray(compRefs);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    const runtimeStateArr = _.map(
      compArr,
      editorAPI.dsRead.components.behaviors.getRuntimeState,
    );
    return compArr.length > 1 ? runtimeStateArr : _.head(runtimeStateArr);
  }

  return {
    update,
    get,
    remove,
    removeAll,
    previewAnimation,
    stopPreviewAnimation,
    execute,
    getRuntimeState,
  };
}

export { create };

// @ts-nocheck
import constants from '#packages/constants';
import type { CompRef } from 'types/documentServices';
import * as gfppData from '#packages/gfppData';
import _ from 'lodash';
import * as platformEvents from 'platformEvents';
import type { EditorAPI } from '#packages/editorAPI';
import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';
import * as platform from '#packages/platform';
import * as platformConnectedGfpp from '../services/platformConnectedGfpp';
import * as upgradeUtils from '../utils/upgradeUtils';
import { buildAppGFPPWithViewStateGFPP } from './appViewState';
import experiment from 'experiment';

const { isReferredComponent } = stateManagement.components.selectors;
const { shouldWidgetHaveAddElements } = stateManagement.platform.selectors;
const { getWidgetSchema } = util.appStudioUtils;
const { ACTIONS } = constants.ROOT_COMPS.GFPP;

function sanitizePresetActions(presetActions, enabledActions) {
  return _.pick(presetActions, enabledActions);
}

function isActionEnabled(editorAPI, { presetActions }, actionType) {
  return !!presetActions[actionType];
}

function hideGfpp(compGfppData) {
  Object.assign(compGfppData, {
    mainActions: [],
    presetActions: [],
    enabledActions: [],
    mobileMainActions: [],
    mobileEnabledActions: [],
  });
}

const getSettingsPanelGfppDefinitionIfNeeded = (editorAPI, compRefs) => {
  const isRootWidget = (compRef) =>
    editorAPI.utils.isSameRef(
      compRef,
      util.appStudioUtils.getRootAppWidget(editorAPI),
    );
  if (util.appStudioUtils.isAppStudio() && isRootWidget(compRefs[0])) {
    return;
  }

  const widgetSchema = getWidgetSchema(editorAPI, compRefs[0]);
  if (!_.isEmpty(widgetSchema.propertiesSchemas)) {
    return {
      onClick() {
        editorAPI.closeCompPanelIfExists();
        editorAPI.store.dispatch(
          stateManagement.panels.actions.openComponentPanel(
            'compPanels.panels.Widget.schemaSettingsPanel',
          ),
        );
      },
      label: 'gfpp_tooltip_settings',
      automationId: 'gfpp-button-settings',
      shouldTranslate: true,
      className: 'widget-settings',
    };
  }
};

const wrapOnClick = (actions) =>
  _.mapValues(actions, (action) => {
    const appWidgetOnClick = action.onClick;
    if (appWidgetOnClick) {
      const onClick = (editorAPI, compRef, origin) => {
        const appWidget =
          editorAPI.components.getContainer_DEPRECATED_BAD_PERFORMANCE(compRef);
        appWidgetOnClick(editorAPI, [appWidget], origin);
      };
      return _.defaults({ onClick }, action);
    }
    return action;
  });

function getAppWidgetGfppDefinition(editorAPI: EditorAPI, compRefs: CompRef[]) {
  let notifyGfppButtonClicked;
  const componentRef = compRefs[0];

  const [widgetRootRef] = editorAPI.components.getChildren(componentRef);
  const resolveFunction = gfppData.utils.getGfppDataResolver(
    editorAPI,
    compRefs,
  );

  const compGfppData = {
    mainActions: [],
    enabledActions: [ACTIONS.ANIMATION],
    presetActions: {},
    mobileEnabledActions: [ACTIONS.ANIMATION, ACTIONS.HIDE],
  };

  const compData = editorAPI.components.data.get(componentRef);
  const appDefId = compData.applicationId || compData.appDefinitionId;
  const hasAppManifest = editorAPI.dsRead.platform.hasAppManifest(appDefId);

  if (!hasAppManifest) {
    hideGfpp(compGfppData);
    return compGfppData;
  }

  const deviceType = stateManagement.mobile.selectors.getDeviceType(
    editorAPI.store.getState(),
  );

  if (deviceType === 'desktop') {
    const shouldHaveAddElements = shouldWidgetHaveAddElements(
      editorAPI,
      componentRef,
    );

    if (shouldHaveAddElements) {
      compGfppData.enabledActions.push(ACTIONS.ADD);
      const panelName = isReferredComponent(componentRef)
        ? 'compPanels.panels.Widget.closeAddElementsPanel'
        : 'compPanels.panels.Widget.openAddElementsPanel';
      compGfppData.presetActions[ACTIONS.ADD] = {
        onClick: () => {
          gfppData.utils.toggleComponentPanel(editorAPI, panelName, {
            widgetRef: componentRef,
          });
        },
        isSelected: gfppData.utils.getPanelStateFn(panelName),
      };
    }
  }

  const gfppDataFromApp = buildAppGFPPWithViewStateGFPP(
    editorAPI,
    componentRef,
  );

  if (gfppDataFromApp) {
    const { applicationId } = editorAPI.platform.getAppDataByAppDefId(appDefId);
    const hasPremiumOffering =
      editorAPI.dsRead.tpa.app.hasPremiumOffering(applicationId);
    const GfppNotifyUtils = platform.getGfppNotifyUtils(
      editorAPI,
      componentRef,
      applicationId,
    );
    notifyGfppButtonClicked = GfppNotifyUtils.notifyGfppButtonClicked;

    const upgradeType = platform.platformConnectedGfpp.toUniversalGfppFormat(
      gfppDataFromApp[deviceType]?.iconButtons?.upgrade,
    );

    compGfppData.presetActions = compGfppData.presetActions || {};
    compGfppData.presetActions.upgrade = upgradeUtils.getPremiumGfpp(
      appDefId,
      upgradeType?.behavior,
    );

    if (deviceType === 'desktop') {
      compGfppData.enabledActions = compGfppData.enabledActions || [];
      compGfppData.enabledActions.push(ACTIONS.UPGRADE, ACTIONS.HELP);
      compGfppData.mainActions = platformConnectedGfpp.getMainActionsFromApp(
        deviceType,
        gfppDataFromApp,
      );
      compGfppData.enabledActions =
        platformConnectedGfpp.generateEnabledActions(
          deviceType,
          gfppDataFromApp,
          resolveFunction(compGfppData.enabledActions),
          hasPremiumOffering,
        );
    } else {
      compGfppData.mobileMainActions =
        platformConnectedGfpp.getMainActionsFromApp(
          deviceType,
          gfppDataFromApp,
        );
      compGfppData.mobileEnabledActions.push(ACTIONS.HELP);
      compGfppData.mobileEnabledActions =
        platformConnectedGfpp.generateEnabledActions(
          deviceType,
          gfppDataFromApp,
          resolveFunction(compGfppData.mobileEnabledActions),
        );
    }
    if (experiment.isOpen('se_gfppPresetActionsFix')) {
      compGfppData.presetActions =
        platformConnectedGfpp.generateIconButtonsOverride(
          deviceType,
          gfppDataFromApp,
          compGfppData,
          notifyGfppButtonClicked,
          componentRef,
          editorAPI,
        );
    } else {
      compGfppData.presetActionsData =
        platformConnectedGfpp.generateIconButtonsOverride(
          deviceType,
          gfppDataFromApp,
          compGfppData,
          notifyGfppButtonClicked,
          componentRef,
          editorAPI,
        );
    }
  }

  compGfppData.additionalCompForGfpp = widgetRootRef;

  compGfppData.mergeGfpp = (appWidgetGfppData, childGfppData) => {
    appWidgetGfppData.presetActions = {
      ...childGfppData.presetActions,
      ...wrapOnClick(appWidgetGfppData.presetActions),
    };

    const unifiedGfppDataFromApp =
      gfppDataFromApp || appWidgetGfppData.widgetGfppDataFromApp
        ? _.merge(gfppDataFromApp, appWidgetGfppData.widgetGfppDataFromApp)
        : undefined;

    if (unifiedGfppDataFromApp) {
      //remove this when settings panel is in EUA
      const settingsMainAction = getSettingsPanelGfppDefinitionIfNeeded(
        editorAPI,
        compRefs,
      );
      const deviceEnabledActions =
        deviceType === 'desktop'
          ? appWidgetGfppData.enabledActions
          : appWidgetGfppData.mobileEnabledActions;
      const currentEnabledActions = [
        ...deviceEnabledActions,
        ...Object.keys(childGfppData.presetActions || {}),
      ];
      const uniqueCurrentEnabledActions = [...new Set(currentEnabledActions)];

      appWidgetGfppData.enabledActions =
        platformConnectedGfpp.filterEnabledActions(
          uniqueCurrentEnabledActions,
          deviceType,
          unifiedGfppDataFromApp,
          true,
        );
      appWidgetGfppData.presetActions = sanitizePresetActions(
        appWidgetGfppData.presetActions,
        appWidgetGfppData.enabledActions,
      );

      childGfppData.mainActions = settingsMainAction
        ? [settingsMainAction].concat(childGfppData.mainActions)
        : childGfppData.mainActions;

      appWidgetGfppData.mainActions = platformConnectedGfpp.mergeMainActions(
        appWidgetGfppData,
        appWidgetGfppData.mainActions,
        deviceType,
        isActionEnabled,
        notifyGfppButtonClicked,
        componentRef,
        editorAPI,
        childGfppData,
      );
    } else {
      appWidgetGfppData.mainActions = [
        ...(appWidgetGfppData.mainActions || []),
        ...(childGfppData.mainActions || []),
      ]
        .filter(Boolean)
        .slice(0, 2);
    }
  };

  return compGfppData;
}

function init(registerGfpp) {
  registerGfpp('AppWidget', getAppWidgetGfppDefinition);
}

function notifyWidgetGfppClicked(
  {
    actionId: id,
    calleeRef,
    origin,
  }: { actionId: string; calleeRef?: CompRef; origin?: string },
  editorAPI: EditorAPI,
  [componentRef]: CompRef[],
) {
  const compData = editorAPI.components.data.get(componentRef);

  const { applicationId } = compData;

  const clickEvent = platformEvents.factory.widgetGfppClicked({
    id,
    componentRef: calleeRef || componentRef,
    gfppOrigin: origin,
  });

  editorAPI.platform.notifyApplication(applicationId, clickEvent);
}

export { init, notifyWidgetGfppClicked };

import React from 'react';
import { mapStateToProps, type BlockLayerProps } from './blockLayerMapper';
import { hoc, cx } from '#packages/util';
import { allowed } from '#packages/editorRestrictions';
import styles from './blockLayer.scss';

const BlockLayerComponent: React.FC<BlockLayerProps> = ({
  className,
  siteScale,
  previewHeight,
  isMobileEditor,
  isZoomModeTransitionActive,
  withShadow = false,
  shouldRemoveBottomBlockAndResizeShadow = false,
}) => {
  const [height, setHeight] = React.useState<number>(previewHeight);

  React.useEffect(() => {
    if (isZoomModeTransitionActive) return;
    setHeight(previewHeight);
  }, [previewHeight, isZoomModeTransitionActive]);

  return (
    <div
      className={cx(styles.container, className, {
        [styles.mobile]: isMobileEditor,
        [styles.withFullHeightShadow]: shouldRemoveBottomBlockAndResizeShadow,
      })}
      style={
        {
          '--siteScale': siteScale,
          '--previewHeight': `${height * siteScale}px`,
        } as React.CSSProperties
      }
      data-aid="block-layer"
    >
      <div className={cx(styles.side, styles.left)} />
      <div className={cx(styles.side, styles.right)} />
      {withShadow && <div className={styles.shadow} />}
      {!isMobileEditor && !shouldRemoveBottomBlockAndResizeShadow && (
        <div className={cx(styles.side, styles.bottom)} />
      )}
    </div>
  );
};

const ConnectedBlockLayer = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(BlockLayerComponent);

export const BlockLayer = hoc.withConditionalRender(
  hoc.STORES.EDITOR_API,
  ({ editorAPI }) => {
    return (
      editorAPI.zoomMode.isInZoomMode() ||
      allowed('rEditor_stage-block-layer.visible')
    );
  },
)(ConnectedBlockLayer);

import _ from 'lodash';
import * as util from '#packages/util';
import {
  applyModeFromClipboard,
  selection,
  hoverBox,
  sectionsOnStage,
  sections,
  mouseActions,
  domMeasurements,
  type EditorState,
} from '#packages/stateManagement';
import {
  WorkspaceApiKey,
  EditorRestrictionsApiKey,
  ReadonlyModeApiKey,
} from '#packages/apis';
import { hoverContainerComponent } from '@wix/bi-logger-editor/v2';

import type { EditorAPI } from '#packages/editorAPI';
import type { SectionWithLayout } from '#packages/sections';
import type { CompRef } from 'types/documentServices';
import type { Dispatch, ThunkAction, StateMapperArgs } from 'types/redux';

const { selectApplyModeFromClipboardSuggestion } =
  applyModeFromClipboard.selectors;
const { getAppContainer } = selection.selectors;
const { getHoveredComp, getHoverBoxOverlay } = hoverBox.selectors;
const { getSectionsOnStage, getHoveredSectionIndex } =
  sectionsOnStage.selectors;
const { setHoveredSectionLike } = sections.actions;
const { translateToViewerCoordinates } = domMeasurements.selectors;

const { isMultiselect } = util.array;

const FORBIDDEN_COMP_TYPES_TO_HOVER = [
  'wysiwyg.viewer.components.PagesContainer',
  'wysiwyg.viewer.components.PageGroup',
  'wysiwyg.common.components.anchor.viewer.Anchor',
];

export function isValidCompToHover(editorAPI: EditorAPI, comp: CompRef) {
  if (!editorAPI.components.is.exist(comp)) {
    return false;
  }
  const state = editorAPI.store.getState();
  const selectedComp = editorAPI.selection.getSelectedComponents();
  const compType = editorAPI.components.getType(comp);
  const focusedContainer = selection.selectors.getFocusedContainer(state);

  const canSelectCompConsideringSpotlightStage =
    util.spotlightStageUtils.canSelectCompConsideringSpotlightStage(
      editorAPI,
      focusedContainer,
      comp,
    );

  return (
    canSelectCompConsideringSpotlightStage &&
    (!editorAPI.selection.isComponentSelected(comp) ||
      isMultiselect(selectedComp)) &&
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    !_.includes(FORBIDDEN_COMP_TYPES_TO_HOVER, compType)
  );
}

const getIsCompDraggable = (
  editorAPI: EditorAPI,
  hoveredComp: CompRef,
  performingMouseMoveAction: boolean,
) => {
  if (performingMouseMoveAction) {
    return true;
  }

  if (hoveredComp && editorAPI.components.is.exist(hoveredComp)) {
    const isControlledByParent =
      editorAPI.components.is.controlledByParent(hoveredComp);
    return (
      isValidCompToHover(editorAPI, hoveredComp) &&
      editorAPI.components.is.draggable(
        isControlledByParent
          ? editorAPI.components.getContainerOrScopeOwner(hoveredComp)
          : hoveredComp,
      )
    );
  }

  return false;
};

const applyTransformationsToLayoutIfNeeded = (
  editorAPI: EditorAPI,
  sectionsLikeWithLayout: SectionWithLayout[],
) => {
  if (!editorAPI.zoomMode.isLeftShrinkedStageZoomOutActive())
    return sectionsLikeWithLayout;

  return sectionsLikeWithLayout.map(({ layout, ref }) => {
    const transformations = editorAPI.components.transformations.get(ref);
    const layoutWithTransformations = transformations?.translate?.y?.value
      ? { ...layout, y: layout.y + transformations.translate.y.value }
      : layout;

    return {
      ref,
      layout: layoutWithTransformations,
    };
  });
};

const getSectionLikeByCursorPosition = (
  currentPageSectionsLike: SectionWithLayout[],
  cursorPositionY: number,
  siteScale: number,
) =>
  currentPageSectionsLike.find(({ layout }) => {
    const top = layout.y * siteScale;
    const bottom = (layout.y + layout.height) * siteScale;
    return top <= cursorPositionY && cursorPositionY <= bottom;
  })?.ref;

const getSectionLikeToHover = (
  editorAPI: EditorAPI,
  compToHover: CompRef,
  currentPageSectionsLike: SectionWithLayout[],
  cursorPositionY: number,
  siteScale: number,
) => {
  if (!compToHover) return null;

  const sectionLikeToHover = getSectionLikeByCursorPosition(
    currentPageSectionsLike,
    cursorPositionY,
    siteScale,
  );

  const focusedContainer = selection.selectors.getFocusedContainer(
    editorAPI.store.getState(),
  );

  const canSelectCompConsideringSpotlightStage =
    util.spotlightStageUtils.canSelectCompConsideringSpotlightStage(
      editorAPI,
      focusedContainer,
      sectionLikeToHover,
    );

  if (
    !canSelectCompConsideringSpotlightStage ||
    (editorAPI.zoomMode.isLeftShrinkedStageZoomOutActive() &&
      editorAPI.sections.isEmptyState() &&
      editorAPI.sections.isSection(sectionLikeToHover))
  )
    return null;

  return sectionLikeToHover;
};

interface OwnProps {
  isDragging: boolean;
  isResizing: boolean;
  shouldShowPageMarginsIndicator: boolean;
  performingMouseMoveAction: boolean;
  siteScale: number;
  isPinMode: boolean;
  hoveredComp: AnyFixMe;
  selectedComponents: CompRef[];
}

let __forceRerender = 0;

export const mapStateToProps = (
  { state: editorState, editorAPI, host }: StateMapperArgs,
  ownProps: OwnProps,
) => {
  const { constraintArea, config, tabIndicationState } = editorState;

  const isCompDraggable = getIsCompDraggable(
    editorAPI,
    ownProps.hoveredComp,
    ownProps.performingMouseMoveAction,
  );

  const isStageZoomMode = editorAPI.zoomMode.isStageZoomMode();
  const shouldShowSpotlightStage = Boolean(
    editorAPI.spotlightStage.getContainer(),
  );

  const editorRestrictionsApi = editorAPI.host.getAPI(EditorRestrictionsApiKey);
  const readonlyModeApi = editorAPI.host.getAPI(ReadonlyModeApiKey);
  const areMouseEventsAllowed = editorRestrictionsApi.allowed(
    'rEditor_mouse-events.interactive',
  );

  const shouldShowCompControls =
    (!editorAPI.zoomMode.isInZoomMode() ||
      editorAPI.zoomMode.enabledCompControls()) &&
    Boolean(ownProps.selectedComponents?.length);

  const applyModeFromClipboardSuggestion =
    selectApplyModeFromClipboardSuggestion(editorState);
  const appContainer = getAppContainer(editorState);
  const hoveredComp = getHoveredComp(editorState);
  const hoverBoxOverlay = getHoverBoxOverlay(editorState);
  const sectionsOnStage = getSectionsOnStage(editorState);
  const hoveredSectionOnStageIndex = getHoveredSectionIndex(editorState);
  const MouseCatcherComponents = host
    .getAPI(WorkspaceApiKey)
    .getMouseCatcherComponents();
  const currentPage = editorAPI.pages.getCurrentPage();
  const currentPageSectionsLike = applyTransformationsToLayoutIfNeeded(
    editorAPI,
    editorAPI.sections.getPageSectionLikeWithLayout(currentPage, true),
  );
  const fixedStageEnabled = util.fixedStage.isFixedStageEnabled();

  return {
    __forceRerender: ++__forceRerender, //to force willReceiveProps, TODO remove
    MouseCatcherComponents,
    isCompDraggable,
    isStageZoomMode,
    isReadonlyMode: readonlyModeApi.isReadonlyModeEnabled(),
    shouldShowSpotlightStage,
    shouldShowCompControls,
    applyModeFromClipboardSuggestion,
    constraintArea,
    config,
    tabIndicationState,
    appContainer,
    hoveredComp,
    hoverBoxOverlay,
    sectionsOnStage,
    hoveredSectionOnStageIndex,
    previewMode: false, // Mouse Catcher render only on Stage, previewMode always false TODO remove it
    currentPageSectionsLike,
    areMouseEventsAllowed,
    fixedStageEnabled,
  };
};

const getStateAndEditorAPI: ThunkAction<{
  editorAPI: EditorAPI;
  getState: () => EditorState;
}> = (dispatch, getState, { editorAPI }) => ({ getState, editorAPI });

export const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
  const {
    editorAPI,
    getState,
  }: {
    editorAPI: EditorAPI;
    getState: () => EditorState;
  } = dispatch(getStateAndEditorAPI);

  return {
    getEditorAPI: () => editorAPI,
    setHoverBox: (compRef: CompRef, overlay: AnyFixMe) => {
      const newCompRef = editorAPI.utils.getParentIfCompCanBeSelected(compRef);
      const nextHoveredCompRef = newCompRef || compRef;
      const hoveredCompRef = getHoveredComp(getState());

      if (!_.isEqual(hoveredCompRef, nextHoveredCompRef)) {
        dispatch(
          hoverBox.actions.setHoverBox(nextHoveredCompRef, Boolean(overlay)),
        );
      }
    },
    setHoveredSectionOnStageIndex: (index: number) =>
      dispatch(sectionsOnStage.actions.setHoveredSectionIndex(index)),
    setHoveredSectionLike: (
      e: MouseEvent,
      compToHover: CompRef,
      currentPageSectionsLike: SectionWithLayout[],
    ) => {
      const currentHoveredSectionLike =
        editorAPI.sections.getHoveredSectionLike();
      const { pageY } = translateToViewerCoordinates(editorAPI, e);
      const sectionLikeRef = getSectionLikeToHover(
        editorAPI,
        compToHover,
        currentPageSectionsLike,
        pageY,
        ownProps.siteScale,
      );

      if (editorAPI.utils.isSameRef(sectionLikeRef, currentHoveredSectionLike))
        return;

      dispatch(setHoveredSectionLike(sectionLikeRef));

      if (sectionLikeRef) {
        const widgetsToExposeBO =
          editorAPI.sections.shouldShowBoExposerOnCurrentMode()
            ? editorAPI.sections.getExposeBOWidgets(sectionLikeRef)
            : [];

        const appIds = widgetsToExposeBO
          .map(
            (ref: CompRef) =>
              editorAPI.components.data.get(ref)?.appDefinitionId,
          )
          .filter(Boolean);

        util.biLogger.report(
          hoverContainerComponent({
            app_id: appIds.join(','),
            component_id: sectionLikeRef.id,
            component_type: editorAPI.components.getType(sectionLikeRef),
            compName: editorAPI.sections.getSectionName(sectionLikeRef),
            component_value: widgetsToExposeBO
              .map(editorAPI.components.getType)
              .join(','),
          }),
        );
      }
    },
    clearHoverBox: () => {
      const hoveredCompRef = getHoveredComp(getState());

      if (hoveredCompRef) {
        dispatch(hoverBox.actions.clearHoverBox());
      }
    },

    setIsMouseOverStage: (isMouseOverStage: boolean) => {
      dispatch(mouseActions.actions.setIsMouseOverStage(isMouseOverStage));
    },
  };
};

// @ts-nocheck
import React from 'react';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as symbols from '@wix/santa-editor-symbols';
import pageSettingsPanelConstants from '../../utils/constants';
import * as permissions from '../../utils/permissions';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import pageSettingsTabMixin from './pageSettingsTabMixin';
import * as groupsApi from '../../utils/groupsApi';
import { PERMISSIONS_TAB_HOOKS } from '../../utils/dataHooks';

import CONSTANTS from '../../utils/constants';
import experiment from 'experiment';
import {
  ActionWithSymbol,
  Composites,
  CustomScroll,
  Divider,
  RadioButtons,
  RichText,
  TextInput,
  TextLabel,
  Thumbnails,
} from '@wix/wix-base-ui';

const { MEMBER_ACCESS_PERMISSIONS, PRICING_PLANS_APP_DEF_ID, INPUT_TYPES } =
  pageSettingsPanelConstants;

const getTitles = ({ groups, type }) =>
  groups
    .filter((group) => group.checked && (!group.type || group.type === type))
    .map(({ title }) => title)
    .join(', ');

const sendPrivacyChangeBI = function (editorAPI, pageId, privacy) {
  editorAPI.bi.event(
    coreBi.events.topbar.pages.top_bar_PAGES_settings_privacy_changed,
    {
      privacy,
      page_id: pageId,
    },
  );
};

const updatePageSecurityData = function (
  editorAPI,
  pageId,
  data,
  privacyTypeBI,
) {
  editorAPI.pages.data.update(pageId, { pageSecurity: data });
  sendPrivacyChangeBI(editorAPI, pageId, privacyTypeBI);
};

const updatePagePassword = function (editorAPI, pageId, plainPassword) {
  editorAPI.pages.permissions.updatePassword(pageId, plainPassword);
};

const removePagePassword = function (editorAPI, pageId) {
  editorAPI.pages.permissions.removePassword(pageId);
};

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'tabPermissionsNew',
  mixins: [pageSettingsTabMixin],
  getInitialState() {
    return this.getPageState(this.props);
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pageData.id !== this.state.pageId) {
      this.setState(this.getPageState(nextProps), () =>
        this.refreshRoles(nextProps.pageData.id),
      );
    }
  },

  componentDidMount() {
    this.mounted = true;
    this.refreshRoles(this.props.pageData.id);
  },

  componentWillUnmount() {
    this.mounted = false;
  },

  getPageState(props) {
    return {
      focusedProtectionType: this.getInitialProtectionType(props),
      shouldShowProtectionType: this.getShouldShowProtectionType(),
      passwordDialogLanguage: this.getInitialLanguage(props),
      membersDialogLanguage: this.getInitialLanguage(props),
      pageId: props.pageData.id,
      isNewValidPasswordInserted: false,
      passwordInputType: INPUT_TYPES.PASSWORD,
      plainPassword: '',
      membersAccessPermissions: MEMBER_ACCESS_PERMISSIONS.ALL,
      groupsForPage: [],
      availableRoles: [groupsApi.getAdminGroup()],
      availablePlans: [],
      availableBadges: [],
      permissions: this.getEditorAPI().dsRead.generalInfo.getUserPermissions(),
    };
  },

  getInitialProtectionType(props) {
    return permissions.getProtectionType(this.getEditorAPI(), props.pageData);
  },

  getShouldShowProtectionType() {
    const dsRead = this.getEditorAPI().dsRead;
    const pageRef = dsRead.pages.getFocusedPage();
    const routerRef = dsRead.routers.getRouterRef.byPage(pageRef);

    return (
      !routerRef ||
      dsRead.routers.get.byRef(routerRef)?.config?.type !== 'private'
    );
  },

  isPageProtectedWithPassword(props) {
    return permissions.isPageProtectedWithPassword(
      this.getEditorAPI(),
      props.pageData,
    );
  },

  savePageSecurityData() {
    const pageId = this.props.pageData.id;
    const pageSecurityUpdater = _.partialRight(
      updatePageSecurityData,
      this.state.focusedProtectionType,
    );
    switch (this.state.focusedProtectionType) {
      case pageSettingsPanelConstants.PERMISSION_STATES.NONE:
        pageSecurityUpdater(this.getEditorAPI(), pageId, {
          requireLogin: false,
        });
        removePagePassword(this.getEditorAPI(), pageId);

        if (experiment.isOpen('specs.ident.UpdateMembersPermsOnEditor')) {
          this.resetMembersAccess();
        }

        break;
      case pageSettingsPanelConstants.PERMISSION_STATES.PASSWORD:
        if (this.state.isNewValidPasswordInserted) {
          updatePagePassword(
            this.getEditorAPI(),
            pageId,
            this.state.plainPassword,
          );
        }
        if (
          this.state.isNewValidPasswordInserted ||
          this.isPageProtectedWithPassword(this.props)
        ) {
          pageSecurityUpdater(this.getEditorAPI(), pageId, {
            requireLogin: false,
            dialogLanguage: this.state.passwordDialogLanguage,
          });
        }
        break;
      case pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS:
        pageSecurityUpdater(this.getEditorAPI(), pageId, {
          requireLogin: true,
          dialogLanguage: this.state.membersDialogLanguage,
        });
        removePagePassword(this.getEditorAPI(), pageId);

        if (experiment.isOpen('specs.ident.UpdateMembersPermsOnEditor')) {
          this.handleMembersAccessChange(this.state.membersAccessPermissions);
        }

        break;
    }
  },

  getThumbnailOptions() {
    return [
      {
        illustration: React.createElement(symbols.symbol, {
          name: 'everyonePermissionThumbnail',
        }),
        value: pageSettingsPanelConstants.PERMISSION_STATES.NONE,
        label: 'NewPages_Panel_Permissions_Public_Label',
      },
      {
        illustration: React.createElement(symbols.symbol, {
          name: 'passwordPermissionThumbnail',
        }),
        value: pageSettingsPanelConstants.PERMISSION_STATES.PASSWORD,
        label: 'NewPages_Panel_Permissions_Password_Label',
      },
      {
        illustration: React.createElement(symbols.symbol, {
          name: 'membersPermissionThumbnail',
        }),
        value: pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS,
        label: 'NewPages_Panel_Permissions_Members_Label',
      },
    ];
  },

  setFocusedProtectionType(type) {
    this.setState(
      {
        focusedProtectionType: type,
      },
      this.savePageSecurityData,
    );
  },

  /**********************************************/
  /****************** members login *************/
  /**********************************************/
  isSiteSaved() {
    return (
      !this.getEditorAPI().dsRead.generalInfo.isFirstSave() &&
      !this.getEditorAPI().dsRead.generalInfo.isDraft()
    );
  },

  getCanCreateNewRole() {
    return this.state.permissions?.includes('my-account.view-site-members');
  },

  getCanCreatePaidPlan() {
    return this.state.permissions?.includes('paid-plans.manage');
  },

  getCanCreateNewBadge() {
    return this.state.permissions?.includes('badges.manage');
  },

  getSiteMembersUrl() {
    const MY_ACCOUNT_BASE_URL = '//www.wix.com/dashboard/';
    const metaSiteId = this.getEditorAPI().dsRead.generalInfo.getMetaSiteId();
    return `${MY_ACCOUNT_BASE_URL}${metaSiteId}/member-permissions/members/`;
  },

  getInitialLanguage(props) {
    const defaultLanguage =
      this.getEditorAPI().dsRead.generalInfo.getLanguage();
    const savedLanguage = props?.pageData?.pageSecurity?.dialogLanguage;
    return _.isEmpty(savedLanguage) ? defaultLanguage : savedLanguage;
  },

  /**********************************************/
  /****************** password ******************/
  /**********************************************/
  isValidPassword(plainPassword) {
    return util.validate.notEmpty(plainPassword);
  },

  isPasswordHidden() {
    return this.state.passwordInputType === INPUT_TYPES.PASSWORD;
  },

  shouldShowPasswordMockElement() {
    return (
      this.isPasswordHidden() &&
      _.isEmpty(this.state.plainPassword) &&
      this.isPageProtectedWithPassword(this.props)
    );
  },

  onPasswordBlur(event) {
    const inputActualValue = event.target.value;
    this.setState(
      {
        plainPassword: inputActualValue,
        isNewValidPasswordInserted: this.isValidPassword(inputActualValue),
        passwordInputType: INPUT_TYPES.PASSWORD,
      },
      this.savePageSecurityData,
    );
  },

  onPasswordFocus() {
    if (this.state.passwordInputType !== INPUT_TYPES.TEXT) {
      this.setState({
        passwordInputType: INPUT_TYPES.TEXT,
      });
    }
  },

  /*****************************************************/
  /****************** dialog language ******************/
  /*****************************************************/
  getDialogLanguageType() {
    const types = {};
    types[pageSettingsPanelConstants.PERMISSION_STATES.PASSWORD] =
      'passwordDialogLanguage';
    types[pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS] =
      'membersDialogLanguage';
    return _.get(types, this.state.focusedProtectionType);
  },
  setDialogLanguage(dialogLanguageType, language) {
    const stateToSet = {};
    stateToSet[dialogLanguageType] = language;
    this.setState(stateToSet, this.savePageSecurityData);
    this.getEditorAPI().bi.event(
      coreBi.events.topbar.pages
        .top_bar_PAGES_settings_privacy_language_selected_click,
      {
        language_selected: language,
        category: this.state.focusedProtectionType,
      },
    );
  },
  getHandleChangeDialogLanguage(dialogLanguageType) {
    return function (newValue) {
      if (this.state[dialogLanguageType] !== newValue) {
        this.setDialogLanguage(dialogLanguageType, newValue);
      }
    }.bind(this);
  },
  handleClickLearnMore() {
    this.getEditorAPI().panelManager.openHelpCenter(
      this.getLearnMoreHelpId(),
      null,
      {
        origin: constants.BI.HELP.ORIGIN.PAGES_PANEL,
        learn_more: true,
      },
    );
  },
  getLearnMoreHelpId() {
    const helpIds = {};
    helpIds[pageSettingsPanelConstants.PERMISSION_STATES.NONE] =
      'e34561a8-1323-4cbb-ad09-ca215e083050';
    helpIds[pageSettingsPanelConstants.PERMISSION_STATES.PASSWORD] =
      '89081a7e-ebec-4553-af0f-0ad6aa21b0c5';
    helpIds[pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS] =
      'e3004c6c-234e-4819-9333-9178d26358f3';

    return _.get(helpIds, this.state.focusedProtectionType);
  },
  getPermissionDescription() {
    const descriptions = {};
    descriptions[pageSettingsPanelConstants.PERMISSION_STATES.NONE] =
      'NewPages_Panel_Permissions_Public_Text';
    descriptions[pageSettingsPanelConstants.PERMISSION_STATES.PASSWORD] =
      'NewPages_Panel_Permissions_Password_Title';
    descriptions[pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS] =
      'NewPages_Panel_Permissions_Members_Text';

    return _.get(descriptions, this.state.focusedProtectionType);
  },
  handleChangePermission(newProtectionType) {
    if (this.state.focusedProtectionType !== newProtectionType) {
      this.setFocusedProtectionType(newProtectionType);
    }
  },

  openSiteMembersSettingsPanel() {
    const editorApi = this.getEditorAPI();
    const panelName = 'panels.focusPanels.siteMembersSettingsPanel';
    editorApi.panelManager.openPanel(panelName, { panelName }, true);
  },

  isPricingPlansOptionsOn() {
    return (
      this.state.focusedProtectionType ===
      pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS
    );
  },

  isBadgesOptionsOn() {
    const isAvailableForMemberOnly =
      this.state.focusedProtectionType ===
      pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS;

    return isAvailableForMemberOnly && this.state.availableBadges.length > 0;
  },

  shouldShowRolesForPage() {
    return (
      this.state.focusedProtectionType ===
      pageSettingsPanelConstants.PERMISSION_STATES.MEMBERS
    );
  },

  getEditRolesSymbol() {
    return React.createElement(symbols.symbol, {
      name: 'pencil',
    });
  },

  getMembersAccessPermissions(pageId, groupsForPage, adminRestrictedPage) {
    // groups for page is empty for all members, this is why we also check admins
    // if it is restricted by amy group, admins is included in groupsForPage
    const hasSpecific =
      !_.isEmpty(groupsForPage) ||
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      _.some(adminRestrictedPage, (id) => id === pageId);
    return hasSpecific
      ? MEMBER_ACCESS_PERMISSIONS.SPECIFIC
      : MEMBER_ACCESS_PERMISSIONS.ALL;
  },

  refreshRoles(pageId) {
    if (!this.isSiteSaved()) {
      const groupsForPage = this.shouldDisplaySpecificAccessOptions()
        ? [groupsApi.getAdminGroup]
        : [];
      this.setState({ groupsForPage });
      return;
    }
    const membersGroupsApi = groupsApi.membersGroupsApi(this.getEditorAPI());
    return Promise.all([
      membersGroupsApi.getGroupsForPage({ pageId }),
      membersGroupsApi.getPagesOnlyForAdmin(),
      membersGroupsApi.getAvailableRoles(),
      membersGroupsApi.getAvailablePlans(),
      membersGroupsApi.getAvailableBadges(),
    ]).then(
      ([
        groupsForPage,
        adminRestrictedPage,
        availableRoles,
        availablePlans,
        availableBadges,
      ]) => {
        if (!this.mounted) {
          return;
        }
        const membersAccessPermissions = this.getMembersAccessPermissions(
          pageId,
          groupsForPage,
          adminRestrictedPage,
        );
        groupsForPage = groupsApi.ensureAdminsRole(groupsForPage);
        availableRoles = groupsApi.ensureAdminsRole(availableRoles);
        this.setState({
          groupsForPage,
          availableRoles,
          availablePlans,
          availableBadges,
          membersAccessPermissions,
        });
      },
    );
  },

  getRolesActionLabel() {
    const { groupsForPage } = this.state;
    return getTitles({ groups: groupsForPage, type: 'role' });
  },

  getPlansActionLabel() {
    const { groupsForPage } = this.state;
    return (
      getTitles({ groups: groupsForPage, type: 'plan' }) ||
      `${translate('NewPages_Panel_Permissions_Member_Plans_Empty')}`
    );
  },

  getBadgesActionLabel() {
    const { groupsForPage } = this.state;
    return (
      getTitles({ groups: groupsForPage, type: groupsApi.badgeGroupType }) ||
      `${translate('NewPages_Panel_Permissions_Member_Badges_Empty')}`
    );
  },

  openRolesPageModal() {
    const editorAPI = this.getEditorAPI();
    editorAPI.saveManager.saveInBackground(
      () => {
        editorAPI.panelManager.closePanelByName(
          'panels.toolPanels.rolesManagerPanel',
          'out_of_focus',
        );
        const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
        const url = `//www.wix.com/dashboard/${metaSiteId}/member-permissions/roles/manage-permissions?referralInfo=editor&viewMode=editor`;
        const modalProps = {
          url,
          onClose: () => this.refreshRoles(this.props.pageData.id),
          helpId: '',
          isBareMode: true,
          width: '100%',
          height: '100%',
        };
        editorAPI.panelManager.openPanel(
          'tpa.compModals.tpaSettingsModal',
          modalProps,
          true,
        );
      },
      _.noop,
      'openRolesPageModal',
    );
  },

  openPlansPageModal() {
    const editorAPI = this.getEditorAPI();
    editorAPI.saveManager.saveInBackground(
      () => {
        editorAPI.panelManager.closePanelByName(
          'panels.toolPanels.rolesManagerPanel',
          'out_of_focus',
        );
        const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
        const url = `//www.wix.com/dashboard/${metaSiteId}/pricing-plans/new?referralInfo=editor&viewMode=editor`;
        const modalProps = {
          url,
          onClose: () => this.refreshRoles(this.props.pageData.id),
          helpId: '',
          isBareMode: true,
          width: '100%',
          height: '100%',
        };
        editorAPI.panelManager.openPanel(
          'tpa.compModals.tpaSettingsModal',
          modalProps,
          true,
        );
      },
      _.noop,
      'openPlansPageModal',
    );
  },

  openBadgesPageModal() {
    const editorAPI = this.getEditorAPI();
    editorAPI.saveManager.saveInBackground(
      () => {
        editorAPI.panelManager.closePanelByName(
          'panels.toolPanels.rolesManagerPanel',
          'out_of_focus',
        );
        const metaSiteId = editorAPI.dsRead.generalInfo.getMetaSiteId();
        const url = `//www.wix.com/dashboard/${metaSiteId}/badge-definitions/new?referralInfo=editor&viewMode=editor`;
        const modalProps = {
          url,
          onClose: () => this.refreshRoles(this.props.pageData.id),
          helpId: '',
          isBareMode: true,
          width: '100%',
          height: '100%',
        };
        editorAPI.panelManager.openPanel(
          'tpa.compModals.tpaSettingsModal',
          modalProps,
          true,
        );
      },
      _.noop,
      'openBadgesPageModal',
    );
  },

  getMembersAccessOptions() {
    return [
      {
        value: MEMBER_ACCESS_PERMISSIONS.ALL,
        label: 'NewPages_Panel_Permissions_Member_Roles_All_Members',
      },
      {
        value: MEMBER_ACCESS_PERMISSIONS.SPECIFIC,
        label: 'NewPages_Panel_Permissions_Member_Roles_Specific_Members',
      },
    ];
  },

  shouldDisplaySpecificAccessOptions() {
    return (
      this.state.membersAccessPermissions === MEMBER_ACCESS_PERMISSIONS.SPECIFIC
    );
  },

  handleMembersAccessChange(membersAccessPermissions) {
    const shouldShowAll =
      membersAccessPermissions === MEMBER_ACCESS_PERMISSIONS.ALL;
    if (!this.isSiteSaved()) {
      const groupsForPage = shouldShowAll ? [] : [groupsApi.getAdminGroup()];
      this.setState({ membersAccessPermissions, groupsForPage });
      return;
    }
    this.setState({ membersAccessPermissions }, () => {
      const groupsToUpdate = shouldShowAll
        ? this.state.groupsForPage.map((group) => ({
            ...group,
            checked: false,
          }))
        : [groupsApi.getAdminGroup()];
      const allMembers = [groupsApi.getAllMembersGroup(shouldShowAll)];
      this.updateGroupsPermissions(allMembers.concat(groupsToUpdate));
    });
  },

  resetMembersAccess() {
    const groupsToReset = this.state.groupsForPage.map((group) => ({
      ...group,
      checked: false,
    }));
    const allMembers = [groupsApi.getAllMembersGroup(false)];
    this.updateGroupsPermissions(allMembers.concat(groupsToReset));
  },

  openPaidPlansPanel() {
    const panelProps = {
      panelName: 'panels.toolPanels.rolesManagerPanel',
      groups: this.state.availablePlans.filter(({ type }) => type === 'plan'),
      selectedGroups: this.state.groupsForPage.filter(
        ({ type }) => type === 'plan',
      ),
      headerTitle: 'NewPages_Plans_Manager_Header_Title',
      applyButtonLabel: 'NewPages_Plans_Manager_Apply_Button',
      applyButtonCallback: this.updateGroupsPermissions,
      addNewButtonLabel: 'NewPages_Plans_Manager_Add_New_Plan',
      addNewButtonCallback: this.openPlansPageModal,
      disableAddNew: !this.getCanCreatePaidPlan(),
    };

    this.getEditorAPI().panelManager.openPanel(
      'panels.toolPanels.rolesManagerPanel',
      panelProps,
      true,
    );
  },

  openRolesPanel() {
    const panelProps = {
      panelName: 'panels.toolPanels.rolesManagerPanel',
      groups: this.state.availableRoles.filter(({ type }) => type === 'role'),
      headerTitle: 'NewPages_Roles_Manager_Header_Title',
      selectedGroups: this.state.groupsForPage.filter(
        ({ type }) => type === 'role',
      ),
      applyButtonLabel: 'NewPages_Roles_Manager_Apply_Button',
      applyButtonCallback: this.updateGroupsPermissions,
      addNewButtonLabel: 'NewPages_Roles_Manager_Add_New_Role',
      addNewButtonCallback: this.openRolesPageModal,
      disableAddNew: !this.getCanCreateNewRole(),
    };

    this.getEditorAPI().panelManager.openPanel(
      'panels.toolPanels.rolesManagerPanel',
      panelProps,
      true,
    );
  },

  openPricingPlansInAppMarket() {
    const panelProps = {
      urlParams: {
        openAppDefId: PRICING_PLANS_APP_DEF_ID,
        openMarketOrigin: 'pageSettings',
        appDefinitionId: PRICING_PLANS_APP_DEF_ID,
      },
    };

    this.getEditorAPI().panelManager.openPanel(
      'tpa.compPanels.appMarketPanel',
      panelProps,
      true,
    );
  },

  openBadgesPanel() {
    const panelProps = {
      panelName: 'panels.toolPanels.rolesManagerPanel',
      groups: this.state.availableRoles.filter(
        ({ type }) => type === groupsApi.badgeGroupType,
      ),
      headerTitle: 'NewPages_Badges_Manager_Header_Title',
      selectedGroups: this.state.groupsForPage.filter(
        ({ type }) => type === groupsApi.badgeGroupType,
      ),
      applyButtonLabel: 'NewPages_Badges_Manager_Apply_Button',
      applyButtonCallback: this.updateGroupsPermissions,
      addNewButtonLabel: 'NewPages_Badges_Manager_Add_New_Badge',
      addNewButtonCallback: this.openBadgesPageModal,
      disableAddNew: !this.getCanCreateNewBadge(),
    };

    this.getEditorAPI().panelManager.openPanel(
      'panels.toolPanels.rolesManagerPanel',
      panelProps,
      true,
    );
  },

  updateGroupsPermissions(groupsToUpdate) {
    const pageId = this.props.pageData.id;
    const membersGroupsApi = groupsApi.membersGroupsApi(this.getEditorAPI());
    return membersGroupsApi
      .updateGroupsPermissions({ pageId, groupsToUpdate })
      .then(() => this.refreshRoles(pageId));
  },

  isPricingPlansAppInstalled() {
    return this.getEditorAPI().documentServices.tpa.app.isInstalled(
      PRICING_PLANS_APP_DEF_ID,
    );
  },

  render() {
    return (
      <CustomScroll>
        <div className="permissions-tab tab-inner">
          <>
            {this.state.shouldShowProtectionType && (
              <Composites.Thumbnails>
                <TextLabel
                  value="NewPages_Panel_Permissions_Title"
                  dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_TITLE}
                />
                <Thumbnails
                  dataHook={PERMISSIONS_TAB_HOOKS.PERMISSIONS_THUMBNAILS}
                  value={this.state.focusedProtectionType}
                  maxThumbsPerRow={3}
                  options={this.getThumbnailOptions()}
                  borderType="thumbnailAndLabel"
                  labelsType="T15"
                  shoudAddTooltipOnEllipsis={false}
                  onChange={this.handleChangePermission}
                />
              </Composites.Thumbnails>
            )}
            <Composites.RichText>
              <RichText>
                <p>
                  {`${translate(this.getPermissionDescription())} `}
                  <a
                    onClick={this.handleClickLearnMore}
                    data-hook={PERMISSIONS_TAB_HOOKS.LEARN_MORE_LINK}
                  >
                    {translate(
                      'NewPages_Panel_Permissions_Password_Learn_More',
                    )}
                  </a>
                </p>
              </RichText>
            </Composites.RichText>
            <Divider />
          </>

          {this.state.focusedProtectionType ===
          CONSTANTS.PERMISSION_STATES.PASSWORD ? (
            <Composites.TextInputLabeled key="page-permissions-password-input">
              <TextLabel
                value="Pages_Permissions_Password_Choose"
                dataHook={PERMISSIONS_TAB_HOOKS.PASSWORD_INPUT_LABEL}
              />
              {this.shouldShowPasswordMockElement() ? (
                <TextInput
                  dataHook={PERMISSIONS_TAB_HOOKS.PASSWORD_INPUT_MOCK}
                  key="passwordMockElement"
                  onClick={this.onPasswordFocus}
                  value={CONSTANTS.PASSWORD_TEMPLATE}
                  type="password"
                />
              ) : null}
              {!this.shouldShowPasswordMockElement() ? (
                <TextInput
                  dataHook={PERMISSIONS_TAB_HOOKS.PASSWORD_INPUT}
                  key="visible-password-input"
                  focus={!this.isPasswordHidden()}
                  onFocus={this.onPasswordFocus}
                  onBlur={this.onPasswordBlur}
                  value={this.state.plainPassword}
                  type={this.state.passwordInputType}
                  placeholder="NewPages_Panel_Permissions_Set_Password_InputField_Text"
                  maxLength={CONSTANTS.PASSWORD_MAX_LENGTH}
                  validator={this.isValidPassword}
                  invalidMessage="Pages_Permissions_Password_Error_Tooltip"
                />
              ) : null}
            </Composites.TextInputLabeled>
          ) : null}
          {this.shouldShowRolesForPage() &&
          this.state.shouldShowProtectionType ? (
            <Divider key="divider-roles" />
          ) : null}
          {this.isPricingPlansOptionsOn() ? (
            <div key="is-pricing-options-on">
              <Composites.RadioButtonsLabeled>
                <TextLabel
                  value="NewPages_Panel_Permissions_Member_Roles_Options_Title"
                  shouldTranslate={true}
                />
                <RadioButtons
                  dataHook={PERMISSIONS_TAB_HOOKS.MEMBERS_ACCESS_OPTIONS}
                  value={this.state.membersAccessPermissions}
                  onChange={this.handleMembersAccessChange}
                  shouldTranslate={true}
                  options={this.getMembersAccessOptions()}
                />
                {this.shouldDisplaySpecificAccessOptions() ? (
                  <div
                    key="should-display-specific-options"
                    className="indented-members-access-options"
                  >
                    <div className="indented-divider">
                      <Divider />
                    </div>

                    <div className="action-with-symbol-container">
                      <Composites.ActionWithSymbolLabeled>
                        <TextLabel value="NewPages_Panel_Permissions_Member_Roles_Select_From_Roles_Label" />
                        <ActionWithSymbol
                          dataHook={PERMISSIONS_TAB_HOOKS.MEMBERS_ROLES_ACTION}
                          action={this.openRolesPanel}
                          symbol={this.getEditRolesSymbol()}
                        >
                          <TextLabel
                            dataHook={PERMISSIONS_TAB_HOOKS.MEMBERS_ROLES_LABEL}
                            value={this.getRolesActionLabel()}
                            shouldTranslate={false}
                          />
                        </ActionWithSymbol>
                      </Composites.ActionWithSymbolLabeled>
                    </div>

                    <Divider />

                    {!this.isPricingPlansAppInstalled() ? (
                      <div key="pricing-plans-banner">
                        <Composites.RichTextLabeled>
                          <TextLabel
                            type="T07"
                            value="NewPages_Panel_Permission_Pricing_Plans_Splash_Label"
                            shouldTranslate={true}
                          />
                          <RichText>
                            {`${translate(
                              'NewPages_Panel_Permission_Pricing_Plans_Splash_Text',
                            )} `}
                            <a
                              onClick={this.openPricingPlansInAppMarket}
                              data-hook={
                                PERMISSIONS_TAB_HOOKS.MEMBERS_PRICING_PLANS_INSTALL
                              }
                            >
                              {translate(
                                'NewPages_Panel_Permission_Pricing_Plans_Splash_Link',
                              )}
                            </a>
                          </RichText>
                        </Composites.RichTextLabeled>
                      </div>
                    ) : null}

                    {this.isPricingPlansAppInstalled() ? (
                      <div key="pricing-plans-action">
                        <Composites.ActionWithSymbolLabeled>
                          <TextLabel value="NewPages_Panel_Permissions_Member_Roles_Select_From_Paid_Plans_Label" />
                          <ActionWithSymbol
                            dataHook={
                              PERMISSIONS_TAB_HOOKS.MEMBERS_PRICING_PLANS_ACTION
                            }
                            action={this.openPaidPlansPanel}
                            symbol={this.getEditRolesSymbol()}
                          >
                            <TextLabel
                              dataHook={
                                PERMISSIONS_TAB_HOOKS.MEMBERS_PRICING_PLANS_LABEL
                              }
                              value={this.getPlansActionLabel()}
                              shouldTranslate={false}
                            />
                          </ActionWithSymbol>
                        </Composites.ActionWithSymbolLabeled>
                      </div>
                    ) : null}

                    {this.isBadgesOptionsOn() && (
                      <>
                        <Divider />
                        <div key="badges-action">
                          <Composites.ActionWithSymbolLabeled>
                            <TextLabel value="NewPages_Panel_Permissions_Member_Roles_Select_From_Badges_Label" />
                            <ActionWithSymbol
                              action={this.openBadgesPanel}
                              symbol={this.getEditRolesSymbol()}
                              dataHook={
                                PERMISSIONS_TAB_HOOKS.MEMBERS_BADGES_ACTION
                              }
                            >
                              <TextLabel
                                dataHook={
                                  PERMISSIONS_TAB_HOOKS.MEMBERS_BADGES_LABEL
                                }
                                value={this.getBadgesActionLabel()}
                                shouldTranslate={false}
                              />
                            </ActionWithSymbol>
                          </Composites.ActionWithSymbolLabeled>
                        </div>
                      </>
                    )}
                  </div>
                ) : null}
              </Composites.RadioButtonsLabeled>
            </div>
          ) : null}

          {!this.isPricingPlansOptionsOn() ? (
            <div key="pricing-plans-options">
              {this.shouldShowRolesForPage() ? (
                <Composites.ActionWithSymbolLabeled key="page-roles">
                  <TextLabel value="NewPages_Panel_Permissions_Member_Roles" />
                  <ActionWithSymbol
                    action={this.openRolesPageModal}
                    symbol={this.getEditRolesSymbol()}
                  >
                    <TextLabel
                      value={this.getRolesActionLabel()}
                      shouldTranslate={false}
                    />
                  </ActionWithSymbol>
                </Composites.ActionWithSymbolLabeled>
              ) : null}
            </div>
          ) : null}

          {this.state.focusedProtectionType ===
          CONSTANTS.PERMISSION_STATES.MEMBERS ? (
            <Divider key="divider-dialog-language" />
          ) : null}
          {this.state.focusedProtectionType ===
          CONSTANTS.PERMISSION_STATES.MEMBERS ? (
            <Composites.RichText
              key="members-links-container"
              className="members-links-container"
            >
              {this.isSiteSaved() && this.getCanCreateNewRole() ? (
                <RichText key="manage-site-members-container">
                  <symbols.symbol name="goToURLAction" />
                  <a
                    data-hook={PERMISSIONS_TAB_HOOKS.MEMBERS_DASHBOARD_LINK}
                    href={this.getSiteMembersUrl()}
                    target="_blank"
                  >
                    {translate(
                      'NewPages_Panel_Permissions_Member_ManageMembers_Link',
                    )}
                  </a>
                </RichText>
              ) : null}
            </Composites.RichText>
          ) : null}
        </div>
      </CustomScroll>
    );
  },
});

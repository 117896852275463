// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as menuContainerUpgradePanelMapper from './menuContainerUpgradePanelMapper';

import React from 'react';
import {
  Button,
  Composites,
  PanelHeaderCenteredText,
  PanelHeaderIllustration,
  PromotionalList,
  TextLabel,
} from '@wix/wix-base-ui';
import { FocusPanelFrame } from '../frames';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

const RETINA_ILLUSTRATION_PATH =
  'panels/focusPanels/menuContainerUpgradeRetina.gif';
const ILLUSTRATION_PATH = 'panels/focusPanels/menuContainerUpgrade.gif';

function isRetina() {
  return util.browserUtil.getDevicePixelRatio() === 2;
}

//TYPE WAS GENERATED, remove this line when reviewed
interface MenuContainerUpgradePanelProps {
  panelName?: string;
  content?: AnyFixMe;
  biEventOrigin?: string;
  sendBiEventForMenuUpgrade: FunctionFixMe;
  upgradeToMenuContainer: FunctionFixMe;
  helpId?: string;
}

class MenuContainerUpgradePanel extends React.Component<MenuContainerUpgradePanelProps> {
  static displayName = 'menuContainerUpgradePanel';

  static propTypes = {
    panelName: PropTypes.string,
    content: PropTypes.object,
    biEventOrigin: PropTypes.string,
    sendBiEventForMenuUpgrade: PropTypes.func.isRequired,
    upgradeToMenuContainer: PropTypes.func.isRequired,
    helpId: PropTypes.string,
  };

  static defaultProps = {
    panelName: 'panels.focusPanels.menuContainerUpgradePanel',
    helpId: 'bd3bb209-b7a6-4f94-9074-fb71a4bda45f',
    content: {
      header: 'Mobile_Menu_Update_Popup_Title',
      headerSubtitle: 'Mobile_Menu_Update_Popup_Subtitle',
      items: [
        'Mobile_Menu_Update_Popup_Bullet1',
        'Mobile_Menu_Update_Popup_Bullet2',
        'Mobile_Menu_Update_Popup_Bullet3',
        'Mobile_Menu_Update_Popup_Bullet4',
      ],
      buttons: {
        cancel: 'Mobile_Menu_Update_Popup_Cancel_Button',
        upgrade: 'Mobile_Menu_Update_Popup_Start_Button',
      },
    },
  };

  onConfirm = () => {
    this.props.sendBiEventForMenuUpgrade();
    this.props.upgradeToMenuContainer();
    this.props.closePanel(this.props.panelName);
  };

  closePanel = () => {
    this.props.closePanel(this.props.panelName);
  };

  onHelp = () => {
    this.props.openHelpCenter();
  };

  getIllustrationSrc = () => {
    const illustrationRelativePath = isRetina()
      ? RETINA_ILLUSTRATION_PATH
      : ILLUSTRATION_PATH;

    return util.media.getMediaUrl(illustrationRelativePath);
  };

  getBlueStripHeight = () => {
    return isRetina() ? 194 : 97;
  };

  render() {
    return (
      <FocusPanelFrame
        automationId={this.props.panelName}
        panelName={this.props.panelName}
        shouldHideHeader={true}
      >
        <Composites.PopupLargeSize className="composite-full-height">
          <Composites.BigPanelHeaderWithIllustration>
            <PanelHeaderCenteredText
              title={this.props.content.header}
              subtitle={this.props.content.headerSubtitle}
              titleType="T16"
              onHelp={this.onHelp}
              onClose={this.closePanel}
            />
            <PanelHeaderIllustration
              blueStripHeight={this.getBlueStripHeight()}
            >
              <img src={this.getIllustrationSrc()} className="illustration" />
            </PanelHeaderIllustration>
          </Composites.BigPanelHeaderWithIllustration>
          <Composites.PanelContent>
            <Composites.PromotionalList>
              <PromotionalList items={this.props.content.items} />
            </Composites.PromotionalList>
          </Composites.PanelContent>
          <Composites.ActionSetHorizontal>
            <Button
              onClick={() => {
                this.closePanel();
              }}
              className="secondary"
            >
              <TextLabel value={this.props.content.buttons.cancel} />
            </Button>
            <Button
              onClick={() => {
                this.onConfirm();
              }}
              className="primary"
            >
              <TextLabel value={this.props.content.buttons.upgrade} />
            </Button>
          </Composites.ActionSetHorizontal>
        </Composites.PopupLargeSize>
      </FocusPanelFrame>
    );
  }
}

export default connect(
  EDITOR_API,
  null,
  menuContainerUpgradePanelMapper.mapDispatchToProps,
)(renderWhenMutated(MenuContainerUpgradePanel));

import React from 'react';
import type { Scope } from '../workspaceRightPanelEntryPoint';
import { close, closeDrillInPanel, onHelp } from '../workspaceRightPanelApi';
import {
  WORKSPACE_RIGHT_PANEL_GROUPS_TAB_ORDER,
  WORKSPACE_RIGHT_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
} from '#packages/constants';
import { TabLabelWithIcon } from './tabLabelWithIcon';

export const mapStateToProps = (scope: Scope) => {
  const { editorAPI, store, contentSlot, drillInContentSlot } = scope;

  const {
    getHelpId,
    getPanelWidth,
    getTitle,
    getIsOpened,
    getActivePanelName,
    getGroupType,
    getContextMenuConfig,
    getContributedDrillInPanels,
    getRenderHeader,
  } = store;

  return {
    helpId: getHelpId(),
    panelWidth: getPanelWidth(),
    title: getTitle(),
    isOpened: getIsOpened(),
    panelContent: contentSlot.getItems(),
    panelName: getActivePanelName(),
    groupType: getGroupType(),
    contextMenuConfig: getContextMenuConfig(),
    contributedDrillInPanels: getContributedDrillInPanels(),
    drillInPanelsContent: drillInContentSlot.getItems(true),
    hiddenControls: editorAPI.store.getState().hideTools,
    renderHeader: getRenderHeader(),
  };
};

export const mapDispatchToProps = (scope: Scope) => {
  const { store, editorAPI } = scope;

  return {
    onHelp: () => {
      onHelp(scope);
      editorAPI.panelManager.openHelpCenter(store.getHelpId());
    },
    onClose: () => {
      close(scope, 'X_button');
    },
    changeTab: (newTab: WORKSPACE_RIGHT_PANEL_NAMES) => {
      store.getOnClose()('tab_switch');
      store
        .getContributedPanels()
        [newTab].onOpen({ origin: 'tab_switch' }, store.getGroupType());
    },
    getTabs: (
      panelName:
        | WORKSPACE_RIGHT_PANEL_NAMES
        | WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
      groupType: WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
    ) => {
      const groupPanelNames: WORKSPACE_RIGHT_PANEL_NAMES[] =
        WORKSPACE_RIGHT_PANEL_GROUPS_TAB_ORDER[groupType] || [];
      const contributedPanels = store.getContributedPanels();
      return groupPanelNames
        .filter(
          (panelName) =>
            contributedPanels[panelName] &&
            !contributedPanels[panelName].isTabDisabled?.(),
        )
        .map((panelName) => {
          const tabTitle = contributedPanels[panelName].tabTitle;
          const iconName = contributedPanels[panelName].iconName;
          return {
            value: panelName,
            label: React.createElement(TabLabelWithIcon, {
              text: tabTitle,
              iconName,
            }),
          };
        });
    },
    onBack: () => {
      closeDrillInPanel(scope);
    },
  };
};

import React, { type FC, useCallback } from 'react';
import { Tooltip } from '@wix/wix-base-ui';
import { ArrowUpDown } from '@wix/wix-ui-icons-common/classic-editor';

import { cx, hoc } from '#packages/util';

import { mapDispatchToProps, mapStateToProps } from './ResizeHandleMapper';

import type {
  ResizeHandleDispatchProps,
  ResizeHandleStateProps,
} from './ResizeHandleMapper';
import type { UISkin } from '../skins/skins.types';

import type { CompRef } from 'types/documentServices';

import styles from './ResizeHandle.scss';

export const EDGE_RESIZE_BUTTON_HOOK = 'section-edge-resize-button';

export interface ResizeHandleOwnProps {
  tooltipText: string;
  compRef: CompRef;
  skin: UISkin;
}

export interface ResizeHandleProps
  extends ResizeHandleDispatchProps,
    ResizeHandleStateProps,
    ResizeHandleOwnProps {}

const ResizeHandleComponent: FC<ResizeHandleProps> = ({
  tooltipText,
  skin,
  startResize,
  removeBottomGap,
  logResizeHandleHover,
  logResizeDoubleClicked,
  isMobileEditor,
}) => {
  const stopEvent = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleMouseDown = useCallback(
    (event: React.MouseEvent) => {
      if (event.detail > 1) return;
      event.stopPropagation();
      startResize(event);
    },
    [startResize],
  );

  const handleDoubleClick = useCallback(
    (event) => {
      stopEvent(event);
      removeBottomGap();
      logResizeDoubleClicked();
    },
    [stopEvent, removeBottomGap, logResizeDoubleClicked],
  );

  return (
    <Tooltip
      content={tooltipText}
      shouldTranslate={false}
      marginTop={8}
      openDelay={250}
      className={cx(styles.tooltipContainer, {
        [styles.mobileEditor]: isMobileEditor,
        [styles.legacy]: skin === 'legacy',
        [styles.primary]: skin === 'primary',
        [styles.secondary]: skin === 'secondary',
        [styles.legacySoap]: skin === 'legacy-soap',
        [styles.primarySoap]: skin === 'primary-soap',
        [styles.secondarySoap]: skin === 'secondary-soap',
      })}
    >
      <button
        data-hook={EDGE_RESIZE_BUTTON_HOOK}
        data-skin={skin}
        onMouseDown={handleMouseDown}
        onMouseMove={stopEvent}
        onMouseEnter={logResizeHandleHover}
        onClick={stopEvent}
        onDoubleClick={handleDoubleClick}
        className={styles.container}
      >
        <ArrowUpDown />
      </button>
    </Tooltip>
  );
};

export const ResizeHandle = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(ResizeHandleComponent);

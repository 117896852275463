import {
  fedopsLogger,
  isAdvancedMenuOpen,
  isCustomMenusEnabled,
} from '#packages/util';
import { migrateDesktopMenu } from './migrateDesktopMenu';
import { migrateMobileMenu } from './migrateMobileMenu';
import { transformPagesPanelStructure } from './structure';
import { createFallbackPagesMenu } from './utils';
import { createMenuApi } from '../../API/menuAPI';
import { PAGES_MENU_ID } from '../../constants';

import type { EditorAPI } from '#packages/editorAPI';

export const migratePanesPanelData = (editorAPI: EditorAPI): Promise<void> => {
  const menuAPI = createMenuApi(editorAPI);

  const pagesMenu = menuAPI.getMenu(PAGES_MENU_ID);
  const oldItems = pagesMenu.items;
  const newItems = transformPagesPanelStructure(oldItems);

  menuAPI.replaceMenuItems(PAGES_MENU_ID, newItems);

  return new Promise((resolve) => editorAPI.waitForChangesApplied(resolve));
};

const makePagesPanelBackup = async (editorAPI: EditorAPI): Promise<void> => {
  createFallbackPagesMenu(editorAPI);

  return new Promise((resolve) => editorAPI.waitForChangesApplied(resolve));
};

export const migrate = async (editorAPI: EditorAPI) => {
  if (isAdvancedMenuOpen() || !isCustomMenusEnabled()) {
    return;
  }

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE,
  );

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE_DESKTOP,
  );
  await migrateDesktopMenu(editorAPI);
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE_DESKTOP,
  );

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE_MOBILE,
  );
  await migrateMobileMenu(editorAPI);
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE_MOBILE,
  );

  await makePagesPanelBackup(editorAPI);

  fedopsLogger.interactionStarted(
    fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE_PAGES_PANEL,
  );
  await migratePanesPanelData(editorAPI);
  fedopsLogger.interactionEnded(
    fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE_PAGES_PANEL,
  );

  fedopsLogger.interactionEnded(fedopsLogger.INTERACTIONS.MANAGE_MENU.MIGRATE);
};

import React, { useState } from 'react';
import { cx, hoc } from '#packages/util';
import * as _ from 'lodash';
import withOpenDropPanel, {
  type WithOpenDropPanelProps,
} from '../hocs/withOpenDropPanel';
import { Button, Text } from '@wix/wix-base-ui';
import styles from './workspaceModesToggle.scss';
import * as symbols from '@wix/santa-editor-symbols';
import { WorkspaceModes } from '#packages/workspaceModes';
import DropDown from '../dropDown/dropDown';
import constants from '#packages/constants';
import WorkspaceModesToggleTooltip from './workspaceModesToggleTooltip';
import { WorkspaceModesApiKey } from '#packages/apis';

import type { InferComponentProps } from '#packages/apilib';
import type { StateMapperArgs } from 'types/redux';

const { DROP_PANELS } = constants.ROOT_COMPS.TOPBAR;

interface WorkspaceModesToggleOwnProps extends WithOpenDropPanelProps {}

type WorkspaceModesToggleProps = InferComponentProps<
  typeof mapStateToProps,
  null,
  WorkspaceModesToggleOwnProps
>;

const WorkspaceModesToggle: React.FC<WorkspaceModesToggleProps> = ({
  isLiteMode,
  openDropPanel,
  closeDropPanel,
  openedDropPanel,
}) => {
  const [shouldTrigger, setShouldTrigger] = useState<boolean>(true);
  const panelName = DROP_PANELS.WORKSPACE_MODE_DROP_PANEL;
  const getLabel = () => {
    return isLiteMode
      ? 'lite_editor_topbar_indicator_lite_editor_label'
      : 'lite_editor_topbar_indicator_full_editor_label';
  };
  return (
    <DropDown
      isOpen={openedDropPanel === panelName}
      panelContent={<WorkspaceModesToggleTooltip closePanel={closeDropPanel} />}
      outerClickShouldStopPropagation={true}
      onPanelOuterClick={() => {
        setShouldTrigger(false);
        closeDropPanel();
      }}
    >
      <div
        onMouseDown={() => setShouldTrigger(true)}
        onClick={() => {
          if (shouldTrigger) {
            openDropPanel(panelName);
          }
        }}
      >
        <Button
          className={cx('btn-confirm-secondary', styles.workspaceModesButton, {
            [styles.liteMode]: isLiteMode,
          })}
          automationId="top-bar-button-workspace-modes"
        >
          <div className={styles.workspaceModesButtonText}>
            <Text weight="bold" size="small" skin="link">
              {getLabel()}
            </Text>
          </div>
          <symbols.symbol name="arrowDown" className={styles.symbol} />
        </Button>
      </div>
    </DropDown>
  );
};

const mapStateToProps = ({ editorAPI }: StateMapperArgs) => {
  const workspaceModesApi = editorAPI.host.getAPI(WorkspaceModesApiKey);
  return {
    isLiteMode: workspaceModesApi.isMode(WorkspaceModes.LITE),
  };
};

export const shouldShowWorkspaceModes = ({ editorAPI }: StateMapperArgs) => {
  return editorAPI.host
    .getAPI(WorkspaceModesApiKey)
    .isWorkspaceModesAvailable();
};

export default _.flow(
  hoc.withConditionalRender(hoc.STORES.EDITOR_API, shouldShowWorkspaceModes),
  hoc.connect(hoc.STORES.EDITOR_API, mapStateToProps),
  withOpenDropPanel,
)(WorkspaceModesToggle);

import {
  interactions,
  domMeasurements,
  draggableSlots,
} from '#packages/stateManagement';
import { utils } from '#packages/core';
import constants from '#packages/constants';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const { isInInteractionMode } = interactions.selectors;
const { getViewPort } = domMeasurements.selectors;
const { compControlsMeasurer } = utils;
const { getAllDraggableSlotsPositions } = draggableSlots.selectors;

export const getBoundingLayoutRelativeToScreen = (
  editorAPI: EditorAPI,
  compRef: CompRef | CompRef[],
) => {
  const compLayout = editorAPI.components.layout.getRelativeToScreen(compRef);

  // When working with modern non-destructive layout modes
  const state = editorAPI.store.getState();
  const shouldUseMeasure = isInInteractionMode(state);

  if (shouldUseMeasure) {
    const compPointer = Array.isArray(compRef) ? compRef[0] : compRef;
    const compClientRect =
      editorAPI.documentServices.components.layout.measure.getBoundingClientRect(
        compPointer,
      );
    compLayout.y = compClientRect.absoluteTop;
    compLayout.x = compClientRect.absoluteLeft;
  }

  return {
    ...compLayout,
    bounding: {
      ...compLayout.bounding,
      fixedPosition: editorAPI.components.layout.isShowOnFixedPosition(compRef),
      rotationInDegrees: compLayout.rotationInDegrees,
    },
  };
};

export const getPageXBoundary = (editorAPI: EditorAPI) => {
  const pagesContainerRef = editorAPI.dsRead.siteSegments.getPagesContainer();
  return editorAPI.components.layout.getRelativeToScreen(pagesContainerRef).x;
};

export const getNavControlsMeasurements = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  compPanel?: Element | Text,
) => {
  const compLayout = getBoundingLayoutRelativeToScreen(editorAPI, compRef);

  const relativeToScroll = !compLayout.fixedPosition;

  const leftBarBoundingRect = editorAPI.getLeftBarButtonsBoundingRect();
  const state = editorAPI.store.getState();

  const viewPort = getViewPort(state, relativeToScroll, leftBarBoundingRect);

  const sizesAndConstraints = {
    viewPort,
    pageXBoundary: getPageXBoundary(editorAPI),
    draggableSlots: getAllDraggableSlotsPositions(state),
  };

  const compParams = {
    id: compRef.id,
    layout: compLayout,
  };

  return compControlsMeasurer.getCompControlsPositions(
    compParams,
    compPanel,
    compParams,
    sizesAndConstraints,
  );
};

export const isContainerComp = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): boolean =>
  editorAPI.components.getType(compRef) === constants.COMP_TYPES.CONTAINER;

export const isBoxSlideShow = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): boolean =>
  editorAPI.components.getType(compRef) === constants.COMP_TYPES.BOX_SLIDE_SHOW;

export const isRepeater = (editorAPI: EditorAPI, compRef: CompRef): boolean =>
  editorAPI.components.getType(compRef) === constants.COMP_TYPES.REPEATER;

export const isCompPreventFixedControls = (
  editorAPI: EditorAPI,
  compRef: CompRef,
) => editorAPI.sections.isFooter(compRef);

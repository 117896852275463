// @ts-nocheck
import React from 'react';
import ReactLinkedStateMixin from 'react-addons-linked-state-mixin';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import {
  Composites,
  PanelHeader,
  TextLabel,
  DropDown,
  DropDownOption,
} from '@wix/wix-base-ui';

import * as core from '#packages/core';
import * as coreBi from '#packages/coreBi';
import * as baseUI from '#packages/baseUI';
import { translate } from '#packages/i18n';
import { workspace, cx } from '#packages/util';

import { ToolPanelFrame } from '../../frames';
import PanelCloseOrigin from '../../frames/panelCloseOrigin';

const FieldType = Object.freeze({
  STRING: 'string',
  DATE: 'date',
  NUMBER: 'number',
  IMAGE: 'image',
  BOOLEAN: 'bool',
  RICH_TEXT: 'richText',
});

const isNewWorkspace = workspace.isNewWorkspaceEnabled();

// eslint-disable-next-line react/prefer-es6-class
export default createReactClass({
  displayName: 'gridAddColumnPanel',

  mixins: [core.mixins.editorAPIMixin, ReactLinkedStateMixin],

  panelTitle: 'TABLE_ADD_PANEL_TITLE',

  getInitialState() {
    return {
      label: this.props.labelPrefix + this.props.nextIndex,
      fieldName: this.props.fieldNamePrefix + this.props.nextIndex,
      type: FieldType.STRING,
      fieldNameWasEdited: false,
      isFormValid: true,
    };
  },

  render() {
    return (
      <ToolPanelFrame
        ref="frame"
        panelName="panels.toolPanels.gridAddColumnPanel"
        contentWrapperClass="grid-add-column-toolbar"
        contentStyle={_.pick(this.props, ['top', 'left'])}
        onClose={this.onClose}
      >
        <div className="grid-add-column-panel">
          <PanelHeader
            ref="panelHeader"
            onClose={this.onCloseButtonClick}
            noHelpBtn
            className={cx({
              header: !isNewWorkspace,
              light: !isNewWorkspace,
            })}
            removeStripe
          >
            <span className="panel-title">{translate(this.panelTitle)}</span>
          </PanelHeader>
          {isNewWorkspace ? null : <div className="header-separator" />}
          <div className="grid-add-column-toolbar-content">
            <baseUI.textInput
              label="TABLE_ADD_PANEL_COL_TITLE_LABEL"
              value={this.state.label}
              onChange={this.onLabelChange}
            />
            <hr className="divider-long" />
            <baseUI.textInput
              label="TABLE_ADD_PANEL_FIELD_NAME_LABEL"
              ref="fieldNameInput"
              value={this.state.fieldName}
              validator={this.fieldNameValidator}
              invalidMessage="FIELD_NAME_CANNOT_BE_EMPTY"
              onValidation={this.onFieldNameValidation}
              onChange={this.onFieldNameChange}
            />
            <hr className="divider-long" />
            <Composites.DropDownLabeled>
              <TextLabel value="TABLE_ADD_PANEL_TYPE_LABEL" />
              <DropDown
                value={this.linkState('type').value}
                onChange={this.linkState('type').requestChange}
                shouldTranslate={false}
              >
                {this.typeOptions.map((option) => (
                  <DropDownOption
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    shouldTranslate={false}
                  />
                ))}
              </DropDown>
            </Composites.DropDownLabeled>

            <hr className="divider-long" />
            <div className="button-row">
              <baseUI.button
                disabled={!this.state.isFormValid}
                label="TABLE_ADD_PANE_DONE_BTN"
                onClick={this.onAddButtonClick}
                className="button"
              />
            </div>
          </div>
        </div>
      </ToolPanelFrame>
    );
  },

  onCloseButtonClick() {
    this.reportPanelClosed(PanelCloseOrigin.HEADER_CLOSE_BUTTON);
    this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
  },

  onAddButtonClick() {
    if (this.state.isFormValid) {
      this.props.addColumnCallback(
        this.state.label,
        this.state.fieldName,
        this.state.type,
      );
      this.reportPanelClosed(PanelCloseOrigin.DONE_BUTTON);
      this.getEditorAPI().panelManager.closePanelByName(this.props.panelName);
    }
  },

  onLabelChange(newLabel) {
    const newState = { label: newLabel };
    if (!this.state.fieldNameWasEdited) {
      const newFieldName = newLabel
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[^a-z0-9_]/g, '');
      if (newFieldName.length !== 0) {
        newState.fieldName = newFieldName;
      }
    }
    this.setState(newState);
  },

  fieldNameValidator(newFieldName) {
    return newFieldName.length > 0;
  },

  onFieldNameChange(newFieldName) {
    this.setState({
      fieldName: newFieldName,
      fieldNameWasEdited: true,
    });
  },

  onFieldNameValidation(isValid) {
    this.setState({
      isFormValid: isValid,
    });
  },

  typeOptions: [
    {
      value: FieldType.STRING,
      label: translate('TABLE_ADD_PANEL_TYPE_OPT1'),
    },
    {
      value: FieldType.IMAGE,
      label: translate('TABLE_ADD_PANEL_TYPE_OPT2'),
    },
    {
      value: FieldType.NUMBER,
      label: translate('TABLE_ADD_PANEL_TYPE_OPT3'),
    },
    {
      value: FieldType.BOOLEAN,
      label: translate('TABLE_ADD_PANEL_TYPE_OPT4'),
    },
    {
      value: FieldType.DATE,
      label: translate('TABLE_ADD_PANEL_TYPE_OPT5'),
    },
    {
      value: FieldType.RICH_TEXT,
      label: translate('TABLE_ADD_PANEL_TYPE_OPT6'),
    },
  ],

  onClose(origin) {
    this.reportPanelClosed(origin);
  },

  /* BI Helpers */

  getSelectedComponentId() {
    const selection = this.props.selectedComponents;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    return _.isArray(selection) ? _.head(selection).id : selection.id;
  },

  reportPanelClosed(origin) {
    const editorAPI = this.getEditorAPI();
    editorAPI.bi.event(coreBi.events.grid.GRID_ADD_COLUMN_DONE, {
      siteId: editorAPI.dsRead.generalInfo.getSiteId(),
      componentId: this.getSelectedComponentId(),
      panelName: this.panelTitle,
      columnType: this.state.type,
      fieldName: this.state.fieldName,
      columnTitle: this.state.label,
      origin,
    });
  },
});

import type { OnDropCallback } from '#packages/addPanelInfra';
import {
  type AddWidgetBIParams,
  addWixStoreWidgetToSite,
  setStyleParams,
} from '#packages/wixStore';
import type { StyleParam } from '@wix/add-panel-component';
import type { CompStructure } from 'types/documentServices';

interface AddWixStoreWidgetOnClickParams {
  compStructure: CompStructure;
  itemId: string;
  styleParams: StyleParam[];
  biParams: AddWidgetBIParams;
}

export const addWixStoreWidgetOnClick = ({
  compStructure,
  itemId,
  styleParams,
  biParams,
}: AddWixStoreWidgetOnClickParams) => {
  const callback = styleParams ? setStyleParams(styleParams) : undefined;
  const { data, layout } = compStructure;

  return addWixStoreWidgetToSite(
    data.widgetId,
    data.appDefinitionId,
    itemId,
    layout,
    callback,
    { biParams, componentDefinition: compStructure },
  );
};

interface AddWixStoreWidgetOnDropParams {
  styleParams: StyleParam[];
  biParams: AddWidgetBIParams;
}

export const addWixStoreWidgetOnDrop =
  ({ styleParams, biParams }: AddWixStoreWidgetOnDropParams): OnDropCallback =>
  (dropPosition, { structure, itemId }, containerRef) => {
    const callback = styleParams ? setStyleParams(styleParams) : undefined;

    const position = {
      ...dropPosition,
      width: structure.layout.width,
      height: structure.layout.height,
    };

    structure.layout.x = dropPosition.x;
    structure.layout.y = dropPosition.y;

    return addWixStoreWidgetToSite(
      structure.data.widgetId,
      structure.data.appDefinitionId,
      itemId,
      position,
      callback,
      {
        biParams,
        containerRef,
        useRelativeToContainerLayout: false,
        componentDefinition: structure as CompStructure,
      },
    );
  };

import React from 'react';
import { translate } from '#packages/i18n';
import * as Symbols from '@wix/santa-editor-symbols';

import type { AddPageCategory } from '#packages/addPreset';
import type { PageLabel } from '../../../types';

const LABEL_PUBLIC_IDs = {
  premium: 'premium',
  new: 'new',
  popular: 'popular',
  dynamic: 'dynamic',
};

export interface CategoryLabel {
  labels: PageLabel[];
  selectedCategory: AddPageCategory;
  isPremiumSite: boolean;
}

const getLabel = ({
  labels,
  selectedCategory,
  isPremiumSite,
}: CategoryLabel): CategoryLabel['labels'][0] => {
  let labelId: string;
  const shouldShowPremiumIconAndText =
    selectedCategory.isPremium && !isPremiumSite;

  if (shouldShowPremiumIconAndText) {
    labelId = LABEL_PUBLIC_IDs.premium;
  } else if (selectedCategory.isDynamic) {
    labelId = LABEL_PUBLIC_IDs.dynamic;
  } else if (selectedCategory.isNew) {
    labelId = LABEL_PUBLIC_IDs.new;
  } else if (selectedCategory.isPopular) {
    labelId = LABEL_PUBLIC_IDs.popular;
  }

  if (!labelId) return;

  let label = labels.find((label) => label.publicId === labelId);

  if (shouldShowPremiumIconAndText) {
    label = {
      ...label,
      symbolName: 'upgradeSmall',
    };
  }

  return label;
};

export const CategoryLabel = ({
  labels,
  selectedCategory,
  isPremiumSite,
}: CategoryLabel) => {
  const label = getLabel({ labels, selectedCategory, isPremiumSite });

  if (label) {
    return (
      <div
        className={`category-label ${label.publicId}`}
        style={{
          color: label.textColor,
          backgroundColor: label.backgroundColor,
        }}
      >
        {label.symbolName ? <Symbols.symbol name={label.symbolName} /> : null}
        {translate(label.name)}
      </div>
    );
  }
  return null;
};

import React from 'react';
import type { SendBiFunction } from 'types/bi';
import * as coreBi from '#packages/coreBi';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as helpIds from '#packages/helpIds';

import connect from './concurrentSavePanelConnect';
import {
  ConcurrentSavePanelFrame,
  NewWorkspaceConcurrentSavePanelFrame,
} from './concurrentSavePanelFrame';

const biEvents = coreBi.events.save.saveFailed;

const PANEL_STATIC_PROPS = {
  newWorkspace: {
    default: {
      title: 'Concurrent_Editing_Save_Site_Version_Title',
      description: ['Concurrent_Editing_Save_Site_Version_Text'],
      confirmLabel: 'Concurrent_Editing_Save_Site_Version_Save_Button',
      cancelLabel: 'Concurrent_Editing_Save_Site_Version_Discard_Button',
      symbolName: 'concurrentSave_NewWorkspace',
      closeOnEscPress: true,
      hideCloseButton: false,
      helpId: null as AnyFixMe,
    },
    csave: {
      title: 'Continuous_Save_Two_Tabs_Open_Header_Title',
      description: [
        'Continuous_Save_Two_Tabs_Open_Body_1',
        'Continuous_Save_Two_Tabs_Open_Body_2',
      ],
      confirmLabel: 'Continuous_Save_Two_Tabs_Open_Primary_CTA',
      cancelLabel: null as AnyFixMe,
      symbolName: 'concurrentSave_NewWorkspace',
      closeOnEscPress: false,
      hideCloseButton: true,
      helpId: helpIds.SAVE_PUBLISH.MULTIPLE_TABS_EDITING,
    },
  },
  default: {
    default: {
      title: 'Concurrent_Editing_Error_Header',
      description: [
        'Concurrent_Editing_Error_Text1',
        'Concurrent_Editing_Error_Text2',
        'Concurrent_Editing_Error_Text3',
      ],
      confirmLabel: 'Concurrent_Editing_Error_Save_Button',
      cancelLabel: 'Concurrent_Editing_Error_Update_Button',
      symbolName: 'bicycle',
      closeOnEscPress: true,
      hideCloseButton: false,
      helpId: null as AnyFixMe,
    },
    csave: {
      title: 'Continuous_Save_Two_Tabs_Open_Header_Title',
      description: [
        'Continuous_Save_Two_Tabs_Open_Body_1',
        'Continuous_Save_Two_Tabs_Open_Body_2',
      ],
      confirmLabel: 'Continuous_Save_Two_Tabs_Open_Primary_CTA',
      cancelLabel: null as AnyFixMe,
      symbolName: 'bicycle',
      closeOnEscPress: false,
      hideCloseButton: true,
      helpId: helpIds.SAVE_PUBLISH.MULTIPLE_TABS_EDITING,
    },
  },
};

export interface ConcurrentSavePanelProps {
  panelName: string;
  origin: string;
  reloadEditor: () => void;
  openHelpCenter: (helpId: string) => void;
  closePanelByName: (panelName: string) => void;
  sendBi: SendBiFunction;
}

export class ConcurrentSavePanel extends React.Component<ConcurrentSavePanelProps> {
  close = () => {
    this.props.closePanelByName(this.props.panelName);
  };

  handleConfirm = () => {
    return this.reloadEditor();
  };

  handleCancel = () => {
    this.reloadEditor();
  };

  reloadEditor() {
    util.windowInteractionUtils.reloadPageWithoutQuestion();
  }

  sendBI(selection: string) {
    const biEvent =
      this.props.origin === 'autosave'
        ? biEvents.CONCURRENT_AUTOSAVE
        : biEvents.CONCURRENT_SAVE;

    this.props.sendBi(biEvent, { selection });
  }

  getStaticProps() {
    const staticProps = util.workspace.isNewWorkspaceEnabled()
      ? PANEL_STATIC_PROPS.newWorkspace
      : PANEL_STATIC_PROPS.default;

    return staticProps.csave;
  }

  renderContent(description: string[]) {
    return (
      <div className="description-wrapper-wider">
        {description.map((line) => (
          <div key={line} className="description-line initialPadding">
            {translate(line)}
          </div>
        ))}
      </div>
    );
  }

  render() {
    const { openHelpCenter } = this.props;
    const { helpId, description, ...staticProps } = this.getStaticProps();

    const isNewWorkspaceEnabled = util.workspace.isNewWorkspaceEnabled();
    const PanelFrameComponent = isNewWorkspaceEnabled
      ? NewWorkspaceConcurrentSavePanelFrame
      : ConcurrentSavePanelFrame;

    return (
      <PanelFrameComponent
        panelName={this.props.panelName}
        title={staticProps.title}
        closeOnEscPress={staticProps.closeOnEscPress}
        confirmLabel={staticProps.confirmLabel}
        cancelLabel={staticProps.cancelLabel}
        symbolName={staticProps.symbolName}
        onConfirm={this.handleConfirm}
        onCancel={this.handleCancel}
        onClose={this.close}
        hideCloseButton={staticProps.hideCloseButton}
        onHelp={helpId ? () => openHelpCenter(helpId) : null}
      >
        {this.renderContent(description)}
      </PanelFrameComponent>
    );
  }
}

export default connect(ConcurrentSavePanel);

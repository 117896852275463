// @ts-nocheck
import constants from '#packages/constants';

import { WIX_FORMS } from '@wix/app-definition-ids';

// this do not exist on '@wix/app-definition-ids'
const CUSTOM_REGISTRATION_APP_DEF_ID = '5f4fa4f1-5afd-4ac0-8a85-e5ac1d2b9b7d';

const closeOnOverlayClick = false;
const xButtonColor = '#000';
const backgroundColor = '#FFF';

const registrationFormContainerStructure = {
  componentType: 'platform.components.AppWidget',
  style: {
    skin: 'platform.components.skins.AppWidgetLoaderSkin',
    style: {},
  },
  data: {
    type: 'AppController',
    name: 'wix-forms-controller',
    controllerType: 'registrationForm',
    applicationId: WIX_FORMS,
    id: 'form-builder-widget',
  },
  layout: {
    width: 467,
    height: 639,
    x: 0,
    y: 0,
    scale: 1,
    rotationInDegrees: 0,
    fixedPosition: false,
  },
  components: [
    {
      type: 'Container',
      components: [],
      skin: 'wysiwyg.viewer.skins.FormContainerSkin',
      layout: {
        width: 467,
        height: 639,
        x: 0,
        y: 0,
        scale: 1,
        rotationInDegrees: 0,
        fixedPosition: false,
      },
      connections: {
        type: 'ConnectionList',
        items: [
          {
            type: 'ConnectionItem',
            controllerId: 'form-builder-widget',
            isPrimary: true,
            config: '{"isDummyForm":true}',
            role: 'form_Role',
          },
        ],
      },
      style: {
        metaData: { isPreset: false, schemaVersion: '1.0', isHidden: false },
        type: 'TopLevelStyle',
        style: {
          properties: {},
          pageId: '',
          compId: '',
        },
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.FormContainerSkin',
      },
      componentType: constants.COMP_TYPES.FORM_CONTAINER,
    },
  ],
};

const popupCloseIconButton = {
  type: 'Component',
  skin: 'svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c',
  layout: {
    width: 35,
    height: 35,
    scale: 1,
    rotationInDegrees: 0,
    anchors: [],
    fixedPosition: true,
    docked: {
      top: {
        px: 60,
      },
      right: {
        px: 60,
      },
    },
  },
  componentType: 'wysiwyg.viewer.components.PopupCloseIconButton',
  props: {
    type: 'SvgShapeProperties',
    maintainAspectRatio: true,
  },
  style: {
    type: 'TopLevelStyle',
    style: {
      groups: {},
      properties: {
        fillcolor: xButtonColor,
      },
      propertiesSource: {
        fillcolor: 'value',
      },
    },
    componentClassName: 'wysiwyg.viewer.components.PopupCloseIconButton',
    mobileHints: {
      type: 'MobileHints',
      hidden: true,
    },
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'svgshape.v2.Svg_9a8686831e874878a55a90925c0feb6c',
  },
};

const lightBoxContent = {
  type: 'Container',
  components: [popupCloseIconButton, registrationFormContainerStructure],
  skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
  layout: {
    width: 514,
    height: 678,
  },
  componentType: 'wysiwyg.viewer.components.PopupContainer',
  props: {
    type: 'PopupContainerProperties',
    horizontalAlignment: 'center',
    verticalAlignment: 'center',
    alignmentType: 'nineGrid',
    horizontalOffset: 0,
    verticalOffset: 0,
  },
  design: {
    type: 'MediaContainerDesignData',
    background: {
      type: 'BackgroundMedia',
      color: '#FFFFFE',
      colorOpacity: 1,
      alignType: 'center',
      fittingType: 'fill',
      scrollType: 'none',
      colorOverlay: '',
      colorOverlayOpacity: 0,
    },
  },
  style: {
    type: 'TopLevelStyle',
    style: {
      groups: {},
      properties: {
        'alpha-bg': '1',
        bg: 'rgba(255,255,255,1)',
      },
      propertiesSource: {
        'alpha-bg': 'value',
        bg: 'value',
      },
    },
    componentClassName: 'wysiwyg.viewer.components.PopupContainer',
    pageId: '',
    compId: '',
    styleType: 'custom',
    skin: 'wysiwyg.viewer.skins.stripContainer.DefaultStripContainer',
  },
};

const LIGHTBOX_DEF = {
  type: 'Page',
  skin: 'skins.core.InlineSkin',
  componentType: 'mobile.core.components.Page',
  data: {
    type: 'Page',
    managingAppDefId: CUSTOM_REGISTRATION_APP_DEF_ID,
    title: 'add_preset_popups_Welcome_1',
    hideTitle: true,
    icon: '',
    descriptionSEO: '',
    metaKeywordsSEO: '',
    pageTitleSEO: '',
    pageUriSEO: 'blank-ncx68',
    hidePage: true,
    underConstruction: false,
    tpaApplicationId: 0,
    pageSecurity: {
      requireLogin: false,
      passwordDigest: '',
      dialogLanguage: '',
    },
    isPopup: true,
    indexable: false,
    isLandingPage: false,
    pageBackgrounds: {
      desktop: {
        ref: {
          type: 'BackgroundMedia',
          color: backgroundColor,
          colorOpacity: 1,
          alignType: 'center',
          fittingType: 'fill',
          scrollType: 'none',
          colorOverlay: '',
          colorOverlayOpacity: 0,
        },
      },
      mobile: {
        custom: true,
        ref: {
          type: 'BackgroundMedia',
          color: backgroundColor,
          colorOpacity: 1,
          alignType: 'center',
          fittingType: 'fill',
          scrollType: 'scroll',
          colorOverlay: '',
          colorOverlayOpacity: 0,
        },
        isPreset: true,
      },
    },
    ignoreBottomBottomAnchors: true,
  },
  props: {
    type: 'PageProperties',
    desktop: {
      popup: {
        closeOnOverlayClick,
      },
    },
    mobile: {
      popup: {
        closeOnOverlayClick,
      },
    },
  },
  style: 'p1',
  tpaComps: [],
  components: [lightBoxContent],
};

const RESPONSIVE_LAYOUT = {
  popup: {
    containerLayout: {
      columnGap: {
        type: 'vw',
        value: 0,
      },
      rowGap: {
        type: 'vw',
        value: 0,
      },
      columns: [
        {
          type: 'MinMaxSize',
          min: {
            type: 'px',
            value: 0,
          },
          max: {
            type: 'fr',
            value: 1,
          },
        },
      ],
      type: 'GridContainerLayout',
      rows: [
        {
          type: 'MinMaxSize',
          min: {
            type: 'px',
            value: 680,
          },
          max: {
            type: 'auto',
          },
        },
      ],
    },
    spx: {
      refWidth: 1280,
      resolverType: 'scale',
    },
    componentLayout: {
      minHeight: {
        value: 25.296442687747035,
        type: 'percentage',
      },
      height: {
        type: 'auto',
      },
      minWidth: {
        type: 'px',
        value: 0,
      },
      type: 'ComponentLayout',
      width: {
        value: 100,
        type: 'percentage',
      },
      maxWidth: {
        type: 'px',
        value: 99999,
      },
      maxHeight: {
        type: 'px',
        value: 99999,
      },
    },
    itemLayout: {
      type: 'FixedItemLayout',
      margins: {
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
        top: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
      },
      justifySelf: 'center',
      alignSelf: 'center',
    },
    type: 'SingleLayoutData',
  },
  appWidget: {
    containerLayout: {
      columnGap: {
        type: 'px',
        value: 0,
      },
      rowGap: {
        type: 'px',
        value: 0,
      },
      columns: [
        {
          type: 'fr',
          value: 1,
        },
      ],
      padding: {
        top: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
        left: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
      },
      type: 'GridContainerLayout',
      rows: [
        {
          value: 1,
          type: 'fr',
        },
      ],
    },
    spx: {
      refWidth: 1280,
      resolverType: 'scale',
    },
    id: 'layout-lksab9g4',
    componentLayout: {
      minHeight: {
        type: 'px',
        value: 0,
      },
      hidden: false,
      height: {
        type: 'auto',
      },
      minWidth: {
        type: 'px',
        value: 0,
      },
      type: 'ComponentLayout',
      width: {
        type: 'percentage',
        value: 100,
      },
      maxWidth: {
        type: 'px',
        value: 99999,
      },
      maxHeight: {
        type: 'px',
        value: 99999,
      },
    },
    itemLayout: {
      alignSelf: 'center',
      margins: {
        top: {
          type: 'px',
          value: 0,
        },
        right: {
          type: 'px',
          value: 0,
        },
        bottom: {
          type: 'px',
          value: 0,
        },
        left: {
          type: 'px',
          value: 0,
        },
      },
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      justifySelf: 'center',
      type: 'GridItemLayout',
    },
    type: 'SingleLayoutData',
  },
  closeButton: {
    containerLayout: {},
    spx: {
      refWidth: 1280,
      resolverType: 'scale',
    },
    componentLayout: {
      minHeight: {
        type: 'px',
        value: 36,
      },
      hidden: false,
      height: {
        type: 'auto',
      },
      minWidth: {
        type: 'px',
        value: 0,
      },
      type: 'ComponentLayout',
      width: {
        type: 'px',
        value: 36,
      },
      maxWidth: {
        type: 'px',
        value: 99999,
      },
    },
    itemLayout: {
      alignSelf: 'start',
      margins: {
        left: {
          type: 'percentage',
          value: 0,
        },
        right: {
          type: 'px',
          value: 30,
        },
        top: {
          type: 'px',
          value: 30,
        },
        bottom: {
          type: 'percentage',
          value: 0,
        },
      },
      gridArea: {
        rowStart: 1,
        rowEnd: 2,
        columnStart: 1,
        columnEnd: 2,
      },
      justifySelf: 'end',
      type: 'GridItemLayout',
    },
    type: 'SingleLayoutData',
  },
  popupContainerSkin:
    'wysiwyg.viewer.skins.screenwidthcontainer.ResponsivePopupContainerWithColorBG',
};

export { LIGHTBOX_DEF, RESPONSIVE_LAYOUT };

// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as topBarData from '../topBarComponent/topBarData';
import * as keyboardContextStack from '../util/keyboardContextStack';
import UpgradeDropPanel from '../dropPanel/upgradeDropPanel';
import CodeDropPanelCorvid from '../dropPanel/codeDropPanelCorvid';
import DealerUpgradeDropPanel from '../dropPanel/dealerUpgradeDropPanel';
import DropPanelAnchor from '../dropPanel/dropPanelAnchor';
import TextAndLinkDropPanel from '../dropPanel/textAndLinkDropPanel';
import DropPanel from '../dropPanel/dropPanel';
import MenuBarItem from './item';
import * as UA from '#packages/uiAnimations';

function template() {
  return (
    <div data-aid="top-bar-menu-bar" className="top-bar-menu-bar-wrapper">
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
      {_.map(this.props.menuBarItems, (item, itemIndex) => {
        const isUpgradeDropPanel =
          this.props.openedDropPanel === item.key && item.key === 'UPGRADE';

        return (
          <DropPanelAnchor key={item.key}>
            <MenuBarItem
              title={translate(item.title)}
              getCounter={item.getCounter}
              automationId={item.automationId}
              isActive={this.getActiveItemIndex() === itemIndex}
              onMouseEnter={() => {
                this.onMouseEnter(item.key);
              }}
              onMouseLeave={() => {
                this.onMouseLeave();
              }}
              className={item.className}
            />
            <UA.topBarMenuPanelAnimation
              outAnimationDuration={this.props.delayedFadeOut ? 0.2 : 0}
            >
              {this.props.openedDropPanel === item.key &&
              item.key !== 'UPGRADE' &&
              item.key !== 'CODE' ? (
                <DropPanel
                  key={`${item.key}DropPanel`}
                  menuKey={item.key}
                  sendBi={this.props.sendBi}
                  automationId={item.dropPanelAutomationId}
                  onMouseEnter={() => {
                    this.props.openDropPanel(item.key);
                  }}
                  onMouseLeave={() => {
                    this.props.closeDropPanel();
                  }}
                  isChecklist={item.isChecklist}
                  closePanel={function () {
                    this.props.closeDropPanel(true);
                  }.bind(this)}
                  items={item.getListItems()}
                />
              ) : null}
              {isUpgradeDropPanel && !this.props.isUsingExternalUpgradeData ? (
                <UpgradeDropPanel
                  key="UpgradeDropPanel"
                  save={this.props.save}
                  isFirstSave={this.props.isFirstSave}
                  isDraftMode={this.props.isDraftMode}
                  sendBi={this.props.sendBi}
                  accountUpgrade={this.props.accountUpgrade}
                  getPersonalSaleTranslatedLabel={
                    this.props.getPersonalSaleTranslatedLabel
                  }
                  getPersonalSaleCampaignSymbolName={
                    this.props.getPersonalSaleCampaignSymbolName
                  }
                  isActivePersonalSaleCampaign={
                    this.props.isActivePersonalSaleCampaign
                  }
                  closePanel={function () {
                    this.props.closeDropPanel(true);
                  }.bind(this)}
                  onMouseEnter={() => {
                    this.props.openDropPanel(item.key);
                  }}
                  onMouseLeave={() => {
                    this.props.closeDropPanel();
                  }}
                />
              ) : null}
              {this.props.openedDropPanel === item.key &&
              item.key === 'CODE' ? (
                <CodeDropPanelCorvid
                  key="CorvidDropPanel"
                  sendBi={this.props.sendBi}
                  toggleDeveloperMode={this.props.toggleDeveloperMode}
                  isDeveloperModeEnabled={this.props.isDeveloperModeEnabled}
                  isWixCodeProvisioned={this.props.isWixCodeProvisioned}
                  onMouseEnter={() => {
                    this.props.openDropPanel(item.key);
                  }}
                  onMouseLeave={() => {
                    this.props.closeDropPanel();
                  }}
                  overrides={item.getOverrides()}
                  devToolsButtonEnabled={item.devToolsButtonEnabled}
                />
              ) : null}
              {isUpgradeDropPanel && this.props.isUsingExternalUpgradeData ? (
                <DealerUpgradeDropPanel
                  key="UpgradeDropPanel"
                  onMouseEnter={() => {
                    this.props.openDropPanel(item.key);
                  }}
                  onMouseLeave={() => {
                    this.props.closeDropPanel();
                  }}
                  dealerViewer={this.props.dealerViewer}
                  metaSiteId={this.props.metaSiteId}
                  metaSiteInstance={this.props.metaSiteInstance}
                  sendBi={this.props.sendBi}
                  fallbackToDefaultUpgradePanel={
                    this.props.fallbackToDefaultUpgradePanel
                  }
                  isFirstSave={this.props.isFirstSave}
                  isDraftMode={this.props.isDraftMode}
                />
              ) : null}
              {this.props.backToAdiTooltipVisibility &&
              this.props.shouldShowADITooltip &&
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line you-dont-need-lodash-underscore/includes
              _.includes(this.props.textAndLinkDropPanelKeys, item.key)
                ? // TODO: Fix this the next time the file is edited.
                  // eslint-disable-next-line you-dont-need-lodash-underscore/map
                  _.map(this.props.customDropPanelItems, (customDropPanel) =>
                    item.key === customDropPanel.targetButtonKey ? (
                      <TextAndLinkDropPanel
                        key={`${item.key}DropPanel`}
                        bubbleStyleOverrides={
                          customDropPanel.bubbleStyleOverrides
                        }
                        dropPanelData={customDropPanel.dropPanelData}
                      />
                    ) : null,
                  )
                : null}
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
              {_.map(this.props.customDropPanelItems, (customDropPanel) =>
                customDropPanel.key === this.props.openedDropPanel &&
                customDropPanel.targetButtonKey === item.key ? (
                  <TextAndLinkDropPanel
                    key={`${item.key}DropPanel`}
                    bubbleStyleOverrides={customDropPanel.bubbleStyleOverrides}
                    dropPanelData={customDropPanel.dropPanelData}
                  />
                ) : null,
              )}
            </UA.topBarMenuPanelAnimation>
          </DropPanelAnchor>
        );
      })}
    </div>
  );
}

const FIRST_OPEN_DROP_PANEL_DELAY = 300;
const { KEYBOARD_ACCESSIBLE_DROP_PANELS } = topBarData;

class MenuBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredItem: null,
    };

    const getNextActiveItemKey = (dir) => {
      const currActiveItemIndex = this.getActiveItemIndex();
      const { length } = KEYBOARD_ACCESSIBLE_DROP_PANELS;
      const newIndex =
        currActiveItemIndex === -1
          ? null
          : (length + currActiveItemIndex + dir) % length;

      return _.isNumber(newIndex)
        ? this.props.menuBarItems[newIndex].key
        : null;
    };

    const openDropPanelByDirection = (dir) => {
      const newItemKey = getNextActiveItemKey(dir);
      if (newItemKey) {
        this.props.openDropPanel(newItemKey);
      }
    };

    this.closePanelImmediately = (callback) =>
      this.props.closeDropPanel(true, callback);
    this.getActiveItemIndex = () =>
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find-index
      _.findIndex(this.props.menuBarItems, [
        'key',
        this.props.openedDropPanel || this.state.hoveredItem,
      ]);
    this.registerKeyboardContext = () => {
      const menuBarCtx = {
        esc: () => {
          if (this.props.openedDropPanel !== null) {
            const immediately = true;
            this.props.closeDropPanel(immediately);
          }
        },
        left: () => openDropPanelByDirection(-1),
        right: () => openDropPanelByDirection(1),
      };
      util.keyboardShortcuts.registerContext(
        util.keyboardShortcuts.CONTEXTS.MENU_BAR,
        menuBarCtx,
      );
    };

    this.onMouseEnter = (key) => {
      window.clearTimeout(this.openDropPanelTimer);

      this.setState({ hoveredItem: key });
      if (this.props.openedDropPanel) {
        this.props.openDropPanel(key);
      } else {
        this.openDropPanelTimer = window.setTimeout(
          () => this.props.openDropPanel(this.state.hoveredItem),
          FIRST_OPEN_DROP_PANEL_DELAY,
        );
      }
    };

    this.onMouseLeave = () => {
      this.props.closeDropPanel();
      this.setState({ hoveredItem: null });
    };
  }

  componentDidMount() {
    this.registerKeyboardContext();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.openedDropPanel === nextProps.openedDropPanel) {
      return;
    }

    const { MENU_BAR } = util.keyboardShortcuts.CONTEXTS;
    const currentActiveKeyboardContext = util.keyboardShortcuts.getContext();
    const willDropPanelOpen = nextProps.openedDropPanel !== null;
    const isMenuBarContextActive = currentActiveKeyboardContext === MENU_BAR;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    const isNextDropPanelAccessible = _.includes(
      KEYBOARD_ACCESSIBLE_DROP_PANELS,
      nextProps.openedDropPanel,
    );
    const isMenuBarContextInQueue = keyboardContextStack.hasContext(MENU_BAR);
    const shouldMenuBarContextBeActive =
      willDropPanelOpen && isNextDropPanelAccessible;

    if (
      shouldMenuBarContextBeActive &&
      !isMenuBarContextActive &&
      !isMenuBarContextInQueue
    ) {
      keyboardContextStack.pushAndSetContext(MENU_BAR);
    } else if (
      !shouldMenuBarContextBeActive &&
      (isMenuBarContextActive || isMenuBarContextInQueue)
    ) {
      keyboardContextStack.popAndRestoreLastContext(MENU_BAR);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(
      _.isEqual(this.state, nextState) && _.isEqual(this.props, nextProps)
    );
  }

  componentWillUnmount() {
    keyboardContextStack.popAndRestoreLastContext(
      util.keyboardShortcuts.CONTEXTS.MENU_BAR,
    );
    util.keyboardShortcuts.unregisterContext(
      util.keyboardShortcuts.CONTEXTS.MENU_BAR,
    );
  }

  render() {
    return template.call(this);
  }
}

MenuBar.displayName = 'MenuBar';
MenuBar.propTypes = {
  menuBarItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  openDropPanel: PropTypes.func.isRequired,
  closeDropPanel: PropTypes.func.isRequired,
  isUsingExternalUpgradeData: PropTypes.bool.isRequired,
  fallbackToDefaultUpgradePanel: PropTypes.func.isRequired,
  dealerViewer: PropTypes.func,
  delayedFadeOut: PropTypes.bool,
  openedDropPanel: PropTypes.string,
  isFirstSave: PropTypes.bool,
  isDraftMode: PropTypes.bool,
  sendBi: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  accountUpgrade: PropTypes.func.isRequired,
  toggleDeveloperMode: PropTypes.func.isRequired,
  isDeveloperModeEnabled: PropTypes.bool,
  isWixCodeProvisioned: PropTypes.bool,
  isActivePersonalSaleCampaign: PropTypes.bool.isRequired,
  getPersonalSaleTranslatedLabel: PropTypes.func.isRequired,
  getPersonalSaleCampaignSymbolName: PropTypes.func.isRequired,
  customDropPanelItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSiteFromOnBoarding: PropTypes.bool.isRequired,
  metaSiteId: PropTypes.string,
  metaSiteInstance: PropTypes.string,
};

export default MenuBar;

import { sections } from '#packages/util';
import { isMeshLayoutEnabled } from '#packages/layoutOneDockMigration';
import pasteLogicConfig from './pasteLogicConfig';

import type { EditorAPI } from '#packages/editorAPI';
import type { Viewport } from '#packages/stateManagement';
import type { MobileFramePosition } from 'types/core';
import type { CompLayout, Size } from 'types/documentServices';
import type { Point } from '../rootComps/mouseCatcher/mouseCatcher';

function getNextComponentPosition(componentPosition: Point) {
  pasteLogicConfig.xOffset = 20;
  pasteLogicConfig.yOffset = 20;

  return {
    x: componentPosition.x + pasteLogicConfig.xOffset,
    y: componentPosition.y + pasteLogicConfig.yOffset,
  };
}

function getNewStackComponentPosition(componentPosition: Point) {
  return {
    x: componentPosition.x + pasteLogicConfig.newStackOffset.x,
    y: componentPosition.y - pasteLogicConfig.newStackOffset.y,
  };
}

function cannotFitIntoViewPort(
  viewPort: Viewport,
  componentPosition: Point,
  componentLayout: CompLayout,
  ignoreThresholds?: boolean,
) {
  let { bottomThreshold } = pasteLogicConfig;
  if (ignoreThresholds) {
    bottomThreshold = 0;
  }
  return (
    componentPosition.x + componentLayout.width > viewPort.stageLayout.width ||
    componentPosition.y + bottomThreshold >
      viewPort.stageLayout.bottom + viewPort.scrollTop
  );
}

function cannotFitIntoMobileStage(
  mobileFramePosition: MobileFramePosition,
  componentPosition: Point,
): boolean {
  const { rightThreshold } = pasteLogicConfig;
  return (
    componentPosition.x + rightThreshold >
    mobileFramePosition.width + mobileFramePosition.left
  );
}

function getCenteredCompPosition(
  editorAPI: EditorAPI,
  viewPort: Viewport,
  componentSize: Size,
) {
  const pagesRef = editorAPI.dsRead.siteSegments.getPagesContainer();
  const pagesLayout = isMeshLayoutEnabled()
    ? editorAPI.components.layout.get_rect(pagesRef)
    : // NOTE:
      // `dsRead.siteSegments.layout.get` is same[1] as
      // `dsRead.components.layout.get`
      // [1]: https://github.com/wix-private/document-management/blob/c9dba0485fb13cda8c785fa73afaa97b933b5ef0/document-services/src/publicAPI/definitions/componentPublicAPI.ts#L275
      editorAPI.dsRead.siteSegments.layout.get(pagesRef);

  if (sections.isSectionsEnabled()) {
    const siteWidth = editorAPI.site.getWidth();
    const offsetLeft = editorAPI.isPopUpMode() ? 0 : editorAPI.site.getSiteX();

    return {
      x: offsetLeft + (siteWidth - componentSize.width) / 2,
      y:
        (viewPort.stageLayout.height - componentSize.height) / 2 +
        viewPort.scrollTop,
    };
  }

  return {
    x: (pagesLayout.width - componentSize.width) / 2,
    y:
      (viewPort.stageLayout.height - componentSize.height) / 2 +
      viewPort.scrollTop -
      pagesLayout.y,
  };
}

function getCenteredPositionInContainerAndViewPort(
  editorAPI: EditorAPI,
  containerLayout: AnyFixMe,
  viewPort: Viewport,
  componentSize: Size,
) {
  const centeredPositionInViewPort = getCenteredCompPosition(
    editorAPI,
    viewPort,
    componentSize,
  );
  const centeredPositionInContainer = getCenteredPositionInContainer(
    containerLayout,
    componentSize,
  );

  return {
    x: centeredPositionInContainer.x,
    y: centeredPositionInViewPort.y - containerLayout.y,
  };
}

function cannotFitIntoPageHeight(
  editorAPI: EditorAPI,
  viewPort: Viewport,
  componentPosition: Point,
) {
  const pagesRef = editorAPI.dsRead.siteSegments.getPagesContainer();
  const pagesLayout = isMeshLayoutEnabled()
    ? editorAPI.components.layout.get_rect(pagesRef)
    : // NOTE:
      // `dsRead.siteSegments.layout.get` is same[1] as
      // `dsRead.components.layout.get`
      // [1]: https://github.com/wix-private/document-management/blob/c9dba0485fb13cda8c785fa73afaa97b933b5ef0/document-services/src/publicAPI/definitions/componentPublicAPI.ts#L275
      editorAPI.dsRead.siteSegments.layout.get(pagesRef);

  return componentPosition.y > pagesLayout.height + viewPort.stageLayout.top;
}

function isComponentInViewPort(viewPort: Viewport, componentPosition: Point) {
  return (
    componentPosition.y >= viewPort.stageLayout.top &&
    componentPosition.y + pasteLogicConfig.bottomThreshold <=
      viewPort.stageLayout.bottom -
        viewPort.stageLayout.top +
        viewPort.scrollTop
  );
}

function getCenteredPositionInContainer(containerLayout: Size, compSize: Size) {
  return {
    x: Math.max((containerLayout.width - compSize.width) / 2, 0),
    y: Math.max((containerLayout.height - compSize.height) / 2, 0),
  };
}

function cannotFitInContainer(
  newComponentPosition: Point,
  compSize: Size,
  containerLayout: Size,
) {
  return newComponentPosition.y + compSize.height > containerLayout.height;
}

export {
  pasteLogicConfig,
  getNextComponentPosition,
  getNewStackComponentPosition,
  cannotFitIntoViewPort,
  getCenteredCompPosition,
  isComponentInViewPort,
  cannotFitIntoMobileStage,
  cannotFitIntoPageHeight,
  getCenteredPositionInContainer,
  getCenteredPositionInContainerAndViewPort,
  cannotFitInContainer,
};

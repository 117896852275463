// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import * as symbols from '@wix/santa-editor-symbols';
import { Tooltip, ContextMenu, ContextMenuAction } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';

//TYPE WAS GENERATED, remove this line when reviewed
interface CustomTooltipWithContextMenuProps {
  isTooltipContentOnAddVisible: boolean;
  isEditRemoveSectionVisible: boolean;
  onEditClick: FunctionFixMe;
  onRemoveClick: FunctionFixMe;
  isErrorContentVisible?: boolean;
  errorContent?: string;
}

class CustomTooltipWithContextMenu extends React.Component<CustomTooltipWithContextMenuProps> {
  static displayName = 'customTooltipWithContextMenu';

  static propTypes = {
    isTooltipContentOnAddVisible: PropTypes.bool.isRequired,
    isEditRemoveSectionVisible: PropTypes.bool.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    isErrorContentVisible: PropTypes.bool,
    errorContent: PropTypes.string,
  };

  static defaultProps = {
    isErrorContentVisible: false,
    errorContent: '',
  };

  getContextMenuButton = () => {
    return React.createElement(symbols.symbol, { name: 'threeDots' });
  };

  preventCloseAddPanel = (event) => {
    event.stopPropagation();
  };

  render() {
    return (
      <div>
        {this.props.isTooltipContentOnAddVisible ? (
          <div key="tooltip-content-add">
            <Tooltip
              content="mobile_features_feature_text_add_tosite"
              shouldTranslate={true}
              alignment="RIGHT"
            >
              <symbols.symbol name="plusBig" />
            </Tooltip>
          </div>
        ) : null}

        {this.props.isEditRemoveSectionVisible ? (
          <div onClick={this.preventCloseAddPanel} key="edit-remove-section">
            <ContextMenu customButton={this.getContextMenuButton()}>
              <ContextMenuAction
                automationId={'aid-ContextMenuAction-edit'}
                onClick={this.props.onEditClick}
                key="edit"
              >
                <symbols.symbol name="editAction" />
                <span>{translate('mobile_features_feature_text_edit')}</span>
              </ContextMenuAction>
              <ContextMenuAction
                automationId={'aid-ContextMenuAction-remove'}
                onClick={this.props.onRemoveClick}
                key="remove"
              >
                <symbols.symbol name="deleteAction" />
                <span>{translate('mobile_features_feature_text_remove')}</span>
              </ContextMenuAction>
            </ContextMenu>
          </div>
        ) : null}

        {this.props.isErrorContentVisible ? (
          <div key="error-content">
            <Tooltip
              content={this.props.errorContent}
              shouldTranslate={true}
              alignment="RIGHT"
            >
              <symbols.symbol
                name="info_bubble_icon"
                className="mobile-tools-custom-tooltip-edit-remove-invalid-icon"
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
    );
  }
}

export default CustomTooltipWithContextMenu;

/* eslint-disable react/no-unknown-property */
import { symbol as Symbol } from '#packages/baseUI';
import { mixins } from '#packages/core';
import { translate } from '#packages/i18n';
import { bi as stateManagementBI } from '#packages/stateManagement';
import { bi as biUtils, hoc } from '#packages/util';
import createReactClass from 'create-react-class';
import React from 'react';
import type { MapDispatchToProps } from 'types/redux';
import { openAppMarketTab } from '../appMarket/services/appMarketTabService';
import { getPendingAppsCount } from '../services/pendingAppsService';
import TpaAppBox from './tpaAddPanelAppBox';

// eslint-disable-next-line react/prefer-es6-class
const tpaAddPanelSectionComponent = createReactClass({
  displayName: 'tpaAddPanelSection',
  mixins: [mixins.editorAPIMixin],
  render() {
    return (
      // @ts-expect-error
      <div category={this.getCategory()}>
        {this.getAppBoxList().map((box: AnyFixMe, boxIndex: AnyFixMe) => (
          <div key={box.appDefinitionId}>
            <TpaAppBox
              box={box}
              index={boxIndex}
              category={this.props.props.category}
              onItemDrag={this.props.onItemDrag}
              referralInfo={this.props.parentType}
            />
          </div>
        ))}
        <div className="more">
          <Symbol name="market" />
          <span>{translate('tpa_add_panel_still_havent_found')}</span>
          <a onClick={this.onClick}>
            {translate('tpa_add_panel_browse_app_market')}
          </a>
          <span> </span>
        </div>
      </div>
    );
  },

  appBoxList: [],

  onClick() {
    this.props.openWixAppMarket(
      this.props.props.category,
      this.props.parentType.toLowerCase(),
    );
  },

  getAppBoxList() {
    return this.appBoxList;
  },

  getCategory() {
    return this.props.props.category;
  },

  getCategoryNum() {
    return this.props.props.categoryNum;
  },
});

export interface TpaAddPanelSectionDispatchProps {
  openWixAppMarket: (category: string, referralInfo: string) => void;
}

export const mapDispatchToProps: MapDispatchToProps<
  TpaAddPanelSectionDispatchProps,
  any
> = (dispatch) => {
  const editorAPI = dispatch(
    (dispatch: AnyFixMe, getState: AnyFixMe, { editorAPI }: AnyFixMe) =>
      editorAPI,
  );

  return {
    openWixAppMarket(category, referralInfo) {
      editorAPI.panelManager.closePanelByName('rEditor.addPanel');
      const urlParams = {
        referralInfoCategory: category,
        openMarketOrigin: referralInfo,
        section: 'related_apps',
      };
      openAppMarketTab(
        {
          urlParams,
        },
        editorAPI.panelManager.openPanel,
      );

      dispatch(
        stateManagementBI.actions.event(biUtils.events.OPEN_MARKET_CLICK, {
          referral_info: referralInfo,
          referral_info_category: category,
          num_pending_apps: getPendingAppsCount(editorAPI),
        }),
      );
    },
  };
};

const ConnectedTpaAddPanelSection = hoc.connect(
  hoc.STORES.EDITOR_API,
  null,
  mapDispatchToProps,
)(tpaAddPanelSectionComponent);

ConnectedTpaAddPanelSection.pure = tpaAddPanelSectionComponent;

export default ConnectedTpaAddPanelSection;

// @ts-nocheck
import * as addPanel from '#packages/oldAddPanel';

export default {
  getDesignSections() {
    return {
      titles: ['LangMenu_Design_Header_Label'],
      sections: [addPanel.sections.getSections().menu.languageSelectorSection],
    };
  },
};

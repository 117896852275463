import _ from 'lodash';
import { EditorAPIKey } from '#packages/apis';
import constants from '#packages/constants';
import * as stateManagement from '#packages/stateManagement';
import type { Shell } from '#packages/apilib';

export function createWixCodeApi(shell: Shell) {
  const editorAPI = shell.getAPI(EditorAPIKey);
  const WIX_CODE_LOADED_PROP = 'wixCodeLoaded';
  const codeEditorCallbacks: FunctionFixMe[] = [];
  let wixCodeLoadedCallbacks: FunctionFixMe[] = [];
  let codeEditorLoaded = false;

  function isLoaded() {
    return editorAPI.store.getState()[WIX_CODE_LOADED_PROP];
  }
  function isCodeEditorLoaded() {
    return codeEditorLoaded;
  }

  function setLoaded() {
    editorAPI.toggleEditorStateParam(WIX_CODE_LOADED_PROP, true);
    onWixCodeLoaded();
  }
  function setCodeEditorLoaded(isLoaded: boolean) {
    codeEditorLoaded = isLoaded;
  }

  const registerToCodeEditorLoaded = (callback: AnyFixMe) => {
    codeEditorCallbacks.push(callback);
  };

  const onWixCodeLoaded = () => {
    wixCodeLoadedCallbacks.forEach((cb) => cb());
  };
  const onCodeEditorLoaded = () => {
    setCodeEditorLoaded(true);
    codeEditorCallbacks.forEach((cb) => cb());
  };

  const registerToWixCodeLoaded = (fn: FunctionFixMe) => {
    wixCodeLoadedCallbacks.push(fn);
    return function unsubscribe() {
      wixCodeLoadedCallbacks = wixCodeLoadedCallbacks.filter((cb) => cb !== fn);
    };
  };

  /*should always navigate to inner route so that the routerData will always be synced when going to preview*/
  function navigateToCurrentInnerRoute(callback: AnyFixMe) {
    function createDynamicLinkAndNavigate(currentRouteInfo: AnyFixMe) {
      const linkItem = editorAPI.dsRead.data.createItem('DynamicPageLink');
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(linkItem, {
        innerRoute: currentRouteInfo.innerRoute || '/',
        routerId: currentRouteInfo.routerId,
      });
      editorAPI.pages.navigateTo(linkItem, callback, true);
    }

    const currentRouterId = stateManagement.dynamicPages.selectors.getRouterId(
      editorAPI.store.getState(),
    );

    if (currentRouterId) {
      createDynamicLinkAndNavigate({
        innerRoute:
          stateManagement.dynamicPages.selectors.getSelectedInnerRoute(
            editorAPI.store.getState(),
          ),
        routerId: currentRouterId,
      });
      return;
    }
    callback();
  }

  function prepareBeforePreview(callback: AnyFixMe) {
    const PREVIEW_STATUS_PARAM = 'previewingStatus';

    if (editorAPI.wixCode.isProvisioned()) {
      editorAPI.savePublish.lockSavePublish();
      editorAPI.toggleEditorStateParam(
        PREVIEW_STATUS_PARAM,
        constants.PROGRESS_STATUS.IN_PROGRESS,
      );
      const navigateToCurrentInnerRouteAndOpenPreview = () =>
        navigateToCurrentInnerRoute(function () {
          resetProgress();
          openPreview();
        });
      if (editorAPI.wixCode.isLoaded()) {
        editorAPI.wixCode.console.clear();
        editorAPI.wixCode.fileSystem
          .flush({ origin: editorAPI.wixCode.fileSystem.FLUSH_ORIGINS.PREVIEW })
          .then(navigateToCurrentInnerRouteAndOpenPreview)
          .catch(function () {
            handleSaveError();
            resetProgress();
          });
      } else {
        navigateToCurrentInnerRouteAndOpenPreview();
      }
    } else {
      openPreview();
    }
    function handleSaveError() {
      editorAPI.panelManager.openPanel('savePublish.panels.common.failPanel', {
        titleKey: 'WixCode_PreviewSaveFail_Title',
        description: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION',
        stepsTitle: 'SAVE_PUBLISH_ERROR_BODY_DESCRIPTION_BOLD',
        steps: [
          'SAVE_PUBLISH_ERROR_OPTION_1',
          'WixCode_PreviewSaveFail_Step2_Text',
          'WixCode_DeveloperModeFail_Step3_Text',
        ],
        helpMessage: 'WixCode_PreviewSaveFail_Learn_More_Label',
        helpLinkMessage: 'WixCode_PreviewSaveFail_Learn_More_Link',
        helpLink: '',
        errorCode: '1234',
      });
      editorAPI.savePublish.unlockSavePublish();
    }

    function openPreview() {
      editorAPI.savePublish.unlockSavePublish();
      callback();
    }

    function resetProgress() {
      editorAPI.toggleEditorStateParam(
        PREVIEW_STATUS_PARAM,
        constants.PROGRESS_STATUS.IDLE,
      );
    }
  }

  async function provisionAsync() {
    if (editorAPI.generalInfo.isFirstSave()) {
      await new Promise((resolve, reject) => {
        editorAPI.saveManager.saveInBackground(
          resolve,
          reject,
          'wixCodeProvision',
          {
            sourceOfStart: 'wixCodeProvision_bgSave',
          },
        );
      });
    }
    return new Promise((resolve, reject) =>
      editorAPI.documentServices.wixCode.provision({
        onSuccess: resolve,
        onError: reject,
      }),
    );
  }

  function provision({ onSuccess = _.noop, onError = _.noop }) {
    provisionAsync().then(onSuccess).catch(onError);
  }

  return {
    isLoaded,
    isCodeEditorLoaded,
    onCodeEditorLoaded,
    registerToCodeEditorLoaded,
    setLoaded,
    prepareBeforePreview,
    provision,
    registerToWixCodeLoaded,
    setCodeEditorLoaded,
  };
}

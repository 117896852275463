// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import * as symbols from '@wix/santa-editor-symbols';
import * as keyboardContextStack from '../util/keyboardContextStack';
import * as coreBi from '#packages/coreBi';
import constants from '#packages/constants';
import {
  Bubble,
  Composites,
  Divider,
  RadioButtonsWithIllustration,
  ToggleSwitch,
} from '@wix/wix-base-ui';
import DropPanelIllustration from './dropPanelIllustration';

function template() {
  return (
    <Bubble
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      arrowAlignment="top"
      contentStyle={this.bubbleContentStyle}
      arrowStyle={this.bubbleArrowStyle}
      maxWidth={750}
      className="drop-panel-top-bar drop-panel-mobile"
    >
      <div data-aid="drop-panel-switch-mode" className="drop-panel-content">
        <div className="drop-panel-mobile-controls">
          <Composites.RadioButtonsWithIllustration>
            <RadioButtonsWithIllustration
              value={this.props.isMobileEditor ? 'mobile' : 'desktop'}
              options={this.radioOptions}
              onChange={this.onRadioChange}
            />
          </Composites.RadioButtonsWithIllustration>

          <div className="drop-panel-mobile-controls-bottom">
            <Divider />

            <Composites.ToggleSwitch>
              <ToggleSwitch
                value={this.props.isMobileOptimized}
                label="TOPBAR_MobileDesktop_MobileOptimized"
                onChange={this.toggleMobileOptimized}
              />
            </Composites.ToggleSwitch>
          </div>
        </div>

        <DropPanelIllustration
          image={this.getImageSrc()}
          onClick={_.noop}
          title="TOPBAR_MOBILE_PROMOTION_TITLE"
          description="TOPBAR_MOBILE_PROMOTION_BODY"
        />
      </div>
    </Bubble>
  );
}

const { MODES } = constants.ROOT_COMPS.TOPBAR;

const BUBBLE_ARROW_STYLE = {
  position: 'absolute',
  right: 'calc(50% - 8px)',
  top: '53px',
};

const BUBBLE_CONTENT_STYLE = {
  position: 'absolute',
  display: 'block',
  minHeight: '298px',
  right: 'calc(50% - 70px)',
  paddingLeft: 0,
  top: '60px',
};

const radioOptions = [
  {
    text: 'TOPBAR_MobileDesktop_Desktopview',
    image: React.createElement(symbols.symbol, { name: 'desktop_v2' }),
    value: 'desktop',
  },
  {
    text: 'TOPBAR_MobileDesktop_MobileView',
    image: React.createElement(symbols.symbol, { name: 'mobile_v2' }),
    value: 'mobile',
  },
];

class MobileDropPanel extends React.Component {
  constructor(props) {
    super(props);
    this.nextEditorMode = this.props.isMobileEditor ? 'mobile' : 'desktop';
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    this.bubbleContentStyle = _.assign({}, BUBBLE_CONTENT_STYLE);
    this.bubbleArrowStyle = BUBBLE_ARROW_STYLE;
    this.radioOptions = radioOptions;

    const devicePixelRatio = util.browserUtil.getDevicePixelRatio();
    const getImageFromStatics = (fileName) =>
      util.media.getMediaUrl(`rEditor/topBar/${fileName}`);

    this.getImageSrc = () =>
      getImageFromStatics(
        devicePixelRatio >= 2
          ? 'mobileDesktopIllustration@x2.png'
          : 'mobileDesktopIllustration.png',
      );

    this.toggleMobileOptimized = () => {
      const newIsOptimized = !this.props.isMobileOptimized;
      const originValue = this.props.isPreviewMode
        ? MODES.PREVIEW
        : MODES.EDITOR;

      this.props.sendBi(coreBi.events.topbar.mobile_view_mode_toggle, {
        origin: originValue,
        status: newIsOptimized ? 1 : 0,
      });

      this.props.setMobileOptimizedSite(newIsOptimized);
    };

    this.setEditorMode = () => {
      this.props.setEditorMode(this.nextEditorMode === 'mobile');

      const biEvent = this.props.isPreviewMode
        ? coreBi.events.topbar.top_bar_preview_page_editor_view_mode_click
        : coreBi.events.topbar.top_bar_editor_view_mode_click;

      this.props.sendBi(biEvent, { editor_view_mode: this.nextEditorMode });

      this.props.sendBi(coreBi.events.topbar.editor_view_mode_switch, {
        origin: 'site_menu',
        mode: this.nextEditorMode,
      });
    };

    this.onRadioChange = (value) => {
      if (this.nextEditorMode !== value) {
        this.nextEditorMode = value;
      }

      this.props.closePanel();
    };

    this.getKeyboardShortcuts = () => ({
      esc: () => {
        this.props.closePanel();
      },
      enter: (e) => {
        e.preventDefault();
      },
    });
  }

  componentDidMount() {
    this.customKeyboardContext = keyboardContextStack.pushAndSetCustomContext(
      this.getKeyboardShortcuts(),
    );
  }

  componentWillUnmount() {
    keyboardContextStack.popAndRestoreLastContext(
      this.customKeyboardContext,
      true,
    );
    const shouldChangeToDesktop =
      this.props.isMobileEditor && this.nextEditorMode === 'desktop';
    const shouldChangeToMobile =
      !this.props.isMobileEditor && this.nextEditorMode === 'mobile';

    if (shouldChangeToDesktop || shouldChangeToMobile) {
      this.setEditorMode();
    }
  }

  render() {
    return template.call(this);
  }
}

MobileDropPanel.displayName = 'MobileDropPanel';

MobileDropPanel.propTypes = {
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  closePanel: PropTypes.func.isRequired,
  sendBi: PropTypes.func.isRequired,
  setMobileOptimizedSite: PropTypes.func.isRequired,
  setEditorMode: PropTypes.func.isRequired,
  isMobileEditor: PropTypes.bool,
  isPreviewMode: PropTypes.bool,
  isMobileOptimized: PropTypes.bool,
};

export default MobileDropPanel;

// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import { translate } from '#packages/i18n';
import React from 'react';
import { Button } from '@wix/wix-base-ui';
import PanelCard from './card';
import Tag from './tag';

//TYPE WAS GENERATED, remove this line when reviewed
interface Props {
  categories: Array<{
    id: string;
    title: string;
    isSelected?: boolean;
  }>;
  dotsActions?: Array<{
    id?: string;
    label?: string;
    onClick?: FunctionFixMe;
  }>;
  onDotsClick?: FunctionFixMe;
  onApply?: FunctionFixMe;
  onSeeAll?: FunctionFixMe;
}

export default class extends React.Component<Props> {
  static displayName = 'RecommendedMediaCard';

  static propTypes = {
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        isSelected: PropTypes.bool,
      }),
    ).isRequired,
    dotsActions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        onClick: PropTypes.func,
      }),
    ),
    onDotsClick: PropTypes.func,
    onApply: PropTypes.func,
    onSeeAll: PropTypes.func,
  };

  state = {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/filter
    selectedCategories: _(this.props.categories)
      .filter('isSelected')
      .map('id')
      .value(),
  };

  title = () => {
    return translate('Media_Panel_Recommended_Section_EmptyState_Title');
  };

  subtitle = () => {
    return translate('Media_Panel_Recommended_Section_EmptyState_Subitle');
  };

  seeAllText = () => {
    return translate('Media_Panel_Recommended_Section_EmptyState_SeeAll_Text');
  };

  applyText = () => {
    return translate('Media_Panel_Recommended_Section_EmptyState_Apply_Button');
  };

  hasSelectedCategories = () => {
    return Boolean(this.state.selectedCategories.length);
  };

  getCategories = () => {
    return this.props.categories;
  };

  isCategorySelected = (id) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(this.state.selectedCategories, id);
  };

  toggleCategory = (id) => {
    const { selectedCategories } = this.state;

    if (this.isCategorySelected(id)) {
      this.setState({
        selectedCategories: _.reject(
          selectedCategories,
          (categoryId) => categoryId === id,
        ),
      });
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/concat
      this.setState({ selectedCategories: _.concat(selectedCategories, id) });
    }
  };

  handleApplyButtonClick = () => {
    _.invoke(this.props, 'onApply', this.state.selectedCategories);
  };

  handleSeeAllButtonClick = () => {
    _.invoke(this.props, 'onSeeAll', this.state.selectedCategories);
  };

  handleDotsClick = () => {
    _.invoke(this.props, 'onDotsClick');
  };

  render() {
    return (
      <PanelCard
        title={this.title()}
        subtitle={this.subtitle()}
        dotsActions={this.props.dotsActions}
        onDotsClick={this.handleDotsClick}
      >
        <div>
          <ul>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line you-dont-need-lodash-underscore/map */}
            {_.map(this.getCategories(), (category) => (
              <li key={category.id} className="recommended-media-card__tag">
                <Tag
                  id={category.id}
                  text={category.title}
                  isSelected={this.isCategorySelected(category.id)}
                  onClick={(id) => this.toggleCategory(id)}
                />
              </li>
            ))}
          </ul>

          <div className="recommended-media-card__footer">
            <Button
              onClick={() => {
                this.handleSeeAllButtonClick();
              }}
              className="btn-text recommended-media-card__see-all-button"
            >
              {this.seeAllText()}
            </Button>

            <Button
              onClick={() => {
                this.handleApplyButtonClick();
              }}
              disabled={!this.hasSelectedCategories()}
              className="btn-sm recommended-media-card__apply-button"
            >
              {this.applyText()}
            </Button>
          </div>
        </div>
      </PanelCard>
    );
  }
}

// @ts-nocheck
import { array } from '#packages/util';
import wixFormsConstants from '../../contact/wixForms/constants';
import * as wixFormsUtils from '../../contact/wixForms/utils';

const addForm = (structure) => {
  const form = wixFormsUtils.getFormWidget({
    layout: {
      width: 490,
      height: 172,
      x: 198.5,
      y: 265,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: 157,
          originalValue: 448,
          type: 'TOP_TOP',
          locked: true,
          targetComponent: 'comp-im22y86b',
        },
        {
          distance: 5,
          originalValue: 476,
          type: 'BOTTOM_PARENT',
          locked: true,
          targetComponent: 'comp-im22i2b5',
        },
      ],
      fixedPosition: false,
    },
    id: 'comp-im22i2c1',
    mobileLayout: {
      width: 282,
      height: 256,
      x: 12,
      y: 268,
      scale: 1,
      rotationInDegrees: 0,
      anchors: [
        {
          distance: -10,
          topToTop: 297,
          originalValue: 618,
          type: 'BOTTOM_TOP',
          locked: true,
          targetComponent: 'comp-im22i2bq',
        },
      ],
      fixedPosition: false,
    },
  });

  // add form to
  // components: [
  //     component_0: {
  //         components: [
  //             component_00
  //             form,
  //             ...
  //         ]
  //     },
  // ]
  return {
    ...structure,
    components: array.immutableSplice(structure.components, 0, 1, {
      ...structure.components[0],
      components: array.immutableSplice(
        structure.components[0].components,
        1,
        0,
        form,
      ),
    }),
  };
};

export default (onDrop, onClick) => (item) => {
  onDrop = onDrop(wixFormsConstants.FormPreset.LIGHTBOX_CONTACT03);
  onClick = onClick(wixFormsConstants.FormPreset.LIGHTBOX_CONTACT03);

  return {
    ...item,
    onDrop,
    onClick,
    structure: addForm(item.structure),
  };
};

import PropTypes from 'prop-types';
import React from 'react';
import * as panels from '#packages/panels';
import * as symbols from '@wix/santa-editor-symbols';
import {
  Button,
  Composites,
  Illustration,
  PanelHeader,
  Text,
  TextLabel,
} from '@wix/wix-base-ui';

function template(props: AnyFixMe) {
  return (
    <panels.frames.FocusPanelFrame
      shouldHideHeader={true}
      panelName={props.panelName}
      width="564px"
      className="app-import-error-panel"
    >
      <Composites.PopupSmallSize className="danger">
        <PanelHeader
          onClose={() => {
            props.onClose();
          }}
        >
          <TextLabel value="AppStudio_Import_Error_Title" />
        </PanelHeader>
        <Composites.PanelContent>
          <Composites.RichTextWithIllustration>
            <Illustration>
              <symbols.symbol name="platformWarning" />
            </Illustration>
            <Composites.RichText>
              <Text enableEllipsis={false}>
                {'AppStudio_Import_Error_Text'}
              </Text>
            </Composites.RichText>
          </Composites.RichTextWithIllustration>
        </Composites.PanelContent>
        <Composites.ActionSetHorizontal>
          <Button
            onClick={() => {
              props.onClose();
            }}
            automationId="error-ok-button"
            className="primary"
          >
            <TextLabel value="AppStudio_Close_Button" />
          </Button>
        </Composites.ActionSetHorizontal>
      </Composites.PopupSmallSize>
    </panels.frames.FocusPanelFrame>
  );
}

const AppImportErrorPanel = (props: AnyFixMe) => template(props);

AppImportErrorPanel.propTypes = {
  onClose: PropTypes.func,
};

export default AppImportErrorPanel;

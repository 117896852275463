import * as coreBi from '#packages/coreBi';
import { PlatformApiKey } from '#packages/apis';
import type { EditorAPI } from '#packages/editorAPI';
import * as util from '#packages/util';
import * as stateManagement from '#packages/stateManagement';

import { dispatchEditorAPI } from '../../../topBarComponent/topBarMapper';

import type { MapDispatchToPropsFunction, MapStateToProps } from 'types/redux';
import type {
  ToolsMenuAPIOwnProps,
  ToolsMenuAPIStateProps,
  ToolsMenuAPIDispatchProps,
} from './toolsMenuAPI';

const mapStateToProps: MapStateToProps<
  ToolsMenuAPIStateProps,
  ToolsMenuAPIOwnProps
> = () => ({});

const mapDispatchToProps: MapDispatchToPropsFunction<
  ToolsMenuAPIDispatchProps,
  ToolsMenuAPIOwnProps
> = (dispatch) => ({
  toggleToolBar: dispatchEditorAPI(dispatch, (editorAPI) => {
    editorAPI.toolbar.toggleToolbar();
    dispatch(
      stateManagement.userPreferences.actions.setSiteUserPreferences(
        'topBarCloseToolsUsed',
        true,
      ),
    );
  }),
  toggleLayersPanel: dispatchEditorAPI(dispatch, (editorAPI) => {
    const state = editorAPI.store.getState();
    const isLayersPanelDisplayed =
      stateManagement.layersPanel.selectors.isLayersPanelDisplayed(state);

    if (isLayersPanelDisplayed) {
      dispatch(stateManagement.layersPanel.actions.hideLayersPanel());
    } else {
      const origin = 'tools menu';
      util.editorWixRecorder.addLabel(
        'panels.toolPanels.layersPanel panel opened',
      );
      editorAPI.bi.event(coreBi.events.panels.PANEL_OPENED, {
        panel_name: 'panels.toolPanels.layersPanel',
        origin,
      });
      dispatch(stateManagement.layersPanel.actions.showLayersPanel(origin));
    }
  }),
  toggleRulers() {
    dispatch(stateManagement.rulers.actions.toggleRulers());
  },
  toggleGuidelines: dispatchEditorAPI(
    dispatch,
    (editorAPI, onSuccess, onFail) =>
      editorAPI.grid.guidelines.toggleGuidelines(onSuccess, onFail),
  ),
  toggleSnapTo: dispatchEditorAPI(dispatch, (editorAPI, onSuccess, onFail) =>
    editorAPI.grid.snapTo.toggleSnapTo(onSuccess, onFail),
  ),
  toggleDeveloperToolBar: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.developerToolBar.toggle(),
  ),
  toggleDeveloperHiddenComponents: dispatchEditorAPI(dispatch, (editorAPI) =>
    editorAPI.developerHiddenComponents.toggle(),
  ),
  openAddonsMarketPanel: dispatchEditorAPI(dispatch, (editorAPI: EditorAPI) => {
    editorAPI.host.getAPI(PlatformApiKey).addons.openAddonsMarketPanel();
  }),
});

export { mapStateToProps, mapDispatchToProps };

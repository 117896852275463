import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  ComponentsApiKey,
  EditorAPIKey,
  EditorCoreApiKey,
  SelectionApiKey,
} from '#packages/apis';
import { bootstrap } from './bootstrap';

export class Scope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreApi = this.useDependency(EditorCoreApiKey);
  selection = this.useDependency(SelectionApiKey);
  components = this.useDependency(ComponentsApiKey);
}

export const DataFixersEntryPoint = createEntryPoint({
  name: 'DataFixers',
  Scope,
  publicApi() {},
  async initialize(scope) {
    await scope.editorCoreApi.hooks.initReady.promise;
    bootstrap(scope);
  },
});

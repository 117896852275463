// @ts-nocheck
import _ from 'lodash';
import * as controllerSettingsGfpp from './controllerSettingsGfpp';
import * as appWidgetGfpp from './appWidgetGfpp';
import events from '../bi/events';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

function hasController(editorAPI, compRefs) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  return _(compRefs)
    .reduce(function (acc, compPointer) {
      return editorAPI.components.is.container(compPointer)
        ? acc.concat(
            compPointer,
            editorAPI.components.get.byAncestor_DEPRECATED_BAD_PERFORMACE(
              compPointer,
            ),
          )
        : acc.concat(compPointer);
    }, [])
    .some(
      (pointer) =>
        editorAPI.components.getType(pointer) ===
        'platform.components.AppController',
    );
}

function hasConnectionsThatWillBreak(editorAPI, compRefs) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/reduce
  const allCompRefs = _.reduce(
    compRefs,
    function (acc, compPointer) {
      return editorAPI.components.is.container(compPointer)
        ? acc.concat(
            compPointer,
            editorAPI.components.get.byAncestor_DEPRECATED_BAD_PERFORMACE(
              compPointer,
            ),
          )
        : acc.concat(compPointer);
    },
    [],
  );

  let allCompsById;

  function hasConnectionNotInSelectedComps(connections) {
    allCompsById = allCompsById || _.keyBy(allCompRefs, 'id');
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    return _(connections)
      .map('controllerRef')
      .some(function (controllerRef) {
        const { id } = controllerRef;
        return (
          !allCompsById[id] &&
          editorAPI.components.getPage(controllerRef).id !==
            editorAPI.pages.getMasterPageId()
        );
      });
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/some
  return _.some(allCompRefs, function (pointer) {
    const connections =
      editorAPI.dsRead.platform.controllers.connections.get(pointer);
    return (
      !_.isEmpty(connections) && hasConnectionNotInSelectedComps(connections)
    );
  });
}

function showWarningIfNeeded(editorAPI, compRefs, attachCandidateRef) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  compRefs = _.isArray(compRefs) ? compRefs : [compRefs];
  const isFromMasterPage = editorAPI.components.isShowOnAllPages(compRefs);
  const isToMasterPage =
    editorAPI.components.isShowOnAllPages(attachCandidateRef);

  function openPanel(props) {
    editorAPI.panelManager.openPanel(
      'platform.panels.warnForDisconnectedComponentsPanel',
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(
        {
          undo: editorAPI.history.undo,
        },
        props,
      ),
    );
  }

  if (
    isFromMasterPage &&
    !isToMasterPage &&
    hasController(editorAPI, compRefs)
  ) {
    openPanel({ controllerComp: true });
  }

  if (
    !isFromMasterPage &&
    isToMasterPage &&
    hasConnectionsThatWillBreak(editorAPI, compRefs)
  ) {
    openPanel({ connectedComps: true });
  }
}

function sendCannotDeleteControllerBI(editorAPI, compRef) {
  const compData = editorAPI.components.data.get(compRef);
  editorAPI.bi.event(events.page_delete_dataset, {
    app_name: compData.applicationId,
    controller_type: compData.controllerType,
    controller_id: compRef.id,
    pageId: editorAPI.dsRead.pages.getPrimaryPageId(),
  });
}

function init(editorAPI, registerGfpp) {
  controllerSettingsGfpp.init(registerGfpp);
  appWidgetGfpp.init(registerGfpp);
  editorAPI.registerCannotRemovePlugin(
    'platform.components.AppController',
    sendCannotDeleteControllerBI.bind(null, editorAPI),
  );
  editorAPI.editorEventRegistry.register(
    editorAPI.editorEventRegistry.constants.events.SET_CONTAINER_BEFORE,
    showWarningIfNeeded,
  );
  return this;
}

function notifyControllerGfppClicked(
  actionId: string,
  editorAPI: EditorAPI,
  compRefs: CompRef[],
) {
  return controllerSettingsGfpp.notifyControllerGfppClicked(
    { actionId },
    editorAPI,
    compRefs,
  );
}

const notifyWidgetGfppClicked = appWidgetGfpp.notifyWidgetGfppClicked;

export { init, notifyControllerGfppClicked, notifyWidgetGfppClicked };

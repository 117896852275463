// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  hide: false,
  title: 'add_section_label_rss',
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.SCALE,
    iconEnabledForComps: {},
  },
  props: {
    image: 'blog/sections/rssButtonSection.png',
    imageHover: null,
    items: [
      {
        id: 'Blog_RSS_Button_1',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          layout: {
            width: 34,
            height: 34,
            x: 10,
            y: 15,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.common.components.rssbutton.viewer.RSSButton',
          data: {
            type: 'RssButton',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            image: {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'RSS Social Icon',
              uri: '062430dbfeba4663a6bf9465b05dee18.png',
              description: '',
              width: 34,
              height: 34,
              alt: 'RSS Feed',
            },
            link: {
              type: 'ExternalLink',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              url: 'http://editor.wix.com/feed.xml',
              target: '_blank',
            },
          },
          props: {
            type: 'RssButtonProperties',
            metaData: { schemaVersion: '1.0' },
            transition: 'fade',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'RSSButton_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          },
          id: 'i8y6qn90',
        },
        preset: {
          rect: { width: 54, height: 64, x: 0, y: 0 },
          label: '',
          tags: null,
        },
      },
      {
        id: 'Blog_RSS_Button_2',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          layout: {
            width: 34,
            height: 34,
            x: 64,
            y: 15,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.common.components.rssbutton.viewer.RSSButton',
          data: {
            type: 'RssButton',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            image: {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'RSS - Black Circle',
              uri: 'b54ca3aedd9b4906a07a75b77e86e102.png',
              description: '',
              width: 34,
              height: 34,
              alt: 'RSS Feed',
            },
            link: {
              type: 'ExternalLink',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              url: 'http://editor.wix.com/feed.xml',
              target: '_blank',
            },
          },
          props: {
            type: 'RssButtonProperties',
            metaData: { schemaVersion: '1.0' },
            transition: 'fade',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'RSSButton_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          },
          id: 'i7bqhnq2',
        },
        preset: {
          rect: { width: 54, height: 64, x: 54, y: 0 },
          label: '',
          tags: null,
        },
      },
      {
        id: 'Blog_RSS_Button_3',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          layout: {
            width: 34,
            height: 34,
            x: 118,
            y: 15,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.common.components.rssbutton.viewer.RSSButton',
          data: {
            type: 'RssButton',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            image: {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'RSS - Grey Circle',
              uri: '51f52d07264f4f16931efa0a35ac198a.png',
              description: '',
              width: 34,
              height: 34,
              alt: 'RSS Feed',
            },
            link: {
              type: 'ExternalLink',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              url: 'http://editor.wix.com/feed.xml',
              target: '_blank',
            },
          },
          props: {
            type: 'RssButtonProperties',
            metaData: { schemaVersion: '1.0' },
            transition: 'fade',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'RSSButton_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          },
          id: 'i8y6vwmp',
        },
        preset: {
          rect: { width: 54, height: 64, x: 108, y: 0 },
          label: '',
          tags: null,
        },
      },
      {
        id: 'Blog_RSS_Button_4',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          layout: {
            width: 41,
            height: 36,
            x: 162,
            y: 14,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.common.components.rssbutton.viewer.RSSButton',
          data: {
            type: 'RssButton',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            image: {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Grey RSS Icon',
              uri: 'a01185ce2c514940ac4cf52014d681ea.png',
              description: '',
              width: 36,
              height: 36,
              alt: 'RSS Feed',
            },
            link: {
              type: 'ExternalLink',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              url: 'http://editor.wix.com/feed.xml',
              target: '_blank',
            },
          },
          props: {
            type: 'RssButtonProperties',
            metaData: { schemaVersion: '1.0' },
            transition: 'fade',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'RSSButton_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          },
          id: 'i8y6wclr',
        },
        preset: {
          rect: { width: 42, height: 64, x: 162, y: 0 },
          label: '',
          tags: null,
        },
      },
      {
        id: 'Blog_RSS_Button_5',
        structure: {
          type: 'Component',
          skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          layout: {
            width: 34,
            height: 34,
            x: 208,
            y: 15,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.common.components.rssbutton.viewer.RSSButton',
          data: {
            type: 'RssButton',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            image: {
              type: 'Image',
              metaData: {
                isPreset: false,
                schemaVersion: '2.0',
                isHidden: false,
              },
              title: 'Black RSS Icon',
              uri: '175b0e2106e64c33b9e42ae0763a63ab.png',
              description: '',
              width: 34,
              height: 34,
              alt: 'RSS Feed',
            },
            link: {
              type: 'ExternalLink',
              metaData: {
                isPreset: false,
                schemaVersion: '1.0',
                isHidden: false,
              },
              url: 'http://editor.wix.com/feed.xml',
              target: '_blank',
            },
          },
          props: {
            type: 'RssButtonProperties',
            metaData: { schemaVersion: '1.0' },
            transition: 'fade',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'RSSButton_1',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.common.components.rssbutton.viewer.skins.RSSButtonSkin',
          },
          id: 'i8y6va2k',
        },
        preset: {
          rect: { width: 42, height: 64, x: 204, y: 0 },
          label: '',
          tags: null,
        },
      },
    ],
    compTypes: ['wysiwyg.common.components.rssbutton.viewer.RSSButton'],
  },
  help: { hide: false, text: 'add_section_info_text_rss' },
};

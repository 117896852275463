import React, { type MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ArrowDownSmall,
  ArrowUpSmall,
  DeleteSmall,
  EditSmall,
  TaskAddSmall,
} from '@wix/wix-ui-icons-common';
import { TreeListItem } from '@wix/wix-base-ui';
import { symbol as Symbol } from '#packages/baseUI';

import SectionsItem from './sectionsItem';
import withSelectedComponent from './withSelectedComponent';
import styles from './sectionizerPanel.scss';

import type { SectionExtendedMetaData } from './sectionizerPanel.types';
import type { WithSelectedComponentsProps } from './withSelectedComponent';

interface SectionsTreeOwnProps {
  sections: SectionExtendedMetaData[];
  sectionsRemoveAllowed: boolean;
  highlighted: number[];
  createAddSectionHandler: (
    sectionIndex: number,
  ) => MouseEventHandler<HTMLButtonElement>;
  createMoveComponentHandler: (
    sectionIndex: number,
    prevSectionIndex: number,
    componentIndex: number,
  ) => MouseEventHandler<HTMLButtonElement>;
  createChangeSectionNameHandler: (
    sectionIndex: number,
  ) => MouseEventHandler<HTMLButtonElement>;
  createRemoveSectionHandler: (
    sectionIndex: number,
  ) => MouseEventHandler<HTMLButtonElement>;
}

interface SectionsTreeProps
  extends SectionsTreeOwnProps,
    WithSelectedComponentsProps {}

const getDefaultSectionItemLabel = () => 'Unnamed';

const SectionsTree: React.FC<SectionsTreeProps> = (props) => {
  const [translate] = useTranslation();
  const highlightedItems = new Set(props.highlighted);
  return (
    <div className={styles.tree}>
      {props.sections.map(({ name, children }, sectionIndex: number) => (
        <SectionsItem
          key={`${name}-${sectionIndex}`}
          label={name ? translate(name) : getDefaultSectionItemLabel()}
          highlighted={highlightedItems.has(sectionIndex)}
          suffix={
            <div>
              <button
                className={styles.contextButton}
                onClick={props.createChangeSectionNameHandler(sectionIndex)}
              >
                <EditSmall />
              </button>
              <button
                className={styles.contextButton}
                onClick={props.createAddSectionHandler(sectionIndex)}
              >
                <TaskAddSmall />
                <ArrowUpSmall />
              </button>
              <button
                className={styles.contextButton}
                onClick={props.createAddSectionHandler(sectionIndex + 1)}
              >
                <TaskAddSmall />
                <ArrowDownSmall />
              </button>
              {props.sectionsRemoveAllowed ? (
                <button
                  className={styles.contextButton}
                  onClick={props.createRemoveSectionHandler(sectionIndex)}
                >
                  <DeleteSmall />
                </button>
              ) : null}
            </div>
          }
        >
          <>
            {children.map(({ compId, displayName, icon }, componentIndex) => (
              <TreeListItem
                label={displayName}
                icon={<Symbol name={icon.svgName} />}
                key={compId}
                selected={compId === props.selectedCompId}
                shouldTranslate={false}
                onClick={() => props.scrollToCompAndSelect(compId)}
                suffix={
                  <div>
                    <button
                      className={styles.contextButton}
                      disabled={sectionIndex - 1 < 0}
                      onClick={props.createMoveComponentHandler(
                        sectionIndex,
                        sectionIndex - 1,
                        componentIndex,
                      )}
                    >
                      <ArrowUpSmall />
                    </button>
                    <button
                      className={styles.contextButton}
                      disabled={sectionIndex + 1 >= props.sections.length}
                      onClick={props.createMoveComponentHandler(
                        sectionIndex,
                        sectionIndex + 1,
                        componentIndex,
                      )}
                    >
                      <ArrowDownSmall />
                    </button>
                  </div>
                }
              />
            ))}
          </>
        </SectionsItem>
      ))}
    </div>
  );
};

export default withSelectedComponent(
  SectionsTree,
) as React.FC<SectionsTreeOwnProps>;

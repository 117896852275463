import experiment from 'experiment';
import * as tpa from '#packages/tpa';
import { EditorParamsApiKey } from '#packages/apis';
import * as stateManagement from '#packages/stateManagement';
import * as appManager from '#packages/appManager';
import wixStorePanel from './appMenu/wixStorePanel';
import * as wixStoreAddPanel from './addPanel/wixStoreAddPanel';
import * as wixStoreDataProvider from './common/wixStoreDataProvider';
import * as wixStoreEditorActionsService from './common/wixStoreEditorActionsService';
import { addAppToSite } from './common/wixStoreEditorActionsService';
import * as wixStoreDeepLinkService from './common/wixStoreDeepLinkService';
import intentWelcomePanel from './intentWelcomePanel/intentWelcomePanel';
import { WixStoreIntentService } from './common/wixStoreIntentService';
import type { EditorAPI } from '#packages/editorAPI';
import { appStudioUtils, tpaUtils } from '#packages/util';
import { PreferenceKey } from './constants';
import { WixStoreEntryPoint } from './wixStoreEntryPoint';
import type { WixStoreApi } from './wixStoreApi';
import shoppingBagWidget from './common/data/components/shoppingBagWidget';

function showIntentWelcomePanel(
  editorAPI: EditorAPI,
  dealerIntentProps: AnyFixMe,
) {
  editorAPI.store
    .dispatch(
      stateManagement.userPreferences.actions.setGlobalUserPreferences(
        dealerIntentProps.userPreferencesKey,
        true,
      ),
    )
    .catch(() => {});

  editorAPI.panelManager.openPanel('wixStore.panels.intentWelcomePanel', {
    ...dealerIntentProps.componentProps,
    origin: dealerIntentProps.componentProps.panelName,
  });
}

async function openSpecialWelcomeModalIfNeeded(
  editorAPI: EditorAPI,
  intentService: WixStoreIntentService,
) {
  await intentService.intentPromise;
  const modalConf = await intentService.getModalForIntent();
  if (modalConf) {
    const didDisplayedStoresModal =
      stateManagement.userPreferences.selectors.getGlobalUserPreferences(
        modalConf.userPreferencesKey,
      )(editorAPI.store.getState());

    if (!didDisplayedStoresModal) {
      editorAPI.store.dispatch(
        stateManagement.welcomeScreen.actions.setOverrideItem({
          panels: [
            {
              shouldShow: () => true,
              show: () => showIntentWelcomePanel(editorAPI, modalConf),
            },
          ],
        }),
      );
      return true;
    }
  }
  return false;
}

function onPreviewUnload(editorAPI: EditorAPI) {
  wixStoreAddPanel.unregister(editorAPI.addPanel);
}

function installStoresOnIntent(editorAPI: EditorAPI) {
  editorAPI.store.dispatch(
    stateManagement.userPreferences.actions.setSiteUserPreferences(
      PreferenceKey.DID_SILENT_INSTALL_STORES_WHEN_HAD_INTENT,
      true,
    ),
  );
  return addAppToSite(
    tpa.constants.BI.type.ADD_APP_PREINSTALLATION,
    true,
    tpaUtils.getStoresAppDefId(),
  );
}

async function registerApp(
  editorAPI: EditorAPI,
  storesWasSilentlyInstalled: boolean,
) {
  await appManager.init(editorAPI);

  wixStoreEditorActionsService.registerLeftBarPanel(
    storesWasSilentlyInstalled,
    editorAPI,
  );

  editorAPI.tpa.dangerouslySubscribeToAppInstall(
    tpaUtils.getStoresAppDefId(),
    (appDefId, options) => {
      wixStoreEditorActionsService.onAppInstalled(editorAPI, {
        origin: options.platformOrigin,
      });
    },
  );

  tpa.superApps.registerOnAppDeleted(
    tpaUtils.getStoresAppDefId(),
    wixStoreEditorActionsService.onAppDeleted,
  );
}

async function installStoresAndOpenSixthPanelIfNeeded(
  editorAPI: EditorAPI,
  intentService: WixStoreIntentService,
  openedModalPromise: Promise<any>,
) {
  const openedModal = await openedModalPromise;

  await intentService.intentPromise;
  const intents = stateManagement.introFunnel.selectors.getAppIntents(
    editorAPI.store.getState(),
  );
  const hasStoresIntent = intents.includes(
    stateManagement.introFunnel.IntroFunnelIntents.STORES,
  );
  const isFirstSave =
    editorAPI.dsRead.generalInfo.isFirstSave() ||
    editorAPI.dsRead.generalInfo.isDraft();

  if (!openedModal && hasStoresIntent && isFirstSave) {
    const storesAlreadySilentlyInstalled =
      stateManagement.userPreferences.selectors.getSiteUserPreferences<boolean>(
        PreferenceKey.DID_SILENT_INSTALL_STORES_WHEN_HAD_INTENT,
      )(editorAPI.store.getState());
    const editorParamsApi = editorAPI.host.getAPI(EditorParamsApiKey);

    const shouldInstallStores =
      !stateManagement.platform.selectors.shouldInstallPendingSilently(
        editorAPI,
      ) &&
      !editorParamsApi.siteCreationWizard &&
      !editorParamsApi.siteGenerationWizard &&
      !appStudioUtils.isAppStudio() &&
      !storesAlreadySilentlyInstalled &&
      experiment.isOpen('se_wixStores_installStoresOnUserIntent') &&
      !wixStoreDataProvider.isAppInstalled(editorAPI);

    if (shouldInstallStores) {
      await installStoresOnIntent(editorAPI);
      return true;
    }
  }
  return false;
}

async function onPreviewReady(editorAPI: EditorAPI) {
  wixStoreAddPanel.register(editorAPI);
  wixStoreEditorActionsService.init(editorAPI);

  const intentService = new WixStoreIntentService(editorAPI);

  editorAPI.registerPostDeepNavigation(wixStoreDeepLinkService.handle);

  const openedModalPromise = openSpecialWelcomeModalIfNeeded(
    editorAPI,
    intentService,
  );

  const installationPromise = installStoresAndOpenSixthPanelIfNeeded(
    editorAPI,
    intentService,
    openedModalPromise,
  );

  editorAPI.registerPrerequisiteForWelcomeScreen(
    Promise.all([openedModalPromise, installationPromise]),
  );

  const storesWasSilentlyInstalled = await installationPromise;
  registerApp(editorAPI, storesWasSilentlyInstalled);
}

const panels = {
  wixStorePanel,
  intentWelcomePanel,
};

export {
  addAppToSite as addStoreAppToSite,
  addWidgetToSite as addWixStoreWidgetToSite,
  setStyleParams,
  addSuperAppSectionPage as addStorePageToSite,
} from './common/wixStoreEditorActionsService';

export type { AddWidgetBIParams } from './common/wixStoreEditorActionsService';

export { mapDispatchToProps as mapDispatchToStoresWelcomeSectionProps } from './addPanel/wixStoreAddPanelViewMapper';
export type { WixStoreAddPanelDispatchProps } from './addPanel/types';

export {
  onPreviewReady,
  onPreviewUnload,
  panels,
  wixStoreDataProvider,
  WixStoreEntryPoint,
  type WixStoreApi,
  shoppingBagWidget,
};

// @ts-nocheck
import * as util from '#packages/util';
import type { AddElementsOptions } from '../editorInterfaces';

function getLinkAsString(editorAPI, appData, token, options) {
  return util.link.getLinkValueAsString(editorAPI, options.link);
}

function getCsrfToken(): string {
  return util.cookie.getCookie('XSRF-TOKEN');
}

const getElementsPanelPropsFromOptions = (options: AddElementsOptions) => ({
  helpId: options.helpId,
  widgetRef: options.widgetRef,
  elementsData: options.elementsData,
  categoriesData: options.categoriesData,
  sectionsData: options.sectionsData,
  withDivider: options.withDivider,
  subtitle: options.subtitle,
  type: 'add',
});

export { getLinkAsString, getCsrfToken, getElementsPanelPropsFromOptions };

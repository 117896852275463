import { superApps, constants as tpaConstants } from '#packages/tpa';
import {
  EditorType,
  InstallationOriginType,
  InstallInitiator,
} from '@wix/platform-editor-sdk';

interface AddSiteSearchParams {
  appDefinitionId: string;
}

export const addSiteSearch = ({ appDefinitionId }: AddSiteSearchParams) => {
  superApps.addApp(appDefinitionId, {
    biOrigin: tpaConstants.BI.type.ADD_APP_NEW_ADD_PANEL,
    platformOrigin: {
      type: EditorType.Classic,
      initiator: InstallInitiator.Editor,
      info: {
        type: InstallationOriginType.AddPanel,
      },
    },
  });
};

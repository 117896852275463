import checkReadinessPanel from './panels/checkReadinessPanel/checkReadinessPanel';
import siteReadinessPanel from './panels/siteReadinessPanel/siteReadinessPanel';

const panels = {
  checkReadinessPanel,
  siteReadinessPanel,
};

export { SiteReadinessEntryPoint } from './scope';

export { panels };

import experiment from 'experiment';
import { BaseEntryScope, createEntryPoint } from '#packages/apilib';
import {
  AddPresetApiKey,
  EditorAPIKey,
  EditorCoreApiKey,
  PanelManagerApiKey,
  TextEditorApiKey,
} from '#packages/apis';
import { COMPS_WITH_BG_SCROLL } from './api/constants';

import { AnimationsApi } from './publicApi';
import { AnimationsApiKey } from './publicApiKey';

import { createAnimationsApi as createAnimationsApiV1 } from './api/v1';
import { createAnimationsApi as createAnimationsApiV3 } from './api/v3';
import { createAnimationsHelpers } from './api/v3/helpers';
import { createGlobalAnimationsApi } from './api/v3/globalAnimationsApi';
import { createPreviewApi } from './api/v3/previewApi';
import { createAnimationPanelApi } from './animationPanel/panelApi';

export class AnimationsScope extends BaseEntryScope {
  editorAPI = this.useDependency(EditorAPIKey);
  editorCoreAPI = this.useDependency(EditorCoreApiKey);
  panelManager = this.useDependency(PanelManagerApiKey);
  addPresetApi = this.useDependency(AddPresetApiKey);
  textEditorApi = this.useDependency(TextEditorApiKey);

  animationsApiV1 = this.declareApi(createAnimationsApiV1);
  animationsApiV3 = this.declareApi(createAnimationsApiV3);
  helpers = this.declareApi(createAnimationsHelpers);
  globalAnimationsApi = this.declareApi(createGlobalAnimationsApi);
  animationPanelApi = this.declareApi(createAnimationPanelApi);

  previewApi = this.declareApi(createPreviewApi);
}

const subscribeOnComponentAddedToStage = ({
  editorAPI,
  globalAnimationsApi,
  textEditorApi,
}: AnimationsScope) => {
  editorAPI.pages.hooks.pageAddedToStage.tap(({ pageRef }) => {
    const pageSections = editorAPI.sections.getPageSections(pageRef);
    pageSections.forEach((sectionRef) =>
      globalAnimationsApi.applyGlobalAnimations(sectionRef),
    );
  });

  editorAPI.components.hooks.componentAddedToStage.tap(({ compRef }) => {
    globalAnimationsApi.applyGlobalAnimations(compRef);
  });

  editorAPI.components.hooks.componentDesignUpdated.tap(
    ({ compRef, designItem }) => {
      const compType = editorAPI.components.getType(compRef);
      if (
        designItem.type === 'MediaContainerDesignData' &&
        COMPS_WITH_BG_SCROLL.includes(compType)
      ) {
        globalAnimationsApi.applyGlobalAnimations(compRef);
      }
    },
  );

  textEditorApi.hooks.textComponentUpdated.tap(
    ({ compRef, originalData, updatedData }) => {
      const { applyGlobalAnimations, shouldUpdateTextComponent } =
        globalAnimationsApi;

      if (shouldUpdateTextComponent(originalData.text, updatedData.text)) {
        applyGlobalAnimations(compRef);
      }
    },
  );
};

export const AnimationsEntrypoint = createEntryPoint({
  name: 'Animations',
  Scope: AnimationsScope,
  publicApi({ contributeApi }) {
    contributeApi(AnimationsApiKey, AnimationsApi);
  },
  async initialize(scope: AnimationsScope) {
    await scope.editorCoreAPI.hooks.initReady.promise;
    if (experiment.isOpen('se_globalAnimations')) {
      subscribeOnComponentAddedToStage(scope);
    }
  },
});

import React from 'react';

import { hoc } from '#packages/util';

import { shouldShowParentContainerBox } from './parentContainerBox.selectors';
import { mapStateToProps } from './parentContainerBox.mapper';
import styles from './parentContainerBox.scss';
import { withRestrictions } from '#packages/editorRestrictions';
import _ from 'lodash';

export type ParentContainerBoxProps = ReturnType<typeof mapStateToProps>;

export const ParentContainerBoxComponent: React.FC<ParentContainerBoxProps> = ({
  containerStyle,
  componentLabel,
  navControlsStyle,
}) => {
  if (!containerStyle) return null;

  return (
    <div
      className={styles.container}
      style={containerStyle}
      data-aid="parent-container-box"
    >
      <span
        data-aid="parent-container-box-label"
        className={styles.label}
        style={{ marginLeft: navControlsStyle?.left || 0 }}
      >
        {componentLabel}
      </span>
      <div className={styles.frame} data-aid="parent-container-box-frame" />
    </div>
  );
};

const ConnectedParentContainerBox = hoc.connect(
  hoc.STORES.EDITOR_API,
  mapStateToProps,
)(ParentContainerBoxComponent);

export const ParentContainerBox = _.flow(
  withRestrictions('rEditor_parent-container-box'),
  hoc.withConditionalRender(
    hoc.STORES.EDITOR_API,
    shouldShowParentContainerBox,
  ),
)(ConnectedParentContainerBox);

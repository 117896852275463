// @ts-nocheck
import addPanelDataConsts from '#packages/addPanelDataConsts';
import * as wixStoreEditorActionsService from '../../wixStoreEditorActionsService';

export default {
  type: addPanelDataConsts.SECTIONS_TYPES.PRESET,
  id: 'wixStoresMultipleProductWidgets',
  hide: false,
  title: 'add_section_label_productwidget',
  subNavigationTitle: 'add_section_label_productwidget',
  presetTitle: 'add_section_label_productwidget',
  tooltipTitle: 'add_section_label_productwidget',
  sectionName: 'multipleProductWidgets',
  subNavigationHide: false,
  showSectionHeader: true,
  additionalBehaviours: {
    labelMode: addPanelDataConsts.LABEL_BEHAVIOUR.NONE,
    infoIcon: addPanelDataConsts.INFO_ICON_BEHAVIOUR.NONE,
    hoverImageAction: addPanelDataConsts.HOVER_IMAGE_ACTIONS.NONE,
    scaleProportion: addPanelDataConsts.DEFAULT_SCALE_FOR_PRESET,
    iconEnabledForComps: [],
  },
  props: {
    image: 'wixStore/sections/productWidget.v4.png',
    imageHover: null,
    onDrop: wixStoreEditorActionsService.onWidgetDrop(),
    onClick: wixStoreEditorActionsService.onWidgetClick(),
    items: [
      {
        id: 'Wix_Store_Product_Widget_Vertical',
        structure: {
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 480,
            height: 404,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            applicationId: '15',
            widgetId: '13ec3e79-e668-cc0c-2d48-e99d53a213dd',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'tpaw0',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
        },
        preset: { rect: { width: 127, height: 145, x: 0, y: 0 }, label: '' },
      },
      {
        id: 'Wix_Store_Product_Widget_Horizontal',
        structure: {
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          layout: {
            width: 940,
            height: 400,
            scale: 1,
            rotationInDegrees: 0,
            fixedPosition: false,
          },
          componentType: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          data: {
            type: 'TPAWidget',
            metaData: { isPreset: true, schemaVersion: '1.0', isHidden: false },
            applicationId: '15',
            widgetId: '13ec3e79-e668-cc0c-2d48-e99d53a213dd',
          },
          style: {
            type: 'TopLevelStyle',
            id: 'tpaw0',
            metaData: {
              isPreset: false,
              schemaVersion: '1.0',
              isHidden: false,
            },
            style: { groups: {}, properties: {}, propertiesSource: {} },
            componentClassName: '',
            pageId: '',
            compId: '',
            styleType: 'system',
            skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          },
        },
        preset: { rect: { width: 323, height: 145, x: 0, y: 165 }, label: '' },
      },
    ],
    compTypes: ['wysiwyg.viewer.components.tpapps.TPAWidget'],
  },
};

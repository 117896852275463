import { type AppHost, AppMainView } from '#packages/apilib';
import {
  EditorAPIKey,
  EditorCoreApiKey,
  EditorParamsApiKey,
} from '#packages/apis';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import * as util from '#packages/util';
import { i18n } from '#packages/i18n';
import { fedopsLogger, editorWixRecorder } from '#packages/util';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider as StoreProvider } from 'react-redux';
import * as baseUI from '#packages/baseUI';
import { WixBaseUiEnvironmentProvider } from '@wix/wix-base-ui';
import * as stateManagement from '#packages/stateManagement';
import experiment from 'experiment';
// Needed to fix cyclic dependency in Mobile App Builder,
// see #53249 for details
import '@/platform';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import { bootstrapNewAddPanel } from '@/addPanelInfra/bootstrap';
// eslint-disable-next-line @wix/santa-editor/scoped-imports
import bootstrapComponentModel from '@/componentModel/bootstrap';
import { createEditorAppHost } from './createEditorHost';
import { initDebugTools } from './debugTools';

import type { EditorPermissions } from '#packages/editorPermissions';
import type { EditorLoggers } from 'types/core';
import type { EditorParams } from '#packages/editorParams';
import type { EditorBaseHooks } from '#packages/editorCore';

async function initQATools(store: AnyFixMe, editorAPI: AnyFixMe) {
  if (!window.queryUtil.isParameterTrue('isqa')) {
    return;
  }
  const [qaAutomation, debugTools] = await Promise.all([
    import('./santa-dom-selectors'),
    import('@/debugTools'),
  ]);

  window.testApi = Object.assign(
    {},
    window.testApi,
    {
      domSelectors: qaAutomation.getDomSelectors(),
      helpers: debugTools.helpers,
      clientSelectors: debugTools.clientSelectors,
      publishState: {
        isPublishedInSession: false,
      },
      stateManagement,
    },
    debugTools.createAutomationApi(editorAPI),
    { editorAPI },
  );
  qaAutomation.init(window);
}

function createGlobalServices() {
  return {
    panelsWorkerService: {},
    tooltipManager: baseUI.tooltipManager,
    mediaManagerSdkFonts: {
      uploadQueue: [] as AnyFixMe,
    },
  };
}

async function build({
  editorParams,
  editorLoggers,
  editorPermissions,
  editorBaseHooks,
}: {
  editorParams: EditorParams;
  editorLoggers: EditorLoggers;
  editorPermissions: EditorPermissions;
  editorBaseHooks: EditorBaseHooks;
}) {
  util.imageTransform.init(); // Initiate imageClientAPI (imageTransform)

  util.polyfills.register();
  if (experiment.isOpen('qualaroo')) {
    util.qualaroo.start();
  }

  const host = createEditorAppHost({
    editorParams,
    editorPermissions,
    editorBaseHooks,
    globalServices: createGlobalServices(),
    debugMode: editorParams.isDebug && !editorParams.isQA,
  });

  const editorAPI = host.getAPI(EditorAPIKey);
  const throttledStore = editorAPI.store;

  throttledStore.dispatch(
    stateManagement.services.actions.loadService('editorAPI', editorAPI) as any,
  );

  throttledStore.dispatch(
    stateManagement.services.actions.loadService('host', host) as any,
  );

  fedopsLogger.create(editorLoggers.fedops);

  host.getAPI(EditorCoreApiKey).hooks.initReady.promise.then(() => {
    util.browserUtil.setTabTitle(editorAPI.siteDisplayName.get());
  });

  const doubleStore = _.defaults(
    {
      getState() {
        const state = throttledStore.getState();
        return {
          state,
          dsRead: editorAPI.dsRead || {},
          editorAPI,
          host,
        };
      },
    },
    throttledStore,
  );

  window.addEventListener('focus', () => {
    throttledStore.dispatch(stateManagement.window.actions.onFocus());
  });

  await initQATools(throttledStore, editorAPI);

  if (editorParams.isDebug) {
    initDebugTools(editorAPI);
  }

  bootstrapComponentModel();
  bootstrapNewAddPanel({ editorAPI, editorParams });

  host.getAPI(EditorCoreApiKey).hooks.initReady.promise.then(() => {
    editorWixRecorder.forceRecordIfNeeded(editorParams);
  });

  return { host, editorAPI, store: doubleStore, stateManagement };
}

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function render({
  host,
  store,
}: {
  host: AppHost;
  store: stateManagement.EditorStore;
}) {
  editorWixRecorder.start();
  const editorParamsApi = host.getAPI(EditorParamsApiKey);

  const root = window.document.getElementById('root');

  if (util.workspace.isNewWorkspaceEnabled()) {
    window.document.documentElement.classList.add('classic-facelift-skin');
  }

  fedopsLogger.appLoadingPhaseStart('editor-visible');
  fedopsLogger.appLoadingPhaseStart('preview-frame-mounted');
  window.rendered = ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <StoreProvider store={store}>
        <I18nextProvider i18n={i18n}>
          <DndProvider backend={HTML5Backend}>
            <WixBaseUiEnvironmentProvider
              madefor
              theme="facelift"
              inputBorders={experiment.isOpen(
                'se_components_editorClassic_inputBorders',
              )}
              {...editorParamsApi.baseUIOverrides}
            >
              <AppMainView host={host} />
            </WixBaseUiEnvironmentProvider>
          </DndProvider>
        </I18nextProvider>
      </StoreProvider>
    </QueryClientProvider>,
    root,
  );
}

const __unmountEditor = () => {
  //used for HMR
  const root = window.document.getElementById('root');
  ReactDOM.unmountComponentAtNode(root);
};

export { render, build, createEditorAppHost, __unmountEditor };

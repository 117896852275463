// @ts-nocheck
import PropTypes from 'prop-types';
import * as util from '#packages/util';
import * as textControls from '#packages/textControls';
import experiment from 'experiment';
import * as invisibleTextEditorMapper from './invisibleTextEditorMapper';
import React from 'react';
import WixRichText from '@wix/wix-richtext';

const {
  connect,
  renderWhenMutated,
  STORES: { EDITOR_API },
} = util.hoc;

//TYPE WAS GENERATED, remove this line when reviewed
interface InvisibleTextEditorProps {
  loadTextEditingAPI: FunctionFixMe;
  unloadTextEditingAPI: FunctionFixMe;
  loadLinksHelper: FunctionFixMe;
  unloadLinksHelper: FunctionFixMe;
}

class InvisibleTextEditor extends React.Component<InvisibleTextEditorProps> {
  static displayName = 'invisibleTextEditor';

  static propTypes = {
    loadTextEditingAPI: PropTypes.func.isRequired,
    unloadTextEditingAPI: PropTypes.func.isRequired,
    loadLinksHelper: PropTypes.func.isRequired,
    unloadLinksHelper: PropTypes.func.isRequired,
  };

  setReady = (manager) => {
    this.props.loadTextEditingAPI(manager);
  };

  componentWillUnmount() {
    this.props.unloadLinksHelper();
    this.props.unloadTextEditingAPI();
  }

  UNSAFE_componentWillMount() {
    const linksHelper = new textControls.LinksHelper([]);
    this.props.loadLinksHelper(linksHelper);
    this.linksManager = new textControls.TextLinksManager(linksHelper);

    this.stylesManager = {
      getFonts: this.props.getFonts,
    };
  }

  getStyleManager = () => {
    return this.stylesManager;
  };

  getLinksManager = () => {
    return this.linksManager;
  };

  getTextEditorStyles = () => {
    return this.props.layout;
  };

  isExperimentOpen(...args) {
    return experiment.isOpen(...args);
  }

  render() {
    return (
      <div
        style={{
          visibility: 'hidden',
        }}
      >
        <WixRichText.TextEditor
          initialData="<p></p>"
          onReady={this.setReady}
          validateHtml={false}
          showToolbar={false}
          stylesManager={this.getStyleManager()}
          linksManager={this.getLinksManager()}
          shouldInjectFontsToDocument={false}
          isExperimentOpen={this.isExperimentOpen}
        />
      </div>
    );
  }
}

const ConnectedComponent = connect(
  EDITOR_API,
  invisibleTextEditorMapper.mapStateToProps,
  invisibleTextEditorMapper.mapDispatchToProps,
)(renderWhenMutated(InvisibleTextEditor));

ConnectedComponent.pure = InvisibleTextEditor;

export default ConnectedComponent;

import * as util from '#packages/util';
import { translate } from '#packages/i18n';
import * as stateManagement from '#packages/stateManagement';
import * as newBlogBi from '../../../../newBlogBi/newBlogBi';
import * as blogUtils from '../../../../utils/blogUtils';
import React from 'react';
import * as baseUI from '#packages/baseUI';
import * as symbols from '@wix/santa-editor-symbols';
import type {
  MapDispatchToProps,
  MapStateToProps,
  ThunkAction,
} from 'types/redux';
import type { SendBiFunction } from 'types/bi';

interface MagicMigrationFailureSlideOwnProps {
  displayName: string;
  onClose: () => void;
  superApp: { id: string };
}

interface MagicMigrationFailureSlideStateProps {
  siteHasNewBlog: boolean;
}

interface MagicMigrationFailureSlideDispatchProps {
  sendBi: SendBiFunction;
  openHelpCenter: (article: string) => void;
  openParentPanel: () => void;
  markQuickMigrationAsSeen: () => void;
}

type MagicMigrationFailureSlideProps = MagicMigrationFailureSlideOwnProps &
  MagicMigrationFailureSlideDispatchProps &
  MagicMigrationFailureSlideStateProps;

class MagicMigrationFailureSlide extends React.Component<MagicMigrationFailureSlideProps> {
  static displayName = 'MagicMigrationFailureSlide';

  componentDidMount() {
    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_SHOWN, {
      modal_name: 'Migration failure panel',
      new_blog_valid: this.props.siteHasNewBlog,
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  }

  onClose = () => {
    this.props.onClose();
    this.props.sendBi(newBlogBi.events.BLOG_MAGIC_MIGRATION_MODAL_ACTION, {
      modal_name: 'Migration failure panel',
      action: 'close',
      origin: newBlogBi.ORIGINS.NEW_BLOG_6TH_BUTTON,
    });
  };

  handleHelpClick = () => {
    this.props.openHelpCenter(blogUtils.helpArticles.troubleshootMigration);
  };

  render() {
    return (
      <div className="blog-magic-migration-failure-slide">
        <div onClick={this.onClose} className="btn-close">
          <symbols.symbol name="headerCloseButton" />
        </div>

        <div className="illustration">
          <symbols.symbol name="illustrationSomethingWentWrong" />
        </div>

        <div className="content">
          <h1 className="title">
            {translate('SiteApp_NewBlog_Migration_Error_Panel_Title')}
          </h1>

          <p className="subtitle">
            {translate('SiteApp_NewBlog_Migration_Error_Panel_Subtitle')}
          </p>

          <baseUI.button
            label="SiteApp_NewBlog_Migration_Error_Panel_Link"
            onClick={() => this.handleHelpClick()}
            className="btn-confirm-primary"
          />

          <p>
            <a
              href="#"
              onClick={() =>
                window.open(
                  'https://www.wix.com/support/conversations/category/contact',
                )
              }
            >
              {translate('SiteApp_NewBlog_Migration_Error_Panel_CTA')}
            </a>
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<
  MagicMigrationFailureSlideStateProps,
  MagicMigrationFailureSlideOwnProps
> = ({ editorAPI }) => ({
  siteHasNewBlog: blogUtils.siteHasNewBlog(editorAPI),
});

const getEditorAPI: ThunkAction = (dispatch, getState, { editorAPI }) =>
  editorAPI;
const mapDispatchToProps: MapDispatchToProps<
  MagicMigrationFailureSlideDispatchProps,
  MagicMigrationFailureSlideOwnProps
> = (dispatch: FunctionFixMe, { superApp }) => {
  const editorAPI = dispatch(getEditorAPI);
  const isNewBlogPanel = superApp.id === blogUtils.newSuperAppId;
  const parentPanel = isNewBlogPanel
    ? blogUtils.newSuperAppPanelName
    : blogUtils.superAppPanelName;

  return {
    sendBi: (event, parameters) =>
      dispatch(stateManagement.bi.actions.event(event, parameters)),
    openHelpCenter: (article) =>
      dispatch(stateManagement.panels.actions.openHelpCenter(article)),
    openParentPanel: () => editorAPI.panelManager.openPanel(parentPanel),
    markQuickMigrationAsSeen: () =>
      blogUtils.markQuickMigrationAsSeen(editorAPI),
  };
};

const ConnectedComponent = util.hoc.connect(
  util.hoc.STORES.EDITOR_API,
  mapStateToProps,
  mapDispatchToProps,
)(MagicMigrationFailureSlide);
ConnectedComponent.pure = MagicMigrationFailureSlide;

export default ConnectedComponent;

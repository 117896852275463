import addPanelDataConsts from '#packages/addPanelDataConsts';
import type { EditorAPI } from '#packages/editorAPI';
import * as addPanel from '#packages/oldAddPanel';
import {
  onClickToContainer,
  onDropToContainer,
} from '../../contact/wixForms/utils';
import getThirdItemExtension from './thirdItem';

export default (
  editorAPI: EditorAPI,
  categoryId: string = addPanelDataConsts.CATEGORIES_ID.POPUP,
) => {
  const { popupSaleSection } = addPanel.sections.getSections().popup;
  const sectionTitle = popupSaleSection.title;
  const onDrop = onDropToContainer(categoryId, sectionTitle).bind(
    null,
    editorAPI,
  );
  const onClick = onClickToContainer(categoryId, sectionTitle).bind(
    null,
    editorAPI,
  );
  const itemsExtensions = [null, null, getThirdItemExtension(onDrop, onClick)];

  return addPanel.dataUtil.extendPropsItems(popupSaleSection, itemsExtensions);
};

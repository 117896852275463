import React from 'react';
import { hoc } from '#packages/util';
import * as stateManagment from '#packages/stateManagement';
import {
  Accessibility,
  type IconProps as AccessibilityProps,
} from '@wix/wix-ui-icons-common';

import type { MapStateToProps } from 'types/redux';

const { isPanelOpenedByName } = stateManagment.panels.selectors;

enum InidicatorColor {
  Blue = 'blue',
  Green = 'green',
}

interface A11yIndicatorStateProps {
  indicatorColor: InidicatorColor;
}

interface A11yIndicator extends A11yIndicatorStateProps, AccessibilityProps {}

const Component: React.FC<A11yIndicator> = ({ indicatorColor, className }) => {
  return (
    <Accessibility className={className} style={{ color: indicatorColor }} />
  );
};

const mapStateToProps: MapStateToProps<
  A11yIndicatorStateProps,
  AccessibilityProps
> = ({ state }) => {
  const isA11yPanelOpen = isPanelOpenedByName(
    state,
    'compPanels.dynamicPanels.a11ySettingsPanel',
  );
  return {
    indicatorColor: isA11yPanelOpen
      ? InidicatorColor.Blue
      : InidicatorColor.Green,
  };
};

export const A11yIndicator = hoc.connect(
  hoc.STORES.STATE_ONLY,
  mapStateToProps,
)(Component);

import { $layoutY, type LayoutY } from '#packages/layout';

const POSSIBLE_IS_BELOW_DELTA = 50;

/**
 * Get anchor target component or section, by heuristic rules described here:
 * https://github.com/wix-private/santa-editor/pull/46254
 *
 * ⚠️ for sections — if anchor does not overlap any section, then:
 * - it is above or below sections (on header or footer)
 * - it is between sections, so there are gaps, and it's bag (example: https://wix.slack.com/archives/C03P0PD9REV/p1660814397275159?thread_ts=1660812275.027999&cid=C03P0PD9REV)
 */
export function getAnchorTarget<TComponentWithLayout>(
  anchorLayoutRaw: LayoutY,
  components: TComponentWithLayout[],
  getLayoutOptions: LayoutY.GetLayoutOptions<TComponentWithLayout>,
): TComponentWithLayout {
  // anchor doesn't have height
  const anchorLayout: LayoutY = {
    y: anchorLayoutRaw.y,
    height: 0,
  };

  const { above, below, overlap } = $layoutY(anchorLayout).getAboveBelowOverlap(
    components,
    getLayoutOptions,
  );

  const overlapLayouts = overlap.map((item) =>
    getLayoutOptions.getLayout(item),
  );

  if (
    (overlap.length === 0 ||
      $layoutY(anchorLayout).isBelow(
        $layoutY(overlapLayouts).bottomY - POSSIBLE_IS_BELOW_DELTA,
      )) &&
    below.length > 0
  ) {
    return $layoutY(anchorLayout).getClosest(below, getLayoutOptions);
  }

  if (overlap.length === 0 && above.length > 0) {
    return $layoutY(anchorLayout).getClosest(above, getLayoutOptions);
  }

  if (overlap.length === 1) {
    return overlap[0];
  }

  return $layoutY(anchorLayout).getClosest(overlap, {
    ...getLayoutOptions,
    byMiddleY: true,
  });
}

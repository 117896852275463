import React, { useEffect, useState } from 'react';
import type { CmdState } from 'types/data';
import type { Link } from '../LinkPanel';
import * as textControls from '#packages/textControls';

interface Props {
  link?: Link;
  getCurrentTextManager?: Function;
  getCurrentLinksHelper?: Function;
  linkUpdate?: boolean;
}
const withDependedLinkUpdate =
  // eslint-disable-next-line react/display-name
  (Component: React.ElementType) => (props: Props) => {
    const { linkUpdate, ...restProps } = props;

    if (!linkUpdate) return <Component {...restProps} />;

    return withLinkUpdate(Component)(restProps);
  };

// eslint-disable-next-line react/display-name
const withLinkUpdate = (Component: React.ElementType) => (props: Props) => {
  const { link, getCurrentTextManager, getCurrentLinksHelper, ...restProps } =
    props;
  const getDoneBtnDisabled = (wixLink: string | number) => {
    return wixLink === textControls.constants.CK_DISABLED;
  };
  const getInitCmdState = () => {
    return getCurrentTextManager?.().getCommandsStates();
  };

  const [linkProp, setLinkProp] = useState(link);
  const [cmdState, setCmdState] = useState(getInitCmdState());

  useEffect(() => {
    const handleCmdState = (cmdState: CmdState) => {
      const link = getCurrentLinksHelper?.().getLink(cmdState.wixLink);

      setLinkProp(link);
      setCmdState(cmdState);
    };

    const textManager = getCurrentTextManager?.();
    textManager?.onCmdStateChange(handleCmdState);

    return () => {
      textManager?.unRegisterListener(handleCmdState);
    };
  }, [getCurrentLinksHelper, getCurrentTextManager]);

  const handleExpandSelectionToLink = () => {
    const textManager = getCurrentTextManager?.();

    if (textManager) {
      textControls.ckUtils.expandSelectionToLinks(textManager);
    }
  };

  const getDoneBtnTooltip = () => {
    const textManager = getCurrentTextManager?.();

    if (!textManager) return null;

    return getCurrentTextManager?.().hasSelection()
      ? 'text_editor_link_disabled_2'
      : 'text_editor_link_disabled';
  };

  return (
    <Component
      {...restProps}
      link={linkProp}
      expandSelection={handleExpandSelectionToLink}
      doneBtnDisabled={getDoneBtnDisabled(cmdState?.wixLink)}
      doneBtnTooltip={getDoneBtnTooltip()}
    />
  );
};

export default withDependedLinkUpdate;

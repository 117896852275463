import { openPanelChangeColorTheme } from './helpers';

import type { EditorAPI } from '#packages/editorAPI';
import { type ToolExecutionSpec } from '@wix/chat-service-sdk';
import type { Dispatch } from 'types/redux';
import type {
  AssistantPayloads,
  OpenEditorPanelsToolParams,
} from '../aiAssistantTypes';

const openEditorPanels = (
  editorAPI: EditorAPI,
  dispatch: Dispatch,
  payloads: AssistantPayloads,
): ToolExecutionSpec => ({
  execute: async (params: any) => {
    const { panelId } = params as OpenEditorPanelsToolParams;

    openPanelChangeColorTheme(editorAPI, dispatch, payloads.origin);

    return {
      success: true,
      content: `panel ${panelId} is now opened in editor UI and user can see it`,
    };
  },
});

export { openEditorPanels };

import type { EntryPoint } from '#packages/apilib';
import { createAppsPanelSectionsApi } from './appsPanelSectionsApi';
import { AppsPanelSectionsApiKey } from './publicApiKey';

export const AppsPanelSectionApiEntryPoint: EntryPoint = {
  name: 'AppPanelSectionsApi',
  declareAPIs: () => [AppsPanelSectionsApiKey],
  attach(shell) {
    shell.contributeAPI(AppsPanelSectionsApiKey, () =>
      createAppsPanelSectionsApi(shell),
    );
  },
};

export { AppsPanelSectionsApiKey };

import * as stateManagement from '#packages/stateManagement';
import * as util from '#packages/util';

const { getPremiumUrl, getPremiumUrlForSiteUpgrade } = util.premiumUtils;

const PP_ORIGIN = 'GFPP';

function getPremiumGfpp(appDefinitionId: AnyFixMe, upgradeType: AnyFixMe) {
  return {
    onClick(editorAPI: AnyFixMe) {
      const isSaved = !editorAPI.dsRead.generalInfo.isFirstSave();
      const isDraftMode = editorAPI.dsRead.generalInfo.isDraft();
      if (isSaved && !isDraftMode) {
        window.open(
          upgradeType === 'SITE_UPGRADE'
            ? getPremiumUrlForSiteUpgrade(editorAPI, {
                appDefinitionId,
                origin: PP_ORIGIN,
              })
            : getPremiumUrl(editorAPI, { appDefinitionId, origin: PP_ORIGIN }),
          '_blank',
        );

        const appData =
          editorAPI.dsRead.tpa.app.getDataByAppDefId(appDefinitionId);
        editorAPI.store.dispatch(
          stateManagement.tpa.actions.addAppToTrackForUpgrade(
            appData.applicationId,
          ),
        );
      } else {
        import('@/tpa').then((tpa) =>
          tpa.packagePicker.startUpgradeFlow(
            editorAPI,
            appDefinitionId,
            false,
            { shouldNavigate: false },
          ),
        );
      }
    },
    icon: () =>
      util.ascend.isAscendUpgrade(appDefinitionId)
        ? 'ascend_upgrade_gfpp_icon'
        : 'upgrade',
    tooltip: () =>
      util.ascend.isAscendUpgrade(appDefinitionId)
        ? 'gfpp_tooltip_ascend_upgrade'
        : 'gfpp_tooltip_upgrade',
  };
}

export { getPremiumGfpp };

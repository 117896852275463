import {
  createAppHost,
  ConsoleHostLogger,
  type HostLogger,
} from '#packages/apilib';
import experiment from 'experiment';
import { createEditorApiEntryPoint } from '../app/EditorApiEntryPoint';
import { EditorMainViewEntryPoint } from './EditorMainViewEntryPoint';
import { LayoutEntrypoint } from '#packages/layout';
import {
  MeasureComponentDragAndDropEntryPoint,
  MeasureFrameRateEntrypoint,
} from '#packages/measuring';
import { BlocksEntryPoint } from '#packages/blocks';
import { ComponentsEntrypoint } from '#packages/components';
import { ComponentsStageModesEntrypoint } from '#packages/componentsStageModes';
import { AscendEntrypoint } from '#packages/ascend';
import { PromoteEntryPoint } from '#packages/promote';
import { ClipboardEntrypoint } from '#packages/clipboard';
import { PagesEntrypoint } from '#packages/pages';
import { HistoryEntrypoint } from '#packages/history';
import { MenuEntrypoint, AdvancedMenuEntryPoint } from '#packages/menu';
import { DeveloperModeEntrypoint } from '#packages/developerMode';
import { MouseActionsEntrypoint } from '../app/APISections/mouseActionsEntry';
import { ConcurrentUsersEntrypoint } from '#packages/concurrentUsers';
import { SelectedComponentEntrypoint } from '../app/APISections/selectedComponentEntry';
import { EditorUIEntrypoint } from '#packages/editorUI';
import { AccountEntrypoint } from '#packages/account';
import { AddPresetApiEntryPoint } from '#packages/addPreset';
import { ComponentFocusModeApiEntryPoint } from '#packages/componentFocusMode';
import { SwitchLayoutEntryPoint } from '#packages/switchLayout';
import { PreviewerEntryPoint } from '#packages/previewer';
import { WizardEntryPoint } from '#packages/wizard';
import { QuickEditEntryPoint } from '#packages/quickEdit';
import { QuickEditEngineEntryPoint } from '#packages/quickEditEngine';
import { TextClassificationApiEntryPoint } from '#packages/textClassification';
import { ListClassificationApiEntryPoint } from '#packages/listClassification';
import { WorkspaceRightPanelEntryPoint } from '#packages/workspaceRightPanel';
import { ThemeEntrypoint } from '#packages/theme';
import { AiEntryPoint } from '#packages/ai';
import { AiWriterPanelEntryPoint } from '#packages/aiWriterPanel';
import { AIAssistantPanelEntryPoint } from '#packages/aiAssistantPanel';
import { BiEntrypoint } from '#packages/bi';
import { SiteEntrypoint } from '#packages/site';
import { PlatformEntrypoint, AddonsEntrypoint } from '#packages/platform';
import { FeedbackEntrypoint } from '#packages/feedback';
import { CampaignsApiEntryPoint } from '#packages/campaigns';
import { SavePublishEntryPoint } from '#packages/savePublish';
import { PanelManagerApiEntryPoint } from '#packages/panelManager';
import { PanelHelpersApiEntryPoint } from '#packages/panelHelpers';
import { MediaServicesEntrypoint } from '#packages/mediaServices';
import { GTMServiceEntrypoint } from '#packages/gtmService';
import { ColumnsEntrypoint } from '#packages/columns';
import { CopyPasteEntrypoint } from '#packages/copyPaste';
import { PreviewEntrypoint } from '#packages/preview';
import { SelectionEntrypoint } from '#packages/selection';
import { PinModeEntrypoint } from '#packages/pinMode';
import { MobileEditorEntrypoint } from '#packages/mobileEditor';
import { MaskCropEntrypoint } from '#packages/maskCrop';
import { ScrollApiEntryPoint } from '../app/APISections/scroll';
import { TextEditorApiEntryPoint } from '#packages/textEditor';
import { HomePageApiEntryPoint } from '../app/APISections/homePage';
import { MainMenuApiEntryPoint } from '#packages/mainMenu';
import { BaseDragEntrypoint } from '../utils/mouseMoveActions/baseDrag/baseDragEntry';
import { BaseResizeAndPushEntrypoint } from '../utils/mouseMoveActions/baseResizeAndPushEntry';
import { PromotionsEntrypoint } from '#packages/promotions';
import { SavedComponentsEntrypoint } from '#packages/savedComponents';
import { MediaEntrypoint } from '../app/APISections/mediaEntry';
import { A11yApiEntryPoint } from '#packages/accessibility';
import {
  WixCodeWorkspaceEntrypoint,
  WixCodePackageLoaderEntryPoint,
} from '#packages/wixCodePackageLoader';
import { SchoolModeApiEntryPoint } from '#packages/schoolMode';
import { TpaEntrypoint } from '#packages/tpa';
import { LayoutersEntrypoint } from '../app/APISections/layoutersEntry';
import { FloatingBubbleEntrypoint } from '#packages/floatingBubble';
import { TabIndicationEntrypoint } from '../app/APISections/tabIndicationStateEntry';
import { PluginServiceEntrypoint } from '../app/pluginServiceEntry';
import { EditorEventRegistryEntrypoint } from '#packages/editorEventRegistry';
import { SectionizerEntryPoint } from '#packages/sectionizer';
import {
  SectionsApiEntryPoint,
  ClassicSectionResizeAndPushEntryPoint,
} from '#packages/sections';
import { SectionsMigrationEntryPoint } from '#packages/sectionsMigration';
import { WixCodeEntrypoint } from '../app/APISections/wixCode';
import { EditorToursEntryPoint } from '#packages/editorTours';
import { createEditorCoreEntrypoint } from '#packages/editorCore';
import { EditorWelcomeScreensEntrypoint } from '#packages/editorWelcomeScreens';
import { LanguagePickerEntrypoint } from '#packages/languagePicker';
import {
  type EditorParams,
  createEditorParamsEntryPoint,
} from '#packages/editorParams';
import { createEditorPermissionsEntryPoint } from '#packages/editorPermissions';
import { EditorRestrictionsEntryPoint } from '#packages/editorRestrictions';
import { WorkspaceEntrypoint } from '#packages/workspace';
import { EditorAPIKey } from '#packages/apis';
import { JiraIssueCollectorEntryPoint } from '#packages/jiraIssueCollector';
import { getApisObjectForEditorAPI } from '../app/setApisToEditorAPI';
import * as platformHostIngration from '@wix/editor-platform-host-integration/repluggable';
import { DocumentServicesEntry } from '#packages/documentServices';
import { EditorPaasApiEntryPoint } from '#packages/editorPaas';
import { DebugLayoutEntryPoint } from '#packages/debugLayout';
import { ZoomModeEntryPoint } from '#packages/zoomMode';
import { CustomAuthenticationEntryPoint } from '#packages/customAuthentication';
import { SpotlightStageApiEntryPoint } from '#packages/spotlightStage';
import { ComponentPanelPreloaderEntrypoint } from '#packages/componentPanelPreloader';
import { SeoAutoRedirectEntryPoint } from '#packages/seoAutoRedirect';
import { WixDataEntryPoint } from '#packages/wixData';
import { AppsPanelSectionApiEntryPoint } from '#packages/appsPanelSections';
import { PrivateAppsEntryPoint } from '#packages/privateAppsPanel';
import { ContentInjectionEntryPoint } from '#packages/contentInjection';
import { DataFixersEntryPoint } from '#packages/dataFixers';
import { SiteGlobalDataEntryPoint } from '#packages/siteGlobalData';
import { TopBarEntryPoint } from '#packages/topBar';
import { WorkspaceModesEntryPoint } from '#packages/workspaceModes';
import { WixStoreEntryPoint } from '#packages/wixStore';
import { PasteClipboardDataToStageEntryPoint } from '#packages/pasteClipboardDataToStage';
import { ImageUploadToStageEntryPoint } from '#packages/imageUploadToStage';
import { TextUploadToStageEntryPoint } from '#packages/clipboardTextUploadToStage';
import { ExperimentsEntrypoint } from '#packages/experiments';
import { SiteWidthMigrationEntryPoint } from '#packages/siteWidthMigration';
import { FixedStageEntryPoint } from '#packages/fixedStage';
import { ImageToBackgroundEntryPoint } from '#packages/imageToBackground';
import { SiteSegmentMediaBackgroundEntryPoint } from '#packages/siteSegmentMediaBackground';

import type { APILayer, EntryPointOrPackage } from '#packages/apilib';
import type { EditorPermissions } from '#packages/editorPermissions';
import type { EditorBaseHooks } from '#packages/editorCore';
import { DataBindingEntrypoint } from '#packages/dataBinding';
import { AnimationsEntrypoint } from '#packages/animations';
import { HighlightResizeEntryPoint } from '#packages/highlightResize';
import { TabsEntryPoint } from '#packages/tabs';
import { SiteReadinessEntryPoint } from '#packages/siteReadiness';
import { ReadonlyModeEntryPoint } from '#packages/readonlyMode';

const initDesignerPanelsApi = () =>
  import('@/designerPanels').then((m) => m.DesignerPanelsApiEntryPoint);

const layers: APILayer[] = [
  {
    level: 0,
    name: 'REPLUGGABLE',
  },
  {
    level: 10,
    name: 'SITE',
  },
  {
    level: 20,
    name: 'EDITOR_INFRA',
  },
  {
    level: 30,
    name: 'DATA_SERVICE',
  },

  {
    level: 40,
    name: 'DERIVATIVE_STATE',
  },
  {
    level: 50,
    name: 'FLOWS',
  },
  {
    level: 60,
    name: 'UI',
  },
  {
    level: 70,
    name: 'VERTICAL',
  },
  {
    level: 80,
    name: 'EXTERNAL',
  },
];

export function createEditorAppHost({
  editorParams,
  globalServices,
  debugMode,
  editorPermissions,
  editorBaseHooks,
}: {
  editorParams: EditorParams;
  globalServices: unknown;
  debugMode?: boolean;
  editorPermissions: EditorPermissions;
  editorBaseHooks?: EditorBaseHooks;
}) {
  const urlParams = new URLSearchParams(window.location.search);

  const enablePerformance = urlParams.has('enablePerformance');
  const disableMemoization = urlParams.has('disableMemoization');
  const debugMemoization = urlParams.has('debugMemoization');
  const disableLayersValidation = urlParams.has('disableLayersValidation');
  const disableCustomHostLogger = urlParams.has('disableCustomHostLogger');

  const errorBoundaryParam =
    urlParams.get('stickyErrorBoundary') || `${debugMode}`;
  const enableStickyErrorBoundaries =
    errorBoundaryParam.toLowerCase() !== 'false';

  const EditorPermissionsEntryPoint =
    createEditorPermissionsEntryPoint(editorPermissions);
  const EditorParamsEntryPoint = createEditorParamsEntryPoint(editorParams);
  const EditorApiEntryPoint = createEditorApiEntryPoint({
    editorParams,
    globalServices,
  });

  const externalRepluggableDescriptors = [
    platformHostIngration.bundleDescriptor,
  ];

  const entryList: EntryPointOrPackage[] = [
    BlocksEntryPoint,
    DocumentServicesEntry,
    EditorPaasApiEntryPoint,
    LanguagePickerEntrypoint,
    EditorPermissionsEntryPoint,
    WorkspaceEntrypoint,
    EditorRestrictionsEntryPoint,
    EditorParamsEntryPoint,
    EditorApiEntryPoint,
    TopBarEntryPoint,
    EditorMainViewEntryPoint,
    LayoutEntrypoint,
    ComponentsEntrypoint,
    ComponentsStageModesEntrypoint,
    AscendEntrypoint,
    MeasureComponentDragAndDropEntryPoint,
    ClipboardEntrypoint,
    PagesEntrypoint,
    MenuEntrypoint,
    AdvancedMenuEntryPoint,
    MaskCropEntrypoint,
    CustomAuthenticationEntryPoint,
    HistoryEntrypoint,
    MobileEditorEntrypoint,
    PinModeEntrypoint,
    ComponentPanelPreloaderEntrypoint,
    SelectionEntrypoint,
    PreviewEntrypoint,
    CopyPasteEntrypoint,
    ColumnsEntrypoint,
    SavePublishEntryPoint,
    ThemeEntrypoint,
    AccountEntrypoint,
    AddPresetApiEntryPoint,
    ComponentFocusModeApiEntryPoint,
    SwitchLayoutEntryPoint,
    PreviewerEntryPoint,
    QuickEditEntryPoint,
    QuickEditEngineEntryPoint,
    PromoteEntryPoint,
    PasteClipboardDataToStageEntryPoint,
    TextUploadToStageEntryPoint,
    ImageUploadToStageEntryPoint,
    WorkspaceModesEntryPoint,
    TextClassificationApiEntryPoint,
    ListClassificationApiEntryPoint,
    WizardEntryPoint,
    WorkspaceRightPanelEntryPoint,
    SelectedComponentEntrypoint,
    SiteEntrypoint,
    ConcurrentUsersEntrypoint,
    MouseActionsEntrypoint,
    DeveloperModeEntrypoint,
    createEditorCoreEntrypoint({
      editorBaseHooks,
    }),
    EditorWelcomeScreensEntrypoint,
    AiEntryPoint,
    AiWriterPanelEntryPoint,
    AIAssistantPanelEntryPoint,
    BiEntrypoint,
    TextEditorApiEntryPoint,
    HomePageApiEntryPoint,
    MainMenuApiEntryPoint,
    ScrollApiEntryPoint,
    SpotlightStageApiEntryPoint,
    PanelManagerApiEntryPoint,
    CampaignsApiEntryPoint,
    PanelHelpersApiEntryPoint,
    BaseDragEntrypoint,
    BaseResizeAndPushEntrypoint,
    EditorUIEntrypoint,
    SchoolModeApiEntryPoint,
    FeedbackEntrypoint,
    MediaServicesEntrypoint,
    GTMServiceEntrypoint,
    PlatformEntrypoint,
    AddonsEntrypoint,
    PromotionsEntrypoint,
    SavedComponentsEntrypoint,
    MediaEntrypoint,
    TpaEntrypoint,
    LayoutersEntrypoint,
    FloatingBubbleEntrypoint,
    TabIndicationEntrypoint,
    PluginServiceEntrypoint,
    EditorEventRegistryEntrypoint,
    WixCodeEntrypoint,
    WixCodeWorkspaceEntrypoint,
    WixCodePackageLoaderEntryPoint,
    EditorToursEntryPoint,
    SectionsApiEntryPoint,
    SectionizerEntryPoint,
    ClassicSectionResizeAndPushEntryPoint,
    SectionsMigrationEntryPoint,
    A11yApiEntryPoint,
    JiraIssueCollectorEntryPoint,
    ZoomModeEntryPoint,
    DebugLayoutEntryPoint,
    SeoAutoRedirectEntryPoint,
    WixDataEntryPoint,
    AppsPanelSectionApiEntryPoint,
    PrivateAppsEntryPoint,
    ContentInjectionEntryPoint,
    DataFixersEntryPoint,
    SiteGlobalDataEntryPoint,
    ExperimentsEntrypoint,
    WixStoreEntryPoint,
    SiteWidthMigrationEntryPoint,
    FixedStageEntryPoint,
    DataBindingEntrypoint,
    ...externalRepluggableDescriptors.flatMap((descriptor) =>
      Object.values(descriptor.packages),
    ),
    ImageToBackgroundEntryPoint,
    AnimationsEntrypoint,
    HighlightResizeEntryPoint,
    TabsEntryPoint,
    SiteSegmentMediaBackgroundEntryPoint,
    SiteReadinessEntryPoint,
    ReadonlyModeEntryPoint,
  ];

  if (editorParams.shouldMeasureFrameRate) {
    entryList.push(MeasureFrameRateEntrypoint);
  }

  const CustomHostLogger: HostLogger = {
    ...ConsoleHostLogger,
    log: (severity, ...args) => {
      if (severity === 'debug') {
        return;
      }
      return ConsoleHostLogger.log(severity, ...args);
    },
  };

  const logger: HostLogger =
    debugMode && disableCustomHostLogger ? undefined : CustomHostLogger;

  //saving original repluggableAppDebug because editor X repluggable is writing it to window
  //@ts-expect-error
  const { repluggableAppDebug } = window;

  const host = createAppHost(
    entryList.map((entry) => {
      if (typeof entry === 'function') {
        return (entry as any)(); //because createEntrypoint in apilib returns factory
      }
      return entry;
    }),
    {
      logger,
      layers,
      monitoring: {
        // NOTE: this is required, because for monitoring, repluggable clones the initial object, so `host.getAPI(EditorAPI)` !== createEditorApi()
        // on preview ready, DS is merged to the editorApi, so same reference is critical
        // same for all apis
        disableMonitoring: true,
        enablePerformance,
        disableMemoization,
        debugMemoization,
      },
      disableLayersValidation,
      enableStickyErrorBoundaries,
      disableCheckCircularDependencies: !debugMode,
      enableReduxDevtoolsExtension: debugMode,
    },
  );

  //saving our repluggableAppDebug, which was written in createAppHost
  //@ts-expect-error
  const seRepluggableAppDebug = window.repluggableAppDebug;
  //@ts-expect-error
  window.repluggableAppDebug = repluggableAppDebug; //restoring original debug object for responsive (used in e2e tests)
  //saving our repluggableAppDebug in different variable, so we can use it to debug repluggable
  //@ts-expect-error
  window.seRepluggableAppDebug = seRepluggableAppDebug;

  const editorAPI = host.getAPI(EditorAPIKey) as any;
  editorAPI.host = host;

  Object.assign(editorAPI, getApisObjectForEditorAPI(host));

  if (
    experiment.isOpen('se_addDesignerPage') ||
    experiment.isOpen('se_addDesignerSection')
  ) {
    void initDesignerPanelsApi().then((pkg) => {
      host.addShells([pkg()]);
    });
  }

  if (editorParams.isDebug) {
    // NOTE: base initialization of Editor API is finished, so disable `debugNotExistedPropProxy` (see EditorAPI entrypoint for the details)
    editorAPI.__debugNotExistedPropProxyEnabled = false;
  }
  return host;
}

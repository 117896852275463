import React from 'react';
import { Composites, RichText } from '@wix/wix-base-ui';
import { translate } from '#packages/i18n';
import CONSTANTS from '../../../../utils/constants';
import { PERMISSIONS_TAB_HOOKS } from '../../../../utils/dataHooks';
import styles from './permissionDescription.scss';

import type { PermissionState } from '../../../../utils/constants';

interface PermissionDescriptionProps {
  focusedProtectionType: PermissionState;
  openHelpCenter: (helpId: string) => void;
}

const { PERMISSION_STATES } = CONSTANTS;

const PERMISSION_HELP_ID: Record<PermissionState, string> = {
  [PERMISSION_STATES.NONE]: 'e34561a8-1323-4cbb-ad09-ca215e083050',
  [PERMISSION_STATES.PASSWORD]: '89081a7e-ebec-4553-af0f-0ad6aa21b0c5',
  [PERMISSION_STATES.MEMBERS]: 'e3004c6c-234e-4819-9333-9178d26358f3',
};

const PERMISSION_DESCRIPTIONS: Record<PermissionState, string> = {
  [PERMISSION_STATES.NONE]: 'NewPages_Panel_Permissions_Public_Text',
  [PERMISSION_STATES.PASSWORD]: 'NewPages_Panel_Permissions_Password_Title',
  [PERMISSION_STATES.MEMBERS]: 'NewPages_Panel_Permissions_Members_Info_Text',
};

export const PermissionDescription: React.FC<PermissionDescriptionProps> = ({
  focusedProtectionType,
  openHelpCenter,
}) => {
  return (
    <Composites.RichText className={styles.permissionDescription}>
      <RichText>
        <p>
          {`${translate(PERMISSION_DESCRIPTIONS[focusedProtectionType])} `}
          <a
            onClick={() =>
              openHelpCenter(PERMISSION_HELP_ID[focusedProtectionType])
            }
            data-hook={PERMISSIONS_TAB_HOOKS.LEARN_MORE_LINK}
          >
            {translate('NewPages_Panel_Permissions_Password_Learn_More')}
          </a>
        </p>
      </RichText>
    </Composites.RichText>
  );
};

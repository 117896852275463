import React from 'react';
import * as BaseUI from '#packages/baseUI';
import {
  Composites,
  Divider,
  Slider,
  TextLabel,
  Thumbnails,
} from '@wix/wix-base-ui';
import * as symbols from '@wix/santa-editor-symbols';

import { EFFECT_LIMITS, EffectTypes } from '../effects';
import type { IEffectsPanelProps } from '../effectsPanel';

import {
  ALIGNMENT_MODES_XY,
  getOriginFromValue,
  getSelectedValueFromOrigin,
} from './customizeUtils';
import * as coreBi from '#packages/coreBi';

type IProps = Pick<
  IEffectsPanelProps,
  'rotate' | 'rotateValue' | 'origin' | 'sendBI' | 'variantId'
>;
interface IState {
  direction: ROTATE_DIRECTION;
  rotateValue: number;
}

const enum ROTATE_DIRECTION {
  CLOCKWISE = 'clockwise',
  COUNTER_CLOCKWISE = 'counterclockwise',
}

const ROTATE_DIRECTIONS = [
  {
    value: ROTATE_DIRECTION.CLOCKWISE,
    illustration: React.createElement(symbols.symbol, {
      name: 'interactionsEffectsRotateRight',
    }),
  },
  {
    value: ROTATE_DIRECTION.COUNTER_CLOCKWISE,
    illustration: React.createElement(symbols.symbol, {
      name: 'interactionsEffectsRotateLeft',
    }),
  },
];

export class CustomizeRotatePanel extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      direction:
        props.rotateValue >= 0
          ? ROTATE_DIRECTION.CLOCKWISE
          : ROTATE_DIRECTION.COUNTER_CLOCKWISE,
      rotateValue: props.rotateValue,
    };
  }

  handleAlignmentChange = (value: string) => {
    const rotate = this.state.rotateValue;
    const origin = getOriginFromValue(value);
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.ROTATE,
        parameter_name: 'effect_origin',
        old_value: getSelectedValueFromOrigin(this.props.origin),
        new_value: value,
      },
    );
    this.props.rotate(rotate, origin);
  };

  handleRotateChange = (value: number) => {
    value =
      this.state.direction === ROTATE_DIRECTION.CLOCKWISE ? value : value * -1;
    const { origin } = this.props;
    this.props.sendBI(
      coreBi.events.interactions.effects_panel_change_settings,
      {
        interaction_id: this.props.variantId,
        behavior_name: EffectTypes.ROTATE,
        parameter_name: 'rotation_value',
        old_value: this.state.rotateValue,
        new_value: value,
      },
    );
    this.props.rotate(value, origin);
    this.setState({ rotateValue: value });
  };

  handleDirectionChange = (direction: ROTATE_DIRECTION) => {
    const rotateValue = Math.abs(this.state.rotateValue);
    this.setState({ direction }, () => {
      this.handleRotateChange(rotateValue);
    });
  };

  render() {
    return (
      <div className="customize-effect-panel" data-aid="customize-effect-panel">
        <Divider long={true} />
        <BaseUI.sectionDividerLabeled label="interactions_effects_rotate_section_title" />
        <Divider long={true} />

        <Composites.Thumbnails>
          <TextLabel value="interactions_effects_rotate_direction_label" />
          <Thumbnails
            value={this.state.direction}
            onChange={this.handleDirectionChange}
            maxThumbsPerRow={3}
            fixedRatio={true}
            options={ROTATE_DIRECTIONS}
          />
        </Composites.Thumbnails>

        <Divider long={false} />

        <Composites.SliderLabeled>
          <TextLabel value="interactions_effects_rotate_rotate_by_label" />
          <Slider
            value={Math.abs(this.state.rotateValue)}
            onChange={this.handleRotateChange}
            unit={'angle'}
            min={EFFECT_LIMITS.ROTATE.MIN}
            max={EFFECT_LIMITS.ROTATE.MAX}
            inputMax={EFFECT_LIMITS.ROTATE.INPUT_MAX}
            step={1}
          />
        </Composites.SliderLabeled>

        <Divider long={false} />

        <BaseUI.alignment
          options={ALIGNMENT_MODES_XY}
          label="interactions_effects_rotate_rotate_from_label"
          value={getSelectedValueFromOrigin(this.props.origin)}
          onChange={this.handleAlignmentChange}
          infoIconProps={{
            text: 'interactions_effects_rotate_rotate_from_tooltip',
          }}
        />
      </div>
    );
  }
}

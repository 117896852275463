import { HttpClient } from '@wix/http-client';
import { suggestPhotos } from '@wix/ambassador-genie-v1-image-recommendation/http';
import type { CompRef, CompData } from '@wix/document-services-types';
import { ImageTypes, SuggestPhotosRequestSource } from '../constants';
import type { EditorAPI } from '#packages/editorAPI';
import constants from '#packages/constants';

const IMAGE_TYPE = 'Image';

interface ImageData {
  ref: CompRef;
  data?: CompData;
  parentData?: CompData;
  newUrl?: string;
  origin?: string;
}

interface ImageRecommendation {
  id?: string;
  source?: SuggestPhotosRequestSource;
  url?: string | null;
  width?: number;
  height?: number;
  score?: number;
}

const getSuggestedPhotosInSection = async (
  httpClient: HttpClient,
  query: string,
  limit: number,
): Promise<AnyFixMe> => {
  let suggestedPhotos: any = [];
  const requestCount = Math.ceil(limit / 5);
  let requestLimit = limit;

  for (let i = 0; i < requestCount; i++) {
    const newImages = await httpClient.request(
      suggestPhotos({
        query,
        limit: requestLimit > 5 ? 5 : requestLimit,
        source: SuggestPhotosRequestSource.PUBLIC_MEDIA,
      }),
    );
    suggestedPhotos = [...suggestedPhotos, newImages];
    requestLimit -= 5;
  }
  return await suggestedPhotos.flatMap(
    (response: AnyFixMe) => response.data.images,
  );
};

const isRelevantImageSize = (
  imageRef: CompRef,
  editorAPI: EditorAPI,
): boolean => {
  const { width, height } = editorAPI.components.layout.get_size(imageRef);
  return width >= 200 && height >= 100;
};

const populateImagesWithParentData = (
  editorAPI: EditorAPI,
  images: AnyFixMe[],
): AnyFixMe[] => {
  return images.map((image) => {
    const parentSection = editorAPI.sections.getClosestSection(image.ref);
    const page = editorAPI.pages.getPageTitle(
      editorAPI.pages.getCurrentPageId(),
    );
    const parentName = parentSection
      ? `${editorAPI.sections.getName(parentSection)} section`
      : `${page} page`;

    return {
      ...image,
      parentData: {
        parentName,
      },
    };
  });
};

const updateImageInTemplates = async (
  editorAPI: EditorAPI,
  imageList: ImageData[],
  newImageList: ImageRecommendation[],
) => {
  await Promise.all(
    imageList.map(async (image: ImageData, index: number) => {
      const newImageData = newImageList[index];

      switch (image.origin) {
        case ImageTypes.Image:
          await editorAPI.components.data.update(image.ref, {
            uri: newImageData.url,
          });

          break;

        case ImageTypes.PageBackground:
          const newPageBackgroundData = image.data;
          const newPageBackgroundDataUri = newImageData.url.split('/').pop();
          const pageBackgroundMediaRef = newPageBackgroundData.ref.mediaRef;

          if (pageBackgroundMediaRef.type === constants.MEDIA_TYPES.IMAGE) {
            pageBackgroundMediaRef.uri = newPageBackgroundDataUri;
          } else {
            pageBackgroundMediaRef.posterImageRef.uri =
              newPageBackgroundDataUri;
          }

          await editorAPI.pages.background.update(
            image.data.ref.metaData.pageId,
            newPageBackgroundData,
            'desktop',
          );

          break;

        case ImageTypes.StripBackground:
        case ImageTypes.SectionBackground:
          const newBackgroundData = image.data;
          const newBackgroundDataUri = newImageData.url.split('/').pop();
          const backgroundMediaRef = newBackgroundData.background.mediaRef;

          backgroundMediaRef.uri = newBackgroundDataUri;

          await editorAPI.components.design.update(
            image.ref,
            newBackgroundData,
            false,
          );

          break;

        default:
          throw new Error('Unknown strategy');
      }
    }),
  );
};

const getImagesToReplace = (editorAPI: EditorAPI) => {
  const imageToReplace: ImageData[] = editorAPI.components.get
    .byType(constants.COMP_TYPES.PHOTO)
    .filter((compRef) => isRelevantImageSize(compRef, editorAPI))
    .map((compRef) => {
      const data = editorAPI.components.data.get(compRef);
      return {
        ref: compRef,
        data,
        origin: ImageTypes.Image,
      };
    });

  const pageBackgroundsToReplace = editorAPI.pages
    .getPagesData()
    .map((page) => editorAPI.dsRead.pages.background.get(page.id, 'desktop'))
    .filter((compData) => compData.ref?.mediaRef)
    .map((compData) => {
      return {
        data: compData,
        ref: { id: compData.ref.id, type: compData.ref.type },
        origin: ImageTypes.PageBackground,
      };
    });

  const getStripBackgrounds = () => {
    const stripBackgroundsToReplace: {
      data: CompData;
      ref: CompRef;
      origin: string;
    }[] = [];

    editorAPI.components.get
      .byType('wysiwyg.viewer.components.StripColumnsContainer')
      .map((compRef) => editorAPI.components.getChildren(compRef))
      .map((compRef) => {
        return compRef.map((comp) => {
          return {
            compData: editorAPI.components.design.get(comp),
            parentRef: comp,
          };
        });
      })
      .map((comps) =>
        comps.forEach(({ compData, parentRef }) => {
          if (compData.background?.mediaRef?.type !== IMAGE_TYPE) return;
          stripBackgroundsToReplace.push({
            data: compData,
            ref: parentRef,
            origin: ImageTypes.StripBackground,
          });
        }),
      );

    return stripBackgroundsToReplace;
  };
  const sectionBackgrounds = editorAPI.sections
    .getAllSections()
    .map((section) => {
      return {
        data: editorAPI.components.design.get(section),
        parentRef: section,
        origin: ImageTypes.SectionBackground,
      };
    })
    .filter(
      (compData) => compData.data?.background?.mediaRef?.type === IMAGE_TYPE,
    );

  const allImages = [
    imageToReplace,
    pageBackgroundsToReplace,
    getStripBackgrounds(),
    sectionBackgrounds,
  ].flat();

  return allImages;
};

export const replaceImagesInTemplates = async (
  editorAPI: EditorAPI,
): Promise<void> => {
  const imagesToReplace: AnyFixMe[] = getImagesToReplace(editorAPI);
  if (imagesToReplace.length === 0) return;

  const imagesWithParentData: AnyFixMe[] = populateImagesWithParentData(
    editorAPI,
    imagesToReplace,
  );

  const httpClient = new HttpClient({
    getAppToken: () =>
      editorAPI.documentServices.platform.getAppDataByApplicationId('-666')
        ?.instance,
  });

  const listOfImagesByType: AnyFixMe = {};

  imagesWithParentData.forEach((image) => {
    const parentName = image.parentData.parentName;
    if (listOfImagesByType[parentName]) {
      listOfImagesByType[parentName] = [
        ...listOfImagesByType[parentName],
        image,
      ];
    } else {
      listOfImagesByType[parentName] = [image];
    }
  });

  await Promise.all(
    Object.keys(listOfImagesByType).map(async (parentName) => {
      const query = `image for the ${parentName}`;
      const limit = listOfImagesByType[parentName].length;
      const newImages = await Promise.all(
        await getSuggestedPhotosInSection(httpClient, query, limit),
      );
      await updateImageInTemplates(
        editorAPI,
        listOfImagesByType[parentName],
        newImages,
      );
    }),
  );

  return null;
};

// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as util from '#packages/util';
import constants from '#packages/constants';
import * as core from '#packages/core';
import reportBiEventOnUiChangeMixin from './reportBiEventOnUiChangeMixin';

function getValueByKey(key, data) {
  return data && _.get(data, key);
}

function onValueChanged(key, type, newVal, dontAddToUndoRedoStack) {
  const editorAPI = this.getEditorAPI();
  this.setValueByKey(editorAPI, key, newVal, type, dontAddToUndoRedoStack);
}

function linkedValue(key, type) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (_.isUndefined(key)) {
    return {
      value: this[type],
      requestChange: onValueChanged.bind(this, key, type),
    };
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
  if (_.isArray(key)) {
    return {
      value: _.merge(_.pick(this[type], key), _.zipObject(key)),
      requestChange: onValueChanged.bind(this, key, type),
    };
  }
  return {
    value: getValueByKey(key, this[type]),
    requestChange: onValueChanged.bind(this, key, type),
  };
}

export default {
  propTypes: {
    selectedComponent: PropTypes.arrayOf(PropTypes.object),
  },
  mixins: [core.mixins.editorAPIMixin, reportBiEventOnUiChangeMixin],
  getFrameProps() {
    return util.panelUtils.getFrameProps(this.props);
  },
  getInitialState() {
    const editorAPI = this.getEditorAPI();
    this.data = editorAPI.components.data.get(this.props.selectedComponent);
    this.properties = editorAPI.components.properties.get(
      this.props.selectedComponent,
    );
    this.layout = editorAPI.components.layout.getRelativeToStructure(
      this.props.selectedComponent,
    );
    if (editorAPI.components.design) {
      this.design = editorAPI.components.design.get(
        this.props.selectedComponent,
      );
    }
    return {};
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const editorAPI = this.getEditorAPI();
    if (editorAPI.components.is.exist(nextProps.selectedComponent)) {
      this.data = editorAPI.components.data.get(nextProps.selectedComponent);
      this.properties = editorAPI.components.properties.get(
        nextProps.selectedComponent,
      );
      this.layout = editorAPI.components.layout.getRelativeToStructure(
        nextProps.selectedComponent,
      );
      this.design = editorAPI.components.design.get(
        nextProps.selectedComponent,
      );
    }
  },

  linkedData(dataFieldName) {
    return linkedValue.call(this, dataFieldName, 'data');
  },

  linkedProperty(propFieldName) {
    return linkedValue.call(this, propFieldName, 'properties');
  },

  getData(fieldName) {
    let data = getValueByKey(fieldName, this.data);
    // Patch to allow opening the designData experiment in viewer without the editor knowing about it
    // If data is missing on the document_data map we try finding it on the design_data
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (_.isUndefined(data) && !_.isUndefined(this.design)) {
      data = getValueByKey(fieldName, this.design);
    }
    return data;
  },

  getDesign(fieldName) {
    return getValueByKey(fieldName, this.design);
  },

  updateData(fieldName, newVal) {
    onValueChanged.call(this, fieldName, 'data', newVal);
  },

  updateDesign(fieldName, newVal, dontAddToUndoRedoStack) {
    onValueChanged.call(
      this,
      fieldName,
      'design',
      newVal,
      dontAddToUndoRedoStack,
    );
  },

  getProperty(fieldName) {
    return getValueByKey(fieldName, this.properties);
  },
  updateProperty(fieldName, newVal, dontAddToUndoRedoStack) {
    onValueChanged.call(
      this,
      fieldName,
      'properties',
      newVal,
      dontAddToUndoRedoStack,
    );
  },
  getLayout(fieldName) {
    return getValueByKey(fieldName, this.layout);
  },
  setValueByKey(editorAPI, key, val, type, dontAddToUndoRedoStack) {
    let newData = _.cloneDeep(this[type]) || {};

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
    if (_.isUndefined(key)) {
      newData = val;
      // eslint-disable-next-line you-dont-need-lodash-underscore/is-array
    } else if (_.isArray(key) && _.isObject(val)) {
      const newValues = _.pick(val, key);
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(newData, newValues);
    } else if (_.isObject(key)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(newData, key);
    } else {
      _.set(newData, key, val);
    }

    //hack for ds.property.update, id is required
    newData.id = newData?.id ? newData.id : this.data?.id;

    if (type === 'data') {
      editorAPI.components.data.update(
        this.props.selectedComponent,
        newData,
        dontAddToUndoRedoStack,
      );
    } else if (type === 'design') {
      editorAPI.components.design.update(
        this.props.selectedComponent,
        newData,
        false,
        dontAddToUndoRedoStack,
      );
    } else {
      editorAPI.components.properties.update(
        this.props.selectedComponent,
        newData,
        dontAddToUndoRedoStack,
      );
    }
    return newData;
  },

  openHelpCenter(helpId, isFromLearnMore) {
    const editorAPI = this.getEditorAPI();
    if (!helpId) {
      return;
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-nil
    const isLearnMoreSpecified = !_.isNil(isFromLearnMore);
    const biHelpParams = {
      component: editorAPI.selection.getSelectedComponentType(),
      learn_more: isLearnMoreSpecified ? !!isFromLearnMore : null,
      panel_name: this.props.panelName,
      origin: constants.BI.HELP.ORIGIN.PANEL,
    };
    editorAPI.panelManager.openHelpCenter(helpId, null, biHelpParams);
  },
};
